/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_OpenAccountsSetup from "./OpenAccountsSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("OpenAccountsSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAccountsSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is OpenAccountsSetup Loads Successfully", () => {
    expect(screen.getByText("OpenAccountsSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for OpenAccountsSetup", () => {
    // START_USER_CODE-USER_OpenAccountsSetup_Custom_Test_Case
    // END_USER_CODE-USER_OpenAccountsSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAccountsSetup />
      );
    });
  });
  afterEach(cleanup);
  test("brnSearch(Button Widget) Test Cases", async () => {
    const brnSearch = screen.getByTestId("brnSearch");
    expect(brnSearch).toBeInTheDocument;
    expect(brnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_brnSearch")
    );
  });
  test("Custom Test Cases for brnSearch", () => {
    // START_USER_CODE-USER_brnSearch_TEST
    // END_USER_CODE-USER_brnSearch_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnDown1(Button Widget) Test Cases", async () => {
    const btnDown1 = screen.getByTestId("btnDown1");
    expect(btnDown1).toBeInTheDocument;
    expect(btnDown1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnDown1")
    );
  });
  test("Custom Test Cases for btnDown1", () => {
    // START_USER_CODE-USER_btnDown1_TEST
    // END_USER_CODE-USER_btnDown1_TEST
  });
  test("btnDown2(Button Widget) Test Cases", async () => {
    const btnDown2 = screen.getByTestId("btnDown2");
    expect(btnDown2).toBeInTheDocument;
    expect(btnDown2.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnDown2")
    );
  });
  test("Custom Test Cases for btnDown2", () => {
    // START_USER_CODE-USER_btnDown2_TEST
    // END_USER_CODE-USER_btnDown2_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnUp1(Button Widget) Test Cases", async () => {
    const btnUp1 = screen.getByTestId("btnUp1");
    expect(btnUp1).toBeInTheDocument;
    expect(btnUp1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnUp1")
    );
  });
  test("Custom Test Cases for btnUp1", () => {
    // START_USER_CODE-USER_btnUp1_TEST
    // END_USER_CODE-USER_btnUp1_TEST
  });
  test("btnUp2(Button Widget) Test Cases", async () => {
    const btnUp2 = screen.getByTestId("btnUp2");
    expect(btnUp2).toBeInTheDocument;
    expect(btnUp2.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnUp2")
    );
  });
  test("Custom Test Cases for btnUp2", () => {
    // START_USER_CODE-USER_btnUp2_TEST
    // END_USER_CODE-USER_btnUp2_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsSetup_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridOpenAccount(Grid Widget) Test Cases", async () => {
    let gridOpenAccount = screen.getByTestId("gridOpenAccount");
    let gridOpenAccountbtn =
      gridOpenAccount.nextElementSibling.firstElementChild;
    gridOpenAccount =
      gridOpenAccount.parentElement.parentElement.parentElement;
    expect(gridOpenAccount.tagName).toBe("DIV");
    expect(gridOpenAccount.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridOpenAccount", () => {
    // START_USER_CODE-USER_gridOpenAccount_TEST
    // END_USER_CODE-USER_gridOpenAccount_TEST
  });
  test("grpbxOpenAccountsSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxOpenAccountsSetup = screen.getByTestId(
      "grpbxOpenAccountsSetup"
    );
    expect(grpbxOpenAccountsSetup.tagName).toBe("BUTTON");
    expect(grpbxOpenAccountsSetup.type).toBe("button");
    expect(grpbxOpenAccountsSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxOpenAccountsSetup", () => {
    // START_USER_CODE-USER_grpbxOpenAccountsSetup_TEST
    // END_USER_CODE-USER_grpbxOpenAccountsSetup_TEST
  });
  test("txtAcctVendor(Textbox Widget) Test Cases", async () => {
    const txtAcctVendor = screen.getByTestId("txtAcctVendor");
    expect(txtAcctVendor.tagName).toBe("INPUT");
    expect(txtAcctVendor.type).toBe("text");
    expect(txtAcctVendor.classList).toContain("textboxWidgetClass");
    expect(txtAcctVendor.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsSetup_txtAcctVendor"
      )
    );
    await act(async () => {
      userEvent.type(txtAcctVendor, "1");
    });
  });
  test("Custom Test Cases for txtAcctVendor", () => {
    // START_USER_CODE-USER_txtAcctVendor_TEST
    // END_USER_CODE-USER_txtAcctVendor_TEST
  });
  test("txtAcctVndr(Textbox Widget) Test Cases", async () => {
    const txtAcctVndr = screen.getByTestId("txtAcctVndr");
    expect(txtAcctVndr.tagName).toBe("INPUT");
    expect(txtAcctVndr.type).toBe("text");
    expect(txtAcctVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAcctVndr, "1");
    });
  });
  test("Custom Test Cases for txtAcctVndr", () => {
    // START_USER_CODE-USER_txtAcctVndr_TEST
    // END_USER_CODE-USER_txtAcctVndr_TEST
  });
  test("gridOpenAccount_txtcolAcctVendor(Grid Widget) Test Cases", async () => {
    let gridOpenAccount_txtcolAcctVendor = screen.getByTestId(
      "gridOpenAccount"
    );
    let gridOpenAccount_txtcolAcctVendorbtn =
      gridOpenAccount_txtcolAcctVendor.nextElementSibling
        .firstElementChild;
    gridOpenAccount_txtcolAcctVendor =
      gridOpenAccount_txtcolAcctVendor.parentElement.parentElement
        .parentElement;
    expect(gridOpenAccount_txtcolAcctVendor.tagName).toBe("DIV");
    expect(gridOpenAccount_txtcolAcctVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAcctVendor", () => {
    // START_USER_CODE-USER_txtcolAcctVendor_TEST
    // END_USER_CODE-USER_txtcolAcctVendor_TEST
  });
  test("gridOpenAccount_txtcolAR(Grid Widget) Test Cases", async () => {
    let gridOpenAccount_txtcolAR = screen.getByTestId(
      "gridOpenAccount"
    );
    let gridOpenAccount_txtcolARbtn =
      gridOpenAccount_txtcolAR.nextElementSibling.firstElementChild;
    gridOpenAccount_txtcolAR =
      gridOpenAccount_txtcolAR.parentElement.parentElement
        .parentElement;
    expect(gridOpenAccount_txtcolAR.tagName).toBe("DIV");
    expect(gridOpenAccount_txtcolAR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAR", () => {
    // START_USER_CODE-USER_txtcolAR_TEST
    // END_USER_CODE-USER_txtcolAR_TEST
  });
  test("gridOpenAccount_txtcolCR(Grid Widget) Test Cases", async () => {
    let gridOpenAccount_txtcolCR = screen.getByTestId(
      "gridOpenAccount"
    );
    let gridOpenAccount_txtcolCRbtn =
      gridOpenAccount_txtcolCR.nextElementSibling.firstElementChild;
    gridOpenAccount_txtcolCR =
      gridOpenAccount_txtcolCR.parentElement.parentElement
        .parentElement;
    expect(gridOpenAccount_txtcolCR.tagName).toBe("DIV");
    expect(gridOpenAccount_txtcolCR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCR", () => {
    // START_USER_CODE-USER_txtcolCR_TEST
    // END_USER_CODE-USER_txtcolCR_TEST
  });
  test("gridOpenAccount_txtcolOpenAcct(Grid Widget) Test Cases", async () => {
    let gridOpenAccount_txtcolOpenAcct = screen.getByTestId(
      "gridOpenAccount"
    );
    let gridOpenAccount_txtcolOpenAcctbtn =
      gridOpenAccount_txtcolOpenAcct.nextElementSibling
        .firstElementChild;
    gridOpenAccount_txtcolOpenAcct =
      gridOpenAccount_txtcolOpenAcct.parentElement.parentElement
        .parentElement;
    expect(gridOpenAccount_txtcolOpenAcct.tagName).toBe("DIV");
    expect(gridOpenAccount_txtcolOpenAcct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenAcct", () => {
    // START_USER_CODE-USER_txtcolOpenAcct_TEST
    // END_USER_CODE-USER_txtcolOpenAcct_TEST
  });
  test("gridOpenAccount_txtcolSR(Grid Widget) Test Cases", async () => {
    let gridOpenAccount_txtcolSR = screen.getByTestId(
      "gridOpenAccount"
    );
    let gridOpenAccount_txtcolSRbtn =
      gridOpenAccount_txtcolSR.nextElementSibling.firstElementChild;
    gridOpenAccount_txtcolSR =
      gridOpenAccount_txtcolSR.parentElement.parentElement
        .parentElement;
    expect(gridOpenAccount_txtcolSR.tagName).toBe("DIV");
    expect(gridOpenAccount_txtcolSR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSR", () => {
    // START_USER_CODE-USER_txtcolSR_TEST
    // END_USER_CODE-USER_txtcolSR_TEST
  });
  test("gridOpenAccount_txtcolVendorSortName(Grid Widget) Test Cases", async () => {
    let gridOpenAccount_txtcolVendorSortName = screen.getByTestId(
      "gridOpenAccount"
    );
    let gridOpenAccount_txtcolVendorSortNamebtn =
      gridOpenAccount_txtcolVendorSortName.nextElementSibling
        .firstElementChild;
    gridOpenAccount_txtcolVendorSortName =
      gridOpenAccount_txtcolVendorSortName.parentElement.parentElement
        .parentElement;
    expect(gridOpenAccount_txtcolVendorSortName.tagName).toBe("DIV");
    expect(gridOpenAccount_txtcolVendorSortName.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountsSetup_gridOpenAccount"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorSortName", () => {
    // START_USER_CODE-USER_txtcolVendorSortName_TEST
    // END_USER_CODE-USER_txtcolVendorSortName_TEST
  });
});
