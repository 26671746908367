/* eslint-disable*/
import React from "react";
import ContractManagement_ReportPreviewExcPrem from "./ReportPreviewExcPrem";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReportPreviewExcPrem Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ReportPreviewExcPrem />);
    });
  });
  afterEach(cleanup);
  test("is ReportPreviewExcPrem Loads Successfully", () => {
    expect(screen.getByText("ReportPreviewExcPrem")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReportPreviewExcPrem", () => {
    // START_USER_CODE-USER_ReportPreviewExcPrem_Custom_Test_Case
    // END_USER_CODE-USER_ReportPreviewExcPrem_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ReportPreviewExcPrem />);
    });
  });
  afterEach(cleanup);
  test("groupboxwidget0(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget0 = screen.getByTestId("groupboxwidget0");
    expect(groupboxwidget0.tagName).toBe("BUTTON");
    expect(groupboxwidget0.type).toBe("button");
    expect(groupboxwidget0.classList).toContain("btn");
    expect(groupboxwidget0.textContent).toEqual(
      t("ContractManagement:ReportPreviewExcPrem_groupboxwidget0")
    );
  });
  test("Custom Test Cases for groupboxwidget0", () => {
    // START_USER_CODE-USER_groupboxwidget0_TEST
    // END_USER_CODE-USER_groupboxwidget0_TEST
  });
});
