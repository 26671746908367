/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FreightRateCopy from "./FreightRateCopy";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FreightRateCopy Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FreightRateCopy />);
    });
  });
  afterEach(cleanup);
  test("is FreightRateCopy Loads Successfully", () => {
    expect(screen.getByText("FreightRateCopy")).toBeInTheDocument;
  });
  test("Custom Test Cases for FreightRateCopy", () => {
    // START_USER_CODE-USER_FreightRateCopy_Custom_Test_Case
    // END_USER_CODE-USER_FreightRateCopy_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FreightRateCopy />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateCopy_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCopy(Button Widget) Test Cases", async () => {
    const btnCopy = screen.getByTestId("btnCopy");
    expect(btnCopy).toBeInTheDocument;
    expect(btnCopy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateCopy_btnCopy")
    );
  });
  test("Custom Test Cases for btnCopy", () => {
    // START_USER_CODE-USER_btnCopy_TEST
    // END_USER_CODE-USER_btnCopy_TEST
  });
  test("grpbxCopyTo(GroupBox Widget) Test Cases", async () => {
    const grpbxCopyTo = screen.getByTestId("grpbxCopyTo");
    expect(grpbxCopyTo.tagName).toBe("BUTTON");
    expect(grpbxCopyTo.type).toBe("button");
    expect(grpbxCopyTo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCopyTo", () => {
    // START_USER_CODE-USER_grpbxCopyTo_TEST
    // END_USER_CODE-USER_grpbxCopyTo_TEST
  });
  test("grpbxFreightDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightDetails = screen.getByTestId("grpbxFreightDetails");
    expect(grpbxFreightDetails.tagName).toBe("BUTTON");
    expect(grpbxFreightDetails.type).toBe("button");
    expect(grpbxFreightDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightDetails", () => {
    // START_USER_CODE-USER_grpbxFreightDetails_TEST
    // END_USER_CODE-USER_grpbxFreightDetails_TEST
  });
  test("grpbxFreightRateCopy(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightRateCopy = screen.getByTestId("grpbxFreightRateCopy");
    expect(grpbxFreightRateCopy.tagName).toBe("BUTTON");
    expect(grpbxFreightRateCopy.type).toBe("button");
    expect(grpbxFreightRateCopy.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightRateCopy", () => {
    // START_USER_CODE-USER_grpbxFreightRateCopy_TEST
    // END_USER_CODE-USER_grpbxFreightRateCopy_TEST
  });
  test("lblCopyTo(Label Widget) Test Cases", async () => {
    const lblCopyTo = screen.getByTestId("lblCopyTo");
    expect(lblCopyTo.tagName).toBe("LABEL");
    expect(lblCopyTo.classList).toContain("form-label");
    expect(lblCopyTo.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateCopy_lblCopyTo")
    );
  });
  test("Custom Test Cases for lblCopyTo", () => {
    // START_USER_CODE-USER_lblCopyTo_TEST
    // END_USER_CODE-USER_lblCopyTo_TEST
  });
  test("txtFromBuyPt(Textbox Widget) Test Cases", async () => {
    const txtFromBuyPt = screen.getByTestId("txtFromBuyPt");
    expect(txtFromBuyPt.tagName).toBe("INPUT");
    expect(txtFromBuyPt.type).toBe("text");
    expect(txtFromBuyPt.classList).toContain("textboxWidgetClass");
    expect(txtFromBuyPt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateCopy_txtFromBuyPt")
    );
    await act(async () => {
      userEvent.type(txtFromBuyPt, "1");
    });
  });
  test("Custom Test Cases for txtFromBuyPt", () => {
    // START_USER_CODE-USER_txtFromBuyPt_TEST
    // END_USER_CODE-USER_txtFromBuyPt_TEST
  });
  test("txtFromCarrier(Textbox Widget) Test Cases", async () => {
    const txtFromCarrier = screen.getByTestId("txtFromCarrier");
    expect(txtFromCarrier.tagName).toBe("INPUT");
    expect(txtFromCarrier.type).toBe("text");
    expect(txtFromCarrier.classList).toContain("textboxWidgetClass");
    expect(txtFromCarrier.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateCopy_txtFromCarrier")
    );
    await act(async () => {
      userEvent.type(txtFromCarrier, "1");
    });
  });
  test("Custom Test Cases for txtFromCarrier", () => {
    // START_USER_CODE-USER_txtFromCarrier_TEST
    // END_USER_CODE-USER_txtFromCarrier_TEST
  });
  test("txtToCarrier(Textbox Widget) Test Cases", async () => {
    const txtToCarrier = screen.getByTestId("txtToCarrier");
    expect(txtToCarrier.tagName).toBe("INPUT");
    expect(txtToCarrier.type).toBe("text");
    expect(txtToCarrier.classList).toContain("textboxWidgetClass");
    expect(txtToCarrier.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateCopy_txtToCarrier")
    );
    await act(async () => {
      userEvent.type(txtToCarrier, "1");
    });
  });
  test("Custom Test Cases for txtToCarrier", () => {
    // START_USER_CODE-USER_txtToCarrier_TEST
    // END_USER_CODE-USER_txtToCarrier_TEST
  });
});
