/* eslint-disable*/
import { ApiService } from "../../Common/ApiService";
export const SystemMaintenanceMasterManagementService = {
    RetrieveStateControlDetails,
    RemoveStateControl,
    CreatePeanutTypeControl,
    UpdatePeanutTypeControl,
    RemoveFarmControl,
    RetrieveFarmControlSearchDetails,
    RetrieveFarmControlDetails,
    UpdateChangeInspectionNumber,
    RetrieveUserFunctionLocationTransferDetails,
    RetrieveWareHouseLicenseControlDetails,
    RetrieveCollectionPointControlDetails,
    RetrieveWeigherControlDetails,
    RetrieveBuyingPointControlAccessM,
    RetrieveBuyingPointControlAccess,
    RemoveAreaControl,
    RemoveBuyingPointControldetails,
    RemoveCollectionPointControldetails,
    RemoveXferBuyingPointControldetails,
    RemoveWeigherControlDetails,
    RemoveCureCleanSchedule,
    CreateCureCleanSchedule,
    CreateStateControls,
    UpdateStateControls,
    RemovePeanutTypeControl,
    RemovePeanutVarietyControl,
    RetrieveWarehouseBinInventoryCheck,
    RemoveWarehouseBinInventory,
    CreateFarmVendorSplit,
    UpdateFarmVendorSplit,
    RemoveCountyControl,
    RetrieveCountyControlDetails,
    RemoveVoidReason,
    CreateWeigherControl,
    CreateFarmControl,
    UpdateFarmControl,
    CreateXferBuyingPoint,
    UpdateFarmVendorSplitOrderDetails,
    RemoveFarmVendorSplitDetails,
    CreateCountyControl,
    UpdateCountyControl,
    CreatePremiumDeductionContract,
    UpdateDeliveryPeriodControls,
    CreateWarehouseBinInventory,
    UpdateWarehouseBinInventory,
    CreateVoidReason,
    UpdateVoidReason,
    CreatePeanutVarietyControl,
    UpdatePeanutVarietyControl,
    RetrieveFarmGovDetails,
    UpdateFreightRate,
    RetrieveFreightRateDetails,
    RetrieveFuelSurchargeList,
    CreateFarmerStockPriceControl,
    RetrieveContractPriceLimitDetails,
    RetrieveWareHouseBinInventoryCopyDetails,
    CreateCollectionPointControl,
    UpdateCollectionPointControl,
    RetrieveInternetTypeFormatControlDetails,
    RetrieveFreightLocationToLocationDetails,
    RetrieveDeliveryPeriodControlDetails,
    CreateAreaControls,
    UpdateAreaControls,
    CreatePeanutSpecification,
    UpdatePeanutSpecification,
    CreatePremiumDeductionVendor,
    CreateBuyingPointControl,
    UpdateFreightLocationToLocationDetails,
    RemoveWarehouseLicenseControlDetails,
    RetrieveIntrestAgreementDetails,
    RemoveIntrestAgreement,
    RetrieveCopyFrieghtRateDetails,
    RetrieveCopyFrieghtRateLocationDetails,
    RetrieveCopyFrieghtRateCarrierDetails,
    UpdateBuyingPointControl,
    RetrieveLeasedTrailerDetails,
    UpdateLeasedTrailer,
    UpdateContractPriceLimit,
    CreatePeanutSpecificationException,
    UpdatePeanutSpecificationException,
    RetrieveBuyingpointControlsWarehouseLicenseDetails,
    RemovePremiumDeductionScheduleDetails,
    CreateIntrestAgreement,
    UpdateIntrestAgreement,
    CreateOpenAccountControl,
    RetrieveFarmerStockPriceControlDetails,
    RetrieveFilterStateCountyDetails,
    UpdateOpenAccountControl,
    RemoveFarmerStockPriceControlDetails,
    UpdateFilterStateCounty,
    RetrievePremiumDeductionContractDetails,
    RetrievePremiumDeductionVendorDetails,
    RetrievePremiumDeductionScheduleDetails,
    UpdateFarmerStockPriceControl,
    RetrieveShrinkControlDetails,
    RetrievePeanutVarietyFilterDetails,
    UpdatePeanutVarietyFilter,
    CreateShrinkControl,
    CreateWarehouseLicenseControl,
    RemoveOpenAccountControlDetails,
    UpdateWhouseLicenseControl,
    RemovePeanutSpecification,
    CreatePremiumDeductSchedule,
    UpdatePremiumDeductSchedule,
    RemovePeanutSpecificationException,
    RemovePremiumDeductionVendorDetails,
    RemovePremiumDeductionContractDetails,
    RetrieveFreightMileageChartDetails,
    RetrieveManualAccountingDistribution,
    RetrieveCmaDmaControlDetails,
    RemoveCmaDmaControlDetails,
    UpdateTradeCategory,
}

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const systemmaintenance_url_api = '/system-maintenance/v1/';

export function RetrieveStateControlDetails(state_abbr) {
    let optionalParamsUrl = ``

    if (state_abbr !== null && state_abbr !== '' && state_abbr !== undefined)
        optionalParamsUrl += `state_abbr=${state_abbr}`
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveStateControlDetails/crop-years/${cropYearFromLS()}/states?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}/crop-years/${cropYearFromLS()}/states?${optionalParamsUrl}`);
}

export function RemoveStateControl(buy_pt_id, state_abbr) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveStateControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/users/${useridFromLS()}` :
        `${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/users/${useridFromLS()}`)
}
export function RemovePremiumDeductionScheduleDetails(buy_pt_id, pd_code) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePremiumDeductionScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/codes/${pd_code}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/codes/${pd_code}`
    )
}
export function CreatePeanutTypeControl(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreatePeanutTypeControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanuttypecontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanuttypecontrols`, data);
}

export function UpdatePeanutTypeControl(pnut_type_id, buy_pt_id, data) {

    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePeanutTypeControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-points/${pnut_type_id}/peanuttypecontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-points/${pnut_type_id}/peanuttypecontrols`, data);
}

export function RetrieveFarmControlDetails(buy_pt_id, state_abbr, county_id, farm_id, farm_suffix) {
    let optionsParamsURL = '';
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }
    if (state_abbr != null && state_abbr !== '' && state_abbr !== undefined) {
        optionsParamsURL += `state_abbr=${state_abbr}&`;
    }
    if (county_id != null && county_id !== '' && county_id !== undefined) {
        optionsParamsURL += `county_id=${county_id}&`;
    }
    if (farm_id != null && farm_id !== '' && farm_id !== undefined) {
        optionsParamsURL += `farm_id=${farm_id}&`;
    }
    if (farm_suffix != null && farm_suffix !== '' && farm_suffix !== undefined) {
        optionsParamsURL += `farm_suffix=${farm_suffix}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveFarmControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/farmcontrols?${optionsParamsURL}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/farmcontrols?${optionsParamsURL}`);

}

export function RetrieveFarmControlSearchDetails(func_id, func_sub_id, buy_pt_id, state_abbr, county_id, farm_id, farm_suffix, split_vendor, search_name, orderby) {
    let optionalParamsUrl = '';
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    if (state_abbr != null && state_abbr !== '' && state_abbr !== undefined) {
        optionalParamsUrl += `state_abbr=${state_abbr}&`;
    }
    if (county_id != null && county_id !== '' && county_id !== undefined) {
        optionalParamsUrl += `county_id=${county_id}&`;
    }
    if (farm_id != null && farm_id !== '' && farm_id !== undefined) {
        optionalParamsUrl += `farm_id=${farm_id}&`;
    }
    if (farm_suffix != null && farm_suffix !== '' && farm_suffix !== undefined) {
        optionalParamsUrl += `farm_suffix=${farm_suffix}&`;
    }
    if (split_vendor != null && split_vendor !== '' && split_vendor !== undefined) {
        optionalParamsUrl += `split_vendor=${split_vendor}&`;
    }
    if (search_name != null && search_name !== '' && search_name !== undefined) {
        optionalParamsUrl += `search_name=${search_name}&`;
    }
    if (orderby != null && orderby !== '' && orderby !== undefined) {
        optionalParamsUrl += `orderby=${orderby}&`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveFarmControlSearchDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/farmcontrols?${optionalParamsUrl}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/farmcontrols?${optionalParamsUrl}`)
}

export function RemoveFarmControl(buy_pt_id, state_abbr, county_id, farm_id, farm_suffix) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveFarmControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${farm_suffix}/users/${useridFromLS()}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${farm_suffix}/users/${useridFromLS()}`);

}
export function UpdateChangeInspectionNumber(data) {
    data.user_id = useridFromLS()
    return ApiService.updateData(
        isLocal()
            ? `/SystemMaintenance/UpdateChangeInspectionNumber/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buy_pt_id}/old-inspections/${data.old_insp_num}/new-inspections/${data.new_insp_num}/inspections`
            : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buy_pt_id}/old-inspections/${data.old_insp_num}/new-inspections/${data.new_insp_num}/inspections`
        , data
    )
}
export function RetrieveUserFunctionLocationTransferDetails(user_id, func_id, func_sub_id, buy_pt_id) {
    let optionalParamsUrl = '';
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined)
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined)
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}`
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveUserFunctionLocationTransferDetails/companies/${compIdFromLS()}/users/${user_id}/functions/${func_id}/functionlocations?${optionalParamsUrl}`
            : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/functions/${func_id}/functionlocations?${optionalParamsUrl}`
    )
}

export function RetrieveWareHouseLicenseControlDetails(fed_whouse_license, buy_pt_id) {
    let optionalParamsUrl = ``
    if (compIdFromLS() != null && compIdFromLS() !== '' && compIdFromLS() !== undefined)
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`
    if (cropYearFromLS() != null && cropYearFromLS() !== '' && cropYearFromLS() !== undefined)
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
    if (fed_whouse_license != null && fed_whouse_license !== '' && fed_whouse_license !== undefined)
        optionalParamsUrl += `fed_whouse_license=${fed_whouse_license}&`
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined)
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}`
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveWareHouseLicenseControlDetails/warehouselicenses?${optionalParamsUrl}`
            : `${systemmaintenance_url_api}warehouselicenses?${optionalParamsUrl}`
    )
}

export function RetrieveCollectionPointControlDetails(comp_id, crop_year, coll_pt_id, area_id) {
    let optionalParamsUrl = ``
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }

    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }

    if (coll_pt_id != null && coll_pt_id !== '' && coll_pt_id !== undefined) {
        optionalParamsUrl += `coll_pt_id=${coll_pt_id}&`;
    }

    if (area_id != null && area_id !== '' && area_id !== undefined) {
        optionalParamsUrl += `area_id=${area_id}&`;
    }

    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveCollectionPointControlDetails/collectionpoints?${optionalParamsUrl}
      `: `${systemmaintenance_url_api}collectionpoints?${optionalParamsUrl}`)
}

export function RetrieveWeigherControlDetails(buy_pt_id) {
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveWeigherControlDetails/companies/${compIdFromLS()}/crop-year/${cropYearFromLS()}/buying-ponints/${buy_pt_id}/weighercontrols`
            : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-year/${cropYearFromLS()}/buying-ponints/${buy_pt_id}/weighercontrols`
    )
}

export function RetrieveBuyingPointControlAccessM(accessuser) {
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveBuyingPointControlAccessM/users/${useridFromLS()}/access-users/${accessuser}/crop-years/${cropYearFromLS()}/companies/${compIdFromLS()}/accesses`
            : `${systemmaintenance_url_api}users/${useridFromLS()}/access-users/${accessuser}/crop-years/${cropYearFromLS()}/companies/${compIdFromLS()}/accesses`
    )
}

export function RetrieveBuyingPointControlAccess(coll_pt_id, accessuser) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveBuyingPointControlAccess/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/users/${useridFromLS()}/access-users/${accessuser}/buyingpointcontrolAccess`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/users/${useridFromLS()}/access-users/${accessuser}/buyingpointcontrolAccess`
    )
}

export function RemoveAreaControl(buy_pt_id, area_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveAreaControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/areas/${area_id}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/areas/${area_id}/users/${useridFromLS()}`
    )
}

export function RemoveBuyingPointControldetails(buy_pt_id, coll_pt_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveBuyingPointControldetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/collection-points/${coll_pt_id}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/collection-points/${coll_pt_id}/users/${useridFromLS()}`
    )
}

export function RemoveCollectionPointControldetails(buy_pt_id, coll_pt_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveCollectionPointControldetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/buying-points/${buy_pt_id}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/buying-points/${buy_pt_id}/users/${useridFromLS()}`
    )
}

export function RemoveXferBuyingPointControldetails(buy_pt_id, coll_pt_id, xfer_buy_pt_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveXferBuyingPointControldetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/collection-points/${coll_pt_id}/xfer-buying-points/${xfer_buy_pt_id}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/collection-points/${coll_pt_id}/xfer-buying-points/${xfer_buy_pt_id}/users/${useridFromLS()}`
    )
}


export function RemoveWeigherControlDetails(buy_pt_id, coll_pt_id, weigher_name) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveWeigherControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/collection-points/${coll_pt_id}/weigher_names/${weigher_name}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/collection-points/${coll_pt_id}/weigher_names/${weigher_name}/users/${useridFromLS()}`
    )
}

export function RemoveCureCleanSchedule(buy_pt_id, schedule_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveCureCleanSchedule/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/schedules/${schedule_id}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/schedules/${schedule_id}/users/${useridFromLS()}`
    )
}

export function CreateCureCleanSchedule(buy_pt_id, data) {
    return ApiService.postData(isLocal()
        ? `/SystemMaintenance/CreateCureCleanSchedule/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/cleanschedules`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/cleanschedules`, data
    );
}

export function CreateStateControls(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateStateControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/statecontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/statecontrols`, data
    );
}

export function UpdateStateControls(state_abbr, buy_pt_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateStateControls/states/${state_abbr}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/statecontrols`
        : `${systemmaintenance_url_api}states/${state_abbr}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/statecontrols`, data
    );
}
export function RemovePeanutTypeControl(buy_pt_id, pnut_type_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePeanutTypeControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-types/${pnut_type_id}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-types/${pnut_type_id}`);
}
export function RemovePeanutVarietyControl(buy_pt_id, area_id, pnut_type_id, pnut_variety_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePeanutVarietyControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/peanut-varieties/${pnut_variety_id}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/peanut-varieties/${pnut_variety_id}`);
}
export function RetrieveWarehouseBinInventoryCheck(buy_pt_id, whouse_num, bin_num) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveWarehouseBinInventoryCheck/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}/bininventorychecks`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}/bininventorychecks`
    )
}
export function RemoveWarehouseBinInventory(buy_pt_id, whouse_num, bin_num) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveWarehouseBinInventory/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}`
    )
}
export function CreateFarmVendorSplit(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateFarmVendorSplit/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/vendorsplits` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/vendorsplits`, data);
}
export function UpdateFarmVendorSplit(buy_pt_id, state_abbr, county_id, farm_id, farm_suffix, split_vendor, data) {

    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFarmVendorSplit/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${farm_suffix}/vendors/${split_vendor}/users/${useridFromLS()}/vendorsplits` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${farm_suffix}/vendors/${split_vendor}/users/${useridFromLS()}/vendorsplits`, data);
}

export function RemoveCountyControl(countY_ID, state_abbr) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveCountyControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/counities/${countY_ID}/states/${state_abbr}/users/${useridFromLS()}` :
        `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/counities/${countY_ID}/states/${state_abbr}/users/${useridFromLS()}`)
}

export function RemoveVoidReason(buy_pt_id, code) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveVoidReason/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/void-reason-codes/${code}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/void-reason-codes/${code}/users/${useridFromLS()}`)
}

export function CreateWeigherControl(buy_pt_id, coll_pt_id, data) {
    let optionsParamsURL = '';
    if (coll_pt_id != null && coll_pt_id !== '' && coll_pt_id !== undefined) {
        optionsParamsURL += `coll_pt_id=${coll_pt_id}&`;
    }
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateWeigherControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/Weighers?${optionsParamsURL}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/Weighers?${optionsParamsURL}`, data);
}

export function RetrieveCountyControlDetails(county_id, state_abbr) {
    let optionsParamsURL = '';
    if (county_id != null && county_id !== '' && county_id !== undefined) {
        optionsParamsURL += `county_id=${county_id}&`;
    }
    if (state_abbr != null && state_abbr !== '' && state_abbr !== undefined) {
        optionsParamsURL += `state_abbr=${state_abbr}&`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveCountyControlDetails/counties?${optionsParamsURL}`
    : `${systemmaintenance_url_api}counties?${optionsParamsURL}`)
}

export function CreateFarmControl(buy_pt_id, dataObj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateFarmControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/farmcontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/farmcontrols`, dataObj);
}

export function UpdateFarmControl(buy_pt_id, id, suffix, dataObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFarmControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/farm-ids/${id}/farm_suffixes/${suffix}/users/${useridFromLS()}/formcontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/farm-ids/${id}/farm_suffixes/${suffix}/users/${useridFromLS()}/formcontrols`, dataObj);
}

export function UpdateFarmVendorSplitOrderDetails(buy_pt_id, state_abbr, county_id, farm_id, suffix, split_vendor, dataObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFarmVendorSplitOrderDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${suffix}/vendors/${split_vendor}/users/${useridFromLS()}/splitorders` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${suffix}/vendors/${split_vendor}/users/${useridFromLS()}/splitorders`, dataObj);
}

export function RemoveFarmVendorSplitDetails(buy_pt_id, state_abbr, county_id, farm_id, suffix, split_vendor) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveFarmVendorSplitDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${suffix}/vendors/${split_vendor}/users/${useridFromLS()}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/states/${state_abbr}/counties/${county_id}/farms/${farm_id}/farm_suffixes/${suffix}/vendors/${split_vendor}/users/${useridFromLS()}`);
}

export function CreateCountyControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateCountyControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/countycontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/countycontrols`, data)
}

export function UpdateCountyControl(state_abbr, county_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateCountyControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/state-abbreviations/${state_abbr}/counties/${county_id}/countycontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/state-abbreviations/${state_abbr}/counties/${county_id}/countycontrols`, data)
}

export function CreateFarmerStockPriceControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateFarmerStockPriceControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/farmerstockprices`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/farmerstockprices`, data)
}
export function UpdateFarmerStockPriceControl(area_id, pnut_type_id, effect_date_time, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFarmerStockPriceControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areas/${area_id}/peanut-typs/${pnut_type_id}/effect-dates/${effect_date_time}/farmerstockpricecontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areas/${area_id}/peanut-typs/${pnut_type_id}/effect-dates/${effect_date_time}/farmerstockpricecontrols`, data)
}
export function CreateXferBuyingPoint(coll_pt_id, dataObj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateXferBuyingPoint/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/collection-points/${coll_pt_id}/xferbuyingpoints` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/collection-points/${coll_pt_id}/xferbuyingpoints`, dataObj);
}
export function CreatePremiumDeductionContract(dataObj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreatePremiumDeductionContract/premiumdeductioncontracts` : `${systemmaintenance_url_api}premiumdeductioncontracts`, dataObj);
}
export function UpdateDeliveryPeriodControls(area_id, action_type, delivery_id, data) {
    let optionalParamsUrl = ''; 
    if (delivery_id != null && delivery_id !== '' && delivery_id !== undefined) {
        optionalParamsUrl += `delivery_id=${delivery_id}&`;
    }
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateDeliveryPeriodControls/crop-years/${cropYearFromLS()}/areas/${area_id}/action-types/${action_type}/periodcontrols?${optionalParamsUrl}` :
        `${systemmaintenance_url_api}crop-years/${cropYearFromLS()}/areas/${area_id}/action-types/${action_type}/periodcontrols?${optionalParamsUrl}`, data);
}

export function CreateWarehouseBinInventory(buy_pt_id, whouse_num, bin_num, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateWarehouseBinInventory/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}/warehousebininventories` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}/warehousebininventories`, data);
}
export function UpdateWarehouseBinInventory(buy_pt_id, whouse_num, bin_num, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateWarehouseBinInventory/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}/warehouseinventories` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/warehouse-numbers/${whouse_num}/bin-numbers/${bin_num}/warehouseinventories`, data);
}

export function CreateVoidReason(buy_pt_id, data) {
    return ApiService.postData(isLocal()? `/SystemMaintenance/CreateVoidReason/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/voidreasons`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/voidreasons`, data
    );
}

export function UpdateVoidReason(buy_pt_id, void_reason_code, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateVoidReason/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/reason-codes/${void_reason_code}/voidreasons`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/reason-codes/${void_reason_code}/voidreasons`, data
    );
}
export function CreatePeanutVarietyControl(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreatePeanutVarietyControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/varietycontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/varietycontrols`, data);
}

export function UpdatePeanutVarietyControl(buy_pt_id,area_id,pnut_type_id,pnut_variety_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePeanutVarietyControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanuttypes/${pnut_type_id}/penautvarieties/${pnut_variety_id}/varietycontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanuttypes/${pnut_type_id}/penautvarieties/${pnut_variety_id}/varietycontrols`, data);
}

export function UpdateFreightRate(flag, buy_pt_id, no_load,data) {

    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFreightRate/flags/${flag}/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/no-loads/${no_load}/freightrates` : `${systemmaintenance_url_api}flags/${flag}/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/no-loads/${no_load}/freightrates`, data);
}
export function RetrieveFreightRateDetails(buy_pt_id,frt_scac_id,effective_date){
    let optionsParamsURL='';
    if (frt_scac_id != null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionsParamsURL += `frt_scac_id=${frt_scac_id}&`;
    }
    if (effective_date != null && effective_date !== '' && effective_date !== undefined) {
        optionsParamsURL += `effective_date=${effective_date}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }

    return ApiService.getData(isLocal()? `/SystemMaintenance/RetrieveFreightRateDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/freightrates?${optionsParamsURL}`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/freightrates?${optionsParamsURL}`)
}
export function RetrieveFuelSurchargeList(fsc_scac_id, fsc_effective_date) {
    let optionalParamsUrl = '';
    if (fsc_scac_id != null && fsc_scac_id !== '' && fsc_scac_id !== undefined) {
        optionalParamsUrl += `fsc_scac_id=${fsc_scac_id}&`;
    }
    if (fsc_effective_date != null && fsc_effective_date !== '' && fsc_effective_date !== undefined) {
        optionalParamsUrl += `fsc_effective_date=${fsc_effective_date}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveFuelSurchargeList/fuelsurchargelists?${optionalParamsUrl}` : `${systemmaintenance_url_api}/fuelsurchargelists?${optionalParamsUrl}`)
}

export function RetrieveFarmGovDetails(buy_pt_id,request_type){
    return ApiService.getData(isLocal()?`/SystemMaintenance/RetrieveFarmGovDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/farms?request_type=${request_type}`
                                        :`${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/farms?request_type=${request_type}`)
}

export function RetrieveContractPriceLimitDetails(data,tags) {
    let optionalParamsUrl = '';
    if (data.buy_pt_id !== null && data.buy_pt_id !== undefined && data.buy_pt_id !== "") {
      optionalParamsUrl += `buy_pt_id=${data.buy_pt_id}&`
    }
    if (data.area_id !== null && data.area_id !== undefined && data.area_id !== "") {
      optionalParamsUrl += `area_id=${data.area_id}&`
    }
    if (data.coll_pt_id !== null && data.coll_pt_id !== undefined && data.coll_pt_id !== "") {
      optionalParamsUrl += `coll_pt_id=${data.coll_pt_id}&`
    }
    if (data.vendor_num !== null && data.vendor_num !== undefined && data.vendor_num !== "") {
      optionalParamsUrl += `vendor_num=${data.vendor_num}&`
    }
    if (data.contract_num !== null && data.contract_num !== undefined && data.contract_num !== "") {
      optionalParamsUrl += `contract_num=${data.contract_num}&`
    }
    if (data.pricing_type !== null && data.pricing_type !== undefined && data.pricing_type !== "") {
      optionalParamsUrl += `pricing_type=${data.pricing_type}&`
    }
    if (data.pnut_type_id !== null && data.pnut_type_id !== undefined && data.pnut_type_id !== "") {
      optionalParamsUrl += `pnut_type_id=${data.pnut_type_id}&`
    }
    if (data.pnut_variety_id !== null && data.pnut_variety_id !== undefined && data.pnut_variety_id !== "") {
      optionalParamsUrl += `pnut_variety_id=${data.pnut_variety_id}&`
    }
    if (data.seg_type !== null && data.seg_type !== undefined && data.seg_type !== "") {
      optionalParamsUrl += `seg_type=${data.seg_type}&`
    }
    if (data.oleic_ind !== null && data.oleic_ind !== undefined && data.oleic_ind !== "") {
      optionalParamsUrl += `oleic_ind=${data.oleic_ind}&`
    }
    if (data.organic_ind !== null && data.organic_ind !== undefined && data.organic_ind !== "") {
      optionalParamsUrl += `organic_ind=${data.organic_ind}&`
    }
    if (data.record_key !== null && data.record_key !== undefined && data.record_key !== "") {
        optionalParamsUrl += `record_key=${data.record_key}&`
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveContractPriceLimitDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/select-tags/${tags}/pricelimits?${optionalParamsUrl}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/select-tags/${tags}/pricelimits?${optionalParamsUrl}`)
  }

  export function RetrieveWareHouseBinInventoryCopyDetails(buy_pt_id, from_warehouse,new_buy_pts) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveWareHouseBinInventoryCopyDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/ware-houses/${from_warehouse}/new-buying-points/${new_buy_pts}/users/${useridFromLS()}/bininventories` : `${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/ware-houses/${from_warehouse}/new-buying-points/${new_buy_pts}/users/${useridFromLS()}/bininventories`)
}
export function CreateCollectionPointControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateCollectionPointControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/collectionpointcontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/collectionpointcontrols`, data);
}
export function UpdateCollectionPointControl(coll_pt_id,data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateCollectionPointControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/users/${useridFromLS()}/collectionpointcontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/users/${useridFromLS()}/collectionpointcontrols`, data);}
   

export function RetrieveInternetTypeFormatControlDetails(){
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveInternetTypeFormatControlDetails/internettypeformats` : `${systemmaintenance_url_api}/internettypeformats`)
}

export function RetrieveFreightLocationToLocationDetails(newcrop_year,orig_buy_pt_id,dest_buy_pt_id,orig_farm,orig_farm_suffix,orig_area_id,dest_area_id,orig_coll_pt_id,dest_coll_pt_id,orig_county,orig_state,datecondition,effectivedate){
    let optionsParamsURL='';
    if (newcrop_year != null && newcrop_year !== '' && newcrop_year !== undefined) {
        optionsParamsURL += `newcrop_year=${newcrop_year}&`;
    }
    if (orig_buy_pt_id != null && orig_buy_pt_id !== '' && orig_buy_pt_id !== undefined) {
        optionsParamsURL += `orig_buy_pt_id=${orig_buy_pt_id}&`;
    }
    if (dest_buy_pt_id != null && dest_buy_pt_id !== '' && dest_buy_pt_id !== undefined) {
        optionsParamsURL += `dest_buy_pt_id=${dest_buy_pt_id}&`;
    }
    if (orig_farm != null && orig_farm !== '' && orig_farm !== undefined) {
        optionsParamsURL += `orig_farm=${orig_farm}&`;
    }
    if (orig_farm_suffix != null && orig_farm_suffix !== '' && orig_farm_suffix !== undefined) {
        optionsParamsURL += `orig_farm_suffix=${orig_farm_suffix}&`;
    }
    if (orig_area_id != null && orig_area_id !== '' && orig_area_id !== undefined) {
        optionsParamsURL += `orig_area_id=${orig_area_id}&`;
    }
    
    if (dest_area_id != null && dest_area_id !== '' && dest_area_id !== undefined) {
        optionsParamsURL += `dest_area_id=${dest_area_id}&`;
    }
    if (orig_coll_pt_id != null && orig_coll_pt_id !== '' && orig_coll_pt_id !== undefined) {
        optionsParamsURL += `orig_coll_pt_id=${orig_coll_pt_id}&`;
    }
    if (dest_coll_pt_id != null && dest_coll_pt_id !== '' && dest_coll_pt_id !== undefined) {
        optionsParamsURL += `dest_coll_pt_id=${dest_coll_pt_id}&`;
    }

    if (orig_county != null && orig_county !== '' && orig_county !== undefined) {
        optionsParamsURL += `orig_county=${orig_county}&`;
    }
    if (orig_state != null && orig_state !== '' && orig_state !== undefined) {
        optionsParamsURL += `orig_state=${orig_state}&`;
    }

    if (datecondition != null && datecondition !== '' && datecondition !== undefined) {
        optionsParamsURL += `datecondition=${datecondition}&`;
    }
    if (effectivedate != null && effectivedate !== '' && effectivedate !== undefined) {
        optionsParamsURL += `effectivedate=${effectivedate}&`;
    }

    return ApiService.getData(isLocal()? `/SystemMaintenance/RetrieveFreightLocationToLocationDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/freightlocations?${optionsParamsURL}`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/freightlocations?${optionsParamsURL}`)
}

export function RetrieveDeliveryPeriodControlDetails(comp_id,crop_year,buy_pt_id, area_id, delivery_id) {
    let optionsParamsURL = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionsParamsURL += `comp_id=${compIdFromLS()}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionsParamsURL += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }
    if (area_id != null && area_id !== '' && area_id !== undefined) {
        optionsParamsURL += `area_id=${area_id}&`;
    }
	 if (delivery_id != null && delivery_id !== '' && delivery_id !== undefined) {
        optionsParamsURL += `delivery_id=${delivery_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveDeliveryPeriodControlDetails/controls?${optionsParamsURL}`
    : `${systemmaintenance_url_api}controls?${optionsParamsURL}`)
}
export function CreateAreaControls(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateAreaControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areacontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areacontrols`, data)
} 
export function UpdateAreaControls(area_id,data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateAreaControls/areas/${area_id}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areacontrols`: 
   `${systemmaintenance_url_api}areas/${area_id}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areacontrols`, data);
}

export function CreatePeanutSpecification(buy_pt_id, data) {
    return ApiService.postData(isLocal()? `/SystemMaintenance/CreatePeanutSpecification/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanutspecifications`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanutspecifications`, data);
}

export function UpdatePeanutSpecification(buy_pt_id, area_id, pnut_type_id, seed_ind, seg_type, edible_oil_ind, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePeanutSpecification/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/seed-indicators/${seed_ind}/segment-types/${seg_type}/edible-oil-indicators/${edible_oil_ind}/peanutspecifications`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/seed-indicators/${seed_ind}/segment-types/${seg_type}/edible-oil-indicators/${edible_oil_ind}/peanutspecifications`, data);
}
    
export function CreatePremiumDeductionVendor(data) {
    data.comp_id = compIdFromLS();
    data.crop_year = cropYearFromLS() ? parseInt(cropYearFromLS()) : null;
    data.user_id = useridFromLS();
    return ApiService.postData(isLocal()? `/SystemMaintenance/CreatePremiumDeductionVendor/deductionvendors`
        : `${systemmaintenance_url_api}deductionvendors`, data);
}

export function CreateBuyingPointControl(coll_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateBuyingPointControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/users/${useridFromLS()}/buyingpoints` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/collection-points/${coll_pt_id}/users/${useridFromLS()}/buyingpoints`, data);
}

export function UpdateFreightLocationToLocationDetails(tag,cropyear, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFreightLocationToLocationDetails/companies/${compIdFromLS()}/crop-years/${cropyear}/modify-tags/${tag}/locations` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropyear}/modify-tags/${tag}/locations`, data);
}
export function RemoveWarehouseLicenseControlDetails(buy_pt_id, fed_whouse_license) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveWarehouseLicenseControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/warehouse-licenses/${fed_whouse_license}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/warehouse-licenses/${fed_whouse_license}`
    )
}
export function RetrieveIntrestAgreementDetails(comp_id, crop_year, buy_pt_id, agree_num) {
    let optionalParamsUrl = ``
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    if (agree_num != null && agree_num !== '' && agree_num !== undefined) {
        optionalParamsUrl += `agree_num=${agree_num}&`;
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveIntrestAgreementDetails/intrestagreements?${optionalParamsUrl}
      `: `${systemmaintenance_url_api}intrestagreements?${optionalParamsUrl}`)
}
export function RemoveIntrestAgreement(buy_pt_id,agree_num,agree_vendor) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveIntrestAgreement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-pointsd/${buy_pt_id}/agreement_numbers/${agree_num}/agreement_vendors/${agree_vendor}/users/${useridFromLS()}` :
        `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-pointsd/${buy_pt_id}/agreement_numbers/${agree_num}/agreement_vendors/${agree_vendor}/users/${useridFromLS()}`)
}
export function RetrieveCopyFrieghtRateDetails(buy_pt_id, effective_date, new_buy_pts, frt_scac_id, no_load_ind_old,no_load_ind_new,frt_vendor,rate_basis,min_weight,user_id) {
    let optionsParamsURL = '';
    if (effective_date != null && effective_date !== '' && effective_date !== undefined) {
        optionsParamsURL += `effective_date=${effective_date}&`;
    }
    if (new_buy_pts != null && new_buy_pts !== '' && new_buy_pts !== undefined) {
        optionsParamsURL += `new_buy_pts=${new_buy_pts}&`;
    }
    if (frt_scac_id != null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionsParamsURL += `frt_scac_id=${frt_scac_id}&`;
    }
    if (no_load_ind_old != null && no_load_ind_old !== '' && no_load_ind_old !== undefined) {
        optionsParamsURL += `no_load_ind_old=${no_load_ind_old}&`;
    }
    if (no_load_ind_new != null && no_load_ind_new !== '' && no_load_ind_new !== undefined) {
        optionsParamsURL += `no_load_ind_new=${no_load_ind_new}&`;
    }
    if (frt_vendor != null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionsParamsURL += `frt_vendor=${frt_vendor}&`;
    }
    if (rate_basis != null && rate_basis !== '' && rate_basis !== undefined) {
        optionsParamsURL += `rate_basis=${rate_basis}&`;
    }
    if (min_weight != null && min_weight !== '' && min_weight !== undefined) {
        optionsParamsURL += `min_weight=${min_weight}&`;
    }
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionsParamsURL += `user_id=${user_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveCopyFrieghtRateDetails/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/copyfreightsrates?${optionsParamsURL}` : 
    `${systemmaintenance_url_api}companies/${compIdFromLS()}/buying-points/${buy_pt_id}/copyfreightsrates?${optionsParamsURL}`);

}
export function RetrieveCopyFrieghtRateLocationDetails(buy_pt_id, new_buy_pts,user_id) {
    let optionsParamsURL = '';
    if (new_buy_pts != null && new_buy_pts !== '' && new_buy_pts !== undefined) {
        optionsParamsURL += `new_buy_pts=${new_buy_pts}&`;
    }
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionsParamsURL += `user_id=${user_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveCopyFrieghtRateLocationDetails/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/copyfreightsratelocations?${optionsParamsURL}` : 
    `${systemmaintenance_url_api}companies/${compIdFromLS()}/buying-points/${buy_pt_id}/copyfreightsratelocations?${optionsParamsURL}`);

}
export function RetrieveCopyFrieghtRateCarrierDetails(buy_pt_id, from_scac_id, to_scac_id, frt_vendor, no_load_ind,new_buy_pts,user_id) {
    let optionsParamsURL = '';
    if (from_scac_id != null && from_scac_id !== '' && from_scac_id !== undefined) {
        optionsParamsURL += `from_scac_id=${from_scac_id}&`;
    }
    if (to_scac_id != null && to_scac_id !== '' && to_scac_id !== undefined) {
        optionsParamsURL += `to_scac_id=${to_scac_id}&`;
    }
    if (frt_vendor != null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionsParamsURL += `frt_vendor=${frt_vendor}&`;
    }
    if (no_load_ind != null && no_load_ind !== '' && no_load_ind !== undefined) {
        optionsParamsURL += `no_load_ind=${no_load_ind}&`;
    }
    if (new_buy_pts != null && new_buy_pts !== '' && new_buy_pts !== undefined) {
        optionsParamsURL += `new_buy_pts=${new_buy_pts}&`;
    }
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionsParamsURL += `user_id=${user_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveCopyFrieghtRateCarrierDetails/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/copyfreightsratecarriers?${optionsParamsURL}` : 
    `${systemmaintenance_url_api}companies/${compIdFromLS()}/buying-points/${buy_pt_id}/copyfreightsratecarriers?${optionsParamsURL}`);

}

export function UpdateBuyingPointControl(buy_pt_id,data) {
    return ApiService.updateData(
        isLocal()
            ? `/SystemMaintenance/UpdateBuyingPointControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/buyingpointcontrols`:
            `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/buyingpointcontrols`, data
    )
}

export function RetrieveLeasedTrailerDetails(vehicle_num) {
    let optionsParamsURL = '';
    if (vehicle_num != null && vehicle_num !== '' && vehicle_num !== undefined) {
        optionsParamsURL += `vehicle_num=${vehicle_num}`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveLeasedTrailerDetails/companies/${compIdFromLS()}/leasedtrailers?${optionsParamsURL}`: 
    `${systemmaintenance_url_api}companies/${compIdFromLS()}/leasedtrailers?${optionsParamsURL}`);
}

export function UpdateLeasedTrailer(action_type, data) {
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateLeasedTrailer/action-types/${action_type}/companies/${compIdFromLS()}/leasedtrailers`
        : `${systemmaintenance_url_api}action-types/${action_type}/companies/${compIdFromLS()}/leasedtrailers`,
            data);
}
export function UpdateContractPriceLimit(tag, record_key, data) {
    let optionsParamsURL = '';
    if(record_key !== undefined && record_key !=='' && record_key !== null ){
        optionsParamsURL = `record_key=${record_key}`;
    }
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateContractPriceLimit/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/tags/${tag}/pricelimits?${optionsParamsURL}` : 
    `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/tags/${tag}/pricelimits?${optionsParamsURL}`,data);
}

export function CreatePeanutSpecificationException(data) {
    return ApiService.postData(isLocal()? `/SystemMaintenance/CreatePeanutSpecificationException/peanutspecificationexceptions`
    : `${systemmaintenance_url_api}peanutspecificationexceptions`, data);
}

export function UpdatePeanutSpecificationException(buy_pt_id, pnut_type_id, pnut_variety_id, seed_ind, seg_type, edible_oil_ind, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePeanutSpecificationException/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/peanut-types/${pnut_type_id}/peanut-varieties/${pnut_variety_id}/seed-indicators/${seed_ind}/segment-types/${seg_type}/edible-oil-indicators/${edible_oil_ind}/specificationexceptions`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/peanut-types/${pnut_type_id}/peanut-varieties/${pnut_variety_id}/seed-indicators/${seed_ind}/segment-types/${seg_type}/edible-oil-indicators/${edible_oil_ind}/specificationexceptions`, data);
}

export function RetrieveBuyingpointControlsWarehouseLicenseDetails(comp_id, crop_year, fed_whouse_license,buy_pt_id) {
    let optionalParamsUrl = ``
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${comp_id}&`;
    }

    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${crop_year}&`;
    }

    if (fed_whouse_license != null && fed_whouse_license !== '' && fed_whouse_license !== undefined) {
        optionalParamsUrl += `fed_whouse_license=${fed_whouse_license}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }

    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveBuyingpointControlsWarehouseLicenseDetails/licenses?${optionalParamsUrl}
      `: `${systemmaintenance_url_api}licenses?${optionalParamsUrl}`)
}

export function CreateIntrestAgreement(dataObj){
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateIntrestAgreement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/intrestagreements`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/intrestagreements`
        , dataObj)
}

export function UpdateIntrestAgreement(agree_num, agree_vendor, dataObj){
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateIntrestAgreement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/agreement_numbers/${agree_num}/agree_vendors/${agree_vendor}/intrestagreements`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/agreement_numbers/${agree_num}/agree_vendors/${agree_vendor}/intrestagreements`
        , dataObj)
}
export function CreateOpenAccountControl(buy_pt_id, data){
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateOpenAccountControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/openaccounttcontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/openaccounttcontrols`, data);
}
export function RetrieveFarmerStockPriceControlDetails(area_id,pnut_type_id,effect_date_time,cdate){
    let optionalParamsUrl=``;
    if(area_id!=null && area_id!=undefined && area_id!=''){
        optionalParamsUrl += `area_id=${area_id}&`
    }
    if(pnut_type_id!=null && pnut_type_id!=undefined && pnut_type_id!=''){
        optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
    }
    if(effect_date_time!=null && effect_date_time!=undefined && effect_date_time!=''){
        optionalParamsUrl += `effect_date_time=${effect_date_time}&`
    }
    if(cdate!=null && cdate!=undefined && cdate!=''){
        optionalParamsUrl += `cdate=${cdate}&`
    }

    return ApiService.getData(isLocal()?`/SystemMaintenance/RetrieveFarmerStockPriceControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/farmerstockprices?${optionalParamsUrl}`
                                        :`${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/farmerstockprices?${optionalParamsUrl}`)
}

export function RetrieveFilterStateCountyDetails(buy_pt_id,filtertype,stateabbr) {
    let optionsParamsURL = '';
    if (stateabbr != null && stateabbr !== '' && stateabbr !== undefined) {
        optionsParamsURL += `stateabbr=${stateabbr}`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveFilterStateCountyDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/filter-types/${filtertype}/statecounties?${optionsParamsURL}`: 
    `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/filter-types/${filtertype}/statecounties?${optionsParamsURL}`);
}
export function UpdateOpenAccountControl(buy_pt_id,acct_vendor,data) {
    return ApiService.updateData(
        isLocal()
            ? `/SystemMaintenance/UpdateOpenAccountControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}/openaccounts`:
            `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}/openaccounts`, data
    )
}

export function RemoveFarmerStockPriceControlDetails(area_id,peanut_type_id,effect_date_time){
    return ApiService.removeData(isLocal()?`/SystemMaintenance/RemoveFarmerStockPriceControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${peanut_type_id}/effect-dates/${effect_date_time}`
                                            :`${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${peanut_type_id}/effect-dates/${effect_date_time}`)
}

export function UpdateFilterStateCounty(buy_pt_id, filtertype , data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFilterStateCounty/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/filter-types/${filtertype}/statecounties`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/filter-types/${filtertype}/statecounties`, data);
}

export function RetrievePremiumDeductionContractDetails(comp_id, crop_year, buy_pt_id, pd_code, pd_contract) {
    let optionalParamsUrl = ``
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    if (pd_code != null && pd_code !== '' && pd_code !== undefined) {
        optionalParamsUrl += `pd_code=${pd_code}&`;
    }
    if (pd_contract != null && pd_contract !== '' && pd_contract !== undefined) {
        optionalParamsUrl += `pd_contract=${pd_contract}&`;
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrievePremiumDeductionContractDetails/premiumdeductioncontracts?${optionalParamsUrl}
      `: `${systemmaintenance_url_api}premiumdeductioncontracts?${optionalParamsUrl}`)
}

export function RetrievePremiumDeductionVendorDetails(comp_id, crop_year, buy_pt_id, pd_code, pd_vendor) {
    let optionalParamsUrl = ``
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    if (pd_code != null && pd_code !== '' && pd_code !== undefined) {
        optionalParamsUrl += `pd_code=${pd_code}&`;
    }
    if (pd_vendor != null && pd_vendor !== '' && pd_vendor !== undefined) {
        optionalParamsUrl += `pd_vendor=${pd_vendor}&`;
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrievePremiumDeductionVendorDetails/premiumdeductionvendors?${optionalParamsUrl}
      `: `${systemmaintenance_url_api}premiumdeductionvendors?${optionalParamsUrl}`)
}

export function RetrievePremiumDeductionScheduleDetails(comp_id, crop_year, buy_pt_id, pd_code, inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind) {
    let optionalParamsUrl = ``
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    if (pd_code != null && pd_code !== '' && pd_code !== undefined) {
        optionalParamsUrl += `pd_code=${pd_code}&`;
    }
    if (inspect_ind != null && inspect_ind !== '' && inspect_ind !== undefined) {
        optionalParamsUrl += `inspect_ind=${inspect_ind}&`;
    }
    if (rate_type != null && rate_type !== '' && rate_type !== undefined) {
        optionalParamsUrl += `rate_type=${rate_type}&`;
    }
    if (check_enable_ind != null && check_enable_ind !== '' && check_enable_ind !== undefined) {
        optionalParamsUrl += `check_enable_ind=${check_enable_ind}&`;
    }
    if (rcpt_enable_ind != null && rcpt_enable_ind !== '' && rcpt_enable_ind !== undefined) {
        optionalParamsUrl += `rcpt_enable_ind=${rcpt_enable_ind}&`;
    }
    if (rpt_1099_ind != null && rpt_1099_ind !== '' && rpt_1099_ind !== undefined) {
        optionalParamsUrl += `rpt_1099_ind=${rpt_1099_ind}&`;
    }
    if (except_prem_ind != null && except_prem_ind !== '' && except_prem_ind !== undefined) {
        optionalParamsUrl += `except_prem_ind=${except_prem_ind}&`;
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrievePremiumDeductionScheduleDetails/premiumdeductionschedules?${optionalParamsUrl}
      `: `${systemmaintenance_url_api}premiumdeductionschedules?${optionalParamsUrl}`)
}

export function RetrieveShrinkControlDetails(fed_whouse_license, pnut_type_id) {
    let optionalParamsUrl = '';
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    
    if(pnut_type_id!=null && pnut_type_id!=undefined && pnut_type_id!=''){
        optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
    }
    if(fed_whouse_license!=null && fed_whouse_license!=undefined && fed_whouse_license!=''){
        optionalParamsUrl += `fed_whouse_license=${fed_whouse_license}&`;
    }
    
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveShrinkControlDetails/shrinkcontrols?${optionalParamsUrl}` : `${systemmaintenance_url_api}shrinkcontrols?${optionalParamsUrl}`);

}

export function RetrievePeanutVarietyFilterDetails(comp_id,crop_year,buy_pt_id,pnut_type_id) {
    let optionsParamsURL = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionsParamsURL += `comp_id=${comp_id}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionsParamsURL += `crop_year=${crop_year}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }
    if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
        optionsParamsURL += `pnut_type_id=${pnut_type_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrievePeanutVarietyFilterDetails/peanutvarietyfilters?${optionsParamsURL}` : 
    `${systemmaintenance_url_api}peanutvarietyfilters?${optionsParamsURL}`);

}

export function UpdatePeanutVarietyFilter(data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePeanutVarietyFilter/peanutvarietyfilters`
    : `${systemmaintenance_url_api}peanutvarietyfilters`, data);
}

export function CreateShrinkControl(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateShrinkControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/shrinkcontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/shrinkcontrols`, data
    );
}

export function CreateWarehouseLicenseControl(buy_pt_id,fed_whouse_license, obj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateWarehouseLicenseControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/fed-warehouse-licenses/${fed_whouse_license}/warehouselicensecontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/fed-warehouse-licenses/${fed_whouse_license}/warehouselicensecontrols`, obj
    );
}
export function RemoveOpenAccountControlDetails(buy_pt_id,acct_vendor){
    return ApiService.removeData(isLocal()?`/SystemMaintenance/RemoveOpenAccountControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}`
                                            :`${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}`)
}

export function UpdateWhouseLicenseControl(Obj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateWhouseLicenseControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/whouselicensecontrols` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/whouselicensecontrols`, Obj);
}

export function RemovePeanutSpecification(buy_pt_id, area_id,pnut_type_id,seed_ind,seg_type,edible_oil_ind) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePeanutSpecification/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/seed-indicators/${seed_ind}/segment-types/${seg_type}/edible-oil-indicators/${edible_oil_ind}/specifications`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/seed-indicators/${seed_ind}/segment-types/${seg_type}/edible-oil-indicators/${edible_oil_ind}/specifications`
    )
}

export function CreatePremiumDeductSchedule(data) {
    return ApiService.postData(isLocal()? `/SystemMaintenance/CreatePremiumDeductSchedule/premiumdeductschedules`
        : `${systemmaintenance_url_api}premiumdeductschedules`, data);
}

export function UpdatePremiumDeductSchedule(buy_pt_id, pd_code, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePremiumDeductSchedule/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/pd-codes/${pd_code}/premiumdeductschedules`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/pd-codes/${pd_code}/premiumdeductschedules`, data);
}

export function RemovePeanutSpecificationException(buy_pt_id, user_id,pnut_variety_id,pnut_type_id,seed_ind,seg_type,edible_oil_ind) {
    let optionsParamsURL = '';
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionsParamsURL += `user_id=${user_id}&`;
    }
    if (pnut_variety_id != null && pnut_variety_id !== '' && pnut_variety_id !== undefined) {
        optionsParamsURL += `pnut_variety_id=${pnut_variety_id}&`;
    }
    if (seed_ind != null && seed_ind !== '' && seed_ind !== undefined) {
        optionsParamsURL += `seed_ind=${seed_ind}&`;
    }
    if (seg_type != null && seg_type !== '' && seg_type !== undefined) {
        optionsParamsURL += `seg_type=${seg_type}&`;
    }
    if (edible_oil_ind != null && edible_oil_ind !== '' && edible_oil_ind !== undefined) {
        optionsParamsURL += `edible_oil_ind=${edible_oil_ind}&`;
    }
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePeanutSpecificationException/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/peanut-types/${pnut_type_id}/specifications?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/peanut-types/${pnut_type_id}/specifications?${optionsParamsURL}`
    )
}

export function RemovePremiumDeductionVendorDetails(buy_pt_id, pd_code, pd_vendor){
    return ApiService.removeData(isLocal()?`/SystemMaintenance/RemovePremiumDeductionVendorDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/codes/${pd_code}/vendors/${pd_vendor}`
    :`${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/codes/${pd_code}/vendors/${pd_vendor}`)
}

export function RemovePremiumDeductionContractDetails(buy_pt_id, pd_code, pd_contract){
    return ApiService.removeData(isLocal()?`/SystemMaintenance/RemovePremiumDeductionContractDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/codes/${pd_code}/contracts/${pd_contract}`
    :`${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}/codes/${pd_code}/contracts/${pd_contract}`)
}
export function RetrieveFreightMileageChartDetails(buy_pt_id, nl_ind, frt_scac_id, effective_date) {
    let optionsParamsURL = '';
    if (frt_scac_id != null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionsParamsURL += `frt_scac_id=${frt_scac_id}&`;}
    if (effective_date != null && effective_date !== '' && effective_date !== undefined) {
        optionsParamsURL += `effective_date=${effective_date}&`;
    } 
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveFreightMileageChartDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/no-load-ind/${nl_ind}/freightmileagecharts?${optionsParamsURL}`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/no-load-ind/${nl_ind}/freightmileagecharts?${optionsParamsURL}`);
}
export function RetrieveManualAccountingDistribution(action_type, buy_pt_id,
    dist_account, dist_resp, dist_dept, dist_sub_dept, dist_loc, dist_amount, dist_quantity,
    audit_check_num, audit_receivable_num, audit_cont_num, audit_insp_num, audit_settle_num,
    audit_whse_rcpt, audit_xfer_num, audit_trade_insp, audit_trade_settle, audit_delv_agree
) {
    let optionsParamsURL = '';

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }
    if (dist_account !== null && dist_account !== '' && dist_account !== undefined) {
        optionsParamsURL += `dist_account=${dist_account}&`;
    }
    if (dist_resp !== null && dist_resp !== '' && dist_resp !== undefined) {
        optionsParamsURL += `dist_resp=${dist_resp}&`;
    }
    if (dist_dept !== null && dist_dept !== '' && dist_dept !== undefined) {
        optionsParamsURL += `dist_dept=${dist_dept}&`;
    }
    if (dist_sub_dept !== null && dist_sub_dept !== '' && dist_sub_dept !== undefined) {
        optionsParamsURL += `dist_sub_dept=${dist_sub_dept}&`;
    }
    if (dist_loc !== null && dist_loc !== '' && dist_loc !== undefined) {
        optionsParamsURL += `dist_loc=${dist_loc}&`;
    }
    if (dist_amount !== null && dist_amount !== '' && dist_amount !== undefined) {
        optionsParamsURL += `dist_amount=${dist_amount}&`;
    }
    if (dist_quantity !== null && dist_quantity !== '' && dist_quantity !== undefined) {
        optionsParamsURL += `dist_quantity=${dist_quantity}&`;
    }
    if (audit_check_num !== null && audit_check_num !== '' && audit_check_num !== undefined) {
        optionsParamsURL += `audit_check_num=${audit_check_num}&`;
    }
    if (audit_receivable_num !== null && audit_receivable_num !== '' && audit_receivable_num !== undefined) {
        optionsParamsURL += `audit_receivable_num=${audit_receivable_num}&`;
    }
    if (audit_cont_num !== null && audit_cont_num !== '' && audit_cont_num !== undefined) {
        optionsParamsURL += `audit_cont_num=${audit_cont_num}&`;
    }
    if (audit_insp_num !== null && audit_insp_num !== '' && audit_insp_num !== undefined) {
        optionsParamsURL += `audit_insp_num=${audit_insp_num}&`;
    }
    if (audit_settle_num !== null && audit_settle_num !== '' && audit_settle_num !== undefined) {
        optionsParamsURL += `audit_settle_num=${audit_settle_num}&`;
    }
    if (audit_whse_rcpt !== null && audit_whse_rcpt !== '' && audit_whse_rcpt !== undefined) {
        optionsParamsURL += `audit_whse_rcpt=${audit_whse_rcpt}&`;
    }
    if (audit_xfer_num !== null && audit_xfer_num !== '' && audit_xfer_num !== undefined) {
        optionsParamsURL += `audit_xfer_num=${audit_xfer_num}&`;
    }
    if (audit_trade_insp !== null && audit_trade_insp !== '' && audit_trade_insp !== undefined) {
        optionsParamsURL += `audit_trade_insp=${audit_trade_insp}&`;
    }
    if (audit_trade_settle !== null && audit_trade_settle !== '' && audit_trade_settle !== undefined) {
        optionsParamsURL += `audit_trade_settle=${audit_trade_settle}&`;
    }
    if (audit_delv_agree !== null && audit_delv_agree !== '' && audit_delv_agree !== undefined) {
        optionsParamsURL += `audit_delv_agree=${audit_delv_agree}&`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualAccountingDistribution/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/manualaccountdistributions?${optionsParamsURL}`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/manualaccountdistributions?${optionsParamsURL}`);
}
export function RetrieveCmaDmaControlDetails(state_id, county_id,
    vendor_num, state_abbr, cma_ind, dma_ind, apply_deduct_ind
) {
    let optionsParamsURL = '';

    if (state_id !== null && state_id !== '' && state_id !== undefined) {
        optionsParamsURL += `state_id=${state_id}&`;
    }
    if (county_id !== null && county_id !== '' && county_id !== undefined) {
        optionsParamsURL += `county_id=${county_id}&`;
    }
    if (vendor_num !== null && vendor_num !== '' && vendor_num !== undefined) {
        optionsParamsURL += `vendor_num=${vendor_num}&`;
    }
    if (state_abbr !== null && state_abbr !== '' && state_abbr !== undefined) {
        optionsParamsURL += `state_abbr=${state_abbr}&`;
    }
    if (cma_ind !== null && cma_ind !== '' && cma_ind !== undefined) {
        optionsParamsURL += `cma_ind=${cma_ind}&`;
    }
    if (dma_ind !== null && dma_ind !== '' && dma_ind !== undefined) {
        optionsParamsURL += `dma_ind=${dma_ind}&`;
    }
    if (apply_deduct_ind !== null && apply_deduct_ind !== '' && apply_deduct_ind !== undefined) {
        optionsParamsURL += `apply_deduct_ind=${apply_deduct_ind}&`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveCmaDmaControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/cmadmacontrols?${optionsParamsURL}`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/cmadmacontrols?${optionsParamsURL}`);
}
export function RemoveCmaDmaControlDetails(buy_pt_id, state_id, county_id, vendor_num) {
    return ApiService.removeData(isLocal()
    ? `/SystemMaintenance/RemoveCmaDmaControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/states/${state_id}/counties/${county_id}/vendors/${vendor_num}`
    : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/states/${state_id}/counties/${county_id}/vendors/${vendor_num}`);
}
export function UpdateTradeCategory(action_type, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateTradeCategory/action-types/${action_type}/tradecategories`
    : `${systemmaintenance_url_api}action-types/${action_type}/tradecategories`, data);   
}