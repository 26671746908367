/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  goTo,
  setData,
  getData,
  disable,
  enable,
  getValue,
} from "../../shared/WindowImports";

import "./SecurityFunctionSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityFunctionSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let lstFunctionID = []
  let lstFuncSub = []
  let lstFuncName = []
  let lstLocationFS = []
  let lstLocationIds = []
  const compIdFromLS = sessionStorage.getItem('compId');
  const useridFromLS = sessionStorage.getItem('userid')
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityFunctionSelect",
    windowName: "SecurityFunctionSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityFunctionSelect",
    // START_USER_CODE-USER_SecurityFunctionSelect_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Security Location Select",
      scrCode: "PN0060H",
    },
    // END_USER_CODE-USER_SecurityFunctionSelect_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    ddAccessLevel: {
      name: "ddAccessLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Access Level:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccessLevel_PROPERTIES

      // END_USER_CODE-USER_ddAccessLevel_PROPERTIES
    },
    ddFunction: {
      name: "ddFunction",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Function:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFunction_PROPERTIES

      // END_USER_CODE-USER_ddFunction_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lstLocation: {
      name: "lstLocation",
      type: "ListBoxFieldWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLocation_PROPERTIES

      // END_USER_CODE-USER_lstLocation_PROPERTIES
    },
    txtKeyInformation: {
      name: "txtKeyInformation",
      type: "TextBoxWidget",
      parent: "grpbxSecurityFunctionSelect",
      Label: "Key Information:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKeyInformation_PROPERTIES

      // END_USER_CODE-USER_txtKeyInformation_PROPERTIES
    },
    grpbxSecurityFunctionSelect: {
      name: "grpbxSecurityFunctionSelect",
      type: "GroupBoxWidget",
      parent: "SecurityFunctionSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSecurityFunctionSelect_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityFunctionSelect_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityFunctionSelect",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#SecurityFunctionLocationSelect": {},
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#SecurityFunctionLocationSelect": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  //  btnAdd:{
  //   DEFAULT: ["SystemMaintenanceSpecialFunctions#SecurityFunctionLocationSelect#DEFAULT#true#Click"],
  // },
  // btnEdit: {
  //   DEFAULT: ["SystemMaintenanceSpecialFunctions#SecurityFunctionLocationSelect#DEFAULT#true#Click"],
  // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  useEffect(() => {
    return () => {
      setData(thisObj, "closingOfSecurityFunctionSelect", true)
    }
  },[])

  useEffect(() => {
    FormRefresh()
  },[getData(thisObj, 'frmSecurtyFunctionSelectrefresh')])

  async function FormRefresh(){
    if(getData(thisObj, 'frmSecurtyFunctionSelectrefresh')){
      await bFillLocationList_Update()
      await EnableDisableControls()
    }
    setData(thisObj, 'frmSecurtyFunctionSelectrefresh', false)
  }

  const formLoad = async () => {
    try{
      let screenData = getData(thisObj, 'frmSecurityFunctionSelect')
      thisObj.values.lstLocation = []
      await bFillFunctionList()
      await bFillAccessLevelList()
      disable(thisObj, 'txtKeyInformation')
      setData(thisObj, "closingOfSecurityFunctionSelect", false)

      if(screenData?.cmdOk_Caption == "Update"){
        disable(thisObj, 'ddFunction')
        thisObj.values.txtKeyInformation = screenData?.security_group
        thisObj.values.ddFunction = `${screenData?.sFuncID}/${screenData?.sSubFuncID}`
        thisObj.values.ddAccessLevel = screenData?.sAccessLevel
        thisObj.values.lblAddedByValue = screenData?.lblAddedBy
        thisObj.values.lblChangedByValue = screenData?.lblChangedBy
        document.getElementById('btnUpdate').innerHTML = "Update"
        await bFillLocationList_Update()
      }
      else if(screenData?.cmdOk_Caption == "Add"){
        enable(thisObj, 'ddFunction')
        thisObj.values.txtKeyInformation = screenData?.security_group
        thisObj.values.lblAddedByValue = ""
        thisObj.values.lblChangedByValue = ""
        document.getElementById('btnUpdate').innerHTML = "Add"
        await bFillLocationList_Update()
        disable(thisObj, 'lstFunction')
      }
      
      await EnableDisableControls()
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }

  async function bFillFunctionList(){
    try{
      let js = []
      let obj = {}
      let funcIdList = []
      let funcSubList = []
      let funcNameList = []
      let bFillFunctionListBool = false

      let LstrList = await SystemMaintenanceSpecialFunctionsService.RetrieveFunctionControlDetails(null, null)
      if(LstrList?.status !== 500){
        LstrList?.forEach(item => {
          if(item.strFieldName == "FUNC_ID"){
            obj = {}
            obj.func_id = item.strFieldData
            funcIdList.push(obj?.func_id)
          }
          if(item.strFieldName == "FUNC_SUB_ID"){
            obj.func_sub_id = item.strFieldData
            funcSubList.push(obj?.func_sub_id)
          }
          if(item.strFieldName == "FUNC_NAME"){
            obj.func_name = item.strFieldData
            funcNameList.push(obj?.func_name)
            js.push({
              key : obj?.func_id + "/" + obj?.func_sub_id,
              description : obj?.func_id + "." + obj?.func_sub_id + " - " + obj?.func_name
            })
          }
        })        

        bFillFunctionListBool = true
      }
      
      setData(thisObj, 'lstFunctionID', funcIdList)
      setData(thisObj, 'lstFuncSub', funcSubList)
      setData(thisObj, 'lstFuncName', funcNameList)
      setData(thisObj, 'ddFunctions_JS', js)

      thisObj.setState(current => {
        return {
          ...current,
          ddFunction: {
            ...state["ddFunction"],
            valueList : js
          }
        }
      })
      thisObj.values.ddFunction = js.length > 0 ? js[0].key : ''

      return bFillFunctionListBool;
    }
    catch(err){
      errorHandler(err)
      return false
    }
  }

  async function bFillAccessLevelList(){
    try{
      let js = [ 
        { key : "I" , description : "Inquiry" },
        { key : "U" , description : "Update" },
        { key : "D" , description : "Delete" },
        { key : "X" , description : "Revoke" }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddAccessLevel: {
            ...state["ddAccessLevel"],
            valueList : js
          }
        }
      })
      thisObj.values.ddAccessLevel = js[0].key

      return true
    } 
    catch(err){
      errorHandler(err)
      return false
    }
  }

  async function bFillLocationList_Update(){
    try{
      let js = []
      let LstrList = []
      let locationList = []
      let locationIdList = []
      let bFillLocationList_UpdateBool = false;  
      
      let funcDetails = thisObj.values.ddFunction
      let selectedFuncId = funcDetails?.split("/")[0]?.trim()
      let selectedFuncSubId = funcDetails?.split("/")[1]?.trim()
      let security_group = getValue(thisObj, 'txtKeyInformation')
      
      LstrList = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupFunctionLocationNameDetails(selectedFuncId, 'PN0060', security_group, selectedFuncSubId, null)
      if(LstrList.status !== 500){
        LstrList?.forEach(item => {
          js.push({
            key         : item?.buy_pt_id + "/" + item?.access_level,
            description : item?.buy_pt_id + " (" + item?.access_level + ") - " + item?.buy_pt_name
          })
          locationList.push(item?.buy_pt_id + " (" + item?.access_level + ") - " + item?.buy_pt_name)
          locationIdList.push(item?.buy_pt_id + "/" + item?.access_level)
        })
        bFillLocationList_UpdateBool = true
      }

      setData(thisObj, 'lstLocationFS', locationList)
      setData(thisObj, 'lstLocationIds', locationIdList)
      setData(thisObj, 'lstLocation_Js', js)

      thisObj.setState(current => {
        return {
          ...current,
          lstLocation: {
            ...state["lstLocation"],
            valueList: js
          }
        }
      })
      thisObj.values.lstLocation = js.length > 0 ? [js[0].key] : ''

      return bFillLocationList_UpdateBool      
    }
    catch(err){
      errorHandler(err)
      return false
    }
  }

  async function EnableDisableControls(){
    try{
      if(getData(thisObj, 'frmSecurityFunctionSelect')?.cmdOk_Caption == "Add"){
        disable(thisObj, 'lstLocation')
        disable(thisObj, 'btnAdd')
        disable(thisObj, 'btnDelete')
        disable(thisObj, 'btnEdit')
      }
      else{
        enable(thisObj, 'btnAdd')
        if(thisObj.values.lstLocation?.length > 0){
          enable(thisObj, 'lstLocation')
          enable(thisObj, 'btnDelete')
          enable(thisObj, 'btnEdit')
        }
        else{
          disable(thisObj, 'lstLocation')
          disable(thisObj, 'btnDelete')
          disable(thisObj, 'btnEdit')
        }
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  const ontxtKeyInformationChange = async() => {
    try{
      if(! await bFillFunctionList()){
        return
      }

      if(! await bFillAccessLevelList()){
        return
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtKeyInformationChange = ontxtKeyInformationChange;

  const onddFunctionChange = async() => {
    try{
      await bFillLocationList_Update()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddFunctionChange = onddFunctionChange;

  const onbtnAddClick = async() => {
    try{
      let Js_arr = getData(thisObj, 'ddFunctions_JS')
      let userId = thisObj.values.txtKeyInformation?.split(" - ")[0]      
      let funcId = thisObj.values.ddFunction?.split("/")[0]?.trim() 
      let funcSubId = thisObj.values.ddFunction?.split("/")[1]?.trim()
      let ddFun_key = getValue(thisObj, 'ddFunction')

      let obj = {
        "lblKeyInformationTag"     : `${userId}|${funcId}|${funcSubId}`,
        "lblKeyInformationCaption" : thisObj.values.txtKeyInformation + ' - ' + Js_arr.find(ele => ele.key == ddFun_key).description,
        "cmdOkCaption"             : "Add"
      }
      setData(thisObj, "frmSecurityFunctionLocationSelect", obj);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityFunctionLocationSelect#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  const onbtnEditClick = async() => {
    try{ 
      let Js_arr = getData(thisObj, 'ddFunctions_JS')     
      let locationDetail = (thisObj.values.lstLocation).toString().split('/')[0]
      let security_group = getValue(thisObj, 'txtKeyInformation')
      let funcId = thisObj.values.ddFunction?.split("/")[0]?.trim()
      let funcSubId = thisObj.values.ddFunction?.split("/")[1]?.trim()
      let buyingPt = locationDetail?.split("(")[0]?.trim()
      let ddFun_key = getValue(thisObj, 'ddFunction')

      let LstrList = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupFunctionLocTransferDetails(compIdFromLS, buyingPt, funcId, funcSubId, security_group)
      if(LstrList.status == 500){
        return
      }      

      let obj = {
        "lblKeyInformationTag"     : `${security_group}|${funcId}|${funcSubId}`,
        "lblKeyInformationCaption" : thisObj.values.txtKeyInformation + ' - ' + Js_arr.find(ele => ele.key == ddFun_key).description,
        "cmdOkCaption"             : "Edit",
        "lstBuyingPointID"         : LstrList[0]?.buy_pt_id,
        "lstAccessLevelId"         : LstrList[0]?.access_level,
        "lblAddedByCaption" : "  " + LstrList[0]?.add_user + " " + ConvertDateTime(LstrList[0]?.add_date_time),
        "lblChangedByCaption" : "  " + LstrList[0]?.chg_user + " " + ConvertDateTime(LstrList[0]?.chg_date_time),
        "cboBuyingPoint_enabled"   : false
      }
      setData(thisObj, "frmSecurityFunctionLocationSelect", obj);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityFunctionLocationSelect#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  const onbtnDeleteClick = async() => {
    try{
      let js = []
      let js1 = []
      let lstrxml = []
      let Location_JS = getData(thisObj, 'lstLocation_Js')
      let buyingPt = (thisObj.values.lstLocation).toString().split('/')[0]
      let LocationDes = Location_JS.find(ele => (ele.key).split('/')[0] == buyingPt).description
      if(!confirm(`Are you sure you wish to delete this location '${LocationDes}'`)){
        return
      }

      let funcDetails = thisObj.values.ddFunction
      let funcId = funcDetails?.split("/")[0]?.trim()
      let funcSubId = funcDetails?.split("/")[1]?.trim()
      let funcSub = funcSubId == '' ? null : funcSubId
      let security_group = getValue(thisObj, 'txtKeyInformation')
      // let buyingPt = locationDetail?.split("(")[0]?.trim()
      
      lstrxml = await SystemMaintenanceSpecialFunctionsService.RemoveSecurityGroupFunctionLocTransferDetails(buyingPt, funcId, funcSub, security_group)
      if(lstrxml.status == 500){
        return
      }
      if(lstrxml.status !== 200){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\n" + lstrxml.message)
        return
      }

      let index = Location_JS.findIndex(ele => (ele.key).split('/')[0] == buyingPt)
      
      // splice will remove the element from the 'index'
      Location_JS.splice(index, 1)    
      Location_JS.forEach(location => {        
        js.push({
          key : location.description?.slice(0,3) + "/"+ location.description?.slice(5,6),
          description : location.description 
        })

        js1.push(location.description?.slice(0,3) + "/"+ location.description?.slice(5,6))
      })

      thisObj.setState(current => {
        return {
          ...current,
          lstLocation: {
            ...state["lstLocation"],
            valueList: js
          }
        }
      })
      setData(thisObj, 'lstLocation_Js', js)
      setData(thisObj, 'lstLocationIds', js1)
      if (js.length > 0) {
        if (index == 0) {
          thisObj.values.lstLocation = [js[0].key]
        } else {
          thisObj.values.lstLocation = [js[index -1].key]
        }
      } else {
        thisObj.values.lstLocation = ''
      }

      await EnableDisableControls();
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  const onbtnCancelClick = async() => {
    try{
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityFunctionSelectPopUp").childNodes[0].click()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnUpdateClick = async () => {
    try {
      let Js_arr = getData(thisObj, 'ddFunctions_JS')
      let ddFun_key = getValue(thisObj, 'ddFunction')
      let funcName = (Js_arr.find(ele => ele.key == ddFun_key).description).split('- ')[1]
      let security_group = getValue(thisObj, 'txtKeyInformation')
      let funcId = thisObj.values.ddFunction?.split("/")[0]?.trim()
      let funcSubId = thisObj.values.ddFunction?.split("/")[1]?.trim()
      let funcSub = funcSubId == '' ? null : funcSubId 
      let accessLvl = getValue(thisObj, 'ddAccessLevel')
      let obj = {}
      if (document.getElementById('btnUpdate').innerHTML == 'Add') {
        let dataObj = {
          "comp_id": compIdFromLS,
          "func_id": funcId,
          "func_sub_id": funcSubId,
          "security_group": security_group,
          "access_level": accessLvl,
          "user_id": useridFromLS
        }
        let resp = await SystemMaintenanceSpecialFunctionsService.CreateSecurityGroupFunctionTransfer(dataObj)
        if (resp.status != 200) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.', false)
          return
        }
        obj = {
          "lstFunctions": `${funcId}.${funcSubId} (${accessLvl}) - ${funcName}`,
          "lstFunctionID": funcId,
          "lstFuncSub": funcSubId,
          "cmdEnableDisableControls": true,
          "security_group": security_group
        }
      } else {
        let dataObj = {
          "access_level": accessLvl, 
          "chg_user": useridFromLS
        }
        let resp = await SystemMaintenanceSpecialFunctionsService.UpdateSecurityGroupFunctionTransfer(funcId, funcSub, security_group, dataObj)
        if (resp.status != 200) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.', false)
          return
        }
        obj = {
          "lstFunctions": `${funcId}.${funcSubId} (${accessLvl}) - ${funcName}`,
          "cmdEnableDisableControls": true,
          "security_group": security_group
        }
      }
      setData(thisObj, 'frmSecurityGroup1', obj)
      setData(thisObj, "closingOfSecurityFunctionSelect", true)
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityFunctionSelectPopUp").childNodes[0].click()
    } catch (error) {
      errorHandler(err)
    }

  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  function ConvertDateTime(dateTime){
    try{
      const d = new Date(dateTime)
      return  [ d.getMonth()+1, d.getDate(), d.getFullYear() ].join('/') 
    }
    catch(err){
      errorHandler(err)
    }
  }

  function errorHandler(err){
    showMessage(thisObj, err.message)
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityFunctionSelect*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityFunctionSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityFunctionSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityFunctionSelect*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityFunctionSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtKeyInformation*/}

                {/* END_USER_CODE-USER_BEFORE_txtKeyInformation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKeyInformation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKeyInformation*/}

                {/* END_USER_CODE-USER_AFTER_txtKeyInformation*/}
                {/* START_USER_CODE-USER_BEFORE_ddFunction*/}

                {/* END_USER_CODE-USER_BEFORE_ddFunction*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddFunction}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddFunction*/}

                {/* END_USER_CODE-USER_AFTER_ddFunction*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccessLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccessLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddAccessLevel*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstLocation*/}

                {/* END_USER_CODE-USER_BEFORE_lstLocation*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLocation}
                  screenConf={state}
                  onMouseMove={(e)=>e.preventDefault()}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstLocation*/}

                {/* END_USER_CODE-USER_AFTER_lstLocation*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityFunctionSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityFunctionSelect*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityFunctionSelect*/}

              {/* END_USER_CODE-USER_AFTER_SecurityFunctionSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_SecurityFunctionSelect
);
