/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable,
} from "../../shared/WindowImports";

import "./TradeCategorySetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_TradeCategorySetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const useridFromLS = sessionStorage.getItem('userid')
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeCategorySetup",
    windowName: "TradeCategorySetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.TradeCategorySetup",
    // START_USER_CODE-USER_TradeCategorySetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Category Setup",
      scrCode: "PN0220A",
    },
    // END_USER_CODE-USER_TradeCategorySetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxTradeCategorySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxTradeCategorySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxTradeCategorySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    col1007: {
      name: "col1007",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "1007",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007_PROPERTIES

      // END_USER_CODE-USER_col1007_PROPERTIES
    },
    colAcct: {
      name: "colAcct",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "Acct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAcct_PROPERTIES

      // END_USER_CODE-USER_colAcct_PROPERTIES
    },
    colBalPL: {
      name: "colBalPL",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "Bal/P-L",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBalPL_PROPERTIES

      // END_USER_CODE-USER_colBalPL_PROPERTIES
    },
    colCategory: {
      name: "colCategory",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "Category",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCategory_PROPERTIES

      // END_USER_CODE-USER_colCategory_PROPERTIES
    },
    colPS: {
      name: "colPS",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "P/S",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPS_PROPERTIES

      // END_USER_CODE-USER_colPS_PROPERTIES
    },
    colSubDept: {
      name: "colSubDept",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "Sub Dept.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSubDept_PROPERTIES

      // END_USER_CODE-USER_colSubDept_PROPERTIES
    },
    colWeighs: {
      name: "colWeighs",
      type: "GridColumnWidget",
      parent: "gridTradeCategoryList",
      Label: "Weighs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWeighs_PROPERTIES

      // END_USER_CODE-USER_colWeighs_PROPERTIES
    },
    gridTradeCategoryList: {
      name: "gridTradeCategoryList",
      type: "GridWidget",
      parent: "grpbxTradeCategorySetup",
      gridCellsOrder:
        "txtcolCategory,txtcolPS,txtcolAcct,txtcolBalPL,txtcolSubDept,txtcolWeighs,txtcol1007",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Cols: "4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridTradeCategoryList_PROPERTIES

      // END_USER_CODE-USER_gridTradeCategoryList_PROPERTIES
    },
    lblTradeCategoryList: {
      name: "lblTradeCategoryList",
      type: "LabelWidget",
      parent: "grpbxTradeCategorySetup",
      Label: "Tade Category List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTradeCategoryList_PROPERTIES

      // END_USER_CODE-USER_lblTradeCategoryList_PROPERTIES
    },
    txtcol1007: {
      name: "txtcol1007",
      type: "TextBoxWidget",
      colName: "col1007",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007_PROPERTIES

      // END_USER_CODE-USER_txtcol1007_PROPERTIES
    },
    txtcolAcct: {
      name: "txtcolAcct",
      type: "TextBoxWidget",
      colName: "colAcct",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAcct_PROPERTIES

      // END_USER_CODE-USER_txtcolAcct_PROPERTIES
    },
    txtcolBalPL: {
      name: "txtcolBalPL",
      type: "TextBoxWidget",
      colName: "colBalPL",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBalPL_PROPERTIES

      // END_USER_CODE-USER_txtcolBalPL_PROPERTIES
    },
    txtcolCategory: {
      name: "txtcolCategory",
      type: "TextBoxWidget",
      colName: "colCategory",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCategory_PROPERTIES

      // END_USER_CODE-USER_txtcolCategory_PROPERTIES
    },
    txtcolPS: {
      name: "txtcolPS",
      type: "TextBoxWidget",
      colName: "colPS",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPS_PROPERTIES

      // END_USER_CODE-USER_txtcolPS_PROPERTIES
    },
    txtcolSubDept: {
      name: "txtcolSubDept",
      type: "TextBoxWidget",
      colName: "colSubDept",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSubDept_PROPERTIES

      // END_USER_CODE-USER_txtcolSubDept_PROPERTIES
    },
    txtcolWeighs: {
      name: "txtcolWeighs",
      type: "TextBoxWidget",
      colName: "colWeighs",
      parent: "gridTradeCategoryList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWeighs_PROPERTIES

      // END_USER_CODE-USER_txtcolWeighs_PROPERTIES
    },
    grpbxTradeCategorySetup: {
      name: "grpbxTradeCategorySetup",
      type: "GroupBoxWidget",
      parent: "TradeCategorySetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxTradeCategorySetup_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeCategorySetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "TradeCategorySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#TradeCategoryProfile":{},
       },
      REVERSE:{
        "ContractManagement#TradeCategoryProfile":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAdd:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#TradeCategoryProfile#DEFAULT#true#Click",]
    },
    btnEdit:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#TradeCategoryProfile#DEFAULT#true#Click",]
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  useEffect(() => {
    FormRefresh()
  },[getData(thisObj, 'TradeCategoryProfileClosed')])

  async function FormRefresh(){
    if(getData(thisObj, 'TradeCategoryProfileClosed')){
      await bFillGrid()
      await EnableDisableControls()
    }
    setData(thisObj, 'TradeCategoryProfileClosed', false)
  }

  useEffect(() => {
    formLoad()
  }, []);

  const formLoad = () => {
    bFillGrid()
    EnableDisableControls()
  }

  const bFillGrid = async () => {
    let response = await SettlementService.RetrieveTradeCategoryDetails(null, null);
    if (response.length > 0 && response != undefined) {
      let js = []
      let obj = {}
      setData(thisObj, 'GridValues', response)
      for (let i = 0; i < response.length; i++) {
        obj.txtcolCategory = response[i].tradeCategory
        obj.txtcolPS = response[i].purchSaleInd
        obj.txtcolAcct = response[i].tradeAccount
        obj.txtcolBalPL = response[i].plBalAcctInd
        obj.txtcolSubDept = response[i].distSubDeptInd
        obj.txtcolWeighs = response[i].distQtyInd
        obj.txtcol1007 = response[i].generate1007Ind

        js.push(obj)
        obj = {}
      }
      setValue(thisObj, 'gridTradeCategoryList', js)
    } else {
      setValue(thisObj, 'gridTradeCategoryList', [])
    }
  }

  const GetAccessLevel = async (strAccessLevel) => {
    let GetAccessLevel = false;

    let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN0220', null, strAccessLevel, null)
    if (response[0].permission == 'Y') {
      GetAccessLevel = true
    }
    return GetAccessLevel
  }

  const EnableDisableControls = async () => {
    let sPermission = ''
    disable(thisObj, 'btnAdd')
    disable(thisObj, 'btnEdit')
    disable(thisObj, 'btnDelete')
    if (await GetAccessLevel('D')) {
      sPermission = 'D'
    } else if (await GetAccessLevel('U')) {
      sPermission = 'U'
    } else if (await GetAccessLevel('I')) {
      sPermission = 'I'
    } else {
      showMessage(thisObj, 'Invalid Permissions', false)
      document.getElementById("SystemMaintenanceSpecialFunctions_TradeCategorySetupPopUp").childNodes[0].click()
      return
    }

    setData(thisObj, 'sPermission_Val', sPermission)

    if (sPermission == 'I') {
      disable(thisObj, 'btnAdd')
      enable(thisObj, 'btnEdit')
      disable(thisObj, 'btnDelete')
    }

    if (sPermission == 'U') {
      enable(thisObj, 'btnAdd')
      enable(thisObj, 'btnEdit')
      disable(thisObj, 'btnDelete')
    }

    if (sPermission == 'D') {
      enable(thisObj, 'btnAdd')
      enable(thisObj, 'btnEdit')
      enable(thisObj, 'btnDelete')
    }
  }

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_TradeCategorySetupPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnClose click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;



  const onbtnAddClick = () => {
    try {
      let obj = {}
      obj.cmdOkCaption = "Add"
      obj.txtTradeCategoryEnable = true
      obj.cboPurchaseSaleIndEnable = true
      obj.txtAccountEnable = true
      obj.cboBalanceSheetProfitLossIndEnable = true
      obj.chkAcctSubDeptEnable = true
      obj.chkRecordWeightsEnable = true
      obj.chkGenerate1007Enable = true
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#TradeCategoryProfile#DEFAULT#true#Click");
      setData(thisObj, 'TradeCategoryProfileData', obj)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnClose click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnEditClick = () => {
    try {
      let sPermission = getData(thisObj, 'sPermission_Val')
      let rowValue = thisObj.state.gridTradeCategoryList.selected[0]
      let gridJs = getData(thisObj, 'GridValues')
      if (thisObj.state.gridTradeCategoryList.selected[0] == undefined || thisObj.state.gridTradeCategoryList.selected[0] == null) {
        showMessage(thisObj, "A row must be selected to open.", false)
        return;
      }
      let obj = {}
      obj.cmdOkCaption = "Update"
      if (sPermission == 'U' || sPermission == 'D') {
        obj.cmdOkEnable = true
      } else {
        obj.cmdOkEnable = false
      }
      obj.txtTradeCategoryEnable = false
      obj.cboPurchaseSaleIndEnable = true
      obj.txtAccountEnable = true
      obj.cboBalanceSheetProfitLossIndEnable = true
      obj.chkAcctSubDeptEnable = true
      obj.chkRecordWeightsEnable = true
      obj.chkGenerate1007Enable = true
      obj.txtTradeCategoryText = rowValue.txtcolCategory

      if (rowValue.txtcolPS == 'P') {
        obj.cboPurchaseSaleIndText = 'Purchase'
        obj.cboPurchaseSaleIndTag = 'P'
      } else {
        obj.cboPurchaseSaleIndText = 'Sale'
        obj.cboPurchaseSaleIndTag = 'S'
      }

      obj.txtAccountText = rowValue.txtcolAcct

      if (rowValue.txtcolBalPL == 'BAL') {
        obj.cboBalanceSheetProfitLossIndText = 'Balance Sheet'
        obj.cboBalanceSheetProfitLossIndTag = 'BAL'
      } else {
        obj.cboBalanceSheetProfitLossIndText = 'Profit-Loss'
        obj.cboBalanceSheetProfitLossIndTag = 'P/L'
      }

      if (rowValue.txtcolSubDept == 'Y') {
        obj.chkAcctSubDept = true
      } else {
        obj.chkAcctSubDept = false
      }

      if (rowValue.txtcolWeighs == 'Y') {
        obj.chkRecordWeights = true
      } else {
        obj.chkRecordWeights = false
      }

      if (rowValue.txtcol1007 == 'Y') {
        obj.chkGenerate1007 = true
      } else {
        obj.chkGenerate1007 = false
      }

      obj.lblAddedBy = gridJs[rowValue.rowID$].addUser + ' ' + moment(gridJs[rowValue.rowID$].addDateTime).format("MM/DD/YYYY");
      obj.lblChangedBy = gridJs[rowValue.rowID$].chgUser + ' ' + moment(gridJs[rowValue.rowID$].chgDateTime).format("MM/DD/YYYY");
      
      setData(thisObj, 'TradeCategoryProfileData', obj)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#TradeCategoryProfile#DEFAULT#true#Click");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during BtnEdit click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick;

  const onbtnDeleteClick = async () => {
    try {
      let rowValue = thisObj.state.gridTradeCategoryList.selected[0]
      let gridJs = getData(thisObj, 'GridValues')
      if (thisObj.state.gridTradeCategoryList.selected[0] == undefined || thisObj.state.gridTradeCategoryList.selected[0] == null) {
        showMessage(thisObj, "A row must be selected to delete.", false)
        return;
      }
      let dataObj = {
        "trade_category": rowValue.txtcolCategory,
        "purch_sale_ind": rowValue.txtcolPS,
        "trade_account": rowValue.txtcolAcct,
        "pl_bal_acct_ind": rowValue.txtcolBalPL,
        "dist_sub_dept_ind": rowValue.txtcolSubDept,
        "dist_qty_ind": rowValue.txtcolWeighs,
        "generate_1007_ind": rowValue.txtcol1007,
        "user_id": useridFromLS
      }
      const confirmBox = window.confirm(`Are you sure you wish to delete '${rowValue.txtcolCategory}' from the Trade Category control table?`)
      if (confirmBox == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateTradeCategory('DELETE', dataObj);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        await bFillGrid()
        await EnableDisableControls()
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnDelete click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeCategorySetup*/}

              {/* END_USER_CODE-USER_BEFORE_TradeCategorySetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxTradeCategorySetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeCategorySetup*/}

              <GroupBoxWidget
                conf={state.grpbxTradeCategorySetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblTradeCategoryList*/}

                {/* END_USER_CODE-USER_BEFORE_lblTradeCategoryList*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTradeCategoryList}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTradeCategoryList*/}

                {/* END_USER_CODE-USER_AFTER_lblTradeCategoryList*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridTradeCategoryList*/}

                {/* END_USER_CODE-USER_BEFORE_gridTradeCategoryList*/}

                <GridWidget
                  conf={state.gridTradeCategoryList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridTradeCategoryList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridTradeCategoryList*/}

                {/* END_USER_CODE-USER_AFTER_gridTradeCategoryList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeCategorySetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeCategorySetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_TradeCategorySetup*/}

              {/* END_USER_CODE-USER_AFTER_TradeCategorySetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_TradeCategorySetup);
