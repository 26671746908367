import ContractManagement_AccountDistributionActivity from "./modules/ContractManagement/AccountDistributionActivity/AccountDistributionActivity";
import ContractManagement_AddendumSeedGrower2009 from "./modules/ContractManagement/AddendumSeedGrower2009/AddendumSeedGrower2009";
import ContractManagement_ApplicationToContract from "./modules/ContractManagement/ApplicationToContract/ApplicationToContract";
import ContractManagement_ContractExceptionPremiumSetup from "./modules/ContractManagement/ContractExceptionPremiumSetup/ContractExceptionPremiumSetup";
import ContractManagement_ContractExceptPremProfile from "./modules/ContractManagement/ContractExceptPremProfile/ContractExceptPremProfile";
import ContractManagement_ContractGroupingProfile from "./modules/ContractManagement/ContractGroupingProfile/ContractGroupingProfile";
import ContractManagement_ContractGroupingSearch from "./modules/ContractManagement/ContractGroupingSearch/ContractGroupingSearch";
import ContractManagement_ContractGrpSelect from "./modules/ContractManagement/ContractGrpSelect/ContractGrpSelect";
import ContractManagement_ContractInquiry from "./modules/ContractManagement/ContractInquiry/ContractInquiry";
import ContractManagement_ContractProfile from "./modules/ContractManagement/ContractProfile/ContractProfile";
import ContractManagement_ContractScan from "./modules/ContractManagement/ContractScan/ContractScan";
import ContractManagement_ExceptionPremiumSearch from "./modules/ContractManagement/ExceptionPremiumSearch/ExceptionPremiumSearch";
import ContractManagement_ExceptPremiumAdjustments from "./modules/ContractManagement/ExceptPremiumAdjustments/ExceptPremiumAdjustments";
import ContractManagement_ExceptPremRequestProfile from "./modules/ContractManagement/ExceptPremRequestProfile/ExceptPremRequestProfile";
import ContractManagement_ExceptPremRequestSearch from "./modules/ContractManagement/ExceptPremRequestSearch/ExceptPremRequestSearch";
import ContractManagement_FlexibleMarketPricings from "./modules/ContractManagement/FlexibleMarketPricings/FlexibleMarketPricings";
import ContractManagement_FlexMktPrint from "./modules/ContractManagement/FlexMktPrint/FlexMktPrint";
import ContractManagement_FlexMktSign from "./modules/ContractManagement/FlexMktSign/FlexMktSign";
import ContractManagement_FlexMktVoid from "./modules/ContractManagement/FlexMktVoid/FlexMktVoid";
import ContractManagement_header from "./modules/Header/Header";
import ContractManagement_MainMenu from "./modules/ContractManagement/MainMenu/MainMenu";
import ContractManagement_ReportPreviewContract from "./modules/ContractManagement/ReportPreviewContract/ReportPreviewContract";
import ContractManagement_ReportPreviewExcPrem from "./modules/ContractManagement/ReportPreviewExcPrem/ReportPreviewExcPrem";
import ContractManagement_VendorDisclosure from "./modules/ContractManagement/VendorDisclosure/VendorDisclosure";
import ContractManagement_VendorLookup from "./modules/ContractManagement/VendorLookup/VendorLookup";
import ContractManagement_ViewPayments from "./modules/ContractManagement/ViewPayments/ViewPayments";
import Settlements_TradeAgreeProfile from './modules/Settlements/TradeAgreeProfile/TradeAgreeProfile';
import Settlements_TradeAgreeSearch from './modules/Settlements/TradeAgreeSearch/TradeAgreeSearch';
import Settlements_TradeSettleSearch from './modules/Settlements/TradeSettleSearch/TradeSettleSearch';
import Settlements_TradeSettleProfile from './modules/Settlements/TradeSettleProfile/TradeSettleProfile';
import Settlements_TradeSettleApplyView from "./modules/Settlements/TradeSettleApplyView/TradeSettleApplyView";
import AccountPayment_CheckInquiry from './modules/AccountPayment/CheckInquiry/CheckInquiry';
import AccountPayment_CorrectCheck from './modules/AccountPayment/CorrectCheck/CorrectCheck';
import AccountPayment_NullifyCheck from './modules/AccountPayment/NullifyCheck/NullifyCheck';
import AccountPayment_PrintChecks from './modules/AccountPayment/PrintChecks/PrintChecks';
import AccountPayment_ReadyToPayRelease from './modules/AccountPayment/ReadyToPayRelease/ReadyToPayRelease';
import AccountPayment_ReportPreviewCheck from './modules/AccountPayment/ReportPreviewCheck/ReportPreviewCheck';
import AccountPayment_ReviewCheck from './modules/AccountPayment/ReviewCheck/ReviewCheck';
import AccountPayment_ReviewCheckAuditDetail from './modules/AccountPayment/ReviewCheckAuditDetail/ReviewCheckAuditDetail';
import AccountPayment_SpecialPayablesInquiry from './modules/AccountPayment/SpecialPayablesInquiry/SpecialPayablesInquiry';
import AccountPayment_SpecialPayablesProfile from './modules/AccountPayment/SpecialPayablesProfile/SpecialPayablesProfile';
import AccountPayment_SpecialPayablesScan from './modules/AccountPayment/SpecialPayablesScan/SpecialPayablesScan';
import AccountPayment_SpecialPayDocsView from './modules/AccountPayment/SpecialPayDocsView/SpecialPayDocsView';
import AccountPayment_CheckDetails from "./modules/AccountPayment/CheckDetails/CheckDetails";
import ReportUsermanual_FarmSummaryReportRequest from './modules/ReportUsermanual/FarmSummaryReportRequest/FarmSummaryReportRequest';
import ReportUsermanual_UserManual from './modules/ReportUsermanual/UserManual/UserManual';
import Settlements_FV95 from "./modules/Settlements/FV95/FV95";
import Settlements_FV95Search from "./modules/Settlements/FV95Search/FV95Search";
import Settlements_ReportPreviewTrade from "./modules/Settlements/ReportPreviewTrade/ReportPreviewTrade";
import Settlements_KCMOdataSearch from "./modules/Settlements/KCMOdataSearch/KCMOdataSearch";
import Settlements_ReportPreviewInspection from "./modules/Settlements/ReportPreviewInspection/ReportPreviewInspection";
import Settlements_ContApplEditAuthorize from "./modules/Settlements/ContApplEditAuthorize/ContApplEditAuthorize";
import Settlements_NoSaleVoidFV95 from "./modules/Settlements/NoSaleVoidFV95/NoSaleVoidFV95";
import Settlements_ReviewCorrectionLog from "./modules/Settlements/ReviewCorrectionLog/ReviewCorrectionLog";
import Settlements_ViewContractApplication from "./modules/Settlements/ViewContractApplication/ViewContractApplication";
import Settlements_ViewPremiumDeductions from "./modules/Settlements/ViewPremiumDeductions/ViewPremiumDeductions";
import Settlements_ViewUploads from "./modules/Settlements/ViewUploads/ViewUploads";
import Settlements_ViewSettlement from "./modules/Settlements/ViewSettlement/ViewSettlement";
import Settlements_FV95Residue from "./modules/Settlements/FV95Residue/FV95Residue";
import Settlements_1007Settlement from "./modules/Settlements/1007Settlement/1007Settlement";
import Settlements_CleaningCuringCosts from "./modules/Settlements/CleaningCuringCosts/CleaningCuringCosts";
import Settlements_KCMOdataProfile from "./modules/Settlements/KCMOdataProfile/KCMOdataProfile";
import Settlements_PremiumDeductionsVendorSplits from "./modules/Settlements/PremiumDeductionsVendorSplits/PremiumDeductionsVendorSplits";
import Settlements_PremiumDeductionsVendorSplitsTrade from "./modules/Settlements/PremiumDeductionsVendorSplitsTrade/PremiumDeductionsVendorSplitsTrade";
import Settlements_PremiumDeductionTrade from "./modules/Settlements/PremiumDeductionTrade/PremiumDeductionTrade";
import Settlements_ScaleTickets from "./modules/Settlements/ScaleTickets/ScaleTickets";
import Settlements_ContractApplications2009 from "./modules/Settlements/ContractApplications2009/ContractApplications2009";
import Settlements_PremiumDeductions from "./modules/Settlements/PremiumDeductions/PremiumDeductions";
import StockTransfer_MultiStopProfile from "./modules/StockTransfer/MultiStopProfile/MultiStopProfile";
import StockTransfer_StockTransferOldNumber from "./modules/StockTransfer/StockTransferOldNumber/StockTransferOldNumber";
import StockTransfer_StockTransfer from "./modules/StockTransfer/StockTransfer/StockTransfer";
import StockTransfer_TransferSearch from "./modules/StockTransfer/TransferSearch/TransferSearch";
import StockTransfer_FrtAccrlSearch from "./modules/StockTransfer/FrtAccrlSearch/FrtAccrlSearch";
import StockTransfer_PremiumDeductionsFrtAccrl from "./modules/StockTransfer/PremiumDeductionsFrtAccrl/PremiumDeductionsFrtAccrl";
import StockTransfer_MultiStopSelect from "./modules/StockTransfer/MultiStopSelect/MultiStopSelect";
import StockTransfer_MultiStopSearch from "./modules/StockTransfer/MultiStopSearch/MultiStopSearch";
import StockTransfer_FrtAccrlSettlement from "./modules/StockTransfer/FrtAccrlSettlement/FrtAccrlSettlement";
import WarehouseReceipts_WarehouseReceipt from "./modules/WarehouseReceipts/WarehouseReceipt/WarehouseReceipt";
import WarehouseReceipts_WarehouseReceiptInquiry from "./modules/WarehouseReceipts/WarehouseReceiptInquiry/WarehouseReceiptInquiry";
import WarehouseReceipts_ViewEWRLog from "./modules/WarehouseReceipts/ViewEWRLog/ViewEWRLog";
import WarehouseReceipts_1007GroupingProfile from "./modules/WarehouseReceipts/1007GroupingProfile/1007GroupingProfile";
import WarehouseReceipts_1007GrpSearch from "./modules/WarehouseReceipts/1007GrpSearch/1007GrpSearch";
import WarehouseReceipts_DelvAgreeSearch from "./modules/WarehouseReceipts/DelvAgreeSearch/DelvAgreeSearch";
import WarehouseReceipts_Delivery from "./modules/WarehouseReceipts/Delivery/Delivery";
import WarehouseReceipts_WarehouseReceiptRedeemByLoan from "./modules/WarehouseReceipts/WarehouseReceiptRedeemByLoan/WarehouseReceiptRedeemByLoan";
import WarehouseReceipts_1007GrpSelect from "./modules/WarehouseReceipts/1007GrpSelect/1007GrpSelect";
import WarehouseReceipts_EWRSendUpdate from "./modules/WarehouseReceipts/EWRSendUpdate/EWRSendUpdate";
import WarehouseReceipts_DelvSettleProfile from "./modules/WarehouseReceipts/DelvSettleProfile/DelvSettleProfile";
import WarehouseReceipts_DelvAgreeProfile from "./modules/WarehouseReceipts/DelvAgreeProfile/DelvAgreeProfile";
import WarehouseReceipts_ExternalReceiptForfeituresSearch from "./modules/WarehouseReceipts/ExternalReceiptForfeituresSearch/ExternalReceiptForfeituresSearch";
import WarehouseReceipts_WarehouseReceiptUpload from "./modules/WarehouseReceipts/WarehouseReceiptUpload/WarehouseReceiptUpload";
import WarehouseReceipts_EWRListUpdate from "./modules/WarehouseReceipts/EWRListUpdate/EWRListUpdate";
import WarehouseReceipts_EWRSendRRI from "./modules/WarehouseReceipts/EWRSendRRI/EWRSendRRI";
import WarehouseReceipts_DelvSettleSearch from "./modules/WarehouseReceipts/DelvSettleSearch/DelvSettleSearch";
import WarehouseReceipts_DelvAvailAgreement from "./modules/WarehouseReceipts/DelvAvailAgreement/DelvAvailAgreement";
import WarehouseReceipts_ViewWRApplications from "./modules/WarehouseReceipts/ViewWRApplications/ViewWRApplications";
import WarehouseReceipts_ViewWRPremiumDeductions from "./modules/WarehouseReceipts/ViewWRPremiumDeductions/ViewWRPremiumDeductions";
import WarehouseReceipts_WhouseApplications from "./modules/WarehouseReceipts/WhouseApplications/WhouseApplications";
import WarehouseReceipts_ExtReceiptForfeituresProfile from "./modules/WarehouseReceipts/ExtReceiptForfeituresProfile/ExtReceiptForfeituresProfile";
import WarehouseReceipts_ViewWarehouseReceipt from "./modules/WarehouseReceipts/ViewWarehouseReceipt/ViewWarehouseReceipt";
import WarehouseReceipts_ViewWRPayments from "./modules/WarehouseReceipts/ViewWRPayments/ViewWRPayments";
import WarehouseReceipts_DelvInAvailAgreement from "./modules/WarehouseReceipts/DelvInAvailAgreement/DelvInAvailAgreement";
import WarehouseReceipts_ExtReceiptForfeituresCropYear from "./modules/WarehouseReceipts/ExtReceiptForfeituresCropYear/ExtReceiptForfeituresCropYear";
import WarehouseReceipts_ReportPreviewWR from "./modules/WarehouseReceipts/ReportPreviewWR/ReportPreviewWR";
import WarehouseReceipts_WhouseReceiptStorage from "./modules/WarehouseReceipts/WhouseReceiptStorage/WhouseReceiptStorage";
import StockTransfer_StockTransferNewNumber from "./modules/StockTransfer/StockTransferNewNumber/StockTransferNewNumber";
import StockTransfer_ReportPreviewTransfer from "./modules/StockTransfer/ReportPreviewTransfer/ReportPreviewTransfer";
import StockTransfer_SelectFromList from "./modules/StockTransfer/SelectFromList/SelectFromList";
import WarehouseReceipts_EWRListRRI from "./modules/WarehouseReceipts/EWRListRRI/EWRListRRI";
import WarehouseReceipts_DelvSettleApply from "./modules/WarehouseReceipts/DelvSettleApply/DelvSettleApply";
import WarehouseReceipts_DelvSettleApplyView from "./modules/WarehouseReceipts/DelvSettleApplyView/DelvSettleApplyView";
import WarehouseReceipts_DelvPremiumDeductions from "./modules/WarehouseReceipts/DelvPremiumDeductions/DelvPremiumDeductions";
import WarehouseReceipts_DelvSettlePaymentView from "./modules/WarehouseReceipts/DelvSettlePaymentView/DelvSettlePaymentView";
import WarehouseReceipts_DelvSettleScaleTicket from "./modules/WarehouseReceipts/DelvSettleScaleTicket/DelvSettleScaleTicket";
import WarehouseReceipts_PremiumDeductionsWRPurch from "./modules/WarehouseReceipts/PremiumDeductionsWRPurch/PremiumDeductionsWRPurch";
import WarehouseReceipts_EWRViewRRI from "./modules/WarehouseReceipts/EWRViewRRI/EWRViewRRI";
import SystemMaintenanceMasterManagement_AreaProfile from "./modules/SystemMaintenanceMasterManagement/AreaProfile/AreaProfile";
import SystemMaintenanceMasterManagement_BuyingPointProfile from "./modules/SystemMaintenanceMasterManagement/BuyingPointProfile/BuyingPointProfile";
import SystemMaintenanceMasterManagement_BuyingPointSetup from "./modules/SystemMaintenanceMasterManagement/BuyingPointSetup/BuyingPointSetup";
import SystemMaintenanceMasterManagement_BuyingPointTransfer from "./modules/SystemMaintenanceMasterManagement/BuyingPointTransfer/BuyingPointTransfer";
import SystemMaintenanceMasterManagement_CollectionPointProfile from "./modules/SystemMaintenanceMasterManagement/CollectionPointProfile/CollectionPointProfile";
import SystemMaintenanceMasterManagement_CountyProfile from "./modules/SystemMaintenanceMasterManagement/CountyProfile/CountyProfile";
import SystemMaintenanceMasterManagement_CountySetup from "./modules/SystemMaintenanceMasterManagement/CountySetup/CountySetup";
import SystemMaintenanceMasterManagement_DeliveryPeriodProfile from "./modules/SystemMaintenanceMasterManagement/DeliveryPeriodProfile/DeliveryPeriodProfile";
import SystemMaintenanceMasterManagement_PeanutTypeProfile from "./modules/SystemMaintenanceMasterManagement/PeanutTypeProfile/PeanutTypeProfile";
import SystemMaintenanceMasterManagement_PeanutVarietyProfile from "./modules/SystemMaintenanceMasterManagement/PeanutVarietyProfile/PeanutVarietyProfile";
import SystemMaintenanceMasterManagement_PeanutVarietySetup from "./modules/SystemMaintenanceMasterManagement/PeanutVarietySetup/PeanutVarietySetup";
import SystemMaintenanceMasterManagement_StateProfile from "./modules/SystemMaintenanceMasterManagement/StateProfile/StateProfile";
import SystemMaintenanceMasterManagement_VoidReasonProfile from "./modules/SystemMaintenanceMasterManagement/VoidReasonProfile/VoidReasonProfile";
import SystemMaintenanceMasterManagement_VoidReasonSetup from "./modules/SystemMaintenanceMasterManagement/VoidReasonSetup/VoidReasonSetup";
import SystemMaintenanceMasterManagement_WarehouseBinSetup from "./modules/SystemMaintenanceMasterManagement/WarehouseBinSetup/WarehouseBinSetup";
import SystemMaintenanceMasterManagement_WeigherProfile from "./modules/SystemMaintenanceMasterManagement/WeigherProfile/WeigherProfile";
import SystemMaintenanceMasterManagement_ContractLimitArea from "./modules/SystemMaintenanceMasterManagement/ContractLimitArea/ContractLimitArea";
import SystemMaintenanceMasterManagement_ContractLimitSearch from "./modules/SystemMaintenanceMasterManagement/ContractLimitSearch/ContractLimitSearch";
import SystemMaintenanceMasterManagement_CureCleanScheduleProfile from "./modules/SystemMaintenanceMasterManagement/CureCleanScheduleProfile/CureCleanScheduleProfile";
import SystemMaintenanceMasterManagement_CureCleanScheduleSetup from "./modules/SystemMaintenanceMasterManagement/CureCleanScheduleSetup/CureCleanScheduleSetup";
import SystemMaintenanceMasterManagement_PremiumDeductionContractProfile from "./modules/SystemMaintenanceMasterManagement/PremiumDeductionContractProfile/PremiumDeductionContractProfile";
import SystemMaintenanceMasterManagement_PremiumDeductionVendorProfile from "./modules/SystemMaintenanceMasterManagement/PremiumDeductionVendorProfile/PremiumDeductionVendorProfile";
import SystemMaintenanceMasterManagement_WarehouseBinCopy from "./modules/SystemMaintenanceMasterManagement/WarehouseBinCopy/WarehouseBinCopy";
import SystemMaintenanceMasterManagement_WarehouseBinProfile from "./modules/SystemMaintenanceMasterManagement/WarehouseBinProfile/WarehouseBinProfile";
import SystemMaintenanceMasterManagement_FarmGovData from "./modules/SystemMaintenanceMasterManagement/FarmGovData/FarmGovData";
import SystemMaintenanceMasterManagement_FarmInquiry from "./modules/SystemMaintenanceMasterManagement/FarmInquiry/FarmInquiry";
import SystemMaintenanceMasterManagement_VendorProfile from "./modules/SystemMaintenanceMasterManagement/VendorProfile/VendorProfile";
import SystemMaintenanceMasterManagement_FarmProfile from "./modules/SystemMaintenanceMasterManagement/FarmProfile/FarmProfile";
import SystemMaintenanceMasterManagement_FreightLocToLocSetup from "./modules/SystemMaintenanceMasterManagement/FreightLocToLocSetup/FreightLocToLocSetup";
import SystemMaintenanceMasterManagement_FreightMenu from "./modules/SystemMaintenanceMasterManagement/FreightMenu/FreightMenu";
import SystemMaintenanceMasterManagement_FreightRateSetup from "./modules/SystemMaintenanceMasterManagement/FreightRateSetup/FreightRateSetup";
import SystemMaintenanceMasterManagement_FuelSurchargeInquiry from "./modules/SystemMaintenanceMasterManagement/FuelSurchargeInquiry/FuelSurchargeInquiry";
import SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfile from "./modules/SystemMaintenanceMasterManagement/PeanutSpecificationExceptionProfile/PeanutSpecificationExceptionProfile";
import SystemMaintenanceMasterManagement_PeanutSpecificationProfile from "./modules/SystemMaintenanceMasterManagement/PeanutSpecificationProfile/PeanutSpecificationProfile";
import SystemMaintenanceMasterManagement_PeanutSpecificationSetup from "./modules/SystemMaintenanceMasterManagement/PeanutSpecificationSetup/PeanutSpecificationSetup";
import SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile from "./modules/SystemMaintenanceMasterManagement/PremiumDeductionScheduleProfile/PremiumDeductionScheduleProfile";
import SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup from "./modules/SystemMaintenanceMasterManagement/PremiumDeductionScheduleSetup/PremiumDeductionScheduleSetup";
import SystemMaintenanceMasterManagement_FreightRateCopy from "./modules/SystemMaintenanceMasterManagement/FreightRateCopy/FreightRateCopy";
import SystemMaintenanceMasterManagement_LeasedTrailerProfile from "./modules/SystemMaintenanceMasterManagement/LeasedTrailerProfile/LeasedTrailerProfile";
import SystemMaintenanceMasterManagement_FarmerStockPriceProfile from "./modules/SystemMaintenanceMasterManagement/FarmerStockPriceProfile/FarmerStockPriceProfile";
import SystemMaintenanceMasterManagement_ContractLimitBuyPt from "./modules/SystemMaintenanceMasterManagement/ContractLimitBuyPt/ContractLimitBuyPt";
import SystemMaintenanceMasterManagement_ContractLimitCollPt from "./modules/SystemMaintenanceMasterManagement/ContractLimitCollPt/ContractLimitCollPt";
import SystemMaintenanceMasterManagement_FreightRateProfile from "./modules/SystemMaintenanceMasterManagement/FreightRateProfile/FreightRateProfile";
import SystemMaintenanceApplicationSupport_VendorSearch from "./modules/SystemMaintenanceApplicationSupport/VendorSearch/VendorSearch";
import SystemMaintenanceApplicationSupport_InventoryInquiry from "./modules/SystemMaintenanceApplicationSupport/InventoryInquiry/InventoryInquiry";
import SystemMaintenanceApplicationSupport_LienHolderProfile from "./modules/SystemMaintenanceApplicationSupport/LienHolderProfile/LienHolderProfile";
import SystemMaintenanceApplicationSupport_Scan211Document from "./modules/SystemMaintenanceApplicationSupport/Scan211Document/Scan211Document";
import SystemMaintenanceApplicationSupport_VendorMaintenance from "./modules/SystemMaintenanceApplicationSupport/VendorMaintenance/VendorMaintenance";
import SystemMaintenanceApplicationSupport_InventoryAdjustments from "./modules/SystemMaintenanceApplicationSupport/InventoryAdjustments/InventoryAdjustments";
import SystemMaintenanceApplicationSupport_ManualRTPMaint from "./modules/SystemMaintenanceApplicationSupport/ManualRTPMaint/ManualRTPMaint";
import SystemMaintenanceApplicationSupport_OpenAdjustmentEntry from "./modules/SystemMaintenanceApplicationSupport/OpenAdjustmentEntry/OpenAdjustmentEntry";
import SystemMaintenanceApplicationSupport_ManualRTPSearch from "./modules/SystemMaintenanceApplicationSupport/ManualRTPSearch/ManualRTPSearch";
import SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup from "./modules/SystemMaintenanceSpecialFunctions/PremiumDeducMasterSetup/PremiumDeducMasterSetup";
import SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile from "./modules/SystemMaintenanceSpecialFunctions/PremiumDeducMasterProfile/PremiumDeducMasterProfile";
import SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate from "./modules/SystemMaintenanceSpecialFunctions/ReportUserSignatureTemplate/ReportUserSignatureTemplate";
import SystemMaintenanceSpecialFunctions_UserSignatureSetup from "./modules/SystemMaintenanceSpecialFunctions/UserSignatureSetup/UserSignatureSetup";
import SystemMaintenanceMasterManagement_FedWhouseLicenseSetup from "./modules/SystemMaintenanceMasterManagement/FedWhouseLicenseSetup/FedWhouseLicenseSetup";
import SystemMaintenanceSpecialFunctions_SpotControlProfile from "./modules/SystemMaintenanceSpecialFunctions/SpotControlProfile/SpotControlProfile";
import SystemMaintenanceApplicationSupport_OpenAccountAdjustments from "./modules/SystemMaintenanceApplicationSupport/OpenAccountAdjustments/OpenAccountAdjustments";
import SystemMaintenanceApplicationSupport_OpenAccountsProfile from "./modules/SystemMaintenanceApplicationSupport/OpenAccountsProfile/OpenAccountsProfile";
import SystemMaintenanceApplicationSupport_SpecFuncChgNum from "./modules/SystemMaintenanceApplicationSupport/SpecFuncChgNum/SpecFuncChgNum";
import SystemMaintenanceApplicationSupport_SpecFuncReprint from "./modules/SystemMaintenanceApplicationSupport/SpecFuncReprint/SpecFuncReprint";
import SystemMaintenanceSpecialFunctions_CompanySetup from "./modules/SystemMaintenanceSpecialFunctions/CompanySetup/CompanySetup";
import SystemMaintenanceSpecialFunctions_SpecialFunctions from "./modules/SystemMaintenanceSpecialFunctions/SpecialFunctions/SpecialFunctions";
import SystemMaintenanceMasterManagement_InterestAgreementProfile from "./modules/SystemMaintenanceMasterManagement/InterestAgreementProfile/InterestAgreementProfile";
import SystemMaintenanceMasterManagement_InterestAgreementSetup from "./modules/SystemMaintenanceMasterManagement/InterestAgreementSetup/InterestAgreementSetup";
import SystemMaintenanceMasterManagement_FreightLocToLocProfile from "./modules/SystemMaintenanceMasterManagement/FreightLocToLocProfile/FreightLocToLocProfile";
import SystemMaintenanceApplicationSupport_ManualAcctDistMaint from "./modules/SystemMaintenanceApplicationSupport/ManualAcctDistMaint/ManualAcctDistMaint";
import SystemMaintenanceApplicationSupport_SpecFuncMisc from "./modules/SystemMaintenanceApplicationSupport/SpecFuncMisc/SpecFuncMisc";
import SystemMaintenanceMasterManagement_FarmerStockPriceSetup from "./modules/SystemMaintenanceMasterManagement/FarmerStockPriceSetup/FarmerStockPriceSetup";
import SystemMaintenanceMasterManagement_FedWhouseLicenseProfile from "./modules/SystemMaintenanceMasterManagement/FedWhouseLicenseProfile/FedWhouseLicenseProfile";
import SystemMaintenanceMasterManagement_Filters from "./modules/SystemMaintenanceMasterManagement/Filters/Filters";
import SystemMaintenanceSpecialFunctions_HighMoistureSetup from "./modules/SystemMaintenanceSpecialFunctions/HighMoistureSetup/HighMoistureSetup";
import SystemMaintenanceSpecialFunctions_SpotControlSetup from "./modules/SystemMaintenanceSpecialFunctions/SpotControlSetup/SpotControlSetup";
import SystemMaintenanceApplicationSupport_DeductionTrackingDetail from "./modules/SystemMaintenanceApplicationSupport/DeductionTrackingDetail/DeductionTrackingDetail";
import SystemMaintenanceApplicationSupport_DeductionTrackingSummary from "./modules/SystemMaintenanceApplicationSupport/DeductionTrackingSummary/DeductionTrackingSummary";
import SystemMaintenanceApplicationSupport_ManualAcctDistSearch from "./modules/SystemMaintenanceApplicationSupport/ManualAcctDistSearch/ManualAcctDistSearch";
import SystemMaintenanceApplicationSupport_OpenAccountsSetup from "./modules/SystemMaintenanceApplicationSupport/OpenAccountsSetup/OpenAccountsSetup";
import SystemMaintenanceSpecialFunctions_CompanyProfile from "./modules/SystemMaintenanceSpecialFunctions/CompanyProfile/CompanyProfile";
import SystemMaintenanceSpecialFunctions_SecurityMenu from "./modules/SystemMaintenanceSpecialFunctions/SecurityMenu/SecurityMenu";
import SystemMaintenanceSpecialFunctions_VendorWireTransferSetup from "./modules/SystemMaintenanceSpecialFunctions/VendorWireTransferSetup/VendorWireTransferSetup";
import SystemMaintenanceSpecialFunctions_LoanRepaySetup from "./modules/SystemMaintenanceSpecialFunctions/LoanRepaySetup/LoanRepaySetup";
import SystemMaintenanceSpecialFunctions_PriceScheduleSetup from "./modules/SystemMaintenanceSpecialFunctions/PriceScheduleSetup/PriceScheduleSetup";
import SystemMaintenanceSpecialFunctions_SecurityGroup from "./modules/SystemMaintenanceSpecialFunctions/SecurityGroup/SecurityGroup";
import SystemMaintenanceSpecialFunctions_SecurityUser from "./modules/SystemMaintenanceSpecialFunctions/SecurityUser/SecurityUser";
import SystemMaintenanceSpecialFunctions_TradeCategorySetup from "./modules/SystemMaintenanceSpecialFunctions/TradeCategorySetup/TradeCategorySetup";
import SystemMaintenanceSpecialFunctions_LoanRepayProfile from "./modules/SystemMaintenanceSpecialFunctions/LoanRepayProfile/LoanRepayProfile";
import SystemMaintenanceSpecialFunctions_MarketPriceProfile from "./modules/SystemMaintenanceSpecialFunctions/MarketPriceProfile/MarketPriceProfile";
import SystemMaintenanceSpecialFunctions_MarketPriceSetup from "./modules/SystemMaintenanceSpecialFunctions/MarketPriceSetup/MarketPriceSetup";
import SystemMaintenanceSpecialFunctions_PriceScheduleAuditList from "./modules/SystemMaintenanceSpecialFunctions/PriceScheduleAuditList/PriceScheduleAuditList";
import SystemMaintenanceSpecialFunctions_SecurityReport from "./modules/SystemMaintenanceSpecialFunctions/SecurityReport/SecurityReport";
import SystemMaintenanceSpecialFunctions_VendorWireTransfer from "./modules/SystemMaintenanceSpecialFunctions/VendorWireTransfer/VendorWireTransfer";
import SystemMaintenanceSpecialFunctions_BankHolidayProfile from "./modules/SystemMaintenanceSpecialFunctions/BankHolidayProfile/BankHolidayProfile";
import SystemMaintenanceApplicationSupport_ManualDPRInvMaint from "./modules/SystemMaintenanceApplicationSupport/ManualDPRInvMaint/ManualDPRInvMaint";
import SystemMaintenanceApplicationSupport_ManualRTPHistSearch from "./modules/SystemMaintenanceApplicationSupport/ManualRTPHistSearch/ManualRTPHistSearch";
import SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch from "./modules/SystemMaintenanceApplicationSupport/ManualDPRInvHistSearch/ManualDPRInvHistSearch";
import SystemMaintenanceApplicationSupport_ManualDPRInvSearch from "./modules/SystemMaintenanceApplicationSupport/ManualDPRInvSearch/ManualDPRInvSearch";
import SystemMaintenanceSpecialFunctions_SecurityFunctionLocationSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityFunctionLocationSelect/SecurityFunctionLocationSelect";
import SystemMaintenanceSpecialFunctions_SecurityGroupUserSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityGroupUserSelect/SecurityGroupUserSelect";
import SystemMaintenanceSpecialFunctions_SecurityLocationSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityLocationSelect/SecurityLocationSelect";
import SystemMaintenanceSpecialFunctions_SecurityUserFunctionLocationSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityUserFunctionLocationSelect/SecurityUserFunctionLocationSelect";
import SystemMaintenanceMasterManagement_FarmSetup from "./modules/SystemMaintenanceMasterManagement/FarmSetup/FarmSetup";
import SystemMaintenanceApplicationSupport_ManualDPRInvHistView from "./modules/SystemMaintenanceApplicationSupport/ManualDPRInvHistView/ManualDPRInvHistView";
import SystemMaintenanceSpecialFunctions_BankHolidaySetup from "./modules/SystemMaintenanceSpecialFunctions/BankHolidaySetup/BankHolidaySetup";
import SystemMaintenanceSpecialFunctions_CmaDmaControlSetup from "./modules/SystemMaintenanceSpecialFunctions/CmaDmaControlSetup/CmaDmaControlSetup";
import SystemMaintenanceSpecialFunctions_DiscountScheduleSetup from "./modules/SystemMaintenanceSpecialFunctions/DiscountScheduleSetup/DiscountScheduleSetup";
import SystemMaintenanceSpecialFunctions_LoanInterestProfile from "./modules/SystemMaintenanceSpecialFunctions/LoanInterestProfile/LoanInterestProfile";
import SystemMaintenanceSpecialFunctions_LoanInterestSetup from "./modules/SystemMaintenanceSpecialFunctions/LoanInterestSetup/LoanInterestSetup";
import SystemMaintenanceSpecialFunctions_LoanReductionProfile from "./modules/SystemMaintenanceSpecialFunctions/LoanReductionProfile/LoanReductionProfile";
import SystemMaintenanceSpecialFunctions_LoanReductionSetup from "./modules/SystemMaintenanceSpecialFunctions/LoanReductionSetup/LoanReductionSetup";
import SystemMaintenanceSpecialFunctions_SecurityFunctionSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityFunctionSelect/SecurityFunctionSelect";
import SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityUserFunctionSelect/SecurityUserFunctionSelect";
import SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect from "./modules/SystemMaintenanceSpecialFunctions/SecurityUserLocationSelect/SecurityUserLocationSelect";
import SystemMaintenanceSpecialFunctions_CmaDmaControlProfile from "./modules/SystemMaintenanceSpecialFunctions/CmaDmaControlProfile/CmaDmaControlProfile";
import SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail from "./modules/SystemMaintenanceSpecialFunctions/DiscountScheduleAuditDetail/DiscountScheduleAuditDetail";
import SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail from "./modules/SystemMaintenanceSpecialFunctions/PriceScheduleAuditDetail/PriceScheduleAuditDetail";
import SystemMaintenanceSpecialFunctions_SecurityFunctionInquiry from "./modules/SystemMaintenanceSpecialFunctions/SecurityFunctionInquiry/SecurityFunctionInquiry";
import SystemMaintenanceSpecialFunctions_TradeCategoryProfile from "./modules/SystemMaintenanceSpecialFunctions/TradeCategoryProfile/TradeCategoryProfile";
import SystemMaintenanceApplicationSupport_ManualRTPHistView from "./modules/SystemMaintenanceApplicationSupport/ManualRTPHistView/ManualRTPHistView";
import SystemMaintenanceSpecialFunctions_Dashboard from "./modules/SystemMaintenanceSpecialFunctions/Dashboard/Dashboard";
import ReportTool_FormulaEditor from "./modules/ReportTool/FormulaEditor/FormulaEditor";
import ReportTool_ProcurementReporting from "./modules/ReportTool/ProcurementReporting/ProcurementReporting";
import WarehouseReceipts_WarehouseReceiptInquiryPopUp from "./modules/WarehouseReceipts/WarehouseReceiptInquiryPopUp/WarehouseReceiptInquiryPopUp";
import StockTransfer_TransferErrorList from "./modules/StockTransfer/TransferErrorList/TransferErrorList";
const routes = [
  {
    path: "/",
    component: ContractManagement_MainMenu,
    exact: true,
  },
  {
    path: "/ContractManagement_AccountDistributionActivity",
    component: ContractManagement_AccountDistributionActivity,
  },
  {
    path: "/ContractManagement_AddendumSeedGrower2009",
    component: ContractManagement_AddendumSeedGrower2009,
  },
  {
    path: "/ContractManagement_ApplicationToContract",
    component: ContractManagement_ApplicationToContract,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_Dashboard",
    component: SystemMaintenanceSpecialFunctions_Dashboard,
  },
  {
    path: "/ContractManagement_ContractExceptionPremiumSetup",
    component: ContractManagement_ContractExceptionPremiumSetup,
  },
  {
    path: "/ContractManagement_ContractExceptPremProfile",
    component: ContractManagement_ContractExceptPremProfile,
  },
  {
    path: "/ContractManagement_ContractGroupingProfile",
    component: ContractManagement_ContractGroupingProfile,
  },
  {
    path: "/ContractManagement_ContractGroupingSearch",
    component: ContractManagement_ContractGroupingSearch,
  },
  {
    path: "/ContractManagement_ContractGrpSelect",
    component: ContractManagement_ContractGrpSelect,
  },
  {
    path: "/ContractManagement_ContractInquiry",
    component: ContractManagement_ContractInquiry,
  },
  {
    path: "/ContractManagement_ContractProfile",
    component: ContractManagement_ContractProfile,
  },
  {
    path: "/ContractManagement_ContractScan",
    component: ContractManagement_ContractScan,
  },
  {
    path: "/ContractManagement_ExceptionPremiumSearch",
    component: ContractManagement_ExceptionPremiumSearch,
  },
  {
    path: "/ContractManagement_ExceptPremiumAdjustments",
    component: ContractManagement_ExceptPremiumAdjustments,
  },
  {
    path: "/ContractManagement_ExceptPremRequestProfile",
    component: ContractManagement_ExceptPremRequestProfile,
  },
  {
    path: "/ContractManagement_ExceptPremRequestSearch",
    component: ContractManagement_ExceptPremRequestSearch,
  },
  {
    path: "/ContractManagement_FlexibleMarketPricings",
    component: ContractManagement_FlexibleMarketPricings,
  },
  {
    path: "/ContractManagement_FlexMktPrint",
    component: ContractManagement_FlexMktPrint,
  },
  {
    path: "/ContractManagement_FlexMktSign",
    component: ContractManagement_FlexMktSign,
  },
  {
    path: "/ContractManagement_FlexMktVoid",
    component: ContractManagement_FlexMktVoid,
  },
  {
    path: "/ContractManagement_header",
    component: ContractManagement_header,
  },
  {
    path: "/ContractManagement_MainMenu",
    component: ContractManagement_MainMenu,
  },
  {
    path: "/ContractManagement_ReportPreviewContract",
    component: ContractManagement_ReportPreviewContract,
  },
  {
    path: "/ContractManagement_ReportPreviewExcPrem",
    component: ContractManagement_ReportPreviewExcPrem,
  },
  {
    path: "/ContractManagement_VendorDisclosure",
    component: ContractManagement_VendorDisclosure,
  },
  {
    path: "/ContractManagement_VendorLookup",
    component: ContractManagement_VendorLookup,
  },
  {
    path: "/ContractManagement_ViewPayments",
    component: ContractManagement_ViewPayments,
  },
  {
    path: "/Settlements_TradeAgreeProfile",
    component: Settlements_TradeAgreeProfile
  },
  {
    path: "/Settlements_TradeAgreeSearch",
    component: Settlements_TradeAgreeSearch
  },
  {
    path: "/Settlements_TradeSettleApplyView",
    component: Settlements_TradeSettleApplyView,
  },
  {

    "path": "/Settlements_TradeSettleSearch",
    "component": Settlements_TradeSettleSearch
  },
  {
    path: "/Settlements_TradeSettleProfile",
    component: Settlements_TradeSettleProfile
  },
  {
    "path": "/AccountPayment_CheckInquiry",
    "component": AccountPayment_CheckInquiry
  },
  {
    "path": "/AccountPayment_CorrectCheck",
    "component": AccountPayment_CorrectCheck
  },
  {
    "path": "/AccountPayment_NullifyCheck",
    "component": AccountPayment_NullifyCheck
  },
  {
    "path": "/AccountPayment_PrintChecks",
    "component": AccountPayment_PrintChecks
  },
  {
    "path": "/AccountPayment_ReadyToPayRelease",
    "component": AccountPayment_ReadyToPayRelease
  },
  {
    "path": "/AccountPayment_ReportPreviewCheck",
    "component": AccountPayment_ReportPreviewCheck
  },
  {
    "path": "/AccountPayment_ReviewCheck",
    "component": AccountPayment_ReviewCheck
  },
  {
    "path": "/AccountPayment_ReviewCheckAuditDetail",
    "component": AccountPayment_ReviewCheckAuditDetail
  },
  {
    "path": "/AccountPayment_SpecialPayablesInquiry",
    "component": AccountPayment_SpecialPayablesInquiry
  },
  {
    "path": "/AccountPayment_SpecialPayablesProfile",
    "component": AccountPayment_SpecialPayablesProfile
  },
  {
    "path": "/AccountPayment_SpecialPayablesScan",
    "component": AccountPayment_SpecialPayablesScan
  },
  {
    "path": "/AccountPayment_SpecialPayDocsView",
    "component": AccountPayment_SpecialPayDocsView
  },
  {
    path: "/AccountPayment_CheckDetails",
    component: AccountPayment_CheckDetails,
  },
  {
    "path": "/ReportUsermanual_FarmSummaryReportRequest",
    "component": ReportUsermanual_FarmSummaryReportRequest
  },
  {
    "path": "/ReportUsermanual_UserManual",
    "component": ReportUsermanual_UserManual
  },
  {
    path: "/Settlements_FV95",
    component: Settlements_FV95,
  },
  {
    path: "/Settlements_FV95Search",
    component: Settlements_FV95Search,
  },
  {
    path: "/Settlements_ReportPreviewTrade",
    component: Settlements_ReportPreviewTrade,
  },
  {
    path: "/Settlements_KCMOdataSearch",
    component: Settlements_KCMOdataSearch,
  },
  {
    path: "/Settlements_ReportPreviewInspection",
    component: Settlements_ReportPreviewInspection,
  },
  {
    path: "/Settlements_ContApplEditAuthorize",
    component: Settlements_ContApplEditAuthorize,
  },
  {
    path: "/Settlements_NoSaleVoidFV95",
    component: Settlements_NoSaleVoidFV95,
  },
  {
    path: "/Settlements_ReviewCorrectionLog",
    component: Settlements_ReviewCorrectionLog,
  },
  {
    path: "/Settlements_ViewContractApplication",
    component: Settlements_ViewContractApplication,
  },
  {
    path: "/Settlements_ViewPremiumDeductions",
    component: Settlements_ViewPremiumDeductions,
  },
  {
    path: "/Settlements_PremiumDeductionTrade",
    component: Settlements_PremiumDeductionTrade,
  },
  {
    path: "/Settlements_ViewUploads",
    component: Settlements_ViewUploads,
  },
  {
    path: "/Settlements_ViewSettlement",
    component: Settlements_ViewSettlement,
  },
  {
    path: "/Settlements_FV95Residue",
    component: Settlements_FV95Residue,
  },
  {
    path: "/Settlements_1007Settlement",
    component: Settlements_1007Settlement,
  },
  {
    path: "/Settlements_CleaningCuringCosts",
    component: Settlements_CleaningCuringCosts,
  },
  {
    path: "/Settlements_KCMOdataProfile",
    component: Settlements_KCMOdataProfile,
  },
  {
    path: "/Settlements_PremiumDeductionsVendorSplits",
    component: Settlements_PremiumDeductionsVendorSplits,
  },
  {
    path: "/Settlements_PremiumDeductionsVendorSplitsTrade",
    component: Settlements_PremiumDeductionsVendorSplitsTrade,
  },
  {
    path: "/Settlements_ScaleTickets",
    component: Settlements_ScaleTickets,
  },
  {
    path: "/Settlements_PremiumDeductions",
    component: Settlements_PremiumDeductions,
  },
  {
    path: "/Settlements_ContractApplications2009",
    component: Settlements_ContractApplications2009,
  },
  {
    path: "/StockTransfer_MultiStopProfile",
    component: StockTransfer_MultiStopProfile,
  },
  {
    path: "/StockTransfer_StockTransferOldNumber",
    component: StockTransfer_StockTransferOldNumber,
  },
  {
    path: "/StockTransfer_StockTransfer",
    component: StockTransfer_StockTransfer,
  },
  {
    path: "/StockTransfer_FrtAccrlSettlement",
    component: StockTransfer_FrtAccrlSettlement,
  },
  {
    path: "/StockTransfer_FrtAccrlSearch",
    component: StockTransfer_FrtAccrlSearch,
  },
  {
    path: "/StockTransfer_TransferSearch",
    component: StockTransfer_TransferSearch,
  },  
  {
    path: "/StockTransfer_MultiStopSelect",
    component: StockTransfer_MultiStopSelect,
  },
  {
    path: "/StockTransfer_PremiumDeductionsFrtAccrl",
    component: StockTransfer_PremiumDeductionsFrtAccrl,
  },
  {
    path: "/StockTransfer_MultiStopSearch",
    component: StockTransfer_MultiStopSearch,
  },
  {
    path: "/StockTransfer_StockTransferNewNumber",
    component: StockTransfer_StockTransferNewNumber,
  },
  {
    path: "/WarehouseReceipts_WarehouseReceipt",
    component: WarehouseReceipts_WarehouseReceipt,
  },
  {
    path: "/WarehouseReceipts_WarehouseReceiptInquiry",
    component: WarehouseReceipts_WarehouseReceiptInquiry,
  },
  {
    path: "/WarehouseReceipts_ViewEWRLog",
    component: WarehouseReceipts_ViewEWRLog,
  },
  {
    path: "/WarehouseReceipts_1007GroupingProfile",
    component: WarehouseReceipts_1007GroupingProfile,
  },
  {
    path: "/WarehouseReceipts_1007GrpSearch",
    component: WarehouseReceipts_1007GrpSearch,
  },
  {
    path: "/WarehouseReceipts_DelvAgreeSearch",
    component: WarehouseReceipts_DelvAgreeSearch,
  },
  {
    path: "/WarehouseReceipts_Delivery",
    component: WarehouseReceipts_Delivery,
  },
  {
    path: "/WarehouseReceipts_WarehouseReceiptRedeemByLoan",
    component: WarehouseReceipts_WarehouseReceiptRedeemByLoan,
  },
  {
    path: "/WarehouseReceipts_1007GrpSelect",
    component: WarehouseReceipts_1007GrpSelect,
  },
  {
    path: "/WarehouseReceipts_EWRSendUpdate",
    component: WarehouseReceipts_EWRSendUpdate,
  },
  {
    path: "/WarehouseReceipts_WarehouseReceiptUpload",
    component: WarehouseReceipts_WarehouseReceiptUpload,
  },
  {
    path: "/WarehouseReceipts_DelvAgreeProfile",
    component: WarehouseReceipts_DelvAgreeProfile,
  },
  {
    path: "/WarehouseReceipts_DelvSettleProfile",
    component: WarehouseReceipts_DelvSettleProfile,
  },
  {
    path: "/WarehouseReceipts_ExternalReceiptForfeituresSearch",
    component: WarehouseReceipts_ExternalReceiptForfeituresSearch,
  },
  {
    path: "/WarehouseReceipts_EWRListUpdate",
    component: WarehouseReceipts_EWRListUpdate,
  },
  {
    path: "/WarehouseReceipts_EWRSendRRI",
    component: WarehouseReceipts_EWRSendRRI,
  },
  {
    path: "/WarehouseReceipts_DelvSettleSearch",
    component: WarehouseReceipts_DelvSettleSearch,
  },
  {
    path: "/WarehouseReceipts_DelvAvailAgreement",
    component: WarehouseReceipts_DelvAvailAgreement,
  },
  {
    path: "/WarehouseReceipts_ViewWRApplications",
    component: WarehouseReceipts_ViewWRApplications,
  },
  {
    path: "/WarehouseReceipts_ViewWRPremiumDeductions",
    component: WarehouseReceipts_ViewWRPremiumDeductions,
  },
  {
    path: "/WarehouseReceipts_WhouseApplications",
    component: WarehouseReceipts_WhouseApplications,
  },
  {
    path: "/WarehouseReceipts_ExtReceiptForfeituresProfile",
    component: WarehouseReceipts_ExtReceiptForfeituresProfile,
  },
  {
    path: "/WarehouseReceipts_ViewWarehouseReceipt",
    component: WarehouseReceipts_ViewWarehouseReceipt,
  },
  {
    path: "/WarehouseReceipts_ViewWRPayments",
    component: WarehouseReceipts_ViewWRPayments,
  },
  {
    path: "/WarehouseReceipts_DelvInAvailAgreement",
    component: WarehouseReceipts_DelvInAvailAgreement,
  },
  {
    path: "/WarehouseReceipts_ExtReceiptForfeituresCropYear",
    component: WarehouseReceipts_ExtReceiptForfeituresCropYear,
  },
  {
    path: "/WarehouseReceipts_ReportPreviewWR",
    component: WarehouseReceipts_ReportPreviewWR,
  },
  {
    path: "/WarehouseReceipts_WhouseReceiptStorage",
    component: WarehouseReceipts_WhouseReceiptStorage,
  },
  {
    path: "/WarehouseReceipts_EWRListRRI",
    component: WarehouseReceipts_EWRListRRI,
  },
  {
    path: "/StockTransfer_ReportPreviewTransfer",
    component: StockTransfer_ReportPreviewTransfer,
  },
  {
    path: "/StockTransfer_SelectFromList",
    component: StockTransfer_SelectFromList,
  },
  {
    path: "/WarehouseReceipts_DelvSettleApply",
    component: WarehouseReceipts_DelvSettleApply,
  },
  {
    path: "/WarehouseReceipts_DelvSettleApplyView",
    component: WarehouseReceipts_DelvSettleApplyView,
  },
  {
    path: "/WarehouseReceipts_DelvPremiumDeductions",
    component: WarehouseReceipts_DelvPremiumDeductions,
  },
  {
    path: "/WarehouseReceipts_DelvSettlePaymentView",
    component: WarehouseReceipts_DelvSettlePaymentView,
  },
  {
    path: "/WarehouseReceipts_DelvSettleScaleTicket",
    component: WarehouseReceipts_DelvSettleScaleTicket,
  },
  {
    path: "/WarehouseReceipts_PremiumDeductionsWRPurch",
    component: WarehouseReceipts_PremiumDeductionsWRPurch,
  },
  {
    path: "/WarehouseReceipts_EWRViewRRI",
    component: WarehouseReceipts_EWRViewRRI,
  },
  {
    path: "/SystemMaintenanceMasterManagement_AreaProfile",
    component: SystemMaintenanceMasterManagement_AreaProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_BuyingPointProfile",
    component: SystemMaintenanceMasterManagement_BuyingPointProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_BuyingPointSetup",
    component: SystemMaintenanceMasterManagement_BuyingPointSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_BuyingPointTransfer",
    component: SystemMaintenanceMasterManagement_BuyingPointTransfer,
  },
  {
    path: "/SystemMaintenanceMasterManagement_CollectionPointProfile",
    component: SystemMaintenanceMasterManagement_CollectionPointProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_CountyProfile",
    component: SystemMaintenanceMasterManagement_CountyProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_CountySetup",
    component: SystemMaintenanceMasterManagement_CountySetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_DeliveryPeriodProfile",
    component: SystemMaintenanceMasterManagement_DeliveryPeriodProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PeanutTypeProfile",
    component: SystemMaintenanceMasterManagement_PeanutTypeProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_CompanyProfile",
    component: SystemMaintenanceSpecialFunctions_CompanyProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PeanutVarietyProfile",
    component: SystemMaintenanceMasterManagement_PeanutVarietyProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PeanutVarietySetup",
    component: SystemMaintenanceMasterManagement_PeanutVarietySetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_StateProfile",
    component: SystemMaintenanceMasterManagement_StateProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_VoidReasonProfile",
    component: SystemMaintenanceMasterManagement_VoidReasonProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_VoidReasonSetup",
    component: SystemMaintenanceMasterManagement_VoidReasonSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_WarehouseBinSetup",
    component: SystemMaintenanceMasterManagement_WarehouseBinSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_WeigherProfile",
    component: SystemMaintenanceMasterManagement_WeigherProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_ContractLimitArea",
    component: SystemMaintenanceMasterManagement_ContractLimitArea,
  },
  {
    path: "/SystemMaintenanceMasterManagement_ContractLimitSearch",
    component: SystemMaintenanceMasterManagement_ContractLimitSearch,
  },
  {
    path: "/SystemMaintenanceMasterManagement_CureCleanScheduleProfile",
    component: SystemMaintenanceMasterManagement_CureCleanScheduleProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_CureCleanScheduleSetup",
    component: SystemMaintenanceMasterManagement_CureCleanScheduleSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PremiumDeductionContractProfile",
    component: SystemMaintenanceMasterManagement_PremiumDeductionContractProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FreightLocToLocProfile",
    component: SystemMaintenanceMasterManagement_FreightLocToLocProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PremiumDeductionVendorProfile",
    component: SystemMaintenanceMasterManagement_PremiumDeductionVendorProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_WarehouseBinCopy",
    component: SystemMaintenanceMasterManagement_WarehouseBinCopy,
  },
  {
    path: "/SystemMaintenanceMasterManagement_WarehouseBinProfile",
    component: SystemMaintenanceMasterManagement_WarehouseBinProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FarmGovData",
    component: SystemMaintenanceMasterManagement_FarmGovData,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FarmInquiry",
    component: SystemMaintenanceMasterManagement_FarmInquiry,
  },
  {
    path: "/SystemMaintenanceMasterManagement_VendorProfile",
    component: SystemMaintenanceMasterManagement_VendorProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FarmProfile",
    component: SystemMaintenanceMasterManagement_FarmProfile,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_VendorSearch",
    component: SystemMaintenanceApplicationSupport_VendorSearch,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup",
    component: SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FarmerStockPriceSetup",
    component: SystemMaintenanceMasterManagement_FarmerStockPriceSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FedWhouseLicenseProfile",
    component: SystemMaintenanceMasterManagement_FedWhouseLicenseProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_Filters",
    component: SystemMaintenanceMasterManagement_Filters,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_InventoryInquiry",
    component: SystemMaintenanceApplicationSupport_InventoryInquiry,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualAcctDistMaint",
    component: SystemMaintenanceApplicationSupport_ManualAcctDistMaint,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_SpecFuncMisc",
    component: SystemMaintenanceApplicationSupport_SpecFuncMisc,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_LienHolderProfile",
    component: SystemMaintenanceApplicationSupport_LienHolderProfile,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_Scan211Document",
    component: SystemMaintenanceApplicationSupport_Scan211Document,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_InventoryAdjustments",
    component: SystemMaintenanceApplicationSupport_InventoryAdjustments,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualRTPMaint",
    component: SystemMaintenanceApplicationSupport_ManualRTPMaint,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualRTPSearch",
    component: SystemMaintenanceApplicationSupport_ManualRTPSearch,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_VendorMaintenance",
    component: SystemMaintenanceApplicationSupport_VendorMaintenance,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile",
    component: SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate",
    component: SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_UserSignatureSetup",
    component: SystemMaintenanceSpecialFunctions_UserSignatureSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FreightLocToLocSetup",
    component: SystemMaintenanceMasterManagement_FreightLocToLocSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FreightMenu",
    component: SystemMaintenanceMasterManagement_FreightMenu,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FreightRateSetup",
    component: SystemMaintenanceMasterManagement_FreightRateSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FuelSurchargeInquiry",
    component: SystemMaintenanceMasterManagement_FuelSurchargeInquiry,
  },
  {
    path:
      "/SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfile",
    component: SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PeanutSpecificationProfile",
    component: SystemMaintenanceMasterManagement_PeanutSpecificationProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PeanutSpecificationSetup",
    component: SystemMaintenanceMasterManagement_PeanutSpecificationSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile",
    component: SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup",
    component: SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FreightRateCopy",
    component: SystemMaintenanceMasterManagement_FreightRateCopy,
  },
  {
    path: "/SystemMaintenanceMasterManagement_LeasedTrailerProfile",
    component: SystemMaintenanceMasterManagement_LeasedTrailerProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FarmerStockPriceProfile",
    component: SystemMaintenanceMasterManagement_FarmerStockPriceProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_ContractLimitBuyPt",
    component: SystemMaintenanceMasterManagement_ContractLimitBuyPt,
  },
  {
    path: "/SystemMaintenanceMasterManagement_ContractLimitCollPt",
    component: SystemMaintenanceMasterManagement_ContractLimitCollPt,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FreightRateProfile",
    component: SystemMaintenanceMasterManagement_FreightRateProfile,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_OpenAdjustmentEntry",
    component: SystemMaintenanceApplicationSupport_OpenAdjustmentEntry,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FedWhouseLicenseSetup",
    component: SystemMaintenanceMasterManagement_FedWhouseLicenseSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SpotControlProfile",
    component: SystemMaintenanceSpecialFunctions_SpotControlProfile,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_OpenAccountAdjustments",
    component: SystemMaintenanceApplicationSupport_OpenAccountAdjustments,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_OpenAccountsProfile",
    component: SystemMaintenanceApplicationSupport_OpenAccountsProfile,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_SpecFuncChgNum",
    component: SystemMaintenanceApplicationSupport_SpecFuncChgNum,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_SpecFuncReprint",
    component: SystemMaintenanceApplicationSupport_SpecFuncReprint,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_CompanySetup",
    component: SystemMaintenanceSpecialFunctions_CompanySetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SpecialFunctions",
    component: SystemMaintenanceSpecialFunctions_SpecialFunctions,
  },
  {
    path: "/SystemMaintenanceMasterManagement_InterestAgreementProfile",
    component: SystemMaintenanceMasterManagement_InterestAgreementProfile,
  },
  {
    path: "/SystemMaintenanceMasterManagement_InterestAgreementSetup",
    component: SystemMaintenanceMasterManagement_InterestAgreementSetup,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_DeductionTrackingDetail",
    component: SystemMaintenanceApplicationSupport_DeductionTrackingDetail,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_DeductionTrackingSummary",
    component: SystemMaintenanceApplicationSupport_DeductionTrackingSummary,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualAcctDistSearch",
    component: SystemMaintenanceApplicationSupport_ManualAcctDistSearch,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_OpenAccountsSetup",
    component: SystemMaintenanceApplicationSupport_OpenAccountsSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_HighMoistureSetup",
    component: SystemMaintenanceSpecialFunctions_HighMoistureSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SpotControlSetup",
    component: SystemMaintenanceSpecialFunctions_SpotControlSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityMenu",
    component: SystemMaintenanceSpecialFunctions_SecurityMenu,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_VendorWireTransferSetup",
    component: SystemMaintenanceSpecialFunctions_VendorWireTransferSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_LoanRepaySetup",
    component: SystemMaintenanceSpecialFunctions_LoanRepaySetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_PriceScheduleSetup",
    component: SystemMaintenanceSpecialFunctions_PriceScheduleSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityGroup",
    component: SystemMaintenanceSpecialFunctions_SecurityGroup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityUser",
    component: SystemMaintenanceSpecialFunctions_SecurityUser,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_TradeCategorySetup",
    component: SystemMaintenanceSpecialFunctions_TradeCategorySetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_BankHolidayProfile",
    component: SystemMaintenanceSpecialFunctions_BankHolidayProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_LoanRepayProfile",
    component: SystemMaintenanceSpecialFunctions_LoanRepayProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_MarketPriceProfile",
    component: SystemMaintenanceSpecialFunctions_MarketPriceProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_MarketPriceSetup",
    component: SystemMaintenanceSpecialFunctions_MarketPriceSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_PriceScheduleAuditList",
    component: SystemMaintenanceSpecialFunctions_PriceScheduleAuditList,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityReport",
    component: SystemMaintenanceSpecialFunctions_SecurityReport,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_VendorWireTransfer",
    component: SystemMaintenanceSpecialFunctions_VendorWireTransfer,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualDPRInvMaint",
    component: SystemMaintenanceApplicationSupport_ManualDPRInvMaint,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualRTPHistSearch",
    component: SystemMaintenanceApplicationSupport_ManualRTPHistSearch,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch",
    component: SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualDPRInvSearch",
    component: SystemMaintenanceApplicationSupport_ManualDPRInvSearch,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityFunctionLocationSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityFunctionLocationSelect,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityGroupUserSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityGroupUserSelect,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityLocationSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityLocationSelect,
  },
  {
    path:
      "/SystemMaintenanceSpecialFunctions_SecurityUserFunctionLocationSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityUserFunctionLocationSelect,
  },
  {
    path: "/SystemMaintenanceMasterManagement_FarmSetup",
    component: SystemMaintenanceMasterManagement_FarmSetup,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualDPRInvHistView",
    component: SystemMaintenanceApplicationSupport_ManualDPRInvHistView,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_BankHolidaySetup",
    component: SystemMaintenanceSpecialFunctions_BankHolidaySetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_CmaDmaControlSetup",
    component: SystemMaintenanceSpecialFunctions_CmaDmaControlSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_DiscountScheduleSetup",
    component: SystemMaintenanceSpecialFunctions_DiscountScheduleSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_LoanInterestProfile",
    component: SystemMaintenanceSpecialFunctions_LoanInterestProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_LoanInterestSetup",
    component: SystemMaintenanceSpecialFunctions_LoanInterestSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_LoanReductionProfile",
    component: SystemMaintenanceSpecialFunctions_LoanReductionProfile,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_LoanReductionSetup",
    component: SystemMaintenanceSpecialFunctions_LoanReductionSetup,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityFunctionSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityFunctionSelect,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect",
    component: SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_CmaDmaControlProfile",
    component: SystemMaintenanceSpecialFunctions_CmaDmaControlProfile,
  },
  {
    path: "/SystemMaintenanceApplicationSupport_ManualRTPHistView",
    component: SystemMaintenanceApplicationSupport_ManualRTPHistView,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail",
    component: SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail",
    component: SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_SecurityFunctionInquiry",
    component: SystemMaintenanceSpecialFunctions_SecurityFunctionInquiry,
  },
  {
    path: "/SystemMaintenanceSpecialFunctions_TradeCategoryProfile",
    component: SystemMaintenanceSpecialFunctions_TradeCategoryProfile,
  },
  {
    path: "/ReportTool_FormulaEditor",
    component: ReportTool_FormulaEditor,
  },
  {
    path: "/ReportTool_ProcurementReporting",
    component: ReportTool_ProcurementReporting,
  },
  {
    path: "/WarehouseReceipts_WarehouseReceiptInquiryPopUp",
    component: WarehouseReceipts_WarehouseReceiptInquiryPopUp,
  },
  {
    path: "/StockTransfer_TransferErrorList",
    component: StockTransfer_TransferErrorList,
  },
];
export default routes;
