/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WhouseApplications from "./WhouseApplications";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WhouseApplications Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WhouseApplications />);
    });
  });
  afterEach(cleanup);
  test("is WhouseApplications Loads Successfully", () => {
    expect(screen.getByText("WhouseApplications")).toBeInTheDocument;
  });
  test("Custom Test Cases for WhouseApplications", () => {
    // START_USER_CODE-USER_WhouseApplications_Custom_Test_Case
    // END_USER_CODE-USER_WhouseApplications_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WhouseApplications />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnClearValues(Button Widget) Test Cases", async () => {
    const btnClearValues = screen.getByTestId("btnClearValues");
    expect(btnClearValues).toBeInTheDocument;
    expect(btnClearValues.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_btnClearValues")
    );
  });
  test("Custom Test Cases for btnClearValues", () => {
    // START_USER_CODE-USER_btnClearValues_TEST
    // END_USER_CODE-USER_btnClearValues_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridContractApplications(Grid Widget) Test Cases", async () => {
    let gridContractApplications = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplicationsbtn =
      gridContractApplications.nextElementSibling.firstElementChild;
    gridContractApplications =
      gridContractApplications.parentElement.parentElement.parentElement;
    expect(gridContractApplications.tagName).toBe("DIV");
    expect(gridContractApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridContractApplications", () => {
    // START_USER_CODE-USER_gridContractApplications_TEST
    // END_USER_CODE-USER_gridContractApplications_TEST
  });
  test("gridSeedGrowerApplications(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplicationsbtn =
      gridSeedGrowerApplications.nextElementSibling.firstElementChild;
    gridSeedGrowerApplications =
      gridSeedGrowerApplications.parentElement.parentElement.parentElement;
    expect(gridSeedGrowerApplications.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSeedGrowerApplications", () => {
    // START_USER_CODE-USER_gridSeedGrowerApplications_TEST
    // END_USER_CODE-USER_gridSeedGrowerApplications_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxContractApplication(GroupBox Widget) Test Cases", async () => {
    const grpbxContractApplication = screen.getByTestId(
      "grpbxContractApplication"
    );
    expect(grpbxContractApplication.tagName).toBe("BUTTON");
    expect(grpbxContractApplication.type).toBe("button");
    expect(grpbxContractApplication.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractApplication", () => {
    // START_USER_CODE-USER_grpbxContractApplication_TEST
    // END_USER_CODE-USER_grpbxContractApplication_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxSeedGroverApplications(GroupBox Widget) Test Cases", async () => {
    const grpbxSeedGroverApplications = screen.getByTestId(
      "grpbxSeedGroverApplications"
    );
    expect(grpbxSeedGroverApplications.tagName).toBe("BUTTON");
    expect(grpbxSeedGroverApplications.type).toBe("button");
    expect(grpbxSeedGroverApplications.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSeedGroverApplications", () => {
    // START_USER_CODE-USER_grpbxSeedGroverApplications_TEST
    // END_USER_CODE-USER_grpbxSeedGroverApplications_TEST
  });
  test("grpbxSpotApplication(GroupBox Widget) Test Cases", async () => {
    const grpbxSpotApplication = screen.getByTestId("grpbxSpotApplication");
    expect(grpbxSpotApplication.tagName).toBe("BUTTON");
    expect(grpbxSpotApplication.type).toBe("button");
    expect(grpbxSpotApplication.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpotApplication", () => {
    // START_USER_CODE-USER_grpbxSpotApplication_TEST
    // END_USER_CODE-USER_grpbxSpotApplication_TEST
  });
  test("grpbxWhouseApplications(GroupBox Widget) Test Cases", async () => {
    const grpbxWhouseApplications = screen.getByTestId(
      "grpbxWhouseApplications"
    );
    expect(grpbxWhouseApplications.tagName).toBe("BUTTON");
    expect(grpbxWhouseApplications.type).toBe("button");
    expect(grpbxWhouseApplications.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWhouseApplications", () => {
    // START_USER_CODE-USER_grpbxWhouseApplications_TEST
    // END_USER_CODE-USER_grpbxWhouseApplications_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblContractApplications(Label Widget) Test Cases", async () => {
    const lblContractApplications = screen.getByTestId(
      "lblContractApplications"
    );
    expect(lblContractApplications.tagName).toBe("LABEL");
    expect(lblContractApplications.classList).toContain("form-label");
    expect(lblContractApplications.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblContractApplications")
    );
  });
  test("Custom Test Cases for lblContractApplications", () => {
    // START_USER_CODE-USER_lblContractApplications_TEST
    // END_USER_CODE-USER_lblContractApplications_TEST
  });
  test("lblSeedGrowerApplications(Label Widget) Test Cases", async () => {
    const lblSeedGrowerApplications = screen.getByTestId(
      "lblSeedGrowerApplications"
    );
    expect(lblSeedGrowerApplications.tagName).toBe("LABEL");
    expect(lblSeedGrowerApplications.classList).toContain("form-label");
    expect(lblSeedGrowerApplications.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblSeedGrowerApplications")
    );
  });
  test("Custom Test Cases for lblSeedGrowerApplications", () => {
    // START_USER_CODE-USER_lblSeedGrowerApplications_TEST
    // END_USER_CODE-USER_lblSeedGrowerApplications_TEST
  });
  test("lblSpotApplication(Label Widget) Test Cases", async () => {
    const lblSpotApplication = screen.getByTestId("lblSpotApplication");
    expect(lblSpotApplication.tagName).toBe("LABEL");
    expect(lblSpotApplication.classList).toContain("form-label");
    expect(lblSpotApplication.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_lblSpotApplication")
    );
  });
  test("Custom Test Cases for lblSpotApplication", () => {
    // START_USER_CODE-USER_lblSpotApplication_TEST
    // END_USER_CODE-USER_lblSpotApplication_TEST
  });
  test("gridSeedGrowerApplications_txtcol1SGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcol1SGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcol1SGAbtn =
      gridSeedGrowerApplications_txtcol1SGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcol1SGA =
      gridSeedGrowerApplications_txtcol1SGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcol1SGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcol1SGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1SGA", () => {
    // START_USER_CODE-USER_txtcol1SGA_TEST
    // END_USER_CODE-USER_txtcol1SGA_TEST
  });
  test("gridContractApplications_txtcolApplied(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolApplied = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolAppliedbtn =
      gridContractApplications_txtcolApplied.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolApplied =
      gridContractApplications_txtcolApplied.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolApplied.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolApplied.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolApplied", () => {
    // START_USER_CODE-USER_txtcolApplied_TEST
    // END_USER_CODE-USER_txtcolApplied_TEST
  });
  test("gridSeedGrowerApplications_txtcolAppliedSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolAppliedSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolAppliedSGAbtn =
      gridSeedGrowerApplications_txtcolAppliedSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolAppliedSGA =
      gridSeedGrowerApplications_txtcolAppliedSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolAppliedSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolAppliedSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedSGA", () => {
    // START_USER_CODE-USER_txtcolAppliedSGA_TEST
    // END_USER_CODE-USER_txtcolAppliedSGA_TEST
  });
  test("gridContractApplications_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolBuyPt = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolBuyPtbtn =
      gridContractApplications_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolBuyPt =
      gridContractApplications_txtcolBuyPt.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolBuyPt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridSeedGrowerApplications_txtcolBuyPtSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolBuyPtSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolBuyPtSGAbtn =
      gridSeedGrowerApplications_txtcolBuyPtSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolBuyPtSGA =
      gridSeedGrowerApplications_txtcolBuyPtSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolBuyPtSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolBuyPtSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPtSGA", () => {
    // START_USER_CODE-USER_txtcolBuyPtSGA_TEST
    // END_USER_CODE-USER_txtcolBuyPtSGA_TEST
  });
  test("gridContractApplications_txtcolContractDate(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContractDate = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContractDatebtn =
      gridContractApplications_txtcolContractDate.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContractDate =
      gridContractApplications_txtcolContractDate.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContractDate.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContractDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractDate", () => {
    // START_USER_CODE-USER_txtcolContractDate_TEST
    // END_USER_CODE-USER_txtcolContractDate_TEST
  });
  test("gridSeedGrowerApplications_txtcolContractDateSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContractDateSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContractDateSGAbtn =
      gridSeedGrowerApplications_txtcolContractDateSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContractDateSGA =
      gridSeedGrowerApplications_txtcolContractDateSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolContractDateSGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolContractDateSGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractDateSGA", () => {
    // START_USER_CODE-USER_txtcolContractDateSGA_TEST
    // END_USER_CODE-USER_txtcolContractDateSGA_TEST
  });
  test("gridContractApplications_txtcolContractLbs(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContractLbs = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContractLbsbtn =
      gridContractApplications_txtcolContractLbs.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContractLbs =
      gridContractApplications_txtcolContractLbs.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContractLbs.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContractLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractLbs", () => {
    // START_USER_CODE-USER_txtcolContractLbs_TEST
    // END_USER_CODE-USER_txtcolContractLbs_TEST
  });
  test("gridSeedGrowerApplications_txtcolContractLbsSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContractLbsSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContractLbsSGAbtn =
      gridSeedGrowerApplications_txtcolContractLbsSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContractLbsSGA =
      gridSeedGrowerApplications_txtcolContractLbsSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolContractLbsSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolContractLbsSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractLbsSGA", () => {
    // START_USER_CODE-USER_txtcolContractLbsSGA_TEST
    // END_USER_CODE-USER_txtcolContractLbsSGA_TEST
  });
  test("gridContractApplications_txtcolContractNumber(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContractNumber = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContractNumberbtn =
      gridContractApplications_txtcolContractNumber.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContractNumber =
      gridContractApplications_txtcolContractNumber.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContractNumber.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContractNumber.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractNumber", () => {
    // START_USER_CODE-USER_txtcolContractNumber_TEST
    // END_USER_CODE-USER_txtcolContractNumber_TEST
  });
  test("gridSeedGrowerApplications_txtcolContractNumberSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContractNumberSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContractNumberSGAbtn =
      gridSeedGrowerApplications_txtcolContractNumberSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContractNumberSGA =
      gridSeedGrowerApplications_txtcolContractNumberSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolContractNumberSGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolContractNumberSGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractNumberSGA", () => {
    // START_USER_CODE-USER_txtcolContractNumberSGA_TEST
    // END_USER_CODE-USER_txtcolContractNumberSGA_TEST
  });
  test("gridContractApplications_txtcolContType(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContType = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContTypebtn =
      gridContractApplications_txtcolContType.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContType =
      gridContractApplications_txtcolContType.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContType.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContType", () => {
    // START_USER_CODE-USER_txtcolContType_TEST
    // END_USER_CODE-USER_txtcolContType_TEST
  });
  test("gridSeedGrowerApplications_txtcolContTypeSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContTypeSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContTypeSGAbtn =
      gridSeedGrowerApplications_txtcolContTypeSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContTypeSGA =
      gridSeedGrowerApplications_txtcolContTypeSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolContTypeSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolContTypeSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContTypeSGA", () => {
    // START_USER_CODE-USER_txtcolContTypeSGA_TEST
    // END_USER_CODE-USER_txtcolContTypeSGA_TEST
  });
  test("gridContractApplications_txtcolDelvEndDt(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolDelvEndDt = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolDelvEndDtbtn =
      gridContractApplications_txtcolDelvEndDt.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolDelvEndDt =
      gridContractApplications_txtcolDelvEndDt.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolDelvEndDt.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolDelvEndDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvEndDt", () => {
    // START_USER_CODE-USER_txtcolDelvEndDt_TEST
    // END_USER_CODE-USER_txtcolDelvEndDt_TEST
  });
  test("gridContractApplications_txtcolDelvStartDt(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolDelvStartDt = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolDelvStartDtbtn =
      gridContractApplications_txtcolDelvStartDt.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolDelvStartDt =
      gridContractApplications_txtcolDelvStartDt.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolDelvStartDt.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolDelvStartDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvStartDt", () => {
    // START_USER_CODE-USER_txtcolDelvStartDt_TEST
    // END_USER_CODE-USER_txtcolDelvStartDt_TEST
  });
  test("gridContractApplications_txtcolGrouping(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolGrouping = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolGroupingbtn =
      gridContractApplications_txtcolGrouping.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolGrouping =
      gridContractApplications_txtcolGrouping.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolGrouping.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolGrouping.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrouping", () => {
    // START_USER_CODE-USER_txtcolGrouping_TEST
    // END_USER_CODE-USER_txtcolGrouping_TEST
  });
  test("gridContractApplications_txtcolGrpShareLbs(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolGrpShareLbs = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolGrpShareLbsbtn =
      gridContractApplications_txtcolGrpShareLbs.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolGrpShareLbs =
      gridContractApplications_txtcolGrpShareLbs.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolGrpShareLbs.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolGrpShareLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrpShareLbs", () => {
    // START_USER_CODE-USER_txtcolGrpShareLbs_TEST
    // END_USER_CODE-USER_txtcolGrpShareLbs_TEST
  });
  test("gridContractApplications_txtcolGrpSharePct(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolGrpSharePct = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolGrpSharePctbtn =
      gridContractApplications_txtcolGrpSharePct.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolGrpSharePct =
      gridContractApplications_txtcolGrpSharePct.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolGrpSharePct.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolGrpSharePct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrpSharePct", () => {
    // START_USER_CODE-USER_txtcolGrpSharePct_TEST
    // END_USER_CODE-USER_txtcolGrpSharePct_TEST
  });
  test("gridContractApplications_txtcolLbsToApply(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolLbsToApply = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolLbsToApplybtn =
      gridContractApplications_txtcolLbsToApply.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolLbsToApply =
      gridContractApplications_txtcolLbsToApply.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolLbsToApply.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolLbsToApply.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLbsToApply", () => {
    // START_USER_CODE-USER_txtcolLbsToApply_TEST
    // END_USER_CODE-USER_txtcolLbsToApply_TEST
  });
  test("gridContractApplications_txtcolOpenBalance(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolOpenBalance = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolOpenBalancebtn =
      gridContractApplications_txtcolOpenBalance.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolOpenBalance =
      gridContractApplications_txtcolOpenBalance.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolOpenBalance.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolOpenBalance.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenBalance", () => {
    // START_USER_CODE-USER_txtcolOpenBalance_TEST
    // END_USER_CODE-USER_txtcolOpenBalance_TEST
  });
  test("gridSeedGrowerApplications_txtcolOpenBalance1SGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolOpenBalance1SGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolOpenBalance1SGAbtn =
      gridSeedGrowerApplications_txtcolOpenBalance1SGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolOpenBalance1SGA =
      gridSeedGrowerApplications_txtcolOpenBalance1SGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolOpenBalance1SGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolOpenBalance1SGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenBalance1SGA", () => {
    // START_USER_CODE-USER_txtcolOpenBalance1SGA_TEST
    // END_USER_CODE-USER_txtcolOpenBalance1SGA_TEST
  });
  test("gridSeedGrowerApplications_txtcolOpenBalance2SGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolOpenBalance2SGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolOpenBalance2SGAbtn =
      gridSeedGrowerApplications_txtcolOpenBalance2SGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolOpenBalance2SGA =
      gridSeedGrowerApplications_txtcolOpenBalance2SGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolOpenBalance2SGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolOpenBalance2SGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenBalance2SGA", () => {
    // START_USER_CODE-USER_txtcolOpenBalance2SGA_TEST
    // END_USER_CODE-USER_txtcolOpenBalance2SGA_TEST
  });
  test("gridContractApplications_txtcolOrder(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolOrder = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolOrderbtn =
      gridContractApplications_txtcolOrder.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolOrder =
      gridContractApplications_txtcolOrder.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolOrder.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolOrder.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrder", () => {
    // START_USER_CODE-USER_txtcolOrder_TEST
    // END_USER_CODE-USER_txtcolOrder_TEST
  });
  test("gridSeedGrowerApplications_txtcolOrderSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolOrderSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolOrderSGAbtn =
      gridSeedGrowerApplications_txtcolOrderSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolOrderSGA =
      gridSeedGrowerApplications_txtcolOrderSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolOrderSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolOrderSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrderSGA", () => {
    // START_USER_CODE-USER_txtcolOrderSGA_TEST
    // END_USER_CODE-USER_txtcolOrderSGA_TEST
  });
  test("gridContractApplications_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolPeanutVariety = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolPeanutVarietybtn =
      gridContractApplications_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolPeanutVariety =
      gridContractApplications_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridSeedGrowerApplications_txtcolPeanutVarietySGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolPeanutVarietySGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolPeanutVarietySGAbtn =
      gridSeedGrowerApplications_txtcolPeanutVarietySGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolPeanutVarietySGA =
      gridSeedGrowerApplications_txtcolPeanutVarietySGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolPeanutVarietySGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolPeanutVarietySGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVarietySGA", () => {
    // START_USER_CODE-USER_txtcolPeanutVarietySGA_TEST
    // END_USER_CODE-USER_txtcolPeanutVarietySGA_TEST
  });
  test("gridSeedGrowerApplications_txtcolPoundsToApplySGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolPoundsToApplySGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolPoundsToApplySGAbtn =
      gridSeedGrowerApplications_txtcolPoundsToApplySGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolPoundsToApplySGA =
      gridSeedGrowerApplications_txtcolPoundsToApplySGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolPoundsToApplySGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolPoundsToApplySGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPoundsToApplySGA", () => {
    // START_USER_CODE-USER_txtcolPoundsToApplySGA_TEST
    // END_USER_CODE-USER_txtcolPoundsToApplySGA_TEST
  });
  test("gridContractApplications_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolPrice = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolPricebtn =
      gridContractApplications_txtcolPrice.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolPrice =
      gridContractApplications_txtcolPrice.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolPrice.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridSeedGrowerApplications_txtcolPriceSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolPriceSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolPriceSGAbtn =
      gridSeedGrowerApplications_txtcolPriceSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolPriceSGA =
      gridSeedGrowerApplications_txtcolPriceSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolPriceSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolPriceSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceSGA", () => {
    // START_USER_CODE-USER_txtcolPriceSGA_TEST
    // END_USER_CODE-USER_txtcolPriceSGA_TEST
  });
  test("gridContractApplications_txtcolSeed(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolSeed = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolSeedbtn =
      gridContractApplications_txtcolSeed.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolSeed =
      gridContractApplications_txtcolSeed.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolSeed.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeed", () => {
    // START_USER_CODE-USER_txtcolSeed_TEST
    // END_USER_CODE-USER_txtcolSeed_TEST
  });
  test("gridContractApplications_txtcolSeedCont(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolSeedCont = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolSeedContbtn =
      gridContractApplications_txtcolSeedCont.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolSeedCont =
      gridContractApplications_txtcolSeedCont.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolSeedCont.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolSeedCont.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedCont", () => {
    // START_USER_CODE-USER_txtcolSeedCont_TEST
    // END_USER_CODE-USER_txtcolSeedCont_TEST
  });
  test("gridContractApplications_txtcolSeedOpen(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolSeedOpen = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolSeedOpenbtn =
      gridContractApplications_txtcolSeedOpen.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolSeedOpen =
      gridContractApplications_txtcolSeedOpen.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolSeedOpen.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolSeedOpen.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedOpen", () => {
    // START_USER_CODE-USER_txtcolSeedOpen_TEST
    // END_USER_CODE-USER_txtcolSeedOpen_TEST
  });
  test("gridSeedGrowerApplications_txtcolSeedSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolSeedSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolSeedSGAbtn =
      gridSeedGrowerApplications_txtcolSeedSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolSeedSGA =
      gridSeedGrowerApplications_txtcolSeedSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolSeedSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolSeedSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedSGA", () => {
    // START_USER_CODE-USER_txtcolSeedSGA_TEST
    // END_USER_CODE-USER_txtcolSeedSGA_TEST
  });
  test("gridContractApplications_txtcolSeedTBD(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolSeedTBD = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolSeedTBDbtn =
      gridContractApplications_txtcolSeedTBD.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolSeedTBD =
      gridContractApplications_txtcolSeedTBD.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolSeedTBD.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolSeedTBD.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedTBD", () => {
    // START_USER_CODE-USER_txtcolSeedTBD_TEST
    // END_USER_CODE-USER_txtcolSeedTBD_TEST
  });
  test("gridSeedGrowerApplications_txtcolSeedTBDSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolSeedTBDSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolSeedTBDSGAbtn =
      gridSeedGrowerApplications_txtcolSeedTBDSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolSeedTBDSGA =
      gridSeedGrowerApplications_txtcolSeedTBDSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolSeedTBDSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolSeedTBDSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedTBDSGA", () => {
    // START_USER_CODE-USER_txtcolSeedTBDSGA_TEST
    // END_USER_CODE-USER_txtcolSeedTBDSGA_TEST
  });
  test("gridContractApplications_txtcolTentApplied(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolTentApplied = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolTentAppliedbtn =
      gridContractApplications_txtcolTentApplied.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolTentApplied =
      gridContractApplications_txtcolTentApplied.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolTentApplied.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolTentApplied.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTentApplied", () => {
    // START_USER_CODE-USER_txtcolTentApplied_TEST
    // END_USER_CODE-USER_txtcolTentApplied_TEST
  });
  test("gridSeedGrowerApplications_txtcolTentAppliedSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolTentAppliedSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolTentAppliedSGAbtn =
      gridSeedGrowerApplications_txtcolTentAppliedSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolTentAppliedSGA =
      gridSeedGrowerApplications_txtcolTentAppliedSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolTentAppliedSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolTentAppliedSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTentAppliedSGA", () => {
    // START_USER_CODE-USER_txtcolTentAppliedSGA_TEST
    // END_USER_CODE-USER_txtcolTentAppliedSGA_TEST
  });
  test("gridContractApplications_txtcolTotalApplication(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolTotalApplication = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolTotalApplicationbtn =
      gridContractApplications_txtcolTotalApplication.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolTotalApplication =
      gridContractApplications_txtcolTotalApplication.parentElement
        .parentElement.parentElement;
    expect(gridContractApplications_txtcolTotalApplication.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolTotalApplication.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalApplication", () => {
    // START_USER_CODE-USER_txtcolTotalApplication_TEST
    // END_USER_CODE-USER_txtcolTotalApplication_TEST
  });
  test("gridSeedGrowerApplications_txtcoltotalApplicationSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcoltotalApplicationSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcoltotalApplicationSGAbtn =
      gridSeedGrowerApplications_txtcoltotalApplicationSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcoltotalApplicationSGA =
      gridSeedGrowerApplications_txtcoltotalApplicationSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcoltotalApplicationSGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcoltotalApplicationSGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcoltotalApplicationSGA", () => {
    // START_USER_CODE-USER_txtcoltotalApplicationSGA_TEST
    // END_USER_CODE-USER_txtcoltotalApplicationSGA_TEST
  });
  test("txtLbsToApply(Textbox Widget) Test Cases", async () => {
    const txtLbsToApply = screen.getByTestId("txtLbsToApply");
    expect(txtLbsToApply.tagName).toBe("INPUT");
    expect(txtLbsToApply.type).toBe("text");
    expect(txtLbsToApply.classList).toContain("textboxWidgetClass");
    expect(txtLbsToApply.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtLbsToApply")
    );
    await act(async () => {
      userEvent.type(txtLbsToApply, "123");
    });
    expect(txtLbsToApply.getAttribute("value")).toBe("");
    expect(txtLbsToApply.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLbsToApply", () => {
    // START_USER_CODE-USER_txtLbsToApply_TEST
    // END_USER_CODE-USER_txtLbsToApply_TEST
  });
  test("txtObligationLocation(Textbox Widget) Test Cases", async () => {
    const txtObligationLocation = screen.getByTestId("txtObligationLocation");
    expect(txtObligationLocation.tagName).toBe("INPUT");
    expect(txtObligationLocation.type).toBe("text");
    expect(txtObligationLocation.classList).toContain("textboxWidgetClass");
    expect(txtObligationLocation.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtObligationLocation")
    );
    await act(async () => {
      userEvent.type(txtObligationLocation, "123");
    });
    expect(txtObligationLocation.getAttribute("value")).toBe("");
    expect(txtObligationLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtObligationLocation", () => {
    // START_USER_CODE-USER_txtObligationLocation_TEST
    // END_USER_CODE-USER_txtObligationLocation_TEST
  });
  test("txtOrder(Textbox Widget) Test Cases", async () => {
    const txtOrder = screen.getByTestId("txtOrder");
    expect(txtOrder.tagName).toBe("INPUT");
    expect(txtOrder.type).toBe("text");
    expect(txtOrder.classList).toContain("textboxWidgetClass");
    expect(txtOrder.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtOrder")
    );
    await act(async () => {
      userEvent.type(txtOrder, "1");
    });
  });
  test("Custom Test Cases for txtOrder", () => {
    // START_USER_CODE-USER_txtOrder_TEST
    // END_USER_CODE-USER_txtOrder_TEST
  });
  test("txtOverideFarmNum(Textbox Widget) Test Cases", async () => {
    const txtOverideFarmNum = screen.getByTestId("txtOverideFarmNum");
    expect(txtOverideFarmNum.tagName).toBe("INPUT");
    expect(txtOverideFarmNum.type).toBe("text");
    expect(txtOverideFarmNum.classList).toContain("textboxWidgetClass");
    expect(txtOverideFarmNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtOverideFarmNum")
    );
    await act(async () => {
      userEvent.type(txtOverideFarmNum, "1");
    });
  });
  test("Custom Test Cases for txtOverideFarmNum", () => {
    // START_USER_CODE-USER_txtOverideFarmNum_TEST
    // END_USER_CODE-USER_txtOverideFarmNum_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPounds(Textbox Widget) Test Cases", async () => {
    const txtPounds = screen.getByTestId("txtPounds");
    expect(txtPounds.tagName).toBe("INPUT");
    expect(txtPounds.type).toBe("text");
    expect(txtPounds.classList).toContain("textboxWidgetClass");
    expect(txtPounds.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtPounds")
    );
    await act(async () => {
      userEvent.type(txtPounds, "123");
    });
    expect(txtPounds.getAttribute("value")).toBe("");
    expect(txtPounds.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds", () => {
    // START_USER_CODE-USER_txtPounds_TEST
    // END_USER_CODE-USER_txtPounds_TEST
  });
  test("txtPrice(Textbox Widget) Test Cases", async () => {
    const txtPrice = screen.getByTestId("txtPrice");
    expect(txtPrice.tagName).toBe("INPUT");
    expect(txtPrice.type).toBe("text");
    expect(txtPrice.classList).toContain("textboxWidgetClass");
    expect(txtPrice.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtPrice")
    );
    await act(async () => {
      userEvent.type(txtPrice, "123");
    });
    expect(txtPrice.getAttribute("value")).toBe("");
    expect(txtPrice.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPrice", () => {
    // START_USER_CODE-USER_txtPrice_TEST
    // END_USER_CODE-USER_txtPrice_TEST
  });
  test("txtSeedLbs(Textbox Widget) Test Cases", async () => {
    const txtSeedLbs = screen.getByTestId("txtSeedLbs");
    expect(txtSeedLbs.tagName).toBe("INPUT");
    expect(txtSeedLbs.type).toBe("text");
    expect(txtSeedLbs.classList).toContain("textboxWidgetClass");
    expect(txtSeedLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtSeedLbs")
    );
    await act(async () => {
      userEvent.type(txtSeedLbs, "123");
    });
    expect(txtSeedLbs.getAttribute("value")).toBe("");
    expect(txtSeedLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeedLbs", () => {
    // START_USER_CODE-USER_txtSeedLbs_TEST
    // END_USER_CODE-USER_txtSeedLbs_TEST
  });
  test("txtUnloadLocation(Textbox Widget) Test Cases", async () => {
    const txtUnloadLocation = screen.getByTestId("txtUnloadLocation");
    expect(txtUnloadLocation.tagName).toBe("INPUT");
    expect(txtUnloadLocation.type).toBe("text");
    expect(txtUnloadLocation.classList).toContain("textboxWidgetClass");
    expect(txtUnloadLocation.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtUnloadLocation")
    );
    await act(async () => {
      userEvent.type(txtUnloadLocation, "123");
    });
    expect(txtUnloadLocation.getAttribute("value")).toBe("");
    expect(txtUnloadLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtUnloadLocation", () => {
    // START_USER_CODE-USER_txtUnloadLocation_TEST
    // END_USER_CODE-USER_txtUnloadLocation_TEST
  });
  test("txtWarehouseReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceiptNum = screen.getByTestId("txtWarehouseReceiptNum");
    expect(txtWarehouseReceiptNum.tagName).toBe("INPUT");
    expect(txtWarehouseReceiptNum.type).toBe("text");
    expect(txtWarehouseReceiptNum.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseReceiptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseApplications_txtWarehouseReceiptNum")
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceiptNum, "123");
    });
    expect(txtWarehouseReceiptNum.getAttribute("value")).toBe("");
    expect(txtWarehouseReceiptNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWarehouseReceiptNum", () => {
    // START_USER_CODE-USER_txtWarehouseReceiptNum_TEST
    // END_USER_CODE-USER_txtWarehouseReceiptNum_TEST
  });
});
