import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { convertStringToJsonObject } from "../../../../common/ScreenInitialization";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip} from "../ParentWidgetFunc";

function ListSelectDeselectWidget(props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
    if (!conf.valueList || !conf.valueList.length) {
      conf.valueList = convertStringToJsonObject(conf.Options);
    }

    let options = conf.valueList.map(value => {
      return {
        value: value.key,
        label: value.description
      };
    });

    let selected = props.values[conf.name];

    let dualListControl = (
      <React.Fragment>
        <DualListBox
          options={options}
          selected={selected}
          disabled={!conf.Enabled}
          canFilter={conf.canFilter}
          preserveSelectOrder
          onChange={value => {
            let event = {
              type: "change",
              target: {
                id: conf.name,
                name: conf.name,
                value
              }
            };
            props.onChange(event);
          }}
          onBlur={() => {
            let event = {
              type: "blur",
              target: {
                id: conf.name,
                name: conf.name
              }
            };
            props.onBlur(event);
          }}
        />
        <Form.Control.Feedback
          className={checkInvalid(props,conf) ? "d-block" : "d-none"}
          type="invalid"
          aria-live="polite" role="alert"
        >
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );
    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        data-testid={conf.name}
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{dualListControl}</Col>
          ) : (
            <React.Fragment> {dualListControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default ListSelectDeselectWidget;
