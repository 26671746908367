/* eslint-disable*/
import React from "react";
import AccountPayment_SpecialPayablesProfile from "./SpecialPayablesProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecialPayablesProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayablesProfile />);
    });
  });
  afterEach(cleanup);
  test("is SpecialPayablesProfile Loads Successfully", () => {
    expect(screen.getByText("SpecialPayablesProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecialPayablesProfile", () => {
    // START_USER_CODE-USER_SpecialPayablesProfile_Custom_Test_Case
    // END_USER_CODE-USER_SpecialPayablesProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayablesProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateHoldPay(Button Widget) Test Cases", async () => {
    const btnCreateHoldPay = screen.getByTestId("btnCreateHoldPay");
    expect(btnCreateHoldPay).toBeInTheDocument;
    expect(btnCreateHoldPay.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnCreateHoldPay")
    );
  });
  test("Custom Test Cases for btnCreateHoldPay", () => {
    // START_USER_CODE-USER_btnCreateHoldPay_TEST
    // END_USER_CODE-USER_btnCreateHoldPay_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnPrintChecks(Button Widget) Test Cases", async () => {
    const btnPrintChecks = screen.getByTestId("btnPrintChecks");
    expect(btnPrintChecks).toBeInTheDocument;
    expect(btnPrintChecks.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnPrintChecks")
    );
  });
  test("Custom Test Cases for btnPrintChecks", () => {
    // START_USER_CODE-USER_btnPrintChecks_TEST
    // END_USER_CODE-USER_btnPrintChecks_TEST
  });
  test("btnSaveToBatch(Button Widget) Test Cases", async () => {
    const btnSaveToBatch = screen.getByTestId("btnSaveToBatch");
    expect(btnSaveToBatch).toBeInTheDocument;
    expect(btnSaveToBatch.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnSaveToBatch")
    );
  });
  test("Custom Test Cases for btnSaveToBatch", () => {
    // START_USER_CODE-USER_btnSaveToBatch_TEST
    // END_USER_CODE-USER_btnSaveToBatch_TEST
  });
  test("btnScanDocuments(Button Widget) Test Cases", async () => {
    const btnScanDocuments = screen.getByTestId("btnScanDocuments");
    expect(btnScanDocuments).toBeInTheDocument;
    expect(btnScanDocuments.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnScanDocuments")
    );
  });
  test("Custom Test Cases for btnScanDocuments", () => {
    // START_USER_CODE-USER_btnScanDocuments_TEST
    // END_USER_CODE-USER_btnScanDocuments_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("btnViewDocuments(Button Widget) Test Cases", async () => {
    const btnViewDocuments = screen.getByTestId("btnViewDocuments");
    expect(btnViewDocuments).toBeInTheDocument;
    expect(btnViewDocuments.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_btnViewDocuments")
    );
  });
  test("Custom Test Cases for btnViewDocuments", () => {
    // START_USER_CODE-USER_btnViewDocuments_TEST
    // END_USER_CODE-USER_btnViewDocuments_TEST
  });
  test("gridSpecialPaymentEntry(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry = screen.getByTestId("gridSpecialPaymentEntry");
    let gridSpecialPaymentEntrybtn =
      gridSpecialPaymentEntry.nextElementSibling.firstElementChild;
    gridSpecialPaymentEntry =
      gridSpecialPaymentEntry.parentElement.parentElement.parentElement;
    expect(gridSpecialPaymentEntry.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSpecialPaymentEntry", () => {
    // START_USER_CODE-USER_gridSpecialPaymentEntry_TEST
    // END_USER_CODE-USER_gridSpecialPaymentEntry_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCheckInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckInfo = screen.getByTestId("grpbxCheckInfo");
    expect(grpbxCheckInfo.tagName).toBe("BUTTON");
    expect(grpbxCheckInfo.type).toBe("button");
    expect(grpbxCheckInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckInfo", () => {
    // START_USER_CODE-USER_grpbxCheckInfo_TEST
    // END_USER_CODE-USER_grpbxCheckInfo_TEST
  });
  test("grpbxPendingEdit(GroupBox Widget) Test Cases", async () => {
    const grpbxPendingEdit = screen.getByTestId("grpbxPendingEdit");
    expect(grpbxPendingEdit.tagName).toBe("BUTTON");
    expect(grpbxPendingEdit.type).toBe("button");
    expect(grpbxPendingEdit.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPendingEdit", () => {
    // START_USER_CODE-USER_grpbxPendingEdit_TEST
    // END_USER_CODE-USER_grpbxPendingEdit_TEST
  });
  test("grpbxremarks(GroupBox Widget) Test Cases", async () => {
    const grpbxremarks = screen.getByTestId("grpbxremarks");
    expect(grpbxremarks.tagName).toBe("BUTTON");
    expect(grpbxremarks.type).toBe("button");
    expect(grpbxremarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxremarks", () => {
    // START_USER_CODE-USER_grpbxremarks_TEST
    // END_USER_CODE-USER_grpbxremarks_TEST
  });
  test("grpbxSpcPayableProfile1(GroupBox Widget) Test Cases", async () => {
    const grpbxSpcPayableProfile1 = screen.getByTestId(
      "grpbxSpcPayableProfile1"
    );
    expect(grpbxSpcPayableProfile1.tagName).toBe("BUTTON");
    expect(grpbxSpcPayableProfile1.type).toBe("button");
    expect(grpbxSpcPayableProfile1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpcPayableProfile1", () => {
    // START_USER_CODE-USER_grpbxSpcPayableProfile1_TEST
    // END_USER_CODE-USER_grpbxSpcPayableProfile1_TEST
  });
  test("grpbxSpcPayableProfile2(GroupBox Widget) Test Cases", async () => {
    const grpbxSpcPayableProfile2 = screen.getByTestId(
      "grpbxSpcPayableProfile2"
    );
    expect(grpbxSpcPayableProfile2.tagName).toBe("BUTTON");
    expect(grpbxSpcPayableProfile2.type).toBe("button");
    expect(grpbxSpcPayableProfile2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpcPayableProfile2", () => {
    // START_USER_CODE-USER_grpbxSpcPayableProfile2_TEST
    // END_USER_CODE-USER_grpbxSpcPayableProfile2_TEST
  });
  test("lblARBalance(Label Widget) Test Cases", async () => {
    const lblARBalance = screen.getByTestId("lblARBalance");
    expect(lblARBalance.tagName).toBe("LABEL");
    expect(lblARBalance.classList).toContain("form-label");
    expect(lblARBalance.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblARBalance")
    );
  });
  test("Custom Test Cases for lblARBalance", () => {
    // START_USER_CODE-USER_lblARBalance_TEST
    // END_USER_CODE-USER_lblARBalance_TEST
  });
  test("lblCRBalance(Label Widget) Test Cases", async () => {
    const lblCRBalance = screen.getByTestId("lblCRBalance");
    expect(lblCRBalance.tagName).toBe("LABEL");
    expect(lblCRBalance.classList).toContain("form-label");
    expect(lblCRBalance.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblCRBalance")
    );
  });
  test("Custom Test Cases for lblCRBalance", () => {
    // START_USER_CODE-USER_lblCRBalance_TEST
    // END_USER_CODE-USER_lblCRBalance_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("lblEdit(Label Widget) Test Cases", async () => {
    const lblEdit = screen.getByTestId("lblEdit");
    expect(lblEdit.tagName).toBe("LABEL");
    expect(lblEdit.classList).toContain("form-label");
    expect(lblEdit.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblEdit")
    );
  });
  test("Custom Test Cases for lblEdit", () => {
    // START_USER_CODE-USER_lblEdit_TEST
    // END_USER_CODE-USER_lblEdit_TEST
  });
  test("lblMemo(Label Widget) Test Cases", async () => {
    const lblMemo = screen.getByTestId("lblMemo");
    expect(lblMemo.tagName).toBe("LABEL");
    expect(lblMemo.classList).toContain("form-label");
    expect(lblMemo.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblMemo")
    );
  });
  test("Custom Test Cases for lblMemo", () => {
    // START_USER_CODE-USER_lblMemo_TEST
    // END_USER_CODE-USER_lblMemo_TEST
  });
  test("lblMemoValue(Label Widget) Test Cases", async () => {
    const lblMemoValue = screen.getByTestId("lblMemoValue");
    expect(lblMemoValue.tagName).toBe("LABEL");
    expect(lblMemoValue.classList).toContain("form-label");
    expect(lblMemoValue.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblMemoValue")
    );
  });
  test("Custom Test Cases for lblMemoValue", () => {
    // START_USER_CODE-USER_lblMemoValue_TEST
    // END_USER_CODE-USER_lblMemoValue_TEST
  });
  test("lblPending(Label Widget) Test Cases", async () => {
    const lblPending = screen.getByTestId("lblPending");
    expect(lblPending.tagName).toBe("LABEL");
    expect(lblPending.classList).toContain("form-label");
    expect(lblPending.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblPending")
    );
  });
  test("Custom Test Cases for lblPending", () => {
    // START_USER_CODE-USER_lblPending_TEST
    // END_USER_CODE-USER_lblPending_TEST
  });
  test("lblRemarksComment(Label Widget) Test Cases", async () => {
    const lblRemarksComment = screen.getByTestId("lblRemarksComment");
    expect(lblRemarksComment.tagName).toBe("LABEL");
    expect(lblRemarksComment.classList).toContain("form-label");
    expect(lblRemarksComment.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblRemarksComment")
    );
  });
  test("Custom Test Cases for lblRemarksComment", () => {
    // START_USER_CODE-USER_lblRemarksComment_TEST
    // END_USER_CODE-USER_lblRemarksComment_TEST
  });
  test("lblSRBalance(Label Widget) Test Cases", async () => {
    const lblSRBalance = screen.getByTestId("lblSRBalance");
    expect(lblSRBalance.tagName).toBe("LABEL");
    expect(lblSRBalance.classList).toContain("form-label");
    expect(lblSRBalance.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblSRBalance")
    );
  });
  test("Custom Test Cases for lblSRBalance", () => {
    // START_USER_CODE-USER_lblSRBalance_TEST
    // END_USER_CODE-USER_lblSRBalance_TEST
  });
  test("lblValOfRTP(Label Widget) Test Cases", async () => {
    const lblValOfRTP = screen.getByTestId("lblValOfRTP");
    expect(lblValOfRTP.tagName).toBe("LABEL");
    expect(lblValOfRTP.classList).toContain("form-label");
    expect(lblValOfRTP.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblValOfRTP")
    );
  });
  test("Custom Test Cases for lblValOfRTP", () => {
    // START_USER_CODE-USER_lblValOfRTP_TEST
    // END_USER_CODE-USER_lblValOfRTP_TEST
  });
  test("lblValOfRTPValue(Label Widget) Test Cases", async () => {
    const lblValOfRTPValue = screen.getByTestId("lblValOfRTPValue");
    expect(lblValOfRTPValue.tagName).toBe("LABEL");
    expect(lblValOfRTPValue.classList).toContain("form-label");
    expect(lblValOfRTPValue.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblValOfRTPValue")
    );
  });
  test("Custom Test Cases for lblValOfRTPValue", () => {
    // START_USER_CODE-USER_lblValOfRTPValue_TEST
    // END_USER_CODE-USER_lblValOfRTPValue_TEST
  });
  test("lblValueOfCheck(Label Widget) Test Cases", async () => {
    const lblValueOfCheck = screen.getByTestId("lblValueOfCheck");
    expect(lblValueOfCheck.tagName).toBe("LABEL");
    expect(lblValueOfCheck.classList).toContain("form-label");
    expect(lblValueOfCheck.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblValueOfCheck")
    );
  });
  test("Custom Test Cases for lblValueOfCheck", () => {
    // START_USER_CODE-USER_lblValueOfCheck_TEST
    // END_USER_CODE-USER_lblValueOfCheck_TEST
  });
  test("lblValueOfCheckValue(Label Widget) Test Cases", async () => {
    const lblValueOfCheckValue = screen.getByTestId("lblValueOfCheckValue");
    expect(lblValueOfCheckValue.tagName).toBe("LABEL");
    expect(lblValueOfCheckValue.classList).toContain("form-label");
    expect(lblValueOfCheckValue.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_lblValueOfCheckValue")
    );
  });
  test("Custom Test Cases for lblValueOfCheckValue", () => {
    // START_USER_CODE-USER_lblValueOfCheckValue_TEST
    // END_USER_CODE-USER_lblValueOfCheckValue_TEST
  });
  test("txtCheck(Textbox Widget) Test Cases", async () => {
    const txtCheck = screen.getByTestId("txtCheck");
    expect(txtCheck.tagName).toBe("INPUT");
    expect(txtCheck.type).toBe("text");
    expect(txtCheck.classList).toContain("textboxWidgetClass");
    expect(txtCheck.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtCheck")
    );
    await act(async () => {
      userEvent.type(txtCheck, "1");
    });
  });
  test("Custom Test Cases for txtCheck", () => {
    // START_USER_CODE-USER_txtCheck_TEST
    // END_USER_CODE-USER_txtCheck_TEST
  });
  test("gridSpecialPaymentEntry_txtcolChargeLocation(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolChargeLocation = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolChargeLocationbtn =
      gridSpecialPaymentEntry_txtcolChargeLocation.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolChargeLocation =
      gridSpecialPaymentEntry_txtcolChargeLocation.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolChargeLocation.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolChargeLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChargeLocation", () => {
    // START_USER_CODE-USER_txtcolChargeLocation_TEST
    // END_USER_CODE-USER_txtcolChargeLocation_TEST
  });
  test("gridSpecialPaymentEntry_txtcolComments(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolComments = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolCommentsbtn =
      gridSpecialPaymentEntry_txtcolComments.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolComments =
      gridSpecialPaymentEntry_txtcolComments.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolComments.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolComments.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolComments", () => {
    // START_USER_CODE-USER_txtcolComments_TEST
    // END_USER_CODE-USER_txtcolComments_TEST
  });
  test("gridSpecialPaymentEntry_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolDate = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolDatebtn =
      gridSpecialPaymentEntry_txtcolDate.nextElementSibling.firstElementChild;
    gridSpecialPaymentEntry_txtcolDate =
      gridSpecialPaymentEntry_txtcolDate.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolDate.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridSpecialPaymentEntry_txtcolInvoiceNumber(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolInvoiceNumber = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolInvoiceNumberbtn =
      gridSpecialPaymentEntry_txtcolInvoiceNumber.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolInvoiceNumber =
      gridSpecialPaymentEntry_txtcolInvoiceNumber.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolInvoiceNumber.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolInvoiceNumber.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInvoiceNumber", () => {
    // START_USER_CODE-USER_txtcolInvoiceNumber_TEST
    // END_USER_CODE-USER_txtcolInvoiceNumber_TEST
  });
  test("gridSpecialPaymentEntry_txtcolItem(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolItem = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolItembtn =
      gridSpecialPaymentEntry_txtcolItem.nextElementSibling.firstElementChild;
    gridSpecialPaymentEntry_txtcolItem =
      gridSpecialPaymentEntry_txtcolItem.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolItem.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolItem.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolItem", () => {
    // START_USER_CODE-USER_txtcolItem_TEST
    // END_USER_CODE-USER_txtcolItem_TEST
  });
  test("gridSpecialPaymentEntry_txtcolItemCode(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolItemCode = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolItemCodebtn =
      gridSpecialPaymentEntry_txtcolItemCode.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolItemCode =
      gridSpecialPaymentEntry_txtcolItemCode.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolItemCode.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolItemCode.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolItemCode", () => {
    // START_USER_CODE-USER_txtcolItemCode_TEST
    // END_USER_CODE-USER_txtcolItemCode_TEST
  });
  test("txtCollectAR(Textbox Widget) Test Cases", async () => {
    const txtCollectAR = screen.getByTestId("txtCollectAR");
    expect(txtCollectAR.tagName).toBe("INPUT");
    expect(txtCollectAR.type).toBe("text");
    expect(txtCollectAR.classList).toContain("textboxWidgetClass");
    expect(txtCollectAR.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtCollectAR")
    );
    await act(async () => {
      userEvent.type(txtCollectAR, "1");
    });
  });
  test("Custom Test Cases for txtCollectAR", () => {
    // START_USER_CODE-USER_txtCollectAR_TEST
    // END_USER_CODE-USER_txtCollectAR_TEST
  });
  test("txtCollectCR(Textbox Widget) Test Cases", async () => {
    const txtCollectCR = screen.getByTestId("txtCollectCR");
    expect(txtCollectCR.tagName).toBe("INPUT");
    expect(txtCollectCR.type).toBe("text");
    expect(txtCollectCR.classList).toContain("textboxWidgetClass");
    expect(txtCollectCR.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtCollectCR")
    );
    await act(async () => {
      userEvent.type(txtCollectCR, "1");
    });
  });
  test("Custom Test Cases for txtCollectCR", () => {
    // START_USER_CODE-USER_txtCollectCR_TEST
    // END_USER_CODE-USER_txtCollectCR_TEST
  });
  test("txtCollectSR(Textbox Widget) Test Cases", async () => {
    const txtCollectSR = screen.getByTestId("txtCollectSR");
    expect(txtCollectSR.tagName).toBe("INPUT");
    expect(txtCollectSR.type).toBe("text");
    expect(txtCollectSR.classList).toContain("textboxWidgetClass");
    expect(txtCollectSR.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtCollectSR")
    );
    await act(async () => {
      userEvent.type(txtCollectSR, "1");
    });
  });
  test("Custom Test Cases for txtCollectSR", () => {
    // START_USER_CODE-USER_txtCollectSR_TEST
    // END_USER_CODE-USER_txtCollectSR_TEST
  });
  test("gridSpecialPaymentEntry_txtcolOleicInd(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolOleicInd = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolOleicIndbtn =
      gridSpecialPaymentEntry_txtcolOleicInd.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolOleicInd =
      gridSpecialPaymentEntry_txtcolOleicInd.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolOleicInd.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolOleicInd.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOleicInd", () => {
    // START_USER_CODE-USER_txtcolOleicInd_TEST
    // END_USER_CODE-USER_txtcolOleicInd_TEST
  });
  test("gridSpecialPaymentEntry_txtcolPayAmount(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolPayAmount = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolPayAmountbtn =
      gridSpecialPaymentEntry_txtcolPayAmount.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolPayAmount =
      gridSpecialPaymentEntry_txtcolPayAmount.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolPayAmount.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolPayAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayAmount", () => {
    // START_USER_CODE-USER_txtcolPayAmount_TEST
    // END_USER_CODE-USER_txtcolPayAmount_TEST
  });
  test("gridSpecialPaymentEntry_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolPeanutType = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolPeanutTypebtn =
      gridSpecialPaymentEntry_txtcolPeanutType.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolPeanutType =
      gridSpecialPaymentEntry_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolPeanutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridSpecialPaymentEntry_txtcolSeedGeneration(Grid Widget) Test Cases", async () => {
    let gridSpecialPaymentEntry_txtcolSeedGeneration = screen.getByTestId(
      "gridSpecialPaymentEntry"
    );
    let gridSpecialPaymentEntry_txtcolSeedGenerationbtn =
      gridSpecialPaymentEntry_txtcolSeedGeneration.nextElementSibling
        .firstElementChild;
    gridSpecialPaymentEntry_txtcolSeedGeneration =
      gridSpecialPaymentEntry_txtcolSeedGeneration.parentElement.parentElement
        .parentElement;
    expect(gridSpecialPaymentEntry_txtcolSeedGeneration.tagName).toBe("DIV");
    expect(gridSpecialPaymentEntry_txtcolSeedGeneration.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesProfile_gridSpecialPaymentEntry")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGeneration", () => {
    // START_USER_CODE-USER_txtcolSeedGeneration_TEST
    // END_USER_CODE-USER_txtcolSeedGeneration_TEST
  });
  test("txtComment(Textbox Widget) Test Cases", async () => {
    const txtComment = screen.getByTestId("txtComment");
    expect(txtComment.tagName).toBe("INPUT");
    expect(txtComment.type).toBe("text");
    expect(txtComment.classList).toContain("textboxWidgetClass");
    expect(txtComment.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtComment")
    );
    await act(async () => {
      userEvent.type(txtComment, "1");
    });
  });
  test("Custom Test Cases for txtComment", () => {
    // START_USER_CODE-USER_txtComment_TEST
    // END_USER_CODE-USER_txtComment_TEST
  });
  test("txtDate(Textbox Widget) Test Cases", async () => {
    const txtDate = screen.getByTestId("txtDate");
    expect(txtDate.tagName).toBe("INPUT");
    expect(txtDate.type).toBe("text");
    expect(txtDate.classList).toContain("textboxWidgetClass");
    expect(txtDate.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtDate")
    );
    await act(async () => {
      userEvent.type(txtDate, "123");
    });
    expect(txtDate.getAttribute("value")).toBe("");
    expect(txtDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDate", () => {
    // START_USER_CODE-USER_txtDate_TEST
    // END_USER_CODE-USER_txtDate_TEST
  });
  test("txtEndCheckNum(Textbox Widget) Test Cases", async () => {
    const txtEndCheckNum = screen.getByTestId("txtEndCheckNum");
    expect(txtEndCheckNum.tagName).toBe("INPUT");
    expect(txtEndCheckNum.type).toBe("text");
    expect(txtEndCheckNum.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEndCheckNum, "1");
    });
  });
  test("Custom Test Cases for txtEndCheckNum", () => {
    // START_USER_CODE-USER_txtEndCheckNum_TEST
    // END_USER_CODE-USER_txtEndCheckNum_TEST
  });
  test("txtInvoiceNumber(Textbox Widget) Test Cases", async () => {
    const txtInvoiceNumber = screen.getByTestId("txtInvoiceNumber");
    expect(txtInvoiceNumber.tagName).toBe("INPUT");
    expect(txtInvoiceNumber.type).toBe("text");
    expect(txtInvoiceNumber.classList).toContain("textboxWidgetClass");
    expect(txtInvoiceNumber.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtInvoiceNumber")
    );
    await act(async () => {
      userEvent.type(txtInvoiceNumber, "123");
    });
    expect(txtInvoiceNumber.getAttribute("value")).toBe("");
    expect(txtInvoiceNumber.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtInvoiceNumber", () => {
    // START_USER_CODE-USER_txtInvoiceNumber_TEST
    // END_USER_CODE-USER_txtInvoiceNumber_TEST
  });
  test("txtPayAmount(Textbox Widget) Test Cases", async () => {
    const txtPayAmount = screen.getByTestId("txtPayAmount");
    expect(txtPayAmount.tagName).toBe("INPUT");
    expect(txtPayAmount.type).toBe("text");
    expect(txtPayAmount.classList).toContain("textboxWidgetClass");
    expect(txtPayAmount.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtPayAmount")
    );
    await act(async () => {
      userEvent.type(txtPayAmount, "123");
    });
    expect(txtPayAmount.getAttribute("value")).toBe("");
    expect(txtPayAmount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPayAmount", () => {
    // START_USER_CODE-USER_txtPayAmount_TEST
    // END_USER_CODE-USER_txtPayAmount_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
