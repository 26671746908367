/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ViewWRPayments from "./ViewWRPayments";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewWRPayments Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewWRPayments />);
    });
  });
  afterEach(cleanup);
  test("is ViewWRPayments Loads Successfully", () => {
    expect(screen.getByText("ViewWRPayments")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewWRPayments", () => {
    // START_USER_CODE-USER_ViewWRPayments_Custom_Test_Case
    // END_USER_CODE-USER_ViewWRPayments_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewWRPayments />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:ViewWRPayments_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:ViewWRPayments_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("WarehouseReceipts:ViewWRPayments_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridPayments(Grid Widget) Test Cases", async () => {
    let gridPayments = screen.getByTestId("gridPayments");
    let gridPaymentsbtn = gridPayments.nextElementSibling.firstElementChild;
    gridPayments = gridPayments.parentElement.parentElement.parentElement;
    expect(gridPayments.tagName).toBe("DIV");
    expect(gridPayments.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPayments", () => {
    // START_USER_CODE-USER_gridPayments_TEST
    // END_USER_CODE-USER_gridPayments_TEST
  });
  test("grpbxViewWRPayments(GroupBox Widget) Test Cases", async () => {
    const grpbxViewWRPayments = screen.getByTestId("grpbxViewWRPayments");
    expect(grpbxViewWRPayments.tagName).toBe("BUTTON");
    expect(grpbxViewWRPayments.type).toBe("button");
    expect(grpbxViewWRPayments.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewWRPayments", () => {
    // START_USER_CODE-USER_grpbxViewWRPayments_TEST
    // END_USER_CODE-USER_grpbxViewWRPayments_TEST
  });
  test("lblPayments(Label Widget) Test Cases", async () => {
    const lblPayments = screen.getByTestId("lblPayments");
    expect(lblPayments.tagName).toBe("LABEL");
    expect(lblPayments.classList).toContain("form-label");
    expect(lblPayments.textContent).toEqual(
      t("WarehouseReceipts:ViewWRPayments_lblPayments")
    );
  });
  test("Custom Test Cases for lblPayments", () => {
    // START_USER_CODE-USER_lblPayments_TEST
    // END_USER_CODE-USER_lblPayments_TEST
  });
  test("gridPayments_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolBuyingPoint = screen.getByTestId("gridPayments");
    let gridPayments_txtcolBuyingPointbtn =
      gridPayments_txtcolBuyingPoint.nextElementSibling.firstElementChild;
    gridPayments_txtcolBuyingPoint =
      gridPayments_txtcolBuyingPoint.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridPayments_txtcolBuyingPoint.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridPayments_txtcolCheckNum(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolCheckNum = screen.getByTestId("gridPayments");
    let gridPayments_txtcolCheckNumbtn =
      gridPayments_txtcolCheckNum.nextElementSibling.firstElementChild;
    gridPayments_txtcolCheckNum =
      gridPayments_txtcolCheckNum.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolCheckNum.tagName).toBe("DIV");
    expect(gridPayments_txtcolCheckNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCheckNum", () => {
    // START_USER_CODE-USER_txtcolCheckNum_TEST
    // END_USER_CODE-USER_txtcolCheckNum_TEST
  });
  test("gridPayments_txtcolClearedDate(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolClearedDate = screen.getByTestId("gridPayments");
    let gridPayments_txtcolClearedDatebtn =
      gridPayments_txtcolClearedDate.nextElementSibling.firstElementChild;
    gridPayments_txtcolClearedDate =
      gridPayments_txtcolClearedDate.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolClearedDate.tagName).toBe("DIV");
    expect(gridPayments_txtcolClearedDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolClearedDate", () => {
    // START_USER_CODE-USER_txtcolClearedDate_TEST
    // END_USER_CODE-USER_txtcolClearedDate_TEST
  });
  test("gridPayments_txtcolInterestAgreement(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolInterestAgreement = screen.getByTestId(
      "gridPayments"
    );
    let gridPayments_txtcolInterestAgreementbtn =
      gridPayments_txtcolInterestAgreement.nextElementSibling.firstElementChild;
    gridPayments_txtcolInterestAgreement =
      gridPayments_txtcolInterestAgreement.parentElement.parentElement
        .parentElement;
    expect(gridPayments_txtcolInterestAgreement.tagName).toBe("DIV");
    expect(gridPayments_txtcolInterestAgreement.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInterestAgreement", () => {
    // START_USER_CODE-USER_txtcolInterestAgreement_TEST
    // END_USER_CODE-USER_txtcolInterestAgreement_TEST
  });
  test("gridPayments_txtcolIssuedDate(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolIssuedDate = screen.getByTestId("gridPayments");
    let gridPayments_txtcolIssuedDatebtn =
      gridPayments_txtcolIssuedDate.nextElementSibling.firstElementChild;
    gridPayments_txtcolIssuedDate =
      gridPayments_txtcolIssuedDate.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolIssuedDate.tagName).toBe("DIV");
    expect(gridPayments_txtcolIssuedDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIssuedDate", () => {
    // START_USER_CODE-USER_txtcolIssuedDate_TEST
    // END_USER_CODE-USER_txtcolIssuedDate_TEST
  });
  test("gridPayments_txtcolPayType(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolPayType = screen.getByTestId("gridPayments");
    let gridPayments_txtcolPayTypebtn =
      gridPayments_txtcolPayType.nextElementSibling.firstElementChild;
    gridPayments_txtcolPayType =
      gridPayments_txtcolPayType.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolPayType.tagName).toBe("DIV");
    expect(gridPayments_txtcolPayType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayType", () => {
    // START_USER_CODE-USER_txtcolPayType_TEST
    // END_USER_CODE-USER_txtcolPayType_TEST
  });
  test("gridPayments_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolProceeds = screen.getByTestId("gridPayments");
    let gridPayments_txtcolProceedsbtn =
      gridPayments_txtcolProceeds.nextElementSibling.firstElementChild;
    gridPayments_txtcolProceeds =
      gridPayments_txtcolProceeds.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolProceeds.tagName).toBe("DIV");
    expect(gridPayments_txtcolProceeds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridPayments_txtcolReceiptNum(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolReceiptNum = screen.getByTestId("gridPayments");
    let gridPayments_txtcolReceiptNumbtn =
      gridPayments_txtcolReceiptNum.nextElementSibling.firstElementChild;
    gridPayments_txtcolReceiptNum =
      gridPayments_txtcolReceiptNum.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolReceiptNum.tagName).toBe("DIV");
    expect(gridPayments_txtcolReceiptNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptNum", () => {
    // START_USER_CODE-USER_txtcolReceiptNum_TEST
    // END_USER_CODE-USER_txtcolReceiptNum_TEST
  });
  test("gridPayments_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolStatus = screen.getByTestId("gridPayments");
    let gridPayments_txtcolStatusbtn =
      gridPayments_txtcolStatus.nextElementSibling.firstElementChild;
    gridPayments_txtcolStatus =
      gridPayments_txtcolStatus.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolStatus.tagName).toBe("DIV");
    expect(gridPayments_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridPayments_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolVendor = screen.getByTestId("gridPayments");
    let gridPayments_txtcolVendorbtn =
      gridPayments_txtcolVendor.nextElementSibling.firstElementChild;
    gridPayments_txtcolVendor =
      gridPayments_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolVendor.tagName).toBe("DIV");
    expect(gridPayments_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridPayments_txtcolVoidDate(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolVoidDate = screen.getByTestId("gridPayments");
    let gridPayments_txtcolVoidDatebtn =
      gridPayments_txtcolVoidDate.nextElementSibling.firstElementChild;
    gridPayments_txtcolVoidDate =
      gridPayments_txtcolVoidDate.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolVoidDate.tagName).toBe("DIV");
    expect(gridPayments_txtcolVoidDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:ViewWRPayments_gridPayments"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVoidDate", () => {
    // START_USER_CODE-USER_txtcolVoidDate_TEST
    // END_USER_CODE-USER_txtcolVoidDate_TEST
  });
});
