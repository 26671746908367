/* eslint-disable*/
import React from "react";
import ContractManagement_ReportPreviewTrade from "./ReportPreviewTrade";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReportPreviewTrade Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ReportPreviewTrade />);
    });
  });
  afterEach(cleanup);
  test("is ReportPreviewTrade Loads Successfully", () => {
    expect(screen.getByText("Report Preview Contract")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReportPreviewTrade", () => {
    // START_USER_CODE-USER_ReportPreviewTrade_Custom_Test_Case
    // END_USER_CODE-USER_ReportPreviewTrade_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ReportPreviewTrade />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:ReportPreviewTrade_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("ContractManagement:ReportPreviewTrade_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrintSetup(Button Widget) Test Cases", async () => {
    const btnPrintSetup = screen.getByTestId("btnPrintSetup");
    expect(btnPrintSetup).toBeInTheDocument;
    expect(btnPrintSetup.textContent).toEqual(
      t("ContractManagement:ReportPreviewTrade_btnPrintSetup")
    );
  });
  test("Custom Test Cases for btnPrintSetup", () => {
    // START_USER_CODE-USER_btnPrintSetup_TEST
    // END_USER_CODE-USER_btnPrintSetup_TEST
  });
  test("btnSaveAsPdf(Button Widget) Test Cases", async () => {
    const btnSaveAsPdf = screen.getByTestId("btnSaveAsPdf");
    expect(btnSaveAsPdf).toBeInTheDocument;
    expect(btnSaveAsPdf.textContent).toEqual(
      t("ContractManagement:ReportPreviewTrade_btnSaveAsPdf")
    );
  });
  test("Custom Test Cases for btnSaveAsPdf", () => {
    // START_USER_CODE-USER_btnSaveAsPdf_TEST
    // END_USER_CODE-USER_btnSaveAsPdf_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
});
