/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ImageWidget,
  goTo,
  setValue,
  setData,
  hide,
  disable,
  enable,
  getValue,
  getData,
  show
} from "../../shared/WindowImports";

import "./UserSignatureSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { useDropzone } from "react-dropzone";
import ModalPopUp from "react-bootstrap/Modal";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { inputPropsType } from "react-bootstrap-typeahead/lib/propTypes";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_UserSignatureSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const [isShow, invokeModal] = React.useState(false);
  const [files, setFiles] = useState([])

  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let user_id = sessionStorage.getItem("userid"); 
  let image64URL = ""
  let allowChange = true
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "UserSignatureSetup",
    windowName: "UserSignatureSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.UserSignatureSetup",
    selectedFiles : "No Image selected ",
    currentFile : "No Image selected",
    // START_USER_CODE-USER_UserSignatureSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Signature Setup",
      scrCode: "PN0205",
    },
    // END_USER_CODE-USER_UserSignatureSetup_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: ".",
      CharWidth: "6",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnLoadFromFile: {
      name: "btnLoadFromFile",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Load From File",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLoadFromFile_PROPERTIES

      // END_USER_CODE-USER_btnLoadFromFile_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnScan: {
      name: "btnScan",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Scan",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnScan_PROPERTIES

      // END_USER_CODE-USER_btnScan_PROPERTIES
    },
    ddUsers: {
      name: "ddUsers",
      type: "DropDownFieldWidget",
      parent: "grpbxUserSignatureSetup",
      Label: "Users:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddUsers_PROPERTIES

      // END_USER_CODE-USER_ddUsers_PROPERTIES
    },
    picSignature: {
      name: "picSignature",
      type: "ImageWidget",
      parent: "grpbxUserSignatureSetup",
      Label: "Image of Signature (image is stretched):",
      widgetState: "Dynamic",
      // START_USER_CODE-USER_picSignature_PROPERTIES

      // END_USER_CODE-USER_picSignature_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxUserSignatureSetup",
      Label: "Name:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtTitle: {
      name: "txtTitle",
      type: "TextBoxWidget",
      parent: "grpbxUserSignatureSetup",
      Label: "Title:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTitle_PROPERTIES

      // END_USER_CODE-USER_txtTitle_PROPERTIES
    },
    grpbxUserSignatureSetup: {
      name: "grpbxUserSignatureSetup",
      type: "GroupBoxWidget",
      parent: "UserSignatureSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxUserSignatureSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxUserSignatureSetup_PROPERTIES
    },
    grpbxPic: {
      name: "grpbxPic",
      type: "GroupBoxWidget",
      parent: "grpbxUserSignatureSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPic_PROPERTIES

      // END_USER_CODE-USER_grpbxPic_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "UserSignatureSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    imageUrl : "",
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnExit: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"]
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // FormLoad function
  let formLoad = async() => {
    try{
      setData(thisObj, 'allowChange', true)
      state.imageUrl = ""
      hide(thisObj, 'grpbxPic', true)
      await bFillUsersList();
      let meAction = 'Inquiry'
      setData(thisObj, 'meAction', meAction)
      EnableDisableControls()
      setLoading(false)
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  // Users Dropdown binding
  const bFillUsersList = async() => {
    try {
      let js = [];
      js.push({key: '', description: ">>> All Users <<<"})
      let response = await ContractManagementService.RetrieveUserControlDetails()
      let data = response;
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].userId, description: data[i].userId + ' - ' + data[i].userName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddUsers: {
            ...state["ddUsers"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddUsers', js[0].key)
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  // Users dropdown change event
  const onddUsersChange = async (event) => {
    try {
      let flag = getData(thisObj, 'allowChange')
      if(flag){
        setData(thisObj, 'allowChange', false)
        if(event?.type == 'change'){
          await LoadSignatureData(event?.target?.value)
          return 
        }
        return
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
      return true;
    }    
  }
  thisObj.onddUsersChange = onddUsersChange

  async function LoadSignatureData(userId){
    try{
      let meAction
      if(userId == null || userId == undefined){
        let usersList = thisObj.state['ddUsers'].valueList;
        let selectedUsersList = thisObj.values['ddUsers'];
        if (selectedUsersList?.length !== 7) {
          return
        }
        
        if (selectedUsersList !== null && selectedUsersList !== undefined) {
          userId = usersList.find(elem => elem.key === selectedUsersList).key;
        }
      }
      
      let response = await ContractManagementService.RetrieveUserSignatureDetails(userId)
      let data = response;
      if (data.length !== 0 && data !== null && data !== undefined) {
        setValue(thisObj, 'txtName', data[0].name)
        setValue(thisObj, 'txtTitle', data[0].title)
        state.imageUrl = "data:image/gif;base64," + data[0].image
        setValue(thisObj, 'grpbxPic', state.imageUrl)
        show(thisObj, 'grpbxPic')
        meAction = 'Edit'
        setData(thisObj, 'meAction', meAction)
        EnableDisableControls()
        setData(thisObj, 'allowChange', true)
      }
      else{
        meAction = 'Add'
        setData(thisObj, 'meAction', meAction)
        EnableDisableControls()
        setData(thisObj, 'allowChange', true)
      }

      if (userId == '') {
        meAction = 'Inquiry'
        setData(thisObj, 'meAction', meAction)
        EnableDisableControls()
        return;
      }
    }
    catch(err){
      showMessage(thisObj, err.message)
    }
  }

  // EnableDisable function
  const EnableDisableControls = () => {
    try {
      let meAction = getData(thisObj, 'meAction')
      switch (meAction) {
        case 'Add' :
          enable(thisObj, 'btnScan') 
          enable(thisObj, 'btnLoadFromFile') 
          disable(thisObj, 'btnDelete')
          setValue(thisObj, 'txtName', "")
          setValue(thisObj, 'txtTitle', "")
          setValue(thisObj, 'grpbxPic', "")
          state.imageUrl = ""
          hide(thisObj, 'grpbxPic', true)
          break;
          
        case 'Edit' :
          enable(thisObj, 'btnScan') 
          enable(thisObj, 'btnLoadFromFile') 
          enable(thisObj, 'btnDelete')
          break;

        case 'Inquiry' :
          disable(thisObj, 'btnScan') 
          disable(thisObj, 'btnLoadFromFile') 
          disable(thisObj, 'btnDelete')  
          setValue(thisObj, 'txtName', '')
          setValue(thisObj, 'txtTitle', '')
          setValue(thisObj, 'grpbxPic', '')
          break;
      }
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  // Scan button functionality 
  const onbtnScanClick = async() => {
    try{  
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#ReportUserSignatureTemplate#DEFAULT#true#Click')
    }
    catch(err){
      showMessage(thisObj, err.message)
    }
  }
  thisObj.onbtnScanClick = onbtnScanClick;

  // Load from File button functionality
  const onbtnLoadFromFileClick = () => {
    try {
      invokeModal(true)
      thisObj.setState((current) => {
        return {
          ...current,
          dropzoneFlag: !thisObj.state.dropzoneFlag
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btn event:Click");
      }
      return false;
    }
    return true
  };
  thisObj.onbtnLoadFromFileClick = onbtnLoadFromFileClick;

  async function enCodeImage(file) {
    try {
      let reader = new FileReader();
      let base64gif = ""
      reader.addEventListener("load", function () {
        base64gif = reader.result;

        setData(thisObj, 'image64URL', base64gif)

        if (![undefined, null, "", "data:"].includes(base64gif)) {
          let fileObj = {
            b64data: base64gif,
            b64fileType: "image/gif",
            currentFileSize: file.size,
            data: base64gif.split("base64,")[1],
            fileType: "image",
            value: file.name
          }

          state.currentFile = fileObj
          state.imageUrl = file.preview

          setData(thisObj, 'grpbxPic', fileObj)
          setValue(thisObj, 'grpbxPic', fileObj)
          show(thisObj, 'grpbxPic')
          invokeModal(false);
        }
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept : ".gif",
    onDrop: async(acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (acceptedFiles[0].name.toLowerCase().split(".")[1] != 'gif') {
          invokeModal(false);
          showMessage(thisObj, "File must be in GIF format.")
          return;
        }
        invokeModal(false)
        await enCodeImage(acceptedFiles[0])  
      }
    },
    onFileDialogCancel: ()=> {
      invokeModal(false)
    }
  })

  // Save button functionality
  const onbtnSaveClick = async() => {
    try{
      let meAction = getData(thisObj, 'meAction')
      let userId = ''
      let usersList = thisObj.state['ddUsers'].valueList;
      let selectedUsersList = thisObj.values['ddUsers'];
      if (selectedUsersList !== null && selectedUsersList !== undefined) {
        userId = usersList.find(elem => elem.key === selectedUsersList).key;
      }

      let name = getValue(thisObj, 'txtName')
      let title = getValue(thisObj, 'txtTitle')
      let image = getValue(thisObj, 'grpbxPic')
      
      if(name == '' || name == null || name == undefined) {
        showMessage(thisObj, "Name is required.")
        return;
      }

      if(title == '' || title == null || title == undefined) {
        showMessage(thisObj, "Title is required.")
        return;
      }

      if(image == '' || image == null || image == undefined) {
        showMessage(thisObj, "Signature is required.")
        return;
      }
      
      if(image.hasOwnProperty('b64data')) {
        image = getData(thisObj, 'image64URL').split("data:image/gif;base64,")[1]
      }
      else {
        image = image.split("data:image/gif;base64,")[1]
      }

      let temp = {
        "uid": user_id,
        "name": name,
        "title": title,
        "image": image
      }

      if(meAction == 'Edit') {
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateUserSignature(userId, temp)
        let data = response
        if (data.status == 400 || data.status == 404 || data.status == 500) {
          showMessage(thisObj, "An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist."+ response.message)
          showMessage(thisObj, "Cannot save file.")
          return;
        }
        if (data.status == 200 || data.status == 204) {
          showMessage(thisObj, "Signature is saved.", true)
        }
        EnableDisableControls()
      }

      else if(meAction == 'Add') {
        temp.comp_id   = compId
        temp.crop_year = cropYear
        temp.user_id   = userId
        let response = await SystemMaintenanceSpecialFunctionsService.CreateUserSignature(temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist."+ response.message)
          showMessage(thisObj, "Cannot save file.")
          return;
        }
        if (data.status == 200 || data.status == 204) {
          showMessage(thisObj, "Signature is saved.", true)
        }
        meAction = 'Edit'
        let eventData = {
          type: 'change',
          target: {
           value: userId
          }
        }
        setData(thisObj, 'allowChange', true)
        await onddUsersChange(eventData)
        EnableDisableControls()
      }
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnSaveClick = onbtnSaveClick

  // Delete button functionality
  const onbtnDeleteClick = async() => {
    try {
      let userId = ''
      let userName = ''
      let usersList = thisObj.state['ddUsers'].valueList;
      let selectedUsersList = thisObj.values['ddUsers'];
      if (selectedUsersList !== null && selectedUsersList !== undefined) {
        userId = usersList.find(elem => elem.key === selectedUsersList).key;
        userName = usersList.find(elem => elem.key === selectedUsersList).description;
      }
      let res = confirm(`Delete signature image for user ${userName}?`)
      if (res == true) {
        setLoading(true)
        let response = await SystemMaintenanceSpecialFunctionsService.RemoveUserSignature(userId)
        if (![200].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
          return
        }
        formLoad()
      }
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
      return true;
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_UserSignatureSetup*/}

              {/* END_USER_CODE-USER_BEFORE_UserSignatureSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxUserSignatureSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxUserSignatureSetup*/}

              <GroupBoxWidget
                conf={state.grpbxUserSignatureSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddUsers*/}

                {/* END_USER_CODE-USER_BEFORE_ddUsers*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddUsers}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddUsers*/}

                {/* END_USER_CODE-USER_AFTER_ddUsers*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_txtTitle*/}

                {/* END_USER_CODE-USER_BEFORE_txtTitle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTitle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTitle*/}

                {/* END_USER_CODE-USER_AFTER_txtTitle*/}
                {/* START_USER_CODE-USER_BEFORE_picSignature*/}

                {/* END_USER_CODE-USER_BEFORE_picSignature*/}
                
                
                <ImageWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.picSignature}
                  screenConf={state}
                />

              <GroupBoxWidget
                conf={state.grpbxPic}
                screenConf={state}
              >
                <img src={state.imageUrl}></img>
              </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_picSignature*/}

                {/* END_USER_CODE-USER_AFTER_picSignature*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxUserSignatureSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxUserSignatureSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnScan*/}

                {/* END_USER_CODE-USER_BEFORE_btnScan*/}

                <ButtonWidget
                  conf={state.btnScan}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnScan*/}

                {/* END_USER_CODE-USER_AFTER_btnScan*/}
                {/* START_USER_CODE-USER_BEFORE_btnLoadFromFile*/}

                {/* END_USER_CODE-USER_BEFORE_btnLoadFromFile*/}

                <ButtonWidget
                  conf={state.btnLoadFromFile}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLoadFromFile*/}
                <ModalPopUp id="blur"
                    show={isShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    keyboard = "false"
                    style={{ width: "50%", height : "100%", left : "26%" }} 
                    autoFocus
                    centered   
                >                  
                  <ModalPopUp.Body>
                    <div style= {{ alignContent : "centered", textAlign : "centered", height : "100%" }}>
                      <div className="dropArea" {...getRootProps()} style={{ border:"1px solid #ccc", textAlign: "centered", height: "100%",backgroundColor:"white" }}>
                      {/* {...getRootProps()} */}
                        <label for="dropFile" style={{ textAlign: "center", width : "100%" , height : "100%" }} class="btn">
                          <strong> Drag 'n' drop some file here, or click to select file. </strong>
                        </label>
                        {/* <input {...getInputProps()} accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height : "100%" , visibility: "hidden" }}/> */}
                      </div>
                    </div>                    
                  </ModalPopUp.Body>
                </ModalPopUp>
                {/* END_USER_CODE-USER_AFTER_btnLoadFromFile*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_UserSignatureSetup*/}

              {/* END_USER_CODE-USER_AFTER_UserSignatureSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_UserSignatureSetup);
