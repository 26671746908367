/* eslint-disable*/
import { ApiService } from "../../Common/ApiService";
export const SystemMaintenanceSpecialFunctionsService = {
    CreateUserFuncionLocationXref,
    UpdateUserFuncionLocationXref,
    CreateSpotPriceControl,
    UpdateSpotPriceControl,
    RemovePremiumDeductMasterDetails,
    CreatePremiumDeductionMaster,
    UpdatePremiumDeductionMaster,
    RetrieveSpotPriceControlDetails,
    RemoveSpotPriceControl,
    RetrieveStateControlDetails,
    CreateCompanyControl,
    UpdateCompanyControl,
    CreateUserSignature,
    UpdateUserSignature,
    RetrieveHighMoistureDetails,
    UpdateHighMoistureDetails,
    RemoveCompanyControl,
    RemoveUserSignature,
    UpdatePriceScheduleAudits,
    UpdateBankHolidayControldetails,
    CreateLoanRepayControl,
    UpdateLoanRepayControl,
    RetrieveFunctionControlDetails,
    RetrieveSecurityGroupControlDetails,
    CreateMarketPriceControls,
    UpdateMarketPriceControls,
    RetrieveVendorWireTransferDetails,
    RetrieveReportUserControlDetails,
    RetrieveMarketPriceControlDetails,
    RemoveUserControl,
    UpdateReportUserControl,
    RemoveFunctionControl,
    RemoveSecurityGroupControl,
    RemoveLoanRePaymentControl,
    RetrieveUserControlList,
    RetrieveSecurityGroupFunctionNameDetails,
    RetrieveSecurityGroupLocationNameDetails,
    RetrieveSecurityGroupFunctionTransferDetails,
    RetrieveSecurityGroupLocationTransferDetails,
    RemoveMarketPriceControlDetails,
    UpdateUserControlDetails,
    RemoveSecurityGroupFunctionTransfer,
    RemoveSecurityGroupLocationTransfer,
    CreateSecurityGroupControl,
    UpdateSecurityGroupControl,
    UpdateTradeCategory,
    RetrieveManualInvestmaintDetails,
    CreateSecurityGroupLocationTransfer,
    UpdateSecurityGroupLocationTransfer,
    CreateSecurityGroupFunctionLocationTransfer,
    UpdateSecurityGroupFunctionLocationTransfer,
    CreateLoanInterest,
    UpdateLoanInterest,
    RetrieveUserFunctionLocationNameDetails,
    RetrieveLoanReductionControlDetails,
    RemoveLoanReductionControl,
    UpdateUserControl,
    CreateUserControl,
    RetrieveUserFunctionNameDetails,
    RetrieveUserLocationNameDetails,
    RetrieveUserLocationTransferDetails,
    CreateLoanReductionControl,
    UpdateLoanReductionControl,
    CreateUserLocationTransfer,
    UpdateUserLocationTransfer,
    RetrieveUserFunctionTransferDetails,
    RetrieveLoanInterestDetails,
    RemoveLoanInterest,
    RemoveUserFunctionLocationTransfer,
    UpdateUserSecurityExport,
    RetrieveBankHolidayControldetails,
    RemoveBankHolidayControldetails,
    RetrieveSecurityGroupFunctionLocationNameDetails,
    RetrieveSecurityGroupFunctionLocTransferDetails,
    CreateSecurityGroupFunctionTransfer,
    UpdateSecurityGroupFunctionTransfer,
    CreateVendorWireTransfer,
    UpdateVendorWireTransfer,
    RemoveVendorWireTransfer,
    CreateUserSecurityImport,
    RemoveFunctionXref,
    RemoveUserLocationTransfer,
    RemoveSecurityGroupFunctionLocTransferDetails,
    CreateUserFunctionTransfer,
    UpdateUserFunctionTransfer,
    CreateFunctionControl,
    UpdateFunctionControl,
    RemoveDiscountScheduleDetails,
    RemovePriceScheduleDetails,
    CreatePriceSchedule,
    CreateDiscountSchedule,
    RetrieveDiscountScheduleDetails,
    CreateCmaDmaControls,
    UpdateCmaDmaControls,
    RetrievePpsDashboard,
}

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const systemmaintenance_url_api = '/system-maintenance/v1/';

export function CreateUserFuncionLocationXref(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateUserFuncionLocationXref/locationxrefs`
        : `${systemmaintenance_url_api}locationxrefs`, data);
}
export function UpdateUserFuncionLocationXref(func_id, user_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateUserFuncionLocationXref/companies/${compIdFromLS()}/functions/${func_id}/users/${user_id}/locationxrefs`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/functions/${func_id}/users/${user_id}/locationxrefs`, data);
}

export function CreateSpotPriceControl(buyingPoint, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateSpotPriceControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/users/${useridFromLS()}/spotpricecontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/users/${useridFromLS()}/spotpricecontrols`, data);
}

export function UpdateSpotPriceControl(buyingPoint, peanutType_id, effectiveDate, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateSpotPriceControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-pointsd/${buyingPoint}/peanut-types/${peanutType_id}/effect-dates/${effectiveDate}/users/${useridFromLS()}/spotpricecontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-pointsd/${buyingPoint}/peanut-types/${peanutType_id}/effect-dates/${effectiveDate}/users/${useridFromLS()}/spotpricecontrols`, data);
}

export function RemovePremiumDeductMasterDetails(buy_pt_id, pd_code) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePremiumDeductMasterDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/premium-deduction-codes/${pd_code}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/premium-deduction-codes/${pd_code}`
    );
}

export function CreatePremiumDeductionMaster(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreatePremiumDeductionMaster/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/premiumdeductions` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/premiumdeductions`, data);
}

export function UpdatePremiumDeductionMaster(buy_pt_id, pd_code, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePremiumDeductionMaster/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/premium-deduction-codes/${pd_code}/premiumdeductions` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/premium-deduction-codes/${pd_code}/premiumdeductions`, data);
}

export function RetrieveSpotPriceControlDetails(comp_id, crop_year, buy_pt_id, pnut_type_id, effect_date_time) {
    let optionsParamsURL = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionsParamsURL += `comp_id=${compIdFromLS()}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionsParamsURL += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
    }
    if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
        optionsParamsURL += `pnut_type_id=${pnut_type_id}&`;
    }
    if (effect_date_time != null && effect_date_time !== '' && effect_date_time !== undefined) {
        optionsParamsURL += `effect_date_time=${effect_date_time}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveSpotPriceControlDetails/spotpricecontrols?${optionsParamsURL}`
        : `${systemmaintenance_url_api}spotpricecontrols?${optionsParamsURL}`)
}

export function RemoveSpotPriceControl(buy_pt_id, pnut_type_id, effect_date_time) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveSpotPriceControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/peanut-types/${pnut_type_id}/effect-dates/${effect_date_time}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/peanut-types/${pnut_type_id}/effect-dates/${effect_date_time}/users/${useridFromLS()}`
    );
}
export function RetrieveStateControlDetails(state_abbr) {
    let optionalParamsUrl = ``

    if (state_abbr !== null && state_abbr !== '' && state_abbr !== undefined)
        optionalParamsUrl += `state_abbr=${state_abbr}`
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveStateControlDetails/crop-years/${cropYearFromLS()}/states?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}/crop-years/${cropYearFromLS()}/states?${optionalParamsUrl}`);
}
export function CreateCompanyControl(comp_id, jsonObj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateCompanyControl/companies/${comp_id}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/companycontrols`
        : `${systemmaintenance_url_api}companies/${comp_id}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/companycontrols`, jsonObj);
}

export function UpdateCompanyControl(comp_id, jsonObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateCompanyControl/companies/${comp_id}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/companies` : `${systemmaintenance_url_api}/companies/${comp_id}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/companies`, jsonObj);
}

export function CreateUserSignature(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateUserSignature/usersignatures`
        : `${systemmaintenance_url_api}usersignatures`, data);
}

export function UpdateUserSignature(user_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateUserSignature/companies/${compIdFromLS()}/users/${user_id}/usersignatures`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/usersignatures`, data);
}

export function RetrieveHighMoistureDetails(pnut_type_id, grade_type, high_moist_pct, high_grade_pct, result) {
    let optionsParamsURL = '';
    if (grade_type != null && grade_type !== '' && grade_type !== undefined) {
        optionsParamsURL += `grade_type=${grade_type}&`;
    }
    if (high_moist_pct != null && high_moist_pct !== '' && high_moist_pct !== undefined) {
        optionsParamsURL += `high_moist_pct=${high_moist_pct}&`;
    }
    if (high_grade_pct != null && high_grade_pct !== '' && high_grade_pct !== undefined) {
        optionsParamsURL += `high_grade_pct=${high_grade_pct}&`;
    }
    if (result != null && result !== '' && result !== undefined) {
        optionsParamsURL += `result=${result}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveHighMoistureDetails/crop-years/${cropYearFromLS()}/peanut-types/${pnut_type_id}/highmoistures?${optionsParamsURL}`
        : `${systemmaintenance_url_api}crop-years/${cropYearFromLS()}/peanut-types/${pnut_type_id}/highmoistures?${optionsParamsURL}`)
}

export function UpdateHighMoistureDetails(status, pnut_type_id, grade_type, high_moist_pct, high_grade_pct, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateHighMoistureDetails/status/${status}/crop-years/${cropYearFromLS()}/peanut-types/${pnut_type_id}/grade-types/${grade_type}/high-grades/${high_grade_pct}/high-moistures/${high_moist_pct}/highmoistures` : `${systemmaintenance_url_api}/status/${status}/crop-years/${cropYearFromLS()}/peanut-types/${pnut_type_id}/grade-types/${grade_type}/high-grades/${high_grade_pct}/high-moistures/${high_moist_pct}/highmoistures`, data);

}

export function RemoveCompanyControl(comp_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveCompanyControl/company-ids/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/companies/${comp_id}`
        : `${systemmaintenance_url_api}company-ids/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/companies/${comp_id}`
    );
}

export function RemoveUserSignature(user_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveUserSignature/companies/${compIdFromLS()}/users/${user_id}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}`
    );
}

export function UpdateBankHolidayControldetails(action_type, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateBankHolidayControldetails/action-types/${action_type}/bankholidays` : `${systemmaintenance_url_api}action-types/${action_type}/bankholidays`, data);
}
export function CreateLoanRepayControl(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateLoanRepayControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/loanrepaycontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/loanrepaycontrols`, data);
}
export function UpdateLoanRepayControl(buy_pt_id, pnut_type_id, effect_date_time, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateLoanRepayControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-points/${pnut_type_id}/effect-dates/${effect_date_time}/loanrepays` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-points/${pnut_type_id}/effect-dates/${effect_date_time}/loanrepays`, data);
}

export function RetrieveFunctionControlDetails(func_id, func_sub_id) {
    let optionsParamsURL = '';
    if (func_id != null && func_id !== '' && func_id !== undefined) {
        optionsParamsURL += `func_id=${func_id}&`;
    }
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionsParamsURL += `func_sub_id=${func_sub_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveFunctionControlDetails/functioncontrols?${optionsParamsURL}` : `${systemmaintenance_url_api}functioncontrols?${optionsParamsURL}`);
}

export function RetrieveSecurityGroupControlDetails(comp_id, security_group) {
    let optionsParamsURL = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionsParamsURL += `comp_id=${comp_id}&`;
    }
    if (security_group != null && security_group !== '' && security_group !== undefined) {
        optionsParamsURL += `security_group=${security_group}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveSecurityGroupControlDetails/securitygroupcontrols?${optionsParamsURL}` : `${systemmaintenance_url_api}securitygroupcontrols?${optionsParamsURL}`);
}

export function CreateMarketPriceControls(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateMarketPriceControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/pricecontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/pricecontrols`, data);
}
export function UpdateMarketPriceControls(buy_pt_id, pnut_type_id, effect_date_time, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateMarketPriceControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-types/${pnut_type_id}/dates/${effect_date_time}/marketprices` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut-types/${pnut_type_id}/dates/${effect_date_time}/marketprices`, data);
}
export function RetrieveVendorWireTransferDetails(vendor_num) {
    let optionalParamsUrl = ``
    if (vendor_num !== null && vendor_num !== '' && vendor_num !== undefined) {
        optionalParamsUrl += `vendor_number=${vendor_num}`
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveVendorWireTransferDetails/vendorwiretransfers?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}/vendorwiretransfers?${optionalParamsUrl}`);
}

export function RetrieveMarketPriceControlDetails(pnut_type_id, effect_date_time, cdate) {
    let optionsParamsURL = '';
    if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
        optionsParamsURL += `pnut_type_id=${pnut_type_id}&`;
    }
    if (effect_date_time != null && effect_date_time !== '' && effect_date_time !== undefined) {
        optionsParamsURL += `effect_date_time=${effect_date_time}&`;
    }
    if (cdate != null && cdate !== '' && cdate !== undefined) {
        optionsParamsURL += `cdate=${cdate}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveMarketPriceControlDetails/marketpricecontrols?comp_id=${compIdFromLS()}&crop_year=${cropYearFromLS()}&${optionsParamsURL}` : `${systemmaintenance_url_api}marketpricecontrols?comp_id=${compIdFromLS()}&crop_year=${cropYearFromLS()}&${optionsParamsURL}`);
}

export function RemoveUserControl(user_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveUserControl/users/${user_id}`
        : `${systemmaintenance_url_api}users/${user_id}`
    );
}
export function RetrieveReportUserControlDetails(user_id) {
    let optionsParamsURL = '';
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionsParamsURL += `user_id=${user_id}&`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveReportUserControlDetails/companies/${compIdFromLS()}/reportusercontrols?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/reportusercontrols?${optionsParamsURL}`);
}
export function UpdateReportUserControl(data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateReportUserControl/companies/${compIdFromLS()}/users/${useridFromLS()}/reportusercontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${useridFromLS()}/reportusercontrols`, data);
}

export function RemoveFunctionControl(func_id, func_sub_id) {
    let optionsParamsURL = '';
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionsParamsURL += `func_sub_id=${func_sub_id}&`;
    }
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveFunctionControl/functions/${func_id}?${optionsParamsURL}`
        : `${systemmaintenance_url_api}functions/${func_id}?${optionsParamsURL}`)
}

export function RemoveSecurityGroupControl(security_group, comp_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveSecurityGroupControl/security-groups/${security_group}/companies/${comp_id}`
        : `${systemmaintenance_url_api}security-groups/${security_group}/companies/${comp_id}`)
}

export function RemoveLoanRePaymentControl(buy_pt_id, peanut_type_id, effectDateTime) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveLoanRePaymentControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut_types/${peanut_type_id}/dates/${effectDateTime}`
        : `${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/peanut_types/${peanut_type_id}/dates/${effectDateTime}`)
}

export function RetrieveUserControlList(comp_id, security_group) {
    let optionalParamsUrl = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (security_group != null && security_group !== '' && security_group !== undefined) {
        optionalParamsUrl += `security_group=${security_group}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveUserControlList/usercontrols?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}usercontrols?${optionalParamsUrl}`);
}

export function RetrieveSecurityGroupFunctionNameDetails(comp_id, security_group) {
    let optionalParamsUrl = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (security_group != null && security_group !== '' && security_group !== undefined) {
        optionalParamsUrl += `security_group=${security_group}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveSecurityGroupFunctionNameDetails/functionnames?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}functionnames?${optionalParamsUrl}`);
}

export function RetrieveSecurityGroupLocationNameDetails(security_group, crop_year, func_id, func_sub_id, user_id) {
    let optionalParamsUrl = '';
    if (security_group != null && security_group !== '' && security_group !== undefined) {
        optionalParamsUrl += `security_group=${security_group}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
    if (func_id != null && func_id !== '' && func_id !== undefined) {
        optionalParamsUrl += `func_id=${func_id}&`;
    }
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
    }
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionalParamsUrl += `user_id=${useridFromLS()}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveSecurityGroupLocationNameDetails/companies/${compIdFromLS()}/locationnames?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/locationnames?${optionalParamsUrl}`);
}

export function RetrieveSecurityGroupFunctionTransferDetails(security_group, func_id, func_sub_id) {
    let optionalParamsUrl = '';
    if (func_id != null && func_id !== '' && func_id !== undefined) {
        optionalParamsUrl += `func_id=${func_id}&`;
    }
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveSecurityGroupFunctionTransferDetails/companies/${compIdFromLS()}/security-group/${security_group}/groupfunctions?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/security-group/${security_group}/groupfunctions?${optionalParamsUrl}`);
}

export function RetrieveSecurityGroupLocationTransferDetails(security_group, buy_pt_id) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveSecurityGroupLocationTransferDetails/companies/${compIdFromLS()}/security-group/${security_group}/buying-points/${buy_pt_id}/grouplocations`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/security-group/${security_group}/buying-points/${buy_pt_id}/grouplocations`);
}

export function RemoveMarketPriceControlDetails(buy_pt_id, peanut_type_id, effectDateTime) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveMarketPriceControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/dates/${effectDateTime}/peanut-types/${peanut_type_id}`
        : `${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/dates/${effectDateTime}/peanut-types/${peanut_type_id}`)
}

export function UpdateUserControlDetails(user_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateUserControlDetails/companies/${compIdFromLS()}/users/${user_id}/usercontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/usercontrols`, data);
}

export function RemoveSecurityGroupFunctionTransfer(security_group, func_id, func_sub_id) {
    let optionalParamsUrl = '';
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveSecurityGroupFunctionTransfer/companies/${compIdFromLS()}/security-groups/${security_group}/functions/${func_id}?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/security-groups/${security_group}/functions/${func_id}?${optionalParamsUrl}`
    );
}

export function RemoveSecurityGroupLocationTransfer(security_group, buy_pt_id) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveSecurityGroupLocationTransfer/companies/${compIdFromLS()}/security-groups/${security_group}/buying-points/${buy_pt_id}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/security-groups/${security_group}/buying-points/${buy_pt_id}`
    );
}

export function CreateSecurityGroupControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateSecurityGroupControl/companies/${compIdFromLS()}/users/${useridFromLS()}/groupcontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${useridFromLS()}/groupcontrols`, data);
}

export function UpdateSecurityGroupControl(security_group, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateSecurityGroupControl/companies/${compIdFromLS()}/security-groups/${security_group}/groupcontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/security-groups/${security_group}/groupcontrols`, data);
}

export function RetrieveManualInvestmaintDetails(action_code, lstrRecordKey, buy_pt_id) {
    let optionalParamsUrl = '';

    if (lstrRecordKey != null && lstrRecordKey !== '' && lstrRecordKey !== undefined) {
        optionalParamsUrl += `key_id=${lstrRecordKey}&`;
    }

    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualInvestmaintDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/action-codes/${action_code}/users/${useridFromLS()}/manualinvests?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/action-codes/${action_code}/users/${useridFromLS()}/manualinvests?${optionalParamsUrl}`)
}

export function UpdateTradeCategory(action_type, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateTradeCategory/action-types/${action_type}/tradecategories`
        : `${systemmaintenance_url_api}action-types/${action_type}/tradecategories`, data);
}
export function CreateSecurityGroupLocationTransfer(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateSecurityGroupLocationTransfer/securitygroups`
        : `${systemmaintenance_url_api}securitygroups`, data);
}
export function UpdateSecurityGroupLocationTransfer(buy_pt_id, security_group, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateSecurityGroupLocationTransfer/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/security-groups/${security_group}/securitygroups`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/buying-points/${buy_pt_id}/security-groups/${security_group}/securitygroups`, data);
}
export function CreateSecurityGroupFunctionLocationTransfer(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateSecurityGroupFunctionLocationTransfer/securitygroupfunctionlocationtransfers`
        : `${systemmaintenance_url_api}securitygroupfunctionlocationtransfers`, data);
}
export function UpdateSecurityGroupFunctionLocationTransfer(buy_pt_id, func_id, func_sub_id, security_group, data) {
    let optionalParamsUrl = '';
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
    }
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateSecurityGroupFunctionLocationTransfer/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/functions/${func_id}/security-groups/${security_group}/securitygroupfunctionlocationtransfers?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/buying-points/${buy_pt_id}/functions/${func_id}/security-groups/${security_group}/securitygroupfunctionlocationtransfers?${optionalParamsUrl}`, data);
}
export function CreateLoanInterest(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateLoanInterest/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/loaninterests`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/loaninterests`, data);
}
export function UpdateLoanInterest(effect_date_time, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateLoanInterest/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/dates/${effect_date_time}/loaninterests`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/dates/${effect_date_time}/loaninterests`, data);
}

export function RetrieveUserFunctionLocationNameDetails(selected_func_id, func_id, uid, selected_func_sub_id, func_sub_id) {
    let optionalParamsUrl = '';
    if (selected_func_sub_id != null && selected_func_sub_id !== '' && selected_func_sub_id !== undefined) {
        optionalParamsUrl += `selected_func_sub_id=${selected_func_sub_id}&`;
    }
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
    }
    return ApiService.getData(isLocal()
        ? `/SystemMaintenance/RetrieveUserFunctionLocationNameDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/user-ids/${uid}/selected-functions/${selected_func_id}/functions/${func_id}/users/${useridFromLS()}/locations?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/user-ids/${uid}/selected-functions/${selected_func_id}/functions/${func_id}/users/${useridFromLS()}/locations?${optionalParamsUrl}`)
}

export function RetrieveLoanReductionControlDetails(effect_date_time) {
    let optionsParamsURL = '';
    if (effect_date_time != null && effect_date_time !== '' && effect_date_time !== undefined) {
        optionsParamsURL += `effect_date_time=${effect_date_time}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveLoanReductionControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loanreductions?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loanreductions?${optionsParamsURL}`)
}

export function RemoveLoanReductionControl(effect_date_time) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveLoanReductionControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/dates/${effect_date_time}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/dates/${effect_date_time}/users/${useridFromLS()}`)
}

export function UpdateUserControl(data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateUserControl/users/${useridFromLS()}/companies/${compIdFromLS()}/usercontrols`
        : `${systemmaintenance_url_api}users/${useridFromLS()}/companies/${compIdFromLS()}/usercontrols`, data);
}

export function CreateUserControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateUserControl/usercontrols`
        : `${systemmaintenance_url_api}usercontrols`, data);
}

export function RetrieveUserFunctionNameDetails(comp_id, user_id) {
    let optionsParamsURL = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionsParamsURL += `comp_id=${comp_id}&`;  //do not replace with default comp_id and User_id(this are page specific)
    }
    if (user_id != null && user_id !== '' && user_id !== undefined) {
        optionsParamsURL += `user_id=${user_id}`;   //do not replace with default comp_id and User_id(this are page specific)
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveUserFunctionNameDetails/userfunctionname?${optionsParamsURL}`
        : `${systemmaintenance_url_api}userfunctionname?${optionsParamsURL}`)
}

export function RetrieveUserLocationNameDetails(func_id, uid, func_sub_id) {
    let optionsParamsURL = '';
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionsParamsURL += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveUserLocationNameDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${uid}/uid/${useridFromLS()}/userlocationnames?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${uid}/uid/${useridFromLS()}/userlocationnames?${optionsParamsURL}`)
}

export function RetrieveUserLocationTransferDetails(user_id, buy_pt_id) {  //userid in this api is page specific , do not replace with default
    let optionsParamsURL = '';
    if (buy_pt_id !== undefined && buy_pt_id != null && buy_pt_id !== '') {
        optionsParamsURL += `buy_pt_id=${buy_pt_id}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveUserLocationTransferDetails/companies/${compIdFromLS()}/users/${user_id}/UserLocationTransfers?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/UserLocationTransfers?${optionsParamsURL}`)

}

export function CreateLoanReductionControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateLoanReductionControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/reductioncontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/reductioncontrols`, data)
}

export function UpdateLoanReductionControl(effect_date_time, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateLoanReductionControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/dates/${effect_date_time}/reductioncontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/dates/${effect_date_time}/reductioncontrols`, data)
}

export function CreateUserLocationTransfer(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateUserLocationTransfer/userlocations`
        : `${systemmaintenance_url_api}userlocations`, data);
}


export function UpdateUserLocationTransfer(user_id, buy_pt_id, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateUserLocationTransfer/companies/${compIdFromLS()}/users/${user_id}/buying-points/${buy_pt_id}/userlocations`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/buying-points/${buy_pt_id}/userlocations`, data);
}


export function RetrieveUserFunctionTransferDetails(user_id, func_id, func_sub_id) { //userid here is page specific , do not replace with default userid
    let optionsParamsURL = '';
    if (user_id !== undefined && user_id !== '' && user_id !== null) {
        optionsParamsURL += `user_id=${user_id}&`;
    }
    if (func_id !== undefined && func_id !== '' && func_id !== null) {
        optionsParamsURL += `func_id=${func_id}&`;
    }
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionsParamsURL += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveUserFunctionTransferDetails/userfunctiontransfers?comp_id=${compIdFromLS()}&${optionsParamsURL}`
        : `${systemmaintenance_url_api}userfunctiontransfers?comp_id=${compIdFromLS()}&${optionsParamsURL}`)

}

export function RetrieveLoanInterestDetails(effect_date_time) {
    let optionsParamsURL = '';
    if (effect_date_time != null && effect_date_time !== '' && effect_date_time !== undefined) {
        optionsParamsURL += `effect_date_time=${effect_date_time}&`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveLoanInterestDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loaninterests?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loaninterests?${optionsParamsURL}`)
}

export function RemoveLoanInterest(effect_date_time) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveLoanInterest/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/dates/${effect_date_time}`
        : `${systemmaintenance_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/dates/${effect_date_time}`)
}

export function RemoveUserFunctionLocationTransfer(user_id, func_id, buy_pt_id, func_sub_id) {
    let optionalParamsUrl = ''
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.removeData(isLocal()
        ? `/SystemMaintenance/RemoveUserFunctionLocationTransfer/companies/${compIdFromLS()}/users/${user_id}/function-id/${func_id}/buying-points/${buy_pt_id}?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/function-id/${func_id}/buying-points/${buy_pt_id}?${optionalParamsUrl}`)
}

export function UpdateUserSecurityExport(from_user_id, func_id, func_sub_id, dataObj) {
    let optionalParamsUrl = ''
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateUserSecurityExport/companies/${compIdFromLS()}/from-users/${from_user_id}/functions/${func_id}/securityexports?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/from-users/${from_user_id}/functions/${func_id}/securityexports?${optionalParamsUrl}`, dataObj)
}

export function RetrieveBankHolidayControldetails(action_type, holiday_date, holiday_name, user_id) {
    let optionalParamsUrl = ''
    if (holiday_date !== undefined && holiday_date !== '' && holiday_date !== null) {
        optionalParamsUrl += `holiday_date=${holiday_date}&`;
    }
    if (holiday_name !== undefined && holiday_name !== '' && holiday_name !== null) {
        optionalParamsUrl += `holiday_name=${holiday_name}&`;
    }
    if (user_id !== undefined && user_id !== '' && user_id !== null) {
        optionalParamsUrl += `user_id=${user_id}`;
    }
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveBankHolidayControldetails/action-types/${action_type}/bankholidays?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}action-types/${action_type}/bankholidays?${optionalParamsUrl}`);
}

export function RemoveBankHolidayControldetails(action_type, holiday_date, holiday_name, user_id) {
    let optionalParamsUrl = ''
    if (holiday_date !== undefined && holiday_date !== '' && holiday_date !== null) {
        optionalParamsUrl += `holiday_date=${holiday_date}`;
    }
    if (holiday_name !== undefined && holiday_name !== '' && holiday_name !== null) {
        optionalParamsUrl += `holiday_name=${holiday_name}&`;
    }
    if (user_id !== undefined && user_id !== '' && user_id !== null) {
        optionalParamsUrl += `user_id=${user_id}`;
    }
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveBankHolidayControldetails/action-types/${action_type}/bankholidays?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}action-types/${action_type}/bankholidays?${optionalParamsUrl}`)
}

export function RetrieveSecurityGroupFunctionLocationNameDetails(selected_func_id, func_id, security_group, selected_func_sub_id, func_sub_id) {
    let optionalParamsUrl = '';
    if (selected_func_sub_id != null && selected_func_sub_id !== '' && selected_func_sub_id !== undefined) {
        optionalParamsUrl += `selected_func_sub_id=${selected_func_sub_id}&`;
    }
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
    }
    return ApiService.getData(isLocal()
        ? `/SystemMaintenance/RetrieveSecurityGroupFunctionLocationNameDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/selected-functions/${selected_func_id}/functions/${func_id}/security-groups/${security_group}/users/${useridFromLS()}/locationnames?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/selected-functions/${selected_func_id}/functions/${func_id}/security-groups/${security_group}/users/${useridFromLS()}/locationnames?${optionalParamsUrl}`)
}

export function RetrieveSecurityGroupFunctionLocTransferDetails(comp_id, buy_pt_id, func_id, func_sub_id, security_group) {
    let optionalParamsUrl = '';
    if (comp_id != null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
    }
    if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }
    if (func_id != null && func_id !== '' && func_id !== undefined) {
        optionalParamsUrl += `func_id=${func_id}&`
    }
    if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`
    }
    if (security_group != null && security_group !== '' && security_group !== undefined) {
        optionalParamsUrl += `security_group=${security_group}`
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveSecurityGroupFunctionLocTransferDetails/securitygroups?${optionalParamsUrl}`
            : `${systemmaintenance_url_api}securitygroups?${optionalParamsUrl}`
    )
}
export function UpdatePriceScheduleAudits(action_type, history_key_id) {
    let optionalParamsUrl = '';
    if (history_key_id != null && history_key_id !== '' && history_key_id !== undefined) {
        optionalParamsUrl += `history_key_id=${history_key_id}&`;
    }
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdatePriceScheduleAudits/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/pricescheduleaudits?${optionalParamsUrl}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/pricescheduleaudits?${optionalParamsUrl}`);
}

export function CreateSecurityGroupFunctionTransfer(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateSecurityGroupFunctionTransfer/securitygroupfunctions`
        : `${systemmaintenance_url_api}securitygroupfunctions`, data);
}

export function UpdateSecurityGroupFunctionTransfer(func_id, func_sub_id, security_group, dataObj) {
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateSecurityGroupFunctionTransfer/companies/${compIdFromLS()}/functions/${func_id}/sub-functions/${func_sub_id}/security-groups/${security_group}/securityfunctions`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/functions/${func_id}/sub-functions/${func_sub_id}/security-groups/${security_group}/securityfunctions`, dataObj)
}

export function CreateVendorWireTransfer(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateVendorWireTransfer/wiretransfers`
        : `${systemmaintenance_url_api}wiretransfers`, data);
}

export function UpdateVendorWireTransfer(vendor_num, dataObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateVendorWireTransfer/vendors/${vendor_num}/wiretransfers`
        : `${systemmaintenance_url_api}vendors/${vendor_num}/wiretransfers`, dataObj);
}

export function RemoveVendorWireTransfer(vendor_num) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveVendorWireTransfer/vendors/${vendor_num}/vendorwiretransfers`
        : `${systemmaintenance_url_api}vendors/${vendor_num}/vendorwiretransfers`);
}

export function CreateUserSecurityImport(from_user_id, to_user_id) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateUserSecurityImport/companies/${compIdFromLS()}/from-users/${from_user_id}/to-users/${to_user_id}/users/${useridFromLS()}/securities`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/from-users/${from_user_id}/to-users/${to_user_id}/users/${useridFromLS()}/securities`, []
    )
}

export function RemoveFunctionXref(user_id, func_id, func_sub_id) { //do not replace this userid with default userid
    let optionsParamsURL = '';
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionsParamsURL += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveFunctionXref/companies/${compIdFromLS()}/users/${user_id}/functions/${func_id}/functionxrefs?${optionsParamsURL}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/functions/${func_id}/functionxrefs?${optionsParamsURL}`)
}

export function RemoveUserLocationTransfer(user_id, buy_pt_id) { //do not replace this userid with default userid 
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveUserLocationTransfer/companies/${compIdFromLS()}/users/${user_id}/buying-points/${buy_pt_id}/UserLocationTransfer`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${user_id}/buying-points/${buy_pt_id}/UserLocationTransfer`)
}

export function RemoveSecurityGroupFunctionLocTransferDetails(buy_pt_id, func_id, func_sub_id, security_group) { //do not replace this userid with default userid 
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveSecurityGroupFunctionLocTransferDetails/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/functions/${func_id}/sub-functions/${func_sub_id}/security-groups/${security_group}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/buying-points/${buy_pt_id}/functions/${func_id}/sub-functions/${func_sub_id}/security-groups/${security_group}`)
}

export function CreateUserFunctionTransfer(dataObj) {
    return ApiService.postData(isLocal()
        ? `/SystemMaintenance/CreateUserFunctionTransfer/companies/${compIdFromLS()}/users/${useridFromLS()}/userfunctions`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${useridFromLS()}/userfunctions`, dataObj)
}

export function UpdateUserFunctionTransfer(userId, func_id, func_sub_id, dataObj) {
    let optionalParamsUrl = ``
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateUserFunctionTransfer/companies/${compIdFromLS()}/users/${userId}/functions/${func_id}/userfunctions?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/users/${userId}/functions/${func_id}/userfunctions?${optionalParamsUrl}`, dataObj)
}
export function CreateFunctionControl(data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateFunctionControl/functions`
        : `${systemmaintenance_url_api}functions`, data);
}

export function UpdateFunctionControl(func_id,func_sub_id, data) {
    let optionalParamsUrl = ``
    if (func_sub_id !== undefined && func_sub_id !== '' && func_sub_id !== null) {
        optionalParamsUrl += `func_sub_id=${func_sub_id}`;
    }
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateFunctionControl/functions/${func_id}/functions?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}functions/${func_id}/functions?${optionalParamsUrl}`, data);
}

export function RemoveDiscountScheduleDetails(buy_pt_id, area_id, pnut_type_id, discount_type, discount_schedule) {

    let optionalParamsUrl = ``
    if (area_id !== undefined && area_id !== '' && area_id !== null) {
        optionalParamsUrl += `&area_id=${area_id}`;
    }
    if (pnut_type_id !== undefined && pnut_type_id !== '' && pnut_type_id !== null) {
        optionalParamsUrl += `&pnut_type_id=${pnut_type_id}`;
    }
    if (discount_type !== undefined && discount_type !== '' && discount_type !== null) {
        optionalParamsUrl += `&discount_type=${discount_type}`;
    }
    if (discount_schedule !== undefined && discount_schedule !== '' && discount_schedule !== null) {
        optionalParamsUrl += `&discount_schedule=${discount_schedule}`;
    }

    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveDiscountScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}?${optionalParamsUrl}`
    )
}


export function RemovePriceScheduleDetails(buy_pt_id, area_id, pnut_type_id) {

    let optionalParamsUrl = ``
    if (area_id !== undefined && area_id !== '' && area_id !== null) {
        optionalParamsUrl += `&area_id=${area_id}`;
    }
    if (pnut_type_id !== undefined && pnut_type_id !== '' && pnut_type_id !== null) {
        optionalParamsUrl += `&pnut_type_id=${pnut_type_id}`;
    }

    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemovePriceScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buy_pt_id}?${optionalParamsUrl}`
    )
}


export function CreatePriceSchedule(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreatePriceSchedule/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/priceschedules`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/priceschedules`, data);
}


export function CreateDiscountSchedule(buy_pt_id, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateDiscountSchedule/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/discountschedules`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/discountschedules`, data);
}

export function RetrieveDiscountScheduleDetails(area_id, pnut_type_id, discount_type, discount_schedule) {

    let optionalParamsUrl = ``
    if (area_id !== undefined && area_id !== '' && area_id !== null) {
        optionalParamsUrl += `&area_id=${area_id}`;
    }
    if (pnut_type_id !== undefined && pnut_type_id !== '' && pnut_type_id !== null) {
        optionalParamsUrl += `&pnut_type_id=${pnut_type_id}`;
    }
    if (discount_type !== undefined && discount_type !== '' && discount_type !== null) {
        optionalParamsUrl += `&discount_type=${discount_type}`;
    }
    if (discount_schedule !== undefined && discount_schedule !== '' && discount_schedule !== null) {
        optionalParamsUrl += `&discount_schedule=${discount_schedule}`;
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveDiscountScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/discountschedules?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/discountschedules?${optionalParamsUrl}`)
}
export function CreateCmaDmaControls(buy_pt_id, LstrHTTP) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateCmaDmaControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/cmadmacontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/cmadmacontrols`, LstrHTTP);
}
export function UpdateCmaDmaControls(buy_pt_id, state_id, county_id, vendor_num, LstrHTTP) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateCmaDmaControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/states/${state_id}/counties/${county_id}/vendors/${vendor_num}/cmadmacontrols`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/states/${state_id}/counties/${county_id}/vendors/${vendor_num}/cmadmacontrols`, LstrHTTP);
}
export function RetrievePpsDashboard() {

    let optionalParamsUrl = ``
        optionalParamsUrl += `&comp_id=${compIdFromLS()}`;
        optionalParamsUrl += `&crop_year=${cropYearFromLS()}`;
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrievePpsDashboard/ppsdashboard?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}ppsdashboard?${optionalParamsUrl}`)
}

