/* eslint-disable*/
import React from "react";
import Footer from "./Footer";
import { cleanup, screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../common/renderTestScreen";

describe("Footer Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Footer />);
    });
  });
  afterEach(cleanup);
  test("is Footer Loads Successfully", async () => {
    const Footer = screen.getByTestId("Footer");
    expect(false).toBe(false);
  });
});
