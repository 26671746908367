/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  getData,
  setValue,
  disable,
  getValue,
  hide,
  setData
} from "../../shared/WindowImports";

import "./ManualRTPHistView.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import FormLabel from "react-bootstrap/esm/FormLabel";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualRTPHistView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualRTPHistView",
    windowName: "ManualRTPHistView",
    template: "Standard Screen",
    Cols: "4",
    ColsForMobile: "4",
    ColsForTabLandscape: "4",
    ColsForTabPotrait: "4",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualRTPHistView",
    // START_USER_CODE-USER_ManualRTPHistView_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Ready to Pay Audit History Detail View",
      scrCode: "PN0210D",
    },
    // END_USER_CODE-USER_ManualRTPHistView_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntnrExit",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    dtTime: {
      name: "dtTime",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistView",
      Label: "Date Time:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_dtTime_PROPERTIES

      // END_USER_CODE-USER_dtTime_PROPERTIES
    },
    lblAfter: {
      name: "lblAfter",
      type: "LabelWidget",
      parent: "grpbxAfter",
      Label: "After",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAfter_PROPERTIES

      // END_USER_CODE-USER_lblAfter_PROPERTIES
    },
    lblBefore: {
      name: "lblBefore",
      type: "LabelWidget",
      parent: "grpbxBefore",
      Label: "Before",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBefore_PROPERTIES

      // END_USER_CODE-USER_lblBefore_PROPERTIES
    },
    txtAction: {
      name: "txtAction",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistView",
      Label: "Action:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAction_PROPERTIES

      // END_USER_CODE-USER_txtAction_PROPERTIES
    },
    txtRTPContract: {
      name: "txtRTPContract",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPContract_PROPERTIES

      // END_USER_CODE-USER_txtRTPContract_PROPERTIES
    },
    txtContract1: {
      name: "txtContract1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract1_PROPERTIES

      // END_USER_CODE-USER_txtContract1_PROPERTIES
    },
    txtCounty: {
      name: "txtCounty",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Country:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCounty_PROPERTIES

      // END_USER_CODE-USER_txtCounty_PROPERTIES
    },
    txtCounty1: {
      name: "txtCounty1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Country:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCounty1_PROPERTIES

      // END_USER_CODE-USER_txtCounty1_PROPERTIES
    },
    txtRTPDeliveryAgreement: {
      name: "txtRTPDeliveryAgreement",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Delivery Agreement #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPDeliveryAgreement_PROPERTIES

      // END_USER_CODE-USER_txtRTPDeliveryAgreement_PROPERTIES
    },
    txtDeliveryAgreement1: {
      name: "txtDeliveryAgreement1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Delivery Agreement #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryAgreement1_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryAgreement1_PROPERTIES
    },
    txtRTPFarm: {
      name: "txtRTPFarm",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPFarm_PROPERTIES

      // END_USER_CODE-USER_txtRTPFarm_PROPERTIES
    },
    txtFarm1: {
      name: "txtFarm1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm1_PROPERTIES

      // END_USER_CODE-USER_txtFarm1_PROPERTIES
    },
    txtFarmSettle: {
      name: "txtFarmSettle",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Farm Settle #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSettle_PROPERTIES

      // END_USER_CODE-USER_txtFarmSettle_PROPERTIES
    },
    txtfarmSettle1: {
      name: "txtfarmSettle1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Farm Settle #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtfarmSettle1_PROPERTIES

      // END_USER_CODE-USER_txtfarmSettle1_PROPERTIES
    },
    txtRTPFreightMemo: {
      name: "txtRTPFreightMemo",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Freight Memo #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPFreightMemo_PROPERTIES

      // END_USER_CODE-USER_txtRTPFreightMemo_PROPERTIES
    },
    txtFreightMemo1: {
      name: "txtFreightMemo1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Freight Memo #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightMemo1_PROPERTIES

      // END_USER_CODE-USER_txtFreightMemo1_PROPERTIES
    },
    txtInspection: {
      name: "txtInspection",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Inspection (SC95) #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspection_PROPERTIES

      // END_USER_CODE-USER_txtInspection_PROPERTIES
    },
    txtInspection1: {
      name: "txtInspection1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Inspection (SC95) #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspection1_PROPERTIES

      // END_USER_CODE-USER_txtInspection1_PROPERTIES
    },
    txtLoanRecductionInd: {
      name: "txtLoanRecductionInd",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Loan Recduction Ind:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRecductionInd_PROPERTIES

      // END_USER_CODE-USER_txtLoanRecductionInd_PROPERTIES
    },
    txtLoanReductionInd1: {
      name: "txtLoanReductionInd1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Loan Reduction Ind:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanReductionInd1_PROPERTIES

      // END_USER_CODE-USER_txtLoanReductionInd1_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistView",
      Label: "Location:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtOption: {
      name: "txtOption",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Option:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOption_PROPERTIES

      // END_USER_CODE-USER_txtOption_PROPERTIES
    },
    txtOption1: {
      name: "txtOption1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Option:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOption1_PROPERTIES

      // END_USER_CODE-USER_txtOption1_PROPERTIES
    },
    txtRTPProceeds: {
      name: "txtRTPProceeds",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Proceeds $:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPProceeds_PROPERTIES

      // END_USER_CODE-USER_txtRTPProceeds_PROPERTIES
    },
    txtRTPProceeds1: {
      name: "txtRTPProceeds1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Proceeds $:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPProceeds1_PROPERTIES

      // END_USER_CODE-USER_txtRTPProceeds1_PROPERTIES
    },
    txtReason: {
      name: "txtReason",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistView",
      Label: "Reason:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReason_PROPERTIES

      // END_USER_CODE-USER_txtReason_PROPERTIES
    },
    txtRemitTo: {
      name: "txtRemitTo",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Remit To:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo_PROPERTIES
    },
    txtRemitTo1: {
      name: "txtRemitTo1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Remit To:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo1_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo1_PROPERTIES
    },
    txtSettlement: {
      name: "txtSettlement",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: " Settlement (1007) #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettlement_PROPERTIES

      // END_USER_CODE-USER_txtSettlement_PROPERTIES
    },
    txtSettlement1: {
      name: "txtSettlement1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Settlement (1007)#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettlement1_PROPERTIES

      // END_USER_CODE-USER_txtSettlement1_PROPERTIES
    },
    txtShrinkPayment: {
      name: "txtShrinkPayment",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Shrink Payment:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPayment_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPayment_PROPERTIES
    },
    txtShrinkPayment1: {
      name: "txtShrinkPayment1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Shrink Payment:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPayment1_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPayment1_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtState1: {
      name: "txtState1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState1_PROPERTIES

      // END_USER_CODE-USER_txtState1_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Status:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtStatus1: {
      name: "txtStatus1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Status:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus1_PROPERTIES

      // END_USER_CODE-USER_txtStatus1_PROPERTIES
    },
    txtStorageHandlingInd: {
      name: "txtStorageHandlingInd",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Storage Handling Ind:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorageHandlingInd_PROPERTIES

      // END_USER_CODE-USER_txtStorageHandlingInd_PROPERTIES
    },
    txtStorageHandlingInd1: {
      name: "txtStorageHandlingInd1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Storage Handling Ind:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorageHandlingInd1_PROPERTIES

      // END_USER_CODE-USER_txtStorageHandlingInd1_PROPERTIES
    },
    txtTradeInsp: {
      name: "txtTradeInsp",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Trade Insp #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInsp_PROPERTIES

      // END_USER_CODE-USER_txtTradeInsp_PROPERTIES
    },
    txtTradeInsp1: {
      name: "txtTradeInsp1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Trade Insp #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInsp1_PROPERTIES

      // END_USER_CODE-USER_txtTradeInsp1_PROPERTIES
    },
    txtTradeSettle: {
      name: "txtTradeSettle",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Trade Settle #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettle_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettle_PROPERTIES
    },
    txtTradeSettle1: {
      name: "txtTradeSettle1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Trade Settle #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettle1_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettle1_PROPERTIES
    },
    txtUser: {
      name: "txtUser",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistView",
      Label: "User:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUser_PROPERTIES

      // END_USER_CODE-USER_txtUser_PROPERTIES
    },
    txtRTPVendor: {
      name: "txtRTPVendor",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRTPVendor_PROPERTIES

      // END_USER_CODE-USER_txtRTPVendor_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorName_PROPERTIES

      // END_USER_CODE-USER_txtVendorName_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Vendor #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor1Name: {
      name: "txtVendor1Name",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Vendor #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1Name_PROPERTIES

      // END_USER_CODE-USER_txtVendor1Name_PROPERTIES
    },
    txtwhseRcpt: {
      name: "txtwhseRcpt",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Whse Rcpt #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtwhseRcpt_PROPERTIES

      // END_USER_CODE-USER_txtwhseRcpt_PROPERTIES
    },
    txtWhseRcpt1: {
      name: "txtWhseRcpt1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Whse Rcpt # :",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseRcpt1_PROPERTIES

      // END_USER_CODE-USER_txtWhseRcpt1_PROPERTIES
    },
    txtWhseReceiptLoc: {
      name: "txtWhseReceiptLoc",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Whse Receipt Loc:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseReceiptLoc_PROPERTIES

      // END_USER_CODE-USER_txtWhseReceiptLoc_PROPERTIES
    },
    txtWhseReceiptLoc1: {
      name: "txtWhseReceiptLoc1",
      type: "TextBoxWidget",
      parent: "grpbxAfter",
      Label: "Whse Receipt Loc:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseReceiptLoc1_PROPERTIES

      // END_USER_CODE-USER_txtWhseReceiptLoc1_PROPERTIES
    },
    grpbxManualRTPHistView: {
      name: "grpbxManualRTPHistView",
      type: "GroupBoxWidget",
      parent: "ManualRTPHistView",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxManualRTPHistView_PROPERTIES

      // END_USER_CODE-USER_grpbxManualRTPHistView_PROPERTIES
    },
    grpbxBefore: {
      name: "grpbxBefore",
      type: "GroupBoxWidget",
      parent: "ManualRTPHistView",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxBefore_PROPERTIES

      // END_USER_CODE-USER_grpbxBefore_PROPERTIES
    },
    grpbxAfter: {
      name: "grpbxAfter",
      type: "GroupBoxWidget",
      parent: "ManualRTPHistView",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxAfter_PROPERTIES

      // END_USER_CODE-USER_grpbxAfter_PROPERTIES
    },
    cmmndCntnrExit: {
      name: "cmmndCntnrExit",
      type: "CommandContainerWidget",
      parent: "ManualRTPHistView",
      Label: "commandcontainerwidget0",
      ColSpan: "4",
      Height: "",
      Width: "",
      Cols: "4",
      // START_USER_CODE-USER_cmmndCntnrExit_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrExit_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    setEventHandlers()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  
  // Form Load function
  const formLoad = async() => {
    try{
      await bFillForm()
      setLoading(false)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  // bFillForm function
  const bFillForm = async() => {
    try {
      hide(thisObj, 'txtStorageHandlingInd')
      hide(thisObj, 'txtStorageHandlingInd1')
      
      let dataObj = getData(thisObj, 'frmManualRTPHistView')
      let transaction_num = dataObj.lblTransactionNumber 
      let audit_date_time = moment(dataObj.txtAuditDateTime).format("yyyy-MM-DD HH:mm:ss.SSS")
      if (transaction_num == '' || transaction_num == null || transaction_num == undefined || audit_date_time == '' || audit_date_time == null || audit_date_time == undefined) {
        return;
      }
      let data = await SystemMaintenanceApplicationSupportService.RetrieveManualReadytoPayMaintHistDetails(transaction_num, audit_date_time)
      if (data !== null && data !== undefined) {
        setValue(thisObj, 'txtAction', data[0].audit_action)
        setValue(thisObj, 'txtUser', data[0].audit_user)
        setValue(thisObj, 'dtTime', moment(data[0].audit_date_time).format('MM/DD/YYYY hh:mm:ss A'))
        setValue(thisObj, 'txtReason', data[0].audit_reason)
        setValue(thisObj, 'txtLocation', data[0].buy_pt)
        setValue(thisObj, 'txtRTPVendor', data[0].old_payee_vendor)
        setData(thisObj, 'txtRTPVendor', data[0].old_payee_vendor)
        setValue(thisObj, 'txtVendor1', data[0].new_payee_vendor)
        setData(thisObj, 'txtVendor1', data[0].new_payee_vendor)
        setValue(thisObj, 'txtVendorName', data[0].old_vendor_name)
        setData(thisObj, 'txtVendorName', data[0].old_vendor_name)
        setValue(thisObj, 'txtVendor1Name', data[0].new_vendor_name)
        setData(thisObj, 'txtVendor1Name', data[0].new_vendor_name)
        setValue(thisObj, 'txtRemitTo', data[0].old_payee_remit)
        setData(thisObj, 'txtRemitTo', data[0].old_payee_remit)
        setValue(thisObj, 'txtRemitTo1', data[0].new_payee_remit)
        setData(thisObj, 'txtRemitTo1', data[0].new_payee_remit)
        setValue(thisObj, 'txtStatus', data[0].old_rtp_status)
        setData(thisObj, 'txtStatus', data[0].old_rtp_status)
        setValue(thisObj, 'txtStatus1', data[0].new_rtp_status)
        setData(thisObj, 'txtStatus1', data[0].new_rtp_status)
        setValue(thisObj, 'txtRTPProceeds', data[0].old_rtp_proceeds)
        setData(thisObj, 'txtRTPProceeds', data[0].old_rtp_proceeds)
        setValue(thisObj, 'txtRTPProceeds1', data[0].new_rtp_proceeds)
        setData(thisObj, 'txtRTPProceeds1', data[0].new_rtp_proceeds)
        setValue(thisObj, 'txtRTPDeliveryAgreement', data[0].old_delv_agree_num)
        setData(thisObj, 'txtRTPDeliveryAgreement', data[0].old_delv_agree_num)
        setValue(thisObj, 'txtDeliveryAgreement1', data[0].new_delv_agree_num)
        setData(thisObj, 'txtDeliveryAgreement1', data[0].new_delv_agree_num)
        setValue(thisObj, 'txtRTPContract', data[0].old_cont_num)
        setData(thisObj, 'txtRTPContract', data[0].old_cont_num)
        setValue(thisObj, 'txtContract1', data[0].new_cont_num)
        setData(thisObj, 'txtContract1', data[0].new_cont_num)
        setValue(thisObj, 'txtRTPFreightMemo', data[0].old_fa_memo_num)
        setData(thisObj, 'txtRTPFreightMemo', data[0].old_fa_memo_num)
        setValue(thisObj, 'txtFreightMemo1', data[0].new_fa_memo_num)
        setData(thisObj, 'txtFreightMemo1', data[0].new_fa_memo_num)
        setValue(thisObj, 'txtInspection', data[0].old_insp_num)
        setData(thisObj, 'txtInspection', data[0].old_insp_num)
        setValue(thisObj, 'txtInspection1', data[0].new_insp_num)
        setData(thisObj, 'txtInspection1', data[0].new_insp_num)
        setValue(thisObj, 'txtTradeInsp', data[0].old_trade_insp)
        setData(thisObj, 'txtTradeInsp', data[0].old_trade_insp)
        setValue(thisObj, 'txtTradeInsp1', data[0].new_trade_insp)
        setData(thisObj, 'txtTradeInsp1', data[0].new_trade_insp)
        setValue(thisObj, 'txtSettlement', data[0].old_settle_num)
        setData(thisObj, 'txtSettlement', data[0].old_settle_num)
        setValue(thisObj, 'txtSettlement1', data[0].new_settle_num)
        setData(thisObj, 'txtSettlement1', data[0].new_settle_num)
        setValue(thisObj, 'txtTradeSettle', data[0].old_trade_settle)
        setData(thisObj, 'txtTradeSettle', data[0].old_trade_settle)
        setValue(thisObj, 'txtTradeSettle1', data[0].new_trade_settle)
        setData(thisObj, 'txtTradeSettle1', data[0].new_trade_settle)
        setValue(thisObj, 'txtwhseRcpt', data[0].old_whse_rcpt)
        setData(thisObj, 'txtwhseRcpt', data[0].old_whse_rcpt)
        setValue(thisObj, 'txtWhseRcpt1', data[0].new_whse_rcpt)
        setData(thisObj, 'txtWhseRcpt1', data[0].new_whse_rcpt)
        setValue(thisObj, 'txtWhseReceiptLoc', data[0].old_whse_bp)
        setData(thisObj, 'txtWhseReceiptLoc', data[0].old_whse_bp)
        setValue(thisObj, 'txtWhseReceiptLoc1', data[0].new_whse_bp)
        setData(thisObj, 'txtWhseReceiptLoc1', data[0].new_whse_bp)
        setValue(thisObj, 'txtState', data[0].old_state_abbr)
        setData(thisObj, 'txtState', data[0].old_state_abbr)
        setValue(thisObj, 'txtState1', data[0].new_state_abbr)
        setData(thisObj, 'txtState1', data[0].new_state_abbr)
        setValue(thisObj, 'txtCounty', data[0].old_county_id)
        setData(thisObj, 'txtCounty', data[0].old_county_id)
        setValue(thisObj, 'txtCounty1', data[0].new_county_id)
        setData(thisObj, 'txtCounty1', data[0].new_county_id)
        setValue(thisObj, 'txtRTPFarm', data[0].old_farm_id)
        setData(thisObj, 'txtRTPFarm', data[0].old_farm_id)
        setValue(thisObj, 'txtFarm1', data[0].new_farm_id)
        setData(thisObj, 'txtFarm1', data[0].new_farm_id)
        setValue(thisObj, 'txtFarmSettle', data[0].old_farm_suffix)
        setData(thisObj, 'txtFarmSettle', data[0].old_farm_suffix)
        setValue(thisObj, 'txtfarmSettle1', data[0].new_farm_suffix)
        setData(thisObj, 'txtfarmSettle1', data[0].new_farm_suffix)
        setValue(thisObj, 'txtOption', data[0].old_opt_pay_ind)
        setData(thisObj, 'txtOption', data[0].old_opt_pay_ind)
        setValue(thisObj, 'txtOption1', data[0].new_opt_pay_ind)
        setData(thisObj, 'txtOption1', data[0].new_opt_pay_ind)
        setValue(thisObj, 'txtShrinkPayment', data[0].old_shrink_pay_ind)
        setData(thisObj, 'txtShrinkPayment', data[0].old_shrink_pay_ind)
        setValue(thisObj, 'txtShrinkPayment1', data[0].new_shrink_pay_ind)
        setData(thisObj, 'txtShrinkPayment1', data[0].new_shrink_pay_ind)
        setValue(thisObj, 'txtLoanRecductionInd', data[0].old_loan_reduct_ind)
        setData(thisObj, 'txtLoanRecductionInd', data[0].old_loan_reduct_ind)
        setValue(thisObj, 'txtLoanReductionInd1', data[0].new_loan_reduct_ind)
        setData(thisObj, 'txtLoanReductionInd1', data[0].new_loan_reduct_ind)
        
        if (data[0].audit_action == 'CHANGE') {
          await setColor()
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in bFillForm`
        );
      }
      return false;
    }
  } 

  // setColor function
  const setColor = async() => {
    try {
      let vendorNumber = getData(thisObj, 'txtRTPVendor')
      let vendorNumber1 = getData(thisObj, 'txtVendor1')
      let remitTo = getData(thisObj, 'txtRemitTo')
      let remitTo1 = getData(thisObj, 'txtRemitTo1')
      let status = getData(thisObj, 'txtStatus')
      let status1 = getData(thisObj, 'txtStatus1')
      let proceeds = getData(thisObj, 'txtRTPProceeds')
      let proceeds1 = getData(thisObj, 'txtRTPProceeds1')
      let contractNumber = getData(thisObj, 'txtRTPContract')
      let contractNumber1 = getData(thisObj, 'txtContract1')
      let FV95Number = getData(thisObj, 'txtInspection')
      let FV95Number1 = getData(thisObj, 'txtInspection1')
      let settlementNumber = getData(thisObj, 'txtSettlement')
      let settlementNumber1 = getData(thisObj, 'txtSettlement1')
      let delvAgree = getData(thisObj, 'txtRTPDeliveryAgreement')
      let delvAgree1 = getData(thisObj, 'txtDeliveryAgreement1')
      let freightMemoNum = getData(thisObj, 'txtRTPFreightMemo')
      let freightMemoNum1 = getData(thisObj, 'txtFreightMemo1')
      let tradeInspNum = getData(thisObj, 'txtTradeInsp')
      let tradeInspNum1 = getData(thisObj, 'txtTradeInsp1')
      let tradeSettleNum = getData(thisObj, 'txtTradeSettle')
      let tradeSettleNum1 = getData(thisObj, 'txtTradeSettle1')
      let whRcptNum = getData(thisObj, 'txtwhseRcpt')
      let whRcptNum1 = getData(thisObj, 'txtWhseRcpt1')
      let whRcptLocation = getData(thisObj, 'txtWhseReceiptLoc')
      let whRcptLocation1 = getData(thisObj, 'txtWhseReceiptLoc1')
      let stateID = getData(thisObj, 'txtState')
      let stateID1 = getData(thisObj, 'txtState1')
      let countyID = getData(thisObj, 'txtCounty')
      let countyID1 = getData(thisObj, 'txtCounty1')
      let farmID = getData(thisObj, 'txtRTPFarm')
      let farmID1 = getData(thisObj, 'txtFarm1')
      let farmSuffix = getData(thisObj, 'txtFarmSettle')
      let farmSuffix1 = getData(thisObj, 'txtfarmSettle1')
      let optionPayInd = getData(thisObj, 'txtOption')
      let optionPayInd1 = getData(thisObj, 'txtOption1')
      let shrinkPaymentInd = getData(thisObj, 'txtShrinkPayment')
      let shrinkPaymentInd1 = getData(thisObj, 'txtShrinkPayment1')
      let loanReductInd = getData(thisObj, 'txtStorageHandlingInd')
      let loanReductInd1 = getData(thisObj, 'txtStorageHandlingInd1')
      let textBoxColor
      let textBoxColor1
      
      if (vendorNumber !== vendorNumber1) {
        textBoxColor = document.getElementById('txtRTPVendor')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtVendor1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (remitTo !== remitTo1) {
        textBoxColor = document.getElementById('txtRemitTo')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtRemitTo1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (status !== status1) {
        textBoxColor = document.getElementById('txtStatus')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtStatus1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (proceeds !== proceeds1) {
        textBoxColor = document.getElementById('txtRTPProceeds')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtRTPProceeds1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (contractNumber !== contractNumber1) {
        textBoxColor = document.getElementById('txtRTPContract')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtContract1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (FV95Number !== FV95Number1) {
        textBoxColor = document.getElementById('txtInspection')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtInspection1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (settlementNumber !== settlementNumber1) {
        textBoxColor = document.getElementById('txtSettlement')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtSettlement1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (delvAgree !== delvAgree1) {
        textBoxColor = document.getElementById('txtRTPDeliveryAgreement')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtDeliveryAgreement1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (freightMemoNum !== freightMemoNum1) {
        textBoxColor = document.getElementById('txtRTPFreightMemo')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtFreightMemo1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (tradeInspNum !== tradeInspNum1) {
        textBoxColor = document.getElementById('txtTradeInsp')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtTradeInsp1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (tradeSettleNum !== tradeSettleNum1) {
        textBoxColor = document.getElementById('txtTradeSettle')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtTradeSettle1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (whRcptNum !== whRcptNum1) {
        textBoxColor = document.getElementById('txtwhseRcpt')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtWhseRcpt1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (whRcptLocation !== whRcptLocation1) {
        textBoxColor = document.getElementById('txtWhseReceiptLoc')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtWhseReceiptLoc1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (stateID !== stateID1) {
        textBoxColor = document.getElementById('txtState')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtState1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (countyID !== countyID1) {
        textBoxColor = document.getElementById('txtCounty')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtCounty1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (farmID !== farmID1) {
        textBoxColor = document.getElementById('txtRTPFarm')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtFarm1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (farmSuffix !== farmSuffix1) {
        textBoxColor = document.getElementById('txtFarmSettle')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtfarmSettle1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (optionPayInd !== optionPayInd1) {
        textBoxColor = document.getElementById('txtOption')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtOption1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (shrinkPaymentInd !== shrinkPaymentInd1) {
        textBoxColor = document.getElementById('txtShrinkPayment')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtShrinkPayment1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }

      if (loanReductInd !== loanReductInd1) {
        textBoxColor = document.getElementById('txtStorageHandlingInd')
        textBoxColor.style.backgroundColor = '#fff478'
        textBoxColor1 = document.getElementById('txtStorageHandlingInd1')
        textBoxColor1.style.backgroundColor = '#fff478'
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in setColor function`
        );
      }
      return false;
    }
  }

  // Function for setEventHandlers
 const setEventHandlers = () => {
    try {
      document.getElementById('txtAction').addEventListener('keydown', notAllowChar);
      document.getElementById('txtUser').addEventListener('keydown', notAllowChar);
      document.getElementById('dtTime').addEventListener('keydown', notAllowChar);
      document.getElementById('txtReason').addEventListener('keydown', notAllowChar);
      document.getElementById('txtLocation').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPVendor').addEventListener('keydown', notAllowChar);
      document.getElementById('txtVendor1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtVendorName').addEventListener('keydown', notAllowChar);
      document.getElementById('txtVendor1Name').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRemitTo').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRemitTo1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtStatus').addEventListener('keydown', notAllowChar);
      document.getElementById('txtStatus1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPProceeds').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPProceeds1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPDeliveryAgreement').addEventListener('keydown', notAllowChar);
      document.getElementById('txtDeliveryAgreement1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPContract').addEventListener('keydown', notAllowChar);
      document.getElementById('txtContract1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPFreightMemo').addEventListener('keydown', notAllowChar);
      document.getElementById('txtFreightMemo1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtInspection').addEventListener('keydown', notAllowChar);
      document.getElementById('txtInspection1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtTradeInsp').addEventListener('keydown', notAllowChar);
      document.getElementById('txtTradeInsp1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtSettlement').addEventListener('keydown', notAllowChar);
      document.getElementById('txtSettlement1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtTradeSettle').addEventListener('keydown', notAllowChar);
      document.getElementById('txtTradeSettle1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtwhseRcpt').addEventListener('keydown', notAllowChar);
      document.getElementById('txtWhseRcpt1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtWhseReceiptLoc').addEventListener('keydown', notAllowChar);
      document.getElementById('txtWhseReceiptLoc1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtState').addEventListener('keydown', notAllowChar);
      document.getElementById('txtState1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtCounty').addEventListener('keydown', notAllowChar);
      document.getElementById('txtCounty1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtRTPFarm').addEventListener('keydown', notAllowChar);
      document.getElementById('txtFarm1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtFarmSettle').addEventListener('keydown', notAllowChar);
      document.getElementById('txtfarmSettle1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtOption').addEventListener('keydown', notAllowChar);
      document.getElementById('txtOption1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtShrinkPayment').addEventListener('keydown', notAllowChar);
      document.getElementById('txtShrinkPayment1').addEventListener('keydown', notAllowChar);
      document.getElementById('txtLoanRecductionInd').addEventListener('keydown', notAllowChar);
      document.getElementById('txtLoanReductionInd1').addEventListener('keydown', notAllowChar);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in setEventHandlers function`
        );
      }
      return false;
    }
  }

// notAllowChar function (Prevents input from user)
  const notAllowChar = (e) => {
    try {
      e.preventDefault();
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in notAllowChar function`
        );
      }
      return false;
    }
  }  

  //Click event for Cancel button
const onbtnExitClick = () => { 
  try {
    document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPHistViewPopUp").childNodes[0].click()
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnExit event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnExitClick = onbtnExitClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualRTPHistView*/}

              {/* END_USER_CODE-USER_BEFORE_ManualRTPHistView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualRTPHistView*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualRTPHistView*/}

              <GroupBoxWidget
                conf={state.grpbxManualRTPHistView}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtAction*/}

                {/* END_USER_CODE-USER_BEFORE_txtAction*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAction}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAction*/}

                {/* END_USER_CODE-USER_AFTER_txtAction*/}
                {/* START_USER_CODE-USER_BEFORE_txtUser*/}

                {/* END_USER_CODE-USER_BEFORE_txtUser*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUser}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUser*/}

                {/* END_USER_CODE-USER_AFTER_txtUser*/}
                {/* START_USER_CODE-USER_BEFORE_dtTime*/}

                {/* END_USER_CODE-USER_BEFORE_dtTime*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtTime}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_dtTime*/}

                {/* END_USER_CODE-USER_AFTER_dtTime*/}
                {/* START_USER_CODE-USER_BEFORE_txtReason*/}

                {/* END_USER_CODE-USER_BEFORE_txtReason*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReason}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReason*/}

                {/* END_USER_CODE-USER_AFTER_txtReason*/}
                {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtLocation*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualRTPHistView*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualRTPHistView*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxBefore*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBefore*/}

              <GroupBoxWidget conf={state.grpbxBefore} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblBefore*/}

                {/* END_USER_CODE-USER_BEFORE_lblBefore*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBefore}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBefore*/}

                {/* END_USER_CODE-USER_AFTER_lblBefore*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_txtRemitTo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRemitTo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_txtRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStatus}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPProceeds*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPDeliveryAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPDeliveryAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPDeliveryAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPDeliveryAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPDeliveryAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPContract*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPFreightMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPFreightMemo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPFreightMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPFreightMemo*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPFreightMemo*/}
                {/* START_USER_CODE-USER_BEFORE_txtInspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtInspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInspection*/}

                {/* END_USER_CODE-USER_AFTER_txtInspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInsp*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInsp*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInsp}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInsp*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInsp*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettlement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettlement*/}

                {/* END_USER_CODE-USER_AFTER_txtSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettle*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettle*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettle*/}
                {/* START_USER_CODE-USER_BEFORE_txtwhseRcpt*/}

                {/* END_USER_CODE-USER_BEFORE_txtwhseRcpt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtwhseRcpt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtwhseRcpt*/}

                {/* END_USER_CODE-USER_AFTER_txtwhseRcpt*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhseReceiptLoc*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhseReceiptLoc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhseReceiptLoc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhseReceiptLoc*/}

                {/* END_USER_CODE-USER_AFTER_txtWhseReceiptLoc*/}
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_txtCounty*/}

                {/* END_USER_CODE-USER_BEFORE_txtCounty*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCounty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCounty*/}

                {/* END_USER_CODE-USER_AFTER_txtCounty*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSettle*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSettle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSettle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSettle*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSettle*/}
                {/* START_USER_CODE-USER_BEFORE_txtOption*/}

                {/* END_USER_CODE-USER_BEFORE_txtOption*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOption}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOption*/}

                {/* END_USER_CODE-USER_AFTER_txtOption*/}
                {/* START_USER_CODE-USER_BEFORE_txtShrinkPayment*/}

                {/* END_USER_CODE-USER_BEFORE_txtShrinkPayment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShrinkPayment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShrinkPayment*/}

                {/* END_USER_CODE-USER_AFTER_txtShrinkPayment*/}
                {/* START_USER_CODE-USER_BEFORE_txtLoanRecductionInd*/}

                {/* END_USER_CODE-USER_BEFORE_txtLoanRecductionInd*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoanRecductionInd}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoanRecductionInd*/}

                {/* END_USER_CODE-USER_AFTER_txtLoanRecductionInd*/}
                {/* START_USER_CODE-USER_BEFORE_txtStorageHandlingInd*/}

                {/* END_USER_CODE-USER_BEFORE_txtStorageHandlingInd*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStorageHandlingInd}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStorageHandlingInd*/}

                {/* END_USER_CODE-USER_AFTER_txtStorageHandlingInd*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBefore*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBefore*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAfter*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAfter*/}

              <GroupBoxWidget conf={state.grpbxAfter} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAfter*/}

                {/* END_USER_CODE-USER_BEFORE_lblAfter*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAfter}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAfter*/}

                {/* END_USER_CODE-USER_AFTER_lblAfter*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor1Name}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                {/* START_USER_CODE-USER_BEFORE_txtRemitTo1*/}

                {/* END_USER_CODE-USER_BEFORE_txtRemitTo1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRemitTo1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRemitTo1*/}

                {/* END_USER_CODE-USER_AFTER_txtRemitTo1*/}
                {/* START_USER_CODE-USER_BEFORE_txtStatus1*/}

                {/* END_USER_CODE-USER_BEFORE_txtStatus1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStatus1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStatus1*/}

                {/* END_USER_CODE-USER_AFTER_txtStatus1*/}
                {/* START_USER_CODE-USER_BEFORE_txtRTPProceeds1*/}

                {/* END_USER_CODE-USER_BEFORE_txtRTPProceeds1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRTPProceeds1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRTPProceeds1*/}

                {/* END_USER_CODE-USER_AFTER_txtRTPProceeds1*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryAgreement1*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryAgreement1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryAgreement1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryAgreement1*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryAgreement1*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract1*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract1*/}

                {/* END_USER_CODE-USER_AFTER_txtContract1*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreightMemo1*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreightMemo1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreightMemo1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreightMemo1*/}

                {/* END_USER_CODE-USER_AFTER_txtFreightMemo1*/}
                {/* START_USER_CODE-USER_BEFORE_txtInspection1*/}

                {/* END_USER_CODE-USER_BEFORE_txtInspection1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspection1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInspection1*/}

                {/* END_USER_CODE-USER_AFTER_txtInspection1*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInsp1*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInsp1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInsp1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInsp1*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInsp1*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettlement1*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettlement1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettlement1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettlement1*/}

                {/* END_USER_CODE-USER_AFTER_txtSettlement1*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettle1*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettle1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettle1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettle1*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettle1*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhseRcpt1*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhseRcpt1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhseRcpt1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhseRcpt1*/}

                {/* END_USER_CODE-USER_AFTER_txtWhseRcpt1*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhseReceiptLoc1*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhseReceiptLoc1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhseReceiptLoc1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhseReceiptLoc1*/}

                {/* END_USER_CODE-USER_AFTER_txtWhseReceiptLoc1*/}
                {/* START_USER_CODE-USER_BEFORE_txtState1*/}

                {/* END_USER_CODE-USER_BEFORE_txtState1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState1*/}

                {/* END_USER_CODE-USER_AFTER_txtState1*/}
                {/* START_USER_CODE-USER_BEFORE_txtCounty1*/}

                {/* END_USER_CODE-USER_BEFORE_txtCounty1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCounty1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCounty1*/}

                {/* END_USER_CODE-USER_AFTER_txtCounty1*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm1*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm1*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm1*/}
                {/* START_USER_CODE-USER_BEFORE_txtfarmSettle1*/}

                {/* END_USER_CODE-USER_BEFORE_txtfarmSettle1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtfarmSettle1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtfarmSettle1*/}

                {/* END_USER_CODE-USER_AFTER_txtfarmSettle1*/}
                {/* START_USER_CODE-USER_BEFORE_txtOption1*/}

                {/* END_USER_CODE-USER_BEFORE_txtOption1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOption1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOption1*/}

                {/* END_USER_CODE-USER_AFTER_txtOption1*/}
                {/* START_USER_CODE-USER_BEFORE_txtShrinkPayment1*/}

                {/* END_USER_CODE-USER_BEFORE_txtShrinkPayment1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShrinkPayment1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShrinkPayment1*/}

                {/* END_USER_CODE-USER_AFTER_txtShrinkPayment1*/}
                {/* START_USER_CODE-USER_BEFORE_txtLoanReductionInd1*/}

                {/* END_USER_CODE-USER_BEFORE_txtLoanReductionInd1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoanReductionInd1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoanReductionInd1*/}

                {/* END_USER_CODE-USER_AFTER_txtLoanReductionInd1*/}
                {/* START_USER_CODE-USER_BEFORE_txtStorageHandlingInd1*/}

                {/* END_USER_CODE-USER_BEFORE_txtStorageHandlingInd1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStorageHandlingInd1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStorageHandlingInd1*/}

                {/* END_USER_CODE-USER_AFTER_txtStorageHandlingInd1*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAfter*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAfter*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrExit*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrExit*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrExit}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrExit*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrExit*/}

              {/* START_USER_CODE-USER_AFTER_ManualRTPHistView*/}

              {/* END_USER_CODE-USER_AFTER_ManualRTPHistView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualRTPHistView
);
