/* eslint-disable*/
import React from "react";
import ParentWidget from "../ParentWidget";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { DateTime } from "../common/DateTime";
import moment from "moment";
import { toast } from "react-toastify";
import { getIn, FastField } from "formik";
import { convertStringToJsonObject } from "../../../../common/ScreenInitialization";
import FileControl from "./FileControl";
import hotkeys from "hotkeys-js";
import $ from "jquery";
import { disable,enable } from "../../shared/ParentWindow";
const _kaledo = window._kaledo;

class ParentGridWidget extends ParentWidget {
  linkClick = this.props.linkClick;
  state = { open: !this.conf.defaultCollapsed };
  editable = this.conf.isEditable;
  defaultNewRow = {};
  operationInProgress = "";
  rows = this.props.rows;
  gridData = [];
  selected = [];
  timeFlag=false;

  actionList = [
    {
      actionName: "Add",
      iconName: "plus",
      clickEvent: () => {
        if (!this.isOperationInProgress()) {
          return;
        }
        //Issue no 101 start
        // disableBulkWidgets(
        //   this.props.refObject,
        //   this.screenConf,
        //   this.conf.name
        // );
        if (this.conf.disableArray) {
          this.conf.disableArray.map((widgetID) =>
            disable(this.props.refObject, widgetID)
          );
        }
        //Issue no 101 end
 
        if (this.conf.isEditable && _kaledo.isCobolTable) {
          for (let i = 0; i < this.conf.DisplaySize; i++) {
            this.newRow = JSON.parse(JSON.stringify(this.defaultNewRow));
            this.newRow.rowID$ = 0;
            this.newRow.editing = true;
            this.arrayHelpers.unshift(this.newRow);
          }
        } else {
          this.newRow = JSON.parse(JSON.stringify(this.defaultNewRow));
          this.newRow.rowID$ = 0;
          this.newRow.editing = true;
          this.arrayHelpers.unshift(this.newRow);
        }
        this.setState({
          okCancelButtons: this.okCancel.addActionOkCancel,
        });
        this.operationInProgress = "Add";
      },
      required: true,
    },
    {
      actionName: "Edit",
      iconName: "pencil",
      clickEvent: () => {
        if (!this.isOperationInProgress()) {
          return;
        }
        //Issue no 101 start
        // disableBulkWidgets(
        //   this.props.refObject,
        //   this.screenConf,
        //   this.conf.name
        // );
        if (this.conf.disableArray) {
          this.conf.disableArray.map((widgetID) =>
            disable(this.props.refObject, widgetID)
          );
        }
        //Issue no 101 end
        if (this.selected.length) {
          this.edit();
          this.operationInProgress = "Edit";
        }
      },
      required: true,
    },

    {
      actionName: "Delete",
      iconName: "trash",
      clickEvent: (isSubDetails) => {
        if (!this.isOperationInProgress()) {
          return;
        }
        if (this.selected.length) {
          this.deleteRow(isSubDetails, this.conf.deleteRowFunction);
        }
      },
      required: true,
    },
    {
      actionName: "Duplicate",
      iconName: "clone",
      clickEvent: (isSubDetails) => {
        if (!this.isOperationInProgress()) {
          return;
        }
        if (this.selected.length) {
          this.duplicateRows(isSubDetails);
        }
      },
      required: true,
    },
  ];
  okCancel = {
    addActionOkCancel: [
      {
        icon: "check",
        clickEvent: (isSubDetails) => {
          if (this.checkErrors()) {
            return;
          }
          let row = this.rows[0];
          row[this.statusColName] = "C";
          row.editing = false;
          this.gridData.push(row);
          this.setState({ okCancelButtons: [] });
          this.arrayHelpers.replace(0, { ...row });
          this.emitGridData(isSubDetails);
          this.operationInProgress = "";
          //Issue no 101 start
          // enableBulkWidgets(
          //   this.props.refObject,
          //   this.screenConf,
          //   this.conf.name
          // );
          if (this.conf.disableArray) {
            this.conf.disableArray.map((widgetID) =>
              enable(this.props.refObject, widgetID)
            );
          }
          //Issue no 101 end
        },
      },
      {
        icon: "times",
        clickEvent: () => {
          this.arrayHelpers.remove(0);
          this.setState({ okCancelButtons: [] });
          this.operationInProgress = "";
          //Issue no 101 start
          // enableBulkWidgets(
          //   this.props.refObject,
          //   this.screenConf,
          //   this.conf.name
          // );
          if (this.conf.disableArray) {
            this.conf.disableArray.map((widgetID) =>
              enable(this.props.refObject, widgetID)
            );
          }
          //Issue no 101 end
        },
      },
    ],
    editActionOkCancel: [
      {
        icon: "check",
        clickEvent: (isSubDetails) => {
          if (this.checkErrors()) {
            return;
          }
          if (this.conf.disableArray) {
            this.conf.disableArray.map((widgetID) =>
              enable(this.props.refObject, widgetID)
            );
          }
          let selectedRows = [];
          this.selected.forEach((selectedRow) => {
            let row = this.rows[selectedRow.rowID$];
            row.editing = false;
            this.arrayHelpers.replace(row.rowID$, { ...row });

            let matchedIndex = this.rowMatchedInGridData(row);
            if (matchedIndex !== -1) {
              this.gridData[matchedIndex] = row;
            } else {
              if (!row[this.statusColName]) {
                row[this.statusColName] = "U";
              }
              this.gridData.push(row);
            }
            selectedRows.push(row);
          });
          this.selected = selectedRows;
          this.setState({ okCancelButtons: [] });
          this.emitGridData(isSubDetails);
          this.operationInProgress = "";
        },
      },
      {
        icon: "times",
        clickEvent: () => {
          this.selected.forEach((selectedRow) => {
            let row = selectedRow;
            row.editing = false;
            this.arrayHelpers.replace(row.rowID$, { ...row });
          });
          if (this.conf.disableArray) {
            this.conf.disableArray.map((widgetID) =>
              enable(this.props.refObject, widgetID)
            );
          }
          this.setState({ okCancelButtons: [] });
          this.operationInProgress = "";
        },
      },
    ],
  };
  okCancelButtons = [];

  // onSelect = ({ selectedRows }) => {

  //   this.selected = this.conf.selected = selectedRows;
  //   if (!this.conf.isMultiSelect) {
  //     selectedRows.forEach((selRow) => {
  //       let prevActive = document.getElementsByClassName("activeRow");
  //       if (prevActive.length > 0) {
  //         prevActive[0].classList.remove("activeRow");
  //       }
  //       let row = document.getElementById("row-" + selRow.rowID$);
  //       row.classList.add("activeRow");
  //       //   let name = `${this.conf.name}.${row.rowID$}.isSelected`;
  //       //       let event = {
  //       //         type: "change",
  //       //         target: {
  //       //           id: this.conf.name,
  //       //           name,
  //       //           value: true,
  //       //         },
  //       //       };
  //       //       this.props.onEvent(event);
  //     })
  //   }
  //   // else{
  //   //   this.rows.forEach((row) => {
  //   //     let isSelected = selectedRows.some(
  //   //       (selectedRow) => row.rowID$ === selectedRow.rowID$
  //   //     );

  //   //     let name = `${this.conf.name}.${row.rowID$}.isSelected`;
  //   //     let event = {
  //   //       type: "change",
  //   //       target: {
  //   //         id: this.conf.name,
  //   //         name,
  //   //         value: isSelected,
  //   //       },
  //   //     };
  //   //     this.props.onEvent(event);
  //   //   });
  //   // }


  //   let event = {
  //     type: "RowSelect",
  //     target: {
  //       id: this.conf.name,
  //     },
  //   };
  //   this.props.onEvent(event);
  //   this.props.conf.selected = selectedRows;
  // };

  onSelect = ({ selectedRows }) => {
    this.selected = this.conf.selected = selectedRows;
    let gridName = this.props.conf.name;
    if (!this.conf.isMultiSelect) {
      selectedRows.forEach((selRow) => {
        this.rows[selRow.rowID$].isSelected = true;
        let prevActive = document.getElementsByClassName("activeRow");
        if (prevActive.length > 0) {
          // prevActive[0].classList.remove("activeRow");
          for (let div of prevActive) {
            if (div.closest('.GridWidget').classList[0] === gridName) {
              div.classList.remove("activeRow")
            }
          }
        }
        let row = $("div." + gridName + " div#row-" + selRow.rowID$);
        $(row).addClass("activeRow")
      })
    }
    else {
      this.rows.forEach((row) => {
        let isSelected = selectedRows.some(
          (selectedRow) => row.rowID$ === selectedRow.rowID$
        );
        let name = `${this.conf.name}.${row.rowID$}.isSelected`;
        this.props.refObject.values[this.conf.name][row.rowID$].isSelected = isSelected;
      });
    }
    let event = {
      type: "RowSelect",
      target: {
        id: this.conf.name,
      },
      selectedRows
    };
    this.props.onEvent(event);
    this.props.conf.selected = selectedRows;
  };
  //   onSelect = ({ selectedRows }) => {
  //     this.selected = this.conf.selected = selectedRows;
  //     let mulSel = false;
  //     let unselRows = [];
  //     let setFld = null;

  //     if(this&& this.conf&& this.conf.isMultiSelect){
  //       //will stop impact on multiselect gris
  //       mulSel = this.conf.isMultiSelect;
  //     }	else {
  //       setFld = this.props.setFieldValue;
  //     }		

  //     if(!this.prevSelected) this.prevSelected =-1;

  //     this.rows.forEach(row => {

  //       let isSelected = selectedRows.some(
  //         selectedRow => row.rowID$ === selectedRow.rowID$
  //       );

  //       let name = `${this.conf.name}.${row.rowID$}.isSelected`;
  //       let unselectable = false;
  //       if(this.state.prevSelected && this.state.prevSelected === name){
  //         unselectable=true;
  //       }

  //       if(unselectable&& (!isSelected)&&(!mulSel)){
  //         if(selectedRows.length === 0){
  //           let l_name = this.state.prevSelected;
  //           unselRows.push(l_name);
  //         }
  //         else{
  //           if(this.selected && this.selected.length === 1){
  //             let l_name = this.state.prevSelected;
  //             unselRows.push(l_name);
  //           }
  //         }
  //       }

  //       if(setFld&&isSelected&&(!mulSel)&&this.selected && this.selected.length === 1){  
  //         this.setState(current => {  
  //           return {
  //             ...current,
  //             prevSelected: name,
  //           };
  //         });
  //         setFld(name,isSelected);
  //       }

  //     });
  //     if(mulSel){
  //     let event = {
  //       type: "RowSelect",
  //       target: {
  //         id: this.conf.name
  //       }
  //     };
  //     this.onEvent(event);
  //   }	
  //     this.conf.selected = selectedRows;	
  //     this.rowUnsel(unselRows);	  
  //   };


  //  rowUnsel(unselRows) {
  //   if(unselRows && this.props.setFieldValue){
  //     var i = 0, len = unselRows.length;
  //   while (i < len) {
  //     this.props.setFieldValue(unselRows[i],false);
  //       i++
  //   }
  //   }
  // }
  rowMatchedInGridData(nonUpdatedRow) {
    let matchedIndex = -1;
    this.gridData.forEach((data, index) => {
      if (data.rowID$ === nonUpdatedRow.rowID$) {
        matchedIndex = index;
        return;
      }
    });
    return matchedIndex;
  }

  isOperationInProgress() {
    if (this.operationInProgress !== "") {
      toast.error(`${this.operationInProgress} operation is in progress`);
      return false;
    }
    return true;
  }

  prepareGridConfData = () => {
    let gridObj = this.conf;
    let screenObj = this.screenConf;
    let gridCells = [];
    let columns = [];
    let gridHeaders = [];
    if ("gridCellsOrder" in gridObj) {
      let gridCellsString = gridObj["gridCellsOrder"];
      if (gridCellsString.indexOf(",") !== -1) {
        let gridCellsArr = gridCellsString.split(",");
        gridCellsArr.forEach((gridCellName, index) => {
          let gridCell = screenObj[gridCellName];
          let gridHeader = screenObj[gridCell["colName"]];
          gridCell.sortable = gridHeader.sortable;
          gridCell.isStatusColumn = gridHeader.isStatusColumn;
          gridCell.Visible = gridHeader.Visible;
          gridCell.wrap = true;

          switch (gridCell.type) {
            case "DateWidget":
              gridCell.format =
                gridCell.format || _kaledo["format"]["dateFormat"];
              gridCell.minWidth = "150px";
              break;
            case "DateTimeWidget":
              gridCell.format =
                gridCell.format || _kaledo["format"]["dateTimeFormat"];
              gridCell.minWidth = "150px";
              break;
            case "TimeWidget":
              gridCell.format =
                gridCell.format || _kaledo["format"]["timeFormat"];
              gridCell.minWidth = "150px";
              break;
            case "DropDownFieldWidget":
              gridCell.valueList = convertStringToJsonObject(gridCell.Options);
              break;
            case "FileWidget":
              gridCell.minWidth = "250px";
              // gridCell.wrap = true;
              break;
            case "TextAreaWidget":
              gridCell.minWidth = "200px";
              // gridCell.wrap = true;
              break;
            default:
              gridCell.format = "";
          }
          gridCells.push(gridCell);
          gridHeaders.push(gridHeader);
          let format = this.isDispFormatReqd()
            ? this.getDispFormat(gridCell)
            : "";
          let column = {
            name: gridCell.Label + "\n" + format,
            id: gridCell.name + "_ID",
            selector: gridCell.name,
            minWidth: gridCell.minWidth,
            wrap: gridCell.wrap,
            cell: (row) => {
              return this.getControl(row, gridCell);
            },
            sortable: gridCell.sortable,
            omit: !gridCell.Visible,
          };
          columns.push(column);
        });
      }
    }

    if (this.conf.isEditable && _kaledo.isCobolTable) {
      let column = {
        // name: gridCell.Label,
        selector: "opColumn",
        minWidth: "75px",
        cell: (row) => {
          const name = `${this.conf.name}.${row.rowID$}.opColumn`;
          if (row.isNewRow) {
            return <div></div>;
          }
          return (
            <div>
              <FastField
                name={name}
                data-testid={name}
                key={name}
                size="sm"
                type="text"
                maxLength="1"
                className={this.getTextBoxClasses()}
                onChange={(e) => {
                  this.props.onEvent(e);
                }}
                onBlur={(e) => {
                  this.props.onEvent(e);
                }}
                as={Form.Control}
                value={row.opColumn}
                autoComplete={"off"}
              />
            </div>
          );
        },
      };
      columns.unshift(column);
    }
    this.columns = columns;
    gridObj["columns"] = gridCells;
    gridObj["gridHeaders"] = gridHeaders;

    if (gridObj.Pagination || gridObj.clientPagination || gridObj.lazyLoading) {
      gridObj.page = {
        pageNumber: 1,
        size: +gridObj.DisplaySize,
      };
      gridObj.totalElements = 0;
    }

    gridObj.editable = gridObj.isEditable;
    gridObj.rows = [];
    gridObj.gridData = [];
    gridObj.selected = [];
  };

  getControl = (row, col) => {
    let value = row[col.name];
    let control = <div></div>;
    switch (col.type) {
      case "LinkWidget":
        control = (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <Button
              variant="link"
              onClick={(e) => {
                e.target.parentElement.click();
                e.target.id = col.name;
                this.linkClick(e);
              }}
              className="p-0"
              data-testid={col.name}
            >
              {value && value !== "" ? value : col.Label}
            </Button>
          </div>
        );
        break;
      case "ButtonWidget":
        control = (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <Button
              variant="primary"
              size="sm"
              onClick={(e) => {
                e.target.parentElement.click();
                e.target.id = col.name;
                this.linkClick(e);
              }}
              data-testid={col.name}
            >
              {col.iconName ? <i className={`fa ${col.iconName}`} /> : null}
              {value && value !== "" ? value : col.Label}
            </Button>
          </div>
        );
        break;
      case "CheckBoxWidget":
        let checkboxValue = typeof value === "undefined" ? "false" : value;
        checkboxValue = checkboxValue === "false" ? false : Boolean(value);
        if (
          !this.editable ||
          (this.editable && (!row.editing || col.ReadOnly || !col.Enabled) && !col.isMultiSelectAlternate))
          return (
            <div
              className="w-100 text-center"
              title={this.getTooltip(col)}
              role="tooltip"
            >
              <Form.Check
                type="checkbox"
                readOnly
                name={`${this.conf.name}.${row.rowID$}.${col.name}`}
                checked={checkboxValue}
              ></Form.Check>
            </div>
          );
        else return this.getEditableControl(row, col, value);
      case "LabelWidget":
        control = (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
            data-testid={col.name}
            className="valRowSel"
          >
            {value && value !== "" ? value : col.Label}
          </div>
        );
        break;
      default:
        if (
          !this.editable ||
          (this.editable && (!row.editing || col.ReadOnly || !col.Enabled))
        ) {
          control = (
            <div
              title={this.getTooltip(col)}
              role="tooltip"
              className="valRowSel"
            >
              {this.getValueTemplate(row, col, value)}
            </div>
          );
        } else {
          control = this.getEditableControl(row, col, value);
        }
    }
    return control;
  };

  getValueTemplate = (row, col, value) => {
    switch (col.type) {
      case "DateWidget":
      case "DateTimeWidget":
      case "TimeWidget":
        if (window._kaledo.isCobolTable) {
          value =
            !value || value === "" ? "" : moment(value).format(col.format);
        } else {
          value = value === "" ? "" : moment(value).format(col.format);
        }
        return value;
      case "FileWidget":
        value = value || [];
        return (
          <FileControl
            conf={this.conf}
            row={row}
            col={col}
            value={value}
            onEvent={this.props.onEvent}
            isValueTemplate={true}
          />
        );
      case "DropDownFieldWidgetASD":
        return value;
      case "DropDownFieldWidget":
        let title = col.SpecialFirstOption;
        let selVal = col.valueList.filter((valueObj) => valueObj.key === value);
        if (selVal.length) {
          title = selVal[0].description;

          return (
            <div
              title={this.getTooltip(col)}
              role="tooltip"
            >
              <DropdownButton
                size="sm"
                id="dropdown-basic-button"
                title={title}
                name={`${this.conf.name}.${row.rowID$}.${col.name}`}
              >
                {col.valueList.map((dd) => (
                  <Dropdown.Item
                    eventKey={dd.key}
                    onSelect={(eventKey, event) => {
                      event.target.value = eventKey;
                      event.type = "change";
                      event.target.name = `${this.conf.name}.${row.rowID$}.${col.name}`;
                      this.props.onEvent(event);
                    }}
                    key={"option" + dd.key}
                  >
                    {dd.description}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          );
        }
        else {
          return value;
        }
      default:
        return value;
    }
  };

  getEditableControl = (row, col, value) => {
    const name = `${this.conf.name}.${row.rowID$}.${col.name}`;
    let error = getIn(this.props.errors, name);
    let touched = getIn(this.props.touched, name);
    switch (col.type) {
      case "TextBoxWidget":
      case "PasswordBoxWidget":

        return (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <FastField
              name={name}
              id={name}
              placeholder={this.getPlaceholder()}
              key={name}
              size="sm"
              autoComplete={col.AutoCompleteForTextBox}
              disabled={!this.conf.Enabled}
              readOnly={this.conf.ReadOnly}
              type={col.type === "PasswordBoxWidget" ? "password" : "text"}
              maxLength={this.getMaxlength(col)}
              className={this.getTextBoxClasses(col) + ' ' + `${col.name}`}
              value={value || ""}
              onChange={(e) => {
                if (this.conf.isEditable && _kaledo.isCobolTable) {
                  let updatedRows = this.conf.updatedRows;
                  if (!updatedRows.includes(row.rowID$)) {
                    updatedRows.push(row.rowID$);
                  }
                  this.props.onEvent(e);
                } else {
                  this.props.onEvent(e);
                }
              }}
              onBlur={(e) => {
                if (this.conf.isEditable && _kaledo.isCobolTable) {
                  let updatedRows = this.conf.updatedRows;
                  if (!updatedRows.includes(row.rowID$)) {
                    updatedRows.push(row.rowID$);
                  }
                  this.props.onEvent(e);
                } else {
                  this.props.onEvent(e);
                }
              }}
              onPaste={(e) => {
                if(col.restrictPaste)  
                  e.preventDefault();
                this.props.onEvent(e);
              }}
              onKeyDown={(e) => {
                if (this.conf.isEditable && _kaledo.isCobolTable) {
                  if (e.key === "End") {
                    e.preventDefault();
                    let newrow = this.rows[row.rowID$];
                    newrow[e.target.name.split(".").pop()] = "";
                    this.arrayHelpers.replace(row.rowID$, { ...newrow });
                  }
                }
              }}
              isInvalid={touched && error}
              title={error}
              as={Form.Control}
              data-testid={col.name}
            />
          </div>
        );


      case "DropDownFieldWidget":
        let title = col.SpecialFirstOption;
        let _colValList = col.valueList;
        let _timeOut =null;
        if (
          col.valRowNo !== undefined &&
          col.valRowNo + "" &&
          col.valRowNo + "" === row.rowID$ + "" &&
          col.colValList
        ) {
          _colValList = col.colValList;
        }
        if (col.callBack && col.childElement && value&&!this.timeFlag) {
          col.callBack(row, col, value);
          this.timeFlag = true;
           setTimeout(() => {
            
            if (this.props.refObject) {
              this.props.refObject.setState((current) => {
                return { ...current, childElement: { ID: row.rowID$ } };
              });
            } else {
            }
            _timeOut = null;
          }, 4000);
        }
        let selVal = _colValList.filter((valueObj) => valueObj.key === value);
        if (selVal.length) {
          title = selVal[0].description;
        } else {
          if (
            col.refColName &&
            row[col.refColName] !== null &&
            row[col.refColName] === ""
          ) {
            _colValList = col.valueList; //DEFAULT
          }
        }
        return (
          <div title={this.getTooltip(col)} role="tooltip">
            <DropdownButton
              size="sm"
              id="dropdown-basic-button"
              title={title}
              name={`${this.conf.name}.${row.rowID$}.${col.name}`}
            >
              {_colValList.map((dd) => (
                <Dropdown.Item
                  eventKey={dd.key}
                  onSelect={(eventKey, event) => {
                    event.target.value = eventKey;
                    event.type = "change";
                    event.target.name = `${this.conf.name}.${row.rowID$}.${col.name}`;
                    if (col.callBack) {
                      this.timeFlag=true;
                      let handleCallback = new Promise(function (call, error) {
                        let find = col.callBack(row, col, eventKey);
                        if (find) {
                          call();
                        }
                      });
                      handleCallback.then(
                        (value) => {
                          this.timeFlag=false;
                          _timeOut = setTimeout(() => {
                            this.props.onEvent(event);
                            _timeOut = null;
                           // this.timeFlag=false;
                          }, 4000)
                          
                        },
                        (error) => {
                        }
                      );
                    }
                    this.props.onEvent(event);
                  }}
                  key={"option" + dd.key}
                >
                  {dd.description}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        );
      // case "DropDownFieldWidget":
      //   let title = col.SpecialFirstOption;
      //   let selVal = col.valueList.filter((valueObj) => valueObj.key === value);
      //   if (selVal.length) {
      //     title = selVal[0].description;
      //   }
      //   return (
      //     <div
      //       title={this.getTooltip(col)}
      //       role="tooltip"
      //     >
      //       <DropdownButton
      //         size="sm"
      //         id="dropdown-basic-button"
      //         title={title}
      //         name={`${this.conf.name}.${row.rowID$}.${col.name}`}
      //       >
      //         {col.valueList.map((dd) => (
      //           <Dropdown.Item
      //             eventKey={dd.key}
      //             onSelect={(eventKey, event) => {
      //               event.target.value = eventKey;
      //               event.type = "change";
      //               event.target.id = col.name
      //               event.target.name = `${this.conf.name}.${row.rowID$}.${col.name}`;
      //               this.props.onEvent(event);
      //             }}
      //             key={"option" + dd.key}
      //           >
      //             {dd.description}
      //           </Dropdown.Item>
      //         ))}
      //       </DropdownButton>
      //     </div>
      //   );
      case "CheckBoxWidget":
        let checkboxValue = typeof value === "undefined" ? "false" : value;
        checkboxValue = checkboxValue === "false" ? false : Boolean(value);
        return (
          <div
            className="w-100 text-center"
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <Form.Check
              type="checkbox"
              name={`${this.conf.name}.${row.rowID$}.${col.name}`}
              checked={checkboxValue}
              onChange={(e) => {
                let event = {
                  type: "change",
                  target: {
                    id: col.name,
                    name: e.target.name,
                    value: !value,
                  },
                };
                this.props.onEvent(event);
              }}
            ></Form.Check>
          </div>
        );
      case "DateWidget":
      case "DateTimeWidget":
      case "TimeWidget":
        let formatString = col.format;
        if (col.is24HourClock || col.isTime24HourClock) {
          formatString = formatString.replace(" a", "");
          formatString = formatString.replace("hh", "HH");
        }
        if (!col.secondTimeRequired) {
          formatString = formatString.replace(":ss", "");
        }
        col.format = formatString;
        let pickerOptions = {
          minDate: col.dateOptions && col.dateOptions.minDate,
          maxDate: col.dateOptions && col.dateOptions.maxDate,
          format: col.format,
        };
        if (window._kaledo.isCobolTable) {
          value = !value || value === "" ? "" : value;
        } else {
          value = !value || value === "" ? '' : value;
        }
        return (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <DateTime
              value={value}
              id={col.name}
              rowID={row.rowID$}
              name={`${this.conf.name}.${row.rowID$}.${col.name}`}
              className="input-group date"
              pickerOptions={pickerOptions}
              disabled={!col.Enabled}
              onChange={(e) => {
                this.props.onEvent(e);
              }}
              data-testid={col.name}
            />
          </div>
        );
      case "CheckBoxWidget":
        return (
          <div
            className="w-100 text-center"
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <Form.Check
              type="checkbox"
              name={`${this.conf.name}.${row.rowID$}.${col.name}`}
              checked={typeof value === "undefined" ? false : value}
              onChange={(e) => {
                let event = {
                  type: "change",
                  target: {
                    id: col.name,
                    name: e.target.name,
                    value: !value,
                  },
                };
                this.props.onEvent(event);
              }}
            ></Form.Check>
          </div>
        );
      case "FileWidget":
        value = value || [];
        return (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <FileControl
              conf={this.conf}
              row={row}
              col={col}
              value={value}
              isInvalid={error}
              title={error}
              onEvent={this.props.onEvent}
              isValueTemplate={false}
            />
          </div>
        );
      case "TextAreaWidget":
        return (
          <div
            className="py-2"
            title={this.getTooltip(col)}
            role="tooltip"
          >
            <Form.Control
              name={`${this.conf.name}.${row.rowID$}.${col.name}`}
              placeholder={this.getPlaceholder()}
              size="sm"
              disabled={!this.conf.Enabled}
              readOnly={this.conf.ReadOnly}
              className={this.getTextBoxClasses()}
              value={value}
              isInvalid={error && touched}
              title={error}
              onChange={this.props.onEvent}
              as="textarea"
            />
          </div>
        );
      default:
        return (
          <div
            title={this.getTooltip(col)}
            role="tooltip"
            data-testid={col.name}
          >
            value
          </div>
        );
    }
  };

  setRowID = () => {
    let rows = this.props.rows;
    rows = rows.map((row, index) => {
      row = { ...row };
      row.rowID$ = index;
      if (this.conf.isEditable && this.conf.isCobolTable) {
        row.editing = true;
      }
      return row;
    });
    this.rows = rows;
  };

  emitGridData(isSubDetails) {
    let gridData = JSON.parse(JSON.stringify(this.gridData));
    if (!isSubDetails) {
      gridData = gridData.map((data) => {
        delete data.rowID$;
        delete data.editing;
        delete data.isSelected;
        if (data.subDetails) {
          let subDetails = data.subDetails.rows;
          subDetails = subDetails.filter((subDetail) => {
            if (subDetail[this.statusColName]) {
              return true;
            } else return false;
          });
          subDetails.forEach((subDetail) => {
            delete subDetail.rowID$;
            delete subDetail.editing;
            delete data.isSelected;
          });
          data.subDetails.rows = subDetails;
        }
        return data;
      });
    }
    this.conf.gridData = gridData;
  }

  checkErrors() {
    let errors = this.props.errors;
    if (Object.keys(errors).length) {
      let keys = Object.keys(errors);
      let gridName = this.conf.name;
      if (keys.indexOf(gridName) !== -1) {
        let fieldName = Object.keys(this.props.errors[gridName][0]);
        let filteredColumns = this.props.conf.columns.filter((col) => {
          return fieldName.includes(col.name)
        })
        filteredColumns.map((col) => {
          toast.error(`Mandatory field ${col.Label} in not filled !`)
        })
        return true;
      }
    }
    return false;
  }

  edit = () => {
    this.selected.forEach((selectedRow) => {
      let row = this.rows[selectedRow.rowID$];
      row.editing = true;
      this.arrayHelpers.replace(row.rowID$, { ...row });
    });
   
    this.setState({
      okCancelButtons: this.okCancel.editActionOkCancel,
    });
    this.timeFlag=false;
  };

  checkSelected = (row) => row.isSelected;

  handleRowClicked = (row, e) => {
    if (!this.conf.isMultiSelect) {
      this.onSelect({ selectedRows: [row] });
    }
  };

  deleteRow(isSubDetails, callback = () => { }) {
    this.selected.forEach((selectedRow) => {
      this.arrayHelpers.remove(selectedRow.rowID$);
      let matchedIndex = this.rowMatchedInGridData(selectedRow);
      if (matchedIndex !== -1) {
        if (this.gridData[matchedIndex][this.statusColName] === "C") {
          if (isSubDetails) {
            this.gridData[matchedIndex][this.statusColName] = "D";
          } else {
            this.gridData.splice(matchedIndex, 1);
          }
        } else if (
          !this.gridData[matchedIndex][this.statusColName] ||
          this.gridData[matchedIndex][this.statusColName] === "U"
        ) {
          this.gridData[matchedIndex][this.statusColName] = "D";
        }
      } else {
        selectedRow[this.statusColName] = "D";
        this.gridData.push(selectedRow);
      }
    });
    this.selected.length = 0;
    this.emitGridData(isSubDetails);
    setTimeout(() => {
      callback()
    }, 0);
  }

  duplicateRows(isSubDetails) {
    this.selected.forEach((selectedRow) => {
      let cloneSelectedRow = JSON.parse(JSON.stringify(selectedRow));
      cloneSelectedRow[this.statusColName] = "C";
      this.arrayHelpers.insert(cloneSelectedRow.rowID$, cloneSelectedRow);
      if (cloneSelectedRow.subDetails) {
        let subDetailsRows = cloneSelectedRow.subDetails.rows;
        subDetailsRows.forEach((subDetail) => {
          subDetail[this.statusColName] = "C";
        });
      }
      this.gridData.push(cloneSelectedRow);
      this.emitGridData(isSubDetails);
    });
  }

  setColVisibility = (col) => {
    col.Visible = !col.Visible;
    this.setState({ isColumnsUpdated: true });
  };
  attachHotKeys = () => {
    if (this.conf.isEditable && _kaledo.isCobolTable) {
      hotkeys.unbind("f8");
      hotkeys("f8", (e) => {
        /*if(this.conf.page.pageNumber === this.rows.length/this.conf.page.size){
        for (let i = 0; i < this.conf.DisplaySize; i++) {
          let newRow = JSON.parse(JSON.stringify(this.defaultNewRow));
          newRow.isNewRow = true;
          delete newRow[this.statusColName];
          this.arrayHelpers.push(newRow);
        }
        //this.conf.totalElements += parseInt(this.conf.DisplaySize);
        setTimeout(() => {
          //document.getElementById("pagination-last-page").click();
        });
  } else {
      e.preventDefault();
        //document.getElementById("lnkFwd").click();
    }*/
        e.preventDefault();
        //To-Do: ABS specific
        if (document.getElementById("lnkFwd") !== null) {
          document.getElementById("lnkFwd").click();
        }
      });

      hotkeys.unbind("f7");
      hotkeys("f7", (e) => {
        e.preventDefault();
        //To-Do: ABS specific
        if (document.getElementById("lnkBkwd") !== null) {
          document.getElementById("lnkBkwd").click();
        }
      });

      /*hotkeys.unbind("f8");
      hotkeys("f8", e => {
        e.preventDefault();
        document.getElementById("pagination-next-page").click();
      });*/
    }
  };

  render() {
    return <div></div>;
  }
}

export default ParentGridWidget;
