/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  getValue,
  disable,
  enable,
  hide,
  show,
  setData,
  getData,
  goTo,
  setFieldValues,
  setLabel,
  hideColumn,
  showColumn,
  readonlyGridColumn,
  readonly,
  isEnabled,
} from "../../shared/WindowImports";

import "./SecurityUser.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import moment from "moment";

import { toPascalCase } from "../../Common/Constants";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityUser(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityUser",
    windowName: "SecurityUser",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityUser",
    // START_USER_CODE-USER_SecurityUser_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "User Security Profile",
      scrCode: "PN0060C",
    },
    // END_USER_CODE-USER_SecurityUser_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnAddFunction: {
      name: "btnAddFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddFunction_PROPERTIES

      // END_USER_CODE-USER_btnAddFunction_PROPERTIES
    },
    btnAddLocation: {
      name: "btnAddLocation",
      type: "ButtonWidget",
      parent: "grpbxLocations",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddLocation_PROPERTIES

      // END_USER_CODE-USER_btnAddLocation_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDeleteFunction: {
      name: "btnDeleteFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteFunction_PROPERTIES

      // END_USER_CODE-USER_btnDeleteFunction_PROPERTIES
    },
    btnDeleteLocation: {
      name: "btnDeleteLocation",
      type: "ButtonWidget",
      parent: "grpbxLocations",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteLocation_PROPERTIES

      // END_USER_CODE-USER_btnDeleteLocation_PROPERTIES
    },
    btnEditFunction: {
      name: "btnEditFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditFunction_PROPERTIES

      // END_USER_CODE-USER_btnEditFunction_PROPERTIES
    },
    btnEditLocation: {
      name: "btnEditLocation",
      type: "ButtonWidget",
      parent: "grpbxLocations",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditLocation_PROPERTIES

      // END_USER_CODE-USER_btnEditLocation_PROPERTIES
    },
    btnImportSecurity: {
      name: "btnImportSecurity",
      type: "ButtonWidget",
      parent: "grpbxImportFunctionsLocations",
      Label: "Import Security",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnImportSecurity_PROPERTIES

      // END_USER_CODE-USER_btnImportSecurity_PROPERTIES
    },
    ddAllFunctions: {
      name: "ddAllFunctions",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "All Functions:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAllFunctions_PROPERTIES

      // END_USER_CODE-USER_ddAllFunctions_PROPERTIES
    },
    ddAllLocations: {
      name: "ddAllLocations",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "All Locations:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAllLocations_PROPERTIES

      // END_USER_CODE-USER_ddAllLocations_PROPERTIES
    },
    ddAuthorityLevel: {
      name: "ddAuthorityLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Authority Level:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAuthorityLevel_PROPERTIES

      // END_USER_CODE-USER_ddAuthorityLevel_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddFromUserID: {
      name: "ddFromUserID",
      type: "DropDownFieldWidget",
      parent: "grpbxImportFunctionsLocations",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      optionMaxLength: 55,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFromUserID_PROPERTIES

      // END_USER_CODE-USER_ddFromUserID_PROPERTIES
    },
    ddSecurityGroup: {
      name: "ddSecurityGroup",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Security Group:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSecurityGroup_PROPERTIES

      // END_USER_CODE-USER_ddSecurityGroup_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityUser",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityUser",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxFunctions: {
      name: "grpbxFunctions",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityUser",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFunctions_PROPERTIES

      // END_USER_CODE-USER_grpbxFunctions_PROPERTIES
    },
    grpbxImportFunctionsLocations: {
      name: "grpbxImportFunctionsLocations",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityUser",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxImportFunctionsLocations_PROPERTIES

      // END_USER_CODE-USER_grpbxImportFunctionsLocations_PROPERTIES
    },
    grpbxLocations: {
      name: "grpbxLocations",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityUser",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxLocations_PROPERTIES

      // END_USER_CODE-USER_grpbxLocations_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblFromUserID: {
      name: "lblFromUserID",
      type: "LabelWidget",
      parent: "grpbxImportFunctionsLocations",
      Label: "From User ID:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFromUserID_PROPERTIES

      // END_USER_CODE-USER_lblFromUserID_PROPERTIES
    },
    lblFunctions: {
      name: "lblFunctions",
      type: "LabelWidget",
      parent: "grpbxFunctions",
      Label: "Functions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFunctions_PROPERTIES

      // END_USER_CODE-USER_lblFunctions_PROPERTIES
    },
    lblImportFunctionsLocations: {
      name: "lblImportFunctionsLocations",
      type: "LabelWidget",
      parent: "grpbxImportFunctionsLocations",
      Label: "Import Functions/Locations",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblImportFunctionsLocations_PROPERTIES

      // END_USER_CODE-USER_lblImportFunctionsLocations_PROPERTIES
    },
    lblLocations: {
      name: "lblLocations",
      type: "LabelWidget",
      parent: "grpbxLocations",
      Label: "Locations:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocations_PROPERTIES

      // END_USER_CODE-USER_lblLocations_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "grpbxImportFunctionsLocations",
      Label: "Import is only enabled after creating the new user.",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote_PROPERTIES

      // END_USER_CODE-USER_lblNote_PROPERTIES
    },
    lstFunctions: {
      name: "lstFunctions",
      type: "ListBoxFieldWidget",
      parent: "grpbxFunctions",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFunctions_PROPERTIES

      // END_USER_CODE-USER_lstFunctions_PROPERTIES
    },
    lstLocations: {
      name: "lstLocations",
      type: "ListBoxFieldWidget",
      parent: "grpbxLocations",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLocations_PROPERTIES

      // END_USER_CODE-USER_lstLocations_PROPERTIES
    },
    txtIBMUserID: {
      name: "txtIBMUserID",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "IBM User ID:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIBMUserID_PROPERTIES

      // END_USER_CODE-USER_txtIBMUserID_PROPERTIES
    },
    txtUserEmail: {
      name: "txtUserEmail",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "User Email:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 98 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUserEmail_PROPERTIES

      // END_USER_CODE-USER_txtUserEmail_PROPERTIES
    },
    txtUserID: {
      name: "txtUserID",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "User ID:",
      LengthRange: { MinLength: 0, MaxLength: 32 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUserID_PROPERTIES

      // END_USER_CODE-USER_txtUserID_PROPERTIES
    },
    txtUserName: {
      name: "txtUserName",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "User Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUserName_PROPERTIES

      // END_USER_CODE-USER_txtUserName_PROPERTIES
    },
    txtUserPhone: {
      name: "txtUserPhone",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "User Phone:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUserPhone_PROPERTIES

      // END_USER_CODE-USER_txtUserPhone_PROPERTIES
    },
    grpbxSecurityUser: {
      name: "grpbxSecurityUser",
      type: "GroupBoxWidget",
      parent: "SecurityUser",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSecurityUser_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityUser_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#SecurityUserFunctionSelect": {},
        "SystemMaintenanceSpecialFunctions#SecurityUserLocationSelect": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#SecurityUserLocationSelect": {},
        "SystemMaintenanceSpecialFunctions#SecurityUserFunctionSelect": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj;
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  //closing of function page
  useEffect(() => {
    let value = getData(thisObj, "closingOfSecurityUserFunctionSelect");
    if (value === true) {
      let data = getData(thisObj, "dataForSecurityUser");
      let obj = {};
      if (data !== null) {
        let valueList = thisObj.state.lstFunctions.valueList;

        if (valueList[0].key == undefined && valueList[0].description == "") {
          valueList = [];
        }

        if (data.condition == "Add") {
          obj = { key: data.key, description: data.description };
          if (valueList !== undefined && valueList.length > 0) {
            valueList.push(obj);
          } else {
            valueList = [obj];
          }
        } else if (data.condition == "Update") {
          for (let i = 0; i < valueList.length; i++) {
            if (valueList[i].key === data.key) {
              valueList[i].description = data.description;
              break; // Exit the loop once the object is updated
            }
          }
        }

        thisObj.setState(current => {
          return {
            ...current,
            lstFunctions: {
              ...state["lstFunctions"],
              valueList: valueList
            }
          }
        })

        if (data.condition == "Add") {
          setValue(_kaledo.thisObj, 'lstFunctions', [valueList.at(valueList.length - 1).key]);

          setTimeout(() => {
            document.getElementById("lstFunctions").scrollTop = document.getElementById("lstFunctions").scrollHeight;
          }, 500);
        }

        EnableDisableControls(true, valueList, thisObj.state.lstLocations.valueList);

        setData(thisObj, "dataForSecurityUser", null);
      }
      setData(thisObj, "closingOfSecurityUserFunctionSelect", false);
    }
  }, [getData(thisObj, "closingOfSecurityUserFunctionSelect")])


  //closing of location page
  useEffect(() => {
    let value = getData(thisObj, "closingOfSecurityUserLocationSelect");
    if (value === true) {
      let data = getData(thisObj, "dataForSecurityUser");
      let obj = {};
      if (data !== null) {
        let valueList = thisObj.state.lstLocations.valueList;

        if (valueList[0].key == undefined && valueList[0].description == "") {
          valueList = [];
        }

        if (data.condition == "Add") {
          obj = { key: data.lstLocationIds, description: data.lstLocationCaption };
          if (valueList !== undefined && valueList.length > 0) {
            valueList.push(obj);
          } else {
            valueList = [obj];
          }
        } else if (data.condition == "Update") {
          for (let i = 0; i < valueList.length; i++) {
            if (valueList[i].key === data.lstLocationIds) {
              valueList[i].description = data.lstLocationCaption;
              break; // Exit the loop once the object is updated
            }
          }
        }

        thisObj.setState(current => {
          return {
            ...current,
            lstLocations: {
              ...state["lstLocations"],
              valueList: valueList
            }
          }
        })

        if (data.condition == "Add") {
          setValue(_kaledo.thisObj, 'lstLocations', [valueList.at(valueList.length - 1).key]);

          setTimeout(() => {
            document.getElementById("lstLocations").scrollTop = document.getElementById("lstLocations").scrollHeight
          }, 200);
        }

        EnableDisableControls(true, thisObj.state.lstFunctions.valueList, valueList)


        setData(thisObj, "dataForSecurityUser", null);
      }
      setData(thisObj, "closingOfSecurityUserLocationSelect", false);
    }
  }, [getData(thisObj, "closingOfSecurityUserLocationSelect")])

  // START_USER_CODE-USER_METHODS

  const [mbFunctionsOrLocationsListed, setmbFunctionsOrLocationsListed] = useState(Boolean);

  async function formLoad() {
    try {
      setLoading(true);
      document.getElementById("txtUserPhone").focus() //needed so that page header not break
      setData(thisObj, "DatafromChildScreensToSecurityMenu", "Update");
      let dataFromParentPage = getData(thisObj, "dataSendingtoSecurityUser");

      let bFillAllFunctionsValue = bFillAllFunctions();
      let bFillAllLocationsValue = bFillAllLocations();
      let bFillAuthorityLevelValue = bFillAuthorityLevel();
      let bFillBuyingPointsValue = await bFillBuyingPoints();
      let bFillSecurityGroupValue = await bFillSecurityGroup();
      let bFillImportFromUsersValue = await bFillImportFromUsers();

      if (!(bFillAllFunctionsValue && bFillAllLocationsValue && bFillAuthorityLevelValue && bFillBuyingPointsValue && bFillSecurityGroupValue && bFillImportFromUsersValue)) {
        alert("error occured at form loading !!!, please try later");
        document.getElementById("SystemMaintenanceSpecialFunctions_SecurityUserPopUp").childNodes[0].click()
        setLoading(false);
        return false;
      }

      if (dataFromParentPage.editing) {
        bFillForm();
        let functionList = await bFillUserFunctions_Update()
        let locationList = await bFillUserLocations_Update()

        EnableDisableControls(true, functionList == false ? [] : functionList, locationList == false ? [] : locationList);
      } else {
        EnableDisableControls()
      }

      document.getElementById("txtIBMUserID").focus() //this 3 lines are needed , so that header of the page will not break due to late loading-unloading
      document.getElementById("txtUserPhone").focus()
      document.getElementById("txtUserPhone").blur()
      document.getElementById("txtUserID").focus()
      setLoading(false);
      return true;
    } catch (error) {
      errorHandler(error, "form Loading")
      return false
    }
  }

  function bFillAllFunctions() {
    try {
      let js = [];

      js.push({ key: "", description: "None" })
      js.push({ key: "I", description: "Inquiry" })
      js.push({ key: "U", description: "Update" })
      js.push({ key: "D", description: "Delete" })
      js.push({ key: "X", description: "Revoke" })

      thisObj.setState(current => {
        return {
          ...current,
          ddAllFunctions: {
            ...state['ddAllFunctions'],
            valueList: js
          }
        }
      })

      setValue(thisObj, "ddAllFunctions", js.at(0).key)
      return true;

    } catch (error) {
      errorHandler(error, "All functions Dropdown");
      return false;
    }
  }

  function bFillAllLocations() {
    try {
      let js = [];

      js.push({ key: "", description: "None" })
      js.push({ key: "I", description: "Inquiry" })
      js.push({ key: "U", description: "Update" })
      js.push({ key: "D", description: "Delete" })
      js.push({ key: "X", description: "Revoke" })

      thisObj.setState(current => {
        return {
          ...current,
          ddAllLocations: {
            ...state['ddAllLocations'],
            valueList: js
          }
        }
      })

      setValue(thisObj, "ddAllLocations", js.at(0).key)
      return true;

    } catch (error) {
      errorHandler(error, "All Locations Dropdown");
      return false;
    }
  }

  function bFillAuthorityLevel() {
    try {
      let js = [];

      js.push({ key: "", description: "None" })
      js.push({ key: "AR", description: "Area" })
      js.push({ key: "CP", description: "Collection Point" })
      js.push({ key: "BP", description: "Buying Point" })

      thisObj.setState(current => {
        return {
          ...current,
          ddAuthorityLevel: {
            ...state['ddAuthorityLevel'],
            valueList: js
          }
        }
      })

      setValue(thisObj, "ddAuthorityLevel", js.at(0).key)
      return true;

    } catch (error) {
      errorHandler(error, "Authority Level Dropdown");
      return false;
    }
  }

  async function bFillBuyingPoints() {
    try {
      let js = []

      let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0060', null, null, null, null)
      if (response !== undefined && response !== null && response.length !== 0) {
        for (var i = 0; i < response.length; i++) {
          js.push({
            key: response[i].buy_pt_id,
            description: response[i].buy_pt_id + ' - ' + toPascalCase(response[i].buy_pt_name)
          })
        }
      } else {
        showMessage(thisObj, "No buying points found")
        return false;
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
      return true;
    } catch (error) {
      errorHandler(error, "Buying Point Dropdown");
      return false;
    }
  }

  async function bFillSecurityGroup() {
    try {
      let js = []
      let compId = sessionStorage.getItem('compId');
      js.push({ key: "", description: "None" });
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupControlDetails(compId, null)
      if (response !== undefined && response !== null && response.length !== 0) {
        for (var i = 0; i < response.length; i++) {
          js.push({ key: response[i].securityGroup.toUpperCase(), description: response[i].securityGroup })
        }
      } else {
        showMessage(thisObj, "no security group found")
        return false;
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddSecurityGroup: {
            ...state["ddSecurityGroup"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSecurityGroup', js.at(0).key);
      return true;

    } catch (error) {
      errorHandler(error, "Security Group Dropdown");
      return false;
    }
  }

  async function bFillImportFromUsers() {
    try {
      let js = [];
      js.push({ key: '', description: ' ' })
      let response = await ContractManagementService.RetrieveUserControlDetails(null, null, null, null);
      if (response !== undefined && response !== null && response.length !== 0) {
        for (var i = 0; i < response.length; i++) {
          js.push({ key: response[i].userId, description: response[i].userId + " - " + response[i].userName })
        }
      } else {
        showMessage(thisObj, "No users found");
        return false;
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddFromUserID: {
            ...state["ddFromUserID"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddFromUserID', js.at(0).key);
      setData(thisObj, "userList", response) //try setting this in parent Page to reduce api calls and time
      return true;

    } catch (error) {
      errorHandler(error, 'Import Userid Dropdown');
      return false;
    }
  }

  async function bFillForm() {
    try {
      setLoading(true);
      document.getElementsByClassName('lblNote')[0].style.visibility = 'hidden';

      let dataFromParentPage = getData(thisObj, "dataSendingtoSecurityUser");

      let alldata = getData(thisObj, "userList");
      if (alldata !== undefined && alldata !== [] && alldata !== null && alldata.length !== 0) {
        let data = alldata.find(alldata => alldata.userId == dataFromParentPage.UserId);

        let values = {}; //for storing all formload values

        document.getElementById('btnAdd').innerText = "Update";
        values.txtUserID = data.userId;
        disable(thisObj, "txtUserID");
        values.txtIBMUserID = data.ibmUserId;
        values.txtUserName = data.userName;
        values.txtUserPhone = data.userPhone;
        values.txtUserEmail = data.userEmail;

        values.ddBuyingPoint = data.buyPtId;
        values.ddAllFunctions = data.allFunctions;
        values.ddAllLocations = data.allLocations;
        values.ddSecurityGroup = data.securityGroup.toUpperCase();
        values.ddAuthorityLevel = data.authLevel;
                
        
        document.getElementsByClassName('lblAddedByValue')[0].innerText = data.addUser + "  " + moment(data.addDateTime).format('MM/DD/YYYY');
        document.getElementsByClassName('lblChangedByValue')[0].innerText = data.chgUser + "  " + moment(data.chgDateTime).format('MM/DD/YYYY');

        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            setValue(_kaledo.thisObj, key, values[key])
          }
        }
        setLoading(false);
        return true;

      } else {
        setLoading(false);
        alert("unable to collect Form data from parent screen");
        return false;
      }
    } catch (error) {
      errorHandler(error, "Form Data Loading");
      return false;
    }
  }

  function EnableDisableControls(boolean = false, functionList = thisObj.state.lstFunctions.valueList, locationList = thisObj.state.lstLocations.valueList) {
    try {
      setmbFunctionsOrLocationsListed(false);
      let enabled = boolean ? !boolean : isEnabled(thisObj, "txtUserID");
      if (enabled) {
        disable(thisObj, "lstLocations");
        disable(thisObj, "lstFunctions");

        disable(thisObj, "btnAddFunction");
        disable(thisObj, "btnEditFunction");
        disable(thisObj, "btnDeleteFunction");

        disable(thisObj, "btnAddLocation");
        disable(thisObj, "btnEditLocation");
        disable(thisObj, "btnDeleteLocation");

        disable(thisObj, "btnImportSecurity");
        disable(thisObj, "ddFromUserID");
      } else {
        enable(thisObj, "btnAddFunction");
        enable(thisObj, "btnAddLocation");
        enable(thisObj, "btnImportSecurity");
        enable(thisObj, "ddFromUserID");

        disable(thisObj, "btnEditFunction");
        disable(thisObj, "btnDeleteFunction");
        disable(thisObj, "btnEditLocation");
        disable(thisObj, "btnDeleteLocation");

        // xxxxxxxx delete below lines only after UAT xxxxxxx //

        // let functionList = _kaledo.thisObj.state.lstFunctions.valueList;
        // let locationList = _kaledo.thisObj.state.lstLocations.valueList;


        // let functionListValues = getData(_kaledo.thisObj, "securityUser_FunctionList");
        // let locationListValues = getData(_kaledo.thisObj, "securityUser_LocationList");
        //functionList[0].key !== undefined
        //locationList[0].key !== undefined


        if (functionList.length > 0 && functionList[functionList.length - 1].key !== undefined) {
          enable(thisObj, "lstFunctions");
          enable(thisObj, "btnEditFunction");
          enable(thisObj, "btnDeleteFunction");
          setmbFunctionsOrLocationsListed(true);
        } else {
          disable(thisObj, "lstFunctions");
          disable(thisObj, "btnEditFunction");
          disable(thisObj, "btnDeleteFunction");
        }

        if (locationList.length > 0 && locationList[locationList.length - 1].key !== undefined) {
          enable(thisObj, "lstLocations");
          enable(thisObj, "btnEditLocation");
          enable(thisObj, "btnDeleteLocation");
          setmbFunctionsOrLocationsListed(true);
        } else {
          disable(thisObj, "lstLocations");
          disable(thisObj, "btnEditLocation");
          disable(thisObj, "btnDeleteLocation");
        }
      }

    } catch (error) {
      errorHandler(error, 'Enable Disble Controls');
      return false;
    }
  }

  async function bFillUserFunctions_Update() {
    try {
      let js = [];
      let user_id = getValue(_kaledo.thisObj, "txtUserID");
      let comp_id = sessionStorage.getItem('compId');
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveUserFunctionNameDetails(comp_id, user_id);
      if (response !== undefined && response !== null && response.length !== 0) {
        for (var i = 0; i < response.length; i++) {
          js.push({ key: response[i].func_id + "." + response[i].func_sub_id, description: response[i].func_id + "." + response[i].func_sub_id + " (" + response[i].access_level + ") - " + response[i].func_name })
        }
      } else {
        thisObj.setState(current => {
          return {
            ...current,
            lstFunctions: {
              ...state["lstFunctions"],
              valueList: js
            }
          }
        })
        return false;
      }

      thisObj.setState(current => {
        return {
          ...current,
          lstFunctions: {
            ...state["lstFunctions"],
            valueList: js
          }
        }
      })
      setValue(_kaledo.thisObj, 'lstFunctions', [js.at(0).key]);


      return js;
    } catch (error) {
      errorHandler(error, "Functions List Data")
      return false;
    }
  }

  async function bFillUserLocations_Update() {
    try {
      let js = [];
      let user_id = getValue(_kaledo.thisObj, "txtUserID");
      let func_id = 'PN0060';
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveUserLocationNameDetails(func_id, user_id, null);
      if (response !== undefined && response !== null && response.length !== 0) {
        for (var i = 0; i < response.length; i++) {
          js.push({ key: response[i].buy_pt_id, description: response[i].buy_pt_id + " (" + response[i].access_level + ") - " + response[i].buy_pt_name })
        }
      } else {
        thisObj.setState(current => {
          return {
            ...current,
            lstLocations: {
              ...state["lstLocations"],
              valueList: js
            }
          }
        })
        return false;
      }

      thisObj.setState(current => {
        return {
          ...current,
          lstLocations: {
            ...state["lstLocations"],
            valueList: js
          }
        }
      })
      setValue(_kaledo.thisObj, 'lstLocations', [js.at(0).key]);
      setData(_kaledo.thisObj, "securityUser_LocationList", js);


      return js;
    } catch (error) {
      errorHandler(error, "Location List Data")
      return false;
    }
  }

  //blur events
  const ontxtUserIDBlur = () => {
    try {
      let value = (getValue(thisObj, "txtUserID"));
      value = value == undefined ? "" : value.toUpperCase();
      setValue(thisObj, "txtUserID", value);
      return true;
    } catch (error) {
      errorHandler(error, "UserId textbox blur event");
      return false;
    }
  }
  thisObj.ontxtUserIDBlur = ontxtUserIDBlur;

  //click events

  const onbtnImportSecurityClick = async () => {
    try {
      let cboImportFromUser = getValue(thisObj, "ddFromUserID");
      let userId = getValue(thisObj, "txtUserID");

      if (cboImportFromUser == undefined || cboImportFromUser == "" || cboImportFromUser == null) {
        showMessage(thisObj, "You must enter a user to import from!");
        document.getElementById("ddFromUserID").focus();
        return false;
      }

      if (mbFunctionsOrLocationsListed) {
        let inputValue = window.confirm("This will remove the existing function/location security for user " + userId + " and replace it with the specified user security. \n Continue?")
        if (!inputValue) {
          return false;
        }
      }

      let response = await SystemMaintenanceSpecialFunctionsService.CreateUserSecurityImport(cboImportFromUser, userId)
      if (response.status == 200) {
        showMessage(thisObj, response.message, true);
        let functionList = await bFillUserFunctions_Update()
        let locationList = await bFillUserLocations_Update()

        EnableDisableControls(true, functionList == false ? [] : functionList, locationList == false ? [] : locationList);
        onbtnAddClick([], functionList, locationList);
      } else {
        showMessage(thisObj, response.message);
        return false;
      }

    } catch (error) {
      errorHandler(error, "Import Security button click");
      return false;
    }
  }
  thisObj.onbtnImportSecurityClick = onbtnImportSecurityClick;

  //add buttons

  const onbtnAddClick = async (event, functionListvalues = thisObj.state.lstFunctions.valueList, locationList = thisObj.state.lstLocations.valueList) => {
    try {
      setLoading(true);
      let userId = getValue(thisObj, "txtUserID");
      let userName = getValue(thisObj, "txtUserName");
      let emailId = getValue(thisObj, "txtUserEmail") == undefined ? "" : getValue(thisObj, "txtUserEmail").trim();
      let buttonCaption = document.getElementById('btnAdd').innerText;

      if (userId == undefined || userId == "" || userName == undefined || userName == "" || emailId == undefined || emailId == "") {
        showMessage(thisObj, "User ID , User Name and Email Id are Required Fields!!!")
        setLoading(false);
        return false;
      }

      if (!validateEmail(emailId)) {  //if they don't need this one then remove
        showMessage(thisObj, "Please provide valid Email");
        setLoading(false);
        return false;
      }

      let data = { //object for update api
        "user_name": userName,
        "ibm_user_id": getValue(thisObj, "txtIBMUserID") == undefined ? "" : getValue(thisObj, "txtIBMUserID"),
        "user_phone": getValue(thisObj, "txtUserPhone") == undefined ? "" : getValue(thisObj, "txtUserPhone"),
        "user_email": emailId,
        "buy_pt_id": getValue(thisObj, "ddBuyingPoint"),
        "all_locations": getValue(thisObj, "ddAllLocations"),
        "all_functions": getValue(thisObj, "ddAllFunctions"),
        "security_group": getValue(thisObj, "ddSecurityGroup"),
        "auth_level": getValue(thisObj, "ddAuthorityLevel"),
        "uid": userId
      }


      switch (buttonCaption) {
        case "Update":
          let response = await SystemMaintenanceSpecialFunctionsService.UpdateUserControl(data);
          if (response.status == 200) {
            let obj = {};
            obj.lstUsers = userId + " - " + userName;
            obj.lstUserId = userId;
            obj.cmdEnableDisableControls = true;
            obj.condition = "Update"
            setData(thisObj, "DatafromChildScreensToSecurityMenu", obj);
            //showMessage(thisObj, response.message, true);
            EnableDisableControls(true, functionListvalues, locationList);
          } else {
            setLoading(false);
            if (response.message.includes(emailId) && response.message.includes("duplicate key")) {
              showMessage(thisObj, "User Email Already in use for another user.");
              return false
            }
            showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
            return false;
          }
          break;

        case "Add":
          let alldata = getData(thisObj, "userList");
          let filteredData = alldata.find(alldata => alldata.userId == userId.toUpperCase())
          if (filteredData !== undefined) {
            setLoading(false);
            showMessage(thisObj, "User ID Already Exists.");
            return false;
          } else {
            data.comp_id = sessionStorage.getItem('compId');
            data.uid = (sessionStorage.getItem('userid') || '');
            data.user_id = userId;
            let response2 = await SystemMaintenanceSpecialFunctionsService.CreateUserControl(data);
            if (response2.status !== 200) {
              setLoading(false);
              if (response2.message.includes(emailId) && response2.message.includes("duplicate key")) {
                showMessage(thisObj, "User Email Already in use for another user.");
                return false
              }
              showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
              return false;
            }
            let obj = {};
            obj.lstUsers = userId + " - " + userName;
            obj.lstUserId = userId;
            obj.cmdEnableDisableControls = true;
            obj.condition = "Add"
            setData(thisObj, "DatafromChildScreensToSecurityMenu", obj);
            showMessage(thisObj, response2.message, true);
            document.getElementsByClassName('lblNote')[0].style.visibility = 'hidden';
            document.getElementById('btnAdd').innerText = "Update";
            disable(thisObj, "txtUserID");
            EnableDisableControls(true, thisObj.state.lstFunctions.valueList, thisObj.state.lstLocations.valueList);
          }
          break;

        default:
          break;
      }
      setLoading(false);
      return true;

    } catch (error) {
      errorHandler(error, 'Add/Update button click')
      return false
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnAddFunctionClick = () => {
    try {
      let userId = getValue(thisObj, "txtUserID");
      let userName = getValue(thisObj, "txtUserName");
      let buy_pt_id = getValue(thisObj, "ddBuyingPoint");
      let js = {
        buyingPt: buy_pt_id, // must
        userId: userId, // must
        userName: userName, // must
        funcId: "", // only for EDIT
        funcSubId: "", // only for EDIT
        access: "", // only for EDIT
        addedBy: "", // only for EDIT
        changedBy: "", // only for EDIT
        condition: "Add"
      }
      setData(thisObj, 'SecurityUserFunctionSelectData', js);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUserFunctionSelect#DEFAULT#true#Click");
      return true;
    } catch (error) {
      errorHandler(error, "Add Function Button Click");
      return false;
    }
  }
  thisObj.onbtnAddFunctionClick = onbtnAddFunctionClick;

  const onbtnAddLocationClick = () => {
    try {
      let userId = getValue(thisObj, "txtUserID");
      let userName = getValue(thisObj, "txtUserName");

      let obj = {};
      obj.lblKeyInformationTag = userId;
      obj.lblKeyInformationCaption = userId + " - " + userName;
      obj.cmdOkCaption = "Add";
      obj.lstBuyingPointID = "";
      obj.lstAccessLevelId = "";
      obj.lblAddedByCaption = "";
      obj.lblChangedByCaption = "";
      obj.cboBuyingPointEnabled = true;
      setData(thisObj, "dataForSecurityUserLocationSelect", obj);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUserLocationSelect#DEFAULT#true#Click");
      return true;
    } catch (error) {
      errorHandler(error, "Add Location Button Click");
      return false;
    }
  }
  thisObj.onbtnAddLocationClick = onbtnAddLocationClick;

  //edit buttons

  const onbtnEditFunctionClick = async () => {
    try {
      let userId = getValue(thisObj, "txtUserID");
      let userName = getValue(thisObj, "txtUserName");
      let buy_pt_id = getValue(thisObj, "lstLocations");
      let listValue = getValue(thisObj, "lstFunctions")[0];
      let func_id = listValue.slice(0, listValue.indexOf("."));
      let func_sub_id = listValue.slice(listValue.indexOf(".") + 1, listValue.length);

      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveUserFunctionTransferDetails(userId, func_id, func_sub_id);
      if (response !== undefined && response !== null && response.length > 0) {
        let js = {
          buyingPt: buy_pt_id, // must
          userId: userId, // must
          userName: userName, // must
          funcId: response[0].func_id, // only for EDIT
          funcSubId: response[0].func_sub_id, // only for EDIT
          access: response[0].access_level, // only for EDIT
          addedBy: response[0].add_user + " " + moment(response[0].add_date_time).format("MM/DD/YYYY"), // only for EDIT
          changedBy: response[0].chg_user + " " + moment(response[0].chg_date_time).format("MM/DD/YYYY"), // only for EDIT
          condition: "Update"
        }
        setData(thisObj, 'SecurityUserFunctionSelectData', js);

        goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUserFunctionSelect#DEFAULT#true#Click");
      } else {
        showMessage(thisObj, "error collecting data on edit function button");
        return false;
      }

      return true;
    } catch (error) {
      errorHandler(error, "Edit Function Button Click");
      return false;
    }
  }
  thisObj.onbtnEditFunctionClick = onbtnEditFunctionClick;

  const onbtnEditLocationClick = async () => {
    try {
      let userId = getValue(thisObj, "txtUserID");
      let userName = getValue(thisObj, "txtUserName");
      let buy_pt_id = getValue(thisObj, "lstLocations");
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveUserLocationTransferDetails(userId, buy_pt_id);
      if (response !== undefined && response !== null && response.length > 0) {
        let obj = {};

        obj.lblKeyInformationTag = userId;
        obj.lblKeyInformationCaption = userId + " - " + userName;
        obj.cmdOkCaption = "Update";
        obj.lstBuyingPointID = response[0].buy_pt_id;
        obj.lstAccessLevelId = response[0].access_level;
        obj.lblAddedByCaption = response[0].add_user + " " + moment(response[0].add_date_time).format("MM/DD/YYYY");
        obj.lblChangedByCaption = response[0].chg_user + " " + moment(response[0].chg_date_time).format("MM/DD/YYYY");
        obj.cboBuyingPointEnabled = false;

        setData(thisObj, "dataForSecurityUserLocationSelect", obj);

        goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUserLocationSelect#DEFAULT#true#Click");
      } else {
        showMessage(thisObj, "error collecting data on edit location button");
        return false;
      }

      return true;
    } catch (error) {
      errorHandler(error, "Edit Location Button Click");
      return false;
    }
  }
  thisObj.onbtnEditLocationClick = onbtnEditLocationClick;

  //delete buttons

  const onbtnDeleteFunctionClick = async () => {
    try {
      let functionListValue = getValue(thisObj, "lstFunctions")[0];
      let valueList = thisObj.state.lstFunctions.valueList;
      let description = ''
      if (valueList !== undefined && valueList !== null) {
        description = valueList.find(elem => elem.key == functionListValue).description;
      }
      let boolean = window.confirm("Are you sure you wish to delete this function '" + description + "'");
      if (!boolean) {
        return false;
      }

      let user_id = getValue(thisObj, "txtUserID");
      let func_id = functionListValue.slice(0, functionListValue.indexOf("."));
      let func_sub_id = functionListValue.slice(functionListValue.indexOf(".") + 1, functionListValue.length);
      let response = await SystemMaintenanceSpecialFunctionsService.RemoveFunctionXref(user_id, func_id, func_sub_id);
      if (response.status !== 200) {
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
        return false;
      } else {

        let index = valueList.findIndex(item => item.key === functionListValue)  //collecting index of record we need to delete
        valueList = valueList.filter(item => item.key !== functionListValue);
        thisObj.setState(current => {
          return {
            ...current,
            lstFunctions: {
              ...state["lstFunctions"],
              valueList: valueList
            }
          }
        })
        //showMessage(thisObj, "Function deleted successfully", true);

        if (valueList !== undefined && valueList.length > 0) {
          if (valueList.length > index) {
            setValue(_kaledo.thisObj, 'lstFunctions', [valueList.at(index).key]); //will select next value
          } else {
            setValue(_kaledo.thisObj, 'lstFunctions', [valueList.at(index - 1).key]); //will select previous value
          }
          EnableDisableControls(true, valueList)
        } else {
          EnableDisableControls(true, valueList)
        }
      }

      return true;
    } catch (error) {
      errorHandler(error, "Delete Function Button Click");
      return false;
    }
  }
  thisObj.onbtnDeleteFunctionClick = onbtnDeleteFunctionClick;

  const onbtnDeleteLocationClick = async () => {
    try {
      let LocationListValue = getValue(thisObj, "lstLocations")[0];
      let valueList = thisObj.state.lstLocations.valueList;
      let description = ''
      if (valueList !== undefined && valueList !== null) {
        description = valueList.find(elem => elem.key == LocationListValue).description;
      }
      let boolean = window.confirm("Are you sure you wish to delete this location '" + description + "'");
      if (!boolean) {
        return false;
      }

      let user_id = getValue(thisObj, "txtUserID");
      let response = await SystemMaintenanceSpecialFunctionsService.RemoveUserLocationTransfer(user_id, LocationListValue);

      if (response.status !== 200) {
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
        return false;
      } else {


        let index = valueList.findIndex(item => item.key === LocationListValue)  //collecting index of record we need to delete
        valueList = valueList.filter(item => item.key !== LocationListValue);
        thisObj.setState(current => {
          return {
            ...current,
            lstLocations: {
              ...state["lstLocations"],
              valueList: valueList
            }
          }
        })
        //showMessage(thisObj, "Location deleted successfully", true);

        if (valueList !== undefined && valueList.length > 0) {
          if (valueList.length > index) {
            setValue(_kaledo.thisObj, 'lstLocations', [valueList.at(index).key]); //will select next value
          } else {
            setValue(_kaledo.thisObj, 'lstLocations', [valueList.at(index - 1).key]); //will select previous value
          }
          EnableDisableControls(true, undefined, valueList)
        } else {
          EnableDisableControls(true, undefined, valueList)
        }
      }

      return true;
    } catch (error) {
      errorHandler(error, "Delete Location Button Click");
      return false;
    }
  }
  thisObj.onbtnDeleteLocationClick = onbtnDeleteLocationClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityUserPopUp").childNodes[0].click()
      return true;
    } catch (error) {
      errorHandler(error, "cancel button click");
      return false
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // extra functions 
  function validateEmail(email) { //if not needed then please remove
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  //explanation for above function

  //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // This function defines a regular expression (regex) pattern stored in the emailRegex variable.
  // The regex pattern ^[^\s@]+@[^\s@]+\.[^\s@]+$ can be broken down as follows:
  // 1)  ^: Matches the beginning of the string.
  // 2)  [^\s@]+: Matches one or more characters that are not whitespace (\s) or the "@" symbol.
  // 3)  @: Matches the "@" symbol.
  // 4)  [^\s@]+: Matches one or more characters that are not whitespace (\s) or the "@" symbol.
  // 5)  \.: Matches a literal period (dot) character, which is required in all valid email addresses.
  // 6)  [^\s@]+: Matches one or more characters that are not whitespace (\s) or the "@" symbol.
  // 7)  $: Matches the end of the string.

  function toPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  //common error handler

  function errorHandler(err, funcName) {
    setLoading(false);
    showMessage(thisObj,
      err instanceof EvalError
        ? err.message
        : 'Something went wrong on ' + funcName + '. Please try again later.'
    )
    //uncomment following line at time of debugging only while pushing code kept this one commented and console.log uncommented to not break page
    //throw Error(err);
  };
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityUser*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityUser*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityUser*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityUser*/}

              <GroupBoxWidget conf={state.grpbxSecurityUser} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUserID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUserID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUserID*/}

                  {/* END_USER_CODE-USER_AFTER_txtUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtIBMUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtIBMUserID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtIBMUserID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtIBMUserID*/}

                  {/* END_USER_CODE-USER_AFTER_txtIBMUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUserName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUserName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUserName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUserName*/}

                  {/* END_USER_CODE-USER_AFTER_txtUserName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUserPhone*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUserPhone*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUserPhone}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUserPhone*/}

                  {/* END_USER_CODE-USER_AFTER_txtUserPhone*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUserEmail*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUserEmail*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUserEmail}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUserEmail*/}

                  {/* END_USER_CODE-USER_AFTER_txtUserEmail*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAllFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAllFunctions*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAllFunctions}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAllFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_ddAllFunctions*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAllLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAllLocations*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAllLocations}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAllLocations*/}

                  {/* END_USER_CODE-USER_AFTER_ddAllLocations*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSecurityGroup*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSecurityGroup*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSecurityGroup}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSecurityGroup*/}

                  {/* END_USER_CODE-USER_AFTER_ddSecurityGroup*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAuthorityLevel*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAuthorityLevel*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAuthorityLevel}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAuthorityLevel*/}

                  {/* END_USER_CODE-USER_AFTER_ddAuthorityLevel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxImportFunctionsLocations*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxImportFunctionsLocations*/}

                <GroupBoxWidget
                  conf={state.grpbxImportFunctionsLocations}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblImportFunctionsLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_lblImportFunctionsLocations*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblImportFunctionsLocations}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblImportFunctionsLocations*/}

                  {/* END_USER_CODE-USER_AFTER_lblImportFunctionsLocations*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFromUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFromUserID*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFromUserID}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFromUserID*/}

                  {/* END_USER_CODE-USER_AFTER_lblFromUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_btnImportSecurity*/}

                  {/* END_USER_CODE-USER_BEFORE_btnImportSecurity*/}

                  <ButtonWidget
                    conf={state.btnImportSecurity}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnImportSecurity*/}

                  {/* END_USER_CODE-USER_AFTER_btnImportSecurity*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFromUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFromUserID*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFromUserID}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFromUserID*/}

                  {/* END_USER_CODE-USER_AFTER_ddFromUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNote*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNote*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNote}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNote*/}

                  {/* END_USER_CODE-USER_AFTER_lblNote*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxImportFunctionsLocations*/}

                {/* END_USER_CODE-USER_AFTER_grpbxImportFunctionsLocations*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFunctions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFunctions*/}

                <GroupBoxWidget conf={state.grpbxFunctions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFunctions*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFunctions}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_lblFunctions*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddFunction*/}

                  <ButtonWidget
                    conf={state.btnAddFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditFunction*/}

                  <ButtonWidget
                    conf={state.btnEditFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteFunction*/}

                  <ButtonWidget
                    conf={state.btnDeleteFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_lstFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFunctions*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFunctions}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_lstFunctions*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFunctions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFunctions*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLocations*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLocations*/}

                <GroupBoxWidget conf={state.grpbxLocations} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLocations*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLocations}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLocations*/}

                  {/* END_USER_CODE-USER_AFTER_lblLocations*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddLocation*/}

                  <ButtonWidget
                    conf={state.btnAddLocation}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditLocation*/}

                  <ButtonWidget
                    conf={state.btnEditLocation}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditLocation*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteLocation*/}

                  <ButtonWidget
                    conf={state.btnDeleteLocation}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteLocation*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_lstLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_lstLocations*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstLocations}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstLocations*/}

                  {/* END_USER_CODE-USER_AFTER_lstLocations*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLocations*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLocations*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

                <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAddedBy}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                  {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAddedByValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                  {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChangedBy}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                  {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChangedByValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityUser*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityUser*/}

              {/* START_USER_CODE-USER_AFTER_SecurityUser*/}

              {/* END_USER_CODE-USER_AFTER_SecurityUser*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SecurityUser);
