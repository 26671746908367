/* eslint-disable*/
import React from "react";
import CheckboxWidget from "./CheckboxWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("CheckboxWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let checkboxwidget1 = {
        name: "checkboxwidget1",
        type: "CheckBoxWidget",
        parent: "groupboxwidget0",
        Label: "checkboxwidget1",
        ofTypeDomain: "d_boolean",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <CheckboxWidget
          onChange={onChange}
          values={values}
          conf={checkboxwidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("is CheckBoxwidget2 Loads Succesfully", async () => {
    const checkboxwidget1 = screen.getByTestId("checkboxwidget1");
    expect(checkboxwidget1.tagName).toBe("INPUT");
    expect(checkboxwidget1.type).toBe("checkbox");
    fireEvent.click(checkboxwidget1, {
      target: { checked: true },
    });
    expect(checkboxwidget1).checked;
  });
});

describe("CheckboxWidget2 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let checkboxwidget2 = {
        name: "checkboxwidget2",
        type: "CheckBoxWidget",
        parent: "groupboxwidget0",
        Label: "checkboxwidget2",
        ofTypeDomain: "d_boolean",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <CheckboxWidget
          values={values}
          conf={checkboxwidget2}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("is CheckBoxwidget2 Loads Succesfully", async () => {
    const checkboxwidget2 = screen.getByTestId("checkboxwidget2");
    expect(checkboxwidget2.tagName).toBe("INPUT");
    expect(checkboxwidget2.type).toBe("checkbox");
  });
});
