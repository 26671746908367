/* eslint-disable*/
import React from "react";
import { render, screen, fireEvent, createEvent } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import App from "./App";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
//import LoginModule_Login from "../app/modules/LoginModule/Login/Login";
import { rest } from "msw";
import { setupServer } from "msw/node";
import Test_RTL from "./modules/TestModule/Test_RTL/Test_RTL";
jest.setTimeout(30000);
const flushPromises = () => new Promise(setImmediate);
describe("App suite", () => {
  const server = setupServer(
    ...[
      rest.post(
        "http://localhost:9000/oauth2-server/oauth/token",
        (req, res, ctx) => {
          return res(
            ctx.json({
              access_token: "2b07559a-4d3d-48c3-84cc-5065bc8bd46f",
              token_type: "bearer",
              refresh_token: "94ff90d2-7f6f-46aa-8b69-e333e91f5ec8",
              expires_in: 1201,
              scope: "read write trust",
            })
          );
        }
      ),
      rest.delete(
        "http://localhost:9000/oauth2-server/oauth/token",
        (req, res, ctx) => {
          return res(
            ctx.json({
              access_token: "2b07559a-4d3d-48c3-84cc-5065bc8bd46f",
              token_type: "bearer",
              refresh_token: "94ff90d2-7f6f-46aa-8b69-e333e91f5ec8",
              expires_in: 1201,
              scope: "read write trust",
            })
          );
        }
      ),
    ]
  );
  beforeAll(() => server.listen());
  afterEach(() => server.resetHandlers());
  afterAll(() => server.close());
  test("App TestCase", async () => {
    window._kaledo["isCobolTable"] = true;
    window._kaledo["compRoot"] = {
      oAuthComp: "http://localhost:8080/oAuthComp/",
    };
    window._kaledo["AuthRoot"] = "http://localhost:9000/oauth2-server/";
    window._kaledo["loginURL"] = "/login";
    window._kaledo["Auth"] = "OAUTH2";
    window._kaledo["AuthClientId"] = "eam-client";
    window._kaledo["AuthClientSecret"] = "eamrestapp";
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    render(<App></App>);
    let textBoxWidget = document.getElementsByClassName("textboxWidgetClass");
    if (
      textBoxWidget !== undefined
    ) {
      textBoxWidget = document.getElementsByClassName("textboxWidgetClass")[0];
      if (
        textBoxWidget !== undefined
      ) {
        textBoxWidget.maxLength = 2;
        userEvent.type(textBoxWidget, "ss");
        textBoxWidget.selectionStart = 3;
        fireEvent.keyUp(textBoxWidget, {
          key: "s",
          code: "KeyS",
          keyCode: 83,
          charCode: 0,
        });
        fireEvent.keyDown(textBoxWidget, {
          key: "s",
          code: "KeyS",
          keyCode: 83,
          charCode: 0,
        });
    
        fireEvent.keyDown(textBoxWidget, {
          key: "Tab",
          code: "Tab",
          keyCode: 9,
          charCode: 0,
        });
        fireEvent.keyDown(textBoxWidget, {
          key: "Space",
          code: "Space",
          keyCode: 32,
          charCode: 0,
        });

        //Paste Event
        const paste = createEvent.paste(textBoxWidget, {
          clipboardData: {
            getData: () => "123456",
          },
        });

        fireEvent(textBoxWidget, paste);
        const text = "Some text";
        userEvent.paste(textBoxWidget, text);
        textBoxWidget.maxLength = 8;
        const paste2 = createEvent.paste(textBoxWidget, {
          clipboardData: {
            getData: () => "12345678",
          },
        });
        fireEvent(textBoxWidget, paste2);

        
        userEvent.paste(textBoxWidget, text);
        fireEvent.paste(textBoxWidget, { target: { value: "4321" } });
        expect(textBoxWidget.value).toBe("4321");
      }
    }
  });

  test("App TestCase-1", async () => {
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    render(<App></App>);
    screen.debug();
    let textBoxWidget = document.getElementsByClassName("textboxWidgetClass");
    if (
      textBoxWidget !== undefined
    ) {
      textBoxWidget = document.getElementsByClassName("textboxWidgetClass")[0];
      if (
        textBoxWidget !== undefined
      ) {
        textBoxWidget.maxLength = 2;
        userEvent.type(textBoxWidget, "ss");
        textBoxWidget.selectionStart = 3;
        fireEvent.keyUp(textBoxWidget, {
          key: "s",
          code: "KeyS",
          keyCode: 83,
          charCode: 0,
        });
        fireEvent.keyDown(textBoxWidget, {
          key: "s",
          code: "KeyS",
          keyCode: 83,
          charCode: 0,
        });
       
        fireEvent.keyDown(textBoxWidget, {
          key: "Tab",
          code: "Tab",
          keyCode: 9,
          charCode: 0,
        });
        fireEvent.keyDown(textBoxWidget, {
          key: "Space",
          code: "Space",
          keyCode: 32,
          charCode: 0,
        });

        //Paste Event
        const paste = createEvent.paste(textBoxWidget, {
          clipboardData: {
            getData: () => "123456",
          },
        });

        fireEvent(textBoxWidget, paste);
        const text = "Some text";
        userEvent.paste(textBoxWidget, text);
        textBoxWidget.maxLength = 8;
        const paste2 = createEvent.paste(textBoxWidget, {
          clipboardData: {
            getData: () => "12345678",
          },
        });
        fireEvent(textBoxWidget, paste2);

       
        userEvent.paste(textBoxWidget, text);
        fireEvent.paste(textBoxWidget, { target: { value: "4321" } });
        expect(textBoxWidget.value).toBe("4321");
      }
    }
  });
  test("App TestCase-2", async () => {
    window._kaledo["isCobolTable"] = true;
    window._kaledo["compRoot"] = {
      oAuthComp: "http://localhost:8080/oAuthComp/",
    };
    window._kaledo["AuthRoot"] = "http://localhost:9000/oauth2-server/";
    window._kaledo["loginURL"] = "/login";
    window._kaledo["Auth"] = "OAUTH2";
    window._kaledo["AuthClientId"] = "eam-client";
    window._kaledo["AuthClientSecret"] = "eamrestapp";
    window._kaledo["isRouteFromTest"] = true;
    // window._kaledo["routes"] = [
    //   {
    //     path: "/",
    //     component: LoginModule_Login,
    //     exact: true,
    //   },
    //   {
    //     path: "/login",
    //     component: LoginModule_Login,
    //   },
    //   {
    //     path: "/Test_RTL",
    //     component: Test_RTL,
    //     private: true,
    //   },
    // ];
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    render(<App></App>);
    let textBoxWidget = document.getElementsByClassName("textboxWidgetClass");
    if (
      textBoxWidget !== undefined
    ) {
      textBoxWidget = document.getElementsByClassName("textboxWidgetClass")[0];
      if (
        textBoxWidget !== undefined
      ) {
        textBoxWidget.maxLength = 2;
        userEvent.type(textBoxWidget, "ss");
        textBoxWidget.selectionStart = 3;
        fireEvent.keyUp(textBoxWidget, {
          key: "s",
          code: "KeyS",
          keyCode: 83,
          charCode: 0,
        });
        fireEvent.keyDown(textBoxWidget, {
          key: "s",
          code: "KeyS",
          keyCode: 83,
          charCode: 0,
        });
       
        fireEvent.keyDown(textBoxWidget, {
          key: "Tab",
          code: "Tab",
          keyCode: 9,
          charCode: 0,
        });
        fireEvent.keyDown(textBoxWidget, {
          key: "Space",
          code: "Space",
          keyCode: 32,
          charCode: 0,
        });

        //Paste Event
        const paste = createEvent.paste(textBoxWidget, {
          clipboardData: {
            getData: () => "123456",
          },
        });

        fireEvent(textBoxWidget, paste);
        const text = "Some text";
        userEvent.paste(textBoxWidget, text);
        textBoxWidget.maxLength = 8;
        const paste2 = createEvent.paste(textBoxWidget, {
          clipboardData: {
            getData: () => "12345678",
          },
        });
        fireEvent(textBoxWidget, paste2);

       
        userEvent.paste(textBoxWidget, text);
        fireEvent.paste(textBoxWidget, { target: { value: "4321" } });
        expect(textBoxWidget.value).toBe("4321");
        userEvent.type(screen.getByTestId("username"), "eam_admin");
        userEvent.type(screen.getByTestId("password"), "admin");
        userEvent.click(screen.getByTestId("login"), { button: 0 });
        await flushPromises();
        setTimeout(async () => {
          let logOut = screen.getByText("translate:Logout");
          await act(async () => {
            userEvent.click(logOut, { button: 0 });
          });
        }, 1000);
        await flushPromises();
      }
    }
  });
  test("App TestCase-3", async () => {
    window._kaledo["isCobolTable"] = true;
    window._kaledo["compRoot"] = {
      oAuthComp: "http://localhost:8080/oAuthComp/",
    };
    window._kaledo["AuthRoot"] = "http://localhost:9000/oauth2-server/";
    window._kaledo["loginURL"] = "/login";
    window._kaledo["Auth"] = "OAUTH2";
    window._kaledo["AuthClientId"] = "eam-client";
    window._kaledo["AuthClientSecret"] = "eamrestapp";
    window._kaledo["isRouteFromTest"] = false;
    // window._kaledo["routes"] = [
    //   {
    //     path: "/",
    //     component: LoginModule_Login,
    //     exact: true,
    //   },
    //   {
    //     path: "/login",
    //     component: LoginModule_Login,
    //   },
    //   {
    //     path: "/Test_RTL",
    //     component: Test_RTL,
    //     private: true,
    //   },
    // ];
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    render(<App></App>);

    await flushPromises();
  });
  test("App TestCase-4", async () => {
    window._kaledo["isCobolTable"] = true;
    window._kaledo["compRoot"] = {
      oAuthComp: "http://localhost:8080/oAuthComp/",
    };
    window._kaledo["AuthRoot"] = "http://localhost:9000/oauth2-server/";
    window._kaledo["loginURL"] = "/login";
    window._kaledo["Auth"] = "OAUTH2";
    window._kaledo["AuthClientId"] = "eam-client";
    window._kaledo["AuthClientSecret"] = "eamrestapp";
    window._kaledo["isRouteFromTest"] = true;
    window._kaledo["authData"] = {
      access_token: "2b07559a-4d3d-48c3-84cc-5065bc8bd46f",
      token_type: "bearer",
      refresh_token: "94ff90d2-7f6f-46aa-8b69-e333e91f5ec8",
      expires_in: 1201,
      scope: "read write trust",
    };
    // window._kaledo["routes"] = [
    //   {
    //     path: "/",
    //     component: LoginModule_Login,
    //     exact: true,
    //   },
    //   {
    //     path: "/login",
    //     component: LoginModule_Login,
    //   },
    //   {
    //     path: "/Test_RTL",
    //     component: Test_RTL,
    //     private: true,
    //   },
    // ];
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    render(<App></App>);
    await flushPromises();
    let logOut = screen.getByText("translate:Logout");
    await act(async () => {
      userEvent.click(logOut, { button: 0 });
    });
    await flushPromises();
  });
});
