/* eslint-disable*/
import React from "react";
import StockTransfer_MultiStopProfile from "./MultiStopProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("MultiStopProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_MultiStopProfile />);
    });
  });
  afterEach(cleanup);
  test("is MultiStopProfile Loads Successfully", () => {
    expect(screen.getByText("MultiStopProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for MultiStopProfile", () => {
    // START_USER_CODE-USER_MultiStopProfile_Custom_Test_Case
    // END_USER_CODE-USER_MultiStopProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_MultiStopProfile />);
    });
  });
  afterEach(cleanup);
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnGreightAccural(Button Widget) Test Cases", async () => {
    const btnGreightAccural = screen.getByTestId("btnGreightAccural");
    expect(btnGreightAccural).toBeInTheDocument;
    expect(btnGreightAccural.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_btnGreightAccural")
    );
  });
  test("Custom Test Cases for btnGreightAccural", () => {
    // START_USER_CODE-USER_btnGreightAccural_TEST
    // END_USER_CODE-USER_btnGreightAccural_TEST
  });
  test("btnSearchForTransfers(Button Widget) Test Cases", async () => {
    const btnSearchForTransfers = screen.getByTestId("btnSearchForTransfers");
    expect(btnSearchForTransfers).toBeInTheDocument;
    expect(btnSearchForTransfers.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_btnSearchForTransfers")
    );
  });
  test("Custom Test Cases for btnSearchForTransfers", () => {
    // START_USER_CODE-USER_btnSearchForTransfers_TEST
    // END_USER_CODE-USER_btnSearchForTransfers_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxGrid(GroupBox Widget) Test Cases", async () => {
    const grpbxGrid = screen.getByTestId("grpbxGrid");
    expect(grpbxGrid.tagName).toBe("BUTTON");
    expect(grpbxGrid.type).toBe("button");
    expect(grpbxGrid.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrid", () => {
    // START_USER_CODE-USER_grpbxGrid_TEST
    // END_USER_CODE-USER_grpbxGrid_TEST
  });
  test("grpbxLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxLabels = screen.getByTestId("grpbxLabels");
    expect(grpbxLabels.tagName).toBe("BUTTON");
    expect(grpbxLabels.type).toBe("button");
    expect(grpbxLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLabels", () => {
    // START_USER_CODE-USER_grpbxLabels_TEST
    // END_USER_CODE-USER_grpbxLabels_TEST
  });
  test("grpbxMultiStopProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxMultiStopProfile = screen.getByTestId("grpbxMultiStopProfile");
    expect(grpbxMultiStopProfile.tagName).toBe("BUTTON");
    expect(grpbxMultiStopProfile.type).toBe("button");
    expect(grpbxMultiStopProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMultiStopProfile", () => {
    // START_USER_CODE-USER_grpbxMultiStopProfile_TEST
    // END_USER_CODE-USER_grpbxMultiStopProfile_TEST
  });
  test("grpbxRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxRow0 = screen.getByTestId("grpbxRow0");
    expect(grpbxRow0.tagName).toBe("BUTTON");
    expect(grpbxRow0.type).toBe("button");
    expect(grpbxRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow0", () => {
    // START_USER_CODE-USER_grpbxRow0_TEST
    // END_USER_CODE-USER_grpbxRow0_TEST
  });
  test("grpbxRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxRow1 = screen.getByTestId("grpbxRow1");
    expect(grpbxRow1.tagName).toBe("BUTTON");
    expect(grpbxRow1.type).toBe("button");
    expect(grpbxRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow1", () => {
    // START_USER_CODE-USER_grpbxRow1_TEST
    // END_USER_CODE-USER_grpbxRow1_TEST
  });
  test("grpbxRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxRow2 = screen.getByTestId("grpbxRow2");
    expect(grpbxRow2.tagName).toBe("BUTTON");
    expect(grpbxRow2.type).toBe("button");
    expect(grpbxRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow2", () => {
    // START_USER_CODE-USER_grpbxRow2_TEST
    // END_USER_CODE-USER_grpbxRow2_TEST
  });
  test("grpbxRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxRow3 = screen.getByTestId("grpbxRow3");
    expect(grpbxRow3.tagName).toBe("BUTTON");
    expect(grpbxRow3.type).toBe("button");
    expect(grpbxRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow3", () => {
    // START_USER_CODE-USER_grpbxRow3_TEST
    // END_USER_CODE-USER_grpbxRow3_TEST
  });
  test("grpbxRow4(GroupBox Widget) Test Cases", async () => {
    const grpbxRow4 = screen.getByTestId("grpbxRow4");
    expect(grpbxRow4.tagName).toBe("BUTTON");
    expect(grpbxRow4.type).toBe("button");
    expect(grpbxRow4.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow4", () => {
    // START_USER_CODE-USER_grpbxRow4_TEST
    // END_USER_CODE-USER_grpbxRow4_TEST
  });
  test("grpbxRow5(GroupBox Widget) Test Cases", async () => {
    const grpbxRow5 = screen.getByTestId("grpbxRow5");
    expect(grpbxRow5.tagName).toBe("BUTTON");
    expect(grpbxRow5.type).toBe("button");
    expect(grpbxRow5.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow5", () => {
    // START_USER_CODE-USER_grpbxRow5_TEST
    // END_USER_CODE-USER_grpbxRow5_TEST
  });
  test("grpbxRow6(GroupBox Widget) Test Cases", async () => {
    const grpbxRow6 = screen.getByTestId("grpbxRow6");
    expect(grpbxRow6.tagName).toBe("BUTTON");
    expect(grpbxRow6.type).toBe("button");
    expect(grpbxRow6.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow6", () => {
    // START_USER_CODE-USER_grpbxRow6_TEST
    // END_USER_CODE-USER_grpbxRow6_TEST
  });
  test("grpbxRow7(GroupBox Widget) Test Cases", async () => {
    const grpbxRow7 = screen.getByTestId("grpbxRow7");
    expect(grpbxRow7.tagName).toBe("BUTTON");
    expect(grpbxRow7.type).toBe("button");
    expect(grpbxRow7.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow7", () => {
    // START_USER_CODE-USER_grpbxRow7_TEST
    // END_USER_CODE-USER_grpbxRow7_TEST
  });
  test("grpbxRow8(GroupBox Widget) Test Cases", async () => {
    const grpbxRow8 = screen.getByTestId("grpbxRow8");
    expect(grpbxRow8.tagName).toBe("BUTTON");
    expect(grpbxRow8.type).toBe("button");
    expect(grpbxRow8.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow8", () => {
    // START_USER_CODE-USER_grpbxRow8_TEST
    // END_USER_CODE-USER_grpbxRow8_TEST
  });
  test("grpbxRow9(GroupBox Widget) Test Cases", async () => {
    const grpbxRow9 = screen.getByTestId("grpbxRow9");
    expect(grpbxRow9.tagName).toBe("BUTTON");
    expect(grpbxRow9.type).toBe("button");
    expect(grpbxRow9.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRow9", () => {
    // START_USER_CODE-USER_grpbxRow9_TEST
    // END_USER_CODE-USER_grpbxRow9_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblLegNum(Label Widget) Test Cases", async () => {
    const lblLegNum = screen.getByTestId("lblLegNum");
    expect(lblLegNum.tagName).toBe("LABEL");
    expect(lblLegNum.classList).toContain("form-label");
    expect(lblLegNum.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblLegNum")
    );
  });
  test("Custom Test Cases for lblLegNum", () => {
    // START_USER_CODE-USER_lblLegNum_TEST
    // END_USER_CODE-USER_lblLegNum_TEST
  });
  test("lblMileageOverride(Label Widget) Test Cases", async () => {
    const lblMileageOverride = screen.getByTestId("lblMileageOverride");
    expect(lblMileageOverride.tagName).toBe("LABEL");
    expect(lblMileageOverride.classList).toContain("form-label");
    expect(lblMileageOverride.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblMileageOverride")
    );
  });
  test("Custom Test Cases for lblMileageOverride", () => {
    // START_USER_CODE-USER_lblMileageOverride_TEST
    // END_USER_CODE-USER_lblMileageOverride_TEST
  });
  test("lblReceivingLocation(Label Widget) Test Cases", async () => {
    const lblReceivingLocation = screen.getByTestId("lblReceivingLocation");
    expect(lblReceivingLocation.tagName).toBe("LABEL");
    expect(lblReceivingLocation.classList).toContain("form-label");
    expect(lblReceivingLocation.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblReceivingLocation")
    );
  });
  test("Custom Test Cases for lblReceivingLocation", () => {
    // START_USER_CODE-USER_lblReceivingLocation_TEST
    // END_USER_CODE-USER_lblReceivingLocation_TEST
  });
  test("lblShippingLocation(Label Widget) Test Cases", async () => {
    const lblShippingLocation = screen.getByTestId("lblShippingLocation");
    expect(lblShippingLocation.tagName).toBe("LABEL");
    expect(lblShippingLocation.classList).toContain("form-label");
    expect(lblShippingLocation.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblShippingLocation")
    );
  });
  test("Custom Test Cases for lblShippingLocation", () => {
    // START_USER_CODE-USER_lblShippingLocation_TEST
    // END_USER_CODE-USER_lblShippingLocation_TEST
  });
  test("lblTransferNum(Label Widget) Test Cases", async () => {
    const lblTransferNum = screen.getByTestId("lblTransferNum");
    expect(lblTransferNum.tagName).toBe("LABEL");
    expect(lblTransferNum.classList).toContain("form-label");
    expect(lblTransferNum.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblTransferNum")
    );
  });
  test("Custom Test Cases for lblTransferNum", () => {
    // START_USER_CODE-USER_lblTransferNum_TEST
    // END_USER_CODE-USER_lblTransferNum_TEST
  });
  test("lblType(Label Widget) Test Cases", async () => {
    const lblType = screen.getByTestId("lblType");
    expect(lblType.tagName).toBe("LABEL");
    expect(lblType.classList).toContain("form-label");
    expect(lblType.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_lblType")
    );
  });
  test("Custom Test Cases for lblType", () => {
    // START_USER_CODE-USER_lblType_TEST
    // END_USER_CODE-USER_lblType_TEST
  });
  test("txtCarrierAbbreviation(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbreviation = screen.getByTestId("txtCarrierAbbreviation");
    expect(txtCarrierAbbreviation.tagName).toBe("INPUT");
    expect(txtCarrierAbbreviation.type).toBe("text");
    expect(txtCarrierAbbreviation.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbreviation.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_txtCarrierAbbreviation")
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbreviation, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbreviation", () => {
    // START_USER_CODE-USER_txtCarrierAbbreviation_TEST
    // END_USER_CODE-USER_txtCarrierAbbreviation_TEST
  });
  test("txtLegNum0(Textbox Widget) Test Cases", async () => {
    const txtLegNum0 = screen.getByTestId("txtLegNum0");
    expect(txtLegNum0.tagName).toBe("INPUT");
    expect(txtLegNum0.type).toBe("text");
    expect(txtLegNum0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum0, "1");
    });
  });
  test("Custom Test Cases for txtLegNum0", () => {
    // START_USER_CODE-USER_txtLegNum0_TEST
    // END_USER_CODE-USER_txtLegNum0_TEST
  });
  test("txtLegNum1(Textbox Widget) Test Cases", async () => {
    const txtLegNum1 = screen.getByTestId("txtLegNum1");
    expect(txtLegNum1.tagName).toBe("INPUT");
    expect(txtLegNum1.type).toBe("text");
    expect(txtLegNum1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum1, "1");
    });
  });
  test("Custom Test Cases for txtLegNum1", () => {
    // START_USER_CODE-USER_txtLegNum1_TEST
    // END_USER_CODE-USER_txtLegNum1_TEST
  });
  test("txtLegNum2(Textbox Widget) Test Cases", async () => {
    const txtLegNum2 = screen.getByTestId("txtLegNum2");
    expect(txtLegNum2.tagName).toBe("INPUT");
    expect(txtLegNum2.type).toBe("text");
    expect(txtLegNum2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum2, "1");
    });
  });
  test("Custom Test Cases for txtLegNum2", () => {
    // START_USER_CODE-USER_txtLegNum2_TEST
    // END_USER_CODE-USER_txtLegNum2_TEST
  });
  test("txtLegNum3(Textbox Widget) Test Cases", async () => {
    const txtLegNum3 = screen.getByTestId("txtLegNum3");
    expect(txtLegNum3.tagName).toBe("INPUT");
    expect(txtLegNum3.type).toBe("text");
    expect(txtLegNum3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum3, "1");
    });
  });
  test("Custom Test Cases for txtLegNum3", () => {
    // START_USER_CODE-USER_txtLegNum3_TEST
    // END_USER_CODE-USER_txtLegNum3_TEST
  });
  test("txtLegNum4(Textbox Widget) Test Cases", async () => {
    const txtLegNum4 = screen.getByTestId("txtLegNum4");
    expect(txtLegNum4.tagName).toBe("INPUT");
    expect(txtLegNum4.type).toBe("text");
    expect(txtLegNum4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum4, "1");
    });
  });
  test("Custom Test Cases for txtLegNum4", () => {
    // START_USER_CODE-USER_txtLegNum4_TEST
    // END_USER_CODE-USER_txtLegNum4_TEST
  });
  test("txtLegNum5(Textbox Widget) Test Cases", async () => {
    const txtLegNum5 = screen.getByTestId("txtLegNum5");
    expect(txtLegNum5.tagName).toBe("INPUT");
    expect(txtLegNum5.type).toBe("text");
    expect(txtLegNum5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum5, "1");
    });
  });
  test("Custom Test Cases for txtLegNum5", () => {
    // START_USER_CODE-USER_txtLegNum5_TEST
    // END_USER_CODE-USER_txtLegNum5_TEST
  });
  test("txtLegNum6(Textbox Widget) Test Cases", async () => {
    const txtLegNum6 = screen.getByTestId("txtLegNum6");
    expect(txtLegNum6.tagName).toBe("INPUT");
    expect(txtLegNum6.type).toBe("text");
    expect(txtLegNum6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum6, "1");
    });
  });
  test("Custom Test Cases for txtLegNum6", () => {
    // START_USER_CODE-USER_txtLegNum6_TEST
    // END_USER_CODE-USER_txtLegNum6_TEST
  });
  test("txtLegNum7(Textbox Widget) Test Cases", async () => {
    const txtLegNum7 = screen.getByTestId("txtLegNum7");
    expect(txtLegNum7.tagName).toBe("INPUT");
    expect(txtLegNum7.type).toBe("text");
    expect(txtLegNum7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum7, "1");
    });
  });
  test("Custom Test Cases for txtLegNum7", () => {
    // START_USER_CODE-USER_txtLegNum7_TEST
    // END_USER_CODE-USER_txtLegNum7_TEST
  });
  test("txtLegNum8(Textbox Widget) Test Cases", async () => {
    const txtLegNum8 = screen.getByTestId("txtLegNum8");
    expect(txtLegNum8.tagName).toBe("INPUT");
    expect(txtLegNum8.type).toBe("text");
    expect(txtLegNum8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum8, "1");
    });
  });
  test("Custom Test Cases for txtLegNum8", () => {
    // START_USER_CODE-USER_txtLegNum8_TEST
    // END_USER_CODE-USER_txtLegNum8_TEST
  });
  test("txtLegNum9(Textbox Widget) Test Cases", async () => {
    const txtLegNum9 = screen.getByTestId("txtLegNum9");
    expect(txtLegNum9.tagName).toBe("INPUT");
    expect(txtLegNum9.type).toBe("text");
    expect(txtLegNum9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLegNum9, "1");
    });
  });
  test("Custom Test Cases for txtLegNum9", () => {
    // START_USER_CODE-USER_txtLegNum9_TEST
    // END_USER_CODE-USER_txtLegNum9_TEST
  });
  test("txtMileage0(Textbox Widget) Test Cases", async () => {
    const txtMileage0 = screen.getByTestId("txtMileage0");
    expect(txtMileage0.tagName).toBe("INPUT");
    expect(txtMileage0.type).toBe("text");
    expect(txtMileage0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage0, "1");
    });
  });
  test("Custom Test Cases for txtMileage0", () => {
    // START_USER_CODE-USER_txtMileage0_TEST
    // END_USER_CODE-USER_txtMileage0_TEST
  });
  test("txtMileage1(Textbox Widget) Test Cases", async () => {
    const txtMileage1 = screen.getByTestId("txtMileage1");
    expect(txtMileage1.tagName).toBe("INPUT");
    expect(txtMileage1.type).toBe("text");
    expect(txtMileage1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage1, "1");
    });
  });
  test("Custom Test Cases for txtMileage1", () => {
    // START_USER_CODE-USER_txtMileage1_TEST
    // END_USER_CODE-USER_txtMileage1_TEST
  });
  test("txtMileage2(Textbox Widget) Test Cases", async () => {
    const txtMileage2 = screen.getByTestId("txtMileage2");
    expect(txtMileage2.tagName).toBe("INPUT");
    expect(txtMileage2.type).toBe("text");
    expect(txtMileage2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage2, "1");
    });
  });
  test("Custom Test Cases for txtMileage2", () => {
    // START_USER_CODE-USER_txtMileage2_TEST
    // END_USER_CODE-USER_txtMileage2_TEST
  });
  test("txtMileage3(Textbox Widget) Test Cases", async () => {
    const txtMileage3 = screen.getByTestId("txtMileage3");
    expect(txtMileage3.tagName).toBe("INPUT");
    expect(txtMileage3.type).toBe("text");
    expect(txtMileage3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage3, "1");
    });
  });
  test("Custom Test Cases for txtMileage3", () => {
    // START_USER_CODE-USER_txtMileage3_TEST
    // END_USER_CODE-USER_txtMileage3_TEST
  });
  test("txtMileage4(Textbox Widget) Test Cases", async () => {
    const txtMileage4 = screen.getByTestId("txtMileage4");
    expect(txtMileage4.tagName).toBe("INPUT");
    expect(txtMileage4.type).toBe("text");
    expect(txtMileage4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage4, "1");
    });
  });
  test("Custom Test Cases for txtMileage4", () => {
    // START_USER_CODE-USER_txtMileage4_TEST
    // END_USER_CODE-USER_txtMileage4_TEST
  });
  test("txtMileage5(Textbox Widget) Test Cases", async () => {
    const txtMileage5 = screen.getByTestId("txtMileage5");
    expect(txtMileage5.tagName).toBe("INPUT");
    expect(txtMileage5.type).toBe("text");
    expect(txtMileage5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage5, "1");
    });
  });
  test("Custom Test Cases for txtMileage5", () => {
    // START_USER_CODE-USER_txtMileage5_TEST
    // END_USER_CODE-USER_txtMileage5_TEST
  });
  test("txtMileage6(Textbox Widget) Test Cases", async () => {
    const txtMileage6 = screen.getByTestId("txtMileage6");
    expect(txtMileage6.tagName).toBe("INPUT");
    expect(txtMileage6.type).toBe("text");
    expect(txtMileage6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage6, "1");
    });
  });
  test("Custom Test Cases for txtMileage6", () => {
    // START_USER_CODE-USER_txtMileage6_TEST
    // END_USER_CODE-USER_txtMileage6_TEST
  });
  test("txtMileage7(Textbox Widget) Test Cases", async () => {
    const txtMileage7 = screen.getByTestId("txtMileage7");
    expect(txtMileage7.tagName).toBe("INPUT");
    expect(txtMileage7.type).toBe("text");
    expect(txtMileage7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage7, "1");
    });
  });
  test("Custom Test Cases for txtMileage7", () => {
    // START_USER_CODE-USER_txtMileage7_TEST
    // END_USER_CODE-USER_txtMileage7_TEST
  });
  test("txtMileage8(Textbox Widget) Test Cases", async () => {
    const txtMileage8 = screen.getByTestId("txtMileage8");
    expect(txtMileage8.tagName).toBe("INPUT");
    expect(txtMileage8.type).toBe("text");
    expect(txtMileage8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage8, "1");
    });
  });
  test("Custom Test Cases for txtMileage8", () => {
    // START_USER_CODE-USER_txtMileage8_TEST
    // END_USER_CODE-USER_txtMileage8_TEST
  });
  test("txtMileage9(Textbox Widget) Test Cases", async () => {
    const txtMileage9 = screen.getByTestId("txtMileage9");
    expect(txtMileage9.tagName).toBe("INPUT");
    expect(txtMileage9.type).toBe("text");
    expect(txtMileage9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMileage9, "1");
    });
  });
  test("Custom Test Cases for txtMileage9", () => {
    // START_USER_CODE-USER_txtMileage9_TEST
    // END_USER_CODE-USER_txtMileage9_TEST
  });
  test("txtOverride0(Textbox Widget) Test Cases", async () => {
    const txtOverride0 = screen.getByTestId("txtOverride0");
    expect(txtOverride0.tagName).toBe("INPUT");
    expect(txtOverride0.type).toBe("text");
    expect(txtOverride0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride0, "1");
    });
  });
  test("Custom Test Cases for txtOverride0", () => {
    // START_USER_CODE-USER_txtOverride0_TEST
    // END_USER_CODE-USER_txtOverride0_TEST
  });
  test("txtOverride1(Textbox Widget) Test Cases", async () => {
    const txtOverride1 = screen.getByTestId("txtOverride1");
    expect(txtOverride1.tagName).toBe("INPUT");
    expect(txtOverride1.type).toBe("text");
    expect(txtOverride1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride1, "1");
    });
  });
  test("Custom Test Cases for txtOverride1", () => {
    // START_USER_CODE-USER_txtOverride1_TEST
    // END_USER_CODE-USER_txtOverride1_TEST
  });
  test("txtOverride2(Textbox Widget) Test Cases", async () => {
    const txtOverride2 = screen.getByTestId("txtOverride2");
    expect(txtOverride2.tagName).toBe("INPUT");
    expect(txtOverride2.type).toBe("text");
    expect(txtOverride2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride2, "1");
    });
  });
  test("Custom Test Cases for txtOverride2", () => {
    // START_USER_CODE-USER_txtOverride2_TEST
    // END_USER_CODE-USER_txtOverride2_TEST
  });
  test("txtOverride3(Textbox Widget) Test Cases", async () => {
    const txtOverride3 = screen.getByTestId("txtOverride3");
    expect(txtOverride3.tagName).toBe("INPUT");
    expect(txtOverride3.type).toBe("text");
    expect(txtOverride3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride3, "1");
    });
  });
  test("Custom Test Cases for txtOverride3", () => {
    // START_USER_CODE-USER_txtOverride3_TEST
    // END_USER_CODE-USER_txtOverride3_TEST
  });
  test("txtOverride4(Textbox Widget) Test Cases", async () => {
    const txtOverride4 = screen.getByTestId("txtOverride4");
    expect(txtOverride4.tagName).toBe("INPUT");
    expect(txtOverride4.type).toBe("text");
    expect(txtOverride4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride4, "1");
    });
  });
  test("Custom Test Cases for txtOverride4", () => {
    // START_USER_CODE-USER_txtOverride4_TEST
    // END_USER_CODE-USER_txtOverride4_TEST
  });
  test("txtOverride5(Textbox Widget) Test Cases", async () => {
    const txtOverride5 = screen.getByTestId("txtOverride5");
    expect(txtOverride5.tagName).toBe("INPUT");
    expect(txtOverride5.type).toBe("text");
    expect(txtOverride5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride5, "1");
    });
  });
  test("Custom Test Cases for txtOverride5", () => {
    // START_USER_CODE-USER_txtOverride5_TEST
    // END_USER_CODE-USER_txtOverride5_TEST
  });
  test("txtOverride6(Textbox Widget) Test Cases", async () => {
    const txtOverride6 = screen.getByTestId("txtOverride6");
    expect(txtOverride6.tagName).toBe("INPUT");
    expect(txtOverride6.type).toBe("text");
    expect(txtOverride6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride6, "1");
    });
  });
  test("Custom Test Cases for txtOverride6", () => {
    // START_USER_CODE-USER_txtOverride6_TEST
    // END_USER_CODE-USER_txtOverride6_TEST
  });
  test("txtOverride7(Textbox Widget) Test Cases", async () => {
    const txtOverride7 = screen.getByTestId("txtOverride7");
    expect(txtOverride7.tagName).toBe("INPUT");
    expect(txtOverride7.type).toBe("text");
    expect(txtOverride7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride7, "1");
    });
  });
  test("Custom Test Cases for txtOverride7", () => {
    // START_USER_CODE-USER_txtOverride7_TEST
    // END_USER_CODE-USER_txtOverride7_TEST
  });
  test("txtOverride8(Textbox Widget) Test Cases", async () => {
    const txtOverride8 = screen.getByTestId("txtOverride8");
    expect(txtOverride8.tagName).toBe("INPUT");
    expect(txtOverride8.type).toBe("text");
    expect(txtOverride8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride8, "1");
    });
  });
  test("Custom Test Cases for txtOverride8", () => {
    // START_USER_CODE-USER_txtOverride8_TEST
    // END_USER_CODE-USER_txtOverride8_TEST
  });
  test("txtOverride9(Textbox Widget) Test Cases", async () => {
    const txtOverride9 = screen.getByTestId("txtOverride9");
    expect(txtOverride9.tagName).toBe("INPUT");
    expect(txtOverride9.type).toBe("text");
    expect(txtOverride9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride9, "1");
    });
  });
  test("Custom Test Cases for txtOverride9", () => {
    // START_USER_CODE-USER_txtOverride9_TEST
    // END_USER_CODE-USER_txtOverride9_TEST
  });
  test("txtPayFreightInd(Textbox Widget) Test Cases", async () => {
    const txtPayFreightInd = screen.getByTestId("txtPayFreightInd");
    expect(txtPayFreightInd.tagName).toBe("INPUT");
    expect(txtPayFreightInd.type).toBe("text");
    expect(txtPayFreightInd.classList).toContain("textboxWidgetClass");
    expect(txtPayFreightInd.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_txtPayFreightInd")
    );
    await act(async () => {
      userEvent.type(txtPayFreightInd, "1");
    });
  });
  test("Custom Test Cases for txtPayFreightInd", () => {
    // START_USER_CODE-USER_txtPayFreightInd_TEST
    // END_USER_CODE-USER_txtPayFreightInd_TEST
  });
  test("txtTransferNum0(Textbox Widget) Test Cases", async () => {
    const txtTransferNum0 = screen.getByTestId("txtTransferNum0");
    expect(txtTransferNum0.tagName).toBe("INPUT");
    expect(txtTransferNum0.type).toBe("text");
    expect(txtTransferNum0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum0, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum0", () => {
    // START_USER_CODE-USER_txtTransferNum0_TEST
    // END_USER_CODE-USER_txtTransferNum0_TEST
  });
  test("txtTransferNum1(Textbox Widget) Test Cases", async () => {
    const txtTransferNum1 = screen.getByTestId("txtTransferNum1");
    expect(txtTransferNum1.tagName).toBe("INPUT");
    expect(txtTransferNum1.type).toBe("text");
    expect(txtTransferNum1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum1, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum1", () => {
    // START_USER_CODE-USER_txtTransferNum1_TEST
    // END_USER_CODE-USER_txtTransferNum1_TEST
  });
  test("txtTransferNum2(Textbox Widget) Test Cases", async () => {
    const txtTransferNum2 = screen.getByTestId("txtTransferNum2");
    expect(txtTransferNum2.tagName).toBe("INPUT");
    expect(txtTransferNum2.type).toBe("text");
    expect(txtTransferNum2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum2, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum2", () => {
    // START_USER_CODE-USER_txtTransferNum2_TEST
    // END_USER_CODE-USER_txtTransferNum2_TEST
  });
  test("txtTransferNum3(Textbox Widget) Test Cases", async () => {
    const txtTransferNum3 = screen.getByTestId("txtTransferNum3");
    expect(txtTransferNum3.tagName).toBe("INPUT");
    expect(txtTransferNum3.type).toBe("text");
    expect(txtTransferNum3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum3, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum3", () => {
    // START_USER_CODE-USER_txtTransferNum3_TEST
    // END_USER_CODE-USER_txtTransferNum3_TEST
  });
  test("txtTransferNum4(Textbox Widget) Test Cases", async () => {
    const txtTransferNum4 = screen.getByTestId("txtTransferNum4");
    expect(txtTransferNum4.tagName).toBe("INPUT");
    expect(txtTransferNum4.type).toBe("text");
    expect(txtTransferNum4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum4, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum4", () => {
    // START_USER_CODE-USER_txtTransferNum4_TEST
    // END_USER_CODE-USER_txtTransferNum4_TEST
  });
  test("txtTransferNum5(Textbox Widget) Test Cases", async () => {
    const txtTransferNum5 = screen.getByTestId("txtTransferNum5");
    expect(txtTransferNum5.tagName).toBe("INPUT");
    expect(txtTransferNum5.type).toBe("text");
    expect(txtTransferNum5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum5, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum5", () => {
    // START_USER_CODE-USER_txtTransferNum5_TEST
    // END_USER_CODE-USER_txtTransferNum5_TEST
  });
  test("txtTransferNum6(Textbox Widget) Test Cases", async () => {
    const txtTransferNum6 = screen.getByTestId("txtTransferNum6");
    expect(txtTransferNum6.tagName).toBe("INPUT");
    expect(txtTransferNum6.type).toBe("text");
    expect(txtTransferNum6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum6, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum6", () => {
    // START_USER_CODE-USER_txtTransferNum6_TEST
    // END_USER_CODE-USER_txtTransferNum6_TEST
  });
  test("txtTransferNum7(Textbox Widget) Test Cases", async () => {
    const txtTransferNum7 = screen.getByTestId("txtTransferNum7");
    expect(txtTransferNum7.tagName).toBe("INPUT");
    expect(txtTransferNum7.type).toBe("text");
    expect(txtTransferNum7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum7, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum7", () => {
    // START_USER_CODE-USER_txtTransferNum7_TEST
    // END_USER_CODE-USER_txtTransferNum7_TEST
  });
  test("txtTransferNum8(Textbox Widget) Test Cases", async () => {
    const txtTransferNum8 = screen.getByTestId("txtTransferNum8");
    expect(txtTransferNum8.tagName).toBe("INPUT");
    expect(txtTransferNum8.type).toBe("text");
    expect(txtTransferNum8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum8, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum8", () => {
    // START_USER_CODE-USER_txtTransferNum8_TEST
    // END_USER_CODE-USER_txtTransferNum8_TEST
  });
  test("txtTransferNum9(Textbox Widget) Test Cases", async () => {
    const txtTransferNum9 = screen.getByTestId("txtTransferNum9");
    expect(txtTransferNum9.tagName).toBe("INPUT");
    expect(txtTransferNum9.type).toBe("text");
    expect(txtTransferNum9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTransferNum9, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum9", () => {
    // START_USER_CODE-USER_txtTransferNum9_TEST
    // END_USER_CODE-USER_txtTransferNum9_TEST
  });
  test("txtTripNum(Textbox Widget) Test Cases", async () => {
    const txtTripNum = screen.getByTestId("txtTripNum");
    expect(txtTripNum.tagName).toBe("INPUT");
    expect(txtTripNum.type).toBe("text");
    expect(txtTripNum.classList).toContain("textboxWidgetClass");
    expect(txtTripNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_txtTripNum")
    );
    await act(async () => {
      userEvent.type(txtTripNum, "1");
    });
  });
  test("Custom Test Cases for txtTripNum", () => {
    // START_USER_CODE-USER_txtTripNum_TEST
    // END_USER_CODE-USER_txtTripNum_TEST
  });
  test("txtVehicleNum(Textbox Widget) Test Cases", async () => {
    const txtVehicleNum = screen.getByTestId("txtVehicleNum");
    expect(txtVehicleNum.tagName).toBe("INPUT");
    expect(txtVehicleNum.type).toBe("text");
    expect(txtVehicleNum.classList).toContain("textboxWidgetClass");
    expect(txtVehicleNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_txtVehicleNum")
    );
    await act(async () => {
      userEvent.type(txtVehicleNum, "1");
    });
  });
  test("Custom Test Cases for txtVehicleNum", () => {
    // START_USER_CODE-USER_txtVehicleNum_TEST
    // END_USER_CODE-USER_txtVehicleNum_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
