/* eslint-disable*/
import React from "react";
import AccountPayment_CheckInquiry from "./CheckInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CheckInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_CheckInquiry />);
    });
  });
  afterEach(cleanup);
  test("is CheckInquiry Loads Successfully", () => {
    expect(screen.getByText("CheckInquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for CheckInquiry", () => {
    // START_USER_CODE-USER_CheckInquiry_Custom_Test_Case
    // END_USER_CODE-USER_CheckInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_CheckInquiry />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(t("AccountPayment:CheckInquiry_btn2"));
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCorrect(Button Widget) Test Cases", async () => {
    const btnCorrect = screen.getByTestId("btnCorrect");
    expect(btnCorrect).toBeInTheDocument;
    expect(btnCorrect.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnCorrect")
    );
  });
  test("Custom Test Cases for btnCorrect", () => {
    // START_USER_CODE-USER_btnCorrect_TEST
    // END_USER_CODE-USER_btnCorrect_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnReview(Button Widget) Test Cases", async () => {
    const btnReview = screen.getByTestId("btnReview");
    expect(btnReview).toBeInTheDocument;
    expect(btnReview.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnReview")
    );
  });
  test("Custom Test Cases for btnReview", () => {
    // START_USER_CODE-USER_btnReview_TEST
    // END_USER_CODE-USER_btnReview_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("AccountPayment:CheckInquiry_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("gridSearchResults(Grid Widget) Test Cases", async () => {
    let gridSearchResults = screen.getByTestId("gridSearchResults");
    let gridSearchResultsbtn =
      gridSearchResults.nextElementSibling.firstElementChild;
    gridSearchResults =
      gridSearchResults.parentElement.parentElement.parentElement;
    expect(gridSearchResults.tagName).toBe("DIV");
    expect(gridSearchResults.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSearchResults", () => {
    // START_USER_CODE-USER_gridSearchResults_TEST
    // END_USER_CODE-USER_gridSearchResults_TEST
  });
  test("grpbxbtn1(GroupBox Widget) Test Cases", async () => {
    const grpbxbtn1 = screen.getByTestId("grpbxbtn1");
    expect(grpbxbtn1.tagName).toBe("BUTTON");
    expect(grpbxbtn1.type).toBe("button");
    expect(grpbxbtn1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxbtn1", () => {
    // START_USER_CODE-USER_grpbxbtn1_TEST
    // END_USER_CODE-USER_grpbxbtn1_TEST
  });
  test("grpbxCheckDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckDetails1 = screen.getByTestId("grpbxCheckDetails1");
    expect(grpbxCheckDetails1.tagName).toBe("BUTTON");
    expect(grpbxCheckDetails1.type).toBe("button");
    expect(grpbxCheckDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckDetails1", () => {
    // START_USER_CODE-USER_grpbxCheckDetails1_TEST
    // END_USER_CODE-USER_grpbxCheckDetails1_TEST
  });
  test("grpbxCheckDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckDetails2 = screen.getByTestId("grpbxCheckDetails2");
    expect(grpbxCheckDetails2.tagName).toBe("BUTTON");
    expect(grpbxCheckDetails2.type).toBe("button");
    expect(grpbxCheckDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckDetails2", () => {
    // START_USER_CODE-USER_grpbxCheckDetails2_TEST
    // END_USER_CODE-USER_grpbxCheckDetails2_TEST
  });
  test("grpbxSearchResults(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchResults = screen.getByTestId("grpbxSearchResults");
    expect(grpbxSearchResults.tagName).toBe("BUTTON");
    expect(grpbxSearchResults.type).toBe("button");
    expect(grpbxSearchResults.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchResults", () => {
    // START_USER_CODE-USER_grpbxSearchResults_TEST
    // END_USER_CODE-USER_grpbxSearchResults_TEST
  });
  test("lblCheckProceeds(Label Widget) Test Cases", async () => {
    const lblCheckProceeds = screen.getByTestId("lblCheckProceeds");
    expect(lblCheckProceeds.tagName).toBe("LABEL");
    expect(lblCheckProceeds.classList).toContain("form-label");
    expect(lblCheckProceeds.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblCheckProceeds")
    );
  });
  test("Custom Test Cases for lblCheckProceeds", () => {
    // START_USER_CODE-USER_lblCheckProceeds_TEST
    // END_USER_CODE-USER_lblCheckProceeds_TEST
  });
  test("lblCheckProceedsValue(Label Widget) Test Cases", async () => {
    const lblCheckProceedsValue = screen.getByTestId("lblCheckProceedsValue");
    expect(lblCheckProceedsValue.tagName).toBe("LABEL");
    expect(lblCheckProceedsValue.classList).toContain("form-label");
    expect(lblCheckProceedsValue.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblCheckProceedsValue")
    );
  });
  test("Custom Test Cases for lblCheckProceedsValue", () => {
    // START_USER_CODE-USER_lblCheckProceedsValue_TEST
    // END_USER_CODE-USER_lblCheckProceedsValue_TEST
  });
  test("lblGrandTotal(Label Widget) Test Cases", async () => {
    const lblGrandTotal = screen.getByTestId("lblGrandTotal");
    expect(lblGrandTotal.tagName).toBe("LABEL");
    expect(lblGrandTotal.classList).toContain("form-label");
    expect(lblGrandTotal.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblGrandTotal")
    );
  });
  test("Custom Test Cases for lblGrandTotal", () => {
    // START_USER_CODE-USER_lblGrandTotal_TEST
    // END_USER_CODE-USER_lblGrandTotal_TEST
  });
  test("lblGrandTotalValue(Label Widget) Test Cases", async () => {
    const lblGrandTotalValue = screen.getByTestId("lblGrandTotalValue");
    expect(lblGrandTotalValue.tagName).toBe("LABEL");
    expect(lblGrandTotalValue.classList).toContain("form-label");
    expect(lblGrandTotalValue.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblGrandTotalValue")
    );
  });
  test("Custom Test Cases for lblGrandTotalValue", () => {
    // START_USER_CODE-USER_lblGrandTotalValue_TEST
    // END_USER_CODE-USER_lblGrandTotalValue_TEST
  });
  test("lblSearchResults(Label Widget) Test Cases", async () => {
    const lblSearchResults = screen.getByTestId("lblSearchResults");
    expect(lblSearchResults.tagName).toBe("LABEL");
    expect(lblSearchResults.classList).toContain("form-label");
    expect(lblSearchResults.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblSearchResults")
    );
  });
  test("Custom Test Cases for lblSearchResults", () => {
    // START_USER_CODE-USER_lblSearchResults_TEST
    // END_USER_CODE-USER_lblSearchResults_TEST
  });
  test("lblSystemVoids(Label Widget) Test Cases", async () => {
    const lblSystemVoids = screen.getByTestId("lblSystemVoids");
    expect(lblSystemVoids.tagName).toBe("LABEL");
    expect(lblSystemVoids.classList).toContain("form-label");
    expect(lblSystemVoids.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblSystemVoids")
    );
  });
  test("Custom Test Cases for lblSystemVoids", () => {
    // START_USER_CODE-USER_lblSystemVoids_TEST
    // END_USER_CODE-USER_lblSystemVoids_TEST
  });
  test("lblSystemVoidsValue(Label Widget) Test Cases", async () => {
    const lblSystemVoidsValue = screen.getByTestId("lblSystemVoidsValue");
    expect(lblSystemVoidsValue.tagName).toBe("LABEL");
    expect(lblSystemVoidsValue.classList).toContain("form-label");
    expect(lblSystemVoidsValue.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblSystemVoidsValue")
    );
  });
  test("Custom Test Cases for lblSystemVoidsValue", () => {
    // START_USER_CODE-USER_lblSystemVoidsValue_TEST
    // END_USER_CODE-USER_lblSystemVoidsValue_TEST
  });
  test("lblWireTransfers(Label Widget) Test Cases", async () => {
    const lblWireTransfers = screen.getByTestId("lblWireTransfers");
    expect(lblWireTransfers.tagName).toBe("LABEL");
    expect(lblWireTransfers.classList).toContain("form-label");
    expect(lblWireTransfers.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblWireTransfers")
    );
  });
  test("Custom Test Cases for lblWireTransfers", () => {
    // START_USER_CODE-USER_lblWireTransfers_TEST
    // END_USER_CODE-USER_lblWireTransfers_TEST
  });
  test("lblWireTransfersValue(Label Widget) Test Cases", async () => {
    const lblWireTransfersValue = screen.getByTestId("lblWireTransfersValue");
    expect(lblWireTransfersValue.tagName).toBe("LABEL");
    expect(lblWireTransfersValue.classList).toContain("form-label");
    expect(lblWireTransfersValue.textContent).toEqual(
      t("AccountPayment:CheckInquiry_lblWireTransfersValue")
    );
  });
  test("Custom Test Cases for lblWireTransfersValue", () => {
    // START_USER_CODE-USER_lblWireTransfersValue_TEST
    // END_USER_CODE-USER_lblWireTransfersValue_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("gridSearchResults_txt2(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txt2 = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txt2btn =
      gridSearchResults_txt2.nextElementSibling.firstElementChild;
    gridSearchResults_txt2 =
      gridSearchResults_txt2.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txt2.tagName).toBe("DIV");
    expect(gridSearchResults_txt2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt2", () => {
    // START_USER_CODE-USER_txt2_TEST
    // END_USER_CODE-USER_txt2_TEST
  });
  test("txtCarrierAbbr(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbr = screen.getByTestId("txtCarrierAbbr");
    expect(txtCarrierAbbr.tagName).toBe("INPUT");
    expect(txtCarrierAbbr.type).toBe("text");
    expect(txtCarrierAbbr.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbr.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtCarrierAbbr")
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbr, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbr", () => {
    // START_USER_CODE-USER_txtCarrierAbbr_TEST
    // END_USER_CODE-USER_txtCarrierAbbr_TEST
  });
  test("txtCheck(Textbox Widget) Test Cases", async () => {
    const txtCheck = screen.getByTestId("txtCheck");
    expect(txtCheck.tagName).toBe("INPUT");
    expect(txtCheck.type).toBe("text");
    expect(txtCheck.classList).toContain("textboxWidgetClass");
    expect(txtCheck.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtCheck")
    );
    await act(async () => {
      userEvent.type(txtCheck, "1");
    });
  });
  test("Custom Test Cases for txtCheck", () => {
    // START_USER_CODE-USER_txtCheck_TEST
    // END_USER_CODE-USER_txtCheck_TEST
  });
  test("gridSearchResults_txtcolCheck(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolCheck = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolCheckbtn =
      gridSearchResults_txtcolCheck.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolCheck =
      gridSearchResults_txtcolCheck.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolCheck.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolCheck.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCheck", () => {
    // START_USER_CODE-USER_txtcolCheck_TEST
    // END_USER_CODE-USER_txtcolCheck_TEST
  });
  test("gridSearchResults_txtcolIssueDate(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolIssueDate = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolIssueDatebtn =
      gridSearchResults_txtcolIssueDate.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolIssueDate =
      gridSearchResults_txtcolIssueDate.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolIssueDate.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolIssueDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIssueDate", () => {
    // START_USER_CODE-USER_txtcolIssueDate_TEST
    // END_USER_CODE-USER_txtcolIssueDate_TEST
  });
  test("gridSearchResults_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolProceeds = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolProceedsbtn =
      gridSearchResults_txtcolProceeds.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolProceeds =
      gridSearchResults_txtcolProceeds.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolProceeds.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolProceeds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridSearchResults_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolStatus = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolStatusbtn =
      gridSearchResults_txtcolStatus.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolStatus =
      gridSearchResults_txtcolStatus.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolStatus.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridSearchResults_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolVendor = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolVendorbtn =
      gridSearchResults_txtcolVendor.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolVendor =
      gridSearchResults_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolVendor.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridSearchResults_txtcolWireAcknowledgement(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolWireAcknowledgement = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolWireAcknowledgementbtn =
      gridSearchResults_txtcolWireAcknowledgement.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolWireAcknowledgement =
      gridSearchResults_txtcolWireAcknowledgement.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolWireAcknowledgement.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolWireAcknowledgement.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWireAcknowledgement", () => {
    // START_USER_CODE-USER_txtcolWireAcknowledgement_TEST
    // END_USER_CODE-USER_txtcolWireAcknowledgement_TEST
  });
  test("gridSearchResults_txtcolWireConfirmation(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolWireConfirmation = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolWireConfirmationbtn =
      gridSearchResults_txtcolWireConfirmation.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolWireConfirmation =
      gridSearchResults_txtcolWireConfirmation.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolWireConfirmation.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolWireConfirmation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWireConfirmation", () => {
    // START_USER_CODE-USER_txtcolWireConfirmation_TEST
    // END_USER_CODE-USER_txtcolWireConfirmation_TEST
  });
  test("gridSearchResults_txtcolWireXferData(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolWireXferData = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolWireXferDatabtn =
      gridSearchResults_txtcolWireXferData.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolWireXferData =
      gridSearchResults_txtcolWireXferData.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolWireXferData.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolWireXferData.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("AccountPayment:CheckInquiry_gridSearchResults"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWireXferData", () => {
    // START_USER_CODE-USER_txtcolWireXferData_TEST
    // END_USER_CODE-USER_txtcolWireXferData_TEST
  });
  test("txtEndDate(Textbox Widget) Test Cases", async () => {
    const txtEndDate = screen.getByTestId("txtEndDate");
    expect(txtEndDate.tagName).toBe("INPUT");
    expect(txtEndDate.type).toBe("text");
    expect(txtEndDate.classList).toContain("textboxWidgetClass");
    expect(txtEndDate.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtEndDate")
    );
    await act(async () => {
      userEvent.type(txtEndDate, "1");
    });
  });
  test("Custom Test Cases for txtEndDate", () => {
    // START_USER_CODE-USER_txtEndDate_TEST
    // END_USER_CODE-USER_txtEndDate_TEST
  });
  test("txtInvoice(Textbox Widget) Test Cases", async () => {
    const txtInvoice = screen.getByTestId("txtInvoice");
    expect(txtInvoice.tagName).toBe("INPUT");
    expect(txtInvoice.type).toBe("text");
    expect(txtInvoice.classList).toContain("textboxWidgetClass");
    expect(txtInvoice.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtInvoice")
    );
    await act(async () => {
      userEvent.type(txtInvoice, "1");
    });
  });
  test("Custom Test Cases for txtInvoice", () => {
    // START_USER_CODE-USER_txtInvoice_TEST
    // END_USER_CODE-USER_txtInvoice_TEST
  });
  test("txtMemo(Textbox Widget) Test Cases", async () => {
    const txtMemo = screen.getByTestId("txtMemo");
    expect(txtMemo.tagName).toBe("INPUT");
    expect(txtMemo.type).toBe("text");
    expect(txtMemo.classList).toContain("textboxWidgetClass");
    expect(txtMemo.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtMemo")
    );
    await act(async () => {
      userEvent.type(txtMemo, "1");
    });
  });
  test("Custom Test Cases for txtMemo", () => {
    // START_USER_CODE-USER_txtMemo_TEST
    // END_USER_CODE-USER_txtMemo_TEST
  });
  test("txtPayAmount(Textbox Widget) Test Cases", async () => {
    const txtPayAmount = screen.getByTestId("txtPayAmount");
    expect(txtPayAmount.tagName).toBe("INPUT");
    expect(txtPayAmount.type).toBe("text");
    expect(txtPayAmount.classList).toContain("textboxWidgetClass");
    expect(txtPayAmount.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtPayAmount")
    );
    await act(async () => {
      userEvent.type(txtPayAmount, "1");
    });
  });
  test("Custom Test Cases for txtPayAmount", () => {
    // START_USER_CODE-USER_txtPayAmount_TEST
    // END_USER_CODE-USER_txtPayAmount_TEST
  });
  test("txtStartDate(Textbox Widget) Test Cases", async () => {
    const txtStartDate = screen.getByTestId("txtStartDate");
    expect(txtStartDate.tagName).toBe("INPUT");
    expect(txtStartDate.type).toBe("text");
    expect(txtStartDate.classList).toContain("textboxWidgetClass");
    expect(txtStartDate.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtStartDate")
    );
    await act(async () => {
      userEvent.type(txtStartDate, "1");
    });
  });
  test("Custom Test Cases for txtStartDate", () => {
    // START_USER_CODE-USER_txtStartDate_TEST
    // END_USER_CODE-USER_txtStartDate_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckInquiry_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
