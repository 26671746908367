/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  goTo,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  disable,
  enable,
  setValue,
  getValue,
  setData,
  disableGridColumn,
  enableGridColumn,
  hideGridHeader,
  showGridHeader,
  getSelectedGridValue
} from "../../shared/WindowImports";

import "./AddendumSeedGrower2009.scss";
import { ContractManagementService } from "../Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CommonContext from '../../Store/CommonContext';
// import { getData } from "../../Common/ApiService";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_AddendumSeedGrower2009(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let ruleforchk = false ;
  let states = {
    Label: "Seed Grower Agreement",
    windowName: "AddendumSeedGrower2009",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.AddendumSeedGrower2009",
    // START_USER_CODE-USER_AddendumSeedGrower2009_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Seed Grower Agreement",
      scrCode: "PN1010B",
      year: "2021"
    },
    // END_USER_CODE-USER_AddendumSeedGrower2009_PROPERTIES
    btnApplications: {
      name: "btnApplications",
      type: "ButtonWidget",
      parent: "commandcontainerwidget21",
      Label: "Applications",
      CharWidth: "26",
      // START_USER_CODE-USER_btnApplications_PROPERTIES

      // END_USER_CODE-USER_btnApplications_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "commandcontainerwidget21",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "commandcontainerwidget21",
      Label: "Update",
      CharWidth: "15",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    chkPeanutVar: {
      name: "chkPeanutVar",
      type: "CheckBoxWidget",
      parent: "grpSeedGrower",
      Label: "Peanut Varities To Be Determined",
      ColSpan: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkPeanutVar_PROPERTIES

      // END_USER_CODE-USER_chkPeanutVar_PROPERTIES
    },
    drpColGenerationProd: {
      name: "drpColGenerationProd",
      type: "GridColumnWidget",
      parent: "gridPeanut",
      Label: "Generation Produced",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_drpColGenerationProd_PROPERTIES

      // END_USER_CODE-USER_drpColGenerationProd_PROPERTIES
    },
    drpColPeanutVariety: {
      name: "drpColPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridPeanut",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_drpColPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_drpColPeanutVariety_PROPERTIES
    },
    drpGenerationProd: {
      name: "drpGenerationProd",
      type: "DropDownFieldWidget",
      colName: "drpColGenerationProd",
      parent: "gridPeanut",
      Label: "Generation Produced",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpGenerationProd_PROPERTIES

      // END_USER_CODE-USER_drpGenerationProd_PROPERTIES
    },
    drpPeanutVariety: {
      name: "drpPeanutVariety",
      type: "DropDownFieldWidget",
      colName: "drpColPeanutVariety",
      parent: "gridPeanut",
      Label: "Peanut Variety",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_drpPeanutVariety_PROPERTIES
    },
    drpSeedPremium: {
      name: "drpSeedPremium",
      type: "DropDownFieldWidget",
      parent: "grpSeedGrower",
      Label: "Seed Premium:",
      DataProviderForDropDown: "inline",
      Options: "",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpSeedPremium_PROPERTIES

      // END_USER_CODE-USER_drpSeedPremium_PROPERTIES
    },
    gridPeanut: {
      name: "gridPeanut",
      type: "GridWidget",
      parent: "grpSeedGrower",
      gridCellsOrder: "drpPeanutVariety,drpGenerationProd,txtApplied,txtStatus",
      Pagination: false,
      isEditable: true,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPeanut_PROPERTIES
      disablePopOverActions:false,
      // END_USER_CODE-USER_gridPeanut_PROPERTIES
    },
    grpTotalApp: {
      name: "grpTotalApp",
      type: "GroupBoxWidget",
      parent: "grpSeedGrower",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpTotalApp_PROPERTIES

      // END_USER_CODE-USER_grpTotalApp_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpSeedGrower",
      Label: "per ton",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    txtApplied: {
      name: "txtApplied",
      type: "TextBoxWidget",
      colName: "txtColApplied",
      parent: "gridPeanut",
      Label: "Applied",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtApplied_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtApplied_PROPERTIES
    },
    txtColApplied: {
      name: "txtColApplied",
      type: "GridColumnWidget",
      parent: "gridPeanut",
      Label: "Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColApplied_PROPERTIES

      // END_USER_CODE-USER_txtColApplied_PROPERTIES
    },
    txtColStatus: {
      name: "txtColStatus",
      type: "GridColumnWidget",
      parent: "gridPeanut",
      VisibleForLargeDesktop: false,
      VisibleForTabLandscape: false,
      VisibleForTabPotrait: false,
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      VisibleForMobile: false,
      Width: "",
      // START_USER_CODE-USER_txtColStatus_PROPERTIES

      // END_USER_CODE-USER_txtColStatus_PROPERTIES
    },
    txtCompany: {
      name: "txtCompany",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Company:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompany_PROPERTIES

      // END_USER_CODE-USER_txtCompany_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Contract:",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtContract2: {
      name: "txtContract2",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      ColSpan: "2",
      ReadOnly: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract2_PROPERTIES

      // END_USER_CODE-USER_txtContract2_PROPERTIES
    },
    txtOpenPounds: {
      name: "txtOpenPounds",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Open Pounds:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenPounds_PROPERTIES

      // END_USER_CODE-USER_txtOpenPounds_PROPERTIES
    },
    txtSeededAcres: {
      name: "txtSeededAcres",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Seeded Acres:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenPounds_PROPERTIES
      Enabled: true,
      ReadOnly: false
      // END_USER_CODE-USER_txtOpenPounds_PROPERTIES
    },
    txtSeededAcres2: {
      name: "txtSeededAcres2",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "",
      HasLabel: false,
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenPounds_PROPERTIES
      Enabled: true,
      ReadOnly: false
      // END_USER_CODE-USER_txtOpenPounds_PROPERTIES
    },
    txtSeededAcres3: {
      name: "txtSeededAcres3",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "",
      HasLabel: false,
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenPounds_PROPERTIES
      Enabled: true,
      ReadOnly: false
      // END_USER_CODE-USER_txtOpenPounds_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Peanut Type:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPounds: {
      name: "txtPounds",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Pounds:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds_PROPERTIES

      // END_USER_CODE-USER_txtPounds_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      colName: "txtColStatus",
      parent: "gridPeanut",
      CharWidth: "48",
      Height: "",
      Width: "",
      ReadOnly: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtTotApplied: {
      name: "txtTotApplied",
      type: "TextBoxWidget",
      parent: "grpTotalApp",
      Label: "Total Applied:",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotApplied_PROPERTIES

      // END_USER_CODE-USER_txtTotApplied_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpSeedGrower",
      Label: "Vendor:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxUpdate",
      Label: "Added By:",
      // ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxUpdate",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES

      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    grpSeedGrower: {
      name: "grpSeedGrower",
      type: "GroupBoxWidget",
      parent: "AddendumSeedGrower2009",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpSeedGrower_PROPERTIES

      // END_USER_CODE-USER_grpSeedGrower_PROPERTIES
    },
    commandcontainerwidget21: {
      name: "commandcontainerwidget21",
      type: "CommandContainerWidget",
      parent: "AddendumSeedGrower2009",
      Label: "commandcontainerwidget21",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_commandcontainerwidget21_PROPERTIES

      // END_USER_CODE-USER_commandcontainerwidget21_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ApplicationToContract": {},
        "ContractManagement#ContractProfile":{}
      },
      REVERSE: {
        "ContractManagement#ApplicationToContract": {},
        "ContractManagement#ContractProfile":{}
      },
    },
  };
  let _buttonServices = {ContractProfile: {},ApplicationToContract:{}};
  let _buttonNavigation = {
    btnUpdate: {
      DEFAULT: ["ContractManagement#ContractProfile#DEFAULT#true#Click"],
    },
    btnApplications: {
      DEFAULT: ["ContractManagement#ApplicationToContract#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad();
    Addenumseeddata()
    
    
    
    // GetPeanutprice(compId, cropYear, 'Option', peanutTypeIdvalue,1,'N','YT7',270001)
    // comp_id,crop_year,pricingtype,pnuttypeid,segtype,organicind,buyptid,contractnum,pnutvarietyid,oleicind,vendornum
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj,'seeddata')]);
  const FormLoad= async () => {
    let type = getValue(thisObj, "gridPeanut");
   
    CheckGridbox();
    Getgenerationproduced()
    GetPeanutprice()
  }


  function GetPeanutprice(){
    let lastdata = getData(thisObj, 'seeddata');
    let p = lastdata[0]
    let pricetype = p.pricingtype
    let contracttype
    if(pricetype == 'S'){
     contracttype = 'SEED'
    }
    ContractManagementService.RetrievePeanutPriceDetails(contracttype, p.peanutTypeId, p.seg, p.organicInd, p.buy_pt_id, p.txtContrctNo, p.pnutvarietyid, p.oleic_ind, p.vid).then(response => {
      let data = response;
      if (data.length > 0) {
        let js = [];
        let js2 = [];
        let description = '';
        for (let i = 0; i < data.length; i++) {
          if (data[i].pricePerTon !== null) {
            description = data[i].pricePerTon;
          }
          if (data[i].oleicInd !== null) {
            description = description + ' ' + data[i].oleicInd;
          }
          if (data[i].pricingTypeDesc !== null) {
            description = description + ' ' + data[i].pricingTypeDesc;
          }
          let obj = { key: data[i].recordKey, description: description }
          js.push(obj);
          let obj2 = {};
        }
        thisObj.setState(current => {
          return {
            ...current,
            drpSeedPremium: {
              ...state["drpSeedPremium"],
              valueList: js,
            }
          }
        })
      }
    })
  }

  function ConvertToDate(str) {
    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }

  const Addenumseeddata = async() => {
    let vendor_lookup = getData(thisObj, 'seeddata');
    let addendum_seed_growers = getData(thisObj, 'AddendumSeedGrower');
    let seedTBD = getData(thisObj, "msseedTBD");

    let contractdetails = await ContractManagementService.RetrieveContractDetails(vendor_lookup[0].txtContrctNo, 'PN1010');
    if (contractdetails.length > 0) {  //scenario = when editing contract
      let sHeader = contractdetails[0];
      let sPricing = sHeader.contract_pricings[0];
      //let txtSeededAcres2 =sPricing.price_limit_rec_key
      let sVendorSplits = sHeader.contract_vendor_splits;
      let adddatetime = sHeader.add_user + ' ' + sHeader.add_date_time
      let chgdatetime = sHeader.chg_user + '' + sHeader.chg_date_time
      //setValue(thisObj, 'txtSeededAcres2',txtSeededAcres2);
      thisObj.setFieldValue("txtVendor", vendor_lookup[0].vid + '-' + vendor_lookup[0].vendorname);
      setData(thisObj, "temp1", vendor_lookup[0].vid);
      thisObj.setFieldValue("txtCompany", sHeader.comp_name);
      thisObj.setFieldValue("txtContract", sHeader.contract_num + '-' + sHeader.revision_num);
      setValue(thisObj, 'txtAddedBy', sHeader.add_user + " " + ConvertToDate(sHeader.add_date_time));
      setValue(thisObj, 'txtChangedBy', sHeader.chg_user + " " + ConvertToDate(sHeader.chg_date_time));

      thisObj.setFieldValue("txtPeanutType", sHeader.pnut_type_name);
      await GetPeanutVarietyFilterdList(sHeader.buy_pt_id, sHeader.pnut_type_id);
      if (addendum_seed_growers !== null) {  // scenario = while editing , coming second time to page
        let seedgrowerdata = addendum_seed_growers;
        if (seedgrowerdata.length > 0) {
          let tempvar = 0
          for (let i = 0; i < seedgrowerdata.length; i++) {
            let abc = seedgrowerdata[i].appl_lbs
            if (parseFloat(abc) == NaN) {
              abc = 0
            }
            tempvar = parseFloat(tempvar) + parseFloat(abc)
          }
          thisObj.setFieldValue("txtPounds", seedgrowerdata[0].cont_lbs);
          thisObj.setFieldValue("txtOpenPounds", parseFloat(seedgrowerdata[0].cont_lbs) - parseFloat(tempvar));
          setValue(thisObj, "txtSeededAcres", seedgrowerdata[0].seed_acres);
          thisObj.setFieldValue("txtTotApplied", tempvar);
          setData(thisObj,"openpounds",tempvar);
          setValue(thisObj, "drpSeedPremium", seedgrowerdata[0].price_limit_rec_key)
          let seed_tbd = contractdetails[0].seed_tbd

          if (seed_tbd == "N") {
            setValue(thisObj, "chkPeanutVar", false);
          } else {
            setValue(thisObj, "chkPeanutVar", true);
            hideGridHeader(thisObj,"gridPeanut")
          }
          let gridvalue = []
          let gridobj = {}

          if (seedgrowerdata[0].pnut_variety_id !== 0) {
            for (var i = 0; i < seedgrowerdata.length; i++) {
              gridobj.drpGenerationProd = seedgrowerdata[i].gen_produced;  
              gridobj.drpPeanutVariety = seedgrowerdata[i].pnut_variety_id;  
              gridobj.rowID$ = i;
              gridobj.txtApplied = seedgrowerdata[i].appl_lbs;   //readonly
              gridobj.txtStatus = seedgrowerdata[i].gen_planted;
              gridvalue.push(gridobj)
              gridobj = {}
              if(seedgrowerdata[i].appl_lbs.toString()!=='0' && seedgrowerdata[i].appl_lbs.toString()!==undefined && seedgrowerdata[i].appl_lbs.toString()!==''){
                ruleforchk = true
              }
            }
          }
          setTimeout(() => {
            setValue(thisObj, "gridPeanut", gridvalue);
          }, 2000);
          
        } else {  //without grid
          thisObj.setFieldValue("txtPounds", contractdetails[0].cont_lbs);
          thisObj.setFieldValue("txtOpenPounds", parseFloat(contractdetails[0].cont_lbs)-parseFloat(contractdetails[0].appl_lbs));
          setValue(thisObj, "drpSeedPremium", contractdetails[0].prem_per_ton);
          setValue(thisObj, "txtSeededAcres", contractdetails[0].seed_acres);
          thisObj.setFieldValue("txtTotApplied", contractdetails[0].appl_lbs);
          setData(thisObj,"openpounds",contractdetails[0].appl_lbs)
          let seed_tbd = contractdetails[0].seed_tbd

          if (seed_tbd == "N") {
            setValue(thisObj, "chkPeanutVar", false);
          } else {
            setValue(thisObj, "chkPeanutVar", true);
            hideGridHeader(thisObj,"gridPeanut")
          }
          setValue(thisObj, "drpSeedPremium", contractdetails[0].price_limit_rec_key)
        }
      } else { // scenario = while editing , first time 
        let seedgrowerdata = sVendorSplits[0].addendum_seed_growers
        if (seedgrowerdata.length > 0) { //with grid
          let tempvar = 0
          for (let i = 0; i < seedgrowerdata.length; i++) {
            let abc = seedgrowerdata[i].appl_lbs
            if (parseFloat(abc) == NaN) {
              abc = 0
            }
            tempvar = parseFloat(tempvar) + parseFloat(abc)
          }
          thisObj.setFieldValue("txtPounds", seedgrowerdata[0].cont_lbs);
          thisObj.setFieldValue("txtOpenPounds", parseFloat(seedgrowerdata[0].cont_lbs)-parseFloat(tempvar));
          setValue(thisObj, "drpSeedPremium", seedgrowerdata[0].prem_per_ton);
          setValue(thisObj, "txtSeededAcres", seedgrowerdata[0].seed_acres);
          thisObj.setFieldValue("txtTotApplied", tempvar === 0 ? "0" : tempvar);
          setData(thisObj,"openpounds",tempvar)
          setValue(thisObj, "drpSeedPremium", seedgrowerdata[0].price_limit_rec_key);
          let seed_tbd = contractdetails[0].seed_tbd

          if (seed_tbd == "N") {
            setValue(thisObj, "chkPeanutVar", false);
          } else {
            setValue(thisObj, "chkPeanutVar", true);
            hideGridHeader(thisObj,"gridPeanut")
          }
          let gridvalue = []
          let gridobj = {}

          for (var i = 0; i < seedgrowerdata.length; i++) {
            gridobj.drpGenerationProd = seedgrowerdata[i].gen_produced;  //  working
            gridobj.drpPeanutVariety = seedgrowerdata[i].pnut_variety_id;  //not working
            gridobj.rowID$ = i;
            gridobj.txtApplied = seedgrowerdata[i].appl_lbs;   //readonly
            gridobj.txtStatus = seedgrowerdata[i].gen_planted;
            gridvalue.push(gridobj)
            gridobj = {}
            if(seedgrowerdata[i].appl_lbs.toString()!=='0' && seedgrowerdata[i].appl_lbs.toString()!==undefined && seedgrowerdata[i].appl_lbs.toString()!==''){
              ruleforchk = true
            }
          }
          setTimeout(() => {
            setValue(thisObj, "gridPeanut", gridvalue);
            setData(thisObj , "flagpound" , true)
          }, 2000);
          
        } else {  //without grid
          thisObj.setFieldValue("txtPounds", contractdetails[0].cont_lbs);
          thisObj.setFieldValue("txtOpenPounds", parseFloat(contractdetails[0].cont_lbs)-parseFloat(contractdetails[0].appl_lbs));
          setValue(thisObj, "drpSeedPremium", contractdetails[0].prem_per_ton);
          setValue(thisObj, "txtSeededAcres", contractdetails[0].seed_acres);
          thisObj.setFieldValue("txtTotApplied", contractdetails[0].appl_lbs);
          setData(thisObj,"openpounds",contractdetails[0].appl_lbs)
          let seed_tbd = contractdetails[0].seed_tbd

          if (seed_tbd == "N") {
            setValue(thisObj, "chkPeanutVar", false);
          } else {
            setValue(thisObj, "chkPeanutVar", true);
            hideGridHeader(thisObj,"gridPeanut")
          }
          setValue(thisObj, "drpSeedPremium", contractdetails[0].price_limit_rec_key)
        }
      }
    } else {  //scenaio = when creating contract 
      thisObj.setFieldValue("txtVendor", vendor_lookup[0].vid + '-' + vendor_lookup[0].vendorname);
      thisObj.setFieldValue("txtCompany", vendor_lookup[0].companyname);
      thisObj.setFieldValue("txtPounds",'0');
      thisObj.setFieldValue("txtOpenPounds",'0');
      thisObj.setFieldValue("txtTotApplied",'0');
      if (addendum_seed_growers !== null) {  //when creating second loading
        let seedgrowerdata = addendum_seed_growers;
        if (seedgrowerdata.length > 0) {
          let tempvar = 0
          for (let i = 0; i < seedgrowerdata.length; i++) {
            let abc = seedgrowerdata[i].appl_lbs
            if (parseFloat(abc) == NaN) {
              abc = 0
            }
            tempvar = parseFloat(tempvar) + parseFloat(abc)
          }
          thisObj.setFieldValue("txtPounds", seedgrowerdata[0].cont_lbs);
          thisObj.setFieldValue("txtOpenPounds", parseFloat(seedgrowerdata[0].cont_lbs) - parseFloat(tempvar));
          setValue(thisObj, "txtSeededAcres", seedgrowerdata[0].seed_acres);
          thisObj.setFieldValue("txtTotApplied", tempvar === 0 ? "0" : tempvar);
          setData(thisObj,"openpounds",tempvar);
          setValue(thisObj, "drpSeedPremium", seedgrowerdata[0].price_limit_rec_key);
          let seed_tbd = seedTBD;

          if (seed_tbd == false) {
            setValue(thisObj, "chkPeanutVar", false);
          } else {
            setValue(thisObj, "chkPeanutVar", true);
            hideGridHeader(thisObj,"gridPeanut")
          }
          let gridvalue = []
          let gridobj = {}

          if (seedgrowerdata[0].pnut_variety_id !== 0) {
            for (var i = 0; i < seedgrowerdata.length; i++) {
              gridobj.drpGenerationProd = seedgrowerdata[i].gen_produced;  // not working
              gridobj.drpPeanutVariety = seedgrowerdata[i].pnut_variety_id;  //not working
              gridobj.rowID$ = i;
              gridobj.txtApplied = seedgrowerdata[i].appl_lbs;   //readonly
              gridobj.txtStatus = seedgrowerdata[i].gen_planted;
              gridvalue.push(gridobj)
              gridobj = {}
              if(seedgrowerdata[i].appl_lbs.toString()!=='0' && seedgrowerdata[i].appl_lbs.toString()!==undefined && seedgrowerdata[i].appl_lbs.toString()!==''){
                ruleforchk = true
              }
            }
          }
          setTimeout(() => {
            setValue(thisObj, "gridPeanut", gridvalue);
          }, 2000);
        }
      }
      let peanutTypeIdvalue = vendor_lookup[0].peanutTypeId !== undefined ? vendor_lookup[0].peanutTypeId : 'RU'
      if (vendor_lookup[0].peanutTypeId === "RU") {
        thisObj.setFieldValue("txtPeanutType", 'Runner');
      }
      if (vendor_lookup[0].peanutTypeId === "VL") {
        thisObj.setFieldValue("txtPeanutType", 'Valencia');
      }
      if (vendor_lookup[0].peanutTypeId === "SP") {
        thisObj.setFieldValue("txtPeanutType", 'Spanish');
      }
      if (vendor_lookup[0].peanutTypeId === "VA") {
        thisObj.setFieldValue("txtPeanutType", 'Virginia');
      }
      GetPeanutVarietyFilterdList(vendor_lookup[0].buy_pt_id, vendor_lookup[0].peanutTypeId);
    }
    permissioncheck()
    checkboxrule()
  }

  function checkboxrule () {
   if(ruleforchk==true){
    disable(thisObj, 'chkPeanutVar')
   }
  }

  const Getgenerationproduced = () => {
    let js = [];
    js.push({ key: 'F', description: 'Foundation' });
    js.push({ key: 'R', description: 'Registered' });
    js.push({ key: 'C', description: 'Certified' });
    let str = "";
      for (let i in js) {
        str += js[i]["description"] + ":";
        str += js[i]["key"] + ",";
      }
      str = str.slice(0,str.length-1)
    // thisObj.setState(current => {
    //   return {
    //     ...current,
    //     drpGenerationProd: {
    //       ...state["drpGenerationProd"],
    //       Options : str
    //     }
    //   }
    // });
    thisObj.state.drpGenerationProd.Options = str;
    thisObj.state.drpGenerationProd.valueList = js;
  };

  function GetPeanutVarietyFilterdList( buy_pt_id,pnut_type_id) {
    ContractManagementService.RetrievePeanutVarietyControlFilteredDetails(buy_pt_id,pnut_type_id).then(response => {
      let data = response;
      let js = [];
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnuT_VARIETY_ID, description: data[i].pnuT_VARIETY_NAME }
        js.push(obj);
      }
      let str = "";
      for (let i in js) {
        str += js[i]["description"] + ":";
        str += js[i]["key"] + ",";
      }
      str = str.slice(0,str.length-1)
      // thisObj.setState(current => {
      //   return {
      //     ...current,
      //     drpPeanutVariety: {
      //       ...state["drpPeanutVariety"],
      //       Options : str
      //     }
      //   }
      // });
      thisObj.state.drpPeanutVariety.Options = str;
      thisObj.state.drpPeanutVariety.valueList = js;
    });
  }

  const CheckGridbox = () => {
    let valueGridbox = document.getElementById('chkPeanutVar').checked;
    if (valueGridbox ===true) {
      setValue(thisObj, "gridPeanut", []);
      thisObj.setState({
        ...state,
        gridPeanut: {
          ...state.gridPeanut,
          disablePopOverActions:true
        }
      })
      hideGridHeader(thisObj,"gridPeanut")
    } else {
      thisObj.setState({
        ...state,
        gridPeanut: {
          ...state.gridPeanut,
          disablePopOverActions:false
        }
      })
      showGridHeader(thisObj,"gridPeanut")
    }
  }

  const onchkPeanutVarChange = () => {
    CheckGridbox();
  };
  thisObj.onchkPeanutVarChange = onchkPeanutVarChange;

  const checkvalue = (drpPeanutVariety_, drpGenerationProd) => {
    if (drpPeanutVariety_ !== '' && drpPeanutVariety_ !== undefined) {
      if (drpGenerationProd !== '' && drpGenerationProd !== undefined) {
      }
      else {
        showMessage(thisObj, 'Generation Produced must be provided', false);
      }
    } else {
      showMessage(thisObj, 'Peanut variety must be entered', false);
    }
  }

  function genplanted() {
    let temp = getData(thisObj, "genplanted")
    if (temp !== 1) {
      return 'R'
    } else {
      let gridPeanut = getValue(thisObj, "gridPeanut");
      if (gridPeanut.length > 0) {
        return gridPeanut[0].drpGenerationProd
      }
    }
  }

  const onbtnUpdateClick = () => {
    try {
      let enteramount= getValue(thisObj,"txtPounds")
      let existingappliedval = thisObj.values.txtTotApplied
      if(enteramount<existingappliedval){
        showMessage(thisObj,"Pounds Contracted cannot be less than Total Applied Pounds.")
        return false;
      }
      setData(thisObj, "genplanted", 1)
      let drpSeedPremium = getValue(thisObj, "drpSeedPremium");
      let valueGridbox = document.getElementById('chkPeanutVar').checked;
      if (drpSeedPremium !== undefined && drpSeedPremium !== '') {
        if (valueGridbox !== true) {

          let gridPeanut = getValue(thisObj, "gridPeanut");
          let varification = 0
          let js = [];
          let obj = {}
          let seed_acres = getValue(thisObj, "txtSeededAcres")
          if (seed_acres == undefined) {
            seed_acres = ''
          }
          else {
            seed_acres = seed_acres.slice(0, seed_acres.indexOf('.'))
          }
          //obj.prem_per_ton = thisObj.values.drpSeedPremium

          //obj.chkPeanutVar=getValue(thisObj, "chkPeanutVar");

          let pricelist = thisObj.state['drpSeedPremium'].valueList;
          let prem_per_ton = '';
          let pricedrpkey = thisObj.values['drpSeedPremium'];
          if (pricedrpkey !== undefined && pricedrpkey !== null) {
            prem_per_ton = pricelist.find(elem => elem.key == pricedrpkey).description;
          }


          //new method
          let predata = getData(thisObj, "seeddata")
          let contractnum = predata[0].txtContrctNo
          let existString = '';
          if (contractnum == undefined || contractnum == null) {
            if (gridPeanut.length > 0) {
              for (let i = 0; i < gridPeanut.length; i++) {
                let exist = js.find(x => x.pnut_variety_id == gridPeanut[i].drpPeanutVariety 
                  && x.gen_produced == gridPeanut[i].drpGenerationProd);
                if (exist !== null && exist !== undefined) {
                  existString = 'row ' + exist.index + ' and ' + (i + 1);
                  break;
                }
                obj.index = i + 1;
                obj.seed_acres = seed_acres
                obj.prem_per_ton = prem_per_ton
                obj.price_limit_rec_key = pricedrpkey
                obj.pnut_variety_id = gridPeanut[i].drpPeanutVariety
                obj.gen_planted = genplanted()
                obj.gen_produced = gridPeanut[i].drpGenerationProd
                obj.cont_lbs = getValue(thisObj, "txtPounds")
                obj.appl_lbs = gridPeanut[i].txtApplied
                obj.tentative_lbs = 0
                js.push(obj)
                obj = {}
              }
            }
          } else {
            if (gridPeanut.length > 0) {
              for (let i = 0; i < gridPeanut.length; i++) {
                let exist = js.find(x => x.pnut_variety_id == gridPeanut[i].drpPeanutVariety 
                  && x.gen_produced == gridPeanut[i].drpGenerationProd);
                if (exist !== null && exist !== undefined) {
                  existString = 'row ' + exist.index + ' and ' + (i + 1);
                  break;
                }
                obj.seed_acres = seed_acres
                obj.prem_per_ton = prem_per_ton
                obj.price_limit_rec_key = pricedrpkey
                obj.pnut_variety_id = gridPeanut[i].drpPeanutVariety
                obj.gen_planted = genplanted()
                obj.gen_produced = gridPeanut[i].drpGenerationProd
                obj.cont_lbs = getValue(thisObj, "txtPounds")
                obj.appl_lbs = gridPeanut[i].txtApplied
                obj.tentative_lbs = 0
                js.push(obj)
                obj = {}
              }
            }
          }
          if (existString !== '') {
            showMessage(thisObj, 'Peanut variety and Generation produced for ' + existString + ' cannot be the same.', false);
            return false;
          }

          //js.push(obj)
          //obj = {}
          setData(thisObj, 'AddendumSeedGrower', js)
          let checkboxdata = getValue(thisObj, "chkPeanutVar")
          setData(thisObj, "msseedTBD", checkboxdata)
          setData(thisObj,"varietyselected",1)
          if (gridPeanut.length == 0) {
            showMessage(thisObj, 'Peanut variety must be entered', false);
          }
          if (gridPeanut.length > 0) {
            for (var i = 0; i < gridPeanut.length; i++) {

              let drpPeanutVariety0 = gridPeanut[0].drpPeanutVariety
              let drpGenerationProd0 = gridPeanut[0].drpGenerationProd
              if (gridPeanut.length == 1) {
                if (drpPeanutVariety0 !== "" && drpGenerationProd0 !== "") {
                  document.getElementById("ContractManagement_AddendumSeedGrower2009PopUp").childNodes[0].click();
                } else {
                  varification = 1
                  checkvalue(drpPeanutVariety0, drpGenerationProd0)
                }
              }
              if (gridPeanut.length > 1) {
                let drpPeanutVariety1 = gridPeanut[1].drpPeanutVariety
                let drpGenerationProd1 = gridPeanut[1].drpGenerationProd
                let drpPeanutVarietyi = gridPeanut[i].drpPeanutVariety
                let drpGenerationProdi = gridPeanut[i].drpGenerationProd


                if (drpPeanutVariety1 !== "" && drpGenerationProd1 !== "" && drpPeanutVarietyi !== '' && drpGenerationProdi!=='') {
                  //document.getElementById("ContractManagement_AddendumSeedGrower2009PopUp").childNodes[0].click();
                } else {
                  //if (drpPeanutVarietyi !== '') {
                    varification = 1
                    checkvalue(drpPeanutVarietyi, drpGenerationProdi)
                  //}

                }
                
                
              }
            }
            if(varification!==1){
              document.getElementById("ContractManagement_AddendumSeedGrower2009PopUp").childNodes[0].click();
            }
          }
        } else {
          let js = [];
          let obj = {};
          let seed_acres = getValue(thisObj, "txtSeededAcres");
          let pricelist = thisObj.state['drpSeedPremium'].valueList;
          let prem_per_ton = '';
          let pricedrpkey = thisObj.values['drpSeedPremium'];
          if (pricedrpkey !== undefined && pricedrpkey !== null) {
            prem_per_ton = pricelist.find(elem => elem.key == pricedrpkey).description;
          }
          if (seed_acres == undefined) {
            seed_acres = '';
          }
          obj.seed_acres = seed_acres;
          obj.prem_per_ton = prem_per_ton
          obj.price_limit_rec_key = pricedrpkey
          obj.pnut_variety_id = 0;
          obj.gen_planted = 0;
          obj.gen_produced = '';
          obj.cont_lbs = getValue(thisObj, "txtPounds");
          obj.appl_lbs = 0;
          obj.tentative_lbs = 0;
          js.push(obj);
          setData(thisObj, 'AddendumSeedGrower', js);
          let checkboxdata = getValue(thisObj, "chkPeanutVar");
          setData(thisObj, "msseedTBD", checkboxdata);

          document.getElementById("ContractManagement_AddendumSeedGrower2009PopUp").childNodes[0].click();
        }

      } else {
        showMessage(thisObj, 'Must Select the valid Premium Per ton amount', false);
      }

      //document.getElementById("ContractManagement_AddendumSeedGrower2009PopUp").childNodes[0].click();    

    }
    catch (err) {
      showMessage(thisObj, "error on update button click")
    }
  };
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const onbtnApplicationsClick = () => {
    try {
      // _buttonNavigation
      let js = [];
      let obj = {}
      obj.GstrCropYear = cropYear
      obj.GstrCompanyID = compId
      obj.lblContractNumber = commonContext.getContractProfile_ContractNumber
      obj.pricingLine = 0
      //obj.lblVendor =getValue(thisObj, "txtVendor")
      obj.lblVendor = getData(thisObj, "temp1")
      js.push(obj)
      obj = {}
      //setData(thisObj,'bFillPounds',js)
      commonContext.setbFillPounds(js)
      goTo(thisObj, "ContractManagement#ApplicationToContract#DEFAULT#true#Click")
    }
    catch (err) {
    }
  };
  thisObj.onbtnApplicationsClick = onbtnApplicationsClick;

const validateTextBox1 = (data) => {
  var res = ''
  for (var i = 0; i < data.length; i++) {
    if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)||(data[i].charCodeAt(0) == 46)) {
      res += data[i]
    }
  }
  return res;
}

const ontxtPoundsChange = () => {
  let txtPound = getValue(thisObj, "txtPounds");
  let validate = validateTextBox1(txtPound)
  let txtAppliedpound = getData(thisObj,"openpounds")
  setValue(thisObj,"txtPounds",validate)
  if(txtAppliedpound==''|| txtAppliedpound==undefined || txtAppliedpound==null || parseFloat(txtAppliedpound)==NaN){	
    txtAppliedpound = 0	
  }
  let openpound = parseFloat(validate) - parseFloat(txtAppliedpound)
  thisObj.setFieldValue("txtOpenPounds",openpound.toString());
}
thisObj.ontxtPoundsChange = ontxtPoundsChange;

  const onbtnCancelClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      document.getElementById("ContractManagement_AddendumSeedGrower2009PopUp").childNodes[0].click();
      
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  function permissioncheck() {
    if (getData(thisObj, "ReadOnly") == true) {
      disable(thisObj, 'chkPeanutVar')
      disable(thisObj, 'txtPounds')
      document.getElementById('drpSeedPremium').disabled = true;
      disableGridColumn(thisObj,"gridPeanut","drpPeanutVariety")
      disableGridColumn(thisObj,"gridPeanut","drpGenerationProd")
      hideGridHeader(thisObj,"gridPeanut")
    }

    let SecurityUpdateAfterSigned = localStorage.getItem('SecurityUpdateAfterSigned') == 'true' ? true : false;
    let ApplicationsExist = localStorage.getItem('ApplicationsExist') == 'true' ? true : false;
    if (SecurityUpdateAfterSigned == true) {
      if (ApplicationsExist && getData(thisObj, 'ReadOnly') == true) {
        disable(thisObj, 'chkPeanutVar')
        document.getElementById('drpSeedPremium').disabled = true;
        disableGridColumn(thisObj,"gridPeanut","drpPeanutVariety")
        disableGridColumn(thisObj,"gridPeanut","drpGenerationProd")
        hideGridHeader(thisObj,"gridPeanut")
        
      }
    }

  }

  const ongridPeanutRowSelect = () => {
    let value = getSelectedGridValue(thisObj,"gridPeanut","txtApplied");
    if(value<=0 || value == null){
      thisObj.state.gridPeanut.actionList[1].required = true;
      thisObj.state.gridPeanut.actionList[2].required = true;
      thisObj.state.gridPeanut.actionList[3].required = true;
    }
    else{
      thisObj.state.gridPeanut.actionList[1].required = false;
      thisObj.state.gridPeanut.actionList[2].required = false;
      thisObj.state.gridPeanut.actionList[3].required = false;
    }
  }
  thisObj.ongridPeanutRowSelect = ongridPeanutRowSelect;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData = {state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_AddendumSeedGrower2009*/}

              {/* END_USER_CODE-USER_BEFORE_AddendumSeedGrower2009*/}

              {/* START_USER_CODE-USER_BEFORE_grpSeedGrower*/}

              {/* END_USER_CODE-USER_BEFORE_grpSeedGrower*/}

              <GroupBoxWidget conf={state.grpSeedGrower} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtCompany*/}

                {/* END_USER_CODE-USER_BEFORE_txtCompany*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCompany}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCompany*/}

                {/* END_USER_CODE-USER_AFTER_txtCompany*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract2*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract2*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract2}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract2*/}

                {/* END_USER_CODE-USER_AFTER_txtContract2*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtPounds*/}
                {/* START_USER_CODE-USER_BEFORE_drpSeedPremium*/}

                {/* END_USER_CODE-USER_BEFORE_drpSeedPremium*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpSeedPremium}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpSeedPremium*/}

                {/* END_USER_CODE-USER_AFTER_drpSeedPremium*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerTon}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtOpenPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtOpenPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOpenPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOpenPounds*/}
                
                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeededAcres}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeededAcres2}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeededAcres3}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* END_USER_CODE-USER_AFTER_txtOpenPounds*/}
                {/* START_USER_CODE-USER_BEFORE_chkPeanutVar*/}

                {/* END_USER_CODE-USER_BEFORE_chkPeanutVar*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkPeanutVar}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkPeanutVar*/}

                {/* END_USER_CODE-USER_AFTER_chkPeanutVar*/}
                {/* START_USER_CODE-USER_BEFORE_gridPeanut*/}

                {/* END_USER_CODE-USER_BEFORE_gridPeanut*/}

                <GridWidget
                  conf={state.gridPeanut}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPeanut}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPeanut*/}

                {/* END_USER_CODE-USER_AFTER_gridPeanut*/}
                {/* START_USER_CODE-USER_BEFORE_grpTotalApp*/}

                {/* END_USER_CODE-USER_BEFORE_grpTotalApp*/}

                <GroupBoxWidget conf={state.grpTotalApp} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtTotApplied*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotApplied*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotApplied}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotApplied*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotApplied*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpTotalApp*/}

                {/* END_USER_CODE-USER_AFTER_grpTotalApp*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpSeedGrower*/}

              {/* END_USER_CODE-USER_AFTER_grpSeedGrower*/}
              {/* START_USER_CODE-USER_BEFORE_commandcontainerwidget21*/}

              {/* END_USER_CODE-USER_BEFORE_commandcontainerwidget21*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.commandcontainerwidget21}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnApplications*/}
            
                {/* END_USER_CODE-USER_BEFORE_btnApplications*/}
                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                 <LabelWidget
                  values={values}
                  conf={state.txtAddedBy}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnApplications}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                
                {/* START_USER_CODE-USER_AFTER_btnApplications*/}

                {/* END_USER_CODE-USER_AFTER_btnApplications*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                 <LabelWidget
                  values={values}
                  conf={state.txtChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_commandcontainerwidget21*/}

              {/* END_USER_CODE-USER_AFTER_commandcontainerwidget21*/}

              {/* START_USER_CODE-USER_AFTER_AddendumSeedGrower2009*/}

              {/* END_USER_CODE-USER_AFTER_AddendumSeedGrower2009*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_AddendumSeedGrower2009);
