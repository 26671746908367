/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_InterestAgreementProfile from "./InterestAgreementProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("InterestAgreementProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_InterestAgreementProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is InterestAgreementProfile Loads Successfully", () => {
    expect(screen.getByText("InterestAgreementProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for InterestAgreementProfile", () => {
    // START_USER_CODE-USER_InterestAgreementProfile_Custom_Test_Case
    // END_USER_CODE-USER_InterestAgreementProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_InterestAgreementProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCncl(Button Widget) Test Cases", async () => {
    const btnCncl = screen.getByTestId("btnCncl");
    expect(btnCncl).toBeInTheDocument;
    expect(btnCncl.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_btnCncl")
    );
  });
  test("Custom Test Cases for btnCncl", () => {
    // START_USER_CODE-USER_btnCncl_TEST
    // END_USER_CODE-USER_btnCncl_TEST
  });
  test("btnLeft(Button Widget) Test Cases", async () => {
    const btnLeft = screen.getByTestId("btnLeft");
    expect(btnLeft).toBeInTheDocument;
    expect(btnLeft.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_btnLeft")
    );
  });
  test("Custom Test Cases for btnLeft", () => {
    // START_USER_CODE-USER_btnLeft_TEST
    // END_USER_CODE-USER_btnLeft_TEST
  });
  test("btnPrntChck(Button Widget) Test Cases", async () => {
    const btnPrntChck = screen.getByTestId("btnPrntChck");
    expect(btnPrntChck).toBeInTheDocument;
    expect(btnPrntChck.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_btnPrntChck"
      )
    );
  });
  test("Custom Test Cases for btnPrntChck", () => {
    // START_USER_CODE-USER_btnPrntChck_TEST
    // END_USER_CODE-USER_btnPrntChck_TEST
  });
  test("btnRight(Button Widget) Test Cases", async () => {
    const btnRight = screen.getByTestId("btnRight");
    expect(btnRight).toBeInTheDocument;
    expect(btnRight.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_btnRight")
    );
  });
  test("Custom Test Cases for btnRight", () => {
    // START_USER_CODE-USER_btnRight_TEST
    // END_USER_CODE-USER_btnRight_TEST
  });
  test("btnSaveBatch(Button Widget) Test Cases", async () => {
    const btnSaveBatch = screen.getByTestId("btnSaveBatch");
    expect(btnSaveBatch).toBeInTheDocument;
    expect(btnSaveBatch.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_btnSaveBatch"
      )
    );
  });
  test("Custom Test Cases for btnSaveBatch", () => {
    // START_USER_CODE-USER_btnSaveBatch_TEST
    // END_USER_CODE-USER_btnSaveBatch_TEST
  });
  test("btnSelectAll(Button Widget) Test Cases", async () => {
    const btnSelectAll = screen.getByTestId("btnSelectAll");
    expect(btnSelectAll).toBeInTheDocument;
    expect(btnSelectAll.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_btnSelectAll"
      )
    );
  });
  test("Custom Test Cases for btnSelectAll", () => {
    // START_USER_CODE-USER_btnSelectAll_TEST
    // END_USER_CODE-USER_btnSelectAll_TEST
  });
  test("btnVndr(Button Widget) Test Cases", async () => {
    const btnVndr = screen.getByTestId("btnVndr");
    expect(btnVndr).toBeInTheDocument;
    expect(btnVndr.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_btnVndr")
    );
  });
  test("Custom Test Cases for btnVndr", () => {
    // START_USER_CODE-USER_btnVndr_TEST
    // END_USER_CODE-USER_btnVndr_TEST
  });
  test("dtIntrstBegn(Date Widget) Test Cases", async () => {
    const dtIntrstBegn = screen.getByTestId("dtIntrstBegn");
    expect(dtIntrstBegn.tagName).toBe("INPUT");
    expect(dtIntrstBegn.type).toBe("text");
    expect(dtIntrstBegn.classList).toContain("datetimepicker-input");
    expect(
      dtIntrstBegn.parentElement.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_dtIntrstBegn"
      )
    );
    await act(async () => {
      userEvent.click(dtIntrstBegn.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtIntrstBegn", () => {
    // START_USER_CODE-USER_dtIntrstBegn_TEST
    // END_USER_CODE-USER_dtIntrstBegn_TEST
  });
  test("gridAssocReady(Grid Widget) Test Cases", async () => {
    let gridAssocReady = screen.getByTestId("gridAssocReady");
    let gridAssocReadybtn = gridAssocReady.nextElementSibling.firstElementChild;
    gridAssocReady = gridAssocReady.parentElement.parentElement.parentElement;
    expect(gridAssocReady.tagName).toBe("DIV");
    expect(gridAssocReady.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAssocReady", () => {
    // START_USER_CODE-USER_gridAssocReady_TEST
    // END_USER_CODE-USER_gridAssocReady_TEST
  });
  test("gridAvlbReady(Grid Widget) Test Cases", async () => {
    let gridAvlbReady = screen.getByTestId("gridAvlbReady");
    let gridAvlbReadybtn = gridAvlbReady.nextElementSibling.firstElementChild;
    gridAvlbReady = gridAvlbReady.parentElement.parentElement.parentElement;
    expect(gridAvlbReady.tagName).toBe("DIV");
    expect(gridAvlbReady.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAvlbReady", () => {
    // START_USER_CODE-USER_gridAvlbReady_TEST
    // END_USER_CODE-USER_gridAvlbReady_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAgreeProf(GroupBox Widget) Test Cases", async () => {
    const grpbxAgreeProf = screen.getByTestId("grpbxAgreeProf");
    expect(grpbxAgreeProf.tagName).toBe("BUTTON");
    expect(grpbxAgreeProf.type).toBe("button");
    expect(grpbxAgreeProf.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAgreeProf", () => {
    // START_USER_CODE-USER_grpbxAgreeProf_TEST
    // END_USER_CODE-USER_grpbxAgreeProf_TEST
  });
  test("grpbxArCr(GroupBox Widget) Test Cases", async () => {
    const grpbxArCr = screen.getByTestId("grpbxArCr");
    expect(grpbxArCr.tagName).toBe("BUTTON");
    expect(grpbxArCr.type).toBe("button");
    expect(grpbxArCr.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxArCr", () => {
    // START_USER_CODE-USER_grpbxArCr_TEST
    // END_USER_CODE-USER_grpbxArCr_TEST
  });
  test("grpbxBottom(GroupBox Widget) Test Cases", async () => {
    const grpbxBottom = screen.getByTestId("grpbxBottom");
    expect(grpbxBottom.tagName).toBe("BUTTON");
    expect(grpbxBottom.type).toBe("button");
    expect(grpbxBottom.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBottom", () => {
    // START_USER_CODE-USER_grpbxBottom_TEST
    // END_USER_CODE-USER_grpbxBottom_TEST
  });
  test("grpbxButtons(GroupBox Widget) Test Cases", async () => {
    const grpbxButtons = screen.getByTestId("grpbxButtons");
    expect(grpbxButtons.tagName).toBe("BUTTON");
    expect(grpbxButtons.type).toBe("button");
    expect(grpbxButtons.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxButtons", () => {
    // START_USER_CODE-USER_grpbxButtons_TEST
    // END_USER_CODE-USER_grpbxButtons_TEST
  });
  test("grpbxGrids(GroupBox Widget) Test Cases", async () => {
    const grpbxGrids = screen.getByTestId("grpbxGrids");
    expect(grpbxGrids.tagName).toBe("BUTTON");
    expect(grpbxGrids.type).toBe("button");
    expect(grpbxGrids.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrids", () => {
    // START_USER_CODE-USER_grpbxGrids_TEST
    // END_USER_CODE-USER_grpbxGrids_TEST
  });
  test("grpbxRemarks(GroupBox Widget) Test Cases", async () => {
    const grpbxRemarks = screen.getByTestId("grpbxRemarks");
    expect(grpbxRemarks.tagName).toBe("BUTTON");
    expect(grpbxRemarks.type).toBe("button");
    expect(grpbxRemarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRemarks", () => {
    // START_USER_CODE-USER_grpbxRemarks_TEST
    // END_USER_CODE-USER_grpbxRemarks_TEST
  });
  test("lblRemarks(Label Widget) Test Cases", async () => {
    const lblRemarks = screen.getByTestId("lblRemarks");
    expect(lblRemarks.tagName).toBe("LABEL");
    expect(lblRemarks.classList).toContain("form-label");
    expect(lblRemarks.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_lblRemarks")
    );
  });
  test("Custom Test Cases for lblRemarks", () => {
    // START_USER_CODE-USER_lblRemarks_TEST
    // END_USER_CODE-USER_lblRemarks_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "1");
    });
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtAssociatedReadyToPay(Textbox Widget) Test Cases", async () => {
    const txtAssociatedReadyToPay = screen.getByTestId(
      "txtAssociatedReadyToPay"
    );
    expect(txtAssociatedReadyToPay.tagName).toBe("INPUT");
    expect(txtAssociatedReadyToPay.type).toBe("text");
    expect(txtAssociatedReadyToPay.classList).toContain("textboxWidgetClass");
    expect(txtAssociatedReadyToPay.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_txtAssociatedReadyToPay"
      )
    );
    await act(async () => {
      userEvent.type(txtAssociatedReadyToPay, "1");
    });
  });
  test("Custom Test Cases for txtAssociatedReadyToPay", () => {
    // START_USER_CODE-USER_txtAssociatedReadyToPay_TEST
    // END_USER_CODE-USER_txtAssociatedReadyToPay_TEST
  });
  test("txtAvlbRadyToPays(Textbox Widget) Test Cases", async () => {
    const txtAvlbRadyToPays = screen.getByTestId("txtAvlbRadyToPays");
    expect(txtAvlbRadyToPays.tagName).toBe("INPUT");
    expect(txtAvlbRadyToPays.type).toBe("text");
    expect(txtAvlbRadyToPays.classList).toContain("textboxWidgetClass");
    expect(txtAvlbRadyToPays.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_txtAvlbRadyToPays"
      )
    );
    await act(async () => {
      userEvent.type(txtAvlbRadyToPays, "1");
    });
  });
  test("Custom Test Cases for txtAvlbRadyToPays", () => {
    // START_USER_CODE-USER_txtAvlbRadyToPays_TEST
    // END_USER_CODE-USER_txtAvlbRadyToPays_TEST
  });
  test("txtBlnc(Textbox Widget) Test Cases", async () => {
    const txtBlnc = screen.getByTestId("txtBlnc");
    expect(txtBlnc.tagName).toBe("INPUT");
    expect(txtBlnc.type).toBe("text");
    expect(txtBlnc.classList).toContain("textboxWidgetClass");
    expect(txtBlnc.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtBlnc")
    );
    await act(async () => {
      userEvent.type(txtBlnc, "1");
    });
  });
  test("Custom Test Cases for txtBlnc", () => {
    // START_USER_CODE-USER_txtBlnc_TEST
    // END_USER_CODE-USER_txtBlnc_TEST
  });
  test("txtBlnc2(Textbox Widget) Test Cases", async () => {
    const txtBlnc2 = screen.getByTestId("txtBlnc2");
    expect(txtBlnc2.tagName).toBe("INPUT");
    expect(txtBlnc2.type).toBe("text");
    expect(txtBlnc2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBlnc2, "1");
    });
  });
  test("Custom Test Cases for txtBlnc2", () => {
    // START_USER_CODE-USER_txtBlnc2_TEST
    // END_USER_CODE-USER_txtBlnc2_TEST
  });
  test("txtBlnc3(Textbox Widget) Test Cases", async () => {
    const txtBlnc3 = screen.getByTestId("txtBlnc3");
    expect(txtBlnc3.tagName).toBe("INPUT");
    expect(txtBlnc3.type).toBe("text");
    expect(txtBlnc3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBlnc3, "1");
    });
  });
  test("Custom Test Cases for txtBlnc3", () => {
    // START_USER_CODE-USER_txtBlnc3_TEST
    // END_USER_CODE-USER_txtBlnc3_TEST
  });
  test("txtChangedBy(Textbox Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("INPUT");
    expect(txtChangedBy.type).toBe("text");
    expect(txtChangedBy.classList).toContain("textboxWidgetClass");
    expect(txtChangedBy.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_txtChangedBy"
      )
    );
    await act(async () => {
      userEvent.type(txtChangedBy, "1");
    });
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("txtCllctAr(Textbox Widget) Test Cases", async () => {
    const txtCllctAr = screen.getByTestId("txtCllctAr");
    expect(txtCllctAr.tagName).toBe("INPUT");
    expect(txtCllctAr.type).toBe("text");
    expect(txtCllctAr.classList).toContain("textboxWidgetClass");
    expect(txtCllctAr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtCllctAr")
    );
    await act(async () => {
      userEvent.type(txtCllctAr, "1");
    });
  });
  test("Custom Test Cases for txtCllctAr", () => {
    // START_USER_CODE-USER_txtCllctAr_TEST
    // END_USER_CODE-USER_txtCllctAr_TEST
  });
  test("txtCllctCR(Textbox Widget) Test Cases", async () => {
    const txtCllctCR = screen.getByTestId("txtCllctCR");
    expect(txtCllctCR.tagName).toBe("INPUT");
    expect(txtCllctCR.type).toBe("text");
    expect(txtCllctCR.classList).toContain("textboxWidgetClass");
    expect(txtCllctCR.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtCllctCR")
    );
    await act(async () => {
      userEvent.type(txtCllctCR, "1");
    });
  });
  test("Custom Test Cases for txtCllctCR", () => {
    // START_USER_CODE-USER_txtCllctCR_TEST
    // END_USER_CODE-USER_txtCllctCR_TEST
  });
  test("txtCllctSr(Textbox Widget) Test Cases", async () => {
    const txtCllctSr = screen.getByTestId("txtCllctSr");
    expect(txtCllctSr.tagName).toBe("INPUT");
    expect(txtCllctSr.type).toBe("text");
    expect(txtCllctSr.classList).toContain("textboxWidgetClass");
    expect(txtCllctSr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtCllctSr")
    );
    await act(async () => {
      userEvent.type(txtCllctSr, "1");
    });
  });
  test("Custom Test Cases for txtCllctSr", () => {
    // START_USER_CODE-USER_txtCllctSr_TEST
    // END_USER_CODE-USER_txtCllctSr_TEST
  });
  test("gridAvlbReady_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolDate = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolDatebtn =
      gridAvlbReady_txtcolDate.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolDate =
      gridAvlbReady_txtcolDate.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolDate.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridAssocReady_txtcolDateassoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolDateassoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolDateassocbtn =
      gridAssocReady_txtcolDateassoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolDateassoc =
      gridAssocReady_txtcolDateassoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolDateassoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolDateassoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateassoc", () => {
    // START_USER_CODE-USER_txtcolDateassoc_TEST
    // END_USER_CODE-USER_txtcolDateassoc_TEST
  });
  test("gridAvlbReady_txtcolNum1(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolNum1 = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolNum1btn =
      gridAvlbReady_txtcolNum1.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolNum1 =
      gridAvlbReady_txtcolNum1.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolNum1.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolNum1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNum1", () => {
    // START_USER_CODE-USER_txtcolNum1_TEST
    // END_USER_CODE-USER_txtcolNum1_TEST
  });
  test("gridAssocReady_txtcolNum1assoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolNum1assoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolNum1assocbtn =
      gridAssocReady_txtcolNum1assoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolNum1assoc =
      gridAssocReady_txtcolNum1assoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolNum1assoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolNum1assoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNum1assoc", () => {
    // START_USER_CODE-USER_txtcolNum1assoc_TEST
    // END_USER_CODE-USER_txtcolNum1assoc_TEST
  });
  test("gridAvlbReady_txtcolNum2(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolNum2 = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolNum2btn =
      gridAvlbReady_txtcolNum2.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolNum2 =
      gridAvlbReady_txtcolNum2.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolNum2.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolNum2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNum2", () => {
    // START_USER_CODE-USER_txtcolNum2_TEST
    // END_USER_CODE-USER_txtcolNum2_TEST
  });
  test("gridAssocReady_txtcolNum2assoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolNum2assoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolNum2assocbtn =
      gridAssocReady_txtcolNum2assoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolNum2assoc =
      gridAssocReady_txtcolNum2assoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolNum2assoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolNum2assoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNum2assoc", () => {
    // START_USER_CODE-USER_txtcolNum2assoc_TEST
    // END_USER_CODE-USER_txtcolNum2assoc_TEST
  });
  test("gridAvlbReady_txtcolOpt(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolOpt = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolOptbtn =
      gridAvlbReady_txtcolOpt.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolOpt =
      gridAvlbReady_txtcolOpt.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolOpt.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolOpt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpt", () => {
    // START_USER_CODE-USER_txtcolOpt_TEST
    // END_USER_CODE-USER_txtcolOpt_TEST
  });
  test("gridAssocReady_txtcolOptassoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolOptassoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolOptassocbtn =
      gridAssocReady_txtcolOptassoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolOptassoc =
      gridAssocReady_txtcolOptassoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolOptassoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolOptassoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOptassoc", () => {
    // START_USER_CODE-USER_txtcolOptassoc_TEST
    // END_USER_CODE-USER_txtcolOptassoc_TEST
  });
  test("gridAvlbReady_txtcolProceed(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolProceed = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolProceedbtn =
      gridAvlbReady_txtcolProceed.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolProceed =
      gridAvlbReady_txtcolProceed.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolProceed.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolProceed.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceed", () => {
    // START_USER_CODE-USER_txtcolProceed_TEST
    // END_USER_CODE-USER_txtcolProceed_TEST
  });
  test("gridAssocReady_txtcolProceedassoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolProceedassoc = screen.getByTestId(
      "gridAssocReady"
    );
    let gridAssocReady_txtcolProceedassocbtn =
      gridAssocReady_txtcolProceedassoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolProceedassoc =
      gridAssocReady_txtcolProceedassoc.parentElement.parentElement
        .parentElement;
    expect(gridAssocReady_txtcolProceedassoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolProceedassoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceedassoc", () => {
    // START_USER_CODE-USER_txtcolProceedassoc_TEST
    // END_USER_CODE-USER_txtcolProceedassoc_TEST
  });
  test("gridAvlbReady_txtcolRemit(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolRemit = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolRemitbtn =
      gridAvlbReady_txtcolRemit.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolRemit =
      gridAvlbReady_txtcolRemit.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolRemit.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolRemit.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRemit", () => {
    // START_USER_CODE-USER_txtcolRemit_TEST
    // END_USER_CODE-USER_txtcolRemit_TEST
  });
  test("gridAssocReady_txtcolRemitassoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolRemitassoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolRemitassocbtn =
      gridAssocReady_txtcolRemitassoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolRemitassoc =
      gridAssocReady_txtcolRemitassoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolRemitassoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolRemitassoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRemitassoc", () => {
    // START_USER_CODE-USER_txtcolRemitassoc_TEST
    // END_USER_CODE-USER_txtcolRemitassoc_TEST
  });
  test("gridAvlbReady_txtcolSetlDate(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolSetlDate = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolSetlDatebtn =
      gridAvlbReady_txtcolSetlDate.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolSetlDate =
      gridAvlbReady_txtcolSetlDate.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolSetlDate.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolSetlDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSetlDate", () => {
    // START_USER_CODE-USER_txtcolSetlDate_TEST
    // END_USER_CODE-USER_txtcolSetlDate_TEST
  });
  test("gridAssocReady_txtcolSetlDateassoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolSetlDateassoc = screen.getByTestId(
      "gridAssocReady"
    );
    let gridAssocReady_txtcolSetlDateassocbtn =
      gridAssocReady_txtcolSetlDateassoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolSetlDateassoc =
      gridAssocReady_txtcolSetlDateassoc.parentElement.parentElement
        .parentElement;
    expect(gridAssocReady_txtcolSetlDateassoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolSetlDateassoc.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSetlDateassoc", () => {
    // START_USER_CODE-USER_txtcolSetlDateassoc_TEST
    // END_USER_CODE-USER_txtcolSetlDateassoc_TEST
  });
  test("gridAvlbReady_txtcolType1(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolType1 = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolType1btn =
      gridAvlbReady_txtcolType1.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolType1 =
      gridAvlbReady_txtcolType1.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolType1.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolType1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1", () => {
    // START_USER_CODE-USER_txtcolType1_TEST
    // END_USER_CODE-USER_txtcolType1_TEST
  });
  test("gridAssocReady_txtcolType1assoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolType1assoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolType1assocbtn =
      gridAssocReady_txtcolType1assoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolType1assoc =
      gridAssocReady_txtcolType1assoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolType1assoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolType1assoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1assoc", () => {
    // START_USER_CODE-USER_txtcolType1assoc_TEST
    // END_USER_CODE-USER_txtcolType1assoc_TEST
  });
  test("gridAvlbReady_txtcolType2(Grid Widget) Test Cases", async () => {
    let gridAvlbReady_txtcolType2 = screen.getByTestId("gridAvlbReady");
    let gridAvlbReady_txtcolType2btn =
      gridAvlbReady_txtcolType2.nextElementSibling.firstElementChild;
    gridAvlbReady_txtcolType2 =
      gridAvlbReady_txtcolType2.parentElement.parentElement.parentElement;
    expect(gridAvlbReady_txtcolType2.tagName).toBe("DIV");
    expect(gridAvlbReady_txtcolType2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAvlbReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2", () => {
    // START_USER_CODE-USER_txtcolType2_TEST
    // END_USER_CODE-USER_txtcolType2_TEST
  });
  test("gridAssocReady_txtcolType2assoc(Grid Widget) Test Cases", async () => {
    let gridAssocReady_txtcolType2assoc = screen.getByTestId("gridAssocReady");
    let gridAssocReady_txtcolType2assocbtn =
      gridAssocReady_txtcolType2assoc.nextElementSibling.firstElementChild;
    gridAssocReady_txtcolType2assoc =
      gridAssocReady_txtcolType2assoc.parentElement.parentElement.parentElement;
    expect(gridAssocReady_txtcolType2assoc.tagName).toBe("DIV");
    expect(gridAssocReady_txtcolType2assoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:InterestAgreementProfile_gridAssocReady"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2assoc", () => {
    // START_USER_CODE-USER_txtcolType2assoc_TEST
    // END_USER_CODE-USER_txtcolType2assoc_TEST
  });
  test("txtInterest(Textbox Widget) Test Cases", async () => {
    const txtInterest = screen.getByTestId("txtInterest");
    expect(txtInterest.tagName).toBe("INPUT");
    expect(txtInterest.type).toBe("text");
    expect(txtInterest.classList).toContain("textboxWidgetClass");
    expect(txtInterest.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_txtInterest"
      )
    );
    await act(async () => {
      userEvent.type(txtInterest, "1");
    });
  });
  test("Custom Test Cases for txtInterest", () => {
    // START_USER_CODE-USER_txtInterest_TEST
    // END_USER_CODE-USER_txtInterest_TEST
  });
  test("txtLienhldr(Textbox Widget) Test Cases", async () => {
    const txtLienhldr = screen.getByTestId("txtLienhldr");
    expect(txtLienhldr.tagName).toBe("INPUT");
    expect(txtLienhldr.type).toBe("text");
    expect(txtLienhldr.classList).toContain("textboxWidgetClass");
    expect(txtLienhldr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementProfile_txtLienhldr"
      )
    );
    await act(async () => {
      userEvent.type(txtLienhldr, "1");
    });
  });
  test("Custom Test Cases for txtLienhldr", () => {
    // START_USER_CODE-USER_txtLienhldr_TEST
    // END_USER_CODE-USER_txtLienhldr_TEST
  });
  test("txtLoc(Textbox Widget) Test Cases", async () => {
    const txtLoc = screen.getByTestId("txtLoc");
    expect(txtLoc.tagName).toBe("INPUT");
    expect(txtLoc.type).toBe("text");
    expect(txtLoc.classList).toContain("textboxWidgetClass");
    expect(txtLoc.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtLoc")
    );
    await act(async () => {
      userEvent.type(txtLoc, "1");
    });
  });
  test("Custom Test Cases for txtLoc", () => {
    // START_USER_CODE-USER_txtLoc_TEST
    // END_USER_CODE-USER_txtLoc_TEST
  });
  test("txtRate(Textbox Widget) Test Cases", async () => {
    const txtRate = screen.getByTestId("txtRate");
    expect(txtRate.tagName).toBe("INPUT");
    expect(txtRate.type).toBe("text");
    expect(txtRate.classList).toContain("textboxWidgetClass");
    expect(txtRate.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtRate")
    );
    await act(async () => {
      userEvent.type(txtRate, "1");
    });
  });
  test("Custom Test Cases for txtRate", () => {
    // START_USER_CODE-USER_txtRate_TEST
    // END_USER_CODE-USER_txtRate_TEST
  });
  test("txtValChck(Textbox Widget) Test Cases", async () => {
    const txtValChck = screen.getByTestId("txtValChck");
    expect(txtValChck.tagName).toBe("INPUT");
    expect(txtValChck.type).toBe("text");
    expect(txtValChck.classList).toContain("textboxWidgetClass");
    expect(txtValChck.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtValChck")
    );
    await act(async () => {
      userEvent.type(txtValChck, "1");
    });
  });
  test("Custom Test Cases for txtValChck", () => {
    // START_USER_CODE-USER_txtValChck_TEST
    // END_USER_CODE-USER_txtValChck_TEST
  });
  test("txtValRtp(Textbox Widget) Test Cases", async () => {
    const txtValRtp = screen.getByTestId("txtValRtp");
    expect(txtValRtp.tagName).toBe("INPUT");
    expect(txtValRtp.type).toBe("text");
    expect(txtValRtp.classList).toContain("textboxWidgetClass");
    expect(txtValRtp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtValRtp")
    );
    await act(async () => {
      userEvent.type(txtValRtp, "1");
    });
  });
  test("Custom Test Cases for txtValRtp", () => {
    // START_USER_CODE-USER_txtValRtp_TEST
    // END_USER_CODE-USER_txtValRtp_TEST
  });
  test("txtVendr(Textbox Widget) Test Cases", async () => {
    const txtVendr = screen.getByTestId("txtVendr");
    expect(txtVendr.tagName).toBe("INPUT");
    expect(txtVendr.type).toBe("text");
    expect(txtVendr.classList).toContain("textboxWidgetClass");
    expect(txtVendr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementProfile_txtVendr")
    );
    await act(async () => {
      userEvent.type(txtVendr, "1");
    });
  });
  test("Custom Test Cases for txtVendr", () => {
    // START_USER_CODE-USER_txtVendr_TEST
    // END_USER_CODE-USER_txtVendr_TEST
  });
});
