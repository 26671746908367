/* eslint-disable*/
import React from "react";
import Settlements_KCMOdataSearch from "./KCMOdataSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("KCMOdataSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_KCMOdataSearch />);
    });
  });
  afterEach(cleanup);
  test("is KCMOdataSearch Loads Successfully", () => {
    expect(screen.getByText("KCMOdataSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for KCMOdataSearch", () => {
    // START_USER_CODE-USER_KCMOdataSearch_Custom_Test_Case
    // END_USER_CODE-USER_KCMOdataSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_KCMOdataSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:KCMOdataSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("Settlements:KCMOdataSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("Settlements:KCMOdataSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:KCMOdataSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnRequestFileResend(Button Widget) Test Cases", async () => {
    const btnRequestFileResend = screen.getByTestId("btnRequestFileResend");
    expect(btnRequestFileResend).toBeInTheDocument;
    expect(btnRequestFileResend.textContent).toEqual(
      t("Settlements:KCMOdataSearch_btnRequestFileResend")
    );
  });
  test("Custom Test Cases for btnRequestFileResend", () => {
    // START_USER_CODE-USER_btnRequestFileResend_TEST
    // END_USER_CODE-USER_btnRequestFileResend_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("Settlements:KCMOdataSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridKCMOdata(Grid Widget) Test Cases", async () => {
    let gridKCMOdata = screen.getByTestId("gridKCMOdata");
    let gridKCMOdatabtn = gridKCMOdata.nextElementSibling.firstElementChild;
    gridKCMOdata = gridKCMOdata.parentElement.parentElement.parentElement;
    expect(gridKCMOdata.tagName).toBe("DIV");
    expect(gridKCMOdata.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridKCMOdata", () => {
    // START_USER_CODE-USER_gridKCMOdata_TEST
    // END_USER_CODE-USER_gridKCMOdata_TEST
  });
  test("grpbxKCMOdataSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxKCMOdataSearch = screen.getByTestId("grpbxKCMOdataSearch");
    expect(grpbxKCMOdataSearch.tagName).toBe("BUTTON");
    expect(grpbxKCMOdataSearch.type).toBe("button");
    expect(grpbxKCMOdataSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxKCMOdataSearch", () => {
    // START_USER_CODE-USER_grpbxKCMOdataSearch_TEST
    // END_USER_CODE-USER_grpbxKCMOdataSearch_TEST
  });
  test("txt1007Settlement(Textbox Widget) Test Cases", async () => {
    const txt1007Settlement = screen.getByTestId("txt1007Settlement");
    expect(txt1007Settlement.tagName).toBe("INPUT");
    expect(txt1007Settlement.type).toBe("text");
    expect(txt1007Settlement.classList).toContain("textboxWidgetClass");
    expect(txt1007Settlement.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataSearch_txt1007Settlement")
    );
    await act(async () => {
      userEvent.type(txt1007Settlement, "1");
    });
  });
  test("Custom Test Cases for txt1007Settlement", () => {
    // START_USER_CODE-USER_txt1007Settlement_TEST
    // END_USER_CODE-USER_txt1007Settlement_TEST
  });
  test("txt1007SettleRangeFrom(Textbox Widget) Test Cases", async () => {
    const txt1007SettleRangeFrom = screen.getByTestId("txt1007SettleRangeFrom");
    expect(txt1007SettleRangeFrom.tagName).toBe("INPUT");
    expect(txt1007SettleRangeFrom.type).toBe("text");
    expect(txt1007SettleRangeFrom.classList).toContain("textboxWidgetClass");
    expect(txt1007SettleRangeFrom.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataSearch_txt1007SettleRangeFrom")
    );
    await act(async () => {
      userEvent.type(txt1007SettleRangeFrom, "1");
    });
  });
  test("Custom Test Cases for txt1007SettleRangeFrom", () => {
    // START_USER_CODE-USER_txt1007SettleRangeFrom_TEST
    // END_USER_CODE-USER_txt1007SettleRangeFrom_TEST
  });
  test("txt1007SettleRangeTo(Textbox Widget) Test Cases", async () => {
    const txt1007SettleRangeTo = screen.getByTestId("txt1007SettleRangeTo");
    expect(txt1007SettleRangeTo.tagName).toBe("INPUT");
    expect(txt1007SettleRangeTo.type).toBe("text");
    expect(txt1007SettleRangeTo.classList).toContain("textboxWidgetClass");
    expect(txt1007SettleRangeTo.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataSearch_txt1007SettleRangeTo")
    );
    await act(async () => {
      userEvent.type(txt1007SettleRangeTo, "1");
    });
  });
  test("Custom Test Cases for txt1007SettleRangeTo", () => {
    // START_USER_CODE-USER_txt1007SettleRangeTo_TEST
    // END_USER_CODE-USER_txt1007SettleRangeTo_TEST
  });
  test("gridKCMOdata_txtcol1007Num(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcol1007Num = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcol1007Numbtn =
      gridKCMOdata_txtcol1007Num.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcol1007Num =
      gridKCMOdata_txtcol1007Num.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcol1007Num.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcol1007Num.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1007Num", () => {
    // START_USER_CODE-USER_txtcol1007Num_TEST
    // END_USER_CODE-USER_txtcol1007Num_TEST
  });
  test("gridKCMOdata_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolBuyPt = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolBuyPtbtn =
      gridKCMOdata_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolBuyPt =
      gridKCMOdata_txtcolBuyPt.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolBuyPt.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridKCMOdata_txtcolCounty(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolCounty = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolCountybtn =
      gridKCMOdata_txtcolCounty.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolCounty =
      gridKCMOdata_txtcolCounty.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolCounty.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolCounty.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCounty", () => {
    // START_USER_CODE-USER_txtcolCounty_TEST
    // END_USER_CODE-USER_txtcolCounty_TEST
  });
  test("gridKCMOdata_txtcolFarm(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolFarm = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolFarmbtn =
      gridKCMOdata_txtcolFarm.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolFarm =
      gridKCMOdata_txtcolFarm.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolFarm.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolFarm.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarm", () => {
    // START_USER_CODE-USER_txtcolFarm_TEST
    // END_USER_CODE-USER_txtcolFarm_TEST
  });
  test("gridKCMOdata_txtcolFedBP(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolFedBP = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolFedBPbtn =
      gridKCMOdata_txtcolFedBP.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolFedBP =
      gridKCMOdata_txtcolFedBP.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolFedBP.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolFedBP.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFedBP", () => {
    // START_USER_CODE-USER_txtcolFedBP_TEST
    // END_USER_CODE-USER_txtcolFedBP_TEST
  });
  test("gridKCMOdata_txtcolFileNum(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolFileNum = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolFileNumbtn =
      gridKCMOdata_txtcolFileNum.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolFileNum =
      gridKCMOdata_txtcolFileNum.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolFileNum.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolFileNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFileNum", () => {
    // START_USER_CODE-USER_txtcolFileNum_TEST
    // END_USER_CODE-USER_txtcolFileNum_TEST
  });
  test("gridKCMOdata_txtcolInspectDate(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolInspectDate = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolInspectDatebtn =
      gridKCMOdata_txtcolInspectDate.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolInspectDate =
      gridKCMOdata_txtcolInspectDate.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolInspectDate.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolInspectDate.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInspectDate", () => {
    // START_USER_CODE-USER_txtcolInspectDate_TEST
    // END_USER_CODE-USER_txtcolInspectDate_TEST
  });
  test("gridKCMOdata_txtcolNetWt(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolNetWt = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolNetWtbtn =
      gridKCMOdata_txtcolNetWt.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolNetWt =
      gridKCMOdata_txtcolNetWt.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolNetWt.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolNetWt.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetWt", () => {
    // START_USER_CODE-USER_txtcolNetWt_TEST
    // END_USER_CODE-USER_txtcolNetWt_TEST
  });
  test("gridKCMOdata_txtcolPnut(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolPnut = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolPnutbtn =
      gridKCMOdata_txtcolPnut.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolPnut =
      gridKCMOdata_txtcolPnut.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolPnut.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolPnut.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnut", () => {
    // START_USER_CODE-USER_txtcolPnut_TEST
    // END_USER_CODE-USER_txtcolPnut_TEST
  });
  test("gridKCMOdata_txtcolSC95Num(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolSC95Num = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolSC95Numbtn =
      gridKCMOdata_txtcolSC95Num.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolSC95Num =
      gridKCMOdata_txtcolSC95Num.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolSC95Num.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolSC95Num.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSC95Num", () => {
    // START_USER_CODE-USER_txtcolSC95Num_TEST
    // END_USER_CODE-USER_txtcolSC95Num_TEST
  });
  test("gridKCMOdata_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolSeg = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolSegbtn =
      gridKCMOdata_txtcolSeg.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolSeg =
      gridKCMOdata_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolSeg.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolSeg.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridKCMOdata_txtcolState(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolState = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolStatebtn =
      gridKCMOdata_txtcolState.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolState =
      gridKCMOdata_txtcolState.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolState.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolState.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolState", () => {
    // START_USER_CODE-USER_txtcolState_TEST
    // END_USER_CODE-USER_txtcolState_TEST
  });
  test("gridKCMOdata_txtcolTransmitDate(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolTransmitDate = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolTransmitDatebtn =
      gridKCMOdata_txtcolTransmitDate.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolTransmitDate =
      gridKCMOdata_txtcolTransmitDate.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolTransmitDate.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolTransmitDate.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransmitDate", () => {
    // START_USER_CODE-USER_txtcolTransmitDate_TEST
    // END_USER_CODE-USER_txtcolTransmitDate_TEST
  });
  test("gridKCMOdata_txtcolVoidCd(Grid Widget) Test Cases", async () => {
    let gridKCMOdata_txtcolVoidCd = screen.getByTestId("gridKCMOdata");
    let gridKCMOdata_txtcolVoidCdbtn =
      gridKCMOdata_txtcolVoidCd.nextElementSibling.firstElementChild;
    gridKCMOdata_txtcolVoidCd =
      gridKCMOdata_txtcolVoidCd.parentElement.parentElement.parentElement;
    expect(gridKCMOdata_txtcolVoidCd.tagName).toBe("DIV");
    expect(gridKCMOdata_txtcolVoidCd.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:KCMOdataSearch_gridKCMOdata")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVoidCd", () => {
    // START_USER_CODE-USER_txtcolVoidCd_TEST
    // END_USER_CODE-USER_txtcolVoidCd_TEST
  });
  test("txtFederalBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtFederalBuyingPoint = screen.getByTestId("txtFederalBuyingPoint");
    expect(txtFederalBuyingPoint.tagName).toBe("INPUT");
    expect(txtFederalBuyingPoint.type).toBe("text");
    expect(txtFederalBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtFederalBuyingPoint.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataSearch_txtFederalBuyingPoint")
    );
    await act(async () => {
      userEvent.type(txtFederalBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtFederalBuyingPoint", () => {
    // START_USER_CODE-USER_txtFederalBuyingPoint_TEST
    // END_USER_CODE-USER_txtFederalBuyingPoint_TEST
  });
  test("txtFileNumber(Textbox Widget) Test Cases", async () => {
    const txtFileNumber = screen.getByTestId("txtFileNumber");
    expect(txtFileNumber.tagName).toBe("INPUT");
    expect(txtFileNumber.type).toBe("text");
    expect(txtFileNumber.classList).toContain("textboxWidgetClass");
    expect(txtFileNumber.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataSearch_txtFileNumber")
    );
    await act(async () => {
      userEvent.type(txtFileNumber, "1");
    });
  });
  test("Custom Test Cases for txtFileNumber", () => {
    // START_USER_CODE-USER_txtFileNumber_TEST
    // END_USER_CODE-USER_txtFileNumber_TEST
  });
  test("txtSC95Inspection(Textbox Widget) Test Cases", async () => {
    const txtSC95Inspection = screen.getByTestId("txtSC95Inspection");
    expect(txtSC95Inspection.tagName).toBe("INPUT");
    expect(txtSC95Inspection.type).toBe("text");
    expect(txtSC95Inspection.classList).toContain("textboxWidgetClass");
    expect(txtSC95Inspection.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataSearch_txtSC95Inspection")
    );
    await act(async () => {
      userEvent.type(txtSC95Inspection, "1");
    });
  });
  test("Custom Test Cases for txtSC95Inspection", () => {
    // START_USER_CODE-USER_txtSC95Inspection_TEST
    // END_USER_CODE-USER_txtSC95Inspection_TEST
  });
  test("txtTransmitDateFrom(Date Widget) Test Cases", async () => {
    const txtTransmitDateFrom = screen.getByTestId("txtTransmitDateFrom");
    expect(txtTransmitDateFrom.tagName).toBe("INPUT");
    expect(txtTransmitDateFrom.type).toBe("text");
    expect(txtTransmitDateFrom.classList).toContain("datetimepicker-input");
    expect(
      txtTransmitDateFrom.parentElement.previousElementSibling.textContent
    ).toEqual(t("Settlements:KCMOdataSearch_txtTransmitDateFrom"));
    await act(async () => {
      userEvent.click(txtTransmitDateFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtTransmitDateFrom", () => {
    // START_USER_CODE-USER_txtTransmitDateFrom_TEST
    // END_USER_CODE-USER_txtTransmitDateFrom_TEST
  });
  test("txtTransmitDateTo(Date Widget) Test Cases", async () => {
    const txtTransmitDateTo = screen.getByTestId("txtTransmitDateTo");
    expect(txtTransmitDateTo.tagName).toBe("INPUT");
    expect(txtTransmitDateTo.type).toBe("text");
    expect(txtTransmitDateTo.classList).toContain("datetimepicker-input");
    expect(
      txtTransmitDateTo.parentElement.previousElementSibling.textContent
    ).toEqual(t("Settlements:KCMOdataSearch_txtTransmitDateTo"));
    await act(async () => {
      userEvent.click(txtTransmitDateTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtTransmitDateTo", () => {
    // START_USER_CODE-USER_txtTransmitDateTo_TEST
    // END_USER_CODE-USER_txtTransmitDateTo_TEST
  });
});
