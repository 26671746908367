/* eslint-disable*/
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import moment from "moment";
import { toast } from "react-toastify";

import { _kaledo } from "../../shared/WindowImports";

const defaultPickerOptions = {
  viewMode: "days",
  allowInputToggle: false,
  locale: "en",
  format: "L LTS",
  calendarWeeks: false,
  toolbarPlacement: "default",
  tooltips: {
    today: "Go to today",
    clear: "Clear selection",
    close: "Close the picker",
    selectMonth: "Select Month",
    prevMonth: "Previous Month",
    nextMonth: "Next Month",
    selectYear: "Select Year",
    prevYear: "Previous Year",
    nextYear: "Next Year",
    selectDecade: "Select Decade",
    prevDecade: "Previous Decade",
    nextDecade: "Next Decade",
    prevCentury: "Previous Century",
    nextCentury: "Next Century",
  },
  widgetPositioning: { horizontal: "auto", vertical: "bottom" },
  focusOnShow: true,
};
function DateTime(props) {
  // The dateTimePicker internals use jquery and events to notify onChange. This triggers when we update props.
  // Use `arePropsUpdating` to determine when to notify parent.
  let datePickerElement = {};
  let datePicker = {};
  let dateTimePicker = {};
  let arePropsUpdating = false;
  let intialClick = true;
  let updateEvent = true;
  const textInputElement = useRef();

  let componentId;
  if (props.rowID) {
    componentId = `${props.id}${props.rowID}Parent`;
  } else {
    componentId = `${props.id}Parent`;
  }
  datePickerElement = $(`#${componentId}`);
  let { pickerOptions } = props;
  pickerOptions = {
    ...defaultPickerOptions,
    ...pickerOptions,
  };
  dateTimePicker = datePickerElement.datetimepicker(pickerOptions);
  datePicker = datePickerElement.data("datetimepicker");

  /*useEffect(() => {      
        return () => {
          if (datePicker) {
            datePicker.destroy();
            datePicker = null;
            datePickerElement = null;
            //textInputElement = null;
          }
        }
    },[]);*/
  useEffect(() => {
    datePickerElement = $(`#${componentId}`);
    dateTimePicker = datePickerElement.datetimepicker(pickerOptions);
    datePicker = datePickerElement.data("datetimepicker");

    return () => {
      if (datePicker) {
        datePicker.destroy();
        datePicker = null;
        datePickerElement = null;
        //textInputElement = null;
      }
    };
    //End - Subbarao - Avoid tabing for grid header
  }, []);
  useEffect(() => {
    datePickerElement = $(`#${componentId}`);
    dateTimePicker = datePickerElement.datetimepicker(pickerOptions);
    datePicker = datePickerElement.data("datetimepicker");
    const { value } = props;
    //if (prevProps.value !== value) {
    arePropsUpdating = true;
    updateValue(value);
    arePropsUpdating = false;
    if (moment.isMoment(value)) {
      if (props.minDateRef) {
        let minEle = $("#" + props.minDateRef + "Parent");
        if (minEle.length) {
          minEle.data("datetimepicker").minDate(value);
        }
      }
      if (props.maxDateRef) {
        let maxEle = $("#" + props.maxDateRef + "Parent");
        if (maxEle.length) {
          maxEle.data("datetimepicker").maxDate(value);
        }
      }
    }

    //}
  }, [props.value]);

  const updateValue = (value) => {
    // datePickerElement = $(`#${componentId}`);

    datePickerElement.off("change.datetimepicker", onChange);
    datePickerElement.off("focusout.datetimepicker", onBlur);
    //datePicker._element[0].off("change");
    $(datePickerElement).off("change");
    $(datePickerElement).off("focusout");
    //datePicker.destroy();

    if (!datePicker) {
      return;
    }
    const {
      pickerOptions: { format },
    } = props;
    if (value !== undefined && value !== null) {
      if (moment.isMoment(value) || value.trim().length) {
        datePicker.date(value);
        if (!moment.isMoment(value)) {
          value = moment(value);
          if (!intialClick)
            datePickerElement.on("focusout.datetimepicker", onBlur); // Changes made for Blank Value when there is no value it should call blur event solution given by Ingnatius.
        } else {
        }
        //textInputElement.current.value = value.format(format);
        return;
      }
    }
    if (!value) {
      let event = {
        type: "change",
        target: {
          value: null,
          name: props.name,
          id: props.id,
        },
      };
      datePicker.clear();
      //props.onChange(event);
    }
    //textInputElement && textInputElement.current && (textInputElement.current.value = "");
    datePicker.clear();
  };

  /* const setRef = ref => {
    textInputElement = ref;
  };*/
  const onChange = (e) => {
    //e.prevent
    const dateStr = e.target.value;
    var valid;
    //date validation ABS specific
    updateEvent = false;
    if (window._kaledo.isCobolTable && dateStr !== undefined) {
      //Todo:abs specific element
      var elemErr = document.getElementById("txtErrMsgs");
      let requiredMsg = window._kaledo.t(
        `AppJSMessage:VALIDATIONMSG_INVALIDDATEMSG`
      );
      valid = validatedate(dateStr);
      if (!valid) {
        if (elemErr !== null) {
          elemErr.value = requiredMsg;
          elemErr.style.color = "red";
          elemErr.style.borderColor = "red";
          setTimeout(() => {
            elemErr.value = "";
          }, 4000);
        } else {
          toast.error(requiredMsg);
        }
        return false;
      } else {
        if (elemErr !== null) {
          elemErr.value = "";
        }
      }
    }
    // if (arePropsUpdating || !e.date) {
    //   return;
    // }
    if (arePropsUpdating || !e.date) {
      if (dateStr && validatedate(dateStr)) {
        let event = {
          type: "change",
          target: {
            value: moment(dateStr, props.pickerOptions.format),
            name: props.name,
            id: props.id,
            sourceEvent: "update1",
          },
        };
        updateEvent = true;
        return props.onChange(event);
      } else {
        // let event = {
        //   type: "change",
        //   target: {
        //     value: "",
        //     name: props.name,
        //     id: props.id,
        //     sourceEvent: "update1",
        //     error: true,
        //   },
        // };
        // return props.onChange(event);
        return;
      }
    }
    let event = {
      type: "change",
      target: {
        value: e.date,
        name: props.name,
        id: props.id,
        sourceEvent: "update2",
      },
    };
    updateEvent = true;
    return props.onChange(event);
  };
  const onBlur = (e) => {
    //ABS specific- In Cobol table, default date should not come if it is empty on blur
    updateEvent = false;
    if (!window._kaledo.isCobolTable && !textInputElement.current.value) {
      const {
        pickerOptions: { format },
      } = props;
      //datePicker.date(new Date());
      //textInputElement.current.value = moment(new Date()).format(format);
    }
    const dateStr = textInputElement.current.value;
    var valid;
    //date validation ABS specific
    if (window._kaledo.isCobolTable && dateStr !== undefined) {
      //Todo:abs specific element
      var elemErr = document.getElementById("txtErrMsgs");
      let requiredMsg = window._kaledo.t(
        `AppJSMessage:VALIDATIONMSG_INVALIDDATEMSG`
      );
      valid = validatedate(dateStr);
      if (!valid && dateStr) {
        if (elemErr !== null) {
          elemErr.value = requiredMsg;
          elemErr.style.color = "red";
          elemErr.style.borderColor = "red";
          setTimeout(() => {
            elemErr.value = "";
          }, 4000);
        } else {
          toast.error(requiredMsg);
        }
        return false;
      } else {
        if (elemErr !== null) {
          elemErr.value = "";
        }
      }
    }
    if (arePropsUpdating || dateStr) {
      return;
    }
    let event = {
      type: "change",
      target: {
        value: dateStr,
        name: props.name,
        id: props.id,
        sourceEvent: "blur",
      },
    };
    updateEvent = true;
    return props.onChange(event);
  };
  const selectTextElementContent = (event) => {
    setTimeout(event.target.select.bind(event.target));
  };
  const handleKeyEvent = (e) => {
    if (e.ctrlKey === true && e.keyCode === 13) {
      const calendar = $(`.bootstrap-datetimepicker-widget`);
      if (calendar.length !== 0) {
        const btn = calendar.find(".picker-switch.accordion-toggle");
        if (btn !== 0) {
          btn.find("a").click();
        }
      }
    } else if (!(e.keyCode === 9 || e.altKey)) {
      const d = datePicker._dates[0] || datePicker.getMoment();
      if (e.ctrlKey === false && e.keyCode === 38) {
        //Up Arrow Key
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().subtract(7, "d"));
        } else {
          datePicker.date(d.clone().add(datePicker.stepping(), "m"));
        }
      } else if (e.ctrlKey === false && e.keyCode === 40) {
        // Down Arrow Key
        if (!datePicker.widget) {
          datePicker.show();
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().add(7, "d"));
        } else {
          datePicker.date(d.clone().subtract(datePicker.stepping(), "m"));
        }
      } else if (e.ctrlKey === true && e.keyCode === 38) {
        //Control Up Arrow Key
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().subtract(1, "y"));
        } else {
          datePicker.date(d.clone().add(1, "h"));
        }
      } else if (e.ctrlKey === true && e.keyCode === 40) {
        //Control Down Arrow Key
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().add(1, "y"));
        } else {
          datePicker.date(d.clone().subtract(1, "h"));
        }
      } else if (e.keyCode === 37) {
        //Left Arrow Key
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().subtract(1, "d"));
        }
      } else if (e.keyCode === 39) {
        //Right Arrow Key
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().add(1, "d"));
        }
      } else if (e.keyCode === 33) {
        //Page Up
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().subtract(1, "M"));
        }
      } else if (e.keyCode === 34) {
        //Page Down
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".datepicker").is(":visible")) {
          datePicker.date(d.clone().add(1, "M"));
        }
      } else if (e.keyCode === 13) {
        //Enter
        if (!datePicker.widget) {
          return false;
        }
        datePicker.hide();
      } else if (e.keyCode === 27) {
        //Escape
        if (!datePicker.widget) {
          return false;
        }
        datePicker.hide();
      } else if (e.ctrlKey === true && e.keyCode === 32) {
        //Control Space
        if (!datePicker.widget) {
          return false;
        }
        if (datePicker.widget.find(".timepicker").is(":visible")) {
          datePicker.widget.find('.btn[data-action="togglePeriod"]').click();
        }
      } else if (e.keyCode === 84) {
        //Key T
        if (!datePicker.widget) {
          return false;
        }
        datePicker.date(datePicker.getMoment());
      } else if (e.keyCode === 46) {
        //Delete
        if (!datePicker.widget) {
          return false;
        }
        datePicker.clear();
      }

      return false;
    }
  };

  const clearRef = (e) => {
    if (props.maxDateRef) {
      let maxEle = $("#" + props.maxDateRef + "Parent");
      if (maxEle.length) {
        let maxDtPk = maxEle.data("datetimepicker").date();
        if (!maxDtPk) {
          datePickerElement.data("datetimepicker").maxDate(false);
          datePickerElement.data("datetimepicker").minDate(false);
        } 
      }
    }
    if (props.minDateRef) {
      let minEle = $("#" + props.minDateRef + "Parent");
      if (minEle.length) {
        let minDtPk = minEle.data("datetimepicker").date();
        if (!minDtPk) {
          datePickerElement.data("datetimepicker").minDate(false);
          datePickerElement.data("datetimepicker").maxDate(false);
        }
      }
    }

    if (!updateEvent) {
      const dateStr = textInputElement.current.value;
      let event = {
        type: "change",
        target: {
          value: dateStr,
          name: props.name,
          id: props.id,
          sourceEvent: "input-blur",
        },
      };
      return props.onChange(event);
    }
  };
  const scrollEventListener = (e) => {
    datePicker.hide()
  }
  const handleIconClickEvent = (event) => {
    datePickerElement.on("change.datetimepicker", onChange);
    if (!intialClick) {
      datePickerElement.on("focusout.datetimepicker", onBlur);
    }
    intialClick = false;
    //Start

    //End
    if (datePicker.widget) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    } else {
      datePicker.show();
    }
    window.addEventListener('scroll',scrollEventListener, {once: true})
    window.addEventListener('wheel',scrollEventListener, {once: true})
  };

  const handleIconKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (!datePicker.widget) {
        datePicker.show();
      }
    }
  };
  const validatedate = (dateStr) => {
    //Validate MM-DD-YYYY,MM/DD/YYY,DD-MM-YYYY,DD/MM/YYYY,MM/DD/YY,MM-DD-YY format
    var dateformat;
    var formatConf = window._kaledo["format"]["dateFormat"];
    // Validate AM PM am pm
    if (
      props.pickerOptions.format.substr(-1) === "a" ||
      props.pickerOptions.format.substr(-1) === "A"
    ) {
      return moment(dateStr, props.pickerOptions.format, true).isValid();
    }
    //
    if (pickerOptions && pickerOptions.format) {
      formatConf = pickerOptions.format;
    }
    
    if (formatConf === "MM-DD-YYYY")
      dateformat = /^(0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])[\-]\d{4}$/;
    if (formatConf === "MM/DD/YYYY")
      dateformat = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;
    if (formatConf === "DD-MM-YYYY")
      dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/;
    if (formatConf === "DD/MM/YYYY")
      dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
    if (formatConf === "MM/DD/YY" || formatConf === "mm/dd/yy")
      dateformat = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{2}$/;
    if (formatConf === "MM-DD-YY" || formatConf === "mm-dd-yy")
      dateformat = /^(0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])[\-]\d{2}$/;
    if (
      dateStr !== "" &&
      dateStr.match(dateformat) &&
      dateStr.length == formatConf.length
    ) {
      var oper1 = dateStr.split("/");
      var oper2 = dateStr.split("-");
      var lenoper1 = oper1.length;
      var lenoper2 = oper2.length;
      if (lenoper1 > 1) {
        var pdate = dateStr.split("/");
      } else if (lenoper2 > 1) {
        pdate = dateStr.split("-");
      }
      var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (formatConf === "MM-DD-YYYY" || formatConf === "MM/DD/YYYY") {
        var mm = parseInt(pdate[0]);
        var dd = parseInt(pdate[1]);
        var yy = parseInt(pdate[2]);
      } else if (formatConf === "DD-MM-YYYY" || formatConf === "DD/MM/YYYY") {
        dd = parseInt(pdate[0]);
        mm = parseInt(pdate[1]);
        yy = parseInt(pdate[2]);
      }
      if (mm == 1 || mm > 2) {
        if (dd > ListofDays[mm - 1]) {
          return false;
        }
      }
      if (mm == 2 && yy.toString().length === 4) {
        var lyear = false;
        if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
          lyear = true;
        }
        if (lyear == false && dd >= 29) {
          return false;
        }
        if (lyear == true && dd > 29) {
          return false;
        }
      }
    } else {
      return false;
    }

    return true;
  };

  const {
    name,
    placeholder,
    disabled,
    required,
    readOnly,
    autoComplete,
    bsStyle,
    className = "",
    format,
  } = props;

  let dateFormatMaxlen = window._kaledo["format"]["dateFormat"].length;
  if (pickerOptions && pickerOptions.format) {
    dateFormatMaxlen = pickerOptions.format.length;
    if (pickerOptions.format.includes("A")) {
      dateFormatMaxlen += 1;
    }
  }
  const bsClass = bsStyle ? `has-${bsStyle}` : "";

  return (
    <div
      style={{ position: "relative" }}
      id={componentId}
      className={className}
      data-target-input="nearest"
    >
      <input
        ref={textInputElement}
        className={`${bsClass} form-control form-control-sm datetimepicker-input`}
        type="text"
        name={name}
        id={name}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        onFocus={selectTextElementContent}
        onKeyDown={handleKeyEvent}
        onKeyUp={(e) => {
          props.onKeyUp(e);
        }}
        data-target={"#" + componentId}
        maxLength={dateFormatMaxlen}
        aria-label="Choose Date"
        autoComplete="off"
        data-testid={props.name}
        onBlur={clearRef}
        onChange={onChange}
      />
      <div
        className="input-group-append"
        data-target={"#" + componentId}
        data-toggle="datetimepicker"
        id={name+"Cal"}
        onMouseDown={handleIconClickEvent}
        onKeyDown={handleIconKeyEvent}
      >
        <div className="input-group-text">
          <i className="fa fa-calendar"></i>
        </div>
      </div>
    </div>
  );
}
DateTime.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  bsStyle: PropTypes.oneOf(["", "success", "warning", "error"]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoComplete: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, //Accept a moment object
  ]),

  pickerOptions: PropTypes.shape({
    format: PropTypes.string,
    locale: PropTypes.string,
    minDate: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
    maxDate: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
    disabledDates: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
    daysOfWeekDisabled: PropTypes.arrayOf(PropTypes.number),
    viewMode: PropTypes.oneOf(["decades", "years", "months", "days"]),
    allowInputToggle: PropTypes.bool,
    calendarWeeks: PropTypes.bool,
    toolbarPlacement: PropTypes.oneOf(["default", "top", "bottom"]),
    tooltips: PropTypes.shape({
      today: PropTypes.string,
      clear: PropTypes.string,
      close: PropTypes.string,
      selectMonth: PropTypes.string,
      prevMonth: PropTypes.string,
      nextMonth: PropTypes.string,
      selectYear: PropTypes.string,
      prevYear: PropTypes.string,
      nextYear: PropTypes.string,
      selectDecade: PropTypes.string,
      prevDecade: PropTypes.string,
      nextDecade: PropTypes.string,
      prevCentury: PropTypes.string,
      nextCentury: PropTypes.string,
    }),
    widgetPositioning: PropTypes.object,
    focusOnShow: PropTypes.bool,
  }),
};
DateTime.defaultProps = {
  id: "react-datetime-bootstrap",
  onChange: () => null,
  onBlur: () => null,
  onKeyUp: () => null,
  pickerOptions: { ...defaultPickerOptions },
};
export { DateTime };
