/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ReportPreviewWR from "./ReportPreviewWR";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReportPreviewWR Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ReportPreviewWR />);
    });
  });
  afterEach(cleanup);
  test("is ReportPreviewWR Loads Successfully", () => {
    expect(screen.getByText("ReportPreviewWR")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReportPreviewWR", () => {
    // START_USER_CODE-USER_ReportPreviewWR_Custom_Test_Case
    // END_USER_CODE-USER_ReportPreviewWR_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ReportPreviewWR />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:ReportPreviewWR_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("WarehouseReceipts:ReportPreviewWR_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrintSetup(Button Widget) Test Cases", async () => {
    const btnPrintSetup = screen.getByTestId("btnPrintSetup");
    expect(btnPrintSetup).toBeInTheDocument;
    expect(btnPrintSetup.textContent).toEqual(
      t("WarehouseReceipts:ReportPreviewWR_btnPrintSetup")
    );
  });
  test("Custom Test Cases for btnPrintSetup", () => {
    // START_USER_CODE-USER_btnPrintSetup_TEST
    // END_USER_CODE-USER_btnPrintSetup_TEST
  });
  test("btnSaveAsPDF(Button Widget) Test Cases", async () => {
    const btnSaveAsPDF = screen.getByTestId("btnSaveAsPDF");
    expect(btnSaveAsPDF).toBeInTheDocument;
    expect(btnSaveAsPDF.textContent).toEqual(
      t("WarehouseReceipts:ReportPreviewWR_btnSaveAsPDF")
    );
  });
  test("Custom Test Cases for btnSaveAsPDF", () => {
    // START_USER_CODE-USER_btnSaveAsPDF_TEST
    // END_USER_CODE-USER_btnSaveAsPDF_TEST
  });
  test("grpbxPreview(GroupBox Widget) Test Cases", async () => {
    const grpbxPreview = screen.getByTestId("grpbxPreview");
    expect(grpbxPreview.tagName).toBe("BUTTON");
    expect(grpbxPreview.type).toBe("button");
    expect(grpbxPreview.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPreview", () => {
    // START_USER_CODE-USER_grpbxPreview_TEST
    // END_USER_CODE-USER_grpbxPreview_TEST
  });
  test("grpbxReportPreviewWR(GroupBox Widget) Test Cases", async () => {
    const grpbxReportPreviewWR = screen.getByTestId("grpbxReportPreviewWR");
    expect(grpbxReportPreviewWR.tagName).toBe("BUTTON");
    expect(grpbxReportPreviewWR.type).toBe("button");
    expect(grpbxReportPreviewWR.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReportPreviewWR", () => {
    // START_USER_CODE-USER_grpbxReportPreviewWR_TEST
    // END_USER_CODE-USER_grpbxReportPreviewWR_TEST
  });
});
