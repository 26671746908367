/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable,
  goTo,
  hide,
  show,
  clearValue
} from "../../shared/WindowImports";

import "./VendorWireTransfer.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_VendorWireTransfer(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VendorWireTransfer",
    windowName: "VendorWireTransfer",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.VendorWireTransfer",
    // START_USER_CODE-USER_VendorWireTransfer_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Vendor Select",
      scrCode: "PN0204B",
    },
    // END_USER_CODE-USER_VendorWireTransfer_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Calcel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnSelect: {
      name: "btnSelect",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Select",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSelect_PROPERTIES

      // END_USER_CODE-USER_btnSelect_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxVendorWireTransfer",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransfer",
      Label: "Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransfer",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransfer",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxVendorWireTransfer: {
      name: "grpbxVendorWireTransfer",
      type: "GroupBoxWidget",
      parent: "VendorWireTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxVendorWireTransfer_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorWireTransfer_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "VendorWireTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
      }
    }
  };

  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  //Custom useEffect
  useEffect(() => {
    clearValue(thisObj, 'txtVendorNum');
    clearValue(thisObj, 'txtName');
    hide(thisObj, 'txtName',false);
    hide(thisObj, "btnSelect",false);
    try {
      let vendor_lookup = getData(thisObj, 'vendorDetails');
      if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
        thisObj.setFieldValue("txtVendorNum", vendor_lookup.VendorNumber);
        thisObj.setFieldValue("txtName", vendor_lookup.vendorName);
        show(thisObj, 'txtName');
        show(thisObj, "btnSelect");
      }
    } catch (err) {
      showMessage(err.message);
    }
  }, [getData(thisObj, 'vendorDetails')]);
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  // Form Load
  function FormLoad() {
    setValue(thisObj, 'txtVendorNum', '');
    setValue(thisObj, 'txtName', '');
    disable(thisObj, 'txtName')
    hide(thisObj, 'txtName',false)
  }
  // Implement Select button functionality
  const onbtnSelectClick = async () => {
    try {
      let VendorDatils = {
        msVendorNumber: getValue(thisObj, 'txtVendorNum'),
        msVendorName: getValue(thisObj, 'txtName')
      }
      setData(thisObj, "VendorSelectDetails", VendorDatils);
      setData(thisObj, 'closingOfVendorWireTransfer', true)
      document.getElementById("SystemMaintenanceSpecialFunctions_VendorWireTransferPopUp").childNodes[0].click();
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnSelectClick = onbtnSelectClick
  // Logic for change vendor
  const ontxtVendorNumChange = async () => {
    try {
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
     let txtsVendor = getValue(thisObj, 'txtVendorNum').toUpperCase()  
      let data = await VerifyVendor();
      if (txtsVendor.length == 6) {
        if (data == true) {
          show(thisObj, "txtName");
          show(thisObj, 'btnSelect');
        }
        else {
          alert("Vendor does not exist.");
          hide(thisObj, "txtName",false);
          hide(thisObj, "btnSelect",false);
        }
      }
      else {
        hide(thisObj, "btnSelect",false);
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtVendorNumChange = ontxtVendorNumChange  
  // Verify Vendor
  async function VerifyVendor() {
    try {
      let VendorNum = getValue(thisObj, "txtVendorNum");
      let venname = null;
      if (VendorNum.length > 5) {
        let response = await ContractManagementService.RetrieveVendorByNumber(VendorNum)
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          venname = data[0].name;
        }
        if (venname != null || venname != undefined) {
          setValue(thisObj, "txtName", venname)
          return true;
        }
        else {
          setValue(thisObj, "txtName", '')
          return false;
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  // START_USER_CODE-USER_METHODS
  //Click event to add Vendor button
  // const onbtnVendorClick = async () => {
  //   try {
  //     goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
  //   }
  //   catch (err) {
  //     errorHandler(err)
  //   }
  // };
  // thisObj.onbtnVendorClick = onbtnVendorClick;

  // Implement functionality for Cancel button___
  const onbtnCancelClick = () => {
    try {
      clearValue(thisObj, 'txtVendorNum');
      clearValue(thisObj, 'txtName');
      setData(thisObj, "VendorSelectDetails", null);
      setData(thisObj, 'closingOfVendorWireTransfer', true)
      document.getElementById("SystemMaintenanceSpecialFunctions_VendorWireTransferPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VendorWireTransfer*/}

              {/* END_USER_CODE-USER_BEFORE_VendorWireTransfer*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorWireTransfer*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorWireTransfer*/}

              <GroupBoxWidget
                conf={state.grpbxVendorWireTransfer}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorWireTransfer*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorWireTransfer*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSelect*/}

                {/* END_USER_CODE-USER_BEFORE_btnSelect*/}

                <ButtonWidget
                  conf={state.btnSelect}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSelect*/}

                {/* END_USER_CODE-USER_AFTER_btnSelect*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_VendorWireTransfer*/}

              {/* END_USER_CODE-USER_AFTER_VendorWireTransfer*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_VendorWireTransfer);
