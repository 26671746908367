/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  TextAreaWidget,
  setValue,
  DateWidget,
  getValue,
  enable,
  disable,
  setData,
  getData,
  hide,
  show,
  isEnabled,
  goTo,
  setLabel,
  AutocompleteWidget,
  isVisible,
  hideColumn,
  setFieldValues,
  hideWidgets,
  showWidgets
} from "../../shared/WindowImports";

import "./FV95.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import CleaningCuringCosts from "../CleaningCuringCosts/CleaningCuringCosts";
import { response } from "msw";
import ScaleTickets from "../ScaleTickets/ScaleTickets";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_FV95(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const [stopFirstRender, setStopFirstRender] = useState(0);
  const [useSettlementDate, setSettlementDate] = useState();
  const [TICKET_TOTAL_CURING_COST, setTotalCuringCost] = useState(0);
  const [TICKET_TOTAL_CLEANING_COST, setTotalCleaningCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [requireStrip,setRequireStrip] = useState(true)
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FV95",
    windowName: "FV95",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.FV95",
    horizontalForm: true,
    // START_USER_CODE-USER_FV95_PROPERTIES
    headerData: {
      scrName: "SC95 - Inspection",
      scrCode: "PN1030A",
    },

    // END_USER_CODE-USER_FV95_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxWeightTickets",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCalculate: {
      name: "btnCalculate",
      type: "ButtonWidget",
      parent: "grpbxValueCalculation",
      Label: "Calculate",
      CharWidth: "20",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnCalculate_PROPERTIES

      // END_USER_CODE-USER_btnCalculate_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnChange: {
      name: "btnChange",
      type: "ButtonWidget",
      parent: "grpbxFarmInfo",
      Label: "Change #",
      CharWidth: "19",
      // START_USER_CODE-USER_btnChange_PROPERTIES

      // END_USER_CODE-USER_btnChange_PROPERTIES
    },
    btnConvertHMC: {
      name: "btnConvertHMC",
      type: "ButtonWidget",
      parent: "grpbxHMC",
      Label: "Convert HMC",
      CharWidth: "25",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnConvertHMC_PROPERTIES

      // END_USER_CODE-USER_btnConvertHMC_PROPERTIES
    },
    btnCreate: {
      name: "btnCreate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreate_PROPERTIES

      // END_USER_CODE-USER_btnCreate_PROPERTIES
    },
    btnCreate1007: {
      name: "btnCreate1007",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create 1007",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreate1007_PROPERTIES

      // END_USER_CODE-USER_btnCreate1007_PROPERTIES
    },
    btnCreateFarm: {
      name: "btnCreateFarm",
      type: "ButtonWidget",
      parent: "grpbxFarmInfo",
      Label: "Create",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCreateFarm_PROPERTIES

      // END_USER_CODE-USER_btnCreateFarm_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxWeightTickets",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxWeightTickets",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnReprint: {
      name: "btnReprint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reprint",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnVendor0: {
      name: "btnVendor0",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor0_PROPERTIES

      // END_USER_CODE-USER_btnVendor0_PROPERTIES
    },
    btnVendor1: {
      name: "btnVendor1",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor1_PROPERTIES

      // END_USER_CODE-USER_btnVendor1_PROPERTIES
    },
    btnVendor10: {
      name: "btnVendor10",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor10_PROPERTIES

      // END_USER_CODE-USER_btnVendor10_PROPERTIES
    },
    btnVendor11: {
      name: "btnVendor11",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor11_PROPERTIES

      // END_USER_CODE-USER_btnVendor11_PROPERTIES
    },
    btnVendor12: {
      name: "btnVendor12",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor12_PROPERTIES

      // END_USER_CODE-USER_btnVendor12_PROPERTIES
    },
    btnVendor13: {
      name: "btnVendor13",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor13_PROPERTIES

      // END_USER_CODE-USER_btnVendor13_PROPERTIES
    },
    btnVendor14: {
      name: "btnVendor14",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor14_PROPERTIES

      // END_USER_CODE-USER_btnVendor14_PROPERTIES
    },
    btnVendor15: {
      name: "btnVendor15",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor15_PROPERTIES

      // END_USER_CODE-USER_btnVendor15_PROPERTIES
    },
    btnVendor16: {
      name: "btnVendor16",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor16_PROPERTIES

      // END_USER_CODE-USER_btnVendor16_PROPERTIES
    },
    btnVendor17: {
      name: "btnVendor17",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor17_PROPERTIES

      // END_USER_CODE-USER_btnVendor17_PROPERTIES
    },
    btnVendor18: {
      name: "btnVendor18",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor18_PROPERTIES

      // END_USER_CODE-USER_btnVendor18_PROPERTIES
    },
    btnVendor19: {
      name: "btnVendor19",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor19_PROPERTIES

      // END_USER_CODE-USER_btnVendor19_PROPERTIES
    },
    btnVendor2: {
      name: "btnVendor2",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor2_PROPERTIES

      // END_USER_CODE-USER_btnVendor2_PROPERTIES
    },
    btnVendor3: {
      name: "btnVendor3",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor3_PROPERTIES

      // END_USER_CODE-USER_btnVendor3_PROPERTIES
    },
    btnVendor4: {
      name: "btnVendor4",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor4_PROPERTIES

      // END_USER_CODE-USER_btnVendor4_PROPERTIES
    },
    btnVendor5: {
      name: "btnVendor5",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor5_PROPERTIES

      // END_USER_CODE-USER_btnVendor5_PROPERTIES
    },
    btnVendor6: {
      name: "btnVendor6",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor6_PROPERTIES

      // END_USER_CODE-USER_btnVendor6_PROPERTIES
    },
    btnVendor7: {
      name: "btnVendor7",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor7_PROPERTIES

      // END_USER_CODE-USER_btnVendor7_PROPERTIES
    },
    btnVendor8: {
      name: "btnVendor8",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor8_PROPERTIES

      // END_USER_CODE-USER_btnVendor8_PROPERTIES
    },
    btnVendor9: {
      name: "btnVendor9",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor9_PROPERTIES

      // END_USER_CODE-USER_btnVendor9_PROPERTIES
    },
    chkboxCMA0: {
      name: "chkboxCMA0",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA0_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA0_PROPERTIES
    },
    chkboxCMA13: {
      name: "chkboxCMA13",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA13_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA13_PROPERTIES
    },
    chkboxCMA1: {
      name: "chkboxCMA1",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA1_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA1_PROPERTIES
    },
    chkboxCMA10: {
      name: "chkboxCMA10",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA10_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA10_PROPERTIES
    },
    chkboxCMA11: {
      name: "chkboxCMA11",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA11_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA11_PROPERTIES
    },
    chkboxCMA12: {
      name: "chkboxCMA12",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA12_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA12_PROPERTIES
    },
    chkboxCMA14: {
      name: "chkboxCMA14",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA14_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA14_PROPERTIES
    },
    chkboxCMA15: {
      name: "chkboxCMA15",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA15_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA15_PROPERTIES
    },
    chkboxCMA16: {
      name: "chkboxCMA16",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA16_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA16_PROPERTIES
    },
    chkboxCMA17: {
      name: "chkboxCMA17",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA17_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA17_PROPERTIES
    },
    chkboxCMA18: {
      name: "chkboxCMA18",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA18_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA18_PROPERTIES
    },
    chkboxCMA19: {
      name: "chkboxCMA19",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA19_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA19_PROPERTIES
    },
    chkboxCMA2: {
      name: "chkboxCMA2",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA2_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA2_PROPERTIES
    },
    chkboxCMA3: {
      name: "chkboxCMA3",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA3_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA3_PROPERTIES
    },
    chkboxCMA4: {
      name: "chkboxCMA4",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA4_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA4_PROPERTIES
    },
    chkboxCMA5: {
      name: "chkboxCMA5",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA5_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA5_PROPERTIES
    },
    chkboxCMA6: {
      name: "chkboxCMA6",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA6_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA6_PROPERTIES
    },
    chkboxCMA7: {
      name: "chkboxCMA7",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA7_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA7_PROPERTIES
    },
    chkboxCMA8: {
      name: "chkboxCMA8",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA8_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA8_PROPERTIES
    },
    chkboxCMA9: {
      name: "chkboxCMA9",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA9_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA9_PROPERTIES
    },
    chkboxCornFound: {
      name: "chkboxCornFound",
      type: "CheckBoxWidget",
      parent: "grpbxGradeCalculation",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCornFound_PROPERTIES

      // END_USER_CODE-USER_chkboxCornFound_PROPERTIES
    },
    chkboxDefault: {
      name: "chkboxDefault",
      type: "CheckBoxWidget",
      parent: "grpbxCMAInformation",
      Label: "Default",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDefault_PROPERTIES

      // END_USER_CODE-USER_chkboxDefault_PROPERTIES
    },
    chkboxFlavusFound: {
      name: "chkboxFlavusFound",
      type: "CheckBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxFlavusFound_PROPERTIES

      // END_USER_CODE-USER_chkboxFlavusFound_PROPERTIES
    },
    chkboxOrganic: {
      name: "chkboxOrganic",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutInfo",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOrganic_PROPERTIES

      // END_USER_CODE-USER_chkboxOrganic_PROPERTIES
    },
    chkboxVicam: {
      name: "chkboxVicam",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutInfo",
      Label: "Vicam",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxVicam_PROPERTIES

      // END_USER_CODE-USER_chkboxVicam_PROPERTIES
    },
    colCCTicket: {
      name: "colCCTicket",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "C/C ticket #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCTicket_PROPERTIES

      // END_USER_CODE-USER_colCCTicket_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colWeightTicket: {
      name: "colWeightTicket",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "Weight Ticket #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWeightTicket_PROPERTIES

      // END_USER_CODE-USER_colWeightTicket_PROPERTIES
    },
    colWtOfPeanuts: {
      name: "colWtOfPeanuts",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "Wt. of Peanuts",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWtOfPeanuts_PROPERTIES

      // END_USER_CODE-USER_colWtOfPeanuts_PROPERTIES
    },
    ddBuyingPt: {
      name: "ddBuyingPt",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Buying Pt:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPt_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPt_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmInfo",
      Label: "County:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Edible Oil:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmInfo",
      Label: "Farm:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES
      optionMaxLength: 25,
      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddIrrigatedDryland: {
      name: "ddIrrigatedDryland",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmInformation",
      Label: "Irrigated/Dryland:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddIrrigatedDryland_PROPERTIES

      // END_USER_CODE-USER_ddIrrigatedDryland_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmInfo",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddObligationPt: {
      name: "ddObligationPt",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Whouse / Bin #:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddObligationPt_PROPERTIES

      // END_USER_CODE-USER_ddObligationPt_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Oleic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxCMAInformation",
      Label: "Remit To:",
      DataProviderForDropDown: "inline",

      optionMaxLength: 25,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddRemitTo0: {
      name: "ddRemitTo0",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo0_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo0_PROPERTIES
    },
    ddRemitTo1: {
      name: "ddRemitTo1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo1_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo1_PROPERTIES
    },
    ddRemitTo10: {
      name: "ddRemitTo10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo10_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo10_PROPERTIES
    },
    ddRemitTo11: {
      name: "ddRemitTo11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo11_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo11_PROPERTIES
    },
    ddRemitTo12: {
      name: "ddRemitTo12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo12_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo12_PROPERTIES
    },
    ddRemitTo13: {
      name: "ddRemitTo13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo13_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo13_PROPERTIES
    },
    ddRemitTo14: {
      name: "ddRemitTo14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo14_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo14_PROPERTIES
    },
    ddRemitTo15: {
      name: "ddRemitTo15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo15_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo15_PROPERTIES
    },
    ddRemitTo16: {
      name: "ddRemitTo16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo16_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo16_PROPERTIES
    },
    ddRemitTo17: {
      name: "ddRemitTo17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo17_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo17_PROPERTIES
    },
    ddRemitTo18: {
      name: "ddRemitTo18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo18_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo18_PROPERTIES
    },
    ddRemitTo19: {
      name: "ddRemitTo19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo19_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo19_PROPERTIES
    },
    ddRemitTo2: {
      name: "ddRemitTo2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo2_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo2_PROPERTIES
    },
    ddRemitTo3: {
      name: "ddRemitTo3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo3_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo3_PROPERTIES
    },
    ddRemitTo4: {
      name: "ddRemitTo4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo5: {
      name: "ddRemitTo5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo5_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo5_PROPERTIES
    },
    ddRemitTo6: {
      name: "ddRemitTo6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo6_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo6_PROPERTIES
    },
    ddRemitTo7: {
      name: "ddRemitTo7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo7_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo7_PROPERTIES
    },
    ddRemitTo8: {
      name: "ddRemitTo8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo8_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo8_PROPERTIES
    },
    ddRemitTo9: {
      name: "ddRemitTo9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 33,
      // START_USER_CODE-USER_ddRemitTo9_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo9_PROPERTIES
    },
    ddSeedGen: {
      name: "ddSeedGen",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seed Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGen_PROPERTIES

      // END_USER_CODE-USER_ddSeedGen_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seg:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmInfo",
      Label: "State:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    ddVendor0: {
      name: "ddVendor0",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor0_PROPERTIES

      // END_USER_CODE-USER_ddVendor0_PROPERTIES
    },
    ddVendor1: {
      name: "ddVendor1",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor1_PROPERTIES

      // END_USER_CODE-USER_ddVendor1_PROPERTIES
    },
    ddVendor10: {
      name: "ddVendor10",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor10_PROPERTIES

      // END_USER_CODE-USER_ddVendor10_PROPERTIES
    },
    ddVendor11: {
      name: "ddVendor11",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor11_PROPERTIES

      // END_USER_CODE-USER_ddVendor11_PROPERTIES
    },
    ddVendor12: {
      name: "ddVendor12",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor12_PROPERTIES

      // END_USER_CODE-USER_ddVendor12_PROPERTIES
    },
    ddVendor13: {
      name: "ddVendor13",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor13_PROPERTIES

      // END_USER_CODE-USER_ddVendor13_PROPERTIES
    },
    ddVendor14: {
      name: "ddVendor14",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor14_PROPERTIES

      // END_USER_CODE-USER_ddVendor14_PROPERTIES
    },
    ddVendor15: {
      name: "ddVendor15",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor15_PROPERTIES

      // END_USER_CODE-USER_ddVendor15_PROPERTIES
    },
    ddVendor16: {
      name: "ddVendor16",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor16_PROPERTIES

      // END_USER_CODE-USER_ddVendor16_PROPERTIES
    },
    ddVendor17: {
      name: "ddVendor17",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor17_PROPERTIES

      // END_USER_CODE-USER_ddVendor17_PROPERTIES
    },
    ddVendor18: {
      name: "ddVendor18",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor18_PROPERTIES

      // END_USER_CODE-USER_ddVendor18_PROPERTIES
    },
    ddVendor19: {
      name: "ddVendor19",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor19_PROPERTIES

      // END_USER_CODE-USER_ddVendor19_PROPERTIES
    },
    ddVendor2: {
      name: "ddVendor2",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor2_PROPERTIES

      // END_USER_CODE-USER_ddVendor2_PROPERTIES
    },
    ddVendor3: {
      name: "ddVendor3",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor3_PROPERTIES

      // END_USER_CODE-USER_ddVendor3_PROPERTIES
    },
    ddVendor4: {
      name: "ddVendor4",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor4_PROPERTIES

      // END_USER_CODE-USER_ddVendor4_PROPERTIES
    },
    ddVendor5: {
      name: "ddVendor5",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor5_PROPERTIES

      // END_USER_CODE-USER_ddVendor5_PROPERTIES
    },
    ddVendor6: {
      name: "ddVendor6",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor6_PROPERTIES

      // END_USER_CODE-USER_ddVendor6_PROPERTIES
    },
    ddVendor7: {
      name: "ddVendor7",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor7_PROPERTIES

      // END_USER_CODE-USER_ddVendor7_PROPERTIES
    },
    ddVendor8: {
      name: "ddVendor8",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor8_PROPERTIES

      // END_USER_CODE-USER_ddVendor8_PROPERTIES
    },
    ddVendor9: {
      name: "ddVendor9",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      valueListForAutocomplete: "Option1:1,Option2:2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendor9_PROPERTIES

      // END_USER_CODE-USER_ddVendor9_PROPERTIES
    },
    ddWhouseBin: {
      name: "ddWhouseBin",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhouseBin_PROPERTIES

      // END_USER_CODE-USER_ddWhouseBin_PROPERTIES
    },
    gridWeightTickets: {
      name: "gridWeightTickets",
      type: "GridWidget",
      parent: "grpbxWeightTickets",
      gridCellsOrder:
        "txtcolWeightTicket,txtcolDate,txtcolWtOfPeanuts,txtcolCCTicket",
      ColSpan: "6",
      Pagination: false,
      HasLabel: false,
      Cols: "6",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridWeightTickets_PROPERTIES

      // END_USER_CODE-USER_gridWeightTickets_PROPERTIES
    },
    txtConcatedData: {
      name: "txtConcatedData",
      type: "TextBoxWidget",
      colName: "colConcatedData",
      parent: "gridWeightTickets",
      //CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWeightTicket_PROPERTIES

      // END_USER_CODE-USER_txtcolWeightTicket_PROPERTIES
    },
    colConcatedData: {
      name: "colConcatedData",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWeightTicket_PROPERTIES

      // END_USER_CODE-USER_colWeightTicket_PROPERTIES
    },
    grpbxCMAInformation: {
      name: "grpbxCMAInformation",
      type: "GroupBoxWidget",
      parent: "grpbxFarmInformation",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCMAInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxCMAInformation_PROPERTIES
    },
    grpbxFarmInfo: {
      name: "grpbxFarmInfo",
      type: "GroupBoxWidget",
      parent: "grpbxFarmInformation",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFarmInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmInfo_PROPERTIES
    },
    grpbxVendorSplitRow0: {
      name: "grpbxVendorSplitRow0",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      clonedExtId: "59875",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow1: {
      name: "grpbxVendorSplitRow1",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES
    },
    grpbxVendorSplitRow10: {
      name: "grpbxVendorSplitRow10",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES
    },
    grpbxVendorSplitRow11: {
      name: "grpbxVendorSplitRow11",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES
    },
    grpbxVendorSplitRow12: {
      name: "grpbxVendorSplitRow12",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES
    },
    grpbxVendorSplitRow13: {
      name: "grpbxVendorSplitRow13",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES
    },
    grpbxVendorSplitRow14: {
      name: "grpbxVendorSplitRow14",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES
    },
    grpbxVendorSplitRow15: {
      name: "grpbxVendorSplitRow15",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES
    },
    grpbxVendorSplitRow16: {
      name: "grpbxVendorSplitRow16",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES
    },
    grpbxVendorSplitRow17: {
      name: "grpbxVendorSplitRow17",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES
    },
    grpbxVendorSplitRow18: {
      name: "grpbxVendorSplitRow18",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES
    },
    grpbxVendorSplitRow19: {
      name: "grpbxVendorSplitRow19",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES
    },
    grpbxVendorSplitRow2: {
      name: "grpbxVendorSplitRow2",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES
    },
    grpbxVendorSplitRow3: {
      name: "grpbxVendorSplitRow3",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES
    },
    grpbxVendorSplitRow4: {
      name: "grpbxVendorSplitRow4",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES
    },
    grpbxVendorSplitRow5: {
      name: "grpbxVendorSplitRow5",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES
    },
    grpbxVendorSplitRow6: {
      name: "grpbxVendorSplitRow6",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow6_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow6_PROPERTIES
    },
    grpbxVendorSplitRow7: {
      name: "grpbxVendorSplitRow7",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES
    },
    grpbxVendorSplitRow8: {
      name: "grpbxVendorSplitRow8",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES
    },
    grpbxVendorSplitRow9: {
      name: "grpbxVendorSplitRow9",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES
    },
    grpbxVirginiaGrading: {
      name: "grpbxVirginiaGrading",
      type: "GroupBoxWidget",
      parent: "grpbxGradeCalculation",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxVirginiaGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxVirginiaGrading_PROPERTIES
    },
    lbl1007Control: {
      name: "lbl1007Control",
      type: "LabelWidget",
      parent: "grpbx1007Control",
      Label: "1007 Control",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl1007Control_PROPERTIES

      // END_USER_CODE-USER_lbl1007Control_PROPERTIES
    },
    lblAdd1007Aplcnt: {
      name: "lblAdd1007Aplcnt",
      type: "LabelWidget",
      parent: "grpbx1007Control",
      Label: "Additional 1007 Applicant",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAdd1007Aplcnt_PROPERTIES

      // END_USER_CODE-USER_lblAdd1007Aplcnt_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      ColSpan: "6",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblAuditCount: {
      name: "lblAuditCount",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Audit Count:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAuditCount_PROPERTIES

      // END_USER_CODE-USER_lblAuditCount_PROPERTIES
    },
    lblAuditCountValue: {
      name: "lblAuditCountValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAuditCountValue_PROPERTIES

      // END_USER_CODE-USER_lblAuditCountValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCorpYear2: {
      name: "lblCorpYear2",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "lblCorpYear2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblCMAInformation: {
      name: "lblCMAInformation",
      type: "LabelWidget",
      parent: "grpbxCMAInformation",
      Label: "CMA Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCMAInformation_PROPERTIES

      // END_USER_CODE-USER_lblCMAInformation_PROPERTIES
    },
    lblCMAParticipant: {
      name: "lblCMAParticipant",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "CMA Participant",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCMAParticipant_PROPERTIES

      // END_USER_CODE-USER_lblCMAParticipant_PROPERTIES
    },
    lblCornFound: {
      name: "lblCornFound",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Corn Found:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCornFound_PROPERTIES

      // END_USER_CODE-USER_lblCornFound_PROPERTIES
    },
    lblDateTimeFormat: {
      name: "lblDateTimeFormat",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "mm/dd/yyyy hh:mm AM/PM",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateTimeFormat_PROPERTIES

      // END_USER_CODE-USER_lblDateTimeFormat_PROPERTIES
    },
    lblDelPtObligation: {
      name: "lblDelPtObligation",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Delivery Point/Obligation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDelPtObligation_PROPERTIES

      // END_USER_CODE-USER_lblDelPtObligation_PROPERTIES
    },
    lblFarmInformation: {
      name: "lblFarmInformation",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "Farm Information",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarmInformation_PROPERTIES

      // END_USER_CODE-USER_lblFarmInformation_PROPERTIES
    },

    lblSC95Submit: {

      name: "lblSC95Submit",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSC95Submit_PROPERTIES

      // END_USER_CODE-USER_lblSC95Submit_PROPERTIES
    },

    lblFlavusFound: {
      name: "lblFlavusFound",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Flavus Found:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFlavusFound_PROPERTIES

      // END_USER_CODE-USER_lblFlavusFound_PROPERTIES
    },
    lblfreefv95: {
      name: "lblfreefv95",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "lblfreefv95",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblfreefv95_PROPERTIES

      // END_USER_CODE-USER_lblfreefv95_PROPERTIES
    },
    lblGradeCalculation: {
      name: "lblGradeCalculation",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grade Calculation",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGradeCalculation_PROPERTIES

      // END_USER_CODE-USER_lblGradeCalculation_PROPERTIES
    },
    lblGrams1: {
      name: "lblGrams1",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrams1_PROPERTIES

      // END_USER_CODE-USER_lblGrams1_PROPERTIES
    },
    lblGrams2: {
      name: "lblGrams2",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrams2_PROPERTIES

      // END_USER_CODE-USER_lblGrams2_PROPERTIES
    },
    lblHMCConvertedGrades: {
      name: "lblHMCConvertedGrades",
      type: "LabelWidget",
      parent: "grpbxHMC",
      Label: "HMC Converted Grades:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblHMCConvertedGrades_PROPERTIES

      // END_USER_CODE-USER_lblHMCConvertedGrades_PROPERTIES
    },
    lblInspectorRemarks: {
      name: "lblInspectorRemarks",
      type: "LabelWidget",
      parent: "grpbxInspectorRemarks",
      Label: "Inspector Remarks",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_lblInspectorRemarks_PROPERTIES
    },
    lblFormDirty: {
      name: "lblFormDirty",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "Load Type",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFormDirty_PROPERTIES

      // END_USER_CODE-USER_lblFormDirty_PROPERTIES
    },
    lblLoadType2: {
      name: "lblLoadType2",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "Load Type",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoadType2_PROPERTIES

      // END_USER_CODE-USER_lblLoadType2_PROPERTIES
    },
    lblN: {
      name: "lblN",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "N",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblN_PROPERTIES

      // END_USER_CODE-USER_lblN_PROPERTIES
    },
    lblOrganic: {
      name: "lblOrganic",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Organic:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOrganic_PROPERTIES

      // END_USER_CODE-USER_lblOrganic_PROPERTIES
    },
    lblPct1: {
      name: "lblPct1",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct1_PROPERTIES

      // END_USER_CODE-USER_lblPct1_PROPERTIES
    },
    lblPct2: {
      name: "lblPct2",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct2_PROPERTIES

      // END_USER_CODE-USER_lblPct2_PROPERTIES
    },
    lblPeanutInformation: {
      name: "lblPeanutInformation",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutInformation_PROPERTIES

      // END_USER_CODE-USER_lblPeanutInformation_PROPERTIES
    },
    lblPerLb: {
      name: "lblPerLb",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb_PROPERTIES

      // END_USER_CODE-USER_lblPerLb_PROPERTIES
    },
    lblPerLb2: {
      name: "lblPerLb2",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb2_PROPERTIES

      // END_USER_CODE-USER_lblPerLb2_PROPERTIES
    },
    lblPerLb3: {
      name: "lblPerLb3",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb3_PROPERTIES

      // END_USER_CODE-USER_lblPerLb3_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "$/ton",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    lblRemitTo: {
      name: "lblRemitTo",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Remit To",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo_PROPERTIES
    },
    lblSC95: {
      name: "lblSC95",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "SC95",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSC95_PROPERTIES

      // END_USER_CODE-USER_lblSC95_PROPERTIES
    },
    lblSettleDate: {
      name: "lblSettleDate",
      type: "LabelWidget",
      parent: "grpbx1007Control",
      Label: "SettleDate",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSettleDate_PROPERTIES

      // END_USER_CODE-USER_lblSettleDate_PROPERTIES
    },
    lblSharePct: {
      name: "lblSharePct",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePct_PROPERTIES

      // END_USER_CODE-USER_lblSharePct_PROPERTIES
    },
    lblTicketUploadMod: {
      name: "lblTicketUploadMod",
      type: "LabelWidget",
      parent: "grpbxFarmInfo",
      Label: "lblTicketUploadMod",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTicketUploadMod_PROPERTIES

      // END_USER_CODE-USER_lblTicketUploadMod_PROPERTIES
    },
    lblUnloadedInfo: {
      name: "lblUnloadedInfo",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Unloaded Info:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUnloadedInfo_PROPERTIES

      // END_USER_CODE-USER_lblUnloadedInfo_PROPERTIES
    },
    lblValueCalculation: {
      name: "lblValueCalculation",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Calculation",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValueCalculation_PROPERTIES

      // END_USER_CODE-USER_lblValueCalculation_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVendor0: {
      name: "lblVendor0",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor0_PROPERTIES

      // END_USER_CODE-USER_lblVendor0_PROPERTIES
    },
    lblVendor1: {
      name: "lblVendor1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor1_PROPERTIES

      // END_USER_CODE-USER_lblVendor1_PROPERTIES
    },
    lblVendor10: {
      name: "lblVendor10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor10_PROPERTIES

      // END_USER_CODE-USER_lblVendor10_PROPERTIES
    },
    lblVendor11: {
      name: "lblVendor11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor11_PROPERTIES

      // END_USER_CODE-USER_lblVendor11_PROPERTIES
    },
    lblVendor12: {
      name: "lblVendor12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor12_PROPERTIES

      // END_USER_CODE-USER_lblVendor12_PROPERTIES
    },
    lblVendor13: {
      name: "lblVendor13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor13_PROPERTIES

      // END_USER_CODE-USER_lblVendor13_PROPERTIES
    },
    lblVendor14: {
      name: "lblVendor14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor14_PROPERTIES

      // END_USER_CODE-USER_lblVendor14_PROPERTIES
    },
    lblVendor15: {
      name: "lblVendor15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor15_PROPERTIES

      // END_USER_CODE-USER_lblVendor15_PROPERTIES
    },
    lblVendor16: {
      name: "lblVendor16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor16_PROPERTIES

      // END_USER_CODE-USER_lblVendor16_PROPERTIES
    },
    lblVendor17: {
      name: "lblVendor17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor17_PROPERTIES

      // END_USER_CODE-USER_lblVendor17_PROPERTIES
    },
    lblVendor18: {
      name: "lblVendor18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor18_PROPERTIES

      // END_USER_CODE-USER_lblVendor18_PROPERTIES
    },
    lblVendor19: {
      name: "lblVendor19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor19_PROPERTIES

      // END_USER_CODE-USER_lblVendor19_PROPERTIES
    },
    lblVendor2: {
      name: "lblVendor2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor2_PROPERTIES

      // END_USER_CODE-USER_lblVendor2_PROPERTIES
    },
    lblVendor3: {
      name: "lblVendor3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor3_PROPERTIES

      // END_USER_CODE-USER_lblVendor3_PROPERTIES
    },
    lblVendor4: {
      name: "lblVendor4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor4_PROPERTIES

      // END_USER_CODE-USER_lblVendor4_PROPERTIES
    },
    lblVendor5: {
      name: "lblVendor5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor5_PROPERTIES

      // END_USER_CODE-USER_lblVendor5_PROPERTIES
    },
    lblVendor6: {
      name: "lblVendor6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor6_PROPERTIES

      // END_USER_CODE-USER_lblVendor6_PROPERTIES
    },
    lblVendor7: {
      name: "lblVendor7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor7_PROPERTIES

      // END_USER_CODE-USER_lblVendor7_PROPERTIES
    },
    lblVendor8: {
      name: "lblVendor8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor8_PROPERTIES

      // END_USER_CODE-USER_lblVendor8_PROPERTIES
    },
    lblVendor9: {
      name: "lblVendor9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor9_PROPERTIES

      // END_USER_CODE-USER_lblVendor9_PROPERTIES
    },
    lblVendorSplits: {
      name: "lblVendorSplits",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor Splits:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorSplits_PROPERTIES

      // END_USER_CODE-USER_lblVendorSplits_PROPERTIES
    },
    lblVirginiaGrading: {
      name: "lblVirginiaGrading",
      type: "LabelWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Virginia Cracked/Broken grading",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVirginiaGrading_PROPERTIES

      // END_USER_CODE-USER_lblVirginiaGrading_PROPERTIES
    },
    lblWeightTickets: {
      name: "lblWeightTickets",
      type: "LabelWidget",
      parent: "grpbxWeightTickets",
      Label: "Weight Tickets",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWeightTickets_PROPERTIES

      // END_USER_CODE-USER_lblWeightTickets_PROPERTIES
    },
    radioHighMoisture: {
      name: "radioHighMoisture",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFarmInfo",
      Label: "High Moisture ?",
      ColSpan: "4",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioHighMoisture_PROPERTIES

      // END_USER_CODE-USER_radioHighMoisture_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbx1007Control",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtAccessFMLb: {
      name: "txtAccessFMLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccessFMLb_PROPERTIES

      // END_USER_CODE-USER_txtAccessFMLb_PROPERTIES
    },
    txtAccessFMTon: {
      name: "txtAccessFMTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess FM:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccessFMTon_PROPERTIES

      // END_USER_CODE-USER_txtAccessFMTon_PROPERTIES
    },
    txtAFlavusLb: {
      name: "txtAFlavusLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAFlavusLb_PROPERTIES

      // END_USER_CODE-USER_txtAFlavusLb_PROPERTIES
    },
    txtAFlavusTon: {
      name: "txtAFlavusTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "A. Flavus:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAFlavusTon_PROPERTIES

      // END_USER_CODE-USER_txtAFlavusTon_PROPERTIES
    },
    txtarInspectorRemarks: {
      name: "txtarInspectorRemarks",
      type: "TextAreaWidget",
      parent: "grpbxInspectorRemarks",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarInspectorRemarks_PROPERTIES
    },
    txtBluePanWt: {
      name: "txtBluePanWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Blue Pan Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBluePanWt_PROPERTIES

      // END_USER_CODE-USER_txtBluePanWt_PROPERTIES
    },
    txtCleanSampleWt: {
      name: "txtCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Clean Sample Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCleanSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtCleanSampleWt_PROPERTIES
    },
    txtCMAVendor: {
      name: "txtCMAVendor",
      type: "TextBoxWidget",
      parent: "grpbxCMAInformation",
      Label: "CMA Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMAVendor_PROPERTIES

      // END_USER_CODE-USER_txtCMAVendor_PROPERTIES
    },
    txtcolCCTicket: {
      name: "txtcolCCTicket",
      type: "TextBoxWidget",
      colName: "colCCTicket",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCTicket_PROPERTIES

      // END_USER_CODE-USER_txtcolCCTicket_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolWeightTicket: {
      name: "txtcolWeightTicket",
      type: "TextBoxWidget",
      colName: "colWeightTicket",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWeightTicket_PROPERTIES

      // END_USER_CODE-USER_txtcolWeightTicket_PROPERTIES
    },
    txtcolWtOfPeanuts: {
      name: "txtcolWtOfPeanuts",
      type: "TextBoxWidget",
      colName: "colWtOfPeanuts",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWtOfPeanuts_PROPERTIES

      // END_USER_CODE-USER_txtcolWtOfPeanuts_PROPERTIES
    },
    txtConcealedRMDGr: {
      name: "txtConcealedRMDGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Concealed RMD:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMDGr_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMDGr_PROPERTIES
    },
    txtConcealedRMDPct: {
      name: "txtConcealedRMDPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES
    },
    txtCrkOrBrkShellsGr: {
      name: "txtCrkOrBrkShellsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Crk, or Brk Shells:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrkOrBrkShellsGr_PROPERTIES

      // END_USER_CODE-USER_txtCrkOrBrkShellsGr_PROPERTIES
    },
    txtCrkOrBrkShellsPct: {
      name: "txtCrkOrBrkShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrkOrBrkShellsPct_PROPERTIES

      // END_USER_CODE-USER_txtCrkOrBrkShellsPct_PROPERTIES
    },
    txtCRMD: {
      name: "txtCRMD",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "C RMD:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCRMD_PROPERTIES

      // END_USER_CODE-USER_txtCRMD_PROPERTIES
    },
    txtDAM: {
      name: "txtDAM",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "DAM:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDAM_PROPERTIES

      // END_USER_CODE-USER_txtDAM_PROPERTIES
    },
    txtDamagedKRS: {
      name: "txtDamagedKRS",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Damaged KRS:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDamageLbs: {
      name: "txtDamageLbs",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageLbs_PROPERTIES

      // END_USER_CODE-USER_txtDamageLbs_PROPERTIES
    },
    txtDamageSplits: {
      name: "txtDamageSplits",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Damage Splits:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageSplits_PROPERTIES

      // END_USER_CODE-USER_txtDamageSplits_PROPERTIES
    },
    txtDamageTon: {
      name: "txtDamageTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Damage:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageTon_PROPERTIES

      // END_USER_CODE-USER_txtDamageTon_PROPERTIES
    },
    txtDiscoloredShellsGr: {
      name: "txtDiscoloredShellsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Discolored Shells:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShellsGr_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShellsGr_PROPERTIES
    },
    txtDiscoloredShellsPct: {
      name: "txtDiscoloredShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES
    },
    txtELK: {
      name: "txtELK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "ELK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELK_PROPERTIES

      // END_USER_CODE-USER_txtELK_PROPERTIES
    },
    txtELKDamage: {
      name: "txtELKDamage",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "ELK Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKDamage_PROPERTIES

      // END_USER_CODE-USER_txtELKDamage_PROPERTIES
    },
    txtELKGr: {
      name: "txtELKGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "ELK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKGr_PROPERTIES

      // END_USER_CODE-USER_txtELKGr_PROPERTIES
    },
    txtELKPct: {
      name: "txtELKPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPct_PROPERTIES

      // END_USER_CODE-USER_txtELKPct_PROPERTIES
    },
    txtELKPremiumLb: {
      name: "txtELKPremiumLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPremiumLb_PROPERTIES

      // END_USER_CODE-USER_txtELKPremiumLb_PROPERTIES
    },
    txtELKPremiumTon: {
      name: "txtELKPremiumTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ELK Premium:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPremiumTon_PROPERTIES

      // END_USER_CODE-USER_txtELKPremiumTon_PROPERTIES
    },
    txtEVehicleNum: {
      name: "txtEVehicleNum",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Vehicle No.:",
      ColSpan: "6",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtEVehicleNum_PROPERTIES
    },
    txtEWeightTkt: {
      name: "txtEWeightTkt",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Weight Tkt:",
      ColSpan: "6",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWeightTkt_PROPERTIES

      // END_USER_CODE-USER_txtEWeightTkt_PROPERTIES
    },
    txtEWtInclVeh: {
      name: "txtEWtInclVeh",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt.Incl.Veh:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWtInclVeh_PROPERTIES

      // END_USER_CODE-USER_txtEWtInclVeh_PROPERTIES
    },
    txtEWtOfPnut: {
      name: "txtEWtOfPnut",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt. of Pnut:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWtOfPnut_PROPERTIES

      // END_USER_CODE-USER_txtEWtOfPnut_PROPERTIES
    },
    txtEWtOfVeh: {
      name: "txtEWtOfVeh",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt. of Veh:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWtOfVeh_PROPERTIES

      // END_USER_CODE-USER_txtEWtOfVeh_PROPERTIES
    },
    txtExcessMoisture: {
      name: "txtExcessMoisture",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess Moisture:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessMoisture_PROPERTIES

      // END_USER_CODE-USER_txtExcessMoisture_PROPERTIES
    },
    txtExcessSplitsLb: {
      name: "txtExcessSplitsLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessSplitsLb_PROPERTIES

      // END_USER_CODE-USER_txtExcessSplitsLb_PROPERTIES
    },
    txtExcessSplitsTon: {
      name: "txtExcessSplitsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ExcessSplits",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessSplitsTon_PROPERTIES

      // END_USER_CODE-USER_txtExcessSplitsTon_PROPERTIES
    },
    txtFANCY: {
      name: "txtFANCY",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FANCY:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFANCY_PROPERTIES

      // END_USER_CODE-USER_txtFANCY_PROPERTIES
    },
    txtFancyGr: {
      name: "txtFancyGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Fancy:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancyGr_PROPERTIES

      // END_USER_CODE-USER_txtFancyGr_PROPERTIES
    },
    txtFancyPct: {
      name: "txtFancyPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancyPct_PROPERTIES

      // END_USER_CODE-USER_txtFancyPct_PROPERTIES
    },
    txtFM: {
      name: "txtFM",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FM:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFM_PROPERTIES

      // END_USER_CODE-USER_txtFM_PROPERTIES
    },
    txtFMSampleWt: {
      name: "txtFMSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "FM Sample Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFMSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtFMSampleWt_PROPERTIES
    },
    txtForeignMaterial: {
      name: "txtForeignMaterial",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Foreign Material:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterial_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterial_PROPERTIES
    },
    txtForeignMaterialGr: {
      name: "txtForeignMaterialGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Foreign Material:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterialGr_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterialGr_PROPERTIES
    },
    txtForeignMaterialPct: {
      name: "txtForeignMaterialPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES
    },
    txtFreezeDamageGr: {
      name: "txtFreezeDamageGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Freeze Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamageGr_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamageGr_PROPERTIES
    },
    txtFreezeDamagePct: {
      name: "txtFreezeDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES
    },
    txtFRZ: {
      name: "txtFRZ",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FRZ:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFRZ_PROPERTIES

      // END_USER_CODE-USER_txtFRZ_PROPERTIES
    },
    txtG: {
      name: "txtG",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "/G:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtG_PROPERTIES

      // END_USER_CODE-USER_txtG_PROPERTIES
    },
    txtGrams: {
      name: "txtGrams",
      type: "TextBoxWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Grams:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrams_PROPERTIES

      // END_USER_CODE-USER_txtGrams_PROPERTIES
    },
    txtGrossWeight: {
      name: "txtGrossWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Gross Weight:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrossWeight_PROPERTIES

      // END_USER_CODE-USER_txtGrossWeight_PROPERTIES
    },
    txtH: {
      name: "txtH",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "H x :",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtH_PROPERTIES

      // END_USER_CODE-USER_txtH_PROPERTIES
    },
    txtHullBrightness: {
      name: "txtHullBrightness",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Hulls Brightness:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightness_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightness_PROPERTIES
    },
    txtHullBrightnessPct: {
      name: "txtHullBrightnessPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES
    },
    txtHULLS: {
      name: "txtHULLS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "HULLS:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHULLS_PROPERTIES

      // END_USER_CODE-USER_txtHULLS_PROPERTIES
    },
    txtHullsGr: {
      name: "txtHullsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Hulls:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsGr_PROPERTIES

      // END_USER_CODE-USER_txtHullsGr_PROPERTIES
    },
    txtHullsPct: {
      name: "txtHullsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsPct_PROPERTIES

      // END_USER_CODE-USER_txtHullsPct_PROPERTIES
    },
    txtInspectionDateTime: {
      name: "txtInspectionDateTime",
      type: "DateTimeWidget",
      parent: "grpbxFarmInfo",
      Label: "Inspection Date/Time:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtInspectionDateTime_PROPERTIES

      // END_USER_CODE-USER_txtInspectionDateTime_PROPERTIES
    },
    txtJumboGr: {
      name: "txtJumboGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Jumbo:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboGr_PROPERTIES

      // END_USER_CODE-USER_txtJumboGr_PROPERTIES
    },
    txtJumboPct: {
      name: "txtJumboPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboPct_PROPERTIES

      // END_USER_CODE-USER_txtJumboPct_PROPERTIES
    },
    txtKernelsREL: {
      name: "txtKernelsREL",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Kernels REL:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelsREL_PROPERTIES

      // END_USER_CODE-USER_txtKernelsREL_PROPERTIES
    },
    txtKernelsRPS: {
      name: "txtKernelsRPS",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Kernels RPS:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelsRPS_PROPERTIES

      // END_USER_CODE-USER_txtKernelsRPS_PROPERTIES
    },
    txtKernelValueLb: {
      name: "txtKernelValueLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelValueLb_PROPERTIES

      // END_USER_CODE-USER_txtKernelValueLb_PROPERTIES
    },
    txtKernelValueTon: {
      name: "txtKernelValueTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Kernel Value:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelValueTon_PROPERTIES

      // END_USER_CODE-USER_txtKernelValueTon_PROPERTIES
    },
    txtlblTotalDiscountsLb: {
      name: "txtlblTotalDiscountsLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtlblTotalDiscountsLb_PROPERTIES

      // END_USER_CODE-USER_txtlblTotalDiscountsLb_PROPERTIES
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "LSK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSK_PROPERTIES

      // END_USER_CODE-USER_txtLSK_PROPERTIES
    },
    txtLSKGr: {
      name: "txtLSKGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "LSK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKGr_PROPERTIES

      // END_USER_CODE-USER_txtLSKGr_PROPERTIES
    },
    txtLSKPct: {
      name: "txtLSKPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKPct_PROPERTIES

      // END_USER_CODE-USER_txtLSKPct_PROPERTIES
    },
    txtLSKvc: {
      name: "txtLSKvc",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKvc_PROPERTIES

      // END_USER_CODE-USER_txtLSKvc_PROPERTIES
    },
    txtMeterReading: {
      name: "txtMeterReading",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Meter Reading:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMeterReading_PROPERTIES

      // END_USER_CODE-USER_txtMeterReading_PROPERTIES
    },
    txtMoisture: {
      name: "txtMoisture",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Moisture:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoisture_PROPERTIES

      // END_USER_CODE-USER_txtMoisture_PROPERTIES
    },
    txtMST: {
      name: "txtMST",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "MST:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMST_PROPERTIES

      // END_USER_CODE-USER_txtMST_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbx1007Control",
      Label: "Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtNetValPerTonExclSLK: {
      name: "txtNetValPerTonExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Value Per Ton Excluding SLK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES

      // END_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtNetWtExclLSK: {
      name: "txtNetWtExclLSK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight Excluding LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWtExclLSK_PROPERTIES

      // END_USER_CODE-USER_txtNetWtExclLSK_PROPERTIES
    },
    txtNumber: {
      name: "txtNumber",
      type: "TextBoxWidget",
      parent: "grpbx1007Control",
      Label: "Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNumber_PROPERTIES

      // END_USER_CODE-USER_txtNumber_PROPERTIES
    },
    txtOI: {
      name: "txtOI",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "O x I $:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOI_PROPERTIES

      // END_USER_CODE-USER_txtOI_PROPERTIES
    },
    txtOK: {
      name: "txtOK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "OK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOK_PROPERTIES

      // END_USER_CODE-USER_txtOK_PROPERTIES
    },
    txtOtherForeignMaterials: {
      name: "txtOtherForeignMaterials",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Other Foreign Materials:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherForeignMaterials_PROPERTIES

      // END_USER_CODE-USER_txtOtherForeignMaterials_PROPERTIES
    },
    txtOtherKernelsGr: {
      name: "txtOtherKernelsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Other Kernels:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernelsGr_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernelsGr_PROPERTIES
    },
    txtOtherKernelsPct: {
      name: "txtOtherKernelsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernelsPct_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernelsPct_PROPERTIES
    },
    txtPercent: {
      name: "txtPercent",
      type: "TextBoxWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Percent:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPercent_PROPERTIES

      // END_USER_CODE-USER_txtPercent_PROPERTIES
    },
    txtProbingPattern: {
      name: "txtProbingPattern",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Probing Pattern:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProbingPattern_PROPERTIES

      // END_USER_CODE-USER_txtProbingPattern_PROPERTIES
    },
    txtReading: {
      name: "txtReading",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfo",
      Label: "Reading:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReading_PROPERTIES

      // END_USER_CODE-USER_txtReading_PROPERTIES
    },
    txtRevision: {
      name: "txtRevision",
      type: "TextBoxWidget",
      parent: "grpbxFarmInfo",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRevision_PROPERTIES

      // END_USER_CODE-USER_txtRevision_PROPERTIES
    },
    txtSampleWt: {
      name: "txtSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Sample Wt:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtSampleWt_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxFarmInfo",
      Label: "SC95 #",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtSeedMeetingSpec: {
      name: "txtSeedMeetingSpec",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seed Meeting Specification:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedMeetingSpec_PROPERTIES

      // END_USER_CODE-USER_txtSeedMeetingSpec_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Seg:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtSharePct0: {
      name: "txtSharePct0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct0_PROPERTIES

      // END_USER_CODE-USER_txtSharePct0_PROPERTIES
    },
    txtSharePct1: {
      name: "txtSharePct1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct1_PROPERTIES

      // END_USER_CODE-USER_txtSharePct1_PROPERTIES
    },
    txtSharePct10: {
      name: "txtSharePct10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct10_PROPERTIES

      // END_USER_CODE-USER_txtSharePct10_PROPERTIES
    },
    txtSharePct11: {
      name: "txtSharePct11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct11_PROPERTIES

      // END_USER_CODE-USER_txtSharePct11_PROPERTIES
    },
    txtSharePct12: {
      name: "txtSharePct12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct12_PROPERTIES

      // END_USER_CODE-USER_txtSharePct12_PROPERTIES
    },
    txtSharePct13: {
      name: "txtSharePct13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct13_PROPERTIES

      // END_USER_CODE-USER_txtSharePct13_PROPERTIES
    },
    txtSharePct14: {
      name: "txtSharePct14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct14_PROPERTIES

      // END_USER_CODE-USER_txtSharePct14_PROPERTIES
    },
    txtSharePct15: {
      name: "txtSharePct15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct15_PROPERTIES

      // END_USER_CODE-USER_txtSharePct15_PROPERTIES
    },
    txtSharePct16: {
      name: "txtSharePct16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct16_PROPERTIES

      // END_USER_CODE-USER_txtSharePct16_PROPERTIES
    },
    txtSharePct17: {
      name: "txtSharePct17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct17_PROPERTIES

      // END_USER_CODE-USER_txtSharePct17_PROPERTIES
    },
    txtSharePct18: {
      name: "txtSharePct18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct18_PROPERTIES

      // END_USER_CODE-USER_txtSharePct18_PROPERTIES
    },
    txtSharePct19: {
      name: "txtSharePct19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct19_PROPERTIES

      // END_USER_CODE-USER_txtSharePct19_PROPERTIES
    },
    txtSharePct2: {
      name: "txtSharePct2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct2_PROPERTIES

      // END_USER_CODE-USER_txtSharePct2_PROPERTIES
    },
    txtSharePct3: {
      name: "txtSharePct3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct3_PROPERTIES

      // END_USER_CODE-USER_txtSharePct3_PROPERTIES
    },
    txtSharePct4: {
      name: "txtSharePct4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct4_PROPERTIES

      // END_USER_CODE-USER_txtSharePct4_PROPERTIES
    },
    txtSharePct5: {
      name: "txtSharePct5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct5_PROPERTIES

      // END_USER_CODE-USER_txtSharePct5_PROPERTIES
    },
    txtSharePct6: {
      name: "txtSharePct6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct6_PROPERTIES

      // END_USER_CODE-USER_txtSharePct6_PROPERTIES
    },
    txtSharePct7: {
      name: "txtSharePct7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct7_PROPERTIES

      // END_USER_CODE-USER_txtSharePct7_PROPERTIES
    },
    txtSharePct8: {
      name: "txtSharePct8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct8_PROPERTIES

      // END_USER_CODE-USER_txtSharePct8_PROPERTIES
    },
    txtSharePct9: {
      name: "txtSharePct9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct9_PROPERTIES

      // END_USER_CODE-USER_txtSharePct9_PROPERTIES
    },
    txtSMK: {
      name: "txtSMK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SMK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK_PROPERTIES

      // END_USER_CODE-USER_txtSMK_PROPERTIES
    },
    txtSMK1Gr: {
      name: "txtSMK1Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 1(Screen Size)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Gr_PROPERTIES
    },
    txtSMK1Pct: {
      name: "txtSMK1Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Pct_PROPERTIES
    },
    txtSMK2Gr: {
      name: "txtSMK2Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 2(Screen Size)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Gr_PROPERTIES
    },
    txtSMK2Pct: {
      name: "txtSMK2Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Pct_PROPERTIES
    },
    txtSMK3Gr: {
      name: "txtSMK3Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 3(Screen Size)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Gr_PROPERTIES
    },
    txtSMK3Pct: {
      name: "txtSMK3Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Pct_PROPERTIES
    },
    txtSMK4Gr: {
      name: "txtSMK4Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 4(Screen Size)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Gr_PROPERTIES
    },
    txtSMK4Pct: {
      name: "txtSMK4Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Pct_PROPERTIES
    },
    txtSMKRS: {
      name: "txtSMKRS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SMKRS:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKRS_PROPERTIES

      // END_USER_CODE-USER_txtSMKRS_PROPERTIES
    },
    txtSMKRSGr: {
      name: "txtSMKRSGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMKRS:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKRSGr_PROPERTIES

      // END_USER_CODE-USER_txtSMKRSGr_PROPERTIES
    },
    txtSMKRSPct: {
      name: "txtSMKRSPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKRSPct_PROPERTIES

      // END_USER_CODE-USER_txtSMKRSPct_PROPERTIES
    },
    txtSoundSplitsGr: {
      name: "txtSoundSplitsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Sound Splits:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplitsGr_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplitsGr_PROPERTIES
    },
    txtSoundSplitsPct: {
      name: "txtSoundSplitsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES
    },
    txtSS: {
      name: "txtSS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SS:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSS_PROPERTIES

      // END_USER_CODE-USER_txtSS_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbx1007Control",
      Label: "Status:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtTickets: {
      name: "txtTickets",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "Tickets:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTickets_PROPERTIES

      // END_USER_CODE-USER_txtTickets_PROPERTIES
    },
    txtTotalDamageGr: {
      name: "txtTotalDamageGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamageGr_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamageGr_PROPERTIES
    },
    txtTotalDamagePct: {
      name: "txtTotalDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamagePct_PROPERTIES
    },
    txtTotalDiscountsTon: {
      name: "txtTotalDiscountsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Discounts:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDiscountsTon_PROPERTIES

      // END_USER_CODE-USER_txtTotalDiscountsTon_PROPERTIES
    },
    txtTotalDollar: {
      name: "txtTotalDollar",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "Total $:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDollar_PROPERTIES

      // END_USER_CODE-USER_txtTotalDollar_PROPERTIES
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Total Kernels:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels_PROPERTIES
    },
    txtTotalKernelsHulls: {
      name: "txtTotalKernelsHulls",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Kernels & Hulls:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernelsHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernelsHulls_PROPERTIES
    },
    txtTotalKrnls: {
      name: "txtTotalKrnls",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Kernels:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKrnls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKrnls_PROPERTIES
    },
    txtTotalLb: {
      name: "txtTotalLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLb_PROPERTIES

      // END_USER_CODE-USER_txtTotalLb_PROPERTIES
    },
    txtTotalLBs: {
      name: "txtTotalLBs",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "Total LBs:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLBs_PROPERTIES

      // END_USER_CODE-USER_txtTotalLBs_PROPERTIES
    },
    txtTotalLoadVal: {
      name: "txtTotalLoadVal",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Value of Load (g):",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLoadVal_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoadVal_PROPERTIES
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total SMK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK_PROPERTIES
    },
    txtTotalTon: {
      name: "txtTotalTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTon_PROPERTIES

      // END_USER_CODE-USER_txtTotalTon_PROPERTIES
    },
    txtTotKrnlsHulls: {
      name: "txtTotKrnlsHulls",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Tot krnls + hulls(excl. lsk):",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotKrnlsHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotKrnlsHulls_PROPERTIES
    },
    txtValenciaCleanSampleWt: {
      name: "txtValenciaCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Valencia Clean Sample Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValenciaCleanSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtValenciaCleanSampleWt_PROPERTIES
    },
    txtValPerPoundExclSLK: {
      name: "txtValPerPoundExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Excluding LSK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES
    },
    txtValPerPoundInclSLK: {
      name: "txtValPerPoundInclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Including LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES
    },
    txtVoidReason: {
      name: "txtVoidReason",
      type: "TextBoxWidget",
      parent: "grpbx1007Control",
      Label: "Void Reason:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVoidReason_PROPERTIES

      // END_USER_CODE-USER_txtVoidReason_PROPERTIES
    },
    txtWtIncludingVehicle: {
      name: "txtWtIncludingVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. Including Vehicle:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES

      // END_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES
    },
    txtWtlessFM: {
      name: "txtWtlessFM",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Weight Less FM:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtlessFM_PROPERTIES

      // END_USER_CODE-USER_txtWtlessFM_PROPERTIES
    },
    txtWtOfVehicle: {
      name: "txtWtOfVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. of Vehicle:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtWtOfVehicleVal: {
      name: "txtWtOfVehicleVal",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicleVal_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicleVal_PROPERTIES
    },
    grpbxFarmInformation: {
      name: "grpbxFarmInformation",
      type: "GroupBoxWidget",
      parent: "FV95",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFarmInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmInformation_PROPERTIES
    },
    grpbxWeightTickets: {
      name: "grpbxWeightTickets",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "2",
      Height: "",
      Width: "",
      clonedExtId: "55982",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxWeightTickets_PROPERTIES

      // END_USER_CODE-USER_grpbxWeightTickets_PROPERTIES
    },
    grpbxWeightVendor: {
      name: "grpbxWeightVendor",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "3",
      HasLabel: false,
      Cols: "3",
      ColsForTabPotrait: "3",
      ColsForLargeDesktop: "3",
      // START_USER_CODE-USER_grpbxWeightVendor_PROPERTIES

      // END_USER_CODE-USER_grpbxWeightVendor_PROPERTIES
    },
    grpbx1007Peanut: {
      name: "grpbx1007Peanut",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbx1007Peanut_PROPERTIES

      // END_USER_CODE-USER_grpbx1007Peanut_PROPERTIES
    },
    grpbx1007Control: {
      name: "grpbx1007Control",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbx1007Control_PROPERTIES

      // END_USER_CODE-USER_grpbx1007Control_PROPERTIES
    },
    grpbxPeanutInfo: {
      name: "grpbxPeanutInfo",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES
    },
    grpbxVendorSplits: {
      name: "grpbxVendorSplits",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "2",
      Height: "",
      Width: "",
      clonedExtId: "56234",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplits_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplits_PROPERTIES
    },
    grpbxInspectorRemarks: {
      name: "grpbxInspectorRemarks",
      type: "GroupBoxWidget",
      parent: "FV95",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_grpbxInspectorRemarks_PROPERTIES
    },
    grpbxGradeCalculation: {
      name: "grpbxGradeCalculation",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxGradeCalculation_PROPERTIES

      // END_USER_CODE-USER_grpbxGradeCalculation_PROPERTIES
    },
    grpbxHMC: {
      name: "grpbxHMC",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxHMC_PROPERTIES

      // END_USER_CODE-USER_grpbxHMC_PROPERTIES
    },
    grpbxValueCalculation: {
      name: "grpbxValueCalculation",
      type: "GroupBoxWidget",
      parent: "FV95",
      ColSpan: "1",
      Height: "",
      Width: "",
      clonedExtId: "57107",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxValueCalculation_PROPERTIES

      // END_USER_CODE-USER_grpbxValueCalculation_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FV95",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      Visible: true,
      VisibleForLargeDesktop: true,
      VisibleForMobile: true,
      VisibleForTabLandscape: true,
      VisibleForTabPotrait: true,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#1007Settlement": {},
        "Settlements#ScaleTickets": {},
        "SystemMaintenanceMasterManagement#FarmSetup":{}
      },
      REVERSE: {
        "Settlements#1007Settlement": {},
        "Settlements#ScaleTickets": {},
        "SystemMaintenanceMasterManagement#FarmSetup":{}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCreate1007: {
    //   DEFAULT: ["Settlements#1007Settlement#DEFAULT#true#Click"],
    // },
    btnAdd: {
      DEFAULT: ["Settlements#ScaleTickets#DEFAULT#true#Click"],
    },
    btnEdit: {
      DEFAULT: ["Settlements#ScaleTickets#DEFAULT#true#Click"],
    },
    // added navigation for phase 4 screen FARM SETUP
    // btnCreateFarm:{
    //       DEFAULT:["SystemMaintenanceMasterManagement#FarmSetup#DEFAULT#true#Click"]
    //     },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  // Variables
  const [lblStatusTag, setLblStatusTag] = useState('');
  const [strHMC, setStrHMC] = useState('N');
  var isFormValid = false;
  const [getbPendingVoid, setbPendingVoid] = useState('');
  const [bAcceptAsSeedNotMeetingSpec, setbAcceptAsSeedNotMeetingSpec] = useState('');

  const [mbEVoidNoSale, setMbEVoidNoSale] = useState(false)
  const [lbl1007, setLbl1007] = useState('')
  const [mbSuperseded, setMbSuperseded] = useState(false);
  const [lblLoadType1, setLblLoadType1] = useState(false);
  const [lblLoadType2, setlblLoadType2] = useState('');
  const [mbSupersedePending, setmbSupersedePending] = useState(false)
  const [lblElecUploadInd, setLblElecUploadInd] = useState("N")
  const [Lblfreefv95, setLblfreefv95] = useState("")
  const [nStateIndex, setnStateIndex] = useState('')
  const [msStateSetting, setmsStateSetting] = useState('')
  const [nCountyIndex, setnCountyIndex] = useState('')
  const [msCountySetting, setmsCountySetting] = useState('')
  const [nLocationIndex, setnLocationIndex] = useState('')
  const [nFarmIndex, setnFarmIndex] = useState('')
  const [lblFV95Number, setlblFV95Number] = useState('')
  const [lblChangedBy, setlblChangedBy] = useState('')
  const [lblLastChange, setlblLastChange] = useState('')
  const [pbForceClose, setpbForceClose] = useState();
  const [yesNo, setYesNo] = useState(false)
  const [bSaveForm, setbSaveForm] = useState(false)
  const [cboOblBuyingPointtag, setcboOblBuyingPointtag] = useState("")
  const [mbAnotherFV95, setMbAnotherFV95] = useState(false)
  const [mbAnotherFV95NoReset, setMbAnotherFV95NoReset] = useState("")
  let lblTicket = "";
  let lblTotalLBs = "";
  let lblTotalDollars = "";
  let msTICKET_TOTAL_CURING_COST = 0;
  let msTICKET_TOTAL_CLEANING_COST = 0;
  const [stateTag, setStateTag] = useState("")
  const [countyTag, setCountyTag] = useState("")
  // Ticket Information Details
  const [sTICKET_NUMBERS_CURE_CLEAN, setsTICKET_NUMBERS_CURE_CLEAN] = useState("");
  const [sTICKET_CURING_WT_IND, setsTICKET_CURING_WT_IND] = useState("");
  const [sTICKET_CLEANING_WT_IND, setsTICKET_CLEANING_WT_IND] = useState("");
  const [sTICKET_MOISTURE, setsTICKET_MOISTURE] = useState(0);
  const [sTICKET_CLEAN_WT_OTHER, setsTICKET_CLEAN_WT_OTHER] = useState(0);
  const [sTICKET_CURE_WT_OTHER, setsTICKET_CURE_WT_OTHER] = useState(0);
  const [sTICKET_CURE_SCHEDULE, setsTICKET_CURE_SCHEDULE] = useState("");
  const [sTICKET_CLEAN_SCHEDULE, setsTICKET_CLEAN_SCHEDULE] = useState("");
  const [sTICKET_TOTAL_CURING_COST, setsTICKET_TOTAL_CURING_COST] = useState(0);
  const [sTICKET_TOTAL_CLEANING_COST, setsTICKET_TOTAL_CLEANING_COST] = useState(0);
  const [useTotalDollars, setTotalDollars] = useState("");
  const [dblMinKernHullPct, setdblMinKernHullPct] = useState();
  const [dblMaxKernHullPct, setdblMaxKernHullPct] = useState();
  const [lblSeedMeetingSpecification, setlblSeedMeetingSpecification] = useState();
  const [UseWeightOfIncludingVehicle, setWeightOfIncludingVehicle] = useState();
  const [UseWeightOfVehicle, setWeightOfVehicle] = useState();


  useEffect(() => {
    parentWindow(thisObj);
  }, [thisObj]);
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setData(thisObj, "vndrRowNo", 0)
    setData(thisObj, "arrRow", []);
    setData(thisObj, 'frmFV95Array', []);
    setData(thisObj, "TicketInfo", null);
    let stripCreate = getData(thisObj,'stripRequire')
    if(stripCreate== 'N'){
      setRequireStrip(false)
    }
    let data = getData(thisObj, 'FV95ScreenData')
    initialVendorsplithide();
    if (data.type == 'ADD') {
     
    setValue(thisObj, 'ddLocation', '');
    
    Formload();
    document.getElementById('txtWtOfVehicleVal').style.visibility = 'hidden';
    document.getElementsByClassName('btnReprint')[1].style.visibility = 'hidden';
    document.getElementById("txtDamagedKRS").style.backgroundColor = "gray";
    document.getElementById("txtELKDamage").style.backgroundColor = "gray";
    setlblLoadType2('ADD');
    }
  }, []);

  useEffect(() => {
    setStopFirstRender(stopFirstRender + 1)
    if (stopFirstRender >= 1) {
      let vndrnmbr = getData(thisObj, 'vendorDetails') == null ? '' : getData(thisObj, 'vendorDetails').VendorNumber
      getVendorDetails(vndrnmbr)
    }
  },
    [getData(thisObj, 'vendorDetails')])

  useEffect(() => {
    if (getData(thisObj, "UnloadFV95Screen")) {
      document.getElementsByClassName("close")[0].click();
    }
    setData(thisObj, 'UnloadFV95Screen', false)
  }, [getData(thisObj, "UnloadFV95Screen")])

  useEffect(() => {
    grdScaleTickets();
    // setData(thisObj, 'frmFV95Array', '');
  }, [getData(thisObj, 'frmFV95Array')])

  useEffect(() => {
    if (yesNo == true) {
      enable(thisObj, 'btnConvertHMC');
    }
    else {
      disable(thisObj, 'btnConvertHMC');
    }
    const vendorList = getData(thisObj, 'vendorList')
    EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, lblLoadType1)
  }, lblLoadType1)

  useEffect(() => {

    let data = getData(thisObj, 'FV95ScreenData')
    if (data != undefined) {
      if (data.type == 'EDIT') {
        setlblLoadType2('EDIT');
        setValue(thisObj, 'txtSC95', data.C_FV95_NUM)
        setData(thisObj, 'selectedRowReload', true);
        setData(thisObj, 'locationData', null);
        
        document.getElementById('txtWtOfVehicleVal').style.visibility = 'hidden';
        document.getElementsByClassName('btnReprint')[1].style.visibility = 'hidden';
        document.getElementById("txtDamagedKRS").style.backgroundColor = "gray";
        document.getElementById("txtELKDamage").style.backgroundColor = "gray";

        EnabelDisableGradeEntry('', '', '', '');
        bFillForm(data.C_FV95_NUM, data.buyPt)
      }
      else {
        setlblLoadType2('ADD');
      }
    }

  }, [getData(thisObj, 'FV95ScreenData')])

  useEffect(() => {
    if (lblLoadType1 == true) {
      if (yesNo == true) {
        enable(thisObj, 'btnConvertHMC')
      }
      else {
        disable(thisObj, 'btnConvertHMC')
      }
      const vendorList = getData(thisObj, 'vendorList')
      EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, lblLoadType1)
      setLblLoadType1(false);
    }
  }, [lblLoadType1 == true])

  useEffect(() => {
    let ticketInfo = getData(thisObj, "TicketInfo");
    if (ticketInfo !== '' && ticketInfo !== undefined && ticketInfo !== null) {
      setsTICKET_NUMBERS_CURE_CLEAN(ticketInfo.txtClnCrgTicketNumber);
      setsTICKET_CURING_WT_IND(ticketInfo.LstrTICKET_CURING_WT_IND);
      setsTICKET_CLEANING_WT_IND(ticketInfo.LstrTICKET_CLEANING_WT_IND);
      setsTICKET_MOISTURE(ticketInfo.LstrTICKET_MOISTURE);
      setsTICKET_CLEAN_WT_OTHER(ticketInfo.LstrTICKET_CLEAN_WT_OTHER);
      setsTICKET_CURE_WT_OTHER(ticketInfo.LstrTICKET_CURE_WT_OTHER);
      setsTICKET_CURE_SCHEDULE(ticketInfo.LstrTICKET_CURE_SCHEDULE);
      setsTICKET_CLEAN_SCHEDULE(ticketInfo.LstrTICKET_CLEAN_SCHEDULE);
      setsTICKET_TOTAL_CURING_COST(ticketInfo.lblCostToCure);
      setsTICKET_TOTAL_CLEANING_COST(ticketInfo.lblCostToClean);
      setTotalDollars(ticketInfo.lblCostToCure + ticketInfo.lblCostToClean);
    }
  }, [getData(thisObj, "TicketInfo")]);

  useEffect(() => {
    let nosaleData = getData(thisObj, 'UpdatedNoSaleVoidFV95');
    if (nosaleData !== undefined && nosaleData !== '' && nosaleData !== null) {
      let txtDC95 = getValue(thisObj, 'txtSC95');
      let buyPt = getValue(thisObj, 'ddLocation');
      bFillForm(txtDC95, buyPt);
    }
  }, [getData(thisObj, 'UpdatedNoSaleVoidFV95')])

  useEffect(() => {
    return () => {
      setData(thisObj, "ClosingofFV95", true)
    }
  })

  useEffect(() => {
    let farmSetupData=getData(thisObj, 'msSelectedFarmId')

    if(farmSetupData!=null){
      farmSetupDataChange(farmSetupData);
    }

  }, [getData(thisObj, 'msSelectedFarmId')])


  let farmSetupDataChange=async(farmSetupData)=>{
    setLoading(true);
    
    let buy_point_id=farmSetupData.buy_point_id
    let state_abbr=farmSetupData.state_abbr
    let county_id=farmSetupData.county_id
    let farmId=farmSetupData.farmDetails.split('-')[0]
    let farmSuffix=farmSetupData.farmDetails.split('-')[1]
    let farmName=farmSetupData.farmDetails.split("-")[2]

    setValue(thisObj,'ddFarm',(farmId + '-' + farmSuffix + '-' + farmName))
    await bFillVendorList(buy_point_id, state_abbr, county_id, farmId, farmSuffix)
    await ShowHidePanels(farmId);
    setLoading(false)
  }
  // Common Methods
  async function Formload() {

    // bLandType();
    // setValue(thisObj, "radioHighMoisture", '2');
    setLoading(true)
    setValue(thisObj, 'lblCorpYear2', "CropYear:" + JSON.parse(sessionStorage.getItem('year')))
    setValue(thisObj, 'txtStatus', 'Pending');
    await bFillContractControlLocationList();
    bFillTypeList()//Method for Peanut Type
    bFillSegmentList() // Method for binding Segment list  
    bFillGeneration() // Method for binding Seed Genration
    bFillOleicList() // Method for Oleic values 
    bFillEdibleOil()// //Method for binding Edible Oil
    bFillOblBuyingPointList()//Method for binding XREF buying points
    bFillOblWarehouseBinList()//Method for obligationpt drop down
    const vendorList = getData(thisObj, 'vendorList')
    await EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, lblLoadType1);
    // CalculateTotals();
    disable(thisObj, 'btnCreateFarm')
    // disable(thisObj, 'txtSeedMeetingSpec')

    disable(thisObj, 'btnChange')
    // disable(thisObj, 'txt1007')
    // setValue(thisObj, 'txt1007', '');
    // disable(thisObj, 'txtStatus')
    // disable(thisObj, 'txtVoidReason')

    //For grpbxHMC
    // disable(thisObj, 'btnConvertHMC')
    // disable(thisObj, 'txtSeg')
    // disable(thisObj, 'txtFM')
    // disable(thisObj, 'txtLSK')
    // disable(thisObj, 'txtMST')
    // disable(thisObj, 'txtSMK')
    // disable(thisObj, 'txtSS')
    // disable(thisObj, 'txtSMKRS')
    // disable(thisObj, 'txtOK')
    // disable(thisObj, 'txtFRZ')
    // disable(thisObj, 'txtCRMD')
    // disable(thisObj, 'txtTotalKernels')
    // disable(thisObj, 'txtDAM')
    // disable(thisObj, 'txtHULLS')
    // disable(thisObj, 'txtELK')
    // disable(thisObj, 'txtFANCY')
    // disable(thisObj, 'txtTotKrnlsHulls')
    // show(thisObj,'grpbxActions')
    let farmDetaIL=getValue(thisObj, 'ddFarm')
    let farM_ID=""
    if(farmDetaIL!=undefined && farmDetaIL!="" && farmDetaIL!=null){
      farM_ID=farmDetaIL.split('-')[0]
    }
    await ShowHidePanels(farM_ID)

    // hide(thisObj, 'grpbxCMAInformation')
    // hide(thisObj, 'ddIrrigatedDryland')
    // hide(thisObj,'txtValPerPoundInclSLK')
    // document.getElementById('txtValPerPoundInclSLK').style.visibility = 'hidden'

    
    // hide(thisObj,'txtRevision')
    document.getElementById("txtRevision").style.visibility = 'hidden';
    hide(thisObj,'ddIrrigatedDryland')
    //Hiding the checkboxes and the lable lblCMAParticipant in the formload
    hide(thisObj,'chkboxCMA0')
    hide(thisObj, 'chkboxCMA1')
    hide(thisObj, 'chkboxCMA2')
    hide(thisObj, 'chkboxCMA3')
    hide(thisObj,'lblCMAParticipant')
    setValue(thisObj, 'radioHighMoisture', '2')
    setYesNo(false)
    setLoading(false)
  }

  //Vendor Split js code
  const initialVendorsplithide = () => {
    // hide(thisObj, 'grpbxVendorSplitRow4')
    // hide(thisObj, 'grpbxVendorSplitRow5')
    // hide(thisObj, 'grpbxVendorSplitRow6')
    // hide(thisObj, 'grpbxVendorSplitRow7')
    // hide(thisObj, 'grpbxVendorSplitRow8')
    // hide(thisObj, 'grpbxVendorSplitRow9')
    // hide(thisObj, 'grpbxVendorSplitRow10')
    // hide(thisObj, 'grpbxVendorSplitRow11')
    // hide(thisObj, 'grpbxVendorSplitRow12')
    // hide(thisObj, 'grpbxVendorSplitRow13')
    // hide(thisObj, 'grpbxVendorSplitRow14')
    // hide(thisObj, 'grpbxVendorSplitRow15')
    // hide(thisObj, 'grpbxVendorSplitRow16')
    
    // hide(thisObj, 'grpbxVendorSplitRow17')
    // hide(thisObj, 'grpbxVendorSplitRow18')
    // hide(thisObj, 'grpbxVendorSplitRow19')
    let VendorSplitsHideArray = []
    for (let i = 4; i <= 19; i++) {
      VendorSplitsHideArray.push(`grpbxVendorSplitRow${i}`)
    }
    hideWidgets(thisObj, VendorSplitsHideArray)
  }

  const ddVendorBlur = (event) => {
    let cntrlId = event.target.name
    let vendor1 = event.target.value  
    // PRB0054498- Vendor numbers are not handled correctly_Convert the vendor value to uppercase
    if (vendor1) {
      vendor1 = vendor1.toUpperCase()
    }
    vendorFill(cntrlId, vendor1)
  }  

  const vendorFill = (cntrlId, vendor1) => {
    let currentRow = cntrlId.match(/(\d+)/)[0]
    if (vendor1 != "" && vendor1 != undefined) {
      setData(thisObj, "vndrRowNo", currentRow)
      setData(thisObj, "vndrid", vendor1)

      getVendorDetails(vendor1)
    }
    else {
      let newRemit = 'ddRemitTo' + currentRow
      let newSharePct = 'txtSharePct' + currentRow
      let lblVendor = 'lblVendor' + currentRow

      setValue(thisObj, newSharePct, "")
      setValue(thisObj, lblVendor, "")
      thisObj.state[newRemit].valueList = [];
      setTimeout(() => {
        setValue(thisObj, newRemit, '')
      }, 100);

    }

  }

  const getVendorDetails = (vendorId) => {
    if (vendorId !== "" && vendorId !== null && vendorId !== undefined) {
      let vndrRowNo = getData(thisObj, "vndrRowNo")
      ContractManagementService.RetieveRemitDetails(vendorId).then(response => {
        let data = response;

        if (data.length > 0) {
          let js = [];
          let lblXXname = data[0].name
          for (var i = 0; i < data.length; i++) {
            let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].address1 + '-' + data[i].city;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          let newVendor = 'ddVendor' + vndrRowNo
          let newLblVendor = 'lblVendor' + vndrRowNo
          let newRemitTo = 'ddRemitTo' + vndrRowNo
          let arrVal = getData(thisObj, "arrRow")

          setData(thisObj, "arrRow", [...arrVal, vndrRowNo])
          setValue(thisObj, newVendor, [vendorId])
          setValue(thisObj, newLblVendor, lblXXname)
          thisObj.state[newRemitTo].valueList = js;

          setTimeout(() => {
            setValue(thisObj, newRemitTo, js.at(0).key);
          }, 100);

          let finalArr = getData(thisObj, "arrRow")
          let result = finalArr.filter((arr) => {
            return (arr == vndrRowNo)
          })
          if (vndrRowNo >= 3 && vndrRowNo <= 18 && result.length <= 1) {
            let newGrpbx = `grpbxVendorSplitRow` + (Number(vndrRowNo) + 1)
            showWidgets(thisObj, [newGrpbx])
          }
        }
      })
    }
  }

  const bFillVendorList = async (buy_point_id, stateAbbr, county_id, farm_id, farmSuffix) => {
    try {
      if (buy_point_id != undefined && buy_point_id != '') {
        let response = await ContractManagementService.RetrieveFarmVendorSplitDetails(buy_point_id, stateAbbr, county_id, farm_id, farmSuffix)
        let vendorList = [];
        response.map((row) => {
          vendorList.push(row.split_vendor)
        })

        vendorList = vendorList.filter((item, index) => vendorList.indexOf(item) === index);

        setData(thisObj, 'vendorList', vendorList)
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {

            let remitResponse = await ContractManagementService.RetieveRemitDetails(response[i].split_vendor)

            if (remitResponse.length > 0) {
              let js = [];
              let lblXXname = remitResponse[0].name
              for (var j = 0; j < remitResponse.length; j++) {
                let description = remitResponse[j].remittoid + '-' + remitResponse[j].name + '-' + remitResponse[j].address1 + '-' + remitResponse[j].city;
                let obj = { key: remitResponse[j].remittoid, description: description };
                js.push(obj);
              }

              let newVendor = 'ddVendor' + i
              let newLblVendor = 'lblVendor' + i
              let newRemitTo = 'ddRemitTo' + i
              let newSharePercent = 'txtSharePct' + i
              let arrVal = getData(thisObj, "arrRow")
              setData(thisObj, "arrRow", [...arrVal, i])

              thisObj.state[newVendor].valueListForAutocomplete = vendorList
              setValue(thisObj, newVendor, [response[i].split_vendor])
              setValue(thisObj, newLblVendor, lblXXname)
              setValue(thisObj, newSharePercent, response[i].split_share_pct)
              thisObj.state[newRemitTo].valueList = js;

              setTimeout(() => {

                setValue(thisObj, newRemitTo, response[i].split_remit);

              }, 100);

              let finalArr = getData(thisObj, "arrRow")
              let result = finalArr.filter((arr) => {
                return (arr == i)
              })

              if (i >= 3 && i <= 18 && result.length <= 1) {
                let newGrpbx = `grpbxVendorSplitRow` + (Number(i) + 1)
                showWidgets(thisObj, [newGrpbx])
              }

            }
          }

        }

      }
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during binding VendorList"
        );
      }
      return false;
    }
  }

  const btnVendorClick = (cntrlId) => {
    // let cntrlId = event.target.name
    setData(thisObj, "vndrRowNo", cntrlId.match(/(\d+)/)[0])
    goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
  }

  //Common Variables and arrays
  let cbosegTag = ''
  // let lstLocationID = []
  // let lstAreaId = []
  // let lstGramEntryInd = []
  // let lstVicamInd = []
  // let lstFV95ResetInd = []
  // let lstCmaInd = []
  // let lstDefaultCMAVendor = []
  // let lstDefaultCMARemitTo = []
  // let lstLocationLandTypeDisplay = []
  let lstStateID = []
  // let lstFarmId = []
  // let lstFarmSuffix = []
  // let lstFarmLandTypeID = []
  let lstWarehouseID = []
  let lstWarehouseBinID = []
  let lstWarehouseOleic = []
  let lstWarehouseVariety = []
  let lstWarehouseGen = []
  let lstLandTypeID = []
  // let gridArray=[]
  let mblnElectronicInd

  //Creating the grid
  const grdScaleTickets = () => {
    let gridArray = getValue(thisObj, 'gridWeightTickets')
    const frmFV95Array = getData(thisObj, 'frmFV95Array')
    if (frmFV95Array != "" && frmFV95Array != undefined && frmFV95Array != null) {
      if (frmFV95Array[0].BtnID == "ADD") {
        let tmpdata = []
        if (frmFV95Array.length > 0) {
          tmpdata = frmFV95Array[0].RowData.split('|');
          let gridData = {}
          gridData.txtcolWeightTicket = tmpdata[0];
          gridData.txtcolDate = tmpdata[1];
          gridData.txtcolWtOfPeanuts = tmpdata[7];
          gridData.txtConcatedData = frmFV95Array[0].RowData;
          gridArray.push(gridData)
        }
      }
      else if (frmFV95Array[0].BtnID == "UPDATE") {
        let rowID = thisObj.state.gridWeightTickets.selected[0].rowID$;
        let tmpdata = []
        if (frmFV95Array.length > 0) {
          tmpdata = frmFV95Array[0].RowData.split('|');
          let gridData = {}
          gridArray[rowID].txtcolWeightTicket = tmpdata[0];
          gridArray[rowID].txtcolDate = tmpdata[1];
          gridArray[rowID].txtcolWtOfPeanuts = tmpdata[7];
          gridArray[rowID].txtConcatedData = frmFV95Array[0].RowData;
        }
      }
      CalculateTotals(gridArray);

      // thisObj.setState(current => {
      //   return {
      //     ...current,
      //     rows:
      //       gridArray
      //   }
      // });
      setValue(thisObj, "gridWeightTickets", gridArray)
      hideColumn(thisObj, 'gridWeightTickets', 'txtcolCCTicket');
      onbtnCalculateClick();
    }
  };

  const onbtnCalculateClick = () => {
    try {
      let dblWtIncVehicle = 0;
      let dblVehicleWt = 0;
      let gridArray = getValue(thisObj, 'gridWeightTickets')
      let locationList = thisObj.state['ddLocation'].valueList;

      let buy_point_id = thisObj.values['ddLocation'];
      let areaid = '';
      if (buy_point_id !== undefined && buy_point_id !== null) {
        areaid = locationList.find(elem => elem.key == buy_point_id).area_id;
      }
      let peanutTypeId = getValue(thisObj, 'ddPeanutType')
      let txtMoistureReading = getValue(thisObj, 'txtMoisture')
      let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct')
      let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct')
      let txtFancyPct = getValue(thisObj, 'txtFancyPct')
      let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct')
      let txtLSKPct = getValue(thisObj, 'txtLSKPct')
      let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct')
      let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct')
      let txtOtherKernalsPct = getValue(thisObj, 'txtOtherKernelsPct')
      let txtELKPct = getValue(thisObj, 'txtELKPct')
      let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct')
      let txtReading = getValue(thisObj, 'txtReading')

      let txt_HFANCY = getValue(thisObj, 'txtFANCY')
      let txt_HFM = getValue(thisObj, 'txtFM')
      let txt_HLSK = getValue(thisObj, 'txtLSK')
      let txt_HSMK = getValue(thisObj, 'txtSMK')
      let txt_HSS = getValue(thisObj, 'txtSS')
      let txt_HOK = getValue(thisObj, 'txtOK')
      let txt_HELK = getValue(thisObj, 'txtELK')
      let txt_HDAM = getValue(thisObj, 'txtDAM')

      for (let i = 0; i < 5; i++) {
        if (gridArray.length > i) {
          let arrScaleTicket = gridArray[i].txtConcatedData.split('|')
          if (Number(arrScaleTicket[5])) {
            if (arrScaleTicket[5] < 0) return
          }
          else return

          if (Number(arrScaleTicket[6])) {
            if (arrScaleTicket[6] < 0) return
          }
          else return
          dblWtIncVehicle = dblWtIncVehicle + Number(arrScaleTicket[5])
          dblVehicleWt = dblVehicleWt + Number(arrScaleTicket[6])
        }
      }
      setWeightOfIncludingVehicle(dblWtIncVehicle);
      setWeightOfVehicle(dblVehicleWt);
      setLblLoadType1(true)
      if (yesNo == false) {
        CalculateValues2(areaid, peanutTypeId, dblWtIncVehicle, dblVehicleWt, txtMoistureReading, txtCrkOrBrkShellsPct, txtDiscoloredShellsPct, txtFancyPct, txtForeignMaterialPct, txtLSKPct, txtSMKRSPct, txtSoundSplitsPct, txtOtherKernalsPct, txtELKPct, txtTotalDamagePct, txtReading)
      }
      else {
        CalculateValues2(areaid, peanutTypeId, dblWtIncVehicle, dblVehicleWt, txtMoistureReading, txtCrkOrBrkShellsPct, txtDiscoloredShellsPct, txt_HFANCY, txt_HFM, txt_HLSK, txt_HSMK, txt_HSS, txt_HOK, txt_HELK, txt_HDAM, txtReading)
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Calculate Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCalculateClick = onbtnCalculateClick


  // Bind Grid
  const bLandType = (farm_id) => {
    let js = [];
    js.push({ key: 'I', description: 'Irrigated' });
    js.push({ key: 'D', description: 'Dryland' });
    js.push({ key: 'U', description: 'Unknown' });
    thisObj.setState(current => {
      return {
        ...current,
        ddIrrigatedDryland: {
          ...state["ddIrrigatedDryland"],
          valueList: js
        }
      }
    });

    if(farm_id!=null && farm_id!="" && farm_id!=undefined){
      let farmList = getData(thisObj,'farmListData')
      let farmIdArray=[];
      let selectedFarm=''
      for(let i=0;i<farmList.length;i++){
        farmIdArray.push(farmList[i].key.slice(0,7))
      }

      for(let i=0;i<farmList.length;i++){
        if(farmIdArray[i]==farm_id){
          selectedFarm=farmList[i].irrdrydefault
        }
      }

      
      if(selectedFarm=="I"){
        setValue(thisObj, 'ddIrrigatedDryland', js.at(0).key)
      }
      else if(selectedFarm=="D"){
        setValue(thisObj, 'ddIrrigatedDryland', js.at(1).key)
      }
      else if(selectedFarm=="U"){
        setValue(thisObj, 'ddIrrigatedDryland', js.at(2).key)
      }
    }
  };

  //Method for Whouse/Bin number
  const bFillWarehouseBinList = async (buy_point_id, organic_ind, pnutType, segment, seedGen, pnutVariety, oleic) => {
    try {
      let js = [];
      js.push({ key: '', description: 'Select' })
      thisObj.setState(current => {
        return {
          ...current,
          ddWhouseBin: {
            ...state["ddWhouseBin"],
            valueList: js
          }
        }
      })
      
      let whouse_num = null
      let bin_num = null
      let seed_gen = null

      let data = await SettlementService.RetrieveWhouseBinInventoryDetails(buy_point_id, whouse_num, bin_num, seed_gen, organic_ind)
      

      if (data.length > 0) {

        for (let i = 0; i < data.length; i++) {
          if (data[i].statusInd == 'A') {
            if (pnutType == data[i].pnutTypeId) {
              if (segment == data[i].segType) {
                if (seedGen == data[i].seedGen || data[i].seedGen == '') {
                  if (pnutVariety == data[i].pnutVarietyId || data[i].pnutVarietyId == '') {
                    if (oleic == data[i].oleicInd || data[i].oleicInd == '') {
                      let dataExist = js.find(elem => elem.description == data[i].whouseNum + ' - ' + data[i].binNum + ' - ' + data[i].whouseBinId);
                      if (dataExist == undefined) {
                        let obj = {
                          key: data[i].whouseNum + data[i].binNum,
                          binNum: data[i].binNum,
                          description: data[i].whouseNum + ' - ' + data[i].binNum + ' - ' + data[i].whouseBinId
                        }
                        js.push(obj)
                        obj = {}
                      }

                      lstWarehouseID.push(data[i].whouseNum)
                      lstWarehouseBinID.push(data[i].binNum)
                      lstWarehouseOleic.push(data[i].oleicInd)
                      lstWarehouseVariety.push(data[i].pnutVarietyId)
                      lstWarehouseGen.push(data[i].seedGen)

                    }
                  }
                }
              }
            }
          }

        }
      }
      else {
        return
      }
      setValue(thisObj, 'ddWhouseBin', '')
      thisObj.setState(current => {
        return {
          ...current,
          ddWhouseBin: {
            ...state["ddWhouseBin"],
            valueList: js
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Unloaded info Dropdown "
        );
      }
    }
  };

  // Start method to disable the electronic controls and called in EnableDisableControls
  const DisableElectronicUploadControls = () => {
    disable(thisObj, 'txtFMSampleWt');
    disable(thisObj, 'txtCleanSampleWt');
    disable(thisObj, 'txtValenciaCleanSampleWt');
    disable(thisObj, 'txtSMKRSGr');
    disable(thisObj, 'txtSoundSplitsGr');
    disable(thisObj, 'txtOtherKernelsGr');
    disable(thisObj, 'txtDamageSplits');
    disable(thisObj, 'txtTotalDamageGr');
    disable(thisObj, 'txtHullsGr');
    disable(thisObj, 'txtFreezeDamageGr');
    disable(thisObj, 'txtConcealedRMDGr');
    disable(thisObj, 'txtCrkOrBrkShellsGr');
    disable(thisObj, 'txtDiscoloredShellsGr');
    disable(thisObj, 'txtELKGr');
    disable(thisObj, 'txtForeignMaterialGr');
    disable(thisObj, 'txtLSKGr');
    disable(thisObj, 'txtFancyGr');
    disable(thisObj, 'txtSMKRSPct');
    disable(thisObj, 'txtSoundSplitsPct');
    disable(thisObj, 'txtOtherKernelsPct');
    disable(thisObj, 'txtTotalDamagePct');
    disable(thisObj, 'txtHullsPct');
    disable(thisObj, 'txtFreezeDamagePct');
    disable(thisObj, 'txtConcealedRMDPct');
    disable(thisObj, 'txtCrkOrBrkShellsPct');
    disable(thisObj, 'txtDiscoloredShellsPct');
    disable(thisObj, 'txtELKPct');
    disable(thisObj, 'txtForeignMaterialPct');
    disable(thisObj, 'txtLSKPct');
    disable(thisObj, 'txtMoisture');
    disable(thisObj, 'txtSC95');
    disable(thisObj, 'txtInspectionDateTime');
    disable(thisObj, 'txtFancyPct');
    disable(thisObj, 'txtKernelsREL');
    disable(thisObj, 'txtKernelsRPS');
    disable(thisObj, 'txtBluePanWt');
    disable(thisObj, 'txtMeterReading');
    disable(thisObj, 'txtDamagedKRS');
    disable(thisObj, 'txtELKDamage');
    disable(thisObj, 'ddPeanutType');
    disable(thisObj, 'ddSeg');
    disable(thisObj, 'chkboxFlavusFound');
    enable(thisObj, 'ddState');
    enable(thisObj, 'ddCounty');
    enable(thisObj, 'ddFarm');

  }
  // End method to disable the electronic controls and called in EnableDisableControls

  // Common Methods
  const bFillOblBuyingPointList = (buy_point_id) => {
    let js = []
    // js.push({ key: '', description: 'Select' })
    SettlementService.RetrieveTransferBuyingPointDetails(buy_point_id, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.description == data[i].xfer_buy_pt_id.trim() + '-' + data[i].xfer_buy_pt_name.trim());
        if (dataExist == undefined) {
          let obj = {
            key: data[i].buy_pt_id,
            description: data[i].xfer_buy_pt_id.trim() + '-' + data[i].xfer_buy_pt_name.trim()
          }
          js.push(obj)
          obj = {}
        }
      }
      setValue(thisObj, 'ddBuyingPt', '')
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPt: {
            ...state["ddBuyingPt"],
            valueList: js
          }
        }
      })
    })
  };

  const bFillOblWarehouseBinList = async (buy_pt_id, pnutType, segment, seedGen, pnutVariety, oleic) => {
    try {
      let js = [];
      // js.push({ key: '', description: 'Select' })
      js.push({ key: '', description: 'Select' })
      thisObj.setState(current => {
        return {
          ...current,
          ddObligationPt: {
            ...state["ddObligationPt"],
            valueList: js
          }
        }
      })

      let whouse_num = null
      let bin_num = null
      let seed_gen = null
      let organic_ind = null
      let data = await SettlementService.RetrieveWhouseBinInventoryDetails(buy_pt_id, whouse_num, bin_num, seed_gen, organic_ind)
      if (data.length > 0) {

        for (let i = 0; i < data.length; i++) {
          if (data[i].statusInd == 'A') {
            if (data[i].whsRcptInd == 'Y') {
              if (pnutType == data[i].pnutTypeId) {
                if (segment == data[i].segType) {
                  if (seedGen == data[i].seedGen || data[i].seedGen == '') {
                    if (pnutVariety == data[i].pnutVarietyId || data[i].pnutVarietyId == '') {
                      if (oleic == data[i].oleicInd || data[i].oleicInd == '') {
                        let dataExist = js.find(elem => elem.description == data[i].whouseNum + ' - ' + data[i].binNum + ' - ' + data[i].whouseBinId);
                        if (dataExist == undefined) {
                          let obj = {
                            key: data[i].whouseNum + data[i].binNum,
                            binNum: data[i].binNum,
                            description: data[i].whouseNum + ' - ' + data[i].binNum + ' - ' + data[i].whouseBinId
                          }
                          js.push(obj)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      setValue(thisObj, 'ddObligationPt', '')
      thisObj.setState(current => {
        return {
          ...current,
          ddObligationPt: {
            ...state["ddObligationPt"],
            valueList: js
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Warehouse/Bin Dropdown"
        );
      }
      return false;
    }
  }
  // Enable Disable Methods
  const EnabelDisableGradeEntry = (SelectedBuyingPointValue, selectedPeanutType, ElecUploadInd, label1007) => {
    try {
      const location = getData(thisObj, 'locationData')

      if (selectedPeanutType == 'VA') {
        document.getElementsByClassName("txtSampleWt")[0].style.visibility = 'visible';
        document.getElementsByClassName("txtGrams")[0].style.visibility = 'visible';
        document.getElementsByClassName("txtPercent")[0].style.visibility = 'visible';
        document.getElementsByClassName("lblVirginiaGrading")[0].style.visibility = 'visible';
      }
      else {
        document.getElementsByClassName("txtSampleWt")[0].style.visibility = 'hidden';
        document.getElementsByClassName("txtGrams")[0].style.visibility = 'hidden';
        document.getElementsByClassName("txtPercent")[0].style.visibility = 'hidden';
        document.getElementsByClassName("lblVirginiaGrading")[0].style.visibility = 'hidden';
      }

      if (selectedPeanutType == 'VL') {
        document.getElementsByClassName("txtValenciaCleanSampleWt")[0].style.visibility = 'visible';
        document.getElementsByClassName("txtCrkOrBrkShellsGr")[0].style.visibility = 'visible';
        document.getElementById("txtCrkOrBrkShellsPct").style.visibility = 'visible';

        document.getElementsByClassName("txtDiscoloredShellsGr")[0].style.visibility = 'visible';
        document.getElementsByClassName("txtDiscoloredShellsPct")[0].style.visibility = 'visible';
      }
      else {
        document.getElementsByClassName("txtValenciaCleanSampleWt")[0].style.visibility = 'hidden';
        document.getElementsByClassName("txtCrkOrBrkShellsGr")[0].style.visibility = 'hidden';
        document.getElementById("txtCrkOrBrkShellsPct").style.visibility = 'hidden';

        document.getElementsByClassName("txtDiscoloredShellsGr")[0].style.visibility = 'hidden';
        document.getElementById("txtDiscoloredShellsPct").style.visibility = 'hidden';
      }
      //const labelN = getValue(thisObj, 'lblN');
      if (ElecUploadInd == 'Y') return false;
      let objVal = {}
      //const label1007 = getValue(thisObj, 'txt1007');
      if (label1007 == '' || label1007 == undefined) {
        if (location !== null) {
          let locationDetailsById = location.find(x => x.buy_pt_id == SelectedBuyingPointValue);
          if (locationDetailsById !== undefined && locationDetailsById !== null) {
            if (locationDetailsById.gram_entry_ind == 'Y') {
              enable(thisObj, 'txtSMKRSGr')
              enable(thisObj, 'txtSoundSplitsGr')
              enable(thisObj, 'txtOtherKernelsGr')
              enable(thisObj, 'txtHullsGr')
              enable(thisObj, 'txtFreezeDamageGr')
              enable(thisObj, 'txtConcealedRMDGr')
              enable(thisObj, 'txtCrkOrBrkShellsGr')
              enable(thisObj, 'txtDiscoloredShellsGr')
              enable(thisObj, 'txtHullBrightness')
              enable(thisObj, 'txtJumboGr')
              enable(thisObj, 'txtELKGr')
              enable(thisObj, 'txtForeignMaterialGr')
              enable(thisObj, 'txtLSKGr')
              enable(thisObj, 'txtFancyGr')
              enable(thisObj, 'txtTotalDamageGr')

              disable(thisObj, 'txtSMKRSPct')
              disable(thisObj, 'txtOtherKernelsPct')
              disable(thisObj, 'txtFreezeDamagePct')
              disable(thisObj, 'txtSoundSplitsPct')
              disable(thisObj, 'txtConcealedRMDPct')
              disable(thisObj, 'txtCrkOrBrkShellsPct')
              disable(thisObj, 'txtDiscoloredShellsPct')
              disable(thisObj, 'txtHullBrightnessPct')
              disable(thisObj, 'txtJumboPct')
              disable(thisObj, 'txtELKPct')
              disable(thisObj, 'txtForeignMaterialPct')
              disable(thisObj, 'txtLSKPct')
              disable(thisObj, 'txtFancyPct')
              disable(thisObj, 'txtTotalDamagePct')

            } else {
              disable(thisObj, 'txtSMKRSGr')
              disable(thisObj, 'txtSoundSplitsGr')
              disable(thisObj, 'txtOtherKernelsGr')
              disable(thisObj, 'txtHullsGr')
              disable(thisObj, 'txtFreezeDamageGr')
              disable(thisObj, 'txtConcealedRMDGr')
              disable(thisObj, 'txtCrkOrBrkShellsGr')
              disable(thisObj, 'txtDiscoloredShellsGr')
              disable(thisObj, 'txtHullBrightness')
              disable(thisObj, 'txtJumboGr')
              disable(thisObj, 'txtELKGr')
              disable(thisObj, 'txtForeignMaterialGr')
              disable(thisObj, 'txtLSKGr')
              disable(thisObj, 'txtFancyGr')
              disable(thisObj, 'txtTotalDamageGr')

              // setValue(thisObj, 'txtSMKRSGr', '')
              // setValue(thisObj, 'txtSoundSplitsGr', '')
              // setValue(thisObj, 'txtOtherKernelsGr', '')
              // setValue(thisObj, 'txtHullsGr', '')
              // setValue(thisObj, 'txtFreezeDamageGr', '')
              // setValue(thisObj, 'txtConcealedRMDGr', '')
              // setValue(thisObj, 'txtCrkOrBrkShellsGr', '')
              // setValue(thisObj, 'txtDiscoloredShellsGr', '')
              // setValue(thisObj, 'txtHullBrightness', '')
              // setValue(thisObj, 'txtJumboGr', '')
              // setValue(thisObj, 'txtELKGr', '')
              // setValue(thisObj, 'txtForeignMaterialGr', '')
              // setValue(thisObj, 'txtLSKGr', '')
              // setValue(thisObj, 'txtFancyGr', '')
              // setValue(thisObj, 'txtTotalDamageGr', '')              
              objVal['txtSMKRSGr'] = ''
              objVal['txtSoundSplitsGr'] = ''
              objVal['txtOtherKernelsGr'] = ''
              objVal['txtHullsGr'] = ''
              objVal['txtFreezeDamageGr'] = ''
              objVal['txtConcealedRMDGr'] = ''
              objVal['txtCrkOrBrkShellsGr'] = ''
              objVal['txtDiscoloredShellsGr'] = ''
              objVal['txtHullBrightness'] = ''
              objVal['txtJumboGr'] = ''
              objVal['txtELKGr'] = ''
              objVal['txtForeignMaterialGr'] = ''
              objVal['txtLSKGr'] = ''
              objVal['txtFancyGr'] = ''
              objVal['txtTotalDamageGr'] = ''

              enable(thisObj, 'txtSMKRSPct')
              enable(thisObj, 'txtOtherKernelsPct')
              enable(thisObj, 'txtFreezeDamagePct')
              enable(thisObj, 'txtSoundSplitsPct')
              enable(thisObj, 'txtConcealedRMDPct')
              enable(thisObj, 'txtCrkOrBrkShellsPct')
              enable(thisObj, 'txtDiscoloredShellsPct')
              enable(thisObj, 'txtHullBrightnessPct')
              enable(thisObj, 'txtJumboPct')
              enable(thisObj, 'txtELKPct')
              enable(thisObj, 'txtForeignMaterialPct')
              enable(thisObj, 'txtLSKPct')
              enable(thisObj, 'txtFancyPct')
              enable(thisObj, 'txtTotalDamagePct')
            }

            if (selectedPeanutType == 'RU' || selectedPeanutType == 'SP') {
              disable(thisObj, 'txtValenciaCleanSampleWt')
              disable(thisObj, 'txtCrkOrBrkShellsGr')
              disable(thisObj, 'txtDiscoloredShellsGr')
              disable(thisObj, 'txtHullBrightness')
              disable(thisObj, 'txtJumboGr')
              disable(thisObj, 'txtELKGr')
              disable(thisObj, 'txtFancyGr')
              disable(thisObj, 'txtCrkOrBrkShellsPct')
              disable(thisObj, 'txtDiscoloredShellsPct')
              disable(thisObj, 'txtHullBrightnessPct')
              disable(thisObj, 'txtJumboPct')
              disable(thisObj, 'txtELKPct')
              disable(thisObj, 'txtFancyPct')

              // setValue(thisObj, 'txtValenciaCleanSampleWt', '0.0')
              // setValue(thisObj, 'txtCrkOrBrkShellsGr', '0.0')
              // setValue(thisObj, 'txtDiscoloredShellsGr', '0.0')
              // setValue(thisObj, 'txtHullBrightness', '0.0')
              // setValue(thisObj, 'txtJumboGr', '0.0')
              // setValue(thisObj, 'txtELKGr', '0.0')
              // setValue(thisObj, 'txtFancyGr', '0.0')
              // setValue(thisObj, 'txtCrkOrBrkShellsPct', '0.0')
              // setValue(thisObj, 'txtDiscoloredShellsPct', '0.0')
              // setValue(thisObj, 'txtHullBrightnessPct', '0.0')
              // setValue(thisObj, 'txtJumboPct', '0.0')
              // setValue(thisObj, 'txtELKPct', '0.0')
              // setValue(thisObj, 'txtFancyPct', '0.0')
              objVal['txtValenciaCleanSampleWt'] = '0.0'
              objVal['txtCrkOrBrkShellsGr'] = '0.0'
              objVal['txtDiscoloredShellsGr'] = '0.0'
              objVal['txtHullBrightness'] = '0.0'
              objVal['txtJumboGr'] = '0.0'
              objVal['txtELKGr'] = '0.0'
              objVal['txtFancyGr'] = '0.0'
              objVal['txtCrkOrBrkShellsPct'] = '0.0'
              objVal['txtDiscoloredShellsPct'] = '0.0'
              objVal['txtHullBrightnessPct'] = '0.0'
              objVal['txtJumboPct'] = '0.0'
              objVal['txtELKPct'] = '0.0'
              objVal['txtFancyPct'] = '0.0'
            }
          } else {
            disable(thisObj, 'txtSMKRSGr')
            disable(thisObj, 'txtSoundSplitsGr')
            disable(thisObj, 'txtOtherKernelsGr')
            disable(thisObj, 'txtHullsGr')
            disable(thisObj, 'txtFreezeDamageGr')
            disable(thisObj, 'txtConcealedRMDGr')
            disable(thisObj, 'txtCrkOrBrkShellsGr')
            disable(thisObj, 'txtDiscoloredShellsGr')
            disable(thisObj, 'txtHullBrightness')
            disable(thisObj, 'txtJumboGr')
            disable(thisObj, 'txtELKGr')
            disable(thisObj, 'txtForeignMaterialGr')
            disable(thisObj, 'txtLSKGr')
            disable(thisObj, 'txtFancyGr')
            disable(thisObj, 'txtTotalDamageGr')

            // setValue(thisObj, 'txtSMKRSGr', '')
            // setValue(thisObj, 'txtSoundSplitsGr', '')
            // setValue(thisObj, 'txtOtherKernelsGr', '')
            // setValue(thisObj, 'txtHullsGr', '')
            // setValue(thisObj, 'txtFreezeDamageGr', '')
            // setValue(thisObj, 'txtConcealedRMDGr', '')
            // setValue(thisObj, 'txtCrkOrBrkShellsGr', '')
            // setValue(thisObj, 'txtDiscoloredShellsGr', '')
            // setValue(thisObj, 'txtHullBrightness', '')
            // setValue(thisObj, 'txtJumboGr', '')
            // setValue(thisObj, 'txtELKGr', '')
            // setValue(thisObj, 'txtForeignMaterialGr', '')
            // setValue(thisObj, 'txtLSKGr', '')
            // setValue(thisObj, 'txtFancyGr', '')
            // setValue(thisObj, 'txtTotalDamageGr', '')
            objVal['txtSMKRSGr'] = ''
            objVal['txtSoundSplitsGr'] = ''
            objVal['txtOtherKernelsGr'] = ''
            objVal['txtHullsGr'] = ''
            objVal['txtFreezeDamageGr'] = ''
            objVal['txtConcealedRMDGr'] = ''
            objVal['txtCrkOrBrkShellsGr'] = ''
            objVal['txtDiscoloredShellsGr'] = ''
            objVal['txtHullBrightness'] = ''
            objVal['txtJumboGr'] = ''
            objVal['txtELKGr'] = ''
            objVal['txtForeignMaterialGr'] = ''
            objVal['txtLSKGr'] = ''
            objVal['txtFancyGr'] = ''
            objVal['txtTotalDamageGr'] = ''

            enable(thisObj, 'txtSMKRSPct')
            enable(thisObj, 'txtOtherKernelsPct')
            enable(thisObj, 'txtFreezeDamagePct')
            enable(thisObj, 'txtSoundSplitsPct')
            enable(thisObj, 'txtConcealedRMDPct')
            enable(thisObj, 'txtCrkOrBrkShellsPct')
            enable(thisObj, 'txtDiscoloredShellsPct')
            enable(thisObj, 'txtHullBrightnessPct')
            enable(thisObj, 'txtJumboPct')
            enable(thisObj, 'txtELKPct')
            enable(thisObj, 'txtForeignMaterialPct')
            enable(thisObj, 'txtLSKPct')
            enable(thisObj, 'txtFancyPct')
            enable(thisObj, 'txtTotalDamagePct')
          }
        } else {
          disable(thisObj, 'txtSMKRSGr')
          disable(thisObj, 'txtSoundSplitsGr')
          disable(thisObj, 'txtOtherKernelsGr')
          disable(thisObj, 'txtHullsGr')
          disable(thisObj, 'txtFreezeDamageGr')
          disable(thisObj, 'txtConcealedRMDGr')
          disable(thisObj, 'txtCrkOrBrkShellsGr')
          disable(thisObj, 'txtDiscoloredShellsGr')
          disable(thisObj, 'txtHullBrightness')
          disable(thisObj, 'txtJumboGr')
          disable(thisObj, 'txtELKGr')
          disable(thisObj, 'txtForeignMaterialGr')
          disable(thisObj, 'txtLSKGr')
          disable(thisObj, 'txtFancyGr')
          disable(thisObj, 'txtTotalDamageGr')

          // setValue(thisObj, 'txtSMKRSGr', '')
          // setValue(thisObj, 'txtSoundSplitsGr', '')
          // setValue(thisObj, 'txtOtherKernelsGr', '')
          // setValue(thisObj, 'txtHullsGr', '')
          // setValue(thisObj, 'txtFreezeDamageGr', '')
          // setValue(thisObj, 'txtConcealedRMDGr', '')
          // setValue(thisObj, 'txtCrkOrBrkShellsGr', '')
          // setValue(thisObj, 'txtDiscoloredShellsGr', '')
          // setValue(thisObj, 'txtHullBrightness', '')
          // setValue(thisObj, 'txtJumboGr', '')
          // setValue(thisObj, 'txtELKGr', '')
          // setValue(thisObj, 'txtForeignMaterialGr', '')
          // setValue(thisObj, 'txtLSKGr', '')
          // setValue(thisObj, 'txtFancyGr', '')
          // setValue(thisObj, 'txtTotalDamageGr', '')
          objVal['txtSMKRSGr'] = ''
          objVal['txtSoundSplitsGr'] = ''
          objVal['txtOtherKernelsGr'] = ''
          objVal['txtHullsGr'] = ''
          objVal['txtFreezeDamageGr'] = ''
          objVal['txtConcealedRMDGr'] = ''
          objVal['txtCrkOrBrkShellsGr'] = ''
          objVal['txtDiscoloredShellsGr'] = ''
          objVal['txtHullBrightness'] = ''
          objVal['txtJumboGr'] = ''
          objVal['txtELKGr'] = ''
          objVal['txtForeignMaterialGr'] = ''
          objVal['txtLSKGr'] = ''
          objVal['txtFancyGr'] = ''
          objVal['txtTotalDamageGr'] = ''

          enable(thisObj, 'txtSMKRSPct')
          enable(thisObj, 'txtOtherKernelsPct')
          enable(thisObj, 'txtFreezeDamagePct')
          enable(thisObj, 'txtSoundSplitsPct')
          enable(thisObj, 'txtConcealedRMDPct')
          enable(thisObj, 'txtCrkOrBrkShellsPct')
          enable(thisObj, 'txtDiscoloredShellsPct')
          enable(thisObj, 'txtHullBrightnessPct')
          enable(thisObj, 'txtJumboPct')
          enable(thisObj, 'txtELKPct')
          enable(thisObj, 'txtForeignMaterialPct')
          enable(thisObj, 'txtLSKPct')
          enable(thisObj, 'txtFancyPct')
          enable(thisObj, 'txtTotalDamagePct')
        }
      } else {
        disable(thisObj, "txtSMK1Gr")
        disable(thisObj, "txtSMK2Gr")
        disable(thisObj, "txtSMK3Gr")
        disable(thisObj, "txtSMK4Gr")
        disable(thisObj, "txtSMK1Pct")
        disable(thisObj, "txtSMK2Pct")
        disable(thisObj, "txtSMK3Pct")
        disable(thisObj, "txtSMK4Pct")
        disable(thisObj, "txtDamageSplits")
        disable(thisObj, "txtOtherForeignMaterials")
        disable(thisObj, "txtMoisture")


        disable(thisObj, "txtSMKRSGr")
        disable(thisObj, "txtSoundSplitsGr")
        disable(thisObj, "txtOtherKernelsGr")
        disable(thisObj, "txtHullsGr")
        disable(thisObj, "txtFreezeDamageGr")
        disable(thisObj, "txtConcealedRMDGr")
        disable(thisObj, "txtCrkOrBrkShellsGr")
        disable(thisObj, "txtDiscoloredShellsGr")
        disable(thisObj, "txtHullBrightness")
        disable(thisObj, 'txtJumboGr')
        disable(thisObj, "txtELKGr")
        disable(thisObj, "txtForeignMaterialGr")
        disable(thisObj, "txtLSKGr")
        disable(thisObj, "txtFancyGr")
        disable(thisObj, "txtTotalDamageGr")
        disable(thisObj, "chkboxFlavusFound")
        disable(thisObj, "chkboxCornFound")
        disable(thisObj, "txtFMSampleWt")
        disable(thisObj, "txtCleanSampleWt")
        disable(thisObj, "txtValenciaCleanSampleWt")
        disable(thisObj, "txtProbingPattern")

        disable(thisObj, "txtSMKRSPct")
        disable(thisObj, "txtSoundSplitsPct")
        disable(thisObj, "txtOtherKernelsPct")
        disable(thisObj, "txtHullsPct")
        disable(thisObj, "txtFreezeDamagePct")
        disable(thisObj, "txtConcealedRMDPct")
        disable(thisObj, "txtCrkOrBrkShellsPct")
        disable(thisObj, "txtDiscoloredShellsPct")
        disable(thisObj, "txtHullBrightness")
        disable(thisObj, 'txtJumboPct')
        disable(thisObj, "txtELKPct")
        disable(thisObj, "txtForeignMaterialPct")
        disable(thisObj, "txtLSKPct")
        disable(thisObj, "txtFancyPct")
        disable(thisObj, "txtTotalDamagePct")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during EnabelDisableGradeEntry function"
        );
      }
      return false;
    }
    return true
  };

  const onlblFormDirtyChange = () => {
    if (yesNo == true) {
      enable(thisObj, 'btnConvertHMC')
    }
    else {
      disable(thisObj, 'btnConvertHMC')
    }
    const vendorList = getData(thisObj, 'vendorList')
    EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, lblLoadType1)
  }
  thisObj.onlblFormDirtyChange = onlblFormDirtyChange

  const onddObligationPtChange = () => {
    setLblLoadType1(true)
  }
  thisObj.onddObligationPtChange = onddObligationPtChange

  const EnabelDisableVicam = (SelectedBuyingPointValue) => {
    const locationData = getData(thisObj, "locationData")
    // let SelectedBuyingPointValue = getValue(thisObj, "ddLocation")
    let locationDetailsById = locationData.find(x => x.buy_pt_id == SelectedBuyingPointValue)
    if (locationDetailsById.vicam_lab == 'Y') {
      setValue(thisObj, "chkboxVicam", 1)
      setData(thisObj, 'CheckBoxChecked', true)
    }
    else {
      setData(thisObj, 'CheckBoxChecked', false)
    }
    onchkboxVicamChange()
  }

  const ShowHidePanels = async(farm_id) => {

    if (farm_id == '' || farm_id == null || farm_id == undefined) {
      // c11007
      // hide(thisObj, 'grpbx1007Control')

      // C1PeanutInfo
      // hide(thisObj, 'grpbxPeanutInfo')

      // c1GradeCalculation
      // hide(thisObj, 'grpbxGradeCalculation')

      // Vendor Split
      // hide(thisObj, 'grpbxVendorSplits')

      // c1ScaleTickets
      // hide(thisObj, 'grpbxWeightTickets')

      // c1ValueCalculation
      // hide(thisObj, 'grpbxValueCalculation')

      // grpbxHMC 
      // hide(thisObj, 'grpbxHMC')

      // c1Remarks
      // hide(thisObj, 'lblInspectorRemarks')
      // hide(thisObj, 'txtarInspectorRemarks')

      let hidePanelsArray = ['grpbx1007Control', 'grpbxPeanutInfo', 'grpbxGradeCalculation', 'grpbxVendorSplits', 'grpbxWeightTickets', 'grpbxValueCalculation', 'grpbxHMC', 'lblInspectorRemarks', 'txtarInspectorRemarks']
      hideWidgets(thisObj, hidePanelsArray)
    }
    else {
      //c11007
      // show(thisObj, 'grpbx1007Control')

      //C1PeanutInfo
      // show(thisObj, 'grpbxPeanutInfo')

      //c1GradeCalculation
      // show(thisObj, 'grpbxGradeCalculation')

      //Vendor Split
      // show(thisObj, 'grpbxVendorSplits')

      //c1ScaleTickets
      // show(thisObj, 'grpbxWeightTickets')

      //c1ValueCalculation
      // show(thisObj, 'grpbxValueCalculation')

      //grpbxHMC 
      // show(thisObj, 'grpbxHMC')

      //c1Remarks
      // show(thisObj, 'lblInspectorRemarks')
      // show(thisObj, 'txtarInspectorRemarks')

      let showPanelArray = ['grpbx1007Control', 'grpbxPeanutInfo', 'grpbxGradeCalculation', 'grpbxVendorSplits', 'grpbxWeightTickets', 'grpbxValueCalculation', 'grpbxHMC', 'lblInspectorRemarks', 'txtarInspectorRemarks']
      showWidgets(thisObj, showPanelArray)
    }
  }

  // Control Events

  // ddRemitTo Change Event
  const onddRemitToChange = () => {
    setLblLoadType1(true);
    const vendorList = getData(thisObj, 'vendorList')
    EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, lblLoadType1)
  }
  thisObj.onddRemitToChange = onddRemitToChange;

  //Buying point binding function
  const bFillContractControlLocationList = async() => {
    let js = []
    let response =await ContractManagementService.RetrieveBuyingPointControlDetails('PN1030', null, null, null, null)
      let data = response;
      for (var i = 0; i < data.length; i++) {
        let obj = {
          key: data[i].buy_pt_id,
          description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim(),
          area_id: data[i].area_id
        };
        js.push(obj);
      }
      setData(thisObj, 'locationData', data);
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
      // setValue(thisObj, 'ddLocation', js.at(0).key);
      let locationDetailsById = data.find(x => x.buy_pt_id == js.at(0).key);
      if (locationDetailsById.cma_ind == 'Y') {
        // showWidgets(thisObj, ['txtCMAVendor'])
        // showWidgets(thisObj, ['ddRemitTo'])
        // showWidgets(thisObj, ['lblCMAInformation'])
        show(thisObj,'txtCMAVendor')
        show(thisObj,'ddRemitTo')
        show(thisObj,'lblCMAInformation')

        if (locationDetailsById.default_cma_vendor_num != "") {
          // showWidgets(thisObj, ['chkboxDefault'])
          show(thisObj,'chkboxDefault')
        }
        else {
          // hideWidgets(thisObj, ['chkboxDefault'])
          hide(thisObj,'chkboxDefault')
        }
      }
      else {
        // let hideLocationChange = ['txtCMAVendor', 'chkboxDefault', 'ddRemitTo', 'lblCMAInformation']
        // hideWidgets(thisObj, hideLocationChange)
        hide(thisObj,'txtCMAVendor')
        hide(thisObj,'ddRemitTo')
        hide(thisObj,'lblCMAInformation')
        hide(thisObj,'chkboxDefault')
      }
  }

  //Buying Point dropdown change event
  const onBuyingPointChange = async () => {
    try {
      setLoading(true);
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation")
      let selectedPeanutType = getValue(thisObj, 'ddPeanutType')
      
      await bFillStateList(SelectedBuyingPointValue, null, true)
      await bFillVarietyList(SelectedBuyingPointValue,selectedPeanutType)
      await DisplayFarmLandType();

      let location = getData(thisObj, 'locationData')
      bFillFarmlist(SelectedBuyingPointValue, null, null);
      bFillWarehouseBinList(SelectedBuyingPointValue, null, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'));
      bFillOblBuyingPointList(SelectedBuyingPointValue)

      let locationDetailsById = location.find(x => x.buy_pt_id == SelectedBuyingPointValue);
      if (locationDetailsById.cma_ind == 'Y' ) {
        
        show(thisObj,'ddRemitTo')
        show(thisObj,'lblCMAInformation')
        show(thisObj,'txtCMAVendor')

        if (locationDetailsById.default_cma_vendor_num != "") {
          show(thisObj,'chkboxDefault')
        }
        else {
          
          show(thisObj,'chkboxDefault')
        }
      }
      else {
        // hide(thisObj, 'txtCMAVendor')
        // hide(thisObj, 'chkboxDefault')
        // hide(thisObj, 'ddRemitTo')
        // hide(thisObj, 'lblCMAInformation')

        let hideLocationChange = ['txtCMAVendor', 'chkboxDefault', 'ddRemitTo', 'lblCMAInformation']
        hideWidgets(thisObj, hideLocationChange)
      }
      
      await ShowHidePanels('')
      let accessPermission = await ContractManagementService.RetrieveAccessPermissionDetails('PN1030', '005', 'U', SelectedBuyingPointValue);
      if (accessPermission[0].permission == "Y") {
        enable(thisObj, 'txtNumber')
        enable(thisObj, 'txtName')
        showWidgets(thisObj, ['lblAdd1007Aplcnt'])
        showWidgets(thisObj, ['txtNumber'])
        showWidgets(thisObj, ['txtName'])
      }
      EnabelDisableGradeEntry(SelectedBuyingPointValue, selectedPeanutType, lblElecUploadInd, getValue(thisObj, 'txt1007'))
      EnabelDisableVicam(SelectedBuyingPointValue)

      const area_id = locationDetailsById.area_id;
      let areaControls = await SettlementService.RetrieveAreaControlDetails(area_id);
      if (areaControls.length > 0) {
        setdblMinKernHullPct(areaControls[0].minKernHullPct);
        setdblMaxKernHullPct(areaControls[0].maxKernHullPct);
      }
      setLblLoadType1(true);
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during buying point change event"
        );
      }

    }
  }
  thisObj.onddLocationChange = onBuyingPointChange

  //State change event
  const onStateChange = () => {
    try {
      let lstStateList = thisObj.state['ddState'].valueList;
      // // let stateIndex = '';
      let stateid = thisObj.values['ddState'];
      let stateAbbrevations = '';
      if (stateid !== undefined && stateid !== null && stateid !== '') {
        stateAbbrevations = lstStateList.find(elem => elem.key == stateid).stateAbbrevations;


        // const location = getData(thisObj, 'locationData')

        // let SelectedBuyingPointValue = getValue(thisObj, "ddLocation")
        // let locationDetailsById = location.find(x => x.buy_pt_id == SelectedBuyingPointValue);
        let buyingPointId = thisObj.values['ddLocation'];

        // if (stateTag == '') setStateTag(-1)

        // let buyingPointId = locationDetailsById.buy_pt_id
        bFillCounty(buyingPointId, 'YES', stateAbbrevations, null);
        // if (bFillCounty(buyingPointId, 'YES', null, null)) {
        //   setStateTag(stateIndex)
        // }
        // else {
        //   stateIndex = parseInt(stateTag)
        // }

        setLblLoadType1(true)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during state change event"
        );
      }
      return false
    };
    return true
  }
  thisObj.onddStateChange = onStateChange

  const onCountyChange = async () => {
    try {
      let buy_pt_id = getValue(thisObj, 'ddLocation')

      let StateList = thisObj.state['ddState'].valueList;
      let SelectedStateValue = thisObj.values['ddState'];

      let StateAbrr = null
      if (SelectedStateValue !== "" && SelectedStateValue !== undefined && SelectedStateValue !== null) {
        StateAbrr = StateList.find(elem => elem.key == SelectedStateValue).stateAbbrevations;


        let CountyList = thisObj.state['ddCounty'].valueList;
        let SelectedCountyValue = thisObj.values['ddCounty'];

        let CountyId = null;
        if (SelectedCountyValue !== "" && SelectedCountyValue !== undefined && SelectedCountyValue !== null) {
          CountyId = CountyList.find(elem => elem.key == SelectedCountyValue).key;
          CountyId = CountyId.split('-')[0];


          // let stateAbbrevations = StateAbrr
          await bFillFarmlist(buy_pt_id, StateAbrr, CountyId);
          enable(thisObj,'btnCreateFarm')
          ShowHidePanels('');
        }
      }
      setLblLoadType1(true);
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during county change event"
        );
      }
      return false
    }
  }
  thisObj.onddCountyChange = onCountyChange

  const farmChange = (farmId) => {
    // if (farmId != undefined && farmId != null) {
    //   bFillVendorList(buy_point_id, StateAbrr, CountyId, farmId, farmSuffix)
    // }
    ShowHidePanels(farmId)
    setLblLoadType1(true)
    // EnableDisableControls()
  }
  const resetVendorList = () => {
    let objVal = {}
    for (let i = 0; i < 20; i++) {
      objVal[`ddVendor${i}`] = ['']
      objVal[`ddRemitTo${i}`] = ''
      objVal[`txtSharePct${i}`] = ''
      objVal[`chkboxCMA${i}`] = false
      // setValue(thisObj, `ddVendor${i}`, [''])
      // setValue(thisObj, `ddRemitTo${i}`, '')
      // setValue(thisObj, `txtSharePct${i}`, '')
      // setValue(thisObj, `chkboxCMA${i}`, false)
    }
    setFieldValues(thisObj, objVal, true);
  }

  const onddFarmChange = async () => {
    setLoading(true);
    resetVendorList()
    let buy_point_id = getValue(thisObj, 'ddLocation')
    let StateList = thisObj.state['ddState'].valueList;
    let SelectedStateValue = thisObj.values['ddState'];
    let StateAbrr = null
    let lstFarmList = thisObj.state['ddFarm'].valueList;
    let farmDetaIL = thisObj.values['ddFarm']
    let farmId = farmDetaIL.split('-')[0]
    
    let farmSuffix = null;

    if (SelectedStateValue !== "" && SelectedStateValue !== undefined) {
      StateAbrr = StateList.find(elem => elem.key == SelectedStateValue).stateAbbrevations;
    }

    let CountyId = thisObj.values['ddCounty'];
    if (CountyId !== "" && CountyId !== undefined) {
      CountyId = CountyId.split('-')[0];
    }

    if (farmId !== '' && farmId !== undefined && farmId != null) {
      farmSuffix = lstFarmList.find(elem => elem.key == farmDetaIL).farmSuffix;
      await bFillVendorList(buy_point_id, StateAbrr, CountyId, farmId, farmSuffix)
    }

    ShowHidePanels(farmId);
    setLblLoadType1(true);
    setLoading(false);
  }
  thisObj.onddFarmChange = onddFarmChange

  //Display the FarmLand type Dropdown
  let DisplayFarmLandType=async()=>{
    let location = getData(thisObj, 'locationData')
    let SelectedBuyingPointValue=getValue(thisObj,'ddLocation')
    let locationDetailsById = location.find(x => x.buy_pt_id == SelectedBuyingPointValue);
    if(locationDetailsById.irr_dry_on=='Y'){
      show(thisObj,'ddIrrigatedDryland')
    }
    else{
      hide(thisObj,'ddIrrigatedDryland')
    }
  }

  //State binding function

  const onchkboxDefaultChange =async () => {
    try {
      setLoading(true)
      let iList
      let vendorList = getData(thisObj, 'vendorList')
      if(vendorList==null || vendorList==undefined){
        vendorList=getData(thisObj,'bfillVendorSplits')
      }

      let locationList = thisObj.state['ddLocation'].valueList;
      let selectedLocation = thisObj.values['ddLocation'];
      let vendorNumber
      if (selectedLocation !== undefined && selectedLocation !== null) {
        let locationData = getData(thisObj, 'locationData');
        let check = getValue(thisObj, 'chkboxDefault');
        vendorNumber=locationData.find(x => x.buy_pt_id == selectedLocation).default_cma_vendor_num
        if (check == true) {
          setValue(thisObj, 'txtCMAVendor', vendorNumber);
        }
        else {
          setValue(thisObj, 'txtCMAVendor', '')
          vendorNumber='';
        }
      }

      let js = [];
      let response =await ContractManagementService.RetieveRemitDetails(vendorNumber)
        let data = response;
        iList = 0;
        if(data!=null && data!=undefined && data!=''){
        for (let i = 0; i < data.length; i++) {
          let obj = { key: data[i].remittoid, 
            description: data[i].number + " - " + data[i].name + " + " + data[i].city + ", " + data[i].state }
          js.push(obj)
        }
      }

        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js
            }
          }
        })

        if(js.length>0){
        setValue(thisObj, 'ddRemitTo', js.at(0).key);
        }
      
      //Rest function is dependent on vendor split option
      if(vendorNumber==''){
        let cmaInformationChkbox=[]
        for(let i=0;i<vendorList.length;i++){
          hide(thisObj,`chkboxCMA${i}`)
          setValue(thisObj,`chkboxCMA${i}`,false)
        }
        hide(thisObj,'lblCMAParticipant',false)
      }
      else{
        let cmaInformationChkbox=[]
        for(let i=0;i<vendorList.length;i++){
          show(thisObj,`chkboxCMA${i}`)
          setValue(thisObj,`chkboxCMA${i}`,true)
        }
        show(thisObj,'lblCMAParticipant')
      }
      setLblLoadType1(true)
      
      EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, true)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Checkbox default change"
        );
      }
      return false;
    }
  };
  thisObj.onchkboxDefaultChange = onchkboxDefaultChange

  const onchkboxCornFoundChange = () => {
    setLblLoadType1(true)
  }
  thisObj.onchkboxCornFoundChange = onchkboxCornFoundChange

  const ontxtForeignMaterialChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtForeignMaterialChange = ontxtForeignMaterialChange

  const ontxtProbingPatternChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtProbingPatternChange = ontxtProbingPatternChange

  const ontxtInspectionDateTimeChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtInspectionDateTimeChange = ontxtInspectionDateTimeChange

  const onddWhouseBinCHange = () => {
    setLblLoadType1(true);
  }
  thisObj.onddWhouseBinCHange = onddWhouseBinCHange

  //Organic checkbox click event
  const onchkboxOrganicChange = () => {
    let buy_point_id = getValue(thisObj, 'ddLocation')
    // let seed_gen = getValue(thisObj, 'ddSeedGen')
    let organiC_IND = getValue(thisObj, 'chkboxOrganic')
    if (organiC_IND == true) organiC_IND = 'Y'
    else organiC_IND = 'N'
    bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
    let obl_buy_pt_id = getValue(thisObj, 'ddBuyingPt')
    bFillOblWarehouseBinList(obl_buy_pt_id, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
  }
  thisObj.onchkboxOrganicChange = onchkboxOrganicChange



  const bFillStateList = async (buyingPointId, stateAbbrevations, filterStatus) => {
    try {
      let js = [];
      let obj = {};
      obj = { key: "", description: "Select" };
      js.push(obj)
      let response = await ContractManagementService.RetrievePeanutStateControlDetails(buyingPointId, stateAbbrevations, filterStatus)
      let data = response;
      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let dataExist = js.find(elem => elem.key == data[i].stateId)
          if (dataExist == undefined) {
            obj = {
              key: data[i].stateId,
              description: data[i].stateName,
              stateAbbrevations: data[i].stateAbbr
            }
            js.push(obj);
            obj = {};
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: js,
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading State event"
        );
      }
    }
  };

  //Method for binding County list
  const bFillCounty = async (buyingPointId, filterStatus, stateAbbrevations, countyId) => {
    try {
      let js = [];
      let obj = {};
      obj = { key: "", description: "Select" };
      js.push(obj);

      if ((getValue(thisObj, 'ddState')) !== null && (getValue(thisObj, 'ddState')) !== "") {
        let response = await ContractManagementService.RetrieveCountyControlDetails(buyingPointId, filterStatus, stateAbbrevations, countyId)
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let dataExist = js.find(elem => elem.key == data[i].countyId)
            if (dataExist == undefined) {
              obj = {
                key: data[i].countyId,
                description: data[i].countyName.trim(),
              }
              js.push(obj);
              obj = {};
            }
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: js,
          }
        }
      })

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Counties event"
        );
      }

    }
  };

  //Method for Farm list
  const bFillFarmlist = async (buy_pt_id, stateAbbrevations, CountyId) => {
    try {
      let farmId = ''
      let farmSuffix = ''
      let js = [];
      let obj = {};

      let response = await ContractManagementService.RetrieveFarmControlDetails('PN1030', null, buy_pt_id, stateAbbrevations, CountyId, farmId, farmSuffix)
      let data = response
      obj = { key: "", description: "Select" };
      js.push(obj);
      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          // let dataExist = js.find(elem => elem.key == (data[i].farm_id+i));
          // if (dataExist == undefined) {
            obj = {
              key: data[i].farm_id + '-' + data[i].farm_suffix + '-' + data[i].farm_name,
              description: data[i].farm_id + '-' + data[i].farm_suffix + '-' + data[i].farm_name,
              farmSuffix: data[i].farm_suffix,
              irrdrydefault: data[i].irr_dry_default
            }
            js.push(obj)
            obj = {}
          // }
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: js,
          }
        }
      })
      setData(thisObj,'farmListData',js)
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Farm event"
        );
      }
      return false;
    }
    return true;
  };
  //Method for binding Peanut Type dropdown
  const bFillTypeList = async() => {
    let js = []
    let obj = {};
    obj = { key: "", description: "Select" }
    js.push(obj)
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
      let data = response

      if (response !== undefined && response.length > 0) {
        for (var i = 0; i < data.length; i++) {
          obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName, gaugeData: data[i].smk1Guage + '|' + data[i].smk2Guage + '|' + data[i].smk3Guage + '|' + data[i].smk4Guage }
          js.push(obj)
        }
      }

      setData(thisObj, 'PeanutTypeData', js)
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
      // setValue(thisObj, 'ddPeanutType', js.at(0).key);
    
  }
  //Method for binding Peanut Variety based on Buying Point and Peanut Type
  const bFillVarietyList = async (buyingPointId, pnut_type_id) => {
    let js = [];
    let obj = {}
    obj = { key: "", description: "Select" }
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      let varietyData = await ContractManagementService.RetrievePeanutVarietyControlFilteredDetails(buyingPointId,pnut_type_id);
      if (varietyData.length > 0) {
        for (var i = 0; i < varietyData.length; i++) {
          let dataExist = js.find(elem => elem.key == varietyData[i].pnuT_VARIETY_ID);
          if (dataExist == undefined) {
            obj = { key: varietyData[i].pnuT_VARIETY_ID, description: varietyData[i].pnuT_VARIETY_NAME, oleicInd: varietyData[i].oleiC_IND }
            if (varietyData[i].symboL_IND == "TRADEMARK") {
              obj = { key: varietyData[i].pnuT_VARIETY_ID, description: varietyData[i].pnuT_VARIETY_NAME + 'ᵀᴹ', oleicInd: varietyData[i].oleiC_IND }
            }
            if (varietyData[i].symboL_IND == "REGISTERED TRADEMARK") {
              obj = { key: varietyData[i].pnuT_VARIETY_ID, description: varietyData[i].pnuT_VARIETY_NAME + '®', oleicInd: varietyData[i].oleiC_IND }
            }
            if (varietyData[i].symboL_IND == "COPYRIGHT") {
              obj = { key: varietyData[i].pnuT_VARIETY_ID, description: varietyData[i].pnuT_VARIETY_NAME + '©', oleicInd: varietyData[i].oleiC_IND }
            }
            js.push(obj);
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js
          }
        }
      });
    }
  }

  //Peanut Type dropdown change event
  const onddPeanutTypeChange = async () => {
    try {
      setLoading(true);
      let lstPeanutList = thisObj.state['ddPeanutType'].valueList;
      let selectedPeanut = '';
      let peanutkey = thisObj.values['ddPeanutType'];
      if (peanutkey !== undefined && peanutkey !== null && peanutkey !== '') {
        selectedPeanut = lstPeanutList.find(elem => elem.key == peanutkey);
        let arrSMKGauges = []
        let buy_point_id = getValue(thisObj, 'ddLocation')
        await bFillVarietyList(buy_point_id, peanutkey);
        let organiC_IND = getValue(thisObj, 'chkboxOrganic')
        if (organiC_IND == true) organiC_IND = 'Y'
        else organiC_IND = 'N'
        await bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
        bFillOblBuyingPointList(buy_point_id)
        arrSMKGauges = selectedPeanut.gaugeData.split('|')
        setValue(thisObj, 'txtSMK1Gr', arrSMKGauges[0])
        setValue(thisObj, 'txtSMK2Gr', arrSMKGauges[1])
        setValue(thisObj, 'txtSMK3Gr', arrSMKGauges[2])
        setValue(thisObj, 'txtSMK4Gr', arrSMKGauges[3])
        setLblLoadType1(true)
        EnabelDisableGradeEntry(buy_point_id, peanutkey, lblElecUploadInd, getValue(thisObj, 'txt1007'))
        const vendorList = getData(thisObj, 'vendorList')
        EnableDisableControls(vendorList, lblStatusTag, lblElecUploadInd, lblLoadType1)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onSegChange = () => {
    try {
      // let lstsegList = thisObj.state['ddSeg'].valueList;
      // let segIndex = '';
      // let segkey = thisObj.values['ddSeg'];
      // if (segkey !== undefined && segkey !== null) {
      //   segIndex = lstsegList.find(elem => elem.key == segkey).rowid;
      // }

      // let lstgenList = thisObj.state['ddSeedGen'].valueList;
      // let genIndex = '';
      // let genkey = thisObj.values['ddSeedGen'];
      // if (genkey !== undefined && genkey !== null) {
      //   genIndex = lstgenList.find(elem => elem.key == genkey).rowid;
      // }

      // let lstoilList = thisObj.state['ddEdibleOil'].valueList;
      // let oilIndex = '';
      // let oilkey = thisObj.values['ddEdibleOil'];
      // if (oilkey !== undefined && oilkey !== null) {
      //   oilIndex = lstoilList.find(elem => elem.key == oilkey).rowid;
      // }

      // if (cbosegTag == '') cbosegTag = '-1'
      // let cboText = lstsegList.find(elem => elem.key == segkey).description
      // if (parseInt(cboText) !== 1) {
      //   genIndex = 0
      //   disable(thisObj, 'ddSeedGen')
      //   oilIndex = 0
      //   enable(thisObj, 'ddEdibleOil')

      // }
      // else {
      //   genIndex = parseInt(genIndex)

      //   enable(thisObj, 'ddSeedGen')

      //   oilIndex = 0;
      //   disable(thisObj, 'ddEdibleOil')
      // }

      let segValue=getValue(thisObj,'ddSeg')
      let lstgenList = thisObj.state['ddSeedGen'].valueList;
      let lstoilList = thisObj.state['ddEdibleOil'].valueList;
      let genValue=getValue(thisObj,'ddSeedGen')
      let edibleValue=getValue(thisObj,'ddEdibleOil')

      if(segValue!='1'){
        setValue(thisObj, 'ddSeedGen', lstgenList.at(0).key)
        disable(thisObj,'ddSeedGen')
        setValue(thisObj, 'ddEdibleOil', lstoilList.at(1).key)
        enable(thisObj,'ddEdibleOil')
      }
      else{
        if(genValue==''){
          setValue(thisObj, 'ddSeedGen', lstgenList.at(0).key)
          enable(thisObj,'ddSeedGen')
        }
        setValue(thisObj, 'ddEdibleOil', lstoilList.at(0).key)
        disable(thisObj,'ddEdibleOil')
      }

      let buy_point_id = getValue(thisObj, 'ddLocation')
      // let seed_gen = getValue(thisObj, 'ddSeedGen')
      let organiC_IND = getValue(thisObj, 'chkboxOrganic')
      if (organiC_IND == true) organiC_IND = 'Y'
      else organiC_IND = 'N'
      bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
      bFillOblBuyingPointList(buy_point_id)
      EnabelDisableVicam(buy_point_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during seg change event"
        );
      }
      return false
    }
    return true
  };
  thisObj.onddSegChange = onSegChange
  //Peanut Variety DropDown Change Event
  const PeanutVarietyChange = async () => {
    try {
      await bFillOleicList()

      let buy_point_id = getValue(thisObj, 'ddLocation')
      // let seed_gen = getValue(thisObj, 'ddSeedGen')
      let organiC_IND = getValue(thisObj, 'chkboxOrganic')
      if (organiC_IND == true) organiC_IND = 'Y'
      else organiC_IND = 'N'
      await bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
      bFillOblBuyingPointList(buy_point_id)
      setLblLoadType1(true)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Variety dropdown change event"
        );
      }
      return false;
    }
    return true
  }
  thisObj.onddPeanutVarietyChange = PeanutVarietyChange
  // Method for binding Segment list  
  const bFillSegmentList = () => {
    let js = []

    // js.push({ key: '', description: 'Select' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })

    setData(thisObj, 'SegData', js)
    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeg', js.at(0).key);
  }

  // Method for binding Seed Genration
  const bFillGeneration = () => {
    let js = []

    js.push({ key: '', description: 'Select' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    setData(thisObj, 'generationData', js)
    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGen: {
          ...state["ddSeedGen"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeedGen', js.at(0).key);
  }

  // Method for Oleic values 
  const bFillOleicList = async () => {
    let js = []
    // js.push({ key: '', description: 'Select' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: '', description: 'None' })

    let peanutVarietyList = thisObj.state['ddPeanutVariety'].valueList;

    let selectedPeanutVariety = '';
    let peanutVarietyKey = thisObj.values['ddPeanutVariety'];

    if (peanutVarietyKey !== undefined && peanutVarietyKey !== null) {
      selectedPeanutVariety = peanutVarietyList.find(elem => elem.key == peanutVarietyKey);

      setValue(thisObj, 'ddOleic', selectedPeanutVariety.oleicInd);
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })

  }

  //Method for binding Edible Oil
  const bFillEdibleOil = () => {
    let js = []
    // js.push({ key: '', description: 'Select' })
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddEdibleOil', js.at(0).key);
  }

  const ClearHMC = () => {
    setValue(thisObj, 'txtSeg', '');
    setValue(thisObj, 'txtFM', '');
    setValue(thisObj, 'txtLSK', '');
    setValue(thisObj, 'txtMST', '');
    setValue(thisObj, 'txtSMK', '');
    setValue(thisObj, 'txtSS', '');
    setValue(thisObj, 'txtSMKRS', '');
    setValue(thisObj, 'txtOK', '');
    setValue(thisObj, 'txtFRZ', '');
    setValue(thisObj, 'txtCRMD', '');
    setValue(thisObj, 'txtTotalKernels', '');
    setValue(thisObj, 'txtDAM', '');
    setValue(thisObj, 'txtHULLS', '');
    setValue(thisObj, 'txtELK', '');
    setValue(thisObj, 'txtFANCY', '');
    setValue(thisObj, 'txtTotKrnlsHulls', '');
  }

  const onbtnCancelClick = () => {
    if (lblLoadType1 == true) {
      const confirmBox = window.confirm(
        "All information entered for this SC95 will be lost since the last time it was saved!!! Continue?"
      )
      if (confirmBox == true) {
        document.getElementsByClassName("close")[0].click();
      }
    }
    else {
      document.getElementsByClassName("close")[0].click();
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  let onbtnCreateFarmClick=()=>{
try{
    //key: data[i].buy_pt_id,
    // description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim(),
    let selectedLocation = getValue(thisObj, 'ddLocation')
    let buy_point_id = ""
    let buyingPointName=''
    let buyingPointList = thisObj.state['ddLocation'].valueList;
    if (selectedLocation !== "" && selectedLocation !== undefined) {
      buy_point_id = buyingPointList.find(elem => elem.key == selectedLocation).key;
      buyingPointName = buyingPointList.find(elem => elem.key == selectedLocation).description;
    }

    let selectedState = getValue(thisObj, 'ddState')
    let state_id = ""
    let stateName=''
    let stateAbbrevations=''
    let stateList = thisObj.state['ddState'].valueList;
    if (selectedState !== "" && selectedState !== undefined) {
      state_id = stateList.find(elem => elem.key == selectedState).key;
      stateName = stateList.find(elem => elem.key == selectedState).description;
      stateAbbrevations = stateList.find(elem=>elem.key==selectedState).stateAbbrevations;
    }

    let SelectedCountyValue = getValue(thisObj, 'ddCounty')
    let countY_ID = ""
    let countyName=''
    let countyList = thisObj.state['ddCounty'].valueList;
    if (SelectedCountyValue !== "" && SelectedCountyValue !== undefined) {
      countY_ID = countyList.find(elem => elem.key == SelectedCountyValue).key;
      countyName = countyList.find(elem => elem.key == SelectedCountyValue).description;
    }

    let farmDetails = getValue(thisObj, 'ddFarm')
    // let farm_id = ""
    // let farmName=''
    let farmList = thisObj.state['ddFarm'].valueList;
    if(farmList.length>1 && farmDetails==undefined){
      farmDetails=farmList[1].key;
    }

    let frmFarmSetup={};
    frmFarmSetup.buyingPointId=buy_point_id;
    frmFarmSetup.buyingPointName=buyingPointName
    frmFarmSetup.stateId=state_id
    frmFarmSetup.state_Name=stateName
    frmFarmSetup.stateAbbr=stateAbbrevations
    frmFarmSetup.countyId=countY_ID
    frmFarmSetup.county_Name=countyName
    frmFarmSetup.farm_details=farmDetails
    // frmFarmSetup.farm_Name=farmName
    // frmFarmSetup.farm_suffix=farmSuffix
    
    setData(thisObj,'farmSetupData',frmFarmSetup)
    goTo(thisObj,"SystemMaintenanceMasterManagement#FarmSetup#DEFAULT#true#Click")
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during Create farm Button"
      );
    }
    return false; 
  }
  }

  thisObj.onbtnCreateFarmClick=onbtnCreateFarmClick

  const onchkboxVicamChange = () => {
    let value = getValue(thisObj, 'chkboxVicam');
    if (value) {
      enable(thisObj, 'txtReading');
    }
    else {
      setValue(thisObj, 'txtReading', '');
      disable(thisObj, 'txtReading');
    }
  }
  thisObj.onchkboxVicamChange = onchkboxVicamChange;

  const onddIrrigatedDrylandChange = () =>{
    setLblLoadType1(true)
  }
  thisObj.onddIrrigatedDrylandChange=onddIrrigatedDrylandChange

  const onchkboxFlavusFoundChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.onchkboxFlavusFoundChange = onchkboxFlavusFoundChange;

  const onbtnConvertHMCClick = () => {
    let strHMC = "N";
    let moistureReading = getValue(thisObj, 'txtMoisture');
    if (parseFloat(moistureReading) > 10.49) {
      strHMC = "Y";
      setStrHMC('Y')
    }
    if (strHMC == "Y") {
      let area_id = getValue(thisObj, 'ddLocation');
      let peanutTypeId = getValue(thisObj, 'ddPeanutType');
      if (peanutTypeId == undefined) {
        peanutTypeId = -1;
      }
      let MoisturePCT = getValue(thisObj, 'txtMoisture');
      let ForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
      let LSKPct = getValue(thisObj, 'txtLSKPct');
      let SMK = getValue(thisObj, 'txtSMKRSPct');
      let SSPct = getValue(thisObj, 'txtSoundSplitsPct');
      let SMKRS = getValue(thisObj, 'txtTotalSMK');
      let OKPct = getValue(thisObj, 'txtOtherKernelsPct');
      let FRZPct = getValue(thisObj, 'txtFreezeDamagePct');
      let CRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
      let TOTKERNPct = getValue(thisObj, 'txtTotalKernels');
      let DamagePct = getValue(thisObj, 'txtTotalDamagePct');
      let HullsPct = getValue(thisObj, 'txtHullsPct');
      let ELKPct = getValue(thisObj, 'txtELKPct');
      let Fancy = getValue(thisObj, 'txtFancyPct');
      ConvertHMCGrades(area_id, peanutTypeId, MoisturePCT, ForeignMaterialPct, LSKPct, SMK, SSPct, SMKRS, OKPct, FRZPct, CRMDPct, TOTKERNPct, DamagePct, HullsPct, ELKPct, Fancy);
    }
  }
  thisObj.onbtnConvertHMCClick = onbtnConvertHMCClick;

  const ontxtNameChange = () => {
    setLblLoadType1(true);
  }
  thisObj.ontxtNameChange = ontxtNameChange;

  const ontxtConcealedRMDPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtConcealedRMDPctChange = ontxtConcealedRMDPctChange;

  const ontxtCrkOrBrkShellsGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtCrkOrBrkShellsGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let ValenciaCleanSampleWt = getValue(thisObj, 'txtValenciaCleanSampleWt');
      let CrkOrBrkShellsGr = getValue(thisObj, 'txtCrkOrBrkShellsGr');
      if (isNumeric(ValenciaCleanSampleWt) && isNumeric(CrkOrBrkShellsGr)) {
        if (ValenciaCleanSampleWt != 0) {
          let calculated = FormatNumber((CrkOrBrkShellsGr / ValenciaCleanSampleWt) * 100);
          setValue(thisObj, 'txtCrkOrBrkShellsPct', calculated);
        }
        else {
          setValue(thisObj, 'txtCrkOrBrkShellsPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtCrkOrBrkShellsPct', '');
      }
    }
  }
  thisObj.ontxtCrkOrBrkShellsGrChange = ontxtCrkOrBrkShellsGrChange;

  const ontxtDamageSplitsChange = () => {
    setLblLoadType1(true);
  }
  thisObj.ontxtDamageSplitsChange = ontxtDamageSplitsChange

  const ontxtCrkOrBrkShellsPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtCrkOrBrkShellsPctChange = ontxtCrkOrBrkShellsPctChange;

  const ontxtDiscoloredShellsGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtDiscoloredShellsGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let ValenciaCleanSampleWt = getValue(thisObj, 'txtValenciaCleanSampleWt');
      let DiscoloredShellsGr = getValue(thisObj, 'txtDiscoloredShellsGr');
      if (isNumeric(ValenciaCleanSampleWt) && isNumeric(DiscoloredShellsGr)) {
        if (ValenciaCleanSampleWt != 0) {
          let calculated = FormatNumber((DiscoloredShellsGr / ValenciaCleanSampleWt) * 100);
          setValue(thisObj, 'txtDiscoloredShellsPct', calculated);
        }
        else {
          setValue(thisObj, 'txtDiscoloredShellsPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtDiscoloredShellsPct', '');
      }
    }
  }
  thisObj.ontxtDiscoloredShellsGrChange = ontxtDiscoloredShellsGrChange;

  const ontxtDiscoloredShellsPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtDiscoloredShellsPctChange = ontxtDiscoloredShellsPctChange;

  const ontxtELKGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtELKGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let ELKGr = getValue(thisObj, 'txtELKGr');
      if (isNumeric(CleanSampleWt) && isNumeric(ELKGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((ELKGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtELKPct', calculated);
        }
        else {
          setValue(thisObj, 'txtELKPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtELKPct', '');
      }
    }
  }
  thisObj.ontxtELKGrChange = ontxtELKGrChange;

  const ontxtELKPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtELKPctChange = ontxtELKPctChange;

  const ontxtFancyGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtFancyGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let FancyGr = getValue(thisObj, 'txtFancyGr');
      if (isNumeric(CleanSampleWt) && isNumeric(FancyGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((FancyGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtFancyPct', calculated);
        }
        else {
          setValue(thisObj, 'txtFancyPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtFancyPct', '');
      }
    }
  }
  thisObj.ontxtFancyGrChange = ontxtFancyGrChange;

  const ontxtFancyPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtFancyPctChange = ontxtFancyPctChange;

  const ontxtFMSampleWtChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }

    let FMSampleWt = getValue(thisObj, 'txtFMSampleWt');
    let ForeignMaterialGr = getValue(thisObj, 'txtForeignMaterialGr');
    if (isNumeric(FMSampleWt) && isNumeric(ForeignMaterialGr)) {
      if (FMSampleWt != 0) {
        let calculated = FormatNumber((ForeignMaterialGr / FMSampleWt) * 100);
        setValue(thisObj, 'txtForeignMaterialPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtForeignMaterialPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtForeignMaterialPct', '');
    }

    let LSKGr = getValue(thisObj, 'txtLSKGr');
    if (isNumeric(FMSampleWt) && isNumeric(LSKGr)) {
      if (FMSampleWt != 0) {
        let calculated = FormatNumber((LSKGr / FMSampleWt) * 100);
        setValue(thisObj, 'txtLSKPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtLSKPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtLSKPct', '');
    }
  }
  thisObj.ontxtFMSampleWtChange = ontxtFMSampleWtChange;

  const ontxtForeignMaterialGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtForeignMaterialGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let FMSampleWt = getValue(thisObj, 'txtFMSampleWt');
      let ForeignMaterialGr = getValue(thisObj, 'txtForeignMaterialGr');
      if (isNumeric(FMSampleWt) && isNumeric(ForeignMaterialGr)) {
        if (FMSampleWt != 0) {
          let calculated = FormatNumber((ForeignMaterialGr / FMSampleWt) * 100);
          setValue(thisObj, 'txtForeignMaterialPct', Number(calculated).toFixed());
        }
        else {
          setValue(thisObj, 'txtForeignMaterialPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtForeignMaterialPct', '');
      }
    }
  }
  thisObj.ontxtForeignMaterialGrChange = ontxtForeignMaterialGrChange;

  const ontxtForeignMaterialPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtForeignMaterialPctChange = ontxtForeignMaterialPctChange;

  const ontxtFreezeDamageGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtFreezeDamageGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let FreezeDamageGr = getValue(thisObj, 'txtFreezeDamageGr');
      if (isNumeric(CleanSampleWt) && isNumeric(FreezeDamageGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((FreezeDamageGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtFreezeDamagePct', Number(calculated).toFixed(2));
        }
        else {
          setValue(thisObj, 'txtFreezeDamagePct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtFreezeDamagePct', '');
      }
    }
  }
  thisObj.ontxtFreezeDamageGrChange = ontxtFreezeDamageGrChange;

  const ontxtFreezeDamagePctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtFreezeDamagePctChange = ontxtFreezeDamagePctChange;

  const ontxtHullBrightnessChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtHullBrightness');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let HullBrightness = getValue(thisObj, 'txtHullBrightness');
      if (isNumeric(CleanSampleWt) && isNumeric(HullBrightness)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((HullBrightness / CleanSampleWt) * 100);
          setValue(thisObj, 'txtHullBrightnessPct', calculated);
        }
        else {
          setValue(thisObj, 'txtHullBrightnessPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtHullBrightnessPct', '');
      }
    }
  }
  thisObj.ontxtHullBrightnessChange = ontxtHullBrightnessChange;

  const ontxtHullBrightnessPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtHullBrightnessPctChange = ontxtHullBrightnessPctChange;

  const ontxtHullsGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtHullsGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let HullsGr = getValue(thisObj, 'txtHullsGr');
      if (isNumeric(CleanSampleWt) && isNumeric(HullsGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((HullsGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtHullsPct', Number(calculated).toFixed());

          let HullsPct = Number(calculated).toFixed();
          if (HullsPct == undefined || HullsPct == '' || HullsPct == null) {
            HullsPct = 0;
          }

          let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
          let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
          let OtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
          let TotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
          setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), OtherKernelsPct, TotalDamagePct, Number(HullsPct));
        }
        else {
          setValue(thisObj, 'txtHullsPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtHullsPct', '');
      }
    }
  }
  thisObj.ontxtHullsGrChange = ontxtHullsGrChange;

  const ontxtHullsPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
    let TotalKrnls = getValue(thisObj, 'txtTotalKrnls');
    if (TotalKrnls == undefined || TotalKrnls == '' || TotalKrnls == null) {
      TotalKrnls = 0;
    }
    let HullsPct = getValue(thisObj, 'txtHullsPct');
    if (HullsPct == undefined || HullsPct == '' || HullsPct == null) {
      HullsPct = 0;
    }
    let TotalKernelsHulls = FormatNumber(Number(TotalKrnls) + Number(HullsPct));
    setValue(thisObj, 'txtTotalKernelsHulls', TotalKernelsHulls);

    let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
    let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
    let OtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
    let TotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
    setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), OtherKernelsPct, TotalDamagePct, HullsPct)
  }
  thisObj.ontxtHullsPctChange = ontxtHullsPctChange;

  const ontxtJumboGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtJumboGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let JumboGr = getValue(thisObj, 'txtJumboGr');
      if (isNumeric(CleanSampleWt) && isNumeric(JumboGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((JumboGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtJumboPct', calculated);
        }
        else {
          setValue(thisObj, 'txtJumboPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtJumboPct', '');
      }
    }
  }
  thisObj.ontxtJumboGrChange = ontxtJumboGrChange;

  const ontxtJumboPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtJumboPctChange = ontxtJumboPctChange;

  const ontxtLSKGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtLSKGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let FMSampleWt = getValue(thisObj, 'txtFMSampleWt');
      let LSKGr = getValue(thisObj, 'txtLSKGr');
      if (isNumeric(FMSampleWt) && isNumeric(LSKGr)) {
        if (FMSampleWt != 0) {
          let calculated = FormatNumber((LSKGr / FMSampleWt) * 100);
          setValue(thisObj, 'txtLSKPct', Number(calculated).toFixed());
        }
        else {
          setValue(thisObj, 'txtLSKPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtLSKPct', '');
      }
    }
  }
  thisObj.ontxtLSKGrChange = ontxtLSKGrChange;

  const ontxtLSKPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtLSKPctChange = ontxtLSKPctChange;

  const ontxtMoistureChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtMoistureChange = ontxtMoistureChange;

  const ontxtOtherKernelsGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtOtherKernelsGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let OtherKernelsGr = getValue(thisObj, 'txtOtherKernelsGr');
      if (isNumeric(CleanSampleWt) && isNumeric(OtherKernelsGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((OtherKernelsGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtOtherKernelsPct', Number(calculated).toFixed());

          let TotalSMK = getValue(thisObj, 'txtTotalSMK');
          let OtherKernelsPct = Number(calculated).toFixed();
          let TotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');

          TotalSMK = (TotalSMK != undefined && TotalSMK != "" && TotalSMK != null) ? Number(TotalSMK) : 0;
          OtherKernelsPct = (OtherKernelsPct != undefined && OtherKernelsPct != "" && OtherKernelsPct != null) ? Number(OtherKernelsPct) : 0;
          TotalDamagePct = (TotalDamagePct != undefined && TotalDamagePct != "" && TotalDamagePct != null) ? Number(TotalDamagePct) : 0;

          let TotalKrnls = FormatNumber(Number(TotalSMK) + Number(OtherKernelsPct) + Number(TotalDamagePct));
          setValue(thisObj, 'txtTotalKrnls', TotalKrnls);

          let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
          let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
          setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), OtherKernelsPct, TotalDamagePct, getValue(thisObj, 'txtHullsPct'))
        }
        else {
          setValue(thisObj, 'txtOtherKernelsPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtOtherKernelsPct', '');
      }
    }
  }
  thisObj.ontxtOtherKernelsGrChange = ontxtOtherKernelsGrChange;

  const ontxtOtherKernelsPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }

    let TotalSMK = getValue(thisObj, 'txtTotalSMK');
    let OtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
    let TotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');

    TotalSMK = (TotalSMK != undefined && TotalSMK != "" && TotalSMK != null) ? Number(TotalSMK) : 0;
    OtherKernelsPct = (OtherKernelsPct != undefined && OtherKernelsPct != "" && OtherKernelsPct != null) ? Number(OtherKernelsPct) : 0;
    TotalDamagePct = (TotalDamagePct != undefined && TotalDamagePct != "" && TotalDamagePct != null) ? Number(TotalDamagePct) : 0;

    let TotalKrnls = FormatNumber(Number(TotalSMK) + Number(OtherKernelsPct) + Number(TotalDamagePct));
    setValue(thisObj, 'txtTotalKrnls', TotalKrnls);

    let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
    let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
    setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), OtherKernelsPct, TotalDamagePct, getValue(thisObj, 'txtHullsPct'))
  }
  thisObj.ontxtOtherKernelsPctChange = ontxtOtherKernelsPctChange;

  const ontxtSMKRSGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtSMKRSGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let SMKRSGr = getValue(thisObj, 'txtSMKRSGr');
      if (isNumeric(CleanSampleWt) && isNumeric(SMKRSGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((SMKRSGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtSMKRSPct', Number(calculated).toFixed());

          let SMKRSPct = Number(calculated).toFixed();
          let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
          if (SoundSplitsPct == undefined || SoundSplitsPct == '' || SoundSplitsPct == null) {
            SoundSplitsPct = 0;
          }
          let TotalSMK = FormatNumber(Number(SMKRSPct) + Number(SoundSplitsPct));
          setValue(thisObj, 'txtTotalSMK', TotalSMK);
          setTotalKernals(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'))
          setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'), getValue(thisObj, 'txtHullsPct'))
        }
        else {
          setValue(thisObj, 'txtSMKRSPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtSMKRSPct', '');
      }
    }
  }
  thisObj.ontxtSMKRSGrChange = ontxtSMKRSGrChange;

  const ontxtSMKRSPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }

    let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
    let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
    if (SoundSplitsPct == undefined || SoundSplitsPct == '' || SoundSplitsPct == null) {
      SoundSplitsPct = 0;
    }
    let TotalSMK = FormatNumber(Number(SMKRSPct) + Number(SoundSplitsPct));
    setValue(thisObj, 'txtTotalSMK', TotalSMK);
    setTotalKernals(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'))
    setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'), getValue(thisObj, 'txtHullsPct'))
  }
  thisObj.ontxtSMKRSPctChange = ontxtSMKRSPctChange;

  const ontxtSoundSplitsGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtSoundSplitsGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let SoundSplitsGr = getValue(thisObj, 'txtSoundSplitsGr');
      if (isNumeric(CleanSampleWt) && isNumeric(SoundSplitsGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((SoundSplitsGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtSoundSplitsPct', Number(calculated).toFixed());

          let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
          SMKRSPct = (SMKRSPct != undefined && SMKRSPct != "" && SMKRSPct != null) ? Number(SMKRSPct) : 0;
          let SoundSplitsPct = Number(calculated).toFixed();
          let TotalSMK = FormatNumber(Number(SMKRSPct) + Number(SoundSplitsPct));
          setValue(thisObj, 'txtTotalSMK', TotalSMK);
          setTotalKernals(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'))
          setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'), getValue(thisObj, 'txtHullsPct'))
        }
        else {
          setValue(thisObj, 'txtSoundSplitsPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtSoundSplitsPct', '');
      }
    }
  }
  thisObj.ontxtSoundSplitsGrChange = ontxtSoundSplitsGrChange;

  const ontxtSoundSplitsPctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }

    let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
    SMKRSPct = (SMKRSPct != undefined && SMKRSPct != "" && SMKRSPct != null) ? Number(SMKRSPct) : 0;
    let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
    let TotalSMK = FormatNumber(Number(SMKRSPct) + Number(SoundSplitsPct));
    setValue(thisObj, 'txtTotalSMK', TotalSMK);
    setTotalKernals(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'))
    setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), getValue(thisObj, 'txtOtherKernelsPct'), getValue(thisObj, 'txtTotalDamagePct'), getValue(thisObj, 'txtHullsPct'))
  }
  thisObj.ontxtSoundSplitsPctChange = ontxtSoundSplitsPctChange;

  const ontxtTotalDamageGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtTotalDamageGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let TotalDamageGr = getValue(thisObj, 'txtTotalDamageGr');
      if (isNumeric(CleanSampleWt) && isNumeric(TotalDamageGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((TotalDamageGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtTotalDamagePct', Number(calculated).toFixed());

          let TotalSMK = getValue(thisObj, 'txtTotalSMK');
          if (TotalSMK == undefined || TotalSMK == '' || TotalSMK == null) {
            TotalSMK = 0;
          }
          let OtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
          if (OtherKernelsPct == undefined || OtherKernelsPct == '' || OtherKernelsPct == null) {
            OtherKernelsPct = 0;
          }
          let TotalDamagePct = Number(calculated).toFixed();
          if (TotalDamagePct == undefined || TotalDamagePct == '' || TotalDamagePct == null) {
            TotalDamagePct = 0;
          }
          let TotalKrnls = FormatNumber(Number(TotalSMK) + Number(OtherKernelsPct) + Number(TotalDamagePct));
          setValue(thisObj, 'txtTotalKrnls', TotalKrnls);
          let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
          let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
          setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), OtherKernelsPct, TotalDamagePct, getValue(thisObj, 'txtHullsPct'))
        }
        else {
          setValue(thisObj, 'txtTotalDamagePct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtTotalDamagePct', '');
      }
    }
  }
  thisObj.ontxtTotalDamageGrChange = ontxtTotalDamageGrChange;

  const ontxtTotalDamagePctChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }

    let TotalSMK = getValue(thisObj, 'txtTotalSMK');
    if (TotalSMK == undefined || TotalSMK == '' || TotalSMK == null) {
      TotalSMK = 0;
    }
    let OtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
    if (OtherKernelsPct == undefined || OtherKernelsPct == '' || OtherKernelsPct == null) {
      OtherKernelsPct = 0;
    }
    let TotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
    if (TotalDamagePct == undefined || TotalDamagePct == '' || TotalDamagePct == null) {
      TotalDamagePct = 0;
    }
    let TotalKrnls = FormatNumber(Number(TotalSMK) + Number(OtherKernelsPct) + Number(TotalDamagePct));
    setValue(thisObj, 'txtTotalKrnls', TotalKrnls);
    let SMKRSPct = getValue(thisObj, 'txtSMKRSPct');
    let SoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
    setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), OtherKernelsPct, TotalDamagePct, getValue(thisObj, 'txtHullsPct'))
  }
  thisObj.ontxtTotalDamagePctChange = ontxtTotalDamagePctChange;

  const ontxtGramsChange = () => {
    setLblLoadType1(true);
    let SampleWt = getValue(thisObj, 'txtSampleWt');
    let Grams = getValue(thisObj, 'txtGrams');
    if (isNumeric(SampleWt) && isNumeric(Grams)) {
      if (SampleWt != 0) {
        let calculated = FormatNumber((Grams / SampleWt) * 100);
        setValue(thisObj, 'txtPercent', calculated);
      }
      else {
        setValue(thisObj, 'txtPercent', 0);
      }
    }
    else {
      setValue(thisObj, 'txtPercent', '');
    }
  }
  thisObj.ontxtGramsChange = ontxtGramsChange;

  const ontxtSampleWtChange = () => {
    setLblLoadType1(true);
    let SampleWt = getValue(thisObj, 'txtSampleWt');
    let Grams = getValue(thisObj, 'txtGrams');
    if (isNumeric(SampleWt) && isNumeric(Grams)) {
      if (SampleWt != 0) {
        let calculated = FormatNumber((Grams / SampleWt) * 100);
        setValue(thisObj, 'txtPercent', calculated);
      }
      else {
        setValue(thisObj, 'txtPercent', 0);
      }
    }
    else {
      setValue(thisObj, 'txtPercent', '');
    }
  }
  thisObj.ontxtSampleWtChange = ontxtSampleWtChange;

  const ontxtReadingChange = () => {
    setLblLoadType1(true);
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate')
    }
  }
  thisObj.ontxtReadingChange = ontxtReadingChange;

  const ontxtValenciaCleanSampleWtChange = () => {
    let ValenciaCleanSampleWt = getValue(thisObj, 'txtValenciaCleanSampleWt');
    let CrkOrBrkShellsGr = getValue(thisObj, 'txtCrkOrBrkShellsGr');
    if (isNumeric(ValenciaCleanSampleWt) && isNumeric(CrkOrBrkShellsGr)) {
      if (ValenciaCleanSampleWt != 0) {
        let calculated = FormatNumber((CrkOrBrkShellsGr / ValenciaCleanSampleWt) * 100);
        setValue(thisObj, 'txtCrkOrBrkShellsPct', calculated);
      }
      else {
        setValue(thisObj, 'txtCrkOrBrkShellsPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtCrkOrBrkShellsPct', '');
    }

    let DiscoloredShellsGr = getValue(thisObj, 'txtDiscoloredShellsGr');
    if (isNumeric(ValenciaCleanSampleWt) && isNumeric(DiscoloredShellsGr)) {
      if (ValenciaCleanSampleWt != 0) {
        let calculated = FormatNumber((DiscoloredShellsGr / ValenciaCleanSampleWt) * 100);
        setValue(thisObj, 'txtDiscoloredShellsPct', calculated);
      }
      else {
        setValue(thisObj, 'txtDiscoloredShellsPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtDiscoloredShellsPct', '');
    }
  }
  thisObj.ontxtValenciaCleanSampleWtChange = ontxtValenciaCleanSampleWtChange;

  const handleKeyDown = (data) => {
    if (data.keyCode != 32 && data.keyCode != 8) {
      if (data.keyCode < 48 || (data.keyCode > 57 && data.keyCode < 65) || data.keyCode > 90) {
        data.preventDefault();
      }
    }
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function FormatNumber(value, fraction = 2) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  function PadDate(vDate) {
    if (vDate instanceof Date) {
      if (vDate.trim().split(' ').length !== 0)
        vDate = vDate.toString().replace(' ', '0')

      if (vDate.trim().split('/').length == 2)
        vDate = '0' + vDate.trim()
    }
    else {
      vDate = ''
    }
    return vDate;
  }
  // Method for Change button click event
  const onbtnChangeClick = async () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddLocation')
      let strNewInspNum = ''
      let blnValidFV95 = false
      let strLstXml = ''

      let old_insp_num = getValue(thisObj, 'txtSC95')
      if (blnValidFV95 == false) {
        strNewInspNum = prompt('Enter New SC95 Number', old_insp_num)
        if (strNewInspNum == '' || strNewInspNum == null) return false
        else {
          blnValidFV95 = await ValidNewFV95Number(strNewInspNum)
        }
      }

      if (blnValidFV95 == true && strNewInspNum != '') {
        let dataObj = {};
        dataObj.user_id = (sessionStorage.getItem('userid') || '');
        const compIdFromLS = (sessionStorage.getItem('compId'));
        const cropYearFromLS = (JSON.parse(sessionStorage.getItem('year')));
        let response = await SettlementService.UpdateChangeInspectionNumber(compIdFromLS, cropYearFromLS, buy_point_id, old_insp_num, strNewInspNum, dataObj)
        if (response.status !== 200) {
          return
        }
        else {
          showMessage(thisObj, `SC95 Number successfully changed to ${strNewInspNum}`, true)
          setValue(thisObj, 'txtSC95', strNewInspNum)
        }

      }
      else {
        onbtnChangeClick()
      }
      setLblLoadType1(true)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during change button event event"
        );
      }
      return false
    }
    return true
  }
  thisObj.onbtnChangeClick = onbtnChangeClick

  const ValidNewFV95Number = async (strNewInspNum) => {
    try {
      let ValidNewFV95Number = true;
      let old_insp_num = getValue(thisObj, 'txtSC95')
      strNewInspNum.trim()
      let buy_point_id = getValue(thisObj, 'ddLocation')
      let responseOneDoc = await SettlementService.RetrieveCheck1007OneDocumentDetails(buy_point_id)
      let dataOneDoc = responseOneDoc
      if (strNewInspNum == '') {
        alert("New SC95 Number is required!!!")
        ValidNewFV95Number = false;
      }
      else if (strNewInspNum.length !== 7) {
        showMessage(thisObj, "New SC95 Number must be 7 characters long!!!")
        ValidNewFV95Number = false;
      }
      else if (dataOneDoc[0].onedoc_ind == "Y") {
        if (isNaN(strNewInspNum)) {
          alert('New SC95 Number must be all numeric for locations using One Documents!!!')
          ValidNewFV95Number = false;
        }
      }
      else {
        let numeric = true;
        for (let i = 1; i < strNewInspNum.length; i++) {
          if (isNumeric(strNewInspNum[i]) == false) {
            numeric = false;
          }
        }

        if ((strNewInspNum.charCodeAt(0) >= 65 && strNewInspNum.charCodeAt(0) <= 90 && numeric) == false) {//Need to check the 2nd if condition for Z
          alert('New SC95 Number must be in Format A###### for this location!!!')
          ValidNewFV95Number = false
        }
      }


      // SettlementService.RetrieveInspectionHeaderList(strNewInspNum, null, null, null).then(response => {
      //   let data = response;
      //   if (response == null || response == undefined) return false
      //   if (data[0].insp_num != "") {
      //     showMessage(thisObj, `SC95 '${strNewInspNum} exists, please correct the SC95 number!!!`)
      //     return false
      //   }
      // })

      let response = await SettlementService.RetrieveInspectionHeaderList(strNewInspNum, null, null, null)
      let data = response;
      // if (response == null || response == undefined || response=='') return false
      // if (data[0].insp_num != "") {
      //   showMessage(thisObj, `SC95 '${strNewInspNum} exists, please correct the SC95 number!!!`)
      //   return false
      // } 
      if (data.length > 0) {
        if (data[0].insp_num != "") {
          alert(`SC95 '${strNewInspNum} exists, please correct the SC95 number!!!`)
          ValidNewFV95Number = false
        }
      }
      // else{
      //   ValidNewFV95Number= false;
      // }
      return ValidNewFV95Number;
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during ValidNewFV95Number event event"
        );
      }
      return false
    }
    return true;
  }

  const bCheckHMC = () => {
    let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct')
    let txtFM = getValue(thisObj, 'txtFM')
    let txtLSKPct = getValue(thisObj, 'txtLSKPct')
    let txtLSK = getValue(thisObj, 'txtLSK')
    let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct')
    let txtSMK = getValue(thisObj, 'txtSMK')
    let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct')
    let txtSS = getValue(thisObj, 'txtSS')
    let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct')
    let peanutTypeId = getValue(thisObj, 'ddPeanutType')
    let txtOK = getValue(thisObj, 'txtOK')
    let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct')
    let txtDAM = getValue(thisObj, 'txtDAM')
    let txtHullsPct = getValue(thisObj, 'txtHullsPct')
    let txtHULLS = getValue(thisObj, 'txtHULLS')
    let txtELKPct = getValue(thisObj, 'txtELKPct')
    let txtELK = getValue(thisObj, 'txtELK')
    let txtFancyPct = getValue(thisObj, 'txtFancyPct')
    let txtFANCY = getValue(thisObj, 'txtFANCY')

    if (txtForeignMaterialPct != 0 || txtForeignMaterialPct != "") {
      if (txtFM == 0) {
        showMessage(thisObj, 'The FM Grades cannot be converted. Please check the value.')
        ClearHMC();
        return false;
      }
    }

    if (txtLSKPct != 0) {
      if (txtLSK = 0) {
        showMessage(thisObj, 'The LSK Grades cannot be converted. Please check the value.')
        ClearHMC();
        return false
      }
    }

    if (txtSMKRSPct != 0) {
      if (txtSMK = 0) {
        showMessage(thisObj, "The SMK Grades cannot be converted. Please check the value.")
        ClearHMC();
        return false
      }
    }

    if (txtSoundSplitsPct != 0) {
      if (txtSS = 0) {
        showMessage(thisObj, 'The SS Grades cannot be converted. Please check the value.')
        ClearHMC();
        return false;
      }
    }

    if (txtOtherKernelsPct != 0 && peanutTypeId.key != "VA") {
      if (txtOK = 0) {
        showMessage(thisObj, 'The OK Grades cannot be converted. Please check the value')
        ClearHMC();
        return false;
      }
    }

    if (txtOtherKernelsPct != 0) {
      if (peanutTypeId.key == "VA" && txtOtherKernelsPct > 2) {
        if (txtOK = 0) {
          showMessage(thisObj, 'The OK Grades cannot be converted. Please check the value.')
          ClearHMC()
        }
      }
    }

    if (txtTotalDamagePct != 0) {
      if (txtDAM = 0) {
        showMessage(thisObj, 'The Damaged Grades cannot be converted. Please check the value.')
        ClearHMC()
      }
    }

    if (txtHullsPct != 0) {
      if (txtHULLS = 0) {
        showMessage(thisObj, 'The Hulls Grades cannot be converted. Please check the value')
        ClearHMC()
      }
    }

    if (txtELKPct != 0) {
      if (txtELK = 0) {
        showMessage(thisObj, 'The Elk Grades cannot be converted. Please check the value')
        ClearHMC()
      }
    }

    if (txtFancyPct != 0) {
      if (txtFANCY = 0) {
        showMessage(thisObj, 'The Fancy Grades cannot be converted. Please check the value')
        ClearHMC()
      }
    }

  }

  const GetAccessLevel = async (buy_point_id, FuncSubId, strAccessLevel) => {
    let GetAccessLevel = false;

    let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1030', FuncSubId, strAccessLevel, buy_point_id)
    if (response[0].permission == 'Y') {
      GetAccessLevel = true
    }
    return GetAccessLevel
  }

  const EnableFV95NumberChange = async (buy_pt_id, txtFV95, lblStatusTag, lbl1007, mbSuperseded, mblnElectronicInd) => {

    if (txtFV95 == "" || mblnElectronicInd) {
      disable(thisObj, 'btnChange')
      return;
    }

    switch (lblStatusTag) {
      case "P":
      case "X":
        let access = await GetAccessLevel(buy_pt_id, "001", "U")
        if (access == true) enable(thisObj, 'btnChange')
        else disable(thisObj, 'btnChange')
        break;

      case "I":
        if (lbl1007 != "") {
          let access = await GetAccessLevel(buy_pt_id, "003", "U")
          if (access == true) enable(thisObj, 'btnChange')
          else disable(thisObj, 'btnChange')
        }
        else if (mbSuperseded) {
          let access = await GetAccessLevel(buy_pt_id, "004", "U")
          if (access == true) enable(thisObj, 'btnChange')
          else disable(thisObj, 'btnChange')
        }
        else {
          let access = await GetAccessLevel(buy_pt_id, "002", "U")
          if (access == true) enable(thisObj, 'btnChange')
          else disable(thisObj, 'btnChange')
        }
        break
      default:
        disable(thisObj, 'btnChange')
    }
  }

  const ConvertHMCGrades = (area_id, peanutTypeId, MoisturePCT, ForeignMaterialPct, LSKPct, SMK, SSPct, SMKRS, OKPct, FRZPct, CRMDPct, TOTKERNPct, DamagePct, HullsPct, ELKPct, Fancy) => {
    try {
      let segType = getValue(thisObj, 'ddSeg')
      let dataObj = {}
      dataObj.MoisturePCT = MoisturePCT
      dataObj.ForeignMaterialPct = ForeignMaterialPct,
        dataObj.LSKPct = LSKPct,
        dataObj.SMK = SMK,
        dataObj.SSPct = SSPct,
        dataObj.SMKRS = SMKRS,
        dataObj.OKPct = OKPct,
        dataObj.FRZPct = FRZPct,
        dataObj.CRMDPct = CRMDPct,
        dataObj.TOTKERNPct = TOTKERNPct,
        dataObj.DamagePct = DamagePct,
        dataObj.HullsPct = HullsPct,
        dataObj.ELKPct = ELKPct
      dataObj.Fancy = Fancy

      SettlementService.UpdateConvertHighMoistureToCGrade(area_id, peanutTypeId, segType, dataObj).then(response => {
        let data = response

        for (let i = 0; i < data.length; i++) {
          setValue(thisObj, 'txtSeg', Number(data[i].seg_type))
          setValue(thisObj, 'txtFM', Number(data[i].fm_pct))
          setValue(thisObj, 'txtLSK', Number(data[i].lsk_pct))

          if (isNumeric(data[0].kc_moist_pct)) {
            setValue(thisObj, 'txtMST', Number(data[i].kc_moist_pct))
          }
          else {
            setValue(thisObj, 'txtMST', MoisturePCT)
          }
          setValue(thisObj, 'txtSMK', Number(data[i].smk_pct))
          setValue(thisObj, 'txtOK', Number(data[i].ok_pct))
          setValue(thisObj, 'txtSS', Number(data[i].ss_pct))
          setValue(thisObj, 'txtSMKRS', getValue(thisObj, 'txtSMK') + getValue(thisObj, 'txtSS'))
          setValue(thisObj, 'txtFRZ', getValue(thisObj, 'txtFreezeDamagePct'))
          setValue(thisObj, 'txtCRMD', getValue(thisObj, 'txtConcealedRMDPct'))
          setValue(thisObj, 'txtDAM', Number(data[i].dam_pct))
          setValue(thisObj, 'txtTotalKernels', getValue(thisObj, 'txtSMKRS') + getValue(thisObj, 'txtOK') + getValue(thisObj, 'txtDAM'))
          setValue(thisObj, 'txtHULLS', Number(data[i].hulls_pct))
          setValue(thisObj, 'txtELK', Number(data[i].elk_pct))
          setValue(thisObj, 'txtFANCY', Number(data[i].fancy_pct))
          setValue(thisObj, 'txtTotKrnlsHulls', getValue(thisObj, 'txtTotalKernels') + getValue(thisObj, 'txtHULLS'))
          disable(thisObj, 'btnConvertHMC')

        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ConvertHMCGrades function"
        );
      }
      return false;
    }
    return true;
  }

  const onradioHighMoistureChange = () => {
    if (getValue(thisObj, 'radioHighMoisture') == '1') {
      setYesNo(true);
      enable(thisObj, 'btnConvertHMC')
    }
    else if (getValue(thisObj, 'radioHighMoisture') == '2') {
      setYesNo(false);
      disable(thisObj, 'btnConvertHMC')
    }
  }
  thisObj.onradioHighMoistureChange = onradioHighMoistureChange


  const onbtnCreateClick = async () => {
    try {
      setLoading(true);
      let noVoidSale = false;
      let sStatusID = lblStatusTag
      let sStatusDesc = getValue(thisObj, 'txtStatus')

      if (lblStatusTag == "X") {
        setMbEVoidNoSale(true)
        noVoidSale = true;
      } else {
        setMbEVoidNoSale(false)
        noVoidSale = false;
      }

      if (noVoidSale == false) {
        if (yesNo == false) {
          await bFormValid(sStatusID, 'I');
          if (!isFormValid) {
            setLblStatusTag(sStatusID)
            setValue(thisObj, 'txtStatus', sStatusDesc)
            setLoading(false);
            return;
          }
        } else {
          onbtnConvertHMCClick()
          onbtnCalculateClick();

          await bFormValid_HMC(sStatusID);
          if (!isFormValid) {
            setLblStatusTag(sStatusID)
            setValue(thisObj, 'txtStatus', sStatusDesc)
            setLoading(false);
            return;
          }
        }
      } else {
        await bFormValid(sStatusID, 'I');
        if (!isFormValid) {
          setLblStatusTag(sStatusID)
          setValue(thisObj, 'txtStatus', sStatusDesc)
          setLoading(false);
          return;
        }
      }

      if (isEnabled(thisObj, 'btnCalculate')) {
        disable(thisObj, 'btnCalculate')
      }

      if (noVoidSale == false) {
        if (yesNo == true) {
          onbtnConvertHMCClick();
          onbtnCalculateClick();
        }
      }

      if (noVoidSale == false) {
        if (await bSaveInspection('I')) { //this function need to be implemented
          await bFillChgDateTime();
          setValue(thisObj, 'txtStatus', 'Inspected');
          if (lblElecUploadInd == "N") {
            let res = confirm('Changes made to this inspection have been successfully saved!!! Do you want to enter another SC95?', true);
            if (res == true) {
              setLblStatusTag(sStatusID)
              sStatusID = '';
              setValue(thisObj, 'txtStatus', sStatusDesc)
              FV95Reset();
            } else {
              setLblStatusTag('I');
              sStatusID = 'I';
            }
          } else {
            setLblStatusTag('I');
            sStatusID = 'I';
            showMessage(thisObj, 'Changes made to this inspection have been successfully saved!!!', true);
          }
        } else {
          setLblStatusTag(sStatusID)
          setValue(thisObj, 'txtStatus', sStatusDesc)
        }
      }
      const vendorList = getData(thisObj, 'vendorList')
      EnableDisableControls(vendorList, sStatusID, lblElecUploadInd, false)
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  thisObj.onbtnCreateClick = onbtnCreateClick

  const bFillChgDateTime = async () => {
    let ChgDateTime = false;
    let insp_num = getValue(thisObj, 'txtSC95');
    let vendorInfo = 'N';
    let headerData = await SettlementService.RetrieveInspectionHeaderList(insp_num, null, null, vendorInfo);
    if (headerData.length > 0) {
      setlblChangedBy(headerData[0].chg_user);
      setlblLastChange(headerData[0].chg_date_time);
      ChgDateTime = true;
    }
  }

  const EnableDisableControls = async(vendorList, status, elecUploadStatus, lblLoadType1, freefv95) => {
    enable(thisObj, 'btnSave')
    enable(thisObj, 'btnCreate1007')
    enable(thisObj, 'btnCreate')
    enable(thisObj, 'txtSC95')
    enable(thisObj, 'txtInspectionDateTime')
    enable(thisObj, 'ddState')
    enable(thisObj, 'ddCounty')
    enable(thisObj, 'ddLocation')
    enable(thisObj, 'ddFarm')

    switch (status) {
      case "S":
      case "V":
      case "N":
      case "R":
        disable(thisObj, 'btnSave')
        disable(thisObj, 'btnCreate1007')
        disable(thisObj, 'btnCreate')
        disable(thisObj, 'txtSC95')
        disable(thisObj, 'txtInspectionDateTime')
        disable(thisObj, 'ddState')
        disable(thisObj, 'ddCounty')
        disable(thisObj, 'ddLocation')
        disable(thisObj, 'ddFarm')
        disable(thisObj, 'btnCreateFarm')
        break;

      case "I":
        if (lblLoadType1 == true) {
          disable(thisObj, 'btnCreate1007')
        }
        else {
          disable(thisObj, 'btnSave')
          enable(thisObj, 'btnCreate1007')
        }
        disable(thisObj, 'btnCreate')
        disable(thisObj, 'txtSC95')
        disable(thisObj, 'ddLocation')

        if (lbl1007 != "") {
          disable(thisObj, 'ddPeanutType')
        }
        else {
          if (mbSuperseded == true) {
            disable(thisObj, 'ddState')
            disable(thisObj, 'ddCounty')
            disable(thisObj, 'ddLocation')
            disable(thisObj, 'ddFarm')
            disable(thisObj, 'btnCreate')
            disable(thisObj, 'txtSC95')

            //For loop is required for Vendor Split
            for (let i = 0; i < vendorList.length; i++) {
              disable(thisObj, `ddVendor${i}`)
              disable(thisObj, `btnVendor${i}`)
              disable(thisObj, `ddRemitTo${i}`)
              disable(thisObj, `txtSharePct${i}`)
              disable(thisObj, `txtSharePct${i}`)
            }


            disable(thisObj, 'chkboxVicam')
            disable(thisObj, "txtReading")
            setValue(thisObj, 'txtReading', '')
          }
          else {
            enable(thisObj, 'ddPeanutType')
            enable(thisObj, 'ddPeanutVariety')
            enable(thisObj, 'ddSeedGen')
            enable(thisObj, 'ddSeg')
          }
        }
        break;

      case "P":
      case "X":
        disable(thisObj, 'btnCreate1007')
        disable(thisObj, 'txtSC95')
        disable(thisObj, 'ddLocation')
        break;

      default:
        disable(thisObj, 'btnCreate1007')

    }

    if (mbSupersedePending) {
      disable(thisObj, 'btnSave')
      disable(thisObj, 'btnCreate1007')
      disable(thisObj, 'btnCreate')
      disable(thisObj, 'txtSC95')
      disable(thisObj, 'txtInspectionDateTime')
      disable(thisObj, 'ddState')
      disable(thisObj, 'ddCounty')
      disable(thisObj, 'ddLocation')
      disable(thisObj, 'ddFarm')
      disable(thisObj, 'btnCreateFarm')
    }

    if (elecUploadStatus == "Y") {
      DisableElectronicUploadControls()
    }

    if (freefv95 == "Y") {
      disable(thisObj, 'ddState')
      disable(thisObj, 'ddCounty')
      disable(thisObj, 'ddLocation')
      disable(thisObj, 'ddFarm')

      //Code for Vendor Split will come and then a API call will happen
      for (let i = 0; i < vendorList.length; i++) {
        disable(thisObj, `ddVendor${i}`)
        disable(thisObj, `btnVendor${i}`)
        disable(thisObj, `ddRemitTo${i}`)
        disable(thisObj, `txtSharePct${i}`)
        disable(thisObj, `txtSharePct${i}`)
      }
      let buy_point_id = getValue(thisObj, 'ddLocation')
      let insp_num = getValue(thisObj, 'txtSC95')
      if (buy_point_id != null) {
        SettlementService.RetrieveCheckWareHouserInIvsDetails(buy_point_id, insp_num).then(response => {
          let data = response

          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < vendorList.length; i++) {
              let vendor_dd = getValue(thisObj, `ddVendor${j}`)
              if (vendor_dd == data[i].split_Vendor) {
                disable(thisObj, `ddVendor${i}`)
                disable(thisObj, `btnVendor${i}`)
                disable(thisObj, `ddRemitTo${i}`)
                disable(thisObj, `txtSharePct${i}`)
                disable(thisObj, `txtSharePct${i}`)
              }
            }
          }
        })
      }
    }
  }

  const onbtnReprintClick = async () => {
    try {
      let lstrxml = [];
      let buy_pt_id = getValue(thisObj, 'ddLocation')
      let StatusValue = thisObj.values.txtStatus
      let status = '';
      if (StatusValue == 'Void') {
        status = 'VOID'
      }
      else if (StatusValue == 'Residue Load') {
        status = 'RESIDUE'
      }
      let js = {
        SC95No: thisObj.values.txtSC95,
        buy_pt_id: buy_pt_id,
        type: 'REPRINT_FROM_HEADER',
        method_name: 'rptInspectionCertificate',
        s1007No: '',
        status: status,
        docType: 'Applicant Original',
        WaterMark: status

      }
      lstrxml = await SettlementService.RetrieveCheck1007OneDocumentDetails(buy_pt_id)

      if (lstrxml[0].onedoc_ind == 'Y') {
        js.method_name = 'rptInspectionCertificate_onedoc',
          js.docType = 'Applicant Original,FSIS,SELLER'
      }
      setData(thisObj, 'ReportPreviewInspectionScreenData', js)
      goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, 'Exception in Re-print button Click')
    }
  }
  thisObj.onbtnReprintClick = onbtnReprintClick

  const Form_QueryUnload = async () => {
    const GstrVersion = ''
    const insp_num = getValue(thisObj, 'txtSC95')
    const response = await (SettlementService.RetrieveInspectionHeaderList(insp_num, null, null, "N"))
    let data = response;
    if (process.env.REACT_APP_ENVIR == "DEV") {
      GstrVersion = 'D'
    }
    if (data.length > 0 || data != null || data != undefined) {
      if (data[0].insp_status == "I") {
        if (data[0].settlement_num == "") {
          confirm('Correction must be completed before continuing.')
          if (GstrVersion == "D") {
            if (confirm('Do you want to bypass this edit and exit the correction?') == true) {
              return;
            }
          }
          //Need to implement Cancel=1
          return
        }
        if (data[0].superseded_settle != "" && data[0].superseded_settle != 0) {
          confirm('Superseded inspection must be completed before continuing.')
          if (GstrVersion == "D") {
            if (confirm('Do you want to bypass this edit and exit the supersede?') == true) {
              return;
            }
          }
          //Need to implement Cancel=1
          return
        }
      }
    }
    else {
      showMessage(thisObj, 'There was an error communicating with the database. Please try again in a few moments. If the problem persits please contact support.')
      //Need to implement Cancel=1
      return
    }
  }

  const onbtnSaveClick = async () => {
    try {
      let sStatusID
      let sStatusDesc
      let strreason
      setLoading(true);

      let txt1007 = getValue(thisObj, 'txt1007')
      strreason = getValue(thisObj, 'txtVoidReason')

      if (lblStatusTag == "X") {
        setMbEVoidNoSale(true)
      }
      else {
        setMbEVoidNoSale(false)
      }

      if (lblStatusTag !== 'X') {
        if (yesNo == true) {
          onbtnConvertHMCClick();

          if (isEnabled(thisObj, 'btnCalculate') == true) {
            onbtnCalculateClick();
          }
          if (isEnabled(thisObj, 'btnCalculate') == false && txt1007 != '' && lblStatusTag == "I") {
            onbtnCalculateClick();
          }
        }

        if (yesNo == false) {
          await bFormValid(lblStatusTag,lblStatusTag);
          if (!isFormValid) {
            setLoading(false);
            return;
          }
        } else {
          await bFormValid_HMC(lblStatusTag);
          if (!isFormValid) {
            setLoading(false);
            return;
          }
        }

        if (isEnabled(thisObj, 'btnCalculate') == true) {
          //need to implement cmdCalculate = True
          disable(thisObj, 'btnCalculate')
        }

        sStatusID = lblStatusTag
        sStatusDesc = getValue(thisObj, 'txtStatus')

        if (lblStatusTag == "") {
          setLblStatusTag("P")
          sStatusID='P'
          setValue(thisObj, 'txtStatus', 'Pending')
        }
      } else {
        await bFormValid(lblStatusTag);
        if (!isFormValid) {
          setLoading(false);
          return;
        }

        if (isEnabled(thisObj, 'btnCalculate') == true) {
          //need to implement cmdCalculate = True
          disable(thisObj, 'btnCalculate')
        }

        if (lblStatusTag == "") {
          setLblStatusTag("P")
          sStatusID='P'
          setValue(thisObj, 'txtStatus', 'Pending')
        }
      }

      if (lblStatusTag !== 'X') {
        if (await bSaveInspection(lblStatusTag)) { 
          await bFillChgDateTime();
          showMessage(thisObj, 'Changes made to this inspection have been successfully saved!!!', true);
        } else {
          setLblStatusTag(sStatusID);
          setValue(thisObj, 'txtStatus', sStatusDesc);
        }
      }

      const vendorList = getData(thisObj, 'vendorList')
      EnableDisableControls(vendorList, sStatusID, lblElecUploadInd, lblLoadType1);
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during button save click"
        );
      }
      return false;
    }
  }

  thisObj.onbtnSaveClick = onbtnSaveClick

  const onbtnAddClick = () => {
    try {
      let frmScaleTickets = {}
      let farmDetaIL=getValue(thisObj, 'ddFarm')
      let farM_ID=farmDetaIL.split('-')[0]
      if (yesNo == true) {
        frmScaleTickets.lblHMC_IND = "Y"
        frmScaleTickets.lblTicketUploadMod = lblTicketUploadMod
        frmScaleTickets.lblElecUploadInd = lblElecUploadInd
        frmScaleTickets.inspNum = getValue(thisObj, 'txtSC95')
      }
      frmScaleTickets.highmoisture = yesNo
      frmScaleTickets.lblBuyingPoint = getValue(thisObj, 'ddLocation')
      frmScaleTickets.lblState = getValue(thisObj, 'ddState')
      frmScaleTickets.lblCounty = getValue(thisObj, 'ddCounty')
      frmScaleTickets.lblFarm = farM_ID
      frmScaleTickets.cmdOk_Caption = "Add"
      setData(thisObj, 'frmScaleTickets', frmScaleTickets)
      goTo(thisObj, "Settlements#ScaleTickets#DEFAULT#true#Click");
      setLblLoadType1(true)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during button Add Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  const onbtnEditClick = () => {
    let arrTicketInfo = thisObj.state.gridWeightTickets.selected[0];
    let frmFV95Array = getData(thisObj, 'frmFV95Array')
    if (arrTicketInfo != undefined && arrTicketInfo != "") {
      let data = [];
      data.push(arrTicketInfo.txtConcatedData);
      let frmScaleTicketsEdit = {}
      if (yesNo == true) {
        frmScaleTicketsEdit.lblHMC_IND = "Y";

      }
      frmScaleTicketsEdit.highmoisture = yesNo;
      frmScaleTicketsEdit.cmdOk_Caption = "Update";
      frmScaleTicketsEdit.arrTicketInfo = data;
      frmScaleTicketsEdit.lblElecUploadInd = lblElecUploadInd
      frmScaleTicketsEdit.lblBuyingPoint = getValue(thisObj, 'ddLocation')
      frmScaleTicketsEdit.frmFV95Array = frmFV95Array;
      setData(thisObj, 'frmScaleTickets', frmScaleTicketsEdit)
      goTo(thisObj, "Settlements#ScaleTickets#DEFAULT#true#Click")
    }
    else {
      showMessage(thisObj, 'Select Weight Ticket to Edit');
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  const onbtnDeleteClick = () => {
    let gridData = getValue(thisObj, 'gridWeightTickets');
    const selectedData = thisObj.state.gridWeightTickets.selected[0];
    if (selectedData != undefined) {
      if (gridData.length > 0) {
        let res = confirm(`Are you sure you wish to delete this ticket '${selectedData.txtcolWeightTicket}'?`)
        if (res == false) {
          return;
        }
        setlblTicketUploadMod(`${lblTicketUploadMod} ${selectedData.txtcolWeightTicket},D,`)
        gridData.splice(selectedData.rowID$, 1);
        CalculateTotals(gridData)
      }
    }
    else {
      showMessage(thisObj, 'Select Weight Ticket to Delete');
    }
    setLblLoadType1(true)
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  const CalculateValues2 = async (areaid, pnutTypeId, weightincludingvehicle, VehicleWeight, MoisturePct, CrBrPct,
    DcolShelPct, FancyPct, ForeignMaterialPct, LSKPct, SMK, SSPct, OKPct, ELKPct, DamagePct, Vicam) => {
    try {
      let response = await SettlementService.RetrieveValueCalculationList(areaid, pnutTypeId, Number(CrBrPct),
        Number(DcolShelPct), Number(FancyPct), Number(DamagePct), Number(SSPct), Number(Vicam), Number(ForeignMaterialPct), Number(SMK), Number(SSPct), Number(OKPct), Number(ELKPct), Number(LSKPct), Number(MoisturePct),
        Number(weightincludingvehicle), Number(VehicleWeight), Number(ForeignMaterialPct))

      let data = response
      if(data[0].weight_including_vehicle!=0){
      // setValue(thisObj, 'txtWtIncludingVehicle', data[0].weight_including_vehicle.toString())
      // setValue(thisObj, 'txtWtOfVehicle', data[0].weight_of_vehicle.toString())
      // setValue(thisObj, 'txtGrossWeight', data[0].gross_weight.toString())
      // setValue(thisObj, 'txtForeignMaterial', data[0].foreign_material_weight.toString())
      // setValue(thisObj, 'txtWtlessFM', data[0].weight_less_fm.toString())
      // setValue(thisObj, 'txtExcessMoisture', data[0].excess_moisture_weight.toString())
      // setValue(thisObj, 'txtNetWeight', data[0].net_weight.toString())
      // setValue(thisObj, 'txtLSKvc', data[0].lsk_weight.toString())
      // setValue(thisObj, 'txtNetWtExclLSK', data[0].net_weight_excluding_lsk.toString())
      // setValue(thisObj, 'txtKernelValueTon', data[0].kernel_value_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtKernelValueLb', data[0].kernel_value_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtELKPremiumTon', data[0].elk_premium_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtELKPremiumLb', data[0].elk_premium_cents.toFixed(5).toString())
      // setValue(thisObj, 'txtTotalTon', data[0].total_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtTotalLb', data[0].total_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtDamageTon', data[0].damaged_discount_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtDamageLbs', data[0].damaged_discount_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtAccessFMTon', data[0].excess_fm_discount_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtAccessFMLb', data[0].excess_fm_discount_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtExcessSplitsTon', data[0].excess_splits_discount_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtExcessSplitsLb', data[0].excess_splits_discount_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtAFlavusTon', data[0].flavus_discount_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtAFlavusLb', data[0].flavus_discount_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtTotalDiscountsTon', data[0].total_discounts_dollars_per_ton.toFixed(2).toString())
      // setValue(thisObj, 'txtlblTotalDiscountsLb', data[0].total_discounts_cents_per_lbs.toFixed(5).toString())
      // setValue(thisObj, 'txtNetValPerTonExclSLK', data[0].net_value_per_ton_excluding_lsk.toFixed(2).toString())
      // setValue(thisObj, 'txtValPerPoundExclSLK', data[0].value_per_pound_excluding_lsk.toFixed(5).toString())
      //setValue(thisObj, 'txtOI', data[0].value_times_weight.toString())
      setLabel(thisObj, 'txtH', `H x ${data[0].lsk_per_pounds.toString()}:`)
      //setValue(thisObj, 'txtH', data[0].lsk_time_lsk_per_pounds.toString())
      // setValue(thisObj, 'txtTotalLoadVal', data[0].total_value_of_load.toString())
      // setValue(thisObj, 'txtG', data[0].value_per_pound_including_lsk.toString())
      setData(thisObj, 'tkc_pct_FV95', Number(data[0].total_kernel_content) == NaN ? '0.00' : Number(data[0].total_kernel_content).toFixed(2))
      disable(thisObj, 'btnCalculate')

      let objVal = {}
      objVal['txtWtIncludingVehicle'] = data[0].weight_including_vehicle.toString();
      objVal['txtWtOfVehicle'] = data[0].weight_of_vehicle.toString();
      objVal['txtGrossWeight'] = data[0].gross_weight.toString();
      objVal['txtForeignMaterial'] = data[0].foreign_material_weight.toString();
      objVal['txtWtlessFM'] = data[0].weight_less_fm.toString();
      objVal['txtExcessMoisture'] = data[0].excess_moisture_weight.toString();
      objVal['txtNetWeight'] = data[0].net_weight.toString();
      objVal['txtLSKvc'] = data[0].lsk_weight.toString();
      objVal['txtNetWtExclLSK'] = data[0].net_weight_excluding_lsk.toString();
      objVal['txtKernelValueTon'] = data[0].kernel_value_dollars_per_ton.toFixed(2).toString();
      objVal['txtKernelValueLb'] = data[0].kernel_value_cents_per_lbs.toFixed(5).toString();
      objVal['txtELKPremiumTon'] = data[0].elk_premium_dollars_per_ton.toFixed(2).toString();
      objVal['txtELKPremiumLb'] = data[0].elk_premium_cents.toFixed(5).toString();
      objVal['txtTotalTon'] = data[0].total_dollars_per_ton.toFixed(2).toString();
      objVal['txtTotalLb'] = data[0].total_cents_per_lbs.toFixed(5).toString();
      objVal['txtDamageTon'] = data[0].damaged_discount_dollars_per_ton.toFixed(2).toString();
      objVal['txtDamageLbs'] = data[0].damaged_discount_cents_per_lbs.toFixed(5).toString();
      objVal['txtAccessFMTon'] = data[0].excess_fm_discount_dollars_per_ton.toFixed(2).toString();
      objVal['txtAccessFMLb'] = data[0].excess_fm_discount_cents_per_lbs.toFixed(5).toString();
      objVal['txtExcessSplitsTon'] = data[0].excess_splits_discount_dollars_per_ton.toFixed(2).toString();
      objVal['txtExcessSplitsLb'] = data[0].excess_splits_discount_cents_per_lbs.toFixed(5).toString();
      objVal['txtAFlavusTon'] = data[0].flavus_discount_dollars_per_ton.toFixed(2).toString();
      objVal['txtAFlavusLb'] = data[0].flavus_discount_cents_per_lbs.toFixed(5).toString();
      objVal['txtTotalDiscountsTon'] = data[0].total_discounts_dollars_per_ton.toFixed(2).toString()
      objVal['txtlblTotalDiscountsLb'] = data[0].total_discounts_cents_per_lbs.toFixed(5).toString()
      objVal['txtNetValPerTonExclSLK'] = data[0].net_value_per_ton_excluding_lsk.toFixed(2).toString();
      objVal['txtValPerPoundExclSLK'] = data[0].value_per_pound_excluding_lsk.toFixed(5).toString();
      objVal['txtOI'] = data[0].value_times_weight.toString();
      objVal['txtH'] = data[0].lsk_time_lsk_per_pounds.toString();
      objVal['txtTotalLoadVal'] = data[0].total_value_of_load.toString();
      objVal['txtG'] = data[0].value_per_pound_including_lsk.toString();

      setFieldValues(thisObj, objVal, true);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during CalculateValues2 function"
        );
      }
      return false;
    }
  }

  const onbtnCreate1007Click = async () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddLocation')
      let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1050', null, 'U', buy_point_id)
      if (response[0].permission == "N") {
        showMessage(thisObj, 'Invalid Security Permissions!!!')
        return false
      }

      if (yesNo == true) {
        if (bCheckHMC() == false) return
      }

      await bSaveInspection(lblStatusTag)
      bFillChgDateTime()
      setbSaveForm(false)
      let data = {}
      data["FV95_NUM"] = getValue(thisObj, 'txtSC95'),
        data["FV1007_NUM"] = getValue(thisObj, 'txt1007'),
        data["BuyPtId"] = getValue(thisObj, 'ddLocation'),
        data["lblfreefv95"] = Lblfreefv95,
        data["cboCMARemit"] = getValue(thisObj, 'ddRemitTo')
      setData(thisObj, '1007SettlementScreenData', data);
      goTo(thisObj, 'Settlements#1007Settlement#DEFAULT#true#Click')


    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during button create 1007 click"
        );
      }
      return false;
      return false
    }
    return true;
  }
  thisObj.onbtnCreate1007Click = onbtnCreate1007Click


  const ontxtConcealedRMDGrChange = () => {
    let txtEnb = isEnabled(thisObj, 'txtConcealedRMDGr');
    if (txtEnb) {
      setLblLoadType1(true);
      let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      let ConcealedRMDGr = getValue(thisObj, 'txtConcealedRMDGr');
      if (isNumeric(CleanSampleWt) && isNumeric(ConcealedRMDGr)) {
        if (CleanSampleWt != 0) {
          let calculated = FormatNumber((ConcealedRMDGr / CleanSampleWt) * 100);
          setValue(thisObj, 'txtConcealedRMDPct', calculated);
        }
        else {
          setValue(thisObj, 'txtConcealedRMDPct', 0);
        }
      }
      else {
        setValue(thisObj, 'txtConcealedRMDPct', '');
      }
    }
  }
  thisObj.ontxtConcealedRMDGrChange = ontxtConcealedRMDGrChange;

  const ontxtCleanSampleWtChange = () => {
    let data = getValue(thisObj, 'gridWeightTickets');
    if (data.length > 0 && isNumeric(getValue(thisObj, 'txtSMKRSPct')) && isNumeric(getValue(thisObj, 'txtSoundSplitsPct')) && isNumeric(getValue(thisObj, 'txtOtherKernelsPct')) && isNumeric(getValue(thisObj, 'txtTotalDamagePct')) && isNumeric(getValue(thisObj, 'txtHullsPct')) && isNumeric(getValue(thisObj, 'txtFMSampleWt')) && isNumeric(getValue(thisObj, 'txtCleanSampleWt'))) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate');
    }

    let CleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
    let SMKRSGr = getValue(thisObj, 'txtSMKRSGr');
    let SoundSplitsGr = getValue(thisObj, 'txtSoundSplitsGr');
    let OtherKernelsGr = getValue(thisObj, 'txtOtherKernelsGr');
    let TotalDamageGr = getValue(thisObj, 'txtTotalDamageGr');
    let HullsGr = getValue(thisObj, 'txtHullsGr');
    let ConcealedRMDGr = getValue(thisObj, 'txtConcealedRMDGr');
    let ELKGr = getValue(thisObj, 'txtELKGr');
    let FreezeDamageGr = getValue(thisObj, 'txtFreezeDamageGr');
    let HullBrightness = getValue(thisObj, 'txtHullBrightness');
    let JumboGr = getValue(thisObj, 'txtJumboGr');
    let FancyGr = getValue(thisObj, 'txtFancyGr');
    let SMKRSPct = 0;
    let SoundSplitsPct = 0;
    let OtherKernalPct = 0;
    let TotalDamagePct = 0;
    let HullsPct = 0;

    if (isNumeric(CleanSampleWt) && isNumeric(SMKRSGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((SMKRSGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtSMKRSPct', Number(calculated).toFixed());
        SMKRSPct = Number(calculated).toFixed();
      }
      else {
        setValue(thisObj, 'txtSMKRSPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtSMKRSPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(SoundSplitsGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((SoundSplitsGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtSoundSplitsPct', Number(calculated).toFixed());
        SoundSplitsPct = Number(calculated).toFixed();
      }
      else {
        setValue(thisObj, 'txtSoundSplitsPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtSoundSplitsPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(OtherKernelsGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((OtherKernelsGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtOtherKernelsPct', Number(calculated).toFixed());
        OtherKernalPct = Number(calculated).toFixed();
      }
      else {
        setValue(thisObj, 'txtOtherKernelsPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtOtherKernelsPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(TotalDamageGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((TotalDamageGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtTotalDamagePct', Number(calculated).toFixed());
        TotalDamagePct = Number(calculated).toFixed();
      }
      else {
        setValue(thisObj, 'txtTotalDamagePct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtTotalDamagePct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(HullsGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((HullsGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtHullsPct', Number(calculated).toFixed());
        HullsPct = Number(calculated).toFixed();
      }
      else {
        setValue(thisObj, 'txtHullsPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtHullsPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(ConcealedRMDGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((ConcealedRMDGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtConcealedRMDPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtConcealedRMDPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtConcealedRMDPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(ELKGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((ELKGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtELKPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtELKPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtELKPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(FreezeDamageGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((FreezeDamageGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtFreezeDamagePct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtFreezeDamagePct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtFreezeDamagePct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(HullBrightness)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((HullBrightness / CleanSampleWt) * 100);
        setValue(thisObj, 'txtHullBrightnessPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtHullBrightnessPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtHullBrightnessPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(JumboGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((JumboGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtJumboPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtJumboPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtJumboPct', '');
    }

    if (isNumeric(CleanSampleWt) && isNumeric(FancyGr)) {
      if (CleanSampleWt != 0) {
        let calculated = FormatNumber((FancyGr / CleanSampleWt) * 100);
        setValue(thisObj, 'txtFancyPct', Number(calculated).toFixed());
      }
      else {
        setValue(thisObj, 'txtFancyPct', 0);
      }
    }
    else {
      setValue(thisObj, 'txtFancyPct', '');
    }

    let TotalSMK = FormatNumber(Number(SMKRSPct) + Number(SoundSplitsPct));
    setValue(thisObj, 'txtTotalSMK', TotalSMK);
    setTotalKernals(Number(SMKRSPct), Number(SoundSplitsPct), Number(OtherKernalPct), Number(TotalDamagePct));
    setTotalKernalsHulls(Number(SMKRSPct), Number(SoundSplitsPct), Number(OtherKernalPct), Number(TotalDamagePct), Number(HullsPct));
  }
  thisObj.ontxtCleanSampleWtChange = ontxtCleanSampleWtChange;

  // #region bFormValid
  const bFormValid = async (strFV95Status, txtStatus) => {
    let strHMC = 'N';
    setStrHMC('N')
    let bPendingVoid = false;
    let bFullDataCheck = false;
    let ddShare = 0;
    let intCMAParticipantCount = 0;
    let meetingSpec = false;
    let setseedmeetingspecasno = false;

    let txtMoisture = getValue(thisObj, 'txtMoisture');
    let radioHighMoisture = getValue(thisObj, "radioHighMoisture");
    if (txtMoisture !== undefined && txtMoisture !== null && txtMoisture !== '') {
      if (txtMoisture > 10.49 && radioHighMoisture == '1') {
        strHMC = 'Y';
        setStrHMC('Y')
      }

      if (strHMC == "Y" && radioHighMoisture !== '1') {
        showMessage(thisObj, "Please mark this SC95 as High Moisture or correct Moisture percent!!!!", false);
        isFormValid = false;
        return;
      }
    }

    let lblStatus = txtStatus;
    if (lblStatus == undefined || lblStatus == null) {
      lblStatus = '';
    }
    if (lblStatus == "X" || strFV95Status == "X") {
      bPendingVoid = true;
    } else {
      bPendingVoid = false;
    }

    if (lblStatus == "" || lblStatus == "P") {
      bFullDataCheck = false;
    } else {
      bFullDataCheck = true;
    }

    isFormValid = false;

    let txtFV95 = getValue(thisObj, 'txtSC95');
    if (txtFV95 == undefined || txtFV95 == null) {
      txtFV95 = '';
    }
    if (txtFV95 == "") {
      showMessage(thisObj, "SC95 Number is required!!!", false);
      isFormValid = false;
      return;
    }

    if (txtFV95.length !== 7) {
      showMessage(thisObj, "SC95 Number must be 7 characters long!!!", false);
      isFormValid = false;
      return;
    }

    if (lblElecUploadInd == 'N') {
      // check_1007OneDoc This API need to implement.
      let buyingPoint = getValue(thisObj, 'ddLocation');
      let documentDetails = await SettlementService.RetrieveCheck1007OneDocumentDetails(buyingPoint);
      if (documentDetails[0].onedoc_ind == 'Y') {
        if (isNumeric(txtFV95) == false) {
          showMessage(thisObj, 'SC95 Number must be all numeric for locations using One Documents!!!', false);
          isFormValid = false;
          return;
        }
      } else {
        let numeric = true;
        for (let i = 1; i < txtFV95.length; i++) {
          if (isNumeric(txtFV95[i]) == false) {
            numeric = false;
          }
        }
        if ((txtFV95.charCodeAt(0) >= 65 && txtFV95.charCodeAt(0) <= 90 && numeric) == false) {
          showMessage(thisObj, 'SC95 Number must be in Format A###### for this location!!!', false);
          isFormValid = false;
          return;
        }
      }
    }

    let txtDateTime = ConvertToDate(getValue(thisObj, 'txtInspectionDateTime'));
    if (txtDateTime !== undefined && txtDateTime !== null && txtDateTime !== '') {
      if (!isValidDate(txtDateTime)) {
        showMessage(thisObj, "Invalid Date!!!", false);
        isFormValid = false;
        return;
      } else {
        if (new Date() < new Date(txtDateTime)) {
          if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT') {
            // pps_defaults API Wil get call
            let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
            if (ppsDefault !== undefined && ppsDefault.length > 0) {
              let compId = sessionStorage.getItem('compId');
              let key = `${compId}` + '_default_overrides';
              if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
                let value = Object.getOwnPropertyDescriptor(ppsDefault[0].bypass_curr_date_edit, key).value;
                if (value !== true) {
                  showMessage(thisObj, "Date cannot be in future!!!", false);
                  isFormValid = false;
                  return;
                }
              }
            }
          }
        }
        let cropYear = JSON.parse(sessionStorage.getItem('year'));
        let date = "06/01/" + (cropYear);
        if (new Date(txtDateTime) < new Date(date)) {
          showMessage(thisObj, "Date cannot be less than June 1 of current crop year!!!", false);
          isFormValid = false;
          return;
        }
      }
    }
    else {
      showMessage(thisObj, "Invalid Date!!!", false);
      isFormValid = false;
      return;
    }

    let cboFarm = getValue(thisObj, 'ddFarm');
    if (cboFarm == undefined || cboFarm == null || cboFarm == '') {
      showMessage(thisObj, "Please Select a Farm!!!", false);
      isFormValid = false;
      return;
    }

    let cboLandType = getValue(thisObj, 'ddIrrigatedDryland');
    let iscLandTypeVisible = isVisible(thisObj, 'ddIrrigatedDryland'); // Should be come from property.
    if (bPendingVoid == false && iscLandTypeVisible == true) {
      if (cboLandType !== "I" && cboLandType !== "D") {
        showMessage(thisObj, "Irrigated/Dryland must be either Irrigated or Dryland!!!", false);
        isFormValid = false;
        return;
      }
    }

    let cboPeanutType = getValue(thisObj, 'ddPeanutType');
    if (cboPeanutType == undefined || cboPeanutType == null || cboPeanutType == '') {
      showMessage(thisObj, "Please Select a Peanut Type!!!", false);
      isFormValid = false;
      return;
    }

    if (bPendingVoid == false) {
      let cboPeanutVariety = getValue(thisObj, 'ddPeanutVariety');
      if (cboPeanutVariety == undefined || cboPeanutVariety == null || cboPeanutVariety == '') {
        showMessage(thisObj, "Please Select a Peanut Variety!!!", false);
        isFormValid = false;
        return;
      }
    }

    let cboSeg = getValue(thisObj, 'ddSeg');
    if (cboSeg == undefined || cboSeg == null || cboSeg == '') {
      showMessage(thisObj, "Please Select a Segment!!!", false);
      isFormValid = false;
      return;
    }

    // txtReading Enable/Disable Logic
    if (isEnabled(thisObj, 'txtReading')) {
      let txtReading = getValue(thisObj, 'txtReading');
      if (txtReading !== undefined && txtReading !== '') {
        if (isNumeric(txtReading)) {
          if (txtReading < 0 || txtReading > 9999.9) {
            showMessage(thisObj, "Vicam must be of format ######!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Vicam must be of format ######!!!", false);
          isFormValid = false;
          return;
        }
      }
    }

    // txtFV95 Enable/Disable Logic
    if (isEnabled(thisObj, 'txtSC95')) {
      let inspectHeaderList = await SettlementService.RetrieveInspectionHeaderList(txtFV95, null, null);
      if (inspectHeaderList.length > 0 && inspectHeaderList[0].insp_num !== '') {
        showMessage(thisObj, "Inspection number " + txtFV95 + ' has already been created!!!', false);
        isFormValid = false;
        return;
      }
    }

    if (!bPendingVoid) {
      // Remitto Dropdown
      let ddRemitTo0 = getValue(thisObj, "ddRemitTo0") == undefined ? '' : getValue(thisObj, "ddRemitTo0");
      let ddRemitTo1 = getValue(thisObj, "ddRemitTo1") == undefined ? '' : getValue(thisObj, "ddRemitTo1");
      let ddRemitTo2 = getValue(thisObj, "ddRemitTo2") == undefined ? '' : getValue(thisObj, "ddRemitTo2");
      let ddRemitTo3 = getValue(thisObj, "ddRemitTo3") == undefined ? '' : getValue(thisObj, "ddRemitTo3");
      if (ddRemitTo0 == '' && ddRemitTo1 == '' && ddRemitTo2 == '' && ddRemitTo3) {
        showMessage(thisObj, "At least one valid vendor is required!!", false);
        isFormValid = false;
        return;
      }
      // Vendor Split changes
      for (let i = 1; i <= 20; i++) {
        let vendor = getValue(thisObj, "ddVendor" + (i - 1)) == undefined ? '' : getValue(thisObj, "ddVendor" + (i - 1));
        let remitTo = getValue(thisObj, "ddRemitTo" + (i - 1)) == undefined ? '' : getValue(thisObj, "ddRemitTo" + (i - 1));
        let sharePercent = getValue(thisObj, "txtSharePct" + (i - 1)) == undefined ? 0 : getValue(thisObj, "txtSharePct" + (i - 1));

        if (vendor.length !== 0 && vendor[0] !== '' && remitTo == '') {
          showMessage(thisObj, "Vendor" + (i - 1) + " is not valid!!!", false);
          isFormValid = false;
          return;
        }

        if (sharePercent !== 0 && sharePercent !== '' && sharePercent !== null && remitTo == '') {
          showMessage(thisObj, "Vendor share" + (i - 1) + " is not valid without a vendor!!!", false);
          isFormValid = false;
          return;
        }

        if (vendor.length > 0 && vendor[0] !== "") {
          if (!isNumeric(sharePercent)) {
            showMessage(thisObj, "Vendor share must be of format ###.##!!!", false);
            isFormValid = false;
            return;
          }

          if (Number(sharePercent) < 0) {
            showMessage(thisObj, "Vendor share must be greater than 0!!!", false);
            isFormValid = false;
            return;
          } else {
            if (Number(sharePercent) > 100) {
              showMessage(thisObj, "Vendor share must be less than 100!!!", false);
              isFormValid = false;
              return;
            }
          }
        }
      }
      ddShare = 0;
      intCMAParticipantCount = 0
      // total number of share % validation
      let txtCMAVendor = getValue(thisObj, 'txtCMAVendor') == undefined ? '' : getValue(thisObj, 'txtCMAVendor');
      for (let i = 1; i <= 20; i++) {
        let sharePercent = getValue(thisObj, "txtSharePct" + (i - 1)) == undefined ? 0 : getValue(thisObj, "txtSharePct" + (i - 1));
        let chkboxCMA = getValue(thisObj, "chkboxCMA" + (i - 1)) == true ? true : false;
        if (sharePercent > 0) {
          ddShare = ddShare + Number(sharePercent);
          if (txtCMAVendor !== '' && chkboxCMA == true) {
            intCMAParticipantCount = intCMAParticipantCount + 1;
          }
        }
      }

      if (Number(ddShare) !== 100) {
        showMessage(thisObj, "Total of vendor share " + Number(ddShare) + " does not equal 100!!!", false);
        isFormValid = false;
        return;
      }


      if (txtCMAVendor !== undefined && txtCMAVendor !== "" && intCMAParticipantCount == 0) {
        showMessage(thisObj, "No Producers are marked as participating in the CMA Contract!", false);
        isFormValid = false;
        return;
      }

      let cboWhouseBin = getValue(thisObj, 'ddWhouseBin');
      if (cboWhouseBin == undefined || cboWhouseBin == null || cboWhouseBin == '') {
        showMessage(thisObj, "Please Select an Unload Warehouse/Bin #!!!", false);
        isFormValid = false;
        return;
      }

      let cboOblBuyingPoint = getValue(thisObj, 'ddBuyingPt');
      if (cboOblBuyingPoint == undefined || cboWhouseBin == null || cboWhouseBin == '') {
        showMessage(thisObj, "Please Select an Obligation Buying Point!!!", false);
        isFormValid = false;
        return;
      }

      let ddObligationPt = getValue(thisObj, 'ddObligationPt');
      if (ddObligationPt == undefined || ddObligationPt == null || ddObligationPt == '') {
        showMessage(thisObj, "Please Select an Obligation Warehouse/Bin #!!!", false);
        isFormValid = false;
        return;
      }

      // txtFMSampleWt
      let txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt');
      if (txtFMSampleWt !== undefined && txtFMSampleWt !== '' && txtFMSampleWt !== null) {
        if (isNumeric(txtFMSampleWt)) {
          if (txtFMSampleWt < 0 || txtFMSampleWt > 9999.9) {
            showMessage(thisObj, "FM Sample Weight must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "FM Sample Weight must be of format ####.#!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtClnSampleWt
      let txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
      if (txtCleanSampleWt !== undefined && txtCleanSampleWt !== '' && txtCleanSampleWt !== null) {
        txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
        if (isNumeric(txtCleanSampleWt)) {
          if (txtCleanSampleWt < 0 || txtCleanSampleWt > 9999.9) {
            showMessage(thisObj, "Clean Sample Weight must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Clean Sample Weight must be of format ####.#!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtVLClnSampleWt
      let txtVLClnSampleWt = getValue(thisObj, 'txtValenciaCleanSampleWt');
      if (txtVLClnSampleWt !== undefined && txtVLClnSampleWt !== '' && txtVLClnSampleWt !== null) {
        if (isNumeric(txtVLClnSampleWt)) {
          if (txtVLClnSampleWt < 0 || txtVLClnSampleWt > 9999.9) {
            showMessage(thisObj, "Valencia Clean Sample Weight must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Valencia Clean Sample Weight must be of format ####.#!!!", false);
          isFormValid = false;
          return;
        }
      }

      // Location Gram Entry Ind = Y
      let locationData = getData(thisObj, 'locationData');
      let locationId = getValue(thisObj, 'ddLocation');
      let gramEntryInd = locationData.find(x => x.buy_pt_id == locationId).gram_entry_ind;
      if (gramEntryInd == 'Y') {
        // txtSMKRSGr
        let txtSMKRSGr = getValue(thisObj, 'txtSMKRSGr');
        if (txtSMKRSGr !== undefined && txtSMKRSGr !== '' && txtSMKRSGr !== null) {
          if (isNumeric(txtSMKRSGr)) {
            if (txtSMKRSGr < 0 || txtSMKRSGr > 9999.9) {
              showMessage(thisObj, "SMK(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "SMK(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtSoundSplitsGr
        let txtSoundSplitsGr = getValue(thisObj, 'txtSoundSplitsGr');
        if (txtSoundSplitsGr !== undefined && txtSoundSplitsGr !== '' && txtSoundSplitsGr !== null) {
          if (isNumeric(txtSoundSplitsGr)) {
            if (txtSoundSplitsGr < 0 || txtSoundSplitsGr > 9999.9) {
              showMessage(thisObj, "Sound Splits(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Sound Splits(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtOtherKernalsGr
        let txtOtherKernalsGr = getValue(thisObj, 'txtOtherKernelsGr');
        if (txtOtherKernalsGr !== undefined && txtOtherKernalsGr !== '' && txtOtherKernalsGr !== null) {
          if (isNumeric(txtOtherKernalsGr)) {
            if (txtOtherKernalsGr < 0 || txtOtherKernalsGr > 9999.9) {
              showMessage(thisObj, "Other Kernals(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Other Kernals(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtTotalDamageGr
        let txtTotalDamageGr = getValue(thisObj, 'txtTotalDamageGr');
        if (txtTotalDamageGr !== undefined && txtTotalDamageGr !== '' && txtTotalDamageGr !== null) {
          if (isNumeric(txtTotalDamageGr)) {
            if (txtTotalDamageGr < 0 || txtTotalDamageGr > 9999.9) {
              showMessage(thisObj, "Damage(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Damage(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtHullsGr
        let txtHullsGr = getValue(thisObj, 'txtHullsGr');
        if (txtHullsGr !== undefined && txtHullsGr !== '' && txtHullsGr !== null) {
          if (isNumeric(txtHullsGr)) {
            if (txtHullsGr < 0 || txtHullsGr > 9999.9) {
              showMessage(thisObj, "Hulls(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Hulls(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtFreezeDamageGr
        let txtFreezeDamageGr = getValue(thisObj, 'txtFreezeDamageGr');
        if (txtFreezeDamageGr !== undefined && txtFreezeDamageGr !== '' && txtFreezeDamageGr !== null) {
          if (isNumeric(txtFreezeDamageGr)) {
            if (txtFreezeDamageGr < 0 || txtFreezeDamageGr > 9999.9) {
              showMessage(thisObj, "Freeze Damage(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Freeze Damage(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtConcealedRMDGr
        let txtConcealedRMDGr = getValue(thisObj, 'txtConcealedRMDGr');
        if (txtConcealedRMDGr !== undefined && txtConcealedRMDGr !== '' && txtConcealedRMDGr !== null) {
          if (isNumeric((txtConcealedRMDGr))) {
            if (txtConcealedRMDGr < 0 || txtConcealedRMDGr > 9999.9) {
              showMessage(thisObj, "Concealed RMD(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Concealed RMD(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtCrkOrBrkShellsGr
        let txtCrkOrBrkShellsGr = getValue(thisObj, 'txtCrkOrBrkShellsGr');
        if (txtCrkOrBrkShellsGr !== undefined && txtCrkOrBrkShellsGr !== '' && txtCrkOrBrkShellsGr !== null) {
          if (isNumeric((txtCrkOrBrkShellsGr))) {
            if (txtCrkOrBrkShellsGr < 0 || txtCrkOrBrkShellsGr > 9999.9) {
              showMessage(thisObj, "Crk or Brk Shells(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Crk or Brk Shells(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtDiscoloredShellsGr
        let txtDiscoloredShellsGr = getValue(thisObj, 'txtDiscoloredShellsGr');
        if (txtDiscoloredShellsGr !== undefined && txtDiscoloredShellsGr !== '' && txtDiscoloredShellsGr !== null) {
          if (isNumeric((txtDiscoloredShellsGr))) {
            if (txtDiscoloredShellsGr < 0 || txtDiscoloredShellsGr > 9999.9) {
              showMessage(thisObj, "Discolored Shells(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Discolored Shells(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtHullBrightness
        let txtHullBrightness = getValue(thisObj, 'txtHullBrightness');
        if (txtHullBrightness !== undefined && txtHullBrightness !== '' && txtHullBrightness !== null) {
          if (isNumeric((txtHullBrightness))) {
            if (txtHullBrightness < 0 || txtHullBrightness > 9999.9) {
              showMessage(thisObj, "Hull Brightness(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Hull Brightness(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtJumboGr
        let txtJumboGr = getValue(thisObj, 'txtJumboGr');
        if (txtJumboGr !== undefined && txtJumboGr !== '' && txtJumboGr !== null) {
          if (isNumeric((txtJumboGr))) {
            if (txtJumboGr < 0 || txtJumboGr > 9999.9) {
              showMessage(thisObj, "Jumbo(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Jumbo(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtELKGr
        let txtELKGr = getValue(thisObj, 'txtELKGr');
        if (txtELKGr !== undefined && txtELKGr !== '' && txtELKGr !== null) {
          if (isNumeric((txtELKGr))) {
            if (txtELKGr < 0 || txtELKGr > 9999.9) {
              showMessage(thisObj, "ELK(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "ELK(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtForeignMaterialGr
        let txtForeignMaterialGr = getValue(thisObj, 'txtForeignMaterialGr');
        if (txtForeignMaterialGr !== undefined && txtForeignMaterialGr !== '' && txtForeignMaterialGr !== null) {
          if (isNumeric((txtForeignMaterialGr))) {
            if (txtForeignMaterialGr < 0 || txtForeignMaterialGr > 9999.9) {
              showMessage(thisObj, "Foreign Material(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Foreign Material(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtLSKGr
        let txtLSKGr = getValue(thisObj, 'txtLSKGr');
        if (txtLSKGr !== undefined && txtLSKGr !== '' && txtLSKGr !== null) {
          if (isNumeric((txtLSKGr))) {
            if (txtLSKGr < 0 || txtLSKGr > 9999.9) {
              showMessage(thisObj, "LSK(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "LSK(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtFancyGr
        let txtFancyGr = getValue(thisObj, 'txtFancyGr');
        if (txtFancyGr !== undefined && txtFancyGr !== '' && txtFancyGr !== null) {
          if (isNumeric((txtFancyGr))) {
            if (txtFancyGr < 0 || txtFancyGr > 9999.9) {
              showMessage(thisObj, "Fancy(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Fancy(grams) must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }
      }

      // txtDamageSplits
      let txtDamageSplits = getValue(thisObj, 'txtDamageSplits');
      if (txtDamageSplits !== undefined && txtDamageSplits !== '' && txtDamageSplits !== null) {
        if (isNumeric((txtDamageSplits))) {
          if (txtDamageSplits < 0 || txtDamageSplits > 9999.9) {
            showMessage(thisObj, "Damage Splits must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Damage Splits must be of format ####.#!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtSMKRSPct
      let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
      if (txtSMKRSPct !== undefined && txtSMKRSPct !== '' && txtSMKRSPct !== null) {
        if (isNumeric((txtSMKRSPct))) {
          if (txtSMKRSPct < 0 || txtSMKRSPct > 100) {
            showMessage(thisObj, 'SMKRS(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "SMKRS(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtSMK1Pct
      // let txtSMK1Pct = getValue(thisObj, 'txtSMK1Pct');
      // if (txtSMK1Pct == undefined || txtSMK1Pct == '') {
      //   setValue(thisObj, 'txtSMK1Pct', 0);
      // }

      let txtSMK1Pct = getValue(thisObj, 'txtSMK1Pct');
      if (txtSMK1Pct !== "" && txtSMK1Pct !== undefined) {
        if (isNumeric((txtSMK1Pct))) {
          if (txtSMK1Pct < 0 || txtSMK1Pct > 100) {
            showMessage(thisObj, "SMK1(percent) must be of format ###.##!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "SMK1(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtSMK2Pct
      // let txtSMK2Pct = getValue(thisObj, 'txtSMK2Pct');
      // if (txtSMK2Pct == undefined || txtSMK2Pct == '') {
      //   setValue(thisObj, 'txtSMK2Pct', 0);
      // }

      let txtSMK2Pct = getValue(thisObj, 'txtSMK2Pct');
      if (txtSMK2Pct !== "" && txtSMK2Pct !== undefined) {
        if (isNumeric((txtSMK2Pct))) {
          if (txtSMK2Pct < 0 || txtSMK2Pct > 100) {
            showMessage(thisObj, "SMK2(percent) must be of format ###.##!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "SMK2(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtSMK3Pct
      // let txtSMK3Pct = getValue(thisObj, 'txtSMK3Pct');
      // if (txtSMK3Pct == undefined || txtSMK3Pct == '') {
      //   setValue(thisObj, 'txtSMK3Pct', 0);
      // }

      let txtSMK3Pct = getValue(thisObj, 'txtSMK3Pct');
      if (txtSMK3Pct !== "" && txtSMK3Pct !== undefined) {
        if (isNumeric((txtSMK3Pct))) {
          if (txtSMK3Pct < 0 || txtSMK3Pct > 100) {
            showMessage(thisObj, "SMK3(percent) must be of format ###.##!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "SMK3(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtSMK4Pct
      // let txtSMK4Pct = getValue(thisObj, 'txtSMK4Pct');
      // if (txtSMK4Pct == undefined || txtSMK4Pct == '') {
      //   setValue(thisObj, 'txtSMK4Pct', 0);
      // }

      let txtSMK4Pct = getValue(thisObj, 'txtSMK4Pct');
      if (txtSMK4Pct !== "" && txtSMK4Pct !== undefined) {
        if (isNumeric((txtSMK4Pct))) {
          if (txtSMK4Pct < 0 || txtSMK4Pct > 100) {
            showMessage(thisObj, "SMK4(percent) must be of format ###.##!!!", false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "SMK4(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      if ((txtSMK1Pct !== "" && txtSMK1Pct !== undefined) ||
        (txtSMK2Pct !== "" && txtSMK2Pct !== undefined) ||
        (txtSMK3Pct !== "" && txtSMK3Pct !== undefined) ||
        (txtSMK4Pct !== "" && txtSMK4Pct !== undefined)) {
        if ((Number(txtSMK1Pct) + Number(txtSMK2Pct) + Number(txtSMK3Pct) + Number(txtSMK4Pct)) !== Number(txtSMKRSPct)) {
          showMessage(thisObj, "SMK1 through SMK4 must total SMKRS!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtSoundSplitsPct
      let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
      if (txtSoundSplitsPct !== undefined && txtSoundSplitsPct !== '' && txtSoundSplitsPct !== null) {
        if (isNumeric((txtSoundSplitsPct))) {
          if (txtSoundSplitsPct < 0 || txtSoundSplitsPct > 100) {
            showMessage(thisObj, 'Sound Splits(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Sound Splits(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtOtherKernelsPct
      let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
      if (txtOtherKernelsPct !== undefined && txtOtherKernelsPct !== '' && txtOtherKernelsPct !== null) {
        if (isNumeric((txtOtherKernelsPct))) {
          if (txtOtherKernelsPct < 0 || txtOtherKernelsPct > 100) {
            showMessage(thisObj, 'Other Kernals(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Other Kernals(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtTotalDamagePct
      let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
      if (txtTotalDamagePct !== undefined && txtTotalDamagePct !== '' && txtTotalDamagePct !== null) {
        if (isNumeric((txtTotalDamagePct))) {
          if (txtTotalDamagePct < 0 || txtTotalDamagePct > 100) {
            showMessage(thisObj, 'Total Damage(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Total Damage(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtHullsPct
      let txtHullsPct = getValue(thisObj, 'txtHullsPct');
      if (txtHullsPct !== undefined && txtHullsPct !== '' && txtHullsPct !== null) {
        if (isNumeric((txtHullsPct))) {
          if (txtHullsPct < 0 || txtHullsPct > 100) {
            showMessage(thisObj, 'Hulls(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Hulls(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      if (isEnabled(thisObj, 'txtHullsGr')) {
        if (Number(txtCleanSampleWt) !== 0) {
          let txtHullsGr = getValue(thisObj, 'txtHullsGr');
          if (Number(Number(txtHullsGr) / Number(txtCleanSampleWt) * 100).toFixed() > Number(txtHullsPct) ||
            (Number(Number(txtHullsGr) / Number(txtCleanSampleWt) * 100).toFixed() < Number(txtHullsPct))) {
            showMessage(thisObj, "Hulls(percent) must be of format within 1 percent of value calculated from grams!!!", false);
            isFormValid = false;
            return;
          }
        }
      }

      // txtFreezeDamagePct
      let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
      if (txtFreezeDamagePct !== undefined && txtFreezeDamagePct !== '' && txtFreezeDamagePct !== null) {
        if (isNumeric((txtFreezeDamagePct))) {
          if (txtFreezeDamagePct < 0 || txtFreezeDamagePct > 100) {
            showMessage(thisObj, 'Freeze Damage(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Freeze Damage(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      if (isEnabled(thisObj, 'txtFreezeDamageGr')) {
        if (Number(txtCleanSampleWt) !== 0) {
          let txtFreezeDamageGr = getValue(thisObj, 'txtFreezeDamageGr');
          if (Number(Number(txtFreezeDamageGr) / Number(txtCleanSampleWt) * 100).toFixed(2) - 0.01 > Number(txtFreezeDamagePct) ||
            (Number(Number(txtFreezeDamageGr) / Number(txtCleanSampleWt) * 100).toFixed(2) + 0.01 < Number(txtFreezeDamagePct))) {
            showMessage(thisObj, "Freeze(percent) must be of format within .01 percent of value calculated from grams!!!", false);
            isFormValid = false;
            return;
          }
        }
      }

      // txtConcealedRMDPct
      let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
      if (txtConcealedRMDPct !== undefined && txtConcealedRMDPct !== '' && txtConcealedRMDPct !== null) {
        if (isNumeric((txtConcealedRMDPct))) {
          if (txtConcealedRMDPct < 0 || txtConcealedRMDPct > 100) {
            showMessage(thisObj, 'Concealed RMD(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Concealed RMD(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      if (isEnabled(thisObj, 'txtConcealedRMDGr')) {
        if (Number(txtCleanSampleWt) !== 0) {
          let txtConcealedRMDGr = getValue(thisObj, 'txtConcealedRMDGr');
          if (Number(Number(txtConcealedRMDGr) / Number(txtCleanSampleWt)).toFixed(2) - 0.01 > Number(txtConcealedRMDPct) ||
            (Number(Number(txtConcealedRMDGr) / Number(txtCleanSampleWt)).toFixed(2) + 0.01 < Number(txtConcealedRMDPct))) {
            showMessage(thisObj, "Concealed(percent) must be of format within .01 percent of value calculated from grams!!!", false);
            isFormValid = false;
            return;
          }
        }
      }

      // txtCrkOrBrkShellsPct
      let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
      if (txtCrkOrBrkShellsPct !== undefined && txtCrkOrBrkShellsPct !== '' && txtCrkOrBrkShellsPct !== null) {
        if (isNumeric((txtCrkOrBrkShellsPct))) {
          if (txtCrkOrBrkShellsPct < 0 || txtCrkOrBrkShellsPct > 100) {
            showMessage(thisObj, 'Crk or Brk Shells(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Crk or Brk Shells(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtDiscoloredShellsPct
      let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
      if (txtDiscoloredShellsPct !== undefined && txtDiscoloredShellsPct !== '' && txtDiscoloredShellsPct !== null) {
        if (isNumeric((txtDiscoloredShellsPct))) {
          if (txtDiscoloredShellsPct < 0 || txtDiscoloredShellsPct > 100) {
            showMessage(thisObj, 'Discolored Shells(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Discolored Shells(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtHullBrightnessPct
      let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
      if (txtHullBrightnessPct !== undefined && txtHullBrightnessPct !== '' && txtHullBrightnessPct !== null) {
        if (isNumeric((txtHullBrightnessPct))) {
          if (txtHullBrightnessPct < 0 || txtHullBrightnessPct > 100) {
            showMessage(thisObj, 'Hull Brightness(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Hull Brightness(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtJumboPct
      let txtJumboPct = getValue(thisObj, 'txtJumboPct');
      if (txtJumboPct !== undefined && txtJumboPct !== '' && txtJumboPct !== null) {
        if (isNumeric((txtJumboPct))) {
          if (txtJumboPct < 0 || txtJumboPct > 100) {
            showMessage(thisObj, 'Jumbo(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Jumbo(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtELKPct
      let txtELKPct = getValue(thisObj, 'txtELKPct');
      if (txtELKPct !== undefined && txtELKPct !== '' && txtELKPct !== null) {
        if (isNumeric((txtELKPct))) {
          if (txtELKPct < 0 || txtELKPct > 100) {
            showMessage(thisObj, 'ELK(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "ELK(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtForeignMaterialPct
      let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
      if (txtForeignMaterialPct !== undefined && txtForeignMaterialPct !== '' && txtForeignMaterialPct !== null) {
        if (isNumeric((txtForeignMaterialPct))) {
          if (txtForeignMaterialPct < 0 || txtForeignMaterialPct > 100) {
            showMessage(thisObj, 'Foreign Material(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Foreign Material(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtLSKPct
      let txtLSKPct = getValue(thisObj, 'txtLSKPct');
      if (txtLSKPct !== undefined && txtLSKPct !== '' && txtLSKPct !== null) {
        if (isNumeric((txtLSKPct))) {
          if (txtLSKPct < 0 || txtLSKPct > 100) {
            showMessage(thisObj, 'LSK(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "LSK(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // txtFancyPct
      let txtFancyPct = getValue(thisObj, 'txtFancyPct');
      if (txtFancyPct !== undefined && txtFancyPct !== '' && txtFancyPct !== null) {
        if (isNumeric((txtFancyPct))) {
          if (txtFancyPct < 0 || txtFancyPct > 100) {
            showMessage(thisObj, 'Fancy(percent) must be of format ###.##!!!', false);
            isFormValid = false;
            return;
          }
        } else {
          showMessage(thisObj, "Fancy(percent) must be of format ###.##!!!", false);
          isFormValid = false;
          return;
        }
      }

      // Peanut Type
      let peanutDescription = null;
      let peanutlist = thisObj.state['ddPeanutType'].valueList;
      let peanutvalue = thisObj.values['ddPeanutType'];
      if (peanutvalue !== undefined && peanutvalue !== null) {
        peanutDescription = peanutlist.find(elem => elem.key == peanutvalue).description;
        if (peanutDescription == 'VA') {
          let txtSampleWt = getValue(thisObj, 'txtSampleWt');
          let txtGrams = getValue(thisObj, 'txtGrams');
          if ((txtSampleWt !== "" || txtSampleWt !== undefined) && (txtGrams !== "" || txtGrams !== undefined)) {
            if (isNumeric((txtSampleWt))) {
              if (txtSampleWt < 0 || txtSampleWt > 9999.9) {
                showMessage(thisObj, "Virginia Cracked/Broken Sample Weight must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Virginia Cracked/Broken Sample Weight must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }

            if (isNumeric((txtGrams))) {
              if (txtGrams < 0 || txtGrams > 9999.9) {
                showMessage(thisObj, "Virginia Cracked/Broken grams must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Virginia Cracked/Broken grams must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }
        }
      }

      if (strHMC !== 'Y') {
        // txtMoisture
        let txtMoisture = getValue(thisObj, 'txtMoisture');
        if (txtMoisture !== undefined && txtMoisture !== '' && txtMoisture !== null) {
          if (isNumeric((txtMoisture))) {
            if (txtMoisture < 0 || txtMoisture > 100) {
              showMessage(thisObj, 'Moisture must be of format ###.##!!!', false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Moisture must be of format ###.##!!!", false);
            isFormValid = false;
            return;
          }
        }
      }

      // bFullDataCheck
      if (bFullDataCheck) {
        if (thisObj.values['gridWeightTickets'].length < 1) {
          showMessage(thisObj, "Must have at least 1 scale ticket!!!", false);
          isFormValid = false;
          return;
        }
        if (dblMinKernHullPct !== 0) {
          let txtTotalKernelsHulls = getValue(thisObj, 'txtTotalKernelsHulls');
          if (txtTotalKernelsHulls == undefined || txtTotalKernelsHulls == '' || txtTotalKernelsHulls == null) {
            txtTotalKernelsHulls = 0;
          }
          if (Number(txtTotalKernelsHulls) < Number(dblMinKernHullPct)) {
            showMessage(thisObj, "Total Kernals and Hulls outside acceptable range!!!", false);
            isFormValid = false;
            return;
          }
        }

        if (dblMaxKernHullPct !== 0) {
          let txtTotalKernelsHulls = getValue(thisObj, 'txtTotalKernelsHulls');
          if (txtTotalKernelsHulls == undefined || txtTotalKernelsHulls == '' || txtTotalKernelsHulls == null) {
            txtTotalKernelsHulls = 0;
          }
          if (Number(txtTotalKernelsHulls) > Number(dblMaxKernHullPct)) {
            showMessage(thisObj, "Total Kernals and Hulls outside acceptable range!!!", false);
            isFormValid = false;
            return;
          }
        }

        let bSpecsExist = false;
        let ddLocation = getValue(thisObj, 'ddLocation');
        let ddPeanutType = getValue(thisObj, 'ddPeanutType');
        let seed_ind = 'N';
        let ddSeg = getValue(thisObj, 'ddSeg');
        let ddEdibleOil = getValue(thisObj, 'ddEdibleOil');
        let ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety');
        let specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, ddPeanutVariety, seed_ind, ddSeg, ddEdibleOil);
        if (specException.length > 0) {
          specException = specException[0];
          bSpecsExist = true;
        } else {
          specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, null, seed_ind, ddSeg, ddEdibleOil);
          if (specException.length > 0) {
            specException = specException[0];
            bSpecsExist = true;
          } else {
            let locationData = getData(thisObj, 'locationData');
            let area_id = locationData.find(x => x.buy_pt_id == ddLocation).area_id;
            specException = await SettlementService.RetrievePeanutSpecificationDetails(area_id, ddPeanutType, seed_ind, ddSeg, ddEdibleOil);
            if (specException.length > 0) {
              specException = specException[0];
              bSpecsExist = true;
            }
          }
        }

        if (bSpecsExist) {
          if (specException.corn_ind !== "" && specException.corn_ind !== undefined) {
            let chkboxCornFound = document.getElementById('chkboxCornFound').checked;
            if ((chkboxCornFound == true && specException.corn_ind !== "Y") || (chkboxCornFound == false && specException.corn_ind !== "N")) {
              showMessage(thisObj, "Corn found does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (specException.flavus_ind !== "" && specException.flavus_ind !== undefined) {
            let chkboxFlavusFound = document.getElementById('chkboxFlavusFound').checked;
            if ((chkboxFlavusFound == true && specException.flavus_ind !== "Y") || (chkboxFlavusFound == false && specException.flavus_ind !== "N")) {
              showMessage(thisObj, "Flavus found does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_vicam_ppb) !== 0 && !isNaN(specException.min_vicam_ppb)) {
            let txtReading = getValue(thisObj, 'txtReading');
            if (Number(txtReading) < Number(specException.min_vicam_ppb)) {
              showMessage(thisObj, "Vicam does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_smk_pct) !== 0 && !isNaN(specException.min_smk_pct)) {
            let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
            if (Number(txtSMKRSPct) < Number(specException.min_smk_pct)) {
              showMessage(thisObj, "SMK does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_ss_pct) !== 0 && !isNaN(specException.min_ss_pct)) {
            let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
            if (Number(txtSoundSplitsPct) < Number(specException.min_ss_pct)) {
              showMessage(thisObj, "Sound Splits does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_ok_pct) !== 0 && !isNaN(specException.min_ok_pct)) {
            let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
            if (Number(txtOtherKernelsPct) < Number(specException.min_ok_pct)) {
              showMessage(thisObj, "Other Kernals does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_dam_pct) !== 0 && !isNaN(specException.min_dam_pct)) {
            let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
            if (Number(txtTotalDamagePct) < Number(specException.min_dam_pct)) {
              showMessage(thisObj, "Damage does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_hull_pct) !== 0 && !isNaN(specException.min_hull_pct)) {
            let txtHullsPct = getValue(thisObj, 'txtHullsPct');
            if (Number(txtHullsPct) < Number(specException.min_hull_pct)) {
              showMessage(thisObj, "Hulls does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_frez_dam_pct) !== 0 && !isNaN(specException.min_frez_dam_pct)) {
            let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
            if (Number(txtFreezeDamagePct) < Number(specException.min_frez_dam_pct)) {
              showMessage(thisObj, "Freeze Damage does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_cncl_rmd_pct) !== 0 && !isNaN(specException.min_cncl_rmd_pct)) {
            let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
            if (Number(txtConcealedRMDPct) < Number(specException.min_cncl_rmd_pct)) {
              showMessage(thisObj, "Concealed RMD does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_cr_br_pct) !== 0 && !isNaN(specException.min_cr_br_pct)) {
            let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
            if (Number(txtCrkOrBrkShellsPct) < Number(specException.min_cr_br_pct)) {
              showMessage(thisObj, "Crk or Brk Shells does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_dcol_shel_pct) !== 0 && !isNaN(specException.min_dcol_shel_pct)) {
            let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
            if (Number(txtDiscoloredShellsPct) < Number(specException.min_dcol_shel_pct)) {
              showMessage(thisObj, "Discolored Shells does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_hul_brit_pct) !== 0 && !isNaN(specException.min_hul_brit_pct)) {
            let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
            if (Number(txtHullBrightnessPct) < Number(specException.min_hul_brit_pct)) {
              showMessage(thisObj, "Hull Brightness does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_jumbo_pct) !== 0 && !isNaN(specException.min_jumbo_pct)) {
            let txtJumboPct = getValue(thisObj, 'txtJumboPct');
            if (Number(txtJumboPct) < Number(specException.min_jumbo_pct)) {
              showMessage(thisObj, "Jumbo does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_elk_pct) !== 0 && !isNaN(specException.min_elk_pct)) {
            let txtELKPct = getValue(thisObj, 'txtELKPct');
            if (Number(txtELKPct) < Number(specException.min_elk_pct)) {
              showMessage(thisObj, "ELK does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_fm_pct) !== 0 && !isNaN(specException.min_fm_pct)) {
            let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
            if (Number(txtForeignMaterialPct) < Number(specException.min_fm_pct)) {
              showMessage(thisObj, "Foreign Material does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.min_lsk_pct) !== 0 && !isNaN(specException.min_lsk_pct)) {
            let txtLSKPct = getValue(thisObj, 'txtLSKPct');
            if (Number(txtLSKPct) < Number(specException.min_lsk_pct)) {
              showMessage(thisObj, "LSK does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (strHMC == 'Y') {
            if (Number(specException.min_moist_pct) !== 0 && !isNaN(specException.min_moist_pct)) {
              let txtMoisture = getValue(thisObj, 'txtMoisture');
              if (Number(txtMoisture) < Number(specException.min_moist_pct)) {
                showMessage(thisObj, "Moisture does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }
          }

          if (Number(specException.min_fan_pct) !== 0 && !isNaN(specException.min_fan_pct)) {
            let txtFancyPct = getValue(thisObj, 'txtFancyPct');
            if (Number(txtFancyPct) < Number(specException.min_fan_pct)) {
              showMessage(thisObj, "Fancy does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_vicam_ppb) !== 0 && !isNaN(specException.max_vicam_ppb)) {
            let txtReading = getValue(thisObj, 'txtReading');
            if (Number(txtReading) > Number(specException.max_vicam_ppb)) {
              showMessage(thisObj, "Vicam does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_smk_pct) !== 0 && !isNaN(specException.max_smk_pct)) {
            let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
            if (Number(txtSMKRSPct) > Number(specException.max_smk_pct)) {
              showMessage(thisObj, "SMK does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_ss_pct) !== 0 && !isNaN(specException.max_ss_pct)) {
            let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
            if (Number(txtSoundSplitsPct) > Number(specException.max_ss_pct)) {
              showMessage(thisObj, "Sound Splits does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_ok_pct) !== 0 && !isNaN(specException.max_ok_pct)) {
            let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
            if (Number(txtOtherKernelsPct) > Number(specException.max_ok_pct)) {
              showMessage(thisObj, "Other Kernals does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_dam_pct) !== 0 && !isNaN(specException.max_dam_pct)) {
            let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
            if (Number(txtTotalDamagePct) > Number(specException.max_dam_pct)) {
              showMessage(thisObj, "Damage does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_hull_pct) !== 0 && !isNaN(specException.max_hull_pct)) {
            let txtHullsPct = getValue(thisObj, 'txtHullsPct');
            if (Number(txtHullsPct) > Number(specException.max_hull_pct)) {
              showMessage(thisObj, "Hulls does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_frez_dam_pct) !== 0 && !isNaN(specException.max_frez_dam_pct)) {
            let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
            if (Number(txtFreezeDamagePct) > Number(specException.max_frez_dam_pct)) {
              showMessage(thisObj, "Freeze Damage does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_cncl_rmd_pct) !== 0 && !isNaN(specException.max_cncl_rmd_pct)) {
            let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
            if (Number(txtConcealedRMDPct) > Number(specException.max_cncl_rmd_pct)) {
              showMessage(thisObj, "Concealed RMD does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_cr_br_pct) !== 0 && !isNaN(specException.max_cr_br_pct)) {
            let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
            if (Number(txtCrkOrBrkShellsPct) > Number(specException.max_cr_br_pct)) {
              showMessage(thisObj, "Crk or Brk Shells does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_dcol_shel_pct) !== 0 && !isNaN(specException.max_dcol_shel_pct)) {
            let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
            if (Number(txtDiscoloredShellsPct) > Number(specException.max_dcol_shel_pct)) {
              showMessage(thisObj, "Discolored Shells does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_hul_brit_pct) !== 0 && !isNaN(specException.max_hul_brit_pct)) {
            let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
            if (Number(txtHullBrightnessPct) > Number(specException.max_hul_brit_pct)) {
              showMessage(thisObj, "Hull Brightness does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_jumbo_pct) !== 0 && !isNaN(specException.max_jumbo_pct)) {
            let txtJumboPct = getValue(thisObj, 'txtJumboPct');
            if (Number(txtJumboPct) > Number(specException.max_jumbo_pct)) {
              showMessage(thisObj, "Jumbo does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_elk_pct) !== 0 && !isNaN(specException.max_elk_pct)) {
            let txtELKPct = getValue(thisObj, 'txtELKPct');
            if (Number(txtELKPct) > Number(specException.max_elk_pct)) {
              showMessage(thisObj, "ELK does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_fm_pct) !== 0 && !isNaN(specException.max_fm_pct)) {
            let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
            if (Number(txtForeignMaterialPct) > Number(specException.max_fm_pct)) {
              showMessage(thisObj, "Foreign Material does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (Number(specException.max_lsk_pct) !== 0 && !isNaN(specException.max_lsk_pct)) {
            let txtLSKPct = getValue(thisObj, 'txtLSKPct');
            if (Number(txtLSKPct) > Number(specException.max_lsk_pct)) {
              showMessage(thisObj, "LSK does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (strHMC !== 'Y') {
            if (Number(specException.max_moist_pct) !== 0 && !isNaN(specException.max_moist_pct)) {
              let txtMoisture = getValue(thisObj, 'txtMoisture');
              if (Number(txtMoisture) > Number(specException.max_moist_pct)) {
                showMessage(thisObj, "Moisture does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }
          }

          if (Number(specException.max_fan_pct) !== 0 && !isNaN(specException.max_fan_pct)) {
            let txtFancyPct = getValue(thisObj, 'txtFancyPct');
            if (Number(txtFancyPct) > Number(specException.max_fan_pct)) {
              showMessage(thisObj, "Fancy does not match peanut specifications!!!", false);
              isFormValid = false;
              return;
            }
          }
        } else {
          showMessage(thisObj, 'No peanut specification record exists matching supplied information. Please add record to the peanut specifiction control table!!!', false);
          isFormValid = false;
          return;
        }

        // Generation & Seg Validation
        let generation = getValue(thisObj, 'ddSeedGen');
        let seg = getValue(thisObj, 'ddSeg');
        if ((generation !== '' && generation !== undefined) && (seg == '1')) {
          bSpecsExist = false;
          // 2 API Will call from here
          let ddLocation = getValue(thisObj, 'ddLocation');
          let ddPeanutType = getValue(thisObj, 'ddPeanutType');
          let seed_ind = 'Y';
          let ddSeg = getValue(thisObj, 'ddSeg');
          let ddEdibleOil = getValue(thisObj, 'ddEdibleOil');
          let ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety');
          let specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, ddPeanutVariety, seed_ind, ddSeg, ddEdibleOil);
          if (specException.length > 0) {
            specException = specException[0];
            bSpecsExist = true;
          } else {
            specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, null, seed_ind, ddSeg, ddEdibleOil);
            if (specException.length > 0) {
              specException = specException[0];
              bSpecsExist = true;
            } else {
              let locationData = getData(thisObj, 'locationData');
              let area_id = locationData.find(x => x.buy_pt_id == ddLocation).area_id;
              specException = await SettlementService.RetrievePeanutSpecificationDetails(area_id, ddPeanutType, seed_ind, ddSeg, ddEdibleOil);
              if (specException.length > 0) {
                specException = specException[0];
                bSpecsExist = true;
              }
            }
          }

          meetingSpec = false;
          setValue(thisObj, 'txtSeedMeetingSpec', '')
          if (bSpecsExist) {
            if (specException.corn_ind !== "" && specException.corn_ind !== undefined) {
              let chkboxCornFound = document.getElementById('chkboxCornFound').checked;
              if ((chkboxCornFound == true && specException.corn_ind !== "Y") || (chkboxCornFound == false && specException.corn_ind !== "N")) {
                if (confirm("Corn found does not match seed specifications? Click on OK to proceed else click on Cancel.") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'.", 'Cancel', "Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }

              }
            }

            // flavus_ind
            if (specException.flavus_ind !== "" && specException.flavus_ind !== undefined) {
              let chkboxFlavusFound = document.getElementById('chkboxFlavusFound').checked;
              if ((chkboxFlavusFound == true && specException.flavus_ind !== "Y") || (chkboxFlavusFound == false && specException.flavus_ind !== "N")) {
                if (confirm("Flavus found does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit., Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'.", 'Cancel', "Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_vicam_ppb
            if (Number(specException.min_vicam_ppb) !== 0 && !isNaN(specException.min_vicam_ppb)) {
              let txtReading = getValue(thisObj, 'txtReading');
              if (Number(txtReading) < Number(specException.min_vicam_ppb)) {
                if (confirm("Vicam does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit., Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'.", 'Cancel', "Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_smk_pct
            if (Number(specException.min_smk_pct) !== 0 && !isNaN(specException.min_smk_pct)) {
              let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
              if (Number(txtSMKRSPct) < Number(specException.min_smk_pct)) {
                if (confirm("SMK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                  //  setValue(thisObj, 'ddSeedGen', '');
                  setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_ss_pct
            if (Number(specException.min_ss_pct) !== 0 && !isNaN(specException.min_ss_pct)) {
              let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
              if (Number(txtSoundSplitsPct) < Number(specException.min_ss_pct)) {
                if (confirm("Sound Splits does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_ok_pct
            if (Number(specException.min_ok_pct) !== 0 && !isNaN(specException.min_ok_pct)) {
              let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
              if (Number(txtOtherKernelsPct) < Number(specException.min_ok_pct)) {
                if (confirm("Other Kernals does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_dam_pct
            if (Number(specException.min_dam_pct) !== 0 && !isNaN(specException.min_dam_pct)) {
              let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
              if (Number(txtTotalDamagePct) < Number(specException.min_dam_pct)) {
                if (confirm("Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                  //  setValue(thisObj, 'ddSeedGen', '');
                  setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_hull_pct
            if (Number(specException.min_hull_pct) !== 0 && !isNaN(specException.min_hull_pct)) {
              let txtHullsPct = getValue(thisObj, 'txtHullsPct');
              if (Number(txtHullsPct) < Number(specException.min_hull_pct)) {
                if (confirm("Hulls does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_frez_dam_pct
            if (Number(specException.min_frez_dam_pct) !== 0 && !isNaN(specException.min_frez_dam_pct)) {
              let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
              if (Number(txtFreezeDamagePct) < Number(specException.min_frez_dam_pct)) {
                if (confirm("Freeze Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_cncl_rmd_pct
            if (Number(specException.min_cncl_rmd_pct) !== 0 && !isNaN(specException.min_cncl_rmd_pct)) {
              let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
              if (Number(txtConcealedRMDPct) < Number(specException.min_cncl_rmd_pct)) {
                if (confirm("Concealed RMD does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_cr_br_pct
            if (Number(specException.min_cr_br_pct) !== 0 && !isNaN(specException.min_cr_br_pct)) {
              let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
              if (Number(txtCrkOrBrkShellsPct) < Number(specException.min_cr_br_pct)) {
                if (confirm("Crk or Brk Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_dcol_shel_pct
            if (Number(specException.min_dcol_shel_pct) !== 0 && !isNaN(specException.min_dcol_shel_pct)) {
              let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
              if (Number(txtDiscoloredShellsPct) < Number(specException.min_dcol_shel_pct)) {
                if (confirm("Discolored Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_hul_brit_pct
            if (Number(specException.min_hul_brit_pct) !== 0 && !isNaN(specException.min_hul_brit_pct)) {
              let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
              if (Number(txtHullBrightnessPct) < Number(specException.min_hul_brit_pct)) {
                if (confirm("Hull Brightness does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_jumbo_pct
            if (Number(specException.min_jumbo_pct) !== 0 && !isNaN(specException.min_jumbo_pct)) {
              let txtJumboPct = getValue(thisObj, 'txtJumboPct');
              if (Number(txtJumboPct) < Number(specException.min_jumbo_pct)) {
                if (confirm("Jumbo does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_elk_pct
            if (Number(specException.min_elk_pct) !== 0 && !isNaN(specException.min_elk_pct)) {
              let txtELKPct = getValue(thisObj, 'txtELKPct');
              if (Number(txtELKPct) < Number(specException.min_elk_pct)) {
                if (confirm("ELK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_fm_pct
            if (Number(specException.min_fm_pct) !== 0 && !isNaN(specException.min_fm_pct)) {
              let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
              if (Number(txtForeignMaterialPct) < Number(specException.min_fm_pct)) {
                if (confirm("Foreign Material does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_fm_pct
            if (Number(specException.min_lsk_pct) !== 0 && !isNaN(specException.min_lsk_pct)) {
              let txtLSKPct = getValue(thisObj, 'txtLSKPct');
              if (Number(txtLSKPct) < Number(specException.min_lsk_pct)) {
                if (confirm("LSK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // min_fm_pct
            if (strHMC !== 'Y') {
              if (Number(specException.min_moist_pct) !== 0 && !isNaN(specException.min_moist_pct)) {
                let txtMoisture = getValue(thisObj, 'txtMoisture');
                if (Number(txtMoisture) < Number(specException.min_moist_pct)) {
                  if (confirm("Moisture does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                    }
                  }
                }
              }
            }

            // min_fm_pct
            if (Number(specException.min_fan_pct) !== 0 && !isNaN(specException.min_fan_pct)) {
              let txtFancyPct = getValue(thisObj, 'txtFancyPct');
              if (Number(txtFancyPct) < Number(specException.min_fan_pct)) {
                if (confirm("Fancy does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_vicam_ppb
            if (Number(specException.max_vicam_ppb) !== 0 && !isNaN(specException.max_vicam_ppb)) {
              let txtReading = getValue(thisObj, 'txtReading');
              if (Number(txtReading) > Number(specException.max_vicam_ppb)) {
                if (confirm("Vicam does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_smk_pct
            if (Number(specException.max_smk_pct) !== 0 && !isNaN(specException.max_smk_pct)) {
              let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
              if (Number(txtSMKRSPct) > Number(specException.max_smk_pct)) {
                if (confirm("SMK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    document.getElementById('txtSeedMeetingSpec').value="";
					          return;
                  }
                }
              }
            }

            // max_ss_pct
            if (Number(specException.max_ss_pct) !== 0 && !isNaN(specException.max_ss_pct)) {
              let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
              if (Number(txtSoundSplitsPct) > Number(specException.max_ss_pct)) {
                if (confirm("Sound Splits does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_ok_pct
            if (Number(specException.max_ok_pct) !== 0 && !isNaN(specException.max_ok_pct)) {
              let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
              if (Number(txtOtherKernelsPct) > Number(specException.max_ok_pct)) {
                if (confirm("Other Kernals does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_dam_pct
            if (Number(specException.max_dam_pct) !== 0 && !isNaN(specException.max_dam_pct)) {
              let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
              if (Number(txtTotalDamagePct) > Number(specException.max_dam_pct)) {
                if (confirm("Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_hull_pct
            if (Number(specException.max_hull_pct) !== 0 && !isNaN(specException.max_hull_pct)) {
              let txtHullsPct = getValue(thisObj, 'txtHullsPct');
              if (Number(txtHullsPct) > Number(specException.max_hull_pct)) {
                if (confirm("Hulls does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_frez_dam_pct
            if (Number(specException.max_frez_dam_pct) !== 0 && !isNaN(specException.max_frez_dam_pct)) {
              let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
              if (Number(txtFreezeDamagePct) > Number(specException.max_frez_dam_pct)) {
                if (confirm("Freeze Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_cncl_rmd_pct
            if (Number(specException.max_cncl_rmd_pct) !== 0 && !isNaN(specException.max_cncl_rmd_pct)) {
              let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
              if (Number(txtConcealedRMDPct) > Number(specException.max_cncl_rmd_pct)) {
                if (confirm("Concealed RMD does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_cncl_rmd_pct
            if (Number(specException.max_cr_br_pct) !== 0 && !isNaN(specException.max_cr_br_pct)) {
              let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
              if (Number(txtCrkOrBrkShellsPct) > Number(specException.max_cr_br_pct)) {
                if (confirm("Crk or Brk Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_dcol_shel_pct
            if (Number(specException.max_dcol_shel_pct) !== 0 && !isNaN(specException.max_dcol_shel_pct)) {
              let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
              if (Number(txtDiscoloredShellsPct) > Number(specException.max_dcol_shel_pct)) {
                if (confirm("Discolored Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_hul_brit_pct
            if (Number(specException.max_hul_brit_pct) !== 0 && !isNaN(specException.max_hul_brit_pct)) {
              let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
              if (Number(txtHullBrightnessPct) > Number(specException.max_hul_brit_pct)) {
                if (confirm("Hull Brightness does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                    //setValue(thisObj, 'ddSeedGen', '');
                    setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_jumbo_pct
            if (Number(specException.max_jumbo_pct) !== 0 && !isNaN(specException.max_jumbo_pct)) {
              let txtJumboPct = getValue(thisObj, 'txtJumboPct');
              if (Number(txtJumboPct) > Number(specException.max_jumbo_pct)) {
                if (confirm("Jumbo does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_elk_pct
            if (Number(specException.max_elk_pct) !== 0 && !isNaN(specException.max_elk_pct)) {
              let txtELKPct = getValue(thisObj, 'txtELKPct');
              if (Number(txtELKPct) > Number(specException.max_elk_pct)) {
                if (confirm("ELK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_fm_pct
            if (Number(specException.max_fm_pct) !== 0 && !isNaN(specException.max_fm_pct)) {
              let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
              if (Number(txtForeignMaterialPct) > Number(specException.max_fm_pct)) {
                if (confirm("Foreign Material does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                  //  setValue(thisObj, 'ddSeedGen', '');
                  setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_lsk_pct
            if (Number(specException.max_lsk_pct) !== 0 && !isNaN(specException.max_lsk_pct)) {
              let txtLSKPct = getValue(thisObj, 'txtLSKPct');
              if (Number(txtLSKPct) > Number(specException.max_lsk_pct)) {
                if (confirm("LSK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            // max_moist_pct
            if (strHMC !== 'Y') {
              if (Number(specException.max_moist_pct) !== 0 && !isNaN(specException.max_moist_pct)) {
                let txtMoisture = getValue(thisObj, 'txtMoisture');
                if (Number(txtMoisture) > Number(specException.max_moist_pct)) {
                  if (confirm("Moisture does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                    }
                  }
                }
              }
            }

            // max_fan_pct
            if (Number(specException.max_fan_pct) !== 0 && !isNaN(specException.max_fan_pct)) {
              let txtFancyPct = getValue(thisObj, 'txtFancyPct');
              if (Number(txtFancyPct) > Number(specException.max_fan_pct)) {
                if (confirm("Fancy does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                  if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                   // setValue(thisObj, 'ddSeedGen', '');
                   setseedmeetingspecasno = true;
                  } else {
                    meetingSpec = true;
					          setValue(thisObj, 'ddSeedGen', '');
					          setValue(thisObj, 'ddWhouseBin', '');
					          setValue(thisObj, 'ddBuyingPt', '');
					          setValue(thisObj, 'ddObligationPt', '');
                    setValue(thisObj, 'txtSeedMeetingSpec', '');
                    document.getElementById('txtSeedMeetingSpec').value='';
					          return;
                  }
                }
              }
            }

            if (meetingSpec) {
              setlblSeedMeetingSpecification('No');
              setValue(thisObj, 'txtSeedMeetingSpec', 'No')
            } else {
              if(setseedmeetingspecasno == true){
                  setlblSeedMeetingSpecification('No');
                  setValue(thisObj, 'txtSeedMeetingSpec', 'No');
            }else{
                  setlblSeedMeetingSpecification('Yes');
                  setValue(thisObj, 'txtSeedMeetingSpec', 'Yes')}
            }
          } else {
            alert('"No seed specification record exists matching supplied information. Please add record to the peanut specifiction control table!!!", 48, "Invalid grade for seed specification!!!');
            isFormValid = false;
            return;
          }
        } else {
          setlblSeedMeetingSpecification('');
          setValue(thisObj, 'txtSeedMeetingSpec', '')
        }
      }
    }

    if (strFV95Status == 'X') {
      let objData = {
        'FV95Number': txtFV95,
        'BuyingPoint': getValue(thisObj, 'ddLocation')
      }
      setData(thisObj, 'NoSaleVoidFV95', objData);
      goTo(thisObj, 'Settlements#NoSaleVoidFV95#DEFAULT#true#Click');
      return true;

      // This API we will cll from useeffect.
      // We will get response from NoSaleVoidFV95 Screen.
    }

    isFormValid = true;
  }
  // #endregion

  // #region 'bFillForm'
  const bFillForm = async (txtFV95) => {
    // Call the API INSPECT_HEADER_LIST2
    setLoading(true)
    let mbSupersedePending = false;
    let freefv95 = '';
    // let lblElecUploadInd = '';
    let lblSubmitType = '';
    let strHMC = '';
    setStrHMC('')
    let lblSeedMeetingSpecification = '';
    let mbSuperseded = false;
    // let mblnElectronicInd = '';
    let msTICKET_NUMBERS_CURE_CLEAN = '';
    let msTICKET_CURING_WT_IND = '';
    let msTICKET_CLEANING_WT_IND = '';
    let msTICKET_MOISTURE = '';
    let msTICKET_CLEAN_WT_OTHER = '';
    let msTICKET_CURE_WT_OTHER = '';
    let msTICKET_CURE_SCHEDULE = '';
    let msTICKET_CLEAN_SCHEDULE = '';
    let msTICKET_TOTAL_CURING_COST = '';
    let msTICKET_TOTAL_CLEANING_COST = '';
    let msTotalCureCost = '';
    let msTotalCleanCost = '';
    let inspectionStatus = '';

    let objVal = {};
    setValue(thisObj, "radioHighMoisture", '1');
    setYesNo(true)

    // let txtFV95 = getValue(thisObj, 'txtSC95');
    let inspectionList = await SettlementService.RetrieveInspectionHeaderList(txtFV95, null, null, 'N');
    if (inspectionList.length > 0) {
      let sHeader = inspectionList[0].inspect_header;
      let sVendorSplits = inspectionList[0].inspect_vendor_splits;
      let sRemarks = inspectionList[0].inspect_remarks;
      let sScaleTickets = inspectionList[0].inspect_scale_tickets;
      let sPremDeductions = inspectionList[0].inspect_prem_deducts;

      objVal['txtNumber'] = inspectionList[0].handler_loc_num_2
      // setValue(thisObj, 'txtNumber', inspectionList[0].handler_loc_num_2);

      objVal['txtName'] = inspectionList[0].handler_loc_name_2
      // setValue(thisObj, 'txtName', inspectionList[0].handler_loc_name_2);
      // Call Access API to hide txtNumber and txtName

      document.getElementsByClassName("lblAdd1007Aplcnt")[0].style.visibility = 'hidden';
      document.getElementsByClassName("txtNumber")[0].style.visibility = 'hidden';
      document.getElementsByClassName("txtName")[0].style.visibility = 'hidden';
      hide(thisObj,'lblCMAParticipant')
      
      disable(thisObj, 'txtNumber')
      disable(thisObj, 'txtName')

      let frmAdd1007 = await GetAccessLevel(inspectionList[0].buy_pt_id, '005', 'U');

      if (frmAdd1007 == true) {
        // show(thisObj, 'lblAdd1007Aplcnt')
        // show(thisObj, 'txtNumber')
        // show(thisObj, 'txtName')

      document.getElementsByClassName("lblAdd1007Aplcnt")[0].style.visibility = 'visible';
      document.getElementsByClassName("txtNumber")[0].style.visibility = 'visible';
      document.getElementsByClassName("txtName")[0].style.visibility = 'visible';

        enable(thisObj, 'txtNumber')
        enable(thisObj, 'txtName')
      }

      if (inspectionList[0].supersede_pending_ind == 'Y' && inspectionList[0].superseded_settle !== '') {
        mbSupersedePending = true;
        alert('This SC95 cannot process until ' + inspectionList[0].superseded_settle + ' has been manually superseded.');
        return false;
      }

      if (inspectionList[0].freE1007 == 'Y') {
        freefv95 = 'Y';
        setLblfreefv95('Y')
      } else {
        freefv95 = 'N';
        setLblfreefv95('N');
      }
      // await bFillContractControlLocationList();
      objVal['ddLocation'] = inspectionList[0].buy_pt_id
      // setValue(thisObj, 'ddLocation', inspectionList[0].buy_pt_id);
      EnabelDisableGradeEntry(inspectionList[0].buy_pt_id, '', '', '');

      await bFillStateList(inspectionList[0].buy_pt_id, null, true);
      objVal['ddState'] = inspectionList[0].state_id
      // setValue(thisObj, 'ddState', inspectionList[0].state_id);

      await bFillCounty(inspectionList[0].buy_pt_id, 'YES', inspectionList[0].state_abbr, null);
      objVal['ddCounty'] = inspectionList[0].county_id
      // setValue(thisObj, 'ddCounty', inspectionList[0].county_id);

      await bFillContractControlLocationList();

      let locationAllData = getData(thisObj, 'locationData')
      
      let locationDetailsById = locationAllData.find(x => x.buy_pt_id == inspectionList[0].buy_pt_id);
        if(locationDetailsById.buy_pt_id==inspectionList[0].buy_pt_id){
          if(locationDetailsById.cma_ind=='Y'){
            // let formLoadHideArray = ['grpbxCMAInformation', 'ddIrrigatedDryland']
            // CMADMADATA.push('txtCMAVendor')
            // CMADMADATA.push('lblCMAInformation')
            // CMADMADATA.push('ddRemitTo')
            // CMADMADATA.push('chkboxDefault')
            // lblCMAInformation','ddRemitTo','chkboxDefault'

            // showWidgets(thisObj, formLoadHideArray)
            show(thisObj,'txtCMAVendor')
            show(thisObj,'lblCMAInformation')
            show(thisObj,'ddRemitTo')
            // show(thisObj,'chkboxDefault')

            if(locationDetailsById.default_cma_vendor_num!=''){
              show(thisObj,'chkboxDefault')
            }
            else{
              hide(thisObj,'chkboxDefault')
            }
          }
          else{
            hide(thisObj,'txtCMAVendor')
            hide(thisObj,'lblCMAInformation')
            hide(thisObj,'ddRemitTo')
            // hide(thisObj,'chkboxDefault')

          }
        }
        
      await bFillFarmlist(inspectionList[0].buy_pt_id, inspectionList[0].state_abbr, inspectionList[0].county_id);
      objVal['ddFarm'] = inspectionList[0].farm_id + '-' + inspectionList[0].farm_suffix + '-' + inspectionList[0].farm_name
      // setValue(thisObj, 'ddFarm', inspectionList[0].farm_id);
      farmChange(inspectionList[0].farm_id);

      bLandType(inspectionList[0].farm_id)
      if(locationDetailsById.irr_dry_on=='Y'){
        show(thisObj,'ddIrrigatedDryland')
      }
      else{
        hide(thisObj,'ddIrrigatedDryland')
      }

      objVal['txtSC95'] = inspectionList[0].insp_num
      // setValue(thisObj, 'txtSC95', inspectionList[0].insp_num);

      objVal['txtInspectionDateTime'] = inspectionList[0].insp_date_time
      // setValue(thisObj, 'txtInspectionDateTime', inspectionList[0].insp_date_time);

      objVal['txtRevision'] = inspectionList[0].revision_num
      hide(thisObj,'txtRevision',false)
      // setValue(thisObj, 'txtRevision', inspectionList[0].revision_num);

      if (inspectionList[0].elec_upload !== '') {
        setLblElecUploadInd(inspectionList[0].elec_upload);
        lblSubmitType = 'SC95 Electronically Submitted';
      } else {
        setLblElecUploadInd('N');
        lblSubmitType = 'SC95 Manually Submitted';
      }
      objVal['lblSC95Submit'] = lblSubmitType
      // setValue(thisObj, 'lblSC95Submit', lblSubmitType)

      await bFillTypeList()
      objVal['ddPeanutType'] = inspectionList[0].pnut_type_id
      // setValue(thisObj, 'ddPeanutType', inspectionList[0].pnut_type_id);

      await bFillVarietyList(inspectionList[0].buy_pt_id, inspectionList[0].pnut_type_id);
      objVal['ddPeanutVariety'] = inspectionList[0].pnut_variety_id
      // setValue(thisObj, 'ddPeanutVariety', inspectionList[0].pnut_variety_id);

      bFillGeneration();
      objVal['ddSeedGen'] = inspectionList[0].seed_gen
      // setValue(thisObj, 'ddSeedGen', inspectionList[0].seed_gen);

      let moistureValue;
      if (isNumeric(inspectionList[0].hmoist_pct)) {
        moistureValue = inspectionList[0].hmoist_pct;
        objVal['txtMoisture'] = inspectionList[0].hmoist_pct
        // setValue(thisObj, 'txtMoisture', inspectionList[0].hmoist_pct);
      } else {
        moistureValue = inspectionList[0].moist_pct;
        objVal['txtMoisture'] = inspectionList[0].moist_pct
        // setValue(thisObj, 'txtMoisture', inspectionList[0].moist_pct);
      }

      strHMC = 'N';
      setStrHMC('N')
      if (parseFloat(moistureValue) > 10.49) {
        strHMC = 'Y';
        setStrHMC('Y')
      }

      let seg;
      bFillSegmentList();
      if (strHMC == 'Y') {
        seg = inspectionList[0].hmc_seg;
        objVal['ddSeg'] = inspectionList[0].hmc_seg
        // setValue(thisObj, 'ddSeg', inspectionList[0].hmc_seg);
      } else {
        seg = inspectionList[0].seg_type;
        objVal['ddSeg'] = inspectionList[0].seg_type
        // setValue(thisObj, 'ddSeg', inspectionList[0].seg_type);
      }
      
      if(seg!='1'){
        disable(thisObj,'ddSeedGen')
      }

      bFillEdibleOil();
      objVal['ddEdibleOil'] = inspectionList[0].edible_oil_ind
      // setValue(thisObj, 'ddEdibleOil', inspectionList[0].edible_oil_ind);

      await bFillOleicList();
      objVal['ddOleic'] = inspectionList[0].oleic_ind
      // setValue(thisObj, 'ddOleic', inspectionList[0].oleic_ind);

      if (inspectionList[0].seed_meeting_spec_ind == 'Y') {
        // lblSeedMeetingSpecification = 'Yes';
        objVal['txtSeedMeetingSpec'] = 'Yes'
        // setValue(thisObj, 'txtSeedMeetingSpec', 'Yes')
      } else if (inspectionList[0].seed_meeting_spec_ind == 'N') {
        // lblSeedMeetingSpecification = 'No';
        objVal['txtSeedMeetingSpec'] = 'No'
        // setValue(thisObj, 'txtSeedMeetingSpec', 'No')
      } else {
        // lblSeedMeetingSpecification = '';
        objVal['txtSeedMeetingSpec'] = ''
        // setValue(thisObj, 'txtSeedMeetingSpec', '')
      }

      objVal['txtProbingPattern'] = inspectionList[0].probe_pattern
      // setValue(thisObj, 'txtProbingPattern', inspectionList[0].probe_pattern);
      disable(thisObj, 'txtSeedMeetingSpec')

      if (inspectionList[0].superseded_settle == 0 || inspectionList[0].superseded_settle == '') {
        mbSuperseded = false;
      } else {
        mbSuperseded = true;
      }

      mblnElectronicInd = inspectionList[0].electronic_upload_ind == 'Y' ? true : false;

      if (inspectionList[0].corn_ind == 'Y') {
        objVal['chkboxCornFound'] = true //setValue(thisObj, 'chkboxCornFound', true);
      } else {
        objVal['chkboxCornFound'] = false //setValue(thisObj, 'chkboxCornFound', false);
      }

      if (inspectionList[0].flavus_ind == 'Y') {
        objVal['chkboxFlavusFound'] = true //setValue(thisObj, 'chkboxFlavusFound', true);
      } else {
        objVal['chkboxFlavusFound'] = false //setValue(thisObj, 'chkboxFlavusFound', false);
      }

      if (inspectionList[0].organic_ind == 'Y') {
        objVal['chkboxOrganic'] = true //setValue(thisObj, 'chkboxOrganic', true);
      } else {
        objVal['chkboxOrganic'] = false //setValue(thisObj, 'chkboxOrganic', false);
      }
      objVal['txtCMAVendor'] = inspectionList[0].cma_vendor_num
      let temp=inspectionList[0].cma_remit_to
      
      // setValue(thisObj, 'txtCMAVendor', inspectionList[0].cma_vendor_num);
      let remitListResponse=await ContractManagementService.RetieveRemitDetails(inspectionList[0].cma_vendor_num)
      if(remitListResponse!=undefined && remitListResponse!='' && remitListResponse!=null){
        let remitData=remitListResponse;

        let js=[];

        if(remitData.length>0){
          let remitIndex=0;
          for(let i=0;i<remitData.length;i++){
            let obj={
              key:remitData[i].remittoid,
              description:remitData[i].number + ' - '+ remitData[i].name + ' - '+ remitData[i].city + ', ' + remitData[i].state
            }
            js.push(obj)
            if(remitData[i].remittoid==temp){
              remitIndex=i;
            }
          }

          thisObj.setState(current => {
            return {
              ...current,
              ddRemitTo: {
                ...state["ddRemitTo"],
                valueList: js,
              }
            }
          })
          setValue(thisObj, 'ddRemitTo', js.at(remitIndex).key);
        }

        let lstLocationLandTypeDisplay=locationDetailsById.irr_dry_on
        if(inspectionList[0].irr_dry_ind=='' && lstLocationLandTypeDisplay=='Y'){
          if(inspectionList[0].insp_status=='I'){
            if(inspectionList[0].settlement_num!=''){
              setbSaveForm(true)
            }
            if(inspectionList[0].superseded_settle!='' && inspectionList[0].superseded_settle!=0){
              setbSaveForm(true)
            }
          }
        }
      }


      // REMIT_LIST API Call

      if (inspectionList[0].vicam_ind == 'Y') {
        // objVal['chkboxVicam'] = true 
        setValue(thisObj, 'chkboxVicam', true);
        enable(thisObj, 'txtReading');
      } else {
        // objVal['chkboxVicam'] = false 
        setValue(thisObj, 'chkboxVicam', false);
        disable(thisObj, 'txtReading');
      }
      objVal['txtReading'] = inspectionList[0].vicam_ppb
      // setValue(thisObj, 'txtReading', inspectionList[0].vicam_ppb);

      // This method will call EnabelDisableVicam();
      EnabelDisableVicam(inspectionList[0].buy_pt_id)
      // onchkboxVicamChange()

      let value = getData(thisObj, 'CheckBoxChecked');
      if (value) {
        enable(thisObj, 'txtReading');
      }
      else {
        objVal['txtReading'] = ''
        // setValue(thisObj, 'txtReading', '');
        disable(thisObj, 'txtReading');
      }

      if (strHMC == 'Y') {
        objVal['txtSeg'] = inspectionList[0].seg_type
        // setValue(thisObj, 'txtSeg', inspectionList[0].seg_type);
      }

      await bFillWarehouseBinList(inspectionList[0].buy_pt_id, inspectionList[0].organic_ind, inspectionList[0].pnut_type_id, seg, inspectionList[0].seed_gen, inspectionList[0].pnut_variety_id, inspectionList[0].oleic_ind);
      objVal['ddWhouseBin'] = inspectionList[0].unld_whse_id + inspectionList[0].unld_bin_id;
      // setValue(thisObj, 'ddWhouseBin', inspectionList[0].unld_whse_id);
      // objVal['ddWhouseBin'] = inspectionList[0].unld_whse_id

      await bFillOblBuyingPointList(inspectionList[0].buy_pt_id);
      objVal['ddBuyingPt'] = inspectionList[0].obl_buy_id
      // setValue(thisObj, 'ddBuyingPt', inspectionList[0].obl_buy_id);

      await bFillOblWarehouseBinList(inspectionList[0].obl_buy_id, inspectionList[0].pnut_type_id, seg, inspectionList[0].seed_gen, inspectionList[0].pnut_variety_id, inspectionList[0].oleic_ind);
      objVal['ddObligationPt'] = inspectionList[0].obl_whse_id + inspectionList[0].obl_bin_id
      // setValue(thisObj, 'ddObligationPt', inspectionList[0].obl_whse_id);

      switch (inspectionList[0].insp_status) {
        case 'V':
          objVal['txtStatus'] = 'Voided'
          // setValue(thisObj, 'txtStatus', 'Voided');
          setLblStatusTag('V')
          inspectionStatus = 'V'
          break;
        case 'N':
          objVal['txtStatus'] = 'No Sale'
          // setValue(thisObj, 'txtStatus', 'No Sale');
          setLblStatusTag('N')
          inspectionStatus = 'N'
          break;
        case 'X':
          objVal['txtStatus'] = 'Pending No Sale'
          // setValue(thisObj, 'txtStatus', 'Pending No Sale');
          setLblStatusTag('X')
          inspectionStatus = 'X'
          break;
        case 'S':
          if (inspectionList[0].settle_correct_cnt > 0 && inspectionList[0].superseded_settle !== '' && inspectionList[0].superseded_settle !== '0') {
            objVal['txtStatus'] = 'Settled - SC' + inspectionList[0].settle_correct_cnt
            // setValue(thisObj, 'txtStatus', 'Settled - SC' + inspectionList[0].settle_correct_cnt);
          } else if (inspectionList[0].settle_correct_cnt > 0) {
            objVal['txtStatus'] = 'Settled - C' + inspectionList[0].settle_correct_cn
            // setValue(thisObj, 'txtStatus', 'Settled - C' + inspectionList[0].settle_correct_cnt);
          } else if (inspectionList[0].superseded_settle !== '' && inspectionList[0].superseded_settle !== '0') {
            objVal['txtStatus'] = 'txtStatus', 'Settled - S'
            // setValue(thisObj, 'txtStatus', 'Settled - S');
          } else {
            objVal['txtStatus'] = 'Settled'
            // setValue(thisObj, 'txtStatus', 'Settled');
          }
          setLblStatusTag('S')
          inspectionStatus = 'S'
          break;
        case 'I':
          objVal['txtStatus'] = 'Inspected'
          // setValue(thisObj, 'txtStatus', 'Inspected');
          setLblStatusTag('I')
          inspectionStatus = 'I'
          break;
        case 'R':
          objVal['txtStatus'] = 'Superseded SC95'
          // setValue(thisObj, 'txtStatus', 'Superseded SC95');
          setLblStatusTag('R')
          inspectionStatus = 'R'
          break;
        default:
          objVal['txtStatus'] = 'Pending'
          // setValue(thisObj, 'txtStatus', 'Pending');
          setLblStatusTag('P')
          inspectionStatus = 'P'
          break;
      }

      if (inspectionList[0].settlement_num !== 0) {
        objVal['txt1007'] = inspectionList[0].settlement_num
        // setValue(thisObj, 'txt1007', inspectionList[0].settlement_num);
        // setLbl1007(inspectionList[0].settlement_num)
        setData(thisObj, '1007SC95', inspectionList[0].settlement_num)
      } else {
        objVal['txt1007'] = ''
        // setValue(thisObj, 'txt1007', '');
        // setLbl1007('')
        setData(thisObj, '1007SC95', '')
      }

      objVal['txtEWeightTkt'] = inspectionList[0].scale_list
      // setValue(thisObj, 'txtEWeightTkt', inspectionList[0].scale_list);

      objVal['txtEVehicleNum'] = inspectionList[0].vehicle_list
      // setValue(thisObj, 'txtEVehicleNum', inspectionList[0].vehicle_list);

      objVal['txtEWtInclVeh'] = inspectionList[0].e_wt_including_vehicle
      // setValue(thisObj, 'txtEWtInclVeh', inspectionList[0].e_wt_including_vehicle);

      objVal['txtEWtOfVeh'] = inspectionList[0].e_wt_of_vehicle
      // setValue(thisObj, 'txtEWtOfVeh', inspectionList[0].e_wt_of_vehicle);

      objVal['txtEWtOfPnut'] = inspectionList[0].e_wt_of_peanuts
      // setValue(thisObj, 'txtEWtOfPnut', inspectionList[0].e_wt_of_peanuts);

      objVal['lblSettleDate'] = inspectionList[0].settlement_date
      // setValue(thisObj, 'lblSettleDate', inspectionList[0].settlement_date);

      setSettlementDate(inspectionList[0].settlement_date);

      objVal['txtFMSampleWt'] = inspectionList[0].fm_sample.toString()
      // setValue(thisObj, 'txtFMSampleWt', inspectionList[0].fm_sample.toString());

      objVal['txtCleanSampleWt'] = inspectionList[0].cln_sample.toString()
      // setValue(thisObj, 'txtCleanSampleWt', inspectionList[0].cln_sample.toString());

      objVal['txtSMKRSGr'] = inspectionList[0].smk_gr.toString()
      // setValue(thisObj, 'txtSMKRSGr', inspectionList[0].smk_gr.toString());

      objVal['txtSoundSplitsGr'] = inspectionList[0].ss_gr.toString()
      // setValue(thisObj, 'txtSoundSplitsGr', inspectionList[0].ss_gr.toString());

      objVal['txtOtherKernelsGr'] = inspectionList[0].ok_gr.toString()
      // setValue(thisObj, 'txtOtherKernelsGr', inspectionList[0].ok_gr.toString());

      objVal['txtDamageSplits'] = inspectionList[0].dam_split_gr.toString()
      // setValue(thisObj, 'txtDamageSplits', inspectionList[0].dam_split_gr.toString());

      objVal['txtTotalDamageGr'] = inspectionList[0].dam_gr.toString()
      // setValue(thisObj, 'txtTotalDamageGr', inspectionList[0].dam_gr.toString());

      objVal['txtHullsGr'] = inspectionList[0].hull_gr.toString()
      // setValue(thisObj, 'txtHullsGr', inspectionList[0].hull_gr.toString());

      objVal['txtFreezeDamageGr'] = inspectionList[0].frez_dam_gr.toString()
      // setValue(thisObj, 'txtFreezeDamageGr', inspectionList[0].frez_dam_gr.toString());

      objVal['txtConcealedRMDGr'] = inspectionList[0].cncl_rmd_gr.toString()
      // setValue(thisObj, 'txtConcealedRMDGr', inspectionList[0].cncl_rmd_gr.toString());

      objVal['txtCrkOrBrkShellsGr'] = inspectionList[0].cr_br_gr.toString()
      // setValue(thisObj, 'txtCrkOrBrkShellsGr', inspectionList[0].cr_br_gr.toString());

      objVal['txtDiscoloredShellsGr'] = inspectionList[0].dcol_shel_gr.toString()
      // setValue(thisObj, 'txtDiscoloredShellsGr', inspectionList[0].dcol_shel_gr.toString());

      objVal['txtHullBrightness'] = inspectionList[0].hul_brit_gr.toString()
      // setValue(thisObj, 'txtHullBrightness', inspectionList[0].hul_brit_gr.toString());

      objVal['txtJumboGr'] = inspectionList[0].jumbo_gr.toString()
      // setValue(thisObj, 'txtJumboGr', inspectionList[0].jumbo_gr.toString());

      objVal['txtELKGr'] = inspectionList[0].elk_gr.toString()
      // setValue(thisObj, 'txtELKGr', inspectionList[0].elk_gr.toString());

      objVal['txtForeignMaterialGr'] = inspectionList[0].fm_gr.toString()
      // setValue(thisObj, 'txtForeignMaterialGr', inspectionList[0].fm_gr.toString());

      objVal['txtLSKGr'] = inspectionList[0].lsk_gr.toString()
      // setValue(thisObj, 'txtLSKGr', inspectionList[0].lsk_gr.toString());

      objVal['txtFancyGr'] = inspectionList[0].fan_gr.toString()
      // setValue(thisObj, 'txtFancyGr', inspectionList[0].fan_gr.toString());

      objVal['txtKernelsREL'] = inspectionList[0].kernel_rels.toString()
      // setValue(thisObj, 'txtKernelsREL', inspectionList[0].kernel_rels.toString());

      objVal['txtKernelsRPS'] = inspectionList[0].kernel_rps.toString()
      // setValue(thisObj, 'txtKernelsRPS', inspectionList[0].kernel_rps.toString());

      objVal['txtBluePanWt'] = inspectionList[0].bluepan_wt.toString();
      // setValue(thisObj, 'txtBluePanWt', inspectionList[0].bluepan_wt.toString());

      objVal['txtMeterReading'] = inspectionList[0].meter_read.toString()
      // setValue(thisObj, 'txtMeterReading', inspectionList[0].meter_read.toString());

      objVal['txtDamagedKRS'] = inspectionList[0].damaged_krs.toString();
      // setValue(thisObj, 'txtDamagedKRS', inspectionList[0].damaged_krs.toString());

      objVal['txtELKDamage'] = inspectionList[0].elk_damaged
      // setValue(thisObj, 'txtELKDamage', inspectionList[0].elk_damaged);

      objVal['txtSMK1Pct'] = inspectionList[0].smk_1_pct.toString()
      // setValue(thisObj, 'txtSMK1Pct', inspectionList[0].smk_1_pct.toString());

      objVal['txtSMK2Pct'] = inspectionList[0].smk_2_pct.toString()
      // setValue(thisObj, 'txtSMK2Pct', inspectionList[0].smk_2_pct.toString());

      objVal['txtSMK3Pct'] = inspectionList[0].smk_3_pct.toString()
      // setValue(thisObj, 'txtSMK3Pct', inspectionList[0].smk_3_pct.toString());

      objVal['txtSMK4Pct'] = inspectionList[0].smk_4_pct.toString()
      // setValue(thisObj, 'txtSMK4Pct', inspectionList[0].smk_4_pct.toString());
      // Control is missing but proved below line as fix

      objVal['tkc_pct_FV95'] = Number(inspectionList[0].tkc_pct) == NaN ? '0.00' : Number(inspectionList[0].tkc_pct).toFixed(2)
      // setData(thisObj, 'tkc_pct_FV95', Number(inspectionList[0].tkc_pct) == NaN ? '0.00' : Number(inspectionList[0].tkc_pct).toFixed(2));

      objVal['txtCrkOrBrkShellsPct'] = inspectionList[0].cr_br_pct.toString()
      // setValue(thisObj, 'txtCrkOrBrkShellsPct', inspectionList[0].cr_br_pct.toString());

      objVal['txtDiscoloredShellsPct'] = inspectionList[0].dcol_shel_pct.toString()
      // setValue(thisObj, 'txtDiscoloredShellsPct', inspectionList[0].dcol_shel_pct.toString());

      objVal['txtHullBrightnessPct'] = inspectionList[0].hul_brit_pct.toString()
      // setValue(thisObj, 'txtHullBrightnessPct', inspectionList[0].hul_brit_pct.toString());

      objVal['txtJumboPct'] = inspectionList[0].jumbo_pct.toString()
      // setValue(thisObj, 'txtJumboPct', inspectionList[0].jumbo_pct.toString());

      objVal['txtSampleWt'] = inspectionList[0].va_cr_br_sample.toString()
      // setValue(thisObj, 'txtSampleWt', inspectionList[0].va_cr_br_sample.toString());

      objVal['txtGrams'] = inspectionList[0].va_cr_br_gram.toString()
      // setValue(thisObj, 'txtGrams', inspectionList[0].va_cr_br_gram.toString());

      objVal['txtPercent'] = inspectionList[0].va_cr_br_pct.toString()
      // setValue(thisObj, 'txtPercent', inspectionList[0].va_cr_br_pct.toString());

      objVal['txtValenciaCleanSampleWt'] = inspectionList[0].vl_cln_sample.toString()
      // setValue(thisObj, 'txtValenciaCleanSampleWt', inspectionList[0].vl_cln_sample.toString());

      let fancyPct;
      let ForeignMaterialPct;
      let lskPct;
      let SMKRSPct;
      let SoundSplitsPct;
      let OtherKernelsPct;
      let ELKPct;
      let TotalDamagePct;
      let HullsPct;

      if (strHMC == 'Y') {
        setValue(thisObj, "radioHighMoisture", '1');
        setYesNo(true)

        objVal['txtSoundSplitsPct'] = inspectionList[0].hss_pct.toString()
        // setValue(thisObj, 'txtSoundSplitsPct', inspectionList[0].hss_pct.toString());
        SoundSplitsPct = inspectionList[0].hss_pct

        objVal['txtOtherKernelsPct'] = inspectionList[0].hok_pct.toString()
        // setValue(thisObj, 'txtOtherKernelsPct', inspectionList[0].hok_pct.toString());
        OtherKernelsPct = inspectionList[0].hok_pct

        objVal['txtTotalDamagePct'] = inspectionList[0].hdam_pct.toString()
        // setValue(thisObj, 'txtTotalDamagePct', inspectionList[0].hdam_pct.toString());
        TotalDamagePct = inspectionList[0].hdam_pct

        objVal['txtHullsPct'] = inspectionList[0].hhull_pct.toString()
        // setValue(thisObj, 'txtHullsPct', inspectionList[0].hhull_pct.toString());
        HullsPct = inspectionList[0].hhull_pct

        objVal['txtFreezeDamagePct'] = inspectionList[0].frez_dam_pct.toString()
        // setValue(thisObj, 'txtFreezeDamagePct', inspectionList[0].frez_dam_pct.toString());

        objVal['txtConcealedRMDPct'] = inspectionList[0].cncl_rmd_pct.toString()
        // setValue(thisObj, 'txtConcealedRMDPct', inspectionList[0].cncl_rmd_pct.toString());

        objVal['txtELKPct'] = inspectionList[0].helk_pct.toString()
        // setValue(thisObj, 'txtELKPct', inspectionList[0].helk_pct.toString());
        ELKPct = inspectionList[0].helk_pct

        objVal['txtForeignMaterialPct'] = inspectionList[0].hfm_pct.toString()
        // setValue(thisObj, 'txtForeignMaterialPct', inspectionList[0].hfm_pct.toString());
        ForeignMaterialPct = inspectionList[0].hfm_pct

        objVal['txtLSKPct'] = inspectionList[0].hlsk_pct.toString()
        // setValue(thisObj, 'txtLSKPct', inspectionList[0].hlsk_pct.toString());
        lskPct = inspectionList[0].hlsk_pc

        objVal['txtFancyPct'] = inspectionList[0].hfan_pct.toString()
        // setValue(thisObj, 'txtFancyPct', inspectionList[0].hfan_pct.toString());
        fancyPct = inspectionList[0].hfan_pct;

        objVal['txtSMKRSPct'] = inspectionList[0].hsmk_pct.toString()
        // setValue(thisObj, 'txtSMKRSPct', inspectionList[0].hsmk_pct.toString());
        SMKRSPct = inspectionList[0].hsmk_pct

        objVal['txtSeg'] = inspectionList[0].seg_type.toString()
        // setValue(thisObj, 'txtSeg', inspectionList[0].seg_type.toString());

        objVal['txtFM'] = inspectionList[0].fm_pct.toString()
        // setValue(thisObj, 'txtFM', inspectionList[0].fm_pct.toString());

        objVal['txtLSK'] = inspectionList[0].lsk_pct.toString()
        // setValue(thisObj, 'txtLSK', inspectionList[0].lsk_pct.toString());

        objVal['txtSMK'] = inspectionList[0].smk_pct.toString()
        // setValue(thisObj, 'txtSMK', inspectionList[0].smk_pct.toString());

        objVal['txtSS'] = inspectionList[0].ss_pct.toString()
        // setValue(thisObj, 'txtSS', inspectionList[0].ss_pct.toString());

        objVal['txtSMKRS'] = FormatNumber(parseFloat(inspectionList[0].smk_pct) + parseFloat(inspectionList[0].ss_pct)).toString()
        // setValue(thisObj, 'txtSMKRS', FormatNumber(parseFloat(inspectionList[0].smk_pct) + parseFloat(inspectionList[0].ss_pct)).toString());

        objVal['txtOK'] = inspectionList[0].ok_pct.toString()
        // setValue(thisObj, 'txtOK', inspectionList[0].ok_pct.toString());

        objVal['txtFRZ'] = inspectionList[0].frez_dam_pct.toString()
        // setValue(thisObj, 'txtFRZ', inspectionList[0].frez_dam_pct.toString());

        objVal['txtCRMD'] = inspectionList[0].cncl_rmd_pct.toString()
        // setValue(thisObj, 'txtCRMD', inspectionList[0].cncl_rmd_pct.toString());

        objVal['txtDAM'] = inspectionList[0].dam_pct.toString()
        // setValue(thisObj, 'txtDAM', inspectionList[0].dam_pct.toString());

        objVal['txtTotalKernels'] = FormatNumber(parseFloat(inspectionList[0].smk_pct) + parseFloat(inspectionList[0].ss_pct) + parseFloat(inspectionList[0].dam_pct) + parseFloat(inspectionList[0].ok_pct)).toString()
        // setValue(thisObj, 'txtTotalKernels', FormatNumber(parseFloat(inspectionList[0].smk_pct) + parseFloat(inspectionList[0].ss_pct) + parseFloat(inspectionList[0].dam_pct) + parseFloat(inspectionList[0].ok_pct)).toString());

        objVal['txtHULLS'] = inspectionList[0].hull_pct.toString()
        // setValue(thisObj, 'txtHULLS', inspectionList[0].hull_pct.toString());

        objVal['txtELK'] = inspectionList[0].elk_pct.toString()
        // setValue(thisObj, 'txtELK', inspectionList[0].elk_pct.toString());

        objVal['txtFANCY'] = inspectionList[0].fan_pct.toString()
        // setValue(thisObj, 'txtFANCY', inspectionList[0].fan_pct.toString());

        objVal['txtMST'] = inspectionList[0].moist_pct.toString()
        // setValue(thisObj, 'txtMST', inspectionList[0].moist_pct.toString());

        objVal['txtTotKrnlsHulls'] = FormatNumber(parseFloat(inspectionList[0].smk_pct) + parseFloat(inspectionList[0].ss_pct) + parseFloat(inspectionList[0].dam_pct) + parseFloat(inspectionList[0].ok_pct) + parseFloat(inspectionList[0].hull_pct)).toString()
        // setValue(thisObj, 'txtTotKrnlsHulls', FormatNumber(parseFloat(inspectionList[0].smk_pct) + parseFloat(inspectionList[0].ss_pct) + parseFloat(inspectionList[0].dam_pct) + parseFloat(inspectionList[0].ok_pct) + parseFloat(inspectionList[0].hull_pct)).toString());
      } else {
        // objVal['radioHighMoisture']= '2' 
        setValue(thisObj, "radioHighMoisture", '2');
        setYesNo(false)

        objVal['txtSMKRSPct'] = inspectionList[0].smk_pct.toString()
        // setValue(thisObj, 'txtSMKRSPct', inspectionList[0].smk_pct.toString());
        SMKRSPct = inspectionList[0].smk_pct

        objVal['txtSoundSplitsPct'] = inspectionList[0].ss_pct.toString()
        // setValue(thisObj, 'txtSoundSplitsPct', inspectionList[0].ss_pct.toString());
        SoundSplitsPct = inspectionList[0].ss_pct

        objVal['txtOtherKernelsPct'] = inspectionList[0].ok_pct.toString()
        // setValue(thisObj, 'txtOtherKernelsPct', inspectionList[0].ok_pct.toString());
        OtherKernelsPct = inspectionList[0].ok_pct

        objVal['txtTotalDamagePct'] = inspectionList[0].dam_pct.toString()
        // setValue(thisObj, 'txtTotalDamagePct', inspectionList[0].dam_pct.toString());
        TotalDamagePct = inspectionList[0].dam_pct

        objVal['txtHullsPct'] = inspectionList[0].hull_pct.toString()
        // setValue(thisObj, 'txtHullsPct', inspectionList[0].hull_pct.toString());
        HullsPct = inspectionList[0].hull_pct

        objVal['txtFreezeDamagePct'] = inspectionList[0].frez_dam_pct.toString()
        // setValue(thisObj, 'txtFreezeDamagePct', inspectionList[0].frez_dam_pct.toString());

        objVal['txtConcealedRMDPct'] = inspectionList[0].cncl_rmd_pct.toString()
        // setValue(thisObj, 'txtConcealedRMDPct', inspectionList[0].cncl_rmd_pct.toString());

        objVal['txtELKPct'] = inspectionList[0].elk_pct.toString()
        // setValue(thisObj, 'txtELKPct', inspectionList[0].elk_pct.toString());
        ELKPct = inspectionList[0].elk_pct

        objVal['txtForeignMaterialPct'] = inspectionList[0].fm_pct.toString()
        // setValue(thisObj, 'txtForeignMaterialPct', inspectionList[0].fm_pct.toString());
        ForeignMaterialPct = inspectionList[0].fm_pct

        objVal['txtLSKPct'] = inspectionList[0].lsk_pct.toString()
        // setValue(thisObj, 'txtLSKPct', inspectionList[0].lsk_pct.toString());
        lskPct = inspectionList[0].lsk_pct

        objVal['txtFancyPct'] = inspectionList[0].fan_pct.toString()
        // setValue(thisObj, 'txtFancyPct', inspectionList[0].fan_pct.toString());
        fancyPct = inspectionList[0].fan_pct;
      }

      setTotalSMK(SMKRSPct, SoundSplitsPct);
      setTotalKernals(SMKRSPct, SoundSplitsPct, OtherKernelsPct, TotalDamagePct);
      setTotalKernalsHulls(SMKRSPct, SoundSplitsPct, OtherKernelsPct, TotalDamagePct, HullsPct);

      objVal['txtOtherForeignMaterials'] = inspectionList[0].oth_fm.toString()
      // setValue(thisObj, 'txtOtherForeignMaterials', inspectionList[0].oth_fm.toString());

      objVal['txtSMK1Gr'] = inspectionList[0].smk_1_guage.toString()
      // setValue(thisObj, 'txtSMK1Gr', inspectionList[0].smk_1_guage.toString());

      objVal['txtSMK2Gr'] = inspectionList[0].smk_2_guage.toString()
      // setValue(thisObj, 'txtSMK2Gr', inspectionList[0].smk_2_guage.toString());

      objVal['txtSMK3Gr'] = inspectionList[0].smk_3_guage.toString()
      // setValue(thisObj, 'txtSMK3Gr', inspectionList[0].smk_3_guage.toString());

      objVal['txtSMK4Gr'] = inspectionList[0].smk_4_guage.toString()
      // setValue(thisObj, 'txtSMK4Gr', inspectionList[0].smk_4_guage.toString());

      objVal['txtWtIncludingVehicle'] = inspectionList[0].tare_wt.toString()
      // setValue(thisObj, 'txtWtIncludingVehicle', inspectionList[0].tare_wt.toString());

      objVal['txtVoidReason'] = inspectionList[0].void_reason_code + ' - ' + inspectionList[0].void_reason_desc
      // setValue(thisObj, 'txtVoidReason', inspectionList[0].void_reason_code + ' - ' + inspectionList[0].void_reason_desc);

      objVal['txtWtOfVehicle'] = inspectionList[0].vehicle_wt.toString()
      // setValue(thisObj, 'txtWtOfVehicle', inspectionList[0].vehicle_wt.toString());

      objVal['txtGrossWeight'] = inspectionList[0].gross_wt.toString()
      // setValue(thisObj, 'txtGrossWeight', inspectionList[0].gross_wt.toString());

      objVal['txtForeignMaterial'] = inspectionList[0].fm_wt.toString()
      // setValue(thisObj, 'txtForeignMaterial', inspectionList[0].fm_wt.toString());

      objVal['txtExcessMoisture'] = inspectionList[0].ex_moist_wt.toString()
      // setValue(thisObj, 'txtExcessMoisture', inspectionList[0].ex_moist_wt.toString());

      objVal['txtNetWeight'] = inspectionList[0].net_wt.toString()
      // setValue(thisObj, 'txtNetWeight', inspectionList[0].net_wt.toString());

      objVal['txtLSKvc'] = inspectionList[0].lsk_wt.toString()
      // setValue(thisObj, 'txtLSKvc', inspectionList[0].lsk_wt.toString());

      objVal['txtKernelValueTon'] = inspectionList[0].kern_value.toString()
      // setValue(thisObj, 'txtKernelValueTon', inspectionList[0].kern_value.toString());

      objVal['txtELKPremiumTon'] = inspectionList[0].elk_prem.toString()
      // setValue(thisObj, 'txtELKPremiumTon', inspectionList[0].elk_prem.toString());

      objVal['txtDamageTon'] = inspectionList[0].dam_discount.toString()
      // setValue(thisObj, 'txtDamageTon', inspectionList[0].dam_discount.toString());

      objVal['txtAccessFMTon'] = inspectionList[0].ex_fm_discount.toString()
      // setValue(thisObj, 'txtAccessFMTon', inspectionList[0].ex_fm_discount.toString());

      objVal['txtExcessSplitsTon'] = inspectionList[0].ex_ss_discount.toString()
      // setValue(thisObj, 'txtExcessSplitsTon', inspectionList[0].ex_ss_discount.toString());

      objVal['txtAFlavusTon'] = inspectionList[0].flavus_discount.toString()
      // setValue(thisObj, 'txtAFlavusTon', inspectionList[0].flavus_discount.toString());

      objVal['txtNetValPerTonExclSLK'] = inspectionList[0].net_les_lsk_val.toString()
      // setValue(thisObj, 'txtNetValPerTonExclSLK', inspectionList[0].net_les_lsk_val.toString());

      objVal['txtG'] = inspectionList[0].net_price.toString() == "" ? '0.00000' : inspectionList[0].net_price.toString()
      // setValue(thisObj, 'txtG', inspectionList[0].net_price.toString() == "" ? '0.00000' : inspectionList[0].net_price.toString());

      objVal['txtTotalLoadVal'] = inspectionList[0].basis_grade_amt.toString()
      // setValue(thisObj, 'txtTotalLoadVal', inspectionList[0].basis_grade_amt.toString());

      objVal['lblAuditCountValue'] = inspectionList[0].revision_num.toString()
      // setValue(thisObj, 'lblAuditCountValue', inspectionList[0].revision_num.toString());

      objVal['lblAddedByValue'] = inspectionList[0].add_user + ' ' + inspectionList[0].add_date_time
      // setValue(thisObj, 'lblAddedByValue', inspectionList[0].add_user + ' ' + inspectionList[0].add_date_time);

      objVal['lblChangedByValue'] = inspectionList[0].chg_user + ' ' + inspectionList[0].chg_date_time
      // setValue(thisObj, 'lblChangedByValue', inspectionList[0].chg_user + ' ' + inspectionList[0].chg_date_time);
      // setValue(thisObj, 'lblLastChange', inspectionList[0].chg_date_time); // Control Not Found


      setlblLastChange(inspectionList[0].chg_date_time);
      msTICKET_NUMBERS_CURE_CLEAN = inspectionList[0].ticket_numbers_cure_clean;
      msTICKET_CURING_WT_IND = inspectionList[0].ticket_curing_wt_ind;
      msTICKET_CLEANING_WT_IND = inspectionList[0].ticket_cleaning_wt_ind;
      msTICKET_MOISTURE = inspectionList[0].ticket_moisture;
      msTICKET_CLEAN_WT_OTHER = inspectionList[0].ticket_clean_wt_other;
      msTICKET_CURE_WT_OTHER = inspectionList[0].ticket_cure_wt_other;
      msTICKET_CURE_SCHEDULE = inspectionList[0].ticket_cure_schedule;
      msTICKET_CLEAN_SCHEDULE = inspectionList[0].ticket_clean_schedule;
      msTICKET_TOTAL_CURING_COST = inspectionList[0].ticket_total_curing_cost;
      msTICKET_TOTAL_CLEANING_COST = inspectionList[0].ticket_total_cleaning_cost;
      msTotalCureCost = msTICKET_TOTAL_CURING_COST;
      msTotalCleanCost = msTICKET_TOTAL_CLEANING_COST;
      setTotalCuringCost(msTICKET_TOTAL_CURING_COST);
      setTotalCleaningCost(msTICKET_TOTAL_CLEANING_COST);

      if (inspectionList[0].insp_status == "I") {
        if (inspectionList[0].settlement_num !== "") {
          disable(thisObj, "radioHighMoisture");
          disable(thisObj, "radioHighMoisture");
          disable(thisObj, 'btnConvertHMC');
        }
      }
      // Write code vendo split is remain


      for (let i = 0; i < sVendorSplits.length; i++) {
        // setValue(thisObj, `ddVendor${i}`, [''])
        // setValue(thisObj, `ddRemitTo${i}`, '')
        // setValue(thisObj, `txtSharePct${i}`, '')
        // setValue(thisObj, `chkboxCMA${i}`, false)
        objVal[`ddVendor${i}`] = ['']
        objVal[`ddRemitTo${i}`] = ''
        objVal[`txtSharePct${i}`] = ''
        objVal[`chkboxCMA${i}`] = false
      }

      hide(thisObj,'chkboxCMA0',false)
      hide(thisObj,'chkboxCMA1',false)
      hide(thisObj,'chkboxCMA2',false)
      hide(thisObj,'chkboxCMA3',false)
      
      let hideArrayBfillform = [];
      let showArrayBfillform = [];
      if (sVendorSplits.length > 0) {
        let lblCMAParticipantVisible=false;
        // Bind Vendor Split Controls
        for (let i = 0; i < sVendorSplits.length; i++) {
          let newVendor = 'ddVendor' + i
          let newLblVendor = 'lblVendor' + i
          let newRemitTo = 'txtSharePct' + i
          let vendorArr = []
          vendorArr.push(sVendorSplits[i].split_vendor)

          objVal[newVendor] = vendorArr
          // setValue(thisObj, newVendor, vendorArr)
          vendorFill(newVendor, sVendorSplits[i].split_vendor)
          objVal[newRemitTo] = sVendorSplits[i].split_share_pct
          // setValue(thisObj, newRemitTo, sVendorSplits[i].split_share_pct)
          if (sVendorSplits[i].cma_participant_ind == 'Y') {
            objVal[`chkboxCMA${i}`] = true
            // setValue(thisObj, `chkboxCMA${i}`, true);
            // showArrayBfillform.push(`chkboxCMA${i}`);
            show(thisObj,`chkboxCMA${i}`)
            lblCMAParticipantVisible=true;
          } else {
            // hideArrayBfillform.push(`chkboxCMA${i}`);
            hide(thisObj,`chkboxCMA${i}`)
          }
        }
        hideWidgets(thisObj, hideArrayBfillform)
        showWidgets(thisObj, showArrayBfillform)
        if(lblCMAParticipantVisible==true){
          show(thisObj,'lblCMAParticipant')
        }
        setData(thisObj,'bfillVendorSplits',sVendorSplits)
      }

      else {
        bFillVendorList(inspectionList[0].buy_pt_id, inspectionList[0].state_abbr, inspectionList[0].county_id, inspectionList[0].farm_id, null)
      }
      EnabelDisableGradeEntry(inspectionList[0].buy_pt_id, inspectionList[0].pnut_type_id, inspectionList[0].elec_upload, inspectionList[0].settlement_num)
      EnableDisableControls(sVendorSplits, inspectionStatus, inspectionList[0].elec_upload, lblLoadType1, freefv95)
      setLblLoadType1(false)
      // Bind Grid is pending, will implement it once the grid part is completely done
      objVal['txtarInspectorRemarks'] = sRemarks[0].remarks.toString()
      //setValue(thisObj, 'txtarInspectorRemarks', sRemarks[0].remarks.toString())


      let js = []
      let weightincludingvehicle = 0;
      let VehicleWeight = 0;

      if (sScaleTickets.length > 0) {
        let scaleTickets = sScaleTickets[0].inspect_scale_ticket

        let dryVehicleCount = 'Y';
        for (let i = 0; i < scaleTickets.length; i++) {
          let gridData = {}
          gridData.txtcolWeightTicket = scaleTickets[i].dry_ticket
          gridData.txtcolDate = moment(scaleTickets[i].dry_date).format('MM/DD/YYYY')
          gridData.txtcolWtOfPeanuts = scaleTickets[i].dry_peanut_wt
          gridData.txtConcatedData = scaleTickets[i].dry_ticket + '|' + scaleTickets[i].dry_date + '|' +
            scaleTickets[i].dry_carrier + '|' + scaleTickets[i].dry_dispatch + '|' +
            scaleTickets[i].dry_vehicle_id + '|' + scaleTickets[i].dry_tare_wt + '|' +
            scaleTickets[i].dry_vehicle_wt + '|' + scaleTickets[i].dry_peanut_wt + '|' +
            scaleTickets[i].green_ticket + '|' + scaleTickets[i].green_date + '|' +
            scaleTickets[i].green_carrier + '|' + scaleTickets[i].green_dispatch + '|' +
            scaleTickets[i].green_vehicle_id + '|' + scaleTickets[i].green_tare_wt + '|' +
            scaleTickets[i].green_vehicle_wt + '|' + scaleTickets[i].green_peanut_wt + '|' + scaleTickets[i].weigher_name
          js.push(gridData)
          weightincludingvehicle = Number(weightincludingvehicle) + Number(scaleTickets[i].dry_tare_wt);
          VehicleWeight = Number(VehicleWeight) + Number(scaleTickets[i].dry_vehicle_wt)
          if (Number(scaleTickets[i].dry_tare_wt) <= 0) {
            dryVehicleCount = 'N';
          }
          if (Number(scaleTickets[i].dry_vehicle_wt) <= 0) {
            dryVehicleCount = 'N';
          }
        }
        // setValue(thisObj, 'gridWeightTickets', js);
        
        hideColumn(thisObj, 'gridWeightTickets', 'txtcolCCTicket');
        // if (dryVehicleCount == 'N') {
        //   setLoading(false);
        //   return false;
        // }
        objVal['gridWeightTickets'] = js
      }
      
      setFieldValues(thisObj, objVal, true)
      if (strHMC != 'Y') {
        CalculateValues2(inspectionList[0].area_id, inspectionList[0].pnut_type_id, weightincludingvehicle, VehicleWeight, moistureValue, inspectionList[0].cr_br_pct, inspectionList[0].dcol_shel_pct, fancyPct, ForeignMaterialPct, lskPct, SMKRSPct, SoundSplitsPct, OtherKernelsPct, ELKPct, TotalDamagePct, inspectionList[0].vicam_ppb)
      } else {
        CalculateValues2(inspectionList[0].area_id, inspectionList[0].pnut_type_id, weightincludingvehicle, VehicleWeight, moistureValue, inspectionList[0].cr_br_pct, inspectionList[0].dcol_shel_pct, inspectionList[0].fan_pct, inspectionList[0].fm_pct, inspectionList[0].lsk_pct, inspectionList[0].smk_pct, inspectionList[0].ss_pct, inspectionList[0].ok_pct, inspectionList[0].elk_pct, inspectionList[0].dam_pct, inspectionList[0].vicam_ppb)
      }

      CalculateTotals(js)

      // Grid Row Selection.
      let txtSC951007 = getData(thisObj, '1007SC95')
      EnableFV95NumberChange(inspectionList[0].buy_pt_id, inspectionList[0].insp_num, inspectionStatus, txtSC951007, mbSuperseded, mblnElectronicInd)
      let documentDetails = await SettlementService.RetrieveCheck1007OneDocumentDetails(inspectionList[0].buy_pt_id);
      if (documentDetails[0].onedoc_ind == 'Y') {
        document.getElementsByClassName('btnReprint')[1].style.visibility = 'visible';
      } else {
        document.getElementsByClassName('btnReprint')[1].style.visibility = 'hidden';
      }

    }

    setLoading(false)
  }
  // endregion

  const setTotalSMK = (SMKRSPct, SoundSplitsPct) => {
    let TotalSMKValue = Number(SMKRSPct) + Number(SoundSplitsPct);
    setValue(thisObj, 'txtTotalSMK', FormatNumber(TotalSMKValue).toString());
  }

  const setTotalKernals = (SMKRSPct, SoundSplitsPct, OtherKernelsPct, TotalDamagePct) => {
    SMKRSPct = (SMKRSPct != undefined && SMKRSPct != "" && SMKRSPct != null) ? Number(SMKRSPct) : 0;
    SoundSplitsPct = (SoundSplitsPct != undefined && SoundSplitsPct != "" && SoundSplitsPct != null) ? Number(SoundSplitsPct) : 0;
    OtherKernelsPct = (OtherKernelsPct != undefined && OtherKernelsPct != "" && OtherKernelsPct != null) ? Number(OtherKernelsPct) : 0;
    TotalDamagePct = (TotalDamagePct != undefined && TotalDamagePct != "" && TotalDamagePct != null) ? Number(TotalDamagePct) : 0;

    let TotalKrnlsValue = Number(SMKRSPct) + Number(SoundSplitsPct) + Number(OtherKernelsPct) + Number(TotalDamagePct)
    setValue(thisObj, 'txtTotalKrnls', FormatNumber(TotalKrnlsValue).toString());
  }

  const setTotalKernalsHulls = (SMKRSPct, SoundSplitsPct, OtherKernelsPct, TotalDamagePct, HullsPct) => {
    SMKRSPct = (SMKRSPct != undefined && SMKRSPct != "" && SMKRSPct != null) ? Number(SMKRSPct) : 0;
    SoundSplitsPct = (SoundSplitsPct != undefined && SoundSplitsPct != "" && SoundSplitsPct != null) ? Number(SoundSplitsPct) : 0;
    OtherKernelsPct = (OtherKernelsPct != undefined && OtherKernelsPct != "" && OtherKernelsPct != null) ? Number(OtherKernelsPct) : 0;
    TotalDamagePct = (TotalDamagePct != undefined && TotalDamagePct != "" && TotalDamagePct != null) ? Number(TotalDamagePct) : 0;

    let TotalKrnlsValue = Number(SMKRSPct) + Number(SoundSplitsPct) + Number(OtherKernelsPct) + Number(TotalDamagePct)

    if (TotalKrnlsValue == undefined || TotalKrnlsValue == '' || TotalKrnlsValue == null) {
      TotalKrnlsValue = 0;
    }
    if (HullsPct == undefined || HullsPct == '' || HullsPct == null) {
      HullsPct = 0;
    }
    let TotalKernelsHulls = FormatNumber(Number(TotalKrnlsValue) + Number(HullsPct));
    setValue(thisObj, 'txtTotalKernelsHulls', TotalKernelsHulls.toString());
  }

  // #region 'bSaveInspection'
  const bSaveInspection = async (StatusTag) => {
    let bSaveInspection = false;

    let gridArray = getValue(thisObj, 'gridWeightTickets')
    if (gridArray.length >= 1) {
      let WtOfVehicle = getValue(thisObj, 'txtWtOfVehicle');;
      if (WtOfVehicle == '' || WtOfVehicle == null || WtOfVehicle == undefined) {
        showMessage(thisObj, 'Vehicle Weight must be numeric! Please check the scale ticket.')
        return false;
      }

      if (WtOfVehicle <= 0) {
        showMessage(thisObj, 'Vehicle Weight must be greater than zero! Please check the scale ticket')
        return false;
      }

      let WtIncludingVehicle = getValue(thisObj, 'txtWtIncludingVehicle');
      if (WtIncludingVehicle == '' || WtIncludingVehicle == undefined || WtIncludingVehicle == null) {
        showMessage(thisObj, 'Weight Including Vehicle must be numeric! Please check the scale ticket.')
        return false;
      }
      if (WtIncludingVehicle <= 0) {
        showMessage(thisObj, 'Weight Including Vehicle must be greater than zero! Please check the scale ticket.')
        return false;
      }
    }


    let buyingPoint = getValue(thisObj, "ddLocation");
    let txtSC95 = getValue(thisObj, 'txtSC95');
    // let insP_DATE_TIME = ConvertToDate(getValue(thisObj, 'txtInspectionDateTime'));
    let insP_DATE_TIME = getValue(thisObj, 'txtInspectionDateTime');
    let settlemenT_NUM = getValue(thisObj, 'txt1007');
    if (settlemenT_NUM == undefined || settlemenT_NUM == '' || settlemenT_NUM == null) {
      settlemenT_NUM = 0;
    }
    let insP_STATUS = StatusTag;
    // let insP_STATUS = 'P';
    // region 'State Abriviation'
    let StateList = thisObj.state['ddState'].valueList;
    let SelectedStateValue = thisObj.values['ddState'];

    let StateAbrr = null
    if (SelectedStateValue !== "" && SelectedStateValue !== undefined) {
      StateAbrr = StateList.find(elem => elem.key == SelectedStateValue).stateAbbrevations;
    }
    let statE_ABBR = StateAbrr;
    // region 'State Abriviation'

    // region 'County Id'
    let countY_ID = thisObj.values['ddCounty'];
    if (countY_ID !== "" && countY_ID !== undefined) {
      countY_ID = countY_ID.split('-')[0];
    }
    // end region
    let farmDetaIL=getValue(thisObj, 'ddFarm')
    let farM_ID = farmDetaIL.split('-')[0]
    let Farm_suffix = '';
    if (farM_ID !== undefined && farM_ID !== '') {
      let farmList = thisObj.state['ddFarm'].valueList;
      Farm_suffix = farmList.find(elem => elem.key == farmDetaIL).farmSuffix;
    }
    let pnuT_TYPE_ID = getValue(thisObj, 'ddPeanutType');
    let pnuT_VARIETY_ID = getValue(thisObj, 'ddPeanutVariety');
    let seeD_GEN = getValue(thisObj, 'ddSeedGen');
    if (seeD_GEN == undefined || seeD_GEN == null) {
      seeD_GEN = '';
    }
    let seeD_MEETING_SPEC_IND1 = getValue(thisObj, 'txtSeedMeetingSpec');
    let seeD_MEETING_SPEC_IND = document.getElementById('txtSeedMeetingSpec').value;
    if (seeD_MEETING_SPEC_IND == 'No') {
      seeD_MEETING_SPEC_IND = 'N';
    } else if (seeD_MEETING_SPEC_IND == 'Yes') {
      seeD_MEETING_SPEC_IND = 'Y';
    } else {
      seeD_MEETING_SPEC_IND = '';
    }
    let probE_PATTERN = getValue(thisObj, 'txtProbingPattern');
    if (probE_PATTERN == undefined || probE_PATTERN == null) {
      probE_PATTERN = '';
    }
    let seG_TYPE = getValue(thisObj, 'ddSeg');
    let ediblE_OIL_IND = getValue(thisObj, 'ddEdibleOil');
    let oleiC_IND = getValue(thisObj, 'ddOleic');
    let organiC_IND = getValue(thisObj, 'chkboxOrganic') == false ? 'N' : 'Y';
    let corN_IND = getValue(thisObj, 'chkboxCornFound') == false ? 'N' : 'Y';
    let flavuS_IND = getValue(thisObj, 'chkboxFlavusFound') == false ? 'N' : 'Y';
    let vicaM_IND = getValue(thisObj, 'chkboxVicam') == false ? 'N' : 'Y';
    if (vicaM_IND == undefined || vicaM_IND == null) {
      vicaM_IND = '';
    }
    let vicaM_PPB = getValue(thisObj, 'txtReading');
    if (vicaM_PPB == undefined || vicaM_PPB == '' || vicaM_PPB == null) {
      vicaM_PPB = 0;
    }
    let obL_BUY_ID = getValue(thisObj, 'ddBuyingPt');
    let obL_WHSE_ID = getValue(thisObj, 'ddObligationPt');
    let obL_BIN_ID = '';
    if (obL_WHSE_ID !== undefined && obL_WHSE_ID !== '' && obL_WHSE_ID !== null) {
      let obligationList = thisObj.state['ddObligationPt'].valueList;
      obL_BIN_ID = obligationList.find(e => e.key == obL_WHSE_ID).binNum;
    }
    let unlD_WHSE_ID = getValue(thisObj, 'ddWhouseBin');
    let unlD_BIN_ID = '';
    if (unlD_WHSE_ID !== undefined && unlD_WHSE_ID !== '' && unlD_WHSE_ID !== null) {
      let warehouseBinNumList = thisObj.state['ddWhouseBin'].valueList;
      unlD_BIN_ID = warehouseBinNumList.find(e => e.key == unlD_WHSE_ID).binNum;
    }

    let fM_SAMPLE = getValue(thisObj, 'txtFMSampleWt');
    if (fM_SAMPLE == undefined || fM_SAMPLE == '' || fM_SAMPLE == null || fM_SAMPLE == "0.00") {
      fM_SAMPLE = 0;
    }
    let clN_SAMPLE = getValue(thisObj, 'txtCleanSampleWt');
    if (clN_SAMPLE == undefined || clN_SAMPLE == '' || clN_SAMPLE == null || clN_SAMPLE == "0.00") {
      clN_SAMPLE = 0;
    }
    let smK_GR = getValue(thisObj, 'txtSMKRSGr');
    if (smK_GR == undefined || smK_GR == '' || smK_GR == null || smK_GR == "0.00") {
      smK_GR = 0;
    }
    let sS_GR = getValue(thisObj, 'txtSoundSplitsGr');
    if (sS_GR == undefined || sS_GR == '' || sS_GR == null || sS_GR == "0.00") {
      sS_GR = 0;
    }
    let oK_GR = getValue(thisObj, 'txtOtherKernelsGr');
    if (oK_GR == undefined || oK_GR == '' || oK_GR == null || oK_GR == "0.00") {
      oK_GR = 0;
    }
    let daM_SPLIT_GR = getValue(thisObj, 'txtDamageSplits');
    if (daM_SPLIT_GR == undefined || daM_SPLIT_GR == '' || daM_SPLIT_GR == null || daM_SPLIT_GR == "0.00") {
      daM_SPLIT_GR = 0;
    }

    let daM_GR = getValue(thisObj, 'txtTotalDamageGr');
    if (daM_GR == undefined || daM_GR == '' || daM_GR == null || daM_GR == "0.00") {
      daM_GR = 0;
    }
    let hulL_GR = getValue(thisObj, 'txtHullsGr');
    if (hulL_GR == undefined || hulL_GR == '' || hulL_GR == null || hulL_GR == "0.00") {
      hulL_GR = 0;
    }
    let freZ_DAM_GR = getValue(thisObj, 'txtFreezeDamageGr');
    if (freZ_DAM_GR == undefined || freZ_DAM_GR == '' || freZ_DAM_GR == null || freZ_DAM_GR == "0.00") {
      freZ_DAM_GR = 0;
    }
    let cncL_RMD_GR = getValue(thisObj, 'txtConcealedRMDGr');
    if (cncL_RMD_GR == undefined || cncL_RMD_GR == '' || cncL_RMD_GR == null || cncL_RMD_GR == "0.00") {
      cncL_RMD_GR = 0;
    }
    let cR_BR_GR = getValue(thisObj, 'txtCrkOrBrkShellsGr');
    if (cR_BR_GR == undefined || cR_BR_GR == '' || cR_BR_GR == null || cR_BR_GR == "0.00") {
      cR_BR_GR = 0;
    }
    let dcoL_SHEL_GR = getValue(thisObj, 'txtDiscoloredShellsGr');
    if (dcoL_SHEL_GR == undefined || dcoL_SHEL_GR == '' || dcoL_SHEL_GR == null || dcoL_SHEL_GR == "0.00") {
      dcoL_SHEL_GR = 0;
    }
    let huL_BRIT_GR = getValue(thisObj, 'txtHullBrightness');
    if (huL_BRIT_GR == undefined || huL_BRIT_GR == '' || huL_BRIT_GR == null || huL_BRIT_GR == "0.00") {
      huL_BRIT_GR = 0;
    }
    let jumbO_GR = getValue(thisObj, 'txtJumboGr');
    if (jumbO_GR == undefined || jumbO_GR == '' || jumbO_GR == null || jumbO_GR == "0.00") {
      jumbO_GR = 0;
    }
    let elK_GR = getValue(thisObj, 'txtELKGr');
    if (elK_GR == undefined || elK_GR == '' || elK_GR == null || elK_GR == "0.00") {
      elK_GR = 0;
    }
    let fM_GR = getValue(thisObj, 'txtForeignMaterialGr');
    if (fM_GR == undefined || fM_GR == '' || fM_GR == null || fM_GR == "0.00") {
      fM_GR = 0;
    }
    let lsK_GR = getValue(thisObj, 'txtLSKGr');
    if (lsK_GR == undefined || lsK_GR == '' || lsK_GR == null || lsK_GR == "0.00") {
      lsK_GR = 0;
    }
    let faN_GR = getValue(thisObj, 'txtFancyGr');
    if (faN_GR == undefined || faN_GR == '' || faN_GR == null || faN_GR == "0.00") {
      faN_GR = 0;
    }
    let smK_PCT = getValue(thisObj, 'txtSMKRSPct');
    if (smK_PCT == undefined || smK_PCT == '' || smK_PCT == null || smK_PCT == "0.00") {
      smK_PCT = 0;
    }
    let smK_1_PCT = getValue(thisObj, 'txtSMK1Pct');
    if (smK_1_PCT == undefined || smK_1_PCT == '' || smK_1_PCT == null || smK_1_PCT == "0.00") {
      smK_1_PCT = 0;
    }
    let smK_2_PCT = getValue(thisObj, 'txtSMK2Pct');
    if (smK_2_PCT == undefined || smK_2_PCT == '' || smK_2_PCT == null || smK_2_PCT == "0.00") {
      smK_2_PCT = 0;
    }
    let smK_3_PCT = getValue(thisObj, 'txtSMK3Pct');
    if (smK_3_PCT == undefined || smK_3_PCT == '' || smK_3_PCT == null || smK_3_PCT == "0.00") {
      smK_3_PCT = 0;
    }
    let smK_4_PCT = getValue(thisObj, 'txtSMK4Pct');
    if (smK_4_PCT == undefined || smK_4_PCT == '' || smK_4_PCT == null || smK_4_PCT == "0.00") {
      smK_4_PCT = 0;
    }
    let sS_PCT = getValue(thisObj, 'txtSoundSplitsPct');
    if (sS_PCT == undefined || sS_PCT == '' || sS_PCT == null || sS_PCT == "0.00") {
      sS_PCT = 0;
    }
    let oK_PCT = getValue(thisObj, 'txtOtherKernelsPct');
    if (oK_PCT == undefined || oK_PCT == '' || oK_PCT == null || oK_PCT == "0.00") {
      oK_PCT = 0;
    }
    let daM_PCT = getValue(thisObj, 'txtTotalDamagePct');
    if (daM_PCT == undefined || daM_PCT == '' || daM_PCT == null || daM_PCT == "0.00") {
      daM_PCT = 0;
    }

    let tkC_PCT = getData(thisObj, 'tkc_pct_FV95');
    if (tkC_PCT == undefined || tkC_PCT == '' || tkC_PCT == null || tkC_PCT == "0.00") {
      tkC_PCT = 0;
    }
    let hulL_PCT = getValue(thisObj, 'txtHullsPct');
    if (hulL_PCT == undefined || hulL_PCT == '' || hulL_PCT == null || hulL_PCT == "0.00") {
      hulL_PCT = 0;
    }
    let freZ_DAM_PCT = getValue(thisObj, 'txtFreezeDamagePct');
    if (freZ_DAM_PCT == undefined || freZ_DAM_PCT == '' || freZ_DAM_PCT == null || freZ_DAM_PCT == "0.00") {
      freZ_DAM_PCT = 0;
    }
    let cncL_RMD_PCT = getValue(thisObj, 'txtConcealedRMDPct');
    if (cncL_RMD_PCT == undefined || cncL_RMD_PCT == '' || cncL_RMD_PCT == null || cncL_RMD_PCT == "0.00") {
      cncL_RMD_PCT = 0;
    }
    let cR_BR_PCT = 0;
    let dcoL_SHEL_PCT = 0;
    let huL_BRIT_PCT = getValue(thisObj, 'txtHullBrightnessPct');
    if (huL_BRIT_PCT == undefined || huL_BRIT_PCT == '' || huL_BRIT_PCT == null || huL_BRIT_PCT == "0.00") {
      huL_BRIT_PCT = 0;
    }
    let jumbO_PCT = getValue(thisObj, 'txtJumboPct');
    if (jumbO_PCT == undefined || jumbO_PCT == '' || jumbO_PCT == null || jumbO_PCT == "0.00") {
      jumbO_PCT = 0;
    }
    let elK_PCT = getValue(thisObj, 'txtELKPct');
    if (elK_PCT == undefined || elK_PCT == '' || elK_PCT == null || elK_PCT == "0.00") {
      elK_PCT = 0;
    }
    let fM_PCT = getValue(thisObj, 'txtForeignMaterialPct');
    if (fM_PCT == undefined || fM_PCT == '' || fM_PCT == null || fM_PCT == "0.00") {
      fM_PCT = 0;
    }
    let lsK_PCT = getValue(thisObj, 'txtLSKPct');
    if (lsK_PCT == undefined || lsK_PCT == '' || lsK_PCT == null || lsK_PCT == "0.00") {
      lsK_PCT = 0;
    }
    let moisT_PCT = getValue(thisObj, 'txtMoisture');
    if (moisT_PCT == undefined || moisT_PCT == '' || moisT_PCT == null || moisT_PCT == "0.00") {
      moisT_PCT = 0;
    }
    let faN_PCT = getValue(thisObj, 'txtFancyPct');
    if (faN_PCT == undefined || faN_PCT == '' || faN_PCT == null || faN_PCT == "0.00") {
      faN_PCT = 0;
    }
    let otH_FM = getValue(thisObj, 'txtOtherForeignMaterials');
    if (otH_FM == undefined || otH_FM == null) {
      otH_FM = '';
    }
    let smK_1_GUAGE = getValue(thisObj, 'txtSMK1Gr');
    let smK_2_GUAGE = getValue(thisObj, 'txtSMK2Gr');
    let smK_3_GUAGE = getValue(thisObj, 'txtSMK3Gr');
    let smK_4_GUAGE = getValue(thisObj, 'txtSMK4Gr');
    let tarE_WT = getValue(thisObj, 'txtWtIncludingVehicle');
    if (tarE_WT == undefined || tarE_WT == '' || tarE_WT == null || tarE_WT == "0.00") {
      tarE_WT = 0;
    }
    let grosS_WT = getValue(thisObj, 'txtGrossWeight');
    if (grosS_WT == undefined || grosS_WT == '' || grosS_WT == null || grosS_WT == "0.00") {
      grosS_WT = 0;
    }
    let fM_WT = getValue(thisObj, 'txtForeignMaterial');
    if (fM_WT == undefined || fM_WT == '' || fM_WT == null || fM_WT == "0.00") {
      fM_WT = 0;
    }
    let eX_MOIST_WT = getValue(thisObj, 'txtExcessMoisture');
    if (eX_MOIST_WT == undefined || eX_MOIST_WT == '' || eX_MOIST_WT == null || eX_MOIST_WT == "0.00") {
      eX_MOIST_WT = 0;
    }
    let neT_WT = getValue(thisObj, 'txtNetWeight');
    if (neT_WT == undefined || neT_WT == '' || neT_WT == null || neT_WT == "0.00" || neT_WT == "0") {
      neT_WT = 0;
    }
    let lsK_WT = getValue(thisObj, 'txtLSKvc');
    if (lsK_WT == undefined || lsK_WT == '' || lsK_WT == null || lsK_WT == "0.00" || lsK_WT == "0") {
      lsK_WT = 0;
    }
    let kerN_VALUE = getValue(thisObj, 'txtKernelValueTon');
    if (kerN_VALUE == undefined || kerN_VALUE == '' || kerN_VALUE == null || kerN_VALUE == "0.00") {
      kerN_VALUE = 0;
    }
    let elK_PREM = getValue(thisObj, 'txtELKPremiumTon');
    if (elK_PREM == undefined || elK_PREM == '' || elK_PREM == null || elK_PREM == "0.00") {
      elK_PREM = 0;
    }
    let daM_DISCOUNT = getValue(thisObj, 'txtDamageTon');
    if (daM_DISCOUNT == undefined || daM_DISCOUNT == '' || daM_DISCOUNT == null || daM_DISCOUNT == "0.00") {
      daM_DISCOUNT = 0;
    }
    let eX_FM_DISCOUNT = getValue(thisObj, 'txtAccessFMTon');
    if (eX_FM_DISCOUNT == undefined || eX_FM_DISCOUNT == '' || eX_FM_DISCOUNT == null || eX_FM_DISCOUNT == "0.00") {
      eX_FM_DISCOUNT = 0;
    }
    let eX_SS_DISCOUNT = getValue(thisObj, 'txtExcessSplitsTon');
    if (eX_SS_DISCOUNT == undefined || eX_SS_DISCOUNT == '' || eX_SS_DISCOUNT == null || eX_SS_DISCOUNT == "0.00") {
      eX_SS_DISCOUNT = 0;
    }
    let flavuS_DISCOUNT = getValue(thisObj, 'txtAFlavusTon');
    if (flavuS_DISCOUNT == undefined || flavuS_DISCOUNT == '' || flavuS_DISCOUNT == null || flavuS_DISCOUNT == "0.00") {
      flavuS_DISCOUNT = 0;
    }
    let neT_LES_LSK_VAL = getValue(thisObj, 'txtNetValPerTonExclSLK');
    if (neT_LES_LSK_VAL == undefined || neT_LES_LSK_VAL == '' || neT_LES_LSK_VAL == null || neT_LES_LSK_VAL == "0.00") {
      neT_LES_LSK_VAL = 0;
    }
    let neT_PRICE = getValue(thisObj, 'txtG');
    if (neT_PRICE == undefined || neT_PRICE == '' || neT_PRICE == null || neT_PRICE == "0.00") {
      neT_PRICE = 0;
    } else {
      neT_PRICE = neT_PRICE / 100;
    }
    let basiS_GRADE_AMT = getValue(thisObj, 'txtTotalLoadVal');
    if (basiS_GRADE_AMT == undefined || basiS_GRADE_AMT == '' || basiS_GRADE_AMT == null || basiS_GRADE_AMT == "0.00" || basiS_GRADE_AMT == "0" || isNaN(basiS_GRADE_AMT)) {
      basiS_GRADE_AMT = 0;
    }
//PRB0054567_INC6094236_Fix for calculating correct Basis_grade_wt for all SC95 #
let dblTotalSharePCT = 0;
let objVendors = [];
for (let i = 1; i <= 20; i++) {
  let vendor = getValue(thisObj, "ddVendor" + (i - 1));
  let remitTo = getValue(thisObj, "ddRemitTo" + (i - 1));
  let txtSharePct = getValue(thisObj, "txtSharePct" + (i - 1));
  if (vendor !== undefined && vendor !== null && vendor !== '' && vendor.length > 0) {
    dblTotalSharePCT = Number(dblTotalSharePCT) + Number(txtSharePct);
    let netWeight = Math.floor(neT_WT * Number(txtSharePct) * 0.01);
    let obj = {
      "gross_weight": Math.floor(grosS_WT * Number(txtSharePct) * 0.01),
      "net_weight": netWeight,
      "lsk_weight": Math.floor(lsK_WT * Number(txtSharePct) * 0.01),
      "net_price": parseFloat((netWeight * neT_PRICE).toFixed(2)),
      "vendorNumber": vendor[0],
      "remitTo": remitTo,
      "share": txtSharePct,
      "CMAParticipant": 'N',
      "totalSharePCT": dblTotalSharePCT
    }
    objVendors.push(obj);
  }
}

    if (dblTotalSharePCT !== 100) {
      showMessage(thisObj, 'Farmer Vendor share percents must total to 100%. Please check share percentages!');
      return false;
    }

    let dbGrossWeight = 0;
    for (let i = 0; i < objVendors.length; i++) {
      dbGrossWeight = Number(dbGrossWeight) + Number(objVendors[i].gross_weight);
    }
    if (dbGrossWeight !== Number(grosS_WT)) {
      for (let i = 0; i < objVendors.length; i++) {
        if (dbGrossWeight < Number(grosS_WT)) {
          objVendors[i].gross_weight = objVendors[i].gross_weight + 1;
          dbGrossWeight = dbGrossWeight + 1;
        } else if (dbGrossWeight > Number(grosS_WT)) {
          objVendors[i].gross_weight = objVendors[i].gross_weight - 1;
          dbGrossWeight = dbGrossWeight - 1;
        }
      }
    }
//PRB0054567_INC6094236_Fix for calculating correct Basis_grade_wt for all SC95 #

    let dbNetWeight = 0;
    for (let i = 0; i < objVendors.length; i++) {
      dbNetWeight = Number(dbNetWeight) + Number(objVendors[i].net_weight);
    }
    if (dbNetWeight !== Number(neT_WT)) {
      let netPrice = getValue(thisObj, 'txtG');
      if (netPrice == undefined || netPrice == '' || netPrice == null) {
        netPrice = 0;
      }
      for (let i = 0; i < objVendors.length; i++) {
        if (dbNetWeight < Number(neT_WT)) {
          objVendors[i].net_weight = objVendors[i].net_weight + 1;
          objVendors[i].net_price = parseFloat((objVendors[i].net_weight * (Number(netPrice) * 0.01)).toFixed(2));  
          dbNetWeight = dbNetWeight + 1;
        } else if (dbNetWeight > Number(grosS_WT)) {
          objVendors[i].net_weight = objVendors[i].net_weight - 1;
          objVendors[i].net_price = parseFloat((objVendors[i].net_weight * (Number(netPrice) * 0.01)).toFixed(2));  
          dbNetWeight = dbNetWeight - 1;
        }
      }
    }

    let dblLsk = 0;
    for (let i = 0; i < objVendors.length; i++) {
      dblLsk = Number(dblLsk) + Number(objVendors[i].lsk_weight);
    }
    if (dblLsk !== Number(lsK_WT)) {
      for (let i = 0; i < objVendors.length; i++) {
        if (dblLsk < Number(lsK_WT)) {
          objVendors[i].lsk_weight = objVendors[i].lsk_weight + 1;
          dblLsk = dblLsk + 1;
        } else if (dblLsk > Number(grosS_WT)) {
          objVendors[i].lsk_weight = objVendors[i].lsk_weight - 1;
          dblLsk = dblLsk - 1;
        }
      }
    }
//PRB0054567_INC6094236_Fix for calculating correct Basis_grade_wt for all SC95 #
  let dblTotalValueOfLoad = 0;
  let maxWeightIndex = 0;
  let maxWeight = objVendors[0].net_weight;
  // Calculate total value and find vendor with maximum weight
  for (let i = 0; i < objVendors.length; i++) {
    // Sum up the net prices to get the total value of the load
    dblTotalValueOfLoad =
      Number(dblTotalValueOfLoad) + parseFloat(objVendors[i].net_price);
    // identifying the vendor with the maximum weight assigned.
    if (objVendors[i].net_weight > maxWeight) {
      maxWeight = objVendors[i].net_weight;
      maxWeightIndex = i;
    }
  }

  // Calculating the adjustment needed to match basiS_GRADE_AMT total amount
  let adjustment = Number(basiS_GRADE_AMT) - dblTotalValueOfLoad;
  // Adjusting the vendor with the maximum weight or to the first vendor in case of same weight (50/50) by the exact difference of a penny or remaining few pennies.
  if (Math.abs(adjustment) > 0.0) {
    // Adjusting the vendor with the maximum weight by the exact difference of a penny or remaining few pennies.
    objVendors[maxWeightIndex].net_price = (
      parseFloat(objVendors[maxWeightIndex].net_price) + adjustment
    ).toFixed(2);
  }
    

    let cmA_VENDOR_NUM = getValue(thisObj, 'txtCMAVendor');
    if (cmA_VENDOR_NUM == undefined || cmA_VENDOR_NUM == null) {
      cmA_VENDOR_NUM = '';
    }
    let cmA_REMIT_TO = getValue(thisObj, 'ddRemitTo');
    if (cmA_REMIT_TO == undefined || cmA_REMIT_TO == null) {
      cmA_REMIT_TO = '';
    }
    let remarks = getValue(thisObj, 'txtarInspectorRemarks');

    let hmC_IND = getValue(thisObj, 'radioHighMoisture') == '1' ? 'Y' : 'N';
    let hseg = getValue(thisObj, 'txtSeg');
    if (hseg == undefined || hseg == '' || hseg == null) {
      hseg = 0;
    }
    let hsmK_PCT = getValue(thisObj, 'txtSMK');
    if (hsmK_PCT == undefined || hsmK_PCT == '' || hsmK_PCT == null || hsmK_PCT == "0.00") {
      hsmK_PCT = 0;
    }
    let hsS_PCT = getValue(thisObj, 'txtSS');
    if (hsS_PCT == undefined || hsS_PCT == '' || hsS_PCT == null || hsS_PCT == "0.00") {
      hsS_PCT = 0;
    }
    let hoK_PCT = getValue(thisObj, 'txtOK');
    if (hoK_PCT == undefined || hoK_PCT == '' || hoK_PCT == null || hoK_PCT == "0.00") {
      hoK_PCT = 0;
    }
    let hdaM_PCT = getValue(thisObj, 'txtDAM');
    if (hdaM_PCT == undefined || hdaM_PCT == '' || hdaM_PCT == null || hdaM_PCT == "0.00") {
      hdaM_PCT = 0;
    }
    let hhulL_PCT = getValue(thisObj, 'txtHULLS');
    if (hhulL_PCT == undefined || hhulL_PCT == '' || hhulL_PCT == null || hhulL_PCT == "0.00") {
      hhulL_PCT = 0;
    }
    let hfreZ_DAM_PCT = getValue(thisObj, 'txtFRZ');
    if (hfreZ_DAM_PCT == undefined || hfreZ_DAM_PCT == '' || hfreZ_DAM_PCT == null || hfreZ_DAM_PCT == "0.00") {
      hfreZ_DAM_PCT = 0;
    }
    let hcncL_RMD_PCT = getValue(thisObj, 'txtCRMD');
    if (hcncL_RMD_PCT == undefined || hcncL_RMD_PCT == '' || hcncL_RMD_PCT == null || hcncL_RMD_PCT == "0.00") {
      hcncL_RMD_PCT = 0;
    }
    let helK_PCT = getValue(thisObj, 'txtELK');
    if (helK_PCT == undefined || helK_PCT == '' || helK_PCT == null || helK_PCT == "0.00") {
      helK_PCT = 0;
    }
    let hfM_PCT = getValue(thisObj, 'txtFM');
    if (hfM_PCT == undefined || hfM_PCT == '' || hfM_PCT == null || hfM_PCT == "0.00") {
      hfM_PCT = 0;
    }
    let hlsK_PCT = getValue(thisObj, 'txtLSK');
    if (hlsK_PCT == undefined || hlsK_PCT == '' || hlsK_PCT == null || hlsK_PCT == "0.00") {
      hlsK_PCT = 0;
    }
    let hfaN_PCT = getValue(thisObj, 'txtFANCY');
    if (hfaN_PCT == undefined || hfaN_PCT == '' || hfaN_PCT == null || hfaN_PCT == "0.00") {
      hfaN_PCT = 0;
    }
    let kerneL_RELS = getValue(thisObj, 'txtKernelsREL');
    if (kerneL_RELS == undefined || kerneL_RELS == '' || kerneL_RELS == null || kerneL_RELS == "0.00") {
      kerneL_RELS = 0;
    }
    let kerneL_RPS = getValue(thisObj, 'txtKernelsRPS');
    if (kerneL_RPS == undefined || kerneL_RPS == '' || kerneL_RPS == null || kerneL_RPS == "0.00") {
      kerneL_RPS = 0;
    }
    let meteR_READ = getValue(thisObj, 'txtMeterReading');
    if (meteR_READ == undefined || meteR_READ == '' || meteR_READ == null || meteR_READ == "0.00") {
      meteR_READ = 0;
    }
    let bluepaN_WT = getValue(thisObj, 'txtBluePanWt');
    if (bluepaN_WT == undefined || bluepaN_WT == '' || bluepaN_WT == null || bluepaN_WT == "0.00") {
      bluepaN_WT = 0;
    }
    let damageD_KRS = getValue(thisObj, 'txtDamagedKRS');
    if (damageD_KRS == undefined || damageD_KRS == '' || damageD_KRS == null || damageD_KRS == "0.00") {
      damageD_KRS = 0;
    }
    let elK_DAMAGED = getValue(thisObj, 'txtELKDamage');
    if (elK_DAMAGED == undefined || elK_DAMAGED == '' || elK_DAMAGED == null || elK_DAMAGED == "0.00") {
      elK_DAMAGED = 0;
    }
    
    let handleR_LOC_NUM_2 = getValue(thisObj, 'txtNumber');
    if (handleR_LOC_NUM_2 == undefined || handleR_LOC_NUM_2 == null) {
      handleR_LOC_NUM_2 = '';
    }
    let handleR_LOC_NAME_2 = getValue(thisObj, 'txtName');
    if (handleR_LOC_NAME_2 == undefined || handleR_LOC_NAME_2 == null) {
      handleR_LOC_NAME_2 = '';
    }
    let vA_CR_BR_SAMPLE = 0;
    let vA_CR_BR_GRAM = 0;
    let vA_CR_BR_PCT = 0;
    if (pnuT_TYPE_ID == 'VA') {
      vA_CR_BR_SAMPLE = getValue(thisObj, 'txtSampleWt');
      if (vA_CR_BR_SAMPLE == undefined || vA_CR_BR_SAMPLE == '' || vA_CR_BR_SAMPLE == null || vA_CR_BR_SAMPLE == "0.00") {
        vA_CR_BR_SAMPLE = 0;
      }
      vA_CR_BR_GRAM = getValue(thisObj, 'txtGrams');
      if (vA_CR_BR_GRAM == undefined || vA_CR_BR_GRAM == '' || vA_CR_BR_GRAM == null || vA_CR_BR_GRAM == "0.00") {
        vA_CR_BR_GRAM = 0;
      }
      vA_CR_BR_PCT = getValue(thisObj, 'txtPercent');
      if (vA_CR_BR_PCT == undefined || vA_CR_BR_PCT == '' || vA_CR_BR_PCT == null || vA_CR_BR_PCT == "0.00") {
        vA_CR_BR_PCT = 0;
      }
    }
    let vL_CLN_SAMPLE = getValue(thisObj, 'txtValenciaCleanSampleWt');
    if (vL_CLN_SAMPLE == undefined || vL_CLN_SAMPLE == '' || vL_CLN_SAMPLE == null || vL_CLN_SAMPLE == "0.00") {
      vL_CLN_SAMPLE = 0;
    }


    let Visible = isVisible(thisObj, 'ddIrrigatedDryland');
    let irR_DRY_IND = '';
    // Visible = false;
    irR_DRY_IND = Visible == true ? getValue(thisObj, 'ddIrrigatedDryland') : '';

    // Grid rows count logic

    // Project Suffix

    let objSave = {
      "insP_DATE_TIME": insP_DATE_TIME,
      "insP_STATUS": insP_STATUS,
      "statE_ABBR": statE_ABBR,
      "countY_ID": countY_ID,
      "farM_ID": farM_ID,
      "farM_SUFFIX": Farm_suffix,
      "pnuT_TYPE_ID": pnuT_TYPE_ID,
      "pnuT_VARIETY_ID": pnuT_VARIETY_ID,
      "seeD_GEN": seeD_GEN,
      "seeD_MEETING_SPEC_IND": seeD_MEETING_SPEC_IND,
      "probE_PATTERN": probE_PATTERN,
      "seG_TYPE": seG_TYPE,
      "ediblE_OIL_IND": ediblE_OIL_IND,
      "oleiC_IND": oleiC_IND,
      "organiC_IND": organiC_IND,
      "corN_IND": corN_IND,
      "flavuS_IND": flavuS_IND,
      "vicaM_IND": vicaM_IND,
      "vicaM_PPB": vicaM_PPB,
      "obL_BUY_ID": obL_BUY_ID,
      "obL_WHSE_ID": obL_WHSE_ID,
      "obL_BIN_ID": obL_BIN_ID,
      "unlD_WHSE_ID": unlD_WHSE_ID,
      "unlD_BIN_ID": unlD_BIN_ID,
      "fM_SAMPLE": fM_SAMPLE,
      "clN_SAMPLE": clN_SAMPLE,
      "smK_GR": smK_GR,
      "sS_GR": sS_GR,
      "oK_GR": oK_GR,
      "daM_SPLIT_GR": daM_SPLIT_GR,
      "daM_GR": daM_GR,
      "hulL_GR": hulL_GR,
      "freZ_DAM_GR": freZ_DAM_GR,
      "cncL_RMD_GR": cncL_RMD_GR,
      "cR_BR_GR": cR_BR_GR,
      "dcoL_SHEL_GR": dcoL_SHEL_GR,
      "huL_BRIT_GR": huL_BRIT_GR,
      "jumbO_GR": jumbO_GR,
      "elK_GR": elK_GR,
      "fM_GR": fM_GR,
      "lsK_GR": lsK_GR,
      "faN_GR": faN_GR,
      "smK_PCT": smK_PCT,
      "smK_1_PCT": smK_1_PCT,
      "smK_2_PCT": smK_2_PCT,
      "smK_3_PCT": smK_3_PCT,
      "smK_4_PCT": smK_4_PCT,
      "sS_PCT": sS_PCT,
      "oK_PCT": oK_PCT,
      "daM_PCT": daM_PCT,
      "tkC_PCT": tkC_PCT,
      "hulL_PCT": hulL_PCT,
      "freZ_DAM_PCT": freZ_DAM_PCT,
      "cncL_RMD_PCT": cncL_RMD_PCT,
      "cR_BR_PCT": cR_BR_PCT,
      "dcoL_SHEL_PCT": dcoL_SHEL_PCT,
      "huL_BRIT_PCT": huL_BRIT_PCT,
      "jumbO_PCT": jumbO_PCT,
      "elK_PCT": elK_PCT,
      "fM_PCT": fM_PCT,
      "lsK_PCT": lsK_PCT,
      "moisT_PCT": moisT_PCT,
      "faN_PCT": faN_PCT,
      "otH_FM": otH_FM,
      "smK_1_GUAGE": smK_1_GUAGE,
      "smK_2_GUAGE": smK_2_GUAGE,
      "smK_3_GUAGE": smK_3_GUAGE,
      "smK_4_GUAGE": smK_4_GUAGE,
      "tarE_WT": tarE_WT,
      "grosS_WT": grosS_WT,
      "fM_WT": fM_WT,
      "eX_MOIST_WT": eX_MOIST_WT,
      "neT_WT": neT_WT,
      "lsK_WT": lsK_WT,
      "kerN_VALUE": kerN_VALUE,
      "elK_PREM": elK_PREM,
      "daM_DISCOUNT": daM_DISCOUNT,
      "eX_FM_DISCOUNT": eX_FM_DISCOUNT,
      "eX_SS_DISCOUNT": eX_SS_DISCOUNT,
      "flavuS_DISCOUNT": flavuS_DISCOUNT,
      "neT_LES_LSK_VAL": neT_LES_LSK_VAL,
      "neT_PRICE": neT_PRICE,
      "basiS_GRADE_AMT": basiS_GRADE_AMT,
      "cmA_VENDOR_NUM": cmA_VENDOR_NUM,
      "cmA_REMIT_TO": cmA_REMIT_TO,
      "remarks": remarks == undefined ? '' : remarks == null ? '' : remarks,
      "useR_ID": sessionStorage.getItem('userid'),

      "tickeT_NUMBERS_CURE_CLEAN": sTICKET_NUMBERS_CURE_CLEAN,
      "tickeT_CURING_WT_IND": sTICKET_CURING_WT_IND,
      "tickeT_CLEANING_WT_IND": sTICKET_CLEANING_WT_IND,
      "tickeT_MOISTURE": sTICKET_MOISTURE,
      "tickeT_CLEAN_WT_OTHER": sTICKET_CLEAN_WT_OTHER,
      "tickeT_CURE_WT_OTHER": sTICKET_CURE_WT_OTHER,
      "tickeT_CURE_SCHEDULE": sTICKET_CURE_SCHEDULE,
      "tickeT_CLEAN_SCHEDULE": sTICKET_CLEAN_SCHEDULE,
      "tickeT_TOTAL_CURING_COST": sTICKET_TOTAL_CURING_COST,
      "tickeT_TOTAL_CLEANING_COST": sTICKET_TOTAL_CLEANING_COST,

      "lasT_DATE_TIME": lblLastChange,

      "hmC_IND": hmC_IND,
      "hseg": hseg,
      "hsmK_PCT": hsmK_PCT,
      "hsS_PCT": hsS_PCT,
      "hoK_PCT": hoK_PCT,
      "hdaM_PCT": hdaM_PCT,
      "hhulL_PCT": hhulL_PCT,
      "hfreZ_DAM_PCT": hfreZ_DAM_PCT,
      "hcncL_RMD_PCT": hcncL_RMD_PCT,
      "helK_PCT": helK_PCT,
      "hfM_PCT": hfM_PCT,
      "hlsK_PCT": hlsK_PCT,
      "hfaN_PCT": hfaN_PCT,
      "kerneL_RELS": kerneL_RELS,
      "kerneL_RPS": kerneL_RPS,
      "meteR_READ": meteR_READ,
      "bluepaN_WT": bluepaN_WT,
      "damageD_KRS": damageD_KRS,
      "elK_DAMAGED": elK_DAMAGED,
      "irR_DRY_IND": irR_DRY_IND,
      "handleR_LOC_NUM_2": handleR_LOC_NUM_2,
      "handleR_LOC_NAME_2": handleR_LOC_NAME_2,
      "vA_CR_BR_SAMPLE": vA_CR_BR_SAMPLE,
      "vA_CR_BR_GRAM": vA_CR_BR_GRAM,
      "vA_CR_BR_PCT": vA_CR_BR_PCT,
      "vL_CLN_SAMPLE": vL_CLN_SAMPLE
    };
    if (lblLoadType2 == 'EDIT') {
      objSave["settlemenT_NUM"] = settlemenT_NUM
      if (useSettlementDate == undefined || useSettlementDate == '') {
        objSave["settlemenT_DATE"] = null
      } else {
        objSave["settlemenT_DATE"] = useSettlementDate
      }
    }
    for (let i = 1; i <= 20; i++) {
      let vendor = getValue(thisObj, "ddVendor" + (i - 1));
      if (vendor == undefined && vendor == null) {
        vendor = '';
      } else {
        vendor = getValue(thisObj, "ddVendor" + (i - 1));
      }
      let remitTo = getValue(thisObj, "ddRemitTo" + (i - 1));
      if (remitTo == undefined && remitTo == null) {
        remitTo = '';
      } else {
        remitTo = getValue(thisObj, "ddRemitTo" + (i - 1));
      }
      let txtSharePct = getValue(thisObj, "txtSharePct" + (i - 1));
      if (txtSharePct == undefined || txtSharePct == null || txtSharePct == '') {
        txtSharePct = 0;
      } else {
        txtSharePct = getValue(thisObj, "txtSharePct" + (i - 1));
      }
      // let vendor = getValue(thisObj, "ddVendor" + (i - 1)) == undefined ? '' : getValue(thisObj, "ddVendor" + (i - 1));
      objSave["spliT_VENDOR" + (i)] = vendor.length == 0 ? '' : vendor[0]
      objSave["spliT_REMIT" + (i)] = remitTo
      objSave["spliT_SHARE_PCT" + (i)] = txtSharePct
      objSave["cmA_PARTICIPANT_IND" + (i)] = "N"
      objSave["purH_STOR_IND" + (i)] = ""
      objSave["grosS_WT" + (i)] = (objVendors[i - 1] == undefined || objVendors[i - 1] == null || objVendors[i - 1] == '') ? 0 : Number(objVendors[i - 1].gross_weight)
      objSave["neT_WT" + (i)] = (objVendors[i - 1] == undefined || objVendors[i - 1] == null || objVendors[i - 1] == '') ? 0 : Number(objVendors[i - 1].net_weight)
      objSave["lsK_WT" + (i)] = (objVendors[i - 1] == undefined || objVendors[i - 1] == null || objVendors[i - 1] == '') ? 0 : Number(objVendors[i - 1].lsk_weight)
      objSave["basiS_GRADE_AMT" + (i)] = (objVendors[i - 1] == undefined || objVendors[i - 1] == null || objVendors[i - 1] == '') ? 0 : parseFloat(objVendors[i - 1].net_price).toFixed(2);
 };

    let vehiclE_WT = 0;
    for (let i = 1; i <= 5; i++) {
      if (i <= thisObj.values['gridWeightTickets'].length) {
        let gridScaleData = thisObj.values['gridWeightTickets'][i - 1].txtConcatedData;
        if (gridScaleData !== '' && gridScaleData !== null && gridScaleData !== undefined) {
          let rowdata = gridScaleData.split('|');
          let bScaleTicketValidation = bScaleTicketValid(rowdata);
          if (bScaleTicketValidation == false) {
            return false;
          }
          if (rowdata !== null) {
            if (rowdata[6] == '' || rowdata[6] == undefined || rowdata[6] == 'undefined' || rowdata[6] == null) {
              vehiclE_WT = vehiclE_WT + 0;
            } else {
              vehiclE_WT = vehiclE_WT + Number(rowdata[6])
            }
            objSave["drY_TICKET" + (i)] = (rowdata[0] == undefined || rowdata[0] == 'undefined' || rowdata[0] == null) ? '' : rowdata[0]
            objSave["drY_DATE" + (i)] = (rowdata[1] == undefined || rowdata[1] == 'undefined' || rowdata[1] == null) ? '' : rowdata[1]
            objSave["drY_CARRIER" + (i)] = (rowdata[2] == undefined || rowdata[2] == 'undefined' || rowdata[2] == null) ? '' : rowdata[2]
            objSave["drY_DISPATCH" + (i)] = (rowdata[3] == undefined || rowdata[3] == 'undefined' || rowdata[3] == null) ? '' : rowdata[3]
            objSave["drY_VEHICLE_ID" + (i)] = (rowdata[4] == undefined || rowdata[4] == 'undefined' || rowdata[4] == null) ? '' : rowdata[4]
            objSave["drY_TARE_WT" + (i)] = (rowdata[5] == '' || rowdata[5] == undefined || rowdata[5] == 'undefined' || rowdata[5] == null) ? 0 : rowdata[5]
            objSave["drY_VEHICLE_WT" + (i)] = (rowdata[6] == '' || rowdata[6] == undefined || rowdata[6] == 'undefined' || rowdata[6] == null) ? 0 : rowdata[6]
            objSave["drY_PEANUT_WT" + (i)] = (rowdata[7] == '' || rowdata[7] == undefined || rowdata[7] == 'undefined' || rowdata[7] == null) ? 0 : rowdata[7]

            objSave["greeN_TICKET" + (i)] = (rowdata[8] == undefined || rowdata[8] == 'undefined' || rowdata[8] == null) ? '' : rowdata[8]
            objSave["greeN_DATE" + (i)] = (rowdata[9] == undefined || rowdata[9] == 'undefined' || rowdata[9] == null) ? '' : rowdata[9]
            objSave["greeN_CARRIER" + (i)] = (rowdata[10] == undefined || rowdata[10] == 'undefined' || rowdata[10] == null) ? '' : rowdata[10]
            objSave["greeN_DISPATCH" + (i)] = (rowdata[11] == undefined || rowdata[11] == 'undefined' || rowdata[11] == null) ? '' : rowdata[11]
            objSave["greeN_VEHICLE_ID" + (i)] = (rowdata[12] == undefined || rowdata[12] == 'undefined' || rowdata[12] == null) ? '' : rowdata[12]
            objSave["greeN_TARE_WT" + (i)] = (rowdata[13] == '' || rowdata[13] == undefined || rowdata[13] == 'undefined' || rowdata[13] == null) ? 0 : rowdata[13]
            objSave["greeN_VEHICLE_WT" + (i)] = (rowdata[14] == '' || rowdata[14] == undefined || rowdata[14] == 'undefined' || rowdata[14] == null) ? 0 : rowdata[14]
            objSave["greeN_PEANUT_WT" + (i)] = (rowdata[15] == '' || rowdata[15] == undefined || rowdata[15] == 'undefined' || rowdata[15] == null) ? 0 : rowdata[15]
            objSave["weigheR_NAME" + (i)] = (rowdata[16] == undefined || rowdata[16] == 'undefined' || rowdata[16] == null) ? '' : rowdata[16]
          }
        }
      } else {
        objSave["drY_TICKET" + (i)] = ''
        objSave["drY_DATE" + (i)] = ''
        objSave["drY_CARRIER" + (i)] = ''
        objSave["drY_DISPATCH" + (i)] = ''
        objSave["drY_VEHICLE_ID" + (i)] = ""
        objSave["drY_TARE_WT" + (i)] = 0
        objSave["drY_VEHICLE_WT" + (i)] = 0
        objSave["drY_PEANUT_WT" + (i)] = 0

        objSave["greeN_TICKET" + (i)] = ''
        objSave["greeN_DATE" + (i)] = ''
        objSave["greeN_CARRIER" + (i)] = ''
        objSave["greeN_DISPATCH" + (i)] = ''
        objSave["greeN_VEHICLE_ID" + (i)] = ''
        objSave["greeN_TARE_WT" + (i)] = 0
        objSave["greeN_VEHICLE_WT" + (i)] = 0
        objSave["greeN_PEANUT_WT" + (i)] = 0
        objSave["weigheR_NAME" + (i)] = ''
      }
    }
    objSave["vehiclE_WT"] = vehiclE_WT;
    let scaleList = {
      scale_ticket_list: lblTicketUploadMod
    };

    if (lblTicketUploadMod !== '') {
      let uploadStatus = await SettlementService.UpdateScaleTicketUploadStatus('I', buyingPoint, sessionStorage.getItem('userid'), scaleList);
      if (uploadStatus !== '') {
        lblTicketUploadMod = '';
      }
    }
    if (lblLoadType2 == 'ADD') {
      let response = await SettlementService.CreateInspection(buyingPoint, txtSC95, objSave);
      if (response.status == 200) {
        setlblLoadType2('EDIT');
        setLblLoadType1(false)
        EnableFV95NumberChange(buyingPoint, txtSC95, lblStatusTag, lbl1007, mbSuperseded, mblnElectronicInd);
        return true;
      } else {
        alert(response.message);
        return false;
      }
    } else {
      let response = await SettlementService.UpdateInspection(buyingPoint, txtSC95, objSave);
      if (response.status == 200) {
        setlblLoadType2('EDIT');
        setLblLoadType1(false)
        EnableFV95NumberChange(buyingPoint, txtSC95, lblStatusTag, lbl1007, mbSuperseded, mblnElectronicInd);
        return true;
      } else {
        if (response.message == 'Error occurred during operation. Status Code : 500 ; Error Meesage : 030-The information from your screen is no longer current.') {
          alert('The information from your screen is no longer current.');
          setlblLoadType2('EDIT');
          EnableFV95NumberChange(buyingPoint, txtSC95, lblStatusTag, lbl1007, mbSuperseded, mblnElectronicInd);
          return false;
        } else {
          alert('An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.');
          return false;
        }
      }
    }

  }
  // #endregion

  function isValidDate(date) {
    let dateObj = new Date(date);
    if (dateObj instanceof Date) {
      return true;
    } else {
      return false;
    }
  }


  const onddEdibleOilChange = () => {
    let buy_point_id = getValue(thisObj, 'ddLocation')
    // let seed_gen = getValue(thisObj, 'ddSeedGen')
    let organiC_IND = getValue(thisObj, 'chkboxOrganic')
    if (organiC_IND == true) organiC_IND = 'Y'
    else organiC_IND = 'N'
    bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
    bFillOblBuyingPointList(buy_point_id)
    setLblLoadType1(true)
  }
  thisObj.onddEdibleOilChange = onddEdibleOilChange

  const onddSeedGenChange = () => {
    let genValue = getValue(thisObj, 'ddSeedGen')
    let segValue = getValue(thisObj, 'ddSeg')
    if (genValue != "") {
      if (segValue > 0) {
        //bFillSegmentList()
        setValue(thisObj, 'ddSeg', '1')
      }
    }
    else {
      setValue(thisObj, 'txtSeedMeetingSpec', '')
    }

    let buy_point_id = getValue(thisObj, 'ddLocation')
    // let seed_gen = getValue(thisObj, 'ddSeedGen')
    let organiC_IND = getValue(thisObj, 'chkboxOrganic')
    if (organiC_IND == true) organiC_IND = 'Y'
    else organiC_IND = 'N'
    bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), segValue, genValue, getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'));
    bFillOblBuyingPointList(buy_point_id)
    let obl_buy_pt_id = getValue(thisObj, 'ddBuyingPt')
    bFillOblWarehouseBinList(obl_buy_pt_id, getValue(thisObj, 'ddPeanutType'), segValue, genValue, getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
    setLblLoadType1(true)
  }
  thisObj.onddSeedGenChange = onddSeedGenChange

  const onddOleicChange = () => {
    let buy_point_id = getValue(thisObj, 'ddLocation')
    // let seed_gen = getValue(thisObj, 'ddSeedGen')
    let organiC_IND = getValue(thisObj, 'chkboxOrganic')
    if (organiC_IND == true) organiC_IND = 'Y'
    else organiC_IND = 'N'
    bFillWarehouseBinList(buy_point_id, organiC_IND, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
    bFillOblBuyingPointList(buy_point_id)
    setLblLoadType1(true)
  }
  thisObj.onddOleicChange = onddOleicChange

  const onddBuyingPtChange = () => {
    try {
      // let OblBuyingPointList = thisObj.state['ddBuyingPt'].valueList;
      // let OblBuyingPointindex = '';
      // let OblBuyingPointKey = thisObj.values['ddBuyingPt'];
      // if (OblBuyingPointKey !== undefined && OblBuyingPointKey !== null && OblBuyingPointKey !== '') {
      //   OblBuyingPointindex = OblBuyingPointList.find(elem => elem.key == OblBuyingPointKey).rowid;

      //   if (cboOblBuyingPointtag == "") setcboOblBuyingPointtag(-1)

      //   if (OblBuyingPointindex != Number(cboOblBuyingPointtag)) {
      //     let obl_buy_pt_id = getValue(thisObj, 'ddBuyingPt')
      //     if (bFillOblWarehouseBinList(obl_buy_pt_id, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))) {
      //       setcboOblBuyingPointtag(OblBuyingPointindex)
      //     }
      //     else {
      //       OblBuyingPointindex = cboOblBuyingPointtag
      //     }
      //   }
      // }
      let obl_buy_pt_id = getValue(thisObj, 'ddBuyingPt')
      bFillOblWarehouseBinList(obl_buy_pt_id, getValue(thisObj, 'ddPeanutType'), getValue(thisObj, 'ddSeg'), getValue(thisObj, 'ddSeedGen'), getValue(thisObj, 'ddPeanutVariety'), getValue(thisObj, 'ddOleic'))
      setLblLoadType1(true)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ddBuyingPtChange event"
        );
      }
      return false;
    }
  }
  thisObj.onddBuyingPtChange = onddBuyingPtChange

  const [lblTicketUploadMod, setlblTicketUploadMod] = useState('')

  const ontxtarInspectorRemarksChange = () => {
    setLblLoadType1(true);
  }
  thisObj.ontxtarInspectorRemarksChange = ontxtarInspectorRemarksChange

  const ontxtSMK1GrChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK1GrChange = ontxtSMK1GrChange

  const ontxtSMK1PctChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK1PctChange = ontxtSMK1PctChange

  const ontxtSMK2GrChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK2GrChange = ontxtSMK2GrChange

  const ontxtSMK2PctChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK2PctChange = ontxtSMK2PctChange

  const ontxtSMK3GrChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK3GrChange = ontxtSMK3GrChange

  const ontxtSMK3PctChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK3PctChange = ontxtSMK3PctChange

  const ontxtSMK4GrChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK4GrChange = ontxtSMK4GrChange

  const ontxtSMK4PctChange = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSMK4PctChange = ontxtSMK4PctChange

  const ontxtSharePct0Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct0Change = ontxtSharePct0Change

  const ontxtSharePct1Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct1Change = ontxtSharePct1Change

  const ontxtSharePct2Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct2Change = ontxtSharePct2Change

  const ontxtSharePct3Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct3Change = ontxtSharePct3Change

  const ontxtSharePct4Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct4Change = ontxtSharePct4Change

  const ontxtSharePct5Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct5Change = ontxtSharePct5Change

  const ontxtSharePct6Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct6Change = ontxtSharePct6Change

  const ontxtSharePct7Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct7Change = ontxtSharePct7Change

  const ontxtSharePct8Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct8Change = ontxtSharePct8Change

  const ontxtSharePct9Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct9Change = ontxtSharePct9Change

  const ontxtSharePct10Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct10Change = ontxtSharePct10Change

  const ontxtSharePct11Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct11Change = ontxtSharePct11Change

  const ontxtSharePct12Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct12Change = ontxtSharePct12Change

  const ontxtSharePct13Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct13Change = ontxtSharePct13Change

  const ontxtSharePct14Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct14Change = ontxtSharePct14Change

  const ontxtSharePct15Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct15Change = ontxtSharePct15Change

  const ontxtSharePct16Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct16Change = ontxtSharePct16Change

  const ontxtSharePct17Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct17Change = ontxtSharePct17Change

  const ontxtSharePct18Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct18Change = ontxtSharePct18Change

  const ontxtSharePct19Change = () => {
    setLblLoadType1(true)
  }
  thisObj.ontxtSharePct19Change = ontxtSharePct19Change
  
  //On blur effects of test boxes
  const ontxtFMSampleWtBlur = () => {
    let tempValue = getValue(thisObj, 'txtFMSampleWt')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtFMSampleWt', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(1))

  }
  thisObj.ontxtFMSampleWtBlur = ontxtFMSampleWtBlur;

  const ontxtCleanSampleWtBlur = () => {
    let tempValue = getValue(thisObj, 'txtCleanSampleWt')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtCleanSampleWt', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(1))

  }
  thisObj.ontxtCleanSampleWtBlur = ontxtCleanSampleWtBlur;

  const ontxtSMKRSPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtSMKRSPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtSMKRSPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtSMKRSPctBlur = ontxtSMKRSPctBlur;

  // const ontxtSMK1PctBlur = () => {
  //   let tempValue = getValue(thisObj, 'txtSMK1Pct')
  //   tempValue = Number(tempValue)
  //   setValue(thisObj, 'txtSMK1Pct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  // }
  // thisObj.ontxtSMK1PctBlur = ontxtSMK1PctBlur;

  // const ontxtSMK2PctBlur = () => {
  //   let tempValue = getValue(thisObj, 'txtSMK2Pct')
  //   tempValue = Number(tempValue)
  //   setValue(thisObj, 'txtSMK2Pct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  // }
  // thisObj.ontxtSMK2PctBlur = ontxtSMK2PctBlur;

  // const ontxtSMK3PctBlur = () => {
  //   let tempValue = getValue(thisObj, 'txtSMK3Pct')
  //   tempValue = Number(tempValue)
  //   setValue(thisObj, 'txtSMK3Pct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  // }
  // thisObj.ontxtSMK3PctBlur = ontxtSMK3PctBlur;

  // const ontxtSMK4PctBlur = () => {
  //   let tempValue = getValue(thisObj, 'txtSMK4Pct')
  //   tempValue = Number(tempValue)
  //   setValue(thisObj, 'txtSMK4Pct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  // }
  // thisObj.ontxtSMK4PctBlur = ontxtSMK4PctBlur;

  const ontxtSoundSplitsPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtSoundSplitsPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtSoundSplitsPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtSoundSplitsPctBlur = ontxtSoundSplitsPctBlur;

  const ontxtOtherKernelsPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtOtherKernelsPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtOtherKernelsPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtOtherKernelsPctBlur = ontxtOtherKernelsPctBlur;

  const ontxtDamageSplitsBlur = () => {
    let tempValue = getValue(thisObj, 'txtDamageSplits')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtDamageSplits', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(1))

  }
  thisObj.ontxtDamageSplitsBlur = ontxtDamageSplitsBlur;

  const ontxtTotalDamagePctBlur = () => {
    let tempValue = getValue(thisObj, 'txtTotalDamagePct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtTotalDamagePct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtTotalDamagePctBlur = ontxtTotalDamagePctBlur;

  const ontxtHullsPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtHullsPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtHullsPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtHullsPctBlur = ontxtHullsPctBlur;

  const ontxtHullBrightnessPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtHullBrightnessPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtHullBrightnessPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtHullBrightnessPctBlur = ontxtHullBrightnessPctBlur;

  const ontxtJumboPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtJumboPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtJumboPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtJumboPctBlur = ontxtJumboPctBlur;

  const ontxtELKPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtELKPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtELKPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtELKPctBlur = ontxtELKPctBlur;

  const ontxtForeignMaterialPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtForeignMaterialPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtForeignMaterialPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtForeignMaterialPctBlur = ontxtForeignMaterialPctBlur;

  const ontxtFancyPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtFancyPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtFancyPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtFancyPctBlur = ontxtFancyPctBlur;

  const ontxtLSKPctBlur = () => {
    let tempValue = getValue(thisObj, 'txtLSKPct')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtLSKPct', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtLSKPctBlur = ontxtLSKPctBlur;

  const ontxtMoistureBlur = () => {
    let tempValue = getValue(thisObj, 'txtMoisture')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtMoisture', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(2))

  }
  thisObj.ontxtMoistureBlur = ontxtMoistureBlur;
  //End of on blur event

  let mbSuccessfulLoad;
  let msPriceSchedules;
  let msDiscountSchedules;
  let mbFormLoading;
  let mbFormFilling;
  let mbCalcRegrading;

  async function FV95Reset() {
    try {
      setMbAnotherFV95(true);
      setMbAnotherFV95NoReset(false);
      let objVal = {}

      objVal['lblLoadType2'] = ''
      objVal['lblLoadType1'] = ''
      objVal['txtStatus'] = 'Pending'
      // setValue(thisObj, 'lblLoadType2', '')
      // setValue(thisObj, 'lblLoadType1', '')
      // setValue(thisObj, 'txtStatus', 'Pending')

      objVal['ddEdibleOil'] = ''
      objVal['ddSeedGen'] = ''
      objVal['ddBuyingPt'] = ''
      objVal['ddObligationPt'] = ''
      objVal['ddPeanutType'] = ''
      objVal['ddPeanutVariety'] = ''
      objVal['ddSeg'] = ''
      objVal['ddWhouseBin'] = ''
      // setValue(thisObj, 'ddEdibleOil', '')
      // setValue(thisObj, 'ddSeedGen', '')
      // setValue(thisObj, 'ddBuyingPt', '')
      // setValue(thisObj, 'ddObligationPt', '')
      // setValue(thisObj, 'ddPeanutType', '')
      // setValue(thisObj, 'ddPeanutVariety', '')
      // setValue(thisObj, 'ddSeg', '')
      // setValue(thisObj, 'ddWhouseBin', '')

      objVal['txtNumber'] = ''
      objVal['txtName'] = ''
      // setValue(thisObj, 'txtNumber', '')
      // setValue(thisObj, 'txtName', '')

      objVal['txtCleanSampleWt'] = ''
      objVal['txtValenciaCleanSampleWt'] = ''
      // setValue(thisObj, 'txtCleanSampleWt', '')
      // setValue(thisObj, 'txtValenciaCleanSampleWt', '')

      objVal['txtConcealedRMDGr'] = ''
      objVal['txtConcealedRMDPct'] = ''
      objVal['txtCrkOrBrkShellsGr'] = ''
      objVal['txtCrkOrBrkShellsPct'] = ''
      objVal['txtDamageSplits'] = ''
      objVal['txtInspectionDateTime'] = ''
      objVal['txtDiscoloredShellsGr'] = ''
      objVal['txtDiscoloredShellsPct'] = ''
      objVal['txtELKGr'] = ''
      objVal['txtELKPct'] = ''
      objVal['txtFancyGr'] = ''
      objVal['txtFancyPct'] = ''
      objVal['txtFMSampleWt'] = ''
      objVal['txtOtherForeignMaterials'] = ''
      objVal['txtForeignMaterialGr'] = ''
      objVal['txtForeignMaterialPct'] = ''
      // setValue(thisObj, 'txtConcealedRMDGr', '')
      // setValue(thisObj, 'txtConcealedRMDPct', '')
      // setValue(thisObj, 'txtCrkOrBrkShellsGr', '')
      // setValue(thisObj, 'txtCrkOrBrkShellsPct', '')
      // setValue(thisObj, 'txtDamageSplits', '')
      // setValue(thisObj, 'txtInspectionDateTime', '')
      // setValue(thisObj, 'txtDiscoloredShellsGr', '')
      // setValue(thisObj, 'txtDiscoloredShellsPct', '')
      // setValue(thisObj, 'txtELKGr', '')
      // setValue(thisObj, 'txtELKPct', '')
      // setValue(thisObj, 'txtFancyGr', '')
      // setValue(thisObj, 'txtFancyPct', '')
      // setValue(thisObj, 'txtFMSampleWt', '')
      // setValue(thisObj, 'txtOtherForeignMaterials', '')
      // setValue(thisObj, 'txtForeignMaterialGr', '')
      // setValue(thisObj, 'txtForeignMaterialPct', '')

      objVal['txtFreezeDamageGr'] = ''
      objVal['txtFreezeDamagePct'] = ''
      objVal['txtSC95'] = ''
      objVal['txtHullBrightness'] = ''
      objVal['txtHullBrightnessPct'] = ''
      objVal['txtHullsGr'] = ''
      objVal['txtHullsPct'] = ''
      objVal['txtJumboGr'] = ''
      objVal['txtJumboPct'] = ''
      objVal['txtLSKGr'] = ''
      objVal['txtLSKPct'] = ''
      objVal['txtMoisture'] = ''
      objVal['txtOtherKernelsGr'] = ''
      objVal['txtOtherKernelsPct'] = ''
      objVal['txtProbingPattern'] = ''
      // setValue(thisObj, 'txtFreezeDamageGr', '')
      // setValue(thisObj, 'txtFreezeDamagePct', '')
      // setValue(thisObj, 'txtSC95', '')
      // setValue(thisObj, 'txtHullBrightness', '')
      // setValue(thisObj, 'txtHullBrightnessPct', '')
      // setValue(thisObj, 'txtHullsGr', '')
      // setValue(thisObj, 'txtHullsPct', '')
      // setValue(thisObj, 'txtJumboGr', '')
      // setValue(thisObj, 'txtJumboPct', '')
      // setValue(thisObj, 'txtLSKGr', '')
      // setValue(thisObj, 'txtLSKPct', '')
      // setValue(thisObj, 'txtMoisture', '')
      // setValue(thisObj, 'txtOtherKernelsGr', '')
      // setValue(thisObj, 'txtOtherKernelsPct', '')
      // setValue(thisObj, 'txtProbingPattern', '')

      objVal['txtarInspectorRemarks'] = ''
      objVal['txtSMK1Gr'] = ''
      objVal['txtSMK1Pct'] = ''
      objVal['txtSMK2Gr'] = ''
      objVal['txtSMK2Pct'] = ''
      objVal['txtSMK3Gr'] = ''
      objVal['txtSMK3Pct'] = ''
      objVal['txtSMK4Gr'] = ''
      objVal['txtSMK4Pct'] = ''
      objVal['txtSMKRSGr'] = ''
      objVal['txtSMKRSPct'] = ''
      //setValue(thisObj, 'txtarInspectorRemarks', '')
      // setValue(thisObj, 'txtSMK1Gr', '')
      // setValue(thisObj, 'txtSMK1Pct', '')
      // setValue(thisObj, 'txtSMK2Gr', '')
      // setValue(thisObj, 'txtSMK2Pct', '')
      // setValue(thisObj, 'txtSMK3Gr', '')
      // setValue(thisObj, 'txtSMK3Pct', '')
      // setValue(thisObj, 'txtSMK4Gr', '')
      // setValue(thisObj, 'txtSMK4Pct', '')
      // setValue(thisObj, 'txtSMKRSGr', '')
      // setValue(thisObj, 'txtSMKRSPct', '')

      objVal['txtSMKRSGr'] = ''
      objVal['txtSMKRSPct'] = ''
      objVal['txtSMKRSGr'] = ''
      objVal['txtSMKRSPct'] = ''
      // setValue(thisObj, 'txtSoundSplitsGr', '')
      // setValue(thisObj, 'txtSoundSplitsPct', '')
      // setValue(thisObj, 'txtTotalDamageGr', '')
      // setValue(thisObj, 'txtTotalDamagePct', '')

      objVal['txtKernelsREL'] = ''
      objVal['txtKernelsRPS'] = ''
      objVal['txtDamagedKRS'] = ''
      objVal['txtMeterReading'] = ''
      objVal['txtBluePanWt'] = ''
      // setValue(thisObj, 'txtKernelsREL', '')
      // setValue(thisObj, 'txtKernelsRPS', '')
      // setValue(thisObj, 'txtDamagedKRS', '')
      // setValue(thisObj, 'txtMeterReading', '')
      // setValue(thisObj, 'txtBluePanWt', '')

      objVal['txtCMAVendor'] = ''
      objVal['chkboxDefault'] = 0
      objVal['ddRemitTo'] = ''
      // setValue(thisObj, 'txtCMAVendor', '')
      // setValue(thisObj, 'chkboxDefault', 0)
      // setValue(thisObj, 'ddRemitTo', '')

      objVal['txtSeg'] = ''
      objVal['txtFM'] = ''
      objVal['txtLSK'] = ''
      objVal['txtMST'] = ''
      objVal['txtSMK'] = ''
      objVal['txtSS'] = ''
      objVal['txtSMKRS'] = ''
      objVal['txtOK'] = ''
      objVal['txtFRZ'] = ''
      objVal['txtCRMD'] = ''
      objVal['txtDAM'] = ''
      objVal['txtHULLS'] = ''
      objVal['txtELK'] = ''
      objVal['txtFANCY'] = ''
      objVal['txtTotKrnlsHulls'] = ''
      // setValue(thisObj, 'txtSeg', '')
      // setValue(thisObj, 'txtFM', '')
      // setValue(thisObj, 'txtLSK', '')
      // setValue(thisObj, 'txtMST', '')
      // setValue(thisObj, 'txtSMK', '')
      // setValue(thisObj, 'txtSS', '')
      // setValue(thisObj, 'txtSMKRS', '')
      // setValue(thisObj, 'txtOK', '')
      // setValue(thisObj, 'txtFRZ', '')
      // setValue(thisObj, 'txtCRMD', '')
      // setValue(thisObj, 'txtTotalKernels', '')
      // setValue(thisObj, 'txtDAM', '')
      // setValue(thisObj, 'txtHULLS', '')
      // setValue(thisObj, 'txtELK', '')
      // setValue(thisObj, 'txtFANCY', '')
      // setValue(thisObj, 'txtTotKrnlsHulls', '')

      objVal['txtSampleWt'] = ''
      objVal['txtGrams'] = ''
      objVal['txtPercent'] = ''
      // setValue(thisObj, 'txtSampleWt', '')
      // setValue(thisObj, 'txtGrams', '')
      // setValue(thisObj, 'txtPercent', '')

      objVal['lblAddedBy'] = ''
      objVal['txtAFlavusTon'] = ''
      objVal['txtAFlavusLb'] = ''
      objVal['lblAuditCount'] = ''
      objVal['lblChangedBy'] = ''
      // setValue(thisObj, 'lblAddedBy', '')
      // setValue(thisObj, 'txtAFlavusTon', '')
      // setValue(thisObj, 'txtAFlavusLb', '')
      // setValue(thisObj, 'lblAuditCount', '')
      // setValue(thisObj, 'lblChangedBy', '')

      objVal['txtDamageTon'] = ''
      objVal['txtDamageLbs'] = ''
      objVal['txtELKPremiumTon'] = ''
      objVal['txtELKPremiumLb'] = ''
      objVal['txtAccessFMTon'] = ''
      objVal['txtAccessFMLb'] = ''
      // setValue(thisObj, 'txtDamageTon', '')
      // setValue(thisObj, 'txtDamageLbs', '')
      // setValue(thisObj, 'txtELKPremiumTon', '')
      // setValue(thisObj, 'txtELKPremiumLb', '')
      // setValue(thisObj, 'txtAccessFMTon', '')
      // setValue(thisObj, 'txtAccessFMLb', '')

      objVal['txtExcessMoisture'] = ''
      objVal['txtForeignMaterial'] = ''
      objVal['txtExcessSplitsTon'] = ''
      objVal['txtExcessSplitsLb'] = ''
      objVal['txtGrossWeight'] = ''
      objVal['txtH'] = ''
      objVal['txtKernelValueTon'] = ''
      objVal['txtKernelValueLb'] = ''
      objVal['txtValPerPoundExclSLK'] = ''
      objVal['txtNetWeight'] = ''
      objVal['txtNetWtExclLSK'] = ''
      objVal['txtOI'] = ''
      objVal['txtTotalDiscountsTon'] = ''
      objVal['txtlblTotalDiscountsLb'] = ''
      objVal['txtTotalDollar'] = ''
      objVal['txtTotalLBs'] = ''
      objVal['txtTotalTon'] = ''
      objVal['txtTotalLb'] = ''
      objVal['txtTotalLoadVal'] = ''
      objVal['txtVoidReason'] = ''
      objVal['txtWtlessFM'] = ''
      objVal['txtEWtInclVeh'] = ''
      objVal['txtEWtOfVeh'] = ''
      objVal['txtTotalSMK'] = ''
      objVal['txtTotalKrnls'] = ''
      objVal['txtTotalKernelsHulls'] = ''
      // setValue(thisObj, 'txtExcessMoisture', '')
      // setValue(thisObj, 'txtForeignMaterial', '')
      // setValue(thisObj, 'txtExcessSplitsTon', '')
      // setValue(thisObj, 'txtExcessSplitsLb', '')
      // setValue(thisObj, 'txtGrossWeight', '')
      // setValue(thisObj, 'txtH', '')
      // setValue(thisObj, 'txtKernelValueTon', '')
      // setValue(thisObj, 'txtKernelValueLb', '')
      // setValue(thisObj, 'txtValPerPoundExclSLK', '')
      // setValue(thisObj, 'txtNetWeight', '')
      // setValue(thisObj, 'txtNetWtExclLSK', '')
      // setValue(thisObj, 'txtOI', '')
      // setValue(thisObj, 'txtTotalDiscountsTon', '')
      // setValue(thisObj, 'txtlblTotalDiscountsLb', '')
      // setValue(thisObj, 'txtTotalDollar', '')
      // setValue(thisObj, 'txtTotalLBs', '')
      // setValue(thisObj, 'txtTotalTon', '')
      // setValue(thisObj, 'txtTotalLb', '')
      // setValue(thisObj, 'txtTotalLoadVal', '')
      // setValue(thisObj, 'txtVoidReason', '')
      // setValue(thisObj, 'txtWtlessFM', '')
      // setValue(thisObj, 'txtEWtInclVeh', '')
      // setValue(thisObj, 'txtEWtOfVeh', '')
      // setValue(thisObj, 'txtTotalSMK', '')
      setValue(thisObj, 'txtTotalKrnls', '')
      setValue(thisObj, 'txtTotalKernelsHulls', '')
      setLblElecUploadInd('');
      // lblSubmitType = '';
      setFieldValues(thisObj, objVal, true);
      mbSuccessfulLoad = false;
      msPriceSchedules = "";
      msDiscountSchedules = "";
      mbFormLoading = false;
      mbFormFilling = false;
      mbCalcRegrading = false;
      setMbSuperseded(false);
      mblnElectronicInd = false;

      bLandType();
      mbFormFilling = false;
      mbFormLoading = false;

      // let lstStateList = thisObj.state['ddState'].valueList;
      // let stateIndex = '';
      // let statekey = thisObj.values['ddState'];
      // if (statekey !== undefined && statekey !== null) {
      //   stateIndex = lstStateList.find(elem => elem.key == statekey).rowid;
      // }

      // let lstLocationList = thisObj.state['ddLocation'].valueList;
      // let locationIndex = '';
      // let locationkey = thisObj.values['ddLocation'];
      // if (locationkey !== undefined && locationkey !== null) {
      //   locationIndex = lstLocationList.find(elem => elem.key == statekey).rowid;
      // }

      // if(lstFV95ResetInd[locationIndex]=="Y"){
      //   setlblLoadType2("ADD")
      //   stateIndex=0
      // }
      // else{
      //   setlblLoadType2("ADD")        
      // }

      setlblLoadType2("ADD")

      enable(thisObj, 'ddLocation')
      enable(thisObj, 'btnCreate');
      enable(thisObj, 'btnCancel');
      enable(thisObj, 'btnSave')
      setMbAnotherFV95(false);
      setMbAnotherFV95NoReset(false);
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, 'Exception in FV95 Reset')
    }
  }

  // function onlblLoadType2Change(){
  //   let buy_pt_id=getValue(thisObj,'ddLocation')
  //   switch(lblLoadType2){
  //     case "ADD":
  //       break;
  //     case 'EDIT':
  //       break;
  //   }
  // }
  // thisObj.onlblLoadType2Change=onlblLoadType2Change

  let bForceTicketUpdate = false;

  async function bFormValid_HMC(strFV95Status) {
    try {
      let LstrList = '';
      let bSpecsExist = false;
      let bFullDataCheck = false;
      let bPendingVoid = false;
      let nIndex = 0;
      let ddShare = 0;
      let intCMAParticipantCount = 0;
      let bAcceptAsSeedNotMeetingSpec = false;
      let lstrxml = '';
      let LstrDflt = '';
      let bFormValid_HMCFlag = false;
      let strHMC = '';
      let setseedmeetingspecasno = false;
      let meetingSpec = false;

      if (Number(thisObj.values.txtMoisture) > 18) {
        showMessage(thisObj, 'Please enter correct High Moisture percentage!!!');
        isFormValid = false;
        return false;
      }
      strHMC = 'N'
      setStrHMC('N')

      if (Number(thisObj.values.txtMoisture) > 10.49) {
        strHMC = 'Y'
        setStrHMC('Y')
      }
      if (strHMC == 'Y') {
        // DoEvents();// please check if the function call exists or not. in VB it is getting called only oncw with no definition
      } else {
        showMessage(thisObj, 'Please enter High Moisture percentage!!!')
        isFormValid = false;
        return false;
      }

      if (Number(thisObj.values.txtTotalDamagePct) <= 3.49 && Number(thisObj.values.txtConcealedRMDPct) <= 1) {
        if (thisObj.values.txtSeg == '2') {
          showMessage(thisObj, 'Invalid HMC Seg Type!')
          isFormValid = false;
          return false;
        }
      }

      bPendingVoid = (thisObj.values.txtStatus == 'X' || strFV95Status == 'X') ? true : false;
      bFullDataCheck = (thisObj.values.txtStatus == '' || thisObj.values.txtStatus == 'Pending') ? false : true;

      isFormValid = false;


      if (thisObj.values.txtSC95.trim() == '') {
        showMessage(thisObj, 'SC95 Number is required!!!')
        isFormValid = false;
        return false;
      }

      if (thisObj.values.txtSC95.length !== 7) {
        showMessage(thisObj, 'SC95 Number must be 7 characters long!!!')
        isFormValid = false;
        return false;
      }

      if (lblElecUploadInd == 'N') {
        lstrxml = await SettlementService.RetrieveCheck1007OneDocumentDetails(thisObj.values['ddLocation'])
        if (lstrxml[0].onedoc_ind.toLocaleUpperCase() == 'Y') {
          if (isNaN(thisObj.values.txtSC95)) {
            showMessage(thisObj, 'SC95 Number must be all numeric for locations using One Documents!!!')
            isFormValid = false;
            return false;
          }
        }
        else {

          let strNewInspNum = thisObj.vlaues.txtSC95
          let numeric = true;
          for (let i = 1; i < strNewInspNum.length; i++) {
            if (isNumeric(strNewInspNum[i]) == false) {
              numeric = false;
            }
          }

          if ((strNewInspNum.charCodeAt(0) >= 65 && strNewInspNum.charCodeAt(0) <= 90 && numeric) == false) {
            showMessage(thisObj, 'SC95 Number must be in Format A###### for this location!!!')
            isFormValid = false;
            return false;
          }
        }
      }

      let txtDateTime = ConvertToDate(getValue(thisObj, 'txtInspectionDateTime'));
      if (txtDateTime !== undefined && txtDateTime !== null && txtDateTime !== '') {
        if (!isValidDate(txtDateTime)) {
          showMessage(thisObj, 'Invalid Date!!!')
          isFormValid = false;
          return false;
        } else {
          if (new Date() < new Date(txtDateTime)) {
            if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT') {
              // pps_defaults API Wil get call
              let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
              if (ppsDefault !== undefined && ppsDefault.length > 0) {
                let compId = sessionStorage.getItem('compId');
                let key = `${compId}` + '_default_overrides';
                if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
                  let value = Object.getOwnPropertyDescriptor(ppsDefault[0].bypass_curr_date_edit, key).value;
                  if (value !== true) {
                    showMessage(thisObj, "Date cannot be in future!!!", false);
                    isFormValid = false;
                    return false;
                  }
                }
              }
            }
          }
          let cropYear = JSON.parse(sessionStorage.getItem('year'));
          let date = "06/01/" + (cropYear);
          if (new Date(txtDateTime) < new Date(date)) {
            showMessage(thisObj, "Date cannot be less than June 1 of current crop year!!!", false);
            isFormValid = false;
            return false;
          }
        }
      }

      if (thisObj.values['ddFarm'] == '' || thisObj.values['ddFarm'] == null || thisObj.values['ddFarm'] == undefined) {
        showMessage(thisObj, 'Please Select a Farm!!!')
        isFormValid = false;
        return false;
      }

      let cboLandType = getValue(thisObj, 'ddIrrigatedDryland');
      let iscLandTypeVisible = isVisible(thisObj, 'ddIrrigatedDryland'); // Should be come from property.
      if (bPendingVoid == false && iscLandTypeVisible == true) {
        if (cboLandType !== "I" && cboLandType !== "D") {
          showMessage(thisObj, "Irrigated/Dryland must be either Irrigated or Dryland!!!");
          isFormValid = false;
          return false;
        }
      }

      if (thisObj.values['ddPeanutType'] == '' || thisObj.values['ddPeanutType'] == null || thisObj.values['ddPeanutType'] == undefined) {
        showMessage(thisObj, 'Please Select a Peanut Type!!!')
        isFormValid = false;
        return false;
      }

      if (bPendingVoid == false) {
        if (thisObj.values['ddPeanutVariety'] == '' || thisObj.values['ddPeanutVariety'] == null || thisObj.values['ddPeanutVariety'] == undefined) {
          showMessage(thisObj, 'Please Select a Peanut Variety!!!')
          isFormValid = false;
          return false;
        }
      }

      if (thisObj.values['ddSeg'] == '' || thisObj.values['ddSeg'] == null || thisObj.values['ddSeg'] == undefined) {
        showMessage(thisObj, 'Please Select a Segment!!!')
        isFormValid = false;
        return false;
      }

      // txtReading Enable/Disable Logic
      if (isEnabled(thisObj, 'txtReading')) {
        let txtReading = getValue(thisObj, 'txtReading');
        if (txtReading !== undefined && txtReading !== '') {
          if (isNumeric(txtReading)) {
            if (txtReading < 0 || txtReading > 9999.9) {
              showMessage(thisObj, "Vicam must be of format ######!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Vicam must be of format ######!!!", false);
            isFormValid = false;
            return;
          }
        }
      }

      // txtFV95 Enable/Disable Logic
      if (isEnabled(thisObj, 'txtSC95')) {
        let inspectHeaderList = await SettlementService.RetrieveInspectionHeaderList(txtFV95, null, null);
        if (inspectHeaderList.length > 0 && inspectHeaderList[0].insp_num !== '') {
          showMessage(thisObj, "Inspection number " + txtFV95 + ' has already been created!!!', false);
          isFormValid = false;
          return;
        }
      }

      if (!bPendingVoid) {
        let ddRemitTo0 = getValue(thisObj, "ddRemitTo0") == undefined ? '' : getValue(thisObj, "ddRemitTo0");
        let ddRemitTo1 = getValue(thisObj, "ddRemitTo1") == undefined ? '' : getValue(thisObj, "ddRemitTo1");
        let ddRemitTo2 = getValue(thisObj, "ddRemitTo2") == undefined ? '' : getValue(thisObj, "ddRemitTo2");
        let ddRemitTo3 = getValue(thisObj, "ddRemitTo3") == undefined ? '' : getValue(thisObj, "ddRemitTo3");
        if (ddRemitTo0 == '' && ddRemitTo1 == '' && ddRemitTo2 == '' && ddRemitTo3) {
          showMessage(thisObj, "At least one valid vendor is required!!", false);
          isFormValid = false;
          return;
        }
        // Vendor Split changes
        for (let i = 1; i <= 20; i++) {
          let vendor = getValue(thisObj, "ddVendor" + (i - 1)) == undefined ? '' : getValue(thisObj, "ddVendor" + (i - 1));
          let remitTo = getValue(thisObj, "ddRemitTo" + (i - 1)) == undefined ? '' : getValue(thisObj, "ddRemitTo" + (i - 1));
          let sharePercent = getValue(thisObj, "txtSharePct" + (i - 1)) == undefined ? 0 : getValue(thisObj, "txtSharePct" + (i - 1));

          if (vendor.length !== 0 && vendor[0] !== '' && remitTo == '') {
            showMessage(thisObj, "Vendor" + (i - 1) + " is not valid!!!", false);
            isFormValid = false;
            return;
          }

          if (sharePercent !== 0 && sharePercent !== '' && sharePercent !== null && remitTo == '') {
            showMessage(thisObj, "Vendor share" + (i - 1) + " is not valid without a vendor!!!", false);
            isFormValid = false;
            return;
          }

          if (vendor.length > 0 && vendor[0] !== "") {
            if (!isNumeric(sharePercent)) {
              showMessage(thisObj, "Vendor share must be of format ###.##!!!", false);
              isFormValid = false;
              return;
            }

            if (Number(sharePercent) < 0) {
              showMessage(thisObj, "Vendor share must be greater than 0!!!", false);
              isFormValid = false;
              return;
            } else {
              if (Number(sharePercent) > 100) {
                showMessage(thisObj, "Vendor share must be less than 100!!!", false);
                isFormValid = false;
                return;
              }
            }
          }
        }
        ddShare = 0;
        intCMAParticipantCount = 0
        // total number of share % validation
        let txtCMAVendor = getValue(thisObj, 'txtCMAVendor') == undefined ? '' : getValue(thisObj, 'txtCMAVendor');
        for (let i = 1; i <= 20; i++) {
          let sharePercent = getValue(thisObj, "txtSharePct" + (i - 1)) == undefined ? 0 : getValue(thisObj, "txtSharePct" + (i - 1));
          let chkboxCMA = getValue(thisObj, "chkboxCMA" + (i - 1)) == true ? true : false;
          if (sharePercent > 0) {
            ddShare = ddShare + Number(sharePercent);
            if (txtCMAVendor !== '' && chkboxCMA == true) {
              intCMAParticipantCount = intCMAParticipantCount + 1;
            }
          }
        }

        if (Number(ddShare) !== 100) {
          showMessage(thisObj, "Total of vendor share " + Number(ddShare) + " does not equal 100!!!", false);
          isFormValid = false;
          return;
        }

        if (txtCMAVendor !== undefined && txtCMAVendor !== "" && intCMAParticipantCount == 0) {
          showMessage(thisObj, "No Producers are marked as participating in the CMA Contract!", false);
          isFormValid = false;
          return;
        }

        let cboWhouseBin = getValue(thisObj, 'ddWhouseBin');
        if (cboWhouseBin == undefined || cboWhouseBin == null || cboWhouseBin == '') {
          showMessage(thisObj, "Please Select an Unload Warehouse/Bin #!!!", false);
          isFormValid = false;
          return;
        }

        let cboOblBuyingPoint = getValue(thisObj, 'ddBuyingPt');
        if (cboOblBuyingPoint == undefined || cboWhouseBin == null || cboWhouseBin == '') {
          showMessage(thisObj, "Please Select an Obligation Buying Point!!!", false);
          isFormValid = false;
          return;
        }

        let ddObligationPt = getValue(thisObj, 'ddObligationPt');
        if (ddObligationPt == undefined || ddObligationPt == null || ddObligationPt == '') {
          showMessage(thisObj, "Please Select an Obligation Warehouse/Bin #!!!", false);
          isFormValid = false;
          return;
        }

        // txtFMSampleWt
        let txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt');
        if (txtFMSampleWt !== undefined && txtFMSampleWt !== '' && txtFMSampleWt !== null) {
          if (isNumeric(txtFMSampleWt)) {
            if (txtFMSampleWt < 0 || txtFMSampleWt > 9999.9) {
              showMessage(thisObj, "FM Sample Weight must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "FM Sample Weight must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtClnSampleWt
        let txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
        if (txtCleanSampleWt !== undefined && txtCleanSampleWt !== '' && txtCleanSampleWt !== null) {
          txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
          if (isNumeric(txtCleanSampleWt)) {
            if (txtCleanSampleWt < 0 || txtCleanSampleWt > 9999.9) {
              showMessage(thisObj, "Clean Sample Weight must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Clean Sample Weight must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // txtVLClnSampleWt
        let txtVLClnSampleWt = getValue(thisObj, 'txtValenciaCleanSampleWt');
        if (txtVLClnSampleWt !== undefined && txtVLClnSampleWt !== '' && txtVLClnSampleWt !== null) {
          if (isNumeric(txtVLClnSampleWt)) {
            if (txtVLClnSampleWt < 0 || txtVLClnSampleWt > 9999.9) {
              showMessage(thisObj, "Valencia Clean Sample Weight must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Valencia Clean Sample Weight must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        // Location Gram Entry Ind = Y
        let locationData = getData(thisObj, 'locationData');
        let locationId = getValue(thisObj, 'ddLocation');
        let gramEntryInd = locationData.find(x => x.buy_pt_id == locationId).gram_entry_ind;
        if (gramEntryInd == 'Y') {
          // txtSMKRSGr
          let txtSMKRSGr = getValue(thisObj, 'txtSMKRSGr');
          if (txtSMKRSGr !== undefined && txtSMKRSGr !== '' && txtSMKRSGr !== null) {
            if (isNumeric(txtSMKRSGr)) {
              if (txtSMKRSGr < 0 || txtSMKRSGr > 9999.9) {
                showMessage(thisObj, "SMK(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "SMK(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtSoundSplitsGr
          let txtSoundSplitsGr = getValue(thisObj, 'txtSoundSplitsGr');
          if (txtSoundSplitsGr !== undefined && txtSoundSplitsGr !== '' && txtSoundSplitsGr !== null) {
            if (isNumeric(txtSoundSplitsGr)) {
              if (txtSoundSplitsGr < 0 || txtSoundSplitsGr > 9999.9) {
                showMessage(thisObj, "Sound Splits(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Sound Splits(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtOtherKernalsGr
          let txtOtherKernalsGr = getValue(thisObj, 'txtOtherKernelsGr');
          if (txtOtherKernalsGr !== undefined && txtOtherKernalsGr !== '' && txtOtherKernalsGr !== null) {
            if (isNumeric(txtOtherKernalsGr)) {
              if (txtOtherKernalsGr < 0 || txtOtherKernalsGr > 9999.9) {
                showMessage(thisObj, "Other Kernals(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Other Kernals(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtTotalDamageGr
          let txtTotalDamageGr = getValue(thisObj, 'txtTotalDamageGr');
          if (txtTotalDamageGr !== undefined && txtTotalDamageGr !== '' && txtTotalDamageGr !== null) {
            if (isNumeric(txtTotalDamageGr)) {
              if (txtTotalDamageGr < 0 || txtTotalDamageGr > 9999.9) {
                showMessage(thisObj, "Damage(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Damage(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtHullsGr
          let txtHullsGr = getValue(thisObj, 'txtHullsGr');
          if (txtHullsGr !== undefined && txtHullsGr !== '' && txtHullsGr !== null) {
            if (isNumeric(txtHullsGr)) {
              if (txtHullsGr < 0 || txtHullsGr > 9999.9) {
                showMessage(thisObj, "Hulls(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Hulls(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtFreezeDamageGr
          let txtFreezeDamageGr = getValue(thisObj, 'txtFreezeDamageGr');
          if (txtFreezeDamageGr !== undefined && txtFreezeDamageGr !== '' && txtFreezeDamageGr !== null) {
            if (isNumeric(txtFreezeDamageGr)) {
              if (txtFreezeDamageGr < 0 || txtFreezeDamageGr > 9999.9) {
                showMessage(thisObj, "Freeze Damage(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Freeze Damage(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtConcealedRMDGr
          let txtConcealedRMDGr = getValue(thisObj, 'txtConcealedRMDGr');
          if (txtConcealedRMDGr !== undefined && txtConcealedRMDGr !== '' && txtConcealedRMDGr !== null) {
            if (isNumeric((txtConcealedRMDGr))) {
              if (txtConcealedRMDGr < 0 || txtConcealedRMDGr > 9999.9) {
                showMessage(thisObj, "Concealed RMD(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Concealed RMD(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtCrkOrBrkShellsGr
          let txtCrkOrBrkShellsGr = getValue(thisObj, 'txtCrkOrBrkShellsGr');
          if (txtCrkOrBrkShellsGr !== undefined && txtCrkOrBrkShellsGr !== '' && txtCrkOrBrkShellsGr !== null) {
            if (isNumeric((txtCrkOrBrkShellsGr))) {
              if (txtCrkOrBrkShellsGr < 0 || txtCrkOrBrkShellsGr > 9999.9) {
                showMessage(thisObj, "Crk or Brk Shells(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Crk or Brk Shells(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtDiscoloredShellsGr
          let txtDiscoloredShellsGr = getValue(thisObj, 'txtDiscoloredShellsGr');
          if (txtDiscoloredShellsGr !== undefined && txtDiscoloredShellsGr !== '' && txtDiscoloredShellsGr !== null) {
            if (isNumeric((txtDiscoloredShellsGr))) {
              if (txtDiscoloredShellsGr < 0 || txtDiscoloredShellsGr > 9999.9) {
                showMessage(thisObj, "Discolored Shells(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Discolored Shells(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtHullBrightness
          let txtHullBrightness = getValue(thisObj, 'txtHullBrightness');
          if (txtHullBrightness !== undefined && txtHullBrightness !== '' && txtHullBrightness !== null) {
            if (isNumeric((txtHullBrightness))) {
              if (txtHullBrightness < 0 || txtHullBrightness > 9999.9) {
                showMessage(thisObj, "Hull Brightness(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Hull Brightness(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtJumboGr
          let txtJumboGr = getValue(thisObj, 'txtJumboGr');
          if (txtJumboGr !== undefined && txtJumboGr !== '' && txtJumboGr !== null) {
            if (isNumeric((txtJumboGr))) {
              if (txtJumboGr < 0 || txtJumboGr > 9999.9) {
                showMessage(thisObj, "Jumbo(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Jumbo(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtELKGr
          let txtELKGr = getValue(thisObj, 'txtELKGr');
          if (txtELKGr !== undefined && txtELKGr !== '' && txtELKGr !== null) {
            if (isNumeric((txtELKGr))) {
              if (txtELKGr < 0 || txtELKGr > 9999.9) {
                showMessage(thisObj, "ELK(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "ELK(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtForeignMaterialGr
          let txtForeignMaterialGr = getValue(thisObj, 'txtForeignMaterialGr');
          if (txtForeignMaterialGr !== undefined && txtForeignMaterialGr !== '' && txtForeignMaterialGr !== null) {
            if (isNumeric((txtForeignMaterialGr))) {
              if (txtForeignMaterialGr < 0 || txtForeignMaterialGr > 9999.9) {
                showMessage(thisObj, "Foreign Material(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Foreign Material(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtLSKGr
          let txtLSKGr = getValue(thisObj, 'txtLSKGr');
          if (txtLSKGr !== undefined && txtLSKGr !== '' && txtLSKGr !== null) {
            if (isNumeric((txtLSKGr))) {
              if (txtLSKGr < 0 || txtLSKGr > 9999.9) {
                showMessage(thisObj, "LSK(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "LSK(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }

          // txtFancyGr
          let txtFancyGr = getValue(thisObj, 'txtFancyGr');
          if (txtFancyGr !== undefined && txtFancyGr !== '' && txtFancyGr !== null) {
            if (isNumeric((txtFancyGr))) {
              if (txtFancyGr < 0 || txtFancyGr > 9999.9) {
                showMessage(thisObj, "Fancy(grams) must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            } else {
              showMessage(thisObj, "Fancy(grams) must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          }
        }

        // txtDamageSplits
        let txtDamageSplits = getValue(thisObj, 'txtDamageSplits');
        if (txtDamageSplits !== undefined && txtDamageSplits !== '' && txtDamageSplits !== null) {
          if (isNumeric((txtDamageSplits))) {
            if (txtDamageSplits < 0 || txtDamageSplits > 9999.9) {
              showMessage(thisObj, "Damage Splits must be of format ####.#!!!", false);
              isFormValid = false;
              return;
            }
          } else {
            showMessage(thisObj, "Damage Splits must be of format ####.#!!!", false);
            isFormValid = false;
            return;
          }
        }

        let txtHSMK = getValue(thisObj, 'txtSMK')
        if (txtHSMK !== undefined && txtHSMK !== '' && txtHSMK !== null) {
          if (isNumeric((txtHSMK))) {
            if (Number(txtHSMK) < 0 || Number(txtHSMK) > 100) {
              showMessage(thisObj, 'SMKRS(percent) must be of format ###.##!!!');
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'SMKRS(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        let txtSS = getValue(thisObj, 'txtSS')
        if (txtSS !== undefined && txtSS !== '' && txtSS !== null) {
          if (isNumeric((txtSS))) {
            if (Number(txtSS) < 0 || Number(txtSS) > 100) {
              showMessage(thisObj, 'Sound Splits(percent) must be of format ###.##!!!');
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Sound Splits(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        let txtOK = getValue(thisObj, 'txtOK')
        if (txtOK !== undefined && txtOK !== '' && txtOK !== null) {
          if (isNumeric((txtOK))) {
            if (Number(txtOK) < 0 || Number(txtOK) > 100) {
              showMessage(thisObj, 'Other Kernals(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Other Kernals(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }


        let txtDAM = getValue(thisObj, 'txtDAM')
        if (txtDAM !== undefined && txtDAM !== '' && txtDAM !== null) {
          if (isNumeric((txtSS))) {
            if (txtDAM < 0 || txtDAM > 100) {
              showMessage(thisObj, 'Total Damage(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Total Damage(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        let txtHULLS = getValue(thisObj, 'txtHULLS')
        if (txtHULLS !== undefined && txtHULLS !== '' && txtHULLS !== null) {
          if (isNumeric((txtHULLS))) {
            if (Number(txtHULLS) < 0 || Number(txtHULLS) > 100) {
              showMessage(thisObj, 'Hulls(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Hulls(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        if (isEnabled(thisObj, 'txtHullsGr')) {
          let txtCleanSampleWt = Number(getValue(thisObj, 'txtCleanSampleWt'));
          if (txtCleanSampleWt !== 0) {
            if (Number(((thisObj.values.txtHullsGr / txtCleanSampleWt) * 100).toFixed(2)) - 1 > thisObj.values.txtHullsPct || Number(((thisObj.values.txtHullsGr / txtCleanSampleWt) * 100).toFixed(2)) + 1 < thisObj.values.txtHullsPct) {
              showMessage(thisObj, 'Hulls(percent) must be of format within 1 percent of value calculated from grams!!!')
              isFormValid = false;
              return false;
            }
          }
        }

        let txtFRZ = getValue(thisObj, 'txtFRZ')
        if (txtFRZ !== undefined && txtFRZ !== '' && txtFRZ !== null) {
          if (isNumeric((txtFRZ))) {
            if (Number(txtFRZ) < 0 || Number(txtFRZ) > 100) {
              showMessage(thisObj, 'Freeze Damage(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Freeze Damage(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        if (isEnabled(thisObj, 'txtFreezeDamageGr')) {
          let txtCleanSampleWt = Number(getValue(thisObj, 'txtCleanSampleWt'));
          if (txtCleanSampleWt !== 0) {
            if (Number(((thisObj.values.txtFreezeDamageGr / txtCleanSampleWt) * 100).toFixed(2)) - 1 > thisObj.values.txtFreezeDamagePct || Number(((thisObj.values.txtFreezeDamageGr / txtCleanSampleWt) * 100).toFixed(2)) + 1 < thisObj.values.txtFreezeDamagePct) {
              showMessage(thisObj, 'Hulls(percent) must be of format within 1 percent of value calculated from grams!!!')
              isFormValid = false;
              return false;
            }
          }
        }

        let txtCRMD = getValue(thisObj, 'txtCRMD')
        if (txtCRMD !== undefined && txtCRMD !== '' && txtCRMD !== null) {
          if (isNumeric((txtCRMD))) {
            if (Number(txtCRMD) < 0 || Number(txtCRMD) > 100) {
              showMessage(thisObj, 'Concealed RMD(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Concealed RMD(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        if (isEnabled(thisObj, 'txtConcealedRMDGr')) {
          let txtCleanSampleWt = Number(getValue(thisObj, 'txtCleanSampleWt'));
          if (txtCleanSampleWt !== 0) {
            if (Number(((thisObj.values.txtConcealedRMDGr / txtCleanSampleWt) * 100).toFixed(2)) - 1 > thisObj.values.txtConcealedRMDPct || Number(((thisObj.values.txtConcealedRMDGr / txtCleanSampleWt) * 100).toFixed(2)) + 1 < thisObj.values.txtConcealedRMDPct) {
              showMessage(thisObj, 'Concealed RMD(percent) must be of format within 1 percent of value calculated from grams!!!')
              isFormValid = false;
              return false;
            }
          }
        }

        let txtELK = getValue(thisObj, 'txtELK')
        if (txtELK !== undefined && txtELK !== '' && txtELK !== null) {
          if (isNumeric((txtELK))) {
            if (Number(txtELK) < 0 || Number(txtELK) > 100) {
              showMessage(thisObj, 'ELK(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'ELK(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        let txtFM = getValue(thisObj, 'txtFM')
        if (txtFM !== undefined && txtFM !== '' && txtFM !== null) {
          if (isNumeric((txtFM))) {
            if (Number(txtFM) < 0 || Number(txtFM) > 100) {
              showMessage(thisObj, 'Foreign Material(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          } else {
            showMessage(thisObj, 'Foreign Material(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        let txtLSK = getValue(thisObj, 'txtLSK')
        if (txtLSK !== undefined && txtLSK !== '' && txtLSK !== null) {
          if (isNumeric((txtLSK))) {
            if (Number(txtLSK) < 0 || Number(txtLSK) > 100) {
              showMessage(thisObj, 'LSK(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          }
          else {
            showMessage(thisObj, 'LSK(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        let txtFANCY = getValue(thisObj, 'txtFANCY')
        if (txtFANCY !== undefined && txtFANCY !== '' && txtFANCY !== null) {
          if (isNumeric((txtFANCY))) {
            if (Number(txtFANCY) < 0 || Number(txtFANCY) > 100) {
              showMessage(thisObj, 'Fancy(percent) must be of format ###.##!!!')
              isFormValid = false;
              return false;
            }
          }
          else {
            showMessage(thisObj, 'Fancy(percent) must be of format ###.##!!!')
            isFormValid = false;
            return false;
          }
        }

        // Peanut Type
        let peanutDescription = null;
        let peanutlist = thisObj.state['ddPeanutType'].valueList;
        let peanutvalue = thisObj.values['ddPeanutType'];
        if (peanutvalue !== undefined && peanutvalue !== null) {
          peanutDescription = peanutlist.find(elem => elem.key == peanutvalue).description;
          if (peanutDescription == 'VA') {
            let txtSampleWt = getValue(thisObj, 'txtSampleWt');
            let txtGrams = getValue(thisObj, 'txtGrams');
            if ((txtSampleWt !== "" || txtSampleWt !== undefined) && (txtGrams !== "" || txtGrams !== undefined)) {
              if (isNumeric((txtSampleWt))) {
                if (txtSampleWt < 0 || txtSampleWt > 9999.9) {
                  showMessage(thisObj, "Virginia Cracked/Broken Sample Weight must be of format ####.#!!!", false);
                  isFormValid = false;
                  return;
                }
              } else {
                showMessage(thisObj, "Virginia Cracked/Broken Sample Weight must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }

              if (isNumeric((txtGrams))) {
                if (txtGrams < 0 || txtGrams > 9999.9) {
                  showMessage(thisObj, "Virginia Cracked/Broken grams must be of format ####.#!!!", false);
                  isFormValid = false;
                  return;
                }
              } else {
                showMessage(thisObj, "Virginia Cracked/Broken grams must be of format ####.#!!!", false);
                isFormValid = false;
                return;
              }
            }
          }
        }

        if (bFullDataCheck) {
          if (thisObj.values['gridWeightTickets'].length < 1) {
            showMessage(thisObj, "Must have at least 1 scale ticket!!!", false);
            isFormValid = false;
            return;
          }
          if (dblMinKernHullPct !== 0) {
            let txtTotalKernelsHulls = getValue(thisObj, 'txtTotalKernelsHulls');
            if (txtTotalKernelsHulls !== undefined || txtTotalKernelsHulls !== '' || txtTotalKernelsHulls !== null) {
              txtTotalKernelsHulls = 0;
            }
            if (Number(txtTotalKernelsHulls) < Number(dblMinKernHullPct)) {
              showMessage(thisObj, "Total Kernals and Hulls outside acceptable range!!!", false);
              isFormValid = false;
              return;
            }
          }

          if (dblMaxKernHullPct !== 0) {
            let txtTotalKernelsHulls = getValue(thisObj, 'txtTotalKernelsHulls');
            if (txtTotalKernelsHulls !== undefined || txtTotalKernelsHulls !== '' || txtTotalKernelsHulls !== null) {
              txtTotalKernelsHulls = 0;
            }
            if (Number(txtTotalKernelsHulls) < Number(dblMaxKernHullPct)) {
              showMessage(thisObj, "Total Kernals and Hulls outside acceptable range!!!", false);
              isFormValid = false;
              return;
            }
          }

          let bSpecsExist = false;
          let ddLocation = getValue(thisObj, 'ddLocation');
          let ddPeanutType = getValue(thisObj, 'ddPeanutType');
          let seed_ind = 'N';
          let ddSeg = getValue(thisObj, 'ddSeg');
          let ddEdibleOil = getValue(thisObj, 'ddEdibleOil');
          let ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety');
          let specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, ddPeanutVariety, seed_ind, ddSeg, ddEdibleOil);
          if (specException.length > 0) {
            specException = specException[0];
            bSpecsExist = true;
          } else {
            specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, null, seed_ind, ddSeg, ddEdibleOil);
            if (specException.length > 0) {
              specException = specException[0];
              bSpecsExist = true;
            } else {
              let locationData = getData(thisObj, 'locationData');
              let area_id = locationData.find(x => x.buy_pt_id == ddLocation).area_id;
              specException = await SettlementService.RetrievePeanutSpecificationDetails(area_id, ddPeanutType, seed_ind, ddSeg, ddEdibleOil);
              if (specException.length > 0) {
                specException = specException[0];
                bSpecsExist = true;
              }
            }
          }

          if (bSpecsExist) {
            if (specException.corn_ind !== "" && specException.corn_ind !== undefined) {
              let chkboxCornFound = document.getElementById('chkboxCornFound').checked;
              if ((chkboxCornFound == true && specException.corn_ind !== "Y") || (chkboxCornFound == false && specException.corn_ind !== "N")) {
                showMessage(thisObj, "Corn found does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (specException.flavus_ind !== "" && specException.flavus_ind !== undefined) {
              let chkboxFlavusFound = document.getElementById('chkboxFlavusFound').checked;
              if ((chkboxFlavusFound == true && specException.flavus_ind !== "Y") || (chkboxFlavusFound == false && specException.flavus_ind !== "N")) {
                showMessage(thisObj, "Flavus found does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_vicam_ppb) !== 0 && !isNaN(specException.min_vicam_ppb)) {
              let txtReading = getValue(thisObj, 'txtReading');
              if (Number(txtReading) < Number(specException.min_vicam_ppb)) {
                showMessage(thisObj, "Vicam does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_smk_pct) !== 0 && !isNaN(specException.min_smk_pct)) {
              let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
              if (Number(txtSMKRSPct) < Number(specException.min_smk_pct)) {
                showMessage(thisObj, "SMK does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_ss_pct) !== 0 && !isNaN(specException.min_ss_pct)) {
              let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
              if (Number(txtSoundSplitsPct) < Number(specException.min_ss_pct)) {
                showMessage(thisObj, "Sound Splits does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_ok_pct) !== 0 && !isNaN(specException.min_ok_pct)) {
              let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
              if (Number(txtOtherKernelsPct) < Number(specException.min_ok_pct)) {
                showMessage(thisObj, "Other Kernals does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_dam_pct) !== 0 && !isNaN(specException.min_dam_pct)) {
              let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
              if (Number(txtTotalDamagePct) < Number(specException.min_dam_pct)) {
                showMessage(thisObj, "Damage does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_hull_pct) !== 0 && !isNaN(specException.min_hull_pct)) {
              let txtHullsPct = getValue(thisObj, 'txtHullsPct');
              if (Number(txtHullsPct) < Number(specException.min_hull_pct)) {
                showMessage(thisObj, "Hulls does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_frez_dam_pct) !== 0 && !isNaN(specException.min_frez_dam_pct)) {
              let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
              if (Number(txtFreezeDamagePct) < Number(specException.min_frez_dam_pct)) {
                showMessage(thisObj, "Freeze Damage does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_cncl_rmd_pct) !== 0 && !isNaN(specException.min_cncl_rmd_pct)) {
              let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
              if (Number(txtConcealedRMDPct) < Number(specException.min_cncl_rmd_pct)) {
                showMessage(thisObj, "Concealed RMD does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_cr_br_pct) !== 0 && !isNaN(specException.min_cr_br_pct)) {
              let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
              if (Number(txtCrkOrBrkShellsPct) < Number(specException.min_cr_br_pct)) {
                showMessage(thisObj, "Crk or Brk Shells does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_dcol_shel_pct) !== 0 && !isNaN(specException.min_dcol_shel_pct)) {
              let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
              if (Number(txtDiscoloredShellsPct) < Number(specException.min_dcol_shel_pct)) {
                showMessage(thisObj, "Discolored Shells does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_hul_brit_pct) !== 0 && !isNaN(specException.min_hul_brit_pct)) {
              let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
              if (Number(txtHullBrightnessPct) < Number(specException.min_hul_brit_pct)) {
                showMessage(thisObj, "Hull Brightness does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_jumbo_pct) !== 0 && !isNaN(specException.min_jumbo_pct)) {
              let txtJumboPct = getValue(thisObj, 'txtJumboPct');
              if (Number(txtJumboPct) < Number(specException.min_jumbo_pct)) {
                showMessage(thisObj, "Jumbo does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_elk_pct) !== 0 && !isNaN(specException.min_elk_pct)) {
              let txtELKPct = getValue(thisObj, 'txtELKPct');
              if (Number(txtELKPct) < Number(specException.min_elk_pct)) {
                showMessage(thisObj, "ELK does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_fm_pct) !== 0 && !isNaN(specException.min_fm_pct)) {
              let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
              if (Number(txtForeignMaterialPct) < Number(specException.min_fm_pct)) {
                showMessage(thisObj, "Foreign Material does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.min_lsk_pct) !== 0 && !isNaN(specException.min_lsk_pct)) {
              let txtLSKPct = getValue(thisObj, 'txtLSKPct');
              if (Number(txtLSKPct) < Number(specException.min_lsk_pct)) {
                showMessage(thisObj, "LSK does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (strHMC == 'Y') {
              if (Number(specException.min_moist_pct) !== 0 && !isNaN(specException.min_moist_pct)) {
                let txtMoisture = getValue(thisObj, 'txtMoisture');
                if (Number(txtMoisture) < Number(specException.min_moist_pct)) {
                  showMessage(thisObj, "Moisture does not match peanut specifications!!!", false);
                  isFormValid = false;
                  return;
                }
              }
            }

            if (Number(specException.min_fan_pct) !== 0 && !isNaN(specException.min_fan_pct)) {
              let txtFancyPct = getValue(thisObj, 'txtFancyPct');
              if (Number(txtFancyPct) < Number(specException.min_fan_pct)) {
                showMessage(thisObj, "Fancy does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_vicam_ppb) !== 0 && !isNaN(specException.max_vicam_ppb)) {
              let txtReading = getValue(thisObj, 'txtReading');
              if (Number(txtReading) > Number(specException.max_vicam_ppb)) {
                showMessage(thisObj, "Vicam does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_smk_pct) !== 0 && !isNaN(specException.max_smk_pct)) {
              let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
              if (Number(txtSMKRSPct) > Number(specException.max_smk_pct)) {
                showMessage(thisObj, "SMK does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_ss_pct) !== 0 && !isNaN(specException.max_ss_pct)) {
              let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
              if (Number(txtSoundSplitsPct) > Number(specException.max_ss_pct)) {
                showMessage(thisObj, "Sound Splits does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_ok_pct) !== 0 && !isNaN(specException.max_ok_pct)) {
              let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
              if (Number(txtOtherKernelsPct) > Number(specException.max_ok_pct)) {
                showMessage(thisObj, "Other Kernals does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_dam_pct) !== 0 && !isNaN(specException.max_dam_pct)) {
              let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
              if (Number(txtTotalDamagePct) > Number(specException.max_dam_pct)) {
                showMessage(thisObj, "Damage does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_hull_pct) !== 0 && !isNaN(specException.max_hull_pct)) {
              let txtHullsPct = getValue(thisObj, 'txtHullsPct');
              if (Number(txtHullsPct) > Number(specException.max_hull_pct)) {
                showMessage(thisObj, "Hulls does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_frez_dam_pct) !== 0 && !isNaN(specException.max_frez_dam_pct)) {
              let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
              if (Number(txtFreezeDamagePct) > Number(specException.max_frez_dam_pct)) {
                showMessage(thisObj, "Freeze Damage does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_cncl_rmd_pct) !== 0 && !isNaN(specException.max_cncl_rmd_pct)) {
              let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
              if (Number(txtConcealedRMDPct) > Number(specException.max_cncl_rmd_pct)) {
                showMessage(thisObj, "Concealed RMD does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_cr_br_pct) !== 0 && !isNaN(specException.max_cr_br_pct)) {
              let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
              if (Number(txtCrkOrBrkShellsPct) > Number(specException.max_cr_br_pct)) {
                showMessage(thisObj, "Crk or Brk Shells does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_dcol_shel_pct) !== 0 && !isNaN(specException.max_dcol_shel_pct)) {
              let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
              if (Number(txtDiscoloredShellsPct) > Number(specException.max_dcol_shel_pct)) {
                showMessage(thisObj, "Discolored Shells does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_hul_brit_pct) !== 0 && !isNaN(specException.max_hul_brit_pct)) {
              let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
              if (Number(txtHullBrightnessPct) > Number(specException.max_hul_brit_pct)) {
                showMessage(thisObj, "Hull Brightness does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_jumbo_pct) !== 0 && !isNaN(specException.max_jumbo_pct)) {
              let txtJumboPct = getValue(thisObj, 'txtJumboPct');
              if (Number(txtJumboPct) > Number(specException.max_jumbo_pct)) {
                showMessage(thisObj, "Jumbo does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_elk_pct) !== 0 && !isNaN(specException.max_elk_pct)) {
              let txtELKPct = getValue(thisObj, 'txtELKPct');
              if (Number(txtELKPct) > Number(specException.max_elk_pct)) {
                showMessage(thisObj, "ELK does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_fm_pct) !== 0 && !isNaN(specException.max_fm_pct)) {
              let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
              if (Number(txtForeignMaterialPct) > Number(specException.max_fm_pct)) {
                showMessage(thisObj, "Foreign Material does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (Number(specException.max_lsk_pct) !== 0 && !isNaN(specException.max_lsk_pct)) {
              let txtLSKPct = getValue(thisObj, 'txtLSKPct');
              if (Number(txtLSKPct) > Number(specException.max_lsk_pct)) {
                showMessage(thisObj, "LSK does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }

            if (strHMC !== 'Y') {
              if (Number(specException.max_moist_pct) !== 0 && !isNaN(specException.max_moist_pct)) {
                let txtMoisture = getValue(thisObj, 'txtMoisture');
                if (Number(txtMoisture) > Number(specException.max_moist_pct)) {
                  showMessage(thisObj, "Moisture does not match peanut specifications!!!", false);
                  isFormValid = false;
                  return;
                }
              }
            }

            if (Number(specException.max_fan_pct) !== 0 && !isNaN(specException.max_fan_pct)) {
              let txtFancyPct = getValue(thisObj, 'txtFancyPct');
              if (Number(txtFancyPct) > Number(specException.max_fan_pct)) {
                showMessage(thisObj, "Fancy does not match peanut specifications!!!", false);
                isFormValid = false;
                return;
              }
            }
          } else {
            showMessage(thisObj, 'No peanut specification record exists matching supplied information. Please add record to the peanut specifiction control table!!!', false);
            isFormValid = false;
            return;
          }

          // Generation & Seg Validation
          let generation = getValue(thisObj, 'ddSeedGen');
          let seg = getValue(thisObj, 'ddSeg');
          if ((generation !== '' && generation !== undefined) && (seg == '1')) {
            bSpecsExist = false;
            // 2 API Will call from here
            let ddLocation = getValue(thisObj, 'ddLocation');
            let ddPeanutType = getValue(thisObj, 'ddPeanutType');
            let seed_ind = 'Y';
            let ddSeg = getValue(thisObj, 'ddSeg');
            let ddEdibleOil = getValue(thisObj, 'ddEdibleOil');
            let ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety');
            let specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, ddPeanutVariety, seed_ind, ddSeg, ddEdibleOil);
            if (specException.length > 0) {
              specException = specException[0];
              bSpecsExist = true;
            } else {
              specException = await SettlementService.RetrievePeanutSpecificationExceptionDetails(ddLocation, ddPeanutType, null, seed_ind, ddSeg, ddEdibleOil);
              if (specException.length > 0) {
                specException = specException[0];
                bSpecsExist = true;
              } else {
                let locationData = getData(thisObj, 'locationData');
                let area_id = locationData.find(x => x.buy_pt_id == ddLocation).area_id;
                specException = await SettlementService.RetrievePeanutSpecificationDetails(area_id, ddPeanutType, seed_ind, ddSeg, ddEdibleOil);
                if (specException.length > 0) {
                  specException = specException[0];
                  bSpecsExist = true;
                }
              }
            }

            meetingSpec = false;
            setValue(thisObj, 'txtSeedMeetingSpec', '')
            if (bSpecsExist) {
              if (specException.corn_ind !== "" && specException.corn_ind !== undefined) {
                let chkboxCornFound = document.getElementById('chkboxCornFound').checked;
                if ((chkboxCornFound == true && specException.corn_ind !== "Y") || (chkboxCornFound == false && specException.corn_ind !== "N")) {
                  if (confirm("Corn found does not match seed specifications? Click on OK to proceed else click on Cancel.") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'.", 'Cancel', "Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }

                }
              }

              // flavus_ind
              if (specException.flavus_ind !== "" && specException.flavus_ind !== undefined) {
                let chkboxFlavusFound = document.getElementById('chkboxFlavusFound').checked;
                if ((chkboxFlavusFound == true && specException.flavus_ind !== "Y") || (chkboxFlavusFound == false && specException.flavus_ind !== "N")) {
                  if (confirm("Flavus found does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit., Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'.", 'Cancel', "Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_vicam_ppb
              if (Number(specException.min_vicam_ppb) !== 0 && !isNaN(specException.min_vicam_ppb)) {
                let txtReading = getValue(thisObj, 'txtReading');
                if (Number(txtReading) < Number(specException.min_vicam_ppb)) {
                  if (confirm("Vicam does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit., Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'.", 'Cancel', "Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_smk_pct
              if (Number(specException.min_smk_pct) !== 0 && !isNaN(specException.min_smk_pct)) {
                let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
                if (Number(txtSMKRSPct) < Number(specException.min_smk_pct)) {
                  if (confirm("SMK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '')
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_ss_pct
              if (Number(specException.min_ss_pct) !== 0 && !isNaN(specException.min_ss_pct)) {
                let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
                if (Number(txtSoundSplitsPct) < Number(specException.min_ss_pct)) {
                  if (confirm("Sound Splits does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_ok_pct
              if (Number(specException.min_ok_pct) !== 0 && !isNaN(specException.min_ok_pct)) {
                let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
                if (Number(txtOtherKernelsPct) < Number(specException.min_ok_pct)) {
                  if (confirm("Other Kernals does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_dam_pct
              if (Number(specException.min_dam_pct) !== 0 && !isNaN(specException.min_dam_pct)) {
                let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
                if (Number(txtTotalDamagePct) < Number(specException.min_dam_pct)) {
                  if (confirm("Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_hull_pct
              if (Number(specException.min_hull_pct) !== 0 && !isNaN(specException.min_hull_pct)) {
                let txtHullsPct = getValue(thisObj, 'txtHullsPct');
                if (Number(txtHullsPct) < Number(specException.min_hull_pct)) {
                  if (confirm("Hulls does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_frez_dam_pct
              if (Number(specException.min_frez_dam_pct) !== 0 && !isNaN(specException.min_frez_dam_pct)) {
                let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
                if (Number(txtFreezeDamagePct) < Number(specException.min_frez_dam_pct)) {
                  if (confirm("Freeze Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_cncl_rmd_pct
              if (Number(specException.min_cncl_rmd_pct) !== 0 && !isNaN(specException.min_cncl_rmd_pct)) {
                let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
                if (Number(txtConcealedRMDPct) < Number(specException.min_cncl_rmd_pct)) {
                  if (confirm("Concealed RMD does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_cr_br_pct
              if (Number(specException.min_cr_br_pct) !== 0 && !isNaN(specException.min_cr_br_pct)) {
                let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
                if (Number(txtCrkOrBrkShellsPct) < Number(specException.min_cr_br_pct)) {
                  if (confirm("Crk or Brk Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_dcol_shel_pct
              if (Number(specException.min_dcol_shel_pct) !== 0 && !isNaN(specException.min_dcol_shel_pct)) {
                let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
                if (Number(txtDiscoloredShellsPct) < Number(specException.min_dcol_shel_pct)) {
                  if (confirm("Discolored Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_hul_brit_pct
              if (Number(specException.min_hul_brit_pct) !== 0 && !isNaN(specException.min_hul_brit_pct)) {
                let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
                if (Number(txtHullBrightnessPct) < Number(specException.min_hul_brit_pct)) {
                  if (confirm("Hull Brightness does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_jumbo_pct
              if (Number(specException.min_jumbo_pct) !== 0 && !isNaN(specException.min_jumbo_pct)) {
                let txtJumboPct = getValue(thisObj, 'txtJumboPct');
                if (Number(txtJumboPct) < Number(specException.min_jumbo_pct)) {
                  if (confirm("Jumbo does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_elk_pct
              if (Number(specException.min_elk_pct) !== 0 && !isNaN(specException.min_elk_pct)) {
                let txtELKPct = getValue(thisObj, 'txtELKPct');
                if (Number(txtELKPct) < Number(specException.min_elk_pct)) {
                  if (confirm("ELK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_fm_pct
              if (Number(specException.min_fm_pct) !== 0 && !isNaN(specException.min_fm_pct)) {
                let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
                if (Number(txtForeignMaterialPct) < Number(specException.min_fm_pct)) {
                  if (confirm("Foreign Material does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_fm_pct
              if (Number(specException.min_lsk_pct) !== 0 && !isNaN(specException.min_lsk_pct)) {
                let txtLSKPct = getValue(thisObj, 'txtLSKPct');
                if (Number(txtLSKPct) < Number(specException.min_lsk_pct)) {
                  if (confirm("LSK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // min_fm_pct
              if (strHMC !== 'Y') {
                if (Number(specException.min_moist_pct) !== 0 && !isNaN(specException.min_moist_pct)) {
                  let txtMoisture = getValue(thisObj, 'txtMoisture');
                  if (Number(txtMoisture) < Number(specException.min_moist_pct)) {
                    if (confirm("Moisture does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                      if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                        //setValue(thisObj, 'ddSeedGen', '');
                        setseedmeetingspecasno = true;
                      } else {
                        meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                      }
                    }
                  }
                }
              }

              // min_fm_pct
              if (Number(specException.min_fan_pct) !== 0 && !isNaN(specException.min_fan_pct)) {
                let txtFancyPct = getValue(thisObj, 'txtFancyPct');
                if (Number(txtFancyPct) < Number(specException.min_fan_pct)) {
                  if (confirm("Fancy does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_vicam_ppb
              if (Number(specException.max_vicam_ppb) !== 0 && !isNaN(specException.max_vicam_ppb)) {
                let txtReading = getValue(thisObj, 'txtReading');
                if (Number(txtReading) > Number(specException.max_vicam_ppb)) {
                  if (confirm("Vicam does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_smk_pct
              if (Number(specException.max_smk_pct) !== 0 && !isNaN(specException.max_smk_pct)) {
                let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
                if (Number(txtSMKRSPct) > Number(specException.max_smk_pct)) {
                  if (confirm("SMK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_ss_pct
              if (Number(specException.max_ss_pct) !== 0 && !isNaN(specException.max_ss_pct)) {
                let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
                if (Number(txtSoundSplitsPct) > Number(specException.max_ss_pct)) {
                  if (confirm("Sound Splits does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_ok_pct
              if (Number(specException.max_ok_pct) !== 0 && !isNaN(specException.max_ok_pct)) {
                let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
                if (Number(txtOtherKernelsPct) > Number(specException.max_ok_pct)) {
                  if (confirm("Other Kernals does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_dam_pct
              if (Number(specException.max_dam_pct) !== 0 && !isNaN(specException.max_dam_pct)) {
                let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
                if (Number(txtTotalDamagePct) > Number(specException.max_dam_pct)) {
                  if (confirm("Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_hull_pct
              if (Number(specException.max_hull_pct) !== 0 && !isNaN(specException.max_hull_pct)) {
                let txtHullsPct = getValue(thisObj, 'txtHullsPct');
                if (Number(txtHullsPct) > Number(specException.max_hull_pct)) {
                  if (confirm("Hulls does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_frez_dam_pct
              if (Number(specException.max_frez_dam_pct) !== 0 && !isNaN(specException.max_frez_dam_pct)) {
                let txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct');
                if (Number(txtFreezeDamagePct) > Number(specException.max_frez_dam_pct)) {
                  if (confirm("Freeze Damage does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_cncl_rmd_pct
              if (Number(specException.max_cncl_rmd_pct) !== 0 && !isNaN(specException.max_cncl_rmd_pct)) {
                let txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct');
                if (Number(txtConcealedRMDPct) > Number(specException.max_cncl_rmd_pct)) {
                  if (confirm("Concealed RMD does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_cncl_rmd_pct
              if (Number(specException.max_cr_br_pct) !== 0 && !isNaN(specException.max_cr_br_pct)) {
                let txtCrkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct');
                if (Number(txtCrkOrBrkShellsPct) > Number(specException.max_cr_br_pct)) {
                  if (confirm("Crk or Brk Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_dcol_shel_pct
              if (Number(specException.max_dcol_shel_pct) !== 0 && !isNaN(specException.max_dcol_shel_pct)) {
                let txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct');
                if (Number(txtDiscoloredShellsPct) > Number(specException.max_dcol_shel_pct)) {
                  if (confirm("Discolored Shells does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_hul_brit_pct
              if (Number(specException.max_hul_brit_pct) !== 0 && !isNaN(specException.max_hul_brit_pct)) {
                let txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct');
                if (Number(txtHullBrightnessPct) > Number(specException.max_hul_brit_pct)) {
                  if (confirm("Hull Brightness does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_jumbo_pct
              if (Number(specException.max_jumbo_pct) !== 0 && !isNaN(specException.max_jumbo_pct)) {
                let txtJumboPct = getValue(thisObj, 'txtJumboPct');
                if (Number(txtJumboPct) > Number(specException.max_jumbo_pct)) {
                  if (confirm("Jumbo does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_elk_pct
              if (Number(specException.max_elk_pct) !== 0 && !isNaN(specException.max_elk_pct)) {
                let txtELKPct = getValue(thisObj, 'txtELKPct');
                if (Number(txtELKPct) > Number(specException.max_elk_pct)) {
                  if (confirm("ELK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_fm_pct
              if (Number(specException.max_fm_pct) !== 0 && !isNaN(specException.max_fm_pct)) {
                let txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct');
                if (Number(txtForeignMaterialPct) > Number(specException.max_fm_pct)) {
                  if (confirm("Foreign Material does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_lsk_pct
              if (Number(specException.max_lsk_pct) !== 0 && !isNaN(specException.max_lsk_pct)) {
                let txtLSKPct = getValue(thisObj, 'txtLSKPct');
                if (Number(txtLSKPct) > Number(specException.max_lsk_pct)) {
                  if (confirm("LSK does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                     // setValue(thisObj, 'ddSeedGen', '');
                     setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              // max_moist_pct
              if (strHMC !== 'Y') {
                if (Number(specException.max_moist_pct) !== 0 && !isNaN(specException.max_moist_pct)) {
                  let txtMoisture = getValue(thisObj, 'txtMoisture');
                  if (Number(txtMoisture) > Number(specException.max_moist_pct)) {
                    if (confirm("Moisture does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                      if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                        //setValue(thisObj, 'ddSeedGen', '');
                        setseedmeetingspecasno = true;
                      } else {
                        meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                      }
                    }
                  }
                }
              }

              // max_fan_pct
              if (Number(specException.max_fan_pct) !== 0 && !isNaN(specException.max_fan_pct)) {
                let txtFancyPct = getValue(thisObj, 'txtFancyPct');
                if (Number(txtFancyPct) > Number(specException.max_fan_pct)) {
                  if (confirm("Fancy does not match seed specifications!!! Click 'OK' to continue. Click 'Cancel' to edit. Invalid grade for seed specification!!!") == true) {
                    if (confirm("If this load will not be dumped in a seed warehouse, click 'Cancel' to remove the seed generation and change the warehouse/bin. If it will be dumped in the seed warehouse, click 'OK'. Load does not meet seed specification!!!") == true) {
                      //setValue(thisObj, 'ddSeedGen', '');
                      setseedmeetingspecasno = true;
                    } else {
                      meetingSpec = true;
					            setValue(thisObj, 'ddSeedGen', '');
					            setValue(thisObj, 'ddWhouseBin', '');
					            setValue(thisObj, 'ddBuyingPt', '');
					            setValue(thisObj, 'ddObligationPt', '');
                      setValue(thisObj, 'txtSeedMeetingSpec', '');
                      document.getElementById('txtSeedMeetingSpec').value='';
					            return;
                    }
                  }
                }
              }

              if (meetingSpec) {
                setlblSeedMeetingSpecification('No');
                setValue(thisObj, 'txtSeedMeetingSpec', 'No')
                document.getElementById('txtSeedMeetingSpec').value='No'
              } else {
                if(setseedmeetingspecasno == true){
                setlblSeedMeetingSpecification('No');
                setValue(thisObj, 'txtSeedMeetingSpec', 'No')
                document.getElementById('txtSeedMeetingSpec').value='No'
              }else{
                setlblSeedMeetingSpecification('Yes');
                setValue(thisObj, 'txtSeedMeetingSpec', 'Yes')
                document.getElementById('txtSeedMeetingSpec').value='Yes'
              }
              }
            } else {
              alert('"No seed specification record exists matching supplied information. Please add record to the peanut specifiction control table!!!", 48, "Invalid grade for seed specification!!!');
              isFormValid = false;
              return;
            }
          } else {
            setlblSeedMeetingSpecification('');
            setValue(thisObj, 'txtSeedMeetingSpec', '')
          }
        }
      }

      if (strFV95Status == 'X') {
        let objData = {
          'FV95Number': txtFV95,
          'BuyingPoint': getValue(thisObj, 'ddLocation')
        }
        setData(thisObj, 'NoSaleVoidFV95', objData);
        goTo(thisObj, 'Settlements#NoSaleVoidFV95#DEFAULT#true#Click');
        return true;

        // This API we will cll from useeffect.
        // We will get response from NoSaleVoidFV95 Screen.
      }

      isFormValid = true;
    } catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, 'Exception in bFormValid_HMC function')
    }
  }

  function ConvertToDate(str) {
    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }

  const CalculateTotals = (gridData) => {
    let totalLBS = 0, ticket;

    for (let i = 0; i < 5; i++) {
      if (gridData.length > i) {
        ticket = i;
        totalLBS += parseFloat(gridData[i].txtcolWtOfPeanuts);
      }
    }
    setValue(thisObj, 'txtTickets', ticket + 1);
    setValue(thisObj, 'txtTotalLBs', totalLBS);

    let TotalDollars = Number(useTotalDollars).toFixed(2);

    setValue(thisObj, 'txtTotalDollar', TotalDollars);

    if (gridData.length <= 5) {
      enable(thisObj, 'btnAdd');
    }
    else {
      disable(thisObj, 'btnAdd');
    }

    let txtSMKRSPct = getValue(thisObj, 'txtSMKRSPct');
    if (txtSMKRSPct == undefined || txtSMKRSPct == '' || txtSMKRSPct == null) {
      txtSMKRSPct = 0;
    }
    let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct');
    if (txtSoundSplitsPct == undefined || txtSoundSplitsPct == '' || txtSoundSplitsPct == null) {
      txtSoundSplitsPct = 0;
    }
    let txtOtherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct');
    if (txtOtherKernelsPct == undefined || txtOtherKernelsPct == '' || txtOtherKernelsPct == null) {
      txtOtherKernelsPct = 0;
    }
    let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
    if (txtTotalDamagePct == undefined || txtTotalDamagePct == '' || txtTotalDamagePct == null) {
      txtTotalDamagePct = 0;
    }
    let txtHullsPct = getValue(thisObj, 'txtHullsPct');
    if (txtHullsPct == undefined || txtHullsPct == '' || txtHullsPct == null) {
      txtHullsPct = 0;
    }
    let txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt');
    if (txtFMSampleWt == undefined || txtFMSampleWt == '' || txtFMSampleWt == null) {
      txtFMSampleWt = 0;
    }
    let txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt');
    if (txtCleanSampleWt == undefined || txtCleanSampleWt == '' || txtCleanSampleWt == null) {
      txtCleanSampleWt = 0;
    }
    if (gridData.length > 0 &&
      isNumeric(txtSMKRSPct) &&
      isNumeric(txtSoundSplitsPct) &&
      isNumeric(txtOtherKernelsPct) &&
      isNumeric(txtTotalDamagePct) &&
      isNumeric(txtHullsPct) &&
      isNumeric(txtFMSampleWt) &&
      isNumeric(txtCleanSampleWt)) {
      enable(thisObj, 'btnCalculate');
    }
    else {
      disable(thisObj, 'btnCalculate');
    }
  }

  const bScaleTicketValid = (data) => {
    try {
      if (yesNo == false) {
        // Dry Scale Ticket Validation
        if (data[0].trim() == "" || data[0].trim() == 'undefined') {
          showMessage(thisObj, "Dry Scale Ticket # is a required field");
          return false;
        }

        // Dry Ticket Date Validation
        let dryDate = ConvertToDate(data[1]);
        if (!isValidDate(dryDate)) {
          showMessage(thisObj, "Invalid Dry Ticket Date");
          return false;
        }

        // Dry Weight Including Vehicle Validation
        if (data[5].trim() == "" || data[5].trim() == 'undefined') {
          showMessage(thisObj, "Dry Weight Including Vehicle is a required field");
          return false;
        }
        if (isNumeric(data[5].trim())) {
          if (parseInt(data[5]) < 0 || parseInt(data[5]) > 999999999) {
            showMessage(thisObj, "Dry Weight Including Vehicle must be of format #########");
            return false;
          }
        } else {
          showMessage(thisObj, "Dry Weight Including Vehicle must be of format #########");
          return false;
        }

        // Dry Weight Of Vehicle Validation
        if (data[6].trim() == "" || data[6].trim() == 'undefined') {
          showMessage(thisObj, "Dry Weight Of Vehicle is a required field");
          return false;
        }
        if (isNumeric(data[6].trim())) {
          if (parseInt(data[6]) < 0 || parseInt(data[6]) > 999999999) {
            return false;
          }
        } else {
          showMessage(thisObj, "Dry Weight of Vehicle must be of format #########");
          return false;
        }

        // Dry Weight Of Peanuts Validation
        if (data[7].trim() == "" || data[7].trim() == 'undefined') {
          showMessage(thisObj, "Dry Weight Of Peanuts is a required field");
          return false;
        }
        if (isNumeric(data[7].trim())) {
          if (parseInt(data[7]) <= 0 || parseInt(data[7]) > 99999) {
            showMessage(thisObj, "Dry Weight Of Peanuts must be between 0 and 99,999");
            return false;
          }
        } else {
          showMessage(thisObj, "Dry Weight Of Peanuts must be between 0 and 99,999");
          return false;
        }

        // Green Ticket Date Validation
        if (data[9].trim() != "" && data[9].trim() !== 'undefined') {
          let greenDate = ConvertToDate(data[9]);
          if (!isValidDate(greenDate)) {
            showMessage(thisObj, "Invalid Green Ticket Date");
            return false;
          }
        }

        // Green Weight Including Vehicle Validation
        if (data[13].trim() != "" && data[13].trim() !== 'undefined') {
          if (!isNumeric(data[13])) {
            return false;
          } else {
            if (parseInt(data[13]) < 0 || parseInt(data[13]) > 999999999) {
              showMessage(thisObj, "Green Weight Including Vehicle must be of format #########");
              return false;
            }
          }
        }

        // Green Weight Of Vehicle Validation
        if (data[14].trim() != "" && data[14].trim() !== 'undefined') {
          if (!isNumeric(data[14])) {
            showMessage(thisObj, "Green Weight Of Vehicle must be of format #########");
            return false;
          } else {
            if (parseInt(data[14]) < 0 || parseInt(data[14]) > 999999999) {
              showMessage(thisObj, "Green Weight Including Vehicle must be of format #########");
              return false;
            }
          }
        }

        // Green Weight Of Peanuts Validation
        if (data[15].trim() != "" && data[15].trim() !== 'undefined') {
          if (isNumeric(data[15].trim())) {
            if (parseInt(data[15]) < 0 || parseInt(data[15]) > 99999) {
              showMessage(thisObj, "Green Weight Of Peanuts must be between 0 and 99,999");
              return false;
            }
          } else {
            showMessage(thisObj, "Green Weight Of Peanuts must be between 0 and 99,999");
            return false;
          }
        }

        // Weigher is Required Validation
        if (data[16].trim() == "" && data[16].trim() == 'undefined') {
          showMessage(thisObj, "Weigher is Required");
          return false;
        }
      } else {
        // Invalid Green Ticket Date Validation
        let greenDate = ConvertToDate(data[9]);
        if (!isValidDate(greenDate)) {
          showMessage(thisObj, "Invalid Green Ticket Date");
          return false;
        }

        // Green Weight Including Vehicle Validation
        if (data[13].trim() != "" && data[13].trim() !== 'undefined') {
          if (!isNumeric(data[13])) {
            showMessage(thisObj, "Green Weight Including Vehicle must be of format #########");
            return false;
          } else {
            if (parseInt(data[13]) < 0 || parseInt(data[13]) > 999999999) {
              showMessage(thisObj, "Green Weight Including Vehicle must be of format #########");
              return false;
            }
          }
        }

        // Green Weight Of Vehicle Validation
        if (data[14].trim() != "" && data[14].trim() !== 'undefined') {
          if (!isNumeric(data[14])) {
            showMessage(thisObj, "Green Weight Of Vehicle must be of format #########");
            return false;
          } else {
            if (parseInt(data[14]) < 0 || parseInt(data[14]) > 999999999) {
              showMessage(thisObj, "Green Weight Including Vehicle must be of format #########");
              return false;
            }
          }
        }

        // Green Weight Of Peanuts Validation
        if (data[15].trim() != "" && data[15].trim() !== 'undefined') {
          if (isNumeric(data[15].trim())) {
            if (parseInt(data[15]) < 0 || parseInt(data[15]) > 99999) {
              showMessage(thisObj, "Green Weight Of Peanuts must be between 0 and 99,999");
              return false;
            }
          } else {
            showMessage(thisObj, "Green Weight Of Peanuts must be between 0 and 99,999");
            return false;
          }
        }

        // Weigher is Required Validation
        if (data[16].trim() == "" || data[16].trim() == 'undefined') {
          showMessage(thisObj, "Weigher is Required");
          return false;
        }
      }
      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error in ScaleTicketValid Function");
      }
      return false;
    }
  }
  const ontxtCMAVendorBlur = async() => {
    try {
      setLoading(true);
      let value = getValue(thisObj, "txtCMAVendor");
      let tag = getData(thisObj, "txtCMAVendor_tag");

      let change = false //if "value" get changed after bFillCmaRemitList()
      if (value == tag) {
        setLoading(false);
        return;
      }

      let booleanValue = await bFillCmaRemitList()
      if (!booleanValue) {
        //setValue(thisObj, "txtCMAVendor", tag);
        setValue(thisObj, "txtCMAVendor", "");
        change = true;
      }

      let newValue;
      change ? newValue = "" : newValue = value;

      if (newValue == "" || newValue == []) {
        setValue(thisObj, "chkboxDefault", false);
        document.getElementsByClassName('lblCMAParticipant')[0].style.visibility = 'hidden';
        for (let i = 0; i < 20; i++) {
          hide(thisObj, ["chkboxCMA" + (i)]);
          setValue(thisObj, ["chkboxCMA" + (i)], false)
        }
      } else {
        document.getElementsByClassName('lblCMAParticipant')[0].style.visibility = 'visible';
        for (let i = 0; i < 20; i++) {
          let vendorNumber = getValue(thisObj, ["ddVendor" + (i)])
          if (vendorNumber !== undefined && vendorNumber !== "" && vendorNumber[0] !== undefined) {
            show(thisObj, ["chkboxCMA" + (i)]);
            setValue(thisObj, ["chkboxCMA" + (i)], true)
          } else {
            setValue(thisObj, ["chkboxCMA" + (i)], false)
          }

        }
      }

      setLblLoadType1(true);
      EnableDisableControls();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error in txtCMAVendor Change event");
      }
      return false;
    }
  }
  thisObj.ontxtCMAVendorBlur = ontxtCMAVendorBlur;

  async function bFillCmaRemitList() {
    try {
      let valueList = []

      //clearing existing data
      setData(thisObj, "txtCMAVendor_tag", "")  //check if needed or not
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: valueList
          }
        }
      })

      let txtCMAVendorValue = getValue(thisObj, "txtCMAVendor");
      let response = await ContractManagementService.RetrieveVendorByNumber(txtCMAVendorValue);

      if (response !== undefined && response !== null && response.length > 0) {
        setValue(thisObj, "txtCMAVendor", towords(response[0].number))
        setData(thisObj, "txtCMAVendor_tag", response[0].number)
        if (response[0].number == "") {
          return true
        }
      } else {
        return false;
      }

      let response2 = await ContractManagementService.RetieveRemitDetails(response[0].number);

      if (response2 !== undefined && response2 !== null && response2.length > 0) {
        for (var i = 0; i < response2.length; i++) {
          valueList.push({ key: response2[i].remittoid, description: response2[i].remittoid + " - " + towords(response2[i].name + " - " + response2[i].city + " ," + response2[i].state) })
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: valueList
            }
          }
        })

        setValue(thisObj, "ddRemitTo", valueList.at(0).key);

        return true;
      } else {
        return false;
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error in bFillCmaRemitList function");
      }
      return false;
    }
  }

  function towords(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  // HTML Design Code
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FV95*/}

              {/* END_USER_CODE-USER_BEFORE_FV95*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFarmInformation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmInformation*/}

              <GroupBoxWidget
                conf={state.grpbxFarmInformation}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxFarmInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFarmInfo*/}

                <GroupBoxWidget conf={state.grpbxFarmInfo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFarmInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFarmInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFarmInformation}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblSC95Submit}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFarmInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblFarmInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSC95*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSC95}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSC95*/}

                  {/* END_USER_CODE-USER_AFTER_lblSC95*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLoadType2*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLoadType2*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLoadType2}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLoadType2*/}

                  {/* END_USER_CODE-USER_AFTER_lblLoadType2*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFormDirty*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFormDirty*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFormDirty}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFormDirty*/}

                  {/* END_USER_CODE-USER_AFTER_lblFormDirty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCounty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                  {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_lblN*/}

                  {/* END_USER_CODE-USER_BEFORE_lblN*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblN}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblN*/}

                  {/* END_USER_CODE-USER_AFTER_lblN*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFarm}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                  {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCreateFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCreateFarm*/}

                  <ButtonWidget
                    conf={state.btnCreateFarm}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCreateFarm*/}

                  {/* END_USER_CODE-USER_AFTER_btnCreateFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_lblfreefv95*/}

                  {/* END_USER_CODE-USER_BEFORE_lblfreefv95*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblfreefv95}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblfreefv95*/}

                  {/* END_USER_CODE-USER_AFTER_lblfreefv95*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                  {/* START_USER_CODE-USER_BEFORE_btnChange*/}

                  {/* END_USER_CODE-USER_BEFORE_btnChange*/}

                  <ButtonWidget
                    conf={state.btnChange}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnChange*/}

                  {/* END_USER_CODE-USER_AFTER_btnChange*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRevision*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRevision*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRevision}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRevision*/}

                  {/* END_USER_CODE-USER_AFTER_txtRevision*/}
                  {/* START_USER_CODE-USER_BEFORE_lblTicketUploadMod*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTicketUploadMod*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTicketUploadMod}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTicketUploadMod*/}

                  {/* END_USER_CODE-USER_AFTER_lblTicketUploadMod*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspectionDateTime*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspectionDateTime*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspectionDateTime}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspectionDateTime*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspectionDateTime*/}
                  {/* START_USER_CODE-USER_BEFORE_lblDateTimeFormat*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDateTimeFormat*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDateTimeFormat}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDateTimeFormat*/}

                  {/* END_USER_CODE-USER_AFTER_lblDateTimeFormat*/}
                  {/* START_USER_CODE-USER_BEFORE_radioHighMoisture*/}

                  {/* END_USER_CODE-USER_BEFORE_radioHighMoisture*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioHighMoisture}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioHighMoisture*/}

                  {/* END_USER_CODE-USER_AFTER_radioHighMoisture*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFarmInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFarmInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCMAInformation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCMAInformation*/}

                <GroupBoxWidget
                  conf={state.grpbxCMAInformation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblCMAInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCMAInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCMAInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCMAInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblCMAInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCMAVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCMAVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCMAVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCMAVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtCMAVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxDefault*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxDefault*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxDefault}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxDefault*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxDefault*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRemitTo}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCMAInformation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCMAInformation*/}
                {/* START_USER_CODE-USER_BEFORE_ddIrrigatedDryland*/}

                {/* END_USER_CODE-USER_BEFORE_ddIrrigatedDryland*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddIrrigatedDryland}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddIrrigatedDryland*/}

                {/* END_USER_CODE-USER_AFTER_ddIrrigatedDryland*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmInformation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmInformation*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxWeightTickets*/}
              {requireStrip == false ? <></>:
              <img
                src={StripPic}
                  className="stripDesign"
                />}
              {/* END_USER_CODE-USER_BEFORE_grpbxWeightTickets*/}
              <GroupBoxWidget
                conf={state.grpbxWeightVendor}
                screenConf={state}
              >
                <GroupBoxWidget
                  conf={state.grpbxWeightTickets}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtEWeightTkt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEWeightTkt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWeightTkt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEWeightTkt*/}

                  {/* END_USER_CODE-USER_AFTER_txtEWeightTkt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEVehicleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEVehicleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEVehicleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEVehicleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtEVehicleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEWtInclVeh*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEWtInclVeh*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWtInclVeh}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEWtInclVeh*/}

                  {/* END_USER_CODE-USER_AFTER_txtEWtInclVeh*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEWtOfVeh*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEWtOfVeh*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWtOfVeh}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEWtOfVeh*/}

                  {/* END_USER_CODE-USER_AFTER_txtEWtOfVeh*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEWtOfPnut*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEWtOfPnut*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWtOfPnut}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEWtOfPnut*/}

                  {/* END_USER_CODE-USER_AFTER_txtEWtOfPnut*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWeightTickets*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWeightTickets*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWeightTickets}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWeightTickets*/}

                  {/* END_USER_CODE-USER_AFTER_lblWeightTickets*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  {/* START_USER_CODE-USER_BEFORE_gridWeightTickets*/}

                  {/* END_USER_CODE-USER_BEFORE_gridWeightTickets*/}

                  <GridWidget
                    conf={state.gridWeightTickets}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridWeightTickets}
                    onEvent={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridWeightTickets*/}

                  {/* END_USER_CODE-USER_AFTER_gridWeightTickets*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTickets*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTickets*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTickets}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTickets*/}

                  {/* END_USER_CODE-USER_AFTER_txtTickets*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLBs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLBs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLBs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLBs*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLBs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDollar*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDollar*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDollar}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDollar*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDollar*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWeightTickets*/}
                <GroupBoxWidget conf={state.grpbxVendorSplits} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblVendorSplits*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendorSplits*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendorSplits}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendorSplits*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendorSplits*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendor}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemitTo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemitTo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSharePct*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSharePct*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSharePct}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSharePct*/}

                  {/* END_USER_CODE-USER_AFTER_lblSharePct*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCMAParticipant*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCMAParticipant*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCMAParticipant}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCMAParticipant*/}

                  {/* END_USER_CODE-USER_AFTER_lblCMAParticipant*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow0}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor0*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor0}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor0*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor0*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor0*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor0*/}

                    <ButtonWidget
                      conf={state.btnVendor0}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor0")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor0*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor0*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo0*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo0*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct0*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct0*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA0*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA0*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA0}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA0*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA0*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor0*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor0*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor0}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor0*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor0*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor1*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor1}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor1*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor1*/}

                    <ButtonWidget
                      conf={state.btnVendor1}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor1")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor1*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo1*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct1*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct1*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA1*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA1*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA1}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA1*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA1*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor1*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor1}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor1*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor2*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor2}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor2*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor2*/}

                    <ButtonWidget
                      conf={state.btnVendor2}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor2")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor2*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo2*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct2*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct2*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA2*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA2*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA2}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA2*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA2*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor2*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor2}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow3}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor3*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor3}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor3*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor3*/}

                    <ButtonWidget
                      conf={state.btnVendor3}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor3")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor3*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo3*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct3*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct3*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA3*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA3*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA3}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA3*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA3*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor3*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor3}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor3*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow4}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor4*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor4}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor4*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor4*/}

                    <ButtonWidget
                      conf={state.btnVendor4}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor4")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor4*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo4*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct4*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct4*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA4*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA4*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA4}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA4*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA4*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor4*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor4}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor4*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow5}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor5*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor5}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor5*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor5*/}

                    <ButtonWidget
                      conf={state.btnVendor5}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor5")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor5*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo5*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct5*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct5*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA5*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA5*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA5}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA5*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA5*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor5*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor5}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor5*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow6}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor6*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor6}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor6*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor6*/}

                    <ButtonWidget
                      conf={state.btnVendor6}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor6")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor6*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo6*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct6*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct6*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA6*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA6*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA6}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA6*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA6*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor6*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor6}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor6*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow7}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor7*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor7}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor7*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor7*/}

                    <ButtonWidget
                      conf={state.btnVendor7}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor7")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor7*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo7*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct7*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct7*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA7*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA7*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA7}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA7*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA7*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor7*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor7}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor7*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow8}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor8*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor8}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor8*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor8*/}

                    <ButtonWidget
                      conf={state.btnVendor8}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor8")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor8*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo8*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct8*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct8*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA8*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA8*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA8}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA8*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA8*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor8*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor8}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor8*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow9}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor9*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor9}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor9*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor9*/}

                    <ButtonWidget
                      conf={state.btnVendor9}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor9")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor9*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo9*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct9*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct9*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA9*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA9*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA9}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA9*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA9*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor9*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor9}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor9*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow10}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor10*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor10}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor10*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor10*/}

                    <ButtonWidget
                      conf={state.btnVendor10}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor10")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor10*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo10}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo10*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct10*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct10*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA10*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA10*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA10}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA10*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA10*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor10*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor10}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor10*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow11}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor11*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor11}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor11*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor11*/}

                    <ButtonWidget
                      conf={state.btnVendor11}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor11")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor11*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo11}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo11*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct11*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct11*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA11*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA11*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA11}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA11*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA11*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor11*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor11}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor11*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow12}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor12*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor12}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor12*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor12*/}

                    <ButtonWidget
                      conf={state.btnVendor12}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor12")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor12*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo12}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo12*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct12*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct12*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA12*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA12*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA12}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA12*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA12*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor12*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor12}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor12*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow13}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor13*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor13}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor13*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor13*/}

                    <ButtonWidget
                      conf={state.btnVendor13}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor13")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor13*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo13}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo13*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct13*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct13*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA13*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA13*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA13}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA13*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA13*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor13*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor13}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor13*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow14}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor14*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor14}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor14*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor14*/}

                    <ButtonWidget
                      conf={state.btnVendor14}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor14")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor14*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo14}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo14*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct14*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct14*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA14*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA14*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA14}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA14*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA14*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor14*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor14}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor14*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow15}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor15*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor15}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor15*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor15*/}

                    <ButtonWidget
                      conf={state.btnVendor15}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor15")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor15*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo15}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo15*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct15*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct15*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA15*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA15*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA15}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA15*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA15*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor15*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor15}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor15*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow16}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor16*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor16}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor16*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor16*/}

                    <ButtonWidget
                      conf={state.btnVendor16}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor16")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor16*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo16}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo16*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct16*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct16*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA16*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA16*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA16}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA16*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA16*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor16*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor16}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor16*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow17}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor17*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor17}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor17*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor17*/}

                    <ButtonWidget
                      conf={state.btnVendor17}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor17")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor17*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo17}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo17*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct17*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct17*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA17*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA17*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA17}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA17*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA17*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor17*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor17}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor17*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow18}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor18*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor18}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor18*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor18*/}

                    <ButtonWidget
                      conf={state.btnVendor18}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor18")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor18*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo18}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo18*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct18*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct18*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA18*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA18*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA18}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA18*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA18*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor18*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor18}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor18*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow19}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_ddVendor19*/}

                    <AutocompleteWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event => ddVendorBlur(event)}
                      values={values}
                      errors={errors}
                      touched={touched}
                      conf={state.ddVendor19}
                      screenConf={state}
                    />
                    {/* START_USER_CODE-USER_AFTER_ddVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_ddVendor19*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor19*/}

                    <ButtonWidget
                      conf={state.btnVendor19}
                      screenConf={state}
                      onClick={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        btnVendorClick("btnVendor19")
                      }
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor19*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo19}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo19*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct19*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct19*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA19*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA19*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA19}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA19*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA19*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor19*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor19}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor19*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}
                </GroupBoxWidget>
                {/* END_USER_CODE-USER_AFTER_grpbxWeightTickets*/}
              </GroupBoxWidget>

              {/* START_USER_CODE-USER_BEFORE_grpbx1007Control*/}
              <GroupBoxWidget conf={state.grpbx1007Peanut} screenConf={state}>
                {/* END_USER_CODE-USER_BEFORE_grpbx1007Control*/}

                <GroupBoxWidget conf={state.grpbx1007Control} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lbl1007Control*/}

                  {/* END_USER_CODE-USER_BEFORE_lbl1007Control*/}

                  <LabelWidget
                    values={values}
                    conf={state.lbl1007Control}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lbl1007Control*/}

                  {/* END_USER_CODE-USER_AFTER_lbl1007Control*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSettleDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSettleDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSettleDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSettleDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblSettleDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStatus}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                  {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVoidReason*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVoidReason*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVoidReason}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVoidReason*/}

                  {/* END_USER_CODE-USER_AFTER_txtVoidReason*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAdd1007Aplcnt*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAdd1007Aplcnt*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAdd1007Aplcnt}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAdd1007Aplcnt*/}

                  {/* END_USER_CODE-USER_AFTER_lblAdd1007Aplcnt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtName*/}

                  {/* END_USER_CODE-USER_AFTER_txtName*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx1007Control*/}

                {/* END_USER_CODE-USER_AFTER_grpbx1007Control*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                <GroupBoxWidget conf={state.grpbxPeanutInfo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblPeanutInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPeanutInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPeanutInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPeanutInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblPeanutInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeedGen*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeedGen*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeedGen}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeedGen*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeedGen*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOrganic*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblOrganic}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_lblOrganic*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxOrganic*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxOrganic}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxOrganic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEdibleOil}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                  {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSeedMeetingSpec*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSeedMeetingSpec*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeedMeetingSpec}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSeedMeetingSpec*/}

                  {/* END_USER_CODE-USER_AFTER_txtSeedMeetingSpec*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxVicam*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxVicam*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxVicam}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxVicam*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxVicam*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReading*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReading*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReading}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReading*/}

                  {/* END_USER_CODE-USER_AFTER_txtReading*/}
                  {/* START_USER_CODE-USER_BEFORE_lblUnloadedInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUnloadedInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUnloadedInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUnloadedInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblUnloadedInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_ddWhouseBin*/}

                  {/* END_USER_CODE-USER_BEFORE_ddWhouseBin*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddWhouseBin}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddWhouseBin*/}

                  {/* END_USER_CODE-USER_AFTER_ddWhouseBin*/}
                  {/* START_USER_CODE-USER_BEFORE_lblDelPtObligation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDelPtObligation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDelPtObligation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDelPtObligation*/}

                  {/* END_USER_CODE-USER_AFTER_lblDelPtObligation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPt*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPt*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPt}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPt*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPt*/}
                  {/* START_USER_CODE-USER_BEFORE_ddObligationPt*/}

                  {/* END_USER_CODE-USER_BEFORE_ddObligationPt*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddObligationPt}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddObligationPt*/}

                  {/* END_USER_CODE-USER_AFTER_ddObligationPt*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}
              </GroupBoxWidget>

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}


              {/* START_USER_CODE-USER_AFTER_grpbxVendorSplits*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorSplits*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}
              {/* END_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}
              {requireStrip == false ? <></>:
              <img
                src={StripPic}
                  className="stripDesign"
                />}
              <GroupBoxWidget
                conf={state.grpbxInspectorRemarks}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

                <LabelWidget
                  values={values}
                  conf={state.lblInspectorRemarks}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblInspectorRemarks*/}

                {/* END_USER_CODE-USER_AFTER_lblInspectorRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarInspectorRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}

              {/* END_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxGradeCalculation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxGradeCalculation*/}

              <GroupBoxWidget
                conf={state.grpbxGradeCalculation}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblGradeCalculation*/}

                {/* END_USER_CODE-USER_BEFORE_lblGradeCalculation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGradeCalculation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGradeCalculation*/}

                {/* END_USER_CODE-USER_AFTER_lblGradeCalculation*/}
                {/* START_USER_CODE-USER_BEFORE_txtFMSampleWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtFMSampleWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFMSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFMSampleWt*/}

                {/* END_USER_CODE-USER_AFTER_txtFMSampleWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtProbingPattern*/}

                {/* END_USER_CODE-USER_BEFORE_txtProbingPattern*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtProbingPattern}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtProbingPattern*/}

                {/* END_USER_CODE-USER_AFTER_txtProbingPattern*/}
                {/* START_USER_CODE-USER_BEFORE_txtCleanSampleWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtCleanSampleWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCleanSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCleanSampleWt*/}

                {/* END_USER_CODE-USER_AFTER_txtCleanSampleWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtValenciaCleanSampleWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtValenciaCleanSampleWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValenciaCleanSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValenciaCleanSampleWt*/}

                {/* END_USER_CODE-USER_AFTER_txtValenciaCleanSampleWt*/}
                {/* START_USER_CODE-USER_BEFORE_lblCornFound*/}

                {/* END_USER_CODE-USER_BEFORE_lblCornFound*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCornFound}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCornFound*/}

                {/* END_USER_CODE-USER_AFTER_lblCornFound*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxCornFound*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxCornFound*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCornFound}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxCornFound*/}

                {/* END_USER_CODE-USER_AFTER_chkboxCornFound*/}
                {/* START_USER_CODE-USER_BEFORE_lblGrams1*/}

                {/* END_USER_CODE-USER_BEFORE_lblGrams1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGrams1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGrams1*/}

                {/* END_USER_CODE-USER_AFTER_lblGrams1*/}
                {/* START_USER_CODE-USER_BEFORE_lblPct1*/}

                {/* END_USER_CODE-USER_BEFORE_lblPct1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPct1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPct1*/}

                {/* END_USER_CODE-USER_AFTER_lblPct1*/}
                {/* START_USER_CODE-USER_BEFORE_lblGrams2*/}

                {/* END_USER_CODE-USER_BEFORE_lblGrams2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGrams2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGrams2*/}

                {/* END_USER_CODE-USER_AFTER_lblGrams2*/}
                {/* START_USER_CODE-USER_BEFORE_lblPct2*/}

                {/* END_USER_CODE-USER_BEFORE_lblPct2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPct2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPct2*/}

                {/* END_USER_CODE-USER_AFTER_lblPct2*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMKRSGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMKRSGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRSGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMKRSGr*/}

                {/* END_USER_CODE-USER_AFTER_txtSMKRSGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMKRSPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMKRSPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRSPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMKRSPct*/}

                {/* END_USER_CODE-USER_AFTER_txtSMKRSPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreezeDamageGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreezeDamageGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreezeDamageGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreezeDamageGr*/}

                {/* END_USER_CODE-USER_AFTER_txtFreezeDamageGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreezeDamagePct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}

                {/* END_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK1Gr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK1Gr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK1Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK1Gr*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK1Gr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK1Pct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK1Pct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK1Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK1Pct*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK1Pct*/}
                {/* START_USER_CODE-USER_BEFORE_txtConcealedRMDGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtConcealedRMDGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConcealedRMDGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtConcealedRMDGr*/}

                {/* END_USER_CODE-USER_AFTER_txtConcealedRMDGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConcealedRMDPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}

                {/* END_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK2Gr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK2Gr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK2Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK2Gr*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK2Gr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK2Pct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK2Pct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK2Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK2Pct*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK2Pct*/}
                {/* START_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrkOrBrkShellsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCrkOrBrkShellsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtCrkOrBrkShellsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrkOrBrkShellsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCrkOrBrkShellsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtCrkOrBrkShellsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK3Gr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK3Gr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK3Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK3Gr*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK3Gr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK3Pct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK3Pct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK3Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK3Pct*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK3Pct*/}
                {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShellsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShellsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscoloredShellsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiscoloredShellsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtDiscoloredShellsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscoloredShellsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK4Gr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK4Gr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK4Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK4Gr*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK4Gr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK4Pct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK4Pct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK4Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK4Pct*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK4Pct*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullBrightness}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullBrightness*/}

                {/* END_USER_CODE-USER_AFTER_txtHullBrightness*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullBrightnessPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}

                {/* END_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtSoundSplitsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSoundSplitsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSoundSplitsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSoundSplitsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtSoundSplitsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSoundSplitsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtJumboGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtJumboGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtJumboGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtJumboGr*/}

                {/* END_USER_CODE-USER_AFTER_txtJumboGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtJumboPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtJumboPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtJumboPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtJumboPct*/}

                {/* END_USER_CODE-USER_AFTER_txtJumboPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalSMK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalSMK*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalSMK*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKGr*/}

                {/* END_USER_CODE-USER_AFTER_txtELKGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKPct*/}

                {/* END_USER_CODE-USER_AFTER_txtELKPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherKernelsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherKernelsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherKernelsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherKernelsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherKernelsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherKernelsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherKernelsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherKernelsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherKernelsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherKernelsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtForeignMaterialGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtForeignMaterialGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterialGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtForeignMaterialGr*/}

                {/* END_USER_CODE-USER_AFTER_txtForeignMaterialGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterialPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}

                {/* END_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageSplits}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamageSplits*/}

                {/* END_USER_CODE-USER_AFTER_txtDamageSplits*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherForeignMaterials*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherForeignMaterials*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherForeignMaterials}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherForeignMaterials*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherForeignMaterials*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalDamageGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalDamageGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDamageGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalDamageGr*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalDamageGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalDamagePct*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalDamagePct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDamagePct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalDamagePct*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalDamagePct*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKGr*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKPct*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelsREL*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelsREL*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelsREL}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelsREL*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelsREL*/}
                {/* START_USER_CODE-USER_BEFORE_txtMoisture*/}

                {/* END_USER_CODE-USER_BEFORE_txtMoisture*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMoisture}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMoisture*/}

                {/* END_USER_CODE-USER_AFTER_txtMoisture*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelsRPS*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelsRPS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelsRPS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelsRPS*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelsRPS*/}
                {/* START_USER_CODE-USER_BEFORE_txtMeterReading*/}

                {/* END_USER_CODE-USER_BEFORE_txtMeterReading*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMeterReading}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMeterReading*/}

                {/* END_USER_CODE-USER_AFTER_txtMeterReading*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalKrnls*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalKrnls*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKrnls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalKrnls*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalKrnls*/}
                {/* START_USER_CODE-USER_BEFORE_txtBluePanWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtBluePanWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBluePanWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBluePanWt*/}

                {/* END_USER_CODE-USER_AFTER_txtBluePanWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtHullsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtHullsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtFancyGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtFancyGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFancyGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFancyGr*/}

                {/* END_USER_CODE-USER_AFTER_txtFancyGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtFancyPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtFancyPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFancyPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFancyPct*/}

                {/* END_USER_CODE-USER_AFTER_txtFancyPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalKernelsHulls*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalKernelsHulls*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKernelsHulls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalKernelsHulls*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalKernelsHulls*/}
                {/* START_USER_CODE-USER_BEFORE_lblFlavusFound*/}

                {/* END_USER_CODE-USER_BEFORE_lblFlavusFound*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFlavusFound}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFlavusFound*/}

                {/* END_USER_CODE-USER_AFTER_lblFlavusFound*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxFlavusFound*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxFlavusFound*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFlavusFound}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxFlavusFound*/}

                {/* END_USER_CODE-USER_AFTER_chkboxFlavusFound*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamagedKRS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_txtELKDamage*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKDamage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKDamage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKDamage*/}

                {/* END_USER_CODE-USER_AFTER_txtELKDamage*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVirginiaGrading*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVirginiaGrading*/}

                <GroupBoxWidget
                  conf={state.grpbxVirginiaGrading}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblVirginiaGrading*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVirginiaGrading*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVirginiaGrading}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVirginiaGrading*/}

                  {/* END_USER_CODE-USER_AFTER_lblVirginiaGrading*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSampleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSampleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSampleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSampleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtSampleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGrams*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGrams*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGrams}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGrams*/}

                  {/* END_USER_CODE-USER_AFTER_txtGrams*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPercent*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPercent*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPercent}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPercent*/}

                  {/* END_USER_CODE-USER_AFTER_txtPercent*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVirginiaGrading*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVirginiaGrading*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxGradeCalculation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxGradeCalculation*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxHMC*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxHMC*/}

              <GroupBoxWidget conf={state.grpbxHMC} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnConvertHMC*/}

                {/* END_USER_CODE-USER_BEFORE_btnConvertHMC*/}

                <ButtonWidget
                  conf={state.btnConvertHMC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnConvertHMC*/}

                {/* END_USER_CODE-USER_AFTER_btnConvertHMC*/}
                {/* START_USER_CODE-USER_BEFORE_lblHMCConvertedGrades*/}

                {/* END_USER_CODE-USER_BEFORE_lblHMCConvertedGrades*/}

                <LabelWidget
                  values={values}
                  conf={state.lblHMCConvertedGrades}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblHMCConvertedGrades*/}

                {/* END_USER_CODE-USER_AFTER_lblHMCConvertedGrades*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtFM*/}

                {/* END_USER_CODE-USER_BEFORE_txtFM*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFM*/}

                {/* END_USER_CODE-USER_AFTER_txtFM*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSK*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSK*/}

                {/* END_USER_CODE-USER_AFTER_txtLSK*/}
                {/* START_USER_CODE-USER_BEFORE_txtMST*/}

                {/* END_USER_CODE-USER_BEFORE_txtMST*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMST}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMST*/}

                {/* END_USER_CODE-USER_AFTER_txtMST*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK*/}
                {/* START_USER_CODE-USER_BEFORE_txtSS*/}

                {/* END_USER_CODE-USER_BEFORE_txtSS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSS*/}

                {/* END_USER_CODE-USER_AFTER_txtSS*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMKRS*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMKRS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMKRS*/}

                {/* END_USER_CODE-USER_AFTER_txtSMKRS*/}
                {/* START_USER_CODE-USER_BEFORE_txtOK*/}

                {/* END_USER_CODE-USER_BEFORE_txtOK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOK*/}

                {/* END_USER_CODE-USER_AFTER_txtOK*/}
                {/* START_USER_CODE-USER_BEFORE_txtFRZ*/}

                {/* END_USER_CODE-USER_BEFORE_txtFRZ*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFRZ}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFRZ*/}

                {/* END_USER_CODE-USER_AFTER_txtFRZ*/}
                {/* START_USER_CODE-USER_BEFORE_txtCRMD*/}

                {/* END_USER_CODE-USER_BEFORE_txtCRMD*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCRMD}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCRMD*/}

                {/* END_USER_CODE-USER_AFTER_txtCRMD*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKernels}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalKernels*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalKernels*/}
                {/* START_USER_CODE-USER_BEFORE_txtDAM*/}

                {/* END_USER_CODE-USER_BEFORE_txtDAM*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDAM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDAM*/}

                {/* END_USER_CODE-USER_AFTER_txtDAM*/}
                {/* START_USER_CODE-USER_BEFORE_txtHULLS*/}

                {/* END_USER_CODE-USER_BEFORE_txtHULLS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHULLS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHULLS*/}

                {/* END_USER_CODE-USER_AFTER_txtHULLS*/}
                {/* START_USER_CODE-USER_BEFORE_txtELK*/}

                {/* END_USER_CODE-USER_BEFORE_txtELK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELK*/}

                {/* END_USER_CODE-USER_AFTER_txtELK*/}
                {/* START_USER_CODE-USER_BEFORE_txtFANCY*/}

                {/* END_USER_CODE-USER_BEFORE_txtFANCY*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFANCY}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFANCY*/}

                {/* END_USER_CODE-USER_AFTER_txtFANCY*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotKrnlsHulls*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotKrnlsHulls*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotKrnlsHulls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotKrnlsHulls*/}

                {/* END_USER_CODE-USER_AFTER_txtTotKrnlsHulls*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxHMC*/}

              {/* END_USER_CODE-USER_AFTER_grpbxHMC*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

              <GroupBoxWidget
                conf={state.grpbxValueCalculation}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                {/* END_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblValueCalculation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblValueCalculation*/}

                {/* END_USER_CODE-USER_AFTER_lblValueCalculation*/}
                {/* START_USER_CODE-USER_BEFORE_btnCalculate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCalculate*/}

                <ButtonWidget
                  conf={state.btnCalculate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCalculate*/}

                {/* END_USER_CODE-USER_AFTER_btnCalculate*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtIncludingVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicleVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                {/* END_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrossWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGrossWeight*/}

                {/* END_USER_CODE-USER_AFTER_txtGrossWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                {/* END_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterial}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtForeignMaterial*/}

                {/* END_USER_CODE-USER_AFTER_txtForeignMaterial*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtlessFM*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtlessFM*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtlessFM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtlessFM*/}

                {/* END_USER_CODE-USER_AFTER_txtWtlessFM*/}
                {/* START_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                {/* END_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessMoisture}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtExcessMoisture*/}

                {/* END_USER_CODE-USER_AFTER_txtExcessMoisture*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWeight*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWeight*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWeight*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKvc*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKvc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKvc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKvc*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKvc*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWtExclLSK*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWtExclLSK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWtExclLSK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWtExclLSK*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWtExclLSK*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerTon}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerLb*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerLb*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerLb}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerLb*/}

                {/* END_USER_CODE-USER_AFTER_lblPerLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelValueTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelValueTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelValueTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelValueTon*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelValueTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelValueLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelValueLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelValueLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelValueLb*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelValueLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKPremiumTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKPremiumTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPremiumTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKPremiumTon*/}

                {/* END_USER_CODE-USER_AFTER_txtELKPremiumTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKPremiumLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKPremiumLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPremiumLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKPremiumLb*/}

                {/* END_USER_CODE-USER_AFTER_txtELKPremiumLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalTon*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalLb*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamageTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamageTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamageTon*/}

                {/* END_USER_CODE-USER_AFTER_txtDamageTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamageLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamageLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamageLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtDamageLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccessFMTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccessFMTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccessFMTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccessFMTon*/}

                {/* END_USER_CODE-USER_AFTER_txtAccessFMTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccessFMLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccessFMLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccessFMLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccessFMLb*/}

                {/* END_USER_CODE-USER_AFTER_txtAccessFMLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessSplitsTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtExcessSplitsTon*/}

                {/* END_USER_CODE-USER_AFTER_txtExcessSplitsTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessSplitsLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtExcessSplitsLb*/}

                {/* END_USER_CODE-USER_AFTER_txtExcessSplitsLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtAFlavusTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtAFlavusTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAFlavusTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAFlavusTon*/}

                {/* END_USER_CODE-USER_AFTER_txtAFlavusTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtAFlavusLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtAFlavusLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAFlavusLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAFlavusLb*/}

                {/* END_USER_CODE-USER_AFTER_txtAFlavusLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalDiscountsTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalDiscountsTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDiscountsTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalDiscountsTon*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalDiscountsTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtlblTotalDiscountsLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtlblTotalDiscountsLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtlblTotalDiscountsLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtlblTotalDiscountsLb*/}

                {/* END_USER_CODE-USER_AFTER_txtlblTotalDiscountsLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetValPerTonExclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}

                {/* END_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}
                {/* START_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                {/* END_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValPerPoundExclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}

                {/* END_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerLb2*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerLb2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerLb2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerLb2*/}

                {/* END_USER_CODE-USER_AFTER_lblPerLb2*/}
                {/* START_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                {/* END_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValPerPoundInclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}

                {/* END_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}
                {/* START_USER_CODE-USER_BEFORE_txtOI*/}

                {/* END_USER_CODE-USER_BEFORE_txtOI*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOI}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOI*/}

                {/* END_USER_CODE-USER_AFTER_txtOI*/}
                {/* START_USER_CODE-USER_BEFORE_txtH*/}

                {/* END_USER_CODE-USER_BEFORE_txtH*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtH}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtH*/}

                {/* END_USER_CODE-USER_AFTER_txtH*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalLoadVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalLoadVal*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalLoadVal*/}
                {/* START_USER_CODE-USER_BEFORE_txtG*/}

                {/* END_USER_CODE-USER_BEFORE_txtG*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtG}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtG*/}

                {/* END_USER_CODE-USER_AFTER_txtG*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerLb3*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerLb3*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerLb3}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerLb3*/}

                {/* END_USER_CODE-USER_AFTER_lblPerLb3*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxValueCalculation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxValueCalculation*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              {requireStrip == false ? <></>:
              <img
                src={StripPic}
                  className="stripDesign"
                />}
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAuditCount*/}

                {/* END_USER_CODE-USER_BEFORE_lblAuditCount*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAuditCount}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAuditCount*/}

                {/* END_USER_CODE-USER_AFTER_lblAuditCount*/}
                {/* START_USER_CODE-USER_BEFORE_lblAuditCountValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAuditCountValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAuditCountValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAuditCountValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAuditCountValue*/}

                <ButtonWidget
                  conf={state.btnReprint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_BEFORE_btnCreate1007*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreate1007*/}

                <ButtonWidget
                  conf={state.btnCreate1007}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreate1007*/}

                {/* END_USER_CODE-USER_AFTER_btnCreate1007*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreate*/}

                <ButtonWidget
                  conf={state.btnCreate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreate*/}

                {/* END_USER_CODE-USER_AFTER_btnCreate*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblCorpYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FV95*/}

              {/* END_USER_CODE-USER_AFTER_FV95*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_FV95);
