/* eslint-disable*/
import React from "react";
import RichTextEditor from "./RichTextEditor";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("RichTextEditor Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let RichTextEditor1 = {
        name: "RichTextEditor1",
        type: "RichTextEditor",
        parent: "groupboxwidget0",
        Label: "RichTextEditor1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();

      renderTestScreen(
        <RichTextEditor
          values={values}
          conf={RichTextEditor1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("RichTextEditor Basic Test Cases", async () => {
    const RichTextEditor1 = screen.getByTestId("RichTextEditor1");

    expect(RichTextEditor1.tagName).toBe("DIV");
  });
});
describe("RichTextEditor Test Cases", () => {
  beforeEach(async () => {
    document.createRange = () => ({
      setStart: () => {},
      setEnd: () => {},
      commonAncestorContainer: {
        nodeName: "BODY",
        ownerDocument: document,
      },
    });
    act(() => {
      let RichTextEditor1 = {
        name: "RichTextEditor1",
        type: "RichTextEditor",
        parent: "groupboxwidget0",
        Label: "RichTextEditor1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        horizontalForm: true,
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      renderTestScreen(
        <RichTextEditor
          values={values}
          conf={RichTextEditor1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("RichTextEditor Basic Test Cases for horizontal form", async () => {
    const RichTextEditor1 = screen.getByTestId("RichTextEditor1");

    expect(RichTextEditor1.tagName).toBe("DIV");
  });
});
