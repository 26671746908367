/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  setValue,
  getValue,
  setData,
  getData, getLabel, enable, disable, hide, show, isEnabled
} from "../../shared/WindowImports";

import "./FreightLocToLocProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { getByLabelText } from "@testing-library/react";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FreightLocToLocProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const functionId = 'PN1080';
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FreightLocToLocProfile",
    windowName: "FreightLocToLocProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FreightLocToLocProfile",
    // START_USER_CODE-USER_FreightLocToLocProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Freight Location To Location Profile",
      scrCode: "PN0250B",
    },
    // END_USER_CODE-USER_FreightLocToLocProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "County:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddDestBuyPt: {
      name: "ddDestBuyPt",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Dest. Buy Point",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestBuyPt_PROPERTIES

      // END_USER_CODE-USER_ddDestBuyPt_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Farm:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES

      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddNoLoadRateBasis: {
      name: "ddNoLoadRateBasis",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "No Load Rate Basis:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddNoLoadRateBasis_PROPERTIES

      // END_USER_CODE-USER_ddNoLoadRateBasis_PROPERTIES
    },
    ddOrigBuyPt: {
      name: "ddOrigBuyPt",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Orig Buy Pt:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrigBuyPt_PROPERTIES

      // END_USER_CODE-USER_ddOrigBuyPt_PROPERTIES
    },
    ddRateBasis: {
      name: "ddRateBasis",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Rate Basis:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateBasis_PROPERTIES

      // END_USER_CODE-USER_ddRateBasis_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    dtEffDate: {
      name: "dtEffDate",
      type: "DateWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtEffDate_PROPERTIES

      // END_USER_CODE-USER_dtEffDate_PROPERTIES
    },
    lblEntrInfo: {
      name: "lblEntrInfo",
      type: "LabelWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Enter information below if you are not using mileage scale rate.",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEntrInfo_PROPERTIES

      // END_USER_CODE-USER_lblEntrInfo_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Note: Farm is based on State,County, Orig and Buy pt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote_PROPERTIES

      // END_USER_CODE-USER_lblNote_PROPERTIES
    },
    lblPounds: {
      name: "lblPounds",
      type: "LabelWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Pounds",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPounds_PROPERTIES

      // END_USER_CODE-USER_lblPounds_PROPERTIES
    },
    lblPounds2: {
      name: "lblPounds2",
      type: "LabelWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Pounds",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPounds2_PROPERTIES

      // END_USER_CODE-USER_lblPounds2_PROPERTIES
    },
    radioType: {
      name: "radioType",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFreightLocToLoc",
      Options: "Loc. to Loc.:1,Farm to Loc.:2",
      Label: "Type:",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioType_PROPERTIES

      // END_USER_CODE-USER_radioType_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtCarrAbbr: {
      name: "txtCarrAbbr",
      type: "TextBoxWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Carrier Abbreviation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrAbbr_PROPERTIES

      // END_USER_CODE-USER_txtCarrAbbr_PROPERTIES
    },
    txtChngdBy: {
      name: "txtChngdBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChngdBy_PROPERTIES

      // END_USER_CODE-USER_txtChngdBy_PROPERTIES
    },
    txtMiles: {
      name: "txtMiles",
      type: "TextBoxWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Miles:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMiles_PROPERTIES

      // END_USER_CODE-USER_txtMiles_PROPERTIES
    },
    txtMinWt: {
      name: "txtMinWt",
      type: "TextBoxWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Minimum Weight:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMinWt_PROPERTIES

      // END_USER_CODE-USER_txtMinWt_PROPERTIES
    },
    txtNoLoadRate: {
      name: "txtNoLoadRate",
      type: "TextBoxWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "No Load Rate:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNoLoadRate_PROPERTIES

      // END_USER_CODE-USER_txtNoLoadRate_PROPERTIES
    },
    txtNoLoadWt: {
      name: "txtNoLoadWt",
      type: "TextBoxWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "No Load Weight:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNoLoadWt_PROPERTIES

      // END_USER_CODE-USER_txtNoLoadWt_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      parent: "grpbxFreightLocToLoc",
      Label: "Rate:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRate_PROPERTIES

      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    grpbxFreightLocToLoc: {
      name: "grpbxFreightLocToLoc",
      type: "GroupBoxWidget",
      parent: "FreightLocToLocProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFreightLocToLoc_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightLocToLoc_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FreightLocToLocProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setEventHandlers();

    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const dataFromParent = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen');
    if (dataFromParent?.length) {
      const parentData = dataFromParent[0];
      if (parentData?.cmdOkCaption) {
        setLabelText('btnOk', parentData.cmdOkCaption);
      }
      const inEditMode = parentData?.cmdOkCaption === 'Update';
      setValue(thisObj, 'txtMiles', parentData?.txtMiles || '');
      setValue(thisObj, 'txtAddedBy', parentData?.lblAddedBy || '');
      setValue(thisObj, 'txtChngdBy', parentData?.lblChangedBy || '');
      if (parentData?.txtEffectiveDate) {
        setValue(thisObj, 'dtEffDate', moment(parentData?.txtEffectiveDate));
      }
      setValue(thisObj, 'txtMinWt', parentData?.txtDeadFreight || '');
      setValue(thisObj, 'txtCarrAbbr', parentData?.txtScac_id || '');
      if (inEditMode) {
        setValue(thisObj, 'txtRate', parentData?.txtRate
          ? Number(parentData.txtRate).toFixed(2) : '0.00');
        setValue(thisObj, 'txtNoLoadRate', parentData?.txtNL_rate
          ? Number(parentData.txtNL_rate).toFixed(2) : '0.00');
      } else {
        setValue(thisObj, 'txtRate', '');
        setValue(thisObj, 'txtNoLoadRate', '');
      }
      setValue(thisObj, 'txtNoLoadWt', parentData?.txtNL_weight || '');
      setValue(thisObj, 'radioType', parentData?.option1 ? '2' : '1');
      onradioTypeChange(null, parentData?.option1 ? '2' : '1');
      if (inEditMode
        && parentData?.lstStateID
        && parentData?.lblOrgBPTCaption) {
        const buyingPtId = parentData.lblOrgBPTCaption.split('-')[0]?.trim();
        fillStateList(true, buyingPtId, true);
      } else {
        fillStateList();
        fillCountyList();
        fillFarmList();
      }
    }
    formActivate();
  }, [getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')])

  const setEventHandlers = () => {
    document.getElementById('txtMiles').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtMinWt').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtNoLoadWt').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtRate').addEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtNoLoadRate').addEventListener('keypress', allowNumbersAndDecimal);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtMiles').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtMinWt').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtNoLoadWt').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtRate').removeEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtNoLoadRate').removeEventListener('keypress', allowNumbersAndDecimal);
  };

  // START_USER_CODE-USER_METHODS
  const setLabelText = (widgetId, value) => {
    const elementMainParent = document.getElementsByClassName(widgetId);
    elementMainParent[elementMainParent.length - 1].firstChild.firstChild.innerHTML = value;
  };

  const getLabelText = (widgetId) => {
    const elementMainParent = document.getElementsByClassName(widgetId);
    return elementMainParent[elementMainParent.length - 1]
      .firstChild.firstChild.innerHTML;
  };

  const fillStateList = async (filter = false, buyPtId = null, setDataFromParent = false) => {
    try {
      const stateList = [];
      stateList.push({ key: '', description: '' });
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state['ddCounty'],
            valueList: [],
            Visible: getValue(thisObj, 'radioType') === '2'
          },
          ddFarm: {
            ...state['ddFarm'],
            valueList: [],
            Visible: getValue(thisObj, 'radioType') === '2'
          }
        }
      });

      const list = await ContractManagementService.RetrievePeanutStateControlDetails(buyPtId, '', filter);

      if (list?.length) {
        list.forEach(stateItem => {
          stateList.push({
            key: stateItem?.stateAbbr?.toString(),
            description: stateItem?.stateName?.toString()
          })
        });
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state['ddState'],
            valueList: stateList,
            Visible: setDataFromParent || getValue(thisObj, 'radioType') === '2'
          }
        }
      });
      if (stateList.length) {
        setValue(_kaledo.thisObj, 'ddState', stateList[0]?.key);
        if (setDataFromParent) {
          const parentData = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
          const buyingPtId = parentData?.lblOrgBPTCaption?.split('-')?.[0]?.trim();
          const stateIndex = stateList.findIndex(item => item?.key === parentData?.lstStateID);
          if (stateIndex !== -1) {
            setValue(_kaledo.thisObj, 'ddState', parentData?.lstStateID);
          }
          fillCountyList('YES', buyingPtId, true, parentData?.lstStateID);
        }
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillStateList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const fillCountyList = async (filter = '', buyPtId = null,
    setDataFromParent = false, stateIdParent = '') => {
    try {
      const countyList = [];
      countyList.push({ key: '', description: '' });
      const selectedState = stateIdParent || getValue(_kaledo.thisObj, 'ddState');
      if (selectedState) {
        const list = await ContractManagementService.RetrieveCountyControlDetails(buyPtId, filter, selectedState);

        if (list?.length) {
          list.forEach(listItem => {
            countyList.push({
              key: listItem?.countyId?.toString(),
              description: listItem?.countyName?.toString()
            })
          });
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state['ddCounty'],
            valueList: countyList,
            Visible: setDataFromParent || getValue(thisObj, 'radioType') === '2'
          }
        }
      });
      if (countyList.length) {
        setValue(_kaledo.thisObj, 'ddCounty', countyList[0]?.key);
        if (setDataFromParent) {
          const parentData = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
          const countyIndex = countyList.findIndex(item => item?.key === parentData?.lstCountyID);
          if (parentData?.lstCountyID && countyIndex) {
            setValue(_kaledo.thisObj, 'ddCounty', parentData?.lstCountyID);
          }
          const buyingPtId = parentData.lblOrgBPTCaption.split('-')[0]?.trim();
          fillFarmList(buyingPtId,
            parentData?.lstStateID,
            parentData?.lstCountyID,
            true);
        }
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillCountyList: ${error?.toString()}`
        );
      }
      return false;
    }
  };
//PRB0054819_INC6192821_Farm to Location Miles Unable to Add

  const fillFarmList = async (buyPtIdParent = '', stateIdParent = '',
    countyIdParent = '', setDataFromParent = false) => {
    try {
      const farmList = [];
      farmList.push({ key: '', description: '' });  
      const buyPtId = buyPtIdParent || getValue(thisObj, 'ddOrigBuyPt');
      const selectedState = stateIdParent || getValue(_kaledo.thisObj, 'ddState');
      const selectedCounty = countyIdParent || getValue(_kaledo.thisObj, 'ddCounty');
      if (buyPtId && selectedState && selectedCounty) {
        const list = await SystemMaintenanceMasterManagementService
          .RetrieveFarmControlDetails(buyPtId, selectedState, selectedCounty);
  
        if (list?.length) {
          list.forEach(listItem => {
            const key = `${listItem?.farmId?.toString()}-${listItem?.farmSuffix?.toString()}`;
            const description = `${listItem?.farmId?.toString()} - ${listItem?.farmSuffix?.toString()} - ${listItem?.farmName?.toString()}`;
            farmList.push({ key, description });
          });
        }
      }
      setData(thisObj, 'farmList', farmList);
      thisObj.setState(current => ({
        ...current,
        ddFarm: {
          ...state['ddFarm'],
          valueList: farmList,
          Visible: setDataFromParent || getValue(thisObj, 'radioType') === '2'
        }
      }));
      if (farmList.length) {
        setValue(thisObj, 'ddFarm', farmList[0]?.key);
        if (setDataFromParent) {
          const parentData = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
          const farmIdFromParent = parentData?.lstFarmID.split('-')?.[0]?.trim();
          const farmSuffixFromParent = parentData?.lstFarmID.split('-')?.[1]?.trim();
          const farmKeyFromParent = `${farmIdFromParent}-${farmSuffixFromParent}`;  
          const farmIndex = farmList.findIndex(item => item?.key === farmKeyFromParent);
          if (farmKeyFromParent && farmIndex !== -1) {
            setValue(thisObj, 'ddFarm', farmKeyFromParent);
          }
        }
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillFarmList: ${error?.toString()}`
        );
      }
      return false;
    }
  };  

  const fillShippingLocationList = async () => {
    try {
      const originBuyingPointList = [];
      originBuyingPointList.push({ key: '', description: '>>> All Locations <<<' });
      const locationListResponse = await ContractManagementService.RetrieveBuyingPointControlDetails(functionId, null);
      if (locationListResponse?.length) {
        locationListResponse.forEach(location => {
          originBuyingPointList.push({
            key: location.buy_pt_id.toString(),
            description: location.buy_pt_id.toString() + ' - ' + location.buy_pt_name.toString()
          });
        });
        if (originBuyingPointList.length === 2) {
          originBuyingPointList.shift();
        }

        setData(thisObj, 'origBuyPtList', originBuyingPointList);
        const parentData = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
        thisObj.setState(current => {
          return {
            ...current,
            ddOrigBuyPt: {
              ...state['ddOrigBuyPt'],
              valueList: originBuyingPointList,
              Enabled: parentData?.cmdOkCaption === 'Add'
            }
          }
        });
        if (originBuyingPointList.length) {
          setValue(thisObj, 'ddOrigBuyPt', originBuyingPointList[0].key);
          if (parentData?.lblOrgBPTCaption) {
            const origBuyPtIndex = originBuyingPointList
              .findIndex(item => item?.description?.trim() === parentData?.lblOrgBPTCaption?.trim());
            if (origBuyPtIndex !== -1) {
              // lblOrgBPTCaption found in originBuyingPointList
              setValue(thisObj, 'ddOrigBuyPt', originBuyingPointList[origBuyPtIndex].key);
            } else {
              // lblOrgBPTCaption does not found in originBuyingPointList
              const newOrigKey = parentData.lblOrgBPTCaption.trim()?.split(' - ')?.[0];
              originBuyingPointList.push({
                key: newOrigKey?.toString(),
                description: parentData.lblOrgBPTCaption
              });
              const lastIndex = originBuyingPointList.length - 1;
              setValue(thisObj, 'ddOrigBuyPt', originBuyingPointList[lastIndex].key);
            }
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillShippingLocationList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const fillReceivingLocationList = async () => {
    try {
      const destinationBuyingPointList = [];
      destinationBuyingPointList.push({ key: '', description: '>>> All Locations <<<' });
      const locationListResponse = await SettlementService.RetrieveTransferBuyingPointDetails();
      if (locationListResponse?.length) {
        locationListResponse.forEach(location => {
          destinationBuyingPointList.push({
            key: location.xfer_buy_pt_id.toString(),
            description: location.xfer_buy_pt_id.toString() + ' - ' + location.xfer_buy_pt_name.toString()
          });
        });
        if (destinationBuyingPointList.length === 2) {
          destinationBuyingPointList.shift();
        }

        setData(thisObj, 'destBuyPtList', destinationBuyingPointList);
        const parentData = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
        thisObj.setState(current => {
          return {
            ...current,
            ddDestBuyPt: {
              ...state['ddDestBuyPt'],
              valueList: destinationBuyingPointList,
              Enabled: parentData?.cmdOkCaption === 'Add'
            }
          }
        });
        if (destinationBuyingPointList.length) {
          setValue(thisObj, 'ddDestBuyPt', destinationBuyingPointList[0].key);
          if (parentData?.lblDestBPTCaption) {
            const destBuyPtIndex = destinationBuyingPointList
              .findIndex(item => item?.description?.trim() === parentData?.lblDestBPTCaption?.trim());
            if (destBuyPtIndex !== -1) {
              // lblDestBPTCaption found in destinationBuyingPointList
              setValue(thisObj, 'ddDestBuyPt', destinationBuyingPointList[destBuyPtIndex].key);
            } else {
              // lblDestBPTCaption does not found in destinationBuyingPointList
              const newDestKey = parentData.lblDestBPTCaption.trim()?.split(' - ')?.[0];
              destinationBuyingPointList.push({
                key: newDestKey?.toString(),
                description: parentData.lblDestBPTCaption
              });
              const lastIndex = destinationBuyingPointList.length - 1;
              setValue(thisObj, 'ddDestBuyPt', destinationBuyingPointList[lastIndex].key);
            }
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillReceivingLocationList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const fillRateBasis = () => {
    try {
      const rateBasisList = [];
      rateBasisList.push({ key: '', description: '' });
      rateBasisList.push({ key: 'FLAT', description: 'FLAT' });
      rateBasisList.push({ key: 'MILE', description: 'MILE' });
      rateBasisList.push({ key: '100WT', description: '100WT' });
      rateBasisList.push({ key: 'TON', description: 'TON' });

      thisObj.setState(current => {
        return {
          ...current,
          ddRateBasis: {
            ...state['ddRateBasis'],
            valueList: rateBasisList
          },
          ddNoLoadRateBasis: {
            ...state['ddNoLoadRateBasis'],
            valueList: rateBasisList
          }
        }
      });
      const dataFromParent = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
      setValue(thisObj, 'ddRateBasis', dataFromParent?.cmbRateBasis || rateBasisList[0].key);
      setValue(thisObj, 'ddNoLoadRateBasis', dataFromParent?.cmbNL_Basis || rateBasisList[0].key);
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillRateBasis: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const formLoad = async () => {
    try {
      const receivingListLoaded = await fillReceivingLocationList();
      if (!receivingListLoaded) {
        return;
      }
      const shippingListLoaded = await fillShippingLocationList();
      if (!shippingListLoaded) {
        return;
      }
      fillRateBasis();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
    }
  };

  const formActivate = () => {
    try {
      const okBtnText = getLabelText('btnOk');
      if (okBtnText === 'Add') {
        setValue(thisObj, 'radioType', '1');
        enable(thisObj, 'ddOrigBuyPt');
        enable(thisObj, 'ddDestBuyPt');

        enable(thisObj, 'ddState');
        enable(thisObj, 'ddCounty');
        enable(thisObj, 'ddFarm');

        enable(thisObj, 'txtNoLoadRate');
        enable(thisObj, 'txtNoLoadWt');
        enable(thisObj, 'ddNoLoadRateBasis');

        enable(thisObj, 'radioType');
        onradioTypeChange(null, '1');
      } else if (okBtnText === 'Update') {
        disable(thisObj, 'ddOrigBuyPt');
        disable(thisObj, 'ddDestBuyPt');
        disable(thisObj, 'radioType');
        disable(thisObj, 'dtEffDate');
      }
      formLoad();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formActivate: ${error?.toString()}`
        );
      }
    }
  };

  const FFSetting = () => {
    try {
      const radioType = getValue(thisObj, 'radioType');
      if (radioType === '2') {
        const origBuyPtList = getData(thisObj, 'origBuyPtList');
        const destBuyPtList = getData(thisObj, 'destBuyPtList');
        const selectedOrigBuyPt = getValue(thisObj, 'ddOrigBuyPt');
        const origBuyPtIndex = origBuyPtList
          .findIndex(item => item.key?.toString() === selectedOrigBuyPt);
        let destBuyPtIndex = destBuyPtList
          .findIndex(item => item?.key === origBuyPtList[origBuyPtIndex]?.key
            && item?.description === origBuyPtList[origBuyPtIndex]?.description);
        if (destBuyPtIndex === -1) {
          destBuyPtList.push(origBuyPtList[origBuyPtIndex]);
          destBuyPtIndex = destBuyPtList.length - 1;
          thisObj.setState(current => {
            return {
              ...current,
              ddDestBuyPt: {
                ...state['ddDestBuyPt'],
                valueList: destBuyPtList,
                Enabled: false
              }
            }
          });
        }
        disable(thisObj, 'ddDestBuyPt');
        setValue(thisObj, 'ddDestBuyPt', destBuyPtList[destBuyPtIndex].key);
      } else {
        enable(thisObj, 'ddDestBuyPt');
      }
    } catch (error) {
      enable(thisObj, 'ddDestBuyPt');
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during FFSetting: ${error?.toString()}`
        );
      }
    }
  };

  const onradioTypeChange = async (event, defaultValue) => {
    try {
      const radioValue = defaultValue || getValue(thisObj, 'radioType');
      switch (radioValue) {
        case '1':
          hide(thisObj, 'ddState');
          hide(thisObj, 'ddCounty');
          hide(thisObj, 'ddFarm');
          hide(thisObj, 'lblNote');
          break;
        case '2':
          show(thisObj, 'ddState');
          show(thisObj, 'ddCounty');
          show(thisObj, 'ddFarm');
          show(thisObj, 'lblNote');
          break;
        default:
          break;
      }
      FFSetting();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onradioTypeChange: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onradioTypeChange = onradioTypeChange;

  const allowOnlyNumbers = (e) => {
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
        e.preventDefault();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during allowOnlyNumbers: ${error?.toString()}`
        );
      }
    }
  };

  const allowNumbersAndDecimal = (e) => {
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || (!e.target.value.includes('.') && e.keyCode === 46) || e.keyCode === 8)) {
        e.preventDefault();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during allowNumbersAndDecimal: ${error?.toString()}`
        );
      }
    }
  };

  const ontxtCarrAbbrBlur = (e) => {
    try {
      setValue(thisObj, 'txtCarrAbbr', e.target.value?.toString().toUpperCase());
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ontxtCarrAbbrBlur: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.ontxtCarrAbbrBlur = ontxtCarrAbbrBlur;

  const onddOrigBuyPtChange = async () => {
    try {
      const selectedOrigBuyPt = getValue(thisObj, 'ddOrigBuyPt');
      if (selectedOrigBuyPt.trim() !== '') {
        const isStateLoaded = await fillStateList(true, selectedOrigBuyPt);
        if (!isStateLoaded) {
          return false;
        }
        const isCountyLoaded = await fillCountyList('YES', selectedOrigBuyPt);
        if (!isCountyLoaded) {
          return false;
        }
      } else {
        const isStateLoaded = await fillStateList();
        if (!isStateLoaded) {
          return false;
        }
        const isCountyLoaded = await fillCountyList();
        if (!isCountyLoaded) {
          return false;
        }
      }
      fillFarmList();

      const selectedRadioType = getValue(thisObj, 'radioType');
      if (selectedRadioType === '2') {
        FFSetting();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onddOrigBuyPtChange: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onddOrigBuyPtChange = onddOrigBuyPtChange;

  const onddStateChange = async () => {
    try {
      const selectedOrigBuyPt = getValue(thisObj, 'ddOrigBuyPt');
      if (selectedOrigBuyPt) {
        fillCountyList('YES', selectedOrigBuyPt?.trim());
      } else {
        fillCountyList();
      }
      fillFarmList();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onddStateChange: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onddStateChange = onddStateChange;

  const onddCountyChange = async () => {
    try {
      const selectedCounty = getValue(_kaledo.thisObj, 'ddCounty');
      if (selectedCounty) {
        fillFarmList();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onddCountyChange: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onddCountyChange = onddCountyChange;

  const onbtnCancelClick = async () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_FreightLocToLocProfilePopUp").childNodes[0].click();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Cancel button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnCancelClick = onbtnCancelClick;

  function formatDate(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSecond = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
  };

  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  };
//PRB0054819_INC6192821_Farm to Location Miles Unable to Add
  const onbtnOkClick = async () => {
    try {
      const okLabelText = getLabelText('btnOk');
      const origBuyPtValue = getValue(thisObj, 'ddOrigBuyPt')?.trim() || '';
      const destBuyPtValue = getValue(thisObj, 'ddDestBuyPt')?.trim() || '';
      const radioTypeValue = getValue(thisObj, 'radioType');
      const stateValue = getValue(_kaledo.thisObj, 'ddState')?.trim() || '';
      const countyValue = getValue(_kaledo.thisObj, 'ddCounty')?.trim() || '';
      const farmValue = getValue(thisObj, 'ddFarm')?.trim() || '';
      const [farmIdValue, farmSuffixValue] = farmValue.split('-').map(s => s.trim());
            const farmList = getData(thisObj, 'farmList') || [];
      const farmSuffixList = getData(thisObj, 'farmSuffixList') || [];
      const farmIndex = farmList
        .findIndex(item => item?.key === farmValue);
      // const farmSuffixValue = farmSuffixList?.length && farmIndex !== -1
      //   ? farmSuffixList[farmIndex]
      //   : '';

      const rateValue = getValue(thisObj, 'txtRate')?.trim() || '';
      const minWtValue = getValue(thisObj, 'txtMinWt')?.trim() || '';
      const rateBasisValue = getValue(thisObj, 'ddRateBasis')?.trim() || '';
      const noLoadWtValue = getValue(thisObj, 'txtNoLoadWt')?.trim() || '';
      const noLoadRateValue = getValue(thisObj, 'txtNoLoadRate')?.trim() || '';
      const noLoadRateBasisValue = getValue(thisObj, 'ddNoLoadRateBasis')?.trim() || '';

      const milesValue = getValue(thisObj, 'txtMiles')?.trim() || '';
      const effectiveDateValue = getValue(thisObj, 'dtEffDate');
      const carrAbbrValue = getValue(thisObj, 'txtCarrAbbr')?.trim() || '';

      const dataFromParent = getData(thisObj, 'dataComingFromFreightLocToLocSetUpScreen')?.[0];
      const carrAbbrOldValue = dataFromParent?.txt_scac_id_old?.trim() || '';
      const modifyTag = okLabelText?.toUpperCase() === 'ADD'
        ? 'A' : 'U';

      const cropYear = dataFromParent?.lblcyr ? Number(dataFromParent?.lblcyr)
        : JSON.parse(sessionStorage.getItem('year'));

      if (okLabelText?.toUpperCase() === 'ADD') {
        if (origBuyPtValue === '') {
          showMessage(thisObj, 'An Orig. Location must be selected.');
          document.getElementById('ddOrigBuyPt').focus();
          return;
        }

        if (destBuyPtValue === '') {
          showMessage(thisObj, 'A Dest. Location must be selected.');
          if (isEnabled(thisObj, 'ddDestBuyPt')) {
            document.getElementById('ddDestBuyPt').focus();
          }
          return;
        }
      }

      if (radioTypeValue === '2') {
        if (!stateValue && !countyValue && !farmValue) {
          showMessage(thisObj, 'Please Select State,County and Farm');
          document.getElementById('ddState').focus();
          return;
        }

        if (!stateValue && (countyValue !== '' || farmValue !== '')) {
          showMessage(thisObj, 'Please Select State');
          document.getElementById('ddState').focus();
          return;
        }

        if (!countyValue && (stateValue !== '' || farmValue !== '')) {
          showMessage(thisObj, 'Please Select County');
          document.getElementById('ddCounty').focus();
          return;
        }

        if (!farmValue && (stateValue !== '' || countyValue !== '')) {
          showMessage(thisObj, 'Please Select Farm');
          document.getElementById('ddFarm').focus();
          return;
        }
      }

      if ((rateValue !== '' && rateValue !== '0' && rateValue !== '0.00')
        || (minWtValue !== '' && minWtValue !== '0')
        || (rateBasisValue !== '')) {
        const rateValueReplaced = rateValue.replace(/0/ig, '').replace('.', '').trim();
        const minWtValueReplaced = minWtValue.replace(/0/ig, '').trim();
        if (rateValueReplaced === ''
          || minWtValueReplaced === ''
          || rateBasisValue === '') {
          showMessage(thisObj, 'You have to fill Minimum Weight,Rate Basis and Rate');
          document.getElementById('txtMinWt').focus();
          return;
        }
      }

      if ((noLoadRateValue !== '' && noLoadRateValue !== '0' && noLoadRateValue !== '0.00')
        || (noLoadWtValue !== '' && noLoadWtValue !== '0')
        || (noLoadRateBasisValue !== '')) {
        const noLoadRateValueReplaced = noLoadRateValue.replace(/0/ig, '').replace('.', '').trim();
        const noLoadWtValueReplaced = noLoadWtValue.replace(/0/ig, '').trim();
        if (noLoadRateValueReplaced === ''
          || noLoadWtValueReplaced === ''
          || noLoadRateBasisValue === '') {
          showMessage(thisObj, 'You have to fill No Load Weight,No Load Rate Basis and No Load Rate');
          document.getElementById('txtNoLoadWt').focus();
          return;
        }
      }

      if (milesValue === '') {
        showMessage(thisObj, 'The Mileage Miles must be specified');
        document.getElementById('txtMiles').focus();
        return;
      }

      if (destBuyPtValue === '' && okLabelText.toUpperCase() === 'ADD') {
        showMessage(thisObj, 'The Dest. buying point must be specified');
        document.getElementById('ddDestBuyPt').focus();
        return;
      }

      if (origBuyPtValue === '' && okLabelText.toUpperCase() === 'ADD') {
        showMessage(thisObj, 'The Orig. buying point must be specified');
        document.getElementById('ddOrigBuyPt').focus();
        return;
      }

      if (!effectiveDateValue) {
        showMessage(thisObj, 'The Effective Date must be specified');
        document.getElementById('dtEffDateParent').firstChild.focus();
        return;
      }
//PRB0054819_INC6192821_Farm to Location Miles Unable to Add
      const data = {
        "orig_buy_pt": origBuyPtValue,
        "dest_buy_pt": destBuyPtValue,
        "loc_to_loc_rate_basis": rateBasisValue,
        "loc_to_loc_rate": rateValue?.replace(/,/ig, '')?.trim() === ''
          ? 0 : Number(rateValue.replace(/,/ig, '').trim()),
        "loc_to_loc_miles": milesValue === '' ? 0 : Number(milesValue),
        "user_id": sessionStorage.getItem('userid') || '',
        "effective_date": formatDate(effectiveDateValue).split(' ')[0],
        "carrier_abbreviation": carrAbbrValue,
        "minimum_weight": minWtValue === '' ? 0 : Number(minWtValue),
        "nl_rate": noLoadRateValue?.replace(/,/ig, '')?.trim() === ''
          ? 0 : Number(noLoadRateValue.replace(/,/ig, '').trim()),
        "nl_rate_basis": noLoadRateBasisValue,
        "nl_weight": noLoadWtValue === '' ? 0 : noLoadWtValue,
        "state_id": radioTypeValue === '2' ? stateValue : '',
        "county_id": radioTypeValue === '2' ? countyValue : '',
        // "farm_id": radioTypeValue === '2' ? farmValue : '',
        // "farm_suffix": radioTypeValue === '2' ? farmSuffixValue : '',
        "farm_id": radioTypeValue === '2' ? farmIdValue : '',
        "farm_suffix": radioTypeValue === '2' ? farmSuffixValue : '',
        "carrier_abbreviation_old": carrAbbrOldValue
      };

      if (okLabelText?.toUpperCase() === 'ADD') {
        data.add_user = sessionStorage.getItem('userid') || '';
        data.add_date_time = (new Date(new Date - (new Date()
          .getTimezoneOffset() * 60000))).toISOString();
      } else {
        data.chg_user = sessionStorage.getItem('userid') || '';
        data.chg_date_time = (new Date(new Date - (new Date()
          .getTimezoneOffset() * 60000))).toISOString();
      }

      const updateFreightLocToLocProfile = await SystemMaintenanceMasterManagementService
        .UpdateFreightLocationToLocationDetails(modifyTag, cropYear, data);

      if (updateFreightLocToLocProfile.status !== 200) {
        showMessage(thisObj, 'An error occured while Adding the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persist.');
        return;
      }

      if (okLabelText?.toUpperCase() !== 'ADD') {
        const frmFreightLocToLocSetupDataObj = {
          txtMiles: milesValue,
          cmbRateBasis: rateBasisValue,
          txtRate: rateValue?.trim() ? Number(rateValue).toFixed(2).toString() : '0.00',
          txtEffectiveDate: formatDate(effectiveDateValue).split(' ')[0],
          txtDeadFreight: minWtValue,
          txtScac_id: carrAbbrValue,
          lstStateID: stateValue,
          lstCountyID: countyValue,
          lstFarmID: farmValue + ' - ' + farmSuffixValue,
          cmbNL_Basis: noLoadRateBasisValue,
          txtNL_rate: noLoadRateValue?.trim()
            ? Number(noLoadRateValue).toFixed(2).toString() : '0.00',
          txtNL_weight: noLoadWtValue
        }
        setData(thisObj, "dataFromFreightLocToLocProfileScreen", frmFreightLocToLocSetupDataObj);
      }

      if (okLabelText?.toUpperCase() === 'ADD') {
        setData(thisObj, 'shouldOpenAddFreightLocToLocProfileScreen', true);
        document.getElementById("SystemMaintenanceMasterManagement_FreightLocToLocProfilePopUp").childNodes[0].click();
      } else {
        setData(thisObj, 'shouldOpenAddFreightLocToLocProfileScreen', false);
        document.getElementById("SystemMaintenanceMasterManagement_FreightLocToLocProfilePopUp").childNodes[0].click();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Ok button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnOkClick = onbtnOkClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FreightLocToLocProfile*/}

              {/* END_USER_CODE-USER_BEFORE_FreightLocToLocProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFreightLocToLoc*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFreightLocToLoc*/}

              <GroupBoxWidget
                conf={state.grpbxFreightLocToLoc}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_radioType*/}

                {/* END_USER_CODE-USER_BEFORE_radioType*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioType}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioType*/}

                {/* END_USER_CODE-USER_AFTER_radioType*/}
                {/* START_USER_CODE-USER_BEFORE_ddOrigBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_ddOrigBuyPt*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOrigBuyPt}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOrigBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_ddOrigBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_ddState*/}

                {/* END_USER_CODE-USER_BEFORE_ddState*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddState}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddState*/}

                {/* END_USER_CODE-USER_AFTER_ddState*/}
                {/* START_USER_CODE-USER_BEFORE_ddDestBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_ddDestBuyPt*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDestBuyPt}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDestBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_ddDestBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCounty}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                {/* START_USER_CODE-USER_BEFORE_dtEffDate*/}

                {/* END_USER_CODE-USER_BEFORE_dtEffDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtEffDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtEffDate*/}

                {/* END_USER_CODE-USER_AFTER_dtEffDate*/}
                {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddFarm}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtMiles*/}

                {/* END_USER_CODE-USER_BEFORE_txtMiles*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMiles}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMiles*/}

                {/* END_USER_CODE-USER_AFTER_txtMiles*/}
                {/* START_USER_CODE-USER_BEFORE_lblNote*/}

                {/* END_USER_CODE-USER_BEFORE_lblNote*/}

                <LabelWidget
                  values={values}
                  conf={state.lblNote}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblNote*/}

                {/* END_USER_CODE-USER_AFTER_lblNote*/}
                {/* START_USER_CODE-USER_BEFORE_lblEntrInfo*/}

                {/* END_USER_CODE-USER_BEFORE_lblEntrInfo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblEntrInfo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblEntrInfo*/}

                {/* END_USER_CODE-USER_AFTER_lblEntrInfo*/}
                {/* START_USER_CODE-USER_BEFORE_txtCarrAbbr*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrAbbr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrAbbr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrAbbr*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrAbbr*/}
                {/* START_USER_CODE-USER_BEFORE_txtMinWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtMinWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMinWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMinWt*/}

                {/* END_USER_CODE-USER_AFTER_txtMinWt*/}
                {/* START_USER_CODE-USER_BEFORE_lblPounds*/}

                {/* END_USER_CODE-USER_BEFORE_lblPounds*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPounds}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPounds*/}

                {/* END_USER_CODE-USER_AFTER_lblPounds*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateBasis*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateBasis*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRateBasis}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateBasis*/}

                {/* END_USER_CODE-USER_AFTER_ddRateBasis*/}
                {/* START_USER_CODE-USER_BEFORE_txtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRate*/}
                {/* START_USER_CODE-USER_BEFORE_txtNoLoadWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtNoLoadWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNoLoadWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNoLoadWt*/}

                {/* END_USER_CODE-USER_AFTER_txtNoLoadWt*/}
                {/* START_USER_CODE-USER_BEFORE_lblPounds2*/}

                {/* END_USER_CODE-USER_BEFORE_lblPounds2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPounds2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPounds2*/}

                {/* END_USER_CODE-USER_AFTER_lblPounds2*/}
                {/* START_USER_CODE-USER_BEFORE_ddNoLoadRateBasis*/}

                {/* END_USER_CODE-USER_BEFORE_ddNoLoadRateBasis*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddNoLoadRateBasis}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddNoLoadRateBasis*/}

                {/* END_USER_CODE-USER_AFTER_ddNoLoadRateBasis*/}
                {/* START_USER_CODE-USER_BEFORE_txtNoLoadRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtNoLoadRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNoLoadRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNoLoadRate*/}

                {/* END_USER_CODE-USER_AFTER_txtNoLoadRate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFreightLocToLoc*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFreightLocToLoc*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_txtChngdBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChngdBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChngdBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChngdBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChngdBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FreightLocToLocProfile*/}

              {/* END_USER_CODE-USER_AFTER_FreightLocToLocProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FreightLocToLocProfile
);
