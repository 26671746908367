/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_CountyProfile from "./CountyProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CountyProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_CountyProfile />);
    });
  });
  afterEach(cleanup);
  test("is CountyProfile Loads Successfully", () => {
    expect(screen.getByText("CountyProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for CountyProfile", () => {
    // START_USER_CODE-USER_CountyProfile_Custom_Test_Case
    // END_USER_CODE-USER_CountyProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_CountyProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCountyProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxCountyProfile = screen.getByTestId("grpbxCountyProfile");
    expect(grpbxCountyProfile.tagName).toBe("BUTTON");
    expect(grpbxCountyProfile.type).toBe("button");
    expect(grpbxCountyProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCountyProfile", () => {
    // START_USER_CODE-USER_grpbxCountyProfile_TEST
    // END_USER_CODE-USER_grpbxCountyProfile_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblCCCWireTransferData(Label Widget) Test Cases", async () => {
    const lblCCCWireTransferData = screen.getByTestId("lblCCCWireTransferData");
    expect(lblCCCWireTransferData.tagName).toBe("LABEL");
    expect(lblCCCWireTransferData.classList).toContain("form-label");
    expect(lblCCCWireTransferData.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CountyProfile_lblCCCWireTransferData"
      )
    );
  });
  test("Custom Test Cases for lblCCCWireTransferData", () => {
    // START_USER_CODE-USER_lblCCCWireTransferData_TEST
    // END_USER_CODE-USER_lblCCCWireTransferData_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblState(Label Widget) Test Cases", async () => {
    const lblState = screen.getByTestId("lblState");
    expect(lblState.tagName).toBe("LABEL");
    expect(lblState.classList).toContain("form-label");
    expect(lblState.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_lblState")
    );
  });
  test("Custom Test Cases for lblState", () => {
    // START_USER_CODE-USER_lblState_TEST
    // END_USER_CODE-USER_lblState_TEST
  });
  test("lblStateValue(Label Widget) Test Cases", async () => {
    const lblStateValue = screen.getByTestId("lblStateValue");
    expect(lblStateValue.tagName).toBe("LABEL");
    expect(lblStateValue.classList).toContain("form-label");
    expect(lblStateValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_lblStateValue")
    );
  });
  test("Custom Test Cases for lblStateValue", () => {
    // START_USER_CODE-USER_lblStateValue_TEST
    // END_USER_CODE-USER_lblStateValue_TEST
  });
  test("txtContact(Textbox Widget) Test Cases", async () => {
    const txtContact = screen.getByTestId("txtContact");
    expect(txtContact.tagName).toBe("INPUT");
    expect(txtContact.type).toBe("text");
    expect(txtContact.classList).toContain("textboxWidgetClass");
    expect(txtContact.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_txtContact")
    );
    await act(async () => {
      userEvent.type(txtContact, "1");
    });
  });
  test("Custom Test Cases for txtContact", () => {
    // START_USER_CODE-USER_txtContact_TEST
    // END_USER_CODE-USER_txtContact_TEST
  });
  test("txtEmail(Textbox Widget) Test Cases", async () => {
    const txtEmail = screen.getByTestId("txtEmail");
    expect(txtEmail.tagName).toBe("INPUT");
    expect(txtEmail.type).toBe("text");
    expect(txtEmail.classList).toContain("textboxWidgetClass");
    expect(txtEmail.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_txtEmail")
    );
    await act(async () => {
      userEvent.type(txtEmail, "1");
    });
  });
  test("Custom Test Cases for txtEmail", () => {
    // START_USER_CODE-USER_txtEmail_TEST
    // END_USER_CODE-USER_txtEmail_TEST
  });
  test("txtFax(Textbox Widget) Test Cases", async () => {
    const txtFax = screen.getByTestId("txtFax");
    expect(txtFax.tagName).toBe("INPUT");
    expect(txtFax.type).toBe("text");
    expect(txtFax.classList).toContain("textboxWidgetClass");
    expect(txtFax.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_txtFax")
    );
    await act(async () => {
      userEvent.type(txtFax, "1");
    });
  });
  test("Custom Test Cases for txtFax", () => {
    // START_USER_CODE-USER_txtFax_TEST
    // END_USER_CODE-USER_txtFax_TEST
  });
  test("txtID(Textbox Widget) Test Cases", async () => {
    const txtID = screen.getByTestId("txtID");
    expect(txtID.tagName).toBe("INPUT");
    expect(txtID.type).toBe("text");
    expect(txtID.classList).toContain("textboxWidgetClass");
    expect(txtID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_txtID")
    );
    await act(async () => {
      userEvent.type(txtID, "1");
    });
  });
  test("Custom Test Cases for txtID", () => {
    // START_USER_CODE-USER_txtID_TEST
    // END_USER_CODE-USER_txtID_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountyProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
});
