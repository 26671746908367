const React = require('react');
const reactI18next = require('react-i18next');
module.exports = {
  withTranslation: () => Component => props => <Component t={k => k} {...props} />,
  t: (key) => {
    let index = key.indexOf(":");
    let newKey = key.substring(index + 1, key.length);
    let name = key.substring(0, index);
    if (name === "translate") return newKey;
    let lang = window._kaledo.languages[0].langCode;
    if (lang !== undefined && lang !== null && lang === "") lang = "en";
    if (name !== undefined && name !== null && name !== '') {
      let json = require("../../public/json/" + lang + "/" + name + ".json");
      return json[newKey];
    } else {
      return newKey;
    }

  },
  I18nextProvider: reactI18next.I18nextProvider,
  initReactI18next: reactI18next.initReactI18next,
  setDefaults: reactI18next.setDefaults,
  getDefaults: reactI18next.getDefaults,
  setI18n: reactI18next.setI18n,
  getI18n: reactI18next.getI18n,
  setLanguage: (lang) => {
    return (() => { });
  },
  getTwoLevelMenus: () => {
    let screenList = window._kaledo["ScreenList"];
    let menuObj = {};
    screenList.forEach((val, indx) => {
      let firstMenuLabel = val.firstMenuLabel;
      if (
        firstMenuLabel === "Dashboard1" ||
        firstMenuLabel === "Dashboard2" ||
        firstMenuLabel === "Dashboard3" ||
        firstMenuLabel === "Dashboard4"
      ) {
        firstMenuLabel = "Dashboard";
      } else if (
        firstMenuLabel === "GoogleSearch" ||
        firstMenuLabel === "TransactionSearch"
      ) {
        firstMenuLabel = "Search";
      }
      if (!menuObj.hasOwnProperty(firstMenuLabel)) {
        let submenusArr = [];
        let submenuObj = Object.assign({}, val);
        delete submenuObj["firstMenuLabel"];
        submenusArr.push(submenuObj);
        menuObj[firstMenuLabel] = submenusArr;
      } else {
        let submenusArr = menuObj[firstMenuLabel];
        let submenuObj = Object.assign({}, val);
        delete submenuObj["firstMenuLabel"];
        submenusArr.push(submenuObj);
      }
    });

    let menuArr = [];
    for (let key in menuObj) {
      let value = menuObj[key];
      let newMenuObj = {};
      newMenuObj["menuLabel"] = key;
      newMenuObj["submenus"] = value;
      menuArr.push(newMenuObj);
    }
    return menuArr;
  },
};
