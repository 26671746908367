/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_UserSignatureSetup from "./UserSignatureSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("UserSignatureSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_UserSignatureSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is UserSignatureSetup Loads Successfully", () => {
    expect(screen.getByText("UserSignatureSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for UserSignatureSetup", () => {
    // START_USER_CODE-USER_UserSignatureSetup_Custom_Test_Case
    // END_USER_CODE-USER_UserSignatureSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_UserSignatureSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnLoadFromFile(Button Widget) Test Cases", async () => {
    const btnLoadFromFile = screen.getByTestId("btnLoadFromFile");
    expect(btnLoadFromFile).toBeInTheDocument;
    expect(btnLoadFromFile.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_btnLoadFromFile")
    );
  });
  test("Custom Test Cases for btnLoadFromFile", () => {
    // START_USER_CODE-USER_btnLoadFromFile_TEST
    // END_USER_CODE-USER_btnLoadFromFile_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnScan(Button Widget) Test Cases", async () => {
    const btnScan = screen.getByTestId("btnScan");
    expect(btnScan).toBeInTheDocument;
    expect(btnScan.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_btnScan")
    );
  });
  test("Custom Test Cases for btnScan", () => {
    // START_USER_CODE-USER_btnScan_TEST
    // END_USER_CODE-USER_btnScan_TEST
  });
  test("grpbxUserSignatureSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxUserSignatureSetup = screen.getByTestId(
      "grpbxUserSignatureSetup"
    );
    expect(grpbxUserSignatureSetup.tagName).toBe("BUTTON");
    expect(grpbxUserSignatureSetup.type).toBe("button");
    expect(grpbxUserSignatureSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUserSignatureSetup", () => {
    // START_USER_CODE-USER_grpbxUserSignatureSetup_TEST
    // END_USER_CODE-USER_grpbxUserSignatureSetup_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtTitle(Textbox Widget) Test Cases", async () => {
    const txtTitle = screen.getByTestId("txtTitle");
    expect(txtTitle.tagName).toBe("INPUT");
    expect(txtTitle.type).toBe("text");
    expect(txtTitle.classList).toContain("textboxWidgetClass");
    expect(txtTitle.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:UserSignatureSetup_txtTitle")
    );
    await act(async () => {
      userEvent.type(txtTitle, "1");
    });
  });
  test("Custom Test Cases for txtTitle", () => {
    // START_USER_CODE-USER_txtTitle_TEST
    // END_USER_CODE-USER_txtTitle_TEST
  });
});
