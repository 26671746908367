/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PeanutTypeProfile from "./PeanutTypeProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PeanutTypeProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_PeanutTypeProfile />);
    });
  });
  afterEach(cleanup);
  test("is PeanutTypeProfile Loads Successfully", () => {
    expect(screen.getByText("PeanutTypeProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for PeanutTypeProfile", () => {
    // START_USER_CODE-USER_PeanutTypeProfile_Custom_Test_Case
    // END_USER_CODE-USER_PeanutTypeProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_PeanutTypeProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxPeanutTypeProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutTypeProfile = screen.getByTestId("grpbxPeanutTypeProfile");
    expect(grpbxPeanutTypeProfile.tagName).toBe("BUTTON");
    expect(grpbxPeanutTypeProfile.type).toBe("button");
    expect(grpbxPeanutTypeProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutTypeProfile", () => {
    // START_USER_CODE-USER_grpbxPeanutTypeProfile_TEST
    // END_USER_CODE-USER_grpbxPeanutTypeProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtAccountSubDepartment(Textbox Widget) Test Cases", async () => {
    const txtAccountSubDepartment = screen.getByTestId(
      "txtAccountSubDepartment"
    );
    expect(txtAccountSubDepartment.tagName).toBe("INPUT");
    expect(txtAccountSubDepartment.type).toBe("text");
    expect(txtAccountSubDepartment.classList).toContain("textboxWidgetClass");
    expect(txtAccountSubDepartment.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutTypeProfile_txtAccountSubDepartment"
      )
    );
    await act(async () => {
      userEvent.type(txtAccountSubDepartment, "1");
    });
  });
  test("Custom Test Cases for txtAccountSubDepartment", () => {
    // START_USER_CODE-USER_txtAccountSubDepartment_TEST
    // END_USER_CODE-USER_txtAccountSubDepartment_TEST
  });
  test("txtID(Textbox Widget) Test Cases", async () => {
    const txtID = screen.getByTestId("txtID");
    expect(txtID.tagName).toBe("INPUT");
    expect(txtID.type).toBe("text");
    expect(txtID.classList).toContain("textboxWidgetClass");
    expect(txtID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtID")
    );
    await act(async () => {
      userEvent.type(txtID, "1");
    });
  });
  test("Custom Test Cases for txtID", () => {
    // START_USER_CODE-USER_txtID_TEST
    // END_USER_CODE-USER_txtID_TEST
  });
  test("txtLSKGuage(Textbox Widget) Test Cases", async () => {
    const txtLSKGuage = screen.getByTestId("txtLSKGuage");
    expect(txtLSKGuage.tagName).toBe("INPUT");
    expect(txtLSKGuage.type).toBe("text");
    expect(txtLSKGuage.classList).toContain("textboxWidgetClass");
    expect(txtLSKGuage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtLSKGuage")
    );
    await act(async () => {
      userEvent.type(txtLSKGuage, "1");
    });
  });
  test("Custom Test Cases for txtLSKGuage", () => {
    // START_USER_CODE-USER_txtLSKGuage_TEST
    // END_USER_CODE-USER_txtLSKGuage_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtOkGuage(Textbox Widget) Test Cases", async () => {
    const txtOkGuage = screen.getByTestId("txtOkGuage");
    expect(txtOkGuage.tagName).toBe("INPUT");
    expect(txtOkGuage.type).toBe("text");
    expect(txtOkGuage.classList).toContain("textboxWidgetClass");
    expect(txtOkGuage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtOkGuage")
    );
    await act(async () => {
      userEvent.type(txtOkGuage, "1");
    });
  });
  test("Custom Test Cases for txtOkGuage", () => {
    // START_USER_CODE-USER_txtOkGuage_TEST
    // END_USER_CODE-USER_txtOkGuage_TEST
  });
  test("txtSMK1Guage(Textbox Widget) Test Cases", async () => {
    const txtSMK1Guage = screen.getByTestId("txtSMK1Guage");
    expect(txtSMK1Guage.tagName).toBe("INPUT");
    expect(txtSMK1Guage.type).toBe("text");
    expect(txtSMK1Guage.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Guage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtSMK1Guage")
    );
    await act(async () => {
      userEvent.type(txtSMK1Guage, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Guage", () => {
    // START_USER_CODE-USER_txtSMK1Guage_TEST
    // END_USER_CODE-USER_txtSMK1Guage_TEST
  });
  test("txtSMK2Guage(Textbox Widget) Test Cases", async () => {
    const txtSMK2Guage = screen.getByTestId("txtSMK2Guage");
    expect(txtSMK2Guage.tagName).toBe("INPUT");
    expect(txtSMK2Guage.type).toBe("text");
    expect(txtSMK2Guage.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Guage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtSMK2Guage")
    );
    await act(async () => {
      userEvent.type(txtSMK2Guage, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Guage", () => {
    // START_USER_CODE-USER_txtSMK2Guage_TEST
    // END_USER_CODE-USER_txtSMK2Guage_TEST
  });
  test("txtSMK3Guage(Textbox Widget) Test Cases", async () => {
    const txtSMK3Guage = screen.getByTestId("txtSMK3Guage");
    expect(txtSMK3Guage.tagName).toBe("INPUT");
    expect(txtSMK3Guage.type).toBe("text");
    expect(txtSMK3Guage.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Guage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtSMK3Guage")
    );
    await act(async () => {
      userEvent.type(txtSMK3Guage, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Guage", () => {
    // START_USER_CODE-USER_txtSMK3Guage_TEST
    // END_USER_CODE-USER_txtSMK3Guage_TEST
  });
  test("txtSMK4Guage(Textbox Widget) Test Cases", async () => {
    const txtSMK4Guage = screen.getByTestId("txtSMK4Guage");
    expect(txtSMK4Guage.tagName).toBe("INPUT");
    expect(txtSMK4Guage.type).toBe("text");
    expect(txtSMK4Guage.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Guage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutTypeProfile_txtSMK4Guage")
    );
    await act(async () => {
      userEvent.type(txtSMK4Guage, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Guage", () => {
    // START_USER_CODE-USER_txtSMK4Guage_TEST
    // END_USER_CODE-USER_txtSMK4Guage_TEST
  });
});
