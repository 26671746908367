import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import hotkeys from 'hotkeys-js';
import "./i18n";
import "./index.scss";
import "./app_customized.scss";

import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { CommonContextProvider } from './app/modules/Store/CommonContext';

window.$ = window.jQuery = require("jquery");
window.moment = require("moment");
require("tempusdominus-bootstrap-4");

hotkeys.filter = function(event) {
  return true;
}

ReactDOM.render(
  <CommonContextProvider>
    <React.StrictMode>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </React.StrictMode>
  </CommonContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
