import React from "react";
import { render } from "@testing-library/react";

import { MemoryRouter } from "react-router-dom";
import { t, getTwoLevelMenus, setLanguage } from "../__mocks__/react-i18next";
import AppContext from "../context/AppContext";

const renderTestScreen = (component) => {
  const defaultRender = render(
    <AppContext.Provider
      value={{
        t: t,
        _selectedLang: "en",
        langClick: setLanguage(),
        screenList: getTwoLevelMenus(),
        _languages: window._kaledo["languages"],
      }}
    >
      <MemoryRouter>{component}</MemoryRouter>
    </AppContext.Provider>
  );
  return defaultRender;
};

export default renderTestScreen;
