/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_ContractLimitArea from "./ContractLimitArea";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractLimitArea Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_ContractLimitArea />);
    });
  });
  afterEach(cleanup);
  test("is ContractLimitArea Loads Successfully", () => {
    expect(screen.getByText("ContractLimitArea")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractLimitArea", () => {
    // START_USER_CODE-USER_ContractLimitArea_Custom_Test_Case
    // END_USER_CODE-USER_ContractLimitArea_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_ContractLimitArea />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnImage1(Button Widget) Test Cases", async () => {
    const btnImage1 = screen.getByTestId("btnImage1");
    expect(btnImage1).toBeInTheDocument;
    expect(btnImage1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_btnImage1")
    );
  });
  test("Custom Test Cases for btnImage1", () => {
    // START_USER_CODE-USER_btnImage1_TEST
    // END_USER_CODE-USER_btnImage1_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxContractLimitArea(GroupBox Widget) Test Cases", async () => {
    const grpbxContractLimitArea = screen.getByTestId("grpbxContractLimitArea");
    expect(grpbxContractLimitArea.tagName).toBe("BUTTON");
    expect(grpbxContractLimitArea.type).toBe("button");
    expect(grpbxContractLimitArea.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractLimitArea", () => {
    // START_USER_CODE-USER_grpbxContractLimitArea_TEST
    // END_USER_CODE-USER_grpbxContractLimitArea_TEST
  });
  test("grpbxFloorControls(GroupBox Widget) Test Cases", async () => {
    const grpbxFloorControls = screen.getByTestId("grpbxFloorControls");
    expect(grpbxFloorControls.tagName).toBe("BUTTON");
    expect(grpbxFloorControls.type).toBe("button");
    expect(grpbxFloorControls.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFloorControls", () => {
    // START_USER_CODE-USER_grpbxFloorControls_TEST
    // END_USER_CODE-USER_grpbxFloorControls_TEST
  });
  test("grpbxTonsDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxTonsDetails = screen.getByTestId("grpbxTonsDetails");
    expect(grpbxTonsDetails.tagName).toBe("BUTTON");
    expect(grpbxTonsDetails.type).toBe("button");
    expect(grpbxTonsDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTonsDetails", () => {
    // START_USER_CODE-USER_grpbxTonsDetails_TEST
    // END_USER_CODE-USER_grpbxTonsDetails_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblDateFormat(Label Widget) Test Cases", async () => {
    const lblDateFormat = screen.getByTestId("lblDateFormat");
    expect(lblDateFormat.tagName).toBe("LABEL");
    expect(lblDateFormat.classList).toContain("form-label");
    expect(lblDateFormat.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_lblDateFormat")
    );
  });
  test("Custom Test Cases for lblDateFormat", () => {
    // START_USER_CODE-USER_lblDateFormat_TEST
    // END_USER_CODE-USER_lblDateFormat_TEST
  });
  test("lblFloorControls(Label Widget) Test Cases", async () => {
    const lblFloorControls = screen.getByTestId("lblFloorControls");
    expect(lblFloorControls.tagName).toBe("LABEL");
    expect(lblFloorControls.classList).toContain("form-label");
    expect(lblFloorControls.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_lblFloorControls")
    );
  });
  test("Custom Test Cases for lblFloorControls", () => {
    // START_USER_CODE-USER_lblFloorControls_TEST
    // END_USER_CODE-USER_lblFloorControls_TEST
  });
  test("txtAvailableTon(Textbox Widget) Test Cases", async () => {
    const txtAvailableTon = screen.getByTestId("txtAvailableTon");
    expect(txtAvailableTon.tagName).toBe("INPUT");
    expect(txtAvailableTon.type).toBe("text");
    expect(txtAvailableTon.classList).toContain("textboxWidgetClass");
    expect(txtAvailableTon.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtAvailableTon")
    );
    await act(async () => {
      userEvent.type(txtAvailableTon, "1");
    });
  });
  test("Custom Test Cases for txtAvailableTon", () => {
    // START_USER_CODE-USER_txtAvailableTon_TEST
    // END_USER_CODE-USER_txtAvailableTon_TEST
  });
  test("txtDescription(Textbox Widget) Test Cases", async () => {
    const txtDescription = screen.getByTestId("txtDescription");
    expect(txtDescription.tagName).toBe("INPUT");
    expect(txtDescription.type).toBe("text");
    expect(txtDescription.classList).toContain("textboxWidgetClass");
    expect(txtDescription.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtDescription")
    );
    await act(async () => {
      userEvent.type(txtDescription, "1");
    });
  });
  test("Custom Test Cases for txtDescription", () => {
    // START_USER_CODE-USER_txtDescription_TEST
    // END_USER_CODE-USER_txtDescription_TEST
  });
  test("txtDryLandAcresContracted(Textbox Widget) Test Cases", async () => {
    const txtDryLandAcresContracted = screen.getByTestId(
      "txtDryLandAcresContracted"
    );
    expect(txtDryLandAcresContracted.tagName).toBe("INPUT");
    expect(txtDryLandAcresContracted.type).toBe("text");
    expect(txtDryLandAcresContracted.classList).toContain("textboxWidgetClass");
    expect(
      txtDryLandAcresContracted.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitArea_txtDryLandAcresContracted"
      )
    );
    await act(async () => {
      userEvent.type(txtDryLandAcresContracted, "1");
    });
  });
  test("Custom Test Cases for txtDryLandAcresContracted", () => {
    // START_USER_CODE-USER_txtDryLandAcresContracted_TEST
    // END_USER_CODE-USER_txtDryLandAcresContracted_TEST
  });
  test("txtIrrigatedAcresContracted(Textbox Widget) Test Cases", async () => {
    const txtIrrigatedAcresContracted = screen.getByTestId(
      "txtIrrigatedAcresContracted"
    );
    expect(txtIrrigatedAcresContracted.tagName).toBe("INPUT");
    expect(txtIrrigatedAcresContracted.type).toBe("text");
    expect(txtIrrigatedAcresContracted.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtIrrigatedAcresContracted.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitArea_txtIrrigatedAcresContracted"
      )
    );
    await act(async () => {
      userEvent.type(txtIrrigatedAcresContracted, "1");
    });
  });
  test("Custom Test Cases for txtIrrigatedAcresContracted", () => {
    // START_USER_CODE-USER_txtIrrigatedAcresContracted_TEST
    // END_USER_CODE-USER_txtIrrigatedAcresContracted_TEST
  });
  test("txtOpenTons(Textbox Widget) Test Cases", async () => {
    const txtOpenTons = screen.getByTestId("txtOpenTons");
    expect(txtOpenTons.tagName).toBe("INPUT");
    expect(txtOpenTons.type).toBe("text");
    expect(txtOpenTons.classList).toContain("textboxWidgetClass");
    expect(txtOpenTons.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtOpenTons")
    );
    await act(async () => {
      userEvent.type(txtOpenTons, "1");
    });
  });
  test("Custom Test Cases for txtOpenTons", () => {
    // START_USER_CODE-USER_txtOpenTons_TEST
    // END_USER_CODE-USER_txtOpenTons_TEST
  });
  test("txtOptionPriceCap(Textbox Widget) Test Cases", async () => {
    const txtOptionPriceCap = screen.getByTestId("txtOptionPriceCap");
    expect(txtOptionPriceCap.tagName).toBe("INPUT");
    expect(txtOptionPriceCap.type).toBe("text");
    expect(txtOptionPriceCap.classList).toContain("textboxWidgetClass");
    expect(txtOptionPriceCap.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtOptionPriceCap")
    );
    await act(async () => {
      userEvent.type(txtOptionPriceCap, "1");
    });
  });
  test("Custom Test Cases for txtOptionPriceCap", () => {
    // START_USER_CODE-USER_txtOptionPriceCap_TEST
    // END_USER_CODE-USER_txtOptionPriceCap_TEST
  });
  test("txtPriceMatchEndDate(Date Widget) Test Cases", async () => {
    const txtPriceMatchEndDate = screen.getByTestId("txtPriceMatchEndDate");
    expect(txtPriceMatchEndDate.tagName).toBe("INPUT");
    expect(txtPriceMatchEndDate.type).toBe("text");
    expect(txtPriceMatchEndDate.classList).toContain("datetimepicker-input");
    expect(
      txtPriceMatchEndDate.parentElement.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitArea_txtPriceMatchEndDate"
      )
    );
    await act(async () => {
      userEvent.click(txtPriceMatchEndDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtPriceMatchEndDate", () => {
    // START_USER_CODE-USER_txtPriceMatchEndDate_TEST
    // END_USER_CODE-USER_txtPriceMatchEndDate_TEST
  });
  test("txtPricePerTon(Textbox Widget) Test Cases", async () => {
    const txtPricePerTon = screen.getByTestId("txtPricePerTon");
    expect(txtPricePerTon.tagName).toBe("INPUT");
    expect(txtPricePerTon.type).toBe("text");
    expect(txtPricePerTon.classList).toContain("textboxWidgetClass");
    expect(txtPricePerTon.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtPricePerTon")
    );
    await act(async () => {
      userEvent.type(txtPricePerTon, "1");
    });
  });
  test("Custom Test Cases for txtPricePerTon", () => {
    // START_USER_CODE-USER_txtPricePerTon_TEST
    // END_USER_CODE-USER_txtPricePerTon_TEST
  });
  test("txtTonsAllowed(Textbox Widget) Test Cases", async () => {
    const txtTonsAllowed = screen.getByTestId("txtTonsAllowed");
    expect(txtTonsAllowed.tagName).toBe("INPUT");
    expect(txtTonsAllowed.type).toBe("text");
    expect(txtTonsAllowed.classList).toContain("textboxWidgetClass");
    expect(txtTonsAllowed.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtTonsAllowed")
    );
    await act(async () => {
      userEvent.type(txtTonsAllowed, "1");
    });
  });
  test("Custom Test Cases for txtTonsAllowed", () => {
    // START_USER_CODE-USER_txtTonsAllowed_TEST
    // END_USER_CODE-USER_txtTonsAllowed_TEST
  });
  test("txtTonsAssigned(Textbox Widget) Test Cases", async () => {
    const txtTonsAssigned = screen.getByTestId("txtTonsAssigned");
    expect(txtTonsAssigned.tagName).toBe("INPUT");
    expect(txtTonsAssigned.type).toBe("text");
    expect(txtTonsAssigned.classList).toContain("textboxWidgetClass");
    expect(txtTonsAssigned.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtTonsAssigned")
    );
    await act(async () => {
      userEvent.type(txtTonsAssigned, "1");
    });
  });
  test("Custom Test Cases for txtTonsAssigned", () => {
    // START_USER_CODE-USER_txtTonsAssigned_TEST
    // END_USER_CODE-USER_txtTonsAssigned_TEST
  });
  test("txtTonsContracted(Textbox Widget) Test Cases", async () => {
    const txtTonsContracted = screen.getByTestId("txtTonsContracted");
    expect(txtTonsContracted.tagName).toBe("INPUT");
    expect(txtTonsContracted.type).toBe("text");
    expect(txtTonsContracted.classList).toContain("textboxWidgetClass");
    expect(txtTonsContracted.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitArea_txtTonsContracted")
    );
    await act(async () => {
      userEvent.type(txtTonsContracted, "1");
    });
  });
  test("Custom Test Cases for txtTonsContracted", () => {
    // START_USER_CODE-USER_txtTonsContracted_TEST
    // END_USER_CODE-USER_txtTonsContracted_TEST
  });
});
