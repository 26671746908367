/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvPremiumDeductions from "./DelvPremiumDeductions";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvPremiumDeductions Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvPremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("is DelvPremiumDeductions Loads Successfully", () => {
    expect(screen.getByText("DelvPremiumDeductions")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvPremiumDeductions", () => {
    // START_USER_CODE-USER_DelvPremiumDeductions_Custom_Test_Case
    // END_USER_CODE-USER_DelvPremiumDeductions_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvPremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:DelvPremiumDeductions_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridPremiumDeductions_btncol1(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_btncol1 = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_btncol1btn =
      gridPremiumDeductions_btncol1.nextElementSibling.firstElementChild;
    gridPremiumDeductions_btncol1 =
      gridPremiumDeductions_btncol1.parentElement.parentElement.parentElement;
    expect(gridPremiumDeductions_btncol1.tagName).toBe("DIV");
    expect(gridPremiumDeductions_btncol1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncol1", () => {
    // START_USER_CODE-USER_btncol1_TEST
    // END_USER_CODE-USER_btncol1_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("WarehouseReceipts:DelvPremiumDeductions_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridPremiumDeductions(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions = screen.getByTestId("gridPremiumDeductions");
    let gridPremiumDeductionsbtn =
      gridPremiumDeductions.nextElementSibling.firstElementChild;
    gridPremiumDeductions =
      gridPremiumDeductions.parentElement.parentElement.parentElement;
    expect(gridPremiumDeductions.tagName).toBe("DIV");
    expect(gridPremiumDeductions.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremiumDeductions", () => {
    // START_USER_CODE-USER_gridPremiumDeductions_TEST
    // END_USER_CODE-USER_gridPremiumDeductions_TEST
  });
  test("grpbxDelvPremiumDeductions(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvPremiumDeductions = screen.getByTestId(
      "grpbxDelvPremiumDeductions"
    );
    expect(grpbxDelvPremiumDeductions.tagName).toBe("BUTTON");
    expect(grpbxDelvPremiumDeductions.type).toBe("button");
    expect(grpbxDelvPremiumDeductions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvPremiumDeductions", () => {
    // START_USER_CODE-USER_grpbxDelvPremiumDeductions_TEST
    // END_USER_CODE-USER_grpbxDelvPremiumDeductions_TEST
  });
  test("lblPremDeduct(Label Widget) Test Cases", async () => {
    const lblPremDeduct = screen.getByTestId("lblPremDeduct");
    expect(lblPremDeduct.tagName).toBe("LABEL");
    expect(lblPremDeduct.classList).toContain("form-label");
    expect(lblPremDeduct.textContent).toEqual(
      t("WarehouseReceipts:DelvPremiumDeductions_lblPremDeduct")
    );
  });
  test("Custom Test Cases for lblPremDeduct", () => {
    // START_USER_CODE-USER_lblPremDeduct_TEST
    // END_USER_CODE-USER_lblPremDeduct_TEST
  });
  test("gridPremiumDeductions_txtcol2(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcol2 = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcol2btn =
      gridPremiumDeductions_txtcol2.nextElementSibling.firstElementChild;
    gridPremiumDeductions_txtcol2 =
      gridPremiumDeductions_txtcol2.parentElement.parentElement.parentElement;
    expect(gridPremiumDeductions_txtcol2.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcol2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol2", () => {
    // START_USER_CODE-USER_txtcol2_TEST
    // END_USER_CODE-USER_txtcol2_TEST
  });
  test("gridPremiumDeductions_txtcol3(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcol3 = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcol3btn =
      gridPremiumDeductions_txtcol3.nextElementSibling.firstElementChild;
    gridPremiumDeductions_txtcol3 =
      gridPremiumDeductions_txtcol3.parentElement.parentElement.parentElement;
    expect(gridPremiumDeductions_txtcol3.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcol3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol3", () => {
    // START_USER_CODE-USER_txtcol3_TEST
    // END_USER_CODE-USER_txtcol3_TEST
  });
  test("gridPremiumDeductions_txtcolPremDeduct(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcolPremDeduct = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcolPremDeductbtn =
      gridPremiumDeductions_txtcolPremDeduct.nextElementSibling
        .firstElementChild;
    gridPremiumDeductions_txtcolPremDeduct =
      gridPremiumDeductions_txtcolPremDeduct.parentElement.parentElement
        .parentElement;
    expect(gridPremiumDeductions_txtcolPremDeduct.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcolPremDeduct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPremDeduct", () => {
    // START_USER_CODE-USER_txtcolPremDeduct_TEST
    // END_USER_CODE-USER_txtcolPremDeduct_TEST
  });
  test("gridPremiumDeductions_txtcolPurchaseAmount(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcolPurchaseAmount = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcolPurchaseAmountbtn =
      gridPremiumDeductions_txtcolPurchaseAmount.nextElementSibling
        .firstElementChild;
    gridPremiumDeductions_txtcolPurchaseAmount =
      gridPremiumDeductions_txtcolPurchaseAmount.parentElement.parentElement
        .parentElement;
    expect(gridPremiumDeductions_txtcolPurchaseAmount.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcolPurchaseAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurchaseAmount", () => {
    // START_USER_CODE-USER_txtcolPurchaseAmount_TEST
    // END_USER_CODE-USER_txtcolPurchaseAmount_TEST
  });
  test("gridPremiumDeductions_txtcolStorageAmount(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcolStorageAmount = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcolStorageAmountbtn =
      gridPremiumDeductions_txtcolStorageAmount.nextElementSibling
        .firstElementChild;
    gridPremiumDeductions_txtcolStorageAmount =
      gridPremiumDeductions_txtcolStorageAmount.parentElement.parentElement
        .parentElement;
    expect(gridPremiumDeductions_txtcolStorageAmount.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcolStorageAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvPremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStorageAmount", () => {
    // START_USER_CODE-USER_txtcolStorageAmount_TEST
    // END_USER_CODE-USER_txtcolStorageAmount_TEST
  });
  test("txtConvertedValue(Textbox Widget) Test Cases", async () => {
    const txtConvertedValue = screen.getByTestId("txtConvertedValue");
    expect(txtConvertedValue.tagName).toBe("INPUT");
    expect(txtConvertedValue.type).toBe("text");
    expect(txtConvertedValue.classList).toContain("textboxWidgetClass");
    expect(txtConvertedValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvPremiumDeductions_txtConvertedValue")
    );
    await act(async () => {
      userEvent.type(txtConvertedValue, "1");
    });
  });
  test("Custom Test Cases for txtConvertedValue", () => {
    // START_USER_CODE-USER_txtConvertedValue_TEST
    // END_USER_CODE-USER_txtConvertedValue_TEST
  });
  test("txtNetProceeds(Textbox Widget) Test Cases", async () => {
    const txtNetProceeds = screen.getByTestId("txtNetProceeds");
    expect(txtNetProceeds.tagName).toBe("INPUT");
    expect(txtNetProceeds.type).toBe("text");
    expect(txtNetProceeds.classList).toContain("textboxWidgetClass");
    expect(txtNetProceeds.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvPremiumDeductions_txtNetProceeds")
    );
    await act(async () => {
      userEvent.type(txtNetProceeds, "1");
    });
  });
  test("Custom Test Cases for txtNetProceeds", () => {
    // START_USER_CODE-USER_txtNetProceeds_TEST
    // END_USER_CODE-USER_txtNetProceeds_TEST
  });
});
