/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch from "./ManualDPRInvHistSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualDPRInvHistSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualDPRInvHistSearch Loads Successfully", () => {
    expect(screen.getByText("ManualDPRInvHistSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualDPRInvHistSearch", () => {
    // START_USER_CODE-USER_ManualDPRInvHistSearch_Custom_Test_Case
    // END_USER_CODE-USER_ManualDPRInvHistSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch />
      );
    });
  });
  afterEach(cleanup);
  test("btmExit(Button Widget) Test Cases", async () => {
    const btmExit = screen.getByTestId("btmExit");
    expect(btmExit).toBeInTheDocument;
    expect(btmExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_btmExit")
    );
  });
  test("Custom Test Cases for btmExit", () => {
    // START_USER_CODE-USER_btmExit_TEST
    // END_USER_CODE-USER_btmExit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnView(Button Widget) Test Cases", async () => {
    const btnView = screen.getByTestId("btnView");
    expect(btnView).toBeInTheDocument;
    expect(btnView.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_btnView")
    );
  });
  test("Custom Test Cases for btnView", () => {
    // START_USER_CODE-USER_btnView_TEST
    // END_USER_CODE-USER_btnView_TEST
  });
  test("buttonwidget5(Button Widget) Test Cases", async () => {
    const buttonwidget5 = screen.getByTestId("buttonwidget5");
    expect(buttonwidget5).toBeInTheDocument;
    expect(buttonwidget5.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_buttonwidget5"
      )
    );
  });
  test("Custom Test Cases for buttonwidget5", () => {
    // START_USER_CODE-USER_buttonwidget5_TEST
    // END_USER_CODE-USER_buttonwidget5_TEST
  });
  test("gridAuditHistory(Grid Widget) Test Cases", async () => {
    let gridAuditHistory = screen.getByTestId("gridAuditHistory");
    let gridAuditHistorybtn =
      gridAuditHistory.nextElementSibling.firstElementChild;
    gridAuditHistory =
      gridAuditHistory.parentElement.parentElement.parentElement;
    expect(gridAuditHistory.tagName).toBe("DIV");
    expect(gridAuditHistory.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAuditHistory", () => {
    // START_USER_CODE-USER_gridAuditHistory_TEST
    // END_USER_CODE-USER_gridAuditHistory_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAuditHistory(GroupBox Widget) Test Cases", async () => {
    const grpbxAuditHistory = screen.getByTestId("grpbxAuditHistory");
    expect(grpbxAuditHistory.tagName).toBe("BUTTON");
    expect(grpbxAuditHistory.type).toBe("button");
    expect(grpbxAuditHistory.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAuditHistory", () => {
    // START_USER_CODE-USER_grpbxAuditHistory_TEST
    // END_USER_CODE-USER_grpbxAuditHistory_TEST
  });
  test("grpbxBuyPtDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyPtDetails = screen.getByTestId("grpbxBuyPtDetails");
    expect(grpbxBuyPtDetails.tagName).toBe("BUTTON");
    expect(grpbxBuyPtDetails.type).toBe("button");
    expect(grpbxBuyPtDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyPtDetails", () => {
    // START_USER_CODE-USER_grpbxBuyPtDetails_TEST
    // END_USER_CODE-USER_grpbxBuyPtDetails_TEST
  });
  test("grpbxManualDPRInvHistSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxManualDPRInvHistSearch = screen.getByTestId(
      "grpbxManualDPRInvHistSearch"
    );
    expect(grpbxManualDPRInvHistSearch.tagName).toBe("BUTTON");
    expect(grpbxManualDPRInvHistSearch.type).toBe("button");
    expect(grpbxManualDPRInvHistSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualDPRInvHistSearch", () => {
    // START_USER_CODE-USER_grpbxManualDPRInvHistSearch_TEST
    // END_USER_CODE-USER_grpbxManualDPRInvHistSearch_TEST
  });
  test("grpbxPnutDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxPnutDetails = screen.getByTestId("grpbxPnutDetails");
    expect(grpbxPnutDetails.tagName).toBe("BUTTON");
    expect(grpbxPnutDetails.type).toBe("button");
    expect(grpbxPnutDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPnutDetails", () => {
    // START_USER_CODE-USER_grpbxPnutDetails_TEST
    // END_USER_CODE-USER_grpbxPnutDetails_TEST
  });
  test("grpbxPrintExport(GroupBox Widget) Test Cases", async () => {
    const grpbxPrintExport = screen.getByTestId("grpbxPrintExport");
    expect(grpbxPrintExport.tagName).toBe("BUTTON");
    expect(grpbxPrintExport.type).toBe("button");
    expect(grpbxPrintExport.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrintExport", () => {
    // START_USER_CODE-USER_grpbxPrintExport_TEST
    // END_USER_CODE-USER_grpbxPrintExport_TEST
  });
  test("grpbxTransferDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxTransferDetails = screen.getByTestId("grpbxTransferDetails");
    expect(grpbxTransferDetails.tagName).toBe("BUTTON");
    expect(grpbxTransferDetails.type).toBe("button");
    expect(grpbxTransferDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransferDetails", () => {
    // START_USER_CODE-USER_grpbxTransferDetails_TEST
    // END_USER_CODE-USER_grpbxTransferDetails_TEST
  });
  test("lblAuditHistOfInvAdj(Label Widget) Test Cases", async () => {
    const lblAuditHistOfInvAdj = screen.getByTestId("lblAuditHistOfInvAdj");
    expect(lblAuditHistOfInvAdj.tagName).toBe("LABEL");
    expect(lblAuditHistOfInvAdj.classList).toContain("form-label");
    expect(lblAuditHistOfInvAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_lblAuditHistOfInvAdj"
      )
    );
  });
  test("Custom Test Cases for lblAuditHistOfInvAdj", () => {
    // START_USER_CODE-USER_lblAuditHistOfInvAdj_TEST
    // END_USER_CODE-USER_lblAuditHistOfInvAdj_TEST
  });
  test("lblPeanut(Label Widget) Test Cases", async () => {
    const lblPeanut = screen.getByTestId("lblPeanut");
    expect(lblPeanut.tagName).toBe("LABEL");
    expect(lblPeanut.classList).toContain("form-label");
    expect(lblPeanut.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_lblPeanut")
    );
  });
  test("Custom Test Cases for lblPeanut", () => {
    // START_USER_CODE-USER_lblPeanut_TEST
    // END_USER_CODE-USER_lblPeanut_TEST
  });
  test("gridAuditHistory_txcolNewPhysicalAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txcolNewPhysicalAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txcolNewPhysicalAdjbtn =
      gridAuditHistory_txcolNewPhysicalAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txcolNewPhysicalAdj =
      gridAuditHistory_txcolNewPhysicalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txcolNewPhysicalAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txcolNewPhysicalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txcolNewPhysicalAdj", () => {
    // START_USER_CODE-USER_txcolNewPhysicalAdj_TEST
    // END_USER_CODE-USER_txcolNewPhysicalAdj_TEST
  });
  test("txt1007Num(Textbox Widget) Test Cases", async () => {
    const txt1007Num = screen.getByTestId("txt1007Num");
    expect(txt1007Num.tagName).toBe("INPUT");
    expect(txt1007Num.type).toBe("text");
    expect(txt1007Num.classList).toContain("textboxWidgetClass");
    expect(txt1007Num.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txt1007Num")
    );
    await act(async () => {
      userEvent.type(txt1007Num, "1");
    });
  });
  test("Custom Test Cases for txt1007Num", () => {
    // START_USER_CODE-USER_txt1007Num_TEST
    // END_USER_CODE-USER_txt1007Num_TEST
  });
  test("txtAuditDtFrom(Date Widget) Test Cases", async () => {
    const txtAuditDtFrom = screen.getByTestId("txtAuditDtFrom");
    expect(txtAuditDtFrom.tagName).toBe("INPUT");
    expect(txtAuditDtFrom.type).toBe("text");
    expect(txtAuditDtFrom.classList).toContain("datetimepicker-input");
    expect(
      txtAuditDtFrom.parentElement.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtAuditDtFrom"
      )
    );
    await act(async () => {
      userEvent.click(txtAuditDtFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtAuditDtFrom", () => {
    // START_USER_CODE-USER_txtAuditDtFrom_TEST
    // END_USER_CODE-USER_txtAuditDtFrom_TEST
  });
  test("txtAuditDtTo(Date Widget) Test Cases", async () => {
    const txtAuditDtTo = screen.getByTestId("txtAuditDtTo");
    expect(txtAuditDtTo.tagName).toBe("INPUT");
    expect(txtAuditDtTo.type).toBe("text");
    expect(txtAuditDtTo.classList).toContain("datetimepicker-input");
    expect(
      txtAuditDtTo.parentElement.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtAuditDtTo"
      )
    );
    await act(async () => {
      userEvent.click(txtAuditDtTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtAuditDtTo", () => {
    // START_USER_CODE-USER_txtAuditDtTo_TEST
    // END_USER_CODE-USER_txtAuditDtTo_TEST
  });
  test("txtAuditUserNum(Textbox Widget) Test Cases", async () => {
    const txtAuditUserNum = screen.getByTestId("txtAuditUserNum");
    expect(txtAuditUserNum.tagName).toBe("INPUT");
    expect(txtAuditUserNum.type).toBe("text");
    expect(txtAuditUserNum.classList).toContain("textboxWidgetClass");
    expect(txtAuditUserNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtAuditUserNum"
      )
    );
    await act(async () => {
      userEvent.type(txtAuditUserNum, "1");
    });
  });
  test("Custom Test Cases for txtAuditUserNum", () => {
    // START_USER_CODE-USER_txtAuditUserNum_TEST
    // END_USER_CODE-USER_txtAuditUserNum_TEST
  });
  test("gridAuditHistory_txtcolAction(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolAction = screen.getByTestId("gridAuditHistory");
    let gridAuditHistory_txtcolActionbtn =
      gridAuditHistory_txtcolAction.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolAction =
      gridAuditHistory_txtcolAction.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolAction.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolAction.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAction", () => {
    // START_USER_CODE-USER_txtcolAction_TEST
    // END_USER_CODE-USER_txtcolAction_TEST
  });
  test("gridAuditHistory_txtcolAdjustmentSource(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolAdjustmentSource = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolAdjustmentSourcebtn =
      gridAuditHistory_txtcolAdjustmentSource.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolAdjustmentSource =
      gridAuditHistory_txtcolAdjustmentSource.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolAdjustmentSource.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolAdjustmentSource.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAdjustmentSource", () => {
    // START_USER_CODE-USER_txtcolAdjustmentSource_TEST
    // END_USER_CODE-USER_txtcolAdjustmentSource_TEST
  });
  test("gridAuditHistory_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolBuyPt = screen.getByTestId("gridAuditHistory");
    let gridAuditHistory_txtcolBuyPtbtn =
      gridAuditHistory_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolBuyPt =
      gridAuditHistory_txtcolBuyPt.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolBuyPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridAuditHistory_txtcolChgMainComment(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolChgMainComment = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolChgMainCommentbtn =
      gridAuditHistory_txtcolChgMainComment.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolChgMainComment =
      gridAuditHistory_txtcolChgMainComment.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolChgMainComment.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolChgMainComment.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChgMainComment", () => {
    // START_USER_CODE-USER_txtcolChgMainComment_TEST
    // END_USER_CODE-USER_txtcolChgMainComment_TEST
  });
  test("gridAuditHistory_txtcolChgMaintDate(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolChgMaintDate = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolChgMaintDatebtn =
      gridAuditHistory_txtcolChgMaintDate.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolChgMaintDate =
      gridAuditHistory_txtcolChgMaintDate.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolChgMaintDate.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolChgMaintDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChgMaintDate", () => {
    // START_USER_CODE-USER_txtcolChgMaintDate_TEST
    // END_USER_CODE-USER_txtcolChgMaintDate_TEST
  });
  test("gridAuditHistory_txtcolChgMaintUser(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolChgMaintUser = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolChgMaintUserbtn =
      gridAuditHistory_txtcolChgMaintUser.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolChgMaintUser =
      gridAuditHistory_txtcolChgMaintUser.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolChgMaintUser.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolChgMaintUser.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChgMaintUser", () => {
    // START_USER_CODE-USER_txtcolChgMaintUser_TEST
    // END_USER_CODE-USER_txtcolChgMaintUser_TEST
  });
  test("gridAuditHistory_txtcolNewCompOwnedAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewCompOwnedAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewCompOwnedAdjbtn =
      gridAuditHistory_txtcolNewCompOwnedAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolNewCompOwnedAdj =
      gridAuditHistory_txtcolNewCompOwnedAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewCompOwnedAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewCompOwnedAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewCompOwnedAdj", () => {
    // START_USER_CODE-USER_txtcolNewCompOwnedAdj_TEST
    // END_USER_CODE-USER_txtcolNewCompOwnedAdj_TEST
  });
  test("gridAuditHistory_txtcolNewIntransitAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewIntransitAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewIntransitAdjbtn =
      gridAuditHistory_txtcolNewIntransitAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolNewIntransitAdj =
      gridAuditHistory_txtcolNewIntransitAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewIntransitAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewIntransitAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewIntransitAdj", () => {
    // START_USER_CODE-USER_txtcolNewIntransitAdj_TEST
    // END_USER_CODE-USER_txtcolNewIntransitAdj_TEST
  });
  test("gridAuditHistory_txtcolNewNotRcptdAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewNotRcptdAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewNotRcptdAdjbtn =
      gridAuditHistory_txtcolNewNotRcptdAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolNewNotRcptdAdj =
      gridAuditHistory_txtcolNewNotRcptdAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewNotRcptdAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewNotRcptdAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewNotRcptdAdj", () => {
    // START_USER_CODE-USER_txtcolNewNotRcptdAdj_TEST
    // END_USER_CODE-USER_txtcolNewNotRcptdAdj_TEST
  });
  test("gridAuditHistory_txtcolNewPnutOleic(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPnutOleic = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPnutOleicbtn =
      gridAuditHistory_txtcolNewPnutOleic.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewPnutOleic =
      gridAuditHistory_txtcolNewPnutOleic.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPnutOleic.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPnutOleic.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPnutOleic", () => {
    // START_USER_CODE-USER_txtcolNewPnutOleic_TEST
    // END_USER_CODE-USER_txtcolNewPnutOleic_TEST
  });
  test("gridAuditHistory_txtcolNewPnutOrganic(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPnutOrganic = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPnutOrganicbtn =
      gridAuditHistory_txtcolNewPnutOrganic.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolNewPnutOrganic =
      gridAuditHistory_txtcolNewPnutOrganic.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPnutOrganic.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPnutOrganic.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPnutOrganic", () => {
    // START_USER_CODE-USER_txtcolNewPnutOrganic_TEST
    // END_USER_CODE-USER_txtcolNewPnutOrganic_TEST
  });
  test("gridAuditHistory_txtcolNewPnutSeed(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPnutSeed = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPnutSeedbtn =
      gridAuditHistory_txtcolNewPnutSeed.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewPnutSeed =
      gridAuditHistory_txtcolNewPnutSeed.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPnutSeed.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPnutSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPnutSeed", () => {
    // START_USER_CODE-USER_txtcolNewPnutSeed_TEST
    // END_USER_CODE-USER_txtcolNewPnutSeed_TEST
  });
  test("gridAuditHistory_txtcolNewPnutSeg(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPnutSeg = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPnutSegbtn =
      gridAuditHistory_txtcolNewPnutSeg.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewPnutSeg =
      gridAuditHistory_txtcolNewPnutSeg.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPnutSeg.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPnutSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPnutSeg", () => {
    // START_USER_CODE-USER_txtcolNewPnutSeg_TEST
    // END_USER_CODE-USER_txtcolNewPnutSeg_TEST
  });
  test("gridAuditHistory_txtcolNewPnutType(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPnutType = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPnutTypebtn =
      gridAuditHistory_txtcolNewPnutType.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewPnutType =
      gridAuditHistory_txtcolNewPnutType.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPnutType.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPnutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPnutType", () => {
    // START_USER_CODE-USER_txtcolNewPnutType_TEST
    // END_USER_CODE-USER_txtcolNewPnutType_TEST
  });
  test("gridAuditHistory_txtcolNewPnutVariety(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPnutVariety = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPnutVarietybtn =
      gridAuditHistory_txtcolNewPnutVariety.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolNewPnutVariety =
      gridAuditHistory_txtcolNewPnutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPnutVariety.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPnutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPnutVariety", () => {
    // START_USER_CODE-USER_txtcolNewPnutVariety_TEST
    // END_USER_CODE-USER_txtcolNewPnutVariety_TEST
  });
  test("gridAuditHistory_txtcolNewPostingDt(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewPostingDt = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewPostingDtbtn =
      gridAuditHistory_txtcolNewPostingDt.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewPostingDt =
      gridAuditHistory_txtcolNewPostingDt.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewPostingDt.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewPostingDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewPostingDt", () => {
    // START_USER_CODE-USER_txtcolNewPostingDt_TEST
    // END_USER_CODE-USER_txtcolNewPostingDt_TEST
  });
  test("gridAuditHistory_txtcolNewReceiptAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewReceiptAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewReceiptAdjbtn =
      gridAuditHistory_txtcolNewReceiptAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewReceiptAdj =
      gridAuditHistory_txtcolNewReceiptAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewReceiptAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewReceiptAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewReceiptAdj", () => {
    // START_USER_CODE-USER_txtcolNewReceiptAdj_TEST
    // END_USER_CODE-USER_txtcolNewReceiptAdj_TEST
  });
  test("gridAuditHistory_txtcolNewShrinkAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewShrinkAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewShrinkAdjbtn =
      gridAuditHistory_txtcolNewShrinkAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewShrinkAdj =
      gridAuditHistory_txtcolNewShrinkAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewShrinkAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewShrinkAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewShrinkAdj", () => {
    // START_USER_CODE-USER_txtcolNewShrinkAdj_TEST
    // END_USER_CODE-USER_txtcolNewShrinkAdj_TEST
  });
  test("gridAuditHistory_txtcolNewUndeterminedAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewUndeterminedAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewUndeterminedAdjbtn =
      gridAuditHistory_txtcolNewUndeterminedAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolNewUndeterminedAdj =
      gridAuditHistory_txtcolNewUndeterminedAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewUndeterminedAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewUndeterminedAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewUndeterminedAdj", () => {
    // START_USER_CODE-USER_txtcolNewUndeterminedAdj_TEST
    // END_USER_CODE-USER_txtcolNewUndeterminedAdj_TEST
  });
  test("gridAuditHistory_txtcolNewWhsBin(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNewWhsBin = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNewWhsBinbtn =
      gridAuditHistory_txtcolNewWhsBin.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNewWhsBin =
      gridAuditHistory_txtcolNewWhsBin.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNewWhsBin.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNewWhsBin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewWhsBin", () => {
    // START_USER_CODE-USER_txtcolNewWhsBin_TEST
    // END_USER_CODE-USER_txtcolNewWhsBin_TEST
  });
  test("gridAuditHistory_txtcolOldCompOwnedAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldCompOwnedAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldCompOwnedAdjbtn =
      gridAuditHistory_txtcolOldCompOwnedAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldCompOwnedAdj =
      gridAuditHistory_txtcolOldCompOwnedAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldCompOwnedAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldCompOwnedAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldCompOwnedAdj", () => {
    // START_USER_CODE-USER_txtcolOldCompOwnedAdj_TEST
    // END_USER_CODE-USER_txtcolOldCompOwnedAdj_TEST
  });
  test("gridAuditHistory_txtcolOldIntransitAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldIntransitAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldIntransitAdjbtn =
      gridAuditHistory_txtcolOldIntransitAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldIntransitAdj =
      gridAuditHistory_txtcolOldIntransitAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldIntransitAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldIntransitAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldIntransitAdj", () => {
    // START_USER_CODE-USER_txtcolOldIntransitAdj_TEST
    // END_USER_CODE-USER_txtcolOldIntransitAdj_TEST
  });
  test("gridAuditHistory_txtcolOldNotRcptdAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldNotRcptdAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldNotRcptdAdjbtn =
      gridAuditHistory_txtcolOldNotRcptdAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldNotRcptdAdj =
      gridAuditHistory_txtcolOldNotRcptdAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldNotRcptdAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldNotRcptdAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldNotRcptdAdj", () => {
    // START_USER_CODE-USER_txtcolOldNotRcptdAdj_TEST
    // END_USER_CODE-USER_txtcolOldNotRcptdAdj_TEST
  });
  test("gridAuditHistory_txtcolOldPhysicalAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPhysicalAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPhysicalAdjbtn =
      gridAuditHistory_txtcolOldPhysicalAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldPhysicalAdj =
      gridAuditHistory_txtcolOldPhysicalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPhysicalAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPhysicalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPhysicalAdj", () => {
    // START_USER_CODE-USER_txtcolOldPhysicalAdj_TEST
    // END_USER_CODE-USER_txtcolOldPhysicalAdj_TEST
  });
  test("gridAuditHistory_txtcolOldPnutOleic(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPnutOleic = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPnutOleicbtn =
      gridAuditHistory_txtcolOldPnutOleic.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldPnutOleic =
      gridAuditHistory_txtcolOldPnutOleic.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPnutOleic.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPnutOleic.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPnutOleic", () => {
    // START_USER_CODE-USER_txtcolOldPnutOleic_TEST
    // END_USER_CODE-USER_txtcolOldPnutOleic_TEST
  });
  test("gridAuditHistory_txtcolOldPnutOrganic(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPnutOrganic = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPnutOrganicbtn =
      gridAuditHistory_txtcolOldPnutOrganic.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldPnutOrganic =
      gridAuditHistory_txtcolOldPnutOrganic.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPnutOrganic.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPnutOrganic.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPnutOrganic", () => {
    // START_USER_CODE-USER_txtcolOldPnutOrganic_TEST
    // END_USER_CODE-USER_txtcolOldPnutOrganic_TEST
  });
  test("gridAuditHistory_txtcolOldPnutSeed(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPnutSeed = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPnutSeedbtn =
      gridAuditHistory_txtcolOldPnutSeed.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldPnutSeed =
      gridAuditHistory_txtcolOldPnutSeed.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPnutSeed.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPnutSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPnutSeed", () => {
    // START_USER_CODE-USER_txtcolOldPnutSeed_TEST
    // END_USER_CODE-USER_txtcolOldPnutSeed_TEST
  });
  test("gridAuditHistory_txtcolOldPnutSeg(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPnutSeg = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPnutSegbtn =
      gridAuditHistory_txtcolOldPnutSeg.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldPnutSeg =
      gridAuditHistory_txtcolOldPnutSeg.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPnutSeg.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPnutSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPnutSeg", () => {
    // START_USER_CODE-USER_txtcolOldPnutSeg_TEST
    // END_USER_CODE-USER_txtcolOldPnutSeg_TEST
  });
  test("gridAuditHistory_txtcolOldPnutType(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPnutType = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPnutTypebtn =
      gridAuditHistory_txtcolOldPnutType.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldPnutType =
      gridAuditHistory_txtcolOldPnutType.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPnutType.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPnutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPnutType", () => {
    // START_USER_CODE-USER_txtcolOldPnutType_TEST
    // END_USER_CODE-USER_txtcolOldPnutType_TEST
  });
  test("gridAuditHistory_txtcolOldPnutVariety(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPnutVariety = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPnutVarietybtn =
      gridAuditHistory_txtcolOldPnutVariety.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldPnutVariety =
      gridAuditHistory_txtcolOldPnutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPnutVariety.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPnutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPnutVariety", () => {
    // START_USER_CODE-USER_txtcolOldPnutVariety_TEST
    // END_USER_CODE-USER_txtcolOldPnutVariety_TEST
  });
  test("gridAuditHistory_txtcolOldPostingDt(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldPostingDt = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldPostingDtbtn =
      gridAuditHistory_txtcolOldPostingDt.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldPostingDt =
      gridAuditHistory_txtcolOldPostingDt.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldPostingDt.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldPostingDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldPostingDt", () => {
    // START_USER_CODE-USER_txtcolOldPostingDt_TEST
    // END_USER_CODE-USER_txtcolOldPostingDt_TEST
  });
  test("gridAuditHistory_txtcolOldShrinkAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldShrinkAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldShrinkAdjbtn =
      gridAuditHistory_txtcolOldShrinkAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldShrinkAdj =
      gridAuditHistory_txtcolOldShrinkAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldShrinkAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldShrinkAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldShrinkAdj", () => {
    // START_USER_CODE-USER_txtcolOldShrinkAdj_TEST
    // END_USER_CODE-USER_txtcolOldShrinkAdj_TEST
  });
  test("gridAuditHistory_txtcolOldUndetrminedAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldUndetrminedAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldUndetrminedAdjbtn =
      gridAuditHistory_txtcolOldUndetrminedAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolOldUndetrminedAdj =
      gridAuditHistory_txtcolOldUndetrminedAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldUndetrminedAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldUndetrminedAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldUndetrminedAdj", () => {
    // START_USER_CODE-USER_txtcolOldUndetrminedAdj_TEST
    // END_USER_CODE-USER_txtcolOldUndetrminedAdj_TEST
  });
  test("gridAuditHistory_txtcolOldWhsBin(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldWhsBin = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldWhsBinbtn =
      gridAuditHistory_txtcolOldWhsBin.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldWhsBin =
      gridAuditHistory_txtcolOldWhsBin.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldWhsBin.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldWhsBin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldWhsBin", () => {
    // START_USER_CODE-USER_txtcolOldWhsBin_TEST
    // END_USER_CODE-USER_txtcolOldWhsBin_TEST
  });
  test("gridAuditHistory_txtcolOldWhsRcptAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolOldWhsRcptAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolOldWhsRcptAdjbtn =
      gridAuditHistory_txtcolOldWhsRcptAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolOldWhsRcptAdj =
      gridAuditHistory_txtcolOldWhsRcptAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolOldWhsRcptAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolOldWhsRcptAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldWhsRcptAdj", () => {
    // START_USER_CODE-USER_txtcolOldWhsRcptAdj_TEST
    // END_USER_CODE-USER_txtcolOldWhsRcptAdj_TEST
  });
  test("gridAuditHistory_txtcolRecordID(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolRecordID = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolRecordIDbtn =
      gridAuditHistory_txtcolRecordID.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolRecordID =
      gridAuditHistory_txtcolRecordID.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolRecordID.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolRecordID.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecordID", () => {
    // START_USER_CODE-USER_txtcolRecordID_TEST
    // END_USER_CODE-USER_txtcolRecordID_TEST
  });
  test("txtSC95Num(Textbox Widget) Test Cases", async () => {
    const txtSC95Num = screen.getByTestId("txtSC95Num");
    expect(txtSC95Num.tagName).toBe("INPUT");
    expect(txtSC95Num.type).toBe("text");
    expect(txtSC95Num.classList).toContain("textboxWidgetClass");
    expect(txtSC95Num.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtSC95Num")
    );
    await act(async () => {
      userEvent.type(txtSC95Num, "1");
    });
  });
  test("Custom Test Cases for txtSC95Num", () => {
    // START_USER_CODE-USER_txtSC95Num_TEST
    // END_USER_CODE-USER_txtSC95Num_TEST
  });
  test("txtTradeInspNum(Textbox Widget) Test Cases", async () => {
    const txtTradeInspNum = screen.getByTestId("txtTradeInspNum");
    expect(txtTradeInspNum.tagName).toBe("INPUT");
    expect(txtTradeInspNum.type).toBe("text");
    expect(txtTradeInspNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtTradeInspNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeInspNum, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspNum", () => {
    // START_USER_CODE-USER_txtTradeInspNum_TEST
    // END_USER_CODE-USER_txtTradeInspNum_TEST
  });
  test("txtTradeSettleNum(Textbox Widget) Test Cases", async () => {
    const txtTradeSettleNum = screen.getByTestId("txtTradeSettleNum");
    expect(txtTradeSettleNum.tagName).toBe("INPUT");
    expect(txtTradeSettleNum.type).toBe("text");
    expect(txtTradeSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettleNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtTradeSettleNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeSettleNum, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettleNum", () => {
    // START_USER_CODE-USER_txtTradeSettleNum_TEST
    // END_USER_CODE-USER_txtTradeSettleNum_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtTransferNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtWhseRcptNum(Textbox Widget) Test Cases", async () => {
    const txtWhseRcptNum = screen.getByTestId("txtWhseRcptNum");
    expect(txtWhseRcptNum.tagName).toBe("INPUT");
    expect(txtWhseRcptNum.type).toBe("text");
    expect(txtWhseRcptNum.classList).toContain("textboxWidgetClass");
    expect(txtWhseRcptNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistSearch_txtWhseRcptNum"
      )
    );
    await act(async () => {
      userEvent.type(txtWhseRcptNum, "1");
    });
  });
  test("Custom Test Cases for txtWhseRcptNum", () => {
    // START_USER_CODE-USER_txtWhseRcptNum_TEST
    // END_USER_CODE-USER_txtWhseRcptNum_TEST
  });
});
