/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvAgreeSearch from "./DelvAgreeSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvAgreeSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvAgreeSearch />);
    });
  });
  afterEach(cleanup);
  test("is DelvAgreeSearch Loads Successfully", () => {
    expect(screen.getByText("DelvAgreeSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvAgreeSearch", () => {
    // START_USER_CODE-USER_DelvAgreeSearch_Custom_Test_Case
    // END_USER_CODE-USER_DelvAgreeSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvAgreeSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateAgreement(Button Widget) Test Cases", async () => {
    const btnCreateAgreement = screen.getByTestId("btnCreateAgreement");
    expect(btnCreateAgreement).toBeInTheDocument;
    expect(btnCreateAgreement.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnCreateAgreement")
    );
  });
  test("Custom Test Cases for btnCreateAgreement", () => {
    // START_USER_CODE-USER_btnCreateAgreement_TEST
    // END_USER_CODE-USER_btnCreateAgreement_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridDelvAgreeSearch(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch = screen.getByTestId("gridDelvAgreeSearch");
    let gridDelvAgreeSearchbtn =
      gridDelvAgreeSearch.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch =
      gridDelvAgreeSearch.parentElement.parentElement.parentElement;
    expect(gridDelvAgreeSearch.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridDelvAgreeSearch", () => {
    // START_USER_CODE-USER_gridDelvAgreeSearch_TEST
    // END_USER_CODE-USER_gridDelvAgreeSearch_TEST
  });
  test("grpbxDelvAgreeSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvAgreeSearch = screen.getByTestId("grpbxDelvAgreeSearch");
    expect(grpbxDelvAgreeSearch.tagName).toBe("BUTTON");
    expect(grpbxDelvAgreeSearch.type).toBe("button");
    expect(grpbxDelvAgreeSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvAgreeSearch", () => {
    // START_USER_CODE-USER_grpbxDelvAgreeSearch_TEST
    // END_USER_CODE-USER_grpbxDelvAgreeSearch_TEST
  });
  test("grpbxDelvAgreeSearch2(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvAgreeSearch2 = screen.getByTestId("grpbxDelvAgreeSearch2");
    expect(grpbxDelvAgreeSearch2.tagName).toBe("BUTTON");
    expect(grpbxDelvAgreeSearch2.type).toBe("button");
    expect(grpbxDelvAgreeSearch2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvAgreeSearch2", () => {
    // START_USER_CODE-USER_grpbxDelvAgreeSearch2_TEST
    // END_USER_CODE-USER_grpbxDelvAgreeSearch2_TEST
  });
  test("grpbxDelvSearch3(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSearch3 = screen.getByTestId("grpbxDelvSearch3");
    expect(grpbxDelvSearch3.tagName).toBe("BUTTON");
    expect(grpbxDelvSearch3.type).toBe("button");
    expect(grpbxDelvSearch3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSearch3", () => {
    // START_USER_CODE-USER_grpbxDelvSearch3_TEST
    // END_USER_CODE-USER_grpbxDelvSearch3_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("txt100AdjustmentLbs(Textbox Widget) Test Cases", async () => {
    const txt100AdjustmentLbs = screen.getByTestId("txt100AdjustmentLbs");
    expect(txt100AdjustmentLbs.tagName).toBe("INPUT");
    expect(txt100AdjustmentLbs.type).toBe("text");
    expect(txt100AdjustmentLbs.classList).toContain("textboxWidgetClass");
    expect(txt100AdjustmentLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txt100AdjustmentLbs")
    );
    await act(async () => {
      userEvent.type(txt100AdjustmentLbs, "123");
    });
    expect(txt100AdjustmentLbs.getAttribute("value")).toBe("");
    expect(txt100AdjustmentLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt100AdjustmentLbs", () => {
    // START_USER_CODE-USER_txt100AdjustmentLbs_TEST
    // END_USER_CODE-USER_txt100AdjustmentLbs_TEST
  });
  test("txtAdjustmentLbs(Textbox Widget) Test Cases", async () => {
    const txtAdjustmentLbs = screen.getByTestId("txtAdjustmentLbs");
    expect(txtAdjustmentLbs.tagName).toBe("INPUT");
    expect(txtAdjustmentLbs.type).toBe("text");
    expect(txtAdjustmentLbs.classList).toContain("textboxWidgetClass");
    expect(txtAdjustmentLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtAdjustmentLbs")
    );
    await act(async () => {
      userEvent.type(txtAdjustmentLbs, "123");
    });
    expect(txtAdjustmentLbs.getAttribute("value")).toBe("");
    expect(txtAdjustmentLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAdjustmentLbs", () => {
    // START_USER_CODE-USER_txtAdjustmentLbs_TEST
    // END_USER_CODE-USER_txtAdjustmentLbs_TEST
  });
  test("txtAgreementNum(Textbox Widget) Test Cases", async () => {
    const txtAgreementNum = screen.getByTestId("txtAgreementNum");
    expect(txtAgreementNum.tagName).toBe("INPUT");
    expect(txtAgreementNum.type).toBe("text");
    expect(txtAgreementNum.classList).toContain("textboxWidgetClass");
    expect(txtAgreementNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtAgreementNum")
    );
    await act(async () => {
      userEvent.type(txtAgreementNum, "1");
    });
  });
  test("Custom Test Cases for txtAgreementNum", () => {
    // START_USER_CODE-USER_txtAgreementNum_TEST
    // END_USER_CODE-USER_txtAgreementNum_TEST
  });
  test("txtAppliedLbs(Textbox Widget) Test Cases", async () => {
    const txtAppliedLbs = screen.getByTestId("txtAppliedLbs");
    expect(txtAppliedLbs.tagName).toBe("INPUT");
    expect(txtAppliedLbs.type).toBe("text");
    expect(txtAppliedLbs.classList).toContain("textboxWidgetClass");
    expect(txtAppliedLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtAppliedLbs")
    );
    await act(async () => {
      userEvent.type(txtAppliedLbs, "123");
    });
    expect(txtAppliedLbs.getAttribute("value")).toBe("");
    expect(txtAppliedLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAppliedLbs", () => {
    // START_USER_CODE-USER_txtAppliedLbs_TEST
    // END_USER_CODE-USER_txtAppliedLbs_TEST
  });
  test("gridDelvAgreeSearch_txtcol100Adjustment(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcol100Adjustment = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcol100Adjustmentbtn =
      gridDelvAgreeSearch_txtcol100Adjustment.nextElementSibling
        .firstElementChild;
    gridDelvAgreeSearch_txtcol100Adjustment =
      gridDelvAgreeSearch_txtcol100Adjustment.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcol100Adjustment.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcol100Adjustment.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100Adjustment", () => {
    // START_USER_CODE-USER_txtcol100Adjustment_TEST
    // END_USER_CODE-USER_txtcol100Adjustment_TEST
  });
  test("gridDelvAgreeSearch_txtcol100AdjustmentDoller(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcol100AdjustmentDoller = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcol100AdjustmentDollerbtn =
      gridDelvAgreeSearch_txtcol100AdjustmentDoller.nextElementSibling
        .firstElementChild;
    gridDelvAgreeSearch_txtcol100AdjustmentDoller =
      gridDelvAgreeSearch_txtcol100AdjustmentDoller.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcol100AdjustmentDoller.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcol100AdjustmentDoller.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100AdjustmentDoller", () => {
    // START_USER_CODE-USER_txtcol100AdjustmentDoller_TEST
    // END_USER_CODE-USER_txtcol100AdjustmentDoller_TEST
  });
  test("gridDelvAgreeSearch_txtcol100Applied(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcol100Applied = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcol100Appliedbtn =
      gridDelvAgreeSearch_txtcol100Applied.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcol100Applied =
      gridDelvAgreeSearch_txtcol100Applied.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcol100Applied.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcol100Applied.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100Applied", () => {
    // START_USER_CODE-USER_txtcol100Applied_TEST
    // END_USER_CODE-USER_txtcol100Applied_TEST
  });
  test("gridDelvAgreeSearch_txtcol100Open(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcol100Open = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcol100Openbtn =
      gridDelvAgreeSearch_txtcol100Open.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcol100Open =
      gridDelvAgreeSearch_txtcol100Open.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcol100Open.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcol100Open.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100Open", () => {
    // START_USER_CODE-USER_txtcol100Open_TEST
    // END_USER_CODE-USER_txtcol100Open_TEST
  });
  test("gridDelvAgreeSearch_txtcolAdjustmentlbs(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolAdjustmentlbs = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolAdjustmentlbsbtn =
      gridDelvAgreeSearch_txtcolAdjustmentlbs.nextElementSibling
        .firstElementChild;
    gridDelvAgreeSearch_txtcolAdjustmentlbs =
      gridDelvAgreeSearch_txtcolAdjustmentlbs.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolAdjustmentlbs.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolAdjustmentlbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAdjustmentlbs", () => {
    // START_USER_CODE-USER_txtcolAdjustmentlbs_TEST
    // END_USER_CODE-USER_txtcolAdjustmentlbs_TEST
  });
  test("gridDelvAgreeSearch_txtcolAgree(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolAgree = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolAgreebtn =
      gridDelvAgreeSearch_txtcolAgree.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolAgree =
      gridDelvAgreeSearch_txtcolAgree.parentElement.parentElement.parentElement;
    expect(gridDelvAgreeSearch_txtcolAgree.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolAgree.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgree", () => {
    // START_USER_CODE-USER_txtcolAgree_TEST
    // END_USER_CODE-USER_txtcolAgree_TEST
  });
  test("gridDelvAgreeSearch_txtcolAgreeDate(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolAgreeDate = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolAgreeDatebtn =
      gridDelvAgreeSearch_txtcolAgreeDate.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolAgreeDate =
      gridDelvAgreeSearch_txtcolAgreeDate.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolAgreeDate.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolAgreeDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeDate", () => {
    // START_USER_CODE-USER_txtcolAgreeDate_TEST
    // END_USER_CODE-USER_txtcolAgreeDate_TEST
  });
  test("gridDelvAgreeSearch_txtcolAppliedLbs(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolAppliedLbs = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolAppliedLbsbtn =
      gridDelvAgreeSearch_txtcolAppliedLbs.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolAppliedLbs =
      gridDelvAgreeSearch_txtcolAppliedLbs.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolAppliedLbs.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolAppliedLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedLbs", () => {
    // START_USER_CODE-USER_txtcolAppliedLbs_TEST
    // END_USER_CODE-USER_txtcolAppliedLbs_TEST
  });
  test("gridDelvAgreeSearch_txtcolConversion(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolConversion = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolConversionbtn =
      gridDelvAgreeSearch_txtcolConversion.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolConversion =
      gridDelvAgreeSearch_txtcolConversion.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolConversion.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolConversion.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolConversion", () => {
    // START_USER_CODE-USER_txtcolConversion_TEST
    // END_USER_CODE-USER_txtcolConversion_TEST
  });
  test("gridDelvAgreeSearch_txtcolDelvInOut(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolDelvInOut = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolDelvInOutbtn =
      gridDelvAgreeSearch_txtcolDelvInOut.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolDelvInOut =
      gridDelvAgreeSearch_txtcolDelvInOut.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolDelvInOut.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolDelvInOut.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvInOut", () => {
    // START_USER_CODE-USER_txtcolDelvInOut_TEST
    // END_USER_CODE-USER_txtcolDelvInOut_TEST
  });
  test("gridDelvAgreeSearch_txtcolLoc(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolLoc = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolLocbtn =
      gridDelvAgreeSearch_txtcolLoc.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolLoc =
      gridDelvAgreeSearch_txtcolLoc.parentElement.parentElement.parentElement;
    expect(gridDelvAgreeSearch_txtcolLoc.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoc", () => {
    // START_USER_CODE-USER_txtcolLoc_TEST
    // END_USER_CODE-USER_txtcolLoc_TEST
  });
  test("gridDelvAgreeSearch_txtcolOpenlb(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolOpenlb = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolOpenlbbtn =
      gridDelvAgreeSearch_txtcolOpenlb.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolOpenlb =
      gridDelvAgreeSearch_txtcolOpenlb.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolOpenlb.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolOpenlb.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenlb", () => {
    // START_USER_CODE-USER_txtcolOpenlb_TEST
    // END_USER_CODE-USER_txtcolOpenlb_TEST
  });
  test("gridDelvAgreeSearch_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolPrice = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolPricebtn =
      gridDelvAgreeSearch_txtcolPrice.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolPrice =
      gridDelvAgreeSearch_txtcolPrice.parentElement.parentElement.parentElement;
    expect(gridDelvAgreeSearch_txtcolPrice.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolPrice.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridDelvAgreeSearch_txtcolPriceMethod(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolPriceMethod = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolPriceMethodbtn =
      gridDelvAgreeSearch_txtcolPriceMethod.nextElementSibling
        .firstElementChild;
    gridDelvAgreeSearch_txtcolPriceMethod =
      gridDelvAgreeSearch_txtcolPriceMethod.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolPriceMethod.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolPriceMethod.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceMethod", () => {
    // START_USER_CODE-USER_txtcolPriceMethod_TEST
    // END_USER_CODE-USER_txtcolPriceMethod_TEST
  });
  test("gridDelvAgreeSearch_txtcolReceiptLbs(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolReceiptLbs = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolReceiptLbsbtn =
      gridDelvAgreeSearch_txtcolReceiptLbs.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolReceiptLbs =
      gridDelvAgreeSearch_txtcolReceiptLbs.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolReceiptLbs.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolReceiptLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptLbs", () => {
    // START_USER_CODE-USER_txtcolReceiptLbs_TEST
    // END_USER_CODE-USER_txtcolReceiptLbs_TEST
  });
  test("gridDelvAgreeSearch_txtcolReceiptValue(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolReceiptValue = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolReceiptValuebtn =
      gridDelvAgreeSearch_txtcolReceiptValue.nextElementSibling
        .firstElementChild;
    gridDelvAgreeSearch_txtcolReceiptValue =
      gridDelvAgreeSearch_txtcolReceiptValue.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolReceiptValue.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolReceiptValue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptValue", () => {
    // START_USER_CODE-USER_txtcolReceiptValue_TEST
    // END_USER_CODE-USER_txtcolReceiptValue_TEST
  });
  test("gridDelvAgreeSearch_txtcolSeedGen(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolSeedGen = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolSeedGenbtn =
      gridDelvAgreeSearch_txtcolSeedGen.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolSeedGen =
      gridDelvAgreeSearch_txtcolSeedGen.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolSeedGen.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolSeedGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGen", () => {
    // START_USER_CODE-USER_txtcolSeedGen_TEST
    // END_USER_CODE-USER_txtcolSeedGen_TEST
  });
  test("gridDelvAgreeSearch_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolSeg = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolSegbtn =
      gridDelvAgreeSearch_txtcolSeg.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolSeg =
      gridDelvAgreeSearch_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridDelvAgreeSearch_txtcolSeg.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridDelvAgreeSearch_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolStatus = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolStatusbtn =
      gridDelvAgreeSearch_txtcolStatus.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolStatus =
      gridDelvAgreeSearch_txtcolStatus.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolStatus.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridDelvAgreeSearch_txtcolTotalAdjustedlbs(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolTotalAdjustedlbs = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolTotalAdjustedlbsbtn =
      gridDelvAgreeSearch_txtcolTotalAdjustedlbs.nextElementSibling
        .firstElementChild;
    gridDelvAgreeSearch_txtcolTotalAdjustedlbs =
      gridDelvAgreeSearch_txtcolTotalAdjustedlbs.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolTotalAdjustedlbs.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolTotalAdjustedlbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalAdjustedlbs", () => {
    // START_USER_CODE-USER_txtcolTotalAdjustedlbs_TEST
    // END_USER_CODE-USER_txtcolTotalAdjustedlbs_TEST
  });
  test("gridDelvAgreeSearch_txtcolType(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolType = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolTypebtn =
      gridDelvAgreeSearch_txtcolType.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolType =
      gridDelvAgreeSearch_txtcolType.parentElement.parentElement.parentElement;
    expect(gridDelvAgreeSearch_txtcolType.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType", () => {
    // START_USER_CODE-USER_txtcolType_TEST
    // END_USER_CODE-USER_txtcolType_TEST
  });
  test("gridDelvAgreeSearch_txtcolVariety(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolVariety = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolVarietybtn =
      gridDelvAgreeSearch_txtcolVariety.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolVariety =
      gridDelvAgreeSearch_txtcolVariety.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolVariety.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolVariety.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVariety", () => {
    // START_USER_CODE-USER_txtcolVariety_TEST
    // END_USER_CODE-USER_txtcolVariety_TEST
  });
  test("gridDelvAgreeSearch_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridDelvAgreeSearch_txtcolVendor = screen.getByTestId(
      "gridDelvAgreeSearch"
    );
    let gridDelvAgreeSearch_txtcolVendorbtn =
      gridDelvAgreeSearch_txtcolVendor.nextElementSibling.firstElementChild;
    gridDelvAgreeSearch_txtcolVendor =
      gridDelvAgreeSearch_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridDelvAgreeSearch_txtcolVendor.tagName).toBe("DIV");
    expect(gridDelvAgreeSearch_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeSearch_gridDelvAgreeSearch")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("txtOpenLbs(Textbox Widget) Test Cases", async () => {
    const txtOpenLbs = screen.getByTestId("txtOpenLbs");
    expect(txtOpenLbs.tagName).toBe("INPUT");
    expect(txtOpenLbs.type).toBe("text");
    expect(txtOpenLbs.classList).toContain("textboxWidgetClass");
    expect(txtOpenLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtOpenLbs")
    );
    await act(async () => {
      userEvent.type(txtOpenLbs, "123");
    });
    expect(txtOpenLbs.getAttribute("value")).toBe("");
    expect(txtOpenLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOpenLbs", () => {
    // START_USER_CODE-USER_txtOpenLbs_TEST
    // END_USER_CODE-USER_txtOpenLbs_TEST
  });
  test("txtReceiptLbs(Textbox Widget) Test Cases", async () => {
    const txtReceiptLbs = screen.getByTestId("txtReceiptLbs");
    expect(txtReceiptLbs.tagName).toBe("INPUT");
    expect(txtReceiptLbs.type).toBe("text");
    expect(txtReceiptLbs.classList).toContain("textboxWidgetClass");
    expect(txtReceiptLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtReceiptLbs")
    );
    await act(async () => {
      userEvent.type(txtReceiptLbs, "123");
    });
    expect(txtReceiptLbs.getAttribute("value")).toBe("");
    expect(txtReceiptLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtReceiptLbs", () => {
    // START_USER_CODE-USER_txtReceiptLbs_TEST
    // END_USER_CODE-USER_txtReceiptLbs_TEST
  });
  test("txtTotalAdjustedLbs(Textbox Widget) Test Cases", async () => {
    const txtTotalAdjustedLbs = screen.getByTestId("txtTotalAdjustedLbs");
    expect(txtTotalAdjustedLbs.tagName).toBe("INPUT");
    expect(txtTotalAdjustedLbs.type).toBe("text");
    expect(txtTotalAdjustedLbs.classList).toContain("textboxWidgetClass");
    expect(txtTotalAdjustedLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtTotalAdjustedLbs")
    );
    await act(async () => {
      userEvent.type(txtTotalAdjustedLbs, "123");
    });
    expect(txtTotalAdjustedLbs.getAttribute("value")).toBe("");
    expect(txtTotalAdjustedLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalAdjustedLbs", () => {
    // START_USER_CODE-USER_txtTotalAdjustedLbs_TEST
    // END_USER_CODE-USER_txtTotalAdjustedLbs_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeSearch_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "123");
    });
    expect(txtVndr.getAttribute("value")).toBe("");
    expect(txtVndr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
