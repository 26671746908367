/* eslint-disable*/
import React from "react";
import Settlements_PremiumDeductions from "./PremiumDeductions";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductions Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductions Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductions")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductions", () => {
    // START_USER_CODE-USER_PremiumDeductions_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductions_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:PremiumDeductions_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridPremiumDeductions_btnCol(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_btnCol = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_btnColbtn =
      gridPremiumDeductions_btnCol.nextElementSibling.firstElementChild;
    gridPremiumDeductions_btnCol =
      gridPremiumDeductions_btnCol.parentElement.parentElement.parentElement;
    expect(gridPremiumDeductions_btnCol.tagName).toBe("DIV");
    expect(gridPremiumDeductions_btnCol.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:PremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btnCol", () => {
    // START_USER_CODE-USER_btnCol_TEST
    // END_USER_CODE-USER_btnCol_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("Settlements:PremiumDeductions_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridPremiumDeductions(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions = screen.getByTestId("gridPremiumDeductions");
    let gridPremiumDeductionsbtn =
      gridPremiumDeductions.nextElementSibling.firstElementChild;
    gridPremiumDeductions =
      gridPremiumDeductions.parentElement.parentElement.parentElement;
    expect(gridPremiumDeductions.tagName).toBe("DIV");
    expect(gridPremiumDeductions.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:PremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremiumDeductions", () => {
    // START_USER_CODE-USER_gridPremiumDeductions_TEST
    // END_USER_CODE-USER_gridPremiumDeductions_TEST
  });
  test("grpbxPremiumDeductions(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductions = screen.getByTestId("grpbxPremiumDeductions");
    expect(grpbxPremiumDeductions.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductions.type).toBe("button");
    expect(grpbxPremiumDeductions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductions", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductions_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductions_TEST
  });
  test("lblPremDeduct(Label Widget) Test Cases", async () => {
    const lblPremDeduct = screen.getByTestId("lblPremDeduct");
    expect(lblPremDeduct.tagName).toBe("LABEL");
    expect(lblPremDeduct.classList).toContain("form-label");
    expect(lblPremDeduct.textContent).toEqual(
      t("Settlements:PremiumDeductions_lblPremDeduct")
    );
  });
  test("Custom Test Cases for lblPremDeduct", () => {
    // START_USER_CODE-USER_lblPremDeduct_TEST
    // END_USER_CODE-USER_lblPremDeduct_TEST
  });
  test("gridPremiumDeductions_txtcolPremDeduct(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcolPremDeduct = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcolPremDeductbtn =
      gridPremiumDeductions_txtcolPremDeduct.nextElementSibling
        .firstElementChild;
    gridPremiumDeductions_txtcolPremDeduct =
      gridPremiumDeductions_txtcolPremDeduct.parentElement.parentElement
        .parentElement;
    expect(gridPremiumDeductions_txtcolPremDeduct.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcolPremDeduct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:PremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPremDeduct", () => {
    // START_USER_CODE-USER_txtcolPremDeduct_TEST
    // END_USER_CODE-USER_txtcolPremDeduct_TEST
  });
  test("gridPremiumDeductions_txtcolPurchaseAmount(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcolPurchaseAmount = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcolPurchaseAmountbtn =
      gridPremiumDeductions_txtcolPurchaseAmount.nextElementSibling
        .firstElementChild;
    gridPremiumDeductions_txtcolPurchaseAmount =
      gridPremiumDeductions_txtcolPurchaseAmount.parentElement.parentElement
        .parentElement;
    expect(gridPremiumDeductions_txtcolPurchaseAmount.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcolPurchaseAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:PremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurchaseAmount", () => {
    // START_USER_CODE-USER_txtcolPurchaseAmount_TEST
    // END_USER_CODE-USER_txtcolPurchaseAmount_TEST
  });
  test("gridPremiumDeductions_txtcolStorageAmount(Grid Widget) Test Cases", async () => {
    let gridPremiumDeductions_txtcolStorageAmount = screen.getByTestId(
      "gridPremiumDeductions"
    );
    let gridPremiumDeductions_txtcolStorageAmountbtn =
      gridPremiumDeductions_txtcolStorageAmount.nextElementSibling
        .firstElementChild;
    gridPremiumDeductions_txtcolStorageAmount =
      gridPremiumDeductions_txtcolStorageAmount.parentElement.parentElement
        .parentElement;
    expect(gridPremiumDeductions_txtcolStorageAmount.tagName).toBe("DIV");
    expect(gridPremiumDeductions_txtcolStorageAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:PremiumDeductions_gridPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStorageAmount", () => {
    // START_USER_CODE-USER_txtcolStorageAmount_TEST
    // END_USER_CODE-USER_txtcolStorageAmount_TEST
  });
});
