/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_CollectionPointProfile from "./CollectionPointProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CollectionPointProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_CollectionPointProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is CollectionPointProfile Loads Successfully", () => {
    expect(screen.getByText("CollectionPointProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for CollectionPointProfile", () => {
    // START_USER_CODE-USER_CollectionPointProfile_Custom_Test_Case
    // END_USER_CODE-USER_CollectionPointProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_CollectionPointProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCollectionpointDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxCollectionpointDetails = screen.getByTestId(
      "grpbxCollectionpointDetails"
    );
    expect(grpbxCollectionpointDetails.tagName).toBe("BUTTON");
    expect(grpbxCollectionpointDetails.type).toBe("button");
    expect(grpbxCollectionpointDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCollectionpointDetails", () => {
    // START_USER_CODE-USER_grpbxCollectionpointDetails_TEST
    // END_USER_CODE-USER_grpbxCollectionpointDetails_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CollectionPointProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblArea(Label Widget) Test Cases", async () => {
    const lblArea = screen.getByTestId("lblArea");
    expect(lblArea.tagName).toBe("LABEL");
    expect(lblArea.classList).toContain("form-label");
    expect(lblArea.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_lblArea")
    );
  });
  test("Custom Test Cases for lblArea", () => {
    // START_USER_CODE-USER_lblArea_TEST
    // END_USER_CODE-USER_lblArea_TEST
  });
  test("lblAreaValue(Label Widget) Test Cases", async () => {
    const lblAreaValue = screen.getByTestId("lblAreaValue");
    expect(lblAreaValue.tagName).toBe("LABEL");
    expect(lblAreaValue.classList).toContain("form-label");
    expect(lblAreaValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_lblAreaValue")
    );
  });
  test("Custom Test Cases for lblAreaValue", () => {
    // START_USER_CODE-USER_lblAreaValue_TEST
    // END_USER_CODE-USER_lblAreaValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CollectionPointProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCompany(Label Widget) Test Cases", async () => {
    const lblCompany = screen.getByTestId("lblCompany");
    expect(lblCompany.tagName).toBe("LABEL");
    expect(lblCompany.classList).toContain("form-label");
    expect(lblCompany.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_lblCompany")
    );
  });
  test("Custom Test Cases for lblCompany", () => {
    // START_USER_CODE-USER_lblCompany_TEST
    // END_USER_CODE-USER_lblCompany_TEST
  });
  test("lblCompanyValue(Label Widget) Test Cases", async () => {
    const lblCompanyValue = screen.getByTestId("lblCompanyValue");
    expect(lblCompanyValue.tagName).toBe("LABEL");
    expect(lblCompanyValue.classList).toContain("form-label");
    expect(lblCompanyValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CollectionPointProfile_lblCompanyValue"
      )
    );
  });
  test("Custom Test Cases for lblCompanyValue", () => {
    // START_USER_CODE-USER_lblCompanyValue_TEST
    // END_USER_CODE-USER_lblCompanyValue_TEST
  });
  test("txtID(Textbox Widget) Test Cases", async () => {
    const txtID = screen.getByTestId("txtID");
    expect(txtID.tagName).toBe("INPUT");
    expect(txtID.type).toBe("text");
    expect(txtID.classList).toContain("textboxWidgetClass");
    expect(txtID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CollectionPointProfile_txtID")
    );
    await act(async () => {
      userEvent.type(txtID, "1");
    });
  });
  test("Custom Test Cases for txtID", () => {
    // START_USER_CODE-USER_txtID_TEST
    // END_USER_CODE-USER_txtID_TEST
  });
});
