/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_CmaDmaControlProfile from "./CmaDmaControlProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CmaDmaControlProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_CmaDmaControlProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is CmaDmaControlProfile Loads Successfully", () => {
    expect(screen.getByText("CmaDmaControlProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for CmaDmaControlProfile", () => {
    // START_USER_CODE-USER_CmaDmaControlProfile_Custom_Test_Case
    // END_USER_CODE-USER_CmaDmaControlProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_CmaDmaControlProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridCMADMAControlProfile(Grid Widget) Test Cases", async () => {
    let gridCMADMAControlProfile = screen.getByTestId(
      "gridCMADMAControlProfile"
    );
    let gridCMADMAControlProfilebtn =
      gridCMADMAControlProfile.nextElementSibling.firstElementChild;
    gridCMADMAControlProfile =
      gridCMADMAControlProfile.parentElement.parentElement.parentElement;
    expect(gridCMADMAControlProfile.tagName).toBe("DIV");
    expect(gridCMADMAControlProfile.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_gridCMADMAControlProfile"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCMADMAControlProfile", () => {
    // START_USER_CODE-USER_gridCMADMAControlProfile_TEST
    // END_USER_CODE-USER_gridCMADMAControlProfile_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCmaDmaControlProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxCmaDmaControlProfile = screen.getByTestId(
      "grpbxCmaDmaControlProfile"
    );
    expect(grpbxCmaDmaControlProfile.tagName).toBe("BUTTON");
    expect(grpbxCmaDmaControlProfile.type).toBe("button");
    expect(grpbxCmaDmaControlProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCmaDmaControlProfile", () => {
    // START_USER_CODE-USER_grpbxCmaDmaControlProfile_TEST
    // END_USER_CODE-USER_grpbxCmaDmaControlProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblPDExceptionList(Label Widget) Test Cases", async () => {
    const lblPDExceptionList = screen.getByTestId("lblPDExceptionList");
    expect(lblPDExceptionList.tagName).toBe("LABEL");
    expect(lblPDExceptionList.classList).toContain("form-label");
    expect(lblPDExceptionList.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_lblPDExceptionList"
      )
    );
  });
  test("Custom Test Cases for lblPDExceptionList", () => {
    // START_USER_CODE-USER_lblPDExceptionList_TEST
    // END_USER_CODE-USER_lblPDExceptionList_TEST
  });
  test("gridCMADMAControlProfile_txtcol1(Grid Widget) Test Cases", async () => {
    let gridCMADMAControlProfile_txtcol1 = screen.getByTestId(
      "gridCMADMAControlProfile"
    );
    let gridCMADMAControlProfile_txtcol1btn =
      gridCMADMAControlProfile_txtcol1.nextElementSibling.firstElementChild;
    gridCMADMAControlProfile_txtcol1 =
      gridCMADMAControlProfile_txtcol1.parentElement.parentElement
        .parentElement;
    expect(gridCMADMAControlProfile_txtcol1.tagName).toBe("DIV");
    expect(gridCMADMAControlProfile_txtcol1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_gridCMADMAControlProfile"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1", () => {
    // START_USER_CODE-USER_txtcol1_TEST
    // END_USER_CODE-USER_txtcol1_TEST
  });
  test("gridCMADMAControlProfile_txtcolCMADMA(Grid Widget) Test Cases", async () => {
    let gridCMADMAControlProfile_txtcolCMADMA = screen.getByTestId(
      "gridCMADMAControlProfile"
    );
    let gridCMADMAControlProfile_txtcolCMADMAbtn =
      gridCMADMAControlProfile_txtcolCMADMA.nextElementSibling
        .firstElementChild;
    gridCMADMAControlProfile_txtcolCMADMA =
      gridCMADMAControlProfile_txtcolCMADMA.parentElement.parentElement
        .parentElement;
    expect(gridCMADMAControlProfile_txtcolCMADMA.tagName).toBe("DIV");
    expect(gridCMADMAControlProfile_txtcolCMADMA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_gridCMADMAControlProfile"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCMADMA", () => {
    // START_USER_CODE-USER_txtcolCMADMA_TEST
    // END_USER_CODE-USER_txtcolCMADMA_TEST
  });
  test("gridCMADMAControlProfile_txtcolProducer(Grid Widget) Test Cases", async () => {
    let gridCMADMAControlProfile_txtcolProducer = screen.getByTestId(
      "gridCMADMAControlProfile"
    );
    let gridCMADMAControlProfile_txtcolProducerbtn =
      gridCMADMAControlProfile_txtcolProducer.nextElementSibling
        .firstElementChild;
    gridCMADMAControlProfile_txtcolProducer =
      gridCMADMAControlProfile_txtcolProducer.parentElement.parentElement
        .parentElement;
    expect(gridCMADMAControlProfile_txtcolProducer.tagName).toBe("DIV");
    expect(gridCMADMAControlProfile_txtcolProducer.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_gridCMADMAControlProfile"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProducer", () => {
    // START_USER_CODE-USER_txtcolProducer_TEST
    // END_USER_CODE-USER_txtcolProducer_TEST
  });
  test("txtCountry(Textbox Widget) Test Cases", async () => {
    const txtCountry = screen.getByTestId("txtCountry");
    expect(txtCountry.tagName).toBe("INPUT");
    expect(txtCountry.type).toBe("text");
    expect(txtCountry.classList).toContain("textboxWidgetClass");
    expect(txtCountry.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_txtCountry")
    );
    await act(async () => {
      userEvent.type(txtCountry, "1");
    });
  });
  test("Custom Test Cases for txtCountry", () => {
    // START_USER_CODE-USER_txtCountry_TEST
    // END_USER_CODE-USER_txtCountry_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_txtState")
    );
    await act(async () => {
      userEvent.type(txtState, "1");
    });
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
