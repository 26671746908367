/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualAcctDistMaint from "./ManualAcctDistMaint";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualAcctDistMaint Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualAcctDistMaint />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualAcctDistMaint Loads Successfully", () => {
    expect(screen.getByText("ManualAcctDistMaint")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualAcctDistMaint", () => {
    // START_USER_CODE-USER_ManualAcctDistMaint_Custom_Test_Case
    // END_USER_CODE-USER_ManualAcctDistMaint_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualAcctDistMaint />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxManualAcctDistMaint(GroupBox Widget) Test Cases", async () => {
    const grpbxManualAcctDistMaint = screen.getByTestId(
      "grpbxManualAcctDistMaint"
    );
    expect(grpbxManualAcctDistMaint.tagName).toBe("BUTTON");
    expect(grpbxManualAcctDistMaint.type).toBe("button");
    expect(grpbxManualAcctDistMaint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualAcctDistMaint", () => {
    // START_USER_CODE-USER_grpbxManualAcctDistMaint_TEST
    // END_USER_CODE-USER_grpbxManualAcctDistMaint_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblPostedOn(Label Widget) Test Cases", async () => {
    const lblPostedOn = screen.getByTestId("lblPostedOn");
    expect(lblPostedOn.tagName).toBe("LABEL");
    expect(lblPostedOn.classList).toContain("form-label");
    expect(lblPostedOn.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_lblPostedOn")
    );
  });
  test("Custom Test Cases for lblPostedOn", () => {
    // START_USER_CODE-USER_lblPostedOn_TEST
    // END_USER_CODE-USER_lblPostedOn_TEST
  });
  test("lblPostedOnValue(Label Widget) Test Cases", async () => {
    const lblPostedOnValue = screen.getByTestId("lblPostedOnValue");
    expect(lblPostedOnValue.tagName).toBe("LABEL");
    expect(lblPostedOnValue.classList).toContain("form-label");
    expect(lblPostedOnValue.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_lblPostedOnValue"
      )
    );
  });
  test("Custom Test Cases for lblPostedOnValue", () => {
    // START_USER_CODE-USER_lblPostedOnValue_TEST
    // END_USER_CODE-USER_lblPostedOnValue_TEST
  });
  test("txt1007SettleNum(Textbox Widget) Test Cases", async () => {
    const txt1007SettleNum = screen.getByTestId("txt1007SettleNum");
    expect(txt1007SettleNum.tagName).toBe("INPUT");
    expect(txt1007SettleNum.type).toBe("text");
    expect(txt1007SettleNum.classList).toContain("textboxWidgetClass");
    expect(txt1007SettleNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txt1007SettleNum"
      )
    );
    await act(async () => {
      userEvent.type(txt1007SettleNum, "1");
    });
  });
  test("Custom Test Cases for txt1007SettleNum", () => {
    // START_USER_CODE-USER_txt1007SettleNum_TEST
    // END_USER_CODE-USER_txt1007SettleNum_TEST
  });
  test("txtAccountNum(Textbox Widget) Test Cases", async () => {
    const txtAccountNum = screen.getByTestId("txtAccountNum");
    expect(txtAccountNum.tagName).toBe("INPUT");
    expect(txtAccountNum.type).toBe("text");
    expect(txtAccountNum.classList).toContain("textboxWidgetClass");
    expect(txtAccountNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtAccountNum")
    );
    await act(async () => {
      userEvent.type(txtAccountNum, "1");
    });
  });
  test("Custom Test Cases for txtAccountNum", () => {
    // START_USER_CODE-USER_txtAccountNum_TEST
    // END_USER_CODE-USER_txtAccountNum_TEST
  });
  test("txtAmount(Textbox Widget) Test Cases", async () => {
    const txtAmount = screen.getByTestId("txtAmount");
    expect(txtAmount.tagName).toBe("INPUT");
    expect(txtAmount.type).toBe("text");
    expect(txtAmount.classList).toContain("textboxWidgetClass");
    expect(txtAmount.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtAmount")
    );
    await act(async () => {
      userEvent.type(txtAmount, "1");
    });
  });
  test("Custom Test Cases for txtAmount", () => {
    // START_USER_CODE-USER_txtAmount_TEST
    // END_USER_CODE-USER_txtAmount_TEST
  });
  test("txtChargeLocation(Textbox Widget) Test Cases", async () => {
    const txtChargeLocation = screen.getByTestId("txtChargeLocation");
    expect(txtChargeLocation.tagName).toBe("INPUT");
    expect(txtChargeLocation.type).toBe("text");
    expect(txtChargeLocation.classList).toContain("textboxWidgetClass");
    expect(txtChargeLocation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtChargeLocation"
      )
    );
    await act(async () => {
      userEvent.type(txtChargeLocation, "1");
    });
  });
  test("Custom Test Cases for txtChargeLocation", () => {
    // START_USER_CODE-USER_txtChargeLocation_TEST
    // END_USER_CODE-USER_txtChargeLocation_TEST
  });
  test("txtCheckNum(Textbox Widget) Test Cases", async () => {
    const txtCheckNum = screen.getByTestId("txtCheckNum");
    expect(txtCheckNum.tagName).toBe("INPUT");
    expect(txtCheckNum.type).toBe("text");
    expect(txtCheckNum.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtCheckNum")
    );
    await act(async () => {
      userEvent.type(txtCheckNum, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum", () => {
    // START_USER_CODE-USER_txtCheckNum_TEST
    // END_USER_CODE-USER_txtCheckNum_TEST
  });
  test("txtContractNum(Textbox Widget) Test Cases", async () => {
    const txtContractNum = screen.getByTestId("txtContractNum");
    expect(txtContractNum.tagName).toBe("INPUT");
    expect(txtContractNum.type).toBe("text");
    expect(txtContractNum.classList).toContain("textboxWidgetClass");
    expect(txtContractNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtContractNum"
      )
    );
    await act(async () => {
      userEvent.type(txtContractNum, "1");
    });
  });
  test("Custom Test Cases for txtContractNum", () => {
    // START_USER_CODE-USER_txtContractNum_TEST
    // END_USER_CODE-USER_txtContractNum_TEST
  });
  test("txtDeliveryAgree(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgree = screen.getByTestId("txtDeliveryAgree");
    expect(txtDeliveryAgree.tagName).toBe("INPUT");
    expect(txtDeliveryAgree.type).toBe("text");
    expect(txtDeliveryAgree.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgree.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtDeliveryAgree"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgree, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgree", () => {
    // START_USER_CODE-USER_txtDeliveryAgree_TEST
    // END_USER_CODE-USER_txtDeliveryAgree_TEST
  });
  test("txtDepartment(Textbox Widget) Test Cases", async () => {
    const txtDepartment = screen.getByTestId("txtDepartment");
    expect(txtDepartment.tagName).toBe("INPUT");
    expect(txtDepartment.type).toBe("text");
    expect(txtDepartment.classList).toContain("textboxWidgetClass");
    expect(txtDepartment.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtDepartment")
    );
    await act(async () => {
      userEvent.type(txtDepartment, "1");
    });
  });
  test("Custom Test Cases for txtDepartment", () => {
    // START_USER_CODE-USER_txtDepartment_TEST
    // END_USER_CODE-USER_txtDepartment_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtLocation")
    );
    await act(async () => {
      userEvent.type(txtLocation, "1");
    });
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtQuantity(Textbox Widget) Test Cases", async () => {
    const txtQuantity = screen.getByTestId("txtQuantity");
    expect(txtQuantity.tagName).toBe("INPUT");
    expect(txtQuantity.type).toBe("text");
    expect(txtQuantity.classList).toContain("textboxWidgetClass");
    expect(txtQuantity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtQuantity")
    );
    await act(async () => {
      userEvent.type(txtQuantity, "1");
    });
  });
  test("Custom Test Cases for txtQuantity", () => {
    // START_USER_CODE-USER_txtQuantity_TEST
    // END_USER_CODE-USER_txtQuantity_TEST
  });
  test("txtReason(Textbox Widget) Test Cases", async () => {
    const txtReason = screen.getByTestId("txtReason");
    expect(txtReason.tagName).toBe("INPUT");
    expect(txtReason.type).toBe("text");
    expect(txtReason.classList).toContain("textboxWidgetClass");
    expect(txtReason.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtReason")
    );
    await act(async () => {
      userEvent.type(txtReason, "1");
    });
  });
  test("Custom Test Cases for txtReason", () => {
    // START_USER_CODE-USER_txtReason_TEST
    // END_USER_CODE-USER_txtReason_TEST
  });
  test("txtResponsibility(Textbox Widget) Test Cases", async () => {
    const txtResponsibility = screen.getByTestId("txtResponsibility");
    expect(txtResponsibility.tagName).toBe("INPUT");
    expect(txtResponsibility.type).toBe("text");
    expect(txtResponsibility.classList).toContain("textboxWidgetClass");
    expect(txtResponsibility.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtResponsibility"
      )
    );
    await act(async () => {
      userEvent.type(txtResponsibility, "1");
    });
  });
  test("Custom Test Cases for txtResponsibility", () => {
    // START_USER_CODE-USER_txtResponsibility_TEST
    // END_USER_CODE-USER_txtResponsibility_TEST
  });
  test("txtSC95InspNum(Textbox Widget) Test Cases", async () => {
    const txtSC95InspNum = screen.getByTestId("txtSC95InspNum");
    expect(txtSC95InspNum.tagName).toBe("INPUT");
    expect(txtSC95InspNum.type).toBe("text");
    expect(txtSC95InspNum.classList).toContain("textboxWidgetClass");
    expect(txtSC95InspNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtSC95InspNum"
      )
    );
    await act(async () => {
      userEvent.type(txtSC95InspNum, "1");
    });
  });
  test("Custom Test Cases for txtSC95InspNum", () => {
    // START_USER_CODE-USER_txtSC95InspNum_TEST
    // END_USER_CODE-USER_txtSC95InspNum_TEST
  });
  test("txtShipLocation(Textbox Widget) Test Cases", async () => {
    const txtShipLocation = screen.getByTestId("txtShipLocation");
    expect(txtShipLocation.tagName).toBe("INPUT");
    expect(txtShipLocation.type).toBe("text");
    expect(txtShipLocation.classList).toContain("textboxWidgetClass");
    expect(txtShipLocation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtShipLocation"
      )
    );
    await act(async () => {
      userEvent.type(txtShipLocation, "1");
    });
  });
  test("Custom Test Cases for txtShipLocation", () => {
    // START_USER_CODE-USER_txtShipLocation_TEST
    // END_USER_CODE-USER_txtShipLocation_TEST
  });
  test("txtSubDepartment(Textbox Widget) Test Cases", async () => {
    const txtSubDepartment = screen.getByTestId("txtSubDepartment");
    expect(txtSubDepartment.tagName).toBe("INPUT");
    expect(txtSubDepartment.type).toBe("text");
    expect(txtSubDepartment.classList).toContain("textboxWidgetClass");
    expect(txtSubDepartment.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtSubDepartment"
      )
    );
    await act(async () => {
      userEvent.type(txtSubDepartment, "1");
    });
  });
  test("Custom Test Cases for txtSubDepartment", () => {
    // START_USER_CODE-USER_txtSubDepartment_TEST
    // END_USER_CODE-USER_txtSubDepartment_TEST
  });
  test("txtTradeInspNum(Textbox Widget) Test Cases", async () => {
    const txtTradeInspNum = screen.getByTestId("txtTradeInspNum");
    expect(txtTradeInspNum.tagName).toBe("INPUT");
    expect(txtTradeInspNum.type).toBe("text");
    expect(txtTradeInspNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtTradeInspNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeInspNum, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspNum", () => {
    // START_USER_CODE-USER_txtTradeInspNum_TEST
    // END_USER_CODE-USER_txtTradeInspNum_TEST
  });
  test("txtTradeSettleNum(Textbox Widget) Test Cases", async () => {
    const txtTradeSettleNum = screen.getByTestId("txtTradeSettleNum");
    expect(txtTradeSettleNum.tagName).toBe("INPUT");
    expect(txtTradeSettleNum.type).toBe("text");
    expect(txtTradeSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettleNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtTradeSettleNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeSettleNum, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettleNum", () => {
    // START_USER_CODE-USER_txtTradeSettleNum_TEST
    // END_USER_CODE-USER_txtTradeSettleNum_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtTransferNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtWhseRcptNum(Textbox Widget) Test Cases", async () => {
    const txtWhseRcptNum = screen.getByTestId("txtWhseRcptNum");
    expect(txtWhseRcptNum.tagName).toBe("INPUT");
    expect(txtWhseRcptNum.type).toBe("text");
    expect(txtWhseRcptNum.classList).toContain("textboxWidgetClass");
    expect(txtWhseRcptNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistMaint_txtWhseRcptNum"
      )
    );
    await act(async () => {
      userEvent.type(txtWhseRcptNum, "1");
    });
  });
  test("Custom Test Cases for txtWhseRcptNum", () => {
    // START_USER_CODE-USER_txtWhseRcptNum_TEST
    // END_USER_CODE-USER_txtWhseRcptNum_TEST
  });
});
