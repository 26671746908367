/* eslint-disable*/
import React from "react";
import GridWidget from "./GridWidget";
import { screen, fireEvent, render } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import AppContext from "../../../../context/AppContext";
import { MemoryRouter } from "react-router-dom";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { t, getTwoLevelMenus } from "../../../../__mocks__/react-i18next";
import { LinkWidget } from "../../shared/WindowImports";
//import ParentWindow from "../../shared/ParentWindow";

describe("GridWidget isCoblTable Test Cases", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget1,datewidget1,buttonwidget1,textboxwidget5,textboxwidget7,filewidget1",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "BankCustomer#findAllCustomers#PagerORsort",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
      },

      buttonwidget1: {
        name: "buttonwidget1",
        type: "ButtonWidget",
        colName: "gridcolumnwidget3",
        parent: "gridwidget1",
        Label: "buttonwidget1",
        ShortcutKey: "1",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },

      gridcolumnwidget3: {
        name: "gridcolumnwidget3",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Button Widget",
        Height: "",
        Width: "",
      },

      datewidget1: {
        name: "datewidget1",
        type: "DateWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "datewidget1",
        ShortcutKey: "3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
      },

      gridcolumnwidget2: {
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Date Widget",
        Height: "",
        Width: "",
      },

      textboxwidget1: {
        name: "textboxwidget1",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget1",
        parent: "gridwidget1",
        Label: "textboxwidget1",
        ShortcutKey: "0",
        DefaultValue: "ToolTip Test",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },

      gridcolumnwidget1: {
        name: "gridcolumnwidget1",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Text Box Widget",
        Height: "",
        Width: "",
      },
      textboxwidget5: {
        name: "textboxwidget5",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget5",
        parent: "gridwidget1",
        Label: "textboxwidget5",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget5: {
        name: "gridcolumnwidget5",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      textboxwidget7: {
        name: "textboxwidget7",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget7",
        parent: "gridwidget1",
        Label: "textboxwidget7",
        CharWidth: "48",
        Height: "",
        Width: "",
        Enabled: false,
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget7: {
        name: "gridcolumnwidget7",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox(Disable)",
        Height: "",
        Width: "",
      },
      filewidget1: {
        name: "filewidget1",
        type: "FileWidget",
        colName: "gridcolumnwidget4",
        parent: "gridwidget1",
        Label: "filewidget1",
        AllowedExtensions: "JPEG,GIF,PNG,JPG",
        ShortcutKey: "6",
        CharWidth: "48",
        Height: "",
        AltText: "Alternate Text For File Widget(Grid)",
        Width: "",
        ShowAs: "RenderOnScreen",
      },
      gridcolumnwidget4: {
        name: "gridcolumnwidget1",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "File Widget",
        Height: "",
        Width: "",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [],
      buttonwidget1: undefined,
      gridcolumnwidget3: undefined,
      datewidget1: undefined,
      gridcolumnwidget2: undefined,
      textboxwidget1: undefined,
      gridcolumnwidget1: undefined,
      textboxwidget5: undefined,
      gridcolumnwidget5: undefined,
      textboxwidget7: undefined,
      gridcolumnwidget7: undefined,
      filewidget1: undefined,
      gridcolumnwidget4: undefined,
    };
    let values = { gridwidget1: [] };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = false;

    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");
    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    const popOverBtn =
      renderVar.getByTestId("gridwidget1").nextElementSibling.firstElementChild;
    userEvent.click(popOverBtn, { button: 0 });
    const addBtn =
      popOverBtn.nextElementSibling.lastElementChild.firstElementChild
        .nextElementSibling.nextElementSibling.firstElementChild;
    userEvent.click(addBtn, { button: 0 });
    const lastPage =
      renderVar.getByTestId("gridwidget1").parentElement.nextElementSibling
        .firstElementChild.lastElementChild.lastElementChild.lastElementChild;
  });
});
describe("GridWidget isCoblTable Test Cases", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget1,datewidget1,buttonwidget1,textboxwidget5,textboxwidget7,filewidget1",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "BankCustomer#findAllCustomers#PagerORsort",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
      },

      buttonwidget1: {
        name: "buttonwidget1",
        type: "ButtonWidget",
        colName: "gridcolumnwidget3",
        parent: "gridwidget1",
        Label: "buttonwidget1",
        ShortcutKey: "1",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },

      gridcolumnwidget3: {
        name: "gridcolumnwidget3",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Button Widget",
        Height: "",
        Width: "",
        sortable: true,
      },

      datewidget1: {
        name: "datewidget1",
        type: "DateWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "datewidget1",
        ShortcutKey: "3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
      },

      gridcolumnwidget2: {
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Date Widget",
        Height: "",
        Width: "",
        sortable: true,
      },

      textboxwidget1: {
        name: "textboxwidget1",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget1",
        parent: "gridwidget1",
        Label: "textboxwidget1",
        ShortcutKey: "0",
        DefaultValue: "ToolTip Test",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },

      gridcolumnwidget1: {
        name: "gridcolumnwidget1",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Text Box Widget",
        Height: "",
        Width: "",
        sortable: true,
      },
      textboxwidget5: {
        name: "textboxwidget5",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget5",
        parent: "gridwidget1",
        Label: "textboxwidget5",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget5: {
        name: "gridcolumnwidget5",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
        sortable: true,
      },
      textboxwidget7: {
        name: "textboxwidget7",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget7",
        parent: "gridwidget1",
        Label: "textboxwidget7",
        CharWidth: "48",
        Height: "",
        Width: "",
        Enabled: false,
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget7: {
        name: "gridcolumnwidget7",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox(Disable)",
        Height: "",
        Width: "",
        sortable: true,
      },
      filewidget1: {
        name: "filewidget1",
        type: "FileWidget",
        colName: "gridcolumnwidget4",
        parent: "gridwidget1",
        Label: "filewidget1",
        AllowedExtensions: "JPEG,GIF,PNG,JPG",
        ShortcutKey: "6",
        CharWidth: "48",
        Height: "",
        AltText: "Alternate Text For File Widget(Grid)",
        Width: "",
        ShowAs: "RenderOnScreen",
      },
      gridcolumnwidget4: {
        name: "gridcolumnwidget1",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "File Widget",
        Height: "",
        Width: "",
        sortable: true,
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [],
      buttonwidget1: undefined,
      gridcolumnwidget3: undefined,
      datewidget1: undefined,
      gridcolumnwidget2: undefined,
      textboxwidget1: undefined,
      gridcolumnwidget1: undefined,
      textboxwidget5: undefined,
      gridcolumnwidget5: undefined,
      textboxwidget7: undefined,
      gridcolumnwidget7: undefined,
      filewidget1: undefined,
      gridcolumnwidget4: undefined,
    };
    let values = { gridwidget1: [] };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;

    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");
    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    const popOverBtn =
      renderVar.getByTestId("gridwidget1").nextElementSibling.firstElementChild;
    userEvent.click(popOverBtn, { button: 0 });

    userEvent.click(screen.getByText("Edit"), { button: 0 });
    const lastPage =
      renderVar.getByTestId("gridwidget1").parentElement.nextElementSibling
        .firstElementChild.lastElementChild.lastElementChild.lastElementChild;

    userEvent.click(renderVar.getByTestId("gridwidget1"), { button: 0 });
    fireEvent.click(renderVar.getByTestId("gridwidget1"));
  });
});
describe("GridWidget isCoblTable Test Cases-Pagination", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Pagination", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",

        ofTypeDomain: "d_intClass",
        ReadOnly: false,
      },
      textboxwidget3: {
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
        ReadOnly: false,
      },
      gridcolumnwidget8: {
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true; //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.keyDown(document, {
      key: "f7",
      code: "f7",
      keyCode: 118,
      charCode: 118,
    });
    fireEvent.keyDown(document, {
      key: "f8",
      code: "f8",
      keyCode: 119,
      charCode: 119,
    });

    lnkFwdFunc();
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    fireEvent.click(
      screen.getByText("3-3 of 3").nextElementSibling.lastChild.previousSibling
        .previousSibling,
      { button: 0 }
    );
    fireEvent.click(
      screen.getByText("2-2 of 3").nextElementSibling.lastChild.previousSibling
        .previousSibling,
      { button: 0 }
    );
    fireEvent.click(
      screen.getByText("Rows per page:").nextElementSibling.lastChild,
      { button: 0 }
    );
    fireEvent.click(
      screen.getByText("Rows per page:").nextElementSibling.firstChild
        .lastChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-Normal get control", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Normal get control", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget9,textboxwidget3,datetimewidget19,dropdownfieldwidget21,textareawidget23,timewidget25",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ReadOnly: false,
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ReadOnly: false,
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },
      datetimewidget19: {
        name: "datetimewidget19",
        type: "DateTimeWidget",
        colName: "gridcolumnwidget18",
        parent: "gridwidget1",
        Label: "datetimewidget19",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 14 },
        ReadOnly: false,
        ofTypeDomain: "d_dateTime",
      },
      gridcolumnwidget18: {
        name: "gridcolumnwidget18",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget18",
        Height: "",
        Width: "",
      },
      dropdownfieldwidget21: {
        name: "dropdownfieldwidget21",
        type: "DropDownFieldWidget",
        colName: "gridcolumnwidget20",
        parent: "gridwidget1",
        Label: "dropdownfieldwidget21",
        CharWidth: "48",
        DataProviderForDropDown: "inline",
        Height: "",
        Width: "",
        Options: "Option1:1,Option2:2",
        ReadOnly: false,
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget20: {
        name: "gridcolumnwidget20",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget20",
        Height: "",
        Width: "",
      },
      textareawidget23: {
        name: "textareawidget23",
        type: "TextAreaWidget",
        colName: "gridcolumnwidget22",
        parent: "gridwidget1",
        Label: "textareawidget23",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ReadOnly: false,
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget22: {
        name: "gridcolumnwidget22",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget22",
        Height: "",
        Width: "",
      },
      timewidget25: {
        name: "timewidget25",
        type: "TimeWidget",
        colName: "gridcolumnwidget24",
        parent: "gridwidget1",
        Label: "timewidget25",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 6 },
        ReadOnly: false,
        ofTypeDomain: "d_time",
      },
      gridcolumnwidget24: {
        name: "gridcolumnwidget24",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget24",
        Height: "",
        Width: "",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
      datetimewidget19: undefined,
      gridcolumnwidget18: undefined,
      dropdownfieldwidget21: undefined,
      gridcolumnwidget20: undefined,
      textareawidget23: undefined,
      gridcolumnwidget22: undefined,
      timewidget25: undefined,
      gridcolumnwidget24: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.keyDown(document, {
      key: "f8",
      code: "f8",
      keyCode: 119,
      charCode: 119,
    });
    lnkFwdFunc();
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    fireEvent.click(
      screen.getByText("3-3 of 3").nextElementSibling.lastChild.previousSibling
        .previousSibling,
      { button: 0 }
    );
    fireEvent.click(
      screen.getByText("2-2 of 3").nextElementSibling.lastChild.previousSibling
        .previousSibling,
      { button: 0 }
    );
    fireEvent.click(
      screen.getByText("Rows per page:").nextElementSibling.lastChild,
      { button: 0 }
    );
    fireEvent.click(
      screen.getByText("Rows per page:").nextElementSibling.firstChild
        .lastChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-Delete", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Delete", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",

        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true; //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Delete"), { button: 0 });
  });
});
describe("GridWidget isCoblTable Test Cases-Duplicate", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Duplicate", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",

        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Duplicate"), { button: 0 });
  });
});
describe("GridWidget isCoblTable Test Cases-Edit", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Edit", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",

        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Edit"), { button: 0 });
  });
});
describe("GridWidget isCoblTable Test Cases-addActionOkCancel-Check", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-addActionOkCancel", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={{}}
                    errors={{}}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Add"), { button: 0 });
    txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });
    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-editActionOkCancel-Check", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-editActionOkCancel", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={{}}
                    errors={{}}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Edit"), { button: 0 });
    txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });
    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-editActionOkCancel-Times", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-editActionOkCancel-Times", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",

        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={{}}
                    errors={{}}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Edit"), { button: 0 });
    txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });
    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.lastChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-addActionOkCancel-Add Times", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-addActionOkCancel", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={{}}
                    errors={{}}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Add"), { button: 0 });
    txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });
    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.lastChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-Editable On change", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Editable On change", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    //userEvent.type(txtBox, "123");
    /*fireEvent.keyDown(txtBox, {
      key: "End",
      code: "End",
      keyCode: 35,
      charCode: 35,
    });*/
  });
});
describe("GridWidget isCoblTable Test Cases-Editable On change", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Editable On change", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget9,textboxwidget3,dropdownfieldwidget21,timewidget35",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      dropdownfieldwidget21: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select",
        name: "dropdownfieldwidget21",
        type: "DropDownFieldWidget",
        colName: "gridcolumnwidget20",
        parent: "gridwidget1",
        Label: "dropdownfieldwidget21",
        CharWidth: "48",
        DataProviderForDropDown: "inline",
        Height: "",
        Width: "",
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget20: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget20",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget20",
        Height: "",
        Width: "",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },
      timewidget35: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calendarTimeType: "Default",
        isTime24HourClock: false,
        minuteTimeRequired: true,
        secondTimeRequired: false,
        stepTimeHour: "1",
        stepTimeMinute: "5",
        stepTimeSecond: "5",
        sysTime: true,
        name: "timewidget35",
        type: "TimeWidget",
        colName: "gridcolumnwidget34",
        parent: "gridwidget1",
        Label: "timewidget35",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 6 },
        ofTypeDomain: "d_time",
      },
      gridcolumnwidget34: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget34",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget34",
        Height: "",
        Width: "",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
      dropdownfieldwidget21: undefined,
      gridcolumnwidget20: undefined,
      timewidget35: undefined,
      gridcolumnwidget34: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    //userEvent.type(txtBox, "123");
  });
});
describe("GridWidget isCoblTable Test Cases-addActionOkCancel-Check with check errors", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-addActionOkCancel-Check with check errors", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder: "textboxwidget9,textboxwidget3",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },

      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });

    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
    fireEvent.click(screen.getByText("Add"), { button: 0 });
    txtBox = screen.getAllByTestId("textboxwidget3")[0];
    fireEvent.click(txtBox.parentElement.parentElement, { button: 0 });
    fireEvent.click(
      screen.getByTestId("gridwidget1").nextElementSibling.firstChild,
      { button: 0 }
    );
  });
});
describe("GridWidget isCoblTable Test Cases-Editable CheckBoxWidget, text area and file widget", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Editable On CheckBoxWidget, text area and file widget", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget9,textboxwidget3,dropdownfieldwidget21,timewidget35,checkboxwidget37,textareawidget23,filewidget41",
        Label: "Cobal Grid",
        isEditable: true,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      dropdownfieldwidget21: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select",
        name: "dropdownfieldwidget21",
        type: "DropDownFieldWidget",
        colName: "gridcolumnwidget20",
        parent: "gridwidget1",
        Label: "dropdownfieldwidget21",
        CharWidth: "48",
        DataProviderForDropDown: "inline",
        Height: "",
        Width: "",
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget20: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget20",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget20",
        Height: "",
        Width: "",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },
      timewidget35: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calendarTimeType: "Default",
        isTime24HourClock: false,
        minuteTimeRequired: true,
        secondTimeRequired: false,
        stepTimeHour: "1",
        stepTimeMinute: "5",
        stepTimeSecond: "5",
        sysTime: true,
        name: "timewidget35",
        type: "TimeWidget",
        colName: "gridcolumnwidget34",
        parent: "gridwidget1",
        Label: "timewidget35",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 6 },
        ofTypeDomain: "d_time",
      },
      gridcolumnwidget34: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget34",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget34",
        Height: "",
        Width: "",
      },
      checkboxwidget37: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        name: "checkboxwidget37",
        type: "CheckBoxWidget",
        colName: "gridcolumnwidget36",
        parent: "gridwidget1",
        Label: "checkboxwidget37",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_boolean",
      },
      gridcolumnwidget36: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget36",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget36",
        Height: "",
        Width: "",
      },
      textareawidget23: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        maxlength: "",
        PlaceholderForTextArea: "",
        textareaType: "default",
        wrap: "None",
        name: "textareawidget23",
        type: "TextAreaWidget",
        colName: "gridcolumnwidget22",
        parent: "gridwidget1",
        Label: "textareawidget23",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget22: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget22",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget22",
        Height: "",
        Width: "",
      },
      filewidget41: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AllowedExtensions: "jpg,gif",
        AltText: "",
        FileCount: "",
        Multiplicity: false,
        ShowAs: "Attachment",
        SizeLimit: "",
        name: "filewidget41",
        type: "FileWidget",
        colName: "gridcolumnwidget40",
        parent: "gridwidget1",
        Label: "filewidget41",
        CharWidth: "48",
        Height: "",
        Width: "",
      },
      gridcolumnwidget40: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget40",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget40",
        Height: "",
        Width: "",
      },
      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
      dropdownfieldwidget21: undefined,
      gridcolumnwidget20: undefined,
      timewidget35: undefined,
      gridcolumnwidget34: undefined,
      checkboxwidget37: undefined,
      gridcolumnwidget36: undefined,
      textareawidget23: undefined,
      gridcolumnwidget22: undefined,
      filewidget41: undefined,
      gridcolumnwidget40: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    // let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");

    gridwidget1_buttonwidget1 =
      gridwidget1_buttonwidget1.parentElement.parentElement.parentElement;
    expect(gridwidget1_buttonwidget1.tagName).toBe("DIV");
    expect(gridwidget1_buttonwidget1.classList).toContain("GridWidget");
    fireEvent.click(screen.getByText("1-1 of 3").nextElementSibling.lastChild, {
      button: 0,
    });
    let txtBox = screen.getAllByTestId("textboxwidget3")[0];
    //userEvent.type(txtBox, "123");
  });
});
describe("GridWidget isCoblTable Test Cases-Editable CheckBoxWidget, text area and file widget(get Value template)", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Editable On CheckBoxWidget, text area and file widget(get Value template)", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget9,textboxwidget3,dropdownfieldwidget21,timewidget35,checkboxwidget37,textareawidget23,filewidget41,linkwidget27",
        Label: "Cobal Grid",
        isEditable: false,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      dropdownfieldwidget21: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select",
        name: "dropdownfieldwidget21",
        type: "DropDownFieldWidget",
        colName: "gridcolumnwidget20",
        parent: "gridwidget1",
        Label: "dropdownfieldwidget21",
        CharWidth: "48",
        DataProviderForDropDown: "inline",
        Height: "",
        Width: "",
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget20: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget20",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget20",
        Height: "",
        Width: "",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },
      timewidget35: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calendarTimeType: "Default",
        isTime24HourClock: false,
        minuteTimeRequired: true,
        secondTimeRequired: false,
        stepTimeHour: "1",
        stepTimeMinute: "5",
        stepTimeSecond: "5",
        sysTime: true,
        name: "timewidget35",
        type: "TimeWidget",
        colName: "gridcolumnwidget34",
        parent: "gridwidget1",
        Label: "timewidget35",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 6 },
        ofTypeDomain: "d_time",
      },
      gridcolumnwidget34: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget34",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget34",
        Height: "",
        Width: "",
      },
      checkboxwidget37: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        name: "checkboxwidget37",
        type: "CheckBoxWidget",
        colName: "gridcolumnwidget36",
        parent: "gridwidget1",
        Label: "checkboxwidget37",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_boolean",
      },
      gridcolumnwidget36: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget36",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget36",
        Height: "",
        Width: "",
      },
      textareawidget23: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        maxlength: "",
        PlaceholderForTextArea: "",
        textareaType: "default",
        wrap: "None",
        name: "textareawidget23",
        type: "TextAreaWidget",
        colName: "gridcolumnwidget22",
        parent: "gridwidget1",
        Label: "textareawidget23",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget22: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget22",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget22",
        Height: "",
        Width: "",
      },
      filewidget41: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AllowedExtensions: "jpg,gif",
        AltText: "",
        FileCount: "",
        Multiplicity: false,
        ShowAs: "Attachment",
        SizeLimit: "",
        name: "filewidget41",
        type: "FileWidget",
        colName: "gridcolumnwidget40",
        parent: "gridwidget1",
        Label: "filewidget41",
        CharWidth: "48",
        Height: "",
        Width: "",
      },
      gridcolumnwidget40: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget40",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget40",
        Height: "",
        Width: "",
      },
      linkwidget27: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        Category: "Submit Page",
        isSynchronous: true,
        name: "linkwidget27",
        type: "LinkWidget",
        colName: "gridcolumnwidget26",
        parent: "gridwidget1",
        Label: "linkwidget27",
        CharWidth: "48",
        Height: "",
        Width: "",
      },
      gridcolumnwidget26: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget26",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget26",
        Height: "",
        Width: "",
      },
      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
      dropdownfieldwidget21: undefined,
      gridcolumnwidget20: undefined,
      timewidget35: undefined,
      gridcolumnwidget34: undefined,
      checkboxwidget37: undefined,
      gridcolumnwidget36: undefined,
      textareawidget23: undefined,
      gridcolumnwidget22: undefined,
      filewidget41: undefined,
      gridcolumnwidget40: undefined,
      linkwidget27: undefined,
      gridcolumnwidget26: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
    let gridwidget1_buttonwidget1 = renderVar.getByTestId("gridwidget1");
  });
});
describe("GridWidget isCoblTable Test Cases-Editable CheckBoxWidget, text area and file widget(get Value template)-extra added field", () => {
  test("gridwidget1_buttonwidget1(Grid Widget) Test Cases-Editable On CheckBoxWidget, text area and file widget(get Value template)-extra added field", async () => {
    let stateScreenConf = {
      gridwidget1: {
        name: "gridwidget1",
        type: "GridWidget",
        parent: "AllWidgetScreenReact",
        gridCellsOrder:
          "textboxwidget9,textboxwidget3,dropdownfieldwidget21,timewidget35,checkboxwidget37,textareawidget23,filewidget41,linkwidget27",
        Label: "Cobal Grid",
        isEditable: false,
        Height: "",
        Width: "",
        paginationService: "",
        isExpandable: true,
        DisplaySize: "1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "1",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        clientPagination: true,
        hideContainer: true,
        expandedCompTemplate: true,
      },

      textboxwidget9: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget9",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget8",
        parent: "gridwidget1",
        Label: "textboxwidget9",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_intClass",
      },
      textboxwidget3: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget3",
        type: "TextBoxWidget",
        colName: "gridcolumnwidget2",
        parent: "gridwidget1",
        Label: "textboxwidget3",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      dropdownfieldwidget21: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select",
        name: "dropdownfieldwidget21",
        type: "DropDownFieldWidget",
        colName: "gridcolumnwidget20",
        parent: "gridwidget1",
        Label: "dropdownfieldwidget21",
        CharWidth: "48",
        DataProviderForDropDown: "inline",
        Height: "",
        Width: "",
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget20: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget20",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget20",
        Height: "",
        Width: "",
      },
      gridcolumnwidget8: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget8",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "Status Coulmn",
        VisibleForLargeDesktop: false,
        VisibleForTabLandscape: false,
        VisibleForTabPotrait: false,
        isStatusColumn: true,
        Visible: false,
        Height: "",
        VisibleForMobile: false,
        Width: "",
      },
      gridcolumnwidget2: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget2",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "TextBox",
        Height: "",
        Width: "",
      },
      lnkBkwd: {
        name: "lnkBkwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkBkwd",
      },
      lnkFwd: {
        name: "lnkFwd",
        type: "LinkWidget",
        parent: "commandcontainerwidget15",
        Label: "lnkFwd",
      },
      timewidget35: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calendarTimeType: "Default",
        isTime24HourClock: false,
        minuteTimeRequired: true,
        secondTimeRequired: false,
        stepTimeHour: "1",
        stepTimeMinute: "5",
        stepTimeSecond: "5",
        sysTime: true,
        name: "timewidget35",
        type: "TimeWidget",
        colName: "gridcolumnwidget34",
        parent: "gridwidget1",
        Label: "timewidget35",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 6 },
        ofTypeDomain: "d_time",
      },
      gridcolumnwidget34: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget34",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget34",
        Height: "",
        Width: "",
      },
      checkboxwidget37: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        name: "checkboxwidget37",
        type: "CheckBoxWidget",
        colName: "gridcolumnwidget36",
        parent: "gridwidget1",
        Label: "checkboxwidget37",
        CharWidth: "48",
        Height: "",
        Width: "",
        ofTypeDomain: "d_boolean",
      },
      gridcolumnwidget36: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget36",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget36",
        Height: "",
        Width: "",
      },
      textareawidget23: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        maxlength: "",
        PlaceholderForTextArea: "",
        textareaType: "default",
        wrap: "None",
        name: "textareawidget23",
        type: "TextAreaWidget",
        colName: "gridcolumnwidget22",
        parent: "gridwidget1",
        Label: "textareawidget23",
        CharWidth: "48",
        Height: "",
        Width: "",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      },
      gridcolumnwidget22: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget22",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget22",
        Height: "",
        Width: "",
      },
      filewidget41: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AllowedExtensions: "jpg,gif",
        AltText: "",
        FileCount: "",
        Multiplicity: false,
        ShowAs: "Attachment",
        SizeLimit: "",
        name: "filewidget41",
        type: "FileWidget",
        colName: "gridcolumnwidget40",
        parent: "gridwidget1",
        Label: "filewidget41",
        CharWidth: "48",
        Height: "",
        Width: "",
      },
      gridcolumnwidget40: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget40",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget40",
        Height: "",
        Width: "",
      },
      linkwidget27: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        Category: "Submit Page",
        isSynchronous: true,
        name: "linkwidget27",
        type: "LinkWidget",
        colName: "gridcolumnwidget26",
        parent: "gridwidget1",
        Label: "linkwidget27",
        CharWidth: "48",
        Height: "",
        Width: "",
      },
      gridcolumnwidget26: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false,
        name: "gridcolumnwidget26",
        type: "GridColumnWidget",
        parent: "gridwidget1",
        Label: "gridcolumnwidget26",
        Height: "",
        Width: "",
      },
      Label: "TestScreen",
      windowName: "TestScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestGUI.TestScreen",
    };
    window._kaledo.initialValues = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
      ],
      textboxwidget9: undefined,
      gridcolumnwidget8: undefined,
      textboxwidget3: undefined,
      gridcolumnwidget2: undefined,
      dropdownfieldwidget21: undefined,
      gridcolumnwidget20: undefined,
      timewidget35: undefined,
      gridcolumnwidget34: undefined,
      checkboxwidget37: undefined,
      gridcolumnwidget36: undefined,
      textareawidget23: undefined,
      gridcolumnwidget22: undefined,
      filewidget41: undefined,
      gridcolumnwidget40: undefined,
      linkwidget27: undefined,
      gridcolumnwidget26: undefined,
    };
    let values = {
      gridwidget1: [
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
        {
          textboxwidget9: "c",
          textboxwidget3: 1,
          dropdownfieldwidget21: "",
          timewidget35: "",
          checkboxwidget37: "",
          textareawidget23: "",
          filewidget41: "",
          linkwidget27: "",
        },
      ],
    };
    let errors = jest.fn();
    let touched = jest.fn();
    let onEvent = jest.fn();
    window._kaledo["isCobolTable"] = true;
    //let parWindow = new ParentWindow(stateScreenConf);

    let lnkFwdFunc = () => {
      //parWindow.navigateToNextPage("gridwidget1",true);
    };
    let lnkBkwdFunc = () => {
      //parWindow.navigateToPreviousPage("gridwidget1");
    };
    let renderVar = render(
      <AppContext.Provider
        value={{
          t: t,
          _selectedLang: "en",
          screenList: getTwoLevelMenus(),
          _languages: window._kaledo["languages"],
        }}
      >
        <MemoryRouter>
          <Formik
            initialValues={window._kaledo.initialValues}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  noValidate
                  className="row"
                >
                  <GridWidget
                    conf={stateScreenConf.gridwidget1}
                    screenConf={stateScreenConf}
                    touched={touched}
                    errors={errors}
                    rows={values.gridwidget1}
                    onEvent={onEvent}
                  />
                  <LinkWidget
                    conf={stateScreenConf.lnkBkwd}
                    screenConf={stateScreenConf}
                    onClick={lnkBkwdFunc}
                  ></LinkWidget>
                  <LinkWidget
                    conf={stateScreenConf.lnkFwd}
                    screenConf={stateScreenConf}
                    onClick={lnkFwdFunc}
                  ></LinkWidget>
                </Form>
              );
            }}
          />
        </MemoryRouter>
      </AppContext.Provider>
    );
  });
});
