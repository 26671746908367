/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  getGridValue,
  setGridValue,
  getSelectedRowNumber,
  getData,
  setData,
  getRowCount,
  hideColumn,
  setGridColList,
  disable,
  enable
} from "../../shared/WindowImports";

import "./InventoryAdjustments.scss";
import jsPDF from 'jspdf';
import Loading from "../../../Loader/Loading";
import * as XLSX from 'xlsx/xlsx.mjs';
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_InventoryAdjustments(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  let useridFromLS = sessionStorage.getItem('userid')
  // START_USER_CODE-USER_PROPERTIES
  const [flag,setFlag] = useState(0)
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "InventoryAdjustments",
    windowName: "InventoryAdjustments",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.InventoryAdjustments",
    // START_USER_CODE-USER_InventoryAdjustments_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Inventory Adjustments",
      scrCode: "PN1070B",
    },
    // END_USER_CODE-USER_InventoryAdjustments_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnExporttoExcel: {
      name: "btnExporttoExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExporttoExcel_PROPERTIES

      // END_USER_CODE-USER_btnExporttoExcel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colAddDateTime: {
      name: "colAddDateTime",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Add Date Time",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddDateTime_PROPERTIES

      // END_USER_CODE-USER_colAddDateTime_PROPERTIES
    },
    colAddUser: {
      name: "colAddUser",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Add User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddUser_PROPERTIES

      // END_USER_CODE-USER_colAddUser_PROPERTIES
    },
    colAdjLbs: {
      name: "colAdjLbs",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Adj/Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAdjLbs_PROPERTIES

      // END_USER_CODE-USER_colAdjLbs_PROPERTIES
    },
    colComments: {
      name: "colComments",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Comments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colComments_PROPERTIES

      // END_USER_CODE-USER_colComments_PROPERTIES
    },
    colDateTime: {
      name: "colDateTime",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Date/Time",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateTime_PROPERTIES

      // END_USER_CODE-USER_colDateTime_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colOrganic: {
      name: "colOrganic",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrganic_PROPERTIES

      // END_USER_CODE-USER_colOrganic_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGen_PROPERTIES

      // END_USER_CODE-USER_colSeedGen_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colWhseBin: {
      name: "colWhseBin",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Whse/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseBin_PROPERTIES

      // END_USER_CODE-USER_colWhseBin_PROPERTIES
    },
    datetimewidget1: {
      name: "datetimewidget1",
      type: "DateWidget",
      colName: "colDateTime",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_datetimewidget1_PROPERTIES
Mandatory:true,
      // END_USER_CODE-USER_datetimewidget1_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxInventoryAdjustments",
      Label: "Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddcolOleic: {
      name: "ddcolOleic",
      type: "DropDownFieldWidget",
      colName: "colOleic",
      parent: "gridAdjustments",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolOleic_PROPERTIES

      // END_USER_CODE-USER_ddcolOleic_PROPERTIES
    },
    ddcolOrganic: {
      name: "ddcolOrganic",
      type: "DropDownFieldWidget",
      colName: "colOrganic",
      parent: "gridAdjustments",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolOrganic_PROPERTIES

      // END_USER_CODE-USER_ddcolOrganic_PROPERTIES
    },
    ddcolPeanutType: {
      name: "ddcolPeanutType",
      type: "DropDownFieldWidget",
      colName: "colPeanutType",
      parent: "gridAdjustments",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolPeanutType_PROPERTIES
      Mandatory:true,
      callBack: (row, col, selectedVal) => {
         if (!col.childElement) return;
        let buyingPointId =getValue(_kaledo.thisObj,'ddBuyingPoint')
         let _fteched =  FillDropDownList(buyingPointId,selectedVal)
          if(_fteched instanceof Promise){
           _fteched.then(val=>{
              let fmtVal ={valueList:val}
              setGridColList(
                thisObj,
                col.parent,
                col.childElement,
                row.rowID$,
                fmtVal,
                col.name
              );
            },err=>{
            })
           }
       
        return true;
      },
      childElement: "ddcolPeanutVariety",
      // END_USER_CODE-USER_ddcolPeanutType_PROPERTIES
    },
    ddcolPeanutVariety: {
      name: "ddcolPeanutVariety",
      type: "DropDownFieldWidget",
      colName: "colPeanutVariety",
      parent: "gridAdjustments",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddcolPeanutVariety_PROPERTIES
    },
    ddcolSeedGen: {
      name: "ddcolSeedGen",
      type: "DropDownFieldWidget",
      colName: "colSeedGen",
      parent: "gridAdjustments",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolSeedGen_PROPERTIES

      // END_USER_CODE-USER_ddcolSeedGen_PROPERTIES
    },
    ddcolSeg: {
      name: "ddcolSeg",
      type: "DropDownFieldWidget",
      colName: "colSeg",
      parent: "gridAdjustments",
      Label: "Seg",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolSeg_PROPERTIES
      Mandatory:true,
      // END_USER_CODE-USER_ddcolSeg_PROPERTIES
    },
    ddcolWhseBin: {
      name: "ddcolWhseBin",
      type: "DropDownFieldWidget",
      colName: "colWhseBin",
      parent: "gridAdjustments",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolWhseBin_PROPERTIES
      Mandatory:true,
      // END_USER_CODE-USER_ddcolWhseBin_PROPERTIES
    },
    gridAdjustments: {
      name: "gridAdjustments",
      type: "GridWidget",
      parent: "grpbxInventoryAdjustments",
      gridCellsOrder:
        "datetimewidget1,ddcolWhseBin,ddcolPeanutType,ddcolPeanutVariety,ddcolSeg,ddcolSeedGen,ddcolOleic,ddcolOrganic,txtcolAdjLbs,txtcolComments,txtcolAddUser,txtcolAddDateTime,txtcolYN",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "1",
      Height: "",
      Width: "",
      isEditable: true,
      needGridChange: true,
      isDeleteRowRequired:false,
      // START_USER_CODE-USER_gridAdjustments_PROPERTIES
      conditionalRowStyles: [{
        when: (row) => { return (row.txtcolYN == 'Y' ) },
        style: {
          backgroundColor: 'lightGrey',
          pointerEvents:"none",
        }
      }],
      disableArray: [
        "btnPrint",
        "ddBuyingPoint",
        "btnExporttoExcel",
        "btnOk"
      ],
      // END_USER_CODE-USER_gridAdjustments_PROPERTIES
    },
    lblAdjustments: {
      name: "lblAdjustments",
      type: "LabelWidget",
      parent: "grpbxInventoryAdjustments",
      Label: "Adjustments:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAdjustments_PROPERTIES

      // END_USER_CODE-USER_lblAdjustments_PROPERTIES
    },
    txtcolAddDateTime: {
      name: "txtcolAddDateTime",
      type: "TextBoxWidget",
      colName: "colAddDateTime",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolAddDateTime_PROPERTIES

      // END_USER_CODE-USER_txtcolAddDateTime_PROPERTIES
    },
    txtcolAddUser: {
      name: "txtcolAddUser",
      type: "TextBoxWidget",
      colName: "colAddUser",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolAddUser_PROPERTIES

      // END_USER_CODE-USER_txtcolAddUser_PROPERTIES
    },
    txtcolAdjLbs: {
      name: "txtcolAdjLbs",
      type: "TextBoxWidget",
      colName: "colAdjLbs",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAdjLbs_PROPERTIES
      Mandatory:true,
      // END_USER_CODE-USER_txtcolAdjLbs_PROPERTIES
    },
    txtcolComments: {
      name: "txtcolComments",
      type: "TextBoxWidget",
      colName: "colComments",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolComments_PROPERTIES
      Mandatory:true,
      // END_USER_CODE-USER_txtcolComments_PROPERTIES
    },
    txtcolYN: {
      name: "txtcolYN",
      type: "TextBoxWidget",
      colName: "colYN",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolYN_PROPERTIES
    
      // END_USER_CODE-USER_txtcolYN_PROPERTIES
    },
    colYN: {
      name: "colYN",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Comments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colYN_PROPERTIES

      // END_USER_CODE-USER_colYN_PROPERTIES
    },
    txtcoldatecolor: {
      name: "txtcoldatecolor",
      type: "TextBoxWidget",
      colName: "coldatecolor",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolYN_PROPERTIES
    
      // END_USER_CODE-USER_txtcolYN_PROPERTIES
    },
    coldatecolor: {
      name: "coldatecolor",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Comments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colYN_PROPERTIES

      // END_USER_CODE-USER_colYN_PROPERTIES
    },
    grpbxInventoryAdjustments: {
      name: "grpbxInventoryAdjustments",
      type: "GroupBoxWidget",
      parent: "InventoryAdjustments",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxInventoryAdjustments_PROPERTIES

      // END_USER_CODE-USER_grpbxInventoryAdjustments_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "InventoryAdjustments",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  const [rowNo, setRowNo] = useState()
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    thisObj.state.gridAdjustments.actionList[3].required = false;
    Form_Load()
    hideColumn(thisObj, "gridAdjustments", "txtcolYN") 
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // hideColumn(thisObj,'gridAdjustments','txtcolYN')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const Form_Load = async () => {
    await bFillBuyingPointList()
    await bFillPeanutTypeList()
    await bFillSegmentList()
    await bFillOrganicList()
    await bFillOleicList()
    await bFillSeedgenList()
    // await bFillWarehouseBinList()
  }
//
  const bFillBuyingPointList = () => {
    let js = []
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj,'ddBuyingPoint',js.at(0).key)
      FillInventoryAdjustments(js.at(0).key)
      bFillWarehouseBinList(js.at(0).key)
    })
   
  }

  //Method for binding Peanut Type dropdown
  const bFillPeanutTypeList = () => {
    let js = [];
    js.push({ key: '', description: '' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description:data[i].pnutTypeId+' - '+data[i].pnutTypeName }
        js.push(obj)
      }
      let str = "";
      for (let i in js) {
        str += js[i]["description"] + ":";
        str += js[i]["key"] + ",";
      }
      str = str.slice(0,str.length-1) 
      thisObj.state.ddcolPeanutType.Options = str;
      thisObj.state.ddcolPeanutType.valueList = js;
    })
    
  }
  const bFillWarehouseBinList = async (cmbBuyingPoint) => {
    let js = []
    js.push({ key: '', description: '' })
    let response = await SettlementService.RetrieveWhouseBinInventoryDetails(cmbBuyingPoint, null, null, null, null)
    let data = response
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
          let obj = {
              key: data[i].whouseNum + ' - ' + data[i].binNum, binNum: data[i].binNum, description: data[i].whouseNum + ' - ' + data[i].binNum
              }
          js.push(obj)
          obj = {}   
      }
      let str = "";
      for (let i in js) {
        str += js[i]["description"] + ":";
        str += js[i]["key"] + ",";
      }
      str = str.slice(0,str.length-1) 
      thisObj.state.ddcolWhseBin.Options = str; 
      thisObj.state.ddcolWhseBin.valueList = js;
    }else{
      thisObj.state.ddcolWhseBin.Options = ""; 
      thisObj.state.ddcolWhseBin.valueList = js;
    }
  }
 // Method for binding Segment list  
 const bFillSegmentList = () => {
  let js = []
  js.push({ key: '',  description: '' })
  js.push({ key: '1', description: '1' })
  js.push({ key: '2', description: '2' })
  js.push({ key: '3', description: '3' })
  let str = "";
  for (let i in js) {
    str += js[i]["description"] + ":";
    str += js[i]["key"] + ",";
  }
  str = str.slice(0,str.length-1)
  thisObj.state.ddcolSeg.Options = str;
  thisObj.state.ddcolSeg.valueList = js;
}

 // Method for binding seed list  
 const bFillSeedgenList = () => {
  let js = []
  js.push({ key: '', description: '' })
  js.push({ key: 'Foundation', description: 'Foundation' })
  js.push({ key: 'Certified', description: 'Certified' })
  js.push({ key: 'Registered', description: 'Registered' })
  let str = "";
  for (let i in js) {
    str += js[i]["description"] + ":";
    str += js[i]["key"] + ",";
  }
  str = str.slice(0,str.length-1)
  thisObj.state.ddcolSeedGen.Options = str; 
  thisObj.state.ddcolSeedGen.valueList = js;
}

// Method for binding ddcolOleic list  
const bFillOleicList = () => {
  let js = []
  js.push({ key: '',  description: '' })
  js.push({ key: 'H', description: 'H' })
  js.push({ key: 'M', description: 'M' })
  let str = "";
  for (let i in js) {
    str += js[i]["description"] + ":";
    str += js[i]["key"] + ",";
  }
  str = str.slice(0,str.length-1)
  thisObj.state.ddcolOleic.Options = str; 
  thisObj.state.ddcolOleic.valueList = js;
}

// Method for binding ddcolOrganic list  
const bFillOrganicList = () => {
  let js = []
  js.push({ key: '',  description: '' })
  js.push({ key: 'N', description: 'N' })
  js.push({ key: 'Y', description: 'Y' })
  let str = "";
  for (let i in js) {
    str += js[i]["description"] + ":";
    str += js[i]["key"] + ",";
  }
  str = str.slice(0,str.length-1)
  thisObj.state.ddcolOrganic.Options = str; 
  thisObj.state.ddcolOrganic.valueList = js;
}
const FillDropDownList = async (buyingPointId, pnut_type_id) => {
  try {
    let js = [];
    let obj = {}
    let str = "";
    obj = { key: "", description: "" }
    js.push(obj)
    let new_varietyData=[]
    let sComboData=''
    setLoading(true)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      let varietyData = await ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null);
      if (varietyData.length > 0) {
        for (var i = 0; i < varietyData.length; i++) {
          let dataExist = js.find(elem => elem.key == varietyData[i].pnut_variety_id);
          if (dataExist == undefined) {
            obj = { key: varietyData[i].pnut_variety_id, description:varietyData[i].pnut_variety_id+ '-' +varietyData[i].pnut_variety_name }
            if (varietyData[i].symbol_ind == "TRADEMARK") {
              obj = { key: varietyData[i].pnut_variety_id , description:varietyData[i].pnut_variety_id+ '-' + varietyData[i].pnut_variety_name + 'ᵀᴹ' }
            }
            if (varietyData[i].symbol_ind == "REGISTERED TRADEMARK") {
              obj = { key: varietyData[i].pnut_variety_id, description:varietyData[i].pnut_variety_id+ '-' + varietyData[i].pnut_variety_name + '®' }
            }
            if (varietyData[i].symbol_ind == "COPYRIGHT") {
              obj = { key: varietyData[i].pnut_variety_id, description:varietyData[i].pnut_variety_id+ '-' + varietyData[i].pnut_variety_name + '©' }
            }
            js.push(obj);
            for (let i in js) {
              str += js[i]["description"] + ":";
              str += js[i]["key"] + ",";
            }
            str = str.slice(0,str.length-1) 
            new_varietyData.push(obj)
            sComboData=new_varietyData.join('|')+"|"
          }
        }
      }
     
    }
    setLoading(false)
   return js
   
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during peanut varity click event"
      );
    }
    return false;
  }
 
}
const FillDropDownVar = async (buyingPointId, pnut_type_id) => {
  let js = [];
  let obj = {}
  let str = "";

  obj = { key: "", description: "" }
  js.push(obj)
  let new_varietyData=[]
  let sComboData=''
  if (pnut_type_id !== null && pnut_type_id !== '') {
    let varietyData = await ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null);
    if (varietyData.length > 0) {
      for (var i = 0; i < varietyData.length; i++) {
        let dataExist = js.find(elem => elem.key == varietyData[i].pnut_variety_id);
        if (dataExist == undefined) {
          obj = { key: varietyData[i].pnut_variety_id, description:varietyData[i].pnut_variety_id+ '-' +varietyData[i].pnut_variety_name }
          if (varietyData[i].symbol_ind == "TRADEMARK") {
            obj = { key: varietyData[i].pnut_variety_id , description:varietyData[i].pnut_variety_id+ '-' + varietyData[i].pnut_variety_name + 'ᵀᴹ' }
          }
          if (varietyData[i].symbol_ind == "REGISTERED TRADEMARK") {
            obj = { key: varietyData[i].pnut_variety_id, description:varietyData[i].pnut_variety_id+ '-' + varietyData[i].pnut_variety_name + '®' }
          }
          if (varietyData[i].symbol_ind == "COPYRIGHT") {
            obj = { key: varietyData[i].pnut_variety_id, description:varietyData[i].pnut_variety_id+ '-' + varietyData[i].pnut_variety_name + '©' }
          }
          js.push(obj);
          for (let i in js) {
            str += js[i]["description"] + ":";
            str += js[i]["key"] + ",";
          }
          str = str.slice(0,str.length-1) 
          // setGridValue(thisObj, 'gridAdjustments', 'ddcolPeanutVariety', 0, js)
          thisObj.state.ddcolPeanutVariety.Options = str; 
          thisObj.state.ddcolPeanutVariety.valueList = js;

          new_varietyData.push(obj)
          sComboData=new_varietyData.join('|')+"|"
        }
      }
    }
   
  }

}
const onbtnOkClick = async(buyingPoint) => {
  return await bSaveData(buyingPoint)
};
thisObj.onbtnOkClick = onbtnOkClick;

const onbtnCancelClick = () => {
  try {
    
    document.getElementById("SystemMaintenanceApplicationSupport_InventoryAdjustmentsPopUp").childNodes[0].click()
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during btnCancel click event"
      );
    }
    return false;
  }
}
thisObj.onbtnCancelClick = onbtnCancelClick;

const isGridDirty = async (buyingPoint) => {
  try{
    let res = []
    let dirtyArr = []
    let data = getValue(thisObj, 'gridAdjustments')
    let dirtyFlag = true
    for(var i=0;i<data?.length; i++){
      dirtyFlag = true

      if ([undefined, null, ""].includes(data[i].datetimewidget1)) {
        dirtyFlag = false
      }
      if ([undefined, null, ""].includes(data[i].ddcolWhseBin)) {
        dirtyFlag = false
      }
      if ([undefined, null, ""].includes(data[i].ddcolPeanutType)) {
        dirtyFlag = false
      }
      if ([undefined, null, ""].includes(data[i].ddcolSeg)) {
        dirtyFlag = false
      }
      if ([undefined, null, ""].includes(data[i].txtcolComments)) {
        dirtyFlag = false
      }
      if ([undefined, null, ""].includes(data[i].txtcolComments)) {
        dirtyFlag = false
      }

      if(dirtyFlag){
        res.push(moment(data[i].datetimewidget1).format('MM/DD/YYYY'))
        res.push(data[i].ddcolWhseBin)
        res.push(data[i].ddcolPeanutType)
        res.push(data[i].ddcolPeanutVariety)
        res.push(data[i].ddcolSeg)
        res.push(data[i].ddcolSeedGen)
        res.push(data[i].ddcolOleic)
        res.push(data[i].ddcolOrganic)
        res.push(data[i].txtcolAdjLbs)
        res.push(data[i].txtcolComments)
        res.push(data[i].txtcolAddUser)
        res.push(data[i].txtcolAddDateTime)
        dirtyArr.push(res)
      }
      res = []
    }
    
    if(getData(thisObj, 'initialGridLength') !== data?.length){
      let input = confirm(`There are some unsaved changes for the current buying point - ${buyingPoint}. Select an appropiate option to continue. \n
      - Select OK to save changes and move to a new buying point.
      - Select CANCEL to retain the unsaved changes.
        `)
      
      if(!input){
        setValue(thisObj,'ddBuyingPoint', buyingPoint)
        return
      }
      else{
        return await onbtnOkClick(buyingPoint)
      }
    }
    return true
  }
  catch(err){
    showMessage(thisObj, err?.message)
  }
}

// code for print pdf...
const onbtnPrintClick = async () => {
  try {
    var obj = new jsPDF('landscape', 'mm', 'a4');
    var data1 = getValue(thisObj, "gridAdjustments")
    let buy_pt_id = thisObj.values['ddBuyingPoint']
    let dddBuyingPointlist = thisObj.state['ddBuyingPoint'].valueList;
    let selbuyptidlist = dddBuyingPointlist.filter(elem => elem.key === buy_pt_id)
    
    let enableDisableFlag = true
    
    let headerarray1 = []
    let headerarray2 = []
    let data = []
    let data2 = []

    let dirtyArr = []
    
    if (data1.length != 0) {
      var res = []
      var res1 = []
      for (var i = 0; i < data1.length; i++) {
        enableDisableFlag = true
        if ([undefined, null, ""].includes(data1[i].datetimewidget1)) {
          enableDisableFlag = false
        }
        if ([undefined, null, ""].includes(data1[i].ddcolWhseBin)) {
          enableDisableFlag = false
        }
        if ([undefined, null, ""].includes(data1[i].ddcolPeanutType)) {
          enableDisableFlag = false
        }
        if ([undefined, null, ""].includes(data1[i].ddcolSeg)) {
          enableDisableFlag = false
        }
        if ([undefined, null, ""].includes(data1[i].txtcolComments)) {
          enableDisableFlag = false
        }
        if ([undefined, null, ""].includes(data1[i].txtcolComments)) {
          enableDisableFlag = false
        }

        if (enableDisableFlag) {
          res.push(moment(data1[i].datetimewidget1).format('MM/DD/YYYY'))
          res.push(data1[i].ddcolWhseBin)
          res.push(data1[i].ddcolPeanutType)
          res.push(data1[i].ddcolPeanutVariety)
          res.push(data1[i].ddcolSeg)
          res.push(data1[i].ddcolSeedGen)
          res.push(data1[i].ddcolOleic)
          res.push(data1[i].ddcolOrganic)
          res.push(data1[i].txtcolAdjLbs)
          res.push(data1[i].txtcolComments)
          res.push(data1[i].txtcolAddUser)
          res.push(data1[i].txtcolAddDateTime)
          dirtyArr.push(res)
          data.push((res.slice(0,9)))
          data2.push((res.slice(9,12)))
        }
        res = []
    }

      if (data1?.length !== dirtyArr?.length) {
        if (!confirm(`${data1.length - dirtyArr.length} out of ${data1.length} are not matching the required validation and will not be included in the print document.\nDo you want to continue ?`)) {
          return
        }
      }
      
      let headerarray = [ "Date/Time", "Whse/Bin", "Peanut Type", "Peanut Variety", "Seg", "Seed Gen", "Oleic", "Orgainc", "Adj/Pounds", "Comments", "Add User", "Add Date Time" ]

      headerarray1 = headerarray.slice(0, 9)
      headerarray2 = headerarray.slice(9, 12)

      var Array = []
      let N = 0;

      if (headerarray.length > 8) {
        while (N < data.length) {
          // first set of 9 columns
          obj.autoTable({
            headStyles: { fontSize: 6.5, cellWidth: 'wrap' },
            styles: { fontSize: 6.5, lineWidth: 0.2 },
            margin: { top: 8, left: 3 },
            theme: 'plain',
            fontStyle: 'normal',
            head: [
              headerarray1
            ],
            body: data.slice(N, N + 25)
          })

          // footer of first 9 columns
          obj.autoTable({
            startY: 15,
            startX: 5,
            styles: { fontSize: 5, lineWidth: 0, color: 10 },
            margin: { bottom: 1, left: 3 },
            body: Array,
            theme: 'plain',
            fontStyle: 'normal',
          });

          obj.addPage();//Adding New page

          // remaining set of columns
          obj.autoTable({
            headStyles: { fontSize: 6.5, cellWidth: 'wrap' },
            styles: { fontSize: 6.5, lineWidth: 0.2 },
            margin: { top: 8, left: 3 },
            theme: 'plain',
            fontStyle: 'normal',
            head: [
              headerarray2
            ],
            body: data2.slice(N, N + 25)

          })

          // footer of remaining columns
          obj.autoTable({
            startY: 195,
            startX: 5,
            styles: { fontSize: 5, lineWidth: 0, color: 10 },
            margin: { bottom: 1, left: 3 },
            body: Array,
            theme: 'plain',
            fontStyle: 'normal',
          });
          N = N + 25;

          // obj.addPage();//Adding New page
        }
      }

      // Page count 
      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 1; w <= pageCount; w++) {
        obj.setPage(w);
        obj.setFontSize(6.5);
        if (buy_pt_id !== '' && buy_pt_id !== null && buy_pt_id !== undefined) {
          obj.text(`Inv. ${cropYearFromLS} : ${selbuyptidlist[0].description}`, 2, 200);
        }
        obj.text(280, 200, String(w))
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
      else {
        alert("Routine:Accdist.cmdPrint_Click")
      }
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnPrintClick = onbtnPrintClick;

//creating excel file in collapse format
const onbtnExporttoExcelClick = async() => {
  try {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let excelData = getValue(thisObj, "gridAdjustments")
    var fileName = "Inventory Adjustments"
    const arrayData = []
    let enableDisableFlag = true
    if (excelData.length > 1)
      for (var i = 0; i < excelData.length; i++) {
        var obj = {
          // "Date/Time": moment(excelData[i].datetimewidget1).format('MM/DD/YYYY'),
          "Date/Time": ExcelNumericDataConvert(moment(excelData[i].datetimewidget1).format('MM/DD/YYYY')),
          "Whse/Bin": excelData[i].ddcolWhseBin,
          "Peanut Type": excelData[i].ddcolPeanutType,
          "Peanut Variety": excelData[i].ddcolPeanutVariety,
          "Seg": ExcelNumericDataConvert(excelData[i].ddcolSeg),
          "Seed Gen": excelData[i].ddcolSeedGen,
          "Oleic": excelData[i].ddcolOleic,
          "Orgainc": excelData[i].ddcolOrganic,
          "Adj/Pounds":ExcelNumericDataConvert(excelData[i].txtcolAdjLbs),
          "Comments":excelData[i].txtcolComments,
          "Add User":excelData[i].txtcolAddUser,
          // "Add Date Time": excelData[i].txtcolAddDateTime
          "Add Date Time": ExcelNumericDataConvert(excelData[i].txtcolAddDateTime)
        }

        enableDisableFlag = true
        if([undefined, null, ""].includes(excelData[i].datetimewidget1)){
          enableDisableFlag = false
        }
        if([undefined, null, ""].includes(excelData[i].ddcolWhseBin)){
          enableDisableFlag = false
        }
        if([undefined, null, ""].includes(excelData[i].ddcolPeanutType)){
          enableDisableFlag = false
        }
        if([undefined, null, ""].includes(excelData[i].ddcolSeg)){
          enableDisableFlag = false
        }
        if([undefined, null, ""].includes(excelData[i].txtcolComments)){
          enableDisableFlag = false
        }
        if([undefined, null, ""].includes(excelData[i].txtcolComments)){
          enableDisableFlag = false
        }
        if(enableDisableFlag){
          arrayData.push(obj);
        }

      } else {
      var obj = {
        "Date/Time": '',
        "Whse/Bin": '',
        "Peanut Type": '',
        "Peanut Variety": '',
        "Seg": '',
        "Seed Gen": '',
        "Oleic": '',
        "Orgainc": '',
        "Adj/Pounds": '',
        "Comments":'',
        "Add User":'',
        "Add Date Time":''
      }

      arrayData.push(obj);
    }

    if(excelData?.length !== arrayData?.length){        
      if(!confirm(`${excelData.length - arrayData.length} out of ${excelData.length} are not matching the required validation and will not be included in the excel document.\nDo you want to continue ?`)){
        return
      }
    }

    const ws = XLSX.utils.json_to_sheet(arrayData)
    var wscols = [
      {width:25,alignment:'right'},
      {width:35},
      {width:30},
      {width:25},
      {width:15},
      {width:10},
      {width:15},
      {width:15},
      {width:20}
  ];
  ws['!cols'] = wscols;
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
      );
    }
    return false;
  }
  return true;

}
thisObj.onbtnExporttoExcelClick = onbtnExporttoExcelClick;

const DateValidation = (data) => {
  if (data != undefined) {
    let dateString = data.split('/');
    let Month = dateString[0]
    let date = dateString[1]
    let Year = dateString[2]
    if (Year == cropYearFromLS) {
      if (Month >= 7) {
        return true;
      } else if (Month < 7) {
        return false;
      }
    } else if (Year == cropYearFromLS + 1) {
      if (Month <= 7) {
        return true;
      } else if (Month > 7) {
        return false;
      }
    } else {
      return false;
    }
  }
}

const setRowColor = (earlieryearfalse) => {
  let n = getRowCount(_kaledo.thisObj,'gridAdjustments');
  for(let i=0; i<n; i++){
    let val = getGridValue(_kaledo.thisObj,'gridAdjustments','txtcolAddDateTime',i);``
    if(earlieryearfalse == false){
      // document.getElementById('txtcolAddDateTime').style.color = 'red';

      // document.getElementById(`row-${i}`).style.color="red"
    }
  }
  }
const bSaveData = async (buyingPoint) => {
  let buy_pt_id= buyingPoint || getValue(thisObj,'ddBuyingPoint')
  if(typeof buy_pt_id === 'object'){  //added to fix bug - 538737 ,sometime getting button class files due to empty props from parent
    buy_pt_id = getValue(thisObj,'ddBuyingPoint')
  }
  let selElem = getValue(thisObj, "gridAdjustments");
  let data = selElem.filter(elem => elem.txtcolYN !== 'Y');
  let obj = {}
  let js = []
  let datetime=true,colWhseBin=true,colPeanutType=true,colSeg=true,colAdjLbs=true,
      colComments=true,futuredateTime =true,earlieryearfalse=true,createdstatus=false
      let setdatetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
      let currentdateTime = ((new Date()).getMonth() + 1) + '/' + (new Date()).getDate() + '/' + (new Date()).getFullYear()

      if(data.length >0){
        for (let i = 0; i < data.length; i++) {
          if(data[i].datetimewidget1 =='' || data[i].datetimewidget1 == undefined){
            datetime =false
          }
          let date1 = new Date("07/01/"+ cropYearFromLS).getTime()
          let date2 = new Date(moment(String((data[i].datetimewidget1))).format('MM/DD/YYYY')).getTime()
          let res = (date2-date1) / (1000 * 3600 * 24);
          if(Number(res) < 0){
            earlieryearfalse=false
            setGridValue(thisObj, 'gridAdjustments', 'txtcoldatecolor', i, 'red')
            document.getElementById(`row-${i}`).querySelector(".rdt_TableCell").style.color="red"
          }
          let currentdate = new Date(currentdateTime).getTime()
          if(date2 > currentdate){
            futuredateTime=false
            document.getElementById(`row-${i}`).querySelector(".rdt_TableCell").style.color="red"
          }
           if(data[i].ddcolWhseBin =='' || data[i].ddcolWhseBin == undefined){
            colWhseBin=false
          }
           if(data[i].ddcolPeanutType =='' || data[i].ddcolPeanutType == undefined){
            colPeanutType =false
          }
           if(data[i].ddcolSeg =='' || data[i].ddcolSeg == undefined){
            colSeg=false
          } if(data[i].txtcolAdjLbs =='' || data[i].txtcolAdjLbs == undefined || isNaN(data[i].txtcolAdjLbs)){
            colAdjLbs =false
          }
          if(data[i].txtcolComments =='' || data[i].txtcolComments == undefined){
            colComments =false
          }
          if(futuredateTime==false || earlieryearfalse ==false){
            obj.txtcoldatecolor='red'
          }
          if(futuredateTime==true && earlieryearfalse ==true && colPeanutType==true && colWhseBin == true && datetime ==true && colComments ==true && colAdjLbs == true && colSeg ==true ){
            let ddcolWhseBinlist = thisObj.state['ddcolWhseBin'].valueList;
            let selddcolWhseBinlist = ddcolWhseBinlist.filter(elem => elem.key === data[i].ddcolWhseBin);
            obj.comp_id = compIdFromLS
            obj.crop_year = cropYearFromLS
            obj.buy_pt_id = buy_pt_id
            obj.whouse_num = (selddcolWhseBinlist[0].key).substring(0, 3) //!==undefined ?selddcolWhseBinlist[0].key:''
            obj.bin_num = selddcolWhseBinlist[0].binNum !==undefined ?selddcolWhseBinlist[0].binNum:''
            obj.pnut_type_id = data[i].ddcolPeanutType
            obj.pnut_variety_id = data[i].ddcolPeanutVariety
            obj.seed_gen = data[i].ddcolSeedGen
            obj.seg_type = data[i].ddcolSeg
            obj.oleic_ind = data[i].ddcolOleic
            obj.physical_inv = parseInt(data[i].txtcolAdjLbs)
            obj.user_id = useridFromLS
            obj.audit_reason=data[i].txtcolComments
            obj.audit_date_time=data[i].datetimewidget1
            obj.organic_ind=data[i].ddcolOrganic
            js.push(obj)
            obj = {}
            createdstatus = true
            // Commented by X210354 - Bug 538737
            // let reponsecreate = await SystemMaintenanceApplicationSupportService.UpdateInventoryAdjustment(obj)
            // if (reponsecreate.status == 200) {
            //   createdstatus=true
            // }else{
            //   createdstatus=false
            // }
          }   
        }
        if(datetime ==false){
          showMessage(thisObj, 'Adjustment Date is invalid!')
        }
        else if(futuredateTime ==false){
          showMessage(thisObj, `Adjustment Date cannot be greater than current date ${currentdateTime}`)
        }else if(earlieryearfalse ==false){
          showMessage(thisObj, 'Adjustment Date cannot be earlier than start of crop year date  07/01/' + cropYearFromLS)
        }
        else if(colWhseBin == false){
          showMessage(thisObj, 'Whse/Bin must be supplied!')
        }else if(colPeanutType == false){
          showMessage(thisObj, 'Peanut Type must be supplied!')
        }else if(colSeg == false){
          showMessage(thisObj, 'Seg Type must be supplied!')
        }else if(colAdjLbs == false){
          showMessage(thisObj, 'Adjustment Pounds must be numeric!')
        }else if(colComments ==false){
          showMessage(thisObj, 'Comments must be supplied!')
        }else{
          
          if(createdstatus ==true){
          let input = confirm(`Inventory Adjustments have been made, do you wish to save these adjustments permanently?`)
          if (input == true) {
            for(var i=0; i<js?.length; i++){
              let resp = await SystemMaintenanceApplicationSupportService.UpdateInventoryAdjustment(js[i])
              if(resp.status !== 200) {
                showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.')
                return false
              }
            }
            await FillInventoryAdjustments(buy_pt_id)
            obj={}
          }
        }else{
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.')
          return false
        }
        }
      } 
      return true
}

  const EnableDisablePrintandExcel = async (flag) => {
    if (flag) {
      enable(thisObj, 'btnPrint')
      enable(thisObj, 'btnExporttoExcel')
    }
    else {
      disable(thisObj, 'btnPrint')
      disable(thisObj, 'btnExporttoExcel')
    }
  }

const FillInventoryAdjustments = async (buying_point) => {
  setLoading(true)
  let data = await SystemMaintenanceApplicationSupportService.RetrieveInventoryAdjustmentDetails(buying_point)
  let griddata=[]
  let obj = {
    txtcolComments:'',
  }
  if (data != undefined && data.length > 0) {
    setFlag(data.length)
  for (let i = 0; i < data.length; i++) {
    obj.datetimewidget1=moment(String(data[i].audit_date_time)).format('MM/DD/YYYY')
    obj.ddcolWhseBin=data[i].whouse_num +' - '+data[i].bin_num
    obj.ddcolPeanutType=data[i].pnut_type_id+' - '+data[i].pnut_type_name
    obj.ddcolPeanutVariety=data[i].pnut_variety_id !=='' && data[i].pnut_variety_id !==undefined? data[i].pnut_variety_id +' - '+data[i].pnut_variety_name:' '
    obj.ddcolSeg=data[i].seg_type
    obj.ddcolSeedGen=data[i].seed_gen
    obj.ddcolOleic=data[i].oleic_ind
    obj.ddcolOrganic=data[i].organic_ind,
    obj.txtcolAdjLbs=parseInt(data[i].physical_inv),
    obj.txtcolComments=data[i].audit_reason,
    obj.txtcolAddUser=data[i].add_user_id,
    obj.txtcolAddDateTime=data[i].add_date_time
    obj.txtcolYN = 'Y'
    griddata.push(obj)
    obj = {}
    }
    setValue(thisObj, "gridAdjustments", griddata);
    setData(thisObj, 'gridAdjustments_rowsData', griddata)
    setLoading(false)
  }else{
    setValue(thisObj, "gridAdjustments", griddata);
    setData(thisObj, 'gridAdjustments_rowsData', griddata)
    setLoading(false)
  }
  setData(thisObj, 'initialGridLength', griddata?.length)
  setData(thisObj, 'initialBuyingPt', buying_point)
  await EnableDisablePrintandExcel(true)
  setLoading(false)
} 

const  ongridAdjustmentsRowSelect = (rowSelected) => {
  let buy_pt_id=getValue(thisObj,'ddBuyingPoint')
    let row = getSelectedRowNumber(thisObj, "gridAdjustments")
    if(row !==undefined){
      FillDropDownVar(buy_pt_id,getGridValue(thisObj, 'gridAdjustments', 'ddcolPeanutType', row))
    }
};
thisObj.ongridAdjustmentsRowSelect = ongridAdjustmentsRowSelect;



const onddcolPeanutTypeChange = (event) => {
  try {
    let rowsData = getData(thisObj, 'gridAdjustments')
    let buy_pt_id=getValue(thisObj,'ddBuyingPoint')
    let row = getSelectedRowNumber(thisObj, "gridAdjustments")
    if(row !==undefined){
      FillDropDownVar(buy_pt_id,getGridValue(thisObj, 'gridAdjustments', 'ddcolPeanutType', row))
    }else{
      FillDropDownVar(buy_pt_id,getGridValue(thisObj, 'gridAdjustments', 'ddcolPeanutType', 0))
    }
 
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during onddcolPeanutTypeChange event"
      );
    }
    return false;
  }
  return true;
};
thisObj.onddcolPeanutTypeChange = onddcolPeanutTypeChange;

const onddBuyingPointChange = async() => {
  try {
    let ddBuyingPointvalue = getValue(thisObj,'ddBuyingPoint')
    if(!await isGridDirty(getData(thisObj, 'initialBuyingPt'))){
      return
    }
    // bSaveData()
    await FillInventoryAdjustments(ddBuyingPointvalue)
    await bFillWarehouseBinList(ddBuyingPointvalue)
  }
  catch (err) {
  }

};
thisObj.onddBuyingPointChange = onddBuyingPointChange;

async function gridChange(event){
  try{
    let enableDisableFlag = true
    if(["change", "blur"].includes(event?.type)){
      let gridData = thisObj.values.gridAdjustments || []
      
      for(var i=0; i<gridData.length; i++){
        if([undefined, null, ""].includes(gridData[i]?.datetimewidget1)){
          enableDisableFlag = false
          break;
        }

        if([undefined, null, ""].includes(gridData[i]?.ddcolPeanutType)){
          enableDisableFlag = false
          break;
        }

        if([undefined, null, ""].includes(gridData[i]?.ddcolSeg)){
          enableDisableFlag = false
          break;
        }

        if([undefined, null, ""].includes(gridData[i]?.ddcolWhseBin)){
          enableDisableFlag = false
          break;
        }

        if([undefined, null, ""].includes(gridData[i]?.txtcolAdjLbs)){
          enableDisableFlag = false
          break;
        }

        if([undefined, null, ""].includes(gridData[i]?.txtcolComments)){
          enableDisableFlag = false
          break;
        }
      }
    }
    
    // await EnableDisablePrintandExcel(enableDisableFlag)
    return enableDisableFlag
  }
  catch(err){
    showMessage(thisObj, err.message)
  }
}

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_InventoryAdjustments*/}

              {/* END_USER_CODE-USER_BEFORE_InventoryAdjustments*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxInventoryAdjustments*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxInventoryAdjustments*/}

              <GroupBoxWidget
                conf={state.grpbxInventoryAdjustments}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblAdjustments*/}

                {/* END_USER_CODE-USER_BEFORE_lblAdjustments*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAdjustments}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAdjustments*/}

                {/* END_USER_CODE-USER_AFTER_lblAdjustments*/}
                {/* START_USER_CODE-USER_BEFORE_gridAdjustments*/}

                {/* END_USER_CODE-USER_BEFORE_gridAdjustments*/}

                <GridWidget
                  conf={state.gridAdjustments}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAdjustments}
                  onEvent={event =>{
                      gridChange(event),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }                    
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                  // onRowChange={selectedRow => onGridRowChange(selectedRow)}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAdjustments*/}

                {/* END_USER_CODE-USER_AFTER_gridAdjustments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxInventoryAdjustments*/}

              {/* END_USER_CODE-USER_AFTER_grpbxInventoryAdjustments*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnExporttoExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExporttoExcel*/}

                <ButtonWidget
                  conf={state.btnExporttoExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExporttoExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExporttoExcel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_InventoryAdjustments*/}

              {/* END_USER_CODE-USER_AFTER_InventoryAdjustments*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_InventoryAdjustments
);
