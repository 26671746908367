/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  setValue,
  getValue,
  setData,
  getData,
  hideColumn,
  goTo,
  editRow,
  getGridValue,
  setGridValue
} from "../../shared/WindowImports";

import "./PremiumDeductionTrade.scss";
import { SettlementService } from '../Service/SettlementService';
import Loading from "../../../Loader/Loading";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function Settlements_PremiumDeductionTrade(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionTrade",
    windowName: "PremiumDeductionTrade",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.PremiumDeductionTrade",
    // START_USER_CODE-USER_PremiumDeductionTrade_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1140E",
    },
    // END_USER_CODE-USER_PremiumDeductionTrade_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxPremDedFooter",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnComplete: {
      name: "btnComplete",
      type: "ButtonWidget",
      parent: "grpbxPremDedFooter",
      Label: "Complete",
      CharWidth: "8",
      // START_USER_CODE-USER_btnComplete_PROPERTIES

      // END_USER_CODE-USER_btnComplete_PROPERTIES
    },
    colPremDeduct: {
      name: "colPremDeduct",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Premiums/Deductions",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDeduct_PROPERTIES

      // END_USER_CODE-USER_colPremDeduct_PROPERTIES
    },
    colPurchsAmt: {
      name: "colPurchsAmt",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Purchase Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurchsAmt_PROPERTIES

      // END_USER_CODE-USER_colPurchsAmt_PROPERTIES
    },
    colStrgAmt: {
      name: "colStrgAmt",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Storage Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStrgAmt_PROPERTIES
      Visible: false,
      // END_USER_CODE-USER_colStrgAmt_PROPERTIES
    },
    gridPremDed: {
      name: "gridPremDed",
      type: "GridWidget",
      parent: "grpbxPremDed",
      gridCellsOrder:
        "btnCol,chkboxcol,txtcolPremDeduct,txtcolPurchsAmt,txtcolStrgAmt,txtcolPdCode,txtcolRateTypeArr,txtcolReqIndArr",
      ColSpan: "4",
      Pagination: false,
      isEditable: true,
      HasLabel: false,
      Cols: "4",
      Height: "",
      Width: "",
      clonedExtId: "31803",
      // isMultiSelect: true,
      // START_USER_CODE-USER_gridPremDed_PROPERTIES
      isCobolTable: true,
      toggleRender:true,
      conditionalRowStyles: [ 
        {
          when: (row) => { return checkIfAtleastOneTrue(row) },
          style: (row) => (setStyles(row))
        },
      ],
      // END_USER_CODE-USER_gridPremDed_PROPERTIES
    },
    lblIblhttps: {
      name: "lblIblhttps",
      type: "LabelWidget",
      parent: "grpbxPremDed",
      Label: "Iblhttps",
      ColSpan: "2",
      Visible: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblIblhttps_PROPERTIES

      // END_USER_CODE-USER_lblIblhttps_PROPERTIES
    },
    lblpremDed: {
      name: "lblpremDed",
      type: "LabelWidget",
      parent: "grpbxPremDed",
      Label: "Premiums/Deductions",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblpremDed_PROPERTIES

      // END_USER_CODE-USER_lblpremDed_PROPERTIES
    },
    colCheckbox: {
      name: "colCheckbox",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckbox_PROPERTIES

      // END_USER_CODE-USER_colCheckbox_PROPERTIES
    },
    chkboxcol: {
      name: "chkboxcol",
      type: "CheckBoxWidget",
      colName: "colCheckbox",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcol_PROPERTIES
      isMultiSelectAlternate: true
      // END_USER_CODE-USER_chkboxcol_PROPERTIES
    },
    txtcolPremDeduct: {
      name: "txtcolPremDeduct",
      type: "TextBoxWidget",
      colName: "colPremDeduct",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremDeduct_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDeduct_PROPERTIES
    },
    txtcolPurchsAmt: {
      name: "txtcolPurchsAmt",
      type: "TextBoxWidget",
      colName: "colPurchsAmt",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      ReadOnly: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPurchsAmt_PROPERTIES

      // END_USER_CODE-USER_txtcolPurchsAmt_PROPERTIES
    },
    txtcolStrgAmt: {
      name: "txtcolStrgAmt",
      type: "TextBoxWidget",
      colName: "colStrgAmt",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStrgAmt_PROPERTIES

      // END_USER_CODE-USER_txtcolStrgAmt_PROPERTIES
    },
    txtcolPdCode: {
      name: "txtcolPdCode",
      type: "TextBoxWidget",
      colName: "colPdCode",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPdCode_PROPERTIES

      // END_USER_CODE-USER_txtcolPdCode_PROPERTIES
    },
    txtcolReqIndArr: {
      name: "txtcolReqIndArr",
      type: "TextBoxWidget",
      colName: "colReqIndArr",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReqIndArr_PROPERTIES

      // END_USER_CODE-USER_txtcolReqIndArr_PROPERTIES
    },
    txtcolRateTypeArr: {
      name: "txtcolRateTypeArr",
      type: "TextBoxWidget",
      colName: "colRateTypeArr",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRateTypeArr_PROPERTIES

      // END_USER_CODE-USER_txtcolRateTypeArr_PROPERTIES
    },
    colPdCode: {
        name: "colPdCode",
        type: "GridColumnWidget",
        parent: "gridPremDed",
        Label:"Pd_Code",
        Height: "",
        Width: "",
        // START_USER_CODE-USER_colPdCode_PROPERTIES
        Visible: false,
      // END_USER_CODE-USER_colPdCode_PROPERTIES
      },
    colRateTypeArr: {
      name: "colRateTypeArr",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Rate Type Array",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRateTypeArr_PROPERTIES
      Visible: false,
      // END_USER_CODE-USER_colRateTypeArr_PROPERTIES 
    },
    colReqIndArr: {
      name: "colReqIndArr",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Required Ind Array",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReqIndArr_PROPERTIES
      Visible: false,
      // END_USER_CODE-USER_colReqIndArr_PROPERTIES 
    },
    txtNetProceeds: {
      name: "txtNetProceeds",
      type: "TextBoxWidget",
      parent: "grpbxPremDed",
      Label: "Net Proceeds",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetProceeds_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtNetProceeds_PROPERTIES
    },
    txtSegValue: {
      name: "txtSegValue",
      type: "TextBoxWidget",
      parent: "grpbxPremDed",
      Label: "Seg. Value:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegValue_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtSegValue_PROPERTIES
    },
    colButton: {
      name: "colButton",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colButton_PROPERTIES

      // END_USER_CODE-USER_colButton_PROPERTIES
    },
    btnCol: {
      name: "btnCol",
      type: "ButtonWidget",
      colName: "colButton",
      parent: "gridPremDed",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCol_PROPERTIES

      // END_USER_CODE-USER_btnCol_PROPERTIES
    },
    grpbxPremDed: {
      name: "grpbxPremDed",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionTrade",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_grpbxPremDed_PROPERTIES

      // END_USER_CODE-USER_grpbxPremDed_PROPERTIES
    },
    grpbxPremDedFooter: {
      name: "grpbxPremDedFooter",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionTrade",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPremDedFooter_PROPERTIES

      // END_USER_CODE-USER_grpbxPremDedFooter_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      "ContractManagement#MainMenu": {},

      REVERSE: {},
      "ContractManagement#MainMenu": {},

    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnCancel: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    FormLoad()
  }, [])

  useEffect(() => {
    let VndSplitsc = getData(thisObj, 'PremiumDeductionVendorSplitsTradeClose')
    if (VndSplitsc == true) {
      let pctArrayAfterVendorClose = getData(thisObj, 'pctArr')

      let psArrayAfterVendorClose = getData(thisObj, 'psArr')

      let amtArrayAfterVendorClose = getData(thisObj, 'amtArrayAfterEditedValue') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'amtArrayAfterEditedValue')

      let vdPurchageData = getData(thisObj, "dataPassingPremiumDeductionsVendorsplitTradePurchase")

      let rowID = getData(thisObj, 'PDTrowID') === "" ? 0 : getData(thisObj, 'PDTrowID') === undefined ? 0 : getData(thisObj, 'PDTrowID') === null ? 0 : Number(getData(thisObj, 'PDTrowID'))
      let j = 0
      if (vdPurchageData.length > 0) {
        for (let i = rowID; j < vdPurchageData.length; i++) {
          for (j = 0; j < vdPurchageData.length; j++) {

            if (psArrayAfterVendorClose[i][j] == 'P') {
              if ((vdPurchageData[i].txtPPCT != "") && (vdPurchageData[i].txtPPCT != 'NaN')) {
                pctArrayAfterVendorClose[i][j] = vdPurchageData[j].txtPPCT
                amtArrayAfterVendorClose[i][j] = vdPurchageData[j].lblPAMT
              }
            }
          }
        }
        setData(thisObj, 'pctArrayAfterVendorClose', pctArrayAfterVendorClose)
        setData(thisObj, 'amtArrayAfterVendorClose', amtArrayAfterVendorClose)
      }
      setData(thisObj, 'PremiumDeductionVendorSplitsTradeClose', '')
    }

  }, [getData(thisObj, 'PremiumDeductionVendorSplitsTradeClose')])

  useEffect(() => {
    if (getData(thisObj, 'premDeductTrade_close') == true) {
      setData(thisObj, 'CompletionStatus', true)
      document.getElementById("Settlements_PremiumDeductionTradePopUp").childNodes[0].click()
      document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click()
      setData(thisObj, 'premDeductTrade_close', false)
    }
  }, [getData(thisObj, 'premDeductTrade_close')])

  

  //global variables
  let methodCallIndicator
  let parntScreenName
  let apiResp = []

  let vendorArr = []
  let amtArr = []
  let pctArr = []
  let psArr = []
  let vendorSpecIndArr = []
  let venDSpecIndArr = []
  let pdCodeArr = []
  let pdIndArr = []
  let rateTypeArr = []
  let autoIndCodArr = []
  let inspectIndArr = []
  let requiredIndArr = []
  let beforeRecvIndArr = []
  let lblVendorArr = [] //['','','','','','','','','','','','','','','','','','','','',]
  let samountArr = []
  
  const onbtnCancelClick = () => {
    setData(thisObj, 'frmTradeSettleProfile.mbCancelled', true)
    document.getElementById("Settlements_PremiumDeductionTradePopUp").childNodes[0].click()
    document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click()
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //parent screen data


  const prntDataObj = getData(thisObj, 'PremiumDeductionDataObj')
  const readPremiumsDeductionsPrntObj = getData(thisObj, 'ReadPremiumsDeductionsTS')
  const lblhttpsData =  getData(thisObj, 'lblhttps')

  let lblPurchValueofSeg = readPremiumsDeductionsPrntObj.lblPurchValueOfSeg
  let lblNet = prntDataObj.lblNetWeight
  let LstrInspNum = prntDataObj.txtTradeInspNum
  let LstrBuyPtID = prntDataObj.Buying_point_id
  let LstrInspectInd = readPremiumsDeductionsPrntObj.LstrInspectInd;
  let LdblSegValue_P = readPremiumsDeductionsPrntObj.lblPurchValueOfSeg == 0.00 ? 0 : readPremiumsDeductionsPrntObj.lblPurchValueOfSeg;
  let LstrContract_P = readPremiumsDeductionsPrntObj.sApplContractList;
  let LstrApplyLBS_P = readPremiumsDeductionsPrntObj.sAppliedList;
  let LstrVendors_P = readPremiumsDeductionsPrntObj.sPVendorList;
  let LdblNET_WT_P = readPremiumsDeductionsPrntObj.dblNetWtP;
  let LdblLSK_WT_P = readPremiumsDeductionsPrntObj.dblLSKWtP;
  let LdblGROSS_WT_P = readPremiumsDeductionsPrntObj.dblGrossWtP;
  let LdblSegValue_S = 0;
  let LstrContract_S = 0;
  let LstrApplyLBS_S = 0;
  let LstrVendors_S = 0;
  let LdblNET_WT_S = prntDataObj.Vendordetails.net_wt;
  let LdblLSK_WT_S = prntDataObj.Vendordetails.lsk_wt;
  let LdblGROSS_WT_S = prntDataObj.Vendordetails.gross_wt;
  let LdblBASIS_GRADE_AMT_P = prntDataObj.Vendordetails.basis_grade_amt;
  let LdblBASIS_GRADE_AMT_S = prntDataObj.Vendordetails.basis_grade_amt;
  let LstrPDcodes_cure_clean_costs = readPremiumsDeductionsPrntObj.LstrPDcodes_cure_clean_costs;
  let dblSegLessOptPurch = 0

  let tradeFlagInd
  let segValPArr = []


  const FormLoad = () => {
  
    methodCallIndicator = true
    if (methodCallIndicator) {
      parntScreenName = 'FRMTRADESETTLEPROFILE'
      ReadPremiumsDeductionsTS()   
    }
  }


  function btnShowHideIND(row){ 
    let ress = apiResp.filter((obj)=> obj.pd_code == row.txtcolPdCode)

    if (ress[0].pd_ind == 'P' || ress[0].rate_type == 'AR' || ress[0].rate_type == 'CR_ACT' || ress[0].rate_type == 'CT_ADV' || ress[0].rate_type == 'FUEL' || ress[0].rate_type == 'LEASE' || ress[0].rate_type == 'O_ADM' || ress[0].rate_type == 'SD_ADM' || ress[0].rate_type == 'SR' ||
      ress[0].VEND_SPEC_IND == 'Y' || ress[0].VENDOR_SPEC_IND1 == 'Y' || ress[0].VENDOR_SPEC_IND2 == 'Y' ||
      ress[0].VENDOR_SPEC_IND3 == 'Y' || ress[0].VENDOR_SPEC_IND4 == 'Y' || ress[0].VENDOR_SPEC_IND5 == 'Y' ||
      ress[0].VENDOR_SPEC_IND6 == 'Y' || ress[0].VENDOR_SPEC_IND7 == 'Y' || ress[0].VENDOR_SPEC_IND8 == 'Y' ||
      ress[0].VENDOR_SPEC_IND9 == 'Y' || ress[0].VENDOR_SPEC_IND10 == 'Y' || ress[0].VENDOR_SPEC_IND11 == 'Y' ||
      ress[0].VENDOR_SPEC_IND12 == 'Y' || ress[0].VENDOR_SPEC_IND13 == 'Y' || ress[0].VENDOR_SPEC_IND14 == 'Y' ||
      ress[0].VENDOR_SPEC_IND15 == 'Y' || ress[0].VENDOR_SPEC_IND16 == 'Y' || ress[0].VENDOR_SPEC_IND17 == 'Y' ||
      ress[0].VENDOR_SPEC_IND18 == 'Y' || ress[0].VENDOR_SPEC_IND19 == 'Y' || ress[0].VENDOR_SPEC_IND20 == 'Y'
    ) {
      return true
    } else {
      return false
    }
  }

  function chkboxEnableDisableIND(row){
    let ress = apiResp.filter((obj)=> obj.required_ind == row.txtcolReqIndArr)

    if (ress[0].required_ind == 'Y' ) {
      return true
    } else {
      return false
    }
  }

  function checkIfAtleastOneTrue(row) {
    let check = chkboxEnableDisableIND(row) || row.txtcolRateTypeArr != 'ENTRY' || btnShowHideIND(row)
    return check
  }
  function setStyles(row){
    let style = 
    btnShowHideIND(row) && chkboxEnableDisableIND(row) && row.txtcolRateTypeArr != 'ENTRY'  ?
    {
      backgroundColor : 'lightgray',
      "input[type='checkbox']": {
        pointerEvents: "none",
      },
      "input[type='text']": {
        pointerEvents: "none",
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
      button: {
        display: "none"
      },
    } : chkboxEnableDisableIND(row) && row.txtcolRateTypeArr != 'ENTRY' ?
      {
        backgroundColor : 'lightgray',
        "input[type='checkbox']": {
          pointerEvents: "none",
        },
        "input[type='text']": {
          pointerEvents: "none",
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
        },
      }
      : row.txtcolRateTypeArr != 'ENTRY' && btnShowHideIND(row) ?
        {
          "input[type='text']": {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
          },
          button: {
            display: "none"
          },
        } : btnShowHideIND(row) && chkboxEnableDisableIND(row) ?
        {
          backgroundColor : 'lightgray',
          "input[type='checkbox']": {
            pointerEvents: "none",
          },
          button: {
            display: "none"
          },
        } : btnShowHideIND(row) ? {
          button: {
            display: "none"
          },
        } :chkboxEnableDisableIND(row) ? {
          backgroundColor : 'lightgray',
          "input[type='checkbox']": {
            pointerEvents: "none",
          },
        } : row.txtcolRateTypeArr != 'ENTRY'?{
          "input[type='text']": {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
          },
        } : {}
        return style
  }

  const onchkboxcolChange = (event) => {
    let nameEvent = event.target.name
    let arr = nameEvent.split(".")[1]
    let val = getGridValue(thisObj, 'gridPremDed', 'txtcolPurchsAmt', arr)
    let vala = getGridValue(thisObj, 'gridPremDed', 'chkboxcol', arr)
    // setGridValue(thisObj,'gridPremDed', 'chkboxcol', 3, true )

    if (parntScreenName = 'FRMTRADESETTLEPROFILE') {
      if (getGridValue(thisObj, 'gridPremDed', 'chkboxcol', arr) == true) {
        samountArr[arr] = getGridValue(thisObj, 'gridPremDed', 'txtcolPurchsAmt', arr)
      }
      Calnetvalue()
    }
    
  }
  thisObj.onchkboxcolChange = onchkboxcolChange;

  const ontxtcolPurchsAmtBlur = (event) => {
    let nameEvent = event.target.name
    let arr = nameEvent.split(".")[1]
    let editedValue = GetPremiumDeductSign(event.target.value, arr)
    setGridValue(_kaledo.thisObj, 'gridPremDed', 'txtcolPurchsAmt', arr, editedValue)
    
    let pctArrayData = getData(thisObj, 'pctArrayAfterVendorClose') == null ? getData(thisObj, 'pctArr') : getData(thisObj, 'amtArrayAfterVendorClose')
    let amtArrayAfterEditedValue = getData(thisObj, 'amtArrayAfterVendorClose') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'amtArrayAfterVendorClose')
    let psArrayData = getData(thisObj, 'psArr')
    let dVal = 0
    for (let i = 0; i < 20; i++) {
      if (psArrayData[arr][i] == 'P') {
        dVal = (Number(editedValue) * (Number(pctArrayData[arr][i]) * 0.01)).toFixed(2)
        amtArrayAfterEditedValue[arr][i] = dVal
      }
    }
    setData(thisObj, 'amtArrayAfterEditedValue', amtArrayAfterEditedValue)

    _kaledo.thisObj.setState({
      ...thisObj.state,
      gridPremDed: {
        ...state.gridPremDed,
        toggleRender: !state.gridPremDed.toggleRender
      }
    })
    // setGridValue(_kaledo.thisObj, 'gridPremDed', 'txtcolPurchsAmt', arr, -1223 )
    // For n = 0 To 19
    //         If UCase(Trim(vsfData.TextMatrix(Row, 92 + n))) = "P" Then vsfData.TextMatrix(Row, 25 + n) = CDbl(vsfData.TextMatrix(Row, Col)) * (CDbl(vsfData.TextMatrix(Row, 45 + n)) * 0.01)
    //     Next n


  }
  thisObj.ontxtcolPurchsAmtBlur = ontxtcolPurchsAmtBlur;

  const Calnetvalue = () => {
    let rowsCount = getData(thisObj, 'rowsCountPDT')
    setValue(thisObj, 'txtNetProceeds', Number(lblPurchValueofSeg).toFixed(2));
    let netProVal = Number(lblPurchValueofSeg)
    for (let i = 0; i < rowsCount ; i++){
      if (_kaledo.thisObj.values['gridPremDed'].length > 0) {
        if (getGridValue(_kaledo.thisObj, 'gridPremDed', 'chkboxcol', i) == true) {
          let pval = getGridValue(_kaledo.thisObj, 'gridPremDed', 'txtcolPurchsAmt', i)
          netProVal = netProVal + Number(pval)
        }    
      }
    }
    setValue(thisObj, 'txtNetProceeds', netProVal.toFixed(2));
  }


  const ReadPremiumsDeductionsTS = async () => {
    setLoading(true)
    // hideColumn(thisObj, 'gridPremDed', 'txtcolStrgAmt')
    // hideColumn(thisObj, 'gridPremDed', 'txtcolPdCode')
    // hideColumn(thisObj, 'gridPremDed', 'txtcolReqIndArr')
    // hideColumn(thisObj, 'gridPremDed', 'txtcolRateTypeArr')
    // frmPremiumDeductionsTrade.ReadPremiumsDeductionsTS txtTradeInspNum.Text, Trim(Left(Trim(cboLocation.Text),
    // InStr(Trim(cboLocation.Text), "-") - 2)), "N", Trim(lblPurchValueOfSeg), sApplContractList,
    //  sAppliedList, sPVendorList,
    // dblNetWtP, dblLSKWtP, dblGrossWtP, 0, sTentContractList

    if (parntScreenName == 'FRMTRADESETTLEPROFILE') {
      setValue(thisObj, 'txtSegValue', Number(lblPurchValueofSeg).toFixed(2))
      // Calnetvalue()
    }

    // sCureCleanCosts = LstrCureCleanCosts

    // lblInspNum.Caption = LstrInspNum
    // lblBuyPtId.Caption = LstrBuyPtID
    // lblPSEGValue.Caption = LdblSegValue_P
    // lblSSEGValue.Caption = LdblSegValue_S

    if (parntScreenName == 'FRMTRADESETTLEPROFILE') {
      tradeFlagInd = 'T'
    } else {
      tradeFlagInd = 'F'
    }

    for (let i = 0; i <= 19; i++) {
      if (parntScreenName != 'FRMTRADESETTLEPROFILE') {
        //if (true) {     // If marrVendorInfo(5, n) <> "" Then
          //if ('P' == 'P') {    //  If marrVendorInfo(5, n) = "P" Then
        //     segValPArr.push(i)
        //   } else {
        //     segValPArr.push(0)
        //   }
        // } else {
        //   segValPArr.push(0)
        // }
      } else {
        if (lblhttpsData[`PURH_STOR_IND${i+1}`] != '') {      // If marrVendorInfo(5, n) <> "" Then
          segValPArr[i] = prntDataObj.Vendordetails[i] != undefined ? prntDataObj.Vendordetails[i].value_of_seg : 0
        } else {
          segValPArr[i] = 0
        }
      }
    }


    // https://apimaznappstst02.asemazeus2appdev01.appserviceenvironment.net/api/v1.1/Settlement/RetrieveTradePremiumDeductionScheduleDetails/
    // companies/B9/crop-years/2022/inspections/S000005/buying-points/1CY/indicators/Y/schedules
    apiResp = await SettlementService.RetrieveTradePremiumDeductionScheduleDetails(
      LstrInspNum,
      LstrBuyPtID,
      LstrInspectInd,
      LdblSegValue_P,
      LstrContract_P,
      LstrApplyLBS_P,
      LstrVendors_P,
      LdblNET_WT_P,
      LdblLSK_WT_P,
      LdblGROSS_WT_P,
      LdblSegValue_S,
      LstrContract_S,
      LstrApplyLBS_S,
      LstrVendors_S,
      LdblNET_WT_S,
      LdblLSK_WT_S,
      LdblGROSS_WT_S,
      LdblBASIS_GRADE_AMT_P,
      LdblBASIS_GRADE_AMT_S,
      LstrPDcodes_cure_clean_costs,
      dblSegLessOptPurch,
      segValPArr[0],
      segValPArr[1],
      segValPArr[2],
      segValPArr[3],
      segValPArr[4],
      segValPArr[5],
      segValPArr[6],
      segValPArr[7],
      segValPArr[8],
      segValPArr[9],
      segValPArr[10],
      segValPArr[11],
      segValPArr[12],
      segValPArr[13],
      segValPArr[14],
      segValPArr[15],
      segValPArr[16],
      segValPArr[17],
      segValPArr[18],
      segValPArr[19],
      )

    setData(thisObj, 'rowsCountPDT', apiResp.length)


    if (apiResp.length > 0 && apiResp != undefined) {
      
      let jsVP = []
      let obj = {}
      for (let i = 0; i < apiResp.length; i++) {
        obj.txtcolPremDeduct = apiResp[i].pd_desc
        obj.txtcolPurchsAmt = apiResp[i].pamount
        obj.txtcolStrgAmt = apiResp[i].samount
        // obj.btnCol = apiResp[i].required_ind == 'Y' ? 'true' : 'false'
        obj.txtcolPdCode = apiResp[i].pd_code
        obj.txtcolReqIndArr = apiResp[i].required_ind
        obj.txtcolRateTypeArr = apiResp[i].rate_type
        obj.chkboxcol = false
        
        jsVP.push(obj)
        obj = {}
      }
      setValue(thisObj, 'gridPremDed', jsVP)
      setData(thisObj, 'apiResponse', apiResp)
      setLoading(false)
    } else {
      setLoading(false)
      setValue(thisObj, 'gridPremDed', [])
      setData(thisObj, 'apiResponse', apiResp)
    }





    for (let i = 0; i < apiResp.length; i++) {
      lblVendorArr.push(['','','','','','','','','','','','','','','','','','','','',]) 
      
      samountArr.push(apiResp[i].samount)//4

      vendorArr.push( // 5-24
        [
          apiResp[i].vendor1, apiResp[i].vendor2, apiResp[i].vendor3, apiResp[i].vendor4, apiResp[i].vendor5,
          apiResp[i].vendor6, apiResp[i].vendor7, apiResp[i].vendor8, apiResp[i].vendor9, apiResp[i].vendor10,
          apiResp[i].vendor11, apiResp[i].vendor12, apiResp[i].vendor13, apiResp[i].vendor14, apiResp[i].vendor15,
          apiResp[i].vendor16, apiResp[i].vendor17, apiResp[i].vendor18, apiResp[i].vendor19, apiResp[i].vendor20
        ]
      )
      amtArr.push( //25-44
        [
          apiResp[i].amt1, apiResp[i].amt2, apiResp[i].amt3, apiResp[i].amt4, apiResp[i].amt5,
          apiResp[i].amt6, apiResp[i].amt7, apiResp[i].amt8, apiResp[i].amt9, apiResp[i].amt10,
          apiResp[i].amt11, apiResp[i].amt12, apiResp[i].amt13, apiResp[i].amt14, apiResp[i].amt15,
          apiResp[i].amt16, apiResp[i].amt17, apiResp[i].amt18, apiResp[i].amt19, apiResp[i].amt20
        ]
      )
      pctArr.push( // 45-64
        [
          apiResp[i].pct1, apiResp[i].pct2, apiResp[i].pct3, apiResp[i].pct4, apiResp[i].pct5,
          apiResp[i].pct6, apiResp[i].pct7, apiResp[i].pct8, apiResp[i].pct9, apiResp[i].pct10,
          apiResp[i].pct11, apiResp[i].pct12, apiResp[i].pct13, apiResp[i].pct14, apiResp[i].pct15,
          apiResp[i].pct16, apiResp[i].pct17, apiResp[i].pct18, apiResp[i].pct19, apiResp[i].pct20
        ]
      )

      pdCodeArr.push([apiResp[i].pd_code]) //65
      pdIndArr.push([apiResp[i].pd_ind])   //66
      rateTypeArr.push([apiResp[i].rate_type])  //67
      autoIndCodArr.push([apiResp[i].auto_ind])  //68
      inspectIndArr.push([apiResp[i].inspect_ind])  //69
      requiredIndArr.push([apiResp[i].required_ind])  //70
      beforeRecvIndArr.push([apiResp[i].before_recv_ind])  //71

      psArr.push( //92-110
        [
          apiResp[i].ps1, apiResp[i].ps2, apiResp[i].ps3, apiResp[i].ps4, apiResp[i].ps5,
          apiResp[i].ps6, apiResp[i].ps7, apiResp[i].ps8, apiResp[i].ps9, apiResp[i].ps10,
          apiResp[i].ps11, apiResp[i].ps12, apiResp[i].ps13, apiResp[i].ps14, apiResp[i].ps15,
          apiResp[i].ps16, apiResp[i].ps17, apiResp[i].ps18, apiResp[i].ps19, apiResp[i].ps20
        ]
      )
      if (apiResp[i].required_ind == 'Y') {
      
        if (_kaledo.thisObj.values['gridPremDed'].length > 0) {
          setGridValue(_kaledo.thisObj, 'gridPremDed', 'chkboxcol', i, true)
        }
      }
      
      if (apiResp[i].auto_ind == 'Y') {
        if (_kaledo.thisObj.values['gridPremDed'].length > 0) {
          setGridValue(_kaledo.thisObj, 'gridPremDed', 'chkboxcol', i, true)
        }
      }

      if (apiResp[i].rate_type == 'ENTRY') {

        //     For nn = 0 To 19-----------to do---------
        //     If (vsfData.TextMatrix(vsfData.Rows - 1, 92 + nn) = "P" And CDbl(vsfData.TextMatrix(vsfData.Rows - 1, 45 + nn)) <> 0) Then
        //         vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 3) = &HFFFF80
        //         vsfData.TextMatrix(vsfData.Rows - 1, 3) = GetInside(lstrxml, "<base_rate>", "</base_rate>")
        //     End If
        // Next nn
        
        for (let j = 0; j < 20; j++) {
          if (psArr[i][j] == 'P' && pctArr[i][j] != 0 || pctArr[i][j] != '0') {
            if (_kaledo.thisObj.values['gridPremDed'].length > 0) {
              setGridValue(_kaledo.thisObj, 'gridPremDed', 'txtcolPurchsAmt', i, apiResp[i].base_rate)
            }
          }
        }

        // for (let j = 0; j < 20; j++) {
        //   if (psArr[i][j] == 'P' && pctArr[i][j] != 0 || pctArr[i][j] != '0') {
        //     obj.txtcolPurchsAmt = apiResp[i].required_ind
        //   }
        // }

        // For nn = 0 To 19
        //     If (vsfData.TextMatrix(vsfData.Rows - 1, 92 + nn) = "S" And CDbl(vsfData.TextMatrix(vsfData.Rows - 1, 45 + nn)) <> 0) Then
        //         vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 4) = &HFFFF80
        //         vsfData.TextMatrix(vsfData.Rows - 1, 4) = GetInside(lstrxml, "<base_rate>", "</base_rate>")
        //     End If
        // Next nn

      }
      venDSpecIndArr.push([apiResp[i].VEND_SPEC_IND]) //112

      vendorSpecIndArr.push(
        [
          apiResp[i].vendoR_SPEC_IND1, apiResp[i].vendoR_SPEC_IND2, apiResp[i].vendoR_SPEC_IND3, apiResp[i].vendoR_SPEC_IND4, apiResp[i].vendoR_SPEC_IND5,
          apiResp[i].vendoR_SPEC_IND6, apiResp[i].vendoR_SPEC_IND7, apiResp[i].vendoR_SPEC_IND8, apiResp[i].vendoR_SPEC_IND9, apiResp[i].vendoR_SPEC_IND10,
          apiResp[i].vendoR_SPEC_IND11, apiResp[i].vendoR_SPEC_IND12, apiResp[i].vendoR_SPEC_IND13, apiResp[i].vendoR_SPEC_IND14, apiResp[i].vendoR_SPEC_IND15,
          apiResp[i].vendoR_SPEC_IND16, apiResp[i].vendoR_SPEC_IND17, apiResp[i].vendoR_SPEC_IND18, apiResp[i].vendoR_SPEC_IND19, apiResp[i].vendoR_SPEC_IND20
        ]
      )

      if (parntScreenName == 'FRMTRADESETTLEPROFILE') {
        for (let j = 0; j < 20; j++) {          
          for(let k=0; k<prntDataObj.Vendordetails.length ; k++ ){
            if (vendorArr[i][j].toUpperCase() == prntDataObj.Vendordetails[k].split_vendor.toUpperCase()) {
              lblVendorArr[i][j] = prntDataObj.Vendordetails[k].vendor_name
            }
          }
        }
      }
    }
    setData(thisObj, 'VendorArr', vendorArr)
    setData(thisObj, 'pdCodeArr', pdCodeArr)
    setData(thisObj, 'pctArr', pctArr)
    setData(thisObj, 'amtArr', amtArr)
    setData(thisObj, 'rateTypeArr', rateTypeArr)
    setData(thisObj, 'psArr', psArr)
    setData(thisObj, 'pdIndArrDD', pdIndArr)
    setData(thisObj, 'lblVendorArrVenBtn', lblVendorArr)

      Calnetvalue()
      setLoading(false)
  }

 

  const GetPremiumDeductSign = (strValue, intRow) => {
    let pdIndArray = getData(thisObj, 'pdIndArrDD')
    let strPermDeduct
    if (strValue == '0.00' || strValue == '0') {
      return parseFloat(strValue)
    }

    if (strValue == '') {
      return '0'
    } else if (isNaN(strValue)){
      return '0'
    }

    strPermDeduct = pdIndArray[intRow][0]
    switch (strPermDeduct) {
      case 'D':
        if (strValue.slice(0,1) == '-') {
          return parseFloat(strValue)
        } else {
          if (strValue.slice(0,1) == '+') {
            return parseFloat(strValue)
          } else {
            return '-' + strValue
          }
        }
        break;
      case "P":
        return parseFloat(strValue)
        break;
      default:
        break;
    }
  }

  const onbtnColClick = (event) => {
    // try {

      let rowSelected = event.target.parentElement.parentElement.parentElement
      let rowID = rowSelected.id.substring(4, 5);
      setData(thisObj, 'PDTrowID')
      let valueGrid = getValue(thisObj, "gridPremDed")[rowID]
      let amtArrConditional = getData(thisObj, 'amtArrayAfterEditedValue') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'amtArrayAfterEditedValue')
      let apiResp = getData(thisObj, 'apiResponse')
      let pctArrVenBtn = getData(thisObj, 'pctArrayAfterVendorClose') == null ? getData(thisObj, 'pctArr') : getData(thisObj, 'pctArrayAfterVendorClose')
      let psArrVenBtn = getData(thisObj, 'psArr')
      let vendorArrVenBtn = getData(thisObj, 'VendorArr')
      let lblVendorArrVenBtn = getData(thisObj, 'lblVendorArrVenBtn')

    let purchaseData = []
    let storageData = []

      let objjj1 = {}
      let objjj2 = {}

      if (apiResp.length > 0 && parseInt(valueGrid.txtcolPurchsAmt) != 0 || parseInt(valueGrid.txtcolStrgAmt) != 0) { // need to add one more condition (vendor split button)
        
          for (let i = 0; i < 20; i++) {
            if (psArrVenBtn[rowID][i] == 'P' && parseInt(pctArrVenBtn[rowID][i]) != 0) {
              objjj1.vendor = `${vendorArrVenBtn[rowID][i]}-${lblVendorArrVenBtn[rowID][i]}`
              objjj1.sharePct = pctArrVenBtn[rowID][i]
              objjj1.shareAmt = amtArrConditional[rowID][i]
              purchaseData.push(objjj1)
              objjj1 = {}
            } else {
              objjj1.vendor = ''
              objjj1.sharePct = ''
              objjj1.shareAmt = ''
              purchaseData.push(objjj1)
              objjj1 = {}
            }
          }
        
          for (let i = 0; i < 20; i++) {
            if (psArrVenBtn[rowID][i] == 'S' && parseInt(pctArrVenBtn[rowID][i]) != 0) {
              objjj2.vendor = `${vendorArrVenBtn[rowID][i]}-${lblVendorArrVenBtn[rowID][i]}`
              objjj2.sharePct = pctArrVenBtn[rowID][i]
              objjj2.shareAmt = amtArrConditional[rowID][i] 
              storageData.push(objjj2)
              objjj2 = {}
            } else {
              objjj2.vendor = ''
              objjj2.sharePct = ''
              objjj2.shareAmt = ''
              storageData.push(objjj2)
              objjj2 = {}
            }
          }

          setData(thisObj, 'VPTData-PtotalAmt', valueGrid.txtcolPurchsAmt)
          setData(thisObj, 'VPTData-StotalAmt', valueGrid.txtcolStrgAmt)

          setData(thisObj, 'VPTData-purchaseData', purchaseData)
          setData(thisObj, 'VPTData-storeageData', storageData)

          if (apiResp[rowID].required_ind == 'Y') {
            setData(thisObj, 'VPTData-cmdOk.Tag', 'false')
            setData(thisObj, 'VPTData-cmdOk.enabled', false)
          } else {
            setData(thisObj, 'VPTData-cmdOk.Tag', 'true')
          }
          // frmPremiumDeductionsVendorSplitsTrade.cmdOk.Tag = "True"
          // If vsfData.Cell(flexcpBackColor, vsfData.Row, 2) = &HE0E0E0 Then frmPremiumDeductionsVendorSplitsTrade.cmdOk.enabled = False: frmPremiumDeductionsVendorSplitsTrade.cmdOk.Tag = "False"

          //   For n = 0 To 19 
          //     If Trim(frmPremiumDeductionsVendorSplitsTrade.txtPPCT(n).Text) <> "" Then frmPremiumDeductionsVendorSplitsTrade.txtPPCT(n).Text = FormatNumber(CDbl(frmPremiumDeductionsVendorSplitsTrade.txtPPCT(n).Text), 2)
          // Next n
          // For n = 0 To 19
          //     If Trim(frmPremiumDeductionsVendorSplitsTrade.txtSPCT(n).Text) <> "" Then frmPremiumDeductionsVendorSplitsTrade.txtSPCT(n).Text = FormatNumber(CDbl(frmPremiumDeductionsVendorSplitsTrade.txtSPCT(n).Text), 2)
          // Next n

          goTo(thisObj, "Settlements#PremiumDeductionsVendorSplitsTrade#DEFAULT#true#Click")
          return

        }
        
        if (parseInt(valueGrid.txtcolPurchsAmt) == 0 && parseInt(valueGrid.txtcolStrgAmt) == 0) {
          showMessage(thisObj, 'The vendor splits for this Premium / Deduction can not be changed because it has no value!!!", 48, "Cannot Change Vendor Splits!!!', false)
          return
        }
      
    // } catch (err) {
    //   if (err instanceof EvalError) {
    //     showMessage(thisObj, err.message);
    //   } else {
    //     showMessage(
    //       thisObj,
    //       "Error occurred during btnCol click event"
    //     );
    //   }
    //   return false;
    // }
  }
  thisObj.onbtnColClick = onbtnColClick;

  const onbtnCompleteClick = async () => {

    let responseData = getData(thisObj, 'apiResponse') == null ? [] : getData(thisObj, 'apiResponse')
    let vendorArrayData = getData(thisObj, 'VendorArr')
    let pdCodeArrayData = getData(thisObj, 'pdCodeArr')
    let pctArrayData = getData(thisObj, 'pctArrayAfterVendorClose') == null ? getData(thisObj, 'pctArr') : getData(thisObj, 'pctArrayAfterVendorClose')
    let amtArrayData
    if (getData(thisObj, 'amtArrayAfterEditedValue') == null) {
      if (getData(thisObj, 'amtArrayAfterVendorClose') == null) {
        amtArrayData = getData(thisObj, 'amtArr')
      } else {
        amtArrayData = getData(thisObj, 'amtArrayAfterVendorClose')
      }
    } else {
      amtArrayData = getData(thisObj, 'amtArrayAfterEditedValue')
    }
    // let amtArrayData = getData(thisObj, 'amtArrayAfterEditedValue') == null ? getData(thisObj, 'amtArrayAfterVendorClose') == null ? getData(thisObj, 'amtArr') : setData(thisObj, 'amtArrayAfterVendorClose')
    let rateTypeArrayData = getData(thisObj, 'rateTypeArr')
    let psArrayData = getData(thisObj, 'psArr')
    let pdIndArrData = getData(thisObj, 'pdIndArrDD')
    let lblhttps = getData(thisObj, 'lblhttps')
    let lblhttpsIDT = getData(thisObj, 'lblhttpsidt')
    let pData = getData(thisObj, "ReadPremiumsDeductionsTS")

    let LdblPPremiums
    let LdblSPremiums
    let LdblPDeductions
    let LdblSDeductions
    let Strtrade1007
    let LdblP = []
    let LdblD = []
    let x_vendor = 0
    let nn = 0
    let s = {}
    let sArr = []
    let oneMoreArr = []
    let httpObj = {};
    let oneMoreArrIDT = []
    let httpObjIDT = {};

    let LdblPPctTotal
    let np = []
    let nd = []
    // let LdblSPctTotal
    let LdblPPct = []
    let numOfVendors = pData.sPVendorListcount
    let parntScrName = "FRMTRADESETTLEPROFILE"

    for (let i = 0; i < 20; i++) {
      LdblP[i] = 0
    }
    for (let i = 0; i < 20; i++) {
      LdblD[i] = 0
    }

    LdblPPremiums = 0
    LdblSPremiums = 0
    LdblPDeductions = 0
    LdblSDeductions = 0

    if (parntScrName = "FRMTRADESETTLEPROFILE") {
      httpObj[`settlement_date`] = lblhttps.SETTLEMENT_DATE
      httpObj[`purch_sale_ind`] = lblhttps.PURCH_SALE_IND
      httpObj[`spot_price`] = lblhttps.SPOT_PRICE == undefined ? 0 : lblhttps.SPOT_PRICE
      httpObj[`spot_pounds`] = lblhttps.SPOT_POUNDS == undefined ? 0 : lblhttps.SPOT_POUNDS
      httpObj[`overide_farm`] = ''
      httpObj[`user_id`] = sessionStorage.getItem("userid")
      //httpObj[`remarks`] = lblhttps.REMARKS == 0 ? '' : undefined ? '' : lblhttps.REMARKS
      // FIX FOR PRB0053857
      httpObj[`remarks`] = lblhttps.REMARKS || '';
      httpObj[`appl_count`] = lblhttps.APPL_COUNT == undefined ? 0 : lblhttps.APPL_COUNT
      httpObj[`idt_comp_id`] = lblhttps.IDT_COMP_ID

      for (let i = 1; i <= numOfVendors; i++) {

        httpObj[`split_vendor${i}`] = lblhttps[`SPLIT_VENDOR${i}`]
        httpObj[`split_remit${i}`] = lblhttps[`SPLIT_REMIT${i}`]
        httpObj[`purh_stor_ind${i}`] = lblhttps[`PURH_STOR_IND${i}`]
        httpObj[`gross_wt${i}`] = lblhttps[`GROSS_WT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttps[`GROSS_WT${i}`])
        httpObj[`net_wt${i}`] = lblhttps[`NET_WT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttps[`NET_WT${i}`])
        httpObj[`lsk_wt${i}`] = lblhttps[`LSK_WT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttps[`LSK_WT${i}`])
        httpObj[`basis_grade_amt${i}`] = lblhttps[`BASIS_GRADE_AMT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttps[`BASIS_GRADE_AMT${i}`])
        httpObj[`value_per_ton${i}`] = lblhttps[`VALUE_PER_TON${i}`] == undefined ? 0 : Number(lblhttps[`VALUE_PER_TON${i}`])
        httpObj[`support_pct${i}`] = lblhttps[`SUPPORT_PCT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttps[`SUPPORT_PCT${i}`])
        httpObj[`value_of_seg${i}`] = lblhttps[`VALUE_OF_SEG${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttps[`VALUE_OF_SEG${i}`])

      }

      for (let i = 1; i <= lblhttps.APPL_COUNT; i++) {
        let oneMoreObj = {}
        oneMoreObj[`appl_contract_num`] = lblhttps[`APPL_CONTRACT_NUM${i}`] == '' ? '0' : undefined ? '0' : lblhttps[`APPL_CONTRACT_NUM${i}`].toString()
        oneMoreObj[`agree_value`] = lblhttps[`AGREE_VALUE${i}`] == undefined ? '0' : '' ? '0' : lblhttps[`AGREE_VALUE${i}`].toString()
        oneMoreObj[`appl_lbs_adj`] = lblhttps[`APPL_LBS_ADJ${i}`] == '' ? '0' : undefined ? '0' : lblhttps[`APPL_LBS_ADJ${i}`].toString()
        oneMoreArr.push(oneMoreObj)
      }
      httpObj['cas_master'] = oneMoreArr

    }

    if (lblhttpsIDT != undefined && lblhttpsIDT != null && lblhttpsIDT != '') {

      httpObjIDT[`settlement_date`] = lblhttpsIDT.SETTLEMENT_DATE
      httpObjIDT[`purch_sale_ind`] = lblhttpsIDT.PURCH_SALE_IND
      httpObjIDT[`spot_price`] = lblhttpsIDT.SPOT_PRICE == undefined ? 0 : lblhttpsIDT.SPOT_PRICE
      httpObjIDT[`spot_pounds`] = lblhttpsIDT.SPOT_POUNDS == undefined ? 0 : lblhttpsIDT.SPOT_POUNDS
      httpObjIDT[`overide_farm`] = ''
      httpObjIDT[`user_id`] = sessionStorage.getItem("userid")
      httpObjIDT[`remarks`] = lblhttpsIDT.REMARKS == 0 ? '' : undefined ? '' : lblhttpsIDT.REMARKS
      httpObjIDT[`appl_count`] = lblhttpsIDT.APPL_COUNT == undefined ? 0 : lblhttpsIDT.APPL_COUNT
      httpObjIDT[`idt_comp_id`] = lblhttpsIDT.IDT_COMP_ID

      for (let i = 1; i <= numOfVendors; i++) {

        httpObjIDT[`split_vendor${i}`] = lblhttpsIDT[`SPLIT_VENDOR${i}`]
        httpObjIDT[`split_remit${i}`] = lblhttpsIDT[`SPLIT_REMIT${i}`]
        httpObjIDT[`purh_stor_ind${i}`] = lblhttpsIDT[`PURH_STOR_IND${i}`]
        httpObjIDT[`gross_wt${i}`] = lblhttpsIDT[`GROSS_WT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttpsIDT[`GROSS_WT${i}`])
        httpObjIDT[`net_wt${i}`] = lblhttpsIDT[`NET_WT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttpsIDT[`NET_WT${i}`])
        httpObjIDT[`lsk_wt${i}`] = lblhttpsIDT[`LSK_WT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttpsIDT[`LSK_WT${i}`])
        httpObjIDT[`basis_grade_amt${i}`] = lblhttpsIDT[`BASIS_GRADE_AMT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttpsIDT[`BASIS_GRADE_AMT${i}`])
        httpObjIDT[`value_per_ton${i}`] = lblhttpsIDT[`VALUE_PER_TON${i}`] == undefined ? 0 : Number(lblhttpsIDT[`VALUE_PER_TON${i}`])
        httpObjIDT[`support_pct${i}`] = lblhttpsIDT[`SUPPORT_PCT${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttpsIDT[`SUPPORT_PCT${i}`])
        httpObjIDT[`value_of_seg${i}`] = lblhttpsIDT[`VALUE_OF_SEG${i}`] == '' ? 0 : undefined ? 0 : Number(lblhttpsIDT[`VALUE_OF_SEG${i}`])

      }

      for (let i = 1; i <= lblhttpsIDT.APPL_COUNT; i++) {
        let oneMoreObjIDT = {}
        oneMoreObjIDT[`appl_contract_num`] = lblhttpsIDT[`APPL_CONTRACT_NUM${i}`] == '' ? '0' : undefined ? '0' : lblhttpsIDT[`APPL_CONTRACT_NUM${i}`]
        oneMoreObjIDT[`agree_value`] = lblhttpsIDT[`AGREE_VALUE${i}`] == '' ? '0' : undefined ? '0' : lblhttpsIDT[`AGREE_VALUE${i}`]
        oneMoreObjIDT[`appl_lbs_adj`] = lblhttpsIDT[`APPL_LBS_ADJ${i}`] == '' ? '0' : undefined ? '0' : lblhttpsIDT[`APPL_LBS_ADJ${i}`]
        oneMoreArrIDT.push(oneMoreObjIDT)
      }
      httpObjIDT['cas_master'] = oneMoreArrIDT

    }

    for (let i = 0; i <= prntDataObj.Vendordetails.length - 1; i++) {
      // rowSelected need value from grid pending
      if (prntDataObj.Vendordetails[i].split_vendor != '') {
        x_vendor = x_vendor + 1;
        for (let j = 0; j < responseData.length; j++) {
          if (getGridValue(_kaledo.thisObj, 'gridPremDed', 'chkboxcol', j) == true) {
            if (vendorArrayData[j][i] != '') {
              let sObj = {}
              nn = nn + 1
              sObj[`pd_split_vendor`] = vendorArrayData[j][i] == 0 ? '' : undefined ? '' : vendorArrayData[j][i]
              sObj[`pd_split_remit`] = '000'
              sObj[`pd_code`] = pdCodeArrayData[j][0] == 0 ? '' : undefined ? '' : pdCodeArrayData[j][0]
              sObj[`pd_split_share_pct`] = pctArrayData[j][i] == 0 ? '' : undefined ? '' : pctArrayData[j][i]
              sObj[`pd_amount`] = amtArrayData[j][i] == 0 ? '' : undefined ? '' : amtArrayData[j][i]
              sObj[`pd_pounds`] = '0'
              sObj[`pd_payee_vendor`] = ''
              sObj[`pd_payee_remit`] = ''
              sObj[`pd_rate_type`] = rateTypeArrayData[j][i] == 0 ? '' : undefined ? '' : rateTypeArrayData[j][i]
              sObj[`pd_ps`] = psArrayData[j][i] == 0 ? '' : undefined ? '' : psArrayData[j][i]
              sArr.push(sObj)
              if (pdIndArrData[j] == "P" && psArrayData[j][i] == "P") {
                LdblPPremiums = LdblPPremiums + Number(amtArrayData[j][i])
                LdblP[i] = LdblP[i] + Number(amtArrayData[j][i])
              }
              if (pdIndArrData[j] == "P" && psArrayData[j][i] == "S") {
                LdblPPremiums = LdblPPremiums + Number(amtArrayData[j][i])
                LdblP[i] = LdblP[i] + Number(amtArrayData[j][i])
              }
              if (pdIndArrData[j] == "D" && psArrayData[j][i] == "P") {
                LdblPDeductions = LdblPDeductions + Number(amtArrayData[j][i])
                LdblD[i] = LdblD[i] + Number(amtArrayData[j][i])
              }
              if (pdIndArrData[j] == "D" && psArrayData[j][i] == "S") {
                LdblPDeductions = LdblPDeductions + Number(amtArrayData[j][i])
                LdblD[i] = LdblD[i] + Number(amtArrayData[j][i])
              }
            }
          }
        }
      }
    }
    s['pds_master'] = sArr

    switch (parntScrName) {
      case "FRMTRADESETTLEPROFILE":

        LdblPPctTotal = 0
        for (let i = 0; i < x_vendor; i++) {
          LdblPPctTotal = LdblPPctTotal + Number(prntDataObj.Vendordetails[i].split_share_pct)
        }
        // LdblSPctTotal = 0

        if (LdblPPctTotal > 0) {
          for (let i = 0; i < x_vendor; i++) {
            LdblPPct[i] = Number(prntDataObj.Vendordetails[i].split_share_pct * 100) / LdblPPctTotal
          }
        }

        // if (LdblSPctTotal > 0) {
        //   for (let i = 1; i <= x_vendor; i++) {
        //     LdblSPct[i] = 0
        //   }
        // }

        for (let i = 0; i <= x_vendor - 1; i++) {
          for (let j = 0; j <= x_vendor - 1; j++) {
            if (responseData.length > 0) {
              // If UCase(Trim(vsfData.TextMatrix(vsfData.Rows - 1, 5 + nnnn))) = UCase(Trim(frmTradeSettleProfile.cboVendor(nnn).Text)) Then np(nnn + 1) = LdblP(nnnn + 1): nd(nnn + 1) = LdblD(nnnn + 1)
              if (vendorArrayData[i][j].toUpperCase() == prntDataObj.Vendordetails[i].split_vendor.toUpperCase()) {
                np[i] = LdblP[j]
                nd[i] = LdblD[j]
              }
            }
          }
        }

        for (let i = 0; i < x_vendor; i++) {
          if (responseData.length > 0) {
            LdblP[i] = np[i]
            LdblD[i] = nd[i]
          }
        }

        for (let i = 0; i < x_vendor; i++) {
          s[`premium_amt${i + 1}`] = Number(LdblP[i].toFixed(2))
          s[`deduct_amt${i + 1}`] = Number(LdblD[i].toFixed(2))
        }

        for (let i = 0; i < x_vendor; i++) {
          let marVendor = prntDataObj.Vendordetails[i].value_of_seg == '' ? 0 : undefined ? 0 : parseFloat(prntDataObj.Vendordetails[i].value_of_seg)
          let proceeds_val = (parseFloat(LdblD[i])) + (parseFloat(LdblP[i])) + marVendor
          s[`proceeds_amt${i + 1}`] = Number(proceeds_val.toFixed(2))
        }

        s[`prem_deduct_count`] = nn

        let frmTradeSettleProfileObj = {
          "lblPurchPremiums": LdblPPremiums,
          "lblPurchDeductions": LdblPDeductions,
          "lblPurchProceeds": LdblPDeductions + LdblPPremiums,
        }
        setData(thisObj, 'TradeSettleProfileObj', frmTradeSettleProfileObj)

        let dataObj = { ...httpObj, ...s }
        let dataObjIDT = { ...httpObjIDT, ...s }

        let trade_category = prntDataObj.sHeader[0].trade_category;
        let buying_pt_id = prntDataObj.Buying_point_id
        let tradeInsNum = readPremiumsDeductionsPrntObj.LstrInspNum
        let tradeSettleNum = prntDataObj.sHeader[0].trade_settle_num
        let tradeSettleNumPara
        let Strtrade1007
        let tradeCatApiResponse = await SettlementService.RetrieveTradeCategoryDetails(trade_category, null);
        if (tradeCatApiResponse != '' && tradeCatApiResponse != null && tradeCatApiResponse != undefined) {
          if (tradeCatApiResponse[0].generate1007Ind == "Y" && tradeSettleNum == '') {
            let buyngPtApiResponse = await SettlementService.RetrieveBuyingPointControlDetails('PN1140', null, null, null, buying_pt_id);
            Strtrade1007 = buyngPtApiResponse[0].next1007
          }

          if (tradeSettleNum != '') {
            tradeSettleNumPara = tradeSettleNum
          } else {
            tradeSettleNumPara = Strtrade1007
          }

          let UpdateRespo = await SettlementService.UpdateTradeSettlementComplete(buying_pt_id, tradeInsNum, tradeSettleNumPara, null, dataObj)
          if (UpdateRespo.status == 200) {
            if (trade_category.toUpperCase() == 'IDT') {
              let UpdaterespoIDT = await SettlementService.UpdateTradeSettlementComplete(buying_pt_id, tradeInsNum, tradeSettleNumPara, null, dataObjIDT)
            }
          } else {
            return
          }

          if (UpdateRespo.status == 400 || UpdateRespo.status == 500) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.', false)
            return
          }

          if (tradeCatApiResponse[0].generate1007Ind == "Y" && Strtrade1007 != '') {
            let js = [{
              Txt_Agree: tradeInsNum,
              lstBuyingPointID: buying_pt_id,
              ReportType: 'TRADE_SETTLEMENT_WORKSHEET'
            }]
            setData(thisObj, 'ReportPreviewTradedata', js)
            goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click")
          }
        }

        if (confirm("PRINT A WORKSHEET?") == true) {
          let js = [{
            Txt_Agree: tradeInsNum,
            lstBuyingPointID: buying_pt_id,
            ReportType: 'TRADE_SETTLEMENT_WORKSHEET'
          }]
          setData(thisObj, 'ReportPreviewTradedata', js)
          goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click")
        } else {
          setData(thisObj, 'CompletionStatus', true)
          document.getElementById("Settlements_PremiumDeductionTradePopUp").childNodes[0].click()
          document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click()
        }

        break;
    }
    if (getData(thisObj, 'premDeductTrade_close') == true) {
      setData(thisObj, 'CompletionStatus', true)
      document.getElementById("Settlements_PremiumDeductionTradePopUp").childNodes[0].click()
      document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click()
    }
    // setData(thisObj, 'CompletionStatus', true)
    // document.getElementById("Settlements_PremiumDeductionTradePopUp").childNodes[0].click()
    // document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click()
    // goTo(thisObj, "Settlements#TradeSettleSearch#DEFAULT#true#Click")
  }
  thisObj.onbtnCompleteClick = onbtnCompleteClick;

  

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionTrade*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionTrade*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremDed*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremDed*/}

              <GroupBoxWidget conf={state.grpbxPremDed} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblpremDed*/}

                {/* END_USER_CODE-USER_BEFORE_lblpremDed*/}

                <LabelWidget
                  values={values}
                  conf={state.lblpremDed}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblpremDed*/}

                {/* END_USER_CODE-USER_AFTER_lblpremDed*/}
                {/* START_USER_CODE-USER_BEFORE_txtSegValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtSegValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSegValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSegValue*/}

                {/* END_USER_CODE-USER_AFTER_txtSegValue*/}
                {/* START_USER_CODE-USER_BEFORE_gridPremDed*/}

                {/* END_USER_CODE-USER_BEFORE_gridPremDed*/}

                <GridWidget
                  conf={state.gridPremDed}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPremDed}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                  key = {state.gridPremDed.toggleRender}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPremDed*/}

                {/* END_USER_CODE-USER_AFTER_gridPremDed*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtNetProceeds*/}
                {/* START_USER_CODE-USER_BEFORE_lblIblhttps*/}

                {/* END_USER_CODE-USER_BEFORE_lblIblhttps*/}

                <LabelWidget
                  values={values}
                  conf={state.lblIblhttps}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblIblhttps*/}

                {/* END_USER_CODE-USER_AFTER_lblIblhttps*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremDed*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremDed*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxPremDedFooter*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremDedFooter*/}

              <GroupBoxWidget
                conf={state.grpbxPremDedFooter}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnComplete*/}

                {/* END_USER_CODE-USER_BEFORE_btnComplete*/}

                <ButtonWidget
                  conf={state.btnComplete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnComplete*/}

                {/* END_USER_CODE-USER_AFTER_btnComplete*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremDedFooter*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremDedFooter*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionTrade*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionTrade*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_PremiumDeductionTrade);
