/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ExtReceiptForfeituresCropYear from "./ExtReceiptForfeituresCropYear";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExtReceiptForfeituresCropYear Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ExtReceiptForfeituresCropYear />);
    });
  });
  afterEach(cleanup);
  test("is ExtReceiptForfeituresCropYear Loads Successfully", () => {
    expect(screen.getByText("ExtReceiptForfeituresCropYear")).toBeInTheDocument;
  });
  test("Custom Test Cases for ExtReceiptForfeituresCropYear", () => {
    // START_USER_CODE-USER_ExtReceiptForfeituresCropYear_Custom_Test_Case
    // END_USER_CODE-USER_ExtReceiptForfeituresCropYear_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ExtReceiptForfeituresCropYear />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresCropYear_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCopy(Button Widget) Test Cases", async () => {
    const btnCopy = screen.getByTestId("btnCopy");
    expect(btnCopy).toBeInTheDocument;
    expect(btnCopy.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresCropYear_btnCopy")
    );
  });
  test("Custom Test Cases for btnCopy", () => {
    // START_USER_CODE-USER_btnCopy_TEST
    // END_USER_CODE-USER_btnCopy_TEST
  });
  test("grpbxExtReceiptForfeituresCropYear(GroupBox Widget) Test Cases", async () => {
    const grpbxExtReceiptForfeituresCropYear = screen.getByTestId(
      "grpbxExtReceiptForfeituresCropYear"
    );
    expect(grpbxExtReceiptForfeituresCropYear.tagName).toBe("BUTTON");
    expect(grpbxExtReceiptForfeituresCropYear.type).toBe("button");
    expect(grpbxExtReceiptForfeituresCropYear.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxExtReceiptForfeituresCropYear", () => {
    // START_USER_CODE-USER_grpbxExtReceiptForfeituresCropYear_TEST
    // END_USER_CODE-USER_grpbxExtReceiptForfeituresCropYear_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresCropYear_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
});
