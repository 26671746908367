/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  disable,
  enable,
  setValue,
  getValue,
  getSelectedRowNumber,
  setData,
  getData
} from "../../shared/WindowImports";

import "./HighMoistureSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_HighMoistureSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let gradeList = []
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "HighMoistureSetup",
    windowName: "HighMoistureSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.HighMoistureSetup",
    // START_USER_CODE-USER_HighMoistureSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "High Moisture Setup",
      scrCode: "PN0300A",
    },
    // END_USER_CODE-USER_HighMoistureSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClear: {
      name: "btnClear",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Clear",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClear_PROPERTIES

      // END_USER_CODE-USER_btnClear_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    cmmndCntnrActions1: {
      name: "cmmndCntnrActions1",
      type: "CommandContainerWidget",
      parent: "grpbxHighMoisturSetup",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_cmmndCntnrActions1_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions1_PROPERTIES
    },
    colGradeType: {
      name: "colGradeType",
      type: "GridColumnWidget",
      parent: "gridHighMoistureSetup",
      Label: "Grade Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGradeType_PROPERTIES

      // END_USER_CODE-USER_colGradeType_PROPERTIES
    },
    colHighGrade: {
      name: "colHighGrade",
      type: "GridColumnWidget",
      parent: "gridHighMoistureSetup",
      Label: "High Grade",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHighGrade_PROPERTIES

      // END_USER_CODE-USER_colHighGrade_PROPERTIES
    },
    colHighMoist: {
      name: "colHighMoist",
      type: "GridColumnWidget",
      parent: "gridHighMoistureSetup",
      Label: "High Moist",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHighMoist_PROPERTIES

      // END_USER_CODE-USER_colHighMoist_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridHighMoistureSetup",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colResult: {
      name: "colResult",
      type: "GridColumnWidget",
      parent: "gridHighMoistureSetup",
      Label: "Result",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colResult_PROPERTIES

      // END_USER_CODE-USER_colResult_PROPERTIES
    },
    ddGradeType: {
      name: "ddGradeType",
      type: "DropDownFieldWidget",
      parent: "grpbxHighMoisturSetup",
      Label: "Grade Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGradeType_PROPERTIES

      // END_USER_CODE-USER_ddGradeType_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxHighMoisturSetup",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    gridHighMoistureSetup: {
      name: "gridHighMoistureSetup",
      type: "GridWidget",
      parent: "grpbxHighMoisturSetup",
      gridCellsOrder:
        "txtcolPeanutType,txtcolGradeType,txtcolHighMoist,txtcolHighGrade,txtcolResult",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      clientPagination: true,

      DisplaySize: 10,
      // START_USER_CODE-USER_gridHighMoistureSetup_PROPERTIES

      // END_USER_CODE-USER_gridHighMoistureSetup_PROPERTIES
    },
    txtcolGradeType: {
      name: "txtcolGradeType",
      type: "TextBoxWidget",
      colName: "colGradeType",
      parent: "gridHighMoistureSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGradeType_PROPERTIES

      // END_USER_CODE-USER_txtcolGradeType_PROPERTIES
    },
    txtcolHighGrade: {
      name: "txtcolHighGrade",
      type: "TextBoxWidget",
      colName: "colHighGrade",
      parent: "gridHighMoistureSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHighGrade_PROPERTIES

      // END_USER_CODE-USER_txtcolHighGrade_PROPERTIES
    },
    txtcolHighMoist: {
      name: "txtcolHighMoist",
      type: "TextBoxWidget",
      colName: "colHighMoist",
      parent: "gridHighMoistureSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHighMoist_PROPERTIES

      // END_USER_CODE-USER_txtcolHighMoist_PROPERTIES
    },
    txtcolPeanutType: {
      name: "txtcolPeanutType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridHighMoistureSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutType_PROPERTIES
    },
    txtcolResult: {
      name: "txtcolResult",
      type: "TextBoxWidget",
      colName: "colResult",
      parent: "gridHighMoistureSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolResult_PROPERTIES

      // END_USER_CODE-USER_txtcolResult_PROPERTIES
    },
    txtHighGrade: {
      name: "txtHighGrade",
      type: "TextBoxWidget",
      parent: "grpbxHighMoisturSetup",
      Label: "High Grade:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHighGrade_PROPERTIES

      // END_USER_CODE-USER_txtHighGrade_PROPERTIES
    },
    txtHighMoist: {
      name: "txtHighMoist",
      type: "TextBoxWidget",
      parent: "grpbxHighMoisturSetup",
      Label: "High Moist:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHighMoist_PROPERTIES

      // END_USER_CODE-USER_txtHighMoist_PROPERTIES
    },
    txtResult: {
      name: "txtResult",
      type: "TextBoxWidget",
      parent: "grpbxHighMoisturSetup",
      Label: "Result:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtResult_PROPERTIES

      // END_USER_CODE-USER_txtResult_PROPERTIES
    },
    grpbxHighMoisturSetup: {
      name: "grpbxHighMoisturSetup",
      type: "GroupBoxWidget",
      parent: "HighMoistureSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxHighMoisturSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxHighMoisturSetup_PROPERTIES
    },
    cmmndCntnrActions2: {
      name: "cmmndCntnrActions2",
      type: "CommandContainerWidget",
      parent: "HighMoistureSetup",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrActions2_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions2_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const Formload = async () => {
    try{
    await bIntiateForm()
    await bFillTypeList()
    disable(thisObj, 'btnAdd')

    disable(thisObj, 'btnUpdate')
    disable(thisObj, 'btnDelete')
    setLoading(false)
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(
        thisObj, "Error in generating Formload"
      )
    }
  }}

  const bFillTypeList = async () => {
    try{
    let js = []
    let obj = {}
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
    let data = response
    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.key == data[i].pnutTypeId)

        if (dataExist == undefined) {
          obj = {
            key: data[i].pnutTypeId,
            description: data[i].pnutTypeName,

          }
          js.push(obj);
          obj = {};
        }
      }
    }
    setValue(thisObj, 'ddPeanutType', js.at(0).key);
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutType: {
          ...state["ddPeanutType"],
          valueList: js,
        }
      }
    })
  }

catch (err) {
   if (err instanceof EvalError) {
    showMessage(thisObj, err.message)
  }
  else {
    showMessage(
      thisObj, "Error in loading dropdown for peanut type"
    )
  }
}}



  async function bConvertType(ConVal, ConType) {
    let bConvertTypeValue = ""
    try {
      if (ConType == "gradetype") {
        if (ConVal.toLocaleUpperCase() == "ss_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Sound Split"
        }
        if (ConVal.toLocaleUpperCase() == "lsk_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Loose Shelled Kernels"
        }
        if (ConVal.toLocaleUpperCase() == "hulls_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Hulls"
        }
        if (ConVal.toLocaleUpperCase() == "fm_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Foreign Material"
        }
        if (ConVal.toLocaleUpperCase() == "dam_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Damage kernels"
        }
        if (ConVal.toLocaleUpperCase() == "smk_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Sound Mature Kernels"
        }
        if (ConVal.toLocaleUpperCase() == "totkern_pc".toLocaleUpperCase()) {
          bConvertTypeValue = "Total Kernels"
        }
        if (ConVal.toLocaleUpperCase() == "elk_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Extra Large kernels"
        }
        if (ConVal.toLocaleUpperCase() == "fancy_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Fancy Pods"
        }
        if (ConVal.toLocaleUpperCase() == "smkss_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "SMKSS"
        }
        if (ConVal.toLocaleUpperCase() == "ok_pct".toLocaleUpperCase()) {
          bConvertTypeValue = "Other Kernels"
        }
      }
      else {
        if (ConVal.toLocaleUpperCase() == "VA") {
          bConvertTypeValue = "Virginia"
        }
        if (ConVal.toLocaleUpperCase() == "RU") {
          bConvertTypeValue = "Runner"
        }
        if (ConVal.toLocaleUpperCase() == "SP") {
          bConvertTypeValue = "Spanish"
        }
        if (ConVal.toLocaleUpperCase() == "VL") {
          bConvertTypeValue = "Valencia"
        }
      }
    }
    catch (err) {
      bConvertTypeValue = ""
    }
    finally {
      return bConvertTypeValue
    }
  }
  async function bReverseType(ConVal, ConType) {
    let bConvertTypeValue = ""
    try {
      if (ConType == "gradetype") {
        if (ConVal == "Sound Split") {
          bConvertTypeValue =  'ss_pct'
        }
        if (ConVal == "Loose Shelled Kernels") {
          bConvertTypeValue = "lsk_pct" 
        }
        if (ConVal == "Hulls") {
          bConvertTypeValue = "hulls_pct"
        }
        if (ConVal == "Foreign Material" ) {
          bConvertTypeValue = "fm_pct"
        }
        if (ConVal == "Damage kernels" ) {
          bConvertTypeValue = "dam_pct"
        }
        if (ConVal == "Sound Mature Kernels") {
          bConvertTypeValue = "smk_pct"
        }
        if (ConVal ==  "Total Kernels") {
          bConvertTypeValue = "totkern_pc"
        }
        if (ConVal == "Extra Large kernels") {
          bConvertTypeValue = "elk_pct"
        }
        if (ConVal == "Fancy Pods") {
          bConvertTypeValue = "fancy_pct" 
        }
        if (ConVal == "SMKSS") {
          bConvertTypeValue = "smkss_pct"
        }
        if (ConVal == "Other Kernels") {
          bConvertTypeValue = "ok_pct"
        }
      }
      else {
        if (ConVal == "Virginia") {
          bConvertTypeValue = "VA"
        }
        if (ConVal == "Runner") {
          bConvertTypeValue = "RU" 
        }
        if (ConVal == "Spanish") {
          bConvertTypeValue = "SP"
        }
        if (ConVal == "Valencia") {
          bConvertTypeValue = "VL"
        }
      }
    }
    catch (err) {
      bConvertTypeValue = ""
    }
    finally {
      return bConvertTypeValue
    }
  }

  const bIntiateForm = async () => {
    try{
    setLoading(true)
    thisObj.values.txtHighMoist = ""
    thisObj.values.txtHighGrade = ""
    thisObj.values.txtResult = ""

    let js = []
    thisObj.setState(current => {
      return {
        ...current,
        ddGradeType: {
          ...state["ddGradeType"],
          valueList: js
        }
      }
    })
     setValue(thisObj, "gridHighMoistureSetup", [])

    js.push({ key: '', description: '' })
    js.push({ key: 'SS_PCT', description: 'Sound Split' })
    js.push({ key: 'LSK_PCT', description: 'Loose Shelled Kernels' })
    js.push({ key: 'HULLS_PCT', description: 'Hulls' })
    js.push({ key: 'FM_PCT', description: 'Foreign Material' })
    js.push({ key: 'DAM_PCT', description: 'Damage kernels' })
    js.push({ key: 'SMK_PCT', description: 'Sound Mature Kernels' })
    js.push({ key: 'TOTKERN_PC', description: 'Total Kernels' })
    js.push({ key: 'ELK_PCT', description: 'Extra Large kernels' })
    js.push({ key: 'FANCY_PCT', description: 'Fancy Pods' })
    js.push({ key: 'SMKSS_PCT', description: 'SMKSS' })
    js.push({ key: 'OK_PCT', description: 'Other Kernels' })
    setData(thisObj, 'gradeList', js)

    thisObj.setState(current => {
      return {
        ...current,
        ddGradeType: {
          ...state["ddGradeType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddGradeType', js.at(0).key);
    setLoading(false)
  }
  catch (err) {
  if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(
        thisObj, "Error in loading dropdown for gradetype"
      )
    }
  }
  }
  const onbtnSearchClick = async () => {
    try {
      setLoading(true)

      let grade_type = thisObj.values.ddGradeType
      let high_moist_pct = thisObj.values.txtHighMoist
      let high_grade_pct = thisObj.values.txtHighGrade
      let result = thisObj.values.txtResult
      let pnut_type_id = thisObj.values.ddPeanutType

      enable(thisObj, 'btnDelete')
      disable(thisObj,'btnUpdate')
      let gridArray = []
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveHighMoistureDetails(pnut_type_id, grade_type, high_moist_pct, high_grade_pct, result)

      if (response != undefined && response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          gridArray.push({
            txtcolGradeType: await bConvertType(response[i].grade_type, "gradetype"),
            txtcolHighMoist: response[i].high_moist_pct,
            txtcolHighGrade: response[i].high_grade_pct,
            txtcolResult: response[i].result,
            txtcolPeanutType: await bConvertType(response[i].pnut_type_id, "peanut")
          })
        }
        }
       else{
        if ( isNaN(high_moist_pct) == true) {
          showMessage(thisObj, "High Moist value should be numeric")
          return false;
        }
        if ( isNaN(high_grade_pct) == true) {
          showMessage(thisObj, "High Grade value should be numeric")
          return false;
        }
        if ( isNaN(result) == true) {
          showMessage(thisObj, "Result value should be numeric")
          return false;
        }
      }
     

      setValue(thisObj, "gridHighMoistureSetup", gridArray)
    }
    catch (err) {
      setValue(thisObj, "gridHighMoistureSetup", [])
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Error occurred during Search Button Click"
        )
      }
    }
    finally {
      setLoading(false)
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const ongridHighMoistureSetupRowSelect = async (rowSelected) => {
    try {
      setLoading(true)
      let data = rowSelected.selectedRows

      if (data !== undefined && data !== null) {
        if (data.length > 0) {
          thisObj.values.txtHighMoist = data[0].txtcolHighMoist
          thisObj.values.txtHighGrade = data[0].txtcolHighGrade

          thisObj.values.txtResult = data[0].txtcolResult.toString()
          let gradeList = getData(thisObj, "gradeList")

          for (var i = 0; i < gradeList.length; i++) {
            if (gradeList[i].description == data[0].txtcolGradeType) {
              thisObj.values.ddGradeType = gradeList[i].key
              setValue(thisObj, "ddGradeType", gradeList[i].key)
              break
            }
          }

          if (await enableDisableControl()) {
            enable(thisObj, "btnAdd")
          }
          else {
            disable(thisObj, "btnAdd")
          }

          if (![undefined, null, ""].includes(data[0].txtcolResult) && data.length > 0) {
            enable(thisObj, "btnUpdate")
          }
          else {
            disable(thisObj, "btnUpdate")
          }
        
        
        }
      }
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  };
  thisObj.ongridHighMoistureSetupRowSelect = ongridHighMoistureSetupRowSelect;

  const onbtnClearClick = async () => {
    try {
      await bIntiateForm()
      disable(thisObj, 'btnAdd')
      disable(thisObj, 'btnUpdate')
    }
    catch (err) {
    }
  }
  thisObj.onbtnClearClick = onbtnClearClick;
 
  const onbtnDeleteClick = async () => {
    try {
      if (thisObj.state.gridHighMoistureSetup.selected[0] == undefined || thisObj.state.gridHighMoistureSetup.selected[0] == null) {
        showMessage(thisObj, "Please select record from grid")
        return;
      }

      let dict = {
        "Sound Split"           : "ss_pct",
        "Loose Shelled Kernels" : "lsk_pct",
        "Hulls"                 : "hulls_pct",
        "Foreign Material"      : "fm_pct",
        "Damage kernels"        : "dam_pct",
        "Sound Mature Kernels"  : "smk_pct",
        "Total Kernels"         : "totkern_pc",
        "Extra Large kernels"   : "elk_pct",
        "Fancy Pods"            : "fancy_pct",
        "SMKSS"                 : "smkss_pct",
        "Other Kernels"         : "ok_pct",
        "Virginia"              : "VA",
        "Runner"                : "RU",
        "Spanish"               : "SP",
        "Valencia"              : "VL"
      }

      let gridData = getValue(thisObj, 'gridHighMoistureSetup');
      let rowNum = getSelectedRowNumber(thisObj, 'gridHighMoistureSetup');

      let pnut_type_id = dict[gridData[rowNum].txtcolPeanutType]
      let grade_type = dict[gridData[rowNum].txtcolGradeType]
      let high_grade_pct = gridData[rowNum].txtcolHighGrade
      let high_moist_pct = gridData[rowNum].txtcolHighMoist
      let result = gridData[rowNum].txtcolResult
      let status = 'DELETE'
      let dataObj = {
        "result": result,
        "user_id": useridFromLS()
      }

      let res = confirm(`Do you want to delete  \nPeanut Type:${pnut_type_id} \nGrade Type:${grade_type} \nHigh moist:${high_moist_pct} \nHigh grade:${high_grade_pct} \nResult : ${result}?`)
      if (res == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateHighMoistureDetails(status, pnut_type_id, grade_type, high_moist_pct, high_grade_pct, dataObj)
       
        if(response.status == 200) {
          showMessage(thisObj, 'Record Deleted Successfully', true)
          onbtnSearchClick()
          disable(thisObj,"btnUpdate")
          disable(thisObj,'btnDelete')
        }

      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Button Click"
        );
      }
      return false;
    }

  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick


  const onbtnAddClick = async () => {
    try {
      let txtHighGrade = thisObj.values.txtHighGrade
      let highMoist = thisObj.values.txtHighMoist
      let txtResult = thisObj.values.txtResult
      let peanutType = thisObj.values.ddPeanutType
      let ddGradeType = thisObj.values.ddGradeType

      if (txtHighGrade == null || txtHighGrade == '' || txtHighGrade == undefined || highMoist == null || highMoist == '' || highMoist == undefined || txtResult == null || txtResult == '' || txtResult == undefined) {
        showMessage(thisObj, "Please fill High moist,High grade and result")
        return false;
      }
      if (txtHighGrade == null || txtHighGrade == '' || txtHighGrade == undefined || isNaN(txtHighGrade) == true) {
        showMessage(thisObj, "High Grade value should be numeric")
        return false;
      }
      if (highMoist == null || highMoist == '' || highMoist == undefined || isNaN(highMoist) == true) {
        showMessage(thisObj, "High Moist value should be numeric")
        return false;
      }
      if (txtResult == null || txtResult == '' || txtResult == undefined || isNaN(txtResult) == true) {
        showMessage(thisObj, "Result value should be numeric")
        return false;
      }

      let pnut_type_id = peanutType
      let grade_type = ddGradeType
      let high_grade_pct = txtHighGrade
      let high_moist_pct = highMoist
      let result = parseInt(txtResult)

      let status = 'ADD'
      let dataObj = {
        "result": result,
        "user_id": useridFromLS()
      }

        let response = await SystemMaintenanceSpecialFunctionsService.UpdateHighMoistureDetails(status, pnut_type_id, grade_type, high_moist_pct, high_grade_pct, dataObj)
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.\n' + response.message)
          return
        }
        if(response.status == 200) {
          showMessage(thisObj, 'Record Added Successfully', true)
          await onbtnSearchClick()
        }
       
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  const onbtnUpdateClick = async () => {
    try {
      if (thisObj.state.gridHighMoistureSetup.selected[0] == undefined || thisObj.state.gridHighMoistureSetup.selected[0] == null) {
        showMessage(thisObj, "Please select record from grid")
        return;
      }
      let highGrade = Number(thisObj.values.txtHighGrade)
      let highMoist = Number(thisObj.values.txtHighMoist)
      let txtResult = thisObj.values.txtResult
      let peanutType = thisObj.values.ddPeanutType
      let ddGradeType = thisObj.values.ddGradeType
      if (txtResult == null || txtResult == '' || txtResult == undefined) {
        showMessage(thisObj, "Please fill result")
      }
      if (isNaN(txtResult) || [undefined, null, ""].includes(txtResult)) {
        showMessage(thisObj, "Result value should be numeric")
      }

      let gridData = getValue(thisObj, 'gridHighMoistureSetup');
      let rowNum = getSelectedRowNumber(thisObj, 'gridHighMoistureSetup');
      let pnut_type_id = await bReverseType(gridData[rowNum].txtcolPeanutType, 'peanut')
      let grade_type = await bReverseType(gridData[rowNum].txtcolGradeType, 'gradetype')
      let high_grade_pct = gridData[rowNum].txtcolHighGrade
      let high_moist_pct = gridData[rowNum].txtcolHighMoist
      let result = gridData[rowNum].txtcolResult

      let status = 'update'
      let dataObj = {
        "result": parseInt(txtResult),
        "user_id": useridFromLS()
      }

        let res = confirm(`Do you want to Update \nPeanut Type:${pnut_type_id} \nGrade Type:${grade_type} \nHigh moist:${high_moist_pct} \nHigh grade:${high_grade_pct} \nResult:${result} \nWith result value :${txtResult} ?` )
        if(!res)
        {
          enable(thisObj,'btnUpdate')
          return;
        }
      if (res) {
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateHighMoistureDetails(status, pnut_type_id, grade_type, high_moist_pct, high_grade_pct, dataObj)
       if (response.status == 200) {
          showMessage(thisObj, 'Record Updated Successfully', true)
          await onbtnSearchClick()
        }
      }
       if(rowNum.length > 0){
        enable(thisObj,"btnUpdate")
      }
      else{
        disable(thisObj,"btnUpdate")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Update Button Click"
        );
      }
      return false;
    }

  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick

  async function enableDisableControl() {
    try {
      let highGrade = thisObj.values.txtHighGrade
      let gradeType = thisObj.values.ddGradeType
      let result = thisObj.values.txtResult
      let highMoist = thisObj.values.txtHighMoist
      if (![undefined, null, ""].includes(highGrade) && ![undefined, null, ""].includes(gradeType) && ![undefined, null, ""].includes(result) && ![undefined, null, ""].includes(highMoist)) {
        return true
      }
      return false
    }
    catch (err) {
      alert(err.message)
    }
  }

  const ontxtHighGradeChange = async () => {
    try {
      let txtHighGrade = thisObj.values.txtHighGrade
      if (await enableDisableControl()) {
        enable(thisObj, "btnAdd")
      }
      else {
        disable(thisObj, "btnAdd")
      }
    }
    catch (err) {
    }
  }
  thisObj.ontxtHighGradeChange = ontxtHighGradeChange


  const ontxtHighMoistChange = async () => {
    try {
     
      if (await enableDisableControl()) {
        enable(thisObj, "btnAdd")
      }
      else {
        disable(thisObj, "btnAdd")
      }
    }
    catch (err) {
    }
  }
  thisObj.ontxtHighMoistChange = ontxtHighMoistChange

  
  const onddGradeTypeChange = async () => {
    try { 
     
      if (await enableDisableControl()) {
        enable(thisObj, "btnAdd")
      }
      else {
        disable(thisObj, "btnAdd")
      }
    }
    catch (err) {
    }
  }
  thisObj.onddGradeTypeChange = onddGradeTypeChange

  const ontxtResultChange = async () => {
    try {
     
      if (await enableDisableControl()) {
        enable(thisObj, "btnAdd")
       }
      else {
        disable(thisObj, "btnAdd")
      }
      let gridData = getValue(thisObj, 'gridHighMoistureSetup')
      if(![undefined, null, ""].includes(thisObj.values.txtResult) && gridData.length > 0){
        enable(thisObj,"btnUpdate")
      }
      else{
        disable(thisObj,"btnUpdate")
      }
      
    }
    catch (err) {
    }
  }
  thisObj.ontxtResultChange = ontxtResultChange


  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_HighMoistureSetupPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;


  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_HighMoistureSetup*/}

              {/* END_USER_CODE-USER_BEFORE_HighMoistureSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxHighMoisturSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxHighMoisturSetup*/}

              <GroupBoxWidget
                conf={state.grpbxHighMoisturSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtHighGrade*/}

                {/* END_USER_CODE-USER_BEFORE_txtHighGrade*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHighGrade}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHighGrade*/}

                {/* END_USER_CODE-USER_AFTER_txtHighGrade*/}
                {/* START_USER_CODE-USER_BEFORE_ddGradeType*/}

                {/* END_USER_CODE-USER_BEFORE_ddGradeType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddGradeType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddGradeType*/}

                {/* END_USER_CODE-USER_AFTER_ddGradeType*/}
                {/* START_USER_CODE-USER_BEFORE_txtResult*/}

                {/* END_USER_CODE-USER_BEFORE_txtResult*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtResult}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtResult*/}

                {/* END_USER_CODE-USER_AFTER_txtResult*/}
                {/* START_USER_CODE-USER_BEFORE_txtHighMoist*/}

                {/* END_USER_CODE-USER_BEFORE_txtHighMoist*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHighMoist}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHighMoist*/}

                {/* END_USER_CODE-USER_AFTER_txtHighMoist*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions1*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions1*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions1}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClear*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClear*/}

                  <ButtonWidget
                    conf={state.btnClear}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClear*/}

                  {/* END_USER_CODE-USER_AFTER_btnClear*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions1*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions1*/}
                {/* START_USER_CODE-USER_BEFORE_gridHighMoistureSetup*/}

                {/* END_USER_CODE-USER_BEFORE_gridHighMoistureSetup*/}

                <GridWidget
                  conf={state.gridHighMoistureSetup}
                  screenConf={state}
                  linkClick={event => {
                    if (state.gridHighMoistureSetup.selected.length > 0) {

                      thisObj.selectedRow = state.gridHighMoistureSetup.selected[0];
                    }
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridHighMoistureSetup}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRow => onGridRowChange(selectedRow)}
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridHighMoistureSetup*/}

                {/* END_USER_CODE-USER_AFTER_gridHighMoistureSetup*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxHighMoisturSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxHighMoisturSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions2*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions2*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions2}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions2*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions2*/}

              {/* START_USER_CODE-USER_AFTER_HighMoistureSetup*/}

              {/* END_USER_CODE-USER_AFTER_HighMoistureSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_HighMoistureSetup);
