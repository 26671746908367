/* eslint-disable*/
import React from "react";
import DateWidget from "./DateWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";
import { DefaultPropsData } from "../common/DefaultPropsData";

describe("DateWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let datewidget1 = {
        name: "datewidget1",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "k",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
      };
      let txtErrMsgs = {
        name: "txtErrMsgs",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "l",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
        AutoCompleteForDate: false
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = { datewidget1: "09-09-2021" };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      window._kaledo.t = jest.fn();
      renderTestScreen(
        <div>
          <DateWidget
            onChange={onChange}
            conf={datewidget1}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
          <DateWidget
            onChange={onChange}
            conf={txtErrMsgs}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is DateWidget1 Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "DD-MM-YYYY";
    window._kaledo.isCobolTable = true;
    let widgetObjWithDefVal = DefaultPropsData["DateWidget"];
    const datewidget1 = screen.getByTestId("datewidget1");
    expect(datewidget1.tagName).toBe("INPUT");
    expect(datewidget1.type).toBe("text");
    expect(datewidget1.classList).toContain("datetimepicker-input");
    expect(
      datewidget1.parentElement.previousElementSibling.textContent
    ).toEqual("Date Widget");
    await act(async () => {
      userEvent.click(datewidget1.nextElementSibling, { button: 0 });
    });
    await act(async () => {
      fireEvent.change(datewidget1, { target: { value: "09-09-2021" } });
    });
  });
});

describe("DateWidget coboltable false date widget on blur Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let datewidget1 = {
        name: "datewidget1",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "k",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
      };
      let txtErrMsgs = {
        name: "txtErrMsgs",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "l",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
        AutoCompleteForDate: false
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = { datewidget1: "09-09-2021" };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      window._kaledo.t = jest.fn();
      window._kaledo.isCobolTable = false;
      renderTestScreen(
        <div>
          <DateWidget
            onChange={onChange}
            conf={datewidget1}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
          <DateWidget
            onChange={onChange}
            onBlur={onBlur}
            conf={txtErrMsgs}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is DateWidget1 Loads Succesfully", async () => {
    const txtErrMsgs = screen.getByTestId("txtErrMsgs");
    await act(async () => {
      fireEvent.focus(txtErrMsgs);
      fireEvent.blur(txtErrMsgs);
    });
  });
});

describe("DateWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let datewidget2 = {
        name: "datewidget2",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "k",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let txtErrMsgs = {
        name: "txtErrMsgs",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "l",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = { datewidget2: "16-12-2021" };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      window._kaledo["dispFormatReqd"] = true;
      window._kaledo.isCobolTable = true;
      renderTestScreen(
        <div>
          <DateWidget
            conf={datewidget2}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
            onChange={onChange}
            onBlur={onBlur}
          />
          <DateWidget
            onChange={onChange}
            conf={txtErrMsgs}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
            onBlur={onBlur}
          />
        </div>
      );
    });
  });
  afterEach(cleanup);
});
describe("DateWidget2 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let datewidget2 = {
        name: "datewidget2",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "k",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let txtErrMsgs = {
        name: "txtErrMsgs",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "l",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = { datewidget2: "16-12-2021" };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      window._kaledo["dispFormatReqd"] = true;
      window._kaledo.isCobolTable = true;
      renderTestScreen(
        <div>
          <DateWidget
            conf={datewidget2}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
            onChange={onChange}
            onBlur={onBlur}
          />
          <DateWidget
            onChange={onChange}
            conf={txtErrMsgs}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is DateWidget2 MM-DD-YYYY Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "MM-DD-YYYY";
    const datewidget2 = screen.getByTestId("datewidget2");
    await act(async () => {
      fireEvent.change(datewidget2, { target: { value: "17-12-2021" } });
    });
  });
  test("is DateWidget2 MM/DD/YYYY  valid (Feb)Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "MM/DD/YYYY";
    const datewidget2 = screen.getByTestId("datewidget2");
    await act(async () => {
      fireEvent.change(datewidget2, { target: { value: "02/30/2021" } });
    });

  });

  test("is DateWidget2 MM/DD/YYYY invalid(feb) Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "MM/DD/YYYY";
    const datewidget2b = screen.getByTestId("datewidget2");
    await act(async () => {
      fireEvent.change(datewidget2b, { target: { value: '02/30/2020' } })
    });
  });

  test("is DateWidget2 DD/MM/YYYY valid(Feb) Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "DD/MM/YYYY";
    const datewidget2a = screen.getByTestId("datewidget2");
    await act(async () => {
      fireEvent.change(datewidget2a, { target: { value: '02/30/2021' } });
    });
  });

  test("is DateWidget2 DD/MM/YYYY invalid Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "DD/MM/YYYY";
    const datewidget2a = screen.getByTestId("datewidget2");
    await act(async () => {
      fireEvent.change(datewidget2a, { target: { value: '02-30-2020' } });
    });
  });

});

describe("DateWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let datewidget1 = {
        name: "datewidget1",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "k",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
      };
      let txtErrMsgs = {
        name: "txtErrMsgs",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "l",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      window._kaledo.t = jest.fn();
      renderTestScreen(
        <div>
          <DateWidget
            onChange={onChange}
            conf={datewidget1}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
          <DateWidget
            onChange={onChange}
            conf={txtErrMsgs}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is DateWidget1 Loads Succesfully", async () => {
    window._kaledo.isCobolTable = true;
    const datewidget1 = screen.getByTestId("datewidget1");
    expect(datewidget1.tagName).toBe("INPUT");
    expect(datewidget1.type).toBe("text");
    expect(datewidget1.classList).toContain("datetimepicker-input");
    await act(async () => {
      userEvent.click(datewidget1.nextElementSibling, { button: 0 });
    });
    await act(async () => {
      fireEvent.change(datewidget1, { target: { value: "09/09/2021" } });
    });
  });
  test("is DateWidget1 MM-DD-YY Loads Succesfully", async () => {
    window._kaledo.isCobolTable = true;
    window._kaledo["format"]["dateFormat"] = "MM-DD-YY";
    const datewidget1 = screen.getByTestId("datewidget1");
    await act(async () => {
      fireEvent.change(datewidget1, { target: { value: '03-30-20' } });
    });
  });
  test("is DateWidget1 MM/DD/YY Loads Succesfully", async () => {
    window._kaledo.isCobolTable = true;
    window._kaledo["format"]["dateFormat"] = "MM/DD/YY";
    const datewidget1 = screen.getByTestId("datewidget1");
    await act(async () => {
      fireEvent.change(datewidget1, { target: { value: '03/30/20' } });
    });
  });
});

describe("DateWidget2 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let datewidget2 = {
        name: "datewidget2",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "k",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let txtErrMsgs = {
        name: "txtErrMsgs",
        type: "DateWidget",
        parent: "groupboxwidget",
        Label: "Date Widget",
        ShortcutKey: "l",
        LengthRange: { MinLength: 0, MaxLength: 8 },
        ofTypeDomain: "d_date",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = { datewidget2: "16-12-2021" };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      window._kaledo["dispFormatReqd"] = true;
      window._kaledo.isCobolTable = true;
      renderTestScreen(
        <div>
          <DateWidget
            conf={datewidget2}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
            onChange={onChange}
            onBlur={onBlur}
          />
          <DateWidget
            onChange={onChange}
            conf={txtErrMsgs}
            screenConf={stateScreenConf}
            values={values}
            touched={touched}
            errors={errors}
          />
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is DateWidget2 MM/DD/YY  Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "MM/DD/YYYY";
    const datewidget2 = screen.getByTestId("datewidget2");
    expect(datewidget2.tagName).toBe("INPUT");
    expect(datewidget2.type).toBe("text");
    expect(datewidget2.classList).toContain("datetimepicker-input");
    const txtErrMsgs = screen.getByTestId("txtErrMsgs");
    await act(async () => {
      userEvent.click(datewidget2.nextElementSibling, { button: 0 });
      fireEvent.change(datewidget2, { target: { value: '03/30/20' } })
      txtErrMsgs.focus();
    });
  });
  test("is DateWidget2 MM-DD-YY Loads Succesfully", async () => {
    window._kaledo["format"]["dateFormat"] = "MM-DD-YYYY";
    const datewidget2 = screen.getByTestId("datewidget2");
    expect(datewidget2.tagName).toBe("INPUT");
    expect(datewidget2.type).toBe("text");
    expect(datewidget2.classList).toContain("datetimepicker-input");
    const txtErrMsgs = screen.getByTestId("txtErrMsgs");
    await act(async () => {
      userEvent.click(datewidget2.nextElementSibling, { button: 0 });
      fireEvent.change(datewidget2, { target: { value: '04-31-2021' } })
      txtErrMsgs.focus();
      fireEvent.keyDown(
        txtErrMsgs,
        {
          key: "Enter",
          code: "Enter",
          keyCode: 13,
          ctrlKey: true
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 40
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 38,
          ctrlKey: true
        }
      );
      fireEvent.keyUp(
        txtErrMsgs,
        {
          keyCode: 38,
          ctrlKey: false
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 38,
          ctrlKey: false
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 40,
          ctrlKey: true
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 37
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 39
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 33
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 34
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 13
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 27
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 84
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 46
        }
      );
      fireEvent.keyDown(
        txtErrMsgs,
        {
          keyCode: 32,
          ctrlKey: true
        }
      );
      fireEvent.mouseDown(txtErrMsgs
      );
      fireEvent.keyDown(txtErrMsgs
      );
    });
  });

});
