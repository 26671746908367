/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail from "./DiscountScheduleAuditDetail";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DiscountScheduleAuditDetail Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail />
      );
    });
  });
  afterEach(cleanup);
  test("is DiscountScheduleAuditDetail Loads Successfully", () => {
    expect(screen.getByText("DiscountScheduleAuditDetail")).toBeInTheDocument;
  });
  test("Custom Test Cases for DiscountScheduleAuditDetail", () => {
    // START_USER_CODE-USER_DiscountScheduleAuditDetail_Custom_Test_Case
    // END_USER_CODE-USER_DiscountScheduleAuditDetail_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail />
      );
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_btnClose"
      )
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("gridDiscountScheduleAudit(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAuditbtn =
      gridDiscountScheduleAudit.nextElementSibling.firstElementChild;
    gridDiscountScheduleAudit =
      gridDiscountScheduleAudit.parentElement.parentElement.parentElement;
    expect(gridDiscountScheduleAudit.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridDiscountScheduleAudit", () => {
    // START_USER_CODE-USER_gridDiscountScheduleAudit_TEST
    // END_USER_CODE-USER_gridDiscountScheduleAudit_TEST
  });
  test("gridDiscountScheduleAudit_txtColnewDiscountPerTon(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColnewDiscountPerTon = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColnewDiscountPerTonbtn =
      gridDiscountScheduleAudit_txtColnewDiscountPerTon.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColnewDiscountPerTon =
      gridDiscountScheduleAudit_txtColnewDiscountPerTon.parentElement
        .parentElement.parentElement;
    expect(gridDiscountScheduleAudit_txtColnewDiscountPerTon.tagName).toBe(
      "DIV"
    );
    expect(
      gridDiscountScheduleAudit_txtColnewDiscountPerTon.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColnewDiscountPerTon", () => {
    // START_USER_CODE-USER_txtColnewDiscountPerTon_TEST
    // END_USER_CODE-USER_txtColnewDiscountPerTon_TEST
  });
  test("gridDiscountScheduleAudit_txtColNewReadingFrom(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColNewReadingFrom = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColNewReadingFrombtn =
      gridDiscountScheduleAudit_txtColNewReadingFrom.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColNewReadingFrom =
      gridDiscountScheduleAudit_txtColNewReadingFrom.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleAudit_txtColNewReadingFrom.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit_txtColNewReadingFrom.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColNewReadingFrom", () => {
    // START_USER_CODE-USER_txtColNewReadingFrom_TEST
    // END_USER_CODE-USER_txtColNewReadingFrom_TEST
  });
  test("gridDiscountScheduleAudit_txtColNewReadingTo(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColNewReadingTo = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColNewReadingTobtn =
      gridDiscountScheduleAudit_txtColNewReadingTo.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColNewReadingTo =
      gridDiscountScheduleAudit_txtColNewReadingTo.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleAudit_txtColNewReadingTo.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit_txtColNewReadingTo.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColNewReadingTo", () => {
    // START_USER_CODE-USER_txtColNewReadingTo_TEST
    // END_USER_CODE-USER_txtColNewReadingTo_TEST
  });
  test("gridDiscountScheduleAudit_txtColNewType(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColNewType = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColNewTypebtn =
      gridDiscountScheduleAudit_txtColNewType.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColNewType =
      gridDiscountScheduleAudit_txtColNewType.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleAudit_txtColNewType.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit_txtColNewType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColNewType", () => {
    // START_USER_CODE-USER_txtColNewType_TEST
    // END_USER_CODE-USER_txtColNewType_TEST
  });
  test("gridDiscountScheduleAudit_txtColOldDiscountPerTon(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColOldDiscountPerTon = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColOldDiscountPerTonbtn =
      gridDiscountScheduleAudit_txtColOldDiscountPerTon.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColOldDiscountPerTon =
      gridDiscountScheduleAudit_txtColOldDiscountPerTon.parentElement
        .parentElement.parentElement;
    expect(gridDiscountScheduleAudit_txtColOldDiscountPerTon.tagName).toBe(
      "DIV"
    );
    expect(
      gridDiscountScheduleAudit_txtColOldDiscountPerTon.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColOldDiscountPerTon", () => {
    // START_USER_CODE-USER_txtColOldDiscountPerTon_TEST
    // END_USER_CODE-USER_txtColOldDiscountPerTon_TEST
  });
  test("gridDiscountScheduleAudit_txtColOldReadingFrom(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColOldReadingFrom = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColOldReadingFrombtn =
      gridDiscountScheduleAudit_txtColOldReadingFrom.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColOldReadingFrom =
      gridDiscountScheduleAudit_txtColOldReadingFrom.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleAudit_txtColOldReadingFrom.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit_txtColOldReadingFrom.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColOldReadingFrom", () => {
    // START_USER_CODE-USER_txtColOldReadingFrom_TEST
    // END_USER_CODE-USER_txtColOldReadingFrom_TEST
  });
  test("gridDiscountScheduleAudit_txtColOldReadingTo(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColOldReadingTo = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColOldReadingTobtn =
      gridDiscountScheduleAudit_txtColOldReadingTo.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColOldReadingTo =
      gridDiscountScheduleAudit_txtColOldReadingTo.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleAudit_txtColOldReadingTo.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit_txtColOldReadingTo.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColOldReadingTo", () => {
    // START_USER_CODE-USER_txtColOldReadingTo_TEST
    // END_USER_CODE-USER_txtColOldReadingTo_TEST
  });
  test("gridDiscountScheduleAudit_txtColOldType(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleAudit_txtColOldType = screen.getByTestId(
      "gridDiscountScheduleAudit"
    );
    let gridDiscountScheduleAudit_txtColOldTypebtn =
      gridDiscountScheduleAudit_txtColOldType.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleAudit_txtColOldType =
      gridDiscountScheduleAudit_txtColOldType.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleAudit_txtColOldType.tagName).toBe("DIV");
    expect(gridDiscountScheduleAudit_txtColOldType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleAuditDetail_gridDiscountScheduleAudit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColOldType", () => {
    // START_USER_CODE-USER_txtColOldType_TEST
    // END_USER_CODE-USER_txtColOldType_TEST
  });
});
