/* eslint-disable*/
import React from "react";
import Settlements_TradeSettleProfile from "./TradeSettleProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettleProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleProfile />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettleProfile Loads Successfully", () => {
    expect(screen.getByText("TradeSettleProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettleProfile", () => {
    // START_USER_CODE-USER_TradeSettleProfile_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettleProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleProfile />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("Settlements:TradeSettleProfile_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(t("Settlements:TradeSettleProfile_btn2"));
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(t("Settlements:TradeSettleProfile_btn3"));
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btn4(Button Widget) Test Cases", async () => {
    const btn4 = screen.getByTestId("btn4");
    expect(btn4).toBeInTheDocument;
    expect(btn4.textContent).toEqual(t("Settlements:TradeSettleProfile_btn4"));
  });
  test("Custom Test Cases for btn4", () => {
    // START_USER_CODE-USER_btn4_TEST
    // END_USER_CODE-USER_btn4_TEST
  });
  test("btnAcctDist(Button Widget) Test Cases", async () => {
    const btnAcctDist = screen.getByTestId("btnAcctDist");
    expect(btnAcctDist).toBeInTheDocument;
    expect(btnAcctDist.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnAcctDist")
    );
  });
  test("Custom Test Cases for btnAcctDist", () => {
    // START_USER_CODE-USER_btnAcctDist_TEST
    // END_USER_CODE-USER_btnAcctDist_TEST
  });
  test("btnAddST(Button Widget) Test Cases", async () => {
    const btnAddST = screen.getByTestId("btnAddST");
    expect(btnAddST).toBeInTheDocument;
    expect(btnAddST.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnAddST")
    );
  });
  test("Custom Test Cases for btnAddST", () => {
    // START_USER_CODE-USER_btnAddST_TEST
    // END_USER_CODE-USER_btnAddST_TEST
  });
  test("btnAgreeAppl(Button Widget) Test Cases", async () => {
    const btnAgreeAppl = screen.getByTestId("btnAgreeAppl");
    expect(btnAgreeAppl).toBeInTheDocument;
    expect(btnAgreeAppl.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnAgreeAppl")
    );
  });
  test("Custom Test Cases for btnAgreeAppl", () => {
    // START_USER_CODE-USER_btnAgreeAppl_TEST
    // END_USER_CODE-USER_btnAgreeAppl_TEST
  });
  test("btnCalculate(Button Widget) Test Cases", async () => {
    const btnCalculate = screen.getByTestId("btnCalculate");
    expect(btnCalculate).toBeInTheDocument;
    expect(btnCalculate.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnCalculate")
    );
  });
  test("Custom Test Cases for btnCalculate", () => {
    // START_USER_CODE-USER_btnCalculate_TEST
    // END_USER_CODE-USER_btnCalculate_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateSettlement(Button Widget) Test Cases", async () => {
    const btnCreateSettlement = screen.getByTestId("btnCreateSettlement");
    expect(btnCreateSettlement).toBeInTheDocument;
    expect(btnCreateSettlement.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnCreateSettlement")
    );
  });
  test("Custom Test Cases for btnCreateSettlement", () => {
    // START_USER_CODE-USER_btnCreateSettlement_TEST
    // END_USER_CODE-USER_btnCreateSettlement_TEST
  });
  test("btnDeductTrack(Button Widget) Test Cases", async () => {
    const btnDeductTrack = screen.getByTestId("btnDeductTrack");
    expect(btnDeductTrack).toBeInTheDocument;
    expect(btnDeductTrack.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnDeductTrack")
    );
  });
  test("Custom Test Cases for btnDeductTrack", () => {
    // START_USER_CODE-USER_btnDeductTrack_TEST
    // END_USER_CODE-USER_btnDeductTrack_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnDeleteST(Button Widget) Test Cases", async () => {
    const btnDeleteST = screen.getByTestId("btnDeleteST");
    expect(btnDeleteST).toBeInTheDocument;
    expect(btnDeleteST.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnDeleteST")
    );
  });
  test("Custom Test Cases for btnDeleteST", () => {
    // START_USER_CODE-USER_btnDeleteST_TEST
    // END_USER_CODE-USER_btnDeleteST_TEST
  });
  test("btnEditST(Button Widget) Test Cases", async () => {
    const btnEditST = screen.getByTestId("btnEditST");
    expect(btnEditST).toBeInTheDocument;
    expect(btnEditST.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnEditST")
    );
  });
  test("Custom Test Cases for btnEditST", () => {
    // START_USER_CODE-USER_btnEditST_TEST
    // END_USER_CODE-USER_btnEditST_TEST
  });
  test("btnPayments(Button Widget) Test Cases", async () => {
    const btnPayments = screen.getByTestId("btnPayments");
    expect(btnPayments).toBeInTheDocument;
    expect(btnPayments.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnPayments")
    );
  });
  test("Custom Test Cases for btnPayments", () => {
    // START_USER_CODE-USER_btnPayments_TEST
    // END_USER_CODE-USER_btnPayments_TEST
  });
  test("btnPremsDeduct(Button Widget) Test Cases", async () => {
    const btnPremsDeduct = screen.getByTestId("btnPremsDeduct");
    expect(btnPremsDeduct).toBeInTheDocument;
    expect(btnPremsDeduct.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnPremsDeduct")
    );
  });
  test("Custom Test Cases for btnPremsDeduct", () => {
    // START_USER_CODE-USER_btnPremsDeduct_TEST
    // END_USER_CODE-USER_btnPremsDeduct_TEST
  });
  test("btnPrintSettlement(Button Widget) Test Cases", async () => {
    const btnPrintSettlement = screen.getByTestId("btnPrintSettlement");
    expect(btnPrintSettlement).toBeInTheDocument;
    expect(btnPrintSettlement.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnPrintSettlement")
    );
  });
  test("Custom Test Cases for btnPrintSettlement", () => {
    // START_USER_CODE-USER_btnPrintSettlement_TEST
    // END_USER_CODE-USER_btnPrintSettlement_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("Settlements:TradeSettleProfile_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("gridScaleTickets(Grid Widget) Test Cases", async () => {
    let gridScaleTickets = screen.getByTestId("gridScaleTickets");
    let gridScaleTicketsbtn =
      gridScaleTickets.nextElementSibling.firstElementChild;
    gridScaleTickets =
      gridScaleTickets.parentElement.parentElement.parentElement;
    expect(gridScaleTickets.tagName).toBe("DIV");
    expect(gridScaleTickets.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridScaleTickets", () => {
    // START_USER_CODE-USER_gridScaleTickets_TEST
    // END_USER_CODE-USER_gridScaleTickets_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxInterDepartmentalTransfer(GroupBox Widget) Test Cases", async () => {
    const grpbxInterDepartmentalTransfer = screen.getByTestId(
      "grpbxInterDepartmentalTransfer"
    );
    expect(grpbxInterDepartmentalTransfer.tagName).toBe("BUTTON");
    expect(grpbxInterDepartmentalTransfer.type).toBe("button");
    expect(grpbxInterDepartmentalTransfer.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInterDepartmentalTransfer", () => {
    // START_USER_CODE-USER_grpbxInterDepartmentalTransfer_TEST
    // END_USER_CODE-USER_grpbxInterDepartmentalTransfer_TEST
  });
  test("grpbxPeanutInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfo = screen.getByTestId("grpbxPeanutInfo");
    expect(grpbxPeanutInfo.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfo.type).toBe("button");
    expect(grpbxPeanutInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutInfo", () => {
    // START_USER_CODE-USER_grpbxPeanutInfo_TEST
    // END_USER_CODE-USER_grpbxPeanutInfo_TEST
  });
  test("grpbxPntShippd(GroupBox Widget) Test Cases", async () => {
    const grpbxPntShippd = screen.getByTestId("grpbxPntShippd");
    expect(grpbxPntShippd.tagName).toBe("BUTTON");
    expect(grpbxPntShippd.type).toBe("button");
    expect(grpbxPntShippd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPntShippd", () => {
    // START_USER_CODE-USER_grpbxPntShippd_TEST
    // END_USER_CODE-USER_grpbxPntShippd_TEST
  });
  test("grpbxReGrading(GroupBox Widget) Test Cases", async () => {
    const grpbxReGrading = screen.getByTestId("grpbxReGrading");
    expect(grpbxReGrading.tagName).toBe("BUTTON");
    expect(grpbxReGrading.type).toBe("button");
    expect(grpbxReGrading.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReGrading", () => {
    // START_USER_CODE-USER_grpbxReGrading_TEST
    // END_USER_CODE-USER_grpbxReGrading_TEST
  });
  test("grpbxScaleTickets(GroupBox Widget) Test Cases", async () => {
    const grpbxScaleTickets = screen.getByTestId("grpbxScaleTickets");
    expect(grpbxScaleTickets.tagName).toBe("BUTTON");
    expect(grpbxScaleTickets.type).toBe("button");
    expect(grpbxScaleTickets.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScaleTickets", () => {
    // START_USER_CODE-USER_grpbxScaleTickets_TEST
    // END_USER_CODE-USER_grpbxScaleTickets_TEST
  });
  test("grpbxSeg(GroupBox Widget) Test Cases", async () => {
    const grpbxSeg = screen.getByTestId("grpbxSeg");
    expect(grpbxSeg.tagName).toBe("BUTTON");
    expect(grpbxSeg.type).toBe("button");
    expect(grpbxSeg.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSeg", () => {
    // START_USER_CODE-USER_grpbxSeg_TEST
    // END_USER_CODE-USER_grpbxSeg_TEST
  });
  test("grpbxSettlementInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxSettlementInformation = screen.getByTestId(
      "grpbxSettlementInformation"
    );
    expect(grpbxSettlementInformation.tagName).toBe("BUTTON");
    expect(grpbxSettlementInformation.type).toBe("button");
    expect(grpbxSettlementInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSettlementInformation", () => {
    // START_USER_CODE-USER_grpbxSettlementInformation_TEST
    // END_USER_CODE-USER_grpbxSettlementInformation_TEST
  });
  test("grpbxShippedFrom(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedFrom = screen.getByTestId("grpbxShippedFrom");
    expect(grpbxShippedFrom.tagName).toBe("BUTTON");
    expect(grpbxShippedFrom.type).toBe("button");
    expect(grpbxShippedFrom.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedFrom", () => {
    // START_USER_CODE-USER_grpbxShippedFrom_TEST
    // END_USER_CODE-USER_grpbxShippedFrom_TEST
  });
  test("grpbxShippedTo(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedTo = screen.getByTestId("grpbxShippedTo");
    expect(grpbxShippedTo.tagName).toBe("BUTTON");
    expect(grpbxShippedTo.type).toBe("button");
    expect(grpbxShippedTo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedTo", () => {
    // START_USER_CODE-USER_grpbxShippedTo_TEST
    // END_USER_CODE-USER_grpbxShippedTo_TEST
  });
  test("grpbxTradeDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeDetails = screen.getByTestId("grpbxTradeDetails");
    expect(grpbxTradeDetails.tagName).toBe("BUTTON");
    expect(grpbxTradeDetails.type).toBe("button");
    expect(grpbxTradeDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeDetails", () => {
    // START_USER_CODE-USER_grpbxTradeDetails_TEST
    // END_USER_CODE-USER_grpbxTradeDetails_TEST
  });
  test("grpbxTradeSettleProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeSettleProfile = screen.getByTestId(
      "grpbxTradeSettleProfile"
    );
    expect(grpbxTradeSettleProfile.tagName).toBe("BUTTON");
    expect(grpbxTradeSettleProfile.type).toBe("button");
    expect(grpbxTradeSettleProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeSettleProfile", () => {
    // START_USER_CODE-USER_grpbxTradeSettleProfile_TEST
    // END_USER_CODE-USER_grpbxTradeSettleProfile_TEST
  });
  test("grpbxValueCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxValueCalculation = screen.getByTestId("grpbxValueCalculation");
    expect(grpbxValueCalculation.tagName).toBe("BUTTON");
    expect(grpbxValueCalculation.type).toBe("button");
    expect(grpbxValueCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValueCalculation", () => {
    // START_USER_CODE-USER_grpbxValueCalculation_TEST
    // END_USER_CODE-USER_grpbxValueCalculation_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("grpbxVendorSplitSub(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitSub = screen.getByTestId("grpbxVendorSplitSub");
    expect(grpbxVendorSplitSub.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitSub.type).toBe("button");
    expect(grpbxVendorSplitSub.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitSub", () => {
    // START_USER_CODE-USER_grpbxVendorSplitSub_TEST
    // END_USER_CODE-USER_grpbxVendorSplitSub_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblConcealedRMD(Label Widget) Test Cases", async () => {
    const lblConcealedRMD = screen.getByTestId("lblConcealedRMD");
    expect(lblConcealedRMD.tagName).toBe("LABEL");
    expect(lblConcealedRMD.classList).toContain("form-label");
    expect(lblConcealedRMD.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblConcealedRMD")
    );
  });
  test("Custom Test Cases for lblConcealedRMD", () => {
    // START_USER_CODE-USER_lblConcealedRMD_TEST
    // END_USER_CODE-USER_lblConcealedRMD_TEST
  });
  test("lblConcealedRMDGr(Label Widget) Test Cases", async () => {
    const lblConcealedRMDGr = screen.getByTestId("lblConcealedRMDGr");
    expect(lblConcealedRMDGr.tagName).toBe("LABEL");
    expect(lblConcealedRMDGr.classList).toContain("form-label");
    expect(lblConcealedRMDGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblConcealedRMDGr")
    );
  });
  test("Custom Test Cases for lblConcealedRMDGr", () => {
    // START_USER_CODE-USER_lblConcealedRMDGr_TEST
    // END_USER_CODE-USER_lblConcealedRMDGr_TEST
  });
  test("lblCrackedBroken(Label Widget) Test Cases", async () => {
    const lblCrackedBroken = screen.getByTestId("lblCrackedBroken");
    expect(lblCrackedBroken.tagName).toBe("LABEL");
    expect(lblCrackedBroken.classList).toContain("form-label");
    expect(lblCrackedBroken.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblCrackedBroken")
    );
  });
  test("Custom Test Cases for lblCrackedBroken", () => {
    // START_USER_CODE-USER_lblCrackedBroken_TEST
    // END_USER_CODE-USER_lblCrackedBroken_TEST
  });
  test("lblCrackedBrokenGr(Label Widget) Test Cases", async () => {
    const lblCrackedBrokenGr = screen.getByTestId("lblCrackedBrokenGr");
    expect(lblCrackedBrokenGr.tagName).toBe("LABEL");
    expect(lblCrackedBrokenGr.classList).toContain("form-label");
    expect(lblCrackedBrokenGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblCrackedBrokenGr")
    );
  });
  test("Custom Test Cases for lblCrackedBrokenGr", () => {
    // START_USER_CODE-USER_lblCrackedBrokenGr_TEST
    // END_USER_CODE-USER_lblCrackedBrokenGr_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("lblDiscoloredShells(Label Widget) Test Cases", async () => {
    const lblDiscoloredShells = screen.getByTestId("lblDiscoloredShells");
    expect(lblDiscoloredShells.tagName).toBe("LABEL");
    expect(lblDiscoloredShells.classList).toContain("form-label");
    expect(lblDiscoloredShells.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblDiscoloredShells")
    );
  });
  test("Custom Test Cases for lblDiscoloredShells", () => {
    // START_USER_CODE-USER_lblDiscoloredShells_TEST
    // END_USER_CODE-USER_lblDiscoloredShells_TEST
  });
  test("lblDiscoloredShellsGr(Label Widget) Test Cases", async () => {
    const lblDiscoloredShellsGr = screen.getByTestId("lblDiscoloredShellsGr");
    expect(lblDiscoloredShellsGr.tagName).toBe("LABEL");
    expect(lblDiscoloredShellsGr.classList).toContain("form-label");
    expect(lblDiscoloredShellsGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblDiscoloredShellsGr")
    );
  });
  test("Custom Test Cases for lblDiscoloredShellsGr", () => {
    // START_USER_CODE-USER_lblDiscoloredShellsGr_TEST
    // END_USER_CODE-USER_lblDiscoloredShellsGr_TEST
  });
  test("lblFancy(Label Widget) Test Cases", async () => {
    const lblFancy = screen.getByTestId("lblFancy");
    expect(lblFancy.tagName).toBe("LABEL");
    expect(lblFancy.classList).toContain("form-label");
    expect(lblFancy.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblFancy")
    );
  });
  test("Custom Test Cases for lblFancy", () => {
    // START_USER_CODE-USER_lblFancy_TEST
    // END_USER_CODE-USER_lblFancy_TEST
  });
  test("lblFancyGr(Label Widget) Test Cases", async () => {
    const lblFancyGr = screen.getByTestId("lblFancyGr");
    expect(lblFancyGr.tagName).toBe("LABEL");
    expect(lblFancyGr.classList).toContain("form-label");
    expect(lblFancyGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblFancyGr")
    );
  });
  test("Custom Test Cases for lblFancyGr", () => {
    // START_USER_CODE-USER_lblFancyGr_TEST
    // END_USER_CODE-USER_lblFancyGr_TEST
  });
  test("lblForeignMaterial(Label Widget) Test Cases", async () => {
    const lblForeignMaterial = screen.getByTestId("lblForeignMaterial");
    expect(lblForeignMaterial.tagName).toBe("LABEL");
    expect(lblForeignMaterial.classList).toContain("form-label");
    expect(lblForeignMaterial.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblForeignMaterial")
    );
  });
  test("Custom Test Cases for lblForeignMaterial", () => {
    // START_USER_CODE-USER_lblForeignMaterial_TEST
    // END_USER_CODE-USER_lblForeignMaterial_TEST
  });
  test("lblForeignMaterialGr(Label Widget) Test Cases", async () => {
    const lblForeignMaterialGr = screen.getByTestId("lblForeignMaterialGr");
    expect(lblForeignMaterialGr.tagName).toBe("LABEL");
    expect(lblForeignMaterialGr.classList).toContain("form-label");
    expect(lblForeignMaterialGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblForeignMaterialGr")
    );
  });
  test("Custom Test Cases for lblForeignMaterialGr", () => {
    // START_USER_CODE-USER_lblForeignMaterialGr_TEST
    // END_USER_CODE-USER_lblForeignMaterialGr_TEST
  });
  test("lblFreezeDamage(Label Widget) Test Cases", async () => {
    const lblFreezeDamage = screen.getByTestId("lblFreezeDamage");
    expect(lblFreezeDamage.tagName).toBe("LABEL");
    expect(lblFreezeDamage.classList).toContain("form-label");
    expect(lblFreezeDamage.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblFreezeDamage")
    );
  });
  test("Custom Test Cases for lblFreezeDamage", () => {
    // START_USER_CODE-USER_lblFreezeDamage_TEST
    // END_USER_CODE-USER_lblFreezeDamage_TEST
  });
  test("lblFreezeDamageGr(Label Widget) Test Cases", async () => {
    const lblFreezeDamageGr = screen.getByTestId("lblFreezeDamageGr");
    expect(lblFreezeDamageGr.tagName).toBe("LABEL");
    expect(lblFreezeDamageGr.classList).toContain("form-label");
    expect(lblFreezeDamageGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblFreezeDamageGr")
    );
  });
  test("Custom Test Cases for lblFreezeDamageGr", () => {
    // START_USER_CODE-USER_lblFreezeDamageGr_TEST
    // END_USER_CODE-USER_lblFreezeDamageGr_TEST
  });
  test("lblHullBrightness(Label Widget) Test Cases", async () => {
    const lblHullBrightness = screen.getByTestId("lblHullBrightness");
    expect(lblHullBrightness.tagName).toBe("LABEL");
    expect(lblHullBrightness.classList).toContain("form-label");
    expect(lblHullBrightness.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblHullBrightness")
    );
  });
  test("Custom Test Cases for lblHullBrightness", () => {
    // START_USER_CODE-USER_lblHullBrightness_TEST
    // END_USER_CODE-USER_lblHullBrightness_TEST
  });
  test("lblHullBrightnessGr(Label Widget) Test Cases", async () => {
    const lblHullBrightnessGr = screen.getByTestId("lblHullBrightnessGr");
    expect(lblHullBrightnessGr.tagName).toBe("LABEL");
    expect(lblHullBrightnessGr.classList).toContain("form-label");
    expect(lblHullBrightnessGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblHullBrightnessGr")
    );
  });
  test("Custom Test Cases for lblHullBrightnessGr", () => {
    // START_USER_CODE-USER_lblHullBrightnessGr_TEST
    // END_USER_CODE-USER_lblHullBrightnessGr_TEST
  });
  test("lblHulls(Label Widget) Test Cases", async () => {
    const lblHulls = screen.getByTestId("lblHulls");
    expect(lblHulls.tagName).toBe("LABEL");
    expect(lblHulls.classList).toContain("form-label");
    expect(lblHulls.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblHulls")
    );
  });
  test("Custom Test Cases for lblHulls", () => {
    // START_USER_CODE-USER_lblHulls_TEST
    // END_USER_CODE-USER_lblHulls_TEST
  });
  test("lblHullsGr(Label Widget) Test Cases", async () => {
    const lblHullsGr = screen.getByTestId("lblHullsGr");
    expect(lblHullsGr.tagName).toBe("LABEL");
    expect(lblHullsGr.classList).toContain("form-label");
    expect(lblHullsGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblHullsGr")
    );
  });
  test("Custom Test Cases for lblHullsGr", () => {
    // START_USER_CODE-USER_lblHullsGr_TEST
    // END_USER_CODE-USER_lblHullsGr_TEST
  });
  test("lblInterDeptTransfer(Label Widget) Test Cases", async () => {
    const lblInterDeptTransfer = screen.getByTestId("lblInterDeptTransfer");
    expect(lblInterDeptTransfer.tagName).toBe("LABEL");
    expect(lblInterDeptTransfer.classList).toContain("form-label");
    expect(lblInterDeptTransfer.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblInterDeptTransfer")
    );
  });
  test("Custom Test Cases for lblInterDeptTransfer", () => {
    // START_USER_CODE-USER_lblInterDeptTransfer_TEST
    // END_USER_CODE-USER_lblInterDeptTransfer_TEST
  });
  test("lblJumbo(Label Widget) Test Cases", async () => {
    const lblJumbo = screen.getByTestId("lblJumbo");
    expect(lblJumbo.tagName).toBe("LABEL");
    expect(lblJumbo.classList).toContain("form-label");
    expect(lblJumbo.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblJumbo")
    );
  });
  test("Custom Test Cases for lblJumbo", () => {
    // START_USER_CODE-USER_lblJumbo_TEST
    // END_USER_CODE-USER_lblJumbo_TEST
  });
  test("lblJumboGr(Label Widget) Test Cases", async () => {
    const lblJumboGr = screen.getByTestId("lblJumboGr");
    expect(lblJumboGr.tagName).toBe("LABEL");
    expect(lblJumboGr.classList).toContain("form-label");
    expect(lblJumboGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblJumboGr")
    );
  });
  test("Custom Test Cases for lblJumboGr", () => {
    // START_USER_CODE-USER_lblJumboGr_TEST
    // END_USER_CODE-USER_lblJumboGr_TEST
  });
  test("lblKernRidingELK(Label Widget) Test Cases", async () => {
    const lblKernRidingELK = screen.getByTestId("lblKernRidingELK");
    expect(lblKernRidingELK.tagName).toBe("LABEL");
    expect(lblKernRidingELK.classList).toContain("form-label");
    expect(lblKernRidingELK.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblKernRidingELK")
    );
  });
  test("Custom Test Cases for lblKernRidingELK", () => {
    // START_USER_CODE-USER_lblKernRidingELK_TEST
    // END_USER_CODE-USER_lblKernRidingELK_TEST
  });
  test("lblKernRidingELKGr(Label Widget) Test Cases", async () => {
    const lblKernRidingELKGr = screen.getByTestId("lblKernRidingELKGr");
    expect(lblKernRidingELKGr.tagName).toBe("LABEL");
    expect(lblKernRidingELKGr.classList).toContain("form-label");
    expect(lblKernRidingELKGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblKernRidingELKGr")
    );
  });
  test("Custom Test Cases for lblKernRidingELKGr", () => {
    // START_USER_CODE-USER_lblKernRidingELKGr_TEST
    // END_USER_CODE-USER_lblKernRidingELKGr_TEST
  });
  test("lblKernRidingELKPct(Textbox Widget) Test Cases", async () => {
    const lblKernRidingELKPct = screen.getByTestId("lblKernRidingELKPct");
    expect(lblKernRidingELKPct.tagName).toBe("INPUT");
    expect(lblKernRidingELKPct.type).toBe("text");
    expect(lblKernRidingELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(lblKernRidingELKPct, "1");
    });
  });
  test("Custom Test Cases for lblKernRidingELKPct", () => {
    // START_USER_CODE-USER_lblKernRidingELKPct_TEST
    // END_USER_CODE-USER_lblKernRidingELKPct_TEST
  });
  test("lblKernRidingPrescribe(Label Widget) Test Cases", async () => {
    const lblKernRidingPrescribe = screen.getByTestId("lblKernRidingPrescribe");
    expect(lblKernRidingPrescribe.tagName).toBe("LABEL");
    expect(lblKernRidingPrescribe.classList).toContain("form-label");
    expect(lblKernRidingPrescribe.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblKernRidingPrescribe")
    );
  });
  test("Custom Test Cases for lblKernRidingPrescribe", () => {
    // START_USER_CODE-USER_lblKernRidingPrescribe_TEST
    // END_USER_CODE-USER_lblKernRidingPrescribe_TEST
  });
  test("lblKernRidingPrescribeGr(Label Widget) Test Cases", async () => {
    const lblKernRidingPrescribeGr = screen.getByTestId(
      "lblKernRidingPrescribeGr"
    );
    expect(lblKernRidingPrescribeGr.tagName).toBe("LABEL");
    expect(lblKernRidingPrescribeGr.classList).toContain("form-label");
    expect(lblKernRidingPrescribeGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblKernRidingPrescribeGr")
    );
  });
  test("Custom Test Cases for lblKernRidingPrescribeGr", () => {
    // START_USER_CODE-USER_lblKernRidingPrescribeGr_TEST
    // END_USER_CODE-USER_lblKernRidingPrescribeGr_TEST
  });
  test("lblNetELK(Label Widget) Test Cases", async () => {
    const lblNetELK = screen.getByTestId("lblNetELK");
    expect(lblNetELK.tagName).toBe("LABEL");
    expect(lblNetELK.classList).toContain("form-label");
    expect(lblNetELK.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblNetELK")
    );
  });
  test("Custom Test Cases for lblNetELK", () => {
    // START_USER_CODE-USER_lblNetELK_TEST
    // END_USER_CODE-USER_lblNetELK_TEST
  });
  test("lblNetELKGr(Label Widget) Test Cases", async () => {
    const lblNetELKGr = screen.getByTestId("lblNetELKGr");
    expect(lblNetELKGr.tagName).toBe("LABEL");
    expect(lblNetELKGr.classList).toContain("form-label");
    expect(lblNetELKGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblNetELKGr")
    );
  });
  test("Custom Test Cases for lblNetELKGr", () => {
    // START_USER_CODE-USER_lblNetELKGr_TEST
    // END_USER_CODE-USER_lblNetELKGr_TEST
  });
  test("lblPeanutInformation(Label Widget) Test Cases", async () => {
    const lblPeanutInformation = screen.getByTestId("lblPeanutInformation");
    expect(lblPeanutInformation.tagName).toBe("LABEL");
    expect(lblPeanutInformation.classList).toContain("form-label");
    expect(lblPeanutInformation.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPeanutInformation")
    );
  });
  test("Custom Test Cases for lblPeanutInformation", () => {
    // START_USER_CODE-USER_lblPeanutInformation_TEST
    // END_USER_CODE-USER_lblPeanutInformation_TEST
  });
  test("lblPerLb(Label Widget) Test Cases", async () => {
    const lblPerLb = screen.getByTestId("lblPerLb");
    expect(lblPerLb.tagName).toBe("LABEL");
    expect(lblPerLb.classList).toContain("form-label");
    expect(lblPerLb.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPerLb")
    );
  });
  test("Custom Test Cases for lblPerLb", () => {
    // START_USER_CODE-USER_lblPerLb_TEST
    // END_USER_CODE-USER_lblPerLb_TEST
  });
  test("lblPerLb2(Label Widget) Test Cases", async () => {
    const lblPerLb2 = screen.getByTestId("lblPerLb2");
    expect(lblPerLb2.tagName).toBe("LABEL");
    expect(lblPerLb2.classList).toContain("form-label");
    expect(lblPerLb2.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPerLb2")
    );
  });
  test("Custom Test Cases for lblPerLb2", () => {
    // START_USER_CODE-USER_lblPerLb2_TEST
    // END_USER_CODE-USER_lblPerLb2_TEST
  });
  test("lblPerLb3(Label Widget) Test Cases", async () => {
    const lblPerLb3 = screen.getByTestId("lblPerLb3");
    expect(lblPerLb3.tagName).toBe("LABEL");
    expect(lblPerLb3.classList).toContain("form-label");
    expect(lblPerLb3.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPerLb3")
    );
  });
  test("Custom Test Cases for lblPerLb3", () => {
    // START_USER_CODE-USER_lblPerLb3_TEST
    // END_USER_CODE-USER_lblPerLb3_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblPittingDamage(Label Widget) Test Cases", async () => {
    const lblPittingDamage = screen.getByTestId("lblPittingDamage");
    expect(lblPittingDamage.tagName).toBe("LABEL");
    expect(lblPittingDamage.classList).toContain("form-label");
    expect(lblPittingDamage.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPittingDamage")
    );
  });
  test("Custom Test Cases for lblPittingDamage", () => {
    // START_USER_CODE-USER_lblPittingDamage_TEST
    // END_USER_CODE-USER_lblPittingDamage_TEST
  });
  test("lblPittingDamageGr(Label Widget) Test Cases", async () => {
    const lblPittingDamageGr = screen.getByTestId("lblPittingDamageGr");
    expect(lblPittingDamageGr.tagName).toBe("LABEL");
    expect(lblPittingDamageGr.classList).toContain("form-label");
    expect(lblPittingDamageGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPittingDamageGr")
    );
  });
  test("Custom Test Cases for lblPittingDamageGr", () => {
    // START_USER_CODE-USER_lblPittingDamageGr_TEST
    // END_USER_CODE-USER_lblPittingDamageGr_TEST
  });
  test("lblPrintSeg(Label Widget) Test Cases", async () => {
    const lblPrintSeg = screen.getByTestId("lblPrintSeg");
    expect(lblPrintSeg.tagName).toBe("LABEL");
    expect(lblPrintSeg.classList).toContain("form-label");
    expect(lblPrintSeg.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblPrintSeg")
    );
  });
  test("Custom Test Cases for lblPrintSeg", () => {
    // START_USER_CODE-USER_lblPrintSeg_TEST
    // END_USER_CODE-USER_lblPrintSeg_TEST
  });
  test("lblProceeds(Label Widget) Test Cases", async () => {
    const lblProceeds = screen.getByTestId("lblProceeds");
    expect(lblProceeds.tagName).toBe("LABEL");
    expect(lblProceeds.classList).toContain("form-label");
    expect(lblProceeds.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblProceeds")
    );
  });
  test("Custom Test Cases for lblProceeds", () => {
    // START_USER_CODE-USER_lblProceeds_TEST
    // END_USER_CODE-USER_lblProceeds_TEST
  });
  test("lblProceedsValue(Label Widget) Test Cases", async () => {
    const lblProceedsValue = screen.getByTestId("lblProceedsValue");
    expect(lblProceedsValue.tagName).toBe("LABEL");
    expect(lblProceedsValue.classList).toContain("form-label");
    expect(lblProceedsValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblProceedsValue")
    );
  });
  test("Custom Test Cases for lblProceedsValue", () => {
    // START_USER_CODE-USER_lblProceedsValue_TEST
    // END_USER_CODE-USER_lblProceedsValue_TEST
  });
  test("lblReGrading(Label Widget) Test Cases", async () => {
    const lblReGrading = screen.getByTestId("lblReGrading");
    expect(lblReGrading.tagName).toBe("LABEL");
    expect(lblReGrading.classList).toContain("form-label");
    expect(lblReGrading.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblReGrading")
    );
  });
  test("Custom Test Cases for lblReGrading", () => {
    // START_USER_CODE-USER_lblReGrading_TEST
    // END_USER_CODE-USER_lblReGrading_TEST
  });
  test("lblRemitTo(Label Widget) Test Cases", async () => {
    const lblRemitTo = screen.getByTestId("lblRemitTo");
    expect(lblRemitTo.tagName).toBe("LABEL");
    expect(lblRemitTo.classList).toContain("form-label");
    expect(lblRemitTo.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblRemitTo")
    );
  });
  test("Custom Test Cases for lblRemitTo", () => {
    // START_USER_CODE-USER_lblRemitTo_TEST
    // END_USER_CODE-USER_lblRemitTo_TEST
  });
  test("lblScaleTickets(Label Widget) Test Cases", async () => {
    const lblScaleTickets = screen.getByTestId("lblScaleTickets");
    expect(lblScaleTickets.tagName).toBe("LABEL");
    expect(lblScaleTickets.classList).toContain("form-label");
    expect(lblScaleTickets.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblScaleTickets")
    );
  });
  test("Custom Test Cases for lblScaleTickets", () => {
    // START_USER_CODE-USER_lblScaleTickets_TEST
    // END_USER_CODE-USER_lblScaleTickets_TEST
  });
  test("lblSeedMeetingSpec(Label Widget) Test Cases", async () => {
    const lblSeedMeetingSpec = screen.getByTestId("lblSeedMeetingSpec");
    expect(lblSeedMeetingSpec.tagName).toBe("LABEL");
    expect(lblSeedMeetingSpec.classList).toContain("form-label");
    expect(lblSeedMeetingSpec.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSeedMeetingSpec")
    );
  });
  test("Custom Test Cases for lblSeedMeetingSpec", () => {
    // START_USER_CODE-USER_lblSeedMeetingSpec_TEST
    // END_USER_CODE-USER_lblSeedMeetingSpec_TEST
  });
  test("lblSeedMeetingSpecValue(Label Widget) Test Cases", async () => {
    const lblSeedMeetingSpecValue = screen.getByTestId(
      "lblSeedMeetingSpecValue"
    );
    expect(lblSeedMeetingSpecValue.tagName).toBe("LABEL");
    expect(lblSeedMeetingSpecValue.classList).toContain("form-label");
    expect(lblSeedMeetingSpecValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSeedMeetingSpecValue")
    );
  });
  test("Custom Test Cases for lblSeedMeetingSpecValue", () => {
    // START_USER_CODE-USER_lblSeedMeetingSpecValue_TEST
    // END_USER_CODE-USER_lblSeedMeetingSpecValue_TEST
  });
  test("lblSettlementInformation(Label Widget) Test Cases", async () => {
    const lblSettlementInformation = screen.getByTestId(
      "lblSettlementInformation"
    );
    expect(lblSettlementInformation.tagName).toBe("LABEL");
    expect(lblSettlementInformation.classList).toContain("form-label");
    expect(lblSettlementInformation.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSettlementInformation")
    );
  });
  test("Custom Test Cases for lblSettlementInformation", () => {
    // START_USER_CODE-USER_lblSettlementInformation_TEST
    // END_USER_CODE-USER_lblSettlementInformation_TEST
  });
  test("lblSharePercentage(Label Widget) Test Cases", async () => {
    const lblSharePercentage = screen.getByTestId("lblSharePercentage");
    expect(lblSharePercentage.tagName).toBe("LABEL");
    expect(lblSharePercentage.classList).toContain("form-label");
    expect(lblSharePercentage.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSharePercentage")
    );
  });
  test("Custom Test Cases for lblSharePercentage", () => {
    // START_USER_CODE-USER_lblSharePercentage_TEST
    // END_USER_CODE-USER_lblSharePercentage_TEST
  });
  test("lblShippedFrom(Label Widget) Test Cases", async () => {
    const lblShippedFrom = screen.getByTestId("lblShippedFrom");
    expect(lblShippedFrom.tagName).toBe("LABEL");
    expect(lblShippedFrom.classList).toContain("form-label");
    expect(lblShippedFrom.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblShippedFrom")
    );
  });
  test("Custom Test Cases for lblShippedFrom", () => {
    // START_USER_CODE-USER_lblShippedFrom_TEST
    // END_USER_CODE-USER_lblShippedFrom_TEST
  });
  test("lblShippedTo(Label Widget) Test Cases", async () => {
    const lblShippedTo = screen.getByTestId("lblShippedTo");
    expect(lblShippedTo.tagName).toBe("LABEL");
    expect(lblShippedTo.classList).toContain("form-label");
    expect(lblShippedTo.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblShippedTo")
    );
  });
  test("Custom Test Cases for lblShippedTo", () => {
    // START_USER_CODE-USER_lblShippedTo_TEST
    // END_USER_CODE-USER_lblShippedTo_TEST
  });
  test("lblSoundSplits(Label Widget) Test Cases", async () => {
    const lblSoundSplits = screen.getByTestId("lblSoundSplits");
    expect(lblSoundSplits.tagName).toBe("LABEL");
    expect(lblSoundSplits.classList).toContain("form-label");
    expect(lblSoundSplits.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSoundSplits")
    );
  });
  test("Custom Test Cases for lblSoundSplits", () => {
    // START_USER_CODE-USER_lblSoundSplits_TEST
    // END_USER_CODE-USER_lblSoundSplits_TEST
  });
  test("lblSoundSplitsGr(Label Widget) Test Cases", async () => {
    const lblSoundSplitsGr = screen.getByTestId("lblSoundSplitsGr");
    expect(lblSoundSplitsGr.tagName).toBe("LABEL");
    expect(lblSoundSplitsGr.classList).toContain("form-label");
    expect(lblSoundSplitsGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSoundSplitsGr")
    );
  });
  test("Custom Test Cases for lblSoundSplitsGr", () => {
    // START_USER_CODE-USER_lblSoundSplitsGr_TEST
    // END_USER_CODE-USER_lblSoundSplitsGr_TEST
  });
  test("lblSts(Label Widget) Test Cases", async () => {
    const lblSts = screen.getByTestId("lblSts");
    expect(lblSts.tagName).toBe("LABEL");
    expect(lblSts.classList).toContain("form-label");
    expect(lblSts.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblSts")
    );
  });
  test("Custom Test Cases for lblSts", () => {
    // START_USER_CODE-USER_lblSts_TEST
    // END_USER_CODE-USER_lblSts_TEST
  });
  test("lblStsVal(Label Widget) Test Cases", async () => {
    const lblStsVal = screen.getByTestId("lblStsVal");
    expect(lblStsVal.tagName).toBe("LABEL");
    expect(lblStsVal.classList).toContain("form-label");
    expect(lblStsVal.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblStsVal")
    );
  });
  test("Custom Test Cases for lblStsVal", () => {
    // START_USER_CODE-USER_lblStsVal_TEST
    // END_USER_CODE-USER_lblStsVal_TEST
  });
  test("lblTckt(Label Widget) Test Cases", async () => {
    const lblTckt = screen.getByTestId("lblTckt");
    expect(lblTckt.tagName).toBe("LABEL");
    expect(lblTckt.classList).toContain("form-label");
    expect(lblTckt.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTckt")
    );
  });
  test("Custom Test Cases for lblTckt", () => {
    // START_USER_CODE-USER_lblTckt_TEST
    // END_USER_CODE-USER_lblTckt_TEST
  });
  test("lblTcktVal(Label Widget) Test Cases", async () => {
    const lblTcktVal = screen.getByTestId("lblTcktVal");
    expect(lblTcktVal.tagName).toBe("LABEL");
    expect(lblTcktVal.classList).toContain("form-label");
    expect(lblTcktVal.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTcktVal")
    );
  });
  test("Custom Test Cases for lblTcktVal", () => {
    // START_USER_CODE-USER_lblTcktVal_TEST
    // END_USER_CODE-USER_lblTcktVal_TEST
  });
  test("lblTotal(Label Widget) Test Cases", async () => {
    const lblTotal = screen.getByTestId("lblTotal");
    expect(lblTotal.tagName).toBe("LABEL");
    expect(lblTotal.classList).toContain("form-label");
    expect(lblTotal.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotal")
    );
  });
  test("Custom Test Cases for lblTotal", () => {
    // START_USER_CODE-USER_lblTotal_TEST
    // END_USER_CODE-USER_lblTotal_TEST
  });
  test("lblTotalDamage(Label Widget) Test Cases", async () => {
    const lblTotalDamage = screen.getByTestId("lblTotalDamage");
    expect(lblTotalDamage.tagName).toBe("LABEL");
    expect(lblTotalDamage.classList).toContain("form-label");
    expect(lblTotalDamage.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalDamage")
    );
  });
  test("Custom Test Cases for lblTotalDamage", () => {
    // START_USER_CODE-USER_lblTotalDamage_TEST
    // END_USER_CODE-USER_lblTotalDamage_TEST
  });
  test("lblTotalDamageGr(Label Widget) Test Cases", async () => {
    const lblTotalDamageGr = screen.getByTestId("lblTotalDamageGr");
    expect(lblTotalDamageGr.tagName).toBe("LABEL");
    expect(lblTotalDamageGr.classList).toContain("form-label");
    expect(lblTotalDamageGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalDamageGr")
    );
  });
  test("Custom Test Cases for lblTotalDamageGr", () => {
    // START_USER_CODE-USER_lblTotalDamageGr_TEST
    // END_USER_CODE-USER_lblTotalDamageGr_TEST
  });
  test("lblTotalELKDamage(Label Widget) Test Cases", async () => {
    const lblTotalELKDamage = screen.getByTestId("lblTotalELKDamage");
    expect(lblTotalELKDamage.tagName).toBe("LABEL");
    expect(lblTotalELKDamage.classList).toContain("form-label");
    expect(lblTotalELKDamage.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalELKDamage")
    );
  });
  test("Custom Test Cases for lblTotalELKDamage", () => {
    // START_USER_CODE-USER_lblTotalELKDamage_TEST
    // END_USER_CODE-USER_lblTotalELKDamage_TEST
  });
  test("lblTotalELKDamageGr(Label Widget) Test Cases", async () => {
    const lblTotalELKDamageGr = screen.getByTestId("lblTotalELKDamageGr");
    expect(lblTotalELKDamageGr.tagName).toBe("LABEL");
    expect(lblTotalELKDamageGr.classList).toContain("form-label");
    expect(lblTotalELKDamageGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalELKDamageGr")
    );
  });
  test("Custom Test Cases for lblTotalELKDamageGr", () => {
    // START_USER_CODE-USER_lblTotalELKDamageGr_TEST
    // END_USER_CODE-USER_lblTotalELKDamageGr_TEST
  });
  test("lblTotalKernalsValue(Label Widget) Test Cases", async () => {
    const lblTotalKernalsValue = screen.getByTestId("lblTotalKernalsValue");
    expect(lblTotalKernalsValue.tagName).toBe("LABEL");
    expect(lblTotalKernalsValue.classList).toContain("form-label");
    expect(lblTotalKernalsValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalKernalsValue")
    );
  });
  test("Custom Test Cases for lblTotalKernalsValue", () => {
    // START_USER_CODE-USER_lblTotalKernalsValue_TEST
    // END_USER_CODE-USER_lblTotalKernalsValue_TEST
  });
  test("lblTotalKernels(Label Widget) Test Cases", async () => {
    const lblTotalKernels = screen.getByTestId("lblTotalKernels");
    expect(lblTotalKernels.tagName).toBe("LABEL");
    expect(lblTotalKernels.classList).toContain("form-label");
    expect(lblTotalKernels.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalKernels")
    );
  });
  test("Custom Test Cases for lblTotalKernels", () => {
    // START_USER_CODE-USER_lblTotalKernels_TEST
    // END_USER_CODE-USER_lblTotalKernels_TEST
  });
  test("lblTotalKernelsAndHulls(Label Widget) Test Cases", async () => {
    const lblTotalKernelsAndHulls = screen.getByTestId(
      "lblTotalKernelsAndHulls"
    );
    expect(lblTotalKernelsAndHulls.tagName).toBe("LABEL");
    expect(lblTotalKernelsAndHulls.classList).toContain("form-label");
    expect(lblTotalKernelsAndHulls.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalKernelsAndHulls")
    );
  });
  test("Custom Test Cases for lblTotalKernelsAndHulls", () => {
    // START_USER_CODE-USER_lblTotalKernelsAndHulls_TEST
    // END_USER_CODE-USER_lblTotalKernelsAndHulls_TEST
  });
  test("lblTotalKernelsAndHullsValue(Label Widget) Test Cases", async () => {
    const lblTotalKernelsAndHullsValue = screen.getByTestId(
      "lblTotalKernelsAndHullsValue"
    );
    expect(lblTotalKernelsAndHullsValue.tagName).toBe("LABEL");
    expect(lblTotalKernelsAndHullsValue.classList).toContain("form-label");
    expect(lblTotalKernelsAndHullsValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalKernelsAndHullsValue")
    );
  });
  test("Custom Test Cases for lblTotalKernelsAndHullsValue", () => {
    // START_USER_CODE-USER_lblTotalKernelsAndHullsValue_TEST
    // END_USER_CODE-USER_lblTotalKernelsAndHullsValue_TEST
  });
  test("lblTotalOK(Label Widget) Test Cases", async () => {
    const lblTotalOK = screen.getByTestId("lblTotalOK");
    expect(lblTotalOK.tagName).toBe("LABEL");
    expect(lblTotalOK.classList).toContain("form-label");
    expect(lblTotalOK.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalOK")
    );
  });
  test("Custom Test Cases for lblTotalOK", () => {
    // START_USER_CODE-USER_lblTotalOK_TEST
    // END_USER_CODE-USER_lblTotalOK_TEST
  });
  test("lblTotalOKGr(Label Widget) Test Cases", async () => {
    const lblTotalOKGr = screen.getByTestId("lblTotalOKGr");
    expect(lblTotalOKGr.tagName).toBe("LABEL");
    expect(lblTotalOKGr.classList).toContain("form-label");
    expect(lblTotalOKGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalOKGr")
    );
  });
  test("Custom Test Cases for lblTotalOKGr", () => {
    // START_USER_CODE-USER_lblTotalOKGr_TEST
    // END_USER_CODE-USER_lblTotalOKGr_TEST
  });
  test("lblTotalSLK(Label Widget) Test Cases", async () => {
    const lblTotalSLK = screen.getByTestId("lblTotalSLK");
    expect(lblTotalSLK.tagName).toBe("LABEL");
    expect(lblTotalSLK.classList).toContain("form-label");
    expect(lblTotalSLK.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalSLK")
    );
  });
  test("Custom Test Cases for lblTotalSLK", () => {
    // START_USER_CODE-USER_lblTotalSLK_TEST
    // END_USER_CODE-USER_lblTotalSLK_TEST
  });
  test("lblTotalSLKGr(Label Widget) Test Cases", async () => {
    const lblTotalSLKGr = screen.getByTestId("lblTotalSLKGr");
    expect(lblTotalSLKGr.tagName).toBe("LABEL");
    expect(lblTotalSLKGr.classList).toContain("form-label");
    expect(lblTotalSLKGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalSLKGr")
    );
  });
  test("Custom Test Cases for lblTotalSLKGr", () => {
    // START_USER_CODE-USER_lblTotalSLKGr_TEST
    // END_USER_CODE-USER_lblTotalSLKGr_TEST
  });
  test("lblTotalSMK(Label Widget) Test Cases", async () => {
    const lblTotalSMK = screen.getByTestId("lblTotalSMK");
    expect(lblTotalSMK.tagName).toBe("LABEL");
    expect(lblTotalSMK.classList).toContain("form-label");
    expect(lblTotalSMK.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalSMK")
    );
  });
  test("Custom Test Cases for lblTotalSMK", () => {
    // START_USER_CODE-USER_lblTotalSMK_TEST
    // END_USER_CODE-USER_lblTotalSMK_TEST
  });
  test("lblTotalSMKRScreen(Label Widget) Test Cases", async () => {
    const lblTotalSMKRScreen = screen.getByTestId("lblTotalSMKRScreen");
    expect(lblTotalSMKRScreen.tagName).toBe("LABEL");
    expect(lblTotalSMKRScreen.classList).toContain("form-label");
    expect(lblTotalSMKRScreen.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalSMKRScreen")
    );
  });
  test("Custom Test Cases for lblTotalSMKRScreen", () => {
    // START_USER_CODE-USER_lblTotalSMKRScreen_TEST
    // END_USER_CODE-USER_lblTotalSMKRScreen_TEST
  });
  test("lblTotalSMKRScreenGr(Label Widget) Test Cases", async () => {
    const lblTotalSMKRScreenGr = screen.getByTestId("lblTotalSMKRScreenGr");
    expect(lblTotalSMKRScreenGr.tagName).toBe("LABEL");
    expect(lblTotalSMKRScreenGr.classList).toContain("form-label");
    expect(lblTotalSMKRScreenGr.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalSMKRScreenGr")
    );
  });
  test("Custom Test Cases for lblTotalSMKRScreenGr", () => {
    // START_USER_CODE-USER_lblTotalSMKRScreenGr_TEST
    // END_USER_CODE-USER_lblTotalSMKRScreenGr_TEST
  });
  test("lblTotalSMKValue(Label Widget) Test Cases", async () => {
    const lblTotalSMKValue = screen.getByTestId("lblTotalSMKValue");
    expect(lblTotalSMKValue.tagName).toBe("LABEL");
    expect(lblTotalSMKValue.classList).toContain("form-label");
    expect(lblTotalSMKValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalSMKValue")
    );
  });
  test("Custom Test Cases for lblTotalSMKValue", () => {
    // START_USER_CODE-USER_lblTotalSMKValue_TEST
    // END_USER_CODE-USER_lblTotalSMKValue_TEST
  });
  test("lblTotalVal(Label Widget) Test Cases", async () => {
    const lblTotalVal = screen.getByTestId("lblTotalVal");
    expect(lblTotalVal.tagName).toBe("LABEL");
    expect(lblTotalVal.classList).toContain("form-label");
    expect(lblTotalVal.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblTotalVal")
    );
  });
  test("Custom Test Cases for lblTotalVal", () => {
    // START_USER_CODE-USER_lblTotalVal_TEST
    // END_USER_CODE-USER_lblTotalVal_TEST
  });
  test("lblValueCalculation(Label Widget) Test Cases", async () => {
    const lblValueCalculation = screen.getByTestId("lblValueCalculation");
    expect(lblValueCalculation.tagName).toBe("LABEL");
    expect(lblValueCalculation.classList).toContain("form-label");
    expect(lblValueCalculation.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblValueCalculation")
    );
  });
  test("Custom Test Cases for lblValueCalculation", () => {
    // START_USER_CODE-USER_lblValueCalculation_TEST
    // END_USER_CODE-USER_lblValueCalculation_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorSplits(Label Widget) Test Cases", async () => {
    const lblVendorSplits = screen.getByTestId("lblVendorSplits");
    expect(lblVendorSplits.tagName).toBe("LABEL");
    expect(lblVendorSplits.classList).toContain("form-label");
    expect(lblVendorSplits.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblVendorSplits")
    );
  });
  test("Custom Test Cases for lblVendorSplits", () => {
    // START_USER_CODE-USER_lblVendorSplits_TEST
    // END_USER_CODE-USER_lblVendorSplits_TEST
  });
  test("lblVicamReading(Label Widget) Test Cases", async () => {
    const lblVicamReading = screen.getByTestId("lblVicamReading");
    expect(lblVicamReading.tagName).toBe("LABEL");
    expect(lblVicamReading.classList).toContain("form-label");
    expect(lblVicamReading.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblVicamReading")
    );
  });
  test("Custom Test Cases for lblVicamReading", () => {
    // START_USER_CODE-USER_lblVicamReading_TEST
    // END_USER_CODE-USER_lblVicamReading_TEST
  });
  test("lblVicamReadingValue(Label Widget) Test Cases", async () => {
    const lblVicamReadingValue = screen.getByTestId("lblVicamReadingValue");
    expect(lblVicamReadingValue.tagName).toBe("LABEL");
    expect(lblVicamReadingValue.classList).toContain("form-label");
    expect(lblVicamReadingValue.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblVicamReadingValue")
    );
  });
  test("Custom Test Cases for lblVicamReadingValue", () => {
    // START_USER_CODE-USER_lblVicamReadingValue_TEST
    // END_USER_CODE-USER_lblVicamReadingValue_TEST
  });
  test("lblXX1(Label Widget) Test Cases", async () => {
    const lblXX1 = screen.getByTestId("lblXX1");
    expect(lblXX1.tagName).toBe("LABEL");
    expect(lblXX1.classList).toContain("form-label");
    expect(lblXX1.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblXX1")
    );
  });
  test("Custom Test Cases for lblXX1", () => {
    // START_USER_CODE-USER_lblXX1_TEST
    // END_USER_CODE-USER_lblXX1_TEST
  });
  test("lblXX2(Label Widget) Test Cases", async () => {
    const lblXX2 = screen.getByTestId("lblXX2");
    expect(lblXX2.tagName).toBe("LABEL");
    expect(lblXX2.classList).toContain("form-label");
    expect(lblXX2.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblXX2")
    );
  });
  test("Custom Test Cases for lblXX2", () => {
    // START_USER_CODE-USER_lblXX2_TEST
    // END_USER_CODE-USER_lblXX2_TEST
  });
  test("lblXX3(Label Widget) Test Cases", async () => {
    const lblXX3 = screen.getByTestId("lblXX3");
    expect(lblXX3.tagName).toBe("LABEL");
    expect(lblXX3.classList).toContain("form-label");
    expect(lblXX3.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblXX3")
    );
  });
  test("Custom Test Cases for lblXX3", () => {
    // START_USER_CODE-USER_lblXX3_TEST
    // END_USER_CODE-USER_lblXX3_TEST
  });
  test("lblXX4(Label Widget) Test Cases", async () => {
    const lblXX4 = screen.getByTestId("lblXX4");
    expect(lblXX4.tagName).toBe("LABEL");
    expect(lblXX4.classList).toContain("form-label");
    expect(lblXX4.textContent).toEqual(
      t("Settlements:TradeSettleProfile_lblXX4")
    );
  });
  test("Custom Test Cases for lblXX4", () => {
    // START_USER_CODE-USER_lblXX4_TEST
    // END_USER_CODE-USER_lblXX4_TEST
  });
  test("txt1007Handler(Textbox Widget) Test Cases", async () => {
    const txt1007Handler = screen.getByTestId("txt1007Handler");
    expect(txt1007Handler.tagName).toBe("INPUT");
    expect(txt1007Handler.type).toBe("text");
    expect(txt1007Handler.classList).toContain("textboxWidgetClass");
    expect(txt1007Handler.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txt1007Handler")
    );
    await act(async () => {
      userEvent.type(txt1007Handler, "1");
    });
  });
  test("Custom Test Cases for txt1007Handler", () => {
    // START_USER_CODE-USER_txt1007Handler_TEST
    // END_USER_CODE-USER_txt1007Handler_TEST
  });
  test("txt1007HandlerName(Textbox Widget) Test Cases", async () => {
    const txt1007HandlerName = screen.getByTestId("txt1007HandlerName");
    expect(txt1007HandlerName.tagName).toBe("INPUT");
    expect(txt1007HandlerName.type).toBe("text");
    expect(txt1007HandlerName.classList).toContain("textboxWidgetClass");
    expect(txt1007HandlerName.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txt1007HandlerName")
    );
    await act(async () => {
      userEvent.type(txt1007HandlerName, "1");
    });
  });
  test("Custom Test Cases for txt1007HandlerName", () => {
    // START_USER_CODE-USER_txt1007HandlerName_TEST
    // END_USER_CODE-USER_txt1007HandlerName_TEST
  });
  test("txtAccessFMPerPound(Textbox Widget) Test Cases", async () => {
    const txtAccessFMPerPound = screen.getByTestId("txtAccessFMPerPound");
    expect(txtAccessFMPerPound.tagName).toBe("INPUT");
    expect(txtAccessFMPerPound.type).toBe("text");
    expect(txtAccessFMPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAccessFMPerPound, "1");
    });
  });
  test("Custom Test Cases for txtAccessFMPerPound", () => {
    // START_USER_CODE-USER_txtAccessFMPerPound_TEST
    // END_USER_CODE-USER_txtAccessFMPerPound_TEST
  });
  test("txtAccessFMPerTon(Textbox Widget) Test Cases", async () => {
    const txtAccessFMPerTon = screen.getByTestId("txtAccessFMPerTon");
    expect(txtAccessFMPerTon.tagName).toBe("INPUT");
    expect(txtAccessFMPerTon.type).toBe("text");
    expect(txtAccessFMPerTon.classList).toContain("textboxWidgetClass");
    expect(txtAccessFMPerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtAccessFMPerTon")
    );
    await act(async () => {
      userEvent.type(txtAccessFMPerTon, "1");
    });
  });
  test("Custom Test Cases for txtAccessFMPerTon", () => {
    // START_USER_CODE-USER_txtAccessFMPerTon_TEST
    // END_USER_CODE-USER_txtAccessFMPerTon_TEST
  });
  test("txtAFlavusPerPound(Textbox Widget) Test Cases", async () => {
    const txtAFlavusPerPound = screen.getByTestId("txtAFlavusPerPound");
    expect(txtAFlavusPerPound.tagName).toBe("INPUT");
    expect(txtAFlavusPerPound.type).toBe("text");
    expect(txtAFlavusPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAFlavusPerPound, "1");
    });
  });
  test("Custom Test Cases for txtAFlavusPerPound", () => {
    // START_USER_CODE-USER_txtAFlavusPerPound_TEST
    // END_USER_CODE-USER_txtAFlavusPerPound_TEST
  });
  test("txtAFlavusPerTon(Textbox Widget) Test Cases", async () => {
    const txtAFlavusPerTon = screen.getByTestId("txtAFlavusPerTon");
    expect(txtAFlavusPerTon.tagName).toBe("INPUT");
    expect(txtAFlavusPerTon.type).toBe("text");
    expect(txtAFlavusPerTon.classList).toContain("textboxWidgetClass");
    expect(txtAFlavusPerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtAFlavusPerTon")
    );
    await act(async () => {
      userEvent.type(txtAFlavusPerTon, "1");
    });
  });
  test("Custom Test Cases for txtAFlavusPerTon", () => {
    // START_USER_CODE-USER_txtAFlavusPerTon_TEST
    // END_USER_CODE-USER_txtAFlavusPerTon_TEST
  });
  test("txtAgreementPrice(Textbox Widget) Test Cases", async () => {
    const txtAgreementPrice = screen.getByTestId("txtAgreementPrice");
    expect(txtAgreementPrice.tagName).toBe("INPUT");
    expect(txtAgreementPrice.type).toBe("text");
    expect(txtAgreementPrice.classList).toContain("textboxWidgetClass");
    expect(txtAgreementPrice.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtAgreementPrice")
    );
    await act(async () => {
      userEvent.type(txtAgreementPrice, "1");
    });
  });
  test("Custom Test Cases for txtAgreementPrice", () => {
    // START_USER_CODE-USER_txtAgreementPrice_TEST
    // END_USER_CODE-USER_txtAgreementPrice_TEST
  });
  test("txtBluePanWT(Textbox Widget) Test Cases", async () => {
    const txtBluePanWT = screen.getByTestId("txtBluePanWT");
    expect(txtBluePanWT.tagName).toBe("INPUT");
    expect(txtBluePanWT.type).toBe("text");
    expect(txtBluePanWT.classList).toContain("textboxWidgetClass");
    expect(txtBluePanWT.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtBluePanWT")
    );
    await act(async () => {
      userEvent.type(txtBluePanWT, "1");
    });
  });
  test("Custom Test Cases for txtBluePanWT", () => {
    // START_USER_CODE-USER_txtBluePanWT_TEST
    // END_USER_CODE-USER_txtBluePanWT_TEST
  });
  test("txtCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtCleanSampleWt = screen.getByTestId("txtCleanSampleWt");
    expect(txtCleanSampleWt.tagName).toBe("INPUT");
    expect(txtCleanSampleWt.type).toBe("text");
    expect(txtCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtCleanSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtCleanSampleWt", () => {
    // START_USER_CODE-USER_txtCleanSampleWt_TEST
    // END_USER_CODE-USER_txtCleanSampleWt_TEST
  });
  test("gridScaleTickets_txtcolInventoryWt(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolInventoryWt = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolInventoryWtbtn =
      gridScaleTickets_txtcolInventoryWt.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolInventoryWt =
      gridScaleTickets_txtcolInventoryWt.parentElement.parentElement
        .parentElement;
    expect(gridScaleTickets_txtcolInventoryWt.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolInventoryWt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInventoryWt", () => {
    // START_USER_CODE-USER_txtcolInventoryWt_TEST
    // END_USER_CODE-USER_txtcolInventoryWt_TEST
  });
  test("gridScaleTickets_txtcolLoad(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolLoad = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolLoadbtn =
      gridScaleTickets_txtcolLoad.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolLoad =
      gridScaleTickets_txtcolLoad.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolLoad.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolLoad.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoad", () => {
    // START_USER_CODE-USER_txtcolLoad_TEST
    // END_USER_CODE-USER_txtcolLoad_TEST
  });
  test("gridScaleTickets_txtcolRecvDate(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolRecvDate = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolRecvDatebtn =
      gridScaleTickets_txtcolRecvDate.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolRecvDate =
      gridScaleTickets_txtcolRecvDate.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolRecvDate.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolRecvDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvDate", () => {
    // START_USER_CODE-USER_txtcolRecvDate_TEST
    // END_USER_CODE-USER_txtcolRecvDate_TEST
  });
  test("gridScaleTickets_txtcolRecvTicket(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolRecvTicket = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolRecvTicketbtn =
      gridScaleTickets_txtcolRecvTicket.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolRecvTicket =
      gridScaleTickets_txtcolRecvTicket.parentElement.parentElement
        .parentElement;
    expect(gridScaleTickets_txtcolRecvTicket.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolRecvTicket.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvTicket", () => {
    // START_USER_CODE-USER_txtcolRecvTicket_TEST
    // END_USER_CODE-USER_txtcolRecvTicket_TEST
  });
  test("gridScaleTickets_txtcolRecvWt(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolRecvWt = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolRecvWtbtn =
      gridScaleTickets_txtcolRecvWt.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolRecvWt =
      gridScaleTickets_txtcolRecvWt.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolRecvWt.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolRecvWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvWt", () => {
    // START_USER_CODE-USER_txtcolRecvWt_TEST
    // END_USER_CODE-USER_txtcolRecvWt_TEST
  });
  test("gridScaleTickets_txtcolShipDate(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolShipDate = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolShipDatebtn =
      gridScaleTickets_txtcolShipDate.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolShipDate =
      gridScaleTickets_txtcolShipDate.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolShipDate.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolShipDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShipDate", () => {
    // START_USER_CODE-USER_txtcolShipDate_TEST
    // END_USER_CODE-USER_txtcolShipDate_TEST
  });
  test("gridScaleTickets_txtcolShipTicket(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolShipTicket = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolShipTicketbtn =
      gridScaleTickets_txtcolShipTicket.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolShipTicket =
      gridScaleTickets_txtcolShipTicket.parentElement.parentElement
        .parentElement;
    expect(gridScaleTickets_txtcolShipTicket.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolShipTicket.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShipTicket", () => {
    // START_USER_CODE-USER_txtcolShipTicket_TEST
    // END_USER_CODE-USER_txtcolShipTicket_TEST
  });
  test("gridScaleTickets_txtcolShipWt(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolShipWt = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolShipWtbtn =
      gridScaleTickets_txtcolShipWt.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolShipWt =
      gridScaleTickets_txtcolShipWt.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolShipWt.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolShipWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShipWt", () => {
    // START_USER_CODE-USER_txtcolShipWt_TEST
    // END_USER_CODE-USER_txtcolShipWt_TEST
  });
  test("gridScaleTickets_txtcolVehicle(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolVehicle = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolVehiclebtn =
      gridScaleTickets_txtcolVehicle.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolVehicle =
      gridScaleTickets_txtcolVehicle.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolVehicle.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolVehicle.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleProfile_gridScaleTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVehicle", () => {
    // START_USER_CODE-USER_txtcolVehicle_TEST
    // END_USER_CODE-USER_txtcolVehicle_TEST
  });
  test("txtConcealedRMDPct(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDPct = screen.getByTestId("txtConcealedRMDPct");
    expect(txtConcealedRMDPct.tagName).toBe("INPUT");
    expect(txtConcealedRMDPct.type).toBe("text");
    expect(txtConcealedRMDPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtConcealedRMDPct, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDPct", () => {
    // START_USER_CODE-USER_txtConcealedRMDPct_TEST
    // END_USER_CODE-USER_txtConcealedRMDPct_TEST
  });
  test("txtCrackedBrokenPct(Textbox Widget) Test Cases", async () => {
    const txtCrackedBrokenPct = screen.getByTestId("txtCrackedBrokenPct");
    expect(txtCrackedBrokenPct.tagName).toBe("INPUT");
    expect(txtCrackedBrokenPct.type).toBe("text");
    expect(txtCrackedBrokenPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrackedBrokenPct, "1");
    });
  });
  test("Custom Test Cases for txtCrackedBrokenPct", () => {
    // START_USER_CODE-USER_txtCrackedBrokenPct_TEST
    // END_USER_CODE-USER_txtCrackedBrokenPct_TEST
  });
  test("txtDamagedKRS(Textbox Widget) Test Cases", async () => {
    const txtDamagedKRS = screen.getByTestId("txtDamagedKRS");
    expect(txtDamagedKRS.tagName).toBe("INPUT");
    expect(txtDamagedKRS.type).toBe("text");
    expect(txtDamagedKRS.classList).toContain("textboxWidgetClass");
    expect(txtDamagedKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtDamagedKRS")
    );
    await act(async () => {
      userEvent.type(txtDamagedKRS, "1");
    });
  });
  test("Custom Test Cases for txtDamagedKRS", () => {
    // START_USER_CODE-USER_txtDamagedKRS_TEST
    // END_USER_CODE-USER_txtDamagedKRS_TEST
  });
  test("txtDamagePerPound(Textbox Widget) Test Cases", async () => {
    const txtDamagePerPound = screen.getByTestId("txtDamagePerPound");
    expect(txtDamagePerPound.tagName).toBe("INPUT");
    expect(txtDamagePerPound.type).toBe("text");
    expect(txtDamagePerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamagePerPound, "1");
    });
  });
  test("Custom Test Cases for txtDamagePerPound", () => {
    // START_USER_CODE-USER_txtDamagePerPound_TEST
    // END_USER_CODE-USER_txtDamagePerPound_TEST
  });
  test("txtDamagePerTon(Textbox Widget) Test Cases", async () => {
    const txtDamagePerTon = screen.getByTestId("txtDamagePerTon");
    expect(txtDamagePerTon.tagName).toBe("INPUT");
    expect(txtDamagePerTon.type).toBe("text");
    expect(txtDamagePerTon.classList).toContain("textboxWidgetClass");
    expect(txtDamagePerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtDamagePerTon")
    );
    await act(async () => {
      userEvent.type(txtDamagePerTon, "1");
    });
  });
  test("Custom Test Cases for txtDamagePerTon", () => {
    // START_USER_CODE-USER_txtDamagePerTon_TEST
    // END_USER_CODE-USER_txtDamagePerTon_TEST
  });
  test("txtDamageSplits(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits = screen.getByTestId("txtDamageSplits");
    expect(txtDamageSplits.tagName).toBe("INPUT");
    expect(txtDamageSplits.type).toBe("text");
    expect(txtDamageSplits.classList).toContain("textboxWidgetClass");
    expect(txtDamageSplits.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtDamageSplits")
    );
    await act(async () => {
      userEvent.type(txtDamageSplits, "1");
    });
  });
  test("Custom Test Cases for txtDamageSplits", () => {
    // START_USER_CODE-USER_txtDamageSplits_TEST
    // END_USER_CODE-USER_txtDamageSplits_TEST
  });
  test("txtDeductions(Textbox Widget) Test Cases", async () => {
    const txtDeductions = screen.getByTestId("txtDeductions");
    expect(txtDeductions.tagName).toBe("INPUT");
    expect(txtDeductions.type).toBe("text");
    expect(txtDeductions.classList).toContain("textboxWidgetClass");
    expect(txtDeductions.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtDeductions")
    );
    await act(async () => {
      userEvent.type(txtDeductions, "1");
    });
  });
  test("Custom Test Cases for txtDeductions", () => {
    // START_USER_CODE-USER_txtDeductions_TEST
    // END_USER_CODE-USER_txtDeductions_TEST
  });
  test("txtDiscoloredShellsPct(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsPct = screen.getByTestId("txtDiscoloredShellsPct");
    expect(txtDiscoloredShellsPct.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsPct.type).toBe("text");
    expect(txtDiscoloredShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsPct", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsPct_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsPct_TEST
  });
  test("txtELKDamaged(Textbox Widget) Test Cases", async () => {
    const txtELKDamaged = screen.getByTestId("txtELKDamaged");
    expect(txtELKDamaged.tagName).toBe("INPUT");
    expect(txtELKDamaged.type).toBe("text");
    expect(txtELKDamaged.classList).toContain("textboxWidgetClass");
    expect(txtELKDamaged.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtELKDamaged")
    );
    await act(async () => {
      userEvent.type(txtELKDamaged, "1");
    });
  });
  test("Custom Test Cases for txtELKDamaged", () => {
    // START_USER_CODE-USER_txtELKDamaged_TEST
    // END_USER_CODE-USER_txtELKDamaged_TEST
  });
  test("txtELKPremiumPerPound(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumPerPound = screen.getByTestId("txtELKPremiumPerPound");
    expect(txtELKPremiumPerPound.tagName).toBe("INPUT");
    expect(txtELKPremiumPerPound.type).toBe("text");
    expect(txtELKPremiumPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPremiumPerPound, "1");
    });
  });
  test("Custom Test Cases for txtELKPremiumPerPound", () => {
    // START_USER_CODE-USER_txtELKPremiumPerPound_TEST
    // END_USER_CODE-USER_txtELKPremiumPerPound_TEST
  });
  test("txtELKPremiumPerTon(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumPerTon = screen.getByTestId("txtELKPremiumPerTon");
    expect(txtELKPremiumPerTon.tagName).toBe("INPUT");
    expect(txtELKPremiumPerTon.type).toBe("text");
    expect(txtELKPremiumPerTon.classList).toContain("textboxWidgetClass");
    expect(txtELKPremiumPerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtELKPremiumPerTon")
    );
    await act(async () => {
      userEvent.type(txtELKPremiumPerTon, "1");
    });
  });
  test("Custom Test Cases for txtELKPremiumPerTon", () => {
    // START_USER_CODE-USER_txtELKPremiumPerTon_TEST
    // END_USER_CODE-USER_txtELKPremiumPerTon_TEST
  });
  test("txtExcessMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcessMoisture = screen.getByTestId("txtExcessMoisture");
    expect(txtExcessMoisture.tagName).toBe("INPUT");
    expect(txtExcessMoisture.type).toBe("text");
    expect(txtExcessMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcessMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtExcessMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcessMoisture, "1");
    });
  });
  test("Custom Test Cases for txtExcessMoisture", () => {
    // START_USER_CODE-USER_txtExcessMoisture_TEST
    // END_USER_CODE-USER_txtExcessMoisture_TEST
  });
  test("txtExcessSplitsPerPound(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsPerPound = screen.getByTestId(
      "txtExcessSplitsPerPound"
    );
    expect(txtExcessSplitsPerPound.tagName).toBe("INPUT");
    expect(txtExcessSplitsPerPound.type).toBe("text");
    expect(txtExcessSplitsPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtExcessSplitsPerPound, "1");
    });
  });
  test("Custom Test Cases for txtExcessSplitsPerPound", () => {
    // START_USER_CODE-USER_txtExcessSplitsPerPound_TEST
    // END_USER_CODE-USER_txtExcessSplitsPerPound_TEST
  });
  test("txtExcessSplitsPerTon(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsPerTon = screen.getByTestId("txtExcessSplitsPerTon");
    expect(txtExcessSplitsPerTon.tagName).toBe("INPUT");
    expect(txtExcessSplitsPerTon.type).toBe("text");
    expect(txtExcessSplitsPerTon.classList).toContain("textboxWidgetClass");
    expect(txtExcessSplitsPerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtExcessSplitsPerTon")
    );
    await act(async () => {
      userEvent.type(txtExcessSplitsPerTon, "1");
    });
  });
  test("Custom Test Cases for txtExcessSplitsPerTon", () => {
    // START_USER_CODE-USER_txtExcessSplitsPerTon_TEST
    // END_USER_CODE-USER_txtExcessSplitsPerTon_TEST
  });
  test("txtFancyPct(Textbox Widget) Test Cases", async () => {
    const txtFancyPct = screen.getByTestId("txtFancyPct");
    expect(txtFancyPct.tagName).toBe("INPUT");
    expect(txtFancyPct.type).toBe("text");
    expect(txtFancyPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPct, "1");
    });
  });
  test("Custom Test Cases for txtFancyPct", () => {
    // START_USER_CODE-USER_txtFancyPct_TEST
    // END_USER_CODE-USER_txtFancyPct_TEST
  });
  test("txtFMSampleWt(Textbox Widget) Test Cases", async () => {
    const txtFMSampleWt = screen.getByTestId("txtFMSampleWt");
    expect(txtFMSampleWt.tagName).toBe("INPUT");
    expect(txtFMSampleWt.type).toBe("text");
    expect(txtFMSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtFMSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtFMSampleWt")
    );
    await act(async () => {
      userEvent.type(txtFMSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtFMSampleWt", () => {
    // START_USER_CODE-USER_txtFMSampleWt_TEST
    // END_USER_CODE-USER_txtFMSampleWt_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtForeignMaterialPct(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialPct = screen.getByTestId("txtForeignMaterialPct");
    expect(txtForeignMaterialPct.tagName).toBe("INPUT");
    expect(txtForeignMaterialPct.type).toBe("text");
    expect(txtForeignMaterialPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtForeignMaterialPct, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterialPct", () => {
    // START_USER_CODE-USER_txtForeignMaterialPct_TEST
    // END_USER_CODE-USER_txtForeignMaterialPct_TEST
  });
  test("txtFreezeDamagePct(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePct = screen.getByTestId("txtFreezeDamagePct");
    expect(txtFreezeDamagePct.tagName).toBe("INPUT");
    expect(txtFreezeDamagePct.type).toBe("text");
    expect(txtFreezeDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePct", () => {
    // START_USER_CODE-USER_txtFreezeDamagePct_TEST
    // END_USER_CODE-USER_txtFreezeDamagePct_TEST
  });
  test("txtGrossWeight(Textbox Widget) Test Cases", async () => {
    const txtGrossWeight = screen.getByTestId("txtGrossWeight");
    expect(txtGrossWeight.tagName).toBe("INPUT");
    expect(txtGrossWeight.type).toBe("text");
    expect(txtGrossWeight.classList).toContain("textboxWidgetClass");
    expect(txtGrossWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtGrossWeight")
    );
    await act(async () => {
      userEvent.type(txtGrossWeight, "1");
    });
  });
  test("Custom Test Cases for txtGrossWeight", () => {
    // START_USER_CODE-USER_txtGrossWeight_TEST
    // END_USER_CODE-USER_txtGrossWeight_TEST
  });
  test("txtH(Textbox Widget) Test Cases", async () => {
    const txtH = screen.getByTestId("txtH");
    expect(txtH.tagName).toBe("INPUT");
    expect(txtH.type).toBe("text");
    expect(txtH.classList).toContain("textboxWidgetClass");
    expect(txtH.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtH")
    );
    await act(async () => {
      userEvent.type(txtH, "1");
    });
  });
  test("Custom Test Cases for txtH", () => {
    // START_USER_CODE-USER_txtH_TEST
    // END_USER_CODE-USER_txtH_TEST
  });
  test("txtHullBrightnessPct(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPct = screen.getByTestId("txtHullBrightnessPct");
    expect(txtHullBrightnessPct.tagName).toBe("INPUT");
    expect(txtHullBrightnessPct.type).toBe("text");
    expect(txtHullBrightnessPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPct, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightnessPct", () => {
    // START_USER_CODE-USER_txtHullBrightnessPct_TEST
    // END_USER_CODE-USER_txtHullBrightnessPct_TEST
  });
  test("txtHullsPct(Textbox Widget) Test Cases", async () => {
    const txtHullsPct = screen.getByTestId("txtHullsPct");
    expect(txtHullsPct.tagName).toBe("INPUT");
    expect(txtHullsPct.type).toBe("text");
    expect(txtHullsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullsPct, "1");
    });
  });
  test("Custom Test Cases for txtHullsPct", () => {
    // START_USER_CODE-USER_txtHullsPct_TEST
    // END_USER_CODE-USER_txtHullsPct_TEST
  });
  test("txtIDTfromtoVendor(Textbox Widget) Test Cases", async () => {
    const txtIDTfromtoVendor = screen.getByTestId("txtIDTfromtoVendor");
    expect(txtIDTfromtoVendor.tagName).toBe("INPUT");
    expect(txtIDTfromtoVendor.type).toBe("text");
    expect(txtIDTfromtoVendor.classList).toContain("textboxWidgetClass");
    expect(txtIDTfromtoVendor.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtIDTfromtoVendor")
    );
    await act(async () => {
      userEvent.type(txtIDTfromtoVendor, "1");
    });
  });
  test("Custom Test Cases for txtIDTfromtoVendor", () => {
    // START_USER_CODE-USER_txtIDTfromtoVendor_TEST
    // END_USER_CODE-USER_txtIDTfromtoVendor_TEST
  });
  test("txtInspectDateTime(Textbox Widget) Test Cases", async () => {
    const txtInspectDateTime = screen.getByTestId("txtInspectDateTime");
    expect(txtInspectDateTime.tagName).toBe("INPUT");
    expect(txtInspectDateTime.type).toBe("text");
    expect(txtInspectDateTime.classList).toContain("textboxWidgetClass");
    expect(txtInspectDateTime.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtInspectDateTime")
    );
    await act(async () => {
      userEvent.type(txtInspectDateTime, "1");
    });
  });
  test("Custom Test Cases for txtInspectDateTime", () => {
    // START_USER_CODE-USER_txtInspectDateTime_TEST
    // END_USER_CODE-USER_txtInspectDateTime_TEST
  });
  test("txtJumboGrPct(Textbox Widget) Test Cases", async () => {
    const txtJumboGrPct = screen.getByTestId("txtJumboGrPct");
    expect(txtJumboGrPct.tagName).toBe("INPUT");
    expect(txtJumboGrPct.type).toBe("text");
    expect(txtJumboGrPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboGrPct, "1");
    });
  });
  test("Custom Test Cases for txtJumboGrPct", () => {
    // START_USER_CODE-USER_txtJumboGrPct_TEST
    // END_USER_CODE-USER_txtJumboGrPct_TEST
  });
  test("txtKernelsREL(Textbox Widget) Test Cases", async () => {
    const txtKernelsREL = screen.getByTestId("txtKernelsREL");
    expect(txtKernelsREL.tagName).toBe("INPUT");
    expect(txtKernelsREL.type).toBe("text");
    expect(txtKernelsREL.classList).toContain("textboxWidgetClass");
    expect(txtKernelsREL.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtKernelsREL")
    );
    await act(async () => {
      userEvent.type(txtKernelsREL, "1");
    });
  });
  test("Custom Test Cases for txtKernelsREL", () => {
    // START_USER_CODE-USER_txtKernelsREL_TEST
    // END_USER_CODE-USER_txtKernelsREL_TEST
  });
  test("txtKernelsRPS(Textbox Widget) Test Cases", async () => {
    const txtKernelsRPS = screen.getByTestId("txtKernelsRPS");
    expect(txtKernelsRPS.tagName).toBe("INPUT");
    expect(txtKernelsRPS.type).toBe("text");
    expect(txtKernelsRPS.classList).toContain("textboxWidgetClass");
    expect(txtKernelsRPS.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtKernelsRPS")
    );
    await act(async () => {
      userEvent.type(txtKernelsRPS, "1");
    });
  });
  test("Custom Test Cases for txtKernelsRPS", () => {
    // START_USER_CODE-USER_txtKernelsRPS_TEST
    // END_USER_CODE-USER_txtKernelsRPS_TEST
  });
  test("txtKernelValuePerPound(Textbox Widget) Test Cases", async () => {
    const txtKernelValuePerPound = screen.getByTestId("txtKernelValuePerPound");
    expect(txtKernelValuePerPound.tagName).toBe("INPUT");
    expect(txtKernelValuePerPound.type).toBe("text");
    expect(txtKernelValuePerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernelValuePerPound, "1");
    });
  });
  test("Custom Test Cases for txtKernelValuePerPound", () => {
    // START_USER_CODE-USER_txtKernelValuePerPound_TEST
    // END_USER_CODE-USER_txtKernelValuePerPound_TEST
  });
  test("txtKernelValuePerTon(Textbox Widget) Test Cases", async () => {
    const txtKernelValuePerTon = screen.getByTestId("txtKernelValuePerTon");
    expect(txtKernelValuePerTon.tagName).toBe("INPUT");
    expect(txtKernelValuePerTon.type).toBe("text");
    expect(txtKernelValuePerTon.classList).toContain("textboxWidgetClass");
    expect(txtKernelValuePerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtKernelValuePerTon")
    );
    await act(async () => {
      userEvent.type(txtKernelValuePerTon, "1");
    });
  });
  test("Custom Test Cases for txtKernelValuePerTon", () => {
    // START_USER_CODE-USER_txtKernelValuePerTon_TEST
    // END_USER_CODE-USER_txtKernelValuePerTon_TEST
  });
  test("txtKernRidingPrescribePct(Textbox Widget) Test Cases", async () => {
    const txtKernRidingPrescribePct = screen.getByTestId(
      "txtKernRidingPrescribePct"
    );
    expect(txtKernRidingPrescribePct.tagName).toBe("INPUT");
    expect(txtKernRidingPrescribePct.type).toBe("text");
    expect(txtKernRidingPrescribePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernRidingPrescribePct, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingPrescribePct", () => {
    // START_USER_CODE-USER_txtKernRidingPrescribePct_TEST
    // END_USER_CODE-USER_txtKernRidingPrescribePct_TEST
  });
  test("txtLoad(Textbox Widget) Test Cases", async () => {
    const txtLoad = screen.getByTestId("txtLoad");
    expect(txtLoad.tagName).toBe("INPUT");
    expect(txtLoad.type).toBe("text");
    expect(txtLoad.classList).toContain("textboxWidgetClass");
    expect(txtLoad.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtLoad")
    );
    await act(async () => {
      userEvent.type(txtLoad, "1");
    });
  });
  test("Custom Test Cases for txtLoad", () => {
    // START_USER_CODE-USER_txtLoad_TEST
    // END_USER_CODE-USER_txtLoad_TEST
  });
  test("txtLot(Textbox Widget) Test Cases", async () => {
    const txtLot = screen.getByTestId("txtLot");
    expect(txtLot.tagName).toBe("INPUT");
    expect(txtLot.type).toBe("text");
    expect(txtLot.classList).toContain("textboxWidgetClass");
    expect(txtLot.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtLot")
    );
    await act(async () => {
      userEvent.type(txtLot, "1");
    });
  });
  test("Custom Test Cases for txtLot", () => {
    // START_USER_CODE-USER_txtLot_TEST
    // END_USER_CODE-USER_txtLot_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "1");
    });
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtLSKDamageRiding(Textbox Widget) Test Cases", async () => {
    const txtLSKDamageRiding = screen.getByTestId("txtLSKDamageRiding");
    expect(txtLSKDamageRiding.tagName).toBe("INPUT");
    expect(txtLSKDamageRiding.type).toBe("text");
    expect(txtLSKDamageRiding.classList).toContain("textboxWidgetClass");
    expect(txtLSKDamageRiding.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtLSKDamageRiding")
    );
    await act(async () => {
      userEvent.type(txtLSKDamageRiding, "1");
    });
  });
  test("Custom Test Cases for txtLSKDamageRiding", () => {
    // START_USER_CODE-USER_txtLSKDamageRiding_TEST
    // END_USER_CODE-USER_txtLSKDamageRiding_TEST
  });
  test("txtLSKFallThru(Textbox Widget) Test Cases", async () => {
    const txtLSKFallThru = screen.getByTestId("txtLSKFallThru");
    expect(txtLSKFallThru.tagName).toBe("INPUT");
    expect(txtLSKFallThru.type).toBe("text");
    expect(txtLSKFallThru.classList).toContain("textboxWidgetClass");
    expect(txtLSKFallThru.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtLSKFallThru")
    );
    await act(async () => {
      userEvent.type(txtLSKFallThru, "1");
    });
  });
  test("Custom Test Cases for txtLSKFallThru", () => {
    // START_USER_CODE-USER_txtLSKFallThru_TEST
    // END_USER_CODE-USER_txtLSKFallThru_TEST
  });
  test("txtLSKRidingGr(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingGr = screen.getByTestId("txtLSKRidingGr");
    expect(txtLSKRidingGr.tagName).toBe("INPUT");
    expect(txtLSKRidingGr.type).toBe("text");
    expect(txtLSKRidingGr.classList).toContain("textboxWidgetClass");
    expect(txtLSKRidingGr.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtLSKRidingGr")
    );
    await act(async () => {
      userEvent.type(txtLSKRidingGr, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingGr", () => {
    // START_USER_CODE-USER_txtLSKRidingGr_TEST
    // END_USER_CODE-USER_txtLSKRidingGr_TEST
  });
  test("txtLSKRidingPct(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingPct = screen.getByTestId("txtLSKRidingPct");
    expect(txtLSKRidingPct.tagName).toBe("INPUT");
    expect(txtLSKRidingPct.type).toBe("text");
    expect(txtLSKRidingPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKRidingPct, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingPct", () => {
    // START_USER_CODE-USER_txtLSKRidingPct_TEST
    // END_USER_CODE-USER_txtLSKRidingPct_TEST
  });
  test("txtMeterReading(Textbox Widget) Test Cases", async () => {
    const txtMeterReading = screen.getByTestId("txtMeterReading");
    expect(txtMeterReading.tagName).toBe("INPUT");
    expect(txtMeterReading.type).toBe("text");
    expect(txtMeterReading.classList).toContain("textboxWidgetClass");
    expect(txtMeterReading.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtMeterReading")
    );
    await act(async () => {
      userEvent.type(txtMeterReading, "1");
    });
  });
  test("Custom Test Cases for txtMeterReading", () => {
    // START_USER_CODE-USER_txtMeterReading_TEST
    // END_USER_CODE-USER_txtMeterReading_TEST
  });
  test("txtMoisture(Textbox Widget) Test Cases", async () => {
    const txtMoisture = screen.getByTestId("txtMoisture");
    expect(txtMoisture.tagName).toBe("INPUT");
    expect(txtMoisture.type).toBe("text");
    expect(txtMoisture.classList).toContain("textboxWidgetClass");
    expect(txtMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtMoisture")
    );
    await act(async () => {
      userEvent.type(txtMoisture, "1");
    });
  });
  test("Custom Test Cases for txtMoisture", () => {
    // START_USER_CODE-USER_txtMoisture_TEST
    // END_USER_CODE-USER_txtMoisture_TEST
  });
  test("txtNetELKPct(Textbox Widget) Test Cases", async () => {
    const txtNetELKPct = screen.getByTestId("txtNetELKPct");
    expect(txtNetELKPct.tagName).toBe("INPUT");
    expect(txtNetELKPct.type).toBe("text");
    expect(txtNetELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetELKPct, "1");
    });
  });
  test("Custom Test Cases for txtNetELKPct", () => {
    // START_USER_CODE-USER_txtNetELKPct_TEST
    // END_USER_CODE-USER_txtNetELKPct_TEST
  });
  test("txtNetValPerTonExclSLK(Textbox Widget) Test Cases", async () => {
    const txtNetValPerTonExclSLK = screen.getByTestId("txtNetValPerTonExclSLK");
    expect(txtNetValPerTonExclSLK.tagName).toBe("INPUT");
    expect(txtNetValPerTonExclSLK.type).toBe("text");
    expect(txtNetValPerTonExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtNetValPerTonExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtNetValPerTonExclSLK")
    );
    await act(async () => {
      userEvent.type(txtNetValPerTonExclSLK, "1");
    });
  });
  test("Custom Test Cases for txtNetValPerTonExclSLK", () => {
    // START_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
    // END_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "1");
    });
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtNetWt(Textbox Widget) Test Cases", async () => {
    const txtNetWt = screen.getByTestId("txtNetWt");
    expect(txtNetWt.tagName).toBe("INPUT");
    expect(txtNetWt.type).toBe("text");
    expect(txtNetWt.classList).toContain("textboxWidgetClass");
    expect(txtNetWt.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtNetWt")
    );
    await act(async () => {
      userEvent.type(txtNetWt, "1");
    });
  });
  test("Custom Test Cases for txtNetWt", () => {
    // START_USER_CODE-USER_txtNetWt_TEST
    // END_USER_CODE-USER_txtNetWt_TEST
  });
  test("txtNetWtExcludingLSK(Textbox Widget) Test Cases", async () => {
    const txtNetWtExcludingLSK = screen.getByTestId("txtNetWtExcludingLSK");
    expect(txtNetWtExcludingLSK.tagName).toBe("INPUT");
    expect(txtNetWtExcludingLSK.type).toBe("text");
    expect(txtNetWtExcludingLSK.classList).toContain("textboxWidgetClass");
    expect(txtNetWtExcludingLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtNetWtExcludingLSK")
    );
    await act(async () => {
      userEvent.type(txtNetWtExcludingLSK, "1");
    });
  });
  test("Custom Test Cases for txtNetWtExcludingLSK", () => {
    // START_USER_CODE-USER_txtNetWtExcludingLSK_TEST
    // END_USER_CODE-USER_txtNetWtExcludingLSK_TEST
  });
  test("txtOI(Textbox Widget) Test Cases", async () => {
    const txtOI = screen.getByTestId("txtOI");
    expect(txtOI.tagName).toBe("INPUT");
    expect(txtOI.type).toBe("text");
    expect(txtOI.classList).toContain("textboxWidgetClass");
    expect(txtOI.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtOI")
    );
    await act(async () => {
      userEvent.type(txtOI, "1");
    });
  });
  test("Custom Test Cases for txtOI", () => {
    // START_USER_CODE-USER_txtOI_TEST
    // END_USER_CODE-USER_txtOI_TEST
  });
  test("txtOKDamageRiding(Textbox Widget) Test Cases", async () => {
    const txtOKDamageRiding = screen.getByTestId("txtOKDamageRiding");
    expect(txtOKDamageRiding.tagName).toBe("INPUT");
    expect(txtOKDamageRiding.type).toBe("text");
    expect(txtOKDamageRiding.classList).toContain("textboxWidgetClass");
    expect(txtOKDamageRiding.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtOKDamageRiding")
    );
    await act(async () => {
      userEvent.type(txtOKDamageRiding, "1");
    });
  });
  test("Custom Test Cases for txtOKDamageRiding", () => {
    // START_USER_CODE-USER_txtOKDamageRiding_TEST
    // END_USER_CODE-USER_txtOKDamageRiding_TEST
  });
  test("txtOKFallThru(Textbox Widget) Test Cases", async () => {
    const txtOKFallThru = screen.getByTestId("txtOKFallThru");
    expect(txtOKFallThru.tagName).toBe("INPUT");
    expect(txtOKFallThru.type).toBe("text");
    expect(txtOKFallThru.classList).toContain("textboxWidgetClass");
    expect(txtOKFallThru.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtOKFallThru")
    );
    await act(async () => {
      userEvent.type(txtOKFallThru, "1");
    });
  });
  test("Custom Test Cases for txtOKFallThru", () => {
    // START_USER_CODE-USER_txtOKFallThru_TEST
    // END_USER_CODE-USER_txtOKFallThru_TEST
  });
  test("txtOKRidingGr(Textbox Widget) Test Cases", async () => {
    const txtOKRidingGr = screen.getByTestId("txtOKRidingGr");
    expect(txtOKRidingGr.tagName).toBe("INPUT");
    expect(txtOKRidingGr.type).toBe("text");
    expect(txtOKRidingGr.classList).toContain("textboxWidgetClass");
    expect(txtOKRidingGr.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtOKRidingGr")
    );
    await act(async () => {
      userEvent.type(txtOKRidingGr, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingGr", () => {
    // START_USER_CODE-USER_txtOKRidingGr_TEST
    // END_USER_CODE-USER_txtOKRidingGr_TEST
  });
  test("txtOKRidingPct(Textbox Widget) Test Cases", async () => {
    const txtOKRidingPct = screen.getByTestId("txtOKRidingPct");
    expect(txtOKRidingPct.tagName).toBe("INPUT");
    expect(txtOKRidingPct.type).toBe("text");
    expect(txtOKRidingPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKRidingPct, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingPct", () => {
    // START_USER_CODE-USER_txtOKRidingPct_TEST
    // END_USER_CODE-USER_txtOKRidingPct_TEST
  });
  test("txtOtherFM(Textbox Widget) Test Cases", async () => {
    const txtOtherFM = screen.getByTestId("txtOtherFM");
    expect(txtOtherFM.tagName).toBe("INPUT");
    expect(txtOtherFM.type).toBe("text");
    expect(txtOtherFM.classList).toContain("textboxWidgetClass");
    expect(txtOtherFM.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtOtherFM")
    );
    await act(async () => {
      userEvent.type(txtOtherFM, "1");
    });
  });
  test("Custom Test Cases for txtOtherFM", () => {
    // START_USER_CODE-USER_txtOtherFM_TEST
    // END_USER_CODE-USER_txtOtherFM_TEST
  });
  test("txtPerG(Textbox Widget) Test Cases", async () => {
    const txtPerG = screen.getByTestId("txtPerG");
    expect(txtPerG.tagName).toBe("INPUT");
    expect(txtPerG.type).toBe("text");
    expect(txtPerG.classList).toContain("textboxWidgetClass");
    expect(txtPerG.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtPerG")
    );
    await act(async () => {
      userEvent.type(txtPerG, "1");
    });
  });
  test("Custom Test Cases for txtPerG", () => {
    // START_USER_CODE-USER_txtPerG_TEST
    // END_USER_CODE-USER_txtPerG_TEST
  });
  test("txtPittingDamagePct(Textbox Widget) Test Cases", async () => {
    const txtPittingDamagePct = screen.getByTestId("txtPittingDamagePct");
    expect(txtPittingDamagePct.tagName).toBe("INPUT");
    expect(txtPittingDamagePct.type).toBe("text");
    expect(txtPittingDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPittingDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtPittingDamagePct", () => {
    // START_USER_CODE-USER_txtPittingDamagePct_TEST
    // END_USER_CODE-USER_txtPittingDamagePct_TEST
  });
  test("txtPremiums(Textbox Widget) Test Cases", async () => {
    const txtPremiums = screen.getByTestId("txtPremiums");
    expect(txtPremiums.tagName).toBe("INPUT");
    expect(txtPremiums.type).toBe("text");
    expect(txtPremiums.classList).toContain("textboxWidgetClass");
    expect(txtPremiums.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtPremiums")
    );
    await act(async () => {
      userEvent.type(txtPremiums, "1");
    });
  });
  test("Custom Test Cases for txtPremiums", () => {
    // START_USER_CODE-USER_txtPremiums_TEST
    // END_USER_CODE-USER_txtPremiums_TEST
  });
  test("txtProbingPattern(Textbox Widget) Test Cases", async () => {
    const txtProbingPattern = screen.getByTestId("txtProbingPattern");
    expect(txtProbingPattern.tagName).toBe("INPUT");
    expect(txtProbingPattern.type).toBe("text");
    expect(txtProbingPattern.classList).toContain("textboxWidgetClass");
    expect(txtProbingPattern.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtProbingPattern")
    );
    await act(async () => {
      userEvent.type(txtProbingPattern, "1");
    });
  });
  test("Custom Test Cases for txtProbingPattern", () => {
    // START_USER_CODE-USER_txtProbingPattern_TEST
    // END_USER_CODE-USER_txtProbingPattern_TEST
  });
  test("txtSeal(Textbox Widget) Test Cases", async () => {
    const txtSeal = screen.getByTestId("txtSeal");
    expect(txtSeal.tagName).toBe("INPUT");
    expect(txtSeal.type).toBe("text");
    expect(txtSeal.classList).toContain("textboxWidgetClass");
    expect(txtSeal.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSeal")
    );
    await act(async () => {
      userEvent.type(txtSeal, "1");
    });
  });
  test("Custom Test Cases for txtSeal", () => {
    // START_USER_CODE-USER_txtSeal_TEST
    // END_USER_CODE-USER_txtSeal_TEST
  });
  test("txtSegValue(Textbox Widget) Test Cases", async () => {
    const txtSegValue = screen.getByTestId("txtSegValue");
    expect(txtSegValue.tagName).toBe("INPUT");
    expect(txtSegValue.type).toBe("text");
    expect(txtSegValue.classList).toContain("textboxWidgetClass");
    expect(txtSegValue.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSegValue")
    );
    await act(async () => {
      userEvent.type(txtSegValue, "1");
    });
  });
  test("Custom Test Cases for txtSegValue", () => {
    // START_USER_CODE-USER_txtSegValue_TEST
    // END_USER_CODE-USER_txtSegValue_TEST
  });
  test("txtSettleDateTime(Textbox Widget) Test Cases", async () => {
    const txtSettleDateTime = screen.getByTestId("txtSettleDateTime");
    expect(txtSettleDateTime.tagName).toBe("INPUT");
    expect(txtSettleDateTime.type).toBe("text");
    expect(txtSettleDateTime.classList).toContain("textboxWidgetClass");
    expect(txtSettleDateTime.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSettleDateTime")
    );
    await act(async () => {
      userEvent.type(txtSettleDateTime, "1");
    });
  });
  test("Custom Test Cases for txtSettleDateTime", () => {
    // START_USER_CODE-USER_txtSettleDateTime_TEST
    // END_USER_CODE-USER_txtSettleDateTime_TEST
  });
  test("txtSharePercentage1(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage1 = screen.getByTestId("txtSharePercentage1");
    expect(txtSharePercentage1.tagName).toBe("INPUT");
    expect(txtSharePercentage1.type).toBe("text");
    expect(txtSharePercentage1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage1, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage1", () => {
    // START_USER_CODE-USER_txtSharePercentage1_TEST
    // END_USER_CODE-USER_txtSharePercentage1_TEST
  });
  test("txtSharePercentage2(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage2 = screen.getByTestId("txtSharePercentage2");
    expect(txtSharePercentage2.tagName).toBe("INPUT");
    expect(txtSharePercentage2.type).toBe("text");
    expect(txtSharePercentage2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage2, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage2", () => {
    // START_USER_CODE-USER_txtSharePercentage2_TEST
    // END_USER_CODE-USER_txtSharePercentage2_TEST
  });
  test("txtSharePercentage3(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage3 = screen.getByTestId("txtSharePercentage3");
    expect(txtSharePercentage3.tagName).toBe("INPUT");
    expect(txtSharePercentage3.type).toBe("text");
    expect(txtSharePercentage3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage3, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage3", () => {
    // START_USER_CODE-USER_txtSharePercentage3_TEST
    // END_USER_CODE-USER_txtSharePercentage3_TEST
  });
  test("txtSharePercentage4(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage4 = screen.getByTestId("txtSharePercentage4");
    expect(txtSharePercentage4.tagName).toBe("INPUT");
    expect(txtSharePercentage4.type).toBe("text");
    expect(txtSharePercentage4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage4, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage4", () => {
    // START_USER_CODE-USER_txtSharePercentage4_TEST
    // END_USER_CODE-USER_txtSharePercentage4_TEST
  });
  test("txtSMK1Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr = screen.getByTestId("txtSMK1Gr");
    expect(txtSMK1Gr.tagName).toBe("INPUT");
    expect(txtSMK1Gr.type).toBe("text");
    expect(txtSMK1Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSMK1Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK1Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Gr", () => {
    // START_USER_CODE-USER_txtSMK1Gr_TEST
    // END_USER_CODE-USER_txtSMK1Gr_TEST
  });
  test("txtSMK1Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK1Pct = screen.getByTestId("txtSMK1Pct");
    expect(txtSMK1Pct.tagName).toBe("INPUT");
    expect(txtSMK1Pct.type).toBe("text");
    expect(txtSMK1Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Pct", () => {
    // START_USER_CODE-USER_txtSMK1Pct_TEST
    // END_USER_CODE-USER_txtSMK1Pct_TEST
  });
  test("txtSMK2Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gr = screen.getByTestId("txtSMK2Gr");
    expect(txtSMK2Gr.tagName).toBe("INPUT");
    expect(txtSMK2Gr.type).toBe("text");
    expect(txtSMK2Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSMK2Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK2Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Gr", () => {
    // START_USER_CODE-USER_txtSMK2Gr_TEST
    // END_USER_CODE-USER_txtSMK2Gr_TEST
  });
  test("txtSMK2Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK2Pct = screen.getByTestId("txtSMK2Pct");
    expect(txtSMK2Pct.tagName).toBe("INPUT");
    expect(txtSMK2Pct.type).toBe("text");
    expect(txtSMK2Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Pct", () => {
    // START_USER_CODE-USER_txtSMK2Pct_TEST
    // END_USER_CODE-USER_txtSMK2Pct_TEST
  });
  test("txtSMK3Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr = screen.getByTestId("txtSMK3Gr");
    expect(txtSMK3Gr.tagName).toBe("INPUT");
    expect(txtSMK3Gr.type).toBe("text");
    expect(txtSMK3Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSMK3Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK3Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Gr", () => {
    // START_USER_CODE-USER_txtSMK3Gr_TEST
    // END_USER_CODE-USER_txtSMK3Gr_TEST
  });
  test("txtSMK3Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK3Pct = screen.getByTestId("txtSMK3Pct");
    expect(txtSMK3Pct.tagName).toBe("INPUT");
    expect(txtSMK3Pct.type).toBe("text");
    expect(txtSMK3Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Pct", () => {
    // START_USER_CODE-USER_txtSMK3Pct_TEST
    // END_USER_CODE-USER_txtSMK3Pct_TEST
  });
  test("txtSMK4Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr = screen.getByTestId("txtSMK4Gr");
    expect(txtSMK4Gr.tagName).toBe("INPUT");
    expect(txtSMK4Gr.type).toBe("text");
    expect(txtSMK4Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSMK4Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK4Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Gr", () => {
    // START_USER_CODE-USER_txtSMK4Gr_TEST
    // END_USER_CODE-USER_txtSMK4Gr_TEST
  });
  test("txtSMK4Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK4Pct = screen.getByTestId("txtSMK4Pct");
    expect(txtSMK4Pct.tagName).toBe("INPUT");
    expect(txtSMK4Pct.type).toBe("text");
    expect(txtSMK4Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Pct", () => {
    // START_USER_CODE-USER_txtSMK4Pct_TEST
    // END_USER_CODE-USER_txtSMK4Pct_TEST
  });
  test("txtSoundSplitsPct(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsPct = screen.getByTestId("txtSoundSplitsPct");
    expect(txtSoundSplitsPct.tagName).toBe("INPUT");
    expect(txtSoundSplitsPct.type).toBe("text");
    expect(txtSoundSplitsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSoundSplitsPct, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsPct", () => {
    // START_USER_CODE-USER_txtSoundSplitsPct_TEST
    // END_USER_CODE-USER_txtSoundSplitsPct_TEST
  });
  test("txtStartDateTime(Textbox Widget) Test Cases", async () => {
    const txtStartDateTime = screen.getByTestId("txtStartDateTime");
    expect(txtStartDateTime.tagName).toBe("INPUT");
    expect(txtStartDateTime.type).toBe("text");
    expect(txtStartDateTime.classList).toContain("textboxWidgetClass");
    expect(txtStartDateTime.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtStartDateTime")
    );
    await act(async () => {
      userEvent.type(txtStartDateTime, "1");
    });
  });
  test("Custom Test Cases for txtStartDateTime", () => {
    // START_USER_CODE-USER_txtStartDateTime_TEST
    // END_USER_CODE-USER_txtStartDateTime_TEST
  });
  test("txtSupportPercent(Textbox Widget) Test Cases", async () => {
    const txtSupportPercent = screen.getByTestId("txtSupportPercent");
    expect(txtSupportPercent.tagName).toBe("INPUT");
    expect(txtSupportPercent.type).toBe("text");
    expect(txtSupportPercent.classList).toContain("textboxWidgetClass");
    expect(txtSupportPercent.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtSupportPercent")
    );
    await act(async () => {
      userEvent.type(txtSupportPercent, "1");
    });
  });
  test("Custom Test Cases for txtSupportPercent", () => {
    // START_USER_CODE-USER_txtSupportPercent_TEST
    // END_USER_CODE-USER_txtSupportPercent_TEST
  });
  test("txtTKCLbs(Textbox Widget) Test Cases", async () => {
    const txtTKCLbs = screen.getByTestId("txtTKCLbs");
    expect(txtTKCLbs.tagName).toBe("INPUT");
    expect(txtTKCLbs.type).toBe("text");
    expect(txtTKCLbs.classList).toContain("textboxWidgetClass");
    expect(txtTKCLbs.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTKCLbs")
    );
    await act(async () => {
      userEvent.type(txtTKCLbs, "1");
    });
  });
  test("Custom Test Cases for txtTKCLbs", () => {
    // START_USER_CODE-USER_txtTKCLbs_TEST
    // END_USER_CODE-USER_txtTKCLbs_TEST
  });
  test("txtTotalDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalDamagePct = screen.getByTestId("txtTotalDamagePct");
    expect(txtTotalDamagePct.tagName).toBe("INPUT");
    expect(txtTotalDamagePct.type).toBe("text");
    expect(txtTotalDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamagePct", () => {
    // START_USER_CODE-USER_txtTotalDamagePct_TEST
    // END_USER_CODE-USER_txtTotalDamagePct_TEST
  });
  test("txtTotalDiscountsPerPound(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsPerPound = screen.getByTestId(
      "txtTotalDiscountsPerPound"
    );
    expect(txtTotalDiscountsPerPound.tagName).toBe("INPUT");
    expect(txtTotalDiscountsPerPound.type).toBe("text");
    expect(txtTotalDiscountsPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDiscountsPerPound, "1");
    });
  });
  test("Custom Test Cases for txtTotalDiscountsPerPound", () => {
    // START_USER_CODE-USER_txtTotalDiscountsPerPound_TEST
    // END_USER_CODE-USER_txtTotalDiscountsPerPound_TEST
  });
  test("txtTotalDiscountsPerTon(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsPerTon = screen.getByTestId(
      "txtTotalDiscountsPerTon"
    );
    expect(txtTotalDiscountsPerTon.tagName).toBe("INPUT");
    expect(txtTotalDiscountsPerTon.type).toBe("text");
    expect(txtTotalDiscountsPerTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalDiscountsPerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTotalDiscountsPerTon")
    );
    await act(async () => {
      userEvent.type(txtTotalDiscountsPerTon, "1");
    });
  });
  test("Custom Test Cases for txtTotalDiscountsPerTon", () => {
    // START_USER_CODE-USER_txtTotalDiscountsPerTon_TEST
    // END_USER_CODE-USER_txtTotalDiscountsPerTon_TEST
  });
  test("txtTotalELKDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalELKDamagePct = screen.getByTestId("txtTotalELKDamagePct");
    expect(txtTotalELKDamagePct.tagName).toBe("INPUT");
    expect(txtTotalELKDamagePct.type).toBe("text");
    expect(txtTotalELKDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalELKDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalELKDamagePct", () => {
    // START_USER_CODE-USER_txtTotalELKDamagePct_TEST
    // END_USER_CODE-USER_txtTotalELKDamagePct_TEST
  });
  test("txtTotalLoadVal(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadVal = screen.getByTestId("txtTotalLoadVal");
    expect(txtTotalLoadVal.tagName).toBe("INPUT");
    expect(txtTotalLoadVal.type).toBe("text");
    expect(txtTotalLoadVal.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadVal.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTotalLoadVal")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadVal, "1");
    });
  });
  test("Custom Test Cases for txtTotalLoadVal", () => {
    // START_USER_CODE-USER_txtTotalLoadVal_TEST
    // END_USER_CODE-USER_txtTotalLoadVal_TEST
  });
  test("txtTotalLoadValue(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadValue = screen.getByTestId("txtTotalLoadValue");
    expect(txtTotalLoadValue.tagName).toBe("INPUT");
    expect(txtTotalLoadValue.type).toBe("text");
    expect(txtTotalLoadValue.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadValue.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTotalLoadValue")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadValue, "1");
    });
  });
  test("Custom Test Cases for txtTotalLoadValue", () => {
    // START_USER_CODE-USER_txtTotalLoadValue_TEST
    // END_USER_CODE-USER_txtTotalLoadValue_TEST
  });
  test("txtTotalOKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalOKPct = screen.getByTestId("txtTotalOKPct");
    expect(txtTotalOKPct.tagName).toBe("INPUT");
    expect(txtTotalOKPct.type).toBe("text");
    expect(txtTotalOKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalOKPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalOKPct", () => {
    // START_USER_CODE-USER_txtTotalOKPct_TEST
    // END_USER_CODE-USER_txtTotalOKPct_TEST
  });
  test("txtTotalPerPound(Textbox Widget) Test Cases", async () => {
    const txtTotalPerPound = screen.getByTestId("txtTotalPerPound");
    expect(txtTotalPerPound.tagName).toBe("INPUT");
    expect(txtTotalPerPound.type).toBe("text");
    expect(txtTotalPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalPerPound, "1");
    });
  });
  test("Custom Test Cases for txtTotalPerPound", () => {
    // START_USER_CODE-USER_txtTotalPerPound_TEST
    // END_USER_CODE-USER_txtTotalPerPound_TEST
  });
  test("txtTotalPerTon(Textbox Widget) Test Cases", async () => {
    const txtTotalPerTon = screen.getByTestId("txtTotalPerTon");
    expect(txtTotalPerTon.tagName).toBe("INPUT");
    expect(txtTotalPerTon.type).toBe("text");
    expect(txtTotalPerTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalPerTon.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTotalPerTon")
    );
    await act(async () => {
      userEvent.type(txtTotalPerTon, "1");
    });
  });
  test("Custom Test Cases for txtTotalPerTon", () => {
    // START_USER_CODE-USER_txtTotalPerTon_TEST
    // END_USER_CODE-USER_txtTotalPerTon_TEST
  });
  test("txtTotalSLKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSLKPct = screen.getByTestId("txtTotalSLKPct");
    expect(txtTotalSLKPct.tagName).toBe("INPUT");
    expect(txtTotalSLKPct.type).toBe("text");
    expect(txtTotalSLKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSLKPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalSLKPct", () => {
    // START_USER_CODE-USER_txtTotalSLKPct_TEST
    // END_USER_CODE-USER_txtTotalSLKPct_TEST
  });
  test("txtTotalSMKRScreenPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKRScreenPct = screen.getByTestId("txtTotalSMKRScreenPct");
    expect(txtTotalSMKRScreenPct.tagName).toBe("INPUT");
    expect(txtTotalSMKRScreenPct.type).toBe("text");
    expect(txtTotalSMKRScreenPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSMKRScreenPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMKRScreenPct", () => {
    // START_USER_CODE-USER_txtTotalSMKRScreenPct_TEST
    // END_USER_CODE-USER_txtTotalSMKRScreenPct_TEST
  });
  test("txtTradeInspectSC95(Textbox Widget) Test Cases", async () => {
    const txtTradeInspectSC95 = screen.getByTestId("txtTradeInspectSC95");
    expect(txtTradeInspectSC95.tagName).toBe("INPUT");
    expect(txtTradeInspectSC95.type).toBe("text");
    expect(txtTradeInspectSC95.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspectSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTradeInspectSC95")
    );
    await act(async () => {
      userEvent.type(txtTradeInspectSC95, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspectSC95", () => {
    // START_USER_CODE-USER_txtTradeInspectSC95_TEST
    // END_USER_CODE-USER_txtTradeInspectSC95_TEST
  });
  test("txtTradeSettle1007(Textbox Widget) Test Cases", async () => {
    const txtTradeSettle1007 = screen.getByTestId("txtTradeSettle1007");
    expect(txtTradeSettle1007.tagName).toBe("INPUT");
    expect(txtTradeSettle1007.type).toBe("text");
    expect(txtTradeSettle1007.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettle1007.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtTradeSettle1007")
    );
    await act(async () => {
      userEvent.type(txtTradeSettle1007, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettle1007", () => {
    // START_USER_CODE-USER_txtTradeSettle1007_TEST
    // END_USER_CODE-USER_txtTradeSettle1007_TEST
  });
  test("txtValPerPoundExclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundExclSLK = screen.getByTestId("txtValPerPoundExclSLK");
    expect(txtValPerPoundExclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundExclSLK.type).toBe("text");
    expect(txtValPerPoundExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtValPerPoundExclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundExclSLK, "1");
    });
  });
  test("Custom Test Cases for txtValPerPoundExclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundExclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundExclSLK_TEST
  });
  test("txtValPerPoundInclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundInclSLK = screen.getByTestId("txtValPerPoundInclSLK");
    expect(txtValPerPoundInclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundInclSLK.type).toBe("text");
    expect(txtValPerPoundInclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundInclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtValPerPoundInclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundInclSLK, "1");
    });
  });
  test("Custom Test Cases for txtValPerPoundInclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundInclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundInclSLK_TEST
  });
  test("txtVehicleWt(Textbox Widget) Test Cases", async () => {
    const txtVehicleWt = screen.getByTestId("txtVehicleWt");
    expect(txtVehicleWt.tagName).toBe("INPUT");
    expect(txtVehicleWt.type).toBe("text");
    expect(txtVehicleWt.classList).toContain("textboxWidgetClass");
    expect(txtVehicleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtVehicleWt")
    );
    await act(async () => {
      userEvent.type(txtVehicleWt, "1");
    });
  });
  test("Custom Test Cases for txtVehicleWt", () => {
    // START_USER_CODE-USER_txtVehicleWt_TEST
    // END_USER_CODE-USER_txtVehicleWt_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "1");
    });
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "1");
    });
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtVendor4(Textbox Widget) Test Cases", async () => {
    const txtVendor4 = screen.getByTestId("txtVendor4");
    expect(txtVendor4.tagName).toBe("INPUT");
    expect(txtVendor4.type).toBe("text");
    expect(txtVendor4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4, "1");
    });
  });
  test("Custom Test Cases for txtVendor4", () => {
    // START_USER_CODE-USER_txtVendor4_TEST
    // END_USER_CODE-USER_txtVendor4_TEST
  });
  test("txtWeightLessFM(Textbox Widget) Test Cases", async () => {
    const txtWeightLessFM = screen.getByTestId("txtWeightLessFM");
    expect(txtWeightLessFM.tagName).toBe("INPUT");
    expect(txtWeightLessFM.type).toBe("text");
    expect(txtWeightLessFM.classList).toContain("textboxWidgetClass");
    expect(txtWeightLessFM.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtWeightLessFM")
    );
    await act(async () => {
      userEvent.type(txtWeightLessFM, "1");
    });
  });
  test("Custom Test Cases for txtWeightLessFM", () => {
    // START_USER_CODE-USER_txtWeightLessFM_TEST
    // END_USER_CODE-USER_txtWeightLessFM_TEST
  });
  test("txtWormDamage(Textbox Widget) Test Cases", async () => {
    const txtWormDamage = screen.getByTestId("txtWormDamage");
    expect(txtWormDamage.tagName).toBe("INPUT");
    expect(txtWormDamage.type).toBe("text");
    expect(txtWormDamage.classList).toContain("textboxWidgetClass");
    expect(txtWormDamage.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtWormDamage")
    );
    await act(async () => {
      userEvent.type(txtWormDamage, "1");
    });
  });
  test("Custom Test Cases for txtWormDamage", () => {
    // START_USER_CODE-USER_txtWormDamage_TEST
    // END_USER_CODE-USER_txtWormDamage_TEST
  });
  test("txtWtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicle = screen.getByTestId("txtWtIncludingVehicle");
    expect(txtWtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicle.type).toBe("text");
    expect(txtWtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleProfile_txtWtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicle, "1");
    });
  });
  test("Custom Test Cases for txtWtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicle_TEST
  });
});
