/* eslint-disable*/
import React from "react";
import ChartWidget from "./ChartWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("ChartWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let chartwidget1 = {
        name: "chartwidget1",
        type: "ChartWidget",
        parent: "TestABSScreen",
        options: {
          chart: {
            type: "pieChart",
            height: 450,
            margin: {
              top: 20,
              right: 20,
              bottom: 40,
              left: 60,
            },
            x: function (d) {
              return d.key;
            },
            y: function (d) {
              return d.y;
            },
            showLabels: true,
            duration: 500,
            labelsOutside: true,
            labelThreshold: 0.01,
            labelSunbeamLayout: true,
            legend: {
              margin: {
                top: 5,
                right: 35,
                bottom: 5,
                left: 0,
              },
            },
          },
          title: {
            enable: false,
            text: "$chartTitle",
          },
          subtitle: {
            enable: false,
            text: "$chartSubtitle",
            css: {
              "text-align": "center",
              margin: "10px 13px 0px 7px",
            },
          },
          caption: {
            enable: false,
            html: "<span>$chartCaption</span>",
            css: {
              "text-align": "center",
              margin: "10px 13px 0px 7px",
            },
          },
        },
        Label: "chartwidget274",
        Height: "",
        Width: "",
        chartType: "pieChart",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      renderTestScreen(
        <ChartWidget conf={chartwidget1} screenConf={stateScreenConf} />
      );
    });
  });
  afterEach(cleanup);
  test("is Chartwidget1 Loads Succesfully", async () => {
    const chartwidget1 = screen.getByTestId("chartwidget1");
    expect(chartwidget1).toBeInTheDocument;
  });
});
describe("ChartWidget2 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let chartwidget2 = {
        name: "chartwidget2",
        type: "ChartWidget",
        parent: "TestABSScreen",
        options: {
          chart: {
            type: "pieChart",
            height: 450,
            width: 1275,
            margin: {
              top: 20,
              right: 20,
              bottom: 40,
              left: 60,
            },
            x: function (d) {
              return d.key;
            },
            y: function (d) {
              return d.y;
            },
            showLabels: true,
            duration: 500,
            labelsOutside: true,
            labelThreshold: 0.01,
            labelSunbeamLayout: true,
            legend: {
              margin: {
                top: 5,
                right: 35,
                bottom: 5,
                left: 0,
              },
            },
          },
          title: {
            enable: false,
            text: "$chartTitle",
          },
          subtitle: {
            enable: false,
            text: "$chartSubtitle",
            css: {
              "text-align": "center",
              margin: "10px 13px 0px 7px",
            },
          },
          caption: {
            enable: false,
            html: "<span>$chartCaption</span>",
            css: {
              "text-align": "center",
              margin: "10px 13px 0px 7px",
            },
          },
        },
        Label: "chartwidget2",
        Height: "",
        Width: "",
        chartType: "pieChart",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      renderTestScreen(
        <ChartWidget conf={chartwidget2} screenConf={stateScreenConf} />
      );
    });
  });
  afterEach(cleanup);
  test("is Chartwidget2 Loads Succesfully", async () => {
    const chartwidget2 = screen.getByTestId("chartwidget2");
    expect(chartwidget2).toBeInTheDocument;
  });
});
