/* eslint-disable*/
import React from "react";
import Settlements_CleaningCuringCosts from "./CleaningCuringCosts";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import '@testing-library/jest-dom';
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CleaningCuringCosts Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_CleaningCuringCosts />);
    });
  });
  afterEach(cleanup);
  test("is CleaningCuringCosts Loads Successfully", () => {
    expect(screen.getByText("CleaningCuringCosts")).toBeInTheDocument;
  });
  test("Custom Test Cases for CleaningCuringCosts", () => {
    // START_USER_CODE-USER_CleaningCuringCosts_Custom_Test_Case
    // END_USER_CODE-USER_CleaningCuringCosts_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_CleaningCuringCosts />);
    });
  });
  afterEach(cleanup);
  test("btnCanceluic0(Button Widget) Test Cases", async () => {
    const btnCanceluic0 = screen.getByTestId("btnCanceluic0");
    expect(btnCanceluic0).toBeInTheDocument;
    expect(btnCanceluic0.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_btnCanceluic0")
    );
  });
  test("Custom Test Cases for btnCanceluic0", () => {
    // START_USER_CODE-USER_btnCanceluic0_TEST
    // END_USER_CODE-USER_btnCanceluic0_TEST
  });
  test("btnOkuic0(Button Widget) Test Cases", async () => {
    const btnOkuic0 = screen.getByTestId("btnOkuic0");
    expect(btnOkuic0).toBeInTheDocument;
    expect(btnOkuic0.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_btnOkuic0")
    );
  });
  test("Custom Test Cases for btnOkuic0", () => {
    // START_USER_CODE-USER_btnOkuic0_TEST
    // END_USER_CODE-USER_btnOkuic0_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxClean(GroupBox Widget) Test Cases", async () => {
    const grpbxClean = screen.getByTestId("grpbxClean");
    expect(grpbxClean.tagName).toBe("BUTTON");
    expect(grpbxClean.type).toBe("button");
    expect(grpbxClean.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxClean", () => {
    // START_USER_CODE-USER_grpbxClean_TEST
    // END_USER_CODE-USER_grpbxClean_TEST
  });
  test("grpbxCleanGrossWt(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanGrossWt = screen.getByTestId("grpbxCleanGrossWt");
    expect(grpbxCleanGrossWt.tagName).toBe("BUTTON");
    expect(grpbxCleanGrossWt.type).toBe("button");
    expect(grpbxCleanGrossWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanGrossWt", () => {
    // START_USER_CODE-USER_grpbxCleanGrossWt_TEST
    // END_USER_CODE-USER_grpbxCleanGrossWt_TEST
  });
  test("grpbxCleanGrossWtRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanGrossWtRow0 = screen.getByTestId("grpbxCleanGrossWtRow0");
    expect(grpbxCleanGrossWtRow0.tagName).toBe("BUTTON");
    expect(grpbxCleanGrossWtRow0.type).toBe("button");
    expect(grpbxCleanGrossWtRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanGrossWtRow0", () => {
    // START_USER_CODE-USER_grpbxCleanGrossWtRow0_TEST
    // END_USER_CODE-USER_grpbxCleanGrossWtRow0_TEST
  });
  test("grpbxCleanGrossWtRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanGrossWtRow1 = screen.getByTestId("grpbxCleanGrossWtRow1");
    expect(grpbxCleanGrossWtRow1.tagName).toBe("BUTTON");
    expect(grpbxCleanGrossWtRow1.type).toBe("button");
    expect(grpbxCleanGrossWtRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanGrossWtRow1", () => {
    // START_USER_CODE-USER_grpbxCleanGrossWtRow1_TEST
    // END_USER_CODE-USER_grpbxCleanGrossWtRow1_TEST
  });
  test("grpbxCleanGrossWtRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanGrossWtRow2 = screen.getByTestId("grpbxCleanGrossWtRow2");
    expect(grpbxCleanGrossWtRow2.tagName).toBe("BUTTON");
    expect(grpbxCleanGrossWtRow2.type).toBe("button");
    expect(grpbxCleanGrossWtRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanGrossWtRow2", () => {
    // START_USER_CODE-USER_grpbxCleanGrossWtRow2_TEST
    // END_USER_CODE-USER_grpbxCleanGrossWtRow2_TEST
  });
  test("grpbxCleaningCuringCosts(GroupBox Widget) Test Cases", async () => {
    const grpbxCleaningCuringCosts = screen.getByTestId(
      "grpbxCleaningCuringCosts"
    );
    expect(grpbxCleaningCuringCosts.tagName).toBe("BUTTON");
    expect(grpbxCleaningCuringCosts.type).toBe("button");
    expect(grpbxCleaningCuringCosts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleaningCuringCosts", () => {
    // START_USER_CODE-USER_grpbxCleaningCuringCosts_TEST
    // END_USER_CODE-USER_grpbxCleaningCuringCosts_TEST
  });
  test("grpbxCleanNetWt(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanNetWt = screen.getByTestId("grpbxCleanNetWt");
    expect(grpbxCleanNetWt.tagName).toBe("BUTTON");
    expect(grpbxCleanNetWt.type).toBe("button");
    expect(grpbxCleanNetWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanNetWt", () => {
    // START_USER_CODE-USER_grpbxCleanNetWt_TEST
    // END_USER_CODE-USER_grpbxCleanNetWt_TEST
  });
  test("grpbxCleanNetWtRow(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanNetWtRow = screen.getByTestId("grpbxCleanNetWtRow");
    expect(grpbxCleanNetWtRow.tagName).toBe("BUTTON");
    expect(grpbxCleanNetWtRow.type).toBe("button");
    expect(grpbxCleanNetWtRow.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanNetWtRow", () => {
    // START_USER_CODE-USER_grpbxCleanNetWtRow_TEST
    // END_USER_CODE-USER_grpbxCleanNetWtRow_TEST
  });
  test("grpbxCleanOther(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanOther = screen.getByTestId("grpbxCleanOther");
    expect(grpbxCleanOther.tagName).toBe("BUTTON");
    expect(grpbxCleanOther.type).toBe("button");
    expect(grpbxCleanOther.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanOther", () => {
    // START_USER_CODE-USER_grpbxCleanOther_TEST
    // END_USER_CODE-USER_grpbxCleanOther_TEST
  });
  test("grpbxCleanOtherRow(GroupBox Widget) Test Cases", async () => {
    const grpbxCleanOtherRow = screen.getByTestId("grpbxCleanOtherRow");
    expect(grpbxCleanOtherRow.tagName).toBe("BUTTON");
    expect(grpbxCleanOtherRow.type).toBe("button");
    expect(grpbxCleanOtherRow.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCleanOtherRow", () => {
    // START_USER_CODE-USER_grpbxCleanOtherRow_TEST
    // END_USER_CODE-USER_grpbxCleanOtherRow_TEST
  });
  test("grpbxCureClean(GroupBox Widget) Test Cases", async () => {
    const grpbxCureClean = screen.getByTestId("grpbxCureClean");
    expect(grpbxCureClean.tagName).toBe("BUTTON");
    expect(grpbxCureClean.type).toBe("button");
    expect(grpbxCureClean.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureClean", () => {
    // START_USER_CODE-USER_grpbxCureClean_TEST
    // END_USER_CODE-USER_grpbxCureClean_TEST
  });
  test("grpbxCureCleanGrossWt(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanGrossWt = screen.getByTestId("grpbxCureCleanGrossWt");
    expect(grpbxCureCleanGrossWt.tagName).toBe("BUTTON");
    expect(grpbxCureCleanGrossWt.type).toBe("button");
    expect(grpbxCureCleanGrossWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanGrossWt", () => {
    // START_USER_CODE-USER_grpbxCureCleanGrossWt_TEST
    // END_USER_CODE-USER_grpbxCureCleanGrossWt_TEST
  });
  test("grpbxCureCleanGrossWtRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanGrossWtRow0 = screen.getByTestId(
      "grpbxCureCleanGrossWtRow0"
    );
    expect(grpbxCureCleanGrossWtRow0.tagName).toBe("BUTTON");
    expect(grpbxCureCleanGrossWtRow0.type).toBe("button");
    expect(grpbxCureCleanGrossWtRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanGrossWtRow0", () => {
    // START_USER_CODE-USER_grpbxCureCleanGrossWtRow0_TEST
    // END_USER_CODE-USER_grpbxCureCleanGrossWtRow0_TEST
  });
  test("grpbxCureCleanGrossWtRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanGrossWtRow1 = screen.getByTestId(
      "grpbxCureCleanGrossWtRow1"
    );
    expect(grpbxCureCleanGrossWtRow1.tagName).toBe("BUTTON");
    expect(grpbxCureCleanGrossWtRow1.type).toBe("button");
    expect(grpbxCureCleanGrossWtRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanGrossWtRow1", () => {
    // START_USER_CODE-USER_grpbxCureCleanGrossWtRow1_TEST
    // END_USER_CODE-USER_grpbxCureCleanGrossWtRow1_TEST
  });
  test("grpbxCureCleanGrossWtRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanGrossWtRow2 = screen.getByTestId(
      "grpbxCureCleanGrossWtRow2"
    );
    expect(grpbxCureCleanGrossWtRow2.tagName).toBe("BUTTON");
    expect(grpbxCureCleanGrossWtRow2.type).toBe("button");
    expect(grpbxCureCleanGrossWtRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanGrossWtRow2", () => {
    // START_USER_CODE-USER_grpbxCureCleanGrossWtRow2_TEST
    // END_USER_CODE-USER_grpbxCureCleanGrossWtRow2_TEST
  });
  test("grpbxCureCleanNetWt(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanNetWt = screen.getByTestId("grpbxCureCleanNetWt");
    expect(grpbxCureCleanNetWt.tagName).toBe("BUTTON");
    expect(grpbxCureCleanNetWt.type).toBe("button");
    expect(grpbxCureCleanNetWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanNetWt", () => {
    // START_USER_CODE-USER_grpbxCureCleanNetWt_TEST
    // END_USER_CODE-USER_grpbxCureCleanNetWt_TEST
  });
  test("grpbxCureCleanNetWtRow(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanNetWtRow = screen.getByTestId("grpbxCureCleanNetWtRow");
    expect(grpbxCureCleanNetWtRow.tagName).toBe("BUTTON");
    expect(grpbxCureCleanNetWtRow.type).toBe("button");
    expect(grpbxCureCleanNetWtRow.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanNetWtRow", () => {
    // START_USER_CODE-USER_grpbxCureCleanNetWtRow_TEST
    // END_USER_CODE-USER_grpbxCureCleanNetWtRow_TEST
  });
  test("grpbxCureCleanOther(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanOther = screen.getByTestId("grpbxCureCleanOther");
    expect(grpbxCureCleanOther.tagName).toBe("BUTTON");
    expect(grpbxCureCleanOther.type).toBe("button");
    expect(grpbxCureCleanOther.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanOther", () => {
    // START_USER_CODE-USER_grpbxCureCleanOther_TEST
    // END_USER_CODE-USER_grpbxCureCleanOther_TEST
  });
  test("grpbxCureCleanOtherRow(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanOtherRow = screen.getByTestId("grpbxCureCleanOtherRow");
    expect(grpbxCureCleanOtherRow.tagName).toBe("BUTTON");
    expect(grpbxCureCleanOtherRow.type).toBe("button");
    expect(grpbxCureCleanOtherRow.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanOtherRow", () => {
    // START_USER_CODE-USER_grpbxCureCleanOtherRow_TEST
    // END_USER_CODE-USER_grpbxCureCleanOtherRow_TEST
  });
  test("grpbxRadioClean(GroupBox Widget) Test Cases", async () => {
    const grpbxRadioClean = screen.getByTestId("grpbxRadioClean");
    expect(grpbxRadioClean.tagName).toBe("BUTTON");
    expect(grpbxRadioClean.type).toBe("button");
    expect(grpbxRadioClean.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRadioClean", () => {
    // START_USER_CODE-USER_grpbxRadioClean_TEST
    // END_USER_CODE-USER_grpbxRadioClean_TEST
  });
  test("grpbxRadioCureClean(GroupBox Widget) Test Cases", async () => {
    const grpbxRadioCureClean = screen.getByTestId("grpbxRadioCureClean");
    expect(grpbxRadioCureClean.tagName).toBe("BUTTON");
    expect(grpbxRadioCureClean.type).toBe("button");
    expect(grpbxRadioCureClean.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRadioCureClean", () => {
    // START_USER_CODE-USER_grpbxRadioCureClean_TEST
    // END_USER_CODE-USER_grpbxRadioCureClean_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblCCCost(Label Widget) Test Cases", async () => {
    const lblCCCost = screen.getByTestId("lblCCCost");
    expect(lblCCCost.tagName).toBe("LABEL");
    expect(lblCCCost.classList).toContain("form-label");
    expect(lblCCCost.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCCost")
    );
  });
  test("Custom Test Cases for lblCCCost", () => {
    // START_USER_CODE-USER_lblCCCost_TEST
    // END_USER_CODE-USER_lblCCCost_TEST
  });
  test("lblCCDryWt(Label Widget) Test Cases", async () => {
    const lblCCDryWt = screen.getByTestId("lblCCDryWt");
    expect(lblCCDryWt.tagName).toBe("LABEL");
    expect(lblCCDryWt.classList).toContain("form-label");
    expect(lblCCDryWt.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCDryWt")
    );
  });
  test("Custom Test Cases for lblCCDryWt", () => {
    // START_USER_CODE-USER_lblCCDryWt_TEST
    // END_USER_CODE-USER_lblCCDryWt_TEST
  });
  test("lblCCGreenWt(Label Widget) Test Cases", async () => {
    const lblCCGreenWt = screen.getByTestId("lblCCGreenWt");
    expect(lblCCGreenWt.tagName).toBe("LABEL");
    expect(lblCCGreenWt.classList).toContain("form-label");
    expect(lblCCGreenWt.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCGreenWt")
    );
  });
  test("Custom Test Cases for lblCCGreenWt", () => {
    // START_USER_CODE-USER_lblCCGreenWt_TEST
    // END_USER_CODE-USER_lblCCGreenWt_TEST
  });
  test("lblCCMoisture(Label Widget) Test Cases", async () => {
    const lblCCMoisture = screen.getByTestId("lblCCMoisture");
    expect(lblCCMoisture.tagName).toBe("LABEL");
    expect(lblCCMoisture.classList).toContain("form-label");
    expect(lblCCMoisture.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCMoisture")
    );
  });
  test("Custom Test Cases for lblCCMoisture", () => {
    // START_USER_CODE-USER_lblCCMoisture_TEST
    // END_USER_CODE-USER_lblCCMoisture_TEST
  });
  test("lblCCNetWeight(Label Widget) Test Cases", async () => {
    const lblCCNetWeight = screen.getByTestId("lblCCNetWeight");
    expect(lblCCNetWeight.tagName).toBe("LABEL");
    expect(lblCCNetWeight.classList).toContain("form-label");
    expect(lblCCNetWeight.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCNetWeight")
    );
  });
  test("Custom Test Cases for lblCCNetWeight", () => {
    // START_USER_CODE-USER_lblCCNetWeight_TEST
    // END_USER_CODE-USER_lblCCNetWeight_TEST
  });
  test("lblCCNWCost(Label Widget) Test Cases", async () => {
    const lblCCNWCost = screen.getByTestId("lblCCNWCost");
    expect(lblCCNWCost.tagName).toBe("LABEL");
    expect(lblCCNWCost.classList).toContain("form-label");
    expect(lblCCNWCost.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCNWCost")
    );
  });
  test("Custom Test Cases for lblCCNWCost", () => {
    // START_USER_CODE-USER_lblCCNWCost_TEST
    // END_USER_CODE-USER_lblCCNWCost_TEST
  });
  test("lblCCNWMoisture(Label Widget) Test Cases", async () => {
    const lblCCNWMoisture = screen.getByTestId("lblCCNWMoisture");
    expect(lblCCNWMoisture.tagName).toBe("LABEL");
    expect(lblCCNWMoisture.classList).toContain("form-label");
    expect(lblCCNWMoisture.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCNWMoisture")
    );
  });
  test("Custom Test Cases for lblCCNWMoisture", () => {
    // START_USER_CODE-USER_lblCCNWMoisture_TEST
    // END_USER_CODE-USER_lblCCNWMoisture_TEST
  });
  test("lblCCNWSchedule(Label Widget) Test Cases", async () => {
    const lblCCNWSchedule = screen.getByTestId("lblCCNWSchedule");
    expect(lblCCNWSchedule.tagName).toBe("LABEL");
    expect(lblCCNWSchedule.classList).toContain("form-label");
    expect(lblCCNWSchedule.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCNWSchedule")
    );
  });
  test("Custom Test Cases for lblCCNWSchedule", () => {
    // START_USER_CODE-USER_lblCCNWSchedule_TEST
    // END_USER_CODE-USER_lblCCNWSchedule_TEST
  });
  test("lblCCOCost(Label Widget) Test Cases", async () => {
    const lblCCOCost = screen.getByTestId("lblCCOCost");
    expect(lblCCOCost.tagName).toBe("LABEL");
    expect(lblCCOCost.classList).toContain("form-label");
    expect(lblCCOCost.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCOCost")
    );
  });
  test("Custom Test Cases for lblCCOCost", () => {
    // START_USER_CODE-USER_lblCCOCost_TEST
    // END_USER_CODE-USER_lblCCOCost_TEST
  });
  test("lblCCOMoisture(Label Widget) Test Cases", async () => {
    const lblCCOMoisture = screen.getByTestId("lblCCOMoisture");
    expect(lblCCOMoisture.tagName).toBe("LABEL");
    expect(lblCCOMoisture.classList).toContain("form-label");
    expect(lblCCOMoisture.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCOMoisture")
    );
  });
  test("Custom Test Cases for lblCCOMoisture", () => {
    // START_USER_CODE-USER_lblCCOMoisture_TEST
    // END_USER_CODE-USER_lblCCOMoisture_TEST
  });
  test("lblCCOSchedule(Label Widget) Test Cases", async () => {
    const lblCCOSchedule = screen.getByTestId("lblCCOSchedule");
    expect(lblCCOSchedule.tagName).toBe("LABEL");
    expect(lblCCOSchedule.classList).toContain("form-label");
    expect(lblCCOSchedule.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCOSchedule")
    );
  });
  test("Custom Test Cases for lblCCOSchedule", () => {
    // START_USER_CODE-USER_lblCCOSchedule_TEST
    // END_USER_CODE-USER_lblCCOSchedule_TEST
  });
  test("lblCCost(Label Widget) Test Cases", async () => {
    const lblCCost = screen.getByTestId("lblCCost");
    expect(lblCCost.tagName).toBe("LABEL");
    expect(lblCCost.classList).toContain("form-label");
    expect(lblCCost.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCost")
    );
  });
  test("Custom Test Cases for lblCCost", () => {
    // START_USER_CODE-USER_lblCCost_TEST
    // END_USER_CODE-USER_lblCCost_TEST
  });
  test("lblCCOWeight(Label Widget) Test Cases", async () => {
    const lblCCOWeight = screen.getByTestId("lblCCOWeight");
    expect(lblCCOWeight.tagName).toBe("LABEL");
    expect(lblCCOWeight.classList).toContain("form-label");
    expect(lblCCOWeight.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCOWeight")
    );
  });
  test("Custom Test Cases for lblCCOWeight", () => {
    // START_USER_CODE-USER_lblCCOWeight_TEST
    // END_USER_CODE-USER_lblCCOWeight_TEST
  });
  test("lblCCSchedule(Label Widget) Test Cases", async () => {
    const lblCCSchedule = screen.getByTestId("lblCCSchedule");
    expect(lblCCSchedule.tagName).toBe("LABEL");
    expect(lblCCSchedule.classList).toContain("form-label");
    expect(lblCCSchedule.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCSchedule")
    );
  });
  test("Custom Test Cases for lblCCSchedule", () => {
    // START_USER_CODE-USER_lblCCSchedule_TEST
    // END_USER_CODE-USER_lblCCSchedule_TEST
  });
  test("lblCCTicket(Label Widget) Test Cases", async () => {
    const lblCCTicket = screen.getByTestId("lblCCTicket");
    expect(lblCCTicket.tagName).toBe("LABEL");
    expect(lblCCTicket.classList).toContain("form-label");
    expect(lblCCTicket.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCCTicket")
    );
  });
  test("Custom Test Cases for lblCCTicket", () => {
    // START_USER_CODE-USER_lblCCTicket_TEST
    // END_USER_CODE-USER_lblCCTicket_TEST
  });
  test("lblCDryWt(Label Widget) Test Cases", async () => {
    const lblCDryWt = screen.getByTestId("lblCDryWt");
    expect(lblCDryWt.tagName).toBe("LABEL");
    expect(lblCDryWt.classList).toContain("form-label");
    expect(lblCDryWt.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCDryWt")
    );
  });
  test("Custom Test Cases for lblCDryWt", () => {
    // START_USER_CODE-USER_lblCDryWt_TEST
    // END_USER_CODE-USER_lblCDryWt_TEST
  });
  test("lblCGreenWt(Label Widget) Test Cases", async () => {
    const lblCGreenWt = screen.getByTestId("lblCGreenWt");
    expect(lblCGreenWt.tagName).toBe("LABEL");
    expect(lblCGreenWt.classList).toContain("form-label");
    expect(lblCGreenWt.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCGreenWt")
    );
  });
  test("Custom Test Cases for lblCGreenWt", () => {
    // START_USER_CODE-USER_lblCGreenWt_TEST
    // END_USER_CODE-USER_lblCGreenWt_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCleaning(Label Widget) Test Cases", async () => {
    const lblCleaning = screen.getByTestId("lblCleaning");
    expect(lblCleaning.tagName).toBe("LABEL");
    expect(lblCleaning.classList).toContain("form-label");
    expect(lblCleaning.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCleaning")
    );
  });
  test("Custom Test Cases for lblCleaning", () => {
    // START_USER_CODE-USER_lblCleaning_TEST
    // END_USER_CODE-USER_lblCleaning_TEST
  });
  test("lblCNetWeight(Label Widget) Test Cases", async () => {
    const lblCNetWeight = screen.getByTestId("lblCNetWeight");
    expect(lblCNetWeight.tagName).toBe("LABEL");
    expect(lblCNetWeight.classList).toContain("form-label");
    expect(lblCNetWeight.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCNetWeight")
    );
  });
  test("Custom Test Cases for lblCNetWeight", () => {
    // START_USER_CODE-USER_lblCNetWeight_TEST
    // END_USER_CODE-USER_lblCNetWeight_TEST
  });
  test("lblCNWCost(Label Widget) Test Cases", async () => {
    const lblCNWCost = screen.getByTestId("lblCNWCost");
    expect(lblCNWCost.tagName).toBe("LABEL");
    expect(lblCNWCost.classList).toContain("form-label");
    expect(lblCNWCost.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCNWCost")
    );
  });
  test("Custom Test Cases for lblCNWCost", () => {
    // START_USER_CODE-USER_lblCNWCost_TEST
    // END_USER_CODE-USER_lblCNWCost_TEST
  });
  test("lblCNWSchedule(Label Widget) Test Cases", async () => {
    const lblCNWSchedule = screen.getByTestId("lblCNWSchedule");
    expect(lblCNWSchedule.tagName).toBe("LABEL");
    expect(lblCNWSchedule.classList).toContain("form-label");
    expect(lblCNWSchedule.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCNWSchedule")
    );
  });
  test("Custom Test Cases for lblCNWSchedule", () => {
    // START_USER_CODE-USER_lblCNWSchedule_TEST
    // END_USER_CODE-USER_lblCNWSchedule_TEST
  });
  test("lblCOCost(Label Widget) Test Cases", async () => {
    const lblCOCost = screen.getByTestId("lblCOCost");
    expect(lblCOCost.tagName).toBe("LABEL");
    expect(lblCOCost.classList).toContain("form-label");
    expect(lblCOCost.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCOCost")
    );
  });
  test("Custom Test Cases for lblCOCost", () => {
    // START_USER_CODE-USER_lblCOCost_TEST
    // END_USER_CODE-USER_lblCOCost_TEST
  });
  test("lblCOSchedule(Label Widget) Test Cases", async () => {
    const lblCOSchedule = screen.getByTestId("lblCOSchedule");
    expect(lblCOSchedule.tagName).toBe("LABEL");
    expect(lblCOSchedule.classList).toContain("form-label");
    expect(lblCOSchedule.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCOSchedule")
    );
  });
  test("Custom Test Cases for lblCOSchedule", () => {
    // START_USER_CODE-USER_lblCOSchedule_TEST
    // END_USER_CODE-USER_lblCOSchedule_TEST
  });
  test("lblCOWeight(Label Widget) Test Cases", async () => {
    const lblCOWeight = screen.getByTestId("lblCOWeight");
    expect(lblCOWeight.tagName).toBe("LABEL");
    expect(lblCOWeight.classList).toContain("form-label");
    expect(lblCOWeight.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCOWeight")
    );
  });
  test("Custom Test Cases for lblCOWeight", () => {
    // START_USER_CODE-USER_lblCOWeight_TEST
    // END_USER_CODE-USER_lblCOWeight_TEST
  });
  test("lblCSchedule(Label Widget) Test Cases", async () => {
    const lblCSchedule = screen.getByTestId("lblCSchedule");
    expect(lblCSchedule.tagName).toBe("LABEL");
    expect(lblCSchedule.classList).toContain("form-label");
    expect(lblCSchedule.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCSchedule")
    );
  });
  test("Custom Test Cases for lblCSchedule", () => {
    // START_USER_CODE-USER_lblCSchedule_TEST
    // END_USER_CODE-USER_lblCSchedule_TEST
  });
  test("lblCTicket(Label Widget) Test Cases", async () => {
    const lblCTicket = screen.getByTestId("lblCTicket");
    expect(lblCTicket.tagName).toBe("LABEL");
    expect(lblCTicket.classList).toContain("form-label");
    expect(lblCTicket.textContent).toEqual(
      t("Settlements:CleaningCuringCosts_lblCTicket")
    );
  });
  test("Custom Test Cases for lblCTicket", () => {
    // START_USER_CODE-USER_lblCTicket_TEST
    // END_USER_CODE-USER_lblCTicket_TEST
  });
  test("txtCCCost0(Textbox Widget) Test Cases", async () => {
    const txtCCCost0 = screen.getByTestId("txtCCCost0");
    expect(txtCCCost0.tagName).toBe("INPUT");
    expect(txtCCCost0.type).toBe("text");
    expect(txtCCCost0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCCost0, "123");
    });
    expect(txtCCCost0.getAttribute("value")).toBe("");
    expect(txtCCCost0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCCost0", () => {
    // START_USER_CODE-USER_txtCCCost0_TEST
    // END_USER_CODE-USER_txtCCCost0_TEST
  });
  test("txtCCCost1(Textbox Widget) Test Cases", async () => {
    const txtCCCost1 = screen.getByTestId("txtCCCost1");
    expect(txtCCCost1.tagName).toBe("INPUT");
    expect(txtCCCost1.type).toBe("text");
    expect(txtCCCost1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCCost1, "123");
    });
    expect(txtCCCost1.getAttribute("value")).toBe("");
    expect(txtCCCost1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCCost1", () => {
    // START_USER_CODE-USER_txtCCCost1_TEST
    // END_USER_CODE-USER_txtCCCost1_TEST
  });
  test("txtCCDryWt0(Textbox Widget) Test Cases", async () => {
    const txtCCDryWt0 = screen.getByTestId("txtCCDryWt0");
    expect(txtCCDryWt0.tagName).toBe("INPUT");
    expect(txtCCDryWt0.type).toBe("text");
    expect(txtCCDryWt0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCDryWt0, "123");
    });
    expect(txtCCDryWt0.getAttribute("value")).toBe("");
    expect(txtCCDryWt0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCDryWt0", () => {
    // START_USER_CODE-USER_txtCCDryWt0_TEST
    // END_USER_CODE-USER_txtCCDryWt0_TEST
  });
  test("txtCCDryWt1(Textbox Widget) Test Cases", async () => {
    const txtCCDryWt1 = screen.getByTestId("txtCCDryWt1");
    expect(txtCCDryWt1.tagName).toBe("INPUT");
    expect(txtCCDryWt1.type).toBe("text");
    expect(txtCCDryWt1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCDryWt1, "123");
    });
    expect(txtCCDryWt1.getAttribute("value")).toBe("");
    expect(txtCCDryWt1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCDryWt1", () => {
    // START_USER_CODE-USER_txtCCDryWt1_TEST
    // END_USER_CODE-USER_txtCCDryWt1_TEST
  });
  test("txtCCDryWt2(Textbox Widget) Test Cases", async () => {
    const txtCCDryWt2 = screen.getByTestId("txtCCDryWt2");
    expect(txtCCDryWt2.tagName).toBe("INPUT");
    expect(txtCCDryWt2.type).toBe("text");
    expect(txtCCDryWt2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCDryWt2, "123");
    });
    expect(txtCCDryWt2.getAttribute("value")).toBe("");
    expect(txtCCDryWt2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCDryWt2", () => {
    // START_USER_CODE-USER_txtCCDryWt2_TEST
    // END_USER_CODE-USER_txtCCDryWt2_TEST
  });
  test("txtCCGreenWt0(Textbox Widget) Test Cases", async () => {
    const txtCCGreenWt0 = screen.getByTestId("txtCCGreenWt0");
    expect(txtCCGreenWt0.tagName).toBe("INPUT");
    expect(txtCCGreenWt0.type).toBe("text");
    expect(txtCCGreenWt0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCGreenWt0, "123");
    });
    expect(txtCCGreenWt0.getAttribute("value")).toBe("");
    expect(txtCCGreenWt0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCGreenWt0", () => {
    // START_USER_CODE-USER_txtCCGreenWt0_TEST
    // END_USER_CODE-USER_txtCCGreenWt0_TEST
  });
  test("txtCCGreenWt1(Textbox Widget) Test Cases", async () => {
    const txtCCGreenWt1 = screen.getByTestId("txtCCGreenWt1");
    expect(txtCCGreenWt1.tagName).toBe("INPUT");
    expect(txtCCGreenWt1.type).toBe("text");
    expect(txtCCGreenWt1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCGreenWt1, "123");
    });
    expect(txtCCGreenWt1.getAttribute("value")).toBe("");
    expect(txtCCGreenWt1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCGreenWt1", () => {
    // START_USER_CODE-USER_txtCCGreenWt1_TEST
    // END_USER_CODE-USER_txtCCGreenWt1_TEST
  });
  test("txtCCGreenWt2(Textbox Widget) Test Cases", async () => {
    const txtCCGreenWt2 = screen.getByTestId("txtCCGreenWt2");
    expect(txtCCGreenWt2.tagName).toBe("INPUT");
    expect(txtCCGreenWt2.type).toBe("text");
    expect(txtCCGreenWt2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCGreenWt2, "123");
    });
    expect(txtCCGreenWt2.getAttribute("value")).toBe("");
    expect(txtCCGreenWt2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCGreenWt2", () => {
    // START_USER_CODE-USER_txtCCGreenWt2_TEST
    // END_USER_CODE-USER_txtCCGreenWt2_TEST
  });
  test("txtCCMoisture0(Textbox Widget) Test Cases", async () => {
    const txtCCMoisture0 = screen.getByTestId("txtCCMoisture0");
    expect(txtCCMoisture0.tagName).toBe("INPUT");
    expect(txtCCMoisture0.type).toBe("text");
    expect(txtCCMoisture0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCMoisture0, "123");
    });
    expect(txtCCMoisture0.getAttribute("value")).toBe("3");
    // expect(txtCCMoisture0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCMoisture0", () => {
    // START_USER_CODE-USER_txtCCMoisture0_TEST
    // END_USER_CODE-USER_txtCCMoisture0_TEST
  });
  test("txtCCMoisture1(Textbox Widget) Test Cases", async () => {
    const txtCCMoisture1 = screen.getByTestId("txtCCMoisture1");
    expect(txtCCMoisture1.tagName).toBe("INPUT");
    expect(txtCCMoisture1.type).toBe("text");
    expect(txtCCMoisture1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCMoisture1, "123");
    });
    expect(txtCCMoisture1.getAttribute("value")).toBe("3");
    // expect(txtCCMoisture1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCMoisture1", () => {
    // START_USER_CODE-USER_txtCCMoisture1_TEST
    // END_USER_CODE-USER_txtCCMoisture1_TEST
  });
  test("txtCCMoisture2(Textbox Widget) Test Cases", async () => {
    const txtCCMoisture2 = screen.getByTestId("txtCCMoisture2");
    expect(txtCCMoisture2.tagName).toBe("INPUT");
    expect(txtCCMoisture2.type).toBe("text");
    expect(txtCCMoisture2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCMoisture2, "123");
    });
    expect(txtCCMoisture2.getAttribute("value")).toBe("3");
    // expect(txtCCMoisture2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCMoisture2", () => {
    // START_USER_CODE-USER_txtCCMoisture2_TEST
    // END_USER_CODE-USER_txtCCMoisture2_TEST
  });
  test("txtCCNetWeight(Textbox Widget) Test Cases", async () => {
    const txtCCNetWeight = screen.getByTestId("txtCCNetWeight");
    expect(txtCCNetWeight.tagName).toBe("INPUT");
    expect(txtCCNetWeight.type).toBe("text");
    expect(txtCCNetWeight.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCNetWeight, "1");
    });
  });
  test("Custom Test Cases for txtCCNetWeight", () => {
    // START_USER_CODE-USER_txtCCNetWeight_TEST
    // END_USER_CODE-USER_txtCCNetWeight_TEST
  });
  test("txtCCNWCost(Textbox Widget) Test Cases", async () => {
    const txtCCNWCost = screen.getByTestId("txtCCNWCost");
    expect(txtCCNWCost.tagName).toBe("INPUT");
    expect(txtCCNWCost.type).toBe("text");
    expect(txtCCNWCost.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCNWCost, "1");
    });
  });
  test("Custom Test Cases for txtCCNWCost", () => {
    // START_USER_CODE-USER_txtCCNWCost_TEST
    // END_USER_CODE-USER_txtCCNWCost_TEST
  });
  test("txtCCNWMoisture(Textbox Widget) Test Cases", async () => {
    const txtCCNWMoisture = screen.getByTestId("txtCCNWMoisture");
    expect(txtCCNWMoisture.tagName).toBe("INPUT");
    expect(txtCCNWMoisture.type).toBe("text");
    expect(txtCCNWMoisture.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCNWMoisture, "1");
    });
  });
  test("Custom Test Cases for txtCCNWMoisture", () => {
    // START_USER_CODE-USER_txtCCNWMoisture_TEST
    // END_USER_CODE-USER_txtCCNWMoisture_TEST
  });
  test("txtCCOCost(Textbox Widget) Test Cases", async () => {
    const txtCCOCost = screen.getByTestId("txtCCOCost");
    expect(txtCCOCost.tagName).toBe("INPUT");
    expect(txtCCOCost.type).toBe("text");
    expect(txtCCOCost.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCOCost, "1");
    });
  });
  test("Custom Test Cases for txtCCOCost", () => {
    // START_USER_CODE-USER_txtCCOCost_TEST
    // END_USER_CODE-USER_txtCCOCost_TEST
  });
  test("txtCCOMoisture(Textbox Widget) Test Cases", async () => {
    const txtCCOMoisture = screen.getByTestId("txtCCOMoisture");
    expect(txtCCOMoisture.tagName).toBe("INPUT");
    expect(txtCCOMoisture.type).toBe("text");
    expect(txtCCOMoisture.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCOMoisture, "1");
    });
  });
  test("Custom Test Cases for txtCCOMoisture", () => {
    // START_USER_CODE-USER_txtCCOMoisture_TEST
    // END_USER_CODE-USER_txtCCOMoisture_TEST
  });
  test("txtCCost0(Textbox Widget) Test Cases", async () => {
    const txtCCost0 = screen.getByTestId("txtCCost0");
    expect(txtCCost0.tagName).toBe("INPUT");
    expect(txtCCost0.type).toBe("text");
    expect(txtCCost0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCost0, "123");
    });
    expect(txtCCost0.getAttribute("value")).toBe("");
    expect(txtCCost0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCost0", () => {
    // START_USER_CODE-USER_txtCCost0_TEST
    // END_USER_CODE-USER_txtCCost0_TEST
  });
  test("txtCCost1(Textbox Widget) Test Cases", async () => {
    const txtCCost1 = screen.getByTestId("txtCCost1");
    expect(txtCCost1.tagName).toBe("INPUT");
    expect(txtCCost1.type).toBe("text");
    expect(txtCCost1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCost1, "123");
    });
    expect(txtCCost1.getAttribute("value")).toBe("");
    expect(txtCCost1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCost1", () => {
    // START_USER_CODE-USER_txtCCost1_TEST
    // END_USER_CODE-USER_txtCCost1_TEST
  });
  test("txtCCost2(Textbox Widget) Test Cases", async () => {
    const txtCCost2 = screen.getByTestId("txtCCost2");
    expect(txtCCost2.tagName).toBe("INPUT");
    expect(txtCCost2.type).toBe("text");
    expect(txtCCost2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCost2, "123");
    });
    expect(txtCCost2.getAttribute("value")).toBe("");
    expect(txtCCost2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCost2", () => {
    // START_USER_CODE-USER_txtCCost2_TEST
    // END_USER_CODE-USER_txtCCost2_TEST
  });
  test("txtCCOWeight(Textbox Widget) Test Cases", async () => {
    const txtCCOWeight = screen.getByTestId("txtCCOWeight");
    expect(txtCCOWeight.tagName).toBe("INPUT");
    expect(txtCCOWeight.type).toBe("text");
    expect(txtCCOWeight.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCOWeight, "1");
    });
  });
  test("Custom Test Cases for txtCCOWeight", () => {
    // START_USER_CODE-USER_txtCCOWeight_TEST
    // END_USER_CODE-USER_txtCCOWeight_TEST
  });
  test("txtCCTicket0(Textbox Widget) Test Cases", async () => {
    const txtCCTicket0 = screen.getByTestId("txtCCTicket0");
    expect(txtCCTicket0.tagName).toBe("INPUT");
    expect(txtCCTicket0.type).toBe("text");
    expect(txtCCTicket0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCTicket0, "123");
    });
    expect(txtCCTicket0.getAttribute("value")).toBe("");
    expect(txtCCTicket0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCTicket0", () => {
    // START_USER_CODE-USER_txtCCTicket0_TEST
    // END_USER_CODE-USER_txtCCTicket0_TEST
  });
  test("txtCCTicket1(Textbox Widget) Test Cases", async () => {
    const txtCCTicket1 = screen.getByTestId("txtCCTicket1");
    expect(txtCCTicket1.tagName).toBe("INPUT");
    expect(txtCCTicket1.type).toBe("text");
    expect(txtCCTicket1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCTicket1, "123");
    });
    expect(txtCCTicket1.getAttribute("value")).toBe("");
    expect(txtCCTicket1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCTicket1", () => {
    // START_USER_CODE-USER_txtCCTicket1_TEST
    // END_USER_CODE-USER_txtCCTicket1_TEST
  });
  test("txtCCTicket2(Textbox Widget) Test Cases", async () => {
    const txtCCTicket2 = screen.getByTestId("txtCCTicket2");
    expect(txtCCTicket2.tagName).toBe("INPUT");
    expect(txtCCTicket2.type).toBe("text");
    expect(txtCCTicket2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCCTicket2, "123");
    });
    expect(txtCCTicket2.getAttribute("value")).toBe("");
    expect(txtCCTicket2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCTicket2", () => {
    // START_USER_CODE-USER_txtCCTicket2_TEST
    // END_USER_CODE-USER_txtCCTicket2_TEST
  });
  test("txtCDryWt0(Textbox Widget) Test Cases", async () => {
    const txtCDryWt0 = screen.getByTestId("txtCDryWt0");
    expect(txtCDryWt0.tagName).toBe("INPUT");
    expect(txtCDryWt0.type).toBe("text");
    expect(txtCDryWt0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCDryWt0, "123");
    });
    expect(txtCDryWt0.getAttribute("value")).toBe("");
    expect(txtCDryWt0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCDryWt0", () => {
    // START_USER_CODE-USER_txtCDryWt0_TEST
    // END_USER_CODE-USER_txtCDryWt0_TEST
  });
  test("txtCDryWt1(Textbox Widget) Test Cases", async () => {
    const txtCDryWt1 = screen.getByTestId("txtCDryWt1");
    expect(txtCDryWt1.tagName).toBe("INPUT");
    expect(txtCDryWt1.type).toBe("text");
    expect(txtCDryWt1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCDryWt1, "123");
    });
    expect(txtCDryWt1.getAttribute("value")).toBe("");
    expect(txtCDryWt1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCDryWt1", () => {
    // START_USER_CODE-USER_txtCDryWt1_TEST
    // END_USER_CODE-USER_txtCDryWt1_TEST
  });
  test("txtCDryWt2(Textbox Widget) Test Cases", async () => {
    const txtCDryWt2 = screen.getByTestId("txtCDryWt2");
    expect(txtCDryWt2.tagName).toBe("INPUT");
    expect(txtCDryWt2.type).toBe("text");
    expect(txtCDryWt2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCDryWt2, "123");
    });
    expect(txtCDryWt2.getAttribute("value")).toBe("");
    expect(txtCDryWt2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCDryWt2", () => {
    // START_USER_CODE-USER_txtCDryWt2_TEST
    // END_USER_CODE-USER_txtCDryWt2_TEST
  });
  test("txtCGreenWt0(Textbox Widget) Test Cases", async () => {
    const txtCGreenWt0 = screen.getByTestId("txtCGreenWt0");
    expect(txtCGreenWt0.tagName).toBe("INPUT");
    expect(txtCGreenWt0.type).toBe("text");
    expect(txtCGreenWt0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCGreenWt0, "123");
    });
    expect(txtCGreenWt0.getAttribute("value")).toBe("");
    expect(txtCGreenWt0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCGreenWt0", () => {
    // START_USER_CODE-USER_txtCGreenWt0_TEST
    // END_USER_CODE-USER_txtCGreenWt0_TEST
  });
  test("txtCGreenWt1(Textbox Widget) Test Cases", async () => {
    const txtCGreenWt1 = screen.getByTestId("txtCGreenWt1");
    expect(txtCGreenWt1.tagName).toBe("INPUT");
    expect(txtCGreenWt1.type).toBe("text");
    expect(txtCGreenWt1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCGreenWt1, "123");
    });
    expect(txtCGreenWt1.getAttribute("value")).toBe("");
    expect(txtCGreenWt1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCGreenWt1", () => {
    // START_USER_CODE-USER_txtCGreenWt1_TEST
    // END_USER_CODE-USER_txtCGreenWt1_TEST
  });
  test("txtCGreenWt2(Textbox Widget) Test Cases", async () => {
    const txtCGreenWt2 = screen.getByTestId("txtCGreenWt2");
    expect(txtCGreenWt2.tagName).toBe("INPUT");
    expect(txtCGreenWt2.type).toBe("text");
    expect(txtCGreenWt2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCGreenWt2, "123");
    });
    expect(txtCGreenWt2.getAttribute("value")).toBe("");
    expect(txtCGreenWt2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCGreenWt2", () => {
    // START_USER_CODE-USER_txtCGreenWt2_TEST
    // END_USER_CODE-USER_txtCGreenWt2_TEST
  });
  test("txtCleanCureTicketNum(Textbox Widget) Test Cases", async () => {
    const txtCleanCureTicketNum = screen.getByTestId("txtCleanCureTicketNum");
    expect(txtCleanCureTicketNum.tagName).toBe("INPUT");
    expect(txtCleanCureTicketNum.type).toBe("text");
    expect(txtCleanCureTicketNum.classList).toContain("textboxWidgetClass");
    // expect(txtCleanCureTicketNum.previousElementSibling.textContent).toEqual(
    //   t("Settlements:CleaningCuringCosts_txtCleanCureTicketNum")
    // );
    await act(async () => {
      userEvent.type(txtCleanCureTicketNum, "1");
    });
  });
  test("Custom Test Cases for txtCleanCureTicketNum", () => {
    // START_USER_CODE-USER_txtCleanCureTicketNum_TEST
    // END_USER_CODE-USER_txtCleanCureTicketNum_TEST
  });
  test("txtCNetWeight(Textbox Widget) Test Cases", async () => {
    const txtCNetWeight = screen.getByTestId("txtCNetWeight");
    expect(txtCNetWeight.tagName).toBe("INPUT");
    expect(txtCNetWeight.type).toBe("text");
    expect(txtCNetWeight.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCNetWeight, "1");
    });
  });
  test("Custom Test Cases for txtCNetWeight", () => {
    // START_USER_CODE-USER_txtCNetWeight_TEST
    // END_USER_CODE-USER_txtCNetWeight_TEST
  });
  test("txtCNWCost(Textbox Widget) Test Cases", async () => {
    const txtCNWCost = screen.getByTestId("txtCNWCost");
    expect(txtCNWCost.tagName).toBe("INPUT");
    expect(txtCNWCost.type).toBe("text");
    expect(txtCNWCost.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCNWCost, "1");
    });
  });
  test("Custom Test Cases for txtCNWCost", () => {
    // START_USER_CODE-USER_txtCNWCost_TEST
    // END_USER_CODE-USER_txtCNWCost_TEST
  });
  test("txtCOCost(Textbox Widget) Test Cases", async () => {
    const txtCOCost = screen.getByTestId("txtCOCost");
    expect(txtCOCost.tagName).toBe("INPUT");
    expect(txtCOCost.type).toBe("text");
    expect(txtCOCost.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCOCost, "1");
    });
  });
  test("Custom Test Cases for txtCOCost", () => {
    // START_USER_CODE-USER_txtCOCost_TEST
    // END_USER_CODE-USER_txtCOCost_TEST
  });
  test("txtCostToClean(Textbox Widget) Test Cases", async () => {
    const txtCostToClean = screen.getByTestId("txtCostToClean");
    expect(txtCostToClean.tagName).toBe("INPUT");
    expect(txtCostToClean.type).toBe("text");
    expect(txtCostToClean.classList).toContain("textboxWidgetClass");
    // expect(txtCostToClean.previousElementSibling.textContent).toEqual(
    //   t("Settlements:CleaningCuringCosts_txtCostToClean")
    // );
    await act(async () => {
      userEvent.type(txtCostToClean, "123");
    });
    expect(txtCostToClean.getAttribute("value")).toBe("");
    expect(txtCostToClean.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCostToClean", () => {
    // START_USER_CODE-USER_txtCostToClean_TEST
    // END_USER_CODE-USER_txtCostToClean_TEST
  });
  test("txtCostToCure(Textbox Widget) Test Cases", async () => {
    const txtCostToCure = screen.getByTestId("txtCostToCure");
    expect(txtCostToCure.tagName).toBe("INPUT");
    expect(txtCostToCure.type).toBe("text");
    expect(txtCostToCure.classList).toContain("textboxWidgetClass");
    // expect(txtCostToCure.previousElementSibling.textContent).toEqual(
    //   t("Settlements:CleaningCuringCosts_txtCostToCure")
    // );
    await act(async () => {
      userEvent.type(txtCostToCure, "123");
    });
    expect(txtCostToCure.getAttribute("value")).toBe("");
    expect(txtCostToCure.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCostToCure", () => {
    // START_USER_CODE-USER_txtCostToCure_TEST
    // END_USER_CODE-USER_txtCostToCure_TEST
  });
  test("txtCOWeight(Textbox Widget) Test Cases", async () => {
    const txtCOWeight = screen.getByTestId("txtCOWeight");
    expect(txtCOWeight.tagName).toBe("INPUT");
    expect(txtCOWeight.type).toBe("text");
    expect(txtCOWeight.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCOWeight, "1");
    });
  });
  test("Custom Test Cases for txtCOWeight", () => {
    // START_USER_CODE-USER_txtCOWeight_TEST
    // END_USER_CODE-USER_txtCOWeight_TEST
  });
  test("txtCTicket0(Textbox Widget) Test Cases", async () => {
    const txtCTicket0 = screen.getByTestId("txtCTicket0");
    expect(txtCTicket0.tagName).toBe("INPUT");
    expect(txtCTicket0.type).toBe("text");
    expect(txtCTicket0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCTicket0, "123");
    });
    expect(txtCTicket0.getAttribute("value")).toBe("");
    expect(txtCTicket0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCTicket0", () => {
    // START_USER_CODE-USER_txtCTicket0_TEST
    // END_USER_CODE-USER_txtCTicket0_TEST
  });
  test("txtCTicket1(Textbox Widget) Test Cases", async () => {
    const txtCTicket1 = screen.getByTestId("txtCTicket1");
    expect(txtCTicket1.tagName).toBe("INPUT");
    expect(txtCTicket1.type).toBe("text");
    expect(txtCTicket1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCTicket1, "123");
    });
    expect(txtCTicket1.getAttribute("value")).toBe("");
    expect(txtCTicket1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCTicket1", () => {
    // START_USER_CODE-USER_txtCTicket1_TEST
    // END_USER_CODE-USER_txtCTicket1_TEST
  });
  test("txtCTicket2(Textbox Widget) Test Cases", async () => {
    const txtCTicket2 = screen.getByTestId("txtCTicket2");
    expect(txtCTicket2.tagName).toBe("INPUT");
    expect(txtCTicket2.type).toBe("text");
    expect(txtCTicket2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCTicket2, "123");
    });
    expect(txtCTicket2.getAttribute("value")).toBe("");
    expect(txtCTicket2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCTicket2", () => {
    // START_USER_CODE-USER_txtCTicket2_TEST
    // END_USER_CODE-USER_txtCTicket2_TEST
  });
});
