/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  getValue,
  enable,
  disable,
  setData,
  getData,
  hide,
  show,
  isEnabled,
  goTo
} from "../../shared/WindowImports";

import "./FarmProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { returnNumericValues } from './../../Common/Constants';
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import CommonContext from "../../Store/CommonContext";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FarmProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const commonContext1 = useContext(CommonContext)
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmProfile",
    windowName: "FarmProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FarmProfile",
    // START_USER_CODE-USER_FarmProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Farm Profile",
      scrCode: "PN0030B",
    },
    // END_USER_CODE-USER_FarmProfile_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnDown: {
      name: "btnDown",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "DownArrow",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDown_PROPERTIES
      iconName: 'fa-arrow-down'
      // END_USER_CODE-USER_btnDown_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnSaveOrder: {
      name: "btnSaveOrder",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "Save Order",
      CharWidth: "23",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveOrder_PROPERTIES

      // END_USER_CODE-USER_btnSaveOrder_PROPERTIES
    },
    btnUp: {
      name: "btnUp",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "UpArrow",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUp_PROPERTIES
      iconName: 'fa-arrow-up'
      // END_USER_CODE-USER_btnUp_PROPERTIES
    },
    ddBuyngPt: {
      name: "ddBuyngPt",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails1",
      Label: "Buying Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyngPt_PROPERTIES

      // END_USER_CODE-USER_ddBuyngPt_PROPERTIES
    },
    ddCountry: {
      name: "ddCountry",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails1",
      Label: "County:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCountry_PROPERTIES

      // END_USER_CODE-USER_ddCountry_PROPERTIES
    },
    ddIrrigate: {
      name: "ddIrrigate",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails2",
      Label: "Irrigate/Dryland:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddIrrigate_PROPERTIES

      // END_USER_CODE-USER_ddIrrigate_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails1",
      Label: "State:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    grpbxFarmDetails1: {
      name: "grpbxFarmDetails1",
      type: "GroupBoxWidget",
      parent: "grpbxFarmProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFarmDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmDetails1_PROPERTIES
    },
    grpbxFarmDetails2: {
      name: "grpbxFarmDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxFarmProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxFarmDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmDetails2_PROPERTIES
    },
    grpbxVendors: {
      name: "grpbxVendors",
      type: "GroupBoxWidget",
      parent: "grpbxFarmProfile",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendors_PROPERTIES

      // END_USER_CODE-USER_grpbxVendors_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblVendors: {
      name: "lblVendors",
      type: "LabelWidget",
      parent: "grpbxVendors",
      Label: "Vendors:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendors_PROPERTIES

      // END_USER_CODE-USER_lblVendors_PROPERTIES
    },
    lstbxVendors: {
      name: "lstbxVendors",
      type: "ListBoxFieldWidget",
      parent: "grpbxVendors",
      ColSpan: "7",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstbxVendors_PROPERTIES

      // END_USER_CODE-USER_lstbxVendors_PROPERTIES
    },
    txtAcres: {
      name: "txtAcres",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails2",
      Label: "Acres:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcres_PROPERTIES

      // END_USER_CODE-USER_txtAcres_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES
      Enabled: false,
      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtAddress: {
      name: "txtAddress",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails2",
      Label: "Address:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddress_PROPERTIES

      // END_USER_CODE-USER_txtAddress_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES
      Enabled: false,
      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtCity: {
      name: "txtCity",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails2",
      Label: "City:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity_PROPERTIES

      // END_USER_CODE-USER_txtCity_PROPERTIES
    },
    txtFrmId: {
      name: "txtFrmId",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails1",
      Label: "Farm ID#:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrmId_PROPERTIES

      // END_USER_CODE-USER_txtFrmId_PROPERTIES
    },
    txtFrmNm: {
      name: "txtFrmNm",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails1",
      Label: "Farm Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrmNm_PROPERTIES

      // END_USER_CODE-USER_txtFrmNm_PROPERTIES
    },
    txtFrmSffx: {
      name: "txtFrmSffx",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails1",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrmSffx_PROPERTIES
      formattingReqd: true,
      // END_USER_CODE-USER_txtFrmSffx_PROPERTIES
    },
    txtPhn: {
      name: "txtPhn",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails2",
      Label: "Phone:",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhn_PROPERTIES

      // END_USER_CODE-USER_txtPhn_PROPERTIES
    },
    txtSortNm: {
      name: "txtSortNm",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails2",
      Label: "Optional Sort Name:",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSortNm_PROPERTIES

      // END_USER_CODE-USER_txtSortNm_PROPERTIES
    },
    txtZip: {
      name: "txtZip",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails2",
      Label: "Zip Code:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZip_PROPERTIES

      // END_USER_CODE-USER_txtZip_PROPERTIES
    },
    grpbxFarmProfile: {
      name: "grpbxFarmProfile",
      type: "GroupBoxWidget",
      parent: "FarmProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFarmProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FarmProfile",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  //Data Coming from Inquiry screen using thr Common Context.
  let dataEditButtonFromFarmInquiry = commonContext1.getFarmInquiry_InquiryEdit;
  let DatafromFarmSetUpAdd = getData(thisObj,"farmprofileAddData")
  const [CVendorFlag, setCVendorFlag] = useState(false);
  let msCurFarm
  let dataEnableDropDownsVendorProfile

  //data coming on edit btn from FarmInquiry
  useEffect(() => {  
    if (dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) {
      document.getElementById("btnOk").innerText = "Update";
      setData(thisObj,"AddbtnCross","")
      disable(thisObj, "txtFrmId")
        disable(thisObj, "txtFrmSffx")
      setValue(thisObj, "txtFrmId", dataEditButtonFromFarmInquiry[0].farm_id)
      setValue(thisObj, "txtFrmSffx", dataEditButtonFromFarmInquiry[0].farm_suffix)
      setValue(thisObj, "txtFrmNm", dataEditButtonFromFarmInquiry[0].farm_name)
      setValue(thisObj, "txtSortNm", dataEditButtonFromFarmInquiry[0].farm_sort_name)
      setValue(thisObj, "txtAddress", dataEditButtonFromFarmInquiry[0].farm_address)
      setValue(thisObj, "txtCity", dataEditButtonFromFarmInquiry[0].farm_city)
      setValue(thisObj, "txtZip", dataEditButtonFromFarmInquiry[0].farm_zip)
      setValue(thisObj, "txtPhn", dataEditButtonFromFarmInquiry[0].farm_phone)
      setValue(thisObj, "txtAcres", dataEditButtonFromFarmInquiry[0].farm_acres)
      setValue(thisObj, "txtAddedBy", dataEditButtonFromFarmInquiry[0].add_user)
      setValue(thisObj, "txtChangedBy", dataEditButtonFromFarmInquiry[0].chg_user)
      setValue(thisObj, "ddIrrigate", dataEditButtonFromFarmInquiry[0].IrrDry)
      EnableDisableControls()
      // bFillVendorList()//for this pls see county dropdown
    }
  }, [commonContext1.getFarmInquiry_InquiryEdit]);

  // Data Coming from Add btn from FarmInquiry
  useEffect(() => {   
    let dataAddbtnFarmInquiry = getData(thisObj, "CreateValue")
    if (dataAddbtnFarmInquiry != undefined && dataAddbtnFarmInquiry != "" && dataAddbtnFarmInquiry != null) {
      EnableDisableControls()
      setData(thisObj,"AddbtnCross","freshdata")
    }
    setData(thisObj, "CreateValue", '')
  }, [getData(thisObj, "CreateValue")]);

  useEffect(() => {   
    if (DatafromFarmSetUpAdd != undefined && DatafromFarmSetUpAdd != "" && DatafromFarmSetUpAdd != null) {
      EnableDisableControls()
      setData(thisObj,"AddbtnCross","freshdata")
    }
    setData(thisObj, "farmprofileAddData", '')
  }, [getData(thisObj, "farmprofileAddData")]);

// Regarding Cross Btn To close the Popup for the Farm Profile Screen as of now Park it after Phase 2 live we will impliment the same.
  let listValue = thisObj.state.lstbxVendors.valueList
  useEffect(() => {
    setData(thisObj, "lstNullOrNot", thisObj.state.lstbxVendors.valueList)
  }, [listValue])

  // Data Coming from the Vendor Profile screen.
  useEffect(() => {
    dataEnableDropDownsVendorProfile = getData(thisObj, "dataEnableDropDownsVendorProfile")
    if (dataEnableDropDownsVendorProfile != undefined && dataEnableDropDownsVendorProfile != "" && dataEnableDropDownsVendorProfile != null) {
      document.getElementById("btnOk").innerText = "Update";
      EnableDisableControls()
      bFillVendorList()
    }
    setData(thisObj, "dataEnableDropDownsVendorProfile", '')
  }, [getData(thisObj, "dataEnableDropDownsVendorProfile")]);

  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    bFillBuyingPointList()
    bFillStateList()
    bLandType()
  }

  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  const ontxtFrmIdChange = () => {
    setValue(thisObj, "txtFrmId", returnNumericValues(txtFrmId.value))
  };
  thisObj.ontxtFrmIdChange = ontxtFrmIdChange;

  // Formating for the FarmBlur and Farm suffix Blur TextBox
  const ontxtFrmIdBlur = () => {
    let FarmNumber = getValue(thisObj, 'txtFrmId')
    if (FarmNumber != null && FarmNumber != undefined) {
      let FarmNumLength = FarmNumber.length
      if (FarmNumLength < 7 && FarmNumLength > 0) {
        let NewFrmNum = '0'.repeat((7 - FarmNumLength)) + FarmNumber
        setValue(thisObj, 'txtFrmId', NewFrmNum)
      }
    }
  }
  thisObj.ontxtFrmIdBlur = ontxtFrmIdBlur

  const ontxtFrmSffxBlur = () => {
    let FarmNumber = getValue(thisObj, 'txtFrmSffx')
    if (FarmNumber != null && FarmNumber != undefined) {
      let FarmNumLength = FarmNumber.length
      if (FarmNumLength < 3 && FarmNumLength > 0) {
        let NewFrmNum = '0'.repeat((3 - FarmNumLength)) + FarmNumber
        setValue(thisObj, 'txtFrmSffx', NewFrmNum)
      }
    }
  }
  thisObj.ontxtFrmSffxBlur = ontxtFrmSffxBlur

  //Buying Point Dropdown Binding 
  const bFillBuyingPointList = () => {
    try {
      setLoading(true);
      let js = []; let initialValue;
      js.push({ key: '', description: '>>> All Buying Points <<<' });
      ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        // PRB0054125
        // if (js.length == 2) {
        //   delete js[0];
        //   initialValue = js[1].key;
        // }
        // else {
        //   initialValue = js[0].key;
        // }
        initialValue = js[0].key;
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyngPt: {
              ...state["ddBuyngPt"],
              valueList: js
            }
          }
        })
        if (dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) {
          disable(thisObj, "ddBuyngPt")
          let BuyingPointDrop = (dataEditButtonFromFarmInquiry[0].buyingpoint)?.toUpperCase()
          setValue(thisObj, 'ddBuyngPt', BuyingPointDrop);
          setData(thisObj, "buyingPoint", BuyingPointDrop)
        }
        else if (DatafromFarmSetUpAdd != undefined && DatafromFarmSetUpAdd != "" && DatafromFarmSetUpAdd != null) {
          disable(thisObj, "ddBuyngPt")
          let BuyingPointDrop = (DatafromFarmSetUpAdd[0].buyingpoint)?.toUpperCase()
          setValue(thisObj, 'ddBuyngPt', BuyingPointDrop);
          setData(thisObj, "buyingPoint", BuyingPointDrop)
        }

        else {
          setValue(thisObj, 'ddBuyngPt', initialValue);
        }
      })
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillBuyingPointList event"
        );
      }
    }
  }

  const onBuyingPointChange = async () => {
    try {
      let PeanutStatelist = thisObj.state['ddState'].valueList;
      let SelectedPeanutStateValue = thisObj.values['ddState'];
      let stateAbbr = null
      if (SelectedPeanutStateValue !== "" && SelectedPeanutStateValue !== undefined) {
        stateAbbr = PeanutStatelist.find(elem => elem.key === SelectedPeanutStateValue).key;
      }

      let BuyingPointList = thisObj.state['ddBuyngPt'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddBuyngPt'];
      let buyingPointId = undefined
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      await bFillCountyList()
      await bFillStateList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddBuyngPtChange = onBuyingPointChange;

  //State Dropdown Binding.
  const bFillStateList = async () => {
    try {
      setLoading(true);
      let jsCounties = [], jsState = [];
      let FilterStatus = true
      let buyingPoint = getValue(thisObj, "ddBuyngPt")
      if (buyingPoint === '') {
        buyingPoint = null
      }
      jsCounties.push({ key: '', description: '>>> All Counties <<<' })
      jsState.push({ key: '', description: '>>> All States <<<' })

      let resp = await ContractManagementService.RetrievePeanutStateControlDetails(buyingPoint, null, FilterStatus)
      let data = resp
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].stateAbbr, description: data[i].stateName }
        jsState.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: jsState
          }
        }
      })
      if (dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) {
        disable(thisObj, "ddState")
        setValue(thisObj, 'ddState', dataEditButtonFromFarmInquiry[0].stateAbbrv);
        setData(thisObj, "stateDropDown", dataEditButtonFromFarmInquiry[0].stateAbbrv)
        await bFillCountyList()
      }

      else if (DatafromFarmSetUpAdd != undefined && DatafromFarmSetUpAdd != "" && DatafromFarmSetUpAdd != null) {
        disable(thisObj, "ddState")
        setValue(thisObj, 'ddState', DatafromFarmSetUpAdd[0].stateAbbrv);
        setData(thisObj, "stateDropDown", DatafromFarmSetUpAdd[0].stateAbbrv)
        await bFillCountyList()
      }
      
      else if (dataEnableDropDownsVendorProfile != undefined && dataEnableDropDownsVendorProfile != "" && dataEnableDropDownsVendorProfile != null) {
        await bFillCountyList()
      }
      else {
        setValue(thisObj, 'ddState', jsState[0].key);
        thisObj.setState(current => {
          return {
            ...current,
            ddCountry: {
              ...state["ddCountry"],
              valueList: jsCounties,
            }
          }
        })
        setValue(thisObj, 'ddCountry', jsCounties[0].key);
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillStateList event"
        );
      }
    }
  }

  const onddStateChange = async () => {
    try {
      await bFillCountyList()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddStateChange event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddStateChange = onddStateChange;

  //County DropDown Binding
  const bFillCountyList = async () => {
    try {
      // setLoading(true);
      let js = []
      let response
      let data 
      let stateValue
      let buyingpt
      if ((dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) || (DatafromFarmSetUpAdd != undefined && DatafromFarmSetUpAdd != "" && DatafromFarmSetUpAdd != null)) {
        buyingpt = getData(thisObj, "buyingPoint")
        stateValue = getData(thisObj, "stateDropDown")
      }
      else {
        buyingpt = getValue(thisObj, "ddBuyngPt");
        stateValue = getValue(thisObj, "ddState");
      }
      if (buyingpt === '') {
        buyingpt = null
      }
      let FilterStatus = "YES"
      js.push({ key: '', description: '>>> All Counties <<<' })
      if((buyingpt != undefined && buyingpt != null && buyingpt !="") && (FilterStatus == "YES")){
         response = await ContractManagementService.RetrieveCountyControlDetails(buyingpt, FilterStatus, stateValue, null)
         data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].countyId.trim(), description: data[i].countyName.trim() }
          js.push(obj)
        }
      }
      else{
        response = await ContractManagementService.RetrieveCountyControlDetails(null, null, stateValue, null)
        data = response
       for (var i = 0; i < data.length; i++) {
         let obj = { key: data[i].countyId.trim(), description: data[i].countyName.trim() }
         js.push(obj)
       }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCountry: {
            ...state["ddCountry"],
            valueList: js
          }
        }
      })
      if (dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) {
        disable(thisObj, "ddCountry")
        let county = dataEditButtonFromFarmInquiry[0].county
        setValue(thisObj, 'ddCountry', county);
        setData(thisObj, "countyDropdown", county)
        await bFillVendorList()
      }

      if (DatafromFarmSetUpAdd != undefined && DatafromFarmSetUpAdd != "" && DatafromFarmSetUpAdd != null) {
        disable(thisObj, "ddCountry")
        let county = DatafromFarmSetUpAdd[0].county
        let findDataCounty = js.find(x => x.key == DatafromFarmSetUpAdd[0].county)
        setValue(thisObj, 'ddCountry',findDataCounty?.key || "");
        setData(thisObj, "countyDropdown", county)
      }

      if (dataEnableDropDownsVendorProfile != undefined && dataEnableDropDownsVendorProfile != "" && dataEnableDropDownsVendorProfile != null) {
        await bFillVendorList()
      }
      // setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillCountyList event"
        );
      }
    }
  }

  // Land dropdown Binding.
  const bLandType = () => {
    let js = [];
    js.push({ key: 'I', description: 'Irrigated' })
    js.push({ key: 'D', description: 'Dryland' })
    js.push({ key: 'U', description: 'Unknown' })
    thisObj.setState(current => {
      return {
        ...current,
        ddIrrigate: {
          ...state["ddIrrigate"],
          valueList: js
        }
      }
    })
    setValue(thisObj, "ddIrrigate", js[2].key)
  }

  //Vendor ListBox Binding Function.
  const bFillVendorList = async () => {
    try {
      setLoading(true)
      let LstrList = ''
      let LstrList2 = ''
      let Farm_suffix = ''
      let FarmId = txtFrmId.value
      let js = [], jsshare = []
      let lstBuyingPointID
      let lstStateID
      let lstCounty
      if ((dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) || (DatafromFarmSetUpAdd != undefined && DatafromFarmSetUpAdd != "" && DatafromFarmSetUpAdd != null)) {
        if (isEnabled(thisObj, "txtSortNm") == true) {
          document.getElementById("txtSortNm").focus();
        }
        lstBuyingPointID = getData(thisObj, "buyingPoint")
        lstStateID = getData(thisObj, "stateDropDown")
        lstCounty = getData(thisObj, "countyDropdown")
      }
      else {
        lstBuyingPointID = getValue(thisObj, "ddBuyngPt")
        lstStateID = getValue(thisObj, "ddState")
        lstCounty = getValue(thisObj, "ddCountry")
      }

      if ((getValue(thisObj, "txtFrmSffx") != '' && getValue(thisObj, "txtFrmSffx") != undefined) || (txtFrmSffx.value != '' && txtFrmSffx.value != undefined)) {
        Farm_suffix = txtFrmSffx.value
      }
      else {
        Farm_suffix = null
      }
      LstrList = await ContractManagementService.RetrieveFarmVendorSplitDetails(lstBuyingPointID, lstStateID, lstCounty, FarmId, Farm_suffix, null)


      if (LstrList.length > 0) {
      for (var i = 0; i < LstrList.length; i++) {
          LstrList2 = await ContractManagementService.RetrieveVendorByNumber(LstrList[i].split_vendor)
          let obj = { key: LstrList[i].split_vendor, description: LstrList[i].split_vendor + '-' + LstrList2[0].name + ' (' + Number(LstrList[i].split_share_pct) + ' )' ,sharePercent : Number(LstrList[i].split_share_pct)}
          js.push(obj);
          jsshare.push(LstrList[i].split_share_pct)
      }
      }

      if (js.length != 0) {
        enable(thisObj, "btnEdit")
        enable(thisObj, "btnDelete")
      }
      else {
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
        setData(thisObj, "emptyListBox", true)
      }
      setData(thisObj, "lstVendors", js)
      setData(thisObj, "splitVendorPercent", jsshare)

      if (LstrList[0] != undefined) {
        setValue(thisObj, "lstbxVendors", [LstrList[0].split_vendor])//for bydefault first list selectable
      }

      thisObj.setState(current => {
        return {
          ...current,
          lstbxVendors: {
            ...state["lstbxVendors"],
            valueList: js
          }
        }
      });
      if (isEnabled(thisObj, "txtFrmNm") == true) {
        document.getElementById("txtFrmNm").focus();
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillVendorList event"
        );
      }
    }
  }

  //Enable Disable Control
  const EnableDisableControls = async () => {
    try {
      // setLoading(true)
      enable(thisObj, "btnAdd")
      enable(thisObj, "btnEdit")
      enable(thisObj, "btnDelete")
      enable(thisObj, "btnUp")
      enable(thisObj, "btnDown")
      enable(thisObj, "btnSaveOrder")
      if (document.getElementById("btnOk").innerText == 'Add') {
        disable(thisObj, "btnAdd")
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
        disable(thisObj, "btnUp")
        disable(thisObj, "btnDown")
        disable(thisObj, "btnSaveOrder")
      }
      // setLoading(false)
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during EnableDisable event"
        );
      }
    }
  }

  //share PCT Canculating functionality in ListBox.
  const bCheckPercent = async () => {
    let bCheckPercent = true
    let dSum
    let n
    let lstVendors = getData(thisObj, "lstVendors")//getValue(thisObj,"lstbxVendors")
    let sharePct = getData(thisObj, "splitVendorPercent")
    if (lstVendors != '' && lstVendors != null && lstVendors != undefined) {
      dSum = 0
      for (var i = 0; i < lstVendors.length; i++) {
        dSum = dSum + Number(sharePct[i])
      }
      if (Number(dSum) != 100) {
        if (Number(dSum) > 100) {
          alert("Split percentages total " + dSum + " is more than 100.")
        }
        else {
          alert("Split percentages total " + dSum + " is less than 100.")
        }
        bCheckPercent = false
      }
      else {
        bCheckPercent = true
      }
    }
    return bCheckPercent;
  }

  //Validation Functionality 
  const bFormValid = async () => {
    try {
      setLoading(true);
      let bFormValid = false
      let buyingpointdrop = getValue(thisObj, "ddBuyngPt")
      let statedrop = getValue(thisObj, "ddState")
      let countydrop = getValue(thisObj, "ddCountry")
      let FarmId = getValue(thisObj, "txtFrmId")
      let farmsuffix = getValue(thisObj, "txtFrmSffx")
      let sortNumtext = getValue(thisObj, "txtSortNm")
      let acrestext = getValue(thisObj, "txtAcres")

      if ((buyingpointdrop == "" || buyingpointdrop == undefined)) {
        alert("Buying Point is a required field.")
        return
      }
      if ((statedrop == "" || statedrop == undefined)) {
        alert("State is a required field.")
        return
      }
      if ((countydrop == "" || countydrop == undefined)) {
        alert("County is a required field.")
        return
      }
      if (FarmId == '' || FarmId == undefined) {
        alert("ID is a required field.")
        if (isEnabled(thisObj, "txtFrmId") == true) {
          document.getElementById("txtFrmId").focus();
        }
        return
      }
      if (cropYear > 2020) {
        if (farmsuffix == '' || farmsuffix == undefined) {
          alert("Suffix is a required field.")
          if (isEnabled(thisObj, "txtFrmSffx") == true) {
            document.getElementById("txtFrmSffx").focus();
          }
          return
        }
      }

      if (getValue(thisObj, "txtFrmNm") == '' || getValue(thisObj, "txtFrmNm") == undefined) {
        alert("Name is a required field.")
        if (isEnabled(thisObj, "txtFrmNm") == true) {
          document.getElementById("txtFrmNm").focus();
        }
        return
      }

      if (getValue(thisObj, "txtSortNm") != '' && getValue(thisObj, "txtSortNm") != undefined) {
        if (sortNumtext.length < 4) {
          alert("Optional Sort Name must be at least 4 characters if going to be used.")
          if (isEnabled(thisObj, "txtSortNm") == true) {
            document.getElementById("txtSortNm").focus();
          }
          return
        }
      }

      if (getValue(thisObj, "txtAcres") != '' && getValue(thisObj, "txtAcres") != undefined && getValue(thisObj, "txtAcres") != null) {
        if (!isNaN(acrestext)) {
          if (Number(acrestext) < 0 || Number(acrestext) > 999999) {
            alert("Acres must be of format ######.")
            if (isEnabled(thisObj, "txtAcres") == true) {
              document.getElementById("txtAcres").focus();
            }
            return
          }
        }
        else {
          alert("Acres must be of format ######.")
          if (isEnabled(thisObj, "txtAcres") == true) {
            document.getElementById("txtAcres").focus();
          }
          return
        }
      }
      bFormValid = true
      return bFormValid    
      // setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Validation event"
        );
      }
    }
    finally {
      setLoading(false);
    }
  }

  // Vendor Adding Functionlity
  const formQueryUnload = async () =>{
    var ListVendors = getValue(thisObj, "lstbxVendors")
    var listVendr = getData(thisObj, "lstVendors")
    let formQueryUnload = false

    if(document.getElementById("btnOk").innerText == 'Update'){
    if((listVendr.length == 0 || listVendr == null)){
      alert("Please add a vendor first.")
      return
    }
    if (((ListVendors.length == 0) && (document.getElementById("btnOk").innerText == 'Update')) ) {
      alert("Please add a vendor first.")
      return
    }
  }
    formQueryUnload = true
    return formQueryUnload
    
  }

  // Ok Button Click Functionality for OK & Update Functionality.
  const onbtnOkClick = async () => {
    try {
      // setLoading(true);
      let lsQueryString = ''
      let lstrxml = ''
      let Farm_suffix
      let n
      let DataOnAddBtn = getData(thisObj, "CreateValue")

      var ListVendors = getValue(thisObj, "lstbxVendors")

      let lstBuyingPointid, lstStateId, lstCountyId
      let farmIdtext = txtFrmId.value

      if (dataEditButtonFromFarmInquiry != undefined && dataEditButtonFromFarmInquiry != "" && dataEditButtonFromFarmInquiry != null) {
        lstBuyingPointid = getData(thisObj, "buyingPoint")
        lstStateId = getData(thisObj, "stateDropDown")
        lstCountyId = getData(thisObj, "countyDropdown")
      }
      else {
        lstBuyingPointid = getValue(thisObj, "ddBuyngPt")
        lstStateId = getValue(thisObj, "ddState")
        lstCountyId = getValue(thisObj, "ddCountry")
      }

      if ((getValue(thisObj, "txtFrmSffx") != '' && getValue(thisObj, "txtFrmSffx") != undefined) || (txtFrmSffx.value != '' && txtFrmSffx.value != undefined)) {
        Farm_suffix = txtFrmSffx.value
      }
      else {
        Farm_suffix = null
      }

      if (!await bFormValid()) {
        return false
      }
      else {
        //======================================Add Btn============================================================
        if (document.getElementById("btnOk").innerText == 'Add') {
          lsQueryString = await ContractManagementService.RetrieveFarmControlDetails(null, null, lstBuyingPointid, lstStateId, lstCountyId, farmIdtext, Farm_suffix)
          if (lsQueryString.length > 0) {
            if (lsQueryString[0].farm_id != '') {
              alert("Farm ID Already Exists.")
              return false
            }
          }
          let dataObj = {
            "state_abbr": lstStateId,
            "county_id": lstCountyId,
            "farm_id": txtFrmId.value,
            "farm_suffix": Farm_suffix,
            "farm_name": txtFrmNm.value,
            "farm_sort_name": txtSortNm.value,
            "farm_oper_name": '',
            "farm_address": txtAddress.value,
            "farm_city": txtCity.value,
            "farm_state": lstStateId,
            "farm_zip": txtZip.value,
            "farm_phone": txtPhn.value,
            "farm_acres": [undefined, null, ''].includes(txtAcres.value) ? '' : Number(txtAcres.value).toFixed(0),
            "landtype": getValue(thisObj, "ddIrrigate")
          };
          setData(thisObj, 'ddFarmData', dataObj.farm_id + '-' + dataObj.farm_suffix + '-' + dataObj.farm_name)
          lsQueryString = await SystemMaintenanceMasterManagementService.CreateFarmControl(lstBuyingPointid, dataObj)
          if (lsQueryString.status != 200) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
            return false
          }
          else {
            setData(thisObj, "lstVendors","")
            document.getElementById("btnOk").innerText = "Update";
            setData(thisObj,"AddbtnCross","")
            await EnableDisableControls()
            if (ListVendors.length == 0) {
              disable(thisObj, "btnEdit")
              disable(thisObj, "btnDelete")
            }
            return false
          }
        }
        //=============================================Update Btn=============================================================
        if (document.getElementById("btnOk").innerText == 'Update') {
          let vendrList = getData(thisObj, "emptyListBox")
          if (! await bCheckPercent()) {
            return false
          }
          else {
            if (CVendorFlag == true) {
              if(!await formQueryUnload ()){
                return false
              }
              const response = confirm("The Order for the Vendors is changed. Do you want to Save the Order? ");
              if (response) {
                await onbtnSaveOrderClick()
                msCurFarm = txtFrmId.value
              }
              else {
                msCurFarm = txtFrmId.value
              }
            }
          }

          let ddIrrigate

          if (getValue(thisObj, "ddIrrigate") == "IRRIGATED") {
            ddIrrigate = 'I'
          }
          else if (getValue(thisObj, "ddIrrigate") == "DRYLAND") {
            ddIrrigate = 'D'
          }
          else if (getValue(thisObj, "ddIrrigate") == "UNKNOWN") {
            ddIrrigate = 'U'
          }
          else {
            ddIrrigate = getValue(thisObj, "ddIrrigate")
          }
          let dataobj = {
            "state_abbr": lstStateId,
            "county_id": lstCountyId,
            "farm_name": txtFrmNm.value,
            "farm_sort_name": txtSortNm.value,
            "farm_address": txtAddress.value,
            "farm_city": txtCity.value,
            "farm_state": lstStateId,
            "farm_zip": txtZip.value,
            "farm_phone": txtPhn.value,
            "farm_acres": [undefined, null, ''].includes(txtAcres.value) ? '' : Number(txtAcres.value).toFixed(0),
            "landtype": ddIrrigate
          };
          lsQueryString = await SystemMaintenanceMasterManagementService.UpdateFarmControl(lstBuyingPointid, farmIdtext, Farm_suffix, dataobj)
          if (lsQueryString.status == 500) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
            return false
          }
          else {
            if(!await formQueryUnload ()){
              return false
            }
            else {
              setData(thisObj, "emptyListBox", false)
              setData(thisObj, "lstVendors","")
              setData(thisObj,"AddbtnCross","")
              setData(thisObj, "reversecaseForFarmProfile","AddeddatafromFarmProfile")
              document.getElementById("SystemMaintenanceMasterManagement_FarmProfilePopUp").childNodes[0].click()
            }
          }
        }
      }
      // setLoading(false);
    }
    catch (err) {
      // setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnOkClick click event"
        );
      }
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  // Save Order of Vendor listbox Functionality
  const onbtnSaveOrderClick = async () => {
    try {
      setLoading(true);
      let vendornumber = ''
      let LstrList = ''
      let lstrxml = ''
      let Farm_suffix = ''
      let x1
      let buyPt = getValue(thisObj, "ddBuyngPt")
      let stateAbb = getValue(thisObj, "ddState")
      let countyDrp = getValue(thisObj, "ddCountry")
      let Farmid = getValue(thisObj, "txtFrmId")
      if (isEnabled(thisObj, "txtSortNm") == true) {
        document.getElementById("txtSortNm").focus();
      }
      let lstVendor = getData(thisObj, "lstVendors")
      if (lstVendor.length < 1) {
        alert("Please add a vendor first.")
        return 
      }
      if ((getValue(thisObj, "txtFrmSffx") != "" && getValue(thisObj, "txtFrmSffx") != "") || (txtFrmSffx.value != '' && txtFrmSffx.value != undefined)) {
        Farm_suffix = txtFrmSffx.value
      }
      else {
        Farm_suffix = null
      }
      if (!await bCheckPercent()) {
        return
      }
      else {
        x1 = 0
        for (var i = 0; i < lstVendor.length; i++) {
          x1 = x1 + 1
          vendornumber = lstVendor[i].key
          let dataobj = {
            "vendor_order": (x1).toString()
          };
          lstrxml = await SystemMaintenanceMasterManagementService.UpdateFarmVendorSplitOrderDetails(buyPt, stateAbb, countyDrp, Farmid, Farm_suffix, vendornumber, dataobj)
        }
        if (lstrxml.status == 200) {
          alert("Vendor Order Saved ")
          setCVendorFlag(false)
        }
        else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return
        }
      }
      if (isEnabled(thisObj, "txtFrmNm") == true) {
        document.getElementById("txtFrmNm").focus();
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during BtnSaveOrder click event"
        );
      }
    }
    finally{
      setLoading(false);
    }
  }
  thisObj.onbtnSaveOrderClick = onbtnSaveOrderClick;

  // Add Vendor from vendor Profile screen
  const onbtnAddClick = async () => {
    try {
      let Farm_suffix = ''
      var ListVendors = getValue(thisObj, "lstbxVendors")
      if ((ListVendors.length >= 20)) {
        alert("A farm can only have a maximum of 20 vendors!!!")
        return
      }
      if ((getValue(thisObj, "txtFrmSffx") != '' && getValue(thisObj, "txtFrmSffx") != undefined) || (txtFrmSffx.value != '' && txtFrmSffx.value != undefined)) {
        Farm_suffix = txtFrmSffx.value
      }

      let countylist = thisObj.state['ddCountry'].valueList;
      let countyDescription = '';
      let countyId = thisObj.values['ddCountry'];
      if (countyId !== undefined && countyId !== null) {
        countyDescription = countylist.find(elem => elem.key == countyId).description;
      }

      let statelist = thisObj.state['ddState'].valueList;
      let stateDescription = '';
      let stateId = thisObj.values['ddState'];
      if (stateId !== undefined && stateId !== null) {
        stateDescription = statelist.find(elem => elem.key == stateId).description;
      }

      let buyinglist = thisObj.state['ddBuyngPt'].valueList;
      let buyingDescription = '';
      let buyingId = thisObj.values['ddBuyngPt'];
      if (buyingId !== undefined && buyingId !== null) {
        buyingDescription = buyinglist.find(elem => elem.key == buyingId).description;
      }

      var vendorprofileDetailsArray = []
      var vendorprofileDetailsDataObj = {
        cmdOkCaption: "Add",
        txtVendorEnabled: true,
        cmdAddVendorEnabled: true,
        lblFarmCaption: getValue(thisObj, "txtFrmId"),
        lblSuffixCaption: Farm_suffix,
        lblCountyCaption: countyDescription,
        lblCountyTag: getValue(thisObj, "ddCountry"),
        lblStateCaption: stateDescription,
        lblStateTag: getValue(thisObj, "ddState"),
        lblBuyingPointCaption: buyingDescription,
        lblBuyingPointTag: getValue(thisObj, "ddBuyngPt")
      }
      vendorprofileDetailsArray.push(vendorprofileDetailsDataObj)
      setData(thisObj, "vendorprofileDetails", vendorprofileDetailsArray)
      setData(thisObj, 'vendorDetails',"");
      goTo(thisObj, "SystemMaintenanceMasterManagement#VendorProfile#DEFAULT#true#Click");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnAddClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;

 // Edit Vendor from vendor Profile screen
  const onbtnEditClick = async () => {
    try {
      let Farm_suffix = ''
      var ListSelectedVendor = lstbxVendors.value
      let LstrList = ''
      let textFarmId = getValue(thisObj, "txtFrmId")

      if ((getValue(thisObj, "txtFrmSffx") != '' && getValue(thisObj, "txtFrmSffx") != undefined) || (txtFrmSffx.value != '' && txtFrmSffx.value != undefined)) {
        Farm_suffix = txtFrmSffx.value
      }
      else {
        Farm_suffix = null
      }

      let countylist = thisObj.state['ddCountry'].valueList;
      let countyDescription = '';
      let countyId = thisObj.values['ddCountry'];
      if (countyId !== undefined && countyId !== null) {
        countyDescription = countylist.find(elem => elem.key == countyId).description;
      }

      let statelist = thisObj.state['ddState'].valueList;
      let stateDescription = '';
      let stateId = thisObj.values['ddState'];
      if (stateId !== undefined && stateId !== null) {
        stateDescription = statelist.find(elem => elem.key == stateId).description;
      }

      let buyinglist = thisObj.state['ddBuyngPt'].valueList;
      let buyingDescription = '';
      let buyingId = thisObj.values['ddBuyngPt'];
      if (buyingId !== undefined && buyingId !== null) {
        buyingDescription = buyinglist.find(elem => elem.key == buyingId).description;
      }

      LstrList = await ContractManagementService.RetrieveFarmVendorSplitDetails(buyingId, stateId, countyId, textFarmId, Farm_suffix, ListSelectedVendor)
      if (lstbxVendors.value != '') {
        var vendorprofileDetailsArray = []
        var vendorprofileDetailsDataObj = {
          cmdOkCaption: "Update",
          txtVendorEnabled: false,
          cmdAddVendorEnabled: false,
          lblFarmCaption: getValue(thisObj, "txtFrmId"),
          lblSuffixCaption: Farm_suffix,
          lblCountyCaption: countyDescription,
          lblCountyTag: getValue(thisObj, "ddCountry"),
          lblStateCaption: stateDescription,
          lblStateTag: getValue(thisObj, "ddState"),
          lblBuyingPointCaption: buyingDescription,
          lblBuyingPointTag: getValue(thisObj, "ddBuyngPt"),
          txtVendorText: LstrList[0].split_vendor,
          txtSharePercent: LstrList[0].split_share_pct,
          lblAddedByCaption: LstrList[0].add_user + " " + moment(LstrList[0].add_date_time).format("M/D/YYYY"),
          lblChangedByCaption: LstrList[0].chg_user + " " + moment(LstrList[0].chg_date_time).format("M/D/YYYY"),
          lbl211ConcordiaCaption: LstrList[0].concordia_form_211,
          remitTo: LstrList[0].split_remit,
          LstrList: LstrList
        }
        vendorprofileDetailsArray.push(vendorprofileDetailsDataObj)
        setData(thisObj, "vendorprofileDetails", vendorprofileDetailsArray)
        setData(thisObj, 'vendorDetails',"");
        goTo(thisObj, "SystemMaintenanceMasterManagement#VendorProfile#DEFAULT#true#Click");
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnEditClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnEditClick = onbtnEditClick;

 // Delete Vendor from vendor Profile screen
  const onbtnDeleteClick = async () => {
    try {
      // setLoading(true);
      let n
      let lstrxml = ''
      let Farm_suffix = ''
      let ListVendors = getValue(thisObj, "lstbxVendors")
      var listVendr = getData(thisObj, "lstVendors")
      let buyingPt = getValue(thisObj, "ddBuyngPt")
      let stateAbbr = getValue(thisObj, "ddState")
      let countyDrop = getValue(thisObj, "ddCountry")
      let Farmid = getValue(thisObj, "txtFrmId")
      let splitVendor = ''
      let findData = listVendr.find(x => x.key == ListVendors[0])
      if (ListVendors.length > 0) {
          splitVendor = ListVendors[0]
      }

      if ((getValue(thisObj, "txtFrmSffx") != '' && getValue(thisObj, "txtFrmSffx") != undefined) || (txtFrmSffx.value != '' && txtFrmSffx.value != undefined)) {
        Farm_suffix = txtFrmSffx.value
      }
      else {
        Farm_suffix = null
      }
      const response = confirm(`Are you sure you wish to delete this vendor '${findData.description}'` );
      if (response) {
        lstrxml = await SystemMaintenanceMasterManagementService.RemoveFarmVendorSplitDetails(buyingPt, stateAbbr, countyDrop, Farmid, Farm_suffix, splitVendor)
        if (lstrxml.status != 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return
        }
        else {
          await bFillVendorList()
        }
      }
      else {
        return
      }
      if (ListVendors != undefined) {
        n = ListVendors.length
      }

      if (ListVendors.length == 0 && listVendr.length == 0) {
        await EnableDisableControls()
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
        return
      }

      if (n > ListVendors.length) {
        await EnableDisableControls()
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
        return
      }
      else {
        ListVendors.length = n
      }
      // setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete click event"
        );
      }
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  // Ordering Listbox Vendors up and Down with Arrows
  const onbtnUpClick = async () => {
    try {
      let dataOfListBox = getValue(thisObj, "lstbxVendors")
      let dataListJS = getData(thisObj, "lstVendors")
      let obj
      let arr1 = []
      if(dataListJS.length != 0 ){
      if (dataListJS[0].key == dataOfListBox[0])
        return dataListJS
      for (var i = 0; i < dataListJS.length; i++) {
        if (dataListJS[i].key == dataOfListBox[0]) {
          obj = arr1.pop()
          dataOfListBox = i
          arr1.push(dataListJS[i])
          break
        }
        else {
          arr1.push(dataListJS[i])
        }
      }
      arr1.push(obj)
      if (dataListJS[i + 1] == undefined) {

      }
      else {
        arr1.push(dataListJS[i + 1])
      }
      for (var i = dataOfListBox + 2; i < dataListJS.length; i++) {
        arr1.push(dataListJS[i])
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstbxVendors: {
            ...state["lstbxVendors"],
            valueList: arr1
          }
        }
      });
      setData(thisObj, "lstVendors", arr1)
      setCVendorFlag(true)
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnUpClick click event"
        );
      }
    }
  }
  thisObj.onbtnUpClick = onbtnUpClick;

  const onbtnDownClick = async () => {
    try {
      let dataOfListBox = getValue(thisObj, "lstbxVendors")
      let dataListJS = getData(thisObj, "lstVendors")
      let obj
      let arr1 = []
      if(dataListJS.length != 0 ){
      if (dataListJS[dataListJS.length - 1].key == dataOfListBox[0])
        return dataListJS
      for (var i = 0; i < dataListJS.length; i++) {
        if (dataListJS[i].key == dataOfListBox[0]) {
          obj = dataListJS[i]
          dataOfListBox = i++
          arr1.push(dataListJS[i])
          break
        }
        else {
          arr1.push(dataListJS[i])
        }
      }
      arr1.push(obj)
      for (var i = dataOfListBox + 2; i < dataListJS.length; i++) {
        arr1.push(dataListJS[i])
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstbxVendors: {
            ...state["lstbxVendors"],
            valueList: arr1
          }
        }
      });
      setData(thisObj, "lstVendors", arr1)
      setCVendorFlag(true)
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnDownClick click event"
        );
      }
    }
  }
  thisObj.onbtnDownClick = onbtnDownClick;

  //Close Btn Functionality on close btn click.
  const onbtnCloseClick = async () => {
    try {
      var ListVendors = getValue(thisObj, "lstbxVendors")
      var listVendr = getData(thisObj, "lstVendors")
      if (!await bCheckPercent()) {
        return
      }
      if(!await formQueryUnload ()){
        return
      }

      else {
        if (CVendorFlag == true) {
          const responseconfirmed = confirm("The Order for the Vendors is changed. Do you want to Save the Order? ");
          if (responseconfirmed) {
            await onbtnSaveOrderClick()
            msCurFarm = txtFrmId.value
          }
          else {
            msCurFarm = txtFrmId.value
          }
        }
        else {
          msCurFarm = txtFrmId.value
        }
        setData(thisObj, "emptyListBox", false)
        setData(thisObj, "lstVendors","")
        setData(thisObj, "reversecaseForFarmProfile","AddeddatafromFarmProfile")
        document.getElementById("SystemMaintenanceMasterManagement_FarmProfilePopUp").childNodes[0].click()
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} thisObj={props.thisObj} /> 
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmProfile*/}

              {/* END_USER_CODE-USER_BEFORE_FarmProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFarmProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmProfile*/}

              <GroupBoxWidget conf={state.grpbxFarmProfile} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxFarmDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFarmDetails1*/}

                <GroupBoxWidget
                  conf={state.grpbxFarmDetails1}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddBuyngPt*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyngPt*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyngPt}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyngPt*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyngPt*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCountry*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCountry*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCountry}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCountry*/}

                  {/* END_USER_CODE-USER_AFTER_ddCountry*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFrmId*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFrmId*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFrmId}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFrmId*/}

                  {/* END_USER_CODE-USER_AFTER_txtFrmId*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFrmSffx*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFrmSffx*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFrmSffx}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFrmSffx*/}

                  {/* END_USER_CODE-USER_AFTER_txtFrmSffx*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFrmNm*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFrmNm*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFrmNm}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFrmNm*/}

                  {/* END_USER_CODE-USER_AFTER_txtFrmNm*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFarmDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFarmDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFarmDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFarmDetails2*/}

                <GroupBoxWidget
                  conf={state.grpbxFarmDetails2}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtSortNm*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSortNm*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSortNm}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSortNm*/}

                  {/* END_USER_CODE-USER_AFTER_txtSortNm*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddress*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddress}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddress*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCity*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCity*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCity}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCity*/}

                  {/* END_USER_CODE-USER_AFTER_txtCity*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZip*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZip*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZip}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZip*/}

                  {/* END_USER_CODE-USER_AFTER_txtZip*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhn*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhn*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhn}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhn*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhn*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAcres*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAcres*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAcres}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAcres*/}

                  {/* END_USER_CODE-USER_AFTER_txtAcres*/}
                  {/* START_USER_CODE-USER_BEFORE_ddIrrigate*/}

                  {/* END_USER_CODE-USER_BEFORE_ddIrrigate*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddIrrigate}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddIrrigate*/}

                  {/* END_USER_CODE-USER_AFTER_ddIrrigate*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFarmDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFarmDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVendors*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVendors*/}

                <GroupBoxWidget conf={state.grpbxVendors} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblVendors*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendors*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendors}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendors*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendors*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUp*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUp*/}

                  <ButtonWidget
                    conf={state.btnDown}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDown*/}

                  {/* END_USER_CODE-USER_AFTER_btnDown*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveOrder*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveOrder*/}

                  <ButtonWidget
                    conf={state.btnUp}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUp*/}

                  {/* END_USER_CODE-USER_AFTER_btnUp*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDown*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDown*/}

                  <ButtonWidget
                    conf={state.btnSaveOrder}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveOrder*/}

                  {/* END_USER_CODE-USER_AFTER_btnSaveOrder*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  {/* START_USER_CODE-USER_BEFORE_lstbxVendors*/}

                  {/* END_USER_CODE-USER_BEFORE_lstbxVendors*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstbxVendors}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstbxVendors*/}

                  {/* END_USER_CODE-USER_AFTER_lstbxVendors*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVendors*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVendors*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FarmProfile*/}

              {/* END_USER_CODE-USER_AFTER_FarmProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FarmProfile);
