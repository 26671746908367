/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FreightRateSetup from "./FreightRateSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FreightRateSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FreightRateSetup />);
    });
  });
  afterEach(cleanup);
  test("is FreightRateSetup Loads Successfully", () => {
    expect(screen.getByText("FreightRateSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for FreightRateSetup", () => {
    // START_USER_CODE-USER_FreightRateSetup_Custom_Test_Case
    // END_USER_CODE-USER_FreightRateSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FreightRateSetup />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridFreightRateSetupData(Grid Widget) Test Cases", async () => {
    let gridFreightRateSetupData = screen.getByTestId(
      "gridFreightRateSetupData"
    );
    let gridFreightRateSetupDatabtn =
      gridFreightRateSetupData.nextElementSibling.firstElementChild;
    gridFreightRateSetupData =
      gridFreightRateSetupData.parentElement.parentElement.parentElement;
    expect(gridFreightRateSetupData.tagName).toBe("DIV");
    expect(gridFreightRateSetupData.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightRateSetup_gridFreightRateSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridFreightRateSetupData", () => {
    // START_USER_CODE-USER_gridFreightRateSetupData_TEST
    // END_USER_CODE-USER_gridFreightRateSetupData_TEST
  });
  test("grpbxFreightRateSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightRateSetup = screen.getByTestId("grpbxFreightRateSetup");
    expect(grpbxFreightRateSetup.tagName).toBe("BUTTON");
    expect(grpbxFreightRateSetup.type).toBe("button");
    expect(grpbxFreightRateSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightRateSetup", () => {
    // START_USER_CODE-USER_grpbxFreightRateSetup_TEST
    // END_USER_CODE-USER_grpbxFreightRateSetup_TEST
  });
  test("txtCarrierAbbriviation(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbriviation = screen.getByTestId("txtCarrierAbbriviation");
    expect(txtCarrierAbbriviation.tagName).toBe("INPUT");
    expect(txtCarrierAbbriviation.type).toBe("text");
    expect(txtCarrierAbbriviation.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbriviation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FreightRateSetup_txtCarrierAbbriviation"
      )
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbriviation, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbriviation", () => {
    // START_USER_CODE-USER_txtCarrierAbbriviation_TEST
    // END_USER_CODE-USER_txtCarrierAbbriviation_TEST
  });
  test("gridFreightRateSetupData_txtCarrierAbbriviationC(Grid Widget) Test Cases", async () => {
    let gridFreightRateSetupData_txtCarrierAbbriviationC = screen.getByTestId(
      "gridFreightRateSetupData"
    );
    let gridFreightRateSetupData_txtCarrierAbbriviationCbtn =
      gridFreightRateSetupData_txtCarrierAbbriviationC.nextElementSibling
        .firstElementChild;
    gridFreightRateSetupData_txtCarrierAbbriviationC =
      gridFreightRateSetupData_txtCarrierAbbriviationC.parentElement
        .parentElement.parentElement;
    expect(gridFreightRateSetupData_txtCarrierAbbriviationC.tagName).toBe(
      "DIV"
    );
    expect(
      gridFreightRateSetupData_txtCarrierAbbriviationC.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightRateSetup_gridFreightRateSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCarrierAbbriviationC", () => {
    // START_USER_CODE-USER_txtCarrierAbbriviationC_TEST
    // END_USER_CODE-USER_txtCarrierAbbriviationC_TEST
  });
  test("gridFreightRateSetupData_txtCarrierVendor(Grid Widget) Test Cases", async () => {
    let gridFreightRateSetupData_txtCarrierVendor = screen.getByTestId(
      "gridFreightRateSetupData"
    );
    let gridFreightRateSetupData_txtCarrierVendorbtn =
      gridFreightRateSetupData_txtCarrierVendor.nextElementSibling
        .firstElementChild;
    gridFreightRateSetupData_txtCarrierVendor =
      gridFreightRateSetupData_txtCarrierVendor.parentElement.parentElement
        .parentElement;
    expect(gridFreightRateSetupData_txtCarrierVendor.tagName).toBe("DIV");
    expect(gridFreightRateSetupData_txtCarrierVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightRateSetup_gridFreightRateSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCarrierVendor", () => {
    // START_USER_CODE-USER_txtCarrierVendor_TEST
    // END_USER_CODE-USER_txtCarrierVendor_TEST
  });
  test("gridFreightRateSetupData_txtDestBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridFreightRateSetupData_txtDestBuyingPoint = screen.getByTestId(
      "gridFreightRateSetupData"
    );
    let gridFreightRateSetupData_txtDestBuyingPointbtn =
      gridFreightRateSetupData_txtDestBuyingPoint.nextElementSibling
        .firstElementChild;
    gridFreightRateSetupData_txtDestBuyingPoint =
      gridFreightRateSetupData_txtDestBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridFreightRateSetupData_txtDestBuyingPoint.tagName).toBe("DIV");
    expect(gridFreightRateSetupData_txtDestBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightRateSetup_gridFreightRateSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDestBuyingPoint", () => {
    // START_USER_CODE-USER_txtDestBuyingPoint_TEST
    // END_USER_CODE-USER_txtDestBuyingPoint_TEST
  });
  test("txtEffectiveDate(Date Widget) Test Cases", async () => {
    const txtEffectiveDate = screen.getByTestId("txtEffectiveDate");
    expect(txtEffectiveDate.tagName).toBe("INPUT");
    expect(txtEffectiveDate.type).toBe("text");
    expect(txtEffectiveDate.classList).toContain("datetimepicker-input");
    expect(
      txtEffectiveDate.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateSetup_txtEffectiveDate")
    );
    await act(async () => {
      userEvent.click(txtEffectiveDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtEffectiveDate", () => {
    // START_USER_CODE-USER_txtEffectiveDate_TEST
    // END_USER_CODE-USER_txtEffectiveDate_TEST
  });
  test("gridFreightRateSetupData_txtEffectiveDateC(Grid Widget) Test Cases", async () => {
    let gridFreightRateSetupData_txtEffectiveDateC = screen.getByTestId(
      "gridFreightRateSetupData"
    );
    let gridFreightRateSetupData_txtEffectiveDateCbtn =
      gridFreightRateSetupData_txtEffectiveDateC.nextElementSibling
        .firstElementChild;
    gridFreightRateSetupData_txtEffectiveDateC =
      gridFreightRateSetupData_txtEffectiveDateC.parentElement.parentElement
        .parentElement;
    expect(gridFreightRateSetupData_txtEffectiveDateC.tagName).toBe("DIV");
    expect(gridFreightRateSetupData_txtEffectiveDateC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightRateSetup_gridFreightRateSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEffectiveDateC", () => {
    // START_USER_CODE-USER_txtEffectiveDateC_TEST
    // END_USER_CODE-USER_txtEffectiveDateC_TEST
  });
  test("gridFreightRateSetupData_txtNoLoadIndicator(Grid Widget) Test Cases", async () => {
    let gridFreightRateSetupData_txtNoLoadIndicator = screen.getByTestId(
      "gridFreightRateSetupData"
    );
    let gridFreightRateSetupData_txtNoLoadIndicatorbtn =
      gridFreightRateSetupData_txtNoLoadIndicator.nextElementSibling
        .firstElementChild;
    gridFreightRateSetupData_txtNoLoadIndicator =
      gridFreightRateSetupData_txtNoLoadIndicator.parentElement.parentElement
        .parentElement;
    expect(gridFreightRateSetupData_txtNoLoadIndicator.tagName).toBe("DIV");
    expect(gridFreightRateSetupData_txtNoLoadIndicator.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightRateSetup_gridFreightRateSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtNoLoadIndicator", () => {
    // START_USER_CODE-USER_txtNoLoadIndicator_TEST
    // END_USER_CODE-USER_txtNoLoadIndicator_TEST
  });
});
