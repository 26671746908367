/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  setData, 
  getData,
  hide,
} from "../../shared/WindowImports";
import "./ReportPreviewExcPrem.scss";

import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import CommonContext from '../../Store/CommonContext'
import jsPDF from "jspdf";
import { useReactToPrint } from 'react-to-print';
import { GetReportLogoImage, GetReportLogoImageDimension } from "./../../Common/Constants";

function ContractManagement_ReportPreviewExcPrem(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  
  const cropYear = () => (JSON.parse(sessionStorage.getItem('year')));  
  const commonContext = useContext(CommonContext);

  const order_param = null
  const logoUrl = GetReportLogoImage(window.location.origin)
  const imgDimension = GetReportLogoImageDimension()
  
  let cropYearFromLS = cropYear()
  let reqMemoNum = commonContext.getExceptPremCreate_MemoNum;
  let lstDisplayData = [];

  let states = {
    Label: "ReportPreviewExcPrem",
    windowName: "ReportPreviewExcPrem",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ReportPreviewExcPrem",
    
    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A"
    },
    renderURL : "",
    
    groupboxwidget0: {
      name: "groupboxwidget0",
      type: "GroupBoxWidget",
      parent: "ReportPreviewExcPrem",
      Label: "groupboxwidget0",
      Height: "",
      Width: "",
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Print Setup",
      CharWidth: "10",
      Visible : false,
      ofTypeDomain: "d_boolean",
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Print",
      CharWidth: "10",
      ofTypeDomain: "d_boolean",
    },
    btnSaveAsPdf: {
      name: "btnSaveAsPdf",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Save As PDF",
      CharWidth: "10",
      ofTypeDomain: "d_boolean",
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Close",
      CharWidth: "10",
      ofTypeDomain: "d_boolean",
    }
  };

  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
  }, []);

  useEffect(() => {
    hide(thisObj, "btnSaveAsPdf")
    RetrieveDisplayData();
  }, [commonContext.getExceptPremCreate_MemoNum])

  async function RetrieveDisplayData(){   
    if(reqMemoNum == undefined && reqMemoNum == null){
      return;
    }
        
    var js = []
    var total = 0
    var grossPoundsTotal = 0
    var netPoundTotal = 0
    var netLSKPoundTotal = 0

    let response = await ContractManagementService.RetrieveExceptionPremiumRequests(reqMemoNum, null)
    let data = response[0].exceptpremrequestdetails;
    var dict = { 
      'G' : ' / Gross Ton',
      'N' : ' / Net Ton',
      'K' : ' / Net Less LSK Ton',
      'B' : ' / Net Ton Basis Grade',
      'L' : ' / Load'
    }
    
    if(data.length != 0){
      for(var i=0;i<data.length;i++){        
        js.push({
          "F1007"         : data[i].settle_num,
          "Farm"          : data[i].farm_id + " " + data[i].farm_suffix,
          "Cont"          : data[i].contract_num,
          "SC95"          : data[i].insp_num ,
          "WhseRcpt"      : data[i].whse_rcpt_num , 
          "UnloadWhs"     : data[i].unld_whse_id,
          "Bin"           : data[i].unld_bin_id,
          "Disps"         : data[i].purch_store_ind == 'S' ? 'Storage' : data[i].purch_store_ind == 'P' ? 'Purchase' : '',
          "GrossPounds"   : data[i].gross_wt ,
          "NetPound"      : data[i].net_wt ,
          "NetLSKPound"   : data[i].net_wt - data[i].lsk_wt ,
          "BasisGradeAmt" : data[i].basis_grade_amt ,
          "Seg"           : data[i].seg_type ,
          "PeanutType"    : data[i].pnut_type_id ,
          "Var"           : data[i].pnut_variety_id ,
          "SeedGen"       : data[i].seed_gen ,
          "SeedSpec"      : data[i].seed_meeting_spec_ind ,
          "Oleic"         : data[i].oleic_ind ,
          "RateBase"      : data[i].prem_rate + dict[data[i].prem_rate_base],
          "PremAmt"       : data[i].prem_amount == '' ? 0 : data[i].prem_amount
        })
        
        total  += Number(data[i].prem_amount)
        grossPoundsTotal += Number(data[i].gross_wt)
        netPoundTotal += Number(data[i].net_wt)
        netLSKPoundTotal += Number(data[i].net_wt - data[i].lsk_wt)
      }    
      setData(thisObj, 'lstDisplayData', js)
      RetrieveExceptionPremiumRequestDetail(total, grossPoundsTotal, netPoundTotal, netLSKPoundTotal)
    }
    else{
      showMessage(thisObj, "No rows were selected.")
      return;
    }
  }

  async function RetrieveExceptionPremiumRequestDetail(total, grossPoundsTotal, netPoundTotal, netLSKPoundTotal){
    if(reqMemoNum == ''){
      showMessage(thisObj, "Requested memo number empty")
      return;      
    }

    let response = await ContractManagementService.RetrieveExceptionPremiumRequests(reqMemoNum, order_param)
    if (response != null && response != undefined) {
      if (response.length != 0) {
        let data = response[0]
        var approvedBy   = "<u> " + data.approve_by + " </u>"
        var approvedDate = "<u> " + new Date(data.approve_date_time).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + new Date(data.approve_date_time).toLocaleString('en-US', { hour: '2-digit', hour12: true, minute: '2-digit' }) + " </u>";
        if (data.approve_by == '') {
          approvedBy   = "______________________"
          approvedDate = "______________"
        }
        var obj = {
          "Total1"          : grossPoundsTotal,
          "Total2"          : netPoundTotal,
          "Total3"          : netLSKPoundTotal,
          "PremiumCodeDesc" : data.prem_code + "-" + data.pd_desc,
          "Total"           : total.toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2}),          
          "Explaination"    : data.request_explanation,
          "RequestedBy"     : "<u> " + data.request_by + " </u>",
          "RequestedDate"   : "<u> " + new Date(data.request_date_time).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + new Date(data.request_date_time).toLocaleString('en-US', { hour: '2-digit', hour12: true, minute: '2-digit' }) + " </u>",
          "ApprovedBy"      : approvedBy,
          "ApprovedDate"    : approvedDate,
          "RequestNo"       : reqMemoNum,
          "Status"          : data.request_status,
          "Location"        : data.buy_pt_id + "-" + data.buy_pt_name,
          "Vendor"          : data.prem_vendor + "-" + data.vendor_name,
          "PrintDate"       : moment(new Date()).format('MM/DD/YYYY hh:mm A') //data.request_date_time
        }
        var lstDisplayData = getData(thisObj, 'lstDisplayData')
        setData(thisObj, 'lstDisplayData', lstDisplayData)
        HTMLRenderData(obj)
      }
    }
    else{
      return;
    }
  }

  function HTMLRenderData(renderData){
    var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
    var renderText1 = `<html>
    <style>
    body:before{
      content: '${environmentType}';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;  
      color: #0d745e;
      font-size: 250px;
      font-weight: 500px;
      display: grid;
      justify-content: center;
      align-content: center;
      opacity: 0.35;
    }
    </style>
    <br/> <br/>
    <table style="align-items: center;width: 100%;">
        <tr style="padding-left:1%; padding-right: 1%;">
            <td style="padding-left:1%; padding-right: 1%;">
                  <img src="${logoUrl}" ${imgDimension} alt="logo"> <br/> 
            </td>
            <td style="padding-left:1%;text-align: center; padding-right: 25%;">
                  <span style="font-size:140%;">
                      <b> Payment Request of Exception Premiums  </b>
                  </span>
            </td>
        </tr>
    </table>
    <br/> <br/> <br/>
    <table style="align-items: center;width: 100%;padding-left: 3%;padding-right: 3%;">    
        <tr style="padding-left:1%; padding-right: 1%;">
            <td> <br/> 1007#</td>
            <td> <br/> Farm</td>
            <td> <br/> Cont</td>
            <td> <br/> SC95</td>
            <td> Whse <br/>Rcpt#</td>
            <td> Unload <br/>  Whs</td>
            <td> <br/> Bin</td>
            <td> <br/> Disps</td>
            <td> Gross <br/> Pounds</td>
            <td> Net <br/> Pounds</td>
            <td> Net-LSK <br/> Pounds</td>
            <td> Basis Grade <br/> Amount</td>
            <td> <br/> Seg &nbsp; </td>
            <td> Peanut <br/> Type</td>
            <td> <br/> Var &nbsp; </td>
            <td> Seed <br/> Gen</td>
            <td> Seed <br/> Spec</td>
            <td> <br/> Oleic</td>
            <td> Rate <br/> Base</td>
            <td> Prem <br/> Amt</td>
        </tr>
        <tr>
            <td colspan="20"> 
                <hr style="width:100%;">
            </td>
        </tr>` 
    var renderText2 = ""
    var lstDisplayData = getData(thisObj, 'lstDisplayData')
   
    if(lstDisplayData == undefined || lstDisplayData == null) {
      return;
    }

    for(var i=0;i<lstDisplayData.length;i++){
          var text = `
          <tr>
                <td> ${lstDisplayData[i].F1007} </td>
                <td> ${lstDisplayData[i].Farm} </td>
                <td> ${lstDisplayData[i].Cont} </td>
                <td> ${lstDisplayData[i].SC95} </td>
                <td> ${lstDisplayData[i].WhseRcpt} </td>
                <td> ${lstDisplayData[i].UnloadWhs} </td>
                <td> ${lstDisplayData[i].Bin} </td>
                <td> ${lstDisplayData[i].Disps} </td>
                <td> ${lstDisplayData[i].GrossPounds} </td>
                <td> ${lstDisplayData[i].NetPound} </td>
                <td> ${lstDisplayData[i].NetLSKPound} </td>
                <td> ${lstDisplayData[i].BasisGradeAmt} </td>
                <td> ${lstDisplayData[i].Seg} </td>
                <td> ${lstDisplayData[i].PeanutType} </td>
                <td> ${lstDisplayData[i].Var} </td>
                <td> ${lstDisplayData[i].SeedGen} </td>
                <td> ${lstDisplayData[i].SeedSpec} </td>
                <td> ${lstDisplayData[i].Oleic} </td>
                <td> ${lstDisplayData[i].RateBase} </td>
                <td> $${lstDisplayData[i].PremAmt} </td>
          </tr>`
          renderText2 += text;
    }
    renderText2 += `<tr> <td> <br/> <br/> </td> </tr>`
    var renderText3 =     `<tr style="padding-top:10%;">
            <td style="text-align: right;" colspan="8">TOTAL&nbsp; : &nbsp; </td>
            <td>${renderData.Total1}</td>
            <td>${renderData.Total2}</td>
            <td>${renderData.Total3}</td>
            <td style="text-align: right;" colspan="9">$ ${renderData.Total}</td>
        </tr>
        <tr>
            <td> <br/> <br/> <br/> </td>
        </tr>
        <tr>
            <td colspan="1"></td>
            <td style="text-align: left;" colspan="3">Premium Code: </td>
            <td colspan="1"></td>
            <td style="text-align: left;" colspan="5"> ${renderData.PremiumCodeDesc}</td>
        </tr>
        <tr>
            <td colspan="1"></td>
            <td style="text-align: left;" colspan="3">Total Due to Producer: </td>
            <td colspan="1"></td>
            <td style="text-align: left;" colspan="4">$ ${renderData.Total}</td>
        </tr>
        <tr>
            <td> <br/> <br/> <br/> <br/> <br/>  </td>
        </tr>
        <tr>
            <td colspan="20">Explanation : ${renderData.Explaination}</td>
        </tr>
        <tr>
            <td> <br/> <br/> <br/> <br/> <br/> </td>
        </tr>
        <tr>
            <td style="text-align: left;" colspan="14">Requested by : ${renderData.RequestedBy}</td>
            <td style="text-align: left;" colspan="6"> Approved by:  ${renderData.ApprovedBy}  </td>
        </tr>
        <tr>
            <td style="text-align: left;" colspan="14">Requested date :  ${renderData.RequestedDate} &nbsp; </td>
            <td style="text-align: left;" colspan="6"> Approved date : ${renderData.ApprovedDate}  </td>
        </tr>
        <tr>
            <td> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>  <br/> <br/> <br/> </td>
        </tr>
    </table>

    <table style="align-items: center;width: 100%;padding-left: 3%;padding-right: 3%;">
      <tr>
          <td style="text-align: left;" colspan="10">
              Request # : ${renderData.RequestNo}
          </td>
          <td style="text-align: center;" colspan="7">
              Location # : ${renderData.Location}
          </td>  
          <td style="text-align: right;">
              Vendor # : ${renderData.Vendor}
          </td> 
        </tr>

        <tr>
          <td style="text-align: left;" colspan="10">
              Status : ${renderData.Status}
          </td>
          <td style="text-align: center;" colspan="7">
              Page: 1 of 1
          </td>  
          <td style="text-align: right;">
              Print Date : ${renderData.PrintDate}
          </td> 
        </tr>
    </table>
    </html>`

    let renderText = renderText1 + renderText2 + renderText3
    state.renderUrl = getBlobURL(renderText, 'text/html')
    document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    let datahtml = getBlobURL(renderText, 'text/html')
    setData(thisObj,'datahtml',datahtml)
  }

  function GetFileName(){
    return ("PayReqExPrem_" + cropYearFromLS + "_" + reqMemoNum + ".pdf")                                
  }

  const handlePrint  = useReactToPrint({
    content : () => document.getElementById('docFrame').contentWindow.document.body, 
    documentTitle : GetFileName(), 
    copyStyles: true,
    addFont : "Times New Roman",
    autoPaging : true,
    onPrintError : (err) => showMessage(thisObj, "Some issue happened. Please try to print again."),
    suppressErrors : true
  })

  const getBlobURL = (code, type) => {
    const blob = new Blob([code], {type});
    return URL.createObjectURL(blob)
  }

  const onbtnSaveAsPdfClick = () => {
    try{
      var renderText = document.getElementById('docFrame').contentWindow.document.body;
      var fileName = GetFileName()
      var doc = new jsPDF('p', 'mm', 'a4');
      doc.addFont({ fontStyle : "Times New Roman"})
      doc.setTextColor("black")
      doc.setFontSize(10)
      doc.html(renderText, {
        callback: function(doc) {
          doc.save(fileName)
        },
        margin: [10, 10, 10, 10],
        autoPaging: 'text',
        x: 0,
        y: 0,
        width: 190, 
        windowWidth: 925
      });
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:btnSaveAsPdf event:Click");
      }
      return false;
    }
  }
  thisObj.onbtnSaveAsPdfClick= onbtnSaveAsPdfClick

  const onbtnPrintClick = () => {
    try{
      //handlePrint()
      let htmldata = getData(thisObj, 'datahtml')
      let fileName = 'PayReqExPrem_'+cropYearFromLS+'_'+reqMemoNum
      const pdfWindow = window.open(htmldata, '', 'width=1000,height=850,scrollbars=1,menuBar=1', fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => { })
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:btnSaveAsPdf event:Click");
      }
      return false;
    }
  }
  thisObj.onbtnPrintClick= onbtnPrintClick

  const onbtnCloseClick = () => {
    try {
      document.getElementById("ContractManagement_ReportPreviewExcPremPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            
            <ContractManagement_header headerData={state.headerData} />            
            <h1 className="pageHeader">{state.Label}</h1>            
            <Form noValidate className="row">
            
              <iframe src={state.renderURL} id="docFrame" width="100%" height="500px" style={{background:"White"}}></iframe>
              <GroupBoxWidget
                conf={state.groupboxwidget0}
                screenConf={state}
              >
                <ButtonWidget
                  conf={state.btnPrintSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnSaveAsPdf}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>              
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ReportPreviewExcPrem);