/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  hide,
  show,
  setValue,
  getValue,
  setData,
  getData,
  getSelectedRowNumber,
  getGridValue,
  setGridValue,
  goTo,
} from "../../shared/WindowImports";

import "./TradeSettleApply.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import { isInteger } from "formik";

// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettleApply(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeSettleApply",
    windowName: "TradeSettleApply",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettleApply",
    // START_USER_CODE-USER_TradeSettleApply_PROPERTIES
    headerData: {
      scrName: "Trade Agreement Application",
      scrCode: "PN1140D",
    },
    // END_USER_CODE-USER_TradeSettleApply_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES
      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES
      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    btnPopup: {
      name: "btnPopup",
      type: "ButtonWidget",
      colName: "btnColPopup",
      parent: "gridAgreementApplication",
      Label: " ",
      CharWidth: "9",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPopup_PROPERTIES
      // END_USER_CODE-USER_btnPopup_PROPERTIES
    },
    btnColPopup: {
      name: "btnColPopup",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "...",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_btnColContrctPopup_PROPERTIES
      // END_USER_CODE-USER_btnColContrctPopup_PROPERTIES
    },
    colAgree: {
      name: "colAgree",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Agree #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgree_PROPERTIES

      // END_USER_CODE-USER_colAgree_PROPERTIES
    },
    colAgreeDate: {
      name: "colAgreeDate",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Agree Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeDate_PROPERTIES

      // END_USER_CODE-USER_colAgreeDate_PROPERTIES
    },
    colAgreedLbs: {
      name: "colAgreedLbs",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Agreed Lbs.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreedLbs_PROPERTIES

      // END_USER_CODE-USER_colAgreedLbs_PROPERTIES
    },
    colAgreedPrice: {
      name: "colAgreedPrice",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Agreed Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreedPrice_PROPERTIES

      // END_USER_CODE-USER_colAgreedPrice_PROPERTIES
    },
    colAppliedLbs: {
      name: "colAppliedLbs",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Applied Lbs.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_colAppliedLbs_PROPERTIES
    },
    colFirmBasisFlat: {
      name: "colFirmBasisFlat",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Firm / Basis / Flat",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFirmBasisFlat_PROPERTIES

      // END_USER_CODE-USER_colFirmBasisFlat_PROPERTIES
    },
    colLbsToApply: {
      name: "colLbsToApply",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Lbs. to Apply",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLbsToApply_PROPERTIES

      // END_USER_CODE-USER_colLbsToApply_PROPERTIES
    },
    colMethod: {
      name: "colMethod",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMethod_PROPERTIES

      // END_USER_CODE-USER_colMethod_PROPERTIES
    },
    colOpenLbs: {
      name: "colOpenLbs",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Open Lbs.",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenLbs_PROPERTIES

      // END_USER_CODE-USER_colOpenLbs_PROPERTIES
    },
    colOrder: {
      name: "colOrder",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Order",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrder_PROPERTIES

      // END_USER_CODE-USER_colOrder_PROPERTIES
    },
    colPnutVar: {
      name: "colPnutVar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Pnut Var",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutVar_PROPERTIES

      // END_USER_CODE-USER_colPnutVar_PROPERTIES
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGen_PROPERTIES

      // END_USER_CODE-USER_colSeedGen_PROPERTIES
    },
    gridAgreementApplication: {
      name: "gridAgreementApplication",
      type: "GridWidget",
      parent: "grpbxTradeAgrmntApp",
      gridCellsOrder:
        "colIsSts,btnPopup,txtcolAgree,txtcolAgreeDate,txtcolPnutVar,txtcolSeedGen,txtcolMethod,txtcolFirmBasisFlat,txtcolOrder,txtcolLbsToApply,txtcolAgreedPrice,txtcolAgreedLbs,txtcolAppliedLbs,txtcolOpenLbs",
      ColSpan: "8",
      Pagination: false,
      ColsForTabLandscape: "13",
      HasLabel: false,
      Cols: "13",
      ColsForTabPotrait: "13",
      ColsForLargeDesktop: "13",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAgreementApplication_PROPERTIES
      isEditable: true,
      needGridChange: true,
      isAddRowRequired:false,
      isDeleteRowRequired:false
      // END_USER_CODE-USER_gridAgreementApplication_PROPERTIES
    },
    lblAgreementApplications: {
      name: "lblAgreementApplications",
      type: "LabelWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Agreement Applications",
      ColSpan: "8",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_lblAgreementApplications_PROPERTIES
    },
    txt1stVendorName: {
      name: "txt1stVendorName",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "1st Vendor #/Name:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1stVendorName_PROPERTIES

      // END_USER_CODE-USER_txt1stVendorName_PROPERTIES
    },
    txtcolAgree: {
      name: "txtcolAgree",
      type: "TextBoxWidget",
      colName: "colAgree",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgree_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolAgree_PROPERTIES
    },
    txtcolAgreeDate: {
      name: "txtcolAgreeDate",
      type: "TextBoxWidget",
      colName: "colAgreeDate",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeDate_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolAgreeDate_PROPERTIES
    },
    txtcolAgreedLbs: {
      name: "txtcolAgreedLbs",
      type: "TextBoxWidget",
      colName: "colAgreedLbs",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreedLbs_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolAgreedLbs_PROPERTIES
    },
    txtcolAgreedPrice: {
      name: "txtcolAgreedPrice",
      type: "TextBoxWidget",
      colName: "colAgreedPrice",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreedPrice_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolAgreedPrice_PROPERTIES
    },
    txtcolAppliedLbs: {
      name: "txtcolAppliedLbs",
      type: "TextBoxWidget",
      colName: "colAppliedLbs",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedLbs_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolAppliedLbs_PROPERTIES
    },
    txtcolFirmBasisFlat: {
      name: "txtcolFirmBasisFlat",
      type: "TextBoxWidget",
      colName: "colFirmBasisFlat",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFirmBasisFlat_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolFirmBasisFlat_PROPERTIES
    },
    txtcolLbsToApply: {
      name: "txtcolLbsToApply",
      type: "TextBoxWidget",
      colName: "colLbsToApply",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolLbsToApply_PROPERTIES
    },
    txtcolMethod: {
      name: "txtcolMethod",
      type: "TextBoxWidget",
      colName: "colMethod",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMethod_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolMethod_PROPERTIES
    },
    txtcolOpenLbs: {
      name: "txtcolOpenLbs",
      type: "TextBoxWidget",
      colName: "colOpenLbs",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenLbs_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolOpenLbs_PROPERTIES
    },
    txtcolOrder: {
      name: "txtcolOrder",
      type: "TextBoxWidget",
      colName: "colOrder",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrder_PROPERTIES

      // END_USER_CODE-USER_txtcolOrder_PROPERTIES
    },
    txtcolPnutVar: {
      name: "txtcolPnutVar",
      type: "TextBoxWidget",
      colName: "colPnutVar",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutVar_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolPnutVar_PROPERTIES
    },
    txtcolSeedGen: {
      name: "txtcolSeedGen",
      type: "TextBoxWidget",
      colName: "colSeedGen",
      parent: "gridAgreementApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedGen_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeedGen_PROPERTIES
    },
    txtLoanRepaymentRate: {
      name: "txtLoanRepaymentRate",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Loan Repayment Rate:",
      ColSpan: "8",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepaymentRate_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepaymentRate_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Net Weight",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPurchaseSale: {
      name: "txtPurchaseSale",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Purchase/Sale",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPurchaseSale_PROPERTIES

      // END_USER_CODE-USER_txtPurchaseSale_PROPERTIES
    },
    txtSeedYN: {
      name: "txtSeedYN",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Seed (Y/N)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedYN_PROPERTIES

      // END_USER_CODE-USER_txtSeedYN_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Seg",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtTradeInspect: {
      name: "txtTradeInspect",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Trade Inspect #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspect_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspect_PROPERTIES
    },
    txtValue: {
      name: "txtValue",
      type: "TextBoxWidget",
      parent: "grpbxTradeAgrmntApp",
      Label: "Value",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue_PROPERTIES

      // END_USER_CODE-USER_txtValue_PROPERTIES
    },
    grpbxTradeAgrmntApp: {
      name: "grpbxTradeAgrmntApp",
      type: "GroupBoxWidget",
      parent: "TradeSettleApply",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxTradeAgrmntApp_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeAgrmntApp_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "TradeSettleApply",
      ColSpan: "8",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "8",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      "ContractManagement#MainMenu": {},
      REVERSE: {},
      "ContractManagement#MainMenu": {},
    },
  };

  

  

  let _buttonServices = {};
  let _buttonNavigation = {
        // btnCancel: {
        //   DEFAULT: ["Settlements#TradeSettleProfile#DEFAULT#false#Click"],
        // },
    
      }
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };



  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    thisObj.state.gridAgreementApplication.actionList[3].required = false;
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const onbtnOkClick = () => {
    try {
      bFormValid();
      bFillContractList();
      if (isContractListValid === false) {
        return
      }
      if (isFormValid === false) {
        return
      }
      setData(thisObj, 'frmTradeSettleProfileData-lblLoanRepayRate', txtLoanRepaymentRate.value )
      if (getValue(thisObj, 'txtNetWeight') == (0 || '0')) {
        setData(thisObj, 'preDectScreenOPEN', false)
      } else {
        setData(thisObj, 'preDectScreenOPEN', true)
      }
      //goTo(thisObj, "Settlements#TradeSettleProfile#DEFAULT#true#Click")
      document.getElementById("Settlements_TradeSettleApplyPopUp").childNodes[0].click()
      return true

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnOk click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  let prntScrData = getData(thisObj, 'ReviewCreateSettlementFormLoadData')

  // form load variables...
  const txtTradeInspNum = prntScrData.txtTradeInspNum           //"D008750"
  const optTradeSale = prntScrData.txtTradeInspNum              //true
  const cboVendor =   prntScrData.Vendordetails[0].split_vendor                     //"666666"
  const lblVendor =  prntScrData.Vendordetails[0].vendor_name                  // to do after getting data from parent screen
  const cboPeanutType = prntScrData.cboPeanutType === 'RU' ? 'Runner' : 'SP' ? 'Spanish' : 'VL' ? 'Valencia' : 'VA' ? 'Virginia' : ''   //"Runner"
  const cboSeg =  prntScrData.cboSeg                            //"1"
  const cboGeneration = prntScrData.cboGeneration               //""
  const optTKCBasis = prntScrData.optTKCBasis                   //false
  const lblTotalValueOfLoad = prntScrData.lblTotalValueOfLoad   //"1111.11"
  const lblNetWeight = prntScrData.lblNetWeight                 //"8000"

  //API call variables...
  const buy_pt_id = prntScrData.Buying_point_id                  //R29
  const pnut_type_id = prntScrData.cboPeanutType                 //"RU"
  const pnut_variety_id = prntScrData.ddPeanutVariety            //"GA06"
  const grade_pricing_method = prntScrData.optTKCBasis           //"FULL"
  const purch_sale_ind = prntScrData.optTradeSale                //"S"
  const delv_ind = "N"

  //global variables...
  var isFormValid = false
  var isContractListValid = false
  const [rowNo, setRowNo] = useState()

  const [myArr, setMyArr] = useState([]);




  const formLoad = () => {

    let nNetWt = lblNetWeight;
    let nSmkPct = prntScrData.txtTotalSMKRScreenPct
    let nLskWt = prntScrData.lblLSK

    setValue(thisObj, 'txtTradeInspect', txtTradeInspNum)
    if (purch_sale_ind.toUpperCase() === "S")
      setValue(thisObj, 'txtPurchaseSale', "Sale")
    else
      setValue(thisObj, 'txtPurchaseSale', "Purchase")

    setValue(thisObj, 'txt1stVendorName', cboVendor + " " + lblVendor)
    setValue(thisObj, 'txtPeanutType', cboPeanutType)
    setValue(thisObj, 'txtSeg', cboSeg)
    setValue(thisObj, 'txtSeedYN', cboGeneration)

    if (optTKCBasis === "TKC") {
      if(isNaN(nNetWt))  nNetWt = 0;
      if(isNaN(nSmkPct))  nSmkPct = 0;
      if(isNaN(nLskWt))  nLskWt = 0;
      
      let  netWtActual = Math.round( ((parseInt(nNetWt) - parseInt(nLskWt)) * (parseInt(nSmkPct) / 100)) + (parseInt(nLskWt)) )      //------ to do while integrating with parent screen------------------------
      setValue(thisObj, 'txtNetWeight', netWtActual)
      
    } else {
      setValue(thisObj, 'txtNetWeight', lblNetWeight)
    }

    setValue(thisObj, 'txtValue', lblTotalValueOfLoad)

    if (optTKCBasis === "TKC")
      hide(thisObj, 'txtLoanRepaymentRate')

    bFillContractApplications();

  }

  function ConvertToDate(str) {

    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }

  const validateTextBox = (val) => {
    var res = ''
    for (var i = 0; i < val.length; i++) {
      if (val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) {
        res += val[i]
      }
    }
    return res;
  }

  const bFillContractApplications = () => {

    var data
    // Dim sVendor(19, 3) As String
    // let x

    // For X = 0 To frmTradeSettleProfile.cboVendor.Count - 1
    //       sVendor(X, 0) = frmTradeSettleProfile.cboVendor(X).Text
    //       sVendor(X, 1) = frmTradeSettleProfile.txtShare(X).Text
    //   Next X
    //                                 ----------------to do while integrating with parent screen------------------

    SettlementService.RetrieveTradeSettlementAvailAgreementDetails(buy_pt_id, txtTradeInspNum, pnut_type_id, pnut_variety_id, cboSeg, grade_pricing_method, purch_sale_ind, delv_ind)
      .then(response => {
        data = response
        if (data.length > 0) {
          hide(thisObj, 'txtLoanRepaymentRate')

          let data2 = data
          let js = []
          let obj = {}
          for (let i = 0; i < data2.length; i++) {
            if (data2[i].firm_basis_opt.toUpperCase() === "B")
              show(thisObj, 'txtLoanRepaymentRate')
            obj.txtcolAgree = data2[i].agreement_num
            obj.txtcolAgreeDate = ConvertToDate(data2[i].agree_date_time)
            obj.txtcolPnutVar = data2[i].pnut_variety_name

            switch (data2[i].symbol_ind) {
              case "TRADEMARK":
                obj.txtcolPnutVar = data2[i].pnut_variety_name + '' + String.fromCodePoint(0x02122)
                break;
              case "REGISTERED TRADEMARK":
                obj.txtcolPnutVar = data2[i].pnut_variety_name + '' + String.fromCodePoint(0x000AE)
                break;
              case "COPYRIGHT":
                obj.txtcolPnutVar = data2[i].pnut_variety_name + '' + String.fromCodePoint(0x00A9)
                break;

              default:
                break;
            }

            obj.txtcolSeedGen = data2[i].seed_gen
            obj.txtcolMethod = data2[i].grade_pricing_method
            obj.txtcolFirmBasisFlat = data2[i].firm_basis_opt

            if (data2[i].grade_pricing_method === 'FULL') {
              if (data2[i].price_per_ton === "" || data2[i].price_per_ton === 0){
                obj.txtcolAgreedPrice = '0.00'
              } else {
                obj.txtcolAgreedPrice = parseFloat(data2[i].price_per_ton)
              }
            } else {
              if (data2[i].price_per_lbs === "" || data2[i].price_per_lbs === 0){
                obj.txtcolAgreedPrice = '0.00'
              } else {
                obj.txtcolAgreedPrice = parseFloat(data2[i].price_per_lbs)
              }
            }

            obj.txtcolOrder = ""
            obj.txtcolLbsToApply = ""
            obj.txtcolAgreedLbs = data2[i].agreed_lbs
            obj.txtcolAppliedLbs = data2[i].delivered_lbs
            obj.txtcolOpenLbs = data2[i].agreed_lbs - data2[i].delivered_lbs
            js.push(obj)
            obj = {}
          }
          setValue(thisObj, 'gridAgreementApplication', js)
          setData(thisObj, 'gridAgreementApplication_rowsData', js)

        }

      })

  }

  const ongridAgreementApplicationRowSelect = (rowSelected) => {

    setRowNo(getSelectedRowNumber(thisObj, 'gridAgreementApplication'))

}
  thisObj.ongridAgreementApplicationRowSelect = ongridAgreementApplicationRowSelect;


  const ontxtLoanRepaymentRateChange = () => {
    try {
      setValue(thisObj, "txtLoanRepaymentRate", validateTextBox(txtLoanRepaymentRate.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtLoanRepaymentRate change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtLoanRepaymentRateChange = ontxtLoanRepaymentRateChange;

  const ontxtcolOrderBlur = (xxzz, colnme = 'txtcolOrder', rNumber = 0) => {
    try {
      let rowsData = getData(thisObj, 'gridAgreementApplication_rowsData')
    if (rowsData.length > 0) {
      switch (colnme) {
        case "txtcolOrder":
          if (isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rNumber))) {
            setGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rNumber, "")
            return
          }
          ComputeOrderedValues2()
          document.getElementsByClassName("fa-check")[0].click()
          break;
        case "txtcolLbsToApply":
          if (isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rNumber))) {
            setGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rNumber, "")
          }
          ComputeOrderedValues2()
          // document.getElementsByClassName("fa-check")[0].click()
          break;

        default:
          break;

      }
    }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtcolOrderBlur event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtcolOrderBlur = ontxtcolOrderBlur;

  // const onCellChange = (columnName, rNumber) => {
  //   let rowsData = getData(thisObj, 'gridAgreementApplication_rowsData')
  //   if (rowsData.length > 0) {
  //     switch (columnName) {
  //       case "txtcolOrder":
  //         if (isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rNumber))) {
  //           setGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rNumber, "")
  //           return
  //         }
  //         ComputeOrderedValues2()
  //         break;
  //       case "txtcolLbsToApply":
  //         if (isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rNumber))) {
  //           setGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rNumber, "")
  //         }
  //         ComputeOrderedValues2()
  //         break;

  //       default:
  //         break;
  //     }
  //   }
  // }


  const onbtnPopupClick = (event) => {
    try {

      let rowSelected = event.target.parentElement.parentElement.parentElement
      let rowID = rowSelected.id.substring(4, 5);
      let valueGrid = getValue(thisObj, "gridAgreementApplication")[rowID]

      let Obj = {}
      Obj.Agreenum = valueGrid.txtcolAgree;
      Obj.GstrEditMode = "1";
      Obj.UpdateBtnCaption = "Update";
      Obj.UpdateBtnlblStatus = "Update";
      Obj.UpdateBtnEnabledDelete = "True";
      Obj.UpdateBtnEnabledPrint = "True";
      let js = [];

      js.push(Obj);
      setData(thisObj, "CreateValue", js)
      goTo(thisObj, "Settlements#TradeAgreeProfile#DEFAULT#true#Click")
      return true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnPopup click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnPopupClick = onbtnPopupClick;


  const bFormValid = () => {
    let nApplPoundsToApply

    nApplPoundsToApply = 0
    let rowsData = getData(thisObj, 'gridAgreementApplication_rowsData') == undefined ? [] : getData(thisObj, 'gridAgreementApplication_rowsData')
    for (let i = 0; i < rowsData.length; i++) {
      let enteredLbsVal = getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i)
      if (rowsData[i].txtcolFirmBasisFlat === "B" && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) !== "") {
        if (txtLoanRepaymentRate.value == "") {
          showMessage(thisObj, "Loan Repay Rate is required!", false)
          return
        }
        isFormValid = false
      }

      if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) !== "") {
        if (isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i))) {
          showMessage(thisObj, "Order must be numeric.", false)
          return
        }
        isFormValid = false
      }

      if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) !== "") {
        if (!isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i))) {
          if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) < 0 || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) > 999999999) {
            showMessage(thisObj, "Pounds to Apply must be of format #########.", false)
            return
          }
        } else {
          showMessage(thisObj, "Pounds to Apply must be of format #########.", false)
          return
        }
        isFormValid = false
      }
      nApplPoundsToApply = nApplPoundsToApply + (enteredLbsVal == '' ? 0 : undefined ? 0 : parseInt(enteredLbsVal))
    }

    if (nApplPoundsToApply < parseInt(getValue(thisObj, 'txtNetWeight'))) {
      showMessage(thisObj, "Not all purchase pounds applied. Please apply all the pounds?", false)
      isFormValid = false
      return
    } else if (nApplPoundsToApply > parseInt(getValue(thisObj, 'txtNetWeight'))) {
      if (confirm("Pounds applied exceeds the pounds on the agreement!!! Do you want to continue ?") == false)
        isFormValid = false
      return
    }

    isFormValid = true

  }


  const bFillContractList = () => {

    let lstNewContractLine = [] 
    let lstAgreeNum = []
    let nContractCount
    let lbsToApp 
    let rowsData = getData(thisObj, 'gridAgreementApplication_rowsData') == undefined ? [] : getData(thisObj, 'gridAgreementApplication_rowsData')

    isContractListValid = false
      nContractCount = 0
      for (let i = 0; i < rowsData.length; i++) {
        lbsToApp = (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i))
        if (lbsToApp != '') {
          if(!isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i))){
            lstNewContractLine.push( rowsData[i].txtcolAgree + "|" + rowsData[i].txtcolFirmBasisFlat + "|" + lbsToApp + "|A|" + rowsData[i].txtcolAgreedPrice + "|C|")
            lstAgreeNum.push(rowsData[i].txtcolAgree)
          }
        }
      }
      setData(thisObj, "TradeSettleProfile_lstNewContractLine_Data", lstNewContractLine )
      setData(thisObj, "TradeSettleProfile_lstAgreeNum_Data", lstAgreeNum )
      isContractListValid = true
  }

  const ontxtcolOrderChange = () => {
    if(rowNo == undefined){
      let orderNum = getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', 0)
      // setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply',0, orderNum*100 )
    } else {
      {
        let orderNum = getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', rowNo)
        // setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply',rowNo, orderNum*100 )
      }
    }
  }
  thisObj.ontxtcolOrderChange = ontxtcolOrderChange

  



  const ComputeOrderedValues2 = () => {
    let tempArr = [];
    let rowsData = getData(thisObj, 'gridAgreementApplication_rowsData')
    let nAvailablePounds = parseInt(getValue(thisObj, 'txtNetWeight'))

    let sAgreeNumTemp
    let sOrderTemp
    let sPoundsTemp
    let sOpenTemp
    let sRowTemp

    let xxzz


    for (let i = 0; i < rowsData.length; i++) {

      let eleme = {}

      if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) != "" && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) != undefined) {
        if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) != "" && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) != undefined) {
          const eleme = { agree_num: rowsData[i].txtcolAgree, order_num: getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) }

          if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) != "" && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) != undefined) {
            eleme.lbsToApply = parseInt(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i))

          } else {
            if (nAvailablePounds < 0) {
              nAvailablePounds = 0
            }
            eleme.lbsToApply = parseInt(nAvailablePounds)
          }
          if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) == undefined || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) == 0 || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) == NaN) {
            eleme.open_applied = parseInt(rowsData[i].txtcolOpenLbs)
          } else {            
            eleme.open_applied = ( parseInt(rowsData[i].txtcolOpenLbs) + parseInt(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i)) )
          }
          eleme.index = i
          if (Object.keys(eleme).length != 0)
            tempArr.push(eleme);
        } else {
          nAvailablePounds = nAvailablePounds - parseInt(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i))
          if (nAvailablePounds < 0)
            nAvailablePounds = 0
        }
      } else {
        if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) != "" && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) != undefined) {

          eleme = { agree_num: rowsData[i].txtcolAgree, order_num: getGridValue(thisObj, 'gridAgreementApplication', 'txtcolOrder', i) }

          if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) != "" && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) != undefined) {
            eleme.lbsToApply = parseInt(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i))
          } else {
            if (nAvailablePounds < 0) {
              nAvailablePounds = 0
            }
            eleme.lbsToApply = parseInt(nAvailablePounds)
          }
          if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) == undefined || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) == 0 || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i) == NaN) {
            eleme.open_applied = parseInt(rowsData[i].txtcolOpenLbs)
          } else {            
            eleme.open_applied = ( parseInt(rowsData[i].txtcolOpenLbs) + parseInt(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', i)) )
          }
          eleme.index = i
        }
      }
      if (Object.keys(eleme).length != 0)
        tempArr.push(eleme);
    }

    setMyArr(tempArr)

    if (tempArr.length > 1 && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rowNo) == "" || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rowNo) == undefined || !isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rowNo))) {
      for (let i = 0; i < tempArr.length - 1; i++) {
        if (tempArr[i].order_num > tempArr[i + 1].order_num) {
          sAgreeNumTemp = tempArr[i + 1].agree_num
          sOrderTemp = tempArr[i + 1].order_num
          sPoundsTemp = tempArr[i + 1].lbsToApply
          sOpenTemp = tempArr[i + 1].open_applied
          sRowTemp = tempArr[i + 1].index

          tempArr[i + 1].agree_num = tempArr[i].agree_num
          tempArr[i + 1].order_num = tempArr[i].order_num
          tempArr[i + 1].lbsToApply = tempArr[i].lbsToApply
          tempArr[i + 1].open_applied = tempArr[i].open_applied
          tempArr[i + 1].index = tempArr[i].index

          tempArr[i].agree_num = sAgreeNumTemp
          tempArr[i].order_num = sOrderTemp
          tempArr[i].lbsToApply = sPoundsTemp
          tempArr[i].open_applied = sOpenTemp
          tempArr[i].index = sRowTemp
        }
      }
    }

    if (tempArr.length > 0 && getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rowNo) == "" || getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rowNo) == undefined || !isNaN(getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', rowNo)) ) {
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i].agree_num != "") {
          if (tempArr[i].lbsToApply <= tempArr[i].open_applied) {
            if (tempArr[i].lbsToApply <= nAvailablePounds) {
              if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index) != tempArr[i].lbsToApply) {
                if (tempArr[i].lbsToApply == 0 || tempArr[i].lbsToApply == undefined || tempArr[i].lbsToApply == "") {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, "")
                } else {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, parseInt(tempArr[i].lbsToApply))
                }
                ontxtcolOrderBlur(xxzz, 'txtcolLbsToApply', tempArr[i].index)
              }
              nAvailablePounds = nAvailablePounds - parseInt(tempArr[i].lbsToApply)
            } else {
              if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index) != nAvailablePounds) {
                if (nAvailablePounds == 0 || nAvailablePounds == undefined || nAvailablePounds == '') {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, "")
                } else {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, parseInt(nAvailablePounds))
                }
                ontxtcolOrderBlur(xxzz, 'txtcolLbsToApply', tempArr[i].index)
              }
              nAvailablePounds = nAvailablePounds - nAvailablePounds
            }
          } else {
            if (tempArr[i].open_applied <= nAvailablePounds) {
              if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index) != tempArr[i].open_applied) {
                if (tempArr[i].open_applied == 0 || tempArr[i].open_applied == undefined || tempArr[i].open_applied == '') {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, "")
                } else {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, parseInt(tempArr[i].open_applied))
                }
                ontxtcolOrderBlur(xxzz, 'txtcolLbsToApply', tempArr[i].index)
              }
              nAvailablePounds = nAvailablePounds - parseInt(tempArr[i].open_applied)
            } else {
              if (getGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index) != nAvailablePounds) {
                if (nAvailablePounds == 0 || nAvailablePounds == undefined || nAvailablePounds == '') {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, "")
                } else {
                  setGridValue(thisObj, 'gridAgreementApplication', 'txtcolLbsToApply', tempArr[i].index, parseInt(nAvailablePounds))
                }
                ontxtcolOrderBlur(xxzz, 'txtcolLbsToApply', tempArr[i].index)
              }
              nAvailablePounds = nAvailablePounds - nAvailablePounds
            }
          }
        }
      }
    }

  }



  const onbtnCancelClick = () => {
    try {
      setData(thisObj, 'preDectScreenOPEN', false)
      setData(thisObj, 'frmTradeSettleProfileData-lblLoanRepayRate', txtLoanRepaymentRate.value )
      // goTo(thisObj, "Settlements#TradeSettleProfile#DEFAULT#true#Click")
      document.getElementById("Settlements_TradeSettleApplyPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnCancel click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeSettleApply*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettleApply*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxTradeAgrmntApp*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeAgrmntApp*/}

              <GroupBoxWidget
                conf={state.grpbxTradeAgrmntApp}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspect*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspect*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspect}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspect*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspect*/}
                {/* START_USER_CODE-USER_BEFORE_txtPurchaseSale*/}

                {/* END_USER_CODE-USER_BEFORE_txtPurchaseSale*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPurchaseSale}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPurchaseSale*/}

                {/* END_USER_CODE-USER_AFTER_txtPurchaseSale*/}
                {/* START_USER_CODE-USER_BEFORE_txt1stVendorName*/}

                {/* END_USER_CODE-USER_BEFORE_txt1stVendorName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1stVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1stVendorName*/}

                {/* END_USER_CODE-USER_AFTER_txt1stVendorName*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedYN*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedYN*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedYN}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedYN*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedYN*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWeight*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWeight*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWeight*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValue*/}

                {/* END_USER_CODE-USER_AFTER_txtValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAgreementApplications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_lblAgreementApplications*/}
                {/* START_USER_CODE-USER_BEFORE_gridAgreementApplication*/}

                {/* END_USER_CODE-USER_BEFORE_gridAgreementApplication*/}

                <GridWidget
                  conf={state.gridAgreementApplication}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  // onRowChange={ selectedRows=> onGridRowChange(selectedRows)}
                  errors={errors}
                  touched={touched}
                  rows={values.gridAgreementApplication}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAgreementApplication*/}

                {/* END_USER_CODE-USER_AFTER_gridAgreementApplication*/}
                {/* START_USER_CODE-USER_BEFORE_txtLoanRepaymentRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtLoanRepaymentRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoanRepaymentRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoanRepaymentRate*/}

                {/* END_USER_CODE-USER_AFTER_txtLoanRepaymentRate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeAgrmntApp*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeAgrmntApp*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettle-Apply*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettleApply*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettleApply);
