/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable,
  isEnabled
} from "../../shared/WindowImports";

import "./StockTransferNewNumber.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { clsTransfer } from "../Common/clsTransfer";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_StockTransferNewNumber(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "StockTransferNewNumber",
    windowName: "StockTransferNewNumber",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.StockTransferNewNumber",
    // START_USER_CODE-USER_StockTransferNewNumber_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "New Transfer Number",
      scrCode: "",
    },
    // END_USER_CODE-USER_StockTransferNewNumber_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxStockTransferNewNumber",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    grpbxStockTransferNewNumber: {
      name: "grpbxStockTransferNewNumber",
      type: "GroupBoxWidget",
      parent: "StockTransferNewNumber",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStockTransferNewNumber_PROPERTIES

      // END_USER_CODE-USER_grpbxStockTransferNewNumber_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "StockTransferNewNumber",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    initTransferForm();
    setEventHandlers();

    return () => {
      removeEventHandlers();
      // let mStockTransfer = getData(thisObj, 'mStockTransfer')
      // let mDialogResult = getData(thisObj, 'mDialogResult')
      // let transferNum = getValue(thisObj, 'txtTransferNum')
      // setData(thisObj, "dataFromNewNumber" , {
      //   mStockTransfer : mStockTransfer,
      //   DialogResult: mDialogResult,
      //   newtransferNumber: transferNum
      //   }
      // )
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const initTransferForm = () => {
    // TODO: Integration Pending -> Stock Transfer Screen should send Cls Transfer object instance
    const mTransfer = getData(thisObj, 'mTransferStockTransferNewNumber');
    if (mTransfer && mTransfer instanceof clsTransfer) {
      const oldTransferNum = mTransfer.msOld_Trans_Num; // OldTransferNumber
      if (oldTransferNum) {
        setValue(thisObj, 'txtTransferNum', oldTransferNum);
      }

      if (!oldTransferNum || !isNaN(oldTransferNum)) {
        enable(thisObj, 'txtTransferNum');
      } else {
        disable(thisObj, 'txtTransferNum');
      }
    }
  };

  // START_USER_CODE-USER_METHODS
  const setEventHandlers = () => {
    // document.getElementById('txtTransferNum').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTransferNum').addEventListener('keypress', allowOnlyNumbers);
  };

  const removeEventHandlers = () => {
    // document.getElementById('txtTransferNum').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTransferNum').removeEventListener('keypress', allowOnlyNumbers);
  };

  const allSelectOnFocus = (event) => {
    document.getElementById(event.target.id).select();
  };

  const allowOnlyNumbers = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57)
      || e.keyCode === 8
      || e.keyCode === 3
      || e.keyCode === 22
      || e.keyCode === 24)) {
      e.preventDefault();
    }
  };

  const ontxtTransferNumBlur = (e) => {
    const enteredNumber = e.target.value;
    if (enteredNumber) {
      const formattedTransferNum = enteredNumber?.toString()?.padStart(6, '0');
      setValue(thisObj, 'txtTransferNum', formattedTransferNum);
    }
  };

  thisObj.ontxtTransferNumBlur = ontxtTransferNumBlur;

  const onbtnOkClick = async () => {
    try {
      const transferNum = getValue(thisObj, 'txtTransferNum') || '';
      const isTransferNumNaN = !transferNum || isNaN(transferNum);
      if (isEnabled(thisObj, 'txtTransferNum') && isTransferNumNaN) {
        showMessage(thisObj, 'Please, enter transfer number');
        return;
      } else {
        setData(thisObj, 'mDialogResult', false);

        // TODO: Integration Pending -> Stock Transfer Screen should send Cls Transfer object instance
        const mTransfer = getData(thisObj, 'mTransferStockTransferNewNumber');
        if (mTransfer && mTransfer instanceof clsTransfer) {
          const res = await mTransfer.CreateTransfer(transferNum);
            //Commented and added 20Jul2024 - PRB0053903 -Problem ticket 
            if(res==false){          
              // if (res?.message?.startsWith('Error:')) {
                // if (res?.message?.includes("PRIMARY KEY constraint 'PK_TRANSFER_HEADER'")) {
                    // if (mTransfer.mDbError.startsWith('Error:')) {
                      //if (mTransfer.mDbError.includes("PRIMARY KEY constraint 'PK_TRANSFER_HEADER'")) {
                 if ( mTransfer.mDbError.includes("Error")) {
                   if ( mTransfer.mDbError.includes("PRIMARY KEY constraint 'PK_TRANSFER_HEADER'")) {
                  //end of changes
              showMessage(thisObj, `Transfer # ${transferNum} already exist!`);
              if (!isEnabled(thisObj, 'txtTransferNum')) {
                enable(thisObj, 'txtTransferNum');
              }
              document.getElementById('txtTransferNum').focus();
              return;
            } else {
              showMessage(thisObj, `Create Transfer Error: ${res?.message || res?.toString()}`);
              setData(thisObj, 'mDialogResult', false);
              if (!isEnabled(thisObj, 'txtTransferNum')) {
                enable(thisObj, 'txtTransferNum');
              }

              let mStockTransfer1 = getData(thisObj, 'mStockTransfer')
              let mDialogResult1 = getData(thisObj, 'mDialogResult')
              let transferNum1 = getValue(thisObj, 'txtTransferNum')
              setData(thisObj, "dataFromNewNumber", {
                mStockTransfer: mStockTransfer1,
                DialogResult: mDialogResult1,
                newtransferNumber: transferNum1
              }
              )
              document.getElementById("StockTransfer_StockTransferNewNumberPopUp")?.childNodes[0]?.click();
              return;
            }// added 20Jul2024 - PRB0053903 -Problem ticket 
            }
          }
          setData(thisObj, 'mStockTransfer', mTransfer)
          setData(thisObj, 'mDialogResult', true);
        }
        let mStockTransfer2 = getData(thisObj, 'mStockTransfer')
        let mDialogResult2 = getData(thisObj, 'mDialogResult')
        let transferNum2 = getValue(thisObj, 'txtTransferNum')
        setData(thisObj, "dataFromNewNumber", {
          mStockTransfer: mStockTransfer2,
          DialogResult: mDialogResult2,
          newtransferNumber: transferNum2
        }
        )
        document.getElementById("StockTransfer_StockTransferNewNumberPopUp")?.childNodes[0]?.click();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Ok button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnCancelClick = async () => {
    try {
      setData(thisObj, 'mDialogResult', false);
      let mStockTransfer = getData(thisObj, 'mStockTransfer')
      let mDialogResult = getData(thisObj, 'mDialogResult')
      let transferNum = getValue(thisObj, 'txtTransferNum')
      setData(thisObj, "dataFromNewNumber", {
        mStockTransfer: mStockTransfer,
        DialogResult: mDialogResult,
        newtransferNumber: transferNum
      }
      )
      document.getElementById("StockTransfer_StockTransferNewNumberPopUp")?.childNodes[0]?.click();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Cancel button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_StockTransferNewNumber*/}

              {/* END_USER_CODE-USER_BEFORE_StockTransferNewNumber*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxStockTransferNewNumber*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxStockTransferNewNumber*/}

              <GroupBoxWidget
                conf={state.grpbxStockTransferNewNumber}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTransferNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxStockTransferNewNumber*/}

              {/* END_USER_CODE-USER_AFTER_grpbxStockTransferNewNumber*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_StockTransferNewNumber*/}

              {/* END_USER_CODE-USER_AFTER_StockTransferNewNumber*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_StockTransferNewNumber);
