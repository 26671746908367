/* eslint-disable*/
import { apiUrl } from '../../common/defaultValues';
// import {authHeader} from '../../helpers/authheader';
import axios from 'axios';

export const Contractcreateservice = {
  fetchVendorlookup,
  createcontractcreate,
  updatecontractcreate,
  deletecontractcreate,
  fileUpload,
  GetCropYear,
  GetCompanyName,
  GetPeanutTypeControlList,
  GetPeanutVarietyControlList,
  GetlocationList
};
export function fetchVendorlookup(txtSrch) {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
      //...authHeader()
    },
  };
  return fetch(`${apiUrl}ContractManagement/vendor_details/${txtSrch}`, requestOptions)
    .then(response => response.json())
    .then(user => {
      return user;
    })
    .catch((error) => {
    });

}
export function createcontractcreate(txtSrch) {
  const addUser = sessionStorage.getItem('userId')

  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
      //...authHeader()
    },
    body: JSON.stringify({})
  };
  return fetch(`${apiUrl}ContractManagement/vendorlookup/vendor-name/${txtSrch}`, requestOptions)
    .then(response => response.json())
    .then(user => {
      return user;
    })
    .catch((error) => {
    });

}

export function updatecontractcreate(id, name, status) {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
      //...authHeader()
    },
    body: JSON.stringify({ name, status })
  };
  return fetch(`${apiUrl}ContractManagement/contractcreates/contractcreate-abbrivations/${id}`, requestOptions)
    .then(response => response.json())
    .then(user => {
      return user;
    })
    .catch((error) => {
    });

}

function deletecontractcreate(id) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
      //...authHeader()
    },
    body: JSON.stringify({ id })
  };
  return fetch(`${apiUrl}ContractManagement/contractcreates/contractcreate-abbrivations//${id}`, requestOptions)
    .then(response => response.json())
    .then(user => {
      return user;
    })
    .catch((error) => {

    });
}
export function fileUpload(file) {
  let data = new FormData();
  data.append('formFile', file);
  var url = `${apiUrl}errorreport/bulkupload`
  return axios.post(url, data, {
    headers: {
      'content-type': 'multipart/form-data',
      //...authHeader()
    }
  })
    .then(res => {
      return res

    })
    .catch()

}
//   export default function* rootSaga() {
//     yield all([
//       fork(fetchVendorlookup),
//       fork(createcontractcreate),
//      fork(updatecontractcreate),
//      fork(deletecontractcreate),

//     ]);
//   }
export function GetCropYear() {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
      //...authHeader()
    },
  };
  return fetch(`${apiUrl}Common/crop_year`, requestOptions)
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch((error) => {
    });

}

export function GetCompanyName(CompId) {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
      //...authHeader()
    },
  };

  return fetch(`${apiUrl}Common/company_details/companies/${CompId}`, requestOptions)
    //return fetch(  `${apiUrl}CompanyDetails/company_id/B9`, requestOptions)
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch((error) => {
    });

}
export function GetPeanutTypeControlList(pnut_type_id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${apiUrl}ContractManagement/peanut_type_controls/peanutypeid/${pnut_type_id}`, requestOptions)
  .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch((error) => {
    });
}

export function GetPeanutVarietyControlList(cropyear, pnut_type_id, pnut_variety_id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${apiUrl}ContractManagement/peanut_variety_controls/companyid/B9/buyingpointid/null/collectionpointid/null/areaid/null/cropyear/${cropyear}/peanuttypeid/${pnut_type_id}/peanutvarietyid/${pnut_variety_id}`, requestOptions)
  .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch((error) => {
    });
}

export function GetlocationList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${apiUrl}ContractManagement/buying_point_control_details/companies/${'B9'}/cropyears/${'2015'}/functions/${'PN0020'}/subfunctions/${'001'}/users/${'A000543'}/areas/${null}/collectionponts/${null}/buyingpoints/${null}`, requestOptions)

  .then(response => response.json())
    .then(user => {
      return user;
    })
    .catch((error) => {
    });
}
