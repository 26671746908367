/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect from "./SecurityUserLocationSelect";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityUserLocationSelect Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect />
      );
    });
  });
  afterEach(cleanup);
  test("is SecurityUserLocationSelect Loads Successfully", () => {
    expect(screen.getByText("SecurityUserLocationSelect")).toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityUserLocationSelect", () => {
    // START_USER_CODE-USER_SecurityUserLocationSelect_Custom_Test_Case
    // END_USER_CODE-USER_SecurityUserLocationSelect_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_btnCancel"
      )
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxSecurityUserLocationSelect(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityUserLocationSelect = screen.getByTestId(
      "grpbxSecurityUserLocationSelect"
    );
    expect(grpbxSecurityUserLocationSelect.tagName).toBe("BUTTON");
    expect(grpbxSecurityUserLocationSelect.type).toBe("button");
    expect(grpbxSecurityUserLocationSelect.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityUserLocationSelect", () => {
    // START_USER_CODE-USER_grpbxSecurityUserLocationSelect_TEST
    // END_USER_CODE-USER_grpbxSecurityUserLocationSelect_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtKeyInformation(Textbox Widget) Test Cases", async () => {
    const txtKeyInformation = screen.getByTestId("txtKeyInformation");
    expect(txtKeyInformation.tagName).toBe("INPUT");
    expect(txtKeyInformation.type).toBe("text");
    expect(txtKeyInformation.classList).toContain("textboxWidgetClass");
    expect(txtKeyInformation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserLocationSelect_txtKeyInformation"
      )
    );
    await act(async () => {
      userEvent.type(txtKeyInformation, "1");
    });
  });
  test("Custom Test Cases for txtKeyInformation", () => {
    // START_USER_CODE-USER_txtKeyInformation_TEST
    // END_USER_CODE-USER_txtKeyInformation_TEST
  });
});
