/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SpotControlProfile from "./SpotControlProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpotControlProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SpotControlProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is SpotControlProfile Loads Successfully", () => {
    expect(screen.getByText("SpotControlProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpotControlProfile", () => {
    // START_USER_CODE-USER_SpotControlProfile_Custom_Test_Case
    // END_USER_CODE-USER_SpotControlProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SpotControlProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxSpotControlProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxSpotControlProfile = screen.getByTestId(
      "grpbxSpotControlProfile"
    );
    expect(grpbxSpotControlProfile.tagName).toBe("BUTTON");
    expect(grpbxSpotControlProfile.type).toBe("button");
    expect(grpbxSpotControlProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpotControlProfile", () => {
    // START_USER_CODE-USER_grpbxSpotControlProfile_TEST
    // END_USER_CODE-USER_grpbxSpotControlProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_txtBuyingPoint")
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtEffectiveDate(Textbox Widget) Test Cases", async () => {
    const txtEffectiveDate = screen.getByTestId("txtEffectiveDate");
    expect(txtEffectiveDate.tagName).toBe("INPUT");
    expect(txtEffectiveDate.type).toBe("text");
    expect(txtEffectiveDate.classList).toContain("textboxWidgetClass");
    expect(txtEffectiveDate.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_txtEffectiveDate"
      )
    );
    await act(async () => {
      userEvent.type(txtEffectiveDate, "1");
    });
  });
  test("Custom Test Cases for txtEffectiveDate", () => {
    // START_USER_CODE-USER_txtEffectiveDate_TEST
    // END_USER_CODE-USER_txtEffectiveDate_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "1");
    });
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtSeg1Spot(Textbox Widget) Test Cases", async () => {
    const txtSeg1Spot = screen.getByTestId("txtSeg1Spot");
    expect(txtSeg1Spot.tagName).toBe("INPUT");
    expect(txtSeg1Spot.type).toBe("text");
    expect(txtSeg1Spot.classList).toContain("textboxWidgetClass");
    expect(txtSeg1Spot.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg1Spot")
    );
    await act(async () => {
      userEvent.type(txtSeg1Spot, "1");
    });
  });
  test("Custom Test Cases for txtSeg1Spot", () => {
    // START_USER_CODE-USER_txtSeg1Spot_TEST
    // END_USER_CODE-USER_txtSeg1Spot_TEST
  });
  test("txtSeg2EditableSpot(Textbox Widget) Test Cases", async () => {
    const txtSeg2EditableSpot = screen.getByTestId(
      "txtSeg2EditableSpot"
    );
    expect(txtSeg2EditableSpot.tagName).toBe("INPUT");
    expect(txtSeg2EditableSpot.type).toBe("text");
    expect(txtSeg2EditableSpot.classList).toContain("textboxWidgetClass");
    expect(txtSeg2EditableSpot.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg2EditableSpot"
      )
    );
    await act(async () => {
      userEvent.type(txtSeg2EditableSpot, "1");
    });
  });
  test("Custom Test Cases for txtSeg2EditableSpot", () => {
    // START_USER_CODE-USER_txtSeg2EditableSpot_TEST
    // END_USER_CODE-USER_txtSeg2EditableSpot_TEST
  });
  test("txtSeg2OilSpot(Textbox Widget) Test Cases", async () => {
    const txtSeg2OilSpot = screen.getByTestId("txtSeg2OilSpot");
    expect(txtSeg2OilSpot.tagName).toBe("INPUT");
    expect(txtSeg2OilSpot.type).toBe("text");
    expect(txtSeg2OilSpot.classList).toContain("textboxWidgetClass");
    expect(txtSeg2OilSpot.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg2OilSpot"
      )
    );
    await act(async () => {
      userEvent.type(txtSeg2OilSpot, "1");
    });
  });
  test("Custom Test Cases for txtSeg2OilSpot", () => {
    // START_USER_CODE-USER_txtSeg2OilSpot_TEST
    // END_USER_CODE-USER_txtSeg2OilSpot_TEST
  });
  test("txtSeg2Spot(Textbox Widget) Test Cases", async () => {
    const txtSeg2Spot = screen.getByTestId("txtSeg2Spot");
    expect(txtSeg2Spot.tagName).toBe("INPUT");
    expect(txtSeg2Spot.type).toBe("text");
    expect(txtSeg2Spot.classList).toContain("textboxWidgetClass");
    expect(txtSeg2Spot.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg2Spot")
    );
    await act(async () => {
      userEvent.type(txtSeg2Spot, "1");
    });
  });
  test("Custom Test Cases for txtSeg2Spot", () => {
    // START_USER_CODE-USER_txtSeg2Spot_TEST
    // END_USER_CODE-USER_txtSeg2Spot_TEST
  });
  test("txtSeg3EdibleSpot(Textbox Widget) Test Cases", async () => {
    const txtSeg3EdibleSpot = screen.getByTestId("txtSeg3EdibleSpot");
    expect(txtSeg3EdibleSpot.tagName).toBe("INPUT");
    expect(txtSeg3EdibleSpot.type).toBe("text");
    expect(txtSeg3EdibleSpot.classList).toContain("textboxWidgetClass");
    expect(txtSeg3EdibleSpot.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg3EdibleSpot"
      )
    );
    await act(async () => {
      userEvent.type(txtSeg3EdibleSpot, "1");
    });
  });
  test("Custom Test Cases for txtSeg3EdibleSpot", () => {
    // START_USER_CODE-USER_txtSeg3EdibleSpot_TEST
    // END_USER_CODE-USER_txtSeg3EdibleSpot_TEST
  });
  test("txtSeg3OilSpot(Textbox Widget) Test Cases", async () => {
    const txtSeg3OilSpot = screen.getByTestId("txtSeg3OilSpot");
    expect(txtSeg3OilSpot.tagName).toBe("INPUT");
    expect(txtSeg3OilSpot.type).toBe("text");
    expect(txtSeg3OilSpot.classList).toContain("textboxWidgetClass");
    expect(txtSeg3OilSpot.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg3OilSpot"
      )
    );
    await act(async () => {
      userEvent.type(txtSeg3OilSpot, "1");
    });
  });
  test("Custom Test Cases for txtSeg3OilSpot", () => {
    // START_USER_CODE-USER_txtSeg3OilSpot_TEST
    // END_USER_CODE-USER_txtSeg3OilSpot_TEST
  });
  test("txtSeg3Spot(Textbox Widget) Test Cases", async () => {
    const txtSeg3Spot = screen.getByTestId("txtSeg3Spot");
    expect(txtSeg3Spot.tagName).toBe("INPUT");
    expect(txtSeg3Spot.type).toBe("text");
    expect(txtSeg3Spot.classList).toContain("textboxWidgetClass");
    expect(txtSeg3Spot.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlProfile_txtSeg3Spot")
    );
    await act(async () => {
      userEvent.type(txtSeg3Spot, "1");
    });
  });
  test("Custom Test Cases for txtSeg3Spot", () => {
    // START_USER_CODE-USER_txtSeg3Spot_TEST
    // END_USER_CODE-USER_txtSeg3Spot_TEST
  });
});
