import React,{useState} from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./TreeWidget.scss";
function TreeWidget(props) {
  const [state,setState]=useState({
    checked:[],
    expanded:[]
  });
  let conf = props.conf;
  //let screenConf = props.screenConf;
  
    return (
      <div>
        <div style={{float:"left"}}>
        <CheckboxTree 
              id = {conf.name}
              nodes={conf.TreeMetaData} 
              checked={state.checked}
              expanded={state.expanded}
              onCheck={checked=>
              setState(current => {
                return {
                  ...current,
                  checked: checked
                };
              })
            }
              onExpand={expanded=>
              setState(current => {
                return {
                  ...current,
                  expanded: expanded
                };
              })
            }
              onClick={(event)=>{
                event.type="NodeClick";
                event.target={id:conf.name};
                props.onClick(event);
              }}
        />
        </div>
      </div>
    )
  
}

export default TreeWidget;
