import React from "react";
import Form from "react-bootstrap/Form";
const EditableCell = ({
  value: initialValue,
  row,
  column,
  updateGridData, // This is a custom function that we supplied to our table instance
  name,id,placeholder,isInvalid,readOnly,
  title,maxLength,autoComplete,disabled,postAction
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)
  const onChange = e => {
    setValue(e.target.value)
  }

  if(title){
      console.error(title);  
  }
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateGridData(row.rowID$, column.name, value);
    if(column.postAction){
      let postAction = column.postAction;
      postAction(column.name,value);
    }
  }
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  return (<input value={value} onChange={onChange} onBlur={onBlur} 
             name ={name} id={id} placeholder={placeholder}
             key={name} type={column.type=== "PasswordBoxWidget" ? "password" : "text"}
             isinvalid={isInvalid} title={title} as={Form.Control} data-testid={column.name} 
             maxLength={maxLength}  readOnly={readOnly} autoComplete={autoComplete}
             disabled={disabled} className="form-control" 
             /> )
}
export default EditableCell