/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect from "./SecurityUserFunctionSelect";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityUserFunctionSelect Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect />
      );
    });
  });
  afterEach(cleanup);
  test("is SecurityUserFunctionSelect Loads Successfully", () => {
    expect(screen.getByText("SecurityUserFunctionSelect")).toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityUserFunctionSelect", () => {
    // START_USER_CODE-USER_SecurityUserFunctionSelect_Custom_Test_Case
    // END_USER_CODE-USER_SecurityUserFunctionSelect_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect />
      );
    });
  });
  afterEach(cleanup);
  test("btnSUFS_OK(Button Widget) Test Cases", async () => {
    const btnSUFS_OK = screen.getByTestId("btnSUFS_OK");
    expect(btnSUFS_OK).toBeInTheDocument;
    expect(btnSUFS_OK.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_btnSUFS_OK")
    );
  });
  test("Custom Test Cases for btnSUFS_OK", () => {
    // START_USER_CODE-USER_btnSUFS_OK_TEST
    // END_USER_CODE-USER_btnSUFS_OK_TEST
  });
  test("btnAddLocation(Button Widget) Test Cases", async () => {
    const btnAddLocation = screen.getByTestId("btnAddLocation");
    expect(btnAddLocation).toBeInTheDocument;
    expect(btnAddLocation.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_btnAddLocation"
      )
    );
  });
  test("Custom Test Cases for btnAddLocation", () => {
    // START_USER_CODE-USER_btnAddLocation_TEST
    // END_USER_CODE-USER_btnAddLocation_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_btnCancel"
      )
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCopy(Button Widget) Test Cases", async () => {
    const btnCopy = screen.getByTestId("btnCopy");
    expect(btnCopy).toBeInTheDocument;
    expect(btnCopy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_btnCopy")
    );
  });
  test("Custom Test Cases for btnCopy", () => {
    // START_USER_CODE-USER_btnCopy_TEST
    // END_USER_CODE-USER_btnCopy_TEST
  });
  test("btnDeleteLocation(Button Widget) Test Cases", async () => {
    const btnDeleteLocation = screen.getByTestId("btnDeleteLocation");
    expect(btnDeleteLocation).toBeInTheDocument;
    expect(btnDeleteLocation.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_btnDeleteLocation"
      )
    );
  });
  test("Custom Test Cases for btnDeleteLocation", () => {
    // START_USER_CODE-USER_btnDeleteLocation_TEST
    // END_USER_CODE-USER_btnDeleteLocation_TEST
  });
  test("btnEditLocation(Button Widget) Test Cases", async () => {
    const btnEditLocation = screen.getByTestId("btnEditLocation");
    expect(btnEditLocation).toBeInTheDocument;
    expect(btnEditLocation.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_btnEditLocation"
      )
    );
  });
  test("Custom Test Cases for btnEditLocation", () => {
    // START_USER_CODE-USER_btnEditLocation_TEST
    // END_USER_CODE-USER_btnEditLocation_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCopyToUserID(GroupBox Widget) Test Cases", async () => {
    const grpbxCopyToUserID = screen.getByTestId("grpbxCopyToUserID");
    expect(grpbxCopyToUserID.tagName).toBe("BUTTON");
    expect(grpbxCopyToUserID.type).toBe("button");
    expect(grpbxCopyToUserID.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCopyToUserID", () => {
    // START_USER_CODE-USER_grpbxCopyToUserID_TEST
    // END_USER_CODE-USER_grpbxCopyToUserID_TEST
  });
  test("grpbxSecurityUserFunctionSelect(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityUserFunctionSelect = screen.getByTestId(
      "grpbxSecurityUserFunctionSelect"
    );
    expect(grpbxSecurityUserFunctionSelect.tagName).toBe("BUTTON");
    expect(grpbxSecurityUserFunctionSelect.type).toBe("button");
    expect(grpbxSecurityUserFunctionSelect.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityUserFunctionSelect", () => {
    // START_USER_CODE-USER_grpbxSecurityUserFunctionSelect_TEST
    // END_USER_CODE-USER_grpbxSecurityUserFunctionSelect_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCopyToUser(Label Widget) Test Cases", async () => {
    const lblCopyToUser = screen.getByTestId("lblCopyToUser");
    expect(lblCopyToUser.tagName).toBe("LABEL");
    expect(lblCopyToUser.classList).toContain("form-label");
    expect(lblCopyToUser.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_lblCopyToUser"
      )
    );
  });
  test("Custom Test Cases for lblCopyToUser", () => {
    // START_USER_CODE-USER_lblCopyToUser_TEST
    // END_USER_CODE-USER_lblCopyToUser_TEST
  });
  test("lblCopyToUserID(Label Widget) Test Cases", async () => {
    const lblCopyToUserID = screen.getByTestId("lblCopyToUserID");
    expect(lblCopyToUserID.tagName).toBe("LABEL");
    expect(lblCopyToUserID.classList).toContain("form-label");
    expect(lblCopyToUserID.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_lblCopyToUserID"
      )
    );
  });
  test("Custom Test Cases for lblCopyToUserID", () => {
    // START_USER_CODE-USER_lblCopyToUserID_TEST
    // END_USER_CODE-USER_lblCopyToUserID_TEST
  });
  test("txtKeyInformation(Textbox Widget) Test Cases", async () => {
    const txtKeyInformation = screen.getByTestId("txtKeyInformation");
    expect(txtKeyInformation.tagName).toBe("INPUT");
    expect(txtKeyInformation.type).toBe("text");
    expect(txtKeyInformation.classList).toContain("textboxWidgetClass");
    expect(txtKeyInformation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionSelect_txtKeyInformation"
      )
    );
    await act(async () => {
      userEvent.type(txtKeyInformation, "1");
    });
  });
  test("Custom Test Cases for txtKeyInformation", () => {
    // START_USER_CODE-USER_txtKeyInformation_TEST
    // END_USER_CODE-USER_txtKeyInformation_TEST
  });
});
