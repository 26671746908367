/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ViewWRPremiumDeductions from "./ViewWRPremiumDeductions";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewWRPremiumDeductions Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewWRPremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("is ViewWRPremiumDeductions Loads Successfully", () => {
    expect(screen.getByText("ViewWRPremiumDeductions")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewWRPremiumDeductions", () => {
    // START_USER_CODE-USER_ViewWRPremiumDeductions_Custom_Test_Case
    // END_USER_CODE-USER_ViewWRPremiumDeductions_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewWRPremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:ViewWRPremiumDeductions_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridPremsDeducts_btncol1(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_btncol1 = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeducts_btncol1btn =
      gridPremsDeducts_btncol1.nextElementSibling.firstElementChild;
    gridPremsDeducts_btncol1 =
      gridPremsDeducts_btncol1.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts_btncol1.tagName).toBe("DIV");
    expect(gridPremsDeducts_btncol1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRPremiumDeductions_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncol1", () => {
    // START_USER_CODE-USER_btncol1_TEST
    // END_USER_CODE-USER_btncol1_TEST
  });
  test("gridPremsDeducts(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeductsbtn =
      gridPremsDeducts.nextElementSibling.firstElementChild;
    gridPremsDeducts =
      gridPremsDeducts.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts.tagName).toBe("DIV");
    expect(gridPremsDeducts.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRPremiumDeductions_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremsDeducts", () => {
    // START_USER_CODE-USER_gridPremsDeducts_TEST
    // END_USER_CODE-USER_gridPremsDeducts_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxViewWRPremiumDeductions(GroupBox Widget) Test Cases", async () => {
    const grpbxViewWRPremiumDeductions = screen.getByTestId(
      "grpbxViewWRPremiumDeductions"
    );
    expect(grpbxViewWRPremiumDeductions.tagName).toBe("BUTTON");
    expect(grpbxViewWRPremiumDeductions.type).toBe("button");
    expect(grpbxViewWRPremiumDeductions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewWRPremiumDeductions", () => {
    // START_USER_CODE-USER_grpbxViewWRPremiumDeductions_TEST
    // END_USER_CODE-USER_grpbxViewWRPremiumDeductions_TEST
  });
  test("lblPremsDeducts(Label Widget) Test Cases", async () => {
    const lblPremsDeducts = screen.getByTestId("lblPremsDeducts");
    expect(lblPremsDeducts.tagName).toBe("LABEL");
    expect(lblPremsDeducts.classList).toContain("form-label");
    expect(lblPremsDeducts.textContent).toEqual(
      t("WarehouseReceipts:ViewWRPremiumDeductions_lblPremsDeducts")
    );
  });
  test("Custom Test Cases for lblPremsDeducts", () => {
    // START_USER_CODE-USER_lblPremsDeducts_TEST
    // END_USER_CODE-USER_lblPremsDeducts_TEST
  });
  test("gridPremsDeducts_txtcolAmount(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolAmount = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeducts_txtcolAmountbtn =
      gridPremsDeducts_txtcolAmount.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolAmount =
      gridPremsDeducts_txtcolAmount.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts_txtcolAmount.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolAmount.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRPremiumDeductions_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmount", () => {
    // START_USER_CODE-USER_txtcolAmount_TEST
    // END_USER_CODE-USER_txtcolAmount_TEST
  });
  test("gridPremsDeducts_txtcolPremDeduct(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolPremDeduct = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolPremDeductbtn =
      gridPremsDeducts_txtcolPremDeduct.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolPremDeduct =
      gridPremsDeducts_txtcolPremDeduct.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolPremDeduct.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolPremDeduct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRPremiumDeductions_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPremDeduct", () => {
    // START_USER_CODE-USER_txtcolPremDeduct_TEST
    // END_USER_CODE-USER_txtcolPremDeduct_TEST
  });
  test("gridPremsDeducts_txtcolTotal(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolTotal = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeducts_txtcolTotalbtn =
      gridPremsDeducts_txtcolTotal.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolTotal =
      gridPremsDeducts_txtcolTotal.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts_txtcolTotal.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolTotal.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRPremiumDeductions_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotal", () => {
    // START_USER_CODE-USER_txtcolTotal_TEST
    // END_USER_CODE-USER_txtcolTotal_TEST
  });
  test("gridPremsDeducts_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolVendor = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeducts_txtcolVendorbtn =
      gridPremsDeducts_txtcolVendor.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolVendor =
      gridPremsDeducts_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts_txtcolVendor.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRPremiumDeductions_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("txtSegVendor1(Textbox Widget) Test Cases", async () => {
    const txtSegVendor1 = screen.getByTestId("txtSegVendor1");
    expect(txtSegVendor1.tagName).toBe("INPUT");
    expect(txtSegVendor1.type).toBe("text");
    expect(txtSegVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegVendor1, "123");
    });
    expect(txtSegVendor1.getAttribute("value")).toBe("");
    expect(txtSegVendor1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSegVendor1", () => {
    // START_USER_CODE-USER_txtSegVendor1_TEST
    // END_USER_CODE-USER_txtSegVendor1_TEST
  });
  test("txtSegVendor2(Textbox Widget) Test Cases", async () => {
    const txtSegVendor2 = screen.getByTestId("txtSegVendor2");
    expect(txtSegVendor2.tagName).toBe("INPUT");
    expect(txtSegVendor2.type).toBe("text");
    expect(txtSegVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegVendor2, "1");
    });
  });
  test("Custom Test Cases for txtSegVendor2", () => {
    // START_USER_CODE-USER_txtSegVendor2_TEST
    // END_USER_CODE-USER_txtSegVendor2_TEST
  });
  test("txtSegVendor3(Textbox Widget) Test Cases", async () => {
    const txtSegVendor3 = screen.getByTestId("txtSegVendor3");
    expect(txtSegVendor3.tagName).toBe("INPUT");
    expect(txtSegVendor3.type).toBe("text");
    expect(txtSegVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegVendor3, "1");
    });
  });
  test("Custom Test Cases for txtSegVendor3", () => {
    // START_USER_CODE-USER_txtSegVendor3_TEST
    // END_USER_CODE-USER_txtSegVendor3_TEST
  });
});
