/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_WeigherProfile from "./WeigherProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WeigherProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WeigherProfile />);
    });
  });
  afterEach(cleanup);
  test("is WeigherProfile Loads Successfully", () => {
    expect(screen.getByText("WeigherProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for WeigherProfile", () => {
    // START_USER_CODE-USER_WeigherProfile_Custom_Test_Case
    // END_USER_CODE-USER_WeigherProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WeigherProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WeigherProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WeigherProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxWeigherProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxWeigherProfile = screen.getByTestId("grpbxWeigherProfile");
    expect(grpbxWeigherProfile.tagName).toBe("BUTTON");
    expect(grpbxWeigherProfile.type).toBe("button");
    expect(grpbxWeigherProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeigherProfile", () => {
    // START_USER_CODE-USER_grpbxWeigherProfile_TEST
    // END_USER_CODE-USER_grpbxWeigherProfile_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WeigherProfile_lblBuyingPoint")
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblBuyingPointValue(Label Widget) Test Cases", async () => {
    const lblBuyingPointValue = screen.getByTestId("lblBuyingPointValue");
    expect(lblBuyingPointValue.tagName).toBe("LABEL");
    expect(lblBuyingPointValue.classList).toContain("form-label");
    expect(lblBuyingPointValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WeigherProfile_lblBuyingPointValue")
    );
  });
  test("Custom Test Cases for lblBuyingPointValue", () => {
    // START_USER_CODE-USER_lblBuyingPointValue_TEST
    // END_USER_CODE-USER_lblBuyingPointValue_TEST
  });
  test("lblWeigher(Label Widget) Test Cases", async () => {
    const lblWeigher = screen.getByTestId("lblWeigher");
    expect(lblWeigher.tagName).toBe("LABEL");
    expect(lblWeigher.classList).toContain("form-label");
    expect(lblWeigher.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WeigherProfile_lblWeigher")
    );
  });
  test("Custom Test Cases for lblWeigher", () => {
    // START_USER_CODE-USER_lblWeigher_TEST
    // END_USER_CODE-USER_lblWeigher_TEST
  });
  test("txtWeigher(Textbox Widget) Test Cases", async () => {
    const txtWeigher = screen.getByTestId("txtWeigher");
    expect(txtWeigher.tagName).toBe("INPUT");
    expect(txtWeigher.type).toBe("text");
    expect(txtWeigher.classList).toContain("textboxWidgetClass");
    expect(txtWeigher.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WeigherProfile_txtWeigher")
    );
    await act(async () => {
      userEvent.type(txtWeigher, "1");
    });
  });
  test("Custom Test Cases for txtWeigher", () => {
    // START_USER_CODE-USER_txtWeigher_TEST
    // END_USER_CODE-USER_txtWeigher_TEST
  });
});
