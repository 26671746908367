/* eslint-disable*/
import React from "react";
import ContractManagement_ExceptPremiumAdjustments from "./ExceptPremiumAdjustments";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExceptPremiumAdjustments Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptPremiumAdjustments />);
    });
  });
  afterEach(cleanup);
  test("is ExceptPremiumAdjustments Loads Successfully", () => {
    expect(screen.getByText("ExceptPremiumAdjustments")).toBeInTheDocument;
  });
  test("Custom Test Cases for ExceptPremiumAdjustments", () => {
    // START_USER_CODE-USER_ExceptPremiumAdjustments_Custom_Test_Case
    // END_USER_CODE-USER_ExceptPremiumAdjustments_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptPremiumAdjustments />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxLabels = screen.getByTestId("grpbxLabels");
    expect(grpbxLabels.tagName).toBe("BUTTON");
    expect(grpbxLabels.type).toBe("button");
    expect(grpbxLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLabels", () => {
    // START_USER_CODE-USER_grpbxLabels_TEST
    // END_USER_CODE-USER_grpbxLabels_TEST
  });
  test("grpbxRateRatebase(GroupBox Widget) Test Cases", async () => {
    const grpbxRateRatebase = screen.getByTestId("grpbxRateRatebase");
    expect(grpbxRateRatebase.tagName).toBe("BUTTON");
    expect(grpbxRateRatebase.type).toBe("button");
    expect(grpbxRateRatebase.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRateRatebase", () => {
    // START_USER_CODE-USER_grpbxRateRatebase_TEST
    // END_USER_CODE-USER_grpbxRateRatebase_TEST
  });
  test("grpbxReqDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxReqDetails = screen.getByTestId("grpbxReqDetails");
    expect(grpbxReqDetails.tagName).toBe("BUTTON");
    expect(grpbxReqDetails.type).toBe("button");
    expect(grpbxReqDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReqDetails", () => {
    // START_USER_CODE-USER_grpbxReqDetails_TEST
    // END_USER_CODE-USER_grpbxReqDetails_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_txtLocation")
    );
    await act(async () => {
      userEvent.type(txtLocation, "123");
    });
    expect(txtLocation.getAttribute("value")).toBe("");
    expect(txtLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtLocationID(Textbox Widget) Test Cases", async () => {
    const txtLocationID = screen.getByTestId("txtLocationID");
    expect(txtLocationID.tagName).toBe("INPUT");
    expect(txtLocationID.type).toBe("text");
    expect(txtLocationID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLocationID, "1");
    });
  });
  test("Custom Test Cases for txtLocationID", () => {
    // START_USER_CODE-USER_txtLocationID_TEST
    // END_USER_CODE-USER_txtLocationID_TEST
  });
  test("txtPayRequest(Textbox Widget) Test Cases", async () => {
    const txtPayRequest = screen.getByTestId("txtPayRequest");
    expect(txtPayRequest.tagName).toBe("INPUT");
    expect(txtPayRequest.type).toBe("text");
    expect(txtPayRequest.classList).toContain("textboxWidgetClass");
    expect(txtPayRequest.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_txtPayRequest")
    );
    await act(async () => {
      userEvent.type(txtPayRequest, "123");
    });
    expect(txtPayRequest.getAttribute("value")).toBe("");
    expect(txtPayRequest.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPayRequest", () => {
    // START_USER_CODE-USER_txtPayRequest_TEST
    // END_USER_CODE-USER_txtPayRequest_TEST
  });
  test("txtPremiumCode(Textbox Widget) Test Cases", async () => {
    const txtPremiumCode = screen.getByTestId("txtPremiumCode");
    expect(txtPremiumCode.tagName).toBe("INPUT");
    expect(txtPremiumCode.type).toBe("text");
    expect(txtPremiumCode.classList).toContain("textboxWidgetClass");
    expect(txtPremiumCode.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_txtPremiumCode")
    );
    await act(async () => {
      userEvent.type(txtPremiumCode, "123");
    });
    expect(txtPremiumCode.getAttribute("value")).toBe("");
    expect(txtPremiumCode.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPremiumCode", () => {
    // START_USER_CODE-USER_txtPremiumCode_TEST
    // END_USER_CODE-USER_txtPremiumCode_TEST
  });
  test("txtPremiumCodeID(Textbox Widget) Test Cases", async () => {
    const txtPremiumCodeID = screen.getByTestId("txtPremiumCodeID");
    expect(txtPremiumCodeID.tagName).toBe("INPUT");
    expect(txtPremiumCodeID.type).toBe("text");
    expect(txtPremiumCodeID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPremiumCodeID, "1");
    });
  });
  test("Custom Test Cases for txtPremiumCodeID", () => {
    // START_USER_CODE-USER_txtPremiumCodeID_TEST
    // END_USER_CODE-USER_txtPremiumCodeID_TEST
  });
  test("txtRate(Textbox Widget) Test Cases", async () => {
    const txtRate = screen.getByTestId("txtRate");
    expect(txtRate.tagName).toBe("INPUT");
    expect(txtRate.type).toBe("text");
    expect(txtRate.classList).toContain("textboxWidgetClass");
    expect(txtRate.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_txtRate")
    );
    await act(async () => {
      userEvent.type(txtRate, "1");
    });
  });
  test("Custom Test Cases for txtRate", () => {
    // START_USER_CODE-USER_txtRate_TEST
    // END_USER_CODE-USER_txtRate_TEST
  });
  test("txtRatebaseID(Textbox Widget) Test Cases", async () => {
    const txtRatebaseID = screen.getByTestId("txtRatebaseID");
    expect(txtRatebaseID.tagName).toBe("INPUT");
    expect(txtRatebaseID.type).toBe("text");
    expect(txtRatebaseID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRatebaseID, "1");
    });
  });
  test("Custom Test Cases for txtRatebaseID", () => {
    // START_USER_CODE-USER_txtRatebaseID_TEST
    // END_USER_CODE-USER_txtRatebaseID_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "123");
    });
    expect(txtStatus.getAttribute("value")).toBe("");
    expect(txtStatus.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremiumAdjustments_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorID(Textbox Widget) Test Cases", async () => {
    const txtVendorID = screen.getByTestId("txtVendorID");
    expect(txtVendorID.tagName).toBe("INPUT");
    expect(txtVendorID.type).toBe("text");
    expect(txtVendorID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendorID, "1");
    });
  });
  test("Custom Test Cases for txtVendorID", () => {
    // START_USER_CODE-USER_txtVendorID_TEST
    // END_USER_CODE-USER_txtVendorID_TEST
  });
});
