/* eslint-disable*/
import React from "react";
import Form from "react-bootstrap/Form";
import $ from "jquery";
const CheckBox = ({
  initialValue,
  row,
  column,
  updateGridData, // This is a custom function that we supplied to our table instance
  name,id,placeholder,isInvalid,readOnly,
  title,maxLength,autoComplete,disabled,nextTabName
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)
  const onChange = e => {
    setValue(e.target.checked);
    $(e.target).focus();
  }

  if(title){
      console.error(title);  
  }
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
   // updateGridData(row.rowID$, column.name, value);
  }
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    let  isChecked = false;
    if(initialValue==="true" || initialValue===true)
    {
        isChecked=true;
    }
    setValue(isChecked)
  }, [initialValue])
  return (<div className="form-check test"><input value={value} onChange={onChange} onBlur={onBlur} checked={value}
             name ={name} id={id} 
             key={name} type="checkbox" className="form-check-input position-static" 
             title={title}  data-testid={column.name} 
             readOnly={readOnly}
             disabled={disabled}  
             /> </div>)
}
export default CheckBox