/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_BankHolidaySetup from "./BankHolidaySetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("BankHolidaySetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_BankHolidaySetup />);
    });
  });
  afterEach(cleanup);
  test("is BankHolidaySetup Loads Successfully", () => {
    expect(screen.getByText("BankHolidaySetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for BankHolidaySetup", () => {
    // START_USER_CODE-USER_BankHolidaySetup_Custom_Test_Case
    // END_USER_CODE-USER_BankHolidaySetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_BankHolidaySetup />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidaySetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidaySetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidaySetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("grpbxBankHolidaySetup(GroupBox Widget) Test Cases", async () => {
    const grpbxBankHolidaySetup = screen.getByTestId("grpbxBankHolidaySetup");
    expect(grpbxBankHolidaySetup.tagName).toBe("BUTTON");
    expect(grpbxBankHolidaySetup.type).toBe("button");
    expect(grpbxBankHolidaySetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBankHolidaySetup", () => {
    // START_USER_CODE-USER_grpbxBankHolidaySetup_TEST
    // END_USER_CODE-USER_grpbxBankHolidaySetup_TEST
  });
});
