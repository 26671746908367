/* eslint-disable*/
import React from "react";
import DropDownWidget from "./DropDownWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("DropDownWidget Test Cases :1", () => {
  beforeEach(async () => {
    act(() => {
      let dropDownWidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select",
        name: "dropDownWidget1",
        type: "dropDownWidget1",
        parent: "groupboxwidget0",
        Label: "dropDownWidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <DropDownWidget
          values={values}
          onBlur={onBlur}
          conf={dropDownWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("DropDown Widget Testing  Basic", async () => {
    act(() => {
      const dropDownWidget1 = screen.getByTestId("dropDownWidget1").firstChild;
      expect(dropDownWidget1.tagName).toBe("BUTTON");
      expect(dropDownWidget1.type).toBe("button");
      expect(dropDownWidget1.id).toBe("dropDownWidget1");
      expect(dropDownWidget1.textContent).toBe("Select");
      fireEvent.blur(dropDownWidget1, { target: { id: "dropDownWidget1" } });
      expect(dropDownWidget1.id).toBe("dropDownWidget1");
      const select = screen.getByText("Select");
      fireEvent.click(select, { button: 0 });
      const btn = screen.getByRole("button");
      fireEvent.select(btn, { button: 0 });
    });
  });
});
describe("DropDownWidget Test Cases :2", () => {
  beforeEach(async () => {
    act(() => {
      let dropDownWidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select",
        name: "dropDownWidget1",
        type: "dropDownWidget1",
        parent: "groupboxwidget0",
        Label: "dropDownWidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = {dropDownWidget1 : "some text"};
      let touched = {dropDownWidget1 : "some text"};
      let onBlur = jest.fn();
      renderTestScreen(
        <DropDownWidget
          values={values}
          onBlur={onBlur}
          conf={dropDownWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("DropDown Widget Testing  Basic", async () => {
    act(() => {
      const dropDownWidget1 = screen.getByTestId("dropDownWidget1").firstChild;
      expect(dropDownWidget1.tagName).toBe("BUTTON");
      expect(dropDownWidget1.type).toBe("button");
      expect(dropDownWidget1.id).toBe("dropDownWidget1");
      expect(dropDownWidget1.textContent).toBe("Select");
      fireEvent.blur(dropDownWidget1, { target: { id: "dropDownWidget1" } });
      expect(dropDownWidget1.id).toBe("dropDownWidget1");
      const select = screen.getByText("Select");
      fireEvent.click(select, { button: 0 });
      const btn = screen.getByRole("button");
      fireEvent.select(btn, { button: 0 });
    });
  });
});
