/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setData,
  setValue,
  disable,
  getData,
  getValue,
  goTo,
  enable,
} from "../../shared/WindowImports";

import "./SecurityGroup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// import { getData } from "../../Common/ApiService";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityGroup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let user_id = sessionStorage.getItem("userid"); 
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityGroup",
    windowName: "SecurityGroup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityGroup",
    // START_USER_CODE-USER_SecurityGroup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Security Groups",
      scrCode: "PN0060D",
    },
    // END_USER_CODE-USER_SecurityGroup_PROPERTIES
    btnSGAdd: {
      name: "btnSGAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSGAdd_PROPERTIES

      // END_USER_CODE-USER_btnSGAdd_PROPERTIES
    },
    btnAddFunction: {
      name: "btnAddFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddFunction_PROPERTIES

      // END_USER_CODE-USER_btnAddFunction_PROPERTIES
    },
    btnAddLocation: {
      name: "btnAddLocation",
      type: "ButtonWidget",
      parent: "grpbxLocations",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddLocation_PROPERTIES

      // END_USER_CODE-USER_btnAddLocation_PROPERTIES
    },
    btnAddUser: {
      name: "btnAddUser",
      type: "ButtonWidget",
      parent: "grpbxUsers",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddUser_PROPERTIES

      // END_USER_CODE-USER_btnAddUser_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDeleteFunction: {
      name: "btnDeleteFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteFunction_PROPERTIES

      // END_USER_CODE-USER_btnDeleteFunction_PROPERTIES
    },
    btnDeleteLocation: {
      name: "btnDeleteLocation",
      type: "ButtonWidget",
      parent: "grpbxLocations",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteLocation_PROPERTIES

      // END_USER_CODE-USER_btnDeleteLocation_PROPERTIES
    },
    btnDeleteUser: {
      name: "btnDeleteUser",
      type: "ButtonWidget",
      parent: "grpbxUsers",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteUser_PROPERTIES

      // END_USER_CODE-USER_btnDeleteUser_PROPERTIES
    },
    btnEditFunction: {
      name: "btnEditFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditFunction_PROPERTIES

      // END_USER_CODE-USER_btnEditFunction_PROPERTIES
    },
    btnEditLocation: {
      name: "btnEditLocation",
      type: "ButtonWidget",
      parent: "grpbxLocations",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditLocation_PROPERTIES

      // END_USER_CODE-USER_btnEditLocation_PROPERTIES
    },
    ddAuthorityLevel: {
      name: "ddAuthorityLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityGroup",
      Label: "Authority Level:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAuthorityLevel_PROPERTIES

      // END_USER_CODE-USER_ddAuthorityLevel_PROPERTIES
    },
    grpbxFunctions: {
      name: "grpbxFunctions",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityGroup",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFunctions_PROPERTIES

      // END_USER_CODE-USER_grpbxFunctions_PROPERTIES
    },
    grpbxLocations: {
      name: "grpbxLocations",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityGroup",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxLocations_PROPERTIES

      // END_USER_CODE-USER_grpbxLocations_PROPERTIES
    },
    grpbxUsers: {
      name: "grpbxUsers",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityGroup",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxUsers_PROPERTIES

      // END_USER_CODE-USER_grpbxUsers_PROPERTIES
    },
    lstUsers: {
      name: "lstUsers",
      type: "ListBoxFieldWidget",
      parent: "grpbxUsers",
      ColSpan: "3",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstUsers_PROPERTIES

      // END_USER_CODE-USER_lstUsers_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblFunctions: {
      name: "lblFunctions",
      type: "LabelWidget",
      parent: "grpbxFunctions",
      Label: "Functions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFunctions_PROPERTIES

      // END_USER_CODE-USER_lblFunctions_PROPERTIES
    },
    lblLocations: {
      name: "lblLocations",
      type: "LabelWidget",
      parent: "grpbxLocations",
      Label: "Locations:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocations_PROPERTIES

      // END_USER_CODE-USER_lblLocations_PROPERTIES
    },
    lblUsers: {
      name: "lblUsers",
      type: "LabelWidget",
      parent: "grpbxUsers",
      Label: "Users:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUsers_PROPERTIES

      // END_USER_CODE-USER_lblUsers_PROPERTIES
    },
    lstFunctions: {
      name: "lstFunctions",
      type: "ListBoxFieldWidget",
      parent: "grpbxFunctions",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFunctions_PROPERTIES

      // END_USER_CODE-USER_lstFunctions_PROPERTIES
    },
    lstLocations: {
      name: "lstLocations",
      type: "ListBoxFieldWidget",
      parent: "grpbxLocations",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLocations_PROPERTIES

      // END_USER_CODE-USER_lstLocations_PROPERTIES
    },
    txtSecurityGroup: {
      name: "txtSecurityGroup",
      type: "TextBoxWidget",
      parent: "grpbxSecurityGroup",
      Label: "Security Group:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSecurityGroup_PROPERTIES

      // END_USER_CODE-USER_txtSecurityGroup_PROPERTIES
    },
    grpbxSecurityGroup: {
      name: "grpbxSecurityGroup",
      type: "GroupBoxWidget",
      parent: "SecurityGroup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSecurityGroup_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityGroup_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityGroup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#SecurityGroupUserSelect":{},
        "SystemMaintenanceSpecialFunctions#SecurityFunctionSelect":{},
       },
      REVERSE:{
        "SystemMaintenanceSpecialFunctions#SecurityGroupUserSelect":{},
        "SystemMaintenanceSpecialFunctions#SecurityFunctionSelect":{},

      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAddUser: {
      DEFAULT: ["SystemMaintenanceSpecialFunctions#SecurityGroupUserSelect#DEFAULT#true#Click"],
    },
    btnAddFunction: {
      DEFAULT: ["SystemMaintenanceSpecialFunctions#SecurityFunctionSelect#DEFAULT#true#Click"],
    },
    // btnEditFunction: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#SecurityFunctionSelect#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
    if (dataObj[0].cmdOkCaption == 'Update') {
      let sgLocationData = getData(thisObj, 'frmSecurityGroup')
      if (sgLocationData !== null) {
        bFillLocationList(sgLocationData.security_group, cropYear)
      }
    }
  }, [getData(thisObj, 'frmSecurityGroup')])

  useEffect(() => {
    let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
    if (dataObj[0].cmdOkCaption == 'Update') {
      let sgUserData = getData(thisObj, 'frmSecurityGrp')
      if (sgUserData !== null) {
        bFillUserList(compId, sgUserData.Securitygrp)
        return;
      }
    }
  }, [getData(thisObj, 'frmSecurityGrp')])

  useEffect(() => {
    let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
    if (dataObj[0].cmdOkCaption == 'Update') {
      let sgFunctionData = getData(thisObj, 'frmSecurityGroup1')
      if (sgFunctionData !== null) {
        bFillFunctionList(compId, sgFunctionData.security_group)
        return;
      }
    }
  }, [getData(thisObj, 'frmSecurityGroup1')])

  // START_USER_CODE-USER_METHODS

  //Function to convert to Pascal Case
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  // FormLoad function
  const formLoad = async() => {
    try {
      let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
      if (dataObj[0].cmdOkCaption == 'Add') {
        document.getElementById('btnSGAdd').innerText = "Add"
        await bFillAuthorityLevel()
        await EnableDisableControls()
        setLoading(false)
      }
      else if (dataObj[0].cmdOkCaption == 'Update') {
        let dataLength = document.getElementsByClassName('btnSGAddButton').length
        for(var i = 0; i < dataLength; i++) {
          if (document.getElementsByClassName('btnSGAddButton')[i] != undefined) {
            document.getElementsByClassName('btnSGAddButton')[i].innerText = 'Update'
          }
        }
        document.getElementById('btnSGAdd').innerText = "Update"
        setValue(thisObj, 'txtSecurityGroup', dataObj[0].txtGroupText)
        disable(thisObj, 'txtSecurityGroup')
        let authorityLevelText = ''
        if (dataObj[0].cboAuthorityLevelText == 'None') {
          authorityLevelText = ''
        }
        else if (dataObj[0].cboAuthorityLevelText == 'Area') {
          authorityLevelText = 'AR'
        }
        else if (dataObj[0].cboAuthorityLevelText == 'Collection Point') {
          authorityLevelText = 'CP'
        }
        else if (dataObj[0].cboAuthorityLevelText == 'Buying Point') {
          authorityLevelText = 'BP'
        }
        setValue(thisObj, 'lblAddedByValue', dataObj[0].lblAddedByCaption)
        setValue(thisObj, 'lblChangedByValue', dataObj[0].lblChangedByCaption)
        await bFillAuthorityLevel(authorityLevelText)
        disable(thisObj, 'ddAuthorityLevel')
        await bFillUserList(compId, dataObj[0].txtGroupText)
        await bFillFunctionList(compId, dataObj[0].txtGroupText)
        await bFillLocationList(dataObj[0].txtGroupText, cropYear)
        await EnableDisableControls()
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  // EnableDisableControls function
  const EnableDisableControls = async () => {
    try {
      let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
      let txtGroup = dataObj[0].txtGroupEnabled
      let funcList = getData(thisObj, 'lstfunctionsLength')
      let locList = getData(thisObj, 'lstLocationsLength')
      let usersList = getData(thisObj, 'lstUsersLength')

      if (txtGroup == true) {
        disable(thisObj, 'lstFunctions')
        disable(thisObj, 'lstLocations')
        disable(thisObj, 'lstUsers')
        disable(thisObj, 'btnAddUser')
        disable(thisObj, 'btnDeleteUser')
        disable(thisObj, 'btnAddFunction')
        disable(thisObj, 'btnEditFunction')
        disable(thisObj, 'btnDeleteFunction')
        disable(thisObj, 'btnAddLocation')
        disable(thisObj, 'btnEditLocation')
        disable(thisObj, 'btnDeleteLocation')
      }
      else {
        enable(thisObj, 'btnAddUser')
        enable(thisObj, 'btnAddFunction')
        enable(thisObj, 'btnAddLocation')

        if (funcList !== null) {
          if (funcList.length > 0) {
            enable(thisObj, 'lstFunctions')
            enable(thisObj, 'btnEditFunction')
            enable(thisObj, 'btnDeleteFunction')
          }
          else {
            disable(thisObj, 'lstFunctions')
            disable(thisObj, 'btnEditFunction')
            disable(thisObj, 'btnDeleteFunction')
          }
        }
        else {
          disable(thisObj, 'lstFunctions')
          disable(thisObj, 'btnEditFunction')
          disable(thisObj, 'btnDeleteFunction')
        }
        if (locList !== null) {
          if (locList.length > 0) {
            enable(thisObj, 'lstLocations')
            enable(thisObj, 'btnEditLocation')
            enable(thisObj, 'btnDeleteLocation')
          }
          else {
            disable(thisObj, 'lstLocations')
            disable(thisObj, 'btnEditLocation')
            disable(thisObj, 'btnDeleteLocation')
          }
        }
        else {
          disable(thisObj, 'lstLocations')
          disable(thisObj, 'btnEditLocation')
          disable(thisObj, 'btnDeleteLocation')
        }
        if (usersList !== null) {
          if (usersList.length > 0) {
            enable(thisObj, 'lstUsers')
            enable(thisObj, 'btnDeleteUser')
          }
          else {
            disable(thisObj, 'lstUsers')
            disable(thisObj, 'btnDeleteUser')
          }
        }
        else {
          disable(thisObj, 'lstUsers')
          disable(thisObj, 'btnDeleteUser')
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in EnableDisableControls function`
        );
      }
      return false;
    }
  }

  // Authority Level dropdown binding
  const bFillAuthorityLevel = async (cboAuthorityLevelText) => {
    try {
      let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
      let js = []
      js.push({ key: '', description: 'None' })
      js.push({ key: 'AR', description: 'Area' })
      js.push({ key: 'CP', description: 'Collection Point' })
      js.push({ key: 'BP', description: 'Buying Point' })

      thisObj.setState(current => {
        return {
          ...current,
          ddAuthorityLevel: {
            ...state['ddAuthorityLevel'],
            valueList: js
          }
        }
      })
      if (dataObj[0].cmdOkCaption == 'Add') {
        setValue(thisObj, 'ddAuthorityLevel', js[0].key)
        return
      }
      else {
        setValue(thisObj, 'ddAuthorityLevel', cboAuthorityLevelText)
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in bFillAuthorityLevel dropdown`
        );
      }
      return false;
    }
  };

  // Users list binding
  const bFillUserList = async (compId, security_group) => {
    try {
      setLoading(true)
      let userListData = getData(thisObj, 'userListData')
      let js = []
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveUserControlList(compId, security_group)
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].user_id, description: data[i].user_id + ' - ' + data[i].user_name, index: i }
          js.push(obj)
        }
      }
      setData(thisObj, "lstUsersLength", js)
      thisObj.setState(current => {
        return {
          ...current,
          lstUsers: {
            ...state["lstUsers"],
            valueList: js,
          }
        }
      })
      if (js.length <= 0) {
        return;
      }
      else if (userListData !== null && js.length > userListData.userIndex && userListData.meAction == true) {
        setValue(thisObj, 'lstUsers', [js.at(userListData.userIndex).key]);
        userListData.meAction = null;
        userListData.userIndex = null;
      }
      else if (userListData !== null && js.length == userListData.userIndex && userListData.meAction == true) {
        setValue(thisObj, 'lstUsers', [js.at(js.length-1).key]);
        userListData.meAction = null;
        userListData.userIndex = null;
      }
      await EnableDisableControls()
      setLoading(false)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in bFillUserList`
        );
      }
      return false;
    }
  }
  
  // Functions list binding
  const bFillFunctionList = async (compId, security_group) => {
    try {
      setLoading(true)
      let functionListData = getData(thisObj, 'functionListData')
      let js = []
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupFunctionNameDetails(compId, security_group)
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].func_id + "!" + data[i].func_sub_id + "!" + data[i].access_level + "!" + data[i].func_name,
                      description: data[i].func_id + '.' + data[i].func_sub_id + ' (' + data[i].access_level + ') - ' + data[i].func_name,
                      func_sub_id: data[i].func_sub_id,
                      func_id: data[i].func_id,
                      index: i }
          js.push(obj)
        }
      }
      setData(thisObj, "lstfunctionsLength", js)
      thisObj.setState(current => {
        return {
          ...current,
          lstFunctions: {
            ...state['lstFunctions'],
            valueList: js,
          }
        }
      })
      if (js.length <= 0) {
        return;
      }
      if (js.length > 0 && (functionListData == null || functionListData == undefined || functionListData.meAction == null || functionListData.funcIndex == null )) {
        setValue(thisObj, 'lstFunctions', [js.at(0).key]);
      }
      else if (functionListData !== null && js.length > functionListData.funcIndex && functionListData.meAction == true) {
        setValue(thisObj, 'lstFunctions', [js.at(functionListData.funcIndex).key]);
        functionListData.meAction = null
        functionListData.funcIndex = null
      }
      else if (functionListData !== null && js.length == functionListData.funcIndex && functionListData.meAction == true) {
        setValue(thisObj, 'lstFunctions', [js.at(js.length-1).key]);
        functionListData.meAction = null
        functionListData.funcIndex = null
      }
      await EnableDisableControls()
      setLoading(false)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in bFillFunctionList`
        );
      }
      return false;
    }
    finally {
      setLoading(false)
    }
  }

  // Locations list binding
  const bFillLocationList = async(security_group, cropYear) => {
    try {
      setLoading(true)
      let locationListData = getData(thisObj, 'locationListData')
      let js = []
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupLocationNameDetails(security_group, cropYear, "PN0060", null, user_id)
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id + ' (' + data[i].access_level + ') - ' + ConvertToPascalCase(data[i].buy_pt_name).toString(), index: i }
          js.push(obj)
        }
      }
      setData(thisObj, "lstLocationsLength", js)
      thisObj.setState(current => {
        return {
          ...current,
          lstLocations: {
            ...state["lstLocations"],
            valueList: js,
          }
        }
      })
      if (js.length <= 0) {
        return;
      }
      if (js.length > 0 && (locationListData == null || locationListData == undefined || locationListData.locIndex == null || locationListData.meAction == null)) {
        setValue(thisObj, 'lstLocations', [js.at(0).key]);
      }
      else if (locationListData !== null && js.length > locationListData.locIndex && locationListData.meAction == true) {
        setValue(thisObj, 'lstLocations', [js.at(locationListData.locIndex).key]);
        locationListData.locIndex = null
        locationListData.meAction = null
      }
      else if (locationListData !== null && js.length == locationListData.locIndex && locationListData.meAction == true) {
        setValue(thisObj, 'lstLocations', [js.at(js.length-1).key]);
        locationListData.locIndex = null
        locationListData.meAction = null
      }
      await EnableDisableControls()
      setLoading(false)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in bFillLocationList`
        );
      }
      return false;
    }
    finally {
      setLoading(false)
    }
  }

  // Add/Update button function
  const onbtnSGAddClick = async() => {
    try{
      let Space = '                                                                                     '
      let dataObj = getData(thisObj, 'dataSendingtoSecurityGroup')
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      if(security_group == '' || security_group == undefined || security_group == null) {
        showMessage(thisObj, "Security Group is a Required Field!!!")
        return;
      }
      let auth_level = getValue(thisObj, 'ddAuthorityLevel')
      if (dataObj[0].cmdOkCaption == 'Add') {
        let temp = {
          "security_group" : security_group,
          "auth_level" : auth_level
        }
        let response = await SystemMaintenanceSpecialFunctionsService.CreateSecurityGroupControl(temp)
        let data = response
        if (data.status !== 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + Space + response.message)
          return;
        }
        let obj = {};
        obj.security_group = security_group
        obj.condition = "Add"
        obj.screen = "SecurityGroup"
        setData(thisObj, "DatafromChildScreensToSecurityMenu", obj);
        document.getElementById("SystemMaintenanceSpecialFunctions_SecurityGroupPopUp").childNodes[0].click()
        return;
      }

      if (dataObj[0].cmdOkCaption == 'Update') {
        let temp = {
          "auth_level" : auth_level,
          "user_id" : user_id
        }
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateSecurityGroupControl(security_group, temp)
        let data = response
        if (data.status !== 200) {
          alert("An error occured while updating the database. Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response.message)
          return;
        }
        let obj ={}
        obj.condition = "Update",
        obj.screen = "SecurityGroup"
        setData(thisObj, "DatafromChildScreensToSecurityMenu", obj);
        document.getElementById("SystemMaintenanceSpecialFunctions_SecurityGroupPopUp").childNodes[0].click()
        return;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add or Update Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnSGAddClick = onbtnSGAddClick

  // Add User button function
  const onbtnAddUserClick = () => {
    try {
      let userList = thisObj.state['lstUsers'].valueList;
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      let frmSecurityGroupUserSelect = {}
      frmSecurityGroupUserSelect.cmdOk_Caption = "Add"
      frmSecurityGroupUserSelect.security_group = security_group
      frmSecurityGroupUserSelect.userList = userList
      setData(thisObj, 'frmSecurityGroupUserSelect', frmSecurityGroupUserSelect)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityGroupUserSelect#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add User Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddUserClick = onbtnAddUserClick

  // Delete User button function
  const onbtnDeleteUserClick = async() => {
    try {
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      let n = 0
      let user_id = thisObj.values.lstUsers[0]
      if (user_id == undefined) {
      showMessage(thisObj, 'Please select a user to delete')
      return
      }
      let userIndex = ''
      let user_name = ''
      let userList = thisObj.state['lstUsers'].valueList;
      let selectedUserListValue = thisObj.values['lstUsers'];
      if (selectedUserListValue !== "" && selectedUserListValue !== undefined) {
        user_id = userList.find(elem => elem.key == selectedUserListValue).key;
        user_name = userList.find(elem => elem.key == selectedUserListValue).description;
        userIndex = userList.find(elem => elem.key == selectedUserListValue).index;
      }
      let temp = {
        "security_group" : ""
      }
      let res = confirm(`Are you sure you wish to delete this User '${user_name}'`)
      if (res == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateUserControlDetails(user_id, temp)
        if (![200].includes(response.status)) {
          alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          return;
        }
        else {
          let obj = {
            meAction : true,
            userIndex : userIndex
          }
          setData(thisObj, 'userListData', obj)
          await bFillUserList(compId, security_group)
          await EnableDisableControls()
        }
        let lstUsers = getData(thisObj, "lstUsersLength")
        if (n > (lstUsers?.length - 1)) {
          await EnableDisableControls()
        }
        else {
          return;
        }
        setLoading(false)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete User Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteUserClick = onbtnDeleteUserClick

  // Add Function button function
  const onbtnAddFunctionClick = () => {
    try {
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      let frmSecurityFunctionSelect = {}
      frmSecurityFunctionSelect.cmdOk_Caption = "Add"
      frmSecurityFunctionSelect.security_group = security_group
      frmSecurityFunctionSelect.cmdAdd_Enabled = false
      frmSecurityFunctionSelect.cmdEdit_Enabled = false
      frmSecurityFunctionSelect.cmdDelete_Enabled = false
      frmSecurityFunctionSelect.lstLocation_Enabled = false
      frmSecurityFunctionSelect.cboFunction_Enabled = true
      setData(thisObj, 'frmSecurityFunctionSelect', frmSecurityFunctionSelect)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityFunctionSelect#DEFAULT#true#Click") 
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Function Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddFunctionClick = onbtnAddFunctionClick

  // Edit Function button function
  const onbtnEditFunctionClick = async() => { 
    try {
      let func_id = ''
      let func_sub_id = ''
      let funcList = thisObj.state['lstFunctions'].valueList;
      let selectedfuncListValue = thisObj.values['lstFunctions'];
      if (selectedfuncListValue !== "" && selectedfuncListValue !== undefined) {
        func_id = funcList.find(elem => elem.key == selectedfuncListValue).func_id;
        func_sub_id = funcList.find(elem => elem.key == selectedfuncListValue).func_sub_id;
      }
      let search = "/"
      let replaceWith = "~"
      let security_group
      let security_group1 = getValue(thisObj, 'txtSecurityGroup')
      if (security_group1.includes("/")) {
        security_group = security_group1.split(search).join(replaceWith);
      }
      else {
        security_group = security_group1
      }
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupFunctionTransferDetails(security_group, func_id, func_sub_id)
      let frmSecurityFunctionSelect = {}
        frmSecurityFunctionSelect.cmdOk_Caption = "Update",
        frmSecurityFunctionSelect.security_group = security_group,
        frmSecurityFunctionSelect.cmdAdd_Enabled = true,
        frmSecurityFunctionSelect.cmdEdit_Enabled = true,
        frmSecurityFunctionSelect.cmdDelete_Enabled = true,
        frmSecurityFunctionSelect.lstLocation_Enabled = true,
        frmSecurityFunctionSelect.cboFunction_Enabled = false,
        frmSecurityFunctionSelect.sFuncID = data[0].func_id,
        frmSecurityFunctionSelect.sSubFuncID = data[0].func_sub_id,
        frmSecurityFunctionSelect.sAccessLevel = data[0].access_level,
        frmSecurityFunctionSelect.lblAddedBy = data[0].add_user + " " + moment(data[0].add_date_time).format("M/D/YYYY"),
        frmSecurityFunctionSelect.lblChangedBy = data[0].chg_user + " " + moment(data[0].chg_date_time).format("M/D/YYYY")
      setData(thisObj, 'frmSecurityFunctionSelect', frmSecurityFunctionSelect)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityFunctionSelect#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Function Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditFunctionClick = onbtnEditFunctionClick

  //Delete Function button 
  const onbtnDeleteFunctionClick = async() => { 
    try {
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      let n = 0
      let funcIndex = ''
      let func_id = ''
      let func_sub_id = ''
      let func_name = ''
      let funcList = thisObj.state['lstFunctions'].valueList;
      let selectedfuncListValue = thisObj.values['lstFunctions'];
      if (selectedfuncListValue !== "" && selectedfuncListValue !== undefined) {
        func_id = funcList.find(elem => elem.key == selectedfuncListValue).func_id;
        funcIndex = funcList.find(elem => elem.key == selectedfuncListValue).index;
        func_name = funcList.find(elem => elem.key == selectedfuncListValue).description;
        func_sub_id = funcList.find(elem => elem.key == selectedfuncListValue).func_sub_id;
      }
      let res = confirm(`Are you sure you wish to delete this function '${func_name}'`)
      if (res == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.RemoveSecurityGroupFunctionTransfer(security_group, func_id, func_sub_id)
        if (![200].includes(response.status)) {
          alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else {
          let obj = {
            meAction : true,
            funcIndex : funcIndex
          }
          setData(thisObj, 'functionListData', obj)
          await bFillFunctionList(compId, security_group)
          await EnableDisableControls()
        }
        let lstFunc = getData(thisObj, 'lstfunctionsLength')
        if (n > (lstFunc?.length - 1)) {
          await EnableDisableControls()
        }
      }
      else {
        return;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Function Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteFunctionClick = onbtnDeleteFunctionClick

  // Add Location button function
  const onbtnAddLocationClick = () => { 
    try {
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      let frmSecurityLocationSelect = {}
      frmSecurityLocationSelect.cmdOk_Caption = "Add"
      frmSecurityLocationSelect.security_group = security_group
      setData(thisObj, 'frmSecurityLocationSelect', frmSecurityLocationSelect)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityLocationSelect#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Location Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddLocationClick = onbtnAddLocationClick

  // Edit Location button function
  const onbtnEditLocationClick = async() => { 
    try {
      let buy_pt_id = ''
      let locList = thisObj.state['lstLocations'].valueList;
      let selectedLocListValue = thisObj.values['lstLocations'];
      if (selectedLocListValue !== "" && selectedLocListValue !== undefined) {
        buy_pt_id = locList.find(elem => elem.key == selectedLocListValue).key;
      }
      let search = "/"
      let replaceWith = "~"
      let security_group
      let security_group1 = getValue(thisObj, 'txtSecurityGroup')
      if (security_group1.includes("/")) {
        security_group = security_group1.split(search).join(replaceWith);
      }
      else {
        security_group = security_group1
      }
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupLocationTransferDetails(security_group, buy_pt_id)
      let frmSecurityLocationSelect = {}
        frmSecurityLocationSelect.cmdOk_Caption = "Update"
        frmSecurityLocationSelect.sAccessLevelId = data[0].access_level
        frmSecurityLocationSelect.lblAddedBy = data[0].add_user + " " + moment(data[0].add_date_time).format("M/D/YYYY")
        frmSecurityLocationSelect.lblChangedBy = data[0].chg_user + " " + moment(data[0].chg_date_time).format("M/D/YYYY")
        frmSecurityLocationSelect.buy_pt_id = buy_pt_id
        frmSecurityLocationSelect.security_group = security_group
      setData(thisObj, 'frmSecurityLocationSelect', frmSecurityLocationSelect)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityLocationSelect#DEFAULT#true#Click")
      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Location Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditLocationClick = onbtnEditLocationClick

  //Delete Location button 
  const onbtnDeleteLocationClick = async() => { 
    try {
      let security_group = getValue(thisObj, 'txtSecurityGroup')
      let n = 0
      let locIndex = ''
      let buy_pt_id = ''
      let buy_pt_name = ''
      let locList = thisObj.state['lstLocations'].valueList;
      let selectedlocListValue = thisObj.values['lstLocations'];
      if (selectedlocListValue !== "" && selectedlocListValue !== undefined) {
        buy_pt_id = locList.find(elem => elem.key == selectedlocListValue).key;
        buy_pt_name = locList.find(elem => elem.key == selectedlocListValue).description;
        locIndex = locList.find(elem => elem.key == selectedlocListValue).index;
      }
      let res = confirm(`Are you sure you wish to delete this location '${buy_pt_name}'`)
      if (res == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.RemoveSecurityGroupLocationTransfer(security_group, buy_pt_id)
        if (![200].includes(response.status)) {
          alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else {
          let obj = {
            meAction : true,
            locIndex : locIndex
          }
          setData(thisObj, 'locationListData', obj)
          await bFillLocationList(security_group, cropYear)
          await EnableDisableControls() 
        }
        let lstLoc = getData(thisObj, 'lstLocationsLength')
        if (n > (lstLoc?.length - 1)) {
          await EnableDisableControls()
        }
      }
      else {
        return;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Location Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteLocationClick = onbtnDeleteLocationClick

//Click event for Cancel button
const onbtnCancelClick = () => {
  try {
    document.getElementById("SystemMaintenanceSpecialFunctions_SecurityGroupPopUp").childNodes[0].click()
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnCancel event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityGroup*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityGroup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityGroup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityGroup*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityGroup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtSecurityGroup*/}

                {/* END_USER_CODE-USER_BEFORE_txtSecurityGroup*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSecurityGroup}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSecurityGroup*/}

                {/* END_USER_CODE-USER_AFTER_txtSecurityGroup*/}
                {/* START_USER_CODE-USER_BEFORE_ddAuthorityLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddAuthorityLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAuthorityLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAuthorityLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddAuthorityLevel*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxUsers*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxUsers*/}

                <GroupBoxWidget conf={state.grpbxUsers} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblUsers*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUsers*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUsers}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUsers*/}

                  {/* END_USER_CODE-USER_AFTER_lblUsers*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddUser*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddUser*/}

                  <ButtonWidget
                    conf={state.btnAddUser}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddUser*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddUser*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteUser*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteUser*/}

                  <ButtonWidget
                    conf={state.btnDeleteUser}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteUser*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteUser*/}
                  {/* START_USER_CODE-USER_BEFORE_lstUsers*/}

                  {/* END_USER_CODE-USER_BEFORE_lstUsers*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstUsers}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstUsers*/}

                  {/* END_USER_CODE-USER_AFTER_lstUsers*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxUsers*/}

                {/* END_USER_CODE-USER_AFTER_grpbxUsers*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFunctions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFunctions*/}

                <GroupBoxWidget conf={state.grpbxFunctions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFunctions*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFunctions}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_lblFunctions*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddFunction*/}

                  <ButtonWidget
                    conf={state.btnAddFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditFunction*/}

                  <ButtonWidget
                    conf={state.btnEditFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteFunction*/}

                  <ButtonWidget
                    conf={state.btnDeleteFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_lstFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFunctions*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFunctions}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_lstFunctions*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFunctions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFunctions*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLocations*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLocations*/}

                <GroupBoxWidget conf={state.grpbxLocations} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLocations*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLocations}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLocations*/}

                  {/* END_USER_CODE-USER_AFTER_lblLocations*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddLocation*/}

                  <ButtonWidget
                    conf={state.btnAddLocation}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditLocation*/}

                  <ButtonWidget
                    conf={state.btnEditLocation}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditLocation*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteLocation*/}

                  <ButtonWidget
                    conf={state.btnDeleteLocation}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteLocation*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_lstLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_lstLocations*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstLocations}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstLocations*/}

                  {/* END_USER_CODE-USER_AFTER_lstLocations*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLocations*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLocations*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityGroup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityGroup*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnSGAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnSGAdd*/}

                <ButtonWidget
                  conf={state.btnSGAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSGAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnSGAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityGroup*/}

              {/* END_USER_CODE-USER_AFTER_SecurityGroup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SecurityGroup);

