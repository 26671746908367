/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
} from "../../shared/WindowImports";

import "./ViewPremiumDeductions.scss";
import Loading from "../../../Loader/Loading";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
// END_USER_CODE-USER_IMPORTS
function Settlements_ViewPremiumDeductions(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewPremiumDeductions",
    windowName: "ViewPremiumDeductions",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ViewPremiumDeductions",
    // START_USER_CODE-USER_ViewPremiumDeductions_PROPERTIES
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1060C",
    },
    // END_USER_CODE-USER_ViewPremiumDeductions_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrCancel",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    cmmndCntnrCancel: {
      name: "cmmndCntnrCancel",
      type: "CommandContainerWidget",
      parent: "grpbxViewPremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrCancel_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrCancel_PROPERTIES
    },
    colAmount: {
      name: "colAmount",
      type: "GridColumnWidget",
      parent: "gridViewPremiumDeductions",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmount_PROPERTIES

      // END_USER_CODE-USER_colAmount_PROPERTIES
    },
    colDescription: {
      name: "colDescription",
      type: "GridColumnWidget",
      parent: "gridViewPremiumDeductions",
      Label: "Description",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDescription_PROPERTIES

      // END_USER_CODE-USER_colDescription_PROPERTIES
    },
    colPremiumsDeductions: {
      name: "colPremiumsDeductions",
      type: "GridColumnWidget",
      parent: "gridViewPremiumDeductions",
      Label: "Premiums/Deductions",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremiumsDeductions_PROPERTIES

      // END_USER_CODE-USER_colPremiumsDeductions_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridViewPremiumDeductions",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    gridViewPremiumDeductions: {
      name: "gridViewPremiumDeductions",
      type: "GridWidget",
      parent: "grpbxViewPremiumDeductions",
      gridCellsOrder:
        "txtcolPremiumsDeductions,txtcolDescription,txtcolVendor,txtcolAmount",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridViewPremiumDeductions_PROPERTIES
      isExpandable: true,
      defaultExpand:true,
      showExpander:true,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
        {data.children ? (
        <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
        {data.children.map((elem, i) => 
          <div className={"row ExpandRow" + " rdt_ExpanderRow"+i} key={"expandRow"+i} onClick={expandRowClicked}>
          <div className="extraRowCell_2 ch-3 TableCell" key="expandCell1">
            <label>
            {elem.txtcolPremiumsDeductions}
            </label>
          </div>
          <div className="extraRowCell_3 ch-3 TableCell" key="expandCell2">
            <label>
            {elem.txtcolDescription}
            </label>
          </div>
          <div className="extraRowCell_4 ch-3 TableCell" key="expandCell3">
            <label>
            {elem.txtcolVendor}
            </label>
          </div>
          <div className="extraRowCell_5 ch-3 TableCell" key="expandCell4">
            <label>
            {elem.txtcolAmount}
            </label>
          </div>
          </div>
          )}
          </div>
        ): null}
          </div>
      )
      // END_USER_CODE-USER_gridViewPremiumDeductions_PROPERTIES
    },
    lblPremiumsDeductions: {
      name: "lblPremiumsDeductions",
      type: "LabelWidget",
      parent: "grpbxViewPremiumDeductions",
      Label: "Premiums/Deductions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremiumsDeductions_PROPERTIES

      // END_USER_CODE-USER_lblPremiumsDeductions_PROPERTIES
    },
    txtcolAmount: {
      name: "txtcolAmount",
      type: "TextBoxWidget",
      colName: "colAmount",
      parent: "gridViewPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolAmount_PROPERTIES
    },
    txtcolDescription: {
      name: "txtcolDescription",
      type: "TextBoxWidget",
      colName: "colDescription",
      parent: "gridViewPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDescription_PROPERTIES

      // END_USER_CODE-USER_txtcolDescription_PROPERTIES
    },
    txtcolPremiumsDeductions: {
      name: "txtcolPremiumsDeductions",
      type: "TextBoxWidget",
      colName: "colPremiumsDeductions",
      parent: "gridViewPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremiumsDeductions_PROPERTIES

      // END_USER_CODE-USER_txtcolPremiumsDeductions_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridViewPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    grpbxViewPremiumDeductions: {
      name: "grpbxViewPremiumDeductions",
      type: "GroupBoxWidget",
      parent: "ViewPremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewPremiumDeductions_PROPERTIES

      // END_USER_CODE-USER_grpbxViewPremiumDeductions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // Form Load Event
  function FormLoad() {
    FillPremiumsDeductions();// Function to Bind Premiums Deductions data to grid
  }

  // Function to Bind Premiums Deductions data to grid
  function FillPremiumsDeductions() {
    try {
      let viewPremiumDeductionsOnLoadDataobj = getData(thisObj, 'viewPremiumDeductionsOnLoadData')
      if (viewPremiumDeductionsOnLoadDataobj != null && viewPremiumDeductionsOnLoadDataobj.insp_num != undefined && viewPremiumDeductionsOnLoadDataobj !== null && viewPremiumDeductionsOnLoadDataobj != '') {
        SettlementService.RetrieveInspectionHeaderList(viewPremiumDeductionsOnLoadDataobj.insp_num, viewPremiumDeductionsOnLoadDataobj.buy_pt_id, viewPremiumDeductionsOnLoadDataobj.action, viewPremiumDeductionsOnLoadDataobj.getvendorinfo).then(response => {
          let PremiumsDeductionsArray = [];
          if (response != undefined && response.length > 0 && response[0].inspect_prem_deducts != undefined && response[0].inspect_prem_deducts.length > 0) {
            let data = response[0].inspect_prem_deducts;
            let obj = {};
            let rowId = 0;
            for (let i = 0; i < data.length; i++) {
              
              obj.rowId = i
              obj.txtcolPremiumsDeductions = data[i].pd_code
              if (data[i].payee_vendor != undefined && data[i].payee_vendor != null && data[i].payee_vendor != "") { obj.txtcolDescription = data[i].pd_desc + " - (" + data[i].payee_vendor + ")" }
              else { obj.txtcolDescription = data[i].pd_desc }
              obj.txtcolVendor = data[i].pd_vendor
              obj.txtcolAmount = data[i].pd_amount
              PremiumsDeductionsArray.push(obj)
              obj = {}
            }
            let arrayWithParentRowIds = addParentRowIdForAllRows(PremiumsDeductionsArray); // This function adds Parent Ids for grouped rows
            let arrayWithAdditionalParentRowForGroupedRows = addParentRowForAllGroupedRows(arrayWithParentRowIds); // This function adds additional Parent rows for showing Total amount for all grouped rows
            let arrayWithGroupedRowsBasedOnParentIds = groupRowsBaedOnParentIds(arrayWithAdditionalParentRowForGroupedRows); // This function groups rows based on Parent Ids.
            setValue(thisObj, "gridViewPremiumDeductions", arrayWithGroupedRowsBasedOnParentIds);
          }
        })
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Error occurred while binding premiums deductions data")
      }
    }
  }

  // function for adding Parent Ids to grouped rows
  function addParentRowIdForAllRows(data) {
    let map = new Map();
    let newJs = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      if (map.has(data[i].txtcolPremiumsDeductions)) {
        obj = { ...data[i] };
        obj.parentRowId = map.get(data[i].txtcolPremiumsDeductions);
      }
      else {
        map.set(data[i].txtcolPremiumsDeductions, data[i].rowId);
        obj = { ...data[i] };
        obj.parentRowId = null;
      }
      newJs.push(obj);
    }
    return newJs;
  }

  // function for adding additional Parent row for showing Total amount for all grouped rows
  function addParentRowForAllGroupedRows(rawArray) {
    let newArrayWithParentRows = [];
    let newParentobj = {};
    let oldParentobj = {};
    let otherRowobj = {};
    let newRowId = 0;
    let newParentRowId = 0;
    let subTotal = 0;
    for (let i = 0; i < rawArray.length; i++) {

      if (rawArray[i].parentRowId == null) {
        // Add New Parent with Total
        
        newRowId = i
        newParentRowId = newRowId
        newParentobj.parentRowId = null // Set null value for additional Parent row showing total values
        newParentobj.newRowId = newRowId
        newParentobj.txtcolPremiumsDeductions = "Total " + rawArray[i].txtcolPremiumsDeductions
        newParentobj.txtcolDescription = ""
        newParentobj.txtcolVendor = ""

        //Add old Parent row as child row
        newRowId = newRowId + 1;
        oldParentobj.txtcolPremiumsDeductions = rawArray[i].txtcolPremiumsDeductions
        oldParentobj.txtcolDescription = rawArray[i].txtcolDescription
        oldParentobj.txtcolVendor = rawArray[i].txtcolVendor
        oldParentobj.txtcolAmount = rawArray[i].txtcolAmount
        subTotal = subTotal + parseFloat(rawArray[i].txtcolAmount) // Doing sum of amount value for grouped rows
        oldParentobj.newRowId = newRowId
        oldParentobj.parentRowId = newParentRowId;
        newArrayWithParentRows.push(oldParentobj);
        oldParentobj = {};
        
        for (let j = i + 1; j < rawArray.length; j++) {
          if (rawArray[j] != undefined && rawArray[j] != null && rawArray[j].parentRowId != null && rawArray[j].parentRowId == rawArray[i].rowId) {
            newRowId = newRowId + 1;
            otherRowobj.newRowId = newRowId
            otherRowobj.parentRowId = newParentRowId;
            otherRowobj.txtcolPremiumsDeductions = "" // Setting blank value as old application shows value only for parent row
            otherRowobj.txtcolDescription = "" // Setting blank value as old application shows value only for parent row
            otherRowobj.txtcolVendor = rawArray[j].txtcolVendor
            otherRowobj.txtcolAmount = rawArray[j].txtcolAmount
            subTotal = subTotal + parseFloat(rawArray[j].txtcolAmount) // Doing sum of amount value for grouped rows
            newArrayWithParentRows.push(otherRowobj);
            otherRowobj = {};
          }
        }
        newParentobj.txtcolAmount = subTotal.toFixed(2) //setting sub total at then end for parent row
        newArrayWithParentRows.push(newParentobj);
        newParentobj = {};
        subTotal = 0;
      }
    }
    return newArrayWithParentRows;
  }

  //function for grouping rows based on Parent Ids.
  function groupRowsBaedOnParentIds(data, multi = null) {
    return data.reduce((r, e) => {
      if (e.parentRowId == multi) {
        const obj = { ...e };
        const children = groupRowsBaedOnParentIds(data, e.newRowId);
        if (children.length) {
          obj.children = children;
        }
        r.push(obj);
      }
      return r;
    }, [])
  }

  //function for expand row click event
  function expandRowClicked(event) {
    var elems = document.querySelectorAll(".activeExpandRow");
    elems.forEach.call(elems, function (el) {
      el.classList.remove("activeExpandRow");
    });
    var elem = document.querySelectorAll(".icTWDw");
    thisObj.state.gridContractGroupingSearchData.selected = [];
    elem.forEach.call(elem, function (el) {
      el.classList.remove("icTWDw");
      el.classList.add("eaQpfw");
    });
    if (event.target.classList.contains("TableCell")) {
      event.target.parentElement.classList.value += " activeExpandRow";
    }
    else {
      event.target.classList.value += " activeExpandRow";
    }
  }

    //function for Cancel button click event
  const onbtnCancelClick = () => {
    try {
      if (document.getElementById("Settlements_ViewPremiumDeductionsPopUp").childNodes[0] != undefined && document.getElementById("Settlements_ViewPremiumDeductionsPopUp").childNodes[0] != null) {
        document.getElementById("Settlements_ViewPremiumDeductionsPopUp").childNodes[0].click()
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Cancel Click event for ViewPremiumDeductions screen"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_ViewPremiumDeductions*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewPremiumDeductions*/}

              <GroupBoxWidget
                conf={state.grpbxViewPremiumDeductions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPremiumsDeductions*/}

                {/* END_USER_CODE-USER_BEFORE_lblPremiumsDeductions*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPremiumsDeductions}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPremiumsDeductions*/}

                {/* END_USER_CODE-USER_AFTER_lblPremiumsDeductions*/}
                {/* START_USER_CODE-USER_BEFORE_gridViewPremiumDeductions*/}

                {/* END_USER_CODE-USER_BEFORE_gridViewPremiumDeductions*/}

                <GridWidget
                  conf={state.gridViewPremiumDeductions}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridViewPremiumDeductions}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridViewPremiumDeductions*/}

                {/* END_USER_CODE-USER_AFTER_gridViewPremiumDeductions*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrCancel*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrCancel*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrCancel}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrCancel*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewPremiumDeductions*/}

              {/* START_USER_CODE-USER_AFTER_ViewPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_ViewPremiumDeductions*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ViewPremiumDeductions);
