/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  setData,
  getData,
  hide
} from "../../shared/WindowImports";

import "./ReportPreviewWR.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import WarehouseReceipt from "../WarehouseReceipt/WarehouseReceipt";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { GetReportLogoImage, GetReportLogoImageDimension } from "../../Common/Constants";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ReportPreviewWR(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const imgUrl = GetReportLogoImage(window.location.origin)
  const imgDimension = GetReportLogoImageDimension()
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReportPreviewWR",
    windowName: "ReportPreviewWR",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ReportPreviewWR",
    // START_USER_CODE-USER_ReportPreviewWR_PROPERTIES
    horizontalForm : true,
    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A",
    },
    // END_USER_CODE-USER_ReportPreviewWR_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print Setup",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSetup_PROPERTIES

      // END_USER_CODE-USER_btnPrintSetup_PROPERTIES
    },
    btnSaveAsPDF: {
      name: "btnSaveAsPDF",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save as PDF",
      CharWidth: "25",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveAsPDF_PROPERTIES

      // END_USER_CODE-USER_btnSaveAsPDF_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxReportPreviewWR",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    grpbxPreview: {
      name: "grpbxPreview",
      type: "GroupBoxWidget",
      parent: "grpbxReportPreviewWR",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPreview_PROPERTIES

      // END_USER_CODE-USER_grpbxPreview_PROPERTIES
    },
    grpbxReportPreviewWR: {
      name: "grpbxReportPreviewWR",
      type: "GroupBoxWidget",
      parent: "ReportPreviewWR",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxReportPreviewWR_PROPERTIES

      // END_USER_CODE-USER_grpbxReportPreviewWR_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() =>{
    return () => {
      setData(thisObj, "closingOfReportPreviewWR", true)
      setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', { flag : true } )
    }
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    hide(thisObj, 'btnPrintSetup')
    hide(thisObj, 'btnSaveAsPDF')
    
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // Dynamic Data
    let ParentScreenData = getData(thisObj, 'ReportPreviewWRData')
    if(ParentScreenData[0].docName == 'rptWarehouseReceipt_FSADocument'){
      ReadFSADoc(ParentScreenData)
    }

    if(ParentScreenData[0].docName == 'rptWarehouseReceiptWorksheet'){
      ReadWRWorksheet(ParentScreenData)
    }

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  async function ReadWRWorksheet(Data) {
    await rptWarehouseReceiptWorksheet(Data[0].buyingPt, Data[0].wrRcptNum, Data[0].rcptValue)
    var renderCode = await WRWorksheet()
    if (renderCode != undefined && renderCode != null) {
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    }
  }

  async function ReadFSADoc(Data) {
    var renderCode = await CombinedFSADoc(Data)
    if (renderCode != undefined && renderCode != null) {
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    }
  }

  async function FSADocHeader(Data) {
    var renderHeader = '';

    let DateTime = moment(Data.d).format('MM/DD/YYYY h:mm:ss A')

    renderHeader = `  
    <table style="padding-top:5%;width: 100%;font-size:85%;">
      <tr>
        <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
          <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
        </td>
        <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;width:38%;"colspan="1">
          <span style="text-align-last: left;color:black;font-size: 110%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
          <span style="text-align: right;color:black;font-size: 76%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
          <span style="text-align: right;color:black;font-size: 76%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/><br/></span>
        </td>
      </tr>
      <tr>
        <td style="padding-left:2%; padding-right:2%;padding-top: 2%; vertical-align: top;text-align: center;width:100%;"colspan="3">
          <span style="text-align-last: left;color:black;font-size: 100%;width:110%;"> <b> THIS DOCUMENT IS REQUIRED FOR YOU TO OBTAIN YOUR LOAN </b><br/></span>
          <span style="text-align-last: left;color:black;font-size: 100%;width:110%;"> <b> ELECTRONIC RECEIPT INFORMATION </b><br/></span>
          <span style="text-align-last: left;color:black;font-size: 100%;width:110%;"> <b><u> (NEEDED BY FSA FOR MARKET LOAN REQUEST) </u></b><br/></span>
          <span style="text-align-last: left;color:black;font-size: 100%;width:110%;"> ${DateTime} </span>
        </td>
      </tr> 
    </table>
    `

  return renderHeader;
  }

  async function FSADocBody(Data,DataArray,PageNumber,PageCount) {
    let RenderDeliveredToDetails = '';
    let DeliveredToDetails = '';
    let VendorDetails = '';
    let tableDataLeft = '';
    let tableDataRight = '';
    let WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'

    DeliveredToDetails +=`
    <style>
    body:before{
        content: '${WaterMark}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #6e6b6c;
        font-size: 650%;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
    @media print {
        @page :footer { display: none }    
        @page :header { display: none }    
        @page { margin-top: 0; margin-bottom: 0; }    
        html,
        body { height: 100%; margin: 0 !important; padding: 0 !important; }
    }     
    </style>
      <table style="font-size: 100%;text-align:left;">
        <tr style="width: 100%;">
          <td>
            <span> ${Data.g} <br/></span>
            <span> ${Data.h1} <br/></span>
            <span> ${Data.h2} <br/></span>
            <span> ${Data.h3} <br/></span>
          </td>
        </tr>
      </table>
    `
    VendorDetails +=`
      <table style="font-size: 100%;width: 100%;text-align:left;">
        <tr>
          <td>
            <span> ${Data.i1} <br/></span>
            <span> ${Data.i2} <br/></span>
            <span> ${Data.i3} <br/></span>
            <span> ${Data.i4} <br/></span>
          </td>
        </tr>
      </table>
    `

    tableDataLeft += `
      <table style="width: 100%;padding-top:2%;border-collapse:collapse;font-size: 60%;">
        <tr>
          <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"><b> FARM# </b></td>
          <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"><b> 1007# </b></td>
          <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"><b> CONTR# </b></td>
          <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"><b> POUNDS </b></td>
          <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"><b> STATE </b></td>
          <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"><b> CNTY </b></td>
        </tr>
     </table>`

     tableDataRight += `
      <table style="width: 100%;padding-top:2%;border-collapse:collapse;font-size: 73%;">
        <tr>
          <td style="text-align:center;width: 16.67%;border-collapse:collapse;"><b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp </b></td>
          <td style="text-align:center;width: 16.67%;border-collapse:collapse;"><b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp </b></td>
          <td style="text-align:center;width: 16.67%;border-collapse:collapse;"><b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp </b></td>
          <td style="text-align:center;width: 16.67%;border-collapse:collapse;"><b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp </b></td>
          <td style="text-align:center;width: 16.67%;border-collapse:collapse;"><b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp </b></td>
          <td style="text-align:center;width: 16.67%;border-collapse:collapse;"><b> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp </b></td>
        </tr>
     </table>`

     let Median1 = 0
     let Median2 = 0
     let End = 0

     if(DataArray.length <= 10){
      Median1 = DataArray.length
     }
     else if(DataArray.length > 10){
       Median1 = 10
       Median2 = 10
       End = DataArray.length
     }



     for(let i = 0; i < Median1; i++){
        tableDataLeft += `
          <table style="width: 100%;padding-top:2%;border-collapse:collapse;font-size: 60%;">
            <tr>
              <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].farm_id} - ${DataArray[i].farm_suffix} </td>
              <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].settlement_num} </td>
              <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].contract_num} </td>
              <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].net_wt} </td>
              <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].state_abbr} </td>
              <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].county_id} </td>
            </tr>
          </table>
        `
     }

     for(let i = Median2; i < End; i++){
      tableDataRight += `
        <table style="width: 100%;padding-top:2%;border-collapse:collapse;font-size: 60%;">
          <tr>
            <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].farm_id} - ${DataArray[i].farm_suffix} </td>
            <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].settlement_num} </td>
            <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].contract_num} </td>
            <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].net_wt} </td>
            <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].state_abbr} </td>
            <td style="text-align:center;width: 16.67%;border: 0.1px solid black;border-collapse:collapse;"> ${DataArray[i].county_id} </td>
          </tr>
        </table>
      `
   }
    
    RenderDeliveredToDetails = `  
    <table style="width: 100%;height: 75%;font-size:100%;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;">
      <tr style="width:49.7%;display: inline-block;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;" rowspan="1">
        <td style="vertical-align: top;width:25%;border-collapse:collapse;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b>Delivered To: </b></span>
        </td>
        <td style="vertical-align: top;width:75%;border-collapse:collapse;">
          <span style="text-align-last: left;color:black;font-size: 72%;"> ${DeliveredToDetails}</span>
        </td>
      </tr>
      <tr style="width:49.7%;display: inline-block;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;vertical-align: top;"rowspan="2">
        <td style="vertical-align: top;width:20%;border-collapse:collapse;">
          <span style="text-align-last: left;color:black;font-size: 80%"><b> Vendor: </b></span>
        </td>
        <td style="vertical-align: top;width:80%;border-collapse:collapse;">
          <span style="text-align-last: left;color:black;font-size: 72%;"> ${VendorDetails}</span>
        </td>
      </tr>
      <tr style="width:49.7%;display: inline-block;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;" rowspan="1">
        <td style="vertical-align: top;width:35%;border-collapse:collapse;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b>Original Location: </b></span>
        </td>
        <td style="vertical-align: top;width:10%;border-collapse:collapse;border: 0.1px solid black;border-left:none;border-top:none;border-bottom:none;">
          <span style="text-align-last: right;color:black;font-size: 72%;"></br> ${Data.l} </span>
        </td>
        <td style="vertical-align: top;width:45%;border-collapse:collapse;border: 0.1px solid black;border-right:none;border-top:none;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> Receipt Obligation Location: </b></span>
        </td>
        <td style="vertical-align: top;width:10%;border-collapse:collapse;padding-left: 2%;">
          <span style="text-align-last: right;color:black;font-size: 72%;"></br> ${Data.m} </span>
        </td>
      </tr>
      <tr style="width:100%;display: inline-block;border-collapse:collapse;vertical-align: top;border-bottom:none;">
        <td style="vertical-align: top;width:11%;border-collapse:collapse;border: 0.1px solid black;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> Receipt #: </b></span>
        </td>
        <td style="vertical-align: top;width:20%;border-collapse:collapse;border: 0.1px solid black;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> Total Net Tons on Rcpt: </b></span>
        </td>
        <td style="vertical-align: top;width:14%;border-collapse:collapse;border: 0.1px solid black;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> CCC Code #: </b></span>
        </td>
        <td style="vertical-align: top;width:14%;border-collapse:collapse;border: 0.1px solid black;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> WHSE LIC. #: </b></span>
        </td>
        <td style="vertical-align: top;width:14%;border-collapse:collapse;border: 0.1px solid black;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> Peanut Type: </b></span>
        </td>
        <td style="vertical-align: top;width:7%;border-collapse:collapse;border: 0.1px solid black;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> Seg: </b></span>
        </td>
        <td style="vertical-align: top;width:20%;border-collapse:collapse;border: 0.1px solid black;border-right:none;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"><b> Average Storage Date: </b></span>
        </td>
        <td style="vertical-align: top;width:0%;border-collapse:collapse;border: 0.1px solid black;border-left:none;border-bottom:none;">
          <span style="text-align-last: left;color:black;font-size: 80%;"> </span>
        </td>
      </tr>
      <tr style="width:100%;display: inline-block;border-collapse:collapse;vertical-align: top;border-top:none;">
        <td style="text-align: right;vertical-align: top;width:11%;border-collapse:collapse;border: 0.1px solid black;border-top:none;">
          <span style="color:black;font-size: 80%;"><b> ${Data.u} </b></span>
        </td>
        <td style="text-align: right;vertical-align: top;width:20%;border-collapse:collapse;border: 0.1px solid black;border-top:none;">
          <span style="color:black;font-size: 80%;"> ${Data.v} </span>
        </td>
        <td style="text-align: right;vertical-align: top;width:14%;border-collapse:collapse;border: 0.1px solid black;border-top:none;">
          <span style="color:black;font-size: 80%;"> ${Data.w} </span>
        </td>
        <td style="text-align: right;vertical-align: top;width:14%;border-collapse:collapse;border: 0.1px solid black;border-top:none;">
          <span style="color:black;font-size: 80%;"> ${Data.x} </span>
        </td>
        <td style="text-align: right;vertical-align: top;width:14%;border-collapse:collapse;border: 0.1px solid black;border-top:none;">
          <span style="color:black;font-size: 80%;"> ${Data.y} </span>
        </td>
        <td style="text-align: right;vertical-align: top;width:7%;border-collapse:collapse;border: 0.1px solid black;border-top:none;">
          <span style="color:black;font-size: 80%;"> ${Data.z} </span>
        </td>
        <td style="text-align: right;vertical-align: top;width:20%;border-collapse:collapse;border: 0.1px solid black;border-right:none;border-top:none;">
          <span style="color:black;font-size: 80%;"> ${Data.aa} </span>
        </td>
        <td style="text-align: rightvertical-align: top;width:0%;border-collapse:collapse;border: 0.1px solid black;border-left:none;border-top:none;">
          <span style=";color:black;font-size: 80%;"> </span>
        </td>
      </tr>
      <tr style="width:100%;border-collapse:collapse;text-align: left;vertical-align: top;display: block;">
        <td style="vertical-align: top;width:100%;">
          <span style="font-size: 90%;vertical-align: top;"><b> Statement of Charges attached for circled items: </b><br/></span>
          <span style="font-size: 90%;vertical-align: top;margin-left: 25%;"> 1. Cleaning & Curing <br/> </span>
          <span style="font-size: 90%;vertical-align: top;margin-left: 25%;"> 2. Seed Receivables <br/> </span>
          <span style="font-size: 90%;vertical-align: top;margin-left: 25%;"> 3. Harvesting <br/> </span>
          <span style="font-size: 90%;vertical-align: top;margin-left: 25%;"> 4. In Charges & Associated Costs <br/> </span>
          <span style="font-size: 90%;vertical-align: top;margin-left: 25%;"> 5. Storage  <br/><br/></span>
        </td>
      </tr>
      <tr style="border-collapse:collapse;vertical-align: top;width:100%;display: inline-flex;">
        <td style="width:49.7%;border-collapse:collapse;display: inline-block;">
          <span style="border-collapse:collapse;"> ${tableDataLeft} <br/></span>
        </td>
        <td style="width:49.7%;border-collapse:collapse;display: inline-block;">
          <span style="border-collapse:collapse;"> ${tableDataRight} <br/></span>
        </td>
      </tr>
    </table>
    <table style="text-align:center;width: 100%;font-size:80%;">
      <tr>
        <td style="text-align:center;width: 100%;border: none;border-collapse:collapse;"> Page ${PageNumber} of ${PageCount} </td>
      </tr>
    </table>
    `

    
    return RenderDeliveredToDetails;
  }

  const FSADoc = async () =>{
    let Data = getData(thisObj, 'FSA_DocData')
    let DataArray = []
    let FSADoc = '';
    let pageGap = '';
    pageGap += `<p style="page-break-after: always"></p>`

    let k = 0
    let start = 0
    let end = 20

    if(Data.s1007s.length % 20 == 0){
      k = Data.s1007s.length/20
    }
    else{
      k = Math.floor(Data.s1007s.length/20) + 1
    }

    for(let j=0; j< k; j++){
      DataArray = Data.s1007s.slice(start, end)
      start = start + 20
      end = end + 20
      if(j == 0){
        FSADoc += `
        ${await FSADocHeader(Data)}
        ${await FSADocBody(Data,DataArray,(j+1),k)}
        `
      }
      else if(j > 0){
        FSADoc += `
        ${pageGap}
        ${await FSADocHeader(Data)}
        ${await FSADocBody(Data,DataArray,(j+1),k)}
        `
      }
      
    }

    return FSADoc;
  }

  const CombinedFSADoc = async (Data) =>{
    let RenderCombinedFSADoc = ''

    for(let i =0; i<Data.length; i++){
      await rptWarehouseReceipt_FSADocument(Data[i].buyingPt,Data[i].wrRcptNum)
      RenderCombinedFSADoc += `
        ${await FSADoc()}
      `
    }

    return RenderCombinedFSADoc;
  }

  const WRWorksheetHeader = async (Data,HeaderData) =>{
    let RenderWorksheetHeader = '';

    HeaderData.ah = HeaderData.ah == undefined || HeaderData.ah == null || HeaderData.ah == '' ? '' : moment(HeaderData.ah).format('MM/DD/YYYY')
    HeaderData.aj = HeaderData.aj == undefined || HeaderData.aj == null || HeaderData.aj == '' ? '' : moment(HeaderData.aj).format('MM/DD/YYYY')
    HeaderData.al = HeaderData.al == undefined || HeaderData.al == null || HeaderData.al == '' ? '' : moment(HeaderData.al).format('MM/DD/YYYY')
    HeaderData.an = HeaderData.an == undefined || HeaderData.an == null || HeaderData.an == '' ? '' : moment(HeaderData.an).format('MM/DD/YYYY')
    HeaderData.ae1 = HeaderData.ae1 == undefined || HeaderData.ae1 == null || HeaderData.ae1 == '' ? '' : HeaderData.ae1
    HeaderData.ae2 = HeaderData.ae2 == undefined || HeaderData.ae2 == null || HeaderData.ae2 == '' ? '' : HeaderData.ae2
    HeaderData.ae3 = HeaderData.ae3 == undefined || HeaderData.ae3 == null || HeaderData.ae3 == '' ? '' : HeaderData.ae3
    HeaderData.ae4 = HeaderData.ae4 == undefined || HeaderData.ae4 == null || HeaderData.ae4 == '' ? '' : HeaderData.ae4
    HeaderData.ag1 = HeaderData.ag1 == undefined || HeaderData.ag1 == null || HeaderData.ag1 == '' ? '' : HeaderData.ag1
    HeaderData.ag2 = HeaderData.ag2 == undefined || HeaderData.ag2 == null || HeaderData.ag2 == '' ? '' : HeaderData.ag2
    HeaderData.ag3 = HeaderData.ag3 == undefined || HeaderData.ag3 == null || HeaderData.ag3 == '' ? '' : HeaderData.ag3
    HeaderData.ag4 = HeaderData.ag4 == undefined || HeaderData.ag4 == null || HeaderData.ag4 == '' ? '' : HeaderData.ag4

    RenderWorksheetHeader +=`
    <table style="padding-top:4%;padding-left:2%;padding-right:2%;width:100%;">
      <tr>
        <td style="width: 8%;">
          <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
        </td>
        <td style="width: 50%; text-align: center;padding-top: 2%;padding-left: 10%;">
          <span><b><u> ${HeaderData.a} </u></b><br/></span>
          <span><b><u> ${HeaderData.b} </u></b><br/></span>
          <span> ${moment(HeaderData.d).format('MM/DD/YYYY h:mm:ss A')}</span>
        </td>
        <td style="width: 40%; text-align: right;">
          <span style="font-size: 90%;"><b> GOLDEN PEANUT COMPANY, LLC </b><br/></span>
          <span style="font-size: 60%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b><br/></span>
          <span style="font-size: 60%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b></span>
        </td>
      </tr>
    </table>

    <table style="margin-left: 2%; margin-right: 2%; margin-top: 1%; width:96%; font-size: 84%; border-collapse:collapse;" colspan="11">
      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Whse. Rcpt. #: </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Status: </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Rcpt Type: </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="5">
          <span><b> Location: </b></span>
        </td>
      </tr>
      <tr>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${HeaderData.i} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${HeaderData.j} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${HeaderData.k} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="5">
          <span> ${HeaderData.l} </span>
        </td>
      </tr>

      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Whse. Lic. #: </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> CCC Loc. Code: </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Whse. No. : </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Bin No. : : </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="1">
          <span><b> Seg: </b></span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;" colspan="2">
          <span><b> Peanut Type: </b></span>
        </td>
      </tr>
      <tr>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${Data.wrws_LicenseNo} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${Data.wrws_CCCCodeNo} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${HeaderData.u} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${HeaderData.v} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="1">
          <span> ${HeaderData.w} </span>
        </td>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;border-top: none;" colspan="2">
          <span> ${HeaderData.x} </span>
        </td>
      </tr>

      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;border-bottom: none;vertical-align: top;" colspan="4">
          <span> Vendor : </span>
        </td>
        <td style="text-align: left;border-collapse:collapse;border-bottom: none;vertical-align: top;" colspan="2">
          <span style="width: 30%;">  CMA: </span>
          <span style="width: 70%;border: 0.1px solid black;border-collapse:collapse;"> &nbsp${HeaderData.ab1}&nbsp </span>
        </td>
        <td style="text-align: left;border-collapse:collapse;border-bottom: none;vertical-align: top;" colspan="2">
          <span style="width: 30%;"> DMA: </span>
          <span style="width: 70%;border: 0.1px solid black;border-collapse:collapse;"> &nbsp${HeaderData.ab2}&nbsp </span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;font-size: 85%;" colspan="3">
          <span style="text-align: left; width: 100%; display: block;"> Issue Date : <br/></span>
          <span style="text-align: right;width: 100%; display: block;"> ${HeaderData.ah} </span>
        </td>
      </tr>

      <tr>
        <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;border-top: none;vertical-align: top;" colspan="4" rowspan="3">
          <span style="vertical-align: top;font-size: 85%;">  ${HeaderData.ad} </span>
          <span style="display: inline-block;text-align: left;font-size: 85%;">  ${HeaderData.ae1} <br/> ${HeaderData.ae2} <br/> ${HeaderData.ae3} <br/> ${HeaderData.ae4}</span>
        </td>
        <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;border-top: none;vertical-align: top;" colspan="4" rowspan="3">
          <span style="vertical-align: top;font-size: 85%;">  ${HeaderData.af} </span>
          <span style="display: inline-block;text-align: left;font-size: 85%;"> ${HeaderData.ag1} <br/> ${HeaderData.ag2} <br/> ${HeaderData.ag3} <br/> ${HeaderData.ag4} </span>
        </td>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;font-size: 85%;" colspan="3" rowspan="1">
          <span style="text-align: left; width: 100%; display: block;"> Weighted Average Storage Date : <br/></span>
          <span style="text-align: right;width: 100%; display: block;"> ${HeaderData.aj} </span>
        </td>
      </tr>

      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;font-size: 85%;" colspan="3" rowspan="1">
          <span style="text-align: left; width: 100%; display: block;"> End Date: <br/></span>
          <span style="text-align: right;width: 100%; display: block;"> ${HeaderData.al} </span>
        </td>
      </tr>

      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;vertical-align: top;font-size: 85%;" colspan="3" rowspan="1">
          <span style="text-align: left; width: 100%; display: block;"> Document Delivered Date: <br/></span>
          <span style="text-align: right;width: 100%; display: block;"> ${HeaderData.an} </span>
        </td>
      </tr>
    </table>

    `

    return RenderWorksheetHeader;
  }

  const WRWorksheetContactDetails = async (Data) =>{
    let RenderWorksheetContractDetails = '';
    let wrws_sContractListLst = Data.wrws_sContractList
    let wrws_sSeedContractListLst = Data.wrws_sSeedContractList

    let VarA = ''
    let VarB = ''
    let VarC = ''
    let VarD = ''

    Data.f = Data.f == undefined || Data.f == null ? '' : Data.f
    Data.g = Data.g == undefined || Data.g == null ? '' : Data.g
    Data.h = Data.h == undefined || Data.h == null ? '' : Data.h
    Data.i = Data.i == undefined || Data.i == null ? '' : Data.i

    RenderWorksheetContractDetails +=`
    <table style="margin-left: 2%; margin-right: 2%; margin-top: 1%; width:60%; font-size: 84%;border-collapse:collapse;">
      <tr>
        <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
          <span><b> Contracts </b></span>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;">
          <span style="width: 28%;display: inline-block;"><b> Contract Number </b></span>
          <span style="width: 23%;display: inline-block;"><b> Type </b></span>
          <span style="width: 23%;display: inline-block;"><b> Pricing Line </b></span>
          <span style="width: 23%;display: inline-block;"><b> Tentative Lbs </b></span>
        </td>
      </tr>`

    if(wrws_sContractListLst != undefined && wrws_sContractListLst != null && wrws_sContractListLst.length > 0){
      for(let i = 0; i < wrws_sContractListLst.length; i++){
        VarA = wrws_sContractListLst[i].contract_num
        VarB = wrws_sContractListLst[i].cont_type
  
        if (wrws_sContractListLst[i].pricing_line == '1') {
          VarC = "Firm" 
        }
        else {
          VarC = "Basis"
        }
  
        if (Data.wrws_mbPurchased) {
          VarD = Number(wrws_sContractListLst[i].appl_lbs_adj)
        }
        else {
          VarD = Number(wrws_sContractListLst[i].tent_lbs_adj)
        }

        RenderWorksheetContractDetails += `
        <tr>
          <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;">
            <span style="width: 28%;display: inline-block;"> ${VarA} </span>
            <span style="width: 23%;display: inline-block;"> ${VarB} </span>
            <span style="width: 23%;display: inline-block;"> ${VarC} </span>
            <span style="width: 23%;display: inline-block;"> ${VarD} </span>
          </td>
        </tr>`
      }
    }

    if(wrws_sSeedContractListLst != undefined && wrws_sSeedContractListLst != null && wrws_sSeedContractListLst.length > 0){
      for(let i = 0; i < wrws_sSeedContractListLst.length; i++){
        VarA = wrws_sSeedContractListLst[i].seed_contract_num
        VarB = "Seed"
        VarC = "N/A"
        VarD = Number(wrws_sSeedContractListLst[i].seed_appl_lbs)

        RenderWorksheetContractDetails += `
        <tr>
          <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;">
            <span style="width: 28%;display: inline-block;"> ${VarA} </span>
            <span style="width: 23%;display: inline-block;"> ${VarB} </span>
            <span style="width: 23%;display: inline-block;"> ${VarC} </span>
            <span style="width: 23%;display: inline-block;"> ${VarD} </span>
          </td>
        </tr>`
      }
    }

    RenderWorksheetContractDetails += ` 
      </table>
    `
    
    return RenderWorksheetContractDetails;
  }

  const WRWorksheetGradeCertificateHeader = async (Data) =>{   
    let RenderGradeCertificateHeader = '';
    
    RenderGradeCertificateHeader +=`
      <tr>
        <td style="text-align: left;border-collapse:collapse;">
          <span><b> Grade Certificate Details: </b></span>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;font-size: 84%;">
          <span style="width:8%;display: inline-block;"><b> Loc </b></span>
          <span style="width:12%;display: inline-block;"><b> Insp Num </b></span>
          <span style="width:23%;display: inline-block;"><b> Farm Num </b></span>
          <span style="width:12%;display: inline-block;"><b> 1007 Num </b></span>
          <span style="width:12%;display: inline-block;"><b> Seed Cls </b></span>
          <span style="width:10%;display: inline-block;"><b> Var </b></span>
          <span style="width:18%;display: inline-block;"><b> Insp Date </b></span>
        </td>
      </tr>
      <tr>
        <td style="text-align: right;border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;border-top:none;font-size: 84%;">
          <span style="width:7%;display: inline-block;"><b> Net Lbs. </b></span>
          <span style="width:7%;display: inline-block;"><b> Net Tons. </b></span>
          <span style="width:6%;display: inline-block;"><b> LSK </b></span>
          <span style="width:7%;display: inline-block;"><b> Net-LSK </b></span>
          <span style="width:6%;display: inline-block;"><b> tkc </b></span>
          <span style="width:5%;display: inline-block;"><b> smk </b></span>
          <span style="width:5%;display: inline-block;"><b> ss </b></span>
          <span style="width:5%;display: inline-block;"><b> ok </b></span>
          <span style="width:5%;display: inline-block;"><b> dam </b></span>
          <span style="width:5%;display: inline-block;"><b> hul </b></span>
          <span style="width:5%;display: inline-block;"><b> fm </b></span>
          <span style="width:5%;display: inline-block;"><b> lsk </b></span>
          <span style="width:5%;display: inline-block;"><b> moi </b></span>
          <span style="width:5%;display: inline-block;"><b> fan </b></span>
          <span style="width:5%;display: inline-block;"><b> elk </b></span>
          <span style="width:5%;display: inline-block;"><b> cr </b></span>
          <span style="width:5%;display: inline-block;"><b> fd </b></span>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;width:100%;border-top:none;font-size: 75%;">
          <span style="width:11%;display: inline-block;"><b>100% Value</b></span>
          <span style="width:6%;display: inline-block;"><b>Contract</b></span>
          <span style="width:5%;display: inline-block;"><b>Pounds</b></span>
          <span style="width:8%;display: inline-block;"><b>Option VPT</b></span>
          <span style="width:10%;display: inline-block;"><b>Option% Supt</b></span>
          <span style="width:9%;display: inline-block;"><b>Option Value</b></span>
          <span style="width:7%;display: inline-block;"><b>${Data.cj}</b></span>
          <span style="width:9%;display: inline-block;"><b>${Data.ck}</b></span>
          <span style="width:8%;display: inline-block;"><b>${Data.cl}</b></span>
          <span style="width:7%;display: inline-block;"><b>${Data.cm}</b></span>
          <span style="width:9%;display: inline-block;"><b>${Data.cn}</b></span>
          <span style="width:7%;display: inline-block;"><b>${Data.co}</b></span>
        </td>
      </tr>
    `
    return RenderGradeCertificateHeader;
  }

  const NumberFormat = async (Value) =>{
    if(Value != undefined && Value != null && Value != ''){
      let NumArray = Value.split('.')
      let Num = NumArray[0]
      let Decimal = NumArray[1]

      if(Decimal == undefined){
        Decimal = '00'
      }
  
      let Final = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal
      return Final
    }
    else{
      return Value
    }
  }

  const WRWorksheetGradeCertificateData = async (Value) =>{
    let RenderGradeCertificateData = '';

    let LastLineValue = Value.contract_lines
    let LastLineValue1 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.pct_basis_grade == undefined || LastLineValue.pct_basis_grade == null || LastLineValue.pct_basis_grade == '' ? '' : LastLineValue.pct_basis_grade
    let LastLineValue2 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.contract_num == undefined || LastLineValue.contract_num == null || LastLineValue.contract_num == '' ? '' : LastLineValue.contract_num
    let LastLineValue3 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.pounds == undefined || LastLineValue.pounds == null || LastLineValue.pounds == '' ? '' :  Intl.NumberFormat('en-US').format(LastLineValue.pounds)
    let LastLineValue4 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.option_price == undefined || LastLineValue.option_price == null || LastLineValue.option_price == '' ? '' : Number(LastLineValue.option_price).toFixed(2)
    let LastLineValue5 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.option_pct_support == undefined || LastLineValue.option_pct_support == null || LastLineValue.option_pct_support == '' ? '' : Number(LastLineValue.option_pct_support).toFixed(2)
    let LastLineValue6 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.option_value == undefined || LastLineValue.option_value == null || LastLineValue.option_value == '' ? '' : Number(LastLineValue.option_value).toFixed(2)
     LastLineValue6 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.option_value == undefined || LastLineValue.option_value == null || LastLineValue.option_value == '' ? '' : await NumberFormat(LastLineValue6)
    let LastLineValue7 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.basis_price == undefined || LastLineValue.basis_price == null || LastLineValue.basis_price == '' || Number(LastLineValue.basis_price) == 0 ? '' : Number(LastLineValue.basis_price).toFixed(2)
    let LastLineValue8 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.basis_pct_support == undefined || LastLineValue.basis_pct_support == null || LastLineValue.basis_pct_support == '' || Number(LastLineValue.basis_pct_support) == 0 ? '' : Number(LastLineValue.basis_pct_support).toFixed(2)
    let LastLineValue9 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.basis_value == undefined || LastLineValue.basis_value == null || LastLineValue.basis_value == '' || Number(LastLineValue.basis_value) == 0 ? '' : Number(LastLineValue.basis_value).toFixed(2)
    let LastLineValue10 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.firm_price == undefined || LastLineValue.firm_price == null || LastLineValue.firm_price == '' || Number(LastLineValue.firm_price) == 0 ? '' : Number(LastLineValue.firm_price).toFixed(2)
    let LastLineValue11 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.firm_pct_support == undefined || LastLineValue.firm_pct_support == null || LastLineValue.firm_pct_support == '' || Number(LastLineValue.firm_pct_support) == 0 ? '' : Number(LastLineValue.firm_pct_support).toFixed(2)
    let LastLineValue12 = LastLineValue == undefined || LastLineValue == null || LastLineValue == '' || LastLineValue.firm_value == undefined || LastLineValue.firm_value == null || LastLineValue.firm_value == '' || Number(LastLineValue.firm_value) == 0 ? '' : Number(LastLineValue.firm_value).toFixed(2)

    if(LastLineValue1 == '' && Value.basis_grade_amt_1007 != undefined && Value.basis_grade_amt_1007 != null && Value.basis_grade_amt_1007 != ''){
      LastLineValue1 = Value.basis_grade_amt_1007
    }

    if(LastLineValue1 != ''){
      LastLineValue1 = Number(LastLineValue1).toFixed(10)
      LastLineValue1 = await NumberFormat(LastLineValue1)
    }

    let PremiumDeductions = ''

    for(let i =0; i <Value.premium_deduction_headers.length;i++){
      PremiumDeductions = PremiumDeductions + ' ' + Value.premium_deduction_headers[i].pd_code + '=' + Value.premium_deduction_headers[i].pd_amount 
    }

    let Obj = {}
    if (Value.farm_name.length >= 11) {
    Obj.cr = (Value.farm_name).split('-')
    Obj.cr1 = Obj.cr[0]
    Obj.cr2 = Obj.cr[1]
    Obj.cr3 = Obj.cr[2]
    Obj.cr4 = Obj.cr1 + '-' + Obj.cr2
    }
    else {
      Obj.cr4 = ""
    }
    let fontSize = '70'
    RenderGradeCertificateData +=`
          <tr>
            <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;font-size: ${fontSize}%;">
              <span style="width:8%;display: inline-block;text-align: left;"> ${Value.buy_pt_id} </span>
              <span style="width:12%;display: inline-block;text-align: left;"> ${Value.insp_num} </span>
              <span style="width:23%;display: inline-block;text-align: left;"> ${Obj.cr4}&nbsp${Value.farm_county}&nbsp${Value.farm_state} </span>
              <span style="width:12%;display: inline-block;text-align: left;"> ${Value.settlement_num} </span>
              <span style="width:12%;display: inline-block;text-align: left;"> ${Value.seed_gen}</span>
              <span style="width:10%;display: inline-block;text-align: left;"> ${Value.pnut_variety_name} </span>
              <span style="width:18%;display: inline-block;text-align: left;"> ${moment(Value.insp_date_time).format('MM/DD/YYYY')} </span>
            </td>
          </tr>
          <tr>
            <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;border-top:none;font-size: ${fontSize}%;">
              <span style="width:7%;display: inline-block;text-align: right;"> ${Value.net_wt} </span>
              <span style="width:7%;display: inline-block;text-align: right;"> ${(Number(Value.net_wt) / 2000).toFixed(2)} </span>
              <span style="width:6%;display: inline-block;text-align: right;"> ${Value.lsk_wt} </span>
              <span style="width:7%;display: inline-block;text-align: right;"> ${Number(Value.net_wt) - Number(Value.lsk_wt)} </span>
              <span style="width:6%;display: inline-block;text-align: right;"> ${Number(Value.tkc_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.smk_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.ss_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.ok_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.dam_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.hull_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.fm_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.lsk_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.moist_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.fan_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.elk_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.cr_br_pct)} </span>
              <span style="width:5%;display: inline-block;text-align: right;"> ${Number(Value.frez_dam_pct)} </span>
            </td>
          </tr>`

    LastLineValue1 = 0
    LastLineValue2 = 0
    LastLineValue3 = 0

    for(var i=0; i< LastLineValue?.length; i++){    
      LastLineValue1 += Number(LastLineValue[i].pct_basis_grade)
      LastLineValue2 += Number(LastLineValue[i].pounds)
      LastLineValue3 += Number(LastLineValue[i].option_value)
      
      let underline1 = i == LastLineValue?.length -1 ? `<u>` : ``
      let underline2 = i == LastLineValue?.length -1 ? `</u>` : ``
      
      RenderGradeCertificateData +=`
          <tr>
            <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-top:none;border-bottom:none;font-size: ${fontSize}%;">
              <span style="width:11%;display: inline-block;text-align: left;">${underline1} ${Number(LastLineValue[i].pct_basis_grade?.toString())?.toFixed(10)} ${underline2} </span>
              <span style="width:6%;display: inline-block;text-align: left;"> ${LastLineValue[i].contract_num} </span>
              <span style="width:5%;display: inline-block;text-align: left;"> ${underline1} ${LastLineValue[i].pounds} ${underline2} </span>
              <span style="width:8%;display: inline-block;text-align: left;"> ${LastLineValue[i].option_price} </span>
              <span style="width:10%;display: inline-block;text-align: left;">${LastLineValue[i].option_pct_support} </span>
              <span style="width:9%;display: inline-block;text-align: left;"> ${underline1} ${LastLineValue[i].option_value} ${underline2} </span>
              <span style="width:7%;display: inline-block;text-align: left;"> ${LastLineValue[i].basis_price} </span>
              <span style="width:9%;display: inline-block;text-align: left;"> ${LastLineValue[i].basis_pct_support} </span>
              <span style="width:8%;display: inline-block;text-align: left;"> ${LastLineValue[i].basis_value} </span>
              <span style="width:7%;display: inline-block;text-align: left;"> ${LastLineValue[i].firm_price} </span>
              <span style="width:9%;display: inline-block;text-align: left;"> ${LastLineValue[i].firm_pct_support} </span>
              <span style="width:7%;display: inline-block;text-align: left;"> ${LastLineValue[i].firm_value} </span>
            </td>
          </tr>`
    }
    
    if(LastLineValue?.length > 1){
      RenderGradeCertificateData +=`
          <tr>
            <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-top:none;border-bottom:none;font-size: ${fontSize}%;">
              <span style="width:11%;display: inline-block;text-align:left;"> ${Number(LastLineValue1?.toString())?.toFixed(10)} </span>
              <span style="width:6%;display: inline-block;text-align: left;"> </span>
              <span style="width:5%;display: inline-block;text-align: left;"> ${LastLineValue2} </span>
              <span style="width:8%;display: inline-block;text-align: left;"> </span>
              <span style="width:10%;display: inline-block;text-align:left;"> </span>
              <span style="width:9%;display: inline-block;text-align: left;"> ${LastLineValue3} </span>
              <span style="width:7%;display: inline-block;text-align: left;"> </span>
              <span style="width:9%;display: inline-block;text-align: left;"> </span>
              <span style="width:8%;display: inline-block;text-align: left;"> </span>
              <span style="width:7%;display: inline-block;text-align: left;"> </span>
              <span style="width:9%;display: inline-block;text-align: left;"> </span>
              <span style="width:7%;display: inline-block;text-align: left;"> </span>
            </td>
          </tr>`
    }

          RenderGradeCertificateData += `
          <tr>
            <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-top:none;font-size: ${fontSize}%;">
              <span style="display: inline-block;text-align: left;"></br> ${PremiumDeductions} </span>
            </td>
          </tr>`

        return RenderGradeCertificateData;
  }

  const WRWorksheet = async () =>{
    let HeaderData = getData(thisObj, 'WorksheetHeaderData')
    let Data = getData(thisObj, 'WRWorksheetData')
    let RenderGradeCertificate = '';
    let start = 0
    let pageGap = '';
    let WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
    pageGap += `<p style="page-break-after: always"></p>`
    let Length = 6

    for(let i =1; i<=Data.sInspections.length; i++){
      if( i == 1){
        RenderGradeCertificate +=`
          ${await WRWorksheetHeader(Data,HeaderData)}
          ${await WRWorksheetContactDetails(Data)}
          <style>
          body:before{
              content: '${WaterMark}';
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: -1;  
              color: #6e6b6c;
              font-size: 650%;
              font-weight: 500px;
              display: grid;
              justify-content: center;
              align-content: center;
              opacity: 0.35;
          }
          @media print {
              @page :footer { display: none }    
              @page :header { display: none }    
              @page { margin-top: 0; margin-bottom: 0; }    
              html,
              body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }
          }     
          </style>
          <table style="margin-left: 2%; margin-right: 2%; margin-top: 1%; width:96%; font-size: 84%;border-collapse:collapse;">
            ${await WRWorksheetGradeCertificateHeader(Data)}
            ${await WRWorksheetGradeCertificateData(Data.sInspections[i-1])}
        `
      }
      else if(i > (start + 1) && i < (start + 5)){
        RenderGradeCertificate +=`
          <style>
          body:before{
              content: '${WaterMark}';
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: -1;  
              color: #6e6b6c;
              font-size: 650%;
              font-weight: 500px;
              display: grid;
              justify-content: center;
              align-content: center;
              opacity: 0.35;
          }
          @media print {
              @page :footer { display: none }    
              @page :header { display: none }    
              @page { margin-top: 0; margin-bottom: 0; }    
              html,
              body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }
          }     
          </style>
          ${await WRWorksheetGradeCertificateData(Data.sInspections[i-1])}
        `
      }
      else if(i == start + 1){
        RenderGradeCertificate +=`
          <style>
            body:before{
                content: '${WaterMark}';
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -1;  
                color: #6e6b6c;
                font-size: 650%;
                font-weight: 500px;
                display: grid;
                justify-content: center;
                align-content: center;
                opacity: 0.35;
            }
            @media print {
                @page :footer { display: none }    
                @page :header { display: none }    
                @page { margin-top: 0; margin-bottom: 0; }    
                html,
                body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }
            }     
            </style> 
          <table style="margin-left: 2%; margin-right: 2%; margin-top: 1%; width:96%; font-size: 84%;border-collapse:collapse;">
            ${await WRWorksheetGradeCertificateHeader(Data)}
            ${await WRWorksheetGradeCertificateData(Data.sInspections[i-1])}
        `
      }
      else if(i == (start + 5)){
        RenderGradeCertificate +=`
          <style>
          body:before{
              content: '${WaterMark}';
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: -1;  
              color: #6e6b6c;
              font-size: 650%;
              font-weight: 500px;
              display: grid;
              justify-content: center;
              align-content: center;
              opacity: 0.35;
          }
          @media print {
              @page :footer { display: none }    
              @page :header { display: none }    
              @page { margin-top: 0; margin-bottom: 0; }    
              html,
              body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }
          }     
          </style>
          ${await WRWorksheetGradeCertificateData(Data.sInspections[i-1])}
          </table>
          ${pageGap}
          ${await WRWorksheetHeader(Data,HeaderData)}
        `
        start = start + 5
      }
    }

    if(Length%5 != 0 || Length < 5){
      RenderGradeCertificate +=`
        </table>
      `
    }

    RenderGradeCertificate +=`
      ${await WRWorksheetTotals(Data)}
      ${await WRWorksheetShrinkDetails(Data)}
    `
    if(HeaderData.b != ''){
      RenderGradeCertificate +=`
        ${pageGap}
        ${await WRWorksheetHeader(Data,HeaderData)}
        ${await WRWorksheetFinalSheet(Data,HeaderData)}
      `
    }

    return RenderGradeCertificate
  }

  const WRWorksheetTotals = async (Data) =>{
    let RenderWorksheetTotals = '';

    RenderWorksheetTotals +=`
    <table style="margin-left: 2%; margin-right: 2%; margin-top: 1%; width:96%; font-size: 84%;border-collapse:collapse;">
      <tr>
        <td style="width: 100%;text-align: left;border: 0.1px solid black;border-collapse:collapse;">
          <span style="display: inline-block;"><b> Totals: </b></span>
        </td>
      </tr>
      <tr>
        <td style="width: 100%;text-align: left;border: 0.1px solid black;border-collapse:collapse;">
          <span style="display: inline-block;"> ${Data.ek} </span>
        </td>
      </tr>
    </table>
    `

    return RenderWorksheetTotals
  }

  const WRWorksheetShrinkDetails = async(Data) =>{
    let RenderWorksheetShrinkDetails = '';

    let TotalBasisGrade = 0
    let TotalPounds = 0
    let TotalOptionValue = 0
    let TotalBasisValue = 0
    let TotalFirmValue = 0

    for(let i=0; i<Data.sInspections.length; i++){
      if(Data.sInspections[i].contract_lines != undefined && Data.sInspections[i].contract_lines != null && Data.sInspections[i].contract_lines != ''){
        for(var x=0; x< Data.sInspections[i].contract_lines?.length; x++){
        TotalBasisGrade = Number(TotalBasisGrade) + Number(Data.sInspections[i].contract_lines[x].pct_basis_grade)
        TotalPounds = Number(TotalPounds) + Number(Data.sInspections[i].contract_lines[x].pounds)
        TotalOptionValue = Number(TotalOptionValue) + Number(Data.sInspections[i].contract_lines[x].option_value)
        TotalBasisValue = Number(TotalBasisValue) + Number(Data.sInspections[i].contract_lines[x].basis_value)
        TotalFirmValue = Number(TotalFirmValue) + Number(Data.sInspections[i].contract_lines[x].firm_value)
        }
      }
      else{
        TotalBasisGrade =  Number(TotalBasisGrade) + Number(Data.sInspections[i].basis_grade_amt_1007)
        TotalPounds = Number(TotalPounds) + Number(Data.sInspections[i].net_wt)
        TotalOptionValue = Number(TotalOptionValue) + Number(Data.sInspections[i].opt_value_of_seg)
      }
    }

    TotalBasisGrade = Number(TotalBasisGrade) == 0 ? '' : Number(TotalBasisGrade).toFixed(2)
    TotalOptionValue = isNaN(TotalOptionValue) == true ? '' : Number(TotalOptionValue).toFixed(2)
    // TotalOptionValue = Number(TotalOptionValue) == 0 ? '' : await NumberFormat(TotalOptionValue.toString())
    TotalBasisValue = Number(TotalBasisValue) == 0 ? '' : Number(TotalBasisValue).toFixed(2)
    TotalFirmValue = Number(TotalFirmValue) == 0 ? '' : Number(TotalFirmValue).toFixed(2)

    let Basis_Value = Number(TotalBasisValue) == 0 ? '' : 'Basis Value'
    let Firm_Value = Number(TotalFirmValue) == 0 ? '' : 'Firm Value'

    RenderWorksheetShrinkDetails +=`
    <table style="margin-left: 2%; margin-right: 2%; margin-top: 1%; width:96%; font-size: 84%;border-collapse:collapse;">
      <tr>
        <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;font-size: 84%;">
          <span style="width:49%;display: inline-block;text-align: left;"><b> Weighted Average, Total Weights and Total Amounts </b></span>
          <span style="width:49%;display: inline-block;text-align: left;"><b> Insp Date </b></span>
        </td>
      </tr>
      <tr>
        <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;border-top:none;font-size: 84%;">
          <span style="width:17%;display: inline-block;text-align: right;"><b> Total Lbs. </b></span>
          <span style="width:8%;display: inline-block;text-align: right;"><b> Total Tons. </b></span>
          <span style="width:6%;display: inline-block;text-align: right;"><b> LSK </b></span>
          <span style="width:7%;display: inline-block;text-align: right;"><b> Net-LSK </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> tkc </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> smk </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> ss </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> ok </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> dam </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> hul </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> fm </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> lsk </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> moi </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> fan </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> elk </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> cr </b></span>
          <span style="width:4%;display: inline-block;text-align: right;"><b> fd </b></span>
        </td>
      </tr>
      <tr>
        <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-top:none;font-size: 75%;">
          <span style="width:13%;display: inline-block;text-align: right;"><b>100% 1007 Value</b></span>
          <span style="width:20%;display: inline-block;text-align: right;"><b>Total Lbs.</b></span>
          <span style="width:20%;display: inline-block;text-align: right;"><b>Option Value</b></span>
          <span style="width:19%;display: inline-block;text-align: right;"><b>${Basis_Value}</b></span>
          <span style="width:19%;display: inline-block;text-align: right;"><b>${Firm_Value}</b></span>
        </td>
      </tr>
      <tr>
        <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;font-size: 84%;">
          <span style="width:44%;display: inline-block;text-align: left;"> </span>
          <span style="width:12%;display: inline-block;text-align: right;"> ${moment(Data.fk).format('MM/DD/YYYY')} </span>
        </td>
      </tr>
      <tr>
        <td style="border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;border-top:none;font-size: 84%;">
          <span style="width:17%;display: inline-block;text-align: right;"> ${Data.fl} </span>
          <span style="width:8%;display: inline-block;text-align: right;"> ${Data.fm} </span>
          <span style="width:6%;display: inline-block;text-align: right;"> ${Data.fn} </span>
          <span style="width:7%;display: inline-block;text-align: right;"> ${Data.fo} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fp} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fq} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fr} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fs} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.ft} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fu} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fv} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fw} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fx} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fy} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.fz} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.ga} </span>
          <span style="width:4%;display: inline-block;text-align: right;"> ${Data.gb} </span>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;width:100%;border-bottom:none;border-top:none;font-size: 75%;">
          <span style="width:7%;display: inline-block;"><b> Shrink % </b></span>
          <span style="width:4.5%;display: inline-block;"> ${Data.gd} </span>
          <span style="width:9.2%;display: inline-block;"> ${Data.ge}  </span>
          <span style="width:6.6%;display: inline-block;"> ${Data.gf}  </span>
          <span style="width:5.4%;display: inline-block;"> ${Data.gg}  </span>
          <span style="width:9%;display: inline-block;"> ${Data.gh}  </span>
        </td>
      </tr>
      <tr>
      <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;width:100%;border-top:none;font-size: 75%;">
        <span style="width:12%;display: inline-block;text-align: right;"> ${TotalBasisGrade}  </span>
        <span style="width:20%;display: inline-block;text-align: right;"> ${TotalPounds}  </span>
        <span style="width:20%;display: inline-block;text-align: right;"> ${TotalOptionValue}  </span>
        <span style="width:19%;display: inline-block;text-align: right;"> ${TotalBasisValue}  </span>
        <span style="width:19%;display: inline-block;text-align: right;"> ${TotalFirmValue}  </span>
      </td>
    </tr>
    </table>
    `

    return RenderWorksheetShrinkDetails
  }

  const WRWorksheetFinalSheet = async(Data,HeaderData) =>{
    let RenderWorksheetFinalSheet = '';
    let RenderWorksheetFinalSheetLeft = '';
    let RenderWorksheetFinalSheetRight = '';
    let wrws_sContractListLst = Data.wrws_sContractList

    let VarA = ''
    let VarB = ''
    let VarC = ''
    let VarD = ''

    RenderWorksheetFinalSheetLeft += `
      <table style="margin-left: 2%; margin-right: 1.5%; margin-top: 1%; width:58%; font-size: 84%;border-collapse:collapse;display: inline-table;">
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span><b> Contracts </b></span>
          </td>
        </tr>
        <tr>
          <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;">
            <span style="width: 35%;display: inline-block;"><b> Contract Number </b></span>
            <span style="width: 16%;display: inline-block;"><b> Type </b></span>
            <span style="width: 22%;display: inline-block;"><b> Pricing Line </b></span>
            <span style="width: 23%;display: inline-block;"><b> Tentative Lbs </b></span>
          </td>
        </tr>`

        if(wrws_sContractListLst != undefined && wrws_sContractListLst != null && wrws_sContractListLst.length > 0){
          for(let i = 0; i < wrws_sContractListLst.length; i++){
            VarA = wrws_sContractListLst[i].contract_num
            VarB = wrws_sContractListLst[i].cont_type
      
            if (wrws_sContractListLst[i].pricing_line == '1') {
              VarC = "Firm" 
            }
            else {
              VarC = "Basis"
            }
      
            if (Data.wrws_mbPurchased) {
              VarD = Number(wrws_sContractListLst[i].appl_lbs_adj)
            }
            else {
              VarD = Number(wrws_sContractListLst[i].tent_lbs_adj)
            }
    
            RenderWorksheetFinalSheetLeft += `
            <tr>
              <td style="text-align: left;border: 0.1px solid black;border-collapse:collapse;">
                <span style="width: 35%;display: inline-block;"> ${VarA} </span>
                <span style="width: 16%;display: inline-block;"> ${VarB} </span>
                <span style="width: 22%;display: inline-block;"> ${VarC} </span>
                <span style="width: 23%;display: inline-block;"> ${VarD} </span>
              </td>
            </tr>`
          }
        }

        RenderWorksheetFinalSheetLeft += 
        `<tr>
          <td style="text-align: left;border-collapse:collapse; padding-top: 4%;">
            <span style="width: 100%;display: inline-block;"> </span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border-collapse:collapse;border: 0.1px solid black;">
            <span style="width: 100%;display: inline-block;"><b> Option Payment or Rebate and Deductions </b></span>
          </td>
        </tr>
        <tr>
          <td style="border-collapse:collapse;border: 0.1px solid black;font-size: 80%;">
            <span style="text-align: right;width: 37%;display: inline-block;"><b> Description </b></span>
            <span style="text-align: right;width: 11%;display: inline-block;"><b> 1007 </b></span>
            <span style="text-align: right;width: 13%;display: inline-block;"><b> Tentative </b></span>
            <span style="text-align: right;width: 12%;display: inline-block;"><b> Rebate </b></span>
            <span style="text-align: right;width: 11%;display: inline-block;"><b> Actual </b></span>
            <span style="text-align: right;width: 13%;display: inline-block;"><b> Bal. Due </b></span>
          </td>
        </tr>
        <tr>
          <td style="border-collapse:collapse;border: 0.1px solid black;font-size: 80%;">
            <span style="text-align: right;width: 37%;display: inline-block;"> Option Payment: </span>
            <span style="text-align: right;width: 11%;display: inline-block;"> ${Data.hs2} </span>
            <span style="text-align: right;width: 13%;display: inline-block;"> ${Data.hs1}</span>
            <span style="text-align: right;width: 12%;display: inline-block;"> </span>
            <span style="text-align: right;width: 11%;display: inline-block;"> </span>
            <span style="text-align: right;width: 13%;display: inline-block;"> </span>
          </td>
        </tr>
        `

        if(Data.hu != ''){
          RenderWorksheetFinalSheetLeft += `
            <tr>
              <td style="border-collapse:collapse;border: 0.1px solid black;font-size: 80%;">
                <span style="text-align: right;width: 37%;display: inline-block;"> Shrink Payment:  </span>
                <span style="text-align: right;width: 11%;display: inline-block;"> </span>
                <span style="text-align: right;width: 13%;display: inline-block;"> ${Data.hu} </span>
                <span style="text-align: right;width: 12%;display: inline-block;"> </span>
                <span style="text-align: right;width: 11%;display: inline-block;"> </span>
                <span style="text-align: right;width: 13%;display: inline-block;"> </span>
              </td>
            </tr>
          `
        }
        let LstrXML5Value = getData(thisObj,'LstrXML5')
        let LdblNet1007 = 0
        let LdblNetTAP = 0

        if(LstrXML5Value != undefined && LstrXML5Value != null && LstrXML5Value?.length >0){
          for(let i = 0; i< LstrXML5Value.length; i++){
            LdblNet1007 = LdblNet1007 + Number(LstrXML5Value[i].pd_orig_amount)
            LdblNetTAP = LdblNetTAP + Number(LstrXML5Value[i].pd_tap_amount)
            let Pd_New_Amount = LstrXML5Value[i].pd_new_amount != undefined && LstrXML5Value[i].pd_new_amount != null && LstrXML5Value[i].pd_new_amount != '' ? Number(LstrXML5Value[i].pd_new_amount).toFixed(2) : LstrXML5Value[i].pd_new_amount
            RenderWorksheetFinalSheetLeft += `
              <tr>
                <td style="border-collapse:collapse;border: 0.1px solid black;font-size: 80%;">
                  <span style="text-align: right;width: 37%;display: inline-block;"> ${LstrXML5Value[i].pd_desc} </span>
                  <span style="text-align: right;width: 11%;display: inline-block;"> ${LstrXML5Value[i].pd_orig_amount} </span>
                  <span style="text-align: right;width: 13%;display: inline-block;"> ${LstrXML5Value[i].pd_tap_amount} </span>
                  <span style="text-align: right;width: 12%;display: inline-block;"> </span>
                  <span style="text-align: right;width: 11%;display: inline-block;"> ${Number(Pd_New_Amount).toFixed(2)} </span>
                  <span style="text-align: right;width: 13%;display: inline-block;"> ${LstrXML5Value[i].balance} </span>
                </td>
              </tr>
            `
          }
        }


        if(Data.if != undefined && Data.if.toString() != ''){
          Data.if = Number(Data.if).toFixed(2)
        }
        if(Data.io != undefined && Data.io.toString() != ''){
          Data.io = Number(Data.io).toFixed(2)
        }
        if(Data.ir != undefined && Data.ir.toString() != ''){
          Data.ir = Number(Data.ir).toFixed(2)
        }
        if(Data.iu != undefined && Data.iu.toString() != ''){
          Data.iu = Number(Data.iu).toFixed(2)
        }
        if(Data.iw != undefined && Data.iw.toString() != ''){
          Data.iw = Number(Data.iw).toFixed(2)
        }
        if(Data.ja != undefined && Data.ja.toString() != ''){
          Data.ja = Number(Data.ja).toFixed(2)
        }
        if(Data.jd != undefined && Data.jd.toString() != ''){
          Data.jd = Number(Data.jd).toFixed(2)
        }
        if(Data.jf != undefined && Data.jf.toString() != ''){
          Data.jf = Number(Data.jf).toFixed(2)
        }
        if(Data.jh != undefined && Data.jh.toString() != ''){
          Data.jh = Number(Data.jh).toFixed(2)
        }
        if(Data.jj != undefined && Data.jj.toString() != ''){
          Data.jj = Number(Data.jj).toFixed(2)
        }
        if(Data.jl != undefined && Data.jl.toString() != ''){
          Data.jl = Number(Data.jl).toFixed(2)
        }
        if(Data.jp != undefined && Data.jp.toString() != ''){
          Data.jp = Number(Data.jp).toFixed(2)
        }
        
        
      RenderWorksheetFinalSheetLeft += `
        <tr>
          <td style="border-collapse:collapse;border: 0.1px solid black;font-size: 80%;">
            <span style="text-align: right;width: 37%;display: inline-block;"> ${Data.ic} </span>
            <span style="text-align: right;width: 11%;display: inline-block;"> ${Data.id}</span>
            <span style="text-align: right;width: 13%;display: inline-block;"> </span>
            <span style="text-align: right;width: 12%;display: inline-block;"> </span>
            <span style="text-align: right;width: 11%;display: inline-block;"> </span>
            <span style="text-align: right;width: 13%;display: inline-block;"> </span>
          </td>
        </tr>
        <tr>
          <td style="border-collapse:collapse;border: 0.1px solid black;font-size: 80%;">
            <span style="text-align: right;width: 37%;display: inline-block;"> ${Data.ie} </span>
            <span style="text-align: right;width: 11%;display: inline-block;"> </span>
            <span style="text-align: right;width: 13%;display: inline-block;"> ${Data.if} </span>
            <span style="text-align: right;width: 12%;display: inline-block;"> </span>
            <span style="text-align: right;width: 11%;display: inline-block;"> </span>
            <span style="text-align: right;width: 13%;display: inline-block;"> </span>
          </td>
        </tr>
      </table>
    `

    if(HeaderData.b != undefined && HeaderData != null && HeaderData.b != '' && HeaderData.b != ' '){
    RenderWorksheetFinalSheetRight += `
      <table style="margin-top: 1%; width:36%; font-size: 84%;border-collapse:collapse;display: inline-table;">
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span><b> Final Settlement </b></span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Description: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> Amount: </span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Purchase Value: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.io} </span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Option Payment: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.ir} </span>
          </td>
        </tr>
        `

        if(Data.iu != ''){
          RenderWorksheetFinalSheetRight += `
            <tr>
              <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
                <span style="text-align: right;width: 70%;display: inline-block;"> Shrink Payment: </span>
                <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.iu} </span>
              </td>
            </tr>
          `
        }

        if(Data.iw != ''){
          RenderWorksheetFinalSheetRight += `
            <tr>
              <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
                <span style="text-align: right;width: 70%;display: inline-block;"> Rebate Payment: </span>
                <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.iw} </span>
              </td>
            </tr>
          `
        }

        if(LstrXML5Value != undefined && LstrXML5Value != null && LstrXML5Value?.length >0){
          for(let i = 0; i< LstrXML5Value.length; i++){
            if(LstrXML5Value[i].pd_ind == 'P' && Number(LstrXML5Value[i].balance) != 0){
              RenderWorksheetFinalSheetRight += `
                <tr>
                  <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
                    <span style="text-align: right;width: 70%;display: inline-block;"> ${LstrXML5Value[i].pd_desc} </span>
                    <span style="text-align: right;width: 28%;display: inline-block;"> ${LstrXML5Value[i].balance} </span>
                  </td>
                </tr>
              `
            }
          }
        }

        RenderWorksheetFinalSheetRight +=`
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Sub Total: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.ja} </span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Less Loan Repayment: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.jd} </span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> To Producer: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.jf} </span>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Less Option Paid: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.jh} </span>
          </td>
        </tr>
        `

        if(Data.jj != ''){
          RenderWorksheetFinalSheetRight += `
            <tr>
              <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
                <span style="text-align: right;width: 70%;display: inline-block;"> Less Shrink Paid: </span>
                <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.jj} </span>
              </td>
            </tr>
          `
        }

        if(Data.jl != ''){
          RenderWorksheetFinalSheetRight += `
            <tr>
              <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
                <span style="text-align: right;width: 70%;display: inline-block;"> Less Rebate Paid: </span>
                <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.jl} </span>
              </td>
            </tr>
          `
        }

      if(LstrXML5Value != undefined && LstrXML5Value != null && LstrXML5Value?.length > 0){
        for(let i =0; i< LstrXML5Value.length; i++){
          if(LstrXML5Value[i].pd_ind == 'D'){
            if(Number(LstrXML5Value[i].balance) != 0){
              RenderWorksheetFinalSheetRight += `
                <tr>
                  <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
                    <span style="text-align: right;width: 70%;display: inline-block;"> ${LstrXML5Value[i].pd_desc} Adj. </span>
                    <span style="text-align: right;width: 28%;display: inline-block;"> ${LstrXML5Value[i].balance} </span>
                  </td>
                </tr>
              `
            }
          }
        }
      }

      RenderWorksheetFinalSheetRight +=`
        <tr>
          <td style="text-align: center;border: 0.1px solid black;border-collapse:collapse;">
            <span style="text-align: right;width: 70%;display: inline-block;"> Producer Total: </span>
            <span style="text-align: right;width: 28%;display: inline-block;"> ${Data.jp} </span>
          </td>
        </tr>
      </table>
    `
    }

    RenderWorksheetFinalSheet +=`
      ${RenderWorksheetFinalSheetLeft}
      ${RenderWorksheetFinalSheetRight}
    `
    return RenderWorksheetFinalSheet;
  }

  const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob)
  }

  //Logical part starts

  const rptWarehouseReceiptWorksheet = async (sBuyPtId, sWRNum, sRcptValue) => {

    let LstrXML2 = []
    let LstrXML3 = []
    let LstrXML4 = []
    let LstrXML5 = []
    let LstrXML5Hold = []
    let LstrXML6 = []
    let LstrXML6Hold = []
    let strInspNum = ''
    let strHoldInspNum = ''
    let nTestPrint = 0
    let sInspections = ''
    let dblNewProceeds = 0
    let dblPremiumTotal = 0
    let dblDeductionsTotal = 0
    let dblUnPaidRebate = 0
    let LstrCityState = ''
    let LstrFedWhsLic = ''
    let Lint1007_lYPos = 0
    let Lintcma = 0
    let dblBasisGradeAmount = 0
    let lngPounds = 0
    let dblOptionValue = 0
    let dblBasisValue = 0
    let dblFirmValue = 0
    let dblFirmValue_SEG23 = 0
    let dblpurchase_value = 0

    let LintContractCtr = 0
    let Ldbl100Pct1007Value = 0
    let Ldbl1007Lbs = 0
    let Ldbl1007OptionValue = 0
    let Ldbl1007BasisValue = 0
    let Ldbl1007FirmValue = 0

    let LdblNet1007 = 0
    let LdblNetTAP = 0

    let LdblNet_Wt_less_Lsk_wt = 0
    let LdblLsk_wt = 0
    let sPnutVarietyName = ''

    let wrws_lstrXML = ""
    let wrws_lYPos = 0
    let wrws_BPCityState = ""
    let wrws_LicenseNo = ""
    let wrws_CCCCodeNo = ""
    let wrws_mbPurchased = false
    let wrws_LstrText = ""
    let wrws_LintHeight = 0
    setData(thisObj, 'wrws_bOptionPayment', false)
    let wrws_sContractList = ""
    let wrws_sSeedContractList = ""
    let bContainsFirm = false
    let LblnFirst = true
    let Ldbl_WtAvg_OptionPerTon = 0
    let Ldbl_WtAvg_OptionPct = 0
    let wrpdf_OriginalBP = ''

    let compID = compIdFromLS()
    let cropYr = cropYearFromLS()

    setData(thisObj, 'msReport', "WRWorkSheet")
    let mbPrintTotal = false
    let bCMAPrint = false

    let WRWorksheetObj = {}

    wrws_lstrXML = await WarehouseReceiptService.RetrieveWareHouseReceiptWorkSheetReportDetails(compID, cropYr, sBuyPtId, sWRNum)
    if (wrws_lstrXML == undefined || wrws_lstrXML == null || wrws_lstrXML.length <= 0) {
      showMessage(thisObj, "Error Communicating with the database. Please reprint.")
      // return;
    }

    if(wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].rcpt_status != undefined && wrws_lstrXML[0].rcpt_status == 'P'){
      wrws_mbPurchased = true
    }

    if (sWRNum != undefined && sWRNum != null && sWRNum != '' && sWRNum.length > 0 && sWRNum.length < 7) {
      sWRNum = '0'.repeat(7 - sWRNum.length) + sWRNum
    }

    let wrpdf_ReceiptNo = sWRNum
    let wrpdf_ObligationBP = sBuyPtId
    LstrXML2 = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, sBuyPtId)
    if (LstrXML2 == undefined || LstrXML2 == null || LstrXML2 == '') {
      // return
    }

    LstrXML3 = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, LstrXML2[0].collPtId)
    if (LstrXML3 == undefined || LstrXML3 == null || LstrXML3.length <= 0) {
      // return
    }

    WRWorksheetObj.wrws_LicenseNo = LstrXML2[0].fedWhouseLicense
    WRWorksheetObj.wrws_CCCCodeNo = LstrXML2[0].cccLocationId


    LstrCityState = await WarehouseReceiptService.RetrieveWhouseLicenseControlCityStateDetails(compID, cropYr, wrws_LicenseNo)
    if (LstrCityState == undefined || LstrCityState == null || LstrCityState == '') {
      // return
    }

    WRWorksheetObj.L11_IssuedAt = LstrCityState[0].physical_city
    WRWorksheetObj.L11_IssuedAtCityState = LstrCityState[0].physical_state

    // rptWarehouseReceiptWorksheetHeader()
    if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && (wrws_lstrXML[0].tent_cont_appl_ind == "O") && (wrws_lstrXML[0].rcpt_status) == 'I') {
      setData(thisObj, 'wrws_bOptionPayment', true)
    }

    WRWorksheetObj.a = "Contracts"

    WRWorksheetObj.b = "Contract Number"
    WRWorksheetObj.c = "Type"
    WRWorksheetObj.d = "Pricing Line"
    WRWorksheetObj.e = "Tentative Lbs"

    wrws_sContractList = wrws_lstrXML == undefined || wrws_lstrXML.length <= 0 || wrws_lstrXML[0].contracts == undefined ? [] :  wrws_lstrXML[0].contracts
    WRWorksheetObj.wrws_sContractList = wrws_sContractList
    wrws_sSeedContractList = wrws_lstrXML == undefined || wrws_lstrXML.length <= 0 || wrws_lstrXML[0].seed_contracts == undefined ? [] : wrws_lstrXML[0].seed_contracts
    WRWorksheetObj.wrws_sSeedContractList = wrws_sSeedContractList

    if (wrws_sContractList != undefined && wrws_sContractList != null && wrws_sContractList != '') {
      WRWorksheetObj.f = wrws_sContractList[0].contract_num
      WRWorksheetObj.g = wrws_sContractList[0].cont_type

      if (wrws_sContractList[0].pricing_line == '1') {
        WRWorksheetObj.h = "Firm" 
      }
      else {
        WRWorksheetObj.h = "Basis"
      }

      WRWorksheetObj.wrws_mbPurchased = wrws_mbPurchased
      if (wrws_mbPurchased) {
        WRWorksheetObj.i = Number(wrws_sContractList[0].appl_lbs_adj)
      }
      else {
        WRWorksheetObj.i = Number(wrws_sContractList[0].tent_lbs_adj)
      }
    }

    if (wrws_LstrText == '') {
      WRWorksheetObj.j = ""
    }
    wrws_LstrText = ""

    if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].spot_pounds != undefined && wrws_lstrXML[0].spot_pounds != null && wrws_lstrXML[0].spot_pounds != '') {
      WRWorksheetObj.k = "Spot"
      WRWorksheetObj.l = "Firm"
      WRWorksheetObj.m = wrws_lstrXML[0].spot_pounds
    }
    else{
      WRWorksheetObj.k = ""
      WRWorksheetObj.l = ""
      WRWorksheetObj.m = ''
    }


    wrws_sSeedContractList = wrws_lstrXML == undefined || wrws_lstrXML.length <= 0 || wrws_lstrXML[0].seed_contracts == undefined ? '' : wrws_lstrXML[0].seed_contracts
    if (wrws_sSeedContractList != undefined && wrws_sSeedContractList != null && wrws_sSeedContractList != '') {
      WRWorksheetObj.n = wrws_sSeedContractList[0].seed_contract_num
      WRWorksheetObj.o = "Seed"
      WRWorksheetObj.p = "N/A"
      WRWorksheetObj.pp = wrws_sSeedContractList[0].seed_appl_lbs
    }

    WRWorksheetObj.q = " Grade Certificate Details:"

    WRWorksheetObj.r = ""
    WRWorksheetObj.s = "Loc"
    WRWorksheetObj.t = "Insp Num"
    WRWorksheetObj.u = "Farm Num"
    WRWorksheetObj.v = ""
    WRWorksheetObj.w = "1007 Num"
    WRWorksheetObj.x = "Seed Cls"
    WRWorksheetObj.y = "Var",
    WRWorksheetObj.z = "Insp Date"

    WRWorksheetObj.aa = "Net Lbs."
    WRWorksheetObj.ab = "Net Tons."
    WRWorksheetObj.ac = "LSK"
    WRWorksheetObj.ad = "Net-LSK"
    WRWorksheetObj.ae = "tkc"
    WRWorksheetObj.af = "smk"
    WRWorksheetObj.ag = "ss"
    WRWorksheetObj.ah = "ok"
    WRWorksheetObj.ai = "dam"
    WRWorksheetObj.aj = "hul"
    WRWorksheetObj.ak = "fm"
    WRWorksheetObj.al = "lsk"
    WRWorksheetObj.am = "moi"
    WRWorksheetObj.an = "fan"
    WRWorksheetObj.ao = "elk"
    WRWorksheetObj.ap = "cr"
    WRWorksheetObj.aq = "fd"

    WRWorksheetObj.ar = "100% Value"
    WRWorksheetObj.as = "Contract"
    WRWorksheetObj.at = "Pounds"
    WRWorksheetObj.au = "Option VPT"
    WRWorksheetObj.av = "Option % Supt."
    WRWorksheetObj.aw = "Option Value"

    if (wrws_mbPurchased) {
      WRWorksheetObj.ax = "Basis VPT"
      WRWorksheetObj.ay = "Basis % Supt."
      WRWorksheetObj.az = "Basis Value"
      WRWorksheetObj.ba = "Firm VPT"
      WRWorksheetObj.bb = "Firm % Supt."
      WRWorksheetObj.bc = "Firm Value"
    }

    sInspections = wrws_lstrXML == undefined || wrws_lstrXML.length <= 0 || wrws_lstrXML[0].inspect_headers == undefined ? '' : wrws_lstrXML[0].inspect_headers // Grade Certification Details
    WRWorksheetObj.sInspections = wrws_lstrXML == undefined || wrws_lstrXML.length <= 0 || wrws_lstrXML[0].inspect_headers == undefined ? '' : wrws_lstrXML[0].inspect_headers

    if (sInspections != undefined && sInspections != null && sInspections != '') {
      for (let i = 0; i < sInspections.length; i++) {

          // rptWarehouseReceiptWorksheetHeader()

          WRWorksheetObj.bd = "Loc"
          WRWorksheetObj.be = "Insp Num"
          WRWorksheetObj.bf = "Farm Num"
          WRWorksheetObj.bh = ""
          WRWorksheetObj.bi = "1007 Num"
          WRWorksheetObj.bj = "Seed Cls"
          WRWorksheetObj.bk = "Var"
          WRWorksheetObj.bl = "Insp Date"

          WRWorksheetObj.bm = "Net Lbs."
          WRWorksheetObj.bn = "Net Tons."
          WRWorksheetObj.bo = "LSK"
          WRWorksheetObj.bp = "Net-LSK"
          WRWorksheetObj.bq = "tkc"
          WRWorksheetObj.br = "smk"
          WRWorksheetObj.bs = "ss"
          WRWorksheetObj.bt = "ok"
          WRWorksheetObj.bu = "dam"
          WRWorksheetObj.bv = "hul"
          WRWorksheetObj.bw = "fm"
          WRWorksheetObj.bx = "lsk"
          WRWorksheetObj.by = "moi"
          WRWorksheetObj.bz = "fan"
          WRWorksheetObj.ca = "elk"
          WRWorksheetObj.cb = "cr"
          WRWorksheetObj.cc = "fd"

          WRWorksheetObj.cd = "100% Value"
          WRWorksheetObj.ce = "Contract"
          WRWorksheetObj.cf = "Pounds"
          WRWorksheetObj.cg = "Option VPT"
          WRWorksheetObj.ch = "Option % Supt."
          WRWorksheetObj.ci = "Option Value"

          WRWorksheetObj.cj = ""
          WRWorksheetObj.ck = ""
          WRWorksheetObj.cl = ""
          WRWorksheetObj.cm = ""
          WRWorksheetObj.cn = ""
          WRWorksheetObj.co = ""

          if (wrws_mbPurchased) {
            WRWorksheetObj.cj = "Basis VPT"
            WRWorksheetObj.ck = "Basis % Supt."
            WRWorksheetObj.cl = "Basis Value"
            WRWorksheetObj.cm = "Firm VPT"
            WRWorksheetObj.cn = "Firm % Supt."
            WRWorksheetObj.co = "Firm Value"
          }
          LblnFirst = true
        

        if (LblnFirst) {
          wrws_lYPos = wrws_lYPos + 600
          LblnFirst = false
        }

        Lint1007_lYPos = wrws_lYPos
        WRWorksheetObj.cp = sInspections[0].buy_pt_id
        wrpdf_OriginalBP = sInspections[0].buy_pt_id
        strInspNum = sInspections[0].insp_num
        WRWorksheetObj.cq = strInspNum

        if (sInspections[0].farm_name.length >= 11) {
          WRWorksheetObj.cr = (sInspections[0].farm_name).split('-')
          WRWorksheetObj.cr1 = WRWorksheetObj.cr[0]
          WRWorksheetObj.cr2 = WRWorksheetObj.cr[1]
          WRWorksheetObj.cr3 = WRWorksheetObj.cr[2]
          WRWorksheetObj.cr4 = WRWorksheetObj.cr1 + '-' + WRWorksheetObj.cr2
        }
        else {
          WRWorksheetObj.cr = ""
        }
        WRWorksheetObj.cs = sInspections[0].farm_county + " " + sInspections[0].farm_state
        WRWorksheetObj.ct = sInspections[0].settlement_num
        WRWorksheetObj.cu = sInspections[0].seed_gen
        sPnutVarietyName = sInspections[0].pnut_variety_name

        switch (sInspections[0].symbol_ind) {
          case "TRADEMARK":
            sPnutVarietyName = sPnutVarietyName + `&reg;`
            break;
          case "REGISTERED TRADEMARK":
            sPnutVarietyName = sPnutVarietyName + `&trade;`
            break;
          case "COPYRIGHT":
            sPnutVarietyName = sPnutVarietyName + `&copy;`
            break;
          default:
            break;
        }


        WRWorksheetObj.cv = sPnutVarietyName
        WRWorksheetObj.cw = sInspections[0].insp_date_time

        WRWorksheetObj.cx = sInspections[0].net_wt

        WRWorksheetObj.cy = Number(sInspections[0].net_wt) / 2000

        WRWorksheetObj.cz = sInspections[0].lsk_wt
        WRWorksheetObj.da = sInspections[0].net_wt - sInspections[0].lsk_wt
        WRWorksheetObj.db = sInspections[0].tkc_pct
        WRWorksheetObj.dc = sInspections[0].smk_pct
        WRWorksheetObj.dd = sInspections[0].ss_pct
        WRWorksheetObj.de = sInspections[0].ok_pct
        WRWorksheetObj.df = sInspections[0].dam_pct
        WRWorksheetObj.dg = sInspections[0].hull_pct
        WRWorksheetObj.dh = sInspections[0].fm_pct
        WRWorksheetObj.di = sInspections[0].lsk_pct
        WRWorksheetObj.dj = sInspections[0].moist_pct
        WRWorksheetObj.dk = sInspections[0].fan_pct
        WRWorksheetObj.dl = sInspections[0].elk_pct
        WRWorksheetObj.dm = sInspections[0].cr_br_pct
        WRWorksheetObj.dn = sInspections[0].frez_dam_pct


        LintContractCtr = 0
        Ldbl100Pct1007Value = 0
        Ldbl1007OptionValue = 0
        Ldbl1007BasisValue = 0
        Ldbl1007FirmValue = 0

        wrws_sContractList = sInspections[i].contract_lines

        wrws_LstrText = wrws_sContractList

        if (wrws_LstrText != undefined && wrws_LstrText != null && wrws_LstrText != '') { // check later
          for (let i = 0; i < wrws_LstrText.length; i++) {

            if (wrws_LstrText[i].contract_type == "O") {
              LintContractCtr = LintContractCtr + 1
            }

            if (LintContractCtr > 1) {
              wrws_LstrText = "*"
              break
            }
          }
        }

        LintContractCtr = 1
        if(wrws_sContractList != undefined && wrws_sContractList != null && wrws_sContractList != ''){
        Ldbl100Pct1007Value = wrws_sContractList[0].pct_basis_grade == undefined || wrws_sContractList[0].pct_basis_grade == null || wrws_sContractList[0].pct_basis_grade == '' ? '' : Number(wrws_sContractList[0].pct_basis_grade)
        Ldbl1007Lbs = wrws_sContractList[0].pounds == undefined || wrws_sContractList[0].pounds == null || wrws_sContractList[0].pounds == '' ? '' :  Number(wrws_sContractList[0].pounds)
        Ldbl1007OptionValue = Number(wrws_sContractList[0].option_value)
        Ldbl1007BasisValue = Number(wrws_sContractList[0].basis_value)
        Ldbl1007FirmValue = Number(wrws_sContractList[0].firm_value)
        dblBasisGradeAmount = 0
        
        for (let j = 0; j < wrws_sContractList.length; j++) {
          WRWorksheetObj.do = Number(wrws_sContractList[j].pct_basis_grade)
          dblBasisGradeAmount = Number(dblBasisGradeAmount) + Number(wrws_sContractList[j].pct_basis_grade)
          WRWorksheetObj.dp = wrws_sContractList[j].contract_num
          WRWorksheetObj.dq = Number(wrws_sContractList[j].pounds)
          lngPounds = lngPounds + wrws_sContractList[j].pounds
          WRWorksheetObj.dr = Number(wrws_sContractList[j].option_price)
          WRWorksheetObj.ds = Number(wrws_sContractList[j].option_pct_support)
          if (wrws_LstrText == "*") {
            WRWorksheetObj.dt = wrws_sContractList[j].option_value
            dblOptionValue = dblOptionValue + Number(wrws_sContractList[j].option_value)
          }
          else {
            WRWorksheetObj.du = Number(wrws_sContractList)
            dblOptionValue = dblOptionValue + Number(wrws_sContractList[j].option_value)
          }

          if (wrws_mbPurchased) {
            WRWorksheetObj.dv = Number(wrws_sContractList[j].basis_price)
            WRWorksheetObj.dw = Number(wrws_sContractList[j].basis_pct_support)
            WRWorksheetObj.dx = Number(wrws_sContractList[j].basis_value)
            dblBasisValue = dblBasisValue + Number(wrws_sContractList)
            WRWorksheetObj.dy = Number(wrws_sContractList[j].firm_price)
            WRWorksheetObj.dz = Number(wrws_sContractList[j].firm_pct_support)

            //commented and added - 27th Aug'2024 - PRB0054189/INC5920772
            //if ((Number(wrws_lstrXML[i].seg_type) > 1) && (Number(wrws_lstrXML[i].ricing_line) == 1) && (wrws_lstrXML[i].redeem_method_ind == "U")) {
              if ((Number(wrws_lstrXML.seg_type) > 1) && (wrws_lstrXML.redeem_method_ind == "U") && (Number(wrws_lstrXML.pricing_line) == 1))  {  
           // end of changes
                dblFirmValue_SEG23 = Number(sRcptValue * Number(wrws_sContractList[j].firm_pct_support)) * 0.01 * Number(wrws_sContractList[j].firm_pct_wr)
              WRWorksheetObj.ea = dblFirmValue_SEG23
              dblFirmValue = dblFirmValue + dblFirmValue_SEG23
            }
            else {
              WRWorksheetObj.ea = Number(wrws_sContractList[j].firm_value)
              dblFirmValue = dblFirmValue + Number(wrws_sContractList[j].firm_value)
            }
          }

          i = i + 1

          if (wrws_sContractList != undefined && wrws_sContractList != null && wrws_sContractList != '') {
            LintContractCtr = LintContractCtr + 1
            Ldbl100Pct1007Value = Ldbl100Pct1007Value + Number(wrws_sContractList[0].pct_basis_grade)
            Ldbl1007Lbs = Ldbl1007Lbs + Number(wrws_sContractList[0].pounds)
            Ldbl1007OptionValue = Ldbl1007OptionValue + Number(wrws_sContractList[0].option_value)
            Ldbl1007BasisValue = Ldbl1007BasisValue + Number(wrws_sContractList[0].basis_value)
            Ldbl1007FirmValue = Ldbl1007FirmValue + Number(wrws_sContractList[0].firm_value)
          }
          else {
            if (LintContractCtr > 1) {
              WRWorksheetObj.eb = Number(Ldbl100Pct1007Value)
              WRWorksheetObj.ec = Number(Ldbl1007Lbs)
              WRWorksheetObj.ed = Number(Ldbl1007OptionValue)

              if (wrws_mbPurchased) {
                WRWorksheetObj.ee = Number(Ldbl1007BasisValue)
                WRWorksheetObj.ef = Number(Ldbl1007FirmValue)
              }
            }

            LintContractCtr = 1
            Ldbl100Pct1007Value = Number(wrws_sContractList[0].pct_basis_grade)
            Ldbl1007Lbs = Number(wrws_sContractList[0].pounds)
            Ldbl1007OptionValue = Number(wrws_sContractList[0].option_value)
            Ldbl1007BasisValue = Number(wrws_sContractList[0].basis_value)
            Ldbl1007FirmValue = Number(wrws_sContractList[0].firm_value)
          }
        }
        }
        else{
          dblBasisGradeAmount = dblBasisGradeAmount + Number(sInspections[i].basis_grade_amt_1007)
          lngPounds = lngPounds + Number(sInspections[i].net_wt)
          dblOptionValue = dblOptionValue + Number(sInspections[i].opt_value_of_seg)
          WRWorksheetObj.eg = Number(sInspections[i].basis_grade_amt_1007)
          wrws_lYPos = wrws_lYPos + 200
        }

        wrws_lYPos = wrws_lYPos + 600
        wrws_LstrText = ""
        if (strInspNum != strHoldInspNum) {
          LstrXML6 = sInspections[0].premium_deduction_headers
          for (let i = 0; i < LstrXML6.length; i++) {
            if (LstrXML6[i].premium_deduction_header != undefined && LstrXML6[i].premium_deduction_header > 0) {
              wrws_LstrText = wrws_LstrText + LstrXML6[i].pd_code + "=" + Number(LstrXML6[i].pd_amount) + ", "
            }
          }
          if (wrws_LstrText == ', ') {  //Check later
            wrws_LstrText = wrws_LstrText // check later
          }
        }

        strHoldInspNum = strInspNum
        WRWorksheetObj.eh = wrws_LstrText
        WRWorksheetObj.ei = ""

        mbPrintTotal = true

      }
    }

    if (mbPrintTotal) {
      wrws_LstrText = "Totals: "
      WRWorksheetObj.ej = wrws_LstrText

      wrws_LstrText = ""
      LstrXML6 = wrws_lstrXML == undefined || wrws_lstrXML.length <= 0 || wrws_lstrXML[0].premium_deduction_totals == undefined ? [] : wrws_lstrXML[0].premium_deduction_totals

      if (LstrXML6 != undefined && LstrXML6 != null && LstrXML6 != '') {
        for (let i = 0; i < LstrXML6.length; i++) {
          wrws_LstrText = wrws_LstrText + LstrXML6[i].pd_code + "=" + LstrXML6[i].pd_amount + ", "
        }
      }

      if (wrws_LstrText == ', ') { // check later
        wrws_LstrText = wrws_LstrText // check later
      }

      WRWorksheetObj.ek = wrws_LstrText
    }
    wrws_LstrText = ""

    WRWorksheetObj.el = ""
    WRWorksheetObj.em = "Weighted Average, Total Weights and Total Amounts"
    WRWorksheetObj.en = "Insp Date"

    WRWorksheetObj.eo = "Total Lbs."
    WRWorksheetObj.ep = "Total Tons."
    WRWorksheetObj.eq = "LSK"
    WRWorksheetObj.er = "Net-LSK"
    WRWorksheetObj.es = "tkc"
    WRWorksheetObj.et = "smk"
    WRWorksheetObj.eu = "ss"
    WRWorksheetObj.ev = "ok"
    WRWorksheetObj.ew = "dam"
    WRWorksheetObj.ex = "hul"
    WRWorksheetObj.ey = "fm"
    WRWorksheetObj.ez = "lsk"
    WRWorksheetObj.fa = "moi"
    WRWorksheetObj.fb = "fan"
    WRWorksheetObj.fc = "elk"
    WRWorksheetObj.fd = "cr"
    WRWorksheetObj.fe = "fd"

    WRWorksheetObj.ff = "100% 1007 Value"
    WRWorksheetObj.fg = "Total Lbs."

    if (wrws_mbPurchased) {
      WRWorksheetObj.fh = "Basis Value"
      WRWorksheetObj.fi = "Firm Value"
    }

    if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0) {
      WRWorksheetObj.fj = ""
      WRWorksheetObj.fk = wrws_lstrXML[0].store_date
      WRWorksheetObj.fl = wrws_lstrXML[0].net_wt

      LdblNet_Wt_less_Lsk_wt = Number((Number(wrws_lstrXML[0].net_wt) - Number(wrws_lstrXML[0].lsk_wt)) / 2000).toFixed(2) // check later
      LdblLsk_wt = Number(Number(wrws_lstrXML[0].lsk_wt) / 2000).toFixed(2)
      WRWorksheetObj.fm = Number(Number(LdblNet_Wt_less_Lsk_wt) + Number(LdblLsk_wt)).toFixed(2)
      WRWorksheetObj.fn = wrws_lstrXML[0].lsk_wt
      WRWorksheetObj.fo = Number(wrws_lstrXML[0].net_wt) - Number(wrws_lstrXML[0].lsk_wt)
      WRWorksheetObj.fp = Number(wrws_lstrXML[0].wa_tkc_pct)
      WRWorksheetObj.fq = Number(wrws_lstrXML[0].wa_smk_pct)
      WRWorksheetObj.fr = Number(wrws_lstrXML[0].wa_ss_pct)
      WRWorksheetObj.fs = Number(wrws_lstrXML[0].wa_ok_pct)
      WRWorksheetObj.ft = Number(wrws_lstrXML[0].wa_dam_pct)
      WRWorksheetObj.fu = Number(wrws_lstrXML[0].wa_hull_pct)
      WRWorksheetObj.fv = Number(wrws_lstrXML[0].wa_fm_pct)
      WRWorksheetObj.fw = Number(wrws_lstrXML[0].wa_lsk_pct)
      WRWorksheetObj.fx = Number(wrws_lstrXML[0].wa_moist_pct)
      WRWorksheetObj.fy = Number(wrws_lstrXML[0].wa_fan_pct)
      WRWorksheetObj.fz = Number(wrws_lstrXML[0].wa_elk_pct)
      WRWorksheetObj.ga = Number(wrws_lstrXML[0].wa_cr_br_pct)
      WRWorksheetObj.gb = Number(wrws_lstrXML[0].wa_frez_dam_pct)

      WRWorksheetObj.gc = "Shrink %"
      WRWorksheetObj.gd = wrws_lstrXML[0].shrink_pct
      WRWorksheetObj.ge = wrws_lstrXML[0].shrink_net_wt

      LdblNet_Wt_less_Lsk_wt = Number((Number(wrws_lstrXML[0].shrink_net_wt) - Number(wrws_lstrXML[0].shrink_lsk_wt)) / 2000).toFixed(2)
      LdblLsk_wt = Number(Number(wrws_lstrXML[0].shrink_lsk_wt) / 2000).toFixed(2)
      WRWorksheetObj.gf = Number(Number(LdblNet_Wt_less_Lsk_wt) + Number(LdblLsk_wt)).toFixed(2)
      WRWorksheetObj.gg = wrws_lstrXML[0].shrink_lsk_wt
      WRWorksheetObj.gh = Number(wrws_lstrXML[0].shrink_net_wt) - Number(wrws_lstrXML[0].shrink_lsk_wt)

      WRWorksheetObj.gi = Number(dblBasisGradeAmount)
      WRWorksheetObj.gj = Number(lngPounds)
      WRWorksheetObj.gk = Number(dblOptionValue)

      WRWorksheetObj.gl = ''
      WRWorksheetObj.gm = ''
      if (wrws_mbPurchased) {
        WRWorksheetObj.gl = Number(dblBasisValue)
        WRWorksheetObj.gm = Number(dblFirmValue)
      }
    }
    else {
      WRWorksheetObj.fj = ""
      WRWorksheetObj.fk = ''
      WRWorksheetObj.fl = ''

      LdblNet_Wt_less_Lsk_wt = ''
      LdblLsk_wt = ''
      WRWorksheetObj.fm = ''
      WRWorksheetObj.fn = ''
      WRWorksheetObj.fo = ''
      WRWorksheetObj.fp = ''
      WRWorksheetObj.fq = ''
      WRWorksheetObj.fr = ''
      WRWorksheetObj.fs = ''
      WRWorksheetObj.ft = ''
      WRWorksheetObj.fu = ''
      WRWorksheetObj.fv = ''
      WRWorksheetObj.fw = ''
      WRWorksheetObj.fx = ''
      WRWorksheetObj.fy = ''
      WRWorksheetObj.fz = ''
      WRWorksheetObj.ga = ''
      WRWorksheetObj.gb = ''

      WRWorksheetObj.gc = ""
      WRWorksheetObj.gd = ''
      WRWorksheetObj.ge = ''

      LdblNet_Wt_less_Lsk_wt = ''
      LdblLsk_wt = ''
      WRWorksheetObj.gf = ''
      WRWorksheetObj.gg = ''
      WRWorksheetObj.gh = ''

      WRWorksheetObj.gi = ''
      WRWorksheetObj.gj = ''
      WRWorksheetObj.gk = ''

      WRWorksheetObj.gl = ''
      WRWorksheetObj.gm = ''
    }

    LblnFirst = true

    if (wrws_mbPurchased || getData(thisObj, 'wrws_bOptionPayment')) {

      // Call rptWarehouseReceiptWorksheetHeader

      WRWorksheetObj.gn = ""
      WRWorksheetObj.go = "Contracts"

      WRWorksheetObj.gp = ""
      WRWorksheetObj.gq = "Contract Number"
      WRWorksheetObj.gr = "Type"
      WRWorksheetObj.gs = "Pricing Line"

      if (wrws_mbPurchased) {
        WRWorksheetObj.gt = "Applied Lbs"
      }
      else {
        WRWorksheetObj.gt = "Tentative Lbs"
      }

      let LstrXML4 = await WarehouseReceiptService.RetrieveWarehouseContractApplicationDetails(compID, cropYr, sBuyPtId, sWRNum)
      if (LstrXML4 == undefined || LstrXML4 == null || LstrXML4.length <= 0) {
        // return
      }

      if (LstrXML4 != undefined && LstrXML4 != null && LstrXML4 != '') {
        for (let i = 0; i < LstrXML4.length; i++) {
          if (Number(LstrXML4[i].appl_lbs_adj) != 0 || Number(LstrXML4[i].tent_lbs_adj) != 0) {
            WRWorksheetObj.gu = ""
            WRWorksheetObj.gv = LstrXML4[i].contract_num
            WRWorksheetObj.gw = LstrXML4[i].cont_type
            if (LstrXML4[i].pricing_line == 1) {
              WRWorksheetObj.gx = "Firm"
              bContainsFirm = true
            }
            else {
              WRWorksheetObj.gx = "Basis"
            }

            if (wrws_mbPurchased) {
              WRWorksheetObj.gy = Number(LstrXML4[i].appl_lbs_adj)
            }
            else {
              WRWorksheetObj.gy = Number(LstrXML4[i].tent_lbs_adj)
            }
          }
        }
      }

      if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].spot_pounds != undefined && wrws_lstrXML[0].spot_pounds != null && wrws_lstrXML[0].spot_pounds != '') {
        WRWorksheetObj.gz = ""
        WRWorksheetObj.ha = "Spot"
        WRWorksheetObj.hb = "Firm"
        WRWorksheetObj.hc = wrws_lstrXML[0].spot_pounds
      }
      else{
        WRWorksheetObj.gz = ""
        WRWorksheetObj.ha = ""
        WRWorksheetObj.hb = ""
        WRWorksheetObj.hc = ''
      }

      wrws_sSeedContractList = wrws_lstrXML != undefined && wrws_lstrXML.length > 0 ? wrws_lstrXML[0].seed_contracts : ''
      if (wrws_sSeedContractList != undefined && wrws_sSeedContractList != null && wrws_sSeedContractList != '') {
        for (let i = 0; i < wrws_sSeedContractList.length; i++) {
          WRWorksheetObj.hd = ""
          WRWorksheetObj.he = wrws_sSeedContractList[0].seed_contract_num
          WRWorksheetObj.hf = "Seed"
          WRWorksheetObj.hg = "N/A"

          WRWorksheetObj.hh = wrws_sSeedContractList[0].seed_appl_lbs
        }
      }


      WRWorksheetObj.hi = ""
      WRWorksheetObj.hj = "Option Payment or Rebate and Deductions"

      // LdblNet1007 = 0
      // LdblNetTAP = 0

      WRWorksheetObj.hk = ""
      WRWorksheetObj.hl = "Description"
      WRWorksheetObj.hm = "1007"
      WRWorksheetObj.hn = "Tentative"
      WRWorksheetObj.ho = "Rebate"
      WRWorksheetObj.hp = "Actual"
      WRWorksheetObj.hq = "Bal. Due"


      WRWorksheetObj.hr = "Option Payment:"
      WRWorksheetObj.hs1 = ''
      WRWorksheetObj.hs2 = ''

      if(wrws_lstrXML != undefined && wrws_lstrXML.length > 0){
      if (wrws_lstrXML[0].tent_cont_appl_ind == 'O') {
        LdblNetTAP = Number(wrws_lstrXML[0].option_payment)
        LdblNetTAP = LdblNetTAP + Number(wrws_lstrXML[0].shrink_payment)
        WRWorksheetObj.hs1 = Number(wrws_lstrXML[0].option_payment)
      }
      else {
        LdblNet1007 = Number(wrws_lstrXML[0].option_payment)
        WRWorksheetObj.hs2 = Number(wrws_lstrXML[0].option_payment)
      }
      WRWorksheetObj.hu = ''
      if (wrws_lstrXML[0].shrink_payment != "") {
        WRWorksheetObj.ht = "Shrink Payment:"
        WRWorksheetObj.hu = Number(wrws_lstrXML[0].shrink_payment)
      }

      if (wrws_lstrXML[0].rebate_details != "") {
        wrws_sRebateList = wrws_lstrXML[0].rebate_details
        for (let i = 0; i < wrws_sRebateList.length; i++) {
          WRWorksheetObj.hv = wrws_sRebateList[i].contract_num + " Rebate Payment:"
          WRWorksheetObj.hw = Number(wrws_sRebateList[i].rebate_value)
        }
      }
    }
    else{
      WRWorksheetObj.hs1 = ''
      WRWorksheetObj.hs2 = ''
      WRWorksheetObj.ht = ''
      WRWorksheetObj.hu = ''
      WRWorksheetObj.hv = ''
      WRWorksheetObj.hw = ''
    }

      LstrXML5 = await WarehouseReceiptService.RetrieveWareHouseWorkSheetPremiumDeductionBalanceDetails(sBuyPtId, sWRNum)
      if (LstrXML5 == undefined || LstrXML5 == null || LstrXML5.length <= 0) {
        // return;
      }
      setData(thisObj,'LstrXML5',LstrXML5)
      LstrXML5Hold = LstrXML5
      for (let i = 0; i < LstrXML5.length; i++) {
        WRWorksheetObj.hx = LstrXML5[i].pd_desc + ":"
        WRWorksheetObj.hy = Number(LstrXML5[i].pd_orig_amount)
        WRWorksheetObj.hz = Number(LstrXML5[i].pd_tap_amount)
        WRWorksheetObj.ia = Number(LstrXML5[i].pd_new_amount)
        WRWorksheetObj.ib = Number(LstrXML5[i].balance)
        LdblNet1007 = LdblNet1007 + Number(LstrXML5[i].pd_orig_amount)
        LdblNetTAP = LdblNetTAP + Number(LstrXML5[i].pd_tap_amount)
      }


      WRWorksheetObj.a = ""
      if (Number(LdblNet1007) < 0) {
        WRWorksheetObj.ic = "Open Account:"
      }
      else {
        WRWorksheetObj.ic = "Net to Producer:"
      }

      WRWorksheetObj.id = Number(LdblNet1007).toFixed(2)


      if (Number(LdblNetTAP) < 0) {
        WRWorksheetObj.ie = "Open Account:"
      } else {
        WRWorksheetObj.ie = "Net to Producer:"
      }

      WRWorksheetObj.if = Number(LdblNetTAP)

      if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].total_rebate != 0) {

        if (wrws_lstrXML[0].total_rebate < 0) {
          WRWorksheetObj.ig = "Rebate Open Account:"
        }
        else {
          WRWorksheetObj.ig = "Rebate to Producer:"
        }
        WRWorksheetObj.ih = Number(wrws_lstrXML[0].total_rebate)
      }
      else{
        WRWorksheetObj.ig = ''
        WRWorksheetObj.ih = ''
      }

      LstrXML5 = LstrXML5Hold
      setData(thisObj,'LstrXML5',LstrXML5Hold)

      if (bContainsFirm) {
        WRWorksheetObj.ii = "Loan Amount"

        WRWorksheetObj.ij = Number(wrws_lstrXML[0].loan_amount)
      }

      if (wrws_mbPurchased) {
        ////vsp_Viewer.TextBox "Final Settlement"

        WRWorksheetObj.ik = "Description:"
        WRWorksheetObj.il = "Amount:"

        dblpurchase_value = Number(wrws_lstrXML[0].value_of_seg)

        WRWorksheetObj.im = ""
        WRWorksheetObj.in = "Purchase Value:"

        WRWorksheetObj.io = Number(dblpurchase_value - Number(wrws_lstrXML[0].option_payment)) - Number(wrws_lstrXML[0].shrink_payment)

        WRWorksheetObj.ip = ""
        WRWorksheetObj.iq = "Option Payment:"
        WRWorksheetObj.ir = Number(wrws_lstrXML[0].option_payment)

        WRWorksheetObj.iu = ''
        if (wrws_lstrXML[0].shrink_payment != "") {
          WRWorksheetObj.is = ""
          WRWorksheetObj.it = "Shrink Payment:"
          WRWorksheetObj.iu = Number(wrws_lstrXML[0].shrink_payment)
        }

        WRWorksheetObj.iw = ''
        if (Number(wrws_lstrXML[0].total_rebate) != 0) {
          WRWorksheetObj.iv = "Rebate Payment:"
          WRWorksheetObj.iw = Number(wrws_lstrXML[0].total_rebate)
          dblpurchase_value = dblpurchase_value + Number(wrws_lstrXML[0].total_rebate)
        }

        dblPremiumTotal = 0
        LstrXML5 = LstrXML5Hold
        setData(thisObj,'LstrXML5',LstrXML5Hold)

        for (let i = 0; i < LstrXML5.length; i++) {
          if (LstrXML5[i].pd_ind == 'P') {
            if (Number(LstrXML5[i].balance) != 0) {
              WRWorksheetObj.ix = LstrXML5[i].pd_desc
              WRWorksheetObj.iy = Number(LstrXML5[i].balance)
              dblPremiumTotal = dblPremiumTotal + Number(LstrXML5[i].balance)
            }
          }
        }

        WRWorksheetObj.iz = "Sub Total:"
        WRWorksheetObj.ja = Number(dblpurchase_value)

        WRWorksheetObj.jb = ""
        WRWorksheetObj.jc = "Less Loan Repayment:"
        WRWorksheetObj.jd = Number(wrws_lstrXML[0].loan_repay_amt) * (-1)


        WRWorksheetObj.je = "To Producer:"
        WRWorksheetObj.jf = (Number(wrws_lstrXML[0].value_of_seg) + dblPremiumTotal) - Number(wrws_lstrXML[0].loan_repay_amt)

        WRWorksheetObj.jg = "Less Option Paid:"
        WRWorksheetObj.jh = Number(wrws_lstrXML[0].option_payment) * (-1)

        WRWorksheetObj.jj = ''
        if (wrws_lstrXML[0].shrink_payment != "") {
          WRWorksheetObj.ji = "Less Shrink Paid:"
          WRWorksheetObj.jj = Number(wrws_lstrXML[0].shrink_payment) * (-1)
        }

        WRWorksheetObj.jl = ''
        if (Number(wrws_lstrXML[0].paid_rebate) != 0) {
          WRWorksheetObj.jk = "Less Rebate Paid:"
          WRWorksheetObj.jl = Number(wrws_lstrXML[0].paid_rebate) * (-1)
        }

        dblUnPaidRebate = Number(wrws_lstrXML[0].total_rebate) - Number(wrws_lstrXML[0].paid_rebate)
        dblDeductionsTotal = 0
        LstrXML5 = LstrXML5Hold
        setData(thisObj,'LstrXML5',LstrXML5Hold)
        for (let i = 0; i < LstrXML5.length; i++) {
          if (LstrXML5[i].pd_ind == 'D') {
            if (Number(LstrXML5[i].balance) != 0) {
              WRWorksheetObj.jm = LstrXML5[i].pd_desc + " Adj."
              WRWorksheetObj.jn = Number(LstrXML5[i].balance)
              dblDeductionsTotal = dblDeductionsTotal + Number(LstrXML5[i].balance)
            }
          }
        }

        WRWorksheetObj.jo = "Producer Total:"
        WRWorksheetObj.jp = Number(wrws_lstrXML[0].proceeds_amt) + dblUnPaidRebate

      }
    }

    if (bCMAPrint) {

      let sNetrepay = 0
      let sMarketGain = 0
      let sContractprice = 0

      sMarketGain = Number(wrws_lstrXML[0].market_gain)
      sContractprice = Number(wrws_lstrXML[0].proceeds_amt)
      sContractprice = sContractprice - sMarketGain
      sNetrepay = Number(wrws_lstrXML[0].loan_repay_amt)
      sNetrepay = sNetrepay - Number(wrws_lstrXML[0].store_repay_amt)
      sNetrepay = sNetrepay + Number(wrws_lstrXML[0].interest_amt)

      WRWorksheetObj.jq = "CMA Check Details"

      WRWorksheetObj.jr = "Description"
      WRWorksheetObj.js = "Amount"

      WRWorksheetObj.jt = "CCC Payoff"
      WRWorksheetObj.ju = Number(sNetrepay)

      WRWorksheetObj.jv = "Market Gain"
      WRWorksheetObj.jw = Number(sMarketGain)

      WRWorksheetObj.jx = "Contract Price"
      WRWorksheetObj.jy = Number(sContractprice)

      WRWorksheetObj.jz = "Total Check Amount"
      WRWorksheetObj.ka = Number(sNetrepay + sMarketGain + sContractprice)

    }
    rptWarehouseReceiptWorksheetHeader(wrws_lstrXML)
    setData(thisObj, 'WRWorksheetData', WRWorksheetObj)
  }

  const rptWarehouseReceiptWorksheetHeader = async (wrws_lstrXML) => {
    let wrws_LstrText = ''
    let wrws_BPCityState = ''
    let wrws_LicenseNo = ''
    let wrws_CCCCodeNo = ''

    let WorksheetHeaderObj = {}
    WorksheetHeaderObj.b = ''

    if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].rcpt_status == "P") {
      WorksheetHeaderObj.a = "Warehouse Receipt Settlement"
      switch (wrws_lstrXML[0].redeem_method_ind) {
        case 'L':
          WorksheetHeaderObj.b = "Loan Repayment Amount"
          break;
        case 'S':
          WorksheetHeaderObj.b = "Original 1007 Grades"
          break;
        case "R":
          WorksheetHeaderObj.b = "Warehouse Receipt Value"
          break;
        case "U":
          WorksheetHeaderObj.b = "Shrunk Receipt method"
          break;
        default:
          break;
      }
    }
    else {
      if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && (wrws_lstrXML[0].tent_cont_appl_ind == "O") && (wrws_lstrXML[0].rcpt_status) == 'I') {
        wrws_LstrText = " - Option Payment"
        WorksheetHeaderObj.b = ' '
        setData(thisObj, 'wrws_bOptionPayment', true)
      }
      WorksheetHeaderObj.a = "Warehouse Receipt Worksheet" + wrws_LstrText
    }

    WorksheetHeaderObj.d =   new Date()

    WorksheetHeaderObj.e =  "Whse. Rcpt. #:"
    WorksheetHeaderObj.f =  "Status:"
    WorksheetHeaderObj.g =  "Rcpt Type:"
    WorksheetHeaderObj.h =  "Location:"

    if (wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].whse_rcpt_num != undefined && wrws_lstrXML[0].whse_rcpt_num != null && wrws_lstrXML[0].whse_rcpt_num.length > 0 && wrws_lstrXML[0].whse_rcpt_num.length < 6) {
      wrws_lstrXML[0].whse_rcpt_num = '0'.repeat(6 - wrws_lstrXML[0].whse_rcpt_num.length) + wrws_lstrXML[0].whse_rcpt_num
    }

    WorksheetHeaderObj.i = wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].whse_rcpt_num != undefined ? wrws_lstrXML[0].whse_rcpt_num : ''
    let wrws_mbPurchased = false

    wrws_LstrText = ""

    if(wrws_lstrXML != undefined && wrws_lstrXML.length >0 && wrws_lstrXML[0].rcpt_status != undefined){
      switch (wrws_lstrXML[0].rcpt_status) {
        case "I":
          WorksheetHeaderObj.j =  "Issued"
          break;
        case "P":
          WorksheetHeaderObj.j =  "Purchased"
          wrws_mbPurchased = true
          break;
        case "D":
          WorksheetHeaderObj.j =  "Delv. Out"
          break;
        case "V":
          WorksheetHeaderObj.j =  "Void"
          break;
        default:
          break;
      }

    if (wrws_lstrXML[0].receipt_type == "P") {
      wrws_LstrText = "Paper"
    }
    else if (wrws_lstrXML[0].receipt_type == 'E') {
      wrws_LstrText = "Electronic"
    }
  }
    WorksheetHeaderObj.k =  wrws_LstrText
    WorksheetHeaderObj.l = wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].buy_pt_id != undefined && wrws_lstrXML[0].buy_pt_name != undefined ?  wrws_lstrXML[0].buy_pt_id + " - " + wrws_lstrXML[0].buy_pt_name + wrws_BPCityState : ''

    WorksheetHeaderObj.m =  "Whse. Lic. #:"
    WorksheetHeaderObj.n =  "CCC Loc. Code:"
    WorksheetHeaderObj.o =  "Whse. No. :"
    WorksheetHeaderObj.p =  "Bin No. :"
    WorksheetHeaderObj.q =  "Seg:"
    WorksheetHeaderObj.r =  "Peanut Type:"

    WorksheetHeaderObj.s =  wrws_LicenseNo
    WorksheetHeaderObj.t =  wrws_CCCCodeNo
    WorksheetHeaderObj.u =  wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].whouse_num != undefined ? wrws_lstrXML[0].whouse_num : ''
    WorksheetHeaderObj.v =  wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].bin_num != undefined ? wrws_lstrXML[0].bin_num : ''

    WorksheetHeaderObj.w =  wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].seg_type != undefined ? wrws_lstrXML[0].seg_type : ''
    WorksheetHeaderObj.x =  wrws_lstrXML != undefined && wrws_lstrXML.length > 0 && wrws_lstrXML[0].pnut_type_name != undefined ? wrws_lstrXML[0].pnut_type_name : ''

    WorksheetHeaderObj.y =  "Vendor :"
    WorksheetHeaderObj.z =  " CMA:"
    WorksheetHeaderObj.aa =  " DMA:"

    WorksheetHeaderObj.ab1 = '&nbsp&nbsp&nbsp'
    WorksheetHeaderObj.ab2 = '&nbsp&nbsp&nbsp'
    if (wrws_lstrXML != undefined && wrws_lstrXML.length >0 && wrws_lstrXML[0].cma_dma_ind != undefined && wrws_lstrXML[0].cma_dma_ind == "C") {
      WorksheetHeaderObj.ab1 =  "X" // 'CMA Check Box
    }
    else if (wrws_lstrXML != undefined && wrws_lstrXML.length >0 && wrws_lstrXML[0].cma_dma_ind != undefined && wrws_lstrXML[0].cma_dma_ind == "D") {
      WorksheetHeaderObj.ab2 =  "X" // 'DMA Check Box
    }

    WorksheetHeaderObj.ac =  "Issue Date :"

    if(wrws_lstrXML != undefined && wrws_lstrXML.length > 0){

    WorksheetHeaderObj.ad =  wrws_lstrXML[0].rcpt_vendor

    wrws_LstrText = wrws_lstrXML[0].vendor_name
    WorksheetHeaderObj.ae1 = wrws_lstrXML[0].vendor_name
    WorksheetHeaderObj.ae4 = ''

    if (wrws_lstrXML[0].vendor_addr1 != '') {
      wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].vendor_addr1
      WorksheetHeaderObj.ae2 = wrws_lstrXML[0].vendor_addr1
    }
    if (wrws_lstrXML[0].vendor_addr2 != '') {
      wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].vendor_addr2
      WorksheetHeaderObj.ae3 = wrws_lstrXML[0].vendor_addr2
    }
    if (wrws_lstrXML[0].vendor_city != '') {
      wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].vendor_city
      WorksheetHeaderObj.ae4 = wrws_lstrXML[0].vendor_city == undefined || wrws_lstrXML[0].vendor_city == null || wrws_lstrXML[0].vendor_city == '' ? WorksheetHeaderObj.ae4 + '' : WorksheetHeaderObj.ae4 + wrws_lstrXML[0].vendor_city
    }
    if (wrws_lstrXML[0].vendor_state_abbr != '') {
      wrws_LstrText = wrws_LstrText + ", " + wrws_lstrXML[0].vendor_state_abbr
      WorksheetHeaderObj.ae4 = wrws_lstrXML[0].vendor_state_abbr == undefined || wrws_lstrXML[0].vendor_state_abbr == null || wrws_lstrXML[0].vendor_state_abbr == '' ? WorksheetHeaderObj.ae4 + '' : WorksheetHeaderObj.ae4 + ", " + wrws_lstrXML[0].vendor_state_abbr
    }

    wrws_LstrText = wrws_LstrText + "   " + wrws_lstrXML[0].vendor_zip
    wrws_lstrXML[0].vendor_zip =  wrws_lstrXML[0].vendor_zip != undefined &&  wrws_lstrXML[0].vendor_zip.length > 5 ?  wrws_lstrXML[0].vendor_zip.slice(0,5) + '-' + wrws_lstrXML[0].vendor_zip.slice(5, wrws_lstrXML[0].vendor_zip.length) : wrws_lstrXML[0].vendor_zip
    WorksheetHeaderObj.ae4 = wrws_lstrXML[0].vendor_zip == undefined || wrws_lstrXML[0].vendor_zip == null || wrws_lstrXML[0].vendor_zip == '' ? WorksheetHeaderObj.ae4 + '' : WorksheetHeaderObj.ae4 + " " + wrws_lstrXML[0].vendor_zip
    WorksheetHeaderObj.ae =  wrws_LstrText

    // 'cma_dma_vendor
    wrws_LstrText = ""
    wrws_LstrText = wrws_lstrXML[0].cma_dma_vendor_num

    WorksheetHeaderObj.af =  wrws_LstrText
    wrws_LstrText = ""
    WorksheetHeaderObj.ag4 = ''
    wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].cma_dma_vendor_name
    WorksheetHeaderObj.ag1 = wrws_lstrXML[0].cma_dma_vendor_name
    if (wrws_lstrXML[0].cma_dma_vendor_addr1 != '') {
      wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].cma_dma_vendor_addr1
      WorksheetHeaderObj.ag2 = wrws_lstrXML[0].cma_dma_vendor_addr1
    }
    if (wrws_lstrXML[0].cma_dma_vendor_addr2 != '') {
      wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].cma_dma_vendor_addr2
      WorksheetHeaderObj.ag3 = wrws_lstrXML[0].cma_dma_vendor_addr2
    }
    if (wrws_lstrXML[0].cma_dma_vendor_city != '') {
      wrws_LstrText = wrws_LstrText + wrws_lstrXML[0].cma_dma_vendor_city
      WorksheetHeaderObj.ag4 = wrws_lstrXML[0].cma_dma_vendor_city == undefined || wrws_lstrXML[0].cma_dma_vendor_city == null || wrws_lstrXML[0].cma_dma_vendor_city == '' ? WorksheetHeaderObj.ag4 + '' :  WorksheetHeaderObj.ag4 + wrws_lstrXML[0].cma_dma_vendor_city
    }
    if (wrws_lstrXML[0].cma_dma_vendor_state_abbr != '') {
      wrws_LstrText = wrws_LstrText + ", " + wrws_lstrXML[0].cma_dma_vendor_state_abbr
      WorksheetHeaderObj.ag4 = wrws_lstrXML[0].cma_dma_vendor_state_abbr == undefined || wrws_lstrXML[0].cma_dma_vendor_state_abbr == null || wrws_lstrXML[0].cma_dma_vendor_state_abbr == '' ? WorksheetHeaderObj.ag4 + '' : WorksheetHeaderObj.ag4 + ", " + wrws_lstrXML[0].cma_dma_vendor_state_abbr
    }
    wrws_LstrText = wrws_LstrText + "   " + wrws_lstrXML[0].cma_dma_vendor_zip
    wrws_lstrXML[0].cma_dma_vendor_zip = wrws_lstrXML[0].cma_dma_vendor_zip != undefined && wrws_lstrXML[0].cma_dma_vendor_zip.length > 5 ? wrws_lstrXML[0].cma_dma_vendor_zip.slice(0,5) + '-' + wrws_lstrXML[0].cma_dma_vendor_zip.slice(5,wrws_lstrXML[0].cma_dma_vendor_zip.length) : wrws_lstrXML[0].cma_dma_vendor_zip
    WorksheetHeaderObj.ag4 = wrws_lstrXML[0].cma_dma_vendor_zip == undefined || wrws_lstrXML[0].cma_dma_vendor_zip == null || wrws_lstrXML[0].cma_dma_vendor_zip == '' ? WorksheetHeaderObj.ag4 + '' : WorksheetHeaderObj.ag4 + "   " + wrws_lstrXML[0].cma_dma_vendor_zip

    WorksheetHeaderObj.ag =  wrws_LstrText

    WorksheetHeaderObj.ah =  wrws_lstrXML[0].issued_date

    WorksheetHeaderObj.ai =  "Weighted Average Storage Date :"

    WorksheetHeaderObj.aj =  wrws_lstrXML[0].store_date

    WorksheetHeaderObj.ak =  "End Date:"

    WorksheetHeaderObj.al = ''
    if (wrws_lstrXML[0].rcpt_status == 'P') {
      WorksheetHeaderObj.al =  wrws_lstrXML[0].chrg_end_date
    }

    WorksheetHeaderObj.am =  "Document Delivered Date:"

    WorksheetHeaderObj.an = ''
    if (wrws_lstrXML[0].rcpt_status == 'P') {
      WorksheetHeaderObj.an =  wrws_lstrXML[0].doc_delv_date
    }
  }
  else{
    WorksheetHeaderObj.ad =  ''

    wrws_LstrText =  ''
    WorksheetHeaderObj.ae1 = ''
    WorksheetHeaderObj.ae4 = ''
    WorksheetHeaderObj.ae2 = ''
    WorksheetHeaderObj.ae3 = ''
    WorksheetHeaderObj.ae4 = ''
    WorksheetHeaderObj.ae = ''
    WorksheetHeaderObj.af = ''
    WorksheetHeaderObj.ag4 = ''
    WorksheetHeaderObj.ag1 = ''
    WorksheetHeaderObj.ag2 = ''
    WorksheetHeaderObj.ag3 = ''
    WorksheetHeaderObj.ag =  ''
    WorksheetHeaderObj.ah =  ''
    WorksheetHeaderObj.ai =  "Weighted Average Storage Date :"
    WorksheetHeaderObj.aj =  ''
    WorksheetHeaderObj.ak =  "End Date:"
    WorksheetHeaderObj.al = ''
    WorksheetHeaderObj.am =  "Document Delivered Date:"
    WorksheetHeaderObj.an = ''
  }

    setData(thisObj, 'WorksheetHeaderData', WorksheetHeaderObj)
  }

  const rptWarehouseReceipt_FSADocument = async (sBuyPtId,sWRNum) => {

    let lstrxml = []
    let LstrXML_1007s = []
    let LstrText = ''
    let LstrDateTime = ''
    let LstrDeliveredTo = ''
    let LstrWHLicenceNo = ''
    let LstrCCCLocationID = ''
    let LstrVendorInfo = ''
    let LintYPos = 0
    let LintStartYPos = 0
    let LintPageNo = 0
    let LintMaxPageNo = 0
    let Lint1007Count = 0
    let Lint1007Count_Bal = 0
    let LdblNet_Wt_less_Lsk_wt = 0
    let LdblLsk_wt = 0
    let wrpdf_ReceiptNo = ''
    let wrpdf_ObligationBP = ''
    let wrpdf_OriginalBP = ''
    let bFirstPage = true
    let bLastPage = false

    const LC_MAX_ROWS = 30
    const LC_MAX_COLS = 5

    let buy_pt_id = sBuyPtId
    let whse_rcpt_num = sWRNum

    let FSA_DocObj = {}

    LstrDateTime = new Date()

    setData(thisObj, 'msReport', "WRFSADocument") //'Used in printer selection

    lstrxml = await WarehouseReceiptService.RetrieveWareHouseReciptFsaDetails(buy_pt_id,whse_rcpt_num)
    if (lstrxml == undefined || lstrxml == null || lstrxml.length <0) {
      showMessage(thisObj, "Error Communicating with the database. Please reprint.")
      return
    }

    LstrXML_1007s = lstrxml[0].wr_1007s[0].wr_1007
    FSA_DocObj.s1007s = lstrxml[0].wr_1007s[0].wr_1007
    // Lint1007Count = LstrXML_1007s[0].wr_1007s[0].count_1007s
    // Lint1007Count_Bal = Lint1007Count
    // LintMaxPageNo = (Lint1007Count / LC_MAX_ROWS * LC_MAX_COLS)
    // if (LintMaxPageNo < Lint1007Count / (LC_MAX_ROWS * LC_MAX_COLS)) {
    //   LintMaxPageNo = LintMaxPageNo + 1
    // }
    // LintPageNo = 1

    LstrWHLicenceNo = lstrxml[0].unld_buying_pt[0].unld_fed_whouse_license
    LstrCCCLocationID = lstrxml[0].unld_buying_pt[0].unld_ccc_location_id

    LstrDeliveredTo = lstrxml[0].unld_buying_pt[0].unld_buy_pt_name
    LstrDeliveredTo = LstrDeliveredTo + lstrxml[0].unld_buying_pt[0].unld_physical_address
    LstrDeliveredTo = LstrDeliveredTo + lstrxml[0].unld_buying_pt[0].unld_physical_city + " "
    LstrDeliveredTo = LstrDeliveredTo + lstrxml[0].unld_buying_pt[0].unld_physical_county_name + " "
    LstrDeliveredTo = LstrDeliveredTo + lstrxml[0].unld_buying_pt[0].unld_physical_state + " "
    LstrDeliveredTo = LstrDeliveredTo + lstrxml[0].unld_buying_pt[0].unld_physical_zip
    FSA_DocObj.h1 = lstrxml[0].unld_buying_pt[0].unld_buy_pt_name
    FSA_DocObj.h2 = lstrxml[0].unld_buying_pt[0].unld_physical_address
    FSA_DocObj.h3 = lstrxml[0].unld_buying_pt[0].unld_physical_city + ' ' + lstrxml[0].unld_buying_pt[0].unld_physical_county_name + ' ' + lstrxml[0].unld_buying_pt[0].unld_physical_state + ' ' + lstrxml[0].unld_buying_pt[0].unld_physical_zip

    LstrVendorInfo = lstrxml[0].whouse_recipt_header[0].rcpt_vendor
    LstrVendorInfo = LstrVendorInfo + lstrxml[0].whouse_recipt_header[0].vendor_name
    LstrVendorInfo = LstrVendorInfo + lstrxml[0].whouse_recipt_header[0].vendor_addr1
    if (lstrxml[0].whouse_recipt_header[0].vendor_addr2 != '') {
      LstrVendorInfo = LstrVendorInfo + lstrxml[0].whouse_recipt_header[0].vendor_addr2
    }

    LstrVendorInfo = LstrVendorInfo + lstrxml[0].whouse_recipt_header[0].vendor_city + ", "
    LstrVendorInfo = LstrVendorInfo + lstrxml[0].whouse_recipt_header[0].vendor_state + " "
    LstrVendorInfo = LstrVendorInfo + lstrxml[0].whouse_recipt_header[0].vendor_zip

    FSA_DocObj.i1 = lstrxml[0].whouse_recipt_header[0].rcpt_vendor
    FSA_DocObj.i2 = lstrxml[0].whouse_recipt_header[0].vendor_name
    FSA_DocObj.i3 = lstrxml[0].whouse_recipt_header[0].vendor_addr1
    if (lstrxml[0].whouse_recipt_header[0].vendor_addr2 != '') {
      FSA_DocObj.i3 = FSA_DocObj.i3 + '</br>' + lstrxml[0].whouse_recipt_header[0].vendor_addr2
    }
    let VendorZipCode = lstrxml[0].whouse_recipt_header[0].vendor_zip
    if(VendorZipCode != undefined && VendorZipCode != null && VendorZipCode.length > 5){
      VendorZipCode = VendorZipCode.slice(0,5) + '-' + VendorZipCode.slice(5,VendorZipCode.length)
    }
    FSA_DocObj.i4 = lstrxml[0].whouse_recipt_header[0].vendor_city + ', ' + lstrxml[0].whouse_recipt_header[0].vendor_state + " " + VendorZipCode


    // FSA_NextPage:

    FSA_DocObj.a = "THIS DOCUMENT IS REQUIRED FOR YOU TO OBTAIN YOUR LOAN"

    FSA_DocObj.b = "ELECTRONIC RECEIPT INFORMATION"

    FSA_DocObj.c = "(NEEDED BY FSA FOR MARKET LOAN REQUEST)"

    FSA_DocObj.d = LstrDateTime

    FSA_DocObj.e = "Delivered To:"
    FSA_DocObj.f = "Vendor:"

    LstrText = lstrxml[0].unld_buying_pt[0].unld_fed_buy_pt_id
    FSA_DocObj.g = LstrText
    FSA_DocObj.h = LstrDeliveredTo
    FSA_DocObj.i = LstrVendorInfo

    FSA_DocObj.j = "Original Location:"
    FSA_DocObj.k = "Receipt Obligation Location:"

    FSA_DocObj.l = lstrxml[0].unld_buying_pt[0].unld_buy_pt_id
    FSA_DocObj.m = lstrxml[0].whouse_recipt_header[0].buy_pt_id
    wrpdf_ObligationBP = lstrxml[0].whouse_recipt_header[0].buy_pt_id
    wrpdf_OriginalBP = lstrxml[0].unld_buy_pt_id

    FSA_DocObj.n = "Receipt #:"
    FSA_DocObj.o = "Total Net Tons on Rcpt:"
    FSA_DocObj.p = "CCC Code #:"
    FSA_DocObj.q = "WHSE LIC. #:"
    FSA_DocObj.r = "Peanut Type:"
    FSA_DocObj.s = "Seg:"
    FSA_DocObj.t = "Average Storage Date:"

    LstrText = lstrxml[0].whouse_recipt_header[0].whse_rcpt_num

    if (LstrText != undefined && LstrText != null && LstrText.length > 0 && LstrText.length < 7) {
      LstrText = '0'.repeat(7 - LstrText.length) + LstrText
    }

    if (bFirstPage == false && bLastPage == true) {
      wrpdf_ReceiptNo = "Receipts"
    }
    else {
      wrpdf_ReceiptNo = LstrText
    }

    FSA_DocObj.u = LstrText

    LdblNet_Wt_less_Lsk_wt = ((Number(lstrxml[0].whouse_recipt_header[0].shrink_net_wt) - Number(lstrxml[0].whouse_recipt_header[0].shrink_lsk_wt)) / 2000).toFixed(2)
    LdblLsk_wt = (Number(lstrxml[0].whouse_recipt_header[0].shrink_lsk_wt) / 2000).toFixed(2)
    LstrText = Number(LdblNet_Wt_less_Lsk_wt) + Number(LdblLsk_wt)
    FSA_DocObj.v = LstrText.toFixed(2)
    FSA_DocObj.w = lstrxml[0].unld_buying_pt[0].unld_ccc_location_id
    FSA_DocObj.x = lstrxml[0].unld_buying_pt[0].unld_fed_whouse_license
    FSA_DocObj.y = lstrxml[0].whouse_recipt_header[0].pnut_type_name
    FSA_DocObj.z = lstrxml[0].whouse_recipt_header[0].seg_type
    FSA_DocObj.aa = lstrxml[0].whouse_recipt_header[0].store_date

    if(FSA_DocObj.aa != undefined && FSA_DocObj.aa != null){
      FSA_DocObj.aa = moment(FSA_DocObj.aa).format('MM/DD/YYYY')
    }

    if (LintPageNo == 1) {
      FSA_DocObj.ab = "Statement of Charges attached for circled items:"
      FSA_DocObj.ac = "1.  Cleaning & Curing"
      FSA_DocObj.ad = "2.  Seed Receivables"
      FSA_DocObj.ae = "3.  Harvesting"
      FSA_DocObj.af = "4.  In Charges & Associated Costs"
      FSA_DocObj.ag = "5.  Storage"
    }

    if ((Lint1007Count_Bal / LC_MAX_ROWS) > 0) {
      FSA_DocObj.ah = "FARM#"
      FSA_DocObj.ai = "1007#"
      FSA_DocObj.aj = "CONTR#"
      FSA_DocObj.ak = "POUNDS"
      FSA_DocObj.al = "STATE"
      FSA_DocObj.am = "CNTY"
    }

    if ((Lint1007Count_Bal / LC_MAX_ROWS) > 1) {
      FSA_DocObj.an = "FARM#"
      FSA_DocObj.ao = "1007#"
      FSA_DocObj.ap = "CONTR#"
      FSA_DocObj.aq = "POUNDS"
      FSA_DocObj.ar = "STATE"
      FSA_DocObj.as = "CNTY"
    }


    if (LstrXML_1007s != undefined && LstrXML_1007s != null && LstrXML_1007s != '') {
      for (let i = 0; i < LstrXML_1007s.length; i++) {
        FSA_DocObj.at = LstrXML_1007s[i].farm_id + " - " + LstrXML_1007s[i].farm_suffix
        LstrText = Number(LstrXML_1007s[i].settlement_num)
        if (LstrText == 0) {
          LstrText = ""
        }

        FSA_DocObj.au = LstrText
        FSA_DocObj.av = LstrXML_1007s[i].contract_num
        FSA_DocObj.aw = LstrXML_1007s[i].net_wt
        FSA_DocObj.ax = LstrXML_1007s[i].state_abbr
        FSA_DocObj.ay = LstrXML_1007s[i].county_id

        Lint1007Count_Bal = Lint1007Count_Bal - 1
      }
    }

    if (LstrXML_1007s != undefined && LstrXML_1007s != null) {
      LintPageNo = LintPageNo + 1
      FSA_DocObj.az = "Page " + (LintPageNo - 1) + " of " + (LintPageNo)
    }
    else {
      FSA_DocObj.ba = "Page " + LintPageNo + " of " + LintPageNo
    }

    setData(thisObj, 'FSA_DocData', FSA_DocObj)
  }

  const onbtnCloseClick = () =>{
    document.getElementById("WarehouseReceipts_ReportPreviewWRPopUp").childNodes[0].click()
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnPrintClick = async (buy_pt_id,whse_rcpt_num) =>{
    try{
    let ParentScreenData1 = getData(thisObj, 'ReportPreviewWRData')
    if(ParentScreenData1[0].docName == 'rptWarehouseReceipt_FSADocument'){
      await PrintFSADoc(ParentScreenData1)
    }
    else if(ParentScreenData1[0].docName == 'rptWarehouseReceiptWorksheet'){
      PrintWorksheet(ParentScreenData1)
    }
    
    let LstrList = []
    let data = {}
    if(getData(thisObj, 'msReport') == "WRFSADocument"){
      for(let i =0; i < ParentScreenData1.length; i++){
        data = {
          fsa_status : 'N'
        }
        LstrList = await WarehouseReceiptService.UpdateFsaDocReady(ParentScreenData1[i].buyingPt,ParentScreenData1[i].wrRcptNum,data)  
      }
    }
  }
  catch{
    let LstrList = []
    let data = {}
    if(getData(thisObj, 'msReport') == "WRFSADocument"){
      for(let i =0; i < ParentScreenData1.length; i++){
        data = {
          fsa_status : 'Y'
        }
        LstrList = await WarehouseReceiptService.UpdateFsaDocReady(ParentScreenData1[i].buyingPt,ParentScreenData1[i].wrRcptNum,data)  
      }
    }
  }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const PrintFSADoc = async (Data) => {
    try{
      var code = await CombinedFSADoc(Data)
      var type = 'text/html'
      var fileName = "PDF"
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {})
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err){
      errorHandler(err)
    }
  }

  const PrintWorksheet = async (Data) => {
    try{
      await rptWarehouseReceiptWorksheet(Data[0].buyingPt, Data[0].wrRcptNum, Data[0].rcptValue)
      var code = await WRWorksheet(Data)
      var type = 'text/html'
      var fileName = "PDF"
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {})
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err){
      errorHandler(err)
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReportPreviewWR*/}
              <iframe src={state.renderUrl} id="docFrame" width="64%" height="500px" style={{marginLeft:"17%",background:"White"}}></iframe>
              {/* END_USER_CODE-USER_BEFORE_ReportPreviewWR*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxReportPreviewWR*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxReportPreviewWR*/}

              <GroupBoxWidget
                conf={state.grpbxReportPreviewWR}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxPreview*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPreview*/}

                <GroupBoxWidget
                  conf={state.grpbxPreview}
                  screenConf={state}
                ></GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPreview*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPreview*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnPrintSetup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrintSetup*/}

                  <ButtonWidget
                    conf={state.btnPrintSetup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrintSetup*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrintSetup*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveAsPDF*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveAsPDF*/}

                  <ButtonWidget
                    conf={state.btnSaveAsPDF}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveAsPDF*/}

                  {/* END_USER_CODE-USER_AFTER_btnSaveAsPDF*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxReportPreviewWR*/}

              {/* END_USER_CODE-USER_AFTER_grpbxReportPreviewWR*/}

              {/* START_USER_CODE-USER_AFTER_ReportPreviewWR*/}

              {/* END_USER_CODE-USER_AFTER_ReportPreviewWR*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ReportPreviewWR);
