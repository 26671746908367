/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getData,
  setData,
  getValue,
  setValue,
  enable,
  disable
} from "../../shared/WindowImports";

import "./CureCleanScheduleProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_CureCleanScheduleProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CureCleanScheduleProfile",
    windowName: "CureCleanScheduleProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.CureCleanScheduleProfile",
    // START_USER_CODE-USER_CureCleanScheduleProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Cure & Clean Schedule Profile",
      scrCode: "PN0140B",
    },
    // END_USER_CODE-USER_CureCleanScheduleProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    ddDryGreenInd: {
      name: "ddDryGreenInd",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Dry Green Ind:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDryGreenInd_PROPERTIES

      // END_USER_CODE-USER_ddDryGreenInd_PROPERTIES
    },
    ddPDCode: {
      name: "ddPDCode",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanScheduleProfile",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPDCode_PROPERTIES

      // END_USER_CODE-USER_ddPDCode_PROPERTIES
    },
    ddRateType: {
      name: "ddRateType",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Rate Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateType_PROPERTIES

      // END_USER_CODE-USER_ddRateType_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    gridPrint: {
      name: "gridPrint",
      type: "GridWidget",
      parent: "grpbxCureCleanScheduleProfile",
      gridCellsOrder: "txtPrint1,txtPrint2,txtPrint3,txtPrint4,txtPrint5",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPrint_PROPERTIES

      // END_USER_CODE-USER_gridPrint_PROPERTIES
    },
    gridRates: {
      name: "gridRates",
      type: "GridWidget",
      parent: "grpbxCureCleanScheduleProfile",
      gridCellsOrder: "txtLowMoisture,txtHighMoisture,txtBaseRate",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "13",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridRates_PROPERTIES
      isEditable: true,
      // END_USER_CODE-USER_gridRates_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblBuyingPointValue: {
      name: "lblBuyingPointValue",
      type: "LabelWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPointValue_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPointValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtBaseRate: {
      name: "txtBaseRate",
      type: "TextBoxWidget",
      colName: "txtColBaseRate",
      parent: "gridRates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBaseRate_PROPERTIES

      // END_USER_CODE-USER_txtBaseRate_PROPERTIES
    },
    txtCode: {
      name: "txtCode",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Code:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCode_PROPERTIES

      // END_USER_CODE-USER_txtCode_PROPERTIES
    },
    txtColBaseRate: {
      name: "txtColBaseRate",
      type: "GridColumnWidget",
      parent: "gridRates",
      Label: "Base Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColBaseRate_PROPERTIES

      // END_USER_CODE-USER_txtColBaseRate_PROPERTIES
    },
    txtColHighMoisture: {
      name: "txtColHighMoisture",
      type: "GridColumnWidget",
      parent: "gridRates",
      Label: "High Moisture %",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColHighMoisture_PROPERTIES

      // END_USER_CODE-USER_txtColHighMoisture_PROPERTIES
    },
    txtColLowMoisture: {
      name: "txtColLowMoisture",
      type: "GridColumnWidget",
      parent: "gridRates",
      Label: "Low Moisture %",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLowMoisture_PROPERTIES

      // END_USER_CODE-USER_txtColLowMoisture_PROPERTIES
    },
    txtColPrint1: {
      name: "txtColPrint1",
      type: "GridColumnWidget",
      parent: "gridPrint",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPrint1_PROPERTIES

      // END_USER_CODE-USER_txtColPrint1_PROPERTIES
    },
    txtColPrint2: {
      name: "txtColPrint2",
      type: "GridColumnWidget",
      parent: "gridPrint",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPrint2_PROPERTIES

      // END_USER_CODE-USER_txtColPrint2_PROPERTIES
    },
    txtColPrint3: {
      name: "txtColPrint3",
      type: "GridColumnWidget",
      parent: "gridPrint",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPrint3_PROPERTIES

      // END_USER_CODE-USER_txtColPrint3_PROPERTIES
    },
    txtColPrint4: {
      name: "txtColPrint4",
      type: "GridColumnWidget",
      parent: "gridPrint",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPrint4_PROPERTIES

      // END_USER_CODE-USER_txtColPrint4_PROPERTIES
    },
    txtColPrint5: {
      name: "txtColPrint5",
      type: "GridColumnWidget",
      parent: "gridPrint",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPrint5_PROPERTIES

      // END_USER_CODE-USER_txtColPrint5_PROPERTIES
    },
    txtDeduction: {
      name: "txtDeduction",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Deduction:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeduction_PROPERTIES

      // END_USER_CODE-USER_txtDeduction_PROPERTIES
    },
    txtDiscription: {
      name: "txtDiscription",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanScheduleProfile",
      Label: "Discription:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscription_PROPERTIES

      // END_USER_CODE-USER_txtDiscription_PROPERTIES
    },
    txtHighMoisture: {
      name: "txtHighMoisture",
      type: "TextBoxWidget",
      colName: "txtColHighMoisture",
      parent: "gridRates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHighMoisture_PROPERTIES

      // END_USER_CODE-USER_txtHighMoisture_PROPERTIES
    },
    txtLowMoisture: {
      name: "txtLowMoisture",
      type: "TextBoxWidget",
      colName: "txtColLowMoisture",
      parent: "gridRates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLowMoisture_PROPERTIES

      // END_USER_CODE-USER_txtLowMoisture_PROPERTIES
    },
    txtPrint1: {
      name: "txtPrint1",
      type: "TextBoxWidget",
      colName: "txtColPrint1",
      parent: "gridPrint",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrint1_PROPERTIES

      // END_USER_CODE-USER_txtPrint1_PROPERTIES
    },
    txtPrint2: {
      name: "txtPrint2",
      type: "TextBoxWidget",
      colName: "txtColPrint2",
      parent: "gridPrint",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrint2_PROPERTIES

      // END_USER_CODE-USER_txtPrint2_PROPERTIES
    },
    txtPrint3: {
      name: "txtPrint3",
      type: "TextBoxWidget",
      colName: "txtColPrint3",
      parent: "gridPrint",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrint3_PROPERTIES

      // END_USER_CODE-USER_txtPrint3_PROPERTIES
    },
    txtPrint4: {
      name: "txtPrint4",
      type: "TextBoxWidget",
      colName: "txtColPrint4",
      parent: "gridPrint",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrint4_PROPERTIES

      // END_USER_CODE-USER_txtPrint4_PROPERTIES
    },
    txtPrint5: {
      name: "txtPrint5",
      type: "TextBoxWidget",
      colName: "txtColPrint5",
      parent: "gridPrint",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrint5_PROPERTIES

      // END_USER_CODE-USER_txtPrint5_PROPERTIES
    },
    grpbxCureCleanScheduleProfile: {
      name: "grpbxCureCleanScheduleProfile",
      type: "GroupBoxWidget",
      parent: "CureCleanScheduleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCureCleanScheduleProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanScheduleProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "CureCleanScheduleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setData(thisObj, 'boolCure', false);
    setData(thisObj, 'boolClean', false);
    setData(thisObj, 'boolCureClean', false);
    disable(thisObj, 'txtDeduction');
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  function roundTo(n, digits) {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
      n = (n * -1).toFixed(digits);
    }
    return n;
  };

  const setLabelText = (widgetId, value) => {
    document.getElementsByClassName(widgetId)[0]
      .firstChild.firstChild.innerHTML = value;
  };

  useEffect(() => {
    const OkBtnText = getData(thisObj, 'ScheduleProfileOkBtnText');
    if (OkBtnText) {
      setLabelText('btnOk', OkBtnText);
    }
  }, [getData(thisObj, 'ScheduleProfileOkBtnText')]);

  useEffect(() => {
    const BuyingPoint = getData(thisObj, 'ScheduleProfileBuyingPoint');
    if (BuyingPoint) {
      setLabelText('lblBuyingPointValue', BuyingPoint);
    }
  }, [getData(thisObj, 'ScheduleProfileBuyingPoint')]);

  useEffect(() => {
    const EnableTxtCode = getData(thisObj, 'ScheduleProfileEnableTxtCode');
    if (EnableTxtCode === false) {
      disable(thisObj, 'txtCode');
    } else {
      enable(thisObj, 'txtCode');
    }
  }, [getData(thisObj, 'ScheduleProfileEnableTxtCode')]);

  useEffect(() => {
    const TxtScheduleCode = getData(thisObj, 'ScheduleProfileTxtScheduleCode');
    if (TxtScheduleCode) {
      setValue(thisObj, 'txtCode', TxtScheduleCode);
    } else {
      setValue(thisObj, 'txtCode', '');
    }
  }, [getData(thisObj, 'ScheduleProfileTxtScheduleCode')]);

  useEffect(() => {
    const TxtDescription = getData(thisObj, 'ScheduleProfileTxtDescription');
    if (TxtDescription) {
      setValue(thisObj, 'txtDiscription', TxtDescription);
    } else {
      setValue(thisObj, 'txtDiscription', '');
    }
  }, [getData(thisObj, 'ScheduleProfileTxtDescription')]);

  useEffect(() => {
    const CureCleanIndicator = getData(thisObj, 'ScheduleProfileCureCleanIndicator');
    if (CureCleanIndicator) {
      setValue(thisObj, 'ddType', CureCleanIndicator);
      setData(thisObj, 'ddType', CureCleanIndicator);
    }
  }, [getData(thisObj, 'ScheduleProfileCureCleanIndicator')]);

  useEffect(() => {
    const DryGreenIndicator = getData(thisObj, 'ScheduleProfileDryGreenIndicator');
    if (DryGreenIndicator) {
      setValue(thisObj, 'ddDryGreenInd', DryGreenIndicator);
    }
  }, [getData(thisObj, 'ScheduleProfileDryGreenIndicator')]);

  useEffect(() => {
    const PdCode = getData(thisObj, 'ScheduleProfilePdCode');
    if (PdCode) {
      setValue(thisObj, 'ddPDCode', PdCode);
    }
  }, [getData(thisObj, 'ScheduleProfilePdCode')]);

  useEffect(() => {
    const RateTypeIndicator = getData(thisObj, 'ScheduleProfileRateTypeIndicator');
    if (RateTypeIndicator) {
      setValue(thisObj, 'ddRateType', RateTypeIndicator);
    }
  }, [getData(thisObj, 'ScheduleProfileRateTypeIndicator')]);

  useEffect(() => {
    const LblAddedBy = getData(thisObj, 'ScheduleProfileLblAddedBy');
    if (LblAddedBy) {
      setLabelText('lblAddedByValue', LblAddedBy);
    } else {
      setLabelText('lblAddedByValue', '');
    }
  }, [getData(thisObj, 'ScheduleProfileLblAddedBy')]);

  useEffect(() => {
    const LblChangedBy = getData(thisObj, 'ScheduleProfileLblChangedBy');
    if (LblChangedBy) {
      setLabelText('lblChangedByValue', LblChangedBy);
    } else {
      setLabelText('lblChangedByValue', '');
    }
  }, [getData(thisObj, 'ScheduleProfileLblChangedBy')]);

  useEffect(() => {
    const ControlList = getData(thisObj, 'ScheduleProfileControlList');
    if (ControlList && ControlList.length) {
      const rateGridData = [];
      ControlList.forEach(listItem => {
        const {
          loMoistPct,
          hiMoistPct,
          baseRate
        } = listItem;
        const rowData = {
          txtBaseRate: parseFloat(baseRate.toString()).toFixed(2),
          txtLowMoisture: parseFloat(loMoistPct.toString()).toFixed(2),
          txtHighMoisture: parseFloat(hiMoistPct.toString()).toFixed(2)
        }
        rateGridData.push(rowData);
      });
      setValue(thisObj, 'gridRates', rateGridData);
    } else {
      setValue(thisObj, 'gridRates', []);
    }
  }, [getData(thisObj, 'ScheduleProfileControlList')]);

  const fillCureCleanIndList = () => {
    const cureCleanIndList = [];
    cureCleanIndList.push({
      key: "CUR",
      description: "Cure"
    });
    cureCleanIndList.push({
      key: "CLN",
      description: "Clean"
    });
    cureCleanIndList.push({
      key: "C/C",
      description: "Cure/Clean"
    });

    thisObj.setState(current => {
      return {
        ...current,
        ddType: {
          ...state['ddType'],
          valueList: cureCleanIndList
        }
      }
    });

    setData(thisObj, 'cureCleanIndList', cureCleanIndList);
    if (!getData(thisObj, 'ScheduleProfileCureCleanIndicator')) {
      setValue(thisObj, 'ddType', cureCleanIndList[0].key);
      setData(thisObj, 'ddType', cureCleanIndList[0].key);
      onddTypeChange(null, cureCleanIndList[0].key);
    }
  };

  const fillDryGreenIndList = () => {
    const dryGreenIndList = [];
    dryGreenIndList.push({
      key: "D",
      description: "Dry Gross"
    });
    dryGreenIndList.push({
      key: "N",
      description: "Dry Net"
    });
    dryGreenIndList.push({
      key: "G",
      description: "Green Gross"
    });
    dryGreenIndList.push({
      key: "O",
      description: "Other"
    });

    thisObj.setState(current => {
      return {
        ...current,
        ddDryGreenInd: {
          ...state['ddDryGreenInd'],
          valueList: dryGreenIndList
        }
      }
    });

    setData(thisObj, 'dryGreenIndList', dryGreenIndList);
    setValue(thisObj, 'ddDryGreenInd', dryGreenIndList[0].key);
  };

  const fillRateTypeIndList = () => {
    const rateTypeIndList = [];
    rateTypeIndList.push({
      key: "T",
      description: "Ton"
    });
    rateTypeIndList.push({
      key: "P",
      description: "Pound"
    });
    rateTypeIndList.push({
      key: "L",
      description: "Load"
    });

    thisObj.setState(current => {
      return {
        ...current,
        ddRateType: {
          ...state['ddRateType'],
          valueList: rateTypeIndList
        }
      }
    });

    setData(thisObj, 'rateTypeIndList', rateTypeIndList);
    setValue(thisObj, 'ddRateType', rateTypeIndList[0].key);
  };

  const fillPdCodeList = async () => {
    try {
      const pdCodeList = [];
      const rateType = 'CURCLN';
      const selectedBuyingPointId = getData(thisObj, 'selectedBuyingPoint');
      const pdCodeResponse = await ContractManagementService
        .RetrivePremiumDeductionScheduleDetails(selectedBuyingPointId, null, null, rateType);
      pdCodeResponse.forEach(pdCodeObj => {
        if (pdCodeObj?.pdInd?.trim() === 'D') {
          const ddListItem = {
            key: pdCodeObj.pdCode,
            description: pdCodeObj.pdCode + ' - ' + pdCodeObj.pdDesc
          };
          pdCodeList.push(ddListItem);
        }

        switch (pdCodeObj.pdCode) {
          case "CURE":
            setData(thisObj, 'boolCure', true);
            break;
          case "CLEAN":
            setData(thisObj, 'boolClean', true);
            break;
          default:
            setData(thisObj, 'boolCureClean', true);
            break;
        }
      });
      onddTypeChange(null, getData(thisObj, 'ddType'));

      thisObj.setState(current => {
        return {
          ...current,
          ddPDCode: {
            ...state["ddPDCode"],
            valueList: pdCodeList
          }
        }
      });

      setData(thisObj, 'pdCodeList', pdCodeList);

      if (pdCodeList.length > 0) {
        setValue(thisObj, 'ddPDCode', pdCodeList[0].key);
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillPdCodeList: ${error?.toString()}`
        );
      }
    }
  };

  const formLoad = () => {
    fillCureCleanIndList();
    fillDryGreenIndList();
    fillRateTypeIndList();
    fillPdCodeList();
  };

  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_CureCleanScheduleProfilePopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message);
      else
        showMessage(thisObj, 'Exception in Cancel button Click');
    }
  };

  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onddTypeChange = (event, defaultValue) => {
    const selectedType = event ? event.target.value : defaultValue;
    const boolCure = getData(thisObj, 'boolCure');
    const boolClean = getData(thisObj, 'boolClean');
    const boolCureClean = getData(thisObj, 'boolCureClean');
    setValue(thisObj, 'txtDeduction', '');
    if (selectedType === 'CUR' && boolCure) {
      setValue(thisObj, 'txtDeduction', 'Cur - Cure');
    } else if (selectedType === 'CLN' && boolClean) {
      setValue(thisObj, 'txtDeduction', 'Cln - Clean');
    } else if (selectedType === 'C/C' && boolCureClean) {
      setValue(thisObj, 'txtDeduction', 'CurCln - Cure/Clean');
    }
    setData(thisObj, 'ddType', selectedType);
  };

  thisObj.onddTypeChange = onddTypeChange;

  const ontxtDiscriptionChange = (event) => {
    const specialCharacters = /[&'"]/g;

    if (specialCharacters.test(event.target.value)) {
      const newValue = event.target.value.replace(specialCharacters, '');
      setValue(thisObj, 'txtDiscription', newValue);
    }
  };

  thisObj.ontxtDiscriptionChange = ontxtDiscriptionChange;

  const onbtnPrintClick = () => {
    try {
      const obj = new jsPDF('p', 'mm', 'a4');

      const pdfData = [];
      const rateList = getValue(thisObj, 'gridRates');

      const buyingPoint = getData(thisObj, 'ScheduleProfileBuyingPoint');
      const row1 = ['Buying Point:', buyingPoint, '', '', ''];

      const code = getValue(thisObj, 'txtCode');
      const row2 = ['Code:', code, '', '', ''];

      const description = getValue(thisObj, 'txtDiscription');
      const row3 = ['Description:', description, '', '', ''];

      const selectedCureCleanInd = getValue(thisObj, 'ddType');
      const cureCleanIndList = getData(thisObj, 'cureCleanIndList');
      const cureCleanIndIndex = cureCleanIndList.findIndex(item => item.key === selectedCureCleanInd);
      const cureCleanInd = cureCleanIndList[cureCleanIndIndex].description;
      const row4 = ['Type:', cureCleanInd, '', '', ''];

      const selectedDryGreenInd = getValue(thisObj, 'ddDryGreenInd');
      const dryGreenIndList = getData(thisObj, 'dryGreenIndList');
      const dryGreenIndIndex = dryGreenIndList.findIndex(item => item.key === selectedDryGreenInd);
      const dryGreenInd = dryGreenIndList[dryGreenIndIndex].description;
      const row5 = ['Dry Green Ind:', dryGreenInd, '', '', ''];

      const selectedRateTypeInd = getValue(thisObj, 'ddRateType');
      const rateTypeIndList = getData(thisObj, 'rateTypeIndList');
      const rateTypeIndIndex = rateTypeIndList.findIndex(item => item.key === selectedRateTypeInd);
      const rateTypeInd = rateTypeIndList[rateTypeIndIndex].description;
      const row6 = ['Rate Type:', rateTypeInd, '', '', ''];

      const deduction = getValue(thisObj, 'txtDeduction');
      const row7 = ['Deduction:', deduction, '', '', ''];

      pdfData.push(row1);
      pdfData.push(row2);
      pdfData.push(row3);
      pdfData.push(row4);
      pdfData.push(row5);
      pdfData.push(row6);
      pdfData.push(row7);

      // Add Rate List Table Heading
      if (rateList.length) {
        const row8 = [
          '',
          '',
          'Low Moisture %',
          'High Moisture %',
          'Base Rate'
        ];
        pdfData.push(row8);
      }
      for (let i = 0; i < rateList.length; i++) {
        const rowAllData = rateList[i];
        const rowData = [
          '',
          '',
          parseFloat(rowAllData.txtLowMoisture.toString()).toFixed(2),
          parseFloat(rowAllData.txtHighMoisture.toString()).toFixed(2),
          parseFloat(rowAllData.txtBaseRate.toString()).toFixed(2)
        ];
        pdfData.push(rowData);
      }

      obj.autoTable({
        startX: 20,
        startY: 20,
        styles: { halign: 'center' },
        head: [],
        body: pdfData,
        columnStyles: {
          0: { halign: 'right' },
          1: { halign: 'left' }
        },
      });
      obj.setFontSize(12);

      const selectedYear = JSON.parse(sessionStorage.getItem('year'));
      let companyName = sessionStorage.getItem('Companyname').trim();
      companyName = companyName.length > 45 ? companyName.slice(0, 46) + '...' : companyName;

      const bottomTextStartPoint = companyName.length > 30
        ? 40 - (1.5 * (companyName.length - 30)) : 40 + (0.6 * (30 - companyName.length));

      obj.text('Cure Clean Schedule Setup  -  ' + selectedYear + ' - ' + companyName,
        bottomTextStartPoint, obj.internal.pageSize.height - 10);

      let fileName = prompt("Please enter the file name :");
      if (!fileName) {
        alert("Failed to print, please provide proper name to file");
      } else {
        obj.save(fileName + '.pdf')
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnPrintClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnPrintClick = onbtnPrintClick;

  const isOverlaping = (rateList, index, selectedRates) => {
    let isOverlap = false;
    for (let i = 0; i < rateList.length; i++) {
      if (i !== index && rateList[i]) {
        const selectedLowMoisture = parseFloat(selectedRates?.txtLowMoisture?.toString());
        const selectedHighMoisture = parseFloat(selectedRates?.txtHighMoisture?.toString());

        const otherLowMoisture = parseFloat(rateList[i]?.txtLowMoisture?.toString());
        const otherHighMoisture = parseFloat(rateList[i]?.txtHighMoisture?.toString());

        if (selectedLowMoisture >= otherLowMoisture && selectedLowMoisture <= otherHighMoisture) {
          isOverlap = true;
          break;
        }

        if (selectedHighMoisture >= otherLowMoisture && selectedHighMoisture <= otherHighMoisture) {
          isOverlap = true;
          break;
        }
      }
    }
    return isOverlap;
  };

  const CheckForValidForm = () => {
    try {
      let isFormValid = false;
      let isDataValid = true;

      const codeValue = getValue(thisObj, 'txtCode')?.trim();
      if (!codeValue) {
        showMessage(thisObj, 'Code is a required field.');
        document.getElementById('txtCode').focus();
        return isFormValid;
      }

      const rateList = getValue(thisObj, 'gridRates');
      if (!rateList.length || rateList[0]?.txtBaseRate?.toString()?.trim() === '') {
        showMessage(thisObj, 'At least one schedule is required.');
        document.getElementById('gridRatesCollapse')?.focus();
        return isFormValid;
      }

      for (let i = 0; i < rateList.length; i++) {
        let lowMoistureValue = rateList[i].txtLowMoisture?.toString()?.trim() || '';
        let highMoistureValue = rateList[i].txtHighMoisture?.toString()?.trim() || '';
        let baseRateValue = rateList[i].txtBaseRate?.toString()?.trim() || '';

        if (lowMoistureValue !== '' || highMoistureValue !== '' || baseRateValue !== '') {
          if (lowMoistureValue === '' && highMoistureValue === '' && baseRateValue !== '') {
            lowMoistureValue = 0;
            highMoistureValue = 100;
          }

          if (!isNaN(lowMoistureValue)) {
            lowMoistureValue = parseFloat(lowMoistureValue.toString());
            if (lowMoistureValue < 0 || lowMoistureValue > 999.99) {
              isDataValid = false;
            }
          } else {
            isDataValid = false;
          }

          if (!isDataValid) {
            showMessage(thisObj, 'Low Moist Percentage must be of format ###.##');
            document.getElementById('gridRatesCollapse')?.focus();
            break;
          }

          if (!isNaN(highMoistureValue)) {
            highMoistureValue = parseFloat(highMoistureValue.toString());
            if (highMoistureValue < 0 || highMoistureValue > 999.99) {
              isDataValid = false;
            }
          } else {
            isDataValid = false;
          }

          if (!isDataValid) {
            showMessage(thisObj, 'High Moist Percentage must be of format ###.##');
            document.getElementById('gridRatesCollapse')?.focus();
            break;
          }

          if (lowMoistureValue > highMoistureValue) {
            showMessage(thisObj, 'High Moist Percentage must be greater than Low Moist Percentage');
            document.getElementById('gridRatesCollapse')?.focus();
            isDataValid = false;
            break;
          }

          if (!isNaN(baseRateValue)) {
            baseRateValue = parseFloat(baseRateValue.toString());
            if (baseRateValue < 0 || baseRateValue > 9999.99999) {
              isDataValid = false;
            }
          } else {
            isDataValid = false;
          }

          if (!isDataValid) {
            showMessage(thisObj, 'Base Rate must be of format ####.#####');
            document.getElementById('gridRatesCollapse')?.focus();
            break;
          }
        }
        rateList[i].txtLowMoisture = lowMoistureValue.toFixed(2);
        rateList[i].txtHighMoisture = highMoistureValue.toFixed(2);
        rateList[i].txtBaseRate = baseRateValue.toFixed(2);
      }
      setValue(thisObj, 'gridRates', rateList);

      for (let i = 0; i < rateList.length; i++) {
        if (isOverlaping(rateList, i, rateList[i])) {
          showMessage(thisObj, 'Overlap exists in moisture percentages.');
          document.getElementById('gridRatesCollapse')?.focus();
          isDataValid = false;
          break;
        }
      }

      if (isDataValid === true) {
        isFormValid = true;
      }
      return isFormValid;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during CheckForValidForm: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const onbtnOkClick = async () => {
    try {
      if (!CheckForValidForm()) {
        return;
      }
      let isAddUpdateSuccess = true;

      const txtPdCode = getValue(thisObj, 'txtDeduction');
      if (!txtPdCode) {
        showMessage(thisObj, 'Update unsuccessful. The correct Premium deduction code must be setup for this location ');
        return;
      }

      const selectedBuyingPointId = getData(thisObj, 'selectedBuyingPoint');
      const scheduleId = getValue(thisObj, 'txtCode');
      const scheduleDeleteResponse = await SystemMaintenanceMasterManagementService
        .RemoveCureCleanSchedule(selectedBuyingPointId, scheduleId);

      if (scheduleDeleteResponse.status !== 200 && scheduleDeleteResponse.status !== 404) {
        showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        return;
      }

      let strPDcode = '';
      switch (txtPdCode) {
        case 'Cur - Cure':
          strPDcode = 'CURE';
          break;
        case 'Cln - Clean':
          strPDcode = 'CLEAN';
          break;
        case 'CurCln - Cure/Clean':
          strPDcode = 'CURCL';
          break;
        default:
          break;
      }

      const cureCleanInd = getValue(thisObj, 'ddType');
      const rateTypeValue = getValue(thisObj, 'ddRateType');
      const dryGreenInd = getValue(thisObj, 'ddDryGreenInd');
      const scheduleDescription = getValue(thisObj, 'txtDiscription')?.trim();
      const scheduleCode = getValue(thisObj, 'txtCode')?.trim();

      const rateList = getValue(thisObj, 'gridRates');
      for (let i = 0; i < rateList.length; i++) {
        let lowMoistureValue = rateList[i].txtLowMoisture?.toString()?.trim() || '';
        let highMoistureValue = rateList[i].txtHighMoisture?.toString()?.trim() || '';
        let baseRateValue = rateList[i].txtBaseRate?.toString()?.trim() || '';

        if (lowMoistureValue !== '' || highMoistureValue !== '' || baseRateValue !== '') {
          lowMoistureValue = parseFloat(lowMoistureValue);
          highMoistureValue = parseFloat(highMoistureValue);
          baseRateValue = parseFloat(baseRateValue);

          const scheduleDetails = {
            "schedule_id": scheduleCode,
            "schedule_num": (i + 1),
            "schedule_desc": scheduleDescription,
            "cure_clean_ind": cureCleanInd,
            "dry_green_ind": dryGreenInd,
            "rate_type": rateTypeValue,
            "lo_moist_pct": lowMoistureValue,
            "hi_moist_pct": highMoistureValue,
            "base_rate": baseRateValue,
            "pd_code": strPDcode
          }
          const scheduleCreateResponse = await SystemMaintenanceMasterManagementService
            .CreateCureCleanSchedule(selectedBuyingPointId, scheduleDetails);

          if (scheduleCreateResponse.status !== 200) {
            showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.');
            isAddUpdateSuccess = false;
            break;
          }
        }
      }
      if (!isAddUpdateSuccess) {
        return;
      }
      const okBtnText = getData(thisObj, 'ScheduleProfileOkBtnText');
      const prevScheduleList = getData(thisObj, 'scheduleList');
      if (okBtnText === 'Add') {
        prevScheduleList.push({
          key: scheduleCode,
          description: scheduleCode + ' - ' + scheduleDescription
        });

        const scheduleListIndex = prevScheduleList.findIndex(obj => obj.key === scheduleCode);
        setData(thisObj, 'ScheduleAddUpdateIndex', scheduleListIndex);
        setData(thisObj, 'ScheduleAddUpdateCompleteList', prevScheduleList);
      } else {
        const scheduleListIndex = prevScheduleList.findIndex(obj => obj.key === scheduleCode);
        prevScheduleList[scheduleListIndex].description = prevScheduleList[scheduleListIndex].key
          + ' - ' + scheduleDescription;
        setData(thisObj, 'ScheduleAddUpdateIndex', scheduleListIndex);
        setData(thisObj, 'ScheduleAddUpdateCompleteList', prevScheduleList);
      }

      document.getElementById("SystemMaintenanceMasterManagement_CureCleanScheduleProfilePopUp").childNodes[0].click();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnOkClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnOkClick = onbtnOkClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CureCleanScheduleProfile*/}

              {/* END_USER_CODE-USER_BEFORE_CureCleanScheduleProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanScheduleProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanScheduleProfile*/}

              <GroupBoxWidget
                conf={state.grpbxCureCleanScheduleProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPointValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPointValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCode*/}

                {/* END_USER_CODE-USER_AFTER_txtCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtDiscription*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiscription*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscription}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiscription*/}

                {/* END_USER_CODE-USER_AFTER_txtDiscription*/}
                {/* START_USER_CODE-USER_BEFORE_ddType*/}

                {/* END_USER_CODE-USER_BEFORE_ddType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddType*/}

                {/* END_USER_CODE-USER_AFTER_ddType*/}
                {/* START_USER_CODE-USER_BEFORE_ddDryGreenInd*/}

                {/* END_USER_CODE-USER_BEFORE_ddDryGreenInd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDryGreenInd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDryGreenInd*/}

                {/* END_USER_CODE-USER_AFTER_ddDryGreenInd*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateType*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRateType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateType*/}

                {/* END_USER_CODE-USER_AFTER_ddRateType*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeduction*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeduction*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeduction}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeduction*/}

                {/* END_USER_CODE-USER_AFTER_txtDeduction*/}
                {/* START_USER_CODE-USER_BEFORE_ddPDCode*/}

                {/* END_USER_CODE-USER_BEFORE_ddPDCode*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPDCode}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPDCode*/}

                {/* END_USER_CODE-USER_AFTER_ddPDCode*/}
                {/* START_USER_CODE-USER_BEFORE_gridPrint*/}

                {/* END_USER_CODE-USER_BEFORE_gridPrint*/}

                <GridWidget
                  conf={state.gridPrint}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPrint}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPrint*/}

                {/* END_USER_CODE-USER_AFTER_gridPrint*/}
                {/* START_USER_CODE-USER_BEFORE_gridRates*/}

                {/* END_USER_CODE-USER_BEFORE_gridRates*/}

                <GridWidget
                  conf={state.gridRates}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridRates}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridRates*/}

                {/* END_USER_CODE-USER_AFTER_gridRates*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCureCleanScheduleProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCureCleanScheduleProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_CureCleanScheduleProfile*/}

              {/* END_USER_CODE-USER_AFTER_CureCleanScheduleProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_CureCleanScheduleProfile
);
