/* eslint-disable*/
import React from "react";
import ReportTool_FormulaEditor from "./FormulaEditor";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FormulaEditor Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReportTool_FormulaEditor />);
    });
  });
  afterEach(cleanup);
  test("is FormulaEditor Loads Successfully", () => {
    expect(screen.getByText("FormulaEditor")).toBeInTheDocument;
  });
  test("Custom Test Cases for FormulaEditor", () => {
    // START_USER_CODE-USER_FormulaEditor_Custom_Test_Case
    // END_USER_CODE-USER_FormulaEditor_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReportTool_FormulaEditor />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(t("ReportTool:FormulaEditor_btnAdd"));
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClear(Button Widget) Test Cases", async () => {
    const btnClear = screen.getByTestId("btnClear");
    expect(btnClear).toBeInTheDocument;
    expect(btnClear.textContent).toEqual(
      t("ReportTool:FormulaEditor_btnClear")
    );
  });
  test("Custom Test Cases for btnClear", () => {
    // START_USER_CODE-USER_btnClear_TEST
    // END_USER_CODE-USER_btnClear_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ReportTool:FormulaEditor_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreate(Button Widget) Test Cases", async () => {
    const btnCreate = screen.getByTestId("btnCreate");
    expect(btnCreate).toBeInTheDocument;
    expect(btnCreate.textContent).toEqual(
      t("ReportTool:FormulaEditor_btnCreate")
    );
  });
  test("Custom Test Cases for btnCreate", () => {
    // START_USER_CODE-USER_btnCreate_TEST
    // END_USER_CODE-USER_btnCreate_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("ReportTool:FormulaEditor_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(t("ReportTool:FormulaEditor_btnEdit"));
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnInsert(Button Widget) Test Cases", async () => {
    const btnInsert = screen.getByTestId("btnInsert");
    expect(btnInsert).toBeInTheDocument;
    expect(btnInsert.textContent).toEqual(
      t("ReportTool:FormulaEditor_btnInsert")
    );
  });
  test("Custom Test Cases for btnInsert", () => {
    // START_USER_CODE-USER_btnInsert_TEST
    // END_USER_CODE-USER_btnInsert_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("ReportTool:FormulaEditor_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbx1(GroupBox Widget) Test Cases", async () => {
    const grpbx1 = screen.getByTestId("grpbx1");
    expect(grpbx1.tagName).toBe("BUTTON");
    expect(grpbx1.type).toBe("button");
    expect(grpbx1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1", () => {
    // START_USER_CODE-USER_grpbx1_TEST
    // END_USER_CODE-USER_grpbx1_TEST
  });
  test("grpbx2(GroupBox Widget) Test Cases", async () => {
    const grpbx2 = screen.getByTestId("grpbx2");
    expect(grpbx2.tagName).toBe("BUTTON");
    expect(grpbx2.type).toBe("button");
    expect(grpbx2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx2", () => {
    // START_USER_CODE-USER_grpbx2_TEST
    // END_USER_CODE-USER_grpbx2_TEST
  });
  test("grpbx21(GroupBox Widget) Test Cases", async () => {
    const grpbx21 = screen.getByTestId("grpbx21");
    expect(grpbx21.tagName).toBe("BUTTON");
    expect(grpbx21.type).toBe("button");
    expect(grpbx21.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx21", () => {
    // START_USER_CODE-USER_grpbx21_TEST
    // END_USER_CODE-USER_grpbx21_TEST
  });
  test("grpbx22(GroupBox Widget) Test Cases", async () => {
    const grpbx22 = screen.getByTestId("grpbx22");
    expect(grpbx22.tagName).toBe("BUTTON");
    expect(grpbx22.type).toBe("button");
    expect(grpbx22.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx22", () => {
    // START_USER_CODE-USER_grpbx22_TEST
    // END_USER_CODE-USER_grpbx22_TEST
  });
  test("grpbx23(GroupBox Widget) Test Cases", async () => {
    const grpbx23 = screen.getByTestId("grpbx23");
    expect(grpbx23.tagName).toBe("BUTTON");
    expect(grpbx23.type).toBe("button");
    expect(grpbx23.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx23", () => {
    // START_USER_CODE-USER_grpbx23_TEST
    // END_USER_CODE-USER_grpbx23_TEST
  });
  test("grpbx3(GroupBox Widget) Test Cases", async () => {
    const grpbx3 = screen.getByTestId("grpbx3");
    expect(grpbx3.tagName).toBe("BUTTON");
    expect(grpbx3.type).toBe("button");
    expect(grpbx3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx3", () => {
    // START_USER_CODE-USER_grpbx3_TEST
    // END_USER_CODE-USER_grpbx3_TEST
  });
  test("grpbx31(GroupBox Widget) Test Cases", async () => {
    const grpbx31 = screen.getByTestId("grpbx31");
    expect(grpbx31.tagName).toBe("BUTTON");
    expect(grpbx31.type).toBe("button");
    expect(grpbx31.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx31", () => {
    // START_USER_CODE-USER_grpbx31_TEST
    // END_USER_CODE-USER_grpbx31_TEST
  });
  test("grpbx32(GroupBox Widget) Test Cases", async () => {
    const grpbx32 = screen.getByTestId("grpbx32");
    expect(grpbx32.tagName).toBe("BUTTON");
    expect(grpbx32.type).toBe("button");
    expect(grpbx32.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx32", () => {
    // START_USER_CODE-USER_grpbx32_TEST
    // END_USER_CODE-USER_grpbx32_TEST
  });
  test("grpbx33(GroupBox Widget) Test Cases", async () => {
    const grpbx33 = screen.getByTestId("grpbx33");
    expect(grpbx33.tagName).toBe("BUTTON");
    expect(grpbx33.type).toBe("button");
    expect(grpbx33.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx33", () => {
    // START_USER_CODE-USER_grpbx33_TEST
    // END_USER_CODE-USER_grpbx33_TEST
  });
  test("grpbx34(GroupBox Widget) Test Cases", async () => {
    const grpbx34 = screen.getByTestId("grpbx34");
    expect(grpbx34.tagName).toBe("BUTTON");
    expect(grpbx34.type).toBe("button");
    expect(grpbx34.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx34", () => {
    // START_USER_CODE-USER_grpbx34_TEST
    // END_USER_CODE-USER_grpbx34_TEST
  });
  test("labelwidget1(Label Widget) Test Cases", async () => {
    const labelwidget1 = screen.getByTestId("labelwidget1");
    expect(labelwidget1.tagName).toBe("LABEL");
    expect(labelwidget1.classList).toContain("form-label");
    expect(labelwidget1.textContent).toEqual(
      t("ReportTool:FormulaEditor_labelwidget1")
    );
  });
  test("Custom Test Cases for labelwidget1", () => {
    // START_USER_CODE-USER_labelwidget1_TEST
    // END_USER_CODE-USER_labelwidget1_TEST
  });
  test("lblDirections(Label Widget) Test Cases", async () => {
    const lblDirections = screen.getByTestId("lblDirections");
    expect(lblDirections.tagName).toBe("LABEL");
    expect(lblDirections.classList).toContain("form-label");
    expect(lblDirections.textContent).toEqual(
      t("ReportTool:FormulaEditor_lblDirections")
    );
  });
  test("Custom Test Cases for lblDirections", () => {
    // START_USER_CODE-USER_lblDirections_TEST
    // END_USER_CODE-USER_lblDirections_TEST
  });
  test("lblDirections1(Label Widget) Test Cases", async () => {
    const lblDirections1 = screen.getByTestId("lblDirections1");
    expect(lblDirections1.tagName).toBe("LABEL");
    expect(lblDirections1.classList).toContain("form-label");
    expect(lblDirections1.textContent).toEqual(
      t("ReportTool:FormulaEditor_lblDirections1")
    );
  });
  test("Custom Test Cases for lblDirections1", () => {
    // START_USER_CODE-USER_lblDirections1_TEST
    // END_USER_CODE-USER_lblDirections1_TEST
  });
  test("lblDirections2(Label Widget) Test Cases", async () => {
    const lblDirections2 = screen.getByTestId("lblDirections2");
    expect(lblDirections2.tagName).toBe("LABEL");
    expect(lblDirections2.classList).toContain("form-label");
    expect(lblDirections2.textContent).toEqual(
      t("ReportTool:FormulaEditor_lblDirections2")
    );
  });
  test("Custom Test Cases for lblDirections2", () => {
    // START_USER_CODE-USER_lblDirections2_TEST
    // END_USER_CODE-USER_lblDirections2_TEST
  });
  test("lblFormulaDefination1(Label Widget) Test Cases", async () => {
    const lblFormulaDefination1 = screen.getByTestId("lblFormulaDefination1");
    expect(lblFormulaDefination1.tagName).toBe("LABEL");
    expect(lblFormulaDefination1.classList).toContain("form-label");
    expect(lblFormulaDefination1.textContent).toEqual(
      t("ReportTool:FormulaEditor_lblFormulaDefination1")
    );
  });
  test("Custom Test Cases for lblFormulaDefination1", () => {
    // START_USER_CODE-USER_lblFormulaDefination1_TEST
    // END_USER_CODE-USER_lblFormulaDefination1_TEST
  });
  test("lblFormulaDetail(Label Widget) Test Cases", async () => {
    const lblFormulaDetail = screen.getByTestId("lblFormulaDetail");
    expect(lblFormulaDetail.tagName).toBe("LABEL");
    expect(lblFormulaDetail.classList).toContain("form-label");
    expect(lblFormulaDetail.textContent).toEqual(
      t("ReportTool:FormulaEditor_lblFormulaDetail")
    );
  });
  test("Custom Test Cases for lblFormulaDetail", () => {
    // START_USER_CODE-USER_lblFormulaDetail_TEST
    // END_USER_CODE-USER_lblFormulaDetail_TEST
  });
  test("txtFormulaDescription(Textbox Widget) Test Cases", async () => {
    const txtFormulaDescription = screen.getByTestId("txtFormulaDescription");
    expect(txtFormulaDescription.tagName).toBe("INPUT");
    expect(txtFormulaDescription.type).toBe("text");
    expect(txtFormulaDescription.classList).toContain("textboxWidgetClass");
    expect(txtFormulaDescription.previousElementSibling.textContent).toEqual(
      t("ReportTool:FormulaEditor_txtFormulaDescription")
    );
    await act(async () => {
      userEvent.type(txtFormulaDescription, "1");
    });
  });
  test("Custom Test Cases for txtFormulaDescription", () => {
    // START_USER_CODE-USER_txtFormulaDescription_TEST
    // END_USER_CODE-USER_txtFormulaDescription_TEST
  });
  test("txtFormulaDescription1(Textbox Widget) Test Cases", async () => {
    const txtFormulaDescription1 = screen.getByTestId("txtFormulaDescription1");
    expect(txtFormulaDescription1.tagName).toBe("INPUT");
    expect(txtFormulaDescription1.type).toBe("text");
    expect(txtFormulaDescription1.classList).toContain("textboxWidgetClass");
    expect(txtFormulaDescription1.previousElementSibling.textContent).toEqual(
      t("ReportTool:FormulaEditor_txtFormulaDescription1")
    );
    await act(async () => {
      userEvent.type(txtFormulaDescription1, "1");
    });
  });
  test("Custom Test Cases for txtFormulaDescription1", () => {
    // START_USER_CODE-USER_txtFormulaDescription1_TEST
    // END_USER_CODE-USER_txtFormulaDescription1_TEST
  });
  test("txtFormulaName(Textbox Widget) Test Cases", async () => {
    const txtFormulaName = screen.getByTestId("txtFormulaName");
    expect(txtFormulaName.tagName).toBe("INPUT");
    expect(txtFormulaName.type).toBe("text");
    expect(txtFormulaName.classList).toContain("textboxWidgetClass");
    expect(txtFormulaName.previousElementSibling.textContent).toEqual(
      t("ReportTool:FormulaEditor_txtFormulaName")
    );
    await act(async () => {
      userEvent.type(txtFormulaName, "1");
    });
  });
  test("Custom Test Cases for txtFormulaName", () => {
    // START_USER_CODE-USER_txtFormulaName_TEST
    // END_USER_CODE-USER_txtFormulaName_TEST
  });
  test("txtFormulaName1(Textbox Widget) Test Cases", async () => {
    const txtFormulaName1 = screen.getByTestId("txtFormulaName1");
    expect(txtFormulaName1.tagName).toBe("INPUT");
    expect(txtFormulaName1.type).toBe("text");
    expect(txtFormulaName1.classList).toContain("textboxWidgetClass");
    expect(txtFormulaName1.previousElementSibling.textContent).toEqual(
      t("ReportTool:FormulaEditor_txtFormulaName1")
    );
    await act(async () => {
      userEvent.type(txtFormulaName1, "1");
    });
  });
  test("Custom Test Cases for txtFormulaName1", () => {
    // START_USER_CODE-USER_txtFormulaName1_TEST
    // END_USER_CODE-USER_txtFormulaName1_TEST
  });
  test("txtReportName(Textbox Widget) Test Cases", async () => {
    const txtReportName = screen.getByTestId("txtReportName");
    expect(txtReportName.tagName).toBe("INPUT");
    expect(txtReportName.type).toBe("text");
    expect(txtReportName.classList).toContain("textboxWidgetClass");
    expect(txtReportName.previousElementSibling.textContent).toEqual(
      t("ReportTool:FormulaEditor_txtReportName")
    );
    await act(async () => {
      userEvent.type(txtReportName, "1");
    });
  });
  test("Custom Test Cases for txtReportName", () => {
    // START_USER_CODE-USER_txtReportName_TEST
    // END_USER_CODE-USER_txtReportName_TEST
  });
  test("txtUserID(Textbox Widget) Test Cases", async () => {
    const txtUserID = screen.getByTestId("txtUserID");
    expect(txtUserID.tagName).toBe("INPUT");
    expect(txtUserID.type).toBe("text");
    expect(txtUserID.classList).toContain("textboxWidgetClass");
    expect(txtUserID.previousElementSibling.textContent).toEqual(
      t("ReportTool:FormulaEditor_txtUserID")
    );
    await act(async () => {
      userEvent.type(txtUserID, "1");
    });
  });
  test("Custom Test Cases for txtUserID", () => {
    // START_USER_CODE-USER_txtUserID_TEST
    // END_USER_CODE-USER_txtUserID_TEST
  });
});
