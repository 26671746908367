import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {
  onFileChange,
  getFileRenderTemplate
} from "../../../../common/ScreenInitialization";
import {checkInvalid, getWidgetClasses, getTooltip, getFieldContentClasses, getVisibility, getFieldContentStyle} from "../ParentWidgetFunc";

function FileWidget (props) {
  let conf = props.conf;
  let screenConf = props.screenConf;

  const [state,setState] = useState(props.conf.name + "_" + 0)
  let fileUpload = React.createRef();
  // state = {
  //   selectedRadio: props.conf.name + "_" + 0
  // };

  const radioOnChange = e => {
    setState({ selectedRadio: e.target.value });
  };

  
    
    const value = props.values[conf.name];
    const fileControl = (
      <React.Fragment>
        <Form.File
          id={conf.name}
          ref={fileUpload}
          name={conf.name}
          size={screenConf.horizontalForm ? "" : "sm"}
          disabled={!conf.Enabled}
          label="Choose file"
          onChange={e => onFileChange(e, value, conf, props.onChange)}
          onBlur={props.onBlur}
          isInvalid={checkInvalid(props,conf)}
          feedback={props.errors[conf.name]}
          custom
        />
        {getFileRenderTemplate(
          value,
          conf,
          undefined,
          conf.name,
          props.onChange,
          fileUpload,
          props.isValueTemplate,
          state,
          radioOnChange
        )}
      </React.Fragment>
    );
    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{fileControl}</Col>
          ) : (
            <React.Fragment> {fileControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default FileWidget;
