/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  RadioButtonGroupWidget,
  setValue,
  getValue,
  setData,
  getData,
  show,
  hide,
  goTo,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./ViewEWRLog.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ViewEWRLog(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewEWRLog",
    windowName: "ViewEWRLog",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ViewEWRLog",
    // START_USER_CODE-USER_ViewEWRLog_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "View EWR Log",
      scrCode: "PN1080C",
    },
    // END_USER_CODE-USER_ViewEWRLog_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxViewEWRLogDetails",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnSendInquiry: {
      name: "btnSendInquiry",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Send Inquiry",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSendInquiry_PROPERTIES

      // END_USER_CODE-USER_btnSendInquiry_PROPERTIES
    },
    btnSendUpdate: {
      name: "btnSendUpdate",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Send Update",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSendUpdate_PROPERTIES

      // END_USER_CODE-USER_btnSendUpdate_PROPERTIES
    },
    btnViewInquiry: {
      name: "btnViewInquiry",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "View Inquiry",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewInquiry_PROPERTIES

      // END_USER_CODE-USER_btnViewInquiry_PROPERTIES
    },
    btnViewUpdate: {
      name: "btnViewUpdate",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "View Update",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewUpdate_PROPERTIES

      // END_USER_CODE-USER_btnViewUpdate_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxViewEWRLog",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colBuyingPoint: {
      name: "colBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colCompID: {
      name: "colCompID",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Comp ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCompID_PROPERTIES

      // END_USER_CODE-USER_colCompID_PROPERTIES
    },
    colCropYear: {
      name: "colCropYear",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Crop Year",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCropYear_PROPERTIES

      // END_USER_CODE-USER_colCropYear_PROPERTIES
    },
    colEntryDate: {
      name: "colEntryDate",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Entry Date",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEntryDate_PROPERTIES

      // END_USER_CODE-USER_colEntryDate_PROPERTIES
    },
    colLogEntry: {
      name: "colLogEntry",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Log Entry",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLogEntry_PROPERTIES

      // END_USER_CODE-USER_colLogEntry_PROPERTIES
    },
    colReceiptNum: {
      name: "colReceiptNum",
      type: "GridColumnWidget",
      parent: "gridEWRLog",
      Label: "Receipt #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptNum_PROPERTIES

      // END_USER_CODE-USER_colReceiptNum_PROPERTIES
    },
    ddBuyingPointID: {
      name: "ddBuyingPointID",
      type: "DropDownFieldWidget",
      parent: "grpbxViewEWRLogDetails",
      Label: "Buying Point ID:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPointID_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPointID_PROPERTIES
    },
    gridEWRLog: {
      name: "gridEWRLog",
      type: "GridWidget",
      parent: "grpbxViewEWRLog",
      gridCellsOrder:
        "txtcolEntryDate,txtcolCompID,tztcolCropYear,txtcolBuyingPoint,txtcolReceiptNum,txtcolAction,txtcolLogEntry",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridEWRLog_PROPERTIES

      // END_USER_CODE-USER_gridEWRLog_PROPERTIES
    },
    grpbxViewEWRLogDetails: {
      name: "grpbxViewEWRLogDetails",
      type: "GroupBoxWidget",
      parent: "grpbxViewEWRLog",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxViewEWRLogDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxViewEWRLogDetails_PROPERTIES
    },
    radioSentReceived: {
      name: "radioSentReceived",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxViewEWRLogDetails",
      Options: "Sent to EWR:1,Received from EWR:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioSentReceived_PROPERTIES

      // END_USER_CODE-USER_radioSentReceived_PROPERTIES
    },
    txtcolAction: {
      name: "txtcolAction",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAction_PROPERTIES

      // END_USER_CODE-USER_txtcolAction_PROPERTIES
    },
    txtcolBuyingPoint: {
      name: "txtcolBuyingPoint",
      type: "TextBoxWidget",
      colName: "colBuyingPoint",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolCompID: {
      name: "txtcolCompID",
      type: "TextBoxWidget",
      colName: "colCompID",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCompID_PROPERTIES

      // END_USER_CODE-USER_txtcolCompID_PROPERTIES
    },
    txtcolEntryDate: {
      name: "txtcolEntryDate",
      type: "TextBoxWidget",
      colName: "colEntryDate",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEntryDate_PROPERTIES

      // END_USER_CODE-USER_txtcolEntryDate_PROPERTIES
    },
    txtcolLogEntry: {
      name: "txtcolLogEntry",
      type: "TextBoxWidget",
      colName: "colLogEntry",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLogEntry_PROPERTIES

      // END_USER_CODE-USER_txtcolLogEntry_PROPERTIES
    },
    txtcolReceiptNum: {
      name: "txtcolReceiptNum",
      type: "TextBoxWidget",
      colName: "colReceiptNum",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtcolReceiptNum_PROPERTIES
    },
    txtReceiptNumber: {
      name: "txtReceiptNumber",
      type: "TextBoxWidget",
      parent: "grpbxViewEWRLogDetails",
      Label: "Receipt Number:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptNumber_PROPERTIES

      // END_USER_CODE-USER_txtReceiptNumber_PROPERTIES
    },
    tztcolCropYear: {
      name: "tztcolCropYear",
      type: "TextBoxWidget",
      colName: "colCropYear",
      parent: "gridEWRLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_tztcolCropYear_PROPERTIES

      // END_USER_CODE-USER_tztcolCropYear_PROPERTIES
    },
    grpbxViewEWRLog: {
      name: "grpbxViewEWRLog",
      type: "GroupBoxWidget",
      parent: "ViewEWRLog",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewEWRLog_PROPERTIES

      // END_USER_CODE-USER_grpbxViewEWRLog_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "WarehouseReceipts#EWRSendUpdate": {},
        "WarehouseReceipts#EWRSendRRI#DEFAULT": {},
        "WarehouseReceipts#EWRListUpdate#DEFAULT": {}
      },
      REVERSE: {
        "WarehouseReceipts#EWRSendUpdate": {},
        "WarehouseReceipts#EWRSendRRI#DEFAULT": {},
        "WarehouseReceipts#EWRListUpdate#DEFAULT": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnSendUpdate: {
      DEFAULT: ["WarehouseReceipts#EWRSendUpdate#DEFAULT#true#Click"],
    },
    btnSendInquiry: {
      DEFAULT: ["WarehouseReceipts#EWRSendRRI#DEFAULT#true#Click"],
    },
    btnViewUpdate: {
      DEFAULT: ["WarehouseReceipts#EWRListUpdate#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setData(thisObj, 'txtWarehouseCode', '');
    setValue(thisObj, 'txtReceiptNumber', '0');
    setData(thisObj, 'txtReceiptNumber', '0');
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const dataFromParent = getData(thisObj, 'viewEWRLogData');
    if (dataFromParent) {
      if (dataFromParent?.txtReceiptNumber) {
        setValue(thisObj, 'txtReceiptNumber', dataFromParent?.txtReceiptNumber);
        setData(thisObj, 'txtReceiptNumber', dataFromParent?.txtReceiptNumber);
      } else {
        setValue(thisObj, 'txtReceiptNumber', '0');
        setData(thisObj, 'txtReceiptNumber', '0');
      }
    }
  }, [getData(thisObj, 'viewEWRLogData')]);

  const [loading, setLoading] = useState(false);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  // START_USER_CODE-USER_METHODS

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  
  const formLoad = async () => {
    try {
      const functionId = 'PN1060';
      const listBuyingPoint = [];
      listBuyingPoint.push({ key: '', description: '>>> All Buying Points <<<' });
      const response = await ContractManagementService
        .RetrieveBuyingPointControlDetails(functionId, null, null, null, null);
      setData(thisObj, 'buyingPointList', response);
      response.forEach(buyingPoint => {
        const listItemKey = buyingPoint?.buy_pt_id;
        const listItemDescription = buyingPoint?.buy_pt_id + " - " + toTitleCase(buyingPoint?.buy_pt_name);
        listBuyingPoint.push({ key: listItemKey, description: listItemDescription });
      });

      if (listBuyingPoint.length === 2) {
        listBuyingPoint.shift();
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPointID: {
            ...state.ddBuyingPointID,
            valueList: listBuyingPoint
          }
        }
      });
      if (listBuyingPoint.length > 1) {
        const dataFromParent = getData(thisObj, 'viewEWRLogData');
        if (dataFromParent?.selectedBuyingPoint) {
          setValue(thisObj, 'ddBuyingPointID', dataFromParent?.selectedBuyingPoint);
          setData(thisObj, 'viewEWRLogData', null);
        } else {
          setValue(thisObj, 'ddBuyingPointID', '');
        }
      }
      checkForAccessPermission();
      return true;
    } catch (error) {
      showMessage(
        thisObj,
        `Error occurred during fetching buying point control list: ${error?.message}`
      );
      return false;
    }
  }

  function formatDate(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSecond = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
  };

  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  };

  const checkForAccessPermission = async () => {
    try {
      hide(thisObj, 'btnSendInquiry');
      hide(thisObj, 'btnViewInquiry');
      hide(thisObj, 'btnSendUpdate');
      hide(thisObj, 'btnViewUpdate');
      const functionId = 'PN1090';
      const functionSubId = '009';
      const access = 'U';
      const permissionResponse = await ContractManagementService
        .RetrieveAccessPermissionDetails(functionId, functionSubId, access, null);
      if (permissionResponse?.length && permissionResponse[0]?.permission?.toUpperCase() === 'Y') {
        show(thisObj, 'btnSendInquiry');
        show(thisObj, 'btnViewInquiry');
        show(thisObj, 'btnSendUpdate');
        show(thisObj, 'btnViewUpdate');
        setValue(thisObj, 'radioSentReceived', '1');
      }
      return true;
    } catch (error) {
      showMessage(
        thisObj,
        `Error occurred during fetching access permission: ${error?.message}`
      );
      return false;
    }
  };

  const onbtnSearchClick = () => {
    const selectedBuyingPoint = getValue(thisObj, 'ddBuyingPointID');
    const receiptNumber = getValue(thisObj, 'txtReceiptNumber');
    if (!selectedBuyingPoint && (!receiptNumber || receiptNumber === '0')) {
      showMessage(thisObj, 'Warehouse Code and/or Receipt Number must be provided');
      return;
    }
    SearchEWRLog();
  };

  thisObj.onbtnSearchClick = onbtnSearchClick;

  const SearchEWRLog = async () => {
    try {
      const selectedBuyingPoint = getValue(thisObj, 'ddBuyingPointID');
      const receiptNumber = getValue(thisObj, 'txtReceiptNumber');
      let lstrSentReceived = 'S';

      const selectedRadio = getValue(thisObj, 'radioSentReceived');
      if (selectedRadio === '2') {
        lstrSentReceived = 'R';
      }

      setLoading(true);
      const searchResponse = await WarehouseReceiptService
        .RetrieveEWarehouseLogDetails(lstrSentReceived, selectedBuyingPoint, receiptNumber);

      const logData = [];
      if (searchResponse?.length > 0) {
        searchResponse.forEach(log => {
          const {
            entry_date,
            comp_id,
            crop_year,
            buy_pt_id,
            receipt_number,
            action,
            description
          } = log;
          const tableRowObj = {
            txtcolEntryDate: entry_date ? formatDate(entry_date) : '',
            txtcolCompID: comp_id,
            tztcolCropYear: crop_year,
            txtcolBuyingPoint: buy_pt_id,
            txtcolReceiptNum: receipt_number,
            txtcolAction: action,
            txtcolLogEntry: description?.trim()
          }
          logData.push(tableRowObj);
        });
      }
      if (logData.length > 0) {
        setSelectedRowNum(0);
        logData[0].isSelected = true;
      }
      setValue(thisObj, 'gridEWRLog', logData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(
        thisObj,
        `Error occurred during search button click handle event: ${error?.message}`
      );
      return false;
    }
  }

  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, 'gridEWRLog');
    if (newRowNum !== undefined && selectedRowNum !== undefined && gridData?.length) {
      gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return (newRowNum)
    }
  }

  const ongridEWRLogRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, 'gridEWRLog');
    selectRow(rowNum);
  }
  thisObj.ongridEWRLogRowSelect = ongridEWRLogRowSelect

  const onbtnCloseClick = async () => {
    try {
      document.getElementById("WarehouseReceipts_ViewEWRLogPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message);
      else
        showMessage(thisObj, 'Exception in Close button Click');
    }
  };

  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnSendInquiryClick = async () => {
    const selectedBuyingPoint = getValue(thisObj, 'ddBuyingPointID');
    if (selectedBuyingPoint !== '') {
      const buyingPointResponse = await ContractManagementService
        .RetrieveBuyingPointControlDetails(null, null, null, null, selectedBuyingPoint);
      if (buyingPointResponse?.length) {
        const warehouseCode = buyingPointResponse?.[0]?.ccc_location_id;
        setData(thisObj, 'txtWarehouseCode', warehouseCode);
      }
    } else {
      setData(thisObj, 'txtWarehouseCode', '');
    }
    const receiptNumber = getValue(thisObj, 'txtReceiptNumber');
    if (receiptNumber && receiptNumber !== '0') {
      setData(thisObj, 'txtReceiptNumber', receiptNumber);
    } else {
      setData(thisObj, 'txtReceiptNumber', '');
    }
    goTo(thisObj, "WarehouseReceipts#EWRSendRRI#DEFAULT#true#Click");
  };

  thisObj.onbtnSendInquiryClick = onbtnSendInquiryClick;

  const onbtnViewInquiryClick = async () => {
    try {
      const selectedBuyingPoint = getValue(thisObj, 'ddBuyingPointID');
      if (selectedBuyingPoint !== '') {
        const buyingPointResponse = await ContractManagementService
          .RetrieveBuyingPointControlDetails(null, null, null, null, selectedBuyingPoint);
        if (buyingPointResponse?.length) {
          const warehouseCode = buyingPointResponse[0]?.ccc_location_id;
          setData(thisObj, 'txtWarehouseCode', warehouseCode);
        }
      } else {
        setData(thisObj, 'txtWarehouseCode', '');
      }
      const receiptNumber = getValue(thisObj, 'txtReceiptNumber');
      if (receiptNumber && receiptNumber !== '0') {
        setData(thisObj, 'txtReceiptNumber', receiptNumber);
      } else {
        setData(thisObj, 'txtReceiptNumber', '');
      }
      goTo(thisObj, "WarehouseReceipts#EWRListRRI#DEFAULT#true#Click");
    } catch (error) {
    }
  };

  thisObj.onbtnViewInquiryClick = onbtnViewInquiryClick;

  const onbtnSendUpdateClick = () => {
    setData(thisObj, 'EWRSendUpdateData', null)
    goTo(thisObj, "WarehouseReceipts#EWRSendUpdate#DEFAULT#true#Click");
  };

  thisObj.onbtnSendUpdateClick = onbtnSendUpdateClick;

  const onbtnViewUpdateClick = () => {
    goTo(thisObj, "WarehouseReceipts#EWRListUpdate#DEFAULT#true#Click");
  };

  thisObj.onbtnViewUpdateClick = onbtnViewUpdateClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewEWRLog*/}

              {/* END_USER_CODE-USER_BEFORE_ViewEWRLog*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewEWRLog*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewEWRLog*/}

              <GroupBoxWidget conf={state.grpbxViewEWRLog} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxViewEWRLogDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxViewEWRLogDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxViewEWRLogDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_radioSentReceived*/}

                  {/* END_USER_CODE-USER_BEFORE_radioSentReceived*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioSentReceived}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioSentReceived*/}

                  {/* END_USER_CODE-USER_AFTER_radioSentReceived*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPointID*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPointID*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPointID}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPointID*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPointID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReceiptNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReceiptNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtReceiptNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxViewEWRLogDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxViewEWRLogDetails*/}
                {/* START_USER_CODE-USER_BEFORE_gridEWRLog*/}

                {/* END_USER_CODE-USER_BEFORE_gridEWRLog*/}

                <GridWidget
                  conf={state.gridEWRLog}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridEWRLog}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridEWRLog*/}

                {/* END_USER_CODE-USER_AFTER_gridEWRLog*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSendInquiry*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSendInquiry*/}

                  <ButtonWidget
                    conf={state.btnSendInquiry}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSendInquiry*/}

                  {/* END_USER_CODE-USER_AFTER_btnSendInquiry*/}
                  {/* START_USER_CODE-USER_BEFORE_btnViewInquiry*/}

                  {/* END_USER_CODE-USER_BEFORE_btnViewInquiry*/}

                  <ButtonWidget
                    conf={state.btnViewInquiry}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnViewInquiry*/}

                  {/* END_USER_CODE-USER_AFTER_btnViewInquiry*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSendUpdate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSendUpdate*/}

                  <ButtonWidget
                    conf={state.btnSendUpdate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSendUpdate*/}

                  {/* END_USER_CODE-USER_AFTER_btnSendUpdate*/}
                  {/* START_USER_CODE-USER_BEFORE_btnViewUpdate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnViewUpdate*/}

                  <ButtonWidget
                    conf={state.btnViewUpdate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnViewUpdate*/}

                  {/* END_USER_CODE-USER_AFTER_btnViewUpdate*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewEWRLog*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewEWRLog*/}

              {/* START_USER_CODE-USER_AFTER_ViewEWRLog*/}

              {/* END_USER_CODE-USER_AFTER_ViewEWRLog*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ViewEWRLog);
