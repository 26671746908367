/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_StateProfile from "./StateProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("StateProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_StateProfile />);
    });
  });
  afterEach(cleanup);
  test("is StateProfile Loads Successfully", () => {
    expect(screen.getByText("StateProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for StateProfile", () => {
    // START_USER_CODE-USER_StateProfile_Custom_Test_Case
    // END_USER_CODE-USER_StateProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_StateProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxStateProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxStateProfile = screen.getByTestId("grpbxStateProfile");
    expect(grpbxStateProfile.tagName).toBe("BUTTON");
    expect(grpbxStateProfile.type).toBe("button");
    expect(grpbxStateProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStateProfile", () => {
    // START_USER_CODE-USER_grpbxStateProfile_TEST
    // END_USER_CODE-USER_grpbxStateProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtAbbreviation(Textbox Widget) Test Cases", async () => {
    const txtAbbreviation = screen.getByTestId("txtAbbreviation");
    expect(txtAbbreviation.tagName).toBe("INPUT");
    expect(txtAbbreviation.type).toBe("text");
    expect(txtAbbreviation.classList).toContain("textboxWidgetClass");
    expect(txtAbbreviation.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_txtAbbreviation")
    );
    await act(async () => {
      userEvent.type(txtAbbreviation, "1");
    });
  });
  test("Custom Test Cases for txtAbbreviation", () => {
    // START_USER_CODE-USER_txtAbbreviation_TEST
    // END_USER_CODE-USER_txtAbbreviation_TEST
  });
  test("txtFreightLoadLimit(Textbox Widget) Test Cases", async () => {
    const txtFreightLoadLimit = screen.getByTestId("txtFreightLoadLimit");
    expect(txtFreightLoadLimit.tagName).toBe("INPUT");
    expect(txtFreightLoadLimit.type).toBe("text");
    expect(txtFreightLoadLimit.classList).toContain("textboxWidgetClass");
    expect(txtFreightLoadLimit.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_txtFreightLoadLimit")
    );
    await act(async () => {
      userEvent.type(txtFreightLoadLimit, "1");
    });
  });
  test("Custom Test Cases for txtFreightLoadLimit", () => {
    // START_USER_CODE-USER_txtFreightLoadLimit_TEST
    // END_USER_CODE-USER_txtFreightLoadLimit_TEST
  });
  test("txtInCharges(Textbox Widget) Test Cases", async () => {
    const txtInCharges = screen.getByTestId("txtInCharges");
    expect(txtInCharges.tagName).toBe("INPUT");
    expect(txtInCharges.type).toBe("text");
    expect(txtInCharges.classList).toContain("textboxWidgetClass");
    expect(txtInCharges.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_txtInCharges")
    );
    await act(async () => {
      userEvent.type(txtInCharges, "1");
    });
  });
  test("Custom Test Cases for txtInCharges", () => {
    // START_USER_CODE-USER_txtInCharges_TEST
    // END_USER_CODE-USER_txtInCharges_TEST
  });
  test("txtInspectionFees(Textbox Widget) Test Cases", async () => {
    const txtInspectionFees = screen.getByTestId("txtInspectionFees");
    expect(txtInspectionFees.tagName).toBe("INPUT");
    expect(txtInspectionFees.type).toBe("text");
    expect(txtInspectionFees.classList).toContain("textboxWidgetClass");
    expect(txtInspectionFees.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_txtInspectionFees")
    );
    await act(async () => {
      userEvent.type(txtInspectionFees, "1");
    });
  });
  test("Custom Test Cases for txtInspectionFees", () => {
    // START_USER_CODE-USER_txtInspectionFees_TEST
    // END_USER_CODE-USER_txtInspectionFees_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtStateID(Textbox Widget) Test Cases", async () => {
    const txtStateID = screen.getByTestId("txtStateID");
    expect(txtStateID.tagName).toBe("INPUT");
    expect(txtStateID.type).toBe("text");
    expect(txtStateID.classList).toContain("textboxWidgetClass");
    expect(txtStateID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:StateProfile_txtStateID")
    );
    await act(async () => {
      userEvent.type(txtStateID, "1");
    });
  });
  test("Custom Test Cases for txtStateID", () => {
    // START_USER_CODE-USER_txtStateID_TEST
    // END_USER_CODE-USER_txtStateID_TEST
  });
});
