/* istanbul ignore file */
import React from "react";
import ReactSummernote from "@humanscape/react-summernote-bs4";
import "@humanscape/react-summernote-bs4/dist/react-summernote.css";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import $ from "jquery";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,
  getTooltip} from "../ParentWidgetFunc";

function RichTextEditor(props) {
    let richControl = "";
    let conf = props.conf;
    let screenConf = props.screenConf;
    let value = props.values[conf.name];
    if (
      conf.type === "TextAreaWidget" &&
      conf.textareaType === "advanced"
    ) {
      richControl = (
        <React.Fragment>
          <ReactSummernote
            name={conf.name}
            onChange={value => {
              let event = {
                type: "change",
                target: {
                  id: conf.name,
                  name: conf.name,
                  value: value
                }
              };
              props.onChange(event);
            }}
            onBlur={() => {
              let event = {
                type: "blur",
                target: {
                  id: conf.name,
                  name: conf.name
                }
              };
              props.onBlur(event);
            }}
            onImageUpload={FileList => {
              const reader = new FileReader();
              reader.onloadend = () => {
                ReactSummernote.insertImage(reader.result);
              };
              reader.readAsDataURL(FileList[0]);
            }}
            isInvalid={checkInvalid(props,conf)}
            options={{
              dialogInBody: true,
              toolbar: [
                ["style", ["style"]],
                ["font", ["bold", "underline", "clear"]],
                ["fontname", ["fontname"]],
                ["para", ["ul", "ol", "paragraph"]],
                ["table", ["table"]],
                ["insert", ["link", "picture", "video"]],
                ["view", ["fullscreen", "codeview"]]
              ]
            }}
            onInit={() => {
              if (!conf.Enabled) {
                $("[id^='react-summernote']").summernote("disable");
              }
            }}
            value={value}
          ></ReactSummernote>
          <Form.Control.Feedback
            className={checkInvalid(props,conf) ? "d-block" : "d-none"}
            type="invalid"
            aria-live="polite" role="alert"
          >
            {props.errors[conf.name]}
          </Form.Control.Feedback>
        </React.Fragment>
      );
    }

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        title={getTooltip(conf)}
        role="tooltip"
        data-testid={conf.name}
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{richControl}</Col>
          ) : (
            <React.Fragment> {richControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default RichTextEditor;
