/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  enable,
  disable,
  setData,
  getData,
  hide,
  show,
  goTo
} from "../../shared/WindowImports";

import "./MultiStopProfile.scss";


import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { StockTransferService } from "../Service/StockTransferService";
import Loading from "../../../Loader/Loading";
import { response } from "msw";
import StripPic from "../../../../assets/img/PinStrip.png";
function StockTransfer_MultiStopProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const [loading, setLoading] = useState(false);
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "MultiStopProfile",
    windowName: "MultiStopProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.MultiStopProfile",
    // START_USER_CODE-USER_MultiStopProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Multi Transfer Profile",
      scrCode: "PN1080K",
    },
    // END_USER_CODE-USER_MultiStopProfile_PROPERTIES
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnGreightAccural: {
      name: "btnGreightAccural",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Freight Accural",
      CharWidth: "32",
      ofTypeDomain: "d_boolean",
    },
    btnReverseFreightAccural: {
      name: "btnReverseFreightAccural",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Freight Accural",
      CharWidth: "32",
      ofTypeDomain: "d_boolean",
    },
    btnSearchForTransfers: {
      name: "btnSearchForTransfers",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearch",
      Label: "Search for Transfers",
      CharWidth: "41",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearchForTransfers_PROPERTIES

      // END_USER_CODE-USER_btnSearchForTransfers_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    cmmndCntnrSearch: {
      name: "cmmndCntnrSearch",
      type: "CommandContainerWidget",
      parent: "grpbxMultiStopProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrSearch_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrSearch_PROPERTIES
    },
    ddReceivingLocation0: {
      name: "ddReceivingLocation0",
      type: "DropDownFieldWidget",
      parent: "grpbxRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation0_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation0_PROPERTIES
    },
    ddReceivingLocation1: {
      name: "ddReceivingLocation1",
      type: "DropDownFieldWidget",
      parent: "grpbxRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation1_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation1_PROPERTIES
    },
    ddReceivingLocation2: {
      name: "ddReceivingLocation2",
      type: "DropDownFieldWidget",
      parent: "grpbxRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation2_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation2_PROPERTIES
    },
    ddReceivingLocation3: {
      name: "ddReceivingLocation3",
      type: "DropDownFieldWidget",
      parent: "grpbxRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation3_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation3_PROPERTIES
    },
    ddReceivingLocation4: {
      name: "ddReceivingLocation4",
      type: "DropDownFieldWidget",
      parent: "grpbxRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation4_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation4_PROPERTIES
    },
    ddReceivingLocation5: {
      name: "ddReceivingLocation5",
      type: "DropDownFieldWidget",
      parent: "grpbxRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation5_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation5_PROPERTIES
    },
    ddReceivingLocation6: {
      name: "ddReceivingLocation6",
      type: "DropDownFieldWidget",
      parent: "grpbxRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation6_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation6_PROPERTIES
    },
    ddReceivingLocation7: {
      name: "ddReceivingLocation7",
      type: "DropDownFieldWidget",
      parent: "grpbxRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation7_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation7_PROPERTIES
    },
    ddReceivingLocation8: {
      name: "ddReceivingLocation8",
      type: "DropDownFieldWidget",
      parent: "grpbxRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation8_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation8_PROPERTIES
    },
    ddReceivingLocation9: {
      name: "ddReceivingLocation9",
      type: "DropDownFieldWidget",
      parent: "grpbxRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivingLocation9_PROPERTIES

      // END_USER_CODE-USER_ddReceivingLocation9_PROPERTIES
    },
    ddShippingLoacation0: {
      name: "ddShippingLoacation0",
      type: "DropDownFieldWidget",
      parent: "grpbxRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation0_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation0_PROPERTIES
    },
    ddShippingLoacation1: {
      name: "ddShippingLoacation1",
      type: "DropDownFieldWidget",
      parent: "grpbxRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation1_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation1_PROPERTIES
    },
    ddShippingLoacation2: {
      name: "ddShippingLoacation2",
      type: "DropDownFieldWidget",
      parent: "grpbxRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation2_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation2_PROPERTIES
    },
    ddShippingLoacation3: {
      name: "ddShippingLoacation3",
      type: "DropDownFieldWidget",
      parent: "grpbxRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation3_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation3_PROPERTIES
    },
    ddShippingLoacation4: {
      name: "ddShippingLoacation4",
      type: "DropDownFieldWidget",
      parent: "grpbxRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation4_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation4_PROPERTIES
    },
    ddShippingLoacation5: {
      name: "ddShippingLoacation5",
      type: "DropDownFieldWidget",
      parent: "grpbxRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation5_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation5_PROPERTIES
    },
    ddShippingLoacation6: {
      name: "ddShippingLoacation6",
      type: "DropDownFieldWidget",
      parent: "grpbxRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation6_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation6_PROPERTIES
    },
    ddShippingLoacation7: {
      name: "ddShippingLoacation7",
      type: "DropDownFieldWidget",
      parent: "grpbxRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation7_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation7_PROPERTIES
    },
    ddShippingLoacation8: {
      name: "ddShippingLoacation8",
      type: "DropDownFieldWidget",
      parent: "grpbxRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation8_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation8_PROPERTIES
    },
    ddShippingLoacation9: {
      name: "ddShippingLoacation9",
      type: "DropDownFieldWidget",
      parent: "grpbxRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoacation9_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoacation9_PROPERTIES
    },
    ddType0: {
      name: "ddType0",
      type: "DropDownFieldWidget",
      parent: "grpbxRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType0_PROPERTIES

      // END_USER_CODE-USER_ddType0_PROPERTIES
    },
    ddType1: {
      name: "ddType1",
      type: "DropDownFieldWidget",
      parent: "grpbxRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType1_PROPERTIES

      // END_USER_CODE-USER_ddType1_PROPERTIES
    },
    ddType2: {
      name: "ddType2",
      type: "DropDownFieldWidget",
      parent: "grpbxRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType2_PROPERTIES

      // END_USER_CODE-USER_ddType2_PROPERTIES
    },
    ddType3: {
      name: "ddType3",
      type: "DropDownFieldWidget",
      parent: "grpbxRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType3_PROPERTIES

      // END_USER_CODE-USER_ddType3_PROPERTIES
    },
    ddType4: {
      name: "ddType4",
      type: "DropDownFieldWidget",
      parent: "grpbxRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType4_PROPERTIES

      // END_USER_CODE-USER_ddType4_PROPERTIES
    },
    ddType5: {
      name: "ddType5",
      type: "DropDownFieldWidget",
      parent: "grpbxRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType5_PROPERTIES

      // END_USER_CODE-USER_ddType5_PROPERTIES
    },
    ddType6: {
      name: "ddType6",
      type: "DropDownFieldWidget",
      parent: "grpbxRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType6_PROPERTIES

      // END_USER_CODE-USER_ddType6_PROPERTIES
    },
    ddType7: {
      name: "ddType7",
      type: "DropDownFieldWidget",
      parent: "grpbxRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType7_PROPERTIES

      // END_USER_CODE-USER_ddType7_PROPERTIES
    },
    ddType8: {
      name: "ddType8",
      type: "DropDownFieldWidget",
      parent: "grpbxRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType8_PROPERTIES

      // END_USER_CODE-USER_ddType8_PROPERTIES
    },
    ddType9: {
      name: "ddType9",
      type: "DropDownFieldWidget",
      parent: "grpbxRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType9_PROPERTIES

      // END_USER_CODE-USER_ddType9_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxMultiStopProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxGrid: {
      name: "grpbxGrid",
      type: "GroupBoxWidget",
      parent: "grpbxMultiStopProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxGrid_PROPERTIES

      // END_USER_CODE-USER_grpbxGrid_PROPERTIES
    },
    grpbxLabels: {
      name: "grpbxLabels",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxLabels_PROPERTIES
    },
    grpbxRow0: {
      name: "grpbxRow0",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      clonedExtId: "57274",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxRow0_PROPERTIES
    },
    grpbxRow1: {
      name: "grpbxRow1",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxRow1_PROPERTIES
    },
    grpbxRow2: {
      name: "grpbxRow2",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxRow2_PROPERTIES
    },
    grpbxRow3: {
      name: "grpbxRow3",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxRow3_PROPERTIES
    },
    grpbxRow4: {
      name: "grpbxRow4",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxRow4_PROPERTIES
    },
    grpbxRow5: {
      name: "grpbxRow5",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxRow5_PROPERTIES
    },
    grpbxRow6: {
      name: "grpbxRow6",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow6_PROPERTIES

      // END_USER_CODE-USER_grpbxRow6_PROPERTIES
    },
    grpbxRow7: {
      name: "grpbxRow7",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxRow7_PROPERTIES
    },
    grpbxRow8: {
      name: "grpbxRow8",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxRow8_PROPERTIES
    },
    grpbxRow9: {
      name: "grpbxRow9",
      type: "GroupBoxWidget",
      parent: "grpbxGrid",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxRow9_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblLegNum: {
      name: "lblLegNum",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Leg #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLegNum_PROPERTIES

      // END_USER_CODE-USER_lblLegNum_PROPERTIES
    },
    lblMileageOverride: {
      name: "lblMileageOverride",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Mileage / Override",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMileageOverride_PROPERTIES

      // END_USER_CODE-USER_lblMileageOverride_PROPERTIES
    },
    lblReceivingLocation: {
      name: "lblReceivingLocation",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Receiving Location",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReceivingLocation_PROPERTIES

      // END_USER_CODE-USER_lblReceivingLocation_PROPERTIES
    },
    lblShippingLocation: {
      name: "lblShippingLocation",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Shipping Location",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippingLocation_PROPERTIES

      // END_USER_CODE-USER_lblShippingLocation_PROPERTIES
    },
    lblTransferNum: {
      name: "lblTransferNum",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Transfer #",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTransferNum_PROPERTIES

      // END_USER_CODE-USER_lblTransferNum_PROPERTIES
    },
    lblType: {
      name: "lblType",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Type",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblType_PROPERTIES

      // END_USER_CODE-USER_lblType_PROPERTIES
    },
    txtCarrierAbbreviation: {
      name: "txtCarrierAbbreviation",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Carrier Abbreviation:",
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbreviation_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbreviation_PROPERTIES
    },
    txtLegNum0: {
      name: "txtLegNum0",
      type: "TextBoxWidget",
      parent: "grpbxRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum0_PROPERTIES

      // END_USER_CODE-USER_txtLegNum0_PROPERTIES
    },
    txtLegNum1: {
      name: "txtLegNum1",
      type: "TextBoxWidget",
      parent: "grpbxRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum1_PROPERTIES

      // END_USER_CODE-USER_txtLegNum1_PROPERTIES
    },
    txtLegNum2: {
      name: "txtLegNum2",
      type: "TextBoxWidget",
      parent: "grpbxRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum2_PROPERTIES

      // END_USER_CODE-USER_txtLegNum2_PROPERTIES
    },
    txtLegNum3: {
      name: "txtLegNum3",
      type: "TextBoxWidget",
      parent: "grpbxRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum3_PROPERTIES

      // END_USER_CODE-USER_txtLegNum3_PROPERTIES
    },
    txtLegNum4: {
      name: "txtLegNum4",
      type: "TextBoxWidget",
      parent: "grpbxRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum4_PROPERTIES

      // END_USER_CODE-USER_txtLegNum4_PROPERTIES
    },
    txtLegNum5: {
      name: "txtLegNum5",
      type: "TextBoxWidget",
      parent: "grpbxRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum5_PROPERTIES

      // END_USER_CODE-USER_txtLegNum5_PROPERTIES
    },
    txtLegNum6: {
      name: "txtLegNum6",
      type: "TextBoxWidget",
      parent: "grpbxRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum6_PROPERTIES

      // END_USER_CODE-USER_txtLegNum6_PROPERTIES
    },
    txtLegNum7: {
      name: "txtLegNum7",
      type: "TextBoxWidget",
      parent: "grpbxRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum7_PROPERTIES

      // END_USER_CODE-USER_txtLegNum7_PROPERTIES
    },
    txtLegNum8: {
      name: "txtLegNum8",
      type: "TextBoxWidget",
      parent: "grpbxRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum8_PROPERTIES

      // END_USER_CODE-USER_txtLegNum8_PROPERTIES
    },
    txtLegNum9: {
      name: "txtLegNum9",
      type: "TextBoxWidget",
      parent: "grpbxRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegNum9_PROPERTIES

      // END_USER_CODE-USER_txtLegNum9_PROPERTIES
    },
    txtMileage0: {
      name: "txtMileage0",
      type: "TextBoxWidget",
      parent: "grpbxRow0",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage0_PROPERTIES

      // END_USER_CODE-USER_txtMileage0_PROPERTIES
    },
    txtMileage1: {
      name: "txtMileage1",
      type: "TextBoxWidget",
      parent: "grpbxRow1",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage1_PROPERTIES

      // END_USER_CODE-USER_txtMileage1_PROPERTIES
    },
    txtMileage2: {
      name: "txtMileage2",
      type: "TextBoxWidget",
      parent: "grpbxRow2",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage2_PROPERTIES

      // END_USER_CODE-USER_txtMileage2_PROPERTIES
    },
    txtMileage3: {
      name: "txtMileage3",
      type: "TextBoxWidget",
      parent: "grpbxRow3",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage3_PROPERTIES

      // END_USER_CODE-USER_txtMileage3_PROPERTIES
    },
    txtMileage4: {
      name: "txtMileage4",
      type: "TextBoxWidget",
      parent: "grpbxRow4",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage4_PROPERTIES

      // END_USER_CODE-USER_txtMileage4_PROPERTIES
    },
    txtMileage5: {
      name: "txtMileage5",
      type: "TextBoxWidget",
      parent: "grpbxRow5",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage5_PROPERTIES

      // END_USER_CODE-USER_txtMileage5_PROPERTIES
    },
    txtMileage6: {
      name: "txtMileage6",
      type: "TextBoxWidget",
      parent: "grpbxRow6",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage6_PROPERTIES

      // END_USER_CODE-USER_txtMileage6_PROPERTIES
    },
    txtMileage7: {
      name: "txtMileage7",
      type: "TextBoxWidget",
      parent: "grpbxRow7",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage7_PROPERTIES

      // END_USER_CODE-USER_txtMileage7_PROPERTIES
    },
    txtMileage8: {
      name: "txtMileage8",
      type: "TextBoxWidget",
      parent: "grpbxRow8",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage8_PROPERTIES

      // END_USER_CODE-USER_txtMileage8_PROPERTIES
    },
    txtMileage9: {
      name: "txtMileage9",
      type: "TextBoxWidget",
      parent: "grpbxRow9",
      HasLabel: false,
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage9_PROPERTIES

      // END_USER_CODE-USER_txtMileage9_PROPERTIES
    },
    txtOverride0: {
      name: "txtOverride0",
      type: "TextBoxWidget",
      parent: "grpbxRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride0_PROPERTIES

      // END_USER_CODE-USER_txtOverride0_PROPERTIES
    },
    txtOverride1: {
      name: "txtOverride1",
      type: "TextBoxWidget",
      parent: "grpbxRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride1_PROPERTIES

      // END_USER_CODE-USER_txtOverride1_PROPERTIES
    },
    txtOverride2: {
      name: "txtOverride2",
      type: "TextBoxWidget",
      parent: "grpbxRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride2_PROPERTIES

      // END_USER_CODE-USER_txtOverride2_PROPERTIES
    },
    txtOverride3: {
      name: "txtOverride3",
      type: "TextBoxWidget",
      parent: "grpbxRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride3_PROPERTIES

      // END_USER_CODE-USER_txtOverride3_PROPERTIES
    },
    txtOverride4: {
      name: "txtOverride4",
      type: "TextBoxWidget",
      parent: "grpbxRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride4_PROPERTIES

      // END_USER_CODE-USER_txtOverride4_PROPERTIES
    },
    txtOverride5: {
      name: "txtOverride5",
      type: "TextBoxWidget",
      parent: "grpbxRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride5_PROPERTIES

      // END_USER_CODE-USER_txtOverride5_PROPERTIES
    },
    txtOverride6: {
      name: "txtOverride6",
      type: "TextBoxWidget",
      parent: "grpbxRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride6_PROPERTIES

      // END_USER_CODE-USER_txtOverride6_PROPERTIES
    },
    txtOverride7: {
      name: "txtOverride7",
      type: "TextBoxWidget",
      parent: "grpbxRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride7_PROPERTIES

      // END_USER_CODE-USER_txtOverride7_PROPERTIES
    },
    txtOverride8: {
      name: "txtOverride8",
      type: "TextBoxWidget",
      parent: "grpbxRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride8_PROPERTIES

      // END_USER_CODE-USER_txtOverride8_PROPERTIES
    },
    txtOverride9: {
      name: "txtOverride9",
      type: "TextBoxWidget",
      parent: "grpbxRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride9_PROPERTIES

      // END_USER_CODE-USER_txtOverride9_PROPERTIES
    },
    txtPayFreightInd: {
      name: "txtPayFreightInd",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Pay Freight Ind:",
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayFreightInd_PROPERTIES

      // END_USER_CODE-USER_txtPayFreightInd_PROPERTIES
    },
    txtTransferNum0: {
      name: "txtTransferNum0",
      type: "TextBoxWidget",
      parent: "grpbxRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum0_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum0_PROPERTIES
    },
    txtTransferNum1: {
      name: "txtTransferNum1",
      type: "TextBoxWidget",
      parent: "grpbxRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum1_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum1_PROPERTIES
    },
    txtTransferNum2: {
      name: "txtTransferNum2",
      type: "TextBoxWidget",
      parent: "grpbxRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum2_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum2_PROPERTIES
    },
    txtTransferNum3: {
      name: "txtTransferNum3",
      type: "TextBoxWidget",
      parent: "grpbxRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum3_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum3_PROPERTIES
    },
    txtTransferNum4: {
      name: "txtTransferNum4",
      type: "TextBoxWidget",
      parent: "grpbxRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum4_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum4_PROPERTIES
    },
    txtTransferNum5: {
      name: "txtTransferNum5",
      type: "TextBoxWidget",
      parent: "grpbxRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum5_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum5_PROPERTIES
    },
    txtTransferNum6: {
      name: "txtTransferNum6",
      type: "TextBoxWidget",
      parent: "grpbxRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum6_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum6_PROPERTIES
    },
    txtTransferNum7: {
      name: "txtTransferNum7",
      type: "TextBoxWidget",
      parent: "grpbxRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum7_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum7_PROPERTIES
    },
    txtTransferNum8: {
      name: "txtTransferNum8",
      type: "TextBoxWidget",
      parent: "grpbxRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum8_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum8_PROPERTIES
    },
    txtTransferNum9: {
      name: "txtTransferNum9",
      type: "TextBoxWidget",
      parent: "grpbxRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum9_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum9_PROPERTIES
    },
    txtTripNum: {
      name: "txtTripNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Trip #:",
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTripNum_PROPERTIES

      // END_USER_CODE-USER_txtTripNum_PROPERTIES
    },
    txtVehicleNum: {
      name: "txtVehicleNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vehicle #:",
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtVehicleNum_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vendor:",
      Enable: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxMultiStopProfile: {
      name: "grpbxMultiStopProfile",
      type: "GroupBoxWidget",
      parent: "MultiStopProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxMultiStopProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxMultiStopProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "MultiStopProfile",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "StockTransfer#MultiStopSelect": {},
      },
      REVERSE: {
        "StockTransfer#MultiStopSelect": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnSearchForTransfers: {
      DEFAULT: ["StockTransfer#MultiStopSelect#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  // #region State Variables
  const [intTotalLEGCount, setintTotalLEGCount] = useState(0);
  // const [lblFrtAccrlComplete, setlblFrtAccrlComplete] = useState('N');
  // const [lblLeasedTrailerInd, setlblLeasedTrailerInd] = useState('');
  const [lbl_pay_freight_ind, setlbl_pay_freight_ind] = useState('');
  const [mbThruHaul, setmbThruHaul] = useState('');
  let mbFormDirty
  let lblFrtAccrlComplete = 'N'
  let lblLeasedTrailerInd = ''

  let lbllegWt = []
  let lblDestBPT
  let updatesuccess
  let mbDontAsk
  // #endregion

  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // Fomr Load event
    FormLoad();
    // setTimeout(() => {
    //   document.getElementById('txtTransferNum0')?.focus()
    // }, 100);
  }, []);

  useEffect(() => {
    return () => {
      onbtnExitClick()
    }
  }, [])

  useEffect(() => {
    let val = getData(thisObj, 'FrtAccrlCompleteCaption')
    if (val == "Y") {
      enable(thisObj, 'btnReverseFreightAccural');
      show(thisObj, 'btnReverseFreightAccural');
      disable(thisObj, 'btnGreightAccural');
      hide(thisObj, 'btnGreightAccural');
      disable(thisObj, 'btnUpdate');
      disable(thisObj, 'btnDelete');
      disable(thisObj, 'btnSearchForTransfers');
      setData(thisObj, 'FrtAccrlCompleteCaption', null)
    }
  }, [getData(thisObj, 'FrtAccrlCompleteCaption')])


  useEffect(() => {
    switch (lbl_pay_freight_ind.toUpperCase()) {
      case 'R':
        setValue(thisObj, 'txtPayFreightInd', 'Received Weight')
        break;
      case 'S':
        setValue(thisObj, 'txtPayFreightInd', 'Shipped Weight')
        break;
      case 'O':
        setValue(thisObj, 'txtPayFreightInd', 'Other Weight')
        break;
      case 'N':
        setValue(thisObj, 'txtPayFreightInd', 'No Freight')
        break;

      default:
        break;
    }
  }, [lbl_pay_freight_ind]);

  useEffect(() => {
    let data = getData(thisObj, 'frmMultiStopProfileData')
    if (data != null && data != undefined && data.length > 0) {
      let lstDestBPT = getData(thisObj, 'lstDestBPT')
      let shippingArr = _kaledo.thisObj.state['ddShippingLoacation0'].valueList
      let typeArr = _kaledo.thisObj.state['ddType0'].valueList
      let receivingArr = _kaledo.thisObj.state['ddReceivingLocation0'].valueList
      let arr = []

      for (let i = 0; i < 10; i++) {
        if (getValue(_kaledo.thisObj, `txtTransferNum${i}`) != ''
          && getValue(_kaledo.thisObj, `txtTransferNum${i}`) != undefined
          && getValue(_kaledo.thisObj, `txtTransferNum${i}`) != null) {
          arr.push(getValue(_kaledo.thisObj, `txtTransferNum${i}`))
        } else {
          arr.push(null)
        }
      }


      let _start = 0
      for (let j = 0; j <= 9; j++) {
        if (getValue(_kaledo.thisObj, `txtTransferNum${j}`) == ''
          || getValue(_kaledo.thisObj, `txtTransferNum${j}`) == undefined
          || getValue(_kaledo.thisObj, `txtTransferNum${j}`) == null) {

          _start = j
          break
        } else {
          if (j == 9) {
            _start = j + 1

          }
        }
      }


      let _totalEle = _start

      let _index = 0
      for (let j = _start; j < 10; j++) {
        let _dataEle = null
        if (arr[j] == null) {
          if (data[_index]) {
            _dataEle = data[_index++]

            if (getValue(_kaledo.thisObj, 'txtCarrierAbbreviation') == '' || getValue(_kaledo.thisObj, 'txtCarrierAbbreviation') == undefined || getValue(_kaledo.thisObj, 'txtCarrierAbbreviation') == null) {
              setValue(_kaledo.thisObj, 'txtCarrierAbbreviation', data[0].txtcolScacID)
            }
            if (getValue(_kaledo.thisObj, 'txtVehicleNum') == '' || getValue(_kaledo.thisObj, 'txtVehicleNum') == undefined || getValue(_kaledo.thisObj, 'txtVehicleNum') == null) {
              setValue(_kaledo.thisObj, 'txtVehicleNum', data[0].txtcolVehicleNum)
            }
            if (getValue(_kaledo.thisObj, 'txtVendor') == '' || getValue(_kaledo.thisObj, 'txtVendor') == undefined || getValue(_kaledo.thisObj, 'txtVendor') == null) {
              setValue(_kaledo.thisObj, 'txtVendor', data[0].txtcolVendorNum)
            }
            if (getValue(_kaledo.thisObj, 'txtPayFreightInd') == '' || getValue(_kaledo.thisObj, 'txtPayFreightInd') == undefined || getValue(_kaledo.thisObj, 'txtPayFreightInd') == null) {
              setlbl_pay_freight_ind(data[0].txtcolPayInd)
            }

            setValue(_kaledo.thisObj, `txtTransferNum${j}`, _dataEle.txtcolTransferNum)

            if (shippingArr.find(item => item.key == _dataEle.txtcolOriginalBuyingPoint).key == _dataEle.txtcolOriginalBuyingPoint) {
              setValue(_kaledo.thisObj, `ddShippingLoacation${j}`, shippingArr.find(item => item.key == _dataEle.txtcolOriginalBuyingPoint).key)
            }

            if (typeArr.find(item => item.key == _dataEle.txtcolTransferType).key == _dataEle.txtcolTransferType) {
              setValue(_kaledo.thisObj, `ddType${j}`, typeArr.find(item => item.key == _dataEle.txtcolTransferType).key)
            }

            if (receivingArr.find(item => item.key == _dataEle.txtcolDestinationBuyingPoint).key == _dataEle.txtcolDestinationBuyingPoint) {
              setValue(_kaledo.thisObj, `ddReceivingLocation${j}`, receivingArr.find(item => item.key == _dataEle.txtcolDestinationBuyingPoint).key)
              setData(_kaledo.thisObj, 'cboDestBPTVal', lstDestBPT.find(item => item.key == _dataEle.txtcolDestinationBuyingPoint).key)
            }

            setValue(_kaledo.thisObj, `txtMileage${j}`, _dataEle.txtcolLegMileage)
            setValue(_kaledo.thisObj, `txtOverride${j}`, _dataEle.txtcolOverride == 0 ? '' : _dataEle.txtcolOverride)

          }
        }
        _totalEle += 1

        if (_totalEle == 10 && data[_index] != undefined && data[_index] != null) {
          _dataEle = data[_index]
          showMessage(_kaledo.thisObj, `Multi-Trips can only contain up to 10 Transfers. Transfer ${_dataEle.txtcolTransferNum} cannot be added to the multi-trip.`)
          break
        }

      }

      if (_start == 10) {
        showMessage(_kaledo.thisObj, `Multi-Trips can only contain up to 10 Transfers. Transfer ${data[0].txtcolTransferNum} cannot be added to the multi-trip.`)
      }

    }
    setData(thisObj, 'frmMultiStopProfileData', null)
  }, [getData(thisObj, 'frmMultiStopProfileData')]);

  // #region FormLoad Method
  const FormLoad = async () => {
    try {
      setLoading(true)
      let prntData = getData(thisObj, 'frmMultiStopProfile')
      setintTotalLEGCount(0);
      bFillLegNum();
      await bFillBuyingPointList();
      await bFillTransferType();

      if (prntData.button == 'Open') {
        setValue(thisObj, 'txtTripNum', prntData.trip_id)
        await bFillForm()
        mbFormDirty = false
      } else if (prntData.button == 'Create') {

      }
      await EnableDisableButtons();
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      errorHandler(err, "FormLoad")
    }
  }
  // #endregion

  // #region Events
  const onbtnExitClick = () => {
    try {
      setData(thisObj, 'cboDestBPTVal', null)
      setData(thisObj, 'lstDestBPT', null)
      setData(thisObj, 'lbllegDest', null)
      setData(thisObj, 'lbllegWt', null)
      if (getData(thisObj, 'onSuccessfullUpdate') == true) {
        setData(thisObj, 'RefreshMultiStopSearch', true)
        setData(thisObj, 'onSuccessfullUpdate', false)
      }
      document.getElementById("StockTransfer_MultiStopProfilePopUp").childNodes[0].click()
      return true;
    }
    catch (err) {
      errorHandler(err, "onbtnExitClick")
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;


  const onbtnReverseFreightAccuralClick = () => {
    try {
      let lblTripID = Number(getValue(thisObj, 'txtTripNum'));
      if (!Number.isInteger(lblTripID)) {
        return false;
      }
      StockTransferService.RemoveFreightAccural(null, null, null, lblTripID, null).then(response => {
        if (response !== null) {
          lblFrtAccrlComplete = 'N';
          EnableDisableButtons()
        }
      });
    }
    catch (err) {
      errorHandler(err, "onbtnReverseFreightAccuralClick")
    }
  }
  thisObj.onbtnReverseFreightAccuralClick = onbtnReverseFreightAccuralClick;

  
  // #endregion

  // #region Common Methods
  const bFillLegNum = () => {
    for (let i = 0; i <= 9; i++) {
      setValue(thisObj, 'txtLegNum' + i, i + 1);
      disable(thisObj, `txtLegNum${i}`);
    }
  }

  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  const bFillBuyingPointList = async () => {
    try {
      let buyingPoint = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1080', '', '', '', '')
      if (buyingPoint !== undefined && buyingPoint.length > 0) {
        let js = [];
        let obj;
        let jsDestBPT = [];
        let objDestBPT;
        obj = { key: '', description: '' };
        js.push(obj);
        objDestBPT = { key: '' };
        jsDestBPT.push(objDestBPT);
        for (var i = 0; i < buyingPoint.length; i++) {
          let obj = { key: buyingPoint[i].buy_pt_id, description: buyingPoint[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(buyingPoint[i].buy_pt_name.trim()) }
          js.push(obj);
          let objDestBPT = { key: buyingPoint[i].buy_pt_id }
          jsDestBPT.push(objDestBPT);
        }
        setData(thisObj, 'lstDestBPT', jsDestBPT)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation0: {
              ...state["ddShippingLoacation0"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation0', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation0: {
              ...state["ddReceivingLocation0"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation0', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation1: {
              ...state["ddShippingLoacation1"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation1', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation1: {
              ...state["ddReceivingLocation1"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation1', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation2: {
              ...state["ddShippingLoacation2"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation2', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation2: {
              ...state["ddReceivingLocation2"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation2', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation3: {
              ...state["ddShippingLoacation3"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation3', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation3: {
              ...state["ddReceivingLocation3"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation3', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation4: {
              ...state["ddShippingLoacation4"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation4', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation4: {
              ...state["ddReceivingLocation4"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation4', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation5: {
              ...state["ddShippingLoacation5"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation5', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation5: {
              ...state["ddReceivingLocation5"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation5', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation6: {
              ...state["ddShippingLoacation6"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation6', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation6: {
              ...state["ddReceivingLocation6"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation6', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation7: {
              ...state["ddShippingLoacation7"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation7', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation7: {
              ...state["ddReceivingLocation7"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation7', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation8: {
              ...state["ddShippingLoacation8"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation8', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation8: {
              ...state["ddReceivingLocation8"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation8', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoacation9: {
              ...state["ddShippingLoacation9"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddShippingLoacation9', js.at(0).key)

        thisObj.setState(current => {
          return {
            ...current,
            ddReceivingLocation9: {
              ...state["ddReceivingLocation9"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddReceivingLocation9', js.at(0).key)
      }
      ;
    }
    catch (err) {
      errorHandler(err, "bFillBuyingPointList")
    }
  }

  const bFillTransferType = async () => {
    try {
      let js = [];
      let obj;
      obj = { key: '', description: '', tag: '' };
      js.push(obj);
      obj = { key: 'ST', description: 'Stock Transfer', tag: '' };
      js.push(obj);
      obj = { key: 'FF', description: 'Farm Freight', tag: '' };
      js.push(obj);
      obj = { key: 'NL', description: 'No Load', tag: '' };
      js.push(obj);

      thisObj.setState(current => {
        return {
          ...current,
          ddType0: {
            ...state["ddType0"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType0', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType1: {
            ...state["ddType1"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType1', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType2: {
            ...state["ddType2"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType2', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType3: {
            ...state["ddType3"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType3', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType4: {
            ...state["ddType4"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType4', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType5: {
            ...state["ddType5"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType5', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType6: {
            ...state["ddType6"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType6', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType7: {
            ...state["ddType7"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType7', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType8: {
            ...state["ddType8"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType8', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddType9: {
            ...state["ddType9"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddType9', js.at(0).key)
    }
    catch (err) {
      errorHandler(err, "bFillTransferType")
    }
  }

  const EnableDisableButtons = async () => {
    try {
      if (lblFrtAccrlComplete == "Y") {
        enable(thisObj, 'btnReverseFreightAccural');
        show(thisObj, 'btnReverseFreightAccural');
        disable(thisObj, 'btnGreightAccural');
        hide(thisObj, 'btnGreightAccural');
        disable(thisObj, 'btnUpdate');
        disable(thisObj, 'btnDelete');
        disable(thisObj, 'btnSearchForTransfers');
      } else {
        disable(thisObj, 'btnReverseFreightAccural');
        hide(thisObj, 'btnReverseFreightAccural');
        show(thisObj, 'btnGreightAccural');
        let lblTripID = Number(getValue(_kaledo.thisObj, 'txtTripNum'));
        if (Number.isInteger(lblTripID)) {
          enable(thisObj, 'btnGreightAccural');
          enable(thisObj, 'btnDelete');
        } else {
          disable(thisObj, 'btnGreightAccural');
          disable(thisObj, 'btnDelete');
        }
        enable(thisObj, 'btnUpdate');
        enable(thisObj, 'btnSearchForTransfers');
      }
    }
    catch (err) {
      errorHandler(err, "EnableDisableButtons")
    }
  }

  const filllabels = async (ind) => {
    try {
      let trans_num
      let trans_type
      let shiping

      if (getValue(thisObj, 'txtTransferNum0') != '' && getValue(thisObj, 'txtTransferNum0') != undefined && getValue(thisObj, 'txtTransferNum0') != null) {
        trans_num = getValue(thisObj, 'txtTransferNum0')
      }
      if (getValue(thisObj, 'ddType0') != '' && getValue(thisObj, 'ddType0') != undefined && getValue(thisObj, 'ddType0') != null) {
        trans_type = getValue(thisObj, 'ddType0')
      }
      if (getValue(thisObj, 'ddShippingLoacation0') != '' && getValue(thisObj, 'ddShippingLoacation0') != undefined && getValue(thisObj, 'ddShippingLoacation0') != null) {
        shiping = getValue(thisObj, 'ddShippingLoacation0')
      }
      let resp = await StockTransferService.RetrieveMultistopAvailableTransferCheckDetails('PN1080', trans_num, null, trans_type, shiping)
      if (resp != null && resp != undefined && resp.length > 0) {
        if (resp[0].frt_scac_id != '' && resp[0].frt_scac_id != null && resp[0].frt_scac_id != undefined) {
          setValue(thisObj, 'txtCarrierAbbreviation', resp[0].frt_scac_id)
          setValue(thisObj, 'txtVendor', resp[0].frt_vendor)
          setValue(thisObj, 'txtVehicleNum', resp[0].vehicle_num)
          setlbl_pay_freight_ind(resp[0].pay_freight_ind)
        }
      } else {
        return
      }
    }
    catch (err) {
      errorHandler(err, "filllabels")
    }
  }

  const FillTransLoc = async (ind) => {
    try {

      let lstDestBPT = getData(thisObj, 'lstDestBPT')

      if (getValue(thisObj, `txtTransferNum${ind}`) == '' || getValue(thisObj, `txtTransferNum${ind}`) == undefined || getValue(thisObj, `txtTransferNum${ind}`) == null)
        return
      if (getValue(thisObj, `ddType${ind}`) == '' || getValue(thisObj, `ddType${ind}`) == undefined || getValue(thisObj, `ddType${ind}`) == null)
        return
      if (getValue(thisObj, `ddShippingLoacation${ind}`) == '' || getValue(thisObj, `ddShippingLoacation${ind}`) == undefined || getValue(thisObj, `ddShippingLoacation${ind}`) == null)
        return


      let tranferNum = getValue(thisObj, `txtTransferNum${ind}`)
      let tranferType = getValue(thisObj, `ddType${ind}`)
      let shpButPt = getValue(thisObj, `ddShippingLoacation${ind}`)

      let resp = await StockTransferService.RetrieveTransferHeaderList('PN1080', null, tranferNum, tranferType, null, null, null, null, null, null, null, shpButPt)
      if (resp != null && resp != undefined && resp.length > 0) {
        for (let i = 0; i < (_kaledo.thisObj.state[`ddShippingLoacation${ind}`].valueList).length - 1; i++) {
          let valueList = _kaledo.thisObj.state[`ddShippingLoacation${ind}`].valueList
          if (resp[0].shp_buy_pt != '' && resp[0].shp_buy_pt != null && resp[0].shp_buy_pt != undefined) {
            if (valueList[i].key == resp[0].shp_buy_pt) {
              setValue(thisObj, `ddShippingLoacation${ind}`, (valueList)[i].key)
              break
            }
          }
        }
        for (let i = 0; i < (_kaledo.thisObj.state[`ddReceivingLocation${ind}`].valueList).length - 1; i++) {
          let valueList = _kaledo.thisObj.state[`ddShippingLoacation${ind}`].valueList
          if (resp[0].rec_buy_pt != '' && resp[0].rec_buy_pt != null && resp[0].rec_buy_pt != undefined) {
            if (valueList[i].key == resp[0].rec_buy_pt) {
              setValue(thisObj, `ddReceivingLocation${ind}`, (valueList)[i].key)
              break
            }
          } else {
            setValue(thisObj, `ddReceivingLocation${ind}`, (_kaledo.thisObj.state[`ddReceivingLocation${ind}`].valueList)[0].key)
          }
        }
        for (let i = 0; i < lstDestBPT.length - 1; i++) {
          if (resp[0].rec_buy_pt != '' && resp[0].rec_buy_pt != null && resp[0].rec_buy_pt != undefined) {
            if (lstDestBPT[i].key == resp[0].rec_buy_pt) {
              setData(thisObj, 'cboDestBPTVal', lstDestBPT[i].key)
              break
            }
          }
        }

        setValue(thisObj, `txtMileage${ind}`, resp[0].leg_mileage)
        setValue(thisObj, `txtOverride${ind}`, resp[0].frt_mileage_override == 0 ? '' : resp[0].frt_mileage_override)
      } else {
        return
      }
    }
    catch (err) {
      errorHandler(err, "FillTransLoc")
    }
  }


  const bFillForm = async () => {
    try {
      let lstDestBPT = getData(thisObj, 'lstDestBPT')
      let trip_id = getValue(_kaledo.thisObj, 'txtTripNum')
      let lbllegDest = []
      let lbllegWt = []
      let cboTransferType_Tag = []

      let response = await StockTransferService.RetrieveMultistopTripDetails(null, trip_id)
      if (response != null && response != undefined && response.length > 0) {
        setValue(thisObj, 'txtCarrierAbbreviation', response[0].frt_scac_id)
        setValue(thisObj, 'txtVendor', response[0].frt_vendor)
        setValue(thisObj, 'txtVehicleNum', response[0].vehicle_num)
        setlbl_pay_freight_ind(response[0].pay_freight_ind)

        lblFrtAccrlComplete = response[0].frt_accrl_complete;
        EnableDisableButtons()

        lblLeasedTrailerInd = 'N'
        if (response[0].vehicle_num != null && response[0].vehicle_num != '' && response[0].vehicle_num != undefined) {
          let Resp = await StockTransferService.RetrieveLeasedTrailerListDetails(null, response[0].vehicle_num)
          if (Resp != null && Resp != undefined && Resp.length > 0) {
            if (Resp[0].vehicle_num != null && Resp[0].vehicle_num != '' && Resp[0].vehicle_num != undefined) {
              lblLeasedTrailerInd = 'Y'
            }
          }
        }

        setValue(thisObj, 'lblAddedByValue', response[0].add_user + ' ' + response[0].add_date_time)
        setValue(thisObj, 'lblChangedByValue', response[0].chg_user + ' ' + response[0].chg_date_time)


        for (let i = 0; i < response.length; i++) {
          for (let j = 0; j < lstDestBPT.length - 1; j++) {
            if (lstDestBPT[j].key == response[i].rec_buy_pt) {
              setData(thisObj, 'cboDestBPTVal', lstDestBPT[j].key)
              break
            }
          }


          for (let k = 0; k < (_kaledo.thisObj.state[`ddShippingLoacation${i}`].valueList).length - 1; k++) {
            let valueList = _kaledo.thisObj.state[`ddShippingLoacation${i}`].valueList
            if (valueList[k].key == response[i].shp_buy_pt) {
              setValue(thisObj, `ddShippingLoacation${i}`, (valueList)[k].key)
              await ddChangeCommonMethod(i)
              if (i > 0) {
                lbllegDest[i - 1] = response[i].shp_buy_pt
                lbllegDest[i] = response[i].rec_buy_pt
              } else {
                lbllegDest[i] = response[i].rec_buy_pt
              }
              setData(thisObj, 'lbllegDest', lbllegDest)
              break
            }
          }

          for (let m = 0; m < (_kaledo.thisObj.state[`ddReceivingLocation${i}`].valueList).length - 1; m++) {
            let valueList = _kaledo.thisObj.state[`ddReceivingLocation${i}`].valueList
            if (valueList[m].key == response[i].rec_buy_pt) {
              setValue(thisObj, `ddReceivingLocation${i}`, (valueList)[m].key)
              break
            }
          }

          for (let n = 0; n < (_kaledo.thisObj.state[`ddType${i}`].valueList).length - 1; n++) {
            let valueList = _kaledo.thisObj.state[`ddType${i}`].valueList
            if (valueList[n].key == response[i].transfer_type) {
              setValue(thisObj, `ddType${i}`, (valueList)[n].key)
              break
            }
          }

          setValue(thisObj, `txtTransferNum${i}`, response[i].transfer_num)
          cboTransferType_Tag.push(response[i].xfer_status)

          setValue(thisObj, `txtMileage${i}`, response[i].leg_mileage)
          setValue(thisObj, `txtOverride${i}`, response[i].frt_mileage_override == 0 ? '' : response[i].frt_mileage_override)

          switch ((response[i].pay_freight_ind).toUpperCase()) {
            case "R":
              lbllegWt.push(!isNaN(response[i].rec_peanut_wt) ? response[i].rec_peanut_wt : 0)
              break;
            case "S":
              lbllegWt.push(!isNaN(response[i].shp_peanut_wt) ? response[i].shp_peanut_wt : 0)
              break;
            case "O":
              lbllegWt.push(!isNaN(response[i].frt_other_wt) ? response[i].frt_other_wt : 0)
              break;
            default:
              lbllegWt.push(0)
              break;
          }

        }
        setData(thisObj, 'lbllegWt', lbllegWt)
        setData(thisObj, 'cboTransferType_Tag', cboTransferType_Tag)

        lblDestBPT = getData(thisObj, 'cboDestBPTVal')
        EnableDisableButtons()
        setLoading(false)
      } else {
        return
        setLoading(false)
      }
    }
    catch (err) {
      setLoading(false)
      errorHandler(err, "bFillForm")
    }
  }

  const ddChangeCommonMethod = async (ind) => {
    try {
      if (ind == 0) {
        await onddShippingLoacation0Change('', ind)
      } else if (ind == 1) {
        await onddShippingLoacation1Change('', ind)
      } else if (ind == 2) {
        await onddShippingLoacation2Change('', ind)
      } else if (ind == 3) {
        await onddShippingLoacation3Change('', ind)
      } else if (ind == 4) {
        await onddShippingLoacation4Change('', ind)
      } else if (ind == 5) {
        await onddShippingLoacation5Change('', ind)
      } else if (ind == 6) {
        await onddShippingLoacation6Change('', ind)
      } else if (ind == 7) {
        await onddShippingLoacation7Change('', ind)
      } else if (ind == 8) {
        await onddShippingLoacation8Change('', ind)
      } else if (ind == 9) {
        await onddShippingLoacation9Change('', ind)
      }
    }
    catch (err) {
      errorHandler(err, "ddChangeCommonMethod")
    }
  }

  const bFormValid = async () => {
    try {
      let strGrid = ''
      let sOrgBPTList = ''
      let lbllegDest = getData(thisObj, 'lbllegDest')

      for (let i = 0; i < 9; i++) {
        if (getValue(thisObj, `txtTransferNum${i}`) != '' && getValue(thisObj, `txtTransferNum${i}`) != null && getValue(thisObj, `txtTransferNum${i}`) != undefined) {
          strGrid += getValue(thisObj, `txtLegNum${i}`) + ','
          strGrid += getValue(thisObj, `txtTransferNum${i}`) + ','
          strGrid += getValue(thisObj, `ddType${i}`) + ','
          strGrid += (getValue(thisObj, `ddShippingLoacation${i}`)).slice(0, 3) + ','
          strGrid += (getValue(thisObj, `ddReceivingLocation${i}`)).slice(0, 3) + ','
          if (lbllegDest?.[i] != null && lbllegDest?.[i] != undefined && lbllegDest?.[i] != '') {
            strGrid += lbllegDest[i] + '|'
          } else {
            strGrid += '' + '|'
          }

          sOrgBPTList = sOrgBPTList + (getValue(thisObj, `ddReceivingLocation${i}`)).slice(0, 3)
        }
      }

      let trip_num
      let dest_buy_pt = getData(thisObj, 'cboDestBPTVal')
      let vendor_num = getValue(thisObj, 'txtVendor')
      let scac = getValue(thisObj, 'txtCarrierAbbreviation')
      let vehicle_num = getValue(thisObj, 'txtVehicleNum')
      let pay_ind = lbl_pay_freight_ind
      let thru_haul = mbThruHaul != 'Y' ? 'N' : mbThruHaul

      if (getValue(thisObj, 'txtTripNum') != '' && getValue(thisObj, 'txtTripNum') != null && getValue(thisObj, 'txtTripNum') != undefined) {
        trip_num = getValue(thisObj, 'txtTripNum')
      } else {
        trip_num = null
      }

      let resp = await StockTransferService.RetrieveMultistopEditChecks(dest_buy_pt, vendor_num, scac, trip_num, strGrid, pay_ind, vehicle_num, thru_haul)
      if ((resp?.status == 500 || resp?.status == 400 || resp?.status == 404 || resp?.status == 403) && resp?.message != undefined) {
        showMessage(thisObj, `${resp.message}`, false)
        return false
      }

      await EnableDisableButtons()
      return true
    }
    catch (err) {
      errorHandler(err, "bFormValid")
    }
  }

  const onbtnUpdateClick = async () => {
    try {
      setLoading(true)
      updatesuccess = false
      let lbllegDest
      let lbllegWt
      if (getData(thisObj, 'lbllegDest') == null || getData(thisObj, 'lbllegDest') == undefined) {
        lbllegDest = []
      } else {
        lbllegDest = getData(thisObj, 'lbllegDest')
      }

      if (getData(thisObj, 'lbllegWt') == null || getData(thisObj, 'lbllegWt') == undefined) {
        lbllegWt = []
      } else {
        lbllegWt = getData(thisObj, 'lbllegWt')
      }
      // await FillTransLoc(0)

      let strAction = ''
      let bAskThruHaul
      let strGrid = ''

      if (getValue(thisObj, 'txtTripNum') == '' || getValue(thisObj, 'txtTripNum') == null || getValue(thisObj, 'txtTripNum') == undefined) {
        strAction = "A"
      } else {
        strAction = "U"
      }

      if (! await bFormValid()) {
        setLoading(false)
        return
      }

      setmbThruHaul('N')

      if (mbDontAsk) {
        bAskThruHaul = false
      } else {
        bAskThruHaul = true
      }

      for (let i = 0; i < 9; i++) {
        let lbllegWtVal
        if (!isNaN(lbllegWt?.[i])) {
          lbllegWtVal = lbllegWt[i]
        } else {
          lbllegWtVal = 0
        }
        let txtMileageVal
        if (!isNaN(getValue(thisObj, `txtMileage${i}`)) && getValue(thisObj, `txtMileage${i}`) != '' && getValue(thisObj, `txtMileage${i}`) != 0 && getValue(thisObj, `txtMileage${i}`) != undefined) {
          txtMileageVal = getValue(thisObj, `txtMileage${i}`)
        } else {
          txtMileageVal = 0
        }
        let txtOverrideVal
        if (!isNaN(getValue(thisObj, `txtOverride${i}`) && getValue(thisObj, `txtOverride${i}`) != '') && getValue(thisObj, `txtOverride${i}`) != 0 && getValue(thisObj, `txtOverride${i}`) != undefined) {
          txtOverrideVal = getValue(thisObj, `txtOverride${i}`)
        } else {
          txtOverrideVal = 0
        }
        if (getValue(thisObj, `txtTransferNum${i}`) != '' && getValue(thisObj, `txtTransferNum${i}`) != undefined && getValue(thisObj, `txtTransferNum${i}`) != null) {
          if (bAskThruHaul) {
            if (getValue(thisObj, `ddType${i}`) == 'FF') {
              if (confirm("Is this a Thru Haul? Confirm Thru Haul") == true) {
                setmbThruHaul('Y')
              } else {
                setLoading(false)
                return
              }
              bAskThruHaul = false
            }
          }

          strGrid += getValue(thisObj, `txtLegNum${i}`) + ','
          strGrid += getValue(thisObj, `txtTransferNum${i}`) + ','
          strGrid += getValue(thisObj, `ddType${i}`) + ','
          strGrid += (getValue(thisObj, `ddShippingLoacation${i}`)).slice(0, 3) + ','
          if (getValue(thisObj, `txtTransferNum${i + 1}`) != '' && getValue(thisObj, `txtTransferNum${i + 1}`) != undefined && getValue(thisObj, `txtTransferNum${i + 1}`) != null) {
            lbllegDest[i] = (getValue(thisObj, `ddShippingLoacation${i + 1}`)).slice(0, 3)
          } else {
            lbllegDest[i] = (getValue(thisObj, `ddReceivingLocation${i}`)).slice(0, 3)
          }
          strGrid += lbllegDest[i] + ','
          strGrid += lbllegWtVal + ','
          strGrid += (getValue(thisObj, `ddReceivingLocation${i}`)).slice(0, 3) + ','
          strGrid += txtMileageVal + ','
          strGrid += txtOverrideVal + '|'
        }
      }

      let dataObj = {}

      if (strAction == 'U') {
        dataObj.trip_num = getValue(thisObj, 'txtTripNum')
      } else {
        dataObj.trip_num = ''
      }

      dataObj.dest_buy_pt = getData(thisObj, 'cboDestBPTVal') == null ? '' : getData(thisObj, 'cboDestBPTVal')
      dataObj.leginfo = strGrid
      dataObj.action = strAction

      let resp = await StockTransferService.UpdateMultistopTrip(null, dataObj)
      if (resp.status == 200) {
        if (resp?.result != null && resp?.result != undefined && resp?.result != '') {
          alert(`The Multi trip ${resp.result} is successfully created !`)
          setValue(thisObj, 'txtTripNum', resp.result)
          setData(thisObj, 'onSuccessfullUpdate', true)
          await bFillForm()
        } else {
          alert(`The Multi trip ${getValue(thisObj, 'txtTripNum')} has been successfully updated!`)
          setData(thisObj, 'onSuccessfullUpdate', true)
          await bFillForm()
        }
      } else if (resp.status == 500) {
        showMessage(thisObj, resp.message.slice(53), false)
        setLoading(false)
        return
      }

      updatesuccess = true
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      errorHandler(err, "onbtnUpdateClick")
    }
    // finally {
      setLoading(false)
    // }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick

  const onbtnDeleteClick = async () => {
    try {
      let lbllegDest = getData(thisObj, 'lbllegDest')
      let lbllegWt = getData(thisObj, 'lbllegWt')
      let strAction = 'D'
      let strGrid = ''

      for (let i = 0; i < 9; i++) {
        let lbllegWtVal
        if (!isNaN(lbllegWt[i])) {
          lbllegWtVal = lbllegWt[i]
        } else {
          lbllegWtVal = 0
        }
        let txtMileageVal
        if (!isNaN(getValue(thisObj, `txtMileage${i}`)) && getValue(thisObj, `txtMileage${i}`) != '') {
          txtMileageVal = getValue(thisObj, `txtMileage${i}`)
        } else {
          txtMileageVal = 0
        }
        let txtOverrideVal
        if (!isNaN(getValue(thisObj, `txtOverride${i}`))) {
          txtOverrideVal = getValue(thisObj, `txtOverride${i}`)
        } else {
          txtOverrideVal = 0
        }
        if (getValue(thisObj, `txtTransferNum${i}`) != '' && getValue(thisObj, `txtTransferNum${i}`) != undefined && getValue(thisObj, `txtTransferNum${i}`) != null) {

          strGrid += getValue(thisObj, `txtLegNum${i}`) + ','
          strGrid += getValue(thisObj, `txtTransferNum${i}`) + ','
          strGrid += getValue(thisObj, `ddType${i}`) + ','
          strGrid += (getValue(thisObj, `ddShippingLoacation${i}`)).slice(0, 3) + ','
          if (getValue(thisObj, `txtTransferNum${i + 1}`) != '' && getValue(thisObj, `txtTransferNum${i + 1}`) != undefined && getValue(thisObj, `txtTransferNum${i + 1}`) != null) {
            lbllegDest[i] = (getValue(thisObj, `ddShippingLoacation${i + 1}`)).slice(0, 3)
          } else {
            lbllegDest[i] = (getValue(thisObj, `ddReceivingLocation${i}`)).slice(0, 3)
          }
          strGrid += lbllegDest[i] + ','
          strGrid += lbllegWtVal + '|'
        }
      }

      let dataObj = {}


      dataObj.trip_num = getValue(thisObj, 'txtTripNum')
      dataObj.dest_buy_pt = getData(thisObj, 'cboDestBPTVal')
      dataObj.leginfo = strGrid
      dataObj.action = strAction

      let resp = await StockTransferService.UpdateMultistopTrip(null, dataObj)
      if (resp.status == 200) {
        setData(thisObj, 'RefreshMultiStopSearch', true)
        document.getElementById("StockTransfer_MultiStopProfilePopUp").childNodes[0].click()
      } else {
        showMessage(thisObj, `${resp.message},`, false)
        return
      }

    }
    catch (err) {
      errorHandler(err, "onbtnDeleteClick")
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick




  const onbtnSearchForTransfersClick = () => {
    let num = 0
    for (let i = 0; i < 10; i++) {
      if (getValue(_kaledo.thisObj, `txtTransferNum${i}`) != ''
        && getValue(_kaledo.thisObj, `txtTransferNum${i}`) != undefined
        && getValue(_kaledo.thisObj, `txtTransferNum${i}`) != null) {
        num = num + 1
      } 
    }
    setData(thisObj, 'numOfRowsCount', num)
    goTo(thisObj, "StockTransfer#MultiStopSelect#DEFAULT#true#Click")
  }
  thisObj.onbtnSearchForTransfersClick = onbtnSearchForTransfersClick

  const onbtnGreightAccuralClick = async () => {
    try {
      let cboTransferType_Tag = getData(thisObj, 'cboTransferType_Tag')

      for (let i = 0; i < 10; i++) {
        if (cboTransferType_Tag[i]?.toUpperCase() == 'V') {
          showMessage(thisObj, `Cannot complete freight accrual.  Transfer #${getValue(thisObj, `txtTransferNum${i}`)} is in void status.`)
          return
        }
      }

      mbDontAsk = true

      await onbtnUpdateClick()
      if (getData(thisObj, 'onSuccessfullUpdate') != true) {
        return
      }

      let obj = {}
      obj.InitForm = true
      obj.lblCropYear = cropYearFromLS()
      // load frmPremiumDeductionsFrtAccrl
      obj.lblTripID = getValue(thisObj, 'txtTripNum')
      obj.ReadPremiumsDeductionsFrtAccrl = true
      obj.sDestBuyPt = getData(thisObj, 'cboDestBPTVal')
      obj.sFrtScacId = getValue(thisObj, 'txtCarrierAbbreviation')
      obj.sFrtVendor = getValue(thisObj, 'txtVendor')
      obj.sLeasedTrailerInd = lblLeasedTrailerInd
      obj.dTripId = getValue(thisObj, 'txtTripNum')
      obj.lblShpBuyPt = getValue(thisObj, 'ddShippingLoacation0')
      obj.btnCompleteVisible=true

      setData(thisObj, 'PremiumDeductionsFrtAccrl', obj)
      // If frmPremiumDeductionsFrtAccrl.lblCancel <> "Y" Then   '2011-034
      //     frmPremiumDeductionsFrtAccrl.Show vbModal
      // End If
      goTo(thisObj, "StockTransfer#PremiumDeductionsFrtAccrl#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err, "onbtnGreightAccuralClick")
    }
  }
  thisObj.onbtnGreightAccuralClick = onbtnGreightAccuralClick


  const ontxtOverride0Blur = (e) => {
    setValue(thisObj, 'txtOverride0', e.target.value)
  }
  thisObj.ontxtOverride0Blur = ontxtOverride0Blur

  const ontxtOverride1Blur = (e) => {
    setValue(thisObj, 'txtOverride1', e.target.value)
  }
  thisObj.ontxtOverride1Blur = ontxtOverride1Blur

  const ontxtOverride2Blur = (e) => {
    setValue(thisObj, 'txtOverride2', e.target.value)
  }
  thisObj.ontxtOverride2Blur = ontxtOverride2Blur

  const ontxtOverride3Blur = (e) => {
    setValue(thisObj, 'txtOverride3', e.target.value)
  }
  thisObj.ontxtOverride3Blur = ontxtOverride3Blur

  const ontxtOverride4Blur = (e) => {
    setValue(thisObj, 'txtOverride4', e.target.value)
  }
  thisObj.ontxtOverride4Blur = ontxtOverride4Blur

  const ontxtOverride5Blur = (e) => {
    setValue(thisObj, 'txtOverride5', e.target.value)
  }
  thisObj.ontxtOverride5Blur = ontxtOverride5Blur

  const ontxtOverride6Blur = (e) => {
    setValue(thisObj, 'txtOverride6', e.target.value)
  }
  thisObj.ontxtOverride6Blur = ontxtOverride6Blur

  const ontxtOverride7Blur = (e) => {
    setValue(thisObj, 'txtOverride7', e.target.value)
  }
  thisObj.ontxtOverride7Blur = ontxtOverride7Blur

  const ontxtOverride8Blur = (e) => {
    setValue(thisObj, 'txtOverride8', e.target.value)
  }
  thisObj.ontxtOverride8Blur = ontxtOverride8Blur

  const ontxtOverride9Blur = (e) => {

    setValue(thisObj, 'txtOverride9', e.target.value)
  }
  thisObj.ontxtOverride9Blur = ontxtOverride9Blur

  const ontxtTransferNum0Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum0', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum0', e.target.value)
    }

    FillTransLoc(0)
    filllabels()
  }
  thisObj.ontxtTransferNum0Blur = ontxtTransferNum0Blur


  const ontxtTransferNum1Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum1', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum1', e.target.value)
    }

    FillTransLoc(1)
    filllabels()
  }
  thisObj.ontxtTransferNum1Blur = ontxtTransferNum1Blur

  const ontxtTransferNum2Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum2', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum2', e.target.value)
    }

    FillTransLoc(2)
    filllabels()
  }
  thisObj.ontxtTransferNum2Blur = ontxtTransferNum2Blur

  const ontxtTransferNum3Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum3', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum3', e.target.value)
    }

    FillTransLoc(3)
    filllabels()
  }
  thisObj.ontxtTransferNum3Blur = ontxtTransferNum3Blur

  const ontxtTransferNum4Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum4', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum4', e.target.value)
    }

    FillTransLoc(4)
    filllabels()
  }
  thisObj.ontxtTransferNum4Blur = ontxtTransferNum4Blur

  const ontxtTransferNum5Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum5', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum5', e.target.value)
    }

    FillTransLoc(5)
    filllabels()
  }
  thisObj.ontxtTransferNum5Blur = ontxtTransferNum5Blur

  const ontxtTransferNum6Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum6', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum6', e.target.value)
    }

    FillTransLoc(6)
    filllabels()
  }
  thisObj.ontxtTransferNum6Blur = ontxtTransferNum6Blur

  const ontxtTransferNum7Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum7', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum7', e.target.value)
    }

    FillTransLoc(7)
    filllabels()
  }
  thisObj.ontxtTransferNum7Blur = ontxtTransferNum7Blur

  const ontxtTransferNum8Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum8', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum8', e.target.value)
    }

    FillTransLoc(8)
    filllabels()
  }
  thisObj.ontxtTransferNum8Blur = ontxtTransferNum8Blur

  const ontxtTransferNum9Blur = (e) => {
    let numofO = ''
    let string = ''

    if (e.target.value == '' || e.target.value == undefined || e.target.value == null) {
      return
    } else if ((e.target.value).length < 6) {
      numofO = 6 - (e.target.value).length
      for (let i = 0; i < numofO; i++) {
        string += '0'
      }
      setValue(thisObj, 'txtTransferNum9', string.concat(e.target.value))
    } else if ((e.target.value).length == 6) {
      setValue(thisObj, 'txtTransferNum9', e.target.value)
    }

    FillTransLoc(9)
    filllabels()
  }
  thisObj.ontxtTransferNum9Blur = ontxtTransferNum9Blur

  const onddType1Change = () => {
    FillTransLoc(1)
  }
  thisObj.onddType1Change = onddType1Change

  const onddType2Change = () => {
    FillTransLoc(2)
  }
  thisObj.onddType2Change = onddType2Change

  const onddType3Change = () => {
    FillTransLoc(3)
  }
  thisObj.onddType3Change = onddType3Change

  const onddType4Change = () => {
    FillTransLoc(4)
  }
  thisObj.onddType4Change = onddType4Change

  const onddType5Change = () => {
    FillTransLoc(5)
  }
  thisObj.onddType5Change = onddType5Change

  const onddType6Change = () => {
    FillTransLoc(6)
  }
  thisObj.onddType6Change = onddType6Change

  const onddType7Change = () => {
    FillTransLoc(7)
  }
  thisObj.onddType7Change = onddType7Change

  const onddType8Change = () => {
    FillTransLoc(8)
  }
  thisObj.onddType8Change = onddType8Change

  const onddType9Change = () => {
    FillTransLoc(9)
  }
  thisObj.onddType9Change = onddType9Change

  const onddType0Change = () => {
    FillTransLoc(0)
  }
  thisObj.onddType0Change = onddType0Change

  const onddType1Blur = () => {
    filllabels()
  }
  thisObj.onddType1Blur = onddType1Blur

  const onddType2Blur = () => {
    filllabels()
  }
  thisObj.onddType2Blur = onddType2Blur

  const onddType3Blur = () => {
    filllabels()
  }
  thisObj.onddType3Blur = onddType3Blur

  const onddType4Blur = () => {
    filllabels()
  }
  thisObj.onddType4Blur = onddType4Blur

  const onddType5Blur = () => {
    filllabels()
  }
  thisObj.onddType5Blur = onddType5Blur

  const onddType6Blur = () => {
    filllabels()
  }
  thisObj.onddType6Blur = onddType6Blur

  const onddType7Blur = () => {
    filllabels()
  }
  thisObj.onddType7Blur = onddType7Blur

  const onddType8Blur = () => {
    filllabels()
  }
  thisObj.onddType8Blur = onddType8Blur

  const onddType9Blur = () => {
    filllabels()
  }
  thisObj.onddType9Blur = onddType9Blur

  const onddType0Blur = () => {
    filllabels()
  }
  thisObj.onddType0Blur = onddType0Blur

  const onddShippingLoacation0Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(0)
    }
  }
  thisObj.onddShippingLoacation0Change = onddShippingLoacation0Change

  const onddShippingLoacation1Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(1)
    }
  }
  thisObj.onddShippingLoacation1Change = onddShippingLoacation1Change

  const onddShippingLoacation2Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(2)
    }
  }
  thisObj.onddShippingLoacation2Change = onddShippingLoacation2Change

  const onddShippingLoacation3Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(3)
    }
  }
  thisObj.onddShippingLoacation3Change = onddShippingLoacation3Change

  const onddShippingLoacation4Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(4)
    }
  }
  thisObj.onddShippingLoacation4Change = onddShippingLoacation4Change

  const onddShippingLoacation5Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(5)
    }
  }
  thisObj.onddShippingLoacation5Change = onddShippingLoacation5Change

  const onddShippingLoacation6Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(6)
    }
  }
  thisObj.onddShippingLoacation6Change = onddShippingLoacation6Change

  const onddShippingLoacation7Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(7)
    }
  }
  thisObj.onddShippingLoacation7Change = onddShippingLoacation7Change

  const onddShippingLoacation8Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(8)
    }
  }
  thisObj.onddShippingLoacation8Change = onddShippingLoacation8Change

  const onddShippingLoacation9Change = async (e, ind) => {
    if (!isNaN(ind)) {
      await FillTransLoc(ind)
    } else {
      await FillTransLoc(9)
    }
  }
  thisObj.onddShippingLoacation9Change = onddShippingLoacation9Change




  const onddShippingLoacation1Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation1Blur = onddShippingLoacation1Blur

  const onddShippingLoacation2Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation2Blur = onddShippingLoacation2Blur

  const onddShippingLoacation3Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation3Blur = onddShippingLoacation3Blur

  const onddShippingLoacation4Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation4Blur = onddShippingLoacation4Blur

  const onddShippingLoacation5Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation5Blur = onddShippingLoacation5Blur

  const onddShippingLoacation6Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation6Blur = onddShippingLoacation6Blur

  const onddShippingLoacation7Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation7Blur = onddShippingLoacation7Blur

  const onddShippingLoacation8Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation8Blur = onddShippingLoacation8Blur

  const onddShippingLoacation9Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation9Blur = onddShippingLoacation9Blur

  const onddShippingLoacation0Blur = () => {
    filllabels()
  }
  thisObj.onddShippingLoacation0Blur = onddShippingLoacation0Blur

  function errorHandler(err, action) {
    showMessage(thisObj,
      err instanceof EvalError
        ? err.message
        : "Something went wrong for " + action + ". Please try again later.")
  }




  // #endregion

  // HTML Code
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_MultiStopProfile*/}

              {/* END_USER_CODE-USER_BEFORE_MultiStopProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxMultiStopProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxMultiStopProfile*/}

              <GroupBoxWidget
                conf={state.grpbxMultiStopProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtTripNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTripNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTripNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTripNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTripNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbreviation*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbreviation*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCarrierAbbreviation}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCarrierAbbreviation*/}

                  {/* END_USER_CODE-USER_AFTER_txtCarrierAbbreviation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVehicleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVehicleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVehicleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVehicleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVehicleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayFreightInd*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayFreightInd*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayFreightInd}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayFreightInd*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayFreightInd*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGrid*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrid*/}

                <GroupBoxWidget conf={state.grpbxGrid} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxLabels*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxLabels*/}

                  <GroupBoxWidget conf={state.grpbxLabels} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblLegNum*/}

                    {/* END_USER_CODE-USER_BEFORE_lblLegNum*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblLegNum}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblLegNum*/}

                    {/* END_USER_CODE-USER_AFTER_lblLegNum*/}
                    {/* START_USER_CODE-USER_BEFORE_lblTransferNum*/}

                    {/* END_USER_CODE-USER_BEFORE_lblTransferNum*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblTransferNum}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblTransferNum*/}

                    {/* END_USER_CODE-USER_AFTER_lblTransferNum*/}
                    {/* START_USER_CODE-USER_BEFORE_lblType*/}

                    {/* END_USER_CODE-USER_BEFORE_lblType*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblType}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblType*/}

                    {/* END_USER_CODE-USER_AFTER_lblType*/}
                    {/* START_USER_CODE-USER_BEFORE_lblShippingLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShippingLocation*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblShippingLocation}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShippingLocation*/}

                    {/* END_USER_CODE-USER_AFTER_lblShippingLocation*/}
                    {/* START_USER_CODE-USER_BEFORE_lblReceivingLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_lblReceivingLocation*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblReceivingLocation}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblReceivingLocation*/}

                    {/* END_USER_CODE-USER_AFTER_lblReceivingLocation*/}
                    {/* START_USER_CODE-USER_BEFORE_lblMileageOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_lblMileageOverride*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblMileageOverride}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblMileageOverride*/}

                    {/* END_USER_CODE-USER_AFTER_lblMileageOverride*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxLabels*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxLabels*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow0*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow0*/}

                  <GroupBoxWidget conf={state.grpbxRow0} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum0*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum0*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum0*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType0*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType0*/}

                    {/* END_USER_CODE-USER_AFTER_ddType0*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation0*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation0*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation0*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation0*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation0*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage0*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride0*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride0*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow0*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow0*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow1*/}

                  <GroupBoxWidget conf={state.grpbxRow1} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum1*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum1*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum1*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType1*/}

                    {/* END_USER_CODE-USER_AFTER_ddType1*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation1*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation1*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation1*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage1*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride1*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride1*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow2*/}

                  <GroupBoxWidget conf={state.grpbxRow2} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum2*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum2*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum2*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType2*/}

                    {/* END_USER_CODE-USER_AFTER_ddType2*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation2*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation2*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation2*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage2*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride2*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow3*/}

                  <GroupBoxWidget conf={state.grpbxRow3} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum3*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum3*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum3*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType3*/}

                    {/* END_USER_CODE-USER_AFTER_ddType3*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation3*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation3*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation3*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage3*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride3*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride3*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow3*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow4*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow4*/}

                  <GroupBoxWidget conf={state.grpbxRow4} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum4*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum4*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum4*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType4*/}

                    {/* END_USER_CODE-USER_AFTER_ddType4*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation4*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation4*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation4*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage4*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride4*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride4*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow4*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow4*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow5*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow5*/}

                  <GroupBoxWidget conf={state.grpbxRow5} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum5*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum5*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum5*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType5*/}

                    {/* END_USER_CODE-USER_AFTER_ddType5*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation5*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation5*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation5*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage5*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride5*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride5*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow5*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow5*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow6*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow6*/}

                  <GroupBoxWidget conf={state.grpbxRow6} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum6*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum6*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum6*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType6*/}

                    {/* END_USER_CODE-USER_AFTER_ddType6*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation6*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation6*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation6*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage6*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride6*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride6*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow6*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow6*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow7*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow7*/}

                  <GroupBoxWidget conf={state.grpbxRow7} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum7*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum7*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum7*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType7*/}

                    {/* END_USER_CODE-USER_AFTER_ddType7*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation7*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation7*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation7*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage7*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride7*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride7*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow7*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow7*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow8*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow8*/}

                  <GroupBoxWidget conf={state.grpbxRow8} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum8*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum8*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum8*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType8*/}

                    {/* END_USER_CODE-USER_AFTER_ddType8*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation8*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation8*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation8*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage8*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride8*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride8*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow8*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow8*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxRow9*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRow9*/}

                  <GroupBoxWidget conf={state.grpbxRow9} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtLegNum9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLegNum9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLegNum9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLegNum9*/}

                    {/* END_USER_CODE-USER_AFTER_txtLegNum9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTransferNum9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTransferNum9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTransferNum9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTransferNum9*/}

                    {/* END_USER_CODE-USER_AFTER_txtTransferNum9*/}
                    {/* START_USER_CODE-USER_BEFORE_ddType9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddType9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddType9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddType9*/}

                    {/* END_USER_CODE-USER_AFTER_ddType9*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShippingLoacation9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShippingLoacation9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShippingLoacation9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShippingLoacation9*/}

                    {/* END_USER_CODE-USER_AFTER_ddShippingLoacation9*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceivingLocation9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceivingLocation9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceivingLocation9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceivingLocation9*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceivingLocation9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMileage9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMileage9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMileage9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMileage9*/}

                    {/* END_USER_CODE-USER_AFTER_txtMileage9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverride9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverride9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverride9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverride9*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverride9*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRow9*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRow9*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrid*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrid*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrSearch*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrSearch*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrSearch}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearchForTransfers*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearchForTransfers*/}

                  <ButtonWidget
                    conf={state.btnSearchForTransfers}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearchForTransfers*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearchForTransfers*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrSearch*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrSearch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxMultiStopProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxMultiStopProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}

                <ButtonWidget
                  conf={state.btnGreightAccural}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>

                <ButtonWidget
                  conf={state.btnReverseFreightAccural}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_MultiStopProfile*/}

              {/* END_USER_CODE-USER_AFTER_MultiStopProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_MultiStopProfile);
