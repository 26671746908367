/* eslint-disable*/
import React from "react";
import WarehouseReceipts_PremiumDeductionsWRPurch from "./PremiumDeductionsWRPurch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionsWRPurch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_PremiumDeductionsWRPurch />);
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionsWRPurch Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionsWRPurch")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionsWRPurch", () => {
    // START_USER_CODE-USER_PremiumDeductionsWRPurch_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionsWRPurch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_PremiumDeductionsWRPurch />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:PremiumDeductionsWRPurch_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridPremDeductWRP_btncol1(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP_btncol1 = screen.getByTestId("gridPremDeductWRP");
    let gridPremDeductWRP_btncol1btn =
      gridPremDeductWRP_btncol1.nextElementSibling.firstElementChild;
    gridPremDeductWRP_btncol1 =
      gridPremDeductWRP_btncol1.parentElement.parentElement.parentElement;
    expect(gridPremDeductWRP_btncol1.tagName).toBe("DIV");
    expect(gridPremDeductWRP_btncol1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncol1", () => {
    // START_USER_CODE-USER_btncol1_TEST
    // END_USER_CODE-USER_btncol1_TEST
  });
  test("btnPurchase(Button Widget) Test Cases", async () => {
    const btnPurchase = screen.getByTestId("btnPurchase");
    expect(btnPurchase).toBeInTheDocument;
    expect(btnPurchase.textContent).toEqual(
      t("WarehouseReceipts:PremiumDeductionsWRPurch_btnPurchase")
    );
  });
  test("Custom Test Cases for btnPurchase", () => {
    // START_USER_CODE-USER_btnPurchase_TEST
    // END_USER_CODE-USER_btnPurchase_TEST
  });
  test("gridPremDeductWRP(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP = screen.getByTestId("gridPremDeductWRP");
    let gridPremDeductWRPbtn =
      gridPremDeductWRP.nextElementSibling.firstElementChild;
    gridPremDeductWRP =
      gridPremDeductWRP.parentElement.parentElement.parentElement;
    expect(gridPremDeductWRP.tagName).toBe("DIV");
    expect(gridPremDeductWRP.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremDeductWRP", () => {
    // START_USER_CODE-USER_gridPremDeductWRP_TEST
    // END_USER_CODE-USER_gridPremDeductWRP_TEST
  });
  test("grpbxPremDeductWRPurch(GroupBox Widget) Test Cases", async () => {
    const grpbxPremDeductWRPurch = screen.getByTestId("grpbxPremDeductWRPurch");
    expect(grpbxPremDeductWRPurch.tagName).toBe("BUTTON");
    expect(grpbxPremDeductWRPurch.type).toBe("button");
    expect(grpbxPremDeductWRPurch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremDeductWRPurch", () => {
    // START_USER_CODE-USER_grpbxPremDeductWRPurch_TEST
    // END_USER_CODE-USER_grpbxPremDeductWRPurch_TEST
  });
  test("lblPremDeduct(Label Widget) Test Cases", async () => {
    const lblPremDeduct = screen.getByTestId("lblPremDeduct");
    expect(lblPremDeduct.tagName).toBe("LABEL");
    expect(lblPremDeduct.classList).toContain("form-label");
    expect(lblPremDeduct.textContent).toEqual(
      t("WarehouseReceipts:PremiumDeductionsWRPurch_lblPremDeduct")
    );
  });
  test("Custom Test Cases for lblPremDeduct", () => {
    // START_USER_CODE-USER_lblPremDeduct_TEST
    // END_USER_CODE-USER_lblPremDeduct_TEST
  });
  test("gridPremDeductWRP_txtcol2(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP_txtcol2 = screen.getByTestId("gridPremDeductWRP");
    let gridPremDeductWRP_txtcol2btn =
      gridPremDeductWRP_txtcol2.nextElementSibling.firstElementChild;
    gridPremDeductWRP_txtcol2 =
      gridPremDeductWRP_txtcol2.parentElement.parentElement.parentElement;
    expect(gridPremDeductWRP_txtcol2.tagName).toBe("DIV");
    expect(gridPremDeductWRP_txtcol2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol2", () => {
    // START_USER_CODE-USER_txtcol2_TEST
    // END_USER_CODE-USER_txtcol2_TEST
  });
  test("gridPremDeductWRP_txtcol3(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP_txtcol3 = screen.getByTestId("gridPremDeductWRP");
    let gridPremDeductWRP_txtcol3btn =
      gridPremDeductWRP_txtcol3.nextElementSibling.firstElementChild;
    gridPremDeductWRP_txtcol3 =
      gridPremDeductWRP_txtcol3.parentElement.parentElement.parentElement;
    expect(gridPremDeductWRP_txtcol3.tagName).toBe("DIV");
    expect(gridPremDeductWRP_txtcol3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol3", () => {
    // START_USER_CODE-USER_txtcol3_TEST
    // END_USER_CODE-USER_txtcol3_TEST
  });
  test("gridPremDeductWRP_txtcolAmount(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP_txtcolAmount = screen.getByTestId(
      "gridPremDeductWRP"
    );
    let gridPremDeductWRP_txtcolAmountbtn =
      gridPremDeductWRP_txtcolAmount.nextElementSibling.firstElementChild;
    gridPremDeductWRP_txtcolAmount =
      gridPremDeductWRP_txtcolAmount.parentElement.parentElement.parentElement;
    expect(gridPremDeductWRP_txtcolAmount.tagName).toBe("DIV");
    expect(gridPremDeductWRP_txtcolAmount.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmount", () => {
    // START_USER_CODE-USER_txtcolAmount_TEST
    // END_USER_CODE-USER_txtcolAmount_TEST
  });
  test("gridPremDeductWRP_txtcolOrigin(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP_txtcolOrigin = screen.getByTestId(
      "gridPremDeductWRP"
    );
    let gridPremDeductWRP_txtcolOriginbtn =
      gridPremDeductWRP_txtcolOrigin.nextElementSibling.firstElementChild;
    gridPremDeductWRP_txtcolOrigin =
      gridPremDeductWRP_txtcolOrigin.parentElement.parentElement.parentElement;
    expect(gridPremDeductWRP_txtcolOrigin.tagName).toBe("DIV");
    expect(gridPremDeductWRP_txtcolOrigin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrigin", () => {
    // START_USER_CODE-USER_txtcolOrigin_TEST
    // END_USER_CODE-USER_txtcolOrigin_TEST
  });
  test("gridPremDeductWRP_txtcolPremDeduct(Grid Widget) Test Cases", async () => {
    let gridPremDeductWRP_txtcolPremDeduct = screen.getByTestId(
      "gridPremDeductWRP"
    );
    let gridPremDeductWRP_txtcolPremDeductbtn =
      gridPremDeductWRP_txtcolPremDeduct.nextElementSibling.firstElementChild;
    gridPremDeductWRP_txtcolPremDeduct =
      gridPremDeductWRP_txtcolPremDeduct.parentElement.parentElement
        .parentElement;
    expect(gridPremDeductWRP_txtcolPremDeduct.tagName).toBe("DIV");
    expect(gridPremDeductWRP_txtcolPremDeduct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:PremiumDeductionsWRPurch_gridPremDeductWRP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPremDeduct", () => {
    // START_USER_CODE-USER_txtcolPremDeduct_TEST
    // END_USER_CODE-USER_txtcolPremDeduct_TEST
  });
});
