/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvSettleApplyView from "./DelvSettleApplyView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvSettleApplyView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleApplyView />);
    });
  });
  afterEach(cleanup);
  test("is DelvSettleApplyView Loads Successfully", () => {
    expect(screen.getByText("DelvSettleApplyView")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvSettleApplyView", () => {
    // START_USER_CODE-USER_DelvSettleApplyView_Custom_Test_Case
    // END_USER_CODE-USER_DelvSettleApplyView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleApplyView />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridAgreementApplications(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplicationsbtn =
      gridAgreementApplications.nextElementSibling.firstElementChild;
    gridAgreementApplications =
      gridAgreementApplications.parentElement.parentElement.parentElement;
    expect(gridAgreementApplications.tagName).toBe("DIV");
    expect(gridAgreementApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAgreementApplications", () => {
    // START_USER_CODE-USER_gridAgreementApplications_TEST
    // END_USER_CODE-USER_gridAgreementApplications_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDelvSettleApplyView(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSettleApplyView = screen.getByTestId(
      "grpbxDelvSettleApplyView"
    );
    expect(grpbxDelvSettleApplyView.tagName).toBe("BUTTON");
    expect(grpbxDelvSettleApplyView.type).toBe("button");
    expect(grpbxDelvSettleApplyView.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSettleApplyView", () => {
    // START_USER_CODE-USER_grpbxDelvSettleApplyView_TEST
    // END_USER_CODE-USER_grpbxDelvSettleApplyView_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblAgreementApplications(Label Widget) Test Cases", async () => {
    const lblAgreementApplications = screen.getByTestId(
      "lblAgreementApplications"
    );
    expect(lblAgreementApplications.tagName).toBe("LABEL");
    expect(lblAgreementApplications.classList).toContain("form-label");
    expect(lblAgreementApplications.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_lblAgreementApplications")
    );
  });
  test("Custom Test Cases for lblAgreementApplications", () => {
    // START_USER_CODE-USER_lblAgreementApplications_TEST
    // END_USER_CODE-USER_lblAgreementApplications_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtAgreementLocations(Textbox Widget) Test Cases", async () => {
    const txtAgreementLocations = screen.getByTestId("txtAgreementLocations");
    expect(txtAgreementLocations.tagName).toBe("INPUT");
    expect(txtAgreementLocations.type).toBe("text");
    expect(txtAgreementLocations.classList).toContain("textboxWidgetClass");
    expect(txtAgreementLocations.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtAgreementLocations")
    );
    await act(async () => {
      userEvent.type(txtAgreementLocations, "123");
    });
    expect(txtAgreementLocations.getAttribute("value")).toBe("");
    expect(txtAgreementLocations.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAgreementLocations", () => {
    // START_USER_CODE-USER_txtAgreementLocations_TEST
    // END_USER_CODE-USER_txtAgreementLocations_TEST
  });
  test("gridAgreementApplications_txtcol100PctAdjDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcol100PctAdjDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcol100PctAdjDollarbtn =
      gridAgreementApplications_txtcol100PctAdjDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcol100PctAdjDollar =
      gridAgreementApplications_txtcol100PctAdjDollar.parentElement
        .parentElement.parentElement;
    expect(gridAgreementApplications_txtcol100PctAdjDollar.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcol100PctAdjDollar.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100PctAdjDollar", () => {
    // START_USER_CODE-USER_txtcol100PctAdjDollar_TEST
    // END_USER_CODE-USER_txtcol100PctAdjDollar_TEST
  });
  test("gridAgreementApplications_txtcol100PctAppliedDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcol100PctAppliedDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcol100PctAppliedDollarbtn =
      gridAgreementApplications_txtcol100PctAppliedDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcol100PctAppliedDollar =
      gridAgreementApplications_txtcol100PctAppliedDollar.parentElement
        .parentElement.parentElement;
    expect(gridAgreementApplications_txtcol100PctAppliedDollar.tagName).toBe(
      "DIV"
    );
    expect(
      gridAgreementApplications_txtcol100PctAppliedDollar.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100PctAppliedDollar", () => {
    // START_USER_CODE-USER_txtcol100PctAppliedDollar_TEST
    // END_USER_CODE-USER_txtcol100PctAppliedDollar_TEST
  });
  test("gridAgreementApplications_txtcol100PctDollarToApply(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcol100PctDollarToApply = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcol100PctDollarToApplybtn =
      gridAgreementApplications_txtcol100PctDollarToApply.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcol100PctDollarToApply =
      gridAgreementApplications_txtcol100PctDollarToApply.parentElement
        .parentElement.parentElement;
    expect(gridAgreementApplications_txtcol100PctDollarToApply.tagName).toBe(
      "DIV"
    );
    expect(
      gridAgreementApplications_txtcol100PctDollarToApply.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100PctDollarToApply", () => {
    // START_USER_CODE-USER_txtcol100PctDollarToApply_TEST
    // END_USER_CODE-USER_txtcol100PctDollarToApply_TEST
  });
  test("gridAgreementApplications_txtcol100PctOpenDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcol100PctOpenDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcol100PctOpenDollarbtn =
      gridAgreementApplications_txtcol100PctOpenDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcol100PctOpenDollar =
      gridAgreementApplications_txtcol100PctOpenDollar.parentElement
        .parentElement.parentElement;
    expect(gridAgreementApplications_txtcol100PctOpenDollar.tagName).toBe(
      "DIV"
    );
    expect(
      gridAgreementApplications_txtcol100PctOpenDollar.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100PctOpenDollar", () => {
    // START_USER_CODE-USER_txtcol100PctOpenDollar_TEST
    // END_USER_CODE-USER_txtcol100PctOpenDollar_TEST
  });
  test("gridAgreementApplications_txtcolAgreeDt(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreeDt = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreeDtbtn =
      gridAgreementApplications_txtcolAgreeDt.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreeDt =
      gridAgreementApplications_txtcolAgreeDt.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreeDt.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreeDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeDt", () => {
    // START_USER_CODE-USER_txtcolAgreeDt_TEST
    // END_USER_CODE-USER_txtcolAgreeDt_TEST
  });
  test("gridAgreementApplications_txtcolAgreeNum(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreeNum = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreeNumbtn =
      gridAgreementApplications_txtcolAgreeNum.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreeNum =
      gridAgreementApplications_txtcolAgreeNum.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreeNum.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreeNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeNum", () => {
    // START_USER_CODE-USER_txtcolAgreeNum_TEST
    // END_USER_CODE-USER_txtcolAgreeNum_TEST
  });
  test("gridAgreementApplications_txtcolInOut(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolInOut = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolInOutbtn =
      gridAgreementApplications_txtcolInOut.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolInOut =
      gridAgreementApplications_txtcolInOut.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolInOut.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolInOut.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInOut", () => {
    // START_USER_CODE-USER_txtcolInOut_TEST
    // END_USER_CODE-USER_txtcolInOut_TEST
  });
  test("gridAgreementApplications_txtcolPaidDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPaidDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPaidDollarbtn =
      gridAgreementApplications_txtcolPaidDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPaidDollar =
      gridAgreementApplications_txtcolPaidDollar.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPaidDollar.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPaidDollar.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPaidDollar", () => {
    // START_USER_CODE-USER_txtcolPaidDollar_TEST
    // END_USER_CODE-USER_txtcolPaidDollar_TEST
  });
  test("gridAgreementApplications_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPeanutType = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPeanutTypebtn =
      gridAgreementApplications_txtcolPeanutType.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPeanutType =
      gridAgreementApplications_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPeanutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridAgreementApplications_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPeanutVariety = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPeanutVarietybtn =
      gridAgreementApplications_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPeanutVariety =
      gridAgreementApplications_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridAgreementApplications_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPrice = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPricebtn =
      gridAgreementApplications_txtcolPrice.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPrice =
      gridAgreementApplications_txtcolPrice.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPrice.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridAgreementApplications_txtcolPricingMethod(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPricingMethod = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPricingMethodbtn =
      gridAgreementApplications_txtcolPricingMethod.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPricingMethod =
      gridAgreementApplications_txtcolPricingMethod.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPricingMethod.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPricingMethod.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPricingMethod", () => {
    // START_USER_CODE-USER_txtcolPricingMethod_TEST
    // END_USER_CODE-USER_txtcolPricingMethod_TEST
  });
  test("gridAgreementApplications_txtcolReceiptValue(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolReceiptValue = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolReceiptValuebtn =
      gridAgreementApplications_txtcolReceiptValue.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolReceiptValue =
      gridAgreementApplications_txtcolReceiptValue.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolReceiptValue.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolReceiptValue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptValue", () => {
    // START_USER_CODE-USER_txtcolReceiptValue_TEST
    // END_USER_CODE-USER_txtcolReceiptValue_TEST
  });
  test("gridAgreementApplications_txtcolSeed(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolSeed = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolSeedbtn =
      gridAgreementApplications_txtcolSeed.nextElementSibling.firstElementChild;
    gridAgreementApplications_txtcolSeed =
      gridAgreementApplications_txtcolSeed.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolSeed.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeed", () => {
    // START_USER_CODE-USER_txtcolSeed_TEST
    // END_USER_CODE-USER_txtcolSeed_TEST
  });
  test("gridAgreementApplications_txtcolType(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolType = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolTypebtn =
      gridAgreementApplications_txtcolType.nextElementSibling.firstElementChild;
    gridAgreementApplications_txtcolType =
      gridAgreementApplications_txtcolType.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolType.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType", () => {
    // START_USER_CODE-USER_txtcolType_TEST
    // END_USER_CODE-USER_txtcolType_TEST
  });
  test("txtDelvInspNum(Textbox Widget) Test Cases", async () => {
    const txtDelvInspNum = screen.getByTestId("txtDelvInspNum");
    expect(txtDelvInspNum.tagName).toBe("INPUT");
    expect(txtDelvInspNum.type).toBe("text");
    expect(txtDelvInspNum.classList).toContain("textboxWidgetClass");
    expect(txtDelvInspNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtDelvInspNum")
    );
    await act(async () => {
      userEvent.type(txtDelvInspNum, "123");
    });
    expect(txtDelvInspNum.getAttribute("value")).toBe("");
    expect(txtDelvInspNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDelvInspNum", () => {
    // START_USER_CODE-USER_txtDelvInspNum_TEST
    // END_USER_CODE-USER_txtDelvInspNum_TEST
  });
  test("txtInOut(Textbox Widget) Test Cases", async () => {
    const txtInOut = screen.getByTestId("txtInOut");
    expect(txtInOut.tagName).toBe("INPUT");
    expect(txtInOut.type).toBe("text");
    expect(txtInOut.classList).toContain("textboxWidgetClass");
    expect(txtInOut.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtInOut")
    );
    await act(async () => {
      userEvent.type(txtInOut, "123");
    });
    expect(txtInOut.getAttribute("value")).toBe("");
    expect(txtInOut.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtInOut", () => {
    // START_USER_CODE-USER_txtInOut_TEST
    // END_USER_CODE-USER_txtInOut_TEST
  });
  test("txtNetLbs(Textbox Widget) Test Cases", async () => {
    const txtNetLbs = screen.getByTestId("txtNetLbs");
    expect(txtNetLbs.tagName).toBe("INPUT");
    expect(txtNetLbs.type).toBe("text");
    expect(txtNetLbs.classList).toContain("textboxWidgetClass");
    expect(txtNetLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtNetLbs")
    );
    await act(async () => {
      userEvent.type(txtNetLbs, "123");
    });
    expect(txtNetLbs.getAttribute("value")).toBe("");
    expect(txtNetLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetLbs", () => {
    // START_USER_CODE-USER_txtNetLbs_TEST
    // END_USER_CODE-USER_txtNetLbs_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPeanutVariety(Textbox Widget) Test Cases", async () => {
    const txtPeanutVariety = screen.getByTestId("txtPeanutVariety");
    expect(txtPeanutVariety.tagName).toBe("INPUT");
    expect(txtPeanutVariety.type).toBe("text");
    expect(txtPeanutVariety.classList).toContain("textboxWidgetClass");
    expect(txtPeanutVariety.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtPeanutVariety")
    );
    await act(async () => {
      userEvent.type(txtPeanutVariety, "123");
    });
    expect(txtPeanutVariety.getAttribute("value")).toBe("");
    expect(txtPeanutVariety.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutVariety", () => {
    // START_USER_CODE-USER_txtPeanutVariety_TEST
    // END_USER_CODE-USER_txtPeanutVariety_TEST
  });
  test("txtSeed(Textbox Widget) Test Cases", async () => {
    const txtSeed = screen.getByTestId("txtSeed");
    expect(txtSeed.tagName).toBe("INPUT");
    expect(txtSeed.type).toBe("text");
    expect(txtSeed.classList).toContain("textboxWidgetClass");
    expect(txtSeed.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtSeed")
    );
    await act(async () => {
      userEvent.type(txtSeed, "123");
    });
    expect(txtSeed.getAttribute("value")).toBe("");
    expect(txtSeed.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeed", () => {
    // START_USER_CODE-USER_txtSeed_TEST
    // END_USER_CODE-USER_txtSeed_TEST
  });
  test("txtSettleNum(Textbox Widget) Test Cases", async () => {
    const txtSettleNum = screen.getByTestId("txtSettleNum");
    expect(txtSettleNum.tagName).toBe("INPUT");
    expect(txtSettleNum.type).toBe("text");
    expect(txtSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtSettleNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtSettleNum")
    );
    await act(async () => {
      userEvent.type(txtSettleNum, "123");
    });
    expect(txtSettleNum.getAttribute("value")).toBe("");
    expect(txtSettleNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSettleNum", () => {
    // START_USER_CODE-USER_txtSettleNum_TEST
    // END_USER_CODE-USER_txtSettleNum_TEST
  });
  test("txtValue(Textbox Widget) Test Cases", async () => {
    const txtValue = screen.getByTestId("txtValue");
    expect(txtValue.tagName).toBe("INPUT");
    expect(txtValue.type).toBe("text");
    expect(txtValue.classList).toContain("textboxWidgetClass");
    expect(txtValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApplyView_txtValue")
    );
    await act(async () => {
      userEvent.type(txtValue, "123");
    });
    expect(txtValue.getAttribute("value")).toBe("");
    expect(txtValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue", () => {
    // START_USER_CODE-USER_txtValue_TEST
    // END_USER_CODE-USER_txtValue_TEST
  });
});
