/* eslint-disable*/
import React from "react";
import StockTransfer_SelectFromList from "./SelectFromList";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SelectFromList Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_SelectFromList />);
    });
  });
  afterEach(cleanup);
  test("is SelectFromList Loads Successfully", () => {
    expect(screen.getByText("SelectFromList")).toBeInTheDocument;
  });
  test("Custom Test Cases for SelectFromList", () => {
    // START_USER_CODE-USER_SelectFromList_Custom_Test_Case
    // END_USER_CODE-USER_SelectFromList_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_SelectFromList />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("StockTransfer:SelectFromList_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("StockTransfer:SelectFromList_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridList(Grid Widget) Test Cases", async () => {
    let gridList = screen.getByTestId("gridList");
    let gridListbtn = gridList.nextElementSibling.firstElementChild;
    gridList = gridList.parentElement.parentElement.parentElement;
    expect(gridList.tagName).toBe("DIV");
    expect(gridList.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridList", () => {
    // START_USER_CODE-USER_gridList_TEST
    // END_USER_CODE-USER_gridList_TEST
  });
  test("grpbxSelectFromList(GroupBox Widget) Test Cases", async () => {
    const grpbxSelectFromList = screen.getByTestId("grpbxSelectFromList");
    expect(grpbxSelectFromList.tagName).toBe("BUTTON");
    expect(grpbxSelectFromList.type).toBe("button");
    expect(grpbxSelectFromList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSelectFromList", () => {
    // START_USER_CODE-USER_grpbxSelectFromList_TEST
    // END_USER_CODE-USER_grpbxSelectFromList_TEST
  });
  test("gridList_txtcolBinName(Grid Widget) Test Cases", async () => {
    let gridList_txtcolBinName = screen.getByTestId("gridList");
    let gridList_txtcolBinNamebtn =
      gridList_txtcolBinName.nextElementSibling.firstElementChild;
    gridList_txtcolBinName =
      gridList_txtcolBinName.parentElement.parentElement.parentElement;
    expect(gridList_txtcolBinName.tagName).toBe("DIV");
    expect(gridList_txtcolBinName.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBinName", () => {
    // START_USER_CODE-USER_txtcolBinName_TEST
    // END_USER_CODE-USER_txtcolBinName_TEST
  });
  test("gridList_txtcolBinNum(Grid Widget) Test Cases", async () => {
    let gridList_txtcolBinNum = screen.getByTestId("gridList");
    let gridList_txtcolBinNumbtn =
      gridList_txtcolBinNum.nextElementSibling.firstElementChild;
    gridList_txtcolBinNum =
      gridList_txtcolBinNum.parentElement.parentElement.parentElement;
    expect(gridList_txtcolBinNum.tagName).toBe("DIV");
    expect(gridList_txtcolBinNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBinNum", () => {
    // START_USER_CODE-USER_txtcolBinNum_TEST
    // END_USER_CODE-USER_txtcolBinNum_TEST
  });
  test("gridList_txtcolCapTon(Grid Widget) Test Cases", async () => {
    let gridList_txtcolCapTon = screen.getByTestId("gridList");
    let gridList_txtcolCapTonbtn =
      gridList_txtcolCapTon.nextElementSibling.firstElementChild;
    gridList_txtcolCapTon =
      gridList_txtcolCapTon.parentElement.parentElement.parentElement;
    expect(gridList_txtcolCapTon.tagName).toBe("DIV");
    expect(gridList_txtcolCapTon.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCapTon", () => {
    // START_USER_CODE-USER_txtcolCapTon_TEST
    // END_USER_CODE-USER_txtcolCapTon_TEST
  });
  test("gridList_txtcolEdible(Grid Widget) Test Cases", async () => {
    let gridList_txtcolEdible = screen.getByTestId("gridList");
    let gridList_txtcolEdiblebtn =
      gridList_txtcolEdible.nextElementSibling.firstElementChild;
    gridList_txtcolEdible =
      gridList_txtcolEdible.parentElement.parentElement.parentElement;
    expect(gridList_txtcolEdible.tagName).toBe("DIV");
    expect(gridList_txtcolEdible.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEdible", () => {
    // START_USER_CODE-USER_txtcolEdible_TEST
    // END_USER_CODE-USER_txtcolEdible_TEST
  });
  test("gridList_txtcolGeneration(Grid Widget) Test Cases", async () => {
    let gridList_txtcolGeneration = screen.getByTestId("gridList");
    let gridList_txtcolGenerationbtn =
      gridList_txtcolGeneration.nextElementSibling.firstElementChild;
    gridList_txtcolGeneration =
      gridList_txtcolGeneration.parentElement.parentElement.parentElement;
    expect(gridList_txtcolGeneration.tagName).toBe("DIV");
    expect(gridList_txtcolGeneration.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGeneration", () => {
    // START_USER_CODE-USER_txtcolGeneration_TEST
    // END_USER_CODE-USER_txtcolGeneration_TEST
  });
  test("gridList_txtcolInvTon(Grid Widget) Test Cases", async () => {
    let gridList_txtcolInvTon = screen.getByTestId("gridList");
    let gridList_txtcolInvTonbtn =
      gridList_txtcolInvTon.nextElementSibling.firstElementChild;
    gridList_txtcolInvTon =
      gridList_txtcolInvTon.parentElement.parentElement.parentElement;
    expect(gridList_txtcolInvTon.tagName).toBe("DIV");
    expect(gridList_txtcolInvTon.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInvTon", () => {
    // START_USER_CODE-USER_txtcolInvTon_TEST
    // END_USER_CODE-USER_txtcolInvTon_TEST
  });
  test("gridList_txtcolOleic(Grid Widget) Test Cases", async () => {
    let gridList_txtcolOleic = screen.getByTestId("gridList");
    let gridList_txtcolOleicbtn =
      gridList_txtcolOleic.nextElementSibling.firstElementChild;
    gridList_txtcolOleic =
      gridList_txtcolOleic.parentElement.parentElement.parentElement;
    expect(gridList_txtcolOleic.tagName).toBe("DIV");
    expect(gridList_txtcolOleic.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOleic", () => {
    // START_USER_CODE-USER_txtcolOleic_TEST
    // END_USER_CODE-USER_txtcolOleic_TEST
  });
  test("gridList_txtcolOrganic(Grid Widget) Test Cases", async () => {
    let gridList_txtcolOrganic = screen.getByTestId("gridList");
    let gridList_txtcolOrganicbtn =
      gridList_txtcolOrganic.nextElementSibling.firstElementChild;
    gridList_txtcolOrganic =
      gridList_txtcolOrganic.parentElement.parentElement.parentElement;
    expect(gridList_txtcolOrganic.tagName).toBe("DIV");
    expect(gridList_txtcolOrganic.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrganic", () => {
    // START_USER_CODE-USER_txtcolOrganic_TEST
    // END_USER_CODE-USER_txtcolOrganic_TEST
  });
  test("gridList_txtcolSegNum(Grid Widget) Test Cases", async () => {
    let gridList_txtcolSegNum = screen.getByTestId("gridList");
    let gridList_txtcolSegNumbtn =
      gridList_txtcolSegNum.nextElementSibling.firstElementChild;
    gridList_txtcolSegNum =
      gridList_txtcolSegNum.parentElement.parentElement.parentElement;
    expect(gridList_txtcolSegNum.tagName).toBe("DIV");
    expect(gridList_txtcolSegNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSegNum", () => {
    // START_USER_CODE-USER_txtcolSegNum_TEST
    // END_USER_CODE-USER_txtcolSegNum_TEST
  });
  test("gridList_txtcolTypeNum(Grid Widget) Test Cases", async () => {
    let gridList_txtcolTypeNum = screen.getByTestId("gridList");
    let gridList_txtcolTypeNumbtn =
      gridList_txtcolTypeNum.nextElementSibling.firstElementChild;
    gridList_txtcolTypeNum =
      gridList_txtcolTypeNum.parentElement.parentElement.parentElement;
    expect(gridList_txtcolTypeNum.tagName).toBe("DIV");
    expect(gridList_txtcolTypeNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTypeNum", () => {
    // START_USER_CODE-USER_txtcolTypeNum_TEST
    // END_USER_CODE-USER_txtcolTypeNum_TEST
  });
  test("gridList_txtcolVariety(Grid Widget) Test Cases", async () => {
    let gridList_txtcolVariety = screen.getByTestId("gridList");
    let gridList_txtcolVarietybtn =
      gridList_txtcolVariety.nextElementSibling.firstElementChild;
    gridList_txtcolVariety =
      gridList_txtcolVariety.parentElement.parentElement.parentElement;
    expect(gridList_txtcolVariety.tagName).toBe("DIV");
    expect(gridList_txtcolVariety.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVariety", () => {
    // START_USER_CODE-USER_txtcolVariety_TEST
    // END_USER_CODE-USER_txtcolVariety_TEST
  });
  test("gridList_txtcolWhseNum(Grid Widget) Test Cases", async () => {
    let gridList_txtcolWhseNum = screen.getByTestId("gridList");
    let gridList_txtcolWhseNumbtn =
      gridList_txtcolWhseNum.nextElementSibling.firstElementChild;
    gridList_txtcolWhseNum =
      gridList_txtcolWhseNum.parentElement.parentElement.parentElement;
    expect(gridList_txtcolWhseNum.tagName).toBe("DIV");
    expect(gridList_txtcolWhseNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("StockTransfer:SelectFromList_gridList")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhseNum", () => {
    // START_USER_CODE-USER_txtcolWhseNum_TEST
    // END_USER_CODE-USER_txtcolWhseNum_TEST
  });
});
