/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  getValue,
  getData,
  setData,
  disable,
  enable,
  goTo,
} from "../../shared/WindowImports";

import "./CompanySetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_CompanySetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CompanySetup",
    windowName: "CompanySetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.CompanySetup",
    // START_USER_CODE-USER_CompanySetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Company Setup",
      scrCode: "PN0050A",
    },
    // END_USER_CODE-USER_CompanySetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxCompanySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxCompanySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxCompanySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    lblCompanyList: {
      name: "lblCompanyList",
      type: "LabelWidget",
      parent: "grpbxCompanySetup",
      Label: "Company List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompanyList_PROPERTIES

      // END_USER_CODE-USER_lblCompanyList_PROPERTIES
    },
    lstCompanyList: {
      name: "lstCompanyList",
      type: "ListBoxFieldWidget",
      parent: "grpbxCompanySetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstCompanyList_PROPERTIES

      // END_USER_CODE-USER_lstCompanyList_PROPERTIES
    },
    grpbxCompanySetup: {
      name: "grpbxCompanySetup",
      type: "GroupBoxWidget",
      parent: "CompanySetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCompanySetup_PROPERTIES

      // END_USER_CODE-USER_grpbxCompanySetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "CompanySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#CompanyProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#CompanyProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAdd: {
      DEFAULT: ["SystemMaintenanceSpecialFunctions#CompanyProfile#DEFAULT#true#Click"],
    },
    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#CompanyProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    try {
      if (getData(thisObj, "CompanyProfile") == true) {
        bFillCompanyList()
        setData(thisObj, "CompanyProfile", false)
      }
      
    } catch (error) {
      showMessage(thisObj, "error on functions after Company Profile closing")
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    try{
      if(getData(thisObj, 'CompanyProfile') == true){
        bFillCompanyList()
        setData(thisObj, 'CompanyProfile', false)
      }
    } catch (error) {
      showMessage(thisObj, "error on functions after Company Profile closing")
    }
  }, [getData(thisObj, 'CompanyProfile')]);

  // START_USER_CODE-USER_METHODS
  // Implement Form Load functionality
  let FormLoad = async () => {
    setData(thisObj, 'mbSuccessfulLoad', true)
    EnableDisableControls();
    bFillCompanyList()
    if (!await bFillCompanyList()) {
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
  }
  // Implement Enable Disable functionality
  const EnableDisableControls = async () => {
    disable(thisObj, 'btnAdd');
    disable(thisObj, 'btnEdit');
    disable(thisObj, 'btnDelete');
    let responseD = await ContractManagementService.RetrieveAccessPermissionDetails('PN0050', null, 'D', null)
    if (responseD[0].permission == 'Y') {
      enable(thisObj, 'btnAdd');
      enable(thisObj, 'btnEdit');
      enable(thisObj, 'btnDelete');
    }

    let responseU = await ContractManagementService.RetrieveAccessPermissionDetails('PN0050', null, 'U', null)
    if (responseU[0].permission == 'Y') {
      enable(thisObj, 'btnAdd');
      enable(thisObj, 'btnEdit');
    }
    let responseI = await ContractManagementService.RetrieveAccessPermissionDetails('PN0050', null, 'I', null)
    if (responseI[0].permission == 'Y') {
      enable(thisObj, 'btnEdit');
    }
  }

  // Impliment functionality to company List
  const bFillCompanyList = async () => {
    setLoading(true)
    let js = [];
    let CompanyDetails = await ContractManagementService.RetrieveCompanyDetails();
    let data = CompanyDetails;
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].compId, description: data[i].compName.trim() }
        js.push(obj)
      }
      
     ;
      setValue(thisObj, "lstCompanyList", [js[0].key])
      setData(thisObj,"companyname",js)
      thisObj.setState(current => {
        return {
          ...current,
          lstCompanyList: {
            ...state["lstCompanyList"],
            valueList: js
          }
        }
      })
     
      setLoading(false)
    }
    else {
      setLoading(false);
      return false;
    }
    setLoading(false)
  };
  // Add button functionality implimentation 
  const onbtnAddClick = () => {
    try {
      let CompanyProfile = {}
      CompanyProfile.cmdOk_Caption = "Add"
      CompanyProfile.txtID = true;
      setData(thisObj, 'CompanyProfile', CompanyProfile)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#CompanyProfile#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      } else {
        showMessage(
          thisObj,
          "Error occurred during State Add Button Click"
        );
        setLoading(false)
      }
      setLoading(false)
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick
  //implement functionality for delete button 
  const onbtnDeleteClick = async () => {
    try {
      let comp_id = getValue(thisObj, "lstCompanyList")
      
      let compName = getData(thisObj,"companyname");
      let findData = compName.find(x => x.key == comp_id[0])
      let obj = {}
      if (comp_id == "") {
        alert("Please select at least one Company");
      }
      else {
        const confirmBox = window.confirm(
          "Are you sure you wish to delete this company '" + findData.description + "'"
        )
        if (confirmBox == true) {
          setLoading(true);
           SystemMaintenanceSpecialFunctionsService.RemoveCompanyControl(comp_id)
            .then(response => {
              if (response.status == 200) {
                 bFillCompanyList();
                if (!bFillCompanyList()) {
                   EnableDisableControls();
                }
                setLoading(false);
                return false;
              }
              else {
                setLoading(false);
                return false;
              }
            });
          // let response = await SystemMaintenanceSpecialFunctionsService.RemoveCompanyControl(comp_id);
          // if (response.status == 200) {
          //   showMessage(thisObj, response.message, true);
          //   await bFillCompanyList();
          //   if(!await bFillCompanyList()){
          //     await EnableDisableControls();
          //   }
          //   setLoading(false);
          //   return false;
          // }
          // else {
          //   setLoading(false);
          //   return false;
          // }
        }
        else {
          setLoading(false);
          return false;
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on Delete button")
        setLoading(false)
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  //Implement Edit buttom functionality
  const onbtnEditClick = async () => {
    let CompId = thisObj.values['lstCompanyList'];
    let CompanyDetails = await ContractManagementService.RetrieveCompanyDetails(CompId);
    let data = CompanyDetails;
    try {
      let CompanyProfile = {}
      CompanyProfile.cmdOk_Caption = 'Update';
      CompanyProfile.comp_id = data[0].compId
      if (data[0].admInterfaceInd == 'Y') {
        //CompanyProfile.chkboxAdmInterface = setData(thisObj, 'chkboxAdmInterface', true);
        CompanyProfile.chkboxAdmInterface = true;
        CompanyProfile.ForeColor = 'HC0'
      }
      else {
        //CompanyProfile.chkboxAdmInterface = setData(thisObj, 'chkboxAdmInterface', false);
        CompanyProfile.chkboxAdmInterface = false;
        CompanyProfile.ForeColor = 'H0'
      }
      CompanyProfile.adm_comp_id = data[0].admCompId
      CompanyProfile.comp_name = data[0].compName.trim()
      CompanyProfile.comp_address = data[0].compAddress.trim()
      CompanyProfile.comp_city = data[0].compCity.trim()
      CompanyProfile.comp_state = data[0].compState.trim()
      CompanyProfile.comp_zip = data[0].compZip
      CompanyProfile.comp_phone = data[0].compPhone
      CompanyProfile.ewr_holder_id = data[0].ewrHolderId
      CompanyProfile.comp_vendor = data[0].compVendor.trim()
      CompanyProfile.sort_order = data[0].sortOrder
      CompanyProfile.last_wire_file_num = data[0].lastWireFileNum
      CompanyProfile.wire_org_name = data[0].wireOrgName.trim()
      CompanyProfile.wire_org_address_1 = data[0].wireOrgAddress1.trim()
      CompanyProfile.wire_org_address_2 = data[0].wireOrgAddress2.trim()
      CompanyProfile.wire_org_city = data[0].wireOrgCity.trim()
      CompanyProfile.wire_org_state = data[0].wireOrgState.trim()
      CompanyProfile.wire_org_zip = data[0].wireOrgZip
      CompanyProfile.wire_org_bank_name = data[0].wireOrgBankName.trim()
      CompanyProfile.wire_org_bank_address = data[0].wireOrgBankAddress.trim()
      CompanyProfile.wire_org_bank_city = data[0].wireOrgBankCity.trim()
      CompanyProfile.wire_org_bank_state = data[0].wireOrgBankState.trim()
      CompanyProfile.wire_org_bank_zip = data[0].wireOrgBankZip
      CompanyProfile.wire_org_bank_aba = data[0].wireOrgBankAba
      CompanyProfile.wire_org_bank_acct = data[0].wireOrgBankAcct
      CompanyProfile.add_user = data[0].addUser.trim()
      CompanyProfile.chg_user = data[0].chgUser.trim()
      CompanyProfile.add_date_time = data[0].addDateTime;
      CompanyProfile.add_date_time = data[0].chgDateTime;
      CompanyProfile.default_yr = data[0].default_yr
      CompanyProfile.active_yr = data[0].active_yr
      let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN0050', null, 'U', null)
      if (response[0].permission !== 'Y') {
        CompanyProfile.allcontrolsenabled=false;
        CompanyProfile.chkboxAdmInterface_enabled = false;
        CompanyProfile.txtADMComapnyID_enabled = false;
        CompanyProfile.txtComapnyName_enabled = false;
        CompanyProfile.txtCity_enabled = false;
        CompanyProfile.ddState_enabled = false;
        CompanyProfile.txtlZip_enabled = false;
        CompanyProfile.txtPhone_enabled = false;
        CompanyProfile.txtEWRID_enabled = false;
        CompanyProfile.txtVendor_enabled = false;
        CompanyProfile.txtSortOrder_enabled = false;
        CompanyProfile.txtLastFile_enabled = false;
        CompanyProfile.txtPayerName_enabled = false;
        CompanyProfile.txtAddress1_enabled = false;
        CompanyProfile.txtCity1_enabled = false;
        CompanyProfile.ddState1_enabled = false;
        CompanyProfile.txtZip1_enabled = false;
        CompanyProfile.txtPayerBank_enabled = false;
        CompanyProfile.txtAddress2_enabled = false;
        CompanyProfile.txtCity2_enabled = false;
        CompanyProfile.ddState2_enabled = false;
        CompanyProfile.txtZip2_enabled = false;
        CompanyProfile.txtBankABANum_enabled = false;
        CompanyProfile.txtBankAccountNum_enabled = false;
        CompanyProfile.cmdOk_Caption_enabled = false;
      }
      setData(thisObj, 'CompanyProfile', CompanyProfile)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#CompanyProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Edit button"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnEditClick = onbtnEditClick
  // Functionality for Close button
  const onbtnCloseClick = () => {
    try {
      goTo(thisObj, "ContractManagement#MainMenu#DEFAULT#false#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
    }
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  //Implement company click functionality
  const onCompanyListChange = async () => {
    await EnableDisableControls();
  }
  thisObj.onlstCompanyListChange = onCompanyListChange;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CompanySetup*/}

              {/* END_USER_CODE-USER_BEFORE_CompanySetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCompanySetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCompanySetup*/}

              <GroupBoxWidget
                conf={state.grpbxCompanySetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblCompanyList*/}

                {/* END_USER_CODE-USER_BEFORE_lblCompanyList*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCompanyList}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCompanyList*/}

                {/* END_USER_CODE-USER_AFTER_lblCompanyList*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstCompanyList*/}

                {/* END_USER_CODE-USER_BEFORE_lstCompanyList*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstCompanyList}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstCompanyList*/}

                {/* END_USER_CODE-USER_AFTER_lstCompanyList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCompanySetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCompanySetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_CompanySetup*/}

              {/* END_USER_CODE-USER_AFTER_CompanySetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_CompanySetup);
