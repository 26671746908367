/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_WarehouseBinSetup from "./WarehouseBinSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseBinSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WarehouseBinSetup />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseBinSetup Loads Successfully", () => {
    expect(screen.getByText("WarehouseBinSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseBinSetup", () => {
    // START_USER_CODE-USER_WarehouseBinSetup_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseBinSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WarehouseBinSetup />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCopy(Button Widget) Test Cases", async () => {
    const btnCopy = screen.getByTestId("btnCopy");
    expect(btnCopy).toBeInTheDocument;
    expect(btnCopy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnCopy")
    );
  });
  test("Custom Test Cases for btnCopy", () => {
    // START_USER_CODE-USER_btnCopy_TEST
    // END_USER_CODE-USER_btnCopy_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinSetup_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridCol(Grid Widget) Test Cases", async () => {
    let gridCol = screen.getByTestId("gridCol");
    let gridColbtn = gridCol.nextElementSibling.firstElementChild;
    gridCol = gridCol.parentElement.parentElement.parentElement;
    expect(gridCol.tagName).toBe("DIV");
    expect(gridCol.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCol", () => {
    // START_USER_CODE-USER_gridCol_TEST
    // END_USER_CODE-USER_gridCol_TEST
  });
  test("grpbxWarehouseBinSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseBinSetup = screen.getByTestId("grpbxWarehouseBinSetup");
    expect(grpbxWarehouseBinSetup.tagName).toBe("BUTTON");
    expect(grpbxWarehouseBinSetup.type).toBe("button");
    expect(grpbxWarehouseBinSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseBinSetup", () => {
    // START_USER_CODE-USER_grpbxWarehouseBinSetup_TEST
    // END_USER_CODE-USER_grpbxWarehouseBinSetup_TEST
  });
  test("gridCol_txtAllowReceipts(Grid Widget) Test Cases", async () => {
    let gridCol_txtAllowReceipts = screen.getByTestId("gridCol");
    let gridCol_txtAllowReceiptsbtn =
      gridCol_txtAllowReceipts.nextElementSibling.firstElementChild;
    gridCol_txtAllowReceipts =
      gridCol_txtAllowReceipts.parentElement.parentElement.parentElement;
    expect(gridCol_txtAllowReceipts.tagName).toBe("DIV");
    expect(gridCol_txtAllowReceipts.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAllowReceipts", () => {
    // START_USER_CODE-USER_txtAllowReceipts_TEST
    // END_USER_CODE-USER_txtAllowReceipts_TEST
  });
  test("gridCol_txtAllowTransfers(Grid Widget) Test Cases", async () => {
    let gridCol_txtAllowTransfers = screen.getByTestId("gridCol");
    let gridCol_txtAllowTransfersbtn =
      gridCol_txtAllowTransfers.nextElementSibling.firstElementChild;
    gridCol_txtAllowTransfers =
      gridCol_txtAllowTransfers.parentElement.parentElement.parentElement;
    expect(gridCol_txtAllowTransfers.tagName).toBe("DIV");
    expect(gridCol_txtAllowTransfers.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAllowTransfers", () => {
    // START_USER_CODE-USER_txtAllowTransfers_TEST
    // END_USER_CODE-USER_txtAllowTransfers_TEST
  });
  test("gridCol_txtBin(Grid Widget) Test Cases", async () => {
    let gridCol_txtBin = screen.getByTestId("gridCol");
    let gridCol_txtBinbtn = gridCol_txtBin.nextElementSibling.firstElementChild;
    gridCol_txtBin = gridCol_txtBin.parentElement.parentElement.parentElement;
    expect(gridCol_txtBin.tagName).toBe("DIV");
    expect(gridCol_txtBin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtBin", () => {
    // START_USER_CODE-USER_txtBin_TEST
    // END_USER_CODE-USER_txtBin_TEST
  });
  test("gridCol_txtDescription(Grid Widget) Test Cases", async () => {
    let gridCol_txtDescription = screen.getByTestId("gridCol");
    let gridCol_txtDescriptionbtn =
      gridCol_txtDescription.nextElementSibling.firstElementChild;
    gridCol_txtDescription =
      gridCol_txtDescription.parentElement.parentElement.parentElement;
    expect(gridCol_txtDescription.tagName).toBe("DIV");
    expect(gridCol_txtDescription.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDescription", () => {
    // START_USER_CODE-USER_txtDescription_TEST
    // END_USER_CODE-USER_txtDescription_TEST
  });
  test("gridCol_txtEdibleOil(Grid Widget) Test Cases", async () => {
    let gridCol_txtEdibleOil = screen.getByTestId("gridCol");
    let gridCol_txtEdibleOilbtn =
      gridCol_txtEdibleOil.nextElementSibling.firstElementChild;
    gridCol_txtEdibleOil =
      gridCol_txtEdibleOil.parentElement.parentElement.parentElement;
    expect(gridCol_txtEdibleOil.tagName).toBe("DIV");
    expect(gridCol_txtEdibleOil.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEdibleOil", () => {
    // START_USER_CODE-USER_txtEdibleOil_TEST
    // END_USER_CODE-USER_txtEdibleOil_TEST
  });
  test("gridCol_txtGovCapacity(Grid Widget) Test Cases", async () => {
    let gridCol_txtGovCapacity = screen.getByTestId("gridCol");
    let gridCol_txtGovCapacitybtn =
      gridCol_txtGovCapacity.nextElementSibling.firstElementChild;
    gridCol_txtGovCapacity =
      gridCol_txtGovCapacity.parentElement.parentElement.parentElement;
    expect(gridCol_txtGovCapacity.tagName).toBe("DIV");
    expect(gridCol_txtGovCapacity.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtGovCapacity", () => {
    // START_USER_CODE-USER_txtGovCapacity_TEST
    // END_USER_CODE-USER_txtGovCapacity_TEST
  });
  test("gridCol_txtObligationAssignment(Grid Widget) Test Cases", async () => {
    let gridCol_txtObligationAssignment = screen.getByTestId("gridCol");
    let gridCol_txtObligationAssignmentbtn =
      gridCol_txtObligationAssignment.nextElementSibling.firstElementChild;
    gridCol_txtObligationAssignment =
      gridCol_txtObligationAssignment.parentElement.parentElement.parentElement;
    expect(gridCol_txtObligationAssignment.tagName).toBe("DIV");
    expect(gridCol_txtObligationAssignment.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtObligationAssignment", () => {
    // START_USER_CODE-USER_txtObligationAssignment_TEST
    // END_USER_CODE-USER_txtObligationAssignment_TEST
  });
  test("gridCol_txtOleic(Grid Widget) Test Cases", async () => {
    let gridCol_txtOleic = screen.getByTestId("gridCol");
    let gridCol_txtOleicbtn =
      gridCol_txtOleic.nextElementSibling.firstElementChild;
    gridCol_txtOleic =
      gridCol_txtOleic.parentElement.parentElement.parentElement;
    expect(gridCol_txtOleic.tagName).toBe("DIV");
    expect(gridCol_txtOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOleic", () => {
    // START_USER_CODE-USER_txtOleic_TEST
    // END_USER_CODE-USER_txtOleic_TEST
  });
  test("gridCol_txtOrganic(Grid Widget) Test Cases", async () => {
    let gridCol_txtOrganic = screen.getByTestId("gridCol");
    let gridCol_txtOrganicbtn =
      gridCol_txtOrganic.nextElementSibling.firstElementChild;
    gridCol_txtOrganic =
      gridCol_txtOrganic.parentElement.parentElement.parentElement;
    expect(gridCol_txtOrganic.tagName).toBe("DIV");
    expect(gridCol_txtOrganic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOrganic", () => {
    // START_USER_CODE-USER_txtOrganic_TEST
    // END_USER_CODE-USER_txtOrganic_TEST
  });
  test("gridCol_txtPeanutType(Grid Widget) Test Cases", async () => {
    let gridCol_txtPeanutType = screen.getByTestId("gridCol");
    let gridCol_txtPeanutTypebtn =
      gridCol_txtPeanutType.nextElementSibling.firstElementChild;
    gridCol_txtPeanutType =
      gridCol_txtPeanutType.parentElement.parentElement.parentElement;
    expect(gridCol_txtPeanutType.tagName).toBe("DIV");
    expect(gridCol_txtPeanutType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("gridCol_txtPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridCol_txtPeanutVariety = screen.getByTestId("gridCol");
    let gridCol_txtPeanutVarietybtn =
      gridCol_txtPeanutVariety.nextElementSibling.firstElementChild;
    gridCol_txtPeanutVariety =
      gridCol_txtPeanutVariety.parentElement.parentElement.parentElement;
    expect(gridCol_txtPeanutVariety.tagName).toBe("DIV");
    expect(gridCol_txtPeanutVariety.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanutVariety", () => {
    // START_USER_CODE-USER_txtPeanutVariety_TEST
    // END_USER_CODE-USER_txtPeanutVariety_TEST
  });
  test("gridCol_txtSeedGen(Grid Widget) Test Cases", async () => {
    let gridCol_txtSeedGen = screen.getByTestId("gridCol");
    let gridCol_txtSeedGenbtn =
      gridCol_txtSeedGen.nextElementSibling.firstElementChild;
    gridCol_txtSeedGen =
      gridCol_txtSeedGen.parentElement.parentElement.parentElement;
    expect(gridCol_txtSeedGen.tagName).toBe("DIV");
    expect(gridCol_txtSeedGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSeedGen", () => {
    // START_USER_CODE-USER_txtSeedGen_TEST
    // END_USER_CODE-USER_txtSeedGen_TEST
  });
  test("gridCol_txtSegType(Grid Widget) Test Cases", async () => {
    let gridCol_txtSegType = screen.getByTestId("gridCol");
    let gridCol_txtSegTypebtn =
      gridCol_txtSegType.nextElementSibling.firstElementChild;
    gridCol_txtSegType =
      gridCol_txtSegType.parentElement.parentElement.parentElement;
    expect(gridCol_txtSegType.tagName).toBe("DIV");
    expect(gridCol_txtSegType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSegType", () => {
    // START_USER_CODE-USER_txtSegType_TEST
    // END_USER_CODE-USER_txtSegType_TEST
  });
  test("gridCol_txtStartupTons(Grid Widget) Test Cases", async () => {
    let gridCol_txtStartupTons = screen.getByTestId("gridCol");
    let gridCol_txtStartupTonsbtn =
      gridCol_txtStartupTons.nextElementSibling.firstElementChild;
    gridCol_txtStartupTons =
      gridCol_txtStartupTons.parentElement.parentElement.parentElement;
    expect(gridCol_txtStartupTons.tagName).toBe("DIV");
    expect(gridCol_txtStartupTons.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtStartupTons", () => {
    // START_USER_CODE-USER_txtStartupTons_TEST
    // END_USER_CODE-USER_txtStartupTons_TEST
  });
  test("gridCol_txtWhs(Grid Widget) Test Cases", async () => {
    let gridCol_txtWhs = screen.getByTestId("gridCol");
    let gridCol_txtWhsbtn = gridCol_txtWhs.nextElementSibling.firstElementChild;
    gridCol_txtWhs = gridCol_txtWhs.parentElement.parentElement.parentElement;
    expect(gridCol_txtWhs.tagName).toBe("DIV");
    expect(gridCol_txtWhs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:WarehouseBinSetup_gridCol")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtWhs", () => {
    // START_USER_CODE-USER_txtWhs_TEST
    // END_USER_CODE-USER_txtWhs_TEST
  });
});
