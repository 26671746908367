/* eslint-disable*/
import React, { useState, useEffect, useContext, useCallback } from "react";
import AzureAD from 'react-aad-msal';
import { authProvider } from '../service/authProvider'
import { withTranslation } from "react-i18next";
import ContactAdministrator from "../ContactAdministrator"
import "./App.module.scss";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import AppContext from "../context/AppContext";
import axios from "axios";
import routes from "./app-routing";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Slide, toast } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import jwt_decode from 'jwt-decode';
const _kaledo = window._kaledo;

function App(props) {
  let [_selectedLang, set_selectedLang] = useState();
  const [authTokens, setauthTokens] = useState(_kaledo.authData);

  useEffect(() => {
    var container = document.getElementsByTagName("body")[0];
    container.onkeyup = function (e) {
      if (_kaledo.isCobolTable) {
        var target = e.srcElement || e.target;
        var allEle = window.$('input:enabled:visible:not([readonly])');
        var indexofCur = allEle.index(target);
        const startPos = e.target.selectionStart;
        if (target.attributes["maxlength"] !== undefined) {
          var maxLength = parseInt(target.attributes["maxlength"].value, 10);
          if (startPos !== undefined && startPos >= maxLength) {
            //Need to handle all remianing key only if max lenght is reached
            if (e.keyCode !== 9 && e.keyCode !== 16 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13 && e.keyCode !== 8 && target.attributes["maxlength"] !== undefined) {
              var myLength = target.value.length;
              if (myLength >= maxLength) {
                var next = target;
                if (indexofCur < allEle.length - 1) {
                  next = allEle.get(indexofCur + 1);
                }
                else {
                  next = allEle.get(0);
                }
                if (next !== undefined) {
                  next.focus();
                  if (next.type === "text" || next.type === "password" || next.type === "textarea")
                    next.setSelectionRange(0, 0);
                }
              }
            }
          }
        }
      }
    };
    container.onkeydown = function (e) {
      if (_kaledo.isCobolTable) {
        var target = e.srcElement || e.target;
        var allEle;
        if (this.classList.contains("modal-open") && this.lastChild !== undefined) {
          allEle = window.$('input:enabled:visible:not([readonly]),textarea:enabled:visible:not([readonly])', this.lastChild);
        }
        else {
          allEle = window.$('input:enabled:visible:not([readonly]),textarea:enabled:visible:not([readonly])', this);
        }

        var indexofCur = allEle.index(target);
        //AutoTabbing on holding the key 
        const startPos = e.target.selectionStart;
        if (target.attributes["maxlength"] !== undefined) {
          var maxLength = parseInt(target.attributes["maxlength"].value, 10);
          if (startPos !== undefined && startPos >= maxLength) {
            //Need to handle all remianing key only if max lenght is reached
            if (e.keyCode !== 9 && e.keyCode !== 16 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13 && e.keyCode !== 8 && target.attributes["maxlength"] !== undefined) {
              var myLength = target.value.length;
              if (myLength >= maxLength) {
                var next = target;
                if (indexofCur < allEle.length - 1) {
                  next = allEle.get(indexofCur + 1);
                }
                else {
                  next = allEle.get(0);
                }
                if (next !== undefined) {
                  next.focus();
                  if (next.type === "text" || next.type === "password" || next.type === "textarea")
                    next.setSelectionRange(0, 0);
                }
              }
            }
          }
        }
        //functionality for tab,shift tab,up arrow and down arrow 
        if (e.keyCode === 9 || e.keyCode === 38 || e.keyCode === 40) {
          var next = target;
          if ((e.keyCode === 9 && e.shiftKey) || e.keyCode === 38) {
            if (indexofCur !== 0)
              next = allEle.get(indexofCur - 1);
            else
              next = allEle.get(allEle.length - 1);

          }
          else {
            if (indexofCur < allEle.length - 1)
              next = allEle.get(indexofCur + 1);
            else
              next = allEle.get(0);
          }
          if (next !== undefined) {
            next.focus();
            if (next.type === "text" || next.type === "password" || next.type === "textarea")
              next.setSelectionRange(0, 0);
          }
          e.stopPropagation();
          e.preventDefault();
        }
        //functionality for backspace 
        /*else if(e.keyCode === 8){
           e.preventDefault();
           let targetEl=e.target;
           const start = e.target.selectionStart;
           targetEl.focus();
           if(targetEl.type==="text" || targetEl.type==="password" ||targetEl.type==="textarea")
             targetEl.setSelectionRange(start-1, start-1);
       }*/
        //functionality for space 
        else if (e.keyCode === 32) {
          let targetEl = e.target;
          let elValue = targetEl.value;
          const start = e.target.selectionStart;
          if (elValue !== undefined) {
            elValue = elValue.substring(0, start) + elValue.substring(start + 1);
            targetEl.value = elValue;
          }
          if (targetEl.type === "text" || targetEl.type === "password" || targetEl.type === "textarea")
            targetEl.setSelectionRange(start, start);

        }
        //replacing character on key pressing
        else if (e.keyCode !== 9 && e.keyCode !== 13 && e.keyCode !== 8 && e.keyCode !== 45 && e.keyCode !== 46 && e.keyCode !== 27 && !(e.keyCode >= 16 && e.keyCode <= 20) && !(e.keyCode >= 32 && e.keyCode <= 40) && !(e.keyCode >= 91 && e.keyCode <= 93) && !(e.keyCode >= 112 && e.keyCode <= 123) && (e.ctrlKey !== true)) {
          let targetEl = e.target;
          let elValue = targetEl.value;
          const start = e.target.selectionStart;
          if (elValue !== undefined) {
            elValue = elValue.substring(0, start) + elValue.substring(start + 1);
            targetEl.value = elValue;
          }
          if (targetEl.type === "text" || targetEl.type === "password" || targetEl.type === "textarea")
            targetEl.setSelectionRange(start, start);

        }
      }
    };
    //MaxLength restriction on pasting the value
    /*document.onpaste = evt =>{
     if(_kaledo.isCobolTable && evt.clipboardData !== undefined && evt.target.attributes["maxlength"]!==undefined){
      //Todo:abs specific element
      var elemErr = document.getElementById('txtErrMsgs');
      var pasteText=evt.clipboardData.getData('text');
      var maxLength=evt.target.attributes["maxlength"].value;
      let requiredMsg = this.props.t(
      `AppJSMessage:VALIDATIONMSG_PASTERESTRMSG`
      );
      const element = evt.target;
      var allEle = window.$('input:enabled:visible:not([readonly])');
      var indexofCur=allEle.index(element);
      const position = element.selectionStart;
      if (position + pasteText.length > maxLength) {
        evt.preventDefault();
        if(elemErr!==null){
          elemErr.value = requiredMsg;
          elemErr.style.color = 'red';
          elemErr.style.borderColor = 'red';
          setTimeout(() => {  
             elemErr.value = "";
          },4000);
        }
        else{
          toast.error(requiredMsg);
        }
  
      }
      else{
        if(elemErr!==null){
        elemErr.value = "";
        }
        
        if((position + pasteText.length)==maxLength){
        var next = element;		
        setTimeout(function(){ 
                if(indexofCur<allEle.length-1){
                  next = allEle.get(indexofCur+1);
                }
                else{
                  next = allEle.get(0);
                }
                if(next!==undefined){
                  next.focus();
                  if(next.type==="text" || next.type==="password" || next.type==="textarea")
                    next.setSelectionRange(0, 0);
                }
                },0);
          }
      }
      }
    }; */
  }, [])

  const setTokens = data => {
    _kaledo.authData = data;
    setauthTokens(data);
    if (data && data.expires_in) {
      let expiresIn = data.expires_in;
      if (expiresIn > 10) {
        expiresIn -= 10;
        if (refreshTimeOut) {
          clearTimeout(refreshTimeOut);
        }
        refreshTimeOut = setTimeout(() => {
          getAccessTokenWithRefreshToken();
        }, expiresIn * 1000);
        if (_kaledo.isRouteFromTest === true && refreshTimeOut !== null)
          getAccessTokenWithRefreshToken();
      }
    }
  };

  const getAccessTokenWithRefreshToken = () => {
    let url = `${_kaledo.AuthRoot}oauth/token`;
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " + btoa(`${_kaledo.AuthClientId}:${_kaledo.AuthClientSecret}`)
    };
    let reqBody = new URLSearchParams();
    reqBody.append("grant_type", "refresh_token");
    reqBody.append("refresh_token", _kaledo.authData.refresh_token);

    axios.post(url, reqBody, { headers }).then(response => {
      setTokens(response.data);
    });
  };

  const getInitialLang = () => {
    let lng = localStorage.getItem("i18nextLng");
    if (lng) {
      let lngArr = _languages.filter(lngObj => lngObj.langCode === lng);
      if (lngArr.length) {
        return lngArr[0];
      }
    }
    return _languages[0];
  };

  const getMenuType = () => {
    let menuType = "top";
    if (_kaledo["menuType"]) {
      menuType = _kaledo["menuType"];
    }
    return menuType;
  };

  const getTwoLevelMenus = () => {
    let screenList = _kaledo["ScreenList"];
    let menuObj = {};
    screenList.forEach((val, indx) => {
      let firstMenuLabel = val.firstMenuLabel;
      if (
        firstMenuLabel === "Dashboard1" ||
        firstMenuLabel === "Dashboard2" ||
        firstMenuLabel === "Dashboard3" ||
        firstMenuLabel === "Dashboard4"
      ) {
        firstMenuLabel = "Dashboard";
      } else if (
        firstMenuLabel === "GoogleSearch" ||
        firstMenuLabel === "TransactionSearch"
      ) {
        firstMenuLabel = "Search";
      }
      if (!menuObj.hasOwnProperty(firstMenuLabel)) {
        let submenusArr = [];
        let submenuObj = Object.assign({}, val);
        delete submenuObj["firstMenuLabel"];
        submenusArr.push(submenuObj);
        menuObj[firstMenuLabel] = submenusArr;
      } else {
        let submenusArr = menuObj[firstMenuLabel];
        let submenuObj = Object.assign({}, val);
        delete submenuObj["firstMenuLabel"];
        submenusArr.push(submenuObj);
      }
    });

    let menuArr = [];
    for (let key in menuObj) {
      let value = menuObj[key];
      let newMenuObj = {};
      newMenuObj["menuLabel"] = key;
      newMenuObj["submenus"] = value;
      menuArr.push(newMenuObj);
    }
    return menuArr;
  };

  let _languages = _kaledo["languages"];
  let _scrnList = getTwoLevelMenus();
  let menuType = getMenuType();
  _selectedLang = getInitialLang();
  let authEnabled = _kaledo.Auth && _kaledo.Auth === "OAUTH2";
  let refreshTimeOut = null;
  let data = {};
  let errorPosition = _kaledo["errorPosition"];
  let classList = {};
  let lastChild = {};
  let activeTab = null;



  const setLanguage = lang => {
    let langCode = lang.langCode || "en";
    props.i18n.changeLanguage(langCode).then(() => {
      set_selectedLang({ ...lang });
    });
  };

  const handleError = error => {
    return Promise.reject(error.message || error);
  };

  function clearStorage() {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  const logout = useCallback(() => {
    clearStorage();
    authProvider.logout();
  }, []);

  let accessToken;
  let tkntype = ""; //Token Type

  for (var i = 0; i < sessionStorage.length; i++) {
    if (sessionStorage.key(i).indexOf("msal." + process.env.REACT_APP_B2C_CLIENT_ID + ".idtoken") != -1) {
      accessToken = sessionStorage.getItem(sessionStorage.key(i));
      tkntype = "valid";
      break;
    }
  }
  if (tkntype == "") {
    for (var i = 0; i < sessionStorage.length; i++) {
      if (sessionStorage.key(i).indexOf("msal.login.error") != -1) {
        accessToken = sessionStorage.getItem(sessionStorage.key(i));
        tkntype = "invalid";
        break;
      }
    }
  }

  const tokenFound = accessToken ? true : false;
  let calculatedTime, validTime, infoTime;
  if (tokenFound) {
    if (tkntype == "valid") {
      const isValid = accessToken ? (jwt_decode(accessToken).exp * 1000) > (Date.now()) : false;
      const exp = isValid ? jwt_decode(accessToken)?.exp : [];
      const roles = isValid ? jwt_decode(accessToken)?.roles : [];
      const userid = isValid ? jwt_decode(accessToken)?.user_id : "";
      if (isValid) {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('roles', roles);
        sessionStorage.setItem('userid', userid);
        calculatedTime = (-(Date.now() / 1000 - exp) * 1000) - 120000;
        infoTime = (-(Date.now() / 1000 - exp) * 1000) - 600000;
        //calculatedTime = 120000 - 60000;
        if (infoTime < 0) {
          infoTime = 0;
        }
        if (calculatedTime < 0) {
          validTime = 0;
        } else {
          validTime = calculatedTime;
        }
      }
      else {
        validTime = 0;
      }

      setTimeout(() => {
        alert("Your session is about to expire. Kindly save all unsaved changes to avoid any data loss.")
      }, infoTime);

      setTimeout(() => {
        alert("Session timeout!! Kindly login again.")
        window.sessionStorage.clear();
        authProvider.logout();
      }, validTime);

      return (
        <AppContext.Provider
          value={{
            screenList: _scrnList,
            _selectedLang: _selectedLang,
            _languages: _languages,
            langClick: setLanguage,
            logout: logout,
            activeTab: activeTab,
            routes: routes,
            t: props.t,
            authTokens: authTokens,
            setAuthTokens: setTokens,
            authEnabled: authEnabled,
            data: data
          }}
        >{props.children}
          <div className="App">
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              transition={Slide}
            ></ToastContainer>
            <Router>
              {(!authEnabled ||
                (authEnabled && authTokens)) && (
                  <Header menuType={menuType} />
                )}
              <div
                className="main"
                style={
                  menuType === "side"
                    ? { width: "85%", marginLeft: "15%" }
                    : null
                }
              >
                <AzureAD provider={authProvider}>
                  <Switch>
                    {_kaledo.isRouteFromTest ? _kaledo.routes.map((route, index) => {
                      if (route.private) {
                        return <PrivateRoute key={index} {...route}></PrivateRoute>;
                      }
                      return <Route key={index} {...route}></Route>;
                    }) : routes.map((route, index) => {
                      if (route.private) {
                        return <PrivateRoute key={index} {...route}></PrivateRoute>;
                      }
                      return <Route key={index} {...route}></Route>;
                    })}
                  </Switch>
                </AzureAD>
              </div>
              <Footer />
            </Router>
          </div>
        </AppContext.Provider>
      );
    }
    else if (tkntype == "invalid") {
      if (accessToken?.toString().indexOf("AADB2C90118") != -1) {
        const msg = "Process completed. Login Again."
        return <ContactAdministrator msg={msg} type='keep' />
      }
      else if (accessToken?.toString().indexOf("AADB2C99002") != -1) {
        return <div></div>
      }
      else if (accessToken?.toString().indexOf("B2CPPS4091") != -1) {
        const msg =
          "Role is not assigned. Kindly contact administrator and get roles assigned for yourself.";
        return <ContactAdministrator msg={msg} type='clear' />
      }
      else if (accessToken?.toString().indexOf("AADB2C90091") != -1) {
        const msg = "User cancelled the operation.";
        return <ContactAdministrator msg={msg} type='clear' />
      } else {
        const msg =
          "Sorry for inconvinence in accesing the application. Kindly try again in sometime or contact Administrator incase same issue is appearing.";
        return <ContactAdministrator msg={msg} type='clear' />
      }
    }
  }
  else {
    clearStorage();
    return <AzureAD provider={authProvider} forceLogin={true}></AzureAD>;
  }
}
export const useAppContext = () => React.useContext(AppContext);
export default withTranslation([
  ..._kaledo.guiModuleNames,
  "LoginModule",
  "AppJSMessage",
  "translate"
])(App);
