/* eslint-disable*/
import React, {useRef, useEffect} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { convertStringToJsonObject } from "../../../../common/ScreenInitialization";
import "./CheckboxGroupWidget.scss";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip} from "../ParentWidgetFunc";

function CheckboxGroupWidget(props){
  const widgetRef = useRef(null)
  let labelWidth = props.conf.labelWidth || 4;
  let conf = props.conf;
  let screenConf = props.screenConf;

    if (!conf.valueList || !conf.valueList.length) {
      conf.valueList = convertStringToJsonObject(conf.Options);
    }
    let value = props.values[conf.name];
    value = [...value];

    useEffect(() => {
      Array.from(widgetRef.current.children).forEach((element,index) => {
        const node = document.createAttribute("id");
        node.value = "labelid-"+ '_' + index;
        if(element.children[1])
          element.children[1].attributes.setNamedItem(node);
      });
    }, []);

    let checkboxGroup = (
      <div className="mb-3" data-testid={conf.name} ref={widgetRef}>
        {conf.valueList.map((element, index) => {
          return (
            <Form.Check
              key={index}
              inline={conf.inline}
              disabled={!conf.Enabled}
              label={element.description}
              type="checkbox"
              checked={value.includes(element.key)}
              value={value[index]}
              name={conf.name}
              id={conf.name + index}
              onChange={e => {
                if (e.target.checked) {
                  value.push(element.key)                  
                } else if (!e.target.checked) {
                    let currentIndex = value.indexOf(element.key)
                      value.splice(currentIndex, 1);                           
                }
                let event = {
                  type: "change",
                  target: {
                    name: conf.name,
                    id: conf.name,
                    value: value
                  }
                };
                props.onChange(event);
              }}
              onBlur={() => {
                let event = {
                  type: "blur",
                  target: {
                    name: conf.name,
                    id: conf.name
                  }
                };
                props.onBlur(event);
              }}
              isInvalid={checkInvalid(props,conf)}
            />
          );
        })}
        <Form.Control.Feedback
          className={checkInvalid(props,conf) ? "d-block" : "d-none"}
          type="invalid"
          aria-live="polite" role="alert"
        >
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </div>
    );
    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
    title={getTooltip(conf)}
    role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{checkboxGroup}</Col>
          ) : (
            <React.Fragment> {checkboxGroup}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default CheckboxGroupWidget;
