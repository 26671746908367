/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ExtReceiptForfeituresProfile from "./ExtReceiptForfeituresProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExtReceiptForfeituresProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ExtReceiptForfeituresProfile />);
    });
  });
  afterEach(cleanup);
  test("is ExtReceiptForfeituresProfile Loads Successfully", () => {
    expect(screen.getByText("ExtReceiptForfeituresProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for ExtReceiptForfeituresProfile", () => {
    // START_USER_CODE-USER_ExtReceiptForfeituresProfile_Custom_Test_Case
    // END_USER_CODE-USER_ExtReceiptForfeituresProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ExtReceiptForfeituresProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAcctDist(Button Widget) Test Cases", async () => {
    const btnAcctDist = screen.getByTestId("btnAcctDist");
    expect(btnAcctDist).toBeInTheDocument;
    expect(btnAcctDist.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnAcctDist")
    );
  });
  test("Custom Test Cases for btnAcctDist", () => {
    // START_USER_CODE-USER_btnAcctDist_TEST
    // END_USER_CODE-USER_btnAcctDist_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCMAVendor(Button Widget) Test Cases", async () => {
    const btnCMAVendor = screen.getByTestId("btnCMAVendor");
    expect(btnCMAVendor).toBeInTheDocument;
    expect(btnCMAVendor.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnCMAVendor")
    );
  });
  test("Custom Test Cases for btnCMAVendor", () => {
    // START_USER_CODE-USER_btnCMAVendor_TEST
    // END_USER_CODE-USER_btnCMAVendor_TEST
  });
  test("btnCopyExternal(Button Widget) Test Cases", async () => {
    const btnCopyExternal = screen.getByTestId("btnCopyExternal");
    expect(btnCopyExternal).toBeInTheDocument;
    expect(btnCopyExternal.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnCopyExternal")
    );
  });
  test("Custom Test Cases for btnCopyExternal", () => {
    // START_USER_CODE-USER_btnCopyExternal_TEST
    // END_USER_CODE-USER_btnCopyExternal_TEST
  });
  test("btnCreate(Button Widget) Test Cases", async () => {
    const btnCreate = screen.getByTestId("btnCreate");
    expect(btnCreate).toBeInTheDocument;
    expect(btnCreate.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnCreate")
    );
  });
  test("Custom Test Cases for btnCreate", () => {
    // START_USER_CODE-USER_btnCreate_TEST
    // END_USER_CODE-USER_btnCreate_TEST
  });
  test("btnDeliveryAgreement(Button Widget) Test Cases", async () => {
    const btnDeliveryAgreement = screen.getByTestId("btnDeliveryAgreement");
    expect(btnDeliveryAgreement).toBeInTheDocument;
    expect(btnDeliveryAgreement.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnDeliveryAgreement")
    );
  });
  test("Custom Test Cases for btnDeliveryAgreement", () => {
    // START_USER_CODE-USER_btnDeliveryAgreement_TEST
    // END_USER_CODE-USER_btnDeliveryAgreement_TEST
  });
  test("btnRelease(Button Widget) Test Cases", async () => {
    const btnRelease = screen.getByTestId("btnRelease");
    expect(btnRelease).toBeInTheDocument;
    expect(btnRelease.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnRelease")
    );
  });
  test("Custom Test Cases for btnRelease", () => {
    // START_USER_CODE-USER_btnRelease_TEST
    // END_USER_CODE-USER_btnRelease_TEST
  });
  test("btnRepayVendor(Button Widget) Test Cases", async () => {
    const btnRepayVendor = screen.getByTestId("btnRepayVendor");
    expect(btnRepayVendor).toBeInTheDocument;
    expect(btnRepayVendor.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnRepayVendor")
    );
  });
  test("Custom Test Cases for btnRepayVendor", () => {
    // START_USER_CODE-USER_btnRepayVendor_TEST
    // END_USER_CODE-USER_btnRepayVendor_TEST
  });
  test("btnReversal(Button Widget) Test Cases", async () => {
    const btnReversal = screen.getByTestId("btnReversal");
    expect(btnReversal).toBeInTheDocument;
    expect(btnReversal.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnReversal")
    );
  });
  test("Custom Test Cases for btnReversal", () => {
    // START_USER_CODE-USER_btnReversal_TEST
    // END_USER_CODE-USER_btnReversal_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxChkboxCntnr(GroupBox Widget) Test Cases", async () => {
    const grpbxChkboxCntnr = screen.getByTestId("grpbxChkboxCntnr");
    expect(grpbxChkboxCntnr.tagName).toBe("BUTTON");
    expect(grpbxChkboxCntnr.type).toBe("button");
    expect(grpbxChkboxCntnr.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChkboxCntnr", () => {
    // START_USER_CODE-USER_grpbxChkboxCntnr_TEST
    // END_USER_CODE-USER_grpbxChkboxCntnr_TEST
  });
  test("grpbxExtReceiptForfeitureProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxExtReceiptForfeitureProfile = screen.getByTestId(
      "grpbxExtReceiptForfeitureProfile"
    );
    expect(grpbxExtReceiptForfeitureProfile.tagName).toBe("BUTTON");
    expect(grpbxExtReceiptForfeitureProfile.type).toBe("button");
    expect(grpbxExtReceiptForfeitureProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxExtReceiptForfeitureProfile", () => {
    // START_USER_CODE-USER_grpbxExtReceiptForfeitureProfile_TEST
    // END_USER_CODE-USER_grpbxExtReceiptForfeitureProfile_TEST
  });
  test("grpbxFactorsForAllPeanuts(GroupBox Widget) Test Cases", async () => {
    const grpbxFactorsForAllPeanuts = screen.getByTestId(
      "grpbxFactorsForAllPeanuts"
    );
    expect(grpbxFactorsForAllPeanuts.tagName).toBe("BUTTON");
    expect(grpbxFactorsForAllPeanuts.type).toBe("button");
    expect(grpbxFactorsForAllPeanuts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFactorsForAllPeanuts", () => {
    // START_USER_CODE-USER_grpbxFactorsForAllPeanuts_TEST
    // END_USER_CODE-USER_grpbxFactorsForAllPeanuts_TEST
  });
  test("grpbxLoan(GroupBox Widget) Test Cases", async () => {
    const grpbxLoan = screen.getByTestId("grpbxLoan");
    expect(grpbxLoan.tagName).toBe("BUTTON");
    expect(grpbxLoan.type).toBe("button");
    expect(grpbxLoan.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoan", () => {
    // START_USER_CODE-USER_grpbxLoan_TEST
    // END_USER_CODE-USER_grpbxLoan_TEST
  });
  test("grpbxLoanNum(GroupBox Widget) Test Cases", async () => {
    const grpbxLoanNum = screen.getByTestId("grpbxLoanNum");
    expect(grpbxLoanNum.tagName).toBe("BUTTON");
    expect(grpbxLoanNum.type).toBe("button");
    expect(grpbxLoanNum.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoanNum", () => {
    // START_USER_CODE-USER_grpbxLoanNum_TEST
    // END_USER_CODE-USER_grpbxLoanNum_TEST
  });
  test("grpbxPayoffDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxPayoffDetails = screen.getByTestId("grpbxPayoffDetails");
    expect(grpbxPayoffDetails.tagName).toBe("BUTTON");
    expect(grpbxPayoffDetails.type).toBe("button");
    expect(grpbxPayoffDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPayoffDetails", () => {
    // START_USER_CODE-USER_grpbxPayoffDetails_TEST
    // END_USER_CODE-USER_grpbxPayoffDetails_TEST
  });
  test("grpbxPrchs1(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchs1 = screen.getByTestId("grpbxPrchs1");
    expect(grpbxPrchs1.tagName).toBe("BUTTON");
    expect(grpbxPrchs1.type).toBe("button");
    expect(grpbxPrchs1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchs1", () => {
    // START_USER_CODE-USER_grpbxPrchs1_TEST
    // END_USER_CODE-USER_grpbxPrchs1_TEST
  });
  test("grpbxPrchs2(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchs2 = screen.getByTestId("grpbxPrchs2");
    expect(grpbxPrchs2.tagName).toBe("BUTTON");
    expect(grpbxPrchs2.type).toBe("button");
    expect(grpbxPrchs2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchs2", () => {
    // START_USER_CODE-USER_grpbxPrchs2_TEST
    // END_USER_CODE-USER_grpbxPrchs2_TEST
  });
  test("grpbxPurchase(GroupBox Widget) Test Cases", async () => {
    const grpbxPurchase = screen.getByTestId("grpbxPurchase");
    expect(grpbxPurchase.tagName).toBe("BUTTON");
    expect(grpbxPurchase.type).toBe("button");
    expect(grpbxPurchase.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPurchase", () => {
    // START_USER_CODE-USER_grpbxPurchase_TEST
    // END_USER_CODE-USER_grpbxPurchase_TEST
  });
  test("grpbxReceiptInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxReceiptInfo = screen.getByTestId("grpbxReceiptInfo");
    expect(grpbxReceiptInfo.tagName).toBe("BUTTON");
    expect(grpbxReceiptInfo.type).toBe("button");
    expect(grpbxReceiptInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReceiptInfo", () => {
    // START_USER_CODE-USER_grpbxReceiptInfo_TEST
    // END_USER_CODE-USER_grpbxReceiptInfo_TEST
  });
  test("grpbxReceiptInfo1(GroupBox Widget) Test Cases", async () => {
    const grpbxReceiptInfo1 = screen.getByTestId("grpbxReceiptInfo1");
    expect(grpbxReceiptInfo1.tagName).toBe("BUTTON");
    expect(grpbxReceiptInfo1.type).toBe("button");
    expect(grpbxReceiptInfo1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReceiptInfo1", () => {
    // START_USER_CODE-USER_grpbxReceiptInfo1_TEST
    // END_USER_CODE-USER_grpbxReceiptInfo1_TEST
  });
  test("grpbxReceiptInfo2(GroupBox Widget) Test Cases", async () => {
    const grpbxReceiptInfo2 = screen.getByTestId("grpbxReceiptInfo2");
    expect(grpbxReceiptInfo2.tagName).toBe("BUTTON");
    expect(grpbxReceiptInfo2.type).toBe("button");
    expect(grpbxReceiptInfo2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReceiptInfo2", () => {
    // START_USER_CODE-USER_grpbxReceiptInfo2_TEST
    // END_USER_CODE-USER_grpbxReceiptInfo2_TEST
  });
  test("grpbxReceiptInfo3(GroupBox Widget) Test Cases", async () => {
    const grpbxReceiptInfo3 = screen.getByTestId("grpbxReceiptInfo3");
    expect(grpbxReceiptInfo3.tagName).toBe("BUTTON");
    expect(grpbxReceiptInfo3.type).toBe("button");
    expect(grpbxReceiptInfo3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReceiptInfo3", () => {
    // START_USER_CODE-USER_grpbxReceiptInfo3_TEST
    // END_USER_CODE-USER_grpbxReceiptInfo3_TEST
  });
  test("grpbxRepay(GroupBox Widget) Test Cases", async () => {
    const grpbxRepay = screen.getByTestId("grpbxRepay");
    expect(grpbxRepay.tagName).toBe("BUTTON");
    expect(grpbxRepay.type).toBe("button");
    expect(grpbxRepay.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRepay", () => {
    // START_USER_CODE-USER_grpbxRepay_TEST
    // END_USER_CODE-USER_grpbxRepay_TEST
  });
  test("grpbxRepayment(GroupBox Widget) Test Cases", async () => {
    const grpbxRepayment = screen.getByTestId("grpbxRepayment");
    expect(grpbxRepayment.tagName).toBe("BUTTON");
    expect(grpbxRepayment.type).toBe("button");
    expect(grpbxRepayment.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRepayment", () => {
    // START_USER_CODE-USER_grpbxRepayment_TEST
    // END_USER_CODE-USER_grpbxRepayment_TEST
  });
  test("grpbxStorage(GroupBox Widget) Test Cases", async () => {
    const grpbxStorage = screen.getByTestId("grpbxStorage");
    expect(grpbxStorage.tagName).toBe("BUTTON");
    expect(grpbxStorage.type).toBe("button");
    expect(grpbxStorage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorage", () => {
    // START_USER_CODE-USER_grpbxStorage_TEST
    // END_USER_CODE-USER_grpbxStorage_TEST
  });
  test("grpbxStrgPrchs(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgPrchs = screen.getByTestId("grpbxStrgPrchs");
    expect(grpbxStrgPrchs.tagName).toBe("BUTTON");
    expect(grpbxStrgPrchs.type).toBe("button");
    expect(grpbxStrgPrchs.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgPrchs", () => {
    // START_USER_CODE-USER_grpbxStrgPrchs_TEST
    // END_USER_CODE-USER_grpbxStrgPrchs_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblChangedDate(Label Widget) Test Cases", async () => {
    const lblChangedDate = screen.getByTestId("lblChangedDate");
    expect(lblChangedDate.tagName).toBe("LABEL");
    expect(lblChangedDate.classList).toContain("form-label");
    expect(lblChangedDate.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblChangedDate")
    );
  });
  test("Custom Test Cases for lblChangedDate", () => {
    // START_USER_CODE-USER_lblChangedDate_TEST
    // END_USER_CODE-USER_lblChangedDate_TEST
  });
  test("lblChangedDateValue(Label Widget) Test Cases", async () => {
    const lblChangedDateValue = screen.getByTestId("lblChangedDateValue");
    expect(lblChangedDateValue.tagName).toBe("LABEL");
    expect(lblChangedDateValue.classList).toContain("form-label");
    expect(lblChangedDateValue.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblChangedDateValue")
    );
  });
  test("Custom Test Cases for lblChangedDateValue", () => {
    // START_USER_CODE-USER_lblChangedDateValue_TEST
    // END_USER_CODE-USER_lblChangedDateValue_TEST
  });
  test("lblFactorsForAllPeanuts(Label Widget) Test Cases", async () => {
    const lblFactorsForAllPeanuts = screen.getByTestId(
      "lblFactorsForAllPeanuts"
    );
    expect(lblFactorsForAllPeanuts.tagName).toBe("LABEL");
    expect(lblFactorsForAllPeanuts.classList).toContain("form-label");
    expect(lblFactorsForAllPeanuts.textContent).toEqual(
      t(
        "WarehouseReceipts:ExtReceiptForfeituresProfile_lblFactorsForAllPeanuts"
      )
    );
  });
  test("Custom Test Cases for lblFactorsForAllPeanuts", () => {
    // START_USER_CODE-USER_lblFactorsForAllPeanuts_TEST
    // END_USER_CODE-USER_lblFactorsForAllPeanuts_TEST
  });
  test("lblLoanNum(Label Widget) Test Cases", async () => {
    const lblLoanNum = screen.getByTestId("lblLoanNum");
    expect(lblLoanNum.tagName).toBe("LABEL");
    expect(lblLoanNum.classList).toContain("form-label");
    expect(lblLoanNum.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblLoanNum")
    );
  });
  test("Custom Test Cases for lblLoanNum", () => {
    // START_USER_CODE-USER_lblLoanNum_TEST
    // END_USER_CODE-USER_lblLoanNum_TEST
  });
  test("lblPayoffDetails(Label Widget) Test Cases", async () => {
    const lblPayoffDetails = screen.getByTestId("lblPayoffDetails");
    expect(lblPayoffDetails.tagName).toBe("LABEL");
    expect(lblPayoffDetails.classList).toContain("form-label");
    expect(lblPayoffDetails.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPayoffDetails")
    );
  });
  test("Custom Test Cases for lblPayoffDetails", () => {
    // START_USER_CODE-USER_lblPayoffDetails_TEST
    // END_USER_CODE-USER_lblPayoffDetails_TEST
  });
  test("lblPct1(Label Widget) Test Cases", async () => {
    const lblPct1 = screen.getByTestId("lblPct1");
    expect(lblPct1.tagName).toBe("LABEL");
    expect(lblPct1.classList).toContain("form-label");
    expect(lblPct1.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct1")
    );
  });
  test("Custom Test Cases for lblPct1", () => {
    // START_USER_CODE-USER_lblPct1_TEST
    // END_USER_CODE-USER_lblPct1_TEST
  });
  test("lblPct10(Label Widget) Test Cases", async () => {
    const lblPct10 = screen.getByTestId("lblPct10");
    expect(lblPct10.tagName).toBe("LABEL");
    expect(lblPct10.classList).toContain("form-label");
    expect(lblPct10.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct10")
    );
  });
  test("Custom Test Cases for lblPct10", () => {
    // START_USER_CODE-USER_lblPct10_TEST
    // END_USER_CODE-USER_lblPct10_TEST
  });
  test("lblPct11(Label Widget) Test Cases", async () => {
    const lblPct11 = screen.getByTestId("lblPct11");
    expect(lblPct11.tagName).toBe("LABEL");
    expect(lblPct11.classList).toContain("form-label");
    expect(lblPct11.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct11")
    );
  });
  test("Custom Test Cases for lblPct11", () => {
    // START_USER_CODE-USER_lblPct11_TEST
    // END_USER_CODE-USER_lblPct11_TEST
  });
  test("lblPct12(Label Widget) Test Cases", async () => {
    const lblPct12 = screen.getByTestId("lblPct12");
    expect(lblPct12.tagName).toBe("LABEL");
    expect(lblPct12.classList).toContain("form-label");
    expect(lblPct12.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct12")
    );
  });
  test("Custom Test Cases for lblPct12", () => {
    // START_USER_CODE-USER_lblPct12_TEST
    // END_USER_CODE-USER_lblPct12_TEST
  });
  test("lblPct13(Label Widget) Test Cases", async () => {
    const lblPct13 = screen.getByTestId("lblPct13");
    expect(lblPct13.tagName).toBe("LABEL");
    expect(lblPct13.classList).toContain("form-label");
    expect(lblPct13.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct13")
    );
  });
  test("Custom Test Cases for lblPct13", () => {
    // START_USER_CODE-USER_lblPct13_TEST
    // END_USER_CODE-USER_lblPct13_TEST
  });
  test("lblPct14(Label Widget) Test Cases", async () => {
    const lblPct14 = screen.getByTestId("lblPct14");
    expect(lblPct14.tagName).toBe("LABEL");
    expect(lblPct14.classList).toContain("form-label");
    expect(lblPct14.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct14")
    );
  });
  test("Custom Test Cases for lblPct14", () => {
    // START_USER_CODE-USER_lblPct14_TEST
    // END_USER_CODE-USER_lblPct14_TEST
  });
  test("lblPct15(Label Widget) Test Cases", async () => {
    const lblPct15 = screen.getByTestId("lblPct15");
    expect(lblPct15.tagName).toBe("LABEL");
    expect(lblPct15.classList).toContain("form-label");
    expect(lblPct15.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct15")
    );
  });
  test("Custom Test Cases for lblPct15", () => {
    // START_USER_CODE-USER_lblPct15_TEST
    // END_USER_CODE-USER_lblPct15_TEST
  });
  test("lblPct16(Label Widget) Test Cases", async () => {
    const lblPct16 = screen.getByTestId("lblPct16");
    expect(lblPct16.tagName).toBe("LABEL");
    expect(lblPct16.classList).toContain("form-label");
    expect(lblPct16.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct16")
    );
  });
  test("Custom Test Cases for lblPct16", () => {
    // START_USER_CODE-USER_lblPct16_TEST
    // END_USER_CODE-USER_lblPct16_TEST
  });
  test("lblPct17(Label Widget) Test Cases", async () => {
    const lblPct17 = screen.getByTestId("lblPct17");
    expect(lblPct17.tagName).toBe("LABEL");
    expect(lblPct17.classList).toContain("form-label");
    expect(lblPct17.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct17")
    );
  });
  test("Custom Test Cases for lblPct17", () => {
    // START_USER_CODE-USER_lblPct17_TEST
    // END_USER_CODE-USER_lblPct17_TEST
  });
  test("lblPct18(Label Widget) Test Cases", async () => {
    const lblPct18 = screen.getByTestId("lblPct18");
    expect(lblPct18.tagName).toBe("LABEL");
    expect(lblPct18.classList).toContain("form-label");
    expect(lblPct18.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct18")
    );
  });
  test("Custom Test Cases for lblPct18", () => {
    // START_USER_CODE-USER_lblPct18_TEST
    // END_USER_CODE-USER_lblPct18_TEST
  });
  test("lblPct19(Label Widget) Test Cases", async () => {
    const lblPct19 = screen.getByTestId("lblPct19");
    expect(lblPct19.tagName).toBe("LABEL");
    expect(lblPct19.classList).toContain("form-label");
    expect(lblPct19.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct19")
    );
  });
  test("Custom Test Cases for lblPct19", () => {
    // START_USER_CODE-USER_lblPct19_TEST
    // END_USER_CODE-USER_lblPct19_TEST
  });
  test("lblPct2(Label Widget) Test Cases", async () => {
    const lblPct2 = screen.getByTestId("lblPct2");
    expect(lblPct2.tagName).toBe("LABEL");
    expect(lblPct2.classList).toContain("form-label");
    expect(lblPct2.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct2")
    );
  });
  test("Custom Test Cases for lblPct2", () => {
    // START_USER_CODE-USER_lblPct2_TEST
    // END_USER_CODE-USER_lblPct2_TEST
  });
  test("lblPct3(Label Widget) Test Cases", async () => {
    const lblPct3 = screen.getByTestId("lblPct3");
    expect(lblPct3.tagName).toBe("LABEL");
    expect(lblPct3.classList).toContain("form-label");
    expect(lblPct3.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct3")
    );
  });
  test("Custom Test Cases for lblPct3", () => {
    // START_USER_CODE-USER_lblPct3_TEST
    // END_USER_CODE-USER_lblPct3_TEST
  });
  test("lblPct4(Label Widget) Test Cases", async () => {
    const lblPct4 = screen.getByTestId("lblPct4");
    expect(lblPct4.tagName).toBe("LABEL");
    expect(lblPct4.classList).toContain("form-label");
    expect(lblPct4.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct4")
    );
  });
  test("Custom Test Cases for lblPct4", () => {
    // START_USER_CODE-USER_lblPct4_TEST
    // END_USER_CODE-USER_lblPct4_TEST
  });
  test("lblPct5(Label Widget) Test Cases", async () => {
    const lblPct5 = screen.getByTestId("lblPct5");
    expect(lblPct5.tagName).toBe("LABEL");
    expect(lblPct5.classList).toContain("form-label");
    expect(lblPct5.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct5")
    );
  });
  test("Custom Test Cases for lblPct5", () => {
    // START_USER_CODE-USER_lblPct5_TEST
    // END_USER_CODE-USER_lblPct5_TEST
  });
  test("lblPct6(Label Widget) Test Cases", async () => {
    const lblPct6 = screen.getByTestId("lblPct6");
    expect(lblPct6.tagName).toBe("LABEL");
    expect(lblPct6.classList).toContain("form-label");
    expect(lblPct6.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct6")
    );
  });
  test("Custom Test Cases for lblPct6", () => {
    // START_USER_CODE-USER_lblPct6_TEST
    // END_USER_CODE-USER_lblPct6_TEST
  });
  test("lblPct7(Label Widget) Test Cases", async () => {
    const lblPct7 = screen.getByTestId("lblPct7");
    expect(lblPct7.tagName).toBe("LABEL");
    expect(lblPct7.classList).toContain("form-label");
    expect(lblPct7.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct7")
    );
  });
  test("Custom Test Cases for lblPct7", () => {
    // START_USER_CODE-USER_lblPct7_TEST
    // END_USER_CODE-USER_lblPct7_TEST
  });
  test("lblPct8(Label Widget) Test Cases", async () => {
    const lblPct8 = screen.getByTestId("lblPct8");
    expect(lblPct8.tagName).toBe("LABEL");
    expect(lblPct8.classList).toContain("form-label");
    expect(lblPct8.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct8")
    );
  });
  test("Custom Test Cases for lblPct8", () => {
    // START_USER_CODE-USER_lblPct8_TEST
    // END_USER_CODE-USER_lblPct8_TEST
  });
  test("lblPct9(Label Widget) Test Cases", async () => {
    const lblPct9 = screen.getByTestId("lblPct9");
    expect(lblPct9.tagName).toBe("LABEL");
    expect(lblPct9.classList).toContain("form-label");
    expect(lblPct9.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPct9")
    );
  });
  test("Custom Test Cases for lblPct9", () => {
    // START_USER_CODE-USER_lblPct9_TEST
    // END_USER_CODE-USER_lblPct9_TEST
  });
  test("lblPPB(Label Widget) Test Cases", async () => {
    const lblPPB = screen.getByTestId("lblPPB");
    expect(lblPPB.tagName).toBe("LABEL");
    expect(lblPPB.classList).toContain("form-label");
    expect(lblPPB.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPPB")
    );
  });
  test("Custom Test Cases for lblPPB", () => {
    // START_USER_CODE-USER_lblPPB_TEST
    // END_USER_CODE-USER_lblPPB_TEST
  });
  test("lblPurchaseInfo(Label Widget) Test Cases", async () => {
    const lblPurchaseInfo = screen.getByTestId("lblPurchaseInfo");
    expect(lblPurchaseInfo.tagName).toBe("LABEL");
    expect(lblPurchaseInfo.classList).toContain("form-label");
    expect(lblPurchaseInfo.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblPurchaseInfo")
    );
  });
  test("Custom Test Cases for lblPurchaseInfo", () => {
    // START_USER_CODE-USER_lblPurchaseInfo_TEST
    // END_USER_CODE-USER_lblPurchaseInfo_TEST
  });
  test("lblReceiptInfo(Label Widget) Test Cases", async () => {
    const lblReceiptInfo = screen.getByTestId("lblReceiptInfo");
    expect(lblReceiptInfo.tagName).toBe("LABEL");
    expect(lblReceiptInfo.classList).toContain("form-label");
    expect(lblReceiptInfo.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblReceiptInfo")
    );
  });
  test("Custom Test Cases for lblReceiptInfo", () => {
    // START_USER_CODE-USER_lblReceiptInfo_TEST
    // END_USER_CODE-USER_lblReceiptInfo_TEST
  });
  test("lblRepayment(Label Widget) Test Cases", async () => {
    const lblRepayment = screen.getByTestId("lblRepayment");
    expect(lblRepayment.tagName).toBe("LABEL");
    expect(lblRepayment.classList).toContain("form-label");
    expect(lblRepayment.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblRepayment")
    );
  });
  test("Custom Test Cases for lblRepayment", () => {
    // START_USER_CODE-USER_lblRepayment_TEST
    // END_USER_CODE-USER_lblRepayment_TEST
  });
  test("lblStorage(Label Widget) Test Cases", async () => {
    const lblStorage = screen.getByTestId("lblStorage");
    expect(lblStorage.tagName).toBe("LABEL");
    expect(lblStorage.classList).toContain("form-label");
    expect(lblStorage.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_lblStorage")
    );
  });
  test("Custom Test Cases for lblStorage", () => {
    // START_USER_CODE-USER_lblStorage_TEST
    // END_USER_CODE-USER_lblStorage_TEST
  });
  test("txtAmount(Textbox Widget) Test Cases", async () => {
    const txtAmount = screen.getByTestId("txtAmount");
    expect(txtAmount.tagName).toBe("INPUT");
    expect(txtAmount.type).toBe("text");
    expect(txtAmount.classList).toContain("textboxWidgetClass");
    expect(txtAmount.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtAmount")
    );
    await act(async () => {
      userEvent.type(txtAmount, "123");
    });
    expect(txtAmount.getAttribute("value")).toBe("");
    expect(txtAmount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAmount", () => {
    // START_USER_CODE-USER_txtAmount_TEST
    // END_USER_CODE-USER_txtAmount_TEST
  });
  test("txtCalCNetRepayAmt(Textbox Widget) Test Cases", async () => {
    const txtCalCNetRepayAmt = screen.getByTestId("txtCalCNetRepayAmt");
    expect(txtCalCNetRepayAmt.tagName).toBe("INPUT");
    expect(txtCalCNetRepayAmt.type).toBe("text");
    expect(txtCalCNetRepayAmt.classList).toContain("textboxWidgetClass");
    expect(txtCalCNetRepayAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCalCNetRepayAmt")
    );
    await act(async () => {
      userEvent.type(txtCalCNetRepayAmt, "123");
    });
    expect(txtCalCNetRepayAmt.getAttribute("value")).toBe("");
    expect(txtCalCNetRepayAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCalCNetRepayAmt", () => {
    // START_USER_CODE-USER_txtCalCNetRepayAmt_TEST
    // END_USER_CODE-USER_txtCalCNetRepayAmt_TEST
  });
  test("txtCCCBeginDate(Textbox Widget) Test Cases", async () => {
    const txtCCCBeginDate = screen.getByTestId("txtCCCBeginDate");
    expect(txtCCCBeginDate.tagName).toBe("INPUT");
    expect(txtCCCBeginDate.type).toBe("text");
    expect(txtCCCBeginDate.classList).toContain("textboxWidgetClass");
    expect(txtCCCBeginDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCCCBeginDate")
    );
    await act(async () => {
      userEvent.type(txtCCCBeginDate, "123");
    });
    expect(txtCCCBeginDate.getAttribute("value")).toBe("");
    expect(txtCCCBeginDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCCBeginDate", () => {
    // START_USER_CODE-USER_txtCCCBeginDate_TEST
    // END_USER_CODE-USER_txtCCCBeginDate_TEST
  });
  test("txtCCCCode(Textbox Widget) Test Cases", async () => {
    const txtCCCCode = screen.getByTestId("txtCCCCode");
    expect(txtCCCCode.tagName).toBe("INPUT");
    expect(txtCCCCode.type).toBe("text");
    expect(txtCCCCode.classList).toContain("textboxWidgetClass");
    expect(txtCCCCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCCCCode")
    );
    await act(async () => {
      userEvent.type(txtCCCCode, "123");
    });
    expect(txtCCCCode.getAttribute("value")).toBe("");
    expect(txtCCCCode.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCCCCode", () => {
    // START_USER_CODE-USER_txtCCCCode_TEST
    // END_USER_CODE-USER_txtCCCCode_TEST
  });
  test("txtCMAVendor(Textbox Widget) Test Cases", async () => {
    const txtCMAVendor = screen.getByTestId("txtCMAVendor");
    expect(txtCMAVendor.tagName).toBe("INPUT");
    expect(txtCMAVendor.type).toBe("text");
    expect(txtCMAVendor.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCMAVendor, "123");
    });
    expect(txtCMAVendor.getAttribute("value")).toBe("");
    expect(txtCMAVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCMAVendor", () => {
    // START_USER_CODE-USER_txtCMAVendor_TEST
    // END_USER_CODE-USER_txtCMAVendor_TEST
  });
  test("txtCMAVendorNum(Textbox Widget) Test Cases", async () => {
    const txtCMAVendorNum = screen.getByTestId("txtCMAVendorNum");
    expect(txtCMAVendorNum.tagName).toBe("INPUT");
    expect(txtCMAVendorNum.type).toBe("text");
    expect(txtCMAVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtCMAVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCMAVendorNum")
    );
    await act(async () => {
      userEvent.type(txtCMAVendorNum, "123");
    });
    expect(txtCMAVendorNum.getAttribute("value")).toBe("");
    expect(txtCMAVendorNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCMAVendorNum", () => {
    // START_USER_CODE-USER_txtCMAVendorNum_TEST
    // END_USER_CODE-USER_txtCMAVendorNum_TEST
  });
  test("txtCnclRMD(Textbox Widget) Test Cases", async () => {
    const txtCnclRMD = screen.getByTestId("txtCnclRMD");
    expect(txtCnclRMD.tagName).toBe("INPUT");
    expect(txtCnclRMD.type).toBe("text");
    expect(txtCnclRMD.classList).toContain("textboxWidgetClass");
    expect(txtCnclRMD.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCnclRMD")
    );
    await act(async () => {
      userEvent.type(txtCnclRMD, "123");
    });
    expect(txtCnclRMD.getAttribute("value")).toBe("");
    expect(txtCnclRMD.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCnclRMD", () => {
    // START_USER_CODE-USER_txtCnclRMD_TEST
    // END_USER_CODE-USER_txtCnclRMD_TEST
  });
  test("txtCommittedTo(Textbox Widget) Test Cases", async () => {
    const txtCommittedTo = screen.getByTestId("txtCommittedTo");
    expect(txtCommittedTo.tagName).toBe("INPUT");
    expect(txtCommittedTo.type).toBe("text");
    expect(txtCommittedTo.classList).toContain("textboxWidgetClass");
    expect(txtCommittedTo.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCommittedTo")
    );
    await act(async () => {
      userEvent.type(txtCommittedTo, "123");
    });
    expect(txtCommittedTo.getAttribute("value")).toBe("");
    expect(txtCommittedTo.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCommittedTo", () => {
    // START_USER_CODE-USER_txtCommittedTo_TEST
    // END_USER_CODE-USER_txtCommittedTo_TEST
  });
  test("txtCopiedToCropYear(Textbox Widget) Test Cases", async () => {
    const txtCopiedToCropYear = screen.getByTestId("txtCopiedToCropYear");
    expect(txtCopiedToCropYear.tagName).toBe("INPUT");
    expect(txtCopiedToCropYear.type).toBe("text");
    expect(txtCopiedToCropYear.classList).toContain("textboxWidgetClass");
    expect(txtCopiedToCropYear.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCopiedToCropYear")
    );
    await act(async () => {
      userEvent.type(txtCopiedToCropYear, "123");
    });
    expect(txtCopiedToCropYear.getAttribute("value")).toBe("");
    expect(txtCopiedToCropYear.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCopiedToCropYear", () => {
    // START_USER_CODE-USER_txtCopiedToCropYear_TEST
    // END_USER_CODE-USER_txtCopiedToCropYear_TEST
  });
  test("txtCrackBroken(Textbox Widget) Test Cases", async () => {
    const txtCrackBroken = screen.getByTestId("txtCrackBroken");
    expect(txtCrackBroken.tagName).toBe("INPUT");
    expect(txtCrackBroken.type).toBe("text");
    expect(txtCrackBroken.classList).toContain("textboxWidgetClass");
    expect(txtCrackBroken.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCrackBroken")
    );
    await act(async () => {
      userEvent.type(txtCrackBroken, "123");
    });
    expect(txtCrackBroken.getAttribute("value")).toBe("");
    expect(txtCrackBroken.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCrackBroken", () => {
    // START_USER_CODE-USER_txtCrackBroken_TEST
    // END_USER_CODE-USER_txtCrackBroken_TEST
  });
  test("txtCtrlNum(Textbox Widget) Test Cases", async () => {
    const txtCtrlNum = screen.getByTestId("txtCtrlNum");
    expect(txtCtrlNum.tagName).toBe("INPUT");
    expect(txtCtrlNum.type).toBe("text");
    expect(txtCtrlNum.classList).toContain("textboxWidgetClass");
    expect(txtCtrlNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtCtrlNum")
    );
    await act(async () => {
      userEvent.type(txtCtrlNum, "123");
    });
    expect(txtCtrlNum.getAttribute("value")).toBe("");
    expect(txtCtrlNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCtrlNum", () => {
    // START_USER_CODE-USER_txtCtrlNum_TEST
    // END_USER_CODE-USER_txtCtrlNum_TEST
  });
  test("txtDateCancelled(Textbox Widget) Test Cases", async () => {
    const txtDateCancelled = screen.getByTestId("txtDateCancelled");
    expect(txtDateCancelled.tagName).toBe("INPUT");
    expect(txtDateCancelled.type).toBe("text");
    expect(txtDateCancelled.classList).toContain("textboxWidgetClass");
    expect(txtDateCancelled.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtDateCancelled")
    );
    await act(async () => {
      userEvent.type(txtDateCancelled, "123");
    });
    expect(txtDateCancelled.getAttribute("value")).toBe("");
    expect(txtDateCancelled.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDateCancelled", () => {
    // START_USER_CODE-USER_txtDateCancelled_TEST
    // END_USER_CODE-USER_txtDateCancelled_TEST
  });
  test("txtDeductAmt(Textbox Widget) Test Cases", async () => {
    const txtDeductAmt = screen.getByTestId("txtDeductAmt");
    expect(txtDeductAmt.tagName).toBe("INPUT");
    expect(txtDeductAmt.type).toBe("text");
    expect(txtDeductAmt.classList).toContain("textboxWidgetClass");
    expect(txtDeductAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtDeductAmt")
    );
    await act(async () => {
      userEvent.type(txtDeductAmt, "123");
    });
    expect(txtDeductAmt.getAttribute("value")).toBe("");
    expect(txtDeductAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDeductAmt", () => {
    // START_USER_CODE-USER_txtDeductAmt_TEST
    // END_USER_CODE-USER_txtDeductAmt_TEST
  });
  test("txtDelvCompletedDt(Textbox Widget) Test Cases", async () => {
    const txtDelvCompletedDt = screen.getByTestId("txtDelvCompletedDt");
    expect(txtDelvCompletedDt.tagName).toBe("INPUT");
    expect(txtDelvCompletedDt.type).toBe("text");
    expect(txtDelvCompletedDt.classList).toContain("textboxWidgetClass");
    expect(txtDelvCompletedDt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtDelvCompletedDt")
    );
    await act(async () => {
      userEvent.type(txtDelvCompletedDt, "123");
    });
    expect(txtDelvCompletedDt.getAttribute("value")).toBe("");
    expect(txtDelvCompletedDt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDelvCompletedDt", () => {
    // START_USER_CODE-USER_txtDelvCompletedDt_TEST
    // END_USER_CODE-USER_txtDelvCompletedDt_TEST
  });
  test("txtDisclrd(Textbox Widget) Test Cases", async () => {
    const txtDisclrd = screen.getByTestId("txtDisclrd");
    expect(txtDisclrd.tagName).toBe("INPUT");
    expect(txtDisclrd.type).toBe("text");
    expect(txtDisclrd.classList).toContain("textboxWidgetClass");
    expect(txtDisclrd.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtDisclrd")
    );
    await act(async () => {
      userEvent.type(txtDisclrd, "123");
    });
    expect(txtDisclrd.getAttribute("value")).toBe("");
    expect(txtDisclrd.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDisclrd", () => {
    // START_USER_CODE-USER_txtDisclrd_TEST
    // END_USER_CODE-USER_txtDisclrd_TEST
  });
  test("txtDocDelvDt(Textbox Widget) Test Cases", async () => {
    const txtDocDelvDt = screen.getByTestId("txtDocDelvDt");
    expect(txtDocDelvDt.tagName).toBe("INPUT");
    expect(txtDocDelvDt.type).toBe("text");
    expect(txtDocDelvDt.classList).toContain("textboxWidgetClass");
    expect(txtDocDelvDt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtDocDelvDt")
    );
    await act(async () => {
      userEvent.type(txtDocDelvDt, "123");
    });
    expect(txtDocDelvDt.getAttribute("value")).toBe("");
    expect(txtDocDelvDt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDocDelvDt", () => {
    // START_USER_CODE-USER_txtDocDelvDt_TEST
    // END_USER_CODE-USER_txtDocDelvDt_TEST
  });
  test("txtELK(Textbox Widget) Test Cases", async () => {
    const txtELK = screen.getByTestId("txtELK");
    expect(txtELK.tagName).toBe("INPUT");
    expect(txtELK.type).toBe("text");
    expect(txtELK.classList).toContain("textboxWidgetClass");
    expect(txtELK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtELK")
    );
    await act(async () => {
      userEvent.type(txtELK, "123");
    });
    expect(txtELK.getAttribute("value")).toBe("");
    expect(txtELK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELK", () => {
    // START_USER_CODE-USER_txtELK_TEST
    // END_USER_CODE-USER_txtELK_TEST
  });
  test("txtEndDate(Textbox Widget) Test Cases", async () => {
    const txtEndDate = screen.getByTestId("txtEndDate");
    expect(txtEndDate.tagName).toBe("INPUT");
    expect(txtEndDate.type).toBe("text");
    expect(txtEndDate.classList).toContain("textboxWidgetClass");
    expect(txtEndDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtEndDate")
    );
    await act(async () => {
      userEvent.type(txtEndDate, "123");
    });
    expect(txtEndDate.getAttribute("value")).toBe("");
    expect(txtEndDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEndDate", () => {
    // START_USER_CODE-USER_txtEndDate_TEST
    // END_USER_CODE-USER_txtEndDate_TEST
  });
  test("txtERecptNum(Textbox Widget) Test Cases", async () => {
    const txtERecptNum = screen.getByTestId("txtERecptNum");
    expect(txtERecptNum.tagName).toBe("INPUT");
    expect(txtERecptNum.type).toBe("text");
    expect(txtERecptNum.classList).toContain("textboxWidgetClass");
    expect(txtERecptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtERecptNum")
    );
    await act(async () => {
      userEvent.type(txtERecptNum, "123");
    });
    expect(txtERecptNum.getAttribute("value")).toBe("");
    expect(txtERecptNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtERecptNum", () => {
    // START_USER_CODE-USER_txtERecptNum_TEST
    // END_USER_CODE-USER_txtERecptNum_TEST
  });
  test("txtExpDate(Textbox Widget) Test Cases", async () => {
    const txtExpDate = screen.getByTestId("txtExpDate");
    expect(txtExpDate.tagName).toBe("INPUT");
    expect(txtExpDate.type).toBe("text");
    expect(txtExpDate.classList).toContain("textboxWidgetClass");
    expect(txtExpDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtExpDate")
    );
    await act(async () => {
      userEvent.type(txtExpDate, "123");
    });
    expect(txtExpDate.getAttribute("value")).toBe("");
    expect(txtExpDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExpDate", () => {
    // START_USER_CODE-USER_txtExpDate_TEST
    // END_USER_CODE-USER_txtExpDate_TEST
  });
  test("txtFancy(Textbox Widget) Test Cases", async () => {
    const txtFancy = screen.getByTestId("txtFancy");
    expect(txtFancy.tagName).toBe("INPUT");
    expect(txtFancy.type).toBe("text");
    expect(txtFancy.classList).toContain("textboxWidgetClass");
    expect(txtFancy.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtFancy")
    );
    await act(async () => {
      userEvent.type(txtFancy, "123");
    });
    expect(txtFancy.getAttribute("value")).toBe("");
    expect(txtFancy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFancy", () => {
    // START_USER_CODE-USER_txtFancy_TEST
    // END_USER_CODE-USER_txtFancy_TEST
  });
  test("txtFarmerStockCost(Textbox Widget) Test Cases", async () => {
    const txtFarmerStockCost = screen.getByTestId("txtFarmerStockCost");
    expect(txtFarmerStockCost.tagName).toBe("INPUT");
    expect(txtFarmerStockCost.type).toBe("text");
    expect(txtFarmerStockCost.classList).toContain("textboxWidgetClass");
    expect(txtFarmerStockCost.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtFarmerStockCost")
    );
    await act(async () => {
      userEvent.type(txtFarmerStockCost, "123");
    });
    expect(txtFarmerStockCost.getAttribute("value")).toBe("");
    expect(txtFarmerStockCost.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmerStockCost", () => {
    // START_USER_CODE-USER_txtFarmerStockCost_TEST
    // END_USER_CODE-USER_txtFarmerStockCost_TEST
  });
  test("txtFM(Textbox Widget) Test Cases", async () => {
    const txtFM = screen.getByTestId("txtFM");
    expect(txtFM.tagName).toBe("INPUT");
    expect(txtFM.type).toBe("text");
    expect(txtFM.classList).toContain("textboxWidgetClass");
    expect(txtFM.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtFM")
    );
    await act(async () => {
      userEvent.type(txtFM, "123");
    });
    expect(txtFM.getAttribute("value")).toBe("");
    expect(txtFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFM", () => {
    // START_USER_CODE-USER_txtFM_TEST
    // END_USER_CODE-USER_txtFM_TEST
  });
  test("txtFreezeDam(Textbox Widget) Test Cases", async () => {
    const txtFreezeDam = screen.getByTestId("txtFreezeDam");
    expect(txtFreezeDam.tagName).toBe("INPUT");
    expect(txtFreezeDam.type).toBe("text");
    expect(txtFreezeDam.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDam.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtFreezeDam")
    );
    await act(async () => {
      userEvent.type(txtFreezeDam, "123");
    });
    expect(txtFreezeDam.getAttribute("value")).toBe("");
    expect(txtFreezeDam.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFreezeDam", () => {
    // START_USER_CODE-USER_txtFreezeDam_TEST
    // END_USER_CODE-USER_txtFreezeDam_TEST
  });
  test("txtHulls(Textbox Widget) Test Cases", async () => {
    const txtHulls = screen.getByTestId("txtHulls");
    expect(txtHulls.tagName).toBe("INPUT");
    expect(txtHulls.type).toBe("text");
    expect(txtHulls.classList).toContain("textboxWidgetClass");
    expect(txtHulls.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtHulls")
    );
    await act(async () => {
      userEvent.type(txtHulls, "123");
    });
    expect(txtHulls.getAttribute("value")).toBe("");
    expect(txtHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHulls", () => {
    // START_USER_CODE-USER_txtHulls_TEST
    // END_USER_CODE-USER_txtHulls_TEST
  });
  test("txtHullsBright(Textbox Widget) Test Cases", async () => {
    const txtHullsBright = screen.getByTestId("txtHullsBright");
    expect(txtHullsBright.tagName).toBe("INPUT");
    expect(txtHullsBright.type).toBe("text");
    expect(txtHullsBright.classList).toContain("textboxWidgetClass");
    expect(txtHullsBright.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtHullsBright")
    );
    await act(async () => {
      userEvent.type(txtHullsBright, "123");
    });
    expect(txtHullsBright.getAttribute("value")).toBe("");
    expect(txtHullsBright.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHullsBright", () => {
    // START_USER_CODE-USER_txtHullsBright_TEST
    // END_USER_CODE-USER_txtHullsBright_TEST
  });
  test("txtInterestAmt(Textbox Widget) Test Cases", async () => {
    const txtInterestAmt = screen.getByTestId("txtInterestAmt");
    expect(txtInterestAmt.tagName).toBe("INPUT");
    expect(txtInterestAmt.type).toBe("text");
    expect(txtInterestAmt.classList).toContain("textboxWidgetClass");
    expect(txtInterestAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtInterestAmt")
    );
    await act(async () => {
      userEvent.type(txtInterestAmt, "123");
    });
    expect(txtInterestAmt.getAttribute("value")).toBe("");
    expect(txtInterestAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtInterestAmt", () => {
    // START_USER_CODE-USER_txtInterestAmt_TEST
    // END_USER_CODE-USER_txtInterestAmt_TEST
  });
  test("txtIssuedDate(Textbox Widget) Test Cases", async () => {
    const txtIssuedDate = screen.getByTestId("txtIssuedDate");
    expect(txtIssuedDate.tagName).toBe("INPUT");
    expect(txtIssuedDate.type).toBe("text");
    expect(txtIssuedDate.classList).toContain("textboxWidgetClass");
    expect(txtIssuedDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtIssuedDate")
    );
    await act(async () => {
      userEvent.type(txtIssuedDate, "123");
    });
    expect(txtIssuedDate.getAttribute("value")).toBe("");
    expect(txtIssuedDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtIssuedDate", () => {
    // START_USER_CODE-USER_txtIssuedDate_TEST
    // END_USER_CODE-USER_txtIssuedDate_TEST
  });
  test("txtJumbo(Textbox Widget) Test Cases", async () => {
    const txtJumbo = screen.getByTestId("txtJumbo");
    expect(txtJumbo.tagName).toBe("INPUT");
    expect(txtJumbo.type).toBe("text");
    expect(txtJumbo.classList).toContain("textboxWidgetClass");
    expect(txtJumbo.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtJumbo")
    );
    await act(async () => {
      userEvent.type(txtJumbo, "123");
    });
    expect(txtJumbo.getAttribute("value")).toBe("");
    expect(txtJumbo.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtJumbo", () => {
    // START_USER_CODE-USER_txtJumbo_TEST
    // END_USER_CODE-USER_txtJumbo_TEST
  });
  test("txtLastAction(Textbox Widget) Test Cases", async () => {
    const txtLastAction = screen.getByTestId("txtLastAction");
    expect(txtLastAction.tagName).toBe("INPUT");
    expect(txtLastAction.type).toBe("text");
    expect(txtLastAction.classList).toContain("textboxWidgetClass");
    expect(txtLastAction.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLastAction")
    );
    await act(async () => {
      userEvent.type(txtLastAction, "123");
    });
    expect(txtLastAction.getAttribute("value")).toBe("");
    expect(txtLastAction.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLastAction", () => {
    // START_USER_CODE-USER_txtLastAction_TEST
    // END_USER_CODE-USER_txtLastAction_TEST
  });
  test("txtLicenseNum(Textbox Widget) Test Cases", async () => {
    const txtLicenseNum = screen.getByTestId("txtLicenseNum");
    expect(txtLicenseNum.tagName).toBe("INPUT");
    expect(txtLicenseNum.type).toBe("text");
    expect(txtLicenseNum.classList).toContain("textboxWidgetClass");
    expect(txtLicenseNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLicenseNum")
    );
    await act(async () => {
      userEvent.type(txtLicenseNum, "123");
    });
    expect(txtLicenseNum.getAttribute("value")).toBe("");
    expect(txtLicenseNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLicenseNum", () => {
    // START_USER_CODE-USER_txtLicenseNum_TEST
    // END_USER_CODE-USER_txtLicenseNum_TEST
  });
  test("txtLnCounty(Textbox Widget) Test Cases", async () => {
    const txtLnCounty = screen.getByTestId("txtLnCounty");
    expect(txtLnCounty.tagName).toBe("INPUT");
    expect(txtLnCounty.type).toBe("text");
    expect(txtLnCounty.classList).toContain("textboxWidgetClass");
    expect(txtLnCounty.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLnCounty")
    );
    await act(async () => {
      userEvent.type(txtLnCounty, "123");
    });
    expect(txtLnCounty.getAttribute("value")).toBe("");
    expect(txtLnCounty.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLnCounty", () => {
    // START_USER_CODE-USER_txtLnCounty_TEST
    // END_USER_CODE-USER_txtLnCounty_TEST
  });
  test("txtLnNumber(Textbox Widget) Test Cases", async () => {
    const txtLnNumber = screen.getByTestId("txtLnNumber");
    expect(txtLnNumber.tagName).toBe("INPUT");
    expect(txtLnNumber.type).toBe("text");
    expect(txtLnNumber.classList).toContain("textboxWidgetClass");
    expect(txtLnNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLnNumber")
    );
    await act(async () => {
      userEvent.type(txtLnNumber, "123");
    });
    expect(txtLnNumber.getAttribute("value")).toBe("");
    expect(txtLnNumber.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLnNumber", () => {
    // START_USER_CODE-USER_txtLnNumber_TEST
    // END_USER_CODE-USER_txtLnNumber_TEST
  });
  test("txtLnRpyLockInDt(Textbox Widget) Test Cases", async () => {
    const txtLnRpyLockInDt = screen.getByTestId("txtLnRpyLockInDt");
    expect(txtLnRpyLockInDt.tagName).toBe("INPUT");
    expect(txtLnRpyLockInDt.type).toBe("text");
    expect(txtLnRpyLockInDt.classList).toContain("textboxWidgetClass");
    expect(txtLnRpyLockInDt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLnRpyLockInDt")
    );
    await act(async () => {
      userEvent.type(txtLnRpyLockInDt, "123");
    });
    expect(txtLnRpyLockInDt.getAttribute("value")).toBe("");
    expect(txtLnRpyLockInDt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLnRpyLockInDt", () => {
    // START_USER_CODE-USER_txtLnRpyLockInDt_TEST
    // END_USER_CODE-USER_txtLnRpyLockInDt_TEST
  });
  test("txtLnState(Textbox Widget) Test Cases", async () => {
    const txtLnState = screen.getByTestId("txtLnState");
    expect(txtLnState.tagName).toBe("INPUT");
    expect(txtLnState.type).toBe("text");
    expect(txtLnState.classList).toContain("textboxWidgetClass");
    expect(txtLnState.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLnState")
    );
    await act(async () => {
      userEvent.type(txtLnState, "123");
    });
    expect(txtLnState.getAttribute("value")).toBe("");
    expect(txtLnState.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLnState", () => {
    // START_USER_CODE-USER_txtLnState_TEST
    // END_USER_CODE-USER_txtLnState_TEST
  });
  test("txtLoanAmt(Textbox Widget) Test Cases", async () => {
    const txtLoanAmt = screen.getByTestId("txtLoanAmt");
    expect(txtLoanAmt.tagName).toBe("INPUT");
    expect(txtLoanAmt.type).toBe("text");
    expect(txtLoanAmt.classList).toContain("textboxWidgetClass");
    expect(txtLoanAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLoanAmt")
    );
    await act(async () => {
      userEvent.type(txtLoanAmt, "123");
    });
    expect(txtLoanAmt.getAttribute("value")).toBe("");
    expect(txtLoanAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLoanAmt", () => {
    // START_USER_CODE-USER_txtLoanAmt_TEST
    // END_USER_CODE-USER_txtLoanAmt_TEST
  });
  test("txtLoanDt(Textbox Widget) Test Cases", async () => {
    const txtLoanDt = screen.getByTestId("txtLoanDt");
    expect(txtLoanDt.tagName).toBe("INPUT");
    expect(txtLoanDt.type).toBe("text");
    expect(txtLoanDt.classList).toContain("textboxWidgetClass");
    expect(txtLoanDt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLoanDt")
    );
    await act(async () => {
      userEvent.type(txtLoanDt, "123");
    });
    expect(txtLoanDt.getAttribute("value")).toBe("");
    expect(txtLoanDt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLoanDt", () => {
    // START_USER_CODE-USER_txtLoanDt_TEST
    // END_USER_CODE-USER_txtLoanDt_TEST
  });
  test("txtLoanRepayAmt(Textbox Widget) Test Cases", async () => {
    const txtLoanRepayAmt = screen.getByTestId("txtLoanRepayAmt");
    expect(txtLoanRepayAmt.tagName).toBe("INPUT");
    expect(txtLoanRepayAmt.type).toBe("text");
    expect(txtLoanRepayAmt.classList).toContain("textboxWidgetClass");
    expect(txtLoanRepayAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLoanRepayAmt")
    );
    await act(async () => {
      userEvent.type(txtLoanRepayAmt, "123");
    });
    expect(txtLoanRepayAmt.getAttribute("value")).toBe("");
    expect(txtLoanRepayAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLoanRepayAmt", () => {
    // START_USER_CODE-USER_txtLoanRepayAmt_TEST
    // END_USER_CODE-USER_txtLoanRepayAmt_TEST
  });
  test("txtLoanRepayAmtOverride(Textbox Widget) Test Cases", async () => {
    const txtLoanRepayAmtOverride = screen.getByTestId(
      "txtLoanRepayAmtOverride"
    );
    expect(txtLoanRepayAmtOverride.tagName).toBe("INPUT");
    expect(txtLoanRepayAmtOverride.type).toBe("text");
    expect(txtLoanRepayAmtOverride.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLoanRepayAmtOverride, "123");
    });
    expect(txtLoanRepayAmtOverride.getAttribute("value")).toBe("");
    expect(txtLoanRepayAmtOverride.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLoanRepayAmtOverride", () => {
    // START_USER_CODE-USER_txtLoanRepayAmtOverride_TEST
    // END_USER_CODE-USER_txtLoanRepayAmtOverride_TEST
  });
  test("txtLoanRepayRate(Textbox Widget) Test Cases", async () => {
    const txtLoanRepayRate = screen.getByTestId("txtLoanRepayRate");
    expect(txtLoanRepayRate.tagName).toBe("INPUT");
    expect(txtLoanRepayRate.type).toBe("text");
    expect(txtLoanRepayRate.classList).toContain("textboxWidgetClass");
    expect(txtLoanRepayRate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLoanRepayRate")
    );
    await act(async () => {
      userEvent.type(txtLoanRepayRate, "123");
    });
    expect(txtLoanRepayRate.getAttribute("value")).toBe("");
    expect(txtLoanRepayRate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLoanRepayRate", () => {
    // START_USER_CODE-USER_txtLoanRepayRate_TEST
    // END_USER_CODE-USER_txtLoanRepayRate_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "123");
    });
    expect(txtLSK.getAttribute("value")).toBe("");
    expect(txtLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtLSKLbs(Textbox Widget) Test Cases", async () => {
    const txtLSKLbs = screen.getByTestId("txtLSKLbs");
    expect(txtLSKLbs.tagName).toBe("INPUT");
    expect(txtLSKLbs.type).toBe("text");
    expect(txtLSKLbs.classList).toContain("textboxWidgetClass");
    expect(txtLSKLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtLSKLbs")
    );
    await act(async () => {
      userEvent.type(txtLSKLbs, "123");
    });
    expect(txtLSKLbs.getAttribute("value")).toBe("");
    expect(txtLSKLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKLbs", () => {
    // START_USER_CODE-USER_txtLSKLbs_TEST
    // END_USER_CODE-USER_txtLSKLbs_TEST
  });
  test("txtLSKLbsShrunk(Textbox Widget) Test Cases", async () => {
    const txtLSKLbsShrunk = screen.getByTestId("txtLSKLbsShrunk");
    expect(txtLSKLbsShrunk.tagName).toBe("INPUT");
    expect(txtLSKLbsShrunk.type).toBe("text");
    expect(txtLSKLbsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKLbsShrunk, "123");
    });
    expect(txtLSKLbsShrunk.getAttribute("value")).toBe("");
    expect(txtLSKLbsShrunk.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKLbsShrunk", () => {
    // START_USER_CODE-USER_txtLSKLbsShrunk_TEST
    // END_USER_CODE-USER_txtLSKLbsShrunk_TEST
  });
  test("txtMarketGain1(Textbox Widget) Test Cases", async () => {
    const txtMarketGain1 = screen.getByTestId("txtMarketGain1");
    expect(txtMarketGain1.tagName).toBe("INPUT");
    expect(txtMarketGain1.type).toBe("text");
    expect(txtMarketGain1.classList).toContain("textboxWidgetClass");
    expect(txtMarketGain1.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtMarketGain1")
    );
    await act(async () => {
      userEvent.type(txtMarketGain1, "123");
    });
    expect(txtMarketGain1.getAttribute("value")).toBe("");
    expect(txtMarketGain1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMarketGain1", () => {
    // START_USER_CODE-USER_txtMarketGain1_TEST
    // END_USER_CODE-USER_txtMarketGain1_TEST
  });
  test("txtMarketGain2(Textbox Widget) Test Cases", async () => {
    const txtMarketGain2 = screen.getByTestId("txtMarketGain2");
    expect(txtMarketGain2.tagName).toBe("INPUT");
    expect(txtMarketGain2.type).toBe("text");
    expect(txtMarketGain2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMarketGain2, "123");
    });
    expect(txtMarketGain2.getAttribute("value")).toBe("");
    expect(txtMarketGain2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMarketGain2", () => {
    // START_USER_CODE-USER_txtMarketGain2_TEST
    // END_USER_CODE-USER_txtMarketGain2_TEST
  });
  test("txtMoist(Textbox Widget) Test Cases", async () => {
    const txtMoist = screen.getByTestId("txtMoist");
    expect(txtMoist.tagName).toBe("INPUT");
    expect(txtMoist.type).toBe("text");
    expect(txtMoist.classList).toContain("textboxWidgetClass");
    expect(txtMoist.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtMoist")
    );
    await act(async () => {
      userEvent.type(txtMoist, "123");
    });
    expect(txtMoist.getAttribute("value")).toBe("");
    expect(txtMoist.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMoist", () => {
    // START_USER_CODE-USER_txtMoist_TEST
    // END_USER_CODE-USER_txtMoist_TEST
  });
  test("txtNetLbs(Textbox Widget) Test Cases", async () => {
    const txtNetLbs = screen.getByTestId("txtNetLbs");
    expect(txtNetLbs.tagName).toBe("INPUT");
    expect(txtNetLbs.type).toBe("text");
    expect(txtNetLbs.classList).toContain("textboxWidgetClass");
    expect(txtNetLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtNetLbs")
    );
    await act(async () => {
      userEvent.type(txtNetLbs, "123");
    });
    expect(txtNetLbs.getAttribute("value")).toBe("");
    expect(txtNetLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetLbs", () => {
    // START_USER_CODE-USER_txtNetLbs_TEST
    // END_USER_CODE-USER_txtNetLbs_TEST
  });
  test("txtNetLbsShrunk(Textbox Widget) Test Cases", async () => {
    const txtNetLbsShrunk = screen.getByTestId("txtNetLbsShrunk");
    expect(txtNetLbsShrunk.tagName).toBe("INPUT");
    expect(txtNetLbsShrunk.type).toBe("text");
    expect(txtNetLbsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetLbsShrunk, "1");
    });
  });
  test("Custom Test Cases for txtNetLbsShrunk", () => {
    // START_USER_CODE-USER_txtNetLbsShrunk_TEST
    // END_USER_CODE-USER_txtNetLbsShrunk_TEST
  });
  test("txtNetRepayAmt(Textbox Widget) Test Cases", async () => {
    const txtNetRepayAmt = screen.getByTestId("txtNetRepayAmt");
    expect(txtNetRepayAmt.tagName).toBe("INPUT");
    expect(txtNetRepayAmt.type).toBe("text");
    expect(txtNetRepayAmt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetRepayAmt, "123");
    });
    expect(txtNetRepayAmt.getAttribute("value")).toBe("");
    expect(txtNetRepayAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetRepayAmt", () => {
    // START_USER_CODE-USER_txtNetRepayAmt_TEST
    // END_USER_CODE-USER_txtNetRepayAmt_TEST
  });
  test("txtOptionPayment(Textbox Widget) Test Cases", async () => {
    const txtOptionPayment = screen.getByTestId("txtOptionPayment");
    expect(txtOptionPayment.tagName).toBe("INPUT");
    expect(txtOptionPayment.type).toBe("text");
    expect(txtOptionPayment.classList).toContain("textboxWidgetClass");
    expect(txtOptionPayment.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtOptionPayment")
    );
    await act(async () => {
      userEvent.type(txtOptionPayment, "123");
    });
    expect(txtOptionPayment.getAttribute("value")).toBe("");
    expect(txtOptionPayment.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOptionPayment", () => {
    // START_USER_CODE-USER_txtOptionPayment_TEST
    // END_USER_CODE-USER_txtOptionPayment_TEST
  });
  test("txtOriginalValue(Textbox Widget) Test Cases", async () => {
    const txtOriginalValue = screen.getByTestId("txtOriginalValue");
    expect(txtOriginalValue.tagName).toBe("INPUT");
    expect(txtOriginalValue.type).toBe("text");
    expect(txtOriginalValue.classList).toContain("textboxWidgetClass");
    expect(txtOriginalValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtOriginalValue")
    );
    await act(async () => {
      userEvent.type(txtOriginalValue, "123");
    });
    expect(txtOriginalValue.getAttribute("value")).toBe("");
    expect(txtOriginalValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOriginalValue", () => {
    // START_USER_CODE-USER_txtOriginalValue_TEST
    // END_USER_CODE-USER_txtOriginalValue_TEST
  });
  test("txtOtherKernels(Textbox Widget) Test Cases", async () => {
    const txtOtherKernels = screen.getByTestId("txtOtherKernels");
    expect(txtOtherKernels.tagName).toBe("INPUT");
    expect(txtOtherKernels.type).toBe("text");
    expect(txtOtherKernels.classList).toContain("textboxWidgetClass");
    expect(txtOtherKernels.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtOtherKernels")
    );
    await act(async () => {
      userEvent.type(txtOtherKernels, "123");
    });
    expect(txtOtherKernels.getAttribute("value")).toBe("");
    expect(txtOtherKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOtherKernels", () => {
    // START_USER_CODE-USER_txtOtherKernels_TEST
    // END_USER_CODE-USER_txtOtherKernels_TEST
  });
  test("txtOverrideFarm(Textbox Widget) Test Cases", async () => {
    const txtOverrideFarm = screen.getByTestId("txtOverrideFarm");
    expect(txtOverrideFarm.tagName).toBe("INPUT");
    expect(txtOverrideFarm.type).toBe("text");
    expect(txtOverrideFarm.classList).toContain("textboxWidgetClass");
    expect(txtOverrideFarm.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtOverrideFarm")
    );
    await act(async () => {
      userEvent.type(txtOverrideFarm, "123");
    });
    expect(txtOverrideFarm.getAttribute("value")).toBe("");
    expect(txtOverrideFarm.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOverrideFarm", () => {
    // START_USER_CODE-USER_txtOverrideFarm_TEST
    // END_USER_CODE-USER_txtOverrideFarm_TEST
  });
  test("txtPayoffLocation(Textbox Widget) Test Cases", async () => {
    const txtPayoffLocation = screen.getByTestId("txtPayoffLocation");
    expect(txtPayoffLocation.tagName).toBe("INPUT");
    expect(txtPayoffLocation.type).toBe("text");
    expect(txtPayoffLocation.classList).toContain("textboxWidgetClass");
    expect(txtPayoffLocation.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtPayoffLocation")
    );
    await act(async () => {
      userEvent.type(txtPayoffLocation, "123");
    });
    expect(txtPayoffLocation.getAttribute("value")).toBe("");
    expect(txtPayoffLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPayoffLocation", () => {
    // START_USER_CODE-USER_txtPayoffLocation_TEST
    // END_USER_CODE-USER_txtPayoffLocation_TEST
  });
  test("txtPremiumAmt(Textbox Widget) Test Cases", async () => {
    const txtPremiumAmt = screen.getByTestId("txtPremiumAmt");
    expect(txtPremiumAmt.tagName).toBe("INPUT");
    expect(txtPremiumAmt.type).toBe("text");
    expect(txtPremiumAmt.classList).toContain("textboxWidgetClass");
    expect(txtPremiumAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtPremiumAmt")
    );
    await act(async () => {
      userEvent.type(txtPremiumAmt, "123");
    });
    expect(txtPremiumAmt.getAttribute("value")).toBe("");
    expect(txtPremiumAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPremiumAmt", () => {
    // START_USER_CODE-USER_txtPremiumAmt_TEST
    // END_USER_CODE-USER_txtPremiumAmt_TEST
  });
  test("txtProceedsAmt(Textbox Widget) Test Cases", async () => {
    const txtProceedsAmt = screen.getByTestId("txtProceedsAmt");
    expect(txtProceedsAmt.tagName).toBe("INPUT");
    expect(txtProceedsAmt.type).toBe("text");
    expect(txtProceedsAmt.classList).toContain("textboxWidgetClass");
    expect(txtProceedsAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtProceedsAmt")
    );
    await act(async () => {
      userEvent.type(txtProceedsAmt, "123");
    });
    expect(txtProceedsAmt.getAttribute("value")).toBe("");
    expect(txtProceedsAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProceedsAmt", () => {
    // START_USER_CODE-USER_txtProceedsAmt_TEST
    // END_USER_CODE-USER_txtProceedsAmt_TEST
  });
  test("txtProducerBeginDate(Textbox Widget) Test Cases", async () => {
    const txtProducerBeginDate = screen.getByTestId("txtProducerBeginDate");
    expect(txtProducerBeginDate.tagName).toBe("INPUT");
    expect(txtProducerBeginDate.type).toBe("text");
    expect(txtProducerBeginDate.classList).toContain("textboxWidgetClass");
    expect(txtProducerBeginDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtProducerBeginDate")
    );
    await act(async () => {
      userEvent.type(txtProducerBeginDate, "123");
    });
    expect(txtProducerBeginDate.getAttribute("value")).toBe("");
    expect(txtProducerBeginDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProducerBeginDate", () => {
    // START_USER_CODE-USER_txtProducerBeginDate_TEST
    // END_USER_CODE-USER_txtProducerBeginDate_TEST
  });
  test("txtRcptNum(Textbox Widget) Test Cases", async () => {
    const txtRcptNum = screen.getByTestId("txtRcptNum");
    expect(txtRcptNum.tagName).toBe("INPUT");
    expect(txtRcptNum.type).toBe("text");
    expect(txtRcptNum.classList).toContain("textboxWidgetClass");
    expect(txtRcptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtRcptNum")
    );
    await act(async () => {
      userEvent.type(txtRcptNum, "123");
    });
    expect(txtRcptNum.getAttribute("value")).toBe("");
    expect(txtRcptNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRcptNum", () => {
    // START_USER_CODE-USER_txtRcptNum_TEST
    // END_USER_CODE-USER_txtRcptNum_TEST
  });
  test("txtRepayVendor(Textbox Widget) Test Cases", async () => {
    const txtRepayVendor = screen.getByTestId("txtRepayVendor");
    expect(txtRepayVendor.tagName).toBe("INPUT");
    expect(txtRepayVendor.type).toBe("text");
    expect(txtRepayVendor.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRepayVendor, "123");
    });
    expect(txtRepayVendor.getAttribute("value")).toBe("");
    expect(txtRepayVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRepayVendor", () => {
    // START_USER_CODE-USER_txtRepayVendor_TEST
    // END_USER_CODE-USER_txtRepayVendor_TEST
  });
  test("txtRepayVendorNum(Textbox Widget) Test Cases", async () => {
    const txtRepayVendorNum = screen.getByTestId("txtRepayVendorNum");
    expect(txtRepayVendorNum.tagName).toBe("INPUT");
    expect(txtRepayVendorNum.type).toBe("text");
    expect(txtRepayVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtRepayVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtRepayVendorNum")
    );
    await act(async () => {
      userEvent.type(txtRepayVendorNum, "123");
    });
    expect(txtRepayVendorNum.getAttribute("value")).toBe("");
    expect(txtRepayVendorNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRepayVendorNum", () => {
    // START_USER_CODE-USER_txtRepayVendorNum_TEST
    // END_USER_CODE-USER_txtRepayVendorNum_TEST
  });
  test("txtShrinkPct(Textbox Widget) Test Cases", async () => {
    const txtShrinkPct = screen.getByTestId("txtShrinkPct");
    expect(txtShrinkPct.tagName).toBe("INPUT");
    expect(txtShrinkPct.type).toBe("text");
    expect(txtShrinkPct.classList).toContain("textboxWidgetClass");
    expect(txtShrinkPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtShrinkPct")
    );
    await act(async () => {
      userEvent.type(txtShrinkPct, "123");
    });
    expect(txtShrinkPct.getAttribute("value")).toBe("");
    expect(txtShrinkPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShrinkPct", () => {
    // START_USER_CODE-USER_txtShrinkPct_TEST
    // END_USER_CODE-USER_txtShrinkPct_TEST
  });
  test("txtShrnkPct(Textbox Widget) Test Cases", async () => {
    const txtShrnkPct = screen.getByTestId("txtShrnkPct");
    expect(txtShrnkPct.tagName).toBe("INPUT");
    expect(txtShrnkPct.type).toBe("text");
    expect(txtShrnkPct.classList).toContain("textboxWidgetClass");
    expect(txtShrnkPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtShrnkPct")
    );
    await act(async () => {
      userEvent.type(txtShrnkPct, "123");
    });
    expect(txtShrnkPct.getAttribute("value")).toBe("");
    expect(txtShrnkPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShrnkPct", () => {
    // START_USER_CODE-USER_txtShrnkPct_TEST
    // END_USER_CODE-USER_txtShrnkPct_TEST
  });
  test("txtShrunkValue(Textbox Widget) Test Cases", async () => {
    const txtShrunkValue = screen.getByTestId("txtShrunkValue");
    expect(txtShrunkValue.tagName).toBe("INPUT");
    expect(txtShrunkValue.type).toBe("text");
    expect(txtShrunkValue.classList).toContain("textboxWidgetClass");
    expect(txtShrunkValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtShrunkValue")
    );
    await act(async () => {
      userEvent.type(txtShrunkValue, "123");
    });
    expect(txtShrunkValue.getAttribute("value")).toBe("");
    expect(txtShrunkValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShrunkValue", () => {
    // START_USER_CODE-USER_txtShrunkValue_TEST
    // END_USER_CODE-USER_txtShrunkValue_TEST
  });
  test("txtSMK(Textbox Widget) Test Cases", async () => {
    const txtSMK = screen.getByTestId("txtSMK");
    expect(txtSMK.tagName).toBe("INPUT");
    expect(txtSMK.type).toBe("text");
    expect(txtSMK.classList).toContain("textboxWidgetClass");
    expect(txtSMK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtSMK")
    );
    await act(async () => {
      userEvent.type(txtSMK, "123");
    });
    expect(txtSMK.getAttribute("value")).toBe("");
    expect(txtSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK", () => {
    // START_USER_CODE-USER_txtSMK_TEST
    // END_USER_CODE-USER_txtSMK_TEST
  });
  test("txtSpotPounds(Textbox Widget) Test Cases", async () => {
    const txtSpotPounds = screen.getByTestId("txtSpotPounds");
    expect(txtSpotPounds.tagName).toBe("INPUT");
    expect(txtSpotPounds.type).toBe("text");
    expect(txtSpotPounds.classList).toContain("textboxWidgetClass");
    expect(txtSpotPounds.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtSpotPounds")
    );
    await act(async () => {
      userEvent.type(txtSpotPounds, "123");
    });
    expect(txtSpotPounds.getAttribute("value")).toBe("");
    expect(txtSpotPounds.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSpotPounds", () => {
    // START_USER_CODE-USER_txtSpotPounds_TEST
    // END_USER_CODE-USER_txtSpotPounds_TEST
  });
  test("txtSpotPrice(Textbox Widget) Test Cases", async () => {
    const txtSpotPrice = screen.getByTestId("txtSpotPrice");
    expect(txtSpotPrice.tagName).toBe("INPUT");
    expect(txtSpotPrice.type).toBe("text");
    expect(txtSpotPrice.classList).toContain("textboxWidgetClass");
    expect(txtSpotPrice.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtSpotPrice")
    );
    await act(async () => {
      userEvent.type(txtSpotPrice, "123");
    });
    expect(txtSpotPrice.getAttribute("value")).toBe("");
    expect(txtSpotPrice.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSpotPrice", () => {
    // START_USER_CODE-USER_txtSpotPrice_TEST
    // END_USER_CODE-USER_txtSpotPrice_TEST
  });
  test("txtSS(Textbox Widget) Test Cases", async () => {
    const txtSS = screen.getByTestId("txtSS");
    expect(txtSS.tagName).toBe("INPUT");
    expect(txtSS.type).toBe("text");
    expect(txtSS.classList).toContain("textboxWidgetClass");
    expect(txtSS.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtSS")
    );
    await act(async () => {
      userEvent.type(txtSS, "123");
    });
    expect(txtSS.getAttribute("value")).toBe("");
    expect(txtSS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSS", () => {
    // START_USER_CODE-USER_txtSS_TEST
    // END_USER_CODE-USER_txtSS_TEST
  });
  test("txtStorageCharge(Textbox Widget) Test Cases", async () => {
    const txtStorageCharge = screen.getByTestId("txtStorageCharge");
    expect(txtStorageCharge.tagName).toBe("INPUT");
    expect(txtStorageCharge.type).toBe("text");
    expect(txtStorageCharge.classList).toContain("textboxWidgetClass");
    expect(txtStorageCharge.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtStorageCharge")
    );
    await act(async () => {
      userEvent.type(txtStorageCharge, "123");
    });
    expect(txtStorageCharge.getAttribute("value")).toBe("");
    expect(txtStorageCharge.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStorageCharge", () => {
    // START_USER_CODE-USER_txtStorageCharge_TEST
    // END_USER_CODE-USER_txtStorageCharge_TEST
  });
  test("txtStrgCreditAmt(Textbox Widget) Test Cases", async () => {
    const txtStrgCreditAmt = screen.getByTestId("txtStrgCreditAmt");
    expect(txtStrgCreditAmt.tagName).toBe("INPUT");
    expect(txtStrgCreditAmt.type).toBe("text");
    expect(txtStrgCreditAmt.classList).toContain("textboxWidgetClass");
    expect(txtStrgCreditAmt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtStrgCreditAmt")
    );
    await act(async () => {
      userEvent.type(txtStrgCreditAmt, "123");
    });
    expect(txtStrgCreditAmt.getAttribute("value")).toBe("");
    expect(txtStrgCreditAmt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStrgCreditAmt", () => {
    // START_USER_CODE-USER_txtStrgCreditAmt_TEST
    // END_USER_CODE-USER_txtStrgCreditAmt_TEST
  });
  test("txtStrgCreditAmtOverride(Textbox Widget) Test Cases", async () => {
    const txtStrgCreditAmtOverride = screen.getByTestId(
      "txtStrgCreditAmtOverride"
    );
    expect(txtStrgCreditAmtOverride.tagName).toBe("INPUT");
    expect(txtStrgCreditAmtOverride.type).toBe("text");
    expect(txtStrgCreditAmtOverride.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtStrgCreditAmtOverride, "123");
    });
    expect(txtStrgCreditAmtOverride.getAttribute("value")).toBe("");
    expect(txtStrgCreditAmtOverride.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStrgCreditAmtOverride", () => {
    // START_USER_CODE-USER_txtStrgCreditAmtOverride_TEST
    // END_USER_CODE-USER_txtStrgCreditAmtOverride_TEST
  });
  test("txtSupportPercent(Textbox Widget) Test Cases", async () => {
    const txtSupportPercent = screen.getByTestId("txtSupportPercent");
    expect(txtSupportPercent.tagName).toBe("INPUT");
    expect(txtSupportPercent.type).toBe("text");
    expect(txtSupportPercent.classList).toContain("textboxWidgetClass");
    expect(txtSupportPercent.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtSupportPercent")
    );
    await act(async () => {
      userEvent.type(txtSupportPercent, "123");
    });
    expect(txtSupportPercent.getAttribute("value")).toBe("");
    expect(txtSupportPercent.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSupportPercent", () => {
    // START_USER_CODE-USER_txtSupportPercent_TEST
    // END_USER_CODE-USER_txtSupportPercent_TEST
  });
  test("txtTotalDamage(Textbox Widget) Test Cases", async () => {
    const txtTotalDamage = screen.getByTestId("txtTotalDamage");
    expect(txtTotalDamage.tagName).toBe("INPUT");
    expect(txtTotalDamage.type).toBe("text");
    expect(txtTotalDamage.classList).toContain("textboxWidgetClass");
    expect(txtTotalDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtTotalDamage")
    );
    await act(async () => {
      userEvent.type(txtTotalDamage, "123");
    });
    expect(txtTotalDamage.getAttribute("value")).toBe("");
    expect(txtTotalDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDamage", () => {
    // START_USER_CODE-USER_txtTotalDamage_TEST
    // END_USER_CODE-USER_txtTotalDamage_TEST
  });
  test("txtTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels = screen.getByTestId("txtTotalKernels");
    expect(txtTotalKernels.tagName).toBe("INPUT");
    expect(txtTotalKernels.type).toBe("text");
    expect(txtTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernels.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtTotalKernels, "123");
    });
    expect(txtTotalKernels.getAttribute("value")).toBe("");
    expect(txtTotalKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernels", () => {
    // START_USER_CODE-USER_txtTotalKernels_TEST
    // END_USER_CODE-USER_txtTotalKernels_TEST
  });
  test("txtTotalKernHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernHulls = screen.getByTestId("txtTotalKernHulls");
    expect(txtTotalKernHulls.tagName).toBe("INPUT");
    expect(txtTotalKernHulls.type).toBe("text");
    expect(txtTotalKernHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernHulls.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtTotalKernHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernHulls, "123");
    });
    expect(txtTotalKernHulls.getAttribute("value")).toBe("");
    expect(txtTotalKernHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernHulls", () => {
    // START_USER_CODE-USER_txtTotalKernHulls_TEST
    // END_USER_CODE-USER_txtTotalKernHulls_TEST
  });
  test("txtTotalLbs(Textbox Widget) Test Cases", async () => {
    const txtTotalLbs = screen.getByTestId("txtTotalLbs");
    expect(txtTotalLbs.tagName).toBe("INPUT");
    expect(txtTotalLbs.type).toBe("text");
    expect(txtTotalLbs.classList).toContain("textboxWidgetClass");
    expect(txtTotalLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtTotalLbs")
    );
    await act(async () => {
      userEvent.type(txtTotalLbs, "123");
    });
    expect(txtTotalLbs.getAttribute("value")).toBe("");
    expect(txtTotalLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLbs", () => {
    // START_USER_CODE-USER_txtTotalLbs_TEST
    // END_USER_CODE-USER_txtTotalLbs_TEST
  });
  test("txtTotalLbsShrunk(Textbox Widget) Test Cases", async () => {
    const txtTotalLbsShrunk = screen.getByTestId("txtTotalLbsShrunk");
    expect(txtTotalLbsShrunk.tagName).toBe("INPUT");
    expect(txtTotalLbsShrunk.type).toBe("text");
    expect(txtTotalLbsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalLbsShrunk, "123");
    });
    expect(txtTotalLbsShrunk.getAttribute("value")).toBe("");
    expect(txtTotalLbsShrunk.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLbsShrunk", () => {
    // START_USER_CODE-USER_txtTotalLbsShrunk_TEST
    // END_USER_CODE-USER_txtTotalLbsShrunk_TEST
  });
  test("txtTotalSMK(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK = screen.getByTestId("txtTotalSMK");
    expect(txtTotalSMK.tagName).toBe("INPUT");
    expect(txtTotalSMK.type).toBe("text");
    expect(txtTotalSMK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtTotalSMK")
    );
    await act(async () => {
      userEvent.type(txtTotalSMK, "123");
    });
    expect(txtTotalSMK.getAttribute("value")).toBe("");
    expect(txtTotalSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSMK", () => {
    // START_USER_CODE-USER_txtTotalSMK_TEST
    // END_USER_CODE-USER_txtTotalSMK_TEST
  });
  test("txtTotalTons(Textbox Widget) Test Cases", async () => {
    const txtTotalTons = screen.getByTestId("txtTotalTons");
    expect(txtTotalTons.tagName).toBe("INPUT");
    expect(txtTotalTons.type).toBe("text");
    expect(txtTotalTons.classList).toContain("textboxWidgetClass");
    expect(txtTotalTons.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtTotalTons")
    );
    await act(async () => {
      userEvent.type(txtTotalTons, "123");
    });
    expect(txtTotalTons.getAttribute("value")).toBe("");
    expect(txtTotalTons.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalTons", () => {
    // START_USER_CODE-USER_txtTotalTons_TEST
    // END_USER_CODE-USER_txtTotalTons_TEST
  });
  test("txtTotalTonsShrunk(Textbox Widget) Test Cases", async () => {
    const txtTotalTonsShrunk = screen.getByTestId("txtTotalTonsShrunk");
    expect(txtTotalTonsShrunk.tagName).toBe("INPUT");
    expect(txtTotalTonsShrunk.type).toBe("text");
    expect(txtTotalTonsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalTonsShrunk, "123");
    });
    expect(txtTotalTonsShrunk.getAttribute("value")).toBe("");
    expect(txtTotalTonsShrunk.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalTonsShrunk", () => {
    // START_USER_CODE-USER_txtTotalTonsShrunk_TEST
    // END_USER_CODE-USER_txtTotalTonsShrunk_TEST
  });
  test("txtValueOfSeg(Textbox Widget) Test Cases", async () => {
    const txtValueOfSeg = screen.getByTestId("txtValueOfSeg");
    expect(txtValueOfSeg.tagName).toBe("INPUT");
    expect(txtValueOfSeg.type).toBe("text");
    expect(txtValueOfSeg.classList).toContain("textboxWidgetClass");
    expect(txtValueOfSeg.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtValueOfSeg")
    );
    await act(async () => {
      userEvent.type(txtValueOfSeg, "123");
    });
    expect(txtValueOfSeg.getAttribute("value")).toBe("");
    expect(txtValueOfSeg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfSeg", () => {
    // START_USER_CODE-USER_txtValueOfSeg_TEST
    // END_USER_CODE-USER_txtValueOfSeg_TEST
  });
  test("txtValuePerTon(Textbox Widget) Test Cases", async () => {
    const txtValuePerTon = screen.getByTestId("txtValuePerTon");
    expect(txtValuePerTon.tagName).toBe("INPUT");
    expect(txtValuePerTon.type).toBe("text");
    expect(txtValuePerTon.classList).toContain("textboxWidgetClass");
    expect(txtValuePerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtValuePerTon")
    );
    await act(async () => {
      userEvent.type(txtValuePerTon, "123");
    });
    expect(txtValuePerTon.getAttribute("value")).toBe("");
    expect(txtValuePerTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValuePerTon", () => {
    // START_USER_CODE-USER_txtValuePerTon_TEST
    // END_USER_CODE-USER_txtValuePerTon_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "123");
    });
    expect(txtVendorNum.getAttribute("value")).toBe("");
    expect(txtVendorNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
  test("txtVICAM(Textbox Widget) Test Cases", async () => {
    const txtVICAM = screen.getByTestId("txtVICAM");
    expect(txtVICAM.tagName).toBe("INPUT");
    expect(txtVICAM.type).toBe("text");
    expect(txtVICAM.classList).toContain("textboxWidgetClass");
    expect(txtVICAM.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtVICAM")
    );
    await act(async () => {
      userEvent.type(txtVICAM, "123");
    });
    expect(txtVICAM.getAttribute("value")).toBe("");
    expect(txtVICAM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVICAM", () => {
    // START_USER_CODE-USER_txtVICAM_TEST
    // END_USER_CODE-USER_txtVICAM_TEST
  });
  test("txtVndrName(Textbox Widget) Test Cases", async () => {
    const txtVndrName = screen.getByTestId("txtVndrName");
    expect(txtVndrName.tagName).toBe("INPUT");
    expect(txtVndrName.type).toBe("text");
    expect(txtVndrName.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndrName, "123");
    });
    expect(txtVndrName.getAttribute("value")).toBe("");
    expect(txtVndrName.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVndrName", () => {
    // START_USER_CODE-USER_txtVndrName_TEST
    // END_USER_CODE-USER_txtVndrName_TEST
  });
  test("txtWhseBin(Textbox Widget) Test Cases", async () => {
    const txtWhseBin = screen.getByTestId("txtWhseBin");
    expect(txtWhseBin.tagName).toBe("INPUT");
    expect(txtWhseBin.type).toBe("text");
    expect(txtWhseBin.classList).toContain("textboxWidgetClass");
    expect(txtWhseBin.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtWhseBin")
    );
    await act(async () => {
      userEvent.type(txtWhseBin, "123");
    });
    expect(txtWhseBin.getAttribute("value")).toBe("");
    expect(txtWhseBin.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWhseBin", () => {
    // START_USER_CODE-USER_txtWhseBin_TEST
    // END_USER_CODE-USER_txtWhseBin_TEST
  });
  test("txtWtdAvgStorageDt(Textbox Widget) Test Cases", async () => {
    const txtWtdAvgStorageDt = screen.getByTestId("txtWtdAvgStorageDt");
    expect(txtWtdAvgStorageDt.tagName).toBe("INPUT");
    expect(txtWtdAvgStorageDt.type).toBe("text");
    expect(txtWtdAvgStorageDt.classList).toContain("textboxWidgetClass");
    expect(txtWtdAvgStorageDt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExtReceiptForfeituresProfile_txtWtdAvgStorageDt")
    );
    await act(async () => {
      userEvent.type(txtWtdAvgStorageDt, "123");
    });
    expect(txtWtdAvgStorageDt.getAttribute("value")).toBe("");
    expect(txtWtdAvgStorageDt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtdAvgStorageDt", () => {
    // START_USER_CODE-USER_txtWtdAvgStorageDt_TEST
    // END_USER_CODE-USER_txtWtdAvgStorageDt_TEST
  });
});
