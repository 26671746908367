/* eslint-disable*/
import { ApiService } from '../../Common/ApiService';

export const ContractManagementService = {
  RetrieveCropYears,
  RetrieveCompanyDetails,
  RetrieveCompanyStats,
  RetrieveUserRoles,
  RetrievePeanutTypeControls,
  RetrievePeanutVarietyControls,
  RetrieveDeliveryPeriodControlDetails,
  RetrieveAccountDistributionActivityDetails,
  RetrieveAccessPermissionDetails,
  RetrievePeanutStateControlDetails,
  RetrieveVendorByNumber,
  RetieveRemitDetails,
  CreateContract,
  RetrieveContractExceptionPremiumMasterDetails,
  CreateContractExceptionPremium,
  RetrieveContractExceptionPremiumDetails,
  UpdateContractExceptionPremium,
  RemoveContractExceptionPremium,
  RetrieveBuyingPointControlDetails,
  UpdateContractGroup,
  RetrieveVendorsByName,
  RetrievePeanutVarietyControlFilteredDetails,
  RetrievePeanutPriceDetails,
  //RetrieveContractDetails1,
  RetrieveContractGroupDetails,
  RetrivePremiumDeductionScheduleDetails,
  RetrieveExceptionPremiumRequestDetails,
  //RetrieveContractdetails2,
  RetrieveReadyToPayDetails,
  RetrievePaymentActivityDetails,
  RetrieveFlexMarketDetails,
  RetriveContractList,
  RetieveContractUploadFileDetails,
  RetrieveContractDetails,
  RetrieveExceptionPremiumRequests,
  RetrieveExceptionPremiumAvailable1007Details,
  UpdateExceptionPremiumRequest,
  CreateExceptionPremium,
  CreateExceptionPremiumRequestDetails,
  RetrieveExceptionPremiumDetails,
  UpdateContract,
  RetrieveFlexMarketPriceElectionPrint,
  RetrieveFlexMarketPriceElectionRescanDetails,
  RetrieveUserControlDetails,
  RetrieveFlexMarketPriceElectionSignDetails,  
  UploadFtpFile,
  RetrieveApplicationContractDetails,
  RetrieveVendorEmailDetails,
  UpdateContractEmail,
  RetrieveUserSignatureDetails,
  UpdateFlexMarketPriceElectionApproveDetails,
  RetrieveUserSignatureDetailsFlexMktPricing,
  RetrieveFlexMarketPriceElectionDetails,
  RetrieveFlexMarketPriceElectionVoidDetails,
  RetrieveContractSign,
  UpdateExceptionPremiumDetails,
  RemoveExceptionPremium,
  UpdateClearContractAdvanceDetails,
  RetrievePeanutStaticValues,
  UpdateVendorEmails,
  Createflexmarket,
  RetrieveCountyControlDetails,
  RetrieveBatchCheckReadyToPayDetails,
  RetrieveBuyingPointControlList,
  RetrieveFreightAccrualPayDetails,
  CreateInspectUploadLogDetails,
  RetrieveFarmVendorSplitDetails,
  RetrieveFarmPickDetails,
  RetrievePeanutPriceScheduleDetails,
  RetrieveFarmControlDetails,
  RetrieveUserControlDetailsImproved
};

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const cnt_mngmt = '/contract-management/v1/';

export function RetrieveCropYears() {
  return ApiService.getData(isLocal() ? '/Common/RetrieveCropYears/years' : `${cnt_mngmt}years`);
  //return ApiService.getData('${cnt_mngmt}years');
}

export function RetrieveCompanyDetails(CompId) {
  let companyId = (CompId !== null && CompId !== undefined) ? CompId : null;
  return ApiService.getData(isLocal() ? `/Common/RetrieveCompanyDetails/companies/${companyId}` : `${cnt_mngmt}companies/${companyId}`);
  //return ApiService.getData(`${cnt_mngmt}companies/${companyId}`);
}

export function RetrieveCompanyStats() {
  return ApiService.getData(isLocal() ? `/Common/RetrieveStatisticDetails/statistics` : `${cnt_mngmt}statistics`);
}

export function RetrieveUserRoles(CompId, UserId, CropYear) {
  return ApiService.getData(isLocal() ? `/Common/RetrieveUserFunctions/companies/${CompId}/users/${UserId}/crop-years/${CropYear}/functions` : `${cnt_mngmt}companies/${CompId}/users/${UserId}/crop-years/${CropYear}/functions`);
}

export function RetrievePeanutTypeControls(pnut_type_id) {
  let optionsParamsURL = '';
  if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
    optionsParamsURL += `pnut_type_id=${pnut_type_id}`;
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrievePeanutTypeControls/types?${optionsParamsURL}` : `${cnt_mngmt}types?${optionsParamsURL}`);
}

export function RetrievePeanutVarietyControls(buyingPointId, collectionPointId, areaId, pnut_type_id, pnut_variety_id) {
  let optionsParamsURL = '';
  if (buyingPointId !== '' && buyingPointId !== null && buyingPointId !== undefined) {
    optionsParamsURL += `buy_pt_id=${buyingPointId}`;
  }

  if (collectionPointId !== '' && collectionPointId !== null && collectionPointId !== undefined) {
    optionsParamsURL += `&coll_pt_id=${collectionPointId}`;
  }

  if (areaId !== '' && areaId !== null && areaId !== undefined) {
    optionsParamsURL += `&area_id=${areaId}`;
  }

  if (pnut_type_id !== '' && pnut_type_id !== null && pnut_type_id !== undefined) {
    optionsParamsURL += `&pnut_type_id=${pnut_type_id}`;
  }

  if (pnut_variety_id != '' && pnut_variety_id !== null && pnut_variety_id !== undefined) {
    optionsParamsURL += `&pnut_variety_id=${pnut_variety_id}`;
  }

  return ApiService.getData(isLocal() ? `/ContractManagement/RetrievePeanutVarietyControls/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/varieties?${optionsParamsURL}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/varieties?${optionsParamsURL}`);
}

export function RetrieveDeliveryPeriodControlDetails(buyingPointId, areaid, deliveryid) {
  let optionsParamsURL = '';
  if (buyingPointId !== null && buyingPointId !== undefined) {
    optionsParamsURL += `buyingPointId=${buyingPointId}`;
  }

  if (areaid !== null && areaid !== undefined) {
    optionsParamsURL += `&areaid=${areaid}`;
  }

  if (deliveryid !== null && deliveryid !== undefined) {
    optionsParamsURL += `&deliveryid=${deliveryid}`;
  }

  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveDeliveryPeriodControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/periods?${optionsParamsURL}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/periods?${optionsParamsURL}`);
}

export function RetrieveAccessPermissionDetails(func_id, func_sub_id, access, buy_pt_id) {
  let optionsParamsURL = '';
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionsParamsURL += `&buy_pt_id=${buy_pt_id}`;
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveAccessPermissionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/sub-functions/${func_sub_id}/access-types/${access}/users/${useridFromLS()}/permissions?${optionsParamsURL}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/sub-functions/${func_sub_id}/access-types/${access}/users/${useridFromLS()}/permissions?${optionsParamsURL}`);
}

export function RetrievePeanutStateControlDetails(buyingPointId, stateAbbrevations, filterStatus) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrievePeanutStateControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/states?filter=${filterStatus}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/states?filter=${filterStatus}`);
}

export function RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num) 
{
  let optionalParamsUrl  = '';
  if (compIdFromLS() !== null && compIdFromLS()  !== '' && compIdFromLS()  !== 0) {
    optionalParamsUrl  += `comp_id=${compIdFromLS()}&`;
  }
  if (cropYearFromLS() !== null && cropYearFromLS()!== '' && cropYearFromLS() !== 0) {
    optionalParamsUrl  += `crop_year=${cropYearFromLS()}&`;
  }
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== 0) {
    optionalParamsUrl  += `buy_pt_id=${buy_pt_id}&`;
  }
  if (audit_insp_num !== null && audit_insp_num !== '' && audit_insp_num !== 0) {
    optionalParamsUrl  += `audit_insp_num=${audit_insp_num}&`;
  }
  if (audit_whse_rcpt !== null && audit_whse_rcpt !== '' && audit_whse_rcpt !== 0) {
    optionalParamsUrl  += `audit_whse_rcpt=${audit_whse_rcpt}&`;
  }
  if (audit_xfer_num !== null && audit_xfer_num !== '' && audit_xfer_num !== 0) {
    optionalParamsUrl  += `audit_xfer_num=${audit_xfer_num}&`;
  }
  if (audit_buy_pt !== null && audit_buy_pt !== '' && audit_buy_pt !== 0) {
    optionalParamsUrl  += `audit_buy_pt=${audit_buy_pt}&`;
  }
  if (audit_receivable_num !== null && audit_receivable_num !== '' && audit_receivable_num !== 0) {
    optionalParamsUrl  += `audit_receivable_num=${audit_receivable_num}&`;
  }
  if (audit_check_num !== null && audit_check_num !== '' && audit_check_num !== 0) {
    optionalParamsUrl  += `audit_check_num=${audit_check_num}&`;
  }
  if (audit_trade_insp !== null && audit_trade_insp !== '' && audit_trade_insp !== 0) {
    optionalParamsUrl  += `audit_trade_insp=${audit_trade_insp}&`;
  }
  if (audit_cont_num !== null && audit_cont_num !== '' && audit_cont_num !== 0) {
    optionalParamsUrl  += `audit_cont_num=${audit_cont_num}&`;
  }
  if (audit_delv_agree_num !== null && audit_delv_agree_num !== '' && audit_delv_agree_num !== 0) {
    optionalParamsUrl  += `audit_delv_agree_num=${audit_delv_agree_num}&`;
  }

  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveAccountDistributionActivityDetails/activities?${optionalParamsUrl}`:`${cnt_mngmt}activities?${optionalParamsUrl}`);
}
export function RetrieveVendorByNumber(vendorId) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveVendorByNumber/vendornumbers/${vendorId}` : `${cnt_mngmt}vendornumbers/${vendorId}`);
}

export function RetieveRemitDetails(vendorId) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetieveRemitDetails/companies/${compIdFromLS()}/vendors/${vendorId}/remits` : `${cnt_mngmt}companies/${compIdFromLS()}/vendors/${vendorId}/remits`);
}

export function CreateContract(peanutTypeId, buyingPointId, data) {
  return ApiService.postData(isLocal() ? `/ContractManagement/CreateContract/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/peanut-types/${peanutTypeId}/buying-points/${buyingPointId}/contracts` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/peanut-types/${peanutTypeId}/buying-points/${buyingPointId}/contracts`, data);
}

export function RetrieveContractExceptionPremiumMasterDetails(buY_PT_ID) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractExceptionPremiumMasterDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/premiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/premiums`);
}

export function CreateContractExceptionPremium(data) {
  return ApiService.postData(isLocal() ? `/ContractManagement/CreateContractExceptionPremium/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buY_PT_ID}/contracts/${data.contract_num}/exceptionpremiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buY_PT_ID}/contracts/${data.contract_num}/exceptionpremiums`, data)
}

export function RetrieveContractExceptionPremiumDetails(buY_PT_ID, contract_num,pd_code) {
  let optionsParamsURL = '';
  if (pd_code !== null && pd_code !== '' && pd_code !== undefined) {
    optionsParamsURL += `pd_code=${pd_code}`;
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractExceptionPremiumDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/contracts/${contract_num}/premiums?${optionsParamsURL}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/contracts/${contract_num}/premiums?${optionsParamsURL}`);
}

export function UpdateContractExceptionPremium(data) {
  return ApiService.updateData(isLocal() ? `/ContractManagement/UpdateContractExceptionPremium/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buY_PT_ID}/contracts/${data.contract_num}/codes/${data.pD_CODE}/premiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buY_PT_ID}/contracts/${data.contract_num}/codes/${data.pD_CODE}/premiums`, data)
}

export function RemoveContractExceptionPremium(buY_PT_ID, contract_num, pD_CODE) {
  return ApiService.removeData(isLocal() ? `/ContractManagement/RemoveContractExceptionPremium/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/contracts/${contract_num}` + "?pd_code=" + pD_CODE : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/contracts/${contract_num}?pd_code=${pD_CODE}`)
}

export function RetrieveBuyingPointControlDetails(func_id, func_sub_id, area_id, coll_pt_id, buy_pt_id) {
  let optionsParamsURL = '';
  if (func_sub_id !== null && func_sub_id !== '' && func_sub_id !== undefined) {
    optionsParamsURL += `func_sub_id=${func_sub_id}`;
  }
  if (area_id !== null && area_id !== '' && area_id !== undefined) {
    optionsParamsURL += `&area_id=${area_id}`;
  }
  if (coll_pt_id !== null && coll_pt_id !== '' && coll_pt_id !== undefined) {
    optionsParamsURL += `&coll_pt_id=${coll_pt_id}`;
  }
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionsParamsURL += `&buy_pt_id=${buy_pt_id}`;
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveBuyingPointControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/locations?${optionsParamsURL}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/locations?${optionsParamsURL}`);
}

export function RetrieveVendorsByName(searchString) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveVendorsByName/vendors/${searchString}` : `${cnt_mngmt}vendors/${searchString}`);
}

export function RetrivePremiumDeductionScheduleDetails(buy_pt_id, pd_code, inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind) {
  let url = isLocal() ? `/ContractManagement/RetrivePremiumDeductionScheduleDetails/schedules?` : `${cnt_mngmt}schedules?`;
  url = url + 'comp_id=' + compIdFromLS();
  url = url + '&crop_year=' + cropYearFromLS();
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    url = url + '&buy_pt_id=' + buy_pt_id;
  }
  if (pd_code !== null && pd_code !== undefined && pd_code !== "") {
    url = url + '&pd_code=' + pd_code;
  }
  if (inspect_ind !== null && inspect_ind !== undefined && inspect_ind !== "") {
    url = url + '&inspect_ind=' + inspect_ind;
  }
  if (rate_type !== null && rate_type !== undefined && rate_type !== "") {
    url = url + '&rate_type=' + rate_type;
  }
  if (check_enable_ind !== null && check_enable_ind !== undefined && check_enable_ind !== "") {
    url = url + '&check_enable_ind=' + check_enable_ind;
  }
  if (rcpt_enable_ind !== null && rcpt_enable_ind !== undefined && rcpt_enable_ind !== "") {
    url = url + '&rcpt_enable_ind=' + rcpt_enable_ind;
  }
  if (rpt_1099_ind !== null && rpt_1099_ind !== undefined && rpt_1099_ind !== "") {
    url = url + '&rpt_1099_ind=' + rpt_1099_ind;
  }
  if (except_prem_ind !== null && except_prem_ind !== undefined && except_prem_ind !== "") {
    url = url + '&except_prem_ind=' + except_prem_ind;
  }
  return ApiService.getData(url);
}

export function RetrievePeanutVarietyControlFilteredDetails(buy_pt_id, pnut_type_id) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrievePeanutVarietyControlFilteredDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/filteredvarieties?buy_pt_id=${buy_pt_id}&pnut_type_id=${pnut_type_id}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/filteredvarieties?buy_pt_id=${buy_pt_id}&pnut_type_id=${pnut_type_id}`)
}

export function RetrievePeanutPriceDetails(typeid, pnuttypeid, segTypeid, organicInd, buyptid, contractnum, pnutvarietyid, oleicind, vendornum) {
  let optionalParamsUrl = '';
  if (contractnum !== null && contractnum !== undefined && contractnum !== "") {
    optionalParamsUrl += `contractnum=${contractnum}&`
  }
  if (pnutvarietyid !== null && pnutvarietyid !== undefined && pnutvarietyid !== "") {
    optionalParamsUrl += `pnutvarietyid=${pnutvarietyid}&`
  }
  if (oleicind !== null && oleicind !== undefined && oleicind !== "" && oleicind !== 'N') {
    optionalParamsUrl += `oleicind=${oleicind}&`
  }
  if (vendornum !== null && vendornum !== undefined && vendornum !== "") {
    optionalParamsUrl += `vendornum=${vendornum}`
  }
  
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrievePeanutPriceDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/pricing-types/${typeid}/peanut-types/${pnuttypeid}/segment-types/${segTypeid}/organic-indicators/${organicInd}/buying-points/${buyptid}/prices?${optionalParamsUrl}`: `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/pricing-types/${typeid}/peanut-types/${pnuttypeid}/segment-types/${segTypeid}/organic-indicators/${organicInd}/buying-points/${buyptid}/prices?${optionalParamsUrl}`);
}

// export function RetrieveContractDetails1(contractNum, funcId) {
//   return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contractNum}/functions/${funcId}/users/${useridFromLS()}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contractNum}/functions/${funcId}/users/${useridFromLS()}/contractdetails`);
// }

export function RetrieveContractGroupDetails(func_id, func_sub_id, grouping_num, contract_num, buy_pt_id, vendor_number, pnut_type_id, pnut_variety_id, seg_type, oleic_ind, seed_grower, organic_ind) {
  let optionalParamsUrl = '';
  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id != "") {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`
  }
  if (grouping_num !== null && grouping_num !== undefined && grouping_num !== "") {
    optionalParamsUrl += `grouping_num=${grouping_num}&`
  }
  if (contract_num !== null && contract_num !== undefined && contract_num !== "") {
    optionalParamsUrl += `contract_num=${contract_num}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (vendor_number !== null && vendor_number !== undefined && vendor_number !== "") {
    optionalParamsUrl += `vendor_number=${vendor_number}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (seed_grower !== null && seed_grower !== undefined && seed_grower !== "") {
    optionalParamsUrl += `seed_grower=${seed_grower}&`
  }
  if (organic_ind !== null && organic_ind !== undefined && organic_ind !== "") {
    optionalParamsUrl += `organic_ind=${organic_ind}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractGroupDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/groups?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/groups?${optionalParamsUrl}`);
}
export function RetrieveExceptionPremiumDetails(buy_pt_id, prem_vendor, prem_code, farm_id, farm_suffix, contract_num, pnut_type_id, pnut_variety_id, seed_ind, seed_gen, oleic_ind, seg_type) {
  let optionalParamsUrl = '';
  if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "") {
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`
  }
  if (contract_num !== null && contract_num !== undefined && contract_num !== "") {
    optionalParamsUrl += `contract_num=${contract_num}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (seed_ind !== null && seed_ind !== undefined && seed_ind !== "") {
    optionalParamsUrl += `seed_ind=${seed_ind}&`
  }
  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveExceptionPremiumDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${prem_vendor}/codes/${prem_code}/premiums?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${prem_vendor}/codes/${prem_code}/premiums?${optionalParamsUrl}`);
}

export function RetrieveExceptionPremiumRequestDetails(request_memo_num, buy_pt_id, insp_num, settle_num, prem_vendor, farm_id, farm_suffix, seg_type, pnut_type_id, pnut_variety_id, oleic_ind, seed_ind, seed_gen, contract_num, request_status, prem_code) {
  let optionalParamsUrl = '';
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (insp_num !== null && insp_num !== undefined && insp_num !== "") {
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  if (settle_num !== null && settle_num !== undefined && settle_num !== "") {
    optionalParamsUrl += `settle_num=${settle_num}&`
  }
  if (prem_vendor !== null && prem_vendor !== undefined && prem_vendor !== "") {
    optionalParamsUrl += `prem_vendor=${prem_vendor}&`
  }
  if (request_memo_num !== null && request_memo_num !== undefined && request_memo_num !== "") {
    optionalParamsUrl += `request_memo_num=${request_memo_num}&`
  }
  if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "") {
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (seed_ind !== null && seed_ind !== undefined && seed_ind !== "") {
    optionalParamsUrl += `seed_ind=${seed_ind}&`
  }
  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (contract_num !== null && contract_num !== undefined && contract_num !== "") {
    optionalParamsUrl += `contract_num=${contract_num}&`
  }
  if (request_status !== null && request_status !== undefined && request_status !== "") {
    optionalParamsUrl += `request_status=${request_status}&`
  }
  if (prem_code !== null && prem_code !== undefined && prem_code !== "") {
    optionalParamsUrl += `prem_code=${prem_code}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveExceptionPremiumRequestDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/premiums?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/premiums?${optionalParamsUrl}`);
}
export function UpdateContractGroup(action_type, grouping_num, data) {
  let optionalParamsUrl = '';
  if (grouping_num !== null && grouping_num !== undefined && grouping_num !== "") {
    optionalParamsUrl += `grouping_num=${grouping_num}&`
  }
  return ApiService.updateData(isLocal() ? `/ContractManagement/UpdateContractGroup/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/groupings?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/groupings?${optionalParamsUrl}`, data);
}
// export function RetrieveContractdetails2(contract_num, func_id, useridFromLS) {
//   return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractdetails/companies/${compIdFromLS}/crop-years/${cropYearFromLS}/contracts/${contract_num}/functions/${func_id}/users/${useridFromLS}` : `${cnt_mngmt}companies/${compIdFromLS}/crop-years/${cropYearFromLS}/contracts/${contract_num}/functions/${func_id}/users/${useridFromLS}`);
// }

export function RetrieveReadyToPayDetails(buy_pt_id, audit_cont_num,payee_vendor,audit_farm_id,audit_insp_num,rtp_status,frt_accrual_ind,posneg_ind) {
  let optionalParamsUrl = '';

  optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  optionalParamsUrl += `crop_Year=${cropYearFromLS()}&`

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (audit_cont_num !== null && audit_cont_num !== undefined && audit_cont_num != "" && audit_cont_num > 0) {
    optionalParamsUrl += `audit_cont_num=${audit_cont_num}&`
  }
  if (payee_vendor !== null && payee_vendor !== undefined && payee_vendor != "") {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  if (audit_farm_id !== null && audit_farm_id !== undefined && audit_farm_id != "") {
    optionalParamsUrl += `audit_farm_id=${audit_farm_id}&`
  }
  if (audit_insp_num !== null && audit_insp_num !== undefined && audit_insp_num != "") {
    optionalParamsUrl += `audit_insp_num=${audit_insp_num}&`
  }
  if (rtp_status !== null && rtp_status !== undefined && rtp_status != "") {
    optionalParamsUrl += `rtp_status=${rtp_status}&`
  }
  if (frt_accrual_ind !== null && frt_accrual_ind !== undefined && frt_accrual_ind != "") {
    optionalParamsUrl += `frt_accrual_ind=${frt_accrual_ind}&`
  }
  if (posneg_ind !== null && posneg_ind !== undefined && posneg_ind != "") {
    optionalParamsUrl += `posneg_ind=${posneg_ind}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveReadyToPayDetails/payments?${optionalParamsUrl}` : `${cnt_mngmt}payments?${optionalParamsUrl}`);
}
export function RetrievePaymentActivityDetails(buy_pt_id, check_num, audit_settle_num, audit_cont_num, audit_delv_agree_num, audit_trade_insp, audit_insp_num, audit_trade_settle, frt_accrual_ind, payee_vendor, payee_remit, issued_date) {
  let optionalParamsUrl = '';
  optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  optionalParamsUrl += `crop_Year=${cropYearFromLS()}&`
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
   }
   if (check_num !== null && check_num !== undefined && check_num != "" && audit_settle_num > 0) {
   optionalParamsUrl += `check_num=${check_num}&`
    }
  if (audit_settle_num !== null && audit_settle_num !== undefined && audit_settle_num != "" && audit_settle_num > 0) {
  optionalParamsUrl += `audit_settle_num=${audit_settle_num}&`
  }
  if (audit_cont_num !== null && audit_cont_num !== undefined && audit_cont_num != "" && audit_cont_num > 0) {
  optionalParamsUrl += `audit_cont_num=${audit_cont_num}&`
   }
  if (audit_delv_agree_num !== null && audit_delv_agree_num !== undefined && audit_delv_agree_num != "" && audit_delv_agree_num) {
  optionalParamsUrl += `audit_delv_agree_num=${audit_delv_agree_num}&`
      }
  if (audit_trade_insp !== null && audit_trade_insp !== undefined && audit_trade_insp != "" && audit_trade_insp) {
  optionalParamsUrl += `audit_trade_insp=${audit_trade_insp}&`
  }
  if (audit_insp_num !== null && audit_insp_num !== undefined && audit_insp_num != "" && audit_insp_num) {
    optionalParamsUrl += `audit_insp_num=${audit_insp_num}&`
  }
  if (audit_trade_settle !== null && audit_trade_settle !== undefined && audit_trade_settle != "" && audit_trade_settle) {
    optionalParamsUrl += `audit_trade_settle=${audit_trade_settle}&`
  }
  if (frt_accrual_ind !== null && frt_accrual_ind !== undefined && frt_accrual_ind != "" && frt_accrual_ind) {
    optionalParamsUrl += `frt_accrual_ind=${frt_accrual_ind}&`
  }
  if (payee_vendor !== null && payee_vendor !== undefined && payee_vendor != "" && payee_vendor) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  if (payee_remit !== null && payee_remit !== undefined && payee_remit != "" && payee_remit) {
    optionalParamsUrl += `payee_remit=${payee_remit}&`
  }
  if (issued_date !== null && issued_date !== undefined && issued_date != "" && issued_date) {
    optionalParamsUrl += `issued_date=${issued_date}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrievePaymentActivityDetails/paymentactivities?${optionalParamsUrl}` : `${cnt_mngmt}paymentactivities?${optionalParamsUrl}`);
  }
  
export function RetrieveFlexMarketDetails(contract) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveFlexMarketDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract}/markets` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract}/markets`)
}

export function RetriveContractList(func_id, func_sub_id, contract_num,
  gen_produced, split_vendor, cont_status, cont_type, buy_pt_id, state_abbr, county_id, farm_id,
  pnut_type_id, pnut_variety_id, seg_type, seed_grower, oleic_ind, organic_ind, beg_cont_date, end_cont_date,
  grouping_num, disclosure) {
  let fullurl = isLocal() ? '/ContractManagement/RetriveContractList/companies/' + `${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/contracts?` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/contracts?`;
  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id !== "") {
    fullurl = fullurl + `func_sub_id=${func_sub_id}&`
  }
  if (contract_num !== null && contract_num !== undefined && contract_num !== "") {
    fullurl = fullurl + `contract_num=${contract_num}&`
  }
  if (gen_produced !== null && gen_produced !== undefined && gen_produced !== "") {
    fullurl = fullurl + `gen_produced=${gen_produced}&`
  }
  if (split_vendor !== null && split_vendor !== undefined && split_vendor !== "") {
    fullurl = fullurl + `split_vendor=${split_vendor}&`
  }
  if (cont_status !== null && cont_status !== undefined && cont_status !== "") {
    fullurl = fullurl + `cont_status=${cont_status}&`
  }
  if (cont_type !== null && cont_type !== undefined && cont_type !== "") {
    fullurl = fullurl + `cont_type=${cont_type}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    fullurl = fullurl + `buy_pt_id=${buy_pt_id}&`
  }
  if (state_abbr !== null && state_abbr !== undefined && state_abbr !== "") {
    fullurl = fullurl + `state_abbr=${state_abbr}&`
  }
  if (county_id !== null && county_id !== undefined && county_id !== "") {
    fullurl = fullurl + `county_id=${county_id}&`
  }
  if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
    fullurl = fullurl + `farm_id=${farm_id}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    fullurl = fullurl + `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    fullurl = fullurl + `pnut_variety_id=${pnut_variety_id}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    fullurl = fullurl + `seg_type=${seg_type}&`
  }
  if (seed_grower !== null && seed_grower !== undefined && seed_grower !== "") {
    fullurl = fullurl + `seed_grower=${seed_grower}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    fullurl = fullurl + `oleic_ind=${oleic_ind}&`
  }
  if (organic_ind !== null && organic_ind !== undefined && organic_ind !== "") {
    fullurl = fullurl + `organic_ind=${organic_ind}&`
  }
  if (beg_cont_date !== null && beg_cont_date !== undefined && beg_cont_date !== "") {
    fullurl = fullurl + `beg_cont_date=${beg_cont_date}&`
  }
  if (end_cont_date !== null && end_cont_date !== undefined && end_cont_date !== "") {
    fullurl = fullurl + `end_cont_date=${end_cont_date}&`
  }
  if (grouping_num !== null && grouping_num !== undefined && grouping_num !== "") {
    fullurl = fullurl + `grouping_num=${grouping_num}&`
  }
  if (disclosure !== null && disclosure !== undefined && disclosure !== "") {
    fullurl = fullurl + `disclosure=${disclosure}`
  }
  return ApiService.getData(fullurl)
}

export function RetrieveExceptionPremiumRequests(request_memo_num, order_param) {
  let optionalParamsUrl = '';
  if (order_param !== null && order_param !== undefined && order_param != "") {
    optionalParamsUrl += `order_param=${order_param}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveExceptionPremiumRequests/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/request-memos/${request_memo_num}/requests?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/request-memos/${request_memo_num}/requests?${optionalParamsUrl}`)
}

export function RetrieveExceptionPremiumAvailable1007Details(buy_pt_id, prem_vendor, prem_code, farm_id, farm_suffix, contract_num, insp_num, pnut_type_id, pnut_variety_id, seed_ind, seed_gen, seg_type, oleic_ind) {
  let optionalParamsUrl = '';
  if (farm_id !== null && farm_id !== undefined && farm_id != "") {
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "") {
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`
  }
  if (contract_num !== null && contract_num !== undefined && contract_num !== "") {
    optionalParamsUrl += `contract_num=${contract_num}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (seed_ind !== null && seed_ind !== undefined && seed_ind !== "") {
    optionalParamsUrl += `seed_ind=${seed_ind}&`
  }
  if (insp_num !== null && insp_num !== undefined && insp_num !== "") {
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveExceptionPremiumAvailable1007Details/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${prem_vendor}/codes/${prem_code}/exceptionpremiums?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${prem_vendor}/codes/${prem_code}/exceptionpremiums?${optionalParamsUrl}`)
}
export function UpdateExceptionPremiumRequest(request_memo_num, type, data) {
  return ApiService.updateData(isLocal() ? `/ContractManagement/UpdateExceptionPremiumRequest/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/requests/${request_memo_num}/action-types/${type}/premiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/requests/${request_memo_num}/action-types/${type}/premiums`, data)
}

export function CreateExceptionPremium(data) {
  return ApiService.postData(isLocal() ? `/ContractManagement/CreateExceptionPremium/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buY_PT_ID}/inspections/${data.insp_num}/vendors/${data.prem_vendor}/codes/${data.prem_code}/contracts/${data.contract_num}/premiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${data.buY_PT_ID}/inspections/${data.insp_num}/vendors/${data.prem_vendor}/codes/${data.prem_code}/contracts/${data.contract_num}/premiums`, data)
}
export function CreateExceptionPremiumRequestDetails(dataObj) {
  return ApiService.postData(isLocal() ? `/ContractManagement/CreateExceptionPremiumRequestDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/transactions/${null}/action-types/${dataObj.type}/premiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/transactions/${null}/action-types/${dataObj.type}/premiums`, dataObj)
}
export function RetieveContractUploadFileDetails(contract_num) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetieveContractUploadFileDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/files` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/files`)
}
//retrivecontractread method with 6 parameters , others 2 have 5 parameters
export function RetrieveContractDetails(contract_num,func_id,func_sub_id){
  let optionalParamsUrl = '';
  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id != "") { 
    optionalParamsUrl += `func_sub_id=${func_sub_id}`;
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/functions/${func_id}/users/${useridFromLS()}/contractdetails?${optionalParamsUrl}`: `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/functions/${func_id}/users/${useridFromLS()}/contractdetails?${optionalParamsUrl}`)
}

export function UpdateContract(numbers, dataObj) {
  return ApiService.updateData(isLocal() ? `/ContractManagement/UpdateContract/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/numbers/${numbers}/contracts` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/numbers/${numbers}/contracts`, dataObj);
}

// export function RetriveContractRead(comp_id, crop_year, contract_num, func_id, uid, func_sub_id) {
//   return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveContractDetails/companies/${comp_id}/crop-years/${crop_year}/contracts/${contract_num}/functions/${func_id}/users/${uid}/contractdetails?${func_sub_id}` : `${cnt_mngmt}companies/${comp_id}/crop-years/${crop_year}/contracts/${contract_num}/functions/${func_id}/users/${uid}/contractdetails?${func_sub_id}`)
// }


export function RetrieveVendorEmailDetails(buy_pt_id, orderbyname, vnumberfull, vendor, vname, vnumber) {
  let optionalParamsUrl = '';
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (orderbyname !== null && orderbyname !== undefined && orderbyname !== "") {
    optionalParamsUrl += `orderbyname=${orderbyname}&`
  }
  if (vnumberfull !== null && vnumberfull !== undefined && vnumberfull !== "") {
    optionalParamsUrl += `vnumberfull=${vnumberfull}&`
  }
  if (vendor !== null && vendor !== undefined && vendor !== "") {
    optionalParamsUrl += `vendor=${vendor}&`
  }
  if (vname !== null && vname !== undefined && vname !== "") {
    optionalParamsUrl += `vname=${vname}&`
  }
  if (vnumber !== null && vnumber !== undefined && vnumber !== "") {
    optionalParamsUrl += `vnumber=${vnumber}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetieveVendorEmailDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/emails?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/emails?${optionalParamsUrl}`);
}

export function UpdateContractEmail(contractNumber, emailInd) {
  return ApiService.updateData(isLocal() ? `/ContractManagement/UpdateContractEmail/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contractNumber}/emails` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contractNumber}/emails`, emailInd);
}

//This method used in Contract Profile and User Id is not logged in user in this case, so do not remove userid from input parameter
export function RetrieveUserSignatureDetails(userid) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveUserSignatureDetails/companies/${compIdFromLS()}/users/${userid}/signatures` : `${cnt_mngmt}companies/${compIdFromLS()}/users/${userid}/signatures`);
}

export function UpdateFlexMarketPriceElectionApproveDetails(cont_num,price_suffix,send_email) {
  let optionalParamsUrl = '';
  if (send_email !== null && send_email !== undefined && send_email != "") { 
    optionalParamsUrl += `send_email=${send_email}`;
  }
  return ApiService.updateData(
    isLocal()
      ? `/ContractManagement/UpdateFlexMarketPriceElectionApproveDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${cont_num}/prices/${price_suffix}/users/${useridFromLS()}/elections?${optionalParamsUrl}`
      : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${cont_num}/prices/${price_suffix}/users/${useridFromLS()}/elections?${optionalParamsUrl}`
  );
}

export function RetrieveUserSignatureDetailsFlexMktPricing() {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveUserSignatureDetails/companies/${compIdFromLS()}/users/${useridFromLS()}/signatures` : `${cnt_mngmt}companies/${compIdFromLS()}/users/${useridFromLS()}/signatures`)
}

export function RetrieveFlexMarketPriceElectionDetails(contract, price_suffix) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveFlexMarketPriceElectionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract}/prices/${price_suffix}/elections` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract}/prices/${price_suffix}/elections`)
}

export function RetrieveFlexMarketPriceElectionVoidDetails(cont_num, price_suffix) {
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveFlexMarketPriceElectionVoidDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${cont_num}/prices/${price_suffix}/users/${useridFromLS()}/voids` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${cont_num}/prices/${price_suffix}/users/${useridFromLS()}/voids`)
}
export function RetrievePeanutPriceScheduleDetails(area_id, pnut_type_id) {
  let optionalParamsUrl = "";
  if (area_id !== null && area_id !== undefined && area_id !== "") {
    optionalParamsUrl += `area_id=${area_id}&`;
  }
  if (
    pnut_type_id !== null &&
    pnut_type_id !== undefined &&
    pnut_type_id !== ""
  ) {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
  }
  return ApiService.getData(
    isLocal()
      ? `/ContractManagement/RetievePriceScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/schedules?${optionalParamsUrl}`
      : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/schedules?${optionalParamsUrl}`
  );
}

export function RetrieveContractSign(contract_num) {
  return ApiService.getData(
    isLocal()
      ? `/ContractManagement/RetrieveContractSignAdvanceDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/signs`
      : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/signs`
  );
}

export function RetrieveFlexMarketPriceElectionPrint(contract_num, pricing_line, price_suffix) {
  return ApiService.getData(
    isLocal()
      ? `/ContractManagement/RetrieveFlexMarketPriceElectionPrint/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/pricing-lines/${pricing_line}/suffixes/${price_suffix}/users/${useridFromLS()}/prints`
      : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${contract_num}/pricing-lines/${pricing_line}/suffixes/${price_suffix}/users/${useridFromLS()}/prints`
  )
}

export function RetrieveFlexMarketPriceElectionSignDetails(cont_num, price_suffix, ftp_file_name) {
  let url = `companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contracts/${cont_num}/prices/${price_suffix}/users/${useridFromLS()}/signs`
  if (ftp_file_name != null && ftp_file_name != undefined) {
    url += `?"ftp_file_name`
  }
  return ApiService.getData(isLocal() ? '/ContractManagement/RetrieveFlexMarketPriceElectionSignDetails/' + url : `${cnt_mngmt}` + url)
}

export function RetrieveFlexMarketPriceElectionRescanDetails(price_suffix, contract_num){
  return ApiService.getData(
    isLocal()
      ? `/ContractManagement/RetrieveFlexMarketPriceElectionRescanDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/suffixes/${price_suffix}/contracts/${contract_num}/users/${useridFromLS()}/rescans`
      : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/suffixes/${price_suffix}/contracts/${contract_num}/users/${useridFromLS()}/rescans`
  );
}

export function RetrieveUserControlDetails(user_id, buy_pt_id, username, userid) {
  let optionalParamsUrl = '';
  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() != "") {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if (user_id !== null && user_id !== undefined && user_id != "") {
    optionalParamsUrl += `user_id=${user_id}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (username !== null && username !== undefined && username != "") {
    optionalParamsUrl += `username=${username}&`
  }
  if (userid !== null && userid !== undefined && userid != "") {
    optionalParamsUrl += `userid=${userid}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveUserControlDetails/users?${optionalParamsUrl}` : `${cnt_mngmt}users?${optionalParamsUrl}`)
};

export async function UploadFtpFile(fileData) {
  var obj = {
    body: fileData
  }
  return await ApiService.postFileUpload(isLocal() ? `/Common/UploadFtpFile/contractfiles` : `${cnt_mngmt}contractfiles`, fileData)
}

export function RetrieveApplicationContractDetails(pricing_line, buy_pt_id, insp_num, contract_num, split_vendor) {
  let optionalParamsUrl = '';

  optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;

  if (pricing_line !== null && pricing_line !== undefined)
    optionalParamsUrl += `pricing_line=${pricing_line}&`

  if (buy_pt_id !== null && buy_pt_id !== undefined)
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`

  if (insp_num !== null && insp_num !== undefined)
    optionalParamsUrl += `insp_num=${insp_num}&`

  if (contract_num !== null && contract_num !== undefined)
    optionalParamsUrl += `contract_num=${contract_num}&`

  if (split_vendor !== null && split_vendor !== undefined)
    optionalParamsUrl += `split_vendor=${split_vendor}`

  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveApplicationContractDetails/applications?${optionalParamsUrl}` : `${cnt_mngmt}applications?${optionalParamsUrl}`)

}

export function UpdateExceptionPremiumDetails(transaction, data) {
  return ApiService.updateData(isLocal() ? `/ContractManagement/UpdateExceptionPremiumDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/transactions/${transaction}/premiums` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/transactions/${transaction}/premiums`, data)
}

export function RemoveExceptionPremium(trans_num) {
  let optionalParamsUrl = '';
  if (useridFromLS != null && useridFromLS != "" && useridFromLS != undefined) {
    optionalParamsUrl += `user_id=${useridFromLS()}`
  }
  return ApiService.removeData(isLocal() ? `/ContractManagement/RemoveExceptionPremium/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/transactions/${trans_num}?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-Years/${cropYearFromLS()}/transactions/${trans_num}?${optionalParamsUrl}`)
}
export function UpdateVendorEmails(vendors, dataObj) {
  return ApiService.updateData(isLocal() ? 
  `/ContractManagement/UpdateVendorEmail/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/vendors/${vendors}/emails`: 
  `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/vendors/${vendors}/emails`, dataObj)
}

export function UpdateClearContractAdvanceDetails(contracts) {
  return ApiService.updateData(isLocal() ?
    `/ContractManagement/UpdateClearContractAdvanceDetails/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/contracts/${contracts}/contracts` :
    `${cnt_mngmt}companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/contracts/${contracts}/contracts`, { addUser: useridFromLS()});
}

export function RetrievePeanutStaticValues() {
  return ApiService.getData(isLocal() ? 
  `/Common/RetrievePeanutStaticValues/static-values/pps_defaults` :
  `${cnt_mngmt}static-values/pps_defaults`);
}

export function Createflexmarket(buy_pt_id , contract_num , pricing_line ,data) {
  return ApiService.postData(isLocal() ? `/ContractManagement/Createflexmarket/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/contracts/${contract_num}/pricing-lines/${pricing_line}/markets` : 
  `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/contracts/${contract_num}/pricing-lines/${pricing_line}/markets`,data)
  
  }

  export function RetrieveCountyControlDetails(buyingPointId,filterStatus,stateAbbrevations,county_id) {
    let optionalParamsUrl='';
    if (filterStatus !== null && filterStatus !== '' && filterStatus !== undefined) {
      optionalParamsUrl += `filter=${filterStatus}&`
    }
    if (stateAbbrevations !== null && stateAbbrevations !== '' && stateAbbrevations !== undefined) {
      optionalParamsUrl += `state_abbr=${stateAbbrevations}&`
    }
    if (county_id !== null && county_id !== '' && county_id !== undefined) {
      optionalParamsUrl += `county_id=${county_id}&`
    }
    return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveCountyControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/counties?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/counties?${optionalParamsUrl}`);
  }


  export function RetrieveBatchCheckReadyToPayDetails(contractNumber ,buy_pt_id, insp_num,whse_rcpt_num, whse_rcpt_bp, settlement_num) {
    let optionalParamsUrl = '';
    if (contractNumber !== null && contractNumber !== undefined && contractNumber !== "") {
      optionalParamsUrl += `contract_num=${contractNumber}&`
    }
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (insp_num !== null && insp_num !== undefined && insp_num !== "") {
      optionalParamsUrl += `insp_num=${insp_num}&`
    }
    if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
    }
    if (whse_rcpt_bp !== null && whse_rcpt_bp !== undefined && whse_rcpt_bp !== "") {
      optionalParamsUrl += `whse_rcpt_bp=${whse_rcpt_bp}&`
    }
    if (settlement_num !== null && settlement_num !== undefined && settlement_num !== "") {
      optionalParamsUrl += `settlement_num=${settlement_num}&`
    }
    return ApiService.getData(isLocal() ? 
      `/ContractManagement/RetrieveBatchCheckReadyToPayDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/checkreadytopays?${optionalParamsUrl}` :
      `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/checkreadytopays?${optionalParamsUrl}`);
  }


export function RetrieveBuyingPointControlList(comp_id, crop_year, area_id, coll_pt_id, buy_pt_id) {
  let optionsParamsURL = '';
  
  if (area_id !== null && area_id !== '' && area_id !== undefined) {
    optionsParamsURL += `&area_id=${area_id}`;
  }
  if (comp_id !== null && comp_id !== '' && comp_id !== undefined) {
    optionsParamsURL += `&comp_id=${comp_id}`;
  }
  if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
    optionsParamsURL += `&crop_year=${crop_year}`;
  }
  if (coll_pt_id !== null && coll_pt_id !== '' && coll_pt_id !== undefined) {
    optionsParamsURL += `&coll_pt_id=${coll_pt_id}`;
  }
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionsParamsURL += `&buy_pt_id=${buy_pt_id}`;
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveBuyingPointControlList/locations?${optionsParamsURL}` : `${cnt_mngmt}locations?${optionsParamsURL}`);
}

export function RetrieveFreightAccrualPayDetails(shp_buy_pt,audit_xfer_num) {
  let optionsParamsURL = '';  
   if(shp_buy_pt!==null&&shp_buy_pt!==''&&shp_buy_pt!==undefined){
    optionsParamsURL+=`&shp_buy_pt=${shp_buy_pt}`;
  }
  if(audit_xfer_num!==null&&audit_xfer_num!==''&&audit_xfer_num!==undefined){
    optionsParamsURL+=`&audit_xfer_num=${audit_xfer_num}`;
  }
  return ApiService.getData(isLocal() ?`/ContractManagement/RetrieveFreightAccrualPayDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/accruals?${optionsParamsURL}`:`${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/accruals?${optionsParamsURL}`);
  }

export function CreateInspectUploadLogDetails(buY_PT_ID,dataObj) {
  return ApiService.postData(isLocal() ? `/ContractManagement/CreateInspectUploadLogDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/logs` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buY_PT_ID}/logs`, dataObj)
}

export function RetrieveFarmVendorSplitDetails(buy_pt_id,state_abbr,county_id,farm_id,farm_suffix,split_vendor){
  let optionalParamsUrl='';

  if(buy_pt_id!==null&&buy_pt_id!==''&&buy_pt_id!==undefined){
    optionalParamsUrl+=`&buy_pt_id=${buy_pt_id}`;
  }

  if(state_abbr!==null&&state_abbr!==''&&state_abbr!==undefined){
    optionalParamsUrl+=`&state_abbr=${state_abbr}`;
  }
  if(county_id!==null&&county_id!==''&&county_id!==undefined){
    optionalParamsUrl+=`&county_id=${county_id}`;
  }
  if(farm_id!==null&&farm_id!==''&&farm_id!==undefined){
    optionalParamsUrl+=`&farm_id=${farm_id}`;
  }

  if(farm_suffix!==null&&farm_suffix!==''&&farm_suffix!==undefined){
    optionalParamsUrl+=`&farm_suffix=${farm_suffix}`;
  }
  if(split_vendor!==null&&split_vendor!==''&&split_vendor!==undefined){
    optionalParamsUrl+=`&split_vendor=${split_vendor}`;
  }

  return ApiService.getData(isLocal() ? 
      `/ContractManagement/RetrieveFarmVendorSplitDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/vendors?${optionalParamsUrl}` :
      `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/vendors?${optionalParamsUrl}`);
}

export function RetrieveFarmPickDetails(buy_pt_id, vendor, contractnum, farmid) {
  let optionalParamsUrl = '';
  if (contractnum !== null && contractnum !== undefined && contractnum != "") {
    optionalParamsUrl += `contractnum=${contractnum}&`
  }
  if (farmid !== null && farmid !== undefined && farmid != "") {
    optionalParamsUrl += `farmid=${farmid}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveFarmPickDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendor}/farms?${optionalParamsUrl}` : `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendor}/farms?${optionalParamsUrl}`);
};

export function RetrieveFarmControlDetails(func_id, func_sub_id, buy_pt_id, state_abbr, county_id, farm_id, farm_suffix) {
  let optionsParamsURL = '';
  if (func_id != null && func_id !== '' && func_id !== undefined) {
    optionsParamsURL += `func_id=${func_id}&`;
  }
  if (func_sub_id != null && func_sub_id !== '' && func_sub_id !== undefined) {
    optionsParamsURL += `func_sub_id=${func_sub_id}&`;
  }
  if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
      optionsParamsURL += `buy_pt_id=${buy_pt_id}&`;
  }
  if (state_abbr != null && state_abbr !== '' && state_abbr !== undefined) {
      optionsParamsURL += `state_abbr=${state_abbr}&`;
  }
  if (county_id != null && county_id !== '' && county_id !== undefined) {
      optionsParamsURL += `county_id=${county_id}&`;
  }
  if (farm_id != null && farm_id !== '' && farm_id !== undefined) {
      optionsParamsURL += `farm_id=${farm_id}&`;
  }
  if (farm_suffix != null && farm_suffix !== '' && farm_suffix !== undefined) {
      optionsParamsURL += `farm_suffix=${farm_suffix}&`;
  }
  return ApiService.getData(isLocal() ? 
  `/ContractManagement/RetrieveFarmControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/farms?${optionsParamsURL}` : 
  `${cnt_mngmt}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/farms?${optionsParamsURL}`);

}

export function RetrieveUserControlDetailsImproved(user_id, buy_pt_id, username, userid) {//Added This Improved method for Security Menu screen for Phase 4 for filtering through buyingptid optional Parameter 
  let optionalParamsUrl = '';
  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() != "") {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if (user_id !== null && user_id !== undefined && user_id != "") {
    optionalParamsUrl += `user_id=${user_id}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buyingptid=${buy_pt_id}&`
  }
  if (username !== null && username !== undefined && username != "") {
    optionalParamsUrl += `username=${username}&`
  }
  if (userid !== null && userid !== undefined && userid != "") {
    optionalParamsUrl += `userid=${userid}&`
  }
  return ApiService.getData(isLocal() ? `/ContractManagement/RetrieveUserControlDetails/users?${optionalParamsUrl}` : `${cnt_mngmt}users?${optionalParamsUrl}`)
};