/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  setValue,
  setData,
  getValue,
  getData,
} from "../../shared/WindowImports";

import "./BankHolidaySetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// import { data } from "msw/lib/types/context";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_BankHolidaySetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let user_id = sessionStorage.getItem("userid"); 
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "BankHolidaySetup",
    windowName: "BankHolidaySetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.BankHolidaySetup",
    // START_USER_CODE-USER_BankHolidaySetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Bank Holiday Setup",
      scrCode: "PN0150A",
    },
    // END_USER_CODE-USER_BankHolidaySetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxBankHolidaySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxBankHolidaySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    lstBankHolidayList: {
      name: "lstBankHolidayList",
      type: "ListBoxFieldWidget",
      parent: "grpbxBankHolidaySetup",
      ColSpan: "2",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstBankHolidayList_PROPERTIES

      // END_USER_CODE-USER_lstBankHolidayList_PROPERTIES
    },
    grpbxBankHolidaySetup: {
      name: "grpbxBankHolidaySetup",
      type: "GroupBoxWidget",
      parent: "BankHolidaySetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBankHolidaySetup_PROPERTIES

      // END_USER_CODE-USER_grpbxBankHolidaySetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "BankHolidaySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#BankHolidayProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#BankHolidayProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAdd: {
      DEFAULT: ["SystemMaintenanceSpecialFunctions#BankHolidayProfile#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    formLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    bFillForm()
  }, [getData(thisObj, 'BankHolidaySetupdata')])

  // START_USER_CODE-USER_METHODS

  // FormLoad function
  const formLoad = async() => {
    try {
      await bFillForm()
      setLoading(false)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in formLoad`
        );
      }
      return false;
    }
  }

  // bFillForm list binding
  const bFillForm = async() => {
    try { 
      let listData = getData(thisObj, 'listData')
      let js = []
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveBankHolidayControldetails('GET', null, null, null)
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let holidayDate = moment(data[i]?.holidayDate).format("MM/DD/YYYY")
          let obj = { key: data[i].holidayDate, description: holidayDate + ' - ' + data[i].holidayName, index: i }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstBankHolidayList: {
            ...state["lstBankHolidayList"],
            valueList: js,
          }
        }
      })
      if (js.length > 0 && (listData == null || listData == undefined || listData.meAction == null || listData.holidayIndex == null)) {
        setValue(thisObj, 'lstBankHolidayList', [js.at(js.length-1).key]); 
        return;
      }
      else if (listData !== null && js.length > listData.holidayIndex && listData.meAction == true) {
        setValue(thisObj, 'lstBankHolidayList', [js.at(listData.holidayIndex).key]);
        listData.meAction = null
        listData.holidayIndex = null
      }
      else if (listData !== null && js.length == listData.holidayIndex && listData.meAction == true) {
        setValue(thisObj, 'lstBankHolidayList', [js.at(js.length-1).key]);
        listData.meAction = null
        listData.holidayIndex = null
      }

    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in bFillForm`
        );
      }
      return false;
    }
  }

  //Delete button Functionality
  const onbtnDeleteClick = async () => {
    try { 
      let holidayName = ''
      let holidayIndex = ''
      let holidayList = thisObj.state['lstBankHolidayList'].valueList;
      let selectedholidayListValue = thisObj.values['lstBankHolidayList'];
      if (selectedholidayListValue !== "" && selectedholidayListValue !== undefined) {
        holidayName = holidayList.find(elem => elem.key == selectedholidayListValue).description;
        holidayIndex = holidayList.find(elem => elem.key == selectedholidayListValue).index;
      }
      let holiday_date = moment(selectedholidayListValue[0]).format("MM/DD/YYYY")
      let res = confirm(`Are you sure you wish to delete Holiday: ${holidayName}?`)
      if (res == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.RemoveBankHolidayControldetails('DEL', holiday_date, null, null)
        if (![200].includes(response.status)) {
          alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          return;
        }
        else {
          let obj = {
            meAction : true,
            holidayIndex : holidayIndex
          }
          setData(thisObj, 'listData', obj)
          bFillForm()
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred in Delete button`
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  //Close Button Functionality
  const onbtnCloseClick = async () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_BankHolidaySetupPopUp").childNodes[0].click()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnCloseClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_BankHolidaySetup*/}

              {/* END_USER_CODE-USER_BEFORE_BankHolidaySetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBankHolidaySetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBankHolidaySetup*/}

              <GroupBoxWidget
                conf={state.grpbxBankHolidaySetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstBankHolidayList*/}

                {/* END_USER_CODE-USER_BEFORE_lstBankHolidayList*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstBankHolidayList}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstBankHolidayList*/}

                {/* END_USER_CODE-USER_AFTER_lstBankHolidayList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBankHolidaySetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBankHolidaySetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_BankHolidaySetup*/}

              {/* END_USER_CODE-USER_AFTER_BankHolidaySetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_BankHolidaySetup);
