/* eslint-disable*/
import React from "react";
import Settlements_ReviewDeductionTracking from "./ReviewDeductionTracking";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReviewDeductionTracking Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ReviewDeductionTracking />);
    });
  });
  afterEach(cleanup);
  test("is ReviewDeductionTracking Loads Successfully", () => {
    expect(screen.getByText("ReviewDeductionTracking")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReviewDeductionTracking", () => {
    // START_USER_CODE-USER_ReviewDeductionTracking_Custom_Test_Case
    // END_USER_CODE-USER_ReviewDeductionTracking_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ReviewDeductionTracking />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnWithImage(Button Widget) Test Cases", async () => {
    const btnWithImage = screen.getByTestId("btnWithImage");
    expect(btnWithImage).toBeInTheDocument;
    expect(btnWithImage.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_btnWithImage")
    );
  });
  test("Custom Test Cases for btnWithImage", () => {
    // START_USER_CODE-USER_btnWithImage_TEST
    // END_USER_CODE-USER_btnWithImage_TEST
  });
  test("gridReviewDedTrckng(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng = screen.getByTestId("gridReviewDedTrckng");
    let gridReviewDedTrckngbtn =
      gridReviewDedTrckng.nextElementSibling.firstElementChild;
    gridReviewDedTrckng =
      gridReviewDedTrckng.parentElement.parentElement.parentElement;
    expect(gridReviewDedTrckng.tagName).toBe("DIV");
    expect(gridReviewDedTrckng.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridReviewDedTrckng", () => {
    // START_USER_CODE-USER_gridReviewDedTrckng_TEST
    // END_USER_CODE-USER_gridReviewDedTrckng_TEST
  });
  test("grpbxAllButtons(GroupBox Widget) Test Cases", async () => {
    const grpbxAllButtons = screen.getByTestId("grpbxAllButtons");
    expect(grpbxAllButtons.tagName).toBe("BUTTON");
    expect(grpbxAllButtons.type).toBe("button");
    expect(grpbxAllButtons.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAllButtons", () => {
    // START_USER_CODE-USER_grpbxAllButtons_TEST
    // END_USER_CODE-USER_grpbxAllButtons_TEST
  });
  test("grpbxAllLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxAllLabels = screen.getByTestId("grpbxAllLabels");
    expect(grpbxAllLabels.tagName).toBe("BUTTON");
    expect(grpbxAllLabels.type).toBe("button");
    expect(grpbxAllLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAllLabels", () => {
    // START_USER_CODE-USER_grpbxAllLabels_TEST
    // END_USER_CODE-USER_grpbxAllLabels_TEST
  });
  test("lblbuypt(Label Widget) Test Cases", async () => {
    const lblbuypt = screen.getByTestId("lblbuypt");
    expect(lblbuypt.tagName).toBe("LABEL");
    expect(lblbuypt.classList).toContain("form-label");
    expect(lblbuypt.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_lblbuypt")
    );
  });
  test("Custom Test Cases for lblbuypt", () => {
    // START_USER_CODE-USER_lblbuypt_TEST
    // END_USER_CODE-USER_lblbuypt_TEST
  });
  test("lblnumber1(Label Widget) Test Cases", async () => {
    const lblnumber1 = screen.getByTestId("lblnumber1");
    expect(lblnumber1.tagName).toBe("LABEL");
    expect(lblnumber1.classList).toContain("form-label");
    expect(lblnumber1.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_lblnumber1")
    );
  });
  test("Custom Test Cases for lblnumber1", () => {
    // START_USER_CODE-USER_lblnumber1_TEST
    // END_USER_CODE-USER_lblnumber1_TEST
  });
  test("lblnumber2(Label Widget) Test Cases", async () => {
    const lblnumber2 = screen.getByTestId("lblnumber2");
    expect(lblnumber2.tagName).toBe("LABEL");
    expect(lblnumber2.classList).toContain("form-label");
    expect(lblnumber2.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_lblnumber2")
    );
  });
  test("Custom Test Cases for lblnumber2", () => {
    // START_USER_CODE-USER_lblnumber2_TEST
    // END_USER_CODE-USER_lblnumber2_TEST
  });
  test("lblsource(Label Widget) Test Cases", async () => {
    const lblsource = screen.getByTestId("lblsource");
    expect(lblsource.tagName).toBe("LABEL");
    expect(lblsource.classList).toContain("form-label");
    expect(lblsource.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_lblsource")
    );
  });
  test("Custom Test Cases for lblsource", () => {
    // START_USER_CODE-USER_lblsource_TEST
    // END_USER_CODE-USER_lblsource_TEST
  });
  test("lblTotal(Label Widget) Test Cases", async () => {
    const lblTotal = screen.getByTestId("lblTotal");
    expect(lblTotal.tagName).toBe("LABEL");
    expect(lblTotal.classList).toContain("form-label");
    expect(lblTotal.textContent).toEqual(
      t("Settlements:ReviewDeductionTracking_lblTotal")
    );
  });
  test("Custom Test Cases for lblTotal", () => {
    // START_USER_CODE-USER_lblTotal_TEST
    // END_USER_CODE-USER_lblTotal_TEST
  });
  test("txt1(Textbox Widget) Test Cases", async () => {
    const txt1 = screen.getByTestId("txt1");
    expect(txt1.tagName).toBe("INPUT");
    expect(txt1.type).toBe("text");
    expect(txt1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txt1, "1");
    });
  });
  test("Custom Test Cases for txt1", () => {
    // START_USER_CODE-USER_txt1_TEST
    // END_USER_CODE-USER_txt1_TEST
  });
  test("txt2(Textbox Widget) Test Cases", async () => {
    const txt2 = screen.getByTestId("txt2");
    expect(txt2.tagName).toBe("INPUT");
    expect(txt2.type).toBe("text");
    expect(txt2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txt2, "1");
    });
  });
  test("Custom Test Cases for txt2", () => {
    // START_USER_CODE-USER_txt2_TEST
    // END_USER_CODE-USER_txt2_TEST
  });
  test("gridReviewDedTrckng_txtcolAmntCllc(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolAmntCllc = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolAmntCllcbtn =
      gridReviewDedTrckng_txtcolAmntCllc.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolAmntCllc =
      gridReviewDedTrckng_txtcolAmntCllc.parentElement.parentElement
        .parentElement;
    expect(gridReviewDedTrckng_txtcolAmntCllc.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolAmntCllc.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolAmntCllcdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolAmntCllcdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolAmntCllcbtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolAmntCllcadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolAmntCllcadd, { button: 0 });
      });
    }
    const txtcolAmntCllc = screen.getByTestId("txtcolAmntCllc");
    expect(txtcolAmntCllc.tagName).toBe("INPUT");
    expect(txtcolAmntCllc.type).toBe("text");
    expect(txtcolAmntCllc.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolAmntCllc, "1");
    });
  });
  test("Custom Test Cases for txtcolAmntCllc", () => {
    // START_USER_CODE-USER_txtcolAmntCllc_TEST
    // END_USER_CODE-USER_txtcolAmntCllc_TEST
  });
  test("gridReviewDedTrckng_txtcolChrgId(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolChrgId = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolChrgIdbtn =
      gridReviewDedTrckng_txtcolChrgId.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolChrgId =
      gridReviewDedTrckng_txtcolChrgId.parentElement.parentElement
        .parentElement;
    expect(gridReviewDedTrckng_txtcolChrgId.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolChrgId.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolChrgIddata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolChrgIddata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolChrgIdbtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolChrgIdadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolChrgIdadd, { button: 0 });
      });
    }
    const txtcolChrgId = screen.getByTestId("txtcolChrgId");
    expect(txtcolChrgId.tagName).toBe("INPUT");
    expect(txtcolChrgId.type).toBe("text");
    expect(txtcolChrgId.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolChrgId, "1");
    });
  });
  test("Custom Test Cases for txtcolChrgId", () => {
    // START_USER_CODE-USER_txtcolChrgId_TEST
    // END_USER_CODE-USER_txtcolChrgId_TEST
  });
  test("gridReviewDedTrckng_txtcolChrgTyp(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolChrgTyp = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolChrgTypbtn =
      gridReviewDedTrckng_txtcolChrgTyp.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolChrgTyp =
      gridReviewDedTrckng_txtcolChrgTyp.parentElement.parentElement
        .parentElement;
    expect(gridReviewDedTrckng_txtcolChrgTyp.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolChrgTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolChrgTypdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolChrgTypdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolChrgTypbtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolChrgTypadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolChrgTypadd, { button: 0 });
      });
    }
    const txtcolChrgTyp = screen.getByTestId("txtcolChrgTyp");
    expect(txtcolChrgTyp.tagName).toBe("INPUT");
    expect(txtcolChrgTyp.type).toBe("text");
    expect(txtcolChrgTyp.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolChrgTyp, "1");
    });
  });
  test("Custom Test Cases for txtcolChrgTyp", () => {
    // START_USER_CODE-USER_txtcolChrgTyp_TEST
    // END_USER_CODE-USER_txtcolChrgTyp_TEST
  });
  test("gridReviewDedTrckng_txtcolCllctId(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolCllctId = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolCllctIdbtn =
      gridReviewDedTrckng_txtcolCllctId.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolCllctId =
      gridReviewDedTrckng_txtcolCllctId.parentElement.parentElement
        .parentElement;
    expect(gridReviewDedTrckng_txtcolCllctId.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolCllctId.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolCllctIddata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolCllctIddata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolCllctIdbtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolCllctIdadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolCllctIdadd, { button: 0 });
      });
    }
    const txtcolCllctId = screen.getByTestId("txtcolCllctId");
    expect(txtcolCllctId.tagName).toBe("INPUT");
    expect(txtcolCllctId.type).toBe("text");
    expect(txtcolCllctId.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolCllctId, "1");
    });
  });
  test("Custom Test Cases for txtcolCllctId", () => {
    // START_USER_CODE-USER_txtcolCllctId_TEST
    // END_USER_CODE-USER_txtcolCllctId_TEST
  });
  test("gridReviewDedTrckng_txtcolCllctTyp(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolCllctTyp = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolCllctTypbtn =
      gridReviewDedTrckng_txtcolCllctTyp.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolCllctTyp =
      gridReviewDedTrckng_txtcolCllctTyp.parentElement.parentElement
        .parentElement;
    expect(gridReviewDedTrckng_txtcolCllctTyp.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolCllctTyp.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolCllctTypdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolCllctTypdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolCllctTypbtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolCllctTypadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolCllctTypadd, { button: 0 });
      });
    }
    const txtcolCllctTyp = screen.getByTestId("txtcolCllctTyp");
    expect(txtcolCllctTyp.tagName).toBe("INPUT");
    expect(txtcolCllctTyp.type).toBe("text");
    expect(txtcolCllctTyp.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolCllctTyp, "1");
    });
  });
  test("Custom Test Cases for txtcolCllctTyp", () => {
    // START_USER_CODE-USER_txtcolCllctTyp_TEST
    // END_USER_CODE-USER_txtcolCllctTyp_TEST
  });
  test("gridReviewDedTrckng_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolDate = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolDatebtn =
      gridReviewDedTrckng_txtcolDate.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolDate =
      gridReviewDedTrckng_txtcolDate.parentElement.parentElement.parentElement;
    expect(gridReviewDedTrckng_txtcolDate.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolDatedata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolDatedata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolDatebtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolDateadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolDateadd, { button: 0 });
      });
    }
    const txtcolDate = screen.getByTestId("txtcolDate");
    expect(txtcolDate.tagName).toBe("INPUT");
    expect(txtcolDate.type).toBe("text");
    expect(txtcolDate.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolDate, "1");
    });
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridReviewDedTrckng_txtcolDeductCode(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolDeductCode = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolDeductCodebtn =
      gridReviewDedTrckng_txtcolDeductCode.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolDeductCode =
      gridReviewDedTrckng_txtcolDeductCode.parentElement.parentElement
        .parentElement;
    expect(gridReviewDedTrckng_txtcolDeductCode.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolDeductCode.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolDeductCodedata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolDeductCodedata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolDeductCodebtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolDeductCodeadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolDeductCodeadd, { button: 0 });
      });
    }
    const txtcolDeductCode = screen.getByTestId("txtcolDeductCode");
    expect(txtcolDeductCode.tagName).toBe("INPUT");
    expect(txtcolDeductCode.type).toBe("text");
    expect(txtcolDeductCode.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolDeductCode, "1");
    });
  });
  test("Custom Test Cases for txtcolDeductCode", () => {
    // START_USER_CODE-USER_txtcolDeductCode_TEST
    // END_USER_CODE-USER_txtcolDeductCode_TEST
  });
  test("gridReviewDedTrckng_txtcolVndr(Grid Widget) Test Cases", async () => {
    let gridReviewDedTrckng_txtcolVndr = screen.getByTestId(
      "gridReviewDedTrckng"
    );
    let gridReviewDedTrckng_txtcolVndrbtn =
      gridReviewDedTrckng_txtcolVndr.nextElementSibling.firstElementChild;
    gridReviewDedTrckng_txtcolVndr =
      gridReviewDedTrckng_txtcolVndr.parentElement.parentElement.parentElement;
    expect(gridReviewDedTrckng_txtcolVndr.tagName).toBe("DIV");
    expect(gridReviewDedTrckng_txtcolVndr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewDeductionTracking_gridReviewDedTrckng")
      )
    ).toBeInTheDocument;
    const gridReviewDedTrckng_txtcolVndrdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridReviewDedTrckng_txtcolVndrdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridReviewDedTrckng"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolVndrbtn, { button: 0 });
      });
      const gridReviewDedTrckng_txtcolVndradd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridReviewDedTrckng_txtcolVndradd, { button: 0 });
      });
    }
    const txtcolVndr = screen.getByTestId("txtcolVndr");
    expect(txtcolVndr.tagName).toBe("INPUT");
    expect(txtcolVndr.type).toBe("text");
    expect(txtcolVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolVndr, "1");
    });
  });
  test("Custom Test Cases for txtcolVndr", () => {
    // START_USER_CODE-USER_txtcolVndr_TEST
    // END_USER_CODE-USER_txtcolVndr_TEST
  });
});
