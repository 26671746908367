/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  TextAreaWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  enable,
  disable
} from "../../shared/WindowImports";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import "./CheckDetails.scss";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_CheckDetails(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [lstrvendor, setlstrvendor] = useState('');
  const [LstrRemitTo, setLstrRemitTo] = useState('');
  const [LstrBatchNumber, setLstrBatchNumber] = useState('');
  const [lblLocation, setlblLocation] = useState('');
  // conse [Enabledisable,setEnabledisable]=useState(false)
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  const useridFromLS = sessionStorage.getItem('userid');

  let states = {
    Label: "CheckDetails",
    windowName: "CheckDetails",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.CheckDetails",
    // START_USER_CODE-USER_CheckDetails_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Payable Detail",
      scrCode: "PN1100G",
    },
    // END_USER_CODE-USER_CheckDetails_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnReturnToHoldPay: {
      name: "btnReturnToHoldPay",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Return To Hold Pay",
      CharWidth: "38",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReturnToHoldPay_PROPERTIES

      // END_USER_CODE-USER_btnReturnToHoldPay_PROPERTIES
    },
    btnUpdateRemarks: {
      name: "btnUpdateRemarks",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Update Remarks",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdateRemarks_PROPERTIES

      // END_USER_CODE-USER_btnUpdateRemarks_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colNumber1: {
      name: "colNumber1",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Number1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber1_PROPERTIES

      // END_USER_CODE-USER_colNumber1_PROPERTIES
    },
    colNumber2: {
      name: "colNumber2",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Number2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber2_PROPERTIES

      // END_USER_CODE-USER_colNumber2_PROPERTIES
    },
    colOpt: {
      name: "colOpt",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Opt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpt_PROPERTIES

      // END_USER_CODE-USER_colOpt_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colSetlDate: {
      name: "colSetlDate",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Setl. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSetlDate_PROPERTIES

      // END_USER_CODE-USER_colSetlDate_PROPERTIES
    },
    colType1: {
      name: "colType1",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Type1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1_PROPERTIES

      // END_USER_CODE-USER_colType1_PROPERTIES
    },
    colType2: {
      name: "colType2",
      type: "GridColumnWidget",
      parent: "gridReadyToPays",
      Label: "Type2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2_PROPERTIES

      // END_USER_CODE-USER_colType2_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails",
      Label: "Remit To:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    gridReadyToPays: {
      name: "gridReadyToPays",
      type: "GridWidget",
      parent: "grpbxCheckDetails",
      gridCellsOrder:
        "txtcolType1,txtcolNumber1,txtcolType2,txtcolNumber2,txtcolProceeds,txtcolOpt,txtcolDate,txtcolSetlDate",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridReadyToPays_PROPERTIES

      // END_USER_CODE-USER_gridReadyToPays_PROPERTIES
    },
    lblBatchedReadyToPays: {
      name: "lblBatchedReadyToPays",
      type: "LabelWidget",
      parent: "grpbxCheckDetails",
      Label: "Batched Ready To Pays:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBatchedReadyToPays_PROPERTIES

      // END_USER_CODE-USER_lblBatchedReadyToPays_PROPERTIES
    },
    lblCheckInformation: {
      name: "lblCheckInformation",
      type: "LabelWidget",
      parent: "grpbxCheckDetails",
      Label: "Check Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCheckInformation_PROPERTIES

      // END_USER_CODE-USER_lblCheckInformation_PROPERTIES
    },
    lblRemarks: {
      name: "lblRemarks",
      type: "LabelWidget",
      parent: "grpbxCheckDetails",
      Label: "Remarks:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemarks_PROPERTIES

      // END_USER_CODE-USER_lblRemarks_PROPERTIES
    },
    txtarRemarks: {
      name: "txtarRemarks",
      type: "TextAreaWidget",
      parent: "grpbxCheckDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarRemarks_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolNumber1: {
      name: "txtcolNumber1",
      type: "TextBoxWidget",
      colName: "colNumber1",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber1_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber1_PROPERTIES
    },
    txtcolNumber2: {
      name: "txtcolNumber2",
      type: "TextBoxWidget",
      colName: "colNumber2",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber2_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber2_PROPERTIES
    },
    txtcolOpt: {
      name: "txtcolOpt",
      type: "TextBoxWidget",
      colName: "colOpt",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpt_PROPERTIES

      // END_USER_CODE-USER_txtcolOpt_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolSetlDate: {
      name: "txtcolSetlDate",
      type: "TextBoxWidget",
      colName: "colSetlDate",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSetlDate_PROPERTIES

      // END_USER_CODE-USER_txtcolSetlDate_PROPERTIES
    },
    txtcolType1: {
      name: "txtcolType1",
      type: "TextBoxWidget",
      colName: "colType1",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1_PROPERTIES

      // END_USER_CODE-USER_txtcolType1_PROPERTIES
    },
    txtcolType2: {
      name: "txtcolType2",
      type: "TextBoxWidget",
      colName: "colType2",
      parent: "gridReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2_PROPERTIES

      // END_USER_CODE-USER_txtcolType2_PROPERTIES
    },
    txtLienholders: {
      name: "txtLienholders",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Lienholders:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLienholders_PROPERTIES

      // END_USER_CODE-USER_txtLienholders_PROPERTIES
    },
    grpbxCheckDetails: {
      name: "grpbxCheckDetails",
      type: "GroupBoxWidget",
      parent: "CheckDetails",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCheckDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckDetails_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "CheckDetails",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Printcheckdetails()
    disable(thisObj, 'btnUpdateRemarks')

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, "PrintCheck")]);
  const From_Unload = () => {

    if (confirm("Changes to remarks have not been saved!") == true) {
      onbtnUpdateRemarksClick()
    } else {
      onbtnCancelClick()
    }

  }
  const Printcheckdetails = () => {
    let getPrintcheckdetails = getData(thisObj, "PrintCheck")
    if (getPrintcheckdetails !== null) {
      setlstrvendor(getPrintcheckdetails.lstrvendor)
      setLstrBatchNumber(getPrintcheckdetails.LstrBatchNumber)
      setLstrRemitTo(getPrintcheckdetails.LstrRemitTo)
      setlblLocation(getPrintcheckdetails.lblLocation)
      bFillReadyToPays(getPrintcheckdetails.lblLocation, getPrintcheckdetails.LstrBatchNumber, getPrintcheckdetails.lstrvendor)
      bFillLienholders(cropYearFromLS, compIdFromLS, getPrintcheckdetails.lstrvendor, '')
      bFillRemitToList2(getPrintcheckdetails.lstrvendor)
    }
  }
  const bFillLienholders = (cropYearFromLS, compIdFromLS, vendor, lienholderLine) => {
    let js = []
    // js.push({ key: '', description: '-' });
    AccountPayment.RetrieveLienHolderControlDetails(cropYearFromLS, compIdFromLS, vendor, lienholderLine).then(response => {
      let data = response
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].lienholder_name, description: data[i].lienholder_name.trim() }
          js.push(obj)
        }

        setValue(thisObj, 'txtLienholders', js.at(0).key)
      }
    })
  }
  // Start Method for binding ReadyToPays
  const bFillReadyToPays = (buy_pt_id, batch_number, payee_vendor) => {
    let js = []
    AccountPayment.RetrieveBatchCheckDetailsList(buy_pt_id, batch_number, payee_vendor).then(response => {
      let data = response
      if (data.length > 0) {
        setValue(thisObj, 'txtarRemarks', data[0].remarks)
        let readytopaydata = data[0].ready_to_pay
        let obj = {};
        let gridReadyToPaysdata = []
        if (readytopaydata.length > 0) {
          for (let i = 0; i < readytopaydata.length; i++) {
            obj.txtcolType1 = readytopaydata[i].pay_type_1,
              obj.txtcolNumber1 = readytopaydata[i].pay_num_1,
              obj.txtcolType2 = readytopaydata[i].pay_type_2,
              obj.txtcolNumber2 = readytopaydata[i].pay_num_2,
              obj.txtcolProceeds = readytopaydata[i].rtp_proceeds,
              obj.txtcolOpt = readytopaydata[i].option_pay,
              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate = ""

            if (readytopaydata[i].intr_agree_num !== '') {

            }

            if (readytopaydata[i].special_type == "INT") {
              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')

            } else if (readytopaydata[i].audit_adjustment !== "") {
              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')

            } else if (readytopaydata[i].audit_insp_num == "TAP") {
              obj.txtcolDate = moment(readytopaydata[i].insp_date_time).format('MM/DD/YYYY'),
                obj.txtcolSetlDate = moment(readytopaydata[i].settlement_date).format('MM/DD/YYYY')

            } else if (readytopaydata[i].audit_insp_num !== "") {
              obj.txtcolDate = moment(readytopaydata[i].insp_date_time).format('MM/DD/YYYY'),
                obj.txtcolSetlDate = moment(readytopaydata[i].settlement_date).format('MM/DD/YYYY')

            } else if (readytopaydata[i].audit_whse_rcpt !== "") {
              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')
              obj.txtcolSetlDate = ""

            } else if (readytopaydata[i].audit_cr_check !== "" && readytopaydata[i].audit_cr_vendor !== "") {

              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')
              obj.txtcolSetlDate = ""

            } else if (readytopaydata[i].audit_cont_num !== "" && readytopaydata[i].rtp_proceeds !== "") {

              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')
              obj.txtcolSetlDate = ""

            } else if (readytopaydata[i].memo_num !== "" && readytopaydata[i].rtp_proceeds !== "") {
              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')
              obj.txtcolSetlDate = ""

            } else {
              obj.txtcolDate = moment(readytopaydata[i].chg_date_time).format('MM/DD/YYYY')
              obj.txtcolSetlDate = ""
            }
            gridReadyToPaysdata.push(obj)
            obj = {}
          }
        }
        setValue(thisObj, "gridReadyToPays", gridReadyToPaysdata);
      }
    })
  }
  // End for binding ReadyToPays

  // Start Method for binding RemitToList2
  const bFillRemitToList2 = (vendor) => {
    let js = []
    ContractManagementService.RetieveRemitDetails(vendor).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].city + ',' + data[i].state;
        let obj = { key: data[i].remittoid, description: description };
        js.push(obj);
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddRemitTo', js.at(0).key);
    })
  }
  const onbtnReturnToHoldPayClick = async () => {
    try {
      let Enabledisable = document.getElementById("btnUpdateRemarks").disabled
      if (Enabledisable == false) {
        From_Unload()
      }
      if (confirm("Do you wish to remove these Check Transactions from Batch?") == true) {
        let objsave =
        {
          "acct_vendor": "",
          "user_id": useridFromLS
        }

        let UpdateBatchReturnToHoldPayment = await AccountPayment.UpdateBatchReturnToHoldPayment(lblLocation, LstrBatchNumber, objsave)
        if (UpdateBatchReturnToHoldPayment.status !== 200) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        }
        else {
          setData(thisObj, 'refreshprintcheck', true)
          goTo(thisObj, "AccountPayment#PrintChecks#DEFAULT#false#Click");

        }


      }
    } catch (err) {
    }
  };
  thisObj.onbtnReturnToHoldPayClick = onbtnReturnToHoldPayClick;

  const onbtnUpdateRemarksClick = async () => {
    try {
      let txtarRemarks = getValue(thisObj, 'txtarRemarks') !== undefined ? getValue(thisObj, 'txtarRemarks') : ''
      let objsave =
      {
        "remarks": txtarRemarks,
        "user_id": useridFromLS
      }

      let UpdateBatchRemarks = await AccountPayment.UpdateBatchRemarks(LstrBatchNumber, objsave)
      if (UpdateBatchRemarks.status == 200) {
        alert('Remarks was updated successfully!')
      }
      else if (UpdateBatchRemarks.status !== 200) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }
      // else{
      //   showMessage(thisObj, 'Communication Error! Cannot connect to web service, please verify your network connection and retry.  Contact your system administrator if this problem persists.')
      // }

    } catch (err) {
    }
  };
  thisObj.onbtnUpdateRemarksClick = onbtnUpdateRemarksClick;

  const onbtnOkClick = () => {
    try {
      setData(thisObj, 'PrintcheckOk', true)
      let Enabledisable = document.getElementById("btnUpdateRemarks").disabled
      if (Enabledisable == false) {
        From_Unload()
      }
      goTo(thisObj, "AccountPayment#PrintChecks#DEFAULT#false#Click");
    } catch (err) {
    }
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  // close the screen
  const onbtnCancelClick = () => {
    try {
      let Enabledisable = document.getElementById("btnUpdateRemarks").disabled
      if (Enabledisable == false) {
        From_Unload()
      }
      document.getElementById("AccountPayment_CheckDetailsPopUp").childNodes[0].click();
    } catch (err) {
    }
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const ontxtarRemarksBlur = () => {
    let txtarRemarks = getValue(thisObj, 'txtarRemarks') !== undefined ? getValue(thisObj, 'txtarRemarks') : ''

    if (txtarRemarks !== '') {
      enable(thisObj, 'btnUpdateRemarks')
    } else {
      disable(thisObj, 'btnUpdateRemarks')
    }

  }
  thisObj.ontxtarRemarksBlur = ontxtarRemarksBlur


  const ontxtarRemarksChange = () => {
    let txtarRemarks = getValue(thisObj, 'txtarRemarks') !== undefined ? getValue(thisObj, 'txtarRemarks') : ''
    if (txtarRemarks !== '') {
      enable(thisObj, 'btnUpdateRemarks')
    } else {
      disable(thisObj, 'btnUpdateRemarks')
    }

  }
  thisObj.ontxtarRemarksChange = ontxtarRemarksChange
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CheckDetails*/}

              {/* END_USER_CODE-USER_BEFORE_CheckDetails*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              <GroupBoxWidget conf={state.grpbxCheckDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblCheckInformation*/}

                {/* END_USER_CODE-USER_BEFORE_lblCheckInformation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCheckInformation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCheckInformation*/}

                {/* END_USER_CODE-USER_AFTER_lblCheckInformation*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtLienholders*/}

                {/* END_USER_CODE-USER_BEFORE_txtLienholders*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLienholders}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLienholders*/}

                {/* END_USER_CODE-USER_AFTER_txtLienholders*/}
                {/* START_USER_CODE-USER_BEFORE_lblBatchedReadyToPays*/}

                {/* END_USER_CODE-USER_BEFORE_lblBatchedReadyToPays*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBatchedReadyToPays}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBatchedReadyToPays*/}

                {/* END_USER_CODE-USER_AFTER_lblBatchedReadyToPays*/}
                {/* START_USER_CODE-USER_BEFORE_gridReadyToPays*/}

                {/* END_USER_CODE-USER_BEFORE_gridReadyToPays*/}

                <GridWidget
                  conf={state.gridReadyToPays}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridReadyToPays}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridReadyToPays*/}

                {/* END_USER_CODE-USER_AFTER_gridReadyToPays*/}
                {/* START_USER_CODE-USER_BEFORE_lblRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_lblRemarks*/}

                <LabelWidget
                  values={values}
                  conf={state.lblRemarks}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblRemarks*/}

                {/* END_USER_CODE-USER_AFTER_lblRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_txtarRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtarRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtarRemarks*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckDetails*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnUpdateRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdateRemarks*/}

                <ButtonWidget
                  conf={state.btnUpdateRemarks}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdateRemarks*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdateRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_btnReturnToHoldPay*/}

                {/* END_USER_CODE-USER_BEFORE_btnReturnToHoldPay*/}

                <ButtonWidget
                  conf={state.btnReturnToHoldPay}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReturnToHoldPay*/}

                {/* END_USER_CODE-USER_AFTER_btnReturnToHoldPay*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_CheckDetails*/}

              {/* END_USER_CODE-USER_AFTER_CheckDetails*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_CheckDetails);
