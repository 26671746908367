/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,  
  getData
} from "../../shared/WindowImports";

import "./ViewWRPremiumDeductions.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ViewWRPremiumDeductions(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromSS = () => (sessionStorage.getItem('compId'));
  const cropYearFromSS = () => (JSON.parse(sessionStorage.getItem('year')));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewWRPremiumDeductions",
    windowName: "ViewWRPremiumDeductions",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ViewWRPremiumDeductions",
    // START_USER_CODE-USER_ViewWRPremiumDeductions_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1090C",
    },
    // END_USER_CODE-USER_ViewWRPremiumDeductions_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    
    colAmount: {
      name: "colAmount",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmount_PROPERTIES

      // END_USER_CODE-USER_colAmount_PROPERTIES
    },
    colPremDeduct: {
      name: "colPremDeduct",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Premium/Deductions",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDeduct_PROPERTIES

      // END_USER_CODE-USER_colPremDeduct_PROPERTIES
    },
    colTotal: {
      name: "colTotal",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Total",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotal_PROPERTIES

      // END_USER_CODE-USER_colTotal_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    gridPremsDeducts: {
      name: "gridPremsDeducts",
      type: "GridWidget",
      parent: "grpbxViewWRPremiumDeductions",
      gridCellsOrder:
        "txtcolPremDeduct,txtcolVendor,txtcolAmount",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPremsDeducts_PROPERTIES
      isExpandable: true,
      defaultExpand: true,
      showExpander: true,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
          {data.children ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.children.map((elem, i) =>
                <div className={"row ExpandRow" + " rdt_ExpanderRow" + i} key={"expandRow" + i} onClick={expandRowClicked}>
                  <div className="extraRowCell_2 ch-3 TableCell" key="expandCell1">
                    <label>
                      {elem.txtcolPremDeduct}
                    </label>
                  </div>
                  <div className="extraRowCell_4 ch-3 TableCell" key="expandCell3">
                    <label>
                      {elem.txtcolVendor}
                    </label>
                  </div>
                  <div className="extraRowCell_5 ch-3 TableCell" key="expandCell4">
                    <label>
                      {elem.txtcolAmount}
                    </label>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridPremsDeducts_PROPERTIES
    },
    lblPremsDeducts: {
      name: "lblPremsDeducts",
      type: "LabelWidget",
      parent: "grpbxViewWRPremiumDeductions",
      Label: "Premiums/Deductions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremsDeducts_PROPERTIES

      // END_USER_CODE-USER_lblPremsDeducts_PROPERTIES
    },
    txtcolAmount: {
      name: "txtcolAmount",
      type: "TextBoxWidget",
      colName: "colAmount",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolAmount_PROPERTIES
    },
    txtcolPremDeduct: {
      name: "txtcolPremDeduct",
      type: "TextBoxWidget",
      colName: "colPremDeduct",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremDeduct_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDeduct_PROPERTIES
    },

    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },

    grpbxViewWRPremiumDeductions: {
      name: "grpbxViewWRPremiumDeductions",
      type: "GroupBoxWidget",
      parent: "ViewWRPremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewWRPremiumDeductions_PROPERTIES

      // END_USER_CODE-USER_grpbxViewWRPremiumDeductions_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ViewWRPremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    let frmViewWRPremiumDeductionsloadDataobj = getData(thisObj, 'frmViewWRPremiumDeductionsloadData')
    if (frmViewWRPremiumDeductionsloadDataobj != null && frmViewWRPremiumDeductionsloadDataobj.lblWRNum != null && frmViewWRPremiumDeductionsloadDataobj.lblWRNum != "" && frmViewWRPremiumDeductionsloadDataobj.lblBuyingPtId != null && frmViewWRPremiumDeductionsloadDataobj.lblBuyingPtId != "") {      
      ReadPremiumsDeductions(frmViewWRPremiumDeductionsloadDataobj.lblWRNum, frmViewWRPremiumDeductionsloadDataobj.lblBuyingPtId);
    }
  }

  function ReadPremiumsDeductions(LstrwhseRcpt, LstrBuyPtID) {
    try {
      WarehouseReceiptService.RetrieveWarehousePremiumDeductionList(compIdFromSS(), cropYearFromSS(), LstrBuyPtID, LstrwhseRcpt).then(response => {
        let PremiumsDeductionsArray = [];
        if (response != undefined && response.length > 0) {
          let data = response;
          let obj = {};
          let rowId = 0;
          for (let i = 0; i < data.length; i++) {
            obj.rowId = i
            obj.txtcolPremDeduct = data[i].pd_code
            obj.txtcolVendor = data[i].payee_vendor
            obj.txtcolAmount = data[i].pd_amount
            PremiumsDeductionsArray.push(obj)
            obj = {}
          }
          let arrayWithParentRowIds = addParentRowIdForAllRows(PremiumsDeductionsArray); // This function adds Parent Ids for grouped rows
          let arrayWithAdditionalParentRowForGroupedRows = addParentRowForAllGroupedRows(arrayWithParentRowIds); // This function adds additional Parent rows for showing Total amount for all grouped rows
          let arrayWithGroupedRowsBasedOnParentIds = groupRowsBaedOnParentIds(arrayWithAdditionalParentRowForGroupedRows); // This function groups rows based on Parent Ids.
          setValue(thisObj, "gridPremsDeducts", arrayWithGroupedRowsBasedOnParentIds);
        }
      })
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Error occurred while binding premiums deductions data")
      }
    }
    finally {
      setLoading(false);
    }
  }

  // function for adding Parent Ids to grouped rows
  function addParentRowIdForAllRows(data) {
    try
    {
    let map = new Map();
    let newJs = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      if (map.has(data[i].txtcolPremDeduct)) {
        obj = { ...data[i] };
        obj.parentRowId = map.get(data[i].txtcolPremDeduct);
      }
      else {
        map.set(data[i].txtcolPremDeduct, data[i].rowId);
        obj = { ...data[i] };
        obj.parentRowId = null;
      }
      newJs.push(obj);
    }
    return newJs;
  } catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(thisObj, "Error occurred while binding addParentRowIdForAllRows data")
    }
  }
  }

  // function for adding additional Parent row for showing Total amount for all grouped rows
  function addParentRowForAllGroupedRows(rawArray) {
    let newArrayWithParentRows = [];
    try
    {
    let newParentobj = {};
    let oldParentobj = {};
    let otherRowobj = {};
    let newRowId = 0;
    let newParentRowId = 0;
    let subTotal = 0;
    for (let i = 0; i < rawArray.length; i++) {

      if (rawArray[i].parentRowId == null) {
        // Add New Parent with Total
        newRowId = i
        newParentRowId = newRowId
        newParentobj.parentRowId = null // Set null value for additional Parent row showing total values
        newParentobj.newRowId = newRowId
        newParentobj.txtcolPremDeduct = "Total " + rawArray[i].txtcolPremDeduct
        //newParentobj.txtcolDescription = ""
        newParentobj.txtcolVendor = ""

        //Add old Parent row as child row
        newRowId = newRowId + 1;
        oldParentobj.txtcolPremDeduct = rawArray[i].txtcolPremDeduct
        //oldParentobj.txtcolDescription = rawArray[i].txtcolDescription
        oldParentobj.txtcolVendor = rawArray[i].txtcolVendor
        oldParentobj.txtcolAmount = rawArray[i].txtcolAmount
        subTotal = subTotal + parseFloat(rawArray[i].txtcolAmount) // Doing sum of amount value for grouped rows
        oldParentobj.newRowId = newRowId
        oldParentobj.parentRowId = newParentRowId;
        newArrayWithParentRows.push(oldParentobj);
        oldParentobj = {};

        for (let j = i + 1; j < rawArray.length; j++) {
          if (rawArray[j] != undefined && rawArray[j] != null && rawArray[j].parentRowId != null && rawArray[j].parentRowId == rawArray[i].rowId) {
            newRowId = newRowId + 1;
            otherRowobj.newRowId = newRowId
            otherRowobj.parentRowId = newParentRowId;
            otherRowobj.txtcolPremiumsDeductions = "" // Setting blank value as old application shows value only for parent row
            otherRowobj.txtcolDescription = "" // Setting blank value as old application shows value only for parent row
            otherRowobj.txtcolVendor = rawArray[j].txtcolVendor
            otherRowobj.txtcolAmount = rawArray[j].txtcolAmount
            subTotal = subTotal + parseFloat(rawArray[j].txtcolAmount) // Doing sum of amount value for grouped rows
            newArrayWithParentRows.push(otherRowobj);
            otherRowobj = {};
          }
        }
        newParentobj.txtcolAmount = subTotal.toFixed(2) //setting sub total at then end for parent row
        newArrayWithParentRows.push(newParentobj);
        newParentobj = {};
        subTotal = 0;
      }
    }
    return newArrayWithParentRows;
  } catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(thisObj, "Error occurred while binding addParentRowForAllGroupedRows data")
    }
  }
  }

  //function for grouping rows based on Parent Ids.
  function groupRowsBaedOnParentIds(data, multi = null) {
    return data.reduce((r, e) => {
      if (e.parentRowId == multi) {
        const obj = { ...e };
        const children = groupRowsBaedOnParentIds(data, e.newRowId);
        if (children.length) {
          obj.children = children;
        }
        r.push(obj);
      }
      return r;
    }, [])
  }

   //function for expand row click event
   function expandRowClicked(event) {
    var elems = document.querySelectorAll(".activeExpandRow");
    elems.forEach.call(elems, function (el) {
      el.classList.remove("activeExpandRow");
    });
    var elem = document.querySelectorAll(".icTWDw");
    thisObj.state.gridContractGroupingSearchData.selected = [];
    elem.forEach.call(elem, function (el) {
      el.classList.remove("icTWDw");
      el.classList.add("eaQpfw");
    });
    if (event.target.classList.contains("TableCell")) {
      event.target.parentElement.classList.value += " activeExpandRow";
    }
    else {
      event.target.classList.value += " activeExpandRow";
    }
  }

    //function for Cancel button click event
    const onbtnCancelClick = () => {
      try {
        if (document.getElementById("WarehouseReceipts_ViewWRPremiumDeductionsPopUp").childNodes[0] != undefined && document.getElementById("WarehouseReceipts_ViewWRPremiumDeductionsPopUp").childNodes[0] != null) {
          document.getElementById("WarehouseReceipts_ViewWRPremiumDeductionsPopUp").childNodes[0].click()
        }
      } catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        } else {
          showMessage(
            thisObj,
            "Error occurred during View Warehouse Receipt Premium Deductions screen Cancel button click event"
          );
        }
        return false;
      }
      return true;
    };
    thisObj.onbtnCancelClick = onbtnCancelClick;
  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewWRPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_ViewWRPremiumDeductions*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewWRPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewWRPremiumDeductions*/}

              <GroupBoxWidget
                conf={state.grpbxViewWRPremiumDeductions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPremsDeducts*/}

                {/* END_USER_CODE-USER_BEFORE_lblPremsDeducts*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPremsDeducts}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPremsDeducts*/}

                {/* END_USER_CODE-USER_AFTER_lblPremsDeducts*/}
                {/* START_USER_CODE-USER_BEFORE_gridPremsDeducts*/}

                {/* END_USER_CODE-USER_BEFORE_gridPremsDeducts*/}

                <GridWidget
                  conf={state.gridPremsDeducts}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPremsDeducts}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPremsDeducts*/}

                {/* END_USER_CODE-USER_AFTER_gridPremsDeducts*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewWRPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewWRPremiumDeductions*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
               
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ViewWRPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_ViewWRPremiumDeductions*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ViewWRPremiumDeductions);
