/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  setValue,
  getValue,
  getData,
  setData,
  disable,
  enable,
  goTo,
} from "../../shared/WindowImports";

import "./CompanyProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { set } from "dot-object";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_CompanyProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let compIdFromLS = sessionStorage.getItem("compId");
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CompanyProfile",
    windowName: "CompanyProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.CompanyProfile",
    // START_USER_CODE-USER_CompanyProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Company Profile",
      scrCode: "PN0050B",
    },
    // END_USER_CODE-USER_CompanyProfile_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    chkboxAdmInterface: {
      name: "chkboxAdmInterface",
      type: "CheckBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "ADM Interface?",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAdmInterface_PROPERTIES

      // END_USER_CODE-USER_chkboxAdmInterface_PROPERTIES
    },
    ddActiveCorp: {
      name: "ddActiveCorp",
      type: "DropDownFieldWidget",
      parent: "grpbxCompanyDetails",
      Label: "Active Corp :",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddActiveCorp_PROPERTIES

      // END_USER_CODE-USER_ddActiveCorp_PROPERTIES
    },
    ddDefaultCorp: {
      name: "ddDefaultCorp",
      type: "DropDownFieldWidget",
      parent: "grpbxCompanyDetails",
      Label: "Default Corp :",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDefaultCorp_PROPERTIES

      // END_USER_CODE-USER_ddDefaultCorp_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxCompanyDetails",
      Label: "State :",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    ddState1: {
      name: "ddState1",
      type: "DropDownFieldWidget",
      parent: "grpbxWireTransferData",
      Label: "State :",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState1_PROPERTIES

      // END_USER_CODE-USER_ddState1_PROPERTIES
    },
    ddState2: {
      name: "ddState2",
      type: "DropDownFieldWidget",
      parent: "grpbxWireTransferData",
      Label: "State :",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState2_PROPERTIES

      // END_USER_CODE-USER_ddState2_PROPERTIES
    },
    grpbxCompanyDetails: {
      name: "grpbxCompanyDetails",
      type: "GroupBoxWidget",
      parent: "grpbxCompanyProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCompanyDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxCompanyDetails_PROPERTIES
    },
    grpbxWireTransferData: {
      name: "grpbxWireTransferData",
      type: "GroupBoxWidget",
      parent: "grpbxCompanyProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWireTransferData_PROPERTIES

      // END_USER_CODE-USER_grpbxWireTransferData_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblWireTransferData: {
      name: "lblWireTransferData",
      type: "LabelWidget",
      parent: "grpbxWireTransferData",
      Label: "Wire Transfer Data:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireTransferData_PROPERTIES

      // END_USER_CODE-USER_lblWireTransferData_PROPERTIES
    },
    txtAddress: {
      name: "txtAddress",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Address :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddress_PROPERTIES

      // END_USER_CODE-USER_txtAddress_PROPERTIES
    },
    txtAddress1: {
      name: "txtAddress1",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Address :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddress1_PROPERTIES

      // END_USER_CODE-USER_txtAddress1_PROPERTIES
    },
    txtAddress12: {
      name: "txtAddress12",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Address :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddress12_PROPERTIES

      // END_USER_CODE-USER_txtAddress12_PROPERTIES
    },
    txtAddress2: {
      name: "txtAddress2",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Address :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddress2_PROPERTIES

      // END_USER_CODE-USER_txtAddress2_PROPERTIES
    },
    txtADMComapnyID: {
      name: "txtADMComapnyID",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "ADM Comapny ID :",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtADMComapnyID_PROPERTIES

      // END_USER_CODE-USER_txtADMComapnyID_PROPERTIES
    },
    txtBankABANum: {
      name: "txtBankABANum",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Bank ABA #:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankABANum_PROPERTIES

      // END_USER_CODE-USER_txtBankABANum_PROPERTIES
    },
    txtBankAccountNum: {
      name: "txtBankAccountNum",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Bank Account #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 34 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankAccountNum_PROPERTIES

      // END_USER_CODE-USER_txtBankAccountNum_PROPERTIES
    },
    txtCity: {
      name: "txtCity",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: " City :",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity_PROPERTIES

      // END_USER_CODE-USER_txtCity_PROPERTIES
    },
    txtCity1: {
      name: "txtCity1",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "City :",
      LengthRange: { MinLength: 0, MaxLength: 25 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity1_PROPERTIES

      // END_USER_CODE-USER_txtCity1_PROPERTIES
    },
    txtCity2: {
      name: "txtCity2",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "City :",
      LengthRange: { MinLength: 0, MaxLength: 25 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity2_PROPERTIES

      // END_USER_CODE-USER_txtCity2_PROPERTIES
    },
    txtComapnyName: {
      name: "txtComapnyName",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Comapny Name :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtComapnyName_PROPERTIES

      // END_USER_CODE-USER_txtComapnyName_PROPERTIES
    },
    txtCompanyID: {
      name: "txtCompanyID",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Company ID :",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompanyID_PROPERTIES

      // END_USER_CODE-USER_txtCompanyID_PROPERTIES
    },
    txtEWRID: {
      name: "txtEWRID",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "EWR ID :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWRID_PROPERTIES

      // END_USER_CODE-USER_txtEWRID_PROPERTIES
    },
    txtLastFile: {
      name: "txtLastFile",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Last File #:",
      ColSpan: "3",
      Alignment: "Right",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLastFile_PROPERTIES

      // END_USER_CODE-USER_txtLastFile_PROPERTIES
    },
    txtlZip: {
      name: "txtlZip",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Zip :",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtlZip_PROPERTIES

      // END_USER_CODE-USER_txtlZip_PROPERTIES
    },
    txtPayerBank: {
      name: "txtPayerBank",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Payer Bank :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayerBank_PROPERTIES

      // END_USER_CODE-USER_txtPayerBank_PROPERTIES
    },
    txtPayerName: {
      name: "txtPayerName",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Payer Name :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayerName_PROPERTIES

      // END_USER_CODE-USER_txtPayerName_PROPERTIES
    },
    txtPhone: {
      name: "txtPhone",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Phone :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhone_PROPERTIES

      // END_USER_CODE-USER_txtPhone_PROPERTIES
    },
    txtSortOrder: {
      name: "txtSortOrder",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Sort Order :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSortOrder_PROPERTIES

      // END_USER_CODE-USER_txtSortOrder_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxCompanyDetails",
      Label: "Vendor :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtZip1: {
      name: "txtZip1",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Zip :",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZip1_PROPERTIES

      // END_USER_CODE-USER_txtZip1_PROPERTIES
    },
    txtZip2: {
      name: "txtZip2",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferData",
      Label: "Zip :",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZip2_PROPERTIES

      // END_USER_CODE-USER_txtZip2_PROPERTIES
    },
    grpbxCompanyProfile: {
      name: "grpbxCompanyProfile",
      type: "GroupBoxWidget",
      parent: "CompanyProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCompanyProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxCompanyProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "CompanyProfile",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  // Implement functionality for Form Load
  const FormLoad = async () => {
    let dataObj = await getData(thisObj, 'CompanyProfile');
    let chkboxAdmInterface = dataObj.chkboxAdmInterface;
    let cmdCaption = dataObj.cmdOk_Caption;
    setData(thisObj, 'mbSuccessfulLoad', true)
    //await bFormValid();    
    if (!await bFillDefaultCropList()) {
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
    if (!await bFillActiveCropList()) {
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
    if (chkboxAdmInterface == true) {
      document.getElementsByClassName("txtADMComapnyID")[0].style.color = "red";
    }
    else {
      document.getElementsByClassName("txtADMComapnyID")[0].style.color = "black";
    }
    // Fill form data
    if (cmdCaption == "Add") {
     // document.getElementById('btnOk').innerHTML = "Add"
     document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = "Add";
      if (!await bFillStateList()) {
        setData(thisObj, 'mbSuccessfulLoad', false);
      }
    }
    if (cmdCaption == "Update") {
      //document.getElementById('btnOk').innerText = "Update"
      document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = "Update";
      let lblAddedBy = dataObj.add_user + " " + moment(dataObj.add_date_time).format("MM/DD/YYYY h:mm:ss A");
      let lblChangedBy = dataObj.chg_user + " " + moment(dataObj.chg_date_time).format("MM/DD/YYYY h:mm:ss A");
      setValue(thisObj, 'txtCompanyID', dataObj.comp_id)
      if (chkboxAdmInterface == true) {
        setValue(thisObj, 'chkboxAdmInterface', true);
      } else {
        setValue(thisObj, 'chkboxAdmInterface', false);
      }
      setValue(thisObj, 'txtADMComapnyID', dataObj.adm_comp_id);
      setValue(thisObj, 'txtComapnyName', dataObj.comp_name);
      setValue(thisObj, 'txtAddress', dataObj.comp_address);
      setValue(thisObj, 'txtCity', dataObj.comp_city);
      //setValue(thisObj, 'ddState', dataObj.comp_state)
      setValue(thisObj, 'txtlZip', dataObj.comp_zip)
      setValue(thisObj, 'txtPhone', dataObj.comp_phone)
      setValue(thisObj, 'txtEWRID', dataObj.ewr_holder_id)
      setValue(thisObj, 'txtVendor', dataObj.comp_vendor)
      if(dataObj.sort_order=="0")
      {
      setValue(thisObj, 'txtSortOrder', "0")
      }
      else
      {
      setValue(thisObj, 'txtSortOrder', dataObj.sort_order)
      }
      setValue(thisObj, 'txtLastFile', dataObj.last_wire_file_num)
      setValue(thisObj, 'txtPayerName', dataObj.wire_org_name)
      setValue(thisObj, 'txtAddress1', dataObj.wire_org_address_1)
      setValue(thisObj, 'txtAddress12', dataObj.wire_org_address_2)
      setValue(thisObj, 'txtCity1', dataObj.wire_org_city)
      //setValue(thisObj, 'ddState1', dataObj.wire_org_state)
      setValue(thisObj, 'txtZip1', dataObj.wire_org_zip)
      setValue(thisObj, 'txtPayerBank', dataObj.wire_org_bank_name)
      setValue(thisObj, 'txtAddress2', dataObj.wire_org_bank_address)
      setValue(thisObj, 'txtCity2', dataObj.wire_org_bank_city)
      //setValue(thisObj, 'ddState2', dataObj.wire_org_bank_state)
      setValue(thisObj, 'txtZip2', dataObj.wire_org_bank_zip)
      setValue(thisObj, 'txtBankABANum', dataObj.wire_org_bank_aba)
      setValue(thisObj, 'txtBankAccountNum', dataObj.wire_org_bank_acct)
      setValue(thisObj, 'lblAddedByValue', lblAddedBy)
      setValue(thisObj, 'lblChangedByValue', lblChangedBy)
      if (!await bFillStateList(dataObj.comp_state,dataObj.wire_org_state,dataObj.wire_org_bank_state)) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(dataObj.allcontrolsenabled==false && dataObj.allcontrolsenabled!=undefined)
      {
disable(thisObj,"txtCompanyID");
disable(thisObj,"txtComapnyName");
disable(thisObj,"txtAddress");
disable(thisObj,"txtCity");
disable(thisObj,"txtCity");
disable(thisObj,"ddState");
disable(thisObj,"txtlZip");
disable(thisObj,"txtPhone");
disable(thisObj,"txtEWRID");
disable(thisObj,"txtVendor");
disable(thisObj,"txtSortOrder");
disable(thisObj,"txtLastFile");
disable(thisObj,"txtPayerName");
disable(thisObj,"txtAddress1");
disable(thisObj,"txtAddress12");
disable(thisObj,"txtCity1");
disable(thisObj,"ddState1");
disable(thisObj,"txtZip1");
disable(thisObj,"txtPayerBank");
disable(thisObj,"txtAddress2");
disable(thisObj,"txtCity2");
disable(thisObj,"ddState2");
disable(thisObj,"txtZip2");
disable(thisObj,"txtBankABANum");
disable(thisObj,"txtBankAccountNum");
disable(thisObj,"btnOk");
disable(thisObj,"txtADMComapnyID");
      }
    }
  }
  // Implement functionality for Form Validation
  const bFormValid = () => {
    try {
      let formValid = false;
      let txtID = getValue(thisObj, 'txtCompanyID');
      let chkboxAdmI = getValue(thisObj, 'chkboxAdmInterface');
      let ADMComapnyID = getValue(thisObj, 'txtADMComapnyID');
      let txtEWRID = getValue(thisObj, 'txtEWRID');
      let txtVendor = getValue(thisObj, 'txtVendor');
      let sortOrder = getValue(thisObj, 'txtSortOrder');
      let ComapnyName = getValue(thisObj, 'txtComapnyName');
      let LastFile = getValue(thisObj, 'txtLastFile');
      let PayerName = getValue(thisObj, 'txtPayerName');
      let Address1 = getValue(thisObj, 'txtAddress1');
      let City1 = getValue(thisObj, 'txtCity1');
      let state1 = getValue(thisObj, 'ddState1');
      let Zip1 = getValue(thisObj, 'txtZip1');
      let PayerBank = getValue(thisObj, 'txtPayerBank');
      let Address2 = getValue(thisObj, 'txtAddress2');
      let City2 = getValue(thisObj, 'txtCity2');
      let state2 = getValue(thisObj, 'ddState2');
      let Zip2 = getValue(thisObj, 'txtZip2');
      let BankABANum = getValue(thisObj, 'txtBankABANum');
      let BankAccountNum = getValue(thisObj, 'txtBankAccountNum');
      let Phone = getValue(thisObj, 'txtPhone');

      if (txtID == "" || txtID == null || txtID == undefined) {
        showMessage(thisObj, 'ID is a required field');
        return formValid;
      }
      if (sortOrder == "" || sortOrder == null || sortOrder == undefined) {
        sortOrder = "0";
      }
      if (chkboxAdmI == true) {
        if (ADMComapnyID == "" || ADMComapnyID == null || ADMComapnyID == undefined){
          showMessage(thisObj, 'ADM Comp ID is required when ADM Interface is checked');
          return formValid;
        }
      }
      if (ComapnyName == "" || ComapnyName == null || ComapnyName == undefined) {
        showMessage(thisObj, 'Name is a required field');
        return formValid;
      }
      if ((LastFile == undefined || LastFile == "") && (PayerName == undefined || PayerName == "") &&
        (Address1 == undefined || Address1 == "") && (Address2 == undefined || Address2 == "") &&
        (City1 == undefined || City1 == "") && (state1 == undefined || state1 == "") &&
        (Zip1 == undefined || Zip1 == "") && (PayerBank == undefined || PayerBank == "") &&
        (Address2 == undefined || Address2 == "") && (City2 == undefined || City2 == "") &&
        (state2 == undefined || state2 == "") && (Zip2 == undefined || Zip2 == "") &&
        (BankABANum == undefined || BankABANum == "") && (BankAccountNum == undefined || BankAccountNum == "")) {
        formValid = true;
        return formValid
      }
      if (LastFile == "" || LastFile == null || LastFile == undefined) {
        showMessage(thisObj, 'Last File# is required');
        return formValid;
      }
      if (PayerName == "" || PayerName == null || PayerName == undefined) {
        showMessage(thisObj, 'Payer Name is required');
        return formValid;
      }
      if (Address1 == "" || Address1 == null || Address1 == undefined) {
        showMessage(thisObj, 'Payer Address is required');
        return formValid;
      }
      if (City1 == "" || City1 == null || City1 == undefined) {
        showMessage(thisObj, 'Payer City is required');
        return formValid;
      }
      if (state1 == "" || state1 == null || state1 == undefined) {
        showMessage(thisObj, 'Payer State is required');
        return formValid;
      }
      if (Zip1 == "" || Zip1 == null || Zip1 == undefined) {
        showMessage(thisObj, 'Payer Zip is required');
        return formValid;
      }
      if (PayerBank == "" || PayerBank == null || PayerBank == undefined) {
        showMessage(thisObj, 'Bank Name is required');
        return formValid;
      }
      if (Address2 == "" || txtAddress2 == null || txtAddress2 == undefined) {
        showMessage(thisObj, 'Bank Address is required');
        return formValid;
      }
      if (City2 == "" || City2 == null || City2 == undefined) {
        showMessage(thisObj, 'Bank City is required');
        return formValid;
      }
      if (state2 == "" || state2 == null || state2 == undefined) {
        showMessage(thisObj, 'Bank State is required');
        return formValid;
      }
      if (Zip2 == "" || Zip2 == null || Zip2 == undefined) {
        showMessage(thisObj, 'Bank Zip is required');
        return formValid;
      }
      if (BankABANum == "" || BankABANum == null || BankABANum == undefined) {
        showMessage(thisObj, 'Bank ABA# is required');
        return formValid;
      }
      if (BankAccountNum == "" || BankAccountNum == null || BankAccountNum == undefined) {
        showMessage(thisObj, 'Bank Account# is required');
        return formValid;
      }
      return true;
    }
    catch (err) {
    }
  }
  //Implement Add and Update button functionality 
  const onbtnOkClick = () => {
    if (bFormValid() == true) {
      try {
        setLoading(true)
        let ParentObj = getData(thisObj, 'CompanyProfile');
        let chkboxAdmInterface = ParentObj.chkboxAdmInterface;
        let cmdCaption = ParentObj.cmdOk_Caption;
        let txtCID = getValue(thisObj, 'txtCompanyID');
        let chkboxAdmI = getValue(thisObj, 'chkboxAdmInterface');
        if (chkboxAdmI == true) {
          chkboxAdmI = "Y"
        } else {
          chkboxAdmI = "N"
        }
        let ADMComapnyID = getValue(thisObj, 'txtADMComapnyID');
        let address = getValue(thisObj, 'txtAddress');
        let city = getValue(thisObj, 'txtCity');
        let state = getValue(thisObj, 'ddState');
        let zip = getValue(thisObj, 'txtlZip');
        let Phone = getValue(thisObj, 'txtPhone');
        let EWRID = getValue(thisObj, 'txtEWRID');
        let vendor = getValue(thisObj, 'txtVendor');
        let sortOrder = getValue(thisObj, 'txtSortOrder');
        let defaultcropyear = getValue(thisObj, 'ddDefaultCorp');
        let activecropyear = getValue(thisObj, 'ddActiveCorp');
        let ComapnyName = getValue(thisObj, 'txtComapnyName');
        let LastFile = getValue(thisObj, 'txtLastFile');
        let PayerName = getValue(thisObj, 'txtPayerName');
        let Address1 = getValue(thisObj, 'txtAddress1');
        let City1 = getValue(thisObj, 'txtCity1');
        let state1 = getValue(thisObj, 'ddState1');
        let Zip1 = getValue(thisObj, 'txtZip1');
        let PayerBank = getValue(thisObj, 'txtPayerBank');
        let Address2 = getValue(thisObj, 'txtAddress2');
        let City2 = getValue(thisObj, 'txtCity2');
        let state2 = getValue(thisObj, 'ddState2');
        let Zip2 = getValue(thisObj, 'txtZip2');
        let BankABANum = getValue(thisObj, 'txtBankABANum');
        let BankAccountNum = getValue(thisObj, 'txtBankAccountNum');
        let wire_org_address_2=getValue(thisObj, 'txtAddress12');
        if (wire_org_address_2 == "" || wire_org_address_2 == undefined || wire_org_address_2 == null) {
          wire_org_address_2 = ""
        }
        if (ADMComapnyID == "" || ADMComapnyID == undefined || ADMComapnyID == null) {
          ADMComapnyID = ""
        }
        if (address == "" || address == undefined || address == null) {
          address = ""
        }
        if (address == "" || address == undefined || address == null) {
          address = ""
        }
        if (city == "" || city == undefined || city == null) {
          city = ""
        }
        if (state == "" || state == undefined || state == null) {
          state = ""
        }
        if (zip == "" || zip == undefined || zip == null) {
          zip = ""
        }
        if (Phone == "" || Phone == undefined || Phone == null) {
          Phone = ""
        }
        if (EWRID == "" || EWRID == undefined || EWRID == null) {
          EWRID = ""
        }
        if (vendor == "" || vendor == undefined || vendor == null) {
          vendor = ""
        }
        if (sortOrder == "" || sortOrder == undefined || sortOrder == null) {
          sortOrder = 0
        }
        if (LastFile == "" || LastFile == undefined || LastFile == null) {
          LastFile = null
        }
        if (PayerName == "" || PayerName == undefined || PayerName == null) {
          PayerName = ""
        }
        if (Address1 == "" || Address1 == undefined || Address1 == null) {
          Address1 = ""
        }
        if (City1 == "" || City1 == undefined || City1 == null) {
          City1 = ""
        }
        if (state1 == "" || state1 == undefined || state1 == null) {
          state1 = ""
        }
        if (Zip1 == "" || Zip1 == undefined || Zip1 == null) {
          Zip1 = ""
        }
        if (PayerBank == "" || PayerBank == undefined || PayerBank == null) {
          PayerBank = ""
        }
        if (Address2 == "" || Address2 == undefined || Address2 == null) {
          Address2 = ""
        }
        if (City2 == "" || City2 == undefined || City2 == null) {
          City2 = ""
        }
        if (state2 == "" || state2 == undefined || state2 == null) {
          state2 = ""
        }
        if (Zip2 == "" || Zip2 == undefined || Zip2 == null) {
          Zip2 = ""
        }
        if (BankAccountNum == "" || BankAccountNum == undefined || BankAccountNum == null) {
          BankAccountNum = ""
        }
        if (BankABANum == "" || BankABANum == undefined || BankABANum == null) {
          BankABANum = ""
        }
        if (chkboxAdmI == "" || chkboxAdmI == undefined || chkboxAdmI == null) {
          chkboxAdmI = "N"
        }
        let data = {
          "default_crop": defaultcropyear,
          "active_crop": activecropyear,
          "comp_name": ComapnyName,
          "comp_address": address,
          "comp_city": city,
          "comp_state": state,
          "comp_zip": zip,
          "comp_phone": Phone,
          "ewr_holder_id": EWRID,
          "comp_vendor": vendor,
          "sort_order": sortOrder,
          "last_wire_file_num": LastFile,
          "wire_org_name": PayerName,
          "wire_org_address_1": Address1,
          "wire_org_address_2": wire_org_address_2,
          "wire_org_city": City1,
          "wire_org_state": state1,
          "wire_org_zip": Zip1,
          "wire_org_bank_name": PayerBank,
          "wire_org_bank_address": Address2,
          "wire_org_bank_city": City2,
          "wire_org_bank_state": state2,
          "wire_org_bank_zip": Zip2,
          "wire_org_bank_acct": BankAccountNum,
          "wire_org_bank_aba": BankABANum,
          "adm_interface_ind": chkboxAdmI,
          "adm_comp_id": ADMComapnyID,
          "cid": compIdFromLS
        }

        let js = {
          txtName: ComapnyName,
          txtId: txtCID
        }
        let response
        if (cmdCaption == "Add") {
          SystemMaintenanceSpecialFunctionsService.CreateCompanyControl(txtCID,  data)
      .then(response => {
        if (response.status == 500) {
          showMessage(thisObj, 'An error occured while creating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
          setLoading(false)
        }
        if (response.status == 200) {
          setData(thisObj, 'CompanyProfile', true);
          setData(thisObj, 'CompanyProfileData', js);
         document.getElementById("SystemMaintenanceSpecialFunctions_CompanyProfilePopUp").childNodes[0].click(); 
         setLoading(false)
        }
      })
         
        }
        else {
          SystemMaintenanceSpecialFunctionsService.UpdateCompanyControl(txtCID,  data)
      .then(response => {
        if (response.status == 500) {
          showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
          setLoading(false)
        }
        if (response.status == 200) {
          setData(thisObj, 'CompanyProfile', true);
          setData(thisObj, 'CompanyProfileData', js);
          document.getElementById("SystemMaintenanceSpecialFunctions_CompanyProfilePopUp").childNodes[0].click(); 
          setLoading(false);
        }
        setLoading(false);
      })
        
        }
        setLoading(false);
      }
      catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
          setLoading(false)
        } else {
          showMessage(
            thisObj,
            "Error occurred during button Add Click",
            setLoading(false)
          );
        }
        return false;
      }
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick
  //Implement text boxes validation
  const ontxtCompanyIDChange = () => {
    let value = getValue(thisObj, 'txtCompanyID')
    setValue(thisObj, 'txtCompanyID', validatetxtCId(value))
  }
  thisObj.ontxtCompanyIDChange = ontxtCompanyIDChange
  //**
  const ontxtlZipChange = () => {
    let value = getValue(thisObj, 'txtlZip')
    setValue(thisObj, 'txtlZip', validatetxtZipCode(value))
  }
  thisObj.ontxtlZipChange = ontxtlZipChange
  //** */
  const ontxtPhoneChange = () => {
    let value = getValue(thisObj, 'txtPhone')
    setValue(thisObj, 'txtPhone', validatetxtPhone(value))
  }
  thisObj.ontxtPhoneChange = ontxtPhoneChange
  //** */
  const ontxtEWRIDChange = () => {
    let value = getValue(thisObj, 'txtEWRID')
    setValue(thisObj, 'txtEWRID', validatetxtEWRHolderID(value))
  }
  thisObj.ontxtEWRIDChange = ontxtEWRIDChange
  //** */
  const ontxtVendorChange = () => {
    let value = getValue(thisObj, 'txtVendor')
    setValue(thisObj, 'txtVendor', validatetxtVendor(value))
  }
  thisObj.ontxtVendorChange = ontxtVendorChange
  //** */
  const ontxtSortOrderChange = () => {
    let value = getValue(thisObj, 'txtSortOrder')
    setValue(thisObj, 'txtSortOrder', validatetxtSortOrder(value))
  }
  thisObj.ontxtSortOrderChange = ontxtSortOrderChange
  //** */
  const ontxtLastFileChange = () => {
    let value = getValue(thisObj, 'txtLastFile')
    setValue(thisObj, 'txtLastFile', validatextLastFileNum(value))
  }
  thisObj.ontxtLastFileChange = ontxtLastFileChange
  //** */
  const ontxtPayerNameChange = () => {
    let value = getValue(thisObj, 'txtPayerName')
    setValue(thisObj, 'txtPayerName', validatetxtPayName(value))
  }
  thisObj.ontxtPayerNameChange = ontxtPayerNameChange
  //** */
  // const ontxtAddressChange = () => {
  //   let value = getValue(thisObj, 'txtAddress')
  //   setValue(thisObj, 'txtAddress', validatetxtPayAddress1(value))
  // }
  // thisObj.ontxtAddressChange = ontxtAddressChange
  //** */
  const ontxtAddress1Change = () => {
    let value = getValue(thisObj, 'txtAddress1')
    setValue(thisObj, 'txtAddress1', validatetxtPayAddress1(value))
  }
  thisObj.ontxtAddress1Change = ontxtAddress1Change
  //** */
  const ontxtCity1Change = () => {
    let value = getValue(thisObj, 'txtCity1')
    setValue(thisObj, 'txtCity1', validatetxtPayCity(value))
  }
  thisObj.ontxtCity1Change = ontxtCity1Change
  //** */
  const ontxtZip1Change = () => {
    let value = getValue(thisObj, 'txtZip1')
    setValue(thisObj, 'txtZip1', validatetxtPayZipCode(value))
  }
  thisObj.ontxtZip1Change = ontxtZip1Change
  //** */
  const ontxtPayerBankChange = () => {
    let value = getValue(thisObj, 'txtPayerBank')
    setValue(thisObj, 'txtPayerBank', validatetxtBankName(value))
  }
  thisObj.ontxtPayerBankChange = ontxtPayerBankChange
  //** */
  const ontxtAddress2Change = () => {
    let value = getValue(thisObj, 'txtAddress2')
    setValue(thisObj, 'txtAddress2', validatetxtBankAddress(value))
  }
  thisObj.ontxtAddress2Change = ontxtAddress2Change
  //** */
  const ontxtCity2Change = () => {
    let value = getValue(thisObj, 'txtCity2')
    setValue(thisObj, 'txtCity2', validatetxtBankCity(value))
  }
  thisObj.ontxtCity2Change = ontxtCity2Change
  //** */
  const ontxtZip2Change = () => {
    let value = getValue(thisObj, 'txtZip2')
    setValue(thisObj, 'txtZip2', validatetxtBankZipCode(value))
  }
  thisObj.ontxtZip2Change = ontxtZip2Change
  //** */
  const ontxtBankABANumChange = () => {
    let value = getValue(thisObj, 'txtBankABANum')
    setValue(thisObj, 'txtBankABANum', validatetxtBankABANum(value))
  }
  thisObj.ontxtBankABANumChange = ontxtBankABANumChange
  //** */
  const ontxtBankAccountNumChange = () => {
    let value = getValue(thisObj, 'txtBankAccountNum')
    setValue(thisObj, 'txtBankAccountNum', validatetxtBankAccountNum(value))
  }
  thisObj.ontxtBankAccountNumChange = ontxtBankAccountNumChange
  //Implement functionality to bind state dropdown 
  const bFillStateList = async (comp_state,state1,state2) => {
    try {
      setLoading(true);
      let jsState = [], jsState1 = [], jsState2 = [];
      let dataObj = await getData(thisObj, 'CompanyProfile');
      jsState.push({ key: '', description: '>>> Select <<<' })
      jsState1.push({ key: '', description: '>>> Select <<<' })
      jsState2.push({ key: '', description: '>>> Select <<<' })
      await SystemMaintenanceSpecialFunctionsService.RetrieveStateControlDetails(null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].state_abbr.toString(),
            description: data[i].state_name.toString(),
            stateId: data[i].state_id,
            index: i
          }
          jsState.push(obj)
          jsState1.push(obj)
          jsState2.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddState: {
              ...state["ddState"],
              valueList: jsState,
            }
          }
        })
      })
      if (dataObj.cmdOk_Caption == "Update") {
        let stateIndex = 0;
        stateIndex = jsState.find(elem => elem.key == comp_state).index;
        setValue(thisObj, "ddState", jsState.at(stateIndex + 1).key);
        setLoading(false);
      }
      else {
        setValue(thisObj, 'ddState', jsState[0].key);
        setLoading(false);
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState1: {
            ...state["ddState1"],
            valueList: jsState1,
          }
        }
      })
      if (dataObj.cmdOk_Caption == "Update") {
        let stateIndex = 0;
        stateIndex = jsState1.find(elem => elem.key == state1).index;
        setValue(thisObj, "ddState1", jsState1.at(stateIndex + 1).key);
        setLoading(false);
      }
      else {
        setValue(thisObj, 'ddState1', jsState1[0].key);
        setLoading(false);
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState2: {
            ...state["ddState2"],
            valueList: jsState2,
          }
        }
      })
      if (dataObj.cmdOk_Caption == "Update") {
        let stateIndex = 0;
        stateIndex = jsState2.find(elem => elem.key == state2).index;
        setValue(thisObj, "ddState2", jsState2.at(stateIndex + 1).key);
        setLoading(false);
      }
      else {
        setValue(thisObj, 'ddState2', jsState2[0].key);
        setLoading(false);
      }
    }
    catch (err) {
      setLoading(false);
    }
  }
  //Implement functionality to bind Default crop year dropdown 
  const bFillDefaultCropList = async () => {
    try {
      setLoading(true)
      let response = await ContractManagementService.RetrieveCropYears();
      let data = response;
      let js = [];
      let defaultYear = data[0].default_Year
      let CurrenDate = new Date();
      let currentYear = (CurrenDate).getFullYear();
      if ((defaultYear != currentYear - 1) && (defaultYear != currentYear)) {
        js.push({ key: defaultYear.toString(), description: defaultYear.toString() })
      }
      js.push({ key: (currentYear - 1).toString(), description: (currentYear - 1).toString() },
        { key: currentYear.toString(), description: currentYear.toString() })
      thisObj.setState(current => {
        return {
          ...current,
          ddDefaultCorp: {
            ...state["ddDefaultCorp"],
            valueList: js
          }
        }
      })
      if(defaultYear == currentYear){
        setValue(thisObj, 'ddDefaultCorp', js.at(1).key);  
      }
      else{
        setValue(thisObj, 'ddDefaultCorp', js.at(0).key);
      }      
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  //Implement functionality to bind Default crop year dropdown 
  const bFillActiveCropList = async () => {
    try {
      setLoading(true)
      let response = await ContractManagementService.RetrieveCropYears();
      let data = response;
      let obj = [];
      let defaultYear = data[0].active_Year
      let CurrenDate = new Date();
      let cuR_SYS_date = data[0].cuR_SYS_DATE;
      let currentYear = (CurrenDate).getFullYear();
      if ((defaultYear != currentYear - 1) && (defaultYear != currentYear)) {
        obj = [{
          key: defaultYear.toString(),
          description: defaultYear.toString(),
        }]
      }
      obj = [{
        key: (currentYear - 1).toString(),
        description: (currentYear - 1).toString(),
      }, {
        key: currentYear.toString(),
        description: currentYear.toString(),
      }]
      thisObj.setState(current => {
        return {
          ...current,
          ddActiveCorp: {
            ...state["ddActiveCorp"],
            valueList: obj
          }
        }
      })
      if(defaultYear == currentYear){
        setValue(thisObj, 'ddActiveCorp', obj.at(1).key);  
      }
      else{
        setValue(thisObj, 'ddActiveCorp', obj.at(0).key);
      }      
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }

  }
  //Implement function for ADM Interface check box
  const onchkboxAdmInterfaceChange = async () => {
    try {
      let chkboxAdmI = getValue(thisObj, 'chkboxAdmInterface');
      if (chkboxAdmI === true) {
        document.getElementsByClassName("txtADMComapnyID")[0].style.color = "red";
      } else {
        document.getElementsByClassName("txtADMComapnyID")[0].style.color = "black";
      }
    }
    catch (err) {
      
    }
  }
  thisObj.onchkboxAdmInterfaceChange = onchkboxAdmInterfaceChange;
  // //Implement method for Unload
  // const From_Unload = () => {

  // }

  // $('.sr-only').on('DOMSubtreeModified',function(){
  //   alert('changed')
  // })
  // For Cancel Button
  const onbtnCancelClick = () => {
    try {
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#CompanySetup#DEFAULT#false#Click");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        //setLoading(false)
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    finally {
      //setLoading(false);
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  //Implement methods for txt Validation
  const validatetxtCId = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 &&
          data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtZipCode = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      // if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
      //   (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) &&
      //   (data[i].charCodeAt(0) != 32) && (data[i].charCodeAt(0) != 45)) {
      //   res += data[i]
      // }
      if (['-', ' ', '', '1', '2', '3','4','5','6','7','8','9','0'].includes(data[i])) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtPhone = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      // if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
      //   (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) &&
      //   (data[i].charCodeAt(0) != 32) || (data[i].charCodeAt(0) == 40) || (data[i].charCodeAt(0) == 41) ||
      //   (data[i].charCodeAt(0) == 45)) {
      //   res += data[i]
      // }
      if (['(', ')', '-', ' ', '', '1', '2', '3','4','5','6','7','8','9','0'].includes(data[i])) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtEWRHolderID = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 &&
          data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtVendor = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 &&
          data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtSortOrder = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatextLastFileNum = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtPayName = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) &&
        (data[i].charCodeAt(0) != 124) && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) &&
        (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60) && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtPayAddress1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) && (data[i].charCodeAt(0) != 124)
        && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) && (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60)
        && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtPayAddress2 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) && (data[i].charCodeAt(0) != 124)
        && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) && (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60)
        && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtPayCity = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) && (data[i].charCodeAt(0) != 124)
        && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) && (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60)
        && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtPayZipCode = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtBankName = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) && (data[i].charCodeAt(0) != 124)
        && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) && (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60)
        && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtBankAddress = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) && (data[i].charCodeAt(0) != 124)
        && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) && (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60)
        && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtBankCity = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) && (data[i].charCodeAt(0) != 124)
        && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) && (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60)
        && (data[i].charCodeAt(0) != 62)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtBankZipCode = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtBankABANum = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 &&
          data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }
  const validatetxtBankAccountNum = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 &&
          data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CompanyProfile*/}

              {/* END_USER_CODE-USER_BEFORE_CompanyProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCompanyProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCompanyProfile*/}

              <GroupBoxWidget
                conf={state.grpbxCompanyProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxCompanyDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCompanyDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxCompanyDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtCompanyID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCompanyID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCompanyID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCompanyID*/}

                  {/* END_USER_CODE-USER_AFTER_txtCompanyID*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxAdmInterface*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxAdmInterface*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxAdmInterface}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxAdmInterface*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxAdmInterface*/}
                  {/* START_USER_CODE-USER_BEFORE_txtADMComapnyID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtADMComapnyID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtADMComapnyID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtADMComapnyID*/}

                  {/* END_USER_CODE-USER_AFTER_txtADMComapnyID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtComapnyName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtComapnyName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtComapnyName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtComapnyName*/}

                  {/* END_USER_CODE-USER_AFTER_txtComapnyName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddress*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddress}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddress*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCity*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCity*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCity}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCity*/}

                  {/* END_USER_CODE-USER_AFTER_txtCity*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_txtlZip*/}

                  {/* END_USER_CODE-USER_BEFORE_txtlZip*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtlZip}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtlZip*/}

                  {/* END_USER_CODE-USER_AFTER_txtlZip*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhone*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhone*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhone}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhone*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhone*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEWRID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEWRID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWRID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEWRID*/}

                  {/* END_USER_CODE-USER_AFTER_txtEWRID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSortOrder*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSortOrder*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSortOrder}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSortOrder*/}

                  {/* END_USER_CODE-USER_AFTER_txtSortOrder*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDefaultCorp*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDefaultCorp*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDefaultCorp}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDefaultCorp*/}

                  {/* END_USER_CODE-USER_AFTER_ddDefaultCorp*/}
                  {/* START_USER_CODE-USER_BEFORE_ddActiveCorp*/}

                  {/* END_USER_CODE-USER_BEFORE_ddActiveCorp*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddActiveCorp}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddActiveCorp*/}

                  {/* END_USER_CODE-USER_AFTER_ddActiveCorp*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCompanyDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCompanyDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWireTransferData*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWireTransferData*/}

                <GroupBoxWidget
                  conf={state.grpbxWireTransferData}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblWireTransferData*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireTransferData*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireTransferData}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireTransferData*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireTransferData*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLastFile*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLastFile*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLastFile}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLastFile*/}

                  {/* END_USER_CODE-USER_AFTER_txtLastFile*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayerName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayerName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayerName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayerName*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayerName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddress1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddress1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddress1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                    <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddress12}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddress1*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddress1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCity1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCity1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCity1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCity1*/}

                  {/* END_USER_CODE-USER_AFTER_txtCity1*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState1*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState1*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState1}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState1*/}

                  {/* END_USER_CODE-USER_AFTER_ddState1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZip1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZip1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZip1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZip1*/}

                  {/* END_USER_CODE-USER_AFTER_txtZip1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayerBank*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayerBank*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayerBank}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayerBank*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayerBank*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddress2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddress2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddress2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddress2*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddress2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCity2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCity2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCity2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCity2*/}

                  {/* END_USER_CODE-USER_AFTER_txtCity2*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState2*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState2*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState2}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState2*/}

                  {/* END_USER_CODE-USER_AFTER_ddState2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZip2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZip2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZip2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZip2*/}

                  {/* END_USER_CODE-USER_AFTER_txtZip2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankABANum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankABANum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankABANum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankABANum*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankABANum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankAccountNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankAccountNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankAccountNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankAccountNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankAccountNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWireTransferData*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWireTransferData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCompanyProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCompanyProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_CompanyProfile*/}

              {/* END_USER_CODE-USER_AFTER_CompanyProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_CompanyProfile);
