/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable,
  hide

} from "../../shared/WindowImports";

import "./PeanutVarietySetup.scss";
import Loading from "../../../Loader/Loading";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from '../../Settlements/Service/SettlementService'
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PeanutVarietySetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PeanutVarietySetup",
    windowName: "PeanutVarietySetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PeanutVarietySetup",
    // START_USER_CODE-USER_PeanutVarietySetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Peanut Setup",
      scrCode: "PN0040A",
    },
    // END_USER_CODE-USER_PeanutVarietySetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnAddV: {
      name: "btnAddV",
      type: "ButtonWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddV_PROPERTIES

      // END_USER_CODE-USER_btnAddV_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnDeleteV: {
      name: "btnDeleteV",
      type: "ButtonWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteV_PROPERTIES

      // END_USER_CODE-USER_btnDeleteV_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnEditV: {
      name: "btnEditV",
      type: "ButtonWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditV_PROPERTIES

      // END_USER_CODE-USER_btnEditV_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Area:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Type:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    lstVariety: {
      name: "lstVariety",
      type: "ListBoxFieldWidget",
      parent: "grpbxPeanutVarietySetup",
      Label: "Variety:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstVariety_PROPERTIES

      // END_USER_CODE-USER_lstVariety_PROPERTIES
    },
    grpbxPeanutVarietySetup: {
      name: "grpbxPeanutVarietySetup",
      type: "GroupBoxWidget",
      parent: "PeanutVarietySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPeanutVarietySetup_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutVarietySetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "PeanutVarietySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#PeanutTypeProfile": {},
        "SystemMaintenanceMasterManagement#PeanutVarietyProfile": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#PeanutTypeProfile": {},
        "SystemMaintenanceMasterManagement#PeanutVarietyProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAdd: {
      DEFAULT: ["SystemMaintenanceMasterManagement#PeanutTypeProfile#DEFAULT#true#Click"]
    },
    // btnEdit: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#PeanutTypeProfile#DEFAULT#true#Click"]
    // },
    // btnAddV: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#PeanutVarietyProfile#DEFAULT#true#Click"]
    // },
    // btnEditV:{
    //   DEFAULT:["SystemMaintenanceMasterManagement#PeanutVarietyProfile#DEFAULT#true#Click"]
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddType", ''),
      setValue(thisObj, "ddArea", ''),
      setValue(thisObj, "lstVariety", '')
    EnableDisableControls()
    Formload()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    peanutSetup();
  }, [getData(thisObj, 'frmPeanutTypeSetup')])
  let peanutSetup = async () => {
    let newData = getData(thisObj, 'frmPeanutTypeSetup')
    if (newData !== null && newData != "" && newData != undefined) {
      await bFillTypeList();
    let typeList =thisObj.state['ddType'].valueList
      let pnut_type_id = newData.txtID
      let type_index = 0;
      if (typeList !== undefined && typeList !== null) {
        type_index = typeList.find(elem => elem.key == pnut_type_id).index;
      }
      setValue(thisObj, 'ddType', typeList.at(type_index).key)
      setData(thisObj, 'frmPeanutTypeSetup','')
    }
  }
  
  useEffect(() => {
    let dataobj = getData(thisObj, 'frmPeanutVarietyProfileSetup');
    if(dataobj != "" && dataobj != null && dataobj != undefined)
    {
    let pnutTypeId = dataobj.lblPeanutTypeValue;
    let areaId =dataobj.lblAreaValue;
    if (pnutTypeId != undefined && pnutTypeId != "" && pnutTypeId != null)
      if (areaId != undefined && areaId != "" && areaId != null) {
        bfillVarietyList(areaId, pnutTypeId)
      }
    }
    setData(thisObj, 'frmPeanutVarietyProfileSetup', '')
  }, [getData(thisObj, 'frmPeanutVarietyProfileSetup')])
  
  let Formload = async () => {
    GetSecurityRoles();
    await bFillTypeList(); // Method for binding Peanut Type dropdown
  }
  //dd Type on change
  const onddTypeChange = async () => {
    let ddType = getValue(thisObj, 'ddType'),
      ddArea = getValue(thisObj, 'ddArea')
    await bfillVarietyList(ddArea, ddType);
  }
  thisObj.onddTypeChange = onddTypeChange
  //dd Area on change
  const onddAreaChange = () => {
    let ddType = getValue(thisObj, 'ddType'),
      ddArea = getValue(thisObj, 'ddArea')
    bfillVarietyList(ddArea, ddType);
  }
  thisObj.onddAreaChange = onddAreaChange

  // EnableDisableControls
  const EnableDisableControls = async () => {
    enable(thisObj, 'btnAdd')
    let typeList = await thisObj.state['ddType'].valueList;
    let varietyList = getData(thisObj, 'varietyLength');
    if (typeList.length <= 0) {
      disable(thisObj, "btnEdit")
      disable(thisObj, "btnDelete")
      disable(thisObj, "btnAddV")
    }
    else {
      enable(thisObj, "btnEdit")
      enable(thisObj, "btnDelete")
      enable(thisObj, "btnAddV")
    }
    if (varietyList <= 0) {
      disable(thisObj, "btnEditV")
      disable(thisObj, "btnDeleteV")
    }
    else {
      enable(thisObj, "btnEditV")
      enable(thisObj, "btnDeleteV")
    }
  }

  //Dropddown for  Type List
  const bFillTypeList = async () => {
    let js = [];
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
    let data = response
    if (data != null && data != undefined) {
      if (data.length > 0) {
        enable(thisObj, "btnEdit")
        enable(thisObj, "btnDelete")
        enable(thisObj, "btnAddV")
        for (var i = 0; i < data.length; i++) {
          js.push({
            key: data[i].pnutTypeId,
            description: data[i].pnutTypeId + '-' + data[i].pnutTypeName,
           index: i
          })
        }
      }
      else
      {
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
        disable(thisObj, "btnAddV")
      }
    }
    setData(thisObj, 'typeLength', js.length);
    setData(thisObj, 'typeListData', js)
    setValue(thisObj, 'ddType', js.at(0).key);
    thisObj.setState(current => {
      return {
        ...current,
        ddType: {
          ...state["ddType"],
          valueList: js,
        }
      }
    })
    bFillAreaList(js.at(0).key)
  }

  //Dropddown for  area List
  const bFillAreaList = async (ddType) => {
    let js = [];
    let response = await SettlementService.RetrieveAreaControlDetails(null)
    let data = response
    if (data != null && data != undefined) {
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          js.push({
            key: data[i].areaId,
            description: data[i].areaId + '-' + data[i].areaName
          })
        }
      }
    }
    setValue(thisObj, 'ddArea', js.at(0).key);
    thisObj.setState(current => {
      return {
        ...current,
        ddArea: {
          ...state["ddArea"],
          valueList: js,
        }
      }
    })
    bfillVarietyList(js.at(0).key, ddType)
  }

  //Dropddown for 1st variety List
  const bfillVarietyList = async (ddArea, ddType) => {
    setLoading(true)
    let areaId = thisObj.values['ddArea']
    let js = [];
    let obj = {}
    let pnut_type_id = thisObj.values['ddType']
      let response = await ContractManagementService.RetrievePeanutVarietyControls(null, null, ddArea, ddType, null)
      if (response.length > 0 ) {
        enable(thisObj, "btnEditV")
        enable(thisObj, "btnDeleteV")
        js = []
        obj = {}
        setValue(thisObj, 'lstVariety', '')
        for (var i = 0; i < response.length; i++) {
          let symbol = ''
          switch (response[i].symbol_ind) {
            case "TRADEMARK":
              symbol = ' ᵀᴹ';
              break;
            case "REGISTERED TRADEMARK":
              symbol = ' ®';
              break;
            case "COPYRIGHT":
              symbol = ' ©';
              break;
            default:
              symbol = ''
          }
          let dataExist = js.find(elem => elem.key == response[i].pnut_variety_id + ' - ' + response[i].pnut_variety_name + symbol)
          if (dataExist == undefined) {
            obj = {
              key: response[i].pnut_variety_id,
              description: response[i].pnut_variety_id + ' - ' + response[i].pnut_variety_name + symbol
            }
            js.push(obj);
            obj = {}
          }
        }
        setData(thisObj, 'varietyLength', js.length);
      }
      else
      {
        disable(thisObj, "btnEditV")
        disable(thisObj, "btnDeleteV")
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstVariety: {
            ...state["lstVariety"],
            valueList: js
          }
        }
      });
    setLoading(false)
  }

  // security roles 
  const GetSecurityRoles = () => {
    let ACCESS = "D";
    let ACCESS2 = "U"
    ContractManagementService.RetrieveAccessPermissionDetails("PN0040", null, ACCESS, null).then(response => {
      let DeletePermission = response[0];
      if (DeletePermission.permission !== "Y") {
       hide(thisObj,'btnDelete',false)
       hide(thisObj,'btnDeleteV',false)
        ContractManagementService.RetrieveAccessPermissionDetails("PN0040", null, ACCESS2, null).then(response => {
          let UpdatePermission = response[0];
          if (UpdatePermission.permission !== "Y") {
            hide(thisObj,'btnAdd',false)
            hide(thisObj,'btnAddV',false)
            document.getElementById("btnEdit").innerText = "View"
            document.getElementById("btnEditV").innerText = "View"
            setData(thisObj, 'cmdEditType_Caption', 'View')
          }
        });
      }
    });
  }

  //Click event of peanut type Delete button
  const onbtnDeleteClick = async () => {
    try {
      let buy_pt_id = null
      let pnut_type_id = getValue(thisObj, "ddType")
      let TypeList = thisObj.state['ddType'].valueList
      let typeName = ''
      let typeIndex;
      if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
        typeName = TypeList.find(elem => elem.key == pnut_type_id).description;
        typeIndex = TypeList.find(elem => elem.key == pnut_type_id).index;
      }
      if (pnut_type_id == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm(`Are you sure you wish to delete the '${typeName}' Peanut Type?`)
        if (confirmBox == true) {
          let response = await SystemMaintenanceMasterManagementService.RemovePeanutTypeControl(buy_pt_id, pnut_type_id);
          if (![200, 400, 404].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          }
          else {
            showMessage(thisObj, response.message, true);
          }
          await  bFillTypeList( pnut_type_id)
          EnableDisableControls()
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  //Click event of peanut variety Delete button
  const onbtnDeleteVClick = async () => {
    try {
      let buy_pt_id = null
      let area_id = getValue(thisObj, "ddArea")
      let pnut_type_id = getValue(thisObj, "ddType")
      let pnut_variety_id = getValue(thisObj, "lstVariety")
      let varietyList = thisObj.state['lstVariety'].valueList
      let varietyName = ''
      let VarietyIndex;
      if (pnut_variety_id !== undefined && pnut_variety_id !== null && pnut_variety_id !== '') {
        varietyName = varietyList.find(elem => elem.key == pnut_variety_id).description;
        VarietyIndex = varietyList.find(elem => elem.key == pnut_variety_id).index;
      }
      let typeList = thisObj.state['ddType'].valueList
      let typeName = ''
      let TypeIndex;
      if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
        typeName = typeList.find(elem => elem.key == pnut_type_id).description;
        TypeIndex = typeList.find(elem => elem.key == pnut_type_id).index;
      }
      let areaList = thisObj.state['ddArea'].valueList
      let areaName = ''
      let areaIndex;
      if (area_id !== undefined && area_id !== null && area_id !== '') {
        areaName = areaList.find(elem => elem.key == area_id).description;
        areaIndex = areaList.find(elem => elem.key == area_id).index;
      }
      if (pnut_variety_id == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm(`Are you sure you wish to delete the '${varietyName}' Variety from Peanut Type '${typeName}' in the ${areaName}?`)
        if (confirmBox == true) {
          let response = await SystemMaintenanceMasterManagementService.RemovePeanutVarietyControl(buy_pt_id, area_id, pnut_type_id, pnut_variety_id);
          if (![200, 400, 404].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          }
          else {
            showMessage(thisObj, response.message, true);
            setData(thisObj, 'varietyLength', '')
          }
          await  bfillVarietyList(area_id, pnut_type_id)
          EnableDisableControls()

        }

      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  };
  thisObj.onbtnDeleteVClick = onbtnDeleteVClick;

  //Click event of peanut type Add button
  const onbtnAddClick = () => {
    try {
      let frmPeanutTypeProfile = {}
      frmPeanutTypeProfile.cmdOk_Caption = 'Add'
      frmPeanutTypeProfile.TxtIdEnabled = 'True'
      setData(thisObj, 'frmPeanutTypeProfile', frmPeanutTypeProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#PeanutTypeProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Add button"
        );
      }
      return false;
    }
    EnableDisableControls()
    return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;

  //Click event of peanut type Edit button
  const onbtnEditClick = async () => {
    let cmdEditType = getData(thisObj, 'cmdEditType_Caption')
    let pnut_type_id = thisObj.values['ddType'];//getValue(thisObj, "ddType");
    let response = await ContractManagementService.RetrievePeanutTypeControls(pnut_type_id)
    let data = response
    try {
      let frmPeanutTypeProfile = {}
      frmPeanutTypeProfile.txtID = data[0].pnutTypeId;
      frmPeanutTypeProfile.txtName = data[0].pnutTypeName;
      frmPeanutTypeProfile.txtOkGuage = data[0].okGuage;
      frmPeanutTypeProfile.txtSMK1Guage = data[0].smk1Guage;
      frmPeanutTypeProfile.txtSMK2Guage = data[0].smk2Guage;
      frmPeanutTypeProfile.txtSMK3Guage = data[0].smk3Guage;
      frmPeanutTypeProfile.txtSMK4Guage = data[0].smk4Guage;
      frmPeanutTypeProfile.txtLSKGuage = data[0].lskGuage;
      frmPeanutTypeProfile.txtAccountSubDepartment = data[0].acctSubDept;
      frmPeanutTypeProfile.lblAddedBy = data[0].addUser + " " + moment(data[0].addDateTime).format("MM/DD/YYYY h:mm:ss A")
      frmPeanutTypeProfile.lblChangedBy = data[0].chgUser + " " + moment(data[0].chgDateTime).format("MM/DD/YYYY h:mm:ss A")
      if (data[0].flexMktAllow == 'Y') {
        frmPeanutTypeProfile.grpboxFlexMarketAllowed = '1'
      }
      else {
        frmPeanutTypeProfile.grpboxFlexMarketAllowed = ''
      }
    if (cmdEditType == "View") {
      frmPeanutTypeProfile.cmdOk_Caption = "View"
      frmPeanutTypeProfile.cmdOk_Visible = false
    }
    else{
      frmPeanutTypeProfile.cmdOk_Caption = "Update"
    }
      setData(thisObj, 'frmPeanutTypeProfile', frmPeanutTypeProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#PeanutTypeProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Edit button"
        );
      }
      return false;
    }
    EnableDisableControls()
    return true;
  };
  thisObj.onbtnEditClick = onbtnEditClick;

  //Click event of peanut variety Add button
  const onbtnAddVClick = () => {
    try {
      let typeList = thisObj.state['ddType'].valueList
      let pnut_type_id = getValue(thisObj, "ddType");
      let typeName = ''
      if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
        typeName = typeList.find(elem => elem.key == pnut_type_id).description
      }
      let areaList = thisObj.state['ddArea'].valueList
      let areaId = getValue(thisObj, "ddArea");
      let areaName = ''
      if (areaId !== undefined && areaId !== null && areaId !== '') {
        areaName = areaList.find(elem => elem.key == areaId).description
      }
      let frmPeanutVarietyProfile = {}
      frmPeanutVarietyProfile.lblPeanutType_Caption = typeName;
      frmPeanutVarietyProfile.lblPeanutType_Tag = pnut_type_id;
      frmPeanutVarietyProfile.lblArea_Caption = areaName;
      frmPeanutVarietyProfile.lblArea_Tag = areaId;
      frmPeanutVarietyProfile.cmdOk_Caption = 'Add'
      frmPeanutVarietyProfile.txtID_enabled = 'True'
      setData(thisObj, 'frmPeanutVarietyProfile', frmPeanutVarietyProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#PeanutVarietyProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Add button"
        );
      }
      return false;
    }
    EnableDisableControls()
    return true;
  };
  thisObj.onbtnAddVClick = onbtnAddVClick;

  //Click event of peanut variety Edit button
  const onbtnEditVClick = async () => {
    try {
      let cmdEditType = getData(thisObj, 'cmdEditType_Caption')
      let pnut_type_id = thisObj.values['ddType'];
      let areaId = thisObj.values['ddArea'];
      let pnut_variety_id = getValue(thisObj, "lstVariety");
      let pnut_variety_name = getValue(thisObj, "lstVariety");
      let typeList = thisObj.state['ddType'].valueList
      let typeName = ''
      if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
        typeName = typeList.find(elem => elem.key == pnut_type_id).description
      }
      let areaList = thisObj.state['ddArea'].valueList
      let areaName = ''
      if (areaId !== undefined && areaId !== null && areaId !== '') {
        areaName = areaList.find(elem => elem.key == areaId).description
      }
      let response = await ContractManagementService.RetrievePeanutVarietyControls(null, null, areaId, pnut_type_id, pnut_variety_id)
      let data = response
      let frmPeanutVarietyProfile = {}
      frmPeanutVarietyProfile.txtID_enabled = 'True'
      frmPeanutVarietyProfile.lblPeanutType_Caption = typeName;
      frmPeanutVarietyProfile.lblPeanutType_Tag = pnut_type_id;
      frmPeanutVarietyProfile.lblArea_Caption = areaName;
      frmPeanutVarietyProfile.lblArea_Tag = areaId;
      frmPeanutVarietyProfile.txtID = data[0].pnut_variety_id;
      frmPeanutVarietyProfile.txtName = data[0].pnut_variety_name;
      frmPeanutVarietyProfile.lblAddedBy = data[0].add_user + " " +data[0].add_date_time;
      frmPeanutVarietyProfile.lblChangedBy = data[0].chg_user + " " + data[0].chg_date_time;
      frmPeanutVarietyProfile.symbol_ind = data[0].symbol_ind;
      frmPeanutVarietyProfile.oleic_ind = data[0].oleic_ind;

      if (data[0].oleic_addem_ind == "Y") {
        frmPeanutVarietyProfile.chkboxOleicAddendumAllowed = true;
      }
      if (cmdEditType == "View") {
        frmPeanutVarietyProfile.cmdOk_Caption = "View"
        frmPeanutVarietyProfile.cmdOk_Visible = false
      }
      else{
        frmPeanutVarietyProfile.cmdOk_Caption = "Update"
      }
    
      setData(thisObj, 'frmPeanutVarietyProfile', frmPeanutVarietyProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#PeanutVarietyProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Edit button"
        );
      }
      return false;
    }
    EnableDisableControls()
    return true;
  };
  thisObj.onbtnEditVClick = onbtnEditVClick;
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">

              {/* START_USER_CODE-USER_BEFORE_PeanutVarietySetup*/}

              {/* END_USER_CODE-USER_BEFORE_PeanutVarietySetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPeanutVarietySetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPeanutVarietySetup*/}

              <GroupBoxWidget
                conf={state.grpbxPeanutVarietySetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_ddType*/}

                {/* END_USER_CODE-USER_BEFORE_ddType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddType*/}

                {/* END_USER_CODE-USER_AFTER_ddType*/}
                {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddArea}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddArea*/}

                {/* END_USER_CODE-USER_AFTER_ddArea*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddV*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddV*/}

                <ButtonWidget
                  conf={state.btnAddV}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddV*/}

                {/* END_USER_CODE-USER_AFTER_btnAddV*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditV*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditV*/}

                <ButtonWidget
                  conf={state.btnEditV}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditV*/}

                {/* END_USER_CODE-USER_AFTER_btnEditV*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteV*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteV*/}

                <ButtonWidget
                  conf={state.btnDeleteV}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteV*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteV*/}
                {/* START_USER_CODE-USER_BEFORE_lstVariety*/}

                {/* END_USER_CODE-USER_BEFORE_lstVariety*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstVariety}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstVariety*/}

                {/* END_USER_CODE-USER_AFTER_lstVariety*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPeanutVarietySetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutVarietySetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_PeanutVarietySetup*/}

              {/* END_USER_CODE-USER_AFTER_PeanutVarietySetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_PeanutVarietySetup);
