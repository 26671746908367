/* eslint-disable*/
import React from "react";
import WarehouseReceipts_1007GrpSelect from "./1007GrpSelect";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("1007GrpSelect Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_1007GrpSelect />);
    });
  });
  afterEach(cleanup);
  test("is 1007GrpSelect Loads Successfully", () => {
    expect(screen.getByText("1007GrpSelect")).toBeInTheDocument;
  });
  test("Custom Test Cases for 1007GrpSelect", () => {
    // START_USER_CODE-USER_1007GrpSelect_Custom_Test_Case
    // END_USER_CODE-USER_1007GrpSelect_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_1007GrpSelect />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnGroupAndPrint(Button Widget) Test Cases", async () => {
    const btnGroupAndPrint = screen.getByTestId("btnGroupAndPrint");
    expect(btnGroupAndPrint).toBeInTheDocument;
    expect(btnGroupAndPrint.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_btnGroupAndPrint")
    );
  });
  test("Custom Test Cases for btnGroupAndPrint", () => {
    // START_USER_CODE-USER_btnGroupAndPrint_TEST
    // END_USER_CODE-USER_btnGroupAndPrint_TEST
  });
  test("btnReprintGrouping(Button Widget) Test Cases", async () => {
    const btnReprintGrouping = screen.getByTestId("btnReprintGrouping");
    expect(btnReprintGrouping).toBeInTheDocument;
    expect(btnReprintGrouping.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_btnReprintGrouping")
    );
  });
  test("Custom Test Cases for btnReprintGrouping", () => {
    // START_USER_CODE-USER_btnReprintGrouping_TEST
    // END_USER_CODE-USER_btnReprintGrouping_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridAvailable1007s(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s = screen.getByTestId("gridAvailable1007s");
    let gridAvailable1007sbtn =
      gridAvailable1007s.nextElementSibling.firstElementChild;
    gridAvailable1007s =
      gridAvailable1007s.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s.tagName).toBe("DIV");
    expect(gridAvailable1007s.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAvailable1007s", () => {
    // START_USER_CODE-USER_gridAvailable1007s_TEST
    // END_USER_CODE-USER_gridAvailable1007s_TEST
  });
  test("grpbx1007GrpSelect(GroupBox Widget) Test Cases", async () => {
    const grpbx1007GrpSelect = screen.getByTestId("grpbx1007GrpSelect");
    expect(grpbx1007GrpSelect.tagName).toBe("BUTTON");
    expect(grpbx1007GrpSelect.type).toBe("button");
    expect(grpbx1007GrpSelect.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007GrpSelect", () => {
    // START_USER_CODE-USER_grpbx1007GrpSelect_TEST
    // END_USER_CODE-USER_grpbx1007GrpSelect_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAvailable1007s(GroupBox Widget) Test Cases", async () => {
    const grpbxAvailable1007s = screen.getByTestId("grpbxAvailable1007s");
    expect(grpbxAvailable1007s.tagName).toBe("BUTTON");
    expect(grpbxAvailable1007s.type).toBe("button");
    expect(grpbxAvailable1007s.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAvailable1007s", () => {
    // START_USER_CODE-USER_grpbxAvailable1007s_TEST
    // END_USER_CODE-USER_grpbxAvailable1007s_TEST
  });
  test("grpbxDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails1 = screen.getByTestId("grpbxDetails1");
    expect(grpbxDetails1.tagName).toBe("BUTTON");
    expect(grpbxDetails1.type).toBe("button");
    expect(grpbxDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails1", () => {
    // START_USER_CODE-USER_grpbxDetails1_TEST
    // END_USER_CODE-USER_grpbxDetails1_TEST
  });
  test("grpbxDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails2 = screen.getByTestId("grpbxDetails2");
    expect(grpbxDetails2.tagName).toBe("BUTTON");
    expect(grpbxDetails2.type).toBe("button");
    expect(grpbxDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails2", () => {
    // START_USER_CODE-USER_grpbxDetails2_TEST
    // END_USER_CODE-USER_grpbxDetails2_TEST
  });
  test("lblAvailable1007s(Label Widget) Test Cases", async () => {
    const lblAvailable1007s = screen.getByTestId("lblAvailable1007s");
    expect(lblAvailable1007s.tagName).toBe("LABEL");
    expect(lblAvailable1007s.classList).toContain("form-label");
    expect(lblAvailable1007s.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_lblAvailable1007s")
    );
  });
  test("Custom Test Cases for lblAvailable1007s", () => {
    // START_USER_CODE-USER_lblAvailable1007s_TEST
    // END_USER_CODE-USER_lblAvailable1007s_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("lblGoupingNumber(Label Widget) Test Cases", async () => {
    const lblGoupingNumber = screen.getByTestId("lblGoupingNumber");
    expect(lblGoupingNumber.tagName).toBe("LABEL");
    expect(lblGoupingNumber.classList).toContain("form-label");
    expect(lblGoupingNumber.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_lblGoupingNumber")
    );
  });
  test("Custom Test Cases for lblGoupingNumber", () => {
    // START_USER_CODE-USER_lblGoupingNumber_TEST
    // END_USER_CODE-USER_lblGoupingNumber_TEST
  });
  test("gridAvailable1007s_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolBuyingPoint = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolBuyingPointbtn =
      gridAvailable1007s_txtcolBuyingPoint.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolBuyingPoint =
      gridAvailable1007s_txtcolBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridAvailable1007s_txtcolContractNum(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolContractNum = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolContractNumbtn =
      gridAvailable1007s_txtcolContractNum.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolContractNum =
      gridAvailable1007s_txtcolContractNum.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolContractNum.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolContractNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractNum", () => {
    // START_USER_CODE-USER_txtcolContractNum_TEST
    // END_USER_CODE-USER_txtcolContractNum_TEST
  });
  test("gridAvailable1007s_txtcolGroupingDate(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolGroupingDate = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolGroupingDatebtn =
      gridAvailable1007s_txtcolGroupingDate.nextElementSibling
        .firstElementChild;
    gridAvailable1007s_txtcolGroupingDate =
      gridAvailable1007s_txtcolGroupingDate.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolGroupingDate.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolGroupingDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGroupingDate", () => {
    // START_USER_CODE-USER_txtcolGroupingDate_TEST
    // END_USER_CODE-USER_txtcolGroupingDate_TEST
  });
  test("gridAvailable1007s_txtcolGroupingNum(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolGroupingNum = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolGroupingNumbtn =
      gridAvailable1007s_txtcolGroupingNum.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolGroupingNum =
      gridAvailable1007s_txtcolGroupingNum.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolGroupingNum.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolGroupingNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGroupingNum", () => {
    // START_USER_CODE-USER_txtcolGroupingNum_TEST
    // END_USER_CODE-USER_txtcolGroupingNum_TEST
  });
  test("gridAvailable1007s_txtcolOleic(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolOleic = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolOleicbtn =
      gridAvailable1007s_txtcolOleic.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolOleic =
      gridAvailable1007s_txtcolOleic.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolOleic.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOleic", () => {
    // START_USER_CODE-USER_txtcolOleic_TEST
    // END_USER_CODE-USER_txtcolOleic_TEST
  });
  test("gridAvailable1007s_txtcolOrganic(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolOrganic = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolOrganicbtn =
      gridAvailable1007s_txtcolOrganic.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolOrganic =
      gridAvailable1007s_txtcolOrganic.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolOrganic.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolOrganic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrganic", () => {
    // START_USER_CODE-USER_txtcolOrganic_TEST
    // END_USER_CODE-USER_txtcolOrganic_TEST
  });
  test("gridAvailable1007s_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolPeanutType = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolPeanutTypebtn =
      gridAvailable1007s_txtcolPeanutType.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolPeanutType =
      gridAvailable1007s_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolPeanutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridAvailable1007s_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolPeanutVariety = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolPeanutVarietybtn =
      gridAvailable1007s_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridAvailable1007s_txtcolPeanutVariety =
      gridAvailable1007s_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridAvailable1007s_txtcolSeedGrower(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolSeedGrower = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolSeedGrowerbtn =
      gridAvailable1007s_txtcolSeedGrower.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolSeedGrower =
      gridAvailable1007s_txtcolSeedGrower.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolSeedGrower.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolSeedGrower.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGrower", () => {
    // START_USER_CODE-USER_txtcolSeedGrower_TEST
    // END_USER_CODE-USER_txtcolSeedGrower_TEST
  });
  test("gridAvailable1007s_txtcolSegType(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolSegType = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolSegTypebtn =
      gridAvailable1007s_txtcolSegType.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolSegType =
      gridAvailable1007s_txtcolSegType.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolSegType.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolSegType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSegType", () => {
    // START_USER_CODE-USER_txtcolSegType_TEST
    // END_USER_CODE-USER_txtcolSegType_TEST
  });
  test("gridAvailable1007s_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolVendor = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolVendorbtn =
      gridAvailable1007s_txtcolVendor.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolVendor =
      gridAvailable1007s_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolVendor.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GrpSelect_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("txtContractNum(Textbox Widget) Test Cases", async () => {
    const txtContractNum = screen.getByTestId("txtContractNum");
    expect(txtContractNum.tagName).toBe("INPUT");
    expect(txtContractNum.type).toBe("text");
    expect(txtContractNum.classList).toContain("textboxWidgetClass");
    expect(txtContractNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_txtContractNum")
    );
    await act(async () => {
      userEvent.type(txtContractNum, "1");
    });
  });
  test("Custom Test Cases for txtContractNum", () => {
    // START_USER_CODE-USER_txtContractNum_TEST
    // END_USER_CODE-USER_txtContractNum_TEST
  });
  test("txtFarmNum(Textbox Widget) Test Cases", async () => {
    const txtFarmNum = screen.getByTestId("txtFarmNum");
    expect(txtFarmNum.tagName).toBe("INPUT");
    expect(txtFarmNum.type).toBe("text");
    expect(txtFarmNum.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFarmNum, "1");
    });
  });
  test("Custom Test Cases for txtFarmNum", () => {
    // START_USER_CODE-USER_txtFarmNum_TEST
    // END_USER_CODE-USER_txtFarmNum_TEST
  });
  test("txtNumber(Textbox Widget) Test Cases", async () => {
    const txtNumber = screen.getByTestId("txtNumber");
    expect(txtNumber.tagName).toBe("INPUT");
    expect(txtNumber.type).toBe("text");
    expect(txtNumber.classList).toContain("textboxWidgetClass");
    expect(txtNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_txtNumber")
    );
    await act(async () => {
      userEvent.type(txtNumber, "1");
    });
  });
  test("Custom Test Cases for txtNumber", () => {
    // START_USER_CODE-USER_txtNumber_TEST
    // END_USER_CODE-USER_txtNumber_TEST
  });
  test("txtTotalNetWeight(Textbox Widget) Test Cases", async () => {
    const txtTotalNetWeight = screen.getByTestId("txtTotalNetWeight");
    expect(txtTotalNetWeight.tagName).toBe("INPUT");
    expect(txtTotalNetWeight.type).toBe("text");
    expect(txtTotalNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtTotalNetWeight.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_txtTotalNetWeight")
    );
    await act(async () => {
      userEvent.type(txtTotalNetWeight, "123");
    });
    expect(txtTotalNetWeight.getAttribute("value")).toBe("");
    expect(txtTotalNetWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalNetWeight", () => {
    // START_USER_CODE-USER_txtTotalNetWeight_TEST
    // END_USER_CODE-USER_txtTotalNetWeight_TEST
  });
  test("txtTotalValue(Textbox Widget) Test Cases", async () => {
    const txtTotalValue = screen.getByTestId("txtTotalValue");
    expect(txtTotalValue.tagName).toBe("INPUT");
    expect(txtTotalValue.type).toBe("text");
    expect(txtTotalValue.classList).toContain("textboxWidgetClass");
    expect(txtTotalValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_txtTotalValue")
    );
    await act(async () => {
      userEvent.type(txtTotalValue, "123");
    });
    expect(txtTotalValue.getAttribute("value")).toBe("");
    expect(txtTotalValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalValue", () => {
    // START_USER_CODE-USER_txtTotalValue_TEST
    // END_USER_CODE-USER_txtTotalValue_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSelect_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
});
