/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PeanutVarietyProfile from "./PeanutVarietyProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PeanutVarietyProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutVarietyProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is PeanutVarietyProfile Loads Successfully", () => {
    expect(screen.getByText("PeanutVarietyProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for PeanutVarietyProfile", () => {
    // START_USER_CODE-USER_PeanutVarietyProfile_Custom_Test_Case
    // END_USER_CODE-USER_PeanutVarietyProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutVarietyProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxPeanutVariety(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutVariety = screen.getByTestId("grpbxPeanutVariety");
    expect(grpbxPeanutVariety.tagName).toBe("BUTTON");
    expect(grpbxPeanutVariety.type).toBe("button");
    expect(grpbxPeanutVariety.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutVariety", () => {
    // START_USER_CODE-USER_grpbxPeanutVariety_TEST
    // END_USER_CODE-USER_grpbxPeanutVariety_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblArea(Label Widget) Test Cases", async () => {
    const lblArea = screen.getByTestId("lblArea");
    expect(lblArea.tagName).toBe("LABEL");
    expect(lblArea.classList).toContain("form-label");
    expect(lblArea.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblArea")
    );
  });
  test("Custom Test Cases for lblArea", () => {
    // START_USER_CODE-USER_lblArea_TEST
    // END_USER_CODE-USER_lblArea_TEST
  });
  test("lblAreaValue(Label Widget) Test Cases", async () => {
    const lblAreaValue = screen.getByTestId("lblAreaValue");
    expect(lblAreaValue.tagName).toBe("LABEL");
    expect(lblAreaValue.classList).toContain("form-label");
    expect(lblAreaValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblAreaValue")
    );
  });
  test("Custom Test Cases for lblAreaValue", () => {
    // START_USER_CODE-USER_lblAreaValue_TEST
    // END_USER_CODE-USER_lblAreaValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblPeanutType(Label Widget) Test Cases", async () => {
    const lblPeanutType = screen.getByTestId("lblPeanutType");
    expect(lblPeanutType.tagName).toBe("LABEL");
    expect(lblPeanutType.classList).toContain("form-label");
    expect(lblPeanutType.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblPeanutType")
    );
  });
  test("Custom Test Cases for lblPeanutType", () => {
    // START_USER_CODE-USER_lblPeanutType_TEST
    // END_USER_CODE-USER_lblPeanutType_TEST
  });
  test("lblPeanutTypeValue(Label Widget) Test Cases", async () => {
    const lblPeanutTypeValue = screen.getByTestId("lblPeanutTypeValue");
    expect(lblPeanutTypeValue.tagName).toBe("LABEL");
    expect(lblPeanutTypeValue.classList).toContain("form-label");
    expect(lblPeanutTypeValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutVarietyProfile_lblPeanutTypeValue"
      )
    );
  });
  test("Custom Test Cases for lblPeanutTypeValue", () => {
    // START_USER_CODE-USER_lblPeanutTypeValue_TEST
    // END_USER_CODE-USER_lblPeanutTypeValue_TEST
  });
  test("txtID(Textbox Widget) Test Cases", async () => {
    const txtID = screen.getByTestId("txtID");
    expect(txtID.tagName).toBe("INPUT");
    expect(txtID.type).toBe("text");
    expect(txtID.classList).toContain("textboxWidgetClass");
    expect(txtID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_txtID")
    );
    await act(async () => {
      userEvent.type(txtID, "1");
    });
  });
  test("Custom Test Cases for txtID", () => {
    // START_USER_CODE-USER_txtID_TEST
    // END_USER_CODE-USER_txtID_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietyProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
});
