import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { DateTime } from "../common/DateTime";
import {getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,
  getTooltip,isDispFormatReqd,getDispFormat,captureDateFormat,prepareDatePickerOptions} from "../ParentWidgetFunc";
function DateWidget(props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
  captureDateFormat(conf);
    let pickerOptions = prepareDatePickerOptions(conf);
    let value = props.values[conf.name];
    // value = !value || value === "" ? moment() : value;
    let dateControl = (
      <React.Fragment>
        <DateTime
          value={value}
          id={conf.name}
          name={conf.name}
          className="input-group date"
          pickerOptions={pickerOptions}
          disabled={!conf.Enabled}
          readOnly={conf.ReadOnly}
		  placeholder={conf.placeholder}
		  autoComplete={conf.AutoCompleteForDate}
          onChange={e => {
            props.onChange(e);
          }}
          onBlur={props.onBlur}
          minDateRef={conf.minDate}
          maxDateRef={conf.maxDate}
        />
        <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        title={getTooltip(conf)}
        role="tooltip"
          >
            <div
              className={getFieldContentClasses(screenConf).join(" ")}
              style={getFieldContentStyle(conf)}
            >
              <Form.Label
                className={screenConf.horizontalForm ? "pt-1" : ""}
                style={getVisibility(conf,screenConf)}
              >
                {conf.Label}
                {isDispFormatReqd() ? <div>{getDispFormat(conf)}</div> : ""}
              </Form.Label>
              {screenConf.horizontalForm ? (
                <Col>{dateControl}</Col>
              ) : (
                <React.Fragment> {dateControl}</React.Fragment>
              )}
            </div>
      </Form.Group>
    );
  
}

export default DateWidget;
