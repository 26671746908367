/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  setData,
  getValue,
  getData,
  enable,
  disable,
  goTo,
  isEnabled,

} from "../../shared/WindowImports";

import "./PremiumDeductionScheduleProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile(
  props
) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  const useridFromLS = sessionStorage.getItem('userid')
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionScheduleProfile",
    windowName: "PremiumDeductionScheduleProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PremiumDeductionScheduleProfile",
    // START_USER_CODE-USER_PremiumDeductionScheduleProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premium Deduction Schedule Profile",
      scrCode: "PN0120B",
    },
    // END_USER_CODE-USER_PremiumDeductionScheduleProfile_PROPERTIES
    btnAddContract: {
      name: "btnAddContract",
      type: "ButtonWidget",
      parent: "grpbxContracts",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddContract_PROPERTIES

      // END_USER_CODE-USER_btnAddContract_PROPERTIES
    },
    btnAddVendor: {
      name: "btnAddVendor",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddVendor_PROPERTIES

      // END_USER_CODE-USER_btnAddVendor_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btncmdEnableDisableControls: {
      name: "btncmdEnableDisableControls",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "cmdEnableDisableControls",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncmdEnableDisableControls_PROPERTIES

      // END_USER_CODE-USER_btncmdEnableDisableControls_PROPERTIES
    },
    btnDeleteContract: {
      name: "btnDeleteContract",
      type: "ButtonWidget",
      parent: "grpbxContracts",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteContract_PROPERTIES

      // END_USER_CODE-USER_btnDeleteContract_PROPERTIES
    },
    btnDeleteVendor: {
      name: "btnDeleteVendor",
      type: "ButtonWidget",
      parent: "grpbxVendors",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteVendor_PROPERTIES

      // END_USER_CODE-USER_btnDeleteVendor_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPayeeVendor: {
      name: "btnPayeeVendor",
      type: "ButtonWidget",
      parent: "grpbxPayee",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPayeeVendor_PROPERTIES

      // END_USER_CODE-USER_btnPayeeVendor_PROPERTIES
    },
    chkboxEnableSEG: {
      name: "chkboxEnableSEG",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxProfileDetails",
      Options: "SEG 1 Enable:1,SEG 2 Enable:2,SEG 3 Enable:3",
      Label: "Enable SEG:",
      ColSpanForLargeDesktop: "2",
      ColSpanForTabLandscape: "2",
      ColSpanForTabPotrait: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxEnableSEG_PROPERTIES

      // END_USER_CODE-USER_chkboxEnableSEG_PROPERTIES
    },
    ddApplyType: {
      name: "ddApplyType",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Apply Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddApplyType_PROPERTIES

      // END_USER_CODE-USER_ddApplyType_PROPERTIES
    },
    ddAuto: {
      name: "ddAuto",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Auto:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAuto_PROPERTIES

      // END_USER_CODE-USER_ddAuto_PROPERTIES
    },
    ddBaseWeight: {
      name: "ddBaseWeight",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Base Weight:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBaseWeight_PROPERTIES

      // END_USER_CODE-USER_ddBaseWeight_PROPERTIES
    },
    ddBeforeSeedReveivable: {
      name: "ddBeforeSeedReveivable",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Before Seed Receivable:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBeforeSeedReveivable_PROPERTIES

      // END_USER_CODE-USER_ddBeforeSeedReveivable_PROPERTIES
    },
    ddContractSpec: {
      name: "ddContractSpec",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Contract Spec:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddContractSpec_PROPERTIES

      // END_USER_CODE-USER_ddContractSpec_PROPERTIES
    },
    ddInspection: {
      name: "ddInspection",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Inspection:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddInspection_PROPERTIES

      // END_USER_CODE-USER_ddInspection_PROPERTIES
    },
    ddPayeeRemitTo: {
      name: "ddPayeeRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxPayee",
      Label: "Payee Remit To:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPayeeRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddPayeeRemitTo_PROPERTIES
    },
    ddPDCode: {
      name: "ddPDCode",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Premium Deduction Code:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPDCode_PROPERTIES

      // END_USER_CODE-USER_ddPDCode_PROPERTIES
    },
    ddRateType: {
      name: "ddRateType",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Rate Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateType_PROPERTIES

      // END_USER_CODE-USER_ddRateType_PROPERTIES
    },
    ddRequired: {
      name: "ddRequired",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Required:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRequired_PROPERTIES

      // END_USER_CODE-USER_ddRequired_PROPERTIES
    },
    ddVendorSpec: {
      name: "ddVendorSpec",
      type: "DropDownFieldWidget",
      parent: "grpbxProfileDetails",
      Label: "Vendor Spec:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendorSpec_PROPERTIES

      // END_USER_CODE-USER_ddVendorSpec_PROPERTIES
    },
    grpbxContracts: {
      name: "grpbxContracts",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionScheduleProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxContracts_PROPERTIES

      // END_USER_CODE-USER_grpbxContracts_PROPERTIES
    },
    grpbxPayee: {
      name: "grpbxPayee",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionScheduleProfile",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPayee_PROPERTIES

      // END_USER_CODE-USER_grpbxPayee_PROPERTIES
    },
    grpbxProfileDetails: {
      name: "grpbxProfileDetails",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionScheduleProfile",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxProfileDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxProfileDetails_PROPERTIES
    },
    grpbxVendors: {
      name: "grpbxVendors",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionScheduleProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxVendors_PROPERTIES

      // END_USER_CODE-USER_grpbxVendors_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblContracts: {
      name: "lblContracts",
      type: "LabelWidget",
      parent: "grpbxContracts",
      Label: "Contracts:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContracts_PROPERTIES

      // END_USER_CODE-USER_lblContracts_PROPERTIES
    },
    lblVendors: {
      name: "lblVendors",
      type: "LabelWidget",
      parent: "grpbxVendors",
      Label: "Vendors:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendors_PROPERTIES

      // END_USER_CODE-USER_lblVendors_PROPERTIES
    },
    lstContracts: {
      name: "lstContracts",
      type: "ListBoxFieldWidget",
      parent: "grpbxContracts",
      ColSpan: "3",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstContracts_PROPERTIES

      // END_USER_CODE-USER_lstContracts_PROPERTIES
    },
    lstVendors: {
      name: "lstVendors",
      type: "ListBoxFieldWidget",
      parent: "grpbxVendors",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstVendors_PROPERTIES

      // END_USER_CODE-USER_lstVendors_PROPERTIES
    },
    txtApplyOrder: {
      name: "txtApplyOrder",
      type: "TextBoxWidget",
      parent: "grpbxProfileDetails",
      Label: "Apply Order:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtApplyOrder_PROPERTIES

      // END_USER_CODE-USER_txtApplyOrder_PROPERTIES
    },
    txtBasePercentage: {
      name: "txtBasePercentage",
      type: "TextBoxWidget",
      parent: "grpbxProfileDetails",
      Label: "Base Percentage:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBasePercentage_PROPERTIES

      // END_USER_CODE-USER_txtBasePercentage_PROPERTIES
    },
    txtBaseRate: {
      name: "txtBaseRate",
      type: "TextBoxWidget",
      parent: "grpbxProfileDetails",
      Label: "Base Rate:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBaseRate_PROPERTIES

      // END_USER_CODE-USER_txtBaseRate_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxProfileDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES
      ReadOnly: true,
      Enable: false
      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtPayeeVendor: {
      name: "txtPayeeVendor",
      type: "TextBoxWidget",
      parent: "grpbxPayee",
      Label: "Payee Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayeeVendor_PROPERTIES

      // END_USER_CODE-USER_txtPayeeVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxPayee",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES
      Enable: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxPremiumDeductionScheduleProfile: {
      name: "grpbxPremiumDeductionScheduleProfile",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionScheduleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPremiumDeductionScheduleProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionScheduleProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionScheduleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#PremiumDeductionContractProfile": {},
        "SystemMaintenanceMasterManagement#PremiumDeductionVendorProfile": {},
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#PremiumDeductionVendorProfile": {},
        "SystemMaintenanceMasterManagement#PremiumDeductionContractProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAddContract: {
    //   DEFAULT: [
    //     "SystemMaintenanceMasterManagement#PremiumDeductionContractProfile#DEFAULT#true#Click",]
    // },
    // btnAddVendor: {
    //   DEFAULT: [
    //     "SystemMaintenanceMasterManagement#PremiumDeductionVendorProfile#DEFAULT#true#Click",]
    // },
    // btnPayeeVendor: {
    //   DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const [loading, setLoading] = useState(false);


  // START_USER_CODE-USER_METHODS

  useEffect(() => {
    const vendorDetails = getData(thisObj, 'vendorDetails');
    if (vendorDetails) {
      setValue(thisObj, 'txtPayeeVendor', vendorDetails?.VendorNumber || '');
      fillRemitListForVendor(vendorDetails?.VendorNumber);
      setData(thisObj, 'vendorDetails', null)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    const newVendor = getData(thisObj, 'PremiumDeductionNewVendor');
    if (newVendor) {
      const prevValueList = thisObj.state['lstVendors'].valueList;
      const filteredList = prevValueList.filter(item => Boolean(item?.key) && Boolean(item?.description));
      let obj = { key: newVendor.key, description: newVendor.description, index: prevValueList.length + 1 }
      filteredList.push(obj);

      thisObj.setState(current => {
        return {
          ...current,
          lstVendors: {
            ...state["lstVendors"],
            valueList: filteredList,
          }
        }
      });
      document.getElementById('lstVendors').focus();
    }
  }, [getData(thisObj, 'PremiumDeductionNewVendor')]);

  useEffect(() => {
    const newContract = getData(thisObj, 'scheduledetails');
    if (newContract) {
      const prevValueList = thisObj.state['lstContracts'].valueList;
      const filteredList = prevValueList.filter(item => Boolean(item?.key) && Boolean(item?.description));
      let obj = { key: newContract.contract, description: newContract.contract, index: prevValueList.length + 1 }
      filteredList.push(obj);

      thisObj.setState(current => {
        return {
          ...current,
          lstContracts: {
            ...state["lstContracts"],
            valueList: filteredList,
          }
        }
      });
      document.getElementById('lstContracts').focus();
    }
  }, [getData(thisObj, 'scheduledetails')]);

  useEffect(() => {
    const enableDisableControlFlag = getData(thisObj, 'frmPremiumDeductionScheduleSetupEnableDisableControls');
    if (enableDisableControlFlag === true) {
      enableDisableControlsPDSP();
      setData(thisObj, 'frmPremiumDeductionScheduleSetupEnableDisableControls', false)
    }
  }, [getData(thisObj, 'frmPremiumDeductionScheduleSetupEnableDisableControls')]);

  useEffect(() => {
    const enableDisableControlFlag = getData(thisObj, 'frmPremiumDeductionScheduleProfileEnableDisableControls');
    if (enableDisableControlFlag === true) {
      enableDisableControlsPDSP();
      setData(thisObj, 'frmPremiumDeductionScheduleProfileEnableDisableControls', false)
    }
  }, [getData(thisObj, 'frmPremiumDeductionScheduleProfileEnableDisableControls')]);


  useEffect(() => {
    formLoad()
  }, []);


  const formLoad = async () => {
    setLoading(true)
    let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
    let ApplyTypeObj = [{ key: 'PUR', description: 'Purchase' },
    { key: 'STO', description: 'Storage' },
    { key: 'BTH', description: 'Both' }]
    setData(thisObj, 'ApplyTypeDDVals', ApplyTypeObj)
    let rateTypeObj = [{ key: 'POUND', description: '$ per Pound' },
    { key: 'TON', description: '$ per Ton' },
    { key: 'LOAD', description: '$ per Load' },
    { key: 'ENTRY', description: 'Enter at Load Time' },
    { key: 'PCTSEG', description: '% of Seg Value' },
    { key: 'SD_ADM', description: 'Seed Grower Addendum' },
    { key: 'LP_ADM', description: 'Landplaster Addendum' },
    { key: 'O_ADM', description: 'OLEIC Addendum' },
    { key: 'CT_ADV', description: 'Contract Advance' },
    { key: 'FUEL', description: 'Fuel Surcharge' },
    { key: 'LEASE', description: 'Trailer Lease' },
    { key: 'AR', description: 'AR' },
    { key: 'SR', description: 'SR' },
    { key: 'CR_ACT', description: 'CR Accounts' },
    { key: 'CURCLN', description: 'Cure & Cleaning' },]
    setData(thisObj, 'rateTypeJSSSS', rateTypeObj)
    let BasewtObj = [{ key: 'GROSS', description: 'Gross' },
    { key: 'NET', description: 'Net' },
    { key: 'NETLSK', description: 'NetLSK' },
    { key: '', description: 'None' }]
    // let yesNo = [{ key: 'Y', description: 'Yes' }, { key: 'N', description: 'No' }]

    if (prntData.cmdOk_Caption == 'Add') {
      document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = 'Add'
      setValue(thisObj, 'txtBuyingPoint', prntData.lblBuyingPoint_Caption)
      await bFillPDCodeList()
      bFillInspectIndList()
      bFillAutoIndList()
      bFillRequiredIndList()
      bFillBeforeRecvIndList()
      setValue(thisObj, 'chkboxEnableSEG', ['1', '2', '3'])
      bFillVendorSpecIndList()
      bFillContractSpecIndList()
      await bFillRateTypeList()
      bFillBaseWtList()
      await bFillApplyTypeList()
      enableDisableControlsPDSP()
      // ddPDCodeChangeForFormLoad()
      // onddRateTypeChange()
    }

    if (prntData.cmdOk_Caption == 'Update') {
      document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = 'Update'
      setValue(thisObj, 'txtBuyingPoint', prntData.lblBuyingPoint_Caption)
      if (prntData.cmbPDCode_enabled == false) {
        disable(thisObj, 'ddPDCode')
      }

      await bFillPDCodeList(prntData.lstPDCodeID)
      bFillInspectIndList(prntData.lstInspectID)
      bFillAutoIndList(prntData.lstAutoInd)
      bFillRequiredIndList(prntData.lstRequiredInd)
      bFillBeforeRecvIndList(prntData.lstBeforeRecvInd)
      if (prntData.txtApplyOrder != null && prntData.txtApplyOrder != undefined && prntData.txtApplyOrder !== '') {
        setValue(thisObj, 'txtApplyOrder', prntData.txtApplyOrder.toString())
      }
      if (prntData.chkSeg1Enable == '1' && prntData.chkSeg2Enable == '1' && prntData.chkSeg3Enable == '1') {
        setValue(thisObj, 'chkboxEnableSEG', ['1', '2', '3'])
      }

      if (prntData.chkSeg1Enable == '1' && prntData.chkSeg2Enable == '' && prntData.chkSeg3Enable == '1') {
        setValue(thisObj, 'chkboxEnableSEG', ['1', '3'])
      }

      if (prntData.chkSeg1Enable == '1' && prntData.chkSeg2Enable == '1' && prntData.chkSeg3Enable == '') {
        setValue(thisObj, 'chkboxEnableSEG', ['1', '2'])
      }

      if (prntData.chkSeg1Enable == '' && prntData.chkSeg2Enable == '1' && prntData.chkSeg3Enable == '1') {
        setValue(thisObj, 'chkboxEnableSEG', ['2', '3'])
      }

      if (prntData.chkSeg1Enable == '1' && prntData.chkSeg2Enable == '' && prntData.chkSeg3Enable == '') {
        setValue(thisObj, 'chkboxEnableSEG', ['1'])
      }
      if (prntData.chkSeg1Enable == '' && prntData.chkSeg2Enable == '1' && prntData.chkSeg3Enable == '') {
        setValue(thisObj, 'chkboxEnableSEG', ['2'])
      }
      if (prntData.chkSeg1Enable == '' && prntData.chkSeg2Enable == '0' && prntData.chkSeg3Enable == '1') {
        setValue(thisObj, 'chkboxEnableSEG', ['3'])
      }
      bFillVendorSpecIndList(prntData.lstVendorSpecInd)
      bFillContractSpecIndList(prntData.lstContSpecInd)
      await bFillRateTypeList(prntData.lstRateTypeID)
      bFillBaseWtList(prntData.lstBaseWtID)
      await bFillApplyTypeList(prntData.lstApplyType)

      await bFillPDVendorList()
      await bFillPDContractList()


      setValue(thisObj, 'lblAddedByValue', prntData.lblAddedBy)
      setValue(thisObj, 'lblChangedByValue', prntData.lblChangedBy)


      // setValue(thisObj, 'ddRateType', rateTypeObj.find(ele => ele.key == prntData.lstRateTypeID).key)
      // setValue(thisObj, 'ddVendorSpec', yesNo.find(ele => ele.key == prntData.lstVendorSpecInd).key)
      // setValue(thisObj, 'ddContractSpec', yesNo.find(ele => ele.key == prntData.lstContSpecInd).key)
      // setValue(thisObj, 'ddApplyType', ApplyTypeObj.find(ele => ele.key == prntData.lstApplyType).key) 
      // setValue(thisObj, 'ddRequired', yesNo.find(ele => ele.key == prntData.lstRequiredInd).key) 

      // setValue(thisObj, 'ddBeforeSeedReveivable', yesNo.find(ele => ele.key == prntData.lstBeforeRecvInd).key) 
      // setValue(thisObj, 'ddBaseWeight', BasewtObj.find(ele => ele.key == prntData.lstBaseWtID).key)
      // setValue(thisObj, 'ddInspection', yesNo.find(ele => ele.key == prntData.lstInspectID).key)


      for (let i = 0; i < ApplyTypeObj.length; i++) {
        if (ApplyTypeObj[i].key == prntData.lstApplyType) {
          setValue(thisObj, 'ddApplyType', ApplyTypeObj[i].key)
          break
        }
      }
      for (let i = 0; i < BasewtObj.length; i++) {
        if (BasewtObj[i].key == prntData.lstBaseWtID) {
          setValue(thisObj, 'ddBaseWeight', BasewtObj[i].key)
          break
        }
      }
      // setTimeout(() => {
      //   if (isEnabled(_kaledo.thisObj, 'ddRateType')) {
          for (let i = 0; i < rateTypeObj.length; i++) {
            if (rateTypeObj[i].key == prntData.lstRateTypeID) {
              setValue(thisObj, 'ddRateType', rateTypeObj[i].key)
              onddRateTypeChange()
              break
            }
          }
      //   }
      // }, 5000);

      if (prntData.txtBaseRate != null && prntData.txtBaseRate != undefined && prntData.txtBaseRate !== '') {
        setValue(thisObj, 'txtBaseRate', (prntData.txtBaseRate.toFixed(5)).toString())
      }
      if (prntData.txtBasePercentage != null && prntData.txtBasePercentage != undefined && prntData.txtBasePercentage !== '') {
        setValue(thisObj, 'txtBasePercentage', (prntData.txtBasePercentage.toFixed(2)).toString())
      }
      setValue(thisObj, 'txtPayeeVendor', prntData.txtPayeeVendor)
      fillRemitListForVendor(prntData.txtPayeeVendor);
      // onddRateTypeChange()
      // onddPDCodeChange()
      enableDisableControlsPDSP()

    }
    setLoading(false)
  }

  const bFillPDCodeList = async (pd_code_ind) => {
    try {
      var respAdd
      var alreadyCretedArr = []
      let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let buy_pt_id = prntData.lblBuyingPoint_Tag
      if (prntData.cmdOk_Caption.toUpperCase() == 'ADD') {
        respAdd = await SystemMaintenanceMasterManagementService.RetrievePremiumDeductionScheduleDetails(compIdFromLS, cropYearFromLS, buy_pt_id, null, null, null, null, null, null, null,)
        if (respAdd == undefined || respAdd.length < 1 || respAdd.length == null) {

        } else {
          for (var i = 0; i < respAdd.length; i++) {
            alreadyCretedArr.push(respAdd[i].pd_code)
          }
        }
      }

      let js = [];
      let lstPDInd = []
      let lstFreightAccrualInd = []
      let lstExceptPremInd = []
      await AccountPayment.RetrievePremiumDeductionMasterDetails(null, null).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            if (prntData.cmdOk_Caption == 'Add') {
              if (!alreadyCretedArr.includes(data[i].pd_code)) {
                setData(thisObj, 'bFillPDCodeListPDSP', true)
                lstPDInd.push(data[i].pd_ind)
                lstFreightAccrualInd.push(data[i].freight_enable_ind)
                lstExceptPremInd.push(data[i].except_prem_ind)
                let obj = { key: data[i].pd_code, description: data[i].pd_code.trim() + ' - ' + data[i].pd_desc.trim() }
                js.push(obj)
                obj = {}
              }
            } else {
              setData(thisObj, 'bFillPDCodeListPDSP', true)
              lstPDInd.push(data[i].pd_ind)
              lstFreightAccrualInd.push(data[i].freight_enable_ind)
              lstExceptPremInd.push(data[i].except_prem_ind)
              let obj = { key: data[i].pd_code, description: data[i].pd_code.trim() + ' - ' + data[i].pd_desc.trim() }
              js.push(obj)
              obj = {}
            }
          }
          setData(thisObj, 'PDCodeList_ddValues', js)
          setData(thisObj, 'lstPDInd_Arr', lstPDInd)
          setData(thisObj, 'lstFreightAccrualInd_Arr', lstFreightAccrualInd)
          setData(thisObj, 'lstExceptPremInd_Arr', lstExceptPremInd)
        } else {
          setData(thisObj, 'bFillPDCodeListPDSP', false)
          setData(thisObj, 'PDCodeList_ddValues', js)
          setData(thisObj, 'lstPDInd_Arr', lstPDInd)
          setData(thisObj, 'lstFreightAccrualInd_Arr', lstFreightAccrualInd)
          setData(thisObj, 'lstExceptPremInd_Arr', lstExceptPremInd)
        }

        if (prntData.cmdOk_Caption == 'Add') {
          setValue(thisObj, "ddPDCode", js.at(0).key)
          onddPDCodeChange()
          setData(thisObj, 'PFPDC_loaded', true)
        } else {
          setValue(thisObj, "ddPDCode", js.find(ele => ele.key == pd_code_ind).key)
          onddPDCodeChange()
          setData(thisObj, 'PFPDC_loaded', true)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPDCode: {
              ...state["ddPDCode"],
              valueList: js,
            }
          }
        })
        setData(thisObj, 'PDCodeList_ddValues', js)
        setData(thisObj, 'lstPDInd_Arr', lstPDInd)
        setData(thisObj, 'lstFreightAccrualInd_Arr', lstFreightAccrualInd)
        setData(thisObj, 'lstExceptPremInd_Arr', lstExceptPremInd)
      })

      if (prntData.cmdOk_Caption == 'Update') {
        disable(thisObj, 'ddPDCode')
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillPDCodeList click event"
        );
      }
      return false;
    }
  }

  const ontxtPayeeVendorChange = () => {
    try {
      let txtVendorNumber = getValue(thisObj, "txtPayeeVendor")
      if (txtVendorNumber != undefined && txtVendorNumber != null) {
        if (txtVendorNumber.length == 6) {
          fillRemitToList(txtVendorNumber)
        }
        else {
          setValue(thisObj, 'txtVndr', '');
          // hide(thisObj, "txtVndr", false);
          const emptyValueList = [];
          thisObj.setState(current => {
            return {
              ...current,
              ddPayeeRemitTo: {
                ...state["ddPayeeRemitTo"],
                valueList: emptyValueList
              }
            }
          });
          setValue(thisObj, "ddPayeeRemitTo", "")
        }
      }
      enableDisableControlsPDSP()

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtPayeeVendorChange click event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtPayeeVendorChange = ontxtPayeeVendorChange;

  const onbtnPayeeVendorClick = () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnVendorClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnPayeeVendorClick = onbtnPayeeVendorClick;

  const fillRemitListForVendor = async (vendorTxtValue) => {
    const remitListLoaded = await fillRemitToList(vendorTxtValue);
    if (!remitListLoaded) {
      const initialVendorTxt = getValue(thisObj, 'txtPayeeVendor');
      setValue(thisObj, 'txtPayeeVendor', initialVendorTxt);
    }
  };

  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  const fillRemitToList = async (vendorValue) => {
    try {
      const vendorId = vendorValue
      if (!vendorId) {
        return true;
      }
      const vendorResponse = await ContractManagementService.RetrieveVendorByNumber(vendorId);
      if (!vendorResponse?.length) {
        return false;
      }

      const vendorName = vendorResponse[0]?.name;
      setValue(thisObj, 'txtVndr', vendorName || '');
      const remitResponse = await ContractManagementService.RetieveRemitDetails(vendorId);

      const remitDetailsList = [];
      remitResponse.forEach(remit => {
        const remitOptionItem = {
          key: remit.remittoid,
          description: ConvertToPascalCase(remit.remittoid) + ' - ' + ConvertToPascalCase(remit.name) + ' - ' + ConvertToPascalCase(remit.city) + ', ' + ConvertToPascalCase(remit.state)
        };
        remitDetailsList.push(remitOptionItem);
      });

      thisObj.setState(current => {
        return {
          ...current,
          ddPayeeRemitTo: {
            ...state['ddPayeeRemitTo'],
            valueList: remitDetailsList
          }
        }
      });

      if (remitDetailsList.length) {
        setValue(thisObj, 'ddPayeeRemitTo', remitDetailsList[0].key);
        setValue(thisObj, 'txtPayeeVendor', vendorValue);
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillRemitToList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillBaseWtList = (val) => {
    try {
      let js = []
      js.push(
        { key: 'GROSS', description: 'Gross' },
        { key: 'NET', description: 'Net' },
        { key: 'NETLSK', description: 'NetLSK' },
        { key: '', description: 'None' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddBaseWeight: {
            ...state["ddBaseWeight"],
            valueList: js
          }
        }
      })
      if (val == undefined || val == '' || val == null) {
        setValue(thisObj, 'ddBaseWeight', js.at(0).key);
      } else {
        setValue(thisObj, "ddBaseWeight", js.find(ele => ele.key == val).key)
      }
      setData(thisObj, 'bFillBaseWtList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillBaseWtList method"
        );
      }
      return false;
    }
  }

  const bFillRateTypeList = async (val) => {
    try {
      let js = []
      js.push(
        { key: 'POUND', description: '$ per Pound' },
        { key: 'TON', description: '$ per Ton' },
        { key: 'LOAD', description: '$ per Load' },
        { key: 'ENTRY', description: 'Enter at Load Time' },
        { key: 'PCTSEG', description: '% of Seg Value' },
        { key: 'SD_ADM', description: 'Seed Grower Addendum' },
        { key: 'LP_ADM', description: 'Landplaster Addendum' },
        { key: 'O_ADM', description: 'OLEIC Addendum' },
        { key: 'CT_ADV', description: 'Contract Advance' },
        { key: 'FUEL', description: 'Fuel Surcharge' },
        { key: 'LEASE', description: 'Trailer Lease' },
        { key: 'AR', description: 'AR' },
        { key: 'SR', description: 'SR' },
        { key: 'CR_ACT', description: 'CR Accounts' },
        { key: 'CURCLN', description: 'Cure & Cleaning' },
      )

      setData(thisObj, 'rateTypeJSSSS', js)

      thisObj.setState(current => {
        return {
          ...current,
          ddRateType: {
            ...state["ddRateType"],
            valueList: js
          }
        }
      })
      if (val == undefined || val == '' || val == null) {
        setValue(thisObj, 'ddRateType', js.at(0).key);
        onddRateTypeChange()
      } else {
        setValue(thisObj, "ddRateType", js.find(ele => ele.key == val).key)
        onddRateTypeChange()
      }
      setData(thisObj, 'bFillRateTypeList_PDSP', true)
      setData(thisObj, 'bFillRateTypeList_Js', js)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillRateTypeList method"
        );
      }
      return false;
    }
  }

  const bFillContractSpecIndList = (val) => {
    try {
      let js = []
      js.push(
        { key: 'Y', description: 'Yes' },
        { key: 'N', description: 'No' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddContractSpec: {
            ...state["ddContractSpec"],
            valueList: js
          }
        }
      })
      if (val == undefined || val == '' || val == null) {
        setValue(thisObj, 'ddContractSpec', js.at(1).key);
      } else {
        setValue(thisObj, "ddContractSpec", js.find(ele => ele.key == val).key)
      }
      setData(thisObj, 'bFillContractSpecIndList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillContractSpecIndList method"
        );
      }
      return false;
    }
  }

  const bFillVendorSpecIndList = (val) => {
    try {
      let js = []
      js.push(
        { key: 'Y', description: 'Yes' },
        { key: 'N', description: 'No' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddVendorSpec: {
            ...state["ddVendorSpec"],
            valueList: js
          }
        }
      })
      if (val == undefined || val == '' || val == null) {
        setValue(thisObj, 'ddVendorSpec', js.at(1).key);
      } else {
        setValue(thisObj, "ddVendorSpec", js.find(ele => ele.key == val).key)
      }
      setData(thisObj, 'bFillVendorSpecIndList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillVendorSpecIndList method"
        );
      }
      return false;
    }
  }

  const bFillBeforeRecvIndList = (val) => {
    try {
      let js = []
      js.push(
        { key: 'Y', description: 'Yes' },
        { key: 'N', description: 'No' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddBeforeSeedReveivable: {
            ...state["ddBeforeSeedReveivable"],
            valueList: js
          }
        }
      })
      if (val == undefined || val == '' || val == null) {
        setValue(thisObj, 'ddBeforeSeedReveivable', js.at(1).key);
      } else {
        setValue(thisObj, "ddBeforeSeedReveivable", js.find(ele => ele.key == val).key)
      }
      setData(thisObj, 'bFillBeforeRecvIndList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillBeforeRecvIndList method"
        );
      }
      return false;
    }
  }

  const bFillRequiredIndList = (val) => {
    try {
      let js = []
      js.push(
        { key: 'Y', description: 'Yes' },
        { key: 'N', description: 'No' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddRequired: {
            ...state["ddRequired"],
            valueList: js
          }
        }
      })
      if (val == undefined || val == '' || val == null) {
        setValue(thisObj, 'ddRequired', js.at(1).key);
      } else {
        setValue(thisObj, "ddRequired", js.find(ele => ele.key == val).key)
      }
      setData(thisObj, 'bFillRequiredIndList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillRequiredIndList method"
        );
      }
      return false;
    }
  }

  const bFillApplyTypeList = async (apply_type) => {
    try {
      let js = []
      js.push(
        { key: 'PUR', description: 'Purchase' },
        { key: 'STO', description: 'Storage' },
        { key: 'BTH', description: 'Both' }
      )

      // if (apply_type == undefined || apply_type == '' || apply_type == null) {
      //   setValue(thisObj, 'ddApplyType', js.at(0).key);
      // } else {
      //   setValue(thisObj, "ddApplyType", js.find(ele => ele.key == apply_type).key)
      // }
      thisObj.setState(current => {
        return {
          ...current,
          ddApplyType: {
            ...state["ddApplyType"],
            valueList: js
          }
        }
      })
      setData(thisObj, 'bFillApplyTypeList_PDSP', true)

      let lstPDInd = getData(thisObj, 'lstPDInd_Arr')
      let PDCode_Js = getData(thisObj, 'PDCodeList_ddValues')
      let RateTypeList_Js = getData(thisObj, 'rateTypeJSSSS')
      if (lstPDInd[PDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'P') {
        if (getValue(_kaledo.thisObj, 'ddPDCode') == 'SP') {
          setValue(thisObj, 'ddApplyType', js.at(2).key);
          setValue(thisObj, 'ddRateType', RateTypeList_Js.at(5).key);
          // onddRateTypeChange()
        } else {
          setValue(thisObj, 'ddApplyType', js.at(0).key);
          disable(thisObj, 'ddApplyType')
          setValue(thisObj, 'ddRateType', RateTypeList_Js.at(0).key);
          // onddRateTypeChange()
        }
      } else {
        setValue(thisObj, 'ddApplyType', js.at(2).key);
        enable(thisObj, 'ddApplyType')
        setValue(thisObj, 'ddRateType', RateTypeList_Js.at(0).key);
        // onddRateTypeChange()
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillApplyTypeList method"
        );
      }
      return false;
    }
  }

  const bFillInspectIndList = (ind) => {
    try {
      let js = []
      js.push(
        { key: 'Y', description: 'Yes' },
        { key: 'N', description: 'No' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddInspection: {
            ...state["ddInspection"],
            valueList: js
          }
        }
      })
      if (ind == undefined || ind == '' || ind == null) {
        setValue(thisObj, 'ddInspection', js.at(1).key);
      } else {
        setValue(thisObj, "ddInspection", js.find(ele => ele.key == ind).key)
      }
      setData(thisObj, 'bFillInspectIndList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillInspectIndList method"
        );
      }
      return false;
    }
  }

  const bFillAutoIndList = (ind) => {
    try {
      let js = []
      js.push(
        { key: 'Y', description: 'Yes' },
        { key: 'N', description: 'No' }
      )

      thisObj.setState(current => {
        return {
          ...current,
          ddAuto: {
            ...state["ddAuto"],
            valueList: js
          }
        }
      })
      if (ind == undefined || ind == '' || ind == null) {
        setValue(thisObj, 'ddAuto', js.at(1).key);
      } else {
        setValue(thisObj, "ddAuto", js.find(ele => ele.key == ind).key)
      }
      setData(thisObj, 'bFillAutoIndList_PDSP', true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillAutoIndList method"
        );
      }
      return false;
    }
  }

  const bFillPDContractList = async () => {
    try {
      let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let btnCaption = document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText
      if (btnCaption == 'Update') {
        let buy_pt_id = prntData.lblBuyingPoint_Tag
        let pd_code = getValue(_kaledo.thisObj, 'ddPDCode')
        // let lisContractVal = getValue(thisObj, "lstContracts")
        let pd_contract = ''
        // if (con == '') {
        //   pd_contract = ''
        // } else {
        //   pd_contract = lisContractVal.length < 1 ? '' : lisContractVal
        // }
        let js = []
        let obj = {}
        let response = await SystemMaintenanceMasterManagementService.RetrievePremiumDeductionContractDetails(compIdFromLS, cropYearFromLS, buy_pt_id, pd_code, pd_contract)
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          setData(thisObj, 'bFillPDContractList_PDSP', true)
          for (let i = 0; i < data.length; i++) {
            let dataExists = js.find(elem => elem.key == response[i].pdCode)
            if (dataExists == undefined) {
              obj = { key: data[i].pd_contract, description: data[i].pd_contract, index: i }
              js.push(obj)
              obj = {}
            }
          }
        }
        setData(thisObj, 'lstContracts', js)
        if (js[0] != undefined) {
          setValue(thisObj, 'lstContracts', js[0].key);
        }

        thisObj.setState(current => {
          return {
            ...current,
            lstContracts: {
              ...state["lstContracts"],
              valueList: js
            }
          }
        });
      } else {
        thisObj.setState(current => {
          return {
            ...current,
            lstContracts: {
              ...state["lstContracts"],
              valueList: []
            }
          }
        });
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillPDContractList method"
        );
      }
      return false;
    }
  }

  const bFillPDVendorList = async () => {
    try {
      let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let btnCaption = document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText
      if (btnCaption == 'Update') {
        let buy_pt_id = prntData.lblBuyingPoint_Tag
        let pd_code = getValue(_kaledo.thisObj, 'ddPDCode')
        // let lisVendorVal = getValue(thisObj, "lstVendors")
        let pd_vendor = ''
        // if (ven == '') {
        //   pd_vendor = ''
        // } else {
        //   pd_vendor = lisVendorVal.length < 1 ? '' : lisVendorVal
        // }
        let js = [];
        let obj = {}
        let response = await SystemMaintenanceMasterManagementService.RetrievePremiumDeductionVendorDetails(compIdFromLS, cropYearFromLS, buy_pt_id, pd_code, pd_vendor)
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          setData(thisObj, 'bFillPDVendorList_PDSP', true)
          for (let i = 0; i < data.length; i++) {
            let dataExists = js.find(elem => elem.key == response[i].pdCode)
            if (dataExists == undefined) {
              obj = { key: data[i].pd_vendor, description: data[i].pd_vendor + '-' + data[i].vendor_name, index: i }
              js.push(obj)
              obj = {}
            }
          }
        }
        setData(thisObj, 'lstVendors', js)
        if (js[0] != undefined) {
          setValue(thisObj, 'lstVendors', js[0].key);
        }

        thisObj.setState(current => {
          return {
            ...current,
            lstVendors: {
              ...state["lstVendors"],
              valueList: js
            }
          }
        });
      } else {
        thisObj.setState(current => {
          return {
            ...current,
            lstVendors: {
              ...state["lstVendors"],
              valueList: []
            }
          }
        });
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillPDVendorList method"
        );
      }
      return false;
    }
  }

  const enableDisableControlsPDSP = () => {
    let PDCode_Js = getData(thisObj, 'PDCodeList_ddValues')
    let BeforeSeedReveivable_Js = _kaledo.thisObj.state['ddBeforeSeedReveivable'].valueList;
    let lstExceptPremInd = getData(thisObj, 'lstExceptPremInd_Arr')
    enable(thisObj, 'btnAddVendor')
    enable(thisObj, 'btnDeleteVendor')
    enable(thisObj, 'btnAddContract')
    enable(thisObj, 'btnDeleteContract')
    if (getValue(_kaledo.thisObj, 'ddVendorSpec') == 'N') {
      disable(thisObj, 'btnAddVendor')
    }

    if ((_kaledo.thisObj.state['lstVendors'].valueList)[0].key == undefined) {
      disable(thisObj, 'btnDeleteVendor')
    }
    if (getData(thisObj, 'frmPremiumDeductionScheduleSetupEnableDisableControls') == true) {
      enable(thisObj, 'btnDeleteVendor')
    }

    if (getValue(_kaledo.thisObj, 'ddContractSpec') == 'N') {
      disable(thisObj, 'btnAddContract')
    }

    if ((_kaledo.thisObj.state['lstContracts'].valueList)[0].key == undefined) {
      disable(thisObj, 'btnDeleteContract')
    }
    if (getData(thisObj, 'frmPremiumDeductionScheduleProfileEnableDisableControls') == true) {
      enable(thisObj, 'btnDeleteContract')
    }

    let btnCaption = document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText
    if (btnCaption == 'Add') {
      disable(thisObj, 'btnAddVendor')
      disable(thisObj, 'btnDeleteVendor')
      disable(thisObj, 'btnAddContract')
      disable(thisObj, 'btnDeleteContract')
    }

    enable(thisObj, 'ddBeforeSeedReveivable')
    if (lstExceptPremInd[PDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'Y') {
      disable(thisObj, 'ddBeforeSeedReveivable')
    }

    switch (getValue(_kaledo.thisObj, 'ddRateType')) {
      case "SD_ADM":
      case "LP_ADM":
      case "O_ADM":
      case "AR":
      case "SR":
        setValue(thisObj, 'ddBeforeSeedReveivable', BeforeSeedReveivable_Js[0].key);
        disable(thisObj, 'ddBeforeSeedReveivable')
        break;
      case "CR_ACT":
        if (BeforeSeedReveivable_Js[0].key != undefined) {
          setValue(thisObj, 'ddBeforeSeedReveivable', BeforeSeedReveivable_Js[1].key);
        }
        disable(thisObj, 'ddBeforeSeedReveivable')
        break;

      default:
        break;
    }
    if (getValue(_kaledo.thisObj, 'txtPayeeVendor') != '' && getValue(_kaledo.thisObj, 'txtPayeeVendor') != undefined && getValue(_kaledo.thisObj, 'txtPayeeVendor') != null) {
      if (BeforeSeedReveivable_Js[0].key != undefined) {
        setValue(thisObj, 'ddBeforeSeedReveivable', BeforeSeedReveivable_Js[1].key);
      }
      disable(thisObj, 'ddBeforeSeedReveivable')
    }
  }

  const bFormValid = async () => {
    let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
    let buy_pt_id = prntData.lblBuyingPoint_Tag
    let lstVendors_Js = _kaledo.thisObj.state['lstVendors'].valueList;
    let lstContracts_Js = _kaledo.thisObj.state['lstContracts'].valueList;
    let PDCode_Js = getData(_kaledo.thisObj, 'PDCodeList_ddValues')
    let lstFreightAccrualInd = getData(thisObj, 'lstFreightAccrualInd_Arr')
    setData(thisObj, 'BformValid_PDSP', false)
    let btnCaption = document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText
    if (btnCaption != "Add") {
      if (getValue(_kaledo.thisObj, 'ddVendorSpec') == 'Y') {
        if (lstVendors_Js[0].key == undefined) {
          showMessage(thisObj, "Please add the Vendors!!!", false)
          enableDisableControlsPDSP()
          return
        }
      }

      if (getValue(_kaledo.thisObj, 'ddVendorSpec') == 'N') {
        if (lstVendors_Js[0].key != undefined) {
          showMessage(thisObj, "Please remove the Vendors!!!", false)
          enableDisableControlsPDSP()
          return
        }
      }

      if (getValue(_kaledo.thisObj, 'ddContractSpec') == 'Y') {
        if (lstContracts_Js[0].key == undefined) {
          showMessage(thisObj, "Please Add the Contracts!!!", false)
          enableDisableControlsPDSP()
          return
        }
      }

      if (getValue(_kaledo.thisObj, 'ddContractSpec') == 'N') {
        if (lstContracts_Js[0].key != undefined) {
          showMessage(thisObj, "Please remove the Contracts!!!", false)
          enableDisableControlsPDSP()
          return
        }
      }
    }

    switch (getValue(_kaledo.thisObj, 'ddRateType')) {
      case 'POUND':
        if (getValue(_kaledo.thisObj, 'ddBaseWeight') == '') {
          showMessage(thisObj, "Base Weight Cannot Be None!!!", false)
          return
        }
        if (getValue(_kaledo.thisObj, 'txtBaseRate') == '' || getValue(_kaledo.thisObj, 'txtBaseRate') == undefined || getValue(_kaledo.thisObj, 'txtBaseRate') == null) {
          showMessage(thisObj, "Base Rate Is Required!!!", false)
          return
        }
        break;
      case 'TON':
        if (getValue(_kaledo.thisObj, 'ddBaseWeight') == '') {
          showMessage(thisObj, "Base Weight Cannot Be None!!!", false)
          return
        }
        if (getValue(_kaledo.thisObj, 'txtBaseRate') == '' || getValue(_kaledo.thisObj, 'txtBaseRate') == undefined || getValue(_kaledo.thisObj, 'txtBaseRate') == null) {
          showMessage(thisObj, "Base Rate Is Required!!!", false)
          return
        }
        break;
      case 'LOAD':
        if (getValue(_kaledo.thisObj, 'ddBaseWeight') != '') {
          showMessage(thisObj, "Base Weight Must Be None!!!", false)
          return
        }
        if (getValue(_kaledo.thisObj, 'txtBaseRate') == '' || getValue(_kaledo.thisObj, 'txtBaseRate') == undefined || getValue(_kaledo.thisObj, 'txtBaseRate') == null) {
          showMessage(thisObj, "Base Rate Is Required!!!", false)
          return
        }
        break;
      case 'ENTRY':
        if (getValue(_kaledo.thisObj, 'ddBaseWeight') != '') {
          showMessage(thisObj, "Base Weight Must Be None!!!", false)
          return
        }
        break;
      case 'PCTSEG':
        if (getValue(_kaledo.thisObj, 'ddBaseWeight') != '') {
          showMessage(thisObj, "Base Weight Must Be None!!!", false)
          return
        }
        if (getValue(_kaledo.thisObj, 'txtBasePercentage') == '' || getValue(_kaledo.thisObj, 'txtBasePercentage') == undefined || getValue(_kaledo.thisObj, 'txtBasePercentage') == null) {
          showMessage(thisObj, "Base Percentage Is Required!!!", false)
          return
        }
        break;
      case "SD_ADM":
      case "LP_ADM":
      case "O_ADM":
      case "CT_ADV":
      case "CR_ACT":
      case "AR":
      case "SR":
        if (lstFreightAccrualInd[PDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'Y') {
          if (getValue(_kaledo.thisObj, 'ddRateType') == 'SD_ADM' || getValue(_kaledo.thisObj, 'ddRateType') == 'LP_ADM' || getValue(_kaledo.thisObj, 'ddRateType') == 'O_ADM' || getValue(_kaledo.thisObj, 'ddRateType') == 'CT_ADV') {
            showMessage(thisObj, "Rate Type not allowed for PD Codes flagged for Freight Accrual!!!", false)
            return
          }
        }

        let resp = await SystemMaintenanceMasterManagementService.RetrievePremiumDeductionScheduleDetails(compIdFromLS, cropYearFromLS, buy_pt_id, null, null, null, null, null, null, null,)
        if (resp != '' && resp != undefined && resp != null && resp.length > 0) {
          let data = resp
          for (let i = 0; i < data.length; i++) {
            if (data[i].rate_type == getValue(_kaledo.thisObj, 'ddRateType') && data[i].pd_code != getValue(_kaledo.thisObj, 'ddPDCode')) {
              showMessage(thisObj, "Rate type already associated with another PD Code for this Buying Point!!!", false)
              return
            }
          }
        } else {
          showMessage(thisObj, "Error communicating with database.", false)
          return
        }
        break;
      case 'FUEL':
      case 'LEASE':
        if (lstFreightAccrualInd[PDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] != 'Y') {
          showMessage(thisObj, "Rate Type not allowed for PD Codes not flagged for Freight Accrual!!!", false)
          return
        }
        break;
      case 'CURCLN':
        if (lstFreightAccrualInd[PDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'Y') {
          showMessage(thisObj, "Rate Type not allowed for PD Codes flagged for Freight Accrual!!!", false)
          return
        }
        break;
      default:
        break;
    }

    setData(thisObj, 'bDataValid_PDSP', true)

    if (!isNaN(getValue(_kaledo.thisObj, 'txtApplyOrder'))) {
      if (getValue(_kaledo.thisObj, 'txtApplyOrder') < 0 || getValue(_kaledo.thisObj, 'txtApplyOrder') > 999 || getValue(_kaledo.thisObj, 'txtApplyOrder') == '') {
        setData(thisObj, 'bDataValid_PDSP', false)
      }
    } else {
      setData(thisObj, 'bDataValid_PDSP', false)
    }

    if (getData(thisObj, 'bDataValid_PDSP') != true) {
      showMessage(thisObj, "Apply Order must be of format ###", false)
      document.getElementById('txtApplyOrder').focus();
      return
    }

    let chkboxArr = getValue(_kaledo.thisObj, 'chkboxEnableSEG')
    if (!chkboxArr.includes('1') && !chkboxArr.includes('2') && !chkboxArr.includes('3')) {
      setValue(thisObj, 'chkboxEnableSEG', ['1', '2', '3'])
    }

    if (getValue(_kaledo.thisObj, 'txtBaseRate') != '' && getValue(_kaledo.thisObj, 'txtBaseRate') != undefined && getValue(_kaledo.thisObj, 'txtBaseRate') != null) {
      if (!isNaN(getValue(_kaledo.thisObj, 'txtBaseRate'))) {
        if (getValue(_kaledo.thisObj, 'txtBaseRate') < 0 || getValue(_kaledo.thisObj, 'txtBaseRate') > 9999.99999 || getValue(_kaledo.thisObj, 'txtBaseRate') == '') {
          setData(thisObj, 'bDataValid_PDSP', false)
        }
      } else {
        setData(thisObj, 'bDataValid_PDSP', false)
      }

      if (getData(thisObj, 'bDataValid_PDSP') != true) {
        showMessage(thisObj, "Base Rate must be of format ####.#####", false)
        document.getElementById('txtBaseRate').focus();
        return
      }
    }

    if (getValue(_kaledo.thisObj, 'txtBasePercentage') != '' && getValue(_kaledo.thisObj, 'txtBasePercentage') != undefined && getValue(_kaledo.thisObj, 'txtBasePercentage') != null) {
      if (!isNaN(getValue(_kaledo.thisObj, 'txtBasePercentage'))) {
        if (getValue(_kaledo.thisObj, 'txtBasePercentage') < 0 || getValue(_kaledo.thisObj, 'txtBasePercentage') > 99.999) {
          setData(thisObj, 'bDataValid_PDSP', false)
        }
      } else {
        setData(thisObj, 'bDataValid_PDSP', false)
      }

      if (getData(thisObj, 'bDataValid_PDSP') != true) {
        showMessage(thisObj, "Base Percentage must be of format ##.####", false)
        document.getElementById('txtBasePercentage').focus();
        return
      }
    }

    setData(thisObj, 'BformValid_PDSP', true)

  }

  const ontxtApplyOrderChange = (e) => {
    try {
      setValue(thisObj, 'txtApplyOrder', e.target.value)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtApplyOrderChange event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtApplyOrderChange = ontxtApplyOrderChange;

  const ontxtBaseRateChange = (e) => {
    try {
      setValue(thisObj, 'txtBaseRate', e.target.value)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtBaseRateChange event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtBaseRateChange = ontxtBaseRateChange;

  const ontxtBasePercentageChange = (e) => {
    try {
      setValue(thisObj, 'txtBasePercentage', e.target.value)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtBasePercentageChange event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtBasePercentageChange = ontxtBasePercentageChange;

  const onbtnAddContractClick = () => {
    try {
      let parentData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let buyingPoint_Js = getData(thisObj, 'PDCodeList_ddValues')
      let arr = thisObj.state['lstContracts'].valueList;
      let dataArr = arr[0].key == undefined ? [] : arr;
      let ByPtID = thisObj.values.ddPDCode;
      let buyingPointDescription = buyingPoint_Js.find(elem => elem.key == ByPtID).description;
      let obj = {}
      obj.cmdOkCaption = 'Add';
      obj.txtContrac_enabled = true;
      obj.buyingpointname = parentData.lblBuyingPoint_Caption;
      obj.buyingpointid = parentData.lblBuyingPoint_Tag;
      obj.pdcodename = buyingPointDescription;
      obj.pdcode = ByPtID;

      setData(thisObj, 'premiumdeductioncontractprofiledata', obj)
      setData(thisObj, 'frmPremiumDeductionScheduleProfile_PDCP', dataArr)

      goTo(thisObj, "SystemMaintenanceMasterManagement#PremiumDeductionContractProfile#DEFAULT#true#Click")
      enableDisableControlsPDSP()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnAddContractClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddContractClick = onbtnAddContractClick;

  const onbtnAddVendorClick = () => {
    try {
      let parentData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let buyingPoint_Js = getData(thisObj, 'PDCodeList_ddValues')
      let ByPtID = thisObj.values.ddPDCode;
      let buyingPointDescription = buyingPoint_Js.find(elem => elem.key == ByPtID).description;
      let obj = {}
      obj.cmdOk_Caption = 'Add';
      obj.txtVendor_enabled = true;
      obj.lblBuyingPoint_describtion = parentData.lblBuyingPoint_Caption;
      obj.lblBuyingPoint_Id = parentData.lblBuyingPoint_Tag;
      obj.lblPDCode_describtion = buyingPointDescription;
      obj.lblPDCode_Id = ByPtID;

      setData(thisObj, 'frmPremiumDeductionVendorProfile_PDSP', obj)
      goTo(thisObj, "SystemMaintenanceMasterManagement#PremiumDeductionVendorProfile#DEFAULT#true#Click")
      enableDisableControlsPDSP()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnAddVendorClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddVendorClick = onbtnAddVendorClick;

  const onddContractSpecChange = () => {
    try {
      enableDisableControlsPDSP()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddContractSpecChange event"
        );
      }
      return false;
    }
  }
  thisObj.onddContractSpecChange = onddContractSpecChange;

  const onddPDCodeChange = () => {
    try {
      let ddInspection_js = _kaledo.thisObj.state['ddInspection'].valueList
      let ddAuto_js = _kaledo.thisObj.state['ddAuto'].valueList
      let ddPDCode_Js = getData(thisObj, 'PDCodeList_ddValues')
      let ddRequired_js = _kaledo.thisObj.state['ddRequired'].valueList
      let ddBeforeSeedReveivable_js = _kaledo.thisObj.state['ddBeforeSeedReveivable'].valueList
      let ddVendorSpec_js = _kaledo.thisObj.state['ddVendorSpec'].valueList
      let ddContractSpec_js = _kaledo.thisObj.state['ddContractSpec'].valueList
      let ddRateType_js = getData(thisObj, 'rateTypeJSSSS')
      let ddBaseWeight_js = _kaledo.thisObj.state['ddBaseWeight'].valueList
      let ddApplyType_js = _kaledo.thisObj.state['ddApplyType'].valueList
      let ddPayeeRemitTo_js = _kaledo.thisObj.state['ddPayeeRemitTo'].valueList
      let lstExceptPremInd = getData(thisObj, 'lstExceptPremInd_Arr')
      let lstPDInd = getData(thisObj, 'lstPDInd_Arr')

      setLoading(true)
      bFillPDVendorList()
      bFillPDContractList()

      if (ddInspection_js[0].key != undefined) {
        setValue(thisObj, 'ddInspection', ddInspection_js[1].key)
      }
      enable(thisObj, 'ddInspection')

      if (ddAuto_js[0].key != undefined) {
        setValue(thisObj, 'ddAuto', ddAuto_js[1].key)
      }
      enable(thisObj, 'ddAuto')

      if (ddRequired_js[0].key != undefined) {
        setValue(thisObj, 'ddRequired', ddRequired_js[1].key)
      }
      enable(thisObj, 'ddRequired')

      if (ddBeforeSeedReveivable_js[0].key != undefined) {
        setValue(thisObj, 'ddBeforeSeedReveivable', ddBeforeSeedReveivable_js[1].key)
      }
      enable(thisObj, 'ddBeforeSeedReveivable')

      setValue(thisObj, 'txtApplyOrder', '')
      enable(thisObj, 'txtApplyOrder')

      setValue(thisObj, 'chkboxEnableSEG', ['1', '2', '3'])
      enable(thisObj, 'chkboxEnableSEG')

      if (ddVendorSpec_js[0].key != undefined) {
        setValue(thisObj, 'ddVendorSpec', ddVendorSpec_js[1].key)
      }
      enable(thisObj, 'ddVendorSpec')

      if (ddContractSpec_js[0].key != undefined) {
        setValue(thisObj, 'ddContractSpec', ddContractSpec_js[1].key)
      }
      enable(thisObj, 'ddContractSpec')

      if (ddRateType_js[0].key != undefined) {
        setValue(thisObj, 'ddRateType', ddRateType_js[0].key)
      }
      enable(thisObj, 'ddRateType')

      if (ddBaseWeight_js[0].key != undefined) {
        setValue(thisObj, 'ddBaseWeight', ddBaseWeight_js[0].key)
      }
      enable(thisObj, 'ddBaseWeight')

      setValue(thisObj, 'txtBaseRate', '')
      enable(thisObj, 'txtBaseRate')

      setValue(thisObj, 'txtBasePercentage', '')
      enable(thisObj, 'txtBasePercentage')

      if (ddApplyType_js[0].key != undefined) {
        setValue(thisObj, 'ddApplyType', ddApplyType_js[2].key)
      }
      enable(thisObj, 'ddApplyType')

      setValue(thisObj, 'txtPayeeVendor', '')
      enable(thisObj, 'txtPayeeVendor')

      setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
      enable(thisObj, 'ddPayeeRemitTo')

      if (lstPDInd[ddPDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'P') {
        if (lstExceptPremInd[ddPDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'Y') {
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddBeforeSeedReveivable')
          setValue(thisObj, 'txtApplyOrder', 99)
          disable(thisObj, 'txtApplyOrder')
          setValue(thisObj, 'chkboxEnableSEG', ['1', '2', '3'])
          enable(thisObj, 'chkboxEnableSEG')
          disable(thisObj, 'ddVendorSpec')
          disable(thisObj, 'ddContractSpec')
          if (ddRateType_js[0].key != undefined) {
            setValue(thisObj, 'ddRateType', ddRateType_js[3].key)
          }
          disable(thisObj, 'ddRateType')
          if (ddBaseWeight_js[0].key != undefined) {
            setValue(thisObj, 'ddBaseWeight', ddBaseWeight_js[3].key)
          }
          disable(thisObj, 'ddBaseWeight')

          disable(thisObj, 'txtBaseRate')
          disable(thisObj, 'txtBasePercentage')
          disable(thisObj, 'ddApplyType')
          disable(thisObj, 'txtPayeeVendor')
          disable(thisObj, 'ddPayeeRemitTo')
        } else {
          if (ddApplyType_js[0].key != undefined) {
            if (getValue(_kaledo.thisObj, 'ddPDCode') == 'SP') {
              setValue(thisObj, 'ddApplyType', ddApplyType_js[2].key)
              setValue(thisObj, 'ddRateType', ddRateType_js[5].key)
            } else {
              setValue(thisObj, 'ddApplyType', ddApplyType_js[0].key)
              setValue(thisObj, 'ddRateType', ddRateType_js[0].key)
              disable(thisObj, 'ddApplyType')
            }
          }
        }
      }
      setLoading(false)
    } catch (err) {
      if (err instanceof EvalError) {
        setLoading(false)
        showMessage(thisObj, err.message);
      } else {
        setLoading(false)
        showMessage(
          thisObj,
          "Error occurred during onddPDCodeChange event"
        );
      }
      return false;
    }
  }
  thisObj.onddPDCodeChange = onddPDCodeChange;

  const onddRateTypeChange = () => {
    try {
      let yesNo = [{ key: 'Y', description: 'Yes' }, { key: 'N', description: 'No' }]
      let BasewtObj = [{ key: 'GROSS', description: 'Gross' }, { key: 'NET', description: 'Net' }, { key: 'NETLSK', description: 'NetLSK' }, { key: '', description: 'None' }]
      let ddPDCode_Js = getData(thisObj, 'PDCodeList_ddValues')
      let ddRateType_js = getData(thisObj, 'rateTypeJSSSS')
      let ddApplyType_js = getData(thisObj, 'ApplyTypeDDVals')
      let ddPayeeRemitTo_js = _kaledo.thisObj.state['ddPayeeRemitTo'].valueList
      let lstExceptPremInd = getData(thisObj, 'lstExceptPremInd_Arr')
      let lstPDInd = getData(thisObj, 'lstPDInd_Arr')


      enable(thisObj, 'txtBaseRate')
      setValue(thisObj, 'txtBaseRate', '')
      enable(thisObj, 'txtBasePercentage')
      setValue(thisObj, 'txtBasePercentage', '')

      enable(thisObj, 'ddBaseWeight')
      enable(thisObj, 'ddInspection')
      enable(thisObj, 'ddRequired')
      enable(thisObj, 'ddAuto')
      enable(thisObj, 'ddBeforeSeedReveivable')
      enable(thisObj, 'ddVendorSpec')
      enable(thisObj, 'ddContractSpec')
      enable(thisObj, 'txtPayeeVendor')
      enable(thisObj, 'ddPayeeRemitTo')
      enable(thisObj, 'txtPayeeVendor')
      enable(thisObj, 'btnPayeeVendor')
//PRB0054392	New PPS bug - Premium Deduction Schedule Profile 
      if ((lstPDInd[ddPDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'D')) {
        if (ddApplyType_js[0].key != undefined || ddApplyType_js[0].key != '') {
        } else {
          setValue(thisObj, 'ddApplyType', ddApplyType_js[2].key)
        }
        enable(thisObj, 'ddApplyType')
      }

      if (lstExceptPremInd[ddPDCode_Js.findIndex(elem => elem.key == getValue(_kaledo.thisObj, 'ddPDCode'))] == 'Y') {
        if (yesNo[0].key != undefined) {
          setValue(thisObj, 'ddInspection', yesNo[1].key)
        }
        disable(thisObj, 'ddInspection')

        if (yesNo[0].key != undefined) {
          setValue(thisObj, 'ddAuto', yesNo[1].key)
        }
        disable(thisObj, 'ddAuto')

        if (yesNo[0].key != undefined) {
          setValue(thisObj, 'ddRequired', yesNo[1].key)
        }
        disable(thisObj, 'ddRequired')

        if (yesNo[0].key != undefined) {
          setValue(thisObj, 'ddBeforeSeedReveivable', yesNo[1].key)
        }
        disable(thisObj, 'ddBeforeSeedReveivable')

        setValue(thisObj, 'txtApplyOrder', 99)
        disable(thisObj, 'txtApplyOrder')
        setValue(thisObj, 'chkboxEnableSEG', ['1', '2', '3'])
        enable(thisObj, 'chkboxEnableSEG')

        if (yesNo[0].key != undefined) {
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
        }
        disable(thisObj, 'ddVendorSpec')

        if (yesNo[0].key != undefined) {
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
        }
        disable(thisObj, 'ddContractSpec')

        if (ddRateType_js[0].key != undefined) {
          setValue(thisObj, 'ddRateType', ddRateType_js[3].key)
        }
        disable(thisObj, 'ddRateType')

        if (BasewtObj[0].key != undefined) {
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
        }
        disable(thisObj, 'ddBaseWeight')

        setValue(thisObj, 'txtBaseRate', '')
        disable(thisObj, 'txtBaseRate')

        setValue(thisObj, 'txtBasePercentage', '')
        disable(thisObj, 'txtBasePercentage')

        if (ddApplyType_js[0].key != undefined) {
          setValue(thisObj, 'ddApplyType', ddApplyType_js[2].key)
        }
        disable(thisObj, 'ddApplyType')

        setValue(thisObj, 'txtPayeeVendor', '')
        disable(thisObj, 'txtPayeeVendor')

        setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
        disable(thisObj, 'ddPayeeRemitTo')
      }

      setLoading(true)
      switch (getValue(_kaledo.thisObj, 'ddRateType')) {
        case 'POUND':
          enable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          enable(thisObj, 'ddBaseWeight')
          break;
        case 'TON':
          enable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          enable(thisObj, 'ddBaseWeight')
          break;
        case 'LOAD':
          enable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          break;
        case 'ENTRY':
          enable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          break;
        case 'PCTSEG':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          enable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          break;
        case 'SD_ADM':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddInspection', yesNo[1].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          setValue(thisObj, 'ddBeforeSeedReveivable', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'ddBeforeSeedReveivable')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[2].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          setValue(thisObj, 'ddApplyType', ddApplyType_js[2].key)
          disable(thisObj, 'ddApplyType')
          break;
        case 'LP_ADM':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddInspection', yesNo[1].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          setValue(thisObj, 'ddBeforeSeedReveivable', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'ddBeforeSeedReveivable')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[1].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          break;
        case 'O_ADM':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddInspection', yesNo[1].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          setValue(thisObj, 'ddBeforeSeedReveivable', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'ddBeforeSeedReveivable')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[1].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          break;
        case 'CT_ADV':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddInspection', yesNo[1].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          break;
        case 'AR':
        case 'SR':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddInspection', yesNo[1].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          setValue(thisObj, 'ddBeforeSeedReveivable', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'ddBeforeSeedReveivable')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          break;
        case 'CR_ACT':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          setValue(thisObj, 'ddInspection', yesNo[1].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          setValue(thisObj, 'ddBeforeSeedReveivable', yesNo[1].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          disable(thisObj, 'ddBeforeSeedReveivable')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          break;
        case 'CURCLN':
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          setValue(thisObj, 'ddInspection', yesNo[0].key)
          setValue(thisObj, 'ddRequired', yesNo[0].key)
          setValue(thisObj, 'ddAuto', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          disable(thisObj, 'ddRequired')
          disable(thisObj, 'ddAuto')
          enable(thisObj, 'ddBeforeSeedReveivable')
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddVendorSpec', yesNo[1].key)
          disable(thisObj, 'ddVendorSpec')
          setValue(thisObj, 'ddContractSpec', yesNo[1].key)
          disable(thisObj, 'ddContractSpec')
          disable(thisObj, 'txtPayeeVendor')
          setValue(thisObj, 'txtPayeeVendor', '')
          setValue(thisObj, 'ddPayeeRemitTo', ddPayeeRemitTo_js[0].key)
          disable(thisObj, 'ddPayeeRemitTo')
          disable(thisObj, 'btnPayeeVendor')
          break;
        case 'FUEL':
          disable(thisObj, 'txtBasePercentage')
          setValue(thisObj, 'txtBasePercentage', '')
          disable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddInspection', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          setValue(thisObj, 'ddApplyType', ddApplyType_js[0].key)
          disable(thisObj, 'ddApplyType')
          break;
        case 'LEASE':
          enable(thisObj, 'txtBaseRate')
          setValue(thisObj, 'txtBaseRate', '')
          disable(thisObj, 'ddBaseWeight')
          setValue(thisObj, 'ddBaseWeight', BasewtObj[3].key)
          setValue(thisObj, 'ddInspection', yesNo[0].key)
          disable(thisObj, 'ddInspection')
          setValue(thisObj, 'ddApplyType', ddApplyType_js[0].key)
          disable(thisObj, 'ddApplyType')
          break;
        default:
          break;
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        setLoading(false)
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddRateTypeChange event"
        );
      }
      return false;
    }
  }

  thisObj.onddRateTypeChange = onddRateTypeChange;

  const onddVendorSpecChange = () => {
    try {
      enableDisableControlsPDSP()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddVendorSpecChange event"
        );
      }
      return false;
    }
  }
  thisObj.onddVendorSpecChange = onddVendorSpecChange;

  const onbtnCancelClick = () => {
    try {
      setData(thisObj, 'PFPDC_loaded', false)
      setData(thisObj, 'AddUpdateData', true)
      document.getElementById("SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnCancel click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnOkClick = async () => {
    try {
      let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let chkboxArr = getValue(thisObj, 'chkboxEnableSEG')

      let chkboxEnableSEG1 = chkboxArr.includes('1') ? 'Y' : 'N'
      let chkboxEnableSEG2 = chkboxArr.includes('2') ? 'Y' : 'N'
      let chkboxEnableSEG3 = chkboxArr.includes('3') ? 'Y' : 'N'
      let baseRate
      if (getValue(thisObj, 'txtBaseRate') == undefined || getValue(thisObj, 'txtBaseRate') == '' || getValue(thisObj, 'txtBaseRate') == null) {
        baseRate = ''
      } else {
        if (!isNaN(getValue(thisObj, 'txtBaseRate'))) {
          let num = getValue(thisObj, 'txtBaseRate')
          baseRate = parseFloat(num)
        } else {
          baseRate = getValue(thisObj, 'txtBaseRate')
        }
      }
      let baseRatePer
      if (getValue(thisObj, 'txtBasePercentage') == undefined || getValue(thisObj, 'txtBasePercentage') == '' || getValue(thisObj, 'txtBasePercentage') == null) {
        baseRatePer = ''
      } else {
        if (!isNaN(getValue(thisObj, 'txtBasePercentage'))) {
          baseRatePer = parseFloat(getValue(thisObj, 'txtBasePercentage'))
        } else {
          baseRatePer = getValue(thisObj, 'txtBasePercentage')
        }
      }
      let applyOrder
      if (getValue(thisObj, 'txtApplyOrder') == undefined || getValue(thisObj, 'txtApplyOrder') == '' || getValue(thisObj, 'txtApplyOrder') == null) {
        applyOrder = 0
      } else {
        applyOrder = getValue(thisObj, 'txtApplyOrder')
      }
      await bFormValid()
      if (getData(thisObj, 'BformValid_PDSP') == false) { // || getData(thisObj, 'bDataValid_PDSP') == null ||
        //getData(thisObj, 'bDataValid_PDSP') == '' || getData(thisObj, 'bDataValid_PDSP') == undefined) {
        return
      }

      let body = {}
      if (document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText == 'Add') {
        body.comp_id = compIdFromLS,
          body.crop_year = parseInt(cropYearFromLS),
          body.buy_pt_id = prntData.lblBuyingPoint_Tag,
          body.pd_code = getValue(thisObj, 'ddPDCode'),
          body.inspect_ind = getValue(thisObj, 'ddInspection'),
          body.auto_ind = getValue(thisObj, 'ddAuto'),
          body.required_ind = getValue(thisObj, 'ddRequired'),
          body.before_recv_ind = getValue(thisObj, 'ddBeforeSeedReveivable'),
          body.apply_order = parseInt(applyOrder),
          body.vend_spec_ind = getValue(thisObj, 'ddVendorSpec'),
          body.cont_spec_ind = getValue(thisObj, 'ddContractSpec'),
          body.rate_type = getValue(thisObj, 'ddRateType'),
          body.base_wt = getValue(thisObj, 'ddBaseWeight'),
          body.base_rate = baseRate,
          body.base_pct = baseRatePer,
          body.payee_vendor = getValue(thisObj, 'txtPayeeVendor'),
          body.payee_remit = getValue(thisObj, 'ddPayeeRemitTo'),
          body.apply_type = getValue(thisObj, 'ddApplyType'),
          body.user_id = useridFromLS,
          body.seg_1_enable = chkboxEnableSEG1,
          body.seg_2_enable = chkboxEnableSEG2,
          body.seg_3_enable = chkboxEnableSEG3
      } else {
        body.inspect_ind = getValue(thisObj, 'ddInspection'),
          body.auto_ind = getValue(thisObj, 'ddAuto'),
          body.required_ind = getValue(thisObj, 'ddRequired'),
          body.before_recv_ind = getValue(thisObj, 'ddBeforeSeedReveivable'),
          body.apply_order = parseInt(applyOrder),
          body.vend_spec_ind = getValue(thisObj, 'ddVendorSpec'),
          body.cont_spec_ind = getValue(thisObj, 'ddContractSpec'),
          body.rate_type = getValue(thisObj, 'ddRateType'),
          body.base_wt = getValue(thisObj, 'ddBaseWeight'),
          body.base_rate = baseRate,
          body.base_pct = baseRatePer,
          body.payee_vendor = getValue(thisObj, 'txtPayeeVendor'),
          body.payee_remit = getValue(thisObj, 'ddPayeeRemitTo'),
          body.apply_type = getValue(thisObj, 'ddApplyType'),
          body.user_id = useridFromLS,
          body.seg_1_enable = chkboxEnableSEG1,
          body.seg_2_enable = chkboxEnableSEG2,
          body.seg_3_enable = chkboxEnableSEG3
      }

      if (document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText == 'Add') {
        let response = await SystemMaintenanceMasterManagementService.CreatePremiumDeductSchedule(body);
        if (![200, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while Adding the databasee.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.', false)
          showMessage(thisObj, `${(response.message).slice(53)}`, false)
          return
        }

      }

      if (document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText == 'Update') {
        let butPtID = prntData.lblBuyingPoint_Tag
        let search = "/"
        let replaceWith = "~"
        let pdCode
        let pd_codetemp = getValue(_kaledo.thisObj, 'ddPDCode')
        if (pd_codetemp.includes("/")) {
          pdCode = pd_codetemp.split(search).join(replaceWith);
        }
        else {
          pdCode = pd_codetemp
        }
        let response = await SystemMaintenanceMasterManagementService.UpdatePremiumDeductSchedule(butPtID, pdCode, body);
        if (![200, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the databasee.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          showMessage(thisObj, `${(response.message).slice(53)}`, false)
          return
        }
      }

      document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = 'Update'
      enableDisableControlsPDSP()
      showMessage(thisObj, "Updates made succesfully.", true)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnOkClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnDeleteVendorClick = async () => {
    try {
      let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let buy_pt_id = prntData.lblBuyingPoint_Tag
      let pdVendor = getValue(_kaledo.thisObj, 'lstVendors')
      let TypeList = thisObj.state['lstVendors'].valueList
      if (TypeList[0].key != undefined && TypeList.length >= 1 && pdVendor.length == 0) {
        pdVendor = TypeList[0].key
      }
      let codeName = ''
      let codeIndex;
      if (pdVendor !== undefined && pdVendor !== null && pdVendor !== '') {
        codeName = TypeList.find(elem => elem.key == pdVendor).description;
        codeIndex = TypeList.find(elem => elem.key == pdVendor).index;
      }

      let search = "/"
      let replaceWith = "~"
      let pdCode
      let pd_codetemp = getValue(_kaledo.thisObj, 'ddPDCode')
      if (pd_codetemp.includes("/")) {
        pdCode = pd_codetemp.split(search).join(replaceWith);
      }
      else {
        pdCode = pd_codetemp
      }

      if (pdVendor == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm(`Are you sure you wish to delete this Vendor Code '${codeName}'`)
        if (confirmBox == true) {
          let response = await SystemMaintenanceMasterManagementService.RemovePremiumDeductionVendorDetails(buy_pt_id, pdCode, pdVendor);
          if (![200, 400, 404].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.', false)
          }
          await bFillPDVendorList()
          enableDisableControlsPDSP()
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during onbtnDeleteVendorClick event"
        );
      }
    }
  }
  thisObj.onbtnDeleteVendorClick = onbtnDeleteVendorClick;

  const onbtnDeleteContractClick = async () => {
    try {
      let prntData = getData(thisObj, 'frmPremiumDeductionScheduleProfile')
      let buy_pt_id = prntData.lblBuyingPoint_Tag
      let pdContract = getValue(_kaledo.thisObj, 'lstContracts')
      let TypeList = thisObj.state['lstContracts'].valueList
      if (TypeList[0].key != undefined && TypeList.length >= 1 && pdContract.length == 0) {
        pdContract = TypeList[0].key
      }
      let codeName = ''
      let codeIndex;
      if (pdContract !== undefined && pdContract !== null && pdContract !== '') {
        codeName = TypeList.find(elem => elem.key == pdContract).description;
        codeIndex = TypeList.find(elem => elem.key == pdContract).index;
      }

      let search = "/"
      let replaceWith = "~"
      let pdCode
      let pd_codetemp = getValue(_kaledo.thisObj, 'ddPDCode')
      if (pd_codetemp.includes("/")) {
        pdCode = pd_codetemp.split(search).join(replaceWith);
      }
      else {
        pdCode = pd_codetemp
      }

      if (pdContract == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm(`Are you sure you wish to delete this Contract Code '${codeName}'`)
        if (confirmBox == true) {
          let response = await SystemMaintenanceMasterManagementService.RemovePremiumDeductionContractDetails(buy_pt_id, pdCode, pdContract);
          if (![200, 400, 404].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.', false)
          }
          await bFillPDContractList()
          enableDisableControlsPDSP()
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during onbtnDeleteContractClick event"
        );
      }
    }
  }
  thisObj.onbtnDeleteContractClick = onbtnDeleteContractClick;





  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionScheduleProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionScheduleProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionScheduleProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionScheduleProfile*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionScheduleProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxProfileDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxProfileDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxProfileDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyingPoint}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPDCode*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPDCode*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPDCode}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPDCode*/}

                  {/* END_USER_CODE-USER_AFTER_ddPDCode*/}
                  {/* START_USER_CODE-USER_BEFORE_ddVendorSpec*/}

                  {/* END_USER_CODE-USER_BEFORE_ddVendorSpec*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddVendorSpec}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddVendorSpec*/}

                  {/* END_USER_CODE-USER_AFTER_ddVendorSpec*/}
                  {/* START_USER_CODE-USER_BEFORE_ddInspection*/}

                  {/* END_USER_CODE-USER_BEFORE_ddInspection*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddInspection}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddInspection*/}

                  {/* END_USER_CODE-USER_AFTER_ddInspection*/}
                  {/* START_USER_CODE-USER_BEFORE_ddContractSpec*/}

                  {/* END_USER_CODE-USER_BEFORE_ddContractSpec*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddContractSpec}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddContractSpec*/}

                  {/* END_USER_CODE-USER_AFTER_ddContractSpec*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAuto*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAuto*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAuto}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAuto*/}

                  {/* END_USER_CODE-USER_AFTER_ddAuto*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRateType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRateType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRateType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRateType*/}

                  {/* END_USER_CODE-USER_AFTER_ddRateType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRequired*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRequired*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRequired}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRequired*/}

                  {/* END_USER_CODE-USER_AFTER_ddRequired*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBaseWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBaseWeight*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBaseWeight}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBaseWeight*/}

                  {/* END_USER_CODE-USER_AFTER_ddBaseWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBeforeSeedReveivable*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBeforeSeedReveivable*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBeforeSeedReveivable}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBeforeSeedReveivable*/}

                  {/* END_USER_CODE-USER_AFTER_ddBeforeSeedReveivable*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBaseRate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBaseRate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBaseRate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBaseRate*/}

                  {/* END_USER_CODE-USER_AFTER_txtBaseRate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtApplyOrder*/}

                  {/* END_USER_CODE-USER_BEFORE_txtApplyOrder*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtApplyOrder}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtApplyOrder*/}

                  {/* END_USER_CODE-USER_AFTER_txtApplyOrder*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBasePercentage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBasePercentage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBasePercentage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBasePercentage*/}

                  {/* END_USER_CODE-USER_AFTER_txtBasePercentage*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxEnableSEG*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxEnableSEG*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxEnableSEG}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxEnableSEG*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxEnableSEG*/}
                  {/* START_USER_CODE-USER_BEFORE_ddApplyType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddApplyType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddApplyType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddApplyType*/}

                  {/* END_USER_CODE-USER_AFTER_ddApplyType*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxProfileDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxProfileDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPayee*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPayee*/}

                <GroupBoxWidget conf={state.grpbxPayee} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtPayeeVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayeeVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayeeVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayeeVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayeeVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPayeeVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPayeeVendor*/}

                  <ButtonWidget
                    conf={state.btnPayeeVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPayeeVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnPayeeVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVndr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                  {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPayeeRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPayeeRemitTo*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPayeeRemitTo}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPayeeRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_ddPayeeRemitTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPayee*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPayee*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVendors*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVendors*/}

                <GroupBoxWidget conf={state.grpbxVendors} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblVendors*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendors*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendors}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendors*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendors*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddVendor*/}

                  <ButtonWidget
                    conf={state.btnAddVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteVendor*/}

                  <ButtonWidget
                    conf={state.btnDeleteVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_lstVendors*/}

                  {/* END_USER_CODE-USER_BEFORE_lstVendors*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstVendors}
                    screenConf={state}
                    onMouseMove={(e)=>e.preventDefault()}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstVendors*/}

                  {/* END_USER_CODE-USER_AFTER_lstVendors*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVendors*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVendors*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContracts*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContracts*/}

                <GroupBoxWidget conf={state.grpbxContracts} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblContracts*/}

                  {/* END_USER_CODE-USER_BEFORE_lblContracts*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblContracts}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblContracts*/}

                  {/* END_USER_CODE-USER_AFTER_lblContracts*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddContract*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddContract*/}

                  <ButtonWidget
                    conf={state.btnAddContract}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddContract*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddContract*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteContract*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteContract*/}

                  <ButtonWidget
                    conf={state.btnDeleteContract}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteContract*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteContract*/}
                  {/* START_USER_CODE-USER_BEFORE_lstContracts*/}

                  {/* END_USER_CODE-USER_BEFORE_lstContracts*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstContracts}
                    screenConf={state}
                    onMouseMove={(e)=>e.preventDefault()}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstContracts*/}

                  {/* END_USER_CODE-USER_AFTER_lstContracts*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContracts*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContracts*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionScheduleProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionScheduleProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btncmdEnableDisableControls*/}

                {/* END_USER_CODE-USER_BEFORE_btncmdEnableDisableControls*/}

                <ButtonWidget
                  conf={state.btncmdEnableDisableControls}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btncmdEnableDisableControls*/}

                {/* END_USER_CODE-USER_AFTER_btncmdEnableDisableControls*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionScheduleProfile*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionScheduleProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile
);
