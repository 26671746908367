/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  disable,
  enable,
  hide,
  show,
  AutocompleteWidget,
  getSelectedRowNumber,
  isEnabled,
  clearValue,
  hideWidgets,
  showWidgets,
  setFieldValues
} from "../../shared/WindowImports";
import { PopupBreak } from "../../Common/CommonFunctions"
import { Buffer } from 'buffer';
import "./WarehouseBinProfile.scss";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { response } from "msw";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_WarehouseBinProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseBinProfile",
    windowName: "WarehouseBinProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.WarehouseBinProfile",
    // START_USER_CODE-USER_WarehouseBinProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Warehouse Bin Profile",
      scrCode: "PN0090B",
    },
    // END_USER_CODE-USER_WarehouseBinProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxWarehouseBinProfile2",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxWarehouseBinProfile2",
      Label: "",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxAllowWhsReceipts: {
      name: "chkboxAllowWhsReceipts",
      type: "CheckBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Allow Whs Receipts to be obligated  to this Whs/Bin",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAllowWhsReceipts_PROPERTIES

      // END_USER_CODE-USER_chkboxAllowWhsReceipts_PROPERTIES
    },
    chkboxOrganic: {
      name: "chkboxOrganic",
      type: "CheckBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOrganic_PROPERTIES

      // END_USER_CODE-USER_chkboxOrganic_PROPERTIES
    },
    chkboxTransfersAllowed: {
      name: "chkboxTransfersAllowed",
      type: "CheckBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxTransfersAllowed_PROPERTIES

      // END_USER_CODE-USER_chkboxTransfersAllowed_PROPERTIES
    },
    ddEdibleOilIndicator: {
      name: "ddEdibleOilIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Edible Oil Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOilIndicator_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOilIndicator_PROPERTIES
    },
    ddObligationAsignment: {
      name: "ddObligationAsignment",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Obligation Assignment:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddObligationAsignment_PROPERTIES

      // END_USER_CODE-USER_ddObligationAsignment_PROPERTIES
    },
    ddOleicIndicator: {
      name: "ddOleicIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Oleic Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleicIndicator_PROPERTIES

      // END_USER_CODE-USER_ddOleicIndicator_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSeedGeneration: {
      name: "ddSeedGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Seed Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_ddSeedGeneration_PROPERTIES
    },
    ddSegmentType: {
      name: "ddSegmentType",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Segment Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegmentType_PROPERTIES

      // END_USER_CODE-USER_ddSegmentType_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile2",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile2",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblBuyingPointValue: {
      name: "lblBuyingPointValue",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPointValue_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPointValue_PROPERTIES
    },
    lblOrganic: {
      name: "lblOrganic",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Organic:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOrganic_PROPERTIES

      // END_USER_CODE-USER_lblOrganic_PROPERTIES
    },
    lblTransfersAllowed: {
      name: "lblTransfersAllowed",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Transfers Allowed:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTransfersAllowed_PROPERTIES

      // END_USER_CODE-USER_lblTransfersAllowed_PROPERTIES
    },
    txtBin: {
      name: "txtBin",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Bin #:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBin_PROPERTIES

      // END_USER_CODE-USER_txtBin_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile2",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES

      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtChangedByValue: {
      name: "txtChangedByValue",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinProfile2",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedByValue_PROPERTIES

      // END_USER_CODE-USER_txtChangedByValue_PROPERTIES
    },
    txtGovCapacity: {
      name: "txtGovCapacity",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Gov Capacity (Tons):",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGovCapacity_PROPERTIES

      // END_USER_CODE-USER_txtGovCapacity_PROPERTIES
    },
    txtStartupTons: {
      name: "txtStartupTons",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Startup Tons:",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStartupTons_PROPERTIES

      // END_USER_CODE-USER_txtStartupTons_PROPERTIES
    },
    txtWarehouse: {
      name: "txtWarehouse",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Warehouse #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouse_PROPERTIES

      // END_USER_CODE-USER_txtWarehouse_PROPERTIES
    },
    txtWarehouseBinDesc: {
      name: "txtWarehouseBinDesc",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseBinProfile",
      Label: "Warehouse Bin Desc:",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseBinDesc_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseBinDesc_PROPERTIES
    },
    grpbxWarehouseBinProfile: {
      name: "grpbxWarehouseBinProfile",
      type: "GroupBoxWidget",
      parent: "WarehouseBinProfile",
      Label: "grpbxWarehouseBinProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxWarehouseBinProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseBinProfile_PROPERTIES
    },
    grpbxWarehouseBinProfile2: {
      name: "grpbxWarehouseBinProfile2",
      type: "GroupBoxWidget",
      parent: "WarehouseBinProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxWarehouseBinProfile2_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseBinProfile2_PROPERTIES
    },
  };
  let useridFromLS = sessionStorage.getItem('userid')
  let _buttonServices = {};
  let _buttonNavigation = {

  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  var isbFormValid = false;
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    Form_Load()
    Form_Activate()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const Form_Load = async () => {

    await bFillTypeList()
    // bFillVarietyList()
    await bFillSeedGenarationList()
    await bFillEdibleOilList()
    await bFillSegmentTypeList()
    await bFillOleicIndList()
    await bFillWarehouseReceiptIndList()
    //setLoading(false)
  }

  const bFillTypeList = async() => {
    let js = [];
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddPeanutType', js.at(0).key)
      await bFillVarietyList(js.at(0).key)


  }

  const bFillVarietyList = async(peanut_type) => {
    setLoading(true)
    let buy_pt_id = '', js = []
    let data = getData(thisObj, 'frmWarehouseBinProfileData')
    if (data != null && data != "" && data != undefined) {
      buy_pt_id = data.lblBuyingPoint;
    }
    let obj = { key: '', description: ">>> All Varieties <<<", lstPeanutVarietyOleicInd: '' }
    js.push(obj)
    if (peanut_type != null && peanut_type != "" && peanut_type != undefined) {
      ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, null, peanut_type, null).then(response => {
        let data = response;
        let count = 0;
        data.forEach(element => {
          count++;
          let newVar = true;
          for (let i = 0; i < count - 1; i++) {
            if ((element.pnut_variety_id).toUpperCase() == (data[i].pnut_variety_id).toUpperCase()) {
              newVar = false;
              break;
            }
          }
          let symbol;
          if (newVar) {
            switch (element.symbol_ind.trim()) {
              case "TRADEMARK":
                symbol = " \u2122"
                break;
              case "REGISTERED TRADEMARK":
                symbol = " \xAE"
                break;
              case "COPYRIGHT":
                symbol = " \xA9"
                break;
              default:
                symbol = "";
            }
            let obj = {
              key: element.pnut_variety_id, description: element.pnut_variety_name + symbol,
              lstPeanutVarietyOleicInd: element.oleic_ind
            }
            js.push(obj)
          }
        });
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddPeanutVariety', js[0].key);
    setLoading(false)
  }

  // Method for binding generation List
  const bFillSeedGenarationList = async() => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGeneration: {
          ...state["ddSeedGeneration"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeedGeneration', js.at(0).key)
  }
  // Method for binding EdibleOil
  const bFillEdibleOilList = async() => {
    let js = []
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Crushing' })

    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOilIndicator: {
          ...state["ddEdibleOilIndicator"],
          valueList: js
        },
      }
    });
  }

  const bFillSegmentTypeList = async() => {
    let js = []
    js.push({ key: '1', description: 'Seg1' })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: 'Seg3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSegmentType: {
          ...state["ddSegmentType"],
          valueList: js
        },
      }
    });
    setValue(thisObj, 'ddSegmentType', js.at(0).key)
  }


  // Method for binding Oleic values
  const bFillOleicIndList = async() => {
    let js = []
    js.push({ key: '', description: 'None' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleicIndicator: {
          ...state["ddOleicIndicator"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleicIndicator', js.at(0).key)
  }

  const bFillWarehouseReceiptIndList = async() => {
    let js = []
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddObligationAsignment: {
          ...state["ddObligationAsignment"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddObligationAsignment', js.at(0).key)
  }
  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
 

  const bFormValid = async () => {
    let bDataValid = true;
    let txtWarehouse = getValue(thisObj, 'txtWarehouse')
    let txtBin = getValue(thisObj, 'txtBin')
    let txtGovCapacity = getValue(thisObj, 'txtGovCapacity')
    let txtStartupTons = getValue(thisObj, 'txtStartupTons')

    if (txtWarehouse == "" || txtWarehouse == undefined) {
      showMessage(thisObj, "Warehouse Number is a required field", false);
      isbFormValid = false;
      return;
    }
    if (txtBin == "" || txtBin == undefined) {
      showMessage(thisObj, "Bin Number is a required field", false);
      isbFormValid = false;
      return;
    }
    if (txtGovCapacity?.trim() !== "" && txtGovCapacity?.trim() !== undefined) {
      if (isNumeric(txtGovCapacity?.trim())) {
        let val = parseFloat(txtGovCapacity)
        if (val < 0 || val > 99999999.9999) {
          showMessage(thisObj, "Capacity must be of format ########.####", false);
          document.getElementById('txtGovCapacity').focus();
          isbFormValid = false;
          return
        }
      }
      else {
        showMessage(thisObj, "Capacity must be of format ########.####", false);
        document.getElementById('txtGovCapacity').focus();
        isbFormValid = false;
        return
      }
    }

    if (txtStartupTons?.trim() !== "" && txtStartupTons?.trim() !== undefined) {
      if (isNumeric(txtStartupTons?.trim())) {
        let val = parseFloat(txtStartupTons)
        if (val < 0 || val > 99999999.9999) {
          showMessage(thisObj, "Startup Tons must be of format ########.####", false);
          document.getElementById('txtStartupTons').focus();
          isbFormValid = false;
          return
        }
      }
      else {
        showMessage(thisObj, "Startup Tons must be of format ########.####", false);
        document.getElementById('txtStartupTons').focus();
        isbFormValid = false;
        return
      }

    }
    isbFormValid = true
  }
  const Form_Activate = async () => {
    //setLoading(true)
    let mbDisable = false,
      bAllowUpd = false,
      bAllowUpdWithInv = false,
      bHasNoInv = false,
      data = getData(thisObj, 'frmWarehouseBinProfileData')
    let GetAccessLevel001 = await ContractManagementService.RetrieveAccessPermissionDetails('PN0090', '001', 'U', data.lblBuyingPoint);
    if (GetAccessLevel001.length > 0) {
      if (GetAccessLevel001[0].permission.toUpperCase() == 'Y') {
        bAllowUpd = true;
        mbDisable = false

      }
    }

    let GetAccessLevel002 = await ContractManagementService.RetrieveAccessPermissionDetails('PN0090', '002', 'U', data.lblBuyingPoint);
    if (GetAccessLevel002.length > 0) {
      if (GetAccessLevel002[0].permission.toUpperCase() == 'Y') {
        bAllowUpdWithInv = true;
      }
    }
    let warehousenumbers=data.txtWarehouseNumber!=='' && data.txtWarehouseNumber !==undefined ?data.txtWarehouseNumber:null
    let BinNumber=data.txtBinNumber!=='' && data.txtBinNumber !==undefined ?data.txtBinNumber:null
    let response = await SystemMaintenanceMasterManagementService.RetrieveWarehouseBinInventoryCheck(data.lblBuyingPoint, warehousenumbers, BinNumber)
    if (response.length > 0) {
      let indicator = response[0].indicator
      if (indicator == 'T') {
        bHasNoInv = true
      }else{
        alert(PopupBreak('System Communication Error:\n',['','An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.','','','Error:','',response[0].errormessage]))
      }
    }else{
      
    }
    let objVal = {}
    if (data.cmdOk == 'Update') {
      // await bFillVarietyList(data[0].txtPeanutType)
      setLoading(true)
      document.getElementById('btnOk').innerHTML = 'Update';
      objVal['txtWarehouse'] = data[0].txtWhs
      objVal['txtBin'] = data[0].txtBin
      objVal['lblBuyingPointValue'] = data.cmbBuyingPoint
      objVal['txtWarehouseBinDesc'] = data[0].txtDescription
      objVal['ddObligationAsignment'] = data[0].txtObligationAssignment
      setData(thisObj, 'objVal', objVal)
      if (data[0].txtAllowReceipts == "Y") {
        objVal['chkboxAllowWhsReceipts'] = true
      } else {
        objVal['chkboxAllowWhsReceipts'] = false
      }
      objVal['ddPeanutType'] = data[0].txtPeanutType
      objVal['ddPeanutVariety'] = data[0].txtPeanutVariety
      objVal['ddSeedGeneration'] = data[0].txtSeedGen
      objVal['ddSegmentType'] = data[0].txtSegType
      objVal['ddEdibleOilIndicator'] = data[0].txtEdibleOil
      objVal['ddOleicIndicator'] = data[0].txtOleic
      if (data[0].txtOrganic == "Y") {
        objVal['chkboxOrganic'] = true
      } else {
        objVal['chkboxOrganic'] = false
      }
      objVal['txtGovCapacity'] = data[0].txtGovCapacity == '.0000' ? '0' : data[0].txtGovCapacity
      objVal['txtStartupTons'] = data[0].txtStartupTons == '.0000' ? '0' : data[0].txtStartupTons
      objVal['lblAddedByValue'] = data[0].addedby
      objVal['txtChangedByValue'] = data[0].changedby
      if (data[0].txtAllowTransfers == "Y") {
        objVal['chkboxTransfersAllowed'] = true
      } else {
        objVal['chkboxTransfersAllowed'] = false
      }
      if (bAllowUpd == true) {
        mbDisable = false
        disable(thisObj, 'txtWarehouse')
        disable(thisObj, 'txtBin')
        // disable(thisObj,'ddObligationAsignment')
        enable(thisObj, 'txtWarehouseBinDesc')
        enable(thisObj, 'chkboxAllowWhsReceipts')
        enable(thisObj, 'ddPeanutType')
        enable(thisObj, 'ddSeedGeneration')
        enable(thisObj, 'ddSegmentType')
        enable(thisObj, 'ddEdibleOilIndicator')
        enable(thisObj, 'ddOleicIndicator')
        enable(thisObj, 'txtStartupTons')
        enable(thisObj, 'chkboxOrganic')
        enable(thisObj, 'chkboxTransfersAllowed')
        enable(thisObj, 'ddPeanutVariety')
      }
      if (bHasNoInv == false && bAllowUpdWithInv == false) {
        mbDisable = false
        disable(thisObj, 'txtWarehouse')
        disable(thisObj, 'txtBin')
        disable(thisObj, 'chkboxAllowWhsReceipts')
        disable(thisObj, 'ddPeanutType')
        disable(thisObj, 'ddSeedGeneration')
        disable(thisObj, 'ddSegmentType')
        disable(thisObj, 'ddEdibleOilIndicator')
        disable(thisObj, 'ddOleicIndicator')
        disable(thisObj, 'txtStartupTons')
        disable(thisObj, 'chkboxOrganic')
      }
      await setFieldValues(thisObj, objVal, true);
      if (((data[0].txtWhs).substring(0, 1) == '1')) {
        setValue(thisObj, 'ddObligationAsignment', 'N')
        disable(thisObj, 'chkboxAllowWhsReceipts')
        disable(thisObj, 'ddObligationAsignment')
      }
      await ontxtWarehouseBlur()
    } else {
      let ddPeanutType = getValue(thisObj, 'ddPeanutType')
      // await bFillVarietyList(ddPeanutType)
      objVal['lblBuyingPointValue'] = data.cmbBuyingPoint
      objVal['chkboxAllowWhsReceipts'] = true
      objVal['chkboxTransfersAllowed'] = true
      document.getElementById('btnOk').innerHTML = 'Add';
      setFieldValues(thisObj, objVal, true);
    }
    setLoading(false)
  }

  //Save and Create the Warehosebinprofile
  const onbtnOkClick = async () => {
    try {
      await bFormValid()
      if (isbFormValid == true) {
        let data = getData(thisObj, 'frmWarehouseBinProfileData')
        let setdatetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
        var dateTime = moment(String(setdatetime)).format('MM/DD/YYYY hh:mm A');
        let txtWarehouse = getValue(thisObj, 'txtWarehouse')
        let txtBin = getValue(thisObj, 'txtBin')
        let lblBuyingPointValue = getValue(thisObj, 'lblBuyingPointValue')
        let txtWarehouseBinDesc = getValue(thisObj, 'txtWarehouseBinDesc') !== undefined && getValue(thisObj, 'txtWarehouseBinDesc') !== '' ? getValue(thisObj, 'txtWarehouseBinDesc') : ''
        let ddObligationAsignment = getValue(thisObj, 'ddObligationAsignment')
        let chkboxAllowWhsReceipts = getValue(thisObj, 'chkboxAllowWhsReceipts')
        let ddPeanutType = getValue(thisObj, 'ddPeanutType')
        let ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety') !== undefined && getValue(thisObj, 'ddPeanutVariety') !== '' ? getValue(thisObj, 'ddPeanutVariety') : ''
        let ddSeedGeneration = getValue(thisObj, 'ddSeedGeneration')
        let ddSegmentType = getValue(thisObj, 'ddSegmentType')
        let ddEdibleOilIndicator = getValue(thisObj, 'ddEdibleOilIndicator') !== undefined && getValue(thisObj, 'ddEdibleOilIndicator') !== '' ? getValue(thisObj, 'ddEdibleOilIndicator') : ''
        let ddOleicIndicator = getValue(thisObj, 'ddOleicIndicator')
        let chkboxOrganic = getValue(thisObj, 'chkboxOrganic')
        let txtGovCapacity = getValue(thisObj, 'txtGovCapacity') !== '' && getValue(thisObj, 'txtGovCapacity') !== undefined ? getValue(thisObj, 'txtGovCapacity') : ''
        let txtStartupTons = getValue(thisObj, 'txtStartupTons') !== '' && getValue(thisObj, 'txtStartupTons') !== undefined ? getValue(thisObj, 'txtStartupTons') : ''
        let lblAddedByValue = getValue(thisObj, 'lblAddedByValue')
        let txtChangedByValue = getValue(thisObj, 'txtChangedByValue')
        let chkboxTransfersAllowed = getValue(thisObj, 'chkboxTransfersAllowed')
        let obj = {}
        let encodethewharhousenin=encodeURIComponent(txtWarehouseBinDesc)
        let enc1 = Buffer.from(txtWarehouseBinDesc).toString('base64');
        obj.whouse_bin_id =enc1
        obj.pnut_type_id = ddPeanutType,
        obj.pnut_variety_id = ddPeanutVariety
        obj.seed_gen = ddSeedGeneration
        obj.seg_type = ddSegmentType
        obj.edible_oil_ind = ddEdibleOilIndicator
        obj.oleic_ind = ddOleicIndicator
        if (chkboxAllowWhsReceipts == true) {
          obj.allow_rcpt_ind = 'Y'
        } else {
          obj.allow_rcpt_ind = 'N'
        }

        if (chkboxTransfersAllowed == true) {
          obj.xfer_allow_ind = 'Y'
        } else {
          obj.xfer_allow_ind = 'N'
        }

        if (chkboxOrganic == true) {
          obj.organic_ind = 'Y'
        } else {
          obj.organic_ind = 'N'
        }

        obj.status_ind = "A"
        obj.capacity_tons = txtGovCapacity,
          obj.startup_tons = txtStartupTons,
          obj.whs_rcpt_ind = ddObligationAsignment

        if (data.cmdOk == 'Add') {
          obj.add_user = useridFromLS
          obj.add_date_time = dateTime
          let response = await SystemMaintenanceMasterManagementService.CreateWarehouseBinInventory(data.lblBuyingPoint, txtWarehouse, txtBin, obj)
          if (response.status == 200) {
            setData(thisObj, 'warehosesetupLoad', true)
            document.getElementById("SystemMaintenanceMasterManagement_WarehouseBinProfilePopUp").childNodes[0].click();
          } else {
            if(response.message =='Error occurred during operation. Status Code : 400 ; Error Meesage : Invalid Security Permissions.'){
              alert(PopupBreak('System Communication Error:\n',['','An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.','','','Error:','','Invalid Security Permissions.']))
            }else{
            alert(PopupBreak('System Communication Error:\n',['','An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.']))
          }
          }
        } else {
          let response = await SystemMaintenanceMasterManagementService.UpdateWarehouseBinInventory(data.lblBuyingPoint, txtWarehouse, txtBin, obj)
          if (response.status == 200) {
            setData(thisObj, 'warehosesetupLoad', true)
            document.getElementById("SystemMaintenanceMasterManagement_WarehouseBinProfilePopUp").childNodes[0].click();
          } else {
            if(response.message =='Error occurred during operation. Status Code : 400 ; Error Meesage : Invalid Security Permissions.'){
              alert(PopupBreak('System Communication Error:\n',['','An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.','','','Error:','','Invalid Security Permissions.']))
            }else{
            alert(PopupBreak('System Communication Error:\n',['','An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.']))
          }
          }
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnOk event:Click"
        );
      }
      return false;
    }

  }
  thisObj.onbtnOkClick = onbtnOkClick

  const onbtnCancelClick = () => {
    document.getElementById("SystemMaintenanceMasterManagement_WarehouseBinProfilePopUp").childNodes[0].click();
  }
  thisObj.onbtnCancelClick = onbtnCancelClick


  const onddPeanutTypeChange = async () => {
    try {
      let peanut_type = getValue(thisObj, 'ddPeanutType');
      bFillVarietyList(peanut_type)

    }
    catch (err) {
    }

  };
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddObligationAsignmentChange = async () => {
    try {
      let ddObligationAsignment = getValue(thisObj, 'ddObligationAsignment')
      if (ddObligationAsignment == 'N') {
        setValue(thisObj, 'chkboxAllowWhsReceipts', false)
        disable(thisObj, 'chkboxAllowWhsReceipts')
      } else if (ddObligationAsignment == 'Y') {
        setValue(thisObj, 'chkboxAllowWhsReceipts', true)
        enable(thisObj, 'chkboxAllowWhsReceipts')
      }

    }
    catch (err) {
    }

  };
  thisObj.onddObligationAsignmentChange = onddObligationAsignmentChange;


  const onddPeanutVarietyChange = async () => {
    try {
      let ddPeanutVarietyList = thisObj.state['ddPeanutVariety'].valueList;
      let SelectedddPeanutVariety = thisObj.values['ddPeanutVariety'];
      let SelectedPeanutVarietyList = ddPeanutVarietyList.filter(elem => elem.key === SelectedddPeanutVariety);
      if (SelectedPeanutVarietyList.length > 0) {
        setValue(thisObj, 'ddOleicIndicator', SelectedPeanutVarietyList[0].lstPeanutVarietyOleicInd)
      }
    }
    catch (err) {
    }

  };
  thisObj.onddPeanutVarietyChange = onddPeanutVarietyChange;

  const ontxtWarehouseBlur = async () => {
    try {
      let txtWarehouse = getValue(thisObj, 'txtWarehouse') || getData(thisObj, 'objVal')['txtWarehouse']
      if (((txtWarehouse).substring(0, 1) == '1')) {
        setValue(thisObj, 'chkboxAllowWhsReceipts', false)
        disable(thisObj, 'chkboxAllowWhsReceipts')
        setValue(thisObj, 'ddObligationAsignment', 'N')
        disable(thisObj, 'ddObligationAsignment')
      } else {
        // setValue(thisObj, 'chkboxAllowWhsReceipts', true)
        enable(thisObj, 'chkboxAllowWhsReceipts')
        enable(thisObj, 'ddObligationAsignment')
      }
    }
    catch (err) {
    }

  };
  thisObj.ontxtWarehouseBlur = ontxtWarehouseBlur;
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;

        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_WarehouseBinProfile*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseBinProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseBinProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseBinProfile*/}

              <GroupBoxWidget
                conf={state.grpbxWarehouseBinProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPointValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPointValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtWarehouse*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouse*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouse}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouse*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouse*/}
                {/* START_USER_CODE-USER_BEFORE_txtBin*/}

                {/* END_USER_CODE-USER_BEFORE_txtBin*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBin}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBin*/}

                {/* END_USER_CODE-USER_AFTER_txtBin*/}
                {/* START_USER_CODE-USER_BEFORE_txtWarehouseBinDesc*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouseBinDesc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouseBinDesc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouseBinDesc*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouseBinDesc*/}
                {/* START_USER_CODE-USER_BEFORE_ddObligationAsignment*/}

                {/* END_USER_CODE-USER_BEFORE_ddObligationAsignment*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddObligationAsignment}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddObligationAsignment*/}

                {/* END_USER_CODE-USER_AFTER_ddObligationAsignment*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxAllowWhsReceipts*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxAllowWhsReceipts*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxAllowWhsReceipts}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxAllowWhsReceipts*/}

                {/* END_USER_CODE-USER_AFTER_chkboxAllowWhsReceipts*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegmentType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegmentType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegmentType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegmentType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegmentType*/}
                {/* START_USER_CODE-USER_BEFORE_ddEdibleOilIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_ddEdibleOilIndicator*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddEdibleOilIndicator}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddEdibleOilIndicator*/}

                {/* END_USER_CODE-USER_AFTER_ddEdibleOilIndicator*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleicIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleicIndicator*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleicIndicator}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleicIndicator*/}

                {/* END_USER_CODE-USER_AFTER_ddOleicIndicator*/}
                {/* START_USER_CODE-USER_BEFORE_lblOrganic*/}

                {/* END_USER_CODE-USER_BEFORE_lblOrganic*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOrganic}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOrganic*/}

                {/* END_USER_CODE-USER_AFTER_lblOrganic*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxOrganic*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxOrganic*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxOrganic}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxOrganic*/}

                {/* END_USER_CODE-USER_AFTER_chkboxOrganic*/}
                {/* START_USER_CODE-USER_BEFORE_txtGovCapacity*/}

                {/* END_USER_CODE-USER_BEFORE_txtGovCapacity*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGovCapacity}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGovCapacity*/}

                {/* END_USER_CODE-USER_AFTER_txtGovCapacity*/}
                {/* START_USER_CODE-USER_BEFORE_txtStartupTons*/}

                {/* END_USER_CODE-USER_BEFORE_txtStartupTons*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStartupTons}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStartupTons*/}

                {/* END_USER_CODE-USER_AFTER_txtStartupTons*/}
                {/* START_USER_CODE-USER_BEFORE_lblTransfersAllowed*/}

                {/* END_USER_CODE-USER_BEFORE_lblTransfersAllowed*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTransfersAllowed}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTransfersAllowed*/}

                {/* END_USER_CODE-USER_AFTER_lblTransfersAllowed*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxTransfersAllowed*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxTransfersAllowed*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxTransfersAllowed}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxTransfersAllowed*/}

                {/* END_USER_CODE-USER_AFTER_chkboxTransfersAllowed*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseBinProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseBinProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseBinProfile2*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseBinProfile2*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxWarehouseBinProfile2}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.txtChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.txtChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseBinProfile2*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseBinProfile2*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseBinProfile*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseBinProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_WarehouseBinProfile);
