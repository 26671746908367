import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip} from "../ParentWidgetFunc";

function LabelWidget(props) {
  //let labelWidth = props.conf.labelWidth || 4;
  let conf = props.conf;
  let screenConf = props.screenConf;
  let labelControl = <div>{props.values[conf.name]}</div>;

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
            data-testid={conf.name}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm &&
          props.values[conf.name] ? (
            <Col>{labelControl}</Col>
          ) : (
            <React.Fragment> {labelControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default LabelWidget;
