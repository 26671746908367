/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  DateWidget,
  setValue,
  getValue,
  disable,
  hide,
  show,
  getData,
  setData,
  enable,
  goTo,
  hideColumn,
  showColumn,
} from "../../shared/WindowImports";

import "./SpotControlSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SpotControlSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpotControlSetup",
    windowName: "SpotControlSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SpotControlSetup",
    // START_USER_CODE-USER_SpotControlSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Spot Price Setup",
      scrCode: "PN0110A",
    },
    // END_USER_CODE-USER_SpotControlSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxSpotControlSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxSpotControlSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxSpotControlSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    colEffective: {
      name: "colEffective",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Effective",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEffective_PROPERTIES

      // END_USER_CODE-USER_colEffective_PROPERTIES
    },
    colSeg1: {
      name: "colSeg1",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg1_PROPERTIES

      // END_USER_CODE-USER_colSeg1_PROPERTIES
    },
    colSeg2Edible: {
      name: "colSeg2Edible",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 2 Edible",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg2Edible_PROPERTIES

      // END_USER_CODE-USER_colSeg2Edible_PROPERTIES
    },
    colSeg2Oil: {
      name: "colSeg2Oil",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 2 Oil",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg2Oil_PROPERTIES

      // END_USER_CODE-USER_colSeg2Oil_PROPERTIES
    },
    colSeg2: {
      name: "colSeg2",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg2_PROPERTIES

      // END_USER_CODE-USER_colSeg2_PROPERTIES
    },
    colSeg3Edible: {
      name: "colSeg3Edible",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 3 Edible",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg3Edible_PROPERTIES

      // END_USER_CODE-USER_colSeg3Edible_PROPERTIES
    },
    colSeg3Oil: {
      name: "colSeg3Oil",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 3 Oil",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg3Oil_PROPERTIES

      // END_USER_CODE-USER_colSeg3Oil_PROPERTIES
    },
    colSeg3: {
      name: "colSeg3",
      type: "GridColumnWidget",
      parent: "gridSpotPrices",
      Label: "Seg 3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg3_PROPERTIES

      // END_USER_CODE-USER_colSeg3_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxSpotControlSetup",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSpotControlSetup",
      Label: "Peanut Type:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    gridSpotPrices: {
      name: "gridSpotPrices",
      type: "GridWidget",
      parent: "grpbxSpotControlSetup",
      gridCellsOrder:
        "txtcolEffective,txtcolSeg1,txtcolSeg2,txtcolSeg2Edible,txtcolSeg2Oil,txtcolSeg3,txtcolSeg3Edible,txtcolSeg3Oil",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Cols: "4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSpotPrices_PROPERTIES

      // END_USER_CODE-USER_gridSpotPrices_PROPERTIES
    },
    lblSpotPrices: {
      name: "lblSpotPrices",
      type: "LabelWidget",
      parent: "grpbxSpotControlSetup",
      Label: "Spot Prices:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpotPrices_PROPERTIES

      // END_USER_CODE-USER_lblSpotPrices_PROPERTIES
    },
    txtcolEffective: {
      name: "txtcolEffective",
      type: "TextBoxWidget",
      colName: "colEffective",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEffective_PROPERTIES

      // END_USER_CODE-USER_txtcolEffective_PROPERTIES
    },
    txtcolSeg1: {
      name: "txtcolSeg1",
      type: "TextBoxWidget",
      colName: "colSeg1",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg1_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg1_PROPERTIES
    },
    txtcolSeg2Edible: {
      name: "txtcolSeg2Edible",
      type: "TextBoxWidget",
      colName: "colSeg2Edible",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg2Edible_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg2Edible_PROPERTIES
    },
    txtcolSeg2Oil: {
      name: "txtcolSeg2Oil",
      type: "TextBoxWidget",
      colName: "colSeg2Oil",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg2Oil_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg2Oil_PROPERTIES
    },
    txtcolSeg2: {
      name: "txtcolSeg2",
      type: "TextBoxWidget",
      colName: "colSeg2",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg2_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg2_PROPERTIES
    },
    txtcolSeg3Edible: {
      name: "txtcolSeg3Edible",
      type: "TextBoxWidget",
      colName: "colSeg3Edible",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg3Edible_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg3Edible_PROPERTIES
    },
    txtcolSeg3Oil: {
      name: "txtcolSeg3Oil",
      type: "TextBoxWidget",
      colName: "colSeg3Oil",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg3Oil_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg3Oil_PROPERTIES
    },
    txtcolSeg3: {
      name: "txtcolSeg3",
      type: "TextBoxWidget",
      colName: "colSeg3",
      parent: "gridSpotPrices",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg3_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg3_PROPERTIES
    },
    grpbxSpotControlSetup: {
      name: "grpbxSpotControlSetup",
      type: "GroupBoxWidget",
      parent: "SpotControlSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSpotControlSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxSpotControlSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "SpotControlSetup",
      Height: "",
      Width: "",
      clonedExtId: "39201",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceSpecialFunctions#":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceSpecialFunctions#":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAdd:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#SpotControlProfile#DEFAULT#true#Click",]
    },
    btnEdit:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#SpotControlProfile#DEFAULT#true#Click",]
    },
    btnClose: {
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }, 
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfSpotControlProfile") == true) {
        getSpotPrices();
      }
      setData(thisObj, "closingOfSpotControlProfile", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Spot Control Profile closing")
    }
  }, [getData(thisObj, "closingOfSpotControlProfile")])

  // START_USER_CODE-USER_METHODS
  const CompId = (sessionStorage.getItem('compId'));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));
  const UserId = (sessionStorage.getItem('userid'));

  function FormLoad() {
    try {
      bFillBuyingPointList()
      bFillTypeList()
      //getSpotPrices()
      //disable(thisObj, 'btnAdd');
      disable(thisObj, 'btnEdit');
      disable(thisObj, 'btnDelete');
      getSpotPrices();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillBuyingPointList = () => {
    try {
      let js = [];
      ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + ' - ' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyingPoint: {
              ...state["ddBuyingPoint"],
              valueList: js
            }
          }
        })
        if (js.length > 0){
          setValue(thisObj, 'ddBuyingPoint', js[0].key);
        }
      })
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillTypeList = () => {
    try {
      let js = [];
      ContractManagementService.RetrievePeanutTypeControls().then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPeanutType: {
              ...state["ddPeanutType"],
              valueList: js,
            }
          }
        })
        if (js.length > 0){
          setValue(thisObj, 'ddPeanutType', js[0].key);
        }
      })
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  Date.prototype.getFormattedTime = function () {
    try {
      let date = moment(this).format('MM/DD/YYYY');
      let hours = this.getHours() == 0 ? "12" : this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
      let minutes = (this.getMinutes() < 10 ? "0" : "") + this.getMinutes();
      let ampm = this.getHours() < 12 ? "AM" : "PM";
      let formattedTime = date + ' ' + hours + ":" + minutes + " " + ampm;
      return formattedTime;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const getSpotPrices = async () => {
    try {
      let buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
      let pnut_type_id = getValue(thisObj, 'ddPeanutType');
      let effect_date_time = '';
      let gridData = [];
      setValue(thisObj, 'gridSpotPrices', gridData);

      if (buy_pt_id != undefined && pnut_type_id != undefined)
      {
        let data = await SystemMaintenanceSpecialFunctionsService.RetrieveSpotPriceControlDetails(CompId, CropYear, buy_pt_id, pnut_type_id, effect_date_time)
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let obj = {};
            let dateValue = new Date(data[i].effectDateTime);
            let formattedTime = dateValue.getFormattedTime();
            obj.txtcolEffective = formattedTime;
            obj.txtcolSeg1 = FormatNumber(data[i].seg1Spot,2);
            obj.txtcolSeg2 = FormatNumber(data[i].seg2Spot,2);
            obj.txtcolSeg2Edible = FormatNumber(data[i].seg2EdibleSpot,2);
            obj.txtcolSeg2Oil = FormatNumber(data[i].seg2OilSpot,2);
            obj.txtcolSeg3 = FormatNumber(data[i].seg3Spot,2);
            obj.txtcolSeg3Edible = FormatNumber(data[i].seg3EdibleSpot,2);
            obj.txtcolSeg3Oil = FormatNumber(data[i].seg3OilSpot,2);
            gridData.push(obj);
          }
          setValue(thisObj, 'gridSpotPrices', gridData);
          if(gridData.length>0){
            enable(thisObj, 'btnEdit'); 
            enable(thisObj, 'btnDelete'); 
          } else {
            disable(thisObj, 'btnEdit');
            disable(thisObj, 'btnDelete');
          }
        } else {
          disable(thisObj, 'btnEdit');
          disable(thisObj, 'btnDelete');
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  function FormatNumber(value, fraction = 2) {
    try {
      let formattedNo;
      if (value != null && value != undefined) {
        if (value.toString().indexOf(",") == -1) {
          formattedNo = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: fraction,
            maximumFractionDigits: fraction,
          }).format(value);
        } else {
          formattedNo = value.toString();
        }
      } else {
        formattedNo = "";
      }
      return formattedNo;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onddBuyingPointChange = () => {
    try {
      getSpotPrices();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const onddPeanutTypeChange = () => {
    try {
      getSpotPrices();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onbtnAddClick = () => {
    try {
      let buyingPointList = thisObj.state['ddBuyingPoint'].valueList
      let buyingPointid = thisObj.values['ddBuyingPoint']
      let buyingPointName = ''
      if (buyingPointid !== "" && buyingPointid !== undefined) {
        buyingPointName = buyingPointList.find(elem => elem.key == buyingPointid).description;
      }
      let peanutTypeList = thisObj.state['ddPeanutType'].valueList
      let peanutTypeId = thisObj.values['ddPeanutType']
      let peanutTypeName = ''
      if (peanutTypeId !== "" && peanutTypeId !== undefined) {
        peanutTypeName = peanutTypeList.find(elem => elem.key == peanutTypeId).description;
      }
      let data1 = {
        "btnOk": "Add",
        "effectiveDateTime_enabled": true,
        "buy_pt_id": buyingPointid,
        "buy_pt_name": buyingPointName,
        "Pnut_type_id": peanutTypeId,
        "Pnut_type_name": peanutTypeName
      }
      setData(thisObj, 'SpotControlSetupData', data1)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SpotControlProfile#DEFAULT#true#Click")
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnEditClick = () => {
    try {
      let value = thisObj.state.gridSpotPrices.selected[0];
      if (value !== undefined) {
        let buyingPointList = thisObj.state['ddBuyingPoint'].valueList
        let buyingPointid = thisObj.values['ddBuyingPoint']
        let buyingPointName = ''
        if (buyingPointid !== "" && buyingPointid !== undefined) {
          buyingPointName = buyingPointList.find(elem => elem.key == buyingPointid).description;
        }
    
        let peanutTypeList = thisObj.state['ddPeanutType'].valueList
        let peanutTypeId = thisObj.values['ddPeanutType']
        let peanutTypeName = ''
        if (peanutTypeId !== "" && peanutTypeId !== undefined) {
          peanutTypeName = peanutTypeList.find(elem => elem.key == peanutTypeId).description;
        }
    
        let effect_date_time = value.txtcolEffective.replace(/[/]/g,'-');
        SystemMaintenanceSpecialFunctionsService.RetrieveSpotPriceControlDetails(CompId, CropYear, buyingPointid, peanutTypeId, effect_date_time).then(response => {
          if (response.length > 0) {
            let data1 = {
              "btnOk": "Update",
              "effectiveDateTime_enabled": false,
              "buy_pt_id": buyingPointid,
              "buy_pt_name": buyingPointName,
              "Pnut_type_id": peanutTypeId,
              "Pnut_type_name": peanutTypeName,
              "txtEffectiveDateTime": response[0].effectDateTime,
              "txtSeg1Spot": FormatNumber(response[0].seg1Spot,2),
              "txtSeg2Spot": FormatNumber(response[0].seg2Spot,2),
              "txtSeg2EditableSpot": FormatNumber(response[0].seg2EdibleSpot,2),
              "txtSeg2OilSpot": FormatNumber(response[0].seg2OilSpot,2),
              "txtSeg3Spot": FormatNumber(response[0].seg3Spot,2),
              "txtSeg3EditableSpot": FormatNumber(response[0].seg3EdibleSpot,2),
              "txtSeg3OilSpot": FormatNumber(response[0].seg3OilSpot,2),
              "lblAddedBy": response[0].addUser,
              "lblAddedDate": response[0].addDateTime,
              "lblChangedBy": response[0].chgUser,
              "lblChangedDate": response[0].chgDateTime
            }
            setData(thisObj, 'SpotControlSetupData', data1)
            goTo(thisObj, "SystemMaintenanceSpecialFunctions#SpotControlProfile#DEFAULT#true#Click")
          }
        })
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick;

  const onbtnDeleteClick = () => {
    try {
      let value = thisObj.state.gridSpotPrices.selected[0];
      let buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
      let pnut_type_id = getValue(thisObj, 'ddPeanutType');
      if (value !== undefined) {
        let effect_date_time = value.txtcolEffective.replace(/[/]/g,'-');
        const confirmBox = confirm("Are you sure you wish to delete this Spot Payment " + value.txtcolEffective)
        if (confirmBox == true) {
          SystemMaintenanceSpecialFunctionsService.RemoveSpotPriceControl(buy_pt_id, pnut_type_id, effect_date_time).then(response => {
            let data = response;
            if (data.status == 200) {
              showMessage(thisObj, data.message, true);
              getSpotPrices();
            }else{
              showMessage(thisObj, data.message, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
            }
          })
        }
      }
      else {
        showMessage(thisObj, "Invalid record selected. Select a valid row and try again!");
        return false;
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpotControlSetup*/}

              {/* END_USER_CODE-USER_BEFORE_SpotControlSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpotControlSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpotControlSetup*/}

              <GroupBoxWidget
                conf={state.grpbxSpotControlSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpotPrices*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpotPrices*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpotPrices}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpotPrices*/}

                {/* END_USER_CODE-USER_AFTER_lblSpotPrices*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridSpotPrices*/}

                {/* END_USER_CODE-USER_BEFORE_gridSpotPrices*/}

                <GridWidget
                  conf={state.gridSpotPrices}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridSpotPrices}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridSpotPrices*/}

                {/* END_USER_CODE-USER_AFTER_gridSpotPrices*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpotControlSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpotControlSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
              
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_SpotControlSetup*/}

              {/* END_USER_CODE-USER_AFTER_SpotControlSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SpotControlSetup);
