/* eslint-disable*/
import React from "react";
import AccountPayment_SpecialPayablesInquiry from "./SpecialPayablesInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecialPayablesInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayablesInquiry />);
    });
  });
  afterEach(cleanup);
  test("is SpecialPayablesInquiry Loads Successfully", () => {
    expect(screen.getByText("SpecialPayablesInquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecialPayablesInquiry", () => {
    // START_USER_CODE-USER_SpecialPayablesInquiry_Custom_Test_Case
    // END_USER_CODE-USER_SpecialPayablesInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayablesInquiry />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateSpecialPayable(Button Widget) Test Cases", async () => {
    const btnCreateSpecialPayable = screen.getByTestId(
      "btnCreateSpecialPayable"
    );
    expect(btnCreateSpecialPayable).toBeInTheDocument;
    expect(btnCreateSpecialPayable.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_btnCreateSpecialPayable")
    );
  });
  test("Custom Test Cases for btnCreateSpecialPayable", () => {
    // START_USER_CODE-USER_btnCreateSpecialPayable_TEST
    // END_USER_CODE-USER_btnCreateSpecialPayable_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridSearchResults(Grid Widget) Test Cases", async () => {
    let gridSearchResults = screen.getByTestId("gridSearchResults");
    let gridSearchResultsbtn =
      gridSearchResults.nextElementSibling.firstElementChild;
    gridSearchResults =
      gridSearchResults.parentElement.parentElement.parentElement;
    expect(gridSearchResults.tagName).toBe("DIV");
    expect(gridSearchResults.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSearchResults", () => {
    // START_USER_CODE-USER_gridSearchResults_TEST
    // END_USER_CODE-USER_gridSearchResults_TEST
  });
  test("grpbxActionsAndResults(GroupBox Widget) Test Cases", async () => {
    const grpbxActionsAndResults = screen.getByTestId("grpbxActionsAndResults");
    expect(grpbxActionsAndResults.tagName).toBe("BUTTON");
    expect(grpbxActionsAndResults.type).toBe("button");
    expect(grpbxActionsAndResults.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActionsAndResults", () => {
    // START_USER_CODE-USER_grpbxActionsAndResults_TEST
    // END_USER_CODE-USER_grpbxActionsAndResults_TEST
  });
  test("grpbxSpcPayableDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxSpcPayableDetails = screen.getByTestId("grpbxSpcPayableDetails");
    expect(grpbxSpcPayableDetails.tagName).toBe("BUTTON");
    expect(grpbxSpcPayableDetails.type).toBe("button");
    expect(grpbxSpcPayableDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpcPayableDetails", () => {
    // START_USER_CODE-USER_grpbxSpcPayableDetails_TEST
    // END_USER_CODE-USER_grpbxSpcPayableDetails_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("txtCheck(Textbox Widget) Test Cases", async () => {
    const txtCheck = screen.getByTestId("txtCheck");
    expect(txtCheck.tagName).toBe("INPUT");
    expect(txtCheck.type).toBe("text");
    expect(txtCheck.classList).toContain("textboxWidgetClass");
    expect(txtCheck.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtCheck")
    );
    await act(async () => {
      userEvent.type(txtCheck, "1");
    });
  });
  test("Custom Test Cases for txtCheck", () => {
    // START_USER_CODE-USER_txtCheck_TEST
    // END_USER_CODE-USER_txtCheck_TEST
  });
  test("gridSearchResults_txtcolAmount(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolAmount = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolAmountbtn =
      gridSearchResults_txtcolAmount.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolAmount =
      gridSearchResults_txtcolAmount.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolAmount.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolAmount.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmount", () => {
    // START_USER_CODE-USER_txtcolAmount_TEST
    // END_USER_CODE-USER_txtcolAmount_TEST
  });
  test("gridSearchResults_txtcolChargeLocation(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolChargeLocation = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolChargeLocationbtn =
      gridSearchResults_txtcolChargeLocation.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolChargeLocation =
      gridSearchResults_txtcolChargeLocation.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolChargeLocation.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolChargeLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChargeLocation", () => {
    // START_USER_CODE-USER_txtcolChargeLocation_TEST
    // END_USER_CODE-USER_txtcolChargeLocation_TEST
  });
  test("gridSearchResults_txtcolMemo(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolMemo = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolMemobtn =
      gridSearchResults_txtcolMemo.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolMemo =
      gridSearchResults_txtcolMemo.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolMemo.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolMemo.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMemo", () => {
    // START_USER_CODE-USER_txtcolMemo_TEST
    // END_USER_CODE-USER_txtcolMemo_TEST
  });
  test("gridSearchResults_txtcolSpoolLocation(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolSpoolLocation = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolSpoolLocationbtn =
      gridSearchResults_txtcolSpoolLocation.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolSpoolLocation =
      gridSearchResults_txtcolSpoolLocation.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolSpoolLocation.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolSpoolLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSpoolLocation", () => {
    // START_USER_CODE-USER_txtcolSpoolLocation_TEST
    // END_USER_CODE-USER_txtcolSpoolLocation_TEST
  });
  test("gridSearchResults_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolStatus = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolStatusbtn =
      gridSearchResults_txtcolStatus.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolStatus =
      gridSearchResults_txtcolStatus.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolStatus.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridSearchResults_txtcolVendorName(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolVendorName = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolVendorNamebtn =
      gridSearchResults_txtcolVendorName.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolVendorName =
      gridSearchResults_txtcolVendorName.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolVendorName.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolVendorName.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:SpecialPayablesInquiry_gridSearchResults")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorName", () => {
    // START_USER_CODE-USER_txtcolVendorName_TEST
    // END_USER_CODE-USER_txtcolVendorName_TEST
  });
  test("txtEndDate(Textbox Widget) Test Cases", async () => {
    const txtEndDate = screen.getByTestId("txtEndDate");
    expect(txtEndDate.tagName).toBe("INPUT");
    expect(txtEndDate.type).toBe("text");
    expect(txtEndDate.classList).toContain("textboxWidgetClass");
    expect(txtEndDate.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtEndDate")
    );
    await act(async () => {
      userEvent.type(txtEndDate, "1");
    });
  });
  test("Custom Test Cases for txtEndDate", () => {
    // START_USER_CODE-USER_txtEndDate_TEST
    // END_USER_CODE-USER_txtEndDate_TEST
  });
  test("txtInvoice(Textbox Widget) Test Cases", async () => {
    const txtInvoice = screen.getByTestId("txtInvoice");
    expect(txtInvoice.tagName).toBe("INPUT");
    expect(txtInvoice.type).toBe("text");
    expect(txtInvoice.classList).toContain("textboxWidgetClass");
    expect(txtInvoice.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtInvoice")
    );
    await act(async () => {
      userEvent.type(txtInvoice, "1");
    });
  });
  test("Custom Test Cases for txtInvoice", () => {
    // START_USER_CODE-USER_txtInvoice_TEST
    // END_USER_CODE-USER_txtInvoice_TEST
  });
  test("txtMemo(Textbox Widget) Test Cases", async () => {
    const txtMemo = screen.getByTestId("txtMemo");
    expect(txtMemo.tagName).toBe("INPUT");
    expect(txtMemo.type).toBe("text");
    expect(txtMemo.classList).toContain("textboxWidgetClass");
    expect(txtMemo.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtMemo")
    );
    await act(async () => {
      userEvent.type(txtMemo, "1");
    });
  });
  test("Custom Test Cases for txtMemo", () => {
    // START_USER_CODE-USER_txtMemo_TEST
    // END_USER_CODE-USER_txtMemo_TEST
  });
  test("txtPayAmount(Textbox Widget) Test Cases", async () => {
    const txtPayAmount = screen.getByTestId("txtPayAmount");
    expect(txtPayAmount.tagName).toBe("INPUT");
    expect(txtPayAmount.type).toBe("text");
    expect(txtPayAmount.classList).toContain("textboxWidgetClass");
    expect(txtPayAmount.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtPayAmount")
    );
    await act(async () => {
      userEvent.type(txtPayAmount, "1");
    });
  });
  test("Custom Test Cases for txtPayAmount", () => {
    // START_USER_CODE-USER_txtPayAmount_TEST
    // END_USER_CODE-USER_txtPayAmount_TEST
  });
  test("txtStartDate(Textbox Widget) Test Cases", async () => {
    const txtStartDate = screen.getByTestId("txtStartDate");
    expect(txtStartDate.tagName).toBe("INPUT");
    expect(txtStartDate.type).toBe("text");
    expect(txtStartDate.classList).toContain("textboxWidgetClass");
    expect(txtStartDate.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtStartDate")
    );
    await act(async () => {
      userEvent.type(txtStartDate, "1");
    });
  });
  test("Custom Test Cases for txtStartDate", () => {
    // START_USER_CODE-USER_txtStartDate_TEST
    // END_USER_CODE-USER_txtStartDate_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesInquiry_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
