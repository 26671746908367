/* eslint-disable*/
import { StockTransferService } from '../Service/StockTransferService';
import { clsArea } from './clsArea';

const defaultPriceScheduleProps = {
    COMP_ID: '',
    CROP_YEAR: '',
    AREA_ID: '',
    PNUT_TYPE_ID: '',
    SUPPORT_PER_TON: '',
    SMK_SS_PER_PCT: '',
    OK_PER_PCT: '',
    ELK_PREM: '',
    MIN_FAN_ELK_PREM: '',
    LSK_PER_TON: '',
    MAX_MOIST: '',
    FAN_THRESHOLD: '',
    FAN_FAIL_PN_ID: '',
    CR_BR_DCOL_THRESHOLD: '',
    CR_BR_DCOL_FAIL_PN_ID: '',
    ADD_USER: '',
    ADD_DATE_TIME: '',
    CHG_USER: '',
    CHG_DATE_TIME: '',
    MARKET_PRICE_FLOOR: '',
    MARKET_PRICE_CEILING: '',
    MARKET_REBATE_RATE: '',
}

const mDiscountList = [];

export class clsPeanutPrice {

    constructor(thisObj) {
        this.thisObj = thisObj;
        this.mudtProps = Object.assign({}, defaultPriceScheduleProps);
    }

    COMP_ID = (value) => {
        this.mudtProps.COMP_ID = value;
    };

    CROP_YEAR = (value) => {
        this.mudtProps.CROP_YEAR = value;
    };

    AREA_ID = (value) => {
        this.mudtProps.AREA_ID = value;
    };

    PNUT_TYPE_ID = (value) => {
        this.mudtProps.PNUT_TYPE_ID = value;
    };

    SUPPORT_PER_TON = (value) => {
        this.mudtProps.SUPPORT_PER_TON = value;
    };

    SMK_SS_PER_PCT = (value) => {
        this.mudtProps.SMK_SS_PER_PCT = value;
    };

    OK_PER_PCT = (value) => {
        this.mudtProps.OK_PER_PCT = value;
    };

    ELK_PREM = (value) => {
        this.mudtProps.ELK_PREM = value;
    };

    MIN_FAN_ELK_PREM = (value) => {
        this.mudtProps.MIN_FAN_ELK_PREM = value;
    };

    LSK_PER_TON = (value) => {
        this.mudtProps.LSK_PER_TON = value;
    };

    MAX_MOIST = (value) => {
        this.mudtProps.MAX_MOIST = value;
    };

    FAN_THRESHOLD = (value) => {
        this.mudtProps.FAN_THRESHOLD = value;
    };

    FAN_FAIL_PN_ID = (value) => {
        this.mudtProps.FAN_FAIL_PN_ID = value;
    };

    CR_BR_DCOL_THRESHOLD = (value) => {
        this.mudtProps.CR_BR_DCOL_THRESHOLD = value;
    };

    CR_BR_DCOL_FAIL_PN_ID = (value) => {
        this.mudtProps.CR_BR_DCOL_FAIL_PN_ID = value;
    };

    ADD_USER = (value) => {
        this.mudtProps.ADD_USER = value;
    };

    ADD_DATE_TIME = (value) => {
        this.mudtProps.ADD_DATE_TIME = value;
    };

    CHG_USER = (value) => {
        this.mudtProps.CHG_USER = value;
    };

    CHG_DATE_TIME = (value) => {
        this.mudtProps.CHG_DATE_TIME = value;
    };

    MARKET_PRICE_FLOOR = (value) => {
        this.mudtProps.MARKET_PRICE_FLOOR = value;
    };

    MARKET_PRICE_CEILING = (value) => {
        this.mudtProps.MARKET_PRICE_CEILING = value;
    };

    MARKET_REBATE_RATE = (value) => {
        this.mudtProps.MARKET_REBATE_RATE = value;
    };

    async Load  (AreaID, PeanutTypeID)  {
        let retrievePriceScheduleSelectDetails = await StockTransferService.RetrievePriceScheduleSelectDetails(AreaID, PeanutTypeID);
        if (retrievePriceScheduleSelectDetails.length > 0) {
            this.Fetch(retrievePriceScheduleSelectDetails[0]);
        }
        let retrieveDiscountScheduleSelectDetails = await StockTransferService.RetrieveDiscountScheduleSelectDetails(AreaID, PeanutTypeID);
        if (retrieveDiscountScheduleSelectDetails.length > 0) {
            mDiscountList = retrieveDiscountScheduleSelectDetails;
        }
    };

    Fetch = (data) => {
        let self = this;
        Object.keys(data).forEach(function (reskey) {
            self[reskey](data[reskey]);
        });
    };

    GetDiscount = (DiscountType, Readings) => {
        let GetDiscount = 0;
        for (let i = 0; i <= mDiscountList.length; i++) {
            if (DiscountType === mDiscountList[i].DISCOUNT_TYPE &&
                Readings >= mDiscountList[i].READING_FROM &&
                Readings <= mDiscountList[i].READING_TO) {
                GetDiscount = mDiscountList[i].DISCOUNT_PER_TON;
            }
        }
    }
}