/* eslint-disable*/
import React from "react";
import ContractManagement_FlexibleMarketPricings from "./FlexibleMarketPricings";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FlexibleMarketPricings Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_FlexibleMarketPricings />);
    });
  });
  afterEach(cleanup);
  test("is FlexibleMarketPricings Loads Successfully", () => {
    expect(screen.getByText("FlexibleMarketPricingsTest")).toBeInTheDocument;
  });
  test("Custom Test Cases for FlexibleMarketPricings", () => {
    // START_USER_CODE-USER_FlexibleMarketPricings_Custom_Test_Case
    // END_USER_CODE-USER_FlexibleMarketPricings_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_FlexibleMarketPricings />);
    });
  });
  afterEach(cleanup);
  test("btnApprv(Button Widget) Test Cases", async () => {
    const btnApprv = screen.getByTestId("btnApprv");
    expect(btnApprv).toBeInTheDocument;
    expect(btnApprv.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnApprv")
    );
  });
  test("Custom Test Cases for btnApprv", () => {
    // START_USER_CODE-USER_btnApprv_TEST
    // END_USER_CODE-USER_btnApprv_TEST
  });
  test("btnCreate(Button Widget) Test Cases", async () => {
    const btnCreate = screen.getByTestId("btnCreate");
    expect(btnCreate).toBeInTheDocument;
    expect(btnCreate.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnCreate")
    );
  });
  test("Custom Test Cases for btnCreate", () => {
    // START_USER_CODE-USER_btnCreate_TEST
    // END_USER_CODE-USER_btnCreate_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnPrintWork(Button Widget) Test Cases", async () => {
    const btnPrintWork = screen.getByTestId("btnPrintWork");
    expect(btnPrintWork).toBeInTheDocument;
    expect(btnPrintWork.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnPrintWork")
    );
  });
  test("Custom Test Cases for btnPrintWork", () => {
    // START_USER_CODE-USER_btnPrintWork_TEST
    // END_USER_CODE-USER_btnPrintWork_TEST
  });
  test("btnPrntPrcng(Button Widget) Test Cases", async () => {
    const btnPrntPrcng = screen.getByTestId("btnPrntPrcng");
    expect(btnPrntPrcng).toBeInTheDocument;
    expect(btnPrntPrcng.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnPrntPrcng")
    );
  });
  test("Custom Test Cases for btnPrntPrcng", () => {
    // START_USER_CODE-USER_btnPrntPrcng_TEST
    // END_USER_CODE-USER_btnPrntPrcng_TEST
  });
  test("btnRescan(Button Widget) Test Cases", async () => {
    const btnRescan = screen.getByTestId("btnRescan");
    expect(btnRescan).toBeInTheDocument;
    expect(btnRescan.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnRescan")
    );
  });
  test("Custom Test Cases for btnRescan", () => {
    // START_USER_CODE-USER_btnRescan_TEST
    // END_USER_CODE-USER_btnRescan_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("gridExistingPricing_colBtnWhsRcpts(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colBtnWhsRcpts = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colBtnWhsRcptsbtn =
      gridExistingPricing_colBtnWhsRcpts.nextElementSibling.firstElementChild;
    gridExistingPricing_colBtnWhsRcpts =
      gridExistingPricing_colBtnWhsRcpts.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colBtnWhsRcpts.tagName).toBe("DIV");
    expect(gridExistingPricing_colBtnWhsRcpts.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colBtnWhsRcpts", () => {
    // START_USER_CODE-USER_colBtnWhsRcpts_TEST
    // END_USER_CODE-USER_colBtnWhsRcpts_TEST
  });
  test("gridExistingPricing_colTxtElection(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtElection = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtElectionbtn =
      gridExistingPricing_colTxtElection.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtElection =
      gridExistingPricing_colTxtElection.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtElection.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtElection.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtElection", () => {
    // START_USER_CODE-USER_colTxtElection_TEST
    // END_USER_CODE-USER_colTxtElection_TEST
  });
  test("gridExistingPricing_colTxtEmpty(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtEmpty = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtEmptybtn =
      gridExistingPricing_colTxtEmpty.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtEmpty =
      gridExistingPricing_colTxtEmpty.parentElement.parentElement.parentElement;
    expect(gridExistingPricing_colTxtEmpty.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtEmpty.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtEmpty", () => {
    // START_USER_CODE-USER_colTxtEmpty_TEST
    // END_USER_CODE-USER_colTxtEmpty_TEST
  });
  test("gridExistingPricing_colTxtLoanGain(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtLoanGain = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtLoanGainbtn =
      gridExistingPricing_colTxtLoanGain.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtLoanGain =
      gridExistingPricing_colTxtLoanGain.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtLoanGain.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtLoanGain.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtLoanGain", () => {
    // START_USER_CODE-USER_colTxtLoanGain_TEST
    // END_USER_CODE-USER_colTxtLoanGain_TEST
  });
  test("gridExistingPricing_colTxtMarketPrice(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtMarketPrice = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtMarketPricebtn =
      gridExistingPricing_colTxtMarketPrice.nextElementSibling
        .firstElementChild;
    gridExistingPricing_colTxtMarketPrice =
      gridExistingPricing_colTxtMarketPrice.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtMarketPrice.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtMarketPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtMarketPrice", () => {
    // START_USER_CODE-USER_colTxtMarketPrice_TEST
    // END_USER_CODE-USER_colTxtMarketPrice_TEST
  });
  test("gridExistingPricing_colTxtPostedPrice(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtPostedPrice = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtPostedPricebtn =
      gridExistingPricing_colTxtPostedPrice.nextElementSibling
        .firstElementChild;
    gridExistingPricing_colTxtPostedPrice =
      gridExistingPricing_colTxtPostedPrice.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtPostedPrice.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtPostedPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtPostedPrice", () => {
    // START_USER_CODE-USER_colTxtPostedPrice_TEST
    // END_USER_CODE-USER_colTxtPostedPrice_TEST
  });
  test("gridExistingPricing_colTxtPounds(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtPounds = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtPoundsbtn =
      gridExistingPricing_colTxtPounds.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtPounds =
      gridExistingPricing_colTxtPounds.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtPounds.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtPounds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtPounds", () => {
    // START_USER_CODE-USER_colTxtPounds_TEST
    // END_USER_CODE-USER_colTxtPounds_TEST
  });
  test("gridExistingPricing_colTxtPricingDate(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtPricingDate = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtPricingDatebtn =
      gridExistingPricing_colTxtPricingDate.nextElementSibling
        .firstElementChild;
    gridExistingPricing_colTxtPricingDate =
      gridExistingPricing_colTxtPricingDate.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtPricingDate.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtPricingDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtPricingDate", () => {
    // START_USER_CODE-USER_colTxtPricingDate_TEST
    // END_USER_CODE-USER_colTxtPricingDate_TEST
  });
  test("gridExistingPricing_colTxtPricingNum(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtPricingNum = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtPricingNumbtn =
      gridExistingPricing_colTxtPricingNum.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtPricingNum =
      gridExistingPricing_colTxtPricingNum.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtPricingNum.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtPricingNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtPricingNum", () => {
    // START_USER_CODE-USER_colTxtPricingNum_TEST
    // END_USER_CODE-USER_colTxtPricingNum_TEST
  });
  test("gridExistingPricing_colTxtRebateRate(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtRebateRate = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtRebateRatebtn =
      gridExistingPricing_colTxtRebateRate.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtRebateRate =
      gridExistingPricing_colTxtRebateRate.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtRebateRate.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtRebateRate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtRebateRate", () => {
    // START_USER_CODE-USER_colTxtRebateRate_TEST
    // END_USER_CODE-USER_colTxtRebateRate_TEST
  });
  test("gridExistingPricing_colTxtRebateVal(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtRebateVal = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtRebateValbtn =
      gridExistingPricing_colTxtRebateVal.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtRebateVal =
      gridExistingPricing_colTxtRebateVal.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtRebateVal.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtRebateVal.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtRebateVal", () => {
    // START_USER_CODE-USER_colTxtRebateVal_TEST
    // END_USER_CODE-USER_colTxtRebateVal_TEST
  });
  test("gridExistingPricing_colTxtStatus(Grid Widget) Test Cases", async () => {
    let gridExistingPricing_colTxtStatus = screen.getByTestId(
      "gridExistingPricing"
    );
    let gridExistingPricing_colTxtStatusbtn =
      gridExistingPricing_colTxtStatus.nextElementSibling.firstElementChild;
    gridExistingPricing_colTxtStatus =
      gridExistingPricing_colTxtStatus.parentElement.parentElement
        .parentElement;
    expect(gridExistingPricing_colTxtStatus.tagName).toBe("DIV");
    expect(gridExistingPricing_colTxtStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for colTxtStatus", () => {
    // START_USER_CODE-USER_colTxtStatus_TEST
    // END_USER_CODE-USER_colTxtStatus_TEST
  });
  test("gridExistingPricing(Grid Widget) Test Cases", async () => {
    let gridExistingPricing = screen.getByTestId("gridExistingPricing");
    let gridExistingPricingbtn =
      gridExistingPricing.nextElementSibling.firstElementChild;
    gridExistingPricing =
      gridExistingPricing.parentElement.parentElement.parentElement;
    expect(gridExistingPricing.tagName).toBe("DIV");
    expect(gridExistingPricing.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:FlexibleMarketPricings_gridExistingPricing")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridExistingPricing", () => {
    // START_USER_CODE-USER_gridExistingPricing_TEST
    // END_USER_CODE-USER_gridExistingPricing_TEST
  });
  test("grpBoxBottom(GroupBox Widget) Test Cases", async () => {
    const grpBoxBottom = screen.getByTestId("grpBoxBottom");
    expect(grpBoxBottom.tagName).toBe("BUTTON");
    expect(grpBoxBottom.type).toBe("button");
    expect(grpBoxBottom.classList).toContain("btn");
  });
  test("Custom Test Cases for grpBoxBottom", () => {
    // START_USER_CODE-USER_grpBoxBottom_TEST
    // END_USER_CODE-USER_grpBoxBottom_TEST
  });
  test("grpboxMiddle(GroupBox Widget) Test Cases", async () => {
    const grpboxMiddle = screen.getByTestId("grpboxMiddle");
    expect(grpboxMiddle.tagName).toBe("BUTTON");
    expect(grpboxMiddle.type).toBe("button");
    expect(grpboxMiddle.classList).toContain("btn");
  });
  test("Custom Test Cases for grpboxMiddle", () => {
    // START_USER_CODE-USER_grpboxMiddle_TEST
    // END_USER_CODE-USER_grpboxMiddle_TEST
  });
  test("grpBoxTop(GroupBox Widget) Test Cases", async () => {
    const grpBoxTop = screen.getByTestId("grpBoxTop");
    expect(grpBoxTop.tagName).toBe("BUTTON");
    expect(grpBoxTop.type).toBe("button");
    expect(grpBoxTop.classList).toContain("btn");
  });
  test("Custom Test Cases for grpBoxTop", () => {
    // START_USER_CODE-USER_grpBoxTop_TEST
    // END_USER_CODE-USER_grpBoxTop_TEST
  });
  test("lblBoxPostingDate(Label Widget) Test Cases", async () => {
    const lblBoxPostingDate = screen.getByTestId("lblBoxPostingDate");
    expect(lblBoxPostingDate.tagName).toBe("LABEL");
    expect(lblBoxPostingDate.classList).toContain("form-label");
    expect(lblBoxPostingDate.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_lblBoxPostingDate")
    );
  });
  test("Custom Test Cases for lblBoxPostingDate", () => {
    // START_USER_CODE-USER_lblBoxPostingDate_TEST
    // END_USER_CODE-USER_lblBoxPostingDate_TEST
  });
  test("txtApprovedPrice(Textbox Widget) Test Cases", async () => {
    const txtApprovedPrice = screen.getByTestId("txtApprovedPrice");
    expect(txtApprovedPrice.tagName).toBe("INPUT");
    expect(txtApprovedPrice.type).toBe("text");
    expect(txtApprovedPrice.classList).toContain("textboxWidgetClass");
    expect(txtApprovedPrice.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtApprovedPrice")
    );
    await act(async () => {
      userEvent.type(txtApprovedPrice, "1");
    });
  });
  test("Custom Test Cases for txtApprovedPrice", () => {
    // START_USER_CODE-USER_txtApprovedPrice_TEST
    // END_USER_CODE-USER_txtApprovedPrice_TEST
  });
  test("txtAvailableLbs(Textbox Widget) Test Cases", async () => {
    const txtAvailableLbs = screen.getByTestId("txtAvailableLbs");
    expect(txtAvailableLbs.tagName).toBe("INPUT");
    expect(txtAvailableLbs.type).toBe("text");
    expect(txtAvailableLbs.classList).toContain("textboxWidgetClass");
    expect(txtAvailableLbs.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtAvailableLbs")
    );
    await act(async () => {
      userEvent.type(txtAvailableLbs, "1");
    });
  });
  test("Custom Test Cases for txtAvailableLbs", () => {
    // START_USER_CODE-USER_txtAvailableLbs_TEST
    // END_USER_CODE-USER_txtAvailableLbs_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtCrntDate(Textbox Widget) Test Cases", async () => {
    const txtCrntDate = screen.getByTestId("txtCrntDate");
    expect(txtCrntDate.tagName).toBe("INPUT");
    expect(txtCrntDate.type).toBe("text");
    expect(txtCrntDate.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrntDate, "1");
    });
  });
  test("Custom Test Cases for txtCrntDate", () => {
    // START_USER_CODE-USER_txtCrntDate_TEST
    // END_USER_CODE-USER_txtCrntDate_TEST
  });
  test("txtMarketEndDate(Textbox Widget) Test Cases", async () => {
    const txtMarketEndDate = screen.getByTestId("txtMarketEndDate");
    expect(txtMarketEndDate.tagName).toBe("INPUT");
    expect(txtMarketEndDate.type).toBe("text");
    expect(txtMarketEndDate.classList).toContain("textboxWidgetClass");
    expect(txtMarketEndDate.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtMarketEndDate")
    );
    await act(async () => {
      userEvent.type(txtMarketEndDate, "1");
    });
  });
  test("Custom Test Cases for txtMarketEndDate", () => {
    // START_USER_CODE-USER_txtMarketEndDate_TEST
    // END_USER_CODE-USER_txtMarketEndDate_TEST
  });
  test("txtMultiPricing(Textbox Widget) Test Cases", async () => {
    const txtMultiPricing = screen.getByTestId("txtMultiPricing");
    expect(txtMultiPricing.tagName).toBe("INPUT");
    expect(txtMultiPricing.type).toBe("text");
    expect(txtMultiPricing.classList).toContain("textboxWidgetClass");
    expect(txtMultiPricing.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtMultiPricing")
    );
    await act(async () => {
      userEvent.type(txtMultiPricing, "1");
    });
  });
  test("Custom Test Cases for txtMultiPricing", () => {
    // START_USER_CODE-USER_txtMultiPricing_TEST
    // END_USER_CODE-USER_txtMultiPricing_TEST
  });
  test("txtOverride(Textbox Widget) Test Cases", async () => {
    const txtOverride = screen.getByTestId("txtOverride");
    expect(txtOverride.tagName).toBe("INPUT");
    expect(txtOverride.type).toBe("text");
    expect(txtOverride.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOverride, "1");
    });
  });
  test("Custom Test Cases for txtOverride", () => {
    // START_USER_CODE-USER_txtOverride_TEST
    // END_USER_CODE-USER_txtOverride_TEST
  });
  test("txtPoundsPriced(Textbox Widget) Test Cases", async () => {
    const txtPoundsPriced = screen.getByTestId("txtPoundsPriced");
    expect(txtPoundsPriced.tagName).toBe("INPUT");
    expect(txtPoundsPriced.type).toBe("text");
    expect(txtPoundsPriced.classList).toContain("textboxWidgetClass");
    expect(txtPoundsPriced.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtPoundsPriced")
    );
    await act(async () => {
      userEvent.type(txtPoundsPriced, "1");
    });
  });
  test("Custom Test Cases for txtPoundsPriced", () => {
    // START_USER_CODE-USER_txtPoundsPriced_TEST
    // END_USER_CODE-USER_txtPoundsPriced_TEST
  });
  test("txtRemainingLbs(Textbox Widget) Test Cases", async () => {
    const txtRemainingLbs = screen.getByTestId("txtRemainingLbs");
    expect(txtRemainingLbs.tagName).toBe("INPUT");
    expect(txtRemainingLbs.type).toBe("text");
    expect(txtRemainingLbs.classList).toContain("textboxWidgetClass");
    expect(txtRemainingLbs.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtRemainingLbs")
    );
    await act(async () => {
      userEvent.type(txtRemainingLbs, "1");
    });
  });
  test("Custom Test Cases for txtRemainingLbs", () => {
    // START_USER_CODE-USER_txtRemainingLbs_TEST
    // END_USER_CODE-USER_txtRemainingLbs_TEST
  });
  test("txtUnApprovedPriced(Textbox Widget) Test Cases", async () => {
    const txtUnApprovedPriced = screen.getByTestId("txtUnApprovedPriced");
    expect(txtUnApprovedPriced.tagName).toBe("INPUT");
    expect(txtUnApprovedPriced.type).toBe("text");
    expect(txtUnApprovedPriced.classList).toContain("textboxWidgetClass");
    expect(txtUnApprovedPriced.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexibleMarketPricings_txtUnApprovedPriced")
    );
    await act(async () => {
      userEvent.type(txtUnApprovedPriced, "1");
    });
  });
  test("Custom Test Cases for txtUnApprovedPriced", () => {
    // START_USER_CODE-USER_txtUnApprovedPriced_TEST
    // END_USER_CODE-USER_txtUnApprovedPriced_TEST
  });
});
