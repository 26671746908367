/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityReport from "./SecurityReport";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityReport Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityReport />);
    });
  });
  afterEach(cleanup);
  test("is SecurityReport Loads Successfully", () => {
    expect(screen.getByText("SecurityReport")).toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityReport", () => {
    // START_USER_CODE-USER_SecurityReport_Custom_Test_Case
    // END_USER_CODE-USER_SecurityReport_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityReport />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityReport_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityReport_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDeleteSelectedUsers(Button Widget) Test Cases", async () => {
    const btnDeleteSelectedUsers = screen.getByTestId("btnDeleteSelectedUsers");
    expect(btnDeleteSelectedUsers).toBeInTheDocument;
    expect(btnDeleteSelectedUsers.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityReport_btnDeleteSelectedUsers"
      )
    );
  });
  test("Custom Test Cases for btnDeleteSelectedUsers", () => {
    // START_USER_CODE-USER_btnDeleteSelectedUsers_TEST
    // END_USER_CODE-USER_btnDeleteSelectedUsers_TEST
  });
  test("btnRequestTheIBMUpdate(Button Widget) Test Cases", async () => {
    const btnRequestTheIBMUpdate = screen.getByTestId("btnRequestTheIBMUpdate");
    expect(btnRequestTheIBMUpdate).toBeInTheDocument;
    expect(btnRequestTheIBMUpdate.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityReport_btnRequestTheIBMUpdate"
      )
    );
  });
  test("Custom Test Cases for btnRequestTheIBMUpdate", () => {
    // START_USER_CODE-USER_btnRequestTheIBMUpdate_TEST
    // END_USER_CODE-USER_btnRequestTheIBMUpdate_TEST
  });
  test("btnSelectAll(Button Widget) Test Cases", async () => {
    const btnSelectAll = screen.getByTestId("btnSelectAll");
    expect(btnSelectAll).toBeInTheDocument;
    expect(btnSelectAll.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityReport_btnSelectAll")
    );
  });
  test("Custom Test Cases for btnSelectAll", () => {
    // START_USER_CODE-USER_btnSelectAll_TEST
    // END_USER_CODE-USER_btnSelectAll_TEST
  });
  test("btnUnselectAll(Button Widget) Test Cases", async () => {
    const btnUnselectAll = screen.getByTestId("btnUnselectAll");
    expect(btnUnselectAll).toBeInTheDocument;
    expect(btnUnselectAll.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityReport_btnUnselectAll")
    );
  });
  test("Custom Test Cases for btnUnselectAll", () => {
    // START_USER_CODE-USER_btnUnselectAll_TEST
    // END_USER_CODE-USER_btnUnselectAll_TEST
  });
  test("btnUpdatSelectedUser(Button Widget) Test Cases", async () => {
    const btnUpdatSelectedUser = screen.getByTestId("btnUpdatSelectedUser");
    expect(btnUpdatSelectedUser).toBeInTheDocument;
    expect(btnUpdatSelectedUser.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityReport_btnUpdatSelectedUser")
    );
  });
  test("Custom Test Cases for btnUpdatSelectedUser", () => {
    // START_USER_CODE-USER_btnUpdatSelectedUser_TEST
    // END_USER_CODE-USER_btnUpdatSelectedUser_TEST
  });
  test("gridUsers(Grid Widget) Test Cases", async () => {
    let gridUsers = screen.getByTestId("gridUsers");
    let gridUsersbtn = gridUsers.nextElementSibling.firstElementChild;
    gridUsers = gridUsers.parentElement.parentElement.parentElement;
    expect(gridUsers.tagName).toBe("DIV");
    expect(gridUsers.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceSpecialFunctions:SecurityReport_gridUsers")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridUsers", () => {
    // START_USER_CODE-USER_gridUsers_TEST
    // END_USER_CODE-USER_gridUsers_TEST
  });
  test("grpbxAddUser(GroupBox Widget) Test Cases", async () => {
    const grpbxAddUser = screen.getByTestId("grpbxAddUser");
    expect(grpbxAddUser.tagName).toBe("BUTTON");
    expect(grpbxAddUser.type).toBe("button");
    expect(grpbxAddUser.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAddUser", () => {
    // START_USER_CODE-USER_grpbxAddUser_TEST
    // END_USER_CODE-USER_grpbxAddUser_TEST
  });
  test("grpbxCropYear(GroupBox Widget) Test Cases", async () => {
    const grpbxCropYear = screen.getByTestId("grpbxCropYear");
    expect(grpbxCropYear.tagName).toBe("BUTTON");
    expect(grpbxCropYear.type).toBe("button");
    expect(grpbxCropYear.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCropYear", () => {
    // START_USER_CODE-USER_grpbxCropYear_TEST
    // END_USER_CODE-USER_grpbxCropYear_TEST
  });
  test("grpbxSecurityReport(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityReport = screen.getByTestId("grpbxSecurityReport");
    expect(grpbxSecurityReport.tagName).toBe("BUTTON");
    expect(grpbxSecurityReport.type).toBe("button");
    expect(grpbxSecurityReport.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityReport", () => {
    // START_USER_CODE-USER_grpbxSecurityReport_TEST
    // END_USER_CODE-USER_grpbxSecurityReport_TEST
  });
  test("gridUsers_txtcolCurrentYears(Grid Widget) Test Cases", async () => {
    let gridUsers_txtcolCurrentYears = screen.getByTestId("gridUsers");
    let gridUsers_txtcolCurrentYearsbtn =
      gridUsers_txtcolCurrentYears.nextElementSibling.firstElementChild;
    gridUsers_txtcolCurrentYears =
      gridUsers_txtcolCurrentYears.parentElement.parentElement.parentElement;
    expect(gridUsers_txtcolCurrentYears.tagName).toBe("DIV");
    expect(gridUsers_txtcolCurrentYears.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceSpecialFunctions:SecurityReport_gridUsers")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCurrentYears", () => {
    // START_USER_CODE-USER_txtcolCurrentYears_TEST
    // END_USER_CODE-USER_txtcolCurrentYears_TEST
  });
  test("gridUsers_txtcolUserID(Grid Widget) Test Cases", async () => {
    let gridUsers_txtcolUserID = screen.getByTestId("gridUsers");
    let gridUsers_txtcolUserIDbtn =
      gridUsers_txtcolUserID.nextElementSibling.firstElementChild;
    gridUsers_txtcolUserID =
      gridUsers_txtcolUserID.parentElement.parentElement.parentElement;
    expect(gridUsers_txtcolUserID.tagName).toBe("DIV");
    expect(gridUsers_txtcolUserID.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceSpecialFunctions:SecurityReport_gridUsers")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserID", () => {
    // START_USER_CODE-USER_txtcolUserID_TEST
    // END_USER_CODE-USER_txtcolUserID_TEST
  });
  test("gridUsers_txtcolUserName(Grid Widget) Test Cases", async () => {
    let gridUsers_txtcolUserName = screen.getByTestId("gridUsers");
    let gridUsers_txtcolUserNamebtn =
      gridUsers_txtcolUserName.nextElementSibling.firstElementChild;
    gridUsers_txtcolUserName =
      gridUsers_txtcolUserName.parentElement.parentElement.parentElement;
    expect(gridUsers_txtcolUserName.tagName).toBe("DIV");
    expect(gridUsers_txtcolUserName.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceSpecialFunctions:SecurityReport_gridUsers")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserName", () => {
    // START_USER_CODE-USER_txtcolUserName_TEST
    // END_USER_CODE-USER_txtcolUserName_TEST
  });
  test("txtOtherCropYearsToView(Textbox Widget) Test Cases", async () => {
    const txtOtherCropYearsToView = screen.getByTestId(
      "txtOtherCropYearsToView"
    );
    expect(txtOtherCropYearsToView.tagName).toBe("INPUT");
    expect(txtOtherCropYearsToView.type).toBe("text");
    expect(txtOtherCropYearsToView.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOtherCropYearsToView, "1");
    });
  });
  test("Custom Test Cases for txtOtherCropYearsToView", () => {
    // START_USER_CODE-USER_txtOtherCropYearsToView_TEST
    // END_USER_CODE-USER_txtOtherCropYearsToView_TEST
  });
});
