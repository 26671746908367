/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_DeductionTrackingSummary from "./DeductionTrackingSummary";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DeductionTrackingSummary Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_DeductionTrackingSummary />
      );
    });
  });
  afterEach(cleanup);
  test("is DeductionTrackingSummary Loads Successfully", () => {
    expect(screen.getByText("DeductionTrackingSummary")).toBeInTheDocument;
  });
  test("Custom Test Cases for DeductionTrackingSummary", () => {
    // START_USER_CODE-USER_DeductionTrackingSummary_Custom_Test_Case
    // END_USER_CODE-USER_DeductionTrackingSummary_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_DeductionTrackingSummary />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:DeductionTrackingSummary_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClearDeduction(Button Widget) Test Cases", async () => {
    const btnClearDeduction = screen.getByTestId("btnClearDeduction");
    expect(btnClearDeduction).toBeInTheDocument;
    expect(btnClearDeduction.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_btnClearDeduction"
      )
    );
  });
  test("Custom Test Cases for btnClearDeduction", () => {
    // START_USER_CODE-USER_btnClearDeduction_TEST
    // END_USER_CODE-USER_btnClearDeduction_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_btnClose"
      )
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_btnPrint"
      )
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnViewDetails(Button Widget) Test Cases", async () => {
    const btnViewDetails = screen.getByTestId("btnViewDetails");
    expect(btnViewDetails).toBeInTheDocument;
    expect(btnViewDetails.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_btnViewDetails"
      )
    );
  });
  test("Custom Test Cases for btnViewDetails", () => {
    // START_USER_CODE-USER_btnViewDetails_TEST
    // END_USER_CODE-USER_btnViewDetails_TEST
  });
  test("gridDeductionData(Grid Widget) Test Cases", async () => {
    let gridDeductionData = screen.getByTestId("gridDeductionData");
    let gridDeductionDatabtn =
      gridDeductionData.nextElementSibling.firstElementChild;
    gridDeductionData =
      gridDeductionData.parentElement.parentElement.parentElement;
    expect(gridDeductionData.tagName).toBe("DIV");
    expect(gridDeductionData.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_gridDeductionData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridDeductionData", () => {
    // START_USER_CODE-USER_gridDeductionData_TEST
    // END_USER_CODE-USER_gridDeductionData_TEST
  });
  test("grpbxDeductionTrackingSummary(GroupBox Widget) Test Cases", async () => {
    const grpbxDeductionTrackingSummary = screen.getByTestId(
      "grpbxDeductionTrackingSummary"
    );
    expect(grpbxDeductionTrackingSummary.tagName).toBe("BUTTON");
    expect(grpbxDeductionTrackingSummary.type).toBe("button");
    expect(grpbxDeductionTrackingSummary.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDeductionTrackingSummary", () => {
    // START_USER_CODE-USER_grpbxDeductionTrackingSummary_TEST
    // END_USER_CODE-USER_grpbxDeductionTrackingSummary_TEST
  });
  test("txtAccountVendor(Textbox Widget) Test Cases", async () => {
    const txtAccountVendor = screen.getByTestId("txtAccountVendor");
    expect(txtAccountVendor.tagName).toBe("INPUT");
    expect(txtAccountVendor.type).toBe("text");
    expect(txtAccountVendor.classList).toContain("textboxWidgetClass");
    expect(txtAccountVendor.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_txtAccountVendor"
      )
    );
    await act(async () => {
      userEvent.type(txtAccountVendor, "1");
    });
  });
  test("Custom Test Cases for txtAccountVendor", () => {
    // START_USER_CODE-USER_txtAccountVendor_TEST
    // END_USER_CODE-USER_txtAccountVendor_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_txtBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("gridDeductionData_txtcolAmountCharged(Grid Widget) Test Cases", async () => {
    let gridDeductionData_txtcolAmountCharged = screen.getByTestId(
      "gridDeductionData"
    );
    let gridDeductionData_txtcolAmountChargedbtn =
      gridDeductionData_txtcolAmountCharged.nextElementSibling
        .firstElementChild;
    gridDeductionData_txtcolAmountCharged =
      gridDeductionData_txtcolAmountCharged.parentElement.parentElement
        .parentElement;
    expect(gridDeductionData_txtcolAmountCharged.tagName).toBe("DIV");
    expect(gridDeductionData_txtcolAmountCharged.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_gridDeductionData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmountCharged", () => {
    // START_USER_CODE-USER_txtcolAmountCharged_TEST
    // END_USER_CODE-USER_txtcolAmountCharged_TEST
  });
  test("gridDeductionData_txtcolAmountCollected(Grid Widget) Test Cases", async () => {
    let gridDeductionData_txtcolAmountCollected = screen.getByTestId(
      "gridDeductionData"
    );
    let gridDeductionData_txtcolAmountCollectedbtn =
      gridDeductionData_txtcolAmountCollected.nextElementSibling
        .firstElementChild;
    gridDeductionData_txtcolAmountCollected =
      gridDeductionData_txtcolAmountCollected.parentElement
        .parentElement.parentElement;
    expect(gridDeductionData_txtcolAmountCollected.tagName).toBe("DIV");
    expect(gridDeductionData_txtcolAmountCollected.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_gridDeductionData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmountCollected", () => {
    // START_USER_CODE-USER_txtcolAmountCollected_TEST
    // END_USER_CODE-USER_txtcolAmountCollected_TEST
  });
  test("gridDeductionData_txtcolAmountDue(Grid Widget) Test Cases", async () => {
    let gridDeductionData_txtcolAmountDue = screen.getByTestId(
      "gridDeductionData"
    );
    let gridDeductionData_txtcolAmountDuebtn =
      gridDeductionData_txtcolAmountDue.nextElementSibling
        .firstElementChild;
    gridDeductionData_txtcolAmountDue =
      gridDeductionData_txtcolAmountDue.parentElement.parentElement
        .parentElement;
    expect(gridDeductionData_txtcolAmountDue.tagName).toBe("DIV");
    expect(gridDeductionData_txtcolAmountDue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_gridDeductionData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmountDue", () => {
    // START_USER_CODE-USER_txtcolAmountDue_TEST
    // END_USER_CODE-USER_txtcolAmountDue_TEST
  });
  test("gridDeductionData_txtcolDeductionData(Grid Widget) Test Cases", async () => {
    let gridDeductionData_txtcolDeductionData = screen.getByTestId(
      "gridDeductionData"
    );
    let gridDeductionData_txtcolDeductionDatabtn =
      gridDeductionData_txtcolDeductionData.nextElementSibling
        .firstElementChild;
    gridDeductionData_txtcolDeductionData =
      gridDeductionData_txtcolDeductionData.parentElement.parentElement
        .parentElement;
    expect(gridDeductionData_txtcolDeductionData.tagName).toBe("DIV");
    expect(gridDeductionData_txtcolDeductionData.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingSummary_gridDeductionData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDeductionData", () => {
    // START_USER_CODE-USER_txtcolDeductionData_TEST
    // END_USER_CODE-USER_txtcolDeductionData_TEST
  });
});
