/* eslint-disable*/
import React from "react";
import Settlements_TradeSettlePaymentView from "./TradeSettlePaymentView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettlePaymentView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettlePaymentView />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettlePaymentView Loads Successfully", () => {
    expect(screen.getByText("TradeSettlePaymentView")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettlePaymentView", () => {
    // START_USER_CODE-USER_TradeSettlePaymentView_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettlePaymentView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettlePaymentView />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:TradeSettlePaymentView_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportExcel(Button Widget) Test Cases", async () => {
    const btnExportExcel = screen.getByTestId("btnExportExcel");
    expect(btnExportExcel).toBeInTheDocument;
    expect(btnExportExcel.textContent).toEqual(
      t("Settlements:TradeSettlePaymentView_btnExportExcel")
    );
  });
  test("Custom Test Cases for btnExportExcel", () => {
    // START_USER_CODE-USER_btnExportExcel_TEST
    // END_USER_CODE-USER_btnExportExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:TradeSettlePaymentView_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSettings(Button Widget) Test Cases", async () => {
    const btnSettings = screen.getByTestId("btnSettings");
    expect(btnSettings).toBeInTheDocument;
    expect(btnSettings.textContent).toEqual(
      t("Settlements:TradeSettlePaymentView_btnSettings")
    );
  });
  test("Custom Test Cases for btnSettings", () => {
    // START_USER_CODE-USER_btnSettings_TEST
    // END_USER_CODE-USER_btnSettings_TEST
  });
  test("gridPaymentView(Grid Widget) Test Cases", async () => {
    let gridPaymentView = screen.getByTestId("gridPaymentView");
    let gridPaymentViewbtn =
      gridPaymentView.nextElementSibling.firstElementChild;
    gridPaymentView = gridPaymentView.parentElement.parentElement.parentElement;
    expect(gridPaymentView.tagName).toBe("DIV");
    expect(gridPaymentView.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPaymentView", () => {
    // START_USER_CODE-USER_gridPaymentView_TEST
    // END_USER_CODE-USER_gridPaymentView_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxViewPayment(GroupBox Widget) Test Cases", async () => {
    const grpbxViewPayment = screen.getByTestId("grpbxViewPayment");
    expect(grpbxViewPayment.tagName).toBe("BUTTON");
    expect(grpbxViewPayment.type).toBe("button");
    expect(grpbxViewPayment.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewPayment", () => {
    // START_USER_CODE-USER_grpbxViewPayment_TEST
    // END_USER_CODE-USER_grpbxViewPayment_TEST
  });
  test("lblBuyPtId(Label Widget) Test Cases", async () => {
    const lblBuyPtId = screen.getByTestId("lblBuyPtId");
    expect(lblBuyPtId.tagName).toBe("LABEL");
    expect(lblBuyPtId.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblBuyPtId", () => {
    // START_USER_CODE-USER_lblBuyPtId_TEST
    // END_USER_CODE-USER_lblBuyPtId_TEST
  });
  test("lblInspNum(Label Widget) Test Cases", async () => {
    const lblInspNum = screen.getByTestId("lblInspNum");
    expect(lblInspNum.tagName).toBe("LABEL");
    expect(lblInspNum.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblInspNum", () => {
    // START_USER_CODE-USER_lblInspNum_TEST
    // END_USER_CODE-USER_lblInspNum_TEST
  });
  test("lblPaymnt(Label Widget) Test Cases", async () => {
    const lblPaymnt = screen.getByTestId("lblPaymnt");
    expect(lblPaymnt.tagName).toBe("LABEL");
    expect(lblPaymnt.classList).toContain("form-label");
    expect(lblPaymnt.textContent).toEqual(
      t("Settlements:TradeSettlePaymentView_lblPaymnt")
    );
  });
  test("Custom Test Cases for lblPaymnt", () => {
    // START_USER_CODE-USER_lblPaymnt_TEST
    // END_USER_CODE-USER_lblPaymnt_TEST
  });
  test("gridPaymentView_txtcolByngPt(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolByngPt = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolByngPtbtn =
      gridPaymentView_txtcolByngPt.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolByngPt =
      gridPaymentView_txtcolByngPt.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolByngPt.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolByngPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolByngPtdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolByngPtdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolByngPtbtn, { button: 0 });
      });
      const gridPaymentView_txtcolByngPtadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolByngPtadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolByngPt", () => {
    // START_USER_CODE-USER_txtcolByngPt_TEST
    // END_USER_CODE-USER_txtcolByngPt_TEST
  });
  test("gridPaymentView_txtcolCleardDt(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolCleardDt = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolCleardDtbtn =
      gridPaymentView_txtcolCleardDt.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolCleardDt =
      gridPaymentView_txtcolCleardDt.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolCleardDt.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolCleardDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolCleardDtdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolCleardDtdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolCleardDtbtn, { button: 0 });
      });
      const gridPaymentView_txtcolCleardDtadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolCleardDtadd, { button: 0 });
      });
    }
    const txtcolCleardDt = screen.getByTestId("txtcolCleardDt");
    expect(txtcolCleardDt.tagName).toBe("INPUT");
    expect(txtcolCleardDt.type).toBe("text");
    expect(txtcolCleardDt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolCleardDt, "1");
    });
  });
  test("Custom Test Cases for txtcolCleardDt", () => {
    // START_USER_CODE-USER_txtcolCleardDt_TEST
    // END_USER_CODE-USER_txtcolCleardDt_TEST
  });
  test("gridPaymentView_txtcolIntrstAgreemnt(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolIntrstAgreemnt = screen.getByTestId(
      "gridPaymentView"
    );
    let gridPaymentView_txtcolIntrstAgreemntbtn =
      gridPaymentView_txtcolIntrstAgreemnt.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolIntrstAgreemnt =
      gridPaymentView_txtcolIntrstAgreemnt.parentElement.parentElement
        .parentElement;
    expect(gridPaymentView_txtcolIntrstAgreemnt.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolIntrstAgreemnt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolIntrstAgreemntdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolIntrstAgreemntdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolIntrstAgreemntbtn, { button: 0 });
      });
      const gridPaymentView_txtcolIntrstAgreemntadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolIntrstAgreemntadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolIntrstAgreemnt", () => {
    // START_USER_CODE-USER_txtcolIntrstAgreemnt_TEST
    // END_USER_CODE-USER_txtcolIntrstAgreemnt_TEST
  });
  test("gridPaymentView_txtcolIssueDt(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolIssueDt = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolIssueDtbtn =
      gridPaymentView_txtcolIssueDt.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolIssueDt =
      gridPaymentView_txtcolIssueDt.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolIssueDt.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolIssueDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolIssueDtdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolIssueDtdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolIssueDtbtn, { button: 0 });
      });
      const gridPaymentView_txtcolIssueDtadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolIssueDtadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolIssueDt", () => {
    // START_USER_CODE-USER_txtcolIssueDt_TEST
    // END_USER_CODE-USER_txtcolIssueDt_TEST
  });
  test("gridPaymentView_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolProceeds = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolProceedsbtn =
      gridPaymentView_txtcolProceeds.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolProceeds =
      gridPaymentView_txtcolProceeds.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolProceeds.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolProceeds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolProceedsdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolProceedsdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolProceedsbtn, { button: 0 });
      });
      const gridPaymentView_txtcolProceedsadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolProceedsadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridPaymentView_txtcolSc95(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolSc95 = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolSc95btn =
      gridPaymentView_txtcolSc95.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolSc95 =
      gridPaymentView_txtcolSc95.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolSc95.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolSc95.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolSc95data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolSc95data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolSc95btn, { button: 0 });
      });
      const gridPaymentView_txtcolSc95add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolSc95add, { button: 0 });
      });
    }
    const txtcolSc95 = screen.getByTestId("txtcolSc95");
    expect(txtcolSc95.tagName).toBe("INPUT");
    expect(txtcolSc95.type).toBe("text");
    expect(txtcolSc95.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolSc95, "1");
    });
  });
  test("Custom Test Cases for txtcolSc95", () => {
    // START_USER_CODE-USER_txtcolSc95_TEST
    // END_USER_CODE-USER_txtcolSc95_TEST
  });
  test("gridPaymentView_txtcolSpclTyp(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolSpclTyp = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolSpclTypbtn =
      gridPaymentView_txtcolSpclTyp.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolSpclTyp =
      gridPaymentView_txtcolSpclTyp.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolSpclTyp.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolSpclTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolSpclTypdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolSpclTypdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolSpclTypbtn, { button: 0 });
      });
      const gridPaymentView_txtcolSpclTypadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolSpclTypadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolSpclTyp", () => {
    // START_USER_CODE-USER_txtcolSpclTyp_TEST
    // END_USER_CODE-USER_txtcolSpclTyp_TEST
  });
  test("gridPaymentView_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolStatus = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolStatusbtn =
      gridPaymentView_txtcolStatus.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolStatus =
      gridPaymentView_txtcolStatus.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolStatus.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolStatusdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolStatusdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolStatusbtn, { button: 0 });
      });
      const gridPaymentView_txtcolStatusadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolStatusadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridPaymentView_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolVendor = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolVendorbtn =
      gridPaymentView_txtcolVendor.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolVendor =
      gridPaymentView_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolVendor.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolVendordata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolVendordata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolVendorbtn, { button: 0 });
      });
      const gridPaymentView_txtcolVendoradd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolVendoradd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridPaymentView_txtcolVoidDt(Grid Widget) Test Cases", async () => {
    let gridPaymentView_txtcolVoidDt = screen.getByTestId("gridPaymentView");
    let gridPaymentView_txtcolVoidDtbtn =
      gridPaymentView_txtcolVoidDt.nextElementSibling.firstElementChild;
    gridPaymentView_txtcolVoidDt =
      gridPaymentView_txtcolVoidDt.parentElement.parentElement.parentElement;
    expect(gridPaymentView_txtcolVoidDt.tagName).toBe("DIV");
    expect(gridPaymentView_txtcolVoidDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePaymentView_gridPaymentView")
      )
    ).toBeInTheDocument;
    const gridPaymentView_txtcolVoidDtdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPaymentView_txtcolVoidDtdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPaymentView"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolVoidDtbtn, { button: 0 });
      });
      const gridPaymentView_txtcolVoidDtadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPaymentView_txtcolVoidDtadd, { button: 0 });
      });
    }
    const txtcolVoidDt = screen.getByTestId("txtcolVoidDt");
    expect(txtcolVoidDt.tagName).toBe("INPUT");
    expect(txtcolVoidDt.type).toBe("text");
    expect(txtcolVoidDt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolVoidDt, "1");
    });
  });
  test("Custom Test Cases for txtcolVoidDt", () => {
    // START_USER_CODE-USER_txtcolVoidDt_TEST
    // END_USER_CODE-USER_txtcolVoidDt_TEST
  });
});
