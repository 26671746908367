/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  setData,
  getData,
  getValue,
  setValue,
  disable,
  enable
} from "../../shared/WindowImports";

import "./LoanRepayProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import { isDate } from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_LoanRepayProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LoanRepayProfile",
    windowName: "LoanRepayProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.LoanRepayProfile",
    // START_USER_CODE-USER_LoanRepayProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Loan Repayment Profile",
      scrCode: "PN0170B",
    },
    // END_USER_CODE-USER_LoanRepayProfile_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateTimeWidget",
      parent: "grpbxVendorWireTransfer",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtInterestRate: {
      name: "txtInterestRate",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransfer",
      Label: "Rate per Ton:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInterestRate_PROPERTIES

      // END_USER_CODE-USER_txtInterestRate_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransfer",
      Label: "Peannut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    grpbxVendorWireTransfer: {
      name: "grpbxVendorWireTransfer",
      type: "GroupBoxWidget",
      parent: "LoanRepayProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxVendorWireTransfer_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorWireTransfer_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "LoanRepayProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    let ParentData = getData(thisObj, "frmloanrepaysetup");
    setData(thisObj, 'ParentData', ParentData);
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  //binding data to the controls while page load
  const formLoad = async () => {
    try {
      setLoading(true);
      disable(thisObj, 'txtPeanutType');
      let ParentData = getData(thisObj, 'ParentData');
      setValue(thisObj, "txtPeanutType", ParentData.lblPeanutTypeCaption);
      if (ParentData.cmdOkCaption == "Update") {
        document.getElementsByClassName("btnAdd")[1].childNodes[0].childNodes[0].innerText = ParentData.cmdOkCaption;
        disable(thisObj, "txtEffectiveDate");
        setValue(thisObj, "txtEffectiveDate", ParentData.txtEffectiveDateTime);
        setValue(thisObj, "txtInterestRate", ParentData.txtRatePerTon);
        setValue(thisObj, "lblAddedByValue", ParentData.lblAddedByCaption);
        setValue(thisObj, "lblChangedByValue", ParentData.lblChangedByCaption);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for Form_Load event"
        );
      }
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }
//To close the popup
  const onbtnCancelClick = () => {
    document.getElementById("SystemMaintenanceSpecialFunctions_LoanRepayProfilePopUp").childNodes[0].click();
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //To add/edit the loan repayments
  const onbtnAddClick = async () => {
    try {
      setLoading(true);
      let response;
      let EffectiveDate = getValue(thisObj, "txtEffectiveDate");
      let rateperton = getValue(thisObj, "txtInterestRate");
      let edate = "";
      if (EffectiveDate != "" && EffectiveDate != undefined) {
        edate = moment(String(EffectiveDate)).format('MM-DD-YYYY HH:mm')
      }
      if (!await bFormValid(EffectiveDate, rateperton)) {
        return false;
      }
      let ParentData = getData(thisObj, 'ParentData');
      let data = {};
      let obj = {};
      if (ParentData.cmdOkCaption == "Add") {
        data = {
          "pnut_type_id": ParentData.lblPeanutTypeTag,
          "effect_date_time": edate,
          "rate_per_ton": Number(rateperton).toFixed(2)
        }
        response = await SystemMaintenanceSpecialFunctionsService.CreateLoanRepayControl("null", data);

      }
      else if (ParentData.cmdOkCaption == "Update") {
        data = {
          "rate_per_ton": Number(rateperton).toFixed(2)
        }
        response = await SystemMaintenanceSpecialFunctionsService.UpdateLoanRepayControl("null", ParentData.lblPeanutTypeTag, edate, data);

      }
      if (response.status == '200') {
        obj = {
          "pnut_type_id": ParentData.lblPeanutTypeTag,
          "lstLoanRepayment": EffectiveDate + "" + rateperton,
          "lstLoanRepaymentID": EffectiveDate
        }
        setData(thisObj, "frmloanrepayprofile", obj);
        setData(thisObj, "frmloanrepaysetuprefresh", "true");
        document.getElementById("SystemMaintenanceSpecialFunctions_LoanRepayProfilePopUp").childNodes[0].click();
      }
      else {
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnAddClick event"
        );
      }
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;
  //validating the controls while add edit loan repayments
  const bFormValid = (EffectiveDate, rateperton) => {
    try {
    let bFormValid = true;

    if (isNaN(new Date(EffectiveDate))) {
      showMessage(thisObj, "Invalid Effective Date Time!!!");
      bFormValid = false;
      return false;
    }
    if (!isNaN(rateperton)) {
      if (rateperton < 0 || rateperton > 9999.99) {
        showMessage(thisObj, "Rate Per Ton must be of format ####.##");
        bFormValid = false;
        return false;
      }
    }
    else {
      showMessage(thisObj, "Rate Per Ton must be of format ####.##");
      bFormValid = false;
      return false;
    }

    return bFormValid;
  }
  catch
  {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for bFormValid event"
      );
    }
  }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LoanRepayProfile*/}

              {/* END_USER_CODE-USER_BEFORE_LoanRepayProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorWireTransfer*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorWireTransfer*/}

              <GroupBoxWidget
                conf={state.grpbxVendorWireTransfer}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtInterestRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtInterestRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInterestRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInterestRate*/}

                {/* END_USER_CODE-USER_AFTER_txtInterestRate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorWireTransfer*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorWireTransfer*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_LoanRepayProfile*/}

              {/* END_USER_CODE-USER_AFTER_LoanRepayProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_LoanRepayProfile);
