/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_BuyingPointTransfer from "./BuyingPointTransfer";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("BuyingPointTransfer Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_BuyingPointTransfer />
      );
    });
  });
  afterEach(cleanup);
  test("is BuyingPointTransfer Loads Successfully", () => {
    expect(screen.getByText("BuyingPointTransfer")).toBeInTheDocument;
  });
  test("Custom Test Cases for BuyingPointTransfer", () => {
    // START_USER_CODE-USER_BuyingPointTransfer_Custom_Test_Case
    // END_USER_CODE-USER_BuyingPointTransfer_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_BuyingPointTransfer />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointTransfer_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointTransfer_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxBuyingPointDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyingPointDetails = screen.getByTestId(
      "grpbxBuyingPointDetails"
    );
    expect(grpbxBuyingPointDetails.tagName).toBe("BUTTON");
    expect(grpbxBuyingPointDetails.type).toBe("button");
    expect(grpbxBuyingPointDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyingPointDetails", () => {
    // START_USER_CODE-USER_grpbxBuyingPointDetails_TEST
    // END_USER_CODE-USER_grpbxBuyingPointDetails_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointTransfer_lblBuyingPoint")
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblBuyingPointValue(Label Widget) Test Cases", async () => {
    const lblBuyingPointValue = screen.getByTestId("lblBuyingPointValue");
    expect(lblBuyingPointValue.tagName).toBe("LABEL");
    expect(lblBuyingPointValue.classList).toContain("form-label");
    expect(lblBuyingPointValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointTransfer_lblBuyingPointValue"
      )
    );
  });
  test("Custom Test Cases for lblBuyingPointValue", () => {
    // START_USER_CODE-USER_lblBuyingPointValue_TEST
    // END_USER_CODE-USER_lblBuyingPointValue_TEST
  });
});
