/* eslint-disable*/
import React from "react";
import ContractManagement_VendorDisclosure from "./VendorDisclosure";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorDisclosure Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_VendorDisclosure />);
    });
  });
  afterEach(cleanup);
  test("is VendorDisclosure Loads Successfully", () => {
    expect(screen.getByText("VendorDisclosure")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorDisclosure", () => {
    // START_USER_CODE-USER_VendorDisclosure_Custom_Test_Case
    // END_USER_CODE-USER_VendorDisclosure_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_VendorDisclosure />);
    });
  });
  afterEach(cleanup);
  test("btnDone(Button Widget) Test Cases", async () => {
    const btnDone = screen.getByTestId("btnDone");
    expect(btnDone).toBeInTheDocument;
    expect(btnDone.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_btnDone")
    );
  });
  test("Custom Test Cases for btnDone", () => {
    // START_USER_CODE-USER_btnDone_TEST
    // END_USER_CODE-USER_btnDone_TEST
  });
  test("btnViewUploadedContract(Button Widget) Test Cases", async () => {
    const btnViewUploadedContract = screen.getByTestId(
      "btnViewUploadedContract"
    );
    expect(btnViewUploadedContract).toBeInTheDocument;
    expect(btnViewUploadedContract.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_btnViewUploadedContract")
    );
  });
  test("Custom Test Cases for btnViewUploadedContract", () => {
    // START_USER_CODE-USER_btnViewUploadedContract_TEST
    // END_USER_CODE-USER_btnViewUploadedContract_TEST
  });
  test("grpbxMinority(GroupBox Widget) Test Cases", async () => {
    const grpbxMinority = screen.getByTestId("grpbxMinority");
    expect(grpbxMinority.tagName).toBe("BUTTON");
    expect(grpbxMinority.type).toBe("button");
    expect(grpbxMinority.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMinority", () => {
    // START_USER_CODE-USER_grpbxMinority_TEST
    // END_USER_CODE-USER_grpbxMinority_TEST
  });
  test("grpbxVeteran(GroupBox Widget) Test Cases", async () => {
    const grpbxVeteran = screen.getByTestId("grpbxVeteran");
    expect(grpbxVeteran.tagName).toBe("BUTTON");
    expect(grpbxVeteran.type).toBe("button");
    expect(grpbxVeteran.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVeteran", () => {
    // START_USER_CODE-USER_grpbxVeteran_TEST
    // END_USER_CODE-USER_grpbxVeteran_TEST
  });
  test("grpbxWmnMnrtVtrn(GroupBox Widget) Test Cases", async () => {
    const grpbxWmnMnrtVtrn = screen.getByTestId("grpbxWmnMnrtVtrn");
    expect(grpbxWmnMnrtVtrn.tagName).toBe("BUTTON");
    expect(grpbxWmnMnrtVtrn.type).toBe("button");
    expect(grpbxWmnMnrtVtrn.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWmnMnrtVtrn", () => {
    // START_USER_CODE-USER_grpbxWmnMnrtVtrn_TEST
    // END_USER_CODE-USER_grpbxWmnMnrtVtrn_TEST
  });
  test("grpbxWomen(GroupBox Widget) Test Cases", async () => {
    const grpbxWomen = screen.getByTestId("grpbxWomen");
    expect(grpbxWomen.tagName).toBe("BUTTON");
    expect(grpbxWomen.type).toBe("button");
    expect(grpbxWomen.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWomen", () => {
    // START_USER_CODE-USER_grpbxWomen_TEST
    // END_USER_CODE-USER_grpbxWomen_TEST
  });
  test("lblMinority(Label Widget) Test Cases", async () => {
    const lblMinority = screen.getByTestId("lblMinority");
    expect(lblMinority.tagName).toBe("LABEL");
    expect(lblMinority.classList).toContain("form-label");
    expect(lblMinority.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblMinority")
    );
  });
  test("Custom Test Cases for lblMinority", () => {
    // START_USER_CODE-USER_lblMinority_TEST
    // END_USER_CODE-USER_lblMinority_TEST
  });
  test("lblMustBeCitizen(Label Widget) Test Cases", async () => {
    const lblMustBeCitizen = screen.getByTestId("lblMustBeCitizen");
    expect(lblMustBeCitizen.tagName).toBe("LABEL");
    expect(lblMustBeCitizen.classList).toContain("form-label");
    expect(lblMustBeCitizen.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblMustBeCitizen")
    );
  });
  test("Custom Test Cases for lblMustBeCitizen", () => {
    // START_USER_CODE-USER_lblMustBeCitizen_TEST
    // END_USER_CODE-USER_lblMustBeCitizen_TEST
  });
  test("lblOMCMinority(Label Widget) Test Cases", async () => {
    const lblOMCMinority = screen.getByTestId("lblOMCMinority");
    expect(lblOMCMinority.tagName).toBe("LABEL");
    expect(lblOMCMinority.classList).toContain("form-label");
    expect(lblOMCMinority.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblOMCMinority")
    );
  });
  test("Custom Test Cases for lblOMCMinority", () => {
    // START_USER_CODE-USER_lblOMCMinority_TEST
    // END_USER_CODE-USER_lblOMCMinority_TEST
  });
  test("lblOMCVeteran(Label Widget) Test Cases", async () => {
    const lblOMCVeteran = screen.getByTestId("lblOMCVeteran");
    expect(lblOMCVeteran.tagName).toBe("LABEL");
    expect(lblOMCVeteran.classList).toContain("form-label");
    expect(lblOMCVeteran.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblOMCVeteran")
    );
  });
  test("Custom Test Cases for lblOMCVeteran", () => {
    // START_USER_CODE-USER_lblOMCVeteran_TEST
    // END_USER_CODE-USER_lblOMCVeteran_TEST
  });
  test("lblOMCWomen(Label Widget) Test Cases", async () => {
    const lblOMCWomen = screen.getByTestId("lblOMCWomen");
    expect(lblOMCWomen.tagName).toBe("LABEL");
    expect(lblOMCWomen.classList).toContain("form-label");
    expect(lblOMCWomen.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblOMCWomen")
    );
  });
  test("Custom Test Cases for lblOMCWomen", () => {
    // START_USER_CODE-USER_lblOMCWomen_TEST
    // END_USER_CODE-USER_lblOMCWomen_TEST
  });
  test("lblVeteran(Label Widget) Test Cases", async () => {
    const lblVeteran = screen.getByTestId("lblVeteran");
    expect(lblVeteran.tagName).toBe("LABEL");
    expect(lblVeteran.classList).toContain("form-label");
    expect(lblVeteran.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblVeteran")
    );
  });
  test("Custom Test Cases for lblVeteran", () => {
    // START_USER_CODE-USER_lblVeteran_TEST
    // END_USER_CODE-USER_lblVeteran_TEST
  });
  test("lblWomen(Label Widget) Test Cases", async () => {
    const lblWomen = screen.getByTestId("lblWomen");
    expect(lblWomen.tagName).toBe("LABEL");
    expect(lblWomen.classList).toContain("form-label");
    expect(lblWomen.textContent).toEqual(
      t("ContractManagement:VendorDisclosure_lblWomen")
    );
  });
  test("Custom Test Cases for lblWomen", () => {
    // START_USER_CODE-USER_lblWomen_TEST
    // END_USER_CODE-USER_lblWomen_TEST
  });
  test("txtWMV(Textbox Widget) Test Cases", async () => {
    const txtWMV = screen.getByTestId("txtWMV");
    expect(txtWMV.tagName).toBe("INPUT");
    expect(txtWMV.type).toBe("text");
    expect(txtWMV.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtWMV, "1");
    });
  });
  test("Custom Test Cases for txtWMV", () => {
    // START_USER_CODE-USER_txtWMV_TEST
    // END_USER_CODE-USER_txtWMV_TEST
  });
});
