/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityMenu from "./SecurityMenu";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityMenu Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityMenu />);
    });
  });
  afterEach(cleanup);
  test("is SecurityMenu Loads Successfully", () => {
    expect(screen.getByText("SecurityMenu")).toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityMenu", () => {
    // START_USER_CODE-USER_SecurityMenu_Custom_Test_Case
    // END_USER_CODE-USER_SecurityMenu_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityMenu />);
    });
  });
  afterEach(cleanup);
  test("btnAddFunction(Button Widget) Test Cases", async () => {
    const btnAddFunction = screen.getByTestId("btnAddFunction");
    expect(btnAddFunction).toBeInTheDocument;
    expect(btnAddFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnAddFunction")
    );
  });
  test("Custom Test Cases for btnAddFunction", () => {
    // START_USER_CODE-USER_btnAddFunction_TEST
    // END_USER_CODE-USER_btnAddFunction_TEST
  });
  test("btnAddGroup(Button Widget) Test Cases", async () => {
    const btnAddGroup = screen.getByTestId("btnAddGroup");
    expect(btnAddGroup).toBeInTheDocument;
    expect(btnAddGroup.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnAddGroup")
    );
  });
  test("Custom Test Cases for btnAddGroup", () => {
    // START_USER_CODE-USER_btnAddGroup_TEST
    // END_USER_CODE-USER_btnAddGroup_TEST
  });
  test("btnAddUser(Button Widget) Test Cases", async () => {
    const btnAddUser = screen.getByTestId("btnAddUser");
    expect(btnAddUser).toBeInTheDocument;
    expect(btnAddUser.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnAddUser")
    );
  });
  test("Custom Test Cases for btnAddUser", () => {
    // START_USER_CODE-USER_btnAddUser_TEST
    // END_USER_CODE-USER_btnAddUser_TEST
  });
  test("btnClear(Button Widget) Test Cases", async () => {
    const btnClear = screen.getByTestId("btnClear");
    expect(btnClear).toBeInTheDocument;
    expect(btnClear.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnClear")
    );
  });
  test("Custom Test Cases for btnClear", () => {
    // START_USER_CODE-USER_btnClear_TEST
    // END_USER_CODE-USER_btnClear_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDeleteFunction(Button Widget) Test Cases", async () => {
    const btnDeleteFunction = screen.getByTestId("btnDeleteFunction");
    expect(btnDeleteFunction).toBeInTheDocument;
    expect(btnDeleteFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnDeleteFunction")
    );
  });
  test("Custom Test Cases for btnDeleteFunction", () => {
    // START_USER_CODE-USER_btnDeleteFunction_TEST
    // END_USER_CODE-USER_btnDeleteFunction_TEST
  });
  test("btnDeleteGroup(Button Widget) Test Cases", async () => {
    const btnDeleteGroup = screen.getByTestId("btnDeleteGroup");
    expect(btnDeleteGroup).toBeInTheDocument;
    expect(btnDeleteGroup.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnDeleteGroup")
    );
  });
  test("Custom Test Cases for btnDeleteGroup", () => {
    // START_USER_CODE-USER_btnDeleteGroup_TEST
    // END_USER_CODE-USER_btnDeleteGroup_TEST
  });
  test("btnDeleteUser(Button Widget) Test Cases", async () => {
    const btnDeleteUser = screen.getByTestId("btnDeleteUser");
    expect(btnDeleteUser).toBeInTheDocument;
    expect(btnDeleteUser.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnDeleteUser")
    );
  });
  test("Custom Test Cases for btnDeleteUser", () => {
    // START_USER_CODE-USER_btnDeleteUser_TEST
    // END_USER_CODE-USER_btnDeleteUser_TEST
  });
  test("btnEditFunction(Button Widget) Test Cases", async () => {
    const btnEditFunction = screen.getByTestId("btnEditFunction");
    expect(btnEditFunction).toBeInTheDocument;
    expect(btnEditFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnEditFunction")
    );
  });
  test("Custom Test Cases for btnEditFunction", () => {
    // START_USER_CODE-USER_btnEditFunction_TEST
    // END_USER_CODE-USER_btnEditFunction_TEST
  });
  test("btnEditGroup(Button Widget) Test Cases", async () => {
    const btnEditGroup = screen.getByTestId("btnEditGroup");
    expect(btnEditGroup).toBeInTheDocument;
    expect(btnEditGroup.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnEditGroup")
    );
  });
  test("Custom Test Cases for btnEditGroup", () => {
    // START_USER_CODE-USER_btnEditGroup_TEST
    // END_USER_CODE-USER_btnEditGroup_TEST
  });
  test("btnEditUser(Button Widget) Test Cases", async () => {
    const btnEditUser = screen.getByTestId("btnEditUser");
    expect(btnEditUser).toBeInTheDocument;
    expect(btnEditUser.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnEditUser")
    );
  });
  test("Custom Test Cases for btnEditUser", () => {
    // START_USER_CODE-USER_btnEditUser_TEST
    // END_USER_CODE-USER_btnEditUser_TEST
  });
  test("btnReports(Button Widget) Test Cases", async () => {
    const btnReports = screen.getByTestId("btnReports");
    expect(btnReports).toBeInTheDocument;
    expect(btnReports.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnReports")
    );
  });
  test("Custom Test Cases for btnReports", () => {
    // START_USER_CODE-USER_btnReports_TEST
    // END_USER_CODE-USER_btnReports_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("grpbxFunctions(GroupBox Widget) Test Cases", async () => {
    const grpbxFunctions = screen.getByTestId("grpbxFunctions");
    expect(grpbxFunctions.tagName).toBe("BUTTON");
    expect(grpbxFunctions.type).toBe("button");
    expect(grpbxFunctions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFunctions", () => {
    // START_USER_CODE-USER_grpbxFunctions_TEST
    // END_USER_CODE-USER_grpbxFunctions_TEST
  });
  test("grpbxGroups(GroupBox Widget) Test Cases", async () => {
    const grpbxGroups = screen.getByTestId("grpbxGroups");
    expect(grpbxGroups.tagName).toBe("BUTTON");
    expect(grpbxGroups.type).toBe("button");
    expect(grpbxGroups.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGroups", () => {
    // START_USER_CODE-USER_grpbxGroups_TEST
    // END_USER_CODE-USER_grpbxGroups_TEST
  });
  test("grpbxSecurityMenu(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityMenu = screen.getByTestId("grpbxSecurityMenu");
    expect(grpbxSecurityMenu.tagName).toBe("BUTTON");
    expect(grpbxSecurityMenu.type).toBe("button");
    expect(grpbxSecurityMenu.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityMenu", () => {
    // START_USER_CODE-USER_grpbxSecurityMenu_TEST
    // END_USER_CODE-USER_grpbxSecurityMenu_TEST
  });
  test("grpbxUsers(GroupBox Widget) Test Cases", async () => {
    const grpbxUsers = screen.getByTestId("grpbxUsers");
    expect(grpbxUsers.tagName).toBe("BUTTON");
    expect(grpbxUsers.type).toBe("button");
    expect(grpbxUsers.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUsers", () => {
    // START_USER_CODE-USER_grpbxUsers_TEST
    // END_USER_CODE-USER_grpbxUsers_TEST
  });
  test("grpbxUserSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxUserSearch = screen.getByTestId("grpbxUserSearch");
    expect(grpbxUserSearch.tagName).toBe("BUTTON");
    expect(grpbxUserSearch.type).toBe("button");
    expect(grpbxUserSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUserSearch", () => {
    // START_USER_CODE-USER_grpbxUserSearch_TEST
    // END_USER_CODE-USER_grpbxUserSearch_TEST
  });
  test("lblFunctions(Label Widget) Test Cases", async () => {
    const lblFunctions = screen.getByTestId("lblFunctions");
    expect(lblFunctions.tagName).toBe("LABEL");
    expect(lblFunctions.classList).toContain("form-label");
    expect(lblFunctions.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_lblFunctions")
    );
  });
  test("Custom Test Cases for lblFunctions", () => {
    // START_USER_CODE-USER_lblFunctions_TEST
    // END_USER_CODE-USER_lblFunctions_TEST
  });
  test("lblGroups(Label Widget) Test Cases", async () => {
    const lblGroups = screen.getByTestId("lblGroups");
    expect(lblGroups.tagName).toBe("LABEL");
    expect(lblGroups.classList).toContain("form-label");
    expect(lblGroups.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_lblGroups")
    );
  });
  test("Custom Test Cases for lblGroups", () => {
    // START_USER_CODE-USER_lblGroups_TEST
    // END_USER_CODE-USER_lblGroups_TEST
  });
  test("lblUsers(Label Widget) Test Cases", async () => {
    const lblUsers = screen.getByTestId("lblUsers");
    expect(lblUsers.tagName).toBe("LABEL");
    expect(lblUsers.classList).toContain("form-label");
    expect(lblUsers.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_lblUsers")
    );
  });
  test("Custom Test Cases for lblUsers", () => {
    // START_USER_CODE-USER_lblUsers_TEST
    // END_USER_CODE-USER_lblUsers_TEST
  });
  test("lblUserSearch(Label Widget) Test Cases", async () => {
    const lblUserSearch = screen.getByTestId("lblUserSearch");
    expect(lblUserSearch.tagName).toBe("LABEL");
    expect(lblUserSearch.classList).toContain("form-label");
    expect(lblUserSearch.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_lblUserSearch")
    );
  });
  test("Custom Test Cases for lblUserSearch", () => {
    // START_USER_CODE-USER_lblUserSearch_TEST
    // END_USER_CODE-USER_lblUserSearch_TEST
  });
  test("txtUserID(Textbox Widget) Test Cases", async () => {
    const txtUserID = screen.getByTestId("txtUserID");
    expect(txtUserID.tagName).toBe("INPUT");
    expect(txtUserID.type).toBe("text");
    expect(txtUserID.classList).toContain("textboxWidgetClass");
    expect(txtUserID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_txtUserID")
    );
    await act(async () => {
      userEvent.type(txtUserID, "1");
    });
  });
  test("Custom Test Cases for txtUserID", () => {
    // START_USER_CODE-USER_txtUserID_TEST
    // END_USER_CODE-USER_txtUserID_TEST
  });
  test("txtUserName(Textbox Widget) Test Cases", async () => {
    const txtUserName = screen.getByTestId("txtUserName");
    expect(txtUserName.tagName).toBe("INPUT");
    expect(txtUserName.type).toBe("text");
    expect(txtUserName.classList).toContain("textboxWidgetClass");
    expect(txtUserName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityMenu_txtUserName")
    );
    await act(async () => {
      userEvent.type(txtUserName, "1");
    });
  });
  test("Custom Test Cases for txtUserName", () => {
    // START_USER_CODE-USER_txtUserName_TEST
    // END_USER_CODE-USER_txtUserName_TEST
  });
});
