/* eslint-disable*/
import React from "react";
import Settlements_PremiumDeductionTrade from "./PremiumDeductionTrade";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionTrade Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductionTrade />);
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionTrade Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionTrade")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionTrade", () => {
    // START_USER_CODE-USER_PremiumDeductionTrade_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionTrade_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductionTrade />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("Settlements:PremiumDeductionTrade_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnVendorSplits(Button Widget) Test Cases", async () => {
    const btnVendorSplits = screen.getByTestId("btnVendorSplits");
    expect(btnVendorSplits).toBeInTheDocument;
    expect(btnVendorSplits.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_btnVendorSplits")
    );
  });
  test("Custom Test Cases for btnVendorSplits", () => {
    // START_USER_CODE-USER_btnVendorSplits_TEST
    // END_USER_CODE-USER_btnVendorSplits_TEST
  });
  test("gridPremDed(Grid Widget) Test Cases", async () => {
    let gridPremDed = screen.getByTestId("gridPremDed");
    let gridPremDedbtn = gridPremDed.nextElementSibling.firstElementChild;
    gridPremDed = gridPremDed.parentElement.parentElement.parentElement;
    expect(gridPremDed.tagName).toBe("DIV");
    expect(gridPremDed.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:PremiumDeductionTrade_gridPremDed")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremDed", () => {
    // START_USER_CODE-USER_gridPremDed_TEST
    // END_USER_CODE-USER_gridPremDed_TEST
  });
  test("grpbxPremDed(GroupBox Widget) Test Cases", async () => {
    const grpbxPremDed = screen.getByTestId("grpbxPremDed");
    expect(grpbxPremDed.tagName).toBe("BUTTON");
    expect(grpbxPremDed.type).toBe("button");
    expect(grpbxPremDed.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremDed", () => {
    // START_USER_CODE-USER_grpbxPremDed_TEST
    // END_USER_CODE-USER_grpbxPremDed_TEST
  });
  test("grpbxPremDedFooter(GroupBox Widget) Test Cases", async () => {
    const grpbxPremDedFooter = screen.getByTestId("grpbxPremDedFooter");
    expect(grpbxPremDedFooter.tagName).toBe("BUTTON");
    expect(grpbxPremDedFooter.type).toBe("button");
    expect(grpbxPremDedFooter.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremDedFooter", () => {
    // START_USER_CODE-USER_grpbxPremDedFooter_TEST
    // END_USER_CODE-USER_grpbxPremDedFooter_TEST
  });
  test("lblIblhttps(Label Widget) Test Cases", async () => {
    const lblIblhttps = screen.getByTestId("lblIblhttps");
    expect(lblIblhttps.tagName).toBe("LABEL");
    expect(lblIblhttps.classList).toContain("form-label");
    expect(lblIblhttps.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_lblIblhttps")
    );
    expect(lblIblhttps.parentElement.parentElement.classList).toContain(
      "d-lg-none"
    );
  });
  test("Custom Test Cases for lblIblhttps", () => {
    // START_USER_CODE-USER_lblIblhttps_TEST
    // END_USER_CODE-USER_lblIblhttps_TEST
  });
  test("lblIblhttpsidt(Label Widget) Test Cases", async () => {
    const lblIblhttpsidt = screen.getByTestId("lblIblhttpsidt");
    expect(lblIblhttpsidt.tagName).toBe("LABEL");
    expect(lblIblhttpsidt.classList).toContain("form-label");
    expect(lblIblhttpsidt.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_lblIblhttpsidt")
    );
  });
  test("Custom Test Cases for lblIblhttpsidt", () => {
    // START_USER_CODE-USER_lblIblhttpsidt_TEST
    // END_USER_CODE-USER_lblIblhttpsidt_TEST
  });
  test("lblpremDed(Label Widget) Test Cases", async () => {
    const lblpremDed = screen.getByTestId("lblpremDed");
    expect(lblpremDed.tagName).toBe("LABEL");
    expect(lblpremDed.classList).toContain("form-label");
    expect(lblpremDed.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_lblpremDed")
    );
  });
  test("Custom Test Cases for lblpremDed", () => {
    // START_USER_CODE-USER_lblpremDed_TEST
    // END_USER_CODE-USER_lblpremDed_TEST
  });
  test("gridPremDed_prem1(Grid Widget) Test Cases", async () => {
    let gridPremDed_prem1 = screen.getByTestId("gridPremDed");
    let gridPremDed_prem1btn =
      gridPremDed_prem1.nextElementSibling.firstElementChild;
    gridPremDed_prem1 =
      gridPremDed_prem1.parentElement.parentElement.parentElement;
    expect(gridPremDed_prem1.tagName).toBe("DIV");
    expect(gridPremDed_prem1.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:PremiumDeductionTrade_gridPremDed")))
      .toBeInTheDocument;
    const gridPremDed_prem1data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPremDed_prem1data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPremDed"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPremDed_prem1btn, { button: 0 });
      });
      const gridPremDed_prem1add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPremDed_prem1add, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for prem1", () => {
    // START_USER_CODE-USER_prem1_TEST
    // END_USER_CODE-USER_prem1_TEST
  });
  test("gridPremDed_txtcolPremDeduct(Grid Widget) Test Cases", async () => {
    let gridPremDed_txtcolPremDeduct = screen.getByTestId("gridPremDed");
    let gridPremDed_txtcolPremDeductbtn =
      gridPremDed_txtcolPremDeduct.nextElementSibling.firstElementChild;
    gridPremDed_txtcolPremDeduct =
      gridPremDed_txtcolPremDeduct.parentElement.parentElement.parentElement;
    expect(gridPremDed_txtcolPremDeduct.tagName).toBe("DIV");
    expect(gridPremDed_txtcolPremDeduct.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:PremiumDeductionTrade_gridPremDed")))
      .toBeInTheDocument;
    const gridPremDed_txtcolPremDeductdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPremDed_txtcolPremDeductdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPremDed"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPremDed_txtcolPremDeductbtn, { button: 0 });
      });
      const gridPremDed_txtcolPremDeductadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPremDed_txtcolPremDeductadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolPremDeduct", () => {
    // START_USER_CODE-USER_txtcolPremDeduct_TEST
    // END_USER_CODE-USER_txtcolPremDeduct_TEST
  });
  test("gridPremDed_txtcolPurchsAmt(Grid Widget) Test Cases", async () => {
    let gridPremDed_txtcolPurchsAmt = screen.getByTestId("gridPremDed");
    let gridPremDed_txtcolPurchsAmtbtn =
      gridPremDed_txtcolPurchsAmt.nextElementSibling.firstElementChild;
    gridPremDed_txtcolPurchsAmt =
      gridPremDed_txtcolPurchsAmt.parentElement.parentElement.parentElement;
    expect(gridPremDed_txtcolPurchsAmt.tagName).toBe("DIV");
    expect(gridPremDed_txtcolPurchsAmt.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:PremiumDeductionTrade_gridPremDed")))
      .toBeInTheDocument;
    const gridPremDed_txtcolPurchsAmtdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPremDed_txtcolPurchsAmtdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPremDed"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPremDed_txtcolPurchsAmtbtn, { button: 0 });
      });
      const gridPremDed_txtcolPurchsAmtadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPremDed_txtcolPurchsAmtadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolPurchsAmt", () => {
    // START_USER_CODE-USER_txtcolPurchsAmt_TEST
    // END_USER_CODE-USER_txtcolPurchsAmt_TEST
  });
  test("gridPremDed_txtcolStrgAmt(Grid Widget) Test Cases", async () => {
    let gridPremDed_txtcolStrgAmt = screen.getByTestId("gridPremDed");
    let gridPremDed_txtcolStrgAmtbtn =
      gridPremDed_txtcolStrgAmt.nextElementSibling.firstElementChild;
    gridPremDed_txtcolStrgAmt =
      gridPremDed_txtcolStrgAmt.parentElement.parentElement.parentElement;
    expect(gridPremDed_txtcolStrgAmt.tagName).toBe("DIV");
    expect(gridPremDed_txtcolStrgAmt.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:PremiumDeductionTrade_gridPremDed")))
      .toBeInTheDocument;
    const gridPremDed_txtcolStrgAmtdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPremDed_txtcolStrgAmtdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPremDed"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPremDed_txtcolStrgAmtbtn, { button: 0 });
      });
      const gridPremDed_txtcolStrgAmtadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPremDed_txtcolStrgAmtadd, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for txtcolStrgAmt", () => {
    // START_USER_CODE-USER_txtcolStrgAmt_TEST
    // END_USER_CODE-USER_txtcolStrgAmt_TEST
  });
  test("txtNetProceeds(Textbox Widget) Test Cases", async () => {
    const txtNetProceeds = screen.getByTestId("txtNetProceeds");
    expect(txtNetProceeds.tagName).toBe("INPUT");
    expect(txtNetProceeds.type).toBe("text");
    expect(txtNetProceeds.classList).toContain("textboxWidgetClass");
    expect(txtNetProceeds.previousElementSibling.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_txtNetProceeds")
    );
    await act(async () => {
      userEvent.type(txtNetProceeds, "1");
    });
  });
  test("Custom Test Cases for txtNetProceeds", () => {
    // START_USER_CODE-USER_txtNetProceeds_TEST
    // END_USER_CODE-USER_txtNetProceeds_TEST
  });
  test("txtSegValue(Textbox Widget) Test Cases", async () => {
    const txtSegValue = screen.getByTestId("txtSegValue");
    expect(txtSegValue.tagName).toBe("INPUT");
    expect(txtSegValue.type).toBe("text");
    expect(txtSegValue.classList).toContain("textboxWidgetClass");
    expect(txtSegValue.previousElementSibling.textContent).toEqual(
      t("Settlements:PremiumDeductionTrade_txtSegValue")
    );
    await act(async () => {
      userEvent.type(txtSegValue, "1");
    });
  });
  test("Custom Test Cases for txtSegValue", () => {
    // START_USER_CODE-USER_txtSegValue_TEST
    // END_USER_CODE-USER_txtSegValue_TEST
  });
});
