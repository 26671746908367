/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  getValue,
  getData,
  setData,
  disable,
  enable,
  goTo
} from "../../shared/WindowImports";

import "./FedWhouseLicenseSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FedWhouseLicenseSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FedWhouseLicenseSetup",
    windowName: "FedWhouseLicenseSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FedWhouseLicenseSetup",
    // START_USER_CODE-USER_FedWhouseLicenseSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Federal License Setup",
      scrCode: "PN0190A",
    },
    // END_USER_CODE-USER_FedWhouseLicenseSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxFedWhouseLicenseSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxFedWhouseLicenseSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxFedWhouseLicenseSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    lblFederalWhouseLicense: {
      name: "lblFederalWhouseLicense",
      type: "LabelWidget",
      parent: "grpbxFedWhouseLicenseSetup",
      Label: "Federal Warehouse License:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFederalWhouseLicense_PROPERTIES

      // END_USER_CODE-USER_lblFederalWhouseLicense_PROPERTIES
    },
    lstFedLic: {
      name: "lstFedLic",
      type: "ListBoxFieldWidget",
      parent: "grpbxFedWhouseLicenseSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFedLic_PROPERTIES

      // END_USER_CODE-USER_lstFedLic_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "FedWhouseLicenseSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    grpbxFedWhouseLicenseSetup: {
      name: "grpbxFedWhouseLicenseSetup",
      type: "GroupBoxWidget",
      parent: "FedWhouseLicenseSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFedWhouseLicenseSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxFedWhouseLicenseSetup_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#FedWhouseLicenseProfile": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#FedWhouseLicenseProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FedWhouseLicenseProfile#DEFAULT#true#Click"]
    // },
    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FedWhouseLicenseProfile#DEFAULT#true#Click"]
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {    
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(()=>{
    let profileData=getData(thisObj,'WhouseLicenseProfileData')
    if(profileData!=null){
      bFillFedLicList()
    }
  },[getData(thisObj,'WhouseLicenseProfileData')])
  // START_USER_CODE-USER_METHODS
  //Implement Form Load functionality
  let FormLoad = async () => {
    setData(thisObj, 'mbSuccessfulLoad', true);
    if (!await bFillFedLicList()) {
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
  }
  // Implement Enable Disable functionality
  const EnableDisableControls = async () => {
    enable(thisObj, 'btnAdd');
    enable(thisObj, 'btnEdit');
    enable(thisObj, 'btnDelete');
    if (!await bFillFedLicList()) {
      disable(thisObj, 'btnEdit');
      disable(thisObj, 'btnDelete');
    }
  }
  // Implement functionality to bind Federal License List
  const bFillFedLicList = async () => {
    setLoading(true)
    let js = [];
    let obj;
    let response = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(null, null);
    let data = response[0].whouse_license_controls
    if (data != null && data.length > 0 && data != undefined) {
      for (let i = 0; i < data.length; i++) {
        obj = {
          key: data[i].fed_whouse_license,
          description: data[i].fed_whouse_license
        }
        js.push(obj)
        obj = {};
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstFedLic: {
            ...state["lstFedLic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'lstFedLic', [js.at(0).key]);
      setLoading(false);
    }
    else {
      EnableDisableControls();
      setLoading(false);
      return false;
    }
    setLoading(false);
    return true;
  }
  // Add button functionality Implementation 
  const onbtnAddClick = () => {
    setLoading(true)
    try {
      let frmFedWhouseLicenseSetup = {}
      frmFedWhouseLicenseSetup.cmdOk_Caption = "Add"
      frmFedWhouseLicenseSetup.frmFedWhouseLicenseSetup_enabled = true
      setData(thisObj, 'FedWhouseLicenseProfile', frmFedWhouseLicenseSetup)
      goTo(thisObj, "SystemMaintenanceMasterManagement#FedWhouseLicenseProfile#DEFAULT#true#Click")
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false);
      } else {
        showMessage(
          thisObj,
          "Error occurred during State Add Button Click"
        );
        setLoading(false);
      }
    }
    setLoading(false);
  }
  thisObj.onbtnAddClick = onbtnAddClick
  // Implement functionality for Delete button
  const onbtnDeleteClick = async () => {    
    try {
      let buy_pt_id = "R29";
      let fed_whouse_license = getValue(thisObj, "lstFedLic")
      if (fed_whouse_license == "") {
        alert("Please select at least one Federal License");
      }
      else {
        const confirmBox = window.confirm(
          "Are you sure you wish to delete this License " + fed_whouse_license + ""
        )
        if (confirmBox == true) {
          setLoading(true);
          let response = await SystemMaintenanceMasterManagementService.RemoveWarehouseLicenseControlDetails(buy_pt_id, fed_whouse_license)
          if (response.status == 200) {
            showMessage(thisObj, response.message, true);
            await bFillFedLicList();
            if (!await bFillFedLicList()) {
              await EnableDisableControls()
              setLoading(false);
              return false;
            }
          }
          else {            
            setLoading(false);
            return false;
          }
        }
        else {
          setLoading(false);
          return false;
        }
      }
      setLoading(false)
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false);
      }
      else {
        showMessage(thisObj, "error on delete button")
      }
      setLoading(false);
      return false;
    }
    return true;
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
  //Implement functionality for Edit button
  const onbtnEditClick = async () => {
    setLoading(true);
    let fed_whouse_license = thisObj.values['lstFedLic'];
    let response = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(fed_whouse_license,null);
    let data = response[0].whouse_license_controls;
    let FedWhouseLicenseProfile = {};
    FedWhouseLicenseProfile.cmdOk_Caption = 'Update';
    FedWhouseLicenseProfile.fed_whouse_license = data[0].fed_whouse_license
    FedWhouseLicenseProfile.fed_whs_lic_name = data[0].fed_whs_lic_name
    FedWhouseLicenseProfile.buy_pt_id = data[0].buy_pt_id
    FedWhouseLicenseProfile.daco_beg_num = data[0].daco_beg_num
    FedWhouseLicenseProfile.daco_end_num = data[0].daco_end_num
    FedWhouseLicenseProfile.daco_next_num = data[0].daco_next_num
    FedWhouseLicenseProfile.whse_rcpt_beg_num = data[0].whse_rcpt_beg_num
    FedWhouseLicenseProfile.whse_rcpt_end_num = data[0].whse_rcpt_end_num
    FedWhouseLicenseProfile.whse_rcpt_next_num = data[0].whse_rcpt_next_num
    FedWhouseLicenseProfile.e_whse_rcpt_beg_num = data[0].e_whse_rcpt_beg_num
    FedWhouseLicenseProfile.e_whse_rcpt_end_num = data[0].e_whse_rcpt_end_num
    FedWhouseLicenseProfile.e_whse_rcpt_next_num = data[0].e_whse_rcpt_next_num
    FedWhouseLicenseProfile.shorage_rate = (data[0].shorage_rate*100).toFixed(2)
    FedWhouseLicenseProfile.shrunk_loadout = data[0].shrunk_loadout, 2
    FedWhouseLicenseProfile.unshrunk_loadout = data[0].unshrunk_loadout, 2
    FedWhouseLicenseProfile.warehouseman_signature = data[0].warehouseman_signature
    FedWhouseLicenseProfile.monthly_storage_rate = data[0].monthly_storage_rate
    if (data[0].storage_paid_thru_date !== null && data[0].storage_paid_thru_date !== undefined) {
      FedWhouseLicenseProfile.storage_paid_thru_date = moment(data[0].storage_paid_thru_date).format("MM/DD/YYYY")
    }

    FedWhouseLicenseProfile.ewr_email_recipient = data[0].ewr_email_recipient
    FedWhouseLicenseProfile.warehouseman_signature = data[0].warehouseman_signature


    FedWhouseLicenseProfile.txtBeg1 = data[0].txtBeg1
    FedWhouseLicenseProfile.txtEnd1 = data[0].txtEnd1
    FedWhouseLicenseProfile.txtNxt1 = data[0].txtNxt1

    FedWhouseLicenseProfile.txtBeg1 = data[0].txtBeg2
    FedWhouseLicenseProfile.txtEnd1 = data[0].txtEnd2
    FedWhouseLicenseProfile.txtNxt1 = data[0].txtNxt2

    FedWhouseLicenseProfile.txtBeg1 = data[0].txtBeg3
    FedWhouseLicenseProfile.txtEnd1 = data[0].txtEnd3
    FedWhouseLicenseProfile.txtNxt1 = data[0].txtNxt3

    FedWhouseLicenseProfile.addUser = data[0].add_user
    FedWhouseLicenseProfile.addTime = data[0].add_date_time
    FedWhouseLicenseProfile.chgUser = data[0].chg_user
    FedWhouseLicenseProfile.chgTime = data[0].chg_date_time
    setData(thisObj, 'FedWhouseLicenseProfile', FedWhouseLicenseProfile)
    goTo(thisObj, "SystemMaintenanceMasterManagement#FedWhouseLicenseProfile#DEFAULT#true#Click")
    setLoading(false);
  }
  thisObj.onbtnEditClick = onbtnEditClick 
  //Implement company click functionality
  // const onFedLicChange = async () => {
  //   await EnableDisableControls();
  // }
  // thisObj.onlstFedLicChange = onFedLicChange;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
             <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FedWhouseLicenseSetup*/}

              {/* END_USER_CODE-USER_BEFORE_FedWhouseLicenseSetup*/}

              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxFedWhouseLicenseSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFedWhouseLicenseSetup*/}

              <GroupBoxWidget
                conf={state.grpbxFedWhouseLicenseSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblFederalWhouseLicense*/}

                {/* END_USER_CODE-USER_BEFORE_lblFederalWhouseLicense*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFederalWhouseLicense}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFederalWhouseLicense*/}

                {/* END_USER_CODE-USER_AFTER_lblFederalWhouseLicense*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstFedLic*/}

                {/* END_USER_CODE-USER_BEFORE_lstFedLic*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstFedLic}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstFedLic*/}

                {/* END_USER_CODE-USER_AFTER_lstFedLic*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFedWhouseLicenseSetup*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              {/* END_USER_CODE-USER_AFTER_grpbxFedWhouseLicenseSetup*/}
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >

                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_FedWhouseLicenseSetup*/}

              {/* END_USER_CODE-USER_AFTER_FedWhouseLicenseSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FedWhouseLicenseSetup
);
