/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  enable,
  disable,
  getData,
  getValue,
  setData,
  goTo,
  hide,
  show,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./DelvAgreeSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService"
import { returnNumericValues } from './../../Common/Constants';
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvAgreeSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvAgreeSearch",
    windowName: "DelvAgreeSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvAgreeSearch",
    // START_USER_CODE-USER_DelvAgreeSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Agreement Search",
      scrCode: "PN1180A",
    },
    // END_USER_CODE-USER_DelvAgreeSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateAgreement: {
      name: "btnCreateAgreement",
      type: "ButtonWidget",
      parent: "grpbxDelvSearch3",
      Label: "Create Agreement",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateAgreement_PROPERTIES

      // END_USER_CODE-USER_btnCreateAgreement_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxDelvSearch3",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxDelvSearch3",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxDelvAgreeSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    col100Adjustment: {
      name: "col100Adjustment",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "100% Adjustment $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100Adjustment_PROPERTIES

      // END_USER_CODE-USER_col100Adjustment_PROPERTIES
    },
    col100AdjustmentLbs: {
      name: "col100AdjustmentLbs",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "100% Adjustment Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100AdjustmentLbs_PROPERTIES

      // END_USER_CODE-USER_col100AdjustmentLbs_PROPERTIES
    },
    col100Applied: {
      name: "col100Applied",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "100% Applied $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100Applied_PROPERTIES

      // END_USER_CODE-USER_col100Applied_PROPERTIES
    },
    col100Open: {
      name: "col100Open",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "100% Open $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100Open_PROPERTIES

      // END_USER_CODE-USER_col100Open_PROPERTIES
    },
    colAdjustmentLbs: {
      name: "colAdjustmentLbs",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Adjustment Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAdjustmentLbs_PROPERTIES

      // END_USER_CODE-USER_colAdjustmentLbs_PROPERTIES
    },
    colAgree: {
      name: "colAgree",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Agree#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgree_PROPERTIES

      // END_USER_CODE-USER_colAgree_PROPERTIES
    },
    colAgreeDate: {
      name: "colAgreeDate",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Agree Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeDate_PROPERTIES

      // END_USER_CODE-USER_colAgreeDate_PROPERTIES
    },
    colAppliedLbs: {
      name: "colAppliedLbs",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Applied Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_colAppliedLbs_PROPERTIES
    },
    colConversion: {
      name: "colConversion",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Conversion%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colConversion_PROPERTIES

      // END_USER_CODE-USER_colConversion_PROPERTIES
    },
    colDelvInOut: {
      name: "colDelvInOut",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Delv In/Out",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvInOut_PROPERTIES

      // END_USER_CODE-USER_colDelvInOut_PROPERTIES
    },
    colLoc: {
      name: "colLoc",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Loc.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoc_PROPERTIES

      // END_USER_CODE-USER_colLoc_PROPERTIES
    },
    colOpenLbs: {
      name: "colOpenLbs",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Open Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenLbs_PROPERTIES

      // END_USER_CODE-USER_colOpenLbs_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPriceMethod: {
      name: "colPriceMethod",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Price Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceMethod_PROPERTIES

      // END_USER_CODE-USER_colPriceMethod_PROPERTIES
    },
    colReceiptLbs: {
      name: "colReceiptLbs",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Receipt Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptLbs_PROPERTIES

      // END_USER_CODE-USER_colReceiptLbs_PROPERTIES
    },
    colReceiptValue: {
      name: "colReceiptValue",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Receipt Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptValue_PROPERTIES

      // END_USER_CODE-USER_colReceiptValue_PROPERTIES
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGen_PROPERTIES

      // END_USER_CODE-USER_colSeedGen_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colTotalAdjustedLbs: {
      name: "colTotalAdjustedLbs",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Total Adjusted Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalAdjustedLbs_PROPERTIES

      // END_USER_CODE-USER_colTotalAdjustedLbs_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridDelvAgreeSearch",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    ddAgreementStatus: {
      name: "ddAgreementStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch",
      Label: "Agreement Status:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreementStatus_PROPERTIES

      // END_USER_CODE-USER_ddAgreementStatus_PROPERTIES
    },
    ddAgreementType: {
      name: "ddAgreementType",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch",
      Label: "Agreement Type:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreementType_PROPERTIES

      // END_USER_CODE-USER_ddAgreementType_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "EdibleOil:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch",
      Label: "Location #:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "Oleic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSeedGeneration: {
      name: "ddSeedGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "Seed Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_ddSeedGeneration_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "Seg #:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    gridDelvAgreeSearch: {
      name: "gridDelvAgreeSearch",
      type: "GridWidget",
      parent: "grpbxDelvSearch3",
      gridCellsOrder:
        "txtcolLoc,txtcolDelvInOut,txtcolAgreeDate,txtcolAgree,txtcolVendor,txtcolType,txtcolVariety,txtcolSeedGen,txtcolSeg,txtcolStatus,txtcolmPriceMethod,txtcolPrice,txtcolConversion,txtcolReceiptValue,txtcol100Applied,txtcol100AdjustmentDoller,txtcol100Open,txtcolReceiptLbs,txtcolAdjustmentlbs,txtcolTotalAdjustedlbs,txtcolAppliedLbs,txtcol100Adjustment,txtcolOpenlb",
      ColSpan: "6",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      expandedRows: [],
      pushExpandedRows: (isExpanded, row) => {
        if (isExpanded) {
          states.gridDelvAgreeSearch.expandedRows.push(row)
        }
        else {
          states.gridDelvAgreeSearch.expandedRows = states.gridDelvAgreeSearch.expandedRows.filter(rows => rows.rowID$ !== row.rowID$)
        }
      },
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: false,
      needGridChange: false,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
          {data.contData ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.contData.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="expandIconSpc"></div>
                    {thisObj.state.gridDelvAgreeSearch.columns[0].Visible ?
                      <div className="extraRowCell_1" key="expandCell1">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[1].Visible ?
                      <div className="extraRowCell_2" key="expandCell2">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[2].Visible ?
                      <div className="extraRowCell_3" key="expandCell3">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[3].Visible ?
                      <div className="extraRowCell_4" key="expandCell4">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[4].Visible ?
                      <div className="extraRowCell_5" key="expandCell5">
                        <label>
                        {elem.vendors}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[5].Visible ?
                      <div className="extraRowCell_6" key="expandCell6">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[6].Visible ?
                      <div className="extraRowCell_7" key="expandCell7">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[7].Visible ?
                      <div className="extraRowCell_8" key="expandCell8">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[8].Visible ?
                      <div className="extraRowCell_9" key="expandCell9">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[9].Visible ?
                      <div className="extraRowCell_10" key="expandCell10">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[10].Visible ?
                      <div className="extraRowCell_11" key="expandCell11">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[11].Visible ?
                      <div className="extraRowCell_12" key="expandCell12">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[12].Visible ?
                      <div className="extraRowCell_13" key="expandCell13">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[13].Visible ?
                      <div className="extraRowCell_14" key="expandCell14">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[14].Visible ?
                      <div className="extraRowCell_15" key="expandCell15">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[15].Visible ?
                      <div className="extraRowCell_16" key="expandCell16">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[16].Visible ?
                      <div className="extraRowCell_17" key="expandCell17">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[17].Visible ?
                      <div className="extraRowCell_18" key="expandCell18">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[18].Visible ?
                      <div className="extraRowCell_19" key="expandCell19">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[19].Visible ?
                      <div className="extraRowCell_20" key="expandCell20">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[20].Visible ?
                      <div className="extraRowCell_21" key="expandCell21">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[21].Visible ?
                      <div className="extraRowCell_22" key="expandCell22">
                      </div> : <></>}
                    {thisObj.state.gridDelvAgreeSearch.columns[22].Visible ?
                      <div className="extraRowCell_23" key="expandCell23">
                      </div> : <></>}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // START_USER_CODE-USER_gridDelvAgreeSearch_PROPERTIES

      // END_USER_CODE-USER_gridDelvAgreeSearch_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxDelvAgreeSearch2",
      Label: "Crop Year",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    txt100AdjustmentLbs: {
      name: "txt100AdjustmentLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSearch3",
      Label: "100% Adjustment lbs",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100AdjustmentLbs_PROPERTIES

      // END_USER_CODE-USER_txt100AdjustmentLbs_PROPERTIES
    },
    txtAdjustmentLbs: {
      name: "txtAdjustmentLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSearch3",
      Label: "Adjustment Lbs",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAdjustmentLbs_PROPERTIES

      // END_USER_CODE-USER_txtAdjustmentLbs_PROPERTIES
    },
    txtAgreementNum: {
      name: "txtAgreementNum",
      type: "TextBoxWidget",
      parent: "grpbxDelvAgreeSearch",
      Label: "Agreement #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreementNum_PROPERTIES

      // END_USER_CODE-USER_txtAgreementNum_PROPERTIES
    },
    txtAppliedLbs: {
      name: "txtAppliedLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSearch3",
      Label: "Applied Lbs",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_txtAppliedLbs_PROPERTIES
    },
    txtcol100Adjustment: {
      name: "txtcol100Adjustment",
      type: "TextBoxWidget",
      colName: "col100AdjustmentLbs",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100Adjustment_PROPERTIES

      // END_USER_CODE-USER_txtcol100Adjustment_PROPERTIES
    },
    txtcol100AdjustmentDoller: {
      name: "txtcol100AdjustmentDoller",
      type: "TextBoxWidget",
      colName: "col100Adjustment",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100AdjustmentDoller_PROPERTIES

      // END_USER_CODE-USER_txtcol100AdjustmentDoller_PROPERTIES
    },
    txtcol100Applied: {
      name: "txtcol100Applied",
      type: "TextBoxWidget",
      colName: "col100Applied",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100Applied_PROPERTIES

      // END_USER_CODE-USER_txtcol100Applied_PROPERTIES
    },
    txtcol100Open: {
      name: "txtcol100Open",
      type: "TextBoxWidget",
      colName: "col100Open",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100Open_PROPERTIES

      // END_USER_CODE-USER_txtcol100Open_PROPERTIES
    },
    txtcolAdjustmentlbs: {
      name: "txtcolAdjustmentlbs",
      type: "TextBoxWidget",
      colName: "colAdjustmentLbs",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAdjustmentlbs_PROPERTIES

      // END_USER_CODE-USER_txtcolAdjustmentlbs_PROPERTIES
    },
    txtcolAgree: {
      name: "txtcolAgree",
      type: "TextBoxWidget",
      colName: "colAgree",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgree_PROPERTIES

      // END_USER_CODE-USER_txtcolAgree_PROPERTIES
    },
    txtcolAgreeDate: {
      name: "txtcolAgreeDate",
      type: "TextBoxWidget",
      colName: "colAgreeDate",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeDate_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeDate_PROPERTIES
    },
    txtcolAppliedLbs: {
      name: "txtcolAppliedLbs",
      type: "TextBoxWidget",
      colName: "colAppliedLbs",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedLbs_PROPERTIES
    },
    txtcolConversion: {
      name: "txtcolConversion",
      type: "TextBoxWidget",
      colName: "colConversion",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolConversion_PROPERTIES

      // END_USER_CODE-USER_txtcolConversion_PROPERTIES
    },
    txtcolDelvInOut: {
      name: "txtcolDelvInOut",
      type: "TextBoxWidget",
      colName: "colDelvInOut",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvInOut_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvInOut_PROPERTIES
    },
    txtcolLoc: {
      name: "txtcolLoc",
      type: "TextBoxWidget",
      colName: "colLoc",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolLoc_PROPERTIES
    },
    txtcolOpenlb: {
      name: "txtcolOpenlb",
      type: "TextBoxWidget",
      colName: "colOpenLbs",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenlb_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenlb_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolmPriceMethod: {
      name: "txtcolmPriceMethod",
      type: "TextBoxWidget",
      colName: "colPriceMethod",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceMethod_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceMethod_PROPERTIES
    },
    txtcolReceiptLbs: {
      name: "txtcolReceiptLbs",
      type: "TextBoxWidget",
      colName: "colReceiptLbs",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolReceiptLbs_PROPERTIES
    },
    txtcolReceiptValue: {
      name: "txtcolReceiptValue",
      type: "TextBoxWidget",
      colName: "colReceiptValue",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptValue_PROPERTIES

      // END_USER_CODE-USER_txtcolReceiptValue_PROPERTIES
    },
    txtcolSeedGen: {
      name: "txtcolSeedGen",
      type: "TextBoxWidget",
      colName: "colSeedGen",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedGen_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedGen_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolTotalAdjustedlbs: {
      name: "txtcolTotalAdjustedlbs",
      type: "TextBoxWidget",
      colName: "colTotalAdjustedLbs",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalAdjustedlbs_PROPERTIES

      // END_USER_CODE-USER_txtcolTotalAdjustedlbs_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES

      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridDelvAgreeSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtOpenLbs: {
      name: "txtOpenLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSearch3",
      Label: "Open Lbs",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenLbs_PROPERTIES

      // END_USER_CODE-USER_txtOpenLbs_PROPERTIES
    },
    txtReceiptLbs: {
      name: "txtReceiptLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSearch3",
      Label: "Receipt Lbs",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptLbs_PROPERTIES

      // END_USER_CODE-USER_txtReceiptLbs_PROPERTIES
    },
    txtTotalAdjustedLbs: {
      name: "txtTotalAdjustedLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSearch3",
      Label: "Total Adjusted Lbs",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAdjustedLbs_PROPERTIES

      // END_USER_CODE-USER_txtTotalAdjustedLbs_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDelvAgreeSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxDelvAgreeSearch",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxDelvAgreeSrch: {
      name: "grpbxDelvAgreeSrch",
      type: "GroupBoxWidget",
      parent: "DelvAgreeSearch",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDelvAgreeSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvAgreeSearch_PROPERTIES
    },
    grpbxDelvAgreeSearch: {
      name: "grpbxDelvAgreeSearch",
      type: "GroupBoxWidget",
      parent: "DelvAgreeSearch",
      ColSpan: "1",
      ColSpanForLargeDesktop: "1",
      ColSpanForTabLandscape: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      ColSpanForTabPotrait: "1",
      HasLabel: false,
      ColSpanForMobile: "1",
      // START_USER_CODE-USER_grpbxDelvAgreeSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvAgreeSearch_PROPERTIES
    },
    grpbxDelvAgreeSearch2: {
      name: "grpbxDelvAgreeSearch2",
      type: "GroupBoxWidget",
      parent: "DelvAgreeSearch",
      ColSpan: "2",
      ColSpanForLargeDesktop: "1",
      ColSpanForTabLandscape: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColSpanForTabPotrait: "1",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColSpanForMobile: "1",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDelvAgreeSearch2_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvAgreeSearch2_PROPERTIES
    },
    grpbxDelvSearch3: {
      name: "grpbxDelvSearch3",
      type: "GroupBoxWidget",
      parent: "DelvAgreeSearch",
      Label: "Seg#",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxDelvSearch3_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSearch3_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DelvAgreeSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
        "WarehouseReceipts#DelvAgreeProfile": {}
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
        "WarehouseReceipts#DelvAgreeProfile": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnCreateAgreement: {
    //   DEFAULT: ["WarehouseReceipts#DelvAgreeProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  let lbl_firstload
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    setValue(thisObj, "lblCropYear", cropyear)
    setValue(thisObj, "ddLocation", '')
    setValue(thisObj, "ddAgreementType", '')
    setValue(thisObj, "ddAgreementStatus", '')
    setValue(thisObj, "ddPeanutType", '')
    setValue(thisObj, "ddPeanutVariety", '')
    setValue(thisObj, "ddSeedGeneration", '')
    setValue(thisObj, "ddSeg", '')
    setValue(thisObj, "ddEdibleOil", '')
    setValue(thisObj, "ddOleic", '')

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let openVendorClick = getData(thisObj,"openVendorClick")
    if(openVendorClick == true){
      setVendorNameBasedOnVendorNumber()
    }
    setData(thisObj,"openVendorClick",false)
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {// For Child screen Integration with Del Agree Profile.
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    lbl_firstload = getData(thisObj,"lbl_firstload")
    if(lbl_firstload != "T" && lbl_firstload != null && lbl_firstload != undefined){
      Form_Activate()
      setData(thisObj,"lbl_firstload",null)
      }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'lbl_firstload')]);

  const [sortedData, setSortedData] = useState();

  // START_USER_CODE-USER_METHODS
  function FormatNumber(value, fraction = 0) {//To format Number
    try{
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on FormatNumber: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  function ConvertToPascalCase(word) {//converting Name in Pascal Case
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  function isNumeric(n) {//Make Numeric Value
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const ontxtAgreementNumChange = () => {//ASCII Method for Agreement Number
    setValue(thisObj, "txtAgreementNum", returnNumericValues(txtAgreementNum.value))
  };
  thisObj.ontxtAgreementNumChange = ontxtAgreementNumChange;

  function FormLoad() {//Form Load Method
    bFillLocationList()
    bFillAgreementTypeList()
    bFillStatusList()
    bFillPeanutTypeList()
    bFillPeanutVarietyList(null, null);
    bFillGenerationList()
    bFillSegmentList()
    bFillOleicList()
    bFillEdibleOilList()
    setValue(thisObj, "txtVendorNum", '')
    setValue(thisObj, "txtVndr", '')
    hide(thisObj, "txtVndr")
    disable(thisObj,"btnOpen")
    setData(thisObj,"lbl_firstload","T")
    setLoading(false);
  }

  const Form_Activate = async () => {
    if(lbl_firstload != "T"){
      if(lbl_firstload == "D"){
        DoSearch()
      }
      else{
        DoSearch()
      }
    }
  }

  const bFillLocationList = async () => {//Location Dropdown
    try{
    let js = []
    js.push({ key: '', description: '>>> All Buying Points <<<' });
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1180', null, null, null, null)
    for (var i = 0; i < response.length; i++) {
      let obj = { key: response[i].buy_pt_id, description: response[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(response[i].buy_pt_name.trim()) }
      js.push(obj)
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddLocation: {
          ...state["ddLocation"],
          valueList: js
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillLocationList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const bFillAgreementTypeList = () => {//AgreementType Dropdown
    try{
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' })
    js.push({ key: 'P', description: 'Delv In' })
    js.push({ key: 'S', description: 'Delv Out' })
    thisObj.setState(current => {
      return {
        ...current,
        ddAgreementType: {
          ...state["ddAgreementType"],
          valueList: js
        }
      }
    })
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on bFillAgreementTypeList: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const bFillStatusList = () => {//Status DropDown
    try{
    let js = [];
    js.push({ key: '', description: '>>> All Status <<<' })
    js.push({ key: 'C', description: 'Complete' })
    js.push({ key: 'O', description: 'Open' })
    thisObj.setState(current => {
      return {
        ...current,
        ddAgreementStatus: {
          ...state["ddAgreementStatus"],
          valueList: js
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillStatusList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const bFillPeanutTypeList = async () => {//PeanutType Dropdown
    try{
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
    for (var i = 0; i < response.length; i++) {
      let obj = { key: response[i].pnutTypeId, description: response[i].pnutTypeName }
      js.push(obj)

    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutType: {
          ...state["ddPeanutType"],
          valueList: js,
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillPeanutTypeList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const bFillPeanutVarietyList = async (buyingPointId, pnut_type_id) => {//Peanut Variety Dropdown
    try{
    let js = []
    let obj = { key: '', description: ">>> All Varieties <<<" }
    js.push(obj)

    if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      let resp = await ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
      if (resp !== null && resp !== undefined) {
        let js1 = [];
        for (var i = 0; i < resp.length; i++) {
          if (!(js1.includes(resp[i].pnut_variety_id))) {
            obj = { key: resp[i].pnut_variety_id, description: resp[i].pnut_variety_name }
            if (resp[i].symbol_ind == "TRADEMARK") {
              obj = { key: resp[i].pnut_variety_id, description: resp[i].pnut_variety_name + 'ᵀᴹ' }
            }
            if (resp[i].symbol_ind == "REGISTERED TRADEMARK") {
              obj = { key: resp[i].pnut_variety_id, description: resp[i].pnut_variety_name + '®' }
            }
            if (resp[i].symbol_ind == "COPYRIGHT") {
              obj = { key: resp[i].pnut_variety_id, description: resp[i].pnut_variety_name + '©' }
            }
            js.push(obj);
            js1.push(resp[i].pnut_variety_id)
          }

        }
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    });
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillPeanutVarietyList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const onPeanutTypeChange = () => {//PeanutType Change event
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on onPeanutTypeChange: ${error?.toString()}`
        );
      }
      return false;
    }
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  const onddLocationChange = () => {//location change event
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety and  based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on onddLocationChange: ${error?.toString()}`
        );
      }
      return false;
    }
  };
  thisObj.onddLocationChange = onddLocationChange;

  const bFillGenerationList = () => {//Generation dropdown
    try{
    let js = [];
    js.push({ key: '', description: '>>> All Generations <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGeneration: {
          ...state["ddSeedGeneration"],
          valueList: js
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillGenerationList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const bFillSegmentList = () => {//seg DropDown
    try{
    let js = [];
    js.push({ key: '', description: '>>> All Segs <<<' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillSegmentList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const bFillOleicList = () => {//oleicList Dropdown
    try{
    let js = [];
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillOleicList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const bFillEdibleOilList = () => {//bFillEdibleOilList
    try{
    let js = [];
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js
        }
      }
    })
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred on bFillEdibleOilList: ${error?.toString()}`
      );
    }
    return false;
  }
  }

  const ontxtVendorNumChange = () => {//Vendor Textbox change
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
  let vendorNumber = txtVendorNum.value.toUpperCase();  
    if (vendorNumber.length == 6) {
      setVendorNameBasedOnVendorNumber()
    } 
    else {
      setValue(thisObj, "txtVndr", '');
      hide(thisObj, "txtVndr");
    }
  };
  thisObj.ontxtVendorNumChange = ontxtVendorNumChange;

  const onbtnVendorClick = async () => {//Btn Search Click
    try {
      setData(thisObj,"openVendorClick",true)
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on onbtnVendorClick: ${error?.toString()}`
        );
      }
      return false;
    }
  };
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const setVendorNameBasedOnVendorNumber = () => {//Function for vendor
    let isVendorLookup = false;
    let vendor_lookup = getData(thisObj, 'vendorDetails');
    let vendorNumber, vendorName;
    if (vendor_lookup !== null) {
      vendorNumber = vendor_lookup.VendorNumber;
      vendorName = vendor_lookup.vendorName;
      setValue(thisObj, "txtVndr", vendorName);
      setValue(thisObj, "txtVendorNum", vendorNumber);
      isVendorLookup = true;
      setData(thisObj, 'vendorDetails', null);
      show(thisObj, 'txtVndr')
      return;
    }

    let vendorValueFromTextBox = getValue(thisObj, 'txtVendorNum');
    if (vendorValueFromTextBox !== undefined && isVendorLookup == false) {
      vendorNumber = vendorValueFromTextBox;
    }

    if (vendorNumber != "" && vendorNumber != undefined && isVendorLookup == false) {
      ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
        let data = response;
        if (response !== undefined && response.length > 0) {
          vendorName = data[0].name;
        
        setValue(thisObj, "txtVndr", vendorName);
        setValue(thisObj, "txtVendorNum", vendorNumber);
        show(thisObj,"txtVndr")
        }
      })
    }
  }

  const onbtnSearchClick = async () => {//Btn Search Click
    try {
      await DoSearch()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on onbtnSearchClick: ${error?.toString()}`
        );
      }
      return false;
    }
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const DoSearch = async () => {//Do search Functionality
    try{
    setLoading(true)
    let Delv_In_Out_Status = ''
    let LstrXMLAppl = ''
    let dbl100ApplDollar = 0
    let dblNetWt
    let dblBGA
    let totalAdjustment = 0
    let colAdjustment = 0
    let Adjustment = 0
    let dblTotalReceipt = 0
    let dblTotalAdjustment = 0
    let dblTotalTotalAdjust = 0
    let dblTotalApply = 0
    let dblTotal100Adjustment = 0
    let dblTotalOpen = 0

    let LstrXMLVendors = ''
    let LstrXMLVendorNames = ''

    let split_vendor = txtVendorNum.value
    let buy_pt_id = thisObj.values['ddLocation']
    let agree_num = txtAgreementNum.value.trim()
    let agree_type = thisObj.values['ddAgreementType']
    let pnut_type_id = thisObj.values['ddPeanutType']
    let agree_status = thisObj.values['ddAgreementStatus']
    let pnut_variety_id = thisObj.values['ddPeanutVariety']
    let seed_gen = thisObj.values['ddSeedGeneration']
    let seg_type = thisObj.values['ddSeg']
    let edible_oil = thisObj.values['ddEdibleOil']
    let delv_ind = 'Y'
    let oleic_ind = thisObj.values['ddOleic']

    let lstrxml = await SettlementService.RetrieveTradeAgreementDetails('PN1180', null, buy_pt_id, agree_num, agree_type, agree_status, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, edible_oil, delv_ind, split_vendor)
    let TransformedRowsArray = [];
    setValue(thisObj, "gridDelvAgreeSearch", []);
    if (lstrxml.length === 0) {
      setValue(thisObj, "gridDelvAgreeSearch", TransformedRowsArray);
      setValue(thisObj, 'txtReceiptLbs', "0")
      setValue(thisObj, 'txtAdjustmentLbs', "0");
      setValue(thisObj, 'txtTotalAdjustedLbs', "0");
      setValue(thisObj, 'txtAppliedLbs', "0");
      setValue(thisObj, 'txt100AdjustmentLbs', "0");
      setValue(thisObj, 'txtOpenLbs', "0");
      setData(thisObj, "gridDelvAgreeSearch", '')
      setSortedData();
      disable(thisObj,"btnOpen")
      setLoading(false);
      return;
    }
    else {
      let js = []
      for (var i = 0; i < lstrxml.length; i++) {
        let contData = []
        let obj = {}
        let arrVendors = [], arrVendorsName = []
        obj.txtcolLoc = lstrxml[i].buy_pt_id
        Delv_In_Out_Status = lstrxml[i].purch_sale_ind
        switch (Delv_In_Out_Status) {
          case "P":
            obj.txtcolDelvInOut = "IN";
            break;
          case "S":
            obj.txtcolDelvInOut = "OUT";
            break;
        }
        var agreeDate = lstrxml[i].agree_date_time
        agreeDate = agreeDate.split(' ')[0];
        obj.txtcolAgreeDate = agreeDate
        obj.txtcolAgree = lstrxml[i].agreement_num
        obj.txtcolType = lstrxml[i].pnut_type_name
        let pnutVarietyName = lstrxml[i].pnut_variety_name
        switch (lstrxml[i].symbol_ind.toUpperCase()) {
          case "TRADEMARK":
            obj.txtcolVariety = pnutVarietyName + " \u2122";
            break;
          case "REGISTERED TRADEMARK":
            obj.txtcolVariety = pnutVarietyName + " \xAE";
            break;
          case "COPYRIGHT":
            obj.txtcolVariety = pnutVarietyName + " \xA9";
            break;
          default:
            obj.txtcolVariety = pnutVarietyName
        }
        obj.txtcolSeedGen = lstrxml[i].seed_gen
        obj.txtcolSeg = lstrxml[i].seg_type
        let agreeStatus = lstrxml[i].agree_status
        if (agreeStatus.toUpperCase() == 'C') {
          obj.txtcolStatus = 'Complete'
        }
        else {
          obj.txtcolStatus = 'Open'
        }
        let firmBasisOpt = lstrxml[i].firm_basis_opt
        if (firmBasisOpt.toUpperCase() == 'F') {
          obj.txtcolmPriceMethod = 'FIRM'
        }
        else {
          obj.txtcolmPriceMethod = 'BASIS'
        }
        obj.txtcolPrice = lstrxml[i].price_per_ton
        obj.txtcolConversion = lstrxml[i].conv_pct
        obj.txtcolReceiptValue = lstrxml[i].rcpt_value

        LstrXMLAppl = ''
        dbl100ApplDollar = 0

        obj.txtcol100Applied = lstrxml[i].delv_app_dol
        if (lstrxml[i].delv_unapp_dol === '') {
          obj.txtcol100AdjustmentDoller = '0.00'
        }
        else {
          obj.txtcol100AdjustmentDoller = lstrxml[i].delv_unapp_dol
        }
        let openDollar = (lstrxml[i].rcpt_value) - (lstrxml[i].delv_app_dol) - (lstrxml[i].delv_unapp_dol)
        obj.txtcol100Open = Number(openDollar)?.toFixed(2)//FormatNumber(openDollar, 2)
        obj.txtcolReceiptLbs = lstrxml[i].agreed_lbs
        obj.txtcolAdjustmentlbs = lstrxml[i].adjust_lbs.split('.')[0]
        totalAdjustment = Number(lstrxml[i].agreed_lbs) + Number(lstrxml[i].adjust_lbs)

        obj.txtcolTotalAdjustedlbs = totalAdjustment
        obj.txtcolAppliedLbs = lstrxml[i].delivered_lbs

        dblNetWt = 0
        dblBGA = 0

        if (lstrxml[i].delv_unapp_dol != "0.00" && lstrxml[i].delv_unapp_dol != "" && lstrxml[i].delv_unapp_dol != "N") {
          agree_num = lstrxml[i].agreement_num
          LstrXMLAppl = await SettlementService.RetrieveTradeAgreementApplicationDetails(agree_num, 'Y', buy_pt_id)
          if (LstrXMLAppl.length != 0) {
            for (var j = 0; j < LstrXMLAppl.length; j++) {
              if (LstrXMLAppl[j].unapp_dol_value > 0) {
                dblNetWt = LstrXMLAppl[j].net_wt
                dblBGA = LstrXMLAppl[j].basis_grade_amt
              }
            }
          }

          colAdjustment = (Number(dblNetWt) * Number(lstrxml[i].delv_unapp_dol)) / Number(dblBGA)
          obj.txtcol100Adjustment = colAdjustment.toFixed(0)

        }
        else {
          colAdjustment = "0"
          obj.txtcol100Adjustment = colAdjustment
        }

        Adjustment = Number(totalAdjustment) - Number(lstrxml[i].delivered_lbs) - Number(colAdjustment)
        obj.txtcolOpenlb = Adjustment.toFixed(0)
        dblTotalReceipt += parseFloat(isNumeric(lstrxml[i].agreed_lbs) ? lstrxml[i].agreed_lbs : 0);
        dblTotalAdjustment += parseFloat(isNumeric(lstrxml[i].adjust_lbs) ? lstrxml[i].adjust_lbs : 0);
        dblTotalTotalAdjust += parseFloat(isNumeric(Number(lstrxml[i].agreed_lbs) + Number(lstrxml[i].adjust_lbs)) ? Number(lstrxml[i].agreed_lbs) + Number(lstrxml[i].adjust_lbs) : 0);
        dblTotalApply += parseFloat(isNumeric(lstrxml[i].delivered_lbs) ? lstrxml[i].delivered_lbs : 0);
        dblTotal100Adjustment += parseFloat(isNumeric(colAdjustment) ? colAdjustment : 0);
        dblTotalOpen += parseFloat(isNumeric(Adjustment) ? Adjustment : 0);

        LstrXMLVendors = lstrxml[i].split_vendor.replace(".", "");
        LstrXMLVendorNames = lstrxml[i].vendor_name.replace(".", "");

        if (LstrXMLVendors.indexOf('|') == -1) {
          obj.txtcolVendor = LstrXMLVendors + " - " + LstrXMLVendorNames
        }
        else {
          arrVendors = LstrXMLVendors.split('|');
          arrVendorsName = LstrXMLVendorNames.split('|');
          if (arrVendors.length > 0) {

            for (let k = 0; k < arrVendors.length; k++) {
              let objCont = {}
              objCont.vendors = arrVendors[k] + " - " + arrVendorsName[k]
              contData.push(objCont);
            }
            obj.txtcolVendor = "Multi ...";
            obj.contData = contData

          }
        }
        js.push(obj)
      }
      setValue(thisObj, "gridDelvAgreeSearch", js)
      enable(thisObj,"btnOpen")
      setData(thisObj, "gridDelvAgreeSearch", js)
      setValue(thisObj, 'txtReceiptLbs', FormatNumber(dblTotalReceipt));
      setValue(thisObj, 'txtAdjustmentLbs', FormatNumber(dblTotalAdjustment));
      setValue(thisObj, 'txtTotalAdjustedLbs', FormatNumber(dblTotalTotalAdjust));
      setValue(thisObj, 'txtAppliedLbs', FormatNumber(dblTotalApply));
      setValue(thisObj, 'txt100AdjustmentLbs', FormatNumber(dblTotal100Adjustment));
      setValue(thisObj, 'txtOpenLbs', FormatNumber(dblTotalOpen));
      
      setData(thisObj, 'textReceiptLbs',dblTotalReceipt)
      setData(thisObj, 'textAdjustmentLbs',dblTotalAdjustment)
      setData(thisObj, 'textTotalAdjustedLbs',dblTotalTotalAdjust)
      setData(thisObj, 'textAppliedLbs',dblTotalApply)
      setData(thisObj, 'text100AdjustmentLbs',dblTotal100Adjustment)
      setData(thisObj, 'textOpenLbs',dblTotalOpen)

      setSortedData(js);
      setLoading(false);
    }
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on DoSearch: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const onbtnPrintClick = () => {//Print btn Functionality
    try {
      var secondaryRows = thisObj.state.gridDelvAgreeSearch.expandedRows;
      let datatable = getData(thisObj, "gridDelvAgreeSearch");
      if (datatable != undefined && datatable != null && datatable != "") {
        let data = []
        let data2 = []
        let headerarray = []
        let headerarray1 = []
        let headerarray2 = []

        if (thisObj.state.gridDelvAgreeSearch.columns[0].Visible) {
          headerarray.push("Loc.")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[1].Visible) {
          headerarray.push("Delv In/Out")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[2].Visible) {
          headerarray.push("Agree Date")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[3].Visible) {
          headerarray.push("Agree#")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[4].Visible) {
          headerarray.push("Vendor")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[5].Visible) {
          headerarray.push("Type")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[6].Visible) {
          headerarray.push("Variety")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[7].Visible) {
          headerarray.push("Seed Gen")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[8].Visible) {
          headerarray.push("Seg")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[9].Visible) {
          headerarray.push("Status")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[10].Visible) {
          headerarray.push("Price Method")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[11].Visible) {
          headerarray.push("Price")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[12].Visible) {
          headerarray.push("Conversion%")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[13].Visible) {
          headerarray.push("Receipt Value")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[14].Visible) {
          headerarray.push("100% Applied $")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[15].Visible) {
          headerarray.push("100% Adjustment $")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[16].Visible) {
          headerarray.push("100% Open $")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[17].Visible) {
          headerarray.push("Receipt Lbs")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[18].Visible) {
          headerarray.push("Adjustment Lbs")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[19].Visible) {
          headerarray.push("Total Adjusted Lbs")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[20].Visible) {
          headerarray.push("Applied Lbs")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[21].Visible) {
          headerarray.push("100% Adjustment Lbs")
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[22].Visible) {
          headerarray.push("Open Lbs")
        }
        if (headerarray.length > 16) {
          headerarray1 = headerarray.slice(0, 16)
          headerarray2 = headerarray.slice(16, 23)
        }
        else {
          headerarray1 = headerarray.slice(0, 16)
        }

        let count = 0;
        for (var i = 0; i < datatable.length; i++) {
          let bodyarray = []
          let childArray1 = []
          let childArray2 = []

          if (thisObj.state.gridDelvAgreeSearch.columns[0].Visible) {
            bodyarray.push(datatable[i].txtcolLoc)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[1].Visible) {
            bodyarray.push(datatable[i].txtcolDelvInOut)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[2].Visible) {
            bodyarray.push(datatable[i].txtcolAgreeDate)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[3].Visible) {
            bodyarray.push(datatable[i].txtcolAgree)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[4].Visible) {
            bodyarray.push(datatable[i].txtcolVendor)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[5].Visible) {
            bodyarray.push(datatable[i].txtcolType)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[6].Visible) {
            bodyarray.push(datatable[i].txtcolVariety)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[7].Visible) {
            bodyarray.push(datatable[i].txtcolSeedGen)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[8].Visible) {
            bodyarray.push(datatable[i].txtcolSeg)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[9].Visible) {
            bodyarray.push(datatable[i].txtcolStatus)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[10].Visible) {
            bodyarray.push(datatable[i].txtcolmPriceMethod)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[11].Visible) {
            bodyarray.push(datatable[i].txtcolPrice)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[12].Visible) {
            bodyarray.push(datatable[i].txtcolConversion)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[13].Visible) {
            bodyarray.push(datatable[i].txtcolReceiptValue)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[14].Visible) {
            bodyarray.push(datatable[i].txtcol100Applied)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[15].Visible) {
            bodyarray.push(datatable[i].txtcol100AdjustmentDoller)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[16].Visible) {
            bodyarray.push(datatable[i].txtcol100Open)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[17].Visible) {
            bodyarray.push(datatable[i].txtcolReceiptLbs)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[18].Visible) {
            bodyarray.push(datatable[i].txtcolAdjustmentlbs)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[19].Visible) {
            bodyarray.push(datatable[i].txtcolTotalAdjustedlbs)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[20].Visible) {
            bodyarray.push(datatable[i].txtcolAppliedLbs)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[21].Visible) {
            bodyarray.push(datatable[i].txtcol100Adjustment)
          }
          if (thisObj.state.gridDelvAgreeSearch.columns[22].Visible) {
            bodyarray.push(datatable[i].txtcolOpenlb)
          }

          if (secondaryRows.length > 0) {
            for (let k = 0; k < secondaryRows.length; k++) {
              if (secondaryRows[k].rowID$ == i) {
                if (secondaryRows[k].contData != undefined) {
                  for (let j = 0; j < secondaryRows[k].contData.length; j++) {
                    if (thisObj.state.gridDelvAgreeSearch.columns[4].Visible) {
                      let extendedDataVendorjs = []
                      if (thisObj.state.gridDelvAgreeSearch.columns[0].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[1].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[2].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[3].Visible) {
                        extendedDataVendorjs.push("")
                      }

                      if (thisObj.state.gridDelvAgreeSearch.columns[4].Visible) {
                        extendedDataVendorjs.push(secondaryRows[k].contData[j].vendors)
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[5].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[6].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[7].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[8].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[9].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[10].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[11].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[12].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[13].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[14].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[15].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[16].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[17].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[18].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[19].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[20].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[21].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (thisObj.state.gridDelvAgreeSearch.columns[22].Visible) {
                        extendedDataVendorjs.push("")
                      }
                      if (extendedDataVendorjs.length > 16) {
                        childArray1.push(extendedDataVendorjs.slice(0, 16))
                        childArray2.push(extendedDataVendorjs.slice(16, 23))
                      }
                    }
                  }
                }
              }
            }
          }
          if (bodyarray.length > 16) {
            data.push(bodyarray.slice(0, 16))
            for (let x = 0; x < childArray1.length; x++) {
              data.push(childArray1[x])
            }
            data2.push(bodyarray.slice(16, 23))
            for (let y = 0; y < childArray2.length; y++) {
              data2.push(childArray2[y])
            }
          }
          else {
            data.push(bodyarray.slice(0, 16))
            for (let x = 0; x < childArray1.length; x++) {
              data.push(childArray1[x])
            }
          }
        }
        // for Bellow Total Part which as old but due to hide show its not implimnet properly
        data2.push([
          "Total", getValue(thisObj, "txtReceiptLbs"), getValue(thisObj, "txtAdjustmentLbs"), getValue(thisObj, "txtTotalAdjustedLbs"), getValue(thisObj, "txtAppliedLbs"), getValue(thisObj, "txt100AdjustmentLbs"), getValue(thisObj, "txtOpenLbs")
        ])

        let obj = new jsPDF({
          orientation: 'landscape',
        });;
        let n = 0
        let incrementValue = 25

          //index of columns which requires right alignments
          let arrayAligneColumn = []
          arrayAligneColumn.push(headerarray1.indexOf("Price"))
          arrayAligneColumn.push(headerarray1.indexOf("Conversion%"))
          arrayAligneColumn.push(headerarray1.indexOf("Receipt Value"))
          arrayAligneColumn.push(headerarray1.indexOf("100% Applied $"))
          arrayAligneColumn.push(headerarray1.indexOf("100% Adjustment $"))
          arrayAligneColumn.push(headerarray1.indexOf("100% Open $"))
          arrayAligneColumn.push(headerarray1.indexOf("Receipt Lbs"))
          arrayAligneColumn.push(headerarray1.indexOf("Adjustment Lbs"))
          arrayAligneColumn.push(headerarray1.indexOf("Total Adjusted Lbs"))
          arrayAligneColumn.push(headerarray1.indexOf("Applied Lbs"))
          arrayAligneColumn.push(headerarray1.indexOf("100% Adjustment Lbs"))
          arrayAligneColumn.push(headerarray1.indexOf("Open Lbs"))

          let arrayallignedcolumn2 = []
          arrayallignedcolumn2.push(headerarray2.indexOf("100% Open $"))
          arrayallignedcolumn2.push(headerarray2.indexOf("Receipt Lbs"))
          arrayallignedcolumn2.push(headerarray2.indexOf("Adjustment Lbs"))
          arrayallignedcolumn2.push(headerarray2.indexOf("Total Adjusted Lbs"))
          arrayallignedcolumn2.push(headerarray2.indexOf("Applied Lbs"))
          arrayallignedcolumn2.push(headerarray2.indexOf("100% Adjustment Lbs"))
          arrayallignedcolumn2.push(headerarray2.indexOf("Open Lbs"))

          while (n < data.length) {
            if (n >= incrementValue) {
              obj.addPage();
            }

            obj.autoTable({
              startX: 5,
              startY: 5,
              styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
              margin: { top: 5, left: 0.5 },
              headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
              body: data.slice(n, n + incrementValue),
              theme: 'plain',
              fontStyle: 'normal',
              head: [headerarray1],

              didParseCell: function (res) {
                //For Numeric value in pdf align to right side
                if(arrayAligneColumn.includes(res.column.index)  && res.section !== "head"){
                  res.cell.styles.halign = "right"
                }else{
                  res.cell.styles.halign = "center"
                }
              },
            });

            if (headerarray2.length > 0) {
              obj.addPage();

              obj.autoTable({
                startX: 5,
                startY: 5,
                styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
                margin: { top: 5, left: 0.5 },
                headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
                body: data2.slice(n, n + incrementValue),
                theme: 'plain',
                fontStyle: 'normal',
                head: [headerarray2],

                didParseCell: function (res) {
                if(arrayallignedcolumn2.includes(res.column.index)  && res.section !== "head"){
                  res.cell.styles.halign = "right"
                }else{
                  res.cell.styles.halign = "center"
                }
                },

              });
            }
          n = n + incrementValue;
        }
        // fOOTER part Implimentation
        let pagelimit = 0
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 1; w <= pageCount; w++) {
          let pageCurrent = w
          obj.setPage(w);
          obj.setFontSize(8);
          obj.text("Delivery Agreement Search - Crop Year " + cropyear, 1, 200);
          obj.text((pageCurrent).toString(), obj.internal.pageSize.width - 10, obj.internal.pageSize.height - 10);
        }

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnPrintClick click event"
        );
      }
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnExportToExcelClick = async () => {//Excel Functionality
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      var fileName = "Delivery Agreement Search"

      let excelData = getData(thisObj, "gridDelvAgreeSearch")
      if (excelData != undefined && excelData != null && excelData != "") {
      let secondaryRows = thisObj.state.gridDelvAgreeSearch.expandedRows;
      const arrayData = []
      for (var i = 0; i < excelData.length; i++) {
        var obj = {}
        if (thisObj.state.gridDelvAgreeSearch.columns[0].Visible) {
          obj["Loc."] = excelData[i].txtcolLoc
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[1].Visible) {
          obj["Delv In/Out"] = excelData[i].txtcolDelvInOut
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[2].Visible) {
          obj["Agree Date"] = excelData[i].txtcolAgreeDate
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[3].Visible) {
          obj["Agree#"] = ExcelNumericDataConvert(excelData[i].txtcolAgree)
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[4].Visible) {
          obj["Vendor"] = excelData[i].txtcolVendor
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[5].Visible) {
          obj["Type"] = excelData[i].txtcolType
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[6].Visible) {
          obj["Variety"] = excelData[i].txtcolVariety
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[7].Visible) {
          obj["Seed Gen"] = excelData[i].txtcolSeedGen
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[8].Visible) {
          obj["Seg"] = ExcelNumericDataConvert(excelData[i].txtcolSeg)
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[9].Visible) {
          obj["Status"] = excelData[i].txtcolStatus
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[10].Visible) {
          obj["Price Method"] = excelData[i].txtcolmPriceMethod
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[11].Visible) {
          obj["Price"] = ExcelNumericDataConvert(excelData[i].txtcolPrice)
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[12].Visible) {
          obj["Conversion%"] = ExcelNumericDataConvert(excelData[i].txtcolConversion)
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[13].Visible) {
          obj["Receipt Value"] = ExcelNumericDataConvert(excelData[i].txtcolReceiptValue,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[14].Visible) {
          obj["100% Applied $"] = ExcelNumericDataConvert(excelData[i].txtcol100Applied,[])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[15].Visible) {
          obj["100% Adjustment $"] = ExcelNumericDataConvert(excelData[i].txtcol100AdjustmentDoller,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[16].Visible) {
          obj["100% Open $"] = ExcelNumericDataConvert(excelData[i].txtcol100Open)
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[17].Visible) {
          obj["Receipt Lbs"] = ExcelNumericDataConvert(excelData[i].txtcolReceiptLbs,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[18].Visible) {
          obj["Adjustment Lbs"] = ExcelNumericDataConvert(excelData[i].txtcolAdjustmentlbs,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[19].Visible) {
          obj["Total Adjusted Lbs"] = ExcelNumericDataConvert(excelData[i].txtcolTotalAdjustedlbs,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[20].Visible) {
          obj["Applied Lbs"] = ExcelNumericDataConvert(excelData[i].txtcolAppliedLbs,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[21].Visible) {
          obj["100% Adjustment Lbs"] = ExcelNumericDataConvert(excelData[i].txtcol100Adjustment,[],[2])
        }
        if (thisObj.state.gridDelvAgreeSearch.columns[22].Visible) {
          obj["Open Lbs"] = ExcelNumericDataConvert(excelData[i].txtcolOpenlb,[],[2])
        }
        arrayData.push(obj)

        if (secondaryRows.length > 0) {
          for (let k = 0; k < secondaryRows.length; k++) {
            if (secondaryRows[k].rowID$ == i) {
              if (excelData[i].contData != undefined) {
                for (let j = 0; j < excelData[i].contData.length; j++) {
                  let extendedDataVendor = {};
                  extendedDataVendor["Vendor"] = excelData[i].contData[j].vendors;
                  arrayData.push(extendedDataVendor);
                }
              }
            }
          }
        }
      }
      
      var Obj = {}
      let textReceiptLbs = getData(thisObj,"textReceiptLbs")
      let textAdjustmentLbs = getData(thisObj,"textAdjustmentLbs")
      let textTotalAdjustedLbs = getData(thisObj,"textTotalAdjustedLbs")
      let textAppliedLbs= getData(thisObj,"textAppliedLbs")
      let text100AdjustmentLbs = getData(thisObj,"text100AdjustmentLbs")
      let textOpenLbs = getData(thisObj,"textOpenLbs")
      if (thisObj.state.gridDelvAgreeSearch.columns[0].Visible) {
        Obj["Loc."] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[1].Visible) {
        Obj["Delv In/Out"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[2].Visible) {
        Obj["Agree Date"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[3].Visible) {
        Obj["Agree#"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[4].Visible) {
        Obj["Vendor"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[5].Visible) {
        Obj["Type"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[6].Visible) {
        Obj["Variety"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[7].Visible) {
        Obj["Seed Gen"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[8].Visible) {
        Obj["Seg"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[9].Visible) {
        Obj["Status"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[10].Visible) {
        Obj["Price Method"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[11].Visible) {
        Obj["Price"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[12].Visible) {
        Obj["Conversion%"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[13].Visible) {
        Obj["Receipt Value"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[14].Visible) {
        Obj["100% Applied $"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[15].Visible) {
        Obj["100% Adjustment $"] = "Total"
      }
      else if (thisObj.state.gridDelvAgreeSearch.columns[16].Visible) {
        Obj["100% Open $"] = "Total"
      }
      if (thisObj.state.gridDelvAgreeSearch.columns[17].Visible) {
        Obj["Receipt Lbs"] = ExcelNumericDataConvert(textReceiptLbs,[],[0])
      }
      if (thisObj.state.gridDelvAgreeSearch.columns[18].Visible) {
        Obj["Adjustment Lbs"] = ExcelNumericDataConvert(textAdjustmentLbs,[],[0])
      }
      if (thisObj.state.gridDelvAgreeSearch.columns[19].Visible) {
        Obj["Total Adjusted Lbs"] = ExcelNumericDataConvert(textTotalAdjustedLbs,[],[0])
      }
      if (thisObj.state.gridDelvAgreeSearch.columns[20].Visible) {
        Obj["Applied Lbs"] = ExcelNumericDataConvert(textAppliedLbs,[],[0])
      }
      if (thisObj.state.gridDelvAgreeSearch.columns[21].Visible) {
        Obj["100% Adjustment Lbs"] = ExcelNumericDataConvert(text100AdjustmentLbs,[],[0])
      }
      if (thisObj.state.gridDelvAgreeSearch.columns[22].Visible) {
        Obj["Open Lbs"] = ExcelNumericDataConvert(textOpenLbs,[],[0])
      }
      arrayData.push(Obj);

      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtnExportToExcelClick: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnCreateAgreementClick = async () => {//Create Btn Click Functionality
    try {
      let obj = {
        AgreementNum: "",
        mode: "Add"
      }
      setData(thisObj, "dataForDelvAgreeProfile", obj)
      goTo(thisObj, "WarehouseReceipts#DelvAgreeProfile#DEFAULT#true#Click");

    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCreateAgreementClick click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnCreateAgreementClick = onbtnCreateAgreementClick;

  const onbtnOpenClick = async () => {//Open Btn Functionality
    try {
      // Fixed As part of SIT Defect
      let vsfGridData = getValue(thisObj,"gridDelvAgreeSearch")
     
      if(vsfGridData !=undefined && vsfGridData !=null && vsfGridData != ''){
        let selectedRowData 
        let rowNum = getSelectedRowNumber(thisObj, 'gridDelvAgreeSearch');
        if (rowNum == undefined) {
          showMessage(thisObj,"An Agreement must be selected to open.",false)
          return;
        }
        selectedRowData = vsfGridData[rowNum]
      
        let obj = {
          AgreementNum :selectedRowData?.txtcolAgree ,
          mode : "Update" 
   
        }
        setData(thisObj, "dataForDelvAgreeProfile", obj)
        goTo(thisObj, "WarehouseReceipts#DelvAgreeProfile#DEFAULT#true#Click");
      }
      else{
        showMessage(thisObj,"An Agreement must be selected to open.",false)
        setData(thisObj,"lbl_firstload","T")
        return;
      }
      
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnOpenClick click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnOpenClick = onbtnOpenClick;

  // Double click Event in Grid
  const ongridDelvAgreeSearchDblclick = () => {//Grid Row Double click events
    try {
      let selectedDblClickRowvalue = thisObj.state.gridDelvAgreeSearch.selected[0];     
      if(selectedDblClickRowvalue != undefined && selectedDblClickRowvalue != null && selectedDblClickRowvalue !=''){
      onbtnOpenClick()
    }
      else {
        alert("An agreement must be selected to open.")
        setData(thisObj,"lbl_firstload","T")
        return;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred while getting selected row object on Grid Row Double click event"
        );
      }
    }
    return true
  }
  thisObj.ongridDelvAgreeSearchDblclick = ongridDelvAgreeSearchDblclick

  const onbtnCloseClick = async () => {//Close Btn Functionality
    try {
      if (document.getElementById("WarehouseReceipts_DelvAgreeSearchPopUp") != null) {
        document.getElementById("WarehouseReceipts_DelvAgreeSearchPopUp").childNodes[0].click();
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCloseClick click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvAgreeSearch*/}

              {/* END_USER_CODE-USER_BEFORE_DelvAgreeSearch*/}
              <GroupBoxWidget
                conf={state.grpbxDelvAgreeSrch}
                screenConf={state}
              >
              {/* START_USER_CODE-USER_BEFORE_grpbxDelvAgreeSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvAgreeSearch*/}

              <GroupBoxWidget
                conf={state.grpbxDelvAgreeSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtAgreementNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtAgreementNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreementNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAgreementNum*/}

                {/* END_USER_CODE-USER_AFTER_txtAgreementNum*/}
                {/* START_USER_CODE-USER_BEFORE_ddAgreementType*/}

                {/* END_USER_CODE-USER_BEFORE_ddAgreementType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAgreementType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAgreementType*/}

                {/* END_USER_CODE-USER_AFTER_ddAgreementType*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_ddAgreementStatus*/}

                {/* END_USER_CODE-USER_BEFORE_ddAgreementStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAgreementStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAgreementStatus*/}

                {/* END_USER_CODE-USER_AFTER_ddAgreementStatus*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvAgreeSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvAgreeSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxDelvAgreeSearch2*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvAgreeSearch2*/}

              <GroupBoxWidget
                conf={state.grpbxDelvAgreeSearch2}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeg}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddEdibleOil}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvAgreeSearch2*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvAgreeSearch2*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxDelvSearch3*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvSearch3*/}

              <GroupBoxWidget conf={state.grpbxDelvSearch3} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateAgreement*/}

                <ButtonWidget
                  conf={state.btnCreateAgreement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateAgreement*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                {/* START_USER_CODE-USER_BEFORE_gridDelvAgreeSearch*/}

                {/* END_USER_CODE-USER_BEFORE_gridDelvAgreeSearch*/}

                <GridWidget
                  conf={state.gridDelvAgreeSearch}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridDelvAgreeSearch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridDelvAgreeSearch*/}

                {/* END_USER_CODE-USER_AFTER_gridDelvAgreeSearch*/}
                {/* START_USER_CODE-USER_BEFORE_txtReceiptLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtReceiptLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReceiptLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReceiptLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtReceiptLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtAdjustmentLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtAdjustmentLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAdjustmentLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAdjustmentLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtAdjustmentLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalAdjustedLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalAdjustedLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalAdjustedLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalAdjustedLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalAdjustedLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtAppliedLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtAppliedLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAppliedLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAppliedLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtAppliedLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txt100AdjustmentLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txt100AdjustmentLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt100AdjustmentLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt100AdjustmentLbs*/}

                {/* END_USER_CODE-USER_AFTER_txt100AdjustmentLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtOpenLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtOpenLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOpenLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOpenLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtOpenLbs*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvSearch3*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvSearch3*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvAgreeSearch*/}

              {/* END_USER_CODE-USER_AFTER_DelvAgreeSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvAgreeSearch);
