/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import jsPDF from 'jspdf';
// import moment from "moment";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  WhiteSpaceWidget,
  getData,
  setValue,
  getValue,
  hideColumn
} from "../../shared/WindowImports";

import "./AccountDistributionActivity.scss";
import { ContractManagementService } from "../Service/ContractManagementService";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CommonContext from '../../Store/CommonContext';
// END_USER_CODE-USER_IMPORTS
function ContractManagement_AccountDistributionActivity(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const commonContext = useContext(CommonContext);
  const match = useRouteMatch();
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  var cropYear = JSON.parse(sessionStorage.getItem('year'));
  var compId = sessionStorage.getItem('compId');
  var ContractType = ''
  // START_USER_CODE-USER_PROPERTIES
  // const json = [
  //   {
  //     txtDate: "10/12/2022",
  //     txtAcct: "1234",
  //     txtResp: "abc",
  //     txtDept: "abc",
  //     txtSubDept: "abc",
  //     txtLoc: "abc",
  //     txtAmount: "120",
  //     txtQuantity: "12",
  //     txtRevInd: "123",
  //     txtIBMDate: "10/12/2022",
  //     txtCntrctNo: "123",
  //     txtManualInd: "123",
  //   },
  // ];
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "AccountDistributionActivity",

    windowName: "AccountDistributionActivity",
    template: "Standard Screen",
    Cols: "3",
    rows: [],
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.AccountDistributionActivity",
    // START_USER_CODE-USER_AccountDistributionActivity_PROPERTIES
    headerData: {
      scrName: "Account Distribution Activity",
      scrCode: "PN1060F",
    },
    headerDetails: {
      year: "",
      companyName: ""
    },
    // END_USER_CODE-USER_AccountDistributionActivity_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxClosePrint",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPrintScreen: {
      name: "btnPrintScreen",
      type: "ButtonWidget",
      parent: "grpbxClosePrint",
      Label: "Print Screen",
      CharWidth: "26",
      id: "pdfDocument",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintScreen_PROPERTIES

      // END_USER_CODE-USER_btnPrintScreen_PROPERTIES
    },
    gridAccountDistribution: {
      name: "gridAccountDistribution",
      type: "GridWidget",
      parent: "grpbxAccntDstrbtn",
      gridCellsOrder:
        "txtDate,txtAcct,txtResp,txtDept,txtSubDept,txtLoc,txtVendor,txtRemit,txtAmount,txtQuantity,txtRevInd,txtIBMDate,txtCheckNumber,txtCntrctNo,txtContRev,txtInspectNumber,txtInspRev,txtSettlementNumber,txtTransNum,txtTransRev,txtTradeInsp,txtTradeRev,txtTradeSettle,txtWRNumber,txtWRRev,txtDelvAgree,txtDelvRev,txtManualInd",
      Pagination: false,
      HasLabel: false,
      Cols: "12",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAccountDistribution_PROPERTIES

      // END_USER_CODE-USER_gridAccountDistribution_PROPERTIES
    },
    grpbxClosePrint: {
      name: "grpbxClosePrint",
      type: "GroupBoxWidget",
      parent: "grpbxAccntDstrbtn",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxClosePrint_PROPERTIES

      // END_USER_CODE-USER_grpbxClosePrint_PROPERTIES
    },
    lblAccountDistribution: {
      name: "lblAccountDistribution",
      type: "LabelWidget",
      parent: "grpbxAccntDstrbtn",
      Label: "Account Distribution",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAccountDistribution_PROPERTIES

      // END_USER_CODE-USER_lblAccountDistribution_PROPERTIES
    },
    txtAcct: {
      name: "txtAcct",
      type: "TextBoxWidget",
      colName: "txtColAcct",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcct_PROPERTIES

      // END_USER_CODE-USER_txtAcct_PROPERTIES
    },
    txtAmount: {
      name: "txtAmount",
      type: "TextBoxWidget",
      colName: "txtColAmount",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtInspectNumber: {
      name: "txtInspectNumber",
      type: "TextBoxWidget",
      colName: "txtColInspectNumber",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtInspRev: {
      name: "txtInspRev",
      type: "TextBoxWidget",
      colName: "txtColInspRev",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtWRNumber: {
      name: "txtWRNumber",
      type: "TextBoxWidget",
      colName: "txtColWRNumber",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },

    txtWRRev: {
      name: "txtWRRev",
      type: "TextBoxWidget",
      colName: "txtColWRRev",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtSettlementNumber: {
      name: "txtSettlementNumber",
      type: "TextBoxWidget",
      colName: "txtColSettlementNumber",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtCntrctNo: {
      name: "txtCntrctNo",
      type: "TextBoxWidget",
      colName: "txtColCntrctNo",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtCntrctNo_PROPERTIES

      // END_USER_CODE-USER_txtCntrctNo_PROPERTIES
    },
    txtContRev: {
      name: "txtContRev",
      type: "TextBoxWidget",
      colName: "txtColContRev",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtContRev_PROPERTIES

      // END_USER_CODE-USER_txtContRev_PROPERTIES
    },
    txtTransNum: {
      name: "txtTransNum",
      type: "TextBoxWidget",
      colName: "txtColTransNum",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtTransNum_PROPERTIES

      // END_USER_CODE-USER_txtTransNum_PROPERTIES
    },
    txtTransRev: {
      name: "txtTransRev",
      type: "TextBoxWidget",
      colName: "txtColTransRev",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtTransRev_PROPERTIES

      // END_USER_CODE-USER_txtTransRev_PROPERTIES
    },
    txtDelvAgree: {
      name: "txtDelvAgree",
      type: "TextBoxWidget",
      colName: "txtColDelvAgree",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtDelvAgree_PROPERTIES

      // END_USER_CODE-USER_txtDelvAgree_PROPERTIES
    },
    txtDelvRev: {
      name: "txtDelvRev",
      type: "TextBoxWidget",
      colName: "txtColDelvRev",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtDelvRev_PROPERTIES

      // END_USER_CODE-USER_txtDelvRev_PROPERTIES
    },
    txtColAcct: {
      name: "txtColAcct",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Acct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAcct_PROPERTIES

      // END_USER_CODE-USER_txtColAcct_PROPERTIES
    },
    txtColAmount: {
      name: "txtColAmount",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAmount_PROPERTIES

      // END_USER_CODE-USER_txtColAmount_PROPERTIES
    },
    txtColSettlementNumber: {
      name: "txtColSettlementNumber",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Settlement Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAmount_PROPERTIES

      // END_USER_CODE-USER_txtColAmount_PROPERTIES
    },
    txtColInspectNumber: {
      name: "txtColInspectNumber",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Inspect Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAmount_PROPERTIES

      // END_USER_CODE-USER_txtColAmount_PROPERTIES
    },
    txtColInspRev: {
      name: "txtColInspRev",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Insp Rev",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAmount_PROPERTIES

      // END_USER_CODE-USER_txtColAmount_PROPERTIES
    },
    txtColWRNumber: {
      name: "txtColIWRNumber",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Insp Rev",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAmount_PROPERTIES

      // END_USER_CODE-USER_txtColAmount_PROPERTIES
    },

    txtColWRRev: {
      name: "txtColIWRRev",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Insp Rev",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAmount_PROPERTIES

      // END_USER_CODE-USER_txtColAmount_PROPERTIES
    },
    txtColCntrctNo: {
      name: "txtColCntrctNo",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Contract Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCntrctNo_PROPERTIES

      // END_USER_CODE-USER_txtColCntrctNo_PROPERTIES
    },
    txtColContRev: {
      name: "txtColContRev",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Cont Rev",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColContRev_PROPERTIES

      // END_USER_CODE-USER_txtColContRev_PROPERTIES
    },
    txtColTransNum: {
      name: "txtColTransNum",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Transfer Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTransNum_PROPERTIES

      // END_USER_CODE-USER_txtColTransNum_PROPERTIES
    },
    txtColTransRev: {
      name: "txtColTransRev",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Tran Rev",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTransRev_PROPERTIES

      // END_USER_CODE-USER_txtColTransRev_PROPERTIES
    },
    txtColDelvAgree: {
      name: "txtColDelvAgree",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Delv Agree",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDelvAgree_PROPERTIES

      // END_USER_CODE-USER_txtColDelvAgree_PROPERTIES
    },
    txtColDelvRev: {
      name: "txtColDelvRev",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Delv Rev",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDelvRev_PROPERTIES

      // END_USER_CODE-USER_txtColDelvRev_PROPERTIES
    },
    txtColDate: {
      name: "txtColDate",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDate_PROPERTIES

      // END_USER_CODE-USER_txtColDate_PROPERTIES
    },
    txtColDept: {
      name: "txtColDept",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Dept",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDept_PROPERTIES

      // END_USER_CODE-USER_txtColDept_PROPERTIES
    },
    txtColIBMDate: {
      name: "txtColIBMDate",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "IBM Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColIBMDate_PROPERTIES

      // END_USER_CODE-USER_txtColIBMDate_PROPERTIES
    },
    txtColLoc: {
      name: "txtColLoc",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLoc_PROPERTIES

      // END_USER_CODE-USER_txtColLoc_PROPERTIES
    },
    txtColManualInd: {
      name: "txtColManualInd",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Manual Ind.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColManualInd_PROPERTIES

      // END_USER_CODE-USER_txtColManualInd_PROPERTIES
    },
    txtColQuantity: {
      name: "txtColQuantity",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Quantity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColQuantity_PROPERTIES

      // END_USER_CODE-USER_txtColQuantity_PROPERTIES
    },
    txtColResp: {
      name: "txtColResp",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Resp",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColResp_PROPERTIES

      // END_USER_CODE-USER_txtColResp_PROPERTIES
    },
    txtColRevInd: {
      name: "txtColRevInd",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Rev Ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColRevInd_PROPERTIES

      // END_USER_CODE-USER_txtColRevInd_PROPERTIES
    },
    txtColSubDept: {
      name: "txtColSubDept",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Sub Dept",
      Height: "",
      Width: "",
      // START_USER_CODE

      // END_USER_CODE
    },
    txtColVendor: {
      name: "txtColVendor",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE

      // END_USER_CODE
    },
    txtColRemit: {
      name: "txtColRemit",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Remit",
      Height: "",
      Width: "",
      // START_USER_CODE-USER

      // END_USER_CODE-USER
    },
    txtColTradeInsp: {
      name: "txtColTradeInsp",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Trade Insp",
      Height: "",
      Width: "",
      // START_USER_CODE

      // END_USER_CODE
    },
    txtColTradeRev: {
      name: "txtColTradeRev",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Trade Rev",
      Height: "",
      Width: "",
      // START_USER_CODE

      // END_USER_CODE
    },
    txtColTradeSettle: {
      name: "txtColTradeSettle",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Trade Settle",
      Height: "",
      Width: "",
      // START_USER_CODE

      // END_USER_CODE-
    },
    txtColCheckNumber: {
      name: "txtColCheckNumber",
      type: "GridColumnWidget",
      parent: "gridAccountDistribution",
      Label: "Check Number",
      Height: "",
      Width: "",
      // START_USER_CODE

      // END_USER_CODE
    },
    txtDate: {
      name: "txtDate",
      type: "TextBoxWidget",
      colName: "txtColDate",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtDate_PROPERTIES

      // END_USER_CODE-USER_txtDate_PROPERTIES
    },
    txtDept: {
      name: "txtDept",
      type: "TextBoxWidget",
      colName: "txtColDept",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDept_PROPERTIES

      // END_USER_CODE-USER_txtDept_PROPERTIES
    },
    txtIBMDate: {
      name: "txtIBMDate",
      type: "TextBoxWidget",
      colName: "txtColIBMDate",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtIBMDate_PROPERTIES

      // END_USER_CODE-USER_txtIBMDate_PROPERTIES
    },
    txtLoc: {
      name: "txtLoc",
      type: "TextBoxWidget",
      colName: "txtColLoc",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoc_PROPERTIES

      // END_USER_CODE-USER_txtLoc_PROPERTIES
    },
    txtManualInd: {
      name: "txtManualInd",
      type: "TextBoxWidget",
      colName: "txtColManualInd",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtManualInd_PROPERTIES

      // END_USER_CODE-USER_txtManualInd_PROPERTIES
    },
    txtQuantity: {
      name: "txtQuantity",
      type: "TextBoxWidget",
      colName: "txtColQuantity",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtQuantity_PROPERTIES

      // END_USER_CODE-USER_txtQuantity_PROPERTIES
    },
    txtResp: {
      name: "txtResp",
      type: "TextBoxWidget",
      colName: "txtColResp",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtResp_PROPERTIES

      // END_USER_CODE-USER_txtResp_PROPERTIES
    },
    txtRevInd: {
      name: "txtRevInd",
      type: "TextBoxWidget",
      colName: "txtColRevInd",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRevInd_PROPERTIES

      // END_USER_CODE-USER_txtRevInd_PROPERTIES
    },
    txtSubDept: {
      name: "txtSubDept",
      type: "TextBoxWidget",
      colName: "txtColSubDept",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      colName: "txtColVendor",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    txtRemit: {
      name: "txtRemit",
      type: "TextBoxWidget",
      colName: "txtColRemit",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    txtCheckNumber: {
      name: "txtCheckNumber",
      type: "TextBoxWidget",
      colName: "txtColCheckNumber",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    txtTradeInsp: {
      name: "txtTradeInsp",
      type: "TextBoxWidget",
      colName: "txtColTradeInsp",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    txtTradeRev: {
      name: "txtTradeRev",
      type: "TextBoxWidget",
      colName: "txtColTradeRev",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    txtTradeSettle: {
      name: "txtTradeSettle",
      type: "TextBoxWidget",
      colName: "txtColTradeSettle",
      parent: "gridAccountDistribution",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDept_PROPERTIES

      // END_USER_CODE-USER_txtSubDept_PROPERTIES
    },
    whtspc01: {
      name: "whtspc01",
      type: "WhiteSpaceWidget",
      parent: "grpbxClosePrint",
      HasLabel: false,
      // START_USER_CODE-USER_whtspc01_PROPERTIES

      // END_USER_CODE-USER_whtspc01_PROPERTIES
    },
    whtspc02: {
      name: "whtspc02",
      type: "WhiteSpaceWidget",
      parent: "grpbxClosePrint",
      HasLabel: false,
      // START_USER_CODE-USER_whtspc02_PROPERTIES

      // END_USER_CODE-USER_whtspc02_PROPERTIES
    },

    grpbxAccntDstrbtn: {
      name: "grpbxAccntDstrbtn",
      type: "GroupBoxWidget",
      parent: "AccountDistributionActivity",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAccntDstrbtn_PROPERTIES

      // END_USER_CODE-USER_grpbxAccntDstrbtn_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  // get api formaccdist;
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    GetAccDistAct();
    thisObj.setState(current => {
      return {
        ...current,
        headerDetails: {
          ...state["headerDetails"],
          year: getData(thisObj, 'headDetailYear'),
          companyName: getData(thisObj, 'headDetailCompName')
        }
      }
    });
  }, []);

  // START_USER_CODE-USER_METHODS
  const GetAccDistAct = () => {
    ContractType = getData(thisObj, 'type')
    if (ContractType == 'CONTRACT') {
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 

      let buy_pt_id = commonContext.getContractProfile_BuyingPointId;
      let audit_cont_num = commonContext.getContractProfile_ContractNumber;
      let audit_insp_num = ""
      let audit_whse_rcpt = ""
      let audit_xfer_num = ""
      let audit_buy_pt = ""
      let audit_receivable_num = ""
      let audit_check_num = ""
      let audit_trade_insp = ""
      let audit_delv_agree_num = ""

      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}

          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtCntrctNo = data[i].auditContNum
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)

        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata

          }
        });
      });
    }
    if (ContractType == 'SETTLEMENT') {
      let NewBuyPtId = commonContext.getViewSettlement_BuyingPointId;
      let newAuditInsNum = commonContext.getViewSettlement_AuditInspNum;
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 

      let buy_pt_id = NewBuyPtId
      let audit_cont_num = ""
      let audit_insp_num = newAuditInsNum
      let audit_whse_rcpt = ""
      let audit_xfer_num = ""
      let audit_buy_pt = ""
      let audit_receivable_num = ""
      let audit_check_num = ""
      let audit_trade_insp = ""
      let audit_delv_agree_num = ""

      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtInspectNumber = data[i].auditInspNum
          Obj.txtInspRev = data[i].auditInspRev
          Obj.txtSettlementNumber = data[i].auditSettleNum
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata
          }
        });
      });
    }
    if (ContractType == 'WAREHOUSERECEIPT') {
      let BuyingPointId = commonContext.getWR_BuyingPointId;
      let AuditWhseRcpt = commonContext.getWR_WRNumber;
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 


      let buy_pt_id = BuyingPointId
      let audit_cont_num = ""
      let audit_insp_num = ""
      let audit_whse_rcpt = AuditWhseRcpt
      let audit_xfer_num = ""
      let audit_buy_pt = ""
      let audit_receivable_num = ""
      let audit_check_num = ""
      let audit_trade_insp = ""
      let audit_delv_agree_num = ""
      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtWRNumber = data[i].auditWhseRcpt
          Obj.txtWRRev = data[i].auditWhseRev
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata
          }
        });
      });
    }
    if (ContractType == 'EXTWHSRECEIPT') {
      let BuyingPointId = commonContext.getWR_BuyingPointId;
      let AuditWhseRcpt = commonContext.getWR_WRNumber;
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 

      let buy_pt_id = BuyingPointId
      let audit_cont_num = ""
      let audit_insp_num = ""
      let audit_whse_rcpt = AuditWhseRcpt
      let audit_xfer_num = ""
      let audit_buy_pt = ""
      let audit_receivable_num = ""
      let audit_check_num = ""
      let audit_trade_insp = ""
      let audit_delv_agree_num = ""
      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtWRNumber = data[i].auditWhseRcpt
          Obj.txtWRRev = data[i].auditWhseRev
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata
          }
        });
      });
    }
    if (ContractType == 'CHECK') {
      let BuyingPointId = commonContext.getReviewCheck_BuyingPointid;
      let sReceivableNum = commonContext.getReviewCheck_sReceivableNum;
      let sCheckNum = commonContext.getReviewCheck_sCheckNum;
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 

      let buy_pt_id = null;
      let audit_cont_num = null
      let audit_insp_num = null
      let audit_whse_rcpt = null
      let audit_xfer_num = null
      let audit_buy_pt = null
      let audit_receivable_num = sReceivableNum
      let audit_check_num = sCheckNum
      let audit_trade_insp = null
      let audit_delv_agree_num = null

      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtVendor = data[i].distVendor
          Obj.txtRemit = data[i].distRemit
          Obj.txtCheckNum = data[i].distCheckNum
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          let Check_Num = (data[i].auditCheckNum).toString();
          let CheckNumLength = Check_Num.length
          if (CheckNumLength < 5 && CheckNumLength > 0) {
            let NewCheckNum = '0'.repeat((5 - CheckNumLength)) + data[i].auditCheckNum
            Check_Num = 'S' + NewCheckNum
          }
          Obj.txtCheckNumber = Check_Num
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata

          }
        });
      });
    }
    if (ContractType == 'TRADE') {
      let BuyingPointId = commonContext.getTradeSettleProfile_BuyingPointId;
      let sInspNum = commonContext.getTradeSettleProfile_sInspNum;
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 

      let buy_pt_id = BuyingPointId;
      let audit_cont_num = null
      let audit_insp_num = null
      let audit_whse_rcpt = null
      let audit_xfer_num = null
      let audit_buy_pt = null
      let audit_receivable_num = null
      let audit_check_num = null
      let audit_trade_insp = sInspNum
      let audit_delv_agree_num = null

      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtTradeInsp = data[i].auditTradeInsp
          Obj.txtTradeRev = data[i].auditTradeRev
          Obj.txtTradeSettle = data[i].auditTradeSettle
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata

          }
        });
      });
    }
    if (ContractType == 'TRANSFER') {
      let BuyingPointId = commonContext.getStockTrans_BuyingPointId;
      let AuditWhseRcpt = commonContext.getStockTransfer_Number;
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvAgree') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtDelvRev') 

      let buy_pt_id = ""
      let audit_cont_num = ""
      let audit_insp_num = ""
      let audit_whse_rcpt = ""
      let audit_xfer_num = AuditWhseRcpt
      let audit_buy_pt = BuyingPointId
      let audit_receivable_num = ""
      let audit_check_num = ""
      let audit_trade_insp = ""
      let audit_delv_agree_num = ""
      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtTransNum = data[i].auditXferNum
          Obj.txtTransRev = data[i].auditXferRev
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata
          }
        });
      });
    }
    if (ContractType == 'DELVAGREE') {
      let BuyingPointId = commonContext.getDelvAgree_BuyingPointId;
      let AuditWhseRcpt = commonContext.getDelvAgree_Number;
      hideColumn(thisObj, "gridAccountDistribution", 'txtVendor')
      hideColumn(thisObj, "gridAccountDistribution", 'txtRemit')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCheckNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtCntrctNo')
      hideColumn(thisObj, "gridAccountDistribution", 'txtContRev') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspectNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtInspRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtSettlementNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRNumber')
      hideColumn(thisObj, "gridAccountDistribution", 'txtWRRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeInsp')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeRev')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTradeSettle')
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransNum') 
      hideColumn(thisObj, "gridAccountDistribution", 'txtTransRev') 

      let buy_pt_id = BuyingPointId
      let audit_cont_num = ""
      let audit_insp_num = ""
      let audit_whse_rcpt = ""
      let audit_xfer_num = ""
      let audit_buy_pt = ""
      let audit_receivable_num = ""
      let audit_check_num = ""
      let audit_trade_insp = ""
      let audit_delv_agree_num = AuditWhseRcpt
      ContractManagementService.RetrieveAccountDistributionActivityDetails(buy_pt_id, audit_insp_num, audit_whse_rcpt, audit_xfer_num, audit_buy_pt, audit_receivable_num, audit_check_num, audit_trade_insp, audit_cont_num, audit_delv_agree_num)?.then(response => {
        let data = response;
        let tmpdata = []
        for (let i = 0; i < data.length; i++) {
          let Obj = {}
          let TxtDate = data[i].auditDateTime
          if (TxtDate !== null && TxtDate !== undefined && TxtDate != '') {
            Obj.txtDate = moment(data[i].auditDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtDate = ''
          }
          Obj.txtAcct = data[i].distAccount
          Obj.txtResp = data[i].distResp
          Obj.txtDept = data[i].distDept
          Obj.txtSubDept = data[i].distSubDept
          Obj.txtLoc = data[i].distLoc
          Obj.txtAmount = data[i].distAmount
          Obj.txtQuantity = data[i].distQuantity
          Obj.txtRevInd = data[i].reversalInd
          let TxtIBMDate = data[i].ibmDateTime
          if (TxtIBMDate !== null && TxtIBMDate !== undefined && TxtIBMDate != '') {
            Obj.txtIBMDate = moment(data[i].ibmDateTime).format("MM/DD/YYYY");
          }
          else {
            Obj.txtIBMDate = ''
          }
          Obj.txtDelvAgree = data[i].auditDelvAgreeNum
          Obj.txtDelvRev = data[i].auditDelvAgreeRev
          Obj.txtManualInd = data[i].auditManualId
          tmpdata.push(Obj)
        }
        setValue(thisObj, "gridAccountDistribution", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata
          }
        });
      });
    }
  };

  const onbtnCloseClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      // document.getElementsByClassName("close")[0].click();
      document.getElementById("ContractManagement_AccountDistributionActivityPopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // code for print pdf...
  const Print = () => {
    ContractType = getData(thisObj, 'type')
    if (ContractType == 'CONTRACT') {
      let buy_pt_id = commonContext.getContractProfile_BuyingPointId;
      let audit_cont_num = commonContext.getContractProfile_ContractNumber;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtCntrctNo, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'Contract Number', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'left' },
          }
        });
        obj.setFontSize(9);
        obj.text(cropYear + ' Account Distribution - ' + buy_pt_id + ' - Contract: ' + audit_cont_num, 10, obj.internal.pageSize.height - 10);
        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    if (ContractType == 'SETTLEMENT') {
      let NewBuyPtId = commonContext.getViewSettlement_BuyingPointId;
      let newAuditInsNum = commonContext.getViewSettlement_AuditInspNum;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtInspectNumber, data1[i].txtInspRev, data1[i].txtSettlementNumber, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'Inspect Number', 'Insp Rev', 'Settlement Number', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'right' },
            13: { halign: 'left' },
          }
        });
        obj.setFontSize(9);
        obj.text(cropYear + ' Account Distribution - ' + NewBuyPtId + ' - Settlement: ' + newAuditInsNum, 10, obj.internal.pageSize.height - 10);

        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    if (ContractType == 'WAREHOUSERECEIPT') {
      let BuyingPointId = commonContext.getWR_BuyingPointId;
      let AuditWhseRcpt = commonContext.getWR_WRNumber;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtWRNumber, data1[i].txtWRRev, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'WR Number', 'WR Rev', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'left' },
          }
        });
        obj.setFontSize(9);
        obj.text(cropYear + ' Account Distribution - ' + BuyingPointId + ' - Receipt: ' + AuditWhseRcpt, 10, obj.internal.pageSize.height - 10);

        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    if (ContractType == 'EXTWHSRECEIPT') {
      let BuyingPointId = commonContext.getWR_BuyingPointId;
      let AuditWhseRcpt = commonContext.getWR_WRNumber;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtWRNumber, data1[i].txtWRRev, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'WR Number', 'WR Rev', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'left' },
          }
        });
        obj.setFontSize(9);
        obj.text(cropYear + ' Account Distribution - ' + BuyingPointId + ' - External Receipt: ' + AuditWhseRcpt, 10, obj.internal.pageSize.height - 10);

        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    if (ContractType == 'CHECK') {
      let BuyingPointId = commonContext.getReviewCheck_BuyingPointid;
      let sReceivableNum = commonContext.getReviewCheck_sReceivableNum;
      let sCheckNum = commonContext.getReviewCheck_sCheckNum;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        var Array = []
        let Obj = {}
        let NewCheckNum = ''
        if (sCheckNum == '0') {
          let ReceivableNum = sReceivableNum.toString();
          let ReceivableNumLength = ReceivableNum.length
          if (ReceivableNumLength < 6 && ReceivableNumLength > 0) {
            NewCheckNum = '0'.repeat((5 - ReceivableNumLength)) + ReceivableNum
            NewCheckNum = 'S' + NewCheckNum
          }

          Obj = { data: cropYear + ' Account Distribution - ' + BuyingPointId + ' - Receivable: ' + NewCheckNum }
        } else {
          Obj = { data: cropYear + ' Account Distribution - ' + BuyingPointId + ' - Check: ' + sCheckNum }
        }
        Array.push(Obj)
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtVendor, data1[i].txtRemit, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtCheckNumber, data1[i].txtManualInd
          ])
        }

        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 10,
          styles: { fontSize: 8, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Vendor', 'Remit', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'Check Number', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'left' },
            7: { halign: 'left' },
            8: { halign: 'right' },
            9: { halign: 'right' },
            10: { halign: 'left' },
            11: { halign: 'left' },
            12: { halign: 'right' },
            13: { halign: 'left' },
          }
        });

        obj.setPage();

        obj.autoTable({
          startY: 275,
          startX: 10,
          styles: { fontSize: 10, lineWidth: 0, color: 10 },
          body: Array,
          theme: 'plain',
          fontStyle: 'normal',
        });
        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }


    }
    if (ContractType == 'TRADE') {
      let BuyingPointId = commonContext.getTradeSettleProfile_BuyingPointId;
      let sInspNum = commonContext.getTradeSettleProfile_sInspNum;
      let CropYear = cropYearFromLS()

      let Arr = []
      let Obj = {}

      Obj = {data: CropYear + ' Account Distribution - ' + BuyingPointId + ' - Trade Insp: ' + sInspNum }

      Arr.push(Obj)


      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtTradeInsp, data1[i].txtTradeRev, data1[i].txtTradeSettle, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'Trade Insp', 'Trade Rev', 'Trade Settle', 'Manual Ind.',            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'right' },
            13: { halign: 'left' },
          }
        });

        obj.setPage();

        obj.autoTable({
          startY: 275,
          startX: 10,
          styles: { fontSize: 10, lineWidth: 0, color: 10 },
          body: Arr,
          theme: 'plain',
          fontStyle: 'normal',
        });
        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    if (ContractType == 'TRANSFER') {
      let BuyingPointId = commonContext.getStockTrans_BuyingPointId;
      let AuditWhseRcpt = commonContext.getStockTransfer_Number;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtTransNum, data1[i].txtTransRev, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'Transfer Number', 'Tran Rev', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'left' },
          }
        });
        obj.setFontSize(9);
        obj.text(cropYear + ' Account Distribution - ' + BuyingPointId + ' - Transfer: ' + AuditWhseRcpt, 10, obj.internal.pageSize.height - 10);

        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    if (ContractType == 'DELVAGREE') {
      let BuyingPointId = commonContext.getDelvAgree_BuyingPointId;
      let AuditWhseRcpt = commonContext.getDelvAgree_Number;
      var data1 = getValue(thisObj, "gridAccountDistribution")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtDate, data1[i].txtAcct, data1[i].txtResp, data1[i].txtDept, data1[i].txtSubDept, data1[i].txtLoc, data1[i].txtAmount, data1[i].txtQuantity, data1[i].txtRevInd, data1[i].txtIBMDate, data1[i].txtDelvAgree, data1[i].txtDelvRev, data1[i].txtManualInd
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Date', 'Acct', 'Resp', 'Dept', 'Sub Dept', 'Loc', 'Amount', 'Quantity', 'Rev Ind', 'IBM Date', 'Delv Agree Number', 'Delv Rev', 'Manual Ind.',
            ]
          ],
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'left' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'left' },
            6: { halign: 'right' },
            7: { halign: 'right' },
            8: { halign: 'left' },
            9: { halign: 'left' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'left' },
          }
        });
        obj.setFontSize(9);
        obj.text(cropYear + ' Account Distribution - ' + BuyingPointId + ' - Delivery Agreement: ' + AuditWhseRcpt, 10, obj.internal.pageSize.height - 10);

        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
  }


  const onbtnPrintScreenClick = () => {
    try {
      Print()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintScreenClick = onbtnPrintScreenClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleSubmit = handleSubmit = handleSubmit;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_AccountDistributionActivity*/}

              {/* END_USER_CODE-USER_BEFORE_AccountDistributionActivity*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxAccntDstrbtn*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAccntDstrbtn*/}

              <GroupBoxWidget conf={state.grpbxAccntDstrbtn} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAccountDistribution*/}

                {/* END_USER_CODE-USER_BEFORE_lblAccountDistribution*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAccountDistribution}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAccountDistribution*/}

                {/* END_USER_CODE-USER_AFTER_lblAccountDistribution*/}
                {/* START_USER_CODE-USER_BEFORE_gridAccountDistribution*/}

                {/* END_USER_CODE-USER_BEFORE_gridAccountDistribution*/}

                <GridWidget
                  conf={state.gridAccountDistribution}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, handleSubmit, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={state.rows}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleSubmit, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAccountDistribution*/}

                {/* END_USER_CODE-USER_AFTER_gridAccountDistribution*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxClosePrint*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxClosePrint*/}

                <GroupBoxWidget conf={state.grpbxClosePrint} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_whtspc01*/}

                  {/* END_USER_CODE-USER_BEFORE_whtspc01*/}

                  <WhiteSpaceWidget
                    conf={state.whtspc01}
                    screenConf={state}
                  ></WhiteSpaceWidget>
                  {/* START_USER_CODE-USER_AFTER_whtspc01*/}

                  {/* END_USER_CODE-USER_AFTER_whtspc01*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                  {/* START_USER_CODE-USER_BEFORE_whtspc02*/}

                  {/* END_USER_CODE-USER_BEFORE_whtspc02*/}

                  <WhiteSpaceWidget
                    conf={state.whtspc02}
                    screenConf={state}
                  ></WhiteSpaceWidget>
                  {/* START_USER_CODE-USER_AFTER_whtspc02*/}

                  {/* END_USER_CODE-USER_AFTER_whtspc02*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrintScreen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrintScreen*/}

                  <ButtonWidget
                    conf={state.btnPrintScreen}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrintScreen*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrintScreen*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxClosePrint*/}

                {/* END_USER_CODE-USER_AFTER_grpbxClosePrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAccntDstrbtn*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAccntDstrbtn*/}

              {/* START_USER_CODE-USER_AFTER_AccountDistributionActivity*/}

              {/* END_USER_CODE-USER_AFTER_AccountDistributionActivity*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_AccountDistributionActivity);
