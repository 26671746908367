/* eslint-disable*/
import React from "react";
import AccountPayment_ReadyToPayRelease from "./ReadyToPayRelease";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReadyToPayRelease Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReadyToPayRelease />);
    });
  });
  afterEach(cleanup);
  test("is ReadyToPayRelease Loads Successfully", () => {
    expect(screen.getByText("ReadyToPayRelease")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReadyToPayRelease", () => {
    // START_USER_CODE-USER_ReadyToPayRelease_Custom_Test_Case
    // END_USER_CODE-USER_ReadyToPayRelease_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReadyToPayRelease />);
    });
  });
  afterEach(cleanup);
  test("btnArr1(Button Widget) Test Cases", async () => {
    const btnArr1 = screen.getByTestId("btnArr1");
    expect(btnArr1).toBeInTheDocument;
    expect(btnArr1.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnArr1")
    );
  });
  test("Custom Test Cases for btnArr1", () => {
    // START_USER_CODE-USER_btnArr1_TEST
    // END_USER_CODE-USER_btnArr1_TEST
  });
  test("btnArr2(Button Widget) Test Cases", async () => {
    const btnArr2 = screen.getByTestId("btnArr2");
    expect(btnArr2).toBeInTheDocument;
    expect(btnArr2.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnArr2")
    );
  });
  test("Custom Test Cases for btnArr2", () => {
    // START_USER_CODE-USER_btnArr2_TEST
    // END_USER_CODE-USER_btnArr2_TEST
  });
  test("btnBrowse(Button Widget) Test Cases", async () => {
    const btnBrowse = screen.getByTestId("btnBrowse");
    expect(btnBrowse).toBeInTheDocument;
  });
  test("Custom Test Cases for btnBrowse", () => {
    // START_USER_CODE-USER_btnBrowse_TEST
    // END_USER_CODE-USER_btnBrowse_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnPrintCheck(Button Widget) Test Cases", async () => {
    const btnPrintCheck = screen.getByTestId("btnPrintCheck");
    expect(btnPrintCheck).toBeInTheDocument;
    expect(btnPrintCheck.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnPrintCheck")
    );
  });
  test("Custom Test Cases for btnPrintCheck", () => {
    // START_USER_CODE-USER_btnPrintCheck_TEST
    // END_USER_CODE-USER_btnPrintCheck_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnSaveToBatch(Button Widget) Test Cases", async () => {
    const btnSaveToBatch = screen.getByTestId("btnSaveToBatch");
    expect(btnSaveToBatch).toBeInTheDocument;
    expect(btnSaveToBatch.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnSaveToBatch")
    );
  });
  test("Custom Test Cases for btnSaveToBatch", () => {
    // START_USER_CODE-USER_btnSaveToBatch_TEST
    // END_USER_CODE-USER_btnSaveToBatch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridReadyToPayHold(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold = screen.getByTestId("gridReadyToPayHold");
    let gridReadyToPayHoldbtn =
      gridReadyToPayHold.nextElementSibling.firstElementChild;
    gridReadyToPayHold =
      gridReadyToPayHold.parentElement.parentElement.parentElement;
    expect(gridReadyToPayHold.tagName).toBe("DIV");
    expect(gridReadyToPayHold.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridReadyToPayHold", () => {
    // START_USER_CODE-USER_gridReadyToPayHold_TEST
    // END_USER_CODE-USER_gridReadyToPayHold_TEST
  });
  test("gridReadyToPayReleased(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased = screen.getByTestId("gridReadyToPayReleased");
    let gridReadyToPayReleasedbtn =
      gridReadyToPayReleased.nextElementSibling.firstElementChild;
    gridReadyToPayReleased =
      gridReadyToPayReleased.parentElement.parentElement.parentElement;
    expect(gridReadyToPayReleased.tagName).toBe("DIV");
    expect(gridReadyToPayReleased.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridReadyToPayReleased", () => {
    // START_USER_CODE-USER_gridReadyToPayReleased_TEST
    // END_USER_CODE-USER_gridReadyToPayReleased_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxArrows(GroupBox Widget) Test Cases", async () => {
    const grpbxArrows = screen.getByTestId("grpbxArrows");
    expect(grpbxArrows.tagName).toBe("BUTTON");
    expect(grpbxArrows.type).toBe("button");
    expect(grpbxArrows.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxArrows", () => {
    // START_USER_CODE-USER_grpbxArrows_TEST
    // END_USER_CODE-USER_grpbxArrows_TEST
  });
  test("grpbxBalance(GroupBox Widget) Test Cases", async () => {
    const grpbxBalance = screen.getByTestId("grpbxBalance");
    expect(grpbxBalance.tagName).toBe("BUTTON");
    expect(grpbxBalance.type).toBe("button");
    expect(grpbxBalance.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBalance", () => {
    // START_USER_CODE-USER_grpbxBalance_TEST
    // END_USER_CODE-USER_grpbxBalance_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxNoteChck(GroupBox Widget) Test Cases", async () => {
    const grpbxNoteChck = screen.getByTestId("grpbxNoteChck");
    expect(grpbxNoteChck.tagName).toBe("BUTTON");
    expect(grpbxNoteChck.type).toBe("button");
    expect(grpbxNoteChck.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxNoteChck", () => {
    // START_USER_CODE-USER_grpbxNoteChck_TEST
    // END_USER_CODE-USER_grpbxNoteChck_TEST
  });
  test("grpbxReadyToPay(GroupBox Widget) Test Cases", async () => {
    const grpbxReadyToPay = screen.getByTestId("grpbxReadyToPay");
    expect(grpbxReadyToPay.tagName).toBe("BUTTON");
    expect(grpbxReadyToPay.type).toBe("button");
    expect(grpbxReadyToPay.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReadyToPay", () => {
    // START_USER_CODE-USER_grpbxReadyToPay_TEST
    // END_USER_CODE-USER_grpbxReadyToPay_TEST
  });
  test("grpbxRemit(GroupBox Widget) Test Cases", async () => {
    const grpbxRemit = screen.getByTestId("grpbxRemit");
    expect(grpbxRemit.tagName).toBe("BUTTON");
    expect(grpbxRemit.type).toBe("button");
    expect(grpbxRemit.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRemit", () => {
    // START_USER_CODE-USER_grpbxRemit_TEST
    // END_USER_CODE-USER_grpbxRemit_TEST
  });
  test("labelwidget49(Label Widget) Test Cases", async () => {
    const labelwidget49 = screen.getByTestId("labelwidget49");
    expect(labelwidget49.tagName).toBe("LABEL");
    expect(labelwidget49.classList).toContain("form-label");
    expect(labelwidget49.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_labelwidget49")
    );
  });
  test("Custom Test Cases for labelwidget49", () => {
    // START_USER_CODE-USER_labelwidget49_TEST
    // END_USER_CODE-USER_labelwidget49_TEST
  });
  test("lblARBalance(Label Widget) Test Cases", async () => {
    const lblARBalance = screen.getByTestId("lblARBalance");
    expect(lblARBalance.tagName).toBe("LABEL");
    expect(lblARBalance.classList).toContain("form-label");
    expect(lblARBalance.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblARBalance")
    );
  });
  test("Custom Test Cases for lblARBalance", () => {
    // START_USER_CODE-USER_lblARBalance_TEST
    // END_USER_CODE-USER_lblARBalance_TEST
  });
  test("lblBalance(Label Widget) Test Cases", async () => {
    const lblBalance = screen.getByTestId("lblBalance");
    expect(lblBalance.tagName).toBe("LABEL");
    expect(lblBalance.classList).toContain("form-label");
    expect(lblBalance.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblBalance")
    );
  });
  test("Custom Test Cases for lblBalance", () => {
    // START_USER_CODE-USER_lblBalance_TEST
    // END_USER_CODE-USER_lblBalance_TEST
  });
  test("lblCorpYear2(Label Widget) Test Cases", async () => {
    const lblCorpYear2 = screen.getByTestId("lblCorpYear2");
    expect(lblCorpYear2.tagName).toBe("LABEL");
    expect(lblCorpYear2.classList).toContain("form-label");
    expect(lblCorpYear2.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblCorpYear2")
    );
  });
  test("Custom Test Cases for lblCorpYear2", () => {
    // START_USER_CODE-USER_lblCorpYear2_TEST
    // END_USER_CODE-USER_lblCorpYear2_TEST
  });
  test("lblCRBalance(Label Widget) Test Cases", async () => {
    const lblCRBalance = screen.getByTestId("lblCRBalance");
    expect(lblCRBalance.tagName).toBe("LABEL");
    expect(lblCRBalance.classList).toContain("form-label");
    expect(lblCRBalance.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblCRBalance")
    );
  });
  test("Custom Test Cases for lblCRBalance", () => {
    // START_USER_CODE-USER_lblCRBalance_TEST
    // END_USER_CODE-USER_lblCRBalance_TEST
  });
  test("lblLienholders(Label Widget) Test Cases", async () => {
    const lblLienholders = screen.getByTestId("lblLienholders");
    expect(lblLienholders.tagName).toBe("LABEL");
    expect(lblLienholders.classList).toContain("form-label");
    expect(lblLienholders.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblLienholders")
    );
  });
  test("Custom Test Cases for lblLienholders", () => {
    // START_USER_CODE-USER_lblLienholders_TEST
    // END_USER_CODE-USER_lblLienholders_TEST
  });
  test("lblLienholdersValue(Label Widget) Test Cases", async () => {
    const lblLienholdersValue = screen.getByTestId("lblLienholdersValue");
    expect(lblLienholdersValue.tagName).toBe("LABEL");
    expect(lblLienholdersValue.classList).toContain("form-label");
    expect(lblLienholdersValue.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblLienholdersValue")
    );
  });
  test("Custom Test Cases for lblLienholdersValue", () => {
    // START_USER_CODE-USER_lblLienholdersValue_TEST
    // END_USER_CODE-USER_lblLienholdersValue_TEST
  });
  test("lblNOTE(Label Widget) Test Cases", async () => {
    const lblNOTE = screen.getByTestId("lblNOTE");
    expect(lblNOTE.tagName).toBe("LABEL");
    expect(lblNOTE.classList).toContain("form-label");
    expect(lblNOTE.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblNOTE")
    );
  });
  test("Custom Test Cases for lblNOTE", () => {
    // START_USER_CODE-USER_lblNOTE_TEST
    // END_USER_CODE-USER_lblNOTE_TEST
  });
  test("lblRTPsHold(Label Widget) Test Cases", async () => {
    const lblRTPsHold = screen.getByTestId("lblRTPsHold");
    expect(lblRTPsHold.tagName).toBe("LABEL");
    expect(lblRTPsHold.classList).toContain("form-label");
    expect(lblRTPsHold.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblRTPsHold")
    );
  });
  test("Custom Test Cases for lblRTPsHold", () => {
    // START_USER_CODE-USER_lblRTPsHold_TEST
    // END_USER_CODE-USER_lblRTPsHold_TEST
  });
  test("lblRTPsHoldValue(Label Widget) Test Cases", async () => {
    const lblRTPsHoldValue = screen.getByTestId("lblRTPsHoldValue");
    expect(lblRTPsHoldValue.tagName).toBe("LABEL");
    expect(lblRTPsHoldValue.classList).toContain("form-label");
    expect(lblRTPsHoldValue.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblRTPsHoldValue")
    );
  });
  test("Custom Test Cases for lblRTPsHoldValue", () => {
    // START_USER_CODE-USER_lblRTPsHoldValue_TEST
    // END_USER_CODE-USER_lblRTPsHoldValue_TEST
  });
  test("lblRTPsReleased(Label Widget) Test Cases", async () => {
    const lblRTPsReleased = screen.getByTestId("lblRTPsReleased");
    expect(lblRTPsReleased.tagName).toBe("LABEL");
    expect(lblRTPsReleased.classList).toContain("form-label");
    expect(lblRTPsReleased.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblRTPsReleased")
    );
  });
  test("Custom Test Cases for lblRTPsReleased", () => {
    // START_USER_CODE-USER_lblRTPsReleased_TEST
    // END_USER_CODE-USER_lblRTPsReleased_TEST
  });
  test("lblRTPsReleasedValue(Label Widget) Test Cases", async () => {
    const lblRTPsReleasedValue = screen.getByTestId("lblRTPsReleasedValue");
    expect(lblRTPsReleasedValue.tagName).toBe("LABEL");
    expect(lblRTPsReleasedValue.classList).toContain("form-label");
    expect(lblRTPsReleasedValue.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblRTPsReleasedValue")
    );
  });
  test("Custom Test Cases for lblRTPsReleasedValue", () => {
    // START_USER_CODE-USER_lblRTPsReleasedValue_TEST
    // END_USER_CODE-USER_lblRTPsReleasedValue_TEST
  });
  test("lblSaveNote(Label Widget) Test Cases", async () => {
    const lblSaveNote = screen.getByTestId("lblSaveNote");
    expect(lblSaveNote.tagName).toBe("LABEL");
    expect(lblSaveNote.classList).toContain("form-label");
    expect(lblSaveNote.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblSaveNote")
    );
  });
  test("Custom Test Cases for lblSaveNote", () => {
    // START_USER_CODE-USER_lblSaveNote_TEST
    // END_USER_CODE-USER_lblSaveNote_TEST
  });
  test("lblSRBalance(Label Widget) Test Cases", async () => {
    const lblSRBalance = screen.getByTestId("lblSRBalance");
    expect(lblSRBalance.tagName).toBe("LABEL");
    expect(lblSRBalance.classList).toContain("form-label");
    expect(lblSRBalance.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblSRBalance")
    );
  });
  test("Custom Test Cases for lblSRBalance", () => {
    // START_USER_CODE-USER_lblSRBalance_TEST
    // END_USER_CODE-USER_lblSRBalance_TEST
  });
  test("lblValOfRTP(Label Widget) Test Cases", async () => {
    const lblValOfRTP = screen.getByTestId("lblValOfRTP");
    expect(lblValOfRTP.tagName).toBe("LABEL");
    expect(lblValOfRTP.classList).toContain("form-label");
    expect(lblValOfRTP.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblValOfRTP")
    );
  });
  test("Custom Test Cases for lblValOfRTP", () => {
    // START_USER_CODE-USER_lblValOfRTP_TEST
    // END_USER_CODE-USER_lblValOfRTP_TEST
  });
  test("lblValOfRTPValue(Label Widget) Test Cases", async () => {
    const lblValOfRTPValue = screen.getByTestId("lblValOfRTPValue");
    expect(lblValOfRTPValue.tagName).toBe("LABEL");
    expect(lblValOfRTPValue.classList).toContain("form-label");
    expect(lblValOfRTPValue.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblValOfRTPValue")
    );
  });
  test("Custom Test Cases for lblValOfRTPValue", () => {
    // START_USER_CODE-USER_lblValOfRTPValue_TEST
    // END_USER_CODE-USER_lblValOfRTPValue_TEST
  });
  test("lblValueOfCheck(Label Widget) Test Cases", async () => {
    const lblValueOfCheck = screen.getByTestId("lblValueOfCheck");
    expect(lblValueOfCheck.tagName).toBe("LABEL");
    expect(lblValueOfCheck.classList).toContain("form-label");
    expect(lblValueOfCheck.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblValueOfCheck")
    );
  });
  test("Custom Test Cases for lblValueOfCheck", () => {
    // START_USER_CODE-USER_lblValueOfCheck_TEST
    // END_USER_CODE-USER_lblValueOfCheck_TEST
  });
  test("lblValueOfCheckValue(Label Widget) Test Cases", async () => {
    const lblValueOfCheckValue = screen.getByTestId("lblValueOfCheckValue");
    expect(lblValueOfCheckValue.tagName).toBe("LABEL");
    expect(lblValueOfCheckValue.classList).toContain("form-label");
    expect(lblValueOfCheckValue.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_lblValueOfCheckValue")
    );
  });
  test("Custom Test Cases for lblValueOfCheckValue", () => {
    // START_USER_CODE-USER_lblValueOfCheckValue_TEST
    // END_USER_CODE-USER_lblValueOfCheckValue_TEST
  });
  test("txtCarrierAbbr(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbr = screen.getByTestId("txtCarrierAbbr");
    expect(txtCarrierAbbr.tagName).toBe("INPUT");
    expect(txtCarrierAbbr.type).toBe("text");
    expect(txtCarrierAbbr.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbr.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtCarrierAbbr")
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbr, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbr", () => {
    // START_USER_CODE-USER_txtCarrierAbbr_TEST
    // END_USER_CODE-USER_txtCarrierAbbr_TEST
  });
  test("txtCheck(Textbox Widget) Test Cases", async () => {
    const txtCheck = screen.getByTestId("txtCheck");
    expect(txtCheck.tagName).toBe("INPUT");
    expect(txtCheck.type).toBe("text");
    expect(txtCheck.classList).toContain("textboxWidgetClass");
    expect(txtCheck.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtCheck")
    );
    await act(async () => {
      userEvent.type(txtCheck, "1");
    });
  });
  test("Custom Test Cases for txtCheck", () => {
    // START_USER_CODE-USER_txtCheck_TEST
    // END_USER_CODE-USER_txtCheck_TEST
  });
  test("gridReadyToPayHold_txtcolDateH(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolDateH = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolDateHbtn =
      gridReadyToPayHold_txtcolDateH.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolDateH =
      gridReadyToPayHold_txtcolDateH.parentElement.parentElement.parentElement;
    expect(gridReadyToPayHold_txtcolDateH.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolDateH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateH", () => {
    // START_USER_CODE-USER_txtcolDateH_TEST
    // END_USER_CODE-USER_txtcolDateH_TEST
  });
  test("gridReadyToPayReleased_txtcolDateR(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolDateR = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolDateRbtn =
      gridReadyToPayReleased_txtcolDateR.nextElementSibling.firstElementChild;
    gridReadyToPayReleased_txtcolDateR =
      gridReadyToPayReleased_txtcolDateR.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolDateR.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolDateR.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateR", () => {
    // START_USER_CODE-USER_txtcolDateR_TEST
    // END_USER_CODE-USER_txtcolDateR_TEST
  });
  test("txtCollectAR(Textbox Widget) Test Cases", async () => {
    const txtCollectAR = screen.getByTestId("txtCollectAR");
    expect(txtCollectAR.tagName).toBe("INPUT");
    expect(txtCollectAR.type).toBe("text");
    expect(txtCollectAR.classList).toContain("textboxWidgetClass");
    expect(txtCollectAR.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtCollectAR")
    );
    await act(async () => {
      userEvent.type(txtCollectAR, "1");
    });
  });
  test("Custom Test Cases for txtCollectAR", () => {
    // START_USER_CODE-USER_txtCollectAR_TEST
    // END_USER_CODE-USER_txtCollectAR_TEST
  });
  test("txtCollectCR(Textbox Widget) Test Cases", async () => {
    const txtCollectCR = screen.getByTestId("txtCollectCR");
    expect(txtCollectCR.tagName).toBe("INPUT");
    expect(txtCollectCR.type).toBe("text");
    expect(txtCollectCR.classList).toContain("textboxWidgetClass");
    expect(txtCollectCR.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtCollectCR")
    );
    await act(async () => {
      userEvent.type(txtCollectCR, "1");
    });
  });
  test("Custom Test Cases for txtCollectCR", () => {
    // START_USER_CODE-USER_txtCollectCR_TEST
    // END_USER_CODE-USER_txtCollectCR_TEST
  });
  test("txtCollectSR(Textbox Widget) Test Cases", async () => {
    const txtCollectSR = screen.getByTestId("txtCollectSR");
    expect(txtCollectSR.tagName).toBe("INPUT");
    expect(txtCollectSR.type).toBe("text");
    expect(txtCollectSR.classList).toContain("textboxWidgetClass");
    expect(txtCollectSR.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtCollectSR")
    );
    await act(async () => {
      userEvent.type(txtCollectSR, "1");
    });
  });
  test("Custom Test Cases for txtCollectSR", () => {
    // START_USER_CODE-USER_txtCollectSR_TEST
    // END_USER_CODE-USER_txtCollectSR_TEST
  });
  test("gridReadyToPayHold_txtcolNumber1H(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolNumber1H = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolNumber1Hbtn =
      gridReadyToPayHold_txtcolNumber1H.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolNumber1H =
      gridReadyToPayHold_txtcolNumber1H.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayHold_txtcolNumber1H.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolNumber1H.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1H", () => {
    // START_USER_CODE-USER_txtcolNumber1H_TEST
    // END_USER_CODE-USER_txtcolNumber1H_TEST
  });
  test("gridReadyToPayReleased_txtcolNumber1R(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolNumber1R = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolNumber1Rbtn =
      gridReadyToPayReleased_txtcolNumber1R.nextElementSibling
        .firstElementChild;
    gridReadyToPayReleased_txtcolNumber1R =
      gridReadyToPayReleased_txtcolNumber1R.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolNumber1R.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolNumber1R.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1R", () => {
    // START_USER_CODE-USER_txtcolNumber1R_TEST
    // END_USER_CODE-USER_txtcolNumber1R_TEST
  });
  test("gridReadyToPayHold_txtcolNumber2H(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolNumber2H = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolNumber2Hbtn =
      gridReadyToPayHold_txtcolNumber2H.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolNumber2H =
      gridReadyToPayHold_txtcolNumber2H.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayHold_txtcolNumber2H.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolNumber2H.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2H", () => {
    // START_USER_CODE-USER_txtcolNumber2H_TEST
    // END_USER_CODE-USER_txtcolNumber2H_TEST
  });
  test("gridReadyToPayReleased_txtcolNumber2R(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolNumber2R = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolNumber2Rbtn =
      gridReadyToPayReleased_txtcolNumber2R.nextElementSibling
        .firstElementChild;
    gridReadyToPayReleased_txtcolNumber2R =
      gridReadyToPayReleased_txtcolNumber2R.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolNumber2R.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolNumber2R.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2R", () => {
    // START_USER_CODE-USER_txtcolNumber2R_TEST
    // END_USER_CODE-USER_txtcolNumber2R_TEST
  });
  test("gridReadyToPayHold_txtcolOptH(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolOptH = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolOptHbtn =
      gridReadyToPayHold_txtcolOptH.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolOptH =
      gridReadyToPayHold_txtcolOptH.parentElement.parentElement.parentElement;
    expect(gridReadyToPayHold_txtcolOptH.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolOptH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOptH", () => {
    // START_USER_CODE-USER_txtcolOptH_TEST
    // END_USER_CODE-USER_txtcolOptH_TEST
  });
  test("gridReadyToPayReleased_txtcolOptR(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolOptR = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolOptRbtn =
      gridReadyToPayReleased_txtcolOptR.nextElementSibling.firstElementChild;
    gridReadyToPayReleased_txtcolOptR =
      gridReadyToPayReleased_txtcolOptR.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolOptR.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolOptR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOptR", () => {
    // START_USER_CODE-USER_txtcolOptR_TEST
    // END_USER_CODE-USER_txtcolOptR_TEST
  });
  test("gridReadyToPayHold_txtcolProceedsH(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolProceedsH = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolProceedsHbtn =
      gridReadyToPayHold_txtcolProceedsH.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolProceedsH =
      gridReadyToPayHold_txtcolProceedsH.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayHold_txtcolProceedsH.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolProceedsH.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceedsH", () => {
    // START_USER_CODE-USER_txtcolProceedsH_TEST
    // END_USER_CODE-USER_txtcolProceedsH_TEST
  });
  test("gridReadyToPayReleased_txtcolProceedsR(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolProceedsR = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolProceedsRbtn =
      gridReadyToPayReleased_txtcolProceedsR.nextElementSibling
        .firstElementChild;
    gridReadyToPayReleased_txtcolProceedsR =
      gridReadyToPayReleased_txtcolProceedsR.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolProceedsR.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolProceedsR.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceedsR", () => {
    // START_USER_CODE-USER_txtcolProceedsR_TEST
    // END_USER_CODE-USER_txtcolProceedsR_TEST
  });
  test("gridReadyToPayHold_txtcolRemitH(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolRemitH = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolRemitHbtn =
      gridReadyToPayHold_txtcolRemitH.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolRemitH =
      gridReadyToPayHold_txtcolRemitH.parentElement.parentElement.parentElement;
    expect(gridReadyToPayHold_txtcolRemitH.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolRemitH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRemitH", () => {
    // START_USER_CODE-USER_txtcolRemitH_TEST
    // END_USER_CODE-USER_txtcolRemitH_TEST
  });
  test("gridReadyToPayReleased_txtcolRemitR(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolRemitR = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolRemitRbtn =
      gridReadyToPayReleased_txtcolRemitR.nextElementSibling.firstElementChild;
    gridReadyToPayReleased_txtcolRemitR =
      gridReadyToPayReleased_txtcolRemitR.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolRemitR.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolRemitR.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRemitR", () => {
    // START_USER_CODE-USER_txtcolRemitR_TEST
    // END_USER_CODE-USER_txtcolRemitR_TEST
  });
  test("gridReadyToPayHold_txtcolSetlDateH(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolSetlDateH = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolSetlDateHbtn =
      gridReadyToPayHold_txtcolSetlDateH.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolSetlDateH =
      gridReadyToPayHold_txtcolSetlDateH.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayHold_txtcolSetlDateH.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolSetlDateH.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSetlDateH", () => {
    // START_USER_CODE-USER_txtcolSetlDateH_TEST
    // END_USER_CODE-USER_txtcolSetlDateH_TEST
  });
  test("gridReadyToPayReleased_txtcolSetlDateR(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolSetlDateR = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolSetlDateRbtn =
      gridReadyToPayReleased_txtcolSetlDateR.nextElementSibling
        .firstElementChild;
    gridReadyToPayReleased_txtcolSetlDateR =
      gridReadyToPayReleased_txtcolSetlDateR.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolSetlDateR.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolSetlDateR.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSetlDateR", () => {
    // START_USER_CODE-USER_txtcolSetlDateR_TEST
    // END_USER_CODE-USER_txtcolSetlDateR_TEST
  });
  test("gridReadyToPayHold_txtcolType1H(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolType1H = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolType1Hbtn =
      gridReadyToPayHold_txtcolType1H.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolType1H =
      gridReadyToPayHold_txtcolType1H.parentElement.parentElement.parentElement;
    expect(gridReadyToPayHold_txtcolType1H.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolType1H.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1H", () => {
    // START_USER_CODE-USER_txtcolType1H_TEST
    // END_USER_CODE-USER_txtcolType1H_TEST
  });
  test("gridReadyToPayReleased_txtcolType1R(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolType1R = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolType1Rbtn =
      gridReadyToPayReleased_txtcolType1R.nextElementSibling.firstElementChild;
    gridReadyToPayReleased_txtcolType1R =
      gridReadyToPayReleased_txtcolType1R.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolType1R.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolType1R.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1R", () => {
    // START_USER_CODE-USER_txtcolType1R_TEST
    // END_USER_CODE-USER_txtcolType1R_TEST
  });
  test("gridReadyToPayHold_txtcolType2H(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolType2H = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolType2Hbtn =
      gridReadyToPayHold_txtcolType2H.nextElementSibling.firstElementChild;
    gridReadyToPayHold_txtcolType2H =
      gridReadyToPayHold_txtcolType2H.parentElement.parentElement.parentElement;
    expect(gridReadyToPayHold_txtcolType2H.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolType2H.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2H", () => {
    // START_USER_CODE-USER_txtcolType2H_TEST
    // END_USER_CODE-USER_txtcolType2H_TEST
  });
  test("gridReadyToPayReleased_txtcolType2R(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolType2R = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolType2Rbtn =
      gridReadyToPayReleased_txtcolType2R.nextElementSibling.firstElementChild;
    gridReadyToPayReleased_txtcolType2R =
      gridReadyToPayReleased_txtcolType2R.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolType2R.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolType2R.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2R", () => {
    // START_USER_CODE-USER_txtcolType2R_TEST
    // END_USER_CODE-USER_txtcolType2R_TEST
  });
  test("gridReadyToPayHold_txtcolWireXferDataH(Grid Widget) Test Cases", async () => {
    let gridReadyToPayHold_txtcolWireXferDataH = screen.getByTestId(
      "gridReadyToPayHold"
    );
    let gridReadyToPayHold_txtcolWireXferDataHbtn =
      gridReadyToPayHold_txtcolWireXferDataH.nextElementSibling
        .firstElementChild;
    gridReadyToPayHold_txtcolWireXferDataH =
      gridReadyToPayHold_txtcolWireXferDataH.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayHold_txtcolWireXferDataH.tagName).toBe("DIV");
    expect(gridReadyToPayHold_txtcolWireXferDataH.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayHold")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWireXferDataH", () => {
    // START_USER_CODE-USER_txtcolWireXferDataH_TEST
    // END_USER_CODE-USER_txtcolWireXferDataH_TEST
  });
  test("gridReadyToPayReleased_txtcolWireXferDataR(Grid Widget) Test Cases", async () => {
    let gridReadyToPayReleased_txtcolWireXferDataR = screen.getByTestId(
      "gridReadyToPayReleased"
    );
    let gridReadyToPayReleased_txtcolWireXferDataRbtn =
      gridReadyToPayReleased_txtcolWireXferDataR.nextElementSibling
        .firstElementChild;
    gridReadyToPayReleased_txtcolWireXferDataR =
      gridReadyToPayReleased_txtcolWireXferDataR.parentElement.parentElement
        .parentElement;
    expect(gridReadyToPayReleased_txtcolWireXferDataR.tagName).toBe("DIV");
    expect(gridReadyToPayReleased_txtcolWireXferDataR.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReadyToPayRelease_gridReadyToPayReleased")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWireXferDataR", () => {
    // START_USER_CODE-USER_txtcolWireXferDataR_TEST
    // END_USER_CODE-USER_txtcolWireXferDataR_TEST
  });
  test("txtEndCheckNum(Textbox Widget) Test Cases", async () => {
    const txtEndCheckNum = screen.getByTestId("txtEndCheckNum");
    expect(txtEndCheckNum.tagName).toBe("INPUT");
    expect(txtEndCheckNum.type).toBe("text");
    expect(txtEndCheckNum.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEndCheckNum, "1");
    });
  });
  test("Custom Test Cases for txtEndCheckNum", () => {
    // START_USER_CODE-USER_txtEndCheckNum_TEST
    // END_USER_CODE-USER_txtEndCheckNum_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("AccountPayment:ReadyToPayRelease_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
