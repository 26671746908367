/* eslint-disable*/
import React from "react";
import ContractManagement_ContractGrpSelect from "./ContractGrpSelect";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractGrpSelect Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractGrpSelect />);
    });
  });
  afterEach(cleanup);
  test("is ContractGrpSelect Loads Successfully", () => {
    expect(screen.getByText("ContractGrpSelect")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractGrpSelect", () => {
    // START_USER_CODE-USER_ContractGrpSelect_Custom_Test_Case
    // END_USER_CODE-USER_ContractGrpSelect_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractGrpSelect />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:ContractGrpSelect_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("ContractManagement:ContractGrpSelect_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnSelect(Button Widget) Test Cases", async () => {
    const btnSelect = screen.getByTestId("btnSelect");
    expect(btnSelect).toBeInTheDocument;
    expect(btnSelect.textContent).toEqual(
      t("ContractManagement:ContractGrpSelect_btnSelect")
    );
  });
  test("Custom Test Cases for btnSelect", () => {
    // START_USER_CODE-USER_btnSelect_TEST
    // END_USER_CODE-USER_btnSelect_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("ContractManagement:ContractGrpSelect_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridSelectCntrcts(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrctsbtn =
      gridSelectCntrcts.nextElementSibling.firstElementChild;
    gridSelectCntrcts =
      gridSelectCntrcts.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts.tagName).toBe("DIV");
    expect(gridSelectCntrcts.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSelectCntrcts", () => {
    // START_USER_CODE-USER_gridSelectCntrcts_TEST
    // END_USER_CODE-USER_gridSelectCntrcts_TEST
  });
  test("grpbxClose(GroupBox Widget) Test Cases", async () => {
    const grpbxClose = screen.getByTestId("grpbxClose");
    expect(grpbxClose.tagName).toBe("BUTTON");
    expect(grpbxClose.type).toBe("button");
    expect(grpbxClose.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxClose", () => {
    // START_USER_CODE-USER_grpbxClose_TEST
    // END_USER_CODE-USER_grpbxClose_TEST
  });
  test("grpbxSelectCntrct(GroupBox Widget) Test Cases", async () => {
    const grpbxSelectCntrct = screen.getByTestId("grpbxSelectCntrct");
    expect(grpbxSelectCntrct.tagName).toBe("BUTTON");
    expect(grpbxSelectCntrct.type).toBe("button");
    expect(grpbxSelectCntrct.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSelectCntrct", () => {
    // START_USER_CODE-USER_grpbxSelectCntrct_TEST
    // END_USER_CODE-USER_grpbxSelectCntrct_TEST
  });
  test("grpbxSrch(GroupBox Widget) Test Cases", async () => {
    const grpbxSrch = screen.getByTestId("grpbxSrch");
    expect(grpbxSrch.tagName).toBe("BUTTON");
    expect(grpbxSrch.type).toBe("button");
    expect(grpbxSrch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSrch", () => {
    // START_USER_CODE-USER_grpbxSrch_TEST
    // END_USER_CODE-USER_grpbxSrch_TEST
  });
  test("gridSelectCntrcts_txt1(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt1 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt1btn =
      gridSelectCntrcts_txt1.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt1 =
      gridSelectCntrcts_txt1.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt1.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt1", () => {
    // START_USER_CODE-USER_txt1_TEST
    // END_USER_CODE-USER_txt1_TEST
  });
  test("gridSelectCntrcts_txt2(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt2 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt2btn =
      gridSelectCntrcts_txt2.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt2 =
      gridSelectCntrcts_txt2.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt2.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt2", () => {
    // START_USER_CODE-USER_txt2_TEST
    // END_USER_CODE-USER_txt2_TEST
  });
  test("gridSelectCntrcts_txt3(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt3 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt3btn =
      gridSelectCntrcts_txt3.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt3 =
      gridSelectCntrcts_txt3.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt3.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt3", () => {
    // START_USER_CODE-USER_txt3_TEST
    // END_USER_CODE-USER_txt3_TEST
  });
  test("gridSelectCntrcts_txt4(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt4 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt4btn =
      gridSelectCntrcts_txt4.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt4 =
      gridSelectCntrcts_txt4.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt4.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt4.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt4", () => {
    // START_USER_CODE-USER_txt4_TEST
    // END_USER_CODE-USER_txt4_TEST
  });
  test("gridSelectCntrcts_txt5(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt5 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt5btn =
      gridSelectCntrcts_txt5.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt5 =
      gridSelectCntrcts_txt5.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt5.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt5.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt5", () => {
    // START_USER_CODE-USER_txt5_TEST
    // END_USER_CODE-USER_txt5_TEST
  });
  test("gridSelectCntrcts_txt6(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt6 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt6btn =
      gridSelectCntrcts_txt6.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt6 =
      gridSelectCntrcts_txt6.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt6.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt6.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt6", () => {
    // START_USER_CODE-USER_txt6_TEST
    // END_USER_CODE-USER_txt6_TEST
  });
  test("gridSelectCntrcts_txt7(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt7 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt7btn =
      gridSelectCntrcts_txt7.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt7 =
      gridSelectCntrcts_txt7.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt7.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt7.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt7", () => {
    // START_USER_CODE-USER_txt7_TEST
    // END_USER_CODE-USER_txt7_TEST
  });
  test("gridSelectCntrcts_txt8(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt8 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt8btn =
      gridSelectCntrcts_txt8.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt8 =
      gridSelectCntrcts_txt8.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt8.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt8.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt8", () => {
    // START_USER_CODE-USER_txt8_TEST
    // END_USER_CODE-USER_txt8_TEST
  });
  test("gridSelectCntrcts_txt9(Grid Widget) Test Cases", async () => {
    let gridSelectCntrcts_txt9 = screen.getByTestId("gridSelectCntrcts");
    let gridSelectCntrcts_txt9btn =
      gridSelectCntrcts_txt9.nextElementSibling.firstElementChild;
    gridSelectCntrcts_txt9 =
      gridSelectCntrcts_txt9.parentElement.parentElement.parentElement;
    expect(gridSelectCntrcts_txt9.tagName).toBe("DIV");
    expect(gridSelectCntrcts_txt9.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGrpSelect_gridSelectCntrcts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt9", () => {
    // START_USER_CODE-USER_txt9_TEST
    // END_USER_CODE-USER_txt9_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGrpSelect_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtOleic(Textbox Widget) Test Cases", async () => {
    const txtOleic = screen.getByTestId("txtOleic");
    expect(txtOleic.tagName).toBe("INPUT");
    expect(txtOleic.type).toBe("text");
    expect(txtOleic.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOleic, "1");
    });
  });
  test("Custom Test Cases for txtOleic", () => {
    // START_USER_CODE-USER_txtOleic_TEST
    // END_USER_CODE-USER_txtOleic_TEST
  });
  test("txtOrganic(Textbox Widget) Test Cases", async () => {
    const txtOrganic = screen.getByTestId("txtOrganic");
    expect(txtOrganic.tagName).toBe("INPUT");
    expect(txtOrganic.type).toBe("text");
    expect(txtOrganic.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOrganic, "1");
    });
  });
  test("Custom Test Cases for txtOrganic", () => {
    // START_USER_CODE-USER_txtOrganic_TEST
    // END_USER_CODE-USER_txtOrganic_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPeanutType, "1");
    });
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPeanutVariety(Textbox Widget) Test Cases", async () => {
    const txtPeanutVariety = screen.getByTestId("txtPeanutVariety");
    expect(txtPeanutVariety.tagName).toBe("INPUT");
    expect(txtPeanutVariety.type).toBe("text");
    expect(txtPeanutVariety.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPeanutVariety, "1");
    });
  });
  test("Custom Test Cases for txtPeanutVariety", () => {
    // START_USER_CODE-USER_txtPeanutVariety_TEST
    // END_USER_CODE-USER_txtPeanutVariety_TEST
  });
  test("txtSeedGrower(Textbox Widget) Test Cases", async () => {
    const txtSeedGrower = screen.getByTestId("txtSeedGrower");
    expect(txtSeedGrower.tagName).toBe("INPUT");
    expect(txtSeedGrower.type).toBe("text");
    expect(txtSeedGrower.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSeedGrower, "1");
    });
  });
  test("Custom Test Cases for txtSeedGrower", () => {
    // START_USER_CODE-USER_txtSeedGrower_TEST
    // END_USER_CODE-USER_txtSeedGrower_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSeg, "1");
    });
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGrpSelect_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtXx(Textbox Widget) Test Cases", async () => {
    const txtXx = screen.getByTestId("txtXx");
    expect(txtXx.tagName).toBe("INPUT");
    expect(txtXx.type).toBe("text");
    expect(txtXx.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtXx, "123");
    });
    expect(txtXx.getAttribute("value")).toBe("");
    expect(txtXx.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtXx", () => {
    // START_USER_CODE-USER_txtXx_TEST
    // END_USER_CODE-USER_txtXx_TEST
  });
});
