/* eslint-disable*/
import React from "react";
import Test_RTL from "./Test_RTL";
import { screen, cleanup, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";
jest.setTimeout(30000);
describe("AllWidgetScreenReact Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Test_RTL />);
    });
  });
  afterEach(cleanup);
  test("is AllWidgetScreenReact Loads Successfully", () => {

  });
  test("Custom Test Cases for AllWidgetScreenReact", () => {

  });
});

describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Test_RTL />);
    });
  });
  afterEach(cleanup);
  test("gridwidget101_buttonwidget143(Grid Widget) Test Cases", async () => {
    let gridwidget101_buttonwidget143 = screen.getByTestId("gridwidget101");
    fireEvent.keyDown(
      gridwidget101_buttonwidget143,
      {
        key: "Home",
        code: "Home",
        keyCode: 36,
        charCode: 0,
      });
    let gridwidget101_buttonwidget143btn =
      gridwidget101_buttonwidget143.nextElementSibling.firstElementChild;
    gridwidget101_buttonwidget143 =
      gridwidget101_buttonwidget143.parentElement.parentElement.parentElement;
    expect(gridwidget101_buttonwidget143.tagName).toBe("DIV");
    expect(gridwidget101_buttonwidget143.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("translate:Test_RTL_gridwidget101"))
    ).toBeInTheDocument;
    const gridwidget101_buttonwidget143data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_buttonwidget143data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_buttonwidget143btn, { button: 0 });
      });
      const gridwidget101_buttonwidget143add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_buttonwidget143add, { button: 0 });
      });
    }
    const buttonwidget143 = screen.getByTestId("buttonwidget143");
    expect(buttonwidget143).toBeInTheDocument;
    expect(
      screen.getAllByText(t("translate:Test_RTL_buttonwidget143"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for buttonwidget143", () => {

  });
  test("gridwidget148_buttonwidget176(Grid Widget) Test Cases", async () => {
    let gridwidget148_buttonwidget176 = screen.getByTestId("gridwidget148");
    let gridwidget148_buttonwidget176btn =
      gridwidget148_buttonwidget176.nextElementSibling.firstElementChild;
    gridwidget148_buttonwidget176 =
      gridwidget148_buttonwidget176.parentElement.parentElement.parentElement;
    expect(gridwidget148_buttonwidget176.tagName).toBe("DIV");
    expect(gridwidget148_buttonwidget176.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("translate:Test_RTL_gridwidget148"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for buttonwidget176", () => {

  });
  test("buttonwidget196(Button Widget) Test Cases", async () => {
    const buttonwidget196 = screen.getByTestId("buttonwidget196");
    expect(buttonwidget196).toBeInTheDocument;
    expect(buttonwidget196.textContent).toEqual(
      t("translate:Test_RTL_buttonwidget196")
    );
  });
  test("Custom Test Cases for buttonwidget196", () => {

  });
  test("buttonwidget197(Button Widget) Test Cases", async () => {
    const buttonwidget197 = screen.getByTestId("buttonwidget197");
    expect(buttonwidget197).toBeInTheDocument;
    expect(buttonwidget197.textContent).toEqual(
      t("translate:Test_RTL_buttonwidget197")
    );
  });
  test("Custom Test Cases for buttonwidget197", () => {

  });
  test("buttonwidget36(Button Widget) Test Cases", async () => {
    const buttonwidget36 = screen.getByTestId("buttonwidget36");
    expect(buttonwidget36).toBeInTheDocument;
    expect(buttonwidget36.textContent).toEqual(
      t("translate:Test_RTL_buttonwidget36")
    );
  });
  test("Custom Test Cases for buttonwidget36", () => {

    userEvent.click(buttonwidget36, { button: 0 });

  });
  test("datewidget12(Date Widget) Test Cases", async () => {
    const datewidget12 = screen.getByTestId("datewidget12");
    expect(datewidget12.tagName).toBe("INPUT");
    expect(datewidget12.type).toBe("text");
    expect(datewidget12.classList).toContain("datetimepicker-input");
    expect(
      datewidget12.parentElement.previousElementSibling.textContent
    ).toEqual(t("translate:Test_RTL_datewidget12"));
    await act(async () => {
      userEvent.click(datewidget12.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for datewidget12", () => {

  });
  test("gridwidget101_datewidget137(Grid Widget) Test Cases", async () => {
    let gridwidget101_datewidget137 = screen.getByTestId("gridwidget101");
    let gridwidget101_datewidget137btn =
      gridwidget101_datewidget137.nextElementSibling.firstElementChild;
    gridwidget101_datewidget137 =
      gridwidget101_datewidget137.parentElement.parentElement.parentElement;
    expect(gridwidget101_datewidget137.tagName).toBe("DIV");
    expect(gridwidget101_datewidget137.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("translate:Test_RTL_gridwidget101"))
    ).toBeInTheDocument;
    const gridwidget101_datewidget137data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_datewidget137data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_datewidget137btn, { button: 0 });
      });
      const gridwidget101_datewidget137add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_datewidget137add, { button: 0 });
      });
    }
    const datewidget137 = screen.getByTestId("gridwidget101.0.datewidget137");
    expect(datewidget137.tagName).toBe("INPUT");
    expect(datewidget137.type).toBe("text");
    expect(datewidget137.classList).toContain("datetimepicker-input");
    expect(
      datewidget137.parentElement.parentElement.getAttribute("title")
    ).toBe("ToolTip Test");
    expect(
      screen.getAllByText(t("translate:Test_RTL_datewidget137"))
    ).toBeInTheDocument;
    await act(async () => {
      userEvent.click(datewidget137.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for datewidget137", () => {

  });
  test("datewidget15(Date Widget) Test Cases", async () => {
    const datewidget15 = screen.getByTestId("datewidget15");
    expect(datewidget15.tagName).toBe("INPUT");
    expect(datewidget15.type).toBe("text");
    expect(datewidget15.classList).toContain("datetimepicker-input");
    expect(
      datewidget15.parentElement.previousElementSibling.textContent
    ).toEqual(t("translate:Test_RTL_datewidget15"));
    await act(async () => {
      userEvent.click(datewidget15.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for datewidget15", () => {

  });
  test("gridwidget148_datewidget172(Grid Widget) Test Cases", async () => {
    let gridwidget148_datewidget172 = screen.getByTestId("gridwidget148");
    let gridwidget148_datewidget172btn =
      gridwidget148_datewidget172.nextElementSibling.firstElementChild;
    gridwidget148_datewidget172 =
      gridwidget148_datewidget172.parentElement.parentElement.parentElement;
    expect(gridwidget148_datewidget172.tagName).toBe("DIV");
    expect(gridwidget148_datewidget172.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("translate:Test_RTL_gridwidget148"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for datewidget172", () => {

  });
  test("gridwidget101_datewidget203(Grid Widget) Test Cases", async () => {
    let gridwidget101_datewidget203 = screen.getByTestId("gridwidget101");
    let gridwidget101_datewidget203btn =
      gridwidget101_datewidget203.nextElementSibling.firstElementChild;
    gridwidget101_datewidget203 =
      gridwidget101_datewidget203.parentElement.parentElement.parentElement;
    expect(gridwidget101_datewidget203.tagName).toBe("DIV");
    expect(gridwidget101_datewidget203.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("translate:Test_RTL_gridwidget101"))
    ).toBeInTheDocument;
    const gridwidget101_datewidget203data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_datewidget203data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_datewidget203btn, { button: 0 });
      });
      const gridwidget101_datewidget203add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_datewidget203add, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for datewidget203", () => {

  });
  test("datewidget43(Date Widget) Test Cases", async () => {
    const datewidget43 = screen.getByTestId("datewidget43");
    expect(datewidget43.tagName).toBe("INPUT");
    expect(datewidget43.type).toBe("text");
    expect(datewidget43.classList).toContain("datetimepicker-input");
    expect(
      datewidget43.parentElement.previousElementSibling.textContent
    ).toEqual(t("translate:Test_RTL_datewidget43"));
    await act(async () => {
      userEvent.click(datewidget43.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for datewidget43", () => {

  });
  test("gridwidget101(Grid Widget) Test Cases", async () => {
    let gridwidget101 = screen.getByTestId("gridwidget101");
    let gridwidget101btn = gridwidget101.nextElementSibling.firstElementChild;
    gridwidget101 = gridwidget101.parentElement.parentElement.parentElement;
    expect(gridwidget101.tagName).toBe("DIV");
    expect(gridwidget101.classList).toContain("GridWidget");

    let gridwidget101navBtns =
      gridwidget101.firstChild.lastChild.firstChild.lastChild.lastChild
        .childNodes;
    gridwidget101navBtns.forEach(async gridwidget101button => {
      if (gridwidget101button.getAttribute("aria-disabled")) {
        await act(async () => {
          userEvent.click(button, { button: 0 });
        });
        setTimeout(() => {
          expect(spy).toHaveBeenCalledWith(
            "/ReactApp/CustomerController/findAllCustomers",
            expect.anything(),
            expect.anything()
          );
        }, 1000);
      }
    });
  });
  test("Custom Test Cases for gridwidget101", () => {

  });
  test("gridwidget148(Grid Widget) Test Cases", async () => {
    let gridwidget148 = screen.getByTestId("gridwidget148");
    let gridwidget148btn = gridwidget148.nextElementSibling.firstElementChild;
    gridwidget148 = gridwidget148.parentElement.parentElement.parentElement;
    expect(gridwidget148.tagName).toBe("DIV");
    expect(gridwidget148.classList).toContain("GridWidget");

    let gridwidget148navBtns =
      gridwidget148.firstChild.lastChild.firstChild.lastChild.lastChild
        .childNodes;
    gridwidget148navBtns.forEach(async gridwidget148button => {
      if (gridwidget148button.getAttribute("aria-disabled")) {
        await act(async () => {
          userEvent.click(button, { button: 0 });
        });
        setTimeout(() => {
          expect(spy).toHaveBeenCalledWith(
            "/ReactApp/CustomerController/findAllCustomers",
            expect.anything(),
            expect.anything()
          );
        }, 1000);
      }
    });
  });
  test("Custom Test Cases for gridwidget148", () => {

  });
  test("gridwidget45(Grid Widget) Test Cases", async () => {
    let gridwidget45 = screen.getByTestId("gridwidget45");
    let gridwidget45btn = gridwidget45.nextElementSibling.firstElementChild;
    gridwidget45 = gridwidget45.parentElement.parentElement.parentElement;
    expect(gridwidget45.tagName).toBe("DIV");
    expect(gridwidget45.classList).toContain("GridWidget");

  });
  test("Custom Test Cases for gridwidget45", () => {

  });
  test("groupboxwidget0(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget0 = screen.getByTestId("groupboxwidget0");
    expect(groupboxwidget0.tagName).toBe("BUTTON");
    expect(groupboxwidget0.type).toBe("button");
    expect(groupboxwidget0.classList).toContain("btn");
    expect(groupboxwidget0.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget0")
    );
  });
  test("Custom Test Cases for groupboxwidget0", () => {

  });
  test("groupboxwidget16(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget16 = screen.getByTestId("groupboxwidget16");
    expect(groupboxwidget16.tagName).toBe("BUTTON");
    expect(groupboxwidget16.type).toBe("button");
    expect(groupboxwidget16.classList).toContain("btn");
    expect(groupboxwidget16.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget16")
    );
  });
  test("Custom Test Cases for groupboxwidget16", () => {

  });
  test("groupboxwidget26(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget26 = screen.getByTestId("groupboxwidget26");
    expect(groupboxwidget26.tagName).toBe("BUTTON");
    expect(groupboxwidget26.type).toBe("button");
    expect(groupboxwidget26.classList).toContain("btn");
    expect(groupboxwidget26.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget26")
    );
  });
  test("Custom Test Cases for groupboxwidget26", () => {

  });
  test("groupboxwidget30(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget30 = screen.getByTestId("groupboxwidget30");
    expect(groupboxwidget30.tagName).toBe("BUTTON");
    expect(groupboxwidget30.type).toBe("button");
    expect(groupboxwidget30.classList).toContain("btn");
    expect(groupboxwidget30.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget30")
    );
  });
  test("Custom Test Cases for groupboxwidget30", () => {

  });
  test("groupboxwidget41(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget41 = screen.getByTestId("groupboxwidget41");
    expect(groupboxwidget41.tagName).toBe("BUTTON");
    expect(groupboxwidget41.type).toBe("button");
    expect(groupboxwidget41.classList).toContain("btn");
    expect(groupboxwidget41.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget41")
    );
  });
  test("Custom Test Cases for groupboxwidget41", () => {

  });
  test("groupboxwidget52(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget52 = screen.getByTestId("groupboxwidget52");
    expect(groupboxwidget52.tagName).toBe("BUTTON");
    expect(groupboxwidget52.type).toBe("button");
    expect(groupboxwidget52.classList).toContain("btn");
    expect(groupboxwidget52.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget52")
    );
  });
  test("Custom Test Cases for groupboxwidget52", () => {

  });
  test("groupboxwidget9(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget9 = screen.getByTestId("groupboxwidget9");
    expect(groupboxwidget9.tagName).toBe("BUTTON");
    expect(groupboxwidget9.type).toBe("button");
    expect(groupboxwidget9.classList).toContain("btn");
    expect(groupboxwidget9.textContent).toEqual(
      t("translate:Test_RTL_groupboxwidget9")
    );
  });
  test("Custom Test Cases for groupboxwidget9", () => {

  });
  test("gridwidget101_labelwidget127(Grid Widget) Test Cases", async () => {
    let gridwidget101_labelwidget127 = screen.getByTestId("gridwidget101");
    let gridwidget101_labelwidget127btn =
      gridwidget101_labelwidget127.nextElementSibling.firstElementChild;
    gridwidget101_labelwidget127 =
      gridwidget101_labelwidget127.parentElement.parentElement.parentElement;
    expect(gridwidget101_labelwidget127.tagName).toBe("DIV");
    expect(gridwidget101_labelwidget127.classList).toContain("GridWidget");

    const gridwidget101_labelwidget127data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_labelwidget127data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_labelwidget127btn, { button: 0 });
      });
      const gridwidget101_labelwidget127add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_labelwidget127add, { button: 0 });
      });
    }
    const labelwidget127 = screen.getByTestId("labelwidget127");
    expect(labelwidget127.tagName).toBe("DIV");

  });
  test("Custom Test Cases for labelwidget127", () => {

  });
  test("gridwidget148_labelwidget162(Grid Widget) Test Cases", async () => {
    let gridwidget148_labelwidget162 = screen.getByTestId("gridwidget148");
    let gridwidget148_labelwidget162btn =
      gridwidget148_labelwidget162.nextElementSibling.firstElementChild;
    gridwidget148_labelwidget162 =
      gridwidget148_labelwidget162.parentElement.parentElement.parentElement;
    expect(gridwidget148_labelwidget162.tagName).toBe("DIV");
    expect(gridwidget148_labelwidget162.classList).toContain("GridWidget");

  });
  test("Custom Test Cases for labelwidget162", () => {

  });
  test("labelwidget31(Label Widget) Test Cases", async () => {
    const labelwidget31 = screen.getByTestId("labelwidget31");
    expect(labelwidget31.tagName).toBe("LABEL");
    expect(labelwidget31.classList).toContain("form-label");
    expect(labelwidget31.textContent).toEqual(
      t("translate:Test_RTL_labelwidget31")
    );
  });
  test("Custom Test Cases for labelwidget31", () => {

  });
  test("gridwidget101_linkwidget125(Grid Widget) Test Cases", async () => {
    let gridwidget101_linkwidget125 = screen.getByTestId("gridwidget101");
    let gridwidget101_linkwidget125btn =
      gridwidget101_linkwidget125.nextElementSibling.firstElementChild;
    gridwidget101_linkwidget125 =
      gridwidget101_linkwidget125.parentElement.parentElement.parentElement;
    expect(gridwidget101_linkwidget125.tagName).toBe("DIV");
    expect(gridwidget101_linkwidget125.classList).toContain("GridWidget");

    const gridwidget101_linkwidget125data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_linkwidget125data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_linkwidget125btn, { button: 0 });
      });
      const gridwidget101_linkwidget125add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_linkwidget125add, { button: 0 });
      });
    }
    const linkwidget125 = screen.getByTestId("linkwidget125");
    expect(linkwidget125).toBeInTheDocument;

    await act(async () => {
      userEvent.click(linkwidget125, { button: 0 });
    });
  });
  test("Custom Test Cases for linkwidget125", () => {

  });
  test("gridwidget148_linkwidget160(Grid Widget) Test Cases", async () => {
    let gridwidget148_linkwidget160 = screen.getByTestId("gridwidget148");
    let gridwidget148_linkwidget160btn =
      gridwidget148_linkwidget160.nextElementSibling.firstElementChild;
    gridwidget148_linkwidget160 =
      gridwidget148_linkwidget160.parentElement.parentElement.parentElement;
    expect(gridwidget148_linkwidget160.tagName).toBe("DIV");
    expect(gridwidget148_linkwidget160.classList).toContain("GridWidget");

  });
  test("Custom Test Cases for linkwidget160", () => {

  });
  test("linkwidget32(Link Widget) Test Cases", async () => {
    const linkwidget32 = screen.getByTestId("linkwidget32");
    expect(linkwidget32).toBeInTheDocument;
    expect(linkwidget32.textContent).toEqual(
      t("translate:Test_RTL_linkwidget32")
    );
    await act(async () => {
      userEvent.click(linkwidget32, { button: 0 });
    });
  });
  test("Custom Test Cases for linkwidget32", () => {

  });
  test("textboxwidget1(Textbox Widget) Test Cases", async () => {
    const textboxwidget1 = screen.getByTestId("textboxwidget1");
    expect(textboxwidget1.tagName).toBe("INPUT");
    expect(textboxwidget1.type).toBe("text");
    expect(textboxwidget1.classList).toContain("textboxWidgetClass");
    expect(textboxwidget1.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget1")
    );
    await act(async () => {
      userEvent.type(textboxwidget1, "1");
    });

    fireEvent.keyDown(
      textboxwidget1,
      {
        key: "Enter",
        code: "Enter",
        keyCode: 13
      }
    );

  });
  test("Custom Test Cases for textboxwidget1", () => {

  });
  test("gridwidget101_textboxwidget109(Grid Widget) Test Cases", async () => {
    let gridwidget101_textboxwidget109 = screen.getByTestId("gridwidget101");
    let gridwidget101_textboxwidget109btn =
      gridwidget101_textboxwidget109.nextElementSibling.firstElementChild;
    gridwidget101_textboxwidget109 =
      gridwidget101_textboxwidget109.parentElement.parentElement.parentElement;
    expect(gridwidget101_textboxwidget109.tagName).toBe("DIV");
    expect(gridwidget101_textboxwidget109.classList).toContain("GridWidget");

    const gridwidget101_textboxwidget109data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_textboxwidget109data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget109btn, { button: 0 });
      });
      const gridwidget101_textboxwidget109add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget109add, { button: 0 });
      });
    }

  });
  test("Custom Test Cases for textboxwidget109", () => {

  });
  test("gridwidget148_textboxwidget154(Grid Widget) Test Cases", async () => {
    let gridwidget148_textboxwidget154 = screen.getByTestId("gridwidget148");
    let gridwidget148_textboxwidget154btn =
      gridwidget148_textboxwidget154.nextElementSibling.firstElementChild;
    gridwidget148_textboxwidget154 =
      gridwidget148_textboxwidget154.parentElement.parentElement.parentElement;
    expect(gridwidget148_textboxwidget154.tagName).toBe("DIV");
    expect(gridwidget148_textboxwidget154.classList).toContain("GridWidget");

  });
  test("Custom Test Cases for textboxwidget154", () => {

  });
  test("textboxwidget177(Textbox Widget) Test Cases", async () => {
    const textboxwidget177 = screen.getByTestId("textboxwidget177");
    expect(textboxwidget177.tagName).toBe("INPUT");
    expect(textboxwidget177.type).toBe("text");
    expect(textboxwidget177.classList).toContain("textboxWidgetClass");
    expect(textboxwidget177.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget177")
    );
    await act(async () => {
      userEvent.type(textboxwidget177, "1");
    });
    expect(textboxwidget177.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget177", () => {

  });
  test("textboxwidget178(Textbox Widget) Test Cases", async () => {
    const textboxwidget178 = screen.getByTestId("textboxwidget178");
    expect(textboxwidget178.tagName).toBe("INPUT");
    expect(textboxwidget178.type).toBe("text");
    expect(textboxwidget178.classList).toContain("textboxWidgetClass");
    expect(textboxwidget178.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget178")
    );
    await act(async () => {
      userEvent.type(textboxwidget178, "1");
    });
    expect(textboxwidget178.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget178", () => {

  });
  test("gridwidget101_textboxwidget180(Grid Widget) Test Cases", async () => {
    let gridwidget101_textboxwidget180 = screen.getByTestId("gridwidget101");
    let gridwidget101_textboxwidget180btn =
      gridwidget101_textboxwidget180.nextElementSibling.firstElementChild;
    gridwidget101_textboxwidget180 =
      gridwidget101_textboxwidget180.parentElement.parentElement.parentElement;
    expect(gridwidget101_textboxwidget180.tagName).toBe("DIV");
    expect(gridwidget101_textboxwidget180.classList).toContain("GridWidget");

    const gridwidget101_textboxwidget180data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_textboxwidget180data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget180btn, { button: 0 });
      });
      const gridwidget101_textboxwidget180add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget180add, { button: 0 });
      });
    }

  });
  test("Custom Test Cases for textboxwidget180", () => {

  });
  test("gridwidget101_textboxwidget182(Grid Widget) Test Cases", async () => {
    let gridwidget101_textboxwidget182 = screen.getByTestId("gridwidget101");
    let gridwidget101_textboxwidget182btn =
      gridwidget101_textboxwidget182.nextElementSibling.firstElementChild;
    gridwidget101_textboxwidget182 =
      gridwidget101_textboxwidget182.parentElement.parentElement.parentElement;
    expect(gridwidget101_textboxwidget182.tagName).toBe("DIV");
    expect(gridwidget101_textboxwidget182.classList).toContain("GridWidget");

    const gridwidget101_textboxwidget182data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_textboxwidget182data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget182btn, { button: 0 });
      });
      const gridwidget101_textboxwidget182add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget182add, { button: 0 });
      });
    }

  });
  test("Custom Test Cases for textboxwidget182", () => {

  });
  test("gridwidget101_textboxwidget186(Grid Widget) Test Cases", async () => {
    let gridwidget101_textboxwidget186 = screen.getByTestId("gridwidget101");
    let gridwidget101_textboxwidget186btn =
      gridwidget101_textboxwidget186.nextElementSibling.firstElementChild;
    gridwidget101_textboxwidget186 =
      gridwidget101_textboxwidget186.parentElement.parentElement.parentElement;
    expect(gridwidget101_textboxwidget186.tagName).toBe("DIV");
    expect(gridwidget101_textboxwidget186.classList).toContain("GridWidget");

    const gridwidget101_textboxwidget186data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_textboxwidget186data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget186btn, { button: 0 });
      });
      const gridwidget101_textboxwidget186add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget186add, { button: 0 });
      });
    }

  });
  test("Custom Test Cases for textboxwidget186", () => {

  });
  test("textboxwidget187(Textbox Widget) Test Cases", async () => {
    const textboxwidget187 = screen.getByTestId("textboxwidget187");
    expect(textboxwidget187.tagName).toBe("INPUT");
    expect(textboxwidget187.type).toBe("text");
    expect(textboxwidget187.classList).toContain("textboxWidgetClass");
    expect(textboxwidget187.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget187")
    );
    await act(async () => {
      userEvent.type(textboxwidget187, "1");
    });
    expect(textboxwidget187.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget187", () => {

  });
  test("gridwidget101_textboxwidget189(Grid Widget) Test Cases", async () => {
    let gridwidget101_textboxwidget189 = screen.getByTestId("gridwidget101");
    let gridwidget101_textboxwidget189btn =
      gridwidget101_textboxwidget189.nextElementSibling.firstElementChild;
    gridwidget101_textboxwidget189 =
      gridwidget101_textboxwidget189.parentElement.parentElement.parentElement;
    expect(gridwidget101_textboxwidget189.tagName).toBe("DIV");
    expect(gridwidget101_textboxwidget189.classList).toContain("GridWidget");

    const gridwidget101_textboxwidget189data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_textboxwidget189data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget189btn, { button: 0 });
      });
      const gridwidget101_textboxwidget189add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget189add, { button: 0 });
      });
    }

  });
  test("Custom Test Cases for textboxwidget189", () => {

  });
  test("textboxwidget190(Textbox Widget) Test Cases", async () => {
    const textboxwidget190 = screen.getByTestId("textboxwidget190");
    expect(textboxwidget190.tagName).toBe("INPUT");
    expect(textboxwidget190.type).toBe("text");
    expect(textboxwidget190.classList).toContain("textboxWidgetClass");
    expect(textboxwidget190.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget190")
    );
    await act(async () => {
      userEvent.type(textboxwidget190, "1");
    });
    expect(textboxwidget190.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget190", () => {

  });
  test("textboxwidget194(Textbox Widget) Test Cases", async () => {
    const textboxwidget194 = screen.getByTestId("textboxwidget194");
    expect(textboxwidget194.tagName).toBe("INPUT");
    expect(textboxwidget194.type).toBe("text");
    expect(textboxwidget194.classList).toContain("textboxWidgetClass");
    expect(textboxwidget194.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget194")
    );
    await act(async () => {
      userEvent.type(textboxwidget194, "1");
    });
    expect(textboxwidget194.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget194", () => {

  });
  test("textboxwidget195(Textbox Widget) Test Cases", async () => {
    const textboxwidget195 = screen.getByTestId("textboxwidget195");
    expect(textboxwidget195.tagName).toBe("INPUT");
    expect(textboxwidget195.type).toBe("text");
    expect(textboxwidget195.classList).toContain("textboxWidgetClass");
    expect(textboxwidget195.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget195")
    );
    await act(async () => {
      userEvent.type(textboxwidget195, "1");
    });
    expect(textboxwidget195.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget195", () => {

  });
  test("textboxwidget2(Textbox Widget) Test Cases", async () => {
    const textboxwidget2 = screen.getByTestId("textboxwidget2");
    expect(textboxwidget2.tagName).toBe("INPUT");
    expect(textboxwidget2.type).toBe("text");
    expect(textboxwidget2.classList).toContain("textboxWidgetClass");
    expect(textboxwidget2.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget2")
    );
    await act(async () => {
      userEvent.type(textboxwidget2, "1");
    });
    expect(textboxwidget2.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget2", () => {

  });
  test("gridwidget101_textboxwidget201(Grid Widget) Test Cases", async () => {
    let gridwidget101_textboxwidget201 = screen.getByTestId("gridwidget101");
    let gridwidget101_textboxwidget201btn =
      gridwidget101_textboxwidget201.nextElementSibling.firstElementChild;
    gridwidget101_textboxwidget201 =
      gridwidget101_textboxwidget201.parentElement.parentElement.parentElement;
    expect(gridwidget101_textboxwidget201.tagName).toBe("DIV");
    expect(gridwidget101_textboxwidget201.classList).toContain("GridWidget");

    const gridwidget101_textboxwidget201data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget101_textboxwidget201data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget101"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget201btn, { button: 0 });
      });
      const gridwidget101_textboxwidget201add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget101_textboxwidget201add, { button: 0 });
      });
    }
  });
  test("Custom Test Cases for textboxwidget201", () => {

  });
  test("textboxwidget3(Textbox Widget) Test Cases", async () => {
    const textboxwidget3 = screen.getByTestId("textboxwidget3");
    expect(textboxwidget3.tagName).toBe("INPUT");
    expect(textboxwidget3.type).toBe("text");
    expect(textboxwidget3.classList).toContain("textboxWidgetClass");
    expect(textboxwidget3.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget3")
    );
    await act(async () => {
      userEvent.type(textboxwidget3, "1");
    });
    expect(textboxwidget3.getAttribute("value")).toBe("");
    expect(textboxwidget3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for textboxwidget3", () => {

  });
  test("textboxwidget4(Textbox Widget) Test Cases", async () => {
    const textboxwidget4 = screen.getByTestId("textboxwidget4");
    expect(textboxwidget4.tagName).toBe("INPUT");
    expect(textboxwidget4.type).toBe("text");
    expect(textboxwidget4.classList).toContain("textboxWidgetClass");
    expect(textboxwidget4.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget4")
    );
    await act(async () => {
      textboxwidget4.focus();
      textboxwidget4.blur();
    });

    expect(textboxwidget4.classList).toContain("requiredField");
    await act(async () => {
      userEvent.type(textboxwidget4, "1");
    });
    expect(textboxwidget4.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget4", () => {

  });
  test("textboxwidget42(Textbox Widget) Test Cases", async () => {
    const textboxwidget42 = screen.getByTestId("textboxwidget42");
    expect(textboxwidget42.tagName).toBe("INPUT");
    expect(textboxwidget42.type).toBe("text");
    expect(textboxwidget42.classList).toContain("textboxWidgetClass");
    expect(textboxwidget42.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget42")
    );
    await act(async () => {
      userEvent.type(textboxwidget42, "1");
    });
    expect(textboxwidget42.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget42", () => {

  });
  test("gridwidget45_textboxwidget47(Grid Widget) Test Cases", async () => {
    let gridwidget45_textboxwidget47 = screen.getByTestId("gridwidget45");
    let gridwidget45_textboxwidget47btn =
      gridwidget45_textboxwidget47.nextElementSibling.firstElementChild;
    gridwidget45_textboxwidget47 =
      gridwidget45_textboxwidget47.parentElement.parentElement.parentElement;
    expect(gridwidget45_textboxwidget47.tagName).toBe("DIV");
    expect(gridwidget45_textboxwidget47.classList).toContain("GridWidget");

    const gridwidget45_textboxwidget47data = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridwidget45_textboxwidget47data.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridwidget45"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridwidget45_textboxwidget47btn, { button: 0 });
      });
      const gridwidget45_textboxwidget47add = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridwidget45_textboxwidget47add, { button: 0 });
      });
    }

  });
  test("Custom Test Cases for textboxwidget47", () => {

  });
  test("textboxwidget5(Textbox Widget) Test Cases", async () => {
    const textboxwidget5 = screen.getByTestId("textboxwidget5");
    expect(textboxwidget5.tagName).toBe("INPUT");
    expect(textboxwidget5.type).toBe("text");
    expect(textboxwidget5.classList).toContain("textboxWidgetClass");
    expect(
      textboxwidget5.parentElement.parentElement.getAttribute("title")
    ).toBe("ToolTip Test");
    expect(textboxwidget5.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget5")
    );
    await act(async () => {
      userEvent.type(textboxwidget5, "1");
    });
    expect(textboxwidget5.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget5", () => {

  });
  test("textboxwidget6(Textbox Widget) Test Cases", async () => {
    const textboxwidget6 = screen.getByTestId("textboxwidget6");
    expect(textboxwidget6.tagName).toBe("INPUT");
    expect(textboxwidget6.type).toBe("text");
    expect(textboxwidget6.classList).toContain("textboxWidgetClass");
    expect(textboxwidget6.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget6")
    );
    expect(textboxwidget6.getAttribute("autocomplete")).toBe("on");
    await act(async () => {
      userEvent.type(textboxwidget6, "1");
    });
    expect(textboxwidget6.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget6", () => {

  });
  test("textboxwidget8(Textbox Widget) Test Cases", async () => {
    const textboxwidget8 = screen.getByTestId("textboxwidget8");
    expect(textboxwidget8.tagName).toBe("INPUT");
    expect(textboxwidget8.type).toBe("text");
    expect(textboxwidget8.classList).toContain("textboxWidgetClass");
    expect(textboxwidget8.previousElementSibling.textContent).toEqual(
      t("translate:Test_RTL_textboxwidget8")
    );
    await act(async () => {
      userEvent.type(textboxwidget8, "1");
    });
    expect(textboxwidget8.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget8", () => {

  });
  test("filewidget131(File Widget) Test Cases", async () => {
    await act(async () => {
      const filewidget131 = screen.getAllByLabelText("Choose file")[0];

      fireEvent.change(filewidget131, { target: { value: "" } });
      const file = new File(['TCSLogo2'], 'TCSLogo2.png', { type: 'image/png' });
      userEvent.upload(filewidget131, file);
      const filewidget13 = screen.getAllByLabelText("Choose file")[1];

      fireEvent.change(filewidget13, { target: { value: "" } });
      userEvent.upload(filewidget13, file);
    });

  });
});
