/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  setValue,
  getValue,
  disable,
  hide,
  setData,
  getData,

} from "../../shared/WindowImports";

import "./FarmGovData.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";


// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FarmGovData(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmGovData",
    windowName: "FarmGovData",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FarmGovData",
    // START_USER_CODE-USER_FarmGovData_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Farm Data",
      scrCode: "PN1020C",
    },
    // END_USER_CODE-USER_FarmGovData_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxFarmGovData",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxFarmGovData",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCopyData: {
      name: "btnCopyData",
      type: "ButtonWidget",
      parent: "grpbxFarmGovData",
      Label: "Copy Date",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCopyData_PROPERTIES

      // END_USER_CODE-USER_btnCopyData_PROPERTIES
    },
    btnExportCounties: {
      name: "btnExportCounties",
      type: "ButtonWidget",
      parent: "grpbxFarmGovData",
      Label: "Export Counties",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportCounties_PROPERTIES

      // END_USER_CODE-USER_btnExportCounties_PROPERTIES
    },
    btnExportFarms: {
      name: "btnExportFarms",
      type: "ButtonWidget",
      parent: "grpbxFarmGovData",
      Label: "Export Farms",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportFarms_PROPERTIES

      // END_USER_CODE-USER_btnExportFarms_PROPERTIES
    },
    ddSelectBuyingPoint: {
      name: "ddSelectBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmGovData",
      Label: "Select Buying Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSelectBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddSelectBuyingPoint_PROPERTIES
    },
    txtBuyingPointID: {
      name: "txtBuyingPointID",
      type: "TextBoxWidget",
      parent: "grpbxFarmGovData",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPointID_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPointID_PROPERTIES
    },
    grpbxFarmGovData: {
      name: "grpbxFarmGovData",
      type: "GroupBoxWidget",
      parent: "FarmGovData",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxFarmGovData_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmGovData_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  let FormLoad = async () => {
    bFillBuyingPointList()
    var FarmCounty = getData(thisObj, 'FarmInquiryData')
    if (FarmCounty == "FarmData") {

      hide(thisObj, "btnCopyData")

      hide(thisObj, "btnExportCounties")

    } else if (FarmCounty == "CountyData") {

      hide(thisObj, "btnCopyData")

      hide(thisObj, "btnExportFarms")
    }
  }


  //Buying point dropdown function
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>Select Buying Point<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddSelectBuyingPoint: {
            ...state["ddSelectBuyingPoint"],
            valueList: js
          }
        }
      })

    })
    setValue(thisObj, 'ddSelectBuyingPoint', js.at(0).key)
  };

  // Export counties button functionality Start---  

  let onbtnExportCountiesClick = async () => {
    try {
      let buy_pt_id = getValue(thisObj, 'ddSelectBuyingPoint')

      if (buy_pt_id == "" || buy_pt_id == null || buy_pt_id == undefined) {
        alert("Select a valid Buying Point.")
        return;
      }

      let js = []
      let response = await SystemMaintenanceMasterManagementService.RetrieveFarmGovDetails(buy_pt_id, 'COUNTY')

      for (var i = 0; i < response.length; i++) {

        js.push(`${response[i].state_abbr},${response[i].state_id},${response[i].county_id},${response[i].county_name}`)
      }
      
      let link = document.createElement("a");
      let userInput = js.join('\n')
      const file = new Blob([userInput], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = buy_pt_id + "counties.txt";
      link.click();
      URL.revokeObjectURL(link.href);

      showMessage(thisObj, 'All County Data Exported Successfully.', true)

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Export Counties Click"
        );
      }
      return false;
    }

  }
  thisObj.onbtnExportCountiesClick = onbtnExportCountiesClick
  // Export counties button functionality End---

  // Export Farm button functionality Start---  

  let onbtnExportFarmsClick = async () => {
    try {
      let buy_pt_id = getValue(thisObj, 'ddSelectBuyingPoint')

      if (buy_pt_id == "" || buy_pt_id == null || buy_pt_id == undefined) {
        alert("Select a valid Buying Point.")
        return;
      }
      let js = []
      let response = await SystemMaintenanceMasterManagementService.RetrieveFarmGovDetails(buy_pt_id, 'FARM')

      for (var i = 0; i < response.length; i++) {
        js.push(`${response[i].row}`)
      }
      
      let link = document.createElement("a");
      let userInput = js.join('\n')
      const file = new Blob([userInput], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = buy_pt_id + "farms.txt";
      link.click();
      URL.revokeObjectURL(link.href);

      showMessage(thisObj, 'All Farm Data Exported Successfully.', true)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Export Farm Click"
        );
      }
      return false;
    }

  }
  thisObj.onbtnExportFarmsClick = onbtnExportFarmsClick;

  // Export Farm button functionality Start---  

  // Cancel button--
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_FarmGovDataPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnCancel event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmGovData*/}

              {/* END_USER_CODE-USER_BEFORE_FarmGovData*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFarmGovData*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmGovData*/}

              <GroupBoxWidget
                conf={state.grpbxFarmGovData}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddSelectBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddSelectBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSelectBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSelectBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddSelectBuyingPoint*/}


                {/* START_USER_CODE-USER_BEFORE_btnCopyData*/}

                {/* END_USER_CODE-USER_BEFORE_btnCopyData*/}

                <ButtonWidget
                  conf={state.btnCopyData}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCopyData*/}

                {/* END_USER_CODE-USER_AFTER_btnCopyData*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportFarms*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportFarms*/}

                <ButtonWidget
                  conf={state.btnExportFarms}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportFarms*/}

                {/* END_USER_CODE-USER_AFTER_btnExportFarms*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportCounties*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportCounties*/}

                <ButtonWidget
                  conf={state.btnExportCounties}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportCounties*/}

                {/* END_USER_CODE-USER_AFTER_btnExportCounties*/}

                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmGovData*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmGovData*/}

              {/* START_USER_CODE-USER_AFTER_FarmGovData*/}

              {/* END_USER_CODE-USER_AFTER_FarmGovData*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FarmGovData);
