/* eslint-disable*/
/* istanbul ignore file */
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  AutocompleteWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LinkWidget,
  GridWidget,
  FileWidget,
  DateWidget,
  CheckboxGroupWidget,
  ListboxWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  TextAreaWidget,
  ListSelectDeselectWidget,
  PasswordWidget,
  ImageWidget,
  RangeWidget,
  SpinnerWidget,
  WhiteSpaceWidget,
  Tab,
  Col,
  Row,
  Nav,
} from "../../shared/WindowImports";
import {getLabel,setLabel,hide,show,isEnabled,isVisible,enable,disable,readonly,clearField,
  getEventObj,setData,getData,clearSessionData,configureService,getValue,getSelectedGridValue,
  setValue,attachHotKeysHandler,setGridValue,getGridValue,hideColumn,editRow,setSelectedGridValue,
  disableGridColumn,enableGridColumn,setGridLastCell,getSelectedRowNumber,getSelectedRowNumbers,
  hasSelectedRows,getRowCount,gridRowStyle,isGridColumnEnable,deleteGridRow,hideGridHeader,showGridHeader,
  readonlyGridColumn,getGridLastCell,setSelectedRows,navigateToNextPage,navigateToPreviousPage,attachHomeKeyHandleForGrid,
  navigate,goTo,clearValue,buttonNavigationHandler,isElementNeedsFocus,focusFirstElement,
} from "../../shared/WindowImports";

// import "./Test_RTL.scss";

function Test_RTL(props){
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let states = {
    Label: "Test_RTL",
    windowName: "Test_RTL",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "translate.Test_RTL",

    autocompletewidget10: {
      name: "autocompletewidget10",
      type: "AutocompleteWidget",
      parent: "groupboxwidget0",
      Label: "Auto Complete Widget",
      ShortcutKey: "i",
      DefaultValue: "Chennai",
      valueListForAutocomplete: "Chennai,Delhi,Mumbai,Calcutta",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_char",
      Mandatory: true,
    },
    buttonwidget143: {
      name: "buttonwidget143",
      type: "ButtonWidget",
      colName: "gridcolumnwidget142",
      parent: "gridwidget101",
      Label: "buttonwidget143",
      ShortcutKey: "1",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    buttonwidget176: {
      name: "buttonwidget176",
      type: "ButtonWidget",
      colName: "gridcolumnwidget175",
      parent: "gridwidget148",
      ToolTip: "ToolTip Test",
      Label: "buttonwidget176",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    buttonwidget196: {
      name: "buttonwidget196",
      type: "ButtonWidget",
      parent: "commandcontainerwidget35",
      Label: "DeleteForm",
      CharWidth: "23",
    },
    buttonwidget197: {
      name: "buttonwidget197",
      type: "ButtonWidget",
      parent: "commandcontainerwidget35",
      Label: "DeleteGrid",
      CharWidth: "23",
    },
    buttonwidget36: {
      name: "buttonwidget36",
      type: "ButtonWidget",
      parent: "commandcontainerwidget35",
      Label: "Ok",
      CharWidth: "8",
      Mandatory: true,
    },
    buttonwidgetClose: {
      name: "buttonwidgetClose",
      type: "ButtonWidget",
      parent: "commandcontainerwidget35",
      Category: "Close",
      Label: "buttonwidgetClose",
      CharWidth: "29",
    },
    buttonwidgetCancel: {
      name: "buttonwidgetCancel",
      type: "ButtonWidget",
      parent: "commandcontainerwidget35",
      Category: "Cancel",
      Label: "buttonwidgetCancel",
      CharWidth: "29",
    },
    checkboxgroupfieldwidget18: {
      name: "checkboxgroupfieldwidget18",
      type: "CheckBoxGroupFieldWidget",
      parent: "groupboxwidget16",
      Label: "Check Box Group Widget",
      ColSpan: "3",
      ColSpanForLargeDesktop: "3",
      ColSpanForTabLandscape: "3",
      ColSpanForTabPotrait: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    checkboxwidget141: {
      name: "checkboxwidget141",
      type: "CheckBoxWidget",
      colName: "gridcolumnwidget140",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "checkboxwidget141",
      ShortcutKey: "2",
      DefaultValue: "true",
      CharWidth: "48",
      Height: "",
      Width: "",
      value: true,
      ofTypeDomain: "d_boolean",
    },
    checkboxwidget17: {
      name: "checkboxwidget17",
      type: "CheckBoxWidget",
      parent: "groupboxwidget16",
      Label: "Check Box Widget",
      ShortcutKey: "q",

      ofTypeDomain: "d_boolean",
    },
    checkboxwidget174: {
      name: "checkboxwidget174",
      type: "CheckBoxWidget",
      colName: "gridcolumnwidget173",
      parent: "gridwidget148",
      Label: "checkboxwidget174",
      CharWidth: "48",
      Height: "",
      Width: "",

      ofTypeDomain: "d_boolean",
    },
    checkboxwidget49: {
      name: "checkboxwidget49",
      type: "CheckBoxWidget",
      colName: "gridcolumnwidget48",
      parent: "gridwidget45",
      Label: "checkboxwidget49",
      CharWidth: "48",
      Height: "",
      Width: "",

      ofTypeDomain: "d_boolean",
    },
    datetimewidget13: {
      name: "datetimewidget13",
      type: "DateTimeWidget",
      parent: "groupboxwidget9",
      Label: "Date Time Widget",
      ShortcutKey: "m",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
    },
    datetimewidget135: {
      name: "datetimewidget135",
      type: "DateTimeWidget",
      colName: "gridcolumnwidget134",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "datetimewidget135",
      ShortcutKey: "4",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
    },
    datetimewidget168: {
      name: "datetimewidget168",
      type: "DateTimeWidget",
      colName: "gridcolumnwidget167",
      parent: "gridwidget148",
      Label: "datetimewidget168",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
    },
    datetimewidget183: {
      name: "datetimewidget183",
      type: "DateTimeWidget",
      parent: "groupboxwidget41",
      Label: "Date Time Widget (Tab)",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
    },
    datewidget12: {
      name: "datewidget12",
      type: "DateWidget",
      parent: "groupboxwidget9",
      Label: "Date Widget",
      ShortcutKey: "k",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    datewidget137: {
      name: "datewidget137",
      type: "DateWidget",
      colName: "gridcolumnwidget136",
      parent: "gridwidget101",
      ToolTip: "ToolTip Test",
      Label: "datewidget137",
      ShortcutKey: "3",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    datewidget15: {
      name: "datewidget15",
      type: "DateWidget",
      parent: "groupboxwidget9",
      Label: "Date Widget (Disable)",
      ShortcutKey: "l",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    datewidget172: {
      name: "datewidget172",
      type: "DateWidget",
      colName: "gridcolumnwidget171",
      parent: "gridwidget148",
      Label: "datewidget172",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    datewidget203: {
      name: "datewidget203",
      type: "DateWidget",
      colName: "gridcolumnwidget202",
      parent: "gridwidget101",
      Label: "datewidget203",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    datewidget43: {
      name: "datewidget43",
      type: "DateWidget",
      parent: "groupboxwidget41",
      Label: "Date Widget (T)",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    dropdownfieldwidget133: {
      name: "dropdownfieldwidget133",
      type: "DropDownFieldWidget",
      colName: "gridcolumnwidget132",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "dropdownfieldwidget133",
      ShortcutKey: "5",
      DefaultValue: "1",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",

      ofTypeDomain: "d_String",
    },
    dropdownfieldwidget166: {
      name: "dropdownfieldwidget166",
      type: "DropDownFieldWidget",
      colName: "gridcolumnwidget165",
      parent: "gridwidget148",
      Label: "dropdownfieldwidget166",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",

      ofTypeDomain: "d_String",
    },
    dropdownfieldwidget19: {
      name: "dropdownfieldwidget19",
      type: "DropDownFieldWidget",
      parent: "groupboxwidget16",
      Label: "Drop Down Widget",
      ShortcutKey: "s",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
    },
    dropdownfieldwidget204: {
      name: "dropdownfieldwidget204",
      type: "DropDownFieldWidget",
      parent: "groupboxwidget16",
      Options: "",
      Label: "dropdownfieldwidget204",
      key: "country",

      ofTypeDomain: "d_String",
    },
    dropdownfieldwidget205: {
      name: "dropdownfieldwidget205",
      type: "DropDownFieldWidget",
      parent: "groupboxwidget16",
      driver: "dropdownfieldwidget204",
      Options: "",
      Label: "dropdownfieldwidget205",
      key: "state",

      ofTypeDomain: "d_String",
    },
    dropdownfieldwidget44: {
      name: "dropdownfieldwidget44",
      type: "DropDownFieldWidget",
      parent: "groupboxwidget41",
      Label: "Drop Down Widget (T-Disable)",
      DataProviderForDropDown: "inline",
      Enabled: false,

      ofTypeDomain: "d_String",
    },
    filewidget131: {
      name: "filewidget131",
      type: "FileWidget",
      colName: "gridcolumnwidget130",
      parent: "gridwidget101",
      Label: "filewidget131",
      AllowedExtensions: "JPEG,GIF,PNG,JPG",
      ShortcutKey: "6",
      CharWidth: "48",
      Height: "",
      AltText: "Alternate Text For File Widget(Grid)",
      Width: "",
      ShowAs: "RenderOnScreen",
      SizeLimit: "1024K",
    },
    filewidget164: {
      name: "filewidget164",
      type: "FileWidget",
      colName: "gridcolumnwidget163",
      parent: "gridwidget148",
      Label: "filewidget164",
      AllowedExtensions: "JPG,GIF,PNG,JPEG",
      CharWidth: "48",
      Height: "",
      Width: "",
      ShowAs: "RenderOnScreen",
      SizeLimit: "1M",
    },
    filewidget27: {
      name: "filewidget27",
      type: "FileWidget",
      parent: "groupboxwidget26",
      Label: "File Widget",
      AllowedExtensions: "JPG,GIF,PNG,JPEG",
      ShortcutKey: "o",
      ShowAs: "RenderOnScreen",
    },
    filewidget53: {
      name: "filewidget53",
      type: "FileWidget",
      parent: "groupboxwidget52",
      Label: "Fiel Widget(T)",
      AllowedExtensions: "JPG,GIF,PNG,JPEG",
      ShowAs: "RenderOnScreen",
      Mandatory: true,
    },
    gridcolumnwidget106: {
      name: "gridcolumnwidget106",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Time Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget108: {
      name: "gridcolumnwidget108",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Text Box Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget110: {
      name: "gridcolumnwidget110",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Text Area Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget118: {
      name: "gridcolumnwidget118",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "PassWord Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget124: {
      name: "gridcolumnwidget124",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Link Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget126: {
      name: "gridcolumnwidget126",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Label Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget130: {
      name: "gridcolumnwidget130",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "File Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget132: {
      name: "gridcolumnwidget132",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "DropDpown Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget134: {
      name: "gridcolumnwidget134",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Date Time Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget136: {
      name: "gridcolumnwidget136",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Date Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget140: {
      name: "gridcolumnwidget140",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Check Box Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget142: {
      name: "gridcolumnwidget142",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Button Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget146: {
      name: "gridcolumnwidget146",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Status",
      VisibleForLargeDesktop: false,
      VisibleForTabLandscape: false,
      VisibleForTabPotrait: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      VisibleForMobile: false,
      Width: "",
    },
    gridcolumnwidget151: {
      name: "gridcolumnwidget151",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Time Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget153: {
      name: "gridcolumnwidget153",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Text Box Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget155: {
      name: "gridcolumnwidget155",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Text Area Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget157: {
      name: "gridcolumnwidget157",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "PassWord Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget159: {
      name: "gridcolumnwidget159",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Link Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget161: {
      name: "gridcolumnwidget161",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Label Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget163: {
      name: "gridcolumnwidget163",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "File Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget165: {
      name: "gridcolumnwidget165",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "DropDown Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget167: {
      name: "gridcolumnwidget167",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Date Time Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget171: {
      name: "gridcolumnwidget171",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Date Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget173: {
      name: "gridcolumnwidget173",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Check Box Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget175: {
      name: "gridcolumnwidget175",
      type: "GridColumnWidget",
      parent: "gridwidget148",
      Label: "Button Widget",
      Height: "",
      Width: "",
    },
    gridcolumnwidget179: {
      name: "gridcolumnwidget179",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "TextBox Widget(Double)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget181: {
      name: "gridcolumnwidget181",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "TextBox Widget(Float)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget185: {
      name: "gridcolumnwidget185",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "Textbox",
      Height: "",
      Width: "",
    },
    gridcolumnwidget188: {
      name: "gridcolumnwidget188",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "TextBox (Mandatory)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget200: {
      name: "gridcolumnwidget200",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "TextBox(Disable)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget202: {
      name: "gridcolumnwidget202",
      type: "GridColumnWidget",
      parent: "gridwidget101",
      Label: "DateWidget(DIsable)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget46: {
      name: "gridcolumnwidget46",
      type: "GridColumnWidget",
      parent: "gridwidget45",
      Label: "Text Box Widget (TG)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget48: {
      name: "gridcolumnwidget48",
      type: "GridColumnWidget",
      parent: "gridwidget45",
      Label: "Check Box Widget (TG)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget50: {
      name: "gridcolumnwidget50",
      type: "GridColumnWidget",
      parent: "gridwidget45",
      Label: "Text Area Widget (TG)",
      Height: "",
      Width: "",
    },
    gridcolumnwidget56: {
      name: "gridcolumnwidget56",
      type: "GridColumnWidget",
      parent: "gridwidget45",
      Label: "Status",
      VisibleForLargeDesktop: false,
      VisibleForTabLandscape: false,
      VisibleForTabPotrait: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      VisibleForMobile: false,
      Width: "",
    },
    gridwidget45: {
      name: "gridwidget45",
      type: "GridWidget",
      parent: "tabwidget39",
      gridCellsOrder:
        "textboxwidget47,checkboxwidget49,textareawidget51,textboxwidget57",
      Label: "Grid Widget (T)",
      ColSpan: "2",
      Pagination: false,
      isEditable: true,
      Cols: "2",
      Height: "",
      Width: "",
    },
    groupboxwidget41: {
      name: "groupboxwidget41",
      type: "GroupBoxWidget",
      parent: "tabwidget38",
      Label: "Basic Widgets",
      ColSpan: "2",
      Height: "",
      Width: "",
      Cols: "2",
    },
    groupboxwidget52: {
      name: "groupboxwidget52",
      type: "GroupBoxWidget",
      parent: "tabwidget40",
      Label: "Media Related Widgets (T)",
      ColSpan: "2",
      Height: "",
      Width: "",
      Cols: "2",
    },
    imagewidget29: {
      name: "imagewidget29",
      type: "ImageWidget",
      parent: "groupboxwidget26",
      Label: "Image Widget(Dynamic)",
      ShortcutKey: "p",
      AltText: "Alternate Text for image widget Dynamic",
      widgetState: "Dynamic",
    },
    imagewidget54: {
      name: "imagewidget54",
      type: "ImageWidget",
      parent: "groupboxwidget52",
      Label: "Alternate Text For Image Widget(T-Dynamic)",
      AltText: "Alternate Text For Image Widget(T-Dynamic)",
      widgetState: "Dynamic",
    },
    labelwidget127: {
      name: "labelwidget127",
      type: "LabelWidget",
      colName: "gridcolumnwidget126",
      parent: "gridwidget101",
      Label: "labelwidget127",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    labelwidget162: {
      name: "labelwidget162",
      type: "LabelWidget",
      colName: "gridcolumnwidget161",
      parent: "gridwidget148",
      Label: "labelwidget162",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    labelwidget31: {
      name: "labelwidget31",
      type: "LabelWidget",
      parent: "groupboxwidget30",
      Label: "Label Widget",
      ShortcutKey: "x",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    linkwidget125: {
      name: "linkwidget125",
      type: "LinkWidget",
      colName: "gridcolumnwidget124",
      parent: "gridwidget101",
      Label: "linkwidget125",
      ShortcutKey: "7",
      CharWidth: "48",
      Height: "",
      Width: "",
    },
    linkwidget160: {
      name: "linkwidget160",
      type: "LinkWidget",
      colName: "gridcolumnwidget159",
      parent: "gridwidget148",
      Label: "linkwidget160",
      CharWidth: "48",
      Height: "",
      Width: "",
    },
    linkwidget32: {
      name: "linkwidget32",
      type: "LinkWidget",
      parent: "groupboxwidget30",
      Label: "LOV Child Page(Link Widget)",
      ShortcutKey: "y",
    },
    listboxfieldwidget20: {
      name: "listboxfieldwidget20",
      type: "ListBoxFieldWidget",
      parent: "groupboxwidget16",
      Label: "List Box Widget",
      ShortcutKey: "t",
      DataProviderForListBox: "inline",
      value: "Option1:1,Option2:2",
      ofTypeDomain: "d_String",
    },
    listselectdeselectfieldwidget21: {
      name: "listselectdeselectfieldwidget21",
      type: "ListSelectDeselectFieldWidget",
      parent: "groupboxwidget16",
      Label: "List Select De-Select Widget",
      ShortcutKey: "u",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
    },
    passwordboxwidget11: {
      name: "passwordboxwidget11",
      type: "PasswordBoxWidget",
      parent: "groupboxwidget0",
      Label: "Password Widget",
      ShortcutKey: "j",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    passwordboxwidget119: {
      name: "passwordboxwidget119",
      type: "PasswordBoxWidget",
      colName: "gridcolumnwidget118",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "passwordboxwidget119",
      ShortcutKey: "8",
      DefaultValue: "Test Data",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      //
    },
    passwordboxwidget158: {
      name: "passwordboxwidget158",
      type: "PasswordBoxWidget",
      colName: "gridcolumnwidget157",
      parent: "gridwidget148",
      Label: "passwordboxwidget158",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    radiobuttongroupfieldwidget22: {
      name: "radiobuttongroupfieldwidget22",
      type: "RadioButtonGroupFieldWidget",
      parent: "groupboxwidget16",
      Label: "Radio Button Widget",
      ShortcutKey: "r",

      ofTypeDomain: "d_boolean",
    },
    rangewidget23: {
      name: "rangewidget23",
      type: "RangeWidget",
      parent: "groupboxwidget16",
      Label: "Range Widget",
      ShortcutKey: "v",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    },
    spinnerwidget24: {
      name: "spinnerwidget24",
      type: "SpinnerWidget",
      parent: "groupboxwidget16",
      Label: "Spinner Widgets",
      ShortcutKey: "x",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    },
    tabwidget38: {
      name: "tabwidget38",
      type: "TabWidget",
      parent: "tabcontainerwidget37",
      Label: "Tab-1",
      Height: "",
      Width: "",
    },
    tabwidget39: {
      name: "tabwidget39",
      type: "TabWidget",
      parent: "tabcontainerwidget37",
      Label: "Tab-2",
      Height: "",
      Width: "",
    },
    tabwidget40: {
      name: "tabwidget40",
      type: "TabWidget",
      parent: "tabcontainerwidget37",
      Label: "Tab-3",
      Height: "",
      Width: "",
    },
    textareawidget111: {
      name: "textareawidget111",
      type: "TextAreaWidget",
      colName: "gridcolumnwidget110",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "textareawidget111",
      ShortcutKey: "9",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textareawidget156: {
      name: "textareawidget156",
      type: "TextAreaWidget",
      colName: "gridcolumnwidget155",
      parent: "gridwidget148",
      Label: "textareawidget156",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textareawidget51: {
      name: "textareawidget51",
      type: "TextAreaWidget",
      colName: "gridcolumnwidget50",
      parent: "gridwidget45",
      Label: "textareawidget51",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textareawidget7: {
      name: "textareawidget7",
      type: "TextAreaWidget",
      parent: "groupboxwidget0",
      Label: "Text Area Widget",
      textareaType: "advanced",
      ShortcutKey: "f",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget1: {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget109: {
      name: "textboxwidget109",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget108",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "textboxwidget109",
      ShortcutKey: "0",
      DefaultValue: "ToolTip Test",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 1, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget147: {
      name: "textboxwidget147",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget146",
      parent: "gridwidget101",
      Label: "textboxwidget147",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget154: {
      name: "textboxwidget154",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget153",
      parent: "gridwidget148",
      Label: "textboxwidget154",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget177: {
      name: "textboxwidget177",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "TextBox Widget(Decimal)",
      LengthRange: { MinLength: 0, MaxLength: 17 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_double",
    },
    textboxwidget178: {
      name: "textboxwidget178",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "TextBox Widget(Float)",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
    },
    textboxwidget180: {
      name: "textboxwidget180",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget179",
      parent: "gridwidget101",
      Label: "textboxwidget180",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 17 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_double",
    },
    textboxwidget182: {
      name: "textboxwidget182",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget181",
      parent: "gridwidget101",
      Label: "textboxwidget182",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
    },
    textboxwidget186: {
      name: "textboxwidget186",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget185",
      parent: "gridwidget101",
      Label: "textboxwidget186",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_intClass",
    },
    textboxwidget187: {
      name: "textboxwidget187",
      type: "TextBoxWidget",
      parent: "groupboxwidget41",
      Label: "TextBox Widget",
      LengthRange: { MinLength: 1, MaxLength: 9 },
      ValueRange: { MinValue: 1, MaxValue: 9 },
      ofTypeDomain: "d_int",
    },
    textboxwidget189: {
      name: "textboxwidget189",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget188",
      parent: "gridwidget101",
      Label: "textboxwidget189",
      CharWidth: "48",
      Height: "",
      Width: "",
      Mandatory: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget190: {
      name: "textboxwidget190",
      type: "TextBoxWidget",
      parent: "groupboxwidget41",
      Label: "TextBox (Mandatory)",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    },
    textboxwidget194: {
      name: "textboxwidget194",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "DeleteMe",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_intClass",
    },
    textboxwidget195: {
      name: "textboxwidget195",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "RetMsg",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget2: {
      name: "textboxwidget2",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Number)",
      ShortcutKey: "c",
      DefaultValue: "123456",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    },
    textboxwidget201: {
      name: "textboxwidget201",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget200",
      parent: "gridwidget101",
      Label: "textboxwidget201",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget3: {
      name: "textboxwidget3",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Disabled)",
      ShortcutKey: "b",
      DefaultValue: "Test Data",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget4: {
      name: "textboxwidget4",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Mandatory)",
      ShortcutKey: "d",
      DefaultValue: "Test Data",
      Mandatory: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ValueRange: { MinValue: 0, MaxValue: 9 },
      ofTypeDomain: "d_int",
      regExp: {
        RegularExpression: "[a-z]",
        ValidatorMessage: "translate.textboxwidget9.valmsg",
      },
    },
    textboxwidget42: {
      name: "textboxwidget42",
      type: "TextBoxWidget",
      parent: "groupboxwidget41",
      Label: "Text Box Widget (T)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget47: {
      name: "textboxwidget47",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget46",
      parent: "gridwidget45",
      Label: "textboxwidget47",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget5: {
      name: "textboxwidget5",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      ToolTip: "ToolTip Test",
      Label: "Text Box Widget (Tool Tip)",
      ShortcutKey: "e",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget57: {
      name: "textboxwidget57",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget56",
      parent: "gridwidget45",
      Label: "textboxwidget57",
      CharWidth: "48",
      Height: "",
      Width: "",

      ofTypeDomain: "d_char",
    },
    textboxwidget6: {
      name: "textboxwidget6",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget(AutoComplete)",
      ShortcutKey: "g",
      DefaultValue: "Test Data",
      AutoCompleteForTextBox: "on",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    textboxwidget8: {
      name: "textboxwidget8",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "LOV",
      ShortcutKey: "h",
      DefaultValue: "Test Data",

      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    timewidget107: {
      name: "timewidget107",
      type: "TimeWidget",
      colName: "gridcolumnwidget106",
      parent: "gridwidget101",
      ToolTip: "Tool Tip Test",
      Label: "timewidget107",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_time",
      Mandatory: true,
    },
    timewidget14: {
      name: "timewidget14",
      type: "TimeWidget",
      parent: "groupboxwidget9",
      Label: "Time Widget",
      ShortcutKey: "n",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_time",
    },
    timewidget152: {
      name: "timewidget152",
      type: "TimeWidget",
      colName: "gridcolumnwidget151",
      parent: "gridwidget148",
      Label: "timewidget152",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_time",
    },
    timewidget184: {
      name: "timewidget184",
      type: "TimeWidget",
      parent: "groupboxwidget41",
      Label: "Time Widget(Tab)",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_time",
    },
    whitespacewidget34: {
      name: "whitespacewidget34",
      type: "WhiteSpaceWidget",
      parent: "groupboxwidget30",
      Label: "White Space Widget",
      ShortcutKey: "z",
    },
    groupboxwidget0: {
      name: "groupboxwidget0",
      type: "GroupBoxWidget",
      parent: "Test_RTL",
      Label: "Text Box Related Widgets",
      Height: "",
      Width: "",
    },
    groupboxwidget9: {
      name: "groupboxwidget9",
      type: "GroupBoxWidget",
      parent: "Test_RTL",
      Label: "Date Related Widgets",
      Height: "",
      Width: "",
    },
    groupboxwidget26: {
      name: "groupboxwidget26",
      type: "GroupBoxWidget",
      parent: "Test_RTL",
      Label: "Media ReleatedWidgets",
      Height: "",
      Width: "",
    },
    groupboxwidget16: {
      name: "groupboxwidget16",
      type: "GroupBoxWidget",
      parent: "Test_RTL",
      Label: "Select ReleatedWidgets",
      Height: "",
      Width: "",
    },
    groupboxwidget30: {
      name: "groupboxwidget30",
      type: "GroupBoxWidget",
      parent: "Test_RTL",
      Label: "Other Widgets",
      Height: "",
      Width: "",
    },
    tabcontainerwidget37: {
      name: "tabcontainerwidget37",
      type: "TabContainerWidget",
      parent: "Test_RTL",
      Label: "Tab Widget",
      Height: "",
      Width: "",
    },
    gridwidget101: {
      name: "gridwidget101",
      type: "GridWidget",
      parent: "Test_RTL",
      gridCellsOrder:
        "textboxwidget147,textboxwidget201,datewidget203,textboxwidget186,buttonwidget143,textboxwidget189,textboxwidget180,textboxwidget182,checkboxwidget141,datewidget137,datetimewidget135,dropdownfieldwidget133,filewidget131,labelwidget127,linkwidget125,passwordboxwidget119,textareawidget111,textboxwidget109,timewidget107",
      Label: "Editable Grid",
      isEditable: true,
      Height: "",
      Width: "",

      paginationService: "BankCustomer#findAllCustomers#PagerORsort",
    },
    gridwidget148: {
      name: "gridwidget148",
      type: "GridWidget",
      parent: "Test_RTL",
      gridCellsOrder:
        "buttonwidget176,checkboxwidget174,datewidget172,datetimewidget168,dropdownfieldwidget166,filewidget164,labelwidget162,linkwidget160,passwordboxwidget158,textareawidget156,textboxwidget154,timewidget152",
      Label: "Non- Editable Grid",
      Height: "",
      Width: "",

      paginationService: "BankCustomer#findAllCustomers#PagerORsort",
    },
    commandcontainerwidget35: {
      name: "commandcontainerwidget35",
      type: "CommandContainerWidget",
      parent: "Test_RTL",
      Label: "commandcontainerwidget35",
      Height: "",
      Width: "",
    },
    _dataCopy: {
      FORWARD: {
        "TestModule#LOVChildPage": {},
      },
      REVERSE: {
        "TestModule#LOVChildPage": {},
      },
    },
  };
 let _buttonServices = {
    Test_RTL: {
      DEFAULT: ["BankCustomer#findAllCustomers#Open"],
    },
    LOVChildPage: {},

    textboxwidget1: {
      DEFAULT: ["BankCustomer#addCustomer#Change"],
    },

    gridwidget101_RowSelect: {
      DEFAULT: ["BankCustomer#addCustomer#RowSelect"],
    },
    buttonwidget36: {
      DEFAULT: ["BankCustomer#findAllCustomers#Click"],
      MODIFY: ["BankCustomer#addCustomer#Click"],
    },
    buttonwidget196: {
      DEFAULT: ["BankCustomer#deleteCustomer#Click"],
    },
    buttonwidget197: {
      DEFAULT: [
        "BankCustomer#deleteCustomer#Click",
        "BankCustomer#findAllCustomers#Click",
      ],
    },
  };
 let _buttonNavigation = {
    linkwidget32: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },

    buttonwidget143: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },
    linkwidget125: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },
    linkwidget160: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },
    buttonwidget36: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },
    buttonwidgetClose: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },
    buttonwidgetCancel: {
      DEFAULT: ["TestModule#LOVChildPage#DEFAULT#true#Click"],
    },
  };
 let _winServices = {
    "BankCustomer#addCustomer": {
      IN: {
        inCutomer: {
          "inCutomer.panCard": "textboxwidget147",
        },
      },
      OUT: {
        retCustomer: {
          //No FPMap for this
        },
      },
      DESC: {
        ownerComp: "BankCustomer",
        isSpringBoot: false,
        produces: "application/json",
        consumes: "application/json",
        type: "POST",
        path: "addCustomer",
        versioningRequired: true,
        versioningType: "MediaType",

        versioningValue: "v1",
        paginationRequired: false,

        controller: "CustomerController",
        inCutomer: "RequestBody",
        inCutomer__Multiplicity: false,
      },
    },
    "BankCustomer#deleteCustomer": {
      IN: {
        inCustomerId: {
          inCustomerId: "textboxwidget194",
        },
      },
      OUT: {
        retStatus: {
          textboxwidget195: "retStatus",
        },
      },
      DESC: {
        ownerComp: "BankCustomer",
        isSpringBoot: false,
        produces: "application/json",
        consumes: "application/text",
        type: "DELETE",
        path: "deleteCustomer/{inCustomerId}",
        versioningRequired: false,

        paginationRequired: false,

        controller: "CustomerController",
        inCustomerId: "PathVariable",
        inCustomerId__Multiplicity: false,
      },
    },
    "BankCustomer#findAllCustomers": {
      IN: {},
      OUT: {
        retCustomerList: {
          textboxwidget154: "retCustomerList.name",
        },
      },
      DESC: {
        ownerComp: "BankCustomer",
        isSpringBoot: false,
        produces: "application/json",
        consumes: "application/text",
        type: "GET",
        path: "findAllCustomers",
        versioningRequired: true,
        versioningType: "Header",

        versioningValue: "v1",
        paginationRequired: false,

        controller: "CustomerController",
      },
    },
  };
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj,state.windowName);
    callOnOpenService(thisObj, screenRef);
	if (screenRef.current !== null) {
      setTimeout(() => {
        focusFirstElement(screenRef, state);
      }, 1);
    }
    var focusElem = document.getElementById("textboxwidget1");
    var needFocus = isElementNeedsFocus(focusElem, state);
	//attachHomeKeyHandleForGrid(thisObj,"gridwidget101");
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const onTest_RTLOpen = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:Test_RTL event:Open"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onTest_RTLOpen=onTest_RTLOpen;

  const ontextboxwidget1Change = () => {
    try {
      window._kaledo["isCobolTable"] = true;
      let js = [
        {
          textboxwidget147: "abcd",
        },
        { textboxwidget147: "yuio" },
        { textboxwidget147: "1234" },
      ];
      setValue(thisObj,"gridwidget101", js);
      let gridObj = getValue(thisObj,"gridwidget101");
      setTimeout(() => {
        setGridValue(thisObj,"gridwidget101", "textboxwidget147", 0, "efgh");
        hideColumn(thisObj,"gridwidget101", "textboxwidget147");
      }, 0);
      showColumn(thisObj,"gridwidget101", "textboxwidget147");
      editRow(thisObj,"gridwidget101", 0);
      let winObj = state;
      gridObj = winObj["gridwidget101"];
      let sel = [
        {
          isSelected: true,
          rowID$: 0,
          textboxwidget147: "efgh",
        },
      ];
       thisObj.setState(current => {
        return {
          ...current,
         gridwidget101: {
                ...state["gridwidget101"],
                selected: [...sel],
              }
        };
      });
      setTimeout(() => {
        setSelectedGridValue("gridwidget101", "textboxwidget147", "eefgh");
        disableGridColumn(thisObj,"gridwidget101", "textboxwidget147");
        setGridLastCell(thisObj,"gridwidget101", "textboxwidget147", "qwer");
      }, 0);
      setTimeout(() => {
        let selRowNum = getSelectedRowNumber(thisObj,"gridwidget101");
        let selRowNums = getSelectedRowNumbers(thisObj,"gridwidget101");
        let hasSel = hasSelectedRows(thisObj,thisObj,"gridwidget101");
        enableGridColumn(thisObj,"gridwidget101", "textboxwidget147");
        let gridSelObjGet = getSelectedGridValue(thisObj,
          "gridwidget101",
          "textboxwidget147"
        );
        try {
          getSelectedGridValue(thisObj,"gridwidget1011", "textboxwidget147");
        } catch (e) {
          //Do Nothing
        }
        try {
         getSelectedGridValue(thisObj,"gridwidget101", "abc");
        } catch (e) {
          //Do Nothing
        }
        try {
          setSelectedGridValue("gridwidget101", "abc");
        } catch (e) {
          //Do Nothing
        }
        try {
          setSelectedGridValue("gridwidget1011", "textboxwidget147");
        } catch (e) {
          //Do Nothing
        }
        try {
          setGridValue(thisObj,"", "gridcolumnwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          setGridValue(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          setGridValue(thisObj,"gridwidget101", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          getGridValue(thisObj,"gridwidget101", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          getGridValue(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          getGridValue(thisObj,"", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          disableGridColumn(thisObj,"", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          disableGridColumn(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          enableGridColumn(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          enableGridColumn(thisObj,"", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          showColumn(thisObj,"", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          showColumn(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          hideColumn(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          hideColumn(thisObj,"", "gridwidget56");
        } catch (e) {
          //Do Nothing
        }
        try {
          getRowCount("");
        } catch (e) {
          //Do Nothing
        }
        try {
          gridRowStyle(thisObj,"");
        } catch (e) {
          //Do Nothing
        }
        try {
          isGridColumnEnable(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          editRow(thisObj,"gridwidget101", -5);
        } catch (e) {
          //Do Nothing
        }
        try {
          editRow(thisObj,"", 5);
        } catch (e) {
          //Do Nothing
        }
        try {
          deleteGridRow(thisObj,"", 5);
        } catch (e) {
          //Do Nothing
        }
        try {
          deleteGridRow(thisObj,"gridwidget101", -5);
        } catch (e) {
          //Do Nothing
        }
        try {
          hideGridHeader(thisObj,"");
        } catch (e) {
          //Do Nothing
        }
        try {
          showGridHeader(thisObj,"");
        } catch (e) {
          //Do Nothing
        }
        try {
          readonlyGridColumn(thisObj,"gridwidget101", "");
        } catch (e) {
          //Do Nothing
        }
        try {
          readonlyGridColumn(thisObj,"", "textboxwidget147");
        } catch (e) {
          //Do Nothing
        }
        try {
          getGridLastCell(thisObj,"", "textboxwidget147");
        } catch (e) {
          //Do Nothing
        }
        try {
          setGridLastCell(thisObj,"", "textboxwidget147");
        } catch (e) {
          //Do Nothing
        }
        try {
          getSelectedRowNumber(thisObj,"gridwidget10111");
        } catch (e) {
          //Do Nothing
        }
        try {
          getSelectedRowNumbers(thisObj,"gridwidget10111");
        } catch (e) {
          //Do Nothing
        }
        let gridObjGet = getGridValue(thisObj,
          "gridwidget101",
          "textboxwidget147",
          0
        );
        let gridlastObjGet = getGridLastCell(thisObj,
          "gridwidget101",
          "textboxwidget147"
        );
      }, 0);
      setSelectedRows(thisObj,"gridwidget101");
      navigateToNextPage(thisObj,"gridwidget101", true);
      navigateToPreviousPage(thisObj,"gridwidget101");
      attachHomeKeyHandleForGrid(thisObj,"gridwidget101");
      navigate(thisObj,"/Login");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:textboxwidget1 event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontextboxwidget1Change=ontextboxwidget1Change;
  const ontextboxwidget178Change = () => {
    try {
      var evntObj = getEventObj(thisObj,);
      showMessage(thisObj,"Testing done", true);
      showMessage(thisObj,"Testing done", false);
      window._kaledo["errorPosition"] = "common";
      window._kaledo.ScreenList.push({
        "mode": 0, "displayName": "TestScreen", "URIPath": "TestModule_LOVChildPage",
        "GUIName": "TestModule",
        "screenName": "LOVChildPage",
        "firstMenuLabel": "Standard Screen"
      });
	  showMessage(thisObj,"Testing done", true);
      showMessage(thisObj,"Testing done", false);
      let rowCount = getRowCount("gridwidget101");
      gridRowStyle(thisObj,"gridwidget101", "gridStyle");
      isGridColumnEnable(thisObj,"gridwidget101", "textboxwidget147");
      deleteGridRow(thisObj,"gridwidget101", 2);
      hideGridHeader(thisObj,"gridwidget101");
      showGridHeader(thisObj,"gridwidget101");
      readonlyGridColumn(thisObj,"gridwidget101", "textboxwidget147");
      goTo(thisObj,"TestModule#LOVChildPage#DEFAULT#true#Click", "F", false);
      goTo(thisObj,"TestModule#LOVChildPage#DEFAULT#true#Click", "F", true);
      goTo(thisObj,"TestModule#LOVChildPage#DEFAULT#false#Click", "F", true);
      goTo(thisObj,"TestModule#LOVChildPage#DEFAULT#false#Click", "G", true);

      const keyDownEvnt = new Event("keydown");
      keyDownEvnt.keyCode = 13;
      keyDownEvnt.key = "Enter";
      keyDownEvnt.code = 13;
      window.dispatchEvent(keyDownEvnt);
      const keyDownEvnt2 = new Event("keyup");
      keyDownEvnt.keyCode = 36;
      keyDownEvnt.key = "home";
      keyDownEvnt.code = 36;
      window.dispatchEvent(keyDownEvnt2);
      setLabel(thisObj,"textboxwidget177", "textboxwidgetFloat");
      disable(thisObj,"textboxwidget177");
      isEnabled(thisObj,"textboxwidget177");
      enable(thisObj,"textboxwidget177");
      hide(thisObj,"textboxwidget177");
      isVisible(thisObj,"textboxwidget177");
      show(thisObj,"textboxwidget177");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:filewidget27 event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontextboxwidget178Change=ontextboxwidget178Change;
  const ontextboxwidget177Change = () => {
    try {
      isEnabled(thisObj,"textboxwidget1");
      isVisible(thisObj,"textboxwidget1");
      enable(thisObj,"textboxwidget1");
      disable(thisObj,"textboxwidget1");
      clearField(thisObj,"textboxwidget1");
      clearValue(thisObj,"textboxwidget1");
      clearSessionData("textboxwidget1");
      setValue(thisObj,"textboxwidget1");
      getValue(thisObj,"textboxwidget1");
      hide(thisObj,"textboxwidget1");
      hide(thisObj,"textboxwidget1", "TabLandscape");
      hide(thisObj,"textboxwidget1", "TabPortrait");
      hide(thisObj,"textboxwidget1", "Mobile");
      show(thisObj,"textboxwidget1", "TabLandscape");
      show(thisObj,"textboxwidget1", "TabPortrait");
      show(thisObj,"textboxwidget1", "Mobile");
      setLabel(thisObj,"textboxwidget1", "textboxwidget1Label");
      getLabel(thisObj,"textboxwidget1");
      readonly(thisObj,"textboxwidget1", "");
      handleModalHide(thisObj,);
      switchToTab(thisObj,"tabwidget38");
      readonly(thisObj,"textboxwidget147", true);
      handleModalHide(thisObj,{
        values: { textboxwidget1: "Done" },
      });
      getLabel(thisObj,"textboxwidget11");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:filewidget27 event:Change"
        );
      }
      return false;
    }
    return true;
  };
 thisObj.ontextboxwidget177Change=ontextboxwidget177Change;
 const onfilewidget27Change = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:filewidget27 event:Change"
        );
      }
      return false;
    }
    return true;
  };
thisObj.onfilewidget27Change=onfilewidget27Change;
const ontabwidget38Click = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:tabwidget38 event:Click"
        );
      }
      return false;
    }
    return true;
  };
	thisObj.ontabwidget38Click=ontabwidget38Click;
	const onlinkwidget32Click = () => {
    try {
      disable(thisObj,"textareawidget7");
      enable(thisObj,"textareawidget7");
      thisObj.context.data = {};
	  parentWindow(thisObj);
      setData(thisObj,"Testing1", "setData");
      getData(thisObj,"Testing1");
      setData(thisObj,"Testing2", "setData", false, true);
      thisObj.context.data = {};
	  parentWindow(thisObj);
      getData(thisObj,"Testing2");
      window._kaledo.guiModuleName = "TestModule";
      setData(thisObj,"Testing3", "setData", true);
      getData(thisObj,"Testing3", true);
      buttonNavigationHandler(thisObj,"buttonwidget36", "Click");
      buttonNavigationHandler(thisObj,"buttonwidgetClose", "Click");
      buttonNavigationHandler(thisObj,"buttonwidgetCancel", "Click");
      buttonNavigationHandler(thisObj,"buttonwidget36", "change");
      var setFldVal = thisObj.setFieldValue;
      thisObj.setFieldValue = null;
	  parentWindow(thisObj);
      clearValue(thisObj,"textboxwidget177");
      clearField(thisObj,"textboxwidget177");
      setLabel(thisObj,"textboxwidget177", "textboxwidgetFloat");
      hide(thisObj,"textboxwidget177");
      hide(thisObj,"textboxwidget177", "TabLandscape");
      hide(thisObj,"textboxwidget177", "TabPotrait");
      hide(thisObj,"textboxwidget177", "Mobile");
      show(thisObj,"textboxwidget177", "TabLandscape");
      show(thisObj,"textboxwidget177", "TabPotrait");
      show(thisObj,"textboxwidget177", "Mobile");
      show(thisObj,"textboxwidget177");
      setValue(thisObj,"textboxwidget1", "abc");
      thisObj.setFieldValue = setFldVal;
	  parentWindow(thisObj);
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:tabwidget38 event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onlinkwidget32Click=onlinkwidget32Click;
  const ongridwidget101RowSelect = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:gridwidget101 event:RowSelect"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ongridwidget101RowSelect=ongridwidget101RowSelect;
  const ongridwidget101rightClick = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:gridwidget101 event:rightClick"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ongridwidget101rightClick=ongridwidget101rightClick;
  const ongridwidget148RowSelect = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:gridwidget148 event:RowSelect"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ongridwidget148RowSelect=ongridwidget148RowSelect;
  const onbuttonwidget36Click = () => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:buttonwidget36 event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbuttonwidget36Click=onbuttonwidget36Click;
  const onPostSubmitOfbuttonwidget36Click = (response) => {
    try {
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PostSubmit Event code for widget:buttonwidget36 event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onPostSubmitOfbuttonwidget36Click=onPostSubmitOfbuttonwidget36Click;
  const onbuttonwidget196Click = () => {
    try {
      let a = null;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:buttonwidget196 event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbuttonwidget196Click=onbuttonwidget196Click;
  const onbuttonwidget197Click = () => {
    try {
      let a = null;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj,err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:buttonwidget197 event:Click"
        );
      }
      return false;
    }
    return true;
  };
 thisObj.onbuttonwidget197Click=onbuttonwidget197Click;
    initForm(thisObj);
    let validationSchema = object(_kaledo.validationSchema);
    let initialValues = _kaledo.initialValues;
    return (
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
		thisObj.values = values = values;
		thisObj.handleChange = handleChange = handleChange;
		thisObj.handleBlur = handleBlur = handleBlur;
		thisObj.isValid = isValid = isValid;
		thisObj.errors = errors = errors;
          return (
            <div
              className={
                _kaledo.guiModuleName +
                "-" +
                state.windowName +
                " col-lg-12"
              }
              data-testid={state.windowName}
              ref={screenRef}
            >
              <Modal
                show={state.showPopup}
                onHide={handleModalHide}
                data={dataCopyObj.dataToCopy}
                component={state.popupComponent}
              />

              <h1 className="pageHeader">{state.Label}</h1>

              <Form noValidate className="row">
                <GroupBoxWidget
                  conf={state.groupboxwidget0}
                  screenConf={state}
                >
                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget1}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget3}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget2}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget4}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget177}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget178}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget5}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextAreaWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textareawidget7}
                    screenConf={state}
                  ></TextAreaWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget6}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget8}
                    screenConf={state}
                  ></TextBoxWidget>

                  <AutocompleteWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    conf={state.autocompletewidget10}
                    screenConf={state}
                  />

                  <PasswordWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.passwordboxwidget11}
                    screenConf={state}
                  ></PasswordWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget194}
                    screenConf={state}
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget195}
                    screenConf={state}
                  ></TextBoxWidget>
                </GroupBoxWidget>

                <GroupBoxWidget
                  conf={state.groupboxwidget9}
                  screenConf={state}
                >
                  <DateWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.datewidget12}
                    screenConf={state}
                  ></DateWidget>

                  <DateWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.datewidget15}
                    screenConf={state}
                  ></DateWidget>

                  <DateWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.datetimewidget13}
                    screenConf={state}
                  ></DateWidget>

                  <DateWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.timewidget14}
                    screenConf={state}
                  ></DateWidget>
                </GroupBoxWidget>

                <GroupBoxWidget
                  conf={state.groupboxwidget26}
                  screenConf={state}
                >
                  <FileWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.filewidget27}
                    screenConf={state}
                  ></FileWidget>

                  <ImageWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.imagewidget29}
                    screenConf={state}
                  />
                </GroupBoxWidget>

                <GroupBoxWidget
                  conf={state.groupboxwidget16}
                  screenConf={state}
                >
                  <CheckboxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.checkboxwidget17}
                    screenConf={state}
                  ></CheckboxWidget>

                  <RadioButtonGroupWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radiobuttongroupfieldwidget22}
                    screenConf={state}
                  ></RadioButtonGroupWidget>

                  <DropDownWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dropdownfieldwidget19}
                    screenConf={state}
                  ></DropDownWidget>

                  <ListboxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.listboxfieldwidget20}
                    screenConf={state}
                  ></ListboxWidget>

                  <ListSelectDeselectWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.listselectdeselectfieldwidget21}
                    screenConf={state}
                  ></ListSelectDeselectWidget>

                  <RangeWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.rangewidget23}
                    screenConf={state}
                  ></RangeWidget>

                  <CheckboxGroupWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.checkboxgroupfieldwidget18}
                    screenConf={state}
                  ></CheckboxGroupWidget>

                  <SpinnerWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.spinnerwidget24}
                    screenConf={state}
                  ></SpinnerWidget>

                  <DropDownWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dropdownfieldwidget204}
                    screenConf={state}
                  ></DropDownWidget>

                  <DropDownWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dropdownfieldwidget205}
                    screenConf={state}
                  ></DropDownWidget>
                </GroupBoxWidget>

                <GroupBoxWidget
                  conf={state.groupboxwidget30}
                  screenConf={state}
                >
                  <LabelWidget
                    values={values}
                    conf={state.labelwidget31}
                    screenConf={state}
                  ></LabelWidget>

                  <LinkWidget
                    conf={state.linkwidget32}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></LinkWidget>

                  <WhiteSpaceWidget
                    conf={state.whitespacewidget34}
                    screenConf={state}
                  ></WhiteSpaceWidget>
                </GroupBoxWidget>

                <Tab.Container
                  activeKey={state.tabcontainerwidget37.activeTab}
                  onSelect={(key) => {
                    switchToTab(thisObj,key);
                  }}
                >
                  <Col
                    sm={state.tabcontainerwidget37.Horizontal ? "12" : "3"}
                  >
                    <Nav
                      variant={
                        state.tabcontainerwidget37.Horizontal &&
                          state.tabcontainerwidget37.variant === "tabs"
                          ? "tabs"
                          : "pills"
                      }
                      className={
                        state.tabcontainerwidget37.Horizontal
                          ? ""
                          : "flex-column"
                      }
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="tabwidget38">
                          {state.tabwidget38.Label}
                        </Nav.Link>
                      </Nav.Item>{" "}
                      <Nav.Item>
                        <Nav.Link eventKey="tabwidget39">
                          {state.tabwidget39.Label}
                        </Nav.Link>
                      </Nav.Item>{" "}
                      <Nav.Item>
                        <Nav.Link eventKey="tabwidget40">
                          {state.tabwidget40.Label}
                        </Nav.Link>
                      </Nav.Item>{" "}
                    </Nav>
                  </Col>
                  <Col
                    sm={state.tabcontainerwidget37.Horizontal ? "12" : "9"}
                    className={
                      state.tabcontainerwidget37.Horizontal ? "pt-2" : ""
                    }
                  >
                    <Row>
                      <Tab.Content className="w-100">
                        <Tab.Pane eventKey="tabwidget38">
                          <GroupBoxWidget
                            conf={state.groupboxwidget41}
                            screenConf={state}
                          >
                            <TextBoxWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.textboxwidget42}
                              screenConf={state}
                            ></TextBoxWidget>

                            <DateWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.datewidget43}
                              screenConf={state}
                            ></DateWidget>

                            <DropDownWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.dropdownfieldwidget44}
                              screenConf={state}
                            ></DropDownWidget>

                            <DateWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.datetimewidget183}
                              screenConf={state}
                            ></DateWidget>

                            <DateWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.timewidget184}
                              screenConf={state}
                            ></DateWidget>

                            <TextBoxWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.textboxwidget187}
                              screenConf={state}
                            ></TextBoxWidget>

                            <TextBoxWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.textboxwidget190}
                              screenConf={state}
                            ></TextBoxWidget>
                          </GroupBoxWidget>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tabwidget39">
                          <GridWidget
                            conf={state.gridwidget45}
                            screenConf={state}
                            linkClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                            errors={errors}
                            touched={touched}
                            rows={values.gridwidget45}
                            onEvent={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                          ></GridWidget>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tabwidget40">
                          <GroupBoxWidget
                            conf={state.groupboxwidget52}
                            screenConf={state}
                          >
                            <FileWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.filewidget53}
                              screenConf={state}
                            ></FileWidget>

                            <ImageWidget
                              onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                              onBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              conf={state.imagewidget54}
                              screenConf={state}
                            />
                          </GroupBoxWidget>
                        </Tab.Pane>
                      </Tab.Content>
                    </Row>
                  </Col>
                </Tab.Container>

                <GridWidget
                  conf={state.gridwidget101}
                  screenConf={state}
                  linkClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  errors={errors}
                  touched={touched}
                  rows={values.gridwidget101}
                  onEvent={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                ></GridWidget>

                <GridWidget
                  conf={state.gridwidget148}
                  screenConf={state}
                  linkClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  errors={errors}
                  touched={touched}
                  rows={values.gridwidget148}
                  onEvent={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                ></GridWidget>

                <CommandContainerWidget
                  conf={state.commandcontainerwidget35}
                  screenConf={state}
                >
                  <ButtonWidget
                    conf={state.buttonwidget36}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>

                  <ButtonWidget
                    conf={state.buttonwidgetClose}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>
                  <ButtonWidget
                    conf={state.buttonwidgetCancel}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>

                  <ButtonWidget
                    conf={state.buttonwidget196}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>

                  <ButtonWidget
                    conf={state.buttonwidget197}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>
                </CommandContainerWidget>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  
}
export default withRouter(Test_RTL);
