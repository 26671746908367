/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
  getValue,
  getGridValue,
  setGridValue,
  disable,
  hideColumn,
  goTo,
} from "../../shared/WindowImports";

import "./FedWhouseLicenseProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";

import Loading from "../../../Loader/Loading";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FedWhouseLicenseProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FedWhouseLicenseProfile",
    windowName: "FedWhouseLicenseProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FedWhouseLicenseProfile",
    // START_USER_CODE-USER_FedWhouseLicenseProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Federal Warehouse License Profile",
      scrCode: "PN0190B",
    },
    // END_USER_CODE-USER_FedWhouseLicenseProfile_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "29",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "29",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    colPnutTyp: {
      name: "colPnutTyp",
      type: "GridColumnWidget",
      parent: "gridLicProf",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutTyp_PROPERTIES

      // END_USER_CODE-USER_colPnutTyp_PROPERTIES
    },
    colPnutTypId: {
      name: "colPnutTypId",
      type: "GridColumnWidget",
      parent: "gridLicProf",
      Label: "Peanut Type Id",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutTypId_PROPERTIES

      // END_USER_CODE-USER_colPnutTypId_PROPERTIES
    },
    colShrinkWtPct: {
      name: "colShrinkWtPct",
      type: "GridColumnWidget",
      parent: "gridLicProf",
      Label: "Shrink Weight Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShrinkWtPct_PROPERTIES

      // END_USER_CODE-USER_colShrinkWtPct_PROPERTIES
    },
    colShrnkValPct: {
      name: "colShrnkValPct",
      type: "GridColumnWidget",
      parent: "gridLicProf",
      Label: "Shrink Value Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShrnkValPct_PROPERTIES

      // END_USER_CODE-USER_colShrnkValPct_PROPERTIES
    },
    ddByngPtCntrl: {
      name: "ddByngPtCntrl",
      type: "DropDownFieldWidget",
      parent: "grpbxFedWhouse",
      Label: "Buying Point Control:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddByngPtCntrl_PROPERTIES

      // END_USER_CODE-USER_ddByngPtCntrl_PROPERTIES
    },
    dtStrgPaid: {
      name: "dtStrgPaid",
      type: "DateWidget",
      parent: "grpbxRateLayout",
      Label: "Storage Paid Through Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_dateCalender",
      // START_USER_CODE-USER_dtStrgPaid_PROPERTIES

      // END_USER_CODE-USER_dtStrgPaid_PROPERTIES
    },
    gridLicProf: {
      name: "gridLicProf",
      type: "GridWidget",
      parent: "grpbxFedWhouse",
      gridCellsOrder:
        "txtcolPnutTyp,txtcolPnutTypId,txtcolShrinkWtPct,txtcolShrnkValPct",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridLicProf_PROPERTIES

      // END_USER_CODE-USER_gridLicProf_PROPERTIES
    },
    grpbxBegEnd: {
      name: "grpbxBegEnd",
      type: "GroupBoxWidget",
      parent: "grpbxFedWhouse",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxBegEnd_PROPERTIES

      // END_USER_CODE-USER_grpbxBegEnd_PROPERTIES
    },
    grpbxRateLayout: {
      name: "grpbxRateLayout",
      type: "GroupBoxWidget",
      parent: "grpbxFedWhouse",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxRateLayout_PROPERTIES

      // END_USER_CODE-USER_grpbxRateLayout_PROPERTIES
    },
    lblDaco: {
      name: "lblDaco",
      type: "LabelWidget",
      parent: "grpbxBegEnd",
      Label: "DACO",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDaco_PROPERTIES

      // END_USER_CODE-USER_lblDaco_PROPERTIES
    },
    lblEwhseRcpt: {
      name: "lblEwhseRcpt",
      type: "LabelWidget",
      parent: "grpbxBegEnd",
      Label: "E Whse Rcpt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEwhseRcpt_PROPERTIES

      // END_USER_CODE-USER_lblEwhseRcpt_PROPERTIES
    },
    lblWhseRcpt: {
      name: "lblWhseRcpt",
      type: "LabelWidget",
      parent: "grpbxBegEnd",
      Label: "Whse Rcpt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWhseRcpt_PROPERTIES

      // END_USER_CODE-USER_lblWhseRcpt_PROPERTIES
    },
    textboxwidget40: {
      name: "textboxwidget40",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget40_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_textboxwidget40_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtBeg1: {
      name: "txtBeg1",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "Beg Number:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBeg1_PROPERTIES

      // END_USER_CODE-USER_txtBeg1_PROPERTIES
    },
    txtBeg2: {
      name: "txtBeg2",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "Beg Number:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBeg2_PROPERTIES

      // END_USER_CODE-USER_txtBeg2_PROPERTIES
    },
    txtBeg3: {
      name: "txtBeg3",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "Beg Number:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBeg3_PROPERTIES

      // END_USER_CODE-USER_txtBeg3_PROPERTIES
    },
    txtCity: {
      name: "txtCity",
      type: "TextBoxWidget",
      parent: "grpbxFedWhouse",
      Label: "City:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity_PROPERTIES

      // END_USER_CODE-USER_txtCity_PROPERTIES
    },
    txtcolPnutTyp: {
      name: "txtcolPnutTyp",
      type: "TextBoxWidget",
      colName: "colPnutTyp",
      parent: "gridLicProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // ReadOnly: true,
      // START_USER_CODE-USER_txtcolPnutTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutTyp_PROPERTIES
    },
    txtcolPnutTypId: {
      name: "txtcolPnutTypId",
      type: "TextBoxWidget",
      colName: "colPnutTypId",
      parent: "gridLicProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPnutTypId_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutTypId_PROPERTIES
    },
    txtcolShrinkWtPct: {
      name: "txtcolShrinkWtPct",
      type: "TextBoxWidget",
      colName: "colShrinkWtPct",
      parent: "gridLicProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShrinkWtPct_PROPERTIES

      // END_USER_CODE-USER_txtcolShrinkWtPct_PROPERTIES
    },
    txtcolShrnkValPct: {
      name: "txtcolShrnkValPct",
      type: "TextBoxWidget",
      colName: "colShrnkValPct",
      parent: "gridLicProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShrnkValPct_PROPERTIES

      // END_USER_CODE-USER_txtcolShrnkValPct_PROPERTIES
    },
    txtEnd1: {
      name: "txtEnd1",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "End Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEnd1_PROPERTIES

      // END_USER_CODE-USER_txtEnd1_PROPERTIES
    },
    txtEnd2: {
      name: "txtEnd2",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "End Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEnd2_PROPERTIES

      // END_USER_CODE-USER_txtEnd2_PROPERTIES
    },
    txtEnd3: {
      name: "txtEnd3",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "End Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEnd3_PROPERTIES

      // END_USER_CODE-USER_txtEnd3_PROPERTIES
    },
    txtEwrEmail: {
      name: "txtEwrEmail",
      type: "TextBoxWidget",
      parent: "grpbxFedWhouse",
      Label: "EWR E-mail address:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEwrEmail_PROPERTIES

      // END_USER_CODE-USER_txtEwrEmail_PROPERTIES
    },
    txtFedWhouseLic: {
      name: "txtFedWhouseLic",
      type: "TextBoxWidget",
      parent: "grpbxFedWhouse",
      Label: "Federal Warehouse License:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFedWhouseLic_PROPERTIES

      // END_USER_CODE-USER_txtFedWhouseLic_PROPERTIES
    },
    txtFedWhsLicNm: {
      name: "txtFedWhsLicNm",
      type: "TextBoxWidget",
      parent: "grpbxFedWhouse",
      Label: "Federal Whs License Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFedWhsLicNm_PROPERTIES

      // END_USER_CODE-USER_txtFedWhsLicNm_PROPERTIES
    },
    txtMnthlyStrgRate: {
      name: "txtMnthlyStrgRate",
      type: "TextBoxWidget",
      parent: "grpbxRateLayout",
      Label: "Monthly Storage Rate:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMnthlyStrgRate_PROPERTIES

      // END_USER_CODE-USER_txtMnthlyStrgRate_PROPERTIES
    },
    txtNxt1: {
      name: "txtNxt1",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "Next Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNxt1_PROPERTIES

      // END_USER_CODE-USER_txtNxt1_PROPERTIES
    },
    txtNxt2: {
      name: "txtNxt2",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "Next Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNxt2_PROPERTIES

      // END_USER_CODE-USER_txtNxt2_PROPERTIES
    },
    txtNxt3: {
      name: "txtNxt3",
      type: "TextBoxWidget",
      parent: "grpbxBegEnd",
      Label: "Next Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNxt3_PROPERTIES

      // END_USER_CODE-USER_txtNxt3_PROPERTIES
    },
    txtShrunkLoad: {
      name: "txtShrunkLoad",
      type: "TextBoxWidget",
      parent: "grpbxRateLayout",
      Label: "Shrunk Loadout:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrunkLoad_PROPERTIES

      // END_USER_CODE-USER_txtShrunkLoad_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxFedWhouse",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtStorgRate: {
      name: "txtStorgRate",
      type: "TextBoxWidget",
      parent: "grpbxRateLayout",
      Label: "Storage Rate:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorgRate_PROPERTIES

      // END_USER_CODE-USER_txtStorgRate_PROPERTIES
    },
    txtUnshrunkLoad: {
      name: "txtUnshrunkLoad",
      type: "TextBoxWidget",
      parent: "grpbxRateLayout",
      Label: "Unshrunk Loadout:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUnshrunkLoad_PROPERTIES

      // END_USER_CODE-USER_txtUnshrunkLoad_PROPERTIES
    },
    txtWhouseSign: {
      name: "txtWhouseSign",
      type: "TextBoxWidget",
      parent: "grpbxFedWhouse",
      Label: "Warehouseman Signature",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhouseSign_PROPERTIES

      // END_USER_CODE-USER_txtWhouseSign_PROPERTIES
    },
    grpbxFedWhouse: {
      name: "grpbxFedWhouse",
      type: "GroupBoxWidget",
      parent: "FedWhouseLicenseProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFedWhouse_PROPERTIES

      // END_USER_CODE-USER_grpbxFedWhouse_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FedWhouseLicenseProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);

    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);


  // START_USER_CODE-USER_METHODS

  async function FormLoad() {
    setLoading(true)
    disable(thisObj, 'txtCity')
    disable(thisObj, 'txtState')
    hideColumn(thisObj,'gridLicProf','txtcolPnutTypId')
    let dataComingFromParent = getData(thisObj, 'FedWhouseLicenseProfile')
    if (dataComingFromParent != '', dataComingFromParent != undefined, dataComingFromParent != null) {
      if (dataComingFromParent.cmdOk_Caption == 'Add') {
        await bFillBuyingPointList();
        await onddByngPtCntrlChange();
        await bFillCityState();
        document.getElementById('btnOk').innerText = 'Add'
      } else {
        await bFillEditBuyingPointList();
        await onddByngPtCntrlChange();
        await settingFormLoadData();
        disable(thisObj, 'txtFedWhouseLic')
        document.getElementById('btnOk').innerText = 'Update'
        await ontxtFedWhouseLicBlur();
      }
      
    }
    setLoading(false)
  }

   const settingFormLoadData = async () => {
    let parentData = getData(thisObj, 'FedWhouseLicenseProfile');
    setValue(thisObj, 'txtFedWhouseLic', parentData.fed_whouse_license)
    setValue(thisObj, "txtFedWhsLicNm", parentData.fed_whs_lic_name)
    setValue(thisObj, 'txtBeg1', parentData.daco_beg_num)
    setValue(thisObj, 'txtBeg2', parentData.whse_rcpt_beg_num)
    setValue(thisObj, 'txtBeg3', parentData.e_whse_rcpt_beg_num)
    setValue(thisObj, 'txtEnd1', parentData.daco_end_num)
    setValue(thisObj, 'txtEnd2', parentData.whse_rcpt_end_num)
    setValue(thisObj, 'txtEnd3', parentData.e_whse_rcpt_end_num)
    setValue(thisObj, 'txtNxt1', parentData.daco_next_num)
    setValue(thisObj, 'txtNxt2', parentData.whse_rcpt_next_num)
    setValue(thisObj,'txtNxt3', parentData.e_whse_rcpt_next_num)
    setValue(thisObj, 'txtStorgRate', (parentData.shorage_rate))
    setValue(thisObj, 'txtMnthlyStrgRate', parentData.monthly_storage_rate)
    setValue(thisObj, 'txtShrunkLoad', parentData.shrunk_loadout)
    setValue(thisObj, 'txtUnshrunkLoad', parentData.unshrunk_loadout)
    setValue(thisObj, 'dtStrgPaid', parentData.storage_paid_thru_date)
    setValue(thisObj, 'txtEwrEmail', parentData.ewr_email_recipient)
    setValue(thisObj, 'txtWhouseSign', parentData.warehouseman_signature)
    setValue(thisObj, 'txtAddedBy', parentData.addUser+' ' + parentData.addTime)
    setValue(thisObj,'textboxwidget40' , parentData.chgUser + ' ' + parentData.chgTime)
  

   }

  // Method for binding Buying Points ---START
  const bFillBuyingPointList = async () => {
    let js = []
    let data = await SystemMaintenanceMasterManagementService.RetrieveBuyingpointControlsWarehouseLicenseDetails(compId, cropYear, null, null)
    if (data.length != 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddByngPtCntrl: {
            ...state["ddByngPtCntrl"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddByngPtCntrl', js.at(0).key);
      setData(thisObj, 'ddByngPtCntrl', js.at(0).key);
    }

  }
  // Function for city and state

  const bFillCityState = async () => {
    let buy_pt_id = [undefined, null].includes(getValue(thisObj, "ddByngPtCntrl")) ? [undefined, null].includes(getData(thisObj, "ddByngPtCntrl")) ? '' : getData(thisObj, "ddByngPtCntrl") : getValue(thisObj, "ddByngPtCntrl")
    
    let data = await SystemMaintenanceMasterManagementService.RetrieveBuyingpointControlsWarehouseLicenseDetails(null, null, null, buy_pt_id)

    if (data.length > 0) {
      setValue(thisObj, 'txtCity', data[0].physical_city)
      setValue(thisObj, 'txtState', data[0].physical_state)
    }
    else {
      setValue(thisObj, 'txtCity', "")
      setValue(thisObj, 'txtState', "")
    }
  }
  // on changing of buying point -----------
  const onddByngPtCntrlChange = async () => {
    try {
      let dataComingFromParent = getData(thisObj, "FedWhouseLicenseProfile");
      if (dataComingFromParent != '', dataComingFromParent != undefined, dataComingFromParent != null) {
        if (dataComingFromParent.cmdOk_Caption == "Add") {
          await bFillCityState()
        }
        else {
          await bFillEditCityState()
        }
      }
      return;

    }
    catch (err) {
      // setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddByngPtCntrlChange event"
        );
      }
    }
  }
  thisObj.onddByngPtCntrlChange = onddByngPtCntrlChange;

  //Edit city state functionality
  const bFillEditCityState = async () => {
    try {
      setLoading(true);
      let buy_pt_id = [undefined, null].includes(getValue(thisObj, "ddByngPtCntrl")) ? [undefined, null].includes(getData(thisObj, "ddByngPtCntrl")) ? '' : getData(thisObj, "ddByngPtCntrl") : getValue(thisObj, "ddByngPtCntrl")
      if(buy_pt_id==null){
        setValue(thisObj, 'txtCity', "")
        setValue(thisObj, 'txtState', "")
        return;
      }

      let response = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(null, buy_pt_id);
      let data = response;

      if (data.length > 0) {
        setValue(thisObj, 'txtCity', data[0].whouse_licenses[0].physical_city)
        setValue(thisObj, 'txtState', data[0].whouse_licenses[0].physical_state)
      }
      else {
        setValue(thisObj, 'txtCity', "")
        setValue(thisObj, 'txtState', "")
      }
      setLoading(false)
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillEditCityState event"
        );
      }
    }
  }

  // Method for binding Edit Buying Points ---START
  const bFillEditBuyingPointList = async () => {
    let fed_whouse_Data = getData(thisObj, "FedWhouseLicenseProfile");
    let fedwhouselicense = fed_whouse_Data.fed_whouse_license
    let BuyingPointId = null;

    let js = []
    let data = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(fedwhouselicense, BuyingPointId)
    data = data[0].whouse_licenses
    if (data.length != 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = {
          key: data[i].buy_pt_id,
          description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim(),
          city: data[i].physical_city,
          State: data[i].physical_state,
          index:i
        }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddByngPtCntrl: {
            ...state["ddByngPtCntrl"],
            valueList: js
          }
        }
      })
      let buy_pt_id=fed_whouse_Data.buy_pt_id
      let index=js.find(elem=>elem.key===buy_pt_id)?.index || 0
      setValue(thisObj, 'ddByngPtCntrl', js.at(index).key);
      setData(thisObj, 'ddByngPtCntrl', js.at(index).key);
    }

  }
  // FormValidation function...

  const bFormValid = async() => {
    try {
      setLoading(true);
      let bFormValid = false

      const FedWhouseLicValue = getValue(thisObj, 'txtFedWhouseLic')?.trim();
      if (FedWhouseLicValue == '' || FedWhouseLicValue == undefined) {
        showMessage(thisObj, 'Federal Warehouse License is a required field.');
        document.getElementById('txtFedWhouseLic').focus();
        setLoading(false)
        return false;
      }
      // DACO......

      const DacoBegNumValue = getValue(thisObj, 'txtBeg1')?.trim();
      if (DacoBegNumValue == '' || DacoBegNumValue == undefined || DacoBegNumValue == null) {
        showMessage(thisObj, 'DACO Begining Number is a required field.');
        document.getElementById('txtBeg1').focus();
        setLoading(false)
        return false;
      }

      const DacoEndNumValue = getValue(thisObj, 'txtEnd1')?.trim();
      if (DacoEndNumValue == '' || DacoEndNumValue == undefined || DacoEndNumValue === null) {
        showMessage(thisObj, 'DACO End Number is a required field.');
        document.getElementById('txtEnd1').focus();
        setLoading(false)
        return false;
      }

      const DacoNxtNumValue = getValue(thisObj, 'txtNxt1')?.trim();
      if (DacoNxtNumValue == '' || DacoNxtNumValue == undefined || DacoNxtNumValue == null) {
        showMessage(thisObj, 'DACO Next Number is a required field.');
        document.getElementById('txtNxt1').focus();
        setLoading(false)
        return false;
      }

      // Whse Rcpt......

      const WhouseBegNumValue = getValue(thisObj, 'txtBeg2')?.trim();
      if (WhouseBegNumValue == '' || WhouseBegNumValue == undefined || WhouseBegNumValue == null) {
        showMessage(thisObj, 'Whse Rcpt. Begining Number is a required field.');
        document.getElementById('txtBeg2').focus();
        setLoading(false)
        return false;
      }

      const WhouseEndNumValue = getValue(thisObj, 'txtEnd2')?.trim();
      if (WhouseEndNumValue == '' || WhouseEndNumValue == undefined || WhouseEndNumValue == null) {
        showMessage(thisObj, 'Whse Rcpt. End Number is a required field.');
        document.getElementById('txtEnd2').focus();
        setLoading(false)
        return false;
      }

      const WhouseNextNumValue = getValue(thisObj, 'txtNxt2')?.trim();
      if (WhouseNextNumValue == '' || WhouseNextNumValue == undefined || WhouseNextNumValue == null) {
        showMessage(thisObj, 'Whse Rcpt. Next Number is a required field.');
        document.getElementById('txtNxt2').focus();
        setLoading(false)
        return false;
      }

      //E Whse Rcpt......

      const EWhouseBegNumValue = getValue(thisObj, 'txtBeg3')?.trim();
      if (EWhouseBegNumValue == '' || EWhouseBegNumValue == undefined || EWhouseBegNumValue == null) {
        showMessage(thisObj, 'E Whse Rcpt. Begining Number is a required field.');
        document.getElementById('txtBeg3').focus();
        setLoading(false)
        return false;
      }

      const EWhouseEndNumValue = getValue(thisObj, 'txtEnd3')?.trim();
      if (EWhouseEndNumValue == '' || EWhouseEndNumValue == undefined || EWhouseEndNumValue == null) {
        showMessage(thisObj, 'E Whse Rcpt. End Number is a required field.');
        document.getElementById('txtEnd3').focus();
        setLoading(false)
        return false;
      }

      const EWhouseNextNumValue = getValue(thisObj, 'txtNxt3')?.trim();
      if (EWhouseNextNumValue == '' || EWhouseNextNumValue == undefined || EWhouseNextNumValue == null) {
        showMessage(thisObj, 'E Whse Rcpt. Next Number is a required field.');
        document.getElementById('txtNxt3').focus();
        setLoading(false)
        return false;
      }

      // Storage, Shrunk Load and Shrunk Unload........

      const StorageRateValue = getValue(thisObj, 'txtStorgRate');
      if (StorageRateValue == '' || StorageRateValue == undefined || StorageRateValue == null) {
        showMessage(thisObj, 'Storage Rate is a required field.');
        document.getElementById('txtStorgRate').focus();
        setLoading(false)
        return false;
      }

      const ShrunkLoadoutValue = getValue(thisObj, 'txtShrunkLoad');
      if (ShrunkLoadoutValue == '' || ShrunkLoadoutValue == undefined || ShrunkLoadoutValue == null) {
        showMessage(thisObj, 'Shrunk Loadout is a required field.');
        document.getElementById('txtShrunkLoad').focus();
        setLoading(false)
        return false;
      }

      const UnshrunkLoadoutValue = getValue(thisObj, 'txtUnshrunkLoad');
      if (UnshrunkLoadoutValue == '' || UnshrunkLoadoutValue == undefined || UnshrunkLoadoutValue == null) {
        showMessage(thisObj, 'Unshrunk Loadout is a required field.');
        document.getElementById('txtUnshrunkLoad').focus();
        setLoading(false)
        return false;
      }

      // numeric & no. range validation condition for all text 
      // DACO......
      if (isNaN(DacoBegNumValue) == false) {
        if (DacoBegNumValue < 0 || DacoBegNumValue > 999999) {
          showMessage(thisObj, 'DACO Begining Number must be between 0 and 999999.');
          document.getElementById('txtBeg1').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'DACO Begining Number must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(DacoEndNumValue) == false) {
        if (DacoEndNumValue < 0 || DacoEndNumValue > 999999) {
          showMessage(thisObj, 'DACO Ending Number must be between 0 and 999999.');
          document.getElementById('txtEnd1').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'DACO Ending Number must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(DacoNxtNumValue) == false) {
        if (DacoNxtNumValue < 0 || DacoNxtNumValue > 999999) {
          showMessage(thisObj, 'DACO Next Number must be between 0 and 999999.');
          document.getElementById('txtNxt1').focus();
          setLoading(false)
          return false;
        } if ((Number(DacoNxtNumValue) < Number(DacoBegNumValue)) || (Number(DacoNxtNumValue) > Number(DacoEndNumValue))) {
          showMessage(thisObj, 'DACO Next Number must be between DACO Begining and DACO Ending numbers.');
          document.getElementById('txtNxt1').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'DACO Next Number must be numeric.');
        setLoading(false)
        return false;
      }

      // Whse Rcpt........

      if (isNaN(WhouseBegNumValue) == false) {
        if (WhouseBegNumValue < 0 || WhouseBegNumValue > 999999) {
          showMessage(thisObj, 'Whse Rcpt. Begining Number must be between 0 and 999999.');
          document.getElementById('txtBeg2').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'Whse Rcpt. Begining Number must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(WhouseEndNumValue) == false) {
        if (WhouseEndNumValue < 0 || WhouseEndNumValue > 999999) {
          showMessage(thisObj, 'Whse Rcpt. Ending Number must be between 0 and 999999.');
          document.getElementById('txtEnd2').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'Whse Rcpt. Ending Number must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(WhouseNextNumValue) == false) {
        if (WhouseNextNumValue < 0 || WhouseNextNumValue > 999999) {
          showMessage(thisObj, 'Whse Rcpt. Next Number must be between 0 and 999999.');
          document.getElementById('txtNxt2').focus();
          setLoading(false)
          return false;
        } if ((Number(WhouseNextNumValue) < Number(WhouseBegNumValue) || Number(WhouseNextNumValue) > Number(WhouseEndNumValue))) {
          showMessage(thisObj, 'Whse Rcpt. Next Number must be between Whse Rcpt. Begining and Whse Rcpt. Ending numbers.');
          document.getElementById('txtNxt2').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'Whse Rcpt. Next Number must be numeric.');
        setLoading(false)
        return false;
      }

      // E Whse Rcpt........

      if (isNaN(EWhouseBegNumValue) == false) {
        if (EWhouseBegNumValue < 0 || EWhouseBegNumValue > 999999) {
          showMessage(thisObj, 'E Whse Rcpt. Begining Number must be between 0 and 999999.');
          document.getElementById('txtBeg3').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'E Whse Rcpt. Begining Number must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(EWhouseEndNumValue) == false) {
        if (EWhouseEndNumValue < 0 || EWhouseEndNumValue > 999999) {
          showMessage(thisObj, 'E Whse Rcpt. Ending Number must be between 0 and 999999.');
          document.getElementById('txtEnd3').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'E Whse Rcpt. Ending Number must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(EWhouseNextNumValue) == false) {
        if (EWhouseNextNumValue < 0 || EWhouseNextNumValue > 999999) {
          showMessage(thisObj, 'E Whse Rcpt. Next Number must be between 0 and 999999.');
          document.getElementById('txtNxt3').focus();
          setLoading(false)
          return false;
        } if ((Number(EWhouseNextNumValue) < Number(EWhouseBegNumValue) || Number(EWhouseNextNumValue) > Number(EWhouseEndNumValue))) {
          showMessage(thisObj, 'E Whse Rcpt. Next Number must be between E Whse Rcpt. Begining and E Whse Rcpt. Ending numbers.');
          document.getElementById('txtNxt3').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'E Whse Rcpt. Next Number must be numeric.');
        setLoading(false)
        return false;
      }

      // Storage, Shrunk Load and Shrunk Unload........

      if (isNaN(StorageRateValue) == false) {
        if (StorageRateValue < 0 || StorageRateValue > 99.99) {
          showMessage(thisObj, 'Storage Rate must be between 0 and 99.99.');
          document.getElementById('txtStorgRate').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'Storage Rate must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(ShrunkLoadoutValue) == false) {
        if (ShrunkLoadoutValue < 0 || ShrunkLoadoutValue > 9999.99) {
          showMessage(thisObj, 'Shrunk Loadout must be between 0 and 9999.99.');
          document.getElementById('txtUnshrunkLoad').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'Shrunk Loadout must be numeric.');
        setLoading(false)
        return false;
      }

      if (isNaN(UnshrunkLoadoutValue) == false) {
        if (UnshrunkLoadoutValue < 0 || UnshrunkLoadoutValue > 9999.99) {
          showMessage(thisObj, 'Unshrunk Loadout must be between 0 and 9999.99.');
          document.getElementById('txtUnshrunkLoad').focus();
          setLoading(false)
          return false;
        }
      } else {
        showMessage(thisObj, 'Unshrunk Loadout must be numeric.');
        setLoading(false)
        return false;
      }

      // Paid date............

      const Storage_paid_thru_date = getValue(thisObj, 'dtStrgPaid')
      if (Storage_paid_thru_date == '' || Storage_paid_thru_date == undefined) {
        showMessage(thisObj, 'Invalid Storage Paid Date!!!');
        setLoading(false)
        return false;
      }
      bFormValid = true
      setLoading(false);
      return bFormValid

    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Validation event"
        );
      }
      return false;
    }
    
  };

  // ok button click.....

  const onbtnOkClick = async () => {
    try{
    setLoading(true)
    let txtEWhouseBegNum = Number(getValue(thisObj, 'txtBeg3'))
    let txtWhouseBegNum = Number(getValue(thisObj, 'txtBeg2'))
    let txtWhouseEndNum = Number(getValue(thisObj, 'txtEnd2'))
    let txtEWhouseEndNum = Number(getValue(thisObj, 'txtEnd3'))
    let FedWhouseLicenseProfile = getData(thisObj, 'FedWhouseLicenseProfile')
    let okCaption = FedWhouseLicenseProfile.cmdOk_Caption
    let fed_whouse_license = getValue(thisObj, 'txtFedWhouseLic')
    let buy_pt_id = getValue(thisObj, 'ddByngPtCntrl')
    const useridFromLS = () => (sessionStorage.getItem('userid'))


    if (((txtEWhouseBegNum >= txtWhouseBegNum) && (txtEWhouseBegNum <= txtWhouseEndNum))
      || ((txtEWhouseEndNum >= txtWhouseBegNum) && txtEWhouseEndNum <= txtWhouseEndNum)) {
      showMessage(thisObj, `Electronic Warehouse Receipt Number range overlaps Paper Warehouse Receipt Number range. Correct and continue.`)
      setLoading(false)
      return
    }
    if (await bFormValid() == false) return;
    let obj = {}
    let fed_whs_lic_name = getValue(thisObj, 'txtFedWhsLicNm')==undefined?'':getValue(thisObj, 'txtFedWhsLicNm')
    let txtDacoBegNum = Number(getValue(thisObj, 'txtBeg1'))
    let txtDacoEndNum = Number(getValue(thisObj, 'txtEnd1'))
    let txtDacoNextNum = Number(getValue(thisObj, 'txtNxt1'))
    let txtWhouseNextNum = Number(getValue(thisObj, 'txtNxt2'))
    let txtEWhouseNextNum = Number(getValue(thisObj, 'txtNxt3'))
    let txtStortageRate = Number((getValue(thisObj, 'txtStorgRate'))/100)
    let txtShrunkLoadout = Number(getValue(thisObj, 'txtShrunkLoad'))
    let txtUnshrunkLoadout = Number(getValue(thisObj, 'txtUnshrunkLoad'))
    let txtEWRErrorAddress = (getValue(thisObj, 'txtEwrEmail'))==undefined?'':(getValue(thisObj, 'txtEwrEmail'))
    let txtWarehousemanSignature = (getValue(thisObj, 'txtWhouseSign'))==undefined?'':(getValue(thisObj, 'txtWhouseSign'))
    let txtmonthly_storage_rate = Number(getValue(thisObj, 'txtMnthlyStrgRate'))
    let txtstorage_paid_thru_date = moment(getValue(thisObj, 'dtStrgPaid')).format('YYYY-MM-DD')

    obj.fed_whs_lic_name = fed_whs_lic_name
    obj.daco_beg_num = txtDacoBegNum
    obj.daco_end_num = txtDacoEndNum
    obj.daco_next_num = txtDacoNextNum
    obj.whse_rcpt_beg_num = txtWhouseBegNum
    obj.whse_rcpt_end_num = txtWhouseEndNum
    obj.whse_rcpt_next_num = txtWhouseNextNum
    obj.e_whse_rcpt_beg_num = txtEWhouseBegNum
    obj.e_whse_rcpt_end_num = txtEWhouseEndNum
    obj.e_whse_rcpt_next_num = txtEWhouseNextNum
    obj.shorage_rate = txtStortageRate
    obj.unshrunk_loadout = txtUnshrunkLoadout
    obj.shrunk_loadout = txtShrunkLoadout
    obj.ewr_error_email = txtEWRErrorAddress
    obj.monthly_storage_rate = txtmonthly_storage_rate
    obj.storage_paid_thru_date = txtstorage_paid_thru_date
    obj.warehouseman_signature = txtWarehousemanSignature


    let response = ''
    if (okCaption == 'Add') {
      response = await SystemMaintenanceMasterManagementService.CreateWarehouseLicenseControl(buy_pt_id, fed_whouse_license,obj)
      //The above api is pending from my end
     
    }
    else if (okCaption == 'Update') {
      obj.buy_pt_id = buy_pt_id
      obj.fed_whouse_license = fed_whouse_license
      obj.user_id = useridFromLS()
      obj.chg_user = useridFromLS()
    response = await SystemMaintenanceMasterManagementService.UpdateWhouseLicenseControl(obj)
      //The above api is pending from Backend team and the API service name also has to be changed
      
    }

    if (![200, 400, 404].includes(response.status)) {
      showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
      setLoading(false)
      return;
    }
    else if(response.status!=200){
      showMessage(thisObj, response.message, true)
      setLoading(false)
      return;
    }
    if (okCaption == 'Add') {
      response = await ContractManagementService.RetrievePeanutTypeControls(null)
      let data = response
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let obj1 = {};
          let peanutTypeId = data[i].pnutTypeId
          obj1.fed_whouse_license = getValue(thisObj, 'txtFedWhouseLic')
          obj1.pnut_type_id = peanutTypeId
          obj1.weight_shrink_pct = '0'
          obj1.value_shrink_pct = '0'
          let peanutResponse = await SystemMaintenanceMasterManagementService.CreateShrinkControl(buy_pt_id, obj1)
          if (![200, 400, 404].includes(peanutResponse.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
            setLoading(false)
            return;
          }
        }
      }
    }
    else if (okCaption == 'Update') {
      let gridLength=thisObj.values.gridLicProf.length
      if (gridLength > 0) {
        for (let i = 0; i < gridLength; i++) {
          let obj1 = {};
          obj1.fed_whouse_license = getValue(thisObj, 'txtFedWhouseLic')
          obj1.pnut_type_id = getGridValue(thisObj, 'gridLicProf', 'txtcolPnutTypId', i);
          obj1.weight_shrink_pct = getGridValue(thisObj, 'gridLicProf', 'txtcolShrinkWtPct', i)
          obj1.value_shrink_pct = getGridValue(thisObj, 'gridLicProf', 'txtcolShrnkValPct', i)
          let peanutResponse = await SystemMaintenanceMasterManagementService.CreateShrinkControl(buy_pt_id, obj1)
          if (![200, 400, 404].includes(peanutResponse.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
            setLoading(false)
            return;
          }
        }
      }
    }
    //Passing the data to parent screen is pending
    let WhouseLicenseProfileData={};
    WhouseLicenseProfileData.fed_whouse_license=getValue(thisObj,'txtFedWhouseLic')
    if(okCaption=='Add'){
    setData(thisObj,'WhouseLicenseProfileData',WhouseLicenseProfileData)
    }
    setLoading(false)
    document.getElementById("SystemMaintenanceMasterManagement_FedWhouseLicenseProfilePopUp").childNodes[0].click();
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during Add or Update Button "
      );
    }
    return false;
  }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  // validateTextBox function.....

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }

  // Key press functions for all text boxes......
  const ontxtBeg1Change = () => {
    try {
      setValue(thisObj, "txtBeg1", validateTextBox(txtBeg1.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtBeg1 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtBeg1Change = ontxtBeg1Change;

  const ontxtEnd1Change = () => {
    try {
      setValue(thisObj, "txtEnd1", validateTextBox(txtEnd1.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtEnd1 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtEnd1Change = ontxtEnd1Change;

  const ontxtNxt1Change = () => {
    try {
      setValue(thisObj, "txtNxt1", validateTextBox(txtNxt1.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtNxt1 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtNxt1Change = ontxtNxt1Change;

  const ontxtBeg3Change = () => {
    try {
      setValue(thisObj, "txtBeg3", validateTextBox(txtBeg3.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtBeg3 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtBeg3Change = ontxtBeg3Change;

  const ontxtEnd3Change = () => {
    try {
      setValue(thisObj, "txtEnd3", validateTextBox(txtEnd3.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtEnd3 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtEnd3Change = ontxtEnd3Change;

  const ontxtNxt3Change = () => {
    try {
      setValue(thisObj, "txtNxt3", validateTextBox(txtNxt3.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtNxt3 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtNxt3Change = ontxtNxt3Change;

  const ontxtBeg2Change = () => {
    try {
      setValue(thisObj, "txtBeg2", validateTextBox(txtBeg2.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtBeg2 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtBeg2Change = ontxtBeg2Change;

  const ontxtEnd2Change = () => {
    try {
      setValue(thisObj, "txtEnd2", validateTextBox(txtEnd2.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtEnd2 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtEnd2Change = ontxtEnd2Change;

  const ontxtNxt2Change = () => {
    try {
      setValue(thisObj, "txtNxt2", validateTextBox(txtNxt2.value))

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtNxt2 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtNxt2Change = ontxtNxt2Change;


  // Grid binding..... 

  const ontxtFedWhouseLicBlur = async () => {
    try {
      setLoading(true);
      let parentData = getData(thisObj, 'FedWhouseLicenseProfile');
      let fed_whouse_license=''
      if(parentData.cmdOk_Caption=='Update'){
        fed_whouse_license = parentData.fed_whouse_license
      }
      else{
        fed_whouse_license=getValue(thisObj,'txtFedWhouseLic')
      }
      let LstrList = '';
      LstrList = await SystemMaintenanceMasterManagementService.RetrieveShrinkControlDetails(fed_whouse_license, null)

      let TransformedRowsArray = [];
      if (LstrList.length === 0) {
        setValue(thisObj, "gridLicProf", TransformedRowsArray);
        setLoading(false)
        return;
      }
      else {
        let js = []
        let obj = {}
        for (var i = 0; i < LstrList.length; i++) {
          obj.txtcolPnutTypId = LstrList[i].pnut_type_id
          obj.txtcolPnutTyp = LstrList[i].pnut_type_name
          obj.txtcolShrinkWtPct = LstrList[i].weight_shrink_pct
          obj.txtcolShrnkValPct = LstrList[i].value_shrink_pct
          TransformedRowsArray.push(obj)
          obj = {}
        }
      }
      setValue(thisObj, "gridLicProf", TransformedRowsArray)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtFedWhouseLic_Change event"
        );
      }
    }
  }
  thisObj.ontxtFedWhouseLicBlur = ontxtFedWhouseLicBlur

  // Close button___
  const onbtnCancelClick = () => {
    try {

      document.getElementById("SystemMaintenanceMasterManagement_FedWhouseLicenseProfilePopUp").childNodes[0].click();

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FedWhouseLicenseProfile*/}

              {/* END_USER_CODE-USER_BEFORE_FedWhouseLicenseProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFedWhouse*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFedWhouse*/}

              <GroupBoxWidget conf={state.grpbxFedWhouse} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtFedWhouseLic*/}

                {/* END_USER_CODE-USER_BEFORE_txtFedWhouseLic*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFedWhouseLic}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFedWhouseLic*/}

                {/* END_USER_CODE-USER_AFTER_txtFedWhouseLic*/}
                {/* START_USER_CODE-USER_BEFORE_txtFedWhsLicNm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFedWhsLicNm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFedWhsLicNm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFedWhsLicNm*/}

                {/* END_USER_CODE-USER_AFTER_txtFedWhsLicNm*/}
                {/* START_USER_CODE-USER_BEFORE_ddByngPtCntrl*/}

                {/* END_USER_CODE-USER_BEFORE_ddByngPtCntrl*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddByngPtCntrl}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddByngPtCntrl*/}

                {/* END_USER_CODE-USER_AFTER_ddByngPtCntrl*/}
                {/* START_USER_CODE-USER_BEFORE_txtCity*/}

                {/* END_USER_CODE-USER_BEFORE_txtCity*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCity}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCity*/}

                {/* END_USER_CODE-USER_AFTER_txtCity*/}
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxBegEnd*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBegEnd*/}

                <GroupBoxWidget conf={state.grpbxBegEnd} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblDaco*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDaco*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDaco}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDaco*/}

                  {/* END_USER_CODE-USER_AFTER_lblDaco*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWhseRcpt*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWhseRcpt*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWhseRcpt}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWhseRcpt*/}

                  {/* END_USER_CODE-USER_AFTER_lblWhseRcpt*/}
                  {/* START_USER_CODE-USER_BEFORE_lblEwhseRcpt*/}

                  {/* END_USER_CODE-USER_BEFORE_lblEwhseRcpt*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblEwhseRcpt}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblEwhseRcpt*/}

                  {/* END_USER_CODE-USER_AFTER_lblEwhseRcpt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBeg1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBeg1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBeg1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBeg1*/}

                  {/* END_USER_CODE-USER_AFTER_txtBeg1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBeg2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBeg2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBeg2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBeg2*/}

                  {/* END_USER_CODE-USER_AFTER_txtBeg2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBeg3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBeg3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBeg3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBeg3*/}

                  {/* END_USER_CODE-USER_AFTER_txtBeg3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEnd1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEnd1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEnd1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEnd1*/}

                  {/* END_USER_CODE-USER_AFTER_txtEnd1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEnd2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEnd2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEnd2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEnd2*/}

                  {/* END_USER_CODE-USER_AFTER_txtEnd2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEnd3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEnd3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEnd3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEnd3*/}

                  {/* END_USER_CODE-USER_AFTER_txtEnd3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNxt1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNxt1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNxt1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNxt1*/}

                  {/* END_USER_CODE-USER_AFTER_txtNxt1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNxt2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNxt2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNxt2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNxt2*/}

                  {/* END_USER_CODE-USER_AFTER_txtNxt2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNxt3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNxt3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNxt3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNxt3*/}

                  {/* END_USER_CODE-USER_AFTER_txtNxt3*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBegEnd*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBegEnd*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRateLayout*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRateLayout*/}

                <GroupBoxWidget conf={state.grpbxRateLayout} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtStorgRate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStorgRate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStorgRate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStorgRate*/}

                  {/* END_USER_CODE-USER_AFTER_txtStorgRate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMnthlyStrgRate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMnthlyStrgRate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMnthlyStrgRate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMnthlyStrgRate*/}

                  {/* END_USER_CODE-USER_AFTER_txtMnthlyStrgRate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtShrunkLoad*/}

                  {/* END_USER_CODE-USER_BEFORE_txtShrunkLoad*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtShrunkLoad}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtShrunkLoad*/}

                  {/* END_USER_CODE-USER_AFTER_txtShrunkLoad*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUnshrunkLoad*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUnshrunkLoad*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUnshrunkLoad}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUnshrunkLoad*/}

                  {/* END_USER_CODE-USER_AFTER_txtUnshrunkLoad*/}
                  {/* START_USER_CODE-USER_BEFORE_dtStrgPaid*/}

                  {/* END_USER_CODE-USER_BEFORE_dtStrgPaid*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dtStrgPaid}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_dtStrgPaid*/}

                  {/* END_USER_CODE-USER_AFTER_dtStrgPaid*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRateLayout*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRateLayout*/}
                {/* START_USER_CODE-USER_BEFORE_gridLicProf*/}

                {/* END_USER_CODE-USER_BEFORE_gridLicProf*/}

                <GridWidget
                  conf={state.gridLicProf}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridLicProf}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridLicProf*/}

                {/* END_USER_CODE-USER_AFTER_gridLicProf*/}
                {/* START_USER_CODE-USER_BEFORE_txtEwrEmail*/}

                {/* END_USER_CODE-USER_BEFORE_txtEwrEmail*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEwrEmail}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEwrEmail*/}

                {/* END_USER_CODE-USER_AFTER_txtEwrEmail*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhouseSign*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhouseSign*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhouseSign}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhouseSign*/}

                {/* END_USER_CODE-USER_AFTER_txtWhouseSign*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFedWhouse*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFedWhouse*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_textboxwidget40*/}

                {/* END_USER_CODE-USER_BEFORE_textboxwidget40*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.textboxwidget40}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_textboxwidget40*/}

                {/* END_USER_CODE-USER_AFTER_textboxwidget40*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FedWhouseLicenseProfile*/}

              {/* END_USER_CODE-USER_AFTER_FedWhouseLicenseProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FedWhouseLicenseProfile
);
