import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import "./GridWidget.scss";
import PopoverActions from "./PopoverActions/PopoverActions";
import ParentGridWidget from "./ParentGridWidget";
import { FieldArray } from "formik";
import { toast } from "react-toastify";
const _kaledo = window._kaledo;

createTheme("cobol", {
  text: {
    primary: "#26d22b",
  },
  background: {
    default: "transparent",
  },
  context: {
    background: "#E91E63",
    text: "#FFFFFF",
  },
  divider: {
    default: "rgba(81, 81, 81, 1)",
  },
  button: {
    default: "#FFFFFF",
    focus: "rgba(255, 255, 255, .54)",
    hover: "rgba(255, 255, 255, .12)",
    disabled: "rgba(255, 255, 255, .18)",
  },
  sortFocus: {
    default: "rgba(255, 255, 255, .54)",
  },
  selected: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  highlightOnHover: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  striped: {
    default: "rgba(0, 0, 0, .87)",
    text: "#FFFFFF",
  },
});

class GridWidget extends ParentGridWidget {
  constructor(props) {
    super(props);
    this.initializeGrid();
    this.conf.updatedRows = [];
  }

  state = {
    open: !this.conf.defaultCollapsed,
    editing: {},
    okCancelButtons: [],
    selectedRadio: "",
    isColumnsUpdated: false,
    footerText: "",
  };

  initializeGrid = () => {
    this.prepareGridConfData();
    this.conf.columns.forEach((col) => {
      switch (col.type) {
        case "FileWidget":
          this.defaultNewRow[col.name] = [];
          break;
        default:
          this.defaultNewRow[col.name] = "";
      }
      if (col.isStatusColumn) {
        this.statusColName = col.name;
      }
    });
    this.defaultNewRow[this.statusColName] = "C";
    this.actionList[0].required = this.conf.isAddRowRequired;
    this.actionList[2].required = this.conf.isDeleteRowRequired;
    this.conf.isLoading = false;
  };

  componentDidMount = () => {
    this.conf.actionList = this.actionList;
    this.conf.statusColName = this.statusColName;
    this.conf.onSelect = this.onSelect;
    this.attachHotKeys();
    //Start - Subbarao - Avoid tabing for grid header
    if (_kaledo.isCobolTable) {
      setTimeout(function () {
        var elems = document.querySelectorAll(
          ".rdt_TableHead .rdt_TableCol .rdt_TableCol_Sortable"
        );
        for (var i = 0; i < elems.length; i++) {
          elems[i].removeAttribute("tabindex");
        }
      }, 0);
    }
    //End - Subbarao - Avoid tabing for grid header
  };

  componentDidUpdate(prevProps) {
    if (this.props.conf.selected) {
      if (this.props.conf.selected.length === 0) {
        if (this.selected) this.selected = [];
      }
    }
    if (prevProps.rows.length !== this.props.rows.length) {
      if (this.conf.page && _kaledo.isCobolTable) {
        let totalPages = Math.ceil(this.rows.length / this.conf.page.size);
        if (this.conf.page.pageNumber === 1) {
          if (totalPages !== 1) {
            this.setState({ footerText: "More +" });
          } else if (totalPages === 1) {
            this.setState({ footerText: "" });
          }
        }
      }
    }
  }

  updateColumnVisibility = () => {
    let isVisibilityChange = this.columns.some(
      (column, index) => column.omit !== !this.conf.columns[index].Visible
    );
    if (isVisibilityChange) {
      this.columns.forEach((column, index) => {
        column.omit = !this.conf.columns[index].Visible;
      });
      this.columns = [...this.columns];
    }
  };

  getFooterText = () => {
    if (_kaledo.isCobolTable) {
      let totalPages = Math.ceil(this.rows.length / this.conf.page.size);
      if (this.conf.page.pageNumber === 1 && totalPages > 1) {
        this.setState({ footerText: "More +" });
      } else if (this.conf.page.pageNumber === totalPages) {
        this.setState({ footerText: "- More" });
      } else {
        this.setState({ footerText: "- More +" });
      }
    }
  };

  render() {
    this.conf = this.props.conf;
    this.screenConf = this.props.screenConf;
    let cobolProperties = {};
    let SampleExpandedComponent = ({ data }) => (
      <div className="py-3 expandable-area col-sm-12">
        {`Please define template for expandable component`}
      </div>
    );

    if (!_kaledo.isCobolTable) {
      this.updateColumnVisibility();
    } else {
      cobolProperties = {
        theme: "cobol",
      };
    }

    if (this.conf.isExpandable) {
      if (this.conf.expandedCompTemplate) {
        SampleExpandedComponent = this.conf.expandedCompTemplate;
      } else {
        toast.error(
          `Please define template for expandable component of ${this.conf.name}`
        );
      }
    }

    let paginationRowsPerPageOptions = [
      parseInt(this.conf.DisplaySize),
      parseInt(this.conf.DisplaySize) + 5,
      parseInt(this.conf.DisplaySize) + 10,
      parseInt(this.conf.DisplaySize) + 15,
    ];
    const toggleClasses = ["pr-1", "fa"];

    this.state.open
      ? toggleClasses.push("fa-caret-down")
      : toggleClasses.push("fa-caret-right");
    const customNoDataComponent = (
      <div>      <table>      <thead>      <tr>
        {this.columns.map((column) => <th key={column.name}>{column.name}</th>)}
      </tr>       </thead>       </table>       </div>)
    let rdtTemplate = (
      <React.Fragment>
        <FieldArray
          name={this.conf.name}
          render={(arrayHelpers) => {
            this.arrayHelpers = arrayHelpers;
            this.setRowID();
            return (
              <DataTable
                columns={this.columns}
                data={this.rows}
                sortServer={this.conf.Pagination}
                onSort={(column, sortDirection) => {
                  if (this.props.conf.sort) {
                    this.props.conf.sort.prop = column.selector;
                    this.props.conf.sort.dir = sortDirection;
                  } else {
                    this.props.conf.sort = {
                      prop: column.selector,
                      dir: sortDirection,
                    };
                  }

                  let event = {
                    type: "PagerORsort",
                    target: {
                      id: this.conf.name,
                    },
                  };
                  this.props.onEvent(event);
                }}
                selectableRows={this.conf.isMultiSelect}
                selectableRowsNoSelectAll={!this.conf.isMultiSelect}
                selectableRowsHighlight={true}
                onSelectedRowsChange={this.onSelect}
                selectableRowSelected={this.checkSelected}
                onRowClicked={this.handleRowClicked}
                selectableRowDisabled={this.conf.selectableRowDisabled}
                conditionalRowStyles={this.conf.conditionalRowStyles}
                onRowDoubleClicked={() => {
                  let event = {
                    type: "Dblclick",
                    target: {
                      id: this.conf.name,
                    },
                  };
                  this.props.onEvent(event)
                }
                }
                {...(this.conf.noDataHeader) && { noDataComponent: customNoDataComponent }}
                // selectableRowsVisibleOnly={false}
                expandableRows={this.conf.isExpandable}
                expandableRowExpanded={(row) =>
                  this.conf.isExpandable && this.conf.defaultExpand
                }
                expandableRowsComponent={<SampleExpandedComponent />}
                expandableRowsHideExpander={
                  this.conf.isExpandable && this.conf.defaultExpand && !this.conf.showExpander
                }
                {...(this.conf.expandedRows && this.conf.pushExpandedRows && { onRowExpandToggled: (isExpanded, row) => { this.conf.pushExpandedRows(isExpanded, row) } })}
                // expandableRows={false}
                // expandOnRowClicked={expandOnRowClick}
                pagination={this.conf.Pagination || this.conf.clientPagination}
                paginationServer={this.conf.Pagination}
                paginationTotalRows={this.conf.totalElements}
                paginationPerPage={parseInt(this.conf.DisplaySize)}
                paginationRowsPerPageOptions={
                  this.conf.paginationRowsPerPageOptions ||
                  paginationRowsPerPageOptions
                }
                paginationDefaultPage={1}
                paginationResetDefaultPage={this.conf.paginationResetDefaultPage}
                onChangePage={page => {//Fixed Given by FEE Team regarding Row select should not Unselect while changing the Pagination as well
                  if (this.conf.page) {
                    this.conf.page.pageNumber = page;
                    this.getFooterText();
                  }
                  if(this.conf.Pagination || this.conf.clientPagination){
                    let event = {
                      type: "PagerORsort",
                      target: {
                        id: this.conf.name,
                        page: page,
                        selectedRows: this.selected
                      }
                    };
                    if(this.selected.length>0 && !this.conf.isMultiSelect)
                    {
                      let selectedElem =document.getElementById('row-'+this.selected[0].rowID$)
                      if(selectedElem != null){
                       selectedElem.classList.add('activeRow')
                      }
                    }
                   
                    this.props.onEvent(event);
                  }
                }}
                onChangeRowsPerPage={(size) => {
                  if (this.conf.page) {
                    this.conf.page.size = size;
                    let event = {
                      type: "PagerORsort",
                      target: {
                        id: this.conf.name,
                        size : size
                      },
                    };
                    this.props.onEvent(event);
                  }
                }}
                highlightOnHover={true}
                // striped={true}
                keyField="rowID$"
                // conditionalRowStyles={conditionalRowStyles}
                // pointerOnHover={pointer}
                // dense={dense}
                // noTableHead={tableHead}
                // persistTableHead={persist}
                // progressPending={loading}
                noHeader={true}
                // responsive={false}
                // subHeader={subHeader}
                // subHeaderComponent={
                //   <div style={{ display: "flex", alignItems: "center" }}>
                //     <TextField
                //       id="outlined-basic"
                //       label="Search"
                //       variant="outlined"
                //       size="small"
                //       style={{ margin: "5px" }}
                //     />
                //     <Icon1 style={{ margin: "5px" }} color="action" />
                //     <Icon2 style={{ margin: "5px" }} color="action" />
                //     <Icon3 style={{ margin: "5px" }} color="action" />
                //   </div>
                // }
                // subHeaderAlign={subHeaderAlign}
                fixedHeader={true}
                fixedHeaderScrollHeight="1000px"
                {...cobolProperties}
              // direction={directionValue}
              />
            );
          }}
        />
        {_kaledo.isCobolTable ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {this.state.footerText}
          </div>
        ) : null}
      </React.Fragment>
    );

    let gridTemplate = (
      <div className={this.getPanelClasses().join(" ") + " GridWidget"}>
        <Card>
          {!this.conf.hideHeader && (
            <Card.Header className="position-relative">
              <Button
                className="text-decoration-none p-0"
                variant="link"
                onClick={() => this.setState({ open: !this.state.open })}
                aria-controls={this.conf.name + "Collapse"}
                aria-expanded={this.state.open}
                data-testid={this.conf.name}
              >
                <i
                  className={toggleClasses.join(" ")}
                  aria-hidden="true"
                ></i>
                {this.conf.HasLabel ? this.getWidgetLabel() : ""}
              </Button>
              {this.conf.disablePopOverActions === undefined || this.conf.disablePopOverActions === false ?
                <PopoverActions
                  columns={this.conf.columns}
                  editable={this.editable}
                  actionList={this.actionList}
                  okCancelButtons={this.state.okCancelButtons}
                  setColVisibility={this.setColVisibility}
                ></PopoverActions>
                : <></>}
            </Card.Header>
          )}
          <Collapse in={this.state.open}>
            <div id={this.conf.name + "Collapse"}>{rdtTemplate}</div>
          </Collapse>
        </Card>
      </div>
    );

    if (this.conf.hideContainer) {
      gridTemplate = (
        <div className={this.getPanelClasses().join(" ") + " GridWidget"}>
          {rdtTemplate}
        </div>
      );
    }

    return gridTemplate;
  }
}

export default GridWidget;
