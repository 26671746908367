/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
} from "../../shared/WindowImports";

import "./FormulaEditor.scss";
import ContractManagement_header from "../../Header/Header";
// START_USER_CODE-USER_IMPORTS

// END_USER_CODE-USER_IMPORTS
function ReportTool_FormulaEditor(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FormulaEditor",
    windowName: "FormulaEditor",
    template: "Standard Screen",
    Cols: "5",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ReportTool.FormulaEditor",
    // START_USER_CODE-USER_FormulaEditor_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Formula Editor",
      scrCode: "",
    },
    // END_USER_CODE-USER_FormulaEditor_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbx32",
      Label: "Add>>",
      CharWidth: "13",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClear: {
      name: "btnClear",
      type: "ButtonWidget",
      parent: "grpbx34",
      Label: "Clear",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClear_PROPERTIES

      // END_USER_CODE-USER_btnClear_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbx1",
      Label: "Close",
      ColSpan: "2",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreate: {
      name: "btnCreate",
      type: "ButtonWidget",
      parent: "grpbx34",
      Label: "Create",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCreate_PROPERTIES

      // END_USER_CODE-USER_btnCreate_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbx23",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbx23",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnInsert: {
      name: "btnInsert",
      type: "ButtonWidget",
      parent: "grpbx33",
      Label: "Insert Formula",
      CharWidth: "30",
      // START_USER_CODE-USER_btnInsert_PROPERTIES

      // END_USER_CODE-USER_btnInsert_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbx34",
      Label: "Update",
      CharWidth: "15",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    grpbx21: {
      name: "grpbx21",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget6",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx21_PROPERTIES

      // END_USER_CODE-USER_grpbx21_PROPERTIES
    },
    grpbx22: {
      name: "grpbx22",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget7",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx22_PROPERTIES

      // END_USER_CODE-USER_grpbx22_PROPERTIES
    },
    grpbx23: {
      name: "grpbx23",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget8",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx23_PROPERTIES

      // END_USER_CODE-USER_grpbx23_PROPERTIES
    },
    grpbx31: {
      name: "grpbx31",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget12",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx31_PROPERTIES

      // END_USER_CODE-USER_grpbx31_PROPERTIES
    },
    grpbx32: {
      name: "grpbx32",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget13",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx32_PROPERTIES

      // END_USER_CODE-USER_grpbx32_PROPERTIES
    },
    grpbx33: {
      name: "grpbx33",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget14",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbx33_PROPERTIES

      // END_USER_CODE-USER_grpbx33_PROPERTIES
    },
    grpbx34: {
      name: "grpbx34",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget15",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx34_PROPERTIES

      // END_USER_CODE-USER_grpbx34_PROPERTIES
    },
    labelwidget1: {
      name: "labelwidget1",
      type: "LabelWidget",
      parent: "grpbx1",
      Label: "Formula Editor",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      HasLabel: false
      // START_USER_CODE-USER_labelwidget1_PROPERTIES

      // END_USER_CODE-USER_labelwidget1_PROPERTIES
    },
    lblDirections: {
      name: "lblDirections",
      type: "LabelWidget",
      parent: "grpbx33",
      Label: "Directions:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDirections_PROPERTIES

      // END_USER_CODE-USER_lblDirections_PROPERTIES
    },
    lblDirections1: {
      name: "lblDirections1",
      type: "LabelWidget",
      parent: "grpbx33",
      Label:
        "1. Use ( ),+,-,*,/ and column names inside [ ] on the left side to create a formula.",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDirections1_PROPERTIES

      // END_USER_CODE-USER_lblDirections1_PROPERTIES
    },
    lblDirections2: {
      name: "lblDirections2",
      type: "LabelWidget",
      parent: "grpbx33",
      Label: "2. Do not change the text inside the square brackets[ ].",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDirections2_PROPERTIES

      // END_USER_CODE-USER_lblDirections2_PROPERTIES
    },
    lblFormulaDefination1: {
      name: "lblFormulaDefination1",
      type: "LabelWidget",
      parent: "grpbx33",
      Label: "Formula Defination",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFormulaDefination1_PROPERTIES

      // END_USER_CODE-USER_lblFormulaDefination1_PROPERTIES
    },
    lblFormulaDetail: {
      name: "lblFormulaDetail",
      type: "LabelWidget",
      parent: "grpbx22",
      Label: "Formula Detail",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFormulaDetail_PROPERTIES

      // END_USER_CODE-USER_lblFormulaDetail_PROPERTIES
    },
    lstDate: {
      name: "lstDate",
      type: "ListBoxFieldWidget",
      parent: "grpbx31",
      Label: "Date Columns",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstDate_PROPERTIES

      // END_USER_CODE-USER_lstDate_PROPERTIES
    },
    lstDirections: {
      name: "lstDirections",
      type: "ListBoxFieldWidget",
      parent: "grpbx33",
      Label: "listboxfieldwidget23",
      ColSpan: "2",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstDirections_PROPERTIES

      // END_USER_CODE-USER_lstDirections_PROPERTIES
    },
    lstFormulaColumn: {
      name: "lstFormulaColumn",
      type: "ListBoxFieldWidget",
      parent: "grpbx22",
      Label: "Formula Defination defines in report column names:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFormulaColumn_PROPERTIES

      // END_USER_CODE-USER_lstFormulaColumn_PROPERTIES
    },
    lstFormulaDefination: {
      name: "lstFormulaDefination",
      type: "ListBoxFieldWidget",
      parent: "grpbx22",
      Label: "Formula Defination:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFormulaDefination_PROPERTIES

      // END_USER_CODE-USER_lstFormulaDefination_PROPERTIES
    },
    lstFormulas: {
      name: "lstFormulas",
      type: "ListBoxFieldWidget",
      parent: "grpbx21",
      Label: "Formulas",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFormulas_PROPERTIES

      // END_USER_CODE-USER_lstFormulas_PROPERTIES
    },
    lstFormulaStatus: {
      name: "lstFormulaStatus",
      type: "ListBoxFieldWidget",
      parent: "grpbx33",
      Label: "Formula Status:",
      ColSpan: "2",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFormulaStatus_PROPERTIES

      // END_USER_CODE-USER_lstFormulaStatus_PROPERTIES
    },
    lstNumber: {
      name: "lstNumber",
      type: "ListBoxFieldWidget",
      parent: "grpbx31",
      Label: "Number columns",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstNumber_PROPERTIES

      // END_USER_CODE-USER_lstNumber_PROPERTIES
    },
    txtFormulaDescription: {
      name: "txtFormulaDescription",
      type: "TextBoxWidget",
      parent: "grpbx22",
      Label: "Formula Description:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFormulaDescription_PROPERTIES

      // END_USER_CODE-USER_txtFormulaDescription_PROPERTIES
    },
    txtFormulaDescription1: {
      name: "txtFormulaDescription1",
      type: "TextBoxWidget",
      parent: "grpbx3",
      Label: "Formula Description:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFormulaDescription1_PROPERTIES

      // END_USER_CODE-USER_txtFormulaDescription1_PROPERTIES
    },
    txtFormulaName: {
      name: "txtFormulaName",
      type: "TextBoxWidget",
      parent: "grpbx22",
      Label: "Formula Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFormulaName_PROPERTIES

      // END_USER_CODE-USER_txtFormulaName_PROPERTIES
    },
    txtFormulaName1: {
      name: "txtFormulaName1",
      type: "TextBoxWidget",
      parent: "grpbx3",
      Label: "Formula Name:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFormulaName1_PROPERTIES

      // END_USER_CODE-USER_txtFormulaName1_PROPERTIES
    },
    txtReportName: {
      name: "txtReportName",
      type: "TextBoxWidget",
      parent: "grpbx1",
      Label: "Report Name:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReportName_PROPERTIES

      // END_USER_CODE-USER_txtReportName_PROPERTIES
    },
    txtUserID: {
      name: "txtUserID",
      type: "TextBoxWidget",
      parent: "grpbx1",
      Label: "User ID:",
      ColSpan: "3",

      ofTypeDomain: "d_char",
      // START_USER_CODE-USER_txtUserID_PROPERTIES

      // END_USER_CODE-USER_txtUserID_PROPERTIES
    },
    grpbx1: {
      name: "grpbx1",
      type: "GroupBoxWidget",
      parent: "FormulaEditor",
      Label: "groupboxwidget0",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbx1_PROPERTIES

      // END_USER_CODE-USER_grpbx1_PROPERTIES
    },
    grpbx2: {
      name: "grpbx2",
      type: "GroupBoxWidget",
      parent: "FormulaEditor",
      Label: "groupboxwidget5",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbx2_PROPERTIES

      // END_USER_CODE-USER_grpbx2_PROPERTIES
    },
    grpbx3: {
      name: "grpbx3",
      type: "GroupBoxWidget",
      parent: "FormulaEditor",
      Label: "groupboxwidget9",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbx3_PROPERTIES

      // END_USER_CODE-USER_grpbx3_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FormulaEditor*/}

              {/* END_USER_CODE-USER_BEFORE_FormulaEditor*/}

              {/* START_USER_CODE-USER_BEFORE_grpbx1*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1*/}

              <GroupBoxWidget conf={state.grpbx1} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_labelwidget1*/}

                {/* END_USER_CODE-USER_BEFORE_labelwidget1*/}

                <LabelWidget
                  values={values}
                  conf={state.labelwidget1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_labelwidget1*/}

                {/* END_USER_CODE-USER_AFTER_labelwidget1*/}
                {/* START_USER_CODE-USER_BEFORE_txtReportName*/}

                {/* END_USER_CODE-USER_BEFORE_txtReportName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReportName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReportName*/}

                {/* END_USER_CODE-USER_AFTER_txtReportName*/}
                {/* START_USER_CODE-USER_BEFORE_txtUserID*/}

                {/* END_USER_CODE-USER_BEFORE_txtUserID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUserID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUserID*/}

                {/* END_USER_CODE-USER_AFTER_txtUserID*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1*/}
              {/* START_USER_CODE-USER_BEFORE_grpbx2*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx2*/}

              <GroupBoxWidget conf={state.grpbx2} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbx21*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx21*/}

                <GroupBoxWidget conf={state.grpbx21} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lstFormulas*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFormulas*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFormulas}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFormulas*/}

                  {/* END_USER_CODE-USER_AFTER_lstFormulas*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx21*/}

                {/* END_USER_CODE-USER_AFTER_grpbx21*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx22*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx22*/}

                <GroupBoxWidget conf={state.grpbx22} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFormulaDetail*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFormulaDetail*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFormulaDetail}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFormulaDetail*/}

                  {/* END_USER_CODE-USER_AFTER_lblFormulaDetail*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFormulaName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFormulaName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFormulaName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFormulaName*/}

                  {/* END_USER_CODE-USER_AFTER_txtFormulaName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFormulaDescription*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFormulaDescription*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFormulaDescription}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFormulaDescription*/}

                  {/* END_USER_CODE-USER_AFTER_txtFormulaDescription*/}
                  {/* START_USER_CODE-USER_BEFORE_lstFormulaDefination*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFormulaDefination*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFormulaDefination}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFormulaDefination*/}

                  {/* END_USER_CODE-USER_AFTER_lstFormulaDefination*/}
                  {/* START_USER_CODE-USER_BEFORE_lstFormulaColumn*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFormulaColumn*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFormulaColumn}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFormulaColumn*/}

                  {/* END_USER_CODE-USER_AFTER_lstFormulaColumn*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx22*/}

                {/* END_USER_CODE-USER_AFTER_grpbx22*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx23*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx23*/}

                <GroupBoxWidget conf={state.grpbx23} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx23*/}

                {/* END_USER_CODE-USER_AFTER_grpbx23*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx2*/}

              {/* END_USER_CODE-USER_AFTER_grpbx2*/}
              {/* START_USER_CODE-USER_BEFORE_grpbx3*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx3*/}

              <GroupBoxWidget conf={state.grpbx3} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtFormulaName1*/}

                {/* END_USER_CODE-USER_BEFORE_txtFormulaName1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFormulaName1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFormulaName1*/}

                {/* END_USER_CODE-USER_AFTER_txtFormulaName1*/}
                {/* START_USER_CODE-USER_BEFORE_txtFormulaDescription1*/}

                {/* END_USER_CODE-USER_BEFORE_txtFormulaDescription1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFormulaDescription1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFormulaDescription1*/}

                {/* END_USER_CODE-USER_AFTER_txtFormulaDescription1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx31*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx31*/}

                <GroupBoxWidget conf={state.grpbx31} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lstNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_lstNumber*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstNumber}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstNumber*/}

                  {/* END_USER_CODE-USER_AFTER_lstNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_lstDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lstDate*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstDate}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstDate*/}

                  {/* END_USER_CODE-USER_AFTER_lstDate*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx31*/}

                {/* END_USER_CODE-USER_AFTER_grpbx31*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx32*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx32*/}

                <GroupBoxWidget conf={state.grpbx32} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx32*/}

                {/* END_USER_CODE-USER_AFTER_grpbx32*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx33*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx33*/}

                <GroupBoxWidget conf={state.grpbx33} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFormulaDefination1*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFormulaDefination1*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFormulaDefination1}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFormulaDefination1*/}

                  {/* END_USER_CODE-USER_AFTER_lblFormulaDefination1*/}
                  {/* START_USER_CODE-USER_BEFORE_btnInsert*/}

                  {/* END_USER_CODE-USER_BEFORE_btnInsert*/}

                  <ButtonWidget
                    conf={state.btnInsert}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnInsert*/}

                  {/* END_USER_CODE-USER_AFTER_btnInsert*/}
                  {/* START_USER_CODE-USER_BEFORE_lblDirections*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDirections*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDirections}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDirections*/}

                  {/* END_USER_CODE-USER_AFTER_lblDirections*/}
                  {/* START_USER_CODE-USER_BEFORE_lblDirections1*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDirections1*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDirections1}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDirections1*/}

                  {/* END_USER_CODE-USER_AFTER_lblDirections1*/}
                  {/* START_USER_CODE-USER_BEFORE_lblDirections2*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDirections2*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDirections2}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDirections2*/}

                  {/* END_USER_CODE-USER_AFTER_lblDirections2*/}
                  {/* START_USER_CODE-USER_BEFORE_lstDirections*/}

                  {/* END_USER_CODE-USER_BEFORE_lstDirections*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstDirections}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstDirections*/}

                  {/* END_USER_CODE-USER_AFTER_lstDirections*/}
                  {/* START_USER_CODE-USER_BEFORE_lstFormulaStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFormulaStatus*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFormulaStatus}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFormulaStatus*/}

                  {/* END_USER_CODE-USER_AFTER_lstFormulaStatus*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx33*/}

                {/* END_USER_CODE-USER_AFTER_grpbx33*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx34*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx34*/}

                <GroupBoxWidget conf={state.grpbx34} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnCreate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCreate*/}

                  <ButtonWidget
                    conf={state.btnCreate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCreate*/}

                  {/* END_USER_CODE-USER_AFTER_btnCreate*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                  <ButtonWidget
                    conf={state.btnUpdate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClear*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClear*/}

                  <ButtonWidget
                    conf={state.btnClear}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClear*/}

                  {/* END_USER_CODE-USER_AFTER_btnClear*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx34*/}

                {/* END_USER_CODE-USER_AFTER_grpbx34*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx3*/}

              {/* END_USER_CODE-USER_AFTER_grpbx3*/}

              {/* START_USER_CODE-USER_AFTER_FormulaEditor*/}

              {/* END_USER_CODE-USER_AFTER_FormulaEditor*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ReportTool_FormulaEditor);
