/* eslint-disable*/
window.$ = window.jQuery = require("jquery");
window.moment = require("moment");
require("tempusdominus-bootstrap-4");
require("jest-canvas-mock");
window._kaledo = {
  restServiceType: "SpringRest",
  languages: [
    {
      langCode: "",
      langName: "English",
    },
  ],
  dispFormatReqd: false,
  ScreenList: [
    {
      mode: "0",
      displayName: "AccountDistributionActivity",
      URIPath: "ContractManagement_AccountDistributionActivity",
      GUIName: "ContractManagement",
      screenName: "AccountDistributionActivity",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Seed Grower Agreement",
      URIPath: "ContractManagement_AddendumSeedGrower2009",
      GUIName: "ContractManagement",
      screenName: "AddendumSeedGrower2009",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Application to Contract",
      URIPath: "ContractManagement_ApplicationToContract",
      GUIName: "ContractManagement",
      screenName: "ApplicationToContract",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ContractExceptionPremiumSetup",
      URIPath: "ContractManagement_ContractExceptionPremiumSetup",
      GUIName: "ContractManagement",
      screenName: "ContractExceptionPremiumSetup",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ContractExceptionPremiumProfile",
      URIPath: "ContractManagement_ContractExceptPremProfile",
      GUIName: "ContractManagement",
      screenName: "ContractExceptPremProfile",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Contract Grouping Profile - Test",
      URIPath: "ContractManagement_ContractGroupingProfile",
      GUIName: "ContractManagement",
      screenName: "ContractGroupingProfile",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Contract Grouping Search",
      URIPath: "ContractManagement_ContractGroupingSearch",
      GUIName: "ContractManagement",
      screenName: "ContractGroupingSearch",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ContractGrpSelect",
      URIPath: "ContractManagement_ContractGrpSelect",
      GUIName: "ContractManagement",
      screenName: "ContractGrpSelect",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Contract Inquiry",
      URIPath: "ContractManagement_ContractInquiry",
      GUIName: "ContractManagement",
      screenName: "ContractInquiry",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Contract Profile",
      URIPath: "ContractManagement_ContractProfile",
      GUIName: "ContractManagement",
      screenName: "ContractProfile",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "SaveAndScanContract",
      URIPath: "ContractManagement_ContractScan",
      GUIName: "ContractManagement",
      screenName: "ContractScan",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Exception Premium Search",
      URIPath: "ContractManagement_ExceptionPremiumSearch",
      GUIName: "ContractManagement",
      screenName: "ExceptionPremiumSearch",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ExceptPremiumAdjustments",
      URIPath: "ContractManagement_ExceptPremiumAdjustments",
      GUIName: "ContractManagement",
      screenName: "ExceptPremiumAdjustments",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ExceptPremRequestProfile",
      URIPath: "ContractManagement_ExceptPremRequestProfile",
      GUIName: "ContractManagement",
      screenName: "ExceptPremRequestProfile",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ReportPreviewExceptPrem",
      URIPath: "ContractManagement_ExceptPremRequestSearch",
      GUIName: "ContractManagement",
      screenName: "ExceptPremRequestSearch",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Farm Profile",
      URIPath: "ContractManagement_FarmProfile",
      GUIName: "ContractManagement",
      screenName: "FarmProfile",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "FlexibleMarketPricingsTest",
      URIPath: "ContractManagement_FlexibleMarketPricings",
      GUIName: "ContractManagement",
      screenName: "FlexibleMarketPricings",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "FlexMktPrint",
      URIPath: "ContractManagement_FlexMktPrint",
      GUIName: "ContractManagement",
      screenName: "FlexMktPrint",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "FlexMktSign",
      URIPath: "ContractManagement_FlexMktSign",
      GUIName: "ContractManagement",
      screenName: "FlexMktSign",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "VoidPriceElection",
      URIPath: "ContractManagement_FlexMktVoid",
      GUIName: "ContractManagement",
      screenName: "FlexMktVoid",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "header",
      URIPath: "ContractManagement_header",
      GUIName: "ContractManagement",
      screenName: "header",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Main Menu",
      URIPath: "ContractManagement_MainMenu",
      GUIName: "ContractManagement",
      screenName: "MainMenu",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "Report Preview Contract",
      URIPath: "ContractManagement_ReportPreviewContract",
      GUIName: "ContractManagement",
      screenName: "ReportPreviewContract",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "ReportPreviewExcPrem",
      URIPath: "ContractManagement_ReportPreviewExcPrem",
      GUIName: "ContractManagement",
      screenName: "ReportPreviewExcPrem",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "VendorDisclosure",
      URIPath: "ContractManagement_VendorDisclosure",
      GUIName: "ContractManagement",
      screenName: "VendorDisclosure",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "VendorLookup",
      URIPath: "ContractManagement_VendorLookup",
      GUIName: "ContractManagement",
      screenName: "VendorLookup",
      firstMenuLabel: "Standard Screen",
    },
    {
      mode: "0",
      displayName: "View Payments",
      URIPath: "ContractManagement_ViewPayments",
      GUIName: "ContractManagement",
      screenName: "ViewPayments",
      firstMenuLabel: "Standard Screen",
    },
  ],
  compRoot: {},
  format: {
    dateTimeFormat: "DD/MM/YYYY hh:mm",
    dateFormat: "DD-MM-YYYY",
    timeFormat: "hh:mm:ss a",
  },
  menuType: "top",
  FirstScreenURIPath: "ContractManagement_AccountDistributionActivity",
  keyStr:
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/\u003d",
};

(screenList => {
  let guiMArr = screenList.map(screenObj => screenObj.GUIName);
  _kaledo.guiModuleNames = [...new Set(guiMArr)];
})(_kaledo.ScreenList);
