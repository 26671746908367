/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  AutocompleteWidget,
  goTo,
  hide
} from "../../shared/WindowImports";

import "./FreightLocToLocSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FreightLocToLocSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FreightLocToLocSetup",
    windowName: "FreightLocToLocSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FreightLocToLocSetup",
    // START_USER_CODE-USER_FreightLocToLocSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Freight Location to Location Setup",
      scrCode: "PN0250A",
    },
    // END_USER_CODE-USER_FreightLocToLocSetup_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    cmmndCntnrActions1: {
      name: "cmmndCntnrActions1",
      type: "CommandContainerWidget",
      parent: "grpbxFreightLocToLocSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrActions1_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions1_PROPERTIES
    },
    ddAreaDest: {
      name: "ddAreaDest",
      type: "DropDownFieldWidget",
      parent: "grpbxDestination",
      Label: "Area:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAreaDest_PROPERTIES

      // END_USER_CODE-USER_ddAreaDest_PROPERTIES
    },

    ddAreaOrig: {
      name: "ddAreaOrig",
      type: "DropDownFieldWidget",
      parent: "grpbxDestination",
      Label: "Area:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAreaOrig_PROPERTIES

      // END_USER_CODE-USER_ddAreaOrig_PROPERTIES
    },

    ddBuyingPointDest: {
      name: "ddBuyingPointDest",
      type: "DropDownFieldWidget",
      parent: "grpbxDestination",
      Label: "Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPointDest_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPointDest_PROPERTIES
    },
    ddBuyingPointOrig: {
      name: "ddBuyingPointOrig",
      type: "DropDownFieldWidget",
      parent: "grpbxOrigination",
      Label: "Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPointOrig_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPointOrig_PROPERTIES
    },

    ddCollectionPointDest: {
      name: "ddCollectionPointDest",
      type: "DropDownFieldWidget",
      parent: "grpbxDestination",
      Label: "Collection Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPointDest_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPointDest_PROPERTIES
    },

    txtFocus: {
      name: "txtFocus",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFocus_PROPERTIES

      // END_USER_CODE-USER_txtFocus_PROPERTIES
    },

    ddCollectionPointOrig: {
      name: "ddCollectionPointOrig",
      type: "DropDownFieldWidget",
      parent: "grpbxOrigination",
      Label: "Collection Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPointOrig_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPointOrig_PROPERTIES
    },

    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxOrigination",
      Label: "County:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddEffectiveDate: {
      name: "ddEffectiveDate",
      type: "DropDownFieldWidget",
      parent: "grpbxEffectiveDate",
      Label: "Effective Date:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_ddEffectiveDate_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxOrigination",
      Label: "Farm:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES

      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxOrigination",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    gridFreightLocToLocSetupData: {
      name: "gridFreightLocToLocSetupData",
      type: "GridWidget",
      parent: "grpbxFreightLocToLocSetup",
      gridCellsOrder:
        "txtOrigArea,txtOrigCollectionPoint,txtDestCollectionPoint,txtDestArea,txtOrigBuyPt,txtDestBuyPt,txtMiles,txtRateBasis,txtRate,txtEffectiveDt,txtMinWt,txtCarrierAbbr,txtStateID,txtCountyID,txtFarmID,txtNLRateBasis,txtNLRate,txtNLLoadWt",
      Pagination: true,
      clientPagination: true,
      DisplaySize: 10,
      totalElement: 0,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFreightLocToLocSetupData_PROPERTIES

      // END_USER_CODE-USER_gridFreightLocToLocSetupData_PROPERTIES
    },
    grpbxDestination: {
      name: "grpbxDestination",
      type: "GroupBoxWidget",
      parent: "grpbxFreightLocToLocSetup",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDestination_PROPERTIES

      // END_USER_CODE-USER_grpbxDestination_PROPERTIES
    },
    grpbxEffectiveDate: {
      name: "grpbxEffectiveDate",
      type: "GroupBoxWidget",
      parent: "grpbxFreightLocToLocSetup",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_grpbxEffectiveDate_PROPERTIES
    },
    grpbxOrigination: {
      name: "grpbxOrigination",
      type: "GroupBoxWidget",
      parent: "grpbxFreightLocToLocSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxOrigination_PROPERTIES

      // END_USER_CODE-USER_grpbxOrigination_PROPERTIES
    },
    lblDestination: {
      name: "lblDestination",
      type: "LabelWidget",
      parent: "grpbxDestination",
      Label: "Destination",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDestination_PROPERTIES

      // END_USER_CODE-USER_lblDestination_PROPERTIES
    },
    lblEffectiveDate: {
      name: "lblEffectiveDate",
      type: "LabelWidget",
      parent: "grpbxEffectiveDate",
      Label: "Effective Date",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_lblEffectiveDate_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "grpbxOrigination",
      Label: "Note : Farm is based on State, County, Orig. Buy Pt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote_PROPERTIES

      // END_USER_CODE-USER_lblNote_PROPERTIES
    },
    lblOrigination: {
      name: "lblOrigination",
      type: "LabelWidget",
      parent: "grpbxOrigination",
      Label: "Origination",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOrigination_PROPERTIES

      // END_USER_CODE-USER_lblOrigination_PROPERTIES
    },
    txtCarrierAbbr: {
      name: "txtCarrierAbbr",
      type: "TextBoxWidget",
      colName: "txtColCarrierAbbr",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbr_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbr_PROPERTIES
    },
    txtColCarrierAbbr: {
      name: "txtColCarrierAbbr",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Carrier Abbr.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCarrierAbbr_PROPERTIES

      // END_USER_CODE-USER_txtColCarrierAbbr_PROPERTIES
    },
    txtColCountyID: {
      name: "txtColCountyID",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "County ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCountyID_PROPERTIES

      // END_USER_CODE-USER_txtColCountyID_PROPERTIES
    },
    txtColDestArea: {
      name: "txtColDestArea",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Dest. Area",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDestArea_PROPERTIES

      // END_USER_CODE-USER_txtColDestArea_PROPERTIES
    },
    txtColDestBuyPt: {
      name: "txtColDestBuyPt",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Dest. Buy Pt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDestBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtColDestBuyPt_PROPERTIES
    },
    txtColDestCollectionPoint: {
      name: "txtColDestCollectionPoint",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Dest. Collection Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDestCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_txtColDestCollectionPoint_PROPERTIES
    },
    txtColEffectiveDate: {
      name: "txtColEffectiveDate",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Effective Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtColEffectiveDate_PROPERTIES
    },
    txtColFarmID: {
      name: "txtColFarmID",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Farm ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColFarmID_PROPERTIES

      // END_USER_CODE-USER_txtColFarmID_PROPERTIES
    },
    txtColMiles: {
      name: "txtColMiles",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Miles",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColMiles_PROPERTIES

      // END_USER_CODE-USER_txtColMiles_PROPERTIES
    },
    txtColMinWt: {
      name: "txtColMinWt",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Min. Wt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColMinWt_PROPERTIES

      // END_USER_CODE-USER_txtColMinWt_PROPERTIES
    },
    txtColNLLoadWt: {
      name: "txtColNLLoadWt",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "NL Load Wt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColNLLoadWt_PROPERTIES

      // END_USER_CODE-USER_txtColNLLoadWt_PROPERTIES
    },
    txtColNLRate: {
      name: "txtColNLRate",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "NL Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColNLRate_PROPERTIES

      // END_USER_CODE-USER_txtColNLRate_PROPERTIES
    },
    txtColNLRateBasis: {
      name: "txtColNLRateBasis",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "NL Rate Basis",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColNLRateBasis_PROPERTIES

      // END_USER_CODE-USER_txtColNLRateBasis_PROPERTIES
    },
    txtColOrigArea: {
      name: "txtColOrigArea",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Orig. Area",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOrigArea_PROPERTIES

      // END_USER_CODE-USER_txtColOrigArea_PROPERTIES
    },
    txtColOrigBuyPt: {
      name: "txtColOrigBuyPt",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Orig. Buy Pt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOrigBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtColOrigBuyPt_PROPERTIES
    },
    txtColOrigCollectionPoint: {
      name: "txtColOrigCollectionPoint",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Orig. Collection Point",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOrigCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_txtColOrigCollectionPoint_PROPERTIES
    },
    txtColRate: {
      name: "txtColRate",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColRate_PROPERTIES

      // END_USER_CODE-USER_txtColRate_PROPERTIES
    },
    txtColRateBasis: {
      name: "txtColRateBasis",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "Rate Basis",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColRateBasis_PROPERTIES

      // END_USER_CODE-USER_txtColRateBasis_PROPERTIES
    },
    txtColStateID: {
      name: "txtColStateID",
      type: "GridColumnWidget",
      parent: "gridFreightLocToLocSetupData",
      Label: "State ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColStateID_PROPERTIES

      // END_USER_CODE-USER_txtColStateID_PROPERTIES
    },
    txtCountyID: {
      name: "txtCountyID",
      type: "TextBoxWidget",
      colName: "txtColCountyID",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountyID_PROPERTIES

      // END_USER_CODE-USER_txtCountyID_PROPERTIES
    },
    txtDestArea: {
      name: "txtDestArea",
      type: "TextBoxWidget",
      colName: "txtColDestArea",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDestArea_PROPERTIES

      // END_USER_CODE-USER_txtDestArea_PROPERTIES
    },
    txtDestBuyPt: {
      name: "txtDestBuyPt",
      type: "TextBoxWidget",
      colName: "txtColDestBuyPt",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDestBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtDestBuyPt_PROPERTIES
    },
    txtDestCollectionPoint: {
      name: "txtDestCollectionPoint",
      type: "TextBoxWidget",
      colName: "txtColDestCollectionPoint",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDestCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_txtDestCollectionPoint_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateWidget",
      parent: "grpbxEffectiveDate",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtEffectiveDt: {
      name: "txtEffectiveDt",
      type: "TextBoxWidget",
      colName: "txtColEffectiveDate",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEffectiveDt_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDt_PROPERTIES
    },
    txtFarmID: {
      name: "txtFarmID",
      type: "TextBoxWidget",
      colName: "txtColFarmID",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmID_PROPERTIES

      // END_USER_CODE-USER_txtFarmID_PROPERTIES
    },
    txtMiles: {
      name: "txtMiles",
      type: "TextBoxWidget",
      colName: "txtColMiles",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMiles_PROPERTIES

      // END_USER_CODE-USER_txtMiles_PROPERTIES
    },
    txtMinWt: {
      name: "txtMinWt",
      type: "TextBoxWidget",
      colName: "txtColMinWt",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMinWt_PROPERTIES

      // END_USER_CODE-USER_txtMinWt_PROPERTIES
    },
    txtNLLoadWt: {
      name: "txtNLLoadWt",
      type: "TextBoxWidget",
      colName: "txtColNLLoadWt",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNLLoadWt_PROPERTIES

      // END_USER_CODE-USER_txtNLLoadWt_PROPERTIES
    },
    txtNLRate: {
      name: "txtNLRate",
      type: "TextBoxWidget",
      colName: "txtColNLRate",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNLRate_PROPERTIES

      // END_USER_CODE-USER_txtNLRate_PROPERTIES
    },
    txtNLRateBasis: {
      name: "txtNLRateBasis",
      type: "TextBoxWidget",
      colName: "txtColNLRateBasis",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNLRateBasis_PROPERTIES

      // END_USER_CODE-USER_txtNLRateBasis_PROPERTIES
    },
    txtOrigArea: {
      name: "txtOrigArea",
      type: "TextBoxWidget",
      colName: "txtColOrigArea",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrigArea_PROPERTIES

      // END_USER_CODE-USER_txtOrigArea_PROPERTIES
    },
    txtOrigBuyPt: {
      name: "txtOrigBuyPt",
      type: "TextBoxWidget",
      colName: "txtColOrigBuyPt",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrigBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtOrigBuyPt_PROPERTIES
    },
    txtOrigCollectionPoint: {
      name: "txtOrigCollectionPoint",
      type: "TextBoxWidget",
      colName: "txtColOrigCollectionPoint",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrigCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_txtOrigCollectionPoint_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      colName: "txtColRate",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRate_PROPERTIES

      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    txtRateBasis: {
      name: "txtRateBasis",
      type: "TextBoxWidget",
      colName: "txtColRateBasis",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRateBasis_PROPERTIES

      // END_USER_CODE-USER_txtRateBasis_PROPERTIES
    },
    txtStateID: {
      name: "txtStateID",
      type: "TextBoxWidget",
      colName: "txtColStateID",
      parent: "gridFreightLocToLocSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateID_PROPERTIES

      // END_USER_CODE-USER_txtStateID_PROPERTIES
    },
    grpbxFreightLocToLocSetup: {
      name: "grpbxFreightLocToLocSetup",
      type: "GroupBoxWidget",
      parent: "FreightLocToLocSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFreightLocToLocSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightLocToLocSetup_PROPERTIES
    },
    cmmndCntnrActions2: {
      name: "cmmndCntnrActions2",
      type: "CommandContainerWidget",
      parent: "FreightLocToLocSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrActions2_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions2_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [gridDataOptimize, setgridDataOptimize] = useState([]);
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    hide(thisObj,'txtFocus')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let shouldOpenAddFreightLocToLocProfileScreen = getData(thisObj, 'shouldOpenAddFreightLocToLocProfileScreen');
    if (shouldOpenAddFreightLocToLocProfileScreen) {
      onbtnAddClick();
      setData(thisObj, 'shouldOpenAddFreightLocToLocProfileScreen', false);
    }
  }, [getData(thisObj, "shouldOpenAddFreightLocToLocProfileScreen")]);

 

  useEffect(() => {
    const updatedLocToLocProfile = getData(thisObj, 'dataFromFreightLocToLocProfileScreen');
    if (updatedLocToLocProfile) {
      const selectedRowData = thisObj.state.gridFreightLocToLocSetupData.selected[0];
      const selectedRowIndex = selectedRowData?.rowID$ + (pageNumber-1)*(pageSize) ;
      const gridData = getValue(thisObj, 'gridFreightLocToLocSetupData');
      if (selectedRowIndex !== -1) { // make sure selected row exists
        const existingGridRowData = gridData[selectedRowIndex];
        gridData[selectedRowIndex] = {
          ...existingGridRowData,
          txtMiles: updatedLocToLocProfile?.txtMiles,
          txtRateBasis: updatedLocToLocProfile?.cmbRateBasis,
          txtRate: Number(updatedLocToLocProfile?.txtRate).toFixed(2),
          txtEffectiveDt: moment(updatedLocToLocProfile?.txtEffectiveDate).format('M/D/YYYY'),
          txtMinWt: updatedLocToLocProfile?.txtDeadFreight,
          txtCarrierAbbr: updatedLocToLocProfile?.txtScac_id,
          txtStateID: updatedLocToLocProfile?.lstStateID,
          txtCountyID: updatedLocToLocProfile?.lstCountyID,
          txtFarmID: updatedLocToLocProfile?.lstFarmID,
          txtNLRateBasis: updatedLocToLocProfile?.cmbNL_Basis,
          txtNLRate: Number(updatedLocToLocProfile?.txtNL_rate).toFixed(2),
          txtNLLoadWt: updatedLocToLocProfile?.txtNL_weight
        };
        setValue(thisObj, 'gridFreightLocToLocSetupData', gridData);
      }
      setData(thisObj, 'dataFromFreightLocToLocProfileScreen', null);
    }
  }, [getData(thisObj, "dataFromFreightLocToLocProfileScreen")]);

  let strOrigArea, strOrigCollection, strOrigBuying, strOrigState, strOrigCounty, strOrigFarm, strDestArea, strDestCollection, strDestBuying, strOrigFarmSuffix

  // START_USER_CODE-USER_METHODS
  //FormLoad Events 
   async function FormLoad() {
    setLoading(true);
    bFillOrigAREALocationList()
    await bFillOrigColPTLocationList()
    await bFillShippingLocationList()

    bFillDESTAREALocationList()
    await bFillDESTColPTLocationList()
    await bFillReceivingLocationList()

    await bFillStateList()
    await bFillFarmList()
    bEffectiveDateDropDown()
    setLoading(false);
  }

  let trasShipList = ''
  // ================================================ origination section Area DropDown =========================================
  const bFillOrigAREALocationList = async () => {
    try {
      let js = []; let initialValue;
      js.push({ key: '', description: '>>> All Locations <<<' });
      trasShipList = ''

      let LstrList = await SettlementService.RetrieveAreaControlDetails(null);
      if (LstrList?.length != 0) {
        for (var i = 0; i < LstrList.length; i++) {
          let obj = {key: LstrList[i].areaId , description:  LstrList[i].areaId + "-" + LstrList[i].areaName }
          js.push(obj)

        }
        if (js.length == 2) {
          delete js[0];
          initialValue = js[1].key;
        }
        else {
          initialValue = js[0].key;
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddAreaOrig: {
              ...state["ddAreaOrig"],
              valueList: js,
            }
          }
        })
      }else{
        initialValue = '>>> All Locations <<<';
      }
      setValue(thisObj, 'ddAreaOrig', initialValue);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillOrigAREALocationList event"
        );
      }
    }
  }

  // ================================================ origination section Collection DropDown =========================================
  const bFillOrigColPTLocationList = async () => {
    try {
      let jsColPt = []; let initialValue;
      jsColPt.push({ key: '', description: '>>> All Locations <<<' });

      let strArea = getValue(thisObj, "ddAreaOrig");
      trasShipList = ''
      let LstrList = await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(compId, cropYear, null, strArea)
      if (LstrList.length != 0) {
        for (var i = 0; i < LstrList.length; i++) {
          let obj = {key: LstrList[i].coll_pt_id  , description: LstrList[i].coll_pt_id + "-" + LstrList[i].buy_pt_name }
          jsColPt.push(obj)
        }
        if (jsColPt.length == 2) {
          delete jsColPt[0];
          initialValue = jsColPt[1].key;
        }
        else {
          initialValue = jsColPt[0].key;
        }
      }
      else{
        initialValue = '>>> All Locations <<<';
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPointOrig: {
            ...state["ddCollectionPointOrig"],
            valueList: jsColPt
          }
        }
      })
      setValue(_kaledo.thisObj, 'ddCollectionPointOrig', initialValue);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillOrigColPTLocationList event"
        );
      }
    }
  }
  //'''''''''''''''''''''''''''''''''''''''''''''''''''Origination Buying Point dropdown'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  const bFillShippingLocationList = async() => {
    try {
      let js = []; let initialValue;
      let strArea = getValue(thisObj, "ddAreaOrig");
      let strCollPT = getValue(_kaledo.thisObj, "ddCollectionPointOrig")

      strOrigCollection = getValue(_kaledo.thisObj, "ddCollectionPointOrig");

      js.push({ key: '', description: '>>> All Locations <<<' });
      let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1080', null, strArea, strCollPT, null)
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        if (js.length == 2) {
          delete js[0];
          initialValue = js[1].key;
        }
        else {
          initialValue = js[0].key;
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyingPointOrig: {
              ...state["ddBuyingPointOrig"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddBuyingPointOrig', initialValue);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillShippingLocationList event"
        );
      }
    }
  }
  //================================================== Destination section Area DropDown ======================================
  const bFillDESTAREALocationList = async () => {
    try {
      let js = []; let initialValue;
      js.push({ key: '', description: '>>> All Locations <<<' });
      trasShipList = ''
      let LstrList = await SettlementService.RetrieveAreaControlDetails(null);
      if (LstrList.length != 0) {
        for (var i = 0; i < LstrList.length; i++) {
          let obj = {key: LstrList[i].areaId , description: LstrList[i].areaId + "-" + LstrList[i].areaName }
          js.push(obj)
        }
        if (js.length == 2) {
          delete js[0];
          initialValue = js[1].key;
        }
        else {
          initialValue = js[0].key;
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddAreaDest: {
              ...state["ddAreaDest"],
              valueList: js
            }
          }
        })
      }else{
        initialValue = '>>> All Locations <<<';
      }
      setValue(thisObj, 'ddAreaDest', initialValue);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillDESTAREALocationList event"
        );
      }
    }
  }

  // ================================================  Destination section Collection DropDown  =========================================
  const bFillDESTColPTLocationList = async () => {
    try {
      let jsColPt = []; let initialValue;
      jsColPt.push({ key: '', description: '>>> All Locations <<<' });

      let strArea = getValue(thisObj, "ddAreaDest");

      trasShipList = ''
      let LstrList = await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(compId, cropYear, null, strArea)
      if (LstrList.length != 0) {
        for (var i = 0; i < LstrList.length; i++) {
          let obj = {key: LstrList[i].coll_pt_id  , description: LstrList[i].coll_pt_id + "-" + LstrList[i].buy_pt_name }
          jsColPt.push(obj)
        }
        if (jsColPt.length == 2) {
          delete jsColPt[0];
          initialValue = jsColPt[1].key;
        }
        else {
          initialValue = jsColPt[0].key;
        }
      }
      else{
        initialValue = '>>> All Locations <<<';
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPointDest: {
            ...state["ddCollectionPointDest"],
            valueList: jsColPt
          }
        }
      })
      setValue(thisObj, 'ddCollectionPointDest', initialValue);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillDESTColPTLocationList event"
        );
      }
    }
  }

  // ''''''''''''''''''''''''''''''''''''''''''''''''''''' Destination Buying Point dropdown''''''''''''''''''''''''''''''''''''''''''''''''''''
  const bFillReceivingLocationList = async () => {
    try {
      let js = []; let initialValue;
      let strArea = getValue(thisObj, "ddAreaDest");
      let strCollPT = getValue(_kaledo.thisObj, "ddCollectionPointDest")

      js.push({ key: '', description: '>>> All Locations <<<' });
      let data  = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1080', null, strArea, strCollPT, null)
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        if (js.length == 2) {
          delete js[0];
          initialValue = js[1].key;
        }
        else {
          initialValue = js[0].key;
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyingPointDest: {
              ...state["ddBuyingPointDest"],
              valueList: js
            }
          }
        })
        setValue(_kaledo.thisObj, 'ddBuyingPointDest', initialValue);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillReceivingLocationList event"
        );
      }
    }
  }

  //==========================================origination section Area and Collection change Event =====================================
  const onddAreaOrigChange =  async () => {
    try {
      await bFillOrigColPTLocationList()
      await bFillShippingLocationList()
      await bFillStateList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddAreaOrigChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddAreaOrigChange = onddAreaOrigChange;

  const onddCollectionPointOrigChange =  async() => {
    try {
      await bFillShippingLocationList()
      await bFillStateList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddCollectionPointOrigChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddCollectionPointOrigChange = onddCollectionPointOrigChange;

  //============================================Destination section Area and Collection change Event =============================
  const onddAreaDestChange =  async () => {
    try {
      await bFillDESTColPTLocationList()
      await bFillReceivingLocationList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddAreaDestChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddAreaDestChange = onddAreaDestChange;

  const onddCollectionPointDestChange =  () => {
    try {
      bFillReceivingLocationList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddCollectionPointDestChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddCollectionPointDestChange = onddCollectionPointDestChange;

  // ================================================ State,County,Farm DropDown =================================
  const bFillStateList = async () => {
    try {
      let jsCounties = [], jsState = [];
      let FilterStatus = true
      let buyingPoint = getValue(thisObj, "ddBuyingPointOrig")
      if (buyingPoint === '') {
        buyingPoint = null
      }
      jsState.push({ key: '', description: ' ' })
      jsCounties.push({ key: '', description: ' ' })

      let resp = await ContractManagementService.RetrievePeanutStateControlDetails(buyingPoint, null, FilterStatus)
      let data = resp
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].stateAbbr, description: data[i].stateName }
        jsState.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: jsState
          }
        }
      })
      setValue(thisObj, 'ddState', jsState[0].key);
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: jsCounties,
          }
        }
      })
      setValue(thisObj, 'ddCounty', jsCounties[0].key);
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillStateList event"
        );
      }
    }
  }

  const bFillCountyList = async () => {
    try {
      let js = []
      let jsFarms = []
      let stateValue = getValue(thisObj, "ddState");
      let buyingpt = getValue(thisObj, "ddBuyingPointOrig")
      if (buyingpt === '') {
        buyingpt = null
      }
      let FilterStatus = "YES"
      js.push({ key: '', description: ' ' })
      jsFarms.push({ key: '', description: ' ' })
      if (stateValue != "" && stateValue != undefined && stateValue != null) {
        let response = await ContractManagementService.RetrieveCountyControlDetails(buyingpt, FilterStatus, stateValue, null)
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].countyId.trim(), description: data[i].countyName.trim() }
          js.push(obj)
        }
      }
      else {
        setValue(thisObj, "ddCounty", "")
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: js
          }
        }
      })

      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: jsFarms,
          }
        }
      })
      setValue(thisObj, 'ddFarm', jsFarms[0].key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillCountyList event"
        );
      }
    }
  }

  const bFillFarmList = async () => {
    try {
      let jsFarms = []
      let jsfarmSuffix = []
      let stateValue = getValue(thisObj, "ddState");
      let buyingpt = getValue(thisObj, "ddBuyingPointOrig")
      let countyId = getValue(thisObj, 'ddCounty');
      if (buyingpt === '') {
        buyingpt = null
      }
      jsFarms.push({ key: '', description: ' ' })
      if ((stateValue != "" && stateValue != undefined && stateValue != null) && (buyingpt != "" && buyingpt != undefined && buyingpt != null) && (countyId != "" && countyId != undefined && countyId != null)) {
        let response = await ContractManagementService.RetrieveFarmControlDetails(null, null, buyingpt, stateValue, countyId, null, null)
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].farm_id + '-' + data[i].farm_suffix, description: data[i].farm_id + ' - ' + data[i].farm_suffix + ' - ' + data[i].farm_name }
          jsFarms.push(obj);
          jsfarmSuffix.push(data[i].farm_suffix)
        }
        setData(thisObj, "Farm_suffix", jsfarmSuffix)
      }
      else {
        setValue(thisObj, 'ddFarm', '');
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: jsFarms
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillFarmList event"
        );
      }
    }
  }

  //============================================== BuyingPoint DropDown Change Event ============================
  const onddBuyingPointOrigChange =  () => {
    try {
      bFillStateList()
      bFillCountyList()
      bFillFarmList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddBuyingPointOrigChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddBuyingPointOrigChange = onddBuyingPointOrigChange;

  // ============================================== State,County,Farm DropDown change event================================
  const onddStateChange =  () => {
    try {
       bFillCountyList()
       bFillFarmList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddStateChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddStateChange = onddStateChange;

  const onddCountyChange =  () => {
    try {
      bFillFarmList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddCountyChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddCountyChange = onddCountyChange;

  // Effective date DropDown Static Values
  const bEffectiveDateDropDown =  () => {
    try {
      let js = [];
      js.push({ key: '~~~', description: "=" })
      js.push({ key: '~=', description: ">=" })
      js.push({ key: '~~=', description: "<=" })
      thisObj.setState(current => {
        return {
          ...current,
          ddEffectiveDate: {
            ...state["ddEffectiveDate"],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddEffectiveDate", js[0].key)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bEffectiveDateDropDown event"
        );
      }
    }
  }

  //Search Button Functionality
  const onbtnSearchClick = () => {
    try {
      let intSearchOK = 0
      let Farm_suffix = getValue(thisObj,"ddFarm")//getData(thisObj, "Farm_suffix")
      if( Farm_suffix != undefined && Farm_suffix != null && Farm_suffix.length != 0 ){
          Farm_suffix = Farm_suffix.slice(-3)
      }

      //============================================= Origin Area  DropDown ======================================================
      strOrigArea = getValue(thisObj, "ddAreaOrig");
      if (strOrigArea != undefined && strOrigArea != null && strOrigArea != "" ) {
          intSearchOK = 1
      }else{
        strOrigArea = ""
      } 

      //============================================= Origin Collection  DropDown ======================================================
      strOrigCollection = getValue(_kaledo.thisObj, "ddCollectionPointOrig");
      if (strOrigCollection != undefined && strOrigCollection != null && strOrigCollection != "" ) {
        intSearchOK = 1
      }else{
        strOrigCollection=''
      }

      //============================================= Origin bUYING point  DropDown ======================================================
      strOrigBuying = getValue(thisObj, "ddBuyingPointOrig");
      if (strOrigBuying != undefined && strOrigBuying != null && strOrigBuying != '') {
        strOrigBuying = getValue(thisObj, "ddBuyingPointOrig")
        intSearchOK = 1
      }
      else {
        strOrigBuying = ''
      }

      strOrigState = getValue(thisObj, "ddState");
      if (strOrigState != undefined && strOrigState != null && strOrigState != '') {
        strOrigState = getValue(thisObj, "ddState")
      }
      else {
        strOrigState = ''
      }

      strOrigCounty = getValue(thisObj, "ddCounty");
      if (strOrigCounty != undefined && strOrigCounty != null && strOrigCounty != '') {
        strOrigCounty = getValue(thisObj, "ddCounty")
      }
      else {
        strOrigCounty = ''
      }

      strOrigFarm = getValue(thisObj, "ddFarm");
      if (strOrigFarm != undefined && strOrigFarm != null && strOrigFarm != '') {
        strOrigFarm = getValue(thisObj, "ddFarm")
      }
      else {
        strOrigFarm = ''
      }

      strOrigFarm = getValue(thisObj, "ddFarm");
      if (strOrigFarm != undefined && strOrigFarm != null && strOrigFarm != '') {
        strOrigFarmSuffix = Farm_suffix
      }
      else {
        strOrigFarmSuffix = ''
      }

      //===========================================Destination Area  DropDown =========================================
      strDestArea = getValue(thisObj, "ddAreaDest");
      if (strDestArea != undefined && strDestArea != null && strDestArea != '') {
          intSearchOK = 1
      } else {
        strDestArea = ''
      }

      //===========================================Destination Collection  DropDown =========================================
      strDestCollection = getValue(thisObj, "ddCollectionPointDest");
      if (strDestCollection != undefined && strDestCollection != null && strDestCollection != '') {
        intSearchOK = 1
      } else {
        strDestCollection = ''
      }

      //=====================================================Collection Buying Point Dropdown ====================================================================
      strDestBuying = getValue(_kaledo.thisObj, "ddBuyingPointDest");
      if (strDestBuying != undefined && strDestBuying != null && strDestBuying != '') {
        intSearchOK = 1
      }
      else {
        strDestBuying = ''
      }

      let EffectiveDate = getValue(thisObj, 'txtEffectiveDate');
      if (EffectiveDate != "" && EffectiveDate != undefined) {
        EffectiveDate = moment(EffectiveDate).format('MM/DD/YYYY');
      } else {
        EffectiveDate = "";
      }

      // =====================================================Final Check================================================
      if (intSearchOK == 1) {
        bFillFRTLOCTOLOC()
      }
      else {
        alert("Please select at least 1 Origination or Destination criteria from the options above.")
        return
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnSearchClick click event"
        );
      }
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // To return Portion of data from Complete Grid Data Array.
  const fetchGridValue = (gridData, pageNumber, DisplaySize) => {
    const startIndex = (pageNumber - 1) * DisplaySize;
    const endIndex = startIndex + DisplaySize;
    return gridData.slice(startIndex, endIndex);
  };

  //Grid Binding Function
  const bFillFRTLOCTOLOC = async () => {
    try {
      setLoading(true)
      let LstrList = ''
      let intSelRow = 1
      let EffectiveDate = getValue(thisObj, 'txtEffectiveDate');
      if (EffectiveDate != "" && EffectiveDate != undefined) {
        EffectiveDate = moment(EffectiveDate).format('MM/DD/YYYY');
      } else {
        EffectiveDate = "";
      }
      let DateCondition = getValue(thisObj, "ddEffectiveDate")

      LstrList = await SystemMaintenanceMasterManagementService.RetrieveFreightLocationToLocationDetails(null, strOrigBuying, strDestBuying, strOrigFarm, strOrigFarmSuffix, strOrigArea, strDestArea, strOrigCollection, strDestCollection, strOrigCounty, strOrigState, DateCondition, EffectiveDate)

      let TransformedRowsArray = [];
      if (LstrList.length === 0) {
        setValue(thisObj, "gridFreightLocToLocSetupData", TransformedRowsArray);
        return;
      }
      else {
        let js = []
        let obj = {}
        for (var i = 0; i < LstrList.length; i++) {
          obj.txtOrigArea = LstrList[i].orig_area_id
          obj.txtOrigCollectionPoint = LstrList[i].orig_coll_pt_id
          obj.txtDestCollectionPoint = LstrList[i].dest_coll_pt_id
          obj.txtDestArea = LstrList[i].dest_area_id
          obj.txtOrigBuyPt = LstrList[i].orig_buy_pt + " - " + LstrList[i].orig_buy_name
          obj.originbuyingpoint = LstrList[i].orig_buy_pt
          obj.txtDestBuyPt = LstrList[i].dest_buy_pt + " - " + LstrList[i].dest_buy_name
          obj.destinationBuyingPoint = LstrList[i].dest_buy_pt
          obj.txtMiles = LstrList[i].loc_to_loc_miles
          obj.txtRateBasis = LstrList[i].loc_to_loc_rate_basis
          if (LstrList[i].loc_to_loc_rate_basis != "") {
            if (LstrList[i].loc_to_loc_rate != undefined) {
              obj.txtRate = Number(LstrList[i].loc_to_loc_rate).toFixed(2)
            }
          } else {
            obj.txtRate = 0
          }
          obj.txtEffectiveDt = moment(LstrList[i].effective_date).format('M/D/YYYY');
          obj.effectiveDate = LstrList[i].effective_date
          obj.txtMinWt = LstrList[i].minimum_weight
          obj.txtCarrierAbbr = LstrList[i].carrier_abbreviation
          obj.txtStateID = LstrList[i].state_id
          obj.txtCountyID = LstrList[i].county_id
          obj.txtFarmID = LstrList[i].farm_id + " - " + LstrList[i].farm_suffix
          obj.farmId = LstrList[i].farm_id
          obj.farmsuffix = LstrList[i].farm_suffix
          obj.txtNLRateBasis = LstrList[i].nl_rate_basis
          if (LstrList[i].nl_rate != undefined) {
            obj.txtNLRate = Number(LstrList[i].nl_rate).toFixed(2)
          }
          obj.txtNLLoadWt = LstrList[i].nl_load_wt
          obj.add_user = LstrList[i].add_user + " - " + moment(LstrList[i].add_date_time).format('M/D/YYYY h:mm:ss A')
          obj.chg_user = LstrList[i].chg_user + " - " + moment(LstrList[i].chg_date_time).format('M/D/YYYY h:mm:ss A')
          obj.cropyear = LstrList[i].crop_year
          TransformedRowsArray.push(obj)
          obj = {}
        }
      }
      setgridDataOptimize(TransformedRowsArray)
      setState(current => { // Pagination Total Rows length
        return{
          ...current,
          gridFreightLocToLocSetupData : {
            ...current.gridFreightLocToLocSetupData,
              totalElements : TransformedRowsArray.length
          }
        }
      })
      setValue(thisObj, "gridFreightLocToLocSetupData", fetchGridValue(TransformedRowsArray,pageNumber,pageSize))
      setData(thisObj, "gridFreightLocToLocSetupData", TransformedRowsArray);
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillFRTLOCTOLOC event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  
  // Event Trigger on Page change change and row per page change
  const ongridFreightLocToLocSetupDataPagerORsort = (e) => {
    if(e.target.page){
      setPageNumber(e.target.page)
      setValue(thisObj, "gridFreightLocToLocSetupData", fetchGridValue(gridDataOptimize,e.target.page, pageSize))
    }
    if(e.target.size){
      setPageSize(e.target.size)
      if(gridDataOptimize.length < e.target.size ){
        setValue(thisObj, "gridFreightLocToLocSetupData", fetchGridValue(gridDataOptimize,1, e.target.size))
      }
      else{
        setValue(thisObj, "gridFreightLocToLocSetupData", fetchGridValue(gridDataOptimize,pageNumber, e.target.size))
      }
    }
  }
  thisObj.ongridFreightLocToLocSetupDataPagerORsort = ongridFreightLocToLocSetupDataPagerORsort;

  //Add Button Functionality Navoigating to Child screen Freight LOC TO LOC Profile screen
  const onbtnAddClick = () => {
    try {
      var frmFreightLocToLocProfileArray = []
      var frmFreightLocToLocProfileDataObj = {
        cmdOkCaption: "Add",
        lblOrgBPTCaption:  "",
        lblDestBPTCaption: ""
      }
      frmFreightLocToLocProfileArray.push(frmFreightLocToLocProfileDataObj)
      setData(thisObj, "dataComingFromFreightLocToLocSetUpScreen", frmFreightLocToLocProfileArray)
      goTo(thisObj, "SystemMaintenanceMasterManagement#FreightLocToLocProfile#DEFAULT#true#Click");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnAddClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;

  // Edit Button Functionality Navoigating to Child screen Freight LOC TO LOC Profile screen
  const onbtnEditClick = async () => {
    try {
      let vsfLocToLocGridData = getValue(thisObj,"gridFreightLocToLocSetupData")
     
      if(vsfLocToLocGridData !=undefined && vsfLocToLocGridData !=null && vsfLocToLocGridData != ''){
        let selectedRowData = thisObj.state.gridFreightLocToLocSetupData.selected[0];

        if(selectedRowData == undefined){
          showMessage(thisObj, 'Please select a row from Grid to Edit');
          return;
        }
        else{
        let effectiveDate
        let option1 = false
        var frmFreightLocToLocProfileArray = []
        if(selectedRowData.effectiveDate != ""){
          effectiveDate = selectedRowData.txtEffectiveDt
        }
        if(selectedRowData.txtStateID != ""){
          option1 = true
        }

        var frmFreightLocToLocProfileDataObj = {
        cmdOkCaption: "Update",
        lblOrgBPTCaption:  selectedRowData.txtOrigBuyPt,
        lblDestBPTCaption:  selectedRowData.txtDestBuyPt,
        txtMiles : selectedRowData.txtMiles,
        cmbRateBasis:  selectedRowData.txtRateBasis,
        txtRate : selectedRowData.txtRate,
        lblAddedBy:  selectedRowData.add_user,
        lblChangedBy : selectedRowData.chg_user,
        txtEffectiveDate : effectiveDate,
        txtDeadFreight:  selectedRowData.txtMinWt,
        txtScac_id : selectedRowData.txtCarrierAbbr,
        txt_scac_id_old:  selectedRowData.txtCarrierAbbr,
        lblcyr : selectedRowData.cropyear,
        option1:option1,
        lstStateID:  selectedRowData.txtStateID,
        lstCountyID : selectedRowData.txtCountyID,
        lstFarmID : selectedRowData.txtFarmID,
        cmbNL_Basis:  selectedRowData.txtNLRateBasis,
        txtNL_rate : selectedRowData.txtNLRate,
        txtNL_weight:  selectedRowData.txtNLLoadWt
      }
      frmFreightLocToLocProfileArray.push(frmFreightLocToLocProfileDataObj)
      setData(thisObj, "dataComingFromFreightLocToLocSetUpScreen", frmFreightLocToLocProfileArray)
      goTo(thisObj, "SystemMaintenanceMasterManagement#FreightLocToLocProfile#DEFAULT#true#Click");
      }
    }
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnEditClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnEditClick = onbtnEditClick;
  
  //Delete Functionality to delete data from grid
  const onbtnDeleteClick = async () => {
    try {
      let vsfLocToLoc = getValue(thisObj,"gridFreightLocToLocSetupData")
     
      if(vsfLocToLoc !=undefined && vsfLocToLoc !=null && vsfLocToLoc != ''){
        let selectedRowData = thisObj.state.gridFreightLocToLocSetupData.selected[0];
        if(selectedRowData == undefined){
          showMessage(thisObj, 'Please select a row from Grid to Delete');
          return;
        }else{
          let sQueryString
          let FarmSuffix = getValue(thisObj,"ddFarm")//getData(thisObj, "Farm_suffix")
            if( FarmSuffix != undefined && FarmSuffix != null && FarmSuffix.length != 0 ){
                FarmSuffix = FarmSuffix.slice(-3)
            }
          let originBuyPt = thisObj.state.gridFreightLocToLocSetupData.selected[0].originbuyingpoint
          let DestBuyingPoint = thisObj.state.gridFreightLocToLocSetupData.selected[0].destinationBuyingPoint
          let locTolocMiles = thisObj.state.gridFreightLocToLocSetupData.selected[0].txtMiles;
          let locTolocRateBasis = thisObj.state.gridFreightLocToLocSetupData.selected[0].txtRateBasis
          let locToLocRate = Number(thisObj.state.gridFreightLocToLocSetupData.selected[0].txtRate).toFixed(0)
          let effectiveDate = new Date(new Date(thisObj.state.gridFreightLocToLocSetupData.selected[0].effectiveDate).getTime() - (new Date(thisObj.state.gridFreightLocToLocSetupData.selected[0].effectiveDate).getTimezoneOffset() * 60000)).toISOString()
          
          let CarrierAbb = thisObj.state.gridFreightLocToLocSetupData.selected[0].txtCarrierAbbr
          let stateAbb =  thisObj.state.gridFreightLocToLocSetupData.selected[0].txtStateID
          let countyId = thisObj.state.gridFreightLocToLocSetupData.selected[0].txtCountyID
          let cropyear = thisObj.state.gridFreightLocToLocSetupData.selected[0].cropyear 
    
          let FarmId = thisObj.state.gridFreightLocToLocSetupData.selected[0].txtFarmID
    
          if(FarmId == "-"){
            FarmId = ''
            FarmSuffix = ''
          }
          else{
            FarmId =  thisObj.state.gridFreightLocToLocSetupData.selected[0].farmId
            FarmSuffix = thisObj.state.gridFreightLocToLocSetupData.selected[0].farmsuffix
          }
          //alert for deleting
          const response = confirm("Are you sure you wish to delete the Mileage for Origination Location " + "'" + thisObj.state.gridFreightLocToLocSetupData.selected[0].txtOrigArea + "'" + "  and Destination Location  " + "'" + thisObj.state.gridFreightLocToLocSetupData.selected[0].txtOrigCollectionPoint + "'");
          if(response){
            
            let dataObj=
        {
          "orig_buy_pt": originBuyPt,
          "dest_buy_pt": DestBuyingPoint,
          "loc_to_loc_miles":Number(locTolocMiles),
          "loc_to_loc_rate_basis": locTolocRateBasis,
          "loc_to_loc_rate": Number(locToLocRate),
          "user_id": userid,
          "effective_date": effectiveDate,
          "carrier_abbreviation": CarrierAbb,
          "minimum_weight": 0,
          "nl_rate": 0,
          "nl_weight": 0,
          "nl_rate_basis":'',
          "state_id": stateAbb,
          "county_id": countyId,
          "farm_id": FarmId,
          "farm_suffix": FarmSuffix,
          "carrier_abbreviation_old": ""
        }
       let sQueryString = await SystemMaintenanceMasterManagementService.UpdateFreightLocationToLocationDetails("D",cropyear,dataObj)
        if (sQueryString.status == 200) {
          await onbtnSearchClick()
        }
        else{
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
          }
          else{
            return;
          }
        }
      }
      else{
        return;
      }
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnDeleteClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  //Print Functionality
  const onbtnPrintClick = async () => {
    try {
      var obj = new jsPDF('landscape', 'mm', 'a4');
      let datatable = gridDataOptimize//getValue(thisObj, "gridFreightLocToLocSetupData");
      if (datatable != undefined && datatable != null && datatable != "") {
        let headerArray1 = []
        let headerArray2 = []
        let res1 = []
        let res2 = []
        for (var i = 0; i < thisObj.state.gridFreightLocToLocSetupData.columns.length; i++) {
          if (thisObj.state.gridFreightLocToLocSetupData.columns[i].Visible) {
            if (headerArray1.length <= 13) {
              headerArray1.push(thisObj.state.gridFreightLocToLocSetupData.columns[i].Label)
            }
            else {
              headerArray2.push(thisObj.state.gridFreightLocToLocSetupData.columns[i].Label)
            }
          }
        }

        let count = 0;
        for (var i = 0; i < datatable.length; i++) {
          let bodyArr1 = []
          let bodyArr2 = []
          if (thisObj.state.gridFreightLocToLocSetupData.columns[0].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[0].Label)) {
              bodyArr1.push(datatable[i].txtOrigArea)
            }
            else {
              bodyArr2.push(datatable[i].txtOrigArea)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[1].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[1].Label)) {
              bodyArr1.push(datatable[i].txtOrigCollectionPoint)
            }
            else {
              bodyArr2.push(datatable[i].txtOrigCollectionPoint)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[2].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[2].Label)) {
              bodyArr1.push(datatable[i].txtDestCollectionPoint)
            }
            else {
              bodyArr2.push(datatable[i].txtDestCollectionPoint)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[3].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[3].Label)) {
              bodyArr1.push(datatable[i].txtDestArea)
            }
            else {
              bodyArr2.push(datatable[i].txtDestArea)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[4].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[4].Label)) {
              bodyArr1.push(datatable[i].txtOrigBuyPt)
            }
            else {
              bodyArr2.push(datatable[i].txtOrigBuyPt)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[5].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[5].Label)) {
              bodyArr1.push(datatable[i].txtDestBuyPt)
            }
            else {
              bodyArr2.push(datatable[i].txtDestBuyPt)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[6].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[6].Label)) {
              bodyArr1.push(datatable[i].txtMiles)
            }
            else {
              bodyArr2.push(datatable[i].txtMiles)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[7].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[7].Label)) {
              bodyArr1.push(datatable[i].txtRateBasis)
            }
            else {
              bodyArr2.push(datatable[i].txtRateBasis)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[8].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[8].Label)) {
              bodyArr1.push(datatable[i].txtRate)
            }
            else {
              bodyArr2.push(datatable[i].txtRate)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[9].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[9].Label)) {
              bodyArr1.push(datatable[i].txtEffectiveDt)
            }
            else {
              bodyArr2.push(datatable[i].txtEffectiveDt)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[10].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[10].Label)) {
              bodyArr1.push(datatable[i].txtMinWt)
            }
            else {
              bodyArr2.push(datatable[i].txtMinWt)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[11].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[11].Label)) {
              bodyArr1.push(datatable[i].txtCarrierAbbr)
            }
            else {
              bodyArr2.push(datatable[i].txtCarrierAbbr)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[12].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[12].Label)) {
              bodyArr1.push(datatable[i].txtStateID)
            }
            else {
              bodyArr2.push(datatable[i].txtStateID)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[13].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[13].Label)) {
              bodyArr1.push(datatable[i].txtCountyID)
            }
            else {
              bodyArr2.push(datatable[i].txtCountyID)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[14].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[14].Label)) {
              bodyArr1.push(datatable[i].txtFarmID)
            }
            else {
              bodyArr2.push(datatable[i].txtFarmID)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[15].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[15].Label)) {
              bodyArr1.push(datatable[i].txtNLRateBasis)
            }
            else {
              bodyArr2.push(datatable[i].txtNLRateBasis)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[16].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[16].Label)) {
              bodyArr1.push(datatable[i].txtNLRate)
            }
            else {
              bodyArr2.push(datatable[i].txtNLRate)
            }
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[17].Visible) {
            if (headerArray1.includes(thisObj.state.gridFreightLocToLocSetupData.columns[17].Label)) {
              bodyArr1.push(datatable[i].txtNLLoadWt)
            }
            else {
              bodyArr2.push(datatable[i].txtNLLoadWt)
            }
          }
          res1.push(bodyArr1)
          res2.push(bodyArr2)
        }
        let obj = new jsPDF();
        let n = 0
        let incrementValue = 35

        while (n < res1.length) {
          if (n >= incrementValue) {
            obj.addPage();
          }

          obj.autoTable({
            startX: 4,
            startY: 2,
            styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
            margin: { top: 5, left: 0.5 },
            body: res1.slice(n, n + incrementValue),
            theme: 'plain',
            fontStyle: 'normal',
            head: [headerArray1]
          });

          if (headerArray2.length > 0) {
            obj.addPage();

            obj.autoTable({
              startX: 4,
              startY: 2,
              styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
              margin: { top: 5, left: 0.5 },
              body: res2.slice(n, n + incrementValue),
              theme: 'plain',
              fontStyle: 'normal',
              head: [headerArray2]
            });
          }

          n = n + incrementValue;
        }

        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 1; w <= pageCount; w++) {
          obj.setPage(w);
          obj.setFontSize(8);
          obj.text('Location to Location Mileage. ' + cropYear,  1, 290);
          obj.text(200, 285, String(w))
        }

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnPrintClick click event"
        );
      }
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  //Export to excel Functionality
  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      var fileName = "Freight Location to Location Setup"

      let excelData = gridDataOptimize//getValue(thisObj, "gridFreightLocToLocSetupData")
      if (excelData != undefined && excelData != null && excelData != "") {
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var obj = {}
          if (thisObj.state.gridFreightLocToLocSetupData.columns[0].Visible) {
            obj["Orig. Area."] = (excelData[i].txtOrigArea)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[1].Visible) {
            obj["Orig. Coll Pt."] = ExcelNumericDataConvert(excelData[i].txtOrigCollectionPoint)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[2].Visible) {
            obj["Dest. Coll Pt."] = ExcelNumericDataConvert(excelData[i].txtDestCollectionPoint)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[3].Visible) {
            obj["Dest. Area."] = excelData[i].txtDestArea
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[4].Visible) {
            obj["Orig. Buy Pt."] = excelData[i].txtOrigBuyPt
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[5].Visible) {
            obj["Dest. Buy Pt."] = excelData[i].txtDestBuyPt
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[6].Visible) {
            obj["Miles"] = ExcelNumericDataConvert(excelData[i].txtMiles)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[7].Visible) {
            obj["Rate Basis"] = excelData[i].txtRateBasis
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[8].Visible) {
            obj["Rate"] = ExcelNumericDataConvert(excelData[i].txtRate)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[9].Visible) {
            obj["Effective Date"] = excelData[i].txtEffectiveDt
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[10].Visible) {
            obj["Min. Wt."] = ExcelNumericDataConvert(excelData[i].txtMinWt)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[11].Visible) {
            obj["Carrier Abbr."] = ExcelNumericDataConvert(excelData[i].txtCarrierAbbr)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[12].Visible) {
            obj["State ID."] = ExcelNumericDataConvert(excelData[i].txtStateID)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[13].Visible) {
            obj["County ID."] = ExcelNumericDataConvert(excelData[i].txtCountyID, [], [2])
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[14].Visible) {
            obj["Farm ID."] = ExcelNumericDataConvert(excelData[i].txtFarmID)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[15].Visible) {
            obj["NL Rate Basis"] =ExcelNumericDataConvert (excelData[i].txtNLRateBasis)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[16].Visible) {
            obj["NL Rate"] = ExcelNumericDataConvert(excelData[i].txtNLRate)
          }
          if (thisObj.state.gridFreightLocToLocSetupData.columns[17].Visible) {
            obj["NL Load Wt."] = ExcelNumericDataConvert(excelData[i].txtNLLoadWt)
          }
          arrayData.push(obj)

        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnExportToExcelClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnCloseClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("SystemMaintenanceMasterManagement_FreightLocToLocSetupPopUp").childNodes[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}
            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FreightLocToLocSetup*/}

              {/* END_USER_CODE-USER_BEFORE_FreightLocToLocSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFreightLocToLocSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFreightLocToLocSetup*/}

              <GroupBoxWidget
                conf={state.grpbxFreightLocToLocSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxOrigination*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxOrigination*/}

                <GroupBoxWidget
                  conf={state.grpbxOrigination}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblOrigination*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOrigination*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblOrigination}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblOrigination*/}

                  {/* END_USER_CODE-USER_AFTER_lblOrigination*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAreaOrig*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAreaOrig*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAreaOrig}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAreaOrig*/}

                  {/* END_USER_CODE-USER_AFTER_ddAreaOrig*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFocus}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }

                  ></TextBoxWidget>
                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionPointOrig*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionPointOrig*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPointOrig}
                    screenConf={state}
                  ></DropDownWidget>

                  {/* START_USER_CODE-USER_AFTER_ddCollectionPointOrig*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionPointOrig*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCounty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                  {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPointOrig*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPointOrig*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPointOrig}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPointOrig*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPointOrig*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFarm}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                  {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNote*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNote*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNote}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNote*/}

                  {/* END_USER_CODE-USER_AFTER_lblNote*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxOrigination*/}

                {/* END_USER_CODE-USER_AFTER_grpbxOrigination*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDestination*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDestination*/}

                <GroupBoxWidget
                  conf={state.grpbxDestination}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblDestination*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDestination*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDestination}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDestination*/}

                  {/* END_USER_CODE-USER_AFTER_lblDestination*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAreaDest*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAreaDest*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAreaDest}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAreaDest*/}

                  {/* END_USER_CODE-USER_AFTER_ddAreaDest*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionPointDest*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionPointDest*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPointDest}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCollectionPointDest*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionPointDest*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPointDest*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPointDest*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPointDest}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPointDest*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPointDest*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDestination*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDestination*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxEffectiveDate*/}

                <GroupBoxWidget
                  conf={state.grpbxEffectiveDate}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblEffectiveDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblEffectiveDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblEffectiveDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblEffectiveDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblEffectiveDate*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEffectiveDate*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEffectiveDate*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEffectiveDate}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEffectiveDate*/}

                  {/* END_USER_CODE-USER_AFTER_ddEffectiveDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEffectiveDate}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_grpbxEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions1*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions1*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions1}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions1*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions1*/}
                {/* START_USER_CODE-USER_BEFORE_gridFreightLocToLocSetupData*/}

                {/* END_USER_CODE-USER_BEFORE_gridFreightLocToLocSetupData*/}

                <GridWidget
                  conf={state.gridFreightLocToLocSetupData}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFreightLocToLocSetupData}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFreightLocToLocSetupData*/}

                {/* END_USER_CODE-USER_AFTER_gridFreightLocToLocSetupData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFreightLocToLocSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFreightLocToLocSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions2*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions2*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions2}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions2*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions2*/}

              {/* START_USER_CODE-USER_AFTER_FreightLocToLocSetup*/}

              {/* END_USER_CODE-USER_AFTER_FreightLocToLocSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FreightLocToLocSetup
);
