/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvAvailAgreement from "./DelvAvailAgreement";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvAvailAgreement Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvAvailAgreement />);
    });
  });
  afterEach(cleanup);
  test("is DelvAvailAgreement Loads Successfully", () => {
    expect(screen.getByText("DelvAvailAgreement")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvAvailAgreement", () => {
    // START_USER_CODE-USER_DelvAvailAgreement_Custom_Test_Case
    // END_USER_CODE-USER_DelvAvailAgreement_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvAvailAgreement />);
    });
  });
  afterEach(cleanup);
  test("btnAttach(Button Widget) Test Cases", async () => {
    const btnAttach = screen.getByTestId("btnAttach");
    expect(btnAttach).toBeInTheDocument;
    expect(btnAttach.textContent).toEqual(
      t("WarehouseReceipts:DelvAvailAgreement_btnAttach")
    );
  });
  test("Custom Test Cases for btnAttach", () => {
    // START_USER_CODE-USER_btnAttach_TEST
    // END_USER_CODE-USER_btnAttach_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:DelvAvailAgreement_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnRemove(Button Widget) Test Cases", async () => {
    const btnRemove = screen.getByTestId("btnRemove");
    expect(btnRemove).toBeInTheDocument;
    expect(btnRemove.textContent).toEqual(
      t("WarehouseReceipts:DelvAvailAgreement_btnRemove")
    );
  });
  test("Custom Test Cases for btnRemove", () => {
    // START_USER_CODE-USER_btnRemove_TEST
    // END_USER_CODE-USER_btnRemove_TEST
  });
  test("grpbxDelvAvailAgreement(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvAvailAgreement = screen.getByTestId(
      "grpbxDelvAvailAgreement"
    );
    expect(grpbxDelvAvailAgreement.tagName).toBe("BUTTON");
    expect(grpbxDelvAvailAgreement.type).toBe("button");
    expect(grpbxDelvAvailAgreement.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvAvailAgreement", () => {
    // START_USER_CODE-USER_grpbxDelvAvailAgreement_TEST
    // END_USER_CODE-USER_grpbxDelvAvailAgreement_TEST
  });
  test("lblAgreeNumBuyPtId(Label Widget) Test Cases", async () => {
    const lblAgreeNumBuyPtId = screen.getByTestId("lblAgreeNumBuyPtId");
    expect(lblAgreeNumBuyPtId.tagName).toBe("LABEL");
    expect(lblAgreeNumBuyPtId.classList).toContain("form-label");
    expect(lblAgreeNumBuyPtId.textContent).toEqual(
      t("WarehouseReceipts:DelvAvailAgreement_lblAgreeNumBuyPtId")
    );
  });
  test("Custom Test Cases for lblAgreeNumBuyPtId", () => {
    // START_USER_CODE-USER_lblAgreeNumBuyPtId_TEST
    // END_USER_CODE-USER_lblAgreeNumBuyPtId_TEST
  });
  test("txtAgreeNum(Textbox Widget) Test Cases", async () => {
    const txtAgreeNum = screen.getByTestId("txtAgreeNum");
    expect(txtAgreeNum.tagName).toBe("INPUT");
    expect(txtAgreeNum.type).toBe("text");
    expect(txtAgreeNum.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAgreeNum, "1");
    });
  });
  test("Custom Test Cases for txtAgreeNum", () => {
    // START_USER_CODE-USER_txtAgreeNum_TEST
    // END_USER_CODE-USER_txtAgreeNum_TEST
  });
});
