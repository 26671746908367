/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  setData,
  setLabel,
  hide,
  show,
  clearValue,
  disable,
} from "../../shared/WindowImports";

import "./FarmSummaryReportRequest.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ReportUsermanual_FarmSummaryReportRequest(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmSummaryReportRequest",
    windowName: "FarmSummaryReportRequest",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ReportUsermanual.FarmSummaryReportRequest",
    // START_USER_CODE-USER_FarmSummaryReportRequest_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Farm Summary Report Request",
      scrCode: "PN0500A",

    },
    // END_USER_CODE-USER_FarmSummaryReportRequest_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "commandcontainerwidget0",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSubmit: {
      name: "btnSubmit",
      type: "ButtonWidget",
      parent: "commandcontainerwidget0",
      Label: "Submit",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSubmit_PROPERTIES

      // END_USER_CODE-USER_btnSubmit_PROPERTIES
    },
    chkboxCombineAllVendors: {
      name: "chkboxCombineAllVendors",
      type: "CheckBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Combine all Vendors on the Farm",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCombineAllVendors_PROPERTIES

      // END_USER_CODE-USER_chkboxCombineAllVendors_PROPERTIES
    },
    chkbxAllFarms: {
      name: "chkbxAllFarms",
      type: "CheckBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Report all Farms for a Vendor",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxAllFarms_PROPERTIES

      // END_USER_CODE-USER_chkbxAllFarms_PROPERTIES
    },
    chkbxAllVendors: {
      name: "chkbxAllVendors",
      type: "CheckBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Report all Vendors on the Farm",
      ColSpan: "2",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxAllVendors_PROPERTIES
      // END_USER_CODE-USER_chkbxAllVendors_PROPERTIES
    },
    chkbxPremDeduct: {
      name: "chkbxPremDeduct",
      type: "CheckBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Report Premium and deduction details",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxPremDeduct_PROPERTIES

      // END_USER_CODE-USER_chkbxPremDeduct_PROPERTIES
    },
    ddReprtFor: {
      name: "ddReprtFor",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmSummary",
      Label: "Report For:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReprtFor_PROPERTIES
      Options:
        "Both Purchase and Storage:B,Purchase Only:P,Storage Only:S",
      // END_USER_CODE-USER_ddReprtFor_PROPERTIES
    },
    lblAllLocFarmSummaryReport: {
      name: "lblAllLocFarmSummaryReport",
      type: "LabelWidget",
      parent: "grpbxFarmSummary",
      // Label: "Individual Farm Summary (Report 38975-01)",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAllLocFarmSummaryReport_PROPERTIES

      // END_USER_CODE-USER_lblAllLocFarmSummaryReport_PROPERTIES
    },
    radioFarmSummary: {
      name: "radioFarmSummary",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxRadioFarmSummary",
      Options:
        "Individual Farm Summary (Report 38975-01):1,Combined Farm Summary (Report 38965-01):2,All Locations Farm Summary (Report 38959-01):3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioFarmSummary_PROPERTIES

      // END_USER_CODE-USER_radioFarmSummary_PROPERTIES
    },
    txtByngPt: {
      name: "txtByngPt",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Buying Point",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtByngPt_PROPERTIES

      // END_USER_CODE-USER_txtByngPt_PROPERTIES
    },
    txtCountyID: {
      name: "txtCountyID",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "County ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountyID_PROPERTIES

      // END_USER_CODE-USER_txtCountyID_PROPERTIES
    },
    txtFarmNumber: {
      name: "txtFarmNumber",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Farm Number:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmNumber_PROPERTIES

      // END_USER_CODE-USER_txtFarmNumber_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Farm Suffix:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtRequestedVendor: {
      name: "txtRequestedVendor",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Request for a specific vendor only:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRequestedVendor_PROPERTIES

      // END_USER_CODE-USER_txtRequestedVendor_PROPERTIES
    },
    txtSpecificContrct: {
      name: "txtSpecificContrct",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "Request for a specific contract only:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpecificContrct_PROPERTIES

      // END_USER_CODE-USER_txtSpecificContrct_PROPERTIES
    },
    txtStateAbbr: {
      name: "txtStateAbbr",
      type: "TextBoxWidget",
      parent: "grpbxFarmSummary",
      Label: "State Abbr:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateAbbr_PROPERTIES

      // END_USER_CODE-USER_txtStateAbbr_PROPERTIES
    },
    grpbxRadioFarmSummary: {
      name: "grpbxRadioFarmSummary",
      type: "GroupBoxWidget",
      parent: "FarmSummaryReportRequest",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxRadioFarmSummary_PROPERTIES

      // END_USER_CODE-USER_grpbxRadioFarmSummary_PROPERTIES
    },
    grpbxFarmSummary: {
      name: "grpbxFarmSummary",
      type: "GroupBoxWidget",
      parent: "FarmSummaryReportRequest",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFarmSummary_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmSummary_PROPERTIES
    },
    commandcontainerwidget0: {
      name: "commandcontainerwidget0",
      type: "CommandContainerWidget",
      parent: "FarmSummaryReportRequest",
      Label: "commandcontainerwidget0",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_commandcontainerwidget0_PROPERTIES

      // END_USER_CODE-USER_commandcontainerwidget0_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const [individual, setIndividual] = useState();
  const [combined, setCombined] = useState();
  const [allLocations, setAllLocations] = useState();

  useEffect(() => {
    if (individual != undefined && combined != undefined && allLocations != undefined) {
      if (individual == "Y") {
        setValue(thisObj, 'radioFarmSummary', '1')
        buildPanel(1)
      }
      else if (combined == "Y") {
        setValue(thisObj, 'radioFarmSummary', '2')
        buildPanel(2)
      }
      else if (allLocations == "Y") {
        setValue(thisObj, 'radioFarmSummary', '3')
        buildPanel(3)
      }
      else {
        clearValue(thisObj, 'radioFarmSummary')
        resetHidden();
        clearFields();
      }
    }
  }, [individual, combined, allLocations])

  function FormLoad() {
    checkPageAccess();
    checkMenuAccess();
  }

  function checkPageAccess() {
    ContractManagementService.RetrieveAccessPermissionDetails('PN0500', null, 'I')
      .then(response => {
        let data = response
        if (data[0].permission !== "Y") {
          document.getElementById("btnClose").click();
        }
      })
  }

  function checkMenuAccess() {
    let selectedRadio = false;
    ContractManagementService.RetrieveAccessPermissionDetails('PN0500', '001', 'I')
      .then(response => {
        let data = response
        if (data[0].permission !== "Y") {
          document.getElementById("radioFarmSummary0").disabled = true;
          setIndividual("N")
        }
        else {
          setIndividual("Y")
        }
      })
    ContractManagementService.RetrieveAccessPermissionDetails('PN0500', '002', 'I')
      .then(response => {
        let data = response
        if (data[0].permission !== "Y") {
          document.getElementById("radioFarmSummary1").disabled = true;
          setCombined("N")
        }
        else {
          setCombined("Y")
        }
      })
    ContractManagementService.RetrieveAccessPermissionDetails('PN0500', '003', 'I')
      .then(response => {
        let data = response
        if (data[0].permission !== "Y") {
          document.getElementById("radioFarmSummary2").disabled = true;
          setAllLocations("N")
        }
        else {
          setAllLocations("Y")
        }
      })
  }

  function resetHidden() {
    show(thisObj, 'ddReprtFor')
    show(thisObj, 'txtByngPt')
    show(thisObj, 'chkbxPremDeduct')
    show(thisObj, 'chkbxAllFarms')
    show(thisObj, 'txtSpecificContrct')
    show(thisObj, 'txtRequestedVendor')
    show(thisObj, 'chkbxAllVendors')
    show(thisObj, 'chkboxCombineAllVendors')
  }

  function clearFields() {
    clearValue(thisObj, 'txtByngPt')
    clearValue(thisObj, 'txtStateAbbr')
    clearValue(thisObj, 'txtCountyID')
    clearValue(thisObj, 'txtFarmNumber')
    clearValue(thisObj, 'txtFarmSuffix')
    clearValue(thisObj, 'ddReprtFor')
    clearValue(thisObj, 'txtSpecificContrct')
    clearValue(thisObj, 'txtRequestedVendor')
    clearValue(thisObj, 'chkbxAllVendors')
    clearValue(thisObj, 'chkbxAllFarms')
    clearValue(thisObj, 'chkboxCombineAllVendors')
    clearValue(thisObj, 'chkbxPremDeduct')
    resetHidden();
  }

  const onradioFarmSummaryChange = () => {
    let summaryType = thisObj.values.radioFarmSummary;
    clearFields();
    buildPanel(summaryType);
  }
  thisObj.onradioFarmSummaryChange = onradioFarmSummaryChange;

  const onchkbxAllFarmsChange = () => {
    let selected = thisObj.values.chkbxAllFarms;
    if (selected == true) {
      setValue(thisObj, 'chkbxAllFarms', true)
    }
    else {
      setValue(thisObj, 'chkbxAllFarms', false)
    }
  }
  thisObj.onchkbxAllFarmsChange = onchkbxAllFarmsChange;

  const onchkbxPremDeductChange = () => {
    let selected = thisObj.values.chkbxPremDeduct;
    if (selected == true) {
      setValue(thisObj, 'chkbxPremDeduct', true)
    }
    else {
      setValue(thisObj, 'chkbxPremDeduct', false)
    }
  }
  thisObj.onchkbxPremDeductChange = onchkbxPremDeductChange;

  const onchkboxCombineAllVendorsChange = () => {
    let selected = thisObj.values.chkboxCombineAllVendors;
    if (selected == true) {
      setValue(thisObj, 'chkboxCombineAllVendors', true)
    }
    else {
      setValue(thisObj, 'chkboxCombineAllVendors', false)
    }
  }
  thisObj.onchkboxCombineAllVendorsChange = onchkboxCombineAllVendorsChange;

  const onchkbxAllVendorsChange = () => {
    let selected = thisObj.values.chkbxAllVendors;
    if (selected == true) {
      setValue(thisObj, 'chkbxAllVendors', true)
    }
    else {
      setValue(thisObj, 'chkbxAllVendors', false)
    }
  }
  thisObj.onchkbxAllVendorsChange = onchkbxAllVendorsChange;

  const onddReprtForChange = () => {
    let selected = thisObj.values.ddReprtFor;
    setValue(thisObj, 'ddReprtFor', selected)
  }
  thisObj.onddReprtForChange = onddReprtForChange;

  function buildPanel(summaryType) {
    if (summaryType == 1) {
      setValue(thisObj, 'lblAllLocFarmSummaryReport', "Individual Farm Summary (Report 38975-01)")
      hide(thisObj, 'ddReprtFor')
      hide(thisObj, 'chkboxCombineAllVendors')
      hide(thisObj, 'chkbxAllVendors')
    } else if (summaryType == 2) {
      setValue(thisObj, 'lblAllLocFarmSummaryReport', "Combined Farm Summary (Report 38965-01)")
      setValue(thisObj, 'chkbxAllVendors', true)
      setValue(thisObj, 'ddReprtFor', 'B')
      hide(thisObj, 'txtRequestedVendor')
      hide(thisObj, 'txtSpecificContrct')
      hide(thisObj, 'chkboxCombineAllVendors')
      hide(thisObj, 'chkbxAllFarms')
      hide(thisObj, 'chkbxPremDeduct')
    } else if (summaryType == 3) {
      setValue(thisObj, 'lblAllLocFarmSummaryReport', "All Locations Farm Summary (Report 38959-01)")
      hide(thisObj, 'txtByngPt')
      hide(thisObj, 'txtSpecificContrct')
      hide(thisObj, 'ddReprtFor')
      hide(thisObj, 'chkbxAllVendors')
      hide(thisObj, 'chkbxAllFarms')
      hide(thisObj, 'chkbxPremDeduct')
    }
  }

  const onbtnSubmitClick = () => {
    let msg = ValidateData();
    if (msg == undefined || msg == "" || msg == null) {
      let dataObj = {};
      dataObj.user_id = sessionStorage.getItem('userid');
      dataObj.crop_year = String(JSON.parse(sessionStorage.getItem('year')));
      dataObj.comp_id = sessionStorage.getItem('compId')
      let inputData = thisObj.values;
      let job_id;
      if (thisObj.values.radioFarmSummary == '1') {
        job_id = 'P38973';
        dataObj.parm_name_03 = 'BUY_PT';
        dataObj.parm_value_03 = inputData.txtByngPt;
        dataObj.parm_name_04 = 'STATE';
        dataObj.parm_value_04 = inputData.txtStateAbbr;
        dataObj.parm_name_05 = 'COUNTY';
        dataObj.parm_value_05 = inputData.txtCountyID;
        dataObj.parm_name_06 = 'FARM';
        dataObj.parm_value_06 = inputData.txtFarmNumber;
        dataObj.parm_name_07 = 'VENDOR';
        dataObj.parm_value_07 = inputData.txtRequestedVendor;
        dataObj.parm_name_08 = 'CONTRACT';
        dataObj.parm_value_08 = inputData.txtSpecificContrct;
        dataObj.parm_name_09 = 'FARM_SW';
        if (inputData.chkbxAllFarms == true) {
          dataObj.parm_value_09 = 'Y';
        }
        else {
          dataObj.parm_value_09 = 'N';
        }
        dataObj.parm_name_10 = 'PREM_DEDUCT';
        if (inputData.chkbxPremDeduct == true) {
          dataObj.parm_value_10 = 'Y';
        }
        else {
          dataObj.parm_value_10 = 'N';
        }
        dataObj.parm_name_11 = 'FARM_SUFFIX';
        dataObj.parm_value_11 = inputData.txtFarmSuffix;

        //-------------------------
        dataObj.parm_name_01 = "";
        dataObj.parm_value_01 = "";
        dataObj.parm_name_02 = "";
        dataObj.parm_value_02 = "";
        dataObj.parm_name_12 = "";
        dataObj.parm_value_12 = "";
        dataObj.parm_name_13 = "";
        dataObj.parm_value_13 = "";
        dataObj.parm_name_14 = "";
        dataObj.parm_value_14 = "";
        dataObj.parm_name_15 = "";
        dataObj.parm_value_15 = "";
        dataObj.parm_name_16 = "";
        dataObj.parm_value_16 = "";
        dataObj.parm_name_17 = "";
        dataObj.parm_value_17 = "";
        dataObj.parm_name_18 = "";
        dataObj.parm_value_18 = "";
        dataObj.parm_name_19 = "";
        dataObj.parm_value_19 = "";
        dataObj.parm_name_20 = "";
        dataObj.parm_value_20 = "";
        //-------------------------

        AccountPayment.CreateBatchJobRequestDetails(job_id, dataObj)
          .then(response => {
            if (response.status == 200) {
              showMessage(thisObj, "Request for the Individual Farm Summary (Report 38975-01) has been submitted.", true)
              clearFields();
              buildPanel(thisObj.values.radioFarmSummary);
              document.getElementById("btnClose").click();
            }
            else {
              showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
            }
          })
      }
      else if (thisObj.values.radioFarmSummary == '2') {
        job_id = 'P38965';
        dataObj.parm_name_03 = 'BUY_PT';
        dataObj.parm_value_03 = inputData.txtByngPt;
        dataObj.parm_name_04 = 'STATE';
        dataObj.parm_value_04 = inputData.txtStateAbbr;
        dataObj.parm_name_05 = 'COUNTY';
        dataObj.parm_value_05 = inputData.txtCountyID;
        dataObj.parm_name_06 = 'FARM';
        dataObj.parm_value_06 = inputData.txtFarmNumber;
        dataObj.parm_name_07 = 'PURCH_STORE';
        dataObj.parm_value_07 = inputData.ddReprtFor;
        dataObj.parm_name_08 = 'FARM_SW';
        if (inputData.chkbxAllVendors == true) {
          dataObj.parm_value_08 = 'Y';
        }
        else {
          dataObj.parm_value_08 = 'N';
        }
        dataObj.parm_name_09 = 'FARM_SUFFIX';
        dataObj.parm_value_09 = inputData.txtFarmSuffix;

        //-------------------------
        dataObj.parm_name_01 = "";
        dataObj.parm_value_01 = "";
        dataObj.parm_name_02 = "";
        dataObj.parm_value_02 = "";
        dataObj.parm_name_10 = "";
        dataObj.parm_value_10 = "";
        dataObj.parm_name_11 = "";
        dataObj.parm_value_11 = "";
        dataObj.parm_name_12 = "";
        dataObj.parm_value_12 = "";
        dataObj.parm_name_13 = "";
        dataObj.parm_value_13 = "";
        dataObj.parm_name_14 = "";
        dataObj.parm_value_14 = "";
        dataObj.parm_name_15 = "";
        dataObj.parm_value_15 = "";
        dataObj.parm_name_16 = "";
        dataObj.parm_value_16 = "";
        dataObj.parm_name_17 = "";
        dataObj.parm_value_17 = "";
        dataObj.parm_name_18 = "";
        dataObj.parm_value_18 = "";
        dataObj.parm_name_19 = "";
        dataObj.parm_value_19 = "";
        dataObj.parm_name_20 = "";
        dataObj.parm_value_20 = "";
        //-------------------------

        AccountPayment.CreateBatchJobRequestDetails(job_id, dataObj)
          .then(response => {
            if (response.status == 200) {
              showMessage(thisObj, "Request for the Combined Farm Summary (Report 38965-01) has been submitted.", true)
              clearFields();
              buildPanel(thisObj.values.radioFarmSummary);
              document.getElementById("btnClose").click();
            }
            else {
              showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
            }
          })
      }
      else if (thisObj.values.radioFarmSummary == '3') {
        job_id = 'P38959';
        dataObj.parm_name_03 = 'STATE';
        dataObj.parm_value_03 = inputData.txtStateAbbr;
        dataObj.parm_name_04 = 'COUNTY';
        dataObj.parm_value_04 = inputData.txtCountyID;
        dataObj.parm_name_05 = 'FARM';
        dataObj.parm_value_05 = inputData.txtFarmNumber;
        dataObj.parm_name_06 = 'VENDOR';
        dataObj.parm_value_06 = inputData.txtRequestedVendor;
        dataObj.parm_name_07 = 'FARM_SW';
        if (inputData.chkboxCombineAllVendors == true) {
          dataObj.parm_value_07 = 'Y';
        }
        else {
          dataObj.parm_value_07 = 'N';
        }
        dataObj.parm_name_08 = 'FARM_SUFFIX';
        dataObj.parm_value_08 = inputData.txtFarmSuffix;

        //-------------------------
        dataObj.parm_name_01 = "";
        dataObj.parm_value_01 = "";
        dataObj.parm_name_02 = "";
        dataObj.parm_value_02 = "";
        dataObj.parm_name_09 = "";
        dataObj.parm_value_09 = "";
        dataObj.parm_name_10 = "";
        dataObj.parm_value_10 = "";
        dataObj.parm_name_11 = "";
        dataObj.parm_value_11 = "";
        dataObj.parm_name_12 = "";
        dataObj.parm_value_12 = "";
        dataObj.parm_name_13 = "";
        dataObj.parm_value_13 = "";
        dataObj.parm_name_14 = "";
        dataObj.parm_value_14 = "";
        dataObj.parm_name_15 = "";
        dataObj.parm_value_15 = "";
        dataObj.parm_name_16 = "";
        dataObj.parm_value_16 = "";
        dataObj.parm_name_17 = "";
        dataObj.parm_value_17 = "";
        dataObj.parm_name_18 = "";
        dataObj.parm_value_18 = "";
        dataObj.parm_name_19 = "";
        dataObj.parm_value_19 = "";
        dataObj.parm_name_20 = "";
        dataObj.parm_value_20 = "";
        //-------------------------

        AccountPayment.CreateBatchJobRequestDetails(job_id, dataObj)
          .then(response => {
            if (response.status == 200) {
              showMessage(thisObj, "Request for the All Locations Farm Summary (Report 38959-01) has been submitted.", true)
              clearFields();
              buildPanel(thisObj.values.radioFarmSummary);
              document.getElementById("btnClose").click();
            }
            else {
              showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
            }
          })
      }
    }
    else {
      showMessage(thisObj, msg);
    }
  }
  thisObj.onbtnSubmitClick = onbtnSubmitClick;

  function ValidateData() {
    let msg = "";
    const hasNumber = /\d/;
    const isNumber = /^\d+$/;
    let Data = thisObj.values;
    if (Data.radioFarmSummary == "" || Data.radioFarmSummary == undefined || Data.radioFarmSummary == null) {
      msg = "Select Summary Report Request Type.";
      return msg;
    }
    if (Data.radioFarmSummary != '3') {
      if (Data.txtByngPt == '' || Data.txtByngPt == undefined) {
        msg = "Buying Point is required.";
        document.getElementById("txtByngPt").focus();
        return msg;
      }
      else if (Data.txtByngPt.trim().length != 3) {
        msg = "Buying Point is invalid.";
        document.getElementById("txtByngPt").focus();
        return msg;
      }
    }
    if (Data.txtStateAbbr == "" || Data.txtStateAbbr == undefined) {
      if (Data.radioFarmSummary == '3') {
        msg = "Either must request by State/County/Farm or Vendor number.";
      } else {
        msg = "State is required.";
      }
      document.getElementById("txtStateAbbr").focus();
      return msg;
    }
    if (Data.txtStateAbbr.trim().length != 2 || hasNumber.test(Data.txtStateAbbr) == true) {
      msg = "State is invalid.";
      document.getElementById("txtStateAbbr").focus();
      return msg;
    }
    if (Data.txtCountyID == "" || Data.txtCountyID == undefined) {
      if (Data.txtCountyID == '3') {
        msg = "Either must request by State/County/Farm or Vendor number.";
      } else {
        msg = "County is required.";
      }
      document.getElementById("txtCountyID").focus();
      return msg;
    }
    if (Data.txtCountyID.trim().length != 3 || isNumber.test(Data.txtCountyID) == false) {
      msg = "County is invalid.";
      document.getElementById("txtCountyID").focus();
      return msg;
    }
    if (Data.txtFarmNumber == "" || Data.txtFarmNumber == undefined) {
      if (Data.txtFarmNumber == '3') {
        msg = "Either must request by State/County/Farm or Vendor number.";
      } else {
        msg = "Farm is required.";
      }
      document.getElementById("txtFarmNumber").focus();
      return msg;
    }
    if (Data.txtFarmNumber.trim().length != 7 || isNumber.test(Data.txtFarmNumber) == false) {
      msg = "Farm is invalid.";
      document.getElementById("txtFarmNumber").focus();
      return msg;
    }
    if (Data.txtFarmSuffix == "" || Data.txtFarmSuffix == undefined) {
      msg = "Farm Suffix is invalid.";
      document.getElementById("txtFarmSuffix").focus();
      return msg;
    }
    if (Data.txtFarmSuffix.trim().length != 3 || isNumber.test(Data.txtFarmSuffix) == false) {
      msg = "Farm Suffix is invalid.";
      document.getElementById("txtFarmSuffix").focus();
      return msg;
    }
    if (Data.radioFarmSummary != '2') {
      if (Data.txtRequestedVendor != '' && Data.txtRequestedVendor != undefined) {
        if (Data.txtRequestedVendor.length > 0) {
          if (Data.txtRequestedVendor.trim().length != 6) {
            msg = "Vendor is invalid.";
            document.getElementById("txtRequestedVendor").focus();
            return msg;
          }
        }
      }
    }
    if (Data.radioFarmSummary == '1') {
      if (Data.txtSpecificContrct != '' && Data.txtSpecificContrct != undefined) {
        if (Data.txtSpecificContrct.length > 0) {
          if (Data.txtSpecificContrct.trim().length != 6) {
            msg = "Contract is invalid.";
            document.getElementById("txtSpecificContrct").focus();
            return msg;
          }
        }
      }
    }
  }

  const ontxtByngPtBlur = () => {
    if (thisObj.values.radioFarmSummary !== '2') {
      if (thisObj.values.txtByngPt != undefined) {
        if (thisObj.values.txtByngPt.length > 0) {
          setValue(thisObj, 'txtByngPt', thisObj.values.txtByngPt.toUpperCase());
        }
      }
    }
  }
  thisObj.ontxtByngPtBlur = ontxtByngPtBlur;

  const ontxtCountyIDBlur = () => {
    if (thisObj.values.txtCountyID != undefined) {
      if (thisObj.values.txtCountyID.length > 0) {
        setValue(thisObj, 'txtCountyID', thisObj.values.txtCountyID.toUpperCase());
      }
    }
  }
  thisObj.ontxtCountyIDBlur = ontxtCountyIDBlur;

  const ontxtFarmNumberBlur = () => {
    if (thisObj.values.txtFarmNumber != undefined) {
      if (thisObj.values.txtFarmNumber.length > 0) {
        setValue(thisObj, 'txtFarmNumber', thisObj.values.txtFarmNumber.toUpperCase());
      }
    }
  }
  thisObj.ontxtFarmNumberBlur = ontxtFarmNumberBlur;

  const ontxtFarmSuffixBlur = () => {
    if (thisObj.values.txtFarmSuffix != undefined) {
      if (thisObj.values.txtFarmSuffix.length > 0) {
        setValue(thisObj, 'txtFarmSuffix', thisObj.values.txtFarmSuffix.toUpperCase());
      }
    }
  }
  thisObj.ontxtFarmSuffixBlur = ontxtFarmSuffixBlur;

  const ontxtStateAbbrBlur = () => {
    if (thisObj.values.txtStateAbbr != undefined) {
      if (thisObj.values.txtStateAbbr.length > 0) {
        setValue(thisObj, 'txtStateAbbr', thisObj.values.txtStateAbbr.toUpperCase());
      }
    }
  }
  thisObj.ontxtStateAbbrBlur = ontxtStateAbbrBlur;

  const ontxtRequestedVendorBlur = () => {
    if (thisObj.values.txtRequestedVendor != undefined) {
      if (thisObj.values.txtRequestedVendor.length > 0) {
        setValue(thisObj, 'txtRequestedVendor', thisObj.values.txtRequestedVendor.toUpperCase());
      }
    }
  }
  thisObj.ontxtRequestedVendorBlur = ontxtRequestedVendorBlur;

  const ontxtSpecificContrctBlur = () => {
    if (thisObj.values.txtSpecificContrct != undefined) {
      if (thisObj.values.txtSpecificContrct.length > 0) {
        setValue(thisObj, 'txtSpecificContrct', thisObj.values.txtSpecificContrct.toUpperCase());
      }
    }
  }
  thisObj.ontxtSpecificContrctBlur = ontxtSpecificContrctBlur;

  // END_USER_CODE-USER_METHODS


  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmSummaryReportRequest*/}

              {/* END_USER_CODE-USER_BEFORE_FarmSummaryReportRequest*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxRadioFarmSummary*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxRadioFarmSummary*/}

              <GroupBoxWidget
                conf={state.grpbxRadioFarmSummary}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_radioFarmSummary*/}

                {/* END_USER_CODE-USER_BEFORE_radioFarmSummary*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioFarmSummary}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioFarmSummary*/}

                {/* END_USER_CODE-USER_AFTER_radioFarmSummary*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxRadioFarmSummary*/}

              {/* END_USER_CODE-USER_AFTER_grpbxRadioFarmSummary*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxFarmSummary*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmSummary*/}

              <GroupBoxWidget conf={state.grpbxFarmSummary} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAllLocFarmSummaryReport*/}

                {/* END_USER_CODE-USER_BEFORE_lblAllLocFarmSummaryReport*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAllLocFarmSummaryReport}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAllLocFarmSummaryReport*/}

                {/* END_USER_CODE-USER_AFTER_lblAllLocFarmSummaryReport*/}
                {/* START_USER_CODE-USER_BEFORE_txtByngPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtByngPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtByngPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtByngPt*/}

                {/* END_USER_CODE-USER_AFTER_txtByngPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtStateAbbr*/}

                {/* END_USER_CODE-USER_BEFORE_txtStateAbbr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStateAbbr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStateAbbr*/}

                {/* END_USER_CODE-USER_AFTER_txtStateAbbr*/}
                {/* START_USER_CODE-USER_BEFORE_txtCountyID*/}

                {/* END_USER_CODE-USER_BEFORE_txtCountyID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCountyID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCountyID*/}

                {/* END_USER_CODE-USER_AFTER_txtCountyID*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_txtRequestedVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtRequestedVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRequestedVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRequestedVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtRequestedVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtSpecificContrct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSpecificContrct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSpecificContrct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSpecificContrct*/}

                {/* END_USER_CODE-USER_AFTER_txtSpecificContrct*/}
                {/* START_USER_CODE-USER_BEFORE_ddReprtFor*/}

                {/* END_USER_CODE-USER_BEFORE_ddReprtFor*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddReprtFor}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddReprtFor*/}

                {/* END_USER_CODE-USER_AFTER_ddReprtFor*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxAllFarms*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxAllFarms*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxAllFarms}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxAllFarms*/}

                {/* END_USER_CODE-USER_AFTER_chkbxAllFarms*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxPremDeduct*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxPremDeduct*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxPremDeduct}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxPremDeduct*/}

                {/* END_USER_CODE-USER_AFTER_chkbxPremDeduct*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxCombineAllVendors*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxCombineAllVendors*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCombineAllVendors}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxCombineAllVendors*/}

                {/* END_USER_CODE-USER_AFTER_chkboxCombineAllVendors*/}
                {/* END_USER_CODE-USER_AFTER_ddReprtFor*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxAllVendors*/}
                {/* END_USER_CODE-USER_BEFORE_chkbxAllVendors*/}
                <CheckboxWidget
                  onChange={event => invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxAllVendors}
                  screenConf={state}
                ></CheckboxWidget>                {/* START_USER_CODE-USER_AFTER_chkbxAllVendors*/}
                {/* END_USER_CODE-USER_AFTER_chkbxAllVendors*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmSummary*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmSummary*/}
              {/* START_USER_CODE-USER_BEFORE_commandcontainerwidget0*/}

              {/* END_USER_CODE-USER_BEFORE_commandcontainerwidget0*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.commandcontainerwidget0}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSubmit*/}

                {/* END_USER_CODE-USER_BEFORE_btnSubmit*/}

                <ButtonWidget
                  conf={state.btnSubmit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSubmit*/}

                {/* END_USER_CODE-USER_AFTER_btnSubmit*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_commandcontainerwidget0*/}

              {/* END_USER_CODE-USER_AFTER_commandcontainerwidget0*/}

              {/* START_USER_CODE-USER_AFTER_FarmSummaryReportRequest*/}

              {/* END_USER_CODE-USER_AFTER_FarmSummaryReportRequest*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ReportUsermanual_FarmSummaryReportRequest);
