/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  enable,
  disable,
  getValue,
  setValue,
  getData,
  setData, goTo, getSelectedRowNumber
} from "../../shared/WindowImports";

import "./ManualAcctDistSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualAcctDistSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  const funcId = 'PN0240';
  const funcSubId = null;
  const gridId = 'gridManualAcctDist';

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualAcctDistSearch",
    windowName: "ManualAcctDistSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualAcctDistSearch",
    // START_USER_CODE-USER_ManualAcctDistSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Account Distribution Maintenance",
      scrCode: "PN0240A",
    },
    // END_USER_CODE-USER_ManualAcctDistSearch_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnReversal: {
      name: "btnReversal",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Reversal",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReversal_PROPERTIES

      // END_USER_CODE-USER_btnReversal_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnView: {
      name: "btnView",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "View",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView_PROPERTIES

      // END_USER_CODE-USER_btnView_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "txtManualAcctDistSearch",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    colAccount: {
      name: "colAccount",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Account",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAccount_PROPERTIES

      // END_USER_CODE-USER_colAccount_PROPERTIES
    },
    colAmount: {
      name: "colAmount",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmount_PROPERTIES

      // END_USER_CODE-USER_colAmount_PROPERTIES
    },
    colBuyingPoint: {
      name: "colBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colChargeLoc: {
      name: "colChargeLoc",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Charge Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChargeLoc_PROPERTIES

      // END_USER_CODE-USER_colChargeLoc_PROPERTIES
    },
    colDept: {
      name: "colDept",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Dept",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDept_PROPERTIES

      // END_USER_CODE-USER_colDept_PROPERTIES
    },
    colNumber1: {
      name: "colNumber1",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber1_PROPERTIES

      // END_USER_CODE-USER_colNumber1_PROPERTIES
    },
    colNumber2: {
      name: "colNumber2",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber2_PROPERTIES

      // END_USER_CODE-USER_colNumber2_PROPERTIES
    },
    colQuantity: {
      name: "colQuantity",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Quantity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colQuantity_PROPERTIES

      // END_USER_CODE-USER_colQuantity_PROPERTIES
    },
    colResp: {
      name: "colResp",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Resp",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colResp_PROPERTIES

      // END_USER_CODE-USER_colResp_PROPERTIES
    },
    colSubDept: {
      name: "colSubDept",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Sub Dept",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSubDept_PROPERTIES

      // END_USER_CODE-USER_colSubDept_PROPERTIES
    },
    colType1: {
      name: "colType1",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1_PROPERTIES

      // END_USER_CODE-USER_colType1_PROPERTIES
    },
    colType2: {
      name: "colType2",
      type: "GridColumnWidget",
      parent: "gridManualAcctDist",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2_PROPERTIES

      // END_USER_CODE-USER_colType2_PROPERTIES
    },
    gridManualAcctDist: {
      name: "gridManualAcctDist",
      type: "GridWidget",
      parent: "txtManualAcctDistSearch",
      gridCellsOrder:
        "txtcolBuyingPoint,txtcolType1,txtcolNumber1,txtcolType2,txtcolNumber2,txtcolAccount,txtcolResp,txtcolDept,txtcolSubDept,txtcolChargeLoc,txtcolAmount,txtcolQuantity",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridManualAcctDist_PROPERTIES

      // END_USER_CODE-USER_gridManualAcctDist_PROPERTIES
    },
    txt1007Settlement: {
      name: "txt1007Settlement",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "(1007) Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Settlement_PROPERTIES

      // END_USER_CODE-USER_txt1007Settlement_PROPERTIES
    },
    txtAccount: {
      name: "txtAccount",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Account #:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccount_PROPERTIES

      // END_USER_CODE-USER_txtAccount_PROPERTIES
    },
    txtAmount: {
      name: "txtAmount",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Amount $:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtChargeLocation: {
      name: "txtChargeLocation",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Charge Location:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChargeLocation_PROPERTIES

      // END_USER_CODE-USER_txtChargeLocation_PROPERTIES
    },
    txtCheck: {
      name: "txtCheck",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheck_PROPERTIES

      // END_USER_CODE-USER_txtCheck_PROPERTIES
    },
    txtcolAccount: {
      name: "txtcolAccount",
      type: "TextBoxWidget",
      colName: "colAccount",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAccount_PROPERTIES

      // END_USER_CODE-USER_txtcolAccount_PROPERTIES
    },
    txtcolAmount: {
      name: "txtcolAmount",
      type: "TextBoxWidget",
      colName: "colAmount",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolAmount_PROPERTIES
    },
    txtcolBuyingPoint: {
      name: "txtcolBuyingPoint",
      type: "TextBoxWidget",
      colName: "colBuyingPoint",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolChargeLoc: {
      name: "txtcolChargeLoc",
      type: "TextBoxWidget",
      colName: "colChargeLoc",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChargeLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolChargeLoc_PROPERTIES
    },
    txtcolDept: {
      name: "txtcolDept",
      type: "TextBoxWidget",
      colName: "colDept",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDept_PROPERTIES

      // END_USER_CODE-USER_txtcolDept_PROPERTIES
    },
    txtcolNumber1: {
      name: "txtcolNumber1",
      type: "TextBoxWidget",
      colName: "colNumber1",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber1_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber1_PROPERTIES
    },
    txtcolNumber2: {
      name: "txtcolNumber2",
      type: "TextBoxWidget",
      colName: "colNumber2",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber2_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber2_PROPERTIES
    },
    txtcolQuantity: {
      name: "txtcolQuantity",
      type: "TextBoxWidget",
      colName: "colQuantity",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolQuantity_PROPERTIES

      // END_USER_CODE-USER_txtcolQuantity_PROPERTIES
    },
    txtcolResp: {
      name: "txtcolResp",
      type: "TextBoxWidget",
      colName: "colResp",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolResp_PROPERTIES

      // END_USER_CODE-USER_txtcolResp_PROPERTIES
    },
    txtcolSubDept: {
      name: "txtcolSubDept",
      type: "TextBoxWidget",
      colName: "colSubDept",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSubDept_PROPERTIES

      // END_USER_CODE-USER_txtcolSubDept_PROPERTIES
    },
    txtcolType1: {
      name: "txtcolType1",
      type: "TextBoxWidget",
      colName: "colType1",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1_PROPERTIES

      // END_USER_CODE-USER_txtcolType1_PROPERTIES
    },
    txtcolType2: {
      name: "txtcolType2",
      type: "TextBoxWidget",
      colName: "colType2",
      parent: "gridManualAcctDist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2_PROPERTIES

      // END_USER_CODE-USER_txtcolType2_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtDeliveryAgreement: {
      name: "txtDeliveryAgreement",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Delivery Agreement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES
    },
    txtDepartment: {
      name: "txtDepartment",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Department:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDepartment_PROPERTIES

      // END_USER_CODE-USER_txtDepartment_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Location:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtLocationName: {
      name: "txtLocationName",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Location:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocationName_PROPERTIES
      ReadOnly: true,
      Enable: false
      // END_USER_CODE-USER_txtLocationName_PROPERTIES
    },
    txtQuantity: {
      name: "txtQuantity",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Quantity #:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtQuantity_PROPERTIES

      // END_USER_CODE-USER_txtQuantity_PROPERTIES
    },
    txtResponsibility: {
      name: "txtResponsibility",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Responsibility:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtResponsibility_PROPERTIES

      // END_USER_CODE-USER_txtResponsibility_PROPERTIES
    },
    txtSC95Inspection: {
      name: "txtSC95Inspection",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "(SC95) Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95Inspection_PROPERTIES

      // END_USER_CODE-USER_txtSC95Inspection_PROPERTIES
    },
    txtSubDepartment: {
      name: "txtSubDepartment",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Sub-Department:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDepartment_PROPERTIES

      // END_USER_CODE-USER_txtSubDepartment_PROPERTIES
    },
    txtTradeSettlement: {
      name: "txtTradeSettlement",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Trade Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettlement_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettlement_PROPERTIES
    },
    txtTradIinspection: {
      name: "txtTradIinspection",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Trade Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradIinspection_PROPERTIES

      // END_USER_CODE-USER_txtTradIinspection_PROPERTIES
    },
    txtTransfer: {
      name: "txtTransfer",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Transfer #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransfer_PROPERTIES

      // END_USER_CODE-USER_txtTransfer_PROPERTIES
    },
    txtWarehouseReceipt: {
      name: "txtWarehouseReceipt",
      type: "TextBoxWidget",
      parent: "txtManualAcctDistSearch",
      Label: "Whse Receipt #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES
    },
    txtManualAcctDistSearch: {
      name: "txtManualAcctDistSearch",
      type: "GroupBoxWidget",
      parent: "ManualAcctDistSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_txtManualAcctDistSearch_PROPERTIES

      // END_USER_CODE-USER_txtManualAcctDistSearch_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "ManualAcctDistSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#ManualAcctDistMaint": {}
      },
      REVERSE: {
        "SystemMaintenanceApplicationSupport#ManualAcctDistMaint": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: [
    //     "SystemMaintenanceApplicationSupport#ManualAcctDistMaint#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);

  const [addAccess, setAddAccess] = useState(false);
  const [viewAccess, setViewAccess] = useState(false);
  const [reversalAccess, setReversalAccess] = useState(false);
  const [searchAccess, setSearchAccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    formLoad();
    setEventHandlers();

    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const manualAccDistMaintData = getData(thisObj, 'frmManualAcctDistSearch');
    const gridData = getValue(thisObj, gridId);
    if (manualAccDistMaintData) {
      const newAddedRowItem = {
        transaction_num: manualAccDistMaintData.TransactionNum,
        txtcolBuyingPoint: manualAccDistMaintData.Location,
        txtcolAccount: manualAccDistMaintData.AccountNum,
        txtcolResp: manualAccDistMaintData.AcctResp,
        txtcolDept: manualAccDistMaintData.AcctDept,
        txtcolSubDept: manualAccDistMaintData.AcctSubDept,
        txtcolChargeLoc: manualAccDistMaintData.AcctChargeLoc,
        txtcolAmount: manualAccDistMaintData.Amount,
        txtcolQuantity: manualAccDistMaintData.AcctQty
      }

      if (manualAccDistMaintData.CheckNum && parseInt(manualAccDistMaintData.CheckNum)) {
        newAddedRowItem.txtcolType1 = 'Check #';
        newAddedRowItem.txtcolNumber1 = (manualAccDistMaintData.CheckNum?.toString() || '0').padStart(6, '0');
        newAddedRowItem.txtcolType2 = 'Issue Loc.';
        newAddedRowItem.txtcolNumber2 = manualAccDistMaintData.IssueLoc || '';
      }

      if (manualAccDistMaintData.Cont && parseInt(manualAccDistMaintData.Cont)) {
        newAddedRowItem.txtcolType1 = 'Cont. #';
        newAddedRowItem.txtcolNumber1 = (manualAccDistMaintData.Cont?.toString() || '0').padStart(6, '0');
        newAddedRowItem.txtcolType2 = '';
        newAddedRowItem.txtcolNumber2 = '';
      }

      if (manualAccDistMaintData.SC95Num && parseInt(manualAccDistMaintData.SC95Num)) {
        newAddedRowItem.txtcolType1 = '1007 #';
        newAddedRowItem.txtcolNumber1 = (manualAccDistMaintData['1007Num']?.toString() || '0').padStart(7, '0');
        newAddedRowItem.txtcolType2 = 'SC95 #';
        newAddedRowItem.txtcolNumber2 = manualAccDistMaintData.SC95Num;
      }

      if (manualAccDistMaintData.WhseRcptNum && parseInt(manualAccDistMaintData.WhseRcptNum)) {
        newAddedRowItem.txtcolType1 = 'WR #';
        newAddedRowItem.txtcolNumber1 = (manualAccDistMaintData.WhseRcptNum?.toString() || '0').padStart(6, '0');
        newAddedRowItem.txtcolType2 = 'Obl. Loc.';
        newAddedRowItem.txtcolNumber2 = manualAccDistMaintData.OblLoc;
      }

      if (manualAccDistMaintData.TransferNum && parseInt(manualAccDistMaintData.TransferNum)) {
        newAddedRowItem.txtcolType1 = 'Transfer #';
        newAddedRowItem.txtcolNumber1 = (manualAccDistMaintData.TransferNum?.toString() || '0').padStart(6, '0');
        newAddedRowItem.txtcolType2 = 'Ship Loc.';
        newAddedRowItem.txtcolNumber2 = manualAccDistMaintData.XferShpLoc;
      }

      if (manualAccDistMaintData.TradeInspNum && parseInt(manualAccDistMaintData.TradeInspNum)) {
        newAddedRowItem.txtcolType1 = 'Trade Settle #';
        newAddedRowItem.txtcolNumber1 = (manualAccDistMaintData.TradeSettleNum?.toString() || '0').padStart(7, '0');
        newAddedRowItem.txtcolType2 = 'Trade Insp. #';
        newAddedRowItem.txtcolNumber2 = manualAccDistMaintData.TradeInspNum;
      }

      if (manualAccDistMaintData.DelvAgree && parseInt(manualAccDistMaintData.DelvAgree)) {
        newAddedRowItem.txtcolType1 = 'Delv.';
        newAddedRowItem.txtcolNumber1 = '';
        newAddedRowItem.txtcolType2 = 'Agree #';
        newAddedRowItem.txtcolNumber2 = (manualAccDistMaintData.DelvAgree?.toString() || '0').padStart(7, '0');
      }

      gridData.push(newAddedRowItem);
      setValue(thisObj, gridId, gridData);
      setData(thisObj, 'frmManualAcctDistSearch', null);
      setData(thisObj, 'ManualAcctDistSearchResults', gridData);
      if (addAccess) {
        enable(thisObj, 'btnAdd');
      } else {
        disable(thisObj, 'btnAdd');
      }
      disable(thisObj, 'btnView');
      disable(thisObj, 'btnReversal');
      if (gridData?.length) {
        if (viewAccess) { enable(thisObj, 'btnView'); }
        if (reversalAccess) { enable(thisObj, 'btnReversal'); }
      }
    }
  }, [getData(thisObj, 'frmManualAcctDistSearch')]);

  useEffect(() => {
    const manualAccDistSearchRefresh = getData(thisObj, 'frmManualAcctDistSearchrefresh');
    if (manualAccDistSearchRefresh === 'true') {
      doSearch();
      setData(thisObj, 'frmManualAcctDistSearchrefresh', null);
    }
  }, [getData(thisObj, 'frmManualAcctDistSearchrefresh')]);
  // START_USER_CODE-USER_METHODS

  const setEventHandlers = () => {
    document.getElementById('txtLocation').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtCheck').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtContract').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtSC95Inspection').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txt1007Settlement').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTradIinspection').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTradeSettlement').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtWarehouseReceipt').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTransfer').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtDeliveryAgreement').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtAccount').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtResponsibility').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtDepartment').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtSubDepartment').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtChargeLocation').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtAmount').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtQuantity').addEventListener('focus', allSelectOnFocus);
    document.getElementById('txtLocation').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtCheck').addEventListener('keypress', allowCheckNumber);
    document.getElementById('txtContract').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtSC95Inspection').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txt1007Settlement').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtTradIinspection').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtWarehouseReceipt').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtTransfer').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtDeliveryAgreement').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtAccount').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtResponsibility').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtDepartment').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtSubDepartment').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtChargeLocation').addEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtAmount').addEventListener('keypress', allowAmountChar);
    document.getElementById('txtQuantity').addEventListener('keypress', allowQuantityChar);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtLocation').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtCheck').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtContract').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtSC95Inspection').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txt1007Settlement').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTradIinspection').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTradeSettlement').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtWarehouseReceipt').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtTransfer').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtDeliveryAgreement').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtAccount').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtResponsibility').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtDepartment').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtSubDepartment').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtChargeLocation').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtAmount').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtQuantity').removeEventListener('focus', allSelectOnFocus);
    document.getElementById('txtLocation').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtCheck').removeEventListener('keypress', allowCheckNumber);
    document.getElementById('txtContract').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtSC95Inspection').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txt1007Settlement').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtTradIinspection').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtTradeSettlement').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtWarehouseReceipt').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtTransfer').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtDeliveryAgreement').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtAccount').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtResponsibility').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtDepartment').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtSubDepartment').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtChargeLocation').removeEventListener('keypress', notAllowSpecialChar);
    document.getElementById('txtAmount').removeEventListener('keypress', allowAmountChar);
    document.getElementById('txtQuantity').removeEventListener('keypress', allowQuantityChar);
  };

  const checkSecurity = async () => {
    try {
      disable(thisObj, 'btnSearch');
      disable(thisObj, 'btnAdd');
      disable(thisObj, 'btnView');
      disable(thisObj, 'btnReversal');
      setSearchAccess(false);
      setAddAccess(false);
      setViewAccess(false);
      setReversalAccess(false);

      const accessDRes = await ContractManagementService
        .RetrieveAccessPermissionDetails(funcId, funcSubId, 'D');

      if (accessDRes?.[0]?.permission === 'Y') {
        enable(thisObj, 'btnSearch');
        enable(thisObj, 'btnAdd');
        setSearchAccess(true);
        setAddAccess(true);
        setViewAccess(true);
        setReversalAccess(true);
        formActivate();
        return;
      }

      const accessURes = await ContractManagementService
        .RetrieveAccessPermissionDetails(funcId, funcSubId, 'U');

      if (accessURes?.[0]?.permission === 'Y') {
        enable(thisObj, 'btnSearch');
        enable(thisObj, 'btnAdd');
        setSearchAccess(true);
        setAddAccess(true);
        setViewAccess(true);
        formActivate();
        return;
      }

      const accessIRes = await ContractManagementService
        .RetrieveAccessPermissionDetails(funcId, funcSubId, 'I');

      if (accessIRes?.[0]?.permission === 'Y') {
        enable(thisObj, 'btnSearch');
        setSearchAccess(true);
        setViewAccess(true);
        formActivate();
        return;
      }
      formActivate();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Check Security: ${error?.toString()}`
        );
      }
    }
  };

  const formLoad = () => {
    try {
      checkSecurity();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
    }
  };

  const formActivate = () => {
    try {
      if (addAccess) {
        enable(thisObj, 'btnAdd');
      }
      disable(thisObj, 'btnView');
      disable(thisObj, 'btnReversal');

      const gridData = getValue(thisObj, gridId);
      if (gridData?.length) {
        if (viewAccess) { enable(thisObj, 'btnView') }
        if (reversalAccess) { enable(thisObj, 'btnReversal') }
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formActivate: ${error?.toString()}`
        );
      }
    }
  };

  const formFieldsValidation = async () => {
    const isLocationValid = await ontxtLocationBlur(null, true);
    if (!isLocationValid) { return false; }
    if (!ontxtCheckBlur(null, true)) { return false; }
    if (!ontxtSC95InspectionBlur(null, true)) { return false; }
    if (!ontxtTradIinspectionBlur(null, true)) { return false; }
    if (!ontxtAccountBlur(null, true)) { return false; }
    if (!ontxtResponsibilityBlur(null, true)) { return false; }
    if (!ontxtDepartmentBlur(null, true)) { return false; }
    if (!ontxtSubDepartmentBlur(null, true)) { return false; }
    if (!ontxtSubDepartmentBlur(null, true)) { return false; }
    const isChargeLocationValid = await ontxtChargeLocationBlur(null, true);
    if (!isChargeLocationValid) { return false; }

    return true;
  };

  const isFormValid = async () => {
    const location = getValue(thisObj, 'txtLocation');
    const checkNum = getValue(thisObj, 'txtCheck');
    const contractNum = getValue(thisObj, 'txtContract');
    const fv95 = getValue(thisObj, 'txtSC95Inspection');
    const txt1007 = getValue(thisObj, 'txt1007Settlement');
    const tradeInspect = getValue(thisObj, 'txtTradIinspection');
    const tradeSettlement = getValue(thisObj, 'txtTradeSettlement');
    const whseRcpt = getValue(thisObj, 'txtWarehouseReceipt');
    const transferNum = getValue(thisObj, 'txtTransfer');
    const delvAgree = getValue(thisObj, 'txtDeliveryAgreement');

    if (
      !location &&
      !checkNum &&
      !contractNum &&
      !fv95 &&
      !txt1007 &&
      !tradeInspect &&
      !tradeSettlement &&
      !whseRcpt &&
      !transferNum &&
      !delvAgree
    ) {
      document.getElementById('txtLocation').focus();
      alert(`You must specify one of the following:\n\n1. Location\n2. Check #\n3. Contract #\n4. SC95 #\n5. 1007 #\n6. Trade Inspection #\n7. Trade Settle #\n8. Whse Receipt #\n9. Transfer #\n10. Delivery Aggreement #\n11. OilStock Load #`);
      return false;
    }

    const checkForFormFields = await formFieldsValidation();
    if (!checkForFormFields) {
      return false;
    }

    return true;
  };

  const doSearch = async () => {
    try {
      setLoading(true);
      const action = 'LST';
      const buyPtId = getValue(thisObj, 'txtLocation');
      const distAccount = getValue(thisObj, 'txtAccount');
      const distResp = getValue(thisObj, 'txtResponsibility');
      const distDept = getValue(thisObj, 'txtDepartment');
      const distSubDept = getValue(thisObj, 'txtSubDepartment');
      const distLocation = getValue(thisObj, 'txtChargeLocation');
      const distAmount = getValue(thisObj, 'txtAmount');
      const distQuantity = getValue(thisObj, 'txtQuantity');
      const checkNumber = getValue(thisObj, 'txtCheck');
      let auditCheckNum = '';
      let auditReceivableNum = '';
      if (checkNumber) {
        if (!isNaN(checkNumber)) {
          auditCheckNum = checkNumber;
          auditReceivableNum = '0'
        } else {
          auditCheckNum = '0';
          auditReceivableNum = checkNumber.substr(1, 5);
        }
      }
      const auditContractNum = getValue(thisObj, 'txtContract');
      const auditInspectNum = getValue(thisObj, 'txtSC95Inspection');
      const auditSettleNum = getValue(thisObj, 'txt1007Settlement');
      const auditWhseRcpt = getValue(thisObj, 'txtWarehouseReceipt');
      const auditXferNum = getValue(thisObj, 'txtTransfer');
      const auditTradeInspect = getValue(thisObj, 'txtTradIinspection');
      const auditTradeSettle = getValue(thisObj, 'txtTradeSettlement');
      const auditDelvAgree = getValue(thisObj, 'txtDeliveryAgreement');

      const searchResponse = await SystemMaintenanceMasterManagementService
        .RetrieveManualAccountingDistribution(action, buyPtId, distAccount, distResp,
          distDept, distSubDept, distLocation, distAmount, distQuantity,
          auditCheckNum, auditReceivableNum, auditContractNum, auditInspectNum,
          auditSettleNum, auditWhseRcpt, auditXferNum, auditTradeInspect,
          auditTradeSettle, auditDelvAgree);

      if (Array.isArray(searchResponse) && searchResponse?.length) {
        const allData = searchResponse[0].acct_dist_activities;
        const gridData = [];
        if (allData?.length) {
          allData.forEach(item => {
            const {
              transaction_num,
              comp_id,
              crop_year,
              buy_pt_id,
              dist_account,
              dist_resp,
              dist_dept,
              dist_sub_dept,
              dist_loc,
              dist_amount,
              dist_quantity,
              audit_check_num,
              audit_cont_num,
              audit_insp_num,
              audit_settle_num,
              audit_whse_rcpt,
              audit_xfer_num,
              audit_receivable_num,
              audit_buy_pt,
              audit_trade_insp,
              audit_trade_settle,
              audit_delv_agree_num,
              ibm_date_time,
              audit_xfer_type,
              audit_user,
              audit_reason,
              audit_date_time,
            } = item;

            const rowItem = {
              transaction_num: transaction_num,
              txtcolBuyingPoint: buy_pt_id,
              txtcolAccount: dist_account,
              txtcolResp: dist_resp,
              txtcolDept: dist_dept,
              txtcolSubDept: dist_sub_dept,
              txtcolChargeLoc: dist_loc,
              txtcolAmount: parseFloat(dist_amount?.toString() || '0.00')?.toFixed(2),
              txtcolQuantity: dist_quantity
            }

            if (audit_check_num !== '') {
              rowItem.txtcolType1 = 'Check #';
              if (parseInt(audit_check_num) === 0) {
                rowItem.txtcolNumber1 = `S${audit_receivable_num?.toString().padStart(5, '0')}`;
              } else {
                rowItem.txtcolNumber1 = `${audit_check_num?.toString().padStart(6, '0')}`;
              }
              rowItem.txtcolType2 = 'Issue Loc.';
              rowItem.txtcolNumber2 = audit_buy_pt;
            }

            if (audit_cont_num !== '') {
              rowItem.txtcolType1 = 'Cont. #';
              rowItem.txtcolNumber1 = `${audit_cont_num?.toString().padStart(6, '0')}`;
              rowItem.txtcolType2 = '';
              rowItem.txtcolNumber2 = '';
            }

            if (audit_insp_num !== '') {
              rowItem.txtcolType1 = '1007 #';
              rowItem.txtcolNumber1 = `${audit_settle_num?.toString().padStart(7, '0')}`;
              rowItem.txtcolType2 = 'SC95 #';
              rowItem.txtcolNumber2 = audit_insp_num;
            }

            if (audit_whse_rcpt !== '') {
              rowItem.txtcolType1 = 'WR #';
              rowItem.txtcolNumber1 = `${audit_whse_rcpt?.toString().padStart(6, '0')}`;
              rowItem.txtcolType2 = 'Obl. Loc.';
              rowItem.txtcolNumber2 = audit_buy_pt;
            }

            if (audit_xfer_num !== '') {
              rowItem.txtcolType1 = 'Transfer #';
              rowItem.txtcolNumber1 = `${audit_xfer_num?.toString().padStart(6, '0')}`;
              rowItem.txtcolType2 = 'Ship Loc.';
              rowItem.txtcolNumber2 = audit_buy_pt;
            }

            if (audit_trade_insp !== '') {
              rowItem.txtcolType1 = 'Trade Settle #';
              rowItem.txtcolNumber1 = `${audit_trade_settle?.toString().padStart(7, '0')}`;
              rowItem.txtcolType2 = 'Trade Insp. #';
              rowItem.txtcolNumber2 = audit_trade_insp;
            }

            if (audit_delv_agree_num !== '') {
              rowItem.txtcolType1 = 'Delv.';
              rowItem.txtcolNumber1 = '';
              rowItem.txtcolType2 = 'Agree #';
              rowItem.txtcolNumber2 = `${audit_delv_agree_num?.toString().padStart(7, '0')}`;
            }

            gridData.push(rowItem);
          });
        }
        setValue(thisObj, gridId, gridData);
        setData(thisObj, 'ManualAcctDistSearchResults', gridData);
        if (addAccess) {
          enable(thisObj, 'btnAdd');
        } else {
          disable(thisObj, 'btnAdd');
        }
        disable(thisObj, 'btnView');
        disable(thisObj, 'btnReversal');
        if (gridData?.length) {
          if (viewAccess) { enable(thisObj, 'btnView'); }
          if (reversalAccess) { enable(thisObj, 'btnReversal'); }
        }
        setLoading(false);
      } else {
        setLoading(false);
        const errMessage = 'Error converting data type numeric to numeric.';
        showMessage(thisObj, 'An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.' + '\n\nError:' + '\n\n' + errMessage);
      }
      // acct_dist_activities
    } catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during doSearch: ${error?.toString()}`
        );
      }
    }
  };

  const onbtnCloseClick = async () => {
    try {
      const checkForFormFields = await formFieldsValidation();
      if (!checkForFormFields) {
        return;
      }

      document.getElementById("SystemMaintenanceApplicationSupport_ManualAcctDistSearchPopUp").childNodes[0].click();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Close button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnAddClick = async () => {
    try {
      const accessURes = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, 'U');
      if (accessURes?.[0]?.permission?.toUpperCase() !== 'Y') {
        showMessage(thisObj, 'You do not have security permissions for this function!');
        return;
      }

      const checkForFormFields = await formFieldsValidation();
      if (!checkForFormFields) {
        return;
      }

      const maintdetailsData = {
        cmdOkCaption: 'Add',
        lblTransactionNumCaption: ''
      };

      setData(thisObj, 'manualAcctDistMaint', maintdetailsData);
      goTo(thisObj, 'SystemMaintenanceApplicationSupport#ManualAcctDistMaint#DEFAULT#true#Click');
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Add button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnReversalClick = async () => {
    try {
      let value = thisObj.state.gridManualAcctDist.selected[0];
      if (value !== undefined) {
        const accessDRes = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, 'D');
        if (accessDRes?.[0]?.permission?.toUpperCase() !== 'Y') {
          showMessage(thisObj, 'You do not have security permissions for this function!');
          return;
        }

        const checkForFormFields = await formFieldsValidation();
        if (!checkForFormFields) {
          return;
        }

        const selectedRowNumber = getSelectedRowNumber(thisObj, gridId);
        const allData = getData(thisObj, 'ManualAcctDistSearchResults');

        const transactionNum = selectedRowNumber !== -1
          ? allData[selectedRowNumber]?.transaction_num
          : allData[0]?.transaction_num;

        const maintdetailsData = {
          cmdOkCaption: 'Reversal',
          lblTransactionNumCaption: transactionNum?.toString()?.trim()
        };

        setData(thisObj, 'manualAcctDistMaint', maintdetailsData);
        goTo(thisObj, 'SystemMaintenanceApplicationSupport#ManualAcctDistMaint#DEFAULT#true#Click');
      } else {
        showMessage(thisObj, "Please select a row from Grid to Reverse");
        return false;
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Reversal button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnReversalClick = onbtnReversalClick;

  const onbtnViewClick = async () => {
    try {
      let value = thisObj.state.gridManualAcctDist.selected[0];
      if (value !== undefined) {
        const accessIRes = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, 'I');
        if (accessIRes?.[0]?.permission?.toUpperCase() !== 'Y') {
          showMessage(thisObj, 'You do not have security permissions for this function!');
          return;
        }

        const checkForFormFields = await formFieldsValidation();
        if (!checkForFormFields) {
          return;
        }

        const selectedRowNumber = getSelectedRowNumber(thisObj, gridId);
        const allData = getData(thisObj, 'ManualAcctDistSearchResults');

        const transactionNum = selectedRowNumber !== -1
          ? allData[selectedRowNumber]?.transaction_num
          : allData[0]?.transaction_num;

        const maintdetailsData = {
          cmdOkCaption: 'View',
          lblTransactionNumCaption: transactionNum?.toString()?.trim()
        };

        setData(thisObj, 'manualAcctDistMaint', maintdetailsData);
        goTo(thisObj, 'SystemMaintenanceApplicationSupport#ManualAcctDistMaint#DEFAULT#true#Click');
      } else {
        showMessage(thisObj, "Please select a row from Grid to View");
        return false;
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during View button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnViewClick = onbtnViewClick;

  const onbtnSearchClick = async () => {
    try {
      const location = getValue(thisObj, 'txtLocation');

      const accessIRes = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, 'I', location);
      if (accessIRes?.[0]?.permission?.toUpperCase() !== 'Y') {
        showMessage(thisObj, 'You do not have security permissions for this function!');
        return;
      }

      const isValid = await isFormValid();
      if (!isValid) {
        return;
      }

      const checkNum = getValue(thisObj, 'txtCheck');
      const contractNum = getValue(thisObj, 'txtContract');
      const fv95 = getValue(thisObj, 'txtSC95Inspection');
      const txt1007 = getValue(thisObj, 'txt1007Settlement');
      const tradeInspect = getValue(thisObj, 'txtTradIinspection');
      const tradeSettlement = getValue(thisObj, 'txtTradeSettlement');
      const whseRcpt = getValue(thisObj, 'txtWarehouseReceipt');
      const transferNum = getValue(thisObj, 'txtTransfer');
      const accountNum = getValue(thisObj, 'txtAccount');
      const acctResp = getValue(thisObj, 'txtResponsibility');
      const acctDept = getValue(thisObj, 'txtDepartment');
      const acctSubDept = getValue(thisObj, 'txtSubDepartment');
      const acctChargeLoc = getValue(thisObj, 'txtChargeLocation');
      const acctAmount = getValue(thisObj, 'txtAmount');
      const acctQty = getValue(thisObj, 'txtQuantity');
      const delvAgree = getValue(thisObj, 'txtDeliveryAgreement');

      if (
        !checkNum &&
        !contractNum &&
        !fv95 &&
        !txt1007 &&
        !tradeInspect &&
        !tradeSettlement &&
        !whseRcpt &&
        !transferNum &&
        !accountNum &&
        !acctResp &&
        !acctDept &&
        !acctSubDept &&
        !acctChargeLoc &&
        !acctAmount &&
        !acctQty &&
        !delvAgree
      ) {
        const prompt1 = window
          .confirm(`Searching by 'Location Only' may take awhile to return results.\n\nDo you wish to continue?`);
        if (prompt1) {
          doSearch();
        }
      } else {
        doSearch();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Search button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnSearchClick = onbtnSearchClick;

  const ontxtLocationBlur = async (e, shouldReturnIsValid = false) => {
    try {
      let location = getValue(thisObj, 'txtLocation');
      if (!location) {
        setData(thisObj, 'lblLocName', '');
        setValue(thisObj, 'txtLocationName', '');
        if (shouldReturnIsValid === true) { return true };
      } else {
        setData(thisObj, 'lblLocName', '');
        setValue(thisObj, 'txtLocationName', '');
        location = location.trim().toUpperCase();
        setValue(thisObj, 'txtLocation', location);

        const buyingPtRes = await ContractManagementService.RetrieveBuyingPointControlDetails(funcId, funcSubId, null, null, location);
        if (!buyingPtRes?.[0]?.buy_pt_name) {
          if (shouldReturnIsValid === true) {
            showMessage(thisObj, 'Location not found!');
            document.getElementById('txtLocation').focus();
            return false;
          };
        } else {
          const buyingPtName = buyingPtRes[0].buy_pt_name;
          setData(thisObj, 'lblLocName', buyingPtName);
          setValue(thisObj, 'txtLocationName', buyingPtName);
          if (shouldReturnIsValid === true) { return true };
        }
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ontxtLocationBlur: ${error?.toString()}`
        );
      }
      if (shouldReturnIsValid === true) { return false };
    }
  }

  thisObj.ontxtLocationBlur = ontxtLocationBlur;

  const ontxtCheckBlur = (e, shouldReturnIsValid = false) => {
    const checkNum = getValue(thisObj, 'txtCheck');
    if (Boolean(checkNum)) {
      if (!isNaN(checkNum)) {
        const formattedCheckNum = checkNum?.toString()?.trim()?.padStart(6, '0');
        setValue(thisObj, 'txtCheck', formattedCheckNum);
        if (shouldReturnIsValid === true) { return true };
      } else {
        const formattedCheckNum = checkNum?.toString()?.trim();
        const firstLetter = formattedCheckNum?.substr(0, 1);
        const remainingLetters = formattedCheckNum?.substr(1, 5);
        if (firstLetter?.toUpperCase() === 'S' && !isNaN(remainingLetters)) {
          setValue(thisObj, 'txtCheck', `S${remainingLetters?.toString()?.padStart(5, '0')}`);
          if (shouldReturnIsValid === true) { return true };
        } else {
          if (shouldReturnIsValid === true) {
            showMessage(thisObj, 'Please enter a valid Check Number.');
            document.getElementById('txtCheck').focus();
            return false;
          }
        }
      }
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtCheckBlur = ontxtCheckBlur;

  const ontxtContractBlur = (e) => {
    if (e.target.value > 0) {
      setValue(thisObj, 'txtContract', e.target.value.padStart(6, '0'));
    } else {
      setValue(thisObj, 'txtContract', '');
    }
  };

  thisObj.ontxtContractBlur = ontxtContractBlur;

  const ontxtSC95InspectionBlur = (e, shouldReturnIsValid = false) => {
    const value = getValue(thisObj, 'txtSC95Inspection');
    if (value) {
      const txtsc95Number = value?.trim()?.toUpperCase();
      setValue(thisObj, 'txtSC95Inspection', txtsc95Number);
      if (txtsc95Number.length !== 7) {
        if (shouldReturnIsValid === true) {
          showMessage(thisObj, 'Please enter a valid SC95 Number');
          document.getElementById('txtSC95Inspection').focus();
          return false;
        }
      }
      if (shouldReturnIsValid === true) { return true };
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtSC95InspectionBlur = ontxtSC95InspectionBlur;

  const ontxt1007SettlementBlur = (e) => {
    if (e.target.value > 0) {
      setValue(thisObj, 'txt1007Settlement', e.target.value?.toString().padStart(7, '0'));
    } else {
      setValue(thisObj, 'txt1007Settlement', '');
    }
  };

  thisObj.ontxt1007SettlementBlur = ontxt1007SettlementBlur;

  const ontxtTradIinspectionBlur = (e, shouldReturnIsValid = false) => {
    const value = getValue(thisObj, 'txtTradIinspection');
    if (value) {
      const tradeInspect = value?.trim()?.toUpperCase();
      setValue(thisObj, 'txtTradIinspection', tradeInspect);
      if (tradeInspect.length !== 7) {
        if (shouldReturnIsValid === true) {
          showMessage(thisObj, 'Please enter a valid Trade Inspection Number.');
          document.getElementById('txtTradIinspection').focus();
          return false;
        }
      }
      if (shouldReturnIsValid === true) { return true };
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtTradIinspectionBlur = ontxtTradIinspectionBlur;

  const ontxtTradeSettlementBlur = (e) => {
    if (e.target.value > 0) {
      const tradeSettleNum = e.target.value?.toString().padStart(7, '0');
      setValue(thisObj, 'txtTradeSettlement', tradeSettleNum);
    } else {
      setValue(thisObj, 'txtTradeSettlement', '');
    }
  };

  thisObj.ontxtTradeSettlementBlur = ontxtTradeSettlementBlur;

  const ontxtWarehouseReceiptBlur = (e) => {
    if (e.target.value > 0) {
      const warehouseReceiptNum = e.target.value?.toString()?.padStart(6, '0');
      setValue(thisObj, 'txtWarehouseReceipt', warehouseReceiptNum);
    } else {
      setValue(thisObj, 'txtWarehouseReceipt', '');
    }
  };

  thisObj.ontxtWarehouseReceiptBlur = ontxtWarehouseReceiptBlur;

  const ontxtTransferBlur = (e) => {
    if (!e.target.value) {
      return;
    }
    const transferNum = e.target.value?.toString()?.trim()?.toUpperCase()?.padStart(6, '0');
    setValue(thisObj, 'txtTransfer', transferNum);
  };

  thisObj.ontxtTransferBlur = ontxtTransferBlur;

  const ontxtDeliveryAgreementBlur = (e) => {
    if (e.target.value > 0) {
      const deliveryAgreement = e.target.value?.toString()?.padStart(7, '0');
      setValue(thisObj, 'txtDeliveryAgreement', deliveryAgreement);
    } else {
      setValue(thisObj, 'txtDeliveryAgreement', '');
    }
  };

  thisObj.ontxtDeliveryAgreementBlur = ontxtDeliveryAgreementBlur;

  const ontxtAccountBlur = (e, shouldReturnIsValid = false) => {
    let value = getValue(thisObj, 'txtAccount');
    if (value) {
      const accountNum = value?.trim()?.toUpperCase();
      setValue(thisObj, 'txtAccount', accountNum);
      if (accountNum.length !== 5) {
        if (shouldReturnIsValid === true) {
          showMessage(thisObj, 'Please enter a valid Account Number.');
          document.getElementById('txtAccount').focus();
          return false;
        }
      }
      if (shouldReturnIsValid === true) { return true };
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtAccountBlur = ontxtAccountBlur;

  const ontxtResponsibilityBlur = (e, shouldReturnIsValid = false) => {
    const value = getValue(thisObj, 'txtResponsibility');
    if (value) {
      const accResponsibilityNum = value?.trim()?.toUpperCase();
      setValue(thisObj, 'txtResponsibility', accResponsibilityNum);
      if (accResponsibilityNum.length !== 3) {
        if (shouldReturnIsValid === true) {
          showMessage(thisObj, 'Please enter a valid Account Responsibility.');
          document.getElementById('txtResponsibility').focus();
          return false;
        }
      }
      if (shouldReturnIsValid === true) { return true };
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtResponsibilityBlur = ontxtResponsibilityBlur;

  const ontxtDepartmentBlur = (e, shouldReturnIsValid = false) => {
    const value = getValue(thisObj, 'txtDepartment');
    if (value) {
      const accDepartmentNum = value?.trim()?.toUpperCase();
      setValue(thisObj, 'txtDepartment', accDepartmentNum);
      if (accDepartmentNum.length !== 2) {
        if (shouldReturnIsValid === true) {
          showMessage(thisObj, 'Please enter a valid Account Department.');
          document.getElementById('txtDepartment').focus();
          return false;
        }
      }
      if (shouldReturnIsValid === true) { return true };
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtDepartmentBlur = ontxtDepartmentBlur;

  const ontxtSubDepartmentBlur = (e, shouldReturnIsValid = false) => {
    const value = getValue(thisObj, 'txtSubDepartment');
    if (value) {
      const accSubDepartmentNum = value?.trim()?.toUpperCase();
      setValue(thisObj, 'txtSubDepartment', accSubDepartmentNum);
      if (accSubDepartmentNum.length !== 3) {
        if (shouldReturnIsValid === true) {
          showMessage(thisObj, 'Please enter a valid Account Sub-Department.');
          document.getElementById('txtSubDepartment').focus();
          return false;
        }
      }
      if (shouldReturnIsValid === true) { return true };
    }
    if (shouldReturnIsValid === true) { return true };
  };

  thisObj.ontxtSubDepartmentBlur = ontxtSubDepartmentBlur;

  const ontxtChargeLocationBlur = async (e, shouldReturnIsValid = false) => {
    try {
      let chrgLocation = getValue(thisObj, 'txtChargeLocation');
      if (!chrgLocation) {
        setData(thisObj, 'lblChrgLocName', '');
        if (shouldReturnIsValid === true) { return true };
      } else {
        setData(thisObj, 'lblChrgLocName', '');
        chrgLocation = chrgLocation.trim().toUpperCase();
        setValue(thisObj, 'txtChargeLocation', chrgLocation);

        const buyingPtRes = await ContractManagementService
          .RetrieveBuyingPointControlDetails(funcId, funcSubId, null, null, chrgLocation);
        if (!buyingPtRes?.[0]?.buy_pt_name) {
          if (shouldReturnIsValid === true) {
            showMessage(thisObj, 'Charge Location not found!');
            document.getElementById('txtChargeLocation').focus();
            return false;
          };
        } else {
          const buyingPtName = buyingPtRes[0].buy_pt_name;
          setData(thisObj, 'lblChrgLocName', buyingPtName);
          if (shouldReturnIsValid === true) { return buyingPtName };
        }
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ontxtChargeLocationBlur: ${error?.toString()}`
        );
      }
      if (shouldReturnIsValid === true) { return false };
    }
  }

  thisObj.ontxtChargeLocationBlur = ontxtChargeLocationBlur;

  const ontxtAmountBlur = (e) => {
    if (e.target.value) {
      const amount = parseFloat(e.target.value.toString()).toFixed(2);
      setValue(thisObj, 'txtAmount', amount.slice(0, 12));
    }
  };

  thisObj.ontxtAmountBlur = ontxtAmountBlur;

  const allSelectOnFocus = (event) => {
    document.getElementById(event.target.id).select();
  };

  const notAllowSpecialChar = (e) => {
    if (e.keyCode === 38 || e.keyCode === 39) {
      e.preventDefault();
    }
  };

  const allowCheckNumber = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57)
      || e.keyCode === 8
      || e.keyCode === 83
      || e.keyCode === 115
      || e.keyCode === 3
      || e.keyCode === 22
      || e.keyCode === 24)) {
      e.preventDefault();
    }
  };

  const allowOnlyNumbers = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57)
      || e.keyCode === 8
      || e.keyCode === 3
      || e.keyCode === 22
      || e.keyCode === 24)) {
      e.preventDefault();
    }
  };

  const allowAmountChar = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57)
      || e.keyCode === 8
      || (!e.target.value.includes('.') && e.keyCode === 46)
      || (!e.target.value.includes('-') && e.keyCode === 45)
      || e.keyCode === 3
      || e.keyCode === 22
      || e.keyCode === 24)) {
      e.preventDefault();
    }
  };

  const allowQuantityChar = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57)
      || e.keyCode === 8
      || (!e.target.value.includes('-') && e.keyCode === 45)
      || e.keyCode === 3
      || e.keyCode === 22
      || e.keyCode === 24)) {
      e.preventDefault();
    }
  };

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualAcctDistSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ManualAcctDistSearch*/}

              {/* START_USER_CODE-USER_BEFORE_txtManualAcctDistSearch*/}

              {/* END_USER_CODE-USER_BEFORE_txtManualAcctDistSearch*/}

              <GroupBoxWidget
                conf={state.txtManualAcctDistSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtLocation*/}

                {/* START_USER_CODE-USER_BEFORE_txtLocationName*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocationName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocationName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLocationName*/}

                {/* END_USER_CODE-USER_AFTER_txtLocationName*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheck*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheck*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheck*/}

                {/* END_USER_CODE-USER_AFTER_txtCheck*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccount*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccount*/}

                {/* END_USER_CODE-USER_AFTER_txtAccount*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtResponsibility*/}

                {/* END_USER_CODE-USER_BEFORE_txtResponsibility*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtResponsibility}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtResponsibility*/}

                {/* END_USER_CODE-USER_AFTER_txtResponsibility*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95Inspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95Inspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95Inspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95Inspection*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95Inspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtDepartment*/}

                {/* END_USER_CODE-USER_BEFORE_txtDepartment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDepartment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDepartment*/}

                {/* END_USER_CODE-USER_AFTER_txtDepartment*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007Settlement*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007Settlement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007Settlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007Settlement*/}

                {/* END_USER_CODE-USER_AFTER_txt1007Settlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtSubDepartment*/}

                {/* END_USER_CODE-USER_BEFORE_txtSubDepartment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSubDepartment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSubDepartment*/}

                {/* END_USER_CODE-USER_AFTER_txtSubDepartment*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradIinspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradIinspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradIinspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradIinspection*/}

                {/* END_USER_CODE-USER_AFTER_txtTradIinspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtChargeLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtChargeLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChargeLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChargeLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtChargeLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouseReceipt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}
                {/* START_USER_CODE-USER_BEFORE_txtQuantity*/}

                {/* END_USER_CODE-USER_BEFORE_txtQuantity*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtQuantity}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtQuantity*/}

                {/* END_USER_CODE-USER_AFTER_txtQuantity*/}
                {/* START_USER_CODE-USER_BEFORE_txtTransfer*/}

                {/* END_USER_CODE-USER_BEFORE_txtTransfer*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTransfer}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTransfer*/}

                {/* END_USER_CODE-USER_AFTER_txtTransfer*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnView*/}

                  {/* END_USER_CODE-USER_BEFORE_btnView*/}

                  <ButtonWidget
                    conf={state.btnView}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnView*/}

                  {/* END_USER_CODE-USER_AFTER_btnView*/}
                  {/* START_USER_CODE-USER_BEFORE_btnReversal*/}

                  {/* END_USER_CODE-USER_BEFORE_btnReversal*/}

                  <ButtonWidget
                    conf={state.btnReversal}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnReversal*/}

                  {/* END_USER_CODE-USER_AFTER_btnReversal*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
                {/* START_USER_CODE-USER_BEFORE_gridManualAcctDist*/}

                {/* END_USER_CODE-USER_BEFORE_gridManualAcctDist*/}

                <GridWidget
                  conf={state.gridManualAcctDist}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridManualAcctDist}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridManualAcctDist*/}

                {/* END_USER_CODE-USER_AFTER_gridManualAcctDist*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_txtManualAcctDistSearch*/}

              {/* END_USER_CODE-USER_AFTER_txtManualAcctDistSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_ManualAcctDistSearch*/}

              {/* END_USER_CODE-USER_AFTER_ManualAcctDistSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualAcctDistSearch
);
