/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  setData,
  getData,
} from "../../shared/WindowImports";

import "./TradeSettlePremDeductView.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {SettlementService} from "../Service/SettlementService";
// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettlePremDeductView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
 
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeSettlePremDeductView",
    windowName: "TradeSettlePremDeductView",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettlePremDeductView",
    // START_USER_CODE-USER_TradeSettlePremDeductView_PROPERTIES
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1140G", 
    },
    // END_USER_CODE-USER_TradeSettlePremDeductView_PROPERTIES
    btnCncl: {
      name: "btnCncl",
      type: "ButtonWidget",
      parent: "grpbxPremDedView",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCncl_PROPERTIES

      // END_USER_CODE-USER_btnCncl_PROPERTIES
    },
    colAmnt: {
      name: "colAmnt",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmnt_PROPERTIES

      // END_USER_CODE-USER_colAmnt_PROPERTIES
    },
    colPremDeduct: {
      name: "colPremDeduct",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Premiums/Deductions",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDeduct_PROPERTIES

      // END_USER_CODE-USER_colPremDeduct_PROPERTIES
    },
    colVndr: {
      name: "colVndr",
      type: "GridColumnWidget",
      parent: "gridPremDed",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVndr_PROPERTIES

      // END_USER_CODE-USER_colVndr_PROPERTIES
    },
    gridPremDed: {
      name: "gridPremDed",
      type: "GridWidget",
      parent: "grpbxPremDedView",
      gridCellsOrder: "txtcolPremDeduct,txtcolVndr,txtcolAmnt",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPremDed_PROPERTIES
      isExpandable: true,
      defaultExpand:true,
      showExpander:true,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
        {data.children ? (
        <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
        {data.children.map((elem, i) => 
          <div className={"row ExpandRow" + " rdt_ExpanderRow"+i} key={"expandRow"+i} onClick={expandRowClicked}>
          <div className="extraRowCell_2 ch-3 TableCell" key="expandCell1">
            {elem.txtcolPremDeduct}
          </div>
          <div className="extraRowCell_4 ch-3 TableCell" key="expandCell3">
            {elem.txtcolVndr}
          </div>
          <div className="extraRowCell_5 ch-3 TableCell" key="expandCell4">
            {elem.txtcolAmnt}
          </div>
          </div>
          )}
          </div>
        ): null}
          </div>
      )
      // END_USER_CODE-USER_gridPremDed_PROPERTIES
    },
    txtcolAmnt: {
      name: "txtcolAmnt",
      type: "TextBoxWidget",
      colName: "colAmnt",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmnt_PROPERTIES

      // END_USER_CODE-USER_txtcolAmnt_PROPERTIES
    },
    txtcolPremDeduct: {
      name: "txtcolPremDeduct",
      type: "TextBoxWidget",
      colName: "colPremDeduct",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremDeduct_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDeduct_PROPERTIES
    },
    txtcolVndr: {
      name: "txtcolVndr",
      type: "TextBoxWidget",
      colName: "colVndr",
      parent: "gridPremDed",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVndr_PROPERTIES

      // END_USER_CODE-USER_txtcolVndr_PROPERTIES
    },
    txtpreDed1: {
      name: "txtpreDed1",
      type: "TextBoxWidget",
      parent: "grpbxPremDedView",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtpreDed1_PROPERTIES

      // END_USER_CODE-USER_txtpreDed1_PROPERTIES
    },
    txtPreDed2: {
      name: "txtPreDed2",
      type: "TextBoxWidget",
      parent: "grpbxPremDedView",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPreDed2_PROPERTIES

      // END_USER_CODE-USER_txtPreDed2_PROPERTIES
    },
    txtPreDed3: {
      name: "txtPreDed3",
      type: "TextBoxWidget",
      parent: "grpbxPremDedView",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPreDed3_PROPERTIES

      // END_USER_CODE-USER_txtPreDed3_PROPERTIES
    },
    txtPremDed: {
      name: "txtPremDed",
      type: "TextBoxWidget",
      parent: "grpbxPremDedView",
      Label: "Premiums/Deductions",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremDed_PROPERTIES

      // END_USER_CODE-USER_txtPremDed_PROPERTIES
    },
    grpbxPremDedView: {
      name: "grpbxPremDedView",
      type: "GroupBoxWidget",
      parent: "TradeSettlePremDeductView",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_grpbxPremDedView_PROPERTIES

      // END_USER_CODE-USER_grpbxPremDedView_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
 
  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    let TradeSettlePremDeductViewFormLoadData = getData(thisObj, "viewPremiumDeductionsOnLoadData")
    setData(thisObj, 'TradeSettlePremDeductViewParentData', TradeSettlePremDeductViewFormLoadData)
    loadData();
  }


  const loadData=()=>{
    let TradeSettlePremDeductViewFormLoadDataObj = getData(thisObj, 'TradeSettlePremDeductViewParentData')
    try {
      if (TradeSettlePremDeductViewFormLoadDataObj != null && TradeSettlePremDeductViewFormLoadDataObj.buy_pt_id != "" && TradeSettlePremDeductViewFormLoadDataObj.insp_num != "" && TradeSettlePremDeductViewFormLoadDataObj != undefined && TradeSettlePremDeductViewFormLoadDataObj.buy_pt_id != undefined && TradeSettlePremDeductViewFormLoadDataObj.insp_num != undefined) {
        SettlementService.RetrieveTradeSettlementPremiumDeductionDetails(TradeSettlePremDeductViewFormLoadDataObj.buy_pt_id, TradeSettlePremDeductViewFormLoadDataObj.insp_num)
          .then(response => {
            let PremDeductArr = [];
            if (response != undefined && response.length > 0) {
              let data = response;
              let obj = {};
              let rowId = 0;
              for (let i = 0; i < data.length; i++) {
                rowId = rowId + 1;
                obj.rowId = rowId
                obj.txtcolPremDeduct = data[i].pd_code
                obj.txtcolVndr = data[i].pd_vendor
                obj.txtcolAmnt = data[i].pd_amount

                PremDeductArr.push(obj)
                obj = {}
              }
              let arrayWithParentRowIds = addParentRowIdForAllRows(PremDeductArr); // This function adds Parent Ids for grouped rows
              let arrayWithAdditionalParentRowForGroupedRows = addParentRowForAllGroupedRows(arrayWithParentRowIds); // This function adds additional Parent rows for showing Total amount for all grouped rows
              let arrayWithGroupedRowsBasedOnParentIds = groupRowsBaedOnParentIds(arrayWithAdditionalParentRowForGroupedRows); // This function groups rows based on Parent Ids.
              setValue(thisObj, "gridPremDed", arrayWithGroupedRowsBasedOnParentIds);
              // setValue(thisObj, "gridPremDed", PremDeductArr);
            }
          })
      }
      // else {
      //   showMessage(thisObj, "Please enter a valid Buying point ID/Trade Inspection number")
      // }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during gridPremDed change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.loadData = loadData;

  // function for adding Parent Ids to grouped rows
  function addParentRowIdForAllRows(data) {
    let map = new Map();
    let newJs = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      if (map.has(data[i].txtcolPremDeduct)) {
        obj = { ...data[i] };
        obj.parentRowId = map.get(data[i].txtcolPremDeduct);
      }
      else {
        map.set(data[i].txtcolPremDeduct, data[i].rowId);
        obj = { ...data[i] };
        obj.parentRowId = null;
      }
      newJs.push(obj);
    }
    return newJs;
  }

  function addParentRowForAllGroupedRows(rawArray) {
    let newArrayWithParentRows = [];
    let newParentobj = {};
    let oldParentobj = {};
    let otherRowobj = {};
    let newRowId = 0;
    let newParentRowId = 0;
    let subTotal = 0;
    for (let i = 0; i < rawArray.length; i++) {

      if (rawArray[i].parentRowId == null) {
        // Add New Parent with Total
        newRowId = newRowId + 1;
        newParentRowId = newRowId
        newParentobj.parentRowId = null // Set null value for additional Parent row showing total values
        newParentobj.newRowId = newRowId
        newParentobj.txtcolPremDeduct = "Total " + rawArray[i].txtcolPremDeduct
        newParentobj.txtcolVndr = ""
        newParentobj.txtcolAmnt = ""

        //Add old Parent row as child row
        newRowId = newRowId + 1;
        oldParentobj.txtcolPremDeduct = rawArray[i].txtcolPremDeduct
        // oldParentobj.txtcolDescription = rawArray[i].txtcolDescription
        oldParentobj.txtcolVndr = rawArray[i].txtcolVndr
        oldParentobj.txtcolAmnt = rawArray[i].txtcolAmnt
        subTotal = subTotal + parseFloat(rawArray[i].txtcolAmnt) // Doing sum of amount value for grouped rows
        oldParentobj.newRowId = newRowId
        oldParentobj.parentRowId = newParentRowId;
        newArrayWithParentRows.push(oldParentobj);
        oldParentobj = {};

        if (rawArray[i + 1] != undefined && rawArray[i + 1] != null && rawArray[i + 1].parentRowId != null && rawArray[i].rowId == rawArray[i + 1].parentRowId) {
          newRowId = newRowId + 1;
          otherRowobj.newRowId = newRowId
          otherRowobj.parentRowId = newParentRowId;
          otherRowobj.txtcolPremDeduct = "" // Setting blank value as old application shows value only for parent row
          // otherRowobj.txtcolDescription = "" // Setting blank value as old application shows value only for parent row
          otherRowobj.txtcolVndr = rawArray[i + 1].txtcolVndr
          otherRowobj.txtcolAmnt = rawArray[i + 1].txtcolAmnt
          subTotal = subTotal + parseFloat(rawArray[i + 1].txtcolAmnt) // Doing sum of amount value for grouped rows
          newArrayWithParentRows.push(otherRowobj);
          otherRowobj = {};
        }
        newParentobj.txtcolAmnt = subTotal.toFixed(2) //setting sub total at then end for parent row
        newArrayWithParentRows.push(newParentobj);
        newParentobj = {};
        subTotal = 0;
      }
    }
    return newArrayWithParentRows;
  }

  //function for grouping rows based on Parent Ids.
  function groupRowsBaedOnParentIds(data, multi = null) {
    return data.reduce((r, e) => {
      if (e.parentRowId == multi) {
        const obj = { ...e };
        const children = groupRowsBaedOnParentIds(data, e.newRowId);
        if (children.length) {
          obj.children = children;
        }
        r.push(obj);
      }
      return r;
    }, [])
  }

  //function for expand row click event
  function expandRowClicked(event) {
    var elems = document.querySelectorAll(".activeExpandRow");
    elems.forEach.call(elems, function (el) {
      el.classList.remove("activeExpandRow");
    });
    var elem = document.querySelectorAll(".icTWDw");
    thisObj.state.gridPremDed.selected = [];
    elem.forEach.call(elem, function (el) {
      el.classList.remove("icTWDw");
      el.classList.add("eaQpfw");
    });
    if (event.target.classList.contains("TableCell")) {
      event.target.parentElement.classList.value += " activeExpandRow";
    }
    else {
      event.target.classList.value += " activeExpandRow";
    }
  }

  // START_USER_CODE-USER_METHODS
  const onbtnCnclClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("Settlements_TradeSettlePremDeductViewPopUp").childNodes[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCncl event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCnclClick = onbtnCnclClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
          
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeSettlePremDeductView*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettlePremDeductView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremDedView*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremDedView*/}

              <GroupBoxWidget conf={state.grpbxPremDedView} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtPremDed*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremDed*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremDed}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremDed*/}

                {/* END_USER_CODE-USER_AFTER_txtPremDed*/}
                {/* START_USER_CODE-USER_BEFORE_gridPremDed*/}

                {/* END_USER_CODE-USER_BEFORE_gridPremDed*/}

                <GridWidget
                  conf={state.gridPremDed}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPremDed}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPremDed*/}

                {/* END_USER_CODE-USER_AFTER_gridPremDed*/}
                {/* START_USER_CODE-USER_BEFORE_txtpreDed1*/}

                {/* END_USER_CODE-USER_BEFORE_txtpreDed1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtpreDed1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtpreDed1*/}

                {/* END_USER_CODE-USER_AFTER_txtpreDed1*/}
                {/* START_USER_CODE-USER_BEFORE_txtPreDed2*/}

                {/* END_USER_CODE-USER_BEFORE_txtPreDed2*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPreDed2}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPreDed2*/}

                {/* END_USER_CODE-USER_AFTER_txtPreDed2*/}
                {/* START_USER_CODE-USER_BEFORE_txtPreDed3*/}

                {/* END_USER_CODE-USER_BEFORE_txtPreDed3*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPreDed3}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPreDed3*/}

                {/* END_USER_CODE-USER_AFTER_txtPreDed3*/}
                {/* START_USER_CODE-USER_BEFORE_btnCncl*/}

                {/* END_USER_CODE-USER_BEFORE_btnCncl*/}

                <ButtonWidget
                  conf={state.btnCncl}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCncl*/}

                {/* END_USER_CODE-USER_AFTER_btnCncl*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremDedView*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremDedView*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettlePremDeductView*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettlePremDeductView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettlePremDeductView);
