/* eslint-disable*/
import React from "react";
import StockTransfer_PremiumDeductionsFrtAccrl from "./PremiumDeductionsFrtAccrl";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionsFrtAccrl Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_PremiumDeductionsFrtAccrl />);
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionsFrtAccrl Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionsFrtAccrl")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionsFrtAccrl", () => {
    // START_USER_CODE-USER_PremiumDeductionsFrtAccrl_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionsFrtAccrl_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_PremiumDeductionsFrtAccrl />);
    });
  });
  afterEach(cleanup);
  test("btmViewTransferDetail(Button Widget) Test Cases", async () => {
    const btmViewTransferDetail = screen.getByTestId("btmViewTransferDetail");
    expect(btmViewTransferDetail).toBeInTheDocument;
    expect(btmViewTransferDetail.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_btmViewTransferDetail")
    );
  });
  test("Custom Test Cases for btmViewTransferDetail", () => {
    // START_USER_CODE-USER_btmViewTransferDetail_TEST
    // END_USER_CODE-USER_btmViewTransferDetail_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnComplete(Button Widget) Test Cases", async () => {
    const btnComplete = screen.getByTestId("btnComplete");
    expect(btnComplete).toBeInTheDocument;
    expect(btnComplete.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_btnComplete")
    );
  });
  test("Custom Test Cases for btnComplete", () => {
    // START_USER_CODE-USER_btnComplete_TEST
    // END_USER_CODE-USER_btnComplete_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnReverseFrtAccrl(Button Widget) Test Cases", async () => {
    const btnReverseFrtAccrl = screen.getByTestId("btnReverseFrtAccrl");
    expect(btnReverseFrtAccrl).toBeInTheDocument;
    expect(btnReverseFrtAccrl.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_btnReverseFrtAccrl")
    );
  });
  test("Custom Test Cases for btnReverseFrtAccrl", () => {
    // START_USER_CODE-USER_btnReverseFrtAccrl_TEST
    // END_USER_CODE-USER_btnReverseFrtAccrl_TEST
  });
  test("gridPremsDeducts(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeductsbtn =
      gridPremsDeducts.nextElementSibling.firstElementChild;
    gridPremsDeducts =
      gridPremsDeducts.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts.tagName).toBe("DIV");
    expect(gridPremsDeducts.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremsDeducts", () => {
    // START_USER_CODE-USER_gridPremsDeducts_TEST
    // END_USER_CODE-USER_gridPremsDeducts_TEST
  });
  test("grpbxDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails1 = screen.getByTestId("grpbxDetails1");
    expect(grpbxDetails1.tagName).toBe("BUTTON");
    expect(grpbxDetails1.type).toBe("button");
    expect(grpbxDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails1", () => {
    // START_USER_CODE-USER_grpbxDetails1_TEST
    // END_USER_CODE-USER_grpbxDetails1_TEST
  });
  test("grpbxDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails2 = screen.getByTestId("grpbxDetails2");
    expect(grpbxDetails2.tagName).toBe("BUTTON");
    expect(grpbxDetails2.type).toBe("button");
    expect(grpbxDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails2", () => {
    // START_USER_CODE-USER_grpbxDetails2_TEST
    // END_USER_CODE-USER_grpbxDetails2_TEST
  });
  test("grpbxPremiumDeductionsFrtAccrl(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionsFrtAccrl = screen.getByTestId(
      "grpbxPremiumDeductionsFrtAccrl"
    );
    expect(grpbxPremiumDeductionsFrtAccrl.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionsFrtAccrl.type).toBe("button");
    expect(grpbxPremiumDeductionsFrtAccrl.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionsFrtAccrl", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionsFrtAccrl_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionsFrtAccrl_TEST
  });
  test("grpbxPremsDeducts(GroupBox Widget) Test Cases", async () => {
    const grpbxPremsDeducts = screen.getByTestId("grpbxPremsDeducts");
    expect(grpbxPremsDeducts.tagName).toBe("BUTTON");
    expect(grpbxPremsDeducts.type).toBe("button");
    expect(grpbxPremsDeducts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremsDeducts", () => {
    // START_USER_CODE-USER_grpbxPremsDeducts_TEST
    // END_USER_CODE-USER_grpbxPremsDeducts_TEST
  });
  test("lblLbsPer(Label Widget) Test Cases", async () => {
    const lblLbsPer = screen.getByTestId("lblLbsPer");
    expect(lblLbsPer.tagName).toBe("LABEL");
    expect(lblLbsPer.classList).toContain("form-label");
    expect(lblLbsPer.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_lblLbsPer")
    );
  });
  test("Custom Test Cases for lblLbsPer", () => {
    // START_USER_CODE-USER_lblLbsPer_TEST
    // END_USER_CODE-USER_lblLbsPer_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblPremDeduct(Label Widget) Test Cases", async () => {
    const lblPremDeduct = screen.getByTestId("lblPremDeduct");
    expect(lblPremDeduct.tagName).toBe("LABEL");
    expect(lblPremDeduct.classList).toContain("form-label");
    expect(lblPremDeduct.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_lblPremDeduct")
    );
  });
  test("Custom Test Cases for lblPremDeduct", () => {
    // START_USER_CODE-USER_lblPremDeduct_TEST
    // END_USER_CODE-USER_lblPremDeduct_TEST
  });
  test("lblTons(Label Widget) Test Cases", async () => {
    const lblTons = screen.getByTestId("lblTons");
    expect(lblTons.tagName).toBe("LABEL");
    expect(lblTons.classList).toContain("form-label");
    expect(lblTons.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_lblTons")
    );
  });
  test("Custom Test Cases for lblTons", () => {
    // START_USER_CODE-USER_lblTons_TEST
    // END_USER_CODE-USER_lblTons_TEST
  });
  test("txtAccrualAmount(Textbox Widget) Test Cases", async () => {
    const txtAccrualAmount = screen.getByTestId("txtAccrualAmount");
    expect(txtAccrualAmount.tagName).toBe("INPUT");
    expect(txtAccrualAmount.type).toBe("text");
    expect(txtAccrualAmount.classList).toContain("textboxWidgetClass");
    expect(txtAccrualAmount.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtAccrualAmount")
    );
    await act(async () => {
      userEvent.type(txtAccrualAmount, "123");
    });
    expect(txtAccrualAmount.getAttribute("value")).toBe("");
    expect(txtAccrualAmount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccrualAmount", () => {
    // START_USER_CODE-USER_txtAccrualAmount_TEST
    // END_USER_CODE-USER_txtAccrualAmount_TEST
  });
  test("txtCarrier(Textbox Widget) Test Cases", async () => {
    const txtCarrier = screen.getByTestId("txtCarrier");
    expect(txtCarrier.tagName).toBe("INPUT");
    expect(txtCarrier.type).toBe("text");
    expect(txtCarrier.classList).toContain("textboxWidgetClass");
    expect(txtCarrier.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtCarrier")
    );
    await act(async () => {
      userEvent.type(txtCarrier, "123");
    });
    expect(txtCarrier.getAttribute("value")).toBe("");
    expect(txtCarrier.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCarrier", () => {
    // START_USER_CODE-USER_txtCarrier_TEST
    // END_USER_CODE-USER_txtCarrier_TEST
  });
  test("gridPremsDeducts_txtcolCarrier(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolCarrier = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeducts_txtcolCarrierbtn =
      gridPremsDeducts_txtcolCarrier.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolCarrier =
      gridPremsDeducts_txtcolCarrier.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts_txtcolCarrier.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolCarrier.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCarrier", () => {
    // START_USER_CODE-USER_txtcolCarrier_TEST
    // END_USER_CODE-USER_txtcolCarrier_TEST
  });
  test("gridPremsDeducts_txtcolFreightAmount(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolFreightAmount = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolFreightAmountbtn =
      gridPremsDeducts_txtcolFreightAmount.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolFreightAmount =
      gridPremsDeducts_txtcolFreightAmount.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolFreightAmount.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolFreightAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFreightAmount", () => {
    // START_USER_CODE-USER_txtcolFreightAmount_TEST
    // END_USER_CODE-USER_txtcolFreightAmount_TEST
  });
  test("gridPremsDeducts_txtcolFreightRate(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolFreightRate = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolFreightRatebtn =
      gridPremsDeducts_txtcolFreightRate.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolFreightRate =
      gridPremsDeducts_txtcolFreightRate.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolFreightRate.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolFreightRate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFreightRate", () => {
    // START_USER_CODE-USER_txtcolFreightRate_TEST
    // END_USER_CODE-USER_txtcolFreightRate_TEST
  });
  test("gridPremsDeducts_txtcolFreightWeight(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolFreightWeight = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolFreightWeightbtn =
      gridPremsDeducts_txtcolFreightWeight.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolFreightWeight =
      gridPremsDeducts_txtcolFreightWeight.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolFreightWeight.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolFreightWeight.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFreightWeight", () => {
    // START_USER_CODE-USER_txtcolFreightWeight_TEST
    // END_USER_CODE-USER_txtcolFreightWeight_TEST
  });
  test("gridPremsDeducts_txtcolFuelPercentage(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolFuelPercentage = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolFuelPercentagebtn =
      gridPremsDeducts_txtcolFuelPercentage.nextElementSibling
        .firstElementChild;
    gridPremsDeducts_txtcolFuelPercentage =
      gridPremsDeducts_txtcolFuelPercentage.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolFuelPercentage.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolFuelPercentage.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFuelPercentage", () => {
    // START_USER_CODE-USER_txtcolFuelPercentage_TEST
    // END_USER_CODE-USER_txtcolFuelPercentage_TEST
  });
  test("gridPremsDeducts_txtcolFuelRatePerMile(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolFuelRatePerMile = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolFuelRatePerMilebtn =
      gridPremsDeducts_txtcolFuelRatePerMile.nextElementSibling
        .firstElementChild;
    gridPremsDeducts_txtcolFuelRatePerMile =
      gridPremsDeducts_txtcolFuelRatePerMile.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolFuelRatePerMile.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolFuelRatePerMile.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFuelRatePerMile", () => {
    // START_USER_CODE-USER_txtcolFuelRatePerMile_TEST
    // END_USER_CODE-USER_txtcolFuelRatePerMile_TEST
  });
  test("gridPremsDeducts_txtcolMiles(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolMiles = screen.getByTestId("gridPremsDeducts");
    let gridPremsDeducts_txtcolMilesbtn =
      gridPremsDeducts_txtcolMiles.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolMiles =
      gridPremsDeducts_txtcolMiles.parentElement.parentElement.parentElement;
    expect(gridPremsDeducts_txtcolMiles.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolMiles.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMiles", () => {
    // START_USER_CODE-USER_txtcolMiles_TEST
    // END_USER_CODE-USER_txtcolMiles_TEST
  });
  test("gridPremsDeducts_txtcolRatingOption(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolRatingOption = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolRatingOptionbtn =
      gridPremsDeducts_txtcolRatingOption.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolRatingOption =
      gridPremsDeducts_txtcolRatingOption.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolRatingOption.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolRatingOption.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRatingOption", () => {
    // START_USER_CODE-USER_txtcolRatingOption_TEST
    // END_USER_CODE-USER_txtcolRatingOption_TEST
  });
  test("gridPremsDeducts_txtcolShippedDate(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolShippedDate = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolShippedDatebtn =
      gridPremsDeducts_txtcolShippedDate.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolShippedDate =
      gridPremsDeducts_txtcolShippedDate.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolShippedDate.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolShippedDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShippedDate", () => {
    // START_USER_CODE-USER_txtcolShippedDate_TEST
    // END_USER_CODE-USER_txtcolShippedDate_TEST
  });
  test("gridPremsDeducts_txtcolTrailerLease(Grid Widget) Test Cases", async () => {
    let gridPremsDeducts_txtcolTrailerLease = screen.getByTestId(
      "gridPremsDeducts"
    );
    let gridPremsDeducts_txtcolTrailerLeasebtn =
      gridPremsDeducts_txtcolTrailerLease.nextElementSibling.firstElementChild;
    gridPremsDeducts_txtcolTrailerLease =
      gridPremsDeducts_txtcolTrailerLease.parentElement.parentElement
        .parentElement;
    expect(gridPremsDeducts_txtcolTrailerLease.tagName).toBe("DIV");
    expect(gridPremsDeducts_txtcolTrailerLease.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("StockTransfer:PremiumDeductionsFrtAccrl_gridPremsDeducts")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTrailerLease", () => {
    // START_USER_CODE-USER_txtcolTrailerLease_TEST
    // END_USER_CODE-USER_txtcolTrailerLease_TEST
  });
  test("txtFreightAmount(Textbox Widget) Test Cases", async () => {
    const txtFreightAmount = screen.getByTestId("txtFreightAmount");
    expect(txtFreightAmount.tagName).toBe("INPUT");
    expect(txtFreightAmount.type).toBe("text");
    expect(txtFreightAmount.classList).toContain("textboxWidgetClass");
    expect(txtFreightAmount.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtFreightAmount")
    );
    await act(async () => {
      userEvent.type(txtFreightAmount, "1");
    });
  });
  test("Custom Test Cases for txtFreightAmount", () => {
    // START_USER_CODE-USER_txtFreightAmount_TEST
    // END_USER_CODE-USER_txtFreightAmount_TEST
  });
  test("txtFreightRate(Textbox Widget) Test Cases", async () => {
    const txtFreightRate = screen.getByTestId("txtFreightRate");
    expect(txtFreightRate.tagName).toBe("INPUT");
    expect(txtFreightRate.type).toBe("text");
    expect(txtFreightRate.classList).toContain("textboxWidgetClass");
    expect(txtFreightRate.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtFreightRate")
    );
    await act(async () => {
      userEvent.type(txtFreightRate, "1");
    });
  });
  test("Custom Test Cases for txtFreightRate", () => {
    // START_USER_CODE-USER_txtFreightRate_TEST
    // END_USER_CODE-USER_txtFreightRate_TEST
  });
  test("txtFreightWeight(Textbox Widget) Test Cases", async () => {
    const txtFreightWeight = screen.getByTestId("txtFreightWeight");
    expect(txtFreightWeight.tagName).toBe("INPUT");
    expect(txtFreightWeight.type).toBe("text");
    expect(txtFreightWeight.classList).toContain("textboxWidgetClass");
    expect(txtFreightWeight.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtFreightWeight")
    );
    await act(async () => {
      userEvent.type(txtFreightWeight, "1");
    });
  });
  test("Custom Test Cases for txtFreightWeight", () => {
    // START_USER_CODE-USER_txtFreightWeight_TEST
    // END_USER_CODE-USER_txtFreightWeight_TEST
  });
  test("txtFuelPct(Textbox Widget) Test Cases", async () => {
    const txtFuelPct = screen.getByTestId("txtFuelPct");
    expect(txtFuelPct.tagName).toBe("INPUT");
    expect(txtFuelPct.type).toBe("text");
    expect(txtFuelPct.classList).toContain("textboxWidgetClass");
    expect(txtFuelPct.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtFuelPct")
    );
    await act(async () => {
      userEvent.type(txtFuelPct, "123");
    });
    expect(txtFuelPct.getAttribute("value")).toBe("");
    expect(txtFuelPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFuelPct", () => {
    // START_USER_CODE-USER_txtFuelPct_TEST
    // END_USER_CODE-USER_txtFuelPct_TEST
  });
  test("txtMiles(Textbox Widget) Test Cases", async () => {
    const txtMiles = screen.getByTestId("txtMiles");
    expect(txtMiles.tagName).toBe("INPUT");
    expect(txtMiles.type).toBe("text");
    expect(txtMiles.classList).toContain("textboxWidgetClass");
    expect(txtMiles.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtMiles")
    );
    await act(async () => {
      userEvent.type(txtMiles, "123");
    });
    expect(txtMiles.getAttribute("value")).toBe("");
    expect(txtMiles.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMiles", () => {
    // START_USER_CODE-USER_txtMiles_TEST
    // END_USER_CODE-USER_txtMiles_TEST
  });
  test("txtRatingOption(Textbox Widget) Test Cases", async () => {
    const txtRatingOption = screen.getByTestId("txtRatingOption");
    expect(txtRatingOption.tagName).toBe("INPUT");
    expect(txtRatingOption.type).toBe("text");
    expect(txtRatingOption.classList).toContain("textboxWidgetClass");
    expect(txtRatingOption.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtRatingOption")
    );
    await act(async () => {
      userEvent.type(txtRatingOption, "123");
    });
    expect(txtRatingOption.getAttribute("value")).toBe("");
    expect(txtRatingOption.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRatingOption", () => {
    // START_USER_CODE-USER_txtRatingOption_TEST
    // END_USER_CODE-USER_txtRatingOption_TEST
  });
  test("txtShippedDate(Textbox Widget) Test Cases", async () => {
    const txtShippedDate = screen.getByTestId("txtShippedDate");
    expect(txtShippedDate.tagName).toBe("INPUT");
    expect(txtShippedDate.type).toBe("text");
    expect(txtShippedDate.classList).toContain("textboxWidgetClass");
    expect(txtShippedDate.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtShippedDate")
    );
    await act(async () => {
      userEvent.type(txtShippedDate, "123");
    });
    expect(txtShippedDate.getAttribute("value")).toBe("");
    expect(txtShippedDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShippedDate", () => {
    // START_USER_CODE-USER_txtShippedDate_TEST
    // END_USER_CODE-USER_txtShippedDate_TEST
  });
  test("txtTons(Textbox Widget) Test Cases", async () => {
    const txtTons = screen.getByTestId("txtTons");
    expect(txtTons.tagName).toBe("INPUT");
    expect(txtTons.type).toBe("text");
    expect(txtTons.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTons, "1");
    });
  });
  test("Custom Test Cases for txtTons", () => {
    // START_USER_CODE-USER_txtTons_TEST
    // END_USER_CODE-USER_txtTons_TEST
  });
  test("txtTrailerLease(Textbox Widget) Test Cases", async () => {
    const txtTrailerLease = screen.getByTestId("txtTrailerLease");
    expect(txtTrailerLease.tagName).toBe("INPUT");
    expect(txtTrailerLease.type).toBe("text");
    expect(txtTrailerLease.classList).toContain("textboxWidgetClass");
    expect(txtTrailerLease.previousElementSibling.textContent).toEqual(
      t("StockTransfer:PremiumDeductionsFrtAccrl_txtTrailerLease")
    );
    await act(async () => {
      userEvent.type(txtTrailerLease, "123");
    });
    expect(txtTrailerLease.getAttribute("value")).toBe("");
    expect(txtTrailerLease.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTrailerLease", () => {
    // START_USER_CODE-USER_txtTrailerLease_TEST
    // END_USER_CODE-USER_txtTrailerLease_TEST
  });
});
