/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvSettleApply from "./DelvSettleApply";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvSettleApply Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleApply />);
    });
  });
  afterEach(cleanup);
  test("is DelvSettleApply Loads Successfully", () => {
    expect(screen.getByText("DelvSettleApply")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvSettleApply", () => {
    // START_USER_CODE-USER_DelvSettleApply_Custom_Test_Case
    // END_USER_CODE-USER_DelvSettleApply_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleApply />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridAgreementApplications(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplicationsbtn =
      gridAgreementApplications.nextElementSibling.firstElementChild;
    gridAgreementApplications =
      gridAgreementApplications.parentElement.parentElement.parentElement;
    expect(gridAgreementApplications.tagName).toBe("DIV");
    expect(gridAgreementApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAgreementApplications", () => {
    // START_USER_CODE-USER_gridAgreementApplications_TEST
    // END_USER_CODE-USER_gridAgreementApplications_TEST
  });
  test("grpbxDelvSettleApply(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSettleApply = screen.getByTestId("grpbxDelvSettleApply");
    expect(grpbxDelvSettleApply.tagName).toBe("BUTTON");
    expect(grpbxDelvSettleApply.type).toBe("button");
    expect(grpbxDelvSettleApply.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSettleApply", () => {
    // START_USER_CODE-USER_grpbxDelvSettleApply_TEST
    // END_USER_CODE-USER_grpbxDelvSettleApply_TEST
  });
  test("lblAgreementApplications(Label Widget) Test Cases", async () => {
    const lblAgreementApplications = screen.getByTestId(
      "lblAgreementApplications"
    );
    expect(lblAgreementApplications.tagName).toBe("LABEL");
    expect(lblAgreementApplications.classList).toContain("form-label");
    expect(lblAgreementApplications.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_lblAgreementApplications")
    );
  });
  test("Custom Test Cases for lblAgreementApplications", () => {
    // START_USER_CODE-USER_lblAgreementApplications_TEST
    // END_USER_CODE-USER_lblAgreementApplications_TEST
  });
  test("txt100PctAdjValue(Textbox Widget) Test Cases", async () => {
    const txt100PctAdjValue = screen.getByTestId("txt100PctAdjValue");
    expect(txt100PctAdjValue.tagName).toBe("INPUT");
    expect(txt100PctAdjValue.type).toBe("text");
    expect(txt100PctAdjValue.classList).toContain("textboxWidgetClass");
    expect(txt100PctAdjValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txt100PctAdjValue")
    );
    await act(async () => {
      userEvent.type(txt100PctAdjValue, "1");
    });
  });
  test("Custom Test Cases for txt100PctAdjValue", () => {
    // START_USER_CODE-USER_txt100PctAdjValue_TEST
    // END_USER_CODE-USER_txt100PctAdjValue_TEST
  });
  test("txtAgreementLocations(Textbox Widget) Test Cases", async () => {
    const txtAgreementLocations = screen.getByTestId("txtAgreementLocations");
    expect(txtAgreementLocations.tagName).toBe("INPUT");
    expect(txtAgreementLocations.type).toBe("text");
    expect(txtAgreementLocations.classList).toContain("textboxWidgetClass");
    expect(txtAgreementLocations.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtAgreementLocations")
    );
    await act(async () => {
      userEvent.type(txtAgreementLocations, "123");
    });
    expect(txtAgreementLocations.getAttribute("value")).toBe("");
    expect(txtAgreementLocations.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAgreementLocations", () => {
    // START_USER_CODE-USER_txtAgreementLocations_TEST
    // END_USER_CODE-USER_txtAgreementLocations_TEST
  });
  test("gridAgreementApplications_txtcol100PctDollarToApply(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcol100PctDollarToApply = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcol100PctDollarToApplybtn =
      gridAgreementApplications_txtcol100PctDollarToApply.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcol100PctDollarToApply =
      gridAgreementApplications_txtcol100PctDollarToApply.parentElement
        .parentElement.parentElement;
    expect(gridAgreementApplications_txtcol100PctDollarToApply.tagName).toBe(
      "DIV"
    );
    expect(
      gridAgreementApplications_txtcol100PctDollarToApply.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100PctDollarToApply", () => {
    // START_USER_CODE-USER_txtcol100PctDollarToApply_TEST
    // END_USER_CODE-USER_txtcol100PctDollarToApply_TEST
  });
  test("gridAgreementApplications_txtcolAgreedPrice(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreedPrice = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreedPricebtn =
      gridAgreementApplications_txtcolAgreedPrice.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreedPrice =
      gridAgreementApplications_txtcolAgreedPrice.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreedPrice.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreedPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreedPrice", () => {
    // START_USER_CODE-USER_txtcolAgreedPrice_TEST
    // END_USER_CODE-USER_txtcolAgreedPrice_TEST
  });
  test("gridAgreementApplications_txtcolAgreeDt(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreeDt = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreeDtbtn =
      gridAgreementApplications_txtcolAgreeDt.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreeDt =
      gridAgreementApplications_txtcolAgreeDt.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreeDt.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreeDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeDt", () => {
    // START_USER_CODE-USER_txtcolAgreeDt_TEST
    // END_USER_CODE-USER_txtcolAgreeDt_TEST
  });
  test("gridAgreementApplications_txtcolAgreeNum(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreeNum = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreeNumbtn =
      gridAgreementApplications_txtcolAgreeNum.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreeNum =
      gridAgreementApplications_txtcolAgreeNum.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreeNum.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreeNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeNum", () => {
    // START_USER_CODE-USER_txtcolAgreeNum_TEST
    // END_USER_CODE-USER_txtcolAgreeNum_TEST
  });
  test("gridAgreementApplications_txtcolAppliedDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAppliedDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAppliedDollarbtn =
      gridAgreementApplications_txtcolAppliedDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAppliedDollar =
      gridAgreementApplications_txtcolAppliedDollar.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAppliedDollar.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAppliedDollar.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedDollar", () => {
    // START_USER_CODE-USER_txtcolAppliedDollar_TEST
    // END_USER_CODE-USER_txtcolAppliedDollar_TEST
  });
  test("gridAgreementApplications_txtcolApplyOrder(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolApplyOrder = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolApplyOrderbtn =
      gridAgreementApplications_txtcolApplyOrder.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolApplyOrder =
      gridAgreementApplications_txtcolApplyOrder.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolApplyOrder.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolApplyOrder.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolApplyOrder", () => {
    // START_USER_CODE-USER_txtcolApplyOrder_TEST
    // END_USER_CODE-USER_txtcolApplyOrder_TEST
  });
  test("gridAgreementApplications_txtcolFirmBasisOpt(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolFirmBasisOpt = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolFirmBasisOptbtn =
      gridAgreementApplications_txtcolFirmBasisOpt.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolFirmBasisOpt =
      gridAgreementApplications_txtcolFirmBasisOpt.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolFirmBasisOpt.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolFirmBasisOpt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFirmBasisOpt", () => {
    // START_USER_CODE-USER_txtcolFirmBasisOpt_TEST
    // END_USER_CODE-USER_txtcolFirmBasisOpt_TEST
  });
  test("gridAgreementApplications_txtcolHidApply(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolHidApply = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolHidApplybtn =
      gridAgreementApplications_txtcolHidApply.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolHidApply =
      gridAgreementApplications_txtcolHidApply.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolHidApply.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolHidApply.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHidApply", () => {
    // START_USER_CODE-USER_txtcolHidApply_TEST
    // END_USER_CODE-USER_txtcolHidApply_TEST
  });
  test("gridAgreementApplications_txtcolMethod(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolMethod = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolMethodbtn =
      gridAgreementApplications_txtcolMethod.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolMethod =
      gridAgreementApplications_txtcolMethod.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolMethod.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolMethod.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMethod", () => {
    // START_USER_CODE-USER_txtcolMethod_TEST
    // END_USER_CODE-USER_txtcolMethod_TEST
  });
  test("gridAgreementApplications_txtcolOpenDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolOpenDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolOpenDollarbtn =
      gridAgreementApplications_txtcolOpenDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolOpenDollar =
      gridAgreementApplications_txtcolOpenDollar.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolOpenDollar.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolOpenDollar.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenDollar", () => {
    // START_USER_CODE-USER_txtcolOpenDollar_TEST
    // END_USER_CODE-USER_txtcolOpenDollar_TEST
  });
  test("gridAgreementApplications_txtcolPaidDollar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPaidDollar = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPaidDollarbtn =
      gridAgreementApplications_txtcolPaidDollar.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPaidDollar =
      gridAgreementApplications_txtcolPaidDollar.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPaidDollar.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPaidDollar.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPaidDollar", () => {
    // START_USER_CODE-USER_txtcolPaidDollar_TEST
    // END_USER_CODE-USER_txtcolPaidDollar_TEST
  });
  test("gridAgreementApplications_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPeanutVariety = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPeanutVarietybtn =
      gridAgreementApplications_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPeanutVariety =
      gridAgreementApplications_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridAgreementApplications_txtcolReceiptValue(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolReceiptValue = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolReceiptValuebtn =
      gridAgreementApplications_txtcolReceiptValue.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolReceiptValue =
      gridAgreementApplications_txtcolReceiptValue.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolReceiptValue.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolReceiptValue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptValue", () => {
    // START_USER_CODE-USER_txtcolReceiptValue_TEST
    // END_USER_CODE-USER_txtcolReceiptValue_TEST
  });
  test("gridAgreementApplications_txtcolSeedGen(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolSeedGen = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolSeedGenbtn =
      gridAgreementApplications_txtcolSeedGen.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolSeedGen =
      gridAgreementApplications_txtcolSeedGen.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolSeedGen.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolSeedGen.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGen", () => {
    // START_USER_CODE-USER_txtcolSeedGen_TEST
    // END_USER_CODE-USER_txtcolSeedGen_TEST
  });
  test("gridAgreementApplications_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolSeg = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolSegbtn =
      gridAgreementApplications_txtcolSeg.nextElementSibling.firstElementChild;
    gridAgreementApplications_txtcolSeg =
      gridAgreementApplications_txtcolSeg.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolSeg.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolSeg.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridAgreementApplications_txtcolSegRate(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolSegRate = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolSegRatebtn =
      gridAgreementApplications_txtcolSegRate.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolSegRate =
      gridAgreementApplications_txtcolSegRate.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolSegRate.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolSegRate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleApply_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSegRate", () => {
    // START_USER_CODE-USER_txtcolSegRate_TEST
    // END_USER_CODE-USER_txtcolSegRate_TEST
  });
  test("txtDelvInspNum(Textbox Widget) Test Cases", async () => {
    const txtDelvInspNum = screen.getByTestId("txtDelvInspNum");
    expect(txtDelvInspNum.tagName).toBe("INPUT");
    expect(txtDelvInspNum.type).toBe("text");
    expect(txtDelvInspNum.classList).toContain("textboxWidgetClass");
    expect(txtDelvInspNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtDelvInspNum")
    );
    await act(async () => {
      userEvent.type(txtDelvInspNum, "123");
    });
    expect(txtDelvInspNum.getAttribute("value")).toBe("");
    expect(txtDelvInspNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDelvInspNum", () => {
    // START_USER_CODE-USER_txtDelvInspNum_TEST
    // END_USER_CODE-USER_txtDelvInspNum_TEST
  });
  test("txtFirstVendorNumName(Textbox Widget) Test Cases", async () => {
    const txtFirstVendorNumName = screen.getByTestId("txtFirstVendorNumName");
    expect(txtFirstVendorNumName.tagName).toBe("INPUT");
    expect(txtFirstVendorNumName.type).toBe("text");
    expect(txtFirstVendorNumName.classList).toContain("textboxWidgetClass");
    expect(txtFirstVendorNumName.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtFirstVendorNumName")
    );
    await act(async () => {
      userEvent.type(txtFirstVendorNumName, "123");
    });
    expect(txtFirstVendorNumName.getAttribute("value")).toBe("");
    expect(txtFirstVendorNumName.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFirstVendorNumName", () => {
    // START_USER_CODE-USER_txtFirstVendorNumName_TEST
    // END_USER_CODE-USER_txtFirstVendorNumName_TEST
  });
  test("txtInOut(Textbox Widget) Test Cases", async () => {
    const txtInOut = screen.getByTestId("txtInOut");
    expect(txtInOut.tagName).toBe("INPUT");
    expect(txtInOut.type).toBe("text");
    expect(txtInOut.classList).toContain("textboxWidgetClass");
    expect(txtInOut.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtInOut")
    );
    await act(async () => {
      userEvent.type(txtInOut, "123");
    });
    expect(txtInOut.getAttribute("value")).toBe("");
    expect(txtInOut.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtInOut", () => {
    // START_USER_CODE-USER_txtInOut_TEST
    // END_USER_CODE-USER_txtInOut_TEST
  });
  test("txtLoanRepaymentRate(Textbox Widget) Test Cases", async () => {
    const txtLoanRepaymentRate = screen.getByTestId("txtLoanRepaymentRate");
    expect(txtLoanRepaymentRate.tagName).toBe("INPUT");
    expect(txtLoanRepaymentRate.type).toBe("text");
    expect(txtLoanRepaymentRate.classList).toContain("textboxWidgetClass");
    expect(txtLoanRepaymentRate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtLoanRepaymentRate")
    );
    await act(async () => {
      userEvent.type(txtLoanRepaymentRate, "1");
    });
  });
  test("Custom Test Cases for txtLoanRepaymentRate", () => {
    // START_USER_CODE-USER_txtLoanRepaymentRate_TEST
    // END_USER_CODE-USER_txtLoanRepaymentRate_TEST
  });
  test("txtNetLbs(Textbox Widget) Test Cases", async () => {
    const txtNetLbs = screen.getByTestId("txtNetLbs");
    expect(txtNetLbs.tagName).toBe("INPUT");
    expect(txtNetLbs.type).toBe("text");
    expect(txtNetLbs.classList).toContain("textboxWidgetClass");
    expect(txtNetLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtNetLbs")
    );
    await act(async () => {
      userEvent.type(txtNetLbs, "123");
    });
    expect(txtNetLbs.getAttribute("value")).toBe("");
    expect(txtNetLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetLbs", () => {
    // START_USER_CODE-USER_txtNetLbs_TEST
    // END_USER_CODE-USER_txtNetLbs_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtSeedYN(Textbox Widget) Test Cases", async () => {
    const txtSeedYN = screen.getByTestId("txtSeedYN");
    expect(txtSeedYN.tagName).toBe("INPUT");
    expect(txtSeedYN.type).toBe("text");
    expect(txtSeedYN.classList).toContain("textboxWidgetClass");
    expect(txtSeedYN.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtSeedYN")
    );
    await act(async () => {
      userEvent.type(txtSeedYN, "123");
    });
    expect(txtSeedYN.getAttribute("value")).toBe("");
    expect(txtSeedYN.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeedYN", () => {
    // START_USER_CODE-USER_txtSeedYN_TEST
    // END_USER_CODE-USER_txtSeedYN_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "123");
    });
    expect(txtSeg.getAttribute("value")).toBe("");
    expect(txtSeg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtValue(Textbox Widget) Test Cases", async () => {
    const txtValue = screen.getByTestId("txtValue");
    expect(txtValue.tagName).toBe("INPUT");
    expect(txtValue.type).toBe("text");
    expect(txtValue.classList).toContain("textboxWidgetClass");
    expect(txtValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleApply_txtValue")
    );
    await act(async () => {
      userEvent.type(txtValue, "123");
    });
    expect(txtValue.getAttribute("value")).toBe("");
    expect(txtValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue", () => {
    // START_USER_CODE-USER_txtValue_TEST
    // END_USER_CODE-USER_txtValue_TEST
  });
});
