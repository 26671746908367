/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { GetReportLogoImage } from "./../../Common/Constants";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  GridWidget,
  WhiteSpaceWidget,
  setValue,
  getValue,
  hideColumn,
  setData,
  getData,
} from "../../shared/WindowImports";

import "./ApplicationToContract.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import CommonContext from '../../Store/CommonContext';
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ApplicationToContract(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  // START_USER_CODE-USER_PROPERTIES
  // const json = [
  //   {
  //     txtBuyingPoint: "123",
  //     txtInspNum: "1456",
  //     txtInspDate: "10/10/2022",
  //     txtSttlmntNum: "234",
  //     txtDateSettle: "10/10/2022",
  //     txtWRNum: "012",
  //     txtWRDate: "20/10/2022",
  //     txtAppliedPounds: "02",
  //     txtTentativePounds: "098",
  //   },
  // ];
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Application to Contract",
    windowName: "ApplicationToContract",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ApplicationToContract",
    // START_USER_CODE-USER_ApplicationToContract_PROPERTIES
    headerData: {
      scrName: "Application To Contract",
      scrCode: "PN1010F",
    },
    // END_USER_CODE-USER_ApplicationToContract_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxCnclExprt",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxCnclExprt",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "grpbxCnclExprt",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    txtAppliedPounds: {
      name: "txtAppliedPounds",
      type: "TextBoxWidget",
      colName: "txtColAppliedPounds",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAppliedPounds_PROPERTIES

      // END_USER_CODE-USER_txtAppliedPounds_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      colName: "txtColBuyindPoint",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtColAppliedPounds: {
      name: "txtColAppliedPounds",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Applied Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAppliedPounds_PROPERTIES

      // END_USER_CODE-USER_txtColAppliedPounds_PROPERTIES
    },
    txtColBuyindPoint: {
      name: "txtColBuyindPoint",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColBuyindPoint_PROPERTIES

      // END_USER_CODE-USER_txtColBuyindPoint_PROPERTIES
    },
    txtColInspDate: {
      name: "txtColInspDate",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Insp. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColInspDate_PROPERTIES

      // END_USER_CODE-USER_txtColInspDate_PROPERTIES
    },
    txtColInspNum: {
      name: "txtColInspNum",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Insp. Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColInspNum_PROPERTIES

      // END_USER_CODE-USER_txtColInspNum_PROPERTIES
    },
    txtColSttlmnttNum: {
      name: "txtColSttlmnttNum",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Settlement Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSttlmnttNum_PROPERTIES

      // END_USER_CODE-USER_txtColSttlmnttNum_PROPERTIES
    },
    txtColTentativePounds: {
      name: "txtColTentativePounds",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Tentative Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTentativePounds_PROPERTIES

      // END_USER_CODE-USER_txtColTentativePounds_PROPERTIES
    },
    txtColWRDate: {
      name: "txtColWRDate",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "WR Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColWRDate_PROPERTIES

      // END_USER_CODE-USER_txtColWRDate_PROPERTIES
    },
    txtColWRNum: {
      name: "txtColWRNum",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "WR Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColWRNum_PROPERTIES

      // END_USER_CODE-USER_txtColWRNum_PROPERTIES
    },
    txtDateSettle: {
      name: "txtDateSettle",
      type: "TextBoxWidget",
      colName: "txtSettleDate",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDateSettle_PROPERTIES

      // END_USER_CODE-USER_txtDateSettle_PROPERTIES
    },
    txtInspDate: {
      name: "txtInspDate",
      type: "TextBoxWidget",
      colName: "txtColInspDate",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspDate_PROPERTIES

      // END_USER_CODE-USER_txtInspDate_PROPERTIES
    },
    txtInspNum: {
      name: "txtInspNum",
      type: "TextBoxWidget",
      colName: "txtColInspNum",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspNum_PROPERTIES

      // END_USER_CODE-USER_txtInspNum_PROPERTIES
    },
    txtSettleDate: {
      name: "txtSettleDate",
      type: "GridColumnWidget",
      parent: "gridAppToContrct",
      Label: "Settle Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtSettleDate_PROPERTIES

      // END_USER_CODE-USER_txtSettleDate_PROPERTIES
    },
    txtSttlmntNum: {
      name: "txtSttlmntNum",
      type: "TextBoxWidget",
      colName: "txtColSttlmnttNum",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSttlmntNum_PROPERTIES

      // END_USER_CODE-USER_txtSttlmntNum_PROPERTIES
    },
    txtTentativePounds: {
      name: "txtTentativePounds",
      type: "TextBoxWidget",
      colName: "txtColTentativePounds",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTentativePounds_PROPERTIES

      // END_USER_CODE-USER_txtTentativePounds_PROPERTIES
    },
    txtWRDate: {
      name: "txtWRDate",
      type: "TextBoxWidget",
      colName: "txtColWRDate",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWRDate_PROPERTIES

      // END_USER_CODE-USER_txtWRDate_PROPERTIES
    },
    txtWRNum: {
      name: "txtWRNum",
      type: "TextBoxWidget",
      colName: "txtColWRNum",
      parent: "gridAppToContrct",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWRNum_PROPERTIES

      // END_USER_CODE-USER_txtWRNum_PROPERTIES
    },
    whtspc01: {
      name: "whtspc01",
      type: "WhiteSpaceWidget",
      parent: "grpbxCnclExprt",
      ColSpan: "2",
      HasLabel: false,
      // START_USER_CODE-USER_whtspc01_PROPERTIES

      // END_USER_CODE-USER_whtspc01_PROPERTIES
    },
    whtspc02: {
      name: "whtspc02",
      type: "WhiteSpaceWidget",
      parent: "grpbxCnclExprt",
      HasLabel: false,
      // START_USER_CODE-USER_whtspc02_PROPERTIES

      // END_USER_CODE-USER_whtspc02_PROPERTIES
    },
    gridAppToContrct: {
      name: "gridAppToContrct",
      type: "GridWidget",
      parent: "ApplicationToContract",
      gridCellsOrder:
        "txtBuyingPoint,txtInspNum,txtInspDate,txtSttlmntNum,txtDateSettle,txtWRNum,txtWRDate,txtAppliedPounds,txtTentativePounds",
      Pagination: false,
      HasLabel: false,
      Cols: "9",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAppToContrct_PROPERTIES

      // END_USER_CODE-USER_gridAppToContrct_PROPERTIES
    },
    grpbxCnclExprt: {
      name: "grpbxCnclExprt",
      type: "GroupBoxWidget",
      parent: "ApplicationToContract",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_grpbxCnclExprt_PROPERTIES

      // END_USER_CODE-USER_grpbxCnclExprt_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {
        "ContractManagement#AddendumSeedGrower2009": {},
        "ContractManagement#ContractProfile": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // setValue(thisObj, "gridAppToContrct", json);
    formload();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const onbtnCancelClick = () => {
    try {
      //document.getElementsByClassName("close")[0].click();
      document.getElementById("ContractManagement_ApplicationToContractPopUp").childNodes[0].click();
      // let pricingval = getData(thisObj,"pricingLine")
      // if(pricingval==0)
      // {
      //  // goTo(thisObj,"ContractManagement#AddendumSeedGrower2009#DEFAULT#true#Click")
      //  history.push('/');
      // }
      // else{
      //   goTo(thisObj,"ContractManagement#ContractProfile#DEFAULT#true#Click")
      // }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let excelData = getValue(thisObj, "gridAppToContrct")
    var fileName = "Application to Contract - "+ getData(thisObj,"cont_number");
    const arrayData = []
    for (var i = 0; i < excelData.length; i++) {
      if(getData(thisObj,"pricingLine")!==0){
      var obj = {
        "Buying Point":excelData[i].txtBuyingPoint,
        "Insp. Num":excelData[i].txtInspNum,
        "Insp. Date":excelData[i].txtInspDate,
        "Settlement Num":excelData[i].txtSttlmntNum,
        "Settle Date":excelData[i].txtDateSettle,
        "WR Num":excelData[i].txtWRNum,
        "WR Date":excelData[i].txtWRDate,
        "Applied Pounds":ExcelNumericDataConvert(excelData[i].txtAppliedPounds),
        "Tentative Pounds":ExcelNumericDataConvert(excelData[i].txtTentativePounds),
      }
      arrayData.push(obj);
    }
    else{
      var obj = {
        "Buying Point":excelData[i].txtBuyingPoint,
        "Insp. Num":excelData[i].txtInspNum,
        "Insp. Date":excelData[i].txtInspDate,
        "Settlement Num":excelData[i].txtSttlmntNum,
        "Settle Date":excelData[i].txtDateSettle,
        "WR Num":excelData[i].txtWRNum,
        "WR Date":excelData[i].txtWRDate,
        "Applied Pounds":ExcelNumericDataConvert(excelData[i].txtAppliedPounds),
        
      }
      arrayData.push(obj);
    }
    }
    const ws = XLSX.utils.json_to_sheet(arrayData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error on export to excel button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnPrintSearchClick = () => {
    try {
      // let div = document.getElementById("gridAppToContrctCollapse").innerHTML;
      // var originalContents = document.body.innerHTML;
      // //document.body.innerHTML = div;
      // document.body.innerHTML = data();
      // window.print();
      // document.body.innerHTML = originalContents

      //const hostPath = "https://pps.dev.adm.com" 
      //const hostPath = "http://localhost:3000"
      const hostPath = window.location.origin
      const imgUrl = GetReportLogoImage(window.location.origin)
      var obj = new jsPDF('landscape', 'mm', 'a4');
      var img = new Image();
      img.src = imgUrl;

      let datatable = getValue(thisObj, "gridAppToContrct")
      let rep = []
      let rep1 =[]

      for (let i = 0; i < datatable.length; i++) {
        let rowdata = [
        datatable[i].txtBuyingPoint,
        datatable[i].txtInspNum,
        datatable[i].txtInspDate,
        datatable[i].txtSttlmntNum,
        datatable[i].txtDateSettle,
        datatable[i].txtWRNum,
        datatable[i].txtWRDate,
        datatable[i].txtAppliedPounds,
        datatable[i].txtTentativePounds,
        ]
        rep.push(rowdata)

        let rowdata1 = [
          datatable[i].txtBuyingPoint,
          datatable[i].txtInspNum,
          datatable[i].txtInspDate,
          datatable[i].txtSttlmntNum,
          datatable[i].txtDateSettle,
          datatable[i].txtWRNum,
          datatable[i].txtWRDate,
          datatable[i].txtAppliedPounds,
          
          ]
          rep1.push(rowdata1)
      }

      //obj.addImage(img, 30, 20)

      if(getData(thisObj,"pricingLine")!==0){
      obj.autoTable({
        startY: 20,
        startX: 20,
        head: [
          ['Buying Point', 'Insp. Num', 'Insp. Date', 'Settlement Num', 'Settle Date', 'WR Num', 'WR Date', 'Applied Pounds', 'Tentative Pounds']
        ],
        body: rep
      });
    }
      else{
        obj.autoTable({
          startY: 20,
          startX: 20,
          head: [
            ['Buying Point', 'Insp. Num', 'Insp. Date', 'Settlement Num', 'Settle Date', 'WR Num', 'WR Date', 'Applied Pounds']
          ],
          body: rep1
        });
      }
      obj.setFontSize(8)
      //obj.text('Page 1 of 1', 150, 200)
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Failed to print , please provide proper name to file")
        }
      }
      else {
        alert("Failed to Print, please provide proper name to file")
      }
      // obj.save('table.pdf')

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error on print button click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick;

  function formload(){
    let js =null
    //js = getData(thisObj,"bFillPounds")
    js = commonContext.bFillPounds
  let contractnumber = js[0].lblContractNumber
  setData(thisObj,"cont_number",contractnumber)
  let vendornum = js[0].lblVendor
  let pricingLine = js[0].pricingLine
  setData(thisObj,"pricingLine",pricingLine)
    if (pricingLine == 0) {
      hideColumn(thisObj,"gridAppToContrct","txtTentativePounds");
    thisObj.setState(current => {
        return {
          ...current,
          headerData: {
            scrName: "Applications to Seed Grower",
            scrCode: "PN1010F",
          },
        }
      })
    }
  //contractnumber=341270
  //vendornum=229435
  
  contractApplicationList(pricingLine,contractnumber,vendornum)
  }

  const contractApplicationList=(pricingLine,contractnumber,vendornum)=>{
  ContractManagementService.RetrieveApplicationContractDetails(pricingLine,null,null,contractnumber,vendornum)
  .then(response=>{
    let data = response
    let mapping =[]
    for(let i=0;data.length>i;i++){
      let obj={}
      obj.txtBuyingPoint=data[i].buyPtId
      obj.txtInspNum=data[i].inspNum
      if(obj.txtInspNum==null || obj.txtInspNum =='')
        obj.txtInspDate=null
      else
        //obj.txtInspDate=data[i].inspDateTime.slice(0,10)
        obj.txtInspDate = moment(data[i].inspDateTime).format("MM/DD/YYYY hh:mm A")
      obj.txtSttlmntNum=data[i].settlementNum
      if(obj.txtSttlmntNum==null || obj.txtSttlmntNum=='')
        obj.txtDateSettle=null
      else
        //obj.txtDateSettle=data[i].settlementDate.slice(0,10)
        obj.txtDateSettle = moment(data[i].settlementDate).format("MM/DD/YYYY hh:mm A")
      if (data[i].whseRcptNum !== '') {
        if (data[i].whseRcptNum !== 0) {
          obj.txtWRNum = data[i].whseRcptNum
        }
        else{
          obj.txtWRNum = null
        }
      }
      else
        obj.txtWRNum = null
      if(obj.txtWRNum==null || obj.txtWRNum=='')
        obj.txtWRDate=null
      else
        //obj.txtWRDate=data[i].auditDateTime.slice(0,10)
        obj.txtWRDate = moment(data[i].auditDateTime).format("MM/DD/YYYY hh:mm A")
      obj.txtAppliedPounds=data[i].applLbsAdj
      obj.txtTentativePounds=data[i].tentLbsAdj

      mapping.push(obj)
    }
    setValue(thisObj,"gridAppToContrct",mapping)

  })
  } 
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ApplicationToContract*/}

              {/* END_USER_CODE-USER_BEFORE_ApplicationToContract*/}

              {/* START_USER_CODE-USER_BEFORE_gridAppToContrct*/}

              {/* END_USER_CODE-USER_BEFORE_gridAppToContrct*/}

              <GridWidget
                conf={state.gridAppToContrct}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridAppToContrct}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                setFieldValue={setFieldValue}
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridAppToContrct*/}

              {/* END_USER_CODE-USER_AFTER_gridAppToContrct*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxCnclExprt*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCnclExprt*/}

              <GroupBoxWidget conf={state.grpbxCnclExprt} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_whtspc01*/}

                {/* END_USER_CODE-USER_BEFORE_whtspc01*/}

                <WhiteSpaceWidget
                  conf={state.whtspc01}
                  screenConf={state}
                ></WhiteSpaceWidget>
                {/* START_USER_CODE-USER_AFTER_whtspc01*/}

                {/* END_USER_CODE-USER_AFTER_whtspc01*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_whtspc02*/}

                {/* END_USER_CODE-USER_BEFORE_whtspc02*/}

                <WhiteSpaceWidget
                  conf={state.whtspc02}
                  screenConf={state}
                ></WhiteSpaceWidget>
                {/* START_USER_CODE-USER_AFTER_whtspc02*/}

                {/* END_USER_CODE-USER_AFTER_whtspc02*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCnclExprt*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCnclExprt*/}

              {/* START_USER_CODE-USER_AFTER_ApplicationToContract*/}

              {/* END_USER_CODE-USER_AFTER_ApplicationToContract*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ApplicationToContract);
