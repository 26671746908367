/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  CheckboxGroupWidget,
  LabelWidget,
  CheckboxWidget,
  setData,
  setValue,
  getValue,
  getData,
  disable,
  enable,
  goTo
} from "../../shared/WindowImports";

import "./InventoryInquiry.scss";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import { Buffer } from 'buffer';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_InventoryInquiry(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  let bFillVarietyListBool = false;
  let LoadIndex = 0;
  let mbSuccessfulLoad = false;
  let lstPeanutVarietyID = []
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "InventoryInquiry",
    windowName: "InventoryInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.InventoryInquiry",
    // START_USER_CODE-USER_InventoryInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Inventory Inquiry",
      scrCode: "PN1070A",
    },
    // END_USER_CODE-USER_InventoryInquiry_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export To Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnMakeAdjustments: {
      name: "btnMakeAdjustments",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Make Adjustments",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnMakeAdjustments_PROPERTIES

      // END_USER_CODE-USER_btnMakeAdjustments_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    chkboxEdibleCrushing: {
      name: "chkboxEdibleCrushing",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxPeanutCriteria",
      Options: "Edible:1,Crushing:2",
      ColSpan: "1",
      ColSpanForLargeDesktop: "2",
      ColSpanForTabLandscape: "2",
      ColSpanForTabPotrait: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxEdibleCrushing_PROPERTIES

      // END_USER_CODE-USER_chkboxEdibleCrushing_PROPERTIES
    },
    chkboxGrpByBuyPt: {
      name: "chkboxGrpByBuyPt",
      type: "CheckBoxWidget",
      parent: "grpbxLocation",
      Label: "Group By Buying Point",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxGrpByBuyPt_PROPERTIES

      // END_USER_CODE-USER_chkboxGrpByBuyPt_PROPERTIES
    },
    chkboxShowGrades: {
      name: "chkboxShowGrades",
      type: "CheckBoxWidget",
      parent: "grpbxLocation",
      Label: "Show Grades in Results",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxShowGrades_PROPERTIES

      // END_USER_CODE-USER_chkboxShowGrades_PROPERTIES
    },
    colBin: {
      name: "colBin",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Bin",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBin_PROPERTIES

      // END_USER_CODE-USER_colBin_PROPERTIES
    },
    colCompanyOwned: {
      name: "colCompanyOwned",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Company Owned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCompanyOwned_PROPERTIES

      // END_USER_CODE-USER_colCompanyOwned_PROPERTIES
    },
    colCRBR: {
      name: "colCRBR",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Crck Brkn%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCRBR_PROPERTIES

      // END_USER_CODE-USER_colCRBR_PROPERTIES
    },
    colCRMD: {
      name: "colCRMD",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Cncl RMD%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCRMD_PROPERTIES

      // END_USER_CODE-USER_colCRMD_PROPERTIES
    },
    colCrush: {
      name: "colCrush",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Crush",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCrush_PROPERTIES

      // END_USER_CODE-USER_colCrush_PROPERTIES
    },
    colDAM: {
      name: "colDAM",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "DAM%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDAM_PROPERTIES

      // END_USER_CODE-USER_colDAM_PROPERTIES
    },
    colDCOL: {
      name: "colDCOL",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Dis Clrd%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDCOL_PROPERTIES

      // END_USER_CODE-USER_colDCOL_PROPERTIES
    },
    colEdible: {
      name: "colEdible",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Edible",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEdible_PROPERTIES

      // END_USER_CODE-USER_colEdible_PROPERTIES
    },
    colELK: {
      name: "colELK",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "ELK%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colELK_PROPERTIES

      // END_USER_CODE-USER_colELK_PROPERTIES
    },
    colFAN: {
      name: "colFAN",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Fan%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFAN_PROPERTIES

      // END_USER_CODE-USER_colFAN_PROPERTIES
    },
    colFM: {
      name: "colFM",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "FM%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFM_PROPERTIES

      // END_USER_CODE-USER_colFM_PROPERTIES
    },
    colFrzDAM: {
      name: "colFrzDAM",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Frz Dam%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrzDAM_PROPERTIES

      // END_USER_CODE-USER_colFrzDAM_PROPERTIES
    },
    colGen: {
      name: "colGen",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGen_PROPERTIES

      // END_USER_CODE-USER_colGen_PROPERTIES
    },
    colGovCapacity: {
      name: "colGovCapacity",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Gov. Capacity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGovCapacity_PROPERTIES

      // END_USER_CODE-USER_colGovCapacity_PROPERTIES
    },
    colHULL: {
      name: "colHULL",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "HULL%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHULL_PROPERTIES

      // END_USER_CODE-USER_colHULL_PROPERTIES
    },
    colHullBr: {
      name: "colHullBr",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Hull Brght%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHullBr_PROPERTIES

      // END_USER_CODE-USER_colHullBr_PROPERTIES
    },
    colInTransit: {
      name: "colInTransit",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "InTransit",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInTransit_PROPERTIES

      // END_USER_CODE-USER_colInTransit_PROPERTIES
    },
    colJumbo: {
      name: "colJumbo",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Jumbo%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colJumbo_PROPERTIES

      // END_USER_CODE-USER_colJumbo_PROPERTIES
    },
    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    colLSK: {
      name: "colLSK",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "LSK%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLSK_PROPERTIES

      // END_USER_CODE-USER_colLSK_PROPERTIES
    },
    colMoist: {
      name: "colMoist",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Moist%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMoist_PROPERTIES

      // END_USER_CODE-USER_colMoist_PROPERTIES
    },
    colOK: {
      name: "colOK",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "OK%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOK_PROPERTIES

      // END_USER_CODE-USER_colOK_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colOrganic: {
      name: "colOrganic",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrganic_PROPERTIES

      // END_USER_CODE-USER_colOrganic_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colPhysicalInventory: {
      name: "colPhysicalInventory",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Physical Inventory",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPhysicalInventory_PROPERTIES

      // END_USER_CODE-USER_colPhysicalInventory_PROPERTIES
    },
    colPitDAM: {
      name: "colPitDAM",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Pit Dam%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPitDAM_PROPERTIES

      // END_USER_CODE-USER_colPitDAM_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colSMK: {
      name: "colSMK",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "SMK%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSMK_PROPERTIES

      // END_USER_CODE-USER_colSMK_PROPERTIES
    },
    colSS: {
      name: "colSS",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "SS%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSS_PROPERTIES

      // END_USER_CODE-USER_colSS_PROPERTIES
    },
    colTKC: {
      name: "colTKC",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "TKC%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTKC_PROPERTIES

      // END_USER_CODE-USER_colTKC_PROPERTIES
    },
    colTotalOpenStorage: {
      name: "colTotalOpenStorage",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Total Open Storage",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalOpenStorage_PROPERTIES

      // END_USER_CODE-USER_colTotalOpenStorage_PROPERTIES
    },
    colUnDeterminedObligated: {
      name: "colUnDeterminedObligated",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "UnDetermined Obligated",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnDeterminedObligated_PROPERTIES

      // END_USER_CODE-USER_colUnDeterminedObligated_PROPERTIES
    },
    colUnReceiptedObligated: {
      name: "colUnReceiptedObligated",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "UnReceipted Obligated",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnReceiptedObligated_PROPERTIES

      // END_USER_CODE-USER_colUnReceiptedObligated_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    colVicam: {
      name: "colVicam",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Vicam PPB",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVicam_PROPERTIES

      // END_USER_CODE-USER_colVicam_PROPERTIES
    },
    colWhse: {
      name: "colWhse",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Whs#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhse_PROPERTIES

      // END_USER_CODE-USER_colWhse_PROPERTIES
    },
    colWhsReceiptObligated: {
      name: "colWhsReceiptObligated",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Whs Receipt Obligated",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhsReceiptObligated_PROPERTIES

      // END_USER_CODE-USER_colWhsReceiptObligated_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Area:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddBinNum: {
      name: "ddBinNum",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Bin#:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      // HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBinNum_PROPERTIES

      // END_USER_CODE-USER_ddBinNum_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddCollectionPoint: {
      name: "ddCollectionPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Coll Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPoint_PROPERTIES
    },
    ddGen: {
      name: "ddGen",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Gen",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGen_PROPERTIES

      // END_USER_CODE-USER_ddGen_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Oleic",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Organic",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganic_PROPERTIES

      // END_USER_CODE-USER_ddOrganic_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Seed",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Seg",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    ddVariety: {
      name: "ddVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Variety",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVariety_PROPERTIES

      // END_USER_CODE-USER_ddVariety_PROPERTIES
    },
    ddWarehouseNum: {
      name: "ddWarehouseNum",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Whse#:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      // HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWarehouseNum_PROPERTIES

      // END_USER_CODE-USER_ddWarehouseNum_PROPERTIES
    },
    gridSearchResults: {
      name: "gridSearchResults",
      type: "GridWidget",
      parent: "grpbxSearchResults",
      gridCellsOrder:
        "txtcolLocation,txtcolWhse,txtcolBin,txtcolPhysicalInventory,txtcolWhsReceiptObligated,txtcolUnReceiptedObligated,txtcolUnDeterminedObligated,txtcolInTransit,txtcolGovCapacity,txtcolCompanyOwned,txtcolTotalOpenStorage,txtcolPeanutType,txtcolVariety,txtcolGen,txtcolOleic,txtcolSeg,txtcolEdible,txtcolCrush,txtcolOrganic,txtcolSMK,txtcolSS,txtcolOK,txtcolDAM,txtcolTKC,txtcolDCOL,txtcolELK,txtcolFM,txtcolLSK,txtcolMoist,txtcolHULL,txtcolFRZ,txtcolPitDAM,txtcolCRBR,txtcolHULLBr,txtcolFAN,txtcolCRMD,txtcolJumbo,txtcolVicam",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSearchResults_PROPERTIES

      // END_USER_CODE-USER_gridSearchResults_PROPERTIES
    },
    grpbxLocation: {
      name: "grpbxLocation",
      type: "GroupBoxWidget",
      parent: "grpbxInventoryInquiry",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxLocation_PROPERTIES

      // END_USER_CODE-USER_grpbxLocation_PROPERTIES
    },
    grpbxPeanutCriteria: {
      name: "grpbxPeanutCriteria",
      type: "GroupBoxWidget",
      parent: "grpbxInventoryInquiry",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutCriteria_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutCriteria_PROPERTIES
    },
    grpbxSearchResults: {
      name: "grpbxSearchResults",
      type: "GroupBoxWidget",
      parent: "grpbxInventoryInquiry",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSearchResults_PROPERTIES

      // END_USER_CODE-USER_grpbxSearchResults_PROPERTIES
    },
    lblLocation: {
      name: "lblLocation",
      type: "LabelWidget",
      parent: "grpbxLocation",
      Label: "Location",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocation_PROPERTIES

      // END_USER_CODE-USER_lblLocation_PROPERTIES
    },
    lblPeanutCriteria: {
      name: "lblPeanutCriteria",
      type: "LabelWidget",
      parent: "grpbxPeanutCriteria",
      Label: "Peanut Criteria",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutCriteria_PROPERTIES

      // END_USER_CODE-USER_lblPeanutCriteria_PROPERTIES
    },
    lblSearchResults: {
      name: "lblSearchResults",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Search Results",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSearchResults_PROPERTIES

      // END_USER_CODE-USER_lblSearchResults_PROPERTIES
    },
    txtcolBin: {
      name: "txtcolBin",
      type: "TextBoxWidget",
      colName: "colBin",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBin_PROPERTIES

      // END_USER_CODE-USER_txtcolBin_PROPERTIES
    },
    txtcolCompanyOwned: {
      name: "txtcolCompanyOwned",
      type: "TextBoxWidget",
      colName: "colCompanyOwned",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCompanyOwned_PROPERTIES

      // END_USER_CODE-USER_txtcolCompanyOwned_PROPERTIES
    },
    txtcolCRBR: {
      name: "txtcolCRBR",
      type: "TextBoxWidget",
      colName: "colCRBR",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCRBR_PROPERTIES

      // END_USER_CODE-USER_txtcolCRBR_PROPERTIES
    },
    txtcolCRMD: {
      name: "txtcolCRMD",
      type: "TextBoxWidget",
      colName: "colCRMD",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCRMD_PROPERTIES

      // END_USER_CODE-USER_txtcolCRMD_PROPERTIES
    },
    txtcolCrush: {
      name: "txtcolCrush",
      type: "TextBoxWidget",
      colName: "colCrush",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCrush_PROPERTIES

      // END_USER_CODE-USER_txtcolCrush_PROPERTIES
    },
    txtcolDAM: {
      name: "txtcolDAM",
      type: "TextBoxWidget",
      colName: "colDAM",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDAM_PROPERTIES

      // END_USER_CODE-USER_txtcolDAM_PROPERTIES
    },
    txtcolDCOL: {
      name: "txtcolDCOL",
      type: "TextBoxWidget",
      colName: "colDCOL",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDCOL_PROPERTIES

      // END_USER_CODE-USER_txtcolDCOL_PROPERTIES
    },
    txtcolEdible: {
      name: "txtcolEdible",
      type: "TextBoxWidget",
      colName: "colEdible",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEdible_PROPERTIES

      // END_USER_CODE-USER_txtcolEdible_PROPERTIES
    },
    txtcolELK: {
      name: "txtcolELK",
      type: "TextBoxWidget",
      colName: "colELK",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolELK_PROPERTIES

      // END_USER_CODE-USER_txtcolELK_PROPERTIES
    },
    txtcolFAN: {
      name: "txtcolFAN",
      type: "TextBoxWidget",
      colName: "colFAN",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFAN_PROPERTIES

      // END_USER_CODE-USER_txtcolFAN_PROPERTIES
    },
    txtcolFM: {
      name: "txtcolFM",
      type: "TextBoxWidget",
      colName: "colFM",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFM_PROPERTIES

      // END_USER_CODE-USER_txtcolFM_PROPERTIES
    },
    txtcolFRZ: {
      name: "txtcolFRZ",
      type: "TextBoxWidget",
      colName: "colFrzDAM",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFRZ_PROPERTIES

      // END_USER_CODE-USER_txtcolFRZ_PROPERTIES
    },
    txtcolGen: {
      name: "txtcolGen",
      type: "TextBoxWidget",
      colName: "colGen",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGen_PROPERTIES

      // END_USER_CODE-USER_txtcolGen_PROPERTIES
    },
    txtcolGovCapacity: {
      name: "txtcolGovCapacity",
      type: "TextBoxWidget",
      colName: "colGovCapacity",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGovCapacity_PROPERTIES

      // END_USER_CODE-USER_txtcolGovCapacity_PROPERTIES
    },
    txtcolHULL: {
      name: "txtcolHULL",
      type: "TextBoxWidget",
      colName: "colHULL",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHULL_PROPERTIES

      // END_USER_CODE-USER_txtcolHULL_PROPERTIES
    },
    txtcolHULLBr: {
      name: "txtcolHULLBr",
      type: "TextBoxWidget",
      colName: "colHullBr",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHULLBr_PROPERTIES

      // END_USER_CODE-USER_txtcolHULLBr_PROPERTIES
    },
    txtcolInTransit: {
      name: "txtcolInTransit",
      type: "TextBoxWidget",
      colName: "colInTransit",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInTransit_PROPERTIES

      // END_USER_CODE-USER_txtcolInTransit_PROPERTIES
    },
    txtcolJumbo: {
      name: "txtcolJumbo",
      type: "TextBoxWidget",
      colName: "colJumbo",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolJumbo_PROPERTIES

      // END_USER_CODE-USER_txtcolJumbo_PROPERTIES
    },
    txtcolLocation: {
      name: "txtcolLocation",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolLocation_PROPERTIES
    },
    txtcolLSK: {
      name: "txtcolLSK",
      type: "TextBoxWidget",
      colName: "colLSK",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLSK_PROPERTIES

      // END_USER_CODE-USER_txtcolLSK_PROPERTIES
    },
    txtcolMoist: {
      name: "txtcolMoist",
      type: "TextBoxWidget",
      colName: "colMoist",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMoist_PROPERTIES

      // END_USER_CODE-USER_txtcolMoist_PROPERTIES
    },
    txtcolOK: {
      name: "txtcolOK",
      type: "TextBoxWidget",
      colName: "colOK",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOK_PROPERTIES

      // END_USER_CODE-USER_txtcolOK_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolOrganic: {
      name: "txtcolOrganic",
      type: "TextBoxWidget",
      colName: "colOrganic",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrganic_PROPERTIES

      // END_USER_CODE-USER_txtcolOrganic_PROPERTIES
    },
    txtcolPeanutType: {
      name: "txtcolPeanutType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutType_PROPERTIES
    },
    txtcolPhysicalInventory: {
      name: "txtcolPhysicalInventory",
      type: "TextBoxWidget",
      colName: "colPhysicalInventory",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPhysicalInventory_PROPERTIES

      // END_USER_CODE-USER_txtcolPhysicalInventory_PROPERTIES
    },
    txtcolPitDAM: {
      name: "txtcolPitDAM",
      type: "TextBoxWidget",
      colName: "colPitDAM",
      parent: "gridSearchResults",
      Label: "textboxwidget41",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPitDAM_PROPERTIES

      // END_USER_CODE-USER_txtcolPitDAM_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolSMK: {
      name: "txtcolSMK",
      type: "TextBoxWidget",
      colName: "colSMK",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSMK_PROPERTIES

      // END_USER_CODE-USER_txtcolSMK_PROPERTIES
    },
    txtcolSS: {
      name: "txtcolSS",
      type: "TextBoxWidget",
      colName: "colSS",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSS_PROPERTIES

      // END_USER_CODE-USER_txtcolSS_PROPERTIES
    },
    txtcolTKC: {
      name: "txtcolTKC",
      type: "TextBoxWidget",
      colName: "colTKC",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTKC_PROPERTIES

      // END_USER_CODE-USER_txtcolTKC_PROPERTIES
    },
    txtcolTotalOpenStorage: {
      name: "txtcolTotalOpenStorage",
      type: "TextBoxWidget",
      colName: "colTotalOpenStorage",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalOpenStorage_PROPERTIES

      // END_USER_CODE-USER_txtcolTotalOpenStorage_PROPERTIES
    },
    txtcolUnDeterminedObligated: {
      name: "txtcolUnDeterminedObligated",
      type: "TextBoxWidget",
      colName: "colUnDeterminedObligated",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnDeterminedObligated_PROPERTIES

      // END_USER_CODE-USER_txtcolUnDeterminedObligated_PROPERTIES
    },
    txtcolUnReceiptedObligated: {
      name: "txtcolUnReceiptedObligated",
      type: "TextBoxWidget",
      colName: "colUnReceiptedObligated",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnReceiptedObligated_PROPERTIES

      // END_USER_CODE-USER_txtcolUnReceiptedObligated_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtcolVicam: {
      name: "txtcolVicam",
      type: "TextBoxWidget",
      colName: "colVicam",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVicam_PROPERTIES

      // END_USER_CODE-USER_txtcolVicam_PROPERTIES
    },
    txtcolWhse: {
      name: "txtcolWhse",
      type: "TextBoxWidget",
      colName: "colWhse",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhse_PROPERTIES

      // END_USER_CODE-USER_txtcolWhse_PROPERTIES
    },
    txtcolWhsReceiptObligated: {
      name: "txtcolWhsReceiptObligated",
      type: "TextBoxWidget",
      colName: "colWhsReceiptObligated",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhsReceiptObligated_PROPERTIES

      // END_USER_CODE-USER_txtcolWhsReceiptObligated_PROPERTIES
    },
    grpbxInventoryInquiry: {
      name: "grpbxInventoryInquiry",
      type: "GroupBoxWidget",
      parent: "InventoryInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxInventoryInquiry_PROPERTIES

      // END_USER_CODE-USER_grpbxInventoryInquiry_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "InventoryInquiry",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: { "ContractManagement#MainMenu": {}, },
      REVERSE: { "ContractManagement#MainMenu": {}, },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click",],
    }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  async function FormLoad(){
    try{
      let lstrxml = []

      setData(thisObj, 'mbSuccessfulLoad', true)
      setData(thisObj, 'LoadIndex', 1)
      setData(thisObj, 'gridSearchResults', [])

      if(!await bFillTypeList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(!await bFillAreaList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(!await bFillSegmentList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(!await bFillOleicList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(!await bFillSeedList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(!await bFillGenerationList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if(!await bFillOrganicList()){
        setData(thisObj, 'mbSuccessfulLoad', false)
      }

      disable(thisObj, 'btnMakeAdjustments')
      
      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1070', null, 'U', null)
      if(lstrxml[0].permission.toLocaleUpperCase() !== 'N'){
        enable(thisObj, 'btnMakeAdjustments')
      }
      setValue(thisObj, 'chkboxGrpByBuyPt', 1)
      await bWarehouseBinList()
      disable(thisObj, 'ddWarehouseNum')
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setData(thisObj, 'LoadIndex', 0)
      setLoading(false);
    }
  }

  // function for formatting the grid numeric values : extra zeros after the decimal will be removed for the values.
  function FormatNum(num) {
    try {
      if (num == undefined)
        num = 0
      let s = Number(num).toString().split('').reverse().join("")
      return s.split('').reverse().join("")
    }
    catch (err) {
      errorHandler(err)
      return num
    }
  }

  // function to fill the Area drop down : ddArea
  async function bFillAreaList(){
    try{
      let LstrList = []
      let js = [ {
        key         : '',
        description : '>>> All Collection Point <<<'
      }]
      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPoint: {
            ...state["ddCollectionPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddCollectionPoint', js.at(0).key)
      setData(thisObj, 'ddCollectionPoint', js.at(0).key)

      js = [ {
        key         : '',
        description : '>>> All Buying Point <<<'
      }]
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key)
      setData(thisObj, 'ddBuyingPoint', js.at(0).key)

      js = [ {
        key         : '',
        description : '>>> All Areas <<<'
      }]

      LstrList = await SettlementService.RetrieveAreaControlDetails(null)
      for(var i=0; i<LstrList.length; i++){
        js.push({
          key         : LstrList[i].areaId,
          description : LstrList[i].areaId + " - " + LstrList[i].areaName
        })
      }
      
      thisObj.setState(current => {
        return {
          ...current,
          ddArea: {
            ...state["ddArea"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddArea', js.at(0).key);
      setData(thisObj, 'ddArea', js.at(0).key);
      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to fill the Collection drop down : ddCollection
  async function bFillCollectionPointList(){
    try{
      let LstrList = []

      // when the area is changed, all the values from buying point dropdown will be cleared.
      let js = [ {
        key         : '',
        description : '>>> All Buying Points <<<'
      }]
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key)
      setData(thisObj, 'ddBuyingPoint', js.at(0).key)

      // when the area is changed, all the values from warehouse/bin dropdown will be cleared.
      js = [ {
        key         : '',
        description : '>>> All Whse/Bin <<<'
      }]
      thisObj.setState(current => {
        return {
          ...current,
          ddWarehouseNum: {
            ...state["ddWarehouseNum"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWarehouseNum', js.at(0).key)
      setData(thisObj, 'ddWarehouseNum', js.at(0).key)

      // new values for the collection point will be added based on the area Id selected.
      js = [ {
        key         : '',
        description : '>>> All Collection Points <<<'
      }]

      let areaId = getValue(thisObj, 'ddArea') // thisObj.values['ddArea']
      if(![undefined, null, ""].includes(areaId)){
        LstrList = await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(compIdFromLS(), cropYearFromLS(), null, areaId)
        for(var i=0; i<LstrList.length; i++){
          js.push({
            key         : LstrList[i].coll_pt_id,
            description : LstrList[i].coll_pt_id + " - " + LstrList[i].buy_pt_name
          })
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPoint: {
            ...state["ddCollectionPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddCollectionPoint', js.at(0).key);
      setData(thisObj, 'ddCollectionPoint', js.at(0).key);

    }
    catch(err){
      errorHandler(err)
    }
  }

  // function to fill the Buying Point drop down : ddBuyingPoint
  async function bFillBuyingPointList(){
    try{
      let LstrList = []

      disable(thisObj, 'ddWarehouseNum')

      // when the area is changed, all the values from warehouse/bin dropdown will be cleared.
      let js = [ {
        key         : '',
        description : '>>> All Whse/Bin <<<'
      }]
      thisObj.setState(current => {
        return {
          ...current,
          ddWarehouseNum: {
            ...state["ddWarehouseNum"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWarehouseNum', js.at(0).key)
      setData(thisObj, 'ddWarehouseNum', js.at(0).key)

      js = [ {
        key         : '',
        description : '>>> All Buying Points <<<'
      }]

      let areaId = getValue(thisObj, 'ddArea') // thisObj.values['ddArea']
      let collId = getValue(thisObj, 'ddCollectionPoint') // thisObj.values['ddCollectionPoint']
      
      if(collId !== '' && collId !== undefined && collId !== null){
        LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1070', null, areaId, collId, null)
        for(var i=0; i<LstrList.length; i++){
          js.push({
            key         : LstrList[i].buy_pt_id,
            description : LstrList[i].buy_pt_id + " - " + LstrList[i].buy_pt_name
          })
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
      setData(thisObj, 'ddBuyingPoint', js.at(0).key);
      
      if(js.length <= 1){
        disable(thisObj, 'ddWarehouseNum')
      }
      else{
        enable(thisObj, 'ddWarehouseNum')
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  // function to fill the Peanut Type drop down : ddType
  async function bFillTypeList(){
    try{
      let LstrTypeList = []
      let js = [ {
        key         : '',
        description : '>>> All Types <<<'
      }]

      LstrTypeList = await ContractManagementService.RetrievePeanutTypeControls(null)
      for(var i=0; i<LstrTypeList.length; i++){
        js.push({
          key         : LstrTypeList[i].pnutTypeId,
          description : LstrTypeList[i].pnutTypeName
        })
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddType: {
            ...state["ddType"],
            valueList: js
          }
        }
      })
      
      setValue(thisObj, 'ddType', js.at(0).key);
      setData(thisObj, 'ddType', js.at(0).key);

      js = [ {
        key         : '',
        description : '>>> All Varities <<<'
      }]

      thisObj.setState(current => {
        return {
          ...current,
          ddVariety: {
            ...state["ddVariety"],
            valueList: js
          }
        }
      })

      setValue(thisObj, 'ddVariety', js.at(0).key);
      setData(thisObj, 'ddVariety', js.at(0).key);

      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to fill the Peanut Variety drop down : ddVariety
  async function bFillVarietyList(){
    try{
      let sXML = []
      let sSymbol = ""
      let sSavVarID = ""
      let lCnt = 0
      let bNewVar = false

      setData(thisObj, 'bFillVarietyListBool', false)

      sSavVarID = getValue(thisObj, 'ddVariety')
      if(sSavVarID !== '' && sSavVarID !== undefined && sSavVarID !== null) {
        sSavVarID = sSavVarID.trim().toLocaleUpperCase()
      }

      let js = [ {
        key         : '',
        description : '>>> All Varities <<<'
      }]

      let peanutType = getValue(thisObj, 'ddType')
      if(peanutType !== "" && peanutType !== undefined && peanutType !== null){
        let areaId  = getValue(thisObj, 'ddArea') // thisObj.values['ddArea']
        let collId  = getValue(thisObj, 'ddCollectionPoint') // thisObj.values['ddCollectionPoint']
        let buyPtID = getValue(thisObj, 'ddBuyingPoint') // thisObj.values['ddBuyingPoint']
        sXML = await ContractManagementService.RetrievePeanutVarietyControls(buyPtID, collId, areaId, peanutType, null)
        
        for(var i=0; i<sXML.length; i++){
          bNewVar = true;
          let peanutVarIdList = [ undefined, null ].includes(getData(thisObj, 'lstPeanutVarietyID')) ? [] : getData(thisObj, 'lstPeanutVarietyID')
          for(var j=0; j< peanutVarIdList.length; j++){
            if(peanutVarIdList[j]?.trim().toLocaleUpperCase() == sXML[i].pnut_variety_id?.trim()?.toLocaleUpperCase()){
              bNewVar = false;
              break;
            }
          }          

          if(bNewVar){
            switch(sXML[i].symbol_ind.trim().toLocaleUpperCase()){
              case "TRADEMARK":
                sSymbol = " \u2122"
                break;
              case "REGISTERED TRADEMARK":
                sSymbol = " \xAE"
                break;
              case "COPYRIGHT":
                sSymbol = " \xA9"
                break;
              default:
                sSymbol = ""
            }
            js.push({
              key         : sXML[i].pnut_variety_id,
              description : sXML[i].pnut_variety_name + sSymbol
            })
            let res = [ undefined, null ].includes(getData(thisObj, 'lstPeanutVarietyID')) ? [] : getData(thisObj, 'lstPeanutVarietyID')
            res.push(sXML[i].pnut_variety_id)
            setData(thisObj, 'lstPeanutVarietyID', res)
          }
        }  
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddVariety: {
            ...state["ddVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddVariety', js.at(0).key);
      setData(thisObj, 'ddVariety', js.at(0).key);

      setData(thisObj, 'bFillVarietyListBool', true)
    }
    catch(err){
      errorHandler(err)
    }
  }

  // function to fill the Organic drop down : ddOrganic
  async function bFillOrganicList(){
    try{
      let js = [
        { key : '',  description : '>>> Both <<<'},
        { key : 'Y', description : 'Yes'},
        { key : 'N', description : 'No'}
      ]
      thisObj.setState(current => {
        return {
          ...current,
          ddOrganic: {
            ...state["ddOrganic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOrganic', js.at(0).key);
      setData(thisObj, 'ddOrganic', js.at(0).key);
    }
    catch(err){
      errorHandler(err)
    }
  }

  // function to fill the Oleic drop down : ddOleic
  async function bFillOleicList(){
    try{
      let js = [
        { key : '',  description : '>>> All Oleic <<<'},
        { key : 'H', description : 'High'},
        { key : 'M', description : 'Mid'},
        { key : 'N', description : 'None'}
      ]
      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...state["ddOleic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOleic', js.at(0).key);
      setData(thisObj, 'ddOleic', js.at(0).key);
      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to fill the Segmentation drop down : ddSeg
  async function bFillSegmentList(){
    try{
      let js = [
        { key : '',  description : '>>> All Segs <<<'},
        { key : '1', description : 'Seg1'},
        { key : '2', description : 'Seg2'},
        { key : '3', description : 'Seg3'}
      ]
      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeg', js.at(0).key);
      setData(thisObj, 'ddSeg', js.at(0).key);
      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to fill the Peanut generation drop down : ddGen
  async function bFillGenerationList(){
    try{
      let js = [
        { key : '',  description : '>>> All <<<'},
        { key : 'F', description : 'Foundation'},
        { key : 'R', description : 'Registered'},
        { key : 'C', description : 'Certified'}
      ]
      thisObj.setState(current => {
        return {
          ...current,
          ddGen: {
            ...state["ddGen"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddGen', js.at(0).key);
      setData(thisObj, 'ddGen', js.at(0).key);
      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to fill the Peanut Seed drop down : ddSeed
  async function bFillSeedList(){
    try{
      let js = [
        { key : '',  description : '>>> Both <<<'},
        { key : 'Y', description : 'Seed'},
        { key : 'N', description : 'Non-Seed'}
      ]
      thisObj.setState(current => {
        return {
          ...current,
          ddSeed: {
            ...state["ddSeed"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeed', js.at(0).key);
      setData(thisObj, 'ddSeed', js.at(0).key);
      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to fill the Warehouse and bin drop down : ddWarehouseNum
  async function bWarehouseBinList(){
    try{
      let js = [{
         key : '',  
         description : '>>> All Whse/Bins <<<'
      }]

      let buyingPt = getValue(thisObj, 'ddBuyingPoint')
      
      if(buyingPt !== '' && buyingPt != null && buyingPt != undefined){
        let response = await SettlementService.RetrieveWhouseBinInventoryDetails(buyingPt, null, null, null, null)
        for(var i=0; i<response.length; i++){
          js.push({
            key         : response[i].whouseNum + '-' + response[i].binNum,
            description : response[i].whouseNum.trim() + ' - ' + response[i].binNum.trim() + ' - ' + response[i].whouseBinId.trim()
          })        
        }
        enable(thisObj, 'ddWarehouseNum')
      }
      else{
        disable(thisObj, 'ddWarehouseNum')
      }     
      
      thisObj.setState(current => {
        return {
          ...current,
          ddWarehouseNum: {
            ...state["ddWarehouseNum"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWarehouseNum', js.at(0).key);
      setData(thisObj, 'ddWarehouseNum', js.at(0).key);
      return true;      
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  // function to load the grid based on the filter criteria
  async function DoSearch(){
    try{
      let n = 0;
      let LstrList = "";
      let ShowGrades = false;
      let s = "";
      let LstrXML2 = "";
      let ss = "";
      let o = "";
      let nn = 0;
      let nnn = 0;

      setLoading(true)
      setData(thisObj, 'gridSearchResults', [])

      let chkShowGrade = getValue(thisObj, 'chkboxShowGrades')
      ShowGrades = [ undefined, null].includes(chkShowGrade) ? false : chkShowGrade

      let areaId = getValue(thisObj, 'ddArea')
      if(areaId !== undefined && areaId !== null && areaId !== ''){
        s += " and B.area_id='" + areaId + "'"
      }

      let collId = getValue(thisObj, 'ddCollectionPoint')
      if(collId !== undefined && collId !== null && collId !== ''){
        s += " and B.coll_pt_id='" + collId + "'"
      }

      let peanutType = getValue(thisObj, 'ddType')
      if(peanutType !== undefined && peanutType !== null && peanutType !== ''){
        s += " and pnut_type_id='" + peanutType + "'"
      }

      let peanutVariety = getValue(thisObj, 'ddVariety')
      if(peanutVariety !== undefined && peanutVariety !== null && peanutVariety !== ''){
        s += " and pnut_variety_id='" + peanutVariety + "'"
      }

      let gen = getValue(thisObj, 'ddGen')
      if(gen !== undefined && gen !== null && gen !== ''){
        s += " and seed_gen='" + gen + "'"
      }

      let seedGen = getValue(thisObj, 'ddSeed')
      if(seedGen !== undefined && seedGen !== null && seedGen !== ''){
        if(seedGen == 'Y'){
          s += " and seed_gen!=''"
        }
        else{
          s += " and seed_gen=''"
        }
      }

      let oliec = getValue(thisObj, 'ddOleic')
      if(oliec !== undefined && oliec !== null && oliec !== ''){
        if(oliec !== 'N'){
          s += " and oleic_ind='" + oliec + "'"
        }
        else{
          s += " and oleic_ind=''"
        }
      }

      let organic = getValue(thisObj, 'ddOrganic')
      if(organic !== undefined && organic !== null && organic !== ''){
        s += " and organic_ind='" + organic + "'"
      }

      let seg = getValue(thisObj, 'ddSeg')
      if(seg !== undefined && seg !== null && seg !== ''){
        s += " and seg_type='" + seg + "'"
      }

      let chkEdible = getValue(thisObj, 'chkboxEdibleCrushing').includes('1') ? true : false
      let chkCrushing = getValue(thisObj, 'chkboxEdibleCrushing').includes('2') ? true : false
      
      if(chkEdible && chkCrushing){
        if(chkEdible){
          s +=  " and (I.edible_oil_ind='E'"
        }
        if(chkCrushing){
          s += " or I.edible_oil_ind='O')"
        }
      }
      else{
        if(chkEdible){
          s += " and edible_oil_ind='E'"
        }
        if(chkCrushing){
          s += " and edible_oil_ind='O'"
        }
      }

      let chkGrpByBuyPt = getValue(thisObj, 'chkboxGrpByBuyPt')
      if(chkGrpByBuyPt){
        ss = "buy_pt_id," + ss
      }

      if(ss.slice(-1) == ','){
        ss = ss.slice(0, -1)
      }

      let symbolDict = {
        "TRADEMARK"            : " ᵀᴹ",
        "REGISTERED TRADEMARK" :" ®",
        "COPYRIGHT"            : " ©",
        "H"                    : "High",
        "M"                    : "Mid",
        ""                     : ""
      }

      let js = []
      let totalArr = [ 0, 0, 0, 0, 0, 0, 0, 0 ]
      let buyPtId = getValue(thisObj, 'ddBuyingPoint')
      let whouseNum = ![ undefined, null, ''].includes(getValue(thisObj, 'ddWarehouseNum')) ? getValue(thisObj, 'ddWarehouseNum').split('-')[0] : ''
      let binNum = ![ undefined, null, ''].includes(getValue(thisObj, 'ddWarehouseNum')) ? getValue(thisObj, 'ddWarehouseNum').split('-')[1] : ''
      let sortby = ss
      let where = Buffer.from(s).toString('base64');
      
      LstrList = await SystemMaintenanceApplicationSupportService.RetrieveWareHouseBnInventoryCalcDetails('PN1070', null, buyPtId, whouseNum, binNum, sortby, where, ShowGrades)
      for(var i=0; i<LstrList.length; i++){
        js.push({
          "txtcolLocation"              : LstrList[i].buy_pt_id + ' - ' + LstrList[i].buy_pt_name,
          "txtcolWhse"                  : LstrList[i].whouse_num,
          "txtcolBin"                   : LstrList[i].bin_num,
          "txtcolPhysicalInventory"     : FormatNum(LstrList[i].physical_inv),
          "txtcolWhsReceiptObligated"   : FormatNum(LstrList[i].whouse_rcpt_inv),
          "txtcolUnReceiptedObligated"  : FormatNum(LstrList[i].unrcpted_inv),
          "txtcolUnDeterminedObligated" : FormatNum(LstrList[i].undetermined_inv),
          "txtcolInTransit"             : FormatNum(LstrList[i].intransit_tons),
          "txtcolGovCapacity"           : FormatNum(LstrList[i].capacity_tons),
          "txtcolCompanyOwned"          : FormatNum(LstrList[i].comp_own_inv),
          "txtcolTotalOpenStorage"      : FormatNum(LstrList[i].tot_open_storage),
          "txtcolPeanutType"            : LstrList[i].pnut_type_name,
          "txtcolVariety"               : LstrList[i].pnut_variety_name + symbolDict[LstrList[i].symbol_ind.trim().toLocaleUpperCase()],
          "txtcolGen"                   : LstrList[i].seed_gen,
          "txtcolOleic"                 : symbolDict[LstrList[i].oleic_ind.trim().toLocaleUpperCase()],
          "txtcolSeg"                   : LstrList[i].seg_type == "" ? "" : 'Seg' + LstrList[i].seg_type,
          "txtcolEdible"                : LstrList[i].edible_oil_ind == 'E' ? 'Y' : '',
          "txtcolCrush"                 : LstrList[i].edible_oil_ind == 'O' ? 'Y' : '',
          "txtcolOrganic"               : LstrList[i].organic_ind,
          "txtcolSMK"                   : FormatNum(LstrList[i].wa_smk_pct),
          "txtcolSS"                    : FormatNum(LstrList[i].wa_ss_pct),
          "txtcolOK"                    : FormatNum(LstrList[i].wa_ok_pct),
          "txtcolDAM"                   : FormatNum(LstrList[i].wa_dam_pct),
          "txtcolTKC"                   : FormatNum(LstrList[i].wa_tkc_pct),
          "txtcolDCOL"                  : FormatNum(LstrList[i].wa_dcol_shel_pct),
          "txtcolELK"                   : FormatNum(LstrList[i].wa_elk_pct),
          "txtcolFM"                    : FormatNum(LstrList[i].wa_fm_pct),
          "txtcolLSK"                   : FormatNum(LstrList[i].wa_lsk_pct),
          "txtcolMoist"                 : FormatNum(LstrList[i].wa_moist_pct),
          "txtcolHULL"                  : FormatNum(LstrList[i].wa_hull_pct),
          "txtcolFRZ"                   : FormatNum(LstrList[i].wa_frez_dam_pct),
          "txtcolPitDAM"                : FormatNum(LstrList[i].wa_pit_dam_pct),
          "txtcolCRBR"                  : FormatNum(LstrList[i].wa_cr_br_pct),
          "txtcolHULLBr"                : FormatNum(LstrList[i].wa_hul_brit_pct),
          "txtcolFAN"                   : FormatNum(LstrList[i].wa_fan_pct),
          "txtcolCRMD"                  : FormatNum(LstrList[i].wa_cncl_rmd_pct),
          "txtcolJumbo"                 : FormatNum(LstrList[i].wa_jumbo_pct),
          "txtcolVicam"                 : FormatNum(LstrList[i].wa_vicam_ppb)
        })
        
        totalArr[0] += Number(LstrList[i].physical_inv)
        totalArr[1] += Number(LstrList[i].whouse_rcpt_inv)
        totalArr[2] += Number(LstrList[i].unrcpted_inv)
        totalArr[3] += Number(LstrList[i].undetermined_inv)
        totalArr[4] += Number(LstrList[i].intransit_tons)
        totalArr[5] += Number(LstrList[i].capacity_tons)
        totalArr[6] += Number(LstrList[i].comp_own_inv)
        totalArr[7] += Number(LstrList[i].tot_open_storage)
      }
      
      if(js.length >= 1){
        js.push({
          "txtcolLocation"              : "Total",
          "txtcolWhse"                  : "",
          "txtcolBin"                   : "",
          "txtcolPhysicalInventory"     : FormatNum(Number(totalArr[0]).toFixed(4)),
          "txtcolWhsReceiptObligated"   : FormatNum(Number(totalArr[1]).toFixed(4)),
          "txtcolUnReceiptedObligated"  : FormatNum(Number(totalArr[2]).toFixed(4)),
          "txtcolUnDeterminedObligated" : FormatNum(Number(totalArr[3]).toFixed(4)),
          "txtcolInTransit"             : FormatNum(Number(totalArr[4]).toFixed(4)),
          "txtcolGovCapacity"           : FormatNum(Number(totalArr[5]).toFixed(4)),
          "txtcolCompanyOwned"          : FormatNum(Number(totalArr[6]).toFixed(4)),
          "txtcolTotalOpenStorage"      : FormatNum(Number(totalArr[7]).toFixed(4)),
          "txtcolPeanutType"            : "",
          "txtcolVariety"               : "",
          "txtcolGen"                   : "",
          "txtcolOleic"                 : "",
          "txtcolSeg"                   : "",
          "txtcolEdible"                : "",
          "txtcolCrush"                 : "",
          "txtcolOrganic"               : "",
          "txtcolSMK"                   : "",
          "txtcolSS"                    : "",
          "txtcolOK"                    : "",
          "txtcolDAM"                   : "",
          "txtcolTKC"                   : "",
          "txtcolDCOL"                  : "",
          "txtcolELK"                   : "",
          "txtcolFM"                    : "",
          "txtcolLSK"                   : "",
          "txtcolMoist"                 : "",
          "txtcolHULL"                  : "",
          "txtcolFRZ"                   : "",
          "txtcolPitDAM"                : "",
          "txtcolCRBR"                  : "",
          "txtcolHULLBr"                : "",
          "txtcolFAN"                   : "",
          "txtcolCRMD"                  : "",
          "txtcolJumbo"                 : "",
          "txtcolVicam"                 : "",
        })
      }
      
      setValue(thisObj, 'gridSearchResults', js)
      setData(thisObj, 'gridSearchResults', js)
      setData(thisObj, 'lastRow', totalArr)

      
      thisObj.state.gridSearchResults.columns[19].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[20].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[21].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[22].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[23].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[24].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[25].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[26].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[27].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[28].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[29].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[30].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[31].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[32].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[33].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[34].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[35].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[36].Visible = ShowGrades
      thisObj.state.gridSearchResults.columns[37].Visible = ShowGrades
      
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }

  const onddAreaChange = async() => {
    try{
      await bFillCollectionPointList()
      disable(thisObj, 'ddWarehouseNum')
    }
    catch(err){
      errorHandler(err)
    }    
  }
  thisObj.onddAreaChange = onddAreaChange;

  const onddCollectionPointChange = async() => {
    try{
      await bFillBuyingPointList()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddCollectionPointChange = onddCollectionPointChange;

  const onddBuyingPointChange = async() => {
    try{
      await bWarehouseBinList()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const onddTypeChange = async() => {
    try{
      setData(thisObj, 'lstPeanutVarietyID', [])
      await bFillVarietyList()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddTypeChange = onddTypeChange;

  const onbtnSearchClick = async() => {
    try{
      await DoSearch()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const onbtnMakeAdjustmentsClick = async() => {
    try{
      goTo(thisObj, 'SystemMaintenanceApplicationSupport#InventoryAdjustments#DEFAULT#true#Click')
      return;
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnMakeAdjustmentsClick = onbtnMakeAdjustmentsClick;

  const onbtnPrintClick = async() => {
    try{
      let areaId  = getValue(thisObj, 'ddArea') == '' ? ">>> All Areas <<<" : getValue(thisObj, 'ddArea')
      let collId  = getValue(thisObj, 'ddCollectionPoint') == '' ? ">>> All Collection Points <<<" : getValue(thisObj, 'ddCollectionPoint')
      let buyPtID = getValue(thisObj, 'ddBuyingPoint') == '' ? ">>> All Buying Points <<<" : getValue(thisObj, 'ddBuyingPoint')

      if(collId == ">>> All Collection Points <<<"){
        buyPtID = ''
      }

      let response = await SettlementService.RetrieveAreaControlDetails(null)
      for(var i=0; i<response.length; i++){
        if(response[i].areaId == areaId){
          areaId = response[i].areaId + " - " + response[i].areaName
          break;
        }
      }

      let sDocName = "Inv. " + cropYearFromLS() + "; " + areaId + "; " + collId + "; " + buyPtID
      areaId  = getValue(thisObj, 'ddArea') == '' ? ">>> All Areas <<<" : getValue(thisObj, 'ddArea')
      setLoading(true)

      let res1 = []
      let res2 = []
      let headerArray1 = []
      let headerArray2 = []
      let data = getData(thisObj, 'gridSearchResults')
      let lastRowData = getData(thisObj, 'lastRow')
      let locationList = []

      if([ undefined, null ].includes(data)){
        return;
      }

      for(var i = 0; i < thisObj.state.gridSearchResults.columns.length; i++){
        if(thisObj.state.gridSearchResults.columns[i].Visible){
          if(headerArray1.length <= 19){
            headerArray1.push(thisObj.state.gridSearchResults.columns[i].Label)
            if(['Location', 'Whs#', 'Bin'].includes(thisObj.state.gridSearchResults.columns[i].Label)){
              headerArray2.push(thisObj.state.gridSearchResults.columns[i].Label)  
            }
          }
          else{
            headerArray2.push(thisObj.state.gridSearchResults.columns[i].Label)
          }          
        }
      }
      
      for(var i = 0; i< data.length; i++){
        let bodyArr1 = []
        let bodyArr2 = []
        if(thisObj.state.gridSearchResults.columns[0].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[0].Label)){
            bodyArr1.push(data[i].txtcolLocation)
            if(headerArray2.includes(thisObj.state.gridSearchResults.columns[0].Label)){
              bodyArr2.push(data[i].txtcolLocation)
            }
          }
          else{
            bodyArr2.push(data[i].txtcolLocation)
          } 

          if(!locationList.includes(data[i].txtcolLocation) && data[i].txtcolLocation !== "Total"){
            locationList.push(data[i].txtcolLocation)
          }
        }
        if(thisObj.state.gridSearchResults.columns[1].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[1].Label)){
            bodyArr1.push("W" + data[i].txtcolWhse)
            if(headerArray2.includes(thisObj.state.gridSearchResults.columns[1].Label)){
              bodyArr2.push("W" + data[i].txtcolWhse)
            }
          }
          else{
            bodyArr2.push("W" + data[i].txtcolWhse)
          }          

          if(!locationList.includes(data[i].txtcolWhse)){
            locationList.push("W" + data[i].txtcolWhse)
          }
        }
        if(thisObj.state.gridSearchResults.columns[2].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[2].Label)){
            bodyArr1.push("B" + data[i].txtcolBin)
            if(headerArray2.includes(thisObj.state.gridSearchResults.columns[2].Label)){
              bodyArr2.push("B" + data[i].txtcolBin)
            }
          }
          else{
            bodyArr2.push("B" + data[i].txtcolBin)
          }

          if(!locationList.includes(data[i].txtcolBin)){
            locationList.push("B" + data[i].txtcolBin)
          }
        }
        if(thisObj.state.gridSearchResults.columns[3].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[3].Label)){
            bodyArr1.push(data[i].txtcolPhysicalInventory)
          }
          else{
            bodyArr2.push(data[i].txtcolPhysicalInventory)
          }
        }
        if(thisObj.state.gridSearchResults.columns[4].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[4].Label)){
            bodyArr1.push(data[i].txtcolWhsReceiptObligated)
          }
          else{
            bodyArr2.push(data[i].txtcolWhsReceiptObligated)
          }
        }
        if(thisObj.state.gridSearchResults.columns[5].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[5].Label)){
            bodyArr1.push(data[i].txtcolUnReceiptedObligated)
          }
          else{
            bodyArr2.push(data[i].txtcolUnReceiptedObligated)
          }
        }
        if(thisObj.state.gridSearchResults.columns[6].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[6].Label)){
            bodyArr1.push(data[i].txtcolUnDeterminedObligated)
          }
          else{
            bodyArr2.push(data[i].txtcolUnDeterminedObligated)
          }
        }
        if(thisObj.state.gridSearchResults.columns[7].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[7].Label)){
            bodyArr1.push(data[i].txtcolInTransit)
          }
          else{
            bodyArr2.push(data[i].txtcolInTransit)
          }
        }
        if(thisObj.state.gridSearchResults.columns[8].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[8].Label)){
            bodyArr1.push(data[i].txtcolGovCapacity)
          }
          else{
            bodyArr2.push(data[i].txtcolGovCapacity)
          }
        }
        if(thisObj.state.gridSearchResults.columns[9].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[9].Label)){
            bodyArr1.push(data[i].txtcolCompanyOwned)
          }
          else{
            bodyArr2.push(data[i].txtcolCompanyOwned)
          }
        }
        if(thisObj.state.gridSearchResults.columns[10].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[10].Label)){
            bodyArr1.push(data[i].txtcolTotalOpenStorage)
          }
          else{
            bodyArr2.push(data[i].txtcolTotalOpenStorage)
          }
        }
        if(thisObj.state.gridSearchResults.columns[11].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[11].Label)){
            bodyArr1.push(data[i].txtcolPeanutType)
          }
          else{
            bodyArr2.push(data[i].txtcolPeanutType)
          }
        }
        if(thisObj.state.gridSearchResults.columns[12].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[12].Label)){
            bodyArr1.push(data[i].txtcolVariety)
          }
          else{
            bodyArr2.push(data[i].txtcolVariety)
          }
        }
        if(thisObj.state.gridSearchResults.columns[13].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[13].Label)){
            bodyArr1.push(data[i].txtcolGen)
          }
          else{
            bodyArr2.push(data[i].txtcolGen)
          }
        }
        if(thisObj.state.gridSearchResults.columns[14].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[14].Label)){
            bodyArr1.push(data[i].txtcolOleic)
          }
          else{
            bodyArr2.push(data[i].txtcolOleic)
          }
        }
        if(thisObj.state.gridSearchResults.columns[15].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[15].Label)){
            bodyArr1.push(data[i].txtcolSeg)
          }
          else{
            bodyArr2.push(data[i].txtcolSeg)
          }
        }
        if(thisObj.state.gridSearchResults.columns[16].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[16].Label)){
            bodyArr1.push(data[i].txtcolEdible)
          }
          else{
            bodyArr2.push(data[i].txtcolEdible)
          }
        }
        if(thisObj.state.gridSearchResults.columns[17].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[17].Label)){
            bodyArr1.push(data[i].txtcolCrush)
          }
          else{
            bodyArr2.push(data[i].txtcolCrush)
          }
        }
        if(thisObj.state.gridSearchResults.columns[18].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[18].Label)){
            bodyArr1.push(data[i].txtcolOrganic)
          }
          else{
            bodyArr2.push(data[i].txtcolOrganic)
          }
        }
        if(thisObj.state.gridSearchResults.columns[19].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[19].Label)){
            bodyArr1.push(data[i].txtcolSMK)
          }
          else{
            bodyArr2.push(data[i].txtcolSMK)
          }
        }
        if(thisObj.state.gridSearchResults.columns[20].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[20].Label)){
            bodyArr1.push(data[i].txtcolSS)
          }
          else{
            bodyArr2.push(data[i].txtcolSS)
          }
        }
        if(thisObj.state.gridSearchResults.columns[21].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[21].Label)){
            bodyArr1.push(data[i].txtcolOK)
          }
          else{
            bodyArr2.push(data[i].txtcolOK)
          }
        }
        if(thisObj.state.gridSearchResults.columns[22].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[22].Label)){
            bodyArr1.push(data[i].txtcolDAM)
          }
          else{
            bodyArr2.push(data[i].txtcolDAM)
          }
        }
        if(thisObj.state.gridSearchResults.columns[23].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[23].Label)){
            bodyArr1.push(data[i].txtcolTKC)
          }
          else{
            bodyArr2.push(data[i].txtcolTKC)
          }
        }
        if(thisObj.state.gridSearchResults.columns[24].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[24].Label)){
            bodyArr1.push(data[i].txtcolDCOL)
          }
          else{
            bodyArr2.push(data[i].txtcolDCOL)
          }
        }
        if(thisObj.state.gridSearchResults.columns[25].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[25].Label)){
            bodyArr1.push(data[i].txtcolELK)
          }
          else{
            bodyArr2.push(data[i].txtcolELK)
          }
        }
        if(thisObj.state.gridSearchResults.columns[26].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[26].Label)){
            bodyArr1.push(data[i].txtcolFM)
          }
          else{
            bodyArr2.push(data[i].txtcolFM)
          }
        }
        if(thisObj.state.gridSearchResults.columns[27].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[27].Label)){
            bodyArr1.push(data[i].txtcolLSK)
          }
          else{
            bodyArr2.push(data[i].txtcolLSK)
          }
        }
        if(thisObj.state.gridSearchResults.columns[28].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[28].Label)){
            bodyArr1.push(data[i].txtcolMoist)
          }
          else{
            bodyArr2.push(data[i].txtcolMoist)
          }
        }
        if(thisObj.state.gridSearchResults.columns[29].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[29].Label)){
            bodyArr1.push(data[i].txtcolHULL)
          }
          else{
            bodyArr2.push(data[i].txtcolHULL)
          }
        }
        if(thisObj.state.gridSearchResults.columns[30].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[30].Label)){
            bodyArr1.push(data[i].txtcolFRZ)
          }
          else{
            bodyArr2.push(data[i].txtcolFRZ)
          }
        }
        if(thisObj.state.gridSearchResults.columns[31].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[31].Label)){
            bodyArr1.push(data[i].txtcolPitDAM)
          }
          else{
            bodyArr2.push(data[i].txtcolPitDAM)
          }
        }
        if(thisObj.state.gridSearchResults.columns[32].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[32].Label)){
            bodyArr1.push(data[i].txtcolCRBR)
          }
          else{
            bodyArr2.push(data[i].txtcolCRBR)
          }
        }
        if(thisObj.state.gridSearchResults.columns[33].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[33].Label)){
            bodyArr1.push(data[i].txtcolHULLBr)
          }
          else{
            bodyArr2.push(data[i].txtcolHULLBr)
          }
        }
        if(thisObj.state.gridSearchResults.columns[34].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[34].Label)){
            bodyArr1.push(data[i].txtcolFAN)
          }
          else{
            bodyArr2.push(data[i].txtcolFAN)
          }
        }
        if(thisObj.state.gridSearchResults.columns[35].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[35].Label)){
            bodyArr1.push(data[i].txtcolCRMD)
          }
          else{
            bodyArr2.push(data[i].txtcolCRMD)
          }
        }
        if(thisObj.state.gridSearchResults.columns[36].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[36].Label)){
            bodyArr1.push(data[i].txtcolJumbo)
          }
          else{
            bodyArr2.push(data[i].txtcolJumbo)
          }
        }
        if(thisObj.state.gridSearchResults.columns[37].Visible){
          if(headerArray1.includes(thisObj.state.gridSearchResults.columns[37].Label)){
            bodyArr1.push(data[i].txtcolVicam)
          }
          else{
            bodyArr2.push(data[i].txtcolVicam)
          }
        }
        res1.push(bodyArr1)
        res2.push(bodyArr2)
      }
      
      if(headerArray2.length <= 3){
        headerArray2 = []
        res2 = []
      }

      let obj = new jsPDF();
      let n = 0
      let incrementValue = 23
      
      while(n < res1.length){
        if(n >= incrementValue){
          obj.addPage();
        }
        
        obj.autoTable({
          startX    : 6,
          startY    : 6,
          styles    : { fontSize : 5, lineWidth : 0.2, theme : 'striped' },
          margin    : { top : 5 , left : 5 },
          body      : res1.slice(n, n + incrementValue),
          theme     : 'plain',
          fontStyle : 'normal',
          head      : [ headerArray1 ],
          didParseCell: function(res){
            if(res.section == "head"){
              res.cell.styles.fillColor = [ 224, 224, 224 ]
              res.cell.styles.halign = "center"
            }
            if(locationList.includes(res?.cell?.raw) && res?.cell?.raw !== ""){
              res.cell.styles.fillColor = [ 224, 224, 224 ]
              if(['W', 'B'].includes(res?.cell?.raw?.slice(0,1))){
                res.cell.raw = res?.cell?.raw?.slice(1)
                res.cell.text = [ res?.cell?.raw ]
              }
            }
            if(!isNaN(res.cell.raw)){
              res.cell.styles.halign = "right"
            }
            else{
              if(!locationList.includes(res?.cell?.raw)){
                res.cell.styles.halign = "center"
              }
            }
            if(res?.cell?.text?.includes("Total")){
              res.row.cells[0].styles.fillColor = [ 224, 224, 224 ]
              res.row.cells[1].styles.fillColor = [ 224, 224, 224 ]
              res.row.cells[2].styles.fillColor = [ 224, 224, 224 ]
            }
          }
        });
        
        obj.autoTable({
          startX    : 275,
          startY    : 275,
          styles    : { fontSize : 8, lineWidth : 0.2, theme : 'striped', lineWidth : 'border = 0' },
          margin    : { top : 5 , left : 0.5 },
          body      : [ { datatable : sDocName.trim() + ' '.repeat((50 - sDocName.trim().length) <= 0 ? 0 : 50 - sDocName.trim().length) } ],
          theme     : 'plain',
          fontStyle : 'normal',
          head      : []
        });

        if(headerArray2.length > 0){
          obj.addPage();
          
          obj.autoTable({
            startX    : 6,
            startY    : 6,
            styles    : { fontSize : 5, lineWidth : 0.2, theme : 'striped' },
            margin    : { top : 5 , left : 5 },
            body      : res2.slice(n, n + incrementValue),
            theme     : 'plain',
            fontStyle : 'normal',
            head      : [ headerArray2 ],
            didParseCell: function(res){
              if(res.section == "head"){
                res.cell.styles.fillColor = [ 224, 224, 224 ]
                res.cell.styles.halign = "center"
              }
              if(locationList.includes(res?.cell?.raw) && res?.cell?.raw !== ""){
                res.cell.styles.fillColor = [ 224, 224, 224 ]
                if(['W', 'B'].includes(res?.cell?.raw?.slice(0,1))){
                  res.cell.raw = res?.cell?.raw?.slice(1)
                  res.cell.text = [ res?.cell?.raw ]
                }
              }
              if(!isNaN(res.cell.raw)){
                res.cell.styles.halign = "right"
              }
              if(res?.cell?.text?.includes("Total")){
                res.row.cells[0].styles.fillColor = [ 224, 224, 224 ]
                res.row.cells[1].styles.fillColor = [ 224, 224, 224 ]
                res.row.cells[2].styles.fillColor = [ 224, 224, 224 ]
              }
            }
          });

          obj.autoTable({
            startX    : 275,
            startY    : 275,
            styles    : { fontSize : 8, lineWidth : 0.2, theme : 'striped', lineWidth : 'border = 0' },
            margin    : { top : 5 , left : 0.5 },
            body      : [ { datatable : sDocName.trim() + ' '.repeat((50 - sDocName.trim().length) <= 0 ? 0 : 50 - sDocName.trim().length) } ],
            theme     : 'plain',
            fontStyle : 'normal',
            head      : []
          });
        }

        n = n + incrementValue;
      }
      
      for(var i=0; i < obj?.internal?.getNumberOfPages(); i++){
        obj.setPage(i+1);
        obj.setFontSize(8)
        obj.text(`${(i+1).toString()}`, obj?.internal?.pageSize?.width - 5, obj?.internal?.pageSize?.height - 15 )
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Failed to print , please provide proper name to file")
        }
      }
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnExportToExcelClick = async() => {
    try{
      let n = 0;
      let intFileNumber = 0;
      let strSourcePath = "Inventory Search.xlsx"

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      let headerArray = []
      for(var i = 0; i < thisObj.state.gridSearchResults.columns.length; i++){
        if(thisObj.state.gridSearchResults.columns[i].Visible){
          headerArray.push(thisObj.state.gridSearchResults.columns[i].Label)
        }
      }
      
      let excelData = []
      let data = getData(thisObj, 'gridSearchResults')
      
      if([ undefined, null ].includes(data)){
        return;
      }
      
      for(var i = 0; i< data.length; i++){ 
        let rowData = {}       
        if(thisObj.state.gridSearchResults.columns[0].Visible){
          rowData[thisObj.state.gridSearchResults.columns[0].Label] = data[i].txtcolLocation
        }
        if(thisObj.state.gridSearchResults.columns[1].Visible){
          rowData[thisObj.state.gridSearchResults.columns[1].Label] = ExcelNumericDataConvert(data[i].txtcolWhse)
        }
        if(thisObj.state.gridSearchResults.columns[2].Visible){
          rowData[thisObj.state.gridSearchResults.columns[2].Label] = ExcelNumericDataConvert(data[i].txtcolBin)
        }
        if(thisObj.state.gridSearchResults.columns[3].Visible){
          rowData[thisObj.state.gridSearchResults.columns[3].Label] = ExcelNumericDataConvert(data[i].txtcolPhysicalInventory)
        }
        if(thisObj.state.gridSearchResults.columns[4].Visible){
          rowData[thisObj.state.gridSearchResults.columns[4].Label] = ExcelNumericDataConvert(data[i].txtcolWhsReceiptObligated)
        }
        if(thisObj.state.gridSearchResults.columns[5].Visible){
          rowData[thisObj.state.gridSearchResults.columns[5].Label] = ExcelNumericDataConvert(data[i].txtcolUnReceiptedObligated)
        }
        if(thisObj.state.gridSearchResults.columns[6].Visible){
          rowData[thisObj.state.gridSearchResults.columns[6].Label] = ExcelNumericDataConvert(data[i].txtcolUnDeterminedObligated)
        }
        if(thisObj.state.gridSearchResults.columns[7].Visible){
          rowData[thisObj.state.gridSearchResults.columns[7].Label] = ExcelNumericDataConvert(data[i].txtcolInTransit)
        }
        if(thisObj.state.gridSearchResults.columns[8].Visible){
          rowData[thisObj.state.gridSearchResults.columns[8].Label] = ExcelNumericDataConvert(data[i].txtcolGovCapacity)
        }
        if(thisObj.state.gridSearchResults.columns[9].Visible){
          rowData[thisObj.state.gridSearchResults.columns[9].Label] = ExcelNumericDataConvert(data[i].txtcolCompanyOwned)
        }
        if(thisObj.state.gridSearchResults.columns[10].Visible){
          rowData[thisObj.state.gridSearchResults.columns[10].Label] = ExcelNumericDataConvert(data[i].txtcolTotalOpenStorage)
        }
        if(thisObj.state.gridSearchResults.columns[11].Visible){
          rowData[thisObj.state.gridSearchResults.columns[11].Label] = data[i].txtcolPeanutType
        }
        if(thisObj.state.gridSearchResults.columns[12].Visible){
          rowData[thisObj.state.gridSearchResults.columns[12].Label] = data[i].txtcolVariety
        }
        if(thisObj.state.gridSearchResults.columns[13].Visible){
          rowData[thisObj.state.gridSearchResults.columns[13].Label] = data[i].txtcolGen
        }
        if(thisObj.state.gridSearchResults.columns[14].Visible){
          rowData[thisObj.state.gridSearchResults.columns[14].Label] = data[i].txtcolOleic
        }
        if(thisObj.state.gridSearchResults.columns[15].Visible){
          rowData[thisObj.state.gridSearchResults.columns[15].Label] = data[i].txtcolSeg
        }
        if(thisObj.state.gridSearchResults.columns[16].Visible){
          rowData[thisObj.state.gridSearchResults.columns[16].Label] = data[i].txtcolEdible
        }
        if(thisObj.state.gridSearchResults.columns[17].Visible){
          rowData[thisObj.state.gridSearchResults.columns[17].Label] = data[i].txtcolCrush
        }
        if(thisObj.state.gridSearchResults.columns[18].Visible){
          rowData[thisObj.state.gridSearchResults.columns[18].Label] = data[i].txtcolOrganic
        }
        if(thisObj.state.gridSearchResults.columns[19].Visible){
          rowData[thisObj.state.gridSearchResults.columns[19].Label] = ExcelNumericDataConvert(data[i].txtcolSMK)
        }
        if(thisObj.state.gridSearchResults.columns[20].Visible){
          rowData[thisObj.state.gridSearchResults.columns[20].Label] = ExcelNumericDataConvert(data[i].txtcolSS)
        }
        if(thisObj.state.gridSearchResults.columns[21].Visible){
          rowData[thisObj.state.gridSearchResults.columns[21].Label] = ExcelNumericDataConvert(data[i].txtcolOK)
        }
        if(thisObj.state.gridSearchResults.columns[22].Visible){
          rowData[thisObj.state.gridSearchResults.columns[22].Label] = ExcelNumericDataConvert(data[i].txtcolDAM)
        }
        if(thisObj.state.gridSearchResults.columns[23].Visible){
          rowData[thisObj.state.gridSearchResults.columns[23].Label] = ExcelNumericDataConvert(data[i].txtcolTKC)
        }
        if(thisObj.state.gridSearchResults.columns[24].Visible){
          rowData[thisObj.state.gridSearchResults.columns[24].Label] = ExcelNumericDataConvert(data[i].txtcolDCOL)
        }
        if(thisObj.state.gridSearchResults.columns[25].Visible){
          rowData[thisObj.state.gridSearchResults.columns[25].Label] = ExcelNumericDataConvert(data[i].txtcolELK)
        }
        if(thisObj.state.gridSearchResults.columns[26].Visible){
          rowData[thisObj.state.gridSearchResults.columns[26].Label] = ExcelNumericDataConvert(data[i].txtcolFM)
        }
        if(thisObj.state.gridSearchResults.columns[27].Visible){
          rowData[thisObj.state.gridSearchResults.columns[27].Label] = ExcelNumericDataConvert(data[i].txtcolLSK)
        }
        if(thisObj.state.gridSearchResults.columns[28].Visible){
          rowData[thisObj.state.gridSearchResults.columns[28].Label] = ExcelNumericDataConvert(data[i].txtcolMoist)
        }
        if(thisObj.state.gridSearchResults.columns[29].Visible){
          rowData[thisObj.state.gridSearchResults.columns[29].Label] = ExcelNumericDataConvert(data[i].txtcolHULL)
        }
        if(thisObj.state.gridSearchResults.columns[30].Visible){
          rowData[thisObj.state.gridSearchResults.columns[30].Label] = ExcelNumericDataConvert(data[i].txtcolFRZ)
        }
        if(thisObj.state.gridSearchResults.columns[31].Visible){
          rowData[thisObj.state.gridSearchResults.columns[31].Label] = ExcelNumericDataConvert(data[i].txtcolPitDAM)
        }
        if(thisObj.state.gridSearchResults.columns[32].Visible){
          rowData[thisObj.state.gridSearchResults.columns[32].Label] = ExcelNumericDataConvert(data[i].txtcolCRBR)
        }
        if(thisObj.state.gridSearchResults.columns[33].Visible){
          rowData[thisObj.state.gridSearchResults.columns[33].Label] = ExcelNumericDataConvert(data[i].txtcolHULLBr)
        }
        if(thisObj.state.gridSearchResults.columns[34].Visible){
          rowData[thisObj.state.gridSearchResults.columns[34].Label] = ExcelNumericDataConvert(data[i].txtcolFAN)
        }
        if(thisObj.state.gridSearchResults.columns[35].Visible){
          rowData[thisObj.state.gridSearchResults.columns[35].Label] = ExcelNumericDataConvert(data[i].txtcolCRMD)
        }
        if(thisObj.state.gridSearchResults.columns[36].Visible){
          rowData[thisObj.state.gridSearchResults.columns[36].Label] = ExcelNumericDataConvert(data[i].txtcolJumbo)
        }
        if(thisObj.state.gridSearchResults.columns[37].Visible){
          rowData[thisObj.state.gridSearchResults.columns[37].Label] = ExcelNumericDataConvert(data[i].txtcolVicam)
        }
        excelData.push(rowData)
      }
      
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const dataBlob = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(dataBlob, strSourcePath);
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnCloseClick = async() => {
    try{
      goTo(thisObj, "ContractManagement#MainMenu#DEFAULT#false#Click")
      return true;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  function errorHandler(err){
    showMessage(thisObj, err.message)
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_InventoryInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_InventoryInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxInventoryInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxInventoryInquiry*/}

              <GroupBoxWidget
                conf={state.grpbxInventoryInquiry}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxLocation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLocation*/}

                <GroupBoxWidget conf={state.grpbxLocation} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLocation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLocation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLocation*/}

                  {/* END_USER_CODE-USER_AFTER_lblLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                  {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddArea}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddArea*/}

                  {/* END_USER_CODE-USER_AFTER_ddArea*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCollectionPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddWarehouseNum*/}

                  {/* END_USER_CODE-USER_BEFORE_ddWarehouseNum*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddWarehouseNum}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddWarehouseNum*/}

                  {/* END_USER_CODE-USER_AFTER_ddWarehouseNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBinNum*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBinNum*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBinNum}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBinNum*/}

                  {/* END_USER_CODE-USER_AFTER_ddBinNum*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxGrpByBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxGrpByBuyPt*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxGrpByBuyPt}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxGrpByBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxGrpByBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxShowGrades*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxShowGrades*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxShowGrades}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxShowGrades*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxShowGrades*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLocation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLocation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPeanutCriteria*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPeanutCriteria*/}

                <GroupBoxWidget
                  conf={state.grpbxPeanutCriteria}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPeanutCriteria*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPeanutCriteria*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPeanutCriteria}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPeanutCriteria*/}

                  {/* END_USER_CODE-USER_AFTER_lblPeanutCriteria*/}
                  {/* START_USER_CODE-USER_BEFORE_ddType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddType*/}

                  {/* END_USER_CODE-USER_AFTER_ddType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeed}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGen*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGen*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGen}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGen*/}

                  {/* END_USER_CODE-USER_AFTER_ddGen*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOrganic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOrganic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOrganic*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxEdibleCrushing*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxEdibleCrushing*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxEdibleCrushing}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxEdibleCrushing*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxEdibleCrushing*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPeanutCriteria*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPeanutCriteria*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSearchResults*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSearchResults*/}

                <GroupBoxWidget
                  conf={state.grpbxSearchResults}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSearchResults*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSearchResults*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSearchResults}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSearchResults*/}

                  {/* END_USER_CODE-USER_AFTER_lblSearchResults*/}
                  {/* START_USER_CODE-USER_BEFORE_btnMakeAdjustments*/}

                  {/* END_USER_CODE-USER_BEFORE_btnMakeAdjustments*/}

                  <ButtonWidget
                    conf={state.btnMakeAdjustments}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnMakeAdjustments*/}

                  {/* END_USER_CODE-USER_AFTER_btnMakeAdjustments*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_gridSearchResults*/}

                  {/* END_USER_CODE-USER_BEFORE_gridSearchResults*/}

                  <GridWidget
                    conf={state.gridSearchResults}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridSearchResults}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridSearchResults*/}

                  {/* END_USER_CODE-USER_AFTER_gridSearchResults*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSearchResults*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSearchResults*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxInventoryInquiry*/}

              {/* END_USER_CODE-USER_AFTER_grpbxInventoryInquiry*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_InventoryInquiry*/}

              {/* END_USER_CODE-USER_AFTER_InventoryInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_InventoryInquiry);