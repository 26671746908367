/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ViewEWRLog from "./ViewEWRLog";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewEWRLog Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewEWRLog />);
    });
  });
  afterEach(cleanup);
  test("is ViewEWRLog Loads Successfully", () => {
    expect(screen.getByText("ViewEWRLog")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewEWRLog", () => {
    // START_USER_CODE-USER_ViewEWRLog_Custom_Test_Case
    // END_USER_CODE-USER_ViewEWRLog_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewEWRLog />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnSendInquiry(Button Widget) Test Cases", async () => {
    const btnSendInquiry = screen.getByTestId("btnSendInquiry");
    expect(btnSendInquiry).toBeInTheDocument;
    expect(btnSendInquiry.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_btnSendInquiry")
    );
  });
  test("Custom Test Cases for btnSendInquiry", () => {
    // START_USER_CODE-USER_btnSendInquiry_TEST
    // END_USER_CODE-USER_btnSendInquiry_TEST
  });
  test("btnSendUpdate(Button Widget) Test Cases", async () => {
    const btnSendUpdate = screen.getByTestId("btnSendUpdate");
    expect(btnSendUpdate).toBeInTheDocument;
    expect(btnSendUpdate.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_btnSendUpdate")
    );
  });
  test("Custom Test Cases for btnSendUpdate", () => {
    // START_USER_CODE-USER_btnSendUpdate_TEST
    // END_USER_CODE-USER_btnSendUpdate_TEST
  });
  test("btnViewInquiry(Button Widget) Test Cases", async () => {
    const btnViewInquiry = screen.getByTestId("btnViewInquiry");
    expect(btnViewInquiry).toBeInTheDocument;
    expect(btnViewInquiry.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_btnViewInquiry")
    );
  });
  test("Custom Test Cases for btnViewInquiry", () => {
    // START_USER_CODE-USER_btnViewInquiry_TEST
    // END_USER_CODE-USER_btnViewInquiry_TEST
  });
  test("btnViewUpdate(Button Widget) Test Cases", async () => {
    const btnViewUpdate = screen.getByTestId("btnViewUpdate");
    expect(btnViewUpdate).toBeInTheDocument;
    expect(btnViewUpdate.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_btnViewUpdate")
    );
  });
  test("Custom Test Cases for btnViewUpdate", () => {
    // START_USER_CODE-USER_btnViewUpdate_TEST
    // END_USER_CODE-USER_btnViewUpdate_TEST
  });
  test("gridEWRLog(Grid Widget) Test Cases", async () => {
    let gridEWRLog = screen.getByTestId("gridEWRLog");
    let gridEWRLogbtn = gridEWRLog.nextElementSibling.firstElementChild;
    gridEWRLog = gridEWRLog.parentElement.parentElement.parentElement;
    expect(gridEWRLog.tagName).toBe("DIV");
    expect(gridEWRLog.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridEWRLog", () => {
    // START_USER_CODE-USER_gridEWRLog_TEST
    // END_USER_CODE-USER_gridEWRLog_TEST
  });
  test("grpbxViewEWRLog(GroupBox Widget) Test Cases", async () => {
    const grpbxViewEWRLog = screen.getByTestId("grpbxViewEWRLog");
    expect(grpbxViewEWRLog.tagName).toBe("BUTTON");
    expect(grpbxViewEWRLog.type).toBe("button");
    expect(grpbxViewEWRLog.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewEWRLog", () => {
    // START_USER_CODE-USER_grpbxViewEWRLog_TEST
    // END_USER_CODE-USER_grpbxViewEWRLog_TEST
  });
  test("grpbxViewEWRLogDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxViewEWRLogDetails = screen.getByTestId("grpbxViewEWRLogDetails");
    expect(grpbxViewEWRLogDetails.tagName).toBe("BUTTON");
    expect(grpbxViewEWRLogDetails.type).toBe("button");
    expect(grpbxViewEWRLogDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewEWRLogDetails", () => {
    // START_USER_CODE-USER_grpbxViewEWRLogDetails_TEST
    // END_USER_CODE-USER_grpbxViewEWRLogDetails_TEST
  });
  test("gridEWRLog_txtcolAction(Grid Widget) Test Cases", async () => {
    let gridEWRLog_txtcolAction = screen.getByTestId("gridEWRLog");
    let gridEWRLog_txtcolActionbtn =
      gridEWRLog_txtcolAction.nextElementSibling.firstElementChild;
    gridEWRLog_txtcolAction =
      gridEWRLog_txtcolAction.parentElement.parentElement.parentElement;
    expect(gridEWRLog_txtcolAction.tagName).toBe("DIV");
    expect(gridEWRLog_txtcolAction.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAction", () => {
    // START_USER_CODE-USER_txtcolAction_TEST
    // END_USER_CODE-USER_txtcolAction_TEST
  });
  test("gridEWRLog_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridEWRLog_txtcolBuyingPoint = screen.getByTestId("gridEWRLog");
    let gridEWRLog_txtcolBuyingPointbtn =
      gridEWRLog_txtcolBuyingPoint.nextElementSibling.firstElementChild;
    gridEWRLog_txtcolBuyingPoint =
      gridEWRLog_txtcolBuyingPoint.parentElement.parentElement.parentElement;
    expect(gridEWRLog_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridEWRLog_txtcolBuyingPoint.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridEWRLog_txtcolCompID(Grid Widget) Test Cases", async () => {
    let gridEWRLog_txtcolCompID = screen.getByTestId("gridEWRLog");
    let gridEWRLog_txtcolCompIDbtn =
      gridEWRLog_txtcolCompID.nextElementSibling.firstElementChild;
    gridEWRLog_txtcolCompID =
      gridEWRLog_txtcolCompID.parentElement.parentElement.parentElement;
    expect(gridEWRLog_txtcolCompID.tagName).toBe("DIV");
    expect(gridEWRLog_txtcolCompID.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCompID", () => {
    // START_USER_CODE-USER_txtcolCompID_TEST
    // END_USER_CODE-USER_txtcolCompID_TEST
  });
  test("gridEWRLog_txtcolEntryDate(Grid Widget) Test Cases", async () => {
    let gridEWRLog_txtcolEntryDate = screen.getByTestId("gridEWRLog");
    let gridEWRLog_txtcolEntryDatebtn =
      gridEWRLog_txtcolEntryDate.nextElementSibling.firstElementChild;
    gridEWRLog_txtcolEntryDate =
      gridEWRLog_txtcolEntryDate.parentElement.parentElement.parentElement;
    expect(gridEWRLog_txtcolEntryDate.tagName).toBe("DIV");
    expect(gridEWRLog_txtcolEntryDate.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEntryDate", () => {
    // START_USER_CODE-USER_txtcolEntryDate_TEST
    // END_USER_CODE-USER_txtcolEntryDate_TEST
  });
  test("gridEWRLog_txtcolLogEntry(Grid Widget) Test Cases", async () => {
    let gridEWRLog_txtcolLogEntry = screen.getByTestId("gridEWRLog");
    let gridEWRLog_txtcolLogEntrybtn =
      gridEWRLog_txtcolLogEntry.nextElementSibling.firstElementChild;
    gridEWRLog_txtcolLogEntry =
      gridEWRLog_txtcolLogEntry.parentElement.parentElement.parentElement;
    expect(gridEWRLog_txtcolLogEntry.tagName).toBe("DIV");
    expect(gridEWRLog_txtcolLogEntry.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLogEntry", () => {
    // START_USER_CODE-USER_txtcolLogEntry_TEST
    // END_USER_CODE-USER_txtcolLogEntry_TEST
  });
  test("gridEWRLog_txtcolReceiptNum(Grid Widget) Test Cases", async () => {
    let gridEWRLog_txtcolReceiptNum = screen.getByTestId("gridEWRLog");
    let gridEWRLog_txtcolReceiptNumbtn =
      gridEWRLog_txtcolReceiptNum.nextElementSibling.firstElementChild;
    gridEWRLog_txtcolReceiptNum =
      gridEWRLog_txtcolReceiptNum.parentElement.parentElement.parentElement;
    expect(gridEWRLog_txtcolReceiptNum.tagName).toBe("DIV");
    expect(gridEWRLog_txtcolReceiptNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptNum", () => {
    // START_USER_CODE-USER_txtcolReceiptNum_TEST
    // END_USER_CODE-USER_txtcolReceiptNum_TEST
  });
  test("txtReceiptNumber(Textbox Widget) Test Cases", async () => {
    const txtReceiptNumber = screen.getByTestId("txtReceiptNumber");
    expect(txtReceiptNumber.tagName).toBe("INPUT");
    expect(txtReceiptNumber.type).toBe("text");
    expect(txtReceiptNumber.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ViewEWRLog_txtReceiptNumber")
    );
    await act(async () => {
      userEvent.type(txtReceiptNumber, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNumber", () => {
    // START_USER_CODE-USER_txtReceiptNumber_TEST
    // END_USER_CODE-USER_txtReceiptNumber_TEST
  });
  test("gridEWRLog_tztcolCropYear(Grid Widget) Test Cases", async () => {
    let gridEWRLog_tztcolCropYear = screen.getByTestId("gridEWRLog");
    let gridEWRLog_tztcolCropYearbtn =
      gridEWRLog_tztcolCropYear.nextElementSibling.firstElementChild;
    gridEWRLog_tztcolCropYear =
      gridEWRLog_tztcolCropYear.parentElement.parentElement.parentElement;
    expect(gridEWRLog_tztcolCropYear.tagName).toBe("DIV");
    expect(gridEWRLog_tztcolCropYear.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:ViewEWRLog_gridEWRLog")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for tztcolCropYear", () => {
    // START_USER_CODE-USER_tztcolCropYear_TEST
    // END_USER_CODE-USER_tztcolCropYear_TEST
  });
});
