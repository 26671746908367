/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  hideColumn,
  getValue,
  goTo,
  setData,
  getData,
  enable,
  disable,
  getSelectedRowNumber,
} from "../../shared/WindowImports";

import "./PriceScheduleAuditList.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_PriceScheduleAuditList(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PriceScheduleAuditList",
    windowName: "PriceScheduleAuditList",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.PriceScheduleAuditList",
    // START_USER_CODE-USER_PriceScheduleAuditList_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Price Schedule Audit History List",
      scrCode: "PN0070C",
    },
    // END_USER_CODE-USER_PriceScheduleAuditList_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnView: {
      name: "btnView",
      type: "ButtonWidget",
      parent: "grpbxPriceScheduleAuditList",
      Label: "View",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView_PROPERTIES

      // END_USER_CODE-USER_btnView_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditList",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colKeyId: {
      name: "colKeyId",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditList",
      Label: "Key ID",
      Height: "",
      Width: "",

    },
    colChangeDateTime: {
      name: "colChangeDateTime",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditList",
      Label: "Change Date/Time",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChangeDateTime_PROPERTIES

      // END_USER_CODE-USER_colChangeDateTime_PROPERTIES
    },
    colChangeUser: {
      name: "colChangeUser",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditList",
      Label: "Change User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChangeUser_PROPERTIES

      // END_USER_CODE-USER_colChangeUser_PROPERTIES
    },
    colRevision: {
      name: "colRevision",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditList",
      Label: "Revision",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRevision_PROPERTIES

      // END_USER_CODE-USER_colRevision_PROPERTIES
    },
    gridPriceScheduleAuditList: {
      name: "gridPriceScheduleAuditList",
      type: "GridWidget",
      parent: "grpbxPriceScheduleAuditList",
      gridCellsOrder:
        "txtcolKeyId,txtcolActions,txtcolRevision,txtcolChangeUser,txtcolChangeDateTime",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPriceScheduleAuditList_PROPERTIES

      // END_USER_CODE-USER_gridPriceScheduleAuditList_PROPERTIES
    },
    txtcolKeyId: {
      name: "txtcolKeyId",
      type: "TextBoxWidget",
      colName: "colKeyId",
      parent: "gridPriceScheduleAuditList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolActions: {
      name: "txtcolActions",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridPriceScheduleAuditList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolActions_PROPERTIES

      // END_USER_CODE-USER_txtcolActions_PROPERTIES
    },
    txtcolChangeDateTime: {
      name: "txtcolChangeDateTime",
      type: "TextBoxWidget",
      colName: "colChangeDateTime",
      parent: "gridPriceScheduleAuditList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChangeDateTime_PROPERTIES

      // END_USER_CODE-USER_txtcolChangeDateTime_PROPERTIES
    },
    txtcolChangeUser: {
      name: "txtcolChangeUser",
      type: "TextBoxWidget",
      colName: "colChangeUser",
      parent: "gridPriceScheduleAuditList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChangeUser_PROPERTIES

      // END_USER_CODE-USER_txtcolChangeUser_PROPERTIES
    },
    txtcolRevision: {
      name: "txtcolRevision",
      type: "TextBoxWidget",
      colName: "colRevision",
      parent: "gridPriceScheduleAuditList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRevision_PROPERTIES

      // END_USER_CODE-USER_txtcolRevision_PROPERTIES
    },
    grpbxPriceScheduleAuditList: {
      name: "grpbxPriceScheduleAuditList",
      type: "GroupBoxWidget",
      parent: "PriceScheduleAuditList",
      Height: "",
      Width: "",
      clonedExtId: "120097",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPriceScheduleAuditList_PROPERTIES

      // END_USER_CODE-USER_grpbxPriceScheduleAuditList_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "PriceScheduleAuditList",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#PriceScheduleAuditDetail": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#PriceScheduleAuditDetail": {}
      }
    }
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnView:{
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#PriceScheduleAuditDetail#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const FormLoad = () => {
    openGrid();
    setLoading(true);

  };
  const openGrid = async () => {
   try {

    setLoading(true);
    let response = await SystemMaintenanceSpecialFunctionsService.UpdatePriceScheduleAudits('INQUIRY',null)
    let data1 = response;
    let data = data1.result;
    let TransformedRowsArray = [];
    let obj = {};
    for (var i = 0; i < data.length; i++) {
      hideColumn(thisObj, "gridPriceScheduleAuditList", 'txtcolKeyId')
      obj.txtcolKeyId = data[i].history_key_id;
      obj.txtcolActions = data[i].history_action;
      obj.txtcolRevision = data[i].revision_num;
      obj.txtcolChangeUser = data[i].chg_user;
      obj.txtcolChangeDateTime = moment(data[i].chg_date_time).format("M/D/YYYY h:mm:ss A");
      TransformedRowsArray.push(obj)
      obj = {}
    }
    if (TransformedRowsArray.length>1){
      enable(thisObj,"btnView")
    }
    else{
      disable(thisObj,"btnView")
    }
    setValue(thisObj, "gridPriceScheduleAuditList", TransformedRowsArray);
    setLoading(false);
    }

    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
      }

      return false
    }
    return true
  }
  // row selection
  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, 'gridPriceScheduleAuditList');
    if (newRowNum !== undefined && selectedRowNum !== undefined && gridData?.length) {
      gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return(newRowNum)
    }
  }
  
  const ongridManualRTPHistRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, 'gridPriceScheduleAuditList');
    selectRow(rowNum);
  }
  thisObj.ongridManualRTPHistRowSelect = ongridManualRTPHistRowSelect
// view button 
  const onbtnViewClick = () => {
    try {
      let gridData = getValue(thisObj, 'gridPriceScheduleAuditList');
        let rowNum = getSelectedRowNumber(thisObj, 'gridPriceScheduleAuditList');
         if (rowNum == undefined) {
        rowNum = 0;
        return;
         }
      let frmPriceScheduleAuditDetail = {}
      frmPriceScheduleAuditDetail.lblKey_ID_Caption = gridData[rowNum].txtcolKeyId,
      
      setData(thisObj, "frmPriceScheduleAuditDetail", frmPriceScheduleAuditDetail)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#PriceScheduleAuditDetail#DEFAULT#true#Click")
      return true
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }
  };
  thisObj.onbtnViewClick = onbtnViewClick;
  // close button
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_PriceScheduleAuditListPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PriceScheduleAuditList*/}

              {/* END_USER_CODE-USER_BEFORE_PriceScheduleAuditList*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPriceScheduleAuditList*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPriceScheduleAuditList*/}

              <GroupBoxWidget
                conf={state.grpbxPriceScheduleAuditList}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnView*/}

                {/* END_USER_CODE-USER_BEFORE_btnView*/}

                <ButtonWidget
                  conf={state.btnView}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnView*/}

                {/* END_USER_CODE-USER_AFTER_btnView*/}
                {/* START_USER_CODE-USER_BEFORE_gridPriceScheduleAuditList*/}

                {/* END_USER_CODE-USER_BEFORE_gridPriceScheduleAuditList*/}

                {/* <GridWidget
                  conf={state.gridPriceScheduleAuditList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPriceScheduleAuditList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget> */}
                <GridWidget

                  conf={state.gridPriceScheduleAuditList}

                  screenConf={state}

                  linkClick={event => {

                    if (state.gridPriceScheduleAuditList.selected.length > 0) {

                      thisObj.selectedRow = state.gridPriceScheduleAuditList.selected[0];

                    }

                    invokeEventHandler(event, handleChange, handleBlur, thisObj)

                  }

                  }

                  errors={errors}

                  touched={touched}

                  rows={values.gridPriceScheduleAuditList}

                  onEvent={event =>

                    invokeEventHandler(event, handleChange, handleBlur, thisObj)

                  }

                  onRowChange={selectedRow => onGridRowChange(selectedRow)}

                  setFieldValue={setFieldValue}

                  refObject={thisObj}

                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPriceScheduleAuditList*/}

                {/* END_USER_CODE-USER_AFTER_gridPriceScheduleAuditList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPriceScheduleAuditList*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPriceScheduleAuditList*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_PriceScheduleAuditList*/}

              {/* END_USER_CODE-USER_AFTER_PriceScheduleAuditList*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_PriceScheduleAuditList
);
