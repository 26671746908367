/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  getData,
  setData,
  setValue,
  getValue,
  goTo,
} from "../../shared/WindowImports";

import "./NullifyCheck.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_NullifyCheck(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "NullifyCheck",
    windowName: "NullifyCheck",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.NullifyCheck",
    // START_USER_CODE-USER_NullifyCheck_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Spoiled Check Entry",
      scrCode: "PN1100C",
    },
    // END_USER_CODE-USER_NullifyCheck_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnSpoilChecks: {
      name: "btnSpoilChecks",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Spoil Checks",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSpoilChecks_PROPERTIES

      // END_USER_CODE-USER_btnSpoilChecks_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails",
      Label: "Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    lblEnterCheckNumber: {
      name: "lblEnterCheckNumber",
      type: "LabelWidget",
      parent: "grpbxCheckDetails",
      Label: "Enter the check numbers for voiding:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEnterCheckNumber_PROPERTIES

      // END_USER_CODE-USER_lblEnterCheckNumber_PROPERTIES
    },
    txtCheckNum1: {
      name: "txtCheckNum1",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum1_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum1_PROPERTIES
    },
    txtCheckNum2: {
      name: "txtCheckNum2",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum2_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum2_PROPERTIES
    },
    txtCheckNum3: {
      name: "txtCheckNum3",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum3_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum3_PROPERTIES
    },
    txtCheckNum4: {
      name: "txtCheckNum4",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum4_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum4_PROPERTIES
    },
    txtCheckNum5: {
      name: "txtCheckNum5",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum5_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum5_PROPERTIES
    },
    txtCheckNum6: {
      name: "txtCheckNum6",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum6_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum6_PROPERTIES
    },
    txtCheckNum7: {
      name: "txtCheckNum7",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum7_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum7_PROPERTIES
    },
    txtCheckNum8: {
      name: "txtCheckNum8",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum8_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum8_PROPERTIES
    },
    grpbxCheckDetails: {
      name: "grpbxCheckDetails",
      type: "GroupBoxWidget",
      parent: "NullifyCheck",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCheckDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckDetails_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "NullifyCheck",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},

      },
      REVERSE: {
        "ContractManagement#MainMenu": {},

      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnCancel: {
      DEFAULT: [
        "ContractManagement#MainMenu#DEFAULT#false#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
  }, []);


  useEffect(() => {
    formLoad()
  }, []);

  // START_USER_CODE-USER_METHODS
  const formLoad = () => {

    if (getData(thisObj, 'isBFillvalid') == false) {
      return
    }

    bFillBuyingPointList();
  }

  const bFillBuyingPointList = () => {
    let js = []
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null).then(response => {
      let data = response
      if (data == null || data == undefined || data.length <= 0) {
        setData(thisObj, 'isBFillvalid', false)
      }
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddLocation', js.at(0).key);
    })
    setData(thisObj, "isBFillvalid", true)
  }

  const ontxtCheckNum1Change = () => {
    setValue(thisObj, "txtCheckNum1", txtCheckNum1.value)
  }
  thisObj.ontxtCheckNum1Change = ontxtCheckNum1Change

  const ontxtCheckNum2Change = () => {
    setValue(thisObj, "txtCheckNum2", txtCheckNum2.value)
  }
  thisObj.ontxtCheckNum2Change = ontxtCheckNum2Change


  const ontxtCheckNum3Change = () => {
    setValue(thisObj, "txtCheckNum3", txtCheckNum3.value)
  }
  thisObj.ontxtCheckNum3Change = ontxtCheckNum3Change


  const ontxtCheckNum4Change = () => {
    setValue(thisObj, "txtCheckNum4", txtCheckNum4.value)
  }
  thisObj.ontxtCheckNum4Change = ontxtCheckNum4Change


  const ontxtCheckNum5Change = () => {
    setValue(thisObj, "txtCheckNum5", txtCheckNum5.value)
  }
  thisObj.ontxtCheckNum5Change = ontxtCheckNum5Change


  const ontxtCheckNum6Change = () => {
    setValue(thisObj, "txtCheckNum6", txtCheckNum6.value)
  }
  thisObj.ontxtCheckNum6Change = ontxtCheckNum6Change


  const ontxtCheckNum7Change = () => {
    setValue(thisObj, "txtCheckNum7", txtCheckNum7.value)
  }
  thisObj.ontxtCheckNum7Change = ontxtCheckNum7Change


  const ontxtCheckNum8Change = () => {
    setValue(thisObj, "txtCheckNum8", txtCheckNum8.value)
  }
  thisObj.ontxtCheckNum8Change = ontxtCheckNum8Change


  // const validatetxtCheckNumBox = (val) => {
  //   var res = ''
  //   for (var i = 0; i < val.length; i++) {
  //     if (val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) {
  //       res += val[i]
  //     }
  //   }
  //   return res;
  // }

  const onbtnSpoilChecksClick = async () => {
    try {
      let arr = []

      let buy_pt_id = getValue(thisObj, 'ddLocation')

      let check1 = getValue(thisObj, "txtCheckNum1")
      let check2 = getValue(thisObj, "txtCheckNum2")
      let check3 = getValue(thisObj, "txtCheckNum3")
      let check4 = getValue(thisObj, "txtCheckNum4")
      let check5 = getValue(thisObj, "txtCheckNum5")
      let check6 = getValue(thisObj, "txtCheckNum6")
      let check7 = getValue(thisObj, "txtCheckNum7")
      let check8 = getValue(thisObj, "txtCheckNum8")

      if (check1 != "" && check1 != undefined)
        arr.push(check1)

      if (check2 != "" && check2 != undefined)
        arr.push(check2)

      if (check3 != "" && check3 != undefined)
        arr.push(check3)

      if (check4 != "" && check4 != undefined)
        arr.push(check4)

      if (check5 != "" && check5 != undefined)
        arr.push(check5)

      if (check6 != "" && check6 != undefined)
        arr.push(check6)

      if (check7 != "" && check7 != undefined)
        arr.push(check7)

      if (check8 != "" && check8 != undefined)
        arr.push(check8)

      let uidObj = {
        uid : sessionStorage.getItem("userid"),
      }

      if (arr.length > 0) {
        arr.sort()

        for (var i = 0; i < 8; i++) {
          setValue(thisObj, `txtCheckNum${i + 1}`, "")
        }

        for (var k = 0; k < arr.length; k++) {
          setValue(thisObj, `txtCheckNum${k + 1}`, arr[k])
        }

        for (var j = 0; j < arr.length; j++) {
          if (!isNaN(arr[j])) {
          let response = await AccountPayment.UpdateNullifyCheckDetails(buy_pt_id, arr[j], uidObj)
          if (response.status != 200) {
            if(response.status == 400){

              
            }
            showMessage(thisObj, response.message, false)
            showMessage(thisObj, `An error occured while voiding Check # ${arr[j]} !!!`, false)
            return;
          } else {
          if (response.status == 200) {
            // showMessage(thisObj, response.result, true)
            setValue(thisObj, `txtCheckNum${j + 1}`, "")
          } 
        }
      } else {
        setValue(thisObj, `txtCheckNum${j + 1}`, "")
      }
        }
        // document.getElementById("AccountPayment_NullifyCheckPopUp").childNodes[0].click()
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during SpoilChecks click event"
        );
      }
      return false;
    }

  }
  thisObj.onbtnSpoilChecksClick = onbtnSpoilChecksClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
          
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_NullifyCheck*/}

              {/* END_USER_CODE-USER_BEFORE_NullifyCheck*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              <GroupBoxWidget conf={state.grpbxCheckDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_lblEnterCheckNumber*/}

                {/* END_USER_CODE-USER_BEFORE_lblEnterCheckNumber*/}

                <LabelWidget
                  values={values}
                  conf={state.lblEnterCheckNumber}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblEnterCheckNumber*/}

                {/* END_USER_CODE-USER_AFTER_lblEnterCheckNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum1*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum1*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum1*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum5*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum5*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum5}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum5*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum5*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum2*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum2*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum2}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum2*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum2*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum6*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum6*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum6}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum6*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum6*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum3*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum3*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum3}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum3*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum3*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum7*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum7*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum7}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum7*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum7*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum4*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum4*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum4}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum4*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum4*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum8*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum8*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum8}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum8*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum8*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckDetails*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSpoilChecks*/}

                {/* END_USER_CODE-USER_BEFORE_btnSpoilChecks*/}

                <ButtonWidget
                  conf={state.btnSpoilChecks}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSpoilChecks*/}

                {/* END_USER_CODE-USER_AFTER_btnSpoilChecks*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_NullifyCheck*/}

              {/* END_USER_CODE-USER_AFTER_NullifyCheck*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_NullifyCheck);
