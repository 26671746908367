/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityUser from "./SecurityUser";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityUser Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityUser />);
    });
  });
  afterEach(cleanup);
  test("is SecurityUser Loads Successfully", () => {
    expect(screen.getByText("SecurityUser")).toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityUser", () => {
    // START_USER_CODE-USER_SecurityUser_Custom_Test_Case
    // END_USER_CODE-USER_SecurityUser_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityUser />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnAddFunction(Button Widget) Test Cases", async () => {
    const btnAddFunction = screen.getByTestId("btnAddFunction");
    expect(btnAddFunction).toBeInTheDocument;
    expect(btnAddFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnAddFunction")
    );
  });
  test("Custom Test Cases for btnAddFunction", () => {
    // START_USER_CODE-USER_btnAddFunction_TEST
    // END_USER_CODE-USER_btnAddFunction_TEST
  });
  test("btnAddLocation(Button Widget) Test Cases", async () => {
    const btnAddLocation = screen.getByTestId("btnAddLocation");
    expect(btnAddLocation).toBeInTheDocument;
    expect(btnAddLocation.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnAddLocation")
    );
  });
  test("Custom Test Cases for btnAddLocation", () => {
    // START_USER_CODE-USER_btnAddLocation_TEST
    // END_USER_CODE-USER_btnAddLocation_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnDeleteFunction(Button Widget) Test Cases", async () => {
    const btnDeleteFunction = screen.getByTestId("btnDeleteFunction");
    expect(btnDeleteFunction).toBeInTheDocument;
    expect(btnDeleteFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnDeleteFunction")
    );
  });
  test("Custom Test Cases for btnDeleteFunction", () => {
    // START_USER_CODE-USER_btnDeleteFunction_TEST
    // END_USER_CODE-USER_btnDeleteFunction_TEST
  });
  test("btnDeleteLocation(Button Widget) Test Cases", async () => {
    const btnDeleteLocation = screen.getByTestId("btnDeleteLocation");
    expect(btnDeleteLocation).toBeInTheDocument;
    expect(btnDeleteLocation.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnDeleteLocation")
    );
  });
  test("Custom Test Cases for btnDeleteLocation", () => {
    // START_USER_CODE-USER_btnDeleteLocation_TEST
    // END_USER_CODE-USER_btnDeleteLocation_TEST
  });
  test("btnEditFunction(Button Widget) Test Cases", async () => {
    const btnEditFunction = screen.getByTestId("btnEditFunction");
    expect(btnEditFunction).toBeInTheDocument;
    expect(btnEditFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnEditFunction")
    );
  });
  test("Custom Test Cases for btnEditFunction", () => {
    // START_USER_CODE-USER_btnEditFunction_TEST
    // END_USER_CODE-USER_btnEditFunction_TEST
  });
  test("btnEditLocation(Button Widget) Test Cases", async () => {
    const btnEditLocation = screen.getByTestId("btnEditLocation");
    expect(btnEditLocation).toBeInTheDocument;
    expect(btnEditLocation.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnEditLocation")
    );
  });
  test("Custom Test Cases for btnEditLocation", () => {
    // START_USER_CODE-USER_btnEditLocation_TEST
    // END_USER_CODE-USER_btnEditLocation_TEST
  });
  test("btnImportSecurity(Button Widget) Test Cases", async () => {
    const btnImportSecurity = screen.getByTestId("btnImportSecurity");
    expect(btnImportSecurity).toBeInTheDocument;
    expect(btnImportSecurity.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_btnImportSecurity")
    );
  });
  test("Custom Test Cases for btnImportSecurity", () => {
    // START_USER_CODE-USER_btnImportSecurity_TEST
    // END_USER_CODE-USER_btnImportSecurity_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxFunctions(GroupBox Widget) Test Cases", async () => {
    const grpbxFunctions = screen.getByTestId("grpbxFunctions");
    expect(grpbxFunctions.tagName).toBe("BUTTON");
    expect(grpbxFunctions.type).toBe("button");
    expect(grpbxFunctions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFunctions", () => {
    // START_USER_CODE-USER_grpbxFunctions_TEST
    // END_USER_CODE-USER_grpbxFunctions_TEST
  });
  test("grpbxImportFunctionsLocations(GroupBox Widget) Test Cases", async () => {
    const grpbxImportFunctionsLocations = screen.getByTestId(
      "grpbxImportFunctionsLocations"
    );
    expect(grpbxImportFunctionsLocations.tagName).toBe("BUTTON");
    expect(grpbxImportFunctionsLocations.type).toBe("button");
    expect(grpbxImportFunctionsLocations.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxImportFunctionsLocations", () => {
    // START_USER_CODE-USER_grpbxImportFunctionsLocations_TEST
    // END_USER_CODE-USER_grpbxImportFunctionsLocations_TEST
  });
  test("grpbxLocations(GroupBox Widget) Test Cases", async () => {
    const grpbxLocations = screen.getByTestId("grpbxLocations");
    expect(grpbxLocations.tagName).toBe("BUTTON");
    expect(grpbxLocations.type).toBe("button");
    expect(grpbxLocations.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLocations", () => {
    // START_USER_CODE-USER_grpbxLocations_TEST
    // END_USER_CODE-USER_grpbxLocations_TEST
  });
  test("grpbxSecurityUser(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityUser = screen.getByTestId("grpbxSecurityUser");
    expect(grpbxSecurityUser.tagName).toBe("BUTTON");
    expect(grpbxSecurityUser.type).toBe("button");
    expect(grpbxSecurityUser.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityUser", () => {
    // START_USER_CODE-USER_grpbxSecurityUser_TEST
    // END_USER_CODE-USER_grpbxSecurityUser_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblFromUserID(Label Widget) Test Cases", async () => {
    const lblFromUserID = screen.getByTestId("lblFromUserID");
    expect(lblFromUserID.tagName).toBe("LABEL");
    expect(lblFromUserID.classList).toContain("form-label");
    expect(lblFromUserID.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblFromUserID")
    );
  });
  test("Custom Test Cases for lblFromUserID", () => {
    // START_USER_CODE-USER_lblFromUserID_TEST
    // END_USER_CODE-USER_lblFromUserID_TEST
  });
  test("lblFunctions(Label Widget) Test Cases", async () => {
    const lblFunctions = screen.getByTestId("lblFunctions");
    expect(lblFunctions.tagName).toBe("LABEL");
    expect(lblFunctions.classList).toContain("form-label");
    expect(lblFunctions.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblFunctions")
    );
  });
  test("Custom Test Cases for lblFunctions", () => {
    // START_USER_CODE-USER_lblFunctions_TEST
    // END_USER_CODE-USER_lblFunctions_TEST
  });
  test("lblImportFunctionsLocations(Label Widget) Test Cases", async () => {
    const lblImportFunctionsLocations = screen.getByTestId(
      "lblImportFunctionsLocations"
    );
    expect(lblImportFunctionsLocations.tagName).toBe("LABEL");
    expect(lblImportFunctionsLocations.classList).toContain("form-label");
    expect(lblImportFunctionsLocations.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUser_lblImportFunctionsLocations"
      )
    );
  });
  test("Custom Test Cases for lblImportFunctionsLocations", () => {
    // START_USER_CODE-USER_lblImportFunctionsLocations_TEST
    // END_USER_CODE-USER_lblImportFunctionsLocations_TEST
  });
  test("lblLocations(Label Widget) Test Cases", async () => {
    const lblLocations = screen.getByTestId("lblLocations");
    expect(lblLocations.tagName).toBe("LABEL");
    expect(lblLocations.classList).toContain("form-label");
    expect(lblLocations.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblLocations")
    );
  });
  test("Custom Test Cases for lblLocations", () => {
    // START_USER_CODE-USER_lblLocations_TEST
    // END_USER_CODE-USER_lblLocations_TEST
  });
  test("lblNote(Label Widget) Test Cases", async () => {
    const lblNote = screen.getByTestId("lblNote");
    expect(lblNote.tagName).toBe("LABEL");
    expect(lblNote.classList).toContain("form-label");
    expect(lblNote.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_lblNote")
    );
  });
  test("Custom Test Cases for lblNote", () => {
    // START_USER_CODE-USER_lblNote_TEST
    // END_USER_CODE-USER_lblNote_TEST
  });
  test("txtIBMUserID(Textbox Widget) Test Cases", async () => {
    const txtIBMUserID = screen.getByTestId("txtIBMUserID");
    expect(txtIBMUserID.tagName).toBe("INPUT");
    expect(txtIBMUserID.type).toBe("text");
    expect(txtIBMUserID.classList).toContain("textboxWidgetClass");
    expect(txtIBMUserID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_txtIBMUserID")
    );
    await act(async () => {
      userEvent.type(txtIBMUserID, "1");
    });
  });
  test("Custom Test Cases for txtIBMUserID", () => {
    // START_USER_CODE-USER_txtIBMUserID_TEST
    // END_USER_CODE-USER_txtIBMUserID_TEST
  });
  test("txtUserEmail(Textbox Widget) Test Cases", async () => {
    const txtUserEmail = screen.getByTestId("txtUserEmail");
    expect(txtUserEmail.tagName).toBe("INPUT");
    expect(txtUserEmail.type).toBe("text");
    expect(txtUserEmail.classList).toContain("textboxWidgetClass");
    expect(txtUserEmail.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_txtUserEmail")
    );
    await act(async () => {
      userEvent.type(txtUserEmail, "1");
    });
  });
  test("Custom Test Cases for txtUserEmail", () => {
    // START_USER_CODE-USER_txtUserEmail_TEST
    // END_USER_CODE-USER_txtUserEmail_TEST
  });
  test("txtUserID(Textbox Widget) Test Cases", async () => {
    const txtUserID = screen.getByTestId("txtUserID");
    expect(txtUserID.tagName).toBe("INPUT");
    expect(txtUserID.type).toBe("text");
    expect(txtUserID.classList).toContain("textboxWidgetClass");
    expect(txtUserID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_txtUserID")
    );
    await act(async () => {
      userEvent.type(txtUserID, "1");
    });
  });
  test("Custom Test Cases for txtUserID", () => {
    // START_USER_CODE-USER_txtUserID_TEST
    // END_USER_CODE-USER_txtUserID_TEST
  });
  test("txtUserName(Textbox Widget) Test Cases", async () => {
    const txtUserName = screen.getByTestId("txtUserName");
    expect(txtUserName.tagName).toBe("INPUT");
    expect(txtUserName.type).toBe("text");
    expect(txtUserName.classList).toContain("textboxWidgetClass");
    expect(txtUserName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_txtUserName")
    );
    await act(async () => {
      userEvent.type(txtUserName, "1");
    });
  });
  test("Custom Test Cases for txtUserName", () => {
    // START_USER_CODE-USER_txtUserName_TEST
    // END_USER_CODE-USER_txtUserName_TEST
  });
  test("txtUserPhone(Textbox Widget) Test Cases", async () => {
    const txtUserPhone = screen.getByTestId("txtUserPhone");
    expect(txtUserPhone.tagName).toBe("INPUT");
    expect(txtUserPhone.type).toBe("text");
    expect(txtUserPhone.classList).toContain("textboxWidgetClass");
    expect(txtUserPhone.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityUser_txtUserPhone")
    );
    await act(async () => {
      userEvent.type(txtUserPhone, "1");
    });
  });
  test("Custom Test Cases for txtUserPhone", () => {
    // START_USER_CODE-USER_txtUserPhone_TEST
    // END_USER_CODE-USER_txtUserPhone_TEST
  });
});
