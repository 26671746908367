/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  hide,
  show,
  goTo
} from "../../shared/WindowImports";

import "./TradeSettleApplyView.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettleApplyView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeSettleApplyView",
    windowName: "TradeSettleApplyView",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettleApplyView",
    // START_USER_CODE-USER_TradeSettleApplyView_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Agreement Application",
      scrCode: "PN1140H",
    },
    // END_USER_CODE-USER_TradeSettleApplyView_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    colAgreedPounds: {
      name: "colAgreedPounds",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agreed Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreedPounds_PROPERTIES

      // END_USER_CODE-USER_colAgreedPounds_PROPERTIES
    },
    colAgreeDt: {
      name: "colAgreeDt",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agree Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeDt_PROPERTIES

      // END_USER_CODE-USER_colAgreeDt_PROPERTIES
    },
    colAgreeNum: {
      name: "colAgreeNum",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agree Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeNum_PROPERTIES

      // END_USER_CODE-USER_colAgreeNum_PROPERTIES
    },
    colApplied: {
      name: "colApplied",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Applied ",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplied_PROPERTIES

      // END_USER_CODE-USER_colApplied_PROPERTIES
    },
    colOpenBalance: {
      name: "colOpenBalance",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Open Balance",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalance_PROPERTIES

      // END_USER_CODE-USER_colOpenBalance_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colPoundsToApply: {
      name: "colPoundsToApply",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApply_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPricingMethod: {
      name: "colPricingMethod",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Pricing Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricingMethod_PROPERTIES

      // END_USER_CODE-USER_colPricingMethod_PROPERTIES
    },
    colPurchaseSale: {
      name: "colPurchaseSale",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Purchase/Sale",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurchaseSale_PROPERTIES

      // END_USER_CODE-USER_colPurchaseSale_PROPERTIES
    },
    colSeed: {
      name: "colSeed",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed_PROPERTIES

      // END_USER_CODE-USER_colSeed_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Type",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    gridAgreementApplications: {
      name: "gridAgreementApplications",
      type: "GridWidget",
      parent: "grpbxTradeSettleApplyView",
      gridCellsOrder:
        "btncol1,txtcolAgreeNum,txtcolAgreeDt,txtcolPeanutType,txtcolPeanutVariety,txtcolSeed,txtcolPricingMethod,txtcolType,txtcolPoundsToApply,txtcolPurchaseSale,txtcolAgreedPounds,txtcolApplied,txtcolOpenBalance,txtcolPrice",
      ColSpan: "7",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_gridAgreementApplications_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridAgreementApplications",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblAgreementApplications: {
      name: "lblAgreementApplications",
      type: "LabelWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Agreement Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_lblAgreementApplications_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtcolAgreedPounds: {
      name: "txtcolAgreedPounds",
      type: "TextBoxWidget",
      colName: "colAgreedPounds",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreedPounds_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreedPounds_PROPERTIES
    },
    txtcolAgreeDt: {
      name: "txtcolAgreeDt",
      type: "TextBoxWidget",
      colName: "colAgreeDt",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeDt_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeDt_PROPERTIES
    },
    txtcolAgreeNum: {
      name: "txtcolAgreeNum",
      type: "TextBoxWidget",
      colName: "colAgreeNum",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeNum_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeNum_PROPERTIES
    },
    txtcolApplied: {
      name: "txtcolApplied",
      type: "TextBoxWidget",
      colName: "colApplied",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolApplied_PROPERTIES

      // END_USER_CODE-USER_txtcolApplied_PROPERTIES
    },
    txtcolOpenBalance: {
      name: "txtcolOpenBalance",
      type: "TextBoxWidget",
      colName: "colOpenBalance",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenBalance_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalance_PROPERTIES
    },
    txtcolPeanutType: {
      name: "txtcolPeanutType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutType_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolPoundsToApply: {
      name: "txtcolPoundsToApply",
      type: "TextBoxWidget",
      colName: "colPoundsToApply",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolPoundsToApply_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPricingMethod: {
      name: "txtcolPricingMethod",
      type: "TextBoxWidget",
      colName: "colPricingMethod",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPricingMethod_PROPERTIES

      // END_USER_CODE-USER_txtcolPricingMethod_PROPERTIES
    },
    txtcolPurchaseSale: {
      name: "txtcolPurchaseSale",
      type: "TextBoxWidget",
      colName: "colPurchaseSale",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPurchaseSale_PROPERTIES

      // END_USER_CODE-USER_txtcolPurchaseSale_PROPERTIES
    },
    txtcolSeed: {
      name: "txtcolSeed",
      type: "TextBoxWidget",
      colName: "colSeed",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES

      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Peanut Variety",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVariety_PROPERTIES
    },
    txtPounds: {
      name: "txtPounds",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds_PROPERTIES

      // END_USER_CODE-USER_txtPounds_PROPERTIES
    },
    txtPurchaseSale: {
      name: "txtPurchaseSale",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Purchase/Sale",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPurchaseSale_PROPERTIES

      // END_USER_CODE-USER_txtPurchaseSale_PROPERTIES
    },
    txtSeed: {
      name: "txtSeed",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Seed",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeed_PROPERTIES

      // END_USER_CODE-USER_txtSeed_PROPERTIES
    },
    txtSettleNum: {
      name: "txtSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Settle #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtSettleNum_PROPERTIES
    },
    txtTradeInspNum: {
      name: "txtTradeInspNum",
      type: "TextBoxWidget",
      parent: "grpbxTradeSettleApplyView",
      Label: "Trade Insp #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspNum_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspNum_PROPERTIES
    },
    grpbxTradeSettleApplyView: {
      name: "grpbxTradeSettleApplyView",
      type: "GroupBoxWidget",
      parent: "TradeSettleApplyView",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxTradeSettleApplyView_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeSettleApplyView_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TradeSettleApplyView",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    LoadApplicationData();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const LoadApplicationData = () => {
    let formData = getData(thisObj, 'ReviewAgreeApplFormLoadData')
    let buy_pt_id = formData.buy_point_id //'2CY'
    let trade_insp_number = formData.txtTradeInspNum //'A323213' 
    let trade_settle_number = formData.txtTradeSettle1007 //''
    
    setValue(thisObj, 'txtTradeInspNum', trade_insp_number)
        
    if (trade_insp_number != null && buy_pt_id != null) {
      SettlementService.RetrieveTradeSettlementDetails(buy_pt_id, trade_insp_number)
        .then(response => {
            if (response.length != 0) {
              let TrSettleList = response[0].trade_settle_header
              let TrSettleVendorList = response[0].trade_settle_vendor_split
              
              trade_settle_number = TrSettleList[0].trade_settle_num
              let peanut_type_name = TrSettleList[0].pnut_type_name
              let peanut_type_id = TrSettleList[0].pnut_type_id
              let peanut_variety_id = TrSettleList[0].pnut_variety_id

              let symbolind = TrSettleList[0].symbol_ind
              let symbolmark = ''
              if(symbolind == "TRADEMARK")
              {
                symbolmark = " " + Chr(153)
              } else if(symbolind == "REGISTERED TRADEMARK")
              {
                symbolmark = " " + Chr(174)
              } else if(symbolind == "COPYRIGHT")
              {
                symbolmark = " " + Chr(169)
              }else
              {
                symbolmark = ""
              }
              let peanut_variety_name = TrSettleList[0].pnut_variety_name + symbolmark
              setValue(thisObj, 'txtSettleNum', trade_settle_number)
              setValue(thisObj, 'txtPeanutType', peanut_type_name)
              setValue(thisObj, 'txtPeanutVariety', peanut_variety_name)

              let segType = TrSettleList[0].seg_type
              let gen_produced_name = TrSettleList[0].gen_produced_name
              if(segType == 1 && gen_produced_name != null){
                setValue(thisObj, 'txtSeed', 'Yes')
              }else{
                setValue(thisObj, 'txtSeed', 'No')
              }
              
              let purch_sale_ind = TrSettleList[0].purch_sale_ind
              if (purch_sale_ind == 'P'){
                setValue(thisObj, 'txtPurchaseSale', 'Purchase')
              }else{
                setValue(thisObj, 'txtPurchaseSale', 'Sale')
              }

              let grade_pricing_method = TrSettleList[0].grade_pricing_method
              let net_wt = Number(TrSettleList[0].net_wt)
              let smk_pct = Number(TrSettleList[0].smk_pct)
              let lsk_wt = Number(TrSettleList[0].lsk_wt)

              let lblApplyPounds = 0
              for (var i = 0; i < TrSettleVendorList.length; i++) {
                let split_vendor = TrSettleVendorList[i].split_vendor
                let split_share_pct = TrSettleVendorList[i].split_share_pct
                let vnet_wt = TrSettleVendorList[i].net_wt
                if(grade_pricing_method == 'TKC'){
                  lblApplyPounds = (Number(net_wt) - Number(lsk_wt)) * (Number(smk_pct)/100) + Number(lsk_wt)
                }else{
                  lblApplyPounds = Number(lblApplyPounds) + Number(vnet_wt)
                }
              }
              setValue(thisObj, 'txtPounds', lblApplyPounds)
            }
          });

      SettlementService.RetrieveTradeSettlementApplicationDetails(trade_insp_number, 'N')
          .then(response => {
              if (response.length != 0) {
                let ContApplist = []
                let ContAppObj = {}
                let poundsApply = 0
                for (var j = 0; j < response.length; j++) {
                  if (response[j].delivered_lbs_adj > 0){
                    ContAppObj.txtcolAgreeNum = response[j].agreement_num
                    ContAppObj.txtcolAgreeDt = moment(response[j].agree_date_time).format('MM/DD/YYYY')
                    ContAppObj.txtcolPeanutType = response[j].pnut_type_id
                    ContAppObj.txtcolPeanutVariety = response[j].pnut_variety_id
                    if (response[j].seed_gen != ''){
                      ContAppObj.txtcolSeed = 'Y'
                    }else{
                      ContAppObj.txtcolSeed = 'N'
                    }
                    ContAppObj.txtcolPricingMethod = response[j].grade_pricing_method
                    if (response[j].firm_basis_opt == 'F'){
                      ContAppObj.txtcolType = 'FIRM'
                    }else if(response[j].firm_basis_opt == 'B')
                    {
                      ContAppObj.txtcolType = 'BASIS'
                    }else if(response[j].firm_basis_opt == 'L')
                    {
                      ContAppObj.txtcolType = 'FLAT'
                    }else
                    {
                      ContAppObj.txtcolType = response[j].firm_basis_opt
                    }
                    // if (ContAppObj.txtcolAgreeNum = response[j].agreement_num){
                    //   poundsApply = Number(poundsApply) + Number(response[j].delivered_lbs_adj)
                    // }
                    ContAppObj.txtcolPoundsToApply = Number(response[j].delivered_lbs_adj)
                    ContAppObj.txtcolPurchaseSale = response[j].purch_sale_ind
                    ContAppObj.txtcolAgreedPounds = Number(response[j].agreed_lbs)
                    ContAppObj.txtcolApplied = response[j].delivered_lbs_adj
                    ContAppObj.txtcolOpenBalance = Number(response[j].agreed_lbs) - Number(response[j].delivered_lbs_adj)
                    if(response[j].grade_pricing_method == 'FULL'){
                      ContAppObj.txtcolPrice = response[j].price_per_ton
                    }else
                    {
                      ContAppObj.txtcolPrice = response[j].price_per_lbs
                    }
                    ContApplist.push(ContAppObj)
                    ContAppObj = {}
                  }
                }
                setValue(thisObj, 'gridAgreementApplications', ContApplist)
              }
            });
    }
    return true;
  }

  const onbtnCancelClick = () => {
    try {
       document.getElementById("Settlements_TradeSettleApplyViewPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtncol1Click = async (event) => {
    try {
     let highlight = document.getElementsByClassName("activeRow")
     if (highlight.length > 0) {
       highlight[0].classList.remove("activeRow")
     }
     let rowSelected = event.target.parentElement.parentElement.parentElement
     rowSelected.classList.add("activeRow")
     let rowID = rowSelected.id.substring(4, 5);
     let NewGridValue = getValue(thisObj, 'gridAgreementApplications')
     if (NewGridValue[rowID].txtcolAgreeNum != null && NewGridValue[rowID].txtcolAgreeNum != undefined && NewGridValue[rowID].txtcolAgreeNum != "") {
       let Agreenum = NewGridValue[rowID].txtcolAgreeNum;
       //let APIresponse = await ContractManagementService.RetrieveContractDetails(contractnum, 'PN1010')
       if(Agreenum.length >0){
          let Obj = {};
          let js = [];
          Obj.Agreenum = Agreenum;
          Obj.GstrEditMode = "1";
          Obj.UpdateBtnCaption = "Update";
          Obj.UpdateBtnlblStatus = "Update";
          Obj.UpdateBtnEnabledDelete = "True";
          Obj.UpdateBtnEnabledPrint = "True";
          js.push(Obj);
          setData(thisObj, "CreateValue", js)
        //  commonContext1.setContractProfile_ContractNumber(Agreenum);
        //  commonContext1.setContractProfile_FormState("edit");
          goTo(thisObj, "Settlements#TradeAgreeProfile#DEFAULT#true#Click");
       } else {
          showMessage(thisObj, "Trade Agree Profile Number does not exist")
       }
     } else {
       showMessage(thisObj, "Trade Agree Profile Number cannot be null !!")
     }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting Trade Agree Profile Number");
      }
    }
  };
  thisObj.onbtncol1Click = onbtncol1Click;
  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeSettleApplyView*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettleApplyView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxTradeSettleApplyView*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeSettleApplyView*/}

              <GroupBoxWidget
                conf={state.grpbxTradeSettleApplyView}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspNum*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettleNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettleNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettleNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettleNum*/}

                {/* END_USER_CODE-USER_AFTER_txtSettleNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutVariety*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutVariety}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeed*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeed*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeed}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeed*/}

                {/* END_USER_CODE-USER_AFTER_txtSeed*/}
                {/* START_USER_CODE-USER_BEFORE_txtPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtPounds*/}
                {/* START_USER_CODE-USER_BEFORE_txtPurchaseSale*/}

                {/* END_USER_CODE-USER_BEFORE_txtPurchaseSale*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPurchaseSale}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPurchaseSale*/}

                {/* END_USER_CODE-USER_AFTER_txtPurchaseSale*/}
                {/* START_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAgreementApplications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_lblAgreementApplications*/}
                {/* START_USER_CODE-USER_BEFORE_gridAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_gridAgreementApplications*/}

                <GridWidget
                  conf={state.gridAgreementApplications}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAgreementApplications}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_gridAgreementApplications*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeSettleApplyView*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeSettleApplyView*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettleApplyView*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettleApplyView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettleApplyView);
