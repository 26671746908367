/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
} from "../../shared/WindowImports";

import "./ReviewDeductionTracking.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import {SettlementService} from "../Service/SettlementService";
import * as XLSX from 'xlsx/xlsx.mjs';
import StripPic from "../../../../assets/img/PinStrip.png";
import moment from "moment";//For date time
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));


// END_USER_CODE-USER_IMPORTS
function Settlements_ReviewDeductionTracking(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReviewDeductionTracking",
    windowName: "ReviewDeductionTracking",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ReviewDeductionTracking",
    // START_USER_CODE-USER_ReviewDeductionTracking_PROPERTIES
    headerData: {
      scrName: "Review Deduction Tracking",
      scrCode: "PN0160G",
      
    },

    // END_USER_CODE-USER_ReviewDeductionTracking_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxAllButtons",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxAllButtons",
      Label: "Export To Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxAllButtons",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnWithImage: {
      name: "btnWithImage",
      type: "ButtonWidget",
      parent: "grpbxAllButtons",
      Label: ".",
      CharWidth: "6",
      // START_USER_CODE-USER_btnWithImage_PROPERTIES

      // END_USER_CODE-USER_btnWithImage_PROPERTIES
    },
    colAmntCllc: {
      name: "colAmntCllc",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Amount Collected",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmntCllc_PROPERTIES

      // END_USER_CODE-USER_colAmntCllc_PROPERTIES
    },
    colChrgId: {
      name: "colChrgId",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Charge to ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChrgId_PROPERTIES

      // END_USER_CODE-USER_colChrgId_PROPERTIES
    },
    colAmtChrgd: {
      name: "colAmtChrgd",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Amount Charged",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmtChrgd_PROPERTIES

      // END_USER_CODE-USER_colAmtChrgd_PROPERTIES
    },
    colChrgTyp: {
      name: "colChrgTyp",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Charge To Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChrgTyp_PROPERTIES

      // END_USER_CODE-USER_colChrgTyp_PROPERTIES
    },
    colCllctId: {
      name: "colCllctId",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Collected for ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCllctId_PROPERTIES

      // END_USER_CODE-USER_colCllctId_PROPERTIES
    },
    colCllctTyp: {
      name: "colCllctTyp",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Collected for Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCllctTyp_PROPERTIES

      // END_USER_CODE-USER_colCllctTyp_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colDeductCode: {
      name: "colDeductCode",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Deduction View",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeductCode_PROPERTIES

      // END_USER_CODE-USER_colDeductCode_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colVndr: {
      name: "colVndr",
      type: "GridColumnWidget",
      parent: "gridReviewDedTrckng",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVndr_PROPERTIES

      // END_USER_CODE-USER_colVndr_PROPERTIES
    },
    lblbuypt: {
      name: "lblbuypt",
      type: "LabelWidget",
      parent: "grpbxAllButtons",
      Label: "lblbuypt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblbuypt_PROPERTIES

      // END_USER_CODE-USER_lblbuypt_PROPERTIES
    },
    lblnumber1: {
      name: "lblnumber1",
      type: "LabelWidget",
      parent: "grpbxAllButtons",
      Label: "lblnumber1",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblnumber1_PROPERTIES

      // END_USER_CODE-USER_lblnumber1_PROPERTIES
    },
    lblnumber2: {
      name: "lblnumber2",
      type: "LabelWidget",
      parent: "grpbxAllButtons",
      Label: "lblnumber2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblnumber2_PROPERTIES

      // END_USER_CODE-USER_lblnumber2_PROPERTIES
    },
    lblsource: {
      name: "lblsource",
      type: "LabelWidget",
      parent: "grpbxAllButtons",
      Label: "lblsource",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblsource_PROPERTIES

      // END_USER_CODE-USER_lblsource_PROPERTIES
    },
    lblTotal: {
      name: "lblTotal",
      type: "LabelWidget",
      parent: "grpbxAllLabels",
      Label: "Total",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotal_PROPERTIES

      // END_USER_CODE-USER_lblTotal_PROPERTIES
    },
    txt1: {
      name: "txt1",
      type: "TextBoxWidget",
      parent: "grpbxAllLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1_PROPERTIES
      ReadOnly:true,
      Enabled:false,
      // END_USER_CODE-USER_txt1_PROPERTIES
    },
    txt2: {
      name: "txt2",
      type: "TextBoxWidget",
      parent: "grpbxAllLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt2_PROPERTIES
      ReadOnly:true,
      Enabled:false,
      // END_USER_CODE-USER_txt2_PROPERTIES
    },
    txtcolAmntCllc: {
      name: "txtcolAmntCllc",
      type: "TextBoxWidget",
      colName: "colAmntCllc",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmntCllc_PROPERTIES

      // END_USER_CODE-USER_txtcolAmntCllc_PROPERTIES
    },
    txtcolChrgId: {
      name: "txtcolChrgId",
      type: "TextBoxWidget",
      colName: "colChrgId",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChrgId_PROPERTIES

      // END_USER_CODE-USER_txtcolChrgId_PROPERTIES
    },
    txtcolAmtChrgd: {
      name: "txtcolAmtChrgd",
      type: "TextBoxWidget",
      colName: "colAmtChrgd",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmtChrgd_PROPERTIES

      // END_USER_CODE-USER_txtcolAmtChrgd_PROPERTIES
    },
    txtcolChrgTyp: {
      name: "txtcolChrgTyp",
      type: "TextBoxWidget",
      colName: "colChrgTyp",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChrgTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolChrgTyp_PROPERTIES
    },
    txtcolCllctId: {
      name: "txtcolCllctId",
      type: "TextBoxWidget",
      colName: "colCllctId",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCllctId_PROPERTIES

      // END_USER_CODE-USER_txtcolCllctId_PROPERTIES
    },
    txtcolCllctTyp: {
      name: "txtcolCllctTyp",
      type: "TextBoxWidget",
      colName: "colCllctTyp",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCllctTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolCllctTyp_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolDeductCode: {
      name: "txtcolDeductCode",
      type: "TextBoxWidget",
      colName: "colDeductCode",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDeductCode_PROPERTIES

      // END_USER_CODE-USER_txtcolDeductCode_PROPERTIES
    },
    txtcolVndr: {
      name: "txtcolVndr",
      type: "TextBoxWidget",
      colName: "colVndr",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVndr_PROPERTIES

      // END_USER_CODE-USER_txtcolVndr_PROPERTIES
    },
    txtStsColm: {
      name: "txtStsColm",
      type: "TextBoxWidget",
      colName: "colIsSts",
      parent: "gridReviewDedTrckng",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStsColm_PROPERTIES

      // END_USER_CODE-USER_txtStsColm_PROPERTIES
    },
    gridReviewDedTrckng: {
      name: "gridReviewDedTrckng",
      type: "GridWidget",
      parent: "ReviewDeductionTracking",
      gridCellsOrder:
        "txtStsColm,txtcolDeductCode,txtcolVndr,txtcolDate,txtcolChrgTyp,txtcolChrgId,txtcolAmtChrgd,txtcolCllctTyp,txtcolCllctId,txtcolAmntCllc",
      Pagination: false,
      isEditable: true,
      HasLabel: false,
      Cols: "10",
      ColsForLargeDesktop: "10",
      Height: "",
      Width: "",
      clonedExtId: "30392",
      // START_USER_CODE-USER_gridReviewDedTrckng_PROPERTIES

      // END_USER_CODE-USER_gridReviewDedTrckng_PROPERTIES
    },
    grpbxAllLabels: {
      name: "grpbxAllLabels",
      type: "GroupBoxWidget",
      parent: "ReviewDeductionTracking",
      Height: "",
      Width: "",
      clonedExtId: "31815",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAllLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxAllLabels_PROPERTIES
    },
    grpbxAllButtons: {
      name: "grpbxAllButtons",
      type: "GroupBoxWidget",
      parent: "ReviewDeductionTracking",
      Height: "",
      Width: "",
      clonedExtId: "30412",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxAllButtons_PROPERTIES

      // END_USER_CODE-USER_grpbxAllButtons_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let ParentData = getData(thisObj, "ReviewDeductionTrackingFormLoadData")
    setData(thisObj, 'ParentData', ParentData)
    FormLoad()
    setValue(thisObj, "txt1", "0.00");
    setValue(thisObj, "txt2", "0.00");
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS)
  var totalAmountCharged = 0
  var totalAmountCollected = 0
  function FormLoad() {
    fillRetrieveDeductionTrackingDetail();
  }

  const fillRetrieveDeductionTrackingDetail = () => {
    let ReviewDeductionTrackingFormLoadDataObj = getData(thisObj, "ReviewDeductionTrackingFormLoadData")
    try {
      if (ReviewDeductionTrackingFormLoadDataObj != null && ReviewDeductionTrackingFormLoadDataObj != undefined &&
        ReviewDeductionTrackingFormLoadDataObj.buy_point_id != "" && ReviewDeductionTrackingFormLoadDataObj.buy_point_id != undefined &&
        ReviewDeductionTrackingFormLoadDataObj.source_type != "" && ReviewDeductionTrackingFormLoadDataObj.source_type != undefined &&
        ReviewDeductionTrackingFormLoadDataObj.id_number_1 != "" && ReviewDeductionTrackingFormLoadDataObj.id_number_1 != undefined &&
        ReviewDeductionTrackingFormLoadDataObj.id_number_2 != "" && ReviewDeductionTrackingFormLoadDataObj.id_number_2 != undefined) {
        SettlementService.RetrieveDeductionTrackingDetail(ReviewDeductionTrackingFormLoadDataObj.buy_point_id, ReviewDeductionTrackingFormLoadDataObj.source_type, ReviewDeductionTrackingFormLoadDataObj.id_number_1, ReviewDeductionTrackingFormLoadDataObj.id_number_2)
          .then(response => {
            let TransformedRowsArray = [];
            if (response != undefined && response.length > 0) {
              let data = response;
              let obj = {};
              for (let i = 0; i < data.length; i++) {
                obj.txtcolDeductCode = data[i].deduct_code
                obj.txtcolVndr = data[i].acct_vendor
                obj.txtcolDate = moment(data[i].audit_date_time).format('MM/DD/YYYY')
                obj.txtcolChrgTyp = data[i].charge_type
                obj.txtcolChrgId = data[i].charge_id
                obj.txtcolAmtChrgd = data[i].charge_amount
                totalAmountCharged = totalAmountCharged + Number(data[i].charge_amount)
                obj.txtcolCllctTyp = data[i].collect_type
                obj.txtcolCllctId = data[i].collect_id
                obj.txtcolAmntCllc = data[i].collect_amount
                totalAmountCollected = totalAmountCollected + Number(data[i].collect_amount)
                TransformedRowsArray.push(obj)
                obj = {}
              }
              let sumTotalAmountCharged = totalAmountCharged.toFixed(2);
              let sumTotalAmountCollected = totalAmountCollected.toFixed(2);
              setValue(thisObj, "gridReviewDedTrckng", TransformedRowsArray);
              setValue(thisObj, "txt1", sumTotalAmountCharged);
              setValue(thisObj, "txt2", sumTotalAmountCollected);
            }
          })
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during gridReviewDedTrckng change event");
      }
      return false;
    }
    return true;
  };
  thisObj.fillRetrieveDeductionTrackingDetail = fillRetrieveDeductionTrackingDetail;

//Button Export To Excel
  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridReviewDedTrckng")
      if (excelData.length > 0) {
        var fileName = "Deduction Tracking"
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Deduction Code": excelData[i].txtcolDeductCode,
            "Vendor": excelData[i].txtcolVndr,
            "Date": excelData[i].txtcolDate,
            "Charge To Type": excelData[i].txtcolChrgTyp,
            "Charge to ID": excelData[i].txtcolChrgId,
            "Amount Charged": ExcelNumericDataConvert(excelData[i].txtcolAmtChrgd, [], 2),
            "Collected for Type": excelData[i].txtcolCllctTyp,
            "Collected for ID": excelData[i].txtcolCllctId,
            "Amount Collected": ExcelNumericDataConvert(excelData[i].txtcolAmntCllc,[],2),
          }
          arrayData.push(obj);
        }
        const TotalAmount = {
          "Deduction Code": 'Total',
          "Vendor": '',
          "Date": '',
          "Charge To Type": '',
          "Charge to ID": '',
          "Amount Charged": ExcelNumericDataConvert(totalAmountCharged, [], 2),
          "Collected for Type": '',
          "Collected for ID": '',
          "Amount Collected": ExcelNumericDataConvert(totalAmountCollected, [], 2),
        }
        arrayData.push(TotalAmount);
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      } 
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during export to excel change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;


// Print Button Functionality

const onbtnPrintClick = () => {
  try{
    var data = getValue(thisObj, "gridReviewDedTrckng")
    let ParentData = getData(thisObj, 'ParentData')
    let dateTime = moment(new Date()).format('M/D/Y h:mm:ss A');
    if(data.length >0){
      var res = []
      for (var i = 0; i < data.length; i++) {
        res.push([
          data[i].txtcolDeductCode, data[i].txtcolVndr, data[i].txtcolDate, data[i].txtcolChrgTyp, data[i].txtcolChrgId, data[i].txtcolAmtChrgd, data[i].txtcolCllctTyp, data[i].txtcolCllctId, data[i].txtcolAmntCllc
        ])
      }
      res.push([
        "Total", "", "", "", "", totalAmountCharged.toFixed(2) , "", "",totalAmountCollected.toFixed(2)
      ])

      var Array = []
      let Obj = {}
      let Type = ' - Check: '
      let CheckNum = ParentData.id_number_2.toString()
      let CheckNumLength = CheckNum.length
      if(CheckNumLength < 6 && CheckNumLength >0){
        CheckNum = 'S' + CheckNum
        Type = ' - Receivable: '
      }
      
      let ReviewDeductionTrackingFormLoadDataObj = getData(thisObj, 'ReviewDeductionTrackingFormLoadData')
      let footerName = cropYearFromLS() +  " Deduction Tracking -" + " " + ReviewDeductionTrackingFormLoadDataObj.buy_point_id + " "
      if (ReviewDeductionTrackingFormLoadDataObj.source_type == "1007_SETTLE"){
        footerName = footerName + "- Settlement: " + ReviewDeductionTrackingFormLoadDataObj.id_number_2 + "/" + ReviewDeductionTrackingFormLoadDataObj.id_number_1
      }
      else if (ReviewDeductionTrackingFormLoadDataObj.source_type == "WHS_RCPT"){
        footerName = footerName + "- Receipt: " + ReviewDeductionTrackingFormLoadDataObj.id_number_1
      }
      else if (ReviewDeductionTrackingFormLoadDataObj.source_type == "TRADE_SETTLE"){
        footerName = footerName + "- Settlement: " + ReviewDeductionTrackingFormLoadDataObj.id_number_1
      }
      else if (ReviewDeductionTrackingFormLoadDataObj.source_type == "CHECK"){
        if(ReviewDeductionTrackingFormLoadDataObj.id_number_1 == "0"){
          footerName = footerName + "- Receivable: S" + ReviewDeductionTrackingFormLoadDataObj.id_number_2
        }
        else{
        footerName = footerName + "- Check: " + ReviewDeductionTrackingFormLoadDataObj.id_number_1
        }
      } 
    else {
      showMessage(thisObj, "No value in the grid to print")
    }
    Obj = {data: footerName + ' - ' + dateTime}
    Array.push(Obj)

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 7,lineWidth : 0.2,color:10 },
        headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' },
          5: { halign: 'right' },
          6: { halign: 'center' },
          7: { halign: 'center' },
          8: { halign: 'right' },
        },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          [
            'Deduction Code', 'Vendor', 'Date', 'Charge To Type', 'Charge to ID', 'Amount Charged', 'Collected for Type', 'Collected for ID', 'Amount Collected'
          ]
        ]
      });

      obj.setPage();

      obj.autoTable({
        startY: 275,
        startX: 10,
        styles: { fontSize: 10, lineWidth: 0, color: 10 },
        body: Array,
        theme: 'plain',
        fontStyle: 'normal',
      });

    let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
  }
    
  }catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
thisObj.onbtnPrintClick = onbtnPrintClick;

const onbtnCloseClick = () => {
  try {
    //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    document.getElementById("Settlements_ReviewDeductionTrackingPopUp").childNodes[0].click()
    //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnClose event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
          
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReviewDeductionTracking*/}

              {/* END_USER_CODE-USER_BEFORE_ReviewDeductionTracking*/}

              {/* START_USER_CODE-USER_BEFORE_gridReviewDedTrckng*/}

              {/* END_USER_CODE-USER_BEFORE_gridReviewDedTrckng*/}

              <GridWidget
                conf={state.gridReviewDedTrckng}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridReviewDedTrckng}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridReviewDedTrckng*/}

              {/* END_USER_CODE-USER_AFTER_gridReviewDedTrckng*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAllLabels*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAllLabels*/}

              <GroupBoxWidget conf={state.grpbxAllLabels} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblTotal*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblTotal*/}
                {/* START_USER_CODE-USER_BEFORE_txt1*/}

                {/* END_USER_CODE-USER_BEFORE_txt1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1*/}

                {/* END_USER_CODE-USER_AFTER_txt1*/}
                {/* START_USER_CODE-USER_BEFORE_txt2*/}

                {/* END_USER_CODE-USER_BEFORE_txt2*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt2}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt2*/}

                {/* END_USER_CODE-USER_AFTER_txt2*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAllLabels*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAllLabels*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAllButtons*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAllButtons*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxAllButtons} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnWithImage*/}

                {/* END_USER_CODE-USER_BEFORE_btnWithImage*/}

                <ButtonWidget
                  conf={state.btnWithImage}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnWithImage*/}

                {/* END_USER_CODE-USER_AFTER_btnWithImage*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblbuypt*/}

                {/* END_USER_CODE-USER_BEFORE_lblbuypt*/}

                <LabelWidget
                  values={values}
                  conf={state.lblbuypt}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblbuypt*/}

                {/* END_USER_CODE-USER_AFTER_lblbuypt*/}
                {/* START_USER_CODE-USER_BEFORE_lblsource*/}

                {/* END_USER_CODE-USER_BEFORE_lblsource*/}

                <LabelWidget
                  values={values}
                  conf={state.lblsource}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblsource*/}

                {/* END_USER_CODE-USER_AFTER_lblsource*/}
                {/* START_USER_CODE-USER_BEFORE_lblnumber1*/}

                {/* END_USER_CODE-USER_BEFORE_lblnumber1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblnumber1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblnumber1*/}

                {/* END_USER_CODE-USER_AFTER_lblnumber1*/}
                {/* START_USER_CODE-USER_BEFORE_lblnumber2*/}

                {/* END_USER_CODE-USER_BEFORE_lblnumber2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblnumber2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblnumber2*/}

                {/* END_USER_CODE-USER_AFTER_lblnumber2*/}

                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAllButtons*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAllButtons*/}

              {/* START_USER_CODE-USER_AFTER_ReviewDeductionTracking*/}

              {/* END_USER_CODE-USER_AFTER_ReviewDeductionTracking*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ReviewDeductionTracking);
