/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getGridValue,
  getData,
  setData,
  getSelectedRowNumber,
  getValue,
  setValue,
  setGridValue,
} from "../../shared/WindowImports";

import "./DiscountScheduleSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_DiscountScheduleSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Discount Schedule Setup",
    windowName: "DiscountScheduleSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.DiscountScheduleSetup",
    // START_USER_CODE-USER_DiscountScheduleSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Discount Schedule Setup",
      scrCode: "PN0070B",
    },
    // END_USER_CODE-USER_DiscountScheduleSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxDiscountSchedule",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxDiscountSchedule",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    colDiscountPerTon: {
      name: "colDiscountPerTon",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleSetup",
      Label: "Discount Per Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDiscountPerTon_PROPERTIES

      // END_USER_CODE-USER_colDiscountPerTon_PROPERTIES
    },
    colReadingFrom: {
      name: "colReadingFrom",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleSetup",
      Label: "Reading From",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReadingFrom_PROPERTIES

      // END_USER_CODE-USER_colReadingFrom_PROPERTIES
    },
    colReadingTo: {
      name: "colReadingTo",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleSetup",
      Label: "Reading To",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReadingTo_PROPERTIES

      // END_USER_CODE-USER_colReadingTo_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleSetup",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    gridDiscountScheduleSetup: {
      name: "gridDiscountScheduleSetup",
      type: "GridWidget",
      parent: "grpbxDiscountSchedule",
      gridCellsOrder:
        "scheduleSetupColl1,scheduleSetupColl2,scheduleSetupColl3,scheduleSetupColl4",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true
      // START_USER_CODE-USER_gridDiscountScheduleSetup_PROPERTIES

      // END_USER_CODE-USER_gridDiscountScheduleSetup_PROPERTIES
    },
    lblDiscountSchedule: {
      name: "lblDiscountSchedule",
      type: "LabelWidget",
      parent: "grpbxDiscountSchedule",
      Label: "Discount Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDiscountSchedule_PROPERTIES

      // END_USER_CODE-USER_lblDiscountSchedule_PROPERTIES
    },
    scheduleSetupColl4: {
      name: "scheduleSetupColl4",
      type: "TextBoxWidget",
      colName: "colDiscountPerTon",
      parent: "gridDiscountScheduleSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_scheduleSetupColl4_PROPERTIES

      // END_USER_CODE-USER_scheduleSetupColl4_PROPERTIES
    },
    scheduleSetupColl2: {
      name: "scheduleSetupColl2",
      type: "TextBoxWidget",
      colName: "colReadingFrom",
      parent: "gridDiscountScheduleSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_scheduleSetupColl2_PROPERTIES

      // END_USER_CODE-USER_scheduleSetupColl2_PROPERTIES
    },
    scheduleSetupColl3: {
      name: "scheduleSetupColl3",
      type: "TextBoxWidget",
      colName: "colReadingTo",
      parent: "gridDiscountScheduleSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_scheduleSetupColl3_PROPERTIES

      // END_USER_CODE-USER_scheduleSetupColl3_PROPERTIES
    },
    // txtcolType: {
    //   // name: "txtcolType",
    //   // type: "TextBoxWidget",
    //   // colName: "colType",
    //   // parent: "gridDiscountScheduleSetup",
    //   // CharWidth: "48",
    //   // Height: "",
    //   // Width: "",
    //   // HasLabel: false,
    //   // LengthRange: { MinLength: 0, MaxLength: 14 },
    //   // DecimalPlaces: 5,
    //   // ofTypeDomain: "d_float",

    //   // START_USER_CODE-USER_txtcolType_PROPERTIES

    //   // END_USER_CODE-USER_txtcolType_PROPERTIES
    // },

    scheduleSetupColl1: {
      name: "scheduleSetupColl1",
      type: "DropDownFieldWidget",
      colName: "colType",
      parent: "gridDiscountScheduleSetup",
      Options: "DAM:1,SS:2,FM:3,VICAM:4",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,
      DefaultValue: "",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_scheduleSetupColl1_PROPERTIES

      // END_USER_CODE-USER_scheduleSetupColl1_PROPERTIES
    },
    grpbxDiscountSchedule: {
      name: "grpbxDiscountSchedule",
      type: "GroupBoxWidget",
      parent: "DiscountScheduleSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDiscountSchedule_PROPERTIES

      // END_USER_CODE-USER_grpbxDiscountSchedule_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DiscountScheduleSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  
  useEffect(() => {
    let datafromparent = getData(thisObj, 'frmDiscountScheduleSetupData')
    if ( datafromparent != undefined &&  datafromparent != null && datafromparent != '') {
      // onbtnOkClick()
      parentData();
    }
    setData(thisObj, 'frmDiscountScheduleSetupData',"")
  }, [getData(thisObj, 'frmDiscountScheduleSetupData')])

  // START_USER_CODE-USER_METHODS
  const onbtnOkClick = () => {
    try {
      let o = ""
      let gridData = thisObj.values.gridDiscountScheduleSetup
      let gridAllData =''
      
      for (let i = 0; i < gridData.length; i++) {
        for (let j = 1; j <= 4; j++) {

          let colName = `scheduleSetupColl${j}`
          let gridValue = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", colName, i)
      
          if (gridValue == '') {
            alert("The Discout Schedule is incomplete!!!")
            return;
          }
          switch (j) {
            case 2:
              if (isNaN(gridValue) == false) {
                if (Number(gridValue) < 0 || Number(gridValue) > 999999.99) {
                  alert("Reading From must be of format ######.##.")
                  return;
                }
              } else {
                alert("Reading From must be of format ######.##.")
                return;
              }
              break;
            case 3:
              if (isNaN(gridValue) == false) {
                if (Number(gridValue) < 0 || Number(gridValue) > 999999.99) {
                  alert("Reading To must be of format ######.##.")
                  return;
                }
              } else {
                alert("Reading To must be of format ######.##.")
                return;
              }
              break;
            case 4:
              if (isNaN(gridValue) == false) {
                if (Number(gridValue) < 0 || Number(gridValue) > 9999.99) {
                  alert("Discount Per Ton must be of format ####.##.")
                  return;
                }
              } else {
                alert("Discount Per Ton must be of format ####.##.")
                return;
              }
              break;
          }
        }
        let readingFrom = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl2", i)
        let readingTo = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl3", i)
        if(Number(readingFrom) > Number(readingTo)){
             alert("Reading From must be less than Reading To.")
             return;
        }
      
        for(let k = 0; k < gridData.length; k++){
          let type_i = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl1", i)
          let type_k = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl1", k)
          let readingForm_i = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl2", i)
          let readingForm_k = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl2", k)
          let readingTo_i = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl3", i)
          let readingTo_k = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl3", k)

          if (i != k) {
            if (type_i == type_k) {
              if (((Number(readingForm_i) >= Number(readingForm_k)) && Number((readingForm_i) <= Number(readingTo_k))) || ((Number(readingTo_i) >= Number(readingForm_k)) && (Number(readingTo_i) <= Number(readingTo_k)))) {
                let typeName = ''
                if(type_i == 1 || type_i == 'DAM'){
                  typeName = "DAM"
                }else if(type_i == 2|| type_i == 'SS'){
                  typeName = "SS"
                }else if (type_i == 3 || type_i == 'FM'){
                  typeName = "FM"
                }else{
                  typeName ="VICAM"
                }
                // typeName = type_i == '1' ? "DAM" : type_i == '2' ? "SS" : type_i == '3' ? "FM" : "VICAM"
                alert(`Overlap exists in ranges for ${typeName}.`)
                return;
              }
            }
          }
        }
      }
      for (let m = 0; m < gridData.length; m++) {
        let colData1 = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl1", m)
        let dropDownName = ''
        let dropdownIndex 
        // dropDownName = colfData1 == '1' ? "DAM" : colData1 == '2' ? "SS" : colData1 == '3' ? "FM" : "VICAM"
        if(colData1 == "DAM" || colData1 ==1 ){
          dropDownName = "DAM"
          dropdownIndex = 1
        }else if(colData1 == "SS" || colData1 ==2 ){
          dropDownName = "SS"
          dropdownIndex = 2
        }else if (colData1 == "FM" || colData1 ==3 ){
          dropDownName = "FM"
          dropdownIndex = 3
        }else{
          dropDownName ="VICAM"
          dropdownIndex = 4
        }
        let colData2 = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl2", m)
        let colData3 = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl3", m)
        let colData4 = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup" ,"scheduleSetupColl4", m)
        gridAllData += `${dropDownName}` + "|" + m + "|" + colData2 + "|" + colData3 + "|" + colData4 + "~"
          // gridAllData.push(rowData)
        }
        

        setData(thisObj, "PriceScheduleSetup", gridAllData)
        document.getElementById("SystemMaintenanceSpecialFunctions_DiscountScheduleSetupPopUp").childNodes[0].click()

    } catch (err) {
      // setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on ok button click."
        );
      }
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  //cancel click
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_DiscountScheduleSetupPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

// Sorting Data -----
  function sortJSON(arr, key, asc=true) {   
    return arr.sort((a, b) => {     
      let x = a[key];     
      let y = b[key];     
      if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }     
      else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }   
    }); 
  }

  // Data coming from parent---
  const parentData = () => {
    try{
        let gridData= []
        let obj ={}
        let columnData1 = getData(thisObj, 'frmDiscountScheduleSetupData')
        let dropDownIndex
        for (let i = 0; i < columnData1.length; i++) {
          if(columnData1[i] !=""){
          let res = columnData1[i].split("|")
          if(res[0] =="DAM"){
              dropDownIndex = "1"
          }else if(res[0] =="SS"){
            dropDownIndex = "2"
          }else if(res[0] =="FM"){
            dropDownIndex = "3"
          }else if(res[0] =="VICAM"){
            dropDownIndex = "4"
          }
          if(res.length > 0){
            obj.scheduleSetupColl1 = dropDownIndex
            obj.scheduleSetupColl2 = res[2]
            obj.scheduleSetupColl3 = res[3]
            obj.scheduleSetupColl4 = res[4]
          }
          gridData.push(obj)
          obj = {}
          }
        }
        gridData = sortJSON(gridData, 'scheduleSetupColl1', true)
        setValue(thisObj, 'gridDiscountScheduleSetup',gridData)
        
    }catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on parentData click."
        );
      }
    }
  }

// Change event for columns ----

  // const onscheduleSetupColl2Blur = async() => {
  //   let gridData = getData(thisObj, 'gridDiscountScheduleSetup')
  //   let RowNum = getSelectedRowNumber(thisObj, 'gridDiscountScheduleSetup')
  //   let Value = ''
  //   if(RowNum != undefined && RowNum != null && RowNum != ''){
  //     Value = getGridValue(thisObj, 'gridDiscountScheduleSetup', 'scheduleSetupColl2', RowNum)
  //   }
  // }
  // thisObj.onscheduleSetupColl2Blur = onscheduleSetupColl2Blur;

  

  // const onscheduleSetupColl2Change = () => {
  //   let TotalGridValue = getValue(thisObj, "gridDiscountScheduleSetup")
  //   for (let i = 0; i < TotalGridValue.length; i++) {
  //     if (TotalGridValue[i].scheduleSetupColl2 !== null && TotalGridValue[i].scheduleSetupColl2 !== undefined && TotalGridValue[i].scheduleSetupColl2 !== '' && TotalGridValue[i].scheduleSetupColl2 !== 0) {
  //       TotalGridValue[i].scheduleSetupColl2 = Number(TotalGridValue[i].scheduleSetupColl2).toFixed(2);
  //     }
  //   }
  // }
  // thisObj.onscheduleSetupColl2Change = onscheduleSetupColl2Change
 
  // const onscheduleSetupColl3Change = () => {
  //   let TotalGridValue = getValue(thisObj, "gridDiscountScheduleSetup")
  //   for (let i = 0; i < TotalGridValue.length; i++) {
  //     if (TotalGridValue[i].scheduleSetupColl3 !== null && TotalGridValue[i].scheduleSetupColl3 !== undefined && TotalGridValue[i].scheduleSetupColl3 !== '' && TotalGridValue[i].scheduleSetupColl3 !== 0) {
  //       TotalGridValue[i].scheduleSetupColl3 = Number(TotalGridValue[i].scheduleSetupColl3).toFixed(2);
  //     }
  //   }
  // }
  // thisObj.onscheduleSetupColl3Change = onscheduleSetupColl3Change

  // const onscheduleSetupColl4Change = () => {
  //   let TotalGridValue = getValue(thisObj, "gridDiscountScheduleSetup")
  //   for (let i = 0; i < TotalGridValue.length; i++) {
  //     if (TotalGridValue[i].scheduleSetupColl4 !== null && TotalGridValue[i].scheduleSetupColl4 !== undefined && TotalGridValue[i].scheduleSetupColl4 !== '' && TotalGridValue[i].scheduleSetupColl4 !== 0) {
  //       TotalGridValue[i].scheduleSetupColl4 = Number(TotalGridValue[i].scheduleSetupColl4).toFixed(2);
  //     }
  //   }
  // }
  // thisObj.onscheduleSetupColl4Change = onscheduleSetupColl4Change

  //Blur event
  const onscheduleSetupColl2Blur = (event) => {
    let nameEvent = event.target.name
    let arr = (nameEvent.split(".")[1])
    // let rowNumber = getSelectedRowNumber(_kaledo.thisObj, "gridDiscountScheduleSetup")
    let gridValue = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl2",arr)
    if (isNaN(gridValue)) {
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl2", arr, gridValue)
    }
    else if (gridValue == ""){
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl2", arr, gridValue)
    }  
    else{
      gridValue = Number(gridValue).toFixed(2)
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl2", arr, gridValue)
    }
  }
  thisObj.onscheduleSetupColl2Blur = onscheduleSetupColl2Blur

  const onscheduleSetupColl3Blur = (event) => {
    let nameEvent = event.target.name
    let arr = (nameEvent.split(".")[1])
    // let rowNumber = getSelectedRowNumber(_kaledo.thisObj, "gridDiscountScheduleSetup")
    let gridValue = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl3",arr)
    if (isNaN(gridValue)) {
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl3", arr, gridValue)
    }
    else if (gridValue == ""){
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl3", arr, gridValue)
    }  
    else{
      gridValue = Number(gridValue).toFixed(2)
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl3", arr, gridValue)
    }
  }
  thisObj.onscheduleSetupColl3Blur = onscheduleSetupColl3Blur

  const onscheduleSetupColl4Blur = (event) => {
    let nameEvent = event.target.name
    let arr = (nameEvent.split(".")[1])
    // let rowNumber = getSelectedRowNumber(_kaledo.thisObj, "gridDiscountScheduleSetup")
    let gridValue = getGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl4",arr)
    if (isNaN(gridValue)) {
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl4", arr, gridValue)
    }
    else if (gridValue == ""){
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl4", arr, gridValue)
    }  
    else{
      gridValue = Number(gridValue).toFixed(2)
      setGridValue(_kaledo.thisObj, "gridDiscountScheduleSetup", "scheduleSetupColl4", arr, gridValue)
    }
  }
  thisObj.onscheduleSetupColl4Blur = onscheduleSetupColl4Blur


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DiscountScheduleSetup*/}

              {/* END_USER_CODE-USER_BEFORE_DiscountScheduleSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDiscountSchedule*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDiscountSchedule*/}

              <GroupBoxWidget
                conf={state.grpbxDiscountSchedule}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblDiscountSchedule*/}

                {/* END_USER_CODE-USER_BEFORE_lblDiscountSchedule*/}

                <LabelWidget
                  values={values}
                  conf={state.lblDiscountSchedule}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblDiscountSchedule*/}

                {/* END_USER_CODE-USER_AFTER_lblDiscountSchedule*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridDiscountScheduleSetup*/}

                {/* END_USER_CODE-USER_BEFORE_gridDiscountScheduleSetup*/}

                <GridWidget
                  conf={state.gridDiscountScheduleSetup}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridDiscountScheduleSetup}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridDiscountScheduleSetup*/}

                {/* END_USER_CODE-USER_AFTER_gridDiscountScheduleSetup*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDiscountSchedule*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDiscountSchedule*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DiscountScheduleSetup*/}

              {/* END_USER_CODE-USER_AFTER_DiscountScheduleSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_DiscountScheduleSetup
);
