/* eslint-disable*/
import React from "react";
import ContractManagement_header from "./Header";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../common/renderTestScreen";
import { t } from "../../../__mocks__/react-i18next";

describe("header Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_header />);
    });
  });
  afterEach(cleanup);
  test("is header Loads Successfully", () => {
    expect(screen.getByText("header")).toBeInTheDocument;
  });
  test("Custom Test Cases for header", () => {
    // START_USER_CODE-USER_header_Custom_Test_Case
    // END_USER_CODE-USER_header_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_header />);
    });
  });
  afterEach(cleanup);
  test("grpbxHeader(GroupBox Widget) Test Cases", async () => {
    const grpbxHeader = screen.getByTestId("grpbxHeader");
    expect(grpbxHeader.tagName).toBe("BUTTON");
    expect(grpbxHeader.type).toBe("button");
    expect(grpbxHeader.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxHeader", () => {
    // START_USER_CODE-USER_grpbxHeader_TEST
    // END_USER_CODE-USER_grpbxHeader_TEST
  });
  test("lblGoldnPnutCmpny(Label Widget) Test Cases", async () => {
    const lblGoldnPnutCmpny = screen.getByTestId("lblGoldnPnutCmpny");
    expect(lblGoldnPnutCmpny.tagName).toBe("LABEL");
    expect(lblGoldnPnutCmpny.classList).toContain("form-label");
    expect(lblGoldnPnutCmpny.textContent).toEqual(
      t("ContractManagement:header_lblGoldnPnutCmpny")
    );
  });
  test("Custom Test Cases for lblGoldnPnutCmpny", () => {
    // START_USER_CODE-USER_lblGoldnPnutCmpny_TEST
    // END_USER_CODE-USER_lblGoldnPnutCmpny_TEST
  });
  test("txtScrName(Textbox Widget) Test Cases", async () => {
    const txtScrName = screen.getByTestId("txtScrName");
    expect(txtScrName.tagName).toBe("INPUT");
    expect(txtScrName.type).toBe("text");
    expect(txtScrName.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtScrName, "1");
    });
  });
  test("Custom Test Cases for txtScrName", () => {
    // START_USER_CODE-USER_txtScrName_TEST
    // END_USER_CODE-USER_txtScrName_TEST
  });
  test("txtScrNmbr(Textbox Widget) Test Cases", async () => {
    const txtScrNmbr = screen.getByTestId("txtScrNmbr");
    expect(txtScrNmbr.tagName).toBe("INPUT");
    expect(txtScrNmbr.type).toBe("text");
    expect(txtScrNmbr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtScrNmbr, "1");
    });
  });
  test("Custom Test Cases for txtScrNmbr", () => {
    // START_USER_CODE-USER_txtScrNmbr_TEST
    // END_USER_CODE-USER_txtScrNmbr_TEST
  });
  test("txtYear(Textbox Widget) Test Cases", async () => {
    const txtYear = screen.getByTestId("txtYear");
    expect(txtYear.tagName).toBe("INPUT");
    expect(txtYear.type).toBe("text");
    expect(txtYear.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtYear, "1");
    });
  });
  test("Custom Test Cases for txtYear", () => {
    // START_USER_CODE-USER_txtYear_TEST
    // END_USER_CODE-USER_txtYear_TEST
  });
});
