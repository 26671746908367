/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  setValue,
  disable,
  setData,
  getValue,
  getData,
  enable,
  hideColumn,
  showColumn,
  getSelectedRowNumber,
  show,
  hide,
  getGridValue,
  getRowCount,
  rowNumber,
  colNumber
} from "../../shared/WindowImports";

import "./WarehouseReceiptUpload.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { useDropzone } from "react-dropzone";
import ModalPopUp from "react-bootstrap/Modal";
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions";
import jsPDF from 'jspdf';
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WarehouseReceiptUpload(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const [isShow, invokeModal] = React.useState(false);
  const [files, setFiles] = useState([])
  const [gridcolor, setgridcolor] = useState([]);
  let user_id = sessionStorage.getItem("userid"); 
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseReceiptUpload",
    windowName: "WarehouseReceiptUpload",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WarehouseReceiptUpload",
    // START_USER_CODE-USER_WarehouseReceiptUpload_PROPERTIES
    horizontalForm : true,
    headerData: {
      scrName: "Warehouse Receipt Upload",
      scrCode: "PN1210D",
    },
    // END_USER_CODE-USER_WarehouseReceiptUpload_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnImportLockin: {
      name: "btnImportLockin",
      type: "ButtonWidget",
      parent: "grpbxuploadlockinfobuttons",
      Label: "Import Lock In",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnImportLockin_PROPERTIES

      // END_USER_CODE-USER_btnImportLockin_PROPERTIES
    },
    btnPrintResult: {
      name: "btnPrintResult",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Print Result",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintResult_PROPERTIES

      // END_USER_CODE-USER_btnPrintResult_PROPERTIES
    },
    btnUpdateWR: {
      name: "btnUpdateWR",
      type: "ButtonWidget",
      parent: "grpbxuploadlockinfobuttons",
      Label: "Update WR",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdateWR_PROPERTIES

      // END_USER_CODE-USER_btnUpdateWR_PROPERTIES
    },
    btnViewlockin: {
      name: "btnViewlockin",
      type: "ButtonWidget",
      parent: "grpbxuploadlockinfobuttons",
      Label: "View Lock In",
      CharWidth: "27",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewlockin_PROPERTIES

      // END_USER_CODE-USER_btnViewlockin_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colLockInCCCLocation: {
      name: "colLockInCCCLocation",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Lock In CCC Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInCCCLocation_PROPERTIES

      // END_USER_CODE-USER_colLockInCCCLocation_PROPERTIES
    },
    colLockInCropYear: {
      name: "colLockInCropYear",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Lock In Crop Year",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInCropYear_PROPERTIES

      // END_USER_CODE-USER_colLockInCropYear_PROPERTIES
    },
    colLockInDate: {
      name: "colLockInDate",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Lock In Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate_PROPERTIES

      // END_USER_CODE-USER_colLockInDate_PROPERTIES
    },
    colLockinRate: {
      name: "colLockinRate",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Lock In Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockinRate_PROPERTIES

      // END_USER_CODE-USER_colLockinRate_PROPERTIES
    },
    colLockInWR: {
      name: "colLockInWR",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Lock In WR #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInWR_PROPERTIES

      // END_USER_CODE-USER_colLockInWR_PROPERTIES
    },
    ddLoadHistory: {
      name: "ddLoadHistory",
      type: "DropDownFieldWidget",
      parent: "grpbxLoadhistory",
      Label: "Load History",
      ColSpan: "3",
      ColSpanForLargeDesktop: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLoadHistory_PROPERTIES

      // END_USER_CODE-USER_ddLoadHistory_PROPERTIES
    },
    gridUploadLockInfo: {
      name: "gridUploadLockInfo",
      type: "GridWidget",
      parent: "grpbxUploadLockInfo",
      gridCellsOrder:
        "txtcolLockInCropYear,txtcolLockInCCCLoc,txtcolLckInWR,txtcolLockInRate,txtcolLockInDate,txtcolWhseRcptNum,txtcolBuyPtId,txtcolOrgBuyPtId,txtcolLoanRepayRate,txtcolLoanLockInDate,txtcolAction",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridUploadLockInfo_PROPERTIES

      // END_USER_CODE-USER_gridUploadLockInfo_PROPERTIES
    },
    grpbxLoadhistory: {
      name: "grpbxLoadhistory",
      type: "GroupBoxWidget",
      parent: "grpbxUploadLockInfo",
      ColSpan: "1",
      ColSpanForLargeDesktop: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoadhistory_PROPERTIES

      // END_USER_CODE-USER_grpbxLoadhistory_PROPERTIES
    },
    grpbxuploadlockinfobuttons: {
      name: "grpbxuploadlockinfobuttons",
      type: "GroupBoxWidget",
      parent: "grpbxUploadLockInfo",
      ColSpan: "1",
      ColSpanForLargeDesktop: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxuploadlockinfobuttons_PROPERTIES

      // END_USER_CODE-USER_grpbxuploadlockinfobuttons_PROPERTIES
    },
    lbladdedBy: {
      name: "lbladdedBy",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbladdedBy_PROPERTIES

      // END_USER_CODE-USER_lbladdedBy_PROPERTIES
    },
    lblAddedbyvalue: {
      name: "lblAddedbyvalue",
      type: "LabelWidget",
      parent: "grpbxAction",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedbyvalue_PROPERTIES

      // END_USER_CODE-USER_lblAddedbyvalue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedbyvalue: {
      name: "lblChangedbyvalue",
      type: "LabelWidget",
      parent: "grpbxAction",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedbyvalue_PROPERTIES

      // END_USER_CODE-USER_lblChangedbyvalue_PROPERTIES
    },
    lblLoadHistory: {
      name: "lblLoadHistory",
      type: "LabelWidget",
      parent: "grpbxLoadhistory",
      Label: "Load History",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoadHistory_PROPERTIES

      // END_USER_CODE-USER_lblLoadHistory_PROPERTIES
    },
    lblUpload: {
      name: "lblUpload",
      type: "LabelWidget",
      parent: "grpbxLoadhistory",
      Label: "Upload Lock In Infomration",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoadHistory_PROPERTIES

      // END_USER_CODE-USER_lblLoadHistory_PROPERTIES
    },
    lblPPS: {
      name: "lblPPS",
      type: "LabelWidget",
      parent: "grpbxLoadhistory",
      Label: "PPS Warehouse Receipt Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoadHistory_PROPERTIES

      // END_USER_CODE-USER_lblLoadHistory_PROPERTIES
    },
    
    lblUploadlockInfo: {
      name: "lblUploadlockInfo",
      type: "LabelWidget",
      parent: "grpbxuploadlockinfobuttons",
      Label: "Upload Lock Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUploadlockInfo_PROPERTIES

      // END_USER_CODE-USER_lblUploadlockInfo_PROPERTIES
    },
    lblImportedLockInMsg: {
      name: "lblImportedLockInMsg",
      type: "LabelWidget",
      parent: "grpbxUploadLockInfo",
      Label: "Upload Lock Information:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblImportedLockInMsg_PROPERTIES

      // END_USER_CODE-USER_lblImportedLockInMsg_PROPERTIES
    },
    radioViewResults: {
      name: "radioViewResults",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxUploadLockInfo",
      Options: "All:1,Invalid:2,Valid:3",
      Label: "View Result:",
      ColSpanForLargeDesktop: "3",
      ColSpanForTabLandscape: "3",
      ColSpanForTabPotrait: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioViewResults_PROPERTIES

      // END_USER_CODE-USER_radioViewResults_PROPERTIES
    },
    colWhseRcptNum: {
      name: "colWhseRcptNum",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "WR #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseRcptNum_PROPERTIES

      // END_USER_CODE-USER_colWhseRcptNum_PROPERTIES
    },
    colBuyPtId: {
      name: "colBuyPtId",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Obl. Pt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPtId_PROPERTIES

      // END_USER_CODE-USER_colBuyPtId_PROPERTIES
    },
    colOrgBuyPtId: {
      name: "colOrgBuyPtId",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Org. Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrgBuyPtId_PROPERTIES

      // END_USER_CODE-USER_colOrgBuyPtId_PROPERTIES
    },
    colLoanRepayRate: {
      name: "colLoanRepayRate",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Loan Repay Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanRepayRate_PROPERTIES

      // END_USER_CODE-USER_colLoanRepayRate_PROPERTIES
    },
    colLoanLockInDate: {
      name: "colLoanLockInDate",
      type: "GridColumnWidget",
      parent: "gridUploadLockInfo",
      Label: "Loan Lock In Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanLockInDate_PROPERTIES

      // END_USER_CODE-USER_colLoanLockInDate_PROPERTIES
    },

  txtcolWhseRcptNum: {
      name: "txtcolWhseRcptNum",
      type: "TextBoxWidget",
      colName: "colWhseRcptNum",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhseRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtcolWhseRcptNum_PROPERTIES
    },
  txtcolBuyPtId: {
      name: "txtcolBuyPtId",
      type: "TextBoxWidget",
      colName: "colBuyPtId",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPtId_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPtId_PROPERTIES
    },
  txtcolOrgBuyPtId: {
      name: "txtcolOrgBuyPtId",
      type: "TextBoxWidget",
      colName: "colOrgBuyPtId",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrgBuyPtId_PROPERTIES

      // END_USER_CODE-USER_txtcolOrgBuyPtId_PROPERTIES
    },
  txtcolLoanRepayRate: {
      name: "txtcolLoanRepayRate",
      type: "TextBoxWidget",
      colName: "colLoanRepayRate",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanRepayRate_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanRepayRate_PROPERTIES
    },
  txtcolLoanLockInDate: {
      name: "txtcolLoanLockInDate",
      type: "TextBoxWidget",
      colName: "colLoanLockInDate",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanLockInDate_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanLockInDate_PROPERTIES
    },
    txtcolAction: {
      name: "txtcolAction",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAction_PROPERTIES

      // END_USER_CODE-USER_txtcolAction_PROPERTIES
    },
    txtcolLckInWR: {
      name: "txtcolLckInWR",
      type: "TextBoxWidget",
      colName: "colLockInWR",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLckInWR_PROPERTIES

      // END_USER_CODE-USER_txtcolLckInWR_PROPERTIES
    },
    txtcolLockInCCCLoc: {
      name: "txtcolLockInCCCLoc",
      type: "TextBoxWidget",
      colName: "colLockInCCCLocation",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLockInCCCLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInCCCLoc_PROPERTIES
    },
    txtcolLockInCropYear: {
      name: "txtcolLockInCropYear",
      type: "TextBoxWidget",
      colName: "colLockInCropYear",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLockInCropYear_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInCropYear_PROPERTIES
    },
    txtcolLockInDate: {
      name: "txtcolLockInDate",
      type: "TextBoxWidget",
      colName: "colLockInDate",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLockInDate_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate_PROPERTIES
    },
    txtcolLockInRate: {
      name: "txtcolLockInRate",
      type: "TextBoxWidget",
      colName: "colLockinRate",
      parent: "gridUploadLockInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLockInRate_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInRate_PROPERTIES
    },
    grpbxUploadLockInfo: {
      name: "grpbxUploadLockInfo",
      type: "GroupBoxWidget",
      parent: "WarehouseReceiptUpload",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxUploadLockInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxUploadLockInfo_PROPERTIES
    },
    grpbxAction: {
      name: "grpbxAction",
      type: "GroupBoxWidget",
      parent: "WarehouseReceiptUpload",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxAction_PROPERTIES

      // END_USER_CODE-USER_grpbxAction_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  async function FormLoad() {
    InitialLoad();
    //load history of lockin uploads
    await bFillLockInHeader();
    //load lock in data into grid that has not been upload
    await bfillImportedLockIn();
    setData(thisObj, 'onChangeValue', null);
    setData(thisObj, 'action', false);
    setLoading(false)
  }

  const InitialLoad = () => {
    setValue(thisObj, 'radioViewResults', '1');
    disable(thisObj, 'btnUpdateWR');
    hide(thisObj, 'lblUpload')
    hide(thisObj, 'lblPPS')
  }

  // Load history dropdown binding
  const bFillLockInHeader = async () => {
    try {
      let bFillLockInHeader = false;
      let js = [];
      js.push({ key: '', description: '' });
      let response = await WarehouseReceiptService.RetrieveWarehouseLockInPreviousLoadHeader();
      if (response != undefined && response.length > 0) {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].id, description: moment(data[i].add_date_time).format("MM/DD/YYYY h:mm:ss A")};
          js.push(obj);
        }
        bFillLockInHeader = true;
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLoadHistory: {
            ...state["ddLoadHistory"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddLoadHistory', js[0].key);
      setData(thisObj, 'loadHistData', js);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occured in bFillLockInHeader");
      }
      return false;
    }
  }

  // OnChange event for Load History dropdown
  const onLoadHistoryChange = async () => {
    try {
      setLoading(true);
      disable(thisObj, 'btnUpdateWR');
      setValue(thisObj, 'radioViewResults', '1');
      let historyId = '';
      let historyList = thisObj.state['ddLoadHistory'].valueList;
      let selectedhistoryListValue = thisObj.values['ddLoadHistory'];
      if (selectedhistoryListValue !== "" && selectedhistoryListValue !== undefined) {
        historyId = historyList.find(elem => elem.key == selectedhistoryListValue).key;
      }
      let actionData = getData(thisObj, 'action');
      if(actionData == true) {
        let ddValue = getData(thisObj, 'loadHistData');
        historyId = ddValue[1].key;
        setData(thisObj, 'action',false);//Refreshed the Flag as part of UAT BUG_610979.
      }
      let js = [];
      let obj = {};
      if (historyId == '') {
        setValue(thisObj, "gridUploadLockInfo", js);
        return;
      }
      else {
        let data = await WarehouseReceiptService.RetrieveWarehouseLockInPreviousLoadDetails(historyId);
        if (data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            show(thisObj, 'lblUpload');
            show(thisObj, 'lblPPS');
            showColumn(thisObj, 'gridUploadLockInfo', 'txtcolWhseRcptNum');
            showColumn(thisObj, 'gridUploadLockInfo', 'txtcolBuyPtId');
            showColumn(thisObj, 'gridUploadLockInfo', 'txtcolOrgBuyPtId');
            showColumn(thisObj, 'gridUploadLockInfo', 'txtcolLoanRepayRate');
            showColumn(thisObj, 'gridUploadLockInfo', 'txtcolLoanLockInDate');
            obj.txtcolLockInCropYear = data[i].li_crop_year;
            obj.txtcolLockInCCCLoc = data[i].li_ccc_location_id;
            if (data[i].li_whse_rcpt_num == '0' && data[i].valid_data == 'N') {
              obj.txtcolLckInWR = '';
            }
            else {
              obj.txtcolLckInWR = data[i].li_whse_rcpt_num;
            }
            obj.txtcolLockInRate = data[i].li_lock_rate;
            if (data[i].li_lock_date == '') {
              obj.txtcolLockInDate = '';
            }
            else {
              obj.txtcolLockInDate = moment(data[i].li_lock_date).format("MM/DD/YYYY");
            }
            if (data[i].wr_whse_rcpt_num == '0' && data[i].valid_data == 'N') {
              obj.txtcolWhseRcptNum = '';
            }
            else {
              obj.txtcolWhseRcptNum = data[i].wr_whse_rcpt_num;
            }
            obj.txtcolBuyPtId = data[i].wr_buy_pt_id;
            obj.txtcolOrgBuyPtId = data[i].wr_org_buy_pt_id;
            if (data[i].wr_loan_repay_rate == '0' && data[i].valid_data == 'N') {
              obj.txtcolLoanRepayRate = '';
            }
            else if (data[i].wr_loan_repay_rate == '0.00') {
              obj.txtcolLoanRepayRate = '0';
            }
            else {
              obj.txtcolLoanRepayRate = data[i].wr_loan_repay_rate;
            }
            if (data[i].action == 'Updated lock-in date.') {
              obj.txtcolLoanLockInDate = moment(data[i].wr_lrr_lock_date).format("MM/DD/YYYY");
            }
            else if (data[i].action == 'Cannot match warehouse receipt. No update.') {
              obj.txtcolLoanLockInDate = '';
            }
            else if (data[i].action == "Updated lock-in rate and date." && data[i].wr_lrr_lock_date !== '') {
              obj.txtcolLoanLockInDate = moment(data[i].wr_lrr_lock_date).format("MM/DD/YYYY");
            }
            else if (data[i].action == "Updated lock-in rate and date." && data[i].wr_lrr_lock_date == '') {
              obj.txtcolLoanLockInDate = '';
            }
            else if (data[i].action == 'Invalid lock in data') {
              obj.txtcolLoanLockInDate = '';
            }
            else if ((data[i].wr_lrr_lock_date !== data[i].li_lock_date) && data[i].action == 'No update.') {
              obj.txtcolLoanLockInDate = data[i].wr_lrr_lock_date;
            }
            else {
              obj.txtcolLoanLockInDate = moment(data[i].wr_lrr_lock_date).format("MM/DD/YYYY");
            }
            obj.txtcolAction = data[i].action;
            let addedBy = data[i].add_user;
            let changedBy = moment(data[i].add_date_time).format("MM/DD/YYYY h:mm:ss A");
            setValue(thisObj, "lblAddedbyvalue", addedBy);
            setValue(thisObj, "lblChangedbyvalue", changedBy);
  
            js.push(obj);
            obj = {};
          }
          setValue(thisObj, 'lblImportedLockInMsg', '');
        }
        setData(thisObj, 'onChangeValue', true);
        setValue(thisObj, "gridUploadLockInfo", js);
        setData(thisObj, "gridLoadData", js);
        setData(thisObj, "gridLoadData1", data);
        setRowColor(js);
      }
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occured in onLoadHistoryChange");
      }
      return false;
    }
    finally {
      setLoading(false);
    }
  }  
  thisObj.onddLoadHistoryChange = onLoadHistoryChange

  // bfillImportedLockIn grid binding
  const bfillImportedLockIn = async () => {
    try {
      setLoading(true);
      hide(thisObj, 'lblPPS');
      hide(thisObj, 'lblUpload');
      let response = await WarehouseReceiptService.RetrieveWarehouseLockinList();
      let js = [];
      if (response != undefined && response.length > 0) {
        show(thisObj, 'lblUpload');
        let data = response;
        let obj = {};
        for (var i = 0; i < data.length; i++) {
          obj.txtcolLockInCropYear = data[i].li_crop_year;
          obj.txtcolLockInCCCLoc = data[i].li_ccc_location_id;
          if (data[i].li_whse_rcpt_num == '0' && data[i].valid_data == 'N') {
            obj.txtcolLckInWR = '';
          }
          else {
            obj.txtcolLckInWR = data[i].li_whse_rcpt_num;
          }
          obj.txtcolLockInRate = data[i].li_lock_rate;
          obj.txtcolLockInDate = moment(data[i].li_lock_date).format("MM/DD/YYYY");
          if (data[i].valid_data == 'N') {
            obj.txtcolAction = 'Invalid data';
          }
          else {
            obj.txtcolAction = '';
          }
          let addedBy = data[i].add_user;
          let changedBy = moment(data[i].add_date_time).format("MM/DD/YYYY h:mm:ss A");
          setValue(thisObj, "lblAddedbyvalue", addedBy);
          setValue(thisObj, "lblChangedbyvalue", changedBy);
          hideColumn(thisObj, 'gridUploadLockInfo','txtcolWhseRcptNum');
          hideColumn(thisObj, 'gridUploadLockInfo','txtcolBuyPtId');
          hideColumn(thisObj, 'gridUploadLockInfo','txtcolOrgBuyPtId');
          hideColumn(thisObj, 'gridUploadLockInfo','txtcolLoanRepayRate');
          hideColumn(thisObj, 'gridUploadLockInfo','txtcolLoanLockInDate');

          js.push(obj);
          obj = {};
        }
        enable(thisObj, 'btnUpdateWR'); 
        setValue(thisObj, 'lblImportedLockInMsg', "The following records were imported but have not been updated to WRs.");
      }
      else {
        disable(thisObj, 'btnUpdateWR');
        setValue(thisObj, "lblAddedbyvalue", '');
        setValue(thisObj, "lblChangedbyvalue", '');
      }
      setValue(thisObj, "gridUploadLockInfo", js);
      setData(thisObj, "gridData", js);
      setRowColor(js);
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occured in bfillImportedLockIn");
      }
      return false;
    }
  }

  // setRowColor function
  const setRowColor = (gridColor) => {
    let val = gridColor;
      for (let i = 0; i < val.length; i++) {
        if (val[i].txtcolAction == "Invalid data") {
          document.querySelector(".gridUploadLockInfo").querySelector(`#row-${i}`).style.color = 'red';
        }
        else {
          document.querySelector(".gridUploadLockInfo").querySelector(`#row-${i}`).style.color = 'black';
        }
      }
    }
    
  // Import LockIn button functionality
  const onbtnImportLockinClick = async () => {
    try {
      let sLockIns = getData(thisObj, 'lockInData');
      if (sLockIns == null || sLockIns == undefined) {
        let response = await WarehouseReceiptService.RetrieveWarehouseLockinList();
        if (response.length > 0) {
          let res = confirm("There are existing rows that have not been updated to WR.  Do you want to delete them?");
          if (res == false) {
            return;
          }
          else {
            invokeModal(true)
            thisObj.setState((current) => {
              return {
                ...current,
                dropzoneFlag: !thisObj.state.dropzoneFlag
              }
            })
          }
        }
        else {
          invokeModal(true)
          thisObj.setState((current) => {
            return {
              ...current,
              dropzoneFlag: !thisObj.state.dropzoneFlag
            }
          })
        }
      }
      else {
        setLoading(true);
        let temp = {
          "sLockIn": sLockIns,
          "user_id": user_id
        }
        let response = await WarehouseReceiptService.UpdateWarehouseLockInImport(temp);
        if (response.status == 200) {
          await bfillImportedLockIn();
          setData(thisObj, 'lockInData', null);
          setValue(thisObj, 'radioViewResults', '1');
          setData(thisObj, 'onChangeValue', false);
          let ddValue = getData(thisObj, 'loadHistData');
          setValue(thisObj, 'ddLoadHistory', (ddValue[0].key).toString());
        }
        else if (response.message.includes("Invalid length parameter passed to the LEFT or SUBSTRING function.\r\nInvalid length parameter passed to the LEFT or SUBSTRING function.\r\nInvalid length parameter passed to the LEFT or SUBSTRING function.\r\nInvalid length parameter passed to the LEFT or SUBSTRING function.\r\nConversion failed when converting date and/or time from character string.")) {
          let arrayTemp = temp.sLockIn.split('|');
          let js = [];
          let obj = {};
          for (var i = 0; i < arrayTemp.length; i++) {
            let dataObj = {
              "sLockIn": arrayTemp[i] + '|',
              "user_id": user_id
            }
            response = await WarehouseReceiptService.UpdateWarehouseLockInImport(dataObj);
            if (response.message.includes("Invalid length parameter passed to the LEFT or SUBSTRING function.\r\nInvalid length parameter passed to the LEFT or SUBSTRING function.\r\nInvalid length parameter passed to the LEFT or SUBSTRING function.\r\nInvalid length parameter passed to the LEFT or SUBSTRING function.\r\nConversion failed when converting date and/or time from character string.")) {
              continue;
            }
            else if (response.status == 200) {
              obj = arrayTemp[i] + '|';
              js.push(obj);
            }
          }
          let data = [];
          for (var i = 0; i < js.length; i++) {
            if (js[i] == '|') {
              continue;
            }
            data.push(js[i]);
          }
          let newsLockIns = data.join("");
          let newdataObj = {
            "sLockIn": newsLockIns,
            "user_id": user_id
          }
          response = await WarehouseReceiptService.UpdateWarehouseLockInImport(newdataObj);
          if (response.status == 200) {
            await bfillImportedLockIn();
            setData(thisObj, 'lockInData', null);
            setValue(thisObj, 'radioViewResults', '1');
            setData(thisObj, 'onChangeValue', false);
            let ddValue = getData(thisObj, 'loadHistData');
            setValue(thisObj, 'ddLoadHistory', (ddValue[0].key).toString());
          }
        }
        else {
          alert("An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist." + response.message);
          setData(thisObj, 'lockInData', null);// Refreshed the Flag as part of UAT BUG_610979 after getting error upload one not working every new time.
          setLoading(false);
          return;
        }
        setLoading(false);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "onbtnImportLockinClick");
      }
      return false;
    }
    return true
  };
  thisObj.onbtnImportLockinClick = onbtnImportLockinClick;


  const { getRootProps, getInputProps } = useDropzone({
    accept : [".txt", ".csv"],
    onDrop: async(acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (!["txt", "csv"].includes(acceptedFiles[0].name.toLowerCase().split(".")[1])) {
          invokeModal(false);
          alert(`File must be in ".txt" / ".csv" format`);
          return;
        }
        invokeModal(false)
        await readTextFile(acceptedFiles[0]);
      }
    },
    onFileDialogCancel: ()=> {
      invokeModal(false)
    }
  })

  const readTextFile = async (file) => {
    try{
      let sLockIns = '';
    var reader = new FileReader();
    reader.onload = function (event) {
      var contents = event.target.result;
      if (contents !== '' && contents.length > 0) {
        let arr = contents.split(/\r?\n/).map(String);
        let js = [];
        let obj = {};
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] === '') {
            continue;
          }
          else {
            obj = arr[i] + '|';
            js.push(obj);
          }
        }
        let data = [];
        for (var i = 0; i < js.length; i++) {
          data.push(js[i]);
        }
        sLockIns = data.join("");
      }
      else {
        if (sLockIns == '') {
          alert("There is no lock in information.");
          return;
        }
      }
      setData(thisObj, 'lockInData', sLockIns);
      onbtnImportLockinClick();
    };
    reader.readAsText(file);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "onreadTextFile");
      }
      return false;
    } 
  }

// View button functionality
const onbtnViewlockinClick = async() => {
  try {
    setLoading(true)
    setValue(thisObj, 'radioViewResults', '1');
    setData(thisObj, 'onChangeValue', false);
    await bfillImportedLockIn();
    await bFillLockInHeader();
    setLoading(false)
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(thisObj, "onbtnViewlockinClick");
    }
    return false;
  }
}
thisObj.onbtnViewlockinClick = onbtnViewlockinClick

// Update button functionality
const onbtnUpdateWRClick = async() => {
  try {
    let gridData = getValue(thisObj, 'gridUploadLockInfo');
    if (gridData == [] || gridData == null || gridData == undefined) {
      showMessage(thisObj, "No data to update warehouse receipts.");
      return;
    }
    else {
      let status = 'no';
      for (var i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolAction == 'Invalid data') {
          status = 'yes';
          break;
        }
      }
      if (status == 'yes') {
        let res = confirm("There are invalid data.  Do you want to continue?");
        if (res == false) {
          return;
        }
      }
      let temp = {
        "user_id": user_id
      }
      let response = await WarehouseReceiptService.UpdateWarehouseLockinDetails(temp);
      if (response.status == 200) {
        showMessage(thisObj, "Warehouse receipts were successful updated.", true);
        await bFillLockInHeader();
        setData(thisObj, 'action', true);
        let ddValue = getData(thisObj, 'loadHistData');
        setValue(thisObj, 'ddLoadHistory', (ddValue[1].key).toString());
        await onLoadHistoryChange();
        setData(thisObj, 'lockInData', null);
      }
      else {
        alert("An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist."+ response.message);
        return;
      }
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(thisObj, "onbtnUpdateWRClick");
    }
    return false;
  }
}
thisObj.onbtnUpdateWRClick = onbtnUpdateWRClick

// Radio button function
const onradioViewResultsChange = async() => {
  try {
    setLoading(true);
    let rdoBtnValue = getValue(thisObj, 'radioViewResults');
    let gridData = getData(thisObj, "gridData");
    let gridLoadData = getData(thisObj, "gridLoadData");
    let gridLoadData1 = getData(thisObj, "gridLoadData1");
    let onChangeValue = getData(thisObj, 'onChangeValue');
    let js = [];
    let obj = {};
    if (rdoBtnValue == '1' && (onChangeValue == false || onChangeValue == null)) {
      await bfillImportedLockIn();
      setRowColor(js);
    }
    if (rdoBtnValue == '2' && (onChangeValue == false || onChangeValue == null)) {
      await bfillImportedLockIn();
      for (var i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolAction == 'Invalid data') {
          obj.txtcolLockInCropYear = gridData[i].txtcolLockInCropYear;
          obj.txtcolLockInCCCLoc = gridData[i].txtcolLockInCCCLoc;
          obj.txtcolLckInWR = gridData[i].txtcolLckInWR;
          obj.txtcolLockInRate = gridData[i].txtcolLockInRate;
          obj.txtcolLockInDate = moment(gridData[i].txtcolLockInDate).format("MM/DD/YYYY");
          obj.txtcolAction = 'Invalid data';
          js.push(obj);
          obj = {};
        }
      }
      setValue(thisObj, "gridUploadLockInfo", js);
      setRowColor(js);
    }
    if (rdoBtnValue == '3' && (onChangeValue == false || onChangeValue == null)) {
      await bfillImportedLockIn();
      for (var i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolAction == '') {
          obj.txtcolLockInCropYear = gridData[i].txtcolLockInCropYear;
          obj.txtcolLockInCCCLoc = gridData[i].txtcolLockInCCCLoc;
          obj.txtcolLckInWR = gridData[i].txtcolLckInWR;
          obj.txtcolLockInRate = gridData[i].txtcolLockInRate;
          obj.txtcolLockInDate = moment(gridData[i].txtcolLockInDate).format("MM/DD/YYYY");
          obj.txtcolAction = '';
          js.push(obj);
          obj = {};
        }
      }
      setValue(thisObj, "gridUploadLockInfo", js);
      setRowColor(js);
    }
    if (rdoBtnValue == '1' && onChangeValue == true) {
      await onLoadHistoryChange();
      setValue(thisObj, 'radioViewResults', '1');
    }
    if (rdoBtnValue == '2' && onChangeValue == true) {
      await onLoadHistoryChange();
      setValue(thisObj, 'radioViewResults', '2');
      for (var i = 0; i < gridLoadData.length; i++) {
        if (gridLoadData1[i].valid_data == 'N') {
          show(thisObj, 'lblUpload');
          show(thisObj, 'lblPPS');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolWhseRcptNum');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolBuyPtId');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolOrgBuyPtId');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolLoanRepayRate');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolLoanLockInDate');
          obj.txtcolLockInCropYear = gridLoadData[i].txtcolLockInCropYear;
          obj.txtcolLockInCCCLoc = gridLoadData[i].txtcolLockInCCCLoc;
          obj.txtcolLckInWR = gridLoadData[i].txtcolLckInWR;
          obj.txtcolLockInRate = gridLoadData[i].txtcolLockInRate;
          obj.txtcolLockInDate = gridLoadData[i].txtcolLockInDate;
          obj.txtcolWhseRcptNum = gridLoadData[i].txtcolWhseRcptNum;
          obj.txtcolBuyPtId = gridLoadData[i].txtcolBuyPtId;
          obj.txtcolOrgBuyPtId = gridLoadData[i].txtcolOrgBuyPtId;
          obj.txtcolLoanRepayRate = gridLoadData[i].txtcolLoanRepayRate;
          obj.txtcolLoanLockInDate = gridLoadData[i].txtcolLoanLockInDate;
          obj.txtcolAction = gridLoadData[i].txtcolAction;
          
          js.push(obj);
          obj = {};
        }
      }
      setValue(thisObj, "gridUploadLockInfo", js);
    }
    if (rdoBtnValue == '3' && onChangeValue == true) {
      await onLoadHistoryChange();
      setValue(thisObj, 'radioViewResults', '3');
      for (var i = 0; i < gridLoadData.length; i++) {
        if (gridLoadData1[i].valid_data == 'Y') {
          show(thisObj, 'lblUpload');
          show(thisObj, 'lblPPS');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolWhseRcptNum');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolBuyPtId');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolOrgBuyPtId');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolLoanRepayRate');
          showColumn(thisObj, 'gridUploadLockInfo', 'txtcolLoanLockInDate');
          obj.txtcolLockInCropYear = gridLoadData[i].txtcolLockInCropYear;
          obj.txtcolLockInCCCLoc = gridLoadData[i].txtcolLockInCCCLoc;
          obj.txtcolLckInWR = gridLoadData[i].txtcolLckInWR;
          obj.txtcolLockInRate = gridLoadData[i].txtcolLockInRate;
          obj.txtcolLockInDate = gridLoadData[i].txtcolLockInDate;
          obj.txtcolWhseRcptNum = gridLoadData[i].txtcolWhseRcptNum;
          obj.txtcolBuyPtId = gridLoadData[i].txtcolBuyPtId;
          obj.txtcolOrgBuyPtId = gridLoadData[i].txtcolOrgBuyPtId;
          obj.txtcolLoanRepayRate = gridLoadData[i].txtcolLoanRepayRate;
          obj.txtcolLoanLockInDate = gridLoadData[i].txtcolLoanLockInDate;
          obj.txtcolAction = gridLoadData[i].txtcolAction;
          
          js.push(obj);
          obj = {};
        }
      }
      setValue(thisObj, "gridUploadLockInfo", js);
    }
    setLoading(false);
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(thisObj, "onradioViewResultsChange");
    }
    return false;
  }
}
thisObj.onradioViewResultsChange = onradioViewResultsChange

// Export to excel functionality
  const onbtnExportToExcelClick = async () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let datatable = getValue(thisObj, 'gridUploadLockInfo');
      let onChangeValue = getData(thisObj, 'onChangeValue');
      // setRowColor(datatable);
      var fileName = "Lock In";
      let excelData = [];
      if (onChangeValue == null || onChangeValue == false) {
        excelData = [{
          "Lock In Crop Year" : '',
          "Lock In CCC Location" : '',
          "Lock In WR #" : '',
          "Lock In Rate" : '',
          "Lock In Date" : '',
          "Action" : ''
        }, {
          "Lock In Crop Year" : '',
          "Lock In CCC Location" : '',
          "Lock In WR #" : '',
          "Lock In Rate" : '',
          "Lock In Date" : '',
          "Action" : ''
        }];
        if (datatable != undefined) {
          for (var i = 0; i < datatable.length; i++) {
            let rowdata = {};
            if (thisObj.state.gridUploadLockInfo.columns[0].Visible) {
              rowdata["Lock In Crop Year"] = ExcelNumericDataConvert(datatable[i].txtcolLockInCropYear);
            }
            if (thisObj.state.gridUploadLockInfo.columns[1].Visible) {
              rowdata["Lock In CCC Location"] = ExcelNumericDataConvert(datatable[i].txtcolLockInCCCLoc);
            }
            if (thisObj.state.gridUploadLockInfo.columns[2].Visible) {
              rowdata["Lock In WR #"] = ExcelNumericDataConvert(datatable[i].txtcolLckInWR);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Lock In Rate"] = ExcelNumericDataConvert(parseFloat(datatable[i].txtcolLockInRate));
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Lock In Date"] = ExcelNumericDataConvert(datatable[i].txtcolLockInDate);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {         
             rowdata["Action"] = datatable[i].txtcolAction;
            }
            excelData.push(rowdata);
          }
        }
    
        const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader : true});
        ws.C1 = { t : 's', v : "Upload Lock in Information"};
        const merge = [{s : {r: 0, c: 3}, e : {r: 0, c: 3}},]
        ws['!merges'] = merge;
        ws.A2 = { t : 's', v : "Lock in Crop Year"};
        ws.B2 = { t : 's', v : "Lock in CCC Location"};
        ws.C2 = { t : 's', v : "Lock in WR #"};
        ws.D2 = { t : 's', v : "Lock in Rate"};
        ws.E2 = { t : 's', v : "Lock in Date"};
        ws.F2 = { t : 's', v : "Action"};

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      if (onChangeValue == true) {
        excelData = [{
          "Lock In Crop Year" : '',
          "Lock In CCC Location" : '',
          "Lock In WR #" : '',
          "Lock In Rate" : '',
          "Lock In Date" : '',
          "WR #" : '',
          "Obl. Pt." : '',
          "Org. Pt." : '',
          "Loan Repay Rate" : '',
          "Loan Lock In Date" : '',
          "Action" : ''
        }, {
          "Lock In Crop Year" : '',
          "Lock In CCC Location" : '',
          "Lock In WR #" : '',
          "Lock In Rate" : '',
          "Lock In Date" : '',
          "WR #" : '',
          "Obl. Pt." : '',
          "Org. Pt." : '',
          "Loan Repay Rate" : '',
          "Loan Lock In Date" : '',
          "Action" : ''
        }];
        if (datatable != undefined) {
          for (var i = 0; i < datatable.length; i++) {
            let rowdata = {};
            if (thisObj.state.gridUploadLockInfo.columns[0].Visible) {
              rowdata["Lock In Crop Year"] = ExcelNumericDataConvert(datatable[i].txtcolLockInCropYear);
            }
            if (thisObj.state.gridUploadLockInfo.columns[1].Visible) {
              rowdata["Lock In CCC Location"] = ExcelNumericDataConvert(datatable[i].txtcolLockInCCCLoc);
            }
            if (thisObj.state.gridUploadLockInfo.columns[2].Visible) {
              rowdata["Lock In WR #"] = ExcelNumericDataConvert(datatable[i].txtcolLckInWR);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Lock In Rate"] = ExcelNumericDataConvert(parseFloat(datatable[i].txtcolLockInRate));
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Lock In Date"] = ExcelNumericDataConvert(datatable[i].txtcolLockInDate);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["WR #"] = ExcelNumericDataConvert(datatable[i].txtcolWhseRcptNum);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Obl. Pt."] = ExcelNumericDataConvert(datatable[i].txtcolBuyPtId);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Org. Pt."] = ExcelNumericDataConvert(datatable[i].txtcolOrgBuyPtId);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Loan Repay Rate"] = ExcelNumericDataConvert(parseFloat(datatable[i].txtcolLoanRepayRate));
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {
              rowdata["Loan Lock In Date"] = ExcelNumericDataConvert(datatable[i].txtcolLoanLockInDate);
            }
            if (thisObj.state.gridUploadLockInfo.columns[3].Visible) {         
             rowdata["Action"] = datatable[i].txtcolAction;
            }
            excelData.push(rowdata);
          }
        }
        const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader : true});
        ws.C1 = { t : 's', v : "Upload Lock in Information"};
        ws.I1 = { t : 's', v : "PPS Warehouse Receipt Information"};
        const merge = [{s : {r: 0, c: 3}, e : {r: 0, c: 3}}, {s : {r: 0, c: 8}, e : {r: 0, c: 8}}]
        ws['!merges'] = merge;
        ws.A2 = { t : 's', v : "Lock in Crop Year"};
        ws.B2 = { t : 's', v : "Lock in CCC Location"};
        ws.C2 = { t : 's', v : "Lock in WR #"};
        ws.D2 = { t : 's', v : "Lock in Rate"};
        ws.E2 = { t : 's', v : "Lock in Date"};
        ws.F2 = { t : 's', v : "WR #"};
        ws.G2 = { t : 's', v : "Obl. Pt."};
        ws.H2 = { t : 's', v : "Org. Pt."};
        ws.I2 = { t : 's', v : "Loan Repay Rate"};
        ws.J2 = { t : 's', v : "Loan Lock In Date"};
        ws.K2 = { t : 's', v : "Action"};
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    } 
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExportClick event:Click"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick

  // Print result function
  const Print = () => {
    try {
      let onChangeValue = getData(thisObj, 'onChangeValue');
      let data = getValue(thisObj, 'gridUploadLockInfo');
      if (onChangeValue == null || onChangeValue == false) {
        let res = [];
        for (var i = 0; i < data.length; i++) {
          res.push([
            data[i].txtcolLockInCropYear, data[i].txtcolLockInCCCLoc, data[i].txtcolLckInWR, data[i].txtcolLockInRate, data[i].txtcolLockInDate, data[i].txtcolAction
          ])
        }
        let obj = new jsPDF();
        obj.autoTable({
          styles: { fontSize: 5, lineWidth: 0.2 },
          margin: { top: 5, left: 0.5 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          columnStyles: {
            0: { halign: 'center' },
            1: { halign: 'center' },
            2: { halign: 'center' },
            3: { halign: 'center' },
            4: { halign: 'center' },
            5: { halign: 'center' },
          },
          head: [
            [ 
              { content : 'Upload Lock in Information', colSpan : 5, styles : {halign : 'center'}},
              { content : '', colSpan : 1, styles : {halign : 'center'}}
            ],
            [
              'Crop Year', 'CCC Loc', 'WR #', 'Lock In Rate', 'Lock In Date', 'Action'
            ]
          ],
          didParseCell: function(res){
            if(res?.cell?.text?.includes("Invalid data")){
              res.row.cells[0].styles.textColor = "red";
              res.row.cells[1].styles.textColor = "red";
              res.row.cells[2].styles.textColor = "red";
              res.row.cells[3].styles.textColor = "red";
              res.row.cells[4].styles.textColor = "red";
              res.row.cells[5].styles.textColor = "red";
            }
          }
        });

        let pageCount = obj.internal.getNumberOfPages();
        for (let w = 0; w <= pageCount; w++) {
          obj.setPage(w);
          let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
          obj.setFontSize(8);
          obj.text(cropYear + '  LOCK IN GRID', 2, obj.internal.pageSize.height - 2);
        }

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf');
          }
          else {
            alert("Routine:WarehouseReceiptUpload.cmdPrint_Click");
          }
        }
        else {
          alert("Routine:WarehouseReceiptUpload.cmdPrint_Click");
        }
      }
      if (onChangeValue == true) {
        let res = [];
        for (var i = 0; i < data.length; i++) {
          res.push([
            data[i].txtcolLockInCropYear, data[i].txtcolLockInCCCLoc, data[i].txtcolLckInWR, data[i].txtcolLockInRate, data[i].txtcolLockInDate, data[i].txtcolWhseRcptNum, data[i].txtcolBuyPtId, data[i].txtcolOrgBuyPtId, data[i].txtcolLoanRepayRate, data[i].txtcolLoanLockInDate, data[i].txtcolAction
          ])
        }
        let obj = new jsPDF();
        obj.autoTable({
          styles: { fontSize: 5, lineWidth: 0.2 },
          margin: { top: 5, left: 0.5 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          columnStyles: {
            0: { halign: 'center' },
            1: { halign: 'center' },
            2: { halign: 'center' },
            3: { halign: 'center' },
            4: { halign: 'center' },
            5: { halign: 'right' },
            6: { halign: 'left' },
            7: { halign: 'left' },
            8: { halign: 'center' },
            9: { halign: 'center' },
            10: { halign: 'left' },
          },
          head: [
            [ 
              { content : 'Upload Lock in Information', colSpan : 5, styles : {halign : 'center'}},
              { content : 'PPS Warehouse Receipt Information', colSpan : 5, styles : {halign : 'center'}},
              { content : '', colSpan : 1, styles : {halign : 'center'}}
            ],
            [
              'Crop Year', 'CCC Loc', 'WR #', 'Lock In Rate', 'Lock In Date', 'WR #', 'Obl. Pt.', 'Org. Pt.', 'Loan Repay Rate', 'Loan Lock In Date', 'Action'
            ]
          ]
        });

        let pageCount = obj.internal.getNumberOfPages();
        for (let w = 0; w <= pageCount; w++) {
          obj.setPage(w);
          let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
          obj.setFontSize(8);
          obj.text(cropYear + '  LOCK IN GRID', 2, obj.internal.pageSize.height - 2);
        }

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf');
          }
          else {
            alert("Routine:WarehouseReceiptUpload.cmdPrint_Click");
          }
        }
        else {
          alert("Routine:WarehouseReceiptUpload.cmdPrint_Click");
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Print function"
        );
      }
      return false;
    }
  }

  // Print Button Functionality
  const onbtnPrintResultClick = () => { 
    try {
      Print();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnPrintResultClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintResultClick = onbtnPrintResultClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_WarehouseReceiptUploadPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_WarehouseReceiptUpload*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseReceiptUpload*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxUploadLockInfo*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxUploadLockInfo*/}

              <GroupBoxWidget
                conf={state.grpbxUploadLockInfo}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxuploadlockinfobuttons*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxuploadlockinfobuttons*/}

                <GroupBoxWidget
                  conf={state.grpbxuploadlockinfobuttons}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblUploadlockInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUploadlockInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUploadlockInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUploadlockInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblUploadlockInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_btnImportLockin*/}

                  {/* END_USER_CODE-USER_BEFORE_btnImportLockin*/}

                  <ButtonWidget
                    conf={state.btnImportLockin}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnImportLockin*/}
                  <ModalPopUp id="blur"
                    show={isShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    keyboard="false"
                    style={{ width: "50%", height: "100%", left: "26%" }}
                    autoFocus
                    centered
                  >
                    <ModalPopUp.Body>
                      <div style={{ alignContent: "centered", textAlign: "centered", height: "100%" }}>
                        <div className="dropArea" {...getRootProps()} style={{ border: "1px solid #ccc", textAlign: "centered", height: "100%",backgroundColor:"white" }}>
                          {/* {...getRootProps()} */}
                          <label for="dropFile" style={{ textAlign: "center", width: "100%", height: "100%" }} class="btn">
                            <strong> Drag 'n' drop some file here, or click to select file. </strong>
                          </label>
                          {/* <input {...getInputProps()} accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height : "100%" , visibility: "hidden" }}/> */}
                        </div>
                      </div>
                    </ModalPopUp.Body>
                  </ModalPopUp>
                  {/* END_USER_CODE-USER_AFTER_btnImportLockin*/}
                  {/* START_USER_CODE-USER_BEFORE_btnViewlockin*/}

                  {/* END_USER_CODE-USER_BEFORE_btnViewlockin*/}

                  <ButtonWidget
                    conf={state.btnViewlockin}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnViewlockin*/}

                  {/* END_USER_CODE-USER_AFTER_btnViewlockin*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUpdateWR*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdateWR*/}

                  <ButtonWidget
                    conf={state.btnUpdateWR}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdateWR*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdateWR*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxuploadlockinfobuttons*/}

                {/* END_USER_CODE-USER_AFTER_grpbxuploadlockinfobuttons*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLoadhistory*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLoadhistory*/}

                <GroupBoxWidget
                  conf={state.grpbxLoadhistory}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblLoadHistory*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLoadHistory*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLoadHistory}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLoadHistory*/}

                  {/* END_USER_CODE-USER_AFTER_lblLoadHistory*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLoadHistory*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLoadHistory*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLoadHistory}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLoadHistory*/}

                  {/* END_USER_CODE-USER_AFTER_ddLoadHistory*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLoadhistory*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLoadhistory*/}
                {/* START_USER_CODE-USER_BEFORE_lblImportedLockInMsg*/}

                {/* END_USER_CODE-USER_BEFORE_lblImportedLockInMsg*/}

                <LabelWidget
                  values={values}
                  conf={state.lblImportedLockInMsg}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblImportedLockInMsg*/}

                {/* END_USER_CODE-USER_AFTER_lblImportedLockInMsg*/}
                {/* START_USER_CODE-USER_BEFORE_radioViewResults*/}

                {/* END_USER_CODE-USER_BEFORE_radioViewResults*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioViewResults}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioViewResults*/}

                {/* END_USER_CODE-USER_AFTER_radioViewResults*/}
                <LabelWidget
                    values={values}
                    conf={state.lblUpload}
                    screenConf={state}
                  ></LabelWidget>

                <LabelWidget
                    values={values}
                    conf={state.lblPPS}
                    screenConf={state}
                  ></LabelWidget>

                {/* START_USER_CODE-USER_BEFORE_gridUploadLockInfo*/}

                {/* END_USER_CODE-USER_BEFORE_gridUploadLockInfo*/}

                <GridWidget
                  conf={state.gridUploadLockInfo}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridUploadLockInfo}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridUploadLockInfo*/}

                {/* END_USER_CODE-USER_AFTER_gridUploadLockInfo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxUploadLockInfo*/}

              {/* END_USER_CODE-USER_AFTER_grpbxUploadLockInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAction*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAction*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxAction} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lbladdedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lbladdedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lbladdedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lbladdedBy*/}

                {/* END_USER_CODE-USER_AFTER_lbladdedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedbyvalue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedbyvalue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedbyvalue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedbyvalue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedbyvalue*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintResult*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintResult*/}

                <ButtonWidget
                  conf={state.btnPrintResult}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintResult*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintResult*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedbyvalue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedbyvalue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedbyvalue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedbyvalue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedbyvalue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAction*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAction*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseReceiptUpload*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseReceiptUpload*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WarehouseReceiptUpload);
