/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  disable,
  enable,
  goTo
} from "../../shared/WindowImports";

import "./CureCleanScheduleSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_CureCleanScheduleSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CureCleanScheduleSetup",
    windowName: "CureCleanScheduleSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.CureCleanScheduleSetup",
    // START_USER_CODE-USER_CureCleanScheduleSetup_PROPERTIES
    headerData: {
      scrName: "Cure & Clean Schedule Setup",
      scrCode: "PN0140A",
    },
    // END_USER_CODE-USER_CureCleanScheduleSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxCureCleanSchedule",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxCureCleanSchedule",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxCureCleanSchedule",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxCureCleanSchedule",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanSchedule",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    lblCureCleanSchedule: {
      name: "lblCureCleanSchedule",
      type: "LabelWidget",
      parent: "grpbxCureCleanSchedule",
      Label: "Cure & Clean Schedule:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCureCleanSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCureCleanSchedule_PROPERTIES
    },
    lstSchedule: {
      name: "lstSchedule",
      type: "ListBoxFieldWidget",
      parent: "grpbxCureCleanSchedule",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstSchedule_PROPERTIES

      // END_USER_CODE-USER_lstSchedule_PROPERTIES
    },
    grpbxCureCleanSchedule: {
      name: "grpbxCureCleanSchedule",
      type: "GroupBoxWidget",
      parent: "CureCleanScheduleSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCureCleanSchedule_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanSchedule_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#CureCleanScheduleProfile": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#CureCleanScheduleProfile": {},
      }
    }

  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAdd: {
      DEFAULT: ["SystemMaintenanceMasterManagement#CureCleanScheduleProfile#DEFAULT#true#Click"]
    },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [successfulLoad, setSuccessfulLoad] = useState(false);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const updatedList = getData(thisObj, 'ScheduleAddUpdateCompleteList');
    if (updatedList && updatedList.length > 0) {
      const updatedScheduleIndex = getData(thisObj, 'ScheduleAddUpdateIndex')

      setData(thisObj, 'scheduleList', updatedList);
      thisObj.setState(current => {
        return {
          ...current,
          lstSchedule: {
            ...state["lstSchedule"],
            valueList: updatedList
          }
        }
      });

      if (updatedList.length > 0) {
        setValue(thisObj, 'lstSchedule', [updatedList[updatedScheduleIndex]?.key]);
        setData(thisObj, 'selectedSchedule', updatedList[updatedScheduleIndex]?.key);
        document.getElementById('lstSchedule')?.focus();
      }
      enableDisableControls();
    }
  }, [getData(thisObj, 'ScheduleAddUpdateCompleteList')]);

  // START_USER_CODE-USER_METHODS

  const fillBuyingPointList = async () => {
    try {
      const functionId = 'PN0030';
      const buyingPointList = [];
      const buyingPointResponse = await ContractManagementService
        .RetrieveBuyingPointControlDetails(functionId);
      buyingPointResponse.forEach(buyingPoint => {
        const dropdownOption = {
          key: buyingPoint.buy_pt_id,
          description: buyingPoint.buy_pt_id + ' - ' + buyingPoint.buy_pt_name
        }
        buyingPointList.push(dropdownOption);
      });
      setData(thisObj, 'buyingPointList', buyingPointList);
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: buyingPointList
          }
        }
      });
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillBuyingPointList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const fillScheduleList = async () => {
    try {
      const selectedBuyingPoint = getData(thisObj, 'selectedBuyingPoint');
      const initialScheduleId = '';
      let sScheduleId = '';
      const scheduleList = [];
      const cureCleanScheduleResponse = await SettlementService
        .RetrieveCureCleanScheduleDetails(selectedBuyingPoint, null, null, initialScheduleId);
      cureCleanScheduleResponse.forEach(cureCleanSchedule => {
        if (cureCleanSchedule.scheduleId?.toString()?.toUpperCase() !== sScheduleId?.toUpperCase()) {
          const listItem = {
            key: cureCleanSchedule.scheduleId,
            description: cureCleanSchedule.scheduleId + ' - ' + cureCleanSchedule.scheduleDesc
          };
          scheduleList.push(listItem);
          sScheduleId = cureCleanSchedule.scheduleId;
        }
      });

      setData(thisObj, 'scheduleList', scheduleList);
      thisObj.setState(current => {
        return {
          ...current,
          lstSchedule: {
            ...state["lstSchedule"],
            valueList: scheduleList
          }
        }
      });

      if (scheduleList.length > 0) {
        setValue(thisObj, 'lstSchedule', [scheduleList[0]?.key]);
        setData(thisObj, 'selectedSchedule', scheduleList[0]?.key);
        document.getElementById('lstSchedule')?.focus();
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillScheduleList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const enableDisableControls = () => {
    try {
      const buyingPointList = getData(thisObj, 'buyingPointList');
      const selectedBuyingPoint = getData(thisObj, 'selectedBuyingPoint');
      const buyingPointListIndex = buyingPointList.findIndex(obj => obj.key === selectedBuyingPoint);
      if (buyingPointListIndex < 0) {
        disable(thisObj, 'btnAdd');
        disable(thisObj, 'btnEdit');
        disable(thisObj, 'btnDelete');
      } else {
        enable(thisObj, 'btnAdd');
        const scheduleList = getData(thisObj, 'scheduleList');
        const selectedSchedule = getData(thisObj, 'selectedSchedule');
        const scheduleListIndex = scheduleList.findIndex(obj => obj.key === selectedSchedule);
        if (scheduleListIndex < 0) {
          disable(thisObj, 'btnEdit');
          disable(thisObj, 'btnDelete');
        } else {
          enable(thisObj, 'btnEdit');
          enable(thisObj, 'btnDelete');
        }
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during enableDisableControls: ${error?.toString()}`
        );
      }
    }
  };

  const formLoad = async () => {
    try {
      setSuccessfulLoad(true);
      const buyingPointLoaded = await fillBuyingPointList();
      if (!buyingPointLoaded) {
        return;
      }
      const buyingPointList = getData(thisObj, 'buyingPointList');
      if (buyingPointList.length > 0) {
        setValue(thisObj, 'ddBuyingPoint', buyingPointList[0]?.key);
        setData(thisObj, 'selectedBuyingPoint', buyingPointList[0]?.key);
        const scheduleListLoaded = await fillScheduleList(buyingPointList[0]?.key);
        if (!scheduleListLoaded) {
          setSuccessfulLoad(false);
        }
      }
      enableDisableControls();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
    }
  };

  const onbtnAddClick = async () => {
    try {
      const selectedBuyingPoint = getData(thisObj, 'selectedBuyingPoint');
      const buyingPointList = getData(thisObj, 'buyingPointList');
      const buyingPointListIndex = buyingPointList.findIndex(obj => obj.key === selectedBuyingPoint);
      let buyingPoint = '';
      if (buyingPointListIndex !== -1) {
        buyingPoint = buyingPointList[buyingPointListIndex]?.description;
      }
      setData(thisObj, 'ScheduleProfileOkBtnText', 'Add');
      setData(thisObj, 'ScheduleProfileBuyingPoint', buyingPoint);
      setData(thisObj, 'ScheduleProfileEnableTxtCode', true);
      setData(thisObj, 'ScheduleProfileTxtScheduleCode', null);
      setData(thisObj, 'ScheduleProfileTxtDescription', null);
      setData(thisObj, 'ScheduleProfileCureCleanIndicator', null);
      setData(thisObj, 'ScheduleProfileDryGreenIndicator', null);
      setData(thisObj, 'ScheduleProfilePdCode', null);
      setData(thisObj, 'ScheduleProfileRateTypeIndicator', null);
      setData(thisObj, 'ScheduleProfileLblAddedBy', null);
      setData(thisObj, 'ScheduleProfileLblChangedBy', null);
      setData(thisObj, 'ScheduleProfileControlList', null);
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnAddClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnDeleteClick = async () => {
    try {
      const scheduleList = getData(thisObj, 'scheduleList');
      const selectedScheduleId = getData(thisObj, 'selectedSchedule');
      const scheduleListIndex = scheduleList.findIndex(obj => obj.key === selectedScheduleId);
      const selectedScheduleDesc = scheduleList[scheduleListIndex]?.description;
      const deleteConfirmation = confirm(`Are you sure you wish to delete this CC Code '${selectedScheduleDesc}'`);
      if (deleteConfirmation) {
        const selectedBuyingPoint = getData(thisObj, 'selectedBuyingPoint');
        const removeScheduleResponse = await SystemMaintenanceMasterManagementService
          .RemoveCureCleanSchedule(selectedBuyingPoint, selectedScheduleId);
        if (removeScheduleResponse.status !== 200) {
          alert('An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.');
          return;
        }

        scheduleList.splice(scheduleListIndex, 1);

        setData(thisObj, 'scheduleList', scheduleList);
        thisObj.setState(current => {
          return {
            ...current,
            lstSchedule: {
              ...state["lstSchedule"],
              valueList: scheduleList
            }
          }
        });

        if (scheduleList.length === 0) {
          enableDisableControls();
          return;
        }
        if (scheduleListIndex > scheduleList.length - 1) {
          setData(thisObj, 'selectedSchedule', scheduleList[scheduleList.length - 1]?.key);
          setValue(thisObj, 'lstSchedule', [scheduleList[scheduleList.length - 1]?.key]);
        } else {
          setData(thisObj, 'selectedSchedule', scheduleList[scheduleListIndex]?.key);
          setValue(thisObj, 'lstSchedule', [scheduleList[scheduleListIndex]?.key]);
        }
        document.getElementById('lstSchedule')?.focus();
        enableDisableControls();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnDeleteClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnEditClick = async () => {
    try {
      const selectedBuyingPoint = getData(thisObj, 'selectedBuyingPoint');
      const selectedScheduleId = getData(thisObj, 'selectedSchedule');

      const cureCleanScheduleResponse = await SettlementService
        .RetrieveCureCleanScheduleDetails(selectedBuyingPoint, null, null, selectedScheduleId);
      const controlList = [];
      let txtScheduleCode = '';
      let txtDescription = '';
      let cureCleanIndicator = '';
      let dryGreenIndicator = '';
      let pdCode = '';
      let rateTypeIndicator = '';
      let lblAddedBy = '';
      let lblChangedBy = '';

      cureCleanScheduleResponse.forEach((cureCleanSchedule, cureCleanScheduleIndex) => {
        if (cureCleanScheduleIndex === 0) {
          txtScheduleCode = cureCleanSchedule.scheduleId;
          txtDescription = cureCleanSchedule.scheduleDesc;
          cureCleanIndicator = cureCleanSchedule.cureCleanInd;
          dryGreenIndicator = cureCleanSchedule.dryGreenInd;
          pdCode = cureCleanSchedule.pdCode;
          rateTypeIndicator = cureCleanSchedule.rateType;
          lblAddedBy = cureCleanSchedule.addUser + ' ' + new Date(cureCleanSchedule.addDateTime)
            .toLocaleDateString('en-US');
          lblChangedBy = cureCleanSchedule.chgUser + ' ' + new Date(cureCleanSchedule.chgDateTime)
            .toLocaleDateString('en-US');
        }

        const loMoistPct = cureCleanSchedule.loMoistPct;
        const hiMoistPct = cureCleanSchedule.hiMoistPct;
        const baseRate = cureCleanSchedule.baseRate;

        controlList.push({
          loMoistPct,
          hiMoistPct,
          baseRate
        });
      });

      const buyingPointList = getData(thisObj, 'buyingPointList');
      const buyingPointListIndex = buyingPointList.findIndex(obj => obj.key === selectedBuyingPoint);
      let buyingPoint = '';
      if (buyingPointListIndex !== -1) {
        buyingPoint = buyingPointList[buyingPointListIndex]?.description;
      }
      setData(thisObj, 'ScheduleProfileOkBtnText', 'Update');
      setData(thisObj, 'ScheduleProfileBuyingPoint', buyingPoint);
      setData(thisObj, 'ScheduleProfileEnableTxtCode', false);
      setData(thisObj, 'ScheduleProfileTxtScheduleCode', txtScheduleCode);
      setData(thisObj, 'ScheduleProfileTxtDescription', txtDescription);
      setData(thisObj, 'ScheduleProfileCureCleanIndicator', cureCleanIndicator);
      setData(thisObj, 'ScheduleProfileDryGreenIndicator', dryGreenIndicator);
      setData(thisObj, 'ScheduleProfilePdCode', pdCode);
      setData(thisObj, 'ScheduleProfileRateTypeIndicator', rateTypeIndicator);
      setData(thisObj, 'ScheduleProfileLblAddedBy', lblAddedBy);
      setData(thisObj, 'ScheduleProfileLblChangedBy', lblChangedBy);
      setData(thisObj, 'ScheduleProfileControlList', controlList);
      goTo(thisObj, "SystemMaintenanceMasterManagement#CureCleanScheduleProfile#DEFAULT#true#Click");
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnEditClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnEditClick = onbtnEditClick;

  const onddBuyingPointChange = async () => {
    try {
      const selectedBuyingPoint = getValue(thisObj, 'ddBuyingPoint');
      setData(thisObj, 'selectedBuyingPoint', selectedBuyingPoint);
      const scheduleListLoaded = await fillScheduleList();
      if (!scheduleListLoaded) {
        return;
      }
      enableDisableControls();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onddBuyingPointChange: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const onlstScheduleChange = () => {
    const selectedScheduleId = getValue(thisObj, 'lstSchedule')?.[0];
    setData(thisObj, 'selectedSchedule', selectedScheduleId);
  };

  thisObj.onlstScheduleChange = onlstScheduleChange;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CureCleanScheduleSetup*/}

              {/* END_USER_CODE-USER_BEFORE_CureCleanScheduleSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanSchedule*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanSchedule*/}

              <GroupBoxWidget
                conf={state.grpbxCureCleanSchedule}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblCureCleanSchedule*/}

                {/* END_USER_CODE-USER_BEFORE_lblCureCleanSchedule*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCureCleanSchedule}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCureCleanSchedule*/}

                {/* END_USER_CODE-USER_AFTER_lblCureCleanSchedule*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstSchedule*/}

                {/* END_USER_CODE-USER_BEFORE_lstSchedule*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstSchedule}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstSchedule*/}

                {/* END_USER_CODE-USER_AFTER_lstSchedule*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCureCleanSchedule*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCureCleanSchedule*/}

              {/* START_USER_CODE-USER_AFTER_CureCleanScheduleSetup*/}

              {/* END_USER_CODE-USER_AFTER_CureCleanScheduleSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_CureCleanScheduleSetup
);
