/* eslint-disable*/
import React, { Component } from "react";
import AppContext from "../../../context/AppContext";
const _kaledo = window._kaledo;
class ParentWidget extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.conf = this.props.conf;
    this.screenConf = this.props.screenConf;
  };
  getSize = () => {
    return this.conf.size || "sm";
  };
  getWidgetLabel = () => {
    return this.conf.Label;
  };
  getPanelClasses = () => {
    return [...this.getWidgetClasses(), "mb-3"];
  };
  getFieldContentClasses = () => {
    return [this.screenConf.horizontalForm ? "d-flex " : ""];
  };
  getFieldContentStyle = () => {
    return {
      width: this.conf.contentWidth ? this.conf.contentWidth + "%" : "100%"    };
  };
  getVisibility = () => {
    if (!this.conf.HasLabel) {
      if (this.screenConf.horizontalForm) {
        return { display: "none" };
      } else {
        return { visibility: "hidden" };
      }
    }
    return null;
  };
  getWidgetClasses = () => {
    return [
      this.conf.name,
      ...this.getResponsiveClasses(),
      ...this.getVisibleResponsiveClasses(),
      ...this.getRowSpanClasses(),
      this.conf.Mandatory ? "required" : "",
      `${this.conf.type.toLowerCase()}-container`,
      this.conf.contentAlign ? `justify-content-${this.conf.contentAlign}` : ""    ];
  };
  getRowSpanClasses = () => {
    const {
      RowSpan,
      RowSpanForTabLandscape,
      RowSpanForTabPotrait,
      RowSpanForMobile    } = this.conf;
    let rowSpanCSSClasses = [];
    if (RowSpan) {
      rowSpanCSSClasses.push(
        "rowspanResponsive-lg-" + RowSpan,
        "rowspanResponsive-md-" + RowSpanForTabLandscape,
        "rowspanResponsive-sm-" + RowSpanForTabPotrait,
        "rowspanResponsive-xs-" + RowSpanForMobile      );
    }
    return rowSpanCSSClasses;
  };
  is12ColumnLayout = () => {
    return this.screenConf.is12Column;
  };
  getVisibleResponsiveClasses = () => {
    let tempArr = [];
    if (this.conf.VisibleForMobile === false) {
      tempArr.push("d-none", "d-sm-block");
    }
    if (this.conf.VisibleForTabPotrait === false) {
      tempArr.push("d-sm-none", "d-md-block");
    }
    if (this.conf.VisibleForTabLandscape === false) {
      tempArr.push("d-md-none", "d-lg-block");
    }
    if (this.conf.Visible === false) {
      tempArr.push("d-lg-none");
    }
    let respArr = ["sm", "md", "lg"];
    respArr.forEach(elem => {
      if (
        tempArr.indexOf("d-" + elem + "-block") !== -1 &&        
        tempArr.indexOf("d-" + elem + "-none") !== -1      ) {
        let blockIndex = tempArr.indexOf("d-" + elem + "-block");
        delete tempArr[blockIndex];
      }
    });
    return tempArr;
  };
  getResponsiveClasses = () => {
    let parentConf = this.screenConf[this.conf.parent];
    if (!parentConf) {
      parentConf = this.screenConf;
    }
    const {
      Cols,
      ColsForTabLandscape,
      ColsForTabPotrait,
      ColsForMobile    } = parentConf;
    const {
      ColSpan,
      ColSpanForTabLandscape,
      ColSpanForTabPotrait,
      ColSpanForMobile    } = this.conf;
    let responsiveClasses = [];
    if(_kaledo.isCobolTable){
        let colSpanNumber = parseInt(ColSpan) * (12 / parseInt(Cols));
        colSpanNumber = Math.round(colSpanNumber);
        responsiveClasses.push("col-" + colSpanNumber);
    }
    else{
        responsiveClasses.push(
          this.getBSClasses(Cols, ColSpan),
          this.getBSClasses(
          ColsForTabLandscape,
          ColSpanForTabLandscape,
            "TabLandscape"          ),
          this.getBSClasses(ColsForTabPotrait, ColSpanForTabPotrait, "TabPotrait"),
          this.getBSClasses(ColsForMobile, ColSpanForMobile, "Mobile")
        );
    }
    return responsiveClasses;
  };
  getBSClasses = (cols, colSpan, type) => {
    let colSpanNumber;
    if (this.is12ColumnLayout()) {
      colSpanNumber = parseInt(colSpan);
    } else {
      colSpanNumber = parseInt(colSpan) * (12 / parseInt(cols));
    }
    colSpanNumber = Math.round(colSpanNumber);
    switch (type) {
      case "TabLandscape":
        return "col-md-" + colSpanNumber;
      case "TabPotrait":
        return "col-sm-" + colSpanNumber;
      case "Mobile":
        return "col-xs-" + colSpanNumber;
      default:
        return "col-lg-" + colSpanNumber;
    }
  };
  checkInvalid() {
    return (
      this.props.touched[this.conf.name] && this.props.errors[this.conf.name]
    );
  }
  getTooltip(col) {
    let obj = this.conf;
    if(col !== undefined)
      obj = col    
      return obj.ToolTip ? obj.ToolTip.Label      ? obj.ToolTip.Label : obj.ToolTip      : "";
  }
  getMaxlength(col) {
      let maxlength=256;
      let ofTypeDomain=col?col.ofTypeDomain:this.conf.ofTypeDomain;
      let LengthRange= col?col.LengthRange:this.conf.LengthRange;
      if(ofTypeDomain==="d_String")
        maxlength=LengthRange ? LengthRange.MaxLength : 256;
      if(ofTypeDomain.includes("d_int"))
        maxlength=LengthRange ? LengthRange.MaxLength : 9;
      if(ofTypeDomain.includes("d_long"))
        maxlength=LengthRange ? LengthRange.MaxLength : 17;
      if(ofTypeDomain.includes("d_float"))
        maxlength=LengthRange ? LengthRange.MaxLength : 14;
      if(ofTypeDomain.includes("d_double"))
        maxlength=LengthRange ? LengthRange.MaxLength : 17;
      return maxlength;
  }
  getTextBoxClasses = (col) => {
    let textBoxClasses = ["textboxWidgetClass"];
    switch (this.conf.Alignment) {
      case "Left":
        textBoxClasses.push("leftAlignClass");
        break;
      case "Right":
        textBoxClasses.push("rightAlignClass");
        break;
      case "Center":
        textBoxClasses.push("centerAlignClass");
        break;
      default:
        textBoxClasses.push("centerAlignClass");
        break;
    }
    if (_kaledo.isCobolTable) {
        if(this.conf.ReadOnly===true)
        {
            textBoxClasses.push("RemoveBottomBorder");
        }
        var maxLen = "";
        var lengthClass = "";
        if(this.conf.type ==="GridWidget" && col!==undefined && col.type==='TextBoxWidget' && col.LengthRange!==undefined && col.LengthRange.MaxLength <= 100){
            maxLen = col.LengthRange.MaxLength;
            lengthClass = "ch-"+maxLen;
            textBoxClasses.push(lengthClass);
        }
        else if (this.conf.ReadOnly !== true && this.conf.Enabled !== false && this.conf.type === 'TextBoxWidget' && this.conf.LengthRange && this.conf.LengthRange.MaxLength<= 100) {
            maxLen = this.conf.LengthRange.MaxLength;
            lengthClass = "ch-"+maxLen;
            textBoxClasses.push(lengthClass);
        }
    }
    if(this.conf.type ==="GridWidget" && col!==undefined && col.type==='TextBoxWidget' && col.Mandatory){
      textBoxClasses.push("requiredField");
    }
    if (this.conf.Mandatory) {
      textBoxClasses.push("requiredField");
    }
    if (this.conf.LOV) {
      textBoxClasses.push("lovTextBoxClass");
    }
    return textBoxClasses.join(" ");
  };
  getTextAreaClasses = () => {
    let textBoxClasses = ["textareaWidgetClass"];
    switch (this.conf.Alignment) {
      case "Left":
        textBoxClasses.push("leftAlignClass");
        break;
      case "Right":
        textBoxClasses.push("rightAlignClass");
        break;
      case "Center":
        textBoxClasses.push("centerAlignClass");
        break;
      default:
        textBoxClasses.push("centerAlignClass");
        break;
    }
    if (this.conf.Mandatory) {
      textBoxClasses.push("requiredField");
    }
    return textBoxClasses.join(" ");
  };
  getPlaceholder() {
    return this.conf.PlaceholderForTextBox      ? this.conf.PlaceholderForTextBox      : "";
  }
  getInputType() {
    if(_kaledo["errorPosition"]!==undefined)
        this.conf.errorPosition = _kaledo["errorPosition"];
    else        
    this.conf.errorPosition = "field";
    if (this.conf.inputType && this.conf.inputType !== "None") {
      return this.conf.inputType;
    } else if (this.conf.type === "PasswordBoxWidget") {
      return "password";
    } else if (this.conf.type === "SpinnerWidget") {
      return "number";
    } else {
      return "text";
    }
  }
  isDispFormatReqd() {
    let DispFormatReqd = false;
    if (_kaledo["dispFormatReqd"])
      DispFormatReqd = true;
    return DispFormatReqd;
  }
  getDispFormat(obj) {
    let pattern = "";
    let conf;
    if(this.conf.type === "GridWidget"){
      conf = obj;
    }else{
      conf = this.conf    }
    let domain =  "d_int";
    if(domain === conf.ofTypeDomain){
          let len = conf.LengthRange.MaxLength;
          let x = "9";
          for (let i = 1; i <= len; i++) {
            pattern += x;
          }
          pattern = "("+pattern+")";
          pattern = conf.formattingReqd ? pattern.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : pattern;
    }
    switch (conf.type) {
      case "DateWidget":
        pattern = conf.format || _kaledo["format"]["dateFormat"];
        pattern = "("+pattern+")";
        break;
      case "DateTimeWidget":
        pattern = conf.format || _kaledo["format"]["dateTimeFormat"];
        pattern = "("+pattern+")";
        break;
      case "TimeWidget":
        pattern = conf.format || _kaledo["format"]["timeFormat"];
        pattern = "("+pattern+")";
        break;
      default:
        break;
    }      
    return pattern;
  }
  captureDateFormat() {
    switch (this.conf.type) {
      case "DateWidget":
        this.conf.format = this.conf.format || _kaledo["format"]["dateFormat"];
        break;
      case "DateTimeWidget":
        this.conf.format =          this.conf.format || _kaledo["format"]["dateTimeFormat"];
        break;
      case "TimeWidget":
        this.conf.format = this.conf.format || _kaledo["format"]["timeFormat"];
        break;
      default:
        this.conf.format = "";
    }
  }
  prepareDatePickerOptions() {
    let formatString = this.conf.format;
    if (this.conf.is24HourClock || this.conf.isTime24HourClock) {
      formatString = formatString.replace(" a", "");
      formatString = formatString.replace("hh", "HH");
    }
    if (!this.conf.secondTimeRequired) {
      formatString = formatString.replace(":ss", "");
    }
    this.conf.format = formatString;
    let pickerOptions = {
      minDate: this.conf.dateOptions && this.conf.dateOptions.minDate,
      maxDate: this.conf.dateOptions && this.conf.dateOptions.maxDate,
      format: this.conf.format    };
    return pickerOptions;
  }
  render() {
    return <div></div>;  }
}
export default ParentWidget;