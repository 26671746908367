/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WarehouseReceipt from "./WarehouseReceipt";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseReceipt Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceipt />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseReceipt Loads Successfully", () => {
    expect(screen.getByText("WarehouseReceipt")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseReceipt", () => {
    // START_USER_CODE-USER_WarehouseReceipt_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseReceipt_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceipt />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCreateWR(Button Widget) Test Cases", async () => {
    const btnCreateWR = screen.getByTestId("btnCreateWR");
    expect(btnCreateWR).toBeInTheDocument;
    expect(btnCreateWR.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_btnCreateWR")
    );
  });
  test("Custom Test Cases for btnCreateWR", () => {
    // START_USER_CODE-USER_btnCreateWR_TEST
    // END_USER_CODE-USER_btnCreateWR_TEST
  });
  test("btnMore(Button Widget) Test Cases", async () => {
    const btnMore = screen.getByTestId("btnMore");
    expect(btnMore).toBeInTheDocument;
    expect(btnMore.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_btnMore")
    );
  });
  test("Custom Test Cases for btnMore", () => {
    // START_USER_CODE-USER_btnMore_TEST
    // END_USER_CODE-USER_btnMore_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("btnVendorMA(Button Widget) Test Cases", async () => {
    const btnVendorMA = screen.getByTestId("btnVendorMA");
    expect(btnVendorMA).toBeInTheDocument;
    expect(btnVendorMA.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_btnVendorMA")
    );
  });
  test("Custom Test Cases for btnVendorMA", () => {
    // START_USER_CODE-USER_btnVendorMA_TEST
    // END_USER_CODE-USER_btnVendorMA_TEST
  });
  test("gridAvailable1007s(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s = screen.getByTestId("gridAvailable1007s");
    let gridAvailable1007sbtn =
      gridAvailable1007s.nextElementSibling.firstElementChild;
    gridAvailable1007s =
      gridAvailable1007s.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s.tagName).toBe("DIV");
    expect(gridAvailable1007s.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAvailable1007s", () => {
    // START_USER_CODE-USER_gridAvailable1007s_TEST
    // END_USER_CODE-USER_gridAvailable1007s_TEST
  });
  test("gridInventoriesAvailable(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailablebtn =
      gridInventoriesAvailable.nextElementSibling.firstElementChild;
    gridInventoriesAvailable =
      gridInventoriesAvailable.parentElement.parentElement.parentElement;
    expect(gridInventoriesAvailable.tagName).toBe("DIV");
    expect(gridInventoriesAvailable.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridInventoriesAvailable", () => {
    // START_USER_CODE-USER_gridInventoriesAvailable_TEST
    // END_USER_CODE-USER_gridInventoriesAvailable_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAvailable1007s(GroupBox Widget) Test Cases", async () => {
    const grpbxAvailable1007s = screen.getByTestId("grpbxAvailable1007s");
    expect(grpbxAvailable1007s.tagName).toBe("BUTTON");
    expect(grpbxAvailable1007s.type).toBe("button");
    expect(grpbxAvailable1007s.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAvailable1007s", () => {
    // START_USER_CODE-USER_grpbxAvailable1007s_TEST
    // END_USER_CODE-USER_grpbxAvailable1007s_TEST
  });
  test("grpbxDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails1 = screen.getByTestId("grpbxDetails1");
    expect(grpbxDetails1.tagName).toBe("BUTTON");
    expect(grpbxDetails1.type).toBe("button");
    expect(grpbxDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails1", () => {
    // START_USER_CODE-USER_grpbxDetails1_TEST
    // END_USER_CODE-USER_grpbxDetails1_TEST
  });
  test("grpbxDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails2 = screen.getByTestId("grpbxDetails2");
    expect(grpbxDetails2.tagName).toBe("BUTTON");
    expect(grpbxDetails2.type).toBe("button");
    expect(grpbxDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails2", () => {
    // START_USER_CODE-USER_grpbxDetails2_TEST
    // END_USER_CODE-USER_grpbxDetails2_TEST
  });
  test("grpbxDetails3(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails3 = screen.getByTestId("grpbxDetails3");
    expect(grpbxDetails3.tagName).toBe("BUTTON");
    expect(grpbxDetails3.type).toBe("button");
    expect(grpbxDetails3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails3", () => {
    // START_USER_CODE-USER_grpbxDetails3_TEST
    // END_USER_CODE-USER_grpbxDetails3_TEST
  });
  test("grpbxFactorsForAllPeanuts(GroupBox Widget) Test Cases", async () => {
    const grpbxFactorsForAllPeanuts = screen.getByTestId(
      "grpbxFactorsForAllPeanuts"
    );
    expect(grpbxFactorsForAllPeanuts.tagName).toBe("BUTTON");
    expect(grpbxFactorsForAllPeanuts.type).toBe("button");
    expect(grpbxFactorsForAllPeanuts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFactorsForAllPeanuts", () => {
    // START_USER_CODE-USER_grpbxFactorsForAllPeanuts_TEST
    // END_USER_CODE-USER_grpbxFactorsForAllPeanuts_TEST
  });
  test("grpbxInventoriesAvailable(GroupBox Widget) Test Cases", async () => {
    const grpbxInventoriesAvailable = screen.getByTestId(
      "grpbxInventoriesAvailable"
    );
    expect(grpbxInventoriesAvailable.tagName).toBe("BUTTON");
    expect(grpbxInventoriesAvailable.type).toBe("button");
    expect(grpbxInventoriesAvailable.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInventoriesAvailable", () => {
    // START_USER_CODE-USER_grpbxInventoriesAvailable_TEST
    // END_USER_CODE-USER_grpbxInventoriesAvailable_TEST
  });
  test("grpbxMarketingAssociation(GroupBox Widget) Test Cases", async () => {
    const grpbxMarketingAssociation = screen.getByTestId(
      "grpbxMarketingAssociation"
    );
    expect(grpbxMarketingAssociation.tagName).toBe("BUTTON");
    expect(grpbxMarketingAssociation.type).toBe("button");
    expect(grpbxMarketingAssociation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMarketingAssociation", () => {
    // START_USER_CODE-USER_grpbxMarketingAssociation_TEST
    // END_USER_CODE-USER_grpbxMarketingAssociation_TEST
  });
  test("grpbxStorageInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxStorageInformation = screen.getByTestId(
      "grpbxStorageInformation"
    );
    expect(grpbxStorageInformation.tagName).toBe("BUTTON");
    expect(grpbxStorageInformation.type).toBe("button");
    expect(grpbxStorageInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorageInformation", () => {
    // START_USER_CODE-USER_grpbxStorageInformation_TEST
    // END_USER_CODE-USER_grpbxStorageInformation_TEST
  });
  test("grpbxWarehouseReceipt(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseReceipt = screen.getByTestId("grpbxWarehouseReceipt");
    expect(grpbxWarehouseReceipt.tagName).toBe("BUTTON");
    expect(grpbxWarehouseReceipt.type).toBe("button");
    expect(grpbxWarehouseReceipt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseReceipt", () => {
    // START_USER_CODE-USER_grpbxWarehouseReceipt_TEST
    // END_USER_CODE-USER_grpbxWarehouseReceipt_TEST
  });
  test("lblAvailable1007s(Label Widget) Test Cases", async () => {
    const lblAvailable1007s = screen.getByTestId("lblAvailable1007s");
    expect(lblAvailable1007s.tagName).toBe("LABEL");
    expect(lblAvailable1007s.classList).toContain("form-label");
    expect(lblAvailable1007s.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_lblAvailable1007s")
    );
  });
  test("Custom Test Cases for lblAvailable1007s", () => {
    // START_USER_CODE-USER_lblAvailable1007s_TEST
    // END_USER_CODE-USER_lblAvailable1007s_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("lblFactorsForAllPeanuts(Label Widget) Test Cases", async () => {
    const lblFactorsForAllPeanuts = screen.getByTestId(
      "lblFactorsForAllPeanuts"
    );
    expect(lblFactorsForAllPeanuts.tagName).toBe("LABEL");
    expect(lblFactorsForAllPeanuts.classList).toContain("form-label");
    expect(lblFactorsForAllPeanuts.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_lblFactorsForAllPeanuts")
    );
  });
  test("Custom Test Cases for lblFactorsForAllPeanuts", () => {
    // START_USER_CODE-USER_lblFactorsForAllPeanuts_TEST
    // END_USER_CODE-USER_lblFactorsForAllPeanuts_TEST
  });
  test("lblInventoriesAvailable(Label Widget) Test Cases", async () => {
    const lblInventoriesAvailable = screen.getByTestId(
      "lblInventoriesAvailable"
    );
    expect(lblInventoriesAvailable.tagName).toBe("LABEL");
    expect(lblInventoriesAvailable.classList).toContain("form-label");
    expect(lblInventoriesAvailable.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_lblInventoriesAvailable")
    );
  });
  test("Custom Test Cases for lblInventoriesAvailable", () => {
    // START_USER_CODE-USER_lblInventoriesAvailable_TEST
    // END_USER_CODE-USER_lblInventoriesAvailable_TEST
  });
  test("lblMarketAssociation(Label Widget) Test Cases", async () => {
    const lblMarketAssociation = screen.getByTestId("lblMarketAssociation");
    expect(lblMarketAssociation.tagName).toBe("LABEL");
    expect(lblMarketAssociation.classList).toContain("form-label");
    expect(lblMarketAssociation.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_lblMarketAssociation")
    );
  });
  test("Custom Test Cases for lblMarketAssociation", () => {
    // START_USER_CODE-USER_lblMarketAssociation_TEST
    // END_USER_CODE-USER_lblMarketAssociation_TEST
  });
  test("lblStorageInformation(Label Widget) Test Cases", async () => {
    const lblStorageInformation = screen.getByTestId("lblStorageInformation");
    expect(lblStorageInformation.tagName).toBe("LABEL");
    expect(lblStorageInformation.classList).toContain("form-label");
    expect(lblStorageInformation.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_lblStorageInformation")
    );
  });
  test("Custom Test Cases for lblStorageInformation", () => {
    // START_USER_CODE-USER_lblStorageInformation_TEST
    // END_USER_CODE-USER_lblStorageInformation_TEST
  });
  test("txt1007GrpNum(Textbox Widget) Test Cases", async () => {
    const txt1007GrpNum = screen.getByTestId("txt1007GrpNum");
    expect(txt1007GrpNum.tagName).toBe("INPUT");
    expect(txt1007GrpNum.type).toBe("text");
    expect(txt1007GrpNum.classList).toContain("textboxWidgetClass");
    expect(txt1007GrpNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txt1007GrpNum")
    );
    await act(async () => {
      userEvent.type(txt1007GrpNum, "1");
    });
  });
  test("Custom Test Cases for txt1007GrpNum", () => {
    // START_USER_CODE-USER_txt1007GrpNum_TEST
    // END_USER_CODE-USER_txt1007GrpNum_TEST
  });
  test("gridAvailable1007s_txtcol1(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcol1 = screen.getByTestId("gridAvailable1007s");
    let gridAvailable1007s_txtcol1btn =
      gridAvailable1007s_txtcol1.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcol1 =
      gridAvailable1007s_txtcol1.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcol1.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcol1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1", () => {
    // START_USER_CODE-USER_txtcol1_TEST
    // END_USER_CODE-USER_txtcol1_TEST
  });
  test("gridAvailable1007s_txtcol1007Num(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcol1007Num = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcol1007Numbtn =
      gridAvailable1007s_txtcol1007Num.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcol1007Num =
      gridAvailable1007s_txtcol1007Num.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcol1007Num.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcol1007Num.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1007Num", () => {
    // START_USER_CODE-USER_txtcol1007Num_TEST
    // END_USER_CODE-USER_txtcol1007Num_TEST
  });
  test("gridAvailable1007s_txtcol2(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcol2 = screen.getByTestId("gridAvailable1007s");
    let gridAvailable1007s_txtcol2btn =
      gridAvailable1007s_txtcol2.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcol2 =
      gridAvailable1007s_txtcol2.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcol2.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcol2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol2", () => {
    // START_USER_CODE-USER_txtcol2_TEST
    // END_USER_CODE-USER_txtcol2_TEST
  });
  test("gridInventoriesAvailable_txtcol3(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcol3 = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcol3btn =
      gridInventoriesAvailable_txtcol3.nextElementSibling.firstElementChild;
    gridInventoriesAvailable_txtcol3 =
      gridInventoriesAvailable_txtcol3.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcol3.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcol3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol3", () => {
    // START_USER_CODE-USER_txtcol3_TEST
    // END_USER_CODE-USER_txtcol3_TEST
  });
  test("gridInventoriesAvailable_txtcolAvailableForAssignment(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolAvailableForAssignment = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolAvailableForAssignmentbtn =
      gridInventoriesAvailable_txtcolAvailableForAssignment.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolAvailableForAssignment =
      gridInventoriesAvailable_txtcolAvailableForAssignment.parentElement
        .parentElement.parentElement;
    expect(gridInventoriesAvailable_txtcolAvailableForAssignment.tagName).toBe(
      "DIV"
    );
    expect(
      gridInventoriesAvailable_txtcolAvailableForAssignment.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAvailableForAssignment", () => {
    // START_USER_CODE-USER_txtcolAvailableForAssignment_TEST
    // END_USER_CODE-USER_txtcolAvailableForAssignment_TEST
  });
  test("gridInventoriesAvailable_txtcolBinDescription(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolBinDescription = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolBinDescriptionbtn =
      gridInventoriesAvailable_txtcolBinDescription.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolBinDescription =
      gridInventoriesAvailable_txtcolBinDescription.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolBinDescription.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolBinDescription.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBinDescription", () => {
    // START_USER_CODE-USER_txtcolBinDescription_TEST
    // END_USER_CODE-USER_txtcolBinDescription_TEST
  });
  test("gridInventoriesAvailable_txtcolCompanyOwned(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolCompanyOwned = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolCompanyOwnedbtn =
      gridInventoriesAvailable_txtcolCompanyOwned.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolCompanyOwned =
      gridInventoriesAvailable_txtcolCompanyOwned.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolCompanyOwned.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolCompanyOwned.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCompanyOwned", () => {
    // START_USER_CODE-USER_txtcolCompanyOwned_TEST
    // END_USER_CODE-USER_txtcolCompanyOwned_TEST
  });
  test("gridAvailable1007s_txtcolContract(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolContract = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolContractbtn =
      gridAvailable1007s_txtcolContract.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolContract =
      gridAvailable1007s_txtcolContract.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolContract.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolContract.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContract", () => {
    // START_USER_CODE-USER_txtcolContract_TEST
    // END_USER_CODE-USER_txtcolContract_TEST
  });
  test("gridAvailable1007s_txtcolFarm(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolFarm = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolFarmbtn =
      gridAvailable1007s_txtcolFarm.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolFarm =
      gridAvailable1007s_txtcolFarm.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolFarm.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolFarm.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarm", () => {
    // START_USER_CODE-USER_txtcolFarm_TEST
    // END_USER_CODE-USER_txtcolFarm_TEST
  });
  test("gridInventoriesAvailable_txtcolFedLicense(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolFedLicense = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolFedLicensebtn =
      gridInventoriesAvailable_txtcolFedLicense.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolFedLicense =
      gridInventoriesAvailable_txtcolFedLicense.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolFedLicense.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolFedLicense.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFedLicense", () => {
    // START_USER_CODE-USER_txtcolFedLicense_TEST
    // END_USER_CODE-USER_txtcolFedLicense_TEST
  });
  test("gridInventoriesAvailable_txtcolGen(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolGen = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolGenbtn =
      gridInventoriesAvailable_txtcolGen.nextElementSibling.firstElementChild;
    gridInventoriesAvailable_txtcolGen =
      gridInventoriesAvailable_txtcolGen.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolGen.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolGen.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGen", () => {
    // START_USER_CODE-USER_txtcolGen_TEST
    // END_USER_CODE-USER_txtcolGen_TEST
  });
  test("gridInventoriesAvailable_txtcolGovernmentCapacity(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolGovernmentCapacity = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolGovernmentCapacitybtn =
      gridInventoriesAvailable_txtcolGovernmentCapacity.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolGovernmentCapacity =
      gridInventoriesAvailable_txtcolGovernmentCapacity.parentElement
        .parentElement.parentElement;
    expect(gridInventoriesAvailable_txtcolGovernmentCapacity.tagName).toBe(
      "DIV"
    );
    expect(
      gridInventoriesAvailable_txtcolGovernmentCapacity.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGovernmentCapacity", () => {
    // START_USER_CODE-USER_txtcolGovernmentCapacity_TEST
    // END_USER_CODE-USER_txtcolGovernmentCapacity_TEST
  });
  test("gridAvailable1007s_txtcolGroupNum(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolGroupNum = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolGroupNumbtn =
      gridAvailable1007s_txtcolGroupNum.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolGroupNum =
      gridAvailable1007s_txtcolGroupNum.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolGroupNum.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolGroupNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGroupNum", () => {
    // START_USER_CODE-USER_txtcolGroupNum_TEST
    // END_USER_CODE-USER_txtcolGroupNum_TEST
  });
  test("gridAvailable1007s_txtcolInspectionDate(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolInspectionDate = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolInspectionDatebtn =
      gridAvailable1007s_txtcolInspectionDate.nextElementSibling
        .firstElementChild;
    gridAvailable1007s_txtcolInspectionDate =
      gridAvailable1007s_txtcolInspectionDate.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolInspectionDate.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolInspectionDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInspectionDate", () => {
    // START_USER_CODE-USER_txtcolInspectionDate_TEST
    // END_USER_CODE-USER_txtcolInspectionDate_TEST
  });
  test("gridInventoriesAvailable_txtcolInTransit(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolInTransit = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolInTransitbtn =
      gridInventoriesAvailable_txtcolInTransit.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolInTransit =
      gridInventoriesAvailable_txtcolInTransit.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolInTransit.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolInTransit.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInTransit", () => {
    // START_USER_CODE-USER_txtcolInTransit_TEST
    // END_USER_CODE-USER_txtcolInTransit_TEST
  });
  test("gridInventoriesAvailable_txtcolLocation(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolLocation = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolLocationbtn =
      gridInventoriesAvailable_txtcolLocation.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolLocation =
      gridInventoriesAvailable_txtcolLocation.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolLocation.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLocation", () => {
    // START_USER_CODE-USER_txtcolLocation_TEST
    // END_USER_CODE-USER_txtcolLocation_TEST
  });
  test("gridAvailable1007s_txtcolLSKWt(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolLSKWt = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolLSKWtbtn =
      gridAvailable1007s_txtcolLSKWt.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolLSKWt =
      gridAvailable1007s_txtcolLSKWt.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolLSKWt.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolLSKWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLSKWt", () => {
    // START_USER_CODE-USER_txtcolLSKWt_TEST
    // END_USER_CODE-USER_txtcolLSKWt_TEST
  });
  test("gridAvailable1007s_txtcolNetWt(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolNetWt = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolNetWtbtn =
      gridAvailable1007s_txtcolNetWt.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolNetWt =
      gridAvailable1007s_txtcolNetWt.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolNetWt.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolNetWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetWt", () => {
    // START_USER_CODE-USER_txtcolNetWt_TEST
    // END_USER_CODE-USER_txtcolNetWt_TEST
  });
  test("gridAvailable1007s_txtcolNetWtLessLSK(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolNetWtLessLSK = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolNetWtLessLSKbtn =
      gridAvailable1007s_txtcolNetWtLessLSK.nextElementSibling
        .firstElementChild;
    gridAvailable1007s_txtcolNetWtLessLSK =
      gridAvailable1007s_txtcolNetWtLessLSK.parentElement.parentElement
        .parentElement;
    expect(gridAvailable1007s_txtcolNetWtLessLSK.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolNetWtLessLSK.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetWtLessLSK", () => {
    // START_USER_CODE-USER_txtcolNetWtLessLSK_TEST
    // END_USER_CODE-USER_txtcolNetWtLessLSK_TEST
  });
  test("gridInventoriesAvailable_txtcolObligatedToWR(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolObligatedToWR = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolObligatedToWRbtn =
      gridInventoriesAvailable_txtcolObligatedToWR.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolObligatedToWR =
      gridInventoriesAvailable_txtcolObligatedToWR.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolObligatedToWR.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolObligatedToWR.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolObligatedToWR", () => {
    // START_USER_CODE-USER_txtcolObligatedToWR_TEST
    // END_USER_CODE-USER_txtcolObligatedToWR_TEST
  });
  test("gridAvailable1007s_txtcolOblPt(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolOblPt = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolOblPtbtn =
      gridAvailable1007s_txtcolOblPt.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolOblPt =
      gridAvailable1007s_txtcolOblPt.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolOblPt.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolOblPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOblPt", () => {
    // START_USER_CODE-USER_txtcolOblPt_TEST
    // END_USER_CODE-USER_txtcolOblPt_TEST
  });
  test("gridInventoriesAvailable_txtcolPhysicalInventory(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolPhysicalInventory = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolPhysicalInventorybtn =
      gridInventoriesAvailable_txtcolPhysicalInventory.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolPhysicalInventory =
      gridInventoriesAvailable_txtcolPhysicalInventory.parentElement
        .parentElement.parentElement;
    expect(gridInventoriesAvailable_txtcolPhysicalInventory.tagName).toBe(
      "DIV"
    );
    expect(
      gridInventoriesAvailable_txtcolPhysicalInventory.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPhysicalInventory", () => {
    // START_USER_CODE-USER_txtcolPhysicalInventory_TEST
    // END_USER_CODE-USER_txtcolPhysicalInventory_TEST
  });
  test("gridAvailable1007s_txtcolSC95(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolSC95 = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolSC95btn =
      gridAvailable1007s_txtcolSC95.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolSC95 =
      gridAvailable1007s_txtcolSC95.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolSC95.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolSC95.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSC95", () => {
    // START_USER_CODE-USER_txtcolSC95_TEST
    // END_USER_CODE-USER_txtcolSC95_TEST
  });
  test("gridInventoriesAvailable_txtcolTentativeOpenStorage(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolTentativeOpenStorage = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolTentativeOpenStoragebtn =
      gridInventoriesAvailable_txtcolTentativeOpenStorage.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolTentativeOpenStorage =
      gridInventoriesAvailable_txtcolTentativeOpenStorage.parentElement
        .parentElement.parentElement;
    expect(gridInventoriesAvailable_txtcolTentativeOpenStorage.tagName).toBe(
      "DIV"
    );
    expect(
      gridInventoriesAvailable_txtcolTentativeOpenStorage.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTentativeOpenStorage", () => {
    // START_USER_CODE-USER_txtcolTentativeOpenStorage_TEST
    // END_USER_CODE-USER_txtcolTentativeOpenStorage_TEST
  });
  test("gridAvailable1007s_txtcolValue(Grid Widget) Test Cases", async () => {
    let gridAvailable1007s_txtcolValue = screen.getByTestId(
      "gridAvailable1007s"
    );
    let gridAvailable1007s_txtcolValuebtn =
      gridAvailable1007s_txtcolValue.nextElementSibling.firstElementChild;
    gridAvailable1007s_txtcolValue =
      gridAvailable1007s_txtcolValue.parentElement.parentElement.parentElement;
    expect(gridAvailable1007s_txtcolValue.tagName).toBe("DIV");
    expect(gridAvailable1007s_txtcolValue.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridAvailable1007s")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolValue", () => {
    // START_USER_CODE-USER_txtcolValue_TEST
    // END_USER_CODE-USER_txtcolValue_TEST
  });
  test("gridInventoriesAvailable_txtcolVariety(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolVariety = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolVarietybtn =
      gridInventoriesAvailable_txtcolVariety.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolVariety =
      gridInventoriesAvailable_txtcolVariety.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolVariety.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVariety", () => {
    // START_USER_CODE-USER_txtcolVariety_TEST
    // END_USER_CODE-USER_txtcolVariety_TEST
  });
  test("gridInventoriesAvailable_txtcolWhouseBin(Grid Widget) Test Cases", async () => {
    let gridInventoriesAvailable_txtcolWhouseBin = screen.getByTestId(
      "gridInventoriesAvailable"
    );
    let gridInventoriesAvailable_txtcolWhouseBinbtn =
      gridInventoriesAvailable_txtcolWhouseBin.nextElementSibling
        .firstElementChild;
    gridInventoriesAvailable_txtcolWhouseBin =
      gridInventoriesAvailable_txtcolWhouseBin.parentElement.parentElement
        .parentElement;
    expect(gridInventoriesAvailable_txtcolWhouseBin.tagName).toBe("DIV");
    expect(gridInventoriesAvailable_txtcolWhouseBin.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceipt_gridInventoriesAvailable")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhouseBin", () => {
    // START_USER_CODE-USER_txtcolWhouseBin_TEST
    // END_USER_CODE-USER_txtcolWhouseBin_TEST
  });
  test("txtContractNum(Textbox Widget) Test Cases", async () => {
    const txtContractNum = screen.getByTestId("txtContractNum");
    expect(txtContractNum.tagName).toBe("INPUT");
    expect(txtContractNum.type).toBe("text");
    expect(txtContractNum.classList).toContain("textboxWidgetClass");
    expect(txtContractNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtContractNum")
    );
    await act(async () => {
      userEvent.type(txtContractNum, "1");
    });
  });
  test("Custom Test Cases for txtContractNum", () => {
    // START_USER_CODE-USER_txtContractNum_TEST
    // END_USER_CODE-USER_txtContractNum_TEST
  });
  test("txtControlNumber(Textbox Widget) Test Cases", async () => {
    const txtControlNumber = screen.getByTestId("txtControlNumber");
    expect(txtControlNumber.tagName).toBe("INPUT");
    expect(txtControlNumber.type).toBe("text");
    expect(txtControlNumber.classList).toContain("textboxWidgetClass");
    expect(txtControlNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtControlNumber")
    );
    await act(async () => {
      userEvent.type(txtControlNumber, "1");
    });
  });
  test("Custom Test Cases for txtControlNumber", () => {
    // START_USER_CODE-USER_txtControlNumber_TEST
    // END_USER_CODE-USER_txtControlNumber_TEST
  });
  test("txtFlavus(Textbox Widget) Test Cases", async () => {
    const txtFlavus = screen.getByTestId("txtFlavus");
    expect(txtFlavus.tagName).toBe("INPUT");
    expect(txtFlavus.type).toBe("text");
    expect(txtFlavus.classList).toContain("textboxWidgetClass");
    expect(txtFlavus.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtFlavus")
    );
    await act(async () => {
      userEvent.type(txtFlavus, "123");
    });
    expect(txtFlavus.getAttribute("value")).toBe("");
    expect(txtFlavus.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFlavus", () => {
    // START_USER_CODE-USER_txtFlavus_TEST
    // END_USER_CODE-USER_txtFlavus_TEST
  });
  test("txtHillsPct(Textbox Widget) Test Cases", async () => {
    const txtHillsPct = screen.getByTestId("txtHillsPct");
    expect(txtHillsPct.tagName).toBe("INPUT");
    expect(txtHillsPct.type).toBe("text");
    expect(txtHillsPct.classList).toContain("textboxWidgetClass");
    expect(txtHillsPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtHillsPct")
    );
    await act(async () => {
      userEvent.type(txtHillsPct, "123");
    });
    expect(txtHillsPct.getAttribute("value")).toBe("");
    expect(txtHillsPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHillsPct", () => {
    // START_USER_CODE-USER_txtHillsPct_TEST
    // END_USER_CODE-USER_txtHillsPct_TEST
  });
  test("txtHoeStored(Textbox Widget) Test Cases", async () => {
    const txtHoeStored = screen.getByTestId("txtHoeStored");
    expect(txtHoeStored.tagName).toBe("INPUT");
    expect(txtHoeStored.type).toBe("text");
    expect(txtHoeStored.classList).toContain("textboxWidgetClass");
    expect(txtHoeStored.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtHoeStored")
    );
    await act(async () => {
      userEvent.type(txtHoeStored, "123");
    });
    expect(txtHoeStored.getAttribute("value")).toBe("");
    expect(txtHoeStored.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHoeStored", () => {
    // START_USER_CODE-USER_txtHoeStored_TEST
    // END_USER_CODE-USER_txtHoeStored_TEST
  });
  test("txtHullsBrightPct(Textbox Widget) Test Cases", async () => {
    const txtHullsBrightPct = screen.getByTestId("txtHullsBrightPct");
    expect(txtHullsBrightPct.tagName).toBe("INPUT");
    expect(txtHullsBrightPct.type).toBe("text");
    expect(txtHullsBrightPct.classList).toContain("textboxWidgetClass");
    expect(txtHullsBrightPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtHullsBrightPct")
    );
    await act(async () => {
      userEvent.type(txtHullsBrightPct, "123");
    });
    expect(txtHullsBrightPct.getAttribute("value")).toBe("");
    expect(txtHullsBrightPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHullsBrightPct", () => {
    // START_USER_CODE-USER_txtHullsBrightPct_TEST
    // END_USER_CODE-USER_txtHullsBrightPct_TEST
  });
  test("txtIssueDate(Textbox Widget) Test Cases", async () => {
    const txtIssueDate = screen.getByTestId("txtIssueDate");
    expect(txtIssueDate.tagName).toBe("INPUT");
    expect(txtIssueDate.type).toBe("text");
    expect(txtIssueDate.classList).toContain("textboxWidgetClass");
    expect(txtIssueDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtIssueDate")
    );
    await act(async () => {
      userEvent.type(txtIssueDate, "123");
    });
    expect(txtIssueDate.getAttribute("value")).toBe("");
    expect(txtIssueDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtIssueDate", () => {
    // START_USER_CODE-USER_txtIssueDate_TEST
    // END_USER_CODE-USER_txtIssueDate_TEST
  });
  test("txtJumboPct(Textbox Widget) Test Cases", async () => {
    const txtJumboPct = screen.getByTestId("txtJumboPct");
    expect(txtJumboPct.tagName).toBe("INPUT");
    expect(txtJumboPct.type).toBe("text");
    expect(txtJumboPct.classList).toContain("textboxWidgetClass");
    expect(txtJumboPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtJumboPct")
    );
    await act(async () => {
      userEvent.type(txtJumboPct, "123");
    });
    expect(txtJumboPct.getAttribute("value")).toBe("");
    expect(txtJumboPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtJumboPct", () => {
    // START_USER_CODE-USER_txtJumboPct_TEST
    // END_USER_CODE-USER_txtJumboPct_TEST
  });
  test("txtLSKLbs(Textbox Widget) Test Cases", async () => {
    const txtLSKLbs = screen.getByTestId("txtLSKLbs");
    expect(txtLSKLbs.tagName).toBe("INPUT");
    expect(txtLSKLbs.type).toBe("text");
    expect(txtLSKLbs.classList).toContain("textboxWidgetClass");
    expect(txtLSKLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtLSKLbs")
    );
    await act(async () => {
      userEvent.type(txtLSKLbs, "123");
    });
    expect(txtLSKLbs.getAttribute("value")).toBe("");
    expect(txtLSKLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKLbs", () => {
    // START_USER_CODE-USER_txtLSKLbs_TEST
    // END_USER_CODE-USER_txtLSKLbs_TEST
  });
  test("txtLSKLbsShrunk(Textbox Widget) Test Cases", async () => {
    const txtLSKLbsShrunk = screen.getByTestId("txtLSKLbsShrunk");
    expect(txtLSKLbsShrunk.tagName).toBe("INPUT");
    expect(txtLSKLbsShrunk.type).toBe("text");
    expect(txtLSKLbsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKLbsShrunk, "123");
    });
    expect(txtLSKLbsShrunk.getAttribute("value")).toBe("");
    expect(txtLSKLbsShrunk.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKLbsShrunk", () => {
    // START_USER_CODE-USER_txtLSKLbsShrunk_TEST
    // END_USER_CODE-USER_txtLSKLbsShrunk_TEST
  });
  test("txtLSKPct(Textbox Widget) Test Cases", async () => {
    const txtLSKPct = screen.getByTestId("txtLSKPct");
    expect(txtLSKPct.tagName).toBe("INPUT");
    expect(txtLSKPct.type).toBe("text");
    expect(txtLSKPct.classList).toContain("textboxWidgetClass");
    expect(txtLSKPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtLSKPct")
    );
    await act(async () => {
      userEvent.type(txtLSKPct, "123");
    });
    expect(txtLSKPct.getAttribute("value")).toBe("");
    expect(txtLSKPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKPct", () => {
    // START_USER_CODE-USER_txtLSKPct_TEST
    // END_USER_CODE-USER_txtLSKPct_TEST
  });
  test("txtNetLbs(Textbox Widget) Test Cases", async () => {
    const txtNetLbs = screen.getByTestId("txtNetLbs");
    expect(txtNetLbs.tagName).toBe("INPUT");
    expect(txtNetLbs.type).toBe("text");
    expect(txtNetLbs.classList).toContain("textboxWidgetClass");
    expect(txtNetLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtNetLbs")
    );
    await act(async () => {
      userEvent.type(txtNetLbs, "123");
    });
    expect(txtNetLbs.getAttribute("value")).toBe("");
    expect(txtNetLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetLbs", () => {
    // START_USER_CODE-USER_txtNetLbs_TEST
    // END_USER_CODE-USER_txtNetLbs_TEST
  });
  test("txtNetLbsShrunk(Textbox Widget) Test Cases", async () => {
    const txtNetLbsShrunk = screen.getByTestId("txtNetLbsShrunk");
    expect(txtNetLbsShrunk.tagName).toBe("INPUT");
    expect(txtNetLbsShrunk.type).toBe("text");
    expect(txtNetLbsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetLbsShrunk, "123");
    });
    expect(txtNetLbsShrunk.getAttribute("value")).toBe("");
    expect(txtNetLbsShrunk.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetLbsShrunk", () => {
    // START_USER_CODE-USER_txtNetLbsShrunk_TEST
    // END_USER_CODE-USER_txtNetLbsShrunk_TEST
  });
  test("txtPctConcealedRMD(Textbox Widget) Test Cases", async () => {
    const txtPctConcealedRMD = screen.getByTestId("txtPctConcealedRMD");
    expect(txtPctConcealedRMD.tagName).toBe("INPUT");
    expect(txtPctConcealedRMD.type).toBe("text");
    expect(txtPctConcealedRMD.classList).toContain("textboxWidgetClass");
    expect(txtPctConcealedRMD.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctConcealedRMD")
    );
    await act(async () => {
      userEvent.type(txtPctConcealedRMD, "123");
    });
    expect(txtPctConcealedRMD.getAttribute("value")).toBe("");
    expect(txtPctConcealedRMD.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctConcealedRMD", () => {
    // START_USER_CODE-USER_txtPctConcealedRMD_TEST
    // END_USER_CODE-USER_txtPctConcealedRMD_TEST
  });
  test("txtPctCrackBroken(Textbox Widget) Test Cases", async () => {
    const txtPctCrackBroken = screen.getByTestId("txtPctCrackBroken");
    expect(txtPctCrackBroken.tagName).toBe("INPUT");
    expect(txtPctCrackBroken.type).toBe("text");
    expect(txtPctCrackBroken.classList).toContain("textboxWidgetClass");
    expect(txtPctCrackBroken.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctCrackBroken")
    );
    await act(async () => {
      userEvent.type(txtPctCrackBroken, "123");
    });
    expect(txtPctCrackBroken.getAttribute("value")).toBe("");
    expect(txtPctCrackBroken.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctCrackBroken", () => {
    // START_USER_CODE-USER_txtPctCrackBroken_TEST
    // END_USER_CODE-USER_txtPctCrackBroken_TEST
  });
  test("txtPctDiscolored(Textbox Widget) Test Cases", async () => {
    const txtPctDiscolored = screen.getByTestId("txtPctDiscolored");
    expect(txtPctDiscolored.tagName).toBe("INPUT");
    expect(txtPctDiscolored.type).toBe("text");
    expect(txtPctDiscolored.classList).toContain("textboxWidgetClass");
    expect(txtPctDiscolored.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctDiscolored")
    );
    await act(async () => {
      userEvent.type(txtPctDiscolored, "123");
    });
    expect(txtPctDiscolored.getAttribute("value")).toBe("");
    expect(txtPctDiscolored.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctDiscolored", () => {
    // START_USER_CODE-USER_txtPctDiscolored_TEST
    // END_USER_CODE-USER_txtPctDiscolored_TEST
  });
  test("txtPctELK(Textbox Widget) Test Cases", async () => {
    const txtPctELK = screen.getByTestId("txtPctELK");
    expect(txtPctELK.tagName).toBe("INPUT");
    expect(txtPctELK.type).toBe("text");
    expect(txtPctELK.classList).toContain("textboxWidgetClass");
    expect(txtPctELK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctELK")
    );
    await act(async () => {
      userEvent.type(txtPctELK, "123");
    });
    expect(txtPctELK.getAttribute("value")).toBe("");
    expect(txtPctELK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctELK", () => {
    // START_USER_CODE-USER_txtPctELK_TEST
    // END_USER_CODE-USER_txtPctELK_TEST
  });
  test("txtPctFancy(Textbox Widget) Test Cases", async () => {
    const txtPctFancy = screen.getByTestId("txtPctFancy");
    expect(txtPctFancy.tagName).toBe("INPUT");
    expect(txtPctFancy.type).toBe("text");
    expect(txtPctFancy.classList).toContain("textboxWidgetClass");
    expect(txtPctFancy.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctFancy")
    );
    await act(async () => {
      userEvent.type(txtPctFancy, "123");
    });
    expect(txtPctFancy.getAttribute("value")).toBe("");
    expect(txtPctFancy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctFancy", () => {
    // START_USER_CODE-USER_txtPctFancy_TEST
    // END_USER_CODE-USER_txtPctFancy_TEST
  });
  test("txtPctFM(Textbox Widget) Test Cases", async () => {
    const txtPctFM = screen.getByTestId("txtPctFM");
    expect(txtPctFM.tagName).toBe("INPUT");
    expect(txtPctFM.type).toBe("text");
    expect(txtPctFM.classList).toContain("textboxWidgetClass");
    expect(txtPctFM.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctFM")
    );
    await act(async () => {
      userEvent.type(txtPctFM, "123");
    });
    expect(txtPctFM.getAttribute("value")).toBe("");
    expect(txtPctFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctFM", () => {
    // START_USER_CODE-USER_txtPctFM_TEST
    // END_USER_CODE-USER_txtPctFM_TEST
  });
  test("txtPctFreezeDamage(Textbox Widget) Test Cases", async () => {
    const txtPctFreezeDamage = screen.getByTestId("txtPctFreezeDamage");
    expect(txtPctFreezeDamage.tagName).toBe("INPUT");
    expect(txtPctFreezeDamage.type).toBe("text");
    expect(txtPctFreezeDamage.classList).toContain("textboxWidgetClass");
    expect(txtPctFreezeDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctFreezeDamage")
    );
    await act(async () => {
      userEvent.type(txtPctFreezeDamage, "123");
    });
    expect(txtPctFreezeDamage.getAttribute("value")).toBe("");
    expect(txtPctFreezeDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctFreezeDamage", () => {
    // START_USER_CODE-USER_txtPctFreezeDamage_TEST
    // END_USER_CODE-USER_txtPctFreezeDamage_TEST
  });
  test("txtPctMoist(Textbox Widget) Test Cases", async () => {
    const txtPctMoist = screen.getByTestId("txtPctMoist");
    expect(txtPctMoist.tagName).toBe("INPUT");
    expect(txtPctMoist.type).toBe("text");
    expect(txtPctMoist.classList).toContain("textboxWidgetClass");
    expect(txtPctMoist.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctMoist")
    );
    await act(async () => {
      userEvent.type(txtPctMoist, "123");
    });
    expect(txtPctMoist.getAttribute("value")).toBe("");
    expect(txtPctMoist.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctMoist", () => {
    // START_USER_CODE-USER_txtPctMoist_TEST
    // END_USER_CODE-USER_txtPctMoist_TEST
  });
  test("txtPctOtherKernels(Textbox Widget) Test Cases", async () => {
    const txtPctOtherKernels = screen.getByTestId("txtPctOtherKernels");
    expect(txtPctOtherKernels.tagName).toBe("INPUT");
    expect(txtPctOtherKernels.type).toBe("text");
    expect(txtPctOtherKernels.classList).toContain("textboxWidgetClass");
    expect(txtPctOtherKernels.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctOtherKernels")
    );
    await act(async () => {
      userEvent.type(txtPctOtherKernels, "123");
    });
    expect(txtPctOtherKernels.getAttribute("value")).toBe("");
    expect(txtPctOtherKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctOtherKernels", () => {
    // START_USER_CODE-USER_txtPctOtherKernels_TEST
    // END_USER_CODE-USER_txtPctOtherKernels_TEST
  });
  test("txtPctSplitDamage(Textbox Widget) Test Cases", async () => {
    const txtPctSplitDamage = screen.getByTestId("txtPctSplitDamage");
    expect(txtPctSplitDamage.tagName).toBe("INPUT");
    expect(txtPctSplitDamage.type).toBe("text");
    expect(txtPctSplitDamage.classList).toContain("textboxWidgetClass");
    expect(txtPctSplitDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctSplitDamage")
    );
    await act(async () => {
      userEvent.type(txtPctSplitDamage, "123");
    });
    expect(txtPctSplitDamage.getAttribute("value")).toBe("");
    expect(txtPctSplitDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctSplitDamage", () => {
    // START_USER_CODE-USER_txtPctSplitDamage_TEST
    // END_USER_CODE-USER_txtPctSplitDamage_TEST
  });
  test("txtPctTotalDamage(Textbox Widget) Test Cases", async () => {
    const txtPctTotalDamage = screen.getByTestId("txtPctTotalDamage");
    expect(txtPctTotalDamage.tagName).toBe("INPUT");
    expect(txtPctTotalDamage.type).toBe("text");
    expect(txtPctTotalDamage.classList).toContain("textboxWidgetClass");
    expect(txtPctTotalDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctTotalDamage")
    );
    await act(async () => {
      userEvent.type(txtPctTotalDamage, "123");
    });
    expect(txtPctTotalDamage.getAttribute("value")).toBe("");
    expect(txtPctTotalDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctTotalDamage", () => {
    // START_USER_CODE-USER_txtPctTotalDamage_TEST
    // END_USER_CODE-USER_txtPctTotalDamage_TEST
  });
  test("txtPctTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtPctTotalKernels = screen.getByTestId("txtPctTotalKernels");
    expect(txtPctTotalKernels.tagName).toBe("INPUT");
    expect(txtPctTotalKernels.type).toBe("text");
    expect(txtPctTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtPctTotalKernels.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtPctTotalKernels, "123");
    });
    expect(txtPctTotalKernels.getAttribute("value")).toBe("");
    expect(txtPctTotalKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctTotalKernels", () => {
    // START_USER_CODE-USER_txtPctTotalKernels_TEST
    // END_USER_CODE-USER_txtPctTotalKernels_TEST
  });
  test("txtPctTotalKrnlsHulls(Textbox Widget) Test Cases", async () => {
    const txtPctTotalKrnlsHulls = screen.getByTestId("txtPctTotalKrnlsHulls");
    expect(txtPctTotalKrnlsHulls.tagName).toBe("INPUT");
    expect(txtPctTotalKrnlsHulls.type).toBe("text");
    expect(txtPctTotalKrnlsHulls.classList).toContain("textboxWidgetClass");
    expect(txtPctTotalKrnlsHulls.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPctTotalKrnlsHulls")
    );
    await act(async () => {
      userEvent.type(txtPctTotalKrnlsHulls, "123");
    });
    expect(txtPctTotalKrnlsHulls.getAttribute("value")).toBe("");
    expect(txtPctTotalKrnlsHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctTotalKrnlsHulls", () => {
    // START_USER_CODE-USER_txtPctTotalKrnlsHulls_TEST
    // END_USER_CODE-USER_txtPctTotalKrnlsHulls_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "123");
    });
    expect(txtSeg.getAttribute("value")).toBe("");
    expect(txtSeg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtSMKPct(Textbox Widget) Test Cases", async () => {
    const txtSMKPct = screen.getByTestId("txtSMKPct");
    expect(txtSMKPct.tagName).toBe("INPUT");
    expect(txtSMKPct.type).toBe("text");
    expect(txtSMKPct.classList).toContain("textboxWidgetClass");
    expect(txtSMKPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtSMKPct")
    );
    await act(async () => {
      userEvent.type(txtSMKPct, "123");
    });
    expect(txtSMKPct.getAttribute("value")).toBe("");
    expect(txtSMKPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMKPct", () => {
    // START_USER_CODE-USER_txtSMKPct_TEST
    // END_USER_CODE-USER_txtSMKPct_TEST
  });
  test("txtSSPct(Textbox Widget) Test Cases", async () => {
    const txtSSPct = screen.getByTestId("txtSSPct");
    expect(txtSSPct.tagName).toBe("INPUT");
    expect(txtSSPct.type).toBe("text");
    expect(txtSSPct.classList).toContain("textboxWidgetClass");
    expect(txtSSPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtSSPct")
    );
    await act(async () => {
      userEvent.type(txtSSPct, "123");
    });
    expect(txtSSPct.getAttribute("value")).toBe("");
    expect(txtSSPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSSPct", () => {
    // START_USER_CODE-USER_txtSSPct_TEST
    // END_USER_CODE-USER_txtSSPct_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "123");
    });
    expect(txtStatus.getAttribute("value")).toBe("");
    expect(txtStatus.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtStorageDate(Textbox Widget) Test Cases", async () => {
    const txtStorageDate = screen.getByTestId("txtStorageDate");
    expect(txtStorageDate.tagName).toBe("INPUT");
    expect(txtStorageDate.type).toBe("text");
    expect(txtStorageDate.classList).toContain("textboxWidgetClass");
    expect(txtStorageDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtStorageDate")
    );
    await act(async () => {
      userEvent.type(txtStorageDate, "123");
    });
    expect(txtStorageDate.getAttribute("value")).toBe("");
    expect(txtStorageDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStorageDate", () => {
    // START_USER_CODE-USER_txtStorageDate_TEST
    // END_USER_CODE-USER_txtStorageDate_TEST
  });
  test("txtTotalLbs(Textbox Widget) Test Cases", async () => {
    const txtTotalLbs = screen.getByTestId("txtTotalLbs");
    expect(txtTotalLbs.tagName).toBe("INPUT");
    expect(txtTotalLbs.type).toBe("text");
    expect(txtTotalLbs.classList).toContain("textboxWidgetClass");
    expect(txtTotalLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtTotalLbs")
    );
    await act(async () => {
      userEvent.type(txtTotalLbs, "123");
    });
    expect(txtTotalLbs.getAttribute("value")).toBe("");
    expect(txtTotalLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLbs", () => {
    // START_USER_CODE-USER_txtTotalLbs_TEST
    // END_USER_CODE-USER_txtTotalLbs_TEST
  });
  test("txtTotalLbsShrunk(Textbox Widget) Test Cases", async () => {
    const txtTotalLbsShrunk = screen.getByTestId("txtTotalLbsShrunk");
    expect(txtTotalLbsShrunk.tagName).toBe("INPUT");
    expect(txtTotalLbsShrunk.type).toBe("text");
    expect(txtTotalLbsShrunk.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalLbsShrunk, "123");
    });
    expect(txtTotalLbsShrunk.getAttribute("value")).toBe("");
    expect(txtTotalLbsShrunk.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLbsShrunk", () => {
    // START_USER_CODE-USER_txtTotalLbsShrunk_TEST
    // END_USER_CODE-USER_txtTotalLbsShrunk_TEST
  });
  test("txtTotalSMKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKPct = screen.getByTestId("txtTotalSMKPct");
    expect(txtTotalSMKPct.tagName).toBe("INPUT");
    expect(txtTotalSMKPct.type).toBe("text");
    expect(txtTotalSMKPct.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMKPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtTotalSMKPct")
    );
    await act(async () => {
      userEvent.type(txtTotalSMKPct, "123");
    });
    expect(txtTotalSMKPct.getAttribute("value")).toBe("");
    expect(txtTotalSMKPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSMKPct", () => {
    // START_USER_CODE-USER_txtTotalSMKPct_TEST
    // END_USER_CODE-USER_txtTotalSMKPct_TEST
  });
  test("txtValShrinkPct(Textbox Widget) Test Cases", async () => {
    const txtValShrinkPct = screen.getByTestId("txtValShrinkPct");
    expect(txtValShrinkPct.tagName).toBe("INPUT");
    expect(txtValShrinkPct.type).toBe("text");
    expect(txtValShrinkPct.classList).toContain("textboxWidgetClass");
    expect(txtValShrinkPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtValShrinkPct")
    );
    await act(async () => {
      userEvent.type(txtValShrinkPct, "123");
    });
    expect(txtValShrinkPct.getAttribute("value")).toBe("");
    expect(txtValShrinkPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValShrinkPct", () => {
    // START_USER_CODE-USER_txtValShrinkPct_TEST
    // END_USER_CODE-USER_txtValShrinkPct_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
  test("txtVendorNumMA(Textbox Widget) Test Cases", async () => {
    const txtVendorNumMA = screen.getByTestId("txtVendorNumMA");
    expect(txtVendorNumMA.tagName).toBe("INPUT");
    expect(txtVendorNumMA.type).toBe("text");
    expect(txtVendorNumMA.classList).toContain("textboxWidgetClass");
    expect(txtVendorNumMA.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtVendorNumMA")
    );
    await act(async () => {
      userEvent.type(txtVendorNumMA, "123");
    });
    expect(txtVendorNumMA.getAttribute("value")).toBe("");
    expect(txtVendorNumMA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendorNumMA", () => {
    // START_USER_CODE-USER_txtVendorNumMA_TEST
    // END_USER_CODE-USER_txtVendorNumMA_TEST
  });
  test("txtVICAMPPB(Textbox Widget) Test Cases", async () => {
    const txtVICAMPPB = screen.getByTestId("txtVICAMPPB");
    expect(txtVICAMPPB.tagName).toBe("INPUT");
    expect(txtVICAMPPB.type).toBe("text");
    expect(txtVICAMPPB.classList).toContain("textboxWidgetClass");
    expect(txtVICAMPPB.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtVICAMPPB")
    );
    await act(async () => {
      userEvent.type(txtVICAMPPB, "123");
    });
    expect(txtVICAMPPB.getAttribute("value")).toBe("");
    expect(txtVICAMPPB.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVICAMPPB", () => {
    // START_USER_CODE-USER_txtVICAMPPB_TEST
    // END_USER_CODE-USER_txtVICAMPPB_TEST
  });
  test("txtWtsShrinkPct(Textbox Widget) Test Cases", async () => {
    const txtWtsShrinkPct = screen.getByTestId("txtWtsShrinkPct");
    expect(txtWtsShrinkPct.tagName).toBe("INPUT");
    expect(txtWtsShrinkPct.type).toBe("text");
    expect(txtWtsShrinkPct.classList).toContain("textboxWidgetClass");
    expect(txtWtsShrinkPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceipt_txtWtsShrinkPct")
    );
    await act(async () => {
      userEvent.type(txtWtsShrinkPct, "1");
    });
  });
  test("Custom Test Cases for txtWtsShrinkPct", () => {
    // START_USER_CODE-USER_txtWtsShrinkPct_TEST
    // END_USER_CODE-USER_txtWtsShrinkPct_TEST
  });
});
