/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setData,
  getData,
  setValue,
  getValue,
  hideColumn,
  getGridValue,
  goTo,
} from "../../shared/WindowImports";

import "./MultiStopSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import { StockTransferService } from "../Service/StockTransferService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_MultiStopSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "MultiStopSelect",
    windowName: "MultiStopSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.MultiStopSelect",
    // START_USER_CODE-USER_MultiStopSelect_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Multi Transfer Select",
      scrCode: "PN1080L",
    },
    // END_USER_CODE-USER_MultiStopSelect_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndDntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxDetails",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnSelect: {
      name: "btnSelect",
      type: "ButtonWidget",
      parent: "grpbxTransferList",
      Label: "Select",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSelect_PROPERTIES

      // END_USER_CODE-USER_btnSelect_PROPERTIES
    },
    colDestinationBuyingPoint: {
      name: "colDestinationBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Dest Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDestinationBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colDestinationBuyingPoint_PROPERTIES
    },
    colOriginalBuyingPoint: {
      name: "colOriginalBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Orig Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOriginalBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colOriginalBuyingPoint_PROPERTIES
    },
    colPayInd: {
      name: "colPayInd",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Pay Ind.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayInd_PROPERTIES

      // END_USER_CODE-USER_colPayInd_PROPERTIES
    },
    colLegMileage: {
      name: "colLegMileage",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Leg Mileage",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLegMileage_PROPERTIES

      // END_USER_CODE-USER_colLegMileage_PROPERTIES
    },
    colOverride: {
      name: "colOverride",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Mileage Override",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOverride_PROPERTIES

      // END_USER_CODE-USER_colOverride_PROPERTIES
    },
    colScacID: {
      name: "colScacID",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Scac ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colScacID_PROPERTIES

      // END_USER_CODE-USER_colScacID_PROPERTIES
    },
    colTransferNum: {
      name: "colTransferNum",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Transfer #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferNum_PROPERTIES

      // END_USER_CODE-USER_colTransferNum_PROPERTIES
    },
    colTransferType: {
      name: "colTransferType",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Transfer Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferType_PROPERTIES

      // END_USER_CODE-USER_colTransferType_PROPERTIES
    },
    colVehicleNum: {
      name: "colVehicleNum",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Vehicle #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVehicleNum_PROPERTIES

      // END_USER_CODE-USER_colVehicleNum_PROPERTIES
    },
    colVendorNum: {
      name: "colVendorNum",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Vendor #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorNum_PROPERTIES

      // END_USER_CODE-USER_colVendorNum_PROPERTIES
    },
    ddDestinationLocation: {
      name: "ddDestinationLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Destination Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestinationLocation_PROPERTIES

      // END_USER_CODE-USER_ddDestinationLocation_PROPERTIES
    },
    ddOriginalLocation: {
      name: "ddOriginalLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Original Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOriginalLocation_PROPERTIES

      // END_USER_CODE-USER_ddOriginalLocation_PROPERTIES
    },
    ddTransferType: {
      name: "ddTransferType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Transfer Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferType_PROPERTIES

      // END_USER_CODE-USER_ddTransferType_PROPERTIES
    },
    gridTransferList: {
      name: "gridTransferList",
      type: "GridWidget",
      parent: "grpbxTransferList",
      gridCellsOrder:
        "txtcolTransferNum,txtcolTransferType,txtcolOriginalBuyingPoint,txtcolDestinationBuyingPoint,txtcolScacID,txtcolVendorNum,txtcolVehicleNum,txtcolPayInd,txtcolLegMileage,txtcolOverride",
      Pagination: false,
      isMultiSelect: true,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridTransferList_PROPERTIES

      // END_USER_CODE-USER_gridTransferList_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxMultiStopSelect",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxTransferList: {
      name: "grpbxTransferList",
      type: "GroupBoxWidget",
      parent: "grpbxMultiStopSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxTransferList_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferList_PROPERTIES
    },
    lblTransferList: {
      name: "lblTransferList",
      type: "LabelWidget",
      parent: "grpbxTransferList",
      Label: "Transfer List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTransferList_PROPERTIES

      // END_USER_CODE-USER_lblTransferList_PROPERTIES
    },
    txtcolDestinationBuyingPoint: {
      name: "txtcolDestinationBuyingPoint",
      type: "TextBoxWidget",
      colName: "colDestinationBuyingPoint",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDestinationBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolDestinationBuyingPoint_PROPERTIES
    },
    txtcolOriginalBuyingPoint: {
      name: "txtcolOriginalBuyingPoint",
      type: "TextBoxWidget",
      colName: "colOriginalBuyingPoint",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOriginalBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolOriginalBuyingPoint_PROPERTIES
    },
    txtcolPayInd: {
      name: "txtcolPayInd",
      type: "TextBoxWidget",
      colName: "colPayInd",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayInd_PROPERTIES

      // END_USER_CODE-USER_txtcolPayInd_PROPERTIES
    },
    txtcolLegMileage: {
      name: "txtcolLegMileage",
      type: "TextBoxWidget",
      colName: "colLegMileage",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLegMileage_PROPERTIES

      // END_USER_CODE-USER_txtcolLegMileage_PROPERTIES
    },
    txtcolOverride: {
      name: "txtcolOverride",
      type: "TextBoxWidget",
      colName: "colOverride",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOverride_PROPERTIES

      // END_USER_CODE-USER_txtcolOverride_PROPERTIES
    },
    txtcolScacID: {
      name: "txtcolScacID",
      type: "TextBoxWidget",
      colName: "colScacID",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolScacID_PROPERTIES

      // END_USER_CODE-USER_txtcolScacID_PROPERTIES
    },
    txtcolTransferNum: {
      name: "txtcolTransferNum",
      type: "TextBoxWidget",
      colName: "colTransferNum",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferNum_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolTransferNum_PROPERTIES
    },
    txtcolTransferType: {
      name: "txtcolTransferType",
      type: "TextBoxWidget",
      colName: "colTransferType",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferType_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferType_PROPERTIES
    },
    txtcolVehicleNum: {
      name: "txtcolVehicleNum",
      type: "TextBoxWidget",
      colName: "colVehicleNum",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtcolVehicleNum_PROPERTIES
    },
    txtcolVendorNum: {
      name: "txtcolVendorNum",
      type: "TextBoxWidget",
      colName: "colVendorNum",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorNum_PROPERTIES
    },
    txtScacID: {
      name: "txtScacID",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Scac ID:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtScacID_PROPERTIES

      // END_USER_CODE-USER_txtScacID_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtVehicleNum: {
      name: "txtVehicleNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vehicle #:",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtVehicleNum_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    grpbxMultiStopSelect: {
      name: "grpbxMultiStopSelect",
      type: "GroupBoxWidget",
      parent: "MultiStopSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxMultiStopSelect_PROPERTIES

      // END_USER_CODE-USER_grpbxMultiStopSelect_PROPERTIES
    },
    cmmndDntnrActions: {
      name: "cmmndDntnrActions",
      type: "CommandContainerWidget",
      parent: "MultiStopSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndDntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndDntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    setTabIndex();
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload()
    setTimeout(() => {
      document.getElementsByClassName('ddOriginalLocation')[1].firstChild.lastChild.firstChild.firstChild.focus()
    }, 500);
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  let Formload = async () => {
    setLoading(true)
    await bFillBuyingPointList(); //Function to set the original and destination buying point
    bFillTransferType() //Function to set the transfer type dropdown
    hideColumn(thisObj,'gridTransferList','txtcolOverride') 
    hideColumn(thisObj,'gridTransferList','txtcolLegMileage')
    setLoading(false)
  }

  const bFillBuyingPointList = async () => {
    let js = []
    let lstLocCmaVendor=[];
    js.push({ key: '', description: '>>> All Buying Points <<<' });
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0160', null, null, null, null)
    let data = response
    for (let i = 0; i < data.length; i++) {
      let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
      lstLocCmaVendor.push({buy_pt_id:data[i].buy_pt_id,loccmavendor:data[i].default_cma_vendor_num})
      js.push(obj)
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddOriginalLocation: {
          ...state["ddOriginalLocation"],
          valueList: js
        }
      }
    })

    thisObj.setState(current => {
      return {
        ...current,
        ddDestinationLocation: {
          ...state["ddDestinationLocation"],
          valueList: js
        }
      }
    })

    setValue(thisObj, 'ddOriginalLocation', js.at(0).key);
    setValue(thisObj, 'ddDestinationLocation', js.at(0).key);
  }

  const bFillTransferType = () => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'ST', description: 'Stock Transfer' })
    js.push({ key: 'FF', description: 'Farm Freight' })
    js.push({ key: 'NL', description: 'No Load' })

    thisObj.setState(current => {
      return {
        ...current,
        ddTransferType: {
          ...state["ddTransferType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddTransferType', js.at(0).key);
  }

  //Search button Click code
  const onbtnSearchClick = async() =>{
    try{
      setLoading(true)

      //Initiliazing all the required parameters
      let originalBuyingpoint=getValue(thisObj,'ddOriginalLocation')
      let destinationBuyingPoint=getValue(thisObj,'ddDestinationLocation')
      let transferNumber=getValue(thisObj,'txtTransferNum')==undefined ? '' : getValue(thisObj,'txtTransferNum')
      let transfer_type= getValue(thisObj,'ddTransferType')
      let frt_scac_id=getValue(thisObj,'txtScacID')
      let vendor_num=getValue(thisObj,'txtVendorNum')
      let vehichle_num=getValue(thisObj,'txtVehicleNum')


      if(originalBuyingpoint==''){
        if(destinationBuyingPoint==''){
          if(transferNumber==''){
            showMessage(thisObj,'Must enter Shipping or Receiving location, when not specifying Transfer number.')
            setLoading(false)
            return;
          }
        }
      }

      //Calling the API
      let response=await StockTransferService.RetrieveMultistopAvailableTransferDetails(transferNumber,transfer_type,originalBuyingpoint,destinationBuyingPoint,frt_scac_id,vendor_num,vehichle_num,null,null)
      let data=response;
      
      if(data!=undefined && data!=null && data!=''){
        let TransformedRowsArray=[];
        let obj={};

        for(let i=0;i<data.length;i++){
          obj.txtcolTransferNum=data[i].transfer_num
          obj.txtcolTransferType=data[i].transfer_type
          obj.txtcolOriginalBuyingPoint=data[i].shp_buy_pt
          obj.txtcolDestinationBuyingPoint=data[i].rec_buy_pt
          obj.txtcolScacID=data[i].frt_scac_id
          obj.txtcolVendorNum=data[i].frt_vendor
          obj.txtcolVehicleNum=data[i].vehicle_num
          obj.txtcolPayInd=data[i].pay_freight_ind
          obj.txtcolLegMileage=data[i].leg_mileage
          obj.txtcolOverride=data[i].frt_mileage_override
          
          TransformedRowsArray.push(obj)
          obj={}
        }
        setValue(thisObj,'gridTransferList',TransformedRowsArray)
      }
      else{
        showMessage(thisObj,'No Transfers Found!!!')
        setValue(thisObj,'gridTransferList',[])
        setLoading(false)
        return
      }

      setLoading(false)
    }
    catch(err){
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Search button Click"
        );
      }
    }
  }
  thisObj.onbtnSearchClick=onbtnSearchClick

  //Select Button Code
  const onbtnSelectClick = ()=>{
    try{
      let frmMultiStopProfileData=[];
      let sNoVehTransferList=''
      let bSelectedRows=false
      let selectedArray=thisObj.state.gridTransferList.selected;

      let selectedCount=getData(thisObj, 'numOfRowsCount')
      let remainingSize=10-selectedCount;

      let newSortedArray=selectedArray.sort((a, b) => {
        return a.rowID$- b.rowID$;
    });
    
      for(let i=0;i<newSortedArray.length;i++){
        if(newSortedArray[i].txtcolVehicleNum==''){
          if(sNoVehTransferList==''){
            sNoVehTransferList=newSortedArray[i].txtcolTransferNum
          }
          else{
            sNoVehTransferList=sNoVehTransferList + ',' + newSortedArray[i].txtcolTransferNum;
          }
        }
        bSelectedRows=true;
      }

      if(sNoVehTransferList!=''){
        showMessage(thisObj,'Transfers must have vehicle numbers:')
        return;
      }

      if(!bSelectedRows){
        showMessage(thisObj,'At least one Transfer must be checkmarked for selection.')
        return;
      }

      for(let i=0;i<newSortedArray.length;i++){
        if(frmMultiStopProfileData.length<(remainingSize)){
          frmMultiStopProfileData.push(newSortedArray[i])
        }
        else{
          alert('Multi-Trips can only contain up to 10 Transfers.')
          setData(thisObj,'frmMultiStopProfileData',frmMultiStopProfileData)
          return
        }
      }

      setData(thisObj,'frmMultiStopProfileData',frmMultiStopProfileData) //Setting the value for Multi Stop Profile Screen
      goTo(thisObj, "StockTransfer#MultiStopProfile#DEFAULT#false#Click")
    }
    catch(err){
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Select button Click"
        );
      }
    }
  }
  thisObj.onbtnSelectClick=onbtnSelectClick

  const ontxtTransferNumChange = () => {
    let transferNumValue = getValue(thisObj, 'txtTransferNum')
    setValue(thisObj, "txtTransferNum", validateTextBox1(transferNumValue))
  };
  thisObj.ontxtTransferNumChange = ontxtTransferNumChange

  //Functio which only takes Character and Numeric values
  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46) || 
      (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90)
       || (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }

  //Function to add the remaining 0 in the Transfer Number textbox if the transfer number length is less than the maximum lenght
  const ontxtTransferNumBlur = async()=>{
    let transfer_num=getValue(thisObj,'txtTransferNum')
    if(transfer_num=='' || transfer_num==undefined || transfer_num==null) return;

    let finalAnswer='';
    let textLength;
    if(transfer_num.length<6){
      textLength=6-(transfer_num.length)
    }
    else{
      textLength=6;
    }
    for(let i=0;i<textLength;i++){
      finalAnswer+='0'
    }
    if(textLength!=6){
    setValue(thisObj,'txtTransferNum',(finalAnswer+transfer_num).toUpperCase())
    }
    else{
      setValue(thisObj,'txtTransferNum',transfer_num.toUpperCase())
    }
  }
  thisObj.ontxtTransferNumBlur=ontxtTransferNumBlur

  let onbtnCloseClick=()=>{
    goTo(thisObj, "StockTransfer#MultiStopProfile#DEFAULT#false#Click")
  }
  thisObj.onbtnCloseClick=onbtnCloseClick
  const setTabIndex = () => {
    let elements = [
      'ddOriginalLocation',
      'ddDestinationLocation',
      'txtTransferNum',
      'ddTransferType',
      'txtScacID',
      'txtVendorNum',
      'txtVehicleNum',
      'btnSearch',
      'btnSelect',
      'btnClose'
    ]
    elements.map((element,index) => {
      let tabelem=document.querySelectorAll(".modal")[2].querySelector(`#${element}`);
      if(tabelem!==null){
        tabelem.tabIndex = index+50;
      }
    })
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_MultiStopSelect*/}

              {/* END_USER_CODE-USER_BEFORE_MultiStopSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxMultiStopSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxMultiStopSelect*/}

              <GroupBoxWidget
                conf={state.grpbxMultiStopSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddOriginalLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOriginalLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOriginalLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOriginalLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddOriginalLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtScacID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtScacID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtScacID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtScacID*/}

                  {/* END_USER_CODE-USER_AFTER_txtScacID*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestinationLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestinationLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTransferNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVehicleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVehicleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVehicleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVehicleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVehicleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddTransferType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddTransferType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddTransferType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddTransferType*/}

                  {/* END_USER_CODE-USER_AFTER_ddTransferType*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTransferList*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTransferList*/}

                <GroupBoxWidget
                  conf={state.grpbxTransferList}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblTransferList*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTransferList*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTransferList}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTransferList*/}

                  {/* END_USER_CODE-USER_AFTER_lblTransferList*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSelect*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSelect*/}

                  <ButtonWidget
                    conf={state.btnSelect}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSelect*/}

                  {/* END_USER_CODE-USER_AFTER_btnSelect*/}
                  {/* START_USER_CODE-USER_BEFORE_gridTransferList*/}

                  {/* END_USER_CODE-USER_BEFORE_gridTransferList*/}

                  <GridWidget
                    conf={state.gridTransferList}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridTransferList}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    refObject={thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridTransferList*/}

                  {/* END_USER_CODE-USER_AFTER_gridTransferList*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTransferList*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTransferList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxMultiStopSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbxMultiStopSelect*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndDntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndDntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndDntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndDntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndDntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_MultiStopSelect*/}

              {/* END_USER_CODE-USER_AFTER_MultiStopSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_MultiStopSelect);
