/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WarehouseReceiptInquiry from "./WarehouseReceiptInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseReceiptInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptInquiry />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseReceiptInquiry Loads Successfully", () => {
    expect(screen.getByText("WarehouseReceiptInquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseReceiptInquiry", () => {
    // START_USER_CODE-USER_WarehouseReceiptInquiry_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseReceiptInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptInquiry />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateWarehouseReceipt(Button Widget) Test Cases", async () => {
    const btnCreateWarehouseReceipt = screen.getByTestId(
      "btnCreateWarehouseReceipt"
    );
    expect(btnCreateWarehouseReceipt).toBeInTheDocument;
    expect(btnCreateWarehouseReceipt.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnCreateWarehouseReceipt")
    );
  });
  test("Custom Test Cases for btnCreateWarehouseReceipt", () => {
    // START_USER_CODE-USER_btnCreateWarehouseReceipt_TEST
    // END_USER_CODE-USER_btnCreateWarehouseReceipt_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnExtRcptForfeiture(Button Widget) Test Cases", async () => {
    const btnExtRcptForfeiture = screen.getByTestId("btnExtRcptForfeiture");
    expect(btnExtRcptForfeiture).toBeInTheDocument;
    expect(btnExtRcptForfeiture.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnExtRcptForfeiture")
    );
  });
  test("Custom Test Cases for btnExtRcptForfeiture", () => {
    // START_USER_CODE-USER_btnExtRcptForfeiture_TEST
    // END_USER_CODE-USER_btnExtRcptForfeiture_TEST
  });
  test("btnNotReady(Button Widget) Test Cases", async () => {
    const btnNotReady = screen.getByTestId("btnNotReady");
    expect(btnNotReady).toBeInTheDocument;
    expect(btnNotReady.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnNotReady")
    );
  });
  test("Custom Test Cases for btnNotReady", () => {
    // START_USER_CODE-USER_btnNotReady_TEST
    // END_USER_CODE-USER_btnNotReady_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrintAllReady(Button Widget) Test Cases", async () => {
    const btnPrintAllReady = screen.getByTestId("btnPrintAllReady");
    expect(btnPrintAllReady).toBeInTheDocument;
    expect(btnPrintAllReady.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnPrintAllReady")
    );
  });
  test("Custom Test Cases for btnPrintAllReady", () => {
    // START_USER_CODE-USER_btnPrintAllReady_TEST
    // END_USER_CODE-USER_btnPrintAllReady_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnReady(Button Widget) Test Cases", async () => {
    const btnReady = screen.getByTestId("btnReady");
    expect(btnReady).toBeInTheDocument;
    expect(btnReady.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnReady")
    );
  });
  test("Custom Test Cases for btnReady", () => {
    // START_USER_CODE-USER_btnReady_TEST
    // END_USER_CODE-USER_btnReady_TEST
  });
  test("btnRedeemByLoan(Button Widget) Test Cases", async () => {
    const btnRedeemByLoan = screen.getByTestId("btnRedeemByLoan");
    expect(btnRedeemByLoan).toBeInTheDocument;
    expect(btnRedeemByLoan.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnRedeemByLoan")
    );
  });
  test("Custom Test Cases for btnRedeemByLoan", () => {
    // START_USER_CODE-USER_btnRedeemByLoan_TEST
    // END_USER_CODE-USER_btnRedeemByLoan_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnUploadLockIn(Button Widget) Test Cases", async () => {
    const btnUploadLockIn = screen.getByTestId("btnUploadLockIn");
    expect(btnUploadLockIn).toBeInTheDocument;
    expect(btnUploadLockIn.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnUploadLockIn")
    );
  });
  test("Custom Test Cases for btnUploadLockIn", () => {
    // START_USER_CODE-USER_btnUploadLockIn_TEST
    // END_USER_CODE-USER_btnUploadLockIn_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("btnViewEWRLog(Button Widget) Test Cases", async () => {
    const btnViewEWRLog = screen.getByTestId("btnViewEWRLog");
    expect(btnViewEWRLog).toBeInTheDocument;
    expect(btnViewEWRLog.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_btnViewEWRLog")
    );
  });
  test("Custom Test Cases for btnViewEWRLog", () => {
    // START_USER_CODE-USER_btnViewEWRLog_TEST
    // END_USER_CODE-USER_btnViewEWRLog_TEST
  });
  test("gridWarehouseReceiptList(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptListbtn =
      gridWarehouseReceiptList.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList =
      gridWarehouseReceiptList.parentElement.parentElement.parentElement;
    expect(gridWarehouseReceiptList.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridWarehouseReceiptList", () => {
    // START_USER_CODE-USER_gridWarehouseReceiptList_TEST
    // END_USER_CODE-USER_gridWarehouseReceiptList_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails1 = screen.getByTestId("grpbxDetails1");
    expect(grpbxDetails1.tagName).toBe("BUTTON");
    expect(grpbxDetails1.type).toBe("button");
    expect(grpbxDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails1", () => {
    // START_USER_CODE-USER_grpbxDetails1_TEST
    // END_USER_CODE-USER_grpbxDetails1_TEST
  });
  test("grpbxDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails2 = screen.getByTestId("grpbxDetails2");
    expect(grpbxDetails2.tagName).toBe("BUTTON");
    expect(grpbxDetails2.type).toBe("button");
    expect(grpbxDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails2", () => {
    // START_USER_CODE-USER_grpbxDetails2_TEST
    // END_USER_CODE-USER_grpbxDetails2_TEST
  });
  test("grpbxDetails3(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails3 = screen.getByTestId("grpbxDetails3");
    expect(grpbxDetails3.tagName).toBe("BUTTON");
    expect(grpbxDetails3.type).toBe("button");
    expect(grpbxDetails3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails3", () => {
    // START_USER_CODE-USER_grpbxDetails3_TEST
    // END_USER_CODE-USER_grpbxDetails3_TEST
  });
  test("grpbxFSADocument(GroupBox Widget) Test Cases", async () => {
    const grpbxFSADocument = screen.getByTestId("grpbxFSADocument");
    expect(grpbxFSADocument.tagName).toBe("BUTTON");
    expect(grpbxFSADocument.type).toBe("button");
    expect(grpbxFSADocument.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFSADocument", () => {
    // START_USER_CODE-USER_grpbxFSADocument_TEST
    // END_USER_CODE-USER_grpbxFSADocument_TEST
  });
  test("grpbxWarehouseReceiptInquiry(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseReceiptInquiry = screen.getByTestId(
      "grpbxWarehouseReceiptInquiry"
    );
    expect(grpbxWarehouseReceiptInquiry.tagName).toBe("BUTTON");
    expect(grpbxWarehouseReceiptInquiry.type).toBe("button");
    expect(grpbxWarehouseReceiptInquiry.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseReceiptInquiry", () => {
    // START_USER_CODE-USER_grpbxWarehouseReceiptInquiry_TEST
    // END_USER_CODE-USER_grpbxWarehouseReceiptInquiry_TEST
  });
  test("grpbxWhsRcptList(GroupBox Widget) Test Cases", async () => {
    const grpbxWhsRcptList = screen.getByTestId("grpbxWhsRcptList");
    expect(grpbxWhsRcptList.tagName).toBe("BUTTON");
    expect(grpbxWhsRcptList.type).toBe("button");
    expect(grpbxWhsRcptList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWhsRcptList", () => {
    // START_USER_CODE-USER_grpbxWhsRcptList_TEST
    // END_USER_CODE-USER_grpbxWhsRcptList_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("lblFSADocument(Label Widget) Test Cases", async () => {
    const lblFSADocument = screen.getByTestId("lblFSADocument");
    expect(lblFSADocument.tagName).toBe("LABEL");
    expect(lblFSADocument.classList).toContain("form-label");
    expect(lblFSADocument.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_lblFSADocument")
    );
  });
  test("Custom Test Cases for lblFSADocument", () => {
    // START_USER_CODE-USER_lblFSADocument_TEST
    // END_USER_CODE-USER_lblFSADocument_TEST
  });
  test("lblWhsRcptList(Label Widget) Test Cases", async () => {
    const lblWhsRcptList = screen.getByTestId("lblWhsRcptList");
    expect(lblWhsRcptList.tagName).toBe("LABEL");
    expect(lblWhsRcptList.classList).toContain("form-label");
    expect(lblWhsRcptList.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_lblWhsRcptList")
    );
  });
  test("Custom Test Cases for lblWhsRcptList", () => {
    // START_USER_CODE-USER_lblWhsRcptList_TEST
    // END_USER_CODE-USER_lblWhsRcptList_TEST
  });
  test("txt1007Num(Textbox Widget) Test Cases", async () => {
    const txt1007Num = screen.getByTestId("txt1007Num");
    expect(txt1007Num.tagName).toBe("INPUT");
    expect(txt1007Num.type).toBe("text");
    expect(txt1007Num.classList).toContain("textboxWidgetClass");
    expect(txt1007Num.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txt1007Num")
    );
    await act(async () => {
      userEvent.type(txt1007Num, "1");
    });
  });
  test("Custom Test Cases for txt1007Num", () => {
    // START_USER_CODE-USER_txt1007Num_TEST
    // END_USER_CODE-USER_txt1007Num_TEST
  });
  test("gridWarehouseReceiptList_txtcol211Concordia(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcol211Concordia = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcol211Concordiabtn =
      gridWarehouseReceiptList_txtcol211Concordia.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcol211Concordia =
      gridWarehouseReceiptList_txtcol211Concordia.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcol211Concordia.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcol211Concordia.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol211Concordia", () => {
    // START_USER_CODE-USER_txtcol211Concordia_TEST
    // END_USER_CODE-USER_txtcol211Concordia_TEST
  });
  test("gridWarehouseReceiptList_txtcol211GPC(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcol211GPC = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcol211GPCbtn =
      gridWarehouseReceiptList_txtcol211GPC.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcol211GPC =
      gridWarehouseReceiptList_txtcol211GPC.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcol211GPC.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcol211GPC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol211GPC", () => {
    // START_USER_CODE-USER_txtcol211GPC_TEST
    // END_USER_CODE-USER_txtcol211GPC_TEST
  });
  test("gridWarehouseReceiptList_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolBuyingPoint = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolBuyingPointbtn =
      gridWarehouseReceiptList_txtcolBuyingPoint.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolBuyingPoint =
      gridWarehouseReceiptList_txtcolBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridWarehouseReceiptList_txtcolContNum(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolContNum = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolContNumbtn =
      gridWarehouseReceiptList_txtcolContNum.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolContNum =
      gridWarehouseReceiptList_txtcolContNum.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolContNum.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolContNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContNum", () => {
    // START_USER_CODE-USER_txtcolContNum_TEST
    // END_USER_CODE-USER_txtcolContNum_TEST
  });
  test("gridWarehouseReceiptList_txtcolDeferred(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolDeferred = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolDeferredbtn =
      gridWarehouseReceiptList_txtcolDeferred.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolDeferred =
      gridWarehouseReceiptList_txtcolDeferred.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolDeferred.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolDeferred.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDeferred", () => {
    // START_USER_CODE-USER_txtcolDeferred_TEST
    // END_USER_CODE-USER_txtcolDeferred_TEST
  });
  test("gridWarehouseReceiptList_txtcolDelvAgreeNum(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolDelvAgreeNum = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolDelvAgreeNumbtn =
      gridWarehouseReceiptList_txtcolDelvAgreeNum.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolDelvAgreeNum =
      gridWarehouseReceiptList_txtcolDelvAgreeNum.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolDelvAgreeNum.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolDelvAgreeNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvAgreeNum", () => {
    // START_USER_CODE-USER_txtcolDelvAgreeNum_TEST
    // END_USER_CODE-USER_txtcolDelvAgreeNum_TEST
  });
  test("gridWarehouseReceiptList_txtcolDelvInd(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolDelvInd = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolDelvIndbtn =
      gridWarehouseReceiptList_txtcolDelvInd.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolDelvInd =
      gridWarehouseReceiptList_txtcolDelvInd.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolDelvInd.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolDelvInd.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvInd", () => {
    // START_USER_CODE-USER_txtcolDelvInd_TEST
    // END_USER_CODE-USER_txtcolDelvInd_TEST
  });
  test("gridWarehouseReceiptList_txtcolEndDate(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolEndDate = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolEndDatebtn =
      gridWarehouseReceiptList_txtcolEndDate.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolEndDate =
      gridWarehouseReceiptList_txtcolEndDate.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolEndDate.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolEndDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEndDate", () => {
    // START_USER_CODE-USER_txtcolEndDate_TEST
    // END_USER_CODE-USER_txtcolEndDate_TEST
  });
  test("gridWarehouseReceiptList_txtcolERcptNum(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolERcptNum = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolERcptNumbtn =
      gridWarehouseReceiptList_txtcolERcptNum.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolERcptNum =
      gridWarehouseReceiptList_txtcolERcptNum.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolERcptNum.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolERcptNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolERcptNum", () => {
    // START_USER_CODE-USER_txtcolERcptNum_TEST
    // END_USER_CODE-USER_txtcolERcptNum_TEST
  });
  test("gridWarehouseReceiptList_txtcolEWRRcptType(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolEWRRcptType = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolEWRRcptTypebtn =
      gridWarehouseReceiptList_txtcolEWRRcptType.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolEWRRcptType =
      gridWarehouseReceiptList_txtcolEWRRcptType.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolEWRRcptType.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolEWRRcptType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEWRRcptType", () => {
    // START_USER_CODE-USER_txtcolEWRRcptType_TEST
    // END_USER_CODE-USER_txtcolEWRRcptType_TEST
  });
  test("gridWarehouseReceiptList_txtcolEWRXferStatus(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolEWRXferStatus = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolEWRXferStatusbtn =
      gridWarehouseReceiptList_txtcolEWRXferStatus.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolEWRXferStatus =
      gridWarehouseReceiptList_txtcolEWRXferStatus.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolEWRXferStatus.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolEWRXferStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEWRXferStatus", () => {
    // START_USER_CODE-USER_txtcolEWRXferStatus_TEST
    // END_USER_CODE-USER_txtcolEWRXferStatus_TEST
  });
  test("gridWarehouseReceiptList_txtcolFarmNo(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolFarmNo = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolFarmNobtn =
      gridWarehouseReceiptList_txtcolFarmNo.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolFarmNo =
      gridWarehouseReceiptList_txtcolFarmNo.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolFarmNo.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolFarmNo.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmNo", () => {
    // START_USER_CODE-USER_txtcolFarmNo_TEST
    // END_USER_CODE-USER_txtcolFarmNo_TEST
  });
  test("gridWarehouseReceiptList_txtcolGen(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolGen = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolGenbtn =
      gridWarehouseReceiptList_txtcolGen.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolGen =
      gridWarehouseReceiptList_txtcolGen.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolGen.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolGen.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGen", () => {
    // START_USER_CODE-USER_txtcolGen_TEST
    // END_USER_CODE-USER_txtcolGen_TEST
  });
  test("gridWarehouseReceiptList_txtcolGroupNum(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolGroupNum = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolGroupNumbtn =
      gridWarehouseReceiptList_txtcolGroupNum.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolGroupNum =
      gridWarehouseReceiptList_txtcolGroupNum.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolGroupNum.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolGroupNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGroupNum", () => {
    // START_USER_CODE-USER_txtcolGroupNum_TEST
    // END_USER_CODE-USER_txtcolGroupNum_TEST
  });
  test("gridWarehouseReceiptList_txtcolHolder(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolHolder = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolHolderbtn =
      gridWarehouseReceiptList_txtcolHolder.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolHolder =
      gridWarehouseReceiptList_txtcolHolder.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolHolder.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolHolder.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHolder", () => {
    // START_USER_CODE-USER_txtcolHolder_TEST
    // END_USER_CODE-USER_txtcolHolder_TEST
  });
  test("gridWarehouseReceiptList_txtcolLastAction(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolLastAction = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolLastActionbtn =
      gridWarehouseReceiptList_txtcolLastAction.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolLastAction =
      gridWarehouseReceiptList_txtcolLastAction.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolLastAction.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolLastAction.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLastAction", () => {
    // START_USER_CODE-USER_txtcolLastAction_TEST
    // END_USER_CODE-USER_txtcolLastAction_TEST
  });
  test("gridWarehouseReceiptList_txtcolLastActionReceived(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolLastActionReceived = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolLastActionReceivedbtn =
      gridWarehouseReceiptList_txtcolLastActionReceived.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolLastActionReceived =
      gridWarehouseReceiptList_txtcolLastActionReceived.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptList_txtcolLastActionReceived.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptList_txtcolLastActionReceived.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLastActionReceived", () => {
    // START_USER_CODE-USER_txtcolLastActionReceived_TEST
    // END_USER_CODE-USER_txtcolLastActionReceived_TEST
  });
  test("gridWarehouseReceiptList_txtcolLoanStatus(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolLoanStatus = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolLoanStatusbtn =
      gridWarehouseReceiptList_txtcolLoanStatus.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolLoanStatus =
      gridWarehouseReceiptList_txtcolLoanStatus.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolLoanStatus.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolLoanStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanStatus", () => {
    // START_USER_CODE-USER_txtcolLoanStatus_TEST
    // END_USER_CODE-USER_txtcolLoanStatus_TEST
  });
  test("gridWarehouseReceiptList_txtcolNetRepay(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolNetRepay = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolNetRepaybtn =
      gridWarehouseReceiptList_txtcolNetRepay.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolNetRepay =
      gridWarehouseReceiptList_txtcolNetRepay.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolNetRepay.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolNetRepay.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetRepay", () => {
    // START_USER_CODE-USER_txtcolNetRepay_TEST
    // END_USER_CODE-USER_txtcolNetRepay_TEST
  });
  test("gridWarehouseReceiptList_txtcolNetWt(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolNetWt = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolNetWtbtn =
      gridWarehouseReceiptList_txtcolNetWt.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolNetWt =
      gridWarehouseReceiptList_txtcolNetWt.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolNetWt.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolNetWt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetWt", () => {
    // START_USER_CODE-USER_txtcolNetWt_TEST
    // END_USER_CODE-USER_txtcolNetWt_TEST
  });
  test("gridWarehouseReceiptList_txtcolOblBin(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolOblBin = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolOblBinbtn =
      gridWarehouseReceiptList_txtcolOblBin.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolOblBin =
      gridWarehouseReceiptList_txtcolOblBin.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolOblBin.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolOblBin.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOblBin", () => {
    // START_USER_CODE-USER_txtcolOblBin_TEST
    // END_USER_CODE-USER_txtcolOblBin_TEST
  });
  test("gridWarehouseReceiptList_txtcolOblPt(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolOblPt = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolOblPtbtn =
      gridWarehouseReceiptList_txtcolOblPt.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolOblPt =
      gridWarehouseReceiptList_txtcolOblPt.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolOblPt.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolOblPt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOblPt", () => {
    // START_USER_CODE-USER_txtcolOblPt_TEST
    // END_USER_CODE-USER_txtcolOblPt_TEST
  });
  test("gridWarehouseReceiptList_txtcolOblWhs(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolOblWhs = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolOblWhsbtn =
      gridWarehouseReceiptList_txtcolOblWhs.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolOblWhs =
      gridWarehouseReceiptList_txtcolOblWhs.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolOblWhs.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolOblWhs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOblWhs", () => {
    // START_USER_CODE-USER_txtcolOblWhs_TEST
    // END_USER_CODE-USER_txtcolOblWhs_TEST
  });
  test("gridWarehouseReceiptList_txtcolOrgBP(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolOrgBP = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolOrgBPbtn =
      gridWarehouseReceiptList_txtcolOrgBP.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolOrgBP =
      gridWarehouseReceiptList_txtcolOrgBP.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolOrgBP.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolOrgBP.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrgBP", () => {
    // START_USER_CODE-USER_txtcolOrgBP_TEST
    // END_USER_CODE-USER_txtcolOrgBP_TEST
  });
  test("gridWarehouseReceiptList_txtcolReady(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolReady = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolReadybtn =
      gridWarehouseReceiptList_txtcolReady.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolReady =
      gridWarehouseReceiptList_txtcolReady.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolReady.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolReady.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReady", () => {
    // START_USER_CODE-USER_txtcolReady_TEST
    // END_USER_CODE-USER_txtcolReady_TEST
  });
  test("gridWarehouseReceiptList_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolSeg = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolSegbtn =
      gridWarehouseReceiptList_txtcolSeg.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolSeg =
      gridWarehouseReceiptList_txtcolSeg.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolSeg.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolSeg.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridWarehouseReceiptList_txtcolShrunkNetWt(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolShrunkNetWt = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolShrunkNetWtbtn =
      gridWarehouseReceiptList_txtcolShrunkNetWt.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolShrunkNetWt =
      gridWarehouseReceiptList_txtcolShrunkNetWt.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolShrunkNetWt.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolShrunkNetWt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShrunkNetWt", () => {
    // START_USER_CODE-USER_txtcolShrunkNetWt_TEST
    // END_USER_CODE-USER_txtcolShrunkNetWt_TEST
  });
  test("gridWarehouseReceiptList_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolStatus = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolStatusbtn =
      gridWarehouseReceiptList_txtcolStatus.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolStatus =
      gridWarehouseReceiptList_txtcolStatus.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolStatus.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridWarehouseReceiptList_txtcolType(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolType = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolTypebtn =
      gridWarehouseReceiptList_txtcolType.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolType =
      gridWarehouseReceiptList_txtcolType.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolType.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType", () => {
    // START_USER_CODE-USER_txtcolType_TEST
    // END_USER_CODE-USER_txtcolType_TEST
  });
  test("gridWarehouseReceiptList_txtcolValue(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolValue = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolValuebtn =
      gridWarehouseReceiptList_txtcolValue.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolValue =
      gridWarehouseReceiptList_txtcolValue.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolValue.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolValue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolValue", () => {
    // START_USER_CODE-USER_txtcolValue_TEST
    // END_USER_CODE-USER_txtcolValue_TEST
  });
  test("gridWarehouseReceiptList_txtcolVariety(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolVariety = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolVarietybtn =
      gridWarehouseReceiptList_txtcolVariety.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolVariety =
      gridWarehouseReceiptList_txtcolVariety.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolVariety.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVariety", () => {
    // START_USER_CODE-USER_txtcolVariety_TEST
    // END_USER_CODE-USER_txtcolVariety_TEST
  });
  test("gridWarehouseReceiptList_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolVendor = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolVendorbtn =
      gridWarehouseReceiptList_txtcolVendor.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolVendor =
      gridWarehouseReceiptList_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolVendor.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridWarehouseReceiptList_txtcolWireXferData(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolWireXferData = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolWireXferDatabtn =
      gridWarehouseReceiptList_txtcolWireXferData.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptList_txtcolWireXferData =
      gridWarehouseReceiptList_txtcolWireXferData.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolWireXferData.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolWireXferData.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWireXferData", () => {
    // START_USER_CODE-USER_txtcolWireXferData_TEST
    // END_USER_CODE-USER_txtcolWireXferData_TEST
  });
  test("gridWarehouseReceiptList_txtcolWRNum(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptList_txtcolWRNum = screen.getByTestId(
      "gridWarehouseReceiptList"
    );
    let gridWarehouseReceiptList_txtcolWRNumbtn =
      gridWarehouseReceiptList_txtcolWRNum.nextElementSibling.firstElementChild;
    gridWarehouseReceiptList_txtcolWRNum =
      gridWarehouseReceiptList_txtcolWRNum.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptList_txtcolWRNum.tagName).toBe("DIV");
    expect(gridWarehouseReceiptList_txtcolWRNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptInquiry_gridWarehouseReceiptList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWRNum", () => {
    // START_USER_CODE-USER_txtcolWRNum_TEST
    // END_USER_CODE-USER_txtcolWRNum_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtEndDateFrom(Date Widget) Test Cases", async () => {
    const txtEndDateFrom = screen.getByTestId("txtEndDateFrom");
    expect(txtEndDateFrom.tagName).toBe("INPUT");
    expect(txtEndDateFrom.type).toBe("text");
    expect(txtEndDateFrom.classList).toContain("datetimepicker-input");
    expect(
      txtEndDateFrom.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:WarehouseReceiptInquiry_txtEndDateFrom"));
    await act(async () => {
      userEvent.click(txtEndDateFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtEndDateFrom", () => {
    // START_USER_CODE-USER_txtEndDateFrom_TEST
    // END_USER_CODE-USER_txtEndDateFrom_TEST
  });
  test("txtEndDateTo(Date Widget) Test Cases", async () => {
    const txtEndDateTo = screen.getByTestId("txtEndDateTo");
    expect(txtEndDateTo.tagName).toBe("INPUT");
    expect(txtEndDateTo.type).toBe("text");
    expect(txtEndDateTo.classList).toContain("datetimepicker-input");
    expect(
      txtEndDateTo.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:WarehouseReceiptInquiry_txtEndDateTo"));
    await act(async () => {
      userEvent.click(txtEndDateTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtEndDateTo", () => {
    // START_USER_CODE-USER_txtEndDateTo_TEST
    // END_USER_CODE-USER_txtEndDateTo_TEST
  });
  test("txtGroupNum(Textbox Widget) Test Cases", async () => {
    const txtGroupNum = screen.getByTestId("txtGroupNum");
    expect(txtGroupNum.tagName).toBe("INPUT");
    expect(txtGroupNum.type).toBe("text");
    expect(txtGroupNum.classList).toContain("textboxWidgetClass");
    expect(txtGroupNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtGroupNum")
    );
    await act(async () => {
      userEvent.type(txtGroupNum, "1");
    });
  });
  test("Custom Test Cases for txtGroupNum", () => {
    // START_USER_CODE-USER_txtGroupNum_TEST
    // END_USER_CODE-USER_txtGroupNum_TEST
  });
  test("txtLoanNum1(Textbox Widget) Test Cases", async () => {
    const txtLoanNum1 = screen.getByTestId("txtLoanNum1");
    expect(txtLoanNum1.tagName).toBe("INPUT");
    expect(txtLoanNum1.type).toBe("text");
    expect(txtLoanNum1.classList).toContain("textboxWidgetClass");
    expect(txtLoanNum1.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtLoanNum1")
    );
    await act(async () => {
      userEvent.type(txtLoanNum1, "1");
    });
  });
  test("Custom Test Cases for txtLoanNum1", () => {
    // START_USER_CODE-USER_txtLoanNum1_TEST
    // END_USER_CODE-USER_txtLoanNum1_TEST
  });
  test("txtLoanNum2(Textbox Widget) Test Cases", async () => {
    const txtLoanNum2 = screen.getByTestId("txtLoanNum2");
    expect(txtLoanNum2.tagName).toBe("INPUT");
    expect(txtLoanNum2.type).toBe("text");
    expect(txtLoanNum2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLoanNum2, "1");
    });
  });
  test("Custom Test Cases for txtLoanNum2", () => {
    // START_USER_CODE-USER_txtLoanNum2_TEST
    // END_USER_CODE-USER_txtLoanNum2_TEST
  });
  test("txtLoanNum3(Textbox Widget) Test Cases", async () => {
    const txtLoanNum3 = screen.getByTestId("txtLoanNum3");
    expect(txtLoanNum3.tagName).toBe("INPUT");
    expect(txtLoanNum3.type).toBe("text");
    expect(txtLoanNum3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLoanNum3, "1");
    });
  });
  test("Custom Test Cases for txtLoanNum3", () => {
    // START_USER_CODE-USER_txtLoanNum3_TEST
    // END_USER_CODE-USER_txtLoanNum3_TEST
  });
  test("txtLockInDateFrom(Date Widget) Test Cases", async () => {
    const txtLockInDateFrom = screen.getByTestId("txtLockInDateFrom");
    expect(txtLockInDateFrom.tagName).toBe("INPUT");
    expect(txtLockInDateFrom.type).toBe("text");
    expect(txtLockInDateFrom.classList).toContain("datetimepicker-input");
    expect(
      txtLockInDateFrom.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:WarehouseReceiptInquiry_txtLockInDateFrom"));
    await act(async () => {
      userEvent.click(txtLockInDateFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtLockInDateFrom", () => {
    // START_USER_CODE-USER_txtLockInDateFrom_TEST
    // END_USER_CODE-USER_txtLockInDateFrom_TEST
  });
  test("txtLockInDateTo(Date Widget) Test Cases", async () => {
    const txtLockInDateTo = screen.getByTestId("txtLockInDateTo");
    expect(txtLockInDateTo.tagName).toBe("INPUT");
    expect(txtLockInDateTo.type).toBe("text");
    expect(txtLockInDateTo.classList).toContain("datetimepicker-input");
    expect(
      txtLockInDateTo.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:WarehouseReceiptInquiry_txtLockInDateTo"));
    await act(async () => {
      userEvent.click(txtLockInDateTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtLockInDateTo", () => {
    // START_USER_CODE-USER_txtLockInDateTo_TEST
    // END_USER_CODE-USER_txtLockInDateTo_TEST
  });
  test("txtMaturityDateFrom(Date Widget) Test Cases", async () => {
    const txtMaturityDateFrom = screen.getByTestId("txtMaturityDateFrom");
    expect(txtMaturityDateFrom.tagName).toBe("INPUT");
    expect(txtMaturityDateFrom.type).toBe("text");
    expect(txtMaturityDateFrom.classList).toContain("datetimepicker-input");
    expect(
      txtMaturityDateFrom.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtMaturityDateFrom")
    );
    await act(async () => {
      userEvent.click(txtMaturityDateFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtMaturityDateFrom", () => {
    // START_USER_CODE-USER_txtMaturityDateFrom_TEST
    // END_USER_CODE-USER_txtMaturityDateFrom_TEST
  });
  test("txtMaturityDateTo(Date Widget) Test Cases", async () => {
    const txtMaturityDateTo = screen.getByTestId("txtMaturityDateTo");
    expect(txtMaturityDateTo.tagName).toBe("INPUT");
    expect(txtMaturityDateTo.type).toBe("text");
    expect(txtMaturityDateTo.classList).toContain("datetimepicker-input");
    expect(
      txtMaturityDateTo.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:WarehouseReceiptInquiry_txtMaturityDateTo"));
    await act(async () => {
      userEvent.click(txtMaturityDateTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtMaturityDateTo", () => {
    // START_USER_CODE-USER_txtMaturityDateTo_TEST
    // END_USER_CODE-USER_txtMaturityDateTo_TEST
  });
  test("txtOblBinNum(Textbox Widget) Test Cases", async () => {
    const txtOblBinNum = screen.getByTestId("txtOblBinNum");
    expect(txtOblBinNum.tagName).toBe("INPUT");
    expect(txtOblBinNum.type).toBe("text");
    expect(txtOblBinNum.classList).toContain("textboxWidgetClass");
    expect(txtOblBinNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtOblBinNum")
    );
    await act(async () => {
      userEvent.type(txtOblBinNum, "1");
    });
  });
  test("Custom Test Cases for txtOblBinNum", () => {
    // START_USER_CODE-USER_txtOblBinNum_TEST
    // END_USER_CODE-USER_txtOblBinNum_TEST
  });
  test("txtOblWhseNum(Textbox Widget) Test Cases", async () => {
    const txtOblWhseNum = screen.getByTestId("txtOblWhseNum");
    expect(txtOblWhseNum.tagName).toBe("INPUT");
    expect(txtOblWhseNum.type).toBe("text");
    expect(txtOblWhseNum.classList).toContain("textboxWidgetClass");
    expect(txtOblWhseNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtOblWhseNum")
    );
    await act(async () => {
      userEvent.type(txtOblWhseNum, "1");
    });
  });
  test("Custom Test Cases for txtOblWhseNum", () => {
    // START_USER_CODE-USER_txtOblWhseNum_TEST
    // END_USER_CODE-USER_txtOblWhseNum_TEST
  });
  test("txtReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtReceiptNum = screen.getByTestId("txtReceiptNum");
    expect(txtReceiptNum.tagName).toBe("INPUT");
    expect(txtReceiptNum.type).toBe("text");
    expect(txtReceiptNum.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtReceiptNum")
    );
    await act(async () => {
      userEvent.type(txtReceiptNum, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNum", () => {
    // START_USER_CODE-USER_txtReceiptNum_TEST
    // END_USER_CODE-USER_txtReceiptNum_TEST
  });
  test("txtRedemptionDateFrom(Date Widget) Test Cases", async () => {
    const txtRedemptionDateFrom = screen.getByTestId("txtRedemptionDateFrom");
    expect(txtRedemptionDateFrom.tagName).toBe("INPUT");
    expect(txtRedemptionDateFrom.type).toBe("text");
    expect(txtRedemptionDateFrom.classList).toContain("datetimepicker-input");
    expect(
      txtRedemptionDateFrom.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtRedemptionDateFrom")
    );
    await act(async () => {
      userEvent.click(txtRedemptionDateFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtRedemptionDateFrom", () => {
    // START_USER_CODE-USER_txtRedemptionDateFrom_TEST
    // END_USER_CODE-USER_txtRedemptionDateFrom_TEST
  });
  test("txtRedemptionDateTo(Date Widget) Test Cases", async () => {
    const txtRedemptionDateTo = screen.getByTestId("txtRedemptionDateTo");
    expect(txtRedemptionDateTo.tagName).toBe("INPUT");
    expect(txtRedemptionDateTo.type).toBe("text");
    expect(txtRedemptionDateTo.classList).toContain("datetimepicker-input");
    expect(
      txtRedemptionDateTo.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtRedemptionDateTo")
    );
    await act(async () => {
      userEvent.click(txtRedemptionDateTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtRedemptionDateTo", () => {
    // START_USER_CODE-USER_txtRedemptionDateTo_TEST
    // END_USER_CODE-USER_txtRedemptionDateTo_TEST
  });
  test("txtTotalNetRepay(Textbox Widget) Test Cases", async () => {
    const txtTotalNetRepay = screen.getByTestId("txtTotalNetRepay");
    expect(txtTotalNetRepay.tagName).toBe("INPUT");
    expect(txtTotalNetRepay.type).toBe("text");
    expect(txtTotalNetRepay.classList).toContain("textboxWidgetClass");
    expect(txtTotalNetRepay.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtTotalNetRepay")
    );
    await act(async () => {
      userEvent.type(txtTotalNetRepay, "123");
    });
    expect(txtTotalNetRepay.getAttribute("value")).toBe("");
    expect(txtTotalNetRepay.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalNetRepay", () => {
    // START_USER_CODE-USER_txtTotalNetRepay_TEST
    // END_USER_CODE-USER_txtTotalNetRepay_TEST
  });
  test("txtTotalNetWt(Textbox Widget) Test Cases", async () => {
    const txtTotalNetWt = screen.getByTestId("txtTotalNetWt");
    expect(txtTotalNetWt.tagName).toBe("INPUT");
    expect(txtTotalNetWt.type).toBe("text");
    expect(txtTotalNetWt.classList).toContain("textboxWidgetClass");
    expect(txtTotalNetWt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtTotalNetWt")
    );
    await act(async () => {
      userEvent.type(txtTotalNetWt, "123");
    });
    expect(txtTotalNetWt.getAttribute("value")).toBe("");
    expect(txtTotalNetWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalNetWt", () => {
    // START_USER_CODE-USER_txtTotalNetWt_TEST
    // END_USER_CODE-USER_txtTotalNetWt_TEST
  });
  test("txtTotalShrunkNetWt(Textbox Widget) Test Cases", async () => {
    const txtTotalShrunkNetWt = screen.getByTestId("txtTotalShrunkNetWt");
    expect(txtTotalShrunkNetWt.tagName).toBe("INPUT");
    expect(txtTotalShrunkNetWt.type).toBe("text");
    expect(txtTotalShrunkNetWt.classList).toContain("textboxWidgetClass");
    expect(txtTotalShrunkNetWt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtTotalShrunkNetWt")
    );
    await act(async () => {
      userEvent.type(txtTotalShrunkNetWt, "123");
    });
    expect(txtTotalShrunkNetWt.getAttribute("value")).toBe("");
    expect(txtTotalShrunkNetWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalShrunkNetWt", () => {
    // START_USER_CODE-USER_txtTotalShrunkNetWt_TEST
    // END_USER_CODE-USER_txtTotalShrunkNetWt_TEST
  });
  test("txtTotalValue(Textbox Widget) Test Cases", async () => {
    const txtTotalValue = screen.getByTestId("txtTotalValue");
    expect(txtTotalValue.tagName).toBe("INPUT");
    expect(txtTotalValue.type).toBe("text");
    expect(txtTotalValue.classList).toContain("textboxWidgetClass");
    expect(txtTotalValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtTotalValue")
    );
    await act(async () => {
      userEvent.type(txtTotalValue, "123");
    });
    expect(txtTotalValue.getAttribute("value")).toBe("");
    expect(txtTotalValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalValue", () => {
    // START_USER_CODE-USER_txtTotalValue_TEST
    // END_USER_CODE-USER_txtTotalValue_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiry_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendr(Textbox Widget) Test Cases", async () => {
    const txtVendr = screen.getByTestId("txtVendr");
    expect(txtVendr.tagName).toBe("INPUT");
    expect(txtVendr.type).toBe("text");
    expect(txtVendr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendr, "123");
    });
    expect(txtVendr.getAttribute("value")).toBe("");
    expect(txtVendr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendr", () => {
    // START_USER_CODE-USER_txtVendr_TEST
    // END_USER_CODE-USER_txtVendr_TEST
  });
});
