import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip} from "../ParentWidgetFunc";
import './AutocompleteWidget.scss';
function AutocompleteWidget(props){
  let conf = props.conf;
  let screenConf = props.screenConf;
  let inputProps = {};

 const getPlaceholder=()=> {
    return conf.PlaceholderForAutoComplete
      ? conf.PlaceholderForAutoComplete
      : "";
  }

    if (!screenConf.horizontalForm) {
      inputProps.size = "sm";
    }

    if (conf.filterBy) {
      inputProps.filterBy = conf.filterBy;
    }

    if (
      conf.valueListForAutocomplete &&
      typeof conf.valueListForAutocomplete === "object"
    ) {
      conf.datasource = conf.valueListForAutocomplete;
    } else if (typeof conf.valueListForAutocomplete === "string") {
      var valueList = [];
      if (conf.valueListForAutocomplete.indexOf(",") !== -1) {
        valueList = conf.valueListForAutocomplete.split(",").map(term => {
          return term.trim();
        });
      } else {
        valueList.push(conf.valueListForAutocomplete);
      }
      conf.datasource = valueList;
    }

    if (!conf.datasource) {
      conf.datasource = [""];
    }

    let autocomplete = (
      <React.Fragment>
        <Typeahead
        // clearButton = {true}
        defaultOpen = {false}
          multiple={conf.multiple ? conf.multiple : false}
          placeholder={getPlaceholder(conf)}
          emptyLabel={
            conf.emptyLabel ? conf.emptyLabel : "No matches found"
          }
          {...inputProps}
          disabled={!conf.Enabled}
          id={conf.name}
          onChange={selected => {
            let event = {
              type: "change",
              target: {
                id: conf.name,
                name: conf.name,
                value: selected
              }
            };
            props.onChange(event);
          }}
          onBlur={props.onBlur}
          labelKey={conf.labelKey}
          options={conf.datasource}
          inputProps={{ required: conf.Mandatory,id: conf.name, name: conf.name, "aria-live": "polite" }}
          selected={
            props.values[conf.name]
              ? props.values[conf.name]
              : []
          }
          isInvalid={checkInvalid(props,conf) ? true : false}
        />
        <Form.Control.Feedback
          className={checkInvalid(props,conf) ? "d-block" : "d-none"}
          type="invalid"
          aria-live="polite" role="alert"
        >
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        data-testid={conf.name}
		    title={getTooltip(conf)}
		    role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>
            < div className="icon-container">
              {autocomplete}
               {conf.caretIcon && <i className="fa fa-caret-down" onClick= {e => {
               e.target.parentNode.children[0].children[0].children[0].click()
              }}></i>}
            </div>
            </Col>
          ) : (
            <React.Fragment>
              <div className="icon-container"> 
                
                {autocomplete}
                <i className="fa fa-caret-down"></i>
              </div>
              </React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default AutocompleteWidget;
