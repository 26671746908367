import React,{ useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./GroupBoxWidget.scss";
import {getPanelClasses,getWidgetLabel} from "../ParentWidgetFunc";

function GroupBoxWidget(props) {
  //state = { open: !this.conf.defaultCollapsed };
  let conf = props.conf;
  let screenConf = props.screenConf;
  const [state, setState] = useState({ open: !props.conf.defaultCollapsed });
    const toggleClasses = ["pr-1", "fa"];
    state.open
      ? toggleClasses.push("fa-caret-down")
      : toggleClasses.push("fa-caret-right");

    let gbTemplate = (
      <div className={getPanelClasses(conf,screenConf).join(" ")}>
        <Card>
          {!conf.hideHeader && (
            <Card.Header>
              <Button
                className="text-decoration-none p-0"
                variant="link"
                onClick={() => setState({ open: !state.open })}
                aria-controls={conf.name + "Collapse"}
                aria-expanded={state.open}
                aria-label={state.open ? "Please click here to collapse" : "Please click here to expand"}
                data-testid={conf.name}
              >
                <i className={toggleClasses.join(" ")} aria-hidden="true"></i>
                {conf.HasLabel ? getWidgetLabel(conf) : ""}
              </Button>
            </Card.Header>
          )}
          <Collapse in={state.open}>
            <Card.Body>
              <Row>{props.children}</Row>
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    );

    if (conf.hideContainer) {
      gbTemplate = (
        <div className={getPanelClasses(conf,screenConf).join(" ")}>
          <div className="row">{props.children}</div>
        </div>
      );
    }

    return gbTemplate;
  
}

export default GroupBoxWidget;
