/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_OpenAdjustmentEntry from "./OpenAdjustmentEntry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("OpenAdjustmentEntry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAdjustmentEntry />
      );
    });
  });
  afterEach(cleanup);
  test("is OpenAdjustmentEntry Loads Successfully", () => {
    expect(screen.getByText("OpenAdjustmentEntry")).toBeInTheDocument;
  });
  test("Custom Test Cases for OpenAdjustmentEntry", () => {
    // START_USER_CODE-USER_OpenAdjustmentEntry_Custom_Test_Case
    // END_USER_CODE-USER_OpenAdjustmentEntry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAdjustmentEntry />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAdjustmentEntry_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAdjustmentEntry_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAdjustmentEntry_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("colAdjustmentAmount(Textbox Widget) Test Cases", async () => {
    const colAdjustmentAmount = screen.getByTestId(
      "colAdjustmentAmount"
    );
    expect(colAdjustmentAmount.tagName).toBe("INPUT");
    expect(colAdjustmentAmount.type).toBe("text");
    expect(colAdjustmentAmount.classList).toContain("textboxWidgetClass");
    expect(colAdjustmentAmount.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAdjustmentEntry_colAdjustmentAmount"
      )
    );
    await act(async () => {
      userEvent.type(colAdjustmentAmount, "1");
    });
  });
  test("Custom Test Cases for colAdjustmentAmount", () => {
    // START_USER_CODE-USER_colAdjustmentAmount_TEST
    // END_USER_CODE-USER_colAdjustmentAmount_TEST
  });
  test("grpbxOpenAdjustmentEntry(GroupBox Widget) Test Cases", async () => {
    const grpbxOpenAdjustmentEntry = screen.getByTestId(
      "grpbxOpenAdjustmentEntry"
    );
    expect(grpbxOpenAdjustmentEntry.tagName).toBe("BUTTON");
    expect(grpbxOpenAdjustmentEntry.type).toBe("button");
    expect(grpbxOpenAdjustmentEntry.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxOpenAdjustmentEntry", () => {
    // START_USER_CODE-USER_grpbxOpenAdjustmentEntry_TEST
    // END_USER_CODE-USER_grpbxOpenAdjustmentEntry_TEST
  });
  test("txtEffectiveDate(Textbox Widget) Test Cases", async () => {
    const txtEffectiveDate = screen.getByTestId("txtEffectiveDate");
    expect(txtEffectiveDate.tagName).toBe("INPUT");
    expect(txtEffectiveDate.type).toBe("text");
    expect(txtEffectiveDate.classList).toContain("textboxWidgetClass");
    expect(txtEffectiveDate.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAdjustmentEntry_txtEffectiveDate"
      )
    );
    await act(async () => {
      userEvent.type(txtEffectiveDate, "1");
    });
  });
  test("Custom Test Cases for txtEffectiveDate", () => {
    // START_USER_CODE-USER_txtEffectiveDate_TEST
    // END_USER_CODE-USER_txtEffectiveDate_TEST
  });
});
