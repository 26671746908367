/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  RadioButtonGroupWidget,
  DateWidget,
  setValue,
  getValue,
  disable,
  hide,
  show,
  getData,
  setData,
  enable,
  goTo,
  hideColumn,
  showColumn,
} from "../../shared/WindowImports";

import "./VendorWireTransferSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService"
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_VendorWireTransferSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VendorWireTransferSetup",
    windowName: "VendorWireTransferSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.VendorWireTransferSetup",
    // START_USER_CODE-USER_VendorWireTransferSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Wire Transfer Information",
      scrCode: "PN0204A",
    },
    // END_USER_CODE-USER_VendorWireTransferSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxVendorWireTransferSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnAdd1: {
      name: "btnAdd1",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd1_PROPERTIES

      // END_USER_CODE-USER_btnAdd1_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxVendorWireTransferSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxVendorWireTransferSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    chkboxUseIMBank: {
      name: "chkboxUseIMBank",
      type: "CheckBoxWidget",
      parent: "grpbxIntermediaryBank",
      Label: "Use an Intermediary Bank for Wire Transfers",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxUseIMBank_PROPERTIES

      // END_USER_CODE-USER_chkboxUseIMBank_PROPERTIES
    },
    ddBankState: {
      name: "ddBankState",
      type: "DropDownFieldWidget",
      parent: "grpbxWireTransferInfo",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBankState_PROPERTIES

      // END_USER_CODE-USER_ddBankState_PROPERTIES
    },
    ddIMBankState: {
      name: "ddIMBankState",
      type: "DropDownFieldWidget",
      parent: "grpbxIntermediaryBank",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddIMBankState_PROPERTIES

      // END_USER_CODE-USER_ddIMBankState_PROPERTIES
    },
    ddPayState: {
      name: "ddPayState",
      type: "DropDownFieldWidget",
      parent: "grpbxWireTransferInfo",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPayState_PROPERTIES

      // END_USER_CODE-USER_ddPayState_PROPERTIES
    },
    ddVendors: {
      name: "ddVendors",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorWireTransferSetup",
      Label: "Vendors:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendors_PROPERTIES

      // END_USER_CODE-USER_ddVendors_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransferSetup",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransferSetup",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransferSetup",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransferSetup",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "TextBoxWidget",
      parent: "grpbxVendorWireTransferSetup",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor5_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtVendor5_PROPERTIES
    },
    grpbxIntermediaryBank: {
      name: "grpbxIntermediaryBank",
      type: "GroupBoxWidget",
      parent: "grpbxWireTransferInfo",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxIntermediaryBank_PROPERTIES

      // END_USER_CODE-USER_grpbxIntermediaryBank_PROPERTIES
    },
    grpbxWireTransferInfo: {
      name: "grpbxWireTransferInfo",
      type: "GroupBoxWidget",
      parent: "grpbxVendorWireTransferSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWireTransferInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxWireTransferInfo_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblWireTransferInformation: {
      name: "lblWireTransferInformation",
      type: "LabelWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Wire Transfer Information",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireTransferInformation_PROPERTIES

      // END_USER_CODE-USER_lblWireTransferInformation_PROPERTIES
    },
    txtBankABANum: {
      name: "txtBankABANum",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Bank ABA#:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankABANum_PROPERTIES

      // END_USER_CODE-USER_txtBankABANum_PROPERTIES
    },
    txtBankAccountNum: {
      name: "txtBankAccountNum",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Bank Account#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 34 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankAccountNum_PROPERTIES

      // END_USER_CODE-USER_txtBankAccountNum_PROPERTIES
    },
    txtBankAddress: {
      name: "txtBankAddress",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Address:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankAddress_PROPERTIES

      // END_USER_CODE-USER_txtBankAddress_PROPERTIES
    },
    txtBankCity: {
      name: "txtBankCity",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "City:",
      LengthRange: { MinLength: 0, MaxLength: 25 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankCity_PROPERTIES

      // END_USER_CODE-USER_txtBankCity_PROPERTIES
    },
    txtBankZip: {
      name: "txtBankZip",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Zip:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBankZip_PROPERTIES

      // END_USER_CODE-USER_txtBankZip_PROPERTIES
    },
    txtEmail: {
      name: "txtEmail",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "E-mail:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail_PROPERTIES

      // END_USER_CODE-USER_txtEmail_PROPERTIES
    },
    txtFAXContact: {
      name: "txtFAXContact",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "FAX Contact:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 60 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFAXContact_PROPERTIES

      // END_USER_CODE-USER_txtFAXContact_PROPERTIES
    },
    txtFAXNum: {
      name: "txtFAXNum",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Fax #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFAXNum_PROPERTIES

      // END_USER_CODE-USER_txtFAXNum_PROPERTIES
    },
    txtIMBank: {
      name: "txtIMBank",
      type: "TextBoxWidget",
      parent: "grpbxIntermediaryBank",
      Label: "Bank:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIMBank_PROPERTIES

      // END_USER_CODE-USER_txtIMBank_PROPERTIES
    },
    txtIMBankABANum: {
      name: "txtIMBankABANum",
      type: "TextBoxWidget",
      parent: "grpbxIntermediaryBank",
      Label: "Bank ABA#:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIMBankABANum_PROPERTIES

      // END_USER_CODE-USER_txtIMBankABANum_PROPERTIES
    },
    txtIMBankAddress: {
      name: "txtIMBankAddress",
      type: "TextBoxWidget",
      parent: "grpbxIntermediaryBank",
      Label: "Address:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIMBankAddress_PROPERTIES

      // END_USER_CODE-USER_txtIMBankAddress_PROPERTIES
    },
    txtIMBankCity: {
      name: "txtIMBankCity",
      type: "TextBoxWidget",
      parent: "grpbxIntermediaryBank",
      Label: "City:",
      LengthRange: { MinLength: 0, MaxLength: 25 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIMBankCity_PROPERTIES

      // END_USER_CODE-USER_txtIMBankCity_PROPERTIES
    },
    txtIMBankZip: {
      name: "txtIMBankZip",
      type: "TextBoxWidget",
      parent: "grpbxIntermediaryBank",
      Label: "Zip:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIMBankZip_PROPERTIES

      // END_USER_CODE-USER_txtIMBankZip_PROPERTIES
    },
    txtPayAddress1: {
      name: "txtPayAddress1",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Address:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayAddress1_PROPERTIES

      // END_USER_CODE-USER_txtPayAddress1_PROPERTIES
    },
    txtPayAddress2: {
      name: "txtPayAddress2",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      ColSpan: "3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayAddress2_PROPERTIES

      // END_USER_CODE-USER_txtPayAddress2_PROPERTIES
    },
    txtPayCity: {
      name: "txtPayCity",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "City:",
      LengthRange: { MinLength: 0, MaxLength: 25 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayCity_PROPERTIES

      // END_USER_CODE-USER_txtPayCity_PROPERTIES
    },
    txtPayeeBank: {
      name: "txtPayeeBank",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Payee Bank:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayeeBank_PROPERTIES

      // END_USER_CODE-USER_txtPayeeBank_PROPERTIES
    },
    txtPayeeName: {
      name: "txtPayeeName",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Payee Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayeeName_PROPERTIES

      // END_USER_CODE-USER_txtPayeeName_PROPERTIES
    },
    txtPayZip: {
      name: "txtPayZip",
      type: "TextBoxWidget",
      parent: "grpbxWireTransferInfo",
      Label: "Zip:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayZip_PROPERTIES

      // END_USER_CODE-USER_txtPayZip_PROPERTIES
    },
    grpbxVendorWireTransferSetup: {
      name: "grpbxVendorWireTransferSetup",
      type: "GroupBoxWidget",
      parent: "VendorWireTransferSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxVendorWireTransferSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorWireTransferSetup_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "VendorWireTransferSetup",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#VendorWireTransfer":{},
       },
      REVERSE:{
        "SystemMaintenanceSpecialFunctions#VendorWireTransfer":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd:{
    //   DEFAULT:[
    //   "SystemMaintenanceSpecialFunctions#VendorWireTransfer#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfVendorWireTransfer") == true) {
        let vendorslist = thisObj.state['ddVendors'].valueList;
        let objVendor = getData(thisObj, "VendorSelectDetails");
        if (objVendor != null & objVendor != undefined){
          let vendorNum = objVendor.msVendorNumber;
          let vendorName = objVendor.msVendorName;
          let vendorexisted = false
          for (var i = 0; i < vendorslist.length; i++){
            if (vendorNum == vendorslist[i].key){
              showMessage(thisObj, "Wire Transfer Control Vendor already exists.");
              setValue(thisObj, 'ddVendors', vendorslist[i].key);
              setData(thisObj,'callingfromAddVendor',vendorslist[i].key);
              onddVendorsChange();
              vendorexisted = true
            }
          }
          if (vendorexisted == false){
            let obj = {
              key: vendorNum,
              description: vendorNum + ' - ' + vendorName
            }
            vendorslist.push(obj);
            setValue(thisObj, 'ddVendors', vendorslist[vendorslist.length - 1].key);
            enableDisableControls('Add');
  
            ContractManagementService.RetrieveVendorByNumber(vendorNum).then(response => {
              let data = response;
              if (data !== null && data !== undefined && data.length > 0) {
                setValue(thisObj, 'txtVendor1', data[0].addr1);
                setValue(thisObj, 'txtVendor2', data[0].addr2);
                setValue(thisObj, 'txtVendor3', data[0].addr3);
                setValue(thisObj, 'txtVendor4', data[0].addr4);
                let vendor5 = data[0].city;
                if (data[0].state != ''){
                  vendor5 = vendor5 + ', ' + data[0].state;
                }
                if (data[0].zip != ''){
                  vendor5 = vendor5 + ' ' + data[0].zip;
                }
                setValue(thisObj, 'txtVendor5', vendor5);

                if(data[0].addr1 != ''){
                  show(thisObj, 'txtVendor1');
                } else {
                  hide(thisObj, 'txtVendor1');
                }
                if(data[0].addr2 != ''){
                  show(thisObj, 'txtVendor2');
                } else {
                  hide(thisObj, 'txtVendor2');
                }
                if(data[0].addr3 != ''){
                  show(thisObj, 'txtVendor3');
                } else {
                  hide(thisObj, 'txtVendor3');
                }
                if(data[0].addr4 != ''){
                  show(thisObj, 'txtVendor4');
                } else {
                  hide(thisObj, 'txtVendor4');
                }
                if(vendor5 != ''){
                  show(thisObj, 'txtVendor5');
                } else {
                  hide(thisObj, 'txtVendor5');
                }
              }
            });
          }
        } else {
          showMessage(thisObj, "No Vendor was entered.");
        }
      }
      setData(thisObj, "closingOfVendorWireTransfer", false);
    } catch (error) {
      showMessage(thisObj, "error on functions after Vendor Wire Transfer closing");
      setData(thisObj, "closingOfVendorWireTransfer", false);
    }
  }, [getData(thisObj, "closingOfVendorWireTransfer")]);
  // START_USER_CODE-USER_METHODS

  const CompId = (sessionStorage.getItem('compId'));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));
  const companyName = (sessionStorage.getItem('Companyname'));
  const UserId = (sessionStorage.getItem('userid'));
  const [loading, setLoading] = useState(false);

  function formLoad() {
    try {
      accessPermissions();
      bFillStateList();
      bFillVendorList();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const accessPermissions = () => {
    try {
      ContractManagementService.RetrieveAccessPermissionDetails('PN0204', null, 'D').then(response => {
        if (response[0].permission == 'Y') {
          setData(thisObj, 'accessFor', 'Delete');
        } else {
          ContractManagementService.RetrieveAccessPermissionDetails('PN0204', null, 'U').then(response => {
            if (response[0].permission == 'Y') {
              setData(thisObj, 'accessFor', 'Update');
            } else {
              ContractManagementService.RetrieveAccessPermissionDetails('PN0204', null, 'I').then(response => {
                if (response[0].permission == 'Y') {
                  setData(thisObj, 'accessFor', 'Inquiry');
                }
              })
            }
          })
        }
        enableDisableControls('Inquiry');
      })
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  //Implement functionality to bind state dropdown 
  const bFillStateList = async () => {
    try {
      let jsState = [];
      jsState.push({ key: '', description: '>>> Select <<<' });
      await SystemMaintenanceSpecialFunctionsService.RetrieveStateControlDetails(null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].state_abbr.toString(),
            description: data[i].state_name.toString(),
            stateId: data[i].state_id,
            index: i
          }
          jsState.push(obj);
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddPayState: {
              ...current["ddPayState"],
              valueList: jsState,
            }
          }
        });
        setValue(thisObj, 'ddPayState', jsState[0].key);

        thisObj.setState(current => {
          return {
            ...current,
            ddBankState: {
              ...current["ddBankState"],
              valueList: jsState,
            }
          }
        });
        setValue(thisObj, 'ddBankState', jsState[0].key);

        thisObj.setState(current => {
          return {
            ...current,
            ddIMBankState: {
              ...current["ddIMBankState"],
              valueList: jsState,
            }
          }
        });
        setValue(thisObj, 'ddIMBankState', jsState[0].key);
      })
    }
    catch (err) {
      errorHandler(err);
    }
  }

  //Implement functionality to bind Vendors dropdown 
  const bFillVendorList = async () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> Select Vendor <<<' });
      await SystemMaintenanceSpecialFunctionsService.RetrieveVendorWireTransferDetails(null).then(response => {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].vendor[0].strFieldData.toString(),
            description: ConvertToPascalCase(data[i].vendor[0].strFieldData.toString()) + ' - ' + ConvertToPascalCase(data[i].vendor[1].strFieldData.toString())
          }
          js.push(obj);
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddVendors: {
              ...current["ddVendors"],
              valueList: js,
            }
          }
        });
        setValue(thisObj, 'ddVendors', js[0].key);
      })
    }
    catch (err) {
      errorHandler(err);
    }
  }

  //Convert to PascalCase function
  function ConvertToPascalCase(word){
    try {
      return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onddVendorsChange = async () => {
    try {
      //setLoading(true);
      clearControls();

      let vendor_num = '';
      if (getData(thisObj,'callingfromAddVendor') == '' || getData(thisObj,'callingfromAddVendor') == null){
        vendor_num = getValue(thisObj, 'ddVendors');
      } else {
        vendor_num = getData(thisObj,'callingfromAddVendor');
        setData(thisObj,'callingfromAddVendor', '');
      }
      
      if(vendor_num != '') {
        await SystemMaintenanceSpecialFunctionsService.RetrieveVendorWireTransferDetails(vendor_num).then(response => {
          let data = response;
          if (data.length >0) {
            setValue(thisObj, 'txtVendor1', ConvertToPascalCase(data[0].vendor[27].strFieldData));
            setValue(thisObj, 'txtVendor2', ConvertToPascalCase(data[0].vendor[28].strFieldData));
            setValue(thisObj, 'txtVendor3', ConvertToPascalCase(data[0].vendor[29].strFieldData));
            setValue(thisObj, 'txtVendor4', ConvertToPascalCase(data[0].vendor[30].strFieldData));
            let vendor5 = ConvertToPascalCase(data[0].vendor[31].strFieldData);
            if (data[0].vendor[32].strFieldData != ''){
              vendor5 = vendor5 + ', ' + ConvertToPascalCase(data[0].vendor[32].strFieldData);
            }
            if (data[0].vendor[33].strFieldData != ''){
              vendor5 = vendor5 + ' ' + data[0].vendor[33].strFieldData.slice(0,5);
            }
            setValue(thisObj, 'txtVendor5', vendor5);
  
            if(getValue(thisObj, 'txtVendor1') != ''){
              show(thisObj, 'txtVendor1');
            } else {
              hide(thisObj, 'txtVendor1');
            }
            if(getValue(thisObj, 'txtVendor2') != ''){
              show(thisObj, 'txtVendor2');
            } else {
              hide(thisObj, 'txtVendor2');
            }
            if(getValue(thisObj, 'txtVendor3') != ''){
              show(thisObj, 'txtVendor3');
            } else {
              hide(thisObj, 'txtVendor3');
            }
            if(getValue(thisObj, 'txtVendor4') != ''){
              show(thisObj, 'txtVendor4');
            } else {
              hide(thisObj, 'txtVendor4');
            }
            if(getValue(thisObj, 'txtVendor5') != ''){
              show(thisObj, 'txtVendor5');
            } else {
              hide(thisObj, 'txtVendor5');
            }

            setValue(thisObj, 'txtPayeeName', data[0].vendor[1].strFieldData); 
            setValue(thisObj, 'txtPayAddress1', data[0].vendor[2].strFieldData); 
            setValue(thisObj, 'txtPayAddress2', data[0].vendor[3].strFieldData); 
            setValue(thisObj, 'txtPayCity', data[0].vendor[4].strFieldData); 
            setValue(thisObj, 'ddPayState', data[0].vendor[5].strFieldData); 
            setValue(thisObj, 'txtPayZip', data[0].vendor[6].strFieldData); 
  
            setValue(thisObj, 'txtPayeeBank', data[0].vendor[7].strFieldData); 
            setValue(thisObj, 'txtBankAddress', data[0].vendor[8].strFieldData); 
            setValue(thisObj, 'txtBankCity', data[0].vendor[9].strFieldData); 
            setValue(thisObj, 'ddBankState', data[0].vendor[10].strFieldData); 
            setValue(thisObj, 'txtBankZip', data[0].vendor[11].strFieldData); 
            setValue(thisObj, 'txtBankABANum', data[0].vendor[13].strFieldData);
            setValue(thisObj, 'txtBankAccountNum', data[0].vendor[12].strFieldData); 
  
            if (data[0].intermediary_bank == 'Y'){
              setValue(thisObj, 'chkboxUseIMBank', '1'); //checked
              setValue(thisObj, 'txtIMBank', data[0].vendor[20].strFieldData);
              setValue(thisObj, 'txtIMBankAddress', data[0].vendor[21].strFieldData);
              setValue(thisObj, 'txtIMBankCity', data[0].vendor[22].strFieldData);
              setValue(thisObj, 'ddIMBankState', data[0].vendor[23].strFieldData);
              setValue(thisObj, 'txtIMBankZip', data[0].vendor[24].strFieldData);
              setValue(thisObj, 'txtIMBankABANum', data[0].vendor[25].strFieldData);
            } else {
              setValue(thisObj, 'chkboxUseIMBank', '');
              setValue(thisObj, 'txtIMBank', '');
              setValue(thisObj, 'txtIMBankAddress', '');
              setValue(thisObj, 'txtIMBankCity', '');
              setValue(thisObj, 'ddIMBankState', '');
              setValue(thisObj, 'txtIMBankZip', '');
              setValue(thisObj, 'txtIMBankABANum', '');
            }
            //onchkboxUseIMBankChange();
  
            setValue(thisObj, 'txtFAXNum', data[0].vendor[14].strFieldData);
            setValue(thisObj, 'txtFAXContact', data[0].vendor[15].strFieldData);
            setValue(thisObj, 'txtEmail', data[0].vendor[26].strFieldData);
  
            let lblAddedBy = data[0].vendor[16].strFieldData + " " + moment(data[0].vendor[17].strFieldData).format("MM/DD/YYYY h:mm:ss A");
            let lblChangedBy = data[0].vendor[18].strFieldData + " " + moment(data[0].vendor[19].strFieldData).format("MM/DD/YYYY h:mm:ss A");
            setValue(thisObj, 'lblAddedByValue', lblAddedBy);
            setValue(thisObj, 'lblChangedByValue', lblChangedBy);
          }
        })
      }
      //setLoading(false);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddVendorsChange = onddVendorsChange;

  const clearControls = () => {
    try {
      setValue(thisObj, 'txtVendor1', '');
      setValue(thisObj, 'txtVendor2', '');
      setValue(thisObj, 'txtVendor3', '');
      setValue(thisObj, 'txtVendor4', '');
      setValue(thisObj, 'txtVendor5', '');
      setValue(thisObj, 'txtPayeeName', ''); 
      setValue(thisObj, 'txtPayAddress1', ''); 
      setValue(thisObj, 'txtPayAddress2', ''); 
      setValue(thisObj, 'txtPayCity', ''); 
      setValue(thisObj, 'ddPayState', ''); 
      setValue(thisObj, 'txtPayZip', ''); 
      setValue(thisObj, 'txtPayeeBank', ''); 
      setValue(thisObj, 'txtBankAddress', ''); 
      setValue(thisObj, 'txtBankCity', ''); 
      setValue(thisObj, 'ddBankState', ''); 
      setValue(thisObj, 'txtBankZip', ''); 
      setValue(thisObj, 'txtBankABANum', '');
      setValue(thisObj, 'txtBankAccountNum', ''); 
      setValue(thisObj, 'chkboxUseIMBank', '');
      setValue(thisObj, 'txtIMBank', '');
      setValue(thisObj, 'txtIMBankAddress', '');
      setValue(thisObj, 'txtIMBankCity', '');
      setValue(thisObj, 'ddIMBankState', '');
      setValue(thisObj, 'txtIMBankZip', '');
      setValue(thisObj, 'txtIMBankABANum', '');
      setValue(thisObj, 'txtFAXNum', '');
      setValue(thisObj, 'txtFAXContact', '');
      setValue(thisObj, 'txtEmail', '');
      setValue(thisObj, 'lblAddedByValue', '');
      setValue(thisObj, 'lblChangedByValue', '');
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const fraWireTransferCtrlsEnable = (showFlag, enableFlag) => {
    try {
      if(showFlag == false){
        hide(thisObj, 'txtPayeeName'); 
        hide(thisObj, 'txtPayAddress1'); 
        hide(thisObj, 'txtPayAddress2'); 
        hide(thisObj, 'txtPayCity'); 
        hide(thisObj, 'ddPayState'); 
        hide(thisObj, 'txtPayZip'); 
        hide(thisObj, 'txtPayeeBank'); 
        hide(thisObj, 'txtBankAddress'); 
        hide(thisObj, 'txtBankCity'); 
        hide(thisObj, 'ddBankState'); 
        hide(thisObj, 'txtBankZip'); 
        hide(thisObj, 'txtBankABANum');
        hide(thisObj, 'txtBankAccountNum'); 
        hide(thisObj, 'chkboxUseIMBank');
        hide(thisObj, 'txtIMBank');
        hide(thisObj, 'txtIMBankAddress');
        hide(thisObj, 'txtIMBankCity');
        hide(thisObj, 'ddIMBankState');
        hide(thisObj, 'txtIMBankZip');
        hide(thisObj, 'txtIMBankABANum');
        hide(thisObj, 'txtFAXNum');
        hide(thisObj, 'txtFAXContact');
        hide(thisObj, 'txtEmail');
      } else {
        if(enableFlag == false){
          disable(thisObj, 'txtPayeeName'); 
          disable(thisObj, 'txtPayAddress1'); 
          disable(thisObj, 'txtPayAddress2'); 
          disable(thisObj, 'txtPayCity'); 
          disable(thisObj, 'ddPayState'); 
          disable(thisObj, 'txtPayZip'); 
          disable(thisObj, 'txtPayeeBank'); 
          disable(thisObj, 'txtBankAddress'); 
          disable(thisObj, 'txtBankCity'); 
          disable(thisObj, 'ddBankState'); 
          disable(thisObj, 'txtBankZip'); 
          disable(thisObj, 'txtBankABANum');
          disable(thisObj, 'txtBankAccountNum'); 
          disable(thisObj, 'chkboxUseIMBank');
          // disable(thisObj, 'txtIMBank');
          // disable(thisObj, 'txtIMBankAddress');
          // disable(thisObj, 'txtIMBankCity');
          // disable(thisObj, 'ddIMBankState');
          // disable(thisObj, 'txtIMBankZip');
          // disable(thisObj, 'txtIMBankABANum');
          disable(thisObj, 'txtFAXNum');
          disable(thisObj, 'txtFAXContact');
          disable(thisObj, 'txtEmail');
    
        } else if(enableFlag == true){
          enable(thisObj, 'txtPayeeName'); 
          enable(thisObj, 'txtPayAddress1'); 
          enable(thisObj, 'txtPayAddress2'); 
          enable(thisObj, 'txtPayCity'); 
          enable(thisObj, 'ddPayState'); 
          enable(thisObj, 'txtPayZip'); 
          enable(thisObj, 'txtPayeeBank'); 
          enable(thisObj, 'txtBankAddress'); 
          enable(thisObj, 'txtBankCity'); 
          enable(thisObj, 'ddBankState'); 
          enable(thisObj, 'txtBankZip'); 
          enable(thisObj, 'txtBankABANum');
          enable(thisObj, 'txtBankAccountNum'); 
          enable(thisObj, 'chkboxUseIMBank');
          // enable(thisObj, 'txtIMBank');
          // enable(thisObj, 'txtIMBankAddress');
          // enable(thisObj, 'txtIMBankCity');
          // enable(thisObj, 'ddIMBankState');
          // enable(thisObj, 'txtIMBankZip');
          // enable(thisObj, 'txtIMBankABANum');
          enable(thisObj, 'txtFAXNum');
          enable(thisObj, 'txtFAXContact');
          enable(thisObj, 'txtEmail');
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const fraIMBankCtrlsEnable = (enableFlag) => {
    try {
      if(enableFlag == false){
        disable(thisObj, 'txtIMBank');
        disable(thisObj, 'txtIMBankAddress');
        disable(thisObj, 'txtIMBankCity');
        disable(thisObj, 'ddIMBankState');
        disable(thisObj, 'txtIMBankZip');
        disable(thisObj, 'txtIMBankABANum');

      } else if(enableFlag == true){
        enable(thisObj, 'txtIMBank');
        enable(thisObj, 'txtIMBankAddress');
        enable(thisObj, 'txtIMBankCity');
        enable(thisObj, 'ddIMBankState');
        enable(thisObj, 'txtIMBankZip');
        enable(thisObj, 'txtIMBankABANum');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const enableDisableControls = (actionType) => {
    try {
      if (actionType == 'Inquiry'){
        enable(thisObj, 'ddVendors');
        show(thisObj, 'btnAdd');
        show(thisObj, 'btnEdit');
        show(thisObj, 'btnDelete');
        fraWireTransferCtrlsEnable(true, false);
        fraIMBankCtrlsEnable(false);
        setValue(thisObj, 'btnAdd1', 'Inquiry');
        hide(thisObj, 'btnAdd1', false);
        hide(thisObj, 'btnCancel', false);

        let accessFor = getData(thisObj, 'accessFor');
        if (accessFor != 'Delete') {
          disable(thisObj, 'btnDelete');
          if (accessFor != 'Update') {
            disable(thisObj, 'btnAdd');
            disable(thisObj, 'btnEdit');
            if (accessFor != 'Inquiry') {
              hide(thisObj, 'ddVendors');
              fraWireTransferCtrlsEnable(false, false)
            }
          }
        }

      } else if (actionType == 'Add') {
        disable(thisObj, 'ddVendors');
        hide(thisObj, 'btnAdd');
        hide(thisObj, 'btnEdit');
        hide(thisObj, 'btnDelete');
        fraWireTransferCtrlsEnable(true, true)
        fraIMBankCtrlsEnable(false);
        setValue(thisObj, 'btnAdd1', 'Add');
        document.getElementsByClassName('btnAdd1')[0].childNodes[0].childNodes[0].innerText = "Add";
        show(thisObj, 'btnAdd1');
        show(thisObj, 'btnCancel');
        enable(thisObj, 'chkboxUseIMBank');
        setValue(thisObj, 'chkboxUseIMBank', '');
        //onchkboxUseIMBankChange();

      } else if (actionType == 'Update') {
        disable(thisObj, 'ddVendors');
        hide(thisObj, 'btnAdd');
        hide(thisObj, 'btnEdit');
        hide(thisObj, 'btnDelete');
        fraWireTransferCtrlsEnable(true, true)
        setValue(thisObj, 'btnAdd1', 'Update');
        document.getElementsByClassName('btnAdd1')[0].childNodes[0].childNodes[0].innerText = "Update";
        show(thisObj, 'btnAdd1');
        show(thisObj, 'btnCancel');
        enable(thisObj, 'chkboxUseIMBank');
        if(getValue(thisObj,'chkboxUseIMBank')=="1") {
          fraIMBankCtrlsEnable(true);
        }
        else{
          fraIMBankCtrlsEnable(false);
        }

      } else if (actionType == 'Delete') {
        disable(thisObj, 'ddVendors');
        hide(thisObj, 'btnAdd');
        hide(thisObj, 'btnEdit');
        hide(thisObj, 'btnDelete');
        fraWireTransferCtrlsEnable(true, false)
        fraIMBankCtrlsEnable(false);
        setValue(thisObj, 'btnAdd1', 'Delete');
        document.getElementsByClassName('btnAdd1')[0].childNodes[0].childNodes[0].innerText = "Delete";
        show(thisObj, 'btnAdd1');
        show(thisObj, 'btnCancel');
        enable(thisObj, 'chkboxUseIMBank');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onchkboxUseIMBankChange = () =>{
    try{
      let chkValue = getValue(thisObj, 'chkboxUseIMBank');
      if(chkValue == '1'){
        fraIMBankCtrlsEnable(true);
      } else {
        setValue(thisObj, 'txtIMBank', '');
        setValue(thisObj, 'txtIMBankAddress', '');
        setValue(thisObj, 'txtIMBankCity', '');
        setValue(thisObj, 'ddIMBankState', '');
        setValue(thisObj, 'txtIMBankZip', '');
        setValue(thisObj, 'txtIMBankABANum', '');
        fraIMBankCtrlsEnable(false);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onchkboxUseIMBankChange = onchkboxUseIMBankChange;
  
  const onbtnAddClick = () => {
    try {
      clearControls();
      let vendorslist = thisObj.state['ddVendors'].valueList;
      setValue(thisObj, 'ddVendors', vendorslist[0].key);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#VendorWireTransfer#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Vendor Wire Transfer Screen has loaded with errors.");
      }
      return false;
    }
  };
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnEditClick = () => {
    try {
      let vendorSelected = thisObj.values['ddVendors'];
      if (vendorSelected == ''){
        showMessage(thisObj,"No Vendor was selected.");
      } else {
        enableDisableControls('Update');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  };
  thisObj.onbtnEditClick = onbtnEditClick;

  const onbtnDeleteClick = () => {
    try {
      let vendorSelected = thisObj.values['ddVendors'];
      if (vendorSelected == ''){
        showMessage(thisObj,"No Vendor was selected.");
      } else {
        enableDisableControls('Delete');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
 
  const onbtnCancelClick = () => {
    try {
      const confirmBox = confirm("You will lose all changes. Do you want to do this?")
      if (confirmBox == true) {
        if (getValue(thisObj, 'btnAdd1') == 'Add'){
          clearControls();
          bFillVendorList();
          //enable(thisObj, 'ddVendors');
        } else if (getValue(thisObj, 'btnAdd1') == 'Update'){
          onddVendorsChange();
        }
        enableDisableControls('Inquiry');
        //enable(thisObj, 'ddVendors');
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // For Cancel Button
  const onbtnCloseClick = () => {
    try {
      if (getValue(thisObj, 'btnAdd1') == 'Add' || getValue(thisObj, 'btnAdd1') == 'Update'){
        const confirmBox = confirm("You will lose all changes. Do you want to do this?")
        if (confirmBox == true) {
          document.getElementById("SystemMaintenanceSpecialFunctions_VendorWireTransferSetupPopUp").childNodes[0].click();
          //goTo(thisObj, "SystemMaintenanceSpecialFunctions#DEFAULT#false#Click");
        }
      } else {
        //goTo(thisObj, "SystemMaintenanceSpecialFunctions#DEFAULT#false#Click");
        document.getElementById("SystemMaintenanceSpecialFunctions_VendorWireTransferSetupPopUp").childNodes[0].click();
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    finally {
      //setLoading(false);
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

   // Implement functionality for Form Validation
   const bFormValid = () => {
    try {
      let formValid = false;
      let payeeName = getValue(thisObj, 'txtPayeeName');
      let payeeAddress1 = getValue(thisObj, 'txtPayAddress1');
      let payeeCity = getValue(thisObj, 'txtPayCity');
      let payeeState = getValue(thisObj, 'ddPayState');
      let payeeZip = getValue(thisObj, 'txtPayZip');
      let payeeBank = getValue(thisObj, 'txtPayeeBank');
      let bankAddress = getValue(thisObj, 'txtBankAddress');
      let bankCity = getValue(thisObj, 'txtBankCity');
      let bankState = getValue(thisObj, 'ddBankState');
      let bankZip = getValue(thisObj, 'txtBankZip');
      let bankABANum = getValue(thisObj, 'txtBankABANum');
      let bankAccountNum = getValue(thisObj, 'txtBankAccountNum');
      let faxNum = getValue(thisObj, 'txtFAXNum');
      let faxContact = getValue(thisObj, 'txtFAXContact');
      let email = getValue(thisObj, 'txtEmail');
      let chkIMBankvalue = getValue(thisObj, 'chkboxUseIMBank');
      let IMbank = getValue(thisObj, 'txtIMBank');
      let IMbankAddress = getValue(thisObj, 'txtIMBankAddress');
      let IMbankCity = getValue(thisObj, 'txtIMBankCity');
      let IMbankStatus = getValue(thisObj, 'ddIMBankState');
      let IMbankZip = getValue(thisObj, 'txtIMBankZip');
      let IMbankABANum = getValue(thisObj, 'txtIMBankABANum');

      if (payeeName == "" || payeeName == null || payeeName == undefined) {
        showMessage(thisObj, 'Payee Name is required');
        return formValid;
      }
      if (payeeAddress1 == "" || payeeAddress1 == null || payeeAddress1 == undefined) {
        showMessage(thisObj, 'Payer Address is required');
        return formValid;
      }
      if (payeeCity == "" || payeeCity == null || payeeCity == undefined) {
        showMessage(thisObj, 'Payer City is required');
        return formValid;
      }
      if (payeeState == "" || payeeState == null || payeeState == undefined) {
        showMessage(thisObj, 'Payer State is required');
        return formValid;
      }
      if (payeeZip == "" || payeeZip == null || payeeZip == undefined) {
        showMessage(thisObj, 'Payer Zip Code is required');
        return formValid;
      }
      if (payeeBank == "" || payeeBank == null || payeeBank == undefined) {
        showMessage(thisObj, 'Bank Name is required');
        return formValid;
      }
      if (bankAddress == "" || bankAddress == null || bankAddress == undefined) {
        showMessage(thisObj, 'Bank Address is required');
        return formValid;
      }
      if (bankCity == "" || bankCity == null || bankCity == undefined) {
        showMessage(thisObj, 'Bank City is required');
        return formValid;
      }
      if (bankState == "" || bankState == null || bankState == undefined) {
        showMessage(thisObj, 'Bank State is required');
        return formValid;
      }
      if (bankZip == "" || bankZip == null || bankZip == undefined) {
        showMessage(thisObj, 'Bank Zip Code is required');
        return formValid;
      }
      if (bankABANum == "" || bankABANum == null || bankABANum == undefined) {
        showMessage(thisObj, 'Bank ABA# is required');
        return formValid;
      }
      if (bankAccountNum == "" || bankAccountNum == null || bankAccountNum == undefined) {
        showMessage(thisObj, 'Bank Account# is required');
        return formValid;
      }
      if(faxNum != '' || faxContact != ''){
        if (faxNum == "" || faxNum == null || faxNum == undefined) {
          showMessage(thisObj, 'FAX Number is required');
          return formValid;
        }
        if (faxContact == "" || faxContact == null || faxContact == undefined) {
          showMessage(thisObj, 'FAX Contact is required');
          return formValid;
        }
      }

      if (chkIMBankvalue == '1') {
        if (IMbank == "" || IMbank == null || IMbank == undefined) {
          showMessage(thisObj, 'Imtermediary Bank Name is required. If an intermediary bank is not used.  Uncheck the Intermediary Bank Checkbox.');
          return formValid;
        }
        if (IMbankAddress == "" || IMbankAddress == null || IMbankAddress == undefined) {
          showMessage(thisObj, 'Imtermediary Bank Address is required. If an intermediary bank is not used.  Uncheck the Intermediary Bank Checkbox.');
          return formValid;
        }
        if (IMbankCity == "" || IMbankCity == null || IMbankCity == undefined) {
          showMessage(thisObj, 'Imtermediary Bank City is required. If an intermediary bank is not used.  Uncheck the Intermediary Bank Checkbox.');
          return formValid;
        }
        if (IMbankStatus == "" || IMbankStatus == null || IMbankStatus == undefined) {
          showMessage(thisObj, 'Imtermediary Bank State is required. If an intermediary bank is not used.  Uncheck the Intermediary Bank Checkbox.');
          return formValid;
        }
        if (IMbankZip == "" || IMbankZip == null || IMbankZip == undefined) {
          showMessage(thisObj, 'Imtermediary Bank Zip Code is required. If an intermediary bank is not used.  Uncheck the Intermediary Bank Checkbox.');
          return formValid;
        }
        if (IMbankABANum == "" || IMbankABANum == null || IMbankABANum == undefined) {
          showMessage(thisObj, 'Imtermediary Bank ABA# is required. If an intermediary bank is not used.  Uncheck the Intermediary Bank Checkbox.');
          return formValid;
        }      
      }
      return true;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  
  const onbtnAdd1Click = async () =>{
    try {
      let lstrxml = [];
      let objTrans = {};
      objTrans.vendor_number = getValue(thisObj, 'ddVendors');
      objTrans.wire_rec_name = getValue(thisObj, 'txtPayeeName');
      objTrans.wire_rec_address_1 = getValue(thisObj, 'txtPayAddress1');
      objTrans.wire_rec_address_2 = getValue(thisObj, 'txtPayAddress2');
      objTrans.wire_rec_city = getValue(thisObj, 'txtPayCity');
      objTrans.wire_rec_state = getValue(thisObj, 'ddPayState');
      objTrans.wire_rec_zip = getValue(thisObj, 'txtPayZip');
      objTrans.wire_rec_bank_name = getValue(thisObj, 'txtPayeeBank');
      objTrans.wire_rec_bank_address = getValue(thisObj, 'txtBankAddress');
      objTrans.wire_rec_bank_city = getValue(thisObj, 'txtBankCity');
      objTrans.wire_rec_bank_state = getValue(thisObj, 'ddBankState');
      objTrans.wire_rec_bank_zip = getValue(thisObj, 'txtBankZip');
      objTrans.wire_rec_bank_acct = getValue(thisObj, 'txtBankAccountNum');
      objTrans.wire_rec_bank_aba = getValue(thisObj, 'txtBankABANum');
      objTrans.wire_fax_number = getValue(thisObj, 'txtFAXNum');
      objTrans.wire_fax_contact_name = getValue(thisObj, 'txtFAXContact');
      objTrans.wire_email_address = getValue(thisObj, 'txtEmail');
      objTrans.uid = UserId;

      if(getValue(thisObj,'chkboxUseIMBank')=="1") {
        objTrans.intermediary_bank_name = getValue(thisObj, 'txtIMBank');
        objTrans.intermediary_bank_address = getValue(thisObj, 'txtIMBankAddress');
        objTrans.intermediary_bank_city = getValue(thisObj, 'txtIMBankCity');
        objTrans.intermediary_bank_state = getValue(thisObj, 'ddIMBankState');
        objTrans.intermediary_bank_zip = getValue(thisObj, 'txtIMBankZip');
        objTrans.intermediary_bank_aba = getValue(thisObj, 'txtIMBankABANum');
      }
      else{
        objTrans.intermediary_bank_name = '';
        objTrans.intermediary_bank_address = '';
        objTrans.intermediary_bank_city = '';
        objTrans.intermediary_bank_state = '';
        objTrans.intermediary_bank_zip = '';
        objTrans.intermediary_bank_aba = '';
      }

      let transAction = getValue(thisObj, 'btnAdd1');
      if(transAction == 'Add'){
        let validationflag = bFormValid();
        if(validationflag == false){
          return;
        }
        await SystemMaintenanceSpecialFunctionsService.CreateVendorWireTransfer(objTrans).then(response =>{
          lstrxml = response
        })
        if (lstrxml.status == 200) {
          enableDisableControls('Inquiry');
        } else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
          return;
        }

      } else if(transAction == 'Update'){
        let validationflag = bFormValid();
        if(validationflag == false){
          return;
        }
        let vendor_number = getValue(thisObj, 'ddVendors');
        await SystemMaintenanceSpecialFunctionsService.UpdateVendorWireTransfer(vendor_number, objTrans).then(response =>{
          lstrxml = response
        })
        if (lstrxml.status == 200) {
          enableDisableControls('Inquiry');
        } else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
          return;
        }

      } else if(transAction == 'Delete'){
        let vendor_number = getValue(thisObj, 'ddVendors');
        await SystemMaintenanceSpecialFunctionsService.RemoveVendorWireTransfer(vendor_number).then(response =>{
          lstrxml = response
        })
        if (lstrxml.status == 200) {
          clearControls();
          bFillVendorList();
          enableDisableControls('Inquiry');
        } else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
          return;
        }
      }
    }  catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnAdd1Click = onbtnAdd1Click;

  //payee details
  const ontxtPayeeNameChange = () => {
    try {
      let value = getValue(thisObj, 'txtPayeeName')
      setValue(thisObj, 'txtPayeeName', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtPayeeNameChange = ontxtPayeeNameChange

  const ontxtPayAddress1Change = () => {
    try {
      let value = getValue(thisObj, 'txtPayAddress1')
      setValue(thisObj, 'txtPayAddress1', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtPayAddress1Change = ontxtPayAddress1Change

  const ontxtPayAddress2Change = () => {
    try {
      let value = getValue(thisObj, 'txtPayAddress2')
      setValue(thisObj, 'txtPayAddress2', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtPayAddress2Change = ontxtPayAddress2Change

  const ontxtPayCityChange = () => {
    try {
      let value = getValue(thisObj, 'txtPayCity')
      setValue(thisObj, 'txtPayCity', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtPayCityChange = ontxtPayCityChange

  const ontxtPayZipChange = () => {
    try {
      let value = getValue(thisObj, 'txtPayZip')
      setValue(thisObj, 'txtPayZip', validateZipCode(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtPayZipChange = ontxtPayZipChange

  //bank details
  const ontxtPayeeBankChange = () => {
    try {
      let value = getValue(thisObj, 'txtPayeeBank')
      setValue(thisObj, 'txtPayeeBank', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtPayeeBankChange = ontxtPayeeBankChange

  const ontxtBankAddressChange = () => {
    try {
      let value = getValue(thisObj, 'txtBankAddress')
      setValue(thisObj, 'txtBankAddress', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtBankAddressChange = ontxtBankAddressChange

  const ontxtBankCityChange = () => {
    try {
      let value = getValue(thisObj, 'txtBankCity')
      setValue(thisObj, 'txtBankCity', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtBankCityChange = ontxtBankCityChange

  const ontxtBankZipChange = () => {
    try {
      let value = getValue(thisObj, 'txtBankZip')
      setValue(thisObj, 'txtBankZip', validateZipCode(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtBankZipChange = ontxtBankZipChange

  const ontxtBankABANumChange = () => {
    try {
      let value = getValue(thisObj, 'txtBankABANum')
      setValue(thisObj, 'txtBankABANum', validateBankABA(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtBankABANumChange = ontxtBankABANumChange

  const ontxtBankAccountNumChange = () => {
    try {
      let value = getValue(thisObj, 'txtBankAccountNum')
      setValue(thisObj, 'txtBankAccountNum', validateBankABA(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtBankAccountNumChange = ontxtBankAccountNumChange

  //IM bank
  const ontxtIMBankChange = () => {
    try {
      let value = getValue(thisObj, 'txtIMBank')
      setValue(thisObj, 'txtIMBank', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtIMBankChange = ontxtIMBankChange

  const ontxtIMBankAddressChange = () => {
    try {
      let value = getValue(thisObj, 'txtIMBankAddress')
      setValue(thisObj, 'txtIMBankAddress', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtIMBankAddressChange = ontxtIMBankAddressChange

  const ontxtIMBankCityChange = () => {
    try {
      let value = getValue(thisObj, 'txtIMBankCity')
      setValue(thisObj, 'txtIMBankCity', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtIMBankCityChange = ontxtIMBankCityChange

  const ontxtIMBankZipChange = () => {
    try {
      let value = getValue(thisObj, 'txtIMBankZip')
      setValue(thisObj, 'txtIMBankZip', validateZipCode(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtIMBankZipChange = ontxtIMBankZipChange

  const ontxtIMBankABANumChange = () => {
    try {
      let value = getValue(thisObj, 'txtIMBankABANum')
      setValue(thisObj, 'txtIMBankABANum', validateBankABA(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtIMBankABANumChange = ontxtIMBankABANumChange

  //FAX email details
  const ontxtFAXNumChange = () => {
    try {
      let value = getValue(thisObj, 'txtFAXNum')
      setValue(thisObj, 'txtFAXNum', validateZipCode(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtFAXNumChange = ontxtFAXNumChange
  
  const ontxtFAXContactChange = () => {
    try {
      let value = getValue(thisObj, 'txtFAXContact')
      setValue(thisObj, 'txtFAXContact', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtFAXContactChange = ontxtFAXContactChange

  const ontxtEmailChange = () => {
    try {
      let value = getValue(thisObj, 'txtEmail')
      setValue(thisObj, 'txtEmail', validateType1(value))
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtEmailChange = ontxtEmailChange


  const validateType1 = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 42) && (data[i].charCodeAt(0) != 94) &&
          (data[i].charCodeAt(0) != 124) && (data[i].charCodeAt(0) != 39) && (data[i].charCodeAt(0) != 34) &&
          (data[i].charCodeAt(0) != 47) && (data[i].charCodeAt(0) != 60) && (data[i].charCodeAt(0) != 62)) {
          res += data[i]
        }
      }
      return res;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const validateZipCode = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) && (data[i].charCodeAt(0) != 8) && 
          (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
          res += data[i]
        }
      }
      return res;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const validateBankABA = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) && (data[i].charCodeAt(0) != 8) && 
          (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
          (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || 
          (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 122)) {
          res += data[i]
        }
      }
      return res;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VendorWireTransferSetup*/}

              {/* END_USER_CODE-USER_BEFORE_VendorWireTransferSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorWireTransferSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorWireTransferSetup*/}

              <GroupBoxWidget
                conf={state.grpbxVendorWireTransferSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_ddVendors*/}

                {/* END_USER_CODE-USER_BEFORE_ddVendors*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddVendors}
                  screenConf={state}
                ></DropDownWidget>
                 <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                 <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor2}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                 <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor3}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                 <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor4}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                 <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor5}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_ddVendors*/}

                {/* END_USER_CODE-USER_AFTER_ddVendors*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWireTransferInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWireTransferInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxWireTransferInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblWireTransferInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireTransferInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireTransferInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireTransferInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireTransferInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayeeName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayeeName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayeeName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayeeName*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayeeName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayAddress1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayAddress1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayAddress1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayAddress1*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayAddress1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayAddress2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayAddress2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayAddress2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayAddress2*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayAddress2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayCity*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayCity*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayCity}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayCity*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayCity*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPayState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPayState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPayState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPayState*/}

                  {/* END_USER_CODE-USER_AFTER_ddPayState*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayZip*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayZip*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayZip}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayZip*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayZip*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayeeBank*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayeeBank*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayeeBank}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayeeBank*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayeeBank*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankAddress*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankAddress}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankAddress*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankCity*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankCity*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankCity}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankCity*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankCity*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBankState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBankState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBankState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBankState*/}

                  {/* END_USER_CODE-USER_AFTER_ddBankState*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankZip*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankZip*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankZip}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankZip*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankZip*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankABANum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankABANum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankABANum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankABANum*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankABANum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBankAccountNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBankAccountNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBankAccountNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBankAccountNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtBankAccountNum*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxIntermediaryBank*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxIntermediaryBank*/}

                  <GroupBoxWidget
                    conf={state.grpbxIntermediaryBank}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_chkboxUseIMBank*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxUseIMBank*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxUseIMBank}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxUseIMBank*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxUseIMBank*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIMBank*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIMBank*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIMBank}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIMBank*/}

                    {/* END_USER_CODE-USER_AFTER_txtIMBank*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIMBankAddress*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIMBankAddress*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIMBankAddress}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIMBankAddress*/}

                    {/* END_USER_CODE-USER_AFTER_txtIMBankAddress*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIMBankCity*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIMBankCity*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIMBankCity}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIMBankCity*/}

                    {/* END_USER_CODE-USER_AFTER_txtIMBankCity*/}
                    {/* START_USER_CODE-USER_BEFORE_ddIMBankState*/}

                    {/* END_USER_CODE-USER_BEFORE_ddIMBankState*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddIMBankState}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddIMBankState*/}

                    {/* END_USER_CODE-USER_AFTER_ddIMBankState*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIMBankZip*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIMBankZip*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIMBankZip}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIMBankZip*/}

                    {/* END_USER_CODE-USER_AFTER_txtIMBankZip*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIMBankABANum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIMBankABANum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIMBankABANum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIMBankABANum*/}

                    {/* END_USER_CODE-USER_AFTER_txtIMBankABANum*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxIntermediaryBank*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxIntermediaryBank*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFAXNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFAXNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFAXNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFAXNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtFAXNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFAXContact*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFAXContact*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFAXContact}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFAXContact*/}

                  {/* END_USER_CODE-USER_AFTER_txtFAXContact*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEmail*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEmail*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEmail}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEmail*/}

                  {/* END_USER_CODE-USER_AFTER_txtEmail*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWireTransferInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWireTransferInfo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorWireTransferSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorWireTransferSetup*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}
                <ButtonWidget
                  conf={state.btnAdd1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
 <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_VendorWireTransferSetup*/}

              {/* END_USER_CODE-USER_AFTER_VendorWireTransferSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_VendorWireTransferSetup
);
