/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getValue,
  setData,
  getData,
  setValue,
  enable,
  disable
} from "../../shared/WindowImports";

import "./TradeCategoryProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_TradeCategoryProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeCategoryProfile",
    windowName: "TradeCategoryProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.TradeCategoryProfile",
    // START_USER_CODE-USER_TradeCategoryProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Category Profile",
      scrCode: "PN0220B",
    },
    // END_USER_CODE-USER_TradeCategoryProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    chkbxAccountingSubDept: {
      name: "chkbxAccountingSubDept",
      type: "CheckBoxWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Accounting Sub-Dept:",
      Alignment: "Right",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxAccountingSubDept_PROPERTIES

      // END_USER_CODE-USER_chkbxAccountingSubDept_PROPERTIES
    },
    chkbxGenerate: {
      name: "chkbxGenerate",
      type: "CheckBoxWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Generate 1007 at Settlement:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxGenerate_PROPERTIES

      // END_USER_CODE-USER_chkbxGenerate_PROPERTIES
    },
    chkbxRecord: {
      name: "chkbxRecord",
      type: "CheckBoxWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Record Weights in Account:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxRecord_PROPERTIES

      // END_USER_CODE-USER_chkbxRecord_PROPERTIES
    },
    ddBalanceSheet: {
      name: "ddBalanceSheet",
      type: "DropDownFieldWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Balance Sheet/Profit-Loss:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBalanceSheet_PROPERTIES

      // END_USER_CODE-USER_ddBalanceSheet_PROPERTIES
    },
    ddPurchaseSale: {
      name: "ddPurchaseSale",
      type: "DropDownFieldWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Purchase/Sale:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPurchaseSale_PROPERTIES

      // END_USER_CODE-USER_ddPurchaseSale_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtAccount: {
      name: "txtAccount",
      type: "TextBoxWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Account:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccount_PROPERTIES

      // END_USER_CODE-USER_txtAccount_PROPERTIES
    },
    txtTradeCategory: {
      name: "txtTradeCategory",
      type: "TextBoxWidget",
      parent: "grpbxTradeCategoryProfile",
      Label: "Trade Category:",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeCategory_PROPERTIES

      // END_USER_CODE-USER_txtTradeCategory_PROPERTIES
    },
    grpbxTradeCategoryProfile: {
      name: "grpbxTradeCategoryProfile",
      type: "GroupBoxWidget",
      parent: "TradeCategoryProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxTradeCategoryProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeCategoryProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TradeCategoryProfile",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const setLabelText = (widgetId, value) => {
    const elementMainParent = document.getElementsByClassName(widgetId);
    elementMainParent[elementMainParent.length - 1].firstChild.firstChild.innerHTML = value;
  };

  const getLabelText = (widgetId) => {
    const elementMainParent = document.getElementsByClassName(widgetId);
    return elementMainParent[elementMainParent.length - 1]
      .firstChild.firstChild.innerHTML;
  };

  useEffect(() => {
    const dataFromParent = getData(thisObj, 'TradeCategoryProfileData');
    if (dataFromParent) {
      if (dataFromParent?.cmdOkCaption) {
        setLabelText('btnUpdate', dataFromParent?.cmdOkCaption);
      }

      if (dataFromParent?.cmdOkEnable !== null && dataFromParent?.cmdOkEnable !== undefined) {
        if (dataFromParent?.cmdOkEnable === false) {
          disable(thisObj, 'btnUpdate');
        } else {
          enable(thisObj, 'btnUpdate');
        }
      }

      if (dataFromParent?.txtTradeCategoryEnable !== null && dataFromParent?.txtTradeCategoryEnable !== undefined) {
        if (dataFromParent?.txtTradeCategoryEnable === false) {
          disable(thisObj, 'txtTradeCategory');
        } else {
          enable(thisObj, 'txtTradeCategory');
        }
      }

      if (dataFromParent?.cboPurchaseSaleIndEnable !== null && dataFromParent?.cboPurchaseSaleIndEnable !== undefined) {
        if (dataFromParent?.cboPurchaseSaleIndEnable === false) {
          disable(thisObj, 'ddPurchaseSale');
        } else {
          enable(thisObj, 'ddPurchaseSale');
        }
      }

      if (dataFromParent?.txtAccountEnable !== null && dataFromParent?.txtAccountEnable !== undefined) {
        if (dataFromParent?.txtAccountEnable === false) {
          disable(thisObj, 'txtAccount');
        } else {
          enable(thisObj, 'txtAccount');
        }
      }

      if (dataFromParent?.cboBalanceSheetProfitLossIndEnable !== null && dataFromParent?.cboBalanceSheetProfitLossIndEnable !== undefined) {
        if (dataFromParent?.cboBalanceSheetProfitLossIndEnable === false) {
          disable(thisObj, 'ddBalanceSheet');
        } else {
          enable(thisObj, 'ddBalanceSheet');
        }
      }

      if (dataFromParent?.chkAcctSubDeptEnable !== null && dataFromParent?.chkAcctSubDeptEnable !== undefined) {
        if (dataFromParent?.chkAcctSubDeptEnable === false) {
          disable(thisObj, 'chkbxAccountingSubDept');
        } else {
          enable(thisObj, 'chkbxAccountingSubDept');
        }
      }

      if (dataFromParent?.chkRecordWeightsEnable !== null && dataFromParent?.chkRecordWeightsEnable !== undefined) {
        if (dataFromParent?.chkRecordWeightsEnable === false) {
          disable(thisObj, 'chkbxRecord');
        } else {
          enable(thisObj, 'chkbxRecord');
        }
      }

      if (dataFromParent?.chkGenerate1007Enable !== null && dataFromParent?.chkGenerate1007Enable !== undefined) {
        if (dataFromParent?.chkGenerate1007Enable === false) {
          disable(thisObj, 'chkbxGenerate');
        } else {
          enable(thisObj, 'chkbxGenerate');
        }
      }

      if (dataFromParent?.txtTradeCategoryText) {
        setValue(thisObj, 'txtTradeCategory', dataFromParent?.txtTradeCategoryText);
      }

      if (dataFromParent?.cboPurchaseSaleIndTag) {
        setValue(thisObj, 'ddPurchaseSale', dataFromParent?.cboPurchaseSaleIndTag);
      }

      if (dataFromParent?.txtAccountText) {
        setValue(thisObj, 'txtAccount', dataFromParent?.txtAccountText);
      }

      if (dataFromParent?.cboBalanceSheetProfitLossIndTag) {
        setValue(thisObj, 'ddBalanceSheet', dataFromParent?.cboBalanceSheetProfitLossIndTag);
      }

      if (dataFromParent?.chkAcctSubDept !== null && dataFromParent?.chkAcctSubDept !== undefined) {
        setValue(thisObj, 'chkbxAccountingSubDept', dataFromParent?.chkAcctSubDept);
      }

      if (dataFromParent?.chkRecordWeights !== null && dataFromParent?.chkRecordWeights !== undefined) {
        setValue(thisObj, 'chkbxRecord', dataFromParent?.chkRecordWeights);
      }

      if (dataFromParent?.chkGenerate1007 !== null && dataFromParent?.chkGenerate1007 !== undefined) {
        setValue(thisObj, 'chkbxGenerate', dataFromParent?.chkGenerate1007);
      }

      if (dataFromParent?.lblAddedBy) {
        setValue(thisObj, 'lblAddedByValue', dataFromParent?.lblAddedBy);
      }

      if (dataFromParent?.lblChangedBy) {
        setValue(thisObj, 'lblChangedByValue', dataFromParent?.lblChangedBy);
      }

      setData(thisObj, 'TradeCategoryProfileData', null);
    }
  }, [getData(thisObj, 'TradeCategoryProfileData')]);

  const fillPurchaseSaleInd = () => {
    try {
      const purchaseSaleValueList = thisObj.state.ddPurchaseSale.valueList?.filter(item => Boolean(item?.key) && Boolean(item?.description));
      if (!purchaseSaleValueList?.length) {
        const purchaseSaleList = [];
        purchaseSaleList.push({ key: 'P', description: 'Purchase' });
        purchaseSaleList.push({ key: 'S', description: 'Sale' });
        thisObj.setState(current => {
          return {
            ...current,
            ddPurchaseSale: {
              ...state['ddPurchaseSale'],
              valueList: purchaseSaleList
            }
          }
        });
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillPurchaseSaleInd: ${error?.toString()}`
        );
      }
    }
  };

  const fillBalanceSheetProfitLossInd = () => {
    try {
      const balanceSheetValueList = thisObj.state.ddBalanceSheet.valueList?.filter(item => Boolean(item?.key) && Boolean(item?.description));
      if (!balanceSheetValueList?.length) {
        const balanceSheetList = [];
        balanceSheetList.push({ key: 'BAL', description: 'Balance Sheet' });
        balanceSheetList.push({ key: 'P/L', description: 'Profit-Loss' });
        thisObj.setState(current => {
          return {
            ...current,
            ddBalanceSheet: {
              ...state['ddBalanceSheet'],
              valueList: balanceSheetList
            }
          }
        });
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillBalanceSheetProfitLossInd: ${error?.toString()}`
        );
      }
    }
  };

  const formLoad = () => {
    fillPurchaseSaleInd();
    fillBalanceSheetProfitLossInd();
  };

  const onbtnCancelClick = async () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_TradeCategoryProfilePopUp").childNodes[0].click();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Cancel button click: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnCancelClick = onbtnCancelClick;

  const formValid = () => {
    try {
      const tradeCategory = getValue(thisObj, 'txtTradeCategory');
      if (!tradeCategory) {
        showMessage(thisObj, 'Must enter a category!');
        return false;
      }

      const accountNum = getValue(thisObj, 'txtAccount');
      if (!accountNum) {
        showMessage(thisObj, 'Must enter an account!');
        return false;
      }

      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formValid: ${error?.toString()}`
        );
      }
    }
  };

  const onbtnUpdateClick = async () => {
    try {
      const isFormValid = formValid();
      if (isFormValid) {
        const actionType = getLabelText('btnUpdate') === 'Add'
          ? 'INSERT' : 'UPDATE';

        const tradeCategory = getValue(thisObj, 'txtTradeCategory');
        const accountNum = getValue(thisObj, 'txtAccount');
        const purchaseSaleInd = getValue(thisObj, 'ddPurchaseSale') || '';
        const balanceSheetProfitLossInd = getValue(thisObj, 'ddBalanceSheet') || '';

        const accountingSubDeptCheck = getValue(thisObj, 'chkbxAccountingSubDept') ? 'Y' : 'N';
        const recordWeightsCheck = getValue(thisObj, 'chkbxRecord') ? 'Y' : 'N';
        const generate1007Check = getValue(thisObj, 'chkbxGenerate') ? 'Y' : 'N';
        const useridFromLS = sessionStorage.getItem('userid') || '';

        const data = {
          trade_category: tradeCategory,
          purch_sale_ind: purchaseSaleInd,
          trade_account: accountNum,
          pl_bal_acct_ind: balanceSheetProfitLossInd,
          dist_sub_dept_ind: accountingSubDeptCheck,
          dist_qty_ind: recordWeightsCheck,
          generate_1007_ind: generate1007Check,
          user_id: useridFromLS
        }

        const tradeCategoryUpdateRes = await SystemMaintenanceMasterManagementService
          .UpdateTradeCategory(actionType, data);

        if (tradeCategoryUpdateRes.status !== 200) {
          const errorMessageSplitted = tradeCategoryUpdateRes?.message?.split('Error Meesage : ')?.length === 2
            ? tradeCategoryUpdateRes.message.split('Error Meesage : ')[1] : '';
          const formattedError = errorMessageSplitted !== 'undefined' ? errorMessageSplitted : '';
          const errorMessage = `An error occured while updating the database.
            Please verify that your data is correct and retry.
            Contact your system administrator if this problem persists.
            ${formattedError ? 'Error: ' : ''}
            ${formattedError}`;
          showMessage(thisObj, errorMessage);
          return;
        } else {
          showMessage(thisObj, 'Trade Category Control table successfully updated.', true);
          setData(thisObj, 'TradeCategoryProfileClosed', true);
          document.getElementById("SystemMaintenanceSpecialFunctions_TradeCategoryProfilePopUp").childNodes[0].click();
        }
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnUpdateClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeCategoryProfile*/}

              {/* END_USER_CODE-USER_BEFORE_TradeCategoryProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxTradeCategoryProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeCategoryProfile*/}

              <GroupBoxWidget
                conf={state.grpbxTradeCategoryProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtTradeCategory*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeCategory*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeCategory}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeCategory*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeCategory*/}
                {/* START_USER_CODE-USER_BEFORE_ddPurchaseSale*/}

                {/* END_USER_CODE-USER_BEFORE_ddPurchaseSale*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPurchaseSale}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPurchaseSale*/}

                {/* END_USER_CODE-USER_AFTER_ddPurchaseSale*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccount*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccount*/}

                {/* END_USER_CODE-USER_AFTER_txtAccount*/}
                {/* START_USER_CODE-USER_BEFORE_ddBalanceSheet*/}

                {/* END_USER_CODE-USER_BEFORE_ddBalanceSheet*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBalanceSheet}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBalanceSheet*/}

                {/* END_USER_CODE-USER_AFTER_ddBalanceSheet*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxAccountingSubDept*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxAccountingSubDept*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxAccountingSubDept}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxAccountingSubDept*/}

                {/* END_USER_CODE-USER_AFTER_chkbxAccountingSubDept*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxRecord*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxRecord*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxRecord}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxRecord*/}

                {/* END_USER_CODE-USER_AFTER_chkbxRecord*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxGenerate*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxGenerate*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxGenerate}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxGenerate*/}

                {/* END_USER_CODE-USER_AFTER_chkbxGenerate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeCategoryProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeCategoryProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeCategoryProfile*/}

              {/* END_USER_CODE-USER_AFTER_TradeCategoryProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_TradeCategoryProfile
);
