/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  getData,
  setData,
  setValue,
  getValue,
  disable,
  enable,
} from "../../shared/WindowImports";

import "./SpecialPayDocsView.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { AccountPayment } from "../Service/AccountPayment";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_SpecialPayDocsView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecialPayDocsView",
    windowName: "SpecialPayDocsView",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.SpecialPayDocsView",
    // START_USER_CODE-USER_SpecialPayDocsView_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "View Special Payable Documents",
      scrCode: "PN1100K",
    },
    // END_USER_CODE-USER_SpecialPayDocsView_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntnrExit",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnReview: {
      name: "btnReview",
      type: "ButtonWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "Remove",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReview_PROPERTIES

      // END_USER_CODE-USER_btnReview_PROPERTIES
    },
    btnView: {
      name: "btnView",
      type: "ButtonWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "View",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView_PROPERTIES

      // END_USER_CODE-USER_btnView_PROPERTIES
    },
    lblMemo: {
      name: "lblMemo",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "Memo:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMemo_PROPERTIES

      // END_USER_CODE-USER_lblMemo_PROPERTIES
    },
    lblMemoValue: {
      name: "lblMemoValue",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMemoValue_PROPERTIES

      // END_USER_CODE-USER_lblMemoValue_PROPERTIES
    },
    lblSpoolLocation: {
      name: "lblSpoolLocation",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "Spool Location:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_lblSpoolLocation_PROPERTIES
    },
    lblSpoolLocationValue: {
      name: "lblSpoolLocationValue",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: ".",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpoolLocationValue_PROPERTIES

      // END_USER_CODE-USER_lblSpoolLocationValue_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVendorValue: {
      name: "lblVendorValue",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: ".",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorValue_PROPERTIES

      // END_USER_CODE-USER_lblVendorValue_PROPERTIES
    },
    lstDocuments: {
      name: "lstDocuments",
      type: "ListBoxFieldWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "Documents:",
      ColSpan: "4",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstDocuments_PROPERTIES

      // END_USER_CODE-USER_lstDocuments_PROPERTIES
    },
    txtSpecialPayDocuments: {
      name: "txtSpecialPayDocuments",
      type: "LabelWidget",
      parent: "grpbxSpecialPayDocs",
      Label: "Special Pay Documents:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpecialPayDocuments_PROPERTIES

      // END_USER_CODE-USER_txtSpecialPayDocuments_PROPERTIES
    },
    grpbxSpecialPayDocs: {
      name: "grpbxSpecialPayDocs",
      type: "GroupBoxWidget",
      parent: "SpecialPayDocsView",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSpecialPayDocs_PROPERTIES

      // END_USER_CODE-USER_grpbxSpecialPayDocs_PROPERTIES
    },
    cmmndCntnrExit: {
      name: "cmmndCntnrExit",
      type: "CommandContainerWidget",
      parent: "SpecialPayDocsView",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrExit_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrExit_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnExit: {
    //   // DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    //   DEFAULT: ["AccountPayment#SpecialPayablesProfile#DEFAULT#false#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  function FormLoad() {
    let data = getData(thisObj, "ViewDocument");

    setValue(thisObj, "lblSpoolLocationValue", data.cboSpoolLocation);
    setValue(thisObj, "lblVendorValue", data.vendor);
    setValue(thisObj, "lblMemoValue", data.memoNumber);
    let memo_num = data.memoNumber;
    LoadDocs(memo_num);
  }

  function LoadDocs(memo_num, document_num = 0) {
    AccountPayment.RetrieveSpecialPayDocumentDetails(memo_num, document_num).then(response => {
      let data = response
      let js = [];
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].document_num, description: data[i].document_name.trim() }
          js.push(obj);
        }
        setData(thisObj, "lstDocuments", data)
        enable(thisObj, "btnView");
        enable(thisObj, "btnReview");
      }
      else {
        setData(thisObj, "lstDocuments", data)
        disable(thisObj, "btnView");
        disable(thisObj, "btnReview");
      }

      thisObj.setState(current => {
        return {
          ...current,
          lstDocuments: {
            ...state["lstDocuments"],
            valueList: js
          }
        }
      });
    })
  }

  const onbtnViewClick = () => {
    let documentSet = [];
    let uplodedfilename, fileFormat, createurl;
    documentSet = getData(thisObj, "lstDocuments");
    if (documentSet.length > 0) {
      let selectedDoc = lstDocuments.value
      if (selectedDoc != null && selectedDoc != "" && selectedDoc != undefined) {
        documentSet.forEach(row => {
          if (row.document_num == selectedDoc) {
            uplodedfilename = row.upload_file_name;
            fileFormat = row.status_ind;
            return;
          }
        });

        if (fileFormat.toUpperCase() == "C") {
          let redirecturl;
          ContractManagementService.RetrievePeanutStaticValues()
            .then(response => {
              let data = response
              if (data !== undefined && data.length !== 0) {
                if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT')
                  redirecturl = data[0].pps_defaults.pps_url.test
                else
                  redirecturl = data[0].pps_defaults.pps_url.prod
              }
              createurl = redirecturl + '/pps/specialpay/' + uplodedfilename;
              window.open(createurl);
            })
        }
        else {
          if (fileFormat.toUpperCase() == "I") {
            showMessage(thisObj, "Invalid file was uploaded. Please rescan this form!")
          }
          else {
            showMessage(thisObj, "Form has not been processed. Try again in about 5 minutes!")
          }
        }
      }
      else {
        showMessage(thisObj, "Select document to view")
      }
    }
  }
  thisObj.onbtnViewClick = onbtnViewClick;

  const onbtnReviewClick = () => {
    let document_num = lstDocuments.value;
    if (document_num != null && document_num != "" && document_num != undefined) {
      let memo_num = getValue(thisObj, "lblMemoValue");
      const confirmBox = window.confirm(
        "Are you sure you wish to remove document " + lstDocuments.selectedOptions[0].innerText + " from this Special Payment Record?"
      )
      if (confirmBox == true) {
        AccountPayment.UpdateSpecialPayUploadDocument(document_num).then(response => {
          let data = response;
          if (data.status == 200) {
            showMessage(thisObj, "Document successfully removed.", true);
            LoadDocs(memo_num);
          }
        })
      }
    } else {
      showMessage(thisObj, "Select document to remove")
    }
  }
  thisObj.onbtnReviewClick = onbtnReviewClick;

  const onbtnExitClick = () => {
    document.getElementById("AccountPayment_SpecialPayDocsViewPopUp").childNodes[0].click();
  }
  thisObj.onbtnExitClick = onbtnExitClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecialPayDocsView*/}

              {/* END_USER_CODE-USER_BEFORE_SpecialPayDocsView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpecialPayDocs*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpecialPayDocs*/}

              <GroupBoxWidget
                conf={state.grpbxSpecialPayDocs}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtSpecialPayDocuments*/}

                {/* END_USER_CODE-USER_BEFORE_txtSpecialPayDocuments*/}

                <LabelWidget
                  values={values}
                  conf={state.txtSpecialPayDocuments}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_txtSpecialPayDocuments*/}

                {/* END_USER_CODE-USER_AFTER_txtSpecialPayDocuments*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpoolLocation*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpoolLocation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpoolLocation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpoolLocation*/}

                {/* END_USER_CODE-USER_AFTER_lblSpoolLocation*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpoolLocationValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpoolLocationValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpoolLocationValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpoolLocationValue*/}

                {/* END_USER_CODE-USER_AFTER_lblSpoolLocationValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVendor}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                {/* START_USER_CODE-USER_BEFORE_lblVendorValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblVendorValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVendorValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVendorValue*/}

                {/* END_USER_CODE-USER_AFTER_lblVendorValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblMemo*/}

                {/* END_USER_CODE-USER_BEFORE_lblMemo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMemo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMemo*/}

                {/* END_USER_CODE-USER_AFTER_lblMemo*/}
                {/* START_USER_CODE-USER_BEFORE_lblMemoValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblMemoValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMemoValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMemoValue*/}

                {/* END_USER_CODE-USER_AFTER_lblMemoValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnView*/}

                {/* END_USER_CODE-USER_BEFORE_btnView*/}

                <ButtonWidget
                  conf={state.btnView}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnView*/}

                {/* END_USER_CODE-USER_AFTER_btnView*/}
                {/* START_USER_CODE-USER_BEFORE_btnReview*/}

                {/* END_USER_CODE-USER_BEFORE_btnReview*/}

                <ButtonWidget
                  conf={state.btnReview}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReview*/}

                {/* END_USER_CODE-USER_AFTER_btnReview*/}
                {/* START_USER_CODE-USER_BEFORE_lstDocuments*/}

                {/* END_USER_CODE-USER_BEFORE_lstDocuments*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstDocuments}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstDocuments*/}

                {/* END_USER_CODE-USER_AFTER_lstDocuments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpecialPayDocs*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpecialPayDocs*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrExit*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrExit*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrExit}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrExit*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrExit*/}

              {/* START_USER_CODE-USER_AFTER_SpecialPayDocsView*/}

              {/* END_USER_CODE-USER_AFTER_SpecialPayDocsView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_SpecialPayDocsView);
