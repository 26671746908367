/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_DeductionTrackingDetail from "./DeductionTrackingDetail";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DeductionTrackingDetail Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_DeductionTrackingDetail />
      );
    });
  });
  afterEach(cleanup);
  test("is DeductionTrackingDetail Loads Successfully", () => {
    expect(screen.getByText("DeductionTrackingDetail")).toBeInTheDocument;
  });
  test("Custom Test Cases for DeductionTrackingDetail", () => {
    // START_USER_CODE-USER_DeductionTrackingDetail_Custom_Test_Case
    // END_USER_CODE-USER_DeductionTrackingDetail_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_DeductionTrackingDetail />
      );
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_btnClose"
      )
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_btnPrint"
      )
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridDeductionTrackingDetail(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetailbtn =
      gridDeductionTrackingDetail.nextElementSibling.firstElementChild;
    gridDeductionTrackingDetail =
      gridDeductionTrackingDetail.parentElement.parentElement.parentElement;
    expect(gridDeductionTrackingDetail.tagName).toBe("DIV");
    expect(gridDeductionTrackingDetail.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridDeductionTrackingDetail", () => {
    // START_USER_CODE-USER_gridDeductionTrackingDetail_TEST
    // END_USER_CODE-USER_gridDeductionTrackingDetail_TEST
  });
  test("grpbxDeductionTrackingDetail(GroupBox Widget) Test Cases", async () => {
    const grpbxDeductionTrackingDetail = screen.getByTestId(
      "grpbxDeductionTrackingDetail"
    );
    expect(grpbxDeductionTrackingDetail.tagName).toBe("BUTTON");
    expect(grpbxDeductionTrackingDetail.type).toBe("button");
    expect(grpbxDeductionTrackingDetail.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDeductionTrackingDetail", () => {
    // START_USER_CODE-USER_grpbxDeductionTrackingDetail_TEST
    // END_USER_CODE-USER_grpbxDeductionTrackingDetail_TEST
  });
  test("lblChargedTotal(Label Widget) Test Cases", async () => {
    const lblChargedTotal = screen.getByTestId("lblChargedTotal");
    expect(lblChargedTotal.tagName).toBe("LABEL");
    expect(lblChargedTotal.classList).toContain("form-label");
    expect(lblChargedTotal.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_lblChargedTotal"
      )
    );
  });
  test("Custom Test Cases for lblChargedTotal", () => {
    // START_USER_CODE-USER_lblChargedTotal_TEST
    // END_USER_CODE-USER_lblChargedTotal_TEST
  });
  test("lblCollectedTotal(Label Widget) Test Cases", async () => {
    const lblCollectedTotal = screen.getByTestId("lblCollectedTotal");
    expect(lblCollectedTotal.tagName).toBe("LABEL");
    expect(lblCollectedTotal.classList).toContain("form-label");
    expect(lblCollectedTotal.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_lblCollectedTotal"
      )
    );
  });
  test("Custom Test Cases for lblCollectedTotal", () => {
    // START_USER_CODE-USER_lblCollectedTotal_TEST
    // END_USER_CODE-USER_lblCollectedTotal_TEST
  });
  test("lblTotal(Label Widget) Test Cases", async () => {
    const lblTotal = screen.getByTestId("lblTotal");
    expect(lblTotal.tagName).toBe("LABEL");
    expect(lblTotal.classList).toContain("form-label");
    expect(lblTotal.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_lblTotal"
      )
    );
  });
  test("Custom Test Cases for lblTotal", () => {
    // START_USER_CODE-USER_lblTotal_TEST
    // END_USER_CODE-USER_lblTotal_TEST
  });
  test("txtAccountVendor(Textbox Widget) Test Cases", async () => {
    const txtAccountVendor = screen.getByTestId("txtAccountVendor");
    expect(txtAccountVendor.tagName).toBe("INPUT");
    expect(txtAccountVendor.type).toBe("text");
    expect(txtAccountVendor.classList).toContain("textboxWidgetClass");
    expect(txtAccountVendor.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_txtAccountVendor"
      )
    );
    await act(async () => {
      userEvent.type(txtAccountVendor, "1");
    });
  });
  test("Custom Test Cases for txtAccountVendor", () => {
    // START_USER_CODE-USER_txtAccountVendor_TEST
    // END_USER_CODE-USER_txtAccountVendor_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_txtBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("gridDeductionTrackingDetail_txtcolAmountCharged(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolAmountCharged = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolAmountChargedbtn =
      gridDeductionTrackingDetail_txtcolAmountCharged.nextElementSibling
        .firstElementChild;
    gridDeductionTrackingDetail_txtcolAmountCharged =
      gridDeductionTrackingDetail_txtcolAmountCharged.parentElement
        .parentElement.parentElement;
    expect(
      gridDeductionTrackingDetail_txtcolAmountCharged.tagName
    ).toBe("DIV");
    expect(
      gridDeductionTrackingDetail_txtcolAmountCharged.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmountCharged", () => {
    // START_USER_CODE-USER_txtcolAmountCharged_TEST
    // END_USER_CODE-USER_txtcolAmountCharged_TEST
  });
  test("gridDeductionTrackingDetail_txtcolAmountCollected(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolAmountCollected = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolAmountCollectedbtn =
      gridDeductionTrackingDetail_txtcolAmountCollected
        .nextElementSibling.firstElementChild;
    gridDeductionTrackingDetail_txtcolAmountCollected =
      gridDeductionTrackingDetail_txtcolAmountCollected.parentElement
        .parentElement.parentElement;
    expect(
      gridDeductionTrackingDetail_txtcolAmountCollected.tagName
    ).toBe("DIV");
    expect(
      gridDeductionTrackingDetail_txtcolAmountCollected.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmountCollected", () => {
    // START_USER_CODE-USER_txtcolAmountCollected_TEST
    // END_USER_CODE-USER_txtcolAmountCollected_TEST
  });
  test("gridDeductionTrackingDetail_txtcolChargeToID(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolChargeToID = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolChargeToIDbtn =
      gridDeductionTrackingDetail_txtcolChargeToID.nextElementSibling
        .firstElementChild;
    gridDeductionTrackingDetail_txtcolChargeToID =
      gridDeductionTrackingDetail_txtcolChargeToID.parentElement
        .parentElement.parentElement;
    expect(gridDeductionTrackingDetail_txtcolChargeToID.tagName).toBe(
      "DIV"
    );
    expect(
      gridDeductionTrackingDetail_txtcolChargeToID.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChargeToID", () => {
    // START_USER_CODE-USER_txtcolChargeToID_TEST
    // END_USER_CODE-USER_txtcolChargeToID_TEST
  });
  test("gridDeductionTrackingDetail_txtcolChargeToType(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolChargeToType = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolChargeToTypebtn =
      gridDeductionTrackingDetail_txtcolChargeToType.nextElementSibling
        .firstElementChild;
    gridDeductionTrackingDetail_txtcolChargeToType =
      gridDeductionTrackingDetail_txtcolChargeToType.parentElement
        .parentElement.parentElement;
    expect(gridDeductionTrackingDetail_txtcolChargeToType.tagName).toBe(
      "DIV"
    );
    expect(
      gridDeductionTrackingDetail_txtcolChargeToType.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChargeToType", () => {
    // START_USER_CODE-USER_txtcolChargeToType_TEST
    // END_USER_CODE-USER_txtcolChargeToType_TEST
  });
  test("gridDeductionTrackingDetail_txtcolCollectedForID(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolCollectedForID = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolCollectedForIDbtn =
      gridDeductionTrackingDetail_txtcolCollectedForID
        .nextElementSibling.firstElementChild;
    gridDeductionTrackingDetail_txtcolCollectedForID =
      gridDeductionTrackingDetail_txtcolCollectedForID.parentElement
        .parentElement.parentElement;
    expect(
      gridDeductionTrackingDetail_txtcolCollectedForID.tagName
    ).toBe("DIV");
    expect(
      gridDeductionTrackingDetail_txtcolCollectedForID.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCollectedForID", () => {
    // START_USER_CODE-USER_txtcolCollectedForID_TEST
    // END_USER_CODE-USER_txtcolCollectedForID_TEST
  });
  test("gridDeductionTrackingDetail_txtcolCollectedForType(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolCollectedForType = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolCollectedForTypebtn =
      gridDeductionTrackingDetail_txtcolCollectedForType
        .nextElementSibling.firstElementChild;
    gridDeductionTrackingDetail_txtcolCollectedForType =
      gridDeductionTrackingDetail_txtcolCollectedForType.parentElement
        .parentElement.parentElement;
    expect(
      gridDeductionTrackingDetail_txtcolCollectedForType.tagName
    ).toBe("DIV");
    expect(
      gridDeductionTrackingDetail_txtcolCollectedForType.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCollectedForType", () => {
    // START_USER_CODE-USER_txtcolCollectedForType_TEST
    // END_USER_CODE-USER_txtcolCollectedForType_TEST
  });
  test("gridDeductionTrackingDetail_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridDeductionTrackingDetail_txtcolDate = screen.getByTestId(
      "gridDeductionTrackingDetail"
    );
    let gridDeductionTrackingDetail_txtcolDatebtn =
      gridDeductionTrackingDetail_txtcolDate.nextElementSibling
        .firstElementChild;
    gridDeductionTrackingDetail_txtcolDate =
      gridDeductionTrackingDetail_txtcolDate.parentElement.parentElement
        .parentElement;
    expect(gridDeductionTrackingDetail_txtcolDate.tagName).toBe("DIV");
    expect(gridDeductionTrackingDetail_txtcolDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:DeductionTrackingDetail_gridDeductionTrackingDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
});
