/* eslint-disable*/
import React from "react";
import GroupWidget from "./GroupWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("GroupWidget Test Cases-1", () => {
  beforeEach(async () => {
    act(() => {
      let groupwidget1 = {
        name: "groupwidget1",
        type: "GroupWidget",
        parent: "groupboxwidget0",
        Label: "groupwidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      renderTestScreen(
        <GroupWidget
          values={values}
          conf={groupwidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("GroupWidget Basic Test Cases", async () => {
    const groupwidget1 = screen.getByTestId("groupwidget1");

    expect(groupwidget1.tagName).toBe("DIV");
  });
});
describe("GroupWidget Test Cases-2", () => {
  beforeEach(async () => {
    act(() => {
      let groupwidget1 = {
        name: "groupwidget1",
        type: "GroupWidget",
        parent: "groupboxwidget0",
        Label: "groupwidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      renderTestScreen(
        <GroupWidget
          values={values}
          conf={groupwidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("GroupWidget Basic Test Cases", async () => {
    const groupwidget1 = screen.getByTestId("groupwidget1");

    expect(groupwidget1.tagName).toBe("DIV");
  });
});
