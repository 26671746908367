/* eslint-disable*/
import React from "react";
import Header from "./Header";
import { cleanup, render, screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../common/renderTestScreen";

describe("Header with menuType Top Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Header menuType={"top"} />);
    });
  });
  afterEach(cleanup);
  test("is Header with menuType Top Loads Successfully", async () => {
    const header = screen.getByTestId("header");
    expect(header.classList.contains("navbar")).toBeTruthy;
  });
});

describe("Header with menuType hamburger Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Header menuType={"hamburger"} />);
    });
  });
  afterEach(cleanup);
  test("is Header with menuType hamburger Loads Successfully", async () => {
    const header = screen.getByTestId("header");
    expect(header.classList.contains("navbar")).toBeTruthy;
  });
});
describe("Header with menuType side Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Header menuType={"side"} />);
    });
  });
  afterEach(cleanup);
  test("is Header with menuType side Loads Successfully", async () => {
    const header = screen.getByTestId("header");
    expect(header.classList.contains("navbar")).toBeTruthy;
  });
});
