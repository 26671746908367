/* eslint-disable*/
import React from "react";
import Settlements_1007Settlement from "./1007Settlement";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("1007Settlement Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_1007Settlement />);
    });
  });
  afterEach(cleanup);
  test("is 1007Settlement Loads Successfully", () => {
    expect(screen.getByText("1007Settlement")).toBeInTheDocument;
  });
  test("Custom Test Cases for 1007Settlement", () => {
    // START_USER_CODE-USER_1007Settlement_Custom_Test_Case
    // END_USER_CODE-USER_1007Settlement_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_1007Settlement />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:1007Settlement_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCntrctAppPremDeduct(Button Widget) Test Cases", async () => {
    const btnCntrctAppPremDeduct = screen.getByTestId("btnCntrctAppPremDeduct");
    expect(btnCntrctAppPremDeduct).toBeInTheDocument;
    expect(btnCntrctAppPremDeduct.textContent).toEqual(
      t("Settlements:1007Settlement_btnCntrctAppPremDeduct")
    );
  });
  test("Custom Test Cases for btnCntrctAppPremDeduct", () => {
    // START_USER_CODE-USER_btnCntrctAppPremDeduct_TEST
    // END_USER_CODE-USER_btnCntrctAppPremDeduct_TEST
  });
  test("btnLookup(Button Widget) Test Cases", async () => {
    const btnLookup = screen.getByTestId("btnLookup");
    expect(btnLookup).toBeInTheDocument;
    expect(btnLookup.textContent).toEqual(
      t("Settlements:1007Settlement_btnLookup")
    );
  });
  test("Custom Test Cases for btnLookup", () => {
    // START_USER_CODE-USER_btnLookup_TEST
    // END_USER_CODE-USER_btnLookup_TEST
  });
  test("grpbx1007Settlements(GroupBox Widget) Test Cases", async () => {
    const grpbx1007Settlements = screen.getByTestId("grpbx1007Settlements");
    expect(grpbx1007Settlements.tagName).toBe("BUTTON");
    expect(grpbx1007Settlements.type).toBe("button");
    expect(grpbx1007Settlements.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007Settlements", () => {
    // START_USER_CODE-USER_grpbx1007Settlements_TEST
    // END_USER_CODE-USER_grpbx1007Settlements_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxControlInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxControlInfo = screen.getByTestId("grpbxControlInfo");
    expect(grpbxControlInfo.tagName).toBe("BUTTON");
    expect(grpbxControlInfo.type).toBe("button");
    expect(grpbxControlInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxControlInfo", () => {
    // START_USER_CODE-USER_grpbxControlInfo_TEST
    // END_USER_CODE-USER_grpbxControlInfo_TEST
  });
  test("grpbxInspectorRemarks(GroupBox Widget) Test Cases", async () => {
    const grpbxInspectorRemarks = screen.getByTestId("grpbxInspectorRemarks");
    expect(grpbxInspectorRemarks.tagName).toBe("BUTTON");
    expect(grpbxInspectorRemarks.type).toBe("button");
    expect(grpbxInspectorRemarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInspectorRemarks", () => {
    // START_USER_CODE-USER_grpbxInspectorRemarks_TEST
    // END_USER_CODE-USER_grpbxInspectorRemarks_TEST
  });
  test("grpbxPrchsStrgWtCert(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsStrgWtCert = screen.getByTestId("grpbxPrchsStrgWtCert");
    expect(grpbxPrchsStrgWtCert.tagName).toBe("BUTTON");
    expect(grpbxPrchsStrgWtCert.type).toBe("button");
    expect(grpbxPrchsStrgWtCert.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsStrgWtCert", () => {
    // START_USER_CODE-USER_grpbxPrchsStrgWtCert_TEST
    // END_USER_CODE-USER_grpbxPrchsStrgWtCert_TEST
  });
  test("grpbxPurchases(GroupBox Widget) Test Cases", async () => {
    const grpbxPurchases = screen.getByTestId("grpbxPurchases");
    expect(grpbxPurchases.tagName).toBe("BUTTON");
    expect(grpbxPurchases.type).toBe("button");
    expect(grpbxPurchases.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPurchases", () => {
    // START_USER_CODE-USER_grpbxPurchases_TEST
    // END_USER_CODE-USER_grpbxPurchases_TEST
  });
  test("grpbxStorage(GroupBox Widget) Test Cases", async () => {
    const grpbxStorage = screen.getByTestId("grpbxStorage");
    expect(grpbxStorage.tagName).toBe("BUTTON");
    expect(grpbxStorage.type).toBe("button");
    expect(grpbxStorage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorage", () => {
    // START_USER_CODE-USER_grpbxStorage_TEST
    // END_USER_CODE-USER_grpbxStorage_TEST
  });
  test("grpbxValueCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxValueCalculation = screen.getByTestId("grpbxValueCalculation");
    expect(grpbxValueCalculation.tagName).toBe("BUTTON");
    expect(grpbxValueCalculation.type).toBe("button");
    expect(grpbxValueCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValueCalculation", () => {
    // START_USER_CODE-USER_grpbxValueCalculation_TEST
    // END_USER_CODE-USER_grpbxValueCalculation_TEST
  });
  test("grpbxVendorSplitRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow0 = screen.getByTestId("grpbxVendorSplitRow0");
    expect(grpbxVendorSplitRow0.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow0.type).toBe("button");
    expect(grpbxVendorSplitRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow0", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow0_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow0_TEST
  });
  test("grpbxVendorSplitRow0uic30(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow0uic30 = screen.getByTestId(
      "grpbxVendorSplitRow0uic30"
    );
    expect(grpbxVendorSplitRow0uic30.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow0uic30.type).toBe("button");
    expect(grpbxVendorSplitRow0uic30.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow0uic30", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow0uic30_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow0uic30_TEST
  });
  test("grpbxVendorSplitRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow1 = screen.getByTestId("grpbxVendorSplitRow1");
    expect(grpbxVendorSplitRow1.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow1.type).toBe("button");
    expect(grpbxVendorSplitRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow1", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow1_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow1_TEST
  });
  test("grpbxVendorSplitRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow3 = screen.getByTestId("grpbxVendorSplitRow3");
    expect(grpbxVendorSplitRow3.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow3.type).toBe("button");
    expect(grpbxVendorSplitRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow3", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow3_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow3_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:1007Settlement_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:1007Settlement_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:1007Settlement_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:1007Settlement_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblControlInformation(Label Widget) Test Cases", async () => {
    const lblControlInformation = screen.getByTestId("lblControlInformation");
    expect(lblControlInformation.tagName).toBe("LABEL");
    expect(lblControlInformation.classList).toContain("form-label");
    expect(lblControlInformation.textContent).toEqual(
      t("Settlements:1007Settlement_lblControlInformation")
    );
  });
  test("Custom Test Cases for lblControlInformation", () => {
    // START_USER_CODE-USER_lblControlInformation_TEST
    // END_USER_CODE-USER_lblControlInformation_TEST
  });
  test("lblInspectorRemarks(Label Widget) Test Cases", async () => {
    const lblInspectorRemarks = screen.getByTestId("lblInspectorRemarks");
    expect(lblInspectorRemarks.tagName).toBe("LABEL");
    expect(lblInspectorRemarks.classList).toContain("form-label");
    expect(lblInspectorRemarks.textContent).toEqual(
      t("Settlements:1007Settlement_lblInspectorRemarks")
    );
  });
  test("Custom Test Cases for lblInspectorRemarks", () => {
    // START_USER_CODE-USER_lblInspectorRemarks_TEST
    // END_USER_CODE-USER_lblInspectorRemarks_TEST
  });
  test("lblPerLb3(Label Widget) Test Cases", async () => {
    const lblPerLb3 = screen.getByTestId("lblPerLb3");
    expect(lblPerLb3.tagName).toBe("LABEL");
    expect(lblPerLb3.classList).toContain("form-label");
    expect(lblPerLb3.textContent).toEqual(
      t("Settlements:1007Settlement_lblPerLb3")
    );
  });
  test("Custom Test Cases for lblPerLb3", () => {
    // START_USER_CODE-USER_lblPerLb3_TEST
    // END_USER_CODE-USER_lblPerLb3_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("Settlements:1007Settlement_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblPOrSuic0(Label Widget) Test Cases", async () => {
    const lblPOrSuic0 = screen.getByTestId("lblPOrSuic0");
    expect(lblPOrSuic0.tagName).toBe("LABEL");
    expect(lblPOrSuic0.classList).toContain("form-label");
    expect(lblPOrSuic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblPOrSuic0")
    );
  });
  test("Custom Test Cases for lblPOrSuic0", () => {
    // START_USER_CODE-USER_lblPOrSuic0_TEST
    // END_USER_CODE-USER_lblPOrSuic0_TEST
  });
  test("lblPoundsuic0(Label Widget) Test Cases", async () => {
    const lblPoundsuic0 = screen.getByTestId("lblPoundsuic0");
    expect(lblPoundsuic0.tagName).toBe("LABEL");
    expect(lblPoundsuic0.classList).toContain("form-label");
    expect(lblPoundsuic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblPoundsuic0")
    );
  });
  test("Custom Test Cases for lblPoundsuic0", () => {
    // START_USER_CODE-USER_lblPoundsuic0_TEST
    // END_USER_CODE-USER_lblPoundsuic0_TEST
  });
  test("lblPurchases(Label Widget) Test Cases", async () => {
    const lblPurchases = screen.getByTestId("lblPurchases");
    expect(lblPurchases.tagName).toBe("LABEL");
    expect(lblPurchases.classList).toContain("form-label");
    expect(lblPurchases.textContent).toEqual(
      t("Settlements:1007Settlement_lblPurchases")
    );
  });
  test("Custom Test Cases for lblPurchases", () => {
    // START_USER_CODE-USER_lblPurchases_TEST
    // END_USER_CODE-USER_lblPurchases_TEST
  });
  test("lblRemitTo0uic0(Label Widget) Test Cases", async () => {
    const lblRemitTo0uic0 = screen.getByTestId("lblRemitTo0uic0");
    expect(lblRemitTo0uic0.tagName).toBe("LABEL");
    expect(lblRemitTo0uic0.classList).toContain("form-label");
    expect(lblRemitTo0uic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblRemitTo0uic0")
    );
  });
  test("Custom Test Cases for lblRemitTo0uic0", () => {
    // START_USER_CODE-USER_lblRemitTo0uic0_TEST
    // END_USER_CODE-USER_lblRemitTo0uic0_TEST
  });
  test("lblSharePctuic0(Label Widget) Test Cases", async () => {
    const lblSharePctuic0 = screen.getByTestId("lblSharePctuic0");
    expect(lblSharePctuic0.tagName).toBe("LABEL");
    expect(lblSharePctuic0.classList).toContain("form-label");
    expect(lblSharePctuic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblSharePctuic0")
    );
  });
  test("Custom Test Cases for lblSharePctuic0", () => {
    // START_USER_CODE-USER_lblSharePctuic0_TEST
    // END_USER_CODE-USER_lblSharePctuic0_TEST
  });
  test("lblStorage(Label Widget) Test Cases", async () => {
    const lblStorage = screen.getByTestId("lblStorage");
    expect(lblStorage.tagName).toBe("LABEL");
    expect(lblStorage.classList).toContain("form-label");
    expect(lblStorage.textContent).toEqual(
      t("Settlements:1007Settlement_lblStorage")
    );
  });
  test("Custom Test Cases for lblStorage", () => {
    // START_USER_CODE-USER_lblStorage_TEST
    // END_USER_CODE-USER_lblStorage_TEST
  });
  test("lblValue0uic0(Label Widget) Test Cases", async () => {
    const lblValue0uic0 = screen.getByTestId("lblValue0uic0");
    expect(lblValue0uic0.tagName).toBe("LABEL");
    expect(lblValue0uic0.classList).toContain("form-label");
    expect(lblValue0uic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblValue0uic0")
    );
  });
  test("Custom Test Cases for lblValue0uic0", () => {
    // START_USER_CODE-USER_lblValue0uic0_TEST
    // END_USER_CODE-USER_lblValue0uic0_TEST
  });
  test("lblValueCalculation(Label Widget) Test Cases", async () => {
    const lblValueCalculation = screen.getByTestId("lblValueCalculation");
    expect(lblValueCalculation.tagName).toBe("LABEL");
    expect(lblValueCalculation.classList).toContain("form-label");
    expect(lblValueCalculation.textContent).toEqual(
      t("Settlements:1007Settlement_lblValueCalculation")
    );
  });
  test("Custom Test Cases for lblValueCalculation", () => {
    // START_USER_CODE-USER_lblValueCalculation_TEST
    // END_USER_CODE-USER_lblValueCalculation_TEST
  });
  test("lblVendor0(Label Widget) Test Cases", async () => {
    const lblVendor0 = screen.getByTestId("lblVendor0");
    expect(lblVendor0.tagName).toBe("LABEL");
    expect(lblVendor0.classList).toContain("form-label");
    expect(lblVendor0.textContent).toEqual(
      t("Settlements:1007Settlement_lblVendor0")
    );
  });
  test("Custom Test Cases for lblVendor0", () => {
    // START_USER_CODE-USER_lblVendor0_TEST
    // END_USER_CODE-USER_lblVendor0_TEST
  });
  test("lblVendor1(Label Widget) Test Cases", async () => {
    const lblVendor1 = screen.getByTestId("lblVendor1");
    expect(lblVendor1.tagName).toBe("LABEL");
    expect(lblVendor1.classList).toContain("form-label");
    expect(lblVendor1.textContent).toEqual(
      t("Settlements:1007Settlement_lblVendor1")
    );
  });
  test("Custom Test Cases for lblVendor1", () => {
    // START_USER_CODE-USER_lblVendor1_TEST
    // END_USER_CODE-USER_lblVendor1_TEST
  });
  test("lblVendor2(Label Widget) Test Cases", async () => {
    const lblVendor2 = screen.getByTestId("lblVendor2");
    expect(lblVendor2.tagName).toBe("LABEL");
    expect(lblVendor2.classList).toContain("form-label");
    expect(lblVendor2.textContent).toEqual(
      t("Settlements:1007Settlement_lblVendor2")
    );
  });
  test("Custom Test Cases for lblVendor2", () => {
    // START_USER_CODE-USER_lblVendor2_TEST
    // END_USER_CODE-USER_lblVendor2_TEST
  });
  test("lblVendor3(Label Widget) Test Cases", async () => {
    const lblVendor3 = screen.getByTestId("lblVendor3");
    expect(lblVendor3.tagName).toBe("LABEL");
    expect(lblVendor3.classList).toContain("form-label");
    expect(lblVendor3.textContent).toEqual(
      t("Settlements:1007Settlement_lblVendor3")
    );
  });
  test("Custom Test Cases for lblVendor3", () => {
    // START_USER_CODE-USER_lblVendor3_TEST
    // END_USER_CODE-USER_lblVendor3_TEST
  });
  test("lblVendorSplitsuic0(Label Widget) Test Cases", async () => {
    const lblVendorSplitsuic0 = screen.getByTestId("lblVendorSplitsuic0");
    expect(lblVendorSplitsuic0.tagName).toBe("LABEL");
    expect(lblVendorSplitsuic0.classList).toContain("form-label");
    expect(lblVendorSplitsuic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblVendorSplitsuic0")
    );
  });
  test("Custom Test Cases for lblVendorSplitsuic0", () => {
    // START_USER_CODE-USER_lblVendorSplitsuic0_TEST
    // END_USER_CODE-USER_lblVendorSplitsuic0_TEST
  });
  test("lblVendoruic0(Label Widget) Test Cases", async () => {
    const lblVendoruic0 = screen.getByTestId("lblVendoruic0");
    expect(lblVendoruic0.tagName).toBe("LABEL");
    expect(lblVendoruic0.classList).toContain("form-label");
    expect(lblVendoruic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblVendoruic0")
    );
  });
  test("Custom Test Cases for lblVendoruic0", () => {
    // START_USER_CODE-USER_lblVendoruic0_TEST
    // END_USER_CODE-USER_lblVendoruic0_TEST
  });
  test("lblWhseRcptuic0(Label Widget) Test Cases", async () => {
    const lblWhseRcptuic0 = screen.getByTestId("lblWhseRcptuic0");
    expect(lblWhseRcptuic0.tagName).toBe("LABEL");
    expect(lblWhseRcptuic0.classList).toContain("form-label");
    expect(lblWhseRcptuic0.textContent).toEqual(
      t("Settlements:1007Settlement_lblWhseRcptuic0")
    );
  });
  test("Custom Test Cases for lblWhseRcptuic0", () => {
    // START_USER_CODE-USER_lblWhseRcptuic0_TEST
    // END_USER_CODE-USER_lblWhseRcptuic0_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "123");
    });
    expect(txt1007.getAttribute("value")).toBe("");
    expect(txt1007.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("txtAccessFMTon(Textbox Widget) Test Cases", async () => {
    const txtAccessFMTon = screen.getByTestId("txtAccessFMTon");
    expect(txtAccessFMTon.tagName).toBe("INPUT");
    expect(txtAccessFMTon.type).toBe("text");
    expect(txtAccessFMTon.classList).toContain("textboxWidgetClass");
    expect(txtAccessFMTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtAccessFMTon")
    );
    await act(async () => {
      userEvent.type(txtAccessFMTon, "1");
    });
  });
  test("Custom Test Cases for txtAccessFMTon", () => {
    // START_USER_CODE-USER_txtAccessFMTon_TEST
    // END_USER_CODE-USER_txtAccessFMTon_TEST
  });
  test("txtAdditionalVendorInfo0(Textbox Widget) Test Cases", async () => {
    const txtAdditionalVendorInfo0 = screen.getByTestId(
      "txtAdditionalVendorInfo0"
    );
    expect(txtAdditionalVendorInfo0.tagName).toBe("INPUT");
    expect(txtAdditionalVendorInfo0.type).toBe("text");
    expect(txtAdditionalVendorInfo0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAdditionalVendorInfo0, "123");
    });
    expect(txtAdditionalVendorInfo0.getAttribute("value")).toBe("");
    expect(txtAdditionalVendorInfo0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAdditionalVendorInfo0", () => {
    // START_USER_CODE-USER_txtAdditionalVendorInfo0_TEST
    // END_USER_CODE-USER_txtAdditionalVendorInfo0_TEST
  });
  test("txtAdditionalVendorInfo1(Textbox Widget) Test Cases", async () => {
    const txtAdditionalVendorInfo1 = screen.getByTestId(
      "txtAdditionalVendorInfo1"
    );
    expect(txtAdditionalVendorInfo1.tagName).toBe("INPUT");
    expect(txtAdditionalVendorInfo1.type).toBe("text");
    expect(txtAdditionalVendorInfo1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAdditionalVendorInfo1, "123");
    });
    expect(txtAdditionalVendorInfo1.getAttribute("value")).toBe("");
    expect(txtAdditionalVendorInfo1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAdditionalVendorInfo1", () => {
    // START_USER_CODE-USER_txtAdditionalVendorInfo1_TEST
    // END_USER_CODE-USER_txtAdditionalVendorInfo1_TEST
  });
  test("txtAdditionalVendorInfo2(Textbox Widget) Test Cases", async () => {
    const txtAdditionalVendorInfo2 = screen.getByTestId(
      "txtAdditionalVendorInfo2"
    );
    expect(txtAdditionalVendorInfo2.tagName).toBe("INPUT");
    expect(txtAdditionalVendorInfo2.type).toBe("text");
    expect(txtAdditionalVendorInfo2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAdditionalVendorInfo2, "123");
    });
    expect(txtAdditionalVendorInfo2.getAttribute("value")).toBe("");
    expect(txtAdditionalVendorInfo2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAdditionalVendorInfo2", () => {
    // START_USER_CODE-USER_txtAdditionalVendorInfo2_TEST
    // END_USER_CODE-USER_txtAdditionalVendorInfo2_TEST
  });
  test("txtAdditionalVendorInfo3(Textbox Widget) Test Cases", async () => {
    const txtAdditionalVendorInfo3 = screen.getByTestId(
      "txtAdditionalVendorInfo3"
    );
    expect(txtAdditionalVendorInfo3.tagName).toBe("INPUT");
    expect(txtAdditionalVendorInfo3.type).toBe("text");
    expect(txtAdditionalVendorInfo3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAdditionalVendorInfo3, "123");
    });
    expect(txtAdditionalVendorInfo3.getAttribute("value")).toBe("");
    expect(txtAdditionalVendorInfo3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAdditionalVendorInfo3", () => {
    // START_USER_CODE-USER_txtAdditionalVendorInfo3_TEST
    // END_USER_CODE-USER_txtAdditionalVendorInfo3_TEST
  });
  test("txtAFlavusTon(Textbox Widget) Test Cases", async () => {
    const txtAFlavusTon = screen.getByTestId("txtAFlavusTon");
    expect(txtAFlavusTon.tagName).toBe("INPUT");
    expect(txtAFlavusTon.type).toBe("text");
    expect(txtAFlavusTon.classList).toContain("textboxWidgetClass");
    expect(txtAFlavusTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtAFlavusTon")
    );
    await act(async () => {
      userEvent.type(txtAFlavusTon, "1");
    });
  });
  test("Custom Test Cases for txtAFlavusTon", () => {
    // START_USER_CODE-USER_txtAFlavusTon_TEST
    // END_USER_CODE-USER_txtAFlavusTon_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtBuyingPoint")
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "123");
    });
    expect(txtBuyingPoint.getAttribute("value")).toBe("");
    expect(txtBuyingPoint.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtCMAParticipant0(Textbox Widget) Test Cases", async () => {
    const txtCMAParticipant0 = screen.getByTestId("txtCMAParticipant0");
    expect(txtCMAParticipant0.tagName).toBe("INPUT");
    expect(txtCMAParticipant0.type).toBe("text");
    expect(txtCMAParticipant0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCMAParticipant0, "123");
    });
    expect(txtCMAParticipant0.getAttribute("value")).toBe("");
    expect(txtCMAParticipant0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCMAParticipant0", () => {
    // START_USER_CODE-USER_txtCMAParticipant0_TEST
    // END_USER_CODE-USER_txtCMAParticipant0_TEST
  });
  test("txtCMAParticipant1(Textbox Widget) Test Cases", async () => {
    const txtCMAParticipant1 = screen.getByTestId("txtCMAParticipant1");
    expect(txtCMAParticipant1.tagName).toBe("INPUT");
    expect(txtCMAParticipant1.type).toBe("text");
    expect(txtCMAParticipant1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCMAParticipant1, "123");
    });
    expect(txtCMAParticipant1.getAttribute("value")).toBe("");
    expect(txtCMAParticipant1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCMAParticipant1", () => {
    // START_USER_CODE-USER_txtCMAParticipant1_TEST
    // END_USER_CODE-USER_txtCMAParticipant1_TEST
  });
  test("txtCMAParticipant2(Textbox Widget) Test Cases", async () => {
    const txtCMAParticipant2 = screen.getByTestId("txtCMAParticipant2");
    expect(txtCMAParticipant2.tagName).toBe("INPUT");
    expect(txtCMAParticipant2.type).toBe("text");
    expect(txtCMAParticipant2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCMAParticipant2, "123");
    });
    expect(txtCMAParticipant2.getAttribute("value")).toBe("");
    expect(txtCMAParticipant2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCMAParticipant2", () => {
    // START_USER_CODE-USER_txtCMAParticipant2_TEST
    // END_USER_CODE-USER_txtCMAParticipant2_TEST
  });
  test("txtCMAParticipant3(Textbox Widget) Test Cases", async () => {
    const txtCMAParticipant3 = screen.getByTestId("txtCMAParticipant3");
    expect(txtCMAParticipant3.tagName).toBe("INPUT");
    expect(txtCMAParticipant3.type).toBe("text");
    expect(txtCMAParticipant3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCMAParticipant3, "123");
    });
    expect(txtCMAParticipant3.getAttribute("value")).toBe("");
    expect(txtCMAParticipant3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCMAParticipant3", () => {
    // START_USER_CODE-USER_txtCMAParticipant3_TEST
    // END_USER_CODE-USER_txtCMAParticipant3_TEST
  });
  test("txtContractSpotPricePrchs(Textbox Widget) Test Cases", async () => {
    const txtContractSpotPricePrchs = screen.getByTestId(
      "txtContractSpotPricePrchs"
    );
    expect(txtContractSpotPricePrchs.tagName).toBe("INPUT");
    expect(txtContractSpotPricePrchs.type).toBe("text");
    expect(txtContractSpotPricePrchs.classList).toContain("textboxWidgetClass");
    expect(
      txtContractSpotPricePrchs.previousElementSibling.textContent
    ).toEqual(t("Settlements:1007Settlement_txtContractSpotPricePrchs"));
    await act(async () => {
      userEvent.type(txtContractSpotPricePrchs, "123");
    });
    expect(txtContractSpotPricePrchs.getAttribute("value")).toBe("");
    expect(txtContractSpotPricePrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtContractSpotPricePrchs", () => {
    // START_USER_CODE-USER_txtContractSpotPricePrchs_TEST
    // END_USER_CODE-USER_txtContractSpotPricePrchs_TEST
  });
  test("txtDamageTon(Textbox Widget) Test Cases", async () => {
    const txtDamageTon = screen.getByTestId("txtDamageTon");
    expect(txtDamageTon.tagName).toBe("INPUT");
    expect(txtDamageTon.type).toBe("text");
    expect(txtDamageTon.classList).toContain("textboxWidgetClass");
    expect(txtDamageTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtDamageTon")
    );
    await act(async () => {
      userEvent.type(txtDamageTon, "1");
    });
  });
  test("Custom Test Cases for txtDamageTon", () => {
    // START_USER_CODE-USER_txtDamageTon_TEST
    // END_USER_CODE-USER_txtDamageTon_TEST
  });
  test("txtDeductionsPrchs(Textbox Widget) Test Cases", async () => {
    const txtDeductionsPrchs = screen.getByTestId("txtDeductionsPrchs");
    expect(txtDeductionsPrchs.tagName).toBe("INPUT");
    expect(txtDeductionsPrchs.type).toBe("text");
    expect(txtDeductionsPrchs.classList).toContain("textboxWidgetClass");
    expect(txtDeductionsPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtDeductionsPrchs")
    );
    await act(async () => {
      userEvent.type(txtDeductionsPrchs, "123");
    });
    expect(txtDeductionsPrchs.getAttribute("value")).toBe("");
    expect(txtDeductionsPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDeductionsPrchs", () => {
    // START_USER_CODE-USER_txtDeductionsPrchs_TEST
    // END_USER_CODE-USER_txtDeductionsPrchs_TEST
  });
  test("txtDeductionsStrg(Textbox Widget) Test Cases", async () => {
    const txtDeductionsStrg = screen.getByTestId("txtDeductionsStrg");
    expect(txtDeductionsStrg.tagName).toBe("INPUT");
    expect(txtDeductionsStrg.type).toBe("text");
    expect(txtDeductionsStrg.classList).toContain("textboxWidgetClass");
    expect(txtDeductionsStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtDeductionsStrg")
    );
    await act(async () => {
      userEvent.type(txtDeductionsStrg, "123");
    });
    expect(txtDeductionsStrg.getAttribute("value")).toBe("");
    expect(txtDeductionsStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDeductionsStrg", () => {
    // START_USER_CODE-USER_txtDeductionsStrg_TEST
    // END_USER_CODE-USER_txtDeductionsStrg_TEST
  });
  test("txtELKPremiumTon(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumTon = screen.getByTestId("txtELKPremiumTon");
    expect(txtELKPremiumTon.tagName).toBe("INPUT");
    expect(txtELKPremiumTon.type).toBe("text");
    expect(txtELKPremiumTon.classList).toContain("textboxWidgetClass");
    expect(txtELKPremiumTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtELKPremiumTon")
    );
    await act(async () => {
      userEvent.type(txtELKPremiumTon, "1");
    });
  });
  test("Custom Test Cases for txtELKPremiumTon", () => {
    // START_USER_CODE-USER_txtELKPremiumTon_TEST
    // END_USER_CODE-USER_txtELKPremiumTon_TEST
  });
  test("txtExcessMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcessMoisture = screen.getByTestId("txtExcessMoisture");
    expect(txtExcessMoisture.tagName).toBe("INPUT");
    expect(txtExcessMoisture.type).toBe("text");
    expect(txtExcessMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcessMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtExcessMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcessMoisture, "1");
    });
  });
  test("Custom Test Cases for txtExcessMoisture", () => {
    // START_USER_CODE-USER_txtExcessMoisture_TEST
    // END_USER_CODE-USER_txtExcessMoisture_TEST
  });
  test("txtExcessSplitsTon(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsTon = screen.getByTestId("txtExcessSplitsTon");
    expect(txtExcessSplitsTon.tagName).toBe("INPUT");
    expect(txtExcessSplitsTon.type).toBe("text");
    expect(txtExcessSplitsTon.classList).toContain("textboxWidgetClass");
    expect(txtExcessSplitsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtExcessSplitsTon")
    );
    await act(async () => {
      userEvent.type(txtExcessSplitsTon, "1");
    });
  });
  test("Custom Test Cases for txtExcessSplitsTon", () => {
    // START_USER_CODE-USER_txtExcessSplitsTon_TEST
    // END_USER_CODE-USER_txtExcessSplitsTon_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "123");
    });
    expect(txtFarm.getAttribute("value")).toBe("");
    expect(txtFarm.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmerStockCostPrchs(Textbox Widget) Test Cases", async () => {
    const txtFarmerStockCostPrchs = screen.getByTestId(
      "txtFarmerStockCostPrchs"
    );
    expect(txtFarmerStockCostPrchs.tagName).toBe("INPUT");
    expect(txtFarmerStockCostPrchs.type).toBe("text");
    expect(txtFarmerStockCostPrchs.classList).toContain("textboxWidgetClass");
    expect(txtFarmerStockCostPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtFarmerStockCostPrchs")
    );
    await act(async () => {
      userEvent.type(txtFarmerStockCostPrchs, "123");
    });
    expect(txtFarmerStockCostPrchs.getAttribute("value")).toBe("");
    expect(txtFarmerStockCostPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmerStockCostPrchs", () => {
    // START_USER_CODE-USER_txtFarmerStockCostPrchs_TEST
    // END_USER_CODE-USER_txtFarmerStockCostPrchs_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtG(Textbox Widget) Test Cases", async () => {
    const txtG = screen.getByTestId("txtG");
    expect(txtG.tagName).toBe("INPUT");
    expect(txtG.type).toBe("text");
    expect(txtG.classList).toContain("textboxWidgetClass");
    expect(txtG.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtG")
    );
    await act(async () => {
      userEvent.type(txtG, "1");
    });
  });
  test("Custom Test Cases for txtG", () => {
    // START_USER_CODE-USER_txtG_TEST
    // END_USER_CODE-USER_txtG_TEST
  });
  test("txtGrossWeight(Textbox Widget) Test Cases", async () => {
    const txtGrossWeight = screen.getByTestId("txtGrossWeight");
    expect(txtGrossWeight.tagName).toBe("INPUT");
    expect(txtGrossWeight.type).toBe("text");
    expect(txtGrossWeight.classList).toContain("textboxWidgetClass");
    expect(txtGrossWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtGrossWeight")
    );
    await act(async () => {
      userEvent.type(txtGrossWeight, "1");
    });
  });
  test("Custom Test Cases for txtGrossWeight", () => {
    // START_USER_CODE-USER_txtGrossWeight_TEST
    // END_USER_CODE-USER_txtGrossWeight_TEST
  });
  test("txtH(Textbox Widget) Test Cases", async () => {
    const txtH = screen.getByTestId("txtH");
    expect(txtH.tagName).toBe("INPUT");
    expect(txtH.type).toBe("text");
    expect(txtH.classList).toContain("textboxWidgetClass");
    expect(txtH.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtH")
    );
    await act(async () => {
      userEvent.type(txtH, "1");
    });
  });
  test("Custom Test Cases for txtH", () => {
    // START_USER_CODE-USER_txtH_TEST
    // END_USER_CODE-USER_txtH_TEST
  });
  test("txtKernelValueTon(Textbox Widget) Test Cases", async () => {
    const txtKernelValueTon = screen.getByTestId("txtKernelValueTon");
    expect(txtKernelValueTon.tagName).toBe("INPUT");
    expect(txtKernelValueTon.type).toBe("text");
    expect(txtKernelValueTon.classList).toContain("textboxWidgetClass");
    expect(txtKernelValueTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtKernelValueTon")
    );
    await act(async () => {
      userEvent.type(txtKernelValueTon, "1");
    });
  });
  test("Custom Test Cases for txtKernelValueTon", () => {
    // START_USER_CODE-USER_txtKernelValueTon_TEST
    // END_USER_CODE-USER_txtKernelValueTon_TEST
  });
  test("txtLSKvc(Textbox Widget) Test Cases", async () => {
    const txtLSKvc = screen.getByTestId("txtLSKvc");
    expect(txtLSKvc.tagName).toBe("INPUT");
    expect(txtLSKvc.type).toBe("text");
    expect(txtLSKvc.classList).toContain("textboxWidgetClass");
    expect(txtLSKvc.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtLSKvc")
    );
    await act(async () => {
      userEvent.type(txtLSKvc, "1");
    });
  });
  test("Custom Test Cases for txtLSKvc", () => {
    // START_USER_CODE-USER_txtLSKvc_TEST
    // END_USER_CODE-USER_txtLSKvc_TEST
  });
  test("txtNetValPerTonExclSLK(Textbox Widget) Test Cases", async () => {
    const txtNetValPerTonExclSLK = screen.getByTestId("txtNetValPerTonExclSLK");
    expect(txtNetValPerTonExclSLK.tagName).toBe("INPUT");
    expect(txtNetValPerTonExclSLK.type).toBe("text");
    expect(txtNetValPerTonExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtNetValPerTonExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtNetValPerTonExclSLK")
    );
    await act(async () => {
      userEvent.type(txtNetValPerTonExclSLK, "1");
    });
  });
  test("Custom Test Cases for txtNetValPerTonExclSLK", () => {
    // START_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
    // END_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "1");
    });
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtNetWeightPrchs(Textbox Widget) Test Cases", async () => {
    const txtNetWeightPrchs = screen.getByTestId("txtNetWeightPrchs");
    expect(txtNetWeightPrchs.tagName).toBe("INPUT");
    expect(txtNetWeightPrchs.type).toBe("text");
    expect(txtNetWeightPrchs.classList).toContain("textboxWidgetClass");
    expect(txtNetWeightPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtNetWeightPrchs")
    );
    await act(async () => {
      userEvent.type(txtNetWeightPrchs, "123");
    });
    expect(txtNetWeightPrchs.getAttribute("value")).toBe("");
    expect(txtNetWeightPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeightPrchs", () => {
    // START_USER_CODE-USER_txtNetWeightPrchs_TEST
    // END_USER_CODE-USER_txtNetWeightPrchs_TEST
  });
  test("txtNetWeightStrg(Textbox Widget) Test Cases", async () => {
    const txtNetWeightStrg = screen.getByTestId("txtNetWeightStrg");
    expect(txtNetWeightStrg.tagName).toBe("INPUT");
    expect(txtNetWeightStrg.type).toBe("text");
    expect(txtNetWeightStrg.classList).toContain("textboxWidgetClass");
    expect(txtNetWeightStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtNetWeightStrg")
    );
    await act(async () => {
      userEvent.type(txtNetWeightStrg, "123");
    });
    expect(txtNetWeightStrg.getAttribute("value")).toBe("");
    expect(txtNetWeightStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeightStrg", () => {
    // START_USER_CODE-USER_txtNetWeightStrg_TEST
    // END_USER_CODE-USER_txtNetWeightStrg_TEST
  });
  test("txtNetWtExclLSK(Textbox Widget) Test Cases", async () => {
    const txtNetWtExclLSK = screen.getByTestId("txtNetWtExclLSK");
    expect(txtNetWtExclLSK.tagName).toBe("INPUT");
    expect(txtNetWtExclLSK.type).toBe("text");
    expect(txtNetWtExclLSK.classList).toContain("textboxWidgetClass");
    expect(txtNetWtExclLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtNetWtExclLSK")
    );
    await act(async () => {
      userEvent.type(txtNetWtExclLSK, "1");
    });
  });
  test("Custom Test Cases for txtNetWtExclLSK", () => {
    // START_USER_CODE-USER_txtNetWtExclLSK_TEST
    // END_USER_CODE-USER_txtNetWtExclLSK_TEST
  });
  test("txtOI(Textbox Widget) Test Cases", async () => {
    const txtOI = screen.getByTestId("txtOI");
    expect(txtOI.tagName).toBe("INPUT");
    expect(txtOI.type).toBe("text");
    expect(txtOI.classList).toContain("textboxWidgetClass");
    expect(txtOI.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtOI")
    );
    await act(async () => {
      userEvent.type(txtOI, "1");
    });
  });
  test("Custom Test Cases for txtOI", () => {
    // START_USER_CODE-USER_txtOI_TEST
    // END_USER_CODE-USER_txtOI_TEST
  });
  test("txtOptionPayment(Textbox Widget) Test Cases", async () => {
    const txtOptionPayment = screen.getByTestId("txtOptionPayment");
    expect(txtOptionPayment.tagName).toBe("INPUT");
    expect(txtOptionPayment.type).toBe("text");
    expect(txtOptionPayment.classList).toContain("textboxWidgetClass");
    expect(txtOptionPayment.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtOptionPayment")
    );
    await act(async () => {
      userEvent.type(txtOptionPayment, "123");
    });
    expect(txtOptionPayment.getAttribute("value")).toBe("");
    expect(txtOptionPayment.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOptionPayment", () => {
    // START_USER_CODE-USER_txtOptionPayment_TEST
    // END_USER_CODE-USER_txtOptionPayment_TEST
  });
  test("txtOptionPriceStrg(Textbox Widget) Test Cases", async () => {
    const txtOptionPriceStrg = screen.getByTestId("txtOptionPriceStrg");
    expect(txtOptionPriceStrg.tagName).toBe("INPUT");
    expect(txtOptionPriceStrg.type).toBe("text");
    expect(txtOptionPriceStrg.classList).toContain("textboxWidgetClass");
    expect(txtOptionPriceStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtOptionPriceStrg")
    );
    await act(async () => {
      userEvent.type(txtOptionPriceStrg, "123");
    });
    expect(txtOptionPriceStrg.getAttribute("value")).toBe("");
    expect(txtOptionPriceStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOptionPriceStrg", () => {
    // START_USER_CODE-USER_txtOptionPriceStrg_TEST
    // END_USER_CODE-USER_txtOptionPriceStrg_TEST
  });
  test("txtPctOfSupportPrchs(Textbox Widget) Test Cases", async () => {
    const txtPctOfSupportPrchs = screen.getByTestId("txtPctOfSupportPrchs");
    expect(txtPctOfSupportPrchs.tagName).toBe("INPUT");
    expect(txtPctOfSupportPrchs.type).toBe("text");
    expect(txtPctOfSupportPrchs.classList).toContain("textboxWidgetClass");
    expect(txtPctOfSupportPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtPctOfSupportPrchs")
    );
    await act(async () => {
      userEvent.type(txtPctOfSupportPrchs, "123");
    });
    expect(txtPctOfSupportPrchs.getAttribute("value")).toBe("");
    expect(txtPctOfSupportPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctOfSupportPrchs", () => {
    // START_USER_CODE-USER_txtPctOfSupportPrchs_TEST
    // END_USER_CODE-USER_txtPctOfSupportPrchs_TEST
  });
  test("txtPctOfSupportStrg(Textbox Widget) Test Cases", async () => {
    const txtPctOfSupportStrg = screen.getByTestId("txtPctOfSupportStrg");
    expect(txtPctOfSupportStrg.tagName).toBe("INPUT");
    expect(txtPctOfSupportStrg.type).toBe("text");
    expect(txtPctOfSupportStrg.classList).toContain("textboxWidgetClass");
    expect(txtPctOfSupportStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtPctOfSupportStrg")
    );
    await act(async () => {
      userEvent.type(txtPctOfSupportStrg, "123");
    });
    expect(txtPctOfSupportStrg.getAttribute("value")).toBe("");
    expect(txtPctOfSupportStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctOfSupportStrg", () => {
    // START_USER_CODE-USER_txtPctOfSupportStrg_TEST
    // END_USER_CODE-USER_txtPctOfSupportStrg_TEST
  });
  test("txtPounds0(Textbox Widget) Test Cases", async () => {
    const txtPounds0 = screen.getByTestId("txtPounds0");
    expect(txtPounds0.tagName).toBe("INPUT");
    expect(txtPounds0.type).toBe("text");
    expect(txtPounds0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds0, "123");
    });
    expect(txtPounds0.getAttribute("value")).toBe("");
    expect(txtPounds0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds0", () => {
    // START_USER_CODE-USER_txtPounds0_TEST
    // END_USER_CODE-USER_txtPounds0_TEST
  });
  test("txtPounds1(Textbox Widget) Test Cases", async () => {
    const txtPounds1 = screen.getByTestId("txtPounds1");
    expect(txtPounds1.tagName).toBe("INPUT");
    expect(txtPounds1.type).toBe("text");
    expect(txtPounds1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds1, "123");
    });
    expect(txtPounds1.getAttribute("value")).toBe("");
    expect(txtPounds1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds1", () => {
    // START_USER_CODE-USER_txtPounds1_TEST
    // END_USER_CODE-USER_txtPounds1_TEST
  });
  test("txtPounds2(Textbox Widget) Test Cases", async () => {
    const txtPounds2 = screen.getByTestId("txtPounds2");
    expect(txtPounds2.tagName).toBe("INPUT");
    expect(txtPounds2.type).toBe("text");
    expect(txtPounds2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds2, "123");
    });
    expect(txtPounds2.getAttribute("value")).toBe("");
    expect(txtPounds2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds2", () => {
    // START_USER_CODE-USER_txtPounds2_TEST
    // END_USER_CODE-USER_txtPounds2_TEST
  });
  test("txtPounds3(Textbox Widget) Test Cases", async () => {
    const txtPounds3 = screen.getByTestId("txtPounds3");
    expect(txtPounds3.tagName).toBe("INPUT");
    expect(txtPounds3.type).toBe("text");
    expect(txtPounds3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds3, "123");
    });
    expect(txtPounds3.getAttribute("value")).toBe("");
    expect(txtPounds3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds3", () => {
    // START_USER_CODE-USER_txtPounds3_TEST
    // END_USER_CODE-USER_txtPounds3_TEST
  });
  test("txtPremiumsPrchs(Textbox Widget) Test Cases", async () => {
    const txtPremiumsPrchs = screen.getByTestId("txtPremiumsPrchs");
    expect(txtPremiumsPrchs.tagName).toBe("INPUT");
    expect(txtPremiumsPrchs.type).toBe("text");
    expect(txtPremiumsPrchs.classList).toContain("textboxWidgetClass");
    expect(txtPremiumsPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtPremiumsPrchs")
    );
    await act(async () => {
      userEvent.type(txtPremiumsPrchs, "123");
    });
    expect(txtPremiumsPrchs.getAttribute("value")).toBe("");
    expect(txtPremiumsPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPremiumsPrchs", () => {
    // START_USER_CODE-USER_txtPremiumsPrchs_TEST
    // END_USER_CODE-USER_txtPremiumsPrchs_TEST
  });
  test("txtProceedsPrchs(Textbox Widget) Test Cases", async () => {
    const txtProceedsPrchs = screen.getByTestId("txtProceedsPrchs");
    expect(txtProceedsPrchs.tagName).toBe("INPUT");
    expect(txtProceedsPrchs.type).toBe("text");
    expect(txtProceedsPrchs.classList).toContain("textboxWidgetClass");
    expect(txtProceedsPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtProceedsPrchs")
    );
    await act(async () => {
      userEvent.type(txtProceedsPrchs, "123");
    });
    expect(txtProceedsPrchs.getAttribute("value")).toBe("");
    expect(txtProceedsPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProceedsPrchs", () => {
    // START_USER_CODE-USER_txtProceedsPrchs_TEST
    // END_USER_CODE-USER_txtProceedsPrchs_TEST
  });
  test("txtProceedsStrg(Textbox Widget) Test Cases", async () => {
    const txtProceedsStrg = screen.getByTestId("txtProceedsStrg");
    expect(txtProceedsStrg.tagName).toBe("INPUT");
    expect(txtProceedsStrg.type).toBe("text");
    expect(txtProceedsStrg.classList).toContain("textboxWidgetClass");
    expect(txtProceedsStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtProceedsStrg")
    );
    await act(async () => {
      userEvent.type(txtProceedsStrg, "123");
    });
    expect(txtProceedsStrg.getAttribute("value")).toBe("");
    expect(txtProceedsStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProceedsStrg", () => {
    // START_USER_CODE-USER_txtProceedsStrg_TEST
    // END_USER_CODE-USER_txtProceedsStrg_TEST
  });
  test("txtRemitTo0(Textbox Widget) Test Cases", async () => {
    const txtRemitTo0 = screen.getByTestId("txtRemitTo0");
    expect(txtRemitTo0.tagName).toBe("INPUT");
    expect(txtRemitTo0.type).toBe("text");
    expect(txtRemitTo0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo0, "123");
    });
    expect(txtRemitTo0.getAttribute("value")).toBe("");
    expect(txtRemitTo0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRemitTo0", () => {
    // START_USER_CODE-USER_txtRemitTo0_TEST
    // END_USER_CODE-USER_txtRemitTo0_TEST
  });
  test("txtRemitTo1(Textbox Widget) Test Cases", async () => {
    const txtRemitTo1 = screen.getByTestId("txtRemitTo1");
    expect(txtRemitTo1.tagName).toBe("INPUT");
    expect(txtRemitTo1.type).toBe("text");
    expect(txtRemitTo1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo1, "123");
    });
    expect(txtRemitTo1.getAttribute("value")).toBe("");
    expect(txtRemitTo1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRemitTo1", () => {
    // START_USER_CODE-USER_txtRemitTo1_TEST
    // END_USER_CODE-USER_txtRemitTo1_TEST
  });
  test("txtRemitTo2(Textbox Widget) Test Cases", async () => {
    const txtRemitTo2 = screen.getByTestId("txtRemitTo2");
    expect(txtRemitTo2.tagName).toBe("INPUT");
    expect(txtRemitTo2.type).toBe("text");
    expect(txtRemitTo2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo2, "123");
    });
    expect(txtRemitTo2.getAttribute("value")).toBe("");
    expect(txtRemitTo2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRemitTo2", () => {
    // START_USER_CODE-USER_txtRemitTo2_TEST
    // END_USER_CODE-USER_txtRemitTo2_TEST
  });
  test("txtRemitTo3(Textbox Widget) Test Cases", async () => {
    const txtRemitTo3 = screen.getByTestId("txtRemitTo3");
    expect(txtRemitTo3.tagName).toBe("INPUT");
    expect(txtRemitTo3.type).toBe("text");
    expect(txtRemitTo3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo3, "123");
    });
    expect(txtRemitTo3.getAttribute("value")).toBe("");
    expect(txtRemitTo3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtRemitTo3", () => {
    // START_USER_CODE-USER_txtRemitTo3_TEST
    // END_USER_CODE-USER_txtRemitTo3_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtSeedPremiumStrg(Textbox Widget) Test Cases", async () => {
    const txtSeedPremiumStrg = screen.getByTestId("txtSeedPremiumStrg");
    expect(txtSeedPremiumStrg.tagName).toBe("INPUT");
    expect(txtSeedPremiumStrg.type).toBe("text");
    expect(txtSeedPremiumStrg.classList).toContain("textboxWidgetClass");
    expect(txtSeedPremiumStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtSeedPremiumStrg")
    );
    await act(async () => {
      userEvent.type(txtSeedPremiumStrg, "123");
    });
    expect(txtSeedPremiumStrg.getAttribute("value")).toBe("");
    expect(txtSeedPremiumStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeedPremiumStrg", () => {
    // START_USER_CODE-USER_txtSeedPremiumStrg_TEST
    // END_USER_CODE-USER_txtSeedPremiumStrg_TEST
  });
  test("txtSharePct0(Textbox Widget) Test Cases", async () => {
    const txtSharePct0 = screen.getByTestId("txtSharePct0");
    expect(txtSharePct0.tagName).toBe("INPUT");
    expect(txtSharePct0.type).toBe("text");
    expect(txtSharePct0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct0, "123");
    });
    expect(txtSharePct0.getAttribute("value")).toBe("");
    expect(txtSharePct0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSharePct0", () => {
    // START_USER_CODE-USER_txtSharePct0_TEST
    // END_USER_CODE-USER_txtSharePct0_TEST
  });
  test("txtSharePct1(Textbox Widget) Test Cases", async () => {
    const txtSharePct1 = screen.getByTestId("txtSharePct1");
    expect(txtSharePct1.tagName).toBe("INPUT");
    expect(txtSharePct1.type).toBe("text");
    expect(txtSharePct1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1, "123");
    });
    expect(txtSharePct1.getAttribute("value")).toBe("");
    expect(txtSharePct1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSharePct1", () => {
    // START_USER_CODE-USER_txtSharePct1_TEST
    // END_USER_CODE-USER_txtSharePct1_TEST
  });
  test("txtSharePct2(Textbox Widget) Test Cases", async () => {
    const txtSharePct2 = screen.getByTestId("txtSharePct2");
    expect(txtSharePct2.tagName).toBe("INPUT");
    expect(txtSharePct2.type).toBe("text");
    expect(txtSharePct2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2, "123");
    });
    expect(txtSharePct2.getAttribute("value")).toBe("");
    expect(txtSharePct2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSharePct2", () => {
    // START_USER_CODE-USER_txtSharePct2_TEST
    // END_USER_CODE-USER_txtSharePct2_TEST
  });
  test("txtSharePct3(Textbox Widget) Test Cases", async () => {
    const txtSharePct3 = screen.getByTestId("txtSharePct3");
    expect(txtSharePct3.tagName).toBe("INPUT");
    expect(txtSharePct3.type).toBe("text");
    expect(txtSharePct3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3, "123");
    });
    expect(txtSharePct3.getAttribute("value")).toBe("");
    expect(txtSharePct3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSharePct3", () => {
    // START_USER_CODE-USER_txtSharePct3_TEST
    // END_USER_CODE-USER_txtSharePct3_TEST
  });
  test("txtTotalDiscountsTon(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsTon = screen.getByTestId("txtTotalDiscountsTon");
    expect(txtTotalDiscountsTon.tagName).toBe("INPUT");
    expect(txtTotalDiscountsTon.type).toBe("text");
    expect(txtTotalDiscountsTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalDiscountsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtTotalDiscountsTon")
    );
    await act(async () => {
      userEvent.type(txtTotalDiscountsTon, "1");
    });
  });
  test("Custom Test Cases for txtTotalDiscountsTon", () => {
    // START_USER_CODE-USER_txtTotalDiscountsTon_TEST
    // END_USER_CODE-USER_txtTotalDiscountsTon_TEST
  });
  test("txtTotalLoadVal(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadVal = screen.getByTestId("txtTotalLoadVal");
    expect(txtTotalLoadVal.tagName).toBe("INPUT");
    expect(txtTotalLoadVal.type).toBe("text");
    expect(txtTotalLoadVal.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadVal.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtTotalLoadVal")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadVal, "1");
    });
  });
  test("Custom Test Cases for txtTotalLoadVal", () => {
    // START_USER_CODE-USER_txtTotalLoadVal_TEST
    // END_USER_CODE-USER_txtTotalLoadVal_TEST
  });
  test("txtTotalLoadValuePrchs(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadValuePrchs = screen.getByTestId("txtTotalLoadValuePrchs");
    expect(txtTotalLoadValuePrchs.tagName).toBe("INPUT");
    expect(txtTotalLoadValuePrchs.type).toBe("text");
    expect(txtTotalLoadValuePrchs.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadValuePrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtTotalLoadValuePrchs")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadValuePrchs, "123");
    });
    expect(txtTotalLoadValuePrchs.getAttribute("value")).toBe("");
    expect(txtTotalLoadValuePrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadValuePrchs", () => {
    // START_USER_CODE-USER_txtTotalLoadValuePrchs_TEST
    // END_USER_CODE-USER_txtTotalLoadValuePrchs_TEST
  });
  test("txtTotalLoadValueStrg(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadValueStrg = screen.getByTestId("txtTotalLoadValueStrg");
    expect(txtTotalLoadValueStrg.tagName).toBe("INPUT");
    expect(txtTotalLoadValueStrg.type).toBe("text");
    expect(txtTotalLoadValueStrg.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadValueStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtTotalLoadValueStrg")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadValueStrg, "123");
    });
    expect(txtTotalLoadValueStrg.getAttribute("value")).toBe("");
    expect(txtTotalLoadValueStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadValueStrg", () => {
    // START_USER_CODE-USER_txtTotalLoadValueStrg_TEST
    // END_USER_CODE-USER_txtTotalLoadValueStrg_TEST
  });
  test("txtTotalTon(Textbox Widget) Test Cases", async () => {
    const txtTotalTon = screen.getByTestId("txtTotalTon");
    expect(txtTotalTon.tagName).toBe("INPUT");
    expect(txtTotalTon.type).toBe("text");
    expect(txtTotalTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalTon.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtTotalTon")
    );
    await act(async () => {
      userEvent.type(txtTotalTon, "1");
    });
  });
  test("Custom Test Cases for txtTotalTon", () => {
    // START_USER_CODE-USER_txtTotalTon_TEST
    // END_USER_CODE-USER_txtTotalTon_TEST
  });
  test("txtValPerPoundExclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundExclSLK = screen.getByTestId("txtValPerPoundExclSLK");
    expect(txtValPerPoundExclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundExclSLK.type).toBe("text");
    expect(txtValPerPoundExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtValPerPoundExclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundExclSLK, "1");
    });
  });
  test("Custom Test Cases for txtValPerPoundExclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundExclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundExclSLK_TEST
  });
  test("txtValPerPoundInclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundInclSLK = screen.getByTestId("txtValPerPoundInclSLK");
    expect(txtValPerPoundInclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundInclSLK.type).toBe("text");
    expect(txtValPerPoundInclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundInclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtValPerPoundInclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundInclSLK, "1");
    });
  });
  test("Custom Test Cases for txtValPerPoundInclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundInclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundInclSLK_TEST
  });
  test("txtValue0(Textbox Widget) Test Cases", async () => {
    const txtValue0 = screen.getByTestId("txtValue0");
    expect(txtValue0.tagName).toBe("INPUT");
    expect(txtValue0.type).toBe("text");
    expect(txtValue0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue0, "123");
    });
    expect(txtValue0.getAttribute("value")).toBe("");
    expect(txtValue0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue0", () => {
    // START_USER_CODE-USER_txtValue0_TEST
    // END_USER_CODE-USER_txtValue0_TEST
  });
  test("txtValue1(Textbox Widget) Test Cases", async () => {
    const txtValue1 = screen.getByTestId("txtValue1");
    expect(txtValue1.tagName).toBe("INPUT");
    expect(txtValue1.type).toBe("text");
    expect(txtValue1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue1, "123");
    });
    expect(txtValue1.getAttribute("value")).toBe("");
    expect(txtValue1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue1", () => {
    // START_USER_CODE-USER_txtValue1_TEST
    // END_USER_CODE-USER_txtValue1_TEST
  });
  test("txtValue2(Textbox Widget) Test Cases", async () => {
    const txtValue2 = screen.getByTestId("txtValue2");
    expect(txtValue2.tagName).toBe("INPUT");
    expect(txtValue2.type).toBe("text");
    expect(txtValue2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue2, "123");
    });
    expect(txtValue2.getAttribute("value")).toBe("");
    expect(txtValue2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue2", () => {
    // START_USER_CODE-USER_txtValue2_TEST
    // END_USER_CODE-USER_txtValue2_TEST
  });
  test("txtValue3(Textbox Widget) Test Cases", async () => {
    const txtValue3 = screen.getByTestId("txtValue3");
    expect(txtValue3.tagName).toBe("INPUT");
    expect(txtValue3.type).toBe("text");
    expect(txtValue3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue3, "123");
    });
    expect(txtValue3.getAttribute("value")).toBe("");
    expect(txtValue3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue3", () => {
    // START_USER_CODE-USER_txtValue3_TEST
    // END_USER_CODE-USER_txtValue3_TEST
  });
  test("txtValueOfOptionStrg(Textbox Widget) Test Cases", async () => {
    const txtValueOfOptionStrg = screen.getByTestId("txtValueOfOptionStrg");
    expect(txtValueOfOptionStrg.tagName).toBe("INPUT");
    expect(txtValueOfOptionStrg.type).toBe("text");
    expect(txtValueOfOptionStrg.classList).toContain("textboxWidgetClass");
    expect(txtValueOfOptionStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtValueOfOptionStrg")
    );
    await act(async () => {
      userEvent.type(txtValueOfOptionStrg, "123");
    });
    expect(txtValueOfOptionStrg.getAttribute("value")).toBe("");
    expect(txtValueOfOptionStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfOptionStrg", () => {
    // START_USER_CODE-USER_txtValueOfOptionStrg_TEST
    // END_USER_CODE-USER_txtValueOfOptionStrg_TEST
  });
  test("txtValueOfSegPrchs(Textbox Widget) Test Cases", async () => {
    const txtValueOfSegPrchs = screen.getByTestId("txtValueOfSegPrchs");
    expect(txtValueOfSegPrchs.tagName).toBe("INPUT");
    expect(txtValueOfSegPrchs.type).toBe("text");
    expect(txtValueOfSegPrchs.classList).toContain("textboxWidgetClass");
    expect(txtValueOfSegPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtValueOfSegPrchs")
    );
    await act(async () => {
      userEvent.type(txtValueOfSegPrchs, "123");
    });
    expect(txtValueOfSegPrchs.getAttribute("value")).toBe("");
    expect(txtValueOfSegPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfSegPrchs", () => {
    // START_USER_CODE-USER_txtValueOfSegPrchs_TEST
    // END_USER_CODE-USER_txtValueOfSegPrchs_TEST
  });
  test("txtVendor0(Textbox Widget) Test Cases", async () => {
    const txtVendor0 = screen.getByTestId("txtVendor0");
    expect(txtVendor0.tagName).toBe("INPUT");
    expect(txtVendor0.type).toBe("text");
    expect(txtVendor0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor0, "123");
    });
    expect(txtVendor0.getAttribute("value")).toBe("");
    expect(txtVendor0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor0", () => {
    // START_USER_CODE-USER_txtVendor0_TEST
    // END_USER_CODE-USER_txtVendor0_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "123");
    });
    expect(txtVendor1.getAttribute("value")).toBe("");
    expect(txtVendor1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "123");
    });
    expect(txtVendor2.getAttribute("value")).toBe("");
    expect(txtVendor2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "123");
    });
    expect(txtVendor3.getAttribute("value")).toBe("");
    expect(txtVendor3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtWtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicle = screen.getByTestId("txtWtIncludingVehicle");
    expect(txtWtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicle.type).toBe("text");
    expect(txtWtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtWtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicle, "1");
    });
  });
  test("Custom Test Cases for txtWtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicle_TEST
  });
  test("txtWtlessFM(Textbox Widget) Test Cases", async () => {
    const txtWtlessFM = screen.getByTestId("txtWtlessFM");
    expect(txtWtlessFM.tagName).toBe("INPUT");
    expect(txtWtlessFM.type).toBe("text");
    expect(txtWtlessFM.classList).toContain("textboxWidgetClass");
    expect(txtWtlessFM.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtWtlessFM")
    );
    await act(async () => {
      userEvent.type(txtWtlessFM, "1");
    });
  });
  test("Custom Test Cases for txtWtlessFM", () => {
    // START_USER_CODE-USER_txtWtlessFM_TEST
    // END_USER_CODE-USER_txtWtlessFM_TEST
  });
  test("txtWtOfVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicle = screen.getByTestId("txtWtOfVehicle");
    expect(txtWtOfVehicle.tagName).toBe("INPUT");
    expect(txtWtOfVehicle.type).toBe("text");
    expect(txtWtOfVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:1007Settlement_txtWtOfVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicle, "1");
    });
  });
  test("Custom Test Cases for txtWtOfVehicle", () => {
    // START_USER_CODE-USER_txtWtOfVehicle_TEST
    // END_USER_CODE-USER_txtWtOfVehicle_TEST
  });
});
