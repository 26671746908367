/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  TextAreaWidget,
} from "../../shared/WindowImports";

import "./ContractExceptPremSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractExceptPremSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractExceptionPremiumProfile",
    windowName: "ContractExceptPremSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractExceptPremSetup",
    // START_USER_CODE-USER_ContractExceptPremSetup_PROPERTIES

    // END_USER_CODE-USER_ContractExceptPremSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxAddCancel",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxAddCancel",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    ddDelLoadType: {
      name: "ddDelLoadType",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Delivery Load Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDelLoadType_PROPERTIES

      // END_USER_CODE-USER_ddDelLoadType_PROPERTIES
    },
    ddExcPrmCd: {
      name: "ddExcPrmCd",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Exception Premium Code:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddExcPrmCd_PROPERTIES

      // END_USER_CODE-USER_ddExcPrmCd_PROPERTIES
    },
    ddRateBase: {
      name: "ddRateBase",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Rate Base:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateBase_PROPERTIES

      // END_USER_CODE-USER_ddRateBase_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxAddCancel",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxBuyingPoint",
      Label: "Buying Point: ",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxAddCancel",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    txtarExplanation: {
      name: "txtarExplanation",
      type: "TextAreaWidget",
      parent: "grpbxBuyingPoint",
      Label: "Explanation:",
      ColSpan: "2",
      RowSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarExplanation_PROPERTIES

      // END_USER_CODE-USER_txtarExplanation_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "Rate:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRate_PROPERTIES

      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    txtRateBase: {
      name: "txtRateBase",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRateBase_PROPERTIES

      // END_USER_CODE-USER_txtRateBase_PROPERTIES
    },
    grpbxBuyingPoint: {
      name: "grpbxBuyingPoint",
      type: "GroupBoxWidget",
      parent: "ContractExceptPremSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES
    },
    grpbxAddCancel: {
      name: "grpbxAddCancel",
      type: "GroupBoxWidget",
      parent: "ContractExceptPremSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAddCancel_PROPERTIES

      // END_USER_CODE-USER_grpbxAddCancel_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const onbtnCancelClick = () => {
    try {
      //START_USER_CODE-2e789b6ebfef9bbc-1e58f10319762074/ContractManagement_cd8cccccfb81b3c0_4e7ede88e89e5111_PreSubmit_EventCode.js

      //END_USER_CODE-2e789b6ebfef9bbc-1e58f10319762074/ContractManagement_cd8cccccfb81b3c0_4e7ede88e89e5111_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // START_USER_CODE-USER_METHODS
  // const onbtnCancelClick = () => {
  //   try {
  //     //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  //     // Modal.close();
  //     document.getElementsByClassName("close")[0].click();
  document.getElementById("ContractManagement_ContractExceptionPremiumSetupPopUp").childNodes[0].click();
  //     //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  //   } catch (err) {
  //     if (err instanceof EvalError) {
  //       showMessage(thisObj, err.message);
  //     } else {
  //       showMessage(
  //         thisObj,
  //         "Exception in PreSubmit Event code for widget:btnCancel event:Click"
  //       );
  //     }
  //     return false;
  //   }
  //   return true;
  // };
  // thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractExceptPremSetup*/}

              {/* END_USER_CODE-USER_BEFORE_ContractExceptPremSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

              <GroupBoxWidget conf={state.grpbxBuyingPoint} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddExcPrmCd*/}

                {/* END_USER_CODE-USER_BEFORE_ddExcPrmCd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddExcPrmCd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddExcPrmCd*/}

                {/* END_USER_CODE-USER_AFTER_ddExcPrmCd*/}
                {/* START_USER_CODE-USER_BEFORE_txtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRate*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateBase*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRateBase}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateBase*/}

                {/* END_USER_CODE-USER_AFTER_ddRateBase*/}
                {/* START_USER_CODE-USER_BEFORE_txtRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_txtRateBase*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRateBase}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRateBase*/}

                {/* END_USER_CODE-USER_AFTER_txtRateBase*/}
                {/* START_USER_CODE-USER_BEFORE_ddDelLoadType*/}

                {/* END_USER_CODE-USER_BEFORE_ddDelLoadType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDelLoadType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDelLoadType*/}

                {/* END_USER_CODE-USER_AFTER_ddDelLoadType*/}
                {/* START_USER_CODE-USER_BEFORE_txtarExplanation*/}

                {/* END_USER_CODE-USER_BEFORE_txtarExplanation*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarExplanation}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarExplanation*/}

                {/* END_USER_CODE-USER_AFTER_txtarExplanation*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAddCancel*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAddCancel*/}

              <GroupBoxWidget conf={state.grpbxAddCancel} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAddCancel*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAddCancel*/}

              {/* START_USER_CODE-USER_AFTER_ContractExceptPremSetup*/}

              {/* END_USER_CODE-USER_AFTER_ContractExceptPremSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractExceptPremSetup);
