/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_CureCleanScheduleProfile from "./CureCleanScheduleProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CureCleanScheduleProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_CureCleanScheduleProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is CureCleanScheduleProfile Loads Successfully", () => {
    expect(screen.getByText("CureCleanScheduleProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for CureCleanScheduleProfile", () => {
    // START_USER_CODE-USER_CureCleanScheduleProfile_Custom_Test_Case
    // END_USER_CODE-USER_CureCleanScheduleProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_CureCleanScheduleProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CureCleanScheduleProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CureCleanScheduleProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnPrintuic0(Button Widget) Test Cases", async () => {
    const btnPrintuic0 = screen.getByTestId("btnPrintuic0");
    expect(btnPrintuic0).toBeInTheDocument;
    expect(btnPrintuic0.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_btnPrintuic0"
      )
    );
  });
  test("Custom Test Cases for btnPrintuic0", () => {
    // START_USER_CODE-USER_btnPrintuic0_TEST
    // END_USER_CODE-USER_btnPrintuic0_TEST
  });
  test("gridPrint(Grid Widget) Test Cases", async () => {
    let gridPrint = screen.getByTestId("gridPrint");
    let gridPrintbtn = gridPrint.nextElementSibling.firstElementChild;
    gridPrint = gridPrint.parentElement.parentElement.parentElement;
    expect(gridPrint.tagName).toBe("DIV");
    expect(gridPrint.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridPrint"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPrint", () => {
    // START_USER_CODE-USER_gridPrint_TEST
    // END_USER_CODE-USER_gridPrint_TEST
  });
  test("gridRates(Grid Widget) Test Cases", async () => {
    let gridRates = screen.getByTestId("gridRates");
    let gridRatesbtn = gridRates.nextElementSibling.firstElementChild;
    gridRates = gridRates.parentElement.parentElement.parentElement;
    expect(gridRates.tagName).toBe("DIV");
    expect(gridRates.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridRates"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridRates", () => {
    // START_USER_CODE-USER_gridRates_TEST
    // END_USER_CODE-USER_gridRates_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCureCleanScheduleProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxCureCleanScheduleProfile = screen.getByTestId(
      "grpbxCureCleanScheduleProfile"
    );
    expect(grpbxCureCleanScheduleProfile.tagName).toBe("BUTTON");
    expect(grpbxCureCleanScheduleProfile.type).toBe("button");
    expect(grpbxCureCleanScheduleProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCureCleanScheduleProfile", () => {
    // START_USER_CODE-USER_grpbxCureCleanScheduleProfile_TEST
    // END_USER_CODE-USER_grpbxCureCleanScheduleProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CureCleanScheduleProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_lblBuyingPoint"
      )
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblBuyingPointValue(Label Widget) Test Cases", async () => {
    const lblBuyingPointValue = screen.getByTestId("lblBuyingPointValue");
    expect(lblBuyingPointValue.tagName).toBe("LABEL");
    expect(lblBuyingPointValue.classList).toContain("form-label");
    expect(lblBuyingPointValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_lblBuyingPointValue"
      )
    );
  });
  test("Custom Test Cases for lblBuyingPointValue", () => {
    // START_USER_CODE-USER_lblBuyingPointValue_TEST
    // END_USER_CODE-USER_lblBuyingPointValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("gridRates_txtBaseRate(Grid Widget) Test Cases", async () => {
    let gridRates_txtBaseRate = screen.getByTestId("gridRates");
    let gridRates_txtBaseRatebtn =
      gridRates_txtBaseRate.nextElementSibling.firstElementChild;
    gridRates_txtBaseRate =
      gridRates_txtBaseRate.parentElement.parentElement.parentElement;
    expect(gridRates_txtBaseRate.tagName).toBe("DIV");
    expect(gridRates_txtBaseRate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridRates"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtBaseRate", () => {
    // START_USER_CODE-USER_txtBaseRate_TEST
    // END_USER_CODE-USER_txtBaseRate_TEST
  });
  test("txtCode(Textbox Widget) Test Cases", async () => {
    const txtCode = screen.getByTestId("txtCode");
    expect(txtCode.tagName).toBe("INPUT");
    expect(txtCode.type).toBe("text");
    expect(txtCode.classList).toContain("textboxWidgetClass");
    expect(txtCode.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CureCleanScheduleProfile_txtCode")
    );
    await act(async () => {
      userEvent.type(txtCode, "1");
    });
  });
  test("Custom Test Cases for txtCode", () => {
    // START_USER_CODE-USER_txtCode_TEST
    // END_USER_CODE-USER_txtCode_TEST
  });
  test("txtDeduction(Textbox Widget) Test Cases", async () => {
    const txtDeduction = screen.getByTestId("txtDeduction");
    expect(txtDeduction.tagName).toBe("INPUT");
    expect(txtDeduction.type).toBe("text");
    expect(txtDeduction.classList).toContain("textboxWidgetClass");
    expect(txtDeduction.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_txtDeduction"
      )
    );
    await act(async () => {
      userEvent.type(txtDeduction, "1");
    });
  });
  test("Custom Test Cases for txtDeduction", () => {
    // START_USER_CODE-USER_txtDeduction_TEST
    // END_USER_CODE-USER_txtDeduction_TEST
  });
  test("txtDiscription(Textbox Widget) Test Cases", async () => {
    const txtDiscription = screen.getByTestId("txtDiscription");
    expect(txtDiscription.tagName).toBe("INPUT");
    expect(txtDiscription.type).toBe("text");
    expect(txtDiscription.classList).toContain("textboxWidgetClass");
    expect(txtDiscription.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_txtDiscription"
      )
    );
    await act(async () => {
      userEvent.type(txtDiscription, "1");
    });
  });
  test("Custom Test Cases for txtDiscription", () => {
    // START_USER_CODE-USER_txtDiscription_TEST
    // END_USER_CODE-USER_txtDiscription_TEST
  });
  test("gridRates_txtHighMoisture(Grid Widget) Test Cases", async () => {
    let gridRates_txtHighMoisture = screen.getByTestId("gridRates");
    let gridRates_txtHighMoisturebtn =
      gridRates_txtHighMoisture.nextElementSibling.firstElementChild;
    gridRates_txtHighMoisture =
      gridRates_txtHighMoisture.parentElement.parentElement.parentElement;
    expect(gridRates_txtHighMoisture.tagName).toBe("DIV");
    expect(gridRates_txtHighMoisture.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridRates"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtHighMoisture", () => {
    // START_USER_CODE-USER_txtHighMoisture_TEST
    // END_USER_CODE-USER_txtHighMoisture_TEST
  });
  test("gridRates_txtLowMoisture(Grid Widget) Test Cases", async () => {
    let gridRates_txtLowMoisture = screen.getByTestId("gridRates");
    let gridRates_txtLowMoisturebtn =
      gridRates_txtLowMoisture.nextElementSibling.firstElementChild;
    gridRates_txtLowMoisture =
      gridRates_txtLowMoisture.parentElement.parentElement.parentElement;
    expect(gridRates_txtLowMoisture.tagName).toBe("DIV");
    expect(gridRates_txtLowMoisture.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridRates"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtLowMoisture", () => {
    // START_USER_CODE-USER_txtLowMoisture_TEST
    // END_USER_CODE-USER_txtLowMoisture_TEST
  });
  test("gridPrint_txtPrint1(Grid Widget) Test Cases", async () => {
    let gridPrint_txtPrint1 = screen.getByTestId("gridPrint");
    let gridPrint_txtPrint1btn =
      gridPrint_txtPrint1.nextElementSibling.firstElementChild;
    gridPrint_txtPrint1 =
      gridPrint_txtPrint1.parentElement.parentElement.parentElement;
    expect(gridPrint_txtPrint1.tagName).toBe("DIV");
    expect(gridPrint_txtPrint1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridPrint"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPrint1", () => {
    // START_USER_CODE-USER_txtPrint1_TEST
    // END_USER_CODE-USER_txtPrint1_TEST
  });
  test("gridPrint_txtPrint2(Grid Widget) Test Cases", async () => {
    let gridPrint_txtPrint2 = screen.getByTestId("gridPrint");
    let gridPrint_txtPrint2btn =
      gridPrint_txtPrint2.nextElementSibling.firstElementChild;
    gridPrint_txtPrint2 =
      gridPrint_txtPrint2.parentElement.parentElement.parentElement;
    expect(gridPrint_txtPrint2.tagName).toBe("DIV");
    expect(gridPrint_txtPrint2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridPrint"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPrint2", () => {
    // START_USER_CODE-USER_txtPrint2_TEST
    // END_USER_CODE-USER_txtPrint2_TEST
  });
  test("gridPrint_txtPrint3(Grid Widget) Test Cases", async () => {
    let gridPrint_txtPrint3 = screen.getByTestId("gridPrint");
    let gridPrint_txtPrint3btn =
      gridPrint_txtPrint3.nextElementSibling.firstElementChild;
    gridPrint_txtPrint3 =
      gridPrint_txtPrint3.parentElement.parentElement.parentElement;
    expect(gridPrint_txtPrint3.tagName).toBe("DIV");
    expect(gridPrint_txtPrint3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridPrint"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPrint3", () => {
    // START_USER_CODE-USER_txtPrint3_TEST
    // END_USER_CODE-USER_txtPrint3_TEST
  });
  test("gridPrint_txtPrint4(Grid Widget) Test Cases", async () => {
    let gridPrint_txtPrint4 = screen.getByTestId("gridPrint");
    let gridPrint_txtPrint4btn =
      gridPrint_txtPrint4.nextElementSibling.firstElementChild;
    gridPrint_txtPrint4 =
      gridPrint_txtPrint4.parentElement.parentElement.parentElement;
    expect(gridPrint_txtPrint4.tagName).toBe("DIV");
    expect(gridPrint_txtPrint4.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridPrint"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPrint4", () => {
    // START_USER_CODE-USER_txtPrint4_TEST
    // END_USER_CODE-USER_txtPrint4_TEST
  });
  test("gridPrint_txtPrint5(Grid Widget) Test Cases", async () => {
    let gridPrint_txtPrint5 = screen.getByTestId("gridPrint");
    let gridPrint_txtPrint5btn =
      gridPrint_txtPrint5.nextElementSibling.firstElementChild;
    gridPrint_txtPrint5 =
      gridPrint_txtPrint5.parentElement.parentElement.parentElement;
    expect(gridPrint_txtPrint5.tagName).toBe("DIV");
    expect(gridPrint_txtPrint5.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:CureCleanScheduleProfile_gridPrint"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPrint5", () => {
    // START_USER_CODE-USER_txtPrint5_TEST
    // END_USER_CODE-USER_txtPrint5_TEST
  });
});
