/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile from "./PremiumDeductionScheduleProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionScheduleProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionScheduleProfile Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionScheduleProfile"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionScheduleProfile", () => {
    // START_USER_CODE-USER_PremiumDeductionScheduleProfile_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionScheduleProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PremiumDeductionScheduleProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAddContract(Button Widget) Test Cases", async () => {
    const btnAddContract = screen.getByTestId("btnAddContract");
    expect(btnAddContract).toBeInTheDocument;
    expect(btnAddContract.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnAddContract"
      )
    );
  });
  test("Custom Test Cases for btnAddContract", () => {
    // START_USER_CODE-USER_btnAddContract_TEST
    // END_USER_CODE-USER_btnAddContract_TEST
  });
  test("btnAddVendor(Button Widget) Test Cases", async () => {
    const btnAddVendor = screen.getByTestId("btnAddVendor");
    expect(btnAddVendor).toBeInTheDocument;
    expect(btnAddVendor.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnAddVendor"
      )
    );
  });
  test("Custom Test Cases for btnAddVendor", () => {
    // START_USER_CODE-USER_btnAddVendor_TEST
    // END_USER_CODE-USER_btnAddVendor_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnCancel"
      )
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btncmdEnableDisableControls(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls = screen.getByTestId(
      "btncmdEnableDisableControls"
    );
    expect(btncmdEnableDisableControls).toBeInTheDocument;
    expect(btncmdEnableDisableControls.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btncmdEnableDisableControls"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls_TEST
  });
  test("btnDeleteContract(Button Widget) Test Cases", async () => {
    const btnDeleteContract = screen.getByTestId("btnDeleteContract");
    expect(btnDeleteContract).toBeInTheDocument;
    expect(btnDeleteContract.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnDeleteContract"
      )
    );
  });
  test("Custom Test Cases for btnDeleteContract", () => {
    // START_USER_CODE-USER_btnDeleteContract_TEST
    // END_USER_CODE-USER_btnDeleteContract_TEST
  });
  test("btnDeleteVendor(Button Widget) Test Cases", async () => {
    const btnDeleteVendor = screen.getByTestId("btnDeleteVendor");
    expect(btnDeleteVendor).toBeInTheDocument;
    expect(btnDeleteVendor.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnDeleteVendor"
      )
    );
  });
  test("Custom Test Cases for btnDeleteVendor", () => {
    // START_USER_CODE-USER_btnDeleteVendor_TEST
    // END_USER_CODE-USER_btnDeleteVendor_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnOk"
      )
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnPayeeVendor(Button Widget) Test Cases", async () => {
    const btnPayeeVendor = screen.getByTestId("btnPayeeVendor");
    expect(btnPayeeVendor).toBeInTheDocument;
    expect(btnPayeeVendor.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_btnPayeeVendor"
      )
    );
  });
  test("Custom Test Cases for btnPayeeVendor", () => {
    // START_USER_CODE-USER_btnPayeeVendor_TEST
    // END_USER_CODE-USER_btnPayeeVendor_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxContracts(GroupBox Widget) Test Cases", async () => {
    const grpbxContracts = screen.getByTestId("grpbxContracts");
    expect(grpbxContracts.tagName).toBe("BUTTON");
    expect(grpbxContracts.type).toBe("button");
    expect(grpbxContracts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContracts", () => {
    // START_USER_CODE-USER_grpbxContracts_TEST
    // END_USER_CODE-USER_grpbxContracts_TEST
  });
  test("grpbxPayee(GroupBox Widget) Test Cases", async () => {
    const grpbxPayee = screen.getByTestId("grpbxPayee");
    expect(grpbxPayee.tagName).toBe("BUTTON");
    expect(grpbxPayee.type).toBe("button");
    expect(grpbxPayee.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPayee", () => {
    // START_USER_CODE-USER_grpbxPayee_TEST
    // END_USER_CODE-USER_grpbxPayee_TEST
  });
  test("grpbxPremiumDeductionScheduleProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionScheduleProfile = screen.getByTestId(
      "grpbxPremiumDeductionScheduleProfile"
    );
    expect(grpbxPremiumDeductionScheduleProfile.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionScheduleProfile.type).toBe("button");
    expect(grpbxPremiumDeductionScheduleProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionScheduleProfile", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionScheduleProfile_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionScheduleProfile_TEST
  });
  test("grpbxProfileDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxProfileDetails = screen.getByTestId("grpbxProfileDetails");
    expect(grpbxProfileDetails.tagName).toBe("BUTTON");
    expect(grpbxProfileDetails.type).toBe("button");
    expect(grpbxProfileDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxProfileDetails", () => {
    // START_USER_CODE-USER_grpbxProfileDetails_TEST
    // END_USER_CODE-USER_grpbxProfileDetails_TEST
  });
  test("grpbxVendors(GroupBox Widget) Test Cases", async () => {
    const grpbxVendors = screen.getByTestId("grpbxVendors");
    expect(grpbxVendors.tagName).toBe("BUTTON");
    expect(grpbxVendors.type).toBe("button");
    expect(grpbxVendors.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendors", () => {
    // START_USER_CODE-USER_grpbxVendors_TEST
    // END_USER_CODE-USER_grpbxVendors_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblContracts(Label Widget) Test Cases", async () => {
    const lblContracts = screen.getByTestId("lblContracts");
    expect(lblContracts.tagName).toBe("LABEL");
    expect(lblContracts.classList).toContain("form-label");
    expect(lblContracts.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_lblContracts"
      )
    );
  });
  test("Custom Test Cases for lblContracts", () => {
    // START_USER_CODE-USER_lblContracts_TEST
    // END_USER_CODE-USER_lblContracts_TEST
  });
  test("lblVendors(Label Widget) Test Cases", async () => {
    const lblVendors = screen.getByTestId("lblVendors");
    expect(lblVendors.tagName).toBe("LABEL");
    expect(lblVendors.classList).toContain("form-label");
    expect(lblVendors.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_lblVendors"
      )
    );
  });
  test("Custom Test Cases for lblVendors", () => {
    // START_USER_CODE-USER_lblVendors_TEST
    // END_USER_CODE-USER_lblVendors_TEST
  });
  test("txtApplyOrder(Textbox Widget) Test Cases", async () => {
    const txtApplyOrder = screen.getByTestId("txtApplyOrder");
    expect(txtApplyOrder.tagName).toBe("INPUT");
    expect(txtApplyOrder.type).toBe("text");
    expect(txtApplyOrder.classList).toContain("textboxWidgetClass");
    expect(txtApplyOrder.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_txtApplyOrder"
      )
    );
    await act(async () => {
      userEvent.type(txtApplyOrder, "1");
    });
  });
  test("Custom Test Cases for txtApplyOrder", () => {
    // START_USER_CODE-USER_txtApplyOrder_TEST
    // END_USER_CODE-USER_txtApplyOrder_TEST
  });
  test("txtBasePercentage(Textbox Widget) Test Cases", async () => {
    const txtBasePercentage = screen.getByTestId("txtBasePercentage");
    expect(txtBasePercentage.tagName).toBe("INPUT");
    expect(txtBasePercentage.type).toBe("text");
    expect(txtBasePercentage.classList).toContain("textboxWidgetClass");
    expect(txtBasePercentage.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_txtBasePercentage"
      )
    );
    await act(async () => {
      userEvent.type(txtBasePercentage, "1");
    });
  });
  test("Custom Test Cases for txtBasePercentage", () => {
    // START_USER_CODE-USER_txtBasePercentage_TEST
    // END_USER_CODE-USER_txtBasePercentage_TEST
  });
  test("txtBaseRate(Textbox Widget) Test Cases", async () => {
    const txtBaseRate = screen.getByTestId("txtBaseRate");
    expect(txtBaseRate.tagName).toBe("INPUT");
    expect(txtBaseRate.type).toBe("text");
    expect(txtBaseRate.classList).toContain("textboxWidgetClass");
    expect(txtBaseRate.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_txtBaseRate"
      )
    );
    await act(async () => {
      userEvent.type(txtBaseRate, "1");
    });
  });
  test("Custom Test Cases for txtBaseRate", () => {
    // START_USER_CODE-USER_txtBaseRate_TEST
    // END_USER_CODE-USER_txtBaseRate_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_txtBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtPayeeVendor(Textbox Widget) Test Cases", async () => {
    const txtPayeeVendor = screen.getByTestId("txtPayeeVendor");
    expect(txtPayeeVendor.tagName).toBe("INPUT");
    expect(txtPayeeVendor.type).toBe("text");
    expect(txtPayeeVendor.classList).toContain("textboxWidgetClass");
    expect(txtPayeeVendor.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleProfile_txtPayeeVendor"
      )
    );
    await act(async () => {
      userEvent.type(txtPayeeVendor, "1");
    });
  });
  test("Custom Test Cases for txtPayeeVendor", () => {
    // START_USER_CODE-USER_txtPayeeVendor_TEST
    // END_USER_CODE-USER_txtPayeeVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
