/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FarmSetup from "./FarmSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmSetup />);
    });
  });
  afterEach(cleanup);
  test("is FarmSetup Loads Successfully", () => {
    expect(screen.getByText("FarmSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmSetup", () => {
    // START_USER_CODE-USER_FarmSetup_Custom_Test_Case
    // END_USER_CODE-USER_FarmSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmSetup />);
    });
  });
  afterEach(cleanup);
  test("btnAddFarm(Button Widget) Test Cases", async () => {
    const btnAddFarm = screen.getByTestId("btnAddFarm");
    expect(btnAddFarm).toBeInTheDocument;
    expect(btnAddFarm.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnAddFarm")
    );
  });
  test("Custom Test Cases for btnAddFarm", () => {
    // START_USER_CODE-USER_btnAddFarm_TEST
    // END_USER_CODE-USER_btnAddFarm_TEST
  });
  test("btnAddVendor(Button Widget) Test Cases", async () => {
    const btnAddVendor = screen.getByTestId("btnAddVendor");
    expect(btnAddVendor).toBeInTheDocument;
    expect(btnAddVendor.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnAddVendor")
    );
  });
  test("Custom Test Cases for btnAddVendor", () => {
    // START_USER_CODE-USER_btnAddVendor_TEST
    // END_USER_CODE-USER_btnAddVendor_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDeleteFarm(Button Widget) Test Cases", async () => {
    const btnDeleteFarm = screen.getByTestId("btnDeleteFarm");
    expect(btnDeleteFarm).toBeInTheDocument;
    expect(btnDeleteFarm.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnDeleteFarm")
    );
  });
  test("Custom Test Cases for btnDeleteFarm", () => {
    // START_USER_CODE-USER_btnDeleteFarm_TEST
    // END_USER_CODE-USER_btnDeleteFarm_TEST
  });
  test("btnDeleteVendor(Button Widget) Test Cases", async () => {
    const btnDeleteVendor = screen.getByTestId("btnDeleteVendor");
    expect(btnDeleteVendor).toBeInTheDocument;
    expect(btnDeleteVendor.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnDeleteVendor")
    );
  });
  test("Custom Test Cases for btnDeleteVendor", () => {
    // START_USER_CODE-USER_btnDeleteVendor_TEST
    // END_USER_CODE-USER_btnDeleteVendor_TEST
  });
  test("btnDown(Button Widget) Test Cases", async () => {
    const btnDown = screen.getByTestId("btnDown");
    expect(btnDown).toBeInTheDocument;
    expect(btnDown.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnDown")
    );
  });
  test("Custom Test Cases for btnDown", () => {
    // START_USER_CODE-USER_btnDown_TEST
    // END_USER_CODE-USER_btnDown_TEST
  });
  test("btnEditFarm(Button Widget) Test Cases", async () => {
    const btnEditFarm = screen.getByTestId("btnEditFarm");
    expect(btnEditFarm).toBeInTheDocument;
    expect(btnEditFarm.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnEditFarm")
    );
  });
  test("Custom Test Cases for btnEditFarm", () => {
    // START_USER_CODE-USER_btnEditFarm_TEST
    // END_USER_CODE-USER_btnEditFarm_TEST
  });
  test("btnEditVendor(Button Widget) Test Cases", async () => {
    const btnEditVendor = screen.getByTestId("btnEditVendor");
    expect(btnEditVendor).toBeInTheDocument;
    expect(btnEditVendor.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnEditVendor")
    );
  });
  test("Custom Test Cases for btnEditVendor", () => {
    // START_USER_CODE-USER_btnEditVendor_TEST
    // END_USER_CODE-USER_btnEditVendor_TEST
  });
  test("btnGovFarmData(Button Widget) Test Cases", async () => {
    const btnGovFarmData = screen.getByTestId("btnGovFarmData");
    expect(btnGovFarmData).toBeInTheDocument;
    expect(btnGovFarmData.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnGovFarmData")
    );
  });
  test("Custom Test Cases for btnGovFarmData", () => {
    // START_USER_CODE-USER_btnGovFarmData_TEST
    // END_USER_CODE-USER_btnGovFarmData_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnUp(Button Widget) Test Cases", async () => {
    const btnUp = screen.getByTestId("btnUp");
    expect(btnUp).toBeInTheDocument;
    expect(btnUp.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_btnUp")
    );
  });
  test("Custom Test Cases for btnUp", () => {
    // START_USER_CODE-USER_btnUp_TEST
    // END_USER_CODE-USER_btnUp_TEST
  });
  test("grpbxFarmSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmSetup = screen.getByTestId("grpbxFarmSetup");
    expect(grpbxFarmSetup.tagName).toBe("BUTTON");
    expect(grpbxFarmSetup.type).toBe("button");
    expect(grpbxFarmSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmSetup", () => {
    // START_USER_CODE-USER_grpbxFarmSetup_TEST
    // END_USER_CODE-USER_grpbxFarmSetup_TEST
  });
  test("lblFarms(Label Widget) Test Cases", async () => {
    const lblFarms = screen.getByTestId("lblFarms");
    expect(lblFarms.tagName).toBe("LABEL");
    expect(lblFarms.classList).toContain("form-label");
    expect(lblFarms.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_lblFarms")
    );
  });
  test("Custom Test Cases for lblFarms", () => {
    // START_USER_CODE-USER_lblFarms_TEST
    // END_USER_CODE-USER_lblFarms_TEST
  });
  test("lblVendors(Label Widget) Test Cases", async () => {
    const lblVendors = screen.getByTestId("lblVendors");
    expect(lblVendors.tagName).toBe("LABEL");
    expect(lblVendors.classList).toContain("form-label");
    expect(lblVendors.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmSetup_lblVendors")
    );
  });
  test("Custom Test Cases for lblVendors", () => {
    // START_USER_CODE-USER_lblVendors_TEST
    // END_USER_CODE-USER_lblVendors_TEST
  });
});
