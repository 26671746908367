/* eslint-disable*/
import React from "react";
import ContractManagement_VendorLookup from "./VendorLookup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorLookup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_VendorLookup />);
    });
  });
  afterEach(cleanup);
  test("is VendorLookup Loads Successfully", () => {
    expect(screen.getByText("VendorLookup")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorLookup", () => {
    // START_USER_CODE-USER_VendorLookup_Custom_Test_Case
    // END_USER_CODE-USER_VendorLookup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_VendorLookup />);
    });
  });
  afterEach(cleanup);
  test("btnCncl(Button Widget) Test Cases", async () => {
    const btnCncl = screen.getByTestId("btnCncl");
    expect(btnCncl).toBeInTheDocument;
    expect(btnCncl.textContent).toEqual(
      t("ContractManagement:VendorLookup_btnCncl")
    );
  });
  test("Custom Test Cases for btnCncl", () => {
    // START_USER_CODE-USER_btnCncl_TEST
    // END_USER_CODE-USER_btnCncl_TEST
  });
  test("btnSelect(Button Widget) Test Cases", async () => {
    const btnSelect = screen.getByTestId("btnSelect");
    expect(btnSelect).toBeInTheDocument;
    expect(btnSelect.textContent).toEqual(
      t("ContractManagement:VendorLookup_btnSelect")
    );
  });
  test("Custom Test Cases for btnSelect", () => {
    // START_USER_CODE-USER_btnSelect_TEST
    // END_USER_CODE-USER_btnSelect_TEST
  });
  test("btnSrch(Button Widget) Test Cases", async () => {
    const btnSrch = screen.getByTestId("btnSrch");
    expect(btnSrch).toBeInTheDocument;
    expect(btnSrch.textContent).toEqual(
      t("ContractManagement:VendorLookup_btnSrch")
    );
  });
  test("Custom Test Cases for btnSrch", () => {
    // START_USER_CODE-USER_btnSrch_TEST
    // END_USER_CODE-USER_btnSrch_TEST
  });
  test("grpbxVendorLookup(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorLookup = screen.getByTestId("grpbxVendorLookup");
    expect(grpbxVendorLookup.tagName).toBe("BUTTON");
    expect(grpbxVendorLookup.type).toBe("button");
    expect(grpbxVendorLookup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorLookup", () => {
    // START_USER_CODE-USER_grpbxVendorLookup_TEST
    // END_USER_CODE-USER_grpbxVendorLookup_TEST
  });
  test("txtSrch(Textbox Widget) Test Cases", async () => {
    const txtSrch = screen.getByTestId("txtSrch");
    expect(txtSrch.tagName).toBe("INPUT");
    expect(txtSrch.type).toBe("text");
    expect(txtSrch.classList).toContain("textboxWidgetClass");
    expect(txtSrch.previousElementSibling.textContent).toEqual(
      t("ContractManagement:VendorLookup_txtSrch")
    );
    await act(async () => {
      userEvent.type(txtSrch, "1");
    });
  });
  test("Custom Test Cases for txtSrch", () => {
    // START_USER_CODE-USER_txtSrch_TEST
    // END_USER_CODE-USER_txtSrch_TEST
  });
});
