import { StockTransferService } from "../../StockTransfer/Service/StockTransferService";

const defaultAreaProps = {
  comp_id: "",
  crop_year: "",
  area_id: "",
  area_name: "",
  handler_loc_num: "",
  ccc_handler_num: "",
  landp_addem_ind: "",
  display_lbs_ton: "",
  min_kern_hull_pct: "",
  max_kern_hull_pct: "",
  clear_acct: "",
  add_user: "",
  add_date_time: "",
  chg_user: "",
  chg_date_time: "",
  fm_agree_ind: "",
};

export class clsArea {
  constructor(thisObj) {
    this.thisObj = thisObj;
    this.mudtProps = Object.assign({}, defaultAreaProps);
    this.mudtSave = Object.assign({}, defaultAreaProps);
  }

  comp_id = (Value) => {
    if ((this.mudtProps.comp_id = Value)) {
      return false;
    } else {
      this.mudtProps.comp_id = Value;
    }
  };

  crop_year = (Value) => {
    if ((this.mudtProps.crop_year = Value)) {
      return false;
    } else {
      this.mudtProps.crop_year = Value;
    }
  };

  area_id = (Value) => {
    if ((this.mudtProps.area_id = Value)) {
      return false;
    } else {
      this.mudtProps.area_id = Value;
    }
  };

  area_name = (Value) => {
    if ((this.mudtProps.area_name = Value)) {
      return false;
    } else {
      this.mudtProps.area_name = Value;
    }
  };

  handler_loc_num = (Value) => {
    if ((this.mudtProps.handler_loc_num = Value)) {
      return false;
    } else {
      this.mudtProps.handler_loc_num = Value;
    }
  };

  ccc_handler_num = (Value) => {
    if ((this.mudtProps.ccc_handler_num = Value)) {
      return false;
    } else {
      this.mudtProps.ccc_handler_num = Value;
    }
  };

  landp_addem_ind = (Value) => {
    if ((this.mudtProps.landp_addem_ind = Value)) {
      return false;
    } else {
      this.mudtProps.landp_addem_ind = Value;
    }
  };

  display_lbs_ton = (Value) => {
    if ((this.mudtProps.display_lbs_ton = Value)) {
      return false;
    } else {
      this.mudtProps.display_lbs_ton = Value;
    }
  };

  min_kern_hull_pct = (Value) => {
    if ((this.mudtProps.min_kern_hull_pct = Value)) {
      return false;
    } else {
      this.mudtProps.min_kern_hull_pct = Value;
    }
  };

  max_kern_hull_pct = (Value) => {
    if ((this.mudtProps.max_kern_hull_pct = Value)) {
      return false;
    } else {
      this.mudtProps.max_kern_hull_pct = Value;
    }
  };

  clear_acct = (Value) => {
    if ((this.mudtProps.clear_acct = Value)) {
      return false;
    } else {
      this.mudtProps.clear_acct = Value;
    }
  };

  add_user = (Value) => {
    if ((this.mudtProps.add_user = Value)) {
      return false;
    } else {
      this.mudtProps.add_user = Value;
    }
  };

  add_date_time = (Value) => {
    if ((this.mudtProps.add_date_time = Value)) {
      return false;
    } else {
      this.mudtProps.add_date_time = Value;
    }
  };

  chg_user = (Value) => {
    if ((this.mudtProps.chg_user = Value)) {
      return false;
    } else {
      this.mudtProps.chg_user = Value;
    }
  };

  chg_date_time = (Value) => {
    if ((this.mudtProps.chg_date_time = Value)) {
      return false;
    } else {
      this.mudtProps.chg_date_time = Value;
    }
  };

  fm_agree_ind = (Value) => {
    if ((this.mudtProps.fm_agree_ind = Value)) {
      return false;
    } else {
      this.mudtProps.fm_agree_ind = Value;
    }
  };

  async Load(AreaID) {
    let retrievePriceScheduleSelectDetails =
      await StockTransferService.RetrieveAreaControlSelectDetails(AreaID);
    if (retrievePriceScheduleSelectDetails.length > 0) {
      this.Fetch(retrievePriceScheduleSelectDetails[0]);
    }
  }

  Fetch = (data) => {
    let self = this;
    Object.keys(data).forEach(function (reskey) {
      self[reskey](data[reskey]);
    });
  };
}
