/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_VendorProfile from "./VendorProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_VendorProfile />);
    });
  });
  afterEach(cleanup);
  test("is VendorProfile Loads Successfully", () => {
    expect(screen.getByText("VendorProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorProfile", () => {
    // START_USER_CODE-USER_VendorProfile_Custom_Test_Case
    // END_USER_CODE-USER_VendorProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_VendorProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnVendorMaintenance(Button Widget) Test Cases", async () => {
    const btnVendorMaintenance = screen.getByTestId(
      "btnVendorMaintenance"
    );
    expect(btnVendorMaintenance).toBeInTheDocument;
    expect(btnVendorMaintenance.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:VendorProfile_btnVendorMaintenance"
      )
    );
  });
  test("Custom Test Cases for btnVendorMaintenance", () => {
    // START_USER_CODE-USER_btnVendorMaintenance_TEST
    // END_USER_CODE-USER_btnVendorMaintenance_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxSuffix(GroupBox Widget) Test Cases", async () => {
    const grpbxSuffix = screen.getByTestId("grpbxSuffix");
    expect(grpbxSuffix.tagName).toBe("BUTTON");
    expect(grpbxSuffix.type).toBe("button");
    expect(grpbxSuffix.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSuffix", () => {
    // START_USER_CODE-USER_grpbxSuffix_TEST
    // END_USER_CODE-USER_grpbxSuffix_TEST
  });
  test("grpbxVendorDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorDetails = screen.getByTestId("grpbxVendorDetails");
    expect(grpbxVendorDetails.tagName).toBe("BUTTON");
    expect(grpbxVendorDetails.type).toBe("button");
    expect(grpbxVendorDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorDetails", () => {
    // START_USER_CODE-USER_grpbxVendorDetails_TEST
    // END_USER_CODE-USER_grpbxVendorDetails_TEST
  });
  test("lblSuffix(Label Widget) Test Cases", async () => {
    const lblSuffix = screen.getByTestId("lblSuffix");
    expect(lblSuffix.tagName).toBe("LABEL");
    expect(lblSuffix.classList).toContain("form-label");
    expect(lblSuffix.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_lblSuffix")
    );
  });
  test("Custom Test Cases for lblSuffix", () => {
    // START_USER_CODE-USER_lblSuffix_TEST
    // END_USER_CODE-USER_lblSuffix_TEST
  });
  test("txt211Concordia(Textbox Widget) Test Cases", async () => {
    const txt211Concordia = screen.getByTestId("txt211Concordia");
    expect(txt211Concordia.tagName).toBe("INPUT");
    expect(txt211Concordia.type).toBe("text");
    expect(txt211Concordia.classList).toContain("textboxWidgetClass");
    expect(txt211Concordia.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txt211Concordia")
    );
    await act(async () => {
      userEvent.type(txt211Concordia, "1");
    });
  });
  test("Custom Test Cases for txt211Concordia", () => {
    // START_USER_CODE-USER_txt211Concordia_TEST
    // END_USER_CODE-USER_txt211Concordia_TEST
  });
  test("txt211GPC(Textbox Widget) Test Cases", async () => {
    const txt211GPC = screen.getByTestId("txt211GPC");
    expect(txt211GPC.tagName).toBe("INPUT");
    expect(txt211GPC.type).toBe("text");
    expect(txt211GPC.classList).toContain("textboxWidgetClass");
    expect(txt211GPC.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txt211GPC")
    );
    await act(async () => {
      userEvent.type(txt211GPC, "1");
    });
  });
  test("Custom Test Cases for txt211GPC", () => {
    // START_USER_CODE-USER_txt211GPC_TEST
    // END_USER_CODE-USER_txt211GPC_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "123");
    });
    expect(txtAddedBy.getAttribute("value")).toBe("");
    expect(txtAddedBy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtBuyingPoint")
    );
    expect(txtBuyingPoint.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtChangedBy(Textbox Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("INPUT");
    expect(txtChangedBy.type).toBe("text");
    expect(txtChangedBy.classList).toContain("textboxWidgetClass");
    expect(txtChangedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtChangedBy")
    );
    await act(async () => {
      userEvent.type(txtChangedBy, "123");
    });
    expect(txtChangedBy.getAttribute("value")).toBe("");
    expect(txtChangedBy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("txtCounty(Textbox Widget) Test Cases", async () => {
    const txtCounty = screen.getByTestId("txtCounty");
    expect(txtCounty.tagName).toBe("INPUT");
    expect(txtCounty.type).toBe("text");
    expect(txtCounty.classList).toContain("textboxWidgetClass");
    expect(txtCounty.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtCounty")
    );
    expect(txtCounty.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtCounty", () => {
    // START_USER_CODE-USER_txtCounty_TEST
    // END_USER_CODE-USER_txtCounty_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtFarm")
    );
    expect(txtFarm.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtRemitTo(Textbox Widget) Test Cases", async () => {
    const txtRemitTo = screen.getByTestId("txtRemitTo");
    expect(txtRemitTo.tagName).toBe("INPUT");
    expect(txtRemitTo.type).toBe("text");
    expect(txtRemitTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo", () => {
    // START_USER_CODE-USER_txtRemitTo_TEST
    // END_USER_CODE-USER_txtRemitTo_TEST
  });
  test("txtSharePercent(Textbox Widget) Test Cases", async () => {
    const txtSharePercent = screen.getByTestId("txtSharePercent");
    expect(txtSharePercent.tagName).toBe("INPUT");
    expect(txtSharePercent.type).toBe("text");
    expect(txtSharePercent.classList).toContain("textboxWidgetClass");
    expect(txtSharePercent.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtSharePercent")
    );
    await act(async () => {
      userEvent.type(txtSharePercent, "1");
    });
  });
  test("Custom Test Cases for txtSharePercent", () => {
    // START_USER_CODE-USER_txtSharePercent_TEST
    // END_USER_CODE-USER_txtSharePercent_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtState")
    );
    expect(txtState.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VendorProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
