/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_SpecFuncReprint from "./SpecFuncReprint";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecFuncReprint Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_SpecFuncReprint />);
    });
  });
  afterEach(cleanup);
  test("is SpecFuncReprint Loads Successfully", () => {
    expect(screen.getByText("SpecFuncReprint")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecFuncReprint", () => {
    // START_USER_CODE-USER_SpecFuncReprint_Custom_Test_Case
    // END_USER_CODE-USER_SpecFuncReprint_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_SpecFuncReprint />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncReprint_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnReprint(Button Widget) Test Cases", async () => {
    const btnReprint = screen.getByTestId("btnReprint");
    expect(btnReprint).toBeInTheDocument;
    expect(btnReprint.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncReprint_btnReprint")
    );
  });
  test("Custom Test Cases for btnReprint", () => {
    // START_USER_CODE-USER_btnReprint_TEST
    // END_USER_CODE-USER_btnReprint_TEST
  });
  test("grpbxSettlement(GroupBox Widget) Test Cases", async () => {
    const grpbxSettlement = screen.getByTestId("grpbxSettlement");
    expect(grpbxSettlement.tagName).toBe("BUTTON");
    expect(grpbxSettlement.type).toBe("button");
    expect(grpbxSettlement.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSettlement", () => {
    // START_USER_CODE-USER_grpbxSettlement_TEST
    // END_USER_CODE-USER_grpbxSettlement_TEST
  });
  test("grpbxSpecFuncReprint(GroupBox Widget) Test Cases", async () => {
    const grpbxSpecFuncReprint = screen.getByTestId(
      "grpbxSpecFuncReprint"
    );
    expect(grpbxSpecFuncReprint.tagName).toBe("BUTTON");
    expect(grpbxSpecFuncReprint.type).toBe("button");
    expect(grpbxSpecFuncReprint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpecFuncReprint", () => {
    // START_USER_CODE-USER_grpbxSpecFuncReprint_TEST
    // END_USER_CODE-USER_grpbxSpecFuncReprint_TEST
  });
  test("grpbxWarehouseReceipt(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseReceipt = screen.getByTestId(
      "grpbxWarehouseReceipt"
    );
    expect(grpbxWarehouseReceipt.tagName).toBe("BUTTON");
    expect(grpbxWarehouseReceipt.type).toBe("button");
    expect(grpbxWarehouseReceipt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseReceipt", () => {
    // START_USER_CODE-USER_grpbxWarehouseReceipt_TEST
    // END_USER_CODE-USER_grpbxWarehouseReceipt_TEST
  });
  test("lblReprintSettlement(Label Widget) Test Cases", async () => {
    const lblReprintSettlement = screen.getByTestId(
      "lblReprintSettlement"
    );
    expect(lblReprintSettlement.tagName).toBe("LABEL");
    expect(lblReprintSettlement.classList).toContain("form-label");
    expect(lblReprintSettlement.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncReprint_lblReprintSettlement"
      )
    );
  });
  test("Custom Test Cases for lblReprintSettlement", () => {
    // START_USER_CODE-USER_lblReprintSettlement_TEST
    // END_USER_CODE-USER_lblReprintSettlement_TEST
  });
  test("lblReprintWarehouseReceipt(Label Widget) Test Cases", async () => {
    const lblReprintWarehouseReceipt = screen.getByTestId(
      "lblReprintWarehouseReceipt"
    );
    expect(lblReprintWarehouseReceipt.tagName).toBe("LABEL");
    expect(lblReprintWarehouseReceipt.classList).toContain("form-label");
    expect(lblReprintWarehouseReceipt.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncReprint_lblReprintWarehouseReceipt"
      )
    );
  });
  test("Custom Test Cases for lblReprintWarehouseReceipt", () => {
    // START_USER_CODE-USER_lblReprintWarehouseReceipt_TEST
    // END_USER_CODE-USER_lblReprintWarehouseReceipt_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncReprint_txtBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtInspection(Textbox Widget) Test Cases", async () => {
    const txtInspection = screen.getByTestId("txtInspection");
    expect(txtInspection.tagName).toBe("INPUT");
    expect(txtInspection.type).toBe("text");
    expect(txtInspection.classList).toContain("textboxWidgetClass");
    expect(txtInspection.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncReprint_txtInspection")
    );
    await act(async () => {
      userEvent.type(txtInspection, "1");
    });
  });
  test("Custom Test Cases for txtInspection", () => {
    // START_USER_CODE-USER_txtInspection_TEST
    // END_USER_CODE-USER_txtInspection_TEST
  });
  test("txtWarehouseReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceiptNum = screen.getByTestId(
      "txtWarehouseReceiptNum"
    );
    expect(txtWarehouseReceiptNum.tagName).toBe("INPUT");
    expect(txtWarehouseReceiptNum.type).toBe("text");
    expect(txtWarehouseReceiptNum.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtWarehouseReceiptNum.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncReprint_txtWarehouseReceiptNum"
      )
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceiptNum, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseReceiptNum", () => {
    // START_USER_CODE-USER_txtWarehouseReceiptNum_TEST
    // END_USER_CODE-USER_txtWarehouseReceiptNum_TEST
  });
});
