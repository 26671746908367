/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from "jspdf";
import moment from 'moment'
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getValue,
  enable,
  disable,
  setData,
  getData,
  hide,
  show,
  isEnabled,
  isVisible,
  goTo,
  readonly,
  getGridValue,
  setRowColor,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./TransferSearch.scss";

// START_USER_CODE-USER_IMPORTS
import Loading from "../../../Loader/Loading";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { StockTransferService } from "../Service/StockTransferService";
import { clsTransfer } from "../Common/clsTransfer";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_TransferSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));
  const compIdFromLS = sessionStorage.getItem('compId');
  let compId = sessionStorage.getItem('compId');
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TransferSearch",
    windowName: "TransferSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.TransferSearch",
    // START_USER_CODE-USER_TransferSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Transfer Search",
      scrCode: "PN1080A",
    },
    // END_USER_CODE-USER_TransferSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCrtNewTrnsfr: {
      name: "btnCrtNewTrnsfr",
      type: "ButtonWidget",
      parent: "grpbxTrnsfr",
      Label: "Create new Transfer",
      CharWidth: "39",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnCrtNewTrnsfr_PROPERTIES

      // END_USER_CODE-USER_btnCrtNewTrnsfr_PROPERTIES
    },
    btnExport: {
      name: "btnExport",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnExport_PROPERTIES

      // END_USER_CODE-USER_btnExport_PROPERTIES
    },
    btnFrtAccr: {
      name: "btnFrtAccr",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Frt Accrl RTP Release",
      CharWidth: "44",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnFrtAccr_PROPERTIES

      // END_USER_CODE-USER_btnFrtAccr_PROPERTIES
    },
    btnLookup: {
      name: "btnLookup",
      type: "ButtonWidget",
      parent: "grpbxTrnsfr",
      Label: "...",
      CharWidth: "9",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnLookup_PROPERTIES

      // END_USER_CODE-USER_btnLookup_PROPERTIES
    },
    btnMltiTrnsfr: {
      name: "btnMltiTrnsfr",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Multi-Transfer",
      CharWidth: "30",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnMltiTrnsfr_PROPERTIES

      // END_USER_CODE-USER_btnMltiTrnsfr_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxTrnsfr",
      Label: "Open",
      CharWidth: "11",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrntTcktDlvry: {
      name: "btnPrntTcktDlvry",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Ticket Delivery",
      CharWidth: "43",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPrntTcktDlvry_PROPERTIES

      // END_USER_CODE-USER_btnPrntTcktDlvry_PROPERTIES
    },
    btnSrch: {
      name: "btnSrch",
      type: "ButtonWidget",
      parent: "grpbxTrnsfr",
      Label: "Search",
      CharWidth: "15",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnSrch_PROPERTIES

      // END_USER_CODE-USER_btnSrch_PROPERTIES
    },
    btnViewRelAccr: {
      name: "btnViewRelAccr",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "View released Accrl.",
      CharWidth: "41",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnViewRelAccr_PROPERTIES

      // END_USER_CODE-USER_btnViewRelAccr_PROPERTIES
    },
    colCarrId: {
      name: "colCarrId",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Carrier ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCarrId_PROPERTIES

      // END_USER_CODE-USER_colCarrId_PROPERTIES
    },
    colFrmGen: {
      name: "colFrmGen",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "From Gen.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrmGen_PROPERTIES

      // END_USER_CODE-USER_colFrmGen_PROPERTIES
    },
    colFrmOlec: {
      name: "colFrmOlec",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "From Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrmOlec_PROPERTIES

      // END_USER_CODE-USER_colFrmOlec_PROPERTIES
    },
    colFrmOrgnc: {
      name: "colFrmOrgnc",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "From Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrmOrgnc_PROPERTIES

      // END_USER_CODE-USER_colFrmOrgnc_PROPERTIES
    },
    colFrmVar: {
      name: "colFrmVar",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "From Var.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrmVar_PROPERTIES

      // END_USER_CODE-USER_colFrmVar_PROPERTIES
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Peanut Type.",
      Height: "",
      Width: "",
    },
    colSegType: {
      name: "colSegType",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Seg Type",
      Height: "",
      Width: "",
    },
    colShpngLoc: {
      name: "colShpngLoc",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Shipping Location",
      Height: "",
      Width: "",
    },
    colFrmWhse: {
      name: "colFrmWhse",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "From Whse/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrmWhse_PROPERTIES

      // END_USER_CODE-USER_colFrmWhse_PROPERTIES
    },
    colFrtAccrCmplt: {
      name: "colFrtAccrCmplt",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Frt. Accr. Complete",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrtAccrCmplt_PROPERTIES

      // END_USER_CODE-USER_colFrtAccrCmplt_PROPERTIES
    },
    colNtWt: {
      name: "colNtWt",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Net Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNtWt_PROPERTIES

      // END_USER_CODE-USER_colNtWt_PROPERTIES
    },
    colPayFrgt: {
      name: "colPayFrgt",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Pay Freight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayFrgt_PROPERTIES

      // END_USER_CODE-USER_colPayFrgt_PROPERTIES
    },
    colRcvLoc: {
      name: "colRcvLoc",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Receiving Loc.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcvLoc_PROPERTIES

      // END_USER_CODE-USER_colRcvLoc_PROPERTIES
    },
    colRcvWt: {
      name: "colRcvWt",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Receive Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcvWt_PROPERTIES

      // END_USER_CODE-USER_colRcvWt_PROPERTIES
    },
    colShppngDate: {
      name: "colShppngDate",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Shipped Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShppngDate_PROPERTIES

      // END_USER_CODE-USER_colShppngDate_PROPERTIES
    },
    colShppngWt: {
      name: "colShppngWt",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Shipping Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShppngWt_PROPERTIES

      // END_USER_CODE-USER_colShppngWt_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES

      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    colToGen: {
      name: "colToGen",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "To Gen.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToGen_PROPERTIES

      // END_USER_CODE-USER_colToGen_PROPERTIES
    },
    colToOleic: {
      name: "colToOleic",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "To Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToOleic_PROPERTIES

      // END_USER_CODE-USER_colToOleic_PROPERTIES
    },
    colToOrgnc: {
      name: "colToOrgnc",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "To Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToOrgnc_PROPERTIES

      // END_USER_CODE-USER_colToOrgnc_PROPERTIES
    },
    colToVar: {
      name: "colToVar",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "To Var.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToVar_PROPERTIES

      // END_USER_CODE-USER_colToVar_PROPERTIES
    },
    colToWhse: {
      name: "colToWhse",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "To Whse/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToWhse_PROPERTIES

      // END_USER_CODE-USER_colToWhse_PROPERTIES
    },
    colTrnsfr: {
      name: "colTrnsfr",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Transfer #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTrnsfr_PROPERTIES

      // END_USER_CODE-USER_colTrnsfr_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    colUpdtInvWt: {
      name: "colUpdtInvWt",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Update Inv. Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUpdtInvWt_PROPERTIES

      // END_USER_CODE-USER_colUpdtInvWt_PROPERTIES
    },
    colWtVarnc: {
      name: "colWtVarnc",
      type: "GridColumnWidget",
      parent: "gridTrnsfrSrch",
      Label: "Weight Variance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWtVarnc_PROPERTIES

      // END_USER_CODE-USER_colWtVarnc_PROPERTIES
    },
    ddFrghtAccr: {
      name: "ddFrghtAccr",
      type: "DropDownFieldWidget",
      parent: "grpbxTransfer",
      Label: "Freight Accural Complete:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrghtAccr_PROPERTIES

      // END_USER_CODE-USER_ddFrghtAccr_PROPERTIES
    },
    ddFrmGen: {
      name: "ddFrmGen",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "From Gen:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrmGen_PROPERTIES

      // END_USER_CODE-USER_ddFrmGen_PROPERTIES
    },
    ddFrmOlc: {
      name: "ddFrmOlc",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "From Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrmOlc_PROPERTIES

      // END_USER_CODE-USER_ddFrmOlc_PROPERTIES
    },
    ddFrmOrgnc: {
      name: "ddFrmOrgnc",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "From Organic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrmOrgnc_PROPERTIES

      // END_USER_CODE-USER_ddFrmOrgnc_PROPERTIES
    },
    ddFrmVrty: {
      name: "ddFrmVrty",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "From Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrmVrty_PROPERTIES

      // END_USER_CODE-USER_ddFrmVrty_PROPERTIES
    },
    ddFrmWhse: {
      name: "ddFrmWhse",
      type: "DropDownFieldWidget",
      parent: "grpbxTransfer",
      Label: "From Whse/Bin:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrmWhse_PROPERTIES

      // END_USER_CODE-USER_ddFrmWhse_PROPERTIES
    },
    ddPayFrght: {
      name: "ddPayFrght",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "Pay Freight:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPayFrght_PROPERTIES

      // END_USER_CODE-USER_ddPayFrght_PROPERTIES
    },
    ddPnutTyp: {
      name: "ddPnutTyp",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPnutTyp_PROPERTIES

      // END_USER_CODE-USER_ddPnutTyp_PROPERTIES
    },
    ddRcvLoc: {
      name: "ddRcvLoc",
      type: "DropDownFieldWidget",
      parent: "grpbxTransfer",
      Label: "Receiving Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRcvLoc_PROPERTIES

      // END_USER_CODE-USER_ddRcvLoc_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddShippingLoc: {
      name: "ddShippingLoc",
      type: "DropDownFieldWidget",
      parent: "grpbxTransfer",
      Label: "Shipping Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoc_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoc_PROPERTIES
    },
    ddSts: {
      name: "ddSts",
      type: "DropDownFieldWidget",
      parent: "grpbxTransfer",
      Label: "Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSts_PROPERTIES

      // END_USER_CODE-USER_ddSts_PROPERTIES
    },
    ddToGen: {
      name: "ddToGen",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "To Gen:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddToGen_PROPERTIES

      // END_USER_CODE-USER_ddToGen_PROPERTIES
    },
    ddToOlec: {
      name: "ddToOlec",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "To Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddToOlec_PROPERTIES

      // END_USER_CODE-USER_ddToOlec_PROPERTIES
    },
    ddToOrgnc: {
      name: "ddToOrgnc",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "To Organic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddToOrgnc_PROPERTIES

      // END_USER_CODE-USER_ddToOrgnc_PROPERTIES
    },
    ddToVrty: {
      name: "ddToVrty",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "To Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddToVrty_PROPERTIES

      // END_USER_CODE-USER_ddToVrty_PROPERTIES
    },
    ddToWhse: {
      name: "ddToWhse",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "To Whse/Bin:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddToWhse_PROPERTIES

      // END_USER_CODE-USER_ddToWhse_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxTrnsfr",
      Label: "Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    dtRcvdFrm: {
      name: "dtRcvdFrm",
      type: "DateWidget",
      parent: "grpbxTransfer",
      Label: "Received Date-From:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtRcvdFrm_PROPERTIES

      // END_USER_CODE-USER_dtRcvdFrm_PROPERTIES
    },
    dtRcvdTo: {
      name: "dtRcvdTo",
      type: "DateWidget",
      parent: "grpbxTransfer",
      Label: "To:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtRcvdTo_PROPERTIES

      // END_USER_CODE-USER_dtRcvdTo_PROPERTIES
    },
    dtShippdDtFrm: {
      name: "dtShippdDtFrm",
      type: "DateWidget",
      parent: "grpbxTransfer",
      Label: "Shipped Date-From:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtShippdDtFrm_PROPERTIES

      // END_USER_CODE-USER_dtShippdDtFrm_PROPERTIES
    },
    dtShippdTo: {
      name: "dtShippdTo",
      type: "DateWidget",
      parent: "grpbxTransfer",
      Label: "To:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtShippdTo_PROPERTIES

      // END_USER_CODE-USER_dtShippdTo_PROPERTIES
    },
    gridTrnsfrSrch: {
      name: "gridTrnsfrSrch",
      type: "GridWidget",
      parent: "grpbxGrid",
      gridCellsOrder:
        "txtcolTrnsfr,txtcolType,txtcolSts,txtcolShppngDate,txtcolPnutType,txtcolSegType,txtcolShpngLoc,txtcolFrmWhse,txtcolRcvLoc,txtcolToWhse,txtcolFrmVar,txtcolToVar,txtcolFrmGen,txtcolToGen,txtcolFrmOlec,txtcolToOleic,txtcolFrmOrgnc,txtcolToOrgnc,txtcolRcvWt,txtcolShppngWt,txtcolWtVarnc,txtcolUpdtInvWt,txtcolNtWt,txtcolFrtAccrCmplt,txtcolCarrId,txtcolPayFrgt",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      ProcessingText: "gridTransfer",
      needGridChange: true,
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: false,
      // START_USER_CODE-USER_gridTrnsfrSrch_PROPERTIES
      clientPagination: true,
      DisplaySize: 10,
      expandedRows: [],
      conditionalRowStyles: [
        {
          when: (row) => { return row.txtcolSts == 'Void' },
          style: {
            '.valRowSel':{
              color:'red'
            }
          }
        }
      ],
      pushExpandedRows: (isExpanded, row) => {
        if (isExpanded) {
          states.gridTrnsfrSrch.expandedRows.push(row)
        }
        else {
          states.gridTrnsfrSrch.expandedRows = states.gridTrnsfrSrch.expandedRows.filter(rows => rows.rowID$ !== row.rowID$)
        }
      },
      isExpandable: true,
      defaultExpand: false,
      needGridChange: false,
      isCobolTable: true,
      expandedCompTemplate: ({ data }) => (
        <div>
          {data.childrow ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.childrow.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="expandIconSpc"></div>
                    {thisObj.state.gridTrnsfrSrch.columns[0].Visible ?
                      <div className="extraRowCell_1" key="expandCell1">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[1].Visible ?
                      <div className="extraRowCell_2" key="expandCell2">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[2].Visible ?
                      <div className="extraRowCell_3" key="expandCell3">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[3].Visible ?
                      <div className="extraRowCell_4" key="expandCell5">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[4].Visible ?
                      <div className="extraRowCell_5" key="expandCell4">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[5].Visible ?
                      <div className="extraRowCell_6" key="expandCell6">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[6].Visible ?
                      <div className="extraRowCell_7" key="expandCell7">
                        <label>
                          {elem.Shp}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[7].Visible ?
                      <div className="extraRowCell_8" key="expandCell8">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[8].Visible ?
                      <div className="extraRowCell_9" key="expandCell9">
                          <label>
                          {elem.Recv}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[9].Visible ?
                      <div className="extraRowCell_10" key="expandCell10">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[10].Visible ?
                      <div className="extraRowCell_11" key="expandCell11">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[11].Visible ?
                      <div className="extraRowCell_12" key="expandCell12">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[12].Visible ?
                      <div className="extraRowCell_13" key="expandCell13">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[13].Visible ?
                      <div className="extraRowCell_14" key="expandCell14">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[14].Visible ?
                      <div className="extraRowCell_15" key="expandCell15">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[15].Visible ?
                      <div className="extraRowCell_16" key="expandCell16">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[16].Visible ?
                      <div className="extraRowCell_17" key="expandCell17">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[17].Visible ?
                      <div className="extraRowCell_18" key="expandCell18">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[18].Visible ?
                      <div className="extraRowCell_19" key="expandCell19">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[19].Visible ?
                      <div className="extraRowCell_20" key="expandCell20">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[20].Visible ?
                      <div className="extraRowCell_21" key="expandCell21">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[21].Visible ?
                      <div className="extraRowCell_22" key="expandCell22">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[22].Visible ?
                      <div className="extraRowCell_23" key="expandCell23">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[23].Visible ?
                      <div className="extraRowCell_24" key="expandCell24">
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[24].Visible ?
                      <div className="extraRowCell_25" key="expandCell25">
                          <label>
                          {elem.Carrier}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTrnsfrSrch.columns[25].Visible ?
                      <div className="extraRowCell_26" key="expandCell26">
                      </div> : <></>}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridTrnsfrSrch_PROPERTIES
    },
    grpbxGrid: {
      name: "grpbxGrid",
      type: "GroupBoxWidget",
      parent: "grpbxTransferSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxGrid_PROPERTIES

      // END_USER_CODE-USER_grpbxGrid_PROPERTIES
    },
    grpbxTransfer: {
      name: "grpbxTransfer",
      type: "GroupBoxWidget",
      parent: "grpbxTransferSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxTransfer_PROPERTIES

      // END_USER_CODE-USER_grpbxTransfer_PROPERTIES
    },
    grpbxTrnsfr: {
      name: "grpbxTrnsfr",
      type: "GroupBoxWidget",
      parent: "grpbxTransferSearch",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxTrnsfr_PROPERTIES

      // END_USER_CODE-USER_grpbxTrnsfr_PROPERTIES
    },
    lblCropYr: {
      name: "lblCropYr",
      type: "LabelWidget",
      parent: "grpbxTrnsfr",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYr_PROPERTIES

      // END_USER_CODE-USER_lblCropYr_PROPERTIES
    },
    lblTotal: {
      name: "lblTotal",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Total:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotal_PROPERTIES

      // END_USER_CODE-USER_lblTotal_PROPERTIES
    },
    lblTrnsfrList: {
      name: "lblTrnsfrList",
      type: "LabelWidget",
      parent: "grpbxGrid",
      Label: "Transfer List",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTrnsfrList_PROPERTIES

      // END_USER_CODE-USER_lblTrnsfrList_PROPERTIES
    },
    txtCarrAbbrv: {
      name: "txtCarrAbbrv",
      type: "TextBoxWidget",
      parent: "grpbxTrnsfr",
      Label: "Carrier Abbreviation #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrAbbrv_PROPERTIES

      // END_USER_CODE-USER_txtCarrAbbrv_PROPERTIES
    },
    txtVndrName: {
      name: "txtVndrName",
      type: "TextBoxWidget",
      parent: "grpbxTrnsfr",
      Label: "Carrier Abbreviation #:",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndrName_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtVndrName_PROPERTIES
    },
    txtcolCarrId: {
      name: "txtcolCarrId",
      type: "TextBoxWidget",
      colName: "colCarrId",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCarrId_PROPERTIES

      // END_USER_CODE-USER_txtcolCarrId_PROPERTIES
    },
    txtcolFrmGen: {
      name: "txtcolFrmGen",
      type: "TextBoxWidget",
      colName: "colFrmGen",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrmGen_PROPERTIES

      // END_USER_CODE-USER_txtcolFrmGen_PROPERTIES
    },
    txtcolFrmOlec: {
      name: "txtcolFrmOlec",
      type: "TextBoxWidget",
      colName: "colFrmOlec",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrmOlec_PROPERTIES

      // END_USER_CODE-USER_txtcolFrmOlec_PROPERTIES
    },
    txtcolFrmOrgnc: {
      name: "txtcolFrmOrgnc",
      type: "TextBoxWidget",
      colName: "colFrmOrgnc",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrmOrgnc_PROPERTIES

      // END_USER_CODE-USER_txtcolFrmOrgnc_PROPERTIES
    },
    txtcolFrmVar: {
      name: "txtcolFrmVar",
      type: "TextBoxWidget",
      colName: "colFrmVar",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrmVar_PROPERTIES

      // END_USER_CODE-USER_txtcolFrmVar_PROPERTIES
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolSegType: {
      name: "txtcolSegType",
      type: "TextBoxWidget",
      colName: "colSegType",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolShpngLoc: {
      name: "txtcolShpngLoc",
      type: "TextBoxWidget",
      colName: "colShpngLoc",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolFrmWhse: {
      name: "txtcolFrmWhse",
      type: "TextBoxWidget",
      colName: "colFrmWhse",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrmWhse_PROPERTIES

      // END_USER_CODE-USER_txtcolFrmWhse_PROPERTIES
    },
    txtcolFrtAccrCmplt: {
      name: "txtcolFrtAccrCmplt",
      type: "TextBoxWidget",
      colName: "colFrtAccrCmplt",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrtAccrCmplt_PROPERTIES

      // END_USER_CODE-USER_txtcolFrtAccrCmplt_PROPERTIES
    },
    txtcolNtWt: {
      name: "txtcolNtWt",
      type: "TextBoxWidget",
      colName: "colNtWt",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNtWt_PROPERTIES

      // END_USER_CODE-USER_txtcolNtWt_PROPERTIES
    },
    txtcolPayFrgt: {
      name: "txtcolPayFrgt",
      type: "TextBoxWidget",
      colName: "colPayFrgt",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayFrgt_PROPERTIES

      // END_USER_CODE-USER_txtcolPayFrgt_PROPERTIES
    },
    txtcolRcvLoc: {
      name: "txtcolRcvLoc",
      type: "TextBoxWidget",
      colName: "colRcvLoc",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcvLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolRcvLoc_PROPERTIES
    },
    txtcolRcvWt: {
      name: "txtcolRcvWt",
      type: "TextBoxWidget",
      colName: "colRcvWt",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcvWt_PROPERTIES

      // END_USER_CODE-USER_txtcolRcvWt_PROPERTIES
    },
    txtcolShppngDate: {
      name: "txtcolShppngDate",
      type: "TextBoxWidget",
      colName: "colShppngDate",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShppngDate_PROPERTIES

      // END_USER_CODE-USER_txtcolShppngDate_PROPERTIES
    },
    txtcolShppngWt: {
      name: "txtcolShppngWt",
      type: "TextBoxWidget",
      colName: "colShppngWt",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShppngWt_PROPERTIES

      // END_USER_CODE-USER_txtcolShppngWt_PROPERTIES
    },
    txtcolSts: {
      name: "txtcolSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSts_PROPERTIES

      // END_USER_CODE-USER_txtcolSts_PROPERTIES
    },
    txtcolToGen: {
      name: "txtcolToGen",
      type: "TextBoxWidget",
      colName: "colToGen",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToGen_PROPERTIES

      // END_USER_CODE-USER_txtcolToGen_PROPERTIES
    },
    txtcolToOleic: {
      name: "txtcolToOleic",
      type: "TextBoxWidget",
      colName: "colToOleic",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolToOleic_PROPERTIES
    },
    txtcolToOrgnc: {
      name: "txtcolToOrgnc",
      type: "TextBoxWidget",
      colName: "colToOrgnc",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToOrgnc_PROPERTIES

      // END_USER_CODE-USER_txtcolToOrgnc_PROPERTIES
    },
    txtcolToVar: {
      name: "txtcolToVar",
      type: "TextBoxWidget",
      colName: "colToVar",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToVar_PROPERTIES

      // END_USER_CODE-USER_txtcolToVar_PROPERTIES
    },
    txtcolToWhse: {
      name: "txtcolToWhse",
      type: "TextBoxWidget",
      colName: "colToWhse",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToWhse_PROPERTIES

      // END_USER_CODE-USER_txtcolToWhse_PROPERTIES
    },
    txtcolTrnsfr: {
      name: "txtcolTrnsfr",
      type: "TextBoxWidget",
      colName: "colTrnsfr",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTrnsfr_PROPERTIES

      // END_USER_CODE-USER_txtcolTrnsfr_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES

      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtcolUpdtInvWt: {
      name: "txtcolUpdtInvWt",
      type: "TextBoxWidget",
      colName: "colUpdtInvWt",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUpdtInvWt_PROPERTIES

      // END_USER_CODE-USER_txtcolUpdtInvWt_PROPERTIES
    },
    txtcolWtVarnc: {
      name: "txtcolWtVarnc",
      type: "TextBoxWidget",
      colName: "colWtVarnc",
      parent: "gridTrnsfrSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWtVarnc_PROPERTIES

      // END_USER_CODE-USER_txtcolWtVarnc_PROPERTIES
    },
    txtNetWt: {
      name: "txtNetWt",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Net Wt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWt_PROPERTIES

      // END_USER_CODE-USER_txtNetWt_PROPERTIES
    },
    txtRcvWt: {
      name: "txtRcvWt",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Receive Wt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRcvWt_PROPERTIES

      // END_USER_CODE-USER_txtRcvWt_PROPERTIES
    },
    txtShippdWt: {
      name: "txtShippdWt",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Shipped Wt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShippdWt_PROPERTIES

      // END_USER_CODE-USER_txtShippdWt_PROPERTIES
    },
    txtTrnsfr: {
      name: "txtTrnsfr",
      type: "TextBoxWidget",
      parent: "grpbxTransfer",
      Label: "Transfer #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTrnsfr_PROPERTIES

      // END_USER_CODE-USER_txtTrnsfr_PROPERTIES
    },
    txtUpdtInvWt: {
      name: "txtUpdtInvWt",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Update Inv Wt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUpdtInvWt_PROPERTIES

      // END_USER_CODE-USER_txtUpdtInvWt_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxTrnsfr",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWeightVariance: {
      name: "txtWeightVariance",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Weight Variance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWeightVariance_PROPERTIES

      // END_USER_CODE-USER_txtWeightVariance_PROPERTIES
    },
    grpbxTransferSearch: {
      name: "grpbxTransferSearch",
      type: "GroupBoxWidget",
      parent: "TransferSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxTransferSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferSearch_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TransferSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "StockTransfer#StockTransfer": {},
        "StockTransfer#FrtAccrlSettlement": {},
        "StockTransfer#FrtAccrlSearch": {},
        "ContractManagement#VendorLookup": {},
        "StockTransfer#MultiStopSearch": {},
        "StockTransfer#ReportPreviewTransfer": {}
      },
      REVERSE: {
        "StockTransfer#StockTransfer": {},
        "StockTransfer#FrtAccrlSettlement": {},
        "StockTransfer#FrtAccrlSearch": {},
        "ContractManagement#VendorLookup": {},
        "StockTransfer#MultiStopSearch": {},
        "StockTransfer#ReportPreviewTransfer": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCrtNewTrnsfr: {
    //   DEFAULT: ["StockTransfer#StockTransfer#DEFAULT#true#Click"],
    // },
    btnFrtAccr: {
      DEFAULT: ["StockTransfer#FrtAccrlSettlement#DEFAULT#true#Click"],
    },
    btnViewRelAccr: {
      DEFAULT: ["StockTransfer#FrtAccrlSearch#DEFAULT#true#Click"],
    },
    btnLookup: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnMltiTrnsfr: {
      DEFAULT: ["StockTransfer#MultiStopSearch#DEFAULT#true#Click"],
    },
    // btnOpen: {
    //   DEFAULT: ["StockTransfer#StockTransfer#DEFAULT#true#Click"],
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    // btnPrntTcktDlvry: {
    //   DEFAULT: ["StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click"],
    // }
  };
  const [loading, setLoading] = useState(false);
  const [gridcolor, setgridcolor] = useState([]);
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(()=>{
    setTabIndex()
  },[])
  useEffect(() => {
    let Status = getData(thisObj, 'ClosingOfStockTransfer')
    let button = getData(thisObj,'btnopen')
    if (Status == true && button == true) {
      onbtnSrchClick()
      setData(thisObj,'btnopen',false)
    }
    setData(thisObj, "ClosingOfStockTransfer", false)
  }, [getData(thisObj, 'ClosingOfStockTransfer')]);


  useEffect(() => {
    let Status = getData(thisObj, 'ClosingOfStockTransfer')
    if (Status == true) {
      Refresh()
    }
    setData(thisObj, "ClosingOfStockTransfer", false)
  }, [getData(thisObj, 'ClosingOfStockTransfer')]);

  useEffect(() => {
    if (getData(thisObj, "ClosingOfStockTransfer") == null) {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      show(thisObj, 'txtVndrName')
      setValue(thisObj, "txtVndr", vendor_lookup.VendorNumber)
      txtVndr.value = vendor_lookup.VendorNumber
      setValue(thisObj, "txtVndrName", vendor_lookup.vendorName)
      AccountPayment.RetrieveScacVendorLookupDetails(vendor_lookup.VendorNumber, '').then(response => {
        if (response.length > 0) {
          setValue(thisObj, 'txtCarrAbbrv', response[0].scac)
        } else {
          setValue(thisObj, 'txtCarrAbbrv', '')
        }
      });
     setData(thisObj,'vendorDetails',null)
    }
    // else {
    //   hide(thisObj, 'txtVndrName', false)
    //   setValue(thisObj, 'txtVndr', '')
    //   setValue(thisObj, 'txtVndrName', '')
    // }
  }
    setData(thisObj, "ClosingOfStockTransfer", null)
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddShippingLoc", ''),
      setValue(thisObj, "ddRcvLoc", ''),
      setValue(thisObj, "ddPnutTyp", ''),
      setValue(thisObj, "ddFrmVrty", ''),
      setValue(thisObj, "ddToVrty", ''),
      setValue(thisObj, "ddFrmWhse", ''),
      setValue(thisObj, "ddToWhse", ''),
      setValue(thisObj, 'lblCropYr', cropYearFromLS());
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
 
  // Functionality of Formload 
  async function FormLoad() {
    setData(thisObj, 'selectedRowReload', false);
    setLoading(true);
    bFillBuyingPointList();
    bFillTransferTypeList();
    bFillPayFreightComboBox();
    bFillSegmentList();
    bFillTransferStatusList();
    bFillFrtAccrlCompleteList();
    bFillReceivingLocationList();
    bFillTypeList();
    lFillFromVarietyList();
    lFillToVarietyList();
    bFillOleicList();
    bFillOleicList1();
    bFillGenerationList();
    bFillGenerationList1();
    bFillDefaultorganic();
    bFillDefaultorganic1()
    bFillDefaultWhseBin();
    bFillDefaultWhseBin1();
    lFillDefaultWhseBin1();
    lFillDefaultWhseBin();
    disable(thisObj, "txtRcvWt");
    disable(thisObj, "txtShippdWt");
    disable(thisObj, "txtWeightVariance");
    disable(thisObj, "txtUpdtInvWt");
    disable(thisObj, "txtNetWt");
    //disable(thisObj, 'btnOpen')
    disable(thisObj, "btnPrint");
    let Data2 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1080', '003', 'D', null)
    if (Data2[0].permission == 'N') {
      disable(thisObj, "btnFrtAccr");
      enable(thisObj, "btnViewRelAccr");
      disable(thisObj, "btnMltiTrnsfr");
    }
    let Data3 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1080', '003', 'D', null)
    if (Data3[0].permission == 'Y') {
      enable(thisObj, "btnFrtAccr");
      enable(thisObj, "btnViewRelAccr");
      enable(thisObj, "btnMltiTrnsfr");
    }
    setLoading(false);
  }
  //txtCarrAbbrv change event 
  const VendorDetails = async (vendor_n) => {
    let vendorDetails = getData(thisObj, 'vendorDetails');
    let vendor1 = vendor_n
    if (vendor_n !== "" && vendor_n !== null && vendor_n !== undefined) {
      vendor1 = vendor_n
    } else if (vendorDetails !== null) {
      let vendorDetails_key = vendorDetails.key
      if (vendorDetails_key !== undefined) {
        vendor1 = vendorDetails.VendorNumber
      }
    }
    let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails(vendor1, '');
    if (GetScac.length > 0) {
      setValue(thisObj, 'txtCarrAbbrv', GetScac[0].scac)
    }
    if (vendornamevisible == true) {
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
      setValue(thisObj, "txtVndrName", venname)
    }
    else {
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      setValue(thisObj, "txtVndrName", "")
    }
  }
  // blur event of txtCarrAbbrv
  const ontxtCarrAbbrvBlur = async () => {
    let js = [];
    let txtCarrier_Abbrv = getValue(thisObj, 'txtCarrAbbrv')
    //let txtCarrier_Abbrv = txtCarrAbbrv.value
    if (txtCarrier_Abbrv !== '') {
      let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails('', txtCarrier_Abbrv);
      if (GetScac.length > 0) {
        show(thisObj, 'txtVndrName')
        setValue(thisObj, 'txtCarrAbbrv', GetScac[0].scac)
        setValue(thisObj, 'txtVndrName', GetScac[0].name)
        setValue(thisObj, 'txtVndr', GetScac[0].number)
      }
      else {
        hide(thisObj, 'txtVndrName', false)
        setValue(thisObj, 'txtVndr', '')
        setValue(thisObj, 'txtVndrName', '')
      }
    }
    else {
      hide(thisObj, 'txtVndrName', false)
      setValue(thisObj, 'txtVndr', '')
    }
  }
  thisObj.ontxtCarrAbbrvBlur = ontxtCarrAbbrvBlur;
  // blur event of txtVndr
  const ontxtVndrBlur = async () => {
    try{
      txtVndr.value = txtVndr.value == '' ? '' : txtVndr.value.toLocaleUpperCase()
      let txtVndrNumber = txtVndr.value
      if (txtVndrNumber.length > 6)
        txtVndr.value = txtVndrNumber.slice(0, 6)
      if (txtVndrNumber.length == 6) {
        let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails(txtVndrNumber, '');
        if (GetScac.length > 0) {
          show(thisObj, 'txtVndrName')
          setValue(thisObj, 'txtCarrAbbrv', GetScac[0].scac)
          setValue(thisObj, 'txtVndrName', GetScac[0].name)
          setValue(thisObj, 'txtVndr', GetScac[0].number)
        } else {
          setValue(thisObj, 'txtVndr', '')
          setValue(thisObj, 'txtCarrAbbrv', '')
          setValue(thisObj, 'txtVndrName', '')
        }
      }
      else {
        hide(thisObj, 'txtVndrName', false)
        setValue(thisObj, 'txtCarrAbbrv', '')
      }
      txtVndr.value = txtVndr.value.toLocaleUpperCase()
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during ontxtVendorChange change event");
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVndrBlur = ontxtVndrBlur;
  // Vendor click button
  const onbtnLookupClick = async () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on Vendor click event")
      }
      return false
    }
  };
  thisObj.onbtnLookupClick = onbtnLookupClick;
  // on change event for Receiving location
  const onddRcvLocChange = () => {
    bFillDefaultWhseBin1();
  }
  thisObj.onddRcvLocChange = onddRcvLocChange;
  // on change event for Shipping location
  const onddShippingLocChange = () => {
    bFillDefaultWhseBin();
    bFillReceivingLocationList();
  }
  thisObj.onddShippingLocChange = onddShippingLocChange;
  //Pascal event
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }
  // Location  dropdown binding function
  const bFillBuyingPointList = () => {
    try {
      let js = []
      js.push({ key: '', description: '>>>All Locations <<<' });
      ContractManagementService.RetrieveBuyingPointControlDetails('PN1080', null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(data[i].buy_pt_name).trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLoc: {
              ...state["ddShippingLoc"],
              valueList: js
            }
          }
        })
      })
      setValue(thisObj, 'ddShippingLoc', js[0].key);
      onddShippingLocChange();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during Location change event");
      }
      return false;
    }
    return true;
  }
  // Location  dropdown binding function
  const bFillReceivingLocationList = () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> All Locations <<<' })
      let buy_point_id = getValue(thisObj, 'ddShippingLoc');
      SettlementService.RetrieveTransferBuyingPointDetails(buy_point_id, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].xfer_buy_pt_id.trim() + ' - ' + ConvertToPascalCase(data[i].xfer_buy_pt_name).trim() }
          js.push(obj)
        }
      })
      thisObj.setState(current => {
        return {
          ...current,
          ddRcvLoc: {
            ...state["ddRcvLoc"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddRcvLoc', js[0].key);
      onddRcvLocChange();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during Location change event");
      }
      return false;
    }
    return true;
  }
  // WhseBin  dropdown binding function
  const bFillDefaultWhseBin = () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> All Whse/Bin <<<' })
      let buy_pt_id = getValue(thisObj, 'ddShippingLoc');
      if (buy_pt_id != null && buy_pt_id != undefined && buy_pt_id != "") {
        SettlementService.RetrieveWhouseBinInventoryDetails(buy_pt_id, null, null, null, null).then(response => {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].whouseNum + '-' + data[i].binNum, description: data[i].whouseNum.trim() + ' - ' + data[i].binNum.trim() + ' - ' + data[i].whouseBinId.trim() }
            js.push(obj)
          }
        })
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddFrmWhse: {
            ...state["ddFrmWhse"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddFrmWhse', js[0].key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during whouseNum change event");
      }
      return false;
    }
    return true;
  }
  // WhseBin  dropdown binding function
  const lFillDefaultWhseBin = () => {
    let js = [];
    let obj = ({ key: '', description: '>>> All Whse/Bin <<<' });
    js.push(obj)
    thisObj.setState(current => {
      return {
        ...current,
        ddFrmWhse: {
          ...state["ddFrmWhse"],
          valueList: js
        }
      }
    })
  }
  // WhseBin  dropdown binding function
  const bFillDefaultWhseBin1 = () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> All Whse/Bin <<<' })
      let buy_pt_id = getValue(thisObj, 'ddRcvLoc');
      if (buy_pt_id != null && buy_pt_id != undefined && buy_pt_id != "") {
        SettlementService.RetrieveWhouseBinInventoryDetails(buy_pt_id, null, null, null, null).then(response => {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].whouseNum + '-' + data[i].binNum, description: data[i].whouseNum.trim() + ' - ' + data[i].binNum.trim() + ' - ' + data[i].whouseBinId.trim() }
            js.push(obj)
          }
        })
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddToWhse: {
            ...state["ddToWhse"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddToWhse', js[0].key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during whouseNum change event");
      }
      return false;
    }
    return true;
  }
  // WhseBin  dropdown binding function
  const lFillDefaultWhseBin1 = () => {
    let js = [];
    let obj = ({ key: '', description: '>>> All Whse/Bin <<<' });
    js.push(obj)
    thisObj.setState(current => {
      return {
        ...current,
        ddToWhse: {
          ...state["ddToWhse"],
          valueList: js
        }
      }
    })
  }
  //on change event of Location function
  const onddRcvLoc = () => {
    try {
      setLoading(true)
      let ReceivingLocationList = thisObj.state['ddRcvLoc'].valueList;
      let SelectedReceivingLocationValue = getValue(thisObj, "ddRcvLoc", '')
      let buy_point_id = ReceivingLocationList.find(elem => elem.key === SelectedReceivingLocationValue).key;

      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      bFillDefaultWhseBin1(buyingPointId, buy_point_id);
      //bFillToVarietyList(buyingPointId,pnut_type_id);
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Receving Location dropdown change event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };
  thisObj.onddRcvLoc = onddRcvLoc;
  // on change event of Location function
  const onddShippingLoc = () => {
    try {
      setLoading(true)
      let BuyingPointList = thisObj.state['ddShippingLoc'].valueList;
      let SelectedShippingLocationValue = getValue(thisObj, "ddShippingLoc", '')
      let buy_point_id = BuyingPointList.find(elem => elem.key === SelectedShippingLocationValue).key;

      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      bFillDefaultWhseBin(buyingPointId, buy_point_id);
      //bFillFromVarietyList(buyingPointId,pnut_type_id);
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Shipping Location dropdown change event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };
  thisObj.onddShippingLoc = onddShippingLoc;
  // on change event for variety function
  const bFillToVarietyList = async(buyingPointId, pnut_type_id) => {
    try {
      let js = [];
      let obj = ({ key: '', description: '>>> All Variety<<<' });
      js.push(obj)
      if (pnut_type_id !== null && pnut_type_id !== '') {
        let response = await ContractManagementService.RetrievePeanutVarietyControls(null, null, null, pnut_type_id, null)
          //.then(response => {
            let data = response;
            if (data !== null && data !== undefined && data.length > 0) {
              let js1 = [];
              for (var i = 0; i < data.length; i++) {
                if (!(js1.includes(data[i].pnut_variety_id))) {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                  if (data[i].symbol_ind == "TRADEMARK") {
                    obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
                  }
                  if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                    obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
                  }
                  if (data[i].symbol_ind == "COPYRIGHT") {
                    obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
                  }
                  js.push(obj);
                  js1.push(data[i].pnut_variety_id)
                }
              }
            }
          //})
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddToVrty: {
            ...state["ddToVrty"],
            valueList: js
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during vatiety change event");
      }
      return false;
    }
    return true;
  }
  const lFillToVarietyList = () => {
    let js = [];
    let obj = ({ key: '', description: '>>> All Variety<<<' });
    js.push(obj)
    thisObj.setState(current => {
      return {
        ...current,
        ddToVrty: {
          ...state["ddToVrty"],
          valueList: js
        }
      }
    })
  }
  // on change event for variety function
  const bFillFromVarietyList = async(buyingPointId, pnut_type_id) => {
    try {
      let js = [];
      let obj = ({ key: '', description: '>>> All Variety<<<' });
      js.push(obj)
      if (pnut_type_id !== null && pnut_type_id !== '') {
        let js1 = [];
        ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
          .then(response => {
            let data = response;
            if (data !== null && data !== undefined && data.length > 0) {
              for (var i = 0; i < data.length; i++) {
                if (!(js1.includes(data[i].pnut_variety_id))) {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                  if (data[i].symbol_ind == "TRADEMARK") {
                    obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
                  }
                  if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                    obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
                  }
                  if (data[i].symbol_ind == "COPYRIGHT") {
                    obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
                  }
                  js.push(obj);
                  js1.push(data[i].pnut_variety_id)
                }
              }
            }
          })
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddFrmVrty: {
            ...state["ddFrmVrty"],
            valueList: js
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during whouseNum change event");
      }
      return false;
    }
    return true;
  }
  const lFillFromVarietyList = () => {
    let js = [];
    let obj = ({ key: '', description: '>>> All Variety<<<' });
    js.push(obj)
    thisObj.setState(current => {
      return {
        ...current,
        ddFrmVrty: {
          ...state["ddFrmVrty"],
          valueList: js
        }
      }
    })
  }
  //peanut type on change function
  const onddPnutTypChange = async() => {
    try {
      setLoading(true)
      let PeanutTypelist = thisObj.state['ddPnutTyp'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPnutTyp", '')
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddShippingLoc'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddShippingLoc", '')
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      setValue(thisObj, 'ddFrmVrty', '')
      setValue(thisObj, 'ddToVrty', '')
      await bFillToVarietyList(buyingPointId, pnut_type_id);
      await bFillFromVarietyList(buyingPointId, pnut_type_id);

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };
  thisObj.onddPnutTypChange = onddPnutTypChange;
  //Method for binding Peanut Type dropdown
  const bFillTypeList = () => {
    try{
      let js = []
      js.push({ key: '', description: '>>> All Types <<<' })
      ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
        let data = response
        if (response !== undefined && response.length > 0) {
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
            js.push(obj)
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPnutTyp: {
              ...state["ddPnutTyp"],
              valueList: js,
            }
          }
        })
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during whouseNum change event");
      }
      return false;
    }
    return true;
  }
  //Method for binding Transfer Type dropdown
  const bFillTransferTypeList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' })
    js.push({ key: 'ST', description: 'Stock Transfer' })
    js.push({ key: 'FF', description: 'Farm Freight' })
    js.push({ key: 'NL', description: 'No Load' })
    thisObj.setState(current => {
      return {
        ...current,
        ddType: {
          ...state["ddType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddType', js[0].key);
  }
  //Method for binding PayFreight dropdown
  const bFillPayFreightComboBox = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'N', description: 'No' })
    js.push({ key: 'Y', description: 'Yes' })
    thisObj.setState(current => {
      return {
        ...current,
        ddPayFrght: {
          ...state["ddPayFrght"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddPayFrght', js[0].key);
  }
  //Method for binding seg dropdown
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>>  All Segs <<<' })
    js.push({ key: '1', description: 'Seg1' })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: 'Seg3' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSegType: {
          ...state["ddSegType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSegType', js[0].key);
  }
  //Method for binding TransferStatus dropdown
  const bFillTransferStatusList = () => {
    let js = []
    js.push({ key: '', description: '>>>  All Statuses <<<' })
    js.push({ key: 'S', description: 'Shipped' })
    js.push({ key: 'R', description: 'Received' })
    js.push({ key: 'V', description: 'Void' })
    js.push({ key: 'P', description: 'Pending' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSts: {
          ...state["ddSts"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSts', js[0].key);
  }
  //Method for binding FrtAccrlComplete dropdown
  const bFillFrtAccrlCompleteList = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'N', description: 'No' })
    js.push({ key: 'Y', description: 'Yes' })
    thisObj.setState(current => {
      return {
        ...current,
        ddFrghtAccr: {
          ...state["ddFrghtAccr"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddFrghtAccr', js[0].key);
  }
  //Method for binding Oleic List dropdown
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddFrmOlc: {
          ...state["ddFrmOlc"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddFrmOlc', js[0].key);
  }
  //Method for binding Oleic List dropdown
  const bFillOleicList1 = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddToOlec: {
          ...state["ddToOlec"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddToOlec', js[0].key);
  }
  //Method for binding Generation List dropdown
  const bFillGenerationList = () => {
    let js = []
    js.push({ key: '', description: ' >>> All Gen <<< ' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    js.push({ key: 'NS', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddFrmGen: {
          ...state["ddFrmGen"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddFrmGen', js[0].key);
  }
  //Method for binding Generation List dropdown
  const bFillGenerationList1 = () => {
    let js = []
    js.push({ key: '', description: ' >>> All Gen <<< ' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    js.push({ key: 'NS', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddToGen: {
          ...state["ddToGen"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddToGen', js[0].key);
  }
  //Method for binding organic dropdown
  const bFillDefaultorganic = () => {
    let js = []
    js.push({ key: '', description: ' >>>   Both   <<< ' })
    js.push({ key: '1', description: 'Organic' })
    js.push({ key: '2', description: 'Non-Organic' })
    thisObj.setState(current => {
      return {
        ...current,
        ddFrmOrgnc: {
          ...state["ddFrmOrgnc"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddFrmOrgnc', js[0].key);
  }
  //Method for binding organic dropdown
  const bFillDefaultorganic1 = () => {
    let js = []
    js.push({ key: '', description: ' >>>   Both   <<< ' })
    js.push({ key: '1', description: 'Organic' })
    js.push({ key: '2', description: 'Non-Organic' })
    thisObj.setState(current => {
      return {
        ...current,
        ddToOrgnc: {
          ...state["ddToOrgnc"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddToOrgnc', js[0].key);
  }
  function ConvertToDate(str) {
    try {
      if (str !== undefined && str !== '') {
        let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
      } else {
        return '';
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ConvertToDate event:Click");
      }
      return false;
    }
    return true;
  }
  //converting date into dd/mm/yyyy formt
  function convert(str) {//for date conversion
    if(str !== undefined && str !== null && str !==''){//added this validation As part pf UAT Bug fix for date related.
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    }else{
      str = ''
    }
  }
  function formatString(str) {
    // Check if string contains a number
    if (!isNaN(str)) {
      // If yes, format string with commas
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // If no, leave string as it is
      return str;
    }
  }
  const setRowColor = async (gridcolor) => {// for grid row color change according to status
    let val = gridcolor
    let colordata = getData(thisObj, "colorWhite")
    for (let i = 0; i <= (val?.length); i++) {
      if (val[i]?.txtcolSts == "Received") {
        if (document.getElementById(`row-${i}`) != undefined) {
          document.getElementById(`row-${i}`).style.color = "black"
        }
      }
      if (val[i]?.txtcolSts == "Shipped") {
        if (document.getElementById(`row-${i}`) != undefined) {
          document.getElementById(`row-${i}`).style.color = "black"
        }
      }
      if (val[i]?.txtcolSts == "Void") {
        if (document.getElementById(`row-${i}`) != undefined) {
          document.getElementById(`row-${i}`).style.color = "red"
        }
      }

      if (val[i]?.txtcolSts == "Pending") {
        if (document.getElementById(`row-${i}`) != undefined) {
          document.getElementById(`row-${i}`).style.color = "black"
        }
      }
    }
  }
  // search button functionality
  const onbtnSrchClick = async () => {
    try {
      let func_id = 'PN1080'
      let func_sub_id = null
      let transfer_num = getValue(thisObj, "txtTrnsfr", '')
      let transfer_type = getValue(thisObj, "ddType", '')
      let xfer_status = getValue(thisObj, "ddSts", '')
      let seg_type = getValue(thisObj, "ddSegType", '')
      let peanut_type = getValue(thisObj, "ddPnutTyp", '')
      let shp_buy_pt = getValue(thisObj, "ddShippingLoc", '')
      let from_variety = getValue(thisObj, "ddFrmVrty", '')
      let from_generation = getValue(thisObj, "ddFrmGen", '')
      let from_oleic = getValue(thisObj, "ddFrmOlc", '')
      let from_organic_ind = getValue(thisObj, "ddFrmOrgnc", '')
      let rec_buy_pt = getValue(thisObj, "ddRcvLoc", '')
      let to_variety = getValue(thisObj, "ddToVrty", '')
      let to_generation = getValue(thisObj, "ddToGen", '')
      let to_oleic = getValue(thisObj, "ddToOlec", '')
      let to_organic_ind = getValue(thisObj, "ddToOrgnc", '')
      let pay_freight_ind = getValue(thisObj, "ddPayFrght", '')
      let frt_accrl_complete = getValue(thisObj, "ddFrghtAccr", '')
      let frt_scac_id = getValue(thisObj, "txtCarrAbbrv", '')
      let frt_vendor = getValue(thisObj, "txtVndr", '')
      let from_whse_num = "";
      let from_bin_num = "";
      if (getValue(thisObj, "ddFrmWhse", '') != undefined && getValue(thisObj, "ddFrmWhse", '') != "" && getValue(thisObj, "ddFrmWhse", '') != null) {
        let fromvals = getValue(thisObj, "ddFrmWhse", '').split("-");
        from_whse_num = fromvals[0];
        from_bin_num = fromvals[1];
      }
      let to_whse_num = "";
      let to_whse_bin = "";
      if (getValue(thisObj, "ddToWhse", '') != undefined && getValue(thisObj, "ddToWhse", '') != "" && getValue(thisObj, "ddToWhse", '') != null) {
        let tovals = getValue(thisObj, "ddToWhse", '').split("-");
        to_whse_num = tovals[0];
        to_whse_bin = tovals[1];
      }

      //Fixed as part of uat bug related to dates.
      let start_shp_date = null
      let start_shp_date1 = getValue(thisObj, "dtShippdDtFrm")
      if (start_shp_date1 != undefined) {
        if (start_shp_date1.length >= 8) {
          start_shp_date1 = start_shp_date1.split(' ')[0]
          start_shp_date = start_shp_date1;
        } else {
          start_shp_date = convert(getValue(thisObj, "dtShippdDtFrm")['_d'])
          if (start_shp_date == "aN/aN/NaN") {
            start_shp_date = ""
          }
        }
      }

      //Fixed as part of uat bug related to dates.
      let end_shp_date = null
      let end_shp_date1 = getValue(thisObj, "dtShippdTo")
      if (end_shp_date1 != undefined) {
        if (end_shp_date1.length >= 8) {
          end_shp_date1 = end_shp_date1.split(' ')[0]
          end_shp_date = end_shp_date1;
        } else {
          end_shp_date = convert(getValue(thisObj, "dtShippdTo")['_d'])
          if (end_shp_date == "aN/aN/NaN") {
            end_shp_date = ""
          }
        }
      }

      //Fixed as part of uat bug related to dates.
      let start_rec_date = null
      let start_rec_date1 = getValue(thisObj, "dtRcvdFrm")
      if (start_rec_date1 != undefined) {
        if (start_rec_date1.length >= 8) {
          start_rec_date1 = start_rec_date1.split(' ')[0]
          start_rec_date = start_rec_date1;
        } else {
          start_rec_date = convert(getValue(thisObj, "dtRcvdFrm")['_d'])
          if (start_rec_date == "aN/aN/NaN") {
            start_rec_date = ""
          }
        }
      }

      //Fixed as part of uat bug related to dates.
      let end_rec_date = null
      let end_rec_date1 = getValue(thisObj, "dtRcvdTo")
      if (end_rec_date1 != undefined) {
        if (end_rec_date1.length >= 8) {
          end_rec_date1 = end_rec_date1.split(' ')[0]
          end_rec_date = end_rec_date1;
        } else {
          end_rec_date = convert(getValue(thisObj, "dtRcvdTo")['_d'])
          if (end_rec_date == "aN/aN/NaN") {
            end_rec_date = ""
          }
        }
      }

      if ((shp_buy_pt == null || shp_buy_pt == undefined || shp_buy_pt == "") &&
        (rec_buy_pt == null || rec_buy_pt == undefined || rec_buy_pt == "") &&
        (transfer_num == null || transfer_num == undefined || transfer_num == "") &&
        (frt_scac_id == null || frt_scac_id == undefined || frt_scac_id == "") &&
        (frt_vendor == null || frt_vendor == undefined || frt_vendor == "")) {
        alert("Must enter Shipping or Receiving location, when not specifying \nTransfer number.");
      }
      else {
        setLoading(true);
        let response = await StockTransferService.RetrieveTransferHeaderList(func_id, func_sub_id, transfer_num, transfer_type, xfer_status, seg_type, peanut_type, start_shp_date, end_shp_date, start_rec_date, end_rec_date, shp_buy_pt, from_whse_num, from_bin_num, from_variety, from_generation, from_oleic, from_organic_ind, rec_buy_pt, to_whse_num, to_whse_bin, to_variety, to_generation, to_oleic, to_organic_ind, pay_freight_ind, frt_accrl_complete, frt_scac_id, frt_vendor)
        let LstrList = response;
        let TransformedRowsArray = [];
        let obj = {}
        if (LstrList != undefined && LstrList.length > 0) {
          enable(thisObj, "btnPrint");
          let dblTotReceivWeight = 0;
          let dblTotShippingWeight = 0;
          let dblTotWeightVariance = 0;
          let dblTotUpdateInvWeight = 0;
          let dbltotNetWeight = 0;
          for (var i = 0; i < LstrList.length; i++) {
            let objContarray = []
            let objCont = {}
            obj.txtcolTrnsfr = LstrList[i].transfer_num
            obj.txtcolType = LstrList[i].transfer_type
            obj.txtcolSts = LstrList[i].xfer_status
            switch (LstrList[i].xfer_status) {
              case "R":
                obj.txtcolSts = "Received"
                break;

              case "S":
                obj.txtcolSts = "Shipped"
                break;

              case "V":
                obj.txtcolSts = "Void"
                break;

              case "P":
                obj.txtcolSts = "Pending"
                break;

              default:
                obj.txtcolSts = LstrList[i].xfer_status
            }
            obj.txtcolShppngDate = moment(LstrList[i].shp_date).format("MM/DD/YYYY") == "Invalid date" ? "" : moment(LstrList[i].shp_date).format("MM/DD/YYYY")
            obj.txtcolPnutType = LstrList[i].peanut_type
            obj.txtcolSegType = LstrList[i].seg
            obj.txtcolShpngLoc = LstrList[i].shp_buy_pt
            obj.txtcolFrmWhse = LstrList[i].shp_whs_bin
            obj.txtcolRcvLoc = LstrList[i].rec_buy_pt
            obj.txtcolToWhse = LstrList[i].rec_whs_bin
            obj.txtcolFrmVar = LstrList[i].from_variety
            switch (LstrList[i].from_symbol) {
              case "TRADEMARK":
                obj.txtcolFrmVar = LstrList[i].from_variety + ' ᵀᴹ';
                break;

              case "REGISTERED TRADEMARK":
                obj.txtcolFrmVar = LstrList[i].from_variety + ' ®';
                break;

              case "COPYRIGHT":
                obj.txtcolFrmVar = LstrList[i].from_variety + ' ©';
                break;

              default:
                obj.txtcolFrmVar = LstrList[i].from_variety
            }
            obj.txtcolToVar = LstrList[i].to_variety
            switch (LstrList[i].to_symbol) {
              case "TRADEMARK":
                obj.txtcolToVar = LstrList[i].to_variety + ' ᵀᴹ';
                break;

              case "REGISTERED TRADEMARK":
                obj.txtcolToVar = LstrList[i].to_variety + ' ®';
                break;

              case "COPYRIGHT":
                obj.txtcolToVar = LstrList[i].to_variety + ' ©';
                break;

              default:
                obj.txtcolToVar = LstrList[i].to_variety
            }
            obj.txtcolFrmGen = LstrList[i].from_gen
            obj.txtcolToGen = LstrList[i].to_gen
            obj.txtcolFrmOlec = LstrList[i].from_oleic
            obj.txtcolToOleic = LstrList[i].to_oleic
            obj.txtcolFrmOrgnc = LstrList[i].from_organic
            obj.txtcolToOrgnc = LstrList[i].to_organic
            obj.txtcolRcvWt = LstrList[i].rec_peanut_wt
            obj.txtcolShppngWt = LstrList[i].shp_peanut_wt
            obj.txtcolWtVarnc = LstrList[i].rec_peanut_wt - LstrList[i].shp_peanut_wt
            obj.txtcolUpdtInvWt = LstrList[i].inv_weight
            obj.txtcolNtWt = LstrList[i].net_wt
            if ((LstrList[i].xfer_status)?.toUpperCase() != "V") {
              if (!isNaN(LstrList[i].rec_peanut_wt)) {
                dblTotReceivWeight = Number(dblTotReceivWeight) + Number(LstrList[i].rec_peanut_wt)
              }
              else {
                dblTotReceivWeight = Number(dblTotReceivWeight) + Number(0)
              }
              if (!isNaN(LstrList[i].shp_peanut_wt)) {
                dblTotShippingWeight = Number(dblTotShippingWeight) + Number(LstrList[i].shp_peanut_wt)
              }
              else {
                dblTotShippingWeight = Number(dblTotShippingWeight) + Number(0)
              }
              if (!isNaN((LstrList[i].rec_peanut_wt) - (LstrList[i].shp_peanut_wt))) {
                dblTotWeightVariance = Number(dblTotWeightVariance) + ((Number(LstrList[i].rec_peanut_wt)) - (Number(LstrList[i].shp_peanut_wt)))
              }
              else {
                dblTotWeightVariance = Number(dblTotWeightVariance) + Number(0)
              }
           
            if ((LstrList[i].transfer_type)?.toUpperCase() == "ST") {
              if (!isNaN(LstrList[i].inv_weight)) {
                dblTotUpdateInvWeight = Number(dblTotUpdateInvWeight) + Number(LstrList[i].inv_weight)
              }
              else {
                dblTotUpdateInvWeight = Number(dblTotUpdateInvWeight) + Number(0)
              }
            }
            if (!isNaN(LstrList[i].net_wt)) {
              dbltotNetWeight = Number(dbltotNetWeight) + Number(LstrList[i].net_wt)
            }
            else {
              dbltotNetWeight = Number(dbltotNetWeight) + Number(0)
            }
          }
            obj.txtcolFrtAccrCmplt = LstrList[i].frt_accrl_complete
            obj.txtcolCarrId = LstrList[i].frt_scac_id
            obj.txtcolPayFrgt = LstrList[i].pay_freight_ind
            objCont.Carrier = "Carrier Vendor: " + LstrList[i].frt_vendor
            objCont.Shp = "Ship Loc: " + LstrList[i].shp_bp_name
            objCont.Recv = "Recv Loc: " + LstrList[i].rec_bp_name
            objContarray.push(objCont)
            obj.childrow = (objContarray)
            TransformedRowsArray.push(obj)
            obj = {}
          }
          setValue(thisObj, "gridTrnsfrSrch", TransformedRowsArray);
          //enable(thisObj, 'btnOpen')
          setgridcolor(TransformedRowsArray)
          setData(thisObj, "txtRcvWt", dblTotReceivWeight);
          setData(thisObj, "txtShippdWt", dblTotShippingWeight);
          setData(thisObj, "txtWeightVariance", dblTotWeightVariance);
          setData(thisObj, "txtUpdtInvWt", dblTotUpdateInvWeight);
          setData(thisObj, "txtNetWt", dbltotNetWeight);
          setLoading(false);
          setValue(thisObj, "txtRcvWt", formatString(dblTotReceivWeight));
          setValue(thisObj, "txtShippdWt", formatString(dblTotShippingWeight));
          setValue(thisObj, "txtWeightVariance", formatString(dblTotWeightVariance));
          setValue(thisObj, "txtUpdtInvWt", formatString(dblTotUpdateInvWeight));
          setValue(thisObj, "txtNetWt", formatString(dbltotNetWeight));
          setRowColor(TransformedRowsArray)
        }
        else {
          setValue(thisObj, 'gridTrnsfrSrch', [])
          setValue(thisObj, "txtRcvWt", formatString('0'));
          setValue(thisObj, "txtShippdWt", formatString('0'));
          setValue(thisObj, "txtWeightVariance", formatString('0'));
          setValue(thisObj, "txtUpdtInvWt", formatString('0'));
          setValue(thisObj, "txtNetWt", formatString('0'));
          disable(thisObj, "btnPrint");
          //disable(thisObj, 'btnOpen')
          setLoading(false);
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
      }
      setLoading(false);
      return false
    }
    return true
  };
  thisObj.onbtnSrchClick = onbtnSrchClick;
  // Fill grid method
  const Refresh = async () => {
   let dblTotReceivWeight_new =0
   let dblTotShippingWeight_new=0
   let dblTotWeightVariance_new=0
   let dblTotUpdateInvWt_new=0
   let dblTotNetWt_new=0
    setLoading(true);
    let value = thisObj.state.gridTrnsfrSrch.selected[0];
    let trnansferchangeData = getData(thisObj, 'trnansferchangeData')
    if (trnansferchangeData !== null && trnansferchangeData !== undefined) {
      if (trnansferchangeData.flag) {
        value.txtcolTrnsfr = trnansferchangeData.trnansfernumber
        setData(thisObj, 'trnansferchangeData', {
          flag: false,
          trnansfernumber: ''
        })
      }
    }
    let gridData = getValue(thisObj, 'gridTrnsfrSrch');
    if (value != undefined) {
      gridData[value.rowID$].txtcolTrnsfr = value.transfer_num;
      gridData[value.rowID$].txtcolType = value.transfer_type;
      gridData[value.rowID$].txtcolSts = value.xfer_status;
      gridData[value.rowID$].txtcolShppngDate = value.shp_date;
      gridData[value.rowID$].txtcolPnutType = value.peanut_type;
      gridData[value.rowID$].txtcolSegType = value.seg;
      gridData[value.rowID$].txtcolShpngLoc = value.shp_buy_pt;
      gridData[value.rowID$].txtcolFrmWhse = value.from_whse_num+"_"+ value.from_bin_num;
      gridData[value.rowID$].txtcolRcvLoc = value.rec_buy_pt;
      gridData[value.rowID$].txtcolToWhse = value.to_whse_num+"_"+ value.to_whse_bin;
      gridData[value.rowID$].txtcolFrmVar = value.from_variety;
      gridData[value.rowID$].txtcolToVar = value.to_variety;
      gridData[value.rowID$].txtcolFrmGen = value.from_gen;
      gridData[value.rowID$].txtcolToGen = value.to_gen;
      gridData[value.rowID$].txtcolFrmOlec = value.from_oleic;
      gridData[value.rowID$].txtcolToOleic = value.to_oleic;
      gridData[value.rowID$].txtcolFrmOrgnc = value.from_organic;
      gridData[value.rowID$].txtcolToOrgnc = value.to_organic;
      gridData[value.rowID$].txtcolRcvWt = value.rec_peanut_wt;
      gridData[value.rowID$].txtcolShppngWt = value.shp_peanut_wt;
      gridData[value.rowID$].txtcolWtVarnc = value.net_wt;
      gridData[value.rowID$].txtcolFrtAccrCmplt = value.frt_accrl_complete;
      if (xfer_status != "V" && transfer_type == "ST")
        switch (value.pay_freight_ind) {
          case "S":
            gridData[value.rowID$].txtcolUpdtInvWt = value.shp_peanut_wt
            break;
          case "R":
            gridData[value.rowID$].txtcolUpdtInvWt = value.rec_peanut_wt
            break;
          case "O":
            gridData[value.rowID$].txtcolUpdtInvWt = value.other_wt
            break;
          default:
            gridData[value.rowID$].txtcolUpdtInvWt = ""
        }
      gridData[value.rowID$].txtcolNtWt = value.net_wt;
      gridData[value.rowID$].txtcolCarrId = value.frt_scac_id;
      gridData[value.rowID$].txtcolPayFrgt = value.pay_freight_ind;
      if (xfer_status != "V"){
        dblTotReceivWeight_new = dblTotReceivWeight_new + rec_peanut_wt
      dblTotShippingWeight_new = dblTotShippingWeight_new + shp_peanut_wt
      dblTotWeightVariance_new = dblTotWeightVariance_new + inv_weight
      if ( transfer_type == "ST")
      switch (value.pay_freight_ind) {
        case "S":
          dblTotUpdateInvWt_new=dblTotUpdateInvWt_new+value.shp_peanut_wt
          break;
        case "R":
          dblTotUpdateInvWt_new=dblTotUpdateInvWt_new+value.rec_peanut_wt
          break;
        case "O":
          dblTotUpdateInvWt_new=dblTotUpdateInvWt_new+value.other_wt
          break;
        default:
          dblTotUpdateInvWt_new=0
          dblTotUpdateInvWt_new = 0
          dblTotNetWt_new = dblTotNetWt_new + net_wt
      }
      objCont.Carrier = "Carrier Vendor: " + data[i].frt_vendor
      objCont.Shp = "Ship Loc: " + data[i].shp_bp_name
      objCont.Recv = "Recv Loc: " + data[i].rec_bp_name
      objContarray.push(objCont)
      obj.childrow = (objContarray)
    }
  }
}
  //create new transfer function
  const onbtnCrtNewTrnsfrClick = () => {
    let mTransfer=new clsTransfer();
    let finalData = {
      "mTransfer":mTransfer,
      "TransferRole":"Shipper",
      "FromFrtAccrl":false,
      "ReCreate":false
    }
    setData(thisObj, 'StockTransfer', finalData);
    goTo(thisObj, "StockTransfer#StockTransfer#DEFAULT#true#Click")
  };
  thisObj.onbtnCrtNewTrnsfrClick = onbtnCrtNewTrnsfrClick;
  //printdelivery ticket function
  const onbtnPrntTcktDlvryClick = async () => {
    try {
      let dataselectedrow = thisObj.state.gridTrnsfrSrch.selected;
      let gridrowValues = getValue(thisObj, "gridTrnsfrSrch")
      let RowNumber = getSelectedRowNumber(thisObj, 'gridTrnsfrSrch')
      let mTransfer=new clsTransfer();
      if (gridrowValues != undefined && gridrowValues != null && gridrowValues != "") {
        if (!dataselectedrow[0]) {
          showMessage(thisObj, "A transfer must be selected to print. ")
          return false;
        }
        let objReportPrevTransfer = {};
        objReportPrevTransfer.TransferNumber = gridrowValues[RowNumber].txtcolTrnsfr;
        objReportPrevTransfer.ShpBuyPT = gridrowValues[RowNumber].txtcolShpngLoc;
        objReportPrevTransfer.XFERStatus = gridrowValues[RowNumber].txtcolSts;
        objReportPrevTransfer.TransferType = gridrowValues[RowNumber].txtcolType;
        objReportPrevTransfer.SCAC = gridrowValues[RowNumber].txtcolCarrId;

        let data = {
          "mTransferNumber": gridrowValues[RowNumber].txtcolTrnsfr,
          "mTransferType": gridrowValues[RowNumber].txtcolType,
          "mShippingBuyingPointID": gridrowValues[RowNumber].txtcolShpngLoc
        }
        await mTransfer.Load(data)
        mTransfer.scac(gridrowValues[RowNumber].txtcolCarrId)

        setData(thisObj, 'mTransferData', mTransfer)
        setData(thisObj, 'ReportTitle', 'BillOfLading');
        setData(thisObj, 'ReportPreviewTransferForRPTbill', objReportPrevTransfer);
        goTo(thisObj, "StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click");
      }
      if (!dataselectedrow[0]) {
        showMessage(thisObj, "A transfer must be selected to print. ")
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "A transfer must be selected to print. \n No Transfers Selected"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrntTcktDlvryClick = onbtnPrntTcktDlvryClick;
  //btn Open functionality
  const onbtnOpenClick = async () => {
    setData(thisObj,'btnopen',true)
    let dblTotReceivWeight_old = 0;
    let dblTotShippingWeight_OLD = 0;
    let dblTotWeightVariance_OLD = 0;
    let dblTotUpdateInvWt_OLD = 0;
    let dblTotNetWt_OLD = 0;

    let selectedRows = thisObj.state.gridTrnsfrSrch.selected;
    let gridValues = getValue(thisObj, "gridTrnsfrSrch");
    let row = getSelectedRowNumber(thisObj, "gridTrnsfrSrch");
    if (gridValues != undefined && gridValues != null && gridValues != "") {
      if (!selectedRows[0]) {
        showMessage(
          thisObj,
          "A transfer must be selected to open"
        );
        return false;
      }
      if (selectedRows != undefined) {
        let mShippingBuyingPointID=selectedRows[0].txtcolShpngLoc;
        let mTransferNumber=selectedRows[0].txtcolTrnsfr;
        let mTransferType=selectedRows[0].txtcolType;
        let response = await ContractManagementService.RetrieveAccessPermissionDetails("PN1080", null, "I", mShippingBuyingPointID);
        if (response[0].permission == "Y") {
          let mTransfer=new clsTransfer();
          if (selectedRows[0]?.txtcolSts?.trim()?.toLocaleUpperCase() !== "VOID") {
            dblTotReceivWeight_old = selectedRows[0]?.txtcolRcvWt;
            dblTotShippingWeight_OLD = selectedRows[0]?.txtcolShppngWt;
            dblTotWeightVariance_OLD = selectedRows[0]?.txtcolWtVarnc;
            if (String(mTransferType).toUpperCase() === "ST") {
              dblTotUpdateInvWt_OLD=selectedRows[0].txtcolUpdtInvWt;
            } else {
              dblTotUpdateInvWt_OLD=0;
            }
            dblTotNetWt_OLD = selectedRows[0]?.txtcolNtWt;
          }
          else {
            dblTotReceivWeight_old = 0;
            dblTotShippingWeight_OLD = 0;
            dblTotWeightVariance_OLD = 0;
            dblTotUpdateInvWt_OLD = 0;
            dblTotNetWt_OLD = 0;
          }

          let data = {
            "mTransferNumber": mTransferNumber,
            "mTransferType": mTransferType,
            "mShippingBuyingPointID": mShippingBuyingPointID
          }
          await mTransfer.Load(data)

          let finalData={
            "mTransfer":mTransfer,
            "TransferRole":"",
            "FromFrtAccrl":false,
            "ReCreate":false
          }
          setData(thisObj, 'StockTransfer', finalData);
          goTo(thisObj, "StockTransfer#StockTransfer#DEFAULT#true#Click")
        }
        else{
          showMessage(
            thisObj,
            "Invalid Security Permissions!!!"
          );
          return false;
        }
      }
    }
    if (!selectedRows[0]) {
      showMessage(
        thisObj,
        "A transfer must be selected to open"
      );
      return false;
    }
  }
  thisObj.onbtnOpenClick = onbtnOpenClick;
  
  const ongridTrnsfrSrchDblclick = async () => {
    onbtnOpenClick();
  };
  thisObj.ongridTrnsfrSrchDblclick = ongridTrnsfrSrchDblclick;
 
  // errorHandler fuction
  function errorHandler(err) {
    showMessage(thisObj, err instanceof EvalError ? err.message : 'Invalid Property Array index.')
  }
  // Export to excel function
  const onbtnExportClick = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, "gridTrnsfrSrch")
    let secondaryRows = thisObj.state.gridTrnsfrSrch.expandedRows;
    var fileName = "Transfer Search"
    const excelData = []
    if (datatable != undefined && datatable != null && datatable != "") {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridTrnsfrSrch.columns[0].Visible) {
          rowdata["Transfer #"] = ExcelNumericDataConvert(datatable[i].txtcolTrnsfr);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[1].Visible) {
          rowdata["Type"] = datatable[i].txtcolType;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[2].Visible) {
          rowdata["Status"] = datatable[i].txtcolSts;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[3].Visible) {
          rowdata["Shipped Date"] = datatable[i].txtcolShppngDate
        }
        if (thisObj.state.gridTrnsfrSrch.columns[4].Visible) {
          rowdata["Peanut Type"] = datatable[i].txtcolPnutType;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[5].Visible) {
          rowdata["Seg Type"] = datatable[i].txtcolSegType
        }
        if (thisObj.state.gridTrnsfrSrch.columns[6].Visible) {
          rowdata["Shipping Location"] = datatable[i].txtcolShpngLoc;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[7].Visible) {
          rowdata["From Whse/Bin"] = datatable[i].txtcolFrmWhse
        }
        if (thisObj.state.gridTrnsfrSrch.columns[8].Visible) {
          rowdata["Receiving Loc."] = datatable[i].txtcolRcvLoc;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[9].Visible) {
          rowdata["To Whse/Bin"] = datatable[i].txtcolToWhse
        }
        if (thisObj.state.gridTrnsfrSrch.columns[10].Visible) {
          rowdata["From Var."] = datatable[i].txtcolFrmVar;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[11].Visible) {
          rowdata["To Var."] = datatable[i].txtcolToVar
        }
        if (thisObj.state.gridTrnsfrSrch.columns[12].Visible) {
          rowdata["From Gen."] = datatable[i].txtcolFrmGen;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[13].Visible) {
          rowdata["To Gen."] = datatable[i].txtcolToGen
        }
        if (thisObj.state.gridTrnsfrSrch.columns[14].Visible) {
          rowdata["From Oleic"] = datatable[i].txtcolFrmOlec;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[15].Visible) {
          rowdata["To Oleic"] = datatable[i].txtcolToOleic
        }
        if (thisObj.state.gridTrnsfrSrch.columns[16].Visible) {
          rowdata["From Organic"] = datatable[i].txtcolFrmOrgnc;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[17].Visible) {
          rowdata["To Organic"] = datatable[i].txtcolToOrgnc;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[18].Visible) {
          rowdata["Receive Weight"] = ExcelNumericDataConvert(datatable[i].txtcolRcvWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[19].Visible) {
          rowdata["Shipping Weight"] = ExcelNumericDataConvert(datatable[i].txtcolShppngWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[20].Visible) {
          rowdata["Weight Variance"] = ExcelNumericDataConvert(datatable[i].txtcolWtVarnc);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[21].Visible) {
          rowdata["Update Inv. Weight"] = ExcelNumericDataConvert(datatable[i].txtcolUpdtInvWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[22].Visible) {
          rowdata["Net Weight"] = ExcelNumericDataConvert(datatable[i].txtcolNtWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[23].Visible) {
          rowdata["Frt. Accr. Complete"] = datatable[i].txtcolFrtAccrCmplt
        }
        if (thisObj.state.gridTrnsfrSrch.columns[24].Visible) {
          rowdata["Carrier ID"] = datatable[i].txtcolCarrId;
        }
        if (thisObj.state.gridTrnsfrSrch.columns[25].Visible) {
          rowdata["Pay Freight"] = datatable[i].txtcolPayFrgt;
        }
        excelData.push(rowdata);
        if (secondaryRows.length > 0) {
          for (let k = 0; k < secondaryRows.length; k++) {
            if (secondaryRows[k].rowID$ == i) {
              if (datatable[i].childrow != undefined) {
                for (let j = 0; j < datatable[i].childrow.length; j++) {
                  let extendedData = {};
                  extendedData["Shipping Location"] = datatable[k].childrow[j].Shp.split('Ship Loc:')[1]
                  extendedData["Receiving Loc."] = datatable[k].childrow[j].Recv.split('Recv Loc:')[1]
                  extendedData["Carrier ID"] = datatable[k].childrow[j].Carrier.split('Carrier Vendor:')[1]
                  excelData.push(extendedData);
                }
              }
            }
          }
        }
      }
    }
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  thisObj.onbtnExportClick = onbtnExportClick;
  // print function
  const onbtnPrintClick = () => {
    var datatable = getValue(thisObj, "gridTrnsfrSrch")
    let secondaryRows = thisObj.state.gridTrnsfrSrch.expandedRows;
    if (datatable != undefined && datatable != null && datatable != "") {
      let data = []
      let data2 = []
      let headerarray = []
      let headerarray1 = []
      let headerarray2 = []

      if (thisObj.state.gridTrnsfrSrch.columns[0].Visible) {
        headerarray.push("Transfer #");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[1].Visible) {
        headerarray.push("Type");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[2].Visible) {
        headerarray.push("Status");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[3].Visible) {
        headerarray.push("Shipped Date");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[4].Visible) {
        headerarray.push("Peanut Type");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[5].Visible) {
        headerarray.push("Seg Type");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[6].Visible) {
        headerarray.push("Shipping Location");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[7].Visible) {
        headerarray.push("From Whse/Bin");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[8].Visible) {
        headerarray.push("Receiving Loc.");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[9].Visible) {
        headerarray.push("To Whse/Bin");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[10].Visible) {
        headerarray.push("From Var.");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[11].Visible) {
        headerarray.push("To Var.");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[12].Visible) {
        headerarray.push("From Gen.");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[13].Visible) {
        headerarray.push("To Gen.");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[14].Visible) {
        headerarray.push("From Oleic");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[15].Visible) {
        headerarray.push("To Oleic");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[16].Visible) {
        headerarray.push("From Organic");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[17].Visible) {
        headerarray.push("To Organic");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[18].Visible) {
        headerarray.push("Receive Weight");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[19].Visible) {
        headerarray.push("Shipping Weight");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[20].Visible) {
        headerarray.push("Weight Variance");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[21].Visible) {
        headerarray.push("Update Inv. Weight");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[22].Visible) {
        headerarray.push("Net Weight");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[23].Visible) {
        headerarray.push("Frt. Accr. Complete");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[24].Visible) {
        headerarray.push("Carrier ID");
      }
      if (thisObj.state.gridTrnsfrSrch.columns[25].Visible) {
        headerarray.push("Pay Freight");
      }
      if (headerarray.length > 21) {
        headerarray1 = headerarray.slice(0, 21)
        headerarray2 = headerarray.slice(21, headerarray.length)
      }
      else {
        headerarray1 = headerarray.slice(0, 20)
      }

      for (var i = 0; i < datatable.length; i++) {
        let bodyarray = []
        let childArray1 = []
        let childArray2 = []
        if (thisObj.state.gridTrnsfrSrch.columns[0].Visible) {
          bodyarray.push(datatable[i].txtcolTrnsfr);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[1].Visible) {
          bodyarray.push(datatable[i].txtcolType);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[2].Visible) {
          bodyarray.push(datatable[i].txtcolSts);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[3].Visible) {
          bodyarray.push(datatable[i].txtcolShppngDate);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[4].Visible) {
          bodyarray.push(datatable[i].txtcolPnutType);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[5].Visible) {
          bodyarray.push(datatable[i].txtcolSegType);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[6].Visible) {
          bodyarray.push(datatable[i].txtcolShpngLoc);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[7].Visible) {
          bodyarray.push(datatable[i].txtcolFrmWhse);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[8].Visible) {
          bodyarray.push(datatable[i].txtcolRcvLoc);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[9].Visible) {
          bodyarray.push(datatable[i].txtcolToWhse);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[10].Visible) {
          bodyarray.push(datatable[i].txtcolFrmVar);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[11].Visible) {
          bodyarray.push(datatable[i].txtcolToVar);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[12].Visible) {
          bodyarray.push(datatable[i].txtcolFrmGen);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[13].Visible) {
          bodyarray.push(datatable[i].txtcolToGen);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[14].Visible) {
          bodyarray.push(datatable[i].txtcolFrmOlec);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[15].Visible) {
          bodyarray.push(datatable[i].txtcolToOleic);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[16].Visible) {
          bodyarray.push(datatable[i].txtcolFrmOrgnc);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[17].Visible) {
          bodyarray.push(datatable[i].txtcolToOrgnc);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[18].Visible) {
          bodyarray.push(datatable[i].txtcolRcvWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[19].Visible) {
          bodyarray.push(datatable[i].txtcolShppngWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[20].Visible) {
          bodyarray.push(datatable[i].txtcolWtVarnc);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[21].Visible) {
          bodyarray.push(datatable[i].txtcolUpdtInvWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[22].Visible) {
          bodyarray.push(datatable[i].txtcolNtWt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[23].Visible) {
          bodyarray.push(datatable[i].txtcolFrtAccrCmplt);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[24].Visible) {
          bodyarray.push(datatable[i].txtcolCarrId);
        }
        if (thisObj.state.gridTrnsfrSrch.columns[25].Visible) {
          bodyarray.push(datatable[i].txtcolPayFrgt);
        }


        if (secondaryRows.length > 0) {
          for (let k = 0; k < secondaryRows.length; k++) {
            if (secondaryRows[k].rowID$ == i) {
              if (secondaryRows[k].childrow != undefined) {
                for (let j = 0; j < secondaryRows[k].childrow.length; j++) {
                  let extendedData = []
                  if (thisObj.state.gridTrnsfrSrch.columns[0].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[1].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[2].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[3].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[4].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[5].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[6].Visible) {
                    extendedData.push(secondaryRows[k].childrow[j].Shp.split('Ship Loc:')[1] == undefined ? '' : secondaryRows[k].childrow[j].Shp.split('Ship Loc:')[1])
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[7].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[8].Visible) {
                    extendedData.push(secondaryRows[k].childrow[j].Recv.split('Recv Loc:')[1] == undefined ? '' : secondaryRows[k].childrow[j].Recv.split('Recv Loc:')[1])
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[9].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[10].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[11].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[12].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[13].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[14].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[15].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[16].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[17].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[18].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[19].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[20].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[21].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[22].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[23].Visible) {
                    extendedData.push("")
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[24].Visible) {
                    extendedData.push(secondaryRows[k].childrow[j].Carrier.split('Carrier Vendor:')[1] == undefined ? '' : secondaryRows[k].childrow[j].Carrier.split('Carrier Vendor:')[1])
                  }
                  if (thisObj.state.gridTrnsfrSrch.columns[25].Visible) {
                    extendedData.push("")
                  }
                  if (extendedData.length > 21) {
                    childArray1.push(extendedData.slice(0, 21))
                    childArray2.push(extendedData.slice(21, extendedData.length))
                  }
                  else {
                    childArray1.push(extendedData.slice(0, 20))
                  }
                }
              }
            }
          }
        }
        if (bodyarray.length > 21) {
          data.push(bodyarray.slice(0, 21))
          for (let x = 0; x < childArray1.length; x++) {
            data.push(childArray1[x])
          }
          data2.push(bodyarray.slice(21, bodyarray.lengt))
          for (let y = 0; y < childArray2.length; y++) {
            data2.push(childArray2[y])
          }
        }
        else {
          data.push(bodyarray.slice(0, 21))
          for (let x = 0; x < childArray1.length; x++) {
            data.push(childArray1[x])
          }
        }
      }

      var obj = new jsPDF();
      let n = 0
      let incrementValue = 35

      while (n < data.length) {
        if (n >= incrementValue) {
          obj.addPage();
        }

        obj.autoTable({
          startX: 5,
          startY: 5,
          styles: { fontSize: 4.5, lineWidth: 0.2, theme: 'striped' },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          margin: { top: 5, left: 0.5 },
          body: data.slice(n, n + incrementValue),
          theme: 'plain',
          fontStyle: 'normal',
          head: [headerarray1],

          didParseCell: function (res) {
            if (res?.cell?.text?.includes("Void")) {
              for (var i = 0; i < headerarray1?.length; i++) {
                res.row.cells[i].styles.textColor = 'red'
              }
            }
          }
        });

        if (headerarray2.length > 0) {
          obj.addPage();

          obj.autoTable({
            startX: 5,
            startY: 5,
            styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
            margin: { top: 5, left: 0.5 },
            body: data2.slice(n, n + incrementValue),
            theme: 'plain',
            fontStyle: 'normal',
            head: [headerarray2],

            didParseCell: function (res) {
              if (res?.cell?.text?.includes("Void")) {
                for (var i = 0; i < headerarray2?.length; i++) {
                  res.row.cells[i].styles.textColor = 'red'
                }
              }
            }

          });
        }
        n = n + incrementValue;
      }
      let total = ("Receive Wt" + getValue(thisObj, 'txtRcvWt') +
        " Shipped Wt" + getValue(thisObj, 'txtShippdWt') + " Wt Variance" + getValue(thisObj, 'txtWeightVariance') + " Upd Inv Wt" + getValue(thisObj, 'txtUpdtInvWt') +
        " Net Wt" + (getValue(thisObj, 'txtNetWt')))
      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(9);
        obj.text(' Crop Year' + " " + sessionStorage.getItem('year') + " - " + total, 10, obj.internal.pageSize.height - 10);
        obj.text(pageCurrent?.toString(), obj?.internal?.pageSize?.width - 15, obj?.internal?.pageSize?.height - 10)
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:TransferSearch.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:TransferSearch.cmdPrintSearch_Click")
      }
    }
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
  //ViewRelAccr function
  const onbtnViewRelAccrClick = () => {
    try {
      goTo(thisObj, "StockTransfer#FrtAccrlSearch#DEFAULT#true#Click")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:ViewRelAccr event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnViewRelAccrClick = onbtnViewRelAccrClick;
  //FrtAccr function
  const onbtnFrtAccrClick = () => {
    try {
      goTo(thisObj, "StockTransfer#FrtAccrlSettlement#DEFAULT#true#Click")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:FrtAccr event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnFrtAccrClick = onbtnFrtAccrClick;
  //MltiTrnsfr function
  const onbtnMltiTrnsfrClick = () => {
    try {
      goTo(thisObj, "StockTransfer#MultiStopSearch#DEFAULT#true#Click")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:MltiTrnsfr:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnMltiTrnsfrClick = onbtnMltiTrnsfrClick;
  const onGridRowChange = (rowSelected) => {
    let data3 = rowSelected;
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
      enable(thisObj, 'btnOpen')
    }
  };
  thisObj.onGridRowChange = onGridRowChange;
  const setTabIndex = () => {
    let elements = [
      'txtTrnsfr',
      'ddShippingLoc',
      'ddRcvLoc',
      'dtShippdDtFrm',
      'dtShippdDtFrmCal',
      'dtShippdTo',
      'dtShippdToCal',
      'dtRcvdFrm',
      'dtRcvdFrmCal',
      'dtRcvdTo',
      'dtRcvdToCal',
      'ddSts',
      'ddFrmWhse',
      'ddFrghtAccr',
      'ddToWhse',
      'ddType',
      'ddFrmOrgnc',
      'ddToOrgnc',
      'ddPnutTyp',
      'ddSegType',
      'txtVndr',
      'btnLookup',
      'txtCarrAbbrv',
      'ddFrmVrty',
      'ddToVrty',
      'ddFrmGen',
      'ddToGen',
      'ddFrmOlc',
      'ddToOlec',
      'ddPayFrght',
      'btnSrch',
      'btnCrtNewTrnsfr',
      'btnOpen',
      'gridTrnsfrSrch',
      'btnPrntTcktDlvry',
      'btnViewRelAccr',
      'btnFrtAccr',
      'btnMltiTrnsfr',
      'btnExport',
      'btnPrint',
      'btnClose'
    ]
    elements.map((element, index) => {
      let tabElement = document.querySelector(`#${element}`);
      if(tabElement){
        tabElement.tabIndex = index + 1;
      }
    })
  }
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TransferSearch*/}

              {/* END_USER_CODE-USER_BEFORE_TransferSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxTransferSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTransferSearch*/}

              <GroupBoxWidget
                conf={state.grpbxTransferSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxTransfer*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTransfer*/}

                <GroupBoxWidget conf={state.grpbxTransfer} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtTrnsfr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTrnsfr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTrnsfr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTrnsfr*/}

                  {/* END_USER_CODE-USER_AFTER_txtTrnsfr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddShippingLoc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddShippingLoc*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddShippingLoc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddShippingLoc*/}

                  {/* END_USER_CODE-USER_AFTER_ddShippingLoc*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRcvLoc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRcvLoc*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRcvLoc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRcvLoc*/}

                  {/* END_USER_CODE-USER_AFTER_ddRcvLoc*/}
                  {/* START_USER_CODE-USER_BEFORE_dtShippdDtFrm*/}

                  {/* END_USER_CODE-USER_BEFORE_dtShippdDtFrm*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dtShippdDtFrm}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_dtShippdDtFrm*/}

                  {/* END_USER_CODE-USER_AFTER_dtShippdDtFrm*/}
                  {/* START_USER_CODE-USER_BEFORE_dtShippdTo*/}

                  {/* END_USER_CODE-USER_BEFORE_dtShippdTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dtShippdTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_dtShippdTo*/}

                  {/* END_USER_CODE-USER_AFTER_dtShippdTo*/}
                  {/* START_USER_CODE-USER_BEFORE_dtRcvdFrm*/}

                  {/* END_USER_CODE-USER_BEFORE_dtRcvdFrm*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dtRcvdFrm}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_dtRcvdFrm*/}

                  {/* END_USER_CODE-USER_AFTER_dtRcvdFrm*/}
                  {/* START_USER_CODE-USER_BEFORE_dtRcvdTo*/}

                  {/* END_USER_CODE-USER_BEFORE_dtRcvdTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dtRcvdTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_dtRcvdTo*/}

                  {/* END_USER_CODE-USER_AFTER_dtRcvdTo*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSts*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSts*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSts}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSts*/}

                  {/* END_USER_CODE-USER_AFTER_ddSts*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFrmWhse*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrmWhse*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrmWhse}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrmWhse*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrmWhse*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFrghtAccr*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrghtAccr*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrghtAccr}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrghtAccr*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrghtAccr*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTransfer*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTransfer*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTrnsfr*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTrnsfr*/}

                <GroupBoxWidget conf={state.grpbxTrnsfr} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddToWhse*/}

                  {/* END_USER_CODE-USER_BEFORE_ddToWhse*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddToWhse}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddToWhse*/}

                  {/* END_USER_CODE-USER_AFTER_ddToWhse*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFrmVrty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrmVrty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrmVrty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrmVrty*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrmVrty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddType*/}

                  {/* END_USER_CODE-USER_AFTER_ddType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddToVrty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddToVrty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddToVrty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddToVrty*/}

                  {/* END_USER_CODE-USER_AFTER_ddToVrty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFrmOrgnc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrmOrgnc*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrmOrgnc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrmOrgnc*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrmOrgnc*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFrmGen*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrmGen*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrmGen}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrmGen*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrmGen*/}
                  {/* START_USER_CODE-USER_BEFORE_ddToOrgnc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddToOrgnc*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddToOrgnc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddToOrgnc*/}

                  {/* END_USER_CODE-USER_AFTER_ddToOrgnc*/}
                  {/* START_USER_CODE-USER_BEFORE_ddToGen*/}

                  {/* END_USER_CODE-USER_BEFORE_ddToGen*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddToGen}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddToGen*/}

                  {/* END_USER_CODE-USER_AFTER_ddToGen*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPnutTyp*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPnutTyp*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPnutTyp}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPnutTyp*/}

                  {/* END_USER_CODE-USER_AFTER_ddPnutTyp*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFrmOlc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrmOlc*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrmOlc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrmOlc*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrmOlc*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSegType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                  {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddToOlec*/}

                  {/* END_USER_CODE-USER_BEFORE_ddToOlec*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddToOlec}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddToOlec*/}

                  {/* END_USER_CODE-USER_AFTER_ddToOlec*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVndr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                  {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                  {/* START_USER_CODE-USER_BEFORE_btnLookup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnLookup*/}

                  <ButtonWidget
                    conf={state.btnLookup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnLookup*/}

                  {/* END_USER_CODE-USER_AFTER_btnLookup*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPayFrght*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPayFrght*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPayFrght}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPayFrght*/}

                  {/* END_USER_CODE-USER_AFTER_ddPayFrght*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCarrAbbrv*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCarrAbbrv*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCarrAbbrv}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVndrName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCarrAbbrv*/}

                  {/* END_USER_CODE-USER_AFTER_txtCarrAbbrv*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSrch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSrch*/}

                  <ButtonWidget
                    conf={state.btnSrch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSrch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSrch*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCropYr*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCropYr*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCropYr}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCropYr*/}

                  {/* END_USER_CODE-USER_AFTER_lblCropYr*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCrtNewTrnsfr*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCrtNewTrnsfr*/}

                  <ButtonWidget
                    conf={state.btnCrtNewTrnsfr}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCrtNewTrnsfr*/}

                  {/* END_USER_CODE-USER_AFTER_btnCrtNewTrnsfr*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                  <ButtonWidget
                    conf={state.btnOpen}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTrnsfr*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTrnsfr*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGrid*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrid*/}

                <GroupBoxWidget conf={state.grpbxGrid} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblTrnsfrList*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTrnsfrList*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTrnsfrList}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTrnsfrList*/}

                  {/* END_USER_CODE-USER_AFTER_lblTrnsfrList*/}
                  {/* START_USER_CODE-USER_BEFORE_gridTrnsfrSrch*/}

                  {/* END_USER_CODE-USER_BEFORE_gridTrnsfrSrch*/}

                  <GridWidget
                    conf={state.gridTrnsfrSrch}
                    screenConf={state}
                    linkClick={event => {
                      if (state.gridTrnsfrSrch.selected.length > 0) {
                        thisObj.selectedRow = state.gridTrnsfrSrch.selected[0];
                      }
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridTrnsfrSrch}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onRowChange={selectedRow => onGridRowChange(selectedRow)}
                    setFieldValue={setFieldValue}
                    refObject={thisObj}

                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridTrnsfrSrch*/}

                  {/* END_USER_CODE-USER_AFTER_gridTrnsfrSrch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrid*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrid*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTransferSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTransferSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnPrntTcktDlvry*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrntTcktDlvry*/}

                <ButtonWidget
                  conf={state.btnPrntTcktDlvry}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrntTcktDlvry*/}

                {/* END_USER_CODE-USER_AFTER_btnPrntTcktDlvry*/}
                {/* START_USER_CODE-USER_BEFORE_lblTotal*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblTotal*/}
                {/* START_USER_CODE-USER_BEFORE_txtRcvWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtRcvWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRcvWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRcvWt*/}

                {/* END_USER_CODE-USER_AFTER_txtRcvWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtShippdWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtShippdWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShippdWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShippdWt*/}

                {/* END_USER_CODE-USER_AFTER_txtShippdWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtWeightVariance*/}

                {/* END_USER_CODE-USER_BEFORE_txtWeightVariance*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWeightVariance}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWeightVariance*/}

                {/* END_USER_CODE-USER_AFTER_txtWeightVariance*/}
                {/* START_USER_CODE-USER_BEFORE_txtUpdtInvWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtUpdtInvWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUpdtInvWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUpdtInvWt*/}

                {/* END_USER_CODE-USER_AFTER_txtUpdtInvWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWt*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWt*/}
                {/* START_USER_CODE-USER_BEFORE_btnViewRelAccr*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewRelAccr*/}

                <ButtonWidget
                  conf={state.btnViewRelAccr}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewRelAccr*/}

                {/* END_USER_CODE-USER_AFTER_btnViewRelAccr*/}
                {/* START_USER_CODE-USER_BEFORE_btnFrtAccr*/}

                {/* END_USER_CODE-USER_BEFORE_btnFrtAccr*/}

                <ButtonWidget
                  conf={state.btnFrtAccr}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFrtAccr*/}

                {/* END_USER_CODE-USER_AFTER_btnFrtAccr*/}
                {/* START_USER_CODE-USER_BEFORE_btnMltiTrnsfr*/}

                {/* END_USER_CODE-USER_BEFORE_btnMltiTrnsfr*/}

                <ButtonWidget
                  conf={state.btnMltiTrnsfr}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnMltiTrnsfr*/}

                {/* END_USER_CODE-USER_AFTER_btnMltiTrnsfr*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExport*/}

                {/* END_USER_CODE-USER_BEFORE_btnExport*/}

                <ButtonWidget
                  conf={state.btnExport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExport*/}

                {/* END_USER_CODE-USER_AFTER_btnExport*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TransferSearch*/}

              {/* END_USER_CODE-USER_AFTER_TransferSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_TransferSearch);
