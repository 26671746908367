/* eslint-disable*/
import React from "react";
import AccountPayment_ReviewCheck from "./ReviewCheck";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReviewCheck Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReviewCheck />);
    });
  });
  afterEach(cleanup);
  test("is ReviewCheck Loads Successfully", () => {
    expect(screen.getByText("ReviewCheck")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReviewCheck", () => {
    // START_USER_CODE-USER_ReviewCheck_Custom_Test_Case
    // END_USER_CODE-USER_ReviewCheck_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReviewCheck />);
    });
  });
  afterEach(cleanup);
  test("btnAcctDist(Button Widget) Test Cases", async () => {
    const btnAcctDist = screen.getByTestId("btnAcctDist");
    expect(btnAcctDist).toBeInTheDocument;
    expect(btnAcctDist.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnAcctDist")
    );
  });
  test("Custom Test Cases for btnAcctDist", () => {
    // START_USER_CODE-USER_btnAcctDist_TEST
    // END_USER_CODE-USER_btnAcctDist_TEST
  });
  test("btnAuditHistory(Button Widget) Test Cases", async () => {
    const btnAuditHistory = screen.getByTestId("btnAuditHistory");
    expect(btnAuditHistory).toBeInTheDocument;
    expect(btnAuditHistory.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnAuditHistory")
    );
  });
  test("Custom Test Cases for btnAuditHistory", () => {
    // START_USER_CODE-USER_btnAuditHistory_TEST
    // END_USER_CODE-USER_btnAuditHistory_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCorrectCheck(Button Widget) Test Cases", async () => {
    const btnCorrectCheck = screen.getByTestId("btnCorrectCheck");
    expect(btnCorrectCheck).toBeInTheDocument;
    expect(btnCorrectCheck.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnCorrectCheck")
    );
  });
  test("Custom Test Cases for btnCorrectCheck", () => {
    // START_USER_CODE-USER_btnCorrectCheck_TEST
    // END_USER_CODE-USER_btnCorrectCheck_TEST
  });
  test("btnDeductuionTracking(Button Widget) Test Cases", async () => {
    const btnDeductuionTracking = screen.getByTestId("btnDeductuionTracking");
    expect(btnDeductuionTracking).toBeInTheDocument;
    expect(btnDeductuionTracking.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnDeductuionTracking")
    );
  });
  test("Custom Test Cases for btnDeductuionTracking", () => {
    // START_USER_CODE-USER_btnDeductuionTracking_TEST
    // END_USER_CODE-USER_btnDeductuionTracking_TEST
  });
  test("btnPrinter(Button Widget) Test Cases", async () => {
    const btnPrinter = screen.getByTestId("btnPrinter");
    expect(btnPrinter).toBeInTheDocument;
    expect(btnPrinter.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnPrinter")
    );
  });
  test("Custom Test Cases for btnPrinter", () => {
    // START_USER_CODE-USER_btnPrinter_TEST
    // END_USER_CODE-USER_btnPrinter_TEST
  });
  test("btnPrintStub(Button Widget) Test Cases", async () => {
    const btnPrintStub = screen.getByTestId("btnPrintStub");
    expect(btnPrintStub).toBeInTheDocument;
    expect(btnPrintStub.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnPrintStub")
    );
  });
  test("Custom Test Cases for btnPrintStub", () => {
    // START_USER_CODE-USER_btnPrintStub_TEST
    // END_USER_CODE-USER_btnPrintStub_TEST
  });
  test("btnResendWireConfirmation(Button Widget) Test Cases", async () => {
    const btnResendWireConfirmation = screen.getByTestId(
      "btnResendWireConfirmation"
    );
    expect(btnResendWireConfirmation).toBeInTheDocument;
    expect(btnResendWireConfirmation.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnResendWireConfirmation")
    );
  });
  test("Custom Test Cases for btnResendWireConfirmation", () => {
    // START_USER_CODE-USER_btnResendWireConfirmation_TEST
    // END_USER_CODE-USER_btnResendWireConfirmation_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("AccountPayment:ReviewCheck_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("gridPaymentActivity(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity = screen.getByTestId("gridPaymentActivity");
    let gridPaymentActivitybtn =
      gridPaymentActivity.nextElementSibling.firstElementChild;
    gridPaymentActivity =
      gridPaymentActivity.parentElement.parentElement.parentElement;
    expect(gridPaymentActivity.tagName).toBe("DIV");
    expect(gridPaymentActivity.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPaymentActivity", () => {
    // START_USER_CODE-USER_gridPaymentActivity_TEST
    // END_USER_CODE-USER_gridPaymentActivity_TEST
  });
  test("grpbxCheckDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckDetails = screen.getByTestId("grpbxCheckDetails");
    expect(grpbxCheckDetails.tagName).toBe("BUTTON");
    expect(grpbxCheckDetails.type).toBe("button");
    expect(grpbxCheckDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckDetails", () => {
    // START_USER_CODE-USER_grpbxCheckDetails_TEST
    // END_USER_CODE-USER_grpbxCheckDetails_TEST
  });
  test("grpbxDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails1 = screen.getByTestId("grpbxDetails1");
    expect(grpbxDetails1.tagName).toBe("BUTTON");
    expect(grpbxDetails1.type).toBe("button");
    expect(grpbxDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails1", () => {
    // START_USER_CODE-USER_grpbxDetails1_TEST
    // END_USER_CODE-USER_grpbxDetails1_TEST
  });
  test("grpbxDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails2 = screen.getByTestId("grpbxDetails2");
    expect(grpbxDetails2.tagName).toBe("BUTTON");
    expect(grpbxDetails2.type).toBe("button");
    expect(grpbxDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails2", () => {
    // START_USER_CODE-USER_grpbxDetails2_TEST
    // END_USER_CODE-USER_grpbxDetails2_TEST
  });
  test("grpbxDetails3(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails3 = screen.getByTestId("grpbxDetails3");
    expect(grpbxDetails3.tagName).toBe("BUTTON");
    expect(grpbxDetails3.type).toBe("button");
    expect(grpbxDetails3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails3", () => {
    // START_USER_CODE-USER_grpbxDetails3_TEST
    // END_USER_CODE-USER_grpbxDetails3_TEST
  });
  test("grpbxdetails4(GroupBox Widget) Test Cases", async () => {
    const grpbxdetails4 = screen.getByTestId("grpbxdetails4");
    expect(grpbxdetails4.tagName).toBe("BUTTON");
    expect(grpbxdetails4.type).toBe("button");
    expect(grpbxdetails4.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxdetails4", () => {
    // START_USER_CODE-USER_grpbxdetails4_TEST
    // END_USER_CODE-USER_grpbxdetails4_TEST
  });
  test("grpbxDetails5(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails5 = screen.getByTestId("grpbxDetails5");
    expect(grpbxDetails5.tagName).toBe("BUTTON");
    expect(grpbxDetails5.type).toBe("button");
    expect(grpbxDetails5.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails5", () => {
    // START_USER_CODE-USER_grpbxDetails5_TEST
    // END_USER_CODE-USER_grpbxDetails5_TEST
  });
  test("grpbxPaymentActivityRemarks(GroupBox Widget) Test Cases", async () => {
    const grpbxPaymentActivityRemarks = screen.getByTestId(
      "grpbxPaymentActivityRemarks"
    );
    expect(grpbxPaymentActivityRemarks.tagName).toBe("BUTTON");
    expect(grpbxPaymentActivityRemarks.type).toBe("button");
    expect(grpbxPaymentActivityRemarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPaymentActivityRemarks", () => {
    // START_USER_CODE-USER_grpbxPaymentActivityRemarks_TEST
    // END_USER_CODE-USER_grpbxPaymentActivityRemarks_TEST
  });
  test("lblAcknowledgement(Label Widget) Test Cases", async () => {
    const lblAcknowledgement = screen.getByTestId("lblAcknowledgement");
    expect(lblAcknowledgement.tagName).toBe("LABEL");
    expect(lblAcknowledgement.classList).toContain("form-label");
    expect(lblAcknowledgement.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblAcknowledgement")
    );
  });
  test("Custom Test Cases for lblAcknowledgement", () => {
    // START_USER_CODE-USER_lblAcknowledgement_TEST
    // END_USER_CODE-USER_lblAcknowledgement_TEST
  });
  test("lblAcknowledgementValue(Label Widget) Test Cases", async () => {
    const lblAcknowledgementValue = screen.getByTestId(
      "lblAcknowledgementValue"
    );
    expect(lblAcknowledgementValue.tagName).toBe("LABEL");
    expect(lblAcknowledgementValue.classList).toContain("form-label");
    expect(lblAcknowledgementValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblAcknowledgementValue")
    );
  });
  test("Custom Test Cases for lblAcknowledgementValue", () => {
    // START_USER_CODE-USER_lblAcknowledgementValue_TEST
    // END_USER_CODE-USER_lblAcknowledgementValue_TEST
  });
  test("lblCheck(Label Widget) Test Cases", async () => {
    const lblCheck = screen.getByTestId("lblCheck");
    expect(lblCheck.tagName).toBe("LABEL");
    expect(lblCheck.classList).toContain("form-label");
    expect(lblCheck.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblCheck")
    );
  });
  test("Custom Test Cases for lblCheck", () => {
    // START_USER_CODE-USER_lblCheck_TEST
    // END_USER_CODE-USER_lblCheck_TEST
  });
  test("lblCheckValue(Label Widget) Test Cases", async () => {
    const lblCheckValue = screen.getByTestId("lblCheckValue");
    expect(lblCheckValue.tagName).toBe("LABEL");
    expect(lblCheckValue.classList).toContain("form-label");
    expect(lblCheckValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblCheckValue")
    );
  });
  test("Custom Test Cases for lblCheckValue", () => {
    // START_USER_CODE-USER_lblCheckValue_TEST
    // END_USER_CODE-USER_lblCheckValue_TEST
  });
  test("lblClearDate(Label Widget) Test Cases", async () => {
    const lblClearDate = screen.getByTestId("lblClearDate");
    expect(lblClearDate.tagName).toBe("LABEL");
    expect(lblClearDate.classList).toContain("form-label");
    expect(lblClearDate.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblClearDate")
    );
  });
  test("Custom Test Cases for lblClearDate", () => {
    // START_USER_CODE-USER_lblClearDate_TEST
    // END_USER_CODE-USER_lblClearDate_TEST
  });
  test("lblClearDateValue(Label Widget) Test Cases", async () => {
    const lblClearDateValue = screen.getByTestId("lblClearDateValue");
    expect(lblClearDateValue.tagName).toBe("LABEL");
    expect(lblClearDateValue.classList).toContain("form-label");
    expect(lblClearDateValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblClearDateValue")
    );
  });
  test("Custom Test Cases for lblClearDateValue", () => {
    // START_USER_CODE-USER_lblClearDateValue_TEST
    // END_USER_CODE-USER_lblClearDateValue_TEST
  });
  test("lblFederalReserveRef(Label Widget) Test Cases", async () => {
    const lblFederalReserveRef = screen.getByTestId("lblFederalReserveRef");
    expect(lblFederalReserveRef.tagName).toBe("LABEL");
    expect(lblFederalReserveRef.classList).toContain("form-label");
    expect(lblFederalReserveRef.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblFederalReserveRef")
    );
  });
  test("Custom Test Cases for lblFederalReserveRef", () => {
    // START_USER_CODE-USER_lblFederalReserveRef_TEST
    // END_USER_CODE-USER_lblFederalReserveRef_TEST
  });
  test("lblFederalReserveRefValue(Label Widget) Test Cases", async () => {
    const lblFederalReserveRefValue = screen.getByTestId(
      "lblFederalReserveRefValue"
    );
    expect(lblFederalReserveRefValue.tagName).toBe("LABEL");
    expect(lblFederalReserveRefValue.classList).toContain("form-label");
    expect(lblFederalReserveRefValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblFederalReserveRefValue")
    );
  });
  test("Custom Test Cases for lblFederalReserveRefValue", () => {
    // START_USER_CODE-USER_lblFederalReserveRefValue_TEST
    // END_USER_CODE-USER_lblFederalReserveRefValue_TEST
  });
  test("lblInterestAgree(Label Widget) Test Cases", async () => {
    const lblInterestAgree = screen.getByTestId("lblInterestAgree");
    expect(lblInterestAgree.tagName).toBe("LABEL");
    expect(lblInterestAgree.classList).toContain("form-label");
    expect(lblInterestAgree.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblInterestAgree")
    );
  });
  test("Custom Test Cases for lblInterestAgree", () => {
    // START_USER_CODE-USER_lblInterestAgree_TEST
    // END_USER_CODE-USER_lblInterestAgree_TEST
  });
  test("lblInterestAgreeValue(Label Widget) Test Cases", async () => {
    const lblInterestAgreeValue = screen.getByTestId("lblInterestAgreeValue");
    expect(lblInterestAgreeValue.tagName).toBe("LABEL");
    expect(lblInterestAgreeValue.classList).toContain("form-label");
    expect(lblInterestAgreeValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblInterestAgreeValue")
    );
  });
  test("Custom Test Cases for lblInterestAgreeValue", () => {
    // START_USER_CODE-USER_lblInterestAgreeValue_TEST
    // END_USER_CODE-USER_lblInterestAgreeValue_TEST
  });
  test("lblIssueDate(Label Widget) Test Cases", async () => {
    const lblIssueDate = screen.getByTestId("lblIssueDate");
    expect(lblIssueDate.tagName).toBe("LABEL");
    expect(lblIssueDate.classList).toContain("form-label");
    expect(lblIssueDate.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblIssueDate")
    );
  });
  test("Custom Test Cases for lblIssueDate", () => {
    // START_USER_CODE-USER_lblIssueDate_TEST
    // END_USER_CODE-USER_lblIssueDate_TEST
  });
  test("lblIssueDateValue(Label Widget) Test Cases", async () => {
    const lblIssueDateValue = screen.getByTestId("lblIssueDateValue");
    expect(lblIssueDateValue.tagName).toBe("LABEL");
    expect(lblIssueDateValue.classList).toContain("form-label");
    expect(lblIssueDateValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblIssueDateValue")
    );
  });
  test("Custom Test Cases for lblIssueDateValue", () => {
    // START_USER_CODE-USER_lblIssueDateValue_TEST
    // END_USER_CODE-USER_lblIssueDateValue_TEST
  });
  test("lblIssueUser(Label Widget) Test Cases", async () => {
    const lblIssueUser = screen.getByTestId("lblIssueUser");
    expect(lblIssueUser.tagName).toBe("LABEL");
    expect(lblIssueUser.classList).toContain("form-label");
    expect(lblIssueUser.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblIssueUser")
    );
  });
  test("Custom Test Cases for lblIssueUser", () => {
    // START_USER_CODE-USER_lblIssueUser_TEST
    // END_USER_CODE-USER_lblIssueUser_TEST
  });
  test("lblIssueUserValue(Label Widget) Test Cases", async () => {
    const lblIssueUserValue = screen.getByTestId("lblIssueUserValue");
    expect(lblIssueUserValue.tagName).toBe("LABEL");
    expect(lblIssueUserValue.classList).toContain("form-label");
    expect(lblIssueUserValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblIssueUserValue")
    );
  });
  test("Custom Test Cases for lblIssueUserValue", () => {
    // START_USER_CODE-USER_lblIssueUserValue_TEST
    // END_USER_CODE-USER_lblIssueUserValue_TEST
  });
  test("lbllblReconDateValue(Label Widget) Test Cases", async () => {
    const lbllblReconDateValue = screen.getByTestId("lbllblReconDateValue");
    expect(lbllblReconDateValue.tagName).toBe("LABEL");
    expect(lbllblReconDateValue.classList).toContain("form-label");
    expect(lbllblReconDateValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lbllblReconDateValue")
    );
  });
  test("Custom Test Cases for lbllblReconDateValue", () => {
    // START_USER_CODE-USER_lbllblReconDateValue_TEST
    // END_USER_CODE-USER_lbllblReconDateValue_TEST
  });
  test("lblLienHolder(Label Widget) Test Cases", async () => {
    const lblLienHolder = screen.getByTestId("lblLienHolder");
    expect(lblLienHolder.tagName).toBe("LABEL");
    expect(lblLienHolder.classList).toContain("form-label");
    expect(lblLienHolder.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblLienHolder")
    );
  });
  test("Custom Test Cases for lblLienHolder", () => {
    // START_USER_CODE-USER_lblLienHolder_TEST
    // END_USER_CODE-USER_lblLienHolder_TEST
  });
  test("lblLienHolderValue(Label Widget) Test Cases", async () => {
    const lblLienHolderValue = screen.getByTestId("lblLienHolderValue");
    expect(lblLienHolderValue.tagName).toBe("LABEL");
    expect(lblLienHolderValue.classList).toContain("form-label");
    expect(lblLienHolderValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblLienHolderValue")
    );
  });
  test("Custom Test Cases for lblLienHolderValue", () => {
    // START_USER_CODE-USER_lblLienHolderValue_TEST
    // END_USER_CODE-USER_lblLienHolderValue_TEST
  });
  test("lblLocation(Label Widget) Test Cases", async () => {
    const lblLocation = screen.getByTestId("lblLocation");
    expect(lblLocation.tagName).toBe("LABEL");
    expect(lblLocation.classList).toContain("form-label");
    expect(lblLocation.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblLocation")
    );
  });
  test("Custom Test Cases for lblLocation", () => {
    // START_USER_CODE-USER_lblLocation_TEST
    // END_USER_CODE-USER_lblLocation_TEST
  });
  test("lblLocationValue(Label Widget) Test Cases", async () => {
    const lblLocationValue = screen.getByTestId("lblLocationValue");
    expect(lblLocationValue.tagName).toBe("LABEL");
    expect(lblLocationValue.classList).toContain("form-label");
    expect(lblLocationValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblLocationValue")
    );
  });
  test("Custom Test Cases for lblLocationValue", () => {
    // START_USER_CODE-USER_lblLocationValue_TEST
    // END_USER_CODE-USER_lblLocationValue_TEST
  });
  test("lblPaymentActivity(Label Widget) Test Cases", async () => {
    const lblPaymentActivity = screen.getByTestId("lblPaymentActivity");
    expect(lblPaymentActivity.tagName).toBe("LABEL");
    expect(lblPaymentActivity.classList).toContain("form-label");
    expect(lblPaymentActivity.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblPaymentActivity")
    );
  });
  test("Custom Test Cases for lblPaymentActivity", () => {
    // START_USER_CODE-USER_lblPaymentActivity_TEST
    // END_USER_CODE-USER_lblPaymentActivity_TEST
  });
  test("lblProceeds(Label Widget) Test Cases", async () => {
    const lblProceeds = screen.getByTestId("lblProceeds");
    expect(lblProceeds.tagName).toBe("LABEL");
    expect(lblProceeds.classList).toContain("form-label");
    expect(lblProceeds.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblProceeds")
    );
  });
  test("Custom Test Cases for lblProceeds", () => {
    // START_USER_CODE-USER_lblProceeds_TEST
    // END_USER_CODE-USER_lblProceeds_TEST
  });
  test("lblProceedsValue(Label Widget) Test Cases", async () => {
    const lblProceedsValue = screen.getByTestId("lblProceedsValue");
    expect(lblProceedsValue.tagName).toBe("LABEL");
    expect(lblProceedsValue.classList).toContain("form-label");
    expect(lblProceedsValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblProceedsValue")
    );
  });
  test("Custom Test Cases for lblProceedsValue", () => {
    // START_USER_CODE-USER_lblProceedsValue_TEST
    // END_USER_CODE-USER_lblProceedsValue_TEST
  });
  test("lblReconDate(Label Widget) Test Cases", async () => {
    const lblReconDate = screen.getByTestId("lblReconDate");
    expect(lblReconDate.tagName).toBe("LABEL");
    expect(lblReconDate.classList).toContain("form-label");
    expect(lblReconDate.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblReconDate")
    );
  });
  test("Custom Test Cases for lblReconDate", () => {
    // START_USER_CODE-USER_lblReconDate_TEST
    // END_USER_CODE-USER_lblReconDate_TEST
  });
  test("lblRemitTo(Label Widget) Test Cases", async () => {
    const lblRemitTo = screen.getByTestId("lblRemitTo");
    expect(lblRemitTo.tagName).toBe("LABEL");
    expect(lblRemitTo.classList).toContain("form-label");
    expect(lblRemitTo.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblRemitTo")
    );
  });
  test("Custom Test Cases for lblRemitTo", () => {
    // START_USER_CODE-USER_lblRemitTo_TEST
    // END_USER_CODE-USER_lblRemitTo_TEST
  });
  test("lblRemitToValue(Label Widget) Test Cases", async () => {
    const lblRemitToValue = screen.getByTestId("lblRemitToValue");
    expect(lblRemitToValue.tagName).toBe("LABEL");
    expect(lblRemitToValue.classList).toContain("form-label");
    expect(lblRemitToValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblRemitToValue")
    );
  });
  test("Custom Test Cases for lblRemitToValue", () => {
    // START_USER_CODE-USER_lblRemitToValue_TEST
    // END_USER_CODE-USER_lblRemitToValue_TEST
  });
  test("lblSpecialType(Label Widget) Test Cases", async () => {
    const lblSpecialType = screen.getByTestId("lblSpecialType");
    expect(lblSpecialType.tagName).toBe("LABEL");
    expect(lblSpecialType.classList).toContain("form-label");
    expect(lblSpecialType.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblSpecialType")
    );
  });
  test("Custom Test Cases for lblSpecialType", () => {
    // START_USER_CODE-USER_lblSpecialType_TEST
    // END_USER_CODE-USER_lblSpecialType_TEST
  });
  test("lblSpecialTypeValue(Label Widget) Test Cases", async () => {
    const lblSpecialTypeValue = screen.getByTestId("lblSpecialTypeValue");
    expect(lblSpecialTypeValue.tagName).toBe("LABEL");
    expect(lblSpecialTypeValue.classList).toContain("form-label");
    expect(lblSpecialTypeValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblSpecialTypeValue")
    );
  });
  test("Custom Test Cases for lblSpecialTypeValue", () => {
    // START_USER_CODE-USER_lblSpecialTypeValue_TEST
    // END_USER_CODE-USER_lblSpecialTypeValue_TEST
  });
  test("lblStatus(Label Widget) Test Cases", async () => {
    const lblStatus = screen.getByTestId("lblStatus");
    expect(lblStatus.tagName).toBe("LABEL");
    expect(lblStatus.classList).toContain("form-label");
    expect(lblStatus.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblStatus")
    );
  });
  test("Custom Test Cases for lblStatus", () => {
    // START_USER_CODE-USER_lblStatus_TEST
    // END_USER_CODE-USER_lblStatus_TEST
  });
  test("lblStatusValue(Label Widget) Test Cases", async () => {
    const lblStatusValue = screen.getByTestId("lblStatusValue");
    expect(lblStatusValue.tagName).toBe("LABEL");
    expect(lblStatusValue.classList).toContain("form-label");
    expect(lblStatusValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblStatusValue")
    );
  });
  test("Custom Test Cases for lblStatusValue", () => {
    // START_USER_CODE-USER_lblStatusValue_TEST
    // END_USER_CODE-USER_lblStatusValue_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorValue(Label Widget) Test Cases", async () => {
    const lblVendorValue = screen.getByTestId("lblVendorValue");
    expect(lblVendorValue.tagName).toBe("LABEL");
    expect(lblVendorValue.classList).toContain("form-label");
    expect(lblVendorValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblVendorValue")
    );
  });
  test("Custom Test Cases for lblVendorValue", () => {
    // START_USER_CODE-USER_lblVendorValue_TEST
    // END_USER_CODE-USER_lblVendorValue_TEST
  });
  test("lblVoidDate(Label Widget) Test Cases", async () => {
    const lblVoidDate = screen.getByTestId("lblVoidDate");
    expect(lblVoidDate.tagName).toBe("LABEL");
    expect(lblVoidDate.classList).toContain("form-label");
    expect(lblVoidDate.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblVoidDate")
    );
  });
  test("Custom Test Cases for lblVoidDate", () => {
    // START_USER_CODE-USER_lblVoidDate_TEST
    // END_USER_CODE-USER_lblVoidDate_TEST
  });
  test("lblVoidDateValue(Label Widget) Test Cases", async () => {
    const lblVoidDateValue = screen.getByTestId("lblVoidDateValue");
    expect(lblVoidDateValue.tagName).toBe("LABEL");
    expect(lblVoidDateValue.classList).toContain("form-label");
    expect(lblVoidDateValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblVoidDateValue")
    );
  });
  test("Custom Test Cases for lblVoidDateValue", () => {
    // START_USER_CODE-USER_lblVoidDateValue_TEST
    // END_USER_CODE-USER_lblVoidDateValue_TEST
  });
  test("lblVoidUser(Label Widget) Test Cases", async () => {
    const lblVoidUser = screen.getByTestId("lblVoidUser");
    expect(lblVoidUser.tagName).toBe("LABEL");
    expect(lblVoidUser.classList).toContain("form-label");
    expect(lblVoidUser.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblVoidUser")
    );
  });
  test("Custom Test Cases for lblVoidUser", () => {
    // START_USER_CODE-USER_lblVoidUser_TEST
    // END_USER_CODE-USER_lblVoidUser_TEST
  });
  test("lblVoidUserValue(Label Widget) Test Cases", async () => {
    const lblVoidUserValue = screen.getByTestId("lblVoidUserValue");
    expect(lblVoidUserValue.tagName).toBe("LABEL");
    expect(lblVoidUserValue.classList).toContain("form-label");
    expect(lblVoidUserValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblVoidUserValue")
    );
  });
  test("Custom Test Cases for lblVoidUserValue", () => {
    // START_USER_CODE-USER_lblVoidUserValue_TEST
    // END_USER_CODE-USER_lblVoidUserValue_TEST
  });
  test("lblWellsFargoRef(Label Widget) Test Cases", async () => {
    const lblWellsFargoRef = screen.getByTestId("lblWellsFargoRef");
    expect(lblWellsFargoRef.tagName).toBe("LABEL");
    expect(lblWellsFargoRef.classList).toContain("form-label");
    expect(lblWellsFargoRef.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWellsFargoRef")
    );
  });
  test("Custom Test Cases for lblWellsFargoRef", () => {
    // START_USER_CODE-USER_lblWellsFargoRef_TEST
    // END_USER_CODE-USER_lblWellsFargoRef_TEST
  });
  test("lblWellsFargoRefValue(Label Widget) Test Cases", async () => {
    const lblWellsFargoRefValue = screen.getByTestId("lblWellsFargoRefValue");
    expect(lblWellsFargoRefValue.tagName).toBe("LABEL");
    expect(lblWellsFargoRefValue.classList).toContain("form-label");
    expect(lblWellsFargoRefValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWellsFargoRefValue")
    );
  });
  test("Custom Test Cases for lblWellsFargoRefValue", () => {
    // START_USER_CODE-USER_lblWellsFargoRefValue_TEST
    // END_USER_CODE-USER_lblWellsFargoRefValue_TEST
  });
  test("lblWireData(Label Widget) Test Cases", async () => {
    const lblWireData = screen.getByTestId("lblWireData");
    expect(lblWireData.tagName).toBe("LABEL");
    expect(lblWireData.classList).toContain("form-label");
    expect(lblWireData.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWireData")
    );
  });
  test("Custom Test Cases for lblWireData", () => {
    // START_USER_CODE-USER_lblWireData_TEST
    // END_USER_CODE-USER_lblWireData_TEST
  });
  test("lblWireDataValue(Label Widget) Test Cases", async () => {
    const lblWireDataValue = screen.getByTestId("lblWireDataValue");
    expect(lblWireDataValue.tagName).toBe("LABEL");
    expect(lblWireDataValue.classList).toContain("form-label");
    expect(lblWireDataValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWireDataValue")
    );
  });
  test("Custom Test Cases for lblWireDataValue", () => {
    // START_USER_CODE-USER_lblWireDataValue_TEST
    // END_USER_CODE-USER_lblWireDataValue_TEST
  });
  test("lblWireErrorMsg(Label Widget) Test Cases", async () => {
    const lblWireErrorMsg = screen.getByTestId("lblWireErrorMsg");
    expect(lblWireErrorMsg.tagName).toBe("LABEL");
    expect(lblWireErrorMsg.classList).toContain("form-label");
    expect(lblWireErrorMsg.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWireErrorMsg")
    );
  });
  test("Custom Test Cases for lblWireErrorMsg", () => {
    // START_USER_CODE-USER_lblWireErrorMsg_TEST
    // END_USER_CODE-USER_lblWireErrorMsg_TEST
  });
  test("lblWireErrorMsgValue(Label Widget) Test Cases", async () => {
    const lblWireErrorMsgValue = screen.getByTestId("lblWireErrorMsgValue");
    expect(lblWireErrorMsgValue.tagName).toBe("LABEL");
    expect(lblWireErrorMsgValue.classList).toContain("form-label");
    expect(lblWireErrorMsgValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWireErrorMsgValue")
    );
  });
  test("Custom Test Cases for lblWireErrorMsgValue", () => {
    // START_USER_CODE-USER_lblWireErrorMsgValue_TEST
    // END_USER_CODE-USER_lblWireErrorMsgValue_TEST
  });
  test("lblWireFileID(Label Widget) Test Cases", async () => {
    const lblWireFileID = screen.getByTestId("lblWireFileID");
    expect(lblWireFileID.tagName).toBe("LABEL");
    expect(lblWireFileID.classList).toContain("form-label");
    expect(lblWireFileID.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWireFileID")
    );
  });
  test("Custom Test Cases for lblWireFileID", () => {
    // START_USER_CODE-USER_lblWireFileID_TEST
    // END_USER_CODE-USER_lblWireFileID_TEST
  });
  test("lblWireFileIDValue(Label Widget) Test Cases", async () => {
    const lblWireFileIDValue = screen.getByTestId("lblWireFileIDValue");
    expect(lblWireFileIDValue.tagName).toBe("LABEL");
    expect(lblWireFileIDValue.classList).toContain("form-label");
    expect(lblWireFileIDValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWireFileIDValue")
    );
  });
  test("Custom Test Cases for lblWireFileIDValue", () => {
    // START_USER_CODE-USER_lblWireFileIDValue_TEST
    // END_USER_CODE-USER_lblWireFileIDValue_TEST
  });
  test("lblWirePaymentID(Label Widget) Test Cases", async () => {
    const lblWirePaymentID = screen.getByTestId("lblWirePaymentID");
    expect(lblWirePaymentID.tagName).toBe("LABEL");
    expect(lblWirePaymentID.classList).toContain("form-label");
    expect(lblWirePaymentID.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWirePaymentID")
    );
  });
  test("Custom Test Cases for lblWirePaymentID", () => {
    // START_USER_CODE-USER_lblWirePaymentID_TEST
    // END_USER_CODE-USER_lblWirePaymentID_TEST
  });
  test("lblWirePaymentIDValue(Label Widget) Test Cases", async () => {
    const lblWirePaymentIDValue = screen.getByTestId("lblWirePaymentIDValue");
    expect(lblWirePaymentIDValue.tagName).toBe("LABEL");
    expect(lblWirePaymentIDValue.classList).toContain("form-label");
    expect(lblWirePaymentIDValue.textContent).toEqual(
      t("AccountPayment:ReviewCheck_lblWirePaymentIDValue")
    );
  });
  test("Custom Test Cases for lblWirePaymentIDValue", () => {
    // START_USER_CODE-USER_lblWirePaymentIDValue_TEST
    // END_USER_CODE-USER_lblWirePaymentIDValue_TEST
  });
  test("gridPaymentActivity_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolBuyPt = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolBuyPtbtn =
      gridPaymentActivity_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolBuyPt =
      gridPaymentActivity_txtcolBuyPt.parentElement.parentElement.parentElement;
    expect(gridPaymentActivity_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolBuyPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridPaymentActivity_txtcolNumber1(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolNumber1 = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolNumber1btn =
      gridPaymentActivity_txtcolNumber1.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolNumber1 =
      gridPaymentActivity_txtcolNumber1.parentElement.parentElement
        .parentElement;
    expect(gridPaymentActivity_txtcolNumber1.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolNumber1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1", () => {
    // START_USER_CODE-USER_txtcolNumber1_TEST
    // END_USER_CODE-USER_txtcolNumber1_TEST
  });
  test("gridPaymentActivity_txtcolNumber2(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolNumber2 = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolNumber2btn =
      gridPaymentActivity_txtcolNumber2.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolNumber2 =
      gridPaymentActivity_txtcolNumber2.parentElement.parentElement
        .parentElement;
    expect(gridPaymentActivity_txtcolNumber2.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolNumber2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2", () => {
    // START_USER_CODE-USER_txtcolNumber2_TEST
    // END_USER_CODE-USER_txtcolNumber2_TEST
  });
  test("gridPaymentActivity_txtcolOpt(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolOpt = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolOptbtn =
      gridPaymentActivity_txtcolOpt.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolOpt =
      gridPaymentActivity_txtcolOpt.parentElement.parentElement.parentElement;
    expect(gridPaymentActivity_txtcolOpt.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolOpt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpt", () => {
    // START_USER_CODE-USER_txtcolOpt_TEST
    // END_USER_CODE-USER_txtcolOpt_TEST
  });
  test("gridPaymentActivity_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolProceeds = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolProceedsbtn =
      gridPaymentActivity_txtcolProceeds.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolProceeds =
      gridPaymentActivity_txtcolProceeds.parentElement.parentElement
        .parentElement;
    expect(gridPaymentActivity_txtcolProceeds.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolProceeds.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridPaymentActivity_txtcolType1(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolType1 = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolType1btn =
      gridPaymentActivity_txtcolType1.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolType1 =
      gridPaymentActivity_txtcolType1.parentElement.parentElement.parentElement;
    expect(gridPaymentActivity_txtcolType1.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolType1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1", () => {
    // START_USER_CODE-USER_txtcolType1_TEST
    // END_USER_CODE-USER_txtcolType1_TEST
  });
  test("gridPaymentActivity_txtcolType2(Grid Widget) Test Cases", async () => {
    let gridPaymentActivity_txtcolType2 = screen.getByTestId(
      "gridPaymentActivity"
    );
    let gridPaymentActivity_txtcolType2btn =
      gridPaymentActivity_txtcolType2.nextElementSibling.firstElementChild;
    gridPaymentActivity_txtcolType2 =
      gridPaymentActivity_txtcolType2.parentElement.parentElement.parentElement;
    expect(gridPaymentActivity_txtcolType2.tagName).toBe("DIV");
    expect(gridPaymentActivity_txtcolType2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:ReviewCheck_gridPaymentActivity"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2", () => {
    // START_USER_CODE-USER_txtcolType2_TEST
    // END_USER_CODE-USER_txtcolType2_TEST
  });
});
