/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvSettleSearch from "./DelvSettleSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvSettleSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleSearch />);
    });
  });
  afterEach(cleanup);
  test("is DelvSettleSearch Loads Successfully", () => {
    expect(screen.getByText("DelvSettleSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvSettleSearch", () => {
    // START_USER_CODE-USER_DelvSettleSearch_Custom_Test_Case
    // END_USER_CODE-USER_DelvSettleSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateDelvSettlement(Button Widget) Test Cases", async () => {
    const btnCreateDelvSettlement = screen.getByTestId(
      "btnCreateDelvSettlement"
    );
    expect(btnCreateDelvSettlement).toBeInTheDocument;
    expect(btnCreateDelvSettlement.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_btnCreateDelvSettlement")
    );
  });
  test("Custom Test Cases for btnCreateDelvSettlement", () => {
    // START_USER_CODE-USER_btnCreateDelvSettlement_TEST
    // END_USER_CODE-USER_btnCreateDelvSettlement_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridDelvSettlementList(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList = screen.getByTestId("gridDelvSettlementList");
    let gridDelvSettlementListbtn =
      gridDelvSettlementList.nextElementSibling.firstElementChild;
    gridDelvSettlementList =
      gridDelvSettlementList.parentElement.parentElement.parentElement;
    expect(gridDelvSettlementList.tagName).toBe("DIV");
    expect(gridDelvSettlementList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridDelvSettlementList", () => {
    // START_USER_CODE-USER_gridDelvSettlementList_TEST
    // END_USER_CODE-USER_gridDelvSettlementList_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDelvSettlementList(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSettlementList = screen.getByTestId(
      "grpbxDelvSettlementList"
    );
    expect(grpbxDelvSettlementList.tagName).toBe("BUTTON");
    expect(grpbxDelvSettlementList.type).toBe("button");
    expect(grpbxDelvSettlementList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSettlementList", () => {
    // START_USER_CODE-USER_grpbxDelvSettlementList_TEST
    // END_USER_CODE-USER_grpbxDelvSettlementList_TEST
  });
  test("grpbxDelvSettleSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSettleSearch = screen.getByTestId("grpbxDelvSettleSearch");
    expect(grpbxDelvSettleSearch.tagName).toBe("BUTTON");
    expect(grpbxDelvSettleSearch.type).toBe("button");
    expect(grpbxDelvSettleSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSettleSearch", () => {
    // START_USER_CODE-USER_grpbxDelvSettleSearch_TEST
    // END_USER_CODE-USER_grpbxDelvSettleSearch_TEST
  });
  test("grpbxDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails1 = screen.getByTestId("grpbxDetails1");
    expect(grpbxDetails1.tagName).toBe("BUTTON");
    expect(grpbxDetails1.type).toBe("button");
    expect(grpbxDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails1", () => {
    // START_USER_CODE-USER_grpbxDetails1_TEST
    // END_USER_CODE-USER_grpbxDetails1_TEST
  });
  test("grpbxDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails2 = screen.getByTestId("grpbxDetails2");
    expect(grpbxDetails2.tagName).toBe("BUTTON");
    expect(grpbxDetails2.type).toBe("button");
    expect(grpbxDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails2", () => {
    // START_USER_CODE-USER_grpbxDetails2_TEST
    // END_USER_CODE-USER_grpbxDetails2_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("txt100PctValue(Textbox Widget) Test Cases", async () => {
    const txt100PctValue = screen.getByTestId("txt100PctValue");
    expect(txt100PctValue.tagName).toBe("INPUT");
    expect(txt100PctValue.type).toBe("text");
    expect(txt100PctValue.classList).toContain("textboxWidgetClass");
    expect(txt100PctValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txt100PctValue")
    );
    await act(async () => {
      userEvent.type(txt100PctValue, "123");
    });
    expect(txt100PctValue.getAttribute("value")).toBe("");
    expect(txt100PctValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt100PctValue", () => {
    // START_USER_CODE-USER_txt100PctValue_TEST
    // END_USER_CODE-USER_txt100PctValue_TEST
  });
  test("txtAgreementNum(Textbox Widget) Test Cases", async () => {
    const txtAgreementNum = screen.getByTestId("txtAgreementNum");
    expect(txtAgreementNum.tagName).toBe("INPUT");
    expect(txtAgreementNum.type).toBe("text");
    expect(txtAgreementNum.classList).toContain("textboxWidgetClass");
    expect(txtAgreementNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txtAgreementNum")
    );
    await act(async () => {
      userEvent.type(txtAgreementNum, "1");
    });
  });
  test("Custom Test Cases for txtAgreementNum", () => {
    // START_USER_CODE-USER_txtAgreementNum_TEST
    // END_USER_CODE-USER_txtAgreementNum_TEST
  });
  test("gridDelvSettlementList_txtcol100PctValue(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcol100PctValue = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcol100PctValuebtn =
      gridDelvSettlementList_txtcol100PctValue.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcol100PctValue =
      gridDelvSettlementList_txtcol100PctValue.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcol100PctValue.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcol100PctValue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100PctValue", () => {
    // START_USER_CODE-USER_txtcol100PctValue_TEST
    // END_USER_CODE-USER_txtcol100PctValue_TEST
  });
  test("gridDelvSettlementList_txtcolAgreeNum(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolAgreeNum = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolAgreeNumbtn =
      gridDelvSettlementList_txtcolAgreeNum.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolAgreeNum =
      gridDelvSettlementList_txtcolAgreeNum.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolAgreeNum.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolAgreeNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeNum", () => {
    // START_USER_CODE-USER_txtcolAgreeNum_TEST
    // END_USER_CODE-USER_txtcolAgreeNum_TEST
  });
  test("gridDelvSettlementList_txtcolAgreeType(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolAgreeType = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolAgreeTypebtn =
      gridDelvSettlementList_txtcolAgreeType.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolAgreeType =
      gridDelvSettlementList_txtcolAgreeType.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolAgreeType.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolAgreeType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeType", () => {
    // START_USER_CODE-USER_txtcolAgreeType_TEST
    // END_USER_CODE-USER_txtcolAgreeType_TEST
  });
  test("gridDelvSettlementList_txtcolConvValue(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolConvValue = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolConvValuebtn =
      gridDelvSettlementList_txtcolConvValue.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolConvValue =
      gridDelvSettlementList_txtcolConvValue.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolConvValue.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolConvValue.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolConvValue", () => {
    // START_USER_CODE-USER_txtcolConvValue_TEST
    // END_USER_CODE-USER_txtcolConvValue_TEST
  });
  test("gridDelvSettlementList_txtcolGen(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolGen = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolGenbtn =
      gridDelvSettlementList_txtcolGen.nextElementSibling.firstElementChild;
    gridDelvSettlementList_txtcolGen =
      gridDelvSettlementList_txtcolGen.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolGen.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGen", () => {
    // START_USER_CODE-USER_txtcolGen_TEST
    // END_USER_CODE-USER_txtcolGen_TEST
  });
  test("gridDelvSettlementList_txtcolInspNum(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolInspNum = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolInspNumbtn =
      gridDelvSettlementList_txtcolInspNum.nextElementSibling.firstElementChild;
    gridDelvSettlementList_txtcolInspNum =
      gridDelvSettlementList_txtcolInspNum.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolInspNum.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolInspNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInspNum", () => {
    // START_USER_CODE-USER_txtcolInspNum_TEST
    // END_USER_CODE-USER_txtcolInspNum_TEST
  });
  test("gridDelvSettlementList_txtcolLoc(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolLoc = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolLocbtn =
      gridDelvSettlementList_txtcolLoc.nextElementSibling.firstElementChild;
    gridDelvSettlementList_txtcolLoc =
      gridDelvSettlementList_txtcolLoc.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolLoc.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoc", () => {
    // START_USER_CODE-USER_txtcolLoc_TEST
    // END_USER_CODE-USER_txtcolLoc_TEST
  });
  test("gridDelvSettlementList_txtcolNetWeight(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolNetWeight = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolNetWeightbtn =
      gridDelvSettlementList_txtcolNetWeight.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolNetWeight =
      gridDelvSettlementList_txtcolNetWeight.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolNetWeight.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolNetWeight.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetWeight", () => {
    // START_USER_CODE-USER_txtcolNetWeight_TEST
    // END_USER_CODE-USER_txtcolNetWeight_TEST
  });
  test("gridDelvSettlementList_txtcolPnutType(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolPnutType = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolPnutTypebtn =
      gridDelvSettlementList_txtcolPnutType.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolPnutType =
      gridDelvSettlementList_txtcolPnutType.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolPnutType.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolPnutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutType", () => {
    // START_USER_CODE-USER_txtcolPnutType_TEST
    // END_USER_CODE-USER_txtcolPnutType_TEST
  });
  test("gridDelvSettlementList_txtcolPnutVariety(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolPnutVariety = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolPnutVarietybtn =
      gridDelvSettlementList_txtcolPnutVariety.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolPnutVariety =
      gridDelvSettlementList_txtcolPnutVariety.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolPnutVariety.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolPnutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutVariety", () => {
    // START_USER_CODE-USER_txtcolPnutVariety_TEST
    // END_USER_CODE-USER_txtcolPnutVariety_TEST
  });
  test("gridDelvSettlementList_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolSeg = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolSegbtn =
      gridDelvSettlementList_txtcolSeg.nextElementSibling.firstElementChild;
    gridDelvSettlementList_txtcolSeg =
      gridDelvSettlementList_txtcolSeg.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolSeg.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridDelvSettlementList_txtcolSettleNum(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolSettleNum = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolSettleNumbtn =
      gridDelvSettlementList_txtcolSettleNum.nextElementSibling
        .firstElementChild;
    gridDelvSettlementList_txtcolSettleNum =
      gridDelvSettlementList_txtcolSettleNum.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolSettleNum.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolSettleNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSettleNum", () => {
    // START_USER_CODE-USER_txtcolSettleNum_TEST
    // END_USER_CODE-USER_txtcolSettleNum_TEST
  });
  test("gridDelvSettlementList_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolStatus = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolStatusbtn =
      gridDelvSettlementList_txtcolStatus.nextElementSibling.firstElementChild;
    gridDelvSettlementList_txtcolStatus =
      gridDelvSettlementList_txtcolStatus.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolStatus.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridDelvSettlementList_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridDelvSettlementList_txtcolVendor = screen.getByTestId(
      "gridDelvSettlementList"
    );
    let gridDelvSettlementList_txtcolVendorbtn =
      gridDelvSettlementList_txtcolVendor.nextElementSibling.firstElementChild;
    gridDelvSettlementList_txtcolVendor =
      gridDelvSettlementList_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridDelvSettlementList_txtcolVendor.tagName).toBe("DIV");
    expect(gridDelvSettlementList_txtcolVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleSearch_gridDelvSettlementList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("txtConvValue(Textbox Widget) Test Cases", async () => {
    const txtConvValue = screen.getByTestId("txtConvValue");
    expect(txtConvValue.tagName).toBe("INPUT");
    expect(txtConvValue.type).toBe("text");
    expect(txtConvValue.classList).toContain("textboxWidgetClass");
    expect(txtConvValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txtConvValue")
    );
    await act(async () => {
      userEvent.type(txtConvValue, "1");
    });
  });
  test("Custom Test Cases for txtConvValue", () => {
    // START_USER_CODE-USER_txtConvValue_TEST
    // END_USER_CODE-USER_txtConvValue_TEST
  });
  test("txtDelvInspectNum(Textbox Widget) Test Cases", async () => {
    const txtDelvInspectNum = screen.getByTestId("txtDelvInspectNum");
    expect(txtDelvInspectNum.tagName).toBe("INPUT");
    expect(txtDelvInspectNum.type).toBe("text");
    expect(txtDelvInspectNum.classList).toContain("textboxWidgetClass");
    expect(txtDelvInspectNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txtDelvInspectNum")
    );
    await act(async () => {
      userEvent.type(txtDelvInspectNum, "1");
    });
  });
  test("Custom Test Cases for txtDelvInspectNum", () => {
    // START_USER_CODE-USER_txtDelvInspectNum_TEST
    // END_USER_CODE-USER_txtDelvInspectNum_TEST
  });
  test("txtDelvSettleNum(Textbox Widget) Test Cases", async () => {
    const txtDelvSettleNum = screen.getByTestId("txtDelvSettleNum");
    expect(txtDelvSettleNum.tagName).toBe("INPUT");
    expect(txtDelvSettleNum.type).toBe("text");
    expect(txtDelvSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtDelvSettleNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txtDelvSettleNum")
    );
    await act(async () => {
      userEvent.type(txtDelvSettleNum, "1");
    });
  });
  test("Custom Test Cases for txtDelvSettleNum", () => {
    // START_USER_CODE-USER_txtDelvSettleNum_TEST
    // END_USER_CODE-USER_txtDelvSettleNum_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "123");
    });
    expect(txtNetWeight.getAttribute("value")).toBe("");
    expect(txtNetWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleSearch_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
});
