/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  setValue,
  getValue,
  disable,
  hide,
  show,
  getData,
  setData,
  enable,
  goTo
} from "../../shared/WindowImports";

import "./FrtAccrlSearch.scss";

// START_USER_CODE-USER_IMPORTS
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import { returnNumericValues } from './../../Common/Constants';
import jsPDF from 'jspdf';
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../../Settlements/Service/SettlementService"
import { StockTransferService } from "../../StockTransfer/Service/StockTransferService"
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_FrtAccrlSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FrtAccrlSearch",
    windowName: "FrtAccrlSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.FrtAccrlSearch",
    // START_USER_CODE-USER_FrtAccrlSearch_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Freight Accrual Search",
      scrCode: "PN1080H",
    },
    // END_USER_CODE-USER_FrtAccrlSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export To Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchOpt",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnReversePayments: {
      name: "btnReversePayments",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchOpt",
      Label: "Reverse Payments",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReversePayments_PROPERTIES

      // END_USER_CODE-USER_btnReversePayments_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchOpt",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnUpdateInvoice: {
      name: "btnUpdateInvoice",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchOpt",
      Label: "Update Invoice",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdateInvoice_PROPERTIES

      // END_USER_CODE-USER_btnUpdateInvoice_PROPERTIES
    },
    cmmndCntnrSearchOpt: {
      name: "cmmndCntnrSearchOpt",
      type: "CommandContainerWidget",
      parent: "grpbxFrtAccrlSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrSearchOpt_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrSearchOpt_PROPERTIES
    },
    colCarrierAbbr: {
      name: "colCarrierAbbr",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Carrier Abbr.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCarrierAbbr_PROPERTIES

      // END_USER_CODE-USER_colCarrierAbbr_PROPERTIES
    },
    colCheckNum: {
      name: "colCheckNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Check #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckNum_PROPERTIES

      // END_USER_CODE-USER_colCheckNum_PROPERTIES
    },
    colCropYear: {
      name: "colCropYear",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "CYR",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCropYear_PROPERTIES

      // END_USER_CODE-USER_colCropYear_PROPERTIES
    },
    colDestinationLocation: {
      name: "colDestinationLocation",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Dest. Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDestinationLocation_PROPERTIES

      // END_USER_CODE-USER_colDestinationLocation_PROPERTIES
    },
    colDispatchNum: {
      name: "colDispatchNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Dispatch #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDispatchNum_PROPERTIES

      // END_USER_CODE-USER_colDispatchNum_PROPERTIES
    },
    colInvoiceNum: {
      name: "colInvoiceNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Invoice #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInvoiceNum_PROPERTIES

      // END_USER_CODE-USER_colInvoiceNum_PROPERTIES
    },
    colIssuedDate: {
      name: "colIssuedDate",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Issued Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIssuedDate_PROPERTIES

      // END_USER_CODE-USER_colIssuedDate_PROPERTIES
    },
    colMemoNum: {
      name: "colMemoNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Memo #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMemoNum_PROPERTIES

      // END_USER_CODE-USER_colMemoNum_PROPERTIES
    },
    colPayableCropYear: {
      name: "colPayableCropYear",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Pay CYR",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayableCropYear_PROPERTIES

      // END_USER_CODE-USER_colPayableCropYear_PROPERTIES
    },
    colProceedAmount: {
      name: "colProceedAmount",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Proceed Amt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceedAmount_PROPERTIES

      // END_USER_CODE-USER_colProceedAmount_PROPERTIES
    },
    colShippingLocation: {
      name: "colShippingLocation",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Ship. Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShippingLocation_PROPERTIES

      // END_USER_CODE-USER_colShippingLocation_PROPERTIES
    },
    colTicketNum: {
      name: "colTicketNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Ticket #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTicketNum_PROPERTIES

      // END_USER_CODE-USER_colTicketNum_PROPERTIES
    },
    colTransferNum: {
      name: "colTransferNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Transfer #",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferNum_PROPERTIES

      // END_USER_CODE-USER_colTransferNum_PROPERTIES
    },
    colVendorNum: {
      name: "colVendorNum",
      type: "GridColumnWidget",
      parent: "gridFreightAccural",
      Label: "Vendor #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorNum_PROPERTIES

      // END_USER_CODE-USER_colVendorNum_PROPERTIES
    },
    ddDestinationLocation: {
      name: "ddDestinationLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Destination Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestinationLocation_PROPERTIES

      // END_USER_CODE-USER_ddDestinationLocation_PROPERTIES
    },
    ddShippingLocation: {
      name: "ddShippingLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Shipping Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLocation_PROPERTIES

      // END_USER_CODE-USER_ddShippingLocation_PROPERTIES
    },
    gridFreightAccural: {
      name: "gridFreightAccural",
      type: "GridWidget",
      parent: "grpbxFrtAccrlSearch",
      gridCellsOrder:
        "txtcolMemoNum,txtcolCheckNum,txtcolIssuedDate,txtcolCropYear,txtcolPayableCropYear,txtcolVendorNum,txtcolShippingLocation,txtcolDestinationLocation,txtcolCarrierAbbr,txtcolTransferNum,txtcolInvoiceNum,txtcolTicketNum,txtcolDispatchNum,txtcolProceedAmount",
      Pagination: true,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFreightAccural_PROPERTIES
      clientPagination: true,
      DisplaySize: 10,
      totalElement: 0,
      // END_USER_CODE-USER_gridFreightAccural_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxFrtAccrlSearch",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    tctScaleTktNum: {
      name: "tctScaleTktNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Scale Tkt #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_tctScaleTktNum_PROPERTIES

      // END_USER_CODE-USER_tctScaleTktNum_PROPERTIES
    },
    txtCarrierAbbrNum: {
      name: "txtCarrierAbbrNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Carrier Abbreviation #:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbrNum_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbrNum_PROPERTIES
    },
    txtCheckNum: {
      name: "txtCheckNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Check #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum_PROPERTIES
    },
    txtcolCarrierAbbr: {
      name: "txtcolCarrierAbbr",
      type: "TextBoxWidget",
      colName: "colCarrierAbbr",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCarrierAbbr_PROPERTIES

      // END_USER_CODE-USER_txtcolCarrierAbbr_PROPERTIES
    },
    txtcolCheckNum: {
      name: "txtcolCheckNum",
      type: "TextBoxWidget",
      colName: "colCheckNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCheckNum_PROPERTIES

      // END_USER_CODE-USER_txtcolCheckNum_PROPERTIES
    },
    txtcolCropYear: {
      name: "txtcolCropYear",
      type: "TextBoxWidget",
      colName: "colCropYear",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCropYear_PROPERTIES

      // END_USER_CODE-USER_txtcolCropYear_PROPERTIES
    },
    txtcolDestinationLocation: {
      name: "txtcolDestinationLocation",
      type: "TextBoxWidget",
      colName: "colDestinationLocation",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDestinationLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolDestinationLocation_PROPERTIES
    },
    txtcolDispatchNum: {
      name: "txtcolDispatchNum",
      type: "TextBoxWidget",
      colName: "colDispatchNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDispatchNum_PROPERTIES

      // END_USER_CODE-USER_txtcolDispatchNum_PROPERTIES
    },
    txtcolInvoiceNum: {
      name: "txtcolInvoiceNum",
      type: "TextBoxWidget",
      colName: "colInvoiceNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInvoiceNum_PROPERTIES

      // END_USER_CODE-USER_txtcolInvoiceNum_PROPERTIES
    },
    txtcolIssuedDate: {
      name: "txtcolIssuedDate",
      type: "TextBoxWidget",
      colName: "colIssuedDate",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIssuedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolIssuedDate_PROPERTIES
    },
    txtcolMemoNum: {
      name: "txtcolMemoNum",
      type: "TextBoxWidget",
      colName: "colMemoNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMemoNum_PROPERTIES

      // END_USER_CODE-USER_txtcolMemoNum_PROPERTIES
    },
    txtcolPayableCropYear: {
      name: "txtcolPayableCropYear",
      type: "TextBoxWidget",
      colName: "colPayableCropYear",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayableCropYear_PROPERTIES

      // END_USER_CODE-USER_txtcolPayableCropYear_PROPERTIES
    },
    txtcolProceedAmount: {
      name: "txtcolProceedAmount",
      type: "TextBoxWidget",
      colName: "colProceedAmount",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceedAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolProceedAmount_PROPERTIES
    },
    txtcolShippingLocation: {
      name: "txtcolShippingLocation",
      type: "TextBoxWidget",
      colName: "colShippingLocation",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShippingLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolShippingLocation_PROPERTIES
    },
    txtcolTicketNum: {
      name: "txtcolTicketNum",
      type: "TextBoxWidget",
      colName: "colTicketNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTicketNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTicketNum_PROPERTIES
    },
    txtcolTransferNum: {
      name: "txtcolTransferNum",
      type: "TextBoxWidget",
      colName: "colTransferNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferNum_PROPERTIES
    },
    txtcolVendorNum: {
      name: "txtcolVendorNum",
      type: "TextBoxWidget",
      colName: "colVendorNum",
      parent: "gridFreightAccural",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorNum_PROPERTIES
    },
    txtCropYear: {
      name: "txtCropYear",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCropYear_PROPERTIES

      // END_USER_CODE-USER_txtCropYear_PROPERTIES
    },
    txtDateRangeFrom: {
      name: "txtDateRangeFrom",
      type: "DateTimeWidget",
      parent: "grpbxDetails",
      Label: "Date Range:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtDateRangeFrom_PROPERTIES

      // END_USER_CODE-USER_txtDateRangeFrom_PROPERTIES
    },
    txtDateRangeTo: {
      name: "txtDateRangeTo",
      type: "DateTimeWidget",
      parent: "grpbxDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtDateRangeTo_PROPERTIES

      // END_USER_CODE-USER_txtDateRangeTo_PROPERTIES
    },
    txtDispatchNum: {
      name: "txtDispatchNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Dispatch #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDispatchNum_PROPERTIES

      // END_USER_CODE-USER_txtDispatchNum_PROPERTIES
    },
    txtInvoiceNum: {
      name: "txtInvoiceNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Invoice #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInvoiceNum_PROPERTIES

      // END_USER_CODE-USER_txtInvoiceNum_PROPERTIES
    },
    txtMemoNum: {
      name: "txtMemoNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Memo #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMemoNum_PROPERTIES

      // END_USER_CODE-USER_txtMemoNum_PROPERTIES
    },
    txtPayableCropYear: {
      name: "txtPayableCropYear",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Payable Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayableCropYear_PROPERTIES

      // END_USER_CODE-USER_txtPayableCropYear_PROPERTIES
    },
    txtTotals: {
      name: "txtTotals",
      type: "TextBoxWidget",
      parent: "grpbxFrtAccrlSearch",
      Label: "Totals:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotals_PROPERTIES

      // END_USER_CODE-USER_txtTotals_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    grpbxFrtAccrlSearch: {
      name: "grpbxFrtAccrlSearch",
      type: "GroupBoxWidget",
      parent: "FrtAccrlSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFrtAccrlSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxFrtAccrlSearch_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "FrtAccrlSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "StockTransfer#ReportPreviewTransfer":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "StockTransfer#ReportPreviewTransfer":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnClose:{
    //   DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    setTabIndex();
  },[]);
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const CompId = (sessionStorage.getItem('compId'));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));
  const companyName = (sessionStorage.getItem('Companyname'));
  const UserId = (sessionStorage.getItem('userid'));
  const [loading, setLoading] = useState(false);
  const [gridDataOptimize, setgridDataOptimize] = useState([]);
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  function FormLoad() {
    setLoading(true);
    hide(thisObj, 'btnReversePayments');
    bFillShippingLocationList()
    bFillDestinationLocationList()
    setLoading(false);
  }
  const bFillShippingLocationList = () => {
    try {
      let js = []; 
      let initialValue;
      js.push({ key: '', description: '>>> All Buying Points <<<' });
      ContractManagementService.RetrieveBuyingPointControlDetails('PN1080', null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + ' - ' + ConvertToPascalCase(data[i].buy_pt_name).trim() }
          js.push(obj)
        }
        if (js.length == 2) {
          delete js[0];
          initialValue = js[1].key;
        }
        else {
          initialValue = js[0].key;
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddShippingLocation: {
              ...state["ddShippingLocation"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddShippingLocation', initialValue);
      })
    } catch (err) {
      errorHandler(err, "bFillShippingLocationList")
    }
  }

  const bFillDestinationLocationList = () => {
    try {
      let js = [];
      let initialValue;
      js.push({ key: '', description: '>>> All Buying Points <<<' })
      let buy_point_id = getValue(thisObj, 'ddShippingLocation');
      if (buy_point_id == "" || buy_point_id == undefined) {
        buy_point_id = null;
      }
      SettlementService.RetrieveTransferBuyingPointDetails(buy_point_id, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].xfer_buy_pt_id.trim() + ' - ' + ConvertToPascalCase(data[i].xfer_buy_pt_name).trim() }
          js.push(obj)
        }
      })
      if (js.length == 2) {
        delete js[0];
        initialValue = js[1].key;
      }
      else {
        initialValue = js[0].key;
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddDestinationLocation: {
            ...state["ddDestinationLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddDestinationLocation', initialValue);
    } catch (err) {
      errorHandler(err, "bFillDestinationLocationList")
    }
  }

  //Pascal event
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  const onddShippingLocationChange = () => {
    bFillDestinationLocationList();
  }
  thisObj.onddShippingLocationChange = onddShippingLocationChange;

  const onbtnSearchClick = () => {
    try {
      let ship_buy_pt = getValue(thisObj, 'ddShippingLocation');
      let dest_buy_pt = getValue(thisObj, 'ddDestinationLocation');
      let vendor = getValue(thisObj, 'txtVendorNum');
      let carrAbbrNum = getValue(thisObj, 'txtCarrierAbbrNum');
      let transNum = getValue(thisObj, 'txtTransferNum');
      let cropYr = getValue(thisObj, 'txtCropYear');
      let pblCropYr = getValue(thisObj, 'txtPayableCropYear');
      let scaleTktNum = getValue(thisObj, 'tctScaleTktNum');
      let invoiceNum = getValue(thisObj, 'txtInvoiceNum');
      let memoNum = getValue(thisObj, 'txtMemoNum');
      let dispatchNum = getValue(thisObj, 'txtDispatchNum');
      let checkNum = getValue(thisObj, 'txtCheckNum');
      let dateFrom = getValue(thisObj, 'txtDateRangeFrom');
      let dateTo = getValue(thisObj, 'txtDateRangeTo');

      if ((ship_buy_pt == "" || ship_buy_pt == undefined) && (dest_buy_pt == "" || dest_buy_pt == undefined) && 
          (vendor == "" || vendor == undefined) && (carrAbbrNum == "" || carrAbbrNum == undefined) && 
          (transNum == "" || transNum == undefined) && (cropYr == "" || cropYr == undefined) && 
          (pblCropYr == "" || pblCropYr == undefined) && (scaleTktNum == "" || scaleTktNum == undefined) && 
          (invoiceNum == "" || invoiceNum == undefined) && (memoNum == "" || memoNum == undefined) && 
          (dispatchNum == "" || dispatchNum == undefined) && (checkNum == "" || checkNum == undefined) && 
          (dateFrom == "" || dateFrom == undefined) && (dateTo == "" || dateTo == undefined)) {
        showMessage(thisObj, "Please enter at least one search criteria.");
        //defaultButtonView();
        // let gridData = [];
        // setValue(thisObj, 'gridFreightAccural', gridData);
        // setValue(thisObj, 'txtTotals', '');
        return;
      }
      DoSearch();

    } catch (err) {
      errorHandler(err, "onbtnSearchClick")
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // To return Portion of data from Complete Grid Data Array.
  const fetchGridValue = (gridData, pageNumber, DisplaySize) => {
    const startIndex = (pageNumber - 1) * DisplaySize;
    const endIndex = startIndex + DisplaySize;
    return gridData.slice(startIndex, endIndex);
  };

  const DoSearch = async () => {
    try {
      let obj1 = [];
      // obj.ship_buy_pt = getValue(thisObj, 'ddShippingLocation');
      // obj.dest_buy_pt = getValue(thisObj, 'ddDestinationLocation');
      obj1.vendor = getValue(thisObj, 'txtVendorNum');
      obj1.carrAbbrNum = getValue(thisObj, 'txtCarrierAbbrNum');
      obj1.transNum = getValue(thisObj, 'txtTransferNum');
      obj1.cropYr = getValue(thisObj, 'txtCropYear');
      obj1.pblCropYr = getValue(thisObj, 'txtPayableCropYear');
      obj1.scaleTktNum = getValue(thisObj, 'tctScaleTktNum');
      obj1.invoiceNum = getValue(thisObj, 'txtInvoiceNum');
      obj1.memoNum = getValue(thisObj, 'txtMemoNum');
      obj1.dispatchNum = getValue(thisObj, 'txtDispatchNum');
      obj1.checkNum = getValue(thisObj, 'txtCheckNum');
      let startDate = getValue(thisObj, 'txtDateRangeFrom');
      if (startDate != "" && startDate != undefined) {
        obj1.dateFrom = moment(startDate).format('MM/DD/YYYY');
      } else {
        obj1.dateFrom = "";
      }
      let endDate = getValue(thisObj, 'txtDateRangeTo');
      if (endDate != "" && endDate != undefined) {
        obj1.dateTo = moment(endDate).format('MM/DD/YYYY');
      } else {
        obj1.dateTo = "";
      }
      let shipLoc = getValue(thisObj, 'ddShippingLocation');
      if (shipLoc.length > 0) {
        let values = shipLoc.split('-');
        obj1.shipLocId = values[0];
        obj1.shipLocName = values[1];
      }
      else {
        obj1.shipLocId = "";
        obj1.shipLocName = "";
      }
      let destLoc = getValue(thisObj, 'ddDestinationLocation');
      if (shipLoc.length > 0) {
        let values = destLoc.split('-');
        obj1.destLocId = values[0];
        obj1.destLocName = values[1];
      }
      else {
        obj1.destLocId = "";
        obj1.destLocName = "";
      }

      setLoading(true);
      let totalValue = 0;
      let gridData = [];
      setValue(thisObj, 'gridFreightAccural', gridData);
      setValue(thisObj, 'txtTotals', '');

      let data = await StockTransferService.RetrievefreightAccrualSearchDetails(obj1)
      let obj = {};
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          
          obj.txtcolMemoNum = data[i].audit_memo_num;
          obj.txtcolCheckNum = data[i].check_num;
          //obj.txtcolIssuedDate = data[i].issued_date;
          if (data[i].issued_date != '' && data[i].issued_date != null){
            let dateValue = new Date(data[i].issued_date);
            let formattedTime = dateValue.getFormattedTime();
            obj.txtcolIssuedDate = formattedTime;
          } else {
            obj.txtcolIssuedDate = data[i].issued_date;
          }
          obj.txtcolCropYear = data[i].audit_crop_year;
          obj.txtcolPayableCropYear = data[i].audit_pay_crop_year;
          obj.txtcolVendorNum = data[i].frt_vendor;
          obj.txtcolShippingLocation = data[i].shp_buy_pt;
          obj.txtcolDestinationLocation = data[i].rec_buy_pt;
          obj.txtcolCarrierAbbr = data[i].frt_scac_id;
          obj.txtcolTransferNum = data[i].audit_xfer_num;
          obj.txtcolInvoiceNum = data[i].freight_invoice;
          obj.txtcolTicketNum = data[i].load_num;
          obj.txtcolDispatchNum = data[i].dispatch_num;
          obj.txtcolProceedAmount = '$' + FormatNumber(data[i].accrual_amount, 2);
          totalValue = totalValue + Number(data[i].accrual_amount);
          gridData.push(obj);
          obj = {};
        }
        setgridDataOptimize(gridData)
        thisObj.setState(current => { // Pagination Total Rows length
          return{
            ...current,
            gridFreightAccural : {
              ...current.gridFreightAccural,
                totalElements : gridData.length
            }
          }
        })

        setValue(thisObj, 'gridFreightAccural', fetchGridValue(gridData,pageNumber,pageSize));
        setValue(thisObj, 'txtTotals', '$' + FormatNumber(totalValue, 2));

        // thisObj.setState((current) => {
        //   return {
        //     ...current,
        //     gridFreightAccural: {
        //       ...current["gridFreightAccural"],
        //       paginationResetDefaultPage:
        //         !current.gridFreightAccural.paginationResetDefaultPage,
        //     },
        //   };
        // });
      }else{
      setgridDataOptimize(gridData)
      }
      setLoading(false);
    } catch (err) {
      errorHandler(err, "DoSearch")
    }
  }

    // Event Trigger on Page change change and row per page change
    const ongridFreightAccuralPagerORsort = (e) => {
      if(e.target.page){
        setPageNumber(e.target.page)
        setValue(thisObj, "gridFreightAccural", fetchGridValue(gridDataOptimize,e.target.page, pageSize))
      }
      if(e.target.size){
        setPageSize(e.target.size)
        if(gridDataOptimize.length < e.target.size ){
          setValue(thisObj, "gridFreightAccural", fetchGridValue(gridDataOptimize,1, e.target.size))
        }
        else{
          setValue(thisObj, "gridFreightAccural", fetchGridValue(gridDataOptimize,pageNumber, e.target.size))
        }
      }
    }
    thisObj.ongridFreightAccuralPagerORsort = ongridFreightAccuralPagerORsort;

  function FormatNumber(value, fraction = 2) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  Date.prototype.getFormattedTime = function () {
    try {
      let date = moment(this).format('MM/DD/YYYY');
      let hours = this.getHours() == 0 ? "12" : this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
      let minutes = (this.getMinutes() < 10 ? "0" : "") + this.getMinutes();
      let seconds = (this.getSeconds() < 10 ? "0" : "") + this.getSeconds();
      let ampm = this.getHours() < 12 ? "AM" : "PM";
      let formattedTime = date + ' ' + hours + ":" + minutes + ":" + seconds + " " + ampm;
      return formattedTime;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let datatable = gridDataOptimize//getValue(thisObj, 'gridFreightAccural');
      var fileName = "Freight Accrual Search";
      let excelData = [];
      if (datatable != undefined) {
        for (let i = 0; i < datatable.length; i++) {
          let rowdata = {};
          if (thisObj.state.gridFreightAccural.columns[0].Visible) {
            rowdata["Memo #"] = datatable[i].txtcolMemoNum;
          }
          if (thisObj.state.gridFreightAccural.columns[1].Visible) {
            rowdata["Check #"] = datatable[i].txtcolCheckNum;
          }
          if (thisObj.state.gridFreightAccural.columns[2].Visible) {
            rowdata["Issued Date"] = datatable[i].txtcolIssuedDate;
          }
          if (thisObj.state.gridFreightAccural.columns[3].Visible) {
            rowdata["CYR"] = datatable[i].txtcolCropYear;
          }
          if (thisObj.state.gridFreightAccural.columns[4].Visible) {
            rowdata["Pay CYR"] = datatable[i].txtcolPayableCropYear;
          }
          if (thisObj.state.gridFreightAccural.columns[5].Visible) {
            rowdata["Vendor #"] = datatable[i].txtcolVendorNum;
          }
          if (thisObj.state.gridFreightAccural.columns[6].Visible) {
            rowdata["Ship. Location"] = datatable[i].txtcolShippingLocation;
          }
          if (thisObj.state.gridFreightAccural.columns[7].Visible) {
            rowdata["Dest. Location"] = datatable[i].txtcolDestinationLocation;
          }
          if (thisObj.state.gridFreightAccural.columns[8].Visible) {
            rowdata["Carrier Abbr."] = datatable[i].txtcolCarrierAbbr;
          }
          if (thisObj.state.gridFreightAccural.columns[9].Visible) {
            rowdata["Transfer #"] = datatable[i].txtcolTransferNum;
          }
          if (thisObj.state.gridFreightAccural.columns[10].Visible) {
            rowdata["Invoice #"] = datatable[i].txtcolInvoiceNum;
          }
          if (thisObj.state.gridFreightAccural.columns[11].Visible) {
            rowdata["Ticket #"] = datatable[i].txtcolTicketNum;
          }
          if (thisObj.state.gridFreightAccural.columns[12].Visible) {
            rowdata["Dispatch #"] = datatable[i].txtcolDispatchNum;
          }
          if (thisObj.state.gridFreightAccural.columns[13].Visible) {
            if (datatable[i].txtcolProceedAmount != '') {
              let amt1 = datatable[i].txtcolProceedAmount.substring(1,datatable[i].txtcolProceedAmount.length) //remove $ symbol
              rowdata["Proceed Amt"] = ExcelNumericDataConvert(amt1,[',']);
            } else {
              rowdata["Proceed Amt"] = datatable[i].txtcolProceedAmount;
            }
            //rowdata["Proceed Amt"] = ExcelNumericDataConvert(datatable[i].txtcolProceedAmount,[',']);
          }
          excelData.push(rowdata);
        }
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnPrintSearchClick = () => {
    try {
      var obj = new jsPDF('landscape', 'mm', 'a4');
      let datatable = gridDataOptimize//getValue(thisObj, 'gridFreightAccural');
      if (datatable != undefined && datatable != null && datatable != "") {
        let data = []
        let headerarray = []
        if (thisObj.state.gridFreightAccural.columns[0].Visible) {
          headerarray.push("Memo #")
        }
        if (thisObj.state.gridFreightAccural.columns[1].Visible) {
          headerarray.push("Check #")
        }
        if (thisObj.state.gridFreightAccural.columns[2].Visible) {
          headerarray.push("Issued Date")
        }
        if (thisObj.state.gridFreightAccural.columns[3].Visible) {
          headerarray.push("CYR")
        }
        if (thisObj.state.gridFreightAccural.columns[4].Visible) {
          headerarray.push("Pay CYR")
        }
        if (thisObj.state.gridFreightAccural.columns[5].Visible) {
          headerarray.push("Vendor #")
        }
        if (thisObj.state.gridFreightAccural.columns[6].Visible) {
          headerarray.push("Ship. Location")
        }
        if (thisObj.state.gridFreightAccural.columns[7].Visible) {
          headerarray.push("Dest. Location")
        }
        if (thisObj.state.gridFreightAccural.columns[8].Visible) {
          headerarray.push("Carrier Abbr.")
        }
        if (thisObj.state.gridFreightAccural.columns[9].Visible) {
          headerarray.push("Transfer #")
        }
        if (thisObj.state.gridFreightAccural.columns[10].Visible) {
          headerarray.push("Invoice #")
        }
        if (thisObj.state.gridFreightAccural.columns[11].Visible) {
          headerarray.push("Ticket #")
        }
        if (thisObj.state.gridFreightAccural.columns[12].Visible) {
          headerarray.push("Dispatch #")
        }
        if (thisObj.state.gridFreightAccural.columns[13].Visible) {
          headerarray.push("Proceed Amt")
        }

        for (let i = 0; i < datatable.length; i++) {
          let bodyarray = []
          if (thisObj.state.gridFreightAccural.columns[0].Visible) {
            bodyarray.push(datatable[i].txtcolMemoNum)
          }
          if (thisObj.state.gridFreightAccural.columns[1].Visible) {
            bodyarray.push(datatable[i].txtcolCheckNum)
          }
          if (thisObj.state.gridFreightAccural.columns[2].Visible) {
            bodyarray.push(datatable[i].txtcolIssuedDate)
          }
          if (thisObj.state.gridFreightAccural.columns[3].Visible) {
            bodyarray.push(datatable[i].txtcolCropYear)
          }
          if (thisObj.state.gridFreightAccural.columns[4].Visible) {
            bodyarray.push(datatable[i].txtcolPayableCropYear)
          }
          if (thisObj.state.gridFreightAccural.columns[5].Visible) {
            bodyarray.push(datatable[i].txtcolVendorNum)
          }
          if (thisObj.state.gridFreightAccural.columns[6].Visible) {
            bodyarray.push(datatable[i].txtcolShippingLocation)
          }
          if (thisObj.state.gridFreightAccural.columns[7].Visible) {
            bodyarray.push(datatable[i].txtcolDestinationLocation)
          }
          if (thisObj.state.gridFreightAccural.columns[8].Visible) {
            bodyarray.push(datatable[i].txtcolCarrierAbbr)
          }
          if (thisObj.state.gridFreightAccural.columns[9].Visible) {
            bodyarray.push(datatable[i].txtcolTransferNum)
          }
          if (thisObj.state.gridFreightAccural.columns[10].Visible) {
            bodyarray.push(datatable[i].txtcolInvoiceNum)
          }
          if (thisObj.state.gridFreightAccural.columns[11].Visible) {
            bodyarray.push(datatable[i].txtcolTicketNum)
          }
          if (thisObj.state.gridFreightAccural.columns[12].Visible) {
            bodyarray.push(datatable[i].txtcolDispatchNum)
          }
          if (thisObj.state.gridFreightAccural.columns[13].Visible) {
            bodyarray.push(ExcelNumericDataConvert(datatable[i].txtcolProceedAmount,[',']))
          }
          data.push(bodyarray)
        }

        obj.autoTable({
          startY: 10,
          startX: 10,
          headStyles: { fontSize: 6 },
          styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          theme: 'plain',
          fontStyle: 'normal',
          head: [headerarray],
          body: data,
          columnStyles: {
            0: { halign: 'center' },
            1: { halign: 'center' },
            2: { halign: 'left' },
            3: { halign: 'center' },
            4: { halign: 'center' },
            5: { halign: 'center' },
            6: { halign: 'left' },
            7: { halign: 'left' },
            8: { halign: 'center' },
            9: { halign: 'center' },
            10: { halign: 'center' },
            11: { halign: 'center' },
            12: { halign: 'center' },
            13: { halign: 'center' }
          },
        });
      
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 1; w <= pageCount; w++) {
          obj.setPage(w);
          obj.setFontSize(7);
          //let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
          //obj.text('Freight Accrual Search - Page : ' + pageCurrent + '/' + pageCount, 10, obj.internal.pageSize.height - 10);
          
          obj.text('            Freight Accrual Search ' , 6, 200);
          obj.text(280, 200, String(w))
        }
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick;

  const onbtnPrintClick = async () => {
    try {
      let value = thisObj.state.gridFreightAccural.selected[0];
      if (value !== undefined) {
        let response = await StockTransferService.RetrieveSettlementFreightAccrualDocuments(value.txtcolCropYear, value.txtcolPayableCropYear, value.txtcolMemoNum)
        if (response != undefined ) {
          // let objresp = {}
          // objresp.result = response;
          // objresp.memoNum = value.txtcolMemoNum;
          // setData(thisObj, 'FrtAccrlSearchData', objresp)
          let Obj = {};
          Obj.data = response;
          Obj.Method = 'Fill_FASettlement';
          Obj.MemoNum = value.txtcolMemoNum;
          Obj.ReportType = "";
          Obj.BtnName = 'btnPrint';
          setData(thisObj, 'ReportTitle', 'Settlement');
          setData(thisObj, 'ReportPreviewTransferForFASettlement', Obj);
          goTo(thisObj, "StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click");
        }
      } else {
        showMessage(thisObj, 'Please select a row in the grid.');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;
  
  const onbtnUpdateInvoiceClick = async () => {
    try {
      let value = thisObj.state.gridFreightAccural.selected[0];
      if (value !== undefined) {
        let selCropYear = value.txtcolCropYear;
        let selMemoNum = value.txtcolMemoNum;
        let givenInvoiceNum = prompt("Enter Invoice Number.",value.txtcolInvoiceNum);
        if (givenInvoiceNum != null && givenInvoiceNum != undefined) {
          if (givenInvoiceNum.toUpperCase() != value.txtcolInvoiceNum.toUpperCase()){
            let dataObj = {
                //new_invoice_num: givenInvoiceNum.toUpperCase()
                new_invoice_num :  Buffer.from(givenInvoiceNum.toUpperCase()).toString('base64')
            }
            let response = await StockTransferService.UpdateSettlementFreightAcculInvoice(selCropYear, selMemoNum, dataObj)
            if (response != undefined) {
              if(response.status == '200'){
                DoSearch();
                // let datatable = getValue(thisObj, 'gridFreightAccural');
                // if (datatable != undefined) {
                //   for (let i = 0; i < datatable.length; i++) {
                //     if (datatable[i].txtcolCropYear == selCropYear && datatable[i].txtcolMemoNum == selMemoNum) {
                //       datatable[i].txtcolInvoiceNum = givenInvoiceNum;
                //     }
                //   }
                // }
              }
            }
          }
        }
      } else {
        //showMessage(thisObj, 'Please select a row in the grid.');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnUpdateInvoiceClick = onbtnUpdateInvoiceClick;
  
  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  const ontxtCropYearChange = () => {
    setValue(thisObj, "txtCropYear", returnNumericValues(txtCropYear.value))
  };
  thisObj.ontxtCropYearChange = ontxtCropYearChange;
  
  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  const ontxtPayableCropYearChange = () => {
    setValue(thisObj, "txtPayableCropYear", returnNumericValues(txtPayableCropYear.value))
  };
  thisObj.ontxtPayableCropYearChange = ontxtPayableCropYearChange;

  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  const ontxtMemoNumChange = () => {
    setValue(thisObj, "txtMemoNum", returnNumericValues(txtMemoNum.value))
  };
  thisObj.ontxtMemoNumChange = ontxtMemoNumChange;

   // For Cancel Button
   const onbtnCloseClick = () => {
    try {
        document.getElementById("StockTransfer_FrtAccrlSearchPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    finally {
      //setLoading(false);
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const setTabIndex = () => {
    let elements = [
      'ddShippingLocation',
      'ddDestinationLocation',
      'txtVendorNum',
      'txtCarrierAbbrNum',
      'txtTransferNum',
      'txtCropYear',
      'txtPayableCropYear',
      'tctScaleTktNum',
      'txtInvoiceNum',
      'txtMemoNum',
      'txtDispatchNum',
      'txtCheckNum',
      'txtDateRangeFromParent',
      'txtDateRangeToParent',
      'btnSearch',
      'btnPrint',
      'btnClose',
      'btnExportToExcel',
      'btnPrintSearch',
      'btnUpdateInvoice'
    ]
    elements.map((element, index) => {
      let tabElement = document.querySelectorAll(".modal")[0].querySelector(`#${element}`);
      if(tabElement){
        tabElement.tabIndex = index + 1;
      }
    })
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FrtAccrlSearch*/}

              {/* END_USER_CODE-USER_BEFORE_FrtAccrlSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFrtAccrlSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFrtAccrlSearch*/}

              <GroupBoxWidget
                conf={state.grpbxFrtAccrlSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddShippingLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddShippingLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddShippingLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddShippingLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddShippingLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_tctScaleTktNum*/}

                  {/* END_USER_CODE-USER_BEFORE_tctScaleTktNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.tctScaleTktNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_tctScaleTktNum*/}

                  {/* END_USER_CODE-USER_AFTER_tctScaleTktNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestinationLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestinationLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInvoiceNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInvoiceNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInvoiceNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInvoiceNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtInvoiceNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMemoNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMemoNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMemoNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMemoNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtMemoNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbrNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbrNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCarrierAbbrNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCarrierAbbrNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtCarrierAbbrNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDispatchNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDispatchNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDispatchNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDispatchNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtDispatchNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTransferNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCheckNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCheckNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCheckNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCheckNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtCheckNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCropYear*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCropYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_txtCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDateRangeFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDateRangeFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDateRangeFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDateRangeFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtDateRangeFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDateRangeTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDateRangeTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDateRangeTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDateRangeTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtDateRangeTo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPayableCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPayableCropYear*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPayableCropYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPayableCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_txtPayableCropYear*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrSearchOpt*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrSearchOpt*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrSearchOpt}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnReversePayments*/}

                  {/* END_USER_CODE-USER_BEFORE_btnReversePayments*/}

                  <ButtonWidget
                    conf={state.btnReversePayments}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnReversePayments*/}

                  {/* END_USER_CODE-USER_AFTER_btnReversePayments*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUpdateInvoice*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdateInvoice*/}

                  <ButtonWidget
                    conf={state.btnUpdateInvoice}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdateInvoice*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdateInvoice*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrSearchOpt*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrSearchOpt*/}
                {/* START_USER_CODE-USER_BEFORE_gridFreightAccural*/}

                {/* END_USER_CODE-USER_BEFORE_gridFreightAccural*/}

                <GridWidget
                  conf={state.gridFreightAccural}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFreightAccural}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFreightAccural*/}

                {/* END_USER_CODE-USER_AFTER_gridFreightAccural*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotals*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotals*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotals}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotals*/}

                {/* END_USER_CODE-USER_AFTER_txtTotals*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFrtAccrlSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFrtAccrlSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_FrtAccrlSearch*/}

              {/* END_USER_CODE-USER_AFTER_FrtAccrlSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_FrtAccrlSearch);
