/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualDPRInvSearch from "./ManualDPRInvSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualDPRInvSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvSearch />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualDPRInvSearch Loads Successfully", () => {
    expect(screen.getByText("ManualDPRInvSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualDPRInvSearch", () => {
    // START_USER_CODE-USER_ManualDPRInvSearch_Custom_Test_Case
    // END_USER_CODE-USER_ManualDPRInvSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvSearch />
      );
    });
  });
  afterEach(cleanup);
  test("btmExit(Button Widget) Test Cases", async () => {
    const btmExit = screen.getByTestId("btmExit");
    expect(btmExit).toBeInTheDocument;
    expect(btmExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btmExit")
    );
  });
  test("Custom Test Cases for btmExit", () => {
    // START_USER_CODE-USER_btmExit_TEST
    // END_USER_CODE-USER_btmExit_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnAuditHistory(Button Widget) Test Cases", async () => {
    const btnAuditHistory = screen.getByTestId("btnAuditHistory");
    expect(btnAuditHistory).toBeInTheDocument;
    expect(btnAuditHistory.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnAuditHistory"
      )
    );
  });
  test("Custom Test Cases for btnAuditHistory", () => {
    // START_USER_CODE-USER_btnAuditHistory_TEST
    // END_USER_CODE-USER_btnAuditHistory_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridAuditHistory(Grid Widget) Test Cases", async () => {
    let gridAuditHistory = screen.getByTestId("gridAuditHistory");
    let gridAuditHistorybtn =
      gridAuditHistory.nextElementSibling.firstElementChild;
    gridAuditHistory =
      gridAuditHistory.parentElement.parentElement.parentElement;
    expect(gridAuditHistory.tagName).toBe("DIV");
    expect(gridAuditHistory.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAuditHistory", () => {
    // START_USER_CODE-USER_gridAuditHistory_TEST
    // END_USER_CODE-USER_gridAuditHistory_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAuditHistory(GroupBox Widget) Test Cases", async () => {
    const grpbxAuditHistory = screen.getByTestId("grpbxAuditHistory");
    expect(grpbxAuditHistory.tagName).toBe("BUTTON");
    expect(grpbxAuditHistory.type).toBe("button");
    expect(grpbxAuditHistory.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAuditHistory", () => {
    // START_USER_CODE-USER_grpbxAuditHistory_TEST
    // END_USER_CODE-USER_grpbxAuditHistory_TEST
  });
  test("grpbxBuyPtDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyPtDetails = screen.getByTestId("grpbxBuyPtDetails");
    expect(grpbxBuyPtDetails.tagName).toBe("BUTTON");
    expect(grpbxBuyPtDetails.type).toBe("button");
    expect(grpbxBuyPtDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyPtDetails", () => {
    // START_USER_CODE-USER_grpbxBuyPtDetails_TEST
    // END_USER_CODE-USER_grpbxBuyPtDetails_TEST
  });
  test("grpbxManualDPRInvSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxManualDPRInvSearch = screen.getByTestId(
      "grpbxManualDPRInvSearch"
    );
    expect(grpbxManualDPRInvSearch.tagName).toBe("BUTTON");
    expect(grpbxManualDPRInvSearch.type).toBe("button");
    expect(grpbxManualDPRInvSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualDPRInvSearch", () => {
    // START_USER_CODE-USER_grpbxManualDPRInvSearch_TEST
    // END_USER_CODE-USER_grpbxManualDPRInvSearch_TEST
  });
  test("grpbxPnutDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxPnutDetails = screen.getByTestId("grpbxPnutDetails");
    expect(grpbxPnutDetails.tagName).toBe("BUTTON");
    expect(grpbxPnutDetails.type).toBe("button");
    expect(grpbxPnutDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPnutDetails", () => {
    // START_USER_CODE-USER_grpbxPnutDetails_TEST
    // END_USER_CODE-USER_grpbxPnutDetails_TEST
  });
  test("grpbxPrintExport(GroupBox Widget) Test Cases", async () => {
    const grpbxPrintExport = screen.getByTestId("grpbxPrintExport");
    expect(grpbxPrintExport.tagName).toBe("BUTTON");
    expect(grpbxPrintExport.type).toBe("button");
    expect(grpbxPrintExport.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrintExport", () => {
    // START_USER_CODE-USER_grpbxPrintExport_TEST
    // END_USER_CODE-USER_grpbxPrintExport_TEST
  });
  test("grpbxTransferDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxTransferDetails = screen.getByTestId("grpbxTransferDetails");
    expect(grpbxTransferDetails.tagName).toBe("BUTTON");
    expect(grpbxTransferDetails.type).toBe("button");
    expect(grpbxTransferDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransferDetails", () => {
    // START_USER_CODE-USER_grpbxTransferDetails_TEST
    // END_USER_CODE-USER_grpbxTransferDetails_TEST
  });
  test("lblAuditHistOfInvAdj(Label Widget) Test Cases", async () => {
    const lblAuditHistOfInvAdj = screen.getByTestId("lblAuditHistOfInvAdj");
    expect(lblAuditHistOfInvAdj.tagName).toBe("LABEL");
    expect(lblAuditHistOfInvAdj.classList).toContain("form-label");
    expect(lblAuditHistOfInvAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_lblAuditHistOfInvAdj"
      )
    );
  });
  test("Custom Test Cases for lblAuditHistOfInvAdj", () => {
    // START_USER_CODE-USER_lblAuditHistOfInvAdj_TEST
    // END_USER_CODE-USER_lblAuditHistOfInvAdj_TEST
  });
  test("lblNote(Label Widget) Test Cases", async () => {
    const lblNote = screen.getByTestId("lblNote");
    expect(lblNote.tagName).toBe("LABEL");
    expect(lblNote.classList).toContain("form-label");
    expect(lblNote.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_lblNote")
    );
  });
  test("Custom Test Cases for lblNote", () => {
    // START_USER_CODE-USER_lblNote_TEST
    // END_USER_CODE-USER_lblNote_TEST
  });
  test("lblPeanut(Label Widget) Test Cases", async () => {
    const lblPeanut = screen.getByTestId("lblPeanut");
    expect(lblPeanut.tagName).toBe("LABEL");
    expect(lblPeanut.classList).toContain("form-label");
    expect(lblPeanut.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_lblPeanut")
    );
  });
  test("Custom Test Cases for lblPeanut", () => {
    // START_USER_CODE-USER_lblPeanut_TEST
    // END_USER_CODE-USER_lblPeanut_TEST
  });
  test("txt1007Num(Textbox Widget) Test Cases", async () => {
    const txt1007Num = screen.getByTestId("txt1007Num");
    expect(txt1007Num.tagName).toBe("INPUT");
    expect(txt1007Num.type).toBe("text");
    expect(txt1007Num.classList).toContain("textboxWidgetClass");
    expect(txt1007Num.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txt1007Num")
    );
    await act(async () => {
      userEvent.type(txt1007Num, "1");
    });
  });
  test("Custom Test Cases for txt1007Num", () => {
    // START_USER_CODE-USER_txt1007Num_TEST
    // END_USER_CODE-USER_txt1007Num_TEST
  });
  test("txtAuditDtFrom(Date Widget) Test Cases", async () => {
    const txtAuditDtFrom = screen.getByTestId("txtAuditDtFrom");
    expect(txtAuditDtFrom.tagName).toBe("INPUT");
    expect(txtAuditDtFrom.type).toBe("text");
    expect(txtAuditDtFrom.classList).toContain("datetimepicker-input");
    expect(
      txtAuditDtFrom.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtAuditDtFrom")
    );
    await act(async () => {
      userEvent.click(txtAuditDtFrom.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtAuditDtFrom", () => {
    // START_USER_CODE-USER_txtAuditDtFrom_TEST
    // END_USER_CODE-USER_txtAuditDtFrom_TEST
  });
  test("txtAuditDtTo(Date Widget) Test Cases", async () => {
    const txtAuditDtTo = screen.getByTestId("txtAuditDtTo");
    expect(txtAuditDtTo.tagName).toBe("INPUT");
    expect(txtAuditDtTo.type).toBe("text");
    expect(txtAuditDtTo.classList).toContain("datetimepicker-input");
    expect(
      txtAuditDtTo.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtAuditDtTo")
    );
    await act(async () => {
      userEvent.click(txtAuditDtTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtAuditDtTo", () => {
    // START_USER_CODE-USER_txtAuditDtTo_TEST
    // END_USER_CODE-USER_txtAuditDtTo_TEST
  });
  test("txtAuditUserNum(Textbox Widget) Test Cases", async () => {
    const txtAuditUserNum = screen.getByTestId("txtAuditUserNum");
    expect(txtAuditUserNum.tagName).toBe("INPUT");
    expect(txtAuditUserNum.type).toBe("text");
    expect(txtAuditUserNum.classList).toContain("textboxWidgetClass");
    expect(txtAuditUserNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtAuditUserNum"
      )
    );
    await act(async () => {
      userEvent.type(txtAuditUserNum, "1");
    });
  });
  test("Custom Test Cases for txtAuditUserNum", () => {
    // START_USER_CODE-USER_txtAuditUserNum_TEST
    // END_USER_CODE-USER_txtAuditUserNum_TEST
  });
  test("gridAuditHistory_txtcolAdjustmentSource(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolAdjustmentSource = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolAdjustmentSourcebtn =
      gridAuditHistory_txtcolAdjustmentSource.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolAdjustmentSource =
      gridAuditHistory_txtcolAdjustmentSource.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolAdjustmentSource.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolAdjustmentSource.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAdjustmentSource", () => {
    // START_USER_CODE-USER_txtcolAdjustmentSource_TEST
    // END_USER_CODE-USER_txtcolAdjustmentSource_TEST
  });
  test("gridAuditHistory_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolBuyPt = screen.getByTestId("gridAuditHistory");
    let gridAuditHistory_txtcolBuyPtbtn =
      gridAuditHistory_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolBuyPt =
      gridAuditHistory_txtcolBuyPt.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolBuyPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridAuditHistory_txtcolChgMainComment(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolChgMainComment = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolChgMainCommentbtn =
      gridAuditHistory_txtcolChgMainComment.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolChgMainComment =
      gridAuditHistory_txtcolChgMainComment.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolChgMainComment.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolChgMainComment.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChgMainComment", () => {
    // START_USER_CODE-USER_txtcolChgMainComment_TEST
    // END_USER_CODE-USER_txtcolChgMainComment_TEST
  });
  test("gridAuditHistory_txtcolChgMaintDate(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolChgMaintDate = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolChgMaintDatebtn =
      gridAuditHistory_txtcolChgMaintDate.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolChgMaintDate =
      gridAuditHistory_txtcolChgMaintDate.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolChgMaintDate.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolChgMaintDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChgMaintDate", () => {
    // START_USER_CODE-USER_txtcolChgMaintDate_TEST
    // END_USER_CODE-USER_txtcolChgMaintDate_TEST
  });
  test("gridAuditHistory_txtcolChgMaintUser(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolChgMaintUser = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolChgMaintUserbtn =
      gridAuditHistory_txtcolChgMaintUser.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolChgMaintUser =
      gridAuditHistory_txtcolChgMaintUser.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolChgMaintUser.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolChgMaintUser.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChgMaintUser", () => {
    // START_USER_CODE-USER_txtcolChgMaintUser_TEST
    // END_USER_CODE-USER_txtcolChgMaintUser_TEST
  });
  test("gridAuditHistory_txtcolComment(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolComment = screen.getByTestId("gridAuditHistory");
    let gridAuditHistory_txtcolCommentbtn =
      gridAuditHistory_txtcolComment.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolComment =
      gridAuditHistory_txtcolComment.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolComment.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolComment.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolComment", () => {
    // START_USER_CODE-USER_txtcolComment_TEST
    // END_USER_CODE-USER_txtcolComment_TEST
  });
  test("gridAuditHistory_txtcolCompOwnedAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolCompOwnedAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolCompOwnedAdjbtn =
      gridAuditHistory_txtcolCompOwnedAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolCompOwnedAdj =
      gridAuditHistory_txtcolCompOwnedAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolCompOwnedAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolCompOwnedAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCompOwnedAdj", () => {
    // START_USER_CODE-USER_txtcolCompOwnedAdj_TEST
    // END_USER_CODE-USER_txtcolCompOwnedAdj_TEST
  });
  test("gridAuditHistory_txtcolCreateDate(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolCreateDate = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolCreateDatebtn =
      gridAuditHistory_txtcolCreateDate.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolCreateDate =
      gridAuditHistory_txtcolCreateDate.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolCreateDate.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolCreateDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCreateDate", () => {
    // START_USER_CODE-USER_txtcolCreateDate_TEST
    // END_USER_CODE-USER_txtcolCreateDate_TEST
  });
  test("gridAuditHistory_txtcolIntransitAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolIntransitAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolIntransitAdjbtn =
      gridAuditHistory_txtcolIntransitAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolIntransitAdj =
      gridAuditHistory_txtcolIntransitAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolIntransitAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolIntransitAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIntransitAdj", () => {
    // START_USER_CODE-USER_txtcolIntransitAdj_TEST
    // END_USER_CODE-USER_txtcolIntransitAdj_TEST
  });
  test("gridAuditHistory_txtcolNotRcptdAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolNotRcptdAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolNotRcptdAdjbtn =
      gridAuditHistory_txtcolNotRcptdAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolNotRcptdAdj =
      gridAuditHistory_txtcolNotRcptdAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolNotRcptdAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolNotRcptdAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNotRcptdAdj", () => {
    // START_USER_CODE-USER_txtcolNotRcptdAdj_TEST
    // END_USER_CODE-USER_txtcolNotRcptdAdj_TEST
  });
  test("gridAuditHistory_txtcolPhysicalAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPhysicalAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPhysicalAdjbtn =
      gridAuditHistory_txtcolPhysicalAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPhysicalAdj =
      gridAuditHistory_txtcolPhysicalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolPhysicalAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPhysicalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPhysicalAdj", () => {
    // START_USER_CODE-USER_txtcolPhysicalAdj_TEST
    // END_USER_CODE-USER_txtcolPhysicalAdj_TEST
  });
  test("gridAuditHistory_txtcolPnutOleic(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPnutOleic = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPnutOleicbtn =
      gridAuditHistory_txtcolPnutOleic.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPnutOleic =
      gridAuditHistory_txtcolPnutOleic.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolPnutOleic.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPnutOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutOleic", () => {
    // START_USER_CODE-USER_txtcolPnutOleic_TEST
    // END_USER_CODE-USER_txtcolPnutOleic_TEST
  });
  test("gridAuditHistory_txtcolPnutOrganic(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPnutOrganic = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPnutOrganicbtn =
      gridAuditHistory_txtcolPnutOrganic.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPnutOrganic =
      gridAuditHistory_txtcolPnutOrganic.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolPnutOrganic.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPnutOrganic.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutOrganic", () => {
    // START_USER_CODE-USER_txtcolPnutOrganic_TEST
    // END_USER_CODE-USER_txtcolPnutOrganic_TEST
  });
  test("gridAuditHistory_txtcolPnutSeed(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPnutSeed = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPnutSeedbtn =
      gridAuditHistory_txtcolPnutSeed.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPnutSeed =
      gridAuditHistory_txtcolPnutSeed.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolPnutSeed.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPnutSeed.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutSeed", () => {
    // START_USER_CODE-USER_txtcolPnutSeed_TEST
    // END_USER_CODE-USER_txtcolPnutSeed_TEST
  });
  test("gridAuditHistory_txtcolPnutSeg(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPnutSeg = screen.getByTestId("gridAuditHistory");
    let gridAuditHistory_txtcolPnutSegbtn =
      gridAuditHistory_txtcolPnutSeg.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPnutSeg =
      gridAuditHistory_txtcolPnutSeg.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolPnutSeg.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPnutSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutSeg", () => {
    // START_USER_CODE-USER_txtcolPnutSeg_TEST
    // END_USER_CODE-USER_txtcolPnutSeg_TEST
  });
  test("gridAuditHistory_txtcolPnutType(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPnutType = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPnutTypebtn =
      gridAuditHistory_txtcolPnutType.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPnutType =
      gridAuditHistory_txtcolPnutType.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolPnutType.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPnutType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutType", () => {
    // START_USER_CODE-USER_txtcolPnutType_TEST
    // END_USER_CODE-USER_txtcolPnutType_TEST
  });
  test("gridAuditHistory_txtcolPnutVariety(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPnutVariety = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPnutVarietybtn =
      gridAuditHistory_txtcolPnutVariety.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPnutVariety =
      gridAuditHistory_txtcolPnutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolPnutVariety.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPnutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutVariety", () => {
    // START_USER_CODE-USER_txtcolPnutVariety_TEST
    // END_USER_CODE-USER_txtcolPnutVariety_TEST
  });
  test("gridAuditHistory_txtcolPostingDt(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolPostingDt = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolPostingDtbtn =
      gridAuditHistory_txtcolPostingDt.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolPostingDt =
      gridAuditHistory_txtcolPostingDt.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolPostingDt.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolPostingDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPostingDt", () => {
    // START_USER_CODE-USER_txtcolPostingDt_TEST
    // END_USER_CODE-USER_txtcolPostingDt_TEST
  });
  test("gridAuditHistory_txtcolRecordID(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolRecordID = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolRecordIDbtn =
      gridAuditHistory_txtcolRecordID.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolRecordID =
      gridAuditHistory_txtcolRecordID.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolRecordID.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolRecordID.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecordID", () => {
    // START_USER_CODE-USER_txtcolRecordID_TEST
    // END_USER_CODE-USER_txtcolRecordID_TEST
  });
  test("gridAuditHistory_txtcolShrinkAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolShrinkAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolShrinkAdjbtn =
      gridAuditHistory_txtcolShrinkAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolShrinkAdj =
      gridAuditHistory_txtcolShrinkAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolShrinkAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolShrinkAdj.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShrinkAdj", () => {
    // START_USER_CODE-USER_txtcolShrinkAdj_TEST
    // END_USER_CODE-USER_txtcolShrinkAdj_TEST
  });
  test("gridAuditHistory_txtcolUndetrminedAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolUndetrminedAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolUndetrminedAdjbtn =
      gridAuditHistory_txtcolUndetrminedAdj.nextElementSibling
        .firstElementChild;
    gridAuditHistory_txtcolUndetrminedAdj =
      gridAuditHistory_txtcolUndetrminedAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolUndetrminedAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolUndetrminedAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUndetrminedAdj", () => {
    // START_USER_CODE-USER_txtcolUndetrminedAdj_TEST
    // END_USER_CODE-USER_txtcolUndetrminedAdj_TEST
  });
  test("gridAuditHistory_txtcolWhsBin(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolWhsBin = screen.getByTestId("gridAuditHistory");
    let gridAuditHistory_txtcolWhsBinbtn =
      gridAuditHistory_txtcolWhsBin.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolWhsBin =
      gridAuditHistory_txtcolWhsBin.parentElement.parentElement.parentElement;
    expect(gridAuditHistory_txtcolWhsBin.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolWhsBin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhsBin", () => {
    // START_USER_CODE-USER_txtcolWhsBin_TEST
    // END_USER_CODE-USER_txtcolWhsBin_TEST
  });
  test("gridAuditHistory_txtcolWhsRcptAdj(Grid Widget) Test Cases", async () => {
    let gridAuditHistory_txtcolWhsRcptAdj = screen.getByTestId(
      "gridAuditHistory"
    );
    let gridAuditHistory_txtcolWhsRcptAdjbtn =
      gridAuditHistory_txtcolWhsRcptAdj.nextElementSibling.firstElementChild;
    gridAuditHistory_txtcolWhsRcptAdj =
      gridAuditHistory_txtcolWhsRcptAdj.parentElement.parentElement
        .parentElement;
    expect(gridAuditHistory_txtcolWhsRcptAdj.tagName).toBe("DIV");
    expect(gridAuditHistory_txtcolWhsRcptAdj.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_gridAuditHistory"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhsRcptAdj", () => {
    // START_USER_CODE-USER_txtcolWhsRcptAdj_TEST
    // END_USER_CODE-USER_txtcolWhsRcptAdj_TEST
  });
  test("txtSC95Num(Textbox Widget) Test Cases", async () => {
    const txtSC95Num = screen.getByTestId("txtSC95Num");
    expect(txtSC95Num.tagName).toBe("INPUT");
    expect(txtSC95Num.type).toBe("text");
    expect(txtSC95Num.classList).toContain("textboxWidgetClass");
    expect(txtSC95Num.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtSC95Num")
    );
    await act(async () => {
      userEvent.type(txtSC95Num, "1");
    });
  });
  test("Custom Test Cases for txtSC95Num", () => {
    // START_USER_CODE-USER_txtSC95Num_TEST
    // END_USER_CODE-USER_txtSC95Num_TEST
  });
  test("txtTotalCompOwned(Textbox Widget) Test Cases", async () => {
    const txtTotalCompOwned = screen.getByTestId("txtTotalCompOwned");
    expect(txtTotalCompOwned.tagName).toBe("INPUT");
    expect(txtTotalCompOwned.type).toBe("text");
    expect(txtTotalCompOwned.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalCompOwned, "1");
    });
  });
  test("Custom Test Cases for txtTotalCompOwned", () => {
    // START_USER_CODE-USER_txtTotalCompOwned_TEST
    // END_USER_CODE-USER_txtTotalCompOwned_TEST
  });
  test("txtTotalIntransit(Textbox Widget) Test Cases", async () => {
    const txtTotalIntransit = screen.getByTestId("txtTotalIntransit");
    expect(txtTotalIntransit.tagName).toBe("INPUT");
    expect(txtTotalIntransit.type).toBe("text");
    expect(txtTotalIntransit.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalIntransit, "1");
    });
  });
  test("Custom Test Cases for txtTotalIntransit", () => {
    // START_USER_CODE-USER_txtTotalIntransit_TEST
    // END_USER_CODE-USER_txtTotalIntransit_TEST
  });
  test("txtTotalPhysical(Textbox Widget) Test Cases", async () => {
    const txtTotalPhysical = screen.getByTestId("txtTotalPhysical");
    expect(txtTotalPhysical.tagName).toBe("INPUT");
    expect(txtTotalPhysical.type).toBe("text");
    expect(txtTotalPhysical.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalPhysical, "1");
    });
  });
  test("Custom Test Cases for txtTotalPhysical", () => {
    // START_USER_CODE-USER_txtTotalPhysical_TEST
    // END_USER_CODE-USER_txtTotalPhysical_TEST
  });
  test("txtTotalReceipted(Textbox Widget) Test Cases", async () => {
    const txtTotalReceipted = screen.getByTestId("txtTotalReceipted");
    expect(txtTotalReceipted.tagName).toBe("INPUT");
    expect(txtTotalReceipted.type).toBe("text");
    expect(txtTotalReceipted.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalReceipted, "1");
    });
  });
  test("Custom Test Cases for txtTotalReceipted", () => {
    // START_USER_CODE-USER_txtTotalReceipted_TEST
    // END_USER_CODE-USER_txtTotalReceipted_TEST
  });
  test("txtTotalShrink(Textbox Widget) Test Cases", async () => {
    const txtTotalShrink = screen.getByTestId("txtTotalShrink");
    expect(txtTotalShrink.tagName).toBe("INPUT");
    expect(txtTotalShrink.type).toBe("text");
    expect(txtTotalShrink.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalShrink, "1");
    });
  });
  test("Custom Test Cases for txtTotalShrink", () => {
    // START_USER_CODE-USER_txtTotalShrink_TEST
    // END_USER_CODE-USER_txtTotalShrink_TEST
  });
  test("txtTotalUndetermined(Textbox Widget) Test Cases", async () => {
    const txtTotalUndetermined = screen.getByTestId("txtTotalUndetermined");
    expect(txtTotalUndetermined.tagName).toBe("INPUT");
    expect(txtTotalUndetermined.type).toBe("text");
    expect(txtTotalUndetermined.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalUndetermined, "1");
    });
  });
  test("Custom Test Cases for txtTotalUndetermined", () => {
    // START_USER_CODE-USER_txtTotalUndetermined_TEST
    // END_USER_CODE-USER_txtTotalUndetermined_TEST
  });
  test("txtTotalUnreceipted(Textbox Widget) Test Cases", async () => {
    const txtTotalUnreceipted = screen.getByTestId("txtTotalUnreceipted");
    expect(txtTotalUnreceipted.tagName).toBe("INPUT");
    expect(txtTotalUnreceipted.type).toBe("text");
    expect(txtTotalUnreceipted.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalUnreceipted, "1");
    });
  });
  test("Custom Test Cases for txtTotalUnreceipted", () => {
    // START_USER_CODE-USER_txtTotalUnreceipted_TEST
    // END_USER_CODE-USER_txtTotalUnreceipted_TEST
  });
  test("txtTradeInspNum(Textbox Widget) Test Cases", async () => {
    const txtTradeInspNum = screen.getByTestId("txtTradeInspNum");
    expect(txtTradeInspNum.tagName).toBe("INPUT");
    expect(txtTradeInspNum.type).toBe("text");
    expect(txtTradeInspNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtTradeInspNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeInspNum, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspNum", () => {
    // START_USER_CODE-USER_txtTradeInspNum_TEST
    // END_USER_CODE-USER_txtTradeInspNum_TEST
  });
  test("txtTradeSettleNum(Textbox Widget) Test Cases", async () => {
    const txtTradeSettleNum = screen.getByTestId("txtTradeSettleNum");
    expect(txtTradeSettleNum.tagName).toBe("INPUT");
    expect(txtTradeSettleNum.type).toBe("text");
    expect(txtTradeSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettleNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtTradeSettleNum"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeSettleNum, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettleNum", () => {
    // START_USER_CODE-USER_txtTradeSettleNum_TEST
    // END_USER_CODE-USER_txtTradeSettleNum_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtTransferNum")
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtWhseRcptNum(Textbox Widget) Test Cases", async () => {
    const txtWhseRcptNum = screen.getByTestId("txtWhseRcptNum");
    expect(txtWhseRcptNum.tagName).toBe("INPUT");
    expect(txtWhseRcptNum.type).toBe("text");
    expect(txtWhseRcptNum.classList).toContain("textboxWidgetClass");
    expect(txtWhseRcptNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvSearch_txtWhseRcptNum")
    );
    await act(async () => {
      userEvent.type(txtWhseRcptNum, "1");
    });
  });
  test("Custom Test Cases for txtWhseRcptNum", () => {
    // START_USER_CODE-USER_txtWhseRcptNum_TEST
    // END_USER_CODE-USER_txtWhseRcptNum_TEST
  });
});
