import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {getPlaceholder,checkInvalid,getInputType,getSize,getTextBoxClasses,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip,} from "../ParentWidgetFunc";

function PasswordWidget(props){
  let conf = props.conf;
  let screenConf = props.screenConf;
  let passwordControl = (
      <React.Fragment>
        <Form.Control
          placeholder={getPlaceholder(conf)}
          size={screenConf.horizontalForm ? "" : getSize(conf)}
          autoComplete={conf.AutoCompleteForTextBox}
          disabled={!conf.Enabled}
          readOnly={conf.ReadOnly}
          type={getInputType(conf)}
          className={getTextBoxClasses(conf)}
          name={conf.name}
          value={props.values[conf.name]}
          onChange={props.onChange}
          onBlur={props.onBlur}
          isInvalid={checkInvalid(props,conf)}
          data-testid={conf.name}
        />
        <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{passwordControl}</Col>
          ) : (
            <React.Fragment> {passwordControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default PasswordWidget;
