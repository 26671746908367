/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  hide
} from "../../shared/WindowImports";

import "./Dashboard.scss";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_Dashboard(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Dashboard",
    windowName: "Dashboard",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "1",
    ColsForTabLandscape: "1",
    ColsForTabPotrait: "1",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "1",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.Dashboard",
    // START_USER_CODE-USER_Dashboard_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Procurement System Dashboard",
      scrCode: "PN0206A",
    },
    // END_USER_CODE-USER_Dashboard_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save",
      CharWidth: "11",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxDashboard",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    grpbxPreview: {
      name: "grpbxPreview",
      type: "GroupBoxWidget",
      parent: "grpbxDashboard",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPreview_PROPERTIES

      // END_USER_CODE-USER_grpbxPreview_PROPERTIES
    },
    grpbxDashboard: {
      name: "grpbxDashboard",
      type: "GroupBoxWidget",
      parent: "Dashboard",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDashboard_PROPERTIES

      // END_USER_CODE-USER_grpbxDashboard_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#SpecialFunctions":{},
       },
      REVERSE:{
        "SystemMaintenanceSpecialFunctions#SpecialFunctions":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["SystemMaintenanceSpecialFunctions#SpecialFunctions#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    ReadFile();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  async function getppsdashboard() {
    try {
      let response = await SystemMaintenanceSpecialFunctionsService.RetrievePpsDashboard();
      var renderstr = '';
      let responsedata;
      let coll_pt_id = "";
      let buy_pt_id = "";
      let n
      let x
      let dt
      let xx
      if (response != "" && response != null && response != undefined) {
        let responsedata; response[0];
        responsedata = response[0]
        renderstr += `<html><center>
      <table width="960" bgcolor=""><tbody><tr><td height="5/"></td></tr><tr><td width="300" align="center" valign="center"></td><td width="150/"></td><td width="500" align="center" valign="top" face="Helvetica" size="1"><font color="#FFFFFF" face="Arial Narrow" size="6"></font><table style="border: 1px;"><tbody><tr><td width="50/"></td><td width="110"><font color="#2B3246" face="Arial" size="1"></font><br><font color="#FCB535" face="Helvetica" size="3"><b>Report Time:</b></font><br><font color="#FCB535" face="Helvetica" size="3"><b>Company:</b></font><br><font color="#FCB535" face="Helvetica" size="3"><b>Crop Year:</b></font></td><td width="260"><font color="#2B3246" face="Arial" size="1"></font><br><font color="#FCB535" face="Helvetica" size="3"><b>`+ responsedata.report_time + `</b></font><br><font color="#FCB535" face="Helvetica" size="3"><b>` + responsedata.comp_name + `</b></font><br><font color="#FCB535" face="Helvetica" size="3"><b>` + responsedata.crop_year + `</b></font><br></td></tr></tbody></table></td></tr><tr><td height="5/"></td></tr></tbody></table>
      
      <table bgcolor=#FFFFFF width=960 border=0 cellspacing=0 cellpadding=0>
	<tr><td colspan=4 height=10/></tr><tr>
	<td width=125/><td width=350 align=left valign=top><font face='Arial Narrow' size=4><b>Daily Totals</b></font></td>
	<td width=100/><td width=375 align=left valign=top><font face='Arial Narrow' size=4><b>Crop Year Totals</b></font></td></tr>
	<tr><td colspan=2 width=475 align=left valign=top><blockquote><font face='Helvetica' size=3>
    New Inspections: <b><font color=#5E86C5>`+ Number(responsedata.new_inspections).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.new_inspections_net_wt).toLocaleString() + `)</font></b><br>
	Settled Inspections: <b><font color=#5E86C5>`+ Number(responsedata.settled_inspections).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.settled_inspections_net_wt).toLocaleString() + `)</font></b><br>
	Transfers: <b><font color=#5E86C5>`+ Number(responsedata.transfers).toLocaleString() + `</font></b><br>
	New Warehouse Receipts: <b><font color=#5E86C5>`+ Number(responsedata.new_warehouse_receipts).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.new_warehouse_receipts_net_wt).toLocaleString() + `)</font></b><br>
	WRs Purchased: <b><font color=#5E86C5>`+ Number(responsedata.warehouse_receipts_p).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.warehouse_receipts_net_wt_p).toLocaleString() + `)</font></b><br>
	New Contracts: <b><font color=#5E86C5>`+ Number(responsedata.new_contracts).toLocaleString() + `</font></b><br>
	Changed Contracts: <b><font color=#5E86C5>`+ Number(responsedata.changed_contracts).toLocaleString() + `</font></b><br>
	Trade Agrees: <b><font color=#5E86C5>`+ Number(responsedata.trade_agrees).toLocaleString() + `</font></b><br>
	Trade Settles: <b><font color=#5E86C5>`+ Number(responsedata.trade_settles).toLocaleString() + `</font></b><br>
	Delv Agrees: <b><font color=#5E86C5>`+ Number(responsedata.delv_agrees).toLocaleString() + `</font></b><br>
	Delv Settles: <b><font color=#5E86C5>`+ Number(responsedata.delv_settles).toLocaleString() + `</font></b><br>
	External Rcpts: <b><font color=#5E86C5>`+ Number(responsedata.wrhs_rcpt_for_delv).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.wrhs_rcpt_for_delv_net_wt).toLocaleString() + `)</font></b><br>
	Avg Purch Cost/Ton: <b><font color=#5E86C5>$`+ Number(responsedata.avg_pur_cost_per_ton).toLocaleString(undefined, { maximumFractionDigits: 2 }) + `</font></b>
	</font></blockquote></td>
	<td colspan=2 width=475 align=left valign=top><blockquote><font face='Helvetica' size=3>
	Total Inspections: <b><font color=#5E86C5>`+ Number(responsedata.total_inspections).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.total_inspections_net_wt).toLocaleString() + `)</font></b><br>
	Total Settled Inspections: <b><font color=#5E86C5>`+ Number(responsedata.total_settled_inspections).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.total_settled_inspections_net_wt).toLocaleString() + `)</font></b><br>
	Total Transfers: <b><font color=#5E86C5>`+ Number(responsedata.total_transfers).toLocaleString() + `</font></b><br>
	Total Warehouse Receipts: <b><font color=#5E86C5>`+ Number(responsedata.total_warehouse_receipts).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.total_warehouse_receipts_net_wt).toLocaleString() + `)</font></b><br>
	Total WRs Purchased: <b><font color=#5E86C5>`+ Number(responsedata.total_warehouse_receipts_p).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.total_warehouse_receipts_net_wt_p).toLocaleString() + `)</font></b><br>
	Total Contracts: <b><font color=#5E86C5>`+ Number(responsedata.total_contracts).toLocaleString() + `</font></b><br><br>
	Total Trade Agrees: <b><font color=#5E86C5>`+ Number(responsedata.total_trade_agrees).toLocaleString() + `</font></b><br>
	Total Trade Settles: <b><font color=#5E86C5>`+ Number(responsedata.total_trade_settles).toLocaleString() + `</font></b><br>
	Total Delv Agrees: <b><font color=#5E86C5>`+ Number(responsedata.total_delv_agrees).toLocaleString() + `</font></b><br>
	Total Delv Settles: <b><font color=#5E86C5>`+ Number(responsedata.total_delv_settles).toLocaleString() + `</font></b><br>
	Total External Rcpts: <b><font color=#5E86C5>`+ Number(responsedata.total_wrhs_rcpt_for_delv).toLocaleString() + `</font>&nbsp;&nbsp;<font color=#F68A4E>(` + Number(responsedata.total_wrhs_rcpt_for_delv_net_wt).toLocaleString() + `)</font></b><br>
	Total Avg Purch Cost/Ton: <b><font color=#5E86C5>$`+ Number(responsedata.total_avg_pur_cost_per_ton).toLocaleString(undefined, { maximumFractionDigits: 2 }) + `</font></b>
	</font></blockquote></td></tr><tr><td colspan=4 height=5/></tr></table>`

        renderstr = renderstr + `<table width=960 border=0 cellspacing=0 cellpadding=0>`
        x = 0
        xx = 0
        for (var i = 0; i < responsedata.dashboard_bp.length; i++) {
          if (coll_pt_id != responsedata.dashboard_bp[i].coll_pt_id) {
            coll_pt_id = responsedata.dashboard_bp[i].coll_pt_id;
            if (x == 1)
              renderstr = renderstr + `</td> \n</tr> \n<tr> \n<td colspan=6 height=30> \n</td> \n</tr> \n`
            renderstr = renderstr + `</table> \n<table bgcolor=#FFFFFF width=960 border=0 cellspacing=0 cellpadding=0> \n<tr> \n<td colspan=6 bgcolor=#2B3246 height=5> \n</td> \n</tr> \n`
            renderstr = renderstr + `<img src='Images/Golden Peanut Usage Exploratory_Horizontal Pattern Gold.png' width=60/></td>`
            renderstr = renderstr + `<tr> \n<td align=center valign=center height=70 colspan=6> \n<font face='Arial Narrow' size=5 color=#2B3246><b>` + responsedata.dashboard_bp[i].coll_pt_id + ` - ` + responsedata.dashboard_bp[i].coll_pt_name + `</b></font> \n</td> \n</tr> \n`

            buy_pt_id = ""
            n = 1
          }
          x = 1
          xx = xx + 1
          if (buy_pt_id != responsedata.dashboard_bp[i].buy_pt_id) {
            buy_pt_id = responsedata.dashboard_bp[i].buy_pt_id;
            if (n > 1)
              renderstr = renderstr + `</td>\n`
            if (n == 1)
              renderstr = renderstr + `<tr> \n`
            if (n == 4) {
              renderstr = renderstr + `</tr> \n<tr> \n<td width=30 height=30> \n</td> \n</tr> \n`
              n = 1
            }
            renderstr = renderstr + `<td width=20/><td width=275 align=left valign=top> \n<font face='Arial Narrow' size=3><b><u>` + responsedata.dashboard_bp[i].buy_pt_id + `-` + responsedata.dashboard_bp[i].buy_pt_name + `</u></b></font><br>`
            n = n + 1
          }
          if(responsedata.dashboard_bp[i].stat2==null || responsedata.dashboard_bp[i].stat2=="" || responsedata.dashboard_bp[i].stat2==undefined)
          {
            renderstr = renderstr + `<font face='Arial Narrow' size=2>` + responsedata.dashboard_bp[i].stat_name + ` - <b><font color=#5E86C5>` + responsedata.dashboard_bp[i].stat + `</font></b></font><br>`
          }
          else
          {
            renderstr = renderstr + `<font face='Arial Narrow' size=2>`+  responsedata.dashboard_bp[i].stat_name +` - <b><font color=#5E86C5>`+  responsedata.dashboard_bp[i].stat +`</font>&nbsp;&nbsp;<font color=#F68A4E>(`+ Number(responsedata.dashboard_bp[i].stat2).toLocaleString() +`)</font></b></font><br>`
          }

        }
        renderstr = renderstr + `</td></tr><tr><td height=30 colspan=3 bgcolor=#FFFFFF /></tr></table> \n
        <table width=960 bgcolor='#2B3246' border=0 cellspacing=0 cellpadding=0>
        <img src='Images/Golden Peanut Usage Exploratory_Horizontal Pattern Gold.png' width=60 /><tr><td height=5/></tr></table>`

        renderstr = renderstr + `</center></html>`;
        return renderstr
      }
      else {
        return `<p>No response found</p>`
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during Formload')
      }
    }
    finally {
    }
  }
  async function GetHtmlCode() {
    var renderCode = ''
    renderCode = await getppsdashboard()
    return renderCode;
  }
  async function ReadFile() {
    var renderCode = await GetHtmlCode()
    if (renderCode != undefined && renderCode != null) {
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    }
  }
  const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob)
  }
  const onbtnPrintClick = async () => {
    try {
      var data = await getppsdashboard()
      var type = 'text/html'
      const dataUrl1 = window.URL.createObjectURL(new Blob([data], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1', 'test.pdf')
      pdfWindow.focus()
      pdfWindow.print()
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_Dashboard*/}

              {/* END_USER_CODE-USER_BEFORE_Dashboard*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDashboard*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDashboard*/}
              <GroupBoxWidget conf={state.grpbxDashboard} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxPreview*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPreview*/}
               
                <GroupBoxWidget
                  conf={state.grpbxPreview}
                  screenConf={state}
                >
                  <iframe src={state.renderUrl} id="docFrame" width="100%" style={{ background: "White" }}></iframe>
                </GroupBoxWidget>
               
                {/* START_USER_CODE-USER_AFTER_grpbxPreview*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPreview*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
                <img
                src={StripPic}
                  className="stripDesign"
                />
                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDashboard*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDashboard*/}

              {/* START_USER_CODE-USER_AFTER_Dashboard*/}

              {/* END_USER_CODE-USER_AFTER_Dashboard*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_Dashboard);
