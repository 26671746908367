/* eslint-disable*/
import { SystemMaintenanceMasterManagementService } from '../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices'

const mItemList = [];
const mComboList = '';

const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

export class clsPeanutVarietyList {

    constructor(thisObj) {
        this.thisObj = thisObj;
    }

    Load = async (CropYear, BuyingPointID, PeanutType) => {

        let systemMaintenanceMasterManagementServices = await SystemMaintenanceMasterManagementService.RetrievePeanutVarietyFilterDetails(compIdFromLS(), CropYear, BuyingPointID, PeanutType);
        if (systemMaintenanceMasterManagementServices.length > 0) {
            mItemList = [];
            let obj;
            for (let i = 0; i < systemMaintenanceMasterManagementServices.length; i++) {
                mComboList += mComboList + '|' + systemMaintenanceMasterManagementServices[i].PNUT_VARIETY_NAME;

                obj = { 
                    key: systemMaintenanceMasterManagementServices[i].PNUT_VARIETY_ID.trim(), 
                    description: systemMaintenanceMasterManagementServices[i].PNUT_VARIETY_NAME.trim() 
                };
                mItemList.push(obj);
            }
        }
    }

    Save = async (CropYear, BuyingPointID, PeanutType) => {
        let peanutVarietyArray = [
            {
                "comp_id": compIdFromLS(),
                "crop_year": (CropYear).toString(),
                "buy_pt_id": BuyingPointID,
                "pnut_type_id": PeanutType
            }
        ];
        let filtercount = 1;
        for (let i = 0; i < mItemList.length; i++) {
            let PeanutVarietyObj = {
                "comp_id": compIdFromLS(),
                "crop_year": (CropYear).toString(),
                "buy_pt_id": BuyingPointID,
                "pnut_type_id": PeanutType,
                "pnut_variety_id": mItemList[i].PNUT_VARIETY_ID
            }
            peanutVarietyArray.push(PeanutVarietyObj);
            filtercount++;
        }
        let dataObj =
        {
            "filtercount": filtercount,
            "peanut_variety_filter": peanutVarietyArray
        }
        let updatepeanutFilter = await SystemMaintenanceMasterManagementService.UpdatePeanutVarietyFilter(dataObj);
        if (updatepeanutFilter) {
            return true;
        }
    }

    Count = () => {
        return mItemList.length;
    }

    GetItemID = (Name) => {
        return mItemList.item(Name).PNUT_VARIETY_ID;
    }

    GetComboList = () => {
        return mComboList;
    }

    GetOleicID = (Name) => {
        return mItemList.item(Name).OLEIC_IND;
    }
}