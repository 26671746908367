/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";//For date time
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getData,
  setData,
  disable,
  enable,
  getValue,
  getSelectedRowNumber,
  goTo,
} from "../../shared/WindowImports";

import "./ContractGroupingProfile.scss";
// START_USER_CODE-USER_IMPORTS
import { ContractManagementService } from "../Service/ContractManagementService";
import ContractManagement_header from "../../Header/Header";
import CommonContext from "../../Store/CommonContext";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractGroupingProfile(props) {
  const contextType = useAppContext();
  const commonContext1 = useContext(CommonContext);
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem("year"));
  let compId = sessionStorage.getItem("compId");
  let userid = sessionStorage.getItem("userid");
  // START_USER_CODE-USER_PROPERTIES
  const js = []
  const jsObj = {}
      jsObj.btnPopup = "",
      jsObj.txtContracts= '0',
      jsObj.txtShare= "0",
  js.push(jsObj)
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Contract Grouping Profile - Test",
    windowName: "ContractGroupingProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractGroupingProfile",
    // START_USER_CODE-USER_ContractGroupingProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Grouping Profile",
      scrCode: "PN1150B",
    },
    // END_USER_CODE-USER_ContractGroupingProfile_PROPERTIES
    btnColContrctPopup: {
      name: "btnColContrctPopup",
      type: "GridColumnWidget",
      parent: "gridCntrctShr",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_btnColContrctPopup_PROPERTIES

      // END_USER_CODE-USER_btnColContrctPopup_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnPopup: {
      name: "btnPopup",
      type: "ButtonWidget",
      colName: "btnColContrctPopup",
      parent: "gridCntrctShr",
      Label: "...",
      CharWidth: "9",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPopup_PROPERTIES

      // END_USER_CODE-USER_btnPopup_PROPERTIES
    },
    btnSearchforCntrcts: {
      name: "btnSearchforCntrcts",
      type: "ButtonWidget",
      parent: "grpbxSearchCntrcts",
      Label: "Search for Contracts",
      CharWidth: "41",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearchforCntrcts_PROPERTIES

      // END_USER_CODE-USER_btnSearchforCntrcts_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    btnInsert: {
      name: "btnInsert",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Insert",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    date: {
      name: "date",
      type: "DateWidget",
      parent: "grpbxGrouping",
      Label: "Date",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_date_PROPERTIES

      // END_USER_CODE-USER_date_PROPERTIES
    },
    dateFormat: {
      name: "dateFormat",
      type: "LabelWidget",
      parent: "grpbxGrouping",
      Label: "mm/dd/yy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_dateFormat_PROPERTIES

      // END_USER_CODE-USER_dateFormat_PROPERTIES
    },
    txtColIsStatus: {
      name: "txtColIsStatus",
      type: "GridColumnWidget",
      parent: "gridCntrctShr",
      Label: "",
      HasLabel: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget7_PROPERTIES

      // END_USER_CODE-USER_gridcolumnwidget7_PROPERTIES
    },
    txtIStatus: {
      name: "txtIStatus",
      type: "TextBoxWidget",
      colName: "txtColIsStatus",
      parent: "gridCntrctShr",
      Label: "",
      HasLabel: false,
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget8_PROPERTIES

      // END_USER_CODE-USER_textboxwidget8_PROPERTIES
    },
    gridCntrctShr: {
      name: "gridCntrctShr",
      type: "GridWidget",
      parent: "grpbxGrouping",
      gridCellsOrder: "btnPopup,txtContracts,txtShare",
      Pagination: false,
      isEditable: true,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridCntrctShr_PROPERTIES
      deleteRowFunction:()=>{
        calcTotal()
      }
      // END_USER_CODE-USER_gridCntrctShr_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "grpbxCntrctGrpPrfl",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    grpbxGrouping: {
      name: "grpbxGrouping",
      type: "GroupBoxWidget",
      parent: "grpbxCntrctGrpPrfl",
      Label: "groupboxwidget1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxGrouping_PROPERTIES

      // END_USER_CODE-USER_grpbxGrouping_PROPERTIES
    },
    grpbxSearchCntrcts: {
      name: "grpbxSearchCntrcts",
      type: "GroupBoxWidget",
      parent: "grpbxCntrctGrpPrfl",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSearchCntrcts_PROPERTIES

      // END_USER_CODE-USER_grpbxSearchCntrcts_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      Alignment: "Right",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      Alignment: "Right",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    txtcolContracts: {
      name: "txtcolContracts",
      type: "GridColumnWidget",
      parent: "gridCntrctShr",
      Label: "Contracts#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolContracts_PROPERTIES

      // END_USER_CODE-USER_txtcolContracts_PROPERTIES
    },
    txtcolShare: {
      name: "txtcolShare",
      type: "GridColumnWidget",
      parent: "gridCntrctShr",
      Label: "Share",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolShare_PROPERTIES

      // END_USER_CODE-USER_txtcolShare_PROPERTIES
    },
    txtContracts: {
      name: "txtContracts",
      type: "TextBoxWidget",
      colName: "txtcolContracts",
      parent: "gridCntrctShr",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContracts_PROPERTIES
      Mandatory: true,
      // END_USER_CODE-USER_txtContracts_PROPERTIES
    },
    txtGroupingInput: {
      name: "txtGroupingInput",
      type: "TextBoxWidget",
      parent: "grpbxGrouping",
      Label: "Grouping:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroupingInput_PROPERTIES

      // END_USER_CODE-USER_txtGroupingInput_PROPERTIES
    },
    txtShare: {
      name: "txtShare",
      type: "TextBoxWidget",
      colName: "txtcolShare",
      parent: "gridCntrctShr",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShare_PROPERTIES

      // END_USER_CODE-USER_txtShare_PROPERTIES
    },
    txtTotal: {
      name: "txtTotal",
      type: "TextBoxWidget",
      parent: "grpbxSearchCntrcts",
      Label: "Total:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotal_PROPERTIES

      // END_USER_CODE-USER_txtTotal_PROPERTIES
    },
    grpbxCntrctGrpPrfl: {
      name: "grpbxCntrctGrpPrfl",
      type: "GroupBoxWidget",
      parent: "ContractGroupingProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCntrctGrpPrfl_PROPERTIES

      // END_USER_CODE-USER_grpbxCntrctGrpPrfl_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ContractGrpSelect": {},
      },
      REVERSE: {
        "ContractManagement#ContractGrpSelect": {},
      },
    },
  };
  let _buttonServices = {
    ContractGrpSelect: {},
  };
  let _buttonNavigation = {
    btnSearchforCntrcts: {
      DEFAULT: ["ContractManagement#ContractGrpSelect#DEFAULT#true#Click"],
    },
    // btnPopup: {
    //   DEFAULT: ["ContractManagement#ContractProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  var mbSecurityDelete = false;
  var mbSecurityUpdate = false;
  var mbSecurityInquiry = false;


  // useEffect (() =>{
  //   gridData = (getValue(thisObj, "gridCntrctShr")=== undefined ? [] :getValue(thisObj, "gridCntrctShr"));
  // }, [getValue(thisObj, "gridCntrctShr")])
  // to display grouping number fro contractgroupsearch screen
  useEffect(() => {
    if (getData(thisObj, "GroupingNumber")) {
      setValue(thisObj, "txtGroupingInput", getData(thisObj, "GroupingNumber"));
      // disable(thisObj, "txtGroupingInput");
      setValue(thisObj, "date", getData(thisObj, "GroupingDate"));
      // get  api for contractgrouplist starts here
      ContractManagementService.RetrieveContractGroupDetails(
        "PN1150",
        "",
        getData(thisObj, "GroupingNumber"),
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ).then((response) => {
        if (response == undefined || response == null) {
          alert("No Groupings Found!!!", false);
        } else {
          let data = response;
          setValue(thisObj, "gridCntrctShr", js);
          let list = [];
          let obj = {};
          var total = 0;
          for (var i = 0; i < data.length; i++) {
            if (data[i].strFieldName.trim() == "COMP_ID") {
              obj = {};
            }
            if (data[i].strFieldName.trim() == "GROUPING_NUM") {
              obj.GroupingNumber = data[i].strFieldData.trim();
            }
            if (data[i].strFieldName.trim() == "GROUPING_DATE_TIME") {
              obj.GroupingDate = new Date(
                data[i].strFieldData.trim()
              ).toLocaleDateString();
            }
            if (data[i].strFieldName.trim() == "CONTRACT_NUM") {
              obj.txtContracts = data[i].strFieldData.trim();
            }
            if (data[i].strFieldName.trim() == "GROUPING_SHARE") {
              obj.txtShare = data[i].strFieldData.trim();
              total = total + parseFloat(data[i].strFieldData);
              list.push(obj);
            }
          }
          let ob = {};
          let arr = [];
          data.map(i => {
            switch (i.strFieldName.trim()) {
              case "COMP_ID":
                ob = new Object()
                ob["id"] = i.strFieldData
                break;
              case "GROUPING_NUM":
                ob["groupingNum"] = i.strFieldData
                break;
              case "GROUPING_DATE_TIME":
                ob["groupingDate"] = i.strFieldData
                break;
              case "CONTRACT_NUM":
                ob["txtContracts"] = i.strFieldData
                break;
              case "GROUPING_SHARE":
                ob["txtShare"] = i.strFieldData
                arr.push(ob)
                break;
            }
          })
          // if (list.length > 0) {
          //   enable(thisObj, "btnDelete");
          // }
          // if ( getData(thisObj, "contractGroupingList") != null && getData(thisObj, "contractGroupingList") != undefined ) {
          //   let contractGroupingList = getData(thisObj, "contractGroupingList");
          //   for (var i = 0; i < contractGroupingList.length; i++) {
          //     if (contractGroupingList[i] != "") {
          //       list.push({
          //         GroupingNumber: list[0].GroupingNumber,
          //         GroupingDate: "", //new Date().split(' ')[0],
          //         txtContracts: contractGroupingList[i],
          //         txtShare: "",
          //       });
          //     }
          //   }
            // let res = [];
            // for (var i = 0; i < list.length; i++) {
            //   res.push(list[i].txtContracts);
            // }
            // for (var i = contractGroupingList.length; i < 10; i++) {
            //   res.push("");
            // }
          //   setData(thisObj, "contractGroupingList", js);
          // }
          let txtContarctsArr = []
          arr.map(cnt => {
            txtContarctsArr.push(cnt.txtContracts)
          })
          setValue(thisObj, "gridCntrctShr", arr);
          setData(thisObj, 'Array_', arr);
          setValue(thisObj, "txtTotal", total);
          setData(thisObj, "contractGroupingList", [])
          localStorage.setItem('gridData', []);
          localStorage.setItem("gridData", txtContarctsArr)
        }
      });
    }
  }, []);
  //  get api for contractgrplist ends here

  const EnableDisableControls = () =>{
    let GroupingNum = getData(thisObj, "GroupingNumber")
    if(mbSecurityDelete){ 
        enable(thisObj, "btnSearchforCntrcts");
        enable(thisObj, "btnUpdate");
        if(GroupingNum != null && GroupingNum !=  undefined && GroupingNum !=''){
          enable(thisObj, "btnDelete");
        }
    }
    if(mbSecurityUpdate){
      enable(thisObj, "btnSearchforCntrcts");
      enable(thisObj, "btnUpdate");
    }
    if(mbSecurityInquiry){
      enable(thisObj, "btnSearchforCntrcts");
    }
  }

  const FormLoad = async () => {
    setData(thisObj, "gridCntrctShr", js);
    disable(thisObj, "txtGroupingInput");
    disable(thisObj, "txtTotal");
    disable(thisObj, "btnDelete");
    disable(thisObj, "btnUpdate");
    disable(thisObj, "btnSearchforCntrcts");
    enable(thisObj, "btnExit");

    let deletePermission = await ContractManagementService.RetrieveAccessPermissionDetails("PN1150", null, "D", null);
    if (deletePermission[0].permission == "Y") {
      mbSecurityDelete = true;
      mbSecurityUpdate = true;
      mbSecurityInquiry = true;
    }
    let UpdatePermission = await ContractManagementService.RetrieveAccessPermissionDetails("PN1150", null, "U", null);
    if (UpdatePermission[0].permission == "Y") {
      mbSecurityUpdate = true;
      mbSecurityInquiry = true;
    }
    let InquiryPermission = await ContractManagementService.RetrieveAccessPermissionDetails("PN1150", null, "I", null);
    if (InquiryPermission[0].permission == "Y") {
      mbSecurityInquiry = true;
    }
    EnableDisableControls();
  };

  function calcTotal(){
    ontxtShareChange()
  }

  const onbtnUpdateClick = async () => {
    setData(thisObj, "ReloadContractGroupingSearchGrid", true);
    getValue(thisObj, "gridCntrctShr")
    try {
      var EnteredDate = convert(getValue(thisObj, "date"))
      if (EnteredDate != undefined && EnteredDate != "aN/aN/NaN") {
        if (
          thisObj.values.txtGroupingInput == "" ||
          thisObj.values.txtGroupingInput == null
        ) {
          let contractNo = "";
          let error = false
          for (let i = 0; i < thisObj.values.gridCntrctShr.length; i++) {
            if (
              thisObj.values.gridCntrctShr[i].txtContracts != "" &&
              thisObj.values.gridCntrctShr[i].txtContracts != null &&
              thisObj.values.gridCntrctShr[i].txtContracts != undefined
            ) {
              if (
                thisObj.values.gridCntrctShr[i].txtShare != "" &&
                thisObj.values.gridCntrctShr[i].txtShare != undefined &&
                thisObj.values.gridCntrctShr[i].txtShare != null&&
                thisObj.values.gridCntrctShr[i].txtShare != 0
              ) {
                contractNo = thisObj.values.gridCntrctShr[i].txtContracts;
              } else {
                showMessage(thisObj, "Invalid share value");
              }
            }
            else {
              error = true
            }
          }
          if (error) {
            showMessage(thisObj, "Invalid Contract Number: Contract number cannot be Null");
            return false
          }
          let response =
            await ContractManagementService.RetrieveContractGroupDetails(
              "PN1150",
              "",
              "",
              contractNo,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              ""
            );
          if (response) {
            let data = response;
            if (data.length > 0) {
              let list = [];
              let obj = {};
              for (var i = 0; i < data.length; i++) {
                if (data[i].strFieldName.trim() == "COMP_ID") {
                  obj = {};
                }
                getValue(thisObj, "gridCntrctShr")
                for (let j = 0; j < thisObj.values.gridCntrctShr.length; j++) {
                  if (thisObj.values.gridCntrctShr[j].txtShare !== null && thisObj.values.gridCntrctShr[j].txtShare !== undefined && thisObj.values.gridCntrctShr[j].txtShare !== ''&& thisObj.values.gridCntrctShr[j].txtShare !== 0) {

                    if (data[i].strFieldName.trim() == "GROUPING_NUM") {
                      obj.GroupingNumber = data[i].strFieldData.trim();
                      setValue(
                        thisObj,
                        "txtGroupingInput",
                        data[i].strFieldData.trim()
                      );
                      showMessage(
                        thisObj,
                        `Contract ${contractNo} already belongs to grouping ${data[i].strFieldData.trim()}`
                      );
                      break;
                    }
                  }
                }
              }
            } else {
              let obj = {};
              let total = 0
              let action = thisObj.values.txtGroupingInput ? "update" : "insert";
              obj.user_id = userid;
              if (thisObj.values.date != "" && thisObj.values.date != null) {
                obj.grouping_date_time = moment(thisObj.values.date).format();
              }
              for (let i = 0; i < thisObj.values.gridCntrctShr.length; i++) {
                if (thisObj.values.gridCntrctShr[i].txtContracts != "") {
                  if (thisObj.values.gridCntrctShr[i].txtContracts.length != 6) {
                    showMessage(thisObj, "Invalid Contract Number");
                    return false;
                  }
                  obj["contract_num_" + i] = parseInt(
                    thisObj.values.gridCntrctShr[i].txtContracts
                  );
                } else {
                  obj["contract_num_" + i] = 0;
                }
                if (thisObj.values.gridCntrctShr[i].txtShare != "") {
                  obj["grouping_share_" + i] = parseInt(
                    thisObj.values.gridCntrctShr[i].txtShare
                  );
                  total = total + parseInt(
                    thisObj.values.gridCntrctShr[i].txtShare
                  );
                } else {
                  obj["grouping_share_" + i] = 0;
                }
              }
              for (
                let j = thisObj.values.gridCntrctShr.length;
                j <= 10 - thisObj.values.gridCntrctShr.length + 1;
                j++
              ) {
                obj["grouping_share_" + j] = 0;
                obj["contract_num_" + j] = 0;
              }
              let tmp = await bFormValid();
              if (total != 100) {
                showMessage(
                  thisObj,
                  "total share percentage should be 100"
                );
                return false
              }
              if (tmp == true) {
                ContractManagementService.UpdateContractGroup(
                  action,
                  thisObj.values.txtGroupingInput,
                  obj
                ).then((res) => {
                  alert(res.message)
                  document.getElementById("ContractManagement_ContractGroupingProfilePopUp").childNodes[0].click();
                });
              }
            }
          }
        } else {
          let obj = {};
          let total = 0
          let action =
            thisObj.values.txtGroupingInput != "" ? "update" : "insert";
          obj.user_id = userid;
          obj.grouping_date_time = moment(thisObj.values.date).format();
          for (let i = 0; i < thisObj.values.gridCntrctShr.length; i++) {
            if (thisObj.values.gridCntrctShr[i].txtContracts != "") {
              if (thisObj.values.gridCntrctShr[i].txtContracts.length != 6) {
                showMessage(thisObj, "Invalid Contract Number");
                return false;
              }
              obj["contract_num_" + i] = parseInt(
                thisObj.values.gridCntrctShr[i].txtContracts
              );
            } else {
              obj["contract_num_" + i] = 0;
            }
            if (thisObj.values.gridCntrctShr[i].txtShare != "") {
              obj["grouping_share_" + i] = parseInt(
                thisObj.values.gridCntrctShr[i].txtShare
              );
              total = total + parseInt(
                thisObj.values.gridCntrctShr[i].txtShare
              );
            } else {
              obj["grouping_share_" + i] = 0;
            }
          }
          for (
            let j = thisObj.values.gridCntrctShr.length;
            j <= 10 - thisObj.values.gridCntrctShr.length + 1;
            j++
          ) {
            obj["grouping_share_" + j] = 0;
            obj["contract_num_" + j] = 0;
          }
          getValue(thisObj, "gridCntrctShr")
          for (let j = 0; j < thisObj.values.gridCntrctShr.length; j++) {
            if (thisObj.values.gridCntrctShr[j].txtShare !== null && thisObj.values.gridCntrctShr[j].txtShare !== undefined && thisObj.values.gridCntrctShr[j].txtShare !== "" && thisObj.values.gridCntrctShr[j].txtShare !== 0) {
              setData(thisObj, "abc", thisObj.values.gridCntrctShr[0].txtContracts); //need to make this dynamic so to change all contract for validation
              if (total != 100) {
                showMessage(
                  thisObj,
                  "total share percentage should be 100"
                );
                return false
              }
            } else {
              showMessage(thisObj, "Invalid Share value")
              break;
            }
          }
          let tmp = await bFormValid();
          if (tmp == true) {
            ContractManagementService.UpdateContractGroup(
              action,
              thisObj.values.txtGroupingInput,
              obj
            ).then((res) => {
              alert(res.message)
              document.getElementById("ContractManagement_ContractGroupingProfilePopUp").childNodes[0].click();
            });
          }
        }
      } else { showMessage(thisObj, "Date cannot be null") }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in Presubmit of event code of widget:btnUpdate: Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const onbtnDeleteClick = () => {
    setData(thisObj, "ReloadContractGroupingSearchGrid", true);
    try {
      onButtonClick("delete");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn delete event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
  // put api for ContractGroupingModify
  const onButtonClick = (action) => {
    let obj = {};
    obj.user_id = userid;
    obj.grouping_date_time = moment(thisObj.values.date).format();
    for (let i = 0; i < thisObj.values.gridCntrctShr.length; i++) {
      if (thisObj.values.gridCntrctShr[i].txtContracts != "") {
        if (thisObj.values.gridCntrctShr[i].txtContracts.length != 6) {
          showMessage(thisObj, "Invalid Contract Number");
          return false;
        }
        obj["contract_num_" + i] = parseInt(
          thisObj.values.gridCntrctShr[i].txtContracts
        );
      } else {
        obj["contract_num_" + i] = 0;
      }
      if (thisObj.values.gridCntrctShr[i].txtShare != "") {
        obj["grouping_share_" + i] = parseInt(
          thisObj.values.gridCntrctShr[i].txtShare
        );
      } else {
        obj["grouping_share_" + i] = 0;
      }
    }
    for (
      let j = thisObj.values.gridCntrctShr.length;
      j <= 10 - thisObj.values.gridCntrctShr.length + 1;
      j++
    ) {
      obj["grouping_share_" + j] = 0;
      obj["contract_num_" + j] = 0;
    }
    try {
      ContractManagementService.UpdateContractGroup(
        action,
        thisObj.values.txtGroupingInput,
        obj
      ).then((res) => {
        alert(res.message)
        document.getElementById("ContractManagement_ContractGroupingProfilePopUp").childNodes[0].click();
      });
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in while saving grouping contract");
      }
      return false;
    }
    return true;
  };
  // put Api code ends here
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
    let newList = []
    let newObj = {}
    let txtContractsArray = []
    // let contractGroupingList = getData(thisObj, "contractGroupingList");
    // if (contractGroupingList != null && contractGroupingList != undefined) {
    //   let list = [];
    //   for (var i = 0; i < contractGroupingList.length; i++) {
    //     list.push({
    //       btnPopup: "...",
    //       txtContracts: contractGroupingList[i],
    //       txtShare: ""
    //     });
    //   }
      // setValue(thisObj, "gridCntrctShr", list);
    // }
      // setValue(thisObj, 'gridCntrctShr', js)
      let contractGroupingList = getData(thisObj, "contractGroupingList");
      let newValue = getValue(thisObj, 'gridCntrctShr');
      for (var i = 0; i < newValue.length; i++){
        newObj.txtContracts = newValue[i].txtContracts
        newObj.txtShare = newValue[i].txtShare
        newList.push(newObj)
        newObj = {}
        }
      if(contractGroupingList != null && contractGroupingList != undefined && contractGroupingList.length >0){
      for (var i = 0; i < contractGroupingList.length; i++) {
        newObj.txtContracts = contractGroupingList[i]
        newObj.txtShare = ''
        newList.push(newObj)
        newObj = {}
      }
      setValue(thisObj, 'gridCntrctShr', newList)
      setData(thisObj, 'Array_', newList)
          newList.map(cnt => {
            txtContractsArray.push(cnt.txtContracts)
          })
      localStorage.setItem('gridData', []);
      localStorage.setItem('gridData', txtContractsArray);
      // setValue(thisObj, "gridCntrctShr", list);
      // let res = contractGroupingList;
      // for (var i = contractGroupingList.length; i < 10; i++) {
      //   res.push("");
      // }
      // setData(thisObj, "contractGroupingList", res);
    } 
    setData(thisObj, "contractGroupingList", []);
  }, [getData(thisObj, "contractGroupingList")]);


  const ontxtContractsBlur = () =>{
    let newManualValue = getData(thisObj, 'Array_');
    let list = [];
    let newUpdatedList = []
    let newUpdatedobj = {}
    for (var i = 0; i < newManualValue.length; i++) {
      list.push(newManualValue[i].txtContracts)
    }
    let GridValue = getValue(thisObj, 'gridCntrctShr')
    if (GridValue.length > list.length) {
          for (var i = 0; i < GridValue.length; i++) {
            if ((list.includes(GridValue[i].txtContracts)) == false) {
              newUpdatedobj.txtContracts = GridValue[i].txtContracts
              newUpdatedobj.txtShare = GridValue[i].txtShare
              newUpdatedList.push(newUpdatedobj)
              newUpdatedobj = {}
            }
          }
        for (var i = 0; i < newManualValue.length; i++) {
          newUpdatedobj.txtContracts = newManualValue[i].txtContracts
          newUpdatedobj.txtShare = newManualValue[i].txtShare
          newUpdatedList.push(newUpdatedobj)
          newUpdatedobj = {}
        }
    } else if (GridValue.length == list.length) {
          for (var i = 0; i < GridValue.length; i++) {
              newUpdatedobj.txtContracts = GridValue[i].txtContracts
              newUpdatedobj.txtShare = GridValue[i].txtShare
              newUpdatedList.push(newUpdatedobj)
              newUpdatedobj = {}
            }
    } else if (GridValue.length < list.length) {
      for (var i = 0; i < GridValue.length; i++) {
        newUpdatedobj.txtContracts = GridValue[i].txtContracts
        newUpdatedobj.txtShare = GridValue[i].txtShare
        newUpdatedList.push(newUpdatedobj)
        newUpdatedobj = {}
      }
    }
    // setValue(thisObj, 'gridCntrctShr', newUpdatedList)
    setData(thisObj, 'Array_', newUpdatedList)
    let newUpdatedArray = [];
    newUpdatedList.map(cnt => {
      newUpdatedArray.push(cnt.txtContracts)
    })
    localStorage.setItem('gridData', []);
    localStorage.setItem('gridData', newUpdatedArray);
  }
  thisObj.ontxtContractsBlur = ontxtContractsBlur;

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);

    FormLoad();
    setValue(thisObj,'gridCntrctShr',js)
    setData(thisObj, "contractGroupingList", [])
    setData(thisObj, 'Array_', []);
    localStorage.setItem('gridData', []);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const ongridCntrctShrRowSelect = (event) => {
    try {
      let row = getSelectedRowNumber(thisObj, "gridCntrctShr")
      if (row === 0) {
        let highlight = document.getElementsByClassName("activeRow")
        if (highlight.length > 0) {
          highlight[0].classList.remove("activeRow")
        }
        document.getElementById("gridCntrctShrCollapse").firstChild.firstChild.firstChild.lastChild.firstChild.className += " activeRow"
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ongridCntrctShrRowSelect = ongridCntrctShrRowSelect;

  const onbtnPopupClick = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue = getValue(thisObj, 'gridCntrctShr')
      if (NewGridValue[rowID].txtContracts != null && NewGridValue[rowID].txtContracts != undefined && NewGridValue[rowID].txtContracts != "") {
        let contractnum = NewGridValue[rowID].txtContracts;
        let APIresponse = await ContractManagementService.RetrieveContractDetails(contractnum, 'PN1010')
        if(APIresponse.length >0){
          commonContext1.setContractProfile_ContractNumber(contractnum);
          commonContext1.setContractProfile_FormState("edit");
          goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
        } else {
          showMessage(thisObj, "Contract does not exist")
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtnPopupClick = onbtnPopupClick;


  const onbtnExitClick = () => {
    try {
      setValue(thisObj, "gridCntrctShr", js)
      setData(thisObj, "contractGroupingList", [])
      setData(thisObj, 'Array_', [])
      //START_USER_CODE-63dfe999ef8de0ba-16aed6661e2380fd/ContractManagement_2b08ad3892ccdcb5_a032de0546f5e808_PreSubmit_EventCode.js
      document
        .getElementById("ContractManagement_ContractGroupingProfilePopUp")
        .childNodes[0].click();
      //END_USER_CODE-63dfe999ef8de0ba-16aed6661e2380fd/ContractManagement_2b08ad3892ccdcb5_a032de0546f5e808_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;

  //bformvalid logic (for validating preconditions required for grouping contracts)
  const bFormValid = async () => {
    let contractnum = getData(thisObj, "abc");
    let sHoldContract = "";
    let sHoldLocation = "";
    let sHoldPeanutType = "";
    let sHoldPeanutVariety = "";
    let sHoldSymbolInd = "";
    let sHoldSeedGrower = "";
    let sHoldSeg = "";
    let sHoldOleic = "";
    let sHoldOrganic = "";
    let sHoldCenterPivot = "";
    let sHoldDryLand = "";
    let sHoldOther = "";
    let sHoldVendorSplits = "";
    let isFormValid = true;
    for (let i = 0; i < thisObj.values.gridCntrctShr.length; i++) {
      if (thisObj.values.gridCntrctShr[i].txtShare != undefined && thisObj.values.gridCntrctShr[i].txtShare != null && thisObj.values.gridCntrctShr[i].txtShare != "" && thisObj.values.gridCntrctShr[i].txtShare != 0) {
        let response = await ContractManagementService.RetrieveContractDetails(
          thisObj.values.gridCntrctShr[i].txtContracts,
          "PN1010"
        );
        if (response) {
          let temp = response;
          let contractdetails = temp[0];
          if (contractdetails !== undefined && contractdetails !== null) {
            let contract = contractdetails.cont_type;
            if (contract == "Seed") {
              showMessage(thisObj, "Seed Contracts cannot be added to a grouping");
            }
            let contractstatus = contractdetails.cont_status;
            if (contractstatus !== "A") {
              switch (contractstatus) {
                case "P":
                  contractstatus = "Pending";
                  break;
                case "S":
                  contractstatus = "Signed";
                  break;
                case "C":
                  contractstatus = "Closed";
                  break;
                case "V":
                  let cancelDate = contractdetails.auto_cancel_date;
                  if (cancelDate !== "") {
                    if (isValidDate(cancelDate)) {
                      contractstatus = "Cancelled";
                      break;
                    } else {
                      contractstatus = "Voided";
                      break;
                    }
                  } else {
                    contractstatus = "Voided";
                    break;
                  }
                case "C":
                  contractstatus = "Closed";
                  break;
              }
              showMessage(thisObj, "Contract status is not approved for contract in this grouping");
              isFormValid = false;
              return isFormValid;
            }
            let bFirm = false;
            let bBasis = false;
            let sContractPricings = contractdetails.contract_pricings;
            for (let i = 0; i < sContractPricings.length; i++) {
              if (sContractPricings.firm_basis == "F") {
                bFirm = true;
              }
              if (sContractPricings.firm_basis == "B") {
                bBasis = true;
              }
            }

            if (bFirm && bBasis) {
              let contractnumber = contractdetails.contract_num;
              let message =
                "A Contract with both Firm and Basis cannot be added to a grouping.\n \n";
              message =
                message +
                "Contract " +
                contractnumber +
                "Invalid Contract Grouping";
              showMessage(thisObj, message);
            }

            if (sHoldContract == "") {
              sHoldContract = contractdetails;
              sHoldLocation = sHoldContract.buy_pt_id?.toUpperCase();
              sHoldPeanutType = sHoldContract.pnut_type_name?.toUpperCase();
              sHoldPeanutVariety = sHoldContract.pnut_variety_name?.toUpperCase();
              sHoldSymbolInd = sHoldContract.symbol_ind?.toUpperCase();
              sHoldSeedGrower = sHoldContract.seed_grower?.toUpperCase();
              sHoldSeg = sHoldContract.seg_type?.toUpperCase();
              sHoldOleic = sHoldContract.oleic_ind_name?.toUpperCase();
              sHoldOrganic = sHoldContract.organic?.toUpperCase();
              sHoldCenterPivot = sHoldContract.center_pivot_acres;
              sHoldDryLand = sHoldContract.dry_land_acres;
              sHoldOther = sHoldContract.other_acres;
              sHoldVendorSplits =
                sHoldContract.contract_vendor_splits[0].split_vendor;
            } else {
              if (sHoldLocation !== contractdetails.buy_pt_id?.toUpperCase()) {
                var message =
                  "The location is not the same on all contracts in this grouping.";
                showMessage(thisObj, message);
                isFormValid = false;
                return isFormValid;
              }

              if (
                sHoldPeanutType !== contractdetails.pnut_type_name?.toUpperCase()
              ) {
                showMessage(thisObj,
                  "The peanut type is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }

              if (
                sHoldPeanutVariety !==
                contractdetails.pnut_variety_name?.toUpperCase() ||
                sHoldSymbolInd != contractdetails.symbol_ind?.toUpperCase()
              ) {
                switch (sHoldSymbolInd) {
                  case "TRADEMARK":
                    sHoldSymbolInd = " " + " ᵀᴹ";
                    break;
                  case "REGISTERED TRADEMARK":
                    sHoldSymbolInd = " " + " ®";
                    break;
                  case "COPYRIGHT":
                    sHoldSymbolInd = " " + " ©";
                    break;
                  default:
                    sHoldSymbolInd = "";
                    showMessage(thisObj,
                      "Status is not the same on all contracts in this grouping."
                    );
                    isFormValid = false;
                    return isFormValid;
                }
              }
              if (sHoldSeedGrower !== contractdetails.seed_grower?.toUpperCase()) {
                showMessage(thisObj,
                  "Seed Contracts cannot be added to a grouping"
                );
                isFormValid = false;
                return isFormValid;
              }
              if (sHoldSeg !== contractdetails.seg_type?.toUpperCase()) {
                showMessage(thisObj,
                  "Seg type is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }

              if (sHoldOleic !== contractdetails.oleic_ind_name?.toUpperCase()) {
                showMessage(thisObj,
                  "Oleic Ind is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }
              if (sHoldOrganic !== contractdetails.organic?.toUpperCase()) {
                showMessage(thisObj,
                  "Organic status is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }
              if (Number(sHoldCenterPivot) !== Number(contractdetails.center_pivot_acres)) {
                showMessage(thisObj,
                  "Center pivot acres is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }
              if (Number(sHoldDryLand) !== Number(contractdetails.dry_land_acres)) {
                showMessage(thisObj,
                  "Dry land acres is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }
              if (Number(sHoldOther) !== Number(contractdetails.other_acres)) {
                showMessage(thisObj,
                  "Other acres is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }
              if (
                sHoldVendorSplits !==
                contractdetails.contract_vendor_splits[0].split_vendor
              ) {
                showMessage(thisObj,
                  "Vendor is not the same on all contracts in this grouping."
                );
                isFormValid = false;
                return isFormValid;
              }
            }
          } else {
            showMessage(thisObj, "Invalid contract number: No grouings found")
            isFormValid = false;
            return isFormValid;
          }
        }
      } else {
        showMessage(thisObj, "Invalid share value")
        isFormValid = false;
        return isFormValid;
      }
    }
    return isFormValid;
  };


  const ontxtShareChange = () => {
    let TotalGridValue = getValue(thisObj, "gridCntrctShr")
    let Total = 0
    for (let i = 0; i < TotalGridValue.length; i++) {
      if (TotalGridValue[i].txtShare !== null && TotalGridValue[i].txtShare !== undefined && TotalGridValue[i].txtShare !== '' && TotalGridValue[i].txtShare !== 0) {
        Total = Total + parseInt(
          TotalGridValue[i].txtShare
        );
        TotalGridValue[i].txtShare = Number(TotalGridValue[i].txtShare).toFixed(2);
      }
    }
    setValue(thisObj, "txtTotal", Number(Total).toFixed(2));
  }
  thisObj.ontxtShareChange = ontxtShareChange

  // START_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractGroupingProfile*/}

              {/* END_USER_CODE-USER_BEFORE_ContractGroupingProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCntrctGrpPrfl*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCntrctGrpPrfl*/}

              <GroupBoxWidget
                conf={state.grpbxCntrctGrpPrfl}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxGrouping*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrouping*/}

                <GroupBoxWidget conf={state.grpbxGrouping} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtGroupingInput*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGroupingInput*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGroupingInput}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGroupingInput*/}

                  {/* END_USER_CODE-USER_AFTER_txtGroupingInput*/}
                  {/* START_USER_CODE-USER_BEFORE_date*/}

                  {/* END_USER_CODE-USER_BEFORE_date*/}

                  <DateWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.date}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_date*/}

                  {/* END_USER_CODE-USER_AFTER_date*/}
                  {/* START_USER_CODE-USER_BEFORE_dateFormat*/}

                  {/* END_USER_CODE-USER_BEFORE_dateFormat*/}

                  <LabelWidget
                    values={values}
                    conf={state.dateFormat}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_dateFormat*/}

                  {/* END_USER_CODE-USER_AFTER_dateFormat*/}
                  {/* START_USER_CODE-USER_BEFORE_gridCntrctShr*/}

                  {/* END_USER_CODE-USER_BEFORE_gridCntrctShr*/}

                  <GridWidget
                    conf={state.gridCntrctShr}
                    screenConf={state}
                    linkClick={(event) => {
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      );
                    }}
                    errors={errors}
                    touched={touched}
                    rows={values.gridCntrctShr}
                    onEvent={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    setFieldValue={setFieldValue}
                    refObject={thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridCntrctShr*/}

                  {/* END_USER_CODE-USER_AFTER_gridCntrctShr*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrouping*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrouping*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSearchCntrcts*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSearchCntrcts*/}

                <GroupBoxWidget
                  conf={state.grpbxSearchCntrcts}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearchforCntrcts*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearchforCntrcts*/}

                  <ButtonWidget
                    conf={state.btnSearchforCntrcts}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearchforCntrcts*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearchforCntrcts*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotal*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotal}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotal*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotal*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSearchCntrcts*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSearchCntrcts*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

                <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAddedBy}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                  {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                  <ButtonWidget
                    conf={state.btnUpdate}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    // onClick={()=>onButtonClick('delete')}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                  <ButtonWidget
                    conf={state.btnExit}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExit*/}

                  {/* END_USER_CODE-USER_AFTER_btnExit*/}
                  {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChangedBy}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                  {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCntrctGrpPrfl*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCntrctGrpPrfl*/}

              {/* START_USER_CODE-USER_AFTER_ContractGroupingProfile*/}

              {/* END_USER_CODE-USER_AFTER_ContractGroupingProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractGroupingProfile);
