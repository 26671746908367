/* eslint-disable*/
import React from "react";
import Settlements_PremiumDeductionsVendorSplitsTrade from "./PremiumDeductionsVendorSplitsTrade";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionsVendorSplitsTrade Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductionsVendorSplitsTrade />);
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionsVendorSplitsTrade Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionsVendorSplitsTrade"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionsVendorSplitsTrade", () => {
    // START_USER_CODE-USER_PremiumDeductionsVendorSplitsTrade_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionsVendorSplitsTrade_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductionsVendorSplitsTrade />);
    });
  });
  afterEach(cleanup);
  test("btnCanceluic0(Button Widget) Test Cases", async () => {
    const btnCanceluic0 = screen.getByTestId("btnCanceluic0");
    expect(btnCanceluic0).toBeInTheDocument;
    expect(btnCanceluic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_btnCanceluic0")
    );
  });
  test("Custom Test Cases for btnCanceluic0", () => {
    // START_USER_CODE-USER_btnCanceluic0_TEST
    // END_USER_CODE-USER_btnCanceluic0_TEST
  });
  test("btnOkuic0(Button Widget) Test Cases", async () => {
    const btnOkuic0 = screen.getByTestId("btnOkuic0");
    expect(btnOkuic0).toBeInTheDocument;
    expect(btnOkuic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_btnOkuic0")
    );
  });
  test("Custom Test Cases for btnOkuic0", () => {
    // START_USER_CODE-USER_btnOkuic0_TEST
    // END_USER_CODE-USER_btnOkuic0_TEST
  });
  test("grpbxPrchsLabelsuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsLabelsuic0 = screen.getByTestId("grpbxPrchsLabelsuic0");
    expect(grpbxPrchsLabelsuic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsLabelsuic0.type).toBe("button");
    expect(grpbxPrchsLabelsuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsLabelsuic0", () => {
    // START_USER_CODE-USER_grpbxPrchsLabelsuic0_TEST
    // END_USER_CODE-USER_grpbxPrchsLabelsuic0_TEST
  });
  test("grpbxPrchsRow10uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow10uic0 = screen.getByTestId("grpbxPrchsRow10uic0");
    expect(grpbxPrchsRow10uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow10uic0.type).toBe("button");
    expect(grpbxPrchsRow10uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow10uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow10uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow10uic0_TEST
  });
  test("grpbxPrchsRow11uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow11uic0 = screen.getByTestId("grpbxPrchsRow11uic0");
    expect(grpbxPrchsRow11uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow11uic0.type).toBe("button");
    expect(grpbxPrchsRow11uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow11uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow11uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow11uic0_TEST
  });
  test("grpbxPrchsRow12uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow12uic0 = screen.getByTestId("grpbxPrchsRow12uic0");
    expect(grpbxPrchsRow12uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow12uic0.type).toBe("button");
    expect(grpbxPrchsRow12uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow12uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow12uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow12uic0_TEST
  });
  test("grpbxPrchsRow13uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow13uic0 = screen.getByTestId("grpbxPrchsRow13uic0");
    expect(grpbxPrchsRow13uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow13uic0.type).toBe("button");
    expect(grpbxPrchsRow13uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow13uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow13uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow13uic0_TEST
  });
  test("grpbxPrchsRow14uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow14uic0 = screen.getByTestId("grpbxPrchsRow14uic0");
    expect(grpbxPrchsRow14uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow14uic0.type).toBe("button");
    expect(grpbxPrchsRow14uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow14uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow14uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow14uic0_TEST
  });
  test("grpbxPrchsRow15uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow15uic0 = screen.getByTestId("grpbxPrchsRow15uic0");
    expect(grpbxPrchsRow15uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow15uic0.type).toBe("button");
    expect(grpbxPrchsRow15uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow15uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow15uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow15uic0_TEST
  });
  test("grpbxPrchsRow16uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow16uic0 = screen.getByTestId("grpbxPrchsRow16uic0");
    expect(grpbxPrchsRow16uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow16uic0.type).toBe("button");
    expect(grpbxPrchsRow16uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow16uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow16uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow16uic0_TEST
  });
  test("grpbxPrchsRow17uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow17uic0 = screen.getByTestId("grpbxPrchsRow17uic0");
    expect(grpbxPrchsRow17uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow17uic0.type).toBe("button");
    expect(grpbxPrchsRow17uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow17uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow17uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow17uic0_TEST
  });
  test("grpbxPrchsRow18uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow18uic0 = screen.getByTestId("grpbxPrchsRow18uic0");
    expect(grpbxPrchsRow18uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow18uic0.type).toBe("button");
    expect(grpbxPrchsRow18uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow18uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow18uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow18uic0_TEST
  });
  test("grpbxPrchsRow19uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow19uic0 = screen.getByTestId("grpbxPrchsRow19uic0");
    expect(grpbxPrchsRow19uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow19uic0.type).toBe("button");
    expect(grpbxPrchsRow19uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow19uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow19uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow19uic0_TEST
  });
  test("grpbxPrchsRow1uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow1uic0 = screen.getByTestId("grpbxPrchsRow1uic0");
    expect(grpbxPrchsRow1uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow1uic0.type).toBe("button");
    expect(grpbxPrchsRow1uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow1uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow1uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow1uic0_TEST
  });
  test("grpbxPrchsRow20uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow20uic0 = screen.getByTestId("grpbxPrchsRow20uic0");
    expect(grpbxPrchsRow20uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow20uic0.type).toBe("button");
    expect(grpbxPrchsRow20uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow20uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow20uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow20uic0_TEST
  });
  test("grpbxPrchsRow2uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow2uic0 = screen.getByTestId("grpbxPrchsRow2uic0");
    expect(grpbxPrchsRow2uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow2uic0.type).toBe("button");
    expect(grpbxPrchsRow2uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow2uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow2uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow2uic0_TEST
  });
  test("grpbxPrchsRow3uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow3uic0 = screen.getByTestId("grpbxPrchsRow3uic0");
    expect(grpbxPrchsRow3uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow3uic0.type).toBe("button");
    expect(grpbxPrchsRow3uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow3uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow3uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow3uic0_TEST
  });
  test("grpbxPrchsRow4uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow4uic0 = screen.getByTestId("grpbxPrchsRow4uic0");
    expect(grpbxPrchsRow4uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow4uic0.type).toBe("button");
    expect(grpbxPrchsRow4uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow4uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow4uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow4uic0_TEST
  });
  test("grpbxPrchsRow5uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow5uic0 = screen.getByTestId("grpbxPrchsRow5uic0");
    expect(grpbxPrchsRow5uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow5uic0.type).toBe("button");
    expect(grpbxPrchsRow5uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow5uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow5uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow5uic0_TEST
  });
  test("grpbxPrchsRow6uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow6uic0 = screen.getByTestId("grpbxPrchsRow6uic0");
    expect(grpbxPrchsRow6uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow6uic0.type).toBe("button");
    expect(grpbxPrchsRow6uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow6uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow6uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow6uic0_TEST
  });
  test("grpbxPrchsRow7uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow7uic0 = screen.getByTestId("grpbxPrchsRow7uic0");
    expect(grpbxPrchsRow7uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow7uic0.type).toBe("button");
    expect(grpbxPrchsRow7uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow7uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow7uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow7uic0_TEST
  });
  test("grpbxPrchsRow8uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow8uic0 = screen.getByTestId("grpbxPrchsRow8uic0");
    expect(grpbxPrchsRow8uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow8uic0.type).toBe("button");
    expect(grpbxPrchsRow8uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow8uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow8uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow8uic0_TEST
  });
  test("grpbxPrchsRow9uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow9uic0 = screen.getByTestId("grpbxPrchsRow9uic0");
    expect(grpbxPrchsRow9uic0.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow9uic0.type).toBe("button");
    expect(grpbxPrchsRow9uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow9uic0", () => {
    // START_USER_CODE-USER_grpbxPrchsRow9uic0_TEST
    // END_USER_CODE-USER_grpbxPrchsRow9uic0_TEST
  });
  test("grpbxPremiumDeductionsVendorSplitsTrade(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionsVendorSplitsTrade = screen.getByTestId(
      "grpbxPremiumDeductionsVendorSplitsTrade"
    );
    expect(grpbxPremiumDeductionsVendorSplitsTrade.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionsVendorSplitsTrade.type).toBe("button");
    expect(grpbxPremiumDeductionsVendorSplitsTrade.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionsVendorSplitsTrade", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionsVendorSplitsTrade_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionsVendorSplitsTrade_TEST
  });
  test("grpbxPurchaseuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPurchaseuic0 = screen.getByTestId("grpbxPurchaseuic0");
    expect(grpbxPurchaseuic0.tagName).toBe("BUTTON");
    expect(grpbxPurchaseuic0.type).toBe("button");
    expect(grpbxPurchaseuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPurchaseuic0", () => {
    // START_USER_CODE-USER_grpbxPurchaseuic0_TEST
    // END_USER_CODE-USER_grpbxPurchaseuic0_TEST
  });
  test("grpbxStorageuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStorageuic0 = screen.getByTestId("grpbxStorageuic0");
    expect(grpbxStorageuic0.tagName).toBe("BUTTON");
    expect(grpbxStorageuic0.type).toBe("button");
    expect(grpbxStorageuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorageuic0", () => {
    // START_USER_CODE-USER_grpbxStorageuic0_TEST
    // END_USER_CODE-USER_grpbxStorageuic0_TEST
  });
  test("grpbxStrgLabelsuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgLabelsuic0 = screen.getByTestId("grpbxStrgLabelsuic0");
    expect(grpbxStrgLabelsuic0.tagName).toBe("BUTTON");
    expect(grpbxStrgLabelsuic0.type).toBe("button");
    expect(grpbxStrgLabelsuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgLabelsuic0", () => {
    // START_USER_CODE-USER_grpbxStrgLabelsuic0_TEST
    // END_USER_CODE-USER_grpbxStrgLabelsuic0_TEST
  });
  test("grpbxStrgRow10uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow10uic0 = screen.getByTestId("grpbxStrgRow10uic0");
    expect(grpbxStrgRow10uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow10uic0.type).toBe("button");
    expect(grpbxStrgRow10uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow10uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow10uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow10uic0_TEST
  });
  test("grpbxStrgRow11uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow11uic0 = screen.getByTestId("grpbxStrgRow11uic0");
    expect(grpbxStrgRow11uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow11uic0.type).toBe("button");
    expect(grpbxStrgRow11uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow11uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow11uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow11uic0_TEST
  });
  test("grpbxStrgRow12uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow12uic0 = screen.getByTestId("grpbxStrgRow12uic0");
    expect(grpbxStrgRow12uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow12uic0.type).toBe("button");
    expect(grpbxStrgRow12uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow12uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow12uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow12uic0_TEST
  });
  test("grpbxStrgRow13uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow13uic0 = screen.getByTestId("grpbxStrgRow13uic0");
    expect(grpbxStrgRow13uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow13uic0.type).toBe("button");
    expect(grpbxStrgRow13uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow13uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow13uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow13uic0_TEST
  });
  test("grpbxStrgRow14uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow14uic0 = screen.getByTestId("grpbxStrgRow14uic0");
    expect(grpbxStrgRow14uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow14uic0.type).toBe("button");
    expect(grpbxStrgRow14uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow14uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow14uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow14uic0_TEST
  });
  test("grpbxStrgRow15uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow15uic0 = screen.getByTestId("grpbxStrgRow15uic0");
    expect(grpbxStrgRow15uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow15uic0.type).toBe("button");
    expect(grpbxStrgRow15uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow15uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow15uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow15uic0_TEST
  });
  test("grpbxStrgRow16uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow16uic0 = screen.getByTestId("grpbxStrgRow16uic0");
    expect(grpbxStrgRow16uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow16uic0.type).toBe("button");
    expect(grpbxStrgRow16uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow16uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow16uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow16uic0_TEST
  });
  test("grpbxStrgRow17uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow17uic0 = screen.getByTestId("grpbxStrgRow17uic0");
    expect(grpbxStrgRow17uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow17uic0.type).toBe("button");
    expect(grpbxStrgRow17uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow17uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow17uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow17uic0_TEST
  });
  test("grpbxStrgRow18uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow18uic0 = screen.getByTestId("grpbxStrgRow18uic0");
    expect(grpbxStrgRow18uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow18uic0.type).toBe("button");
    expect(grpbxStrgRow18uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow18uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow18uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow18uic0_TEST
  });
  test("grpbxStrgRow19uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow19uic0 = screen.getByTestId("grpbxStrgRow19uic0");
    expect(grpbxStrgRow19uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow19uic0.type).toBe("button");
    expect(grpbxStrgRow19uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow19uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow19uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow19uic0_TEST
  });
  test("grpbxStrgRow1uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow1uic0 = screen.getByTestId("grpbxStrgRow1uic0");
    expect(grpbxStrgRow1uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow1uic0.type).toBe("button");
    expect(grpbxStrgRow1uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow1uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow1uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow1uic0_TEST
  });
  test("grpbxStrgRow20uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow20uic0 = screen.getByTestId("grpbxStrgRow20uic0");
    expect(grpbxStrgRow20uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow20uic0.type).toBe("button");
    expect(grpbxStrgRow20uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow20uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow20uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow20uic0_TEST
  });
  test("grpbxStrgRow2uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow2uic0 = screen.getByTestId("grpbxStrgRow2uic0");
    expect(grpbxStrgRow2uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow2uic0.type).toBe("button");
    expect(grpbxStrgRow2uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow2uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow2uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow2uic0_TEST
  });
  test("grpbxStrgRow3uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow3uic0 = screen.getByTestId("grpbxStrgRow3uic0");
    expect(grpbxStrgRow3uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow3uic0.type).toBe("button");
    expect(grpbxStrgRow3uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow3uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow3uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow3uic0_TEST
  });
  test("grpbxStrgRow4uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow4uic0 = screen.getByTestId("grpbxStrgRow4uic0");
    expect(grpbxStrgRow4uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow4uic0.type).toBe("button");
    expect(grpbxStrgRow4uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow4uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow4uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow4uic0_TEST
  });
  test("grpbxStrgRow5uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow5uic0 = screen.getByTestId("grpbxStrgRow5uic0");
    expect(grpbxStrgRow5uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow5uic0.type).toBe("button");
    expect(grpbxStrgRow5uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow5uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow5uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow5uic0_TEST
  });
  test("grpbxStrgRow6uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow6uic0 = screen.getByTestId("grpbxStrgRow6uic0");
    expect(grpbxStrgRow6uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow6uic0.type).toBe("button");
    expect(grpbxStrgRow6uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow6uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow6uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow6uic0_TEST
  });
  test("grpbxStrgRow7uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow7uic0 = screen.getByTestId("grpbxStrgRow7uic0");
    expect(grpbxStrgRow7uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow7uic0.type).toBe("button");
    expect(grpbxStrgRow7uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow7uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow7uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow7uic0_TEST
  });
  test("grpbxStrgRow8uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow8uic0 = screen.getByTestId("grpbxStrgRow8uic0");
    expect(grpbxStrgRow8uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow8uic0.type).toBe("button");
    expect(grpbxStrgRow8uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow8uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow8uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow8uic0_TEST
  });
  test("grpbxStrgRow9uic0(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow9uic0 = screen.getByTestId("grpbxStrgRow9uic0");
    expect(grpbxStrgRow9uic0.tagName).toBe("BUTTON");
    expect(grpbxStrgRow9uic0.type).toBe("button");
    expect(grpbxStrgRow9uic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow9uic0", () => {
    // START_USER_CODE-USER_grpbxStrgRow9uic0_TEST
    // END_USER_CODE-USER_grpbxStrgRow9uic0_TEST
  });
  test("grpbxTotalPrchsuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxTotalPrchsuic0 = screen.getByTestId("grpbxTotalPrchsuic0");
    expect(grpbxTotalPrchsuic0.tagName).toBe("BUTTON");
    expect(grpbxTotalPrchsuic0.type).toBe("button");
    expect(grpbxTotalPrchsuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTotalPrchsuic0", () => {
    // START_USER_CODE-USER_grpbxTotalPrchsuic0_TEST
    // END_USER_CODE-USER_grpbxTotalPrchsuic0_TEST
  });
  test("grpbxTotalStrguic0(GroupBox Widget) Test Cases", async () => {
    const grpbxTotalStrguic0 = screen.getByTestId("grpbxTotalStrguic0");
    expect(grpbxTotalStrguic0.tagName).toBe("BUTTON");
    expect(grpbxTotalStrguic0.type).toBe("button");
    expect(grpbxTotalStrguic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTotalStrguic0", () => {
    // START_USER_CODE-USER_grpbxTotalStrguic0_TEST
    // END_USER_CODE-USER_grpbxTotalStrguic0_TEST
  });
  test("lbl10suic0(Label Widget) Test Cases", async () => {
    const lbl10suic0 = screen.getByTestId("lbl10suic0");
    expect(lbl10suic0.tagName).toBe("LABEL");
    expect(lbl10suic0.classList).toContain("form-label");
    expect(lbl10suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl10suic0")
    );
  });
  test("Custom Test Cases for lbl10suic0", () => {
    // START_USER_CODE-USER_lbl10suic0_TEST
    // END_USER_CODE-USER_lbl10suic0_TEST
  });
  test("lbl10uic0(Label Widget) Test Cases", async () => {
    const lbl10uic0 = screen.getByTestId("lbl10uic0");
    expect(lbl10uic0.tagName).toBe("LABEL");
    expect(lbl10uic0.classList).toContain("form-label");
    expect(lbl10uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl10uic0")
    );
  });
  test("Custom Test Cases for lbl10uic0", () => {
    // START_USER_CODE-USER_lbl10uic0_TEST
    // END_USER_CODE-USER_lbl10uic0_TEST
  });
  test("lbl11suic0(Label Widget) Test Cases", async () => {
    const lbl11suic0 = screen.getByTestId("lbl11suic0");
    expect(lbl11suic0.tagName).toBe("LABEL");
    expect(lbl11suic0.classList).toContain("form-label");
    expect(lbl11suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl11suic0")
    );
  });
  test("Custom Test Cases for lbl11suic0", () => {
    // START_USER_CODE-USER_lbl11suic0_TEST
    // END_USER_CODE-USER_lbl11suic0_TEST
  });
  test("lbl11uic0(Label Widget) Test Cases", async () => {
    const lbl11uic0 = screen.getByTestId("lbl11uic0");
    expect(lbl11uic0.tagName).toBe("LABEL");
    expect(lbl11uic0.classList).toContain("form-label");
    expect(lbl11uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl11uic0")
    );
  });
  test("Custom Test Cases for lbl11uic0", () => {
    // START_USER_CODE-USER_lbl11uic0_TEST
    // END_USER_CODE-USER_lbl11uic0_TEST
  });
  test("lbl12suic0(Label Widget) Test Cases", async () => {
    const lbl12suic0 = screen.getByTestId("lbl12suic0");
    expect(lbl12suic0.tagName).toBe("LABEL");
    expect(lbl12suic0.classList).toContain("form-label");
    expect(lbl12suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl12suic0")
    );
  });
  test("Custom Test Cases for lbl12suic0", () => {
    // START_USER_CODE-USER_lbl12suic0_TEST
    // END_USER_CODE-USER_lbl12suic0_TEST
  });
  test("lbl12uic0(Label Widget) Test Cases", async () => {
    const lbl12uic0 = screen.getByTestId("lbl12uic0");
    expect(lbl12uic0.tagName).toBe("LABEL");
    expect(lbl12uic0.classList).toContain("form-label");
    expect(lbl12uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl12uic0")
    );
  });
  test("Custom Test Cases for lbl12uic0", () => {
    // START_USER_CODE-USER_lbl12uic0_TEST
    // END_USER_CODE-USER_lbl12uic0_TEST
  });
  test("lbl13suic0(Label Widget) Test Cases", async () => {
    const lbl13suic0 = screen.getByTestId("lbl13suic0");
    expect(lbl13suic0.tagName).toBe("LABEL");
    expect(lbl13suic0.classList).toContain("form-label");
    expect(lbl13suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl13suic0")
    );
  });
  test("Custom Test Cases for lbl13suic0", () => {
    // START_USER_CODE-USER_lbl13suic0_TEST
    // END_USER_CODE-USER_lbl13suic0_TEST
  });
  test("lbl13uic0(Label Widget) Test Cases", async () => {
    const lbl13uic0 = screen.getByTestId("lbl13uic0");
    expect(lbl13uic0.tagName).toBe("LABEL");
    expect(lbl13uic0.classList).toContain("form-label");
    expect(lbl13uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl13uic0")
    );
  });
  test("Custom Test Cases for lbl13uic0", () => {
    // START_USER_CODE-USER_lbl13uic0_TEST
    // END_USER_CODE-USER_lbl13uic0_TEST
  });
  test("lbl14suic0(Label Widget) Test Cases", async () => {
    const lbl14suic0 = screen.getByTestId("lbl14suic0");
    expect(lbl14suic0.tagName).toBe("LABEL");
    expect(lbl14suic0.classList).toContain("form-label");
    expect(lbl14suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl14suic0")
    );
  });
  test("Custom Test Cases for lbl14suic0", () => {
    // START_USER_CODE-USER_lbl14suic0_TEST
    // END_USER_CODE-USER_lbl14suic0_TEST
  });
  test("lbl14uic0(Label Widget) Test Cases", async () => {
    const lbl14uic0 = screen.getByTestId("lbl14uic0");
    expect(lbl14uic0.tagName).toBe("LABEL");
    expect(lbl14uic0.classList).toContain("form-label");
    expect(lbl14uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl14uic0")
    );
  });
  test("Custom Test Cases for lbl14uic0", () => {
    // START_USER_CODE-USER_lbl14uic0_TEST
    // END_USER_CODE-USER_lbl14uic0_TEST
  });
  test("lbl15suic0(Label Widget) Test Cases", async () => {
    const lbl15suic0 = screen.getByTestId("lbl15suic0");
    expect(lbl15suic0.tagName).toBe("LABEL");
    expect(lbl15suic0.classList).toContain("form-label");
    expect(lbl15suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl15suic0")
    );
  });
  test("Custom Test Cases for lbl15suic0", () => {
    // START_USER_CODE-USER_lbl15suic0_TEST
    // END_USER_CODE-USER_lbl15suic0_TEST
  });
  test("lbl15uic0(Label Widget) Test Cases", async () => {
    const lbl15uic0 = screen.getByTestId("lbl15uic0");
    expect(lbl15uic0.tagName).toBe("LABEL");
    expect(lbl15uic0.classList).toContain("form-label");
    expect(lbl15uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl15uic0")
    );
  });
  test("Custom Test Cases for lbl15uic0", () => {
    // START_USER_CODE-USER_lbl15uic0_TEST
    // END_USER_CODE-USER_lbl15uic0_TEST
  });
  test("lbl16suic0(Label Widget) Test Cases", async () => {
    const lbl16suic0 = screen.getByTestId("lbl16suic0");
    expect(lbl16suic0.tagName).toBe("LABEL");
    expect(lbl16suic0.classList).toContain("form-label");
    expect(lbl16suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl16suic0")
    );
  });
  test("Custom Test Cases for lbl16suic0", () => {
    // START_USER_CODE-USER_lbl16suic0_TEST
    // END_USER_CODE-USER_lbl16suic0_TEST
  });
  test("lbl16uic0(Label Widget) Test Cases", async () => {
    const lbl16uic0 = screen.getByTestId("lbl16uic0");
    expect(lbl16uic0.tagName).toBe("LABEL");
    expect(lbl16uic0.classList).toContain("form-label");
    expect(lbl16uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl16uic0")
    );
  });
  test("Custom Test Cases for lbl16uic0", () => {
    // START_USER_CODE-USER_lbl16uic0_TEST
    // END_USER_CODE-USER_lbl16uic0_TEST
  });
  test("lbl17suic0(Label Widget) Test Cases", async () => {
    const lbl17suic0 = screen.getByTestId("lbl17suic0");
    expect(lbl17suic0.tagName).toBe("LABEL");
    expect(lbl17suic0.classList).toContain("form-label");
    expect(lbl17suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl17suic0")
    );
  });
  test("Custom Test Cases for lbl17suic0", () => {
    // START_USER_CODE-USER_lbl17suic0_TEST
    // END_USER_CODE-USER_lbl17suic0_TEST
  });
  test("lbl17uic0(Label Widget) Test Cases", async () => {
    const lbl17uic0 = screen.getByTestId("lbl17uic0");
    expect(lbl17uic0.tagName).toBe("LABEL");
    expect(lbl17uic0.classList).toContain("form-label");
    expect(lbl17uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl17uic0")
    );
  });
  test("Custom Test Cases for lbl17uic0", () => {
    // START_USER_CODE-USER_lbl17uic0_TEST
    // END_USER_CODE-USER_lbl17uic0_TEST
  });
  test("lbl18suic0(Label Widget) Test Cases", async () => {
    const lbl18suic0 = screen.getByTestId("lbl18suic0");
    expect(lbl18suic0.tagName).toBe("LABEL");
    expect(lbl18suic0.classList).toContain("form-label");
    expect(lbl18suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl18suic0")
    );
  });
  test("Custom Test Cases for lbl18suic0", () => {
    // START_USER_CODE-USER_lbl18suic0_TEST
    // END_USER_CODE-USER_lbl18suic0_TEST
  });
  test("lbl18uic0(Label Widget) Test Cases", async () => {
    const lbl18uic0 = screen.getByTestId("lbl18uic0");
    expect(lbl18uic0.tagName).toBe("LABEL");
    expect(lbl18uic0.classList).toContain("form-label");
    expect(lbl18uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl18uic0")
    );
  });
  test("Custom Test Cases for lbl18uic0", () => {
    // START_USER_CODE-USER_lbl18uic0_TEST
    // END_USER_CODE-USER_lbl18uic0_TEST
  });
  test("lbl19suic0(Label Widget) Test Cases", async () => {
    const lbl19suic0 = screen.getByTestId("lbl19suic0");
    expect(lbl19suic0.tagName).toBe("LABEL");
    expect(lbl19suic0.classList).toContain("form-label");
    expect(lbl19suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl19suic0")
    );
  });
  test("Custom Test Cases for lbl19suic0", () => {
    // START_USER_CODE-USER_lbl19suic0_TEST
    // END_USER_CODE-USER_lbl19suic0_TEST
  });
  test("lbl19uic0(Label Widget) Test Cases", async () => {
    const lbl19uic0 = screen.getByTestId("lbl19uic0");
    expect(lbl19uic0.tagName).toBe("LABEL");
    expect(lbl19uic0.classList).toContain("form-label");
    expect(lbl19uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl19uic0")
    );
  });
  test("Custom Test Cases for lbl19uic0", () => {
    // START_USER_CODE-USER_lbl19uic0_TEST
    // END_USER_CODE-USER_lbl19uic0_TEST
  });
  test("lbl1suic0(Label Widget) Test Cases", async () => {
    const lbl1suic0 = screen.getByTestId("lbl1suic0");
    expect(lbl1suic0.tagName).toBe("LABEL");
    expect(lbl1suic0.classList).toContain("form-label");
    expect(lbl1suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl1suic0")
    );
  });
  test("Custom Test Cases for lbl1suic0", () => {
    // START_USER_CODE-USER_lbl1suic0_TEST
    // END_USER_CODE-USER_lbl1suic0_TEST
  });
  test("lbl1uic0(Label Widget) Test Cases", async () => {
    const lbl1uic0 = screen.getByTestId("lbl1uic0");
    expect(lbl1uic0.tagName).toBe("LABEL");
    expect(lbl1uic0.classList).toContain("form-label");
    expect(lbl1uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl1uic0")
    );
  });
  test("Custom Test Cases for lbl1uic0", () => {
    // START_USER_CODE-USER_lbl1uic0_TEST
    // END_USER_CODE-USER_lbl1uic0_TEST
  });
  test("lbl20suic0(Label Widget) Test Cases", async () => {
    const lbl20suic0 = screen.getByTestId("lbl20suic0");
    expect(lbl20suic0.tagName).toBe("LABEL");
    expect(lbl20suic0.classList).toContain("form-label");
    expect(lbl20suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl20suic0")
    );
  });
  test("Custom Test Cases for lbl20suic0", () => {
    // START_USER_CODE-USER_lbl20suic0_TEST
    // END_USER_CODE-USER_lbl20suic0_TEST
  });
  test("lbl20uic0(Label Widget) Test Cases", async () => {
    const lbl20uic0 = screen.getByTestId("lbl20uic0");
    expect(lbl20uic0.tagName).toBe("LABEL");
    expect(lbl20uic0.classList).toContain("form-label");
    expect(lbl20uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl20uic0")
    );
  });
  test("Custom Test Cases for lbl20uic0", () => {
    // START_USER_CODE-USER_lbl20uic0_TEST
    // END_USER_CODE-USER_lbl20uic0_TEST
  });
  test("lbl2suic0(Label Widget) Test Cases", async () => {
    const lbl2suic0 = screen.getByTestId("lbl2suic0");
    expect(lbl2suic0.tagName).toBe("LABEL");
    expect(lbl2suic0.classList).toContain("form-label");
    expect(lbl2suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl2suic0")
    );
  });
  test("Custom Test Cases for lbl2suic0", () => {
    // START_USER_CODE-USER_lbl2suic0_TEST
    // END_USER_CODE-USER_lbl2suic0_TEST
  });
  test("lbl2uic0(Label Widget) Test Cases", async () => {
    const lbl2uic0 = screen.getByTestId("lbl2uic0");
    expect(lbl2uic0.tagName).toBe("LABEL");
    expect(lbl2uic0.classList).toContain("form-label");
    expect(lbl2uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl2uic0")
    );
  });
  test("Custom Test Cases for lbl2uic0", () => {
    // START_USER_CODE-USER_lbl2uic0_TEST
    // END_USER_CODE-USER_lbl2uic0_TEST
  });
  test("lbl3suic0(Label Widget) Test Cases", async () => {
    const lbl3suic0 = screen.getByTestId("lbl3suic0");
    expect(lbl3suic0.tagName).toBe("LABEL");
    expect(lbl3suic0.classList).toContain("form-label");
    expect(lbl3suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl3suic0")
    );
  });
  test("Custom Test Cases for lbl3suic0", () => {
    // START_USER_CODE-USER_lbl3suic0_TEST
    // END_USER_CODE-USER_lbl3suic0_TEST
  });
  test("lbl3uic0(Label Widget) Test Cases", async () => {
    const lbl3uic0 = screen.getByTestId("lbl3uic0");
    expect(lbl3uic0.tagName).toBe("LABEL");
    expect(lbl3uic0.classList).toContain("form-label");
    expect(lbl3uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl3uic0")
    );
  });
  test("Custom Test Cases for lbl3uic0", () => {
    // START_USER_CODE-USER_lbl3uic0_TEST
    // END_USER_CODE-USER_lbl3uic0_TEST
  });
  test("lbl4suic0(Label Widget) Test Cases", async () => {
    const lbl4suic0 = screen.getByTestId("lbl4suic0");
    expect(lbl4suic0.tagName).toBe("LABEL");
    expect(lbl4suic0.classList).toContain("form-label");
    expect(lbl4suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl4suic0")
    );
  });
  test("Custom Test Cases for lbl4suic0", () => {
    // START_USER_CODE-USER_lbl4suic0_TEST
    // END_USER_CODE-USER_lbl4suic0_TEST
  });
  test("lbl4uic0(Label Widget) Test Cases", async () => {
    const lbl4uic0 = screen.getByTestId("lbl4uic0");
    expect(lbl4uic0.tagName).toBe("LABEL");
    expect(lbl4uic0.classList).toContain("form-label");
    expect(lbl4uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl4uic0")
    );
  });
  test("Custom Test Cases for lbl4uic0", () => {
    // START_USER_CODE-USER_lbl4uic0_TEST
    // END_USER_CODE-USER_lbl4uic0_TEST
  });
  test("lbl5suic0(Label Widget) Test Cases", async () => {
    const lbl5suic0 = screen.getByTestId("lbl5suic0");
    expect(lbl5suic0.tagName).toBe("LABEL");
    expect(lbl5suic0.classList).toContain("form-label");
    expect(lbl5suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl5suic0")
    );
  });
  test("Custom Test Cases for lbl5suic0", () => {
    // START_USER_CODE-USER_lbl5suic0_TEST
    // END_USER_CODE-USER_lbl5suic0_TEST
  });
  test("lbl5uic0(Label Widget) Test Cases", async () => {
    const lbl5uic0 = screen.getByTestId("lbl5uic0");
    expect(lbl5uic0.tagName).toBe("LABEL");
    expect(lbl5uic0.classList).toContain("form-label");
    expect(lbl5uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl5uic0")
    );
  });
  test("Custom Test Cases for lbl5uic0", () => {
    // START_USER_CODE-USER_lbl5uic0_TEST
    // END_USER_CODE-USER_lbl5uic0_TEST
  });
  test("lbl6suic0(Label Widget) Test Cases", async () => {
    const lbl6suic0 = screen.getByTestId("lbl6suic0");
    expect(lbl6suic0.tagName).toBe("LABEL");
    expect(lbl6suic0.classList).toContain("form-label");
    expect(lbl6suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl6suic0")
    );
  });
  test("Custom Test Cases for lbl6suic0", () => {
    // START_USER_CODE-USER_lbl6suic0_TEST
    // END_USER_CODE-USER_lbl6suic0_TEST
  });
  test("lbl6uic0(Label Widget) Test Cases", async () => {
    const lbl6uic0 = screen.getByTestId("lbl6uic0");
    expect(lbl6uic0.tagName).toBe("LABEL");
    expect(lbl6uic0.classList).toContain("form-label");
    expect(lbl6uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl6uic0")
    );
  });
  test("Custom Test Cases for lbl6uic0", () => {
    // START_USER_CODE-USER_lbl6uic0_TEST
    // END_USER_CODE-USER_lbl6uic0_TEST
  });
  test("lbl7suic0(Label Widget) Test Cases", async () => {
    const lbl7suic0 = screen.getByTestId("lbl7suic0");
    expect(lbl7suic0.tagName).toBe("LABEL");
    expect(lbl7suic0.classList).toContain("form-label");
    expect(lbl7suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl7suic0")
    );
  });
  test("Custom Test Cases for lbl7suic0", () => {
    // START_USER_CODE-USER_lbl7suic0_TEST
    // END_USER_CODE-USER_lbl7suic0_TEST
  });
  test("lbl7uic0(Label Widget) Test Cases", async () => {
    const lbl7uic0 = screen.getByTestId("lbl7uic0");
    expect(lbl7uic0.tagName).toBe("LABEL");
    expect(lbl7uic0.classList).toContain("form-label");
    expect(lbl7uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl7uic0")
    );
  });
  test("Custom Test Cases for lbl7uic0", () => {
    // START_USER_CODE-USER_lbl7uic0_TEST
    // END_USER_CODE-USER_lbl7uic0_TEST
  });
  test("lbl8suic0(Label Widget) Test Cases", async () => {
    const lbl8suic0 = screen.getByTestId("lbl8suic0");
    expect(lbl8suic0.tagName).toBe("LABEL");
    expect(lbl8suic0.classList).toContain("form-label");
    expect(lbl8suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl8suic0")
    );
  });
  test("Custom Test Cases for lbl8suic0", () => {
    // START_USER_CODE-USER_lbl8suic0_TEST
    // END_USER_CODE-USER_lbl8suic0_TEST
  });
  test("lbl8uic0(Label Widget) Test Cases", async () => {
    const lbl8uic0 = screen.getByTestId("lbl8uic0");
    expect(lbl8uic0.tagName).toBe("LABEL");
    expect(lbl8uic0.classList).toContain("form-label");
    expect(lbl8uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl8uic0")
    );
  });
  test("Custom Test Cases for lbl8uic0", () => {
    // START_USER_CODE-USER_lbl8uic0_TEST
    // END_USER_CODE-USER_lbl8uic0_TEST
  });
  test("lbl9suic0(Label Widget) Test Cases", async () => {
    const lbl9suic0 = screen.getByTestId("lbl9suic0");
    expect(lbl9suic0.tagName).toBe("LABEL");
    expect(lbl9suic0.classList).toContain("form-label");
    expect(lbl9suic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl9suic0")
    );
  });
  test("Custom Test Cases for lbl9suic0", () => {
    // START_USER_CODE-USER_lbl9suic0_TEST
    // END_USER_CODE-USER_lbl9suic0_TEST
  });
  test("lbl9uic0(Label Widget) Test Cases", async () => {
    const lbl9uic0 = screen.getByTestId("lbl9uic0");
    expect(lbl9uic0.tagName).toBe("LABEL");
    expect(lbl9uic0.classList).toContain("form-label");
    expect(lbl9uic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lbl9uic0")
    );
  });
  test("Custom Test Cases for lbl9uic0", () => {
    // START_USER_CODE-USER_lbl9uic0_TEST
    // END_USER_CODE-USER_lbl9uic0_TEST
  });
  test("lblPurchaseuic0(Label Widget) Test Cases", async () => {
    const lblPurchaseuic0 = screen.getByTestId("lblPurchaseuic0");
    expect(lblPurchaseuic0.tagName).toBe("LABEL");
    expect(lblPurchaseuic0.classList).toContain("form-label");
    expect(lblPurchaseuic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblPurchaseuic0")
    );
  });
  test("Custom Test Cases for lblPurchaseuic0", () => {
    // START_USER_CODE-USER_lblPurchaseuic0_TEST
    // END_USER_CODE-USER_lblPurchaseuic0_TEST
  });
  test("lblShareAmountPrchsuic0(Label Widget) Test Cases", async () => {
    const lblShareAmountPrchsuic0 = screen.getByTestId(
      "lblShareAmountPrchsuic0"
    );
    expect(lblShareAmountPrchsuic0.tagName).toBe("LABEL");
    expect(lblShareAmountPrchsuic0.classList).toContain("form-label");
    expect(lblShareAmountPrchsuic0.textContent).toEqual(
      t(
        "Settlements:PremiumDeductionsVendorSplitsTrade_lblShareAmountPrchsuic0"
      )
    );
  });
  test("Custom Test Cases for lblShareAmountPrchsuic0", () => {
    // START_USER_CODE-USER_lblShareAmountPrchsuic0_TEST
    // END_USER_CODE-USER_lblShareAmountPrchsuic0_TEST
  });
  test("lblShareAmountStrguic0(Label Widget) Test Cases", async () => {
    const lblShareAmountStrguic0 = screen.getByTestId("lblShareAmountStrguic0");
    expect(lblShareAmountStrguic0.tagName).toBe("LABEL");
    expect(lblShareAmountStrguic0.classList).toContain("form-label");
    expect(lblShareAmountStrguic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblShareAmountStrguic0")
    );
  });
  test("Custom Test Cases for lblShareAmountStrguic0", () => {
    // START_USER_CODE-USER_lblShareAmountStrguic0_TEST
    // END_USER_CODE-USER_lblShareAmountStrguic0_TEST
  });
  test("lblSharePctPrchsuic0(Label Widget) Test Cases", async () => {
    const lblSharePctPrchsuic0 = screen.getByTestId("lblSharePctPrchsuic0");
    expect(lblSharePctPrchsuic0.tagName).toBe("LABEL");
    expect(lblSharePctPrchsuic0.classList).toContain("form-label");
    expect(lblSharePctPrchsuic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblSharePctPrchsuic0")
    );
  });
  test("Custom Test Cases for lblSharePctPrchsuic0", () => {
    // START_USER_CODE-USER_lblSharePctPrchsuic0_TEST
    // END_USER_CODE-USER_lblSharePctPrchsuic0_TEST
  });
  test("lblSharePctStrguic0(Label Widget) Test Cases", async () => {
    const lblSharePctStrguic0 = screen.getByTestId("lblSharePctStrguic0");
    expect(lblSharePctStrguic0.tagName).toBe("LABEL");
    expect(lblSharePctStrguic0.classList).toContain("form-label");
    expect(lblSharePctStrguic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblSharePctStrguic0")
    );
  });
  test("Custom Test Cases for lblSharePctStrguic0", () => {
    // START_USER_CODE-USER_lblSharePctStrguic0_TEST
    // END_USER_CODE-USER_lblSharePctStrguic0_TEST
  });
  test("lblStorageuic0(Label Widget) Test Cases", async () => {
    const lblStorageuic0 = screen.getByTestId("lblStorageuic0");
    expect(lblStorageuic0.tagName).toBe("LABEL");
    expect(lblStorageuic0.classList).toContain("form-label");
    expect(lblStorageuic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblStorageuic0")
    );
  });
  test("Custom Test Cases for lblStorageuic0", () => {
    // START_USER_CODE-USER_lblStorageuic0_TEST
    // END_USER_CODE-USER_lblStorageuic0_TEST
  });
  test("lblVendorPrchsuic0(Label Widget) Test Cases", async () => {
    const lblVendorPrchsuic0 = screen.getByTestId("lblVendorPrchsuic0");
    expect(lblVendorPrchsuic0.tagName).toBe("LABEL");
    expect(lblVendorPrchsuic0.classList).toContain("form-label");
    expect(lblVendorPrchsuic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblVendorPrchsuic0")
    );
  });
  test("Custom Test Cases for lblVendorPrchsuic0", () => {
    // START_USER_CODE-USER_lblVendorPrchsuic0_TEST
    // END_USER_CODE-USER_lblVendorPrchsuic0_TEST
  });
  test("lblVendorStrguic0(Label Widget) Test Cases", async () => {
    const lblVendorStrguic0 = screen.getByTestId("lblVendorStrguic0");
    expect(lblVendorStrguic0.tagName).toBe("LABEL");
    expect(lblVendorStrguic0.classList).toContain("form-label");
    expect(lblVendorStrguic0.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_lblVendorStrguic0")
    );
  });
  test("Custom Test Cases for lblVendorStrguic0", () => {
    // START_USER_CODE-USER_lblVendorStrguic0_TEST
    // END_USER_CODE-USER_lblVendorStrguic0_TEST
  });
  test("txtShareAmount10suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount10suic0 = screen.getByTestId("txtShareAmount10suic0");
    expect(txtShareAmount10suic0.tagName).toBe("INPUT");
    expect(txtShareAmount10suic0.type).toBe("text");
    expect(txtShareAmount10suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount10suic0, "123");
    });
    expect(txtShareAmount10suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount10suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount10suic0", () => {
    // START_USER_CODE-USER_txtShareAmount10suic0_TEST
    // END_USER_CODE-USER_txtShareAmount10suic0_TEST
  });
  test("txtShareAmount10uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount10uic0 = screen.getByTestId("txtShareAmount10uic0");
    expect(txtShareAmount10uic0.tagName).toBe("INPUT");
    expect(txtShareAmount10uic0.type).toBe("text");
    expect(txtShareAmount10uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount10uic0, "123");
    });
    expect(txtShareAmount10uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount10uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount10uic0", () => {
    // START_USER_CODE-USER_txtShareAmount10uic0_TEST
    // END_USER_CODE-USER_txtShareAmount10uic0_TEST
  });
  test("txtShareAmount11suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount11suic0 = screen.getByTestId("txtShareAmount11suic0");
    expect(txtShareAmount11suic0.tagName).toBe("INPUT");
    expect(txtShareAmount11suic0.type).toBe("text");
    expect(txtShareAmount11suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount11suic0, "123");
    });
    expect(txtShareAmount11suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount11suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount11suic0", () => {
    // START_USER_CODE-USER_txtShareAmount11suic0_TEST
    // END_USER_CODE-USER_txtShareAmount11suic0_TEST
  });
  test("txtShareAmount11uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount11uic0 = screen.getByTestId("txtShareAmount11uic0");
    expect(txtShareAmount11uic0.tagName).toBe("INPUT");
    expect(txtShareAmount11uic0.type).toBe("text");
    expect(txtShareAmount11uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount11uic0, "123");
    });
    expect(txtShareAmount11uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount11uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount11uic0", () => {
    // START_USER_CODE-USER_txtShareAmount11uic0_TEST
    // END_USER_CODE-USER_txtShareAmount11uic0_TEST
  });
  test("txtShareAmount12suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount12suic0 = screen.getByTestId("txtShareAmount12suic0");
    expect(txtShareAmount12suic0.tagName).toBe("INPUT");
    expect(txtShareAmount12suic0.type).toBe("text");
    expect(txtShareAmount12suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount12suic0, "123");
    });
    expect(txtShareAmount12suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount12suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount12suic0", () => {
    // START_USER_CODE-USER_txtShareAmount12suic0_TEST
    // END_USER_CODE-USER_txtShareAmount12suic0_TEST
  });
  test("txtShareAmount12uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount12uic0 = screen.getByTestId("txtShareAmount12uic0");
    expect(txtShareAmount12uic0.tagName).toBe("INPUT");
    expect(txtShareAmount12uic0.type).toBe("text");
    expect(txtShareAmount12uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount12uic0, "123");
    });
    expect(txtShareAmount12uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount12uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount12uic0", () => {
    // START_USER_CODE-USER_txtShareAmount12uic0_TEST
    // END_USER_CODE-USER_txtShareAmount12uic0_TEST
  });
  test("txtShareAmount13suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount13suic0 = screen.getByTestId("txtShareAmount13suic0");
    expect(txtShareAmount13suic0.tagName).toBe("INPUT");
    expect(txtShareAmount13suic0.type).toBe("text");
    expect(txtShareAmount13suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount13suic0, "123");
    });
    expect(txtShareAmount13suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount13suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount13suic0", () => {
    // START_USER_CODE-USER_txtShareAmount13suic0_TEST
    // END_USER_CODE-USER_txtShareAmount13suic0_TEST
  });
  test("txtShareAmount13uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount13uic0 = screen.getByTestId("txtShareAmount13uic0");
    expect(txtShareAmount13uic0.tagName).toBe("INPUT");
    expect(txtShareAmount13uic0.type).toBe("text");
    expect(txtShareAmount13uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount13uic0, "123");
    });
    expect(txtShareAmount13uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount13uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount13uic0", () => {
    // START_USER_CODE-USER_txtShareAmount13uic0_TEST
    // END_USER_CODE-USER_txtShareAmount13uic0_TEST
  });
  test("txtShareAmount14suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount14suic0 = screen.getByTestId("txtShareAmount14suic0");
    expect(txtShareAmount14suic0.tagName).toBe("INPUT");
    expect(txtShareAmount14suic0.type).toBe("text");
    expect(txtShareAmount14suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount14suic0, "123");
    });
    expect(txtShareAmount14suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount14suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount14suic0", () => {
    // START_USER_CODE-USER_txtShareAmount14suic0_TEST
    // END_USER_CODE-USER_txtShareAmount14suic0_TEST
  });
  test("txtShareAmount14uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount14uic0 = screen.getByTestId("txtShareAmount14uic0");
    expect(txtShareAmount14uic0.tagName).toBe("INPUT");
    expect(txtShareAmount14uic0.type).toBe("text");
    expect(txtShareAmount14uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount14uic0, "123");
    });
    expect(txtShareAmount14uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount14uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount14uic0", () => {
    // START_USER_CODE-USER_txtShareAmount14uic0_TEST
    // END_USER_CODE-USER_txtShareAmount14uic0_TEST
  });
  test("txtShareAmount15suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount15suic0 = screen.getByTestId("txtShareAmount15suic0");
    expect(txtShareAmount15suic0.tagName).toBe("INPUT");
    expect(txtShareAmount15suic0.type).toBe("text");
    expect(txtShareAmount15suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount15suic0, "123");
    });
    expect(txtShareAmount15suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount15suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount15suic0", () => {
    // START_USER_CODE-USER_txtShareAmount15suic0_TEST
    // END_USER_CODE-USER_txtShareAmount15suic0_TEST
  });
  test("txtShareAmount15uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount15uic0 = screen.getByTestId("txtShareAmount15uic0");
    expect(txtShareAmount15uic0.tagName).toBe("INPUT");
    expect(txtShareAmount15uic0.type).toBe("text");
    expect(txtShareAmount15uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount15uic0, "123");
    });
    expect(txtShareAmount15uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount15uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount15uic0", () => {
    // START_USER_CODE-USER_txtShareAmount15uic0_TEST
    // END_USER_CODE-USER_txtShareAmount15uic0_TEST
  });
  test("txtShareAmount16suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount16suic0 = screen.getByTestId("txtShareAmount16suic0");
    expect(txtShareAmount16suic0.tagName).toBe("INPUT");
    expect(txtShareAmount16suic0.type).toBe("text");
    expect(txtShareAmount16suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount16suic0, "123");
    });
    expect(txtShareAmount16suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount16suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount16suic0", () => {
    // START_USER_CODE-USER_txtShareAmount16suic0_TEST
    // END_USER_CODE-USER_txtShareAmount16suic0_TEST
  });
  test("txtShareAmount16uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount16uic0 = screen.getByTestId("txtShareAmount16uic0");
    expect(txtShareAmount16uic0.tagName).toBe("INPUT");
    expect(txtShareAmount16uic0.type).toBe("text");
    expect(txtShareAmount16uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount16uic0, "123");
    });
    expect(txtShareAmount16uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount16uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount16uic0", () => {
    // START_USER_CODE-USER_txtShareAmount16uic0_TEST
    // END_USER_CODE-USER_txtShareAmount16uic0_TEST
  });
  test("txtShareAmount17suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount17suic0 = screen.getByTestId("txtShareAmount17suic0");
    expect(txtShareAmount17suic0.tagName).toBe("INPUT");
    expect(txtShareAmount17suic0.type).toBe("text");
    expect(txtShareAmount17suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount17suic0, "123");
    });
    expect(txtShareAmount17suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount17suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount17suic0", () => {
    // START_USER_CODE-USER_txtShareAmount17suic0_TEST
    // END_USER_CODE-USER_txtShareAmount17suic0_TEST
  });
  test("txtShareAmount17uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount17uic0 = screen.getByTestId("txtShareAmount17uic0");
    expect(txtShareAmount17uic0.tagName).toBe("INPUT");
    expect(txtShareAmount17uic0.type).toBe("text");
    expect(txtShareAmount17uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount17uic0, "123");
    });
    expect(txtShareAmount17uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount17uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount17uic0", () => {
    // START_USER_CODE-USER_txtShareAmount17uic0_TEST
    // END_USER_CODE-USER_txtShareAmount17uic0_TEST
  });
  test("txtShareAmount18suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount18suic0 = screen.getByTestId("txtShareAmount18suic0");
    expect(txtShareAmount18suic0.tagName).toBe("INPUT");
    expect(txtShareAmount18suic0.type).toBe("text");
    expect(txtShareAmount18suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount18suic0, "123");
    });
    expect(txtShareAmount18suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount18suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount18suic0", () => {
    // START_USER_CODE-USER_txtShareAmount18suic0_TEST
    // END_USER_CODE-USER_txtShareAmount18suic0_TEST
  });
  test("txtShareAmount18uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount18uic0 = screen.getByTestId("txtShareAmount18uic0");
    expect(txtShareAmount18uic0.tagName).toBe("INPUT");
    expect(txtShareAmount18uic0.type).toBe("text");
    expect(txtShareAmount18uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount18uic0, "123");
    });
    expect(txtShareAmount18uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount18uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount18uic0", () => {
    // START_USER_CODE-USER_txtShareAmount18uic0_TEST
    // END_USER_CODE-USER_txtShareAmount18uic0_TEST
  });
  test("txtShareAmount19suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount19suic0 = screen.getByTestId("txtShareAmount19suic0");
    expect(txtShareAmount19suic0.tagName).toBe("INPUT");
    expect(txtShareAmount19suic0.type).toBe("text");
    expect(txtShareAmount19suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount19suic0, "123");
    });
    expect(txtShareAmount19suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount19suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount19suic0", () => {
    // START_USER_CODE-USER_txtShareAmount19suic0_TEST
    // END_USER_CODE-USER_txtShareAmount19suic0_TEST
  });
  test("txtShareAmount19uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount19uic0 = screen.getByTestId("txtShareAmount19uic0");
    expect(txtShareAmount19uic0.tagName).toBe("INPUT");
    expect(txtShareAmount19uic0.type).toBe("text");
    expect(txtShareAmount19uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount19uic0, "123");
    });
    expect(txtShareAmount19uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount19uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount19uic0", () => {
    // START_USER_CODE-USER_txtShareAmount19uic0_TEST
    // END_USER_CODE-USER_txtShareAmount19uic0_TEST
  });
  test("txtShareAmount1suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount1suic0 = screen.getByTestId("txtShareAmount1suic0");
    expect(txtShareAmount1suic0.tagName).toBe("INPUT");
    expect(txtShareAmount1suic0.type).toBe("text");
    expect(txtShareAmount1suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount1suic0, "123");
    });
    expect(txtShareAmount1suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount1suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount1suic0", () => {
    // START_USER_CODE-USER_txtShareAmount1suic0_TEST
    // END_USER_CODE-USER_txtShareAmount1suic0_TEST
  });
  test("txtShareAmount1uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount1uic0 = screen.getByTestId("txtShareAmount1uic0");
    expect(txtShareAmount1uic0.tagName).toBe("INPUT");
    expect(txtShareAmount1uic0.type).toBe("text");
    expect(txtShareAmount1uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount1uic0, "123");
    });
    expect(txtShareAmount1uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount1uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount1uic0", () => {
    // START_USER_CODE-USER_txtShareAmount1uic0_TEST
    // END_USER_CODE-USER_txtShareAmount1uic0_TEST
  });
  test("txtShareAmount20suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount20suic0 = screen.getByTestId("txtShareAmount20suic0");
    expect(txtShareAmount20suic0.tagName).toBe("INPUT");
    expect(txtShareAmount20suic0.type).toBe("text");
    expect(txtShareAmount20suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount20suic0, "123");
    });
    expect(txtShareAmount20suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount20suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount20suic0", () => {
    // START_USER_CODE-USER_txtShareAmount20suic0_TEST
    // END_USER_CODE-USER_txtShareAmount20suic0_TEST
  });
  test("txtShareAmount20uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount20uic0 = screen.getByTestId("txtShareAmount20uic0");
    expect(txtShareAmount20uic0.tagName).toBe("INPUT");
    expect(txtShareAmount20uic0.type).toBe("text");
    expect(txtShareAmount20uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount20uic0, "123");
    });
    expect(txtShareAmount20uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount20uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount20uic0", () => {
    // START_USER_CODE-USER_txtShareAmount20uic0_TEST
    // END_USER_CODE-USER_txtShareAmount20uic0_TEST
  });
  test("txtShareAmount2suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount2suic0 = screen.getByTestId("txtShareAmount2suic0");
    expect(txtShareAmount2suic0.tagName).toBe("INPUT");
    expect(txtShareAmount2suic0.type).toBe("text");
    expect(txtShareAmount2suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount2suic0, "123");
    });
    expect(txtShareAmount2suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount2suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount2suic0", () => {
    // START_USER_CODE-USER_txtShareAmount2suic0_TEST
    // END_USER_CODE-USER_txtShareAmount2suic0_TEST
  });
  test("txtShareAmount2uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount2uic0 = screen.getByTestId("txtShareAmount2uic0");
    expect(txtShareAmount2uic0.tagName).toBe("INPUT");
    expect(txtShareAmount2uic0.type).toBe("text");
    expect(txtShareAmount2uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount2uic0, "123");
    });
    expect(txtShareAmount2uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount2uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount2uic0", () => {
    // START_USER_CODE-USER_txtShareAmount2uic0_TEST
    // END_USER_CODE-USER_txtShareAmount2uic0_TEST
  });
  test("txtShareAmount3suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount3suic0 = screen.getByTestId("txtShareAmount3suic0");
    expect(txtShareAmount3suic0.tagName).toBe("INPUT");
    expect(txtShareAmount3suic0.type).toBe("text");
    expect(txtShareAmount3suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount3suic0, "123");
    });
    expect(txtShareAmount3suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount3suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount3suic0", () => {
    // START_USER_CODE-USER_txtShareAmount3suic0_TEST
    // END_USER_CODE-USER_txtShareAmount3suic0_TEST
  });
  test("txtShareAmount3uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount3uic0 = screen.getByTestId("txtShareAmount3uic0");
    expect(txtShareAmount3uic0.tagName).toBe("INPUT");
    expect(txtShareAmount3uic0.type).toBe("text");
    expect(txtShareAmount3uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount3uic0, "123");
    });
    expect(txtShareAmount3uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount3uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount3uic0", () => {
    // START_USER_CODE-USER_txtShareAmount3uic0_TEST
    // END_USER_CODE-USER_txtShareAmount3uic0_TEST
  });
  test("txtShareAmount4suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount4suic0 = screen.getByTestId("txtShareAmount4suic0");
    expect(txtShareAmount4suic0.tagName).toBe("INPUT");
    expect(txtShareAmount4suic0.type).toBe("text");
    expect(txtShareAmount4suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount4suic0, "123");
    });
    expect(txtShareAmount4suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount4suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount4suic0", () => {
    // START_USER_CODE-USER_txtShareAmount4suic0_TEST
    // END_USER_CODE-USER_txtShareAmount4suic0_TEST
  });
  test("txtShareAmount4uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount4uic0 = screen.getByTestId("txtShareAmount4uic0");
    expect(txtShareAmount4uic0.tagName).toBe("INPUT");
    expect(txtShareAmount4uic0.type).toBe("text");
    expect(txtShareAmount4uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount4uic0, "123");
    });
    expect(txtShareAmount4uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount4uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount4uic0", () => {
    // START_USER_CODE-USER_txtShareAmount4uic0_TEST
    // END_USER_CODE-USER_txtShareAmount4uic0_TEST
  });
  test("txtShareAmount5suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount5suic0 = screen.getByTestId("txtShareAmount5suic0");
    expect(txtShareAmount5suic0.tagName).toBe("INPUT");
    expect(txtShareAmount5suic0.type).toBe("text");
    expect(txtShareAmount5suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount5suic0, "123");
    });
    expect(txtShareAmount5suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount5suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount5suic0", () => {
    // START_USER_CODE-USER_txtShareAmount5suic0_TEST
    // END_USER_CODE-USER_txtShareAmount5suic0_TEST
  });
  test("txtShareAmount5uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount5uic0 = screen.getByTestId("txtShareAmount5uic0");
    expect(txtShareAmount5uic0.tagName).toBe("INPUT");
    expect(txtShareAmount5uic0.type).toBe("text");
    expect(txtShareAmount5uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount5uic0, "123");
    });
    expect(txtShareAmount5uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount5uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount5uic0", () => {
    // START_USER_CODE-USER_txtShareAmount5uic0_TEST
    // END_USER_CODE-USER_txtShareAmount5uic0_TEST
  });
  test("txtShareAmount6suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount6suic0 = screen.getByTestId("txtShareAmount6suic0");
    expect(txtShareAmount6suic0.tagName).toBe("INPUT");
    expect(txtShareAmount6suic0.type).toBe("text");
    expect(txtShareAmount6suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount6suic0, "123");
    });
    expect(txtShareAmount6suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount6suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount6suic0", () => {
    // START_USER_CODE-USER_txtShareAmount6suic0_TEST
    // END_USER_CODE-USER_txtShareAmount6suic0_TEST
  });
  test("txtShareAmount6uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount6uic0 = screen.getByTestId("txtShareAmount6uic0");
    expect(txtShareAmount6uic0.tagName).toBe("INPUT");
    expect(txtShareAmount6uic0.type).toBe("text");
    expect(txtShareAmount6uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount6uic0, "123");
    });
    expect(txtShareAmount6uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount6uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount6uic0", () => {
    // START_USER_CODE-USER_txtShareAmount6uic0_TEST
    // END_USER_CODE-USER_txtShareAmount6uic0_TEST
  });
  test("txtShareAmount7suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount7suic0 = screen.getByTestId("txtShareAmount7suic0");
    expect(txtShareAmount7suic0.tagName).toBe("INPUT");
    expect(txtShareAmount7suic0.type).toBe("text");
    expect(txtShareAmount7suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount7suic0, "123");
    });
    expect(txtShareAmount7suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount7suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount7suic0", () => {
    // START_USER_CODE-USER_txtShareAmount7suic0_TEST
    // END_USER_CODE-USER_txtShareAmount7suic0_TEST
  });
  test("txtShareAmount7uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount7uic0 = screen.getByTestId("txtShareAmount7uic0");
    expect(txtShareAmount7uic0.tagName).toBe("INPUT");
    expect(txtShareAmount7uic0.type).toBe("text");
    expect(txtShareAmount7uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount7uic0, "123");
    });
    expect(txtShareAmount7uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount7uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount7uic0", () => {
    // START_USER_CODE-USER_txtShareAmount7uic0_TEST
    // END_USER_CODE-USER_txtShareAmount7uic0_TEST
  });
  test("txtShareAmount8suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount8suic0 = screen.getByTestId("txtShareAmount8suic0");
    expect(txtShareAmount8suic0.tagName).toBe("INPUT");
    expect(txtShareAmount8suic0.type).toBe("text");
    expect(txtShareAmount8suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount8suic0, "123");
    });
    expect(txtShareAmount8suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount8suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount8suic0", () => {
    // START_USER_CODE-USER_txtShareAmount8suic0_TEST
    // END_USER_CODE-USER_txtShareAmount8suic0_TEST
  });
  test("txtShareAmount8uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount8uic0 = screen.getByTestId("txtShareAmount8uic0");
    expect(txtShareAmount8uic0.tagName).toBe("INPUT");
    expect(txtShareAmount8uic0.type).toBe("text");
    expect(txtShareAmount8uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount8uic0, "123");
    });
    expect(txtShareAmount8uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount8uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount8uic0", () => {
    // START_USER_CODE-USER_txtShareAmount8uic0_TEST
    // END_USER_CODE-USER_txtShareAmount8uic0_TEST
  });
  test("txtShareAmount9suic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount9suic0 = screen.getByTestId("txtShareAmount9suic0");
    expect(txtShareAmount9suic0.tagName).toBe("INPUT");
    expect(txtShareAmount9suic0.type).toBe("text");
    expect(txtShareAmount9suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount9suic0, "123");
    });
    expect(txtShareAmount9suic0.getAttribute("value")).toBe("");
    expect(txtShareAmount9suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount9suic0", () => {
    // START_USER_CODE-USER_txtShareAmount9suic0_TEST
    // END_USER_CODE-USER_txtShareAmount9suic0_TEST
  });
  test("txtShareAmount9uic0(Textbox Widget) Test Cases", async () => {
    const txtShareAmount9uic0 = screen.getByTestId("txtShareAmount9uic0");
    expect(txtShareAmount9uic0.tagName).toBe("INPUT");
    expect(txtShareAmount9uic0.type).toBe("text");
    expect(txtShareAmount9uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount9uic0, "123");
    });
    expect(txtShareAmount9uic0.getAttribute("value")).toBe("");
    expect(txtShareAmount9uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount9uic0", () => {
    // START_USER_CODE-USER_txtShareAmount9uic0_TEST
    // END_USER_CODE-USER_txtShareAmount9uic0_TEST
  });
  test("txtSharePct10suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct10suic0 = screen.getByTestId("txtSharePct10suic0");
    expect(txtSharePct10suic0.tagName).toBe("INPUT");
    expect(txtSharePct10suic0.type).toBe("text");
    expect(txtSharePct10suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct10suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct10suic0", () => {
    // START_USER_CODE-USER_txtSharePct10suic0_TEST
    // END_USER_CODE-USER_txtSharePct10suic0_TEST
  });
  test("txtSharePct10uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct10uic0 = screen.getByTestId("txtSharePct10uic0");
    expect(txtSharePct10uic0.tagName).toBe("INPUT");
    expect(txtSharePct10uic0.type).toBe("text");
    expect(txtSharePct10uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct10uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct10uic0", () => {
    // START_USER_CODE-USER_txtSharePct10uic0_TEST
    // END_USER_CODE-USER_txtSharePct10uic0_TEST
  });
  test("txtSharePct11suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct11suic0 = screen.getByTestId("txtSharePct11suic0");
    expect(txtSharePct11suic0.tagName).toBe("INPUT");
    expect(txtSharePct11suic0.type).toBe("text");
    expect(txtSharePct11suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct11suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct11suic0", () => {
    // START_USER_CODE-USER_txtSharePct11suic0_TEST
    // END_USER_CODE-USER_txtSharePct11suic0_TEST
  });
  test("txtSharePct11uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct11uic0 = screen.getByTestId("txtSharePct11uic0");
    expect(txtSharePct11uic0.tagName).toBe("INPUT");
    expect(txtSharePct11uic0.type).toBe("text");
    expect(txtSharePct11uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct11uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct11uic0", () => {
    // START_USER_CODE-USER_txtSharePct11uic0_TEST
    // END_USER_CODE-USER_txtSharePct11uic0_TEST
  });
  test("txtSharePct12suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct12suic0 = screen.getByTestId("txtSharePct12suic0");
    expect(txtSharePct12suic0.tagName).toBe("INPUT");
    expect(txtSharePct12suic0.type).toBe("text");
    expect(txtSharePct12suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct12suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct12suic0", () => {
    // START_USER_CODE-USER_txtSharePct12suic0_TEST
    // END_USER_CODE-USER_txtSharePct12suic0_TEST
  });
  test("txtSharePct12uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct12uic0 = screen.getByTestId("txtSharePct12uic0");
    expect(txtSharePct12uic0.tagName).toBe("INPUT");
    expect(txtSharePct12uic0.type).toBe("text");
    expect(txtSharePct12uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct12uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct12uic0", () => {
    // START_USER_CODE-USER_txtSharePct12uic0_TEST
    // END_USER_CODE-USER_txtSharePct12uic0_TEST
  });
  test("txtSharePct13suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct13suic0 = screen.getByTestId("txtSharePct13suic0");
    expect(txtSharePct13suic0.tagName).toBe("INPUT");
    expect(txtSharePct13suic0.type).toBe("text");
    expect(txtSharePct13suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct13suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct13suic0", () => {
    // START_USER_CODE-USER_txtSharePct13suic0_TEST
    // END_USER_CODE-USER_txtSharePct13suic0_TEST
  });
  test("txtSharePct13uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct13uic0 = screen.getByTestId("txtSharePct13uic0");
    expect(txtSharePct13uic0.tagName).toBe("INPUT");
    expect(txtSharePct13uic0.type).toBe("text");
    expect(txtSharePct13uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct13uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct13uic0", () => {
    // START_USER_CODE-USER_txtSharePct13uic0_TEST
    // END_USER_CODE-USER_txtSharePct13uic0_TEST
  });
  test("txtSharePct14suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct14suic0 = screen.getByTestId("txtSharePct14suic0");
    expect(txtSharePct14suic0.tagName).toBe("INPUT");
    expect(txtSharePct14suic0.type).toBe("text");
    expect(txtSharePct14suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct14suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct14suic0", () => {
    // START_USER_CODE-USER_txtSharePct14suic0_TEST
    // END_USER_CODE-USER_txtSharePct14suic0_TEST
  });
  test("txtSharePct14uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct14uic0 = screen.getByTestId("txtSharePct14uic0");
    expect(txtSharePct14uic0.tagName).toBe("INPUT");
    expect(txtSharePct14uic0.type).toBe("text");
    expect(txtSharePct14uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct14uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct14uic0", () => {
    // START_USER_CODE-USER_txtSharePct14uic0_TEST
    // END_USER_CODE-USER_txtSharePct14uic0_TEST
  });
  test("txtSharePct15suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct15suic0 = screen.getByTestId("txtSharePct15suic0");
    expect(txtSharePct15suic0.tagName).toBe("INPUT");
    expect(txtSharePct15suic0.type).toBe("text");
    expect(txtSharePct15suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct15suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct15suic0", () => {
    // START_USER_CODE-USER_txtSharePct15suic0_TEST
    // END_USER_CODE-USER_txtSharePct15suic0_TEST
  });
  test("txtSharePct15uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct15uic0 = screen.getByTestId("txtSharePct15uic0");
    expect(txtSharePct15uic0.tagName).toBe("INPUT");
    expect(txtSharePct15uic0.type).toBe("text");
    expect(txtSharePct15uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct15uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct15uic0", () => {
    // START_USER_CODE-USER_txtSharePct15uic0_TEST
    // END_USER_CODE-USER_txtSharePct15uic0_TEST
  });
  test("txtSharePct16suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct16suic0 = screen.getByTestId("txtSharePct16suic0");
    expect(txtSharePct16suic0.tagName).toBe("INPUT");
    expect(txtSharePct16suic0.type).toBe("text");
    expect(txtSharePct16suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct16suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct16suic0", () => {
    // START_USER_CODE-USER_txtSharePct16suic0_TEST
    // END_USER_CODE-USER_txtSharePct16suic0_TEST
  });
  test("txtSharePct16uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct16uic0 = screen.getByTestId("txtSharePct16uic0");
    expect(txtSharePct16uic0.tagName).toBe("INPUT");
    expect(txtSharePct16uic0.type).toBe("text");
    expect(txtSharePct16uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct16uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct16uic0", () => {
    // START_USER_CODE-USER_txtSharePct16uic0_TEST
    // END_USER_CODE-USER_txtSharePct16uic0_TEST
  });
  test("txtSharePct17suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct17suic0 = screen.getByTestId("txtSharePct17suic0");
    expect(txtSharePct17suic0.tagName).toBe("INPUT");
    expect(txtSharePct17suic0.type).toBe("text");
    expect(txtSharePct17suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct17suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct17suic0", () => {
    // START_USER_CODE-USER_txtSharePct17suic0_TEST
    // END_USER_CODE-USER_txtSharePct17suic0_TEST
  });
  test("txtSharePct17uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct17uic0 = screen.getByTestId("txtSharePct17uic0");
    expect(txtSharePct17uic0.tagName).toBe("INPUT");
    expect(txtSharePct17uic0.type).toBe("text");
    expect(txtSharePct17uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct17uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct17uic0", () => {
    // START_USER_CODE-USER_txtSharePct17uic0_TEST
    // END_USER_CODE-USER_txtSharePct17uic0_TEST
  });
  test("txtSharePct18suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct18suic0 = screen.getByTestId("txtSharePct18suic0");
    expect(txtSharePct18suic0.tagName).toBe("INPUT");
    expect(txtSharePct18suic0.type).toBe("text");
    expect(txtSharePct18suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct18suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct18suic0", () => {
    // START_USER_CODE-USER_txtSharePct18suic0_TEST
    // END_USER_CODE-USER_txtSharePct18suic0_TEST
  });
  test("txtSharePct18uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct18uic0 = screen.getByTestId("txtSharePct18uic0");
    expect(txtSharePct18uic0.tagName).toBe("INPUT");
    expect(txtSharePct18uic0.type).toBe("text");
    expect(txtSharePct18uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct18uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct18uic0", () => {
    // START_USER_CODE-USER_txtSharePct18uic0_TEST
    // END_USER_CODE-USER_txtSharePct18uic0_TEST
  });
  test("txtSharePct19suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct19suic0 = screen.getByTestId("txtSharePct19suic0");
    expect(txtSharePct19suic0.tagName).toBe("INPUT");
    expect(txtSharePct19suic0.type).toBe("text");
    expect(txtSharePct19suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct19suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct19suic0", () => {
    // START_USER_CODE-USER_txtSharePct19suic0_TEST
    // END_USER_CODE-USER_txtSharePct19suic0_TEST
  });
  test("txtSharePct19uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct19uic0 = screen.getByTestId("txtSharePct19uic0");
    expect(txtSharePct19uic0.tagName).toBe("INPUT");
    expect(txtSharePct19uic0.type).toBe("text");
    expect(txtSharePct19uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct19uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct19uic0", () => {
    // START_USER_CODE-USER_txtSharePct19uic0_TEST
    // END_USER_CODE-USER_txtSharePct19uic0_TEST
  });
  test("txtSharePct1suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct1suic0 = screen.getByTestId("txtSharePct1suic0");
    expect(txtSharePct1suic0.tagName).toBe("INPUT");
    expect(txtSharePct1suic0.type).toBe("text");
    expect(txtSharePct1suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct1suic0", () => {
    // START_USER_CODE-USER_txtSharePct1suic0_TEST
    // END_USER_CODE-USER_txtSharePct1suic0_TEST
  });
  test("txtSharePct1uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct1uic0 = screen.getByTestId("txtSharePct1uic0");
    expect(txtSharePct1uic0.tagName).toBe("INPUT");
    expect(txtSharePct1uic0.type).toBe("text");
    expect(txtSharePct1uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct1uic0", () => {
    // START_USER_CODE-USER_txtSharePct1uic0_TEST
    // END_USER_CODE-USER_txtSharePct1uic0_TEST
  });
  test("txtSharePct20suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct20suic0 = screen.getByTestId("txtSharePct20suic0");
    expect(txtSharePct20suic0.tagName).toBe("INPUT");
    expect(txtSharePct20suic0.type).toBe("text");
    expect(txtSharePct20suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct20suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct20suic0", () => {
    // START_USER_CODE-USER_txtSharePct20suic0_TEST
    // END_USER_CODE-USER_txtSharePct20suic0_TEST
  });
  test("txtSharePct20uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct20uic0 = screen.getByTestId("txtSharePct20uic0");
    expect(txtSharePct20uic0.tagName).toBe("INPUT");
    expect(txtSharePct20uic0.type).toBe("text");
    expect(txtSharePct20uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct20uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct20uic0", () => {
    // START_USER_CODE-USER_txtSharePct20uic0_TEST
    // END_USER_CODE-USER_txtSharePct20uic0_TEST
  });
  test("txtSharePct2suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct2suic0 = screen.getByTestId("txtSharePct2suic0");
    expect(txtSharePct2suic0.tagName).toBe("INPUT");
    expect(txtSharePct2suic0.type).toBe("text");
    expect(txtSharePct2suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct2suic0", () => {
    // START_USER_CODE-USER_txtSharePct2suic0_TEST
    // END_USER_CODE-USER_txtSharePct2suic0_TEST
  });
  test("txtSharePct2uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct2uic0 = screen.getByTestId("txtSharePct2uic0");
    expect(txtSharePct2uic0.tagName).toBe("INPUT");
    expect(txtSharePct2uic0.type).toBe("text");
    expect(txtSharePct2uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct2uic0", () => {
    // START_USER_CODE-USER_txtSharePct2uic0_TEST
    // END_USER_CODE-USER_txtSharePct2uic0_TEST
  });
  test("txtSharePct3suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct3suic0 = screen.getByTestId("txtSharePct3suic0");
    expect(txtSharePct3suic0.tagName).toBe("INPUT");
    expect(txtSharePct3suic0.type).toBe("text");
    expect(txtSharePct3suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct3suic0", () => {
    // START_USER_CODE-USER_txtSharePct3suic0_TEST
    // END_USER_CODE-USER_txtSharePct3suic0_TEST
  });
  test("txtSharePct3uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct3uic0 = screen.getByTestId("txtSharePct3uic0");
    expect(txtSharePct3uic0.tagName).toBe("INPUT");
    expect(txtSharePct3uic0.type).toBe("text");
    expect(txtSharePct3uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct3uic0", () => {
    // START_USER_CODE-USER_txtSharePct3uic0_TEST
    // END_USER_CODE-USER_txtSharePct3uic0_TEST
  });
  test("txtSharePct4suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct4suic0 = screen.getByTestId("txtSharePct4suic0");
    expect(txtSharePct4suic0.tagName).toBe("INPUT");
    expect(txtSharePct4suic0.type).toBe("text");
    expect(txtSharePct4suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct4suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct4suic0", () => {
    // START_USER_CODE-USER_txtSharePct4suic0_TEST
    // END_USER_CODE-USER_txtSharePct4suic0_TEST
  });
  test("txtSharePct4uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct4uic0 = screen.getByTestId("txtSharePct4uic0");
    expect(txtSharePct4uic0.tagName).toBe("INPUT");
    expect(txtSharePct4uic0.type).toBe("text");
    expect(txtSharePct4uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct4uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct4uic0", () => {
    // START_USER_CODE-USER_txtSharePct4uic0_TEST
    // END_USER_CODE-USER_txtSharePct4uic0_TEST
  });
  test("txtSharePct5suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct5suic0 = screen.getByTestId("txtSharePct5suic0");
    expect(txtSharePct5suic0.tagName).toBe("INPUT");
    expect(txtSharePct5suic0.type).toBe("text");
    expect(txtSharePct5suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct5suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct5suic0", () => {
    // START_USER_CODE-USER_txtSharePct5suic0_TEST
    // END_USER_CODE-USER_txtSharePct5suic0_TEST
  });
  test("txtSharePct5uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct5uic0 = screen.getByTestId("txtSharePct5uic0");
    expect(txtSharePct5uic0.tagName).toBe("INPUT");
    expect(txtSharePct5uic0.type).toBe("text");
    expect(txtSharePct5uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct5uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct5uic0", () => {
    // START_USER_CODE-USER_txtSharePct5uic0_TEST
    // END_USER_CODE-USER_txtSharePct5uic0_TEST
  });
  test("txtSharePct6suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct6suic0 = screen.getByTestId("txtSharePct6suic0");
    expect(txtSharePct6suic0.tagName).toBe("INPUT");
    expect(txtSharePct6suic0.type).toBe("text");
    expect(txtSharePct6suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct6suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct6suic0", () => {
    // START_USER_CODE-USER_txtSharePct6suic0_TEST
    // END_USER_CODE-USER_txtSharePct6suic0_TEST
  });
  test("txtSharePct6uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct6uic0 = screen.getByTestId("txtSharePct6uic0");
    expect(txtSharePct6uic0.tagName).toBe("INPUT");
    expect(txtSharePct6uic0.type).toBe("text");
    expect(txtSharePct6uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct6uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct6uic0", () => {
    // START_USER_CODE-USER_txtSharePct6uic0_TEST
    // END_USER_CODE-USER_txtSharePct6uic0_TEST
  });
  test("txtSharePct7suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct7suic0 = screen.getByTestId("txtSharePct7suic0");
    expect(txtSharePct7suic0.tagName).toBe("INPUT");
    expect(txtSharePct7suic0.type).toBe("text");
    expect(txtSharePct7suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct7suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct7suic0", () => {
    // START_USER_CODE-USER_txtSharePct7suic0_TEST
    // END_USER_CODE-USER_txtSharePct7suic0_TEST
  });
  test("txtSharePct7uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct7uic0 = screen.getByTestId("txtSharePct7uic0");
    expect(txtSharePct7uic0.tagName).toBe("INPUT");
    expect(txtSharePct7uic0.type).toBe("text");
    expect(txtSharePct7uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct7uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct7uic0", () => {
    // START_USER_CODE-USER_txtSharePct7uic0_TEST
    // END_USER_CODE-USER_txtSharePct7uic0_TEST
  });
  test("txtSharePct8suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct8suic0 = screen.getByTestId("txtSharePct8suic0");
    expect(txtSharePct8suic0.tagName).toBe("INPUT");
    expect(txtSharePct8suic0.type).toBe("text");
    expect(txtSharePct8suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct8suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct8suic0", () => {
    // START_USER_CODE-USER_txtSharePct8suic0_TEST
    // END_USER_CODE-USER_txtSharePct8suic0_TEST
  });
  test("txtSharePct8uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct8uic0 = screen.getByTestId("txtSharePct8uic0");
    expect(txtSharePct8uic0.tagName).toBe("INPUT");
    expect(txtSharePct8uic0.type).toBe("text");
    expect(txtSharePct8uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct8uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct8uic0", () => {
    // START_USER_CODE-USER_txtSharePct8uic0_TEST
    // END_USER_CODE-USER_txtSharePct8uic0_TEST
  });
  test("txtSharePct9suic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct9suic0 = screen.getByTestId("txtSharePct9suic0");
    expect(txtSharePct9suic0.tagName).toBe("INPUT");
    expect(txtSharePct9suic0.type).toBe("text");
    expect(txtSharePct9suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct9suic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct9suic0", () => {
    // START_USER_CODE-USER_txtSharePct9suic0_TEST
    // END_USER_CODE-USER_txtSharePct9suic0_TEST
  });
  test("txtSharePct9uic0(Textbox Widget) Test Cases", async () => {
    const txtSharePct9uic0 = screen.getByTestId("txtSharePct9uic0");
    expect(txtSharePct9uic0.tagName).toBe("INPUT");
    expect(txtSharePct9uic0.type).toBe("text");
    expect(txtSharePct9uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct9uic0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct9uic0", () => {
    // START_USER_CODE-USER_txtSharePct9uic0_TEST
    // END_USER_CODE-USER_txtSharePct9uic0_TEST
  });
  test("txtTotalShareAmountsuic0(Textbox Widget) Test Cases", async () => {
    const txtTotalShareAmountsuic0 = screen.getByTestId(
      "txtTotalShareAmountsuic0"
    );
    expect(txtTotalShareAmountsuic0.tagName).toBe("INPUT");
    expect(txtTotalShareAmountsuic0.type).toBe("text");
    expect(txtTotalShareAmountsuic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalShareAmountsuic0, "123");
    });
    expect(txtTotalShareAmountsuic0.getAttribute("value")).toBe("");
    expect(txtTotalShareAmountsuic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalShareAmountsuic0", () => {
    // START_USER_CODE-USER_txtTotalShareAmountsuic0_TEST
    // END_USER_CODE-USER_txtTotalShareAmountsuic0_TEST
  });
  test("txtTotalShareAmountuic0(Textbox Widget) Test Cases", async () => {
    const txtTotalShareAmountuic0 = screen.getByTestId(
      "txtTotalShareAmountuic0"
    );
    expect(txtTotalShareAmountuic0.tagName).toBe("INPUT");
    expect(txtTotalShareAmountuic0.type).toBe("text");
    expect(txtTotalShareAmountuic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalShareAmountuic0, "123");
    });
    expect(txtTotalShareAmountuic0.getAttribute("value")).toBe("");
    expect(txtTotalShareAmountuic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalShareAmountuic0", () => {
    // START_USER_CODE-USER_txtTotalShareAmountuic0_TEST
    // END_USER_CODE-USER_txtTotalShareAmountuic0_TEST
  });
  test("txtTotalSharePctsuic0(Textbox Widget) Test Cases", async () => {
    const txtTotalSharePctsuic0 = screen.getByTestId("txtTotalSharePctsuic0");
    expect(txtTotalSharePctsuic0.tagName).toBe("INPUT");
    expect(txtTotalSharePctsuic0.type).toBe("text");
    expect(txtTotalSharePctsuic0.classList).toContain("textboxWidgetClass");
    expect(txtTotalSharePctsuic0.previousElementSibling.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_txtTotalSharePctsuic0")
    );
    await act(async () => {
      userEvent.type(txtTotalSharePctsuic0, "123");
    });
    expect(txtTotalSharePctsuic0.getAttribute("value")).toBe("");
    expect(txtTotalSharePctsuic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSharePctsuic0", () => {
    // START_USER_CODE-USER_txtTotalSharePctsuic0_TEST
    // END_USER_CODE-USER_txtTotalSharePctsuic0_TEST
  });
  test("txtTotalSharePctuic0(Textbox Widget) Test Cases", async () => {
    const txtTotalSharePctuic0 = screen.getByTestId("txtTotalSharePctuic0");
    expect(txtTotalSharePctuic0.tagName).toBe("INPUT");
    expect(txtTotalSharePctuic0.type).toBe("text");
    expect(txtTotalSharePctuic0.classList).toContain("textboxWidgetClass");
    expect(txtTotalSharePctuic0.previousElementSibling.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplitsTrade_txtTotalSharePctuic0")
    );
    await act(async () => {
      userEvent.type(txtTotalSharePctuic0, "123");
    });
    expect(txtTotalSharePctuic0.getAttribute("value")).toBe("");
    expect(txtTotalSharePctuic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSharePctuic0", () => {
    // START_USER_CODE-USER_txtTotalSharePctuic0_TEST
    // END_USER_CODE-USER_txtTotalSharePctuic0_TEST
  });
  test("txtVendor10suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor10suic0 = screen.getByTestId("txtVendor10suic0");
    expect(txtVendor10suic0.tagName).toBe("INPUT");
    expect(txtVendor10suic0.type).toBe("text");
    expect(txtVendor10suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor10suic0, "123");
    });
    expect(txtVendor10suic0.getAttribute("value")).toBe("");
    expect(txtVendor10suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor10suic0", () => {
    // START_USER_CODE-USER_txtVendor10suic0_TEST
    // END_USER_CODE-USER_txtVendor10suic0_TEST
  });
  test("txtVendor10uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor10uic0 = screen.getByTestId("txtVendor10uic0");
    expect(txtVendor10uic0.tagName).toBe("INPUT");
    expect(txtVendor10uic0.type).toBe("text");
    expect(txtVendor10uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor10uic0, "123");
    });
    expect(txtVendor10uic0.getAttribute("value")).toBe("");
    expect(txtVendor10uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor10uic0", () => {
    // START_USER_CODE-USER_txtVendor10uic0_TEST
    // END_USER_CODE-USER_txtVendor10uic0_TEST
  });
  test("txtVendor11suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor11suic0 = screen.getByTestId("txtVendor11suic0");
    expect(txtVendor11suic0.tagName).toBe("INPUT");
    expect(txtVendor11suic0.type).toBe("text");
    expect(txtVendor11suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor11suic0, "123");
    });
    expect(txtVendor11suic0.getAttribute("value")).toBe("");
    expect(txtVendor11suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor11suic0", () => {
    // START_USER_CODE-USER_txtVendor11suic0_TEST
    // END_USER_CODE-USER_txtVendor11suic0_TEST
  });
  test("txtVendor11uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor11uic0 = screen.getByTestId("txtVendor11uic0");
    expect(txtVendor11uic0.tagName).toBe("INPUT");
    expect(txtVendor11uic0.type).toBe("text");
    expect(txtVendor11uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor11uic0, "123");
    });
    expect(txtVendor11uic0.getAttribute("value")).toBe("");
    expect(txtVendor11uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor11uic0", () => {
    // START_USER_CODE-USER_txtVendor11uic0_TEST
    // END_USER_CODE-USER_txtVendor11uic0_TEST
  });
  test("txtVendor12suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor12suic0 = screen.getByTestId("txtVendor12suic0");
    expect(txtVendor12suic0.tagName).toBe("INPUT");
    expect(txtVendor12suic0.type).toBe("text");
    expect(txtVendor12suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor12suic0, "123");
    });
    expect(txtVendor12suic0.getAttribute("value")).toBe("");
    expect(txtVendor12suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor12suic0", () => {
    // START_USER_CODE-USER_txtVendor12suic0_TEST
    // END_USER_CODE-USER_txtVendor12suic0_TEST
  });
  test("txtVendor12uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor12uic0 = screen.getByTestId("txtVendor12uic0");
    expect(txtVendor12uic0.tagName).toBe("INPUT");
    expect(txtVendor12uic0.type).toBe("text");
    expect(txtVendor12uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor12uic0, "123");
    });
    expect(txtVendor12uic0.getAttribute("value")).toBe("");
    expect(txtVendor12uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor12uic0", () => {
    // START_USER_CODE-USER_txtVendor12uic0_TEST
    // END_USER_CODE-USER_txtVendor12uic0_TEST
  });
  test("txtVendor13suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor13suic0 = screen.getByTestId("txtVendor13suic0");
    expect(txtVendor13suic0.tagName).toBe("INPUT");
    expect(txtVendor13suic0.type).toBe("text");
    expect(txtVendor13suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor13suic0, "123");
    });
    expect(txtVendor13suic0.getAttribute("value")).toBe("");
    expect(txtVendor13suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor13suic0", () => {
    // START_USER_CODE-USER_txtVendor13suic0_TEST
    // END_USER_CODE-USER_txtVendor13suic0_TEST
  });
  test("txtVendor13uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor13uic0 = screen.getByTestId("txtVendor13uic0");
    expect(txtVendor13uic0.tagName).toBe("INPUT");
    expect(txtVendor13uic0.type).toBe("text");
    expect(txtVendor13uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor13uic0, "123");
    });
    expect(txtVendor13uic0.getAttribute("value")).toBe("");
    expect(txtVendor13uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor13uic0", () => {
    // START_USER_CODE-USER_txtVendor13uic0_TEST
    // END_USER_CODE-USER_txtVendor13uic0_TEST
  });
  test("txtVendor14suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor14suic0 = screen.getByTestId("txtVendor14suic0");
    expect(txtVendor14suic0.tagName).toBe("INPUT");
    expect(txtVendor14suic0.type).toBe("text");
    expect(txtVendor14suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor14suic0, "123");
    });
    expect(txtVendor14suic0.getAttribute("value")).toBe("");
    expect(txtVendor14suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor14suic0", () => {
    // START_USER_CODE-USER_txtVendor14suic0_TEST
    // END_USER_CODE-USER_txtVendor14suic0_TEST
  });
  test("txtVendor14uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor14uic0 = screen.getByTestId("txtVendor14uic0");
    expect(txtVendor14uic0.tagName).toBe("INPUT");
    expect(txtVendor14uic0.type).toBe("text");
    expect(txtVendor14uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor14uic0, "123");
    });
    expect(txtVendor14uic0.getAttribute("value")).toBe("");
    expect(txtVendor14uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor14uic0", () => {
    // START_USER_CODE-USER_txtVendor14uic0_TEST
    // END_USER_CODE-USER_txtVendor14uic0_TEST
  });
  test("txtVendor15suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor15suic0 = screen.getByTestId("txtVendor15suic0");
    expect(txtVendor15suic0.tagName).toBe("INPUT");
    expect(txtVendor15suic0.type).toBe("text");
    expect(txtVendor15suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor15suic0, "123");
    });
    expect(txtVendor15suic0.getAttribute("value")).toBe("");
    expect(txtVendor15suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor15suic0", () => {
    // START_USER_CODE-USER_txtVendor15suic0_TEST
    // END_USER_CODE-USER_txtVendor15suic0_TEST
  });
  test("txtVendor15uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor15uic0 = screen.getByTestId("txtVendor15uic0");
    expect(txtVendor15uic0.tagName).toBe("INPUT");
    expect(txtVendor15uic0.type).toBe("text");
    expect(txtVendor15uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor15uic0, "123");
    });
    expect(txtVendor15uic0.getAttribute("value")).toBe("");
    expect(txtVendor15uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor15uic0", () => {
    // START_USER_CODE-USER_txtVendor15uic0_TEST
    // END_USER_CODE-USER_txtVendor15uic0_TEST
  });
  test("txtVendor16suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor16suic0 = screen.getByTestId("txtVendor16suic0");
    expect(txtVendor16suic0.tagName).toBe("INPUT");
    expect(txtVendor16suic0.type).toBe("text");
    expect(txtVendor16suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor16suic0, "123");
    });
    expect(txtVendor16suic0.getAttribute("value")).toBe("");
    expect(txtVendor16suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor16suic0", () => {
    // START_USER_CODE-USER_txtVendor16suic0_TEST
    // END_USER_CODE-USER_txtVendor16suic0_TEST
  });
  test("txtVendor16uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor16uic0 = screen.getByTestId("txtVendor16uic0");
    expect(txtVendor16uic0.tagName).toBe("INPUT");
    expect(txtVendor16uic0.type).toBe("text");
    expect(txtVendor16uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor16uic0, "123");
    });
    expect(txtVendor16uic0.getAttribute("value")).toBe("");
    expect(txtVendor16uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor16uic0", () => {
    // START_USER_CODE-USER_txtVendor16uic0_TEST
    // END_USER_CODE-USER_txtVendor16uic0_TEST
  });
  test("txtVendor17suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor17suic0 = screen.getByTestId("txtVendor17suic0");
    expect(txtVendor17suic0.tagName).toBe("INPUT");
    expect(txtVendor17suic0.type).toBe("text");
    expect(txtVendor17suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor17suic0, "123");
    });
    expect(txtVendor17suic0.getAttribute("value")).toBe("");
    expect(txtVendor17suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor17suic0", () => {
    // START_USER_CODE-USER_txtVendor17suic0_TEST
    // END_USER_CODE-USER_txtVendor17suic0_TEST
  });
  test("txtVendor17uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor17uic0 = screen.getByTestId("txtVendor17uic0");
    expect(txtVendor17uic0.tagName).toBe("INPUT");
    expect(txtVendor17uic0.type).toBe("text");
    expect(txtVendor17uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor17uic0, "123");
    });
    expect(txtVendor17uic0.getAttribute("value")).toBe("");
    expect(txtVendor17uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor17uic0", () => {
    // START_USER_CODE-USER_txtVendor17uic0_TEST
    // END_USER_CODE-USER_txtVendor17uic0_TEST
  });
  test("txtVendor18suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor18suic0 = screen.getByTestId("txtVendor18suic0");
    expect(txtVendor18suic0.tagName).toBe("INPUT");
    expect(txtVendor18suic0.type).toBe("text");
    expect(txtVendor18suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor18suic0, "123");
    });
    expect(txtVendor18suic0.getAttribute("value")).toBe("");
    expect(txtVendor18suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor18suic0", () => {
    // START_USER_CODE-USER_txtVendor18suic0_TEST
    // END_USER_CODE-USER_txtVendor18suic0_TEST
  });
  test("txtVendor18uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor18uic0 = screen.getByTestId("txtVendor18uic0");
    expect(txtVendor18uic0.tagName).toBe("INPUT");
    expect(txtVendor18uic0.type).toBe("text");
    expect(txtVendor18uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor18uic0, "123");
    });
    expect(txtVendor18uic0.getAttribute("value")).toBe("");
    expect(txtVendor18uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor18uic0", () => {
    // START_USER_CODE-USER_txtVendor18uic0_TEST
    // END_USER_CODE-USER_txtVendor18uic0_TEST
  });
  test("txtVendor19suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor19suic0 = screen.getByTestId("txtVendor19suic0");
    expect(txtVendor19suic0.tagName).toBe("INPUT");
    expect(txtVendor19suic0.type).toBe("text");
    expect(txtVendor19suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor19suic0, "123");
    });
    expect(txtVendor19suic0.getAttribute("value")).toBe("");
    expect(txtVendor19suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor19suic0", () => {
    // START_USER_CODE-USER_txtVendor19suic0_TEST
    // END_USER_CODE-USER_txtVendor19suic0_TEST
  });
  test("txtVendor19uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor19uic0 = screen.getByTestId("txtVendor19uic0");
    expect(txtVendor19uic0.tagName).toBe("INPUT");
    expect(txtVendor19uic0.type).toBe("text");
    expect(txtVendor19uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor19uic0, "123");
    });
    expect(txtVendor19uic0.getAttribute("value")).toBe("");
    expect(txtVendor19uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor19uic0", () => {
    // START_USER_CODE-USER_txtVendor19uic0_TEST
    // END_USER_CODE-USER_txtVendor19uic0_TEST
  });
  test("txtVendor1suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor1suic0 = screen.getByTestId("txtVendor1suic0");
    expect(txtVendor1suic0.tagName).toBe("INPUT");
    expect(txtVendor1suic0.type).toBe("text");
    expect(txtVendor1suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1suic0, "123");
    });
    expect(txtVendor1suic0.getAttribute("value")).toBe("");
    expect(txtVendor1suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor1suic0", () => {
    // START_USER_CODE-USER_txtVendor1suic0_TEST
    // END_USER_CODE-USER_txtVendor1suic0_TEST
  });
  test("txtVendor1uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor1uic0 = screen.getByTestId("txtVendor1uic0");
    expect(txtVendor1uic0.tagName).toBe("INPUT");
    expect(txtVendor1uic0.type).toBe("text");
    expect(txtVendor1uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1uic0, "123");
    });
    expect(txtVendor1uic0.getAttribute("value")).toBe("");
    expect(txtVendor1uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor1uic0", () => {
    // START_USER_CODE-USER_txtVendor1uic0_TEST
    // END_USER_CODE-USER_txtVendor1uic0_TEST
  });
  test("txtVendor20suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor20suic0 = screen.getByTestId("txtVendor20suic0");
    expect(txtVendor20suic0.tagName).toBe("INPUT");
    expect(txtVendor20suic0.type).toBe("text");
    expect(txtVendor20suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor20suic0, "123");
    });
    expect(txtVendor20suic0.getAttribute("value")).toBe("");
    expect(txtVendor20suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor20suic0", () => {
    // START_USER_CODE-USER_txtVendor20suic0_TEST
    // END_USER_CODE-USER_txtVendor20suic0_TEST
  });
  test("txtVendor20uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor20uic0 = screen.getByTestId("txtVendor20uic0");
    expect(txtVendor20uic0.tagName).toBe("INPUT");
    expect(txtVendor20uic0.type).toBe("text");
    expect(txtVendor20uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor20uic0, "123");
    });
    expect(txtVendor20uic0.getAttribute("value")).toBe("");
    expect(txtVendor20uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor20uic0", () => {
    // START_USER_CODE-USER_txtVendor20uic0_TEST
    // END_USER_CODE-USER_txtVendor20uic0_TEST
  });
  test("txtVendor2suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor2suic0 = screen.getByTestId("txtVendor2suic0");
    expect(txtVendor2suic0.tagName).toBe("INPUT");
    expect(txtVendor2suic0.type).toBe("text");
    expect(txtVendor2suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2suic0, "123");
    });
    expect(txtVendor2suic0.getAttribute("value")).toBe("");
    expect(txtVendor2suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor2suic0", () => {
    // START_USER_CODE-USER_txtVendor2suic0_TEST
    // END_USER_CODE-USER_txtVendor2suic0_TEST
  });
  test("txtVendor2uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor2uic0 = screen.getByTestId("txtVendor2uic0");
    expect(txtVendor2uic0.tagName).toBe("INPUT");
    expect(txtVendor2uic0.type).toBe("text");
    expect(txtVendor2uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2uic0, "123");
    });
    expect(txtVendor2uic0.getAttribute("value")).toBe("");
    expect(txtVendor2uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor2uic0", () => {
    // START_USER_CODE-USER_txtVendor2uic0_TEST
    // END_USER_CODE-USER_txtVendor2uic0_TEST
  });
  test("txtVendor3suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor3suic0 = screen.getByTestId("txtVendor3suic0");
    expect(txtVendor3suic0.tagName).toBe("INPUT");
    expect(txtVendor3suic0.type).toBe("text");
    expect(txtVendor3suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3suic0, "123");
    });
    expect(txtVendor3suic0.getAttribute("value")).toBe("");
    expect(txtVendor3suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor3suic0", () => {
    // START_USER_CODE-USER_txtVendor3suic0_TEST
    // END_USER_CODE-USER_txtVendor3suic0_TEST
  });
  test("txtVendor3uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor3uic0 = screen.getByTestId("txtVendor3uic0");
    expect(txtVendor3uic0.tagName).toBe("INPUT");
    expect(txtVendor3uic0.type).toBe("text");
    expect(txtVendor3uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3uic0, "123");
    });
    expect(txtVendor3uic0.getAttribute("value")).toBe("");
    expect(txtVendor3uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor3uic0", () => {
    // START_USER_CODE-USER_txtVendor3uic0_TEST
    // END_USER_CODE-USER_txtVendor3uic0_TEST
  });
  test("txtVendor4suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor4suic0 = screen.getByTestId("txtVendor4suic0");
    expect(txtVendor4suic0.tagName).toBe("INPUT");
    expect(txtVendor4suic0.type).toBe("text");
    expect(txtVendor4suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4suic0, "123");
    });
    expect(txtVendor4suic0.getAttribute("value")).toBe("");
    expect(txtVendor4suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor4suic0", () => {
    // START_USER_CODE-USER_txtVendor4suic0_TEST
    // END_USER_CODE-USER_txtVendor4suic0_TEST
  });
  test("txtVendor4uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor4uic0 = screen.getByTestId("txtVendor4uic0");
    expect(txtVendor4uic0.tagName).toBe("INPUT");
    expect(txtVendor4uic0.type).toBe("text");
    expect(txtVendor4uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4uic0, "123");
    });
    expect(txtVendor4uic0.getAttribute("value")).toBe("");
    expect(txtVendor4uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor4uic0", () => {
    // START_USER_CODE-USER_txtVendor4uic0_TEST
    // END_USER_CODE-USER_txtVendor4uic0_TEST
  });
  test("txtVendor5suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor5suic0 = screen.getByTestId("txtVendor5suic0");
    expect(txtVendor5suic0.tagName).toBe("INPUT");
    expect(txtVendor5suic0.type).toBe("text");
    expect(txtVendor5suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor5suic0, "123");
    });
    expect(txtVendor5suic0.getAttribute("value")).toBe("");
    expect(txtVendor5suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor5suic0", () => {
    // START_USER_CODE-USER_txtVendor5suic0_TEST
    // END_USER_CODE-USER_txtVendor5suic0_TEST
  });
  test("txtVendor5uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor5uic0 = screen.getByTestId("txtVendor5uic0");
    expect(txtVendor5uic0.tagName).toBe("INPUT");
    expect(txtVendor5uic0.type).toBe("text");
    expect(txtVendor5uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor5uic0, "123");
    });
    expect(txtVendor5uic0.getAttribute("value")).toBe("");
    expect(txtVendor5uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor5uic0", () => {
    // START_USER_CODE-USER_txtVendor5uic0_TEST
    // END_USER_CODE-USER_txtVendor5uic0_TEST
  });
  test("txtVendor6suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor6suic0 = screen.getByTestId("txtVendor6suic0");
    expect(txtVendor6suic0.tagName).toBe("INPUT");
    expect(txtVendor6suic0.type).toBe("text");
    expect(txtVendor6suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor6suic0, "123");
    });
    expect(txtVendor6suic0.getAttribute("value")).toBe("");
    expect(txtVendor6suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor6suic0", () => {
    // START_USER_CODE-USER_txtVendor6suic0_TEST
    // END_USER_CODE-USER_txtVendor6suic0_TEST
  });
  test("txtVendor6uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor6uic0 = screen.getByTestId("txtVendor6uic0");
    expect(txtVendor6uic0.tagName).toBe("INPUT");
    expect(txtVendor6uic0.type).toBe("text");
    expect(txtVendor6uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor6uic0, "123");
    });
    expect(txtVendor6uic0.getAttribute("value")).toBe("");
    expect(txtVendor6uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor6uic0", () => {
    // START_USER_CODE-USER_txtVendor6uic0_TEST
    // END_USER_CODE-USER_txtVendor6uic0_TEST
  });
  test("txtVendor7suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor7suic0 = screen.getByTestId("txtVendor7suic0");
    expect(txtVendor7suic0.tagName).toBe("INPUT");
    expect(txtVendor7suic0.type).toBe("text");
    expect(txtVendor7suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor7suic0, "123");
    });
    expect(txtVendor7suic0.getAttribute("value")).toBe("");
    expect(txtVendor7suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor7suic0", () => {
    // START_USER_CODE-USER_txtVendor7suic0_TEST
    // END_USER_CODE-USER_txtVendor7suic0_TEST
  });
  test("txtVendor7uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor7uic0 = screen.getByTestId("txtVendor7uic0");
    expect(txtVendor7uic0.tagName).toBe("INPUT");
    expect(txtVendor7uic0.type).toBe("text");
    expect(txtVendor7uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor7uic0, "123");
    });
    expect(txtVendor7uic0.getAttribute("value")).toBe("");
    expect(txtVendor7uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor7uic0", () => {
    // START_USER_CODE-USER_txtVendor7uic0_TEST
    // END_USER_CODE-USER_txtVendor7uic0_TEST
  });
  test("txtVendor8suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor8suic0 = screen.getByTestId("txtVendor8suic0");
    expect(txtVendor8suic0.tagName).toBe("INPUT");
    expect(txtVendor8suic0.type).toBe("text");
    expect(txtVendor8suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor8suic0, "123");
    });
    expect(txtVendor8suic0.getAttribute("value")).toBe("");
    expect(txtVendor8suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor8suic0", () => {
    // START_USER_CODE-USER_txtVendor8suic0_TEST
    // END_USER_CODE-USER_txtVendor8suic0_TEST
  });
  test("txtVendor8uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor8uic0 = screen.getByTestId("txtVendor8uic0");
    expect(txtVendor8uic0.tagName).toBe("INPUT");
    expect(txtVendor8uic0.type).toBe("text");
    expect(txtVendor8uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor8uic0, "123");
    });
    expect(txtVendor8uic0.getAttribute("value")).toBe("");
    expect(txtVendor8uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor8uic0", () => {
    // START_USER_CODE-USER_txtVendor8uic0_TEST
    // END_USER_CODE-USER_txtVendor8uic0_TEST
  });
  test("txtVendor9suic0(Textbox Widget) Test Cases", async () => {
    const txtVendor9suic0 = screen.getByTestId("txtVendor9suic0");
    expect(txtVendor9suic0.tagName).toBe("INPUT");
    expect(txtVendor9suic0.type).toBe("text");
    expect(txtVendor9suic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor9suic0, "123");
    });
    expect(txtVendor9suic0.getAttribute("value")).toBe("");
    expect(txtVendor9suic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor9suic0", () => {
    // START_USER_CODE-USER_txtVendor9suic0_TEST
    // END_USER_CODE-USER_txtVendor9suic0_TEST
  });
  test("txtVendor9uic0(Textbox Widget) Test Cases", async () => {
    const txtVendor9uic0 = screen.getByTestId("txtVendor9uic0");
    expect(txtVendor9uic0.tagName).toBe("INPUT");
    expect(txtVendor9uic0.type).toBe("text");
    expect(txtVendor9uic0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor9uic0, "123");
    });
    expect(txtVendor9uic0.getAttribute("value")).toBe("");
    expect(txtVendor9uic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor9uic0", () => {
    // START_USER_CODE-USER_txtVendor9uic0_TEST
    // END_USER_CODE-USER_txtVendor9uic0_TEST
  });
});
