import React from "react";
import IframeWidget from "./IframeWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("IframeWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let IframeWidget1 = {
        name: "IframeWidget1",
        type: "IframeWidget",
        parent: "groupboxwidget0",
        Label: "IframeWidget1",
        ofTypeDomain: "d_boolean",
        Enabled: true,
        HasLabel: true,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <IframeWidget
          onChange={onChange}
          values={values}
          conf={IframeWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("IframeWidget Widget Test Cases", async () => {
    const IframeWidget1 = screen.getByTestId("IframeWidget1").firstChild;
    expect(IframeWidget1.tagName).toBe("BUTTON");
    fireEvent.click(IframeWidget1, { target: { "  inlineValidate": true } });
  });
});
describe("IframeWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let IframeWidget1 = {
        name: "IframeWidget1",
        type: "IframeWidget",
        parent: "groupboxwidget0",
        Label: "IframeWidget1",
        ofTypeDomain: "d_boolean",
        Enabled: true,
        HasLabel: false,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <IframeWidget
          onChange={onChange}
          values={values}
          conf={IframeWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("IframeWidget Widget Test Cases to cover remaining branches", async () => {
    const IframeWidget1 = screen.getByTestId("IframeWidget1").firstChild;
    expect(IframeWidget1.tagName).toBe("BUTTON");
    fireEvent.click(IframeWidget1, { target: { "  inlineValidate": false } });
  });
});
