/* eslint-disable*/
import { ApiService } from "../../Common/ApiService";

export const AccountPayment = {
  RetrieveBatchReadyToPaymentDetails,
  RetrievePaymentActivityAuditDetails,
  RetrievePremiumDeductionMasterDetails,
  RetrieveBatchReadyToPaymentIssueDetails,
  RetrieveVerifyChecksUnusedDetails,
  UpdateNullifyCheckDetails,
  RetrievePaymentActivity,
  RetrieveReadyToPaymentDetails,
  RetrieveScacVendorLookupDetails,
  RetrieveOpenAccountControlDetails,
  UpdateSpecialPayablesReset,
  RemoveSpecialPayables,
  RetrieveSpecialPayableDetails,
  CreateSpecialPayables,
  RetrieveSpecialPayableList,  
  UpdateBatchReadyToPayDetails,
  UpdateReleaseReadyToPayDetails,
  RetrieveBatchCheckPrintDetails,
  UpdateSpecialPayablesSetOnHold,
  UpdateChangeCheckNumberDetails,  
  UpdateVoidCheckDetails,
  RetrieveSpecialPayDocumentDetails,
  UpdateSpecialPayUploadDocument,
  RetrieveLienHolderControlDetails,
  UpdateReleaseReadyToPayList,
  RetrieveSpecialPayables,
  CreateBatchJobRequestDetails,
  RetrievePaymentActivityListDetails,
  RetrieveBatchCheckDetails,
  UpdateBatchCheckDetails,
  UpdateSpecialPayDocumentDetails,
  UpdateSpecialPayDocumentScanList,
  RetrieveAgreementWorksheetDocumentDetails,
  RetrieveBatchCheckDetailsList,
  UpdateBatchRemarks,
  UpdateBatchReturnToHoldPayment,
  RetrievePpsReportDetails
};
const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const accountpayment = '/account-payment/v1/';

export function UpdateSpecialPayDocumentScanList (document_num, dataobj){
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateSpecialPayDocumentScanList/document-numbers/${document_num}/scans?` : `${accountpayment}document-numbers/${document_num}/scans?`, dataobj)
}

export function UpdateSpecialPayDocumentDetails (document_num,dataobj){
  let optionalParamsUrl = '';
  if (document_num !== null && document_num !== '' && document_num !== undefined) {
    document_num += `document_num=${document_num}&`
  }
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateSpecialPayDocumentDetails/scans?${optionalParamsUrl}` : `${accountpayment}scans?${optionalParamsUrl}`, dataobj)
}

export function UpdateSpecialPayablesSetOnHold(buy_pt_id, payee_vendor, memo_num) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateSpecialPayablesSetOnHold/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${payee_vendor}/numbers/${memo_num}/payables` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${payee_vendor}/numbers/${memo_num}/payables`)
}

export function RetrieveBatchCheckPrintDetails(buy_pt_id, number, payee_vendor) {
  let optionalParamsUrl = '';
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveBatchCheckPrintDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/check-numbers/${number}/prints?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/check-numbers/${number}/prints?${optionalParamsUrl}`)
}
export function UpdateReleaseReadyToPayDetails(buy_pt_id, cbuy_pt_id, number, acct_vendor, dataObj) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateReleaseReadyToPayDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/cbuying-points/${cbuy_pt_id}/check-numbers/${number}/vendors/${acct_vendor}/readytopays` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/cbuying-points/${cbuy_pt_id}/check-numbers/${number}/vendors/${acct_vendor}/readytopays`, dataObj)
}
export function UpdateBatchReadyToPayDetails(buy_pt_id, acct_vendor, dataObj) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateBatchReadyToPayDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}/batches` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}/batches`, dataObj)
}
export function RetrieveSpecialPayableList(buy_pt_id, payee_vendor, memo_num, status, chk_num, recv_num) {
  let optionalParamsUrl = '';
  if (status !== null && status !== '' && status !== undefined) {
    optionalParamsUrl += `status=${status}&`
  }
  if (chk_num !== null && chk_num !== '' && chk_num !== undefined) {
    optionalParamsUrl += `chk_num=${chk_num}&`
  }
  if (recv_num !== null && recv_num !== '' && recv_num !== undefined) {
    optionalParamsUrl += `recv_num=${recv_num}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveSpecialPayableList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${payee_vendor}/numbers/${memo_num}/payables?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${payee_vendor}/numbers/${memo_num}/payables?${optionalParamsUrl}`);
}
export function CreateSpecialPayables(memoNum, dataObj) {
  return ApiService.postData(isLocal() ? `/AccountPayment/CreateSpecialPayables/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/numbers/${memoNum}/payables` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/numbers/${memoNum}/payables`, dataObj);
}
export function RetrieveSpecialPayableDetails(invoice) {
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveSpecialPayableDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/invoices/${invoice}/specialpayables` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/invoices/${invoice}/specialpayables`)
}
export function RemoveSpecialPayables(buy_pt_id, vendorId, memNum, transactionNum) {
  return ApiService.removeData(isLocal() ? `/AccountPayment/RemoveSpecialPayables/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendorId}/memo-number/${memNum}/transactions/${transactionNum}/users/${useridFromLS()}/payables` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendorId}/memo-number/${memNum}/transactions/${transactionNum}/users/${useridFromLS()}/payables`)
}
export function UpdateSpecialPayablesReset(buy_pt_id, vendorId, memNum, dataObj) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateSpecialPayablesReset/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/vendors/${vendorId}/numbers/${memNum}/payables` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/vendors/${vendorId}/numbers/${memNum}/payables`, dataObj);
}
export function RetrieveOpenAccountControlDetails(buy_pt_id, acct_vendor) {
  let optionalParamsUrl = '';
  if (compIdFromLS() !== null && compIdFromLS() !== '' && compIdFromLS() !== undefined) {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (acct_vendor !== null && acct_vendor !== '' && acct_vendor !== undefined) {
    optionalParamsUrl += `acct_vendor=${acct_vendor}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveOpenAccountControlDetails/controls?${optionalParamsUrl}` : `${accountpayment}controls?${optionalParamsUrl}`)
}
export function RetrieveBatchReadyToPaymentDetails(buy_pt_id, payee_vendor, payee_remit) {
  let optionalParamsUrl = '';
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  if (payee_remit !== null && payee_remit !== '' && payee_remit !== undefined) {
    optionalParamsUrl += `payee_remit=${payee_remit}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveBatchReadyToPaymentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/payments?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/payments?${optionalParamsUrl}`);
}
export function RetrieveVerifyChecksUnusedDetails(check_num_low, check_num_high) {
  let optionalParamsUrl = '';
  if (compIdFromLS() !== null && compIdFromLS() !== '' && compIdFromLS() !== undefined) {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if (cropYearFromLS() !== null && cropYearFromLS() !== '' && cropYearFromLS() !== undefined) {
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
  }
  if (check_num_low !== null && check_num_low !== '' && check_num_low !== undefined) {
    optionalParamsUrl += `check_num_low=${check_num_low}&`
  }
  if (check_num_high !== null && check_num_high !== '' && check_num_high !== undefined) {
    optionalParamsUrl += `check_num_high=${check_num_high}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveVerifyChecksUnusedDetails/verifychecks?${optionalParamsUrl}` : `${accountpayment}verifychecks?${optionalParamsUrl}`)

}
export function RetrieveBatchReadyToPaymentIssueDetails(batch_number, buy_pt_id, cbuy_pt_id, start_check_num) {
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveBatchReadyToPaymentIssueDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/cbuying-points/${cbuy_pt_id}/check-numbers/${start_check_num}/batch-numbers/${batch_number}/users/${useridFromLS()}/issues` : `${accountpayment}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/cbuying-points/${cbuy_pt_id}/check-numbers/${start_check_num}/batch-numbers/${batch_number}/users/${useridFromLS()}/issues`);

}
export function RetrievePaymentActivityAuditDetails(check_num, receivable_num) {
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrievePaymentActivityAuditDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/check-numbers/${check_num}/receivable-numbers/${receivable_num}/audits` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/check-numbers/${check_num}/receivable-numbers/${receivable_num}/audits`);
}
export function RetrievePremiumDeductionMasterDetails(pd_code, check_enable_ind) {
  let optionalParamsUrl = '';
  if (pd_code !== null && pd_code !== '' && pd_code !== undefined) {
    optionalParamsUrl += `pd_code=${pd_code}&`
  }
  if (check_enable_ind !== null && check_enable_ind !== '' && check_enable_ind !== undefined) {
    optionalParamsUrl += `check_enable_ind=${check_enable_ind}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrievePremiumDeductionMasterDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/deductions?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/deductions?${optionalParamsUrl}`);
}
export function UpdateNullifyCheckDetails(buy_pt_id, check_num, uid) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateNullifyCheckDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/check-numbers/${check_num}/nullifychecks ` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/check-numbers/${check_num}/nullifychecks`, uid);

}
export function RetrievePaymentActivity(func_id, func_sub_id, buy_pt_id, payee_vendor, payee_remit, check_num, settlement_num, wire_xfer_data, memo_num, invoice_num, item_code, oleic_ind, charge_buy_pt_id, start_date, end_date, pnut_type_id, seed_gen, check_status, pay_amount) {
  let optionalParamsUrl = '';
  if (func_sub_id !== null && func_sub_id !== '' && func_sub_id !== undefined) {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  if (payee_remit !== null && payee_remit !== '' && payee_remit !== undefined) {
    optionalParamsUrl += `payee_remit=${payee_remit}&`
  }
  if (check_num !== null && check_num !== '' && check_num !== undefined) {
    optionalParamsUrl += `check_num=${check_num}&`
  }
  if (settlement_num !== null && settlement_num !== '' && settlement_num !== undefined) {
    optionalParamsUrl += `settlement_num=${settlement_num}&`
  }
  if (wire_xfer_data !== null && wire_xfer_data !== '' && wire_xfer_data !== undefined) {
    optionalParamsUrl += `wire_xfer_data=${wire_xfer_data}&`
  }
  if (memo_num !== null && memo_num !== '' && memo_num !== undefined) {
    optionalParamsUrl += `memo_num=${memo_num}&`
  }
  if (invoice_num !== null && invoice_num !== '' && invoice_num !== undefined) {
    optionalParamsUrl += `invoice_num=${invoice_num}&`
  }
  if (item_code !== null && item_code !== '' && item_code !== undefined) {
    optionalParamsUrl += `item_code=${item_code}&`
  }
  if (oleic_ind !== null && oleic_ind !== '' && oleic_ind !== undefined) {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (charge_buy_pt_id !== null && charge_buy_pt_id !== '' && charge_buy_pt_id !== undefined) {
    optionalParamsUrl += `charge_buy_pt_id=${charge_buy_pt_id}&`
  }
  if (invoice_num !== null && invoice_num !== '' && invoice_num !== undefined) {
    optionalParamsUrl += `invoice_num=${invoice_num}&`
  }
  if (start_date !== null && start_date !== '' && start_date !== undefined) {
    optionalParamsUrl += `start_date=${start_date}&`
  }
  if (end_date !== null && end_date !== '' && end_date !== undefined) {
    optionalParamsUrl += `end_date=${end_date}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (seed_gen !== null && seed_gen !== '' && seed_gen !== undefined) {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (check_status !== null && check_status !== '' && check_status !== undefined) {
    optionalParamsUrl += `check_status=${check_status}&`
  }
  if (pay_amount !== null && pay_amount !== '' && pay_amount !== undefined) {
    optionalParamsUrl += `pay_amount=${pay_amount}`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrievePaymentActivity/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/functions/${func_id}/payments?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/functions/${func_id}/payments?${optionalParamsUrl}`);
}

export function RetrieveReadyToPaymentDetails(buy_pt_id, payee_vendor, audit_farm_id, audit_farm_suffix, audit_insp_num, audit_cont_num, audit_settle_num, audit_whse_rcpt, rtp_proceeds, rtp_status, batch_number, rtp_payee_remit, special_type_none, rtp_status_hr) {
  let optionalParamsUrl = '';
  if (audit_farm_id !== null && audit_farm_id !== '' && audit_farm_id !== undefined) {
    optionalParamsUrl += `audit_farm_id=${audit_farm_id}&`
  }
  if (audit_farm_suffix !== null && audit_farm_suffix !== '' && audit_farm_suffix !== undefined) {
    optionalParamsUrl += `audit_farm_suffix=${audit_farm_suffix}&`
  }
  if (audit_insp_num !== null && audit_insp_num !== '' && audit_insp_num !== undefined) {
    optionalParamsUrl += `audit_insp_num=${audit_insp_num}&`
  }
  if (audit_cont_num !== null && audit_cont_num !== '' && audit_cont_num !== undefined) {
    optionalParamsUrl += `audit_cont_num=${audit_cont_num}&`
  }
  if (audit_settle_num !== null && audit_settle_num !== '' && audit_settle_num !== undefined) {
    optionalParamsUrl += `audit_settle_num=${audit_settle_num}&`
  }
  if (audit_whse_rcpt !== null && audit_whse_rcpt !== '' && audit_whse_rcpt !== undefined) {
    optionalParamsUrl += `audit_whse_rcpt=${audit_whse_rcpt}&`
  }
  if (rtp_proceeds !== null && rtp_proceeds !== '' && rtp_proceeds !== undefined) {
    optionalParamsUrl += `rtp_proceeds=${rtp_proceeds}&`
  }
  if (rtp_status !== null && rtp_status !== '' && rtp_status !== undefined) {
    optionalParamsUrl += `rtp_status=${rtp_status}&`
  }
  if (batch_number !== null && batch_number !== '' && batch_number !== undefined) {
    optionalParamsUrl += `batch_number=${batch_number}&`
  }
  if (rtp_payee_remit !== null && rtp_payee_remit !== '' && rtp_payee_remit !== undefined) {
    optionalParamsUrl += `rtp_payee_remit=${rtp_payee_remit}&`
  }
  if (special_type_none !== null && special_type_none !== '' && special_type_none !== undefined) {
    optionalParamsUrl += `special_type_none=${special_type_none}&`
  }
  if (rtp_status_hr !== null && rtp_status_hr !== '' && rtp_status_hr !== undefined) {
    optionalParamsUrl += `rtp_status_hr=${rtp_status_hr}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveReadyToPaymentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${payee_vendor}/payments?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${payee_vendor}/payments?${optionalParamsUrl}`);
}

export function UpdateChangeCheckNumberDetails(buy_pt_id,old_check_num,new_check_num,data) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateChangeCheckNumberDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/old-check-numbers/${old_check_num}/new-check-numbers/${new_check_num}/checks` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/old-check-numbers/${old_check_num}/new-check-numbers/${new_check_num}/checks`, data)
}

export function RetrieveScacVendorLookupDetails(number, scac) {
  let optionalParamsUrl = '';
  if (scac !== null && scac !== '' && scac !== undefined) {
    optionalParamsUrl += `scac=${scac}&`
  }
  if (number !== null && number !== '' && number !== undefined) {
    optionalParamsUrl += `number=${number}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveScacVendorLookupDetails/vendors?${optionalParamsUrl}` : `${accountpayment}vendors?${optionalParamsUrl}`);
}
export function UpdateVoidCheckDetails(buy_pt_id,checkdt,cnum,fa_void_type,rnum,dataobj) {
  let optionalParamsUrl = '';
  if (cnum !== null && cnum !== '' && cnum !== undefined) {
    optionalParamsUrl += `cnum=${cnum}&`
  }
  if (rnum !== null && rnum !== '' && rnum !== undefined) {
    optionalParamsUrl += `rnum=${rnum}&`
  }
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateVoidCheckDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/dates/${checkdt}/users/${useridFromLS()}/voids/${fa_void_type}/checks?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/dates/${checkdt}/users/${useridFromLS()}/voids/${fa_void_type}/checks?${optionalParamsUrl}`,dataobj);
} 

export function RetrieveSpecialPayDocumentDetails(memo_num, document_num) {
  let optionalParamsUrl = '';
  if (memo_num !== null && memo_num !== '' && memo_num !== undefined) {
    optionalParamsUrl += `memo_num=${memo_num}&`
  }
  if (document_num !== null && document_num !== '' && document_num !== undefined) {
    optionalParamsUrl += `document_num=${document_num}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveSpecialPayDocumentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/documents?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/documents?${optionalParamsUrl}`);
}

export function UpdateSpecialPayUploadDocument(document_num) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateSpecialPayUploadDocument/document-numbers/${document_num}/documents` : `${accountpayment}document-numbers/${document_num}/documents`);
}

export function RetrieveLienHolderControlDetails(compId, cropYear, vendor, lienholderLine) {
  let optionalParamsUrl = '';
  if(compId !== null && compId !== undefined && compId !== '')
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  if(cropYear !== null && cropYear !== undefined && cropYear !== '')
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
  if (vendor != null && vendor != undefined && vendor != '')
    optionalParamsUrl += `vendor=${vendor}&`
  if (lienholderLine != null && lienholderLine != undefined && lienholderLine != '')
    optionalParamsUrl += `lienholder_line=${lienholderLine}`

  return ApiService.getData(
    isLocal()
      ? `/AccountPayment/RetrieveLienHolderControlDetails/lienholders?${optionalParamsUrl}`
      : `${accountpayment}lienholders?${optionalParamsUrl}`
  )
}

export function RetrieveSpecialPayables(memo_num, invoice_num, payee_vendor, payee_remit, item_code, pay_amount, spool_buy_pt_id, charge_buy_pt_id, start_date, end_date, pnut_type_id, seed_gen, oleic_ind, rtp_sw, pay_sw, status, chk_num, recv_num) {
  let optionalParamsUrl = '';
  if (memo_num !== null && memo_num !== '' && memo_num !== undefined) {
    optionalParamsUrl += `memo_num=${memo_num}`
  }
  if (invoice_num !== null && invoice_num !== '' && invoice_num !== undefined) {
    optionalParamsUrl += `&invoice_num=${invoice_num}`
  }
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `&payee_vendor=${payee_vendor}`
  }
  if (payee_remit !== null && payee_remit !== '' && payee_remit !== undefined) {
    optionalParamsUrl += `&payee_remit=${payee_remit}`
  }
  if (item_code !== null && item_code !== '' && item_code !== undefined) {
    optionalParamsUrl += `&item_code=${item_code}`
  }
  if (pay_amount !== null && pay_amount !== '' && pay_amount !== undefined) {
    optionalParamsUrl += `&pay_amount=${pay_amount}`
  }
  if (spool_buy_pt_id !== null && spool_buy_pt_id !== '' && spool_buy_pt_id !== undefined) {
    optionalParamsUrl += `&spool_buy_pt_id=${spool_buy_pt_id}`
  }
  if (charge_buy_pt_id !== null && charge_buy_pt_id !== '' && charge_buy_pt_id !== undefined) {
    optionalParamsUrl += `&charge_buy_pt_id=${charge_buy_pt_id}`
  }
  if (start_date !== null && start_date !== '' && start_date !== undefined) {
    optionalParamsUrl += `&start_date=${start_date}`
  }
  if (end_date !== null && end_date !== '' && end_date !== undefined) {
    optionalParamsUrl += `&end_date=${end_date}`
  }
  if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
    optionalParamsUrl += `&pnut_type_id=${pnut_type_id}`
  }
  if (seed_gen !== null && seed_gen !== '' && seed_gen !== undefined) {
    optionalParamsUrl += `&seed_gen=${seed_gen}`
  }
  if (oleic_ind !== null && oleic_ind !== '' && oleic_ind !== undefined) {
    optionalParamsUrl += `&oleic_ind=${oleic_ind}`
  }
  if (rtp_sw !== null && rtp_sw !== '' && rtp_sw !== undefined) {
    optionalParamsUrl += `&rtp_sw=${rtp_sw}`
  }
  if (pay_sw !== null && pay_sw !== '' && pay_sw !== undefined) {
    optionalParamsUrl += `&pay_sw=${pay_sw}`
  }
  if (status !== null && status !== '' && status !== undefined) {
    optionalParamsUrl += `&status=${status}`
  }
  if (chk_num !== null && chk_num !== '' && chk_num !== undefined) {
    optionalParamsUrl += `&chk_num=${chk_num}`
  }
  if (recv_num !== null && recv_num !== '' && recv_num !== undefined) {
    optionalParamsUrl += `&recv_num=${recv_num}`
  }
  return ApiService.getData(isLocal() ?`/AccountPayment/RetrieveSpecialPayables/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/payables?${optionalParamsUrl}`:`${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/payables?${optionalParamsUrl}`);
}
export function UpdateReleaseReadyToPayList(dataObj) {
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateReleaseReadyToPayList/readytopays` : `${accountpayment}readytopays`, dataObj)
}

export function CreateBatchJobRequestDetails(job_id,dataObj) {
  return ApiService.postData(isLocal() ? `/AccountPayment/CreateBatchJobRequestDetails/job-id/${job_id}/requests` : `${accountpayment}job-id/${job_id}/requests`, dataObj)
}
export function RetrievePaymentActivityListDetails(buy_pt_id,check_num,batch_number,payee_vendor){
  let optionalParamsUrl = '';
  if (batch_number !== null && batch_number !== '' && batch_number !== undefined) {
    optionalParamsUrl += `batch_number=${batch_number}&`
  }
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  return ApiService.getData(isLocal() ? `/AccountPayment/RetrievePaymentActivityListDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/check-numbers/${check_num}/activities?${optionalParamsUrl}` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/check-numbers/${check_num}/activities?${optionalParamsUrl}`)
}

export function RetrieveBatchCheckDetails(check_num) {
return ApiService.getData(isLocal() ? `/AccountPayment/RetrieveBatchCheckDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/check-numbers/${check_num}/checks` : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/check-numbers/${check_num}/checks`);
}

export function UpdateBatchCheckDetails(check_num,buy_pt_id,payee_vendor,data) {
  let optionalParamsUrl = '';
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  return ApiService.updateData(isLocal() ? `/AccountPayment/UpdateBatchCheckDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/numbers/${check_num}/buying-points/${buy_pt_id}/batches?${optionalParamsUrl}`:`${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/numbers/${check_num}/buying-points/${buy_pt_id}/batches?${optionalParamsUrl}`,data)
}
export function RetrieveAgreementWorksheetDocumentDetails(agree_num, delv_ind, buy_pt_id){
  return ApiService.getData(
    isLocal()
    ? `/AccountPayment/RetrieveAgreementWorksheetDocumentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/agreement-numbers/${agree_num}/delivery-indicators/${delv_ind}/buying-points/${buy_pt_id}/worksheetdocuments`
    : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/agreement-numbers/${agree_num}/delivery-indicators/${delv_ind}/buying-points/${buy_pt_id}/worksheetdocuments`
  )
}

export function RetrieveBatchCheckDetailsList(buy_pt_id, batch_number, payee_vendor){
  let optionalParamsUrl = '';
  
  if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined) {
    optionalParamsUrl += `payee_vendor=${payee_vendor}&`
  }
  return ApiService.getData(
    isLocal()
    ? `/AccountPayment/RetrieveBatchCheckDetailsList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/batch_numbers/${batch_number}/batchcheck?${optionalParamsUrl}`
    : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/batch_numbers/${batch_number}/batchcheck?${optionalParamsUrl}`
  )
}

export function UpdateBatchRemarks(batch_number,dataobj){
  return ApiService.updateData(
    isLocal()
    ? `/AccountPayment/UpdateBatchRemarks/batch_numbers/${batch_number}/batchremarks?`
    : `${accountpayment}/batch_numbers/${batch_number}/batchremarks?`,dataobj
  )
}

export function UpdateBatchReturnToHoldPayment(buy_pt_id,batch_number,dataobj){
  return ApiService.updateData(
    isLocal()
    ? `/AccountPayment/UpdateBatchReturnToHoldPayment/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/batch_numbers/${batch_number}/holdpayments?`
    : `${accountpayment}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/batch_numbers/${batch_number}/holdpayments?`,dataobj)
}

export function RetrievePpsReportDetails(){
  return ApiService.getData(
    isLocal() 
      ? `/AccountPayment/RetrievePpsReportDetails/users/${useridFromLS()}/ppsreports`
      : `${accountpayment}users/${useridFromLS()}/ppsreports`
  )
}