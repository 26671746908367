/* eslint-disable*/
import moment from "moment";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { showMessage } from "../../shared/ParentWindow";
import { StockTransferService } from "../Service/StockTransferService";
import { clsArea } from "./clsArea";
import { clsBrokenRules } from "./clsBrokenRules";
import { clsUser } from "./clsUser";
import { Buffer } from 'buffer';

let mobjValid = new clsBrokenRules();
let AreaInfo = new clsArea();
let CurrentUser = new clsUser();

const defaultTransferProps = {
  comp_id: "",
  crop_year: "",
  transfer_num: "",
  revision_num: "",
  original_xfer: "",
  dispatch_num: "",
  vehichle_num: "",
  truck_line: "",
  driver_name: "",
  shp_buy_pt: "",
  shp_whouse: "",
  shp_bin: "",
  shp_date: "",
  shp_scale_tick: "",
  shp_split_scale: "",
  shp_tare_wt: "",
  shp_vehicle_wt: "",
  shp_peanut_wt: "",
  rec_buy_pt: "",
  rec_whouse: "",
  rec_bin: "",
  rec_date: "",
  rec_scale_tick: "",
  rec_split_scale: "",
  rec_tare_wt: "",
  rec_vehicle_wt: "",
  rec_peanut_wt: "",
  inventory_ind: "",
  other_wt: "",
  pnut_type_id: "",
  pnut_variety_id: "",
  seed_gen: "",
  seg_type: "",
  edible_oil_ind: "",
  oleic_ind: "",
  corn_ind: "",
  flavus_ind: "",
  vicam_ind: "",
  vicam_ppb: "",
  fm_sample: "",
  cln_sample: "",
  smk_gr: "",
  ss_gr: "",
  ok_gr: "",
  dam_split_gr: "",
  dam_gr: "",
  hull_gr: "",
  frez_dam_gr: "",
  pit_dam_gr: "",
  cncl_rmd_gr: "",
  cr_br_gr: "",
  dcol_shel_gr: "",
  hul_brit_gr: "",
  jumbo_gr: "",
  elk_gr: "",
  elk_ride_gr: "",
  elk_dam_gr: "",
  fm_gr: "",
  lsk_gr: "",
  fan_gr: "",
  ride_pscrib_gr: "",
  smk_pct: "",
  smk_1_pct: "",
  smk_2_pct: "",
  smk_3_pct: "",
  smk_4_pct: "",
  ss_pct: "",
  ok_pct: "",
  ok_ride_pct: "",
  ok_fall_pct: "",
  ok_dam_pct: "",
  dam_pct: "",
  tkc_pct: "",
  hull_pct: "",
  frez_dam_pct: "",
  pit_dam_pct: "",
  cncl_rmd_pct: "",
  cr_br_pct: "",
  dcol_shel_pct: "",
  hul_brit_pct: "",
  jumbo_pct: "",
  elk_pct: "",
  elk_ride_pct: "",
  elk_dam_pct: "",
  fm_pct: "",
  lsk_pct: "",
  lsk_ride_pct: "",
  lsk_fall_pct: "",
  lsk_dam_pct: "",
  moist_pct: "",
  fan_pct: "",
  ride_pscrib_pct: "",
  oth_fm: "",
  ok_guage: "",
  smk_1_guage: "",
  smk_2_guage: "",
  smk_3_guage: "",
  smk_4_guage: "",
  lsk_guage: "",
  gross_wt: "",
  fm_wt: "",
  ex_moist_wt: "",
  net_wt: "",
  lsk_wt: "",
  kern_value: "",
  elk_prem: "",
  dam_discount: "",
  ex_fm_discount: "",
  ex_ss_discount: "",
  flavus_discount: "",
  net_les_lsk_val: "",
  net_price: "",
  basis_grade_amt: "",
  freight_rate: "",
  freight_amt: "",
  add_user: "",
  add_date_time: null,
  chg_user: "",
  chg_date_time: null,
  accounting_date: null,
  xfer_status: "",
  from_seed_gen: "",
  to_seed_gen: "",
  from_edible_oil_ind: "",
  to_edible_oil_ind: "",
  from_oleic_ind: "",
  to_oleic_ind: "",
  from_pnut_variety_id: "",
  to_pnut_variety_id: "",
  transfer_type: "",
  pay_freight_ind: "",
  frt_other_wt: "",
  frt_accrl_complete: "",
  frt_scac_id: "",
  frt_vendor: "",
  state_abbr: "",
  county_id: "",
  farm_id: "",
  farm_suffix: "", //'project suffix
  freight_invoice: "",
  trip_id: 0,
  leg_count: "",
  leg_orig_buy_pt: "",
  leg_dest_buy_pt: "",
  leg_mileage: "",
  trip_weight: "",
  frt_premium_amt: "",
  frt_deduct_amt: "",
  frt_proceeds_amt: "",
  frt_rate_basis: "",
  frt_mileage_override: "",
  from_organic_ind: "",
  to_organic_ind: "",
  frt_fuel_pct: "",
  frt_lease_pct: "",
  frt_rate_source: "",
  manual_grade_ind: "",
  xfer_auto_num_ind: "",
  remark: "",
  settled_1007_num: "",
  vendor_info: "",
  from_pnut_variety_name: "",
  from_symbol_ind: "",
  to_pnut_variety_name: "",
  to_symbol_ind: "",
  manualentrymode: "", //'grade info entry
  manualoverride: false,
  // '    xfer_gram_entry_ind: '',
  area_id: "",
  gramentry: false,
  editmoist: false, //'pp1-2011-031 6/24/2011
  shp_buy_info: "",
  rec_buy_info: "",
  scac: "",
  grade_source: "",
  override_source: "",
  transferrole: "",
};

const UserTransferRoleList = ["ReadOnly", "Shipper", "Receiver", "Freighter"];
const ManualEntryMode = ["ManualGrams", "ManualPct"];
const TransferStatus = [
  "Pending",
  "Shipped",
  "Received",
  "FreightAccrued",
  "Voided",
];
const PayFreightType = [
  "ShippedWeight",
  "ReceivedWeight",
  "OtherWeight",
  "NoFreight",
];

const RulesList = {
  SHIPPING_LOCATION_IS_REQUIRED_RULE: "Shipping Location is Required!",
  TRANSFER_NUMBER_IS_REQUIRED_RULE: "Transfer Number is Required!",
  TRANSFER_NUMBER_ALREADY_EXIST_RULE: "Transfer Number already exist!",
  RECEIVED_DATE_EARLIER_THAN_SHIP_DATE:
    "Received Date cannot be earlier than Ship Date!",
  SHIP_RECEIVED_DAYS_GREATER_THAN_RANGE:
    "Number of days between ship date and received date is greater than ",
  DISPATCH_NUMBER_IS_REQUIRED_FOR_NO_LOADS:
    "Dispatch Number is required for No Loads!",
  OTHER_INVENTORY_UPDATE_WEIGHT_IS_REQUIRED_RULE:
    "Update inventory other weight is required!",
  TOTAL_KERNELS_AND_HULLS_OUT_OF_RANGE_RULE:
    "Total Kernals and Hulls outside acceptable range!",
  ELK_RIDING_SCREEN_MINUS_TOTAL_ELK_DAMAGE_NOT_EQUAL_NET_ELK_RULE:
    "ELK Riding Screen - Total Elk Damage must equal NetELK!",
  SMK1_THROUGH_SMK4_MUST_TOTAL_RULE: "SMK1 through SMK4 must total SMKRS!",
  LSK_MUST_TOTAL_RULE: "LSK Riding Screen data must equal total LSK!",
  FREIGHT_OTHER_WEIGHT_IS_REQUIRED_RULE: "Freight other weight is required!",
  INVENTORY_OTHER_WEIGHT_IS_REQUIRED_RULE:
    "Update inventory other weight is required!",
  FARM_IS_REQUIRED_RULE:
    "Freight state, county, farm, farm suffix information is required!", //Project Suffix

  //Shipped Rules
  SHIP_DATE_IS_REQUIRED_RULE: "Ship Date is Required!",
  SHIP_DATE_IS_INVALID:
    "Please, enter a valid Ship Date in the [MM/DD/YYYY] format",
  SHIP_DATE_LATER_THAN_TODAY_RULE:
    "Shipped Date cannot be later than today's date!",
  SHIPPED_DATE_EARLIER_THAN_START_CROP_YEAR:
    "Shipped Date cannot be earlier than start of crop year date", //2010-010
  SHIPPED_SCALE_TICKET_IS_REQUIRED_RULE: "Shipped Scale Ticket is Required!",
  SHIPPED_GROSS_WEIGHT_IS_REQUIRED_RULE: "Shipped Gross Weight is Required!",
  //Infra# 1717397 (11/03/2011)
  SHIPPED_TARE_WEIGHT_IS_REQUIRED_RULE: "Shipped Vehicle Weight is Required!",
  SHIPPED_TARE_WEIGHT_SHOULD_BE_LESS_THEN_GROSS_WEIGHT_RULE:
    "Shipped Tare Weight should be less then gross weight!",
  SHIPPED_BIN_IS_REQUIRED_RULE: "Shipped From Bin is Required!",
  //Received Rules
  RECEIVED_DATE_IS_REQUIRED_RULE: "Received Date is required!",
  RECEIVED_BUYING_POINT_IS_REQUIRED_RULE: "Received Buying Point is Required!",
  RECEIVED_DATE_IS_INVALID:
    "Please, enter a valid Received Date in the [MM/DD/YYYY] format",
  RECEIVED_DATE_LATER_THAN_TODAY_RULE:
    "Received Date cannot be later than today's date!",
  RECEIVED_DATE_EARLIER_THAN_START_CROP_YEAR:
    "Received Date cannot be earlier than start of crop year date", //2010-010
  RECEIVED_SCALE_TICKET_IS_REQUIRED_RULE: "Received Scale Ticket is Required!",
  RECEIVED_GROSS_WEIGHT_IS_REQUIRED_RULE: "Received Gross Weight is Required!",
  //Infra# 1717397 (11/03/2011)
  RECEIVED_TARE_WEIGHT_IS_REQUIRED_RULE: "Received Vehicle Weight is Required!",
  RECEIVED_TARE_WEIGHT_SHOULD_BE_LESS_THEN_GROSS_WEIGHT_RULE:
    "Received Tare Weight should be less then gross weight!",
  RECEIVED_BIN_IS_REQUIRED_RULE: "Shipped To Bin is Required!",
  //Freight Rules
  VALID_VENDOR_IS_REQUIRED_RULE: "Valid vendor is required!",
  MILEAGE_IS_REQUIRED_RULE: "Mileage is required!",
  OTHER_FREIGHT_WEIGHT_IS_REQUIRED_RULE: "Freight other weight is required!",

  //Grade Calculation Rules
  INVALID_PERCENT_RULE: "Invalid percent!",
  OK_BALANCE_RULE:
    "Other Kernel Riding Screen data must equal total Other Kernel!",

  //Peanut Info rules
  PEANUT_TYPE_IS_REQUIRED_RULE: "Peanut Type is Required!",
  SEGMENT_TYPE_IS_REQUIRED_RULE: "Segment is Required!",
};

const GRADE_GRAM_DECIMALS = 1;
const GRADE_PCT_DECIMALS = 1;
const GRADE_PCT_FORMAT = 2;

const compIdFromLS = () => sessionStorage.getItem("compId");
const cropYearFromLS = () => JSON.parse(sessionStorage.getItem("year"));
const useridFromLS = () => sessionStorage.getItem("userid") || "";

export class clsTransfer {
  constructor(thisObj) {
    this.thisObj = thisObj;
    this.mudtProps = Object.assign({}, defaultTransferProps);
    this.mudtSave = Object.assign({}, defaultTransferProps);
    this.mflgNew = false;
    this.mflgEditing = false;
    this.mflgDeleted = false;
    this.mflgDirty = false;
    this.mflgReceivingPointChanged = false;
    this.mflgLoading = false;
    this.msOld_Trans_Num = "";
    this.miMaxDateRange = 5;

    this.mDbError = null;
    this.mPrevShippingLocation = null;
    this.mPrevTransferType = null;
    this.mFANeedInfo = "";

    this.mNewStatus = 0;
    this.transferRole = "";

    //--StockTransfer Start--
    this.mbFormFilling = false;
    this.ShippingLocationList = {};
    this.DestinationLocationList = {};
    this.StateList = {};
    this.CountyList = {};
    this.FarmList = {};
    this.mbAutoNumber = false;
    this.mPeanutTypeID = "";
    this.mSegment = "";
    this.mOrganic = "";
    this.mCtrl = "";
    this.mbFromFrtAccrl = false;
    this.mPrevTransferShpLoc = "";
    this.mPrevTransferRecLoc = "";
    this.mOldPrevTransferType = "";
    this.mPrevState = "";
    this.mPrevCounty = "";
    this.mPrevFarm = "";
    this.mPrevFarmSuffix = "";
    this.Farm_Suffix_FromList = "";
    this.mbStartTransfer = false;
    this.mbChgShpLoc = false;
    //--StockTransfer End--

    this.mudtProps.xfer_status = "P"; //'Pending;
    this.mudtProps.transfer_type = "ST"; //'Standard Transfer;
    this.mudtProps.pay_freight_ind = "R"; //'Use Received Weight;
    this.mudtProps.inventory_ind = "R"; //'Use Received Weight;
    this.mudtProps.frt_accrl_complete = "N"; //'Freight Accrual Not Completed;
    this.mudtProps.comp_id = compIdFromLS();
    this.mudtProps.crop_year = cropYearFromLS();
    this.mudtProps.add_user = useridFromLS();
    this.mudtProps.chg_user = useridFromLS();
    this.mudtProps.add_date_time = moment(Date.now()).format("MM/DD/YYYY h:mm:ss A");
    this.mudtProps.chg_date_time = moment(Date.now()).format("MM/DD/YYYY h:mm:ss A");
    this.mudtProps.transfer_num = "";
    this.mudtProps.manual_grade_ind = "N"; //'Auto Grade;
    //' this.mudtProps.XFER_GRAM_ENTRY_IND = 'N'         //'Percent Mode;
    this.mudtProps.gramentry = false; //'Percent Mode;
    this.mudtProps.editmoist = false; //'PP1-2011-031 6/24/2011;
    this.mudtProps.xfer_auto_num_ind = "N"; //'Manual Entry Transfer Number;
    //' this.mudtProps.FM_SAMPLE = 100;
    //' this.mudtProps.CLN_SAMPLE = 100;

    this.mflgNew = true;

    //------For Public Event Flags-----
    this.BuyingPointChanged = {
      trigger: false,
      isShipping: null,
      buyingPt: "",
    };
    this.TransferStatusChanged = false;
    this.TransferTypeChanged = false;
    this.FreightAccrualStatusChanged = {
      trigger: false,
      isFreightAccrualCompleted: null,
    };
    this.WeightChanged = false;
    this.ManualGradeStatusChanged = {
      trigger: false,
      manualGradeMode: "",
    };
    this.PeanutValueChanged = false;
    this.ShippingBinChanged = false;
    this.PropertyChanged = false;
    //---------------------------------
  } // End constructor

  async setMaxDateRange() {
    //-----Part of Load
    mobjValid = new clsBrokenRules();
    mobjValid.RuleBroken(RulesList.SHIPPING_LOCATION_IS_REQUIRED_RULE, true);
    //-----------------
    let response = await ContractManagementService.RetrievePeanutStaticValues();
    if (response.length != 0) {
      this.miMaxDateRange = response[0].pps_defaults.transfer_max_date_range;
    }
  }

  SetTransferRole = async (value) => {
    this.transferRole = value;
  };

  CheckTransferAccessLevel = async (
    ShippingBuyingPointID,
    ReceivingBuyingPointID = "",
    TransferType = ""
  ) => {
    let response1 =
      await ContractManagementService.RetrieveAccessPermissionDetails(
        "PN1080",
        "001",
        "U"
      );
    if (String(response1[0].permission).toUpperCase() == "Y") {
      if (TransferType == "FF") {
        this.transferRole = UserTransferRoleList.indexOf("Freighter");
      } else {
        this.transferRole = UserTransferRoleList.indexOf("Shipper");
      }
    }
    if (ReceivingBuyingPointID != "") {
      let response2 =
        await ContractManagementService.RetrieveAccessPermissionDetails(
          "PN1080",
          "002",
          "U"
        );
      if (String(response2[0].permission).toUpperCase() == "Y") {
        if (TransferType == "FF") {
          this.transferRole = UserTransferRoleList.indexOf("Freighter");
        } else {
          this.transferRole = UserTransferRoleList.indexOf("Receiver");
        }
      }
      let response3 =
        await ContractManagementService.RetrieveAccessPermissionDetails(
          "PN1080",
          "003",
          "U"
        );
      if (String(response3[0].permission).toUpperCase() == "Y") {
        this.transferRole = UserTransferRoleList.indexOf("Freighter");
      }
    }
  };

  GetTransferTypeIndex = async () => {
    switch (this.mudtProps.transfer_type) {
      case "ST":
        return "0";
      case "FF":
        return "1";
      case "NL":
        return "2";
    }
  };

  GetInventoryIndicatorIndex = async () => {
    switch (this.mudtProps.inventory_ind) {
      case "S":
        return "0";
      case "R":
        return "1";
      case "O":
        return "2";
    }
  };

  async GetTransferStatusID(NewStatus) {
    //'   Converts Transfer Status from enumeration into string
    let transferStatusID = "";
    switch (NewStatus) {
      case TransferStatus.indexOf("Pending"):
        transferStatusID = "P";
        break;
      case TransferStatus.indexOf("Received"):
        transferStatusID = "R";
        break;
      case TransferStatus.indexOf("Shipped"):
        transferStatusID = "S";
        break;
      default:
        transferStatusID = " ";
        break;
    }
    return transferStatusID;
  } // End GetTransferStatusID

  // async CalculatePeanutValueOld() {
  //   try {
  //     let dWtInclVeh = 0.0;
  //     let dWtVeh = 0.0;

  //     // '12/17/2009
  //     switch (this.mudtProps.inventory_ind) {
  //       case "R":
  //         dWtInclVeh = this.mudtProps.rec_tare_wt;
  //         dWtVeh = this.mudtProps.rec_vehicle_wt;
  //         break;
  //       case "S":
  //         dWtInclVeh = this.mudtProps.shp_tare_wt;
  //         dWtVeh = this.mudtProps.shp_vehicle_wt;
  //         break;
  //       case "O":
  //         dWtInclVeh = this.mudtProps.other_wt;
  //         dWtVeh = 0;
  //         break;
  //       default:
  //         break;
  //     }

  //     const res = await SettlementService.RetrieveValueCalculationList(
  //       this.mudtProps.area_id,
  //       this.mudtProps.pnut_type_id,
  //       this.mudtProps.cr_br_pct,
  //       this.mudtProps.dcol_shel_pct,
  //       this.mudtProps.fan_pct,
  //       this.mudtProps.dam_pct,
  //       this.mudtProps.ss_pct,
  //       this.mudtProps.vicam_ppb,
  //       this.mudtProps.fm_pct,
  //       this.mudtProps.smk_pct,
  //       this.mudtProps.ss_pct,
  //       this.mudtProps.ok_pct,
  //       this.mudtProps.elk_pct,
  //       this.mudtProps.lsk_pct,
  //       this.mudtProps.moist_pct,
  //       dWtInclVeh,
  //       dWtVeh,
  //       this.mudtProps.fm_pct
  //     );

  //     if (!res?.length) {
  //       return;
  //     }
  //     const resValue = res?.[0];
  //     this.mudtProps.basis_grade_amt = resValue.total_value_of_load;
  //     this.mudtProps.net_wt = resValue.net_weight;
  //     this.mudtProps.fm_wt = resValue.foreign_material_weight;
  //     this.mudtProps.lsk_wt = resValue.lsk_weight;
  //     this.mudtProps.ex_moist_wt = resValue.excess_moisture_weight;
  //     this.mudtProps.kern_value = resValue.kernel_value_dollars_per_ton;

  //     this.mudtProps.elk_prem = resValue.elk_premium_dollars_per_ton
  //       ? parseFloat(resValue.elk_premium_dollars_per_ton)
  //       : 0;
  //     this.mudtProps.dam_discount = resValue.damaged_discount_dollars_per_ton
  //       ? parseFloat(resValue.damaged_discount_dollars_per_ton)
  //       : 0;
  //     this.mudtProps.ex_fm_discount =
  //       resValue.excess_fm_discount_dollars_per_ton
  //         ? parseFloat(resValue.excess_fm_discount_dollars_per_ton)
  //         : 0;
  //     this.mudtProps.ex_ss_discount =
  //       resValue.excess_splits_discount_dollars_per_ton
  //         ? parseFloat(resValue.excess_splits_discount_dollars_per_ton)
  //         : 0;
  //     this.mudtProps.net_les_lsk_val = resValue.net_value_per_ton_excluding_lsk
  //       ? parseFloat(resValue.net_value_per_ton_excluding_lsk)
  //       : 0;
  //     if (this.mudtProps.net_wt > 0) {
  //       this.mudtProps.net_price = Number(
  //         Number(this.mudtProps.basis_grade_amt) / Number(this.mudtProps.net_wt)
  //       ).toFixed(7);
  //       // 'GetXmlElement(XmlDoc, "//value_per_pound_excluding_lsk") / 100)
  //     } else {
  //       this.mudtProps.net_price = 0;
  //     }

  //     if ((this.mudtProps.tkc_pct = 0)) {
  //       this.mudtProps.tkc_pct = resValue.total_kernel_content
  //         ? parseFloat(resValue.total_kernel_content)
  //         : 0;
  //     }

  //     if (this.thisObj) {
  //       setData(this.thisObj, "PeanutValueChanged", true);
  //     }
  //   } catch (error) {
  //     console.error("Error occured during CalculatePeanutValueOld", error);
  //     if (this.thisObj) {
  //       if (error instanceof EvalError) {
  //         showMessage(this.thisObj, error?.message);
  //       } else {
  //         showMessage(
  //           this.thisObj,
  //           `Error occurred during CalculatePeanutValueOld: ${error?.toString()}`
  //         );
  //       }
  //     }
  //   }
  // } // End CalculatePeanutValueOld

  Serialize() {
    return {
      COMP_ID: this.mudtProps.comp_id,
      CROP_YEAR: this.mudtProps.crop_year,
      TRANSFER_NUM: this.mudtProps.transfer_num,
      REVISION_NUM: this.mudtProps.revision_num,
      ORIGINAL_XFER: this.mudtProps.original_xfer,
      LOAD_NUM: this.mudtProps.LOAD_NUM,
      DISPATCH_NUM: this.mudtProps.dispatch_num,
      VEHICHLE_NUM: this.mudtProps.vehichle_num,
      TRUCK_LINE: this.mudtProps.truck_line,
      DRIVER_NAME: this.mudtProps.driver_name,
      SHP_BUY_PT: this.mudtProps.shp_buy_pt,
      SHP_WHOUSE: this.mudtProps.shp_whouse,
      SHP_BIN: this.mudtProps.shp_bin,
      SHP_DATE: this.mudtProps.shp_date,
      SHP_SCALE_TICK: this.mudtProps.shp_scale_tick,
      SHP_SPLIT_SCALE: this.mudtProps.shp_split_scale,
      SHP_TARE_WT: this.mudtProps.shp_tare_wt,
      SHP_VEHICLE_WT: this.mudtProps.shp_vehicle_wt,
      SHP_PEANUT_WT: this.mudtProps.shp_peanut_wt,
      REC_BUY_PT: this.mudtProps.rec_buy_pt,
      REC_WHOUSE: this.mudtProps.rec_whouse,
      REC_BIN: this.mudtProps.rec_bin,
      REC_DATE: this.mudtProps.rec_date,
      REC_SCALE_TICK: this.mudtProps.rec_scale_tick,
      REC_SPLIT_SCALE: this.mudtProps.rec_split_scale,
      REC_TARE_WT: this.mudtProps.rec_tare_wt,
      REC_VEHICLE_WT: this.mudtProps.rec_vehicle_wt,
      REC_PEANUT_WT: this.mudtProps.rec_peanut_wt,
      INVENTORY_IND: this.mudtProps.inventory_ind,
      OTHER_WT: this.mudtProps.other_wt,
      PNUT_TYPE_ID: this.mudtProps.pnut_type_id,
      PNUT_VARIETY_ID: this.mudtProps.pnut_variety_id,
      SEED_GEN: this.mudtProps.seed_gen,
      SEG_TYPE: this.mudtProps.seg_type,
      EDIBLE_OIL_IND: this.mudtProps.edible_oil_ind,
      OLEIC_IND: this.mudtProps.oleic_ind,
      CORN_IND: this.mudtProps.corn_ind,
      FLAVUS_IND: this.mudtProps.flavus_ind,
      VICAM_IND: this.mudtProps.vicam_ind,
      VICAM_PPB: this.mudtProps.vicam_ppb,
      FM_SAMPLE: this.mudtProps.fm_sample,
      CLN_SAMPLE: this.mudtProps.cln_sample,
      SMK_GR: this.mudtProps.smk_gr,
      SS_GR: this.mudtProps.ss_gr,
      OK_GR: this.mudtProps.ok_gr,
      DAM_SPLIT_GR: this.mudtProps.dam_split_gr,
      DAM_GR: this.mudtProps.dam_gr,
      HULL_GR: this.mudtProps.hull_gr,
      FREZ_DAM_GR: this.mudtProps.frez_dam_gr,
      PIT_DAM_GR: this.mudtProps.pit_dam_gr,
      CNCL_RMD_GR: this.mudtProps.cncl_rmd_gr,
      CR_BR_GR: this.mudtProps.cr_br_gr,
      DCOL_SHEL_GR: this.mudtProps.dcol_shel_gr,
      HUL_BRIT_GR: this.mudtProps.hul_brit_gr,
      JUMBO_GR: this.mudtProps.jumbo_gr,
      ELK_GR: this.mudtProps.elk_gr,
      ELK_RIDE_GR: this.mudtProps.elk_ride_gr,
      ELK_DAM_GR: this.mudtProps.elk_dam_gr,
      FM_GR: this.mudtProps.fm_gr,
      LSK_GR: this.mudtProps.lsk_gr,
      FAN_GR: this.mudtProps.fan_gr,
      RIDE_PSCRIB_GR: this.mudtProps.ride_pscrib_gr,
      SMK_PCT: this.mudtProps.smk_pct,
      SMK_1_PCT: this.mudtProps.smk_1_pct,
      SMK_2_PCT: this.mudtProps.smk_2_pct,
      SMK_3_PCT: this.mudtProps.smk_3_pct,
      SMK_4_PCT: this.mudtProps.smk_4_pct,
      SS_PCT: this.mudtProps.ss_pct,
      OK_PCT: this.mudtProps.ok_pct,
      OK_RIDE_PCT: this.mudtProps.ok_ride_pct,
      OK_FALL_PCT: this.mudtProps.ok_fall_pct,
      OK_DAM_PCT: this.mudtProps.ok_dam_pct,
      DAM_PCT: this.mudtProps.dam_pct,
      TKC_PCT: this.mudtProps.tkc_pct,
      HULL_PCT: this.mudtProps.hull_pct,
      FREZ_DAM_PCT: this.mudtProps.frez_dam_pct,
      PIT_DAM_PCT: this.mudtProps.pit_dam_pct,
      CNCL_RMD_PCT: this.mudtProps.cncl_rmd_pct,
      CR_BR_PCT: this.mudtProps.cr_br_pct,
      DCOL_SHEL_PCT: this.mudtProps.dcol_shel_pct,
      HUL_BRIT_PCT: this.mudtProps.hul_brit_pct,
      JUMBO_PCT: this.mudtProps.jumbo_pct,
      ELK_PCT: this.mudtProps.elk_pct,
      ELK_RIDE_PCT: this.mudtProps.elk_ride_pct,
      ELK_DAM_PCT: this.mudtProps.elk_dam_pct,
      FM_PCT: this.mudtProps.fm_pct,
      LSK_PCT: this.mudtProps.lsk_pct,
      LSK_RIDE_PCT: this.mudtProps.lsk_ride_pct,
      LSK_FALL_PCT: this.mudtProps.lsk_fall_pct,
      LSK_DAM_PCT: this.mudtProps.lsk_dam_pct,
      MOIST_PCT: this.mudtProps.moist_pct,
      FAN_PCT: this.mudtProps.fan_pct,
      RIDE_PSCRIB_PCT: this.mudtProps.ride_pscrib_pct,
      OTH_FM: this.mudtProps.oth_fm,
      OK_GUAGE: this.mudtProps.ok_guage,
      SMK_1_GUAGE: this.mudtProps.smk_1_guage,
      SMK_2_GUAGE: this.mudtProps.smk_2_guage,
      SMK_3_GUAGE: this.mudtProps.smk_3_guage,
      SMK_4_GUAGE: this.mudtProps.smk_4_guage,
      LSK_GUAGE: this.mudtProps.lsk_guage,
      GROSS_WT: this.mudtProps.gross_wt,
      FM_WT: this.mudtProps.fm_wt,
      EX_MOIST_WT: this.mudtProps.ex_moist_wt,
      NET_WT: this.mudtProps.net_wt,
      LSK_WT: this.mudtProps.lsk_wt,
      KERN_VALUE: this.mudtProps.kern_value,
      ELK_PREM: this.mudtProps.elk_prem,
      DAM_DISCOUNT: this.mudtProps.dam_discount,
      EX_FM_DISCOUNT: this.mudtProps.ex_fm_discount,
      EX_SS_DISCOUNT: this.mudtProps.ex_ss_discount,
      FLAVUS_DISCOUNT: this.mudtProps.flavus_discount,
      NET_LES_LSK_VAL: this.mudtProps.net_les_lsk_val,
      NET_PRICE: this.mudtProps.net_price,
      BASIS_GRADE_AMT: this.mudtProps.basis_grade_amt,
      FREIGHT_RATE: this.mudtProps.freight_rate,
      FREIGHT_AMT: this.mudtProps.freight_amt,
      ADD_USER: this.mudtProps.add_user,
      ADD_DATE_TIME: this.mudtProps.add_date_time,
      CHG_USER: this.mudtProps.chg_user,
      CHG_DATE_TIME: this.mudtProps.chg_date_time,
      ACCOUNTING_DATE: this.mudtProps.accounting_date,
      XFER_STATUS: this.mudtProps.xfer_status,
      FROM_SEED_GEN: this.mudtProps.from_seed_gen,
      TO_SEED_GEN: this.mudtProps.to_seed_gen,
      FROM_EDIBLE_OIL_IND: this.mudtProps.from_edible_oil_ind,
      TO_EDIBLE_OIL_IND: this.mudtProps.to_edible_oil_ind,
      FROM_OLEIC_IND: this.mudtProps.from_oleic_ind,
      TO_OLEIC_IND: this.mudtProps.to_oleic_ind,
      FROM_PNUT_VARIETY_ID: this.mudtProps.from_pnut_variety_id,
      TO_PNUT_VARIETY_ID: this.mudtProps.to_pnut_variety_id,
      TRANSFER_TYPE: this.mudtProps.transfer_type,
      PAY_FREIGHT_IND: this.mudtProps.pay_freight_ind,
      FRT_OTHER_WT: this.mudtProps.frt_other_wt,
      FRT_ACCRL_COMPLETE: this.mudtProps.frt_accrl_complete,
      FRT_SCAC_ID: this.mudtProps.frt_scac_id,
      FRT_VENDOR: this.mudtProps.frt_vendor,
      STATE_ABBR: this.mudtProps.state_abbr,
      COUNTY_ID: this.mudtProps.county_id,
      FARM_ID: this.mudtProps.farm_id,
      FARM_SUFFIX: this.mudtProps.farm_suffix,
      FREIGHT_INVOICE: this.mudtProps.freight_invoice,
      TRIP_ID: this.mudtProps.trip_id,
      LEG_COUNT: this.mudtProps.leg_count,
      LEG_ORIG_BUY_PT: this.mudtProps.leg_orig_buy_pt,
      LEG_DEST_BUY_PT: this.mudtProps.leg_dest_buy_pt,
      LEG_MILEAGE: this.mudtProps.leg_mileage,
      TRIP_WEIGHT: this.mudtProps.trip_weight,
      FRT_PREMIUM_AMT: this.mudtProps.frt_premium_amt,
      FRT_DEDUCT_AMT: this.mudtProps.frt_deduct_amt,
      FRT_PROCEEDS_AMT: this.mudtProps.frt_proceeds_amt,
      FRT_RATE_BASIS: this.mudtProps.frt_rate_basis,
      FRT_MILEAGE_OVERRIDE: this.mudtProps.frt_mileage_override,
      FROM_ORGANIC_IND: this.mudtProps.from_organic_ind,
      TO_ORGANIC_IND: this.mudtProps.to_organic_ind,
      FRT_FUEL_PCT: this.mudtProps.frt_fuel_pct,
      FRT_LEASE_PCT: this.mudtProps.frt_lease_pct,
      FRT_RATE_SOURCE: this.mudtProps.frt_rate_source,
      MANUAL_GRADE_IND: this.mudtProps.manual_grade_ind,
      REMARK: this.mudtProps.remark,
      SETTLED_1007_NUM: this.mudtProps.settled_1007_num,
      GRADE_SOURCE: this.mudtProps.grade_source,
      OVERRIDE_SOURCE: this.mudtProps.override_source,
      TEMP_ID: this.mudtProps.TEMP_ID,
    };
  } // End Serialize

  async CheckCurrentStatus() {
    if (this.mudtProps.transfer_type === "NL") {
      if (
        this.mudtProps.shp_buy_pt !== "" &&
        this.mudtProps.rec_buy_pt !== ""
      ) {
        await this.CheckTransferAccessLevel(
          this.mudtProps.shp_buy_pt,
          this.mudtProps.rec_buy_pt,
          this.mudtProps.transfer_type
        );
      }
      if (
        this.mudtProps.xfer_status === "S" &&
        this.transferRole > UserTransferRoleList.indexOf("Shipper")
      ) {
        this.mudtProps.rec_date = this.mudtProps.shp_date;
        this.mNewStatus = TransferStatus.indexOf("Received");
        this.mudtProps.xfer_status = "R";
        this.mflgDirty = true;
      }
    }
  } // End CheckCurrentStatus

  async CreateTransfer(value = "") {
    try {
      if (Boolean(value)) {
        this.mudtProps.transfer_num = value;
      }
      this.mudtProps.xfer_status = await this.GetTransferStatusID(this.mNewStatus);
      await this.CalculatePeanutValueOld();
      const createTransferPayload = this.NewSerialize();
      await this.CheckCurrentStatus();

      const response = await StockTransferService.CreateTransferHeader(
        createTransferPayload
      );

      if (response.status !== 200) {
        this.mDbError = response.message
        this.mudtProps.transfer_num = "* NEW *"
        return false;
      } else {
        let data = response.result

        data = data.split("|")

        for (let i = 0; i < data.length; i++) {
          if (data[i].includes("return_value")) {
            this.mudtProps.transfer_num = data[i].split(":")[1]
          }
          if (data[i].includes("chg_date_time")) {
            this.mudtProps.chg_date_time = data[i].split(":")[1] + ":" + data[i].split(":")[2] + ":" + data[i].split(":")[3]
          }
          if (data[i].includes("leg_mileage")) {
            this.mudtProps.leg_mileage = data[i].split(":")[1]
          }
          if (data[i].includes("fa_message")) {
            this.mFANeedInfo = data[i].split(":")[1] + (data[i].split(":")[2] !== undefined ? ":" + data[i].split(":")[2] : "")
          }
        }
        this.mflgNew = false;
        return true
      }
    } catch (error) {
      console.error("Error occured during CreateTransfer", error);
      if (this.thisObj) {
        if (error instanceof EvalError) {
          showMessage(this.thisObj, error?.message);
        } else {
          showMessage(
            this.thisObj,
            `Error occurred during CreateTransfer: ${error?.toString()}`
          );
        }
      }
    }
  } // End CreateTransfer

  async CheckForLeasedVehicle() {
    if (this.mudtProps.vehichle_num != "") {
      let response =
        await StockTransferService.RetrieveLeasedTrailerListDetails(
          null,
          this.mudtProps.vehichle_num
        );
      if (response.length > 0) {
        let data = response[0];
        if (data.vehichle_num == "") {
          this.mudtProps.frt_lease_pct = 0;
        } else {
          this.mudtProps.frt_lease_pct = Number(data.percentage);
        }
      } else {
        this.mudtProps.frt_lease_pct = 0;
      }
    } else {
      this.mudtProps.frt_lease_pct = 0;
    }
  }

  async CalculateGrossWeight() {
    if (
      Number(this.mudtProps.shp_tare_wt) > 0 &&
      Number(this.mudtProps.shp_tare_wt) > Number(this.mudtProps.shp_vehicle_wt)
    ) {
      this.mudtProps.shp_peanut_wt =
        Number(this.mudtProps.shp_tare_wt) - Number(this.mudtProps.shp_vehicle_wt);
    } else {
      this.mudtProps.shp_peanut_wt = 0;
    }
    mobjValid.RuleBroken(
      RulesList["SHIPPED_TARE_WEIGHT_SHOULD_BE_LESS_THEN_GROSS_WEIGHT_RULE"],
      Number(this.mudtProps.shp_vehicle_wt) > Number(this.mudtProps.shp_tare_wt)
    );
    if (
      Number(this.mudtProps.rec_tare_wt) > 0 &&
      Number(this.mudtProps.rec_tare_wt) > Number(this.mudtProps.rec_vehicle_wt)
    ) {
      this.mudtProps.rec_peanut_wt =
        Number(this.mudtProps.rec_tare_wt) - Number(this.mudtProps.rec_vehicle_wt);
    } else {
      this.mudtProps.rec_peanut_wt = 0;
    }
    mobjValid.RuleBroken(
      RulesList["RECEIVED_TARE_WEIGHT_SHOULD_BE_LESS_THEN_GROSS_WEIGHT_RULE"],
      Number(this.mudtProps.rec_vehicle_wt) > Number(this.mudtProps.rec_tare_wt)
    );
    mobjValid.RuleBroken(
      RulesList["RECEIVED_TARE_WEIGHT_IS_REQUIRED_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.rec_vehicle_wt == 0
    );
    mobjValid.RuleBroken(
      RulesList["RECEIVED_GROSS_WEIGHT_IS_REQUIRED_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.rec_tare_wt == 0,
      false
    );
    switch (this.mudtProps.inventory_ind) {
      case "R":
        this.mudtProps.gross_wt = this.mudtProps.rec_peanut_wt;
        break;
      case "S":
        this.mudtProps.gross_wt = this.mudtProps.shp_peanut_wt;
        break;
      case "O":
        this.mudtProps.gross_wt = this.mudtProps.other_wt;
        break;
    }
  }

  async SetGramEntryMode() {
    switch (this.mudtProps.gramentry) {
      case true:
        this.mudtProps.manualentrymode = ManualEntryMode.indexOf("ManualGrams");
        break;
      case false:
        this.mudtProps.manualentrymode = ManualEntryMode.indexOf("ManualPct");
        break;
      default:
        this.mudtProps.manualentrymode = ManualEntryMode.indexOf("ManualPct");
        break;
    }
  }

  async GramEntryName() {
    await this.SetGramEntryMode();
    switch (this.mudtProps.manualentrymode) {
      case ManualEntryMode.indexOf("ManualGrams"):
        return "GRAM";
      case ManualEntryMode.indexOf("ManualPct"):
        return "PCT";
    }
  }

  async CalculateGrade() {
    switch (this.mudtProps.manualentrymode) {
      case ManualEntryMode.indexOf("ManualGrams"):
        if (Number(this.mudtProps.cln_sample) > 0) {
          this.mudtProps.smk_pct = (
            (Number(this.mudtProps.smk_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.ok_pct = (
            (Number(this.mudtProps.ok_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.ss_pct = (
            (Number(this.mudtProps.ss_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.dam_pct = (
            (Number(this.mudtProps.dam_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.hull_pct = (
            (Number(this.mudtProps.hull_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.frez_dam_pct = (
            (Number(this.mudtProps.frez_dam_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.cncl_rmd_pct = (
            (Number(this.mudtProps.cncl_rmd_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.cr_br_pct = (
            (Number(this.mudtProps.cr_br_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.dcol_shel_pct = (
            (Number(this.mudtProps.dcol_shel_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.hul_brit_pct = (
            (Number(this.mudtProps.hul_brit_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.jumbo_pct = (
            (Number(this.mudtProps.jumbo_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.elk_pct = (
            (Number(this.mudtProps.elk_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          // this.mudtProps.fm_pct = ((this.mudtProps.fm_gr * 100) /this.mudtProps.cln_sample).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.fan_pct = (
            (Number(this.mudtProps.fan_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.pit_dam_pct = (
            (Number(this.mudtProps.pit_dam_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          // this.mudtProps.lsk_pct = ((this.mudtProps.lsk_gr * 100) /this.mudtProps.cln_sample).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.ride_pscrib_pct = (
            (Number(this.mudtProps.ride_pscrib_gr) * 100) /
            Number(this.mudtProps.cln_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
        } else {
          this.mudtProps.smk_pct = 0;
          this.mudtProps.ok_pct = 0;
          this.mudtProps.ss_pct = 0;
          this.mudtProps.dam_pct = 0;
          this.mudtProps.hull_pct = 0;
          this.mudtProps.frez_dam_pct = 0;
          this.mudtProps.cncl_rmd_pct = 0;
          this.mudtProps.cr_br_pct = 0;
          this.mudtProps.dcol_shel_pct = 0;
          this.mudtProps.hul_brit_pct = 0;
          this.mudtProps.jumbo_pct = 0;
          this.mudtProps.elk_pct = 0;
          // this.mudtProps.fm_pct = 0
          this.mudtProps.fan_pct = 0;
          this.mudtProps.pit_dam_pct = 0;
          // this.mudtProps.lsk_pct = 0
          this.mudtProps.ride_pscrib_pct = 0;
        }
        if (Number(this.mudtProps.fm_sample) > 0) {
          this.mudtProps.lsk_pct = (
            (Number(this.mudtProps.lsk_gr) * 100) /
            Number(this.mudtProps.fm_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
          this.mudtProps.fm_pct = (
            (Number(this.mudtProps.fm_gr) * 100) /
            Number(this.mudtProps.fm_sample)
          ).toFixed(GRADE_PCT_DECIMALS);
        } else {
          this.mudtProps.lsk_pct = 0;
          this.mudtProps.fm_pct = 0;
        }
        break;
      case ManualEntryMode.indexOf("ManualPct"):
        if (Number(this.mudtProps.cln_sample) > 0) {
          this.mudtProps.smk_gr = (
            (Number(this.mudtProps.smk_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.ok_gr = (
            (Number(this.mudtProps.ok_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.ss_gr = (
            (Number(this.mudtProps.ss_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.dam_gr = (
            (Number(this.mudtProps.dam_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.hull_gr = (
            (Number(this.mudtProps.hull_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.frez_dam_gr = (
            (Number(this.mudtProps.frez_dam_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.cncl_rmd_gr = (
            (Number(this.mudtProps.cncl_rmd_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.cr_br_gr = (
            (Number(this.mudtProps.cr_br_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.dcol_shel_gr = (
            (Number(this.mudtProps.dcol_shel_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.hul_brit_gr = (
            (Number(this.mudtProps.hul_brit_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.jumbo_gr = (
            (Number(this.mudtProps.jumbo_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.elk_gr = (
            (Number(this.mudtProps.elk_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          // this.mudtProps.fm_gr = ((Number(this.mudtProps.fm_pct) * Number(this.mudtProps.cln_sample))/100).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.fan_gr = (
            (Number(this.mudtProps.fan_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.pit_dam_gr = (
            (Number(this.mudtProps.pit_dam_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          // this.mudtProps.lsk_gr = ((Number(this.mudtProps.lsk_pct) * Number(this.mudtProps.cln_sample))/100).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.ride_pscrib_gr = (
            (Number(this.mudtProps.ride_pscrib_pct) * Number(this.mudtProps.cln_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
        } else {
          this.mudtProps.smk_gr = 0;
          this.mudtProps.ok_gr = 0;
          this.mudtProps.ss_gr = 0;
          this.mudtProps.dam_gr = 0;
          this.mudtProps.hull_gr = 0;
          this.mudtProps.frez_dam_gr = 0;
          this.mudtProps.cncl_rmd_gr = 0;
          this.mudtProps.cr_br_gr = 0;
          this.mudtProps.dcol_shel_gr = 0;
          this.mudtProps.hul_brit_gr = 0;
          this.mudtProps.jumbo_gr = 0;
          this.mudtProps.elk_gr = 0;
          // this.mudtProps.fm_gr = 0
          this.mudtProps.fan_gr = 0;
          this.mudtProps.pit_dam_gr = 0;
          // this.mudtProps.lsk_gr = 0
          this.mudtProps.ride_pscrib_gr = 0;
        }
        if (Number(this.mudtProps.fm_sample) > 0) {
          this.mudtProps.fm_gr = (
            (Number(this.mudtProps.fm_pct) * Number(this.mudtProps.fm_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
          this.mudtProps.lsk_gr = (
            (Number(this.mudtProps.lsk_pct) * Number(this.mudtProps.fm_sample)) / 100
          ).toFixed(GRADE_GRAM_DECIMALS);
        } else {
          this.mudtProps.fm_gr = 0;
          this.mudtProps.lsk_gr = 0;
        }
        break;
    }
    this.mudtProps.net_wt =
      this.mudtProps.gross_wt -
      this.mudtProps.fm_wt -
      this.mudtProps.ex_moist_wt;
    this.mflgDirty = true;
    let areaInfoTemp = new clsArea();
    if (AreaInfo.mudtProps.area_id == "") {
      areaInfoTemp = await CurrentUser.GetAreaInfo(this.mudtProps.area_id);
      AreaInfo = areaInfoTemp
    } else {
      areaInfoTemp = AreaInfo
    }

    let totalKernelsAndHullsPct = await this.TotalKernelsAndHullsPct()
    mobjValid.RuleBroken(
      RulesList["TOTAL_KERNELS_AND_HULLS_OUT_OF_RANGE_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      (Number(totalKernelsAndHullsPct) <
        Number(areaInfoTemp.mudtProps.min_kern_hull_pct) ||
        Number(totalKernelsAndHullsPct) >
        Number(areaInfoTemp.mudtProps.max_kern_hull_pct))
    );

    mobjValid.RuleBroken(
      RulesList["SMK1_THROUGH_SMK4_MUST_TOTAL_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      Number(this.RoundOff(this.mudtProps.smk_1_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.smk_2_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.smk_3_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.smk_4_pct, 1)) >
      0 &&
      Math.abs(
        this.RoundOff(
          Math.abs(
            Number(this.RoundOff(this.mudtProps.smk_1_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.smk_2_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.smk_3_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.smk_4_pct, 1)) -
            Number(this.RoundOff(this.mudtProps.smk_pct, 1))
          ),
          1
        )
      ) > 1,
      false
    );

    mobjValid.RuleBroken(
      RulesList["OK_BALANCE_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      Number(this.RoundOff(this.mudtProps.ok_ride_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.ok_fall_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.ok_dam_pct, 1)) >
      0 &&
      this.RoundOff(
        Math.abs(
          Number(this.RoundOff(this.mudtProps.ok_pct, 1)) -
          (Number(this.RoundOff(this.mudtProps.ok_ride_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.ok_fall_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.ok_dam_pct, 1)))
        ),
        1
      ) > 0
    );
  }

  async CalculateProceeds() {
    this.mudtProps.frt_proceeds_amt =
      Number(this.mudtProps.freight_amt) +
      Number(this.mudtProps.frt_premium_amt) +
      Number(this.mudtProps.frt_deduct_amt);
  }

  async FreightAccrualCompleted() {
    if (this.mudtProps.frt_accrl_complete == "Y") {
      return true;
    }
    return false;
  }

  isValid = async () => {
    let count = await mobjValid.Count();
    return count == 0;
  };

  async BeginEdit() {
    this.mudtSave = this.mudtProps;
    this.mflgEditing = true;
  }

  async CancelEdit() {
    this.mudtProps = this.mudtSave;
    this.mflgDirty = false;
    this.mflgEditing = false;
  }

  async ApplyEdit() {
    this.mudtProps.xfer_status = await this.GetTransferStatusID(this.mNewStatus);
    this.mudtSave = this.mudtProps;
    this.mflgEditing = false;
    this.mflgDirty = false;
  }

  async Load(inputData, crop_year = "") {
    let conditionalYear = crop_year == "" ? cropYearFromLS() : crop_year
    let response =
      await StockTransferService.RetrieveTransferHeaderSelectDetails(
        inputData.mTransferNumber,
        inputData.mTransferType,
        inputData.mShippingBuyingPointID,
        conditionalYear
      );

    let data = response[0];
    this.fetch(data);
    this.mflgNew = false;
    this.mflgDirty = false;
    mobjValid = new clsBrokenRules();
    this.BeginEdit();
  }

  fetch = (data) => {
    let self = this;
    this.mflgLoading = true;
    Object.keys(data).forEach(function (reskey) {
      try {
        self[reskey](data[reskey]);
      } catch (e) {
      }
    });
    this.mflgLoading = false;
  };

  comp_id = (value) => {
    if (this.mudtProps.comp_id == value) {
      return;
    }
    this.mudtProps.comp_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  crop_year = (value) => {
    if (this.mudtProps.crop_year == value) {
      return;
    }
    this.mudtProps.crop_year = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  transfer_num = (value) => {
    this.mudtProps.transfer_num = value;
  };

  revision_num = (value) => {
    this.mudtProps.revision_num = value;
  };

  original_xfer = (value) => {
    if (this.mudtProps.original_xfer == value) {
      return;
    }
    this.mudtProps.original_xfer = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dispatch_num = (value) => {
    if (this.mudtProps.dispatch_num == value) {
      return;
    }
    this.mudtProps.dispatch_num = value;
    mobjValid.RuleBroken(
      RulesList["DISPATCH_NUMBER_IS_REQUIRED_FOR_NO_LOADS"],
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.transfer_type == "NL" &&
      String(value).trim() == ""
    );
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  vehichle_num = async (value) => {
    if (this.mudtProps.vehichle_num == value) {
      return;
    }
    this.mudtProps.vehichle_num = value;
    if (this.mflgLoading) {
      return;
    }
    await this.CheckForLeasedVehicle();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  settled_1007_num = (value) => {
    if (this.mudtProps.settled_1007_num == value) {
      return;
    }
    this.mudtProps.settled_1007_num = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  truck_line = (value) => {
    if (this.mudtProps.truck_line == value) {
      return;
    }
    this.mudtProps.truck_line = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  driver_name = (value) => {
    if (this.mudtProps.driver_name == value) {
      return;
    }
    this.mudtProps.driver_name = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  shp_buy_pt = (value) => {
    if (this.mudtProps.shp_buy_pt == value) {
      return;
    }
    this.mPrevShippingLocation = this.mudtProps.shp_buy_pt;
    this.mudtProps.shp_buy_pt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    let obj = { trigger: true, isShipping: true, buyingPt: value };
    this.BuyingPointChanged = obj;
  };

  shp_whouse = (value) => {
    if (this.mudtProps.shp_whouse == value) {
      return;
    }
    this.mudtProps.shp_whouse = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  shp_bin = (value) => {
    mobjValid.RuleBroken(
      RulesList["SHIPPED_BIN_IS_REQUIRED_RULE"],
      this.mNewStatus != TransferStatus.indexOf("Pending") && value == ""
    );
    if (this.mudtProps.shp_bin == value) {
      return;
    }
    this.mudtProps.shp_bin = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.ShippingBinChanged = true;
  };

  xfer_auto_num_ind = (value) => {
    this.mudtProps.xfer_auto_num_ind = value;
  };

  shp_date = async (value) => {
    if (this.mflgLoading) {
      this.mudtProps.shp_date = value;
      return;
    }
    if (this.mudtProps.shp_date == value) {
      return;
    }
    if (moment(value).isValid()) {
      this.mudtProps.shp_date = value;
      if (!moment(this.mudtProps.rec_date).isValid()) {
        this.NewStatus(TransferStatus.indexOf("Shipped"));
      }
      let currentDate = moment(Date.now());
      let yearStartDate = moment("7/1/" + cropYearFromLS());
      let shpDate = moment(value);
      let recDate = moment(this.mudtProps.rec_date);

      const diffCurrentDays = currentDate.diff(shpDate, 'days')
      const diffStartDays = shpDate.diff(yearStartDate, 'days')

      mobjValid.RuleBroken(
        RulesList["SHIP_DATE_LATER_THAN_TODAY_RULE"],
        diffCurrentDays < 0
      );
      mobjValid.RuleBroken(
        RulesList["SHIPPED_DATE_EARLIER_THAN_START_CROP_YEAR"],
        diffStartDays < 0,
        false,
        yearStartDate.format("MM/DD/YYYY")
      );
      if (moment(this.mudtProps.rec_date).isValid()) {
        const diffEarlyDays = shpDate.diff(recDate, 'days')

        mobjValid.RuleBroken(
          RulesList["RECEIVED_DATE_EARLIER_THAN_SHIP_DATE"],
          diffEarlyDays > 0,
          false
        );
      }
      if (
        this.mudtProps.transfer_type == "NL" &&
        this.transferRole > UserTransferRoleList.indexOf("Shipper")
      ) {
        this.mudtProps.rec_date = value;
        this.mNewStatus = TransferStatus.indexOf("Received");
        this.TransferStatusChanged = true;
      }
    } else {
      if (moment(this.mudtProps.rec_date).isValid()) {
        this.NewStatus(TransferStatus.indexOf("Received"));
      } else {
        this.NewStatus(TransferStatus.indexOf("Pending"));
      }
      mobjValid.RuleBroken(RulesList["SHIP_DATE_IS_INVALID"], true);
      this.mudtProps.shp_date = "";
      if ((this.mudtProps.xfer_status = "P")) {
        this.NewStatus(TransferStatus.indexOf("Pending"));
        mobjValid.BrokenRuleList = {};
      }
    }
    if (this.mflgLoading == true) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  shp_scale_tick = (value) => {
    if (this.mudtProps.shp_scale_tick == value) {
      return;
    }
    this.mudtProps.shp_scale_tick = value;
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["SHIPPED_GROSS_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.shp_tare_wt == 0,
      false
    );
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  shp_split_scale = (value) => {
    if (this.mudtProps.shp_split_scale == value) {
      return;
    }
    this.mudtProps.shp_split_scale = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  shp_tare_wt = async (value) => {
    if (this.mudtProps.shp_tare_wt == value) {
      return;
    }
    this.mudtProps.shp_tare_wt = value;
    this.CalculateGrossWeight();
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["SHIPPED_GROSS_WEIGHT_IS_REQUIRED_RULE"],
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.shp_tare_wt == 0
    );
    this.mflgDirty = true;
    this.WeightChanged = true;
    this.PropertyChanged = true;
  };

  shp_vehicle_wt = (value) => {
    if (this.mudtProps.shp_vehicle_wt == value) {
      return;
    }
    this.mudtProps.shp_vehicle_wt = value;
    this.CalculateGrossWeight();
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["SHIPPED_TARE_WEIGHT_IS_REQUIRED_RULE"],
      (this.mNewStatus == TransferStatus.indexOf("Shipped") ||
        this.mNewStatus == TransferStatus.indexOf("Received")) &&
      this.mudtProps.shp_vehicle_wt == 0
    );
    this.mflgDirty = true;
    this.WeightChanged = true;
  };

  shp_peanut_wt = (value) => {
    this.mudtProps.shp_peanut_wt = value;
  };

  rec_buy_pt = async (value) => {
    this.mudtProps.rec_buy_pt = value;
    this.SetGramEntryMode();
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    let obj = { trigger: true, isShipping: false, buyingPt: value };
    this.BuyingPointChanged = obj;
  };

  rec_whouse = (value) => {
    this.mudtProps.rec_whouse = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  rec_bin = (value) => {
    mobjValid.RuleBroken(
      RulesList["RECEIVED_BIN_IS_REQUIRED_RULE"],
      this.mNewStatus != TransferStatus.indexOf("Pending") && value == ""
    );
    this.mudtProps.rec_bin = value;
    this.SetGramEntryMode();
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  rec_date = async (value) => {
    if (moment(value).isValid()) {
      let currentDate = moment(Date.now());
      let yearStartDate = moment("7/1/" + cropYearFromLS());
      let shpDate = moment(this.mudtProps.shp_date);
      let recDate = moment(value);
      this.mudtProps.rec_date = value;
      if (this.mudtProps.transfer_type == "FF") {
        this.mudtProps.shp_date = this.mudtProps.rec_date;
      }
      this.NewStatus(TransferStatus.indexOf("Received"));

      const diffCurrentDays = currentDate.diff(recDate, 'days')

      const diffStartDays = recDate.diff(yearStartDate, 'days')

      mobjValid.RuleBroken(
        RulesList["RECEIVED_DATE_LATER_THAN_TODAY_RULE"],
        diffCurrentDays < 0
      );
      mobjValid.RuleBroken(
        RulesList["RECEIVED_DATE_EARLIER_THAN_START_CROP_YEAR"],
        diffStartDays < 0,
        false,
        yearStartDate.format("MM/DD/YYYY")
      );

      if (moment(this.mudtProps.shp_date).isValid()) {
        const diffEarlyDays = recDate.diff(shpDate, 'days')

        mobjValid.RuleBroken(
          RulesList["RECEIVED_DATE_EARLIER_THAN_SHIP_DATE"],
          diffEarlyDays < 0
        );
      }
    } else {
      this.mudtProps.rec_date = "";
      if (this.mudtProps.transfer_type == "FF") {
        this.mudtProps.shp_date = this.mudtProps.rec_date;
      }
      if (moment(this.mudtProps.shp_date).isValid()) {
        this.NewStatus(TransferStatus.indexOf("Shipped"));
      } else {
        this.NewStatus(TransferStatus.indexOf("Pending"));
      }
      mobjValid.RuleBroken(RulesList["SHIP_DATE_IS_INVALID"], true);
    }
    if (this.mflgLoading == true) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  rec_scale_tick = (value) => {
    if (this.mudtProps.rec_scale_tick == value) {
      return;
    }
    this.mudtProps.rec_scale_tick = value;
    if (this.mflgLoading) {
      return;
    }
    if (this.mudtProps.transfer_type == "FF") {
      this.mudtProps.shp_scale_tick = this.mudtProps.rec_scale_tick;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  rec_split_scale = (value) => {
    this.mudtProps.rec_split_scale = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  rec_tare_wt = (value) => {
    this.mudtProps.rec_tare_wt = value;
    this.CalculateGrossWeight();
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.WeightChanged = true;
    this.PropertyChanged = true;
  };

  rec_vehicle_wt = (value) => {
    this.mudtProps.rec_vehicle_wt = value;
    this.CalculateGrossWeight();
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.WeightChanged = true;
    this.PropertyChanged = true;
  };

  rec_peanut_wt = (value) => {
    this.mudtProps.rec_peanut_wt = value;
  };

  inventory_ind = (value) => {
    this.mudtProps.inventory_ind = value;
    this.CalculateGrossWeight();
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["INVENTORY_OTHER_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.inventory_ind == "O" && this.mudtProps.other_wt == 0
    );
    this.mflgDirty = true;
  };

  other_wt = (value) => {
    this.mudtProps.other_wt = value;
    this.CalculateGrossWeight();
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["INVENTORY_OTHER_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.inventory_ind == "O" && this.mudtProps.other_wt == 0
    );
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  pnut_type_id = async (value) => {
    if (this.mudtProps.pnut_type_id == value) {
      return;
    }
    this.mudtProps.pnut_type_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  pnut_variety_id = (value) => {
    this.mudtProps.pnut_variety_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  seed_gen = (value) => {
    this.mudtProps.seed_gen = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  seg_type = (value) => {
    this.mudtProps.seg_type = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  edible_oil_ind = (value) => {
    this.mudtProps.edible_oil_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  oleic_ind = (value) => {
    this.mudtProps.oleic_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  corn_ind = async (value) => {
    this.mudtProps.corn_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  flavus_ind = (value) => {
    this.mudtProps.flavus_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  vicam_ind = (value) => {
    this.mudtProps.vicam_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  vicam_ppb = (value) => {
    this.mudtProps.vicam_ppb = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  fm_sample = (value) => {
    if (this.mudtProps.fm_sample == value) {
      return;
    }
    this.mudtProps.fm_sample = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  cln_sample = (value) => {
    if (this.mudtProps.cln_sample == value) {
      return;
    }
    this.mudtProps.cln_sample = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_gr = (value) => {
    this.mudtProps.smk_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ss_gr = (value) => {
    this.mudtProps.ss_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ok_gr = (value) => {
    this.mudtProps.ok_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dam_split_gr = (value) => {
    this.mudtProps.dam_split_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dam_gr = (value) => {
    this.mudtProps.dam_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  hull_gr = (value) => {
    this.mudtProps.hull_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  frez_dam_gr = (value) => {
    this.mudtProps.frez_dam_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  pit_dam_gr = (value) => {
    this.mudtProps.pit_dam_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  cncl_rmd_gr = (value) => {
    this.mudtProps.cncl_rmd_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  cr_br_gr = (value) => {
    this.mudtProps.cr_br_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dcol_shel_gr = (value) => {
    this.mudtProps.dcol_shel_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  hul_brit_gr = (value) => {
    this.mudtProps.hul_brit_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  jumbo_gr = (value) => {
    this.mudtProps.jumbo_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_gr = (value) => {
    this.mudtProps.elk_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_ride_gr = (value) => {
    this.mudtProps.elk_ride_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_dam_gr = (value) => {
    this.mudtProps.elk_dam_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  fm_gr = (value) => {
    this.mudtProps.fm_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_gr = (value) => {
    this.mudtProps.lsk_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  fan_gr = (value) => {
    this.mudtProps.fan_gr = value;
    if (this.mflgLoading) {
      return;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ride_pscrib_gr = (value) => {
    if (this.mudtProps.ride_pscrib_gr == value) {
      return;
    }
    this.mudtProps.ride_pscrib_gr = value;
    if (this.mflgLoading) {
      return;
    }
    if (Number(this.mudtProps.cln_sample) > 0) {
      this.mudtProps.ride_pscrib_pct = (
        (value / this.mudtProps.fm_sample) *
        100
      ).toFixed(GRADE_GRAM_DECIMALS);
    } else {
      this.mudtProps.ride_pscrib_pct = 0;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_pct = (value) => {
    this.mudtProps.smk_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "SMK_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_1_pct = (value) => {
    this.mudtProps.smk_1_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "SMK_1_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_2_pct = (value) => {
    this.mudtProps.smk_2_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "SMK_2_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_3_pct = (value) => {
    this.mudtProps.smk_3_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "SMK_3_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_4_pct = (value) => {
    this.mudtProps.smk_4_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "SMK_4_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ss_pct = (value) => {
    this.mudtProps.ss_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "SS_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ok_pct = (value) => {
    this.mudtProps.ok_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "OK_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ok_ride_pct = (value) => {
    this.mudtProps.ok_ride_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "OK_RIDE_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ok_fall_pct = (value) => {
    this.mudtProps.ok_fall_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "OK_FALL_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ok_dam_pct = (value) => {
    this.mudtProps.ok_dam_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "OK_DAM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dam_pct = (value) => {
    this.mudtProps.dam_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "DAM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  tkc_pct = (value) => {
    this.mudtProps.tkc_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "TKC_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  hull_pct = (value) => {
    this.mudtProps.hull_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "HULL_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  frez_dam_pct = (value) => {
    this.mudtProps.frez_dam_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "FREZ_DAM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  pit_dam_pct = (value) => {
    if (this.mudtProps.pit_dam_pct == value) {
      return;
    }
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "PIT_DAM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.mudtProps.pit_dam_pct = Number(value).toFixed(1);
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  cncl_rmd_pct = (value) => {
    this.mudtProps.cncl_rmd_pct = value;
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "CNCL_RMD_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  cr_br_pct = (value) => {
    this.mudtProps.cr_br_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "CR_BR_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dcol_shel_pct = (value) => {
    this.mudtProps.dcol_shel_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "DCOL_SHEL_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  hul_brit_pct = (value) => {
    this.mudtProps.hul_brit_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "HUL_BRIT_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  jumbo_pct = (value) => {
    this.mudtProps.jumbo_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "JUMBO_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_pct = (value) => {
    this.mudtProps.elk_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "ELK_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_ride_pct = (value) => {
    this.mudtProps.elk_ride_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "ELK_RIDE_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_dam_pct = (value) => {
    this.mudtProps.elk_dam_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "ELK_DAM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  fm_pct = (value) => {
    this.mudtProps.fm_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "FM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_pct = (value) => {
    this.mudtProps.lsk_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "LSK_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_ride_pct = (value) => {
    this.mudtProps.lsk_ride_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "LSK_RIDE_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_fall_pct = (value) => {
    this.mudtProps.lsk_fall_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "LSK_FALL_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_dam_pct = (value) => {
    this.mudtProps.lsk_dam_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "LSK_DAM_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  moist_pct = (value) => {
    this.mudtProps.moist_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "MOIST_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  fan_pct = (value) => {
    this.mudtProps.fan_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "FAN_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ride_pscrib_pct = (value) => {
    if (this.mudtProps.ride_pscrib_pct == value) {
      return;
    }
    this.mudtProps.ride_pscrib_pct = Number(value).toFixed(1);
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      "RIDE_PSCRIB_PCT:",
      Number(value) > 100,
      false,
      RulesList["INVALID_PERCENT_RULE"]
    );
    if (Number(this.mudtProps.cln_sample) > 0) {
      this.mudtProps.ride_pscrib_gr = (
        (value / this.mudtProps.fm_sample) *
        100
      ).toFixed(GRADE_GRAM_DECIMALS);
    } else {
      this.mudtProps.ride_pscrib_gr = 0;
    }
    this.CalculateGrade();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  oth_fm = (value) => {
    this.mudtProps.oth_fm = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ok_guage = (value) => {
    this.mudtProps.ok_guage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_1_guage = (value) => {
    if (this.mudtProps.smk_1_guage == value) {
      return;
    }
    this.mudtProps.smk_1_guage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_2_guage = (value) => {
    if (this.mudtProps.smk_2_guage == value) {
      return;
    }
    this.mudtProps.smk_2_guage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_3_guage = (value) => {
    if (this.mudtProps.smk_3_guage == value) {
      return;
    }
    this.mudtProps.smk_3_guage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  smk_4_guage = (value) => {
    if (this.mudtProps.smk_4_guage == value) {
      return;
    }
    this.mudtProps.smk_4_guage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_guage = (value) => {
    this.mudtProps.lsk_guage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  gross_wt = (value) => {
    this.mudtProps.gross_wt = value;
  };

  fm_wt = (value) => {
    this.mudtProps.fm_wt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ex_moist_wt = (value) => {
    this.mudtProps.ex_moist_wt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  net_wt = (value) => {
    this.mudtProps.net_wt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  lsk_wt = (value) => {
    this.mudtProps.lsk_wt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  kern_value = (value) => {
    this.mudtProps.kern_value = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  elk_prem = (value) => {
    this.mudtProps.elk_prem = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  dam_discount = (value) => {
    this.mudtProps.dam_discount = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ex_fm_discount = (value) => {
    this.mudtProps.ex_fm_discount = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  ex_ss_discount = (value) => {
    this.mudtProps.ex_ss_discount = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  flavus_discount = (value) => {
    this.mudtProps.flavus_discount = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  net_les_lsk_val = (value) => {
    this.mudtProps.net_les_lsk_val = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  net_price = (value) => {
    this.mudtProps.net_price = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  basis_grade_amt = (value) => {
    this.mudtProps.basis_grade_amt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  freight_rate = (value) => {
    this.mudtProps.freight_rate = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  freight_amt = async (value) => {
    this.mudtProps.freight_amt = value;
    if (this.mflgLoading) {
      return;
    }
    await this.CalculateProceeds();
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  add_user = (value) => {
    this.mudtProps.add_user = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  add_date_time = (value) => {
    this.mudtProps.add_date_time = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  chg_user = (value) => {
    this.mudtProps.chg_user = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  chg_date_time = (value) => {
    this.mudtProps.chg_date_time = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  accounting_date = (value) => {
    this.mudtProps.accounting_date = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  xfer_status = (value) => {
    this.mudtProps.xfer_status = value;
    switch (value) {
      case "P":
        this.mNewStatus = TransferStatus.indexOf("Pending");
        break;
      case "S":
        this.mNewStatus = TransferStatus.indexOf("Shipped");
        break;
      case "R":
        this.mNewStatus = TransferStatus.indexOf("Received");
        break;
      case "V":
        this.mNewStatus = TransferStatus.indexOf("Voided");
        break;
    }
    if (this.mflgLoading) {
      return;
    }
    // this.mflgDirty = true;
    this.TransferStatusChanged = true;
  };

  from_seed_gen = async (value) => {
    this.mudtProps.from_seed_gen = value?.toUpperCase() == "NOT SPECIFIED" ? "" : value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  to_seed_gen = async (value) => {
    this.mudtProps.to_seed_gen = value?.toUpperCase() == "NOT SPECIFIED" ? "" : value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  from_edible_oil_ind = (value) => {
    this.mudtProps.from_edible_oil_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  to_edible_oil_ind = (value) => {
    this.mudtProps.to_edible_oil_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  from_oleic_ind = async (value) => {
    this.mudtProps.from_oleic_ind = value?.toUpperCase() == "NOT SPECIFIED" ? "" : value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  to_oleic_ind = async (value) => {
    this.mudtProps.to_oleic_ind = value?.toUpperCase() == "NOT SPECIFIED" ? "" : value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  from_pnut_variety_id = async (value) => {
    this.mudtProps.from_pnut_variety_id = value?.toUpperCase() == "NOT SPECIFIED" ? "" : value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  to_pnut_variety_id = async (value) => {
    this.mudtProps.to_pnut_variety_id = value?.toUpperCase() == "NOT SPECIFIED" ? "" : value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  transfer_type = (value) => {
    if (this.mudtProps.transfer_type == value) {
      return;
    }
    this.mudtProps.transfer_type = value;
    if (this.mflgLoading) {
      return;
    }
    if (value == "FF") {
      // Clear some values
    }
    if (value == "NL") {
      // Clear some values
    }
    this.mflgDirty = true;
    this.TransferTypeChanged = true;
  };

  pay_freight_ind = (value) => {
    this.mudtProps.pay_freight_ind = value;
    // No Freight will be paid; Clear all freight info
    if (this.mudtProps.pay_freight_ind == "N") {
      this.mudtProps.frt_accrl_complete = "N";
      this.mudtProps.freight_amt = 0;
      this.mudtProps.freight_rate = 0;
      this.mudtProps.freight_invoice = "";
      this.mudtProps.frt_deduct_amt = 0;
      this.mudtProps.frt_premium_amt = 0;
      this.mudtProps.frt_proceeds_amt = 0;
      this.mudtProps.frt_rate_basis = "";
      this.mudtProps.frt_rate_source = "";
    }
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["FREIGHT_OTHER_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.xfer_status != "P" &&
      this.mudtProps.pay_freight_ind == "O" &&
      Number(this.mudtProps.frt_other_wt) == 0 &&
      this.mudtProps.transfer_type != "NL"
    );
    this.mflgDirty = !(this.FreightAccrualCompleted() == true);
    this.PropertyChanged = true;
  };

  frt_other_wt = async (value) => {
    this.mudtProps.frt_other_wt = value;
    if (this.mflgLoading) {
      return;
    }
    mobjValid.RuleBroken(
      RulesList["FREIGHT_OTHER_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.pay_freight_ind == "O" &&
      Number(this.mudtProps.frt_other_wt) == 0 &&
      this.mudtProps.transfer_type != "NL"
    );
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  frt_accrl_complete = (value) => {
    this.mudtProps.frt_accrl_complete = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    let obj = {
      trigger: true,
      isFreightAccrualCompleted: value == "Y" ? true : false,
    };
    this.FreightAccrualStatusChanged = obj;
  };

  frt_scac_id = (value) => {
    if (this.mudtProps.frt_scac_id == value) {
      return;
    }
    this.mudtProps.frt_scac_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  frt_vendor = (value) => {
    if (this.mudtProps.frt_vendor == value) {
      return;
    }
    this.mudtProps.frt_vendor = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  state_abbr = async (value) => {
    this.mudtProps.state_abbr = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  county_id = async (value) => {
    this.mudtProps.county_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  farm_id = async (value) => {
    this.mudtProps.farm_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  farm_suffix = async (value) => {
    this.mudtProps.farm_suffix = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  freight_invoice = (value) => {
    if (this.mudtProps.freight_invoice == value) {
      return;
    }
    this.mudtProps.freight_invoice = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  trip_id = (value) => {
    this.mudtProps.trip_id = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  leg_count = (value) => {
    this.mudtProps.leg_count = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  leg_orig_buy_pt = (value) => {
    this.mudtProps.leg_orig_buy_pt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  leg_dest_buy_pt = (value) => {
    this.mudtProps.leg_dest_buy_pt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  leg_mileage = (value) => {
    this.mudtProps.leg_mileage = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  trip_weight = (value) => {
    this.mudtProps.trip_weight = value;
  };

  frt_premium_amt = async (value) => {
    this.mudtProps.frt_premium_amt = value;
    if (this.mflgLoading) {
      return;
    }
    await this.CalculateProceeds();
  };

  frt_deduct_amt = async (value) => {
    this.mudtProps.frt_deduct_amt = value;
    if (this.mflgLoading) {
      return;
    }
    await this.CalculateProceeds();
  };

  frt_proceeds_amt = async (value) => {
    this.mudtProps.frt_proceeds_amt = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  frt_rate_basis = (value) => {
    this.mudtProps.frt_rate_basis = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  frt_mileage_override = (value) => {
    this.mudtProps.frt_mileage_override = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  from_organic_ind = (value) => {
    this.mudtProps.from_organic_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  to_organic_ind = (value) => {
    this.mudtProps.to_organic_ind = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  frt_fuel_pct = (value) => {
    this.mudtProps.frt_fuel_pct = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  frt_lease_pct = (value) => {
    this.mudtProps.frt_lease_pct = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  frt_rate_source = (value) => {
    this.mudtProps.frt_rate_source = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
  };

  manual_grade_ind = async (value) => {
    this.mudtProps.manual_grade_ind = value;
    if (this.mflgLoading) {
      return;
    }
    await this.SetGramEntryMode();
    this.mflgDirty = true;
    let obj = {
      trigger: true,
      manualGradeMode: value,
    };
    this.ManualGradeStatusChanged = obj;
  };

  remark = async (value) => {
    this.mudtProps.remark = value;
    if (this.mflgLoading) {
      return;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  vendor_info = (value) => {
    this.mudtProps.vendor_info = value;
  };

  from_pnut_variety_name = (value) => {
    this.mudtProps.from_pnut_variety_name = value;
  };

  from_symbol_ind = (value) => {
    this.mudtProps.from_symbol_ind = value;
    switch (String(value).trim().toUpperCase()) {
      case "TRADEMARK":
        this.mudtProps.from_pnut_variety_name =
          String(this.mudtProps.from_pnut_variety_name).trim() + " \u2122";
        break;
      case "REGISTERED TRADEMARK":
        this.mudtProps.from_pnut_variety_name =
          String(this.mudtProps.from_pnut_variety_name).trim() + " \xAE";
        break;
      case "COPYRIGHT":
        this.mudtProps.from_pnut_variety_name =
          String(this.mudtProps.from_pnut_variety_name).trim() + " \xA9";
        break;
      default:
        this.mudtProps.from_pnut_variety_name = String(
          this.mudtProps.from_pnut_variety_name
        ).trim();
        break;
    }
  };

  to_pnut_variety_name = (value) => {
    this.mudtProps.to_pnut_variety_name = value;
  };

  to_symbol_ind = (value) => {
    this.mudtProps.to_symbol_ind = value;
    switch (String(value).trim().toUpperCase()) {
      case "TRADEMARK":
        this.mudtProps.to_pnut_variety_name =
          String(this.mudtProps.to_pnut_variety_name).trim() + " \u2122";
        break;
      case "REGISTERED TRADEMARK":
        this.mudtProps.to_pnut_variety_name =
          String(this.mudtProps.to_pnut_variety_name).trim() + " \xAE";
        break;
      case "COPYRIGHT":
        this.mudtProps.to_pnut_variety_name =
          String(this.mudtProps.to_pnut_variety_name).trim() + " \xA9";
        break;
      default:
        this.mudtProps.to_pnut_variety_name = String(
          this.mudtProps.to_pnut_variety_name
        ).trim();
        break;
    }
  };

  area_id = async (value) => {
    if (this.mudtProps.area_id == value) {
      return;
    }
    this.mudtProps.area_id = value;
    AreaInfo = await CurrentUser.GetAreaInfo(this.mudtProps.area_id);
  };

  // xfer_gram_entry_ind = (value) => {
  //   this.mudtProps.xfer_gram_entry_ind = value;
  //   this.SetGramEntryMode();
  // };

  gramentry = (value) => {
    this.mudtProps.gramentry = value;
    this.SetGramEntryMode();
  };

  manualoverride = async (value) => {
    this.mudtProps.manualoverride = value;
  };

  scac = (value) => {
    this.mudtProps.scac = value;
  };

  grade_source = (value) => {
    this.mudtProps.grade_source = value;
  };

  override_source = async (value) => {
    this.mudtProps.override_source = value;
  };

  shp_buy_info = (value) => {
    this.mudtProps.shp_buy_info = value;
  };

  rec_buy_info = (value) => {
    this.mudtProps.rec_buy_info = value;
  };

  NewStatus = (value) => {
    this.mNewStatus = value;
    this.TransferStatusChanged = true;
  };

  FANeedInfo = async (value) => {
    this.mFANeedInfo = value;
  };

  Dirty = async (value) => {
    this.mflgDirty = value;
  };

  OldTransferNumber = (value) => {
    this.msOld_Trans_Num = value;
  };

  ClearGradeInfo = async () => {
    this.mudtProps.flavus_ind = "N"; //A Flavus
    this.mudtProps.vicam_ind = "N"; //VICAM ind
    this.mudtProps.vicam_ppb = ""; //VICAM ppb
    this.mudtProps.fm_sample = ""; //FM sample
    this.mudtProps.cln_sample = ""; //Clean sample
    this.mudtProps.smk_gr = ""; //SMK
    this.mudtProps.ss_gr = ""; //Sound Splits
    this.mudtProps.ok_gr = ""; //Other Kernels
    this.mudtProps.dam_split_gr = ""; //Damage Splits
    this.mudtProps.dam_gr = ""; //Damaged
    this.mudtProps.hull_gr = ""; //Hulls
    this.mudtProps.frez_dam_gr = ""; //Freeze Damage
    this.mudtProps.pit_dam_gr = ""; //Pitting Damage
    this.mudtProps.cncl_rmd_gr = ""; //Concealed RMD
    this.mudtProps.cr_br_gr = ""; //Cracked/Broken
    this.mudtProps.dcol_shel_gr = ""; //Discolored
    this.mudtProps.hul_brit_gr = ""; //Hull Brightness
    this.mudtProps.jumbo_gr = ""; //Jumbo
    this.mudtProps.elk_gr = ""; //ELK
    this.mudtProps.elk_ride_gr = "";
    this.mudtProps.elk_dam_gr = "";
    this.mudtProps.fm_gr = ""; //Foreign material
    this.mudtProps.lsk_gr = ""; //LSK
    this.mudtProps.fan_gr = ""; //Fancy
    this.mudtProps.ride_pscrib_gr = "";
    this.mudtProps.smk_pct = ""; //SMK
    this.mudtProps.smk_1_pct = ""; //SMK 1
    this.mudtProps.smk_2_pct = ""; //SMK 2
    this.mudtProps.smk_3_pct = ""; //SMK 3
    this.mudtProps.smk_4_pct = ""; //SMK 4
    this.mudtProps.ss_pct = ""; //Sound Splits
    this.mudtProps.ok_pct = ""; //Other Kernels
    this.mudtProps.ok_ride_pct = "";
    this.mudtProps.ok_fall_pct = "";
    this.mudtProps.ok_dam_pct = "";
    this.mudtProps.dam_pct = ""; //Damaged
    this.mudtProps.tkc_pct = ""; //TKC
    this.mudtProps.hull_pct = ""; //Hulls
    this.mudtProps.frez_dam_pct = ""; //Freeze Damage
    this.mudtProps.pit_dam_pct = ""; //Pitting damage
    this.mudtProps.cncl_rmd_pct = ""; //Concealed RMD
    this.mudtProps.cr_br_pct = ""; //Cracked/Broken
    this.mudtProps.dcol_shel_pct = ""; //Discolored
    this.mudtProps.hul_brit_pct = ""; //Hull Brightness
    this.mudtProps.jumbo_pct = ""; //Jumbo
    this.mudtProps.elk_pct = ""; //ELK
    this.mudtProps.elk_ride_pct = "";
    this.mudtProps.elk_dam_pct = "";
    this.mudtProps.fm_pct = ""; //Foreign material
    this.mudtProps.lsk_pct = ""; //LSK
    this.mudtProps.lsk_ride_pct = "";
    this.mudtProps.lsk_fall_pct = "";
    this.mudtProps.lsk_dam_pct = "";
    this.mudtProps.moist_pct = ""; //Moisture
    this.mudtProps.fan_pct = ""; //Fancy
    this.mudtProps.ride_pscrib_pct = "";
    this.mudtProps.oth_fm = "";
    this.mudtProps.grade_source = "";
    this.mudtProps.override_source = "";

    this.mflgDirty = true;
  };

  CanGenerateTransferNumber = async () => {
    return (
      this.mudtProps.xfer_auto_num_ind == "Y" &&
      this.mudtProps.transfer_type == "ST" &&
      this.mudtProps.manualoverride == false
    );
  };

  CanOverrideTransferNumber = async () => {
    return (
      this.mudtProps.xfer_auto_num_ind == "Y" &&
      this.mudtProps.transfer_type == "ST"
    );
  };

  PrevTransferType = () => {
    return this.mPrevTransferType;
  };

  PrevShippingLocation = () => {
    return this.mPrevShippingLocation;
  };

  LeasedTrailerInd = () => {
    return Number(this.mudtProps.frt_lease_pct) > 0 ? "Y" : "N";
  };

  WeightLessLSK = async () => {
    if (Number(this.mudtProps.net_wt) > Number(this.mudtProps.lsk_wt)) {
      return Number(this.mudtProps.net_wt) - Number(this.mudtProps.lsk_wt);
    } else {
      return 0;
    }
  };

  WeightLessFM = async () => {
    if (Number(this.mudtProps.gross_wt) > Number(this.mudtProps.fm_wt)) {
      return Number(this.mudtProps.gross_wt) - Number(this.mudtProps.fm_wt);
    } else {
      return 0;
    }
  };

  LeasedTrailerInfo = async () => {
    if (Number(this.mudtProps.frt_lease_pct) > 0) {
      return "Leased Trailer [" + this.mudtProps.frt_lease_pct + "%]";
    } else {
      return "";
    }
  };

  FreightMiles = async () => {
    return Number(this.mudtProps.frt_mileage_override) > 0
      ? this.mudtProps.frt_mileage_override
      : this.mudtProps.leg_mileage;
  };

  IsLeasedTrailer = () => {
    return Number(this.mudtProps.frt_lease_pct) > 0;
  };

  GetBrokenRuleList = async () => {
    if (await this.isValid()) {
      return {};
    } else {
      return mobjValid.Errors();
    }
  };

  SetDirty = () => {
    this.mflgDirty = true;
  };

  WeightVariance = async () => {
    if (Number(this.mudtProps.rec_peanut_wt) != 0 && Number(this.mudtProps.shp_peanut_wt)) {
      return Number(this.mudtProps.rec_peanut_wt) - Number(this.mudtProps.shp_peanut_wt);
    } else {
      return 0;
    }
  };

  SetShippingPointInfo = async (data) => {
    if (this.mudtProps.area_id == "") {
      this.mudtProps.area_id = data.area_id;
    }
    if (this.mudtProps.shp_buy_pt == data.buy_pt_id) {
      return;
    }
    this.mudtProps.shp_buy_pt = data.buy_pt_id;
    this.mudtProps.area_id = data.area_id;
    this.mudtProps.shp_buy_info =
      data.buy_pt_name +
      " - " +
      data.physical_city +
      " - " +
      data.physical_state;
    this.mudtProps.xfer_auto_num_ind =
      data.xfer_auto_num_ind == "Y" ? "Y" : "N";
    this.mflgDirty = true;
    let obj = { trigger: true, isShipping: true, buyingPt: data.buy_pt_id };
    this.BuyingPointChanged = obj;
  };

  SetReceivingPointInfo = async (data) => {
    let Curr_Rec_Buy_Pt = this.mudtProps.rec_buy_pt;
    if (this.mudtProps.rec_buy_pt != data.buy_pt_id) {
      this.mflgDirty = true;
      this.mflgReceivingPointChanged = true;
    } else {
      this.mflgReceivingPointChanged = false;
    }
    this.mudtProps.rec_buy_pt = data.buy_pt_id;
    this.mudtProps.area_id = data.area_id;
    this.mudtProps.gramentry = data.xfer_gram_entry_ind == "Y" ? true : false;
    this.mudtProps.editmoist = data.xfer_edit_moist_pct == "Y" ? true : false;
    this.mudtProps.rec_buy_info =
      data.xfer_buy_pt_name +
      " - " +
      data.xfer_buy_pt_physical_city +
      " - " +
      data.xfer_buy_pt_physical_state;
    this.SetGramEntryMode();
    if (Curr_Rec_Buy_Pt == data.buy_pt_id) {
      return;
    }
    let obj = { trigger: true, isShipping: false, buyingPt: data.buy_pt_id };
    this.BuyingPointChanged = obj;
  };

  StatusName = async () => {
    switch (this.mNewStatus) {
      case TransferStatus.indexOf("Pending"):
        return "Pending";
      case TransferStatus.indexOf("Shipped"):
        return "Shipped";
      case TransferStatus.indexOf("Received"):
        return "Received";
      case TransferStatus.indexOf("Voided"):
        return "Void";
      default:
        return "";
    }
  };

  GetShippedSplitWeightFlag = async () => {
    return this.mudtProps.shp_split_scale == "Y" ? true : false;
  };

  GetReceivedSplitWeightFlag = async () => {
    return this.mudtProps.rec_split_scale == "Y" ? true : false;
  };

  async SetInventoryIndicator(index) {
    switch (index) {
      case "0":
        this.inventory_ind("S");
        break;
      case "1":
        this.inventory_ind("R");
        break;
      case "2":
        this.inventory_ind("O");
        break;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  }

  async SetPayFreightType(PayfFeightType) {
    switch (Number(PayfFeightType)) {
      case PayFreightType.indexOf("ShippedWeight"):
        this.pay_freight_ind("S");
        break;
      case PayFreightType.indexOf("ReceivedWeight"):
        this.pay_freight_ind("R");
        break;
      case PayFreightType.indexOf("OtherWeight"):
        this.pay_freight_ind("O");
        break;
      case PayFreightType.indexOf("NoFreight"):
        this.pay_freight_ind("N");
        break;
    }
    this.mflgDirty = true;
    this.PropertyChanged = true;
  }

  async GetPayFreightType() {
    switch (this.mudtProps.pay_freight_ind) {
      case "S":
        return PayFreightType.indexOf("ShippedWeight");
      case "R":
        return PayFreightType.indexOf("ReceivedWeight");
      case "O":
        return PayFreightType.indexOf("OtherWeight");
      case "N":
        return PayFreightType.indexOf("NoFreight");
    }
  }

  async ReceivingPointChanged() {
    return this.mflgReceivingPointChanged;
  }

  async EnableTagList(TagList, Tag) {
    return String(TagList).toUpperCase().indexOf(String(Tag).toUpperCase()) >= 0;
  }

  async SetTransferType(index) {
    this.mPrevTransferType = this.mudtProps.transfer_type;
    switch (index) {
      case "0":
        this.transfer_type("ST");
        break;
      case "1":
        this.transfer_type("FF");
        break;
      case "2":
        this.transfer_type("NL");
        break;
    }
  }

  async FreightAccrualWeight() {
    switch (this.mudtProps.pay_freight_ind) {
      case "S":
        return this.mudtProps.shp_peanut_wt;
      case "R":
        return this.mudtProps.rec_peanut_wt;
      case "O":
        return this.mudtProps.frt_other_wt;
      case "N":
        return 0;
    }
  }

  async IsNew() {
    return this.mflgNew;
  }

  async IsDirty() {
    return this.mflgDirty;
  }

  async TotalSmkAndSplitPct() {
    return (
      Number(this.RoundOff(Number(this.mudtProps.smk_pct), 1)) +
      Number(this.RoundOff(Number(this.mudtProps.ss_pct), 1))
    ).toFixed(GRADE_PCT_FORMAT);
  }

  async TotalKernelsPct() {
    return (
      Number(await this.TotalSmkAndSplitPct()) +
      Number(this.RoundOff(Number(this.mudtProps.ok_pct), 1)) +
      Number(this.RoundOff(Number(this.mudtProps.dam_pct), 1))
    ).toFixed(GRADE_PCT_FORMAT);
  }

  async TotalKernelsAndHullsPct() {
    return (
      Number(await this.TotalKernelsPct()) +
      Number(this.RoundOff(Number(this.mudtProps.hull_pct), 1))
    ).toFixed(GRADE_PCT_FORMAT);
  }

  RoundOff(value, decimal = 0) {
    let calValue = value != "" ? Number(Number(value).toFixed(1)).toFixed(2) : value
    return calValue
  }

  VoidUnvoid = async (voidIt) => {
    let dataObj = {
      comp_id: this.mudtProps.comp_id,
      crop_year: String(this.mudtProps.crop_year),
      transfer_num: this.mudtProps.transfer_num,
      transfer_type: this.mudtProps.transfer_type,
      shp_buy_pt: this.mudtProps.shp_buy_pt,
      chg_date_time: this.mudtProps.chg_date_time,
      chg_user: this.mudtProps.chg_user,
      void_flag: voidIt ? "1" : "0",
    };
    this.CancelEdit();
    let response = await StockTransferService.UpdateTransferHeaderVoidUnvoidDetails(dataObj);
    if (response.status !== 200) {
      this.mDbError = response.message
      return false;
    }
    //Check folowing after response
    let data = response.result

    data = data.split("|")

    for (let i = 0; i < data.length; i++) {
      if (data[i].includes("chg_date_time")) {
        this.chg_date_time(data[i].split(":")[1] + ":" + data[i].split(":")[2] + ":" + data[i].split(":")[3])
      }
      if (data[i].includes("xfer_status")) {
        this.xfer_status(data[i].split(":")[1])
      }
    }
    this.mflgDirty = false;
    return true;
  };

  ReverseFreightAccrualOld = async () => {
    if (Number(this.mudtProps.trip_id) > 0) {
      const confirmBox = window.confirm(
        "This will Reverse Freight Accrual for all Transfers on this Multi-Trip!\r\nDo you want to continue?"
      );
      if (confirmBox == false) {
        return;
      }
    }
    let reponse = await StockTransferService.RemoveFreightAccural(
      this.mudtProps.shp_buy_pt,
      this.mudtProps.transfer_type,
      this.mudtProps.transfer_num,
      this.mudtProps.trip_id,
      this.mudtProps.crop_year
    );
    //Error handling Pending
    //     If CBool(GetInside(lstrxml, "<successful>", "</successful>")) = False Then

    //     MsgBox "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.", vbExclamation, "Reverse Freight Accrual"
    //     Exit Sub
    // End If
    this.mudtProps.freight_amt = 0;
    this.mudtProps.freight_invoice = "";
    this.mudtProps.freight_rate = 0;
    this.mudtProps.frt_fuel_pct = 0;
    this.mudtProps.frt_rate_basis = "";
    this.mudtProps.frt_rate_source = "";
    this.mudtProps.frt_proceeds_amt = 0;
    this.frt_accrl_complete("N");
  };

  CalculatePeanutValueOld = async () => {
    let dWtInclVeh, dWtVeh;
    switch (this.mudtProps.inventory_ind) {
      case "R":
        dWtInclVeh = this.mudtProps.rec_tare_wt;
        dWtVeh = this.mudtProps.rec_vehicle_wt;
        break;
      case "S":
        dWtInclVeh = this.mudtProps.shp_tare_wt;
        dWtVeh = this.mudtProps.shp_vehicle_wt;
        break;
      case "O":
        dWtInclVeh = this.mudtProps.other_wt;
        dWtVeh = 0;
        break;
    }

    let response = await SettlementService.RetrieveValueCalculationList(
      this.mudtProps.area_id == "" ? null : this.mudtProps.area_id,
      this.mudtProps.pnut_type_id == "" ? null : this.mudtProps.pnut_type_id,
      this.mudtProps.cr_br_pct,
      this.mudtProps.dcol_shel_pct,
      this.mudtProps.fan_pct,
      this.mudtProps.dam_pct,
      this.mudtProps.ss_pct,
      this.mudtProps.vicam_ppb,
      this.mudtProps.fm_pct,
      this.mudtProps.smk_pct,
      this.mudtProps.ss_pct,
      this.mudtProps.ok_pct,
      this.mudtProps.elk_pct,
      this.mudtProps.lsk_pct,
      this.mudtProps.moist_pct,
      dWtInclVeh,
      dWtVeh,
      this.mudtProps.fm_pct
    );

    this.mudtProps.basis_grade_amt = response[0].total_value_of_load;
    this.mudtProps.net_wt = response[0].net_weight;
    this.mudtProps.fm_wt = response[0].foreign_material_weight;
    this.mudtProps.lsk_wt = response[0].lsk_weight;
    this.mudtProps.ex_moist_wt = response[0].excess_moisture_weight;
    this.mudtProps.kern_value = response[0].kernel_value_dollars_per_ton;
    this.mudtProps.elk_prem = response[0].elk_premium_dollars_per_ton;
    this.mudtProps.dam_discount = response[0].damaged_discount_dollars_per_ton;
    this.mudtProps.ex_fm_discount =
      response[0].excess_fm_discount_dollars_per_ton;
    this.mudtProps.ex_ss_discount =
      response[0].excess_splits_discount_dollars_per_ton;
    this.mudtProps.net_les_lsk_val =
      response[0].net_value_per_ton_excluding_lsk;

    if (Number(this.mudtProps.net_wt) > 0) {
      this.mudtProps.net_price = this.RoundOff(
        this.mudtProps.basis_grade_amt / this.mudtProps.net_wt,
        7
      );
    } else {
      this.mudtProps.net_price = 0;
    }

    if (Number(this.mudtProps.tkc_pct) == 0) {
      this.mudtProps.tkc_pct = "0" + String(response[0].total_kernel_content);
    }
    this.PeanutValueChanged = true;
  };

  CheckBusinessRules = async () => {
    let areaInfoTemp = new clsArea()
    if (AreaInfo.mudtProps.area_id == "") {
      if (this.mudtProps.area_id == "") {
        alert("Area id is empty");
      }
      if (this.mudtProps.area_id != "") {
        areaInfoTemp = await CurrentUser.GetAreaInfo(this.mudtProps.area_id);
        AreaInfo = areaInfoTemp
      }
    }
    else {
      areaInfoTemp = AreaInfo
    }
    mobjValid.RuleBroken(
      RulesList["SHIP_DATE_IS_REQUIRED_RULE"],
      (this.mNewStatus == TransferStatus.indexOf("Received") &&
      !moment(this.mudtProps.shp_date).isValid())||(this.mNewStatus == TransferStatus.indexOf("Pending") &&
      this.mudtProps.shp_scale_tick != ""&& this.mudtProps.shp_peanut_wt!=""&&
      !moment(this.mudtProps.shp_date).isValid()),
      false
    );
    mobjValid.RuleBroken(
      RulesList["SHIP_DATE_IS_INVALID"],
      this.mNewStatus == TransferStatus.indexOf("Shipped") &&
      !moment(this.mudtProps.shp_date),
      false
    );

    let totalKernelsAndHullsPct = await this.TotalKernelsAndHullsPct()
    mobjValid.RuleBroken(
      RulesList["TOTAL_KERNELS_AND_HULLS_OUT_OF_RANGE_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      (Number(totalKernelsAndHullsPct) <
        Number(areaInfoTemp.mudtProps.min_kern_hull_pct) ||
        Number(totalKernelsAndHullsPct) >
        Number(areaInfoTemp.mudtProps.max_kern_hull_pct))
    );
    mobjValid.RuleBroken(
      RulesList[
      "ELK_RIDING_SCREEN_MINUS_TOTAL_ELK_DAMAGE_NOT_EQUAL_NET_ELK_RULE"
      ],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      Number(this.RoundOff(this.mudtProps.elk_ride_gr, 1)) +
      Number(this.RoundOff(this.mudtProps.elk_dam_gr, 1)) >
      0 &&
      Number(this.RoundOff(this.mudtProps.elk_ride_gr, 1)) -
      Number(this.RoundOff(this.mudtProps.elk_dam_gr, 1)) !=
      Number(this.RoundOff(this.mudtProps.elk_gr, 1))
    );
    mobjValid.RuleBroken(
      RulesList["LSK_MUST_TOTAL_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      Number(this.RoundOff(this.mudtProps.lsk_ride_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.lsk_fall_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.lsk_dam_pct, 1)) >
      0 &&
      Number(this.RoundOff(this.mudtProps.lsk_ride_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.lsk_fall_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.lsk_dam_pct, 1)) !=
      Number(this.RoundOff(this.mudtProps.lsk_pct, 1))
    );
    mobjValid.RuleBroken(
      RulesList["SMK1_THROUGH_SMK4_MUST_TOTAL_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      Number(this.RoundOff(this.mudtProps.smk_1_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.smk_2_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.smk_3_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.smk_4_pct, 1)) >
      0 &&
      Math.abs(
        this.RoundOff(
          Math.abs(
            Number(this.RoundOff(this.mudtProps.smk_1_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.smk_2_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.smk_3_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.smk_4_pct, 1)) -
            Number(this.RoundOff(this.mudtProps.smk_pct, 1))
          ),
          1
        )
      ) > 1,
      false
    );
    mobjValid.RuleBroken(
      RulesList["OK_BALANCE_RULE"],
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.transfer_type == "ST" &&
      Number(this.RoundOff(this.mudtProps.ok_ride_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.ok_fall_pct, 1)) +
      Number(this.RoundOff(this.mudtProps.ok_dam_pct, 1)) >
      0 &&
      this.RoundOff(
        Math.abs(
          Number(this.RoundOff(this.mudtProps.ok_pct, 1)) -
          (Number(this.RoundOff(this.mudtProps.ok_ride_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.ok_fall_pct, 1)) +
            Number(this.RoundOff(this.mudtProps.ok_dam_pct, 1)))
        ),
        1
      ) > 0
    );
    mobjValid.RuleBroken(
      RulesList["SHIPPING_LOCATION_IS_REQUIRED_RULE"],
      this.mudtProps.shp_buy_pt == "",
      false
    );
    mobjValid.RuleBroken(
      RulesList["SHIPPED_BIN_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.shp_bin == "",
      false
    );
    mobjValid.RuleBroken(
      RulesList["SHIPPED_SCALE_TICKET_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mudtProps.shp_scale_tick == "",
      false
    );
    mobjValid.RuleBroken(
      RulesList["SHIPPED_GROSS_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      Number(this.mudtProps.shp_tare_wt) == 0,
      false
    );

    mobjValid.RuleBroken(
      RulesList["SHIPPED_TARE_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.shp_vehicle_wt == 0,
      false
    );
    mobjValid.RuleBroken(
      RulesList["SHIPPED_TARE_WEIGHT_SHOULD_BE_LESS_THEN_GROSS_WEIGHT_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      Number(this.mudtProps.shp_vehicle_wt) > 0 &&
      Number(this.mudtProps.shp_tare_wt) <= Number(this.mudtProps.shp_vehicle_wt),
      false
    );
    mobjValid.RuleBroken(
      RulesList["DISPATCH_NUMBER_IS_REQUIRED_FOR_NO_LOADS"],
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.transfer_type == "NL" &&
      String(this.mudtProps.dispatch_num).trim() == "",
      false
    );
    mobjValid.RuleBroken(
      RulesList["FREIGHT_OTHER_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.xfer_status != "P" &&
      this.mudtProps.pay_freight_ind == "O" &&
      Number(this.mudtProps.frt_other_wt) == 0 &&
      this.mudtProps.transfer_type != "NL"
    );
    mobjValid.RuleBroken(
      RulesList["OTHER_INVENTORY_UPDATE_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.inventory_ind == "O" &&
      this.mudtProps.other_wt == "0",
      false
    );

    let currentDate = moment(Date.now());
    let yearStartDate = moment("7/1/" + cropYearFromLS());
    let shpDate = moment(this.mudtProps.shp_date);
    let recDate = moment(this.mudtProps.rec_date);

    if (moment(this.mudtProps.shp_date).isValid()) {

      const diffCurrentDays = currentDate.diff(shpDate, 'days')
      const diffStartDays = shpDate.diff(yearStartDate, 'days')


      mobjValid.RuleBroken(
        RulesList["SHIP_DATE_LATER_THAN_TODAY_RULE"],
        diffCurrentDays < 0
      );
      mobjValid.RuleBroken(
        RulesList["SHIPPED_DATE_EARLIER_THAN_START_CROP_YEAR"],
        diffStartDays < 0,
        false,
        yearStartDate.format("MM/DD/YYYY")
      );
    }

    if (moment(this.mudtProps.rec_date).isValid()) {

      const diffCurrentDays = currentDate.diff(recDate, 'days')
      const diffStartDays = recDate.diff(yearStartDate, 'days')

      mobjValid.RuleBroken(
        RulesList["RECEIVED_DATE_LATER_THAN_TODAY_RULE"],
        diffCurrentDays < 0,
        false
      );
      mobjValid.RuleBroken(
        RulesList["RECEIVED_DATE_EARLIER_THAN_START_CROP_YEAR"],
        diffStartDays < 0,
        false,
        yearStartDate.format("MM/DD/YYYY")
      );
    }

    if (
      moment(this.mudtProps.shp_date).isValid() &&
      moment(this.mudtProps.rec_date).isValid()
    ) {
      const diffEarlyDays = shpDate.diff(recDate, 'days')

      mobjValid.RuleBroken(
        RulesList["RECEIVED_DATE_EARLIER_THAN_SHIP_DATE"],
        diffEarlyDays > 0,
        false
      );

      const diffGreaterDays = recDate.diff(shpDate, 'days')

      mobjValid.RuleBroken(
        RulesList["SHIP_RECEIVED_DAYS_GREATER_THAN_RANGE"],
        diffGreaterDays > this.miMaxDateRange,
        false,
        this.miMaxDateRange + " days"
      );
    }

    mobjValid.RuleBroken(
      RulesList["RECEIVED_DATE_IS_REQUIRED_RULE"],
      (this.mNewStatus == (TransferStatus.indexOf("Shipped"))  && 
      !moment(this.mudtProps.rec_date).isValid() &&
      this.mudtProps.rec_scale_tick!=""),
      false
      );

    mobjValid.RuleBroken(
      RulesList["RECEIVED_BUYING_POINT_IS_REQUIRED_RULE"],
      (this.mudtProps.transfer_type != "NL" &&
        this.mNewStatus != TransferStatus.indexOf("Pending") &&
        this.mudtProps.rec_buy_pt == "") ||
      (this.mudtProps.transfer_type == "NL" &&
        this.mNewStatus == TransferStatus.indexOf("Received") &&
        this.mudtProps.rec_buy_pt == "")
    );
    mobjValid.RuleBroken(
      RulesList["FARM_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "FF" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      this.mudtProps.farm_id == "" &&
      this.mudtProps.farm_suffix == ""
    );
    mobjValid.RuleBroken(
      RulesList["RECEIVED_BIN_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type == "ST" &&
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.rec_bin == ""
    );
    mobjValid.RuleBroken(
      RulesList["RECEIVED_SCALE_TICKET_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type != "NL" &&
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.rec_scale_tick == "",
      false
    );
    mobjValid.RuleBroken(
      RulesList["RECEIVED_GROSS_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type != "NL" &&
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.rec_tare_wt == 0,
      false
    );

    mobjValid.RuleBroken(
      RulesList["RECEIVED_TARE_WEIGHT_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type != "NL" &&
      this.mNewStatus == TransferStatus.indexOf("Received") &&
      this.mudtProps.rec_vehicle_wt == 0,
      false
    );
    mobjValid.RuleBroken(
      RulesList["RECEIVED_TARE_WEIGHT_SHOULD_BE_LESS_THEN_GROSS_WEIGHT_RULE"],
      this.mudtProps.transfer_type != "NL" &&
      this.mNewStatus != TransferStatus.indexOf("Pending") &&
      Number(this.mudtProps.rec_tare_wt) > 0 &&
      Number(this.mudtProps.rec_tare_wt) <= Number(this.mudtProps.rec_vehicle_wt),
      false
    );

    //Peanut id and seg are required for FF and NL
    mobjValid.RuleBroken(
      RulesList["PEANUT_TYPE_IS_REQUIRED_RULE"],
      this.mudtProps.transfer_type != "ST" &&
      (this.mNewStatus != TransferStatus.indexOf("Pending") ||
        this.mudtProps.xfer_status != "P") &&
      (await this.FreightAccrualCompleted()) == false &&
      this.mudtProps.pnut_type_id == "",
      false
    );
    // mobjValid.RuleBroken(
    //   RulesList["SEGMENT_TYPE_IS_REQUIRED_RULE"],
    //   this.mudtProps.transfer_type != "ST" &&
    //     (this.mNewStatus != TransferStatus.indexOf("Pending") ||
    //       this.mudtProps.xfer_status != "P") &&
    //     (await this.FreightAccrualCompleted()) == false &&
    //     this.mudtProps.seg_type == "",
    //   false
    // );
  };

  SetShippingBinInfo = async (bin, PeanutType, bGradeOnly = false) => {
    if ((bin.mudtProps.PNUT_TYPE_ID != this.mudtProps.pnut_type_id && this.mudtProps.pnut_type_id != "") || (bin.mudtProps.SEG_TYPE != this.mudtProps.seg_type && this.mudtProps.seg_type != "") || (bin.mudtProps.ORGANIC_IND != this.mudtProps.to_organic_ind && this.mudtProps.to_organic_ind != "")) {
      this.mudtProps.rec_bin = ""
      this.mudtProps.rec_whouse = ""
      this.mudtProps.to_edible_oil_ind = ""
      this.mudtProps.to_oleic_ind = ""
      this.mudtProps.to_organic_ind = ""
      this.mudtProps.to_pnut_variety_id = ""
      this.mudtProps.to_seed_gen = ""
    }
    if (PeanutType == undefined || PeanutType == null || PeanutType == "") {
      this.mudtProps.pnut_type_id = ""
      this.mudtProps.smk_1_guage = ""
      this.mudtProps.smk_2_guage = ""
      this.mudtProps.smk_3_guage = ""
      this.mudtProps.smk_4_guage = ""
      this.mudtProps.ok_guage = ""
      this.mudtProps.lsk_guage = ""
    }
    else {
      this.mudtProps.pnut_type_id = PeanutType.pnut_type_id
      this.mudtProps.smk_1_guage = PeanutType.smk_1_guage
      this.mudtProps.smk_2_guage = PeanutType.smk_2_guage
      this.mudtProps.smk_3_guage = PeanutType.smk_3_guage
      this.mudtProps.smk_4_guage = PeanutType.smk_4_guage
      this.mudtProps.ok_guage = PeanutType.ok_guage
      this.mudtProps.lsk_guage = PeanutType.lsk_guage
    }
    this.mudtProps.pnut_variety_id = bin.mudtProps.PNUT_VARIETY_ID
    this.mudtProps.from_pnut_variety_name = bin.mudtProps.PNUT_VARIETY_NAME
    this.mudtProps.shp_whouse = bin.mudtProps.WHOUSE_NUM
    this.mudtProps.seg_type = bin.mudtProps.SEG_TYPE
    this.mudtProps.from_organic_ind = bin.mudtProps.ORGANIC_IND
    this.mudtProps.from_edible_oil_ind = bin.mudtProps.EDIBLE_OIL_IND

    if (bGradeOnly == false) {
      this.mudtProps.from_pnut_variety_id = bin.mudtProps.PNUT_VARIETY_ID
      this.mudtProps.from_seed_gen = bin.mudtProps.SEED_GEN
      this.mudtProps.from_oleic_ind = bin.mudtProps.OLEIC_IND
    }

    if (this.mudtProps.manual_grade_ind != "Y") {
      if (await bin.HasGrades()) {
        this.mudtProps.flavus_ind = String(bin.mudtProps.FLAVUS_IND)
        this.mudtProps.vicam_ind = String(bin.mudtProps.WA_VICAM_IND)
        this.mudtProps.vicam_ppb = this.RoundOff(bin.mudtProps.WA_VICAM_PPB, 0)
        this.mudtProps.fm_sample = this.RoundOff(bin.mudtProps.WA_FM_SAMPLE, 1)
        this.mudtProps.cln_sample = this.RoundOff(bin.mudtProps.WA_CLN_SAMPLE, 1)
        this.mudtProps.smk_gr = this.RoundOff(bin.mudtProps.WA_SMK_GR, 1)
        this.mudtProps.ss_gr = this.RoundOff(bin.mudtProps.WA_SS_GR, 1)
        this.mudtProps.ok_gr = this.RoundOff(bin.mudtProps.WA_OK_GR, 1)
        this.mudtProps.dam_split_gr = this.RoundOff(bin.mudtProps.WA_DAM_SPLIT_GR, 1)
        this.mudtProps.dam_gr = this.RoundOff(bin.mudtProps.WA_DAM_GR, 1)
        this.mudtProps.hull_gr = this.RoundOff(bin.mudtProps.WA_HULL_GR, 1)
        this.mudtProps.frez_dam_gr = this.RoundOff(bin.mudtProps.WA_FREZ_DAM_GR, 1)
        this.mudtProps.pit_dam_gr = this.RoundOff(bin.mudtProps.WA_PIT_DAM_GR, 1)
        this.mudtProps.cncl_rmd_gr = this.RoundOff(bin.mudtProps.WA_CNCL_RMD_GR, 1)
        this.mudtProps.cr_br_gr = this.RoundOff(bin.mudtProps.WA_CR_BR_GR, 1)
        this.mudtProps.dcol_shel_gr = this.RoundOff(bin.mudtProps.WA_DCOL_SHEL_GR, 1)
        this.mudtProps.hul_brit_gr = this.RoundOff(bin.mudtProps.WA_HUL_BRIT_GR, 1)
        this.mudtProps.jumbo_gr = this.RoundOff(bin.mudtProps.WA_JUMBO_GR, 1)
        this.mudtProps.elk_gr = this.RoundOff(bin.mudtProps.WA_ELK_GR, 1)
        this.mudtProps.fm_gr = this.RoundOff(bin.mudtProps.WA_FM_GR, 1)
        this.mudtProps.lsk_gr = this.RoundOff(bin.mudtProps.WA_LSK_GR, 1)
        this.mudtProps.fan_gr = this.RoundOff(bin.mudtProps.WA_FAN_GR, 1)
        this.mudtProps.smk_pct = this.RoundOff(bin.mudtProps.WA_SMK_PCT, 1)
        this.mudtProps.smk_1_guage = String(bin.mudtProps.WA_SMK_1_GUAGE)
        this.mudtProps.smk_1_pct = this.RoundOff(bin.mudtProps.WA_SMK_1_PCT, 1)
        this.mudtProps.smk_2_guage = String(bin.mudtProps.WA_SMK_2_GUAGE)
        this.mudtProps.smk_2_pct = this.RoundOff(bin.mudtProps.WA_SMK_2_PCT, 1)
        this.mudtProps.smk_3_guage = String(bin.mudtProps.WA_SMK_3_GUAGE)
        this.mudtProps.smk_3_pct = this.RoundOff(bin.mudtProps.WA_SMK_3_PCT, 1)
        this.mudtProps.smk_4_guage = String(bin.mudtProps.WA_SMK_4_GUAGE)
        this.mudtProps.smk_4_pct = this.RoundOff(bin.mudtProps.WA_SMK_4_PCT, 1)
        this.mudtProps.ss_pct = this.RoundOff(bin.mudtProps.WA_SS_PCT, 1)
        this.mudtProps.ok_pct = this.RoundOff(bin.mudtProps.WA_OK_PCT, 1)
        this.mudtProps.dam_pct = this.RoundOff(bin.mudtProps.WA_DAM_PCT, 1)
        this.mudtProps.tkc_pct = this.RoundOff(bin.mudtProps.WA_TKC_PCT, 1)
        this.mudtProps.hull_pct = this.RoundOff(bin.mudtProps.WA_HULL_PCT, 1)
        this.mudtProps.frez_dam_pct = this.RoundOff(bin.mudtProps.WA_FREZ_DAM_PCT, 1)
        this.mudtProps.pit_dam_pct = this.RoundOff(bin.mudtProps.WA_PIT_DAM_PCT, 1)
        this.mudtProps.cncl_rmd_pct = this.RoundOff(bin.mudtProps.WA_CNCL_RMD_PCT, 1)
        this.mudtProps.cr_br_pct = this.RoundOff(bin.mudtProps.WA_CR_BR_PCT, 1)
        this.mudtProps.dcol_shel_pct = this.RoundOff(bin.mudtProps.WA_DCOL_SHEL_PCT, 1)
        this.mudtProps.hul_brit_pct = this.RoundOff(bin.mudtProps.WA_HUL_BRIT_PCT, 1)
        this.mudtProps.jumbo_pct = this.RoundOff(bin.mudtProps.WA_JUMBO_PCT, 1)
        this.mudtProps.elk_pct = this.RoundOff(bin.mudtProps.WA_ELK_PCT, 1)
        this.mudtProps.fm_pct = this.RoundOff(bin.mudtProps.WA_FM_PCT, 1)
        this.mudtProps.lsk_pct = this.RoundOff(bin.mudtProps.WA_LSK_PCT, 1)
        this.mudtProps.moist_pct = this.RoundOff(bin.mudtProps.WA_MOIST_PCT, 1)
        this.mudtProps.fan_pct = this.RoundOff(bin.mudtProps.WA_FAN_PCT, 1)
        this.mudtProps.grade_source = String(bin.mudtProps.GRADE_SOURCE)
        this.mudtProps.override_source = String(bin.mudtProps.OVERRIDE_SOURCE)
      }
      else {
        this.mudtProps.flavus_ind = "N"
        this.mudtProps.vicam_ind = "N"
        this.mudtProps.vicam_ppb = 0
        this.mudtProps.fm_sample = 0
        this.mudtProps.cln_sample = 0
        this.mudtProps.smk_gr = 0
        this.mudtProps.ss_gr = 0
        this.mudtProps.ok_gr = 0
        this.mudtProps.dam_split_gr = 0
        this.mudtProps.dam_gr = 0
        this.mudtProps.hull_gr = 0
        this.mudtProps.frez_dam_gr = 0
        this.mudtProps.pit_dam_gr = 0
        this.mudtProps.cncl_rmd_gr = 0
        this.mudtProps.cr_br_gr = 0
        this.mudtProps.dcol_shel_gr = 0
        this.mudtProps.hul_brit_gr = 0
        this.mudtProps.jumbo_gr = 0
        this.mudtProps.elk_gr = 0
        this.mudtProps.elk_ride_gr = 0
        this.mudtProps.elk_dam_gr = 0
        this.mudtProps.fm_gr = 0
        this.mudtProps.lsk_gr = 0
        this.mudtProps.fan_gr = 0
        this.mudtProps.ride_pscrib_gr = 0
        this.mudtProps.smk_pct = 0
        this.mudtProps.smk_1_pct = 0
        this.mudtProps.smk_2_pct = 0
        this.mudtProps.smk_3_pct = 0
        this.mudtProps.smk_4_pct = 0
        this.mudtProps.ss_pct = 0
        this.mudtProps.ok_pct = 0
        this.mudtProps.ok_ride_pct = 0
        this.mudtProps.ok_fall_pct = 0
        this.mudtProps.ok_dam_pct = 0
        this.mudtProps.dam_pct = 0
        this.mudtProps.tkc_pct = 0
        this.mudtProps.hull_pct = 0
        this.mudtProps.frez_dam_pct = 0
        this.mudtProps.pit_dam_pct = 0
        this.mudtProps.cncl_rmd_pct = 0
        this.mudtProps.cr_br_pct = 0
        this.mudtProps.dcol_shel_pct = 0
        this.mudtProps.hul_brit_pct = 0
        this.mudtProps.jumbo_pct = 0
        this.mudtProps.elk_pct = 0
        this.mudtProps.elk_ride_pct = 0
        this.mudtProps.elk_dam_pct = 0
        this.mudtProps.fm_pct = 0
        this.mudtProps.lsk_pct = 0
        this.mudtProps.lsk_ride_pct = 0
        this.mudtProps.lsk_fall_pct = 0
        this.mudtProps.lsk_dam_pct = 0
        this.mudtProps.moist_pct = 0
        this.mudtProps.fan_pct = 0
        this.mudtProps.ride_pscrib_pct = 0
        this.mudtProps.oth_fm = ""
        this.mudtProps.grade_source = ""
        this.mudtProps.override_source = ""
      }
    }
    this.mflgDirty = true;
    this.shp_bin(bin.mudtProps.BIN_NUM)
  }

  SetReceivingBinInfo = async (bin) => {
    this.mudtProps.rec_bin = bin.mudtProps.BIN_NUM
    this.mudtProps.rec_whouse = bin.mudtProps.WHOUSE_NUM
    this.mudtProps.to_pnut_variety_id = bin.mudtProps.PNUT_VARIETY_ID
    this.mudtProps.to_pnut_variety_name = bin.mudtProps.PNUT_VARIETY_NAME
    this.mudtProps.to_oleic_ind = bin.mudtProps.OLEIC_IND
    this.mudtProps.to_organic_ind = bin.mudtProps.ORGANIC_IND
    this.mudtProps.to_edible_oil_ind = bin.mudtProps.EDIBLE_OIL_IND
    this.mudtProps.to_seed_gen = bin.mudtProps.SEED_GEN
    this.mudtProps.seed_gen = bin.mudtProps.SEED_GEN

    this.mflgDirty = true;
    this.PropertyChanged = true;
  };

  NewSerialize = () => {
    let dataObj = {
      "comp_id": this.mudtProps.comp_id?.toString(),
      "crop_year": this.mudtProps.crop_year?.toString(),
      "shp_buy_pt": this.mudtProps.shp_buy_pt?.toString(),
      "rec_buy_pt": this.mudtProps.rec_buy_pt?.toString(),
      "dest_buy_pt": this.mudtProps.dest_buy_pt?.toString() || "",  ///////////////////
      "trip_id": this.mudtProps.trip_id?.toString() == "" ? "0" : this.mudtProps.trip_id?.toString(),
      "transfer_num": this.mudtProps.transfer_num?.toString(),
      "transfer_type": this.mudtProps.transfer_type?.toString(),
      "xfer_status": this.mudtProps.xfer_status?.toString(),
      "chg_date_time": moment(this.mudtProps.chg_date_time).format("MM/DD/YYYY h:mm:ss A"),
      "chg_user": this.mudtProps.chg_user?.toString(),
      "add_user": this.mudtProps.add_user?.toString(),
      "rec_date": this.mudtProps.rec_date == "" ? "" : moment(this.mudtProps.rec_date).format("MM/DD/YYYY"),
      "frt_accrl_complete": this.mudtProps.frt_accrl_complete?.toString(),
      "frt_scac_id": this.mudtProps.frt_scac_id?.toString(),
      "frt_mileage_override": this.mudtProps.frt_mileage_override?.toString() == "" ? "0" : this.mudtProps.frt_mileage_override?.toString(),
      "shp_date": this.mudtProps.shp_date == "" ? "" : moment(this.mudtProps.shp_date).format("MM/DD/YYYY"),
      "state_abbr": this.mudtProps.state_abbr?.toString(),
      "county_id": this.mudtProps.county_id?.toString(),
      "farm_id": this.mudtProps.farm_id?.toString(),
      "farm_suffix": this.mudtProps.farm_suffix?.toString(),
      "frt_other_wt": this.mudtProps.frt_other_wt?.toString() == "" ? "0" : this.mudtProps.frt_other_wt?.toString(),
      "pay_freight_ind": this.mudtProps.pay_freight_ind?.toString(),
      "leg_mileage": this.mudtProps.leg_mileage?.toString() == "" ? "0" : this.mudtProps.leg_mileage?.toString(),
      "grade_source": this.mudtProps.grade_source?.toString(),
      "original_xfer": this.mudtProps.original_xfer?.toString(),
      "load_num": this.mudtProps.load_num?.toString() == "" ? "0" : this.mudtProps.load_num?.toString(),
      "dispatch_num": this.mudtProps.dispatch_num?.toString(),
      "vehichle_num": this.mudtProps.vehichle_num?.toString(),
      "truck_line": Buffer.from(this.mudtProps.truck_line?.toString()).toString('base64'), //this.mudtProps.truck_line?.toString(),
      "driver_name": this.mudtProps.driver_name?.toString(),
      "shp_whouse": this.mudtProps.shp_whouse?.toString(),
      "shp_bin": this.mudtProps.shp_bin?.toString(),
      "rec_whouse": this.mudtProps.rec_whouse?.toString(),
      "rec_bin": this.mudtProps.rec_bin?.toString(),
      "pnut_variety_id": this.mudtProps.pnut_variety_id?.toString(),
      "seed_gen": this.mudtProps.seed_gen?.toString(),
      "edible_oil_ind": this.mudtProps.edible_oil_ind?.toString(),
      "oleic_ind": this.mudtProps.oleic_ind?.toString(),
      "corn_ind": this.mudtProps.corn_ind?.toString(),
      "flavus_ind": this.mudtProps.flavus_ind?.toString(),
      "vicam_ind": this.mudtProps.vicam_ind?.toString(),
      "vicam_ppb": this.mudtProps.vicam_ppb?.toString() == "" ? 0 : Number(Number(this.mudtProps.vicam_ppb?.toString()).toFixed(0)),
      "fm_sample": this.mudtProps.fm_sample?.toString() == "" ? "0" : this.mudtProps.fm_sample?.toString(),
      "cln_sample": this.mudtProps.cln_sample?.toString() == "" ? "0" : this.mudtProps.cln_sample?.toString(),
      "smk_gr": this.mudtProps.smk_gr?.toString() == "" ? "0" : this.mudtProps.smk_gr?.toString(),
      "ss_gr": this.mudtProps.ss_gr?.toString() == "" ? "0" : this.mudtProps.ss_gr?.toString(),
      "ok_gr": this.mudtProps.ok_gr?.toString() == "" ? "0" : this.mudtProps.ok_gr?.toString(),
      "dam_split_gr": this.mudtProps.dam_split_gr?.toString() == "" ? "0" : this.mudtProps.dam_split_gr?.toString(),
      "dam_gr": this.mudtProps.dam_gr?.toString() == "" ? "0" : this.mudtProps.dam_gr?.toString(),
      "hull_gr": this.mudtProps.hull_gr?.toString() == "" ? "0" : this.mudtProps.hull_gr?.toString(),
      "frez_dam_gr": this.mudtProps.frez_dam_gr?.toString() == "" ? "0" : this.mudtProps.frez_dam_gr?.toString(),
      "pit_dam_gr": this.mudtProps.pit_dam_gr?.toString() == "" ? "0" : this.mudtProps.pit_dam_gr?.toString(),
      "cncl_rmd_gr": this.mudtProps.cncl_rmd_gr?.toString() == "" ? "0" : this.mudtProps.cncl_rmd_gr?.toString(),
      "cr_br_gr": this.mudtProps.cr_br_gr?.toString() == "" ? "0" : this.mudtProps.cr_br_gr?.toString(),
      "dcol_shel_gr": this.mudtProps.dcol_shel_gr?.toString() == "" ? "0" : this.mudtProps.dcol_shel_gr?.toString(),
      "hul_brit_gr": this.mudtProps.hul_brit_gr?.toString() == "" ? "0" : this.mudtProps.hul_brit_gr?.toString(),
      "jumbo_gr": this.mudtProps.jumbo_gr?.toString() == "" ? "0" : this.mudtProps.jumbo_gr?.toString(),
      "elk_gr": this.mudtProps.elk_gr?.toString() == "" ? "0" : this.mudtProps.elk_gr?.toString(),
      "elk_ride_gr": this.mudtProps.elk_ride_gr?.toString() == "" ? "0" : this.mudtProps.elk_ride_gr?.toString(),
      "elk_dam_gr": this.mudtProps.elk_dam_gr?.toString() == "" ? "0" : this.mudtProps.elk_dam_gr?.toString(),
      "fm_gr": this.mudtProps.fm_gr?.toString() == "" ? "0" : this.mudtProps.fm_gr?.toString(),
      "lsk_gr": this.mudtProps.lsk_gr?.toString() == "" ? "0" : this.mudtProps.lsk_gr?.toString(),
      "fan_gr": this.mudtProps.fan_gr?.toString() == "" ? "0" : this.mudtProps.fan_gr?.toString(),
      "ride_pscrib_gr": this.mudtProps.ride_pscrib_gr?.toString() == "" ? "0" : this.mudtProps.ride_pscrib_gr?.toString(),
      "smk_pct": this.mudtProps.smk_pct?.toString() == "" ? "0" : this.mudtProps.smk_pct?.toString(),
      "smk_1_pct": this.mudtProps.smk_1_pct?.toString() == "" ? "0" : this.mudtProps.smk_1_pct?.toString(),
      "smk_2_pct": this.mudtProps.smk_2_pct?.toString() == "" ? "0" : this.mudtProps.smk_2_pct?.toString(),
      "smk_3_pct": this.mudtProps.smk_3_pct?.toString() == "" ? "0" : this.mudtProps.smk_3_pct?.toString(),
      "smk_4_pct": this.mudtProps.smk_4_pct?.toString() == "" ? "0" : this.mudtProps.smk_4_pct?.toString(),
      "ss_pct": this.mudtProps.ss_pct?.toString() == "" ? "0" : this.mudtProps.ss_pct?.toString(),
      "ok_pct": this.mudtProps.ok_pct?.toString() == "" ? "0" : this.mudtProps.ok_pct?.toString(),
      "ok_ride_pct": this.mudtProps.ok_ride_pct?.toString() == "" ? "0" : this.mudtProps.ok_ride_pct?.toString(),
      "ok_fall_pct": this.mudtProps.ok_fall_pct?.toString() == "" ? "0" : this.mudtProps.ok_fall_pct?.toString(),
      "ok_dam_pct": this.mudtProps.ok_dam_pct?.toString() == "" ? "0" : this.mudtProps.ok_dam_pct?.toString(),
      "dam_pct": this.mudtProps.dam_pct?.toString() == "" ? "0" : this.mudtProps.dam_pct?.toString(),
      "tkc_pct": this.mudtProps.tkc_pct?.toString() == "" ? "0" : this.mudtProps.tkc_pct?.toString(),
      "hull_pct": this.mudtProps.hull_pct?.toString() == "" ? "0" : this.mudtProps.hull_pct?.toString(),
      "frez_dam_pct": this.mudtProps.frez_dam_pct?.toString() == "" ? "0" : this.mudtProps.frez_dam_pct?.toString(),
      "pit_dam_pct": this.mudtProps.pit_dam_pct?.toString() == "" ? "0" : this.mudtProps.pit_dam_pct?.toString(),
      "cncl_rmd_pct": this.mudtProps.cncl_rmd_pct?.toString() == "" ? "0" : this.mudtProps.cncl_rmd_pct?.toString(),
      "cr_br_pct": this.mudtProps.cr_br_pct?.toString() == "" ? "0" : this.mudtProps.cr_br_pct?.toString(),
      "dcol_shel_pct": this.mudtProps.dcol_shel_pct?.toString() == "" ? "0" : this.mudtProps.dcol_shel_pct?.toString(),
      "hul_brit_pct": this.mudtProps.hul_brit_pct?.toString() == "" ? "0" : this.mudtProps.hul_brit_pct?.toString(),
      "jumbo_pct": this.mudtProps.jumbo_pct?.toString() == "" ? "0" : this.mudtProps.jumbo_pct?.toString(),
      "elk_pct": this.mudtProps.elk_pct?.toString() == "" ? "0" : this.mudtProps.elk_pct?.toString(),
      "elk_ride_pct": this.mudtProps.elk_ride_pct?.toString() == "" ? "0" : this.mudtProps.elk_ride_pct?.toString(),
      "elk_dam_pct": this.mudtProps.elk_dam_pct?.toString() == "" ? "0" : this.mudtProps.elk_dam_pct?.toString(),
      "fm_pct": this.mudtProps.fm_pct?.toString() == "" ? "0" : this.mudtProps.fm_pct?.toString(),
      "lsk_pct": this.mudtProps.lsk_pct?.toString() == "" ? "0" : this.mudtProps.lsk_pct?.toString(),
      "lsk_ride_pct": this.mudtProps.lsk_ride_pct?.toString() == "" ? "0" : this.mudtProps.lsk_ride_pct?.toString(),
      "lsk_fall_pct": this.mudtProps.lsk_fall_pct?.toString() == "" ? "0" : this.mudtProps.lsk_fall_pct?.toString(),
      "lsk_dam_pct": this.mudtProps.lsk_dam_pct?.toString() == "" ? "0" : this.mudtProps.lsk_dam_pct?.toString(),
      "moist_pct": this.mudtProps.moist_pct?.toString() == "" ? "0" : this.mudtProps.moist_pct?.toString(),
      "fan_pct": this.mudtProps.fan_pct?.toString() == "" ? "0" : this.mudtProps.fan_pct?.toString(),
      "ride_pscrib_pct": this.mudtProps.ride_pscrib_pct?.toString() == "" ? "0" : this.mudtProps.ride_pscrib_pct?.toString(),
      "oth_fm": this.mudtProps.oth_fm?.toString(),
      "ok_guage": this.mudtProps.ok_guage?.toString(),
      "smk_1_guage": this.mudtProps.smk_1_guage?.toString(),
      "smk_2_guage": this.mudtProps.smk_2_guage?.toString(),
      "smk_3_guage": this.mudtProps.smk_3_guage?.toString(),
      "smk_4_guage": this.mudtProps.smk_4_guage?.toString(),
      "lsk_guage": this.mudtProps.lsk_guage?.toString(),
      "from_seed_gen": this.mudtProps.from_seed_gen?.toString(),
      "to_seed_gen": this.mudtProps.to_seed_gen?.toString(),
      "from_edible_oil_ind": this.mudtProps.from_edible_oil_ind?.toString(),
      "to_edible_oil_ind": this.mudtProps.to_edible_oil_ind?.toString(),
      "from_oleic_ind": this.mudtProps.from_oleic_ind?.toString(),
      "to_oleic_ind": this.mudtProps.to_oleic_ind?.toString(),
      "from_pnut_variety_id": this.mudtProps.from_pnut_variety_id?.toString(),
      "to_pnut_variety_id": this.mudtProps.to_pnut_variety_id?.toString(),
      "from_organic_ind": this.mudtProps.from_organic_ind?.toString(),
      "to_organic_ind": this.mudtProps.to_organic_ind?.toString(),
      "manual_grade_ind": this.mudtProps.manual_grade_ind?.toString(),
      "remark": this.mudtProps.remark?.toString(),
      "settled_1007_num": this.mudtProps.settled_1007_num?.toString(),
      "shp_scale_tick": this.mudtProps.shp_scale_tick?.toString(),
      "shp_split_scale": this.mudtProps.shp_split_scale?.toString(),
      "shp_tare_wt": this.mudtProps.shp_tare_wt?.toString() == "" ? "0" : this.mudtProps.shp_tare_wt?.toString(),
      "shp_vehicle_wt": this.mudtProps.shp_vehicle_wt?.toString() == "" ? "0" : this.mudtProps.shp_vehicle_wt?.toString(),
      "shp_peanut_wt": this.mudtProps.shp_peanut_wt?.toString() == "" ? "0" : this.mudtProps.shp_peanut_wt?.toString(),
      "rec_scale_tick": this.mudtProps.rec_scale_tick?.toString(),
      "rec_split_scale": this.mudtProps.rec_split_scale?.toString(),
      "rec_tare_wt": this.mudtProps.rec_tare_wt?.toString() == "" ? "0" : this.mudtProps.rec_tare_wt?.toString(),
      "rec_vehicle_wt": this.mudtProps.rec_vehicle_wt?.toString() == "" ? "0" : this.mudtProps.rec_vehicle_wt?.toString(),
      "rec_peanut_wt": this.mudtProps.rec_peanut_wt?.toString() == "" ? "0" : this.mudtProps.rec_peanut_wt?.toString(),
      "inventory_ind": this.mudtProps.inventory_ind?.toString(),
      "other_wt": this.mudtProps.other_wt?.toString() == "" ? "0" : this.mudtProps.other_wt?.toString(),
      "pnut_type_id": this.mudtProps.pnut_type_id?.toString(),
      "seg_type": this.mudtProps.seg_type?.toString(),
      "gross_wt": this.mudtProps.gross_wt?.toString() == "" ? "0" : this.mudtProps.gross_wt?.toString(),
      "fm_wt": this.mudtProps.fm_wt?.toString() == "" ? "0" : this.mudtProps.fm_wt?.toString(),
      "ex_moist_wt": this.mudtProps.ex_moist_wt?.toString() == "" ? "0" : this.mudtProps.ex_moist_wt?.toString(),
      "net_wt": this.mudtProps.net_wt?.toString() == "" ? "0" : this.mudtProps.net_wt?.toString(),
      "lsk_wt": this.mudtProps.lsk_wt?.toString() == "" ? "0" : this.mudtProps.lsk_wt?.toString(),
      "kern_value": this.mudtProps.kern_value?.toString() == "" ? "0" : this.mudtProps.kern_value?.toString(),
      "elk_prem": this.mudtProps.elk_prem?.toString() == "" ? "0" : this.mudtProps.elk_prem?.toString(),
      "dam_discount": this.mudtProps.dam_discount?.toString() == "" ? "0" : this.mudtProps.dam_discount?.toString(),
      "ex_fm_discount": this.mudtProps.ex_fm_discount?.toString() == "" ? "0" : this.mudtProps.ex_fm_discount?.toString(),
      "ex_ss_discount": this.mudtProps.ex_ss_discount?.toString() == "" ? "0" : this.mudtProps.ex_ss_discount?.toString(),
      "flavus_discount": this.mudtProps.flavus_discount?.toString() == "" ? "0" : this.mudtProps.flavus_discount?.toString(),
      "net_les_lsk_val": this.mudtProps.net_les_lsk_val?.toString() == "" ? "0" : this.mudtProps.net_les_lsk_val?.toString(),
      "net_price": this.mudtProps.net_price?.toString() == "" ? "0" : this.mudtProps.net_price?.toString(),
      "basis_grade_amt": this.mudtProps.basis_grade_amt?.toString() == "" ? "0" : this.mudtProps.basis_grade_amt?.toString(),
      "freight_rate": this.mudtProps.freight_rate?.toString() == "" ? "0" : this.mudtProps.freight_rate?.toString(),
      "freight_amt": this.mudtProps.freight_amt?.toString() == "" ? "0" : this.mudtProps.freight_amt?.toString(),
      "frt_vendor": this.mudtProps.frt_vendor?.toString(),
      "freight_invoice": this.mudtProps.freight_invoice?.toString(),
      "leg_count": this.mudtProps.leg_count?.toString() == "" ? "0" : this.mudtProps.leg_count?.toString(),
      "leg_orig_buy_pt": this.mudtProps.leg_orig_buy_pt?.toString(),
      "leg_dest_buy_pt": this.mudtProps.leg_dest_buy_pt?.toString(),
      "trip_weight": this.mudtProps.trip_weight?.toString() == "" ? "0" : this.mudtProps.trip_weight?.toString(),
      "frt_premium_amt": this.mudtProps.frt_premium_amt?.toString() == "" ? "0" : this.mudtProps.frt_premium_amt?.toString(),
      "frt_deduct_amt": this.mudtProps.frt_deduct_amt?.toString() == "" ? "0" : this.mudtProps.frt_deduct_amt?.toString(),
      "frt_proceeds_amt": this.mudtProps.frt_proceeds_amt?.toString() == "" ? "0" : this.mudtProps.frt_proceeds_amt?.toString(),
      "frt_rate_basis": this.mudtProps.frt_rate_basis?.toString(),
      "frt_fuel_pct": this.mudtProps.frt_fuel_pct?.toString() == "" ? "0" : this.mudtProps.frt_fuel_pct?.toString(),
      "frt_lease_pct": this.mudtProps.frt_lease_pct?.toString() == "" ? "0" : this.mudtProps.frt_lease_pct?.toString(),
      "frt_rate_source": this.mudtProps.frt_rate_source?.toString()
    }
    return dataObj
  }

  Save = async () => {
    await this.CheckBusinessRules()
    await this.CheckCurrentStatus()
    await this.CalculatePeanutValueOld()

    let validation = await this.isValid()
    if (!validation) {
      return false
    }

    this.mudtProps.xfer_status = await this.GetTransferStatusID(this.mNewStatus)
    this.mudtProps.chg_user = useridFromLS()  //CurrentUser.mudtProps.UserID

    let dataObj = this.NewSerialize()

    let response = await StockTransferService.UpdateTransferHeader(dataObj)

    if (response.status !== 200) {
      this.mDbError = response.message
      return false;
    } else {
      let data = response.result

      data = data.split("|")

      for (let i = 0; i < data.length; i++) {
        if (data[i].includes("chg_date_time")) {
          this.chg_date_time(data[i].split(":")[1] + ":" + data[i].split(":")[2] + ":" + data[i].split(":")[3])
        }
        if (data[i].includes("leg_mileage")) {
          this.leg_mileage(data[i].split(":")[1])
        }
        if (data[i].includes("fa_message")) {
          this.mFANeedInfo = data[i].split(":")[1] + (data[i].split(":")[2] !== undefined ? ":" + data[i].split(":")[2] : "")
        }
      }
      await this.ApplyEdit()
      await this.BeginEdit()
      return true
    }
  }
} // End clsTransfer class
