/* eslint-disable*/
import React from "react";
import RangeWidget from "./RangeWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("RangeWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let rangewidget1 = {
        name: "rangewidget1",
        type: "RangeWidget",
        parent: "groupboxwidget0",
        Label: "rangewidget1",
        LengthRange: { MinLength: 0, MaxLength: 9 },
        ofTypeDomain: "d_int",
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <RangeWidget
          conf={rangewidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is RangeWidget1 Loads Succesfully", async () => {
    const rangewidget1 = screen.getByTestId("rangewidget1");
    expect(rangewidget1.tagName).toBe("INPUT");
    expect(rangewidget1.type).toBe("range");
    expect(rangewidget1.classList).toContain("textboxWidgetClass");
  });
});
describe("RangeWidget2 Test Cases", () => {
  afterEach(cleanup);
  test("Is RangeWidget2 Loads Succesfully", async () => {
    let rangewidget3 = {
      name: "rangewidget3",
      type: "RangeWidget",
      parent: "groupboxwidget0",
      Label: "rangewidget3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
      horizontalForm: true,
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    const testScreen = renderTestScreen(
      <RangeWidget
        conf={rangewidget3}
        screenConf={stateScreenConf}
        values={values}
        touched={touched}
        errors={errors}
      />
    );
    const rangewidget3el = testScreen.getByTestId("rangewidget3");
    expect(rangewidget3el.tagName).toBe("INPUT");
    expect(rangewidget3el.type).toBe("range");
    expect(rangewidget3el.classList).toContain("textboxWidgetClass");
  });
});
