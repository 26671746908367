/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  disable,
} from "../../shared/WindowImports";

import "./SecurityGroupUserSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import { response } from "msw";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityGroupUserSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityGroupUserSelect",
    windowName: "SecurityGroupUserSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityGroupUserSelect",
    // START_USER_CODE-USER_SecurityGroupUserSelect_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Security User Select",
      scrCode: "PN0060G",
    },
    // END_USER_CODE-USER_SecurityGroupUserSelect_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    ddUser: {
      name: "ddUser",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityGroupUserSelect",
      Label: "User:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddUser_PROPERTIES

      // END_USER_CODE-USER_ddUser_PROPERTIES
    },
    txtKeyInfo: {
      name: "txtKeyInfo",
      type: "TextBoxWidget",
      parent: "grpbxSecurityGroupUserSelect",
      Label: "Key Information:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKeyInfo_PROPERTIES

      // END_USER_CODE-USER_txtKeyInfo_PROPERTIES
    },
    grpbxSecurityGroupUserSelect: {
      name: "grpbxSecurityGroupUserSelect",
      type: "GroupBoxWidget",
      parent: "SecurityGroupUserSelect",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSecurityGroupUserSelect_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityGroupUserSelect_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityGroupUserSelect",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  // START_USER_CODE-USER_METHODS
  const FormLoad = async() => {
    setLoading(true)
    await bFillUsers()
    let frmSecurityGroup = getData(thisObj, 'frmSecurityGroupUserSelect');
    let cmdOk = frmSecurityGroup.cmdOk_Caption;
    let Securitygrp = frmSecurityGroup.security_group;
    if (frmSecurityGroup != '' && frmSecurityGroup != undefined) {
      if (cmdOk == "Add") {
        setValue(thisObj, "txtKeyInfo",frmSecurityGroup.security_group);
        disable(thisObj, 'txtKeyInfo')
        document.getElementById('btnUpdate').innerText = "Add"
      }
    }
    setLoading(false)
  };
  //update
  const onbtnUpdateClick = async () => {
    try {
      let frmSecurityGroup = getData(thisObj, "frmSecurityGroupUserSelect");
      let userid = frmSecurityGroup.userList;
      let js=[]
      let obj={}
      for (var i = 0; i < userid.length; i++) {
        obj = userid[i].key
        js.push(obj)
      }
      let lstUserID = getValue(thisObj, "ddUser");
      const isValueInArray = js.includes(lstUserID);
 
      if (isValueInArray) {
        showMessage(thisObj,"This user has already been associated!!!");
        return;
      } 
      let cmdOk = frmSecurityGroup.cmdOk_Caption;
      let Securitygrp = getValue(thisObj, "txtKeyInfo");
      let user_id = getValue(thisObj, "ddUser")
      let data = {
        "security_group": Securitygrp,
      }
      if (cmdOk == "Add") {
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateUserControlDetails(user_id, data)
        if (![200, 400, 404].includes(response.status)) {
          alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else if (response.status == 200) {
          showMessage(thisObj,"added successfully",true)
          let dataObj = {}
          dataObj.user_id = user_id
          dataObj.Securitygrp = Securitygrp
          setData(thisObj, 'frmSecurityGrp', dataObj)
          goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityGroup#DEFAULT#true#Click")
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  // users dropdown
  const bFillUsers = async() => {
    try {
      let js = [];
      //js.push({key: '', description: ">>> All Users <<<"})
      let response = await ContractManagementService.RetrieveUserControlDetails()
      let data = response;
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].userId, description: data[i].userId + ' - ' + data[i].userName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddUser: {
            ...state["ddUser"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddUser', js[0].key)
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }
  //cancel
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityGroupUserSelectPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
           <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityGroupUserSelect*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityGroupUserSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityGroupUserSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityGroupUserSelect*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityGroupUserSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtKeyInfo*/}

                {/* END_USER_CODE-USER_BEFORE_txtKeyInfo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKeyInfo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKeyInfo*/}

                {/* END_USER_CODE-USER_AFTER_txtKeyInfo*/}
                {/* START_USER_CODE-USER_BEFORE_ddUser*/}

                {/* END_USER_CODE-USER_BEFORE_ddUser*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddUser}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddUser*/}

                {/* END_USER_CODE-USER_AFTER_ddUser*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityGroupUserSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityGroupUserSelect*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityGroupUserSelect*/}

              {/* END_USER_CODE-USER_AFTER_SecurityGroupUserSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_SecurityGroupUserSelect
);
