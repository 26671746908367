/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityUserFunctionLocationSelect from "./SecurityUserFunctionLocationSelect";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityUserFunctionLocationSelect Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SecurityUserFunctionLocationSelect />
      );
    });
  });
  afterEach(cleanup);
  test("is SecurityUserFunctionLocationSelect Loads Successfully", () => {
    expect(screen.getByText("SecurityUserFunctionLocationSelect"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityUserFunctionLocationSelect", () => {
    // START_USER_CODE-USER_SecurityUserFunctionLocationSelect_Custom_Test_Case
    // END_USER_CODE-USER_SecurityUserFunctionLocationSelect_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_SecurityUserFunctionLocationSelect />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_btnCancel"
      )
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_btnUpdate"
      )
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxSecurityLocationSelect(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityLocationSelect = screen.getByTestId(
      "grpbxSecurityLocationSelect"
    );
    expect(grpbxSecurityLocationSelect.tagName).toBe("BUTTON");
    expect(grpbxSecurityLocationSelect.type).toBe("button");
    expect(grpbxSecurityLocationSelect.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityLocationSelect", () => {
    // START_USER_CODE-USER_grpbxSecurityLocationSelect_TEST
    // END_USER_CODE-USER_grpbxSecurityLocationSelect_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtKeyInfo(Textbox Widget) Test Cases", async () => {
    const txtKeyInfo = screen.getByTestId("txtKeyInfo");
    expect(txtKeyInfo.tagName).toBe("INPUT");
    expect(txtKeyInfo.type).toBe("text");
    expect(txtKeyInfo.classList).toContain("textboxWidgetClass");
    expect(txtKeyInfo.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SecurityUserFunctionLocationSelect_txtKeyInfo"
      )
    );
    await act(async () => {
      userEvent.type(txtKeyInfo, "1");
    });
  });
  test("Custom Test Cases for txtKeyInfo", () => {
    // START_USER_CODE-USER_txtKeyInfo_TEST
    // END_USER_CODE-USER_txtKeyInfo_TEST
  });
});
