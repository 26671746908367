/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile from "./PremiumDeducMasterProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeducMasterProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is PremiumDeducMasterProfile Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionMasterProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeducMasterProfile", () => {
    // START_USER_CODE-USER_PremiumDeducMasterProfile_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeducMasterProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxPremiumDeductionMasterProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionMasterProfile = screen.getByTestId(
      "grpbxPremiumDeductionMasterProfile"
    );
    expect(grpbxPremiumDeductionMasterProfile.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionMasterProfile.type).toBe("button");
    expect(grpbxPremiumDeductionMasterProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionMasterProfile", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionMasterProfile_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionMasterProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtAccount(Textbox Widget) Test Cases", async () => {
    const txtAccount = screen.getByTestId("txtAccount");
    expect(txtAccount.tagName).toBe("INPUT");
    expect(txtAccount.type).toBe("text");
    expect(txtAccount.classList).toContain("textboxWidgetClass");
    expect(txtAccount.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_txtAccount"
      )
    );
    await act(async () => {
      userEvent.type(txtAccount, "1");
    });
  });
  test("Custom Test Cases for txtAccount", () => {
    // START_USER_CODE-USER_txtAccount_TEST
    // END_USER_CODE-USER_txtAccount_TEST
  });
  test("txtDiscription(Textbox Widget) Test Cases", async () => {
    const txtDiscription = screen.getByTestId("txtDiscription");
    expect(txtDiscription.tagName).toBe("INPUT");
    expect(txtDiscription.type).toBe("text");
    expect(txtDiscription.classList).toContain("textboxWidgetClass");
    expect(txtDiscription.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_txtDiscription"
      )
    );
    await act(async () => {
      userEvent.type(txtDiscription, "1");
    });
  });
  test("Custom Test Cases for txtDiscription", () => {
    // START_USER_CODE-USER_txtDiscription_TEST
    // END_USER_CODE-USER_txtDiscription_TEST
  });
  test("txtPDCode(Textbox Widget) Test Cases", async () => {
    const txtPDCode = screen.getByTestId("txtPDCode");
    expect(txtPDCode.tagName).toBe("INPUT");
    expect(txtPDCode.type).toBe("text");
    expect(txtPDCode.classList).toContain("textboxWidgetClass");
    expect(txtPDCode.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_txtPDCode")
    );
    await act(async () => {
      userEvent.type(txtPDCode, "1");
    });
  });
  test("Custom Test Cases for txtPDCode", () => {
    // START_USER_CODE-USER_txtPDCode_TEST
    // END_USER_CODE-USER_txtPDCode_TEST
  });
  test("txtPDSeedAccount(Textbox Widget) Test Cases", async () => {
    const txtPDSeedAccount = screen.getByTestId("txtPDSeedAccount");
    expect(txtPDSeedAccount.tagName).toBe("INPUT");
    expect(txtPDSeedAccount.type).toBe("text");
    expect(txtPDSeedAccount.classList).toContain("textboxWidgetClass");
    expect(txtPDSeedAccount.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterProfile_txtPDSeedAccount"
      )
    );
    await act(async () => {
      userEvent.type(txtPDSeedAccount, "1");
    });
  });
  test("Custom Test Cases for txtPDSeedAccount", () => {
    // START_USER_CODE-USER_txtPDSeedAccount_TEST
    // END_USER_CODE-USER_txtPDSeedAccount_TEST
  });
});
