/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_PriceScheduleAuditList from "./PriceScheduleAuditList";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PriceScheduleAuditList Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PriceScheduleAuditList />
      );
    });
  });
  afterEach(cleanup);
  test("is PriceScheduleAuditList Loads Successfully", () => {
    expect(screen.getByText("PriceScheduleAuditList")).toBeInTheDocument;
  });
  test("Custom Test Cases for PriceScheduleAuditList", () => {
    // START_USER_CODE-USER_PriceScheduleAuditList_Custom_Test_Case
    // END_USER_CODE-USER_PriceScheduleAuditList_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PriceScheduleAuditList />
      );
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnView(Button Widget) Test Cases", async () => {
    const btnView = screen.getByTestId("btnView");
    expect(btnView).toBeInTheDocument;
    expect(btnView.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_btnView")
    );
  });
  test("Custom Test Cases for btnView", () => {
    // START_USER_CODE-USER_btnView_TEST
    // END_USER_CODE-USER_btnView_TEST
  });
  test("gridPriceScheduleAuditList(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditList = screen.getByTestId(
      "gridPriceScheduleAuditList"
    );
    let gridPriceScheduleAuditListbtn =
      gridPriceScheduleAuditList.nextElementSibling.firstElementChild;
    gridPriceScheduleAuditList =
      gridPriceScheduleAuditList.parentElement.parentElement.parentElement;
    expect(gridPriceScheduleAuditList.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_gridPriceScheduleAuditList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPriceScheduleAuditList", () => {
    // START_USER_CODE-USER_gridPriceScheduleAuditList_TEST
    // END_USER_CODE-USER_gridPriceScheduleAuditList_TEST
  });
  test("grpbxPriceScheduleAuditList(GroupBox Widget) Test Cases", async () => {
    const grpbxPriceScheduleAuditList = screen.getByTestId(
      "grpbxPriceScheduleAuditList"
    );
    expect(grpbxPriceScheduleAuditList.tagName).toBe("BUTTON");
    expect(grpbxPriceScheduleAuditList.type).toBe("button");
    expect(grpbxPriceScheduleAuditList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPriceScheduleAuditList", () => {
    // START_USER_CODE-USER_grpbxPriceScheduleAuditList_TEST
    // END_USER_CODE-USER_grpbxPriceScheduleAuditList_TEST
  });
  test("gridPriceScheduleAuditList_txtcolActions(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditList_txtcolActions = screen.getByTestId(
      "gridPriceScheduleAuditList"
    );
    let gridPriceScheduleAuditList_txtcolActionsbtn =
      gridPriceScheduleAuditList_txtcolActions.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditList_txtcolActions =
      gridPriceScheduleAuditList_txtcolActions.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditList_txtcolActions.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditList_txtcolActions.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_gridPriceScheduleAuditList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolActions", () => {
    // START_USER_CODE-USER_txtcolActions_TEST
    // END_USER_CODE-USER_txtcolActions_TEST
  });
  test("gridPriceScheduleAuditList_txtcolChangeDateTime(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditList_txtcolChangeDateTime = screen.getByTestId(
      "gridPriceScheduleAuditList"
    );
    let gridPriceScheduleAuditList_txtcolChangeDateTimebtn =
      gridPriceScheduleAuditList_txtcolChangeDateTime.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditList_txtcolChangeDateTime =
      gridPriceScheduleAuditList_txtcolChangeDateTime.parentElement
        .parentElement.parentElement;
    expect(gridPriceScheduleAuditList_txtcolChangeDateTime.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditList_txtcolChangeDateTime.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_gridPriceScheduleAuditList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChangeDateTime", () => {
    // START_USER_CODE-USER_txtcolChangeDateTime_TEST
    // END_USER_CODE-USER_txtcolChangeDateTime_TEST
  });
  test("gridPriceScheduleAuditList_txtcolChangeUser(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditList_txtcolChangeUser = screen.getByTestId(
      "gridPriceScheduleAuditList"
    );
    let gridPriceScheduleAuditList_txtcolChangeUserbtn =
      gridPriceScheduleAuditList_txtcolChangeUser.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditList_txtcolChangeUser =
      gridPriceScheduleAuditList_txtcolChangeUser.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditList_txtcolChangeUser.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditList_txtcolChangeUser.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_gridPriceScheduleAuditList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChangeUser", () => {
    // START_USER_CODE-USER_txtcolChangeUser_TEST
    // END_USER_CODE-USER_txtcolChangeUser_TEST
  });
  test("gridPriceScheduleAuditList_txtcolRevision(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditList_txtcolRevision = screen.getByTestId(
      "gridPriceScheduleAuditList"
    );
    let gridPriceScheduleAuditList_txtcolRevisionbtn =
      gridPriceScheduleAuditList_txtcolRevision.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditList_txtcolRevision =
      gridPriceScheduleAuditList_txtcolRevision.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditList_txtcolRevision.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditList_txtcolRevision.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditList_gridPriceScheduleAuditList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRevision", () => {
    // START_USER_CODE-USER_txtcolRevision_TEST
    // END_USER_CODE-USER_txtcolRevision_TEST
  });
});
