/* eslint-disable*/
import { clsPeanutVarietyList } from "./clsPeanutVarietyList";

const defaultPeanutTypeProps = {
    PNUT_TYPE_ID: '',
    PNUT_TYPE_NAME: '',
    OK_GUAGE: '',
    SMK_1_GUAGE: '',
    SMK_2_GUAGE: '',
    SMK_3_GUAGE: '',
    SMK_4_GUAGE: '',
    LSK_GUAGE: '',
    ACCT_SUB_DEPT: '',
    ADD_USER: '',
    ADD_DATE_TIME: '',
    CHG_USER: '',
    CHG_DATE_TIME: '',
    FLEX_MKT_ALLOW: ''
}

const mVarietyList = [];

export class clsPeanutType {

    constructor(thisObj) {
        this.thisObj = thisObj;
        this.mudtProps = Object.assign({}, defaultPeanutTypeProps);
        this.mudtSave = Object.assign({}, defaultPeanutTypeProps);
    }

    PNUT_TYPE_ID = (Value) => {
        if (this.mudtProps.PNUT_TYPE_ID === Value) {
            return this.mudtProps.PNUT_TYPE_ID;
        } else {
            this.mudtProps.PNUT_TYPE_ID = Value;
        }
    }

    PNUT_TYPE_NAME = (Value) => {
        if (this.mudtProps.PNUT_TYPE_NAME === Value) {
            return this.mudtProps.PNUT_TYPE_NAME;
        } else {
            this.mudtProps.PNUT_TYPE_NAME = Value;
        }
    }

    OK_GUAGE = (Value) => {
        if (this.mudtProps.OK_GUAGE === Value) {
            return this.mudtProps.OK_GUAGE;
        } else {
            this.mudtProps.OK_GUAGE = Value;
        }
    }

    SMK_1_GUAGE = (Value) => {
        if (this.mudtProps.SMK_1_GUAGE === Value) {
            return this.mudtProps.SMK_1_GUAGE;
        } else {
            this.mudtProps.SMK_1_GUAGE = Value;
        }
    }

    SMK_2_GUAGE = (Value) => {
        if (this.mudtProps.SMK_2_GUAGE === Value) {
            return this.mudtProps.SMK_2_GUAGE;
        } else {
            this.mudtProps.SMK_2_GUAGE = Value;
        }
    }


    SMK_3_GUAGE = (Value) => {
        if (this.mudtProps.SMK_3_GUAGE === Value) {
            return this.mudtProps.SMK_3_GUAGE;
        } else {
            this.mudtProps.SMK_3_GUAGE = Value;
        }
    }

    SMK_4_GUAGE = (Value) => {
        if (this.mudtProps.SMK_4_GUAGE === Value) {
            return this.mudtProps.SMK_4_GUAGE;
        } else {
            this.mudtProps.SMK_4_GUAGE = Value;
        }
    }

    LSK_GUAGE = (Value) => {
        if (this.mudtProps.LSK_GUAGE === Value) {
            return this.mudtProps.LSK_GUAGE;
        } else {
            this.mudtProps.LSK_GUAGE = Value;
        }
    }

    ACCT_SUB_DEPT = (Value) => {
        if (this.mudtProps.ACCT_SUB_DEPT === Value) {
            return this.mudtProps.ACCT_SUB_DEPT;
        } else {
            this.mudtProps.ACCT_SUB_DEPT = Value;
        }
    }

    ADD_USER = (Value) => {
        if (this.mudtProps.ADD_USER === Value) {
            return this.mudtProps.ADD_USER;
        } else {
            this.mudtProps.ADD_USER = Value;
        }
    }

    ADD_DATE_TIME = (Value) => {
        if (this.mudtProps.ADD_DATE_TIME === Value) {
            return this.mudtProps.ADD_DATE_TIME;
        } else {
            this.mudtProps.ADD_DATE_TIME = Value;
        }
    }

    CHG_USER = (Value) => {
        if (this.mudtProps.CHG_USER === Value) {
            return this.mudtProps.CHG_USER;
        } else {
            this.mudtProps.CHG_USER = Value;
        }
    }

    CHG_DATE_TIME = (Value) => {
        if (this.mudtProps.CHG_DATE_TIME === Value) {
            return this.mudtProps.CHG_DATE_TIME;
        } else {
            this.mudtProps.CHG_DATE_TIME = Value;
        }
    }

    FLEX_MKT_ALLOW = (Value) => {
        if (this.mudtProps.FLEX_MKT_ALLOW === Value) {
            return this.mudtProps.FLEX_MKT_ALLOW;
        } else {
            this.mudtProps.FLEX_MKT_ALLOW = Value;
        }
    }

    Fetch = (data) => {
        let self = this;
        Object.keys(data).forEach(function (reskey) {
            self[reskey](data[reskey]);
        });
    };

    GetComboVarietyList = (CropYear, BuyingPointID) => {
        mVarietyList = new clsPeanutVarietyList();
        mVarietyList.Load(CropYear, BuyingPointID, this.mudtProps.PNUT_TYPE_ID);
        return mVarietyList.GetComboList();
    }

    GetVarietyList = (CropYear, BuyingPointID) => {
        mVarietyList = new clsPeanutVarietyList();
        mVarietyList.Load(CropYear, BuyingPointID, this.mudtProps.PNUT_TYPE_ID);
        return mVarietyList;
    }

    VarietyList() {
        return mVarietyList;
    }
}