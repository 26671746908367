/* eslint-disable*/
import React from "react";
import ContractManagement_ExceptionPremiumSearch from "./ExceptionPremiumSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExceptionPremiumSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptionPremiumSearch />);
    });
  });
  afterEach(cleanup);
  test("is ExceptionPremiumSearch Loads Successfully", () => {
    expect(screen.getByText("Exception Premium Search")).toBeInTheDocument;
  });
  test("Custom Test Cases for ExceptionPremiumSearch", () => {
    // START_USER_CODE-USER_ExceptionPremiumSearch_Custom_Test_Case
    // END_USER_CODE-USER_ExceptionPremiumSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptionPremiumSearch />);
    });
  });
  afterEach(cleanup);
  test("btnContrctPyReq(Button Widget) Test Cases", async () => {
    const btnContrctPyReq = screen.getByTestId("btnContrctPyReq");
    expect(btnContrctPyReq).toBeInTheDocument;
    expect(btnContrctPyReq.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnContrctPyReq")
    );
  });
  test("Custom Test Cases for btnContrctPyReq", () => {
    // START_USER_CODE-USER_btnContrctPyReq_TEST
    // END_USER_CODE-USER_btnContrctPyReq_TEST
  });
  test("btnCrtExcPrem(Button Widget) Test Cases", async () => {
    const btnCrtExcPrem = screen.getByTestId("btnCrtExcPrem");
    expect(btnCrtExcPrem).toBeInTheDocument;
    expect(btnCrtExcPrem.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnCrtExcPrem")
    );
  });
  test("Custom Test Cases for btnCrtExcPrem", () => {
    // START_USER_CODE-USER_btnCrtExcPrem_TEST
    // END_USER_CODE-USER_btnCrtExcPrem_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnExport(Button Widget) Test Cases", async () => {
    const btnExport = screen.getByTestId("btnExport");
    expect(btnExport).toBeInTheDocument;
    expect(btnExport.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnExport")
    );
  });
  test("Custom Test Cases for btnExport", () => {
    // START_USER_CODE-USER_btnExport_TEST
    // END_USER_CODE-USER_btnExport_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPopupVendr(Button Widget) Test Cases", async () => {
    const btnPopupVendr = screen.getByTestId("btnPopupVendr");
    expect(btnPopupVendr).toBeInTheDocument;
    expect(btnPopupVendr.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnPopupVendr")
    );
  });
  test("Custom Test Cases for btnPopupVendr", () => {
    // START_USER_CODE-USER_btnPopupVendr_TEST
    // END_USER_CODE-USER_btnPopupVendr_TEST
  });
  test("btnPrntSrch(Button Widget) Test Cases", async () => {
    const btnPrntSrch = screen.getByTestId("btnPrntSrch");
    expect(btnPrntSrch).toBeInTheDocument;
    expect(btnPrntSrch.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnPrntSrch")
    );
  });
  test("Custom Test Cases for btnPrntSrch", () => {
    // START_USER_CODE-USER_btnPrntSrch_TEST
    // END_USER_CODE-USER_btnPrntSrch_TEST
  });
  test("btnSrch(Button Widget) Test Cases", async () => {
    const btnSrch = screen.getByTestId("btnSrch");
    expect(btnSrch).toBeInTheDocument;
    expect(btnSrch.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_btnSrch")
    );
  });
  test("Custom Test Cases for btnSrch", () => {
    // START_USER_CODE-USER_btnSrch_TEST
    // END_USER_CODE-USER_btnSrch_TEST
  });
  test("grpbxExcPremSrch(GroupBox Widget) Test Cases", async () => {
    const grpbxExcPremSrch = screen.getByTestId("grpbxExcPremSrch");
    expect(grpbxExcPremSrch.tagName).toBe("BUTTON");
    expect(grpbxExcPremSrch.type).toBe("button");
    expect(grpbxExcPremSrch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxExcPremSrch", () => {
    // START_USER_CODE-USER_grpbxExcPremSrch_TEST
    // END_USER_CODE-USER_grpbxExcPremSrch_TEST
  });
  test("grpbxExprtPrnt(GroupBox Widget) Test Cases", async () => {
    const grpbxExprtPrnt = screen.getByTestId("grpbxExprtPrnt");
    expect(grpbxExprtPrnt.tagName).toBe("BUTTON");
    expect(grpbxExprtPrnt.type).toBe("button");
    expect(grpbxExprtPrnt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxExprtPrnt", () => {
    // START_USER_CODE-USER_grpbxExprtPrnt_TEST
    // END_USER_CODE-USER_grpbxExprtPrnt_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("txtContrct(Textbox Widget) Test Cases", async () => {
    const txtContrct = screen.getByTestId("txtContrct");
    expect(txtContrct.tagName).toBe("INPUT");
    expect(txtContrct.type).toBe("text");
    expect(txtContrct.classList).toContain("textboxWidgetClass");
    expect(txtContrct.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtContrct")
    );
    await act(async () => {
      userEvent.type(txtContrct, "1");
    });
  });
  test("Custom Test Cases for txtContrct", () => {
    // START_USER_CODE-USER_txtContrct_TEST
    // END_USER_CODE-USER_txtContrct_TEST
  });
  test("txtFrm(Textbox Widget) Test Cases", async () => {
    const txtFrm = screen.getByTestId("txtFrm");
    expect(txtFrm.tagName).toBe("INPUT");
    expect(txtFrm.type).toBe("text");
    expect(txtFrm.classList).toContain("textboxWidgetClass");
    expect(txtFrm.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtFrm")
    );
    await act(async () => {
      userEvent.type(txtFrm, "1");
    });
  });
  test("Custom Test Cases for txtFrm", () => {
    // START_USER_CODE-USER_txtFrm_TEST
    // END_USER_CODE-USER_txtFrm_TEST
  });
  test("txtFrmSfx(Textbox Widget) Test Cases", async () => {
    const txtFrmSfx = screen.getByTestId("txtFrmSfx");
    expect(txtFrmSfx.tagName).toBe("INPUT");
    expect(txtFrmSfx.type).toBe("text");
    expect(txtFrmSfx.classList).toContain("textboxWidgetClass");
    expect(txtFrmSfx.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtFrmSfx")
    );
    await act(async () => {
      userEvent.type(txtFrmSfx, "1");
    });
  });
  test("Custom Test Cases for txtFrmSfx", () => {
    // START_USER_CODE-USER_txtFrmSfx_TEST
    // END_USER_CODE-USER_txtFrmSfx_TEST
  });
  test("txtPayReq(Textbox Widget) Test Cases", async () => {
    const txtPayReq = screen.getByTestId("txtPayReq");
    expect(txtPayReq.tagName).toBe("INPUT");
    expect(txtPayReq.type).toBe("text");
    expect(txtPayReq.classList).toContain("textboxWidgetClass");
    expect(txtPayReq.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtPayReq")
    );
    await act(async () => {
      userEvent.type(txtPayReq, "1");
    });
  });
  test("Custom Test Cases for txtPayReq", () => {
    // START_USER_CODE-USER_txtPayReq_TEST
    // END_USER_CODE-USER_txtPayReq_TEST
  });
  test("txtsc95(Textbox Widget) Test Cases", async () => {
    const txtsc95 = screen.getByTestId("txtsc95");
    expect(txtsc95.tagName).toBe("INPUT");
    expect(txtsc95.type).toBe("text");
    expect(txtsc95.classList).toContain("textboxWidgetClass");
    expect(txtsc95.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtsc95")
    );
    await act(async () => {
      userEvent.type(txtsc95, "1");
    });
  });
  test("Custom Test Cases for txtsc95", () => {
    // START_USER_CODE-USER_txtsc95_TEST
    // END_USER_CODE-USER_txtsc95_TEST
  });
  test("txtTotlAmt(Textbox Widget) Test Cases", async () => {
    const txtTotlAmt = screen.getByTestId("txtTotlAmt");
    expect(txtTotlAmt.tagName).toBe("INPUT");
    expect(txtTotlAmt.type).toBe("text");
    expect(txtTotlAmt.classList).toContain("textboxWidgetClass");
    expect(txtTotlAmt.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtTotlAmt")
    );
    await act(async () => {
      userEvent.type(txtTotlAmt, "1");
    });
  });
  test("Custom Test Cases for txtTotlAmt", () => {
    // START_USER_CODE-USER_txtTotlAmt_TEST
    // END_USER_CODE-USER_txtTotlAmt_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    expect(txtVndr.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptionPremiumSearch_txtVndr")
    );
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
