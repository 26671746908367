/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getData,
  setData,
} from "../../shared/WindowImports";

import "./ReviewCheckAuditDetail.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { AccountPayment } from "../Service/AccountPayment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_ReviewCheckAuditDetail(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReviewCheckAuditDatail",
    windowName: "ReviewCheckAuditDetail",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.ReviewCheckAuditDetail",
    // START_USER_CODE-USER_ReviewCheckAuditDetail_PROPERTIES
    headerData: {
      scrName: "Payment Audit History",
      scrCode: "PN1100F",  
    },
    // END_USER_CODE-USER_ReviewCheckAuditDetail_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      ColSpan: "3",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridCheckAuditDetails",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colAuditDate: {
      name: "colAuditDate",
      type: "GridColumnWidget",
      parent: "gridCheckAuditDetails",
      Label: "Audit Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAuditDate_PROPERTIES

      // END_USER_CODE-USER_colAuditDate_PROPERTIES
    },
    colAuditUser: {
      name: "colAuditUser",
      type: "GridColumnWidget",
      parent: "gridCheckAuditDetails",
      Label: "Audit User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAuditUser_PROPERTIES

      // END_USER_CODE-USER_colAuditUser_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridCheckAuditDetails",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    txtcolAction: {
      name: "txtcolAction",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridCheckAuditDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAction_PROPERTIES

      // END_USER_CODE-USER_txtcolAction_PROPERTIES
    },
    txtcolAuditDate: {
      name: "txtcolAuditDate",
      type: "TextBoxWidget",
      colName: "colAuditDate",
      parent: "gridCheckAuditDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAuditDate_PROPERTIES

      // END_USER_CODE-USER_txtcolAuditDate_PROPERTIES
    },
    txtcolAuditUser: {
      name: "txtcolAuditUser",
      type: "TextBoxWidget",
      colName: "colAuditUser",
      parent: "gridCheckAuditDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAuditUser_PROPERTIES

      // END_USER_CODE-USER_txtcolAuditUser_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridCheckAuditDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    gridCheckAuditDetails: {
      name: "gridCheckAuditDetails",
      type: "GridWidget",
      parent: "ReviewCheckAuditDetail",
      gridCellsOrder:
        "txtcolAuditDate,txtcolAuditUser,txtcolAction,txtcolStatus",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridCheckAuditDetails_PROPERTIES

      // END_USER_CODE-USER_gridCheckAuditDetails_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "ReviewCheckAuditDetail",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "AccountPayment#ReviewCheck": {},
       
      },
      REVERSE: {
        "AccountPayment#ReviewCheck": {},
       
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose : {
      DEFAULT: [
        "AccountPayment#ReviewCheck#DEFAULT#true#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  function formLoad() {
    FillPaymentActivityAuditList();
  }

  const FillPaymentActivityAuditList = () => {
    let ReviewCheckAuditDetailFormLoadDataObj = getData(thisObj, 'ReviewCheckAuditDetailFormLoadDataObj')
    try {
      AccountPayment.RetrievePaymentActivityAuditDetails(ReviewCheckAuditDetailFormLoadDataObj.check_num, ReviewCheckAuditDetailFormLoadDataObj.receivable_num)
        .then(response => {
          if (response !== undefined) {
            let data = response;
            let list = []
            let obj = {}
            if (ReviewCheckAuditDetailFormLoadDataObj.check_num != "" && ReviewCheckAuditDetailFormLoadDataObj.check_num !== undefined && ReviewCheckAuditDetailFormLoadDataObj.receivable_num != "" && ReviewCheckAuditDetailFormLoadDataObj.receivable_num != undefined) {
              if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].strFieldName.trim() == 'AUDIT_DATE_TIME') {
                    obj.txtcolAuditDate = data[i].strFieldData
                  }
                  if (data[i].strFieldName.trim() == 'AUDIT_USER') {
                    obj.txtcolAuditUser = data[i].strFieldData.trim()
                  }
                  if (data[i].strFieldName.trim() == 'HISTORY_ACTION') {
                    obj.txtcolAction = data[i].strFieldData.trim()
                  }
                  if (data[i].strFieldName.trim() == 'AUDIT_MESSAGE') {
                    obj.txtcolStatus = data[i].strFieldData.trim()
                    list.push(obj)
                    obj = {}
                  }
                }
                setValue(thisObj, "gridCheckAuditDetails", list)
              }
            } else {
              showMessage(thisObj, "Check num/Receivable num is invalid");
            }
          }
        })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in Presubmit of event code of widget:gridCheckAuditDetails: onLoad"
        );
      }
      return false;
    }
  }
  // [];
  const onbtnCloseClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("AccountPayment_ReviewCheckAuditDetailPopUp").childNodes[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
          
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReviewCheckAuditDetail*/}

              {/* END_USER_CODE-USER_BEFORE_ReviewCheckAuditDetail*/}

              {/* START_USER_CODE-USER_BEFORE_gridCheckAuditDetails*/}

              {/* END_USER_CODE-USER_BEFORE_gridCheckAuditDetails*/}

              <GridWidget
                conf={state.gridCheckAuditDetails}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridCheckAuditDetails}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridCheckAuditDetails*/}

              {/* END_USER_CODE-USER_AFTER_gridCheckAuditDetails*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_ReviewCheckAuditDetail*/}

              {/* END_USER_CODE-USER_AFTER_ReviewCheckAuditDetail*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_ReviewCheckAuditDetail);
