/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  TextAreaWidget,
  setData,
  getData,
  setValue,
  enable,
  disable,
  getValue,
  isEnabled,
  goTo,
  hideColumn,

} from "../../shared/WindowImports";

import "./InterestAgreementProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_InterestAgreementProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  const SELECT_ALL = "Select All";
  const UNSELECT_ALL = "UnSelect All";

  let mbSuccessfulLoad = false;
  let MstrRefindVendor = "";
  let MstrRefindAgreeRemito = "";
  let chknumber = "";
  let LstrReceivable_Num = "";
  let bRemitDiffer = false;
  let Mint_MAX_CHECK_ROWS = 5000;
  let txtCheckNumber = "";
  let txtEndCheckNumber = "";
  let cmdAddTag = "";
  let lstAgreementRemitTo = [];
  let lblCheckNumber = ""
  let txtVendorNumberTag = ""
  // END_USER_CODE-USER_PROPERTIES

  let states = {  
    Label: "InterestAgreementProfile",
    windowName: "InterestAgreementProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.InterestAgreementProfile",
    // START_USER_CODE-USER_InterestAgreementProfile_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Interest Agreement Profile",
      scrCode: "PN0130B",
    },
    // END_USER_CODE-USER_InterestAgreementProfile_PROPERTIES
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnCncl: {
      name: "btnCncl",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCncl_PROPERTIES

      // END_USER_CODE-USER_btnCncl_PROPERTIES
    },
    btnLeft: {
      name: "btnLeft",
      type: "ButtonWidget",
      parent: "grpbxButtons",
      Label: "<<<",
      CharWidth: "9",
      // START_USER_CODE-USER_btnLeft_PROPERTIES

      // END_USER_CODE-USER_btnLeft_PROPERTIES
    },
    btnPrntChck: {
      name: "btnPrntChck",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Checks",
      CharWidth: "26",
      // START_USER_CODE-USER_btnPrntChck_PROPERTIES

      // END_USER_CODE-USER_btnPrntChck_PROPERTIES
    },
    btnRight: {
      name: "btnRight",
      type: "ButtonWidget",
      parent: "grpbxButtons",
      Label: ">>>",
      CharWidth: "9",
      // START_USER_CODE-USER_btnRight_PROPERTIES

      // END_USER_CODE-USER_btnRight_PROPERTIES
    },
    btnSaveBatch: {
      name: "btnSaveBatch",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save To Batch",
      CharWidth: "28",
      // START_USER_CODE-USER_btnSaveBatch_PROPERTIES

      // END_USER_CODE-USER_btnSaveBatch_PROPERTIES
    },
    btnSelectAll: {
      name: "btnSelectAll",
      type: "ButtonWidget",
      parent: "grpbxGrids",
      Label: "Select All",
      CharWidth: "23",
      // START_USER_CODE-USER_btnSelectAll_PROPERTIES

      // END_USER_CODE-USER_btnSelectAll_PROPERTIES
    },
    btnVndr: {
      name: "btnVndr",
      type: "ButtonWidget",
      parent: "grpbxAgreeProf",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnVndr_PROPERTIES

      // END_USER_CODE-USER_btnVndr_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colDateassoc: {
      name: "colDateassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateassoc_PROPERTIES

      // END_USER_CODE-USER_colDateassoc_PROPERTIES
    },
    colNum1: {
      name: "colNum1",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Num 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNum1_PROPERTIES

      // END_USER_CODE-USER_colNum1_PROPERTIES
    },
    colNum1assoc: {
      name: "colNum1assoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Num 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNum1assoc_PROPERTIES

      // END_USER_CODE-USER_colNum1assoc_PROPERTIES
    },
    colNum2: {
      name: "colNum2",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Num 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNum2_PROPERTIES

      // END_USER_CODE-USER_colNum2_PROPERTIES
    },
    colNum2assoc: {
      name: "colNum2assoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Num 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNum2assoc_PROPERTIES

      // END_USER_CODE-USER_colNum2assoc_PROPERTIES
    },
    colOpt: {
      name: "colOpt",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Opt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpt_PROPERTIES

      // END_USER_CODE-USER_colOpt_PROPERTIES
    },
    colOptassoc: {
      name: "colOptassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Opt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOptassoc_PROPERTIES

      // END_USER_CODE-USER_colOptassoc_PROPERTIES
    },
    colProceed: {
      name: "colProceed",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceed_PROPERTIES

      // END_USER_CODE-USER_colProceed_PROPERTIES
    },
    colProceedassoc: {
      name: "colProceedassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceedassoc_PROPERTIES

      // END_USER_CODE-USER_colProceedassoc_PROPERTIES
    },
    colRemit: {
      name: "colRemit",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Remit",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRemit_PROPERTIES

      // END_USER_CODE-USER_colRemit_PROPERTIES
    },
    colRemitassoc: {
      name: "colRemitassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Remit",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRemitassoc_PROPERTIES

      // END_USER_CODE-USER_colRemitassoc_PROPERTIES
    },
    colSetlDate: {
      name: "colSetlDate",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Setl. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSetlDate_PROPERTIES

      // END_USER_CODE-USER_colSetlDate_PROPERTIES
    },
    colSetlDateassoc: {
      name: "colSetlDateassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Setl. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSetlDateassoc_PROPERTIES

      // END_USER_CODE-USER_colSetlDateassoc_PROPERTIES
    },
    colType1: {
      name: "colType1",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Type 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1_PROPERTIES

      // END_USER_CODE-USER_colType1_PROPERTIES
    },
    colPaySort: {
      name: "colPaySort",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Pay Sort",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPaySort_PROPERTIES

      // END_USER_CODE-USER_colPaySort_PROPERTIES
    },
    colSettleNum: {
      name: "colSettleNum",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Settle number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSettleNum_PROPERTIES

      // END_USER_CODE-USER_colSettleNum_PROPERTIES
    },
    colType1assoc: {
      name: "colType1assoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Type 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1assoc_PROPERTIES

      // END_USER_CODE-USER_colType1assoc_PROPERTIES
    },
    colPaySortassoc: {
      name: "colPaySortassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Pay Sort",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPaySortassoc_PROPERTIES

      // END_USER_CODE-USER_colPaySortassoc_PROPERTIES
    },
    colSettleNumassoc: {
      name: "colSettleNumassoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Settle number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSettleNumassoc_PROPERTIES

      // END_USER_CODE-USER_colSettleNumassoc_PROPERTIES
    },
    colType2: {
      name: "colType2",
      type: "GridColumnWidget",
      parent: "gridAvlbReady",
      Label: "Type 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2_PROPERTIES

      // END_USER_CODE-USER_colType2_PROPERTIES
    },
    colType2assoc: {
      name: "colType2assoc",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      Label: "Type 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2assoc_PROPERTIES

      // END_USER_CODE-USER_colType2assoc_PROPERTIES
    },
    ddRemit: {
      name: "ddRemit",
      type: "DropDownFieldWidget",
      parent: "grpbxRemarks",
      Label: "Remit To:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemit_PROPERTIES

      // END_USER_CODE-USER_ddRemit_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxAgreeProf",
      Label: "Remit To:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    dtIntrstBegn: {
      name: "dtIntrstBegn",
      type: "DateWidget",
      parent: "grpbxAgreeProf",
      Label: "Interest Begin Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtIntrstBegn_PROPERTIES

      // END_USER_CODE-USER_dtIntrstBegn_PROPERTIES
    },
    gridAssocReady: {
      name: "gridAssocReady",
      type: "GridWidget",
      parent: "grpbxGrids",
      gridCellsOrder:
        "txtcolType1assoc,txtcolNum1assoc,txtcolType2assoc,txtcolNum2assoc,txtcolProceedassoc,chkbxCol,txtcolOptassoc,txtcolDateassoc,txtcolSetlDateassoc,txtcolRemitassoc,txtcolPaySortassoc,txtcolSettleNumassoc",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isMultiSelect: true,
      isEditable:true,
      isCobolTable:true,
      // START_USER_CODE-USER_gridAssocReady_PROPERTIES

      // END_USER_CODE-USER_gridAssocReady_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES

      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    gridAvlbReady: {
      name: "gridAvlbReady",
      type: "GridWidget",
      parent: "grpbxGrids",
      gridCellsOrder:
        "txtcolType1,txtcolNum1,txtcolType2,txtcolNum2,txtcolProceed,txtcolOpt,txtcolDate,txtcolSetlDate,txtcolRemit,txtcolPaySort,txtcolSettleNum",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      clonedExtId: "41789",
      isMultiSelect: true,
      // START_USER_CODE-USER_gridAvlbReady_PROPERTIES

      // END_USER_CODE-USER_gridAvlbReady_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "grpbxBottom",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    grpbxArCr: {
      name: "grpbxArCr",
      type: "GroupBoxWidget",
      parent: "grpbxBottom",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxArCr_PROPERTIES

      // END_USER_CODE-USER_grpbxArCr_PROPERTIES
    },
    grpbxButtons: {
      name: "grpbxButtons",
      type: "GroupBoxWidget",
      parent: "grpbxGrids",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxButtons_PROPERTIES

      // END_USER_CODE-USER_grpbxButtons_PROPERTIES
    },
    grpbxRemarks: {
      name: "grpbxRemarks",
      type: "GroupBoxWidget",
      parent: "grpbxBottom",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxRemarks_PROPERTIES

      // END_USER_CODE-USER_grpbxRemarks_PROPERTIES
    },
    lblRemarks: {
      name: "lblRemarks",
      type: "LabelWidget",
      parent: "grpbxRemarks",
      Label:
        "The Add/Update buttons will only save the Interest Agreement Status of RTP's and will not save the remarks.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemarks_PROPERTIES

      // END_USER_CODE-USER_lblRemarks_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES
      Enabled : false,
      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtarRemarks: {
      name: "txtarRemarks",
      type: "TextAreaWidget",
      parent: "grpbxRemarks",
      Label: "Remarks:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarRemarks_PROPERTIES
    },
    txtAssociatedReadyToPay: {
      name: "txtAssociatedReadyToPay",
      type: "TextBoxWidget",
      parent: "grpbxGrids",
      Label: "Associated Ready To Pays:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAssociatedReadyToPay_PROPERTIES

      // END_USER_CODE-USER_txtAssociatedReadyToPay_PROPERTIES
    },
    txtAvlbRadyToPays: {
      name: "txtAvlbRadyToPays",
      type: "TextBoxWidget",
      parent: "grpbxGrids",
      Label: "Available Ready To Pays:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAvlbRadyToPays_PROPERTIES

      // END_USER_CODE-USER_txtAvlbRadyToPays_PROPERTIES
    },
    txtBlnc: {
      name: "txtBlnc",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Balance:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBlnc_PROPERTIES

      // END_USER_CODE-USER_txtBlnc_PROPERTIES
    },
    txtBlnc2: {
      name: "txtBlnc2",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBlnc2_PROPERTIES

      // END_USER_CODE-USER_txtBlnc2_PROPERTIES
    },
    txtBlnc3: {
      name: "txtBlnc3",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBlnc3_PROPERTIES

      // END_USER_CODE-USER_txtBlnc3_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES
      Enabled : false,
      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtCllctAr: {
      name: "txtCllctAr",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Collect AR:",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtCllctAr_PROPERTIES

      // END_USER_CODE-USER_txtCllctAr_PROPERTIES
    },
    txtCllctCR: {
      name: "txtCllctCR",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Collect CR:",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtCllctCR_PROPERTIES

      // END_USER_CODE-USER_txtCllctCR_PROPERTIES
    },
    txtCllctSr: {
      name: "txtCllctSr",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Collect SR:",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtCllctSr_PROPERTIES

      // END_USER_CODE-USER_txtCllctSr_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolDateassoc: {
      name: "txtcolDateassoc",
      type: "TextBoxWidget",
      colName: "colDateassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolDateassoc_PROPERTIES
    },
    txtcolNum1: {
      name: "txtcolNum1",
      type: "TextBoxWidget",
      colName: "colNum1",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNum1_PROPERTIES

      // END_USER_CODE-USER_txtcolNum1_PROPERTIES
    },
    txtcolNum1assoc: {
      name: "txtcolNum1assoc",
      type: "TextBoxWidget",
      colName: "colNum1assoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNum1assoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolNum1assoc_PROPERTIES
    },
    txtcolNum2: {
      name: "txtcolNum2",
      type: "TextBoxWidget",
      colName: "colNum2",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNum2_PROPERTIES

      // END_USER_CODE-USER_txtcolNum2_PROPERTIES
    },
    txtcolNum2assoc: {
      name: "txtcolNum2assoc",
      type: "TextBoxWidget",
      colName: "colNum2assoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNum2assoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolNum2assoc_PROPERTIES
    },
    txtcolOpt: {
      name: "txtcolOpt",
      type: "TextBoxWidget",
      colName: "colOpt",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpt_PROPERTIES

      // END_USER_CODE-USER_txtcolOpt_PROPERTIES
    },
    txtcolOptassoc: {
      name: "txtcolOptassoc",
      type: "TextBoxWidget",
      colName: "colOptassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOptassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolOptassoc_PROPERTIES
    },
    txtcolProceed: {
      name: "txtcolProceed",
      type: "TextBoxWidget",
      colName: "colProceed",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceed_PROPERTIES

      // END_USER_CODE-USER_txtcolProceed_PROPERTIES
    },
    txtcolProceedassoc: {
      name: "txtcolProceedassoc",
      type: "TextBoxWidget",
      colName: "colProceedassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceedassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolProceedassoc_PROPERTIES
    },
    txtcolRemit: {
      name: "txtcolRemit",
      type: "TextBoxWidget",
      colName: "colRemit",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRemit_PROPERTIES

      // END_USER_CODE-USER_txtcolRemit_PROPERTIES
    },
    txtcolRemitassoc: {
      name: "txtcolRemitassoc",
      type: "TextBoxWidget",
      colName: "colRemitassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRemitassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolRemitassoc_PROPERTIES
    },
    txtcolSetlDate: {
      name: "txtcolSetlDate",
      type: "TextBoxWidget",
      colName: "colSetlDate",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSetlDate_PROPERTIES

      // END_USER_CODE-USER_txtcolSetlDate_PROPERTIES
    },
    txtcolSetlDateassoc: {
      name: "txtcolSetlDateassoc",
      type: "TextBoxWidget",
      colName: "colSetlDateassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSetlDateassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolSetlDateassoc_PROPERTIES
    },
    txtcolType1: {
      name: "txtcolType1",
      type: "TextBoxWidget",
      colName: "colType1",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1_PROPERTIES

      // END_USER_CODE-USER_txtcolType1_PROPERTIES
    },
    txtcolPaySort: {
      name: "txtcolPaySort",
      type: "TextBoxWidget",
      colName: "colPaySort",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPaySort_PROPERTIES

      // END_USER_CODE-USER_txtcolPaySort_PROPERTIES
    },
    txtcolSettleNum: {
      name: "txtcolSettleNum",
      type: "TextBoxWidget",
      colName: "colSettleNum",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtcolSettleNum_PROPERTIES
    },
    txtcolType1assoc: {
      name: "txtcolType1assoc",
      type: "TextBoxWidget",
      colName: "colType1assoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1assoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolType1assoc_PROPERTIES
    },
    txtcolPaySortassoc: {
      name: "txtcolPaySortassoc",
      type: "TextBoxWidget",
      colName: "colPaySortassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPaySortassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolPaySortassoc_PROPERTIES
    },
       txtcolSettleNumassoc: {
      name: "txtcolSettleNumassoc",
      type: "TextBoxWidget",
      colName: "colSettleNumassoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSettleNumassoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolSettleNumassoc_PROPERTIES
    },
    txtcolType2: {
      name: "txtcolType2",
      type: "TextBoxWidget",
      colName: "colType2",
      parent: "gridAvlbReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2_PROPERTIES

      // END_USER_CODE-USER_txtcolType2_PROPERTIES
    },
    txtcolType2assoc: {
      name: "txtcolType2assoc",
      type: "TextBoxWidget",
      colName: "colType2assoc",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2assoc_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtcolType2assoc_PROPERTIES
    },
    txtInterest: {
      name: "txtInterest",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Interest:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInterest_PROPERTIES

      // END_USER_CODE-USER_txtInterest_PROPERTIES
    },
    lblBalance: {
      name: "lblBalance",
      type: "LabelWidget",
      parent: "grpbxArCr",
      Label: "Balance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBalance_PROPERTIES

      // END_USER_CODE-USER_lblBalance_PROPERTIES
    },
    txtLienhldr: {
      name: "txtLienhldr",
      type: "TextBoxWidget",
      parent: "grpbxRemarks",
      Label: "LienHolders:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLienhldr_PROPERTIES
      Readonly:true,
      Enabled:false
      // END_USER_CODE-USER_txtLienhldr_PROPERTIES
    },
    txtLoc: {
      name: "txtLoc",
      type: "TextBoxWidget",
      parent: "grpbxAgreeProf",
      Label: "Location:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoc_PROPERTIES

      // END_USER_CODE-USER_txtLoc_PROPERTIES
    },
    txtAgreement: {
      name: "txtAgreement",
      type: "TextBoxWidget",
      parent: "grpbxAgreeProf",
      Label: "Agreement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreement_PROPERTIES

      // END_USER_CODE-USER_txtAgreement_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      parent: "grpbxAgreeProf",
      Label: "Rate:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtRate_PROPERTIES

      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    txtValChck: {
      name: "txtValChck",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Value of Check:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValChck_PROPERTIES

      // END_USER_CODE-USER_txtValChck_PROPERTIES
    },
    txtValRtp: {
      name: "txtValRtp",
      type: "TextBoxWidget",
      parent: "grpbxArCr",
      Label: "Value of RTP's:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValRtp_PROPERTIES

      // END_USER_CODE-USER_txtValRtp_PROPERTIES
    },
    txtVendr: {
      name: "txtVendr",
      type: "TextBoxWidget",
      parent: "grpbxAgreeProf",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES

      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    txtVendrName: {
      name: "txtVendrName",
      type: "TextBoxWidget",
      parent: "grpbxAgreeProf",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendrName_PROPERTIES
      Readonly:true,
      Enabled:false,
      // END_USER_CODE-USER_txtVendrName_PROPERTIES
    },
    grpbxAgreeProf: {
      name: "grpbxAgreeProf",
      type: "GroupBoxWidget",
      parent: "InterestAgreementProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxAgreeProf_PROPERTIES

      // END_USER_CODE-USER_grpbxAgreeProf_PROPERTIES
    },
    grpbxGrids: {
      name: "grpbxGrids",
      type: "GroupBoxWidget",
      parent: "InterestAgreementProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxGrids_PROPERTIES

      // END_USER_CODE-USER_grpbxGrids_PROPERTIES
    },
    grpbxBottom: {
      name: "grpbxBottom",
      type: "GroupBoxWidget",
      parent: "InterestAgreementProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxBottom_PROPERTIES

      // END_USER_CODE-USER_grpbxBottom_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
      },
    },
  };

  let _buttonServices = { VendorLookup: {} };
  let _buttonNavigation = {
    btnVndr: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let screenData = getData(thisObj, 'frmInterestAgreementProfile')
    let js = {
      buyingPt     : screenData.lblLocation_Tag,
      buyingPtName : screenData.lblLocation_Caption,
      agreementNum : screenData.txtAgreementNumber,
      vendorNum    : screenData.txtVendorNumber,
      rate         : screenData.txtRate,
      action       : screenData.cmdOk_Caption.toLocaleUpperCase(),
      lblAddedBy   : screenData.lblAddedBy,
      lblChangedBy : screenData.lblChangedBy,
      beginDate    : screenData.txtBeginDateTime
    }
    setData(thisObj, 'vendorDetails', null)
    setData(thisObj, 'InterestAgreementData', js)
    FormLoad()
    setEventHandlers()

    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    if (getData(thisObj, "closingOfReportPreviewCheck")) {
      document.getElementsByClassName("close")[0].click();
    }
    setData(thisObj, 'closingOfReportPreviewCheck', false)
  }, [getData(thisObj, "closingOfReportPreviewCheck")])

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let vendorData = getData(thisObj, 'vendorDetails')
    if(vendorData !== null && vendorData !== undefined){
      thisObj.values.txtVendr = vendorData.VendorNumber
      setValue(thisObj, 'txtVendr', vendorData.VendorNumber)
      setData(thisObj, 'txtVendr', vendorData.VendorNumber)
      onbtnVndrClick()
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj,'vendorDetails')]);

  const setEventHandlers = () => {
    document.getElementById('txtRate').addEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtCllctSr').addEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtCllctAr').addEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtCllctCR').addEventListener('keypress', allowNumbersAndDecimal);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtRate').removeEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtCllctSr').removeEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtCllctAr').removeEventListener('keypress', allowNumbersAndDecimal);
    document.getElementById('txtCllctCR').removeEventListener('keypress', allowNumbersAndDecimal);
  };

  const allowNumbersAndDecimal = (e) => {
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || (!e.target.value.includes('.') && e.keyCode === 46) || e.keyCode === 8)) {
        e.preventDefault();
      }
    } catch (er){
      errorHandler(err)
    }
  };

  // START_USER_CODE-USER_METHODS
  async function FormLoad(){
    try{
      let screenData = getData(thisObj, 'InterestAgreementData')    
      
      setValue(thisObj, 'txtLoc', screenData.buyingPtName)
      setData(thisObj, 'MstrRefindVendor', 'Y')
      setData(thisObj, 'mbSuccessfulLoad', true)
      setValue(thisObj, 'txtAddedBy', screenData.lblAddedBy)
      setValue(thisObj, 'txtChangedBy', screenData.lblChangedBy)
      document.getElementById('btnSave').innerHTML = screenData.action.toLocaleUpperCase() == 'UPDATE' ? "Update" : "Add"
      disable(thisObj, 'txtLoc')
      if(screenData.action.toLocaleUpperCase() == 'UPDATE'){        
        thisObj.values.txtLoc = screenData.buyingPtName
        thisObj.values.txtVendr = screenData.vendorNum
        thisObj.values.txtAgreement = screenData.agreementNum
        setValue(thisObj, 'txtVendr', screenData.vendorNum)
        setValue(thisObj, 'txtAgreement', screenData.agreementNum)
        setValue(thisObj, 'txtRate', screenData.rate)
        setValue(thisObj, 'dtIntrstBegn', screenData.beginDate)
        setData(thisObj, 'txtVendr', screenData.vendorNum)
        setData(thisObj, 'txtAgreement', screenData.agreementNum)
        setData(thisObj, 'txtRate', screenData.rate)
        setData(thisObj, 'dtIntrstBegn', screenData.beginDate)
        disable(thisObj, 'txtAgreement')
        disable(thisObj, 'txtVendr') 
        disable(thisObj, 'btnVndr')
        await bFillRemitToList(screenData.vendorNum)
      }
      else if(screenData.action.toLocaleUpperCase() == 'ADD'){
        thisObj.values.txtLoc = ""
        thisObj.values.txtVendr = ""
        thisObj.values.txtAgreement = ""
        thisObj.values.txtVendrName = ""
        setValue(thisObj, 'txtVendr', "")
        setValue(thisObj, 'txtAgreement', "")
        setValue(thisObj, 'txtRate', "")
        setValue(thisObj, 'dtIntrstBegn', "")
        setValue(thisObj, "txtVendrName", "")
        setData(thisObj, 'txtVendr', "")
        setData(thisObj, 'txtAgreement', "")
        setData(thisObj, 'txtRate', "")
        setData(thisObj, 'dtIntrstBegn', "")
        setData(thisObj, "txtVendrName", "")
        enable(thisObj, 'txtAgreement')
        enable(thisObj, 'txtVendr') 
        enable(thisObj, 'btnVndr')
      }
      setValue(thisObj, 'txtAvlbRadyToPays', "0")
      setValue(thisObj, 'txtAssociatedReadyToPay', "0")

      disable(thisObj, "txtValRtp")
      disable(thisObj, "txtInterest")
      disable(thisObj, "txtValChck")
      disable(thisObj, "txtBlnc")
      disable(thisObj, "txtBlnc2")
      disable(thisObj, "txtBlnc3")
      await ontxtVendrNameChange()
    }
    catch(err){
      errorHandler(err)
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
    finally{
      setLoading(false)
    }
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function toPascalCase(word){
    try{
      return word.replace(/\w+/g, 
        function(w){
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
    }
    catch(err){
      errorHandler(err)
    }
  }

  function bSettlePad(strSettle){
    try{
      strSettle = strSettle.toString()
      if(strSettle.trim().length < 7 && strSettle.trim().length > 0){
        return "0".repeat(7 - strSettle.length) + strSettle.trim()
      }
      return strSettle;
    }
    catch(err){
      errorHandler(err)
    }
  }
  
  function bAuditPad(strAudit){
    try{
      strAudit = strAudit.toString()
      if(strAudit.trim().length < 7 && strAudit.trim().length > 0){
        return "0".repeat(7 - strAudit.length) + strAudit.trim()
      }
      return strAudit;
    }
    catch(err){
      errorHandler(err)
    }
  }

  function FormatSettlementNumber(settleNumber){
    try{
      return settleNumber;
    }
    catch(err){
      errorHandler(err)
    }
  }

  function ClearTexts(){
    try{
      setValue(thisObj, 'txtCllctAr', '0.00')
      setValue(thisObj, 'txtCllctCR', '0.00')
      setValue(thisObj, 'txtCllctSr', '0.00')
    }
    catch(err){
      errorHandler(err)
    }
  }

  function EnableDisableAll(indBln){
    try{
      if(indBln){
        enable(thisObj, 'txtCllctAr')
        enable(thisObj, 'txtCllctCR')
        enable(thisObj, 'txtCllctSr')
      }
      else{
        disable(thisObj, 'txtCllctAr')
        disable(thisObj, 'txtCllctCR')
        disable(thisObj, 'txtCllctSr')
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  function EnableDisableTextBoxes(value){
    //let value = getValue(thisObj, 'txtValRtp')
    if(Number(value) <= 0){
      ClearTexts();
      EnableDisableAll(false)
      return
    }
    if(getValue(thisObj, 'txtBlnc') > 0){
      enable(thisObj, 'txtCllctSr')
    }
    else{
      disable(thisObj, 'txtCllctSr')
    }

    if(getValue(thisObj, 'txtBlnc2') > 0){
      enable(thisObj, 'txtCllctAr')
    }
    else{
      disable(thisObj, 'txtCllctAr')
    }

    if(getValue(thisObj, 'txtBlnc3') > 0){
      enable(thisObj, 'txtCllctCR')
    }
    else{
      disable(thisObj, 'txtCllctCR')
    }
  }

  function checkAgainstValueOfCheck(txtName, txtValue){
    try{
      let ldblValueOfCheck = 0;
      if(txtName !== 'SR'){
        ldblValueOfCheck += Number(([undefined, null].includes(getValue(thisObj, 'txtCllctSr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctSr')) ? '' : getData(thisObj, 'txtCllctSr') : getValue(thisObj, 'txtCllctSr')))
      }
      if(txtName !== 'AR'){
        ldblValueOfCheck += Number(([undefined, null].includes(getValue(thisObj, 'txtCllctAr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctAr')) ? '' : getData(thisObj, 'txtCllctAr') : getValue(thisObj, 'txtCllctAr')))
      }
      if(txtName !== 'CR'){
        ldblValueOfCheck += Number(([undefined, null].includes(getValue(thisObj, 'txtCllctCR')) ? [undefined, null].includes(getData(thisObj, 'txtCllctCR')) ? '' : getData(thisObj, 'txtCllctCR') : getValue(thisObj, 'txtCllctCR')))
      }

      let lblValueOfRTPs = Number(([undefined, null].includes(getValue(thisObj, 'txtValRtp')) ? [undefined, null].includes(getData(thisObj, 'txtValRtp')) ? '' : getData(thisObj, 'txtValRtp') : getValue(thisObj, 'txtValRtp')))
      let lblInterest = Number(([undefined, null].includes(getValue(thisObj, 'txtInterest')) ? [undefined, null].includes(getData(thisObj, 'txtInterest')) ? '' : getData(thisObj, 'txtInterest') : getValue(thisObj, 'txtInterest')))
      
      if(txtValue + ldblValueOfCheck > lblValueOfRTPs + lblInterest){
        txtValue = Number(lblValueOfRTPs + lblInterest - ldblValueOfCheck).toFixed(2)
        if(txtName == 'SR'){
          setValue(thisObj, 'txtCllctSr', txtValue)
          setData(thisObj, 'txtCllctSr', txtValue)
        }
        else if(txtName == 'AR'){
          setValue(thisObj, 'txtCllctAr', txtValue)
          setData(thisObj, 'txtCllctAr', txtValue)
        }
        else if(txtName == 'CR'){
          setValue(thisObj, 'txtCllctCR', txtValue)
          setData(thisObj, 'txtCllctCR', txtValue)
        }
      }      
    }
    catch(err){
      errorHandler(err)
    }
  }

  function FormatCheckNumber(lstrCheckNumber){
    try{
      lstrCheckNumber = lstrCheckNumber.toString()
      if(lstrCheckNumber.indexOf('S') !== -1)
        return 'S' + '0'.repeat(5- lstrCheckNumber.length) + lstrCheckNumber      
      return '0'.repeat(6- lstrCheckNumber.length) + lstrCheckNumber      
    } 
    catch(err){
      errorHandler(err);
    }
  }

  function errorHandler(err){
    showMessage(thisObj, err.message)
  }

  async function GetTransSumProceeds(lstrxml, strMemoNum, dblRtpProceeds, n){
    try{
      let strTransNum = "";
      let dblAmount = 0;

      for(var i=n; i<lstrxml.length; i++){
        if(lstrxml[i].memo_num.trim() !== "" && strMemoNum == lstrxml[i].memo_num.trim()){
          strTransNum += (lstrxml[i].transaction_num + ",")
          dblAmount += lstrxml[i].rtp_proceeds          
        }
        else{
          break;
        }
      }

      if(strTransNum.slice(-1) == ","){
        strTransNum = strTransNum.slice(0,-1)
      }
      return [ strTransNum, dblAmount ]
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function bCheck_AR_CR_SR(){
    try{
      let flag = false
      
      let crBal = getValue(thisObj, 'txtBlnc3')
      let crColl = getValue(thisObj, "txtCllctCR")
      if(Number(crBal > 0) && Number(crColl) == 0){
        flag = true
      }

      let srBal = getValue(thisObj, 'txtBlnc')
      let srColl = getValue(thisObj, "txtCllctSr")
      if(Number(srBal > 0) && Number(srColl) == 0){
        flag = true
      }

      let arBal = getValue(thisObj, 'txtBlnc2')
      let arColl = getValue(thisObj, "txtCllctAr")
      if(Number(arBal > 0) && Number(arColl) == 0){
        flag = true
      }
      return flag;
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function SetSelectStatus(){
    try{
      let bSelectedAll = false;

      let assocList = getValue(thisObj, 'gridAssocReady')
      for(var i=0; i<assocList.length; i++){
        if(assocList[i].chkbxCol){
          bSelectedAll = true
        }
        else{
          bSelectedAll = false;
          break;
        }
      }

      if(bSelectedAll){
        document.getElementById('btnSelectAll').innerHTML = "UNSELECT_ALL"
      }
      else{
        document.getElementById('btnSelectAll').innerHTML = "SELECT_ALL"
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function SetRemitTo(assocList){
    try{
      let strRemit = ""
      let bRemitDiffer = false;
      let key = 0
      // let assocList = getValue(thisObj, 'gridAssocReady')
      let lstAgree = [ undefined, null ].includes(getData(thisObj, 'lstAgreementRemitTo')) ? [] : getData(thisObj, 'lstAgreementRemitTo')
      
      if(assocList.length > 0){
        strRemit = assocList[0].txtcolRemitassoc
        for(var i=0; i<assocList.length; i++){
          if(strRemit !== assocList[i].txtcolRemitassoc){
            bRemitDiffer = true;
            strRemit = ""
            break;
          }
          bRemitDiffer = false;
        }
        

        if(lstAgree.length > 0){
          if(bRemitDiffer){
            key = null
          }
          else{
            key = null
            for(var i=0; i<lstAgree.length; i++){
              key = null
              if(lstAgree[i].key == strRemit.trim()){
                key = i
                break;
              }
            }
          }
        }
        else if(lstAgree.length == 0){
          key = 0
        }
        else{
          key = null
        }
      }
      else{
        key = null
      }
      setValue(thisObj, 'ddRemit', key == null ? '' : lstAgree.at(key).key)
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function Calc(){
    try{
      let n = 0;
      let nn = 0;
      let nnn = 0;
      let MdblDays = 0;

      let srColl = [undefined, null].includes(getValue(thisObj, 'txtCllctSr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctSr')) ? '' : getData(thisObj, 'txtCllctSr') : getValue(thisObj, 'txtCllctSr')
      let arColl = [undefined, null].includes(getValue(thisObj, 'txtCllctAr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctAr')) ? '' : getData(thisObj, 'txtCllctAr') : getValue(thisObj, 'txtCllctAr')
      let crColl = [undefined, null].includes(getValue(thisObj, 'txtCllctCR')) ? [undefined, null].includes(getData(thisObj, 'txtCllctCR')) ? '' : getData(thisObj, 'txtCllctCR') : getValue(thisObj, 'txtCllctCR')

      setValue(thisObj, 'txtCllctSr', Number(srColl).toFixed(2))
      setValue(thisObj, 'txtCllctAr', Number(arColl).toFixed(2))
      setValue(thisObj, 'txtCllctCR', Number(crColl).toFixed(2))
      setData(thisObj, 'txtCllctSr', Number(srColl).toFixed(2))
      setData(thisObj, 'txtCllctAr', Number(arColl).toFixed(2))
      setData(thisObj, 'txtCllctCR', Number(crColl).toFixed(2))
      
      let assocList = getValue(thisObj, 'gridAssocReady')
      let beginDate = [undefined, null].includes(getValue(thisObj, 'dtIntrstBegn')) ? [undefined, null].includes(getData(thisObj, 'dtIntrstBegn')) ? '' : getData(thisObj, 'dtIntrstBegn') : getValue(thisObj, 'dtIntrstBegn')
      let rate = [undefined, null].includes(getValue(thisObj, 'txtRate')) ? [undefined, null].includes(getData(thisObj, 'txtRate')) ? '' : getData(thisObj, 'txtRate') : getValue(thisObj, 'txtRate')
      beginDate = beginDate == '' ? '' : moment(beginDate).format('MM/DD/YYYY')
      
      for(var i=0; i<assocList.length; i++){
        if(assocList[i].chkbxCol){
          nn += parseFloat(assocList[i].txtcolProceedassoc)
          if(parseFloat(assocList[i].txtcolProceedassoc) >= 0){
            if(beginDate.trim() == ""){
              let date1 = new Date()
              let date2 = new Date(assocList[i].txtcolDateassoc)
              MdblDays = (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
            }
            else{
              let date1 = new Date(assocList[i].txtcolDateassoc)
              let date2 = new Date(beginDate)
              if(date1 > date2){
                MdblDays = ((new Date()).getTime() - date1.getTime()) / (1000 * 3600 * 24);
              }
              else{
                MdblDays = ((new Date()).getTime() - date2.getTime()) / (1000 * 3600 * 24);
              }
            }
            nnn += (parseFloat(assocList[i].txtcolProceedassoc) * (Number(rate) * 0.01) * (parseInt(MdblDays) / 365))
          }
        }
      }
      
      setValue(thisObj, 'txtInterest', nnn > 0 ? Number(nnn).toFixed(2) : "0.00")
      setValue(thisObj, 'txtValRtp', Number(nn).toFixed(2))

      let valueOfCheck = Number(Number(nnn > 0 ? nnn : 0) + Number(nn) - Number(srColl) - Number(arColl) - Number(crColl)).toFixed(2)
      setValue(thisObj, 'txtValChck', ['-0', '-0.00'].includes(valueOfCheck) ? '0.00' : valueOfCheck)
      await ontxtValChckChange()
      EnableDisableTextBoxes(Number(nn).toFixed(2));
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function bFormValid(){
    try{
      let bDataValid = true;
      let bFormValidBool = false;

      let lstAgree = [ undefined, null ].includes(getData(thisObj, 'lstAgreementRemitTo')) ? [] : getData(thisObj, 'lstAgreementRemitTo')
      if(isEnabled(thisObj, 'txtVendr')){
        if(lstAgree.length <= 0){
          alert("A valid vendor is required.")
          document.getElementById('txtVendr').focus();
          setValue(thisObj, 'txtVendr', thisObj.values.txtVendr)
          return;
        }
      }

      let agreementNum = getValue(thisObj, 'txtAgreement')
      if(isNumeric(agreementNum)){
        if(Number(agreementNum) < 0 || Number(agreementNum) > 999999){
          bDataValid = false;
        }
      }
      else{
        bDataValid = false;
      }

      if(!bDataValid){
        alert("Agreement Number must be of format ######")
        document.getElementById('txtAgreement').focus();
        setValue(thisObj, 'txtAgreement', agreementNum)
        return;
      }

      let rate = getValue(thisObj, 'txtRate')
      if(isNumeric(rate)){
        if(Number(rate) < 0 || Number(rate) > 999.9999){
          bDataValid = false;
        }
      }
      else{
        bDataValid = false;
      }

      if(!bDataValid){
        alert("Rate must be of format ####.####")
        document.getElementById('txtRate').focus();
        setValue(thisObj, 'txtRate', thisObj.values.rate)
        return;
      }
      bFormValidBool = true;

      return bFormValidBool;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  async function GetNextCheckNum(){
    try{
      let lstrxml = []
      if(!isNaN(getData(thisObj, 'txtCheckNumber'))){
        lstrxml = ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, getData(thisObj, 'lblCheckNumber'))
        if(lstrxml.length > 0){
          setData(thisObj, 'chknumber', FormatCheckNumber(lstrxml[0].next_check.trim()))
          setData(thisObj, 'txtCheckNumber', FormatCheckNumber(lstrxml[0].next_check.trim()))
        }
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function bFillRemitToList(vendorNum){
    try{
      if([undefined , null].includes(vendorNum)){
        vendorNum = thisObj.values.txtVendr
      }
      let vendorNumber = vendorNum
      let js = [{
        key : '',
        description : '>>> ALL <<<'
      }]
      let LstrList = await ContractManagementService.RetieveRemitDetails(vendorNumber)
      if(LstrList.length > 0){
        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddRemitTo', '')
        
        setValue(thisObj, 'txtVendrName', LstrList[0].name)
        setData(thisObj, 'txtVendrName', LstrList[0].name)
        thisObj.values.txtVendrName = LstrList[0].name
        
        for(var i=0; i<LstrList.length;i++){
          js.push({
            key : LstrList[i].remittoid,
            description : LstrList[i].remittoid + " - " + toPascalCase(LstrList[i].name) + " - " + toPascalCase(LstrList[i].city) + ", " + toPascalCase(LstrList[i].state)
          })
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js
            }
          }
        })
        
        setValue(thisObj, 'ddRemitTo', js.at(0).key)
        setData(thisObj, 'ddRemitTo', js.at(0).key)
      }
      else{
        if([undefined, null, ""].includes(thisObj.values.txtVendr)){
          js = []
        }

        setValue(thisObj, 'txtVendrName', "")
        setData(thisObj, 'txtVendrName', "")
        thisObj.values.txtVendrName = ""

        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddRemitTo', '')

        thisObj.setState(current => {
          return {
            ...current,
            ddRemit: {
              ...state["ddRemit"],
              valueList: []
            }
          }
        })
        setData(thisObj, 'lstAgreementRemitTo', [])
        setValue(thisObj, 'ddRemit', '')
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function bFillAgreementRemitToList(vendorNum){
    try{
      let LstrList = []
      let bFillAgreementRemitToListBool = true
      let vendorNumber = vendorNum

      LstrList = await ContractManagementService.RetrieveVendorByNumber(vendorNumber)
      if(LstrList.length > 0){
        setValue(thisObj, 'txtVendrName', toPascalCase(LstrList[0].name))
        setData(thisObj, 'txtVendrName', toPascalCase(LstrList[0].name))
        thisObj.values.txtVendrName = toPascalCase(LstrList[0].name)

        LstrList = await ContractManagementService.RetieveRemitDetails(vendorNumber)
        if(LstrList.length > 0){
          thisObj.setState(current => {
            return {
              ...current,
              ddRemit: {
                ...state["ddRemit"],
                valueList: []
              }
            }
          })
          setData(thisObj, 'lstAgreementRemitTo', [])
          setValue(thisObj, 'ddRemit', '') 
          
          let js = []
          for(var i=0; i<LstrList.length;i++){
            js.push({
              key : LstrList[i].remittoid,
              description : LstrList[i].remittoid + " - " + toPascalCase(LstrList[i].name) + " - " + toPascalCase(LstrList[i].city) + ", " + toPascalCase(LstrList[i].state)
            })
          }

          thisObj.setState(current => {
            return {
              ...current,
              ddRemit: {
                ...state["ddRemit"],
                valueList: js
              }
            }
          })
          setValue(thisObj, 'ddRemit', js.length > 0 ? '' : js.at(0).key)
          setData(thisObj, 'lstAgreementRemitTo', js)
        }
        else{
          bFillAgreementRemitToListBool = false  
        }
      }
      else{
        setValue(thisObj, 'txtVendrName', "")
        setData(thisObj, "txtVendrName", "")
        thisObj.values.txtVendrName = ""

        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: [{
                key : '',
                description : '>>> ALL <<<'
              }]
            }
          }
        })
        setValue(thisObj, 'ddRemitTo', '')

        thisObj.setState(current => {
          return {
            ...current,
            ddRemit: {
              ...state["ddRemit"],
              valueList: []
            }
          }
        })
        setData(thisObj, 'lstAgreementRemitTo', [])
        setValue(thisObj, 'ddRemit', '')
        bFillAgreementRemitToListBool = false
      }
    return bFillAgreementRemitToListBool;
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function bFillLienholders(vendorNum){
    try{
      let js= ''
      let bFillLienholdersBool = false;
      let vendorNumber = vendorNum 
      setValue(thisObj, 'txtLienhldr', js)

      let LstrList = await AccountPayment.RetrieveLienHolderControlDetails(compIdFromLS(), cropYearFromLS(), vendorNumber, null)
      if(LstrList.length > 0){
        for(var i=0; i<LstrList.length; i++){
          js += (LstrList[i].lienholder_name + ", ")
        }
        bFillLienholdersBool = true;
      }

      if(js.length > 0){
        setValue(thisObj, 'txtLienhldr', js.slice(0, -2))
      }
      return bFillLienholdersBool;
    }
    catch(err){
      errorHandler(err)
      return false;
    }
  }

  async function DoSearch(){
    try{
      setLoading(true)
      
      let lstrxml = []
      let LstrList = []
      let strMemoNum = ""
      let bRowInserted = false
      let dblRtpProceeds = 0

      setData(thisObj, 'txtCheckNumber', "")
      setValue(thisObj, 'txtValChck', "")
      setValue(thisObj, 'txtLienhldr', "")
      setValue(thisObj, 'txtInterest', "")
      setValue(thisObj, 'txtBlnc', "")
      setValue(thisObj, 'txtBlnc2', "")
      setValue(thisObj, 'txtBlnc3', "")
      setValue(thisObj, 'txtCllctSr', "")
      setValue(thisObj, 'txtCllctCR', "")
      setValue(thisObj, 'txtCllctAr', "")
      setValue(thisObj, 'txtValRtp', "")

      LstrList = await ContractManagementService.RetrieveAccessPermissionDetails('PN1030', '001', 'U', null)
      if(LstrList[0].permission.toLocaleUpperCase() == 'Y'){
        setData(thisObj, 'cmdAddTag', "Y")
      }

      let availableRTPSCount = 0
      let availableRTPSArray = []
      let associateRTPSArray = []    
      
      let buyingPt = thisObj.values.txtLoc.split("-")[0].trim()
      let payee_vendor = thisObj.values.txtVendr

      let audit_farm_id = null
      let audit_farm_suffix = null
      let audit_insp_num = null
      let audit_cont_num = null
      let audit_settle_num = null
      let audit_whse_rcpt = null
      let rtp_proceeds = null
      let rtp_status = "H"
      let batch_number = null
      let rtp_payee_remit = getValue(thisObj, 'ddRemitTo')
      let special_type_none = null
      let rtp_status_hr = null
      let agreementNum = thisObj.values.txtAgreement

      hideColumn(thisObj,'gridAvlbReady', 'txtcolPaySort')
      hideColumn(thisObj,'gridAvlbReady', 'txtcolSettleNum')

      hideColumn(thisObj,'gridAssocReady', 'txtcolPaySortassoc')
      hideColumn(thisObj,'gridAssocReady', 'txtcolSettleNumassoc')

      lstrxml = await AccountPayment.RetrieveReadyToPaymentDetails(buyingPt, payee_vendor, audit_farm_id, audit_farm_suffix, audit_insp_num, audit_cont_num, audit_settle_num, audit_whse_rcpt, rtp_proceeds, rtp_status, batch_number, rtp_payee_remit, special_type_none, rtp_status_hr)
      for(var i=0; i<lstrxml.length; i++){
        var obj = {}
        bRowInserted = false;
        if(lstrxml[i].audit_insp_num.trim() !== ""){
          obj.rowId             = availableRTPSCount++;
          obj.rowData1          = lstrxml[i].transaction_num
          bRowInserted          = true 
          obj.txtcolType1       = lstrxml[i].pay_type_1
          obj.txtcolNum1        = lstrxml[i].pay_number_1
          obj.txtcolType2       = lstrxml[i].pay_type_2
          obj.txtcolNum2        = lstrxml[i].pay_number_2
          obj.txtcolProceed     = Number(lstrxml[i].rtp_proceeds).toFixed(2)
          obj.txtcolOpt         = lstrxml[i].pay_option_ind
          obj.txtcolDate        = moment(lstrxml[i].insp_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate    = moment(lstrxml[i].settlement_date).format('MM/DD/YYYY')
          obj.txtcolSettleNum   = "I" + bSettlePad(FormatSettlementNumber(lstrxml[i].audit_settle_num))
          obj.txtcolPaySort     = lstrxml[i].pay_sort
          obj.txtcolRemit       = lstrxml[i].payee_remit
          obj.color             = ""
          availableRTPSArray.push(obj)
        }
        else if(lstrxml[i].audit_trade_insp.trim() !== ""){
          obj.rowId             = availableRTPSCount++;
          obj.rowData1          = lstrxml[i].transaction_num
          bRowInserted          = true 
          obj.txtcolType1       = lstrxml[i].pay_type_1
          obj.txtcolNum1        = lstrxml[i].pay_number_1
          obj.txtcolType2       = lstrxml[i].pay_type_2
          obj.txtcolNum2        = lstrxml[i].pay_number_2
          obj.txtcolProceed     = Number(lstrxml[i].rtp_proceeds).toFixed(2)
          obj.txtcolOpt         = lstrxml[i].pay_option_ind
          obj.txtcolDate        = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate    = ""
          obj.txtcolSettleNum   = "T" + lstrxml[i].audit_settle_num
          obj.txtcolPaySort     = lstrxml[i].pay_sort
          obj.txtcolRemit       = lstrxml[i].payee_remit
          obj.color             = ""
          availableRTPSArray.push(obj)
        }
        else{
          if(lstrxml[i].audit_whse_rcpt !== 0){
            obj.rowId           = availableRTPSCount++;
            obj.rowData1        = lstrxml[i].transaction_num
            bRowInserted        = true
            obj.txtcolType1     = lstrxml[i].pay_type_1
            obj.txtcolNum1      = lstrxml[i].pay_number_1
            obj.txtcolType2     = lstrxml[i].pay_type_2
            obj.txtcolNum2      = lstrxml[i].pay_number_2
            obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
            obj.txtcolOpt       = lstrxml[i].pay_option_ind
            obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate  = ""
            obj.txtcolSettleNum = "WR" + bAuditPad(lstrxml[i].pay_number_1)
            obj.txtcolPaySort   = lstrxml[i].pay_sort
            obj.txtcolRemit     = lstrxml[i].payee_remit
            obj.color           = "HFFC0C0"
            availableRTPSArray.push(obj)
          }
          if(lstrxml[i].audit_cr_check.trim() !== "" && lstrxml[i].audit_cr_vendor.trim() !== ""){
            obj.rowId           = availableRTPSCount++;
            obj.rowData1        = lstrxml[i].transaction_num
            bRowInserted        = true
            obj.txtcolType1     = lstrxml[i].pay_type_1
            obj.txtcolNum1      = lstrxml[i].pay_number_1
            obj.txtcolType2     = lstrxml[i].pay_type_2
            obj.txtcolNum2      = lstrxml[i].pay_number_2
            obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
            obj.txtcolOpt       = lstrxml[i].pay_option_ind
            obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate  = ""
            obj.txtcolSettleNum = "CR" + bAuditPad(lstrxml[i].pay_number_1)
            obj.txtcolPaySort   = lstrxml[i].pay_sort
            obj.txtcolRemit     = lstrxml[i].payee_remit
            obj.color           = ""
            availableRTPSArray.push(obj)
          }
          if(lstrxml[i].audit_cont_num !== 0){
            obj.rowId           = availableRTPSCount++;
            obj.rowData1        = lstrxml[i].transaction_num
            bRowInserted        = true
            obj.txtcolType1     = lstrxml[i].pay_type_1
            obj.txtcolNum1      = lstrxml[i].pay_number_1
            obj.txtcolType2     = lstrxml[i].pay_type_2
            obj.txtcolNum2      = lstrxml[i].pay_number_2
            obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
            obj.txtcolOpt       = lstrxml[i].pay_option_ind
            obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate  = ""
            obj.txtcolSettleNum = "Cont" + bAuditPad(lstrxml[i].pay_number_1)
            obj.txtcolPaySort   = lstrxml[i].pay_sort
            obj.txtcolRemit     = lstrxml[i].payee_remit
            obj.color           = ""
            availableRTPSArray.push(obj)
          }
          if(lstrxml[i].memo_num.trim() !== "" && lstrxml[i].rtp_proceeds !== 0 && lstrxml[i].memo_num !== strMemoNum){
            obj.rowId          = availableRTPSCount++;
            let res            = await GetTransSumProceeds(lstrxml, lstrxml[i].memo_num.trim(), dblRtpProceeds, i)
            dblRtpProceeds     = Number(res[1])
            obj.rowData1       = res[0]
            obj.txtcolType1    = lstrxml[i].pay_type_1
            obj.txtcolNum1     = lstrxml[i].pay_number_1
            obj.txtcolType2    = lstrxml[i].pay_type_2
            obj.txtcolNum2     = lstrxml[i].pay_number_2
            obj.txtcolProceed  = Number(dblRtpProceeds).toFixed(2)
            obj.txtcolOpt      = lstrxml[i].pay_option_ind
            obj.txtcolDate     = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate = ""
            obj.txtcolPaySort  = lstrxml[i].pay_sort
            obj.txtcolRemit    = lstrxml[i].payee_remit
            strMemoNum         = lstrxml[i].memo_num
            obj.color          = ""
            availableRTPSArray.push(obj)
          }          
        }

        if(lstrxml[i].audit_delv_agree_num.trim() !== ""){
          obj.rowId           = availableRTPSCount++;
          obj.rowData1        = lstrxml[i].transaction_num
          bRowInserted        = true 
          obj.txtcolType1     = lstrxml[i].pay_type_1
          obj.txtcolNum1      = lstrxml[i].pay_number_1
          obj.txtcolType2     = lstrxml[i].pay_type_2
          obj.txtcolNum2      = lstrxml[i].pay_number_2
          obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
          obj.txtcolOpt       = lstrxml[i].pay_option_ind
          obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate  = ""
          obj.txtcolSettleNum = "Delv" + bSettlePad(FormatSettlementNumber(lstrxml[i].pay_number_2))
          obj.txtcolPaySort   = lstrxml[i].pay_sort
          obj.txtcolRemit     = lstrxml[i].payee_remit
          obj.color           = ""
          availableRTPSArray.push(obj)
        }
        
        if(lstrxml[i].intr_agree_num.toLocaleUpperCase().trim() == agreementNum.toLocaleUpperCase().trim() && bRowInserted){
          obj.rowId = availableRTPSCount--;
          let res = availableRTPSArray.pop()
          if(res !== undefined && res !== null){
            if(res.color !== "HE0E0E0" && !(res.color == "HFFC0C0" && getData(thisObj, 'cmdAddTag') !== 'Y')){
              let js = {
                rowId                : res.rowId,
                rowData1             : res.rowData1,
                txtcolType1assoc     : res.txtcolType1,
                txtcolNum1assoc      : res.txtcolNum1,
                txtcolType2assoc     : res.txtcolType2,
                txtcolNum2assoc      : res.txtcolNum2,
                txtcolProceedassoc   : res.txtcolProceed,
                txtcolOptassoc       : res.txtcolOpt,
                txtcolDateassoc      : res.txtcolDate,
                txtcolSetlDateassoc  : res.txtcolSetlDate,
                txtcolSettleNumassoc : "",
                txtcolPaySortassoc   : res.txtcolPaySort,
                txtcolRemitassoc     : res.txtcolRemit,
                chkbxCol             : false,
                color                : res.color
              }
              if(res.color == "HFFC0C0"){
                js.color = "HFFC0C0"
              }
              associateRTPSArray.push(js)
            }
          }
          else{
            availableRTPSArray.push(res)
          }
        }
      }

      strMemoNum = ""
      dblRtpProceeds = 0
      rtp_status = "R"

      lstrxml = await AccountPayment.RetrieveReadyToPaymentDetails(buyingPt, payee_vendor, audit_farm_id, audit_farm_suffix, audit_insp_num, audit_cont_num, audit_settle_num, audit_whse_rcpt, rtp_proceeds, rtp_status, batch_number, rtp_payee_remit, special_type_none, rtp_status_hr)
      for(var i=0; i<lstrxml.length; i++){
        var obj = {}
        bRowInserted = false;
        if(lstrxml[i].audit_insp_num.trim() !== ""){
          obj.rowId           = availableRTPSCount++;
          obj.rowData1        = lstrxml[i].transaction_num
          bRowInserted        = true 
          obj.txtcolType1     = lstrxml[i].pay_type_1
          obj.txtcolNum1      = lstrxml[i].pay_number_1
          obj.txtcolType2     = lstrxml[i].pay_type_2
          obj.txtcolNum2      = lstrxml[i].pay_number_2
          obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
          obj.txtcolOpt       = lstrxml[i].pay_option_ind
          obj.txtcolDate      = moment(lstrxml[i].insp_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate  = moment(lstrxml[i].settlement_date).format('MM/DD/YYYY')
          obj.txtcolSettleNum = "I" + bSettlePad(FormatSettlementNumber(lstrxml[i].audit_settle_num))
          obj.txtcolPaySort   = lstrxml[i].pay_sort
          obj.txtcolRemit     = lstrxml[i].payee_remit
          obj.color           = ""
          availableRTPSArray.push(obj)
        }
        else if(lstrxml[i].audit_trade_insp.trim() !== ""){
          obj.rowId           = availableRTPSCount++;
          obj.rowData1        = lstrxml[i].transaction_num
          bRowInserted        = true 
          obj.txtcolType1     = lstrxml[i].pay_type_1
          obj.txtcolNum1      = lstrxml[i].pay_number_1
          obj.txtcolType2     = lstrxml[i].pay_type_2
          obj.txtcolNum2      = lstrxml[i].pay_number_2
          obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
          obj.txtcolOpt       = lstrxml[i].pay_option_ind
          obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate  = ""
          obj.txtcolSettleNum = "T" + lstrxml[i].audit_settle_num
          obj.txtcolPaySort   = lstrxml[i].pay_sort
          obj.txtcolRemit     = lstrxml[i].payee_remit
          obj.color           = ""
          availableRTPSArray.push(obj)
        }
        else{
          if(lstrxml[i].audit_whse_rcpt !== 0){
            obj.rowId           = availableRTPSCount++;
            obj.rowData1        = lstrxml[i].transaction_num
            bRowInserted        = true
            obj.txtcolType1     = lstrxml[i].pay_type_1
            obj.txtcolNum1      = lstrxml[i].pay_number_1
            obj.txtcolType2     = lstrxml[i].pay_type_2
            obj.txtcolNum2      = lstrxml[i].pay_number_2
            obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
            obj.txtcolOpt       = lstrxml[i].pay_option_ind
            obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate  = ""
            obj.txtcolSettleNum = "WR" + bAuditPad(lstrxml[i].pay_number_1)
            obj.txtcolPaySort   = lstrxml[i].pay_sort
            obj.txtcolRemit     = lstrxml[i].payee_remit
            obj.color           = "HFFC0C0"
            availableRTPSArray.push(obj)
          }
          if(lstrxml[i].audit_cr_check.trim() !== "" && lstrxml[i].audit_cr_vendor.trim() !== ""){
            obj.rowId           = availableRTPSCount++;
            obj.rowData1        = lstrxml[i].transaction_num
            bRowInserted        = true
            obj.txtcolType1     = lstrxml[i].pay_type_1
            obj.txtcolNum1      = lstrxml[i].pay_number_1
            obj.txtcolType2     = lstrxml[i].pay_type_2
            obj.txtcolNum2      = lstrxml[i].pay_number_2
            obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
            obj.txtcolOpt       = lstrxml[i].pay_option_ind
            obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate  = ""
            obj.txtcolSettleNum = "CR" + bAuditPad(lstrxml[i].pay_number_1)
            obj.txtcolPaySort   = lstrxml[i].pay_sort
            obj.txtcolRemit     = lstrxml[i].payee_remit
            obj.color           = ""
            availableRTPSArray.push(obj)
          }
          if(lstrxml[i].audit_cont_num !== 0){
            obj.rowId           = availableRTPSCount++;
            obj.rowData1        = lstrxml[i].transaction_num
            bRowInserted        = true
            obj.txtcolType1     = lstrxml[i].pay_type_1
            obj.txtcolNum1      = lstrxml[i].pay_number_1
            obj.txtcolType2     = lstrxml[i].pay_type_2
            obj.txtcolNum2      = lstrxml[i].pay_number_2
            obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
            obj.txtcolOpt       = lstrxml[i].pay_option_ind
            obj.txtcolDate      = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
            obj.txtcolSetlDate  = ""
            obj.txtcolSettleNum = "Cont" + bAuditPad(lstrxml[i].pay_number_1)
            obj.txtcolPaySort   = lstrxml[i].pay_sort
            obj.txtcolRemit     = lstrxml[i].payee_remit
            obj.color           = ""
            availableRTPSArray.push(obj)
          }
        }
        
        if(lstrxml[i].intr_agree_num.toLocaleUpperCase().trim() == getData(thisObj, 'txtAgreement').toLocaleUpperCase().trim() ){
          obj.rowId = availableRTPSCount--;
          let res = availableRTPSArray.pop()
          if(res !== undefined && res !== null){
            associateRTPSArray.push({
              rowId                : res.rowId,
              rowData1             : res.rowData1,
              txtcolType1assoc     : res.txtcolType1,
              txtcolNum1assoc      : res.txtcolNum1,
              txtcolType2assoc     : res.txtcolType2,
              txtcolNum2assoc      : res.txtcolNum2,
              txtcolProceedassoc   : res.txtcolProceed,
              txtcolOptassoc       : res.txtcolOpt,
              txtcolDateassoc      : res.txtcolDate,
              txtcolSetlDateassoc  : res.txtcolSetlDate,
              txtcolSettleNumassoc : "",
              txtcolPaySortassoc   : res.txtcolPaySort,
              txtcolRemitassoc     : res.txtcolRemit,
              chkbxCol             : false,
              color                : res.color
            })
          }
        }

        if(lstrxml[i].audit_delv_agree_num.trim() !== ""){
          obj.rowId           = availableRTPSCount++;
          obj.rowData1        = lstrxml[i].transaction_num
          obj.txtcolType1     = lstrxml[i].pay_type_1
          obj.txtcolNum1      = lstrxml[i].pay_number_1
          obj.txtcolType2     = lstrxml[i].pay_type_2
          obj.txtcolNum2      = lstrxml[i].pay_number_2
          obj.txtcolProceed   = Number(lstrxml[i].rtp_proceeds).toFixed(2)
          obj.txtcolOpt       = lstrxml[i].pay_option_ind
          obj.txtcolDat       = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate  = ""
          obj.txtcolSettleNum = "Delv" + bSettlePad(FormatSettlementNumber(lstrxml[i].pay_number_2))
          obj.txtcolPaySort   = lstrxml[i].pay_sort
          obj.txtcolRemit     = lstrxml[i].payee_remit
          obj.color           = ""
          availableRTPSArray.push(obj)
        }

        if(lstrxml[i].memo_num.trim() !== "" && lstrxml[i].rtp_proceeds !== 0 && lstrxml[i].memo_num !== strMemoNum){
          obj.rowId = availableRTPSCount++;
          let res = await GetTransSumProceeds(lstrxml, lstrxml[i].memo_num.trim(), dblRtpProceeds, i)
          dblRtpProceeds = Number(res[1])
          obj.rowData1       = res[0]
          bRowInserted       = true
          obj.txtcolType1    = lstrxml[i].pay_type_1
          obj.txtcolNum1     = lstrxml[i].pay_number_1
          obj.txtcolType2    = lstrxml[i].pay_type_2
          obj.txtcolNum2     = lstrxml[i].pay_number_2
          obj.txtcolProceed  = Number(dblRtpProceeds).toFixed(2)
          obj.txtcolOpt      = lstrxml[i].pay_option_ind
          obj.txtcolDate     = moment(lstrxml[i].chg_date_time).format('MM/DD/YYYY')
          obj.txtcolSetlDate = ""
          obj.txtcolPaySort  = lstrxml[i].pay_sort
          obj.txtcolRemit    = lstrxml[i].payee_remit
          obj.color          = ""
          strMemoNum         = lstrxml[i].memo_num
          availableRTPSArray.push(obj)
        }
      }
      
      await SetRemitTo(associateRTPSArray)

      if(!await bFillLienholders(payee_vendor)){
      }

    lstrxml = await ContractManagementService.RetrieveUserControlDetails(useridFromLS(), null, null, null)
    setData(thisObj, 'lblCheckNumber', lstrxml[0].buyPtId)
    if(lstrxml.length > 0){
      lstrxml = await ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, lstrxml[0].buyPtId)
      if(lstrxml.length > 0){
        setData(thisObj, 'txtEndCheckNumber', lstrxml[0].end_check)
        setData(thisObj, 'chknumber', lstrxml[0].next_check)
        setData(thisObj, 'LstrReceivable_Num', lstrxml[0].new_receivable_no)
        setData(thisObj, 'txtCheckNumber', lstrxml[0].next_check)
      }
    }

    lstrxml = await AccountPayment.RetrieveOpenAccountControlDetails(buyingPt, payee_vendor)
    if(lstrxml.length > 0){
      if(lstrxml[0].open_acct_control.length > 0){
        setValue(thisObj, 'txtBlnc', Number(lstrxml[0].open_acct_control[0].sr_end_balance).toFixed(2))
        setValue(thisObj, 'txtBlnc2', Number(lstrxml[0].open_acct_control[0].ar_end_balance).toFixed(2))
        setValue(thisObj, 'txtBlnc3', Number(lstrxml[0].open_acct_control[0].cr_end_balance).toFixed(2))
      }
      else{
        setValue(thisObj, 'txtBlnc', "0.00")
        setValue(thisObj, 'txtBlnc2', "0.00")
        setValue(thisObj, 'txtBlnc3', "0.00")
      }
      
      setValue(thisObj, 'txtCllctSr', "0.00")
      setValue(thisObj, 'txtCllctAr', "0.00")
      setValue(thisObj, 'txtCllctCR', "0.00")
      setData(thisObj, 'txtCllctSr', "0.00")
      setData(thisObj, 'txtCllctAr', "0.00")
      setData(thisObj, 'txtCllctCR', "0.00")
    }

    setValue(thisObj, 'gridAvlbReady', availableRTPSArray)
    setValue(thisObj, 'txtAvlbRadyToPays', availableRTPSArray.length.toString())

    setValue(thisObj, 'gridAssocReady', associateRTPSArray)
    setValue(thisObj, 'txtAssociatedReadyToPay', associateRTPSArray.length.toString())
    
    await Calc();

    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }

  const ontxtCllctArChange = async() => {
    try{
      if(!isEnabled(thisObj, 'txtCllctAr')){
        return;
      }

      let txtCllAR = Number(([undefined, null].includes(getValue(thisObj, 'txtCllctAr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctAr')) ? '' : getData(thisObj, 'txtCllctAr') : getValue(thisObj, 'txtCllctAr')))
      let lblBalAR = Number(([undefined, null].includes(getValue(thisObj, 'txtBlnc2')) ? [undefined, null].includes(getData(thisObj, 'txtBlnc2')) ? '' : getData(thisObj, 'txtBlnc2') : getValue(thisObj, 'txtBlnc2')))
      if(txtCllAR > lblBalAR){
        setValue(thisObj, 'txtCllctAr', Number(lblBalAR) == NaN ? "" : Number(lblBalAR).toFixed(2))
      }
      checkAgainstValueOfCheck('AR', txtCllAR > lblBalAR ? lblBalAR : txtCllAR)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCllctArChange = ontxtCllctArChange;

  const ontxtCllctCRChange = async() => {
    try{
      if(!isEnabled(thisObj, 'txtCllctCR')){
        return;
      }

      let txtCllCR = Number(([undefined, null].includes(getValue(thisObj, 'txtCllctCR')) ? [undefined, null].includes(getData(thisObj, 'txtCllctCR')) ? '' : getData(thisObj, 'txtCllctCR') : getValue(thisObj, 'txtCllctCR')))
      let lblBalCR = Number(([undefined, null].includes(getValue(thisObj, 'txtBlnc3')) ? [undefined, null].includes(getData(thisObj, 'txtBlnc3')) ? '' : getData(thisObj, 'txtBlnc3') : getValue(thisObj, 'txtBlnc3')))
      if(txtCllCR > lblBalCR){
        setValue(thisObj, 'txtCllctCR', Number(lblBalCR) == NaN ? "" : Number(lblBalCR).toFixed(2)) 
      }
      checkAgainstValueOfCheck('CR', txtCllCR > lblBalCR ? lblBalCR : txtCllCR)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCllctCRChange = ontxtCllctCRChange;

  const ontxtCllctSrChange = async() => {
    try{
      if(!isEnabled(thisObj, 'txtCllctSr')){
        return;
      }

      let txtCllSR = Number(([undefined, null].includes(getValue(thisObj, 'txtCllctSr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctSr')) ? '' : getData(thisObj, 'txtCllctSr') : getValue(thisObj, 'txtCllctSr')))
      let lblBalSR = Number(([undefined, null].includes(getValue(thisObj, 'txtBlnc')) ? [undefined, null].includes(getData(thisObj, 'txtBlnc')) ? '' : getData(thisObj, 'txtBlnc') : getValue(thisObj, 'txtBlnc')))
      if(txtCllSR > lblBalSR){
        setValue(thisObj, 'txtCllctSr', Number(lblBalSR) == NaN ? "" : Number(lblBalSR).toFixed(2))
      }
      checkAgainstValueOfCheck('SR', txtCllSR > lblBalSR ? lblBalSR : txtCllSR)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCllctSrChange = ontxtCllctSrChange;

  const ontxtCllctArBlur = async() => {
    try{
      let txtCllAR = ([undefined, null].includes(getValue(thisObj, 'txtCllctAr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctAr')) ? '' : getData(thisObj, 'txtCllctAr') : getValue(thisObj, 'txtCllctAr'))
      if(txtCllAR.toString().includes('-')){
        alert("Negative collection amount not allowed.")
        document.getElementById('txtCllctAr').focus();
        setValue(thisObj, 'txtCllctAr', Number(txtCllAR) == NaN ? '' : Math.abs(txtCllAR))
        return;
      }
      await Calc();
      return;
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCllctArBlur = ontxtCllctArBlur;

  const ontxtCllctCRBlur = async() => {
    try{
      let txtCllCR = ([undefined, null].includes(getValue(thisObj, 'txtCllctCR')) ? [undefined, null].includes(getData(thisObj, 'txtCllctCR')) ? '' : getData(thisObj, 'txtCllctCR') : getValue(thisObj, 'txtCllctCR'))
      if(txtCllCR.toString().includes('-')){
        alert("Negative collection amount not allowed.")
        document.getElementById('txtCllctCR').focus();
        setValue(thisObj, 'txtCllctCR', Number(txtCllCR) == NaN ? '' : Math.abs(txtCllCR))
        return;
      }
      await Calc();
      return;
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCllctCRBlur = ontxtCllctCRBlur;

  const ontxtCllctSrBlur = async() => {
    try{
      let txtCllSR = ([undefined, null].includes(getValue(thisObj, 'txtCllctSr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctSr')) ? '' : getData(thisObj, 'txtCllctSr') : getValue(thisObj, 'txtCllctSr'))
      if(txtCllSR.toString().includes('-')){
        alert("Negative collection amount not allowed.")
        document.getElementById('txtCllctSr').focus();
        setValue(thisObj, 'txtCllctSr', Number(txtCllSR) == NaN ? '' : Math.abs(txtCllSR))
        return;
      }
      await Calc();
      return;
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCllctSrBlur = ontxtCllctSrBlur;

  const ondtIntrstBegnBlur = async() => {
    try{
      await Calc()
    }
    catch(err){
      errorHandler(err)
    }  
  }
  thisObj.ondtIntrstBegnBlur = ondtIntrstBegnBlur;

  const ontxtRateBlur = async() => {
    try{
      let rate = ""
      if(![undefined, null, NaN , "" ].includes(thisObj.values.txtRate)){
        rate =  Number(thisObj.values.txtRate)
      }
      
      thisObj.values.txtRate = rate
      setValue(thisObj, 'txtRate', rate)
      setData(thisObj, 'txtRate', rate)

      await Calc()
    }
    catch(err){
      errorHandler(err)
    }  
  }
  thisObj.ontxtRateBlur = ontxtRateBlur;

  const ontxtVendrBlur = async() => {
    try{
      if(document.activeElement){
        if(document.activeElement.id == "txtVendr"){
          await ontxtVendrClick()
        }
      }
  
      let refindVendor = getData(thisObj, 'MstrRefindVendor')
      if(refindVendor.toLocaleUpperCase().trim() !== thisObj.values.txtVendr){
        if(! await bFillAgreementRemitToList(thisObj.values.txtVendr)){
          if(getData(thisObj, 'txtVendorNumberTag'))
            thisObj.values.txtVendr = getData(thisObj, 'txtVendorNumberTag')
        }
        else{
          if(thisObj.values.txtVendr !== ""){
            await bFillRemitToList(thisObj.values.txtVendr)
            await DoSearch()
          }
        }
      }

      if([undefined, null, ""].includes(thisObj.values.txtVendr)){
        setValue(thisObj, 'gridAvlbReady', [])
        setValue(thisObj, 'gridAssocReady', [])
        setValue(thisObj, 'txtAvlbRadyToPays', "0")
        setValue(thisObj, 'txtAssociatedReadyToPay', "0")

        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: []
            }
          }
        })
        setValue(thisObj, 'ddRemitTo', '')

        thisObj.setState(current => {
          return {
            ...current,
            ddRemit: {
              ...state["ddRemit"],
              valueList: []
            }
          }
        })
        setValue(thisObj, 'ddRemit', '')
        setData(thisObj, 'lstAgreementRemitTo', [])
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtVendrBlur = ontxtVendrBlur;

  const ontxtValChckChange = async() => {
    try{
      let valOfCheck = getValue(thisObj, "txtValChck")
      if(Number(valOfCheck) > 0){
        setData(thisObj, "txtCheckNumber", FormatCheckNumber(getData(thisObj, "chknumber")))
      }
      else{
        let recNum = getData(thisObj, 'LstrReceivable_Num')
        setData(thisObj, 'txtCheckNumber', 'S' + '0'.repeat(5 - recNum.toString().length) + recNum.toString().trim())
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtValChckChange = ontxtValChckChange;

  const ontxtVendrChange = async() => {
    try{
      if(thisObj.values.txtVendr.length !== 6){
        setValue(thisObj, "txtLienhldr", "")
        setData(thisObj, "txtLienhldr", "")
      }
      if([undefined, null].includes(document.activeElement)){
        if(! await bFillAgreementRemitToList(thisObj.values.txtVendr)){
          if(getData(thisObj, 'txtVendorNumberTag'))          
            thisObj.values.txtVendr =  getData(thisObj, 'txtVendorNumberTag')
        }
      }
      else{
        if(document.activeElement.id !== "txtVendr"){
          if(! await bFillAgreementRemitToList()){
            if(getData(thisObj, 'txtVendorNumberTag'))
              thisObj.values.txtVendr =  getData(thisObj, 'txtVendorNumberTag')
          }
        }
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtVendrChange = ontxtVendrChange;

  const ontxtVendrNameChange = async() => {
    try{
      let vendorNum = [ undefined, null ].includes(getValue(thisObj, 'txtVendr')) ? [ undefined, null ].includes(getData(thisObj, 'txtVendr')) ? '' : getData(thisObj, 'txtVendr') : getValue(thisObj, 'txtVendr')
      let vendorName = [ undefined, null ].includes(getValue(thisObj, 'txtVendrName')) ? [ undefined, null ].includes(getData(thisObj, 'txtVendrName')) ? '' : getData(thisObj, 'txtVendrName') : getValue(thisObj, 'txtVendrName')
      if(vendorName == null || vendorName == undefined || vendorName == ''){
        return;
      }
      else{
        await bFillAgreementRemitToList(vendorNum)
        await bFillRemitToList(vendorNum)
        await DoSearch();
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtVendrNameChange = ontxtVendrNameChange;

  const onddRemitToChange = async() => {
    try{
      await DoSearch()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddRemitToChange = onddRemitToChange;

  const ontxtVendrClick = async() => {
    try{
      setData(thisObj, "MstrRefindVendor", thisObj.values.txtVendr)
      setData(thisObj, "txtVendorNumberTag", thisObj.values.txtVendr)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtVendrClick = ontxtVendrClick;

  const ongridAvlbReadyDblclick = async() => {
    try{
      await onbtnRightClick();
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ongridAvlbReadyDblclick = ongridAvlbReadyDblclick;

  const ongridAssocReadyDblclick = async() => {
    try{
      let assocList = getValue(thisObj, 'gridAssocReady')
      if(assocList.length < 5){
        await onbtnLeftClick();
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ongridAssocReadyDblclick = ongridAssocReadyDblclick;

  const onchkbxColChange = async() => {
    try{
      await Calc()
      await SetSelectStatus()
      await ontxtValChckChange()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onchkbxColChange = onchkbxColChange;

  const onddRemitToClick = async() => {
    try{
      await DoSearch()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddRemitToClick = onddRemitToClick;

  const onbtnRightClick = async(flag, availList, n) => {
    try{
      if(thisObj.state.gridAvlbReady.selected == null || thisObj.state.gridAvlbReady.selected == undefined){
        return;
      }

      let js = []
      let rowIdList = []
      let avalList = getValue(thisObj, 'gridAvlbReady')
      let assocList = getValue(thisObj, 'gridAssocReady')
      if(assocList.length > Mint_MAX_CHECK_ROWS){
        alert("You have reached the maximum limit of " + Mint_MAX_CHECK_ROWS + " transactions that can be released on a single check!")
      }
      
      for(var i=0;i<thisObj.state.gridAvlbReady.selected.length; i++){
        assocList.push({
          rowId                : thisObj.state.gridAvlbReady.selected[i].rowId,
          rowData1             : thisObj.state.gridAvlbReady.selected[i].rowData1,
          txtcolType1assoc     : thisObj.state.gridAvlbReady.selected[i].txtcolType1,
          txtcolNum1assoc      : thisObj.state.gridAvlbReady.selected[i].txtcolNum1,
          txtcolType2assoc     : thisObj.state.gridAvlbReady.selected[i].txtcolType2,
          txtcolNum2assoc      : thisObj.state.gridAvlbReady.selected[i].txtcolNum2,
          txtcolProceedassoc   : thisObj.state.gridAvlbReady.selected[i].txtcolProceed,
          txtcolOptassoc       : thisObj.state.gridAvlbReady.selected[i].txtcolOpt,
          txtcolDateassoc      : thisObj.state.gridAvlbReady.selected[i].txtcolDate,
          txtcolSetlDateassoc  : thisObj.state.gridAvlbReady.selected[i].txtcolSetlDate,
          txtcolSettleNumassoc : "",
          txtcolPaySortassoc   : thisObj.state.gridAvlbReady.selected[i].txtcolPaySort,
          txtcolRemitassoc     : thisObj.state.gridAvlbReady.selected[i].txtcolRemit,
          chkbxCol             : false
        })
        rowIdList.push(thisObj.state.gridAvlbReady.selected[i].rowId)

      }

      for(var i=0; i<avalList.length; i++){
        if(!rowIdList.includes(avalList[i].rowId)){
          js.push(avalList[i])
        }
      }
      
      setValue(thisObj, 'gridAvlbReady', js)
      setValue(thisObj, 'gridAssocReady', assocList)

      setValue(thisObj, 'txtAvlbRadyToPays', js.length.toString())
      setValue(thisObj, 'txtAssociatedReadyToPay', assocList.length.toString())

      await Calc()
      await SetRemitTo(assocList)
      await SetSelectStatus()

    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnRightClick = onbtnRightClick;

  const onbtnLeftClick = async() => {
    try{
      if(thisObj.state.gridAssocReady.selected == null || thisObj.state.gridAssocReady.selected == undefined){
        return;
      }

      let js = []
      let rowIdList = []
      let avalList = getValue(thisObj, 'gridAvlbReady')
      let assocList = getValue(thisObj, 'gridAssocReady')
      
      for(var i=0;i<thisObj.state.gridAssocReady.selected.length; i++){
        avalList.push({
          rowId           : thisObj.state.gridAssocReady.selected[i].rowId,
          rowData         : thisObj.state.gridAssocReady.selected[i].rowData1,
          txtcolType1     : thisObj.state.gridAssocReady.selected[i].txtcolType1assoc,
          txtcolNum1      : thisObj.state.gridAssocReady.selected[i].txtcolNum1assoc,
          txtcolType2     : thisObj.state.gridAssocReady.selected[i].txtcolType2assoc,
          txtcolNum2      : thisObj.state.gridAssocReady.selected[i].txtcolNum2assoc,
          txtcolProceed   : thisObj.state.gridAssocReady.selected[i].txtcolProceedassoc,
          txtcolOpt       : thisObj.state.gridAssocReady.selected[i].txtcolOptassoc,
          txtcolDate      : thisObj.state.gridAssocReady.selected[i].txtcolDateassoc,
          txtcolSetlDate  : thisObj.state.gridAssocReady.selected[i].txtcolSetlDateassoc,
          txtcolSettleNum : "",
          txtcolPaySort   : thisObj.state.gridAssocReady.selected[i].txtcolPaySortassoc,
          txtcolRemit     : thisObj.state.gridAssocReady.selected[i].txtcolRemitassoc,
        })
        rowIdList.push(thisObj.state.gridAssocReady.selected[i].rowData1)
      }

      for(var i=0; i<assocList.length; i++){
        if(!rowIdList.includes(assocList[i].rowData1)){
          js.push(assocList[i])
        }
      }
      
      setValue(thisObj, 'gridAssocReady', js)
      setValue(thisObj, 'gridAvlbReady', avalList)

      setValue(thisObj, 'txtAssociatedReadyToPay', js.length.toString())
      setValue(thisObj, 'txtAvlbRadyToPays', avalList.length.toString())

      await SetRemitTo(js)
      await SetSelectStatus()
      
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnLeftClick = onbtnLeftClick;

  const onbtnSaveClick = async() => {
    try{
      let lstrxml = [];
      let s = "";

      if(!await bFormValid()){
        return;
      }
      setLoading(true)
      let assocList = getValue(thisObj, 'gridAssocReady')
      for(var i=0; i<assocList.length; i++){
        s += assocList[i].rowData1
        if(s.slice(-1) !== ","){
          s += ","
        }
      }
      if(s.slice(-1) == ","){
        s = s.slice(0, -1)
      }
      
      let dataObj = {
        "buy_pt_id"    : thisObj.values.txtLoc.split('-')[0].trim(),
        "rtplist"      : s,
        "agree_remit"  : thisObj.values['ddRemit'],
        "agree_rate"   : thisObj.values.txtRate,
        "begin_date"   : [ undefined, null ].includes(thisObj.values.dtIntrstBegn) ? "" : thisObj.values.dtIntrstBegn
      }
      
      let js = {
        "vendorNum"     : thisObj.values.txtVendr,
        "agreementNum"  : thisObj.values.txtAgreement,
        "agreementName" : thisObj.values.txtAgreement + " - " + thisObj.values.txtVendr + " - " + thisObj.values.txtVendrName,
        "buyingPt"      : thisObj.values.txtLoc.split("-")[0].trim()
      }
      
      let buttonCaption = document.getElementById('btnSave').innerHTML
      if(buttonCaption.toLocaleUpperCase() == "ADD"){
        dataObj.agree_vendor = thisObj.values.txtVendr
        dataObj.agree_num  = thisObj.values.txtAgreement
        
        lstrxml = await SystemMaintenanceMasterManagementService.CreateIntrestAgreement(dataObj)
        if(lstrxml.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
        setData(thisObj, 'InterestAgreementSetupData', js)
        goTo(thisObj, "SystemMaintenanceMasterManagement#InterestAgreementSetup#DEFAULT#false#Click")
      }
      else if(buttonCaption.toLocaleUpperCase() == "UPDATE"){
        lstrxml = await SystemMaintenanceMasterManagementService.UpdateIntrestAgreement(thisObj.values.txtAgreement,thisObj.values.txtVendr,dataObj)
        if(lstrxml.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
        setData(thisObj, 'InterestAgreementSetupData', js)
        goTo(thisObj, "SystemMaintenanceMasterManagement#InterestAgreementSetup#DEFAULT#false#Click")
      }
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnSaveClick = onbtnSaveClick;

  const onbtnCnclClick = async() =>{
    try{
      goTo(thisObj, "SystemMaintenanceMasterManagement#InterestAgreementSetup#DEFAULT#false#Click")
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnCnclClick = onbtnCnclClick;

  const onbtnPrntChckClick = async() => {
    try{
      let lstrxml = []
      let n = 0
      let RCOUNT = 0;
      let s= "";
      let blnflagchecked = false;
      let astrTransNum = []
      let tempVal = ""

      let payee_vendor = thisObj.values.txtVendr
      lstrxml = await ContractManagementService.RetrieveVendorByNumber(payee_vendor)
      if(lstrxml.length <= 0){
        alert("Vendor not found!")
        return
      }

      if(lstrxml[0].number == ""){
        alert("Vendor not found!")
        return
      }

      if(lstrxml[0].pay_ind?.toLocaleUpperCase() !== "Y"){
        alert("The Vendor Master Pay indicator is not set to Y!")
        return
      }

      let valOfChck = getValue(thisObj, 'txtValChck')
      if(await bCheck_AR_CR_SR() && Number(valOfChck) > 0){
        let res = confirm("Do you wish to collect the AR, CR or SR that is due? ")
        if(res){
          if(isEnabled(thisObj, 'txtCllctSr')){
            document.getElementById('txtCllctSr').focus();
            return;
          }
          if(isEnabled(thisObj, 'txtCllctAr')){
            document.getElementById('txtCllctAr').focus();
            return;
          }
          if(isEnabled(thisObj, 'txtCllctCR')){
            document.getElementById('txtCllctCR').focus();
            return;
          }
        }
      } 
      
      if (thisObj.values['ddRemit'] == '' || thisObj.values['ddRemit'] == null || thisObj.values['ddRemit'] == undefined) {
        alert("A Remit To must be selected!!!")
        document.getElementById('ddRemit').focus();
        return;
      }

      let assocList = getValue(thisObj, 'gridAssocReady')
      for(var i=0; i<assocList.length; i++){
        if(assocList[i].chkbxCol){
          blnflagchecked = true;
        }
      }
      
      if(!blnflagchecked){
        return
      }

      if(!await bFormValid()){
        return
      }
      
      valOfChck = getValue(thisObj, 'txtValChck')
      if(Number(valOfChck) > 0){
        let tempVal = prompt("Enter check number")
        if(tempVal == null || tempVal == undefined || tempVal == ''){
          alert('Check number cannot be empty.')
          return;
        }

        if(isNaN(tempVal)){
          alert("Check Number must be numeric")
          return;
        }

        if(Number(tempVal) <= 0){
          alert("The Check Number cannot be Zero !")
          return;
        }

        if(Number(tempVal) > getData(thisObj, "txtEndCheckNumber")){
          alert("The Check Number exceeds number range!")
          return;
        }
        setData(thisObj, 'chknumber', Number(tempVal))
        setData(thisObj, 'txtCheckNumber', FormatCheckNumber(Number(tempVal)))
      }

      setLoading(true)
      if(parseFloat(valOfChck) > 0){
        lstrxml = await AccountPayment.RetrieveVerifyChecksUnusedDetails(getData(thisObj, "txtCheckNumber"))
        if(lstrxml.status == 500){
          if(lstrxml.message == "Error occurred during operation. Status Code : 500 ; Error Meesage : CHECK #" + Number(getData(thisObj, "txtCheckNumber")).toString() + " exceeds check range!!!"){
            alert("An error occured while communicating or updating with the database. Please contact your system administrator if the problem persists. \nError Meesage : CHECK #" + Number(getData(thisObj, "txtCheckNumber")).toString() + " exceeds check range!!!")
          }
          else{
            alert("An error occured while correcting selected check!!!")
          }          
          return;
        }
        else{
          if(lstrxml.length > 0){
            if(lstrxml[0].check_num !== ""){
              alert("Check number " + lstrxml[0].check_num + " with that number already exists!!!")
              return;
            }
          }
        }
      }

      for(var i=0; i<assocList.length; i++){
        s += assocList[i].rowData1
        if(s.slice(-1) !== ","){
          s += ","
        }
      }
      if(s.slice(-1) == ","){
        s = s.slice(0, -1)
      }
      
      let dataObj = {
        "buy_pt_id"    : thisObj.values.txtLoc.split('-')[0].trim(),
        "rtplist"      : s,
        "agree_num"    : thisObj.values.txtAgreement,
        "agree_rate"   : thisObj.values.txtRate,
        "begin_date"   : thisObj.values.dtIntrstBegn
      }
      
      let js = {
        "vendorNum" : thisObj.values.txtVendr,
        "agreementNum" : thisObj.values.txtAgreement,
        "agreementName" : thisObj.values.txtAgreement + " - " + thisObj.values.txtVendr + " - " + thisObj.values.txtVendrName
      }
      
      let buttonCaption = document.getElementById('btnSave').innerHTML
      if(buttonCaption.toLocaleUpperCase() == "ADD"){
        dataObj.agree_vendor = thisObj.values.txtVendr
        dataObj.agree_remit  = thisObj.values['ddRemit']
        
        lstrxml = await SystemMaintenanceMasterManagementService.CreateIntrestAgreement(dataObj)
        if(lstrxml.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
        setData(thisObj, 'InterestAgreementSetupData', js)
      }
      else if(buttonCaption.toLocaleUpperCase() == "UPDATE"){
        lstrxml = await SystemMaintenanceMasterManagementService.UpdateIntrestAgreement(thisObj.values.txtVendr,thisObj.values['ddRemit'],dataObj)
        if(lstrxml.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
        setData(thisObj, 'InterestAgreementSetupData', js)
      }

      js = []
      RCOUNT = 0
      s = ""

      valOfChck = getValue(thisObj, 'txtValChck')
      if(Number(valOfChck) <= 0){
      }
      else{
        let val = FormatCheckNumber(getData(thisObj, 'txtCheckNumber'))
        setData(thisObj, 'txtCheckNumber', val)
      }
      
      for(var i=0; i<assocList.length; i++){
        if(assocList[i].chkbxCol){
          if(assocList[i].rowData1 !== ""){
            astrTransNum = assocList[i].rowData1.split(',')
            for(var j=0; j<astrTransNum.length; j++){
              RCOUNT++;
              js.push({
                "tn" : astrTransNum[j],
                "ty" : 'R'
              })
            }
          }
          else{
            RCOUNT++;
            js.push({
              "tn" : assocList[i].rowData1,
              "ty" : 'R'
            })
          }          
        }
      }
      
      let txtCllCR = ([undefined, null].includes(getValue(thisObj, 'txtCllctCR')) ? [undefined, null].includes(getData(thisObj, 'txtCllctCR')) ? '' : getData(thisObj, 'txtCllctCR') : getValue(thisObj, 'txtCllctCR'))
      let txtCllAR = ([undefined, null].includes(getValue(thisObj, 'txtCllctAr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctAr')) ? '' : getData(thisObj, 'txtCllctAr') : getValue(thisObj, 'txtCllctAr'))
      let txtCllSR = ([undefined, null].includes(getValue(thisObj, 'txtCllctSr')) ? [undefined, null].includes(getData(thisObj, 'txtCllctSr')) ? '' : getData(thisObj, 'txtCllctSr') : getValue(thisObj, 'txtCllctSr'))
      
      dataObj = {
        "user_id": useridFromLS(),
        "interest_amt": thisObj.values.txtInterest,
        "remit_to": thisObj.values['ddRemit'],
        "cr_amount": Number(txtCllCR),
        "ar_amount": Number(txtCllAR),
        "sr_amount": Number(txtCllSR),
        "intr_agree_num": thisObj.values.txtAgreement,
        "remarks": [undefined, null].includes(thisObj.values.txtarRemarks) ? "" : thisObj.values.txtarRemarks,
        "rcount": RCOUNT,
        "transactions": js
      }
      
      let buyingPt = thisObj.values.txtLoc.split('-')[0].trim()
      let cbuyPtID = getData(thisObj, 'lblCheckNumber')
      let number = getData(thisObj, "txtCheckNumber")
      valOfChck = getValue(thisObj, 'txtValChck')
      
      lstrxml = await AccountPayment.UpdateReleaseReadyToPayDetails(buyingPt, cbuyPtID, number, payee_vendor, dataObj)
      // if(lstrxml.status == 200){
      //   goTo(thisObj, "SystemMaintenanceMasterManagement#InterestAgreementSetup#DEFAULT#false#Click")
      //   return
      // }
      if(lstrxml.status == 500){
        if(lstrxml.message == "Error occurred during operation. Status Code : 500 ; Error Meesage : CHECK #" + Number(getData(thisObj, "txtCheckNumber")).toString() + " already exists!!!"){
          alert("An error occured while communicating or updating with the database. Please contact your system administrator if the problem persists. \nError Meesage : CHECK #" + Number(getData(thisObj, "txtCheckNumber")).toString() + " already exists!!!")
        }
        else{
          alert("An error occured while correcting selected check!!!")
        }
        return;
      }
      else if(lstrxml?.status == 200){
        if(Number(valOfChck) > 0){
          let navigateObj = [{
            vendor       : thisObj.values.txtVendr,
            VendorName   : thisObj.values.txtVendrName,
            buying_Point : thisObj.values.txtLoc.split('-')[0].trim(),
            check_num    :  getData(thisObj, 'chknumber'),
            Title        : 'ORIGIN BUYING POINT',
            ScreenName   : 'INTRESTAGREEMENTPROFILE',
            Proceeds     : thisObj.values.txtValChck
          }]

          let checkNumber = getData(thisObj, "txtCheckNumber")
          if(!isNaN(checkNumber)){
            let LstrXMLCopy = await AccountPayment.RetrieveBatchCheckPrintDetails(navigateObj.buying_Point, checkNumber, navigateObj.vendor)
            if(LstrXMLCopy.length > 0){
              setData(thisObj, 'closeIAPscreen', true)
              setData(thisObj, 'vendorDetails', navigateObj);
              setData(thisObj, "ReportPreviewCheck", navigateObj)
              goTo(thisObj, "AccountPayment#ReportPreviewCheck#DEFAULT#true#Click");  
              return
            }
          }
        }
      }
      else{
        await GetNextCheckNum()
        return;
      }
      await onbtnCnclClick()
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnPrntChckClick = onbtnPrntChckClick;

  const onbtnSaveBatchClick = async() => {
    try{
      let LlngRCount = 0;
      let lstrxml = "";
      let astrTransNum = "";
      let blnflagchecked = false;
      let s = "";
      let payee_vendor = thisObj.values.txtVendr
      lstrxml = await ContractManagementService.RetrieveVendorByNumber(payee_vendor)
      if(lstrxml.length <= 0){
        alert("Vendor not found!")
        return
      }

      if(lstrxml[0].number == ""){
        alert("Vendor not found!")
        return
      }

      if(lstrxml[0].pay_ind !== "Y"){
        alert("The Vendor Master Pay indicator is not set to Y!")
        return
      }

      let valOfChck = getValue(thisObj, 'txtValChck')
      if(await bCheck_AR_CR_SR() && Number(valOfChck) > 0){
        let res = confirm("Do you wish to collect the AR, CR or SR that is due? ")
        if(res){
          if(isEnabled(thisObj, 'txtCllctSr')){
            document.getElementById('txtCllctSr').focus();
            return;
          }
          if(isEnabled(thisObj, 'txtCllctAr')){
            document.getElementById('txtCllctAr').focus();
            return;
          }
          if(isEnabled(thisObj, 'txtCllctCR')){
            document.getElementById('txtCllctCR').focus();
            return;
          }
        }
      } 
      
      if (thisObj.values['ddRemit'] == '' || thisObj.values['ddRemit'] == null || thisObj.values['ddRemit'] == undefined) {
        alert("A Remit To must be selected!!!")
        document.getElementById('ddRemit').focus();
        return;
      }

      let assocList = getValue(thisObj, 'gridAssocReady')
      for(var i=0; i<assocList.length; i++){
        if(assocList[i].chkbxCol){
          blnflagchecked = true;
        }
      }
      
      if(!blnflagchecked){
        alert("Atleast one Associated Ready To Pay must be checked to generate a Batched Check!")
        return
      }

      if(!await bFormValid()){
        return
      }

      setLoading(true)
      assocList  = getValue(thisObj, 'gridAssocReady')
      for(var i=0; i<assocList.length; i++){
        s += assocList[i].rowData1
        if(s.slice(-1) !== ","){
          s += ","
        }
      }
      if(s.slice(-1) == ","){
        s = s.slice(0, -1)
      }
      
      let dataObj = {
        "buy_pt_id"    : thisObj.values.txtLoc.split('-')[0].trim(),
        "rtplist"      : s,
        "agree_num"    : thisObj.values.txtAgreement, 
        "agree_rate"   : thisObj.values.txtRate,
        "begin_date"   : thisObj.values.dtIntrstBegn
      }
      
      let js = {
        "vendorNum" : thisObj.values.txtVendr,
        "agreementNum" : thisObj.values.txtAgreement,
        "agreementName" : thisObj.values.txtAgreement + " - " + thisObj.values.txtVendr + " - " + thisObj.values.txtVendrName
      }
      
      let buttonCaption = document.getElementById('btnSave').innerHTML
      
      if(buttonCaption.toLocaleUpperCase() == "ADD"){
        dataObj.agree_vendor = thisObj.values.txtVendr
        dataObj.agree_remit  = thisObj.values['ddRemit']
        
        lstrxml = await SystemMaintenanceMasterManagementService.CreateIntrestAgreement(dataObj)
        if(lstrxml.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
        setData(thisObj, 'InterestAgreementSetupData', js)
      }
      else if(buttonCaption.toLocaleUpperCase() == "UPDATE"){
        lstrxml = await SystemMaintenanceMasterManagementService.UpdateIntrestAgreement(thisObj.values.txtVendr,thisObj.values['ddRemit'],dataObj)
        if(lstrxml.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return;
        }
        setData(thisObj, 'InterestAgreementSetupData', js)
      }

      LlngRCount = 0
      js = []
      for(var i=0; i<assocList.length; i++){
        if(assocList[i].chkbxCol){
          if(assocList[i].rowData1 !== ""){
            astrTransNum = assocList[i].rowData1.split(',')
            for(var j=0; j<astrTransNum.length; j++){
              LlngRCount++;
              js.push({
                "tn" : astrTransNum[j],
                "ty" : 'B'
              })
            }
          }
          else{
            LlngRCount++;
            js.push({
              "tn" : assocList[i].rowData1,
              "ty" : 'B'
            })
          }          
        }
      }
      
      dataObj = {
        "user_id": useridFromLS(),
        "interest_amt": thisObj.values.txtInterest,
        "remit_to": thisObj.values['ddRemit'],
        "cr_amount": Number(thisObj.values.txtCllctCR),
        "ar_amount": Number(thisObj.values.txtCllctAr),
        "sr_amount": Number(thisObj.values.txtCllctSr),
        "intr_agree_num": thisObj.values.txtAgreement,
        "remarks": [undefined, null].includes(thisObj.values.txtarRemarks) ? "" : thisObj.values.txtarRemarks,
        "rcount": LlngRCount,
        "transactions" : js
      }
      
      lstrxml = await AccountPayment.UpdateBatchReadyToPayDetails(thisObj.values.txtLoc.split('-')[0].trim(), thisObj.values.txtVendr, dataObj)
      if(lstrxml.status == 500){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
        return;
      }
      else if(lstrxml.status == 200){
        alert("Interest Agreement Check was saved to Batch successfully!")
        goTo(thisObj, "SystemMaintenanceMasterManagement#InterestAgreementSetup#DEFAULT#false#Click")
      }
      else{
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
        return;
      }
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnSaveBatchClick = onbtnSaveBatchClick;

  const onbtnSelectAllClick = async() => {
    try{
      setLoading(true)
      let caption = document.getElementById('btnSelectAll').innerHTML
      let assocList = getValue(thisObj, 'gridAssocReady')
      if(caption.toLocaleUpperCase() == "SELECT_ALL"){
        for(var i=0; i<assocList.length; i++){
          assocList[i].chkbxCol = true
        }
        document.getElementById('btnSelectAll').innerHTML = "UNSELECT_ALL"
      }
      else{
        for(var i=0; i<assocList.length; i++){
          assocList[i].chkbxCol = false
        }
        document.getElementById('btnSelectAll').innerHTML = "SELECT_ALL"
      }
      
      setValue(thisObj, 'gridAssocReady', assocList)
      await Calc();
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnSelectAllClick = onbtnSelectAllClick;

  const onbtnVndrClick = async() => {
    try{
      if([ undefined, null, "" ].includes(thisObj.values.txtVendr)){
        return;
      }
      else{
        setData(thisObj, "txtVendorNumberTag", thisObj.values.txtVendr)
        await bFillAgreementRemitToList(thisObj.values.txtVendr)
        await bFillRemitToList(thisObj.values.txtVendr)
        await DoSearch()
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnVndrClick = onbtnVndrClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_InterestAgreementProfile*/}

              {/* END_USER_CODE-USER_BEFORE_InterestAgreementProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxAgreeProf*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAgreeProf*/}

              <GroupBoxWidget conf={state.grpbxAgreeProf} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtLoc*/}

                {/* END_USER_CODE-USER_BEFORE_txtLoc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoc*/}

                {/* END_USER_CODE-USER_AFTER_txtLoc*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendr*/}

                {/* END_USER_CODE-USER_AFTER_txtVendr*/}
                {/* START_USER_CODE-USER_BEFORE_btnVndr*/}

                {/* END_USER_CODE-USER_BEFORE_btnVndr*/}

                <ButtonWidget
                  conf={state.btnVndr}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                  <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendrName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_btnVndr*/}

                {/* END_USER_CODE-USER_AFTER_btnVndr*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRate*/}
                {/* START_USER_CODE-USER_BEFORE_dtIntrstBegn*/}

                {/* END_USER_CODE-USER_BEFORE_dtIntrstBegn*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtIntrstBegn}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtIntrstBegn*/}

                {/* END_USER_CODE-USER_AFTER_dtIntrstBegn*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAgreeProf*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAgreeProf*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxGrids*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxGrids*/}

              <GroupBoxWidget conf={state.grpbxGrids} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAvlbRadyToPays*/}

                {/* END_USER_CODE-USER_BEFORE_txtAvlbRadyToPays*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAvlbRadyToPays}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAvlbRadyToPays*/}

                {/* END_USER_CODE-USER_AFTER_txtAvlbRadyToPays*/}
                {/* START_USER_CODE-USER_BEFORE_txtAssociatedReadyToPay*/}

                {/* END_USER_CODE-USER_BEFORE_txtAssociatedReadyToPay*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAssociatedReadyToPay}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAssociatedReadyToPay*/}

                {/* END_USER_CODE-USER_AFTER_txtAssociatedReadyToPay*/}
                {/* START_USER_CODE-USER_BEFORE_btnSelectAll*/}

                {/* END_USER_CODE-USER_BEFORE_btnSelectAll*/}

                <ButtonWidget
                  conf={state.btnSelectAll}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSelectAll*/}

                {/* END_USER_CODE-USER_AFTER_btnSelectAll*/}
                {/* START_USER_CODE-USER_BEFORE_gridAvlbReady*/}

                {/* END_USER_CODE-USER_BEFORE_gridAvlbReady*/}

                <GridWidget
                  conf={state.gridAvlbReady}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAvlbReady}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject = {thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAvlbReady*/}

                {/* END_USER_CODE-USER_AFTER_gridAvlbReady*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxButtons*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxButtons*/}

                <GroupBoxWidget conf={state.grpbxButtons} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnRight*/}

                  {/* END_USER_CODE-USER_BEFORE_btnRight*/}

                  <ButtonWidget
                    conf={state.btnRight}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnRight*/}

                  {/* END_USER_CODE-USER_AFTER_btnRight*/}
                  {/* START_USER_CODE-USER_BEFORE_btnLeft*/}

                  {/* END_USER_CODE-USER_BEFORE_btnLeft*/}

                  <ButtonWidget
                    conf={state.btnLeft}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnLeft*/}

                  {/* END_USER_CODE-USER_AFTER_btnLeft*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxButtons*/}

                {/* END_USER_CODE-USER_AFTER_grpbxButtons*/}
                {/* START_USER_CODE-USER_BEFORE_gridAssocReady*/}

                {/* END_USER_CODE-USER_BEFORE_gridAssocReady*/}

                <GridWidget
                  conf={state.gridAssocReady}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAssocReady}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject = {thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAssocReady*/}

                {/* END_USER_CODE-USER_AFTER_gridAssocReady*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxGrids*/}

              {/* END_USER_CODE-USER_AFTER_grpbxGrids*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxBottom*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBottom*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxBottom} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRemarks*/}

                <GroupBoxWidget conf={state.grpbxRemarks} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtLienhldr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLienhldr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLienhldr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLienhldr*/}

                  {/* END_USER_CODE-USER_AFTER_txtLienhldr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRemit*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRemit*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRemit}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRemit*/}

                  {/* END_USER_CODE-USER_AFTER_ddRemit*/}
                  {/* START_USER_CODE-USER_BEFORE_txtarRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_txtarRemarks*/}

                  <TextAreaWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtarRemarks}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_AFTER_txtarRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_txtarRemarks*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemarks*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemarks}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemarks*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRemarks*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxArCr*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxArCr*/}

                <GroupBoxWidget conf={state.grpbxArCr} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtValRtp*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValRtp*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValRtp}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValRtp*/}

                  {/* END_USER_CODE-USER_AFTER_txtValRtp*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInterest*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInterest*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInterest}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                    <LabelWidget
                  values={values}
                  conf={state.lblBalance}
                  screenConf={state}
                ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInterest*/}

                  {/* END_USER_CODE-USER_AFTER_txtInterest*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtCllctSr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCllctSr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCllctSr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCllctSr*/}

                  {/* END_USER_CODE-USER_AFTER_txtCllctSr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBlnc*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBlnc*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBlnc}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBlnc*/}

                  {/* END_USER_CODE-USER_AFTER_txtBlnc*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCllctAr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCllctAr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCllctAr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCllctAr*/}

                  {/* END_USER_CODE-USER_AFTER_txtCllctAr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBlnc2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBlnc2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBlnc2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBlnc2*/}

                  {/* END_USER_CODE-USER_AFTER_txtBlnc2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCllctCR*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCllctCR*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCllctCR}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCllctCR*/}

                  {/* END_USER_CODE-USER_AFTER_txtCllctCR*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBlnc3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBlnc3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBlnc3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBlnc3*/}

                  {/* END_USER_CODE-USER_AFTER_txtBlnc3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValChck*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValChck*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValChck}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValChck*/}

                  {/* END_USER_CODE-USER_AFTER_txtValChck*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxArCr*/}

                {/* END_USER_CODE-USER_AFTER_grpbxArCr*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

                <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddedBy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                  <ButtonWidget
                    conf={state.btnSave}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSave*/}

                  {/* END_USER_CODE-USER_AFTER_btnSave*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveBatch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveBatch*/}

                  <ButtonWidget
                    conf={state.btnSaveBatch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveBatch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSaveBatch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrntChck*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrntChck*/}

                  <ButtonWidget
                    conf={state.btnPrntChck}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrntChck*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrntChck*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCncl*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCncl*/}

                  <ButtonWidget
                    conf={state.btnCncl}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCncl*/}

                  {/* END_USER_CODE-USER_AFTER_btnCncl*/}
                  {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtChangedBy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                  {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBottom*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBottom*/}

              {/* START_USER_CODE-USER_AFTER_InterestAgreementProfile*/}

              {/* END_USER_CODE-USER_AFTER_InterestAgreementProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_InterestAgreementProfile
);
