/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
    Form,
    object,
    Formik,
    withRouter,
    invokeEventHandler,
    callOnOpenPreSubmit,
    callOnOpenService,
    handleModalHide,
    parentWindow,
    dataCopyObj,
    showMessage,
    useAppContext,
    Modal,
    _kaledo,
    initForm,
    GroupBoxWidget,
    ButtonWidget,
    disable,
    enable,
    useLocation,
    getData,
    setData,
    hide
} from "../../shared/WindowImports";
import "./ReportPreviewContract.scss";
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import CommonContext from '../../Store/CommonContext';
import { GetReportLogoImage, GetReportLogoImageDimension } from "./../../Common/Constants";

function ContractManagement_ReportPreviewContract(props) {
    const contextType = useAppContext();
    const screenRef = useRef();
    const commonContext = useContext(CommonContext);
    const location = useLocation();
    const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

    const imgUrl = GetReportLogoImage(window.location.origin)
    const imgDimension = GetReportLogoImageDimension()

    let cropYear = cropYearFromLS()
    let contract_num = commonContext.getContractProfile_ContractNumber;
    let price_suffix = "";
    let lstContract = [];
    let lstContractLienHolders = [];
    let contractAddress = [];
    let signDetails = [];
    let contractVendorSplit = [];
    let addendumSeedGrowers = [];

    let states = {
        Label: "Report Preview Contract",
        windowName: "ReportPreviewContract",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        is12Column: false,
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "ContractManagement.ReportPreviewContract",

        headerData: {
            scrName: "Print Preview",
            scrCode: "PN000A"
        },
        renderUrl: "",
        renderCode: "",
        lstReport: [],
        address: {},
        contract_num: {},
        lstReportDetail: [],

        groupboxwidget0: {
            name: "groupboxwidget0",
            type: "GroupBoxWidget",
            parent: "ReportPreviewContract",
            Label: "groupboxwidget0",
            Height: "",
            Width: "",
        },
        btnPrint: {
            name: "btnPrint",
            type: "ButtonWidget",
            parent: "groupboxwidget0",
            Label: "Print",
            CharWidth: "10",
            ofTypeDomain: "d_boolean",
        },
        btnSaveAsPdf: {
            name: "btnSaveAsPdf",
            type: "ButtonWidget",
            parent: "groupboxwidget0",
            Label: "Save As PDF",
            CharWidth: "10",
            ofTypeDomain: "d_boolean",
        },
        btnClose: {
            name: "btnClose",
            type: "ButtonWidget",
            parent: "groupboxwidget0",
            Label: "Close",
            CharWidth: "10",
            ofTypeDomain: "d_boolean",
        }
    };
    let _buttonServices = {};
    let _buttonNavigation = {};
    let _winServices = {};
    const [state, setState] = useState(states);
    const thisObj = {
        context: contextType,
        state: state,
        props: props,
        _buttonServices: _buttonServices,
        _buttonNavigation: _buttonNavigation,
        _winServices: _winServices,
        setState: setState,
    };
    useEffect(() => {
        parentWindow(thisObj);
    });
    useEffect(() => {
        callOnOpenPreSubmit(thisObj, state.windowName);
        callOnOpenService(thisObj, screenRef);
        disable(thisObj, "btnPrint")
        disable(thisObj, "btnSaveAsPdf")
        disable(thisObj, "btnClose")
        hide(thisObj, "btnSaveAsPdf")
        GetApiData()
        return () => {
            let parentName = getData(thisObj, "parentName")
            let promptMsg = getData(thisObj, "chkSeedGrower2010");
            if (parentName === "ContractProfile") {
                if (promptMsg == true) {
                    let firstResp = window.confirm("Do you want to enter a Seed Agreement for this contract? Click on OK to proceed else click on Cancel.")
                    if (firstResp == true) {
                        document.title = parentName;
                        setData(thisObj, "parentName", "ContractProfile");
                        window.location.reload();
                        return null;
                    } else {
                        let rsp = window.confirm("Do you want to create another Contract? Click OK to proceed else click on Cancel to go to Main Menu screen.")
                        if (rsp == true) {
                            document.title = parentName;
                            setData(thisObj, "parentName", "ContractProfile");
                            window.location.reload();
                            return null;
                        } else {
                            document.title = "Main Menu";
                            setData(thisObj, "parentName", "MainMenu");
                            window.location.replace(window.location.origin + "/#/")
                        }
                    }
                } else {
                    let rsp = window.confirm("Do you want to create another Contract? Click OK to proceed else click on Cancel to go to Main Menu screen.")
                    if (rsp == true) {
                        document.title = parentName;
                        setData(thisObj, "parentName", "ContractProfile");
                        window.location.reload();
                        return null;
                    } else {
                        document.title = "Main Menu";
                        setData(thisObj, "parentName", "MainMenu");
                        window.location.replace(window.location.origin + "/#/")
                    }
                }
            }
        }
    }, []);

    async function GetApiData() {
        let cont_num = commonContext.getContractProfile_ContractNumber
        let response = await ContractManagementService.RetrieveContractDetails(cont_num, 'PN1010')
        if (response != null && response != undefined) {
            if (response.length != 0) {
                lstContract = response[0]
                lstContractLienHolders = response[0].contract_lienholders
                if(response[0].contract_pricings.length != 0)
                    price_suffix = response[0].contract_pricings[0].firm_basis
                else
                    price_suffix = state.headerData.scrCode[5];
                
                if(response[0].contract_vendor_splits.length != 0){
                    contractVendorSplit = response[0].contract_vendor_splits
                    setData(thisObj, 'cmdForeignMatarial' , response[0].contract_vendor_splits[0].fm_addendum_ind) 
                    setData(thisObj, 'cmdChemicalAddendum', response[0].contract_vendor_splits[0].chemical_addendum_ind) 
                    if(response[0].contract_vendor_splits[0].addendum_seed_growers != 0)
                        addendumSeedGrowers  = response[0].contract_vendor_splits[0].addendum_seed_growers;
                    else
                        addendumSeedGrowers  = []
                }
                else
                    contractVendorSplit = []
            }
            else {
                lstContract = {
                    "center_pivot_acres": '',
                    "dry_land_acres": '',
                    "yield_per_acre": '',
                    "pnut_variety_name": '',
                    "seed_grower": '',
                    "oleic_ind": '',
                    "organic": '',
                    "cont_lbs" : '0',
                    "contractRemarks" : ''
                }
                lstContractLienHolders = [
                    { "lienholder_name": '', "lienholder_address": '', "nature_of_lien": '' },
                    { "lienholder_name": '', "lienholder_address": '', "nature_of_lien": '' }
                ]            
                price_suffix = state.headerData.scrCode[5];
                contractVendorSplit = []
                addendumSeedGrowers  = []
            }
        }
        else {
            showMessage(thisObj, "Some technical issue has happened. Please try again.")
            return;
        }
        setData(thisObj, 'priceSuffix', price_suffix)
        let response1 = await ContractManagementService.RetieveRemitDetails(lstContract.contract_vendor_splits[0].split_vendor)
        if (response1 != null && response1 != undefined) {
            if (response1.length != 0)
                contractAddress = response1
            else
                showMessage(thisObj, "No contract address details found.")
        }
        else {
            showMessage(thisObj, "Some technical issue has happened. Please try again.")
            return;
        }

        let response2 = await ContractManagementService.RetrieveContractSign(cont_num)        
        if (response2 != null && response2 != undefined) {
            if (response2.length != 0)
                signDetails = response2[0]            
            else
                signDetails = ''
        }
        else {
            showMessage(thisObj, "Some technical issue has happened. Please try again.")
            return;
        }
        enable(thisObj, "btnPrint")
        enable(thisObj, "btnClose")
        await ReadFile();
    }

    function GetTodayDate(date1) {
        var date = new Date();   
        if (date1 != null)
            date = new Date(date1)         
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        var todayDate = dayNames[date.getDay()] + ", " + monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
        return todayDate;
    }

    async function ReadFile() {
        var renderCode = await GetHtmlCode(78, 100, '')
        state.renderCode = renderCode
        state.renderUrl = getBlobURL(renderCode, 'text/html')
        document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    }

    function ConvertToPascalCase(data){
        return data[0].toLocaleUpperCase() + data.slice(1).toLocaleLowerCase();
    }
    
    async function PadValues(data, padLength, type){
        try{
            switch(type){
                case 'name':
                    if(data == '')
                        break;
                    var arr = data.split(' ')
                    data = ''
                    for(var i=0; i<arr.length; i++)
                        data += (ConvertToPascalCase(arr[i]) + ' ')
                    break;
                case 'address':
                    data = data == ', ,  ' ? '' : data.toLocaleUpperCase()
                    break;
                default:
                    break;
            }
            let returnValue = data == '' ? '' : data.trim();
            for(var i=data.length; i< padLength; i++)
                returnValue += '_'             
            return returnValue;
        }
        catch(err){
            showMessage(thisObj, err.message)
        }
    }

    async function GetHTMLData() {
        if (contractAddress != null && contractAddress != undefined) {
            if (contractAddress.length == 0) {
                contractAddress.push({
                    name: '',
                    address1: '',
                    address2: '',
                    name: '',
                    state: '',
                    zip: '',
                    sharePct : 0
                })                
            }
        }
        else {
            contractAddress.push({
                name: '',
                address1: '',
                address2: '',
                name: '',
                state: '',
                zip: '',
                sharePct : 0
            })
        }
        var data1 = contractAddress
        
        var parentData = {
            collectionPoint     : lstContract.coll_pt_id,
            contLbs             : lstContract.cont_lbs == 0 ? 0 : lstContract.cont_lbs,
            ratePerTon          : lstContract.prem_per_ton == 0 ? '0.00' : lstContract.prem_per_ton,
            deliveryPoint       : lstContract.buy_pt_id,
            buyingPoint         : lstContract.buy_pt_name,
            handlerNo           : lstContract.handler_loc_num,
            licenseNo           : lstContract.fed_whouse_license,
            centerPivotIrr      : lstContract.center_pivot_acres,
            cableTowIrrigated   : lstContract.other_acres,
            dryLand             : lstContract.dry_land_acres,
            total               : Number(lstContract.center_pivot_acres) + Number(lstContract.dry_land_acres) + Number(lstContract.other_acres),
            avgYield            : lstContract.yield_per_acre,
            variety             : lstContract.pnut_variety_name,
            seedGrower          : lstContract.seed_grower == 'N' ? " YES ___ NO <u> X </u>" : " YES <u> &nbsp; X &nbsp; NO </u> ___",
            seedCredit          : lstContract.seed_tbd    == 'N' ? " YES ___ NO <u> X </u>" : " YES <u> &nbsp; X &nbsp; NO </u> ___",
            highOleic           : lstContract.oleic_ind    == ''  ? " YES ___ NO <u> X </u>" : " YES <u> &nbsp; X &nbsp; NO </u> ___",
            organic             : lstContract.organic        == 'N' ? " YES ___ NO <u> X </u>" : " YES <u> &nbsp; X &nbsp; NO </u> ___",
            price               : lstContract.contract_pricings.length == 0 ? '0' :  lstContract.contract_pricings[0].price_per_ton,
            contractPounds      : lstContract.contract_pricings.length == 0 ? '0' :  (Number(lstContract.contract_pricings[0].cont_lbs)).toLocaleString("en-US"),
            rebateRate          : lstContract.contract_pricings.length == 0 ? '' :  lstContract.contract_pricings[0].rebate_rate,
            payPrice            : lstContract.contract_pricings.length == 0 ? '0' :  lstContract.contract_pricings[0].pay_rebate_at,
            mfloor              : lstContract.contract_pricings.length == 0 ? '0.0' :  lstContract.contract_pricings[0].market_floor,
            mCeiling            : lstContract.contract_pricings.length == 0 ? '0.0' :  lstContract.contract_pricings[0].market_ceiling,
            pricePound          : lstContract.contract_pricings.length == 0 ? '0' :  lstContract.contract_pricings[0].inc_price_per_pound,
            lineHolderName1     : lstContractLienHolders[0].lienholder_name,
            lineHolderAddr1     : lstContractLienHolders[0].lienholder_address + ", " + lstContractLienHolders[0].lienholder_city + ", " + lstContractLienHolders[0].lienholder_state + " " + lstContractLienHolders[0].lienholder_zip,
            lineHolderNature1   : lstContractLienHolders[0].nature_of_lien,
            lineHolderName2     : lstContractLienHolders[1].lienholder_name,
            lineHolderAddr2     : lstContractLienHolders[1].lienholder_address + ", " + lstContractLienHolders[1].lienholder_city + ", " + lstContractLienHolders[1].lienholder_state + " " + lstContractLienHolders[1].lienholder_zip,
            lineHolderNature2   : lstContractLienHolders[1].nature_of_lien,
            mOptionPrice        : lstContract.contract_pricings.length == 0 ? '0' : lstContract.contract_pricings[0].max_option_price,
            floorPrice          : lstContract.contract_pricings.length == 0 ? '0' : lstContract.contract_pricings[0].price_per_ton,
            floorDate           : lstContract.contract_pricings.length == 0 ? new Date() : lstContract.contract_pricings[0].price_match_end_date
        }

        var contractPricing = {
            rebate_rate : '',
            multi_pricing_ind : '',
            mkt_gain_share : '',
            market_ceiling : '',
            mkt_gain_share : '',
            loan_repay_ind : '',
            pay_rebate_at : ''
        } 

        return {
            addDateTime         : lstContract.add_date_time,
            contractDate        : GetTodayDate(lstContract.cont_date),
            contLbs             : parentData.contLbs,
            ratePerTon          : parentData.ratePerTon,
            cropYear            : lstContract.crop_year,
            contractNumber      : lstContract.contract_num,
            buyingPoint         : parentData.buyingPoint,
            splitPct            : lstContract.contract_vendor_splits[0].split_share_pct.split('.')[0] == '100' ? "<u> (" + lstContract.contract_vendor_splits[0].split_share_pct.split('.')[0] + "%) </u>"  : '',
            vendorNumber        : lstContract.contract_vendor_splits[0].split_vendor,
            vendorName          : data1[0].name,
            vendorAddress1      : data1[0].address1,
            vendorAddress2      : data1[0].address2,
            vendorAddress3      : data1[0].city + "&nbsp;," + data1[0].state + "&nbsp;" + data1[0].zip.slice(0,5),
            deliveryPoint       : "<u> " + parentData.deliveryPoint + "  _________________________ </u>",
            collectionPoint     : "<u> " + parentData.collectionPoint + " _______________________ </u>",
            handlerNo           : "<u> " + parentData.handlerNo + " ________________________ </u>",
            licenseNo           : "<u> " + parentData.licenseNo + " __________________ </u>",
            contractType        : lstContract.cont_type,
            centerPivotIrr      : parentData.centerPivotIrr == '' ? 0 : parentData.centerPivotIrr,
            cableTowIrrigated   : parentData.cableTowIrrigated == '' ? 0 : parentData.cableTowIrrigated,
            dryLand             : parentData.dryLand == '' ? 0 : parentData.dryLand,
            total               : parentData.total == '' ? 0 : parentData.total,
            avgYield            : parentData.avgYield == '' ? 0 : parentData.avgYield,
            variety             : parentData.variety == '' ? 'Not Specified' : parentData.variety,
            peanutType          : lstContract.pnut_type_name,
            segregation         : lstContract.seg_type,
            seedGrower          : parentData.seedGrower,
            seedCredit          : parentData.seedCredit,
            highOleic           : parentData.highOleic,
            organic             : parentData.organic,
            price               : "<u> " + parentData.price + " </u>",
            contractPounds      : "<u> " + parentData.contractPounds + " </u>",
            rebateRate          : parentData.rebateRate == 0 ? '0.00' : parentData.rebateRate,
            payPrice            : Number(parentData.payPrice) == 0 ? 0 : parentData.payPrice,
            mFloor              : parseFloat(parentData.mfloor).toFixed(2),
            mCeiling            : parseFloat(parentData.mCeiling).toFixed(2),
            pricePound          : parentData.pricePound, 
            purchasePeriod      : lstContract.delivery_period,
            lineHolderName1     : "<u> "  + await PadValues(parentData.lineHolderName1, 28, 'name') + " </u>",
            lineHolderAddr1     : "<u> "  + await PadValues(parentData.lineHolderAddr1, 30, 'address') + " </u>",
            lineHolderNature1   : "<u> "  + await PadValues(parentData.lineHolderNature1, 47, 'name') + " </u>",
            lineHolderName2     : "<u> "  + await PadValues(parentData.lineHolderName2, 28, 'name') + " </u>",
            lineHolderAddr2     : "<u> "  + await PadValues(parentData.lineHolderAddr2, 30, 'address') + " </u>",
            lineHolderNature2   : "<u> "  + await PadValues(parentData.lineHolderNature2, 47, 'name') + " </u>",
            mOptionPrice        : parentData.mOptionPrice,
            floorPrice          : parentData.floorPrice,
            floorDate           : parentData.floorDate,
            contractRemarks     : lstContract.contract_remarks,
            weekPeriod          : lstContract.contract_pricings.length == 0 ? 0 : lstContract.contract_pricings[0].mkt_wk_prior_mat,
            lockInData          : lstContract.contract_pricings.length == 0 ? [] : lstContract.contract_pricings[0].lock_in_data,
            contractPricing     : lstContract.contract_pricings.length == 0 ? contractPricing : lstContract.contract_pricings[0],
            zipCode             : '30022-8209'
        }
    }
 
    async function GetHtmlCode(fontSize, tableWidth, pageGap) {
        var obj = await GetHTMLData()
        if (obj == null || obj == undefined) {
            obj = {
                addDateTime : new Date(),
                contractDate : GetTodayDate(new Date()),
                contLbs : "0",
                cropYear: cropYear,
                ratePerTon : "0.00",
                contractNumber: contract_num,
                vendorNumber: lstContract.contract_vendor_splits[0].split_vendor,
                buyingPoint : '',
                splitPct : '',
                vendorName: "_______________",
                vendorAddress1: "_______________",
                vendorAddress2: "",
                vendorAddress3: "_____________________",
                deliveryPoint: "___",
                collectionPoint: "___",
                handlerNo: "______",
                licenseNo: "______",
                contractType: "M",
                centerPivotIrr: "",
                cableTowIrrigated: "",
                dryLand: "",
                total: "",
                avgYield: "",
                variety: "",
                peanutType: "",
                segregation: "",
                seedGrower: "",
                seedCredit: "",
                highOleic: "",
                organic: "&nbsp; YES &nbsp; _____ &nbsp; NO &nbsp; _____ &nbsp;",
                purchasePeriod: "___",
                price: "___",
                contractPounds: "0",
                lineHolderName1: "___________________",
                lineHolderAddr1: "___________________",
                lineHolderNature1: "___________________",
                lineHolderName2: "___________________",
                lineHolderAddr2: "___________________",
                lineHolderNature2: "___________________"
            }
        }
        
        var renderCode = ''
        let contractType = getData(thisObj, 'contractType')
        if(contractType == 'O')
            renderCode = getOptionContract(fontSize, tableWidth, pageGap, obj)
        else if(contractType == 'C')
            renderCode = getContract(fontSize, tableWidth, pageGap, obj)
        else if(contractType == 'M')
            renderCode = getFlexMarketContract(fontSize, tableWidth, pageGap, obj)
        else if(contractType == 'L')
            renderCode = getFloorMarketContract(fontSize, tableWidth, pageGap, obj)
        else if(contractType == 'S')
            renderCode = getSeedContract(fontSize, tableWidth, pageGap, obj)
        else
            alert("Contract type not valid")
        return renderCode;
    }

    function getOptionContract(fontSize, tableWidth, pageGap, obj){
        var dict = { 1 : 'st', 2 : 'nd', 3: 'rd', 4 : 'th', 5 : 'th', 6 : 'th', 7 : 'th', 8 : 'th', 9 : 'th', 10 : 'th', 11 : 'th', 12 : 'th', 13 : 'th', 14 : 'th', 15 : 'th', 16 : 'th', 17 : 'th', 18 : 'th', 19 : 'th', 20 : 'th', 21:'st', 22:'nd',23: 'rd', 24 : 'th', 25 : 'th', 26 : 'th', 27 : 'th', 28 : 'th', 29 : 'th', 30 : 'th', 31:'st'} 
        
        var FMChemical = getData(thisObj, 'cmdForeignMatarial') == 'Y' ? true : false;
        var ChemicalArg =  getData(thisObj, 'cmdChemicalAddendum') == 'Y' ? true : false;
        var vendorDisc = getData(thisObj,'minorDisclosure')=='Completed'?true:false;
        var comments = getData(thisObj, 'comments') 
        var fMChemical = ''
        var vendorDisclosure = ''
        var chemArg = ''
        var commentsArg = ''
    
        var pageNumber = 1
        var pageCount = 1
        
        if(FMChemical)
            pageCount++;
        if(comments)
            pageCount++;
        if(!vendorDisc)
            pageCount++;
        if(ChemicalArg)
            pageCount++
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
        var optionPage = `<html>
        <style>
        body:before{
            content: '${environmentType}';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;  
            color: #0d745e;
            font-size: 250px;
            font-weight: 500px;
            display: grid;
            justify-content: center;
            align-content: center;
            opacity: 0.35;
        }
        @media print {
            @page :footer { display: none }    
            @page :header { display: none }    
            @page { margin-top: 0; margin-bottom: 0; }    
            html,
            body { height: 100%; margin: 0 !important; padding: 0 !important; }
        }     
        </style>
        ${pageGap} 
        <p style="page-break-after: always">
        <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
            <tr>
                        <td style="padding-left:0.5%;padding-right:1%;">                	
                        <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                        </td>
                        <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
                            <br/>
                            <span style="font-size:150%;color: black;">
                                <b> Option for Purchase <br /> Of Farmer Stock Peanuts  </b>
                            </span>
                        </td>
                        <td style="padding-left:5%;">
                            <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                            <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                        </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:0.5%;font-size: 110%;color:black;"> <b> Date: <u> ${obj.contractDate} </u> </b> </td>
                        <td style="padding-left:0.01%;text-align: center; padding-right:0.5%;color:black;font-size:115%"> 
                            <b> (Acceptance by Golden Required) </b> <br/> <b> CROP YEAR &nbsp; <u> ${obj.cropYear} </b> </u>
                        </td>
                        <td style="padding-left:18%;font-size: 110%;color:black;"> <b> No: ${obj.contractNumber} </b> </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:0.5%;color:black;" colspan="2">  <b> Seller/Producer Name & Address: Vendor # <u> ${obj.vendorNumber} </u> </b> </td>
                        <td style="padding-left: 10%;color:black;"> <b>  Delivery Point:  ${obj.deliveryPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </b> </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:0.5%;color:black;" colspan="2">  <b> <u> ${obj.vendorName} </u>  &nbsp; ${obj.splitPct} </b> </td>
                        <td style="padding-left: 10%;color:black;"> <b> Collection Point:  ${obj.collectionPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </b> </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:0.5%;color:black;" colspan="2">  <b> <u> ${obj.vendorAddress1} &nbsp;&nbsp; ${obj.vendorAddress2} &nbsp; </u> </b> </td>
                        <td style="padding-left: 10%;color:black;"> <b>Handler No.: ${obj.handlerNo} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; </b> </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:0.5%;color:black;" colspan="2">  <b> <u> ${obj.vendorAddress3} &nbsp; &nbsp; </u>  </b> </td>
                        <td style="padding-left: 10%;color:black;"> <b> U.S.W.A. License No.: ${obj.licenseNo} </b>  </td> 
                    </tr>
                    <tr>
                        <td colspan="3">
                            <hr style="width: 100%" /> <hr style="width: 100%" /> 
                        </td>
                    </tr>        
                    <tr> 
                        <td style="padding-left:0.5%;text-align: justify;color:black;font-size:105%">
                            <b> <u>PLANTING &nbsp; COMMITMENT:</u> </b>
                        </td>
                    </tr>
        <tr>
            <td style="padding-left:0.5%;text-align: justify;color:black;" colspan="3"> <b>
                Acres: Center Pivot Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.centerPivotIrr} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                Cable Tow Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.cableTowIrrigated == 0 ? '' : obj.cableTowIrrigated} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                Dryland <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.dryLand == 0 ? '' : obj.dryLand} &nbsp;&nbsp;&nbsp;&nbsp; </u> &nbsp; &nbsp;
                Total Acres <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.total} &nbsp;&nbsp;&nbsp;&nbsp; </u> &nbsp; &nbsp; </b>
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.5%;text-align: justify;color:black;" colspan="3"> <b>
                Type <u>&nbsp;&nbsp; ${obj.peanutType} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                Segregation: <u> &nbsp;&nbsp; ${obj.segregation} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                Certified Organic: &nbsp; ${obj.organic} &nbsp; &nbsp; </b>
                <hr style="width: 100%" /> <hr style="width: 100%" />
            </td>
        </tr>    
        <tr>
            <td style="padding-left:0.01%;text-align: justify;color:black;padding-right:0.01%;" colspan="3">
                LIENS, ASSIGNMENTS OR PRIOR INTERESTS   : &nbsp; &nbsp; Seller certifies that there are no liens, crop insurance assignments or prior interests (such as, but not limited to, landlord's liens, landlord's crop share in the peanuts, landlord's crop share in money, FSA, security interests in favor of banks or other lenders, prior crop contracts, etc.) on the peanuts covered by this contract except as follows (use additional sheets if necessary):
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top: 0.2%;color:black;font-size:120%;"> Name of Lienholder/Assignee </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;font-size:120%;"> Address  </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;font-size:120%;"> Nature of Liens, Assignments or Prior Interests: </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top: 0.2%;color:black;"> 1. ${obj.lineHolderName1} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderAddr1}  </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderNature1} </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top: 0.2%;color:black;"> 2. ${obj.lineHolderName2} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderAddr2} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderNature2} </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top:0.05%;padding-right:0.01%;text-align:justify;color:black;" colspan="3">
                Seller is now the owner of, or warrants that he will plant not less than 90% of the committed acreage set out above and further warrants that he will produce in the United States during the applicable crop year, <u> &nbsp; &nbsp; &nbsp; &nbsp; ${obj.contractPounds} &nbsp; &nbsp; &nbsp; &nbsp; </u>lbs. of farmer stock peanuts, more particularly described above ("the Peanuts"). Upon acceptance by Golden, Seller is willing, for the consideration stated herein, to grant Golden such exclusive option to purchase the Peanuts. Golden has the right to reject if the peanuts contain any freeze damage. Should the Peanuts be stored by Golden prior to purchase, Golden will store the Peanuts in a USDA approved peanut storage facility at its published tariff rate.
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top:0.05%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Now therefore and in consideration of (a) $ <u> ${signDetails.signing_advance} </u> paid on the execution hereof in cash or credit to Seller's account and (b) $ <u>${obj.price}</u> per ton, basis grade, to be paid when the peanuts are inspected, less the amount specified in paragraph (a) above, Seller does hereby grant to Golden the exclusive right to purchase the Peanuts on the terms set out below. Should the USDA Loan Differential be set at a rate other than $355.00 for the applicable type, the amount of the option payment shall be adjusted in an equal and inverse amount
            </td>
        </tr>
        <tr>
            <td style="padding-top:0.05%;padding-left:0.31%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                <ol start="1" style="padding-left: 1em; list-style: decimal;">
                    <li style="color:black;">
                        The purchase price of the Peanuts in the event Golden exercises its option to purchase shall be the USDA loan repayment rate, basis grade, for the Peanut type in effect on the date Golden exercises this option. Basis grade shall be determined using the official USDA Price Table File for the above-referenced crop year and Type.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Golden may exercise this Option by (i) giving written notice of its exercise to Seller either in person or by U.S. Mail and addressed to Seller at the address set out above, or (ii) at Seller's discretion, Seller will permit Golden to make repayments and redemptions on Seller's behalf; if Seller does so, then by repaying Seller's Marketing Assistance Loan (MAL) and receiving transfer of the applicable warehouse receipt. If exercise is under (i), the date Golden exercises this option shall be deemed to be the date of the notice if in person, or otherwise on the date mailed. If exercise is under (ii), the exercise shall be effective upon Golden's repaying the MAL on Seller's behalf.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Upon notice of exercise, Seller shall immediately transfer and deliver to Golden the original Warehouse Receipt and possession of the Peanuts. The sale shall be FOB the Storage Location of the Peanuts unless Golden exercises prior to storage, in which case the sale shall be FOB the Delivery Point.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        (a). Golden may exercise this option at any time during a period beginning on the date hereof and ending on the date of loan forfeiture if there is a marketing loan, or if not a marketing loan, the date of forfeiture had there been a marketing loan taken on the last day of eligibility. <br />
                        (b). Notwithstanding any other provision of this option to purchase or any other contract, title and control of the commodity and beneficial interest in the commodity, as specified in 7CFR 1421.6, shall remain with the Producer until the Buyer exercises this option to purchase the commodity. This option to purchase shall expire, notwithstanding any action or inaction by either the Producer or the Buyer, at the earlier of: (1) the maturity of any Commodity Credit Corporation (CCC) loan that is secured by such commodity; (2) the date CCC claims title to such commodity; or (3) such other date as provided in this option;
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller warrants that the Peanuts sold hereunder shall, at the time this document is executed and hereafter, be free and clear of all indebtedness, liens, security interests and encumbrances unless otherwise noted herein and have not been nor will be contracted or optioned for sale to any other entity.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller has full right and title to the Peanuts and agrees to indemnify and hold Golden harmless from and against any claims and encumbrances or adverse claims to the Peanuts that might be asserted by any other party.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        If Seller has previously contracted peanuts with Golden, the order of delivery shall be in the chronological order of the date of the contracts.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        If the Peanuts should be covered by any prior agreement between the parties hereto, such prior agreement is hereby amended to specifically exclude the Peanuts.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        The rights and obligations of Seller may not be assigned without the consent of Golden, such consent to not be unreasonably withheld. Golden may assign without the consent of Seller any or all of its obligations hereunder
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller warrants the peanuts covered hereby to be fit for human consumption and not in violation of any provisions of the Federal Food, Drug and Cosmetic Act, Environmental Protection Agency Regulations and the Federal Insecticide, Fungicide and Rodenticide Act and that no daminozides, alachlor, botran, monosodium methanearsonate (MSMA) or any pesticides not specifically labeled for peanuts have been used. Seller agrees to indemnify and hold Golden, its employees, agents, officers and directors harmless from all loss or liability, including attorney's fees incurred by Golden as a result of Seller's breach of such warranties.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller and Golden agree that they have executed and delivered this Option in contemplation of the limited source or market of the Peanuts. Seller acknowledges that Golden has entered or will enter into commitments with other persons in reliance upon Seller's performance hereunder. In recognition of the foregoing and the unique characteristics and source of peanuts, Seller agrees that Golden shall be entitled to, in addition to any other remedy at law or in equity which Golden may have under this Option or otherwise, the remedy of specific performance of Seller's obligations under this Option, and breach or threatened breach of this Option shall entitle Golden to a temporary or permanent restraining order or injunction. Further, Seller, his successors, heirs and permitted assigns, waive any claim or defense that there is an adequate remedy at law for such breach or threatened breach.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Any controversy, dispute or claim arising out of or relating to this contract or the breach of this contract, including any controversy, dispute or claim that arises out of any instrument or document related to this contract, and including any claim based on an alleged tort, shall be resolved by binding arbitration administered by the American Arbitration Association under its commercial arbitration rules, and judgement on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Any party to this contract may bring an action, including a summary or expedited proceeding, to compel arbitration of any controversy or claim to which these rules apply in any court having jurisdiction over such action. The arbitration shall be conducted by the Atlanta, Georgia, U.S.A. office of the American Arbitration Association.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        THIS AGREEMENT SHALL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE STATE OF  GEORGIA.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        This Agreement is effective upon acceptance by Golden, as evidenced by signature of an authorized employee of Golden. BUYING POINT PERSONNEL ARE NOT AUTHORIZED EMPLOYEES.
                    </li>
                </ol>    
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;color:black;" colspan="2">
            ${pageGap} <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC </b> </span> <br/> By, Name and Title
            </td>            
        </tr> 
        <tr>
            <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
            </td>
        </tr>
        <tr>
            <td style="align-items: center;padding-left: 45%;color:black;" colspan="2">
                <br/> <br/> Page  ${pageNumber}  of  ${pageCount} <br/>
            </td>
            <td style="align-items: center;padding-left: 15%;color:black;" colspan="2">
                <br/> <br/> Rev. &nbsp; Jan 2009 <br/>
            </td>
        </tr> 
    </table>
        </p>`
        
        if(FMChemical){
            pageNumber++;
            fMChemical = getFMChemicalData(fontSize, obj, dict, pageNumber, pageCount)
        }
        if(comments){
            pageNumber++;
            commentsArg = getContractComments(fontSize, tableWidth, pageGap, obj, pageNumber, pageCount)
        }
        if(!vendorDisc){
            pageNumber++;
            vendorDisclosure = getVendorDisclosureData(fontSize, tableWidth, obj, pageNumber, pageCount)
        }
        if(ChemicalArg){
            pageNumber++;
            chemArg = getChemicalArgData(fontSize, tableWidth, obj, dict, pageNumber, pageCount)
        }     
    return optionPage + fMChemical + commentsArg + vendorDisclosure + chemArg + `</html>`
    }

    function getContract(fontSize, tableWidth, pageGap, obj){
        var dict = { 1 : 'st', 2 : 'nd', 3: 'rd', 4 : 'th', 5 : 'th', 6 : 'th', 7 : 'th', 8 : 'th', 9 : 'th', 10 : 'th', 11 : 'th', 12 : 'th', 13 : 'th', 14 : 'th', 15 : 'th', 16 : 'th', 17 : 'th', 18 : 'th', 19 : 'th', 20 : 'th', 21:'st', 22:'nd',23: 'rd', 24 : 'th', 25 : 'th', 26 : 'th', 27 : 'th', 28 : 'th', 29 : 'th', 30 : 'th', 31:'st'} 
        
        var FMChemical = getData(thisObj, 'cmdForeignMatarial') == 'Y' ? true : false;
        var ChemicalArg =  getData(thisObj, 'cmdChemicalAddendum') == 'Y' ? true : false;
        var vendorDisc = getData(thisObj,'minorDisclosure')=='Completed'?true:false;
        var comments = getData(thisObj, 'comments')

        var fMChemical = ''
        var vendorDisclosure = ''
        var chemArg = ''
        var commentsArg = ''
        var pageNumber = 2
        var pageCount = 2
        
        if(FMChemical)
            pageCount++;
        if(comments)
            pageCount++;
        if(!vendorDisc)
            pageCount++;
        if(ChemicalArg)
            pageCount++

        if(FMChemical){
            pageNumber++;
            fMChemical = getFMChemicalData(fontSize, obj, dict, pageNumber, pageCount)
        }
        if(comments){
            pageNumber++;
            commentsArg = getContractComments(fontSize, tableWidth, pageGap, obj, pageNumber, pageCount)
        }
        if(!vendorDisc){
            pageNumber++;
            vendorDisclosure = getVendorDisclosureData(fontSize, tableWidth, obj, pageNumber, pageCount)
        }
        if(ChemicalArg){
            pageNumber++;
            chemArg = getChemicalArgData(fontSize, tableWidth, obj, dict, pageNumber, pageCount)
        }   
        return getNewContractData(fontSize, tableWidth, pageGap, obj, pageCount) + fMChemical + commentsArg + vendorDisclosure + chemArg + `</html>`;
    }

    function getFlexMarketContract(fontSize, tableWidth, pageGap, obj){
        var dict = { 'January' : '01', 'February' : '02', 'March' : '03', 'April': '04', 'May' : '05', 'June': '06', 'July': '07', 'August' : '08', 'September':'09','October':'10', 'November':'11', 'December': '12',1 : 'st', 2 : 'nd', 3: 'rd', 4 : 'th', 5 : 'th', 6 : 'th', 7 : 'th', 8 : 'th', 9 : 'th', 10 : 'th', 11 : 'th', 12 : 'th', 13 : 'th', 14 : 'th', 15 : 'th', 16 : 'th', 17 : 'th', 18 : 'th', 19 : 'th', 20 : 'th', 21:'st', 22:'nd',23: 'rd', 24 : 'th', 25 : 'th', 26 : 'th', 27 : 'th', 28 : 'th', 29 : 'th', 30 : 'th', 31:'st'} 
        
        var FMChemical = getData(thisObj, 'cmdForeignMatarial') == 'Y' ? true : false;
        var ChemicalArg =  getData(thisObj, 'cmdChemicalAddendum') == 'Y' ? true : false;
        var vendorDisc = getData(thisObj,'minorDisclosure')=='Completed'?true:false;
        var comments = getData(thisObj, 'comments')

        var fMChemical = ''
        var vendorDisclosure = ''
        var chemArg = ''
        var commentsArg = ''
        var pageNumber = 1
        var pageCount = 1
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
        if(FMChemical)
            pageCount++;
        pageCount++;
        if(comments)
            pageCount++
        if(!vendorDisc)
            pageCount++;
        if(ChemicalArg)
            pageCount++
        
        var numberToWordArr = ['', ' one', ' two', ' three', ' four', ' five', ' six', ' seven', ' eight', ' nine', ' ten', ' eleven', ' twelve', ' thirteen', ' fourteen', ' fifteen', ' sixteen', ' seventeen', ' eighteen', ' nineteen']
        var flexPagePara1 = `This letter acknowledges that you have entered into an <u> Option for Purchase of Farmer Stock Peanuts </u> ("Contract") number <u> ${obj.contractNumber} </u> with Golden Peanut Company, LLC ("Golden") for the <u> ${obj.cropYear} </u> crop year.  You are eligible to participate in the Golden Flexible Marketing Plan ("Plan").  You will automatically be enrolled at the time that you obtain a USDA Marketing Assistance Loan ("Loan"), if you so choose.`
        var flexPagePara2 = `The Marketing Period shall be from the date of your Loan, `
        if(obj.lockInData.length !== 0){
            flexPagePara2 += `with tons being required to be priced as follows:`
            for(var i=0; i< obj.lockInData.length; i++){
                flexPagePara2 += (obj.lockInData[i].pct + '% by ' + obj.lockInData[i].date)
                i++;
                if(i<obj.lockInData.length)
                    flexPagePara2 += ', plus'
            }
            flexPagePara2 += `<br/> Larger quantities may be priced by the specified dates, if you so elect.`
        }
        else{
            flexPagePara2 += `until ${numberToWordArr[obj.weekPeriod]}  weeks prior to the scheduled maturity of your Loan.  You have until the end of the Marketing Period to lock in your `
            flexPagePara2 += ((obj.contractPricing.rebate_rate != '' ? 'Rebate' : 'Gain') + ', even if Golden has already redeemed the peanuts')
            flexPagePara2 += (obj.contractPricing.rebate_rate == '' ? ' or has already locked in the Loan Repayment Rate with the USDA' : '')
            flexPagePara2 += '.'

            if(obj.contractPricing.multi_pricing_ind == 'Y'){
                flexPagePara2 += ' You can lock in your '
                flexPagePara2 += ((obj.contractPricing.rebate_rate != '' ? 'Rebate' : 'Gain') + ' on all of the delivered contract pounds or in partial quantities.')
            }
            else
                flexPagePara2 += ' You will have one opportunity to lock in the entire quantity of the Contract.'
        }
        
        var flexPagePara3 = `As a participant in the Plan, at any time during the Marketing Period as explained above you can`
        if(obj.contractPricing.mkt_gain_share != '' && obj.contractPricing.rebate_rate != '')
            flexPagePara3 += '  a.)'
        if(obj.contractPricing.rebate_rate != ''){
            flexPagePara3 += ` elect to lock in a Market Rebate ("Rebate") of $ ${obj.contractPricing.rebate_rate} per farmer stock ton, basis grade, for every $ ${obj.contractPricing.inc_price_per_pound} per pound increase in the ${obj.cropYear} crop shelled U.S. Runner Medium market price above $ ${obj.contractPricing.rebate_rate} per pound, ` 
            if(obj.contractPricing.market_ceiling != '')
                flexPagePara3 += `up to a maximum of $ ${obj.mCeiling} per pound, `
            flexPagePara3 += `as determined by the posted Golden's Weekly Flexible Market Price`
        }
        if(obj.contractPricing.mkt_gain_share != '' && obj.contractPricing.rebate_rate != '')
            flexPagePara3 += '  b.)'
        if(obj.contractPricing.mkt_gain_share != '')
            flexPagePara3 += ` elect to lock in the Market Loan Gain ("Gain"), as determined by the USDA Loan Repayment Rate for peanuts.  You will be entitled to receive ${obj.contractPricing.mkt_gain_share} % of the Gain on the delivered pounds of your Contract`
        flexPagePara3 += '.  Golden reserves the right to redeem the peanuts on the Contract at any time, regardless of when you lock in your '
        flexPagePara3 += (obj.contractPricing.rebate_rate != '' ? 'Rebate' : 'Gain')

        var flexPagePara4 = ''
        if(obj.contractPricing.loan_repay_ind == 'G' && obj.contractPricing.rebate_rate != ''){
            flexPagePara4 += "Golden's Weekly Flexible Market Price used to establish the Market Rebate"
            if(obj.contractPricing.mkt_gain_share != '')
                flexPagePara4 += ', and the "Golden Marketing Loan Gain",'
            flexPagePara4 += ` will be announced and posted each Monday at 3:00 p.m. Eastern time and will remain in effect until the next Monday at 3:00 p.m. Eastern time.  Golden will obtain market quotes from selected peanut brokers to determine the weekly market price for ${obj.cropYear} crop shelled U.S. Runner Medium peanuts`
            if(obj.contractPricing.mkt_gain_share != '')
                flexPagePara4 += `, and will use the USDA loan repayment rate for peanuts of the prior Tuesday to establish the "Golden Marketing Loan Gain"`
            flexPagePara4 += '.'
        }
        var flexPagePara5 = `You will be responsible for locking in your ` + (obj.contractPricing.rebate_rate != '' ? 'Rebate' : 'Gain') + ' in writing on a '
        flexPagePara5 += ((obj.contractPricing.rebate_rate != '' ? 'Price Election' : '"Market Loan Gain Sharing Plan Election"') + ' form provided by Golden.  Should you fail to ')

        if(obj.lockInData.length != 0)
            flexPagePara5 += 'price the required tons by the specified dates'
        else
            flexPagePara5 += ('lock in your ' + (obj.contractPricing.rebate_rate != '' ? 'Rebate' : 'Gain') + ' during the Marketing Period')
        flexPagePara5 += (', the ' + (obj.contractPricing.rebate_rate != '' ? 'Rebate' : 'Market Loan Gain') + ' in effect at the time ')
        if(obj.lockInData.length == 0)
            flexPagePara5 += 'your Marketing Period expires '
        flexPagePara5 += 'will be used.'
        
        var flexPagePara6 = ('The ' + (obj.contractPricing.rebate_rate != '' ? 'Rebate' : ''))
        if(obj.contractPricing.mkt_gain_share != '' && obj.contractPricing.rebate_rate != '')
            flexPagePara6 += ' or the '
        if(obj.contractPricing.mkt_gain_share != '')
            flexPagePara6 += 'split in the "Golden Marketing Loan Gain"'
        flexPagePara6 += ' will be paid at the'
        if(obj.contractPricing.pay_rebate_at == 'PRICING')
            flexPagePara6 += " time you elect to price your peanuts. "
        else
            flexPagePara6 += " end of the Marketing Period. "
        flexPagePara6 += ' We appreciate this opportunity to work with you.  Should you have any questions, please contact your Golden Peanut Company buying point representative.'

        var optionPage = `<html>
        <style>
        body:before{
            content: '${environmentType}';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;  
            color: #0d745e;
            font-size: 250px;
            font-weight: 500px;
            display: grid;
            justify-content: center;
            align-content: center;
            opacity: 0.35;
        }
        @media print {
            @page :footer { display: none }    
            @page :header { display: none }    
            @page { margin-top: 0; margin-bottom: 0; }    
            html,
            body { height: 100%; margin: 0 !important; padding: 0 !important; }
        }     
        </style>
        ${pageGap} 
        <p style="page-break-after: always">
        <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
            <tr style="padding-left:1%; padding-right: 1%;">
                        <td style="padding-left:2.5%; padding-right: 1%;">                	
                        <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                        </td>
                        <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
                            <br/>
                            <span style="font-size:150%;color: black;">
                                <b> Option for Purchase <br /> Of Farmer Stock Peanuts  </b>
                            </span>
                        </td>
                        <td style="padding-left:10%;">
                            <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                            <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                        </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:2.5%;font-size: 110%;color:black;"> <b> Date: <u> ${obj.contractDate} </u> </b> </td>
                        <td style="padding-left:0.01%;text-align:center; padding-right: 0.01%;color:black;font-size:115%"> 
                            <b> (Acceptance by Golden Required) </b> <br/>
                            <b> CROP YEAR &nbsp;  <u> ${obj.cropYear} </b> </u>
                        </td>
                        <td style="padding-left:18%;font-size: 110%;color:black;"> <b> No: ${obj.contractNumber} </b> </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2"> 
                            Seller/Producer Name & Address: Vendor #  <u> <u> ${obj.vendorNumber} </u>  </u> 
                        </td>
                        <td style="padding-left: 10%;color:black;"> Delivery Point &nbsp; :  ${obj.deliveryPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2"> <u> ${obj.vendorName} </u>  ${obj.splitPct} </td>
                        <td style="padding-left: 10%;color:black;"> Collection Point &nbsp; :  ${obj.collectionPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2"> <u> ${obj.vendorAddress1} &nbsp;&nbsp; ${obj.vendorAddress2} </u> &nbsp; </td>
                        <td style="padding-left: 10%;color:black;"> Handler No &nbsp; : ${obj.handlerNo} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2">  <u> ${obj.vendorAddress3} &nbsp; &nbsp; </u> </td>
                        <td style="padding-left: 10%;color:black;"> U.S.W.A. License No &nbsp; : ${obj.licenseNo}  </td> 
                    </tr>
                    <tr>
                        <td style="padding-right: 3.75%;" colspan="3">
                            <hr style="width: 100%" /> <hr style="width: 100%" /> 
                        </td>
                    </tr>        
                    <tr> 
                        <td style="padding-left:2.5%;text-align: justify;color:black;font-size:105%">
                            <u>PLANTING &nbsp; COMMITMENT:</u>
                        </td>
                    </tr>
        <tr>
            <td style="padding-left:2.5%; padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Acres: Center Pivot Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.centerPivotIrr} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                Cable Tow Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.cableTowIrrigated == 0 ? '' : obj.cableTowIrrigated} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                Dryland <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.dryLand  == 0 ? '' : obj.dryLand} &nbsp;&nbsp;&nbsp;&nbsp; </u> &nbsp; &nbsp;
                Total Acres <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.total} &nbsp;&nbsp;&nbsp;&nbsp; </u> &nbsp; &nbsp;
            </td>
        </tr>
        <tr>
            <td style="padding-left:2.5%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Type <u>&nbsp;&nbsp; ${obj.peanutType} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                Segregation: <u> &nbsp;&nbsp; ${obj.segregation} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                Certified Organic: &nbsp; ${obj.organic} &nbsp; &nbsp;
                <hr style="width: 100%" /> <hr style="width: 100%" />
            </td>
        </tr>    
        <tr>
            <td style="padding-left:2.5%;padding-right: 12%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                LIENS, &nbsp; ASSIGNMENTS &nbsp; OR &nbsp; PRIOR &nbsp; INTERESTS &nbsp; : &nbsp; &nbsp; Seller certifies that there are no liens, crop insurance assignments or prior interests (such as, but not limited to, landlord's liens, landlord's crop share in the peanuts, landlord's crop share in money, FSA, security interests in favor of banks or other lenders, prior crop contracts, etc.) on the peanuts covered by this contract except as follows (use additional sheets if necessary):
            </td>
        </tr>
        <tr>
        <td style="padding-left:2.5%;padding-top: 0.2%;color:black;"> Name of Lienholder/Assignee </td>
        <td style="padding-left:3%;padding-top: 0.2%;color:black;"> Address  </td>
        <td style="padding-top: 0.2%;color:black;"> Nature of Liens, Assignments or Prior Interests: </td>
        </tr>
        <tr>
            <td style="padding-left:2.5%;padding-top: 0.2%;color:black;"> 1. ${obj.lineHolderName1} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderAddr1}  </td>
            <td style="padding-top: 0.2%;color:black;">  ${obj.lineHolderNature1} </td>
        </tr>
        <tr>
            <td style="padding-left:2.5%;padding-top: 0.2%;color:black;"> 2. ${obj.lineHolderName2} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderAddr2} </td>
            <td style="padding-top: 0.2%;color:black;">  ${obj.lineHolderNature2} </td>
        </tr>
        <tr>
            <td style="padding-left:2.5%;padding-top:0.05%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Seller is now the owner of, or warrants that he will plant not less than 90% of the committed acreage set out above and further warrants that he will produce in the United States during the applicable crop year, <u> &nbsp; &nbsp; &nbsp; &nbsp; ${obj.contractPounds} &nbsp; &nbsp; &nbsp; &nbsp; </u>lbs. of farmer stock peanuts, more particularly described above ("the Peanuts"). Upon acceptance by Golden, Seller is willing, for the consideration stated herein, to grant Golden such exclusive option to purchase the Peanuts. Golden has the right to reject if the peanuts contain any freeze damage. Should the Peanuts be stored by Golden prior to purchase, Golden will store the Peanuts in a USDA approved peanut storage facility at its published tariff rate.
            </td>
        </tr>
        <tr>
            <td style="padding-left:2.5%;padding-top:0.05%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Now therefore and in consideration of (a) $ <u> ${signDetails.signing_advance} </u> paid on the execution hereof in cash or credit to Seller's account and (b) $ <u>${obj.price}</u> per ton, basis grade, to be paid when the peanuts are inspected, less the amount specified in paragraph (a) above, Seller does hereby grant to Golden the exclusive right to purchase the Peanuts on the terms set out below. Should the USDA Loan Differential be set at a rate other than $355.00 for the applicable type, the amount of the option payment shall be adjusted in an equal and inverse amount
            </td>
        </tr>
        <tr>
            <td style="padding-top:0.05%;padding-left:0.5%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                <ol start="1">
                    <li style="color:black;">
                        The purchase price of the Peanuts in the event Golden exercises its option to purchase shall be the USDA loan repayment rate, basis grade, for the Peanut type in effect on the date Golden exercises this option. Basis grade shall be determined using the official USDA Price Table File for the above-referenced crop year and Type.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Golden may exercise this Option by (i) giving written notice of its exercise to Seller either in person or by U.S. Mail and addressed to Seller at the address set out above, or (ii) at Seller's discretion, Seller will permit Golden to make repayments and redemptions on Seller's behalf; if Seller does so, then by repaying Seller's Marketing Assistance Loan (MAL) and receiving transfer of the applicable warehouse receipt. If exercise is under (i), the date Golden exercises this option shall be deemed to be the date of the notice if in person, or otherwise on the date mailed. If exercise is under (ii), the exercise shall be effective upon Golden's repaying the MAL on Seller's behalf.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Upon notice of exercise, Seller shall immediately transfer and deliver to Golden the original Warehouse Receipt and possession of the Peanuts. The sale shall be FOB the Storage Location of the Peanuts unless Golden exercises prior to storage, in which case the sale shall be FOB the Delivery Point.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        (a). Golden may exercise this option at any time during a period beginning on the date hereof and ending on the date of loan forfeiture if there is a marketing loan, or if not a marketing loan, the date of forfeiture had there been a marketing loan taken on the last day of eligibility. <br />
                        (b). Notwithstanding any other provision of this option to purchase or any other contract, title and control of the commodity and beneficial interest in the commodity, as specified in 7CFR 1421.6, shall remain with the Producer until the Buyer exercises this option to purchase the commodity. This option to purchase shall expire, notwithstanding any action or inaction by either the Producer or the Buyer, at the earlier of: (1) the maturity of any Commodity Credit Corporation (CCC) loan that is secured by such commodity; (2) the date CCC claims title to such commodity; or (3) such other date as provided in this option;
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller warrants that the Peanuts sold hereunder shall, at the time this document is executed and hereafter, be free and clear of all indebtedness, liens, security interests and encumbrances unless otherwise noted herein and have not been nor will be contracted or optioned for sale to any other entity.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller has full right and title to the Peanuts and agrees to indemnify and hold Golden harmless from and against any claims and encumbrances or adverse claims to the Peanuts that might be asserted by any other party.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        If Seller has previously contracted peanuts with Golden, the order of delivery shall be in the chronological order of the date of the contracts.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        If the Peanuts should be covered by any prior agreement between the parties hereto, such prior agreement is hereby amended to specifically exclude the Peanuts.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        The rights and obligations of Seller may not be assigned without the consent of Golden, such consent to not be unreasonably withheld. Golden may assign without the consent of Seller any or all of its bligations hereunder
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller warrants the peanuts covered hereby to be fit for human consumption and not in violation of any provisions of the Federal Food, Drug and Cosmetic Act, Environmental Protection Agency Regulations and the Federal Insecticide, Fungicide and Rodenticide Act and that no daminozides, alachlor, botran, monosodium methanearsonate (MSMA) or any pesticides not specifically labeled for peanuts have been used. Seller agrees to indemnify and hold Golden, its employees, agents, officers and directors harmless from all loss or liability, including attorney's fees incurred by Golden as a result of Seller's breach of such warranties.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller and Golden agree that they have executed and delivered this Option in contemplation of the limited source or market of the Peanuts. Seller acknowledges that Golden has entered or will enter into commitments with other persons in reliance upon Seller's performance hereunder. In recognition of the foregoing and the unique characteristics and source of peanuts, Seller agrees that Golden shall be entitled to, in addition to any other remedy at law or in equity which Golden may have under this Option or otherwise, the remedy of specific performance of Seller's obligations under this Option, and breach or threatened breach of this Option shall entitle Golden to a temporary or permanent restraining order or injunction. Further, Seller, his successors, heirs and permitted assigns, waive any claim or defense that there is an adequate remedy at law for such breach or threatened breach.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Any controversy, dispute or claim arising out of or relating to this contract or the breach of this contract, including any controversy, dispute or claim that arises out of any instrument or document related to this contract, and including any claim based on an alleged tort, shall be resolved by binding arbitration administered by the American Arbitration Association under its commercial arbitration rules, and judgement on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Any party to this contract may bring an action, including a summary or expedited proceeding, to compel arbitration of any controversy or claim to which these rules apply in any court having jurisdiction over such action. The arbitration shall be conducted by the Atlanta, Georgia, U.S.A. office of the American Arbitration Association.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        THIS AGREEMENT SHALL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE STATE OF  GEORGIA.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        This Agreement is effective upon acceptance by Golden, as evidenced by signature of an authorized employee of Golden. BUYING POINT PERSONNEL ARE NOT AUTHORIZED EMPLOYEES.
                    </li>
                </ol>    
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;color:black;" colspan="2">
                ${pageGap} <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC/Buyer </b> </span> <br/> By, Name and Title
            </td>            
        </tr> 
        <tr>
            <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
            </td>
        </tr> 
        <tr>
            <td style="align-items: center;padding-left: 45%;color:black;" colspan="2">
                <br/> <br/> Page  ${pageNumber}  of  ${pageCount}
            </td>
            <td style="align-items: center;padding-left: 15%;color:black;" colspan="2">
                <br/> <br/> Rev. &nbsp; Jan 2009
            </td>
        </tr> 
    </table>
        </p>`
        
        if(FMChemical){
            pageNumber++;
            fMChemical = getFMChemicalData(fontSize, obj, dict, pageNumber, pageCount)
        }
        pageNumber++;    
        if(comments){
            pageNumber++;
            commentsArg = getContractComments(fontSize, tableWidth, pageGap, obj, pageNumber, pageCount)
        }
        var flexMarketPage = `<p style="page-break-after: always">
        
    <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
        <tr style="padding-left:1%; padding-right: 1%;">
            <td style="padding-left:2.5%; padding-right: 1%;">                	
                <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
            </td>
            <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
            </td>
            <td style="padding-left:15%;">
                <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
            </td>
        </tr> 
        <tr style="padding-left:1%; margin-bottom:5%;padding-right: 1%;">
        <td style="padding-left:0.01%;margin-bottom:5%; padding-right: 1%;color:black;font-size:135%;"> <br>
            <br>
            Date: ${dict[GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[0].trim()]}/${GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[1].trim()}/${GetTodayDate(obj.contractDate).split(',')[2].trim()}  <br>
            <br>
        </td>
        </tr>
        <tr style="padding-left:1%; padding-right: 1%;">
            <td style="padding-left:0.01%; padding-right: 1%;color:black;font-size:135%;"> 
                ${obj.vendorName} &nbsp; 
            </td>
        </tr>
    <tr style="padding-left:1%; padding-right: 1%;">
        <td style="padding-left:0.01%; padding-right: 1%;color:black;font-size:135%;"> 
            ${obj.vendorAddress1} &nbsp;&nbsp; ${obj.vendorAddress2} &nbsp;
        </td>
    </tr>
    <tr style="padding-left:1%; padding-right: 1%;">
    <td style="padding-left:0.01%; padding-right: 1%;color:black;font-size:135%;"> 
        ${obj.vendorAddress3} &nbsp; &nbsp; <br>
             <br>
        </td>       
    </tr>
    <tr style="padding-left:1%; padding-right: 1%;">
        <td style="padding-left:0.01%; padding-right: 1%;color:black;font-size:135%;">  
        Subject: Flexible Marketing Plan <br>
        <br>
        </td>
    </tr>
    <tr style="padding-left:1%; padding-right: 1%;">
        <td style="padding-left:0.01%; padding-right: 1%;color:black;font-size:135%;">  
        Dear Sir or Madame, <br> <br>
        <br>
        </td>
    </tr>
    <tr>
        <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
        ${flexPagePara1}
        <br> <br>
        </td>
    </tr> 
    <tr>
    <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
        ${flexPagePara2} <br>
    <br>
    </td>
    </tr> 
    <tr>
    <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
        ${flexPagePara3}  <br>  
    <br>
    </td>
    </tr> 
    <tr>
    <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
        ${flexPagePara4} <br>
    <br>
    </td>
    </tr> 
    <tr>
    <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
        ${flexPagePara5} <br> <br>
    <br>
    </td>
    </tr> 
    <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
        ${flexPagePara6} <br> <br>
    <br>
    </td>
    </tr> 
    </tr> 
    <td style="padding-left:0.01%;padding-right:5%;text-align: justify;color:black;font-size:135%;" colspan="3">
    
    Sincerely,   <br> <br> <br> <br> <br> <br> <br> 
    </td>
    </tr>
    <tr>
    <td style="padding-left:0.01%;color:black;" colspan="2">
    
    <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC <br></b> </span> <br/> By, Name and Title
    </td>
    </tr>       
            <tr>
                <td style="align-items: center;padding-left: 45%;color:black;" colspan="3">
                    <br/> <br/> <br/> <br/> <br/> Page  ${pageNumber}  of  ${pageCount}
                </td>            
            </tr>  
        </table>
        </p>`

        if(!vendorDisc){
            pageNumber++;
            vendorDisclosure = getVendorDisclosureData(fontSize, tableWidth, obj, pageNumber, pageCount)
        }
        if(ChemicalArg){
            pageNumber++;
            chemArg = getChemicalArgData(fontSize, tableWidth, obj, dict, pageNumber, pageCount)
        }  
        return optionPage + fMChemical + flexMarketPage + commentsArg + vendorDisclosure + chemArg + `</html>`
    }

    async function getFloorMarketContract(fontSize, tableWidth, pageGap, obj){
        var dict = { 'January' : '01', 'February' : '02', 'March' : '03', 'April': '04', 'May' : '05', 'June': '06', 'July': '07', 'August' : '08', 'September':'09','October':'10', 'November':'11', 'December': '12',1 : 'st', 2 : 'nd', 3: 'rd', 4 : 'th', 5 : 'th', 6 : 'th', 7 : 'th', 8 : 'th', 9 : 'th', 10 : 'th', 11 : 'th', 12 : 'th', 13 : 'th', 14 : 'th', 15 : 'th', 16 : 'th', 17 : 'th', 18 : 'th', 19 : 'th', 20 : 'th', 21:'st', 22:'nd',23: 'rd', 24 : 'th', 25 : 'th', 26 : 'th', 27 : 'th', 28 : 'th', 29 : 'th', 30 : 'th', 31:'st'} 
        let response = await ContractManagementService.RetrievePeanutPriceScheduleDetails(lstContract.area_id, lstContract.pnut_type_id)
        let floorObj = {
            'areaName'       : response[2].strFieldData == 'SE' ? 'Southeast Area' : response[2].strFieldData == 'SW' ? 'SouthWest Area' : response[2].strFieldData == 'VC' ? 'Virginia/Carolina Area' : 'Area',
            "firmFloorPrice" : lstContract.contract_pricings.length != 0 ?   Math.round((Number(lstContract.contract_pricings[0].price_per_ton)    + Number(response[4].strFieldData) + Number.EPSILON) * 100) / 100 : Number(response[4].strFieldData),
            "basisMaxOption" : lstContract.contract_pricings.length != 0 ?   "$" + Number(lstContract.contract_pricings[0].max_option_price) + ".00" : 0,
            "firmMaxOption"  : lstContract.contract_pricings.length != 0 ?   Math.round((Number(lstContract.contract_pricings[0].max_option_price)    + Number(response[4].strFieldData) + Number.EPSILON) * 100) / 100 : Number(response[4].strFieldData),
            "endDate"        : GetTodayDate(lstContract.contract_pricings[0].price_match_end_date),
            "displayName"    : await PadValues(obj.vendorName, 0, 'name')
        }
        var floorData = ''
        if(lstContract.contract_pricings.length != 0){
            var msPayRebate = lstContract.contract_pricings[0].pay_rebate_at
            var msMaxOption = Number(lstContract.contract_pricings[0].max_option_price)
            if(msPayRebate == 'PRICING' && msMaxOption == 0){
                floorData = `Seller/Producer ${floorObj.displayName.trim()} shall have the option to firm price this contract at the existing Golden Peanut Company 
                            ${floorObj.areaName} farmer stock price for ${obj.peanutType} peanut type, any time after delivery of the peanuts and before ${floorObj.endDate}. On ${floorObj.endDate} 
                            any pounds not priced will be firmed at the higher of the $ ${obj.contractPricing.price_per_ton} Option Price ($ ${floorObj.firmFloorPrice} 
                            farmer stock price basis  grade) or the existing Golden Peanut Company ${floorObj.areaName} farmer stock price (basis grade).`
            }
            else if(msPayRebate == 'PRICING' && msMaxOption != 0){
                floorData = `Seller/Producer ${floorObj.displayName.trim()} shall have the opportunity to firm price this contract at the existing Golden Peanut Company 
                            ${floorObj.areaName} farmer stock price for ${obj.peanutType} peanut type, but not to exceed  ${floorObj.basisMaxOption} Option Price Cap($ 
                            ${floorObj.firmMaxOption} per farmer stock ton, basis grade), any time after inspection of the peanuts and before  ${floorObj.endDate}. On ${floorObj.endDate} any contracted 
                            pounds not priced will be firm priced at the higher of the $ ${obj.contractPricing.price_per_ton}  Floor Price Option ($ ${floorObj.firmFloorPrice} per farmer stock ton, basis grade) 
                            or the existing Golden Peanut Company ${floorObj.areaName} farmer stock price (basis grade) not to exceed ${floorObj.basisMaxOption} Option Price Cap ($${floorObj.firmMaxOption} per farmer stock ton, basis grade.)`
            }
            else if(msPayRebate == 'DELIVERY' && msMaxOption == 0){
                floorData = `Seller/Producer ${floorObj.displayName.trim()} shall have the option to  firm price this contract at the existing Golden Peanut Company 
                            ${floorObj.areaName} farmer stock price for ${obj.peanutType} peanut, type any time after signing this contract and up to the date of the first 
                            delivery of any of these contracted peanuts. On  the date of the first delivery of any pounds, and contracted pounds not priced will be firm priced at the higher of the $
                            ${obj.contractPricing.price_per_ton} Option price ($ ${floorObj.firmFloorPrice} farmer stock price basis  grade) or the existing Golden Peanut Company ${floorObj.areaName}  farmer stock price (basis grade).`
            }
            else if(msPayRebate == 'DELIVERY' && msMaxOption != 0){
                floorData = `Seller/Producer ${floorObj.displayName.trim()} shall have the opportunity  to firm price this contract at the existing Golden Peanut Company 
                            ${floorObj.areaName} farmer stock price for ${obj.peanutType} peanut type,  but not to exceed ${floorObj.basisMaxOption} Option Price Cap ($ 
                            ${floorObj.firmMaxOption} per farmer stock ton, basis grade), any time after  signing this contract and up to the date of the first inspection of  these 
                            contracted peanuts. On the date of the first inspection of any pounds, any contracted pounds not priced will be firm priced at the higher of the $${obj.contractPricing.price_per_ton}  Floor Price Option ($ ${floorObj.firmFloorPrice}  per farmer stock ton, 
                            basis grade) or the existing Golden Peanut Company ${floorObj.areaName} farmer stock price (basis grade) not to exceed ${floorObj.basisMaxOption} Option Price Cap ($${floorObj.firmMaxOption} per farmer stock ton, basis grade).`
            }
        }
        
        var FMChemical = getData(thisObj, 'cmdForeignMatarial') == 'Y' ? true : false;
        var ChemicalArg =  getData(thisObj, 'cmdChemicalAddendum') == 'Y' ? true : false;
        var vendorDisc = getData(thisObj,'minorDisclosure')=='Completed'?true:false;
        var seedArg = getData(thisObj, 'seedArg') == 'Y' ? true : false;
        var comments = getData(thisObj, 'comments')

        var fMChemical = ''
        var vendorDisclosure = ''
        var chemArg = ''
        var seedpage3 = ''
        var seedpage4 = ''
        var seedpage5 = ''
        var commentsArg  = ''
        var pageNumber = 1
        var pageCount = 1
        
        if(FMChemical)
            pageCount++;
        if(seedArg)
            pageCount = pageCount + 3
        pageCount++;
        if(comments)
            pageCount++;
        if(!vendorDisc)
            pageCount++;
        if(ChemicalArg)
            pageCount++
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
        var paragraph = 'Rev. '
        if(Number(obj.cropYear) <= 2006){
            if(new Date(obj.addDateTime) > new Date('3/1/2005 11:59:00 PM'))
                paragraph += '01-31-05'
            else if(new Date(obj.addDateTime) > new Date('3/2/2005 12:00:00 AM') && Number(obj.cropYear) == 2004)
                paragraph += '12-10-03'
        }
        else{
            if(Number(obj.cropYear) >= 2009)
                paragraph += 'Jan. 2009'
            else if(Number(obj.cropYear) > 2006)
                paragraph += 'February. 2007'
            else 
                paragraph += 'April 2006'            
        }

        var optionPage = `<html>
        <style>
        body:before{
            content: '${environmentType}';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;  
            color: #0d745e;
            font-size: 250px;
            font-weight: 500px;
            display: grid;
            justify-content: center;
            align-content: center;
            opacity: 0.35;
        }
        @media print {
            @page :footer { display: none }    
            @page :header { display: none }    
            @page { margin-top: 0; margin-bottom: 0; }    
            html,
            body { height: 100%; margin: 0 !important; padding: 0 !important; }
        }     
        </style>
        ${pageGap} 
        <p style="page-break-after: always">
        <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
            <tr style="padding-left:1%; padding-right: 1%;">
                        <td style="padding-left:0.01%; padding-right: 1%;">                	
                        <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                        </td>
                        <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
                            <br/>
                            <span style="font-size:150%;color: black;">
                                <b> Option for Purchase <br /> Of Farmer Stock Peanuts  </b>
                            </span>
                        </td>
                        <td style="padding-left:10%;">
                            <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                            <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                        </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:0.01%;font-size: 110%;color:black;"> <b> Date: <u> ${obj.contractDate} </u> </b> </td>
                        <td style="padding-left:0.5%;text-align: center; padding-right: 0.5%;color:black;font-size:115%"> 
                            <b> (Acceptance by Golden Required) </b> <br/>
                            <b> CROP YEAR &nbsp; <u> ${obj.cropYear} </b> </u>
                        </td>
                        <td style="padding-left:18%;font-size: 110%;color:black;"> <b> No: ${obj.contractNumber} </b> </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:0.01%;color:black;" colspan="2"> Seller/Producer Name & Address: Vendor #  ${obj.vendorNumber} </td>
                        <td style="padding-left: 12%;color:black;"> Delivery Point &nbsp; :  ${obj.deliveryPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:0.01%;color:black;" colspan="2">  <u> ${obj.vendorName} </u>  &nbsp; ${obj.splitPct} </td>
                        <td style="padding-left: 12%;color:black;"> Collection Point &nbsp; :  ${obj.collectionPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:0.01;color:black;" colspan="2">  <u> ${obj.vendorAddress1} &nbsp;&nbsp; ${obj.vendorAddress2} &nbsp; </u> </td>
                        <td style="padding-left: 12%;color:black;"> Handler No. &nbsp; : ${obj.handlerNo} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:0.01%;color:black;" colspan="2">  <u> ${obj.vendorAddress3} &nbsp; &nbsp; </u>  </td>
                        <td style="padding-left: 12%;color:black;"> U.S.W.A. License No. &nbsp; : ${obj.licenseNo}  </td> 
                    </tr>
                    <tr>
                        <td style="padding-right:5.75%;" colspan="3">
                            <hr style="width: 100%" /> <hr style="width: 100%" /> 
                        </td>
                    </tr>        
                    <tr> 
                        <td style="padding-left:0.01%;text-align: justify;color:black;font-size:105%">
                            <b> <u>PLANTING &nbsp; COMMITMENT:</u> </b>
                        </td>
                    </tr>
        <tr>
            <td style="padding-left:0.01%; padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Acres: Center Pivot Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.centerPivotIrr} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                Cable Tow Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.cableTowIrrigated == 0 ? '' : obj.cableTowIrrigated} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                Dryland <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.dryLand  == 0 ? '' : obj.dryLand} &nbsp;&nbsp;&nbsp;&nbsp; </u> &nbsp; &nbsp;
                Total Acres <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.total} &nbsp;&nbsp;&nbsp;&nbsp; </u> &nbsp; &nbsp;
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Type <u>&nbsp;&nbsp; ${obj.peanutType} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                Segregation: <u> &nbsp;&nbsp; ${obj.segregation} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                Certified Organic: &nbsp; ${obj.organic} &nbsp; &nbsp;
                <hr style="width: 100%" />
                <hr style="width: 100%" />
            </td>
        </tr>    
        <tr>
            <td style="padding-left:0.01%;padding-right: 12%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                LIENS, &nbsp; ASSIGNMENTS &nbsp; OR &nbsp; PRIOR &nbsp; INTERESTS &nbsp; : &nbsp; &nbsp; Seller certifies that there are no liens, crop insurance assignments or prior interests (such as, but not limited to, landlord's liens, landlord's crop share in the peanuts, landlord's crop share in money, FSA, security interests in favor of banks or other lenders, prior crop contracts, etc.) on the peanuts covered by this contract except as follows (use additional sheets if necessary):
            </td>
        </tr>
        <tr>
        <td style="padding-left:0.01%;padding-top: 0.2%;color:black;"> Name of Lienholder/Assignee </td>
        <td style="padding-left:3%;padding-top: 0.2%;color:black;"> Address  </td>
        <td style="padding-top: 0.2%;color:black;"> Nature of Liens, Assignments or Prior Interests: </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top: 0.2%;color:black;"> 1. ${obj.lineHolderName1} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderAddr1}  </td>
            <td style="padding-top: 0.2%;color:black;">  ${obj.lineHolderNature1} </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top: 0.2%;color:black;"> 2. ${obj.lineHolderName2} </td>
            <td style="padding-left:3%;padding-top: 0.2%;color:black;">  ${obj.lineHolderAddr2} </td>
            <td style="padding-top: 0.2%;color:black;">  ${obj.lineHolderNature2} </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top:0.05%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Seller is now the owner of, or warrants that he will plant not less than 90% of the committed acreage set out above and further warrants that he will produce in the United States during the applicable crop year, <u> &nbsp; &nbsp; &nbsp; &nbsp; ${obj.contractPounds} &nbsp; &nbsp; &nbsp; &nbsp; </u>lbs. of farmer stock peanuts, more particularly described above ("the Peanuts"). Upon acceptance by Golden, Seller is willing, for the consideration stated herein, to grant Golden such exclusive option to purchase the Peanuts. Golden has the right to reject if the peanuts contain any freeze damage. Should the Peanuts be stored by Golden prior to purchase, Golden will store the Peanuts in a USDA approved peanut storage facility at its published tariff rate.
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;padding-top:0.05%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                Now therefore and in consideration of (a) $ <u> ${signDetails.signing_advance} </u> paid on the execution hereof in cash or credit to Seller's account and (b) $ <u>${obj.price}</u> per ton, basis grade, to be paid when the peanuts are inspected, less the amount specified in paragraph (a) above, Seller does hereby grant to Golden the exclusive right to purchase the Peanuts on the terms set out below. Should the USDA Loan Differential be set at a rate other than $355.00 for the applicable type, the amount of the option payment shall be adjusted in an equal and inverse amount
            </td>
        </tr>
        <tr>
            <td style="padding-top:0.05%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                <ol start="1">
                    <li style="color:black;">
                        The purchase price of the Peanuts in the event Golden exercises its option to purchase shall be the USDA loan repayment rate, basis grade, for the Peanut type in effect on the date Golden exercises this option. Basis grade shall be determined using the official USDA Price Table File for the above-referenced crop year and Type.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Golden may exercise this Option by (i) giving written notice of its exercise to Seller either in person or by U.S. Mail and addressed to Seller at the address set out above, or (ii) at Seller's discretion, Seller will permit Golden to make repayments and redemptions on Seller's behalf; if Seller does so, then by repaying Seller's Marketing Assistance Loan (MAL) and receiving transfer of the applicable warehouse receipt. If exercise is under (i), the date Golden exercises this option shall be deemed to be the date of the notice if in person, or otherwise on the date mailed. If exercise is under (ii), the exercise shall be effective upon Golden's repaying the MAL on Seller's behalf.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Upon notice of exercise, Seller shall immediately transfer and deliver to Golden the original Warehouse Receipt and possession of the Peanuts. The sale shall be FOB the Storage Location of the Peanuts unless Golden exercises prior to storage, in which case the sale shall be FOB the Delivery Point.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        (a). Golden may exercise this option at any time during a period beginning on the date hereof and ending on the date of loan forfeiture if there is a marketing loan, or if not a marketing loan, the date of forfeiture had there been a marketing loan taken on the last day of eligibility. <br />
                        (b). Notwithstanding any other provision of this option to purchase or any other contract, title and control of the commodity and beneficial interest in the commodity, as specified in 7CFR 1421.6, shall remain with the Producer until the Buyer exercises this option to purchase the commodity. This option to purchase shall expire, notwithstanding any action or inaction by either the Producer or the Buyer, at the earlier of: (1) the maturity of any Commodity Credit Corporation (CCC) loan that is secured by such commodity; (2) the date CCC claims title to such commodity; or (3) such other date as provided in this option;
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller warrants that the Peanuts sold hereunder shall, at the time this document is executed and hereafter, be free and clear of all indebtedness, liens, security interests and encumbrances unless otherwise noted herein and have not been nor will be contracted or optioned for sale to any other entity.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller has full right and title to the Peanuts and agrees to indemnify and hold Golden harmless from and against any claims and encumbrances or adverse claims to the Peanuts that might be asserted by any other party.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        If Seller has previously contracted peanuts with Golden, the order of delivery shall be in the chronological order of the date of the contracts.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        If the Peanuts should be covered by any prior agreement between the parties hereto, such prior agreement is hereby amended to specifically exclude the Peanuts.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        The rights and obligations of Seller may not be assigned without the consent of Golden, such consent to not be unreasonably withheld. Golden may assign without the consent of Seller any or all of its obligations hereunder.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller warrants the peanuts covered hereby to be fit for human consumption and not in violation of any provisions of the Federal Food, Drug and Cosmetic Act, Environmental Protection Agency Regulations and the Federal Insecticide, Fungicide and Rodenticide Act and that no daminozides, alachlor, botran, monosodium methanearsonate (MSMA) or any pesticides not specifically labeled for peanuts have been used. Seller agrees to indemnify and hold Golden, its employees, agents, officers and directors harmless from all loss or liability, including attorney's fees incurred by Golden as a result of Seller's breach of such warranties.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Seller and Golden agree that they have executed and delivered this Option in contemplation of the limited source or market of the Peanuts. Seller acknowledges that Golden has entered or will enter into commitments with other persons in reliance upon Seller's performance hereunder. In recognition of the foregoing and the unique characteristics and source of peanuts, Seller agrees that Golden shall be entitled to, in addition to any other remedy at law or in equity which Golden may have under this Option or otherwise, the remedy of specific performance of Seller's obligations under this Option, and breach or threatened breach of this Option shall entitle Golden to a temporary or permanent restraining order or injunction. Further, Seller, his successors, heirs and permitted assigns, waive any claim or defense that there is an adequate remedy at law for such breach or threatened breach.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        Any controversy, dispute or claim arising out of or relating to this contract or the breach of this contract, including any controversy, dispute or claim that arises out of any instrument or document related to this contract, and including any claim based on an alleged tort, shall be resolved by binding arbitration administered by the American Arbitration Association under its commercial arbitration rules, and judgement on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Any party to this contract may bring an action, including a summary or expedited proceeding, to compel arbitration of any controversy or claim to which these rules apply in any court having jurisdiction over such action. The arbitration shall be conducted by the Atlanta, Georgia, U.S.A. office of the American Arbitration Association.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        THIS AGREEMENT SHALL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE STATE OF  GEORGIA.
                    </li>
                    <li style="padding-top:0.05%;color:black;">
                        This Agreement is effective upon acceptance by Golden, as evidenced by signature of an authorized employee of Golden. BUYING POINT PERSONNEL ARE NOT AUTHORIZED EMPLOYEES.
                    </li>
                </ol>    
            </td>
        </tr>
        <tr>
            <td style="padding-left:0.01%;color:black;" colspan="2">
            ${pageGap}
                <span style="font-size: 150%;"> <b> GOLDEN PEANUT COMPANY, LLC </b> </span> <br/> By, Name and Title
            </td>            
        </tr> 
        <tr>
            <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
            </td>
        </tr>
        <tr>
            <td style="align-items: center;padding-left: 42%;color:black;" colspan="2">
                <br/> <br/> <br/> <br/> Page  ${pageNumber}  of  ${pageCount}
            </td>
            <td style="align-items: center;padding-left: 27%;color:black;" colspan="2">
                <br/> <br/> ${paragraph}
            </td>
        </tr> 
    </table>
        </p>`
        
        if(FMChemical){
            pageNumber++;
            fMChemical = getFMChemicalData(fontSize, obj, dict, pageNumber, pageCount)
        }
        if(comments){
            pageNumber++;
            commentsArg = getContractComments(fontSize, tableWidth, pageGap, obj, pageNumber, pageCount)
        }
        if(seedArg){
            pageNumber++;
            var paragraph1 = 'Rev. '
            if(Number(obj.cropYear) <= 2008){
                if(Number(obj.cropYear) > 2005)
                    paragraph1 += 'April 14 2005'
                else if(Number(obj.cropYear) == 2003)
                    paragraph += 'Feb 1, 2003'
                else if(Number(obj.cropYear) == 2004)
                    paragraph1 += 'May, 2003'
                else
                    paragraph1 += 'April 2005'                
            }
            else
                paragraph1 += 'Feb 2009'
            
            seedpage3 = `<p style="page-break-after: always">            
            <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
                <tr style="padding-left:1%; padding-right: 1%;">
                    <td>                	
                        <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                    </td>
                    <td style="padding-left:0.5%;text-align: center; padding-right: 0.1%;color:black;font-size:110%;">
                        <br/> <br/> <b> SEED &nbsp; CREDIT &nbsp; AGREEMENT </b>
                    </td>
                    <td style="padding-left:8%;">
                        <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                        <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                    </td>
                </tr>     
                <tr>
                    <td style="padding-left:0.01%;padding-top:5%;color:black;font-size:120%;" colspan="3">
                        Crop Year : <u> ${obj.cropYear} </u> &nbsp; &nbsp; Buying Point : <u> &nbsp; &nbsp; &nbsp; ${obj.buyingPoint} &nbsp; &nbsp; &nbsp; </u>
                        Loc. Nummber : <u> ${obj.deliveryPoint} </u>  &nbsp; &nbsp; Vendor No. : <u> &nbsp; &nbsp; &nbsp;  ${obj.vendorNumber} &nbsp; &nbsp; &nbsp; </u>
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01;padding-top:2%;color:black;text-align:justify;font-size:120%;" colspan="3">
                        This Agreement is entered into between Golden Peanut Company, LLC (Golden) and <u> &nbsp; &nbsp; &nbsp; <u> ${obj.vendorName} </u>   &nbsp; &nbsp; &nbsp; </u> _________________________ (Producer).
                    </td>
                </tr>    
                <tr>
                    <td style="padding-left:0.01%;padding-top:1%;color:black;font-size:120%;" colspan="3">
                        Date : <u> ${obj.contractDate} </u>
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top:1%;padding-right:7.5%;color:black;font-size:120%;" colspan="3">
                        <ol start="1">
                            <li style="padding-top:1%;text-align: justify;">
                                Subject to Producer's credit being approved by Golden, Golden agrees to furnish treated peanut planting seed to Producer at planting time which shall be used to plant the acres in which Golden may or may not have a contractual interest. Producer agrees to pay Golden for such planting seed at harvest time. The value of the peanut planting seed that Golden agrees to furnish to Producer on credit shall be an amount up to $ ___________ (the Debt)
                                <br/> <br/>
                                To settle Producer's Debt to Golden, Golden will receive and keep te agreed amount of money from Producer's first deliveries of peanuts, whether the first peanuts delivered by Producer are contracted or uncontracted , regardless of farm number, or will be paid from option payments or from marketing loan proceeds. Producer warrants that any peanut so
                                delivered shall be or  become free  and clear of all liens and encumbrances, but in any event. Producer agrees to indemnify and hold Golden harmlessnfrom and against any and all damages, claims or judgements suffered by Golden if Producer delivers encumbranced peanuts. If Producer is unable, due to crop failure, to deliver a sufficient quantity of peanuts to satisfy
                                his Debt from current crop year peanuts, payments for such seed is duenot later tahn December 31 of current crop year, in cash. Accounts not paid by December 31 pf the current year will be considered delinquent and will begin to accureinterest at the rate of 18% per annum until paid.
                            </li>
                            <li style="padding-top:1%;text-align: justify;">
                                Producer shall and hereby does grant Golden a security interest in Producer's peanut crop for the Crop Year specified herein in all counties and states produced,  and all proceeds therefrom, including but not limited to sale of crop, proceeds from any and all crop insurance payments and any and all government program payments.
                            </li>
                            <li style="padding-top:1%;text-align: justify;">
                                Producer hereby grants Golden authorization to obtain credit information on Producer now and in the future, including consumer credit reports, in conjunction with Golden's extension of credit to Producer.
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-right:5%;padding-top:1%;color:black;font-size:120%;" colspan="3">
                        <b> LIENHOLDER &nbsp; AGREEMENT </b>
                        The undersigned Lienholder agrees to release up to the dollar-amount amount specified in Paragraph 1 above to Golden from Producer’s first crop proceeds or allow Golden to offset from money due to Producer from Golden in order to satisfy Producer’s Debt with Golden. 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left: 40%;padding-top:5%;color:black;font-size:120%;" colspan="3">
                        <b> Name of Lienholder : </b> ________________________________________________________<br/> <br/> <br/>
                    </td>
                </tr>
                <tr>
                    <td style="padding-left: 50%;color:black;font-size:120%;" colspan="3">
                        By : _______________________________________________________ <br/> <br/> <br/>
                        Title : ______________________________________________________ <br/> <br/> <br/>
                        Dated : ______________________________________________________ <br/> <br/> <br/>
                    </td>
                </tr>
            <tr>
                <td style="align-items: center;padding-left: 42%;color:black;" colspan="2">
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                    <br/> <br/> Page  ${pageNumber} of  ${pageCount} <br/> <br/> <br/> <br/> <br/> 
                </td>  
                <td style="align-items: center;padding-left: 27%;color:black;" colspan="2">
                      <br/> <br/> ${paragraph1}
                </td>          
            </tr>        
        </table>
            </p>`

            pageNumber++;
            seedpage4 = `<p style="page-break-after: always">
            <table style="align-items: center;width: 80%;font-size:80%;">
                <tr style="padding-left:1%; padding-right: 1%;">
                    <td>                	
                        <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                    </td>
                    <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;color:black;">
                        <b> Contract &nbsp; for &nbsp; Purchase 
                            <br/> And &nbsp;Sale  &nbsp; of &nbsp; Farmer &nbsp; Stock &nbsp; Peanuts <br/>
                        </b>
                        (Including &nbsp; First &nbsp; Refusal &nbsp; Option)
                    </td>
                    <td style="padding-left:7%;">
                        <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                        <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                    </td>
                </tr>     
                <tr>
                    <td style="padding-top:2%;color:black;text-align: center;font-size:125%;" colspan="3">
                        <u> CROP &nbsp; YEAR :  ${obj.cropYear} </u> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top:5%;padding-right:5%;color:black;text-align:justify;font-size:125%;" colspan="3">
                        This Agreement amends by additional provisions herein that certain ${obj.cropYear} Crop Year Contract for Purchase and Sale of
                        Famerstock Peanuts (including First Refusal Option) (the "Contract ") by and between Golden Peanut Company, LLC ("Golden "), and   
                        <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u> ${obj.vendorName} </u>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>( "Producer" ), 
                        dated <u> &nbsp;&nbsp; ${GetTodayDate(obj.contractDate)} &nbsp; &nbsp; </u>   and numbered <u> &nbsp; &nbsp; ${obj.contractNumber} &nbsp; &nbsp; </u>
                    </td>
                </tr>    
                <tr>
                    <td style="padding-left:0.01%;padding-top:10%;padding-right:5%;color:black;text-align: justify;font-size:125%;" colspan="3">
                        Upon presentation of sufficient evidence of application of landplaster, or a substitute with acceptable calcium content approved by Golden, at a quantity approved by Golden, to the Virginia-Type peanut acres planted under this contract, Golden agrees to pay Producer $
                        <u> &nbsp; &nbsp;  0.00 &nbsp; &nbsp; </u> per net farmer stock ton contracted and delivered. Golden will make payment separately upon completion of deliver on this contract.
                    </td>
                </tr>    
                <tr>
                    <td style="padding-left:2.5%;padding-top:5%;font-size:125%;">
                        <b> Golden Peanut Company, LLC </b>
                    </td>
                    <td style="padding-left:17.5%;padding-top:5%;font-size:125%;">
                        <b> Producer </b>
                    </td>
                </tr>
    
                <tr>
                    <td style="padding-left:2.5%;padding-top:1%;font-size:125%;">
                        By : ____________________________________                   
                    </td>
                    <td style="padding-left:17.5%;padding-top:1%;font-size:125%;" colspan="2">
                        By : ____________________________________  
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:2.5%;padding-top:2%;font-size:125%;">
                        Dated : _________________________________
                    </td>
                    <td style="padding-left:17.5%;padding-top:2%;font-size:125%;" colspan="2">
                        Dated : _________________________________
                    </td>
                </tr>
                <tr>
                    <td style="padding-left: 52.5%;padding-top:2%;color:black;;font-size:125%" colspan="3">
                        OWNER &nbsp; / &nbsp; OPERATOR <br/> <br/>
                        By : ______________________________________________________ <br/> <br/> <br/>
                        By : ______________________________________________________ <br/> <br/> <br/>
                        By : ______________________________________________________ <br/> <br/> <br/>
                    </td>
                </tr>
            <tr>
                <td style="align-items: center;padding-left: 45%;color:black;" colspan="3">
                    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> Page  ${pageNumber} of  ${pageCount}
                </td>            
            </tr>        
        </table>
            </p>`
            
            var paragraph3 = ''
            if(obj.peanutType == 'Runner'){
                if(Number(obj.cropYear) > 2008)
                    paragraph3 = '#1 Rev. Jan 2009'
                else
                    paragraph3 = '#1 Rev. April 2006'                
            } else
                if(Number(obj.cropYear) > 2008)
                    paragraph3 = '#2 Rev. Jan 2009'
                else
                    paragraph3 = '#2 Rev. April 2006'
                
            pageNumber++;
            seedpage5 = `        <p style="page-break-after: always">
            <table style="align-items: center;width: 80%;font-size:80%;">
                <tr style="padding-left:1%; padding-right: 1%;">
                            <td style="padding-left:2.5%; padding-right: 1%;">                	
                                <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                            </td>
                            <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
                                <br/>
                                <span style="font-size:150%;color: black;">
                                    <b> Seed Option Addendum  </b>
                                </span>
                            </td>
                            <td style="padding-left:0.05%;">
                                <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                                <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                            </td>
                        </tr>        
                        <tr>
                            <td style="padding-left:2.5%;font-size: 110%;color:black;"> </td>
                            <td style="padding-left:2%;text-align: center; padding-right: 2%;color:black;font-size:125%"> 
                                <b> CROP YEAR &nbsp; : <u> ${obj.cropYear} </b> </u>
                            </td>
                            <td style="padding-left:18%;font-size: 110%;color:black;"> <b> No: <u> ${obj.contractNumber} </u> </b>  </td>
                        </tr>        
                        <tr>
                            <td style="padding-left:2.5%;color:black;" colspan="2"> </td>
                            <td style="padding-left: 10%;color:black;"> Buying Point &nbsp; :  ${obj.deliveryPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </td> 
                        </tr>
                        <tr>
                            <td style="padding-left:2.5%;color:black;" colspan="2"> </td>
                            <td style="padding-left: 10%;color:black;"> Collection Point &nbsp; :  ${obj.collectionPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </td> 
                        </tr>
                        <tr> 
                            <td style="padding-left:2.5%;text-align: justify;color:black;font-size:100%;" colspan="3">
                                This Addendum is made to Option for Purchase of Farmer Stock Peanuts (Acceptance by Golden Required) by and between Golden Peanut Company, LLC (Golden) and
                                <u>  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ${obj.vendorName} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </u>
                                (Producer) dated <u> ${GetTodayDate(obj.contractDate)} </u> and numbered <u> &nbsp; &nbsp; ${obj.contractNumber} &nbsp; &nbsp;</u>
                                and is entered into for the production by Producer of a particular variety or varieties of Seed peanuts (hereinafter the "Seed")    
                            </td>
                        </tr>                        
            <tr>
                <td style="padding-top:0.7%;padding-left:0.01%;padding-right:5%;text-align: justify;color:black;" colspan="3">
                    <ol start="1">
                        <li style="color:black;">
                            <b><u>  ACREAGE/POUNDAGE/PREMIUM </u></b> &nbsp; - &nbsp; Producer agrees to produce Seed as follows:
                            <br/>
                            Variety &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            Generation <br/> _______________________________________________________________________________________________________________ <br/> <br/>
                            Producer hereby grants Golden an option to utilize up to <u> &nbsp; &nbsp; 0 &nbsp; &nbsp; </u> 
                            lbs. of peanuts as Seed meeting the specifications hereunder. The consideration for this option shall be $ 0.00 
                            per net weight ton, less loose kernels (LSK), to be paid at inspection. Producer agrees that the production will meet the certification requirements of the applicable seed certifying
                            agency or authority (Seed Agency) having responsibility for the certification of Seed grown by Producer.                    
                        </li>
                        <li style="padding-top:0.25%;color:black;">
                            <b><u> PLANTING &nbsp; PRATICES </u></b> - Producer agreess to utilize his best efforts to prduce a high yield of quality seeds solely of the designated variety(s) which will conform to the specification herein adn be suitable for seed purposes. Producer shall notify Golden if his propose time and methods of planting and cultivation differ significantly from the normal planting practises in the area of the production. Producer shall permit and cooperate in the inspection of the seed fields as required by Golden and the State Certification Agency and shall implement the recommendations of Golden and the State Certification Agency for the production of seed peanuts grown hereunder. If Producer fails to comply with the rules and regulations for certifying peanut seed prescribed by the Seed Agency or commits any act, or fails to commit any act, which prevents the peanuts from being certified, Golden shall be released from its obligations under this agreement. If located in the Virginia/Carolinas or Southeast area, seed Producer will apply calcium sulfate regardless of soil test to seed production fields at state extension service recommended rates.        
                        </li>
                        <li style="padding-top:0.25%;color:black;">
                         <b><u> CLEANING &nbsp; AND &nbsp; CURING </u></b> -
                         All peanuts, regardless of condition, produced pursuant to this agreement shall be delivered at harvest by Producer at Producer's expense to Golden to the specified delivery point or an alternative delivery point within a reasonable distance of the specified delivery point, as Golden may designate, for the purpose of cleaning and/or mechanical curing at Golden's standard rates. With Golden's prior written approval, Producer may have seed peanuts cured on farm curing equipment which has been approved and will be supervised by Golden.    
                        </li>
                        <li style="padding-top:0.25%;color:black;">
                            <b><u> SPECIFICATION </u></b> <br/>
                            (a) The peanuts must meet the following minimum specifications, on the first grade, to qualify for the seed premium:
                            <b>
                            <ol start="1">
                                <li style="padding-top:0.2%;color:black;"> Specifications of the applicable Seed Agency. </li>
                                <li style="padding-top:0.15%;color:black;"> Combining moisture level between 16% and 20% moisture and cured at a maximum temperature rise of 15°F above ambient temperature and not to exceed 95°F, except that in the Southwest area, solar trailers and bags shall not exceed 18% moisture at combining and shall show no evidence of molded hulls and/or caking. </li>
                                <li style="padding-top:0.15%;color:black;"> Moisture content of no less than 8% and no more than 10.49%, unless otherwise approved by Golden. </li>
                                <li style="padding-top:0.15%;color:black;"> Maximum of 4% sound splits. </li>
                                <li style="padding-top:0.15%;color:black;"> Maximum of 6% loose shelled kernels. </li>
                                <li style="padding-top:0.15%;color:black;"> Minimum of 68% sound mature kernels. </li>
                                <li style="padding-top:0.15%;color:black;"> Maximum of 6% foreign material. </li>
                                <li style="padding-top:0.15%;color:black;"> No high moisture foreign material as determined by official inspection. </li>
                                <li style="padding-top:0.15%;color:black;"> No Freeze Damage or pitting.  </li>
                                <li style="padding-top:0.15%;color:black;"> Golden reserves the right to reject seed fields infected with the scale rotinia or CBR. </li>
                            </ol>
                            </b>
                            (b) For the purpose of certification, Golden shall be considered the producer of seed produced hereunder, and Golden agrees to pay all certification costs. <br/>
                            (c) Producer agrees to allow Golden representatives to inspect fields and/or handling/curing equipment for compliance with the above specifications, and that such determination shall be final. 
                            <br/>
                        </li>
                        <li style="padding-top:0.25%;color:black;">
                            Notwithstanding any other provision of this option to purchase or any other contract, title and control of the commodity and beneficial interest in the commodity as specified in 
                            7 CFR 1421.6, shall remain with the Producer until the buyer exercises this option to purchase the commodity. This option to purchase shall expire, notwithstanding any action or inaction
                            by either the Producer or the buyer, at the earlier of:
                            <ol start="1">
                                <li>The maturity of any Commodity Credit Corporation (CCC) loan that is secured by such commodity;</li>
                                <li>The date CCC claims title to such commodity; or </li>
                                <li>Such other date as provided in this option.</li>
                            </ol>    
                        </li>    
                        <li style="padding-top:0.25%;color:black;">
                            <b> <u> REPRESENTATIONS AND WARRANTIES </u> </b>  
                            Producer warrants that the Seed produced and delivered by Producer to Golden hereunder shall not be encumbered by any claim, lien or
                            indebtedness. If Producer plants on farm land other than his own, or produces the Seed in partnership, joint venture, or otherwise in cooperation with any other person, Producer shall
                            indemnify and save Golden harmless from any and all claims to payments for Seed produced hereunder by any third party. Producer expressly agrees to indemnify and save Golden
                            harmless from any and all losses or damage occasioned to Golden as a result of Producer's breach of the aforesaid warranties.    
                        </li>   
                        <li style="padding-top:0.25%;color:black;">
                            The delivery and storage location of the Seed will be designated by Golden.
                        </li>                 
                    </ol>    
                </td>
            </tr>        
            <tr>
                <td style="padding-left:2.5%;text-align: justify;color:black;font-size:100%;" colspan="2">
                    <span style="font-size: 120%;">
                        <b> Golden Peanut Company, LLC  </b>
                    </span>
                    <br/> <br/> By, Name and Title
                </td>
            </tr>
            <tr>
                <td style="padding-top:5%;padding-left:55%;text-align: justify;color:black;font-size:100%;" colspan="3">
                    <b> Producer  &nbsp; __________________________________________________  </b>
                </td>
            </tr>
            <tr>
                <td style="align-items: center;padding-left: 42%;color:black;" colspan="2">
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                    <br/> <br/> Page  ${pageNumber} of  ${pageCount}
                </td> 
                <td style="align-items: center;padding-left: 27%;color:black;" colspan="2">
                    <br/> <br/> ${paragraph3}
                </td>        
            </tr>        
        </table>
            </p>`
        }
        pageNumber++;
        var floorMarketPage = `<p style="page-break-after: always">
        <br /> 
      <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
          <tr style="padding-left:1%; padding-right: 1%;">
              <td style="padding-left:2.5%; padding-right: 1%;">                	
                  <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
              </td>
              <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
              </td>
              <td style="padding-left:8%;">
                  <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                  <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                  <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                  <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
              </td>
          </tr>   
          <tr>
              <td style="padding-right: 5.75%;" colspan="3">
                  <hr style="width:100%" />
              </td>
          </tr>  
          <tr>
              <td style="text-align: center;align-items: center;" colspan="3">
                  <span style="font-size:170%;color:black;text-align: center;">
                      <br/> <br/> <b> FLOOR &nbsp; PRICE &nbsp; CONTRACT &nbsp; ATTACHMENT </b> <br /> <br/>
                      <br/><br/><br/><br/>
                  </span>
              </td>
          </tr>    
          <tr>
              <td style="padding-left:0.01%;font-size:125%;color:black;"> <b> Date: <u> ${obj.contractDate} </u> </b> </td>
              <td style="text-align: center; padding-right: 4%;color:black;font-size:125%">
                  <b> CROP YEAR &nbsp; : <u> ${obj.cropYear} </b> </u>
              </td>
              <td style="padding-left:18%;font-size: 125%;color:black;"> <b> No: </b> <u> ${obj.contractNumber} </u> </td>
          </tr>          
          <tr>
              <td style="padding-left:0.01%;padding-right:5%;font-size:150%;text-align: justify;color:black;" colspan="3">
                <br/><br/><br/><br/> <br/><br/><br/><br/> <br/><br/><br/><br/> 
                ${floorData} <br/>
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>  <br/> <br/> <br/> <br/> <br/><br/><br/><br/>
              </td>
          </tr>
          <tr>
              <td style="padding-left:2.5%;color:black;" colspan="2">
                  ${pageGap}${pageGap} ${pageGap}
                  <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC </b> </span> <br/> By, Name and Title
              </td>
              <td style="padding-left:5%;padding-right:10%;padding-top:2%;color:black;" colspan="2">
                  <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
              </td>
          </tr> 
          <tr>
              <td style="align-items: center;padding-left: 45%;color:black;" colspan="3">
                  <br/> <br/> Page  ${pageNumber} of  ${pageCount}
              </td>            
          </tr>  
      </table>
              </p>`
        if(!vendorDisc){
            pageNumber++;
            vendorDisclosure = getVendorDisclosureData(fontSize, tableWidth, obj, pageNumber, pageCount)
        }
        if(ChemicalArg){
            pageNumber++;
            chemArg = getChemicalArgData(fontSize, tableWidth, obj, dict, pageNumber, pageCount)
        }        
    return optionPage + fMChemical + commentsArg + seedpage3 + seedpage4 + seedpage5 + floorMarketPage + vendorDisclosure + chemArg + `</html>`
    }

    async function getSeedContract(fontSize, tableWidth, pageGap, obj){
        var dict = { 'January' : '01', 'February' : '02', 'March' : '03', 'April': '04', 'May' : '05', 'June': '06', 'July': '07', 'August' : '08', 'September':'09','October':'10', 'November':'11', 'December': '12',1 : 'st', 2 : 'nd', 3: 'rd', 4 : 'th', 5 : 'th', 6 : 'th', 7 : 'th', 8 : 'th', 9 : 'th', 10 : 'th', 11 : 'th', 12 : 'th', 13 : 'th', 14 : 'th', 15 : 'th', 16 : 'th', 17 : 'th', 18 : 'th', 19 : 'th', 20 : 'th', 21:'st', 22:'nd',23: 'rd', 24 : 'th', 25 : 'th', 26 : 'th', 27 : 'th', 28 : 'th', 29 : 'th', 30 : 'th', 31:'st'} 
        
        var ChemicalArg =  getData(thisObj, 'cmdChemicalAddendum') == 'Y' ? true : false;
        var vendorDisc = getData(thisObj,'minorDisclosure')=='Completed'?true:false;
        var comments = getData(thisObj, 'comments')
        var seedGrowerData = ''
        var vendorDisclosure = ''
        var commentsArg = ''
        var chemArg = ''
        var pageNumber = 1
        var pageCount = 1
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
        if(!vendorDisc)
            pageCount++;
        if(comments)
            pageCount++;
        if(ChemicalArg)
            pageCount++
        if(addendumSeedGrowers.length == 0){
            addendumSeedGrowers.push({ 
                "pnut_variety_name" : 'To be determined',
                "gen_produced_name" : ''
            })
        }        
        var symbolDict = {
            "TRADEMARK" : `&reg;`,
            "REGISTERED TRADEMARK" : `&trade;`,
            "COPYRIGHT" : `&copy;`,
            "" : ""
        }
        
        for(var i=0;i<addendumSeedGrowers.length; i++){
            var p = "<u>" + addendumSeedGrowers[i].pnut_variety_name + symbolDict[addendumSeedGrowers[i].symbol_ind] + " (" + addendumSeedGrowers[i].gen_produced_name + ")" 
            for(var k=p.length; k<36; k++)
                p += '_'
            p += '</u>'
            if(i%3 == 0)
                seedGrowerData += `<tr> <td style="padding-left:3.75%;padding-top: 0.2%;color:black;"> ${p} </td>`
            if(i%3 == 1)
                seedGrowerData += `<td style="padding-left:6.5%;padding-top: 0.2%;color:black;"> ${p} </td>`
            if(i%3 == 2)
                seedGrowerData += `<td style="padding-left:10.5%;padding-top: 0.2%;color:black;"> ${p} </td> </tr>`            
        }
        for(var j = i; j<12; j++){
            var p = '<u>'
            for(var k=p.length; k<35; k++) 
                p += '_'
            p += '</u>'
            if(j%3 == 0)
                seedGrowerData += `<tr> <td style="padding-left:3.75%;padding-top: 0.2%;color:black;"> ${p} </td>`
            if(j%3 == 1)
                seedGrowerData += `<td style="padding-left:6.5%;padding-top: 0.2%;color:black;"> ${p} </td>`
            if(j%3 == 2)
                seedGrowerData += `<td style="padding-left:10.5%;padding-top: 0.2%;color:black;"> ${p} </td> </tr>`            
        }
        var paragraph = obj.peanutType == 'Runner' ? '#1 Rev. Apr 2009' : '#2 Rev. Apr 2009'
        
        var seedPage = `<html>
        <style>
        body:before{
            content: '${environmentType}';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;  
            color: #0d745e;
            font-size: 250px;
            font-weight: 500px;
            display: grid;
            justify-content: center;
            align-content: center;
            opacity: 0.35;
        }
        @media print {
            @page :footer { display: none }    
            @page :header { display: none }    
            @page { margin-top: 0; margin-bottom: 0; }    
            html,
            body { height: 100%; margin: 0 !important; padding: 0 !important; }
        }     
        </style>
        ${pageGap} 
        <p style="page-break-after: always">
        <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
            <tr style="padding-left:1%; padding-right: 1%;">
                        <td style="padding-left:2.5%; padding-right: 1%;">                	
                            <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                        </td>
                        <td style="padding-left:8%;">
                            <br/>
                            <span style="font-size:150%;text-align:center;color:black;">
                                <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Seed Producer  <br/> 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Agreement  </b>
                            </span>
                        </td>
                        <td style="padding-left:5%;">
                            <span style="padding-left:8%;text-align-last: left;color:black;font-size: 130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                            <span style="padding-left:15%;text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/HANDLER) </b> <br/></span>
                            <span style="padding-left:12%;text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                            <span style="padding-left:18%;text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                        </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:2.5%;font-size: 110%;color:black;"> <b> Date: <u> &nbsp;&nbsp; ${dict[GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[0].trim()]}/${GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[1].trim()}/${GetTodayDate(obj.contractDate).split(',')[2].trim()} &nbsp;&nbsp; </u> </b> </td>
                        <td style="padding-left:10%;text-align:center;color:black;font-size:125%">  <b> CROP YEAR &nbsp; <u> ${obj.cropYear} </b> </u> </td>
                        <td style="padding-left:25%;font-size: 110%;color:black;"> <b> No: <u> &nbsp; ${obj.contractNumber} &nbsp; </u> </b> </td>
                    </tr>        
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2">  <b> Producer Name & Address: Vendor # <u> ${obj.vendorNumber} </u> </b> </td>
                        <td style="padding-left:13%;color:black;"> <b>  Delivery Point:  ${obj.deliveryPoint} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; </b> </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2">  <b> <u> ${obj.vendorName}</u>${obj.splitPct} </b> </td>
                        <td style="padding-left:13%;color:black;"> <b> Collection Point:  ${obj.collectionPoint} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; </b> </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2">  <b> <u> ${obj.vendorAddress1} &nbsp;&nbsp; ${obj.vendorAddress2} &nbsp; </u> </b> </td>
                        <td style="padding-left:13%;color:black;"> <b>Handler No.: ${obj.handlerNo} &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; </b> </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;color:black;" colspan="2">  <b> <u> ${obj.vendorAddress3} &nbsp; &nbsp; </u>  </b> </td>
                        <td style="padding-left:13%;color:black;"> <b> U.S.W.A. License No.: ${obj.licenseNo} </b>  </td> 
                    </tr>
                    <tr> 
                        <td style="padding-left:2.5%;text-align: justify;color:black;font-size:100%;" colspan="3">
                            <br/> This agreement between Golden Peanut Company, LLC (Golden) and Producer is entered into for the production by Producer of seed peanuts (hereinafter the 'seed') as follows:
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left:5.01%;padding-top: 0.2%;color:black;text-align:center;"> Variety(Generation)  </td>
                        <td style="padding-left:5.5%;padding-top: 0.2%;color:black;text-align:center;"> Variety(Generation)  </td>
                        <td style="padding-left:15.1%;padding-top: 0.2%;color:black;text-align:left;"> Variety(Generation)  </td>
                    </tr>
        ${seedGrowerData}
        <tr>
            <td style="padding-top:0.7%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                <ol start="1">
                    <li style="color:black;padding-right:0.01%;">
                        Producer agrees to produce and deliver to Golden <u> &nbsp; &nbsp; ${obj.contLbs} &nbsp; &nbsp; </u> pounds farmer stock Peanuts in the varieties specified above. Producer hereby grants to Golden the right of first refusal with respect to the seed as set out in Paragraph 6 below. The Considersation for this option shall be $ <u> &nbsp; ${obj.ratePerTon}  &nbsp; </u> per net weight ton, less loose shelled kernels (LSK), meeting the specification hereunder, such option to be paid at time of inspection. Producer agrees that the production will meet the certification requirements of the applicable seed certifying agency or authority(Seed Agency) having responsibility for the certification of seed grown by Producer.
                    </li>
                    <li style="padding-top:0.25%;color:black;padding-right:0.01%;">
                        <u> PLANTING PRACTICES </u> - Producer agress to utilize his best efforts to produce a high yield of quality seed solely of the designated variety(s) which will conform to the specifications herein and be suitable for seed purposes. Producer shall notify Golden if his proposed time and methods of planting and cultivation differ significantly from the normal planting practices in the area of the production. Producer shall permit and cooperate in the inspection of the seed fields as required by Golden and the State Certification Agency and shall implement the recommendations of Golden and the State Certification Agency for the production of seed peanuts grown hereunder. If Producer fails to comply with the rules and regulations for certifying peanut seed prescribed by the Seed Agency or commits any act, or fails to commit any act, which prevents the peanuts from being certified, Golden shall be released from its obligations under this agreement. If located in the Virginia/Carolinas or Southeast area, seed Producer will apply calcium sulfate regardless of soil test to seed production fields at state extension service recommended rates.        
                    </li>
                    <li style="padding-top:0.25%;color:black;padding-right:0.01%;">
                     <u> CLEANING AND CURING </u> -
                     All peanuts, regardless of condition, produced pursuant to this agreement shall be delivered at harvest by Producer at Producer's expense to Golden to the specified delivery point or an alternative delivery point within a reasonable distance of the specified delivery point, as Golden may designate, for the purpose of cleaning and/or mechanical curing at Golden's standard rates. With Golden's prior written approval, Producer may have seed peanuts cured on farm curing equipment which has been approved and will be supervised by Golden.    
                    </li>
                    <li style="padding-top:0.25%;color:black;padding-right:0.01%;">
                        <u> SPECIFICATIONS </u> <br/>
                        (a) The peanuts must meet the following minimum specifications, on the first grade, to qualify for the seed premium:
                        <b>
                        <ol start="1">
                            <li style="padding-top:0.2%;color:black;">  Specifications of the applicable Seed Agency. </li>
                            <li style="padding-top:0.15%;color:black;"> Combining moisture level between 16% and 20% moisture and cured at a maximum temperature rise of 15 &#176;F above ambient temperature and not to exceed 95 &#176;F, except that in the Southwest area, solar trailers and bags shall not exceed 18% moisture at combining and shall show no evidence of molded hulls and/or caking. </li>
                            <li style="padding-top:0.15%;color:black;"> Moisture content of no less than 8% and no more than 10.49%, unless otherwise approved by Golden.  </li>
                            <li style="padding-top:0.15%;color:black;"> Maximum of 4% sound splits. </li>
                            <li style="padding-top:0.15%;color:black;"> Maximum of 6% loose shelled kernels. </li>
                            <li style="padding-top:0.15%;color:black;"> Minimum of 68% sound mature kernels. </li>
                            <li style="padding-top:0.15%;color:black;"> Maximum of 6% foreign material. </li>
                            <li style="padding-top:0.15%;color:black;"> No high moisture foreign material as determined by official inspection. </li>
                            <li style="padding-top:0.15%;color:black;"> No Freeze Damage or pitting.  </li>
                            <li style="padding-top:0.15%;color:black;"> Golden reserves the right to reject seed fields infected with sclerotinia or CBR.  </li>
                        </ol>
                        </b>
                        (b) For the purpose of certification, Golden shall be considered the producer of seed produced hereunder, and Golden agrees to pay all certification costs. <br/>
                        (c) Producer agrees to allow Golden representatives to inspect fields and/or handling/curing equipment for compliance with the above specifications, and that such determination shall be final. 
                    </li>
                    <li style="padding-top:0.25%;color:black;padding-right:0.01%;">
                        <u> REPRESENTATIONS AND WARRANTIES </u>  -
                        Producer warrants that the seed produced and delivered by Producer to Golden hereunder shall not be encumbered by any claim, lien or indebtedness. If Producer plants on farm other than his own, or produces the seed in partnership, joint venture, or otherwise in cooperation with any other person, Producer shall indemnify and save Golden harmless from any and all claims to payments for seed produced hereunder by any third party. Producer expressly agrees to indemnify and save Golden harmless from any and all losses or damage occasioned to Golden as a result of Producer's breach of the aforesaid warranties. 
                    </li>
                    <li style="padding-top:0.25%;color:black;padding-right:0.01%;">
                        <u> RIGHT OF FIRST REFUSAL </u>  -
                        Producer hereby agrees to enter into an Option for Purchase or a contract for purchase of Seed specified herein, pursuant to the other terms of this paragraph. Should Producer receive a written offer from a bona fide peanut handler, as defined by USDA, Producer shall present a copy of such offer to Golden or its representative. Golden shall have three business days to meet the offer or Producer shall be free to take delivery of peanuts in Golden's warehouse per the terms of Producer's warehouse receipt(s) and Golden's Tariff for Storing and Handling Farmer Stock Peanuts 
                    </li>                    
                </ol>    
            </td>
        </tr>        
        <tr>
            <td style="padding-left:2.5%;text-align: justify;color:black;font-size:110%;" colspan="2">
                <b> Golden Peanut Company, LLC  <br/> <br/> By:  &nbsp; __________________________________________________ </b>
            </td>
            <td style="text-align: justify;color:black;font-size:110%;">
                <b> Producer <br/> <br/> By:  &nbsp; __________________________________________________ </b>
            </td>
        </tr>
        <tr>
            <td style="align-items: center;padding-left: 42%;color:black;" colspan="2">
            <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> 
                <br/> <br/> Page  ${pageNumber} of  ${pageCount}
            </td>  
            <td style="align-items: center;padding-left: 27%;color:black;" colspan="2">
                <br/> <br/> ${paragraph}
            </td>     
        </tr>        
    </table>
        </p>`
        if(comments){
            pageNumber++;
            commentsArg = getContractComments(fontSize, tableWidth, pageGap, obj, pageNumber, pageCount)
        }        
        if(!vendorDisc){
            pageNumber++;
            vendorDisclosure = getVendorDisclosureData(fontSize, tableWidth, obj, pageNumber, pageCount)
        }
        if(ChemicalArg){
            pageNumber++;
            chemArg = getChemicalArgData(fontSize, tableWidth, obj, dict, pageNumber, pageCount)
        } 
        return seedPage + commentsArg + vendorDisclosure + chemArg + `</html>`
    }

    function getNewContractData(fontSize, tableWidth, pageGap, obj, pageCount) {
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
        var paragraph = 'Rev. '
        if(Number(obj.cropYear) < 2006){
            if(new Date(obj.addDateTime) > new Date('3/1/2005 11:59:00 PM'))
                paragraph += 'Jan 31, 2005'
            else if(new Date(obj.addDateTime) > new Date('3/2/2005 12:00:00 AM') && Number(cropYear) == 2004)
                paragraph += 'Dec 11, 2003'
        }
        else{
            if(Number(obj.cropYear) > 2008)
                paragraph += ' May, 2008'
            else if(Number(obj.cropYear) > 2006)
                paragraph += ' February, 2007'
            else
                paragraph += ' April, 2006'
        }        
        return `<html>
    <style>
    body:before{
        content: '${environmentType}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 250px;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
    @media print {
      @page :footer { display: none }
      @page :header { display: none }
      @page { margin-top : 0; margin-bottom: 0; }
      html, body {
        height:100%; 
        margin: 0 !important; 
        padding: 0 !important;
      }      
    }    
    </style>
    ${pageGap} 
    <p style="page-break-after: always">
    <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
        <tr style="padding-left:1%; padding-right: 1%;">
                    <td style="padding-left:0.01%; padding-right: 1%;">                	
                        <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                    </td>
                    <td style="padding-left:4.1%;text-align: center; padding-right: 0.1%;">
                        <br/>
                        <span style="font-size:130%;color: black;">
                            <b> Contract for Purchase <br/> And Sale Of Farmer Stock Peanuts  </b>
                        </span>
                    </td>
                    <td style="padding-left:15%;">
                        <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                        <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <b> (GOLDEN) </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> <br/></span>
                    </td>
                </tr>        
                <tr>
                    <td style="padding-left:0.01%;font-size: 110%;color:black;"> <b> Date: <u> ${obj.contractDate} </u> </b> </td>
                    <td style="padding-left:2%;text-align: center; padding-right: 2%;color:black;font-size:125%"> 
                        <b> <u> CROP YEAR ${obj.cropYear} </b> </u>
                    </td>
                    <td style="padding-left:31%;font-size: 110%;color:black;"> <b> No: ${obj.contractNumber} </b> </td>
                </tr>        
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="2"> 
                        <b> Seller/Producer Name & Address: Vendor #  <u> ${obj.vendorNumber} </u>  </b> 
                    </td>
                    <td style="padding-left: 15%;color:black;"> <b> Delivery Point :  ${obj.deliveryPoint} </b>  </td> 
                </tr>
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="2"> 
                        <b> <u> ${obj.vendorName} </u>  &nbsp; ${obj.splitPct} </b> 
                    </td>
                    <td style="padding-left: 15%;color:black;"> <b> Collection Point :  ${obj.collectionPoint} </b>  </td> 
                </tr>
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="2"> 
                        <b> <u> ${obj.vendorAddress1} &nbsp;&nbsp; ${obj.vendorAddress2} &nbsp; </u> </b>
                    </td>
                    <td style="padding-left: 15%;color:black;"> <b>  Handler No. : ${obj.handlerNo} </b>  </td> 
                </tr>
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="2"> 
                        <b>  <u> ${obj.vendorAddress3} </u> &nbsp; &nbsp; </b> 
                    </td>
                    <td style="padding-left: 15%;color:black;"> <b>  U.S.W.A. License No. : ${obj.licenseNo} </b>  </td> 
                </tr>
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="4"> 
                        <b> S.S./Fed. I.D. # &nbsp; &nbsp; ___________________ </b> 
                    </td>                    
                </tr>
                <tr>
                    <td style="padding-right: 0.01%;" colspan="3">
                        <b> <hr style="width: 100%" /> <hr style="width: 100%" /> </b> 
                    </td>
                </tr>        
                <tr> 
                    <td style="padding-left:0.01%;text-align: justify;color:black;font-size:105%">
                        <b> <u>PLANTING &nbsp; COMMITMENT:</u> </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%; padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        <b> 
                        Acres: Center Pivot Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.centerPivotIrr}  &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                        Cable Tow Irrigated <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.cableTowIrrigated}  &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                        Dryland <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.dryLand} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                        Total <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.total} &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                        Average Yield per Acre: <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.avgYield}  &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp;
                        </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="3">
                        <b> Variety(s) <u> &nbsp;&nbsp;&nbsp;&nbsp; ${obj.variety}  &nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp; &nbsp; </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        <b> Seller understands that Golden is depending upon delivery of the peanuts contracted herein in order to fulfill its contract obligations. Seller warrants that <br/> Seller will <u>  plant not less than 90% of the committed acreage  </u> and further warrants that the peanuts will be produced in the United States. </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top: 0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        <b>     
                        Peanut Type: <u>&nbsp;&nbsp; ${obj.peanutType} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                        Segregation: <u>&nbsp;&nbsp; ${obj.segregation} &nbsp;&nbsp;</u> &nbsp;&nbsp;
                        Seed Grower: &nbsp; ${obj.seedGrower} &nbsp;
                        Seed Credit: &nbsp; ${obj.seedCredit} &nbsp;
                        High Oleic: &nbsp; ${obj.highOleic}  &nbsp;
                        Organic: &nbsp; ${obj.organic} &nbsp;              
                        </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top: 0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        <b> Seller agrees to sell and Golden agrees to buy on the terms set out in this contract, according to the USDA Price Table File for the above-referenced crop year and Peanut type, the quantity of U.S.-grown edible quality peanuts described below. Provided, however, if the peanuts contracted hereunder meet Golden's seed specifications and requirements, Golden may not use or sell the peanuts contracted hereunder for seed use without further agreement between the parties. </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top: 0.4%;color:black;" colspan="3">
                        <b> Purchase Period(s): &nbsp; <u> Unless otherwise specified herein, &nbsp; ${obj.purchasePeriod} &nbsp; </u> </b> 
                    </td>
                </tr>
                <tr>
                    <td style="padding-right: 0.01%;" colspan="3">
                        <b> <hr style="width: 100%" /> <hr style="width: 100%" /> </b>
                    </td>
                </tr>
                <tr>
                  <td style="padding-left:0.01%;padding-right: 12%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                    <b> <u> Price, &nbsp;  Basis &nbsp; Grade : </u> <br/>
                      1. <u> ${obj.payPrice} </u> &nbsp;  pounds @ $ <u> ${obj.rebateRate} </u> &nbsp;  per farmer stock ton.  <br/>
                      2. <u>  ${obj.contractPounds}  </u> &nbsp;  pounds @ $<u> ${obj.price} </u>  &nbsp; per farmer stock ton above the USDA Marketing Assistance Loan Repayment Rate in effect at the time of purchase. </b> 
                  </td>
                </tr>        
                <tr>
                    <td style="padding-right: 0.01%;" colspan="3">
                        <b>  <hr style="width: 100%" /> <hr style="width: 100%" /> </b>
                    </td>
                </tr>        
                <tr>
                    <td style="padding-left:0.01%;padding-right: 12%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        LIENS, &nbsp; ASSIGNMENTS &nbsp; OR &nbsp; PRIOR &nbsp; INTERESTS &nbsp; :  &nbsp; &nbsp; Seller certifies that there are no liens, crop insurance assignments or prior interests (such as, but not limited to, landlord's liens, landlord's crop share in the peanuts, landlord's crop share in money, FSA, PCA, security interests in favor of banks or other lenders, prior crop contracts, etc.) on the peanuts covered by this contract except as follows (use additional sheets if necessary): 
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top: 0.2%;color:black;"> Name of Lienholder/Assignee </td>
                    <td style="padding-left:3%;padding-top: 0.2%;color:black;"> Address  </td>
                    <td style="padding-top: 0.2%;color:black;"> Nature of Liens, Assignments or Prior Interests: </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top: 0.4%;color:black;"> 1.  ${obj.lineHolderName1} </td>
                    <td style="padding-left:3%;padding-top: 0.4%;color:black;"> ${obj.lineHolderAddr1}  </td>
                    <td style="padding-top: 0.4%;color:black;">  ${obj.lineHolderNature1} </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top: 0.4%;color:black;"> 2. ${obj.lineHolderName2}  </td>
                    <td style="padding-left:3%;padding-top: 0.4%;color:black;"> ${obj.lineHolderAddr2}  </td>
                    <td style="padding-top: 0.4%;color:black;">  ${obj.lineHolderNature2} </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top:0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        <u> Seller shall promptly notify Golden in writing of any liens, encumbrances or prior interests which may attach to these peanuts prior to payment. </u> Seller authorizes Golden to discharge any and all liens, penalties, charges, or encumbrances out of the payments due hereunder. If peanuts covered by this contract are subject to a crop share in peanut leases with landlord, Seller shall have such landlord join in and acknowledge this contract by signing below. Seller's execution of this contract without such an acknowledgement shall constitute a representation that the peanuts covered by this contract are not subject to a crop share.
                    </td>
                </tr>   
                <tr>
                    <td style="padding-left:0.01%;padding-top:0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        THE  TERMS  AND  CONDITIONS  APPEARING  ON  PAGE 2 ,  "TERMS  AND  CONDITIONS" , ARE  PART  OF  THIS  CONTRACT. This contract sets the terms and conditions under which Golden is willing to buy from Seller the peanuts designated herein. Seller recognizes that Golden, in reliance on this contract, has made binding commitments with respect to the quantity of peanuts herein contracted, and Seller agrees that it is obligated to deliver such quantity in compliance with the terms and conditions hereunder.
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;padding-top:0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        BOTH PARTIES &nbsp; here to have read and understand the terms and conditions stated on the face of this contract and on Page 2, "TERMS AND CONDITIONS", and agree to all of said terms and conditions. Both parties hereto intend this instrument to be a bona fide, binding and enforceable contract for the actual delivery of the peanuts herein provided.
                    </td>
                </tr>   
                <tr>
                    <td style="padding-left:0.01%;padding-top:0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        <u> SELLER &nbsp; ACKNOWLEDGES &nbsp; that use of daminozides, alachlor, botran, monosodium methanearsonate (MSMA) or any pesticides not specifically labeled for peanuts on the peanuts to be delivered pursuant to this Agreement constitutes a breach of this Agreement.</u>
                    </td>
                </tr>   
                <tr>
                    <td style="padding-left:0.01%;padding-top:0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        IN WITNESS WHEREOF the respective parties have signed these presents. By executing this agreement, Seller warrants and represents that it has full legal power and authority to deliver and sell for itself and any owner/operator all the peanuts covered by this Agreement and is the only person(s) sharing, as a Seller, in the proceeds from the sale of the peanuts covered by this Agreement.
                    </td>
                </tr>   
                <tr>
                    <td style="padding-left:0.01%;padding-top:0.4%;padding-right:0.01%;text-align: justify;color:black;" colspan="3">
                        Landlord/Operator: Sale to Golden Peanut Company, LLC of peanuts by this contract is hereby acknowledged, confirmed and approved, and undersigned landlord hereby waives any rights to assert or enforce against Golden any liens, crop shares or other rights or interests in any such peanuts.
                    </td>
                </tr>         
                <tr>
                    <td style="padding-left:0.01%;padding-top:7%;padding-right:20%;   color:black;" colspan="2">
                        <hr style="width: 100%" /> (Landlord) &nbsp; Operator
                    </td>
                    <td style="padding-left:10%;padding-top:7%;color:black;">
                        <hr style="width: 100%" /> &nbsp; Date 
                    </td>
                </tr>         
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="2">
                        ${pageGap}
                        <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC </b> </span> <br/> By, Name and Title
                    </td>            
                </tr> 
                <tr>
                    <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                        <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
                    </td>
                </tr> 
                <tr >
                  <td style="align-items: center;padding-left: 42%;color:black;" colspan="2">
                      <br/> <br/> Page  1  of  ${pageCount}
                  </td>
                  <td style="align-items: center;padding-left: 27%;color:black;" colspan="2">
                      <br/> <br/> ${paragraph}
                  </td>
                </tr>
              </table>
              </p>
              
              <p style="page-break-after: always">
              ${pageGap} 
              <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
              <tr style="padding-left:1%; padding-right: 1%;">                    
                    <td style="padding-left:25%;text-align: center; padding-right: 0.1%;" colpsan="3">
                        <br/>
                        <span style="font-size:150%;color: black;">
                            <b> Contract for Purchase <br/> And Sale Of Farmer Stock Peanuts  </b> <br/>
                        </span>
                        <span style="font-size:135%;color:black;">
                            <b> TERMS AND CONDITIONS </b>
                        </span>
                    </td>                    
                </tr>
                <tr>
                    <td style="padding-top:1%;padding-left:0.01%;padding-right:0.01%;text-align:justify;color:black;font-size:110%;" colspan="3">
                        <b>1. Entire Contract:</b> This contract constitutes the complete and exclusive agreement between Golden and Seller as to the subject matter hereof, supersedes any and all other agreements, representations, promises, statements or understandings between Golden and Seller as to the subject matter hereof, and may be amended only by written agreement signed by the party to be bound. No parol evidence shall be relevant to supplement or explain this agreement. 
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>2. Loan Repayment:</b> For purposes of this contract, Loan Repayment shall be defined as the amount required by producers to satisfy peanut marketing assistance loans with USDA, excluding interest. 
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>3. Right of First Refusal:</b> Seller shall not sell uncontracted peanuts to a buyer other than Golden without first offering such peanuts to Golden at a price not greater and upon terms and conditions not less favorable than the terms and conditions, including the cash price, offered in writing and in good faith by a buyer who is a bona fide commercial peanut sheller for such peanuts. Seller shall furnish the written offer to Golden who shall then have a period of 72 hours in which to meet the offer. Should Golden fail to meet the offer within such time, Seller may proceed to sell such peanuts to the other buyer on the basis of the same price, terms and conditions offered to Golden. However, in the event that Seller does not sell such peanuts to the other buyer on the basis of said price, terms and conditions offered to Golden, any subsequent offer to Seller for such peanuts by another buyer must be presented to Golden pursuant to the right of first refusal set forth in this paragraph.
                    </td>
                </tr>        
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>4. Assignment:</b> The rights and obligations of Seller may not be assigned or terminated without the consent of Golden, such consent to not unreasonably be withheld. Golden may assign without the consent of Seller any or all of its obligations hereunder upon notice to Seller.
                    </td>
                </tr>          
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>5. Failure to Deliver: </b>  It is the express bona fide intention of the Seller and Golden that Seller shall actually produce and deliver to Golden the full quantity of peanuts contracted hereunder. If Seller fails to deliver the full quantity of the peanuts as contracted hereunder, for any reason whatsoever, except for a weather-related act of God, Golden may, at its option, cover any undelivered quantity at Seller's expense, and/or exercise any right or remedy which it has under applicable law, including but not limited to, the Uniform Commercial Code as in effect in the State of Georgia. Golden and Seller agree that they have executed and delivered this contract in contemplation of the particular and unique characteristics and source of peanuts and Golden's own marketing and delivery requirements with respect thereto.
                    </td>
                </tr>   
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>6. No Adulteration: </b>   Seller warrants that the peanuts delivered on this contract shall be fit for human consumption and not be adulterated or misbranded within the meaning of the Federal Food, Drug and Cosmetic Act or be peanuts which may not, under provisions of that Act, be introduced into interstate commerce. Any peanuts not in compliance with the foregoing warranties or any peanuts, irrespective of grade, which are tagged, seized, condemned, declared unfit or otherwise objectionable by any municipal, state, or federal agency shall not be acceptable under this contract and ownership thereof shall remain with Seller. Seller shall pay all costs incurred by Golden with respect to any such peanuts. Seller further warrants that no product containing daminozides, alachlor, botran, or any pesticide not specifically labeled for peanuts, has been used on the peanut plants on which the peanuts were grown. Such use of daminozides, alachlor, botran monosodium methanearsonate (MSMA) or any other pesticide not specifically labeled for peanuts by the Seller shall constitute a breach of Seller's obligations hereunder. Seller will reimburse Golden for any and all costs and expenses incurred as a result of Seller's use of daminozides, alachlor, botran, MSMA, or any other pesticides not specifically labeled for peanuts by Seller. Such use shall constitute a breach of Seller's obligations hereunder. Seller agrees that Golden and/or its representatives shall have access at all reasonable times to examine Seller's acreage on which the peanuts are being grown and shall be permitted to take a reasonable number of plant tissue samples for chemical analysis.
                    </td>
                </tr>  
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>7. Separate Deliveries: </b>   Seller may make separate deliveries hereunder. As Golden accepts each such delivery, Golden shall pay to Seller an amount for such delivery determined as set forth above. No payment by Golden shall be construed as a waiver of any term or condition of this agreement to be performed by Seller.                
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>8. Different Grades: </b>    Golden, at its sole option, may accept delivery of a different type or grade of peanuts than specified herein, or otherwise not complying with the terms and conditions herein. Any non-complying delivery accepted by Golden shall be at the price herein provided, subject to the USDA Price Table File for the previously-referenced crop year and Peanut type. Acceptance of any delivery not complying with the terms and conditions of this contract shall not waive any rights or remedies accruing to Golden as a result of such noncompliance nor effect a waiver of any further noncompliance.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>9. Choice of law: </b>   This contract shall be governed by the laws of the State of Georgia and shall be void unless signed by both parties.        
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>10. Failure to Purchase: </b>  Golden shall have the right to decline to purchase the peanuts contracted hereunder, in which event Seller's damages shall be limited to the amount, if any, which Golden agreed to pay Seller above the USDA marketing assistance loan repayment rate.
                    </td>
                </tr> 
                
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>11. Order of Delivery: </b>  In the event Seller has contracted for the sale of peanuts under separate contracts, Seller will be paid for deliveries of peanuts at the applicable price in the chronological order in which such contracts were signed.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>12. Unenforceable Provisions: </b>  In the event any one or more provisions of this contract shall for any reason be held to be invalid, illegal or unenforceable in any respect, such invalidity, illegibility, or unenforceability shall not affect any other provision hereof, and this contract shall be construed as if such invalid, illegal or unenforceable provision has never been contained herein.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>13. Arbitration: </b>  Any and all disputes under this contract shall be submitted for arbitration in accordance with Article VIII of the American Peanut Shellers Association Official Trading Rules. Provided, however, the foregoing shall not bar a party from injunctive or other equitable relief from a court of competent jurisdiction when necessary to prevent immediate irreparable harm, preserve the status quo pending resolution of the underlying dispute, or in aid of arbitration, provided that the underlying dispute, if not already submitted to arbitration, is submitted for negotiation and arbitration promptly after the applicable relief. Neither party shall be awarded punitive nor exemplary damages for claims hereunder.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>14. Damaged Peanuts: </b>  Golden may reject any peanuts delivered hereunder which have any damage due to pitting, freeze or concealed damage.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>15. Release of FSA Records: </b>  Seller may, at his discretion, grant the applicable Farm Service Agency (FSA) office permission to release all records of Seller's to Golden pertaining to planted acreage or the marketing of peanuts covered by this contract.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>16.  Point of Delivery: </b> Delivery shall be to the Delivery Point specified in this contract, unless otherwise approved by Golden.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>17. Basis Grades: </b>  The term, "Basis Grade", means that the price per ton shall be adjusted up or down according to the official grade results. The USDA Price Table File for the applicable crop year and Peanut type shall be used to determine the final price.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>18. No Sale/Re-Grade: </b>  At Golden's sole option, peanuts "no-saled" by Seller because of grade may be rejected.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>19. High Oleic Peanuts: </b>  Seller warrants that if the peanuts herein are high oleic, the appropriate box on the contract is marked and that the seed from which such peanuts were produced are from a licensed source.
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>20. Beneficial Interest: </b>  Seller retains all beneficial interests in the peanuts until such time as the title to the peanuts is actually transferred to Golden, the warehouse receipts are delivered to Golden, or any payment of the purchase price is made by Golden. Until that time, Seller retains title to and control of the peanuts and beneficial interest as specified in 7CFR 1421.6 remains with Seller until time of purchase.                
                    </td>
                </tr> 
                <tr>
                    <td style="padding-top:0.025%;padding-left:0.01%;padding-right:0.01%;text-align: justify;color:black;font-size:110%;" colspan="3">
                        <b>21. Bought from storage: </b>   Peanuts contracted herein that are stored prior to purchase shall be bought at the prices and terms stated herein, but will be bought on the basis of the delivered weights and grades, not on the weights and grades after shrinking for storage.
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:0.01%;color:black;" colspan="2">
                        ${pageGap}<span style="font-size: 150%;"> <b> GOLDEN PEANUT COMPANY, LLC </b> </span> <br/> By, Name and Title
                    </td>            
                </tr> 
                <tr>
                    <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">                    
                    </td>
                </tr> 
                <tr>
                  <td style="align-items: center;padding-left: 45%;color:black;" colspan="2">
                      <br/> <br/> Page  2  of  ${pageCount}
                  </td>
                  <td style="align-items: center;padding-left: 10%;color:black;" colspan="2">
                      <br/> Rev. May, 2008  <br/> <br/> <br/> <br/>
                  </td>
                </tr> 
              </table>  
            </p>`
    }

    function getFMChemicalData(fontSize, obj, dict, pageNumber, pageCount){
        return `<p style="page-break-after: always">        
    <table style="align-items: center;width: 100%;font-size:${fontSize}%;">
        <tr style="padding-left:1%; padding-right: 1%;">
            <td style="padding-left:2.5%; padding-right: 1%;">                	
                <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
            </td>
            <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
            </td>
            <td style="padding-left:15%;">
                <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
            </td>
        </tr>     
        <tr>
            <td style="text-align: center;align-items: center;" colspan="3">
                <span style="font-size:170%;color:black;text-align: center;">
                <br/> <br/><br/> <b> ADDENDUM TO FARMERS STOCK CONTRACT </b> <br /> <br/> <br/> <br/> <br/>
                </span>
                </span>
            </td>
        </tr>
        <tr>
            <td style="padding-top:5%;padding-left:1%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
                <b> The undersigned Seller/Producer does hereby agree with Golden Peanut Company, LLC./Buyer that if the peanuts </b>
            </td>
        </tr>
        <tr>
            <td style="padding-top:2%;padding-left:1%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
                <b> sold by Seller to Buyer grade in excess of 6% Foreign Material, Buyer will cause the peanuts to be cleaned using </b> 
            </td>
        </tr>
        <tr>
            <td style="padding-top:2%;padding-left:1%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
                <b> standard farmers stock cleaning processes prior to sale to Buyer. </b>
            </td>
        </tr>
        <tr>
            <td style="padding-top:10%;padding-left:1%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
                <b> This Addendum shall be considered a part of all Farmers Stock Contracts and Options between Seller and Buyers. </b>
            </td>
        </tr>
        <tr>
            <td style="padding-top:2%;padding-left:1%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
                <b> This <u> ${GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[1]}${dict[GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[1]]}</u> day of <u>${GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[0]}</u>, <u>${GetTodayDate(obj.contractDate).split(',')[2].trim()}</u>. </b>
                <br> <br> <br> <br> <br> <br/> <br/> <br/> <br/> <br/> <br/> <br> <br/> <br/> <br/> 
            </td>
        </tr>    
        <tr>
            <td style="padding-left:0.01%;color:black;" colspan="2">
                <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC/Buyer </b> </span> <br/> By, Name and Title
            </td>            
        </tr> 
        <tr>
            <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
            </td>
        </tr>
        <tr>
            <td style="align-items: center;padding-left: 45%;color:black;" colspan="3">
                <br/> <br/> <br/> <br/> Page  ${pageNumber}  of  ${pageCount} 
            </td>            
        </tr>  
    </table>
            </p>`
    }

    function getContractComments(fontSize, tableWidth, pageGap, obj, pageNumber, pageCount){
        return `<p style="page-break-after: always">
        <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
            <tr style="padding-left:1%; padding-right: 1%;">
                        <td style="padding-left:2.5%; padding-right: 1%;">                	
                            <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                        </td>
                        <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
                            <br/>
                            <span style="font-size:150%;color: black;">
                                <br/> <b> CONTRACT ATTACHMENT  </b>
                            </span>
                        </td>
                        <td style="padding-left:0.05%;">
                            <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                            <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                            <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
                        </td>
                    </tr>  
                    <tr>
                        <td style="padding-left:2.5%;font-size: 110%;color:black;" colspan="3"> 
                            ${pageGap} ${pageGap} ${pageGap}
                        </td>
                    </tr>     
                    <tr>
                        <td style="padding-left:2.5%;font-size: 125%;color:black;"> <b> Crop Year: <u> ${obj.cropYear} </u> </b> </td>
                        <td style="padding-left:15%;text-align: center; padding-right: 2%;color:black;font-size:125%" colspan="2">  <b> Buying Point &nbsp; : <u> ${obj.buyingPoint} </b> </u> </td>
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;font-size: 125%;color:black;"> <b> Loc. Number <u> ${obj.deliveryPoint} </u> </b>  </td>
                        <td style="padding-left:2%;text-align: center; padding-right: 2%;color:black;font-size:125%" colspan="2"> <b> Contract No: <u> ${obj.contractNumber} </u> </b>  </td> 
                    </tr>
                    <tr>
                        <td style="padding-left:2.5%;font-size: 110%;color:black;" colspan="3"> 
                            <br/> <br/> 
                        </td>
                    </tr>  
                    <tr>
                        <td style="padding-left:2.5%;font-size: 125%;color:black;">  
                            <u> Comments: </u> <br/> ${obj.contractRemarks}
                        </td>
                    </tr>                           
        <tr>
            <td style="align-items: center;padding-left:45%;padding-top:10%;color:black;" colspan="3">
            <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> 
                <br/> <br/> Page  ${pageNumber} of  ${pageCount}
                ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} 
                ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap} ${pageGap}                  
            </td>            
        </tr>        
    </table>
        </p>`

    }

    function getVendorDisclosureData(fontSize, tableWidth, obj, pageNumber, pageCount){
        return `<p style="page-break-after: always">
    <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
        <tr style="padding-left:1%; padding-right: 1%;">
            <td style="padding-left:2.5%; padding-right: 1%;">                	
                <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
            </td>
            <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
            </td>
            <td style="padding-left:8%;">
                <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
            </td>
        </tr>   
        <tr>
            <td style="padding-right: 3.75%;" colspan="3">
                <hr style="width: 100%" />
            </td>
        </tr>  
        <tr>
            <td style="text-align: center;align-items: center;" colspan="3">
                <span style="font-size:170%;color:black;text-align: center;">
                    <br/> <br/> <b> VOLUNTARY &nbsp; DISCLOSURE </b> <br /> <br/>
                </span>
            </td>
        </tr>    
        <tr>
            <td style="padding-left:6%;font-size: 125%;color:black;"> <b> Date: <u> ${obj.contractDate} </u> </b> </td>
            <td style="text-align: center; padding-right: 4%;color:black;font-size:125%">
                <b> CROP YEAR &nbsp; : <u> ${obj.cropYear} </b> </u>
            </td>
            <td style="padding-left:18%;font-size: 125%;color:black;"> <b> No: </b> <u> ${obj.contractNumber} </u> </td>
        </tr>
        <tr>
            <td style="padding-left:6%;padding-right: 3.75%;font-size: 135%;color:black;" colspan="3">
                <br/><br/>
                <hr style="width: 100%" />  
                <br/>
                <b> Women Owned, Managed, and Controlled </b> (51% or more) :   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <b> YES </b> <u> &nbsp;&nbsp;&nbsp;&nbsp; </u>  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <b> NO  </b> <u> &nbsp;&nbsp;&nbsp;&nbsp; </u>          
            </td>
        </tr>
        <tr>
            <td style="padding-left:6%;padding-right: 3.75%;font-size: 135%;color:black;" colspan="3">
                <br/><br/>
                *** Must be a United States Citizen.
                <hr style="width: 100%" />  
                <br/>
                <b>  Minority Owned, Managed, and Controlled </b> (51% or more) :   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <b> YES </b> <u> &nbsp;&nbsp;&nbsp;&nbsp; </u>  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <b> NO  </b> <u> &nbsp;&nbsp;&nbsp;&nbsp; </u>   
                <br/> <br/>                 
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; African American    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Indian (Sub-Continent)    <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Hispanic American    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Asian / Pacific Islander    <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Native American    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Multi-Cultural (more than one ethnic group)    <br/>
            </td>
        </tr>
        <tr>
            <td style="padding-left:6%;padding-right: 3.75%;font-size: 135%;color:black;" colspan="3">
                <br/><br/>
                *** Must be a United States Citizen.
                <hr style="width: 100%" />  
                <br/>
                <b>  Veteran Owned, Managed, and Controlled </b> (51% or more) :   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <b> YES </b> <u> &nbsp;&nbsp;&nbsp;&nbsp; </u>  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <b> NO  </b> <u> &nbsp;&nbsp;&nbsp;&nbsp; </u>   
                <br/> <br/>                 
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Vietnam    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                ________ &nbsp;&nbsp;&nbsp; Service Disabled    <br/>                
            </td>
        </tr>
        <tr>
            <td style="padding-left:6%;padding-right: 3.75%;font-size: 135%;color:black;" colspan="3">
                <br/><br/>
                ** Veteran is defined, past or present, as a member of the United States Military.<br/>
                *** Must be a United States Citizen.
                <hr style="width: 100%" />  
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
            </td>
        </tr>
        <tr>
            <td style="padding-left:6%;color:black;" colspan="2">
                <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC </b> </span> <br/> By, Name and Title
            </td>            
        </tr> 
        <tr>
            <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
            </td>
        </tr> 
        <tr>
            <td style="align-items: center;padding-left: 45%;color:black;" colspan="3">
                <br/> <br/> Page  ${pageNumber} of  ${pageCount}
            </td>            
        </tr>  
    </table>
            </p>`
    }

    function getChemicalArgData(fontSize, tableWidth, obj, dict, pageNumber, pageCount){
        return `<p style="page-break-after: always">
        <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
        <tr style="padding-left:1%; padding-right: 1%;">
            <td style="padding-left:2.5%; padding-right: 1%;">                	
                <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
            </td>
            <td style="padding-left:0.1%;text-align: center; padding-right: 0.1%;">
            </td>
            <td style="padding-left:6%;">
                <span style="padding-left:5%;text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                <span style="padding-left:5%;text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  <b> (GOLDEN/BUYER) </b> <br/></span>
                <span style="padding-left:5%;text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                <span style="padding-left:5%;text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA ${obj.zipCode} </b> </span>
            </td>
        </tr>  
    <tr>
        <td style="text-align: center;align-items: center;" colspan="3">
            <span style="font-size:180%;color:black;text-align: center;">
            <br/> <br/> <br/>  <b> ADDENDUM TO THE FARMER STOCK CONTRACT </b> <br /> <br/>
            </span>
        </td>
    </tr>    
    <tr>
        <td style="padding-top:1%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            Dear Peanut Growers:
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            Our world is ever changing and demands to improve safety and quality of our food supply increase daily. We continually strive to meet new goals to satisfy the expectations of our customers and consumers. As producers of a food ingredient, you play a critical role in this process. Food safety/quality begins at the farm.
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            The good job that you do and your efforts to continually improve are greatly appreciated. It takes all segments of our industry working together to succeed and grow.
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            As a new growing season commences, we are looking at areas to improve our systems and processes. This addendum to the contract requires:
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            <ol type="I">
                <li> Monitor and clean peanut transport vehicles. Trailers and wagons need to be free of any other crop residue or foreign material that could be mixed into a load of peanuts. For example, corn, soybeans, pecans. Additionally, soybeans and pecans are both allergens that pose a food safety issue and cannot be mixed with peanuts. </li>
                <li> Ramik Green and other rodenticides used for pest control can be a serious food safety issue if it infiltrates farmer stock peanuts. Please do not allow the products to be used in or around any peanut equipment or storage areas to help reduce the risk of contaminating the peanuts. </li>
                <li> Occasionally during the shelling process we note  internal cavity depressions within the kernel. This is also referred to as "hollow heart" and is a developmental issue. Research literature points to Boron deficiency  in the soil  as a  probable cause for this. When oil roasting these kernels, visual  defects are noticeable and flavor can be negatively impacted. When evaluating soil samples for your crop needs, Boron must be considered. It may help improve kernel density which ultimately increases the weight of your peanuts and improves the physical quality too.</li>
                <li> We've been fortunate in recent years to receive timely beneficial rains on our peanut crop. Hopefully that will be true again this year. However, crop segregation is a large part of our quality program every year. Measures that separate irrigated and non-irrigated peanuts assist in reduction of quality risks such as aflatoxin. Your assistance in harvesting and loading irrigated and non-irrigated peanuts independently, could help us better manage quality for customers and consumers. </li>
                <li> Three other items that <b> should NOT BE USED are ProPhyt fungicide, or similar phosphorous acid based products, Artisan, and TiltBravo fungicide. Bravo is allowed. </b> </li>
                <li> Golden Peanut Company, LLC reserves the right to test product at any time. </li>
            </ol>
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            We greatly appreciate your assistance and cooperation. Golden Peanut looks forward to working with you as we continue improving our processes and products. <br/> <br/>
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            Thank you, <br/> Golden Peanut Company, LLC <br/>
        </td>
    </tr>
    <tr>
        <td style="padding-top:0.5%;padding-left:0.05%;padding-right:0.01%;text-align: justify;color:black;font-size:140%;" colspan="3">
            My signature below indicates I have read, understand, and have received this information.
            This ${GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[1]}${dict[GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[1]]} day of ${GetTodayDate(obj.contractDate).split(',')[1].trim().split(' ')[0]}, ${GetTodayDate(obj.contractDate).split(',')[2].trim()}
            <br/> <br/>  <br/> <br/> <br/> <br/>
        </td>
    </tr>
    <tr>
            <td style="padding-left:0.01%;color:black;" colspan="2">
                <span style="font-size: 150%;"> <b> Golden Peanut Company, LLC Representative </b> </span> <br/> By, Name and Title
            </td>            
        </tr> 
        <tr>
            <td style="padding-left:65%;padding-right:1%;padding-top:8%;color:black;" colspan="3">
                <hr style="width: 100%" /> Seller/Producer Signature <br/> <br/>
            </td>
        </tr>
        <tr>
            <td style="align-items: center;padding-left: 45%;color:black;" colspan="3">
                <br/> <br/> Page  ${pageNumber}  of  ${pageCount}
            </td>            
        </tr> 
    </table>
    </p>`
    }

    const getBlobURL = (code, type) => {
        const blob = new Blob([code], { type });
        return URL.createObjectURL(blob)
    }

    async function Print() {
        try {
            let cont_num = commonContext.getContractProfile_ContractNumber
            let price_suffix = ''
            let response = await ContractManagementService.RetrieveContractDetails(cont_num, 'PN1010')            
            if (response.length == 0 || response == null || response == undefined) {
                lstContract = {
                    "center_pivot_acres": '',
                    "dry_land_acres": '',
                    "yield_per_acre": '',
                    "pnut_variety_name": '',
                    "seed_grower": '',
                    "oleic_ind": '',
                    "organic": ''
                }
                lstContractLienHolders = [
                    { "lienholder_name": '', "lienholder_address": '', "nature_of_lien": '' },
                    { "lienholder_name": '', "lienholder_address": '', "nature_of_lien": '' }
                ]
                price_suffix = state.headerData.scrCode[5]
            }
            else {
                lstContract = response[0]
                lstContractLienHolders = response[0].contract_lienholders
                if(response[0].contract_pricings.length == 0)
                    price_suffix = state.headerData.scrCode[5]
                else
                    price_suffix = response[0].contract_pricings[0].firm_basis
                
                if(response[0].contract_vendor_splits.length != 0){
                    contractVendorSplit = response[0].contract_vendor_splits
                    if(response[0].contract_vendor_splits[0].addendum_seed_growers != 0)
                        addendumSeedGrowers  = response[0].contract_vendor_splits[0].addendum_seed_growers;
                    else
                        addendumSeedGrowers  = []
                }
                else
                    contractVendorSplit = []                
            }
            let response1 = await ContractManagementService.RetieveRemitDetails(lstContract.contract_vendor_splits[0].split_vendor)
            if (response1.length == 0 || response1 == null || response1 == undefined)
                showMessage(thisObj, "Some technical issue has happened. Please try again.")
            else
                contractAddress = response1
                     
            let response2 = await ContractManagementService.RetrieveContractSign(cont_num)
            if (response2.length == 0 || response2 == null || response2 == undefined)
                showMessage(thisObj, "Some technical issue has happened. Please try again.")            
            else
                signDetails = response2[0]
    
            var code = await GetHtmlCode(58, 100, '<br/>')
            var type = 'text/html'
            var fileName = "PDF" + "_" + cropYear + "_" + cont_num + "_" + price_suffix
            
            if(getData(thisObj, 'contractType') == 'C')
                fileName = "CON" + "_" + cropYear + "_" + cont_num + "_" + price_suffix

            const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
            const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1', fileName)
            pdfWindow.focus()
            pdfWindow.addEventListener('beforeprint', event => {})
            pdfWindow.print() 
            pdfWindow.addEventListener('afterprint', (event) => {
                //pdfWindow.close()  // changes suggested by BAU team
            })                       
        }
        catch (err) {
            if (err instanceof EvalError)
                alert(err.message);
            else
                alert("Exception in PreSubmit Event code for widget:btnPrint event:Click");            
        }
    }

    const onbtnPrintClick = async () => {
        try {
            Print()
        }
        catch (err) {
            if (err instanceof EvalError)
                alert(err.message);
            else
                alert("Exception in PreSubmit Event code for widget:btnPrint event:Click");
        }
    }
    thisObj.onbtnPrintClick = onbtnPrintClick;

    const onbtnCloseClick = () => {
        try {
            if (commonContext.getContractProfile_ReportRequest == 'Create') {
                setData(thisObj, 'SaveContract', commonContext.getContractProfile_ReportRequest);
                document.getElementsByClassName("close")[0].click();
            } else if (commonContext.getContractProfile_ReportRequest == 'ContractInquiry') {
                setData(thisObj, 'SaveContract', commonContext.getContractProfile_ReportRequest);
                document.getElementsByClassName("close")[0].click();
            } else {
                setData(thisObj, 'SaveContract', commonContext.getContractProfile_ReportRequest);
                document.getElementsByClassName("close")[1].click();
            }
        } catch (err) {
            if (err instanceof EvalError)
                showMessage(thisObj, err.message);
            else
                showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
            return false;
        }
        return true;
    };
    thisObj.onbtnCloseClick = onbtnCloseClick;

    initForm(thisObj);
    let validationSchema = object(_kaledo.validationSchema);
    let initialValues = _kaledo.initialValues;
    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues}>
            {({
                handleChange,
                handleBlur,
                values,
                isValid,
                errors,
                setFieldValue,
            }) => {
                thisObj.setFieldValue = setFieldValue = setFieldValue;
                thisObj.values = values = values;
                thisObj.handleChange = handleChange = handleChange;
                thisObj.handleBlur = handleBlur = handleBlur;
                thisObj.isValid = isValid = isValid;
                thisObj.errors = errors = errors;
                return (
                    <div
                        className={
                            _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
                        }
                        data-testid={state.windowName}
                        ref={screenRef}
                    >
                        <Modal
                            show={state.showPopup}
                            onHide={vlaues => {
                                handleModalHide(thisObj, values);
                            }}
                            data={dataCopyObj.dataToCopy}
                            component={state.popupComponent}
                        />
                        <ContractManagement_header headerData={state.headerData} />
                        <h1 className="pageHeader">{state.Label}</h1>
                        <Form noValidate className="row">
                            <iframe src={state.renderUrl} id="docFrame" width="100%" height="500px" style={{background:"White"}} ></iframe>
                            <GroupBoxWidget
                                conf={state.groupboxwidget0}
                                screenConf={state}
                            >
                                <ButtonWidget
                                    conf={state.btnPrint}
                                    screenConf={state}
                                    onClick={event => invokeEventHandler(event, handleChange, handleBlur, thisObj) }
                                ></ButtonWidget>
                                <ButtonWidget
                                    conf={state.btnSaveAsPdf}
                                    screenConf={state}
                                    onClick={event => invokeEventHandler(event, handleChange, handleBlur, thisObj) }
                                ></ButtonWidget>
                                <ButtonWidget
                                    conf={state.btnClose}
                                    screenConf={state}
                                    onClick={event => invokeEventHandler(event, handleChange, handleBlur, thisObj) }
                                ></ButtonWidget>
                            </GroupBoxWidget>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
}
export default withRouter(ContractManagement_ReportPreviewContract);