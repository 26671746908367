/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  getValue,
  hide,
  show,
  setData,
  getData,
  setGridValue,
  getGridValue,
  disable,
  enable
} from "../../shared/WindowImports";

import "./Filters.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_Filters(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const [loading, setLoading] = useState(true);
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Filters",
    windowName: "Filters",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.Filters",
    rowSelected: [],//selected row
    // START_USER_CODE-USER_Filters_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Filters",
      scrCode: "PN0310",
    },
    // END_USER_CODE-USER_Filters_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxCounty",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "commandcontainerwidget42",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btncolEditCounty: {
      name: "btncolEditCounty",
      type: "ButtonWidget",
      colName: "colEditCounty",
      parent: "gridStates",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btncolEditCounty_PROPERTIES

      // END_USER_CODE-USER_btncolEditCounty_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxPnutVrty",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnSaveCounty: {
      name: "btnSaveCounty",
      type: "ButtonWidget",
      parent: "grpbxCounty",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSaveCounty_PROPERTIES

      // END_USER_CODE-USER_btnSaveCounty_PROPERTIES
    },
    btnSaveStates: {
      name: "btnSaveStates",
      type: "ButtonWidget",
      parent: "grpbxState",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSaveStates_PROPERTIES

      // END_USER_CODE-USER_btnSaveStates_PROPERTIES
    },
    btnSrch: {
      name: "btnSrch",
      type: "ButtonWidget",
      parent: "grpbxPnutVrty",
      Label: "Search",
      CharWidth: "15",
      // START_USER_CODE-USER_btnSrch_PROPERTIES

      // END_USER_CODE-USER_btnSrch_PROPERTIES
    },
    chkboxcolExcluded: {
      name: "chkboxcolExcluded",
      type: "CheckBoxWidget",
      colName: "colExcluded",
      parent: "gridStates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcolExcluded_PROPERTIES

      // END_USER_CODE-USER_chkboxcolExcluded_PROPERTIES
    },
    chkboxExcludedCnty: {
      name: "chkboxExcludedCnty",
      type: "CheckBoxWidget",
      colName: "gridcolumnwidget21",
      parent: "gridCounties",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxExcludedCnty_PROPERTIES

      // END_USER_CODE-USER_chkboxExcludedCnty_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChkbx",
      parent: "gridPnutFilter",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxCol_PROPERTIES

      // END_USER_CODE-USER_chkbxCol_PROPERTIES
    },
    colChkbx: {
      name: "colChkbx",
      type: "GridColumnWidget",
      parent: "gridPnutFilter",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChkbx_PROPERTIES

      // END_USER_CODE-USER_colChkbx_PROPERTIES
    },
    colCountyNm: {
      name: "colCountyNm",
      type: "GridColumnWidget",
      parent: "gridCounties",
      Label: "County Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCountyNm_PROPERTIES

      // END_USER_CODE-USER_colCountyNm_PROPERTIES
    },
    colEditCounty: {
      name: "colEditCounty",
      type: "GridColumnWidget",
      parent: "gridStates",
      Label: "Edit County",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEditCounty_PROPERTIES

      // END_USER_CODE-USER_colEditCounty_PROPERTIES
    },
    colExcluded: {
      name: "colExcluded",
      type: "GridColumnWidget",
      parent: "gridStates",
      Label: "Excluded",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colExcluded_PROPERTIES

      // END_USER_CODE-USER_colExcluded_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "GridColumnWidget",
      parent: "gridCounties",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colIsStsPnut: {
      name: "colIsStsPnut",
      type: "GridColumnWidget",
      parent: "gridPnutFilter",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIsStsPnut_PROPERTIES

      // END_USER_CODE-USER_colIsStsPnut_PROPERTIES
    },
    colIsStsState: {
      name: "colIsStsState",
      type: "GridColumnWidget",
      parent: "gridStates",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIsStsState_PROPERTIES

      // END_USER_CODE-USER_colIsStsState_PROPERTIES
    },
    colPnutVrtyNm: {
      name: "colPnutVrtyNm",
      type: "GridColumnWidget",
      parent: "gridPnutFilter",
      Label: "Peanut Variety and Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutVrtyNm_PROPERTIES

      // END_USER_CODE-USER_colPnutVrtyNm_PROPERTIES
    },
    colStateNm: {
      name: "colStateNm",
      type: "GridColumnWidget",
      parent: "gridStates",
      Label: "State Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStateNm_PROPERTIES

      // END_USER_CODE-USER_colStateNm_PROPERTIES
    },
    ddBuyingPt: {
      name: "ddBuyingPt",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutVrty",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPt_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPt_PROPERTIES
    },
    ddByngPt: {
      name: "ddByngPt",
      type: "DropDownFieldWidget",
      parent: "grpbxStateCounty",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddByngPt_PROPERTIES

      // END_USER_CODE-USER_ddByngPt_PROPERTIES
    },
    ddPnutType: {
      name: "ddPnutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutVrty",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPnutType_PROPERTIES

      // END_USER_CODE-USER_ddPnutType_PROPERTIES
    },
    gridcolumnwidget21: {
      name: "gridcolumnwidget21",
      type: "GridColumnWidget",
      parent: "gridCounties",
      Label: "Excluded",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget21_PROPERTIES

      // END_USER_CODE-USER_gridcolumnwidget21_PROPERTIES
    },
    gridCounties: {
      name: "gridCounties",
      type: "GridWidget",
      parent: "grpbxCounty",
      gridCellsOrder: "txtColIsSts,chkboxExcludedCnty,txtcolCountyNm",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridCounties_PROPERTIES

      // END_USER_CODE-USER_gridCounties_PROPERTIES
    },
    gridPnutFilter: {
      name: "gridPnutFilter",
      type: "GridWidget",
      parent: "grpbxPnutVrty",
      gridCellsOrder: "chkbxCol,txtcolPnutVrtyNm",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridPnutFilter_PROPERTIES

      // END_USER_CODE-USER_gridPnutFilter_PROPERTIES
    },
    gridStates: {
      name: "gridStates",
      type: "GridWidget",
      parent: "grpbxState",
      gridCellsOrder:
        "txtColIsStsState,btncolEditCounty,chkboxcolExcluded,txtcolStateNm",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridStates_PROPERTIES

      // END_USER_CODE-USER_gridStates_PROPERTIES
    },
    grpbxCounty: {
      name: "grpbxCounty",
      type: "GroupBoxWidget",
      parent: "grpbxStateCounty",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxCounty_PROPERTIES

      // END_USER_CODE-USER_grpbxCounty_PROPERTIES
    },
    grpbxState: {
      name: "grpbxState",
      type: "GroupBoxWidget",
      parent: "grpbxStateCounty",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxState_PROPERTIES

      // END_USER_CODE-USER_grpbxState_PROPERTIES
    },
    lblAkkCounties: {
      name: "lblAkkCounties",
      type: "LabelWidget",
      parent: "grpbxCounty",
      Label: "Akk Counties",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAkkCounties_PROPERTIES

      // END_USER_CODE-USER_lblAkkCounties_PROPERTIES
    },
    lblFilterSel: {
      name: "lblFilterSel",
      type: "LabelWidget",
      parent: "grpbxFilter",
      Label: "Filter Selection",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFilterSel_PROPERTIES

      // END_USER_CODE-USER_lblFilterSel_PROPERTIES
    },
    lblPnutVrtyFiltr: {
      name: "lblPnutVrtyFiltr",
      type: "LabelWidget",
      parent: "grpbxPnutVrty",
      Label: "Peanut Variety Filter:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPnutVrtyFiltr_PROPERTIES

      // END_USER_CODE-USER_lblPnutVrtyFiltr_PROPERTIES
    },
    lblSelect: {
      name: "lblSelect",
      type: "LabelWidget",
      parent: "grpbxPnutVrty",
      Label: "Select varieties to be excluded:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSelect_PROPERTIES

      // END_USER_CODE-USER_lblSelect_PROPERTIES
    },
    lblStateCounty: {
      name: "lblStateCounty",
      type: "LabelWidget",
      parent: "grpbxStateCounty",
      Label: "State and County filter",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStateCounty_PROPERTIES

      // END_USER_CODE-USER_lblStateCounty_PROPERTIES
    },
    lblStates: {
      name: "lblStates",
      type: "LabelWidget",
      parent: "grpbxState",
      Label: "States",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStates_PROPERTIES

      // END_USER_CODE-USER_lblStates_PROPERTIES
    },
    radioFilter: {
      name: "radioFilter",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFilter",
      Options: "Peanut Variety Filter:1,State and County Filter:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioFilter_PROPERTIES

      // END_USER_CODE-USER_radioFilter_PROPERTIES
    },
    txtcolCountyNm: {
      name: "txtcolCountyNm",
      type: "TextBoxWidget",
      colName: "colCountyNm",
      parent: "gridCounties",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtcolCountyNm_PROPERTIES

      // END_USER_CODE-USER_txtcolCountyNm_PROPERTIES
    },
    txtColIsSts: {
      name: "txtColIsSts",
      type: "TextBoxWidget",
      colName: "colIsSts",
      parent: "gridCounties",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtColIsSts_PROPERTIES

      // END_USER_CODE-USER_txtColIsSts_PROPERTIES
    },
    txtColIsStsState: {
      name: "txtColIsStsState",
      type: "TextBoxWidget",
      colName: "colIsStsState",
      parent: "gridStates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtColIsStsState_PROPERTIES

      // END_USER_CODE-USER_txtColIsStsState_PROPERTIES
    },
    txtcolPnutVrtyNm: {
      name: "txtcolPnutVrtyNm",
      type: "TextBoxWidget",
      colName: "colPnutVrtyNm",
      parent: "gridPnutFilter",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtcolPnutVrtyNm_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutVrtyNm_PROPERTIES
    },
    txtcolStateNm: {
      name: "txtcolStateNm",
      type: "TextBoxWidget",
      colName: "colStateNm",
      parent: "gridStates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtcolStateNm_PROPERTIES

      // END_USER_CODE-USER_txtcolStateNm_PROPERTIES
    },
    grpbxFilter: {
      name: "grpbxFilter",
      type: "GroupBoxWidget",
      parent: "Filters",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFilter_PROPERTIES

      // END_USER_CODE-USER_grpbxFilter_PROPERTIES
    },
    grpbxStateCounty: {
      name: "grpbxStateCounty",
      type: "GroupBoxWidget",
      parent: "Filters",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxStateCounty_PROPERTIES

      // END_USER_CODE-USER_grpbxStateCounty_PROPERTIES
    },
    grpbxPnutVrty: {
      name: "grpbxPnutVrty",
      type: "GroupBoxWidget",
      parent: "Filters",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPnutVrty_PROPERTIES

      // END_USER_CODE-USER_grpbxPnutVrty_PROPERTIES
    },
    commandcontainerwidget42: {
      name: "commandcontainerwidget42",
      type: "CommandContainerWidget",
      parent: "Filters",
      Label: "commandcontainerwidget42",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_commandcontainerwidget42_PROPERTIES

      // END_USER_CODE-USER_commandcontainerwidget42_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {}
      },
      REVERSE: {
        "ContractManagement#MainMenu": {}
      },
    },
  };
  let _buttonServices = { MainMenu: {} };
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: [
        "ContractManagement#MainMenu#DEFAULT#false#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    setValue(thisObj, "radioFilter", "1") //bydefault first radio btn selected
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  //form Load Implimentation
  async function FormLoad() {
    FillBuyingPointComboBox()
    FillPeanutTypeItems()
    await onradioFilterChange()
    await EnableControls()
    setValue(thisObj, "lblAkkCounties", " Counties")
    setLoading(false);
  }

  //radio btn change event
  const onradioFilterChange = async () => {
    await EnableControls()
    setValue(thisObj, "lblAkkCounties", " Counties")
    document.getElementsByClassName('gridStates')[0].style.pointerEvents = 'auto'
  };
  thisObj.onradioFilterChange = onradioFilterChange;

  //Buying Point Dropdown Binding
  const FillBuyingPointComboBox = () => {
    try {
      let js = [];
      js.push({ key: '', description: '' });
      ContractManagementService.RetrieveBuyingPointControlDetails('PN0310', null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyingPt: {
              ...state["ddBuyingPt"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddBuyingPt', js[0].key);

        thisObj.setState(current => {
          return {
            ...current,
            ddByngPt: {
              ...state["ddByngPt"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddByngPt', js[0].key);
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during FillBuyingPointComboBox event"
        );
      }
    }
  }

  //Peanut Type Dropdown Binding
  const FillPeanutTypeItems = () => {
    try {
      let js = [];
      js.push({ key: '', description: '' }) 
      ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)

        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPnutType: {
              ...state["ddPnutType"],
              valueList: js,
            }
          }
        })
        setValue(thisObj, 'ddPnutType', js[0].key);
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during FillPeanutTypeItems event"
        );
      }
    }
  }

  //Peanut Variety Filter: search grid populating from here..
  const FillPeanutVariteyItems = async () => {
    try {
      setLoading(true);
      let buyingPointId = getValue(thisObj, "ddBuyingPt")
      let pnut_type_id = getValue(thisObj, "ddPnutType")
      let resp = await SystemMaintenanceMasterManagementService.RetrievePeanutVarietyFilterDetails(compId, cropYear, buyingPointId, pnut_type_id)
      let TransformedRowsArray = [];
      if (resp.length === 0) {
        setValue(thisObj, "gridPnutFilter", TransformedRowsArray);
        return;
      }
      else {
        let js = []
        let obj = {}
        for (var i = 0; i < resp.length; i++) {
          obj.txtcolPnutVrtyNm = resp[i].id + "-" + resp[i].name
          obj.varietyId = resp[i].id
          js.push(obj)
          obj = {}
        }
        setValue(thisObj, "gridPnutFilter", js);
        for (var j = 0; j < resp.length; j++) {
          if (resp[j].isselected == "Y") {
            setGridValue(_kaledo.thisObj, 'gridPnutFilter', 'chkbxCol', j, true)
          }
        }
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during FillPeanutVariteyItems event"
        );
      }
    }
    finally {
      setLoading(false);
    }
  }

  //Search Btn Functionality
  const onbtnSrchClick = async () => {
    try {
      if (getValue(thisObj, "ddBuyingPt") == "" || getValue(thisObj, "ddBuyingPt") == undefined || getValue(thisObj, "ddBuyingPt") == null) {
        alert("Please Select a Buying Point.")
        return;
      }
      if (getValue(thisObj, "ddPnutType") == "" || getValue(thisObj, "ddPnutType") == undefined || getValue(thisObj, "ddPnutType") == null) {
        alert("Please Select a Peanut Type.")
        return;
      }
      else {
        await FillPeanutVariteyItems()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtnSrchClick: Click"
        )
      }
      return
    }
  };
  thisObj.onbtnSrchClick = onbtnSrchClick;

  //Enable Disable functionality
  const EnableControls = async () => {
    try {
      let radioFilter = getValue(thisObj, "radioFilter")
      if (radioFilter == 1) {
        hide(thisObj, "grpbxCounty")
        hide(thisObj, "grpbxState")
        hide(thisObj, "grpbxStateCounty")
        show(thisObj, "ddBuyingPt")
        show(thisObj, "ddPnutType")
        show(thisObj, "gridPnutFilter")
        show(thisObj, "grpbxPnutVrty")
        setValue(thisObj, "ddPnutType", "")
        setValue(thisObj, "ddBuyingPt", "")
        setValue(thisObj, "gridPnutFilter", []);
        disable(thisObj, 'btnSaveStates');
        disable(thisObj, 'btnSaveCounty');
        disable(thisObj, 'btnSaveCounty');
        disable(thisObj, 'btnCancel');
      }
      else if (radioFilter == 2) {
        show(thisObj, "grpbxCounty")
        show(thisObj, "grpbxState")
        show(thisObj, "grpbxStateCounty")
        hide(thisObj, "ddBuyingPt")
        hide(thisObj, "ddPnutType")
        hide(thisObj, "gridPnutFilter")
        hide(thisObj, "grpbxPnutVrty")
        setValue(thisObj, "ddByngPt", "")
        setValue(thisObj, "gridStates", []);
        setValue(thisObj, "gridCounties", []);
        disable(thisObj, 'btnSaveStates');
        disable(thisObj, 'btnSaveCounty');
        disable(thisObj, 'btnSaveCounty');
        disable(thisObj, 'btnCancel');
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during EnableControls event"
        );
      }
    }
  }

  //Dropdown Change Event
  const onddByngPtChange = () => {
    try {
      bFillStateGrid()
      document.getElementsByClassName('gridStates')[0].style.pointerEvents = 'auto'
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddByngPtChange change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddByngPtChange = onddByngPtChange;

  //state Grid Binding
  const bFillStateGrid = async () => {
    try {
      setLoading(true);
      let mBuyingPointList = getValue(thisObj, "ddByngPt")
      let LstrList = await SystemMaintenanceMasterManagementService.RetrieveFilterStateCountyDetails(mBuyingPointList, "STATE", null)
      if (LstrList.length > 0) {
        let js = []
        let obj = {}
        for (var i = 0; i < LstrList.length; i++) {
          obj.txtcolStateNm = LstrList[i].state_name
          obj.stateAbbr = LstrList[i].state_abbr
          js.push(obj)
          obj = {}
        }
        setValue(thisObj, "gridStates", js);
        for (var j = 0; j < LstrList.length; j++) {
          if (LstrList[j].excluded == "Y") {
            setGridValue(_kaledo.thisObj, 'gridStates', 'chkboxcolExcluded', j, true)
          }
        }
        enable(thisObj, 'btnSaveStates');
        setValue(thisObj, "gridCounties", []);
        disable(thisObj, 'btnSaveCounty');
        disable(thisObj, 'btnCancel');
      }
      else {
        setValue(thisObj, "gridStates", []);
        setValue(thisObj, "gridCounties", []);
        disable(thisObj, 'btnSaveCounty');
        disable(thisObj, 'btnCancel');
      }
      document.getElementById('column-btncolEditCounty').firstChild.innerHTML = "Edit County"
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillStateGrid event"
        );
      }
    }
    finally {
      setLoading(false);
    }
  }

  //County Grid Binding
  const bFillCountyGrid = async () => {
    try {
      setLoading(true);
      let BuyingPointList = getValue(_kaledo.thisObj, "ddByngPt")
      let stateAbbr = getData(thisObj, "stateAbbr")
      let statelabelchange = getData(thisObj, "StateforLabel")
      setValue(thisObj, "lblAkkCounties", statelabelchange == "" ? "" : statelabelchange + " Counties")

      enable(thisObj, 'btnSaveCounty');
      enable(thisObj, 'btnCancel');
      disable(thisObj, "btnSaveStates")
      let LstrList = await SystemMaintenanceMasterManagementService.RetrieveFilterStateCountyDetails(BuyingPointList, "COUNTY", stateAbbr)
      if (LstrList.length > 0) {
        let js = []
        let obj = {}
        for (var i = 0; i < LstrList.length; i++) {
          obj.txtcolCountyNm = LstrList[i].county_name + "-" + LstrList[i].county_id
          obj.CountyAbbr = LstrList[i].county_id
          js.push(obj)
          obj = {}
        }
        setValue(thisObj, "gridCounties", js);
        for (var j = 0; j < LstrList.length; j++) {
          if (LstrList[j].excluded == "Y") {
            setGridValue(_kaledo.thisObj, 'gridCounties', 'chkboxExcludedCnty', j, true)
          }
        }
        document.getElementsByClassName('gridStates')[0].style.pointerEvents = 'none' // disable grid of state
      }
      else {
        setValue(thisObj, "gridCounties", []);
        document.getElementsByClassName('gridStates')[0].style.pointerEvents = 'none' // disable grid of state
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillCountyGrid event"
        );
      }
    }
    finally {
      setLoading(false);
    }
  }

  //Save Btn Functionality
  const onbtnSaveClick = async () => {
    try {
      let buyingPointId = getValue(thisObj, "ddBuyingPt")
      let pnut_type_id = getValue(thisObj, "ddPnutType")
      let vsfPeanutVariety = getValue(thisObj, "gridPnutFilter")
      let peanutVarietyArray = [
        {
          "comp_id": compId,
          "crop_year": (cropYear).toString(),
          "buy_pt_id": buyingPointId,
          "pnut_type_id": pnut_type_id
        }
      ]
      let filtercount = 1

      if (vsfPeanutVariety != undefined && vsfPeanutVariety != null && vsfPeanutVariety != '') {
        for (var i = 0; i < vsfPeanutVariety.length; i++) {
          if (vsfPeanutVariety[i].chkbxCol != undefined && vsfPeanutVariety[i].chkbxCol != null) {
            if (vsfPeanutVariety[i].chkbxCol == true) {
              let PeanutVarietyObj = {
                "comp_id": compId,
                "crop_year": (cropYear).toString(),
                "buy_pt_id": buyingPointId,
                "pnut_type_id": pnut_type_id,
                "pnut_variety_id": vsfPeanutVariety[i].varietyId
              }
              peanutVarietyArray.push(PeanutVarietyObj)
              filtercount++
            }
          }
        }
        let dataObj =
        {
          "filtercount": filtercount,
          "peanut_variety_filter": peanutVarietyArray
        }
        let lstrxml = await SystemMaintenanceMasterManagementService.UpdatePeanutVarietyFilter(dataObj)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtnSaveClick: Click"
        )
      }
      return
    }
  };
  thisObj.onbtnSaveClick = onbtnSaveClick;

 //Save state Btn Click event
  const onbtnSaveStatesClick = async () => {
    try {
      setLoading(true);
      let vsfState = getValue(thisObj, "gridStates")
      let buyingPointId = getValue(thisObj, "ddByngPt")
      let stateAbbr = []
      let statetotal
      if (vsfState != undefined && vsfState != null && vsfState != '') {
        for (var i = 0; i < vsfState.length; i++) {
          if (vsfState[i].chkboxcolExcluded != undefined && vsfState[i].chkboxcolExcluded != null) {
            if (vsfState[i].chkboxcolExcluded == true) {
              stateAbbr.push(vsfState[i].stateAbbr)
              statetotal = (stateAbbr).join('|') + '|'
            }
          }
        }
        let dataObj =
        {
          "state_abbr_list": statetotal,
          "county_list": ""
        }
        if (statetotal == undefined) {
          dataObj =
          {
            "state_abbr_list": "",
            "county_list": ""
          }
        }
        let lstrxml = await SystemMaintenanceMasterManagementService.UpdateFilterStateCounty(buyingPointId, "STATE", dataObj)
      }
      else {
        return;
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtnSaveStatesClick: Click"
        )
      }
      return
    }
  };
  thisObj.onbtnSaveStatesClick = onbtnSaveStatesClick;

  //btn inside grid of state click event
  const onbtncolEditCountyClick = async (event) => {
    try {
      let vsfDataState = getValue(thisObj, "gridStates")
      let excluded

      let rowSelected = event.target.parentElement.parentElement.parentElement
      let rowID = rowSelected.id.substring(4, 6);
      if (vsfDataState[rowID].stateAbbr != null && vsfDataState[rowID].stateAbbr != undefined && vsfDataState[rowID].stateAbbr != "") {
        let stateAbbr = vsfDataState[rowID].stateAbbr;
        excluded = vsfDataState[rowID].chkboxcolExcluded
        let StateforLabel = vsfDataState[rowID].txtcolStateNm
        if (stateAbbr != null && stateAbbr != "") {
          setData(thisObj, "stateAbbr", stateAbbr)
          setData(thisObj, "StateforLabel", StateforLabel)
        }
      }

      if (vsfDataState != undefined && vsfDataState != null && vsfDataState != '') {
        if (excluded == true) {
          const response = confirm("Cannot exclude counties if state is excluded.  Remove exclusion?");
          if (response) {
            //deselect checkbox 
            setGridValue(_kaledo.thisObj, 'gridStates', 'chkboxcolExcluded', rowID, false)
          }
          else {
            return;
          }
        }
        await bFillCountyGrid()

      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtncolEditCountyClick: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtncolEditCountyClick = onbtncolEditCountyClick;

  //save County btn click event
  const onbtnSaveCountyClick = async () => {
    try {
      setLoading(true);
      let vsfCounty = getValue(thisObj, "gridCounties")
      let buyingPointId = getValue(thisObj, "ddByngPt")
      let stateAbbr = getData(thisObj, "stateAbbr")
      let countyAbbr = []
      let countytotal
      if (vsfCounty != undefined && vsfCounty != null && vsfCounty != '') {
        for (var i = 0; i < vsfCounty.length; i++) {
          if (vsfCounty[i].chkboxExcludedCnty != undefined && vsfCounty[i].chkboxExcludedCnty != null) {
            if (vsfCounty[i].chkboxExcludedCnty == true) {
              countyAbbr.push(vsfCounty[i].CountyAbbr)
              countytotal = (countyAbbr).join('|') + '|'
            }
          }
        }
        let dataObj =
        {
          "state_abbr_list": stateAbbr,
          "county_list": countytotal
        }
        if (countytotal == undefined) {
          dataObj =
          {
            "state_abbr_list": stateAbbr,
            "county_list": ""
          }
        }
        let lstrxml = await SystemMaintenanceMasterManagementService.UpdateFilterStateCounty(buyingPointId, "COUNTY", dataObj)
        if (lstrxml.status == 200) {
          onbtnCancelClick()
        }
      }
      else {
        onbtnCancelClick()
        return;
      }
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtnSaveCountyClick: Click"
        )
      }
    }
    finally {
      setLoading(false);
    }
  };
  thisObj.onbtnSaveCountyClick = onbtnSaveCountyClick;

  //Cancel Click event
  const onbtnCancelClick = async () => {
    try {
      setValue(thisObj, "gridCounties", []);
      disable(thisObj, 'btnSaveCounty');
      disable(thisObj, 'btnCancel');
      enable(thisObj, 'btnSaveStates');
      setValue(thisObj, "lblAkkCounties", " Counties")
      document.getElementsByClassName('gridStates')[0].style.pointerEvents = 'auto'
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:onbtnCancelClick: Click"
        )
      }
      return
    }
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_Filters*/}

              {/* END_USER_CODE-USER_BEFORE_Filters*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFilter*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFilter*/}

              <GroupBoxWidget conf={state.grpbxFilter} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblFilterSel*/}

                {/* END_USER_CODE-USER_BEFORE_lblFilterSel*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFilterSel}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFilterSel*/}

                {/* END_USER_CODE-USER_AFTER_lblFilterSel*/}
                {/* START_USER_CODE-USER_BEFORE_radioFilter*/}

                {/* END_USER_CODE-USER_BEFORE_radioFilter*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioFilter}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioFilter*/}

                {/* END_USER_CODE-USER_AFTER_radioFilter*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFilter*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFilter*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxStateCounty*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxStateCounty*/}

              <GroupBoxWidget conf={state.grpbxStateCounty} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblStateCounty*/}

                {/* END_USER_CODE-USER_BEFORE_lblStateCounty*/}

                <LabelWidget
                  values={values}
                  conf={state.lblStateCounty}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblStateCounty*/}

                {/* END_USER_CODE-USER_AFTER_lblStateCounty*/}
                {/* START_USER_CODE-USER_BEFORE_ddByngPt*/}

                {/* END_USER_CODE-USER_BEFORE_ddByngPt*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddByngPt}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddByngPt*/}

                {/* END_USER_CODE-USER_AFTER_ddByngPt*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxState*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxState*/}

                <GroupBoxWidget conf={state.grpbxState} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblStates*/}

                  {/* END_USER_CODE-USER_BEFORE_lblStates*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblStates}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblStates*/}

                  {/* END_USER_CODE-USER_AFTER_lblStates*/}
                  {/* START_USER_CODE-USER_BEFORE_gridStates*/}

                  {/* END_USER_CODE-USER_BEFORE_gridStates*/}

                  <GridWidget
                    conf={state.gridStates}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridStates}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    setFieldValue={setFieldValue}
                    refObject={thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridStates*/}

                  {/* END_USER_CODE-USER_AFTER_gridStates*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveStates*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveStates*/}

                  <ButtonWidget
                    conf={state.btnSaveStates}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveStates*/}

                  {/* END_USER_CODE-USER_AFTER_btnSaveStates*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxState*/}

                {/* END_USER_CODE-USER_AFTER_grpbxState*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCounty*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCounty*/}

                <GroupBoxWidget conf={state.grpbxCounty} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblAkkCounties*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAkkCounties*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAkkCounties}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAkkCounties*/}

                  {/* END_USER_CODE-USER_AFTER_lblAkkCounties*/}
                  {/* START_USER_CODE-USER_BEFORE_gridCounties*/}

                  {/* END_USER_CODE-USER_BEFORE_gridCounties*/}

                  <GridWidget
                    conf={state.gridCounties}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridCounties}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    setFieldValue={setFieldValue}
                    refObject={thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridCounties*/}

                  {/* END_USER_CODE-USER_AFTER_gridCounties*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveCounty*/}

                  <ButtonWidget
                    conf={state.btnSaveCounty}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveCounty*/}

                  {/* END_USER_CODE-USER_AFTER_btnSaveCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCounty*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCounty*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxStateCounty*/}

              {/* END_USER_CODE-USER_AFTER_grpbxStateCounty*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxPnutVrty*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPnutVrty*/}

              <GroupBoxWidget conf={state.grpbxPnutVrty} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblPnutVrtyFiltr*/}

                {/* END_USER_CODE-USER_BEFORE_lblPnutVrtyFiltr*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPnutVrtyFiltr}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPnutVrtyFiltr*/}

                {/* END_USER_CODE-USER_AFTER_lblPnutVrtyFiltr*/}
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPt*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPt*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPt}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPt*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPt*/}
                {/* START_USER_CODE-USER_BEFORE_ddPnutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPnutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPnutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPnutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPnutType*/}
                {/* START_USER_CODE-USER_BEFORE_lblSelect*/}

                {/* END_USER_CODE-USER_BEFORE_lblSelect*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSelect}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSelect*/}

                {/* END_USER_CODE-USER_AFTER_lblSelect*/}
                {/* START_USER_CODE-USER_BEFORE_btnSrch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSrch*/}

                <ButtonWidget
                  conf={state.btnSrch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSrch*/}

                {/* END_USER_CODE-USER_AFTER_btnSrch*/}
                {/* START_USER_CODE-USER_BEFORE_gridPnutFilter*/}

                {/* END_USER_CODE-USER_BEFORE_gridPnutFilter*/}

                <GridWidget
                  conf={state.gridPnutFilter}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPnutFilter}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPnutFilter*/}

                {/* END_USER_CODE-USER_AFTER_gridPnutFilter*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPnutVrty*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPnutVrty*/}
              {/* START_USER_CODE-USER_BEFORE_commandcontainerwidget42*/}

              {/* END_USER_CODE-USER_BEFORE_commandcontainerwidget42*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.commandcontainerwidget42}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_commandcontainerwidget42*/}

              {/* END_USER_CODE-USER_AFTER_commandcontainerwidget42*/}

              {/* START_USER_CODE-USER_AFTER_Filters*/}

              {/* END_USER_CODE-USER_AFTER_Filters*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_Filters);
