/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue, getValue,
  setData, getData
} from "../../shared/WindowImports";

import "./TradeSettlePaymentView.scss";
import * as XLSX from 'xlsx/xlsx.mjs';
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import moment from "moment";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
//import { RetrieveTradeSettlementPaymentDetails, SettlementService } from "../Service/SettlementService";
// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettlePaymentView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeSettlePaymentView",
    windowName: "TradeSettlePaymentView",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettlePaymentView",
    // START_USER_CODE-USER_TradeSettlePaymentView_PROPERTIES
    headerData: {
      scrName: "Payment History",
      scrCode: "PN1140I",
    },
    // END_USER_CODE-USER_TradeSettlePaymentView_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportExcel: {
      name: "btnExportExcel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExportExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colByngPt: {
      name: "colByngPt",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colByngPt_PROPERTIES

      // END_USER_CODE-USER_colByngPt_PROPERTIES
    },
    colChkNm: {
      name: "colChkNm",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Check Num",
      // isStatusColumn: true,
      // Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChkNm_PROPERTIES

      // END_USER_CODE-USER_colChkNm_PROPERTIES
    },
    colCleardDt: {
      name: "colCleardDt",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Cleared Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCleardDt_PROPERTIES

      // END_USER_CODE-USER_colCleardDt_PROPERTIES
    },
    colIntrstAgreemnt: {
      name: "colIntrstAgreemnt",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Interest Agreement",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIntrstAgreemnt_PROPERTIES

      // END_USER_CODE-USER_colIntrstAgreemnt_PROPERTIES
    },
    colIssueDt: {
      name: "colIssueDt",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Issued Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIssueDt_PROPERTIES

      // END_USER_CODE-USER_colIssueDt_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colSc95: {
      name: "colSc95",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "SC95 #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSc95_PROPERTIES

      // END_USER_CODE-USER_colSc95_PROPERTIES
    },
    colSpclTyp: {
      name: "colSpclTyp",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Special Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSpclTyp_PROPERTIES

      // END_USER_CODE-USER_colSpclTyp_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colVoidDt: {
      name: "colVoidDt",
      type: "GridColumnWidget",
      parent: "gridPaymentView",
      Label: "Void Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVoidDt_PROPERTIES

      // END_USER_CODE-USER_colVoidDt_PROPERTIES
    },
    gridPaymentView: {
      name: "gridPaymentView",
      type: "GridWidget",
      parent: "grpbxViewPayment",
      gridCellsOrder:
        "txtcolChkNm,txtcolVendor,txtcolStatus,txtcolProceeds,txtcolIntrstAgreemnt,txtcolSpclTyp,txtcolIssueDt,txtcolCleardDt,txtcolVoidDt,txtcolByngPt,txtcolSc95",
      Pagination: false,
      isEditable: false,
      HasLabel: false,
      Cols: "20",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPaymentView_PROPERTIES

      // END_USER_CODE-USER_gridPaymentView_PROPERTIES
    },
    lblBuyPtId: {
      name: "lblBuyPtId",
      type: "LabelWidget",
      parent: "grpbxViewPayment",
      Label: "lblBuyPtId",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyPtId_PROPERTIES

      // END_USER_CODE-USER_lblBuyPtId_PROPERTIES
    },
    lblInspNum: {
      name: "lblInspNum",
      type: "LabelWidget",
      parent: "grpbxViewPayment",
      Label: "lblInspNum",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInspNum_PROPERTIES

      // END_USER_CODE-USER_lblInspNum_PROPERTIES
    },
    lblPaymnt: {
      name: "lblPaymnt",
      type: "LabelWidget",
      parent: "grpbxViewPayment",
      Label: "Payments",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPaymnt_PROPERTIES

      // END_USER_CODE-USER_lblPaymnt_PROPERTIES
    },
    txtcolByngPt: {
      name: "txtcolByngPt",
      type: "TextBoxWidget",
      colName: "colByngPt",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolByngPt_PROPERTIES

      // END_USER_CODE-USER_txtcolByngPt_PROPERTIES
    },
    txtcolChkNm: {
      name: "txtcolChkNm",
      type: "TextBoxWidget",
      colName: "colChkNm",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChkNm_PROPERTIES

      // END_USER_CODE-USER_txtcolChkNm_PROPERTIES
    },
    txtcolCleardDt: {
      name: "txtcolCleardDt",
      type: "TextBoxWidget",
      colName: "colCleardDt",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCleardDt_PROPERTIES

      // END_USER_CODE-USER_txtcolCleardDt_PROPERTIES
    },
    txtcolIntrstAgreemnt: {
      name: "txtcolIntrstAgreemnt",
      type: "TextBoxWidget",
      colName: "colIntrstAgreemnt",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIntrstAgreemnt_PROPERTIES

      // END_USER_CODE-USER_txtcolIntrstAgreemnt_PROPERTIES
    },
    txtcolIssueDt: {
      name: "txtcolIssueDt",
      type: "TextBoxWidget",
      colName: "colIssueDt",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIssueDt_PROPERTIES

      // END_USER_CODE-USER_txtcolIssueDt_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolSc95: {
      name: "txtcolSc95",
      type: "TextBoxWidget",
      colName: "colSc95",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSc95_PROPERTIES

      // END_USER_CODE-USER_txtcolSc95_PROPERTIES
    },
    txtcolSpclTyp: {
      name: "txtcolSpclTyp",
      type: "TextBoxWidget",
      colName: "colSpclTyp",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSpclTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolSpclTyp_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolVoidDt: {
      name: "txtcolVoidDt",
      type: "TextBoxWidget",
      colName: "colVoidDt",
      parent: "gridPaymentView",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVoidDt_PROPERTIES

      // END_USER_CODE-USER_txtcolVoidDt_PROPERTIES
    },
    grpbxViewPayment: {
      name: "grpbxViewPayment",
      type: "GroupBoxWidget",
      parent: "TradeSettlePaymentView",
      Height: "",
      Width: "",
      clonedExtId: "28885",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewPayment_PROPERTIES

      // END_USER_CODE-USER_grpbxViewPayment_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TradeSettlePaymentView",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      "ContractManagement#MainMenu": {},
      REVERSE: {},
      "ContractManagement#MainMenu": {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnClose: {
    //   DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    // }, 

  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  function FormLoad() {

    GetTradeSettlePaymentView();
  }

  // Method for getting View Payment details
  const GetTradeSettlePaymentView = async () => {
    let PaymentsData = getData(thisObj, "ReviewPaymentsFormLoadData");
    let tmpdata = []
    let tmpdata2 = []
    let buy_pt_id = PaymentsData.buy_point_id
    let TradeInspNum = PaymentsData.txtTradeInspNum
    let audit_delv_agree_num = null
    let check_num = null, audit_settle_num = null, audit_cont_num = null, audit_insp_num = null, audit_trade_settle = null, frt_accrual_ind = null, payee_vendor = null, payee_remit = null, issued_date = null
    try {
      if (buy_pt_id !== '' && buy_pt_id !== undefined && TradeInspNum !== '' && TradeInspNum !== undefined) {
        let readyToPay = await SettlementService.RetrieveTradeSettlementPaymentDetails(buy_pt_id, TradeInspNum, audit_delv_agree_num)

        if (readyToPay.length > 0 || readyToPay !== undefined) {
          for (let i = 0; i < readyToPay.length; i++) {
            let Obj = {}
            Obj.txtcolChkNm = "R-T-P"
            Obj.txtcolVendor = readyToPay[i].payee_vendor
            Obj.txtcolStatus = "Unpaid"
            Obj.txtcolProceeds = readyToPay[i].rtp_proceeds
            Obj.txtcolIntrstAgreemnt = readyToPay[i].intr_agree_num
            Obj.txtcolSpclTyp = readyToPay[i].special_type
            Obj.txtcolIssueDt = ""
            Obj.txtcolCleardDt = ""
            Obj.txtcolVoidDt = ""
            Obj.txtcolByngPt = buy_pt_id
            Obj.txtcolSc95 = TradeInspNum
            Obj.rowID$ = i;
            tmpdata.push(Obj);
          }
        }

        setValue(thisObj, "gridPaymentView", tmpdata)

        let paymentActivity = await ContractManagementService.RetrievePaymentActivityDetails(buy_pt_id, check_num, audit_settle_num, audit_cont_num, audit_delv_agree_num, TradeInspNum, audit_insp_num, audit_trade_settle, frt_accrual_ind, payee_vendor, payee_remit, issued_date)
        if (paymentActivity.length > 0) {
          for (let i = 0; i < paymentActivity.length; i++) {
            let Obj = {}
            Obj.txtcolChkNm = formatCheckNumber(paymentActivity[i].check_num)
            Obj.txtcolVendor = paymentActivity[i].payeeVendor
            switch (paymentActivity[i].checkStatus.trim()) {
              case "I":
                Obj.txtcolStatus = "Issued"
                break;
              case "R":
                Obj.txtcolStatus = "Issued"
                break;
              case "V":
                Obj.txtcolStatus = "Voided"
                break;
              case "C":
                Obj.txtcolStatus = "Cleared"
                break;

              default: null
                break;
            }
            Obj.txtcolProceeds = paymentActivity[i].checkProceeds
            Obj.txtcolIntrstAgreemnt = paymentActivity[i].intrAgreeNum
            Obj.txtcolSpclTyp = paymentActivity[i].specialType
            Obj.txtcolIssueDt = (paymentActivity[i].issuedDate == null || paymentActivity[i].issuedDate == undefined) ? "" : moment(paymentActivity[i].issuedDate).format('MM/DD/YYYY')
            Obj.txtcolCleardDt = (paymentActivity[i].clearDate == null || paymentActivity[i].clearDate == undefined) ? "" : moment(paymentActivity[i].clearDate).format('MM/DD/YYYY')
            Obj.txtcolVoidDt = (paymentActivity[i].voidDate == null || paymentActivity[i].voidDate == undefined) ? "" : moment(paymentActivity[i].voidDate).format('MM/DD/YYYY')
            Obj.txtcolByngPt = buy_pt_id
            Obj.txtcolSc95 = TradeInspNum
            Obj.rowID$ = i;
            tmpdata.push(Obj)
          }
        }
        setValue(thisObj, "gridPaymentView", tmpdata)
        thisObj.setState(current => {
          return {
            ...current,
            rows:
              tmpdata
          }
        });



      }
      else {
        showMessage(thisObj, "Data not found")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for GetTradeSettlePaymentView event:Formload"
        );
      }
      return false;
    }
    return true;
  };

  //FormatcheckNumber
  function formatCheckNumber(LstrCheckNumber) {
    try {
      if (LstrCheckNumber.includes("S")) {
        LstrCheckNumber = LstrCheckNumber.trim().substring(1);
        return "S" + "0".repeat(5 - LstrCheckNumber.length) + LstrCheckNumber.trim();
      } else {
        return "0".repeat(6 - LstrCheckNumber.trim().length) + LstrCheckNumber.trim();
      }
    } catch (error) {
      // Handle error if needed
      return null;
    }
  }
  // Export to excel functionality
  const onbtnExportExcelClick = async () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridPaymentView")
      let PaymentsData = getData(thisObj, "ReviewPaymentsFormLoadData")
      let buy_pt_id = PaymentsData.buy_point_id
      let TradeInspNum = PaymentsData.txtTradeInspNum
      if (excelData !== null || excelData !== undefined || excelData !== '') {
        var fileName = "Payment History TradeSettlement " + buy_pt_id + TradeInspNum
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Chek Num": excelData[i].txtcolChkNm,
            "Vendor": excelData[i].txtcolVendor,
            "Status": excelData[i].txtcolStatus,
            "Proceeds": ExcelNumericDataConvert(excelData[i].txtcolProceeds),
            "Interest Agreement": excelData[i].txtcolIntrstAgreemnt,
            "Special Type": excelData[i].txtcolSpclTyp,
            "Issued Date": excelData[i].txtcolIssueDt,
            "Cleared Date": excelData[i].txtcolCleardDt,
            "Void Date": excelData[i].txtcolVoidDt,
            "Buying Point": excelData[i].txtcolByngPt,
            "SC95# ": excelData[i].txtcolSc95
          }
          arrayData.push(obj);
        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      else { showMessage(thisObj, "No Data to Export") }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExportExcelClick event:Click"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportExcelClick = onbtnExportExcelClick;


  // Print Button Functionality
  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridPaymentView")
      if (data != null && data != undefined) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          res.push([
            data[i].txtcolChkNm, data[i].txtcolVendor, data[i].txtcolStatus, data[i].txtcolProceeds, data[i].txtcolIntrstAgreemnt, data[i].txtcolSpclTyp, data[i].txtcolIssueDt, data[i].txtcolCleardDt, data[i].txtcolVoidDt, data[i].txtcolByngPt, data[i].txtcolSc95
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Check Num', 'Vendor', 'Status', 'Proceeds', 'Interest Agreement', 'Special Type', 'Issued Date', 'Cleared Date', 'Void Date', 'Buying Point', 'SC95#'
            ]
          ]
        });
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Enter Filename")
          }
        }
        else {
          alert("Enter Filename")
        }
      } else {
        showMessage(thisObj, "No Data to Print")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("Settlements_TradeSettlePaymentViewPopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeSettlePaymentView*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettlePaymentView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewPayment*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewPayment*/}

              <GroupBoxWidget conf={state.grpbxViewPayment} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblPaymnt*/}

                {/* END_USER_CODE-USER_BEFORE_lblPaymnt*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPaymnt}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPaymnt*/}

                {/* END_USER_CODE-USER_AFTER_lblPaymnt*/}
                {/* START_USER_CODE-USER_BEFORE_lblInspNum*/}

                {/* END_USER_CODE-USER_BEFORE_lblInspNum*/}

                <LabelWidget
                  values={values}
                  conf={state.lblInspNum}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblInspNum*/}

                {/* END_USER_CODE-USER_AFTER_lblInspNum*/}
                {/* START_USER_CODE-USER_BEFORE_lblBuyPtId*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyPtId*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyPtId}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyPtId*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyPtId*/}
                {/* START_USER_CODE-USER_BEFORE_gridPaymentView*/}

                {/* END_USER_CODE-USER_BEFORE_gridPaymentView*/}

                <GridWidget
                  conf={state.gridPaymentView}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPaymentView}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPaymentView*/}

                {/* END_USER_CODE-USER_AFTER_gridPaymentView*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewPayment*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewPayment*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnSettings*/}

                {/* END_USER_CODE-USER_BEFORE_btnSettings*/}

                {/* START_USER_CODE-USER_AFTER_btnSettings*/}

                {/* END_USER_CODE-USER_AFTER_btnSettings*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportExcel*/}

                <ButtonWidget
                  conf={state.btnExportExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportExcel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettlePaymentView*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettlePaymentView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettlePaymentView);
