/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext} from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  LabelWidget,
  setValue,
  LinkWidget,
  goTo,
  getData
} from "../shared/WindowImports";

import "./Header.scss";
import { ContractManagementService } from "../ContractManagement/Service/ContractManagementService";
// START_USER_CODE-USER_IMPORTS
import logo from "../../../assets/img/Logo.png";
import CommonContext from '../Store/CommonContext';
// END_USER_CODE-USER_IMPORTS

function ContractManagement_header(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
 // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "header",
    windowName: "header",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.header",
    // START_USER_CODE-USER_header_PROPERTIES

    // END_USER_CODE-USER_header_PROPERTIES
    // lblGoldnPnutCmpny: {
    //   name: "lblGoldnPnutCmpny",
    //   type: "LabelWidget",
    //   parent: "grpbxHeader",
    //   Label: "Golden Peanut Company",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lblGoldnPnutCmpny_PROPERTIES

    //   // END_USER_CODE-USER_lblGoldnPnutCmpny_PROPERTIES
    // },
    lnkGoldnPnutCmpny: {
      name: "lnkGoldnPnutCmpny",
      type: "LinkWidget",
      parent: "grpbxHeader",
      Label: "Golden Peanut Company, LLC",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_linkwidget13_PROPERTIES

      // END_USER_CODE-USER_linkwidget13_PROPERTIES
    },
    txtScrName: {
      name: "txtScrName",
      type: "TextBoxWidget",
      parent: "grpbxHeader",
      // HasLabel: false,
      Label:'Page Title:',
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtScrName_PROPERTIES
      value: props.headerData.scrName,
      Enabled: false,
      // END_USER_CODE-USER_txtScrName_PROPERTIES
    },
    // txtScrNmbr: {
    //   name: "txtScrNmbr",
    //   type: "TextBoxWidget",
    //   parent: "grpbxHeader",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtScrNmbr_PROPERTIES
    //   value: props.headerData.scrCode,
    //   Enabled: false,
    //   // END_USER_CODE-USER_txtScrNmbr_PROPERTIES
    // },
    lblLnkScrNmbr: {
      name: "lblLnkScrNmbr",
      type: "LabelWidget",
      parent: "grpbxHeader",
      Label: "Page ID::",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lnkScrNmbr: {
      name: "lnkScrNmbr",
      type: "LinkWidget",
      parent: "grpbxHeader",
      // HasLabel: false,
      Label:'Page ID:',
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_linkwidget13_PROPERTIES
      value: props.headerData.scrCode,
      // Enabled: false,
      // END_USER_CODE-USER_linkwidget13_PROPERTIES
    },
    // txtYear: {
    //   name: "txtYear",
    //   type: "TextBoxWidget",
    //   parent: "grpbxHeader",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtYear_PROPERTIES
    //   value: '',
    //   Enabled: false,
    //   // END_USER_CODE-USER_txtYear_PROPERTIES
    // },
    lblLnkYear: {
      name: "lblLnkYear",
      type: "LabelWidget",
      parent: "grpbxHeader",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lnkYear: {
      name: "lnkYear",
      type: "LinkWidget",
      parent: "grpbxHeader",
      // HasLabel: false,
      Label:'Crop Year:',
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_linkwidget13_PROPERTIES
      // value: props.headerDetails.year,
      // Enabled: false,
      // END_USER_CODE-USER_linkwidget13_PROPERTIES
    },
    lnkClose: {
      name: "lnkClose",
      type: "LinkWidget",
      parent: "grpbxHeader",
      HasLabel: true,
      Label:"X",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_linkwidget13_PROPERTIES
     
      // END_USER_CODE-USER_linkwidget13_PROPERTIES
    },
    grpbxHeader: {
      name: "grpbxHeader",
      type: "GroupBoxWidget",
      parent: "header",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxHeader_PROPERTIES

      // END_USER_CODE-USER_grpbxHeader_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [currentScreen,setCurrentScreen] = useState('');
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    //callOnOpenService(thisObj, screenRef);
    setValue(thisObj,"lnkYear", JSON.parse(sessionStorage.getItem('year')));
    //setValue(thisObj,"lnkGoldnPnutCmpny", JSON.parse(sessionStorage.getItem('Companyname')));
  }, [props]);
  useEffect(()=>{
    setValue(thisObj,"txtScrName",props.headerData.scrName)
    setCurrentScreen(props.headerData.scrName)
  },[props.headerData.scrName])

  // const onlnkScrNmbrClick=()=>{
  //   prompt("password")
  // }
  // thisObj.onlnkScrNmbrClick=onlnkScrNmbrClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;

  const onlnkGoldnPnutCmpnyClick = () => {
    ContractManagementService.RetrieveCompanyStats().then(response => {
      let data = response;
      let finalmsg = "";
      if (data.length > 0)
      {
        let stats = JSON.stringify(data[0]);
        const stats_array = stats.split(',');
        for (let i = 0; i < stats_array.length; i++) {
          const statsElemt = stats_array[i];
          const newstatsElemt = statsElemt.replace(":"," = ");
          const newstatsElemt1 = newstatsElemt.replace(/['"]+/g, ''); //remove quotes
          finalmsg = finalmsg + '\n' + newstatsElemt1;
        }
        finalmsg = finalmsg.slice(2, finalmsg.length-1); // remove { } begin and end places
        alert(finalmsg);
        //showMessage(thisObj,finalmsg);
      }
      return true;        
    });
  };
  thisObj.onlnkGoldnPnutCmpnyClick = onlnkGoldnPnutCmpnyClick;

  const onlnkCloseClick = () => {
    if(currentScreen=="Contract Profile" && window.location.href==window.location.origin+'/'+'#/ContractManagement_ContractInquiry'){
      let formediting = commonContext.getFormdirty_ContractProfile
    if (formediting) {
      let boolval = window.confirm("Changes have not been saved!  Do you want to continue without saving?")
      if (boolval == false) {
        return false;
      }
      else{
        //goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        document.getElementsByClassName("close")[0].click();
        //history.push('/ContractManagement_ContractInquiry');
        //return true;
      }
    }
    else{
      document.getElementsByClassName("close")[0].click();
    }
    }
    if ((currentScreen=="Farm Profile" && window.location.href==window.location.origin+'/'+'#/SystemMaintenanceMasterManagement_FarmInquiry') || (currentScreen=="Farm Profile" && window.location.href==window.location.origin+'/'+'#/Settlements_FV95Search')){
      let valueList = getData(thisObj,"lstNullOrNot")
      let dataOnAddButton = getData(thisObj,"AddbtnCross")
      if(dataOnAddButton == "freshdata"){
        if(props.thisObj){
          handleModalHide(props.thisObj)
        }else{
        document.getElementsByClassName("close")[0].click();
        }
      }
      else
      if( valueList.length>=1 && valueList[0].key != undefined && valueList[0].sharePercent != undefined){
        let DSum = 0
        for (var i = 0; i < valueList.length; i++) {
          DSum = DSum + Number(valueList[i].sharePercent)
        }
        if (Number(DSum) != 100) {
          if (Number(DSum) > 100) {
            alert("Split percentages total " + DSum + " is more than 100.")
            return
          }
          else {
            alert("Split percentages total " + DSum + " is less than 100.")
            return
          }
        }
        else{
          if(props.thisObj){
            handleModalHide(props.thisObj)
          }else{
          document.getElementsByClassName("close")[0].click();
          }
        }
      }
      else{
        alert("Please add a vendor first.")
        return
      }
    }
    if(currentScreen=="Price Schedule Setup" && window.location.href==window.location.origin+'/'+'#/SystemMaintenanceSpecialFunctions_SpecialFunctions'){
      let formediting = getData(thisObj,'mbFormDirty')
    if (formediting) {
      let boolval = window.confirm("This form contains unsaved data. Some data may be lost. Continue?")
      if (boolval == false) {
        return false;
      }
      else{
        //goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        document.getElementsByClassName("close")[0].click();
        //history.push('/ContractManagement_ContractInquiry');
        //return true;
      }
    }
    else{
      document.getElementsByClassName("close")[0].click();
    }
    }

    if(currentScreen=="Stock Transfer" && window.location.href==window.location.origin+'/'+'#/StockTransfer_TransferSearch'){
      //setData(thisObj, "closingflag_stocktransfer", true)
      commonContext.setStockTransfer_crossClick(true)
    }

    if(currentScreen=="Contract Price Limit Buy Pt Details" && window.location.href==window.location.origin+'/'+'#/SystemMaintenanceMasterManagement_ContractLimitSearch'){  //custom cross button Fixed As Part of Prod Issue Fix INC5626542 
      commonContext.setStockTransfer_crossClick(true)
    }
  };
  thisObj.onlnkCloseClick = onlnkCloseClick;

  // const onlnkYearClick = () => { 
  //   let cropYearMsg = ''; 
  //   let today = new Date(); 
  //   let currdate = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear(); 
  //   let cropyear = sessionStorage.getItem('year'); 
  //   let endYear = Number((sessionStorage.getItem('year'))) + 1; 
  //   cropYearMsg = 'Crop Year : ' + cropyear + '\n' + '\n' + 'Start Date : 6/30/' + cropyear + '\n' + 'End Date  : 7/31/' + endYear + '\n' + '\n' + ' Current Date : ' + currdate; 
  //   alert(cropYearMsg);
  // };
  //  thisObj.onlnkYearClick = onlnkYearClick;

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidthHeader"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_header*/}

              {/* END_USER_CODE-USER_BEFORE_header*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxHeader*/}
              
              {/* END_USER_CODE-USER_BEFORE_grpbxHeader*/}

              <GroupBoxWidget conf={state.grpbxHeader} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtScrNmbr*/}
                <div className="LogoImage">
                <img
                  src={logo}
                  width="90"
                  height="90"
                  className="d-inline-block align-top"
                  alt="Golden Peanut Company"
                />
                <span className="environment-title">{process.env.REACT_APP_ENVIR + ' Environment'}</span>

              </div>
                {/* END_USER_CODE-USER_BEFORE_txtScrNmbr*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtScrNmbr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                <div className="grpbxWithoutLogo">
                <div className="headerGrpbx">
                <LinkWidget
                  conf={state.lnkGoldnPnutCmpny}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LinkWidget> 
                <div className="lblValueDiv">
                <LabelWidget
                  values={values}
                  conf={state.lblLnkYear}
                  screenConf={state}
                ></LabelWidget>
                <LinkWidget
                  conf={state.lnkYear}
                  screenConf={state}
                  onClick={event => invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LinkWidget>
                </div>
                <div className="lblValueDiv">
                <LabelWidget
                  values={values}
                  conf={state.lblLnkScrNmbr}
                  screenConf={state}
                ></LabelWidget>
                <LinkWidget
                  conf={state.lnkScrNmbr}
                  screenConf={state}
                  onClick={event => invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LinkWidget>
                </div>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtScrName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtScrNmbr*/}

                {/* END_USER_CODE-USER_AFTER_txtScrNmbr*/}
                {/* START_USER_CODE-USER_BEFORE_lblGoldnPnutCmpny*/}

                {/* END_USER_CODE-USER_BEFORE_lblGoldnPnutCmpny*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblGoldnPnutCmpny}
                  screenConf={state}
                ></LabelWidget> */}
                 
                {/* START_USER_CODE-USER_AFTER_lblGoldnPnutCmpny*/}

                {/* END_USER_CODE-USER_AFTER_lblGoldnPnutCmpny*/}
                {/* START_USER_CODE-USER_BEFORE_txtYear*/}

                {/* END_USER_CODE-USER_BEFORE_txtYear*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtYear}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                
                {/* START_USER_CODE-USER_AFTER_txtYear*/}
                
                {/* END_USER_CODE-USER_AFTER_txtYear*/}
                {/* START_USER_CODE-USER_BEFORE_txtScrName*/}

                {/* END_USER_CODE-USER_BEFORE_txtScrName*/}
                
                </div>
                
                
                {/* START_USER_CODE-USER_AFTER_txtScrName*/}
                </div>
                {(currentScreen=="Contract Profile" && window.location.href==window.location.origin+'/'+'#/ContractManagement_ContractInquiry') || ((currentScreen=="Farm Profile" && window.location.href==window.location.origin+'/'+'#/SystemMaintenanceMasterManagement_FarmInquiry') || (currentScreen=="Farm Profile" && window.location.href==window.location.origin+'/'+'#/Settlements_FV95Search')) || (currentScreen=="Price Schedule Setup" && window.location.href==window.location.origin+'/'+'#/SystemMaintenanceSpecialFunctions_SpecialFunctions')
                 || (currentScreen=="Stock Transfer" && window.location.href==window.location.origin+'/'+'#/StockTransfer_TransferSearch') || (currentScreen=="Contract Price Limit Buy Pt Details" && window.location.href==window.location.origin+'/'+'#/SystemMaintenanceMasterManagement_ContractLimitSearch')?
                <LinkWidget
                  conf={state.lnkClose}
                  screenConf={state}
                  onClick={event => invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LinkWidget>:<></>}
                {/* END_USER_CODE-USER_AFTER_txtScrName*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxHeader*/}

              {/* END_USER_CODE-USER_AFTER_grpbxHeader*/}

              {/* START_USER_CODE-USER_AFTER_header*/}

              {/* END_USER_CODE-USER_AFTER_header*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_header);
