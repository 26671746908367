/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  RadioButtonGroupWidget,
  getValue,
  setValue,
  setData,
  getData,
  disable
} from "../../shared/WindowImports";

import "./DeliveryPeriodProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { AcroFormChoiceField } from "jspdf";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_DeliveryPeriodProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DeliveryPeriodProfile",
    windowName: "DeliveryPeriodProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.DeliveryPeriodProfile",
    // START_USER_CODE-USER_DeliveryPeriodProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Period Profile",
      scrCode: "PN0020",
    },
    // END_USER_CODE-USER_DeliveryPeriodProfile_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    txtDeliveryPeriod: {
      name: "txtDeliveryPeriod",
      type: "TextBoxWidget",
      parent: "grpbxDeliveryPeriodProfile",
      Label: "Delivery Period:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryPeriod_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryPeriod_PROPERTIES
    },
    radioActiveStatus: {
      name: "radioActiveStatus",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxDeliveryPeriodProfile",
      Label: "Active Status",
      
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioActiveStatus_PROPERTIES

      // END_USER_CODE-USER_radioActiveStatus_PROPERTIES
    },
    txtArea: {
      name: "txtArea",
      type: "TextBoxWidget",
      parent: "grpbxDeliveryPeriodProfile",
      Label: "Area:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtArea_PROPERTIES

      // END_USER_CODE-USER_txtArea_PROPERTIES
    },
    txtDeliveryId: {
      name: "txtDeliveryId",
      type: "TextBoxWidget",
      parent: "grpbxDeliveryPeriodProfile",
      Label: "Delivery Id:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryId_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryId_PROPERTIES
    },
    grpbxDeliveryPeriodProfile: {
      name: "grpbxDeliveryPeriodProfile",
      type: "GroupBoxWidget",
      parent: "DeliveryPeriodProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDeliveryPeriodProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxDeliveryPeriodProfile_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DeliveryPeriodProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  
  // START_USER_CODE-USER_METHODS

  //FormLoad function
  const FormLoad=()=>{
    let profileData=getData(thisObj,'frmDeliveryPeriodProfile')
    let action_type=profileData.cmdSave_Caption;
    if(action_type == 'Add'){
      disable(thisObj, 'txtDeliveryId');
      setValue(thisObj,'txtArea',profileData.lblArea)
      disable(thisObj, 'txtArea');
      document.getElementById("txtDeliveryPeriod").focus();
      document.getElementById('btnSave').innerText = "Add"
    }
    else if(action_type == 'Update'){
      disable(thisObj, 'txtDeliveryId');
      disable(thisObj, 'txtArea');
      document.getElementById("txtDeliveryPeriod").focus();
      setValue(thisObj,'txtDeliveryId',profileData.lblDeliveryId)
      setValue(thisObj,'txtArea',profileData.lblArea)
      setValue(thisObj,'txtDeliveryPeriod',profileData.txtdelivery_period)
      let active_ind1 = profileData.active_ind;
      let active_ind=(active_ind1 == true? '1' : '2');
      setValue(thisObj,'radioActiveStatus',active_ind)
      document.getElementById('btnSave').innerText = "Update"
    }
  }
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  //Save button click event
  const onbtnSaveClick = async () => {
   try {
    if (FormValid() == false) {
      return
    }
    let profileData=getData(thisObj,'frmDeliveryPeriodProfile')
    let action_type=profileData.cmdSave_Caption;
    let active_ind1 = getValue(thisObj, 'radioActiveStatus');
    const active_ind=(active_ind1 === '1'? 'Y' : 'N');
    let user_id = useridFromLS();
    let area_id = getValue(thisObj, 'txtArea');
    let delivery_id = getValue(thisObj, 'txtDeliveryId');
    let delivery_period = getValue(thisObj, 'txtDeliveryPeriod')
    if(action_type == 'Add' &&  document.getElementById('btnSave').innerText =="Add"){
      let data = {
      'delivery_period': delivery_period,
      'active_ind': active_ind,
      'user_id': user_id
      }
      let response = await SystemMaintenanceMasterManagementService.UpdateDeliveryPeriodControls(area_id, action_type, delivery_id, data);
      if (![200, 400, 404].includes(response.status)) {
        alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
      }
      
      else if(response.status==200 &&  document.getElementById('btnSave').innerText =="Add"){
        alert('Delivery Period Profile add successful!')
        document.getElementById('btnSave').innerText = "Update"
        setData(thisObj,'closingOfDeliveryprofile',data);
      }
    }
    else if(action_type == 'Add' && document.getElementById('btnSave').innerText =="Update"){
      alert('Delivery Period Profile update successful!')
    }
    else if(action_type == 'Update'){
      let data = {
        'delivery_period': delivery_period,
        'active_ind': active_ind,
        'user_id': user_id
        }
      let response= await SystemMaintenanceMasterManagementService.UpdateDeliveryPeriodControls(area_id, action_type, delivery_id, data);
      if (![200, 400, 404].includes(response.status)) {
        alert('An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
      }
      else if(response.status==200){
        alert('Delivery Period Profile update successful!')
        setData(thisObj,'closingOfDeliveryprofile',data);
      }
    }
   }
   catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btn event:Click"
      );
    }
    return false;
   }
  return true;
  }
  thisObj.onbtnSaveClick = onbtnSaveClick;

  // Event for exit button
  const onbtnExitClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_DeliveryPeriodProfilePopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnExit event:Click");
      }
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;
  
  //formValid for validations
  const FormValid = () => {
    var bFormValid = false
    disable(thisObj, 'txtArea');
    let areaId = getValue(thisObj, 'txtArea');
    let deliveryPeriodProfile = getValue(thisObj, 'txtDeliveryPeriod');
    let activeInd = getValue(thisObj, 'radioActiveStatus');
    if (areaId == null || areaId == '' || areaId == undefined) {
      showMessage(thisObj, "Area ID cannot be empty")
      return bFormValid;
    }
    if (deliveryPeriodProfile == null || deliveryPeriodProfile == '' || deliveryPeriodProfile == undefined) {
      showMessage(thisObj, "Delivery Period description cannot be empty")
      document.getElementById("txtDeliveryPeriod").focus();
      return bFormValid;
    }

    if (activeInd == undefined) {
      showMessage(thisObj, 'Incorrect Data. The Active status must be selected!');
      const radioButtons=document.getElementsByName("radioActiveStatus")
      if(radioButtons.length >0){
        radioButtons[0].focus();
        return bFormValid;
      }
      
    }
    bFormValid=true
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;

        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DeliveryPeriodProfile*/}

              {/* END_USER_CODE-USER_BEFORE_DeliveryPeriodProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDeliveryPeriodProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDeliveryPeriodProfile*/}

              <GroupBoxWidget
                conf={state.grpbxDeliveryPeriodProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryId*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryId*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryId}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryId*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryId*/}
                {/* START_USER_CODE-USER_BEFORE_txtArea*/}

                {/* END_USER_CODE-USER_BEFORE_txtArea*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtArea}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtArea*/}

                {/* END_USER_CODE-USER_AFTER_txtArea*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryPeriod*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryPeriod*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryPeriod}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryPeriod*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryPeriod*/}
                {/* START_USER_CODE-USER_BEFORE_radioActiveStatus*/}

                {/* END_USER_CODE-USER_BEFORE_radioActiveStatus*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioActiveStatus}
                  screenConf={state}
                  
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioActiveStatus*/}

                {/* END_USER_CODE-USER_AFTER_radioActiveStatus*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDeliveryPeriodProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDeliveryPeriodProfile*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DeliveryPeriodProfile*/}

              {/* END_USER_CODE-USER_AFTER_DeliveryPeriodProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_DeliveryPeriodProfile
);
