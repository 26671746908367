/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setData,
  setValue,
  getValue,
  getData,
  goTo,
  enable,
  disable,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./MarketPriceSetup.scss";
import Loading from "../../../Loader/Loading";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_MarketPriceSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "MarketPriceSetup",
    windowName: "MarketPriceSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.MarketPriceSetup",
    // START_USER_CODE-USER_MarketPriceSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Market Price Setup",
      scrCode: "PN0280A",
    },
    // END_USER_CODE-USER_MarketPriceSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxMarketPriceSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxMarketPriceSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxMarketPriceSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxMarketPriceSetup",
      Label: "Peanut Type:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    lblMarketPrice: {
      name: "lblMarketPrice",
      type: "LabelWidget",
      parent: "grpbxMarketPriceSetup",
      Label: "Market Price:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMarketPrice_PROPERTIES

      // END_USER_CODE-USER_lblMarketPrice_PROPERTIES
    },
    lstMarketPrice: {
      name: "lstMarketPrice",
      type: "ListBoxFieldWidget",
      parent: "grpbxMarketPriceSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstMarketPrice_PROPERTIES

      // END_USER_CODE-USER_lstMarketPrice_PROPERTIES
    },
    grpbxMarketPriceSetup: {
      name: "grpbxMarketPriceSetup",
      type: "GroupBoxWidget",
      parent: "MarketPriceSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxMarketPriceSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxMarketPriceSetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "MarketPriceSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#MarketPriceProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#MarketPriceProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#MarketPriceProfile#DEFAULT#true#Click"],
    // },
    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#MarketPriceProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    marketPriceUpdate()
  }, [getData(thisObj, 'frmmarketpriceprofile')])

  let marketPriceUpdate = async () => {
    let refresh = getData(thisObj, 'frmmarketpriceprofile')
    if (refresh !== null && refresh != "" && refresh != undefined) {
      let pnutTypeId = refresh.pnut_type_id
      await bFillMarketPriceList()
      // EnableDisableControls()
    }
    setData(thisObj, 'frmmarketpriceprofile', '')
  }

  // START_USER_CODE-USER_METHODS
  async function FormLoad() {
    try {
      await bFillTypeList(); //Method for binding Peanut Type dropdown
      await bFillMarketPriceList(); //Method for binding Market Price List
      await EnableDisableControls();// Method for EnableDisableControls
     
    }
    catch (err) {
      errorHandler(err)
    }
  }
  // Method for EnableDisableControls
  const EnableDisableControls = () => {
    try {
      let VarietyList = getData(thisObj, 'marketPriceLength');
      if (VarietyList <= 0) {
        disable(thisObj, 'btnEdit')
        disable(thisObj, 'btnDelete')
      }
      else {
        enable(thisObj, 'btnEdit')
        enable(thisObj, 'btnDelete')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred EnableDisable Function"
        );
      }
      return false;
    }
  }
//Method for binding Peanut Type dropdown
  const bFillTypeList = async () => {
    try {
      setLoading(true)

      let js = [];
      let data = await ContractManagementService.RetrievePeanutTypeControls(null)//.then(data => {
      if (data !== null && data !== undefined && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          js.push({
            key: data[i].pnutTypeId,
            description: data[i].pnutTypeName
          })
        }
      }
      setValue(thisObj, 'ddPeanutType', js.at(0).key)
      setData(thisObj, 'ddPeanutType', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
      setLoading(false)

    
    }
    catch (err) {
      errorHandler(err)
    }
  }
//Method for binding Market Price List
  const bFillMarketPriceList = async () => {
    try {
      setLoading(true)
      let pnut_type_id = getValue(thisObj, 'ddPeanutType');
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveMarketPriceControlDetails(pnut_type_id, null, null);
      let obj = {};
      let js = [];
      let Ind = 0
      let childData = getData(thisObj, 'frmmarketpriceprofile')

      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let dataExist= js.find(elem=>elem.key==data[i].effect_date_time)
          if(dataExist==undefined){
          obj = {
            key: data[i].effect_date_time,
            description: moment(data[i].effect_date_time).format('MM/DD/YYYY') + " - " + moment(data[i].effect_date_time).format('hh:mm A') + " - " + Number(data[i].market_price).toFixed(5),
            date: data[i].effect_date_time
          }
          js.push(obj);
          obj = {};

          if(new Date(data[i].effect_date_time)?.getTime() == new Date(childData?.marketPriceDate)?.getTime()){
            Ind = i
          }
        }
        }
      }
      if (js.length>0) {
        if(childData!=null &&childData!=undefined){
          setValue(thisObj, 'lstMarketPrice', [js.at(Ind).key]);
        }else{
          setValue(thisObj, 'lstMarketPrice', [js.at(0).key])

        }
      }
      setData(thisObj, 'marketPriceLength', js.length);
      EnableDisableControls()
      thisObj.setState(current => {
        return {
          ...current,
          lstMarketPrice: {
            ...state["lstMarketPrice"],
            valueList: js
          }
        }

      });
      setLoading(false)    
    }
    catch (err) {
      errorHandler(err)
    }
  }

  const onddPeanutTypeChange = async () => {
    try {      
      await bFillMarketPriceList();
      EnableDisableControls()
    }
    catch (err) {
      errorHandler(err)
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange
// Method for Add btn
  const onbtnAddClick = async() => {
    try {      
      let LstrList = thisObj.state['ddPeanutType'].valueList
      let pnut_type_id = getValue(thisObj, 'ddPeanutType');
      let peanutTypeName = ''
      if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
        peanutTypeName = LstrList.find(elem => elem.key == pnut_type_id).description
      }
      let frmMarketPriceProfile = {};
      frmMarketPriceProfile.cmdOkCaption = "Add"
      frmMarketPriceProfile.lblPeanutTypeTag = pnut_type_id
      frmMarketPriceProfile.lblPeanutTypeCaption = peanutTypeName
      setData(thisObj, 'frmmarketpricesetup', frmMarketPriceProfile)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#MarketPriceProfile#DEFAULT#true#Click")
      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick
// Method for Edit5 btn
  const onbtnEditClick = async () => {
    try {
      let peanutList =  thisObj.state['ddPeanutType'].valueList;
      let marketPriceList = thisObj.state['lstMarketPrice'].valueList
      let peanut_type_id = getValue(thisObj, 'ddPeanutType')
      let effectiveDateTime = getValue(thisObj, 'lstMarketPrice')[0]
      let date = ''
      let peanutName = '';
      if (peanut_type_id !== undefined && peanut_type_id !== null && peanut_type_id !== '') {
        peanutName = peanutList.find(elem => elem.key == peanut_type_id).description
      }
      if (effectiveDateTime !== undefined && effectiveDateTime !== null && effectiveDateTime !== '') {
        date = marketPriceList.find(elem => elem.key == effectiveDateTime).date
      }
      let LstrList = await SystemMaintenanceSpecialFunctionsService.RetrieveMarketPriceControlDetails(peanut_type_id, date, null);
      let frmMarketPriceProfile = {};
      frmMarketPriceProfile.lblPeanutTypeTag = peanut_type_id
      frmMarketPriceProfile.lblPeanutTypeCaption = peanutName
      frmMarketPriceProfile.txtEffectiveDateTime = moment(LstrList[0].effect_date_time).format('MM/DD/YYYY hh:mm A')
      frmMarketPriceProfile.cmdOkCaption = "Update"
      frmMarketPriceProfile.txtMktPrice = Number(LstrList[0].market_price).toFixed(5)
      frmMarketPriceProfile.lblAddedByCaption = LstrList[0].add_user + " " + moment(LstrList[0].add_date_time).format('MM/DD/YYYY')
      frmMarketPriceProfile.lblChangedByCaption = LstrList[0].chg_user + " " + moment(LstrList[0].chg_date_time).format('MM/DD/YYYY')
      setData(thisObj, "frmmarketpricesetup", frmMarketPriceProfile)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#MarketPriceProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during edit button"
        );
      }
      return false;
    }
  };
  thisObj.onbtnEditClick = onbtnEditClick
// Method for Delete
  const onbtnDeleteClick = async () => {
    try{
      let peanutList =  thisObj.state['lstMarketPrice'].valueList;
      let peanut_type_id = getValue(thisObj, 'ddPeanutType')
      let effectiveDateTime = getValue(thisObj, 'lstMarketPrice')[0]
      let peanutName = ''; 
      if (effectiveDateTime !== undefined && effectiveDateTime !== null && effectiveDateTime !== '') {
        peanutName = peanutList.find(elem => elem.key == effectiveDateTime).description
      }
      if(effectiveDateTime==null){
        showMessage(thisObj,'Please select a Market Price to delete')
        return;
      }
      let res=confirm(`Are you sure you wish to delete this Market Price '${peanutName}'`)
      if(res==true){
        let response=await SystemMaintenanceSpecialFunctionsService.RemoveMarketPriceControlDetails(null,peanut_type_id,effectiveDateTime);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        }
        
        await bFillMarketPriceList(peanut_type_id)
        await EnableDisableControls();
      }
      else{
        return;
      }
      }
      catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        }
        else {
          showMessage(
            thisObj,
            "Error occurred during executing delete button"
          );
        }
      }
    }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
// Method for Close btn
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_MarketPriceSetupPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
// Method for handling error
  function errorHandler(err) {
    showMessage(thisObj, err.message)
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>

              {/* START_USER_CODE-USER_BEFORE_MarketPriceSetup*/}

              {/* END_USER_CODE-USER_BEFORE_MarketPriceSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxMarketPriceSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxMarketPriceSetup*/}

              <GroupBoxWidget
                conf={state.grpbxMarketPriceSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_lblMarketPrice*/}

                {/* END_USER_CODE-USER_BEFORE_lblMarketPrice*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMarketPrice}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMarketPrice*/}

                {/* END_USER_CODE-USER_AFTER_lblMarketPrice*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstMarketPrice*/}

                {/* END_USER_CODE-USER_BEFORE_lstMarketPrice*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstMarketPrice}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstMarketPrice*/}

                {/* END_USER_CODE-USER_AFTER_lstMarketPrice*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxMarketPriceSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxMarketPriceSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_MarketPriceSetup*/}

              {/* END_USER_CODE-USER_AFTER_MarketPriceSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_MarketPriceSetup);
