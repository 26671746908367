/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  hideColumn,
} from "../../shared/WindowImports";

import "./DelvSettleApplyView.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvSettleApplyView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem("year"));
  let compId = sessionStorage.getItem("compId");
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvSettleApplyView",
    windowName: "DelvSettleApplyView",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvSettleApplyView",
    // START_USER_CODE-USER_DelvSettleApplyView_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Agreement Applications",
      scrCode: "PN1180G",
    },
    // END_USER_CODE-USER_DelvSettleApplyView_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    col100PctAdjDollar: {
      name: "col100PctAdjDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "100% Adjustment $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100PctAdjDollar_PROPERTIES

      // END_USER_CODE-USER_col100PctAdjDollar_PROPERTIES
    },
    col100PctAppliedDollar: {
      name: "col100PctAppliedDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "100% Applied $",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100PctAppliedDollar_PROPERTIES

      // END_USER_CODE-USER_col100PctAppliedDollar_PROPERTIES
    },
    col100PctDollarToApply: {
      name: "col100PctDollarToApply",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "100% $ To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100PctDollarToApply_PROPERTIES

      // END_USER_CODE-USER_col100PctDollarToApply_PROPERTIES
    },
    col100PctOpenDollar: {
      name: "col100PctOpenDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "100% Open $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100PctOpenDollar_PROPERTIES

      // END_USER_CODE-USER_col100PctOpenDollar_PROPERTIES
    },
    colAgreeDt: {
      name: "colAgreeDt",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agree Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeDt_PROPERTIES

      // END_USER_CODE-USER_colAgreeDt_PROPERTIES
    },
    colAgreeNum: {
      name: "colAgreeNum",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agree Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeNum_PROPERTIES

      // END_USER_CODE-USER_colAgreeNum_PROPERTIES
    },
    colInOut: {
      name: "colInOut",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "In/Out",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInOut_PROPERTIES

      // END_USER_CODE-USER_colInOut_PROPERTIES
    },
    colPaidDollar: {
      name: "colPaidDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Paid $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPaidDollar_PROPERTIES

      // END_USER_CODE-USER_colPaidDollar_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPricingMethod: {
      name: "colPricingMethod",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Pricing Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricingMethod_PROPERTIES

      // END_USER_CODE-USER_colPricingMethod_PROPERTIES
    },
    colReceiptValue: {
      name: "colReceiptValue",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Receipt Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptValue_PROPERTIES

      // END_USER_CODE-USER_colReceiptValue_PROPERTIES
    },
    colSeed: {
      name: "colSeed",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed_PROPERTIES

      // END_USER_CODE-USER_colSeed_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Type",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridAgreementApplications",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    gridAgreementApplications: {
      name: "gridAgreementApplications",
      type: "GridWidget",
      parent: "grpbxDelvSettleApplyView",
      gridCellsOrder:
        "btncol1,txtcolAgreeNum,txtcolAgreeDt,txtcolPeanutType,txtcolPeanutVariety,txtcolSeed,txtcolPricingMethod,txtcolType,txtcol100PctDollarToApply,txtcolInOut,txtcolReceiptValue,txtcolPaidDollar,txtcol100PctAppliedDollar,txtcol100PctAdjDollar,txtcol100PctOpenDollar,txtcolPrice",
      ColSpan: "8",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_gridAgreementApplications_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblAgreementApplications: {
      name: "lblAgreementApplications",
      type: "LabelWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Agreement Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_lblAgreementApplications_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtAgreementLocations: {
      name: "txtAgreementLocations",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Agreement Location(s)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreementLocations_PROPERTIES

      // END_USER_CODE-USER_txtAgreementLocations_PROPERTIES
    },
    txtcol100PctAdjDollar: {
      name: "txtcol100PctAdjDollar",
      type: "TextBoxWidget",
      colName: "col100PctAdjDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100PctAdjDollar_PROPERTIES

      // END_USER_CODE-USER_txtcol100PctAdjDollar_PROPERTIES
    },
    txtcol100PctAppliedDollar: {
      name: "txtcol100PctAppliedDollar",
      type: "TextBoxWidget",
      colName: "col100PctAppliedDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100PctAppliedDollar_PROPERTIES

      // END_USER_CODE-USER_txtcol100PctAppliedDollar_PROPERTIES
    },
    txtcol100PctDollarToApply: {
      name: "txtcol100PctDollarToApply",
      type: "TextBoxWidget",
      colName: "col100PctDollarToApply",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100PctDollarToApply_PROPERTIES

      // END_USER_CODE-USER_txtcol100PctDollarToApply_PROPERTIES
    },
    txtcol100PctOpenDollar: {
      name: "txtcol100PctOpenDollar",
      type: "TextBoxWidget",
      colName: "col100PctOpenDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100PctOpenDollar_PROPERTIES

      // END_USER_CODE-USER_txtcol100PctOpenDollar_PROPERTIES
    },
    txtcolAgreeDt: {
      name: "txtcolAgreeDt",
      type: "TextBoxWidget",
      colName: "colAgreeDt",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeDt_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeDt_PROPERTIES
    },
    txtcolAgreeNum: {
      name: "txtcolAgreeNum",
      type: "TextBoxWidget",
      colName: "colAgreeNum",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeNum_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeNum_PROPERTIES
    },
    txtcolInOut: {
      name: "txtcolInOut",
      type: "TextBoxWidget",
      colName: "colInOut",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInOut_PROPERTIES

      // END_USER_CODE-USER_txtcolInOut_PROPERTIES
    },
    txtcolPaidDollar: {
      name: "txtcolPaidDollar",
      type: "TextBoxWidget",
      colName: "colPaidDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPaidDollar_PROPERTIES

      // END_USER_CODE-USER_txtcolPaidDollar_PROPERTIES
    },
    txtcolPeanutType: {
      name: "txtcolPeanutType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutType_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPricingMethod: {
      name: "txtcolPricingMethod",
      type: "TextBoxWidget",
      colName: "colPricingMethod",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPricingMethod_PROPERTIES

      // END_USER_CODE-USER_txtcolPricingMethod_PROPERTIES
    },
    txtcolReceiptValue: {
      name: "txtcolReceiptValue",
      type: "TextBoxWidget",
      colName: "colReceiptValue",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptValue_PROPERTIES

      // END_USER_CODE-USER_txtcolReceiptValue_PROPERTIES
    },
    txtcolSeed: {
      name: "txtcolSeed",
      type: "TextBoxWidget",
      colName: "colSeed",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES

      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtDelvInspNum: {
      name: "txtDelvInspNum",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Delivery Insp #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDelvInspNum_PROPERTIES

      // END_USER_CODE-USER_txtDelvInspNum_PROPERTIES
    },
    txtInOut: {
      name: "txtInOut",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "In/Out",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInOut_PROPERTIES

      // END_USER_CODE-USER_txtInOut_PROPERTIES
    },
    txtNetLbs: {
      name: "txtNetLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Net Lbs.",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbs_PROPERTIES

      // END_USER_CODE-USER_txtNetLbs_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Peanut Variety",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVariety_PROPERTIES
    },
    txtSeed: {
      name: "txtSeed",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Seed",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeed_PROPERTIES

      // END_USER_CODE-USER_txtSeed_PROPERTIES
    },
    txtSettleNum: {
      name: "txtSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Settle #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtSettleNum_PROPERTIES
    },
    txtValue: {
      name: "txtValue",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApplyView",
      Label: "Value",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue_PROPERTIES

      // END_USER_CODE-USER_txtValue_PROPERTIES
    },
    grpbxDelvSettleApplyView: {
      name: "grpbxDelvSettleApplyView",
      type: "GroupBoxWidget",
      parent: "DelvSettleApplyView",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      clonedExtId: "88375",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxDelvSettleApplyView_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSettleApplyView_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "DelvSettleApplyView",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj;
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  // let lblBuyPtIdValue = "R29";
  // let lblTradeInspNumber = "X194628";
  let lblAgreeBP = ''//Fixed as part of PRE-SIT Issue related to DelvOur Prompt for delvSettleProfile screen.

  //Function for FormLoad
  async function FormLoad() {
    try {
      setLoading(true);
      let data13 = getData(thisObj, "DelvSettleApplyViewData");
      let lblBuyPtIdValue = data13.BuyPtId;
      let lblTradeInspNumber = data13.Insp_Number;
      lblAgreeBP = data13?.lblAgreeBP;//Fixed as part of PRE-SIT Issue related to DelvOur Prompt for delvSettleProfile screen.
      setData(thisObj, "lblBuyPtIdValue", lblBuyPtIdValue);
      setData(thisObj, "lblTradeInspNumber", lblTradeInspNumber);
      await bFillAgreementApplications();
      hideColumn(thisObj, "gridAgreementApplications", "txtcolPricingMethod");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolInOut");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolPrice");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Form Load");
      }
      return false;
    } finally {
      setLoading(false);
    }
  }
  //Code for bFillAgreementApplications
  const bFillAgreementApplications = async () => {
    let lblBuyPtIdValue = getData(thisObj, "lblBuyPtIdValue");
    let lblTradeInspNumber = getData(thisObj, "lblTradeInspNumber");
    let sGaobj = {};
    let sGaArr = [];
    let bFillAgreementApplications = false;
    let response = await SettlementService.RetrieveTradeSettlementDetails(
      lblBuyPtIdValue,
      lblTradeInspNumber
    );
    if (response.length > 0) {
      if(lblAgreeBP!= undefined && lblAgreeBP !=null && lblAgreeBP != ''){//Fixed as part of PRE-SIT Issue related to DelvOut Prompt for delvSettleProfile screen.
        setValue(thisObj, "txtAgreementLocations", lblAgreeBP);
      }else{
        setValue(thisObj, "txtAgreementLocations", lblBuyPtIdValue);
      }
      setValue(thisObj, "txtDelvInspNum", lblTradeInspNumber);
      let sHeader = response[0].trade_settle_header;
      let sVendorSplits = response[0].trade_settle_vendor_split;
      let lblTradeSettleNumber =
        response[0].trade_settle_header[0].trade_settle_num;
      setValue(thisObj, "txtSettleNum", lblTradeSettleNumber);
      let lblPeanutTypeText = response[0].trade_settle_header[0].pnut_type_name;
      setValue(thisObj, "txtPeanutType", lblPeanutTypeText);
      let lblPeanutTypeValue = response[0].trade_settle_header[0].pnut_type_id;
      let lblVarietyText = response[0].trade_settle_header[0].pnut_variety_name;
      setValue(thisObj, "txtPeanutVariety", lblVarietyText);
      let symbol_ind = response[0].trade_settle_header[0].symbol_ind;

      switch (symbol_ind) {
        case "TRADEMARK":
          lblVarietyText = " " + " \u2122";
          break;
        case "REGISTERED TRADEMARK":
          lblVarietyText = " " + " \xAE";
          break;
        case "COPYRIGHT":
          lblVarietyText = " " + " \xA9";
          break;
        default:
        // code block
      }
      let lblVarietyValue = response[0].trade_settle_header[0].pnut_variety_id;
      let seg_type = response[0].trade_settle_header[0].seg_type;
      let gen_produced_name =
        response[0].trade_settle_header[0].gen_produced_name;
      if (
        seg_type == 1 &&
        gen_produced_name !== "" &&
        gen_produced_name !== null &&
        gen_produced_name !== undefined
      ) {
        setValue(thisObj, "txtSeed", "Yes");
      } else {
        setValue(thisObj, "txtSeed", "No");
      }

      let purch_sale_ind = response[0].trade_settle_header[0].purch_sale_ind;
      if (purch_sale_ind == "P") {
        setValue(thisObj, "txtInOut", "In");
      } else {
        setValue(thisObj, "txtInOut", "Out");
      }
      let lblValue = 0;
      let lblNetWt = 0;
      let nControlIndex = 0;

      for (let i = 0; i < sVendorSplits.length; i++) {
        let sVendor1 = sVendorSplits[0].split_vendor;
        let sVendor2 = sVendorSplits[0].split_share_pct;
        lblNetWt = Number(lblNetWt) + Number(sVendorSplits[0].net_wt);
        lblValue = (
          Number(lblValue) + Number(sVendorSplits[0].basis_grade_amt)
        ).toFixed(2);
      }
      setValue(thisObj, "txtNetLbs", lblNetWt);
      setValue(thisObj, "txtValue", lblValue);
    }
    let delv_ind = "Y";
    let response1 = await SettlementService.RetrieveTradeSettlementApplicationDetails(
      lblTradeInspNumber,
      delv_ind
    );
    if (response1.length > 0) {
      for (let i = 0; i < response1.length; i++) {
        let sAgreement = response1;
        let delivered_lbs_adj = response1[i].delivered_lbs_adj;
        if (delivered_lbs_adj > 0) {
          let sAgreementDate = response1[i].agree_date_time?.split(' ')?.[0];//Fixed as part of SIT Bug_547508 date is not coming as per old application.
          let sAgreementNum = response1[i].agreement_num;
          let pnut_type_id = response1[i].pnut_type_id;
          let pnut_variety_id = response1[i].pnut_variety_id;
          let seed_gen = response1[i].seed_gen;
          let grade_pricing_method = response1[i].grade_pricing_method;
          let firm_basis_opt = response1[i].firm_basis_opt;
          sGaobj.txtcolAgreeNum = sAgreementNum;
          sGaobj.txtcolAgreeDt = sAgreementDate;
          sGaobj.txtcolPeanutType = pnut_type_id;
          sGaobj.txtcolPeanutVariety = pnut_variety_id;
          if (seed_gen != "") {
            sGaobj.txtcolSeed = "Y";
          } else {
            sGaobj.txtcolSeed = "N";
          }
          sGaobj.txtcolPricingMethod = grade_pricing_method;
          if (firm_basis_opt == "F") {
            sGaobj.txtcolType = "Firm";
          } else if (firm_basis_opt == "B") {
            sGaobj.txtcolType = "Basis";
          } else {
            sGaobj.txtcolType = "Option";
          }
          if (sAgreementNum == response1[i].agreement_num) {
            sGaobj.txtcol100PctDollarToApply = Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
            }).format(Number(response1[i].settle_dol_value));
          }
          if (response1[i].purch_sale_ind == "P") {
            sGaobj.txtcolInOut = "In";
          } else {
            sGaobj.txtcolInOut = "Out";
          }
          if (isNaN(response1[i].rcpt_value) == false) {
            sGaobj.txtcolReceiptValue = Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
            }).format(Number(response1[i].rcpt_value));
          } else {
            sGaobj.txtcolReceiptValue = 0;
          }
          if (isNaN(response1[i].delv_agree_dol) == false) {
            sGaobj.txtcolPaidDollar = Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
            }).format(Number(response1[i].delv_agree_dol));
          } else {
            sGaobj.txtcolPaidDollar = 0;
          }
          if (isNaN(response1[i].delv_app_dol) == false) {
            sGaobj.txtcol100PctAppliedDollar = Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
            }).format(Number(response1[i].delv_app_dol));
          } else {
            sGaobj.txtcol100PctAppliedDollar = 0;
          }
          if (isNaN(response1[i].unapp_dol_value) == false) {
            sGaobj.txtcol100PctAdjDollar = Number(
              response1[i].unapp_dol_value
            ).toFixed(2);
          } else {
            sGaobj.txtcol100PctAdjDollar = 0;
          }
          let data1 =
            response1[i].rcpt_value -
            response1[i].delv_app_dol -
            response1[i].unapp_dol_value;
          sGaobj.txtcol100PctOpenDollar = Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
          }).format(data1);

          if (response1[i].price_per_ton != "") {
            sGaobj.txtcolPrice = response1[i].price_per_ton;
          }
          sGaArr.push(sGaobj);
          sGaobj = {};
        }
      }
      setValue(thisObj, "gridAgreementApplications", sGaArr);
      setData(thisObj, "gridAgreementApplications", sGaArr);
    }
  };
  //Code for Cancel Click
  const onbtnCancelClick = () => {
    document
      .getElementById("WarehouseReceipts_DelvSettleApplyViewPopUp")
      .childNodes[0].click();
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //Code for btncol1Click
  const onbtncol1Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow");
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow");
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement;
      rowSelected.classList.add("activeRow");
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue =
        getValue(_kaledo.thisObj, "gridAgreementApplications") ||
        getData(_kaledo.thisObj, "gridAgreementApplications");
      let AgreementNum = NewGridValue[rowID].txtcolAgreeNum;
      let mode = "Update";
      let obj = {
        AgreementNum: AgreementNum,
        mode: mode,
      };
      setData(thisObj, "dataForDelvAgreeProfile", obj)
      goTo(thisObj, "WarehouseReceipts#DelvAgreeProfile#DEFAULT#true#Click");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Delivery Agreement Profile already opened in the background."
        );
      }
    }
  };
  thisObj.onbtncol1Click = onbtncol1Click;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvSettleApplyView*/}

              {/* END_USER_CODE-USER_BEFORE_DelvSettleApplyView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvSettleApplyView*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvSettleApplyView*/}

              <GroupBoxWidget
                conf={state.grpbxDelvSettleApplyView}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtDelvInspNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtDelvInspNum*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDelvInspNum}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDelvInspNum*/}

                {/* END_USER_CODE-USER_AFTER_txtDelvInspNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettleNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettleNum*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettleNum}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettleNum*/}

                {/* END_USER_CODE-USER_AFTER_txtSettleNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtInOut*/}

                {/* END_USER_CODE-USER_BEFORE_txtInOut*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInOut}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInOut*/}

                {/* END_USER_CODE-USER_AFTER_txtInOut*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutVariety*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutVariety}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeed*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeed*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeed}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeed*/}

                {/* END_USER_CODE-USER_AFTER_txtSeed*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetLbs*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetLbs}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtNetLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtValue*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValue}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValue*/}

                {/* END_USER_CODE-USER_AFTER_txtValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAgreementApplications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_lblAgreementApplications*/}
                {/* START_USER_CODE-USER_BEFORE_txtAgreementLocations*/}

                {/* END_USER_CODE-USER_BEFORE_txtAgreementLocations*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreementLocations}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAgreementLocations*/}

                {/* END_USER_CODE-USER_AFTER_txtAgreementLocations*/}
                {/* START_USER_CODE-USER_BEFORE_gridAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_gridAgreementApplications*/}

                <GridWidget
                  conf={state.gridAgreementApplications}
                  screenConf={state}
                  linkClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAgreementApplications}
                  onEvent={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_gridAgreementApplications*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvSettleApplyView*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvSettleApplyView*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvSettleApplyView*/}

              {/* END_USER_CODE-USER_AFTER_DelvSettleApplyView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvSettleApplyView);
