/* eslint-disable*/
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { StockTransferService } from "../Service/StockTransferService";
import { clsPeanutPrice } from "./clsPeanutPrice";
import { clsArea } from "./clsArea";

const defaultGoldenPeanutUserProps = {
  UserID: "",
  SessionID: "",
  FullName: "",
  CropYear: 0,
  CompanyID: "",
  CompanyName: "",
  TransferRole: 0,
  IsAutomaticTransferNumber: false,
};

let mPeanutTypeList = [];
const mPeanutPrice = new clsPeanutPrice();
const mAreaInfo = new clsArea();

const enumUserTransferRole = ["ReadOnly", "Shipper", "Receiver", "Freighter"];

export class clsUser {
  constructor(thisObj) {
    this.thisObj = thisObj;
    this.mudtProps = Object.assign({}, defaultGoldenPeanutUserProps);
    this.G_NOT_SPECIFIED = "";

    let mPeanutPrice = new clsPeanutPrice();
    this.mudtProps.TransferRole = enumUserTransferRole.indexOf("Shipper");
    this.NotSpecifiedIsOn(true);
  }

  UserID = (value) => {
    this.mudtProps.UserID = value;
  };

  SessionID = (value) => {
    this.mudtProps.SessionID = value;
  };

  CompanyID = (value) => {
    this.mudtProps.CompanyID = value;
  };

  CompanyName = (value) => {
    this.mudtProps.CompanyName = value;
  };

  CropYear = (value) => {
    this.mudtProps.CropYear = value;
  };

  TransferRole = async(value) => {
    this.mudtProps.TransferRole = value;
  };

  IsAutomaticTransferNumbers = (value) => {
    this.mudtProps.IsAutomaticTransferNumber = value;
  };

  // CheckTransferAccessLevel = async (
  //   ShippingBuyingPointID,
  //   ReceivingBuyingPointID = "",
  //   TransferType = ""
  // ) => {
  //   let response1 =
  //     await ContractManagementService.RetrieveAccessPermissionDetails(
  //       "PN1080",
  //       "001",
  //       "U"
  //     );
  //   if (String(response1[0].permission).toUpperCase() == "Y") {
  //     if (TransferType == "FF") {
  //       this.mudtProps.TransferRole = enumUserTransferRole.indexOf("Freighter");
  //     } else {
  //       this.mudtProps.TransferRole = enumUserTransferRole.indexOf("Shipper");
  //     }
  //   }
  //   if (ReceivingBuyingPointID != "") {
  //     let response2 =
  //       await ContractManagementService.RetrieveAccessPermissionDetails(
  //         "PN1080",
  //         "002",
  //         "U"
  //       );
  //     if (String(response2[0].permission).toUpperCase() == "Y") {
  //       if (TransferType == "FF") {
  //         this.mudtProps.TransferRole =
  //           enumUserTransferRole.indexOf("Freighter");
  //       } else {
  //         this.mudtProps.TransferRole =
  //           enumUserTransferRole.indexOf("Receiver");
  //       }
  //     }
  //     let response3 =
  //       await ContractManagementService.RetrieveAccessPermissionDetails(
  //         "PN1080",
  //         "003",
  //         "U"
  //       );
  //     if (String(response3[0].permission).toUpperCase() == "Y") {
  //       this.mudtProps.TransferRole = enumUserTransferRole.indexOf("Freighter");
  //     }
  //   }
  // };

  GetPeanutPrice = (AreaID, PeanutTypeID) => {
    mPeanutPrice.Load(AreaID, PeanutTypeID);
    return mPeanutPrice;
  };

  GetAreaInfo = async (AreaID) => {
    await mAreaInfo.Load(AreaID);
    return mAreaInfo;
  };

  async GetPeanutType(PeanutTypeID) {
    if (mPeanutTypeList.length === 0) {
      mPeanutTypeList = [];
    }
    if (mPeanutTypeList.length > 0) {
      return mPeanutTypeList.find((elem) => elem.pnut_type_id === PeanutTypeID);
    } else {
      let obj;
      let data = await StockTransferService.RetrievePeanutTypeList();
      for (let i = 0; i < data.length; i++) {
        let dataExist = mPeanutTypeList.find(
          (elem) => elem.pnut_type_id === data[i].pnut_type_id
        );
        if (dataExist === undefined) {
          obj = {
            key: data[i].pnut_type_id.trim(),
            description: data[i].pnut_type_name.trim(),
          };
          mPeanutTypeList.push(data[i]);
        }
      }
      return mPeanutTypeList.find((elem) => elem.pnut_type_id === PeanutTypeID);
    }
  }

  NotSpecifiedIsOn = (IsOn) => {
    if (IsOn) {
      this.G_NOT_SPECIFIED = "Not Specified";
    } else {
      this.G_NOT_SPECIFIED = "";
    }
  };

  PeanutTypeList = () => {
    if (mPeanutTypeList.length > 0) {
      return mPeanutTypeList;
    } else {
      return "";
    }
  };
}
