/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  getData,
  hide,
  setData,
  setValue,
  show,
  disable,
  getValue,
  enable
} from "../../shared/WindowImports";

import "./StateProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import { typeOf } from "@humanscape/react-summernote-bs4";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_StateProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "StateProfile",
    windowName: "StateProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.StateProfile",
    // START_USER_CODE-USER_StateProfile_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "State Profile",
      scrCode: "PN1020C",
    },
    // END_USER_CODE-USER_StateProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtAbbreviation: {
      name: "txtAbbreviation",
      type: "TextBoxWidget",
      parent: "grpbxStateProfile",
      Label: "Abbreviation:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAbbreviation_PROPERTIES

      // END_USER_CODE-USER_txtAbbreviation_PROPERTIES
    },
    txtFreightLoadLimit: {
      name: "txtFreightLoadLimit",
      type: "TextBoxWidget",
      parent: "grpbxStateProfile",
      Label: "Freight Load Limit:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightLoadLimit_PROPERTIES

      // END_USER_CODE-USER_txtFreightLoadLimit_PROPERTIES
    },
    txtInCharges: {
      name: "txtInCharges",
      type: "TextBoxWidget",
      parent: "grpbxStateProfile",
      Label: "In Charges:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInCharges_PROPERTIES

      // END_USER_CODE-USER_txtInCharges_PROPERTIES
    },
    txtInspectionFees: {
      name: "txtInspectionFees",
      type: "TextBoxWidget",
      parent: "grpbxStateProfile",
      Label: "Inspection Fees:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspectionFees_PROPERTIES

      // END_USER_CODE-USER_txtInspectionFees_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxStateProfile",
      Label: "Name:",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtStateID: {
      name: "txtStateID",
      type: "TextBoxWidget",
      parent: "grpbxStateProfile",
      Label: "State ID:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateID_PROPERTIES

      // END_USER_CODE-USER_txtStateID_PROPERTIES
    },
    grpbxStateProfile: {
      name: "grpbxStateProfile",
      type: "GroupBoxWidget",
      parent: "StateProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxStateProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxStateProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "StateProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  function FormLoad() {
    try {
      let dataObj = getData(thisObj, 'frmStateProfile');
    if (dataObj.cmdOk_Caption == "Add") {
        document.getElementById('btnOk').innerText = "Add"
        hide(thisObj, 'txtInCharges')
        hide(thisObj, 'txtInspectionFees')
        hide(thisObj, 'txtFreightLoadLimit')
      }
    if (dataObj.cmdOk_Caption == 'Update') {
      document.getElementById('btnOk').innerText = "Update"
      disable(thisObj, 'txtAbbreviation')
      setValue(thisObj, 'txtAbbreviation', dataObj.txtAbbreviation)
      setValue(thisObj, 'txtName', dataObj.txtName)
      document.getElementById('txtName').focus();
      setValue(thisObj, 'txtStateID', dataObj.txtStateID)
      setValue(thisObj, 'txtInCharges', dataObj.txtInCharges)
      setValue(thisObj, 'txtInspectionFees', dataObj.txtInspFees)
      setValue(thisObj, 'txtFreightLoadLimit', dataObj.txtFreightLoadLimit)
      setValue(thisObj, 'lblAddedByValue', dataObj.lblAddedBy)
      setValue(thisObj, 'lblChangedByValue', dataObj.lblChangedBy)
      if(dataObj.txtStateID == ''){
        hide(thisObj, 'txtInCharges')
        hide(thisObj, 'txtInspectionFees')
        hide(thisObj, 'txtFreightLoadLimit')
      }
    }
    }
    catch (err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event"
        );
      }
      return false;
    }
    return true;
  };

const FormValid = () => {
  try {
    var bFormValid = true
    let abbreviation = getValue(thisObj, 'txtAbbreviation')
    let name = getValue(thisObj, 'txtName')
    let stateId = getValue(thisObj, 'txtStateID')
    let inCharges = getValue(thisObj, 'txtInCharges')
    let inspFees = getValue(thisObj, 'txtInspectionFees')
    let freightLoadLimit = getValue(thisObj, 'txtFreightLoadLimit')
    
    if (abbreviation == null || abbreviation == '' || abbreviation == undefined) {
      showMessage(thisObj, "Abbreviation is a required field")
      bFormValid = false;
      return bFormValid;
    }

    if (name == null || name == '' || name == undefined) {
      showMessage(thisObj, "Name is a required field")
      bFormValid = false;
      return bFormValid;
    }

    if (stateId == null || stateId == '' || stateId == undefined) {
      inCharges = ""
      inspFees = ""
      freightLoadLimit = ""
      return;
    }

    if (Number(inCharges)) {
      if (Number(inCharges) < 0 || Number(inCharges) > 9999.99) {
        showMessage(thisObj, "In Charges must be of format ####.## .")
        bFormValid = false;
        return bFormValid;
      }
    }
    else if (stateId !== '' && isNaN(inCharges) || (inCharges) == '' || (inCharges) == undefined){
      showMessage(thisObj, "In Charges must be numeric.")
      setValue(thisObj, 'txtInCharges', "")
      bFormValid = false;
      return bFormValid;
    }

    if (Number(inspFees)) {
      if (Number(inspFees) < 0 || Number(inspFees) > 9999.99) {
        showMessage(thisObj, "Inspection Fees must be of format ####.## .")
        bFormValid = false;
        return bFormValid;
      }
    }
    else if(stateId !== '' && (isNaN(inspFees) || inspFees == '' || inspFees == undefined)){
      showMessage(thisObj, "Inspection Fees must be numeric.")
      setValue(thisObj, 'txtInspectionFees', "")
      bFormValid = false;
      return bFormValid;
    }

    if (Number(freightLoadLimit)) {
      if (Number(freightLoadLimit) < 0 || Number(freightLoadLimit) > 999999) {
        showMessage(thisObj, "Freight Load Limit must be of format ######.")
        bFormValid = false;
        return bFormValid;
      }
    }
    else if(stateId !== '' && (isNaN(freightLoadLimit) || freightLoadLimit == '' || freightLoadLimit == undefined)){
      showMessage(thisObj, "Freight Load Limit must be numeric.")
      setValue(thisObj, 'txtFreightLoadLimit', "")
      bFormValid = false;
      return bFormValid;
    }
  }
    catch (err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event"
        );
      }
      return false;
    }
    return true;
  };
  
  //Click event for Add/Edit button
  const onbtnOkClick = async() => {
    try{
      if (FormValid() == false) {
        return;
      }
      let dataObj = getData(thisObj, "frmStateProfile");
        let state_abbr = getValue(thisObj, 'txtAbbreviation')
        let state_name = getValue(thisObj, 'txtName')
        let state_id = getValue(thisObj, 'txtStateID')
        let in_charg_rate = getValue(thisObj, 'txtInCharges')
        let insp_fee_rate = getValue(thisObj, 'txtInspectionFees')
        let freight_load_limit = getValue(thisObj, 'txtFreightLoadLimit')

      if (dataObj.cmdOk_Caption == "Add") {
        let temp = {
          "state_id": state_id == undefined ? "" : state_id,
          "state_name": state_name,
          "state_abbr": state_abbr,
          "in_charg_rate": in_charg_rate == undefined ? "" : in_charg_rate,
          "insp_fee_rate": insp_fee_rate == undefined ? "" : insp_fee_rate,
          "freight_load_limit": freight_load_limit == undefined ? "" : freight_load_limit
        }
        let response = await SystemMaintenanceMasterManagementService.CreateStateControls(null, temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
          return;
        }
        let Array = []
        let Obj = {}
        Obj.txtName = state_name
        Obj.txtAbbreviation = state_abbr
        Obj.txtStateID = state_id
        Obj.txtInCharges = in_charg_rate
        Obj.txtInspectionFees = insp_fee_rate
        Obj.txtFreightLoadLimit = freight_load_limit
        Array.push(Obj)
        setData(thisObj, 'frmCountySetup', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_StateProfilePopUp").childNodes[0].click()
      }

      if (dataObj.cmdOk_Caption == "Update") {
        disable(thisObj, 'txtAbbreviation')
        let temp = {
                 "state_id": state_id == undefined ? "" : state_id,
                 "state_name" : state_name,
                 "in_charg_rate" : in_charg_rate == undefined ? "" : in_charg_rate,
                 "insp_fee_rate" : insp_fee_rate == undefined ? "" : insp_fee_rate,
                 "freight_load_limit" : freight_load_limit == undefined ? "" : freight_load_limit
                }
        let response = await SystemMaintenanceMasterManagementService.UpdateStateControls(state_abbr, null, temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response.message)
          return;
        }
        let Array = []
        let Obj = {}
        Obj.txtName = state_name
        Obj.txtAbbreviation = state_abbr
        Obj.txtStateID = state_id
        Obj.txtInCharges = in_charg_rate
        Obj.txtInspectionFees = insp_fee_rate
        Obj.txtFreightLoadLimit = freight_load_limit
        Array.push(Obj)
        setData(thisObj, 'frmCountySetup', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_StateProfilePopUp").childNodes[0].click()
      }
    }
    catch (err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  //Click event for Cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_StateProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //Change event for text Abbreviation
  const ontxtAbbreviationChange = () => {
    try {
      let abbreviation = getValue(thisObj, 'txtAbbreviation')
      abbreviation = abbreviation.toUpperCase();
      setValue(thisObj, 'txtAbbreviation', abbreviation)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txt"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtAbbreviationChange = ontxtAbbreviationChange

  //Blur event for text InCharges
  const ontxtInChargesBlur = () => {
    try {
      let inCharges = getValue(thisObj, 'txtInCharges')
      let regexPattern = /^-?[0-9]+$/;
      if (Number(inCharges)) {
        if(inCharges.length <= 4) {
          inCharges = Number(inCharges).toFixed(2)
        }
        else if (inCharges.length == 5 && regexPattern.test(inCharges) == true) {
          inCharges = Number(inCharges).toFixed(1)
        }
        else if (inCharges.length == 5 && regexPattern.test(inCharges) == false) {
          inCharges = Number(inCharges).toFixed(2)
        }
        else if (inCharges.length == 6 && regexPattern.test(inCharges) == true) {
          inCharges = Number(inCharges) + '.'
        }
        else if (inCharges.length == 6 && regexPattern.test(inCharges) == false) {
          inCharges = Number(inCharges).toFixed(2)
        }
      }
      setValue(thisObj, 'txtInCharges', inCharges)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txt"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtInChargesBlur = ontxtInChargesBlur

  //Blur event for text InspectionFees
  const ontxtInspectionFeesBlur = () => {
    try {
      let inspectionFees = getValue(thisObj, 'txtInspectionFees')
      let regexPattern = /^-?[0-9]+$/;
      if (Number(inspectionFees)) {
        if(inspectionFees.length <= 4) {
          inspectionFees = Number(inspectionFees).toFixed(2)
        }
        else if(inspectionFees.length == 5 && regexPattern.test(inspectionFees) == true) {
          inspectionFees = Number(inspectionFees).toFixed(1)
        }
        else if(inspectionFees.length == 5 && regexPattern.test(inspectionFees) == false) {
          inspectionFees = Number(inspectionFees).toFixed(2)
        }
        else if(inspectionFees.length == 6 && regexPattern.test(inspectionFees) == true) {
          inspectionFees = Number(inspectionFees) + '.'
        }
        else if(inspectionFees.length == 6 && regexPattern.test(inspectionFees) == false) {
          inspectionFees = Number(inspectionFees).toFixed(2)
        }
      }
      setValue(thisObj, 'txtInspectionFees', inspectionFees)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txt"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtInspectionFeesBlur = ontxtInspectionFeesBlur

  //Blur event for text FreightLoadLimit
  const ontxtFreightLoadLimitBlur = () => {
    try {
      let freightLoadLimit = getValue(thisObj, 'txtFreightLoadLimit')
      if (Number(freightLoadLimit)) {
        freightLoadLimit = Number(freightLoadLimit).toFixed(0)
        setValue(thisObj, 'txtFreightLoadLimit', freightLoadLimit)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txt"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtFreightLoadLimitBlur = ontxtFreightLoadLimitBlur

  //Blur event for text StateID
  const ontxtStateIDBlur = () => {
    try {
      let stateId = getValue(thisObj, 'txtStateID')
      if (stateId !== '' || stateId !== null || stateId !== undefined) {
        show(thisObj, 'txtInCharges')
        document.getElementById('txtInCharges').focus();
        show(thisObj, 'txtInspectionFees')
        show(thisObj, 'txtFreightLoadLimit')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txt"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtStateIDBlur = ontxtStateIDBlur

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_StateProfile*/}

              {/* END_USER_CODE-USER_BEFORE_StateProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxStateProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxStateProfile*/}

              <GroupBoxWidget conf={state.grpbxStateProfile} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAbbreviation*/}

                {/* END_USER_CODE-USER_BEFORE_txtAbbreviation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAbbreviation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAbbreviation*/}

                {/* END_USER_CODE-USER_AFTER_txtAbbreviation*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_txtStateID*/}

                {/* END_USER_CODE-USER_BEFORE_txtStateID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStateID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStateID*/}

                {/* END_USER_CODE-USER_AFTER_txtStateID*/}
                {/* START_USER_CODE-USER_BEFORE_txtInCharges*/}

                {/* END_USER_CODE-USER_BEFORE_txtInCharges*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInCharges}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInCharges*/}

                {/* END_USER_CODE-USER_AFTER_txtInCharges*/}
                {/* START_USER_CODE-USER_BEFORE_txtInspectionFees*/}

                {/* END_USER_CODE-USER_BEFORE_txtInspectionFees*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspectionFees}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInspectionFees*/}

                {/* END_USER_CODE-USER_AFTER_txtInspectionFees*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreightLoadLimit*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreightLoadLimit*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreightLoadLimit}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreightLoadLimit*/}

                {/* END_USER_CODE-USER_AFTER_txtFreightLoadLimit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxStateProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxStateProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_StateProfile*/}

              {/* END_USER_CODE-USER_AFTER_StateProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_StateProfile);
