/* eslint-disable*/
import React from "react";
import Settlements_FV95Search from "./FV95Search";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FV95Search Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_FV95Search />);
    });
  });
  afterEach(cleanup);
  test("is FV95Search Loads Successfully", () => {
    expect(screen.getByText("FV95Search")).toBeInTheDocument;
  });
  test("Custom Test Cases for FV95Search", () => {
    // START_USER_CODE-USER_FV95Search_Custom_Test_Case
    // END_USER_CODE-USER_FV95Search_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_FV95Search />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("Settlements:FV95Search_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(t("Settlements:FV95Search_btn2"));
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(t("Settlements:FV95Search_btn3"));
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btn4(Button Widget) Test Cases", async () => {
    const btn4 = screen.getByTestId("btn4");
    expect(btn4).toBeInTheDocument;
    expect(btn4.textContent).toEqual(t("Settlements:FV95Search_btn4"));
  });
  test("Custom Test Cases for btn4", () => {
    // START_USER_CODE-USER_btn4_TEST
    // END_USER_CODE-USER_btn4_TEST
  });
  test("btnAuthorizeApplicationEdit(Button Widget) Test Cases", async () => {
    const btnAuthorizeApplicationEdit = screen.getByTestId(
      "btnAuthorizeApplicationEdit"
    );
    expect(btnAuthorizeApplicationEdit).toBeInTheDocument;
    expect(btnAuthorizeApplicationEdit.textContent).toEqual(
      t("Settlements:FV95Search_btnAuthorizeApplicationEdit")
    );
  });
  test("Custom Test Cases for btnAuthorizeApplicationEdit", () => {
    // START_USER_CODE-USER_btnAuthorizeApplicationEdit_TEST
    // END_USER_CODE-USER_btnAuthorizeApplicationEdit_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(t("Settlements:FV95Search_btnClose"));
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateManualSC95(Button Widget) Test Cases", async () => {
    const btnCreateManualSC95 = screen.getByTestId("btnCreateManualSC95");
    expect(btnCreateManualSC95).toBeInTheDocument;
    expect(btnCreateManualSC95.textContent).toEqual(
      t("Settlements:FV95Search_btnCreateManualSC95")
    );
  });
  test("Custom Test Cases for btnCreateManualSC95", () => {
    // START_USER_CODE-USER_btnCreateManualSC95_TEST
    // END_USER_CODE-USER_btnCreateManualSC95_TEST
  });
  test("btnDeleteSC95(Button Widget) Test Cases", async () => {
    const btnDeleteSC95 = screen.getByTestId("btnDeleteSC95");
    expect(btnDeleteSC95).toBeInTheDocument;
    expect(btnDeleteSC95.textContent).toEqual(
      t("Settlements:FV95Search_btnDeleteSC95")
    );
  });
  test("Custom Test Cases for btnDeleteSC95", () => {
    // START_USER_CODE-USER_btnDeleteSC95_TEST
    // END_USER_CODE-USER_btnDeleteSC95_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("Settlements:FV95Search_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnKCMOdataTransmitted(Button Widget) Test Cases", async () => {
    const btnKCMOdataTransmitted = screen.getByTestId("btnKCMOdataTransmitted");
    expect(btnKCMOdataTransmitted).toBeInTheDocument;
    expect(btnKCMOdataTransmitted.textContent).toEqual(
      t("Settlements:FV95Search_btnKCMOdataTransmitted")
    );
  });
  test("Custom Test Cases for btnKCMOdataTransmitted", () => {
    // START_USER_CODE-USER_btnKCMOdataTransmitted_TEST
    // END_USER_CODE-USER_btnKCMOdataTransmitted_TEST
  });
  test("btnOpenSC95(Button Widget) Test Cases", async () => {
    const btnOpenSC95 = screen.getByTestId("btnOpenSC95");
    expect(btnOpenSC95).toBeInTheDocument;
    expect(btnOpenSC95.textContent).toEqual(
      t("Settlements:FV95Search_btnOpenSC95")
    );
  });
  test("Custom Test Cases for btnOpenSC95", () => {
    // START_USER_CODE-USER_btnOpenSC95_TEST
    // END_USER_CODE-USER_btnOpenSC95_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("Settlements:FV95Search_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnPrintWorkSheet(Button Widget) Test Cases", async () => {
    const btnPrintWorkSheet = screen.getByTestId("btnPrintWorkSheet");
    expect(btnPrintWorkSheet).toBeInTheDocument;
    expect(btnPrintWorkSheet.textContent).toEqual(
      t("Settlements:FV95Search_btnPrintWorkSheet")
    );
  });
  test("Custom Test Cases for btnPrintWorkSheet", () => {
    // START_USER_CODE-USER_btnPrintWorkSheet_TEST
    // END_USER_CODE-USER_btnPrintWorkSheet_TEST
  });
  test("btnReprint(Button Widget) Test Cases", async () => {
    const btnReprint = screen.getByTestId("btnReprint");
    expect(btnReprint).toBeInTheDocument;
    expect(btnReprint.textContent).toEqual(
      t("Settlements:FV95Search_btnReprint")
    );
  });
  test("Custom Test Cases for btnReprint", () => {
    // START_USER_CODE-USER_btnReprint_TEST
    // END_USER_CODE-USER_btnReprint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("Settlements:FV95Search_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnSignSettlements(Button Widget) Test Cases", async () => {
    const btnSignSettlements = screen.getByTestId("btnSignSettlements");
    expect(btnSignSettlements).toBeInTheDocument;
    expect(btnSignSettlements.textContent).toEqual(
      t("Settlements:FV95Search_btnSignSettlements")
    );
  });
  test("Custom Test Cases for btnSignSettlements", () => {
    // START_USER_CODE-USER_btnSignSettlements_TEST
    // END_USER_CODE-USER_btnSignSettlements_TEST
  });
  test("btnTransferSC95File(Button Widget) Test Cases", async () => {
    const btnTransferSC95File = screen.getByTestId("btnTransferSC95File");
    expect(btnTransferSC95File).toBeInTheDocument;
    expect(btnTransferSC95File.textContent).toEqual(
      t("Settlements:FV95Search_btnTransferSC95File")
    );
  });
  test("Custom Test Cases for btnTransferSC95File", () => {
    // START_USER_CODE-USER_btnTransferSC95File_TEST
    // END_USER_CODE-USER_btnTransferSC95File_TEST
  });
  test("btnUnSignSettlements(Button Widget) Test Cases", async () => {
    const btnUnSignSettlements = screen.getByTestId("btnUnSignSettlements");
    expect(btnUnSignSettlements).toBeInTheDocument;
    expect(btnUnSignSettlements.textContent).toEqual(
      t("Settlements:FV95Search_btnUnSignSettlements")
    );
  });
  test("Custom Test Cases for btnUnSignSettlements", () => {
    // START_USER_CODE-USER_btnUnSignSettlements_TEST
    // END_USER_CODE-USER_btnUnSignSettlements_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("Settlements:FV95Search_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("btnViewSettlements(Button Widget) Test Cases", async () => {
    const btnViewSettlements = screen.getByTestId("btnViewSettlements");
    expect(btnViewSettlements).toBeInTheDocument;
    expect(btnViewSettlements.textContent).toEqual(
      t("Settlements:FV95Search_btnViewSettlements")
    );
  });
  test("Custom Test Cases for btnViewSettlements", () => {
    // START_USER_CODE-USER_btnViewSettlements_TEST
    // END_USER_CODE-USER_btnViewSettlements_TEST
  });
  test("btnViewTransferFailures(Button Widget) Test Cases", async () => {
    const btnViewTransferFailures = screen.getByTestId(
      "btnViewTransferFailures"
    );
    expect(btnViewTransferFailures).toBeInTheDocument;
    expect(btnViewTransferFailures.textContent).toEqual(
      t("Settlements:FV95Search_btnViewTransferFailures")
    );
  });
  test("Custom Test Cases for btnViewTransferFailures", () => {
    // START_USER_CODE-USER_btnViewTransferFailures_TEST
    // END_USER_CODE-USER_btnViewTransferFailures_TEST
  });
  test("gridFV95SearchData(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchDatabtn =
      gridFV95SearchData.nextElementSibling.firstElementChild;
    gridFV95SearchData =
      gridFV95SearchData.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData.tagName).toBe("DIV");
    expect(gridFV95SearchData.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridFV95SearchData", () => {
    // START_USER_CODE-USER_gridFV95SearchData_TEST
    // END_USER_CODE-USER_gridFV95SearchData_TEST
  });
  test("grpbxAcions(GroupBox Widget) Test Cases", async () => {
    const grpbxAcions = screen.getByTestId("grpbxAcions");
    expect(grpbxAcions.tagName).toBe("BUTTON");
    expect(grpbxAcions.type).toBe("button");
    expect(grpbxAcions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAcions", () => {
    // START_USER_CODE-USER_grpbxAcions_TEST
    // END_USER_CODE-USER_grpbxAcions_TEST
  });
  test("grpbxFarm(GroupBox Widget) Test Cases", async () => {
    const grpbxFarm = screen.getByTestId("grpbxFarm");
    expect(grpbxFarm.tagName).toBe("BUTTON");
    expect(grpbxFarm.type).toBe("button");
    expect(grpbxFarm.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarm", () => {
    // START_USER_CODE-USER_grpbxFarm_TEST
    // END_USER_CODE-USER_grpbxFarm_TEST
  });
  test("grpbxFV95Search(GroupBox Widget) Test Cases", async () => {
    const grpbxFV95Search = screen.getByTestId("grpbxFV95Search");
    expect(grpbxFV95Search.tagName).toBe("BUTTON");
    expect(grpbxFV95Search.type).toBe("button");
    expect(grpbxFV95Search.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFV95Search", () => {
    // START_USER_CODE-USER_grpbxFV95Search_TEST
    // END_USER_CODE-USER_grpbxFV95Search_TEST
  });
  test("grpbxLocation(GroupBox Widget) Test Cases", async () => {
    const grpbxLocation = screen.getByTestId("grpbxLocation");
    expect(grpbxLocation.tagName).toBe("BUTTON");
    expect(grpbxLocation.type).toBe("button");
    expect(grpbxLocation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLocation", () => {
    // START_USER_CODE-USER_grpbxLocation_TEST
    // END_USER_CODE-USER_grpbxLocation_TEST
  });
  test("grpbxMiscOpt(GroupBox Widget) Test Cases", async () => {
    const grpbxMiscOpt = screen.getByTestId("grpbxMiscOpt");
    expect(grpbxMiscOpt.tagName).toBe("BUTTON");
    expect(grpbxMiscOpt.type).toBe("button");
    expect(grpbxMiscOpt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMiscOpt", () => {
    // START_USER_CODE-USER_grpbxMiscOpt_TEST
    // END_USER_CODE-USER_grpbxMiscOpt_TEST
  });
  test("grpbxNumber(GroupBox Widget) Test Cases", async () => {
    const grpbxNumber = screen.getByTestId("grpbxNumber");
    expect(grpbxNumber.tagName).toBe("BUTTON");
    expect(grpbxNumber.type).toBe("button");
    expect(grpbxNumber.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxNumber", () => {
    // START_USER_CODE-USER_grpbxNumber_TEST
    // END_USER_CODE-USER_grpbxNumber_TEST
  });
  test("grpbxPeanut(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanut = screen.getByTestId("grpbxPeanut");
    expect(grpbxPeanut.tagName).toBe("BUTTON");
    expect(grpbxPeanut.type).toBe("button");
    expect(grpbxPeanut.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanut", () => {
    // START_USER_CODE-USER_grpbxPeanut_TEST
    // END_USER_CODE-USER_grpbxPeanut_TEST
  });
  test("grpbxSearchResults(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchResults = screen.getByTestId("grpbxSearchResults");
    expect(grpbxSearchResults.tagName).toBe("BUTTON");
    expect(grpbxSearchResults.type).toBe("button");
    expect(grpbxSearchResults.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchResults", () => {
    // START_USER_CODE-USER_grpbxSearchResults_TEST
    // END_USER_CODE-USER_grpbxSearchResults_TEST
  });
  test("grpbxType(GroupBox Widget) Test Cases", async () => {
    const grpbxType = screen.getByTestId("grpbxType");
    expect(grpbxType.tagName).toBe("BUTTON");
    expect(grpbxType.type).toBe("button");
    expect(grpbxType.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxType", () => {
    // START_USER_CODE-USER_grpbxType_TEST
    // END_USER_CODE-USER_grpbxType_TEST
  });
  test("grpbxWhseBin(GroupBox Widget) Test Cases", async () => {
    const grpbxWhseBin = screen.getByTestId("grpbxWhseBin");
    expect(grpbxWhseBin.tagName).toBe("BUTTON");
    expect(grpbxWhseBin.type).toBe("button");
    expect(grpbxWhseBin.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWhseBin", () => {
    // START_USER_CODE-USER_grpbxWhseBin_TEST
    // END_USER_CODE-USER_grpbxWhseBin_TEST
  });
  test("labelSC95Insp1007Stlmnt(Label Widget) Test Cases", async () => {
    const labelSC95Insp1007Stlmnt = screen.getByTestId(
      "labelSC95Insp1007Stlmnt"
    );
    expect(labelSC95Insp1007Stlmnt.tagName).toBe("LABEL");
    expect(labelSC95Insp1007Stlmnt.classList).toContain("form-label");
    expect(labelSC95Insp1007Stlmnt.textContent).toEqual(
      t("Settlements:FV95Search_labelSC95Insp1007Stlmnt")
    );
  });
  test("Custom Test Cases for labelSC95Insp1007Stlmnt", () => {
    // START_USER_CODE-USER_labelSC95Insp1007Stlmnt_TEST
    // END_USER_CODE-USER_labelSC95Insp1007Stlmnt_TEST
  });
  test("lblContracted(Label Widget) Test Cases", async () => {
    const lblContracted = screen.getByTestId("lblContracted");
    expect(lblContracted.tagName).toBe("LABEL");
    expect(lblContracted.classList).toContain("form-label");
    expect(lblContracted.textContent).toEqual(
      t("Settlements:FV95Search_lblContracted")
    );
  });
  test("Custom Test Cases for lblContracted", () => {
    // START_USER_CODE-USER_lblContracted_TEST
    // END_USER_CODE-USER_lblContracted_TEST
  });
  test("lblContractedValue(Label Widget) Test Cases", async () => {
    const lblContractedValue = screen.getByTestId("lblContractedValue");
    expect(lblContractedValue.tagName).toBe("LABEL");
    expect(lblContractedValue.classList).toContain("form-label");
    expect(lblContractedValue.textContent).toEqual(
      t("Settlements:FV95Search_lblContractedValue")
    );
  });
  test("Custom Test Cases for lblContractedValue", () => {
    // START_USER_CODE-USER_lblContractedValue_TEST
    // END_USER_CODE-USER_lblContractedValue_TEST
  });
  test("lblFarm(Label Widget) Test Cases", async () => {
    const lblFarm = screen.getByTestId("lblFarm");
    expect(lblFarm.tagName).toBe("LABEL");
    expect(lblFarm.classList).toContain("form-label");
    expect(lblFarm.textContent).toEqual(t("Settlements:FV95Search_lblFarm"));
  });
  test("Custom Test Cases for lblFarm", () => {
    // START_USER_CODE-USER_lblFarm_TEST
    // END_USER_CODE-USER_lblFarm_TEST
  });
  test("lblLocation(Label Widget) Test Cases", async () => {
    const lblLocation = screen.getByTestId("lblLocation");
    expect(lblLocation.tagName).toBe("LABEL");
    expect(lblLocation.classList).toContain("form-label");
    expect(lblLocation.textContent).toEqual(
      t("Settlements:FV95Search_lblLocation")
    );
  });
  test("Custom Test Cases for lblLocation", () => {
    // START_USER_CODE-USER_lblLocation_TEST
    // END_USER_CODE-USER_lblLocation_TEST
  });
  test("lblNetWeight(Label Widget) Test Cases", async () => {
    const lblNetWeight = screen.getByTestId("lblNetWeight");
    expect(lblNetWeight.tagName).toBe("LABEL");
    expect(lblNetWeight.classList).toContain("form-label");
    expect(lblNetWeight.textContent).toEqual(
      t("Settlements:FV95Search_lblNetWeight")
    );
  });
  test("Custom Test Cases for lblNetWeight", () => {
    // START_USER_CODE-USER_lblNetWeight_TEST
    // END_USER_CODE-USER_lblNetWeight_TEST
  });
  test("lblNetWeightValue(Label Widget) Test Cases", async () => {
    const lblNetWeightValue = screen.getByTestId("lblNetWeightValue");
    expect(lblNetWeightValue.tagName).toBe("LABEL");
    expect(lblNetWeightValue.classList).toContain("form-label");
    expect(lblNetWeightValue.textContent).toEqual(
      t("Settlements:FV95Search_lblNetWeightValue")
    );
  });
  test("Custom Test Cases for lblNetWeightValue", () => {
    // START_USER_CODE-USER_lblNetWeightValue_TEST
    // END_USER_CODE-USER_lblNetWeightValue_TEST
  });
  test("lblNumber(Label Widget) Test Cases", async () => {
    const lblNumber = screen.getByTestId("lblNumber");
    expect(lblNumber.tagName).toBe("LABEL");
    expect(lblNumber.classList).toContain("form-label");
    expect(lblNumber.textContent).toEqual(
      t("Settlements:FV95Search_lblNumber")
    );
  });
  test("Custom Test Cases for lblNumber", () => {
    // START_USER_CODE-USER_lblNumber_TEST
    // END_USER_CODE-USER_lblNumber_TEST
  });
  test("lblPeanut(Label Widget) Test Cases", async () => {
    const lblPeanut = screen.getByTestId("lblPeanut");
    expect(lblPeanut.tagName).toBe("LABEL");
    expect(lblPeanut.classList).toContain("form-label");
    expect(lblPeanut.textContent).toEqual(
      t("Settlements:FV95Search_lblPeanut")
    );
  });
  test("Custom Test Cases for lblPeanut", () => {
    // START_USER_CODE-USER_lblPeanut_TEST
    // END_USER_CODE-USER_lblPeanut_TEST
  });
  test("lblTotals(Label Widget) Test Cases", async () => {
    const lblTotals = screen.getByTestId("lblTotals");
    expect(lblTotals.tagName).toBe("LABEL");
    expect(lblTotals.classList).toContain("form-label");
    expect(lblTotals.textContent).toEqual(
      t("Settlements:FV95Search_lblTotals")
    );
  });
  test("Custom Test Cases for lblTotals", () => {
    // START_USER_CODE-USER_lblTotals_TEST
    // END_USER_CODE-USER_lblTotals_TEST
  });
  test("lblType(Label Widget) Test Cases", async () => {
    const lblType = screen.getByTestId("lblType");
    expect(lblType.tagName).toBe("LABEL");
    expect(lblType.classList).toContain("form-label");
    expect(lblType.textContent).toEqual(t("Settlements:FV95Search_lblType"));
  });
  test("Custom Test Cases for lblType", () => {
    // START_USER_CODE-USER_lblType_TEST
    // END_USER_CODE-USER_lblType_TEST
  });
  test("lblUncontracted(Label Widget) Test Cases", async () => {
    const lblUncontracted = screen.getByTestId("lblUncontracted");
    expect(lblUncontracted.tagName).toBe("LABEL");
    expect(lblUncontracted.classList).toContain("form-label");
    expect(lblUncontracted.textContent).toEqual(
      t("Settlements:FV95Search_lblUncontracted")
    );
  });
  test("Custom Test Cases for lblUncontracted", () => {
    // START_USER_CODE-USER_lblUncontracted_TEST
    // END_USER_CODE-USER_lblUncontracted_TEST
  });
  test("lblUncontractedValue(Label Widget) Test Cases", async () => {
    const lblUncontractedValue = screen.getByTestId("lblUncontractedValue");
    expect(lblUncontractedValue.tagName).toBe("LABEL");
    expect(lblUncontractedValue.classList).toContain("form-label");
    expect(lblUncontractedValue.textContent).toEqual(
      t("Settlements:FV95Search_lblUncontractedValue")
    );
  });
  test("Custom Test Cases for lblUncontractedValue", () => {
    // START_USER_CODE-USER_lblUncontractedValue_TEST
    // END_USER_CODE-USER_lblUncontractedValue_TEST
  });
  test("lblValue(Label Widget) Test Cases", async () => {
    const lblValue = screen.getByTestId("lblValue");
    expect(lblValue.tagName).toBe("LABEL");
    expect(lblValue.classList).toContain("form-label");
    expect(lblValue.textContent).toEqual(t("Settlements:FV95Search_lblValue"));
  });
  test("Custom Test Cases for lblValue", () => {
    // START_USER_CODE-USER_lblValue_TEST
    // END_USER_CODE-USER_lblValue_TEST
  });
  test("lblValueValue(Label Widget) Test Cases", async () => {
    const lblValueValue = screen.getByTestId("lblValueValue");
    expect(lblValueValue.tagName).toBe("LABEL");
    expect(lblValueValue.classList).toContain("form-label");
    expect(lblValueValue.textContent).toEqual(
      t("Settlements:FV95Search_lblValueValue")
    );
  });
  test("Custom Test Cases for lblValueValue", () => {
    // START_USER_CODE-USER_lblValueValue_TEST
    // END_USER_CODE-USER_lblValueValue_TEST
  });
  test("lblWhseBin(Label Widget) Test Cases", async () => {
    const lblWhseBin = screen.getByTestId("lblWhseBin");
    expect(lblWhseBin.tagName).toBe("LABEL");
    expect(lblWhseBin.classList).toContain("form-label");
    expect(lblWhseBin.textContent).toEqual(
      t("Settlements:FV95Search_lblWhseBin")
    );
  });
  test("Custom Test Cases for lblWhseBin", () => {
    // START_USER_CODE-USER_lblWhseBin_TEST
    // END_USER_CODE-USER_lblWhseBin_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("gridFV95SearchData_txt1007C(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txt1007C = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txt1007Cbtn =
      gridFV95SearchData_txt1007C.nextElementSibling.firstElementChild;
    gridFV95SearchData_txt1007C =
      gridFV95SearchData_txt1007C.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txt1007C.tagName).toBe("DIV");
    expect(gridFV95SearchData_txt1007C.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txt1007C", () => {
    // START_USER_CODE-USER_txt1007C_TEST
    // END_USER_CODE-USER_txt1007C_TEST
  });
  test("gridFV95SearchData_txtBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtBuyingPoint = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtBuyingPointbtn =
      gridFV95SearchData_txtBuyingPoint.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtBuyingPoint =
      gridFV95SearchData_txtBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridFV95SearchData_txtBuyingPoint.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtBuyingPoint.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("gridFV95SearchData_txtContractC(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtContractC = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtContractCbtn =
      gridFV95SearchData_txtContractC.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtContractC =
      gridFV95SearchData_txtContractC.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtContractC.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtContractC.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtContractC", () => {
    // START_USER_CODE-USER_txtContractC_TEST
    // END_USER_CODE-USER_txtContractC_TEST
  });
  test("txtDACO(Textbox Widget) Test Cases", async () => {
    const txtDACO = screen.getByTestId("txtDACO");
    expect(txtDACO.tagName).toBe("INPUT");
    expect(txtDACO.type).toBe("text");
    expect(txtDACO.classList).toContain("textboxWidgetClass");
    expect(txtDACO.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtDACO")
    );
    await act(async () => {
      userEvent.type(txtDACO, "1");
    });
  });
  test("Custom Test Cases for txtDACO", () => {
    // START_USER_CODE-USER_txtDACO_TEST
    // END_USER_CODE-USER_txtDACO_TEST
  });
  test("gridFV95SearchData_txtDACOC(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtDACOC = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txtDACOCbtn =
      gridFV95SearchData_txtDACOC.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtDACOC =
      gridFV95SearchData_txtDACOC.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtDACOC.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtDACOC.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtDACOC", () => {
    // START_USER_CODE-USER_txtDACOC_TEST
    // END_USER_CODE-USER_txtDACOC_TEST
  });
  test("gridFV95SearchData_txtFarmC(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtFarmC = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txtFarmCbtn =
      gridFV95SearchData_txtFarmC.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtFarmC =
      gridFV95SearchData_txtFarmC.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtFarmC.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtFarmC.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtFarmC", () => {
    // START_USER_CODE-USER_txtFarmC_TEST
    // END_USER_CODE-USER_txtFarmC_TEST
  });
  test("gridFV95SearchData_txtGen(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtGen = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txtGenbtn =
      gridFV95SearchData_txtGen.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtGen =
      gridFV95SearchData_txtGen.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtGen.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtGen.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtGen", () => {
    // START_USER_CODE-USER_txtGen_TEST
    // END_USER_CODE-USER_txtGen_TEST
  });
  test("txtGeneration(Textbox Widget) Test Cases", async () => {
    const txtGeneration = screen.getByTestId("txtGeneration");
    expect(txtGeneration.tagName).toBe("INPUT");
    expect(txtGeneration.type).toBe("text");
    expect(txtGeneration.classList).toContain("textboxWidgetClass");
    expect(txtGeneration.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtGeneration")
    );
    await act(async () => {
      userEvent.type(txtGeneration, "1");
    });
  });
  test("Custom Test Cases for txtGeneration", () => {
    // START_USER_CODE-USER_txtGeneration_TEST
    // END_USER_CODE-USER_txtGeneration_TEST
  });
  test("gridFV95SearchData_txtInspectionDate(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtInspectionDate = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtInspectionDatebtn =
      gridFV95SearchData_txtInspectionDate.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtInspectionDate =
      gridFV95SearchData_txtInspectionDate.parentElement.parentElement
        .parentElement;
    expect(gridFV95SearchData_txtInspectionDate.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtInspectionDate.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtInspectionDate", () => {
    // START_USER_CODE-USER_txtInspectionDate_TEST
    // END_USER_CODE-USER_txtInspectionDate_TEST
  });
  test("txtInspectionDateFrom(Textbox Widget) Test Cases", async () => {
    const txtInspectionDateFrom = screen.getByTestId("txtInspectionDateFrom");
    expect(txtInspectionDateFrom.tagName).toBe("INPUT");
    expect(txtInspectionDateFrom.type).toBe("text");
    expect(txtInspectionDateFrom.classList).toContain("textboxWidgetClass");
    expect(txtInspectionDateFrom.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtInspectionDateFrom")
    );
    await act(async () => {
      userEvent.type(txtInspectionDateFrom, "1");
    });
  });
  test("Custom Test Cases for txtInspectionDateFrom", () => {
    // START_USER_CODE-USER_txtInspectionDateFrom_TEST
    // END_USER_CODE-USER_txtInspectionDateFrom_TEST
  });
  test("txtInspectionDateTo(Textbox Widget) Test Cases", async () => {
    const txtInspectionDateTo = screen.getByTestId("txtInspectionDateTo");
    expect(txtInspectionDateTo.tagName).toBe("INPUT");
    expect(txtInspectionDateTo.type).toBe("text");
    expect(txtInspectionDateTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtInspectionDateTo, "1");
    });
  });
  test("Custom Test Cases for txtInspectionDateTo", () => {
    // START_USER_CODE-USER_txtInspectionDateTo_TEST
    // END_USER_CODE-USER_txtInspectionDateTo_TEST
  });
  test("gridFV95SearchData_txtNetWeight(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtNetWeight = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtNetWeightbtn =
      gridFV95SearchData_txtNetWeight.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtNetWeight =
      gridFV95SearchData_txtNetWeight.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtNetWeight.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtNetWeight.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("gridFV95SearchData_txtObligationPoint(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtObligationPoint = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtObligationPointbtn =
      gridFV95SearchData_txtObligationPoint.nextElementSibling
        .firstElementChild;
    gridFV95SearchData_txtObligationPoint =
      gridFV95SearchData_txtObligationPoint.parentElement.parentElement
        .parentElement;
    expect(gridFV95SearchData_txtObligationPoint.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtObligationPoint.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtObligationPoint", () => {
    // START_USER_CODE-USER_txtObligationPoint_TEST
    // END_USER_CODE-USER_txtObligationPoint_TEST
  });
  test("gridFV95SearchData_txtOneDocBP(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtOneDocBP = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtOneDocBPbtn =
      gridFV95SearchData_txtOneDocBP.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtOneDocBP =
      gridFV95SearchData_txtOneDocBP.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtOneDocBP.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtOneDocBP.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtOneDocBP", () => {
    // START_USER_CODE-USER_txtOneDocBP_TEST
    // END_USER_CODE-USER_txtOneDocBP_TEST
  });
  test("gridFV95SearchData_txtOriginPoint(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtOriginPoint = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtOriginPointbtn =
      gridFV95SearchData_txtOriginPoint.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtOriginPoint =
      gridFV95SearchData_txtOriginPoint.parentElement.parentElement
        .parentElement;
    expect(gridFV95SearchData_txtOriginPoint.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtOriginPoint.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtOriginPoint", () => {
    // START_USER_CODE-USER_txtOriginPoint_TEST
    // END_USER_CODE-USER_txtOriginPoint_TEST
  });
  test("gridFV95SearchData_txtPeanutType(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtPeanutType = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtPeanutTypebtn =
      gridFV95SearchData_txtPeanutType.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtPeanutType =
      gridFV95SearchData_txtPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridFV95SearchData_txtPeanutType.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtPeanutType.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("gridFV95SearchData_txtReceipt(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtReceipt = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtReceiptbtn =
      gridFV95SearchData_txtReceipt.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtReceipt =
      gridFV95SearchData_txtReceipt.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtReceipt.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtReceipt.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtReceipt", () => {
    // START_USER_CODE-USER_txtReceipt_TEST
    // END_USER_CODE-USER_txtReceipt_TEST
  });
  test("gridFV95SearchData_txtReprint(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtReprint = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtReprintbtn =
      gridFV95SearchData_txtReprint.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtReprint =
      gridFV95SearchData_txtReprint.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtReprint.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtReprint.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtReprint", () => {
    // START_USER_CODE-USER_txtReprint_TEST
    // END_USER_CODE-USER_txtReprint_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("gridFV95SearchData_txtSC95C(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtSC95C = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txtSC95Cbtn =
      gridFV95SearchData_txtSC95C.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtSC95C =
      gridFV95SearchData_txtSC95C.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtSC95C.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtSC95C.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtSC95C", () => {
    // START_USER_CODE-USER_txtSC95C_TEST
    // END_USER_CODE-USER_txtSC95C_TEST
  });
  test("gridFV95SearchData_txtSegC(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtSegC = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txtSegCbtn =
      gridFV95SearchData_txtSegC.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtSegC =
      gridFV95SearchData_txtSegC.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtSegC.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtSegC.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtSegC", () => {
    // START_USER_CODE-USER_txtSegC_TEST
    // END_USER_CODE-USER_txtSegC_TEST
  });
  test("txtSharePercent(Textbox Widget) Test Cases", async () => {
    const txtSharePercent = screen.getByTestId("txtSharePercent");
    expect(txtSharePercent.tagName).toBe("INPUT");
    expect(txtSharePercent.type).toBe("text");
    expect(txtSharePercent.classList).toContain("textboxWidgetClass");
    expect(txtSharePercent.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtSharePercent")
    );
    await act(async () => {
      userEvent.type(txtSharePercent, "1");
    });
  });
  test("Custom Test Cases for txtSharePercent", () => {
    // START_USER_CODE-USER_txtSharePercent_TEST
    // END_USER_CODE-USER_txtSharePercent_TEST
  });
  test("gridFV95SearchData_txtStatusC(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtStatusC = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtStatusCbtn =
      gridFV95SearchData_txtStatusC.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtStatusC =
      gridFV95SearchData_txtStatusC.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtStatusC.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtStatusC.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtStatusC", () => {
    // START_USER_CODE-USER_txtStatusC_TEST
    // END_USER_CODE-USER_txtStatusC_TEST
  });
  test("gridFV95SearchData_txtStatusHidden(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtStatusHidden = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtStatusHiddenbtn =
      gridFV95SearchData_txtStatusHidden.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtStatusHidden =
      gridFV95SearchData_txtStatusHidden.parentElement.parentElement
        .parentElement;
    expect(gridFV95SearchData_txtStatusHidden.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtStatusHidden.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtStatusHidden", () => {
    // START_USER_CODE-USER_txtStatusHidden_TEST
    // END_USER_CODE-USER_txtStatusHidden_TEST
  });
  test("gridFV95SearchData_txtValue(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtValue = screen.getByTestId("gridFV95SearchData");
    let gridFV95SearchData_txtValuebtn =
      gridFV95SearchData_txtValue.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtValue =
      gridFV95SearchData_txtValue.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtValue.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtValue.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtValue", () => {
    // START_USER_CODE-USER_txtValue_TEST
    // END_USER_CODE-USER_txtValue_TEST
  });
  test("gridFV95SearchData_txtVariety(Grid Widget) Test Cases", async () => {
    let gridFV95SearchData_txtVariety = screen.getByTestId(
      "gridFV95SearchData"
    );
    let gridFV95SearchData_txtVarietybtn =
      gridFV95SearchData_txtVariety.nextElementSibling.firstElementChild;
    gridFV95SearchData_txtVariety =
      gridFV95SearchData_txtVariety.parentElement.parentElement.parentElement;
    expect(gridFV95SearchData_txtVariety.tagName).toBe("DIV");
    expect(gridFV95SearchData_txtVariety.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95Search_gridFV95SearchData")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtVariety", () => {
    // START_USER_CODE-USER_txtVariety_TEST
    // END_USER_CODE-USER_txtVariety_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtWR(Textbox Widget) Test Cases", async () => {
    const txtWR = screen.getByTestId("txtWR");
    expect(txtWR.tagName).toBe("INPUT");
    expect(txtWR.type).toBe("text");
    expect(txtWR.classList).toContain("textboxWidgetClass");
    expect(txtWR.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Search_txtWR")
    );
    await act(async () => {
      userEvent.type(txtWR, "1");
    });
  });
  test("Custom Test Cases for txtWR", () => {
    // START_USER_CODE-USER_txtWR_TEST
    // END_USER_CODE-USER_txtWR_TEST
  });
});
