/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  _kaledo,
  initForm, //Remove Modal from here
  GroupBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  WhiteSpaceWidget,
  goTo,
  setData,
  getData,
  setValue
} from "../../shared/WindowImports";

import "./MainMenu.scss";

import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import Modal from "react-bootstrap/Modal";  //change
import { ContractManagementService } from "../Service/ContractManagementService";
import { dictRolesControls } from "../../Common/Constants";
import { authProvider } from '../../../../service/authProvider';
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_MainMenu(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [year, setYear] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Main Menu",
    windowName: "MainMenu",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.MainMenu",
    // START_USER_CODE-USER_MainMenu_PROPERTIES
    headerData:{
      scrName: "Main Menu",
      scrCode: "PN0000",
    },
    // END_USER_CODE-USER_MainMenu_PROPERTIES
    applicationSection: {
      name: "applicationSection",
      type: "GroupBoxWidget",
      parent: "grpbxAppSysMaint",
      Label: "Applications",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_applicationSection_PROPERTIES

      // END_USER_CODE-USER_applicationSection_PROPERTIES
    },
    paymentProcessBtnList: {
      name: "paymentProcessBtnList",
      type: "GroupBoxWidget",
      parent: "MainMenu",
      Label: "",
      HasLabel: false,
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_applicationSection_PROPERTIES

      // END_USER_CODE-USER_applicationSection_PROPERTIES
    },
    btnReadyToPayRelease: {
      name: "btnReadyToPayRelease",
      type: "ButtonWidget",
      parent: "paymentProcessBtnList",
      Label: "Ready To Pay Release",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnPrintChecks: {
      name: "btnPrintChecks",
      type: "ButtonWidget",
      parent: "paymentProcessBtnList",
      Label: "Print Checks",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnSpoiledCheckEntry: {
      name: "btnSpoiledCheckEntry",
      type: "ButtonWidget",
      parent: "paymentProcessBtnList",
      Label: "Spoiled Check Entry",
      CharWidth: "45",
      
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnInquireVoidCorrectChecks: {
      name: "btnInquireVoidCorrectChecks",
      type: "ButtonWidget",
      parent: "paymentProcessBtnList",
      Label: "Inquire, Void & Correct Checks",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnSpecialPayables: {
      name: "btnSpecialPayables",
      type: "ButtonWidget",
      parent: "paymentProcessBtnList",
      Label: "Special Payables",
      CharWidth: "45",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    reportBtnList: {
      name: "reportBtnList",
      type: "GroupBoxWidget",
      parent: "MainMenu",
      Label: "",
      HasLabel: false,
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_applicationSection_PROPERTIES

      // END_USER_CODE-USER_applicationSection_PROPERTIES
    },
    btnFarmSumm: {
      name: "btnFarmSumm",
      type: "ButtonWidget",
      parent: "reportBtnList",
      Label: "Farm Summary Report Request",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnViewReports: {
      name: "btnViewReports",
      type: "ButtonWidget",
      parent: "reportBtnList",
      Label: "View Reports",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnProcReport: {
      name: "btnProcReport",
      type: "ButtonWidget",
      parent: "reportBtnList",
      Label: "Farm Summary Report Request",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnFarmSumm: {
      name: "btnFarmSumm",
      type: "ButtonWidget",
      parent: "reportBtnList",
      Label: "Farm Summary Report Request",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnViewReports: {
      name: "btnViewReports",
      type: "ButtonWidget",
      parent: "reportBtnList",
      Label: "View Reports",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnProcReport: {
      name: "btnProcReport",
      type: "ButtonWidget",
      parent: "reportBtnList",
      Label: "Farm Summary Report Request",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnApplicationLable: {
      name: "btnApplicationLable",
      type: "LabelWidget",
      parent: "applicationSection",
      Label: "Applications",
      ColSpan: "5",
      ColSpanForLargeDesktop: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnApplicationLable_PROPERTIES

      // END_USER_CODE-USER_btnApplicationLable_PROPERTIES
    },
    btnBuyingPoints: {
      name: "btnBuyingPoints",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Buying Points",
      CharWidth: "28",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnBuyingPoints_PROPERTIES

      // END_USER_CODE-USER_btnBuyingPoints_PROPERTIES
    },
    btnContractGroupings: {
      name: "btnContractGroupings",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Contract Groupings",
      CharWidth: "37",
      Visible:false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractGroupings_PROPERTIES

      // END_USER_CODE-USER_btnContractGroupings_PROPERTIES
    },
    btnContractLimitControl: {
      name: "btnContractLimitControl",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Contract Limit Control",
      CharWidth: "45",
      Visible:false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnContractLimitControl_PROPERTIES

      // END_USER_CODE-USER_btnContractLimitControl_PROPERTIES
    },
    btnCureCleanSchedule: {
      name: "btnCureCleanSchedule",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Cure/Clean Schedule",
      CharWidth: "39",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCureCleanSchedule_PROPERTIES

      // END_USER_CODE-USER_btnCureCleanSchedule_PROPERTIES
    },
    btnDeliveryInOut: {
      name: "btnDeliveryInOut",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Delivery In/Out",
      CharWidth: "32",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeliveryInOut_PROPERTIES

      // END_USER_CODE-USER_btnDeliveryInOut_PROPERTIES
    },
    btnExceptionPremiums: {
      name: "btnExceptionPremiums",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Exception Premiums",
      CharWidth: "37",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExceptionPremiums_PROPERTIES

      // END_USER_CODE-USER_btnExceptionPremiums_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxAppSysMaint",
      Label: "Exit",
      ColSpan: "5",
      CharWidth: "11",
      ColSpanForLargeDesktop: "5",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnFarmerStockPriceSetup: {
      name: "btnFarmerStockPriceSetup",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Farmer Stock Price Setup",
      CharWidth: "49",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFarmerStockPriceSetup_PROPERTIES

      // END_USER_CODE-USER_btnFarmerStockPriceSetup_PROPERTIES
    },
    btnFarms: {
      name: "btnFarms",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Farms",
      CharWidth: "13",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFarms_PROPERTIES

      // END_USER_CODE-USER_btnFarms_PROPERTIES
    },
    btnFederalWhseLic: {
      name: "btnFederalWhseLic",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Federal Whse. Lic.",
      CharWidth: "37",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFederalWhseLic_PROPERTIES

      // END_USER_CODE-USER_btnFederalWhseLic_PROPERTIES
    },
    btnFilters: {
      name: "btnFilters",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Filters",
      CharWidth: "17",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFilters_PROPERTIES

      // END_USER_CODE-USER_btnFilters_PROPERTIES
    },
    btnFreightControl: {
      name: "btnFreightControl",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Freight Control",
      CharWidth: "32",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFreightControl_PROPERTIES

      // END_USER_CODE-USER_btnFreightControl_PROPERTIES
    },
    btnGroupings1007: {
      name: "btnGroupings1007",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "1007 Groupings",
      CharWidth: "29",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnGroupings1007_PROPERTIES

      // END_USER_CODE-USER_btnGroupings1007_PROPERTIES
    },
    btnInterestAgreement: {
      name: "btnInterestAgreement",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Interest Agreement",
      CharWidth: "37",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnInterestAgreement_PROPERTIES

      // END_USER_CODE-USER_btnInterestAgreement_PROPERTIES
    },
    btnInventory: {
      name: "btnInventory",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Inventory",
      CharWidth: "20",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnInventory_PROPERTIES

      // END_USER_CODE-USER_btnInventory_PROPERTIES
    },
    btnNewContract: {
      name: "btnNewContract",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "New Contract",
      CharWidth: "26",
      //Enabled:false,
      Visible:false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnNewContract_PROPERTIES

      // END_USER_CODE-USER_btnNewContract_PROPERTIES
    },
    btnOpenAccounts: {
      name: "btnOpenAccounts",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Open Accounts",
      CharWidth: "28",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpenAccounts_PROPERTIES

      // END_USER_CODE-USER_btnOpenAccounts_PROPERTIES
    },
    btnPaymentProcessing: {
      name: "btnPaymentProcessing",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Payment Processing",
      CharWidth: "37",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPaymentProcessing_PROPERTIES

      // END_USER_CODE-USER_btnPaymentProcessing_PROPERTIES
    },
    btnPeanuts: {
      name: "btnPeanuts",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Peanuts",
      CharWidth: "17",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPeanuts_PROPERTIES

      // END_USER_CODE-USER_btnPeanuts_PROPERTIES
    },
    btnPeanutSpecifications: {
      name: "btnPeanutSpecifications",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Peanut Specifications",
      CharWidth: "42",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPeanutSpecifications_PROPERTIES

      // END_USER_CODE-USER_btnPeanutSpecifications_PROPERTIES
    },
    btnPremDeducsMaster: {
      name: "btnPremDeducsMaster",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Prem/Deducs Master",
      CharWidth: "37",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPremDeducsMaster_PROPERTIES

      // END_USER_CODE-USER_btnPremDeducsMaster_PROPERTIES
    },
    btnPremDeducsRates: {
      name: "btnPremDeducsRates",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Prem/Deducs Rates",
      CharWidth: "36",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPremDeducsRates_PROPERTIES

      // END_USER_CODE-USER_btnPremDeducsRates_PROPERTIES
    },
    btnReports: {
      name: "btnReports",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Reports",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReports_PROPERTIES

      // END_USER_CODE-USER_btnReports_PROPERTIES
    },
    btnScaleTicket: {
      name: "btnScaleTicket",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Scale(Weight) Ticket",
      CharWidth: "41",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnScaleTicket_PROPERTIES

      // END_USER_CODE-USER_btnScaleTicket_PROPERTIES
    },
    btnSearchFor: {
      name: "btnSearchFor",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Search For SC95's  / 1007's",
      CharWidth: "54",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearchFor_PROPERTIES

      // END_USER_CODE-USER_btnSearchFor_PROPERTIES
    },
    btnSearchForContracts: {
      name: "btnSearchForContracts",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Search For Contracts",
      CharWidth: "41",
      Visible:false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearchForContracts_PROPERTIES

      // END_USER_CODE-USER_btnSearchForContracts_PROPERTIES
    },
    btnSignatureSetup: {
      name: "btnSignatureSetup",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Signature Setup",
      CharWidth: "32",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSignatureSetup_PROPERTIES

      // END_USER_CODE-USER_btnSignatureSetup_PROPERTIES
    },
    btnSpecialFunction: {
      name: "btnSpecialFunction",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Special Functions",
      CharWidth: "33",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSpecialFunction_PROPERTIES

      // END_USER_CODE-USER_btnSpecialFunction_PROPERTIES
    },
    btnSpotPrice: {
      name: "btnSpotPrice",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Spot Price",
      CharWidth: "23",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSpotPrice_PROPERTIES

      // END_USER_CODE-USER_btnSpotPrice_PROPERTIES
    },
    btnStateCountries: {
      name: "btnStateCountries",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "State/Countries",
      CharWidth: "32",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnStateCountries_PROPERTIES

      // END_USER_CODE-USER_btnStateCountries_PROPERTIES
    },
    btnStockTransfers: {
      name: "btnStockTransfers",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Stock Transfers",
      CharWidth: "30",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnStockTransfers_PROPERTIES

      // END_USER_CODE-USER_btnStockTransfers_PROPERTIES
    },
    btnStorageSchedule: {
      name: "btnStorageSchedule",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Storage Schedule",
      CharWidth: "33",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnStorageSchedule_PROPERTIES

      // END_USER_CODE-USER_btnStorageSchedule_PROPERTIES
    },
    btnTradeAgreements: {
      name: "btnTradeAgreements",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Trade Agreements",
      CharWidth: "33",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnTradeAgreements_PROPERTIES

      // END_USER_CODE-USER_btnTradeAgreements_PROPERTIES
    },
    btnTradeSettlements: {
      name: "btnTradeSettlements",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Trade Settlements",
      CharWidth: "35",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnTradeSettlements_PROPERTIES

      // END_USER_CODE-USER_btnTradeSettlements_PROPERTIES
    },
    btnUserManuals: {
      name: "btnUserManuals",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "User Manuals",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUserManuals_PROPERTIES

      // END_USER_CODE-USER_btnUserManuals_PROPERTIES
    },
    btnVendoeMaintenance: {
      name: "btnVendoeMaintenance",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Vendor Maintenance",
      CharWidth: "37",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendoeMaintenance_PROPERTIES

      // END_USER_CODE-USER_btnVendoeMaintenance_PROPERTIES
    },
    btnVoidNoSaleSC95: {
      name: "btnVoidNoSaleSC95",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: " Void/No Sale SC95",
      CharWidth: "38",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoidNoSaleSC95_PROPERTIES

      // END_USER_CODE-USER_btnVoidNoSaleSC95_PROPERTIES
    },
    btnVoidNoSaleSetup: {
      name: "btnVoidNoSaleSetup",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Void/No Sale Setup",
      CharWidth: "38",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoidNoSaleSetup_PROPERTIES

      // END_USER_CODE-USER_btnVoidNoSaleSetup_PROPERTIES
    },
    btnVoidWRcontrolNumber: {
      name: "btnVoidWRcontrolNumber",
      type: "ButtonWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "Void WR Control Number",
      CharWidth: "45",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoidWRcontrolNumber_PROPERTIES

      // END_USER_CODE-USER_btnVoidWRcontrolNumber_PROPERTIES
    },
    btnWarehouseRecipts: {
      name: "btnWarehouseRecipts",
      type: "ButtonWidget",
      parent: "applicationSection",
      Label: "Warehouse Receipts",
      CharWidth: "35",
      Visible: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnWarehouseRecipts_PROPERTIES

      // END_USER_CODE-USER_btnWarehouseRecipts_PROPERTIES
    },
    ddCompany: {
      name: "ddCompany",
      type: "DropDownFieldWidget",
      parent: "grpbxAppSysMaint",
      Label: "Company",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCompany_PROPERTIES

      // END_USER_CODE-USER_ddCompany_PROPERTIES
    },
    ddCropYear: {
      name: "ddCropYear",
      type: "DropDownFieldWidget",
      parent: "grpbxAppSysMaint",
      Options: "2020:1,2021:2",
      Label: "Crop Year",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCropYear_PROPERTIES

      // END_USER_CODE-USER_ddCropYear_PROPERTIES
    },
    lblSystemMaintenanceSetupLable: {
      name: "lblSystemMaintenanceSetupLable",
      type: "LabelWidget",
      parent: "systemMaintenanceSetupSection",
      Label: "System Maintenance & Setup",
      ColSpan: "5",
      ColSpanForLargeDesktop: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSystemMaintenanceSetupLable_PROPERTIES

      // END_USER_CODE-USER_lblSystemMaintenanceSetupLable_PROPERTIES
    },
    systemMaintenanceSetupSection: {
      name: "systemMaintenanceSetupSection",
      type: "GroupBoxWidget",
      parent: "grpbxAppSysMaint",
      Label: "System Maintenance & Setup",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_systemMaintenanceSetupSection_PROPERTIES

      // END_USER_CODE-USER_systemMaintenanceSetupSection_PROPERTIES
    },
    whtSpc01: {
      name: "whtSpc01",
      type: "WhiteSpaceWidget",
      parent: "applicationSection",
      HasLabel: false,
      // START_USER_CODE-USER_whtSpc01_PROPERTIES

      // END_USER_CODE-USER_whtSpc01_PROPERTIES
    },
    whtSpc02: {
      name: "whtSpc02",
      type: "WhiteSpaceWidget",
      parent: "applicationSection",
      HasLabel: false,
      // START_USER_CODE-USER_whtSpc02_PROPERTIES

      // END_USER_CODE-USER_whtSpc02_PROPERTIES
    },
    grpbxAppSysMaint: {
      name: "grpbxAppSysMaint",
      type: "GroupBoxWidget",
      parent: "MainMenu",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxAppSysMaint_PROPERTIES

      // END_USER_CODE-USER_grpbxAppSysMaint_PROPERTIES
    },
    positionChange : false, //change by FEE
    reportPosition : {}, //change by akash
    paymentPosition : {}, //change by akash
    isCollapsedReport: false, //change
    isCollapsedPaymentProcess: false, //change
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ContractGroupingSearch": {},

        "ContractManagement#ContractInquiry": {},

        "ContractManagement#ContractProfile": {},

        "ContractManagement#ExceptionPremiumSearch": {},

        "Settlements#TradeAgreeSearch": {},

        "Settlements#TradeSettleSearch": {},

        "ContractManagement#FarmProfile":{},

        "AccountPayment#ReadyToPayRelease":{},

        "AccountPayment#PrintChecks":{},

        "SettlementAccount#FarmSummaryReportRequest":{},

        "AccountPayment#NullifyCheck":{},
        "AccountPayment#CheckInquiry":{},
        "AccountPayment#SpecialPayablesInquiry":{},
        "SettlementAccount#UserManual":{},
        "ReportUsermanual#FarmSummaryReportRequest":{},
        "ReportUsermanual#UserManual":{},
        "SystemMaintenance#VoidReasonSetup":{},
        "SystemMaintenance#BuyingPointSetup":{},
        "SystemMaintenanceMasterManagement#PeanutVarietySetup":{},
        "SystemMaintenance#CountySetup":{},
        "SystemMaintenanceMasterManagement#CureCleanScheduleSetup":{},
        "Settlements#FV95Search":{},
        "Settlements#ReportPreviewTrade":{},
        "Settlements#NoSaleVoidFV95":{},
        "SystemMaintenanceMasterManagement#WarehouseBinSetup":{},
        "SystemMaintenanceApplicationSupport#VendorSearch":{},
        "SystemMaintenanceSpecialFunctions#PremiumDeducMasterSetup":{},
        "WarehouseReceipts#1007GrpSearch":{},
        "StockTransfer#MultiStopProfile":{},
        "WarehouseReceipts#WarehouseReceiptInquiry":{},
        "StockTransfer#TransferSearch":{},
        "WarehouseReceipts#Delivery":{}
      },
      REVERSE: {
        "ContractManagement#ContractGroupingSearch": {},

        "ContractManagement#ContractInquiry": {},

        "ContractManagement#ContractProfile": {},

        "ContractManagement#ExceptionPremiumSearch": {},

        "Settlements#TradeAgreeSearch": {},

        "Settlements#TradeSettleSearch": {},

        "ContractManagement#FarmProfile":{},

        "AccountPayment#ReadyToPayRelease":{},

        "AccountPayment#PrintChecks":{},

        "SettlementAccount#FarmSummaryReportRequest":{},

        "AccountPayment#NullifyCheck":{},
        "AccountPayment#CheckInquiry":{},
        "AccountPayment#SpecialPayablesInquiry":{},
        "SettlementAccount#UserManual":{},
        "ReportUsermanual#FarmSummaryReportRequest":{},
        "ReportUsermanual#UserManual":{},
        "SystemMaintenanceMasterManagement#VoidReasonSetup":{},
        "SystemMaintenance#BuyingPointSetup":{},
        "SystemMaintenanceMasterManagement#PeanutVarietySetup":{},
        "SystemMaintenance#CountySetup":{},
        "SystemMaintenanceMasterManagement#CureCleanScheduleSetup":{},
        "Settlements#FV95Search":{},
        "Settlements#ReportPreviewTrade":{},
        "Settlements#NoSaleVoidFV95":{},
        "SystemMaintenanceMasterManagement#WarehouseBinSetup":{},
        "SystemMaintenanceApplicationSupport#VendorSearch":{},
        "SystemMaintenanceSpecialFunctions#PremiumDeducMasterSetup":{},
        "WarehouseReceipts#1007GrpSearch":{},
        "StockTransfer#MultiStopProfile":{},
        "WarehouseReceipts#WarehouseReceiptInquiry":{},
        "StockTransfer#TransferSearch":{},
        "WarehouseReceipts#Delivery":{},
        "SystemMaintenanceSpecialFunctions#LoanRepaySetup":{}
      },
    },
  };
  let _buttonServices = {
    ContractGroupingSearch: {},
    ContractInquiry: {},
    ContractProfile: {},
    ExceptionPremiumSearch: {},
    TradeAgreeSearch: {},
    TradeSettlementSearch: {},
    FarmProfile:{},
    ReadyToPayRelease:{}
  };
  let _buttonNavigation = {
    btnNewContract: {
      DEFAULT: ["ContractManagement#ContractProfile#DEFAULT#false#Click"],
    },
    btnSearchForContracts: {
      DEFAULT: ["ContractManagement#ContractInquiry#DEFAULT#false#Click"],
    },
    btnContractGroupings: {
      DEFAULT: [
        "ContractManagement#ContractGroupingSearch#DEFAULT#false#Click",
      ],
    },
    btnTradeAgreements: {
      DEFAULT: ["Settlements#TradeAgreeSearch#DEFAULT#false#Click"],
    },
    btnTradeSettlements: {
      DEFAULT: ["Settlements#TradeSettleSearch#DEFAULT#false#Click"],
    },
    btnExceptionPremiums: {
      DEFAULT: [
        "ContractManagement#ExceptionPremiumSearch#DEFAULT#false#Click",
      ],
    },
    btnFarms: {
      DEFAULT: [
        "SystemMaintenanceMasterManagement#FarmInquiry#DEFAULT#false#Click",
      ],
    },
    btnPrintChecks: {
      // click Event
      DEFAULT: [
        "AccountPayment#PrintChecks#DEFAULT#false#Click",
      ],
    },
    btnReadyToPayRelease:{
      DEFAULT: [
        "AccountPayment#ReadyToPayRelease#DEFAULT#false#Click",
      ],
    },
    btnFarmSumm:{
      DEFAULT: [
        "SettlementAccount#FarmSummaryReportRequest#DEFAULT#false#Click",
      ],
    },
    btnSpoiledCheckEntry:{
      DEFAULT: [
        "AccountPayment#NullifyCheck#DEFAULT#false#Click",
      ],
    },
    btnInquireVoidCorrectChecks:{
      DEFAULT: [
        "AccountPayment#CheckInquiry#DEFAULT#false#Click",
      ],
    },
    btnSpecialPayables:{
      DEFAULT: [
        "AccountPayment#SpecialPayablesInquiry#DEFAULT#false#Click",
      ],
    },
    btnFarmSumm:{
      DEFAULT: [
        "ReportUsermanual#FarmSummaryReportRequest#DEFAULT#false#Click",
      ],
    },
    btnUserManuals:{
      DEFAULT: [
        "ReportUsermanual#UserManual#DEFAULT#false#Click",
      ],
    },
    btnVoidNoSaleSetup:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#VoidReasonSetup#DEFAULT#false#Click",]
    },
    btnBuyingPoints:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#BuyingPointSetup#DEFAULT#false#Click",]
    },
    btnFilters:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#Filters#DEFAULT#false#Click",]
    },
    btnInterestAgreement:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#InterestAgreementSetup#DEFAULT#false#Click",]
    },
    btnPeanuts:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#PeanutVarietySetup#DEFAULT#false#Click",]
    },
    btnStateCountries:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#CountySetup#DEFAULT#false#Click",]
    },
    btnCureCleanSchedule:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#CureCleanScheduleSetup#DEFAULT#false#Click",]
    },
    btnFreightControl:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#FreightMenu#DEFAULT#false#Click",]
    },
    btnPeanutSpecifications:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#PeanutSpecificationSetup#DEFAULT#false#Click",]
    },
    btnPremDeducsRates:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#PremiumDeductionScheduleSetup#DEFAULT#false#Click",]
    },
    btnFarmerStockPriceSetup :{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#FarmerStockPriceSetup#DEFAULT#false#Click",]
    },
    btnContractLimitControl:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#ContractLimitSearch#DEFAULT#false#Click",]
    },
    btnSearchFor: {
      DEFAULT: ["Settlements#FV95Search#DEFAULT#false#Click"],
    },
    btnVoidNoSaleSC95: {
      DEFAULT: ["Settlements#NoSaleVoidFV95#DEFAULT#false#Click"],
    },
    btnStorageSchedule:{
        DEFAULT:[
        "SystemMaintenanceMasterManagement#WarehouseBinSetup#DEFAULT#false#Click",]
      },
      btnFederalWhseLic:{
        DEFAULT:[
        "SystemMaintenanceMasterManagement#FedWhouseLicenseSetup#DEFAULT#false#Click",]
      },
      btnVendoeMaintenance:{
        DEFAULT:[
        "SystemMaintenanceApplicationSupport#VendorSearch#DEFAULT#false#Click",]
      },
      btnSpecialFunction:{
        DEFAULT:[
        "SystemMaintenanceSpecialFunctions#SpecialFunctions#DEFAULT#false#Click",]
      },
      btnLoanRepayment:{
        DEFAULT:[
        "SystemMaintenanceSpecialFunctions#LoanRepaySetup#DEFAULT#false#Click",]
      },
      btnInventory:{
        DEFAULT:[
        "SystemMaintenanceApplicationSupport#InventoryInquiry#DEFAULT#false#Click",]
      },
      btnPremDeducsMaster:{
        DEFAULT:[
        "SystemMaintenanceSpecialFunctions#PremiumDeducMasterSetup#DEFAULT#false#Click",]
      },
      btnSignatureSetup:{
        DEFAULT:[
        "SystemMaintenanceSpecialFunctions#UserSignatureSetup#DEFAULT#false#Click",]
      },
      btnSpotPrice:{
        DEFAULT:[
        "SystemMaintenanceSpecialFunctions#SpotControlSetup#DEFAULT#false#Click",]
      },
      btnOpenAccounts:{
        DEFAULT:[
        "SystemMaintenanceApplicationSupport#OpenAccountsSetup#DEFAULT#false#Click",]
      },
      btnGroupings1007: {
        DEFAULT: ["WarehouseReceipts#1007GrpSearch#DEFAULT#false#Click"],
      },
      btnStockTransfers:{
        DEFAULT:[
        "StockTransfer#MultiStopProfile#DEFAULT#false#Click",]
      },
      btnWarehouseRecipts:{
        DEFAULT:[
        "WarehouseReceipts#WarehouseReceiptInquiry#DEFAULT#false#Click",]
      },
      btnStockTransfers:{
        DEFAULT:[
        "StockTransfer#TransferSearch#DEFAULT#false#Click",]
      },
      btnDeliveryInOut:{
        DEFAULT:[
        "WarehouseReceipts#Delivery#DEFAULT#false#Click",]
      },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

   // Enable or Disable Controls based on roles of the user
  function EnableDisableMenuButtons() {
    let phase12flag = "N"; //added for phase 1 extra buttons disable
    let cropyear = JSON.parse(sessionStorage.getItem('year'));
    let compId = sessionStorage.getItem('compId');
    let userid = sessionStorage.getItem("userid");

    let userroles = sessionStorage.getItem("roles");
    //disableMenuButtons() // disable all buttons before enable based on roles
    const roles_array = userroles.split(',');
    for (let i = 0; i < roles_array.length; i++) {
      const role = roles_array[i];
      if (role != "") {
        let funcid = role.substring(0,6);
        var subfuncid = role.substring(7,10);
        var funcAccess = role.substring(11,12);
        //this true block implemented for Phase 1 & 2 extra buttons disable
        if (phase12flag == 'Y'){
          if (funcid == 'PN1010' || funcid == 'PN1020' || funcid == 'PN1150' || funcid == 'PN1190' || funcid == 'PN1040' || funcid == 'PN1130' || funcid == 'PN1140' || funcid == 'PN1060' || funcid == 'PN1050' || funcid == 'PN1030' || funcid == 'PN1100')
          {
            enableMenuButtons(funcid);
          } // pushing for enabling phase 3 modules buttons in UAT.
          // if (funcid == 'PN1090' || funcid == 'PN1080' || funcid == 'PN1160' || funcid == 'PN1180')
          // {
          //   // nothing do
          // } else {
          //   enableMenuButtons(funcid);
          // }

          const controlname1 = "btnReports";
          setState((previouState)=>({ 
            ...previouState,
            [controlname1] :{
            ...state[controlname1],
            Visible: true // for Phase 2 only
            }
            }));
          
          const controlname = "btnUserManuals";
          setState((previouState)=>({ 
            ...previouState,
            [controlname] :{
            ...state[controlname],
            Visible: false
            }
            }));
        }
        else {
          enableMenuButtons(funcid);
        }

        // this is for Special Payables button enable
        if (funcid == 'PN1100'){
          ContractManagementService.RetrieveAccessPermissionDetails(funcid, '005', 'U', '').then(response => {
            if (response[0].permission == 'Y') {
              let posChg = state.positionChange; //
              setState((previouState)=>({ 
                ...previouState,
                btnSpecialPayables :{
                ...state.btnSpecialPayables,
                Visible: true
                }
                }));
            }
            else{
              ContractManagementService.RetrieveAccessPermissionDetails(funcid, '005', 'I', '').then(response => {
                if (response[0].permission == 'Y') {
                  let posChg = state.positionChange;
                  setState((previouState)=>({ 
                    ...previouState,
                    btnSpecialPayables :{
                    ...state.btnSpecialPayables,
                    Visible: true
                    }
                    }));
                }
              });
            }  
          });
        }
      }     
    }
  }

  function enableMenuButtons(funcid) {
    for (let j = 0; j< dictRolesControls.length; j++) {
      if (funcid == dictRolesControls[j].key)
      {
        const controlname = dictRolesControls[j].value;
        setState((previouState)=>({ 
          ...previouState,
          [controlname] :{
          ...state[controlname],
          Visible: true
          }
          }));
      }
    }
    let posChg = state.positionChange;
    setTimeout(()=>{
      setState((previouState)=>({ 
      ...previouState,
      positionChange: !posChg
      }));
    },0)
  };

  function disableMenuButtons() {
    for (let j = 0; j< dictRolesControls.length; j++) {
      //if (dictRolesControls[j].key != 'PN0000')
      //{
        const controlname = dictRolesControls[j].value;
        setState((previouState)=>({ 
          ...previouState,
          [controlname] :{
          ...state[controlname],
          Visible: false
          }
          }));
      //}
    }
  };
  // Enable or Disable Controls End

  useEffect(() => {
    parentWindow(thisObj);
  });
  window.onresize = resize;
  function resize() {
    let valChange = state.positionChange;
    thisObj.setState((current) => {
      return {
        ...current,
        positionChange: !valChange
      }
    });
  }
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    
    sessionStorage.setItem('compId', 'B9');
    FormLoad();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  
  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let rectRep = document.getElementById("btnReports").getBoundingClientRect();
    
    thisObj.setState(current => {
      return {
        ...current,
        reportPosition: rectRep
      }
    })
    let rectPos = document.getElementById("btnPaymentProcessing").getBoundingClientRect();
    thisObj.setState(current => {
      return {
        ...current,
        paymentPosition: rectPos
      }
    });
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [state.positionChange]);

  const GetCompanyList = () => {
    ContractManagementService.RetrieveCompanyDetails().then(response => {
      let data = response;
      let selElem = data.find(elem => elem.compId === sessionStorage.getItem('compId')).compName;
      if(selElem !==null && selElem !==undefined){
        sessionStorage.setItem('Companyname', selElem);
      }
      let js = [];
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].compId, description: data[i].compName }
        js.push(obj);
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCompany: {
            ...state["ddCompany"],
            valueList: js
          }
        }
      });
      
    });
    setValue(thisObj, 'ddCompany', sessionStorage.getItem('compId'));
  }

  const GetCropYearList = () => {
    ContractManagementService.RetrieveCropYears().then(response => {
      let data = response;
      let defaultYear = '';
      let cropYrStartDate = '';
      let cropYrEndDate = '';
      let currentDate = '';
      let cropYearMsg = '';
      let js = [];
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].cropYear, description: data[i].cropYear }
        defaultYear = data[i].default_Year;
        //defaultCropYear = defaultYear;
        setData(thisObj,'defaultCropYear',defaultYear);
        if(defaultYear == data[i].cropYear)
        {
          cropYrStartDate = data[i].cropYearStartDate;
          cropYrEndDate = data[i].cropYearEndDate;
          currentDate = data[i].cuR_SYS_DATE;
        }
        js.push(obj);
      }
      // cropYearMsg = 'Crop Year: ' + defaultYear + '\n' + '\n' + 'Start Date : ' + cropYrStartDate + '\n' + 'End Date   : ' + cropYrEndDate + '\n' + '\n' + ' Current Date   : ' + currentDate;
      // sessionStorage.setItem('cropYearMessage', cropYearMsg); //message if user click on crop year lable on header.js file

      let usercropyear = sessionStorage.getItem('userselectedyear');
      if (usercropyear !== "" && usercropyear !== null)
      {
        setValue(thisObj, 'ddCropYear', sessionStorage.getItem('userselectedyear'));
      }
      else{
        sessionStorage.setItem('year', defaultYear);
        setValue(thisObj, 'ddCropYear', sessionStorage.getItem('year'));
      }
      let userid = sessionStorage.getItem("userid");
      GetUserRoles(userid);

      thisObj.setState(current => {
        return {
          ...current,
          ddCropYear: {
            ...state["ddCropYear"],
            valueList: js
          }
        }
      });
    });
    let cropyear = sessionStorage.getItem('year');
    setValue(thisObj, 'ddCropYear', cropyear);
  }
  
  function FormLoad() {
    GetCompanyList();
    GetCropYearList();
  }

  const GetUserRoles = (uid) => {
    let compId = sessionStorage.getItem('compId');
    //let userid = sessionStorage.getItem("userid");
    let cropyear = sessionStorage.getItem('year');
    ContractManagementService.RetrieveUserRoles(compId, uid, cropyear).then(response => {
      let data = response;
      if (data.length>0){
        let userroles = data[0].roles;
        if (userroles != "")
        {
          sessionStorage.setItem('roles', userroles);
          disableMenuButtons() // disable all buttons before enable based on roles
          EnableDisableMenuButtons();
        }
      }
      setLoading(false);
    });
  }

  const onddCropYearChange = () => {
    let list = thisObj.state['ddCropYear'].valueList;
    let val = thisObj.values['ddCropYear'];
    let selElem = list.find(elem => elem.key === Number(val));
    thisObj.setState(current => {
      return {
        ...current,
        headerDetails: {
          ...state["headerDetails"],
          year: selElem.description
        }
      }
    });
    sessionStorage.setItem('year', JSON.stringify(selElem.description));
    sessionStorage.setItem('userselectedyear', JSON.stringify(selElem.description));
    //disableMenuButtons() // disable all buttons before enable based on roles
    let userid = sessionStorage.getItem("userid");
    GetUserRoles(userid);
    //EnableDisableMenuButtons();
  };
  thisObj.onddCropYearChange = onddCropYearChange;

  const onddCompanyChange = () => {
    let list = thisObj.state['ddCompany'].valueList;
    let val = thisObj.values['ddCompany'];
    let selElem = list.find(elem => elem.key === val);
    // sessionStorage.setItem('Companyname', JSON.stringify(selElem.description));
    // sessionStorage.setItem('compId', JSON.stringify(selElem.key));
    sessionStorage.setItem('Companyname', selElem.description);
    sessionStorage.setItem('compId', selElem.key);
    let userid = sessionStorage.getItem("userid");
    GetUserRoles(userid);
  };
  thisObj.onddCompanyChange = onddCompanyChange;
  // START_USER_CODE-USER_METHODS
  // const onbtnWarehouseReciptsClick = () => {
  //   try {
  //     //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  //     //goTo(thisObj,"SettlementAccount#ReadyToPayRelease#DEFAULT#true#Click")
  //     // goTo(thisObj,"ContractManagement#FarmProfile#DEFAULT#true#Click")
  //     goTo(thisObj,"ContractManagement#VendorLookup#DEFAULT#true#Click","F",false)
  //     //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  //   } catch (err) {
  //     if (err instanceof EvalError) {
  //       showMessage(thisObj, err.message);
  //     } else {
  //       showMessage(
  //         thisObj,
  //         "Exception in PreSubmit Event code for widget:btnOpen event:Click"
  //       );
  //     }
  //     return false;
  //   }
  //   return true;
  // };
  // thisObj.onbtnWarehouseReciptsClick = onbtnWarehouseReciptsClick;
  // const onbtnReadyToPayReleaseClick = () => {
  //   try {
  //     //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  //     //goTo(thisObj,"SettlementAccount#ReadyToPayRelease#DEFAULT#true#Click")
  //     goTo(thisObj,"ContractManagement#FarmProfile#DEFAULT#true#Click")
  //     //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
  //   } catch (err) {
  //     if (err instanceof EvalError) {
  //       showMessage(thisObj, err.message);
  //     } else {
  //       showMessage(
  //         thisObj,
  //         "Exception in PreSubmit Event code for widget:btnOpen event:Click"
  //       );
  //     }
  //     return false;
  //   }
    
  //   return true;
  // };
  // thisObj.onbtnReadyToPayReleaseClick = onbtnReadyToPayReleaseClick;

  const onbtnExitClick = () =>{
    window.localStorage.clear();
    authProvider.logout();
  };
  thisObj.onbtnExitClick = onbtnExitClick;
//change start
  const onBtnPaymentProcessOver = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      let isCollapsedPaymentProcess = state.isCollapsedPaymentProcess;      
      setState(current => {       
         return {
          ...current,
          isCollapsedPaymentProcess: !isCollapsedPaymentProcess
        };
      });
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnOpen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onBtnPaymentProcessOver = onBtnPaymentProcessOver;
  
  const onBtnReportsOver = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      let isCollapsedReport = state.isCollapsedReport;
      setState(current => {
        return {
          ...current,
          isCollapsedReport: !isCollapsedReport
        };
      });
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnOpen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onBtnReportsOver = onBtnReportsOver;
  //change end
  // END_USER_CODE-USER_METHODS

// btnViewReports Click  
const onbtnViewReportsClick = async() => {
  try{
    setLoading(true)
    let response = await AccountPayment.RetrievePpsReportDetails()
    
    if([ undefined, null, "" ].includes(response[0]?.recipientID)){
      let data = btoa(JSON.stringify({
        recipientID : response[0]?.recipientID || '',
        password    : response[0]?.password || '',
        serverID    : "InfoPac",
        redirect    : true,
        type        : "6"
      }))
      
      window.open(`${response[0].url}?data=${data}`)
    }
    else{
      var newWindow = window.open();
      
      let actionurl = response[0].url 
      let recipientID = response[0].recipientID
      let password = response[0].password

      let formHTML = '<html> <body> <form name="login" method="POST" action="' + actionurl + '">';
      formHTML += '<input type="HIDDEN" name="serverID" value="InfoPac">';
      formHTML += '<input type="HIDDEN" name="recipientID" value="' + recipientID + '">';
      formHTML += '<input type="HIDDEN" name="password" value="' + password + '">';
      formHTML += '<input type="HIDDEN" name="type" value="6">';
      formHTML += '<input type="HIDDEN" name="redirect" value="true">';
      formHTML += '</form>';

      formHTML += '<script language="javascript"> document.login.submit() </script> </body> </html>';
      newWindow.document.write(formHTML);
    }    
  }
   catch(err){
    showMessage(thisObj, err?.message)
  }
  finally{
    setLoading(false)    
  }
}
thisObj.onbtnViewReportsClick = onbtnViewReportsClick;  
// btnViewReports Click

// btnProcReport
const onbtnProcReportClick = async () => {
  let response = await ContractManagementService.RetrievePeanutStaticValues()
  if (response !== undefined && response !== null && response !== '') {
    var reportURL = ''
    let ad_hoc = response[0].pps_defaults.ad_hoc_report;
    if (ad_hoc !== undefined && ad_hoc !== null && ad_hoc !== '') {
      if (process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT') {
        if (ad_hoc.test.available == 'Y') {
          reportURL = ad_hoc.test.link;
        }
        else {
          showMessage(thisObj, 'Test PPS Procurement Reporting is currently not available.')
        }
      }
      else if (process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'LOCAL') {
        if (ad_hoc.dev.available == 'Y') {
          reportURL = ad_hoc.dev.link;
        }
        else {
          showMessage(thisObj, 'Dev PPS Procurement Reporting is currently not available.')
        }
      }
      else if (process.env.REACT_APP_ENVIR == 'PROD') {
        if (ad_hoc.prod.available == 'Y') {
          reportURL = ad_hoc.prod.link;
        }
        else {
          showMessage(thisObj, 'PPS Procurement Reporting is currently not available.')
        }
      }
      window.open(reportURL)
    }
    else {
      showMessage(thisObj, 'Cannot find the Ad-hoc Reporting Website')
    }
  }
  else {
    showMessage(thisObj, 'Cannot find the Ad-hoc Reporting Website')
  }
};
thisObj.onbtnProcReportClick = onbtnProcReportClick;
// btnProcReport

// btnNewContract Click
  const onbtnNewContractClick = () => {
    let selectedCropYear = thisObj.values['ddCropYear'];
    let defaultCropYear = getData(thisObj,'defaultCropYear');
    if (selectedCropYear == defaultCropYear) {
      goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#false#Click");
      return true;
    } else {
      if (confirm("The Crop Year you have seleted is not the current Crop Year " + '[' + defaultCropYear + ']' + ' Do you wish to continue ?') == true) {
        goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#false#Click");
        return true;
      }
    }
  }
  thisObj.onbtnNewContractClick = onbtnNewContractClick;
  // btnNewContract Click

  
   // btnNewContract Click
   const onbtnSearchForContractsClick = () => {
    let selectedCropYear = thisObj.values['ddCropYear'];
    let defaultCropYear = getData(thisObj,'defaultCropYear');
    if (selectedCropYear == defaultCropYear) {
      goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
      return true;
    } else {
      if (confirm("The Crop Year you have seleted is not the current Crop Year " + '[' + defaultCropYear + ']' + ' Do you wish to continue ?') == true) {
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    }
  }
  thisObj.onbtnSearchForContractsClick = onbtnSearchForContractsClick;
  // btnNewContract Click

   // btnNewContract Click
   const onbtnContractGroupingsClick = () => {
    let selectedCropYear = thisObj.values['ddCropYear'];
    let defaultCropYear = getData(thisObj,'defaultCropYear');
    if (selectedCropYear == defaultCropYear) {
      goTo(thisObj, "ContractManagement#ContractGroupingSearch#DEFAULT#false#Click");
      return true;
    } else {
      if (confirm("The Crop Year you have seleted is not the current Crop Year " + '[' + defaultCropYear + ']' + ' Do you wish to continue ?') == true) {
        goTo(thisObj, "ContractManagement#ContractGroupingSearch#DEFAULT#false#Click");
        return true;
      }
    }
  }
  thisObj.onbtnContractGroupingsClick = onbtnContractGroupingsClick;
  // btnNewContract Click

  // btnNewContract Click
  const onbtnExceptionPremiumsClick = () => {
    let selectedCropYear = thisObj.values['ddCropYear'];
    let defaultCropYear = getData(thisObj,'defaultCropYear');
    if (selectedCropYear == defaultCropYear) {
      goTo(thisObj, "ContractManagement#ExceptionPremiumSearch#DEFAULT#false#Click");
      return true;
    } else {
      if (confirm("The Crop Year you have seleted is not the current Crop Year " + '[' + defaultCropYear + ']' + ' Do you wish to continue ?') == true) {
        goTo(thisObj, "ContractManagement#ExceptionPremiumSearch#DEFAULT#false#Click");
        return true;
      }
    }
  }
  thisObj.onbtnExceptionPremiumsClick = onbtnExceptionPremiumsClick;
  // btnNewContract Click


  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_MainMenu*/}

              {/* END_USER_CODE-USER_BEFORE_MainMenu*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxAppSysMaint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAppSysMaint*/}

              <GroupBoxWidget conf={state.grpbxAppSysMaint} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_ddCropYear*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCropYear}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCropYear*/}

                {/* END_USER_CODE-USER_AFTER_ddCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_ddCompany*/}

                {/* END_USER_CODE-USER_BEFORE_ddCompany*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCompany}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCompany*/}

                {/* END_USER_CODE-USER_AFTER_ddCompany*/}
                {/* START_USER_CODE-USER_BEFORE_applicationSection*/}

                {/* END_USER_CODE-USER_BEFORE_applicationSection*/}

                <GroupBoxWidget
                  conf={state.applicationSection}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnApplicationLable*/}

                  {/* END_USER_CODE-USER_BEFORE_btnApplicationLable*/}

                  <LabelWidget
                    values={values}
                    conf={state.btnApplicationLable}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_btnApplicationLable*/}

                  {/* END_USER_CODE-USER_AFTER_btnApplicationLable*/}
                  {/* START_USER_CODE-USER_BEFORE_btnNewContract*/}

                  {/* END_USER_CODE-USER_BEFORE_btnNewContract*/}

                  <ButtonWidget
                    conf={state.btnNewContract}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnNewContract*/}

                  {/* END_USER_CODE-USER_AFTER_btnNewContract*/}
                  {/* START_USER_CODE-USER_BEFORE_whtSpc01*/}

                  {/* END_USER_CODE-USER_BEFORE_whtSpc01*/}

                  {/* <WhiteSpaceWidget
                    conf={state.whtSpc01}
                    screenConf={state}
                  ></WhiteSpaceWidget> */}
                  {/* START_USER_CODE-USER_AFTER_whtSpc01*/}

                  {/* END_USER_CODE-USER_AFTER_whtSpc01*/}
                  {/* START_USER_CODE-USER_BEFORE_whtSpc02*/}

                  {/* END_USER_CODE-USER_BEFORE_whtSpc02*/}

                  {/* <WhiteSpaceWidget
                    conf={state.whtSpc02}
                    screenConf={state}
                  ></WhiteSpaceWidget> */}
                  {/* START_USER_CODE-USER_AFTER_whtSpc02*/}

                  {/* END_USER_CODE-USER_AFTER_whtSpc02*/}
                                    {/* START_USER_CODE-USER_BEFORE_btnSearchForContracts*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearchForContracts*/}

                  <ButtonWidget
                    conf={state.btnSearchForContracts}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearchForContracts*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearchForContracts*/}
                  {/* START_USER_CODE-USER_BEFORE_btnContractGroupings*/}

                  {/* END_USER_CODE-USER_BEFORE_btnContractGroupings*/}

                  <ButtonWidget
                    conf={state.btnContractGroupings}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnContractGroupings*/}

                  {/* END_USER_CODE-USER_AFTER_btnContractGroupings*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExceptionPremiums*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExceptionPremiums*/}

                  <ButtonWidget
                    conf={state.btnExceptionPremiums}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExceptionPremiums*/}

                  {/* END_USER_CODE-USER_AFTER_btnExceptionPremiums*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_btnReports*/}

                  {/* END_USER_CODE-USER_BEFORE_btnReports*/}

                  <ButtonWidget
                    conf={state.btnReports}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onHover={onBtnReportsOver}  //change
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnReports*/}
                  {/* //change start */}
                  <Modal show={state.isCollapsedReport} onMouseLeave={onBtnReportsOver} onHide={onBtnReportsOver} id="ReportModal"
                  style={{ left: state.reportPosition.x, top: state.reportPosition.y}}>
                  {/* <Modal.Header closeButton onClick={this.onlnkSearchHistoryHover}></Modal.Header> */}
                  <Modal.Body>
                  <GroupBoxWidget
                    conf={state.reportBtnList}
                    screenConf={state}
                  >
                    <ButtonWidget
                      conf={state.btnFarmSumm}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                    <ButtonWidget
                      conf={state.btnViewReports}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                    <ButtonWidget
                      conf={state.btnProcReport}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                  </GroupBoxWidget>
                  </Modal.Body>
                  </Modal>
                  {/* change end */}
                  {/* END_USER_CODE-USER_AFTER_btnReports*/}
      {/* START_USER_CODE-USER_BEFORE_btnWarehouseRecipts*/}

                  {/* END_USER_CODE-USER_BEFORE_btnWarehouseRecipts*/}

                  <ButtonWidget
                    conf={state.btnWarehouseRecipts}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnWarehouseRecipts*/}

                  {/* END_USER_CODE-USER_AFTER_btnWarehouseRecipts*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVoidNoSaleSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVoidNoSaleSC95*/}

                  <ButtonWidget
                    conf={state.btnVoidNoSaleSC95}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVoidNoSaleSC95*/}

                  {/* END_USER_CODE-USER_AFTER_btnVoidNoSaleSC95*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearchFor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearchFor*/}

                  <ButtonWidget
                    conf={state.btnSearchFor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearchFor*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearchFor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnStockTransfers*/}

                  {/* END_USER_CODE-USER_BEFORE_btnStockTransfers*/}

                  <ButtonWidget
                    conf={state.btnStockTransfers}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnStockTransfers*/}

                  {/* END_USER_CODE-USER_AFTER_btnStockTransfers*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPaymentProcessing*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPaymentProcessing*/}

                  <ButtonWidget
                    conf={state.btnPaymentProcessing}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onHover={onBtnPaymentProcessOver} //change
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPaymentProcessing*/}
                  {/* change start */}
                  <Modal show={state.isCollapsedPaymentProcess} onMouseLeave={onBtnPaymentProcessOver} onHide={onBtnPaymentProcessOver} id="PaymentProcessModal"
                  style={{ left: state.paymentPosition.x, top: state.paymentPosition.y}}>
                  {/* <Modal.Header closeButton onClick={this.onlnkSearchHistoryHover}></Modal.Header> */}
                  <Modal.Body>
                  <GroupBoxWidget
                    conf={state.paymentProcessBtnList}
                    screenConf={state}
                  >
                    <ButtonWidget
                      conf={state.btnReadyToPayRelease}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                    <ButtonWidget
                      conf={state.btnPrintChecks}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                    <ButtonWidget
                      conf={state.btnSpoiledCheckEntry}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                    <ButtonWidget
                      conf={state.btnInquireVoidCorrectChecks}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                    <ButtonWidget
                      conf={state.btnSpecialPayables}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )}
                    ></ButtonWidget>
                  </GroupBoxWidget>
                  </Modal.Body>
                  </Modal>
                  {/* change end */}
                  {/* END_USER_CODE-USER_AFTER_btnPaymentProcessing*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_btnTradeAgreements*/}

                  {/* END_USER_CODE-USER_BEFORE_btnTradeAgreements*/}

                  <ButtonWidget
                    conf={state.btnTradeAgreements}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnTradeAgreements*/}

                  {/* END_USER_CODE-USER_AFTER_btnTradeAgreements*/}
                  {/* START_USER_CODE-USER_BEFORE_btnTradeSettlements*/}

                  {/* END_USER_CODE-USER_BEFORE_btnTradeSettlements*/}

                  <ButtonWidget
                    conf={state.btnTradeSettlements}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnTradeSettlements*/}

                  {/* END_USER_CODE-USER_AFTER_btnTradeSettlements*/}
                  {/* START_USER_CODE-USER_BEFORE_btnGroupings1007*/}

                  {/* END_USER_CODE-USER_BEFORE_btnGroupings1007*/}

                  <ButtonWidget
                    conf={state.btnGroupings1007}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnGroupings1007*/}

                  {/* END_USER_CODE-USER_AFTER_btnGroupings1007*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUserManuals*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUserManuals*/}

                  <ButtonWidget
                    conf={state.btnUserManuals}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUserManuals*/}

                  {/* END_USER_CODE-USER_AFTER_btnUserManuals*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeliveryInOut*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeliveryInOut*/}

                  <ButtonWidget
                    conf={state.btnDeliveryInOut}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeliveryInOut*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeliveryInOut*/}
                  
                  
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_applicationSection*/}

                {/* END_USER_CODE-USER_AFTER_applicationSection*/}
                {/* START_USER_CODE-USER_BEFORE_systemMaintenanceSetupSection*/}

                {/* END_USER_CODE-USER_BEFORE_systemMaintenanceSetupSection*/}

                <GroupBoxWidget
                  conf={state.systemMaintenanceSetupSection}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSystemMaintenanceSetupLable*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSystemMaintenanceSetupLable*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSystemMaintenanceSetupLable}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSystemMaintenanceSetupLable*/}

                  {/* END_USER_CODE-USER_AFTER_lblSystemMaintenanceSetupLable*/}
                  {/* START_USER_CODE-USER_BEFORE_btnStateCountries*/}

                  {/* END_USER_CODE-USER_BEFORE_btnStateCountries*/}

                  <ButtonWidget
                    conf={state.btnStateCountries}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnStateCountries*/}

                  {/* END_USER_CODE-USER_AFTER_btnStateCountries*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFarms*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFarms*/}

                  <ButtonWidget
                    conf={state.btnFarms}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFarms*/}

                  {/* END_USER_CODE-USER_AFTER_btnFarms*/}
                  {/* START_USER_CODE-USER_BEFORE_btnScaleTicket*/}

                  {/* END_USER_CODE-USER_BEFORE_btnScaleTicket*/}

                  <ButtonWidget
                    conf={state.btnScaleTicket}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnScaleTicket*/}

                  {/* END_USER_CODE-USER_AFTER_btnScaleTicket*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPeanutSpecifications*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPeanutSpecifications*/}

                  <ButtonWidget
                    conf={state.btnPeanutSpecifications}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPeanutSpecifications*/}

                  {/* END_USER_CODE-USER_AFTER_btnPeanutSpecifications*/}
                  {/* START_USER_CODE-USER_BEFORE_btnInventory*/}

                  {/* END_USER_CODE-USER_BEFORE_btnInventory*/}

                  <ButtonWidget
                    conf={state.btnInventory}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnInventory*/}

                  {/* END_USER_CODE-USER_AFTER_btnInventory*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPeanuts*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPeanuts*/}

                  <ButtonWidget
                    conf={state.btnPeanuts}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPeanuts*/}

                  {/* END_USER_CODE-USER_AFTER_btnPeanuts*/}
                  {/* START_USER_CODE-USER_BEFORE_btnStorageSchedule*/}

                  {/* END_USER_CODE-USER_BEFORE_btnStorageSchedule*/}

                  <ButtonWidget
                    conf={state.btnStorageSchedule}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnStorageSchedule*/}

                  {/* END_USER_CODE-USER_AFTER_btnStorageSchedule*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPremDeducsMaster*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPremDeducsMaster*/}

                  <ButtonWidget
                    conf={state.btnPremDeducsMaster}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPremDeducsMaster*/}

                  {/* END_USER_CODE-USER_AFTER_btnPremDeducsMaster*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSpotPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSpotPrice*/}

                  <ButtonWidget
                    conf={state.btnSpotPrice}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSpotPrice*/}

                  {/* END_USER_CODE-USER_AFTER_btnSpotPrice*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOpenAccounts*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpenAccounts*/}

                  <ButtonWidget
                    conf={state.btnOpenAccounts}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpenAccounts*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpenAccounts*/}
                  {/* START_USER_CODE-USER_BEFORE_btnBuyingPoints*/}

                  {/* END_USER_CODE-USER_BEFORE_btnBuyingPoints*/}

                  <ButtonWidget
                    conf={state.btnBuyingPoints}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnBuyingPoints*/}

                  {/* END_USER_CODE-USER_AFTER_btnBuyingPoints*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCureCleanSchedule*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCureCleanSchedule*/}

                  <ButtonWidget
                    conf={state.btnCureCleanSchedule}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCureCleanSchedule*/}

                  {/* END_USER_CODE-USER_AFTER_btnCureCleanSchedule*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPremDeducsRates*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPremDeducsRates*/}

                  <ButtonWidget
                    conf={state.btnPremDeducsRates}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPremDeducsRates*/}

                  {/* END_USER_CODE-USER_AFTER_btnPremDeducsRates*/}
                  {/* START_USER_CODE-USER_BEFORE_btnInterestAgreement*/}

                  {/* END_USER_CODE-USER_BEFORE_btnInterestAgreement*/}

                  <ButtonWidget
                    conf={state.btnInterestAgreement}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnInterestAgreement*/}

                  {/* END_USER_CODE-USER_AFTER_btnInterestAgreement*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFederalWhseLic*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFederalWhseLic*/}

                  <ButtonWidget
                    conf={state.btnFederalWhseLic}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFederalWhseLic*/}

                  {/* END_USER_CODE-USER_AFTER_btnFederalWhseLic*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVoidNoSaleSetup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVoidNoSaleSetup*/}

                  <ButtonWidget
                    conf={state.btnVoidNoSaleSetup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVoidNoSaleSetup*/}

                  {/* END_USER_CODE-USER_AFTER_btnVoidNoSaleSetup*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVoidWRcontrolNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVoidWRcontrolNumber*/}

                  <ButtonWidget
                    conf={state.btnVoidWRcontrolNumber}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVoidWRcontrolNumber*/}

                  {/* END_USER_CODE-USER_AFTER_btnVoidWRcontrolNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_btnContractLimitControl*/}

                  {/* END_USER_CODE-USER_BEFORE_btnContractLimitControl*/}

                  <ButtonWidget
                    conf={state.btnContractLimitControl}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnContractLimitControl*/}

                  {/* END_USER_CODE-USER_AFTER_btnContractLimitControl*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFilters*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFilters*/}

                  <ButtonWidget
                    conf={state.btnFilters}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFilters*/}

                  {/* END_USER_CODE-USER_AFTER_btnFilters*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSpecialFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSpecialFunction*/}

                  <ButtonWidget
                    conf={state.btnSpecialFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSpecialFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnSpecialFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendoeMaintenance*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendoeMaintenance*/}

                  <ButtonWidget
                    conf={state.btnVendoeMaintenance}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendoeMaintenance*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendoeMaintenance*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSignatureSetup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSignatureSetup*/}

                  <ButtonWidget
                    conf={state.btnSignatureSetup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSignatureSetup*/}

                  {/* END_USER_CODE-USER_AFTER_btnSignatureSetup*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFreightControl*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFreightControl*/}

                  <ButtonWidget
                    conf={state.btnFreightControl}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFreightControl*/}

                  {/* END_USER_CODE-USER_AFTER_btnFreightControl*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFarmerStockPriceSetup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFarmerStockPriceSetup*/}

                  <ButtonWidget
                    conf={state.btnFarmerStockPriceSetup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFarmerStockPriceSetup*/}

                  {/* END_USER_CODE-USER_AFTER_btnFarmerStockPriceSetup*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_systemMaintenanceSetupSection*/}

                {/* END_USER_CODE-USER_AFTER_systemMaintenanceSetupSection*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={{...state.btnExit, Label:'Logout'}}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAppSysMaint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAppSysMaint*/}

              {/* START_USER_CODE-USER_AFTER_MainMenu*/}

              {/* END_USER_CODE-USER_AFTER_MainMenu*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_MainMenu);
