/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setData,
  setValue,
  enable,
  disable,
  goTo,
  getValue,
  getData,
  getSelectedRowNumber,
} from "../../shared/WindowImports";

import "./VoidReasonSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// import { getData } from "../../Common/ApiService";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_VoidReasonSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VoidReasonSetup",
    windowName: "VoidReasonSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.VoidReasonSetup",
    // START_USER_CODE-USER_VoidReasonSetup_PROPERTIES
    headerData: {
      scrName: "Void Reason Setup",
      scrCode: "PN0180A",
    },
    // END_USER_CODE-USER_VoidReasonSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxVoidReasonSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxVoidReasonSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxVoidReasonSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    gridVoidReasonData: {
      name: "gridVoidReasonData",
      type: "GridWidget",
      parent: "grpbxVoidReasonSetup",
      gridCellsOrder:
        "txtCode,txtDescription,txtTranslateCode,txtAction,txtLimitedAccess",
      ColSpan: "4",
      Pagination: false,
      ColsForTabLandscape: "13",
      HasLabel: false,
      Cols: "13",
      ColsForTabPotrait: "13",
      ColsForLargeDesktop: "13",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridVoidReasonData_PROPERTIES

      // END_USER_CODE-USER_gridVoidReasonData_PROPERTIES
    },
    lblVoidReason: {
      name: "lblVoidReason",
      type: "LabelWidget",
      parent: "grpbxVoidReasonSetup",
      Label: "Void Reason:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVoidReason_PROPERTIES

      // END_USER_CODE-USER_lblVoidReason_PROPERTIES
    },
    txtAction: {
      name: "txtAction",
      type: "TextBoxWidget",
      colName: "txtColAction",
      parent: "gridVoidReasonData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAction_PROPERTIES

      // END_USER_CODE-USER_txtAction_PROPERTIES
    },
    txtCode: {
      name: "txtCode",
      type: "TextBoxWidget",
      colName: "txtColCode",
      parent: "gridVoidReasonData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCode_PROPERTIES

      // END_USER_CODE-USER_txtCode_PROPERTIES
    },
    txtColAction: {
      name: "txtColAction",
      type: "GridColumnWidget",
      parent: "gridVoidReasonData",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAction_PROPERTIES

      // END_USER_CODE-USER_txtColAction_PROPERTIES
    },
    txtColCode: {
      name: "txtColCode",
      type: "GridColumnWidget",
      parent: "gridVoidReasonData",
      Label: "Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCode_PROPERTIES

      // END_USER_CODE-USER_txtColCode_PROPERTIES
    },
    txtColDescription: {
      name: "txtColDescription",
      type: "GridColumnWidget",
      parent: "gridVoidReasonData",
      Label: "Description",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDescription_PROPERTIES

      // END_USER_CODE-USER_txtColDescription_PROPERTIES
    },
    txtColLimitedAccess: {
      name: "txtColLimitedAccess",
      type: "GridColumnWidget",
      parent: "gridVoidReasonData",
      Label: "Limited Access",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLimitedAccess_PROPERTIES

      // END_USER_CODE-USER_txtColLimitedAccess_PROPERTIES
    },
    txtColTranslateCode: {
      name: "txtColTranslateCode",
      type: "GridColumnWidget",
      parent: "gridVoidReasonData",
      Label: "Translate Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTranslateCode_PROPERTIES

      // END_USER_CODE-USER_txtColTranslateCode_PROPERTIES
    },
    txtDescription: {
      name: "txtDescription",
      type: "TextBoxWidget",
      colName: "txtColDescription",
      parent: "gridVoidReasonData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDescription_PROPERTIES

      // END_USER_CODE-USER_txtDescription_PROPERTIES
    },
    txtLimitedAccess: {
      name: "txtLimitedAccess",
      type: "TextBoxWidget",
      colName: "txtColLimitedAccess",
      parent: "gridVoidReasonData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLimitedAccess_PROPERTIES

      // END_USER_CODE-USER_txtLimitedAccess_PROPERTIES
    },
    txtTranslateCode: {
      name: "txtTranslateCode",
      type: "TextBoxWidget",
      colName: "txtColTranslateCode",
      parent: "gridVoidReasonData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTranslateCode_PROPERTIES

      // END_USER_CODE-USER_txtTranslateCode_PROPERTIES
    },
    grpbxVoidReasonSetup: {
      name: "grpbxVoidReasonSetup",
      type: "GroupBoxWidget",
      parent: "VoidReasonSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxVoidReasonSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxVoidReasonSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "VoidReasonSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#VoidReasonProfile":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#VoidReasonProfile":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose :{
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    btnAdd: {
      DEFAULT:["SystemMaintenanceMasterManagement#VoidReasonProfile#DEFAULT#true#Click"]
    },
    // btnEdit: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#VoidReasonProfile#DEFAULT#true#Click"]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const [selectedRowNum, setSelectedRowNum] = useState(-1);

  useEffect(()=>{
    let childData = getData(thisObj, 'frmVoidReasonSetup')
    if (childData !== null) {
      bFillReasonList()
    }
  },[getData(thisObj, 'frmVoidReasonSetup')])

  const FormLoad = () => {
    EnableDisableControls()
    bFillReasonList()
  }

  //Method to bind the grid
  const  bFillReasonList = async() => {
      let response = await SettlementService.RetrieveVoidReasonDetails(null, null, null)
      
      let js = [];
      if (response !== null && response !== undefined && response.length > 0) {
        let data = response;
        let obj = {};
        for (var i = 0; i < data.length; i++) {
          obj.txtCode = data[i].voidReasonCode;
          obj.txtDescription = data[i].voidReasonDesc;
          obj.txtTranslateCode = data[i].transmitCode;
          obj.txtAction = data[i].actionCode;
          obj.txtLimitedAccess = data[i].limitAccess;
          js.push(obj)
          obj = {}
        }
      }
      setValue(thisObj, 'gridVoidReasonData', js);
      if (js.length > 0) {
        setSelectedRowNum(0);
        js[0].isSelected = true;
      }
      setData(thisObj, 'gridVoidReasonData', js);
      thisObj.setState((current) => {
        return {
          ...current,
          gridVoidReasonData: {
            ...current["gridVoidReasonData"],
            valueList: js
          },
        };
      });
      EnableDisableControls()
  }

  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, 'gridVoidReasonData');
    if (newRowNum !== undefined && selectedRowNum !== undefined && gridData?.length) {
      gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return(newRowNum)
    }
  }

  const ongridVoidReasonDataRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, 'gridVoidReasonData');
    selectRow(rowNum);
  }
  thisObj.ongridVoidReasonDataRowSelect = ongridVoidReasonDataRowSelect

  const EnableDisableControls = () => {
    let gridList = getData(thisObj, 'gridVoidReasonData')
    let gridLength = ''
    if (gridList !== null && gridList !== undefined) {
      gridLength = gridList.length
    }
    enable(thisObj, 'btnAdd')
    enable(thisObj, 'btnEdit')
    enable(thisObj, 'btnDelete')

    if (gridLength <= 0) {
      disable(thisObj, 'btnAdd')
      disable(thisObj, 'btnEdit')
      disable(thisObj, 'btnDelete')
    }
  }

  //Click event of Add button
  const onbtnAddClick = async() => {
    try {
      let frmVoidReasonProfile = {}
      frmVoidReasonProfile.cmdOk_Caption = "Add"
      frmVoidReasonProfile.txtCode_enabled = true
      setData(thisObj, 'frmVoidReasonProfile', frmVoidReasonProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#VoidReasonProfile#DEFAULT#true#Click")
      await bFillReasonList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  //Click event of Edit button
  const onbtnEditClick = async() => {
    try{
      let gridData = getValue(thisObj, 'gridVoidReasonData');
      let rowNum = getSelectedRowNumber(thisObj, 'gridVoidReasonData');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let void_reason_code = gridData[rowNum].txtCode
      let response = await SettlementService.RetrieveVoidReasonDetails(void_reason_code, null, null)
      let data = response
      let frmVoidReasonProfile = {}
      frmVoidReasonProfile.cmdOk_Caption = "Update"
      frmVoidReasonProfile.txtCode_enabled = false
      frmVoidReasonProfile.txtCode = gridData[rowNum].txtCode
      frmVoidReasonProfile.txtDescription = data[0].voidReasonDesc
      frmVoidReasonProfile.txtTransmitCode = data[0].transmitCode
      if (data[0].limitAccess == 'Y') {
        frmVoidReasonProfile.chkboxLimitAccess = '1'  
      }
      else {
        frmVoidReasonProfile.chkboxLimitAccess = ''
      }
      if (data[0].actionCode == 'NO SALE') {
        frmVoidReasonProfile.actionCode = '0'
      }
      else if (data[0].actionCode == 'VOID') {
        frmVoidReasonProfile.actionCode = '1'
      }
      else if (data[0].actionCode == 'SUPERSEDE'){
        frmVoidReasonProfile.actionCode = '2'
      }
      let addDateTime = moment(data[0].addDateTime).format("MM/DD/YYYY");
      frmVoidReasonProfile.lblAddedBy = data[0].addUser + " " + addDateTime
      let chgDateTime = moment(data[0].chgDateTime).format("MM/DD/YYYY");
      frmVoidReasonProfile.lblChangedBy = data[0].chgUser + " " + chgDateTime

      setData(thisObj, 'frmVoidReasonProfile', frmVoidReasonProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#VoidReasonProfile#DEFAULT#true#Click")
      await bFillReasonList()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  //Click event of Delete button
  const onbtnDeleteClick = async() => {
    try {
      let gridData = getValue(thisObj, 'gridVoidReasonData');
      let rowNum = getSelectedRowNumber(thisObj, 'gridVoidReasonData');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let voidReasonCode = gridData[rowNum].txtCode
      let voidReasonDesc = gridData[rowNum].txtDescription
      let res = confirm(`Are you sure you wish to delete this void reason '${voidReasonCode}' - '${voidReasonDesc}'`)
      if (res == true) {
        let response = await SystemMaintenanceMasterManagementService.RemoveVoidReason(null, voidReasonCode)
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
          return
        }
        else {
          showMessage(thisObj, response.message, true)
        }
        await bFillReasonList()
        EnableDisableControls()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Button Click"
        );
      }
      return false;
    }
    
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  //Double click event 
  const ongridVoidReasonDataDblclick = async() => {
    try{
      let void_reason_code = thisObj.state.gridVoidReasonData.selected[0].txtCode;
      let response = await SettlementService.RetrieveVoidReasonDetails(void_reason_code, null, null)
      let data = response
      let frmVoidReasonProfile = {}
      frmVoidReasonProfile.cmdOk_Caption = "Double"
      frmVoidReasonProfile.txtCode_enabled = false
      frmVoidReasonProfile.txtCode = thisObj.state.gridVoidReasonData.selected[0].txtCode
      frmVoidReasonProfile.txtDescription = data[0].voidReasonDesc
      frmVoidReasonProfile.txtTransmitCode = data[0].transmitCode
      let addDateTime = moment(data[0].addDateTime).format("MM/DD/YYYY");
      frmVoidReasonProfile.lblAddedBy = data[0].addUser + " " + addDateTime
      let chgDateTime = moment(data[0].chgDateTime).format("MM/DD/YYYY");
      frmVoidReasonProfile.lblChangedBy = data[0].chgUser + " " + chgDateTime
      setData(thisObj, 'frmVoidReasonProfile', frmVoidReasonProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#VoidReasonProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Double click event"
        );
      }
      return false;
    }
  }
  thisObj.ongridVoidReasonDataDblclick = ongridVoidReasonDataDblclick

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VoidReasonSetup*/}

              {/* END_USER_CODE-USER_BEFORE_VoidReasonSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVoidReasonSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVoidReasonSetup*/}

              <GroupBoxWidget
                conf={state.grpbxVoidReasonSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblVoidReason*/}

                {/* END_USER_CODE-USER_BEFORE_lblVoidReason*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVoidReason}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVoidReason*/}

                {/* END_USER_CODE-USER_AFTER_lblVoidReason*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridVoidReasonData*/}

                {/* END_USER_CODE-USER_BEFORE_gridVoidReasonData*/}

                <GridWidget
                  conf={state.gridVoidReasonData}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridVoidReasonData}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridVoidReasonData*/}

                {/* END_USER_CODE-USER_AFTER_gridVoidReasonData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVoidReasonSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVoidReasonSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_VoidReasonSetup*/}

              {/* END_USER_CODE-USER_AFTER_VoidReasonSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_VoidReasonSetup);
