/* eslint-disable*/
// START_USER_CODE-USER_IMPORTS
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  disable,
  enable,
  setValue,
  setData
} from "../../shared/WindowImports";
import { ContractManagementService } from "../Service/ContractManagementService";
import "./VendorDisclosure.scss";
import CommonContext from '../../Store/CommonContext';
// END_USER_CODE-USER_IMPORTS

// START_USER_CODE-USER_PROPERTIES
function ContractManagement_VendorDisclosure(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  // END_USER_CODE-USER_PROPERTIES

  // START_USER_CODE-USER_VendorDisclosure_PROPERTIES
  let states = {
    Label: "VendorDisclosure",
    windowName: "VendorDisclosure",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.VendorDisclosure",
    // END_USER_CODE-USER_VendorDisclosure_PROPERTIES

    // START_USER_CODE-USER_btnDone_PROPERTIES
    btnDone: {
      name: "btnDone",
      type: "ButtonWidget",
      parent: "grpbxWmnMnrtVtrn",
      Label: "Done",
      CharWidth: "11",
      ofTypeDomain: "d_boolean",
      // END_USER_CODE-USER_btnDone_PROPERTIES
    },
    // START_USER_CODE-USER_btnViewUploadedContract_PROPERTIES
    btnViewUploadedContract: {
      name: "btnViewUploadedContract",
      type: "ButtonWidget",
      parent: "grpbxWmnMnrtVtrn",
      Label: "View Uploaded Contract",
      CharWidth: "45",
      ofTypeDomain: "d_boolean",
    },
    // END_USER_CODE-USER_btnViewUploadedContract_PROPERTIES


    // START_USER_CODE-USER_chkboxMinority_PROPERTIES
    chkboxMinority: {
      name: "chkboxMinority",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxMinority",
      Options:
        "African American:1,Indian (Sub-Continent):2,Hispanic American:3,Asian/Pacific Islander:4,Native American:5,Multi-Cultural:6",
      ColSpanForLargeDesktop: "2",
      ColSpanForTabLandscape: "2",
      ColSpanForTabPotrait: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      inline: true,
    },
    // END_USER_CODE-USER_chkboxMinority_PROPERTIES

    // START_USER_CODE-USER_chkboxVeteran_PROPERTIES
    chkboxVeteran: {
      name: "chkboxVeteran",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxVeteran",
      Options: "Vietnam Veteran:1,Service Disabled:2",
      ColSpanForLargeDesktop: "2",
      ColSpanForTabLandscape: "2",
      ColSpanForTabPotrait: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      inline: true,
    },
    // END_USER_CODE-USER_chkboxVeteran_PROPERTIES

    // START_USER_CODE-USER_grpbxMinority_PROPERTIES
    grpbxMinority: {
      name: "grpbxMinority",
      type: "GroupBoxWidget",
      parent: "grpbxWmnMnrtVtrn",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },

    // END_USER_CODE-USER_grpbxMinority_PROPERTIES

    // START_USER_CODE-USER_grpbxVeteran_PROPERTIES
    grpbxVeteran: {
      name: "grpbxVeteran",
      type: "GroupBoxWidget",
      parent: "grpbxWmnMnrtVtrn",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    // END_USER_CODE-USER_grpbxVeteran_PROPERTIES

    // START_USER_CODE-USER_grpbxWomen_PROPERTIES
    grpbxWomen: {
      name: "grpbxWomen",
      type: "GroupBoxWidget",
      parent: "grpbxWmnMnrtVtrn",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    // END_USER_CODE-USER_grpbxWomen_PROPERTIES

    // START_USER_CODE-USER_lblMinority_PROPERTIES
    lblMinority: {
      name: "lblMinority",
      type: "LabelWidget",
      parent: "grpbxMinority",
      Label: "Minority",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblMinority_PROPERTIES

    // START_USER_CODE-USER_lblMustBeCitizen_PROPERTIES
    lblMustBeCitizen: {
      name: "lblMustBeCitizen",
      type: "LabelWidget",
      parent: "grpbxWmnMnrtVtrn",
      Label: "xxx Must be a US Citizen",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblMustBeCitizen_PROPERTIES

    // START_USER_CODE-USER_lblOMCMinority_PROPERTIES
    lblOMCMinority: {
      name: "lblOMCMinority",
      type: "LabelWidget",
      parent: "grpbxMinority",
      Label: "Owned, Managed and Controlled ( 51% or more)",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblOMCMinority_PROPERTIES

    // START_USER_CODE-USER_lblOMCVeteran_PROPERTIES

    lblOMCVeteran: {
      name: "lblOMCVeteran",
      type: "LabelWidget",
      parent: "grpbxVeteran",
      Label: "Owned, Managed and Controlled ( 51% or more)",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblOMCVeteran_PROPERTIES

    // START_USER_CODE-USER_lblOMCWomen_PROPERTIES
    lblOMCWomen: {
      name: "lblOMCWomen",
      type: "LabelWidget",
      parent: "grpbxWomen",
      Label: "Owned, Managed and Controlled ( 51% or more)",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblOMCWomen_PROPERTIES

    // START_USER_CODE-USER_lblVeteran_PROPERTIES

    lblVeteran: {
      name: "lblVeteran",
      type: "LabelWidget",
      parent: "grpbxVeteran",
      Label: "Veteran (Defined as past or present member of the US military)",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblVeteran_PROPERTIES

    // START_USER_CODE-USER_lblWomen_PROPERTIES
    lblWomen: {
      name: "lblWomen",
      type: "LabelWidget",
      parent: "grpbxWomen",
      Label: "Women",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // END_USER_CODE-USER_lblWomen_PROPERTIES

    // START_USER_CODE-USER_radioMinority_PROPERTIES
    radioMinority: {
      name: "radioMinority",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxMinority",
      Options: "Yes:1,No:2,Not Disclosed:3",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      inline: true,
    },
    // END_USER_CODE-USER_radioMinority_PROPERTIES

    // START_USER_CODE-USER_radioVeteran_PROPERTIES
    radioVeteran: {
      name: "radioVeteran",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxVeteran",
      Options: "Yes:1,No:2,Not Disclosed:3",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      inline: true,
    },
    // END_USER_CODE-USER_radioVeteran_PROPERTIES

    // START_USER_CODE-USER_radioWomen_PROPERTIES
    radioWomen: {
      name: "radioWomen",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxWomen",
      Options: "Yes:1,No:2,Not Disclosed:3",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      inline: true,
    },
    // END_USER_CODE-USER_radioWomen_PROPERTIES

    // START_USER_CODE-USER_txtWMV_PROPERTIES
    txtWMV: {
      name: "txtWMV",
      type: "TextBoxWidget",
      parent: "grpbxWmnMnrtVtrn",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false,
    },
    // END_USER_CODE-USER_txtWMV_PROPERTIES

    // START_USER_CODE-USER_grpbxWmnMnrtVtrn_PROPERTIES
    grpbxWmnMnrtVtrn: {
      name: "grpbxWmnMnrtVtrn",
      type: "GroupBoxWidget",
      parent: "VendorDisclosure",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    // END_USER_CODE-USER_grpbxWmnMnrtVtrn_PROPERTIES  
  };

  // let cropYear = JSON.parse(sessionStorage.getItem('year'));
  // let comp_id = sessionStorage.getItem('compId');
  // let user_id = sessionStorage.getItem('userid');
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad();
  }, []);
  // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    LoadVendor();
    getVendorDetails();
    EnableDisableControl();
  }
  // END-USER_METHODS

  // Start Method for Disable/Enable the View Upload Contract Button
  const EnableDisableControl = () => {
    let sContract = commonContext.getContractProfile_ContractNumber;
    ContractManagementService.RetrieveContractDetails(sContract, 'PN1010').then(contractdetails => {
      let sHeader = contractdetails[0];
      switch (sHeader.cont_status) {
        case 'P':
          disable(thisObj, "btnViewUploadedContract");
          break;
        case 'A': enable(thisObj, "btnViewUploadedContract");
          break;
        case 'S': enable(thisObj, "btnViewUploadedContract");
          break;
        case 'C': disable(thisObj, "btnViewUploadedContract");
          break;
        case 'V': disable(thisObj, "btnViewUploadedContract");
          break;
      }
    });
  }
  //End Method for Disable/Enable the View Upload Contract Button

  // Start Method Binding vendor details to Disclosure lable
  const getVendorDetails = () => {
    let vendorId = commonContext.getContractProfile_VendorId;
    let vendorName = commonContext.getContractProfile_VendorName;
    if (vendorId !== null || vendorName !== null) {
      setValue(thisObj, "txtWMV", vendorId + "-" + vendorName);
    }
  }
  // End Method Binding vendor details to Disclosure lable

  // Start Function to get Vendor details
  const LoadVendor = () => {
    let vendorId = commonContext.getContractProfile_VendorId; 
    let btnRescan = commonContext.getContractProfile_BtnRescanStatus;
    if (btnRescan == true) {
      enable(thisObj, "btnViewUploadedContract");
    }   
    ContractManagementService.RetrieveVendorEmailDetails(null,null,null,null,null,vendorId).then(response => {
      let data = response[0];
      if (data !== null) {
        if (data.womenOwned == "Y") {
          setValue(thisObj, "radioWomen", '1');
        }
        else if (data.womenOwned == "N") {
          setValue(thisObj, "radioWomen", '2');
        }
        else {
          setValue(thisObj, "radioWomen", '3');
        }

        if (data.minorityOwned == "Y") {
          setValue(thisObj, "radioMinority", '1');
        }
        else if (data.minorityOwned == "N") {
          setValue(thisObj, "radioMinority", '2');
          disable(thisObj, "chkboxMinority");
        } else {
          setValue(thisObj, "radioMinority", '3');
          disable(thisObj, "chkboxMinority");
        }

        if (data.minority.length > 0) {
          let js = [];
          let obj;
          for (let i = 0; i < data.minority.length; i++) {
            switch (data.minority[i].disclosureStatus) {
              case 'AFRICAN AMERICAN':
                obj = '1';
                break;
              case 'INDIAN (SUB-CONTINENT)':
                obj = '2';
                break;
              case 'HISPANIC AMERICAN':
                obj = '3';
                break;
              case 'ASIAN/PACIFIC ISLANDER':
                obj = '4';
                break;
              case 'NATIVE AMERICAN':
                obj = '5';
                break;
              case 'MULTI-CULTURAL':
                obj = '6';
                break;
            }
            js.push(obj);
          }
          setValue(thisObj, "chkboxMinority", js);
        }

        if (data.veteranOwned == "Y") {
          setValue(thisObj, "radioVeteran", '1');
        }
        else if (data.veteranOwned == "N") {
          setValue(thisObj, "radioVeteran", '2');
          disable(thisObj, "chkboxVeteran");
        } else {
          setValue(thisObj, "radioVeteran", '3');
          disable(thisObj, "chkboxVeteran");
        }

        if (data.veteran.length > 0) {
          let js = [];
          let obj;
          for (let i = 0; i < data.veteran.length; i++) {
            switch (data.veteran[i].disclosureStatus) {
              case 'VIETNAM VETERAN':
                obj = '1';
                break;
              case 'SERVICE DISABLED':
                obj = '2';
                break;
            }
            js.push(obj);
          }
          setValue(thisObj, "chkboxVeteran", js);
        }
      }
    });
  }
  // End Function to get Vendor details

  // Start Method For Women radio button change event implementaion
  const onradioWomenChange = () => {
    let radioWomen = $("input[type='radio'][name='radioWomen']:checked").val();
    if (radioWomen == "" || radioWomen0 == undefined) {
      return false;
    }
    else {
      radioWomen;
    }
  }
  thisObj.onradioWomenChange = onradioWomenChange;
  // End Method For Women radio button change event implementaion

  // Start Method For Minority radio button change event implementaion
  const onradioMinorityChange = () => {
    let radioMinority = $("input[type='radio'][name='radioMinority']:checked").val();
    if (radioMinority == 1) {
      enable(thisObj, "chkboxMinority");
    }
    else {
      disable(thisObj, "chkboxMinority");
    }
  };
  thisObj.onradioMinorityChange = onradioMinorityChange;
  // End Method For Minority radio button change event implementaion

  // Start Method For Veteran radio button change event implementaion
  const onradioVeteranChange = () => {
    let radioVeteran = $("input[type='radio'][name='radioVeteran']:checked").val();
    if (radioVeteran == 1) {
      enable(thisObj, "chkboxVeteran");
    }
    else {
      disable(thisObj, "chkboxVeteran")
    }
  }
  thisObj.onradioVeteranChange = onradioVeteranChange;
  // End Method For Veteran radio button change event implementaion

  // Start Method for Done functionality
  const onbtnDoneClick = async() => {
    try {
      let women_owned = '';
      if (document.getElementById('radioWomen0').checked == true) {
        women_owned = 'Y';
      }else if (document.getElementById("radioWomen1").checked == true) {
        women_owned = 'N';
      } else {
          women_owned = '';
      }

      let minority_owned = '';
      if (document.getElementById('radioMinority0').checked == true) {
        minority_owned = 'Y';
      }else if (document.getElementById('radioMinority1').checked == true) {
        minority_owned = 'N';
      }else {
        minority_owned = '';
      }

      let minority_status = '';
      if (document.getElementById('chkboxMinority0').checked) {
        minority_status += `AFRICAN AMERICAN`;
      } 
      if (document.getElementById('chkboxMinority1').checked) {
        minority_status += `,INDIAN (SUB-CONTINENT)`;
      } 
      if (document.getElementById('chkboxMinority2').checked == true) {
        minority_status += `,HISPANIC AMERICAN`;
      } 
      if (document.getElementById('chkboxMinority3').checked == true) {
        minority_status += `,ASIAN/PACIFIC ISLANDER`;
      } 
      if (document.getElementById('chkboxMinority4').checked == true) {
        minority_status += `,NATIVE AMERICAN`;
      } 
      if (document.getElementById('chkboxMinority5').checked == true) {
        minority_status += `,MULTI-CULTURAL`;
      }

      let veteran_owned = '';
      if (document.getElementById('radioVeteran0').checked == true) {
        veteran_owned = 'Y'
      }else if (document.getElementById('radioVeteran1').checked == true) {
        veteran_owned = 'N';
      }else {
        veteran_owned = '';
      }

      let veteran_status = '';
      if (document.getElementById('chkboxVeteran0').checked == true) {
        veteran_status += `VIETNAM VETERAN`;
      } 
      if (document.getElementById('chkboxVeteran1').checked == true) {
        veteran_status += `,SERVICE DISABLED`;
      }

      let vendorId = commonContext.getContractProfile_VendorId;
      let dataObj = {
        women_owned: women_owned,
        minority_owned: minority_owned,
        minority_status: minority_status,
        veteran_owned: veteran_owned,
        veteran_status: veteran_status
      }
      let result = await ContractManagementService.UpdateVendorEmails(vendorId, dataObj);
      if (result) {
        showMessage(thisObj, result.message, true);
        let vendorObj = {
          vendorId: commonContext.getContractProfile_VendorId
        };
        setData(thisObj, 'VendorDisclosure', vendorObj);
      }
      document.getElementById('ContractManagement_VendorDisclosurePopUp').childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnDone event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDoneClick = onbtnDoneClick;
  // End Method for Done functionality

  // Start Method for View Upload Contract
  const onbtnViewUploadedContractClick = () => {
    try {
      let sContract = commonContext.getContractProfile_ContractNumber;
      ContractManagementService.RetrieveContractDetails(sContract, 'PN1010')
        .then(response => {
          let data = response;
          let sStatus = '';
          if (data !== null && data !== undefined) {
            sStatus = data[0].upload_status
          } 
          if (sStatus.trim() == "I") {
            showMessage(thisObj, "Invalid file was uploaded. \n\n Please rescan the signed contract before it can be approved?")
            return false
          }
          if (sStatus.trim() !== "C") {
            showMessage(thisObj, "Contract has not been processed. \n\n Try again in about 5 minutes?")
            return false
          }
        })
      ContractManagementService.RetieveContractUploadFileDetails(sContract)
        .then(response => {
          let data1 = response
          let createurl = null
          let uplodedfilename = data1.uploadFileName
          let filepath = data1.filePath
          if ((filepath !== null && filepath !== undefined) && (uplodedfilename !== null && uplodedfilename !== undefined)) {           
            const hostPath = window.location.origin
          if(hostPath == 'https://pps.tst.adm.com' || hostPath == 'https://pps.dev.adm.com' || hostPath == 'http://localhost:3000')
            createurl = (`https://testxml.pps.e-adm.com/pps/contracts/${uplodedfilename}`)
          else
            createurl = (`https://xml.pps.e-adm.com/pps/contracts/${uplodedfilename}`)
          window.open(createurl)
          }
          else{
            showMessage(thisObj,"Cannot find an uploaded contract file.")
          return false
          }
        })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnViewUploadedContractClick = onbtnViewUploadedContractClick;
  // End Method for View Upload Contract

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}

            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">

              {/* END_USER_CODE-USER_BEFORE_VendorDisclosure*/}

              <GroupBoxWidget conf={state.grpbxWmnMnrtVtrn} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtWMV*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWMV}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* END_USER_CODE-USER_AFTER_txtWMV*/}

                {/* START_USER_CODE-USER_BEFORE_grpbxWomen*/}
                <GroupBoxWidget conf={state.grpbxWomen} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblWomen*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblWomen}
                    screenConf={state}
                  ></LabelWidget>

                  {/* END_USER_CODE-USER_AFTER_lblWomen*/}

                  {/* START_USER_CODE-USER_BEFORE_lblOMCWomen*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblOMCWomen}
                    screenConf={state}
                  ></LabelWidget>
                  {/* END_USER_CODE-USER_AFTER_lblOMCWomen*/}

                  {/* START_USER_CODE-USER_BEFORE_radioWomen*/}
                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioWomen}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* END_USER_CODE-USER_AFTER_radioWomen*/}
                </GroupBoxWidget>

                {/* END_USER_CODE-USER_BEFORE_grpbxMinority*/}

                <GroupBoxWidget conf={state.grpbxMinority} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblMinority*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblMinority}
                    screenConf={state}
                  ></LabelWidget>
                  {/* END_USER_CODE-USER_AFTER_lblMinority*/}

                  {/* START_USER_CODE-USER_BEFORE_lblOMCMinority*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblOMCMinority}
                    screenConf={state}
                  ></LabelWidget>
                  {/* END_USER_CODE-USER_AFTER_lblOMCMinority*/}

                  {/* START_USER_CODE-USER_BEFORE_radioMinority*/}
                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioMinority}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* END_USER_CODE-USER_AFTER_radioMinority*/}

                  {/* START_USER_CODE-USER_BEFORE_chkboxMinority*/}
                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxMinority}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* END_USER_CODE-USER_AFTER_chkboxMinority*/}
                </GroupBoxWidget>
                {/* END_USER_CODE-USER_AFTER_grpbxMinority*/}

                {/* START_USER_CODE-USER_BEFORE_grpbxVeteran*/}
                <GroupBoxWidget conf={state.grpbxVeteran} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblVeteran*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblVeteran}
                    screenConf={state}
                  ></LabelWidget>
                  {/* END_USER_CODE-USER_AFTER_lblVeteran*/}

                  {/* START_USER_CODE-USER_BEFORE_lblOMCVeteran*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblOMCVeteran}
                    screenConf={state}
                  ></LabelWidget>
                  {/* END_USER_CODE-USER_AFTER_lblOMCVeteran*/}

                  {/* START_USER_CODE-USER_BEFORE_radioVeteran*/}
                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioVeteran}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* END_USER_CODE-USER_AFTER_radioVeteran*/}

                  {/* START_USER_CODE-USER_BEFORE_chkboxVeteran*/}
                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxVeteran}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* END_USER_CODE-USER_AFTER_chkboxVeteran*/}
                </GroupBoxWidget>
                {/* END_USER_CODE-USER_AFTER_grpbxVeteran*/}

                {/* START_USER_CODE-USER_BEFORE_lblMustBeCitizen*/}
                <LabelWidget
                  values={values}
                  conf={state.lblMustBeCitizen}
                  screenConf={state}
                ></LabelWidget>
                {/* END_USER_CODE-USER_AFTER_lblMustBeCitizen*/}

                {/* START_USER_CODE-USER_AFTER_btnViewUploadedContract*/}
                <ButtonWidget
                  conf={state.btnViewUploadedContract}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>

                {/* END_USER_CODE-USER_AFTER_btnViewUploadedContract*/}

                {/* START_USER_CODE-USER_AFTER_btnDone*/}
                <ButtonWidget
                  conf={state.btnDone}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* END_USER_CODE-USER_AFTER_btnDone*/}

              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWmnMnrtVtrn*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWmnMnrtVtrn*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_VendorDisclosure);
