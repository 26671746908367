/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_AreaProfile from "./AreaProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("AreaProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_AreaProfile />);
    });
  });
  afterEach(cleanup);
  test("is AreaProfile Loads Successfully", () => {
    expect(screen.getByText("AreaProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for AreaProfile", () => {
    // START_USER_CODE-USER_AreaProfile_Custom_Test_Case
    // END_USER_CODE-USER_AreaProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_AreaProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridAreaProfileData(Grid Widget) Test Cases", async () => {
    let gridAreaProfileData = screen.getByTestId("gridAreaProfileData");
    let gridAreaProfileDatabtn =
      gridAreaProfileData.nextElementSibling.firstElementChild;
    gridAreaProfileData =
      gridAreaProfileData.parentElement.parentElement.parentElement;
    expect(gridAreaProfileData.tagName).toBe("DIV");
    expect(gridAreaProfileData.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:AreaProfile_gridAreaProfileData")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAreaProfileData", () => {
    // START_USER_CODE-USER_gridAreaProfileData_TEST
    // END_USER_CODE-USER_gridAreaProfileData_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAreaProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxAreaProfile = screen.getByTestId("grpbxAreaProfile");
    expect(grpbxAreaProfile.tagName).toBe("BUTTON");
    expect(grpbxAreaProfile.type).toBe("button");
    expect(grpbxAreaProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAreaProfile", () => {
    // START_USER_CODE-USER_grpbxAreaProfile_TEST
    // END_USER_CODE-USER_grpbxAreaProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCompany(Label Widget) Test Cases", async () => {
    const lblCompany = screen.getByTestId("lblCompany");
    expect(lblCompany.tagName).toBe("LABEL");
    expect(lblCompany.classList).toContain("form-label");
    expect(lblCompany.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblCompany")
    );
  });
  test("Custom Test Cases for lblCompany", () => {
    // START_USER_CODE-USER_lblCompany_TEST
    // END_USER_CODE-USER_lblCompany_TEST
  });
  test("lblCompanyValue(Label Widget) Test Cases", async () => {
    const lblCompanyValue = screen.getByTestId("lblCompanyValue");
    expect(lblCompanyValue.tagName).toBe("LABEL");
    expect(lblCompanyValue.classList).toContain("form-label");
    expect(lblCompanyValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblCompanyValue")
    );
  });
  test("Custom Test Cases for lblCompanyValue", () => {
    // START_USER_CODE-USER_lblCompanyValue_TEST
    // END_USER_CODE-USER_lblCompanyValue_TEST
  });
  test("lblID(Label Widget) Test Cases", async () => {
    const lblID = screen.getByTestId("lblID");
    expect(lblID.tagName).toBe("LABEL");
    expect(lblID.classList).toContain("form-label");
    expect(lblID.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblID")
    );
  });
  test("Custom Test Cases for lblID", () => {
    // START_USER_CODE-USER_lblID_TEST
    // END_USER_CODE-USER_lblID_TEST
  });
  test("lblMax(Label Widget) Test Cases", async () => {
    const lblMax = screen.getByTestId("lblMax");
    expect(lblMax.tagName).toBe("LABEL");
    expect(lblMax.classList).toContain("form-label");
    expect(lblMax.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblMax")
    );
  });
  test("Custom Test Cases for lblMax", () => {
    // START_USER_CODE-USER_lblMax_TEST
    // END_USER_CODE-USER_lblMax_TEST
  });
  test("lblMin(Label Widget) Test Cases", async () => {
    const lblMin = screen.getByTestId("lblMin");
    expect(lblMin.tagName).toBe("LABEL");
    expect(lblMin.classList).toContain("form-label");
    expect(lblMin.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_lblMin")
    );
  });
  test("Custom Test Cases for lblMin", () => {
    // START_USER_CODE-USER_lblMin_TEST
    // END_USER_CODE-USER_lblMin_TEST
  });
  test("gridAreaProfileData_txtActiveStatus(Grid Widget) Test Cases", async () => {
    let gridAreaProfileData_txtActiveStatus = screen.getByTestId(
      "gridAreaProfileData"
    );
    let gridAreaProfileData_txtActiveStatusbtn =
      gridAreaProfileData_txtActiveStatus.nextElementSibling.firstElementChild;
    gridAreaProfileData_txtActiveStatus =
      gridAreaProfileData_txtActiveStatus.parentElement.parentElement
        .parentElement;
    expect(gridAreaProfileData_txtActiveStatus.tagName).toBe("DIV");
    expect(gridAreaProfileData_txtActiveStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:AreaProfile_gridAreaProfileData")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtActiveStatus", () => {
    // START_USER_CODE-USER_txtActiveStatus_TEST
    // END_USER_CODE-USER_txtActiveStatus_TEST
  });
  test("txtCCCHandler(Textbox Widget) Test Cases", async () => {
    const txtCCCHandler = screen.getByTestId("txtCCCHandler");
    expect(txtCCCHandler.tagName).toBe("INPUT");
    expect(txtCCCHandler.type).toBe("text");
    expect(txtCCCHandler.classList).toContain("textboxWidgetClass");
    expect(txtCCCHandler.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_txtCCCHandler")
    );
    await act(async () => {
      userEvent.type(txtCCCHandler, "1");
    });
  });
  test("Custom Test Cases for txtCCCHandler", () => {
    // START_USER_CODE-USER_txtCCCHandler_TEST
    // END_USER_CODE-USER_txtCCCHandler_TEST
  });
  test("txtClearingAmount(Textbox Widget) Test Cases", async () => {
    const txtClearingAmount = screen.getByTestId("txtClearingAmount");
    expect(txtClearingAmount.tagName).toBe("INPUT");
    expect(txtClearingAmount.type).toBe("text");
    expect(txtClearingAmount.classList).toContain("textboxWidgetClass");
    expect(txtClearingAmount.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_txtClearingAmount")
    );
    await act(async () => {
      userEvent.type(txtClearingAmount, "1");
    });
  });
  test("Custom Test Cases for txtClearingAmount", () => {
    // START_USER_CODE-USER_txtClearingAmount_TEST
    // END_USER_CODE-USER_txtClearingAmount_TEST
  });
  test("txtContractPendingMaxDays(Textbox Widget) Test Cases", async () => {
    const txtContractPendingMaxDays = screen.getByTestId(
      "txtContractPendingMaxDays"
    );
    expect(txtContractPendingMaxDays.tagName).toBe("INPUT");
    expect(txtContractPendingMaxDays.type).toBe("text");
    expect(txtContractPendingMaxDays.classList).toContain("textboxWidgetClass");
    expect(
      txtContractPendingMaxDays.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:AreaProfile_txtContractPendingMaxDays"
      )
    );
    await act(async () => {
      userEvent.type(txtContractPendingMaxDays, "1");
    });
  });
  test("Custom Test Cases for txtContractPendingMaxDays", () => {
    // START_USER_CODE-USER_txtContractPendingMaxDays_TEST
    // END_USER_CODE-USER_txtContractPendingMaxDays_TEST
  });
  test("gridAreaProfileData_txtDeliveryId(Grid Widget) Test Cases", async () => {
    let gridAreaProfileData_txtDeliveryId = screen.getByTestId(
      "gridAreaProfileData"
    );
    let gridAreaProfileData_txtDeliveryIdbtn =
      gridAreaProfileData_txtDeliveryId.nextElementSibling.firstElementChild;
    gridAreaProfileData_txtDeliveryId =
      gridAreaProfileData_txtDeliveryId.parentElement.parentElement
        .parentElement;
    expect(gridAreaProfileData_txtDeliveryId.tagName).toBe("DIV");
    expect(gridAreaProfileData_txtDeliveryId.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:AreaProfile_gridAreaProfileData")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDeliveryId", () => {
    // START_USER_CODE-USER_txtDeliveryId_TEST
    // END_USER_CODE-USER_txtDeliveryId_TEST
  });
  test("gridAreaProfileData_txtDeliveryPeriodDescription(Grid Widget) Test Cases", async () => {
    let gridAreaProfileData_txtDeliveryPeriodDescription = screen.getByTestId(
      "gridAreaProfileData"
    );
    let gridAreaProfileData_txtDeliveryPeriodDescriptionbtn =
      gridAreaProfileData_txtDeliveryPeriodDescription.nextElementSibling
        .firstElementChild;
    gridAreaProfileData_txtDeliveryPeriodDescription =
      gridAreaProfileData_txtDeliveryPeriodDescription.parentElement
        .parentElement.parentElement;
    expect(gridAreaProfileData_txtDeliveryPeriodDescription.tagName).toBe(
      "DIV"
    );
    expect(
      gridAreaProfileData_txtDeliveryPeriodDescription.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:AreaProfile_gridAreaProfileData")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDeliveryPeriodDescription", () => {
    // START_USER_CODE-USER_txtDeliveryPeriodDescription_TEST
    // END_USER_CODE-USER_txtDeliveryPeriodDescription_TEST
  });
  test("txtHandlerLoc(Textbox Widget) Test Cases", async () => {
    const txtHandlerLoc = screen.getByTestId("txtHandlerLoc");
    expect(txtHandlerLoc.tagName).toBe("INPUT");
    expect(txtHandlerLoc.type).toBe("text");
    expect(txtHandlerLoc.classList).toContain("textboxWidgetClass");
    expect(txtHandlerLoc.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_txtHandlerLoc")
    );
    await act(async () => {
      userEvent.type(txtHandlerLoc, "1");
    });
  });
  test("Custom Test Cases for txtHandlerLoc", () => {
    // START_USER_CODE-USER_txtHandlerLoc_TEST
    // END_USER_CODE-USER_txtHandlerLoc_TEST
  });
  test("txtID(Textbox Widget) Test Cases", async () => {
    const txtID = screen.getByTestId("txtID");
    expect(txtID.tagName).toBe("INPUT");
    expect(txtID.type).toBe("text");
    expect(txtID.classList).toContain("textboxWidgetClass");
    expect(txtID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_txtID")
    );
    await act(async () => {
      userEvent.type(txtID, "1");
    });
  });
  test("Custom Test Cases for txtID", () => {
    // START_USER_CODE-USER_txtID_TEST
    // END_USER_CODE-USER_txtID_TEST
  });
  test("txtKernalHullRangeMax(Textbox Widget) Test Cases", async () => {
    const txtKernalHullRangeMax = screen.getByTestId("txtKernalHullRangeMax");
    expect(txtKernalHullRangeMax.tagName).toBe("INPUT");
    expect(txtKernalHullRangeMax.type).toBe("text");
    expect(txtKernalHullRangeMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernalHullRangeMax, "1");
    });
  });
  test("Custom Test Cases for txtKernalHullRangeMax", () => {
    // START_USER_CODE-USER_txtKernalHullRangeMax_TEST
    // END_USER_CODE-USER_txtKernalHullRangeMax_TEST
  });
  test("txtKernalHullRangeMin(Textbox Widget) Test Cases", async () => {
    const txtKernalHullRangeMin = screen.getByTestId("txtKernalHullRangeMin");
    expect(txtKernalHullRangeMin.tagName).toBe("INPUT");
    expect(txtKernalHullRangeMin.type).toBe("text");
    expect(txtKernalHullRangeMin.classList).toContain("textboxWidgetClass");
    expect(txtKernalHullRangeMin.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_txtKernalHullRangeMin")
    );
    await act(async () => {
      userEvent.type(txtKernalHullRangeMin, "1");
    });
  });
  test("Custom Test Cases for txtKernalHullRangeMin", () => {
    // START_USER_CODE-USER_txtKernalHullRangeMin_TEST
    // END_USER_CODE-USER_txtKernalHullRangeMin_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:AreaProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
});
