import React from "react";
import {getPanelClasses} from "../ParentWidgetFunc";
import Row from "react-bootstrap/Row";

function CommandContainerWidget (props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
    return (
      <div className={getPanelClasses(conf,screenConf).join(" ")}>
        <Row className="mx-0">{props.children}</Row>
      </div>
    );
  
}

export default CommandContainerWidget;
