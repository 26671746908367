/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PeanutSpecificationSetup from "./PeanutSpecificationSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PeanutSpecificationSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutSpecificationSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is PeanutSpecificationSetup Loads Successfully", () => {
    expect(screen.getByText("PeanutSpecificationSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for PeanutSpecificationSetup", () => {
    // START_USER_CODE-USER_PeanutSpecificationSetup_Custom_Test_Case
    // END_USER_CODE-USER_PeanutSpecificationSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutSpecificationSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAddS(Button Widget) Test Cases", async () => {
    const btnAddS = screen.getByTestId("btnAddS");
    expect(btnAddS).toBeInTheDocument;
    expect(btnAddS.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnAddS")
    );
  });
  test("Custom Test Cases for btnAddS", () => {
    // START_USER_CODE-USER_btnAddS_TEST
    // END_USER_CODE-USER_btnAddS_TEST
  });
  test("btnAddSE(Button Widget) Test Cases", async () => {
    const btnAddSE = screen.getByTestId("btnAddSE");
    expect(btnAddSE).toBeInTheDocument;
    expect(btnAddSE.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnAddSE")
    );
  });
  test("Custom Test Cases for btnAddSE", () => {
    // START_USER_CODE-USER_btnAddSE_TEST
    // END_USER_CODE-USER_btnAddSE_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btncmdEnableDisableControls(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls = screen.getByTestId(
      "btncmdEnableDisableControls"
    );
    expect(btncmdEnableDisableControls).toBeInTheDocument;
    expect(btncmdEnableDisableControls.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btncmdEnableDisableControls"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls_TEST
  });
  test("btnDeleteS(Button Widget) Test Cases", async () => {
    const btnDeleteS = screen.getByTestId("btnDeleteS");
    expect(btnDeleteS).toBeInTheDocument;
    expect(btnDeleteS.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnDeleteS")
    );
  });
  test("Custom Test Cases for btnDeleteS", () => {
    // START_USER_CODE-USER_btnDeleteS_TEST
    // END_USER_CODE-USER_btnDeleteS_TEST
  });
  test("btnDeleteSE(Button Widget) Test Cases", async () => {
    const btnDeleteSE = screen.getByTestId("btnDeleteSE");
    expect(btnDeleteSE).toBeInTheDocument;
    expect(btnDeleteSE.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnDeleteSE"
      )
    );
  });
  test("Custom Test Cases for btnDeleteSE", () => {
    // START_USER_CODE-USER_btnDeleteSE_TEST
    // END_USER_CODE-USER_btnDeleteSE_TEST
  });
  test("btnEditS(Button Widget) Test Cases", async () => {
    const btnEditS = screen.getByTestId("btnEditS");
    expect(btnEditS).toBeInTheDocument;
    expect(btnEditS.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnEditS")
    );
  });
  test("Custom Test Cases for btnEditS", () => {
    // START_USER_CODE-USER_btnEditS_TEST
    // END_USER_CODE-USER_btnEditS_TEST
  });
  test("btnEditSE(Button Widget) Test Cases", async () => {
    const btnEditSE = screen.getByTestId("btnEditSE");
    expect(btnEditSE).toBeInTheDocument;
    expect(btnEditSE.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationSetup_btnEditSE")
    );
  });
  test("Custom Test Cases for btnEditSE", () => {
    // START_USER_CODE-USER_btnEditSE_TEST
    // END_USER_CODE-USER_btnEditSE_TEST
  });
  test("gridSpecificationExpectations(Grid Widget) Test Cases", async () => {
    let gridSpecificationExpectations = screen.getByTestId(
      "gridSpecificationExpectations"
    );
    let gridSpecificationExpectationsbtn =
      gridSpecificationExpectations.nextElementSibling.firstElementChild;
    gridSpecificationExpectations =
      gridSpecificationExpectations.parentElement.parentElement.parentElement;
    expect(gridSpecificationExpectations.tagName).toBe("DIV");
    expect(gridSpecificationExpectations.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecificationExpectations"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSpecificationExpectations", () => {
    // START_USER_CODE-USER_gridSpecificationExpectations_TEST
    // END_USER_CODE-USER_gridSpecificationExpectations_TEST
  });
  test("gridSpecifications(Grid Widget) Test Cases", async () => {
    let gridSpecifications = screen.getByTestId("gridSpecifications");
    let gridSpecificationsbtn =
      gridSpecifications.nextElementSibling.firstElementChild;
    gridSpecifications =
      gridSpecifications.parentElement.parentElement.parentElement;
    expect(gridSpecifications.tagName).toBe("DIV");
    expect(gridSpecifications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecifications"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSpecifications", () => {
    // START_USER_CODE-USER_gridSpecifications_TEST
    // END_USER_CODE-USER_gridSpecifications_TEST
  });
  test("grpbxPeanutSpecificationSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutSpecificationSetup = screen.getByTestId(
      "grpbxPeanutSpecificationSetup"
    );
    expect(grpbxPeanutSpecificationSetup.tagName).toBe("BUTTON");
    expect(grpbxPeanutSpecificationSetup.type).toBe("button");
    expect(grpbxPeanutSpecificationSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutSpecificationSetup", () => {
    // START_USER_CODE-USER_grpbxPeanutSpecificationSetup_TEST
    // END_USER_CODE-USER_grpbxPeanutSpecificationSetup_TEST
  });
  test("lblSpecificationExpectations(Label Widget) Test Cases", async () => {
    const lblSpecificationExpectations = screen.getByTestId(
      "lblSpecificationExpectations"
    );
    expect(lblSpecificationExpectations.tagName).toBe("LABEL");
    expect(lblSpecificationExpectations.classList).toContain("form-label");
    expect(lblSpecificationExpectations.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_lblSpecificationExpectations"
      )
    );
  });
  test("Custom Test Cases for lblSpecificationExpectations", () => {
    // START_USER_CODE-USER_lblSpecificationExpectations_TEST
    // END_USER_CODE-USER_lblSpecificationExpectations_TEST
  });
  test("lblSpecifications(Label Widget) Test Cases", async () => {
    const lblSpecifications = screen.getByTestId("lblSpecifications");
    expect(lblSpecifications.tagName).toBe("LABEL");
    expect(lblSpecifications.classList).toContain("form-label");
    expect(lblSpecifications.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_lblSpecifications"
      )
    );
  });
  test("Custom Test Cases for lblSpecifications", () => {
    // START_USER_CODE-USER_lblSpecifications_TEST
    // END_USER_CODE-USER_lblSpecifications_TEST
  });
  test("gridSpecifications_txtEdibleOilS(Grid Widget) Test Cases", async () => {
    let gridSpecifications_txtEdibleOilS = screen.getByTestId(
      "gridSpecifications"
    );
    let gridSpecifications_txtEdibleOilSbtn =
      gridSpecifications_txtEdibleOilS.nextElementSibling.firstElementChild;
    gridSpecifications_txtEdibleOilS =
      gridSpecifications_txtEdibleOilS.parentElement.parentElement
        .parentElement;
    expect(gridSpecifications_txtEdibleOilS.tagName).toBe("DIV");
    expect(gridSpecifications_txtEdibleOilS.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecifications"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEdibleOilS", () => {
    // START_USER_CODE-USER_txtEdibleOilS_TEST
    // END_USER_CODE-USER_txtEdibleOilS_TEST
  });
  test("gridSpecificationExpectations_txtEdibleOilSE(Grid Widget) Test Cases", async () => {
    let gridSpecificationExpectations_txtEdibleOilSE = screen.getByTestId(
      "gridSpecificationExpectations"
    );
    let gridSpecificationExpectations_txtEdibleOilSEbtn =
      gridSpecificationExpectations_txtEdibleOilSE.nextElementSibling
        .firstElementChild;
    gridSpecificationExpectations_txtEdibleOilSE =
      gridSpecificationExpectations_txtEdibleOilSE.parentElement.parentElement
        .parentElement;
    expect(gridSpecificationExpectations_txtEdibleOilSE.tagName).toBe("DIV");
    expect(gridSpecificationExpectations_txtEdibleOilSE.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecificationExpectations"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEdibleOilSE", () => {
    // START_USER_CODE-USER_txtEdibleOilSE_TEST
    // END_USER_CODE-USER_txtEdibleOilSE_TEST
  });
  test("gridSpecifications_txtPeanutTypeS(Grid Widget) Test Cases", async () => {
    let gridSpecifications_txtPeanutTypeS = screen.getByTestId(
      "gridSpecifications"
    );
    let gridSpecifications_txtPeanutTypeSbtn =
      gridSpecifications_txtPeanutTypeS.nextElementSibling.firstElementChild;
    gridSpecifications_txtPeanutTypeS =
      gridSpecifications_txtPeanutTypeS.parentElement.parentElement
        .parentElement;
    expect(gridSpecifications_txtPeanutTypeS.tagName).toBe("DIV");
    expect(gridSpecifications_txtPeanutTypeS.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecifications"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanutTypeS", () => {
    // START_USER_CODE-USER_txtPeanutTypeS_TEST
    // END_USER_CODE-USER_txtPeanutTypeS_TEST
  });
  test("gridSpecificationExpectations_txtPeanutTypeSE(Grid Widget) Test Cases", async () => {
    let gridSpecificationExpectations_txtPeanutTypeSE = screen.getByTestId(
      "gridSpecificationExpectations"
    );
    let gridSpecificationExpectations_txtPeanutTypeSEbtn =
      gridSpecificationExpectations_txtPeanutTypeSE.nextElementSibling
        .firstElementChild;
    gridSpecificationExpectations_txtPeanutTypeSE =
      gridSpecificationExpectations_txtPeanutTypeSE.parentElement.parentElement
        .parentElement;
    expect(gridSpecificationExpectations_txtPeanutTypeSE.tagName).toBe("DIV");
    expect(gridSpecificationExpectations_txtPeanutTypeSE.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecificationExpectations"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanutTypeSE", () => {
    // START_USER_CODE-USER_txtPeanutTypeSE_TEST
    // END_USER_CODE-USER_txtPeanutTypeSE_TEST
  });
  test("gridSpecificationExpectations_txtPeanutVarietySE(Grid Widget) Test Cases", async () => {
    let gridSpecificationExpectations_txtPeanutVarietySE = screen.getByTestId(
      "gridSpecificationExpectations"
    );
    let gridSpecificationExpectations_txtPeanutVarietySEbtn =
      gridSpecificationExpectations_txtPeanutVarietySE.nextElementSibling
        .firstElementChild;
    gridSpecificationExpectations_txtPeanutVarietySE =
      gridSpecificationExpectations_txtPeanutVarietySE.parentElement
        .parentElement.parentElement;
    expect(gridSpecificationExpectations_txtPeanutVarietySE.tagName).toBe(
      "DIV"
    );
    expect(
      gridSpecificationExpectations_txtPeanutVarietySE.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecificationExpectations"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanutVarietySE", () => {
    // START_USER_CODE-USER_txtPeanutVarietySE_TEST
    // END_USER_CODE-USER_txtPeanutVarietySE_TEST
  });
  test("gridSpecifications_txtSeedS(Grid Widget) Test Cases", async () => {
    let gridSpecifications_txtSeedS = screen.getByTestId("gridSpecifications");
    let gridSpecifications_txtSeedSbtn =
      gridSpecifications_txtSeedS.nextElementSibling.firstElementChild;
    gridSpecifications_txtSeedS =
      gridSpecifications_txtSeedS.parentElement.parentElement.parentElement;
    expect(gridSpecifications_txtSeedS.tagName).toBe("DIV");
    expect(gridSpecifications_txtSeedS.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecifications"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSeedS", () => {
    // START_USER_CODE-USER_txtSeedS_TEST
    // END_USER_CODE-USER_txtSeedS_TEST
  });
  test("gridSpecificationExpectations_txtSeedSE(Grid Widget) Test Cases", async () => {
    let gridSpecificationExpectations_txtSeedSE = screen.getByTestId(
      "gridSpecificationExpectations"
    );
    let gridSpecificationExpectations_txtSeedSEbtn =
      gridSpecificationExpectations_txtSeedSE.nextElementSibling
        .firstElementChild;
    gridSpecificationExpectations_txtSeedSE =
      gridSpecificationExpectations_txtSeedSE.parentElement.parentElement
        .parentElement;
    expect(gridSpecificationExpectations_txtSeedSE.tagName).toBe("DIV");
    expect(gridSpecificationExpectations_txtSeedSE.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecificationExpectations"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSeedSE", () => {
    // START_USER_CODE-USER_txtSeedSE_TEST
    // END_USER_CODE-USER_txtSeedSE_TEST
  });
  test("gridSpecifications_txtSegS(Grid Widget) Test Cases", async () => {
    let gridSpecifications_txtSegS = screen.getByTestId("gridSpecifications");
    let gridSpecifications_txtSegSbtn =
      gridSpecifications_txtSegS.nextElementSibling.firstElementChild;
    gridSpecifications_txtSegS =
      gridSpecifications_txtSegS.parentElement.parentElement.parentElement;
    expect(gridSpecifications_txtSegS.tagName).toBe("DIV");
    expect(gridSpecifications_txtSegS.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecifications"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSegS", () => {
    // START_USER_CODE-USER_txtSegS_TEST
    // END_USER_CODE-USER_txtSegS_TEST
  });
  test("gridSpecificationExpectations_txtSegSE(Grid Widget) Test Cases", async () => {
    let gridSpecificationExpectations_txtSegSE = screen.getByTestId(
      "gridSpecificationExpectations"
    );
    let gridSpecificationExpectations_txtSegSEbtn =
      gridSpecificationExpectations_txtSegSE.nextElementSibling
        .firstElementChild;
    gridSpecificationExpectations_txtSegSE =
      gridSpecificationExpectations_txtSegSE.parentElement.parentElement
        .parentElement;
    expect(gridSpecificationExpectations_txtSegSE.tagName).toBe("DIV");
    expect(gridSpecificationExpectations_txtSegSE.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:PeanutSpecificationSetup_gridSpecificationExpectations"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSegSE", () => {
    // START_USER_CODE-USER_txtSegSE_TEST
    // END_USER_CODE-USER_txtSegSE_TEST
  });
});
