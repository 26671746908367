/* eslint-disable*/
import React from "react";
import StockTransfer_MultiStopSelect from "./MultiStopSelect";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("MultiStopSelect Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_MultiStopSelect />);
    });
  });
  afterEach(cleanup);
  test("is MultiStopSelect Loads Successfully", () => {
    expect(screen.getByText("MultiStopSelect")).toBeInTheDocument;
  });
  test("Custom Test Cases for MultiStopSelect", () => {
    // START_USER_CODE-USER_MultiStopSelect_Custom_Test_Case
    // END_USER_CODE-USER_MultiStopSelect_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_MultiStopSelect />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnSelect(Button Widget) Test Cases", async () => {
    const btnSelect = screen.getByTestId("btnSelect");
    expect(btnSelect).toBeInTheDocument;
    expect(btnSelect.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_btnSelect")
    );
  });
  test("Custom Test Cases for btnSelect", () => {
    // START_USER_CODE-USER_btnSelect_TEST
    // END_USER_CODE-USER_btnSelect_TEST
  });
  test("gridTransferList(Grid Widget) Test Cases", async () => {
    let gridTransferList = screen.getByTestId("gridTransferList");
    let gridTransferListbtn =
      gridTransferList.nextElementSibling.firstElementChild;
    gridTransferList =
      gridTransferList.parentElement.parentElement.parentElement;
    expect(gridTransferList.tagName).toBe("DIV");
    expect(gridTransferList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridTransferList", () => {
    // START_USER_CODE-USER_gridTransferList_TEST
    // END_USER_CODE-USER_gridTransferList_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxMultiStopSelect(GroupBox Widget) Test Cases", async () => {
    const grpbxMultiStopSelect = screen.getByTestId("grpbxMultiStopSelect");
    expect(grpbxMultiStopSelect.tagName).toBe("BUTTON");
    expect(grpbxMultiStopSelect.type).toBe("button");
    expect(grpbxMultiStopSelect.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMultiStopSelect", () => {
    // START_USER_CODE-USER_grpbxMultiStopSelect_TEST
    // END_USER_CODE-USER_grpbxMultiStopSelect_TEST
  });
  test("grpbxTransferList(GroupBox Widget) Test Cases", async () => {
    const grpbxTransferList = screen.getByTestId("grpbxTransferList");
    expect(grpbxTransferList.tagName).toBe("BUTTON");
    expect(grpbxTransferList.type).toBe("button");
    expect(grpbxTransferList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransferList", () => {
    // START_USER_CODE-USER_grpbxTransferList_TEST
    // END_USER_CODE-USER_grpbxTransferList_TEST
  });
  test("lblTransferList(Label Widget) Test Cases", async () => {
    const lblTransferList = screen.getByTestId("lblTransferList");
    expect(lblTransferList.tagName).toBe("LABEL");
    expect(lblTransferList.classList).toContain("form-label");
    expect(lblTransferList.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_lblTransferList")
    );
  });
  test("Custom Test Cases for lblTransferList", () => {
    // START_USER_CODE-USER_lblTransferList_TEST
    // END_USER_CODE-USER_lblTransferList_TEST
  });
  test("gridTransferList_txtcolDestinationBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolDestinationBuyingPoint = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolDestinationBuyingPointbtn =
      gridTransferList_txtcolDestinationBuyingPoint.nextElementSibling
        .firstElementChild;
    gridTransferList_txtcolDestinationBuyingPoint =
      gridTransferList_txtcolDestinationBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolDestinationBuyingPoint.tagName).toBe("DIV");
    expect(gridTransferList_txtcolDestinationBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDestinationBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolDestinationBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolDestinationBuyingPoint_TEST
  });
  test("gridTransferList_txtcolOriginalBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolOriginalBuyingPoint = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolOriginalBuyingPointbtn =
      gridTransferList_txtcolOriginalBuyingPoint.nextElementSibling
        .firstElementChild;
    gridTransferList_txtcolOriginalBuyingPoint =
      gridTransferList_txtcolOriginalBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolOriginalBuyingPoint.tagName).toBe("DIV");
    expect(gridTransferList_txtcolOriginalBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOriginalBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolOriginalBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolOriginalBuyingPoint_TEST
  });
  test("gridTransferList_txtcolPayInd(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolPayInd = screen.getByTestId("gridTransferList");
    let gridTransferList_txtcolPayIndbtn =
      gridTransferList_txtcolPayInd.nextElementSibling.firstElementChild;
    gridTransferList_txtcolPayInd =
      gridTransferList_txtcolPayInd.parentElement.parentElement.parentElement;
    expect(gridTransferList_txtcolPayInd.tagName).toBe("DIV");
    expect(gridTransferList_txtcolPayInd.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayInd", () => {
    // START_USER_CODE-USER_txtcolPayInd_TEST
    // END_USER_CODE-USER_txtcolPayInd_TEST
  });
  test("gridTransferList_txtcolScacID(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolScacID = screen.getByTestId("gridTransferList");
    let gridTransferList_txtcolScacIDbtn =
      gridTransferList_txtcolScacID.nextElementSibling.firstElementChild;
    gridTransferList_txtcolScacID =
      gridTransferList_txtcolScacID.parentElement.parentElement.parentElement;
    expect(gridTransferList_txtcolScacID.tagName).toBe("DIV");
    expect(gridTransferList_txtcolScacID.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolScacID", () => {
    // START_USER_CODE-USER_txtcolScacID_TEST
    // END_USER_CODE-USER_txtcolScacID_TEST
  });
  test("gridTransferList_txtcolTransferNum(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolTransferNum = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolTransferNumbtn =
      gridTransferList_txtcolTransferNum.nextElementSibling.firstElementChild;
    gridTransferList_txtcolTransferNum =
      gridTransferList_txtcolTransferNum.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolTransferNum.tagName).toBe("DIV");
    expect(gridTransferList_txtcolTransferNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferNum", () => {
    // START_USER_CODE-USER_txtcolTransferNum_TEST
    // END_USER_CODE-USER_txtcolTransferNum_TEST
  });
  test("gridTransferList_txtcolTransferType(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolTransferType = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolTransferTypebtn =
      gridTransferList_txtcolTransferType.nextElementSibling.firstElementChild;
    gridTransferList_txtcolTransferType =
      gridTransferList_txtcolTransferType.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolTransferType.tagName).toBe("DIV");
    expect(gridTransferList_txtcolTransferType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferType", () => {
    // START_USER_CODE-USER_txtcolTransferType_TEST
    // END_USER_CODE-USER_txtcolTransferType_TEST
  });
  test("gridTransferList_txtcolVehicleNum(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolVehicleNum = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolVehicleNumbtn =
      gridTransferList_txtcolVehicleNum.nextElementSibling.firstElementChild;
    gridTransferList_txtcolVehicleNum =
      gridTransferList_txtcolVehicleNum.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolVehicleNum.tagName).toBe("DIV");
    expect(gridTransferList_txtcolVehicleNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVehicleNum", () => {
    // START_USER_CODE-USER_txtcolVehicleNum_TEST
    // END_USER_CODE-USER_txtcolVehicleNum_TEST
  });
  test("gridTransferList_txtcolVendorNum(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolVendorNum = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolVendorNumbtn =
      gridTransferList_txtcolVendorNum.nextElementSibling.firstElementChild;
    gridTransferList_txtcolVendorNum =
      gridTransferList_txtcolVendorNum.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolVendorNum.tagName).toBe("DIV");
    expect(gridTransferList_txtcolVendorNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSelect_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorNum", () => {
    // START_USER_CODE-USER_txtcolVendorNum_TEST
    // END_USER_CODE-USER_txtcolVendorNum_TEST
  });
  test("txtScacID(Textbox Widget) Test Cases", async () => {
    const txtScacID = screen.getByTestId("txtScacID");
    expect(txtScacID.tagName).toBe("INPUT");
    expect(txtScacID.type).toBe("text");
    expect(txtScacID.classList).toContain("textboxWidgetClass");
    expect(txtScacID.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_txtScacID")
    );
    await act(async () => {
      userEvent.type(txtScacID, "1");
    });
  });
  test("Custom Test Cases for txtScacID", () => {
    // START_USER_CODE-USER_txtScacID_TEST
    // END_USER_CODE-USER_txtScacID_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_txtTransferNum")
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtVehicleNum(Textbox Widget) Test Cases", async () => {
    const txtVehicleNum = screen.getByTestId("txtVehicleNum");
    expect(txtVehicleNum.tagName).toBe("INPUT");
    expect(txtVehicleNum.type).toBe("text");
    expect(txtVehicleNum.classList).toContain("textboxWidgetClass");
    expect(txtVehicleNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_txtVehicleNum")
    );
    await act(async () => {
      userEvent.type(txtVehicleNum, "1");
    });
  });
  test("Custom Test Cases for txtVehicleNum", () => {
    // START_USER_CODE-USER_txtVehicleNum_TEST
    // END_USER_CODE-USER_txtVehicleNum_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopSelect_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
});
