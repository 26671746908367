/* eslint-disable*/
import React from "react";
import ContractManagement_ContractProfile from "./ContractProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractProfile />);
    });
  });
  afterEach(cleanup);
  test("is ContractProfile Loads Successfully", () => {
    expect(screen.getByText("Contract Profile")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractProfile", () => {
    // START_USER_CODE-USER_ContractProfile_Custom_Test_Case
    // END_USER_CODE-USER_ContractProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAccDist(Button Widget) Test Cases", async () => {
    const btnAccDist = screen.getByTestId("btnAccDist");
    expect(btnAccDist).toBeInTheDocument;
    expect(btnAccDist.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnAccDist")
    );
  });
  test("Custom Test Cases for btnAccDist", () => {
    // START_USER_CODE-USER_btnAccDist_TEST
    // END_USER_CODE-USER_btnAccDist_TEST
  });
  test("btnApprv(Button Widget) Test Cases", async () => {
    const btnApprv = screen.getByTestId("btnApprv");
    expect(btnApprv).toBeInTheDocument;
    expect(btnApprv.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnApprv")
    );
  });
  test("Custom Test Cases for btnApprv", () => {
    // START_USER_CODE-USER_btnApprv_TEST
    // END_USER_CODE-USER_btnApprv_TEST
  });
  test("btnChemiclAgreemnt(Button Widget) Test Cases", async () => {
    const btnChemiclAgreemnt = screen.getByTestId("btnChemiclAgreemnt");
    expect(btnChemiclAgreemnt).toBeInTheDocument;
    expect(btnChemiclAgreemnt.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnChemiclAgreemnt")
    );
  });
  test("Custom Test Cases for btnChemiclAgreemnt", () => {
    // START_USER_CODE-USER_btnChemiclAgreemnt_TEST
    // END_USER_CODE-USER_btnChemiclAgreemnt_TEST
  });
  test("btnCntnr(GroupBox Widget) Test Cases", async () => {
    const btnCntnr = screen.getByTestId("btnCntnr");
    expect(btnCntnr.tagName).toBe("BUTTON");
    expect(btnCntnr.type).toBe("button");
    expect(btnCntnr.classList).toContain("btn");
  });
  test("Custom Test Cases for btnCntnr", () => {
    // START_USER_CODE-USER_btnCntnr_TEST
    // END_USER_CODE-USER_btnCntnr_TEST
  });
  test("btnCreate(Button Widget) Test Cases", async () => {
    const btnCreate = screen.getByTestId("btnCreate");
    expect(btnCreate).toBeInTheDocument;
    expect(btnCreate.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnCreate")
    );
  });
  test("Custom Test Cases for btnCreate", () => {
    // START_USER_CODE-USER_btnCreate_TEST
    // END_USER_CODE-USER_btnCreate_TEST
  });
  test("btnDeactivate(Button Widget) Test Cases", async () => {
    const btnDeactivate = screen.getByTestId("btnDeactivate");
    expect(btnDeactivate).toBeInTheDocument;
    expect(btnDeactivate.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnDeactivate")
    );
  });
  test("Custom Test Cases for btnDeactivate", () => {
    // START_USER_CODE-USER_btnDeactivate_TEST
    // END_USER_CODE-USER_btnDeactivate_TEST
  });
  test("btnEmail(Button Widget) Test Cases", async () => {
    const btnEmail = screen.getByTestId("btnEmail");
    expect(btnEmail).toBeInTheDocument;
    expect(btnEmail.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnEmail")
    );
  });
  test("Custom Test Cases for btnEmail", () => {
    // START_USER_CODE-USER_btnEmail_TEST
    // END_USER_CODE-USER_btnEmail_TEST
  });
  test("btnEnblDate(Button Widget) Test Cases", async () => {
    const btnEnblDate = screen.getByTestId("btnEnblDate");
    expect(btnEnblDate).toBeInTheDocument;
    expect(btnEnblDate.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnEnblDate")
    );
  });
  test("Custom Test Cases for btnEnblDate", () => {
    // START_USER_CODE-USER_btnEnblDate_TEST
    // END_USER_CODE-USER_btnEnblDate_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnFlxMktPcngDet(Button Widget) Test Cases", async () => {
    const btnFlxMktPcngDet = screen.getByTestId("btnFlxMktPcngDet");
    expect(btnFlxMktPcngDet).toBeInTheDocument;
    expect(btnFlxMktPcngDet.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnFlxMktPcngDet")
    );
  });
  test("Custom Test Cases for btnFlxMktPcngDet", () => {
    // START_USER_CODE-USER_btnFlxMktPcngDet_TEST
    // END_USER_CODE-USER_btnFlxMktPcngDet_TEST
  });
  test("btnFlxMktWrkSht(Button Widget) Test Cases", async () => {
    const btnFlxMktWrkSht = screen.getByTestId("btnFlxMktWrkSht");
    expect(btnFlxMktWrkSht).toBeInTheDocument;
    expect(btnFlxMktWrkSht.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnFlxMktWrkSht")
    );
  });
  test("Custom Test Cases for btnFlxMktWrkSht", () => {
    // START_USER_CODE-USER_btnFlxMktWrkSht_TEST
    // END_USER_CODE-USER_btnFlxMktWrkSht_TEST
  });
  test("btnFmAgreement(Button Widget) Test Cases", async () => {
    const btnFmAgreement = screen.getByTestId("btnFmAgreement");
    expect(btnFmAgreement).toBeInTheDocument;
    expect(btnFmAgreement.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnFmAgreement")
    );
  });
  test("Custom Test Cases for btnFmAgreement", () => {
    // START_USER_CODE-USER_btnFmAgreement_TEST
    // END_USER_CODE-USER_btnFmAgreement_TEST
  });
  test("btnLookup(Button Widget) Test Cases", async () => {
    const btnLookup = screen.getByTestId("btnLookup");
    expect(btnLookup).toBeInTheDocument;
    expect(btnLookup.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnLookup")
    );
  });
  test("Custom Test Cases for btnLookup", () => {
    // START_USER_CODE-USER_btnLookup_TEST
    // END_USER_CODE-USER_btnLookup_TEST
  });
  test("btnLookupFrm(Button Widget) Test Cases", async () => {
    const btnLookupFrm = screen.getByTestId("btnLookupFrm");
    expect(btnLookupFrm).toBeInTheDocument;
    expect(btnLookupFrm.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnLookupFrm")
    );
  });
  test("Custom Test Cases for btnLookupFrm", () => {
    // START_USER_CODE-USER_btnLookupFrm_TEST
    // END_USER_CODE-USER_btnLookupFrm_TEST
  });
  test("btnPopUp(Button Widget) Test Cases", async () => {
    const btnPopUp = screen.getByTestId("btnPopUp");
    expect(btnPopUp).toBeInTheDocument;
    expect(btnPopUp.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnPopUp")
    );
  });
  test("Custom Test Cases for btnPopUp", () => {
    // START_USER_CODE-USER_btnPopUp_TEST
    // END_USER_CODE-USER_btnPopUp_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPymnt(Button Widget) Test Cases", async () => {
    const btnPymnt = screen.getByTestId("btnPymnt");
    expect(btnPymnt).toBeInTheDocument;
    expect(btnPymnt.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnPymnt")
    );
  });
  test("Custom Test Cases for btnPymnt", () => {
    // START_USER_CODE-USER_btnPymnt_TEST
    // END_USER_CODE-USER_btnPymnt_TEST
  });
  test("btnRescan(Button Widget) Test Cases", async () => {
    const btnRescan = screen.getByTestId("btnRescan");
    expect(btnRescan).toBeInTheDocument;
    expect(btnRescan.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnRescan")
    );
  });
  test("Custom Test Cases for btnRescan", () => {
    // START_USER_CODE-USER_btnRescan_TEST
    // END_USER_CODE-USER_btnRescan_TEST
  });
  test("btnSeedGrwrAgreemnt(Button Widget) Test Cases", async () => {
    const btnSeedGrwrAgreemnt = screen.getByTestId("btnSeedGrwrAgreemnt");
    expect(btnSeedGrwrAgreemnt).toBeInTheDocument;
    expect(btnSeedGrwrAgreemnt.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnSeedGrwrAgreemnt")
    );
  });
  test("Custom Test Cases for btnSeedGrwrAgreemnt", () => {
    // START_USER_CODE-USER_btnSeedGrwrAgreemnt_TEST
    // END_USER_CODE-USER_btnSeedGrwrAgreemnt_TEST
  });
  test("btnSetpExcPrm(Button Widget) Test Cases", async () => {
    const btnSetpExcPrm = screen.getByTestId("btnSetpExcPrm");
    expect(btnSetpExcPrm).toBeInTheDocument;
    expect(btnSetpExcPrm.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnSetpExcPrm")
    );
  });
  test("Custom Test Cases for btnSetpExcPrm", () => {
    // START_USER_CODE-USER_btnSetpExcPrm_TEST
    // END_USER_CODE-USER_btnSetpExcPrm_TEST
  });
  test("btnSign(Button Widget) Test Cases", async () => {
    const btnSign = screen.getByTestId("btnSign");
    expect(btnSign).toBeInTheDocument;
    expect(btnSign.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnSign")
    );
  });
  test("Custom Test Cases for btnSign", () => {
    // START_USER_CODE-USER_btnSign_TEST
    // END_USER_CODE-USER_btnSign_TEST
  });
  test("btnViewUpldCntrct(Button Widget) Test Cases", async () => {
    const btnViewUpldCntrct = screen.getByTestId("btnViewUpldCntrct");
    expect(btnViewUpldCntrct).toBeInTheDocument;
    expect(btnViewUpldCntrct.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnViewUpldCntrct")
    );
  });
  test("Custom Test Cases for btnViewUpldCntrct", () => {
    // START_USER_CODE-USER_btnViewUpldCntrct_TEST
    // END_USER_CODE-USER_btnViewUpldCntrct_TEST
  });
  test("btnVndrLookupFrm2(Button Widget) Test Cases", async () => {
    const btnVndrLookupFrm2 = screen.getByTestId("btnVndrLookupFrm2");
    expect(btnVndrLookupFrm2).toBeInTheDocument;
    expect(btnVndrLookupFrm2.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnVndrLookupFrm2")
    );
  });
  test("Custom Test Cases for btnVndrLookupFrm2", () => {
    // START_USER_CODE-USER_btnVndrLookupFrm2_TEST
    // END_USER_CODE-USER_btnVndrLookupFrm2_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("ContractManagement:ContractProfile_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("contrctControl(Label Widget) Test Cases", async () => {
    const contrctControl = screen.getByTestId("contrctControl");
    expect(contrctControl.tagName).toBe("LABEL");
    expect(contrctControl.classList).toContain("form-label");
    expect(contrctControl.textContent).toEqual(
      t("ContractManagement:ContractProfile_contrctControl")
    );
  });
  test("Custom Test Cases for contrctControl", () => {
    // START_USER_CODE-USER_contrctControl_TEST
    // END_USER_CODE-USER_contrctControl_TEST
  });
  test("date(Date Widget) Test Cases", async () => {
    const date = screen.getByTestId("date");
    expect(date.tagName).toBe("INPUT");
    expect(date.type).toBe("text");
    expect(date.classList).toContain("datetimepicker-input");
    expect(date.parentElement.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_date")
    );
    await act(async () => {
      userEvent.click(date.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for date", () => {
    // START_USER_CODE-USER_date_TEST
    // END_USER_CODE-USER_date_TEST
  });
  test("dtEnd(Date Widget) Test Cases", async () => {
    const dtEnd = screen.getByTestId("dtEnd");
    expect(dtEnd.tagName).toBe("INPUT");
    expect(dtEnd.type).toBe("text");
    expect(dtEnd.classList).toContain("datetimepicker-input");
    expect(dtEnd.parentElement.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_dtEnd")
    );
    await act(async () => {
      userEvent.click(dtEnd.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtEnd", () => {
    // START_USER_CODE-USER_dtEnd_TEST
    // END_USER_CODE-USER_dtEnd_TEST
  });
  test("dtEnd2(Date Widget) Test Cases", async () => {
    const dtEnd2 = screen.getByTestId("dtEnd2");
    expect(dtEnd2.tagName).toBe("INPUT");
    expect(dtEnd2.type).toBe("text");
    expect(dtEnd2.classList).toContain("datetimepicker-input");
    await act(async () => {
      userEvent.click(dtEnd2.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtEnd2", () => {
    // START_USER_CODE-USER_dtEnd2_TEST
    // END_USER_CODE-USER_dtEnd2_TEST
  });
  test("dtHrvstCmplt(Date Widget) Test Cases", async () => {
    const dtHrvstCmplt = screen.getByTestId("dtHrvstCmplt");
    expect(dtHrvstCmplt.tagName).toBe("INPUT");
    expect(dtHrvstCmplt.type).toBe("text");
    expect(dtHrvstCmplt.classList).toContain("datetimepicker-input");
    expect(
      dtHrvstCmplt.parentElement.previousElementSibling.textContent
    ).toEqual(t("ContractManagement:ContractProfile_dtHrvstCmplt"));
    await act(async () => {
      userEvent.click(dtHrvstCmplt.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtHrvstCmplt", () => {
    // START_USER_CODE-USER_dtHrvstCmplt_TEST
    // END_USER_CODE-USER_dtHrvstCmplt_TEST
  });
  test("dtStart(Date Widget) Test Cases", async () => {
    const dtStart = screen.getByTestId("dtStart");
    expect(dtStart.tagName).toBe("INPUT");
    expect(dtStart.type).toBe("text");
    expect(dtStart.classList).toContain("datetimepicker-input");
    expect(dtStart.parentElement.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_dtStart")
    );
    await act(async () => {
      userEvent.click(dtStart.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtStart", () => {
    // START_USER_CODE-USER_dtStart_TEST
    // END_USER_CODE-USER_dtStart_TEST
  });
  test("dtStrt2(Date Widget) Test Cases", async () => {
    const dtStrt2 = screen.getByTestId("dtStrt2");
    expect(dtStrt2.tagName).toBe("INPUT");
    expect(dtStrt2.type).toBe("text");
    expect(dtStrt2.classList).toContain("datetimepicker-input");
    await act(async () => {
      userEvent.click(dtStrt2.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtStrt2", () => {
    // START_USER_CODE-USER_dtStrt2_TEST
    // END_USER_CODE-USER_dtStrt2_TEST
  });
  test("grpbx(Label Widget) Test Cases", async () => {
    const grpbx = screen.getByTestId("grpbx");
    expect(grpbx.tagName).toBe("LABEL");
    expect(grpbx.classList).toContain("form-label");
    expect(grpbx.textContent).toEqual(
      t("ContractManagement:ContractProfile_grpbx")
    );
  });
  test("Custom Test Cases for grpbx", () => {
    // START_USER_CODE-USER_grpbx_TEST
    // END_USER_CODE-USER_grpbx_TEST
  });
  test("grpbxCmnts(GroupBox Widget) Test Cases", async () => {
    const grpbxCmnts = screen.getByTestId("grpbxCmnts");
    expect(grpbxCmnts.tagName).toBe("BUTTON");
    expect(grpbxCmnts.type).toBe("button");
    expect(grpbxCmnts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCmnts", () => {
    // START_USER_CODE-USER_grpbxCmnts_TEST
    // END_USER_CODE-USER_grpbxCmnts_TEST
  });
  test("grpbxCntrctCntrl02(GroupBox Widget) Test Cases", async () => {
    const grpbxCntrctCntrl02 = screen.getByTestId("grpbxCntrctCntrl02");
    expect(grpbxCntrctCntrl02.tagName).toBe("BUTTON");
    expect(grpbxCntrctCntrl02.type).toBe("button");
    expect(grpbxCntrctCntrl02.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCntrctCntrl02", () => {
    // START_USER_CODE-USER_grpbxCntrctCntrl02_TEST
    // END_USER_CODE-USER_grpbxCntrctCntrl02_TEST
  });
  test("grpbxContrctCntrl01(GroupBox Widget) Test Cases", async () => {
    const grpbxContrctCntrl01 = screen.getByTestId("grpbxContrctCntrl01");
    expect(grpbxContrctCntrl01.tagName).toBe("BUTTON");
    expect(grpbxContrctCntrl01.type).toBe("button");
    expect(grpbxContrctCntrl01.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContrctCntrl01", () => {
    // START_USER_CODE-USER_grpbxContrctCntrl01_TEST
    // END_USER_CODE-USER_grpbxContrctCntrl01_TEST
  });
  test("grpbxContrctControl(GroupBox Widget) Test Cases", async () => {
    const grpbxContrctControl = screen.getByTestId("grpbxContrctControl");
    expect(grpbxContrctControl.tagName).toBe("BUTTON");
    expect(grpbxContrctControl.type).toBe("button");
    expect(grpbxContrctControl.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContrctControl", () => {
    // START_USER_CODE-USER_grpbxContrctControl_TEST
    // END_USER_CODE-USER_grpbxContrctControl_TEST
  });
  test("grpBxContrctDetls(GroupBox Widget) Test Cases", async () => {
    const grpBxContrctDetls = screen.getByTestId("grpBxContrctDetls");
    expect(grpBxContrctDetls.tagName).toBe("BUTTON");
    expect(grpBxContrctDetls.type).toBe("button");
    expect(grpBxContrctDetls.classList).toContain("btn");
  });
  test("Custom Test Cases for grpBxContrctDetls", () => {
    // START_USER_CODE-USER_grpBxContrctDetls_TEST
    // END_USER_CODE-USER_grpBxContrctDetls_TEST
  });
  test("grpBxDlvryPrdDesc(GroupBox Widget) Test Cases", async () => {
    const grpBxDlvryPrdDesc = screen.getByTestId("grpBxDlvryPrdDesc");
    expect(grpBxDlvryPrdDesc.tagName).toBe("BUTTON");
    expect(grpBxDlvryPrdDesc.type).toBe("button");
    expect(grpBxDlvryPrdDesc.classList).toContain("btn");
  });
  test("Custom Test Cases for grpBxDlvryPrdDesc", () => {
    // START_USER_CODE-USER_grpBxDlvryPrdDesc_TEST
    // END_USER_CODE-USER_grpBxDlvryPrdDesc_TEST
  });
  test("grpbxFarmPlntngIntn(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmPlntngIntn = screen.getByTestId("grpbxFarmPlntngIntn");
    expect(grpbxFarmPlntngIntn.tagName).toBe("BUTTON");
    expect(grpbxFarmPlntngIntn.type).toBe("button");
    expect(grpbxFarmPlntngIntn.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmPlntngIntn", () => {
    // START_USER_CODE-USER_grpbxFarmPlntngIntn_TEST
    // END_USER_CODE-USER_grpbxFarmPlntngIntn_TEST
  });
  test("grpbxFlex(GroupBox Widget) Test Cases", async () => {
    const grpbxFlex = screen.getByTestId("grpbxFlex");
    expect(grpbxFlex.tagName).toBe("BUTTON");
    expect(grpbxFlex.type).toBe("button");
    expect(grpbxFlex.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFlex", () => {
    // START_USER_CODE-USER_grpbxFlex_TEST
    // END_USER_CODE-USER_grpbxFlex_TEST
  });
  test("grpbxGrpingNo(GroupBox Widget) Test Cases", async () => {
    const grpbxGrpingNo = screen.getByTestId("grpbxGrpingNo");
    expect(grpbxGrpingNo.tagName).toBe("BUTTON");
    expect(grpbxGrpingNo.type).toBe("button");
    expect(grpbxGrpingNo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrpingNo", () => {
    // START_USER_CODE-USER_grpbxGrpingNo_TEST
    // END_USER_CODE-USER_grpbxGrpingNo_TEST
  });
  test("grpbxHdingVndr(Label Widget) Test Cases", async () => {
    const grpbxHdingVndr = screen.getByTestId("grpbxHdingVndr");
    expect(grpbxHdingVndr.tagName).toBe("LABEL");
    expect(grpbxHdingVndr.classList).toContain("form-label");
    expect(grpbxHdingVndr.textContent).toEqual(
      t("ContractManagement:ContractProfile_grpbxHdingVndr")
    );
  });
  test("Custom Test Cases for grpbxHdingVndr", () => {
    // START_USER_CODE-USER_grpbxHdingVndr_TEST
    // END_USER_CODE-USER_grpbxHdingVndr_TEST
  });
  test("grpbxLienHldr(GroupBox Widget) Test Cases", async () => {
    const grpbxLienHldr = screen.getByTestId("grpbxLienHldr");
    expect(grpbxLienHldr.tagName).toBe("BUTTON");
    expect(grpbxLienHldr.type).toBe("button");
    expect(grpbxLienHldr.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLienHldr", () => {
    // START_USER_CODE-USER_grpbxLienHldr_TEST
    // END_USER_CODE-USER_grpbxLienHldr_TEST
  });
  test("grpbxStsDates(GroupBox Widget) Test Cases", async () => {
    const grpbxStsDates = screen.getByTestId("grpbxStsDates");
    expect(grpbxStsDates.tagName).toBe("BUTTON");
    expect(grpbxStsDates.type).toBe("button");
    expect(grpbxStsDates.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStsDates", () => {
    // START_USER_CODE-USER_grpbxStsDates_TEST
    // END_USER_CODE-USER_grpbxStsDates_TEST
  });
  test("grpBxVendr(GroupBox Widget) Test Cases", async () => {
    const grpBxVendr = screen.getByTestId("grpBxVendr");
    expect(grpBxVendr.tagName).toBe("BUTTON");
    expect(grpBxVendr.type).toBe("button");
    expect(grpBxVendr.classList).toContain("btn");
  });
  test("Custom Test Cases for grpBxVendr", () => {
    // START_USER_CODE-USER_grpBxVendr_TEST
    // END_USER_CODE-USER_grpBxVendr_TEST
  });
  test("lblAcres(Label Widget) Test Cases", async () => {
    const lblAcres = screen.getByTestId("lblAcres");
    expect(lblAcres.tagName).toBe("LABEL");
    expect(lblAcres.classList).toContain("form-label");
    expect(lblAcres.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblAcres")
    );
  });
  test("Custom Test Cases for lblAcres", () => {
    // START_USER_CODE-USER_lblAcres_TEST
    // END_USER_CODE-USER_lblAcres_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAvgYld(Label Widget) Test Cases", async () => {
    const lblAvgYld = screen.getByTestId("lblAvgYld");
    expect(lblAvgYld.tagName).toBe("LABEL");
    expect(lblAvgYld.classList).toContain("form-label");
    expect(lblAvgYld.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblAvgYld")
    );
  });
  test("Custom Test Cases for lblAvgYld", () => {
    // START_USER_CODE-USER_lblAvgYld_TEST
    // END_USER_CODE-USER_lblAvgYld_TEST
  });
  test("lblBasis(Label Widget) Test Cases", async () => {
    const lblBasis = screen.getByTestId("lblBasis");
    expect(lblBasis.tagName).toBe("LABEL");
    expect(lblBasis.classList).toContain("form-label");
    expect(lblBasis.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblBasis")
    );
  });
  test("Custom Test Cases for lblBasis", () => {
    // START_USER_CODE-USER_lblBasis_TEST
    // END_USER_CODE-USER_lblBasis_TEST
  });
  test("lblChngdBy(Label Widget) Test Cases", async () => {
    const lblChngdBy = screen.getByTestId("lblChngdBy");
    expect(lblChngdBy.tagName).toBe("LABEL");
    expect(lblChngdBy.classList).toContain("form-label");
    expect(lblChngdBy.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblChngdBy")
    );
  });
  test("Custom Test Cases for lblChngdBy", () => {
    // START_USER_CODE-USER_lblChngdBy_TEST
    // END_USER_CODE-USER_lblChngdBy_TEST
  });
  test("lblCllcAdv(Label Widget) Test Cases", async () => {
    const lblCllcAdv = screen.getByTestId("lblCllcAdv");
    expect(lblCllcAdv.tagName).toBe("LABEL");
    expect(lblCllcAdv.classList).toContain("form-label");
    expect(lblCllcAdv.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblCllcAdv")
    );
  });
  test("Custom Test Cases for lblCllcAdv", () => {
    // START_USER_CODE-USER_lblCllcAdv_TEST
    // END_USER_CODE-USER_lblCllcAdv_TEST
  });
  test("lblCntrctDetls(Label Widget) Test Cases", async () => {
    const lblCntrctDetls = screen.getByTestId("lblCntrctDetls");
    expect(lblCntrctDetls.tagName).toBe("LABEL");
    expect(lblCntrctDetls.classList).toContain("form-label");
    expect(lblCntrctDetls.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblCntrctDetls")
    );
  });
  test("Custom Test Cases for lblCntrctDetls", () => {
    // START_USER_CODE-USER_lblCntrctDetls_TEST
    // END_USER_CODE-USER_lblCntrctDetls_TEST
  });
  test("lblFlexMarketPricing(Label Widget) Test Cases", async () => {
    const lblFlexMarketPricing = screen.getByTestId("lblFlexMarketPricing");
    expect(lblFlexMarketPricing.tagName).toBe("LABEL");
    expect(lblFlexMarketPricing.classList).toContain("form-label");
    expect(lblFlexMarketPricing.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblFlexMarketPricing")
    );
  });
  test("Custom Test Cases for lblFlexMarketPricing", () => {
    // START_USER_CODE-USER_lblFlexMarketPricing_TEST
    // END_USER_CODE-USER_lblFlexMarketPricing_TEST
  });
  test("lblFrm(Label Widget) Test Cases", async () => {
    const lblFrm = screen.getByTestId("lblFrm");
    expect(lblFrm.tagName).toBe("LABEL");
    expect(lblFrm.classList).toContain("form-label");
    expect(lblFrm.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblFrm")
    );
  });
  test("Custom Test Cases for lblFrm", () => {
    // START_USER_CODE-USER_lblFrm_TEST
    // END_USER_CODE-USER_lblFrm_TEST
  });
  test("lblFrmPlntIntn(Label Widget) Test Cases", async () => {
    const lblFrmPlntIntn = screen.getByTestId("lblFrmPlntIntn");
    expect(lblFrmPlntIntn.tagName).toBe("LABEL");
    expect(lblFrmPlntIntn.classList).toContain("form-label");
    expect(lblFrmPlntIntn.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblFrmPlntIntn")
    );
  });
  test("Custom Test Cases for lblFrmPlntIntn", () => {
    // START_USER_CODE-USER_lblFrmPlntIntn_TEST
    // END_USER_CODE-USER_lblFrmPlntIntn_TEST
  });
  test("lblMktPrcng(Textbox Widget) Test Cases", async () => {
    const lblMktPrcng = screen.getByTestId("lblMktPrcng");
    expect(lblMktPrcng.tagName).toBe("INPUT");
    expect(lblMktPrcng.type).toBe("text");
    expect(lblMktPrcng.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(lblMktPrcng, "1");
    });
  });
  test("Custom Test Cases for lblMktPrcng", () => {
    // START_USER_CODE-USER_lblMktPrcng_TEST
    // END_USER_CODE-USER_lblMktPrcng_TEST
  });
  test("lblRemit(Label Widget) Test Cases", async () => {
    const lblRemit = screen.getByTestId("lblRemit");
    expect(lblRemit.tagName).toBe("LABEL");
    expect(lblRemit.classList).toContain("form-label");
    expect(lblRemit.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblRemit")
    );
  });
  test("Custom Test Cases for lblRemit", () => {
    // START_USER_CODE-USER_lblRemit_TEST
    // END_USER_CODE-USER_lblRemit_TEST
  });
  test("lblSignAdvnc(Label Widget) Test Cases", async () => {
    const lblSignAdvnc = screen.getByTestId("lblSignAdvnc");
    expect(lblSignAdvnc.tagName).toBe("LABEL");
    expect(lblSignAdvnc.classList).toContain("form-label");
    expect(lblSignAdvnc.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblSignAdvnc")
    );
  });
  test("Custom Test Cases for lblSignAdvnc", () => {
    // START_USER_CODE-USER_lblSignAdvnc_TEST
    // END_USER_CODE-USER_lblSignAdvnc_TEST
  });
  test("lblStsDates(Label Widget) Test Cases", async () => {
    const lblStsDates = screen.getByTestId("lblStsDates");
    expect(lblStsDates.tagName).toBe("LABEL");
    expect(lblStsDates.classList).toContain("form-label");
    expect(lblStsDates.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblStsDates")
    );
  });
  test("Custom Test Cases for lblStsDates", () => {
    // START_USER_CODE-USER_lblStsDates_TEST
    // END_USER_CODE-USER_lblStsDates_TEST
  });
  test("lblVndr(Label Widget) Test Cases", async () => {
    const lblVndr = screen.getByTestId("lblVndr");
    expect(lblVndr.tagName).toBe("LABEL");
    expect(lblVndr.classList).toContain("form-label");
    expect(lblVndr.textContent).toEqual(
      t("ContractManagement:ContractProfile_lblVndr")
    );
  });
  test("Custom Test Cases for lblVndr", () => {
    // START_USER_CODE-USER_lblVndr_TEST
    // END_USER_CODE-USER_lblVndr_TEST
  });
  test("lienHldr2(Textbox Widget) Test Cases", async () => {
    const lienHldr2 = screen.getByTestId("lienHldr2");
    expect(lienHldr2.tagName).toBe("INPUT");
    expect(lienHldr2.type).toBe("text");
    expect(lienHldr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(lienHldr2, "1");
    });
  });
  test("Custom Test Cases for lienHldr2", () => {
    // START_USER_CODE-USER_lienHldr2_TEST
    // END_USER_CODE-USER_lienHldr2_TEST
  });
  test("mmddyy(Label Widget) Test Cases", async () => {
    const mmddyy = screen.getByTestId("mmddyy");
    expect(mmddyy.tagName).toBe("LABEL");
    expect(mmddyy.classList).toContain("form-label");
    expect(mmddyy.textContent).toEqual(
      t("ContractManagement:ContractProfile_mmddyy")
    );
  });
  test("Custom Test Cases for mmddyy", () => {
    // START_USER_CODE-USER_mmddyy_TEST
    // END_USER_CODE-USER_mmddyy_TEST
  });
  test("txt211concordia(Textbox Widget) Test Cases", async () => {
    const txt211concordia = screen.getByTestId("txt211concordia");
    expect(txt211concordia.tagName).toBe("INPUT");
    expect(txt211concordia.type).toBe("text");
    expect(txt211concordia.classList).toContain("textboxWidgetClass");
    expect(txt211concordia.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txt211concordia")
    );
    await act(async () => {
      userEvent.type(txt211concordia, "1");
    });
  });
  test("Custom Test Cases for txt211concordia", () => {
    // START_USER_CODE-USER_txt211concordia_TEST
    // END_USER_CODE-USER_txt211concordia_TEST
  });
  test("txt211Gpc(Textbox Widget) Test Cases", async () => {
    const txt211Gpc = screen.getByTestId("txt211Gpc");
    expect(txt211Gpc.tagName).toBe("INPUT");
    expect(txt211Gpc.type).toBe("text");
    expect(txt211Gpc.classList).toContain("textboxWidgetClass");
    expect(txt211Gpc.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txt211Gpc")
    );
    await act(async () => {
      userEvent.type(txt211Gpc, "1");
    });
  });
  test("Custom Test Cases for txt211Gpc", () => {
    // START_USER_CODE-USER_txt211Gpc_TEST
    // END_USER_CODE-USER_txt211Gpc_TEST
  });
  test("txtAdd2(Textbox Widget) Test Cases", async () => {
    const txtAdd2 = screen.getByTestId("txtAdd2");
    expect(txtAdd2.tagName).toBe("INPUT");
    expect(txtAdd2.type).toBe("text");
    expect(txtAdd2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAdd2, "1");
    });
  });
  test("Custom Test Cases for txtAdd2", () => {
    // START_USER_CODE-USER_txtAdd2_TEST
    // END_USER_CODE-USER_txtAdd2_TEST
  });
  test("txtAddr(Textbox Widget) Test Cases", async () => {
    const txtAddr = screen.getByTestId("txtAddr");
    expect(txtAddr.tagName).toBe("INPUT");
    expect(txtAddr.type).toBe("text");
    expect(txtAddr.classList).toContain("textboxWidgetClass");
    expect(txtAddr.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtAddr")
    );
    await act(async () => {
      userEvent.type(txtAddr, "1");
    });
  });
  test("Custom Test Cases for txtAddr", () => {
    // START_USER_CODE-USER_txtAddr_TEST
    // END_USER_CODE-USER_txtAddr_TEST
  });
  test("txtApp2(Textbox Widget) Test Cases", async () => {
    const txtApp2 = screen.getByTestId("txtApp2");
    expect(txtApp2.tagName).toBe("INPUT");
    expect(txtApp2.type).toBe("text");
    expect(txtApp2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtApp2, "1");
    });
  });
  test("Custom Test Cases for txtApp2", () => {
    // START_USER_CODE-USER_txtApp2_TEST
    // END_USER_CODE-USER_txtApp2_TEST
  });
  test("txtApplied(Textbox Widget) Test Cases", async () => {
    const txtApplied = screen.getByTestId("txtApplied");
    expect(txtApplied.tagName).toBe("INPUT");
    expect(txtApplied.type).toBe("text");
    expect(txtApplied.classList).toContain("textboxWidgetClass");
    expect(txtApplied.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtApplied")
    );
    await act(async () => {
      userEvent.type(txtApplied, "1");
    });
  });
  test("Custom Test Cases for txtApplied", () => {
    // START_USER_CODE-USER_txtApplied_TEST
    // END_USER_CODE-USER_txtApplied_TEST
  });
  test("txtAvgYld(Textbox Widget) Test Cases", async () => {
    const txtAvgYld = screen.getByTestId("txtAvgYld");
    expect(txtAvgYld.tagName).toBe("INPUT");
    expect(txtAvgYld.type).toBe("text");
    expect(txtAvgYld.classList).toContain("textboxWidgetClass");
    expect(txtAvgYld.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtAvgYld")
    );
    await act(async () => {
      userEvent.type(txtAvgYld, "1");
    });
  });
  test("Custom Test Cases for txtAvgYld", () => {
    // START_USER_CODE-USER_txtAvgYld_TEST
    // END_USER_CODE-USER_txtAvgYld_TEST
  });
  test("txtAvlLbs(Textbox Widget) Test Cases", async () => {
    const txtAvlLbs = screen.getByTestId("txtAvlLbs");
    expect(txtAvlLbs.tagName).toBe("INPUT");
    expect(txtAvlLbs.type).toBe("text");
    expect(txtAvlLbs.classList).toContain("textboxWidgetClass");
    expect(txtAvlLbs.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtAvlLbs")
    );
    await act(async () => {
      userEvent.type(txtAvlLbs, "1");
    });
  });
  test("Custom Test Cases for txtAvlLbs", () => {
    // START_USER_CODE-USER_txtAvlLbs_TEST
    // END_USER_CODE-USER_txtAvlLbs_TEST
  });
  test("txtbxMax(Textbox Widget) Test Cases", async () => {
    const txtbxMax = screen.getByTestId("txtbxMax");
    expect(txtbxMax.tagName).toBe("INPUT");
    expect(txtbxMax.type).toBe("text");
    expect(txtbxMax.classList).toContain("textboxWidgetClass");
    expect(txtbxMax.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtbxMax")
    );
    await act(async () => {
      userEvent.type(txtbxMax, "1");
    });
  });
  test("Custom Test Cases for txtbxMax", () => {
    // START_USER_CODE-USER_txtbxMax_TEST
    // END_USER_CODE-USER_txtbxMax_TEST
  });
  test("txtbxMin(Textbox Widget) Test Cases", async () => {
    const txtbxMin = screen.getByTestId("txtbxMin");
    expect(txtbxMin.tagName).toBe("INPUT");
    expect(txtbxMin.type).toBe("text");
    expect(txtbxMin.classList).toContain("textboxWidgetClass");
    expect(txtbxMin.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtbxMin")
    );
    await act(async () => {
      userEvent.type(txtbxMin, "1");
    });
  });
  test("Custom Test Cases for txtbxMin", () => {
    // START_USER_CODE-USER_txtbxMin_TEST
    // END_USER_CODE-USER_txtbxMin_TEST
  });
  test("txtCity(Textbox Widget) Test Cases", async () => {
    const txtCity = screen.getByTestId("txtCity");
    expect(txtCity.tagName).toBe("INPUT");
    expect(txtCity.type).toBe("text");
    expect(txtCity.classList).toContain("textboxWidgetClass");
    expect(txtCity.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtCity")
    );
    await act(async () => {
      userEvent.type(txtCity, "1");
    });
  });
  test("Custom Test Cases for txtCity", () => {
    // START_USER_CODE-USER_txtCity_TEST
    // END_USER_CODE-USER_txtCity_TEST
  });
  test("txtCity2(Textbox Widget) Test Cases", async () => {
    const txtCity2 = screen.getByTestId("txtCity2");
    expect(txtCity2.tagName).toBe("INPUT");
    expect(txtCity2.type).toBe("text");
    expect(txtCity2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCity2, "1");
    });
  });
  test("Custom Test Cases for txtCity2", () => {
    // START_USER_CODE-USER_txtCity2_TEST
    // END_USER_CODE-USER_txtCity2_TEST
  });
  test("txtCllcAdv(Textbox Widget) Test Cases", async () => {
    const txtCllcAdv = screen.getByTestId("txtCllcAdv");
    expect(txtCllcAdv.tagName).toBe("INPUT");
    expect(txtCllcAdv.type).toBe("text");
    expect(txtCllcAdv.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCllcAdv, "1");
    });
  });
  test("Custom Test Cases for txtCllcAdv", () => {
    // START_USER_CODE-USER_txtCllcAdv_TEST
    // END_USER_CODE-USER_txtCllcAdv_TEST
  });
  test("txtCntrctno2(Textbox Widget) Test Cases", async () => {
    const txtCntrctno2 = screen.getByTestId("txtCntrctno2");
    expect(txtCntrctno2.tagName).toBe("INPUT");
    expect(txtCntrctno2.type).toBe("text");
    expect(txtCntrctno2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCntrctno2, "1");
    });
  });
  test("Custom Test Cases for txtCntrctno2", () => {
    // START_USER_CODE-USER_txtCntrctno2_TEST
    // END_USER_CODE-USER_txtCntrctno2_TEST
  });
  test("txtCntrPivtAcrs(Textbox Widget) Test Cases", async () => {
    const txtCntrPivtAcrs = screen.getByTestId("txtCntrPivtAcrs");
    expect(txtCntrPivtAcrs.tagName).toBe("INPUT");
    expect(txtCntrPivtAcrs.type).toBe("text");
    expect(txtCntrPivtAcrs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCntrPivtAcrs, "1");
    });
  });
  test("Custom Test Cases for txtCntrPivtAcrs", () => {
    // START_USER_CODE-USER_txtCntrPivtAcrs_TEST
    // END_USER_CODE-USER_txtCntrPivtAcrs_TEST
  });
  test("txtCntrPivtAvgYld(Textbox Widget) Test Cases", async () => {
    const txtCntrPivtAvgYld = screen.getByTestId("txtCntrPivtAvgYld");
    expect(txtCntrPivtAvgYld.tagName).toBe("INPUT");
    expect(txtCntrPivtAvgYld.type).toBe("text");
    expect(txtCntrPivtAvgYld.classList).toContain("textboxWidgetClass");
    expect(txtCntrPivtAvgYld.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtCntrPivtAvgYld")
    );
    await act(async () => {
      userEvent.type(txtCntrPivtAvgYld, "1");
    });
  });
  test("Custom Test Cases for txtCntrPivtAvgYld", () => {
    // START_USER_CODE-USER_txtCntrPivtAvgYld_TEST
    // END_USER_CODE-USER_txtCntrPivtAvgYld_TEST
  });
  test("txtContrctNo(Textbox Widget) Test Cases", async () => {
    const txtContrctNo = screen.getByTestId("txtContrctNo");
    expect(txtContrctNo.tagName).toBe("INPUT");
    expect(txtContrctNo.type).toBe("text");
    expect(txtContrctNo.classList).toContain("textboxWidgetClass");
    expect(txtContrctNo.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtContrctNo")
    );
    await act(async () => {
      userEvent.type(txtContrctNo, "1");
    });
  });
  test("Custom Test Cases for txtContrctNo", () => {
    // START_USER_CODE-USER_txtContrctNo_TEST
    // END_USER_CODE-USER_txtContrctNo_TEST
  });
  test("txtDel(Label Widget) Test Cases", async () => {
    const txtDel = screen.getByTestId("txtDel");
    expect(txtDel.tagName).toBe("LABEL");
    expect(txtDel.classList).toContain("form-label");
    expect(txtDel.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDel")
    );
  });
  test("Custom Test Cases for txtDel", () => {
    // START_USER_CODE-USER_txtDel_TEST
    // END_USER_CODE-USER_txtDel_TEST
  });
  test("txtDryLndAcres(Textbox Widget) Test Cases", async () => {
    const txtDryLndAcres = screen.getByTestId("txtDryLndAcres");
    expect(txtDryLndAcres.tagName).toBe("INPUT");
    expect(txtDryLndAcres.type).toBe("text");
    expect(txtDryLndAcres.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDryLndAcres, "1");
    });
  });
  test("Custom Test Cases for txtDryLndAcres", () => {
    // START_USER_CODE-USER_txtDryLndAcres_TEST
    // END_USER_CODE-USER_txtDryLndAcres_TEST
  });
  test("txtDryLndAvgYld(Textbox Widget) Test Cases", async () => {
    const txtDryLndAvgYld = screen.getByTestId("txtDryLndAvgYld");
    expect(txtDryLndAvgYld.tagName).toBe("INPUT");
    expect(txtDryLndAvgYld.type).toBe("text");
    expect(txtDryLndAvgYld.classList).toContain("textboxWidgetClass");
    expect(txtDryLndAvgYld.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDryLndAvgYld")
    );
    await act(async () => {
      userEvent.type(txtDryLndAvgYld, "1");
    });
  });
  test("Custom Test Cases for txtDryLndAvgYld", () => {
    // START_USER_CODE-USER_txtDryLndAvgYld_TEST
    // END_USER_CODE-USER_txtDryLndAvgYld_TEST
  });
  test("txtDtApprv(Textbox Widget) Test Cases", async () => {
    const txtDtApprv = screen.getByTestId("txtDtApprv");
    expect(txtDtApprv.tagName).toBe("INPUT");
    expect(txtDtApprv.type).toBe("text");
    expect(txtDtApprv.classList).toContain("textboxWidgetClass");
    expect(txtDtApprv.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDtApprv")
    );
    await act(async () => {
      userEvent.type(txtDtApprv, "1");
    });
  });
  test("Custom Test Cases for txtDtApprv", () => {
    // START_USER_CODE-USER_txtDtApprv_TEST
    // END_USER_CODE-USER_txtDtApprv_TEST
  });
  test("txtDtClose(Textbox Widget) Test Cases", async () => {
    const txtDtClose = screen.getByTestId("txtDtClose");
    expect(txtDtClose.tagName).toBe("INPUT");
    expect(txtDtClose.type).toBe("text");
    expect(txtDtClose.classList).toContain("textboxWidgetClass");
    expect(txtDtClose.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDtClose")
    );
    await act(async () => {
      userEvent.type(txtDtClose, "1");
    });
  });
  test("Custom Test Cases for txtDtClose", () => {
    // START_USER_CODE-USER_txtDtClose_TEST
    // END_USER_CODE-USER_txtDtClose_TEST
  });
  test("txtDtCncl(Textbox Widget) Test Cases", async () => {
    const txtDtCncl = screen.getByTestId("txtDtCncl");
    expect(txtDtCncl.tagName).toBe("INPUT");
    expect(txtDtCncl.type).toBe("text");
    expect(txtDtCncl.classList).toContain("textboxWidgetClass");
    expect(txtDtCncl.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDtCncl")
    );
    await act(async () => {
      userEvent.type(txtDtCncl, "1");
    });
  });
  test("Custom Test Cases for txtDtCncl", () => {
    // START_USER_CODE-USER_txtDtCncl_TEST
    // END_USER_CODE-USER_txtDtCncl_TEST
  });
  test("txtDtSign(Textbox Widget) Test Cases", async () => {
    const txtDtSign = screen.getByTestId("txtDtSign");
    expect(txtDtSign.tagName).toBe("INPUT");
    expect(txtDtSign.type).toBe("text");
    expect(txtDtSign.classList).toContain("textboxWidgetClass");
    expect(txtDtSign.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDtSign")
    );
    await act(async () => {
      userEvent.type(txtDtSign, "1");
    });
  });
  test("Custom Test Cases for txtDtSign", () => {
    // START_USER_CODE-USER_txtDtSign_TEST
    // END_USER_CODE-USER_txtDtSign_TEST
  });
  test("txtDtVoided(Textbox Widget) Test Cases", async () => {
    const txtDtVoided = screen.getByTestId("txtDtVoided");
    expect(txtDtVoided.tagName).toBe("INPUT");
    expect(txtDtVoided.type).toBe("text");
    expect(txtDtVoided.classList).toContain("textboxWidgetClass");
    expect(txtDtVoided.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtDtVoided")
    );
    await act(async () => {
      userEvent.type(txtDtVoided, "1");
    });
  });
  test("Custom Test Cases for txtDtVoided", () => {
    // START_USER_CODE-USER_txtDtVoided_TEST
    // END_USER_CODE-USER_txtDtVoided_TEST
  });
  test("txtEmail(Textbox Widget) Test Cases", async () => {
    const txtEmail = screen.getByTestId("txtEmail");
    expect(txtEmail.tagName).toBe("INPUT");
    expect(txtEmail.type).toBe("text");
    expect(txtEmail.classList).toContain("textboxWidgetClass");
    expect(txtEmail.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtEmail")
    );
    await act(async () => {
      userEvent.type(txtEmail, "1");
    });
  });
  test("Custom Test Cases for txtEmail", () => {
    // START_USER_CODE-USER_txtEmail_TEST
    // END_USER_CODE-USER_txtEmail_TEST
  });
  test("txtEntryDt(Textbox Widget) Test Cases", async () => {
    const txtEntryDt = screen.getByTestId("txtEntryDt");
    expect(txtEntryDt.tagName).toBe("INPUT");
    expect(txtEntryDt.type).toBe("text");
    expect(txtEntryDt.classList).toContain("textboxWidgetClass");
    expect(txtEntryDt.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtEntryDt")
    );
    await act(async () => {
      userEvent.type(txtEntryDt, "1");
    });
  });
  test("Custom Test Cases for txtEntryDt", () => {
    // START_USER_CODE-USER_txtEntryDt_TEST
    // END_USER_CODE-USER_txtEntryDt_TEST
  });
  test("txtGrpng(Textbox Widget) Test Cases", async () => {
    const txtGrpng = screen.getByTestId("txtGrpng");
    expect(txtGrpng.tagName).toBe("INPUT");
    expect(txtGrpng.type).toBe("text");
    expect(txtGrpng.classList).toContain("textboxWidgetClass");
    expect(txtGrpng.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtGrpng")
    );
    await act(async () => {
      userEvent.type(txtGrpng, "1");
    });
  });
  test("Custom Test Cases for txtGrpng", () => {
    // START_USER_CODE-USER_txtGrpng_TEST
    // END_USER_CODE-USER_txtGrpng_TEST
  });
  test("txtLnHldrNm(Textbox Widget) Test Cases", async () => {
    const txtLnHldrNm = screen.getByTestId("txtLnHldrNm");
    expect(txtLnHldrNm.tagName).toBe("INPUT");
    expect(txtLnHldrNm.type).toBe("text");
    expect(txtLnHldrNm.classList).toContain("textboxWidgetClass");
    expect(txtLnHldrNm.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtLnHldrNm")
    );
    await act(async () => {
      userEvent.type(txtLnHldrNm, "1");
    });
  });
  test("Custom Test Cases for txtLnHldrNm", () => {
    // START_USER_CODE-USER_txtLnHldrNm_TEST
    // END_USER_CODE-USER_txtLnHldrNm_TEST
  });
  test("txtMinDisclsr(Textbox Widget) Test Cases", async () => {
    const txtMinDisclsr = screen.getByTestId("txtMinDisclsr");
    expect(txtMinDisclsr.tagName).toBe("INPUT");
    expect(txtMinDisclsr.type).toBe("text");
    expect(txtMinDisclsr.classList).toContain("textboxWidgetClass");
    expect(txtMinDisclsr.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtMinDisclsr")
    );
    await act(async () => {
      userEvent.type(txtMinDisclsr, "1");
    });
  });
  test("Custom Test Cases for txtMinDisclsr", () => {
    // START_USER_CODE-USER_txtMinDisclsr_TEST
    // END_USER_CODE-USER_txtMinDisclsr_TEST
  });
  test("txtMktEndDt(Textbox Widget) Test Cases", async () => {
    const txtMktEndDt = screen.getByTestId("txtMktEndDt");
    expect(txtMktEndDt.tagName).toBe("INPUT");
    expect(txtMktEndDt.type).toBe("text");
    expect(txtMktEndDt.classList).toContain("textboxWidgetClass");
    expect(txtMktEndDt.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtMktEndDt")
    );
    await act(async () => {
      userEvent.type(txtMktEndDt, "1");
    });
  });
  test("Custom Test Cases for txtMktEndDt", () => {
    // START_USER_CODE-USER_txtMktEndDt_TEST
    // END_USER_CODE-USER_txtMktEndDt_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtNature2(Textbox Widget) Test Cases", async () => {
    const txtNature2 = screen.getByTestId("txtNature2");
    expect(txtNature2.tagName).toBe("INPUT");
    expect(txtNature2.type).toBe("text");
    expect(txtNature2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNature2, "1");
    });
  });
  test("Custom Test Cases for txtNature2", () => {
    // START_USER_CODE-USER_txtNature2_TEST
    // END_USER_CODE-USER_txtNature2_TEST
  });
  test("txtNtrOfLiens(Textbox Widget) Test Cases", async () => {
    const txtNtrOfLiens = screen.getByTestId("txtNtrOfLiens");
    expect(txtNtrOfLiens.tagName).toBe("INPUT");
    expect(txtNtrOfLiens.type).toBe("text");
    expect(txtNtrOfLiens.classList).toContain("textboxWidgetClass");
    expect(txtNtrOfLiens.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtNtrOfLiens")
    );
    await act(async () => {
      userEvent.type(txtNtrOfLiens, "1");
    });
  });
  test("Custom Test Cases for txtNtrOfLiens", () => {
    // START_USER_CODE-USER_txtNtrOfLiens_TEST
    // END_USER_CODE-USER_txtNtrOfLiens_TEST
  });
  test("txtOthrAcrs(Textbox Widget) Test Cases", async () => {
    const txtOthrAcrs = screen.getByTestId("txtOthrAcrs");
    expect(txtOthrAcrs.tagName).toBe("INPUT");
    expect(txtOthrAcrs.type).toBe("text");
    expect(txtOthrAcrs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOthrAcrs, "1");
    });
  });
  test("Custom Test Cases for txtOthrAcrs", () => {
    // START_USER_CODE-USER_txtOthrAcrs_TEST
    // END_USER_CODE-USER_txtOthrAcrs_TEST
  });
  test("txtOthrAvgYld(Textbox Widget) Test Cases", async () => {
    const txtOthrAvgYld = screen.getByTestId("txtOthrAvgYld");
    expect(txtOthrAvgYld.tagName).toBe("INPUT");
    expect(txtOthrAvgYld.type).toBe("text");
    expect(txtOthrAvgYld.classList).toContain("textboxWidgetClass");
    expect(txtOthrAvgYld.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtOthrAvgYld")
    );
    await act(async () => {
      userEvent.type(txtOthrAvgYld, "1");
    });
  });
  test("Custom Test Cases for txtOthrAvgYld", () => {
    // START_USER_CODE-USER_txtOthrAvgYld_TEST
    // END_USER_CODE-USER_txtOthrAvgYld_TEST
  });
  test("txtPounds(Textbox Widget) Test Cases", async () => {
    const txtPounds = screen.getByTestId("txtPounds");
    expect(txtPounds.tagName).toBe("INPUT");
    expect(txtPounds.type).toBe("text");
    expect(txtPounds.classList).toContain("textboxWidgetClass");
    expect(txtPounds.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtPounds")
    );
    await act(async () => {
      userEvent.type(txtPounds, "1");
    });
  });
  test("Custom Test Cases for txtPounds", () => {
    // START_USER_CODE-USER_txtPounds_TEST
    // END_USER_CODE-USER_txtPounds_TEST
  });
  test("txtPounds2(Textbox Widget) Test Cases", async () => {
    const txtPounds2 = screen.getByTestId("txtPounds2");
    expect(txtPounds2.tagName).toBe("INPUT");
    expect(txtPounds2.type).toBe("text");
    expect(txtPounds2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds2, "1");
    });
  });
  test("Custom Test Cases for txtPounds2", () => {
    // START_USER_CODE-USER_txtPounds2_TEST
    // END_USER_CODE-USER_txtPounds2_TEST
  });
  test("txtPriceLbs(Textbox Widget) Test Cases", async () => {
    const txtPriceLbs = screen.getByTestId("txtPriceLbs");
    expect(txtPriceLbs.tagName).toBe("INPUT");
    expect(txtPriceLbs.type).toBe("text");
    expect(txtPriceLbs.classList).toContain("textboxWidgetClass");
    expect(txtPriceLbs.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtPriceLbs")
    );
    await act(async () => {
      userEvent.type(txtPriceLbs, "1");
    });
  });
  test("Custom Test Cases for txtPriceLbs", () => {
    // START_USER_CODE-USER_txtPriceLbs_TEST
    // END_USER_CODE-USER_txtPriceLbs_TEST
  });
  test("txtRemngLbs(Textbox Widget) Test Cases", async () => {
    const txtRemngLbs = screen.getByTestId("txtRemngLbs");
    expect(txtRemngLbs.tagName).toBe("INPUT");
    expect(txtRemngLbs.type).toBe("text");
    expect(txtRemngLbs.classList).toContain("textboxWidgetClass");
    expect(txtRemngLbs.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtRemngLbs")
    );
    await act(async () => {
      userEvent.type(txtRemngLbs, "1");
    });
  });
  test("Custom Test Cases for txtRemngLbs", () => {
    // START_USER_CODE-USER_txtRemngLbs_TEST
    // END_USER_CODE-USER_txtRemngLbs_TEST
  });
  test("txtShare(Textbox Widget) Test Cases", async () => {
    const txtShare = screen.getByTestId("txtShare");
    expect(txtShare.tagName).toBe("INPUT");
    expect(txtShare.type).toBe("text");
    expect(txtShare.classList).toContain("textboxWidgetClass");
    expect(txtShare.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtShare")
    );
    await act(async () => {
      userEvent.type(txtShare, "1");
    });
  });
  test("Custom Test Cases for txtShare", () => {
    // START_USER_CODE-USER_txtShare_TEST
    // END_USER_CODE-USER_txtShare_TEST
  });
  test("txtSignAdv(Textbox Widget) Test Cases", async () => {
    const txtSignAdv = screen.getByTestId("txtSignAdv");
    expect(txtSignAdv.tagName).toBe("INPUT");
    expect(txtSignAdv.type).toBe("text");
    expect(txtSignAdv.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSignAdv, "1");
    });
  });
  test("Custom Test Cases for txtSignAdv", () => {
    // START_USER_CODE-USER_txtSignAdv_TEST
    // END_USER_CODE-USER_txtSignAdv_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "1");
    });
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtTntApp2(Textbox Widget) Test Cases", async () => {
    const txtTntApp2 = screen.getByTestId("txtTntApp2");
    expect(txtTntApp2.tagName).toBe("INPUT");
    expect(txtTntApp2.type).toBe("text");
    expect(txtTntApp2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTntApp2, "1");
    });
  });
  test("Custom Test Cases for txtTntApp2", () => {
    // START_USER_CODE-USER_txtTntApp2_TEST
    // END_USER_CODE-USER_txtTntApp2_TEST
  });
  test("txtTntAppld(Textbox Widget) Test Cases", async () => {
    const txtTntAppld = screen.getByTestId("txtTntAppld");
    expect(txtTntAppld.tagName).toBe("INPUT");
    expect(txtTntAppld.type).toBe("text");
    expect(txtTntAppld.classList).toContain("textboxWidgetClass");
    expect(txtTntAppld.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtTntAppld")
    );
    await act(async () => {
      userEvent.type(txtTntAppld, "1");
    });
  });
  test("Custom Test Cases for txtTntAppld", () => {
    // START_USER_CODE-USER_txtTntAppld_TEST
    // END_USER_CODE-USER_txtTntAppld_TEST
  });
  test("txtTntOpen(Textbox Widget) Test Cases", async () => {
    const txtTntOpen = screen.getByTestId("txtTntOpen");
    expect(txtTntOpen.tagName).toBe("INPUT");
    expect(txtTntOpen.type).toBe("text");
    expect(txtTntOpen.classList).toContain("textboxWidgetClass");
    expect(txtTntOpen.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtTntOpen")
    );
    await act(async () => {
      userEvent.type(txtTntOpen, "1");
    });
  });
  test("Custom Test Cases for txtTntOpen", () => {
    // START_USER_CODE-USER_txtTntOpen_TEST
    // END_USER_CODE-USER_txtTntOpen_TEST
  });
  test("txtTntOpen2(Textbox Widget) Test Cases", async () => {
    const txtTntOpen2 = screen.getByTestId("txtTntOpen2");
    expect(txtTntOpen2.tagName).toBe("INPUT");
    expect(txtTntOpen2.type).toBe("text");
    expect(txtTntOpen2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTntOpen2, "1");
    });
  });
  test("Custom Test Cases for txtTntOpen2", () => {
    // START_USER_CODE-USER_txtTntOpen2_TEST
    // END_USER_CODE-USER_txtTntOpen2_TEST
  });
  test("txtTotlAcrs(Textbox Widget) Test Cases", async () => {
    const txtTotlAcrs = screen.getByTestId("txtTotlAcrs");
    expect(txtTotlAcrs.tagName).toBe("INPUT");
    expect(txtTotlAcrs.type).toBe("text");
    expect(txtTotlAcrs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotlAcrs, "1");
    });
  });
  test("Custom Test Cases for txtTotlAcrs", () => {
    // START_USER_CODE-USER_txtTotlAcrs_TEST
    // END_USER_CODE-USER_txtTotlAcrs_TEST
  });
  test("txtTotlApp(Textbox Widget) Test Cases", async () => {
    const txtTotlApp = screen.getByTestId("txtTotlApp");
    expect(txtTotlApp.tagName).toBe("INPUT");
    expect(txtTotlApp.type).toBe("text");
    expect(txtTotlApp.classList).toContain("textboxWidgetClass");
    expect(txtTotlApp.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtTotlApp")
    );
    await act(async () => {
      userEvent.type(txtTotlApp, "1");
    });
  });
  test("Custom Test Cases for txtTotlApp", () => {
    // START_USER_CODE-USER_txtTotlApp_TEST
    // END_USER_CODE-USER_txtTotlApp_TEST
  });
  test("txtTotlApp2(Textbox Widget) Test Cases", async () => {
    const txtTotlApp2 = screen.getByTestId("txtTotlApp2");
    expect(txtTotlApp2.tagName).toBe("INPUT");
    expect(txtTotlApp2.type).toBe("text");
    expect(txtTotlApp2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotlApp2, "1");
    });
  });
  test("Custom Test Cases for txtTotlApp2", () => {
    // START_USER_CODE-USER_txtTotlApp2_TEST
    // END_USER_CODE-USER_txtTotlApp2_TEST
  });
  test("txtZip(Textbox Widget) Test Cases", async () => {
    const txtZip = screen.getByTestId("txtZip");
    expect(txtZip.tagName).toBe("INPUT");
    expect(txtZip.type).toBe("text");
    expect(txtZip.classList).toContain("textboxWidgetClass");
    expect(txtZip.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractProfile_txtZip")
    );
    await act(async () => {
      userEvent.type(txtZip, "1");
    });
  });
  test("Custom Test Cases for txtZip", () => {
    // START_USER_CODE-USER_txtZip_TEST
    // END_USER_CODE-USER_txtZip_TEST
  });
  test("txtZip2(Textbox Widget) Test Cases", async () => {
    const txtZip2 = screen.getByTestId("txtZip2");
    expect(txtZip2.tagName).toBe("INPUT");
    expect(txtZip2.type).toBe("text");
    expect(txtZip2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtZip2, "1");
    });
  });
  test("Custom Test Cases for txtZip2", () => {
    // START_USER_CODE-USER_txtZip2_TEST
    // END_USER_CODE-USER_txtZip2_TEST
  });
});
