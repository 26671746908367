/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getData,
  setData
} from "../../shared/WindowImports";

import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import "./EWRListUpdate.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_EWRListUpdate(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "EWRListUpdate",
    windowName: "EWRListUpdate",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.EWRListUpdate",
    // START_USER_CODE-USER_EWRListUpdate_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "EWR List Update",
      scrCode: "PN1090L",
    },
    // END_USER_CODE-USER_EWRListUpdate_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    colAddDate: {
      name: "colAddDate",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Add Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddDate_PROPERTIES

      // END_USER_CODE-USER_colAddDate_PROPERTIES
    },
    colAddUser: {
      name: "colAddUser",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Add User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddUser_PROPERTIES

      // END_USER_CODE-USER_colAddUser_PROPERTIES
    },
    colCode: {
      name: "colCode",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCode_PROPERTIES

      // END_USER_CODE-USER_colCode_PROPERTIES
    },
    colErrorMessage: {
      name: "colErrorMessage",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Error Message",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colErrorMessage_PROPERTIES

      // END_USER_CODE-USER_colErrorMessage_PROPERTIES
    },
    colErrorNum: {
      name: "colErrorNum",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Err#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colErrorNum_PROPERTIES

      // END_USER_CODE-USER_colErrorNum_PROPERTIES
    },
    colFromHolder: {
      name: "colFromHolder",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "From Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFromHolder_PROPERTIES

      // END_USER_CODE-USER_colFromHolder_PROPERTIES
    },
    colID: {
      name: "colID",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "ID",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colID_PROPERTIES

      // END_USER_CODE-USER_colID_PROPERTIES
    },
    colRcptNum: {
      name: "colRcptNum",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Rcpt#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptNum_PROPERTIES

      // END_USER_CODE-USER_colRcptNum_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colToHolder: {
      name: "colToHolder",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "To Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToHolder_PROPERTIES

      // END_USER_CODE-USER_colToHolder_PROPERTIES
    },
    colToWhse: {
      name: "colToWhse",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "To Whse",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToWhse_PROPERTIES

      // END_USER_CODE-USER_colToWhse_PROPERTIES
    },
    colUploadFile: {
      name: "colUploadFile",
      type: "GridColumnWidget",
      parent: "gridEWRList",
      Label: "Upload File",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUploadFile_PROPERTIES

      // END_USER_CODE-USER_colUploadFile_PROPERTIES
    },
    gridEWRList: {
      name: "gridEWRList",
      type: "GridWidget",
      parent: "grpbxEWRListUpdate",
      gridCellsOrder:
        "txtcolID,txtcolCode,txtcolFromHolder,txtcolRcptNum,txtcolStatus,txtcolToHolder,txtcolToWhse,txtcolUploadFile,txtcolAddUser,txtcolAddDate,txtcolErrorNum,txtcolErrorMessage",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridEWRList_PROPERTIES

      // END_USER_CODE-USER_gridEWRList_PROPERTIES
    },
    grpbxSearch: {
      name: "grpbxSearch",
      type: "GroupBoxWidget",
      parent: "grpbxEWRListUpdate",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxSearch_PROPERTIES
    },
    txtActionCode: {
      name: "txtActionCode",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Action Code:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtActionCode_PROPERTIES

      // END_USER_CODE-USER_txtActionCode_PROPERTIES
    },
    txtcolAddDate: {
      name: "txtcolAddDate",
      type: "TextBoxWidget",
      colName: "colAddDate",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAddDate_PROPERTIES

      // END_USER_CODE-USER_txtcolAddDate_PROPERTIES
    },
    txtcolAddUser: {
      name: "txtcolAddUser",
      type: "TextBoxWidget",
      colName: "colAddUser",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAddUser_PROPERTIES

      // END_USER_CODE-USER_txtcolAddUser_PROPERTIES
    },
    txtcolCode: {
      name: "txtcolCode",
      type: "TextBoxWidget",
      colName: "colCode",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCode_PROPERTIES

      // END_USER_CODE-USER_txtcolCode_PROPERTIES
    },
    txtcolErrorMessage: {
      name: "txtcolErrorMessage",
      type: "TextBoxWidget",
      colName: "colErrorMessage",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolErrorMessage_PROPERTIES

      // END_USER_CODE-USER_txtcolErrorMessage_PROPERTIES
    },
    txtcolErrorNum: {
      name: "txtcolErrorNum",
      type: "TextBoxWidget",
      colName: "colErrorNum",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolErrorNum_PROPERTIES

      // END_USER_CODE-USER_txtcolErrorNum_PROPERTIES
    },
    txtcolFromHolder: {
      name: "txtcolFromHolder",
      type: "TextBoxWidget",
      colName: "colFromHolder",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFromHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolFromHolder_PROPERTIES
    },
    txtcolID: {
      name: "txtcolID",
      type: "TextBoxWidget",
      colName: "colID",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolID_PROPERTIES

      // END_USER_CODE-USER_txtcolID_PROPERTIES
    },
    txtcolRcptNum: {
      name: "txtcolRcptNum",
      type: "TextBoxWidget",
      colName: "colRcptNum",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptNum_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolToHolder: {
      name: "txtcolToHolder",
      type: "TextBoxWidget",
      colName: "colToHolder",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolToHolder_PROPERTIES
    },
    txtcolToWhse: {
      name: "txtcolToWhse",
      type: "TextBoxWidget",
      colName: "colToWhse",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToWhse_PROPERTIES

      // END_USER_CODE-USER_txtcolToWhse_PROPERTIES
    },
    txtcolUploadFile: {
      name: "txtcolUploadFile",
      type: "TextBoxWidget",
      colName: "colUploadFile",
      parent: "gridEWRList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUploadFile_PROPERTIES

      // END_USER_CODE-USER_txtcolUploadFile_PROPERTIES
    },
    txtFromHolderID: {
      name: "txtFromHolderID",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "From Holder ID:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtFromHolderID_PROPERTIES

      // END_USER_CODE-USER_txtFromHolderID_PROPERTIES
    },
    txtReceiptNumber: {
      name: "txtReceiptNumber",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Receipt Number:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptNumber_PROPERTIES

      // END_USER_CODE-USER_txtReceiptNumber_PROPERTIES
    },
    txtToHolderID: {
      name: "txtToHolderID",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "To Holder ID:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtToHolderID_PROPERTIES

      // END_USER_CODE-USER_txtToHolderID_PROPERTIES
    },
    txtToWarehouseCode: {
      name: "txtToWarehouseCode",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "To Warehouse Code:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtToWarehouseCode_PROPERTIES

      // END_USER_CODE-USER_txtToWarehouseCode_PROPERTIES
    },
    grpbxEWRListUpdate: {
      name: "grpbxEWRListUpdate",
      type: "GroupBoxWidget",
      parent: "EWRListUpdate",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxEWRListUpdate_PROPERTIES

      // END_USER_CODE-USER_grpbxEWRListUpdate_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "EWRListUpdate",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
   // setLoading(false);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    formLoad();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const compId = compIdFromLS();

  const onbtnSearchClick = async () => {
    try{
    let ewr_holder_id = getData(thisObj, 'ewr_holder_id');
   
    let action_code = 'CGH';
    let from_holder_id = ewr_holder_id;
    let receipt_num = thisObj.values.txtReceiptNumber;
    let to_holder_id = thisObj.values.txtToHolderID;
    let to_whse_code = thisObj.values.txtToWarehouseCode;
    setLoading(true);
    let response;
    response = await WarehouseReceiptService.RetrieveEwrSendDetails(null, action_code, from_holder_id, receipt_num, to_holder_id, to_whse_code);
    

    let sendDetailsArray = []
    let sendDetailObj = {}
    if (response.length > 0) {
      
      for (let i = 0; i < response.length; i++) {

        sendDetailObj.txtcolID = response[i].transaction_num;
        sendDetailObj.txtcolCode = response[i].action_code;
        sendDetailObj.txtcolFromHolder = response[i].from_holder_id;
        sendDetailObj.txtcolRcptNum = response[i].receipt_num;
        switch (response[i].ewr_transmission_status) {
          case "A":
             sendDetailObj.txtcolStatus = "Accepted"
            break;
          case "R":
             sendDetailObj.txtcolStatus = "Rejected"
            break;
          case "N":
             sendDetailObj.txtcolStatus = "Not Ack."
            break;
          case " ":
             sendDetailObj.txtcolStatus = "Unknown"
            break;
        }
        sendDetailObj.txtcolToHolder = response[i].to_holder_id;
        sendDetailObj.txtcolToWhse = response[i].to_whse_code;
        sendDetailObj.txtcolUploadFile = response[i].upload_file;
        sendDetailObj.txtcolAddUser = response[i].add_user;
        sendDetailObj.txtcolAddDate = moment(response[i].add_date_time).format("MM/DD/YYYY");
        sendDetailObj.txtcolErrorNum = response[i].error_code;
        sendDetailObj.txtcolErrorMessage = response[i].error_text;
        sendDetailsArray.push(sendDetailObj);
        sendDetailObj = {}

      }
     
      setValue(thisObj, 'gridEWRList', sendDetailsArray)
    }
    setLoading(false);
  }
  catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
    }

    return false
  }
  return true
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;


  const bFillActionCode = async () => {
    setValue(thisObj, "txtActionCode", 'CGH');
    // getData(thisObj,'action_code','CGH')
  }

  const bFillFromHolderID = async () => {
    let response
    response = await ContractManagementService.RetrieveCompanyDetails(compId)
    let ewr_holder_id = response[0].ewrHolderId;
    setValue(thisObj, "txtFromHolderID", ewr_holder_id);
    setData(thisObj, 'ewr_holder_id', ewr_holder_id)
  }

  const onbtnCloseClick = () => {
    try {

      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      // document.getElementsByClassName("close")[0].click();
      document.getElementById("WarehouseReceipts_EWRListUpdatePopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const formLoad = async () => {
    setLoading(true)
   await bFillActionCode()
   await bFillFromHolderID()
    setLoading(false)
  }

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_EWRListUpdate*/}

              {/* END_USER_CODE-USER_BEFORE_EWRListUpdate*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxEWRListUpdate*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxEWRListUpdate*/}

              <GroupBoxWidget
                conf={state.grpbxEWRListUpdate}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxSearch*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSearch*/}

                <GroupBoxWidget conf={state.grpbxSearch} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtActionCode*/}

                  {/* END_USER_CODE-USER_BEFORE_txtActionCode*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtActionCode}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtActionCode*/}

                  {/* END_USER_CODE-USER_AFTER_txtActionCode*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFromHolderID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFromHolderID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFromHolderID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFromHolderID*/}

                  {/* END_USER_CODE-USER_AFTER_txtFromHolderID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtToHolderID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtToHolderID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtToHolderID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtToHolderID*/}

                  {/* END_USER_CODE-USER_AFTER_txtToHolderID*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReceiptNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReceiptNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtReceiptNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtToWarehouseCode*/}

                  {/* END_USER_CODE-USER_BEFORE_txtToWarehouseCode*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtToWarehouseCode}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtToWarehouseCode*/}

                  {/* END_USER_CODE-USER_AFTER_txtToWarehouseCode*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSearch*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSearch*/}
                {/* START_USER_CODE-USER_BEFORE_gridEWRList*/}

                {/* END_USER_CODE-USER_BEFORE_gridEWRList*/}

                <GridWidget
                  conf={state.gridEWRList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridEWRList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridEWRList*/}

                {/* END_USER_CODE-USER_AFTER_gridEWRList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxEWRListUpdate*/}

              {/* END_USER_CODE-USER_AFTER_grpbxEWRListUpdate*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_EWRListUpdate*/}

              {/* END_USER_CODE-USER_AFTER_EWRListUpdate*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_EWRListUpdate);
