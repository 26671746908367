/* eslint-disable*/
import React from "react";
import WarehouseReceipts_1007GroupingProfile from "./1007GroupingProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("1007GroupingProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_1007GroupingProfile />);
    });
  });
  afterEach(cleanup);
  test("is 1007GroupingProfile Loads Successfully", () => {
    expect(screen.getByText("1007GroupingProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for 1007GroupingProfile", () => {
    // START_USER_CODE-USER_1007GroupingProfile_Custom_Test_Case
    // END_USER_CODE-USER_1007GroupingProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_1007GroupingProfile />);
    });
  });
  afterEach(cleanup);
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("WarehouseReceipts:1007GroupingProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("WarehouseReceipts:1007GroupingProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnSearchFor1007(Button Widget) Test Cases", async () => {
    const btnSearchFor1007 = screen.getByTestId("btnSearchFor1007");
    expect(btnSearchFor1007).toBeInTheDocument;
    expect(btnSearchFor1007.textContent).toEqual(
      t("WarehouseReceipts:1007GroupingProfile_btnSearchFor1007")
    );
  });
  test("Custom Test Cases for btnSearchFor1007", () => {
    // START_USER_CODE-USER_btnSearchFor1007_TEST
    // END_USER_CODE-USER_btnSearchFor1007_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("WarehouseReceipts:1007GroupingProfile_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grid1007Grouping(Grid Widget) Test Cases", async () => {
    let grid1007Grouping = screen.getByTestId("grid1007Grouping");
    let grid1007Groupingbtn =
      grid1007Grouping.nextElementSibling.firstElementChild;
    grid1007Grouping =
      grid1007Grouping.parentElement.parentElement.parentElement;
    expect(grid1007Grouping.tagName).toBe("DIV");
    expect(grid1007Grouping.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GroupingProfile_grid1007Grouping")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for grid1007Grouping", () => {
    // START_USER_CODE-USER_grid1007Grouping_TEST
    // END_USER_CODE-USER_grid1007Grouping_TEST
  });
  test("grpbx1007GroupingProfile(GroupBox Widget) Test Cases", async () => {
    const grpbx1007GroupingProfile = screen.getByTestId(
      "grpbx1007GroupingProfile"
    );
    expect(grpbx1007GroupingProfile.tagName).toBe("BUTTON");
    expect(grpbx1007GroupingProfile.type).toBe("button");
    expect(grpbx1007GroupingProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007GroupingProfile", () => {
    // START_USER_CODE-USER_grpbx1007GroupingProfile_TEST
    // END_USER_CODE-USER_grpbx1007GroupingProfile_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grid1007Grouping_txtcol1007Num(Grid Widget) Test Cases", async () => {
    let grid1007Grouping_txtcol1007Num = screen.getByTestId("grid1007Grouping");
    let grid1007Grouping_txtcol1007Numbtn =
      grid1007Grouping_txtcol1007Num.nextElementSibling.firstElementChild;
    grid1007Grouping_txtcol1007Num =
      grid1007Grouping_txtcol1007Num.parentElement.parentElement.parentElement;
    expect(grid1007Grouping_txtcol1007Num.tagName).toBe("DIV");
    expect(grid1007Grouping_txtcol1007Num.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GroupingProfile_grid1007Grouping")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1007Num", () => {
    // START_USER_CODE-USER_txtcol1007Num_TEST
    // END_USER_CODE-USER_txtcol1007Num_TEST
  });
  test("grid1007Grouping_txtcolContractNum(Grid Widget) Test Cases", async () => {
    let grid1007Grouping_txtcolContractNum = screen.getByTestId(
      "grid1007Grouping"
    );
    let grid1007Grouping_txtcolContractNumbtn =
      grid1007Grouping_txtcolContractNum.nextElementSibling.firstElementChild;
    grid1007Grouping_txtcolContractNum =
      grid1007Grouping_txtcolContractNum.parentElement.parentElement
        .parentElement;
    expect(grid1007Grouping_txtcolContractNum.tagName).toBe("DIV");
    expect(grid1007Grouping_txtcolContractNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GroupingProfile_grid1007Grouping")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractNum", () => {
    // START_USER_CODE-USER_txtcolContractNum_TEST
    // END_USER_CODE-USER_txtcolContractNum_TEST
  });
  test("grid1007Grouping_txtcolLocation(Grid Widget) Test Cases", async () => {
    let grid1007Grouping_txtcolLocation = screen.getByTestId(
      "grid1007Grouping"
    );
    let grid1007Grouping_txtcolLocationbtn =
      grid1007Grouping_txtcolLocation.nextElementSibling.firstElementChild;
    grid1007Grouping_txtcolLocation =
      grid1007Grouping_txtcolLocation.parentElement.parentElement.parentElement;
    expect(grid1007Grouping_txtcolLocation.tagName).toBe("DIV");
    expect(grid1007Grouping_txtcolLocation.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:1007GroupingProfile_grid1007Grouping")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLocation", () => {
    // START_USER_CODE-USER_txtcolLocation_TEST
    // END_USER_CODE-USER_txtcolLocation_TEST
  });
  test("txtDate(Textbox Widget) Test Cases", async () => {
    const txtDate = screen.getByTestId("txtDate");
    expect(txtDate.tagName).toBe("INPUT");
    expect(txtDate.type).toBe("text");
    expect(txtDate.classList).toContain("textboxWidgetClass");
    expect(txtDate.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GroupingProfile_txtDate")
    );
    await act(async () => {
      userEvent.type(txtDate, "123");
    });
    expect(txtDate.getAttribute("value")).toBe("");
    expect(txtDate.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDate", () => {
    // START_USER_CODE-USER_txtDate_TEST
    // END_USER_CODE-USER_txtDate_TEST
  });
  test("txtGroupingNum(Textbox Widget) Test Cases", async () => {
    const txtGroupingNum = screen.getByTestId("txtGroupingNum");
    expect(txtGroupingNum.tagName).toBe("INPUT");
    expect(txtGroupingNum.type).toBe("text");
    expect(txtGroupingNum.classList).toContain("textboxWidgetClass");
    expect(txtGroupingNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GroupingProfile_txtGroupingNum")
    );
    await act(async () => {
      userEvent.type(txtGroupingNum, "123");
    });
    expect(txtGroupingNum.getAttribute("value")).toBe("");
    expect(txtGroupingNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtGroupingNum", () => {
    // START_USER_CODE-USER_txtGroupingNum_TEST
    // END_USER_CODE-USER_txtGroupingNum_TEST
  });
});
