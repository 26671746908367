/* eslint-disable*/
import React from "react";
import ContractManagement_ContractInquiry from "./ContractInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractInquiry />);
    });
  });
  afterEach(cleanup);
  test("is ContractInquiry Loads Successfully", () => {
    expect(screen.getByText("Contract Inquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractInquiry", () => {
    // START_USER_CODE-USER_ContractInquiry_Custom_Test_Case
    // END_USER_CODE-USER_ContractInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractInquiry />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportExcel(Button Widget) Test Cases", async () => {
    const btnExportExcel = screen.getByTestId("btnExportExcel");
    expect(btnExportExcel).toBeInTheDocument;
    expect(btnExportExcel.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnExportExcel")
    );
  });
  test("Custom Test Cases for btnExportExcel", () => {
    // START_USER_CODE-USER_btnExportExcel_TEST
    // END_USER_CODE-USER_btnExportExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVenLookUp(Button Widget) Test Cases", async () => {
    const btnVenLookUp = screen.getByTestId("btnVenLookUp");
    expect(btnVenLookUp).toBeInTheDocument;
    expect(btnVenLookUp.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnVenLookUp")
    );
  });
  test("Custom Test Cases for btnVenLookUp", () => {
    // START_USER_CODE-USER_btnVenLookUp_TEST
    // END_USER_CODE-USER_btnVenLookUp_TEST
  });
  test("btnViewUpload(Button Widget) Test Cases", async () => {
    const btnViewUpload = screen.getByTestId("btnViewUpload");
    expect(btnViewUpload).toBeInTheDocument;
    expect(btnViewUpload.textContent).toEqual(
      t("ContractManagement:ContractInquiry_btnViewUpload")
    );
  });
  test("Custom Test Cases for btnViewUpload", () => {
    // START_USER_CODE-USER_btnViewUpload_TEST
    // END_USER_CODE-USER_btnViewUpload_TEST
  });
  test("contractList(GroupBox Widget) Test Cases", async () => {
    const contractList = screen.getByTestId("contractList");
    expect(contractList.tagName).toBe("BUTTON");
    expect(contractList.type).toBe("button");
    expect(contractList.classList).toContain("btn");
    expect(contractList.textContent).toEqual(
      t("ContractManagement:ContractInquiry_contractList")
    );
  });
  test("Custom Test Cases for contractList", () => {
    // START_USER_CODE-USER_contractList_TEST
    // END_USER_CODE-USER_contractList_TEST
  });
  test("dtContractDate(Date Widget) Test Cases", async () => {
    const dtContractDate = screen.getByTestId("dtContractDate");
    expect(dtContractDate.tagName).toBe("INPUT");
    expect(dtContractDate.type).toBe("text");
    expect(dtContractDate.classList).toContain("datetimepicker-input");
    expect(
      dtContractDate.parentElement.previousElementSibling.textContent
    ).toEqual(t("ContractManagement:ContractInquiry_dtContractDate"));
    await act(async () => {
      userEvent.click(dtContractDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtContractDate", () => {
    // START_USER_CODE-USER_dtContractDate_TEST
    // END_USER_CODE-USER_dtContractDate_TEST
  });
  test("dtThru(Date Widget) Test Cases", async () => {
    const dtThru = screen.getByTestId("dtThru");
    expect(dtThru.tagName).toBe("INPUT");
    expect(dtThru.type).toBe("text");
    expect(dtThru.classList).toContain("datetimepicker-input");
    expect(dtThru.parentElement.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_dtThru")
    );
    await act(async () => {
      userEvent.click(dtThru.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtThru", () => {
    // START_USER_CODE-USER_dtThru_TEST
    // END_USER_CODE-USER_dtThru_TEST
  });
  test("grpAction(GroupBox Widget) Test Cases", async () => {
    const grpAction = screen.getByTestId("grpAction");
    expect(grpAction.tagName).toBe("BUTTON");
    expect(grpAction.type).toBe("button");
    expect(grpAction.classList).toContain("btn");
  });
  test("Custom Test Cases for grpAction", () => {
    // START_USER_CODE-USER_grpAction_TEST
    // END_USER_CODE-USER_grpAction_TEST
  });
  test("grpContractInquiry(GroupBox Widget) Test Cases", async () => {
    const grpContractInquiry = screen.getByTestId("grpContractInquiry");
    expect(grpContractInquiry.tagName).toBe("BUTTON");
    expect(grpContractInquiry.type).toBe("button");
    expect(grpContractInquiry.classList).toContain("btn");
  });
  test("Custom Test Cases for grpContractInquiry", () => {
    // START_USER_CODE-USER_grpContractInquiry_TEST
    // END_USER_CODE-USER_grpContractInquiry_TEST
  });
  test("lblGeneral(Label Widget) Test Cases", async () => {
    const lblGeneral = screen.getByTestId("lblGeneral");
    expect(lblGeneral.tagName).toBe("LABEL");
    expect(lblGeneral.classList).toContain("form-label");
    expect(lblGeneral.textContent).toEqual(
      t("ContractManagement:ContractInquiry_lblGeneral")
    );
  });
  test("Custom Test Cases for lblGeneral", () => {
    // START_USER_CODE-USER_lblGeneral_TEST
    // END_USER_CODE-USER_lblGeneral_TEST
  });
  test("lblLocation(Label Widget) Test Cases", async () => {
    const lblLocation = screen.getByTestId("lblLocation");
    expect(lblLocation.tagName).toBe("LABEL");
    expect(lblLocation.classList).toContain("form-label");
    expect(lblLocation.textContent).toEqual(
      t("ContractManagement:ContractInquiry_lblLocation")
    );
  });
  test("Custom Test Cases for lblLocation", () => {
    // START_USER_CODE-USER_lblLocation_TEST
    // END_USER_CODE-USER_lblLocation_TEST
  });
  test("lblPeanut(Label Widget) Test Cases", async () => {
    const lblPeanut = screen.getByTestId("lblPeanut");
    expect(lblPeanut.tagName).toBe("LABEL");
    expect(lblPeanut.classList).toContain("form-label");
    expect(lblPeanut.textContent).toEqual(
      t("ContractManagement:ContractInquiry_lblPeanut")
    );
  });
  test("Custom Test Cases for lblPeanut", () => {
    // START_USER_CODE-USER_lblPeanut_TEST
    // END_USER_CODE-USER_lblPeanut_TEST
  });
  test("lblTotals(Label Widget) Test Cases", async () => {
    const lblTotals = screen.getByTestId("lblTotals");
    expect(lblTotals.tagName).toBe("LABEL");
    expect(lblTotals.classList).toContain("form-label");
    expect(lblTotals.textContent).toEqual(
      t("ContractManagement:ContractInquiry_lblTotals")
    );
  });
  test("Custom Test Cases for lblTotals", () => {
    // START_USER_CODE-USER_lblTotals_TEST
    // END_USER_CODE-USER_lblTotals_TEST
  });
  test("txtAppliedPounds(Textbox Widget) Test Cases", async () => {
    const txtAppliedPounds = screen.getByTestId("txtAppliedPounds");
    expect(txtAppliedPounds.tagName).toBe("INPUT");
    expect(txtAppliedPounds.type).toBe("text");
    expect(txtAppliedPounds.classList).toContain("textboxWidgetClass");
    expect(txtAppliedPounds.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtAppliedPounds")
    );
    expect(txtAppliedPounds.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtAppliedPounds", () => {
    // START_USER_CODE-USER_txtAppliedPounds_TEST
    // END_USER_CODE-USER_txtAppliedPounds_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtContractPounds(Textbox Widget) Test Cases", async () => {
    const txtContractPounds = screen.getByTestId("txtContractPounds");
    expect(txtContractPounds.tagName).toBe("INPUT");
    expect(txtContractPounds.type).toBe("text");
    expect(txtContractPounds.classList).toContain("textboxWidgetClass");
    expect(txtContractPounds.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtContractPounds")
    );
    expect(txtContractPounds.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtContractPounds", () => {
    // START_USER_CODE-USER_txtContractPounds_TEST
    // END_USER_CODE-USER_txtContractPounds_TEST
  });
  test("txtDryLandAcres(Textbox Widget) Test Cases", async () => {
    const txtDryLandAcres = screen.getByTestId("txtDryLandAcres");
    expect(txtDryLandAcres.tagName).toBe("INPUT");
    expect(txtDryLandAcres.type).toBe("text");
    expect(txtDryLandAcres.classList).toContain("textboxWidgetClass");
    expect(txtDryLandAcres.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtDryLandAcres")
    );
    expect(txtDryLandAcres.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtDryLandAcres", () => {
    // START_USER_CODE-USER_txtDryLandAcres_TEST
    // END_USER_CODE-USER_txtDryLandAcres_TEST
  });
  test("txtGroup(Textbox Widget) Test Cases", async () => {
    const txtGroup = screen.getByTestId("txtGroup");
    expect(txtGroup.tagName).toBe("INPUT");
    expect(txtGroup.type).toBe("text");
    expect(txtGroup.classList).toContain("textboxWidgetClass");
    expect(txtGroup.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtGroup")
    );
    await act(async () => {
      userEvent.type(txtGroup, "1");
    });
  });
  test("Custom Test Cases for txtGroup", () => {
    // START_USER_CODE-USER_txtGroup_TEST
    // END_USER_CODE-USER_txtGroup_TEST
  });
  test("txtIrrigatedAcres(Textbox Widget) Test Cases", async () => {
    const txtIrrigatedAcres = screen.getByTestId("txtIrrigatedAcres");
    expect(txtIrrigatedAcres.tagName).toBe("INPUT");
    expect(txtIrrigatedAcres.type).toBe("text");
    expect(txtIrrigatedAcres.classList).toContain("textboxWidgetClass");
    expect(txtIrrigatedAcres.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtIrrigatedAcres")
    );
    expect(txtIrrigatedAcres.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtIrrigatedAcres", () => {
    // START_USER_CODE-USER_txtIrrigatedAcres_TEST
    // END_USER_CODE-USER_txtIrrigatedAcres_TEST
  });
  test("txtOpen(Textbox Widget) Test Cases", async () => {
    const txtOpen = screen.getByTestId("txtOpen");
    expect(txtOpen.tagName).toBe("INPUT");
    expect(txtOpen.type).toBe("text");
    expect(txtOpen.classList).toContain("textboxWidgetClass");
    expect(txtOpen.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtOpen")
    );
    expect(txtOpen.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtOpen", () => {
    // START_USER_CODE-USER_txtOpen_TEST
    // END_USER_CODE-USER_txtOpen_TEST
  });
  test("txtPrice(Textbox Widget) Test Cases", async () => {
    const txtPrice = screen.getByTestId("txtPrice");
    expect(txtPrice.tagName).toBe("INPUT");
    expect(txtPrice.type).toBe("text");
    expect(txtPrice.classList).toContain("textboxWidgetClass");
    expect(txtPrice.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtPrice")
    );
    await act(async () => {
      userEvent.type(txtPrice, "1");
    });
  });
  test("Custom Test Cases for txtPrice", () => {
    // START_USER_CODE-USER_txtPrice_TEST
    // END_USER_CODE-USER_txtPrice_TEST
  });
  test("txtTentativeApplied(Textbox Widget) Test Cases", async () => {
    const txtTentativeApplied = screen.getByTestId("txtTentativeApplied");
    expect(txtTentativeApplied.tagName).toBe("INPUT");
    expect(txtTentativeApplied.type).toBe("text");
    expect(txtTentativeApplied.classList).toContain("textboxWidgetClass");
    expect(txtTentativeApplied.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtTentativeApplied")
    );
    expect(txtTentativeApplied.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtTentativeApplied", () => {
    // START_USER_CODE-USER_txtTentativeApplied_TEST
    // END_USER_CODE-USER_txtTentativeApplied_TEST
  });
  test("txtTotalApp(Textbox Widget) Test Cases", async () => {
    const txtTotalApp = screen.getByTestId("txtTotalApp");
    expect(txtTotalApp.tagName).toBe("INPUT");
    expect(txtTotalApp.type).toBe("text");
    expect(txtTotalApp.classList).toContain("textboxWidgetClass");
    expect(txtTotalApp.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtTotalApp")
    );
    expect(txtTotalApp.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtTotalApp", () => {
    // START_USER_CODE-USER_txtTotalApp_TEST
    // END_USER_CODE-USER_txtTotalApp_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractInquiry_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
