/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  getValue,
  setValue,
  setData,
  getData,
  format,
  disable,
  goTo
} from "../../shared/WindowImports";

import "./FarmerStockPriceProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices"
import { isDate } from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FarmerStockPriceProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmerStockPriceProfile",
    windowName: "FarmerStockPriceProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FarmerStockPriceProfile",
    // START_USER_CODE-USER_FarmerStockPriceProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Farmer Stock Price Profile",
      scrCode: "PN0270B",
    },
    // END_USER_CODE-USER_FarmerStockPriceProfile_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtArea: {
      name: "txtArea",
      type: "TextBoxWidget",
      parent: "grpFarmSrockPriceProfile",
      Label: "Area:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtArea_PROPERTIES

      // END_USER_CODE-USER_txtArea_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateTimeWidget",
      parent: "grpFarmSrockPriceProfile",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtFarmerStockPrice: {
      name: "txtFarmerStockPrice",
      type: "TextBoxWidget",
      parent: "grpFarmSrockPriceProfile",
      Label: "Farmer Stock Price:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmerStockPrice_PROPERTIES

      // END_USER_CODE-USER_txtFarmerStockPrice_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpFarmSrockPriceProfile",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    grpFarmSrockPriceProfile: {
      name: "grpFarmSrockPriceProfile",
      type: "GroupBoxWidget",
      parent: "FarmerStockPriceProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpFarmSrockPriceProfile_PROPERTIES

      // END_USER_CODE-USER_grpFarmSrockPriceProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FarmerStockPriceProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  // START_USER_CODE-USER_METHODS
  
 //FormLoad function
  const FormLoad=()=>{
    let profiledetails=getData(thisObj,'StockProfileData')
    let profileCaption=profiledetails.cmdOk;

    if(profileCaption =='ADD'){
      document.getElementById('btnOk').innerText = "Add"
      document.getElementById("txtEffectiveDateParent").focus();
      setValue(thisObj, "txtArea", profiledetails.Area)
      setValue(thisObj, "txtPeanutType", profiledetails.Peanut_type)
      disable(thisObj, 'txtArea');
      disable(thisObj, 'txtPeanutType');
    }
    else if(profileCaption =='Update'){
      disable(thisObj,'txtEffectiveDate')
      disable(thisObj, 'txtArea');
      disable(thisObj, 'txtPeanutType');
      setValue(thisObj, "txtArea", profiledetails.Area)
      setValue(thisObj, "txtPeanutType", profiledetails.Peanut_type)
      setValue(thisObj, "txtEffectiveDate", profiledetails.txtEffectiveDateTime)
      let lblAddedBy1=profiledetails.lblAddedBy
      let lblAddedBy=lblAddedBy1.slice(0,-11)
      let lblChangedBy1=profiledetails.lblChangedBy
      let lblChangedBy=lblChangedBy1.slice(0,-11)
      setValue(thisObj,'lblAddedByValue',lblAddedBy)
      setValue(thisObj,'lblChangedByValue',lblChangedBy)
      setValue(thisObj,'txtFarmerStockPrice', profiledetails.txtFarmerStockPrice)
      document.getElementById("txtFarmerStockPrice").focus();
      document.getElementById('btnOk').innerText = "Update"
    }
  }
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  //Form Validation  
  const FormValid = () => {
    var bformValid = false
    let txtEffectivedate=getValue(thisObj,'txtEffectiveDate')
    const dateTime=new Date(txtEffectivedate)
    const formatDate=moment(dateTime, "yyyy-MM-dd HH:mm:ss.SSS").isValid()
    if(formatDate == false){
      showMessage(thisObj,'Invalid Effective Date Time!')
      return bformValid
    } 
    let txtFarmerStockprice1=getValue(thisObj,'txtFarmerStockPrice')
    if((!(/^\$?\d+(\.\d+)?$/).test(txtFarmerStockprice1)) || txtFarmerStockprice1 < 0 || txtFarmerStockprice1 > 9999){
      showMessage(thisObj,'Farmer Stock Price must be in ####.## format!')
      return bformValid
    }
    bformValid=true
   }

  //Add button function
  const onbtnOkClick = async() => {
   try{
    if(FormValid()==false){
      return
    }
    let profiledetails=getData(thisObj,'StockProfileData')
    let profileCaption=profiledetails.cmdOk;
    let area_id1 = getValue(thisObj, 'txtArea');
    let area_id=area_id1.slice(0,2)
    let pnut_type_id1 = getValue(thisObj, 'txtPeanutType');
    let pnut_type_id=pnut_type_id1.slice(0,2)
    let profile_effective_date1=getValue(thisObj,'txtEffectiveDate') 
    const dateTime=new Date(profile_effective_date1)
    const effect_date_time=moment(dateTime).format("yyyy-MM-DD HH:mm:ss.SSS")
    let profile_farmer_stock_price1=getValue(thisObj,'txtFarmerStockPrice')
    let market_price;
    if(profile_farmer_stock_price1.includes('$')){
      let market_price1=parseFloat(profile_farmer_stock_price1.replace('$',''));
      market_price=Number(market_price1).toFixed(2);
    }
    else{
      let market_price1=parseFloat(profile_farmer_stock_price1);
      market_price=Number(market_price1).toFixed(2);
    }
    let user_id = useridFromLS()
  
    if(profileCaption =='ADD'){
      let temp={
        "area_id": area_id,
        "pnut_type_id": pnut_type_id,
        "effect_date_time": effect_date_time,
        "market_price": market_price,
        };
      let response;
      response=await SystemMaintenanceMasterManagementService.CreateFarmerStockPriceControl(temp);
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
      }
      else{
        if(response.status==200){
          // showMessage(thisObj,response.message,true)
          setData(thisObj,'closingOfFarmerProfile',temp);
          goTo(thisObj,'SystemMaintenanceMasterManagement_FarmerStockPriceProfilePopUp')
        }
        else{
         showMessage(thisObj,response.message)
        }
      }
  }
  //Update button function  
    else if(profileCaption =='Update'){
      let data={
        "user_id": user_id,
        "market_price": market_price,
      };
      let temp={
        "area_id": area_id,
        "pnut_type_id": pnut_type_id,
        "effect_date_time": effect_date_time,
        "market_price": market_price,
        };
      let response= await SystemMaintenanceMasterManagementService.UpdateFarmerStockPriceControl(area_id,pnut_type_id,effect_date_time,data);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else{
          if(response.status==200){
            // showMessage(thisObj,response.message,true)
          }
          else{
            showMessage(thisObj,response.message)
          }
        }
      setData(thisObj,'closingOfFarmerProfile',temp);
      goTo(thisObj,'SystemMaintenanceMasterManagement_FarmerStockPriceProfilePopUp')
    }
   }
   catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btn event:Click"
      );
    }
    return false;
   }
  return true;
  }
  thisObj.onbtnOkClick= onbtnOkClick
  
  //Exit Button Function
  const onbtnExitClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_FarmerStockPriceProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnExit event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmerStockPriceProfile*/}

              {/* END_USER_CODE-USER_BEFORE_FarmerStockPriceProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpFarmSrockPriceProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpFarmSrockPriceProfile*/}

              <GroupBoxWidget
                conf={state.grpFarmSrockPriceProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtArea*/}

                {/* END_USER_CODE-USER_BEFORE_txtArea*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtArea}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtArea*/}

                {/* END_USER_CODE-USER_AFTER_txtArea*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmerStockPrice*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmerStockPrice*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmerStockPrice}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmerStockPrice*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmerStockPrice*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpFarmSrockPriceProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpFarmSrockPriceProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FarmerStockPriceProfile*/}

              {/* END_USER_CODE-USER_AFTER_FarmerStockPriceProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FarmerStockPriceProfile
);
