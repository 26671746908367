/* eslint-disable*/
import { ApiService } from "../../Common/ApiService";

export const WarehouseReceiptService = {
  RetrieveVendorHolderTransferDetails,
  RetrieveInspectHeaderAvailable1007Details,
  RetrieveEWarehouseLogDetails,
  CreateEwrInquirySendDetails,
  RetrieveWarehousePremiumDeductionList,
  RetrieveDeliveryOutAgreementWareHouseDetails,
  CreateEwrSend,
  RetrieveStoragePaidList,
  UpdateStoragePaid,
  RemoveStoragePaid,
  RetrieveWareHouseTentPurchContractApplicationDetails,
  RetrieveWareHouseReciptHeaderDetails,
  RetrieveInspectHeaderListDetails,
  RetrievePricingDataWithFailoverTestDetails,
  RetrieveExternalReceiptList,
  RetrieveExternalReceiptDetails,
  RetrievePremiumDeductionScheduleList,
  RetrievePremiumDeductionScheduleDetails,
  RetrieveWareHouseReceiptList,
  RetrieveWareHouseCheckStateCounty,
  RetrieveEWareHouseInquirySendDetails,
  RetrieveEwrSendDetails,
  UpdateExternalReceiptCopy,
  RetrieveReadyToPayWareHouseDetails,
  RetrievePaymentActivityDetails,
  RetrieveObligationPointAvailableInventoryDetails,
  RetrieveDeliveryOutreceipts,
  RetrieveWhouseLicenseControlCityStateDetails,
  RetrieveInspectHeaderDetails,
  RetrieveWhouseReceipt1007Details,
  RetrieveWareHouseReceiptWorkSheetReportDetails,
  RetrieveWareHouseWorkSheetPremiumDeductionBalanceDetails,
  RetrieveWareHouseReciptFsaDetails,
  UpdateFsaDocReady,
  RetrieveCheck1007FreeStatusDetails,
  UpdateWareHouseReceiptClearRpsFromEwr,
  Retrieve1007GroupList,
  RetrieveEWarehouseInquiryLogDetails,
  UpdateDeliveryOutwarehouseAgreements,  
  RetrieveWarehouseLockInPreviousLoadDetails,
  UpdateWarehouseLockinDetails,
  RetrieveWarehouseLockinList,
  UpdateWarehouseLockInImport,
  RetrieveWarehouseLockInPreviousLoadHeader,
  RetrieveWareHouseContractDetails,
  RetrieveWarehouseContractApplicationDetails,
  UpdateUploadSendToEWarehouse,
  UpdateLoadoutDeliveryInProcess,
  UpdateWareHouseReceiptSetFsaDocument,
  Update1007Grouping,
  RetrieveDeliveryReceiptDetails,
  RetrieveDeliveryInAgreementWareHouseDetails,
  UpdateDeliveryWareHouseAgreement,
  UpdateWareHouseReceiptRedeemByLoan,
  UpdateLoadOutDeliveryProcessDetails,
  RetrievecalCulatePurchaseValuePreAppliedDetails,
  RetrieveExternalReceiptSelectDetails,
  RetrieveCalculatedReceiptValue,
  CreateWareHouseReceipt,
  UpdateWareHouseReceiptRedeemByLoanReverse,
  CreateWareHouseReceiptRedeemByLoan,
  RetrieveSettleWhouseReceiptDetails,
  UpdateSettleTentApplication,
  UpdateExternalReceipt,
  RetrieveWareHouseValueCalculationDetails,
  UpdateExternalReceiptRelease,
  RetrieveDeliveryAgreementAttachmentDetails,
  RetrieveObligationPointDetails,
  UpdateShrinkPaymentsReverse,
  CreateShrinkPay,
  CreateStorageHandlingPaymentDetails,
  UpdateUnPurchaseWarehouseReceipt,
  UpdateWarehouseReceiptVoid,
  UpdateWhouseReceiptUnvoid,
  UpdateStorageHandlingPaymentReverse,
  UpdateExternalReceiptPayOff,
  UpdateEwrTransmissionStatus,
  UpdateVoidWareHouseReceipt,
  UpdateWareHouseReceiptChangeDate,
  UpdateWareHouseReceipt,
  UpdateReverseWarehouseReceiptTentApplicationDeatils,
  UpdateDeliveryAgreement,
  UpdateLoadOutDeliveryComplete,
  UpdateLoadOutDeliveryCompleteUndo,
  UpdateDeliverySettlementComplete,
  UpdateDeliverySettlementReverse
};

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const warehouse_receipt_url_api = '/warehouse-receipt/v1/';

export function RetrieveWareHouseReceiptList(rcpt_status, stat_type, loan_number, buy_pt_id, whse_rcpt_num, settlement_grouping_num, settlement_num, cont_num, org_buy_pt, rcpt_vendor, whouse_num,
  bin_num, pnut_type_id, seg_type, min_end_date, max_end_date, min_lock_in_date, max_lock_in_date, ewr_status, min_audit_purchase_date, max_audit_purchase_date,
  current_holder, ccc_500_ind, fsa_doc_ready_ind, min_loan_maturity_date, max_loan_maturity_date, wire_xfer_data, state_abbr,
  county_id, farm_id, farm_suffix, oleic_ind, seed_ind, organic_ind, seed_gen,requiredrows) {
  let optionalParamsUrl = '';

  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (organic_ind !== null && organic_ind !== undefined && organic_ind !== "") {
    optionalParamsUrl += `organic_ind=${organic_ind}&`
  }
  if (seed_ind !== null && seed_ind !== undefined && seed_ind !== "") {
    optionalParamsUrl += `seed_ind=${seed_ind}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "") {
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`
  }
  if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if (county_id !== null && county_id !== undefined && county_id !== "") {
    optionalParamsUrl += `county_id=${county_id}&`
  }
  if (state_abbr !== null && state_abbr !== undefined && state_abbr !== "") {
    optionalParamsUrl += `state_abbr=${state_abbr}&`
  }
  if (wire_xfer_data !== null && wire_xfer_data !== undefined && wire_xfer_data !== "") {
    optionalParamsUrl += `wire_xfer_data=${wire_xfer_data}&`
  }
  if (max_loan_maturity_date !== null && max_loan_maturity_date !== undefined && max_loan_maturity_date !== "") {
    optionalParamsUrl += `max_loan_maturity_date=${max_loan_maturity_date}&`
  }
  if (min_loan_maturity_date !== null && min_loan_maturity_date !== undefined && min_loan_maturity_date !== "") {
    optionalParamsUrl += `min_loan_maturity_date=${min_loan_maturity_date}&`
  }
  if (fsa_doc_ready_ind !== null && fsa_doc_ready_ind !== undefined && fsa_doc_ready_ind !== "") {
    optionalParamsUrl += `fsa_doc_ready_ind=${fsa_doc_ready_ind}&`
  }
  if (ccc_500_ind !== null && ccc_500_ind !== undefined && ccc_500_ind !== "") {
    optionalParamsUrl += `ccc_500_ind=${ccc_500_ind}&`
  }
  if (current_holder !== null && current_holder !== undefined && current_holder !== "") {
    optionalParamsUrl += `current_holder=${current_holder}&`
  }
  if (max_audit_purchase_date !== null && max_audit_purchase_date !== undefined && max_audit_purchase_date !== "") {
    optionalParamsUrl += `max_audit_purchase_date=${max_audit_purchase_date}&`
  }
  if (min_audit_purchase_date !== null && min_audit_purchase_date !== undefined && min_audit_purchase_date !== "") {
    optionalParamsUrl += `min_audit_purchase_date=${min_audit_purchase_date}&`
  }
  if (ewr_status !== null && ewr_status !== undefined && ewr_status !== "") {
    optionalParamsUrl += `ewr_status=${ewr_status}&`
  }
  if (loan_number !== null && loan_number !== undefined && loan_number !== "") {
    optionalParamsUrl += `loan_number=${loan_number}&`
  }
  if (max_lock_in_date !== null && max_lock_in_date !== undefined && max_lock_in_date !== "") {
    optionalParamsUrl += `max_lock_in_date=${max_lock_in_date}&`
  }
  if (min_lock_in_date !== null && min_lock_in_date !== undefined && min_lock_in_date !== "") {
    optionalParamsUrl += `min_lock_in_date=${min_lock_in_date}&`
  }
  if (max_end_date !== null && max_end_date !== undefined && max_end_date !== "") {
    optionalParamsUrl += `max_end_date=${max_end_date}&`
  }
  if (min_end_date !== null && min_end_date !== undefined && min_end_date !== "") {
    optionalParamsUrl += `min_end_date=${min_end_date}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (bin_num !== null && bin_num !== undefined && bin_num !== "") {
    optionalParamsUrl += `bin_num=${bin_num}&`
  }
  if (whouse_num !== null && whouse_num !== undefined && whouse_num !== "") {
    optionalParamsUrl += `whouse_num=${whouse_num}&`
  }
  if (rcpt_vendor !== null && rcpt_vendor !== undefined && rcpt_vendor !== "") {
    optionalParamsUrl += `rcpt_vendor=${rcpt_vendor}&`
  }
  if (stat_type !== null && stat_type !== undefined && stat_type !== "") {
    optionalParamsUrl += `stat_type=${stat_type}&`
  }
  if (rcpt_status !== null && rcpt_status !== undefined && rcpt_status !== "") {
    optionalParamsUrl += `rcpt_status=${rcpt_status}&`
  }
  if (org_buy_pt !== null && org_buy_pt !== undefined && org_buy_pt !== "") {
    optionalParamsUrl += `org_buy_pt=${org_buy_pt}&`
  }
  if (useridFromLS() !== null && useridFromLS() !== undefined && useridFromLS() !== "") {
    optionalParamsUrl += `userid=${useridFromLS()}&`
  }
  if (cont_num !== null && cont_num !== undefined && cont_num !== "") {
    optionalParamsUrl += `cont_num=${cont_num}&`
  }
  if (settlement_num !== null && settlement_num !== undefined && settlement_num !== "") {
    optionalParamsUrl += `settlement_num=${settlement_num}&`
  }
  if (settlement_grouping_num !== null && settlement_grouping_num !== undefined && settlement_grouping_num !== "") {
    optionalParamsUrl += `settlement_grouping_num=${settlement_grouping_num}&`
  }
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (requiredrows !== null && requiredrows !== undefined && requiredrows != "") {
    optionalParamsUrl += `requiredrows=${requiredrows}&`
  }//added addition parameter to solve App gateway Issue if huge data is there
  return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWareHouseReceiptList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/receipts?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/receipts?${optionalParamsUrl}`);

}

export function RetrieveVendorHolderTransferDetails(comp_id, crop_year) {
  let optionalParamsUrl = '';
  if (comp_id !== null && comp_id !== undefined && comp_id != "") {
    optionalParamsUrl += `comp_id=${comp_id}&`
  }
  if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
    optionalParamsUrl += `crop_year=${crop_year}&`
  }
  return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveVendorHolderTransferDetails/vendorholdertransfers?${optionalParamsUrl}` : `${warehouse_receipt_url_api}vendorholdertransfers?${optionalParamsUrl}`);
}

export function RetrieveInspectHeaderAvailable1007Details(buy_pt_id, insp_num, settlement_num, pnut_type_id, pnut_variety_id, farm_id, farm_suffix, insp_status, seed_rcpt, seed_gen, seg_type, oleic_ind, state_abbr, county_id, purch_store_ind, obl_buy_id, insp_date_time_start, insp_date_time_end, split_vendor, contract_num, rcpt_buy_pt, contract_type, settlement_grouping_num, whse_rcpt_num, cma_search) {
  let optionalParamsUrl = ''
  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() != "")
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  if (cropYearFromLS() !== null && cropYearFromLS() !== undefined && cropYearFromLS() !== "")
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "")
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  if (insp_num !== null && insp_num !== undefined && insp_num != "")
    optionalParamsUrl += `insp_num=${insp_num}&`
  if (settlement_num !== null && settlement_num !== undefined && settlement_num != "")
    optionalParamsUrl += `settlement_num=${settlement_num}&`
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id != "")
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`

  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id != "")
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  if (farm_id !== null && farm_id !== undefined && farm_id != "")
    optionalParamsUrl += `farm_id=${farm_id}&`
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix != "")
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`
  if (insp_status !== null && insp_status !== undefined && insp_status != "")
    optionalParamsUrl += `insp_status=${insp_status}&`

  if (seed_rcpt !== null && seed_rcpt !== undefined && seed_rcpt != "")
    optionalParamsUrl += `seed_rcpt=${seed_rcpt}&`
  if (seed_gen !== null && seed_gen !== undefined && seed_gen != "")
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  if (seg_type !== null && seg_type !== undefined && seg_type != "")
    optionalParamsUrl += `seg_type=${seg_type}&`
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind != "")
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`

  if (state_abbr !== null && state_abbr !== undefined && state_abbr != "")
    optionalParamsUrl += `state_abbr=${state_abbr}&`
  if (county_id !== null && county_id !== undefined && county_id != "")
    optionalParamsUrl += `county_id=${county_id}&`
  if (purch_store_ind !== null && purch_store_ind !== undefined && purch_store_ind != "")
    optionalParamsUrl += `purch_store_ind=${purch_store_ind}&`
  if (obl_buy_id !== null && obl_buy_id !== undefined && obl_buy_id != "")
    optionalParamsUrl += `obl_buy_id=${obl_buy_id}&`

  if (insp_date_time_start !== null && insp_date_time_start !== undefined && insp_date_time_start != "")
    optionalParamsUrl += `insp_date_time_start=${insp_date_time_start}&`
  if (insp_date_time_end !== null && insp_date_time_end !== undefined && insp_date_time_end != "")
    optionalParamsUrl += `insp_date_time_end=${insp_date_time_end}&`
  if (split_vendor !== null && split_vendor !== undefined && split_vendor != "")
    optionalParamsUrl += `split_vendor=${split_vendor}&`
  if (contract_num !== null && contract_num !== undefined && contract_num != "")
    optionalParamsUrl += `contract_num=${contract_num}&`

  if (rcpt_buy_pt !== null && rcpt_buy_pt !== undefined && rcpt_buy_pt != "")
    optionalParamsUrl += `rcpt_buy_pt=${rcpt_buy_pt}&`
  if (contract_type !== null && contract_type !== undefined && contract_type != "")
    optionalParamsUrl += `contract_type=${contract_type}&`
  if (settlement_grouping_num !== null && settlement_grouping_num !== undefined && settlement_grouping_num != "")
    optionalParamsUrl += `settlement_grouping_num=${settlement_grouping_num}&`
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num != "")
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
  if (cma_search !== null && cma_search !== undefined && cma_search != "")
    optionalParamsUrl += `cma_search=${cma_search}`

  return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveInspectHeaderAvailable1007Details/inspectheaderavailables?${optionalParamsUrl}` : `${warehouse_receipt_url_api}inspectheaderavailables?${optionalParamsUrl}`)
}

export function RetrieveEWarehouseLogDetails(sent_received = 'S', buy_pt_id, receipt_number) {
  let optionalParamsUrl = '';

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  }

  if (receipt_number !== null && receipt_number !== undefined && receipt_number !== "") {
    optionalParamsUrl += `receipt_number=${receipt_number}`;
  }

  return ApiService.getData(isLocal()
    ? `/WarehouseReceipts/RetrieveEWarehouseLogDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/sent-receives/${sent_received}/warehouselogs?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/sent-receives/${sent_received}/warehouselogs?${optionalParamsUrl}`);
}

export function CreateEwrInquirySendDetails(createEwrInquirySendObj) {
  const createEwrInquirySendData = createEwrInquirySendObj;
  createEwrInquirySendData.comp_id = compIdFromLS();
  createEwrInquirySendData.crop_year = cropYearFromLS();
  createEwrInquirySendData.user_id = useridFromLS();
  return ApiService.postData(isLocal()
    ? `/WarehouseReceipts/CreateEwrInquirySendDetails/ewrinquirysends`
    : `${warehouse_receipt_url_api}ewrinquirysends`,
    createEwrInquirySendData);
}

export function RetrieveWarehousePremiumDeductionList(comp_id, crop_year, buy_pt_id, whse_rcpt_num) {
  let optionalParamsUrl = '';
  if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
    optionalParamsUrl += `comp_id=${comp_id}&`
  }
  if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
    optionalParamsUrl += `crop_year=${crop_year}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
  }
  return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWarehousePremiumDeductionList/warehousepremiumdeductions?${optionalParamsUrl}` : `${warehouse_receipt_url_api}warehousepremiumdeductions?${optionalParamsUrl}`)
}

export function RetrieveDeliveryOutAgreementWareHouseDetails(buy_pt_id, whse_rcpt_num, agreement_num) {
  let optionalParamsUrl = '';
  let comp_id = compIdFromLS()
  let crop_years = cropYearFromLS()

  if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
    optionalParamsUrl += `comp_id=${comp_id}&`;
  }
  if (crop_years !== null && crop_years !== undefined && crop_years !== "") {
    optionalParamsUrl += `crop_year=${crop_years}&`;
  }

  if (agreement_num !== null && agreement_num !== undefined && agreement_num !== "") {
    optionalParamsUrl += `agreement_num=${agreement_num}&`;
  }

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  }

  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}`;
  }

  return ApiService.getData(isLocal()
    ? `/WarehouseReceipts/RetrieveDeliveryOutAgreementWareHouseDetails/deliveryoutagreements?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}/deliveryoutagreements?${optionalParamsUrl}`);
}

export function CreateEwrSend(dataObj) {
  return ApiService.postData(isLocal() ? `/WarehouseReceipts/CreateEwrSend/ewrs` : `${warehouse_receipt_url_api}ewrs`, dataObj);
}

export function RetrieveWareHouseTentPurchContractApplicationDetails(buy_pt_id, whse_rcpt_num) {
  let optionalParamsUrl = ''

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "")
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num != "")
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}`

  return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWareHouseTentPurchContractApplicationDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contractapplications?${optionalParamsUrl}` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/contractapplications?${optionalParamsUrl}`)
}
export function RetrieveStoragePaidList(buy_pt_id, whse_rcpt_num, posting_date) {
  let optionalParamsUrl = '';
  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() != "")
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`

  if (cropYearFromLS() !== null && cropYearFromLS() !== undefined && cropYearFromLS() !== "")
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`

  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }

  if (whse_rcpt_num !== null && whse_rcpt_num !== '' && whse_rcpt_num !== undefined) {
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
  }

  if (posting_date !== null && posting_date !== '' && posting_date !== undefined) {
    optionalParamsUrl += `posting_date=${posting_date}&`
  }
  return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveStoragePaidList/storagepaids?${optionalParamsUrl}` : `${warehouse_receipt_url_api}storagepaids?${optionalParamsUrl}`)
}

export function UpdateStoragePaid(whse_rcpt_num, buy_pt_id, dataObj) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateStoragePaid/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${whse_rcpt_num}/buying-points/${buy_pt_id}/storagepaids` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${whse_rcpt_num}/buying-points/${buy_pt_id}/storagepaids`, dataObj)
}

export function RemoveStoragePaid(whse_rcpt_num, buy_pt_id, posting_date) {
  return ApiService.removeData(isLocal() ? `/WarehouseReceipts/RemoveStoragePaid/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${whse_rcpt_num}/buying-points/${buy_pt_id}/posting-dates/${posting_date}/storagepaids` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${whse_rcpt_num}/buying-points/${buy_pt_id}/posting-dates/${posting_date}/storagepaids`);
}

export function RetrieveWareHouseReciptHeaderDetails(buy_pt_id, whse_rcpt_num, rcpt_vendor, whouse_num, bin_num, rcpt_status, pnut_type_id, seg_type, get_seed_lbs) {
  let optionalParamsUrl = ''
  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() !== "")
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  if (cropYearFromLS() !== null && cropYearFromLS() !== undefined && cropYearFromLS() !== "")
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "")
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "")
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`;
  if (rcpt_vendor !== null && rcpt_vendor !== undefined && rcpt_vendor !== "")
    optionalParamsUrl += `rcpt_vendor=${rcpt_vendor}&`;
  if (whouse_num !== null && whouse_num !== undefined && whouse_num !== "")
    optionalParamsUrl += `whouse_num=${whouse_num}&`;
  if (bin_num !== null && bin_num !== undefined && bin_num !== "")
    optionalParamsUrl += `bin_num=${bin_num}&`;
  if (rcpt_status !== null && rcpt_status !== undefined && rcpt_status !== "")
    optionalParamsUrl += `rcpt_status=${rcpt_status}&`;
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "")
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
  if (seg_type !== null && seg_type !== undefined && seg_type !== "")
    optionalParamsUrl += `seg_type=${seg_type}&`;
  if (get_seed_lbs !== null && get_seed_lbs !== undefined && get_seed_lbs !== "")
    optionalParamsUrl += `get_seed_lbs=${get_seed_lbs}`;

  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveWareHouseReciptHeaderDetails/reciptheaders?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}reciptheaders?${optionalParamsUrl}`
  )
}

export function RetrieveInspectHeaderListDetails(buy_pt_id, insp_num, settlement_num, pnut_type_id, pnut_variety_id, farm_id, farm_suffix, insp_status, seed_gen, seg_type, oleic_ind, state_abbr, county_id, purch_store_ind, obl_buy_id, insp_date_time_start, insp_date_time_end, split_vendor, rcpt_buy_pt, applied, whse_rcpt_num) {
  let optionalParamsUrl = ""
  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() !== "")
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  if (cropYearFromLS() !== null && cropYearFromLS() !== undefined && cropYearFromLS() !== "")
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "")
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  if (insp_num !== null && insp_num !== undefined && insp_num !== "")
    optionalParamsUrl += `insp_num=${insp_num}&`;
  if (settlement_num !== null && settlement_num !== undefined && settlement_num !== "")
    optionalParamsUrl += `settlement_num=${settlement_num}&`;
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "")
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "")
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`;
  if (farm_id !== null && farm_id !== undefined && farm_id !== "")
    optionalParamsUrl += `farm_id=${farm_id}&`;
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "")
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`;
  if (insp_status !== null && insp_status !== undefined && insp_status !== "")
    optionalParamsUrl += `insp_status=${insp_status}&`;
  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "")
    optionalParamsUrl += `seed_gen=${seed_gen}&`;
  if (seg_type !== null && seg_type !== undefined && seg_type !== "")
    optionalParamsUrl += `seg_type=${seg_type}&`;
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "")
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`;
  if (state_abbr !== null && state_abbr !== undefined && state_abbr !== "")
    optionalParamsUrl += `state_abbr=${state_abbr}&`;
  if (county_id !== null && county_id !== undefined && county_id !== "")
    optionalParamsUrl += `county_id=${county_id}&`;
  if (purch_store_ind !== null && purch_store_ind !== undefined && purch_store_ind !== "")
    optionalParamsUrl += `purch_store_ind=${purch_store_ind}&`;
  if (obl_buy_id !== null && obl_buy_id !== undefined && obl_buy_id !== "")
    optionalParamsUrl += `obl_buy_id=${obl_buy_id}&`;
  if (insp_date_time_start !== null && insp_date_time_start !== undefined && insp_date_time_start !== "")
    optionalParamsUrl += `insp_date_time_start=${insp_date_time_start}&`;
  if (insp_date_time_end !== null && insp_date_time_end !== undefined && insp_date_time_end !== "")
    optionalParamsUrl += `insp_date_time_end=${insp_date_time_end}&`;
  if (split_vendor !== null && split_vendor !== undefined && split_vendor !== "")
    optionalParamsUrl += `split_vendor=${split_vendor}&`;
  if (rcpt_buy_pt !== null && rcpt_buy_pt !== undefined && rcpt_buy_pt !== "")
    optionalParamsUrl += `rcpt_buy_pt=${rcpt_buy_pt}&`;
  if (applied !== null && applied !== undefined && applied !== "")
    optionalParamsUrl += `applied=${applied}&`;
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "")
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}`;

  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveInspectHeaderListDetails/inspectheaders?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}inspectheaders?${optionalParamsUrl}`
  )
}

export function RetrievePricingDataWithFailoverTestDetails(area_id, pnut_type_id, cr_br_pct, dcol_shel_pct, fan_pct) {
  let optionalParamsUrl = ''
  if (cr_br_pct !== null && cr_br_pct !== undefined && cr_br_pct !== "")
    optionalParamsUrl += `cr_br_pct=${cr_br_pct}&`;
  if (dcol_shel_pct !== null && dcol_shel_pct !== undefined && dcol_shel_pct !== "")
    optionalParamsUrl += `dcol_shel_pct=${dcol_shel_pct}&`;
  if (fan_pct !== null && fan_pct !== undefined && fan_pct !== "")
    optionalParamsUrl += `fan_pct=${fan_pct}`;

  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrievePricingDataWithFailoverTestDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/failovers?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/failovers?${optionalParamsUrl}`
  )
}

export function RetrieveExternalReceiptList(func_id, func_sub_id, ccc_whse_code,
  receipt_num, delv_agree_num, receipt_num1, record_status, peanut_type_id, buy_pt_number) {
  let optionalParamsUrl = '';

  optionalParamsUrl += `user_id=${useridFromLS()}&`;

  if (func_id !== null && func_id !== undefined && func_id !== "") {
    optionalParamsUrl += `func_id=${func_id}&`;
  }

  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id !== "") {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
  }

  if (ccc_whse_code !== null && ccc_whse_code !== undefined && ccc_whse_code !== "") {
    optionalParamsUrl += `ccc_whse_code=${ccc_whse_code}&`;
  }

  if (receipt_num !== null && receipt_num !== undefined && receipt_num !== "") {
    optionalParamsUrl += `receipt_num=${receipt_num}&`;
  }

  if (delv_agree_num !== null && delv_agree_num !== undefined && delv_agree_num !== "") {
    optionalParamsUrl += `delv_agree_num=${delv_agree_num}&`;
  }

  if (receipt_num1 !== null && receipt_num1 !== undefined && receipt_num1 !== "") {
    optionalParamsUrl += `receipt_num1=${receipt_num1}&`;
  }

  if (record_status !== null && record_status !== undefined && record_status !== "") {
    optionalParamsUrl += `record_status=${record_status}&`;
  }

  if (peanut_type_id !== null && peanut_type_id !== undefined && peanut_type_id !== "") {
    optionalParamsUrl += `peanut_type_id=${peanut_type_id}&`;
  }

  if (buy_pt_number !== null && buy_pt_number !== undefined && buy_pt_number !== "") {
    optionalParamsUrl += `buy_pt_number=${buy_pt_number}&`;
  }

  return ApiService.getData(isLocal()
    ? `/WarehouseReceipts/RetrieveExternalReceiptList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/externalreceipts?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/externalreceipts?${optionalParamsUrl}`);
}

export function RetrieveExternalReceiptDetails(func_id, func_sub_id, ccc_whse_code, receipt_num) {
  let optionalParamsUrl = '';

  optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;

  if (func_id !== null && func_id !== undefined && func_id !== "") {
    optionalParamsUrl += `func_id=${func_id}&`;
  }

  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id !== "") {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`;
  }

  if (ccc_whse_code !== null && ccc_whse_code !== undefined && ccc_whse_code !== "") {
    optionalParamsUrl += `ccc_whse_code=${ccc_whse_code}&`;
  }

  if (receipt_num !== null && receipt_num !== undefined && receipt_num !== "") {
    optionalParamsUrl += `receipt_num=${receipt_num}&`;
  }

  return ApiService.getData(isLocal()
    ? `/WarehouseReceipts/RetrieveExternalReceiptDetails/externalreceipts?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}externalreceipts?${optionalParamsUrl}`);
}

export function RetrievePremiumDeductionScheduleList(wr_recpt_num, obl_buy_pt_id, unld_buy_pt_id, inspect_ind, insp_num, segvalue_p, gmasegvalue_p, contlist_p, appllbs_p, vendlist_p, netwt_p, lskwt_p, grosswt_p, segvalue_s, contlist_s, appllbs_s, vendlist_s, netwt_s, lskwt_s, grosswt_s, basis_grade_amt_p, basis_grade_amt_s, dma_vendor, contractlbs1007, contlist_seed, appllbs_seed) {
  let optionalParamsUrl = ''
  if (insp_num !== null && insp_num !== undefined && insp_num !== "")
    optionalParamsUrl += `insp_num=${insp_num}&`;
  if (inspect_ind !== null && inspect_ind !== undefined && inspect_ind !== "")
    optionalParamsUrl += `inspect_ind=${inspect_ind}&`;
  if (segvalue_p !== null && segvalue_p !== undefined && segvalue_p !== "")
    optionalParamsUrl += `segvalue_p=${segvalue_p}&`;
  if (gmasegvalue_p !== null && gmasegvalue_p !== undefined && gmasegvalue_p !== "")
    optionalParamsUrl += `gmasegvalue_p=${gmasegvalue_p}&`;
  if (contlist_p !== null && contlist_p !== undefined && contlist_p !== "")
    optionalParamsUrl += `contlist_p=${contlist_p}&`;
  if (appllbs_p !== null && appllbs_p !== undefined && appllbs_p !== "")
    optionalParamsUrl += `appllbs_p=${appllbs_p}&`;
  if (vendlist_p !== null && vendlist_p !== undefined && vendlist_p !== "")
    optionalParamsUrl += `vendlist_p=${vendlist_p}&`;
  if (netwt_p !== null && netwt_p !== undefined && netwt_p !== "")
    optionalParamsUrl += `netwt_p=${netwt_p}&`;
  if (lskwt_p !== null && lskwt_p !== undefined && lskwt_p !== "")
    optionalParamsUrl += `lskwt_p=${lskwt_p}&`;
  if (grosswt_p !== null && grosswt_p !== undefined && grosswt_p !== "")
    optionalParamsUrl += `grosswt_p=${grosswt_p}&`;
  if (segvalue_s !== null && segvalue_s !== undefined && segvalue_s !== "")
    optionalParamsUrl += `segvalue_s=${segvalue_s}&`;
  if (contlist_s !== null && contlist_s !== undefined && contlist_s !== "")
    optionalParamsUrl += `contlist_s=${contlist_s}&`;
  if (appllbs_s !== null && appllbs_s !== undefined && appllbs_s !== "")
    optionalParamsUrl += `appllbs_s=${appllbs_s}&`;
  if (vendlist_s !== null && vendlist_s !== undefined && vendlist_s !== "")
    optionalParamsUrl += `vendlist_s=${vendlist_s}&`;
  if (netwt_s !== null && netwt_s !== undefined && netwt_s !== "")
    optionalParamsUrl += `netwt_s=${netwt_s}&`;
  if (lskwt_s !== null && lskwt_s !== undefined && lskwt_s !== "")
    optionalParamsUrl += `lskwt_s=${lskwt_s}&`;
  if (grosswt_s !== null && grosswt_s !== undefined && grosswt_s !== "")
    optionalParamsUrl += `grosswt_s=${grosswt_s}&`;
  if (basis_grade_amt_p !== null && basis_grade_amt_p !== undefined && basis_grade_amt_p !== "")
    optionalParamsUrl += `basis_grade_amt_p=${basis_grade_amt_p}&`;
  if (basis_grade_amt_s !== null && basis_grade_amt_s !== undefined && basis_grade_amt_s !== "")
    optionalParamsUrl += `basis_grade_amt_s=${basis_grade_amt_s}&`;
  if (dma_vendor !== null && dma_vendor !== undefined && dma_vendor !== "")
    optionalParamsUrl += `dma_vendor=${dma_vendor}&`;
  if (contractlbs1007 !== null && contractlbs1007 !== undefined && contractlbs1007 !== "")
    optionalParamsUrl += `contractlbs1007=${contractlbs1007}&`;
  if (contlist_seed !== null && contlist_seed !== undefined && contlist_seed !== "")
    optionalParamsUrl += `contlist_seed=${contlist_seed}&`;
  if (appllbs_seed !== null && appllbs_seed !== undefined && appllbs_seed !== "")
    optionalParamsUrl += `appllbs_seed=${appllbs_seed}&`;
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrievePremiumDeductionScheduleList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${wr_recpt_num}/obl-buying-points/${obl_buy_pt_id}/unld-buying-points/${unld_buy_pt_id}/premiumdeductions?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${wr_recpt_num}/obl-buying-points/${obl_buy_pt_id}/unld-buying-points/${unld_buy_pt_id}/premiumdeductions?${optionalParamsUrl}`
  )
}

export function RetrievePremiumDeductionScheduleDetails(wr_recpt_num, obl_buy_pt_id, unld_buy_pt_id, insp_num, inspect_ind, segvalue_p, gmasegvalue_p, contlist_p,
  appllbs_p, vendlist_p, netwt_p, lskwt_p, grosswt_p, segvalue_s, contlist_s, appllbs_s, vendlist_s, netwt_s, lskwt_s, grosswt_s, basis_grade_amt_p,
  basis_grade_amt_s, dma_vendor, contractlbs1007, contlist_seed, appllbs_seed) {
  let optionalParamsUrl = ''
  if (insp_num !== null && insp_num !== undefined && insp_num !== "")
    optionalParamsUrl += `insp_num=${insp_num}&`;
  if (inspect_ind !== null && inspect_ind !== undefined && inspect_ind !== "")
    optionalParamsUrl += `inspect_ind=${inspect_ind}&`;
  if (segvalue_p !== null && segvalue_p !== undefined && segvalue_p !== "")
    optionalParamsUrl += `segvalue_p=${segvalue_p}&`;
  if (gmasegvalue_p !== null && gmasegvalue_p !== undefined && gmasegvalue_p !== "")
    optionalParamsUrl += `gmasegvalue_p=${gmasegvalue_p}&`;
  if (contlist_p !== null && contlist_p !== undefined && contlist_p !== "")
    optionalParamsUrl += `contlist_p=${contlist_p}&`;
  if (appllbs_p !== null && appllbs_p !== undefined && appllbs_p !== "")
    optionalParamsUrl += `appllbs_p=${appllbs_p}&`;
  if (vendlist_p !== null && vendlist_p !== undefined && vendlist_p !== "")
    optionalParamsUrl += `vendlist_p=${vendlist_p}&`;
  if (netwt_p !== null && netwt_p !== undefined && netwt_p !== "")
    optionalParamsUrl += `netwt_p=${netwt_p}&`;
  if (lskwt_p !== null && lskwt_p !== undefined && lskwt_p !== "")
    optionalParamsUrl += `lskwt_p=${lskwt_p}&`;
  if (grosswt_p !== null && grosswt_p !== undefined && grosswt_p !== "")
    optionalParamsUrl += `grosswt_p=${grosswt_p}&`;
  if (segvalue_s !== null && segvalue_s !== undefined && segvalue_s !== "")
    optionalParamsUrl += `segvalue_s=${segvalue_s}&`;
  if (contlist_s !== null && contlist_s !== undefined && contlist_s !== "")
    optionalParamsUrl += `contlist_s=${contlist_s}&`;
  if (appllbs_s !== null && appllbs_s !== undefined && appllbs_s !== "")
    optionalParamsUrl += `appllbs_s=${appllbs_s}&`;
  if (vendlist_s !== null && vendlist_s !== undefined && vendlist_s !== "")
    optionalParamsUrl += `vendlist_s=${vendlist_s}&`;
  if (netwt_s !== null && netwt_s !== undefined && netwt_s !== "")
    optionalParamsUrl += `netwt_s=${netwt_s}&`;
  if (lskwt_s !== null && lskwt_s !== undefined && lskwt_s !== "")
    optionalParamsUrl += `lskwt_s=${lskwt_s}&`;
  if (grosswt_s !== null && grosswt_s !== undefined && grosswt_s !== "")
    optionalParamsUrl += `grosswt_s=${grosswt_s}&`;
  if (basis_grade_amt_p !== null && basis_grade_amt_p !== undefined && basis_grade_amt_p !== "")
    optionalParamsUrl += `basis_grade_amt_p=${basis_grade_amt_p}&`;
  if (basis_grade_amt_s !== null && basis_grade_amt_s !== undefined && basis_grade_amt_s !== "")
    optionalParamsUrl += `basis_grade_amt_s=${basis_grade_amt_s}&`;
  if (dma_vendor !== null && dma_vendor !== undefined && dma_vendor !== "")
    optionalParamsUrl += `dma_vendor=${dma_vendor}&`;
  if (contractlbs1007 !== null && contractlbs1007 !== undefined && contractlbs1007 !== "")
    optionalParamsUrl += `contractlbs1007=${contractlbs1007}&`;
  if (contlist_seed !== null && contlist_seed !== undefined && contlist_seed !== "")
    optionalParamsUrl += `contlist_seed=${contlist_seed}&`;
  if (appllbs_seed !== null && appllbs_seed !== undefined && appllbs_seed !== "")
    optionalParamsUrl += `appllbs_seed=${appllbs_seed}&`;
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrievePremiumDeductionScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${wr_recpt_num}/obl-buying-points/${obl_buy_pt_id}/unld-buying-points/${unld_buy_pt_id}/deductionschedules?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipt-numbers/${wr_recpt_num}/obl-buying-points/${obl_buy_pt_id}/unld-buying-points/${unld_buy_pt_id}/deductionschedules?${optionalParamsUrl}`
  )
}

export function RetrieveWareHouseCheckStateCounty(state_id, county_id, comp_id, crop_year, flg_cma_dma) {
  let optionalParamsUrl = ''
  if (comp_id !== null && comp_id !== undefined && comp_id !== "")
    optionalParamsUrl += `comp_id=${comp_id}&`;
  if (crop_year !== null && crop_year !== undefined && crop_year !== "")
    optionalParamsUrl += `crop_year=${crop_year}&`;
  if (flg_cma_dma !== null && flg_cma_dma !== undefined && flg_cma_dma !== "")
    optionalParamsUrl += `flg_cma_dma=${flg_cma_dma}&`;
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveWareHouseCheckStateCounty/states/${state_id}/counties/${county_id}/warehousechecks?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}states/${state_id}/counties/${county_id}/warehousechecks?${optionalParamsUrl}`
  )
}

export function RetrieveEWareHouseInquirySendDetails(file_num, whse_code, receipt_num, license_num, fsa_farm_num, pnut_type, buying_pt_num, loan_num, receipt_status, ccc_loan_status) {
  let comp_id = compIdFromLS()
  let crop_years = cropYearFromLS()
  let optionalParamsUrl = '';
  if (file_num !== null && file_num !== undefined && file_num !== "") {
    optionalParamsUrl += `file_num=${file_num}&`;
  }
  if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
    optionalParamsUrl += `comp_id=${comp_id}&`;
  }
  if (crop_years !== null && crop_years !== undefined && crop_years !== "") {
    optionalParamsUrl += `crop_year=${crop_years}&`;
  }
  if (whse_code !== null && whse_code !== undefined && whse_code !== "") {
    optionalParamsUrl += `whse_code=${whse_code}&`;
  }
  if (receipt_num !== null && receipt_num !== undefined && receipt_num !== "") {
    optionalParamsUrl += `receipt_num=${receipt_num}&`;
  }
  if (license_num !== null && license_num !== undefined && license_num !== "") {
    optionalParamsUrl += `license_num=${license_num}&`;
  }
  if (fsa_farm_num !== null && fsa_farm_num !== undefined && fsa_farm_num !== "") {
    optionalParamsUrl += `fsa_farm_num=${fsa_farm_num}`;
  }
  if (pnut_type !== null && pnut_type !== undefined && pnut_type !== "") {
    optionalParamsUrl += `pnut_type=${pnut_type}&`;
  }
  if (buying_pt_num !== null && buying_pt_num !== undefined && buying_pt_num !== "") {
    optionalParamsUrl += `buying_pt_num=${buying_pt_num}&`;
  }
  if (loan_num !== null && loan_num !== undefined && loan_num !== "") {
    optionalParamsUrl += `loan_num=${loan_num}&`;
  }
  if (receipt_status !== null && receipt_status !== undefined && receipt_status !== "") {
    optionalParamsUrl += `receipt_status=${receipt_status}`;
  }
  if (ccc_loan_status !== null && ccc_loan_status !== undefined && ccc_loan_status !== "") {
    optionalParamsUrl += `ccc_loan_status=${ccc_loan_status}`;
  }
  return ApiService.getData(isLocal()
    ? `/WarehouseReceipts/RetrieveEWareHouseInquirySendDetails/sendinquiries?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}/sendinquiries?${optionalParamsUrl}`);
}


export function RetrieveEwrSendDetails(file_num,action_code,from_holder_id,receipt_num,to_holder_id,to_whse_code){
  let comp_id= compIdFromLS()
  let crop_years=cropYearFromLS()
  let optionalParamsUrl = ''
  if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
    optionalParamsUrl += `comp_id=${comp_id}&`;
  }
  if (crop_years !== null && crop_years !== undefined && crop_years !== "") {
    optionalParamsUrl += `crop_year=${crop_years}&`;
  }
  if (file_num !== null && file_num !== undefined && file_num !== "")
    optionalParamsUrl += `file_num=${file_num}&`;
  if (action_code !== null && action_code !== undefined && action_code !== "")
    optionalParamsUrl += `action_code=${action_code}&`;
  if (from_holder_id !== null && from_holder_id !== undefined && from_holder_id !== "")
    optionalParamsUrl += `from_holder_id=${from_holder_id}&`;
  if (receipt_num !== null && receipt_num !== undefined && receipt_num !== "")
    optionalParamsUrl += `receipt_num=${receipt_num}&`;
  if (to_holder_id !== null && to_holder_id !== undefined && to_holder_id !== "")
    optionalParamsUrl += `to_holder_id=${to_holder_id}&`;
  if (to_whse_code !== null && to_whse_code !== undefined && to_whse_code !== "")
    optionalParamsUrl += `to_whse_code=${to_whse_code}&`;
    
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveEwrSendDetails/ewrsends?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}ewrsends?${optionalParamsUrl}`
  )
}

export function UpdateExternalReceiptCopy(data) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateExternalReceiptCopy/receiptnumbercopies`
      : `${warehouse_receipt_url_api}receiptnumbercopies` , data)
  }

export function RetrieveReadyToPayWareHouseDetails(audit_whse_bp, audit_whse_rcpt){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveRadyToPayWareHouseDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/audit-warehouse-buying-ponits/${audit_whse_bp}/audit-warehouse-receipt-numbers/${audit_whse_rcpt}/readytopays`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/audit-warehouse-buying-ponits/${audit_whse_bp}/audit-warehouse-receipt-numbers/${audit_whse_rcpt}/readytopays`
  )
}

export function RetrievePaymentActivityDetails(audit_whse_bp, audit_whse_rcpt){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrievePayamentActivityDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/audit-warehouse-buying-ponits/${audit_whse_bp}/audit-warehouse-receipt-numbers/${audit_whse_rcpt}/payamentactivities`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/audit-warehouse-buying-ponits/${audit_whse_bp}/audit-warehouse-receipt-numbers/${audit_whse_rcpt}/payamentactivities`
  )
}
  export function RetrieveObligationPointAvailableInventoryDetails(func_id,buy_pt_id,func_sub_id,pnut_type_id,pnut_variety_id,seed_rcpt,seed_gen,seg_type,oleic_ind) {
    let optionalParamsUrl = '';

    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id !== "") {
      optionalParamsUrl += `func_sub_id=${func_sub_id}&`
    }
    if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
      optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
    }
    if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
      optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
    }
    if (seed_rcpt !== null && seed_rcpt !== undefined && seed_rcpt !== "") {
      optionalParamsUrl += `seed_rcpt=${seed_rcpt}&`
    }
    if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
      optionalParamsUrl += `seed_gen=${seed_gen}&`
    }
    if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
      optionalParamsUrl += `seg_type=${seg_type}&`
    }
    if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
      optionalParamsUrl += `oleic_ind=${oleic_ind}&`
    }

    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveObligationPointAvailableInventoryDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/availableinventories?${optionalParamsUrl}` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/availableinventories?${optionalParamsUrl}`
    )
  }


  export function RetrieveObligationPointDetails(func_id,buy_pt_id) {
    let optionalParamsUrl = '';
    
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveObligationPointDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/obligationpoints?${optionalParamsUrl}` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/obligationpoints?${optionalParamsUrl}`
    )
  }

  export function RetrieveDeliveryOutreceipts(agreement_num, pnut_type_id) {
    let crop_year = cropYearFromLS();
    let optionalParamsUrl = ``
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
  
    if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      optionalParamsUrl += `pnut_type_id=${pnut_type_id}`;
    }
  
    return ApiService.getData( 
      isLocal() 
      ? `/WarehouseReceipts/RetrieveDeliveryOutreceipts/agreement-numbers/${agreement_num}/companies/${compIdFromLS()}/deliveryoutreceipts?${optionalParamsUrl}
      `:`${warehouse_receipt_url_api}agreement-numbers/${agreement_num}/companies/${compIdFromLS()}/deliveryoutreceipts?${optionalParamsUrl}`)
  }

  export function RetrieveWhouseLicenseControlCityStateDetails(comp_id,crop_year,fed_whouse_license){
    let optionalParamsUrl = '';
    
    if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
      optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
      optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (fed_whouse_license !== null && fed_whouse_license !== undefined && fed_whouse_license !== "") {
      optionalParamsUrl += `fed_whouse_license=${fed_whouse_license}&`
    }
    
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWhouseLicenseControlCityStateDetails/Controlcitystates?${optionalParamsUrl}`:
    `${warehouse_receipt_url_api}Controlcitystates?${optionalParamsUrl}`
    )
  }

  export function RetrieveInspectHeaderDetails(comp_id,crop_year,rcpt_buy_pt,whse_rcpt_num){
    let optionalParamsUrl = '';
    
    if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
      optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
      optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (rcpt_buy_pt !== null && rcpt_buy_pt !== undefined && rcpt_buy_pt !== "") {
      optionalParamsUrl += `rcpt_buy_pt=${rcpt_buy_pt}&`
    }
    if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
    }
    
    return ApiService.getData(isLocal() ? `/WarehouseReceipts​/RetrieveInspectHeaderDetails​/headers?${optionalParamsUrl}`:
    `${warehouse_receipt_url_api}headers?${optionalParamsUrl}`
    )
  }

  export function RetrieveWhouseReceipt1007Details(comp_id,crop_year,rcpt_buy_pt,whse_rcpt_num){
    let optionalParamsUrl = '';
    
    if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
      optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
      optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (rcpt_buy_pt !== null && rcpt_buy_pt !== undefined && rcpt_buy_pt !== "") {
      optionalParamsUrl += `rcpt_buy_pt=${rcpt_buy_pt}&`
    }
    if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
    }
    
    return ApiService.getData(isLocal() ? `/WarehouseReceipts​/RetrieveWhouseReceipt1007Details​/1007whousereceipts?${optionalParamsUrl}`:
    `${warehouse_receipt_url_api}headers?${optionalParamsUrl}`
    )
  }

  export function RetrieveWareHouseReceiptWorkSheetReportDetails(comp_id,crop_year,buy_pt_id,whse_rcpt_num){
    let optionalParamsUrl = '';
    
    if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
      optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
      optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
    }
    
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWareHouseReceiptWorkSheetReportDetails/worksheetreports?${optionalParamsUrl}`:
    `${warehouse_receipt_url_api}worksheetreports?${optionalParamsUrl}`
    )
  }

  export function RetrieveWarehouseContractApplicationDetails(comp_id,crop_year,buy_pt_id,whse_rcpt_num){
    let optionalParamsUrl = '';
    
    if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
      optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
      optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
    }
    
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWarehouseContractApplicationDetails/contractapplications?${optionalParamsUrl}`:
    `${warehouse_receipt_url_api}contractapplications?${optionalParamsUrl}`
    )
  }

  export function RetrieveWareHouseWorkSheetPremiumDeductionBalanceDetails(buy_pt_id,whse_rcpt_num){
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWareHouseWorkSheetPremiumDeductionBalanceDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/WorkSheetPremiumDeductions`:
    `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/WorkSheetPremiumDeductions`
    )
  }

  export function RetrieveWareHouseReciptFsaDetails(buy_pt_id,whse_rcpt_num){
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWareHouseReciptFsaDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/warehouserecipts`:
    `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/warehouserecipts`
    )
  }

  export function UpdateFsaDocReady(buy_pt_id,whse_rcpt_num,data) {
    return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateFsaDocReady/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehose-recipt-numbers/${whse_rcpt_num}/fsadocreadydetails` : 
    `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehose-recipt-numbers/${whse_rcpt_num}/fsadocreadydetails`, data)
  }

  export function Retrieve1007GroupList(comp_id,crop_year,buy_pt_id,insp_num,settlement_num,pnut_type_id,pnut_variety_id,farm_suffix,farm_id,insp_status,seed_rcpt,seed_gen,seg_type,oleic_ind,state_abbr,county_id,purch_store_ind,obl_buy_id,insp_date_time_start,insp_date_time_end,split_vendor,contract_num,rcpt_buy_pt,contract_type,settlement_grouping_num,whse_rcpt_num,settle_grp_cma_dma_ind,settle_grp_deferred_ind){
    let optionalParamsUrl = ``
    if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
      optionalParamsUrl += `comp_id=${comp_id}&`;
    }
    if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
      optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
    }
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (insp_num !== null && insp_num !== undefined && insp_num != ""){
    optionalParamsUrl += `insp_num=${insp_num}&`
    }
    if (settlement_num !== null && settlement_num !== undefined && settlement_num !== "") {
      optionalParamsUrl += `settlement_num=${settlement_num}&`
    }
    if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
          optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
    }
    if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id != ""){
          optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
    }  
    if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "") {
            optionalParamsUrl += `farm_suffix=${farm_suffix}&`
     }
    if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
            optionalParamsUrl += `farm_id=${farm_id}&`
    }
   if (insp_status !== null && insp_status !== undefined && insp_status != ""){
          optionalParamsUrl += `insp_status=${insp_status}&`
   }
   if (seed_rcpt !== null && seed_rcpt !== undefined && seed_rcpt != ""){
   optionalParamsUrl += `seed_rcpt=${seed_rcpt}&`
   }
   if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
   if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
   if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
   if (state_abbr !== null && state_abbr !== undefined && state_abbr !== "") {
    optionalParamsUrl += `state_abbr=${state_abbr}&`
  }
  if (county_id !== null && county_id !== undefined && county_id !== "") {
    optionalParamsUrl += `county_id=${county_id}&`
  }
  if (purch_store_ind !== null && purch_store_ind !== undefined && purch_store_ind != ""){
    optionalParamsUrl += `purch_store_ind=${purch_store_ind}&`
  }
  if (obl_buy_id !== null && obl_buy_id !== undefined && obl_buy_id != ""){
    optionalParamsUrl += `obl_buy_id=${obl_buy_id}&`
  }
  if (insp_date_time_start !== null && insp_date_time_start !== undefined && insp_date_time_start != ""){
    optionalParamsUrl += `insp_date_time_start=${insp_date_time_start}&`
  }
  if (insp_date_time_end !== null && insp_date_time_end !== undefined && insp_date_time_end != ""){
    optionalParamsUrl += `insp_date_time_end=${insp_date_time_end}&`
  }
  if (split_vendor !== null && split_vendor !== undefined && split_vendor != ""){
    optionalParamsUrl += `split_vendor=${split_vendor}&`
  }
  if (contract_num !== null && contract_num !== undefined && contract_num != ""){
    optionalParamsUrl += `contract_num=${contract_num}&`
  }
  if (rcpt_buy_pt !== null && rcpt_buy_pt !== undefined && rcpt_buy_pt != ""){
  optionalParamsUrl += `rcpt_buy_pt=${rcpt_buy_pt}&`
  }
  if (contract_type !== null && contract_type !== undefined && contract_type != ""){
    optionalParamsUrl += `contract_type=${contract_type}&`
  }
  if (settlement_grouping_num !== null && settlement_grouping_num !== undefined && settlement_grouping_num !== "") {
      optionalParamsUrl += `settlement_grouping_num=${settlement_grouping_num}&`
    }
  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
          }
          
  if (settle_grp_cma_dma_ind !== null && settle_grp_cma_dma_ind !== undefined && settle_grp_cma_dma_ind !== "") {
      optionalParamsUrl += `settle_grp_cma_dma_ind=${settle_grp_cma_dma_ind}&`
   }
   
   if (settle_grp_deferred_ind !== null && settle_grp_deferred_ind !== undefined && settle_grp_deferred_ind !== "") {
    optionalParamsUrl += `settle_grp_deferred_ind=${settle_grp_deferred_ind}&`
 }   
          return ApiService.getData( 
            isLocal() 
            ? `/WarehouseReceipts/Retrieve1007GroupList/1007groups?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}1007groups?${optionalParamsUrl}`)
        }

export function RetrieveCheck1007FreeStatusDetails(buyingPt, whse_rcpt_num){
  return ApiService.getData(
      isLocal()
      ? `/WarehouseReceipts/RetrieveCheck1007FreeStatusDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPt}/warehouse-receipt-numbers/${whse_rcpt_num}/1007freestatuses`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPt}/warehouse-receipt-numbers/${whse_rcpt_num}/1007freestatuses`
  )
}

export function UpdateWareHouseReceiptClearRpsFromEwr(buy_pt_id, whse_rcpt_num, dataObj) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateWareHouseReceiptClearRpsFromEwr/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/clearrecipts` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/clearrecipts`, dataObj)
}

export function RetrieveEWarehouseInquiryLogDetails(file_num){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveEWarehouseInquiryLogDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/files/${file_num}/inqirylogs`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/files/${file_num}/inqirylogs`
  )
}

export function UpdateDeliveryOutwarehouseAgreements(buy_pt_id, whse_rcpt_num, dataObj) {
  let crop_year = cropYearFromLS();
  let optionalParamsUrl = ``
  if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
      optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
  }

  if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  }

  if (whse_rcpt_num != null && whse_rcpt_num !== '' && whse_rcpt_num !== undefined) {
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}`;
  }

  return ApiService.updateData( 
    isLocal() 
    ? `/WarehouseReceipts/UpdateDeliveryOutwarehouseAgreements/companies/${compIdFromLS()}/deliveryoutwarehouseagreements?${optionalParamsUrl}
    `:`${warehouse_receipt_url_api}companies/${compIdFromLS()}/deliveryoutwarehouseagreements?${optionalParamsUrl}`, dataObj);
}

export function RetrieveWarehouseLockInPreviousLoadDetails(load_id){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveWarehouseLockInPreviousLoadDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loads/${load_id}/previousloads`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loads/${load_id}/previousloads`
  );
}

export function UpdateWarehouseLockinDetails(dataObj) {
  return ApiService.updateData( 
    isLocal() 
    ? `/WarehouseReceipts/UpdateWarehouseLockinDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouselockins
    `:`${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouselockins`, dataObj);
}

export function RetrieveWarehouseLockinList(){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveWarehouseLockinList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouselocks`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouselocks`
  );
}

export function UpdateWarehouseLockInImport(dataObj) {
  return ApiService.updateData( 
    isLocal() 
    ? `/WarehouseReceipts/UpdateWarehouseLockInImport/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/lockinimports
    `:`${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/lockinimports`, dataObj);
}

export function RetrieveWarehouseLockInPreviousLoadHeader(){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveWarehouseLockInPreviousLoadHeader/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/previousloadheaders`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/previousloadheaders`
  );
  }

  export function RetrieveWareHouseContractDetails(buy_pt_id,func_id,user_id,whse_rcpt_num,msMode,func_sub_id,pnut_type_id,
    pnut_variety_id,vendor,farm_id,seed_grower,seg_type) {
    let optionalParamsUrl = '';
    if (msMode !== null && msMode !== '' && msMode !== undefined) {
      optionalParamsUrl += `msMode=${msMode}&`
    }
    if (func_id !== null && func_id !== '' && func_id !== undefined) {
      optionalParamsUrl += `func_id=${func_id}&`
    }
    if (func_sub_id !== null && func_sub_id !== '' && func_sub_id !== undefined) {
      optionalParamsUrl += `func_sub_id=${func_sub_id}&`
    }
    if (user_id !== null && user_id !== '' && user_id !== undefined) {
      optionalParamsUrl += `user_id=${user_id}&`
    }
    if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
    }
    if (pnut_variety_id !== null && pnut_variety_id !== '' && pnut_variety_id !== undefined) {
      optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
    }
    if (vendor !== null && vendor !== '' && vendor !== undefined) {
      optionalParamsUrl += `vendor=${vendor}&`
    }
    if (farm_id !== null && farm_id !== '' && farm_id !== undefined) {
      optionalParamsUrl += `farm_id=${farm_id}&`
    }
    if (seed_grower !== null && seed_grower !== '' && seed_grower !== undefined) {
      optionalParamsUrl += `seed_grower=${seed_grower}&`
    }
    if (seg_type !== null && seg_type !== '' && seg_type !== undefined) {
      optionalParamsUrl += `seg_type=${seg_type}&`
    }
  
    return ApiService.getData(isLocal() ? `/WarehouseReceipts/RetrieveWareHouseContractDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/functions/${func_id}/users/${user_id}/warehouse-receipt-numbers/${whse_rcpt_num}/warehousecontracts?${optionalParamsUrl}` :
     `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/functions/${func_id}/users/${user_id}/warehouse-receipt-numbers/${whse_rcpt_num}/warehousecontracts?${optionalParamsUrl}`);
  }
  
export function UpdateUploadSendToEWarehouse(buyingPt, whseRcptNum, dataObj){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateUploadSendToEWarehouse/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPt}/warehouse-receipt-numbers/${whseRcptNum}/ewarehousereceipts`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPt}/warehouse-receipt-numbers/${whseRcptNum}/ewarehousereceipts`
      , dataObj
  )
}

export function UpdateLoadoutDeliveryInProcess(buy_pt_id, whse_rcpt_num){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateLoadoutDeliveryInProcess/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/deliveryinprocesses`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/deliveryinprocesses`
      , { user_id : useridFromLS() }
  )
}
export function UpdateWareHouseReceiptSetFsaDocument(buy_pt_id, whse_rcpt_num, dataObj) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateWareHouseReceiptSetFsaDocument/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/fsadocuments` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/fsadocuments`, dataObj)
}
export function Update1007Grouping(modify_tag, settlement_grouping_num, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/Update1007Grouping/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/action-types/${modify_tag}/group-numbers/${settlement_grouping_num}/1007groups`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/action-types/${modify_tag}/group-numbers/${settlement_grouping_num}/1007groups`
    , dataObj
  )

}
export function RetrieveDeliveryReceiptDetails(agreement_num, pnut_type_id, buy_pt_id) {
  let crop_year = cropYearFromLS();
  let optionalParamsUrl = ``
  if (crop_year != null && crop_year !== '' && crop_year !== undefined) {
      optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
  }

  if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
  }

  if (buy_pt_id != null && buy_pt_id !== undefined) {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}`;
    }

  return ApiService.getData( 
    isLocal() 
    ? `/WarehouseReceipts/RetrieveDeliveryReceiptDetails/agreement-numbers/${agreement_num}/companies/${compIdFromLS()}/deliveryreceipts?${optionalParamsUrl}
    `:`${warehouse_receipt_url_api}agreement-numbers/${agreement_num}/companies/${compIdFromLS()}/deliveryreceipts?${optionalParamsUrl}`)
}

export function RetrieveDeliveryInAgreementWareHouseDetails(whse_rcpt_num,agreement_num) {
  let optionalParamsUrl = '';

  if (compIdFromLS() !== null && compIdFromLS() !== undefined && compIdFromLS() !== "") {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  }
  if (cropYearFromLS() !== null && cropYearFromLS() !== undefined && cropYearFromLS() !== "") {
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
  }

  if (agreement_num !== null && agreement_num !== undefined && agreement_num !== "") {
    optionalParamsUrl += `agreement_num=${agreement_num}&`;
  }

  if (whse_rcpt_num !== null && whse_rcpt_num !== undefined && whse_rcpt_num !== "") {
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`;
  }

  return ApiService.getData(isLocal()
    ? `/WarehouseReceipts/RetrieveDeliveryInAgreementWareHouseDetails/deliveryinagreements?${optionalParamsUrl}`
    : `${warehouse_receipt_url_api}/deliveryinagreements?${optionalParamsUrl}`);
}

export function UpdateDeliveryWareHouseAgreement(agreement_num, whse_rcpt_num, prev_agree) {
  let optionalParamsUrl = ``

  if (agreement_num !== null && agreement_num !== undefined && agreement_num !== "") {
      optionalParamsUrl += `agreement_num=${agreement_num}&`;
  }

  if (prev_agree != null && prev_agree !== '' && prev_agree !== undefined) {
      optionalParamsUrl += `prev_agree=${prev_agree}&`;
  }

  if (whse_rcpt_num != null && whse_rcpt_num !== '' && whse_rcpt_num !== undefined) {
      optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}`;
  }

  return ApiService.updateData(
      isLocal()
          ? `/WarehouseReceipts/UpdateDeliveryWareHouseAgreement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouseagreements?${optionalParamsUrl}
    `: `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouseagreements?${optionalParamsUrl}`);
}

export function UpdateWareHouseReceiptRedeemByLoan(dataObj){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateWareHouseReceiptRedeemByLoan/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/redeembyloans`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/redeembyloans`
      , dataObj
  )
}

export function UpdateWareHouseReceiptRedeemByLoanReverse(dataObj){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateWareHouseReceiptRedeemByLoanReverse/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loanreverses`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/loanreverses`
      , dataObj
  )
}

export function CreateWareHouseReceiptRedeemByLoan(obj){
  return ApiService.postData(
    isLocal()
      ? `/WarehouseReceipts/CreateWareHouseReceiptRedeemByLoan/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehousereceiptloans`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehousereceiptloans`
      , obj
  )
}
export function UpdateLoadOutDeliveryProcessDetails(buy_pt_id, whse_rcpt_num){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateLoadOutDeliveryProcessDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/deliveryoutprocesses`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/deliveryoutprocesses`
      , { user_id : useridFromLS() }
  )
}

export function RetrievecalCulatePurchaseValuePreAppliedDetails(buy_pt_id, whse_rcpt_num, indicator, loan_repay, loan_value, loan_rate_date, receipt_value){
  let optionalParamsUrl = ""
  if(loan_repay !== null && loan_repay !== undefined && loan_repay !== ''){
    optionalParamsUrl += `loan_repay=${loan_repay}&`
  }
  if(loan_value !== null && loan_value !== undefined && loan_value !== ''){
    optionalParamsUrl += `loan_value=${loan_value}&`
  }
  if(loan_rate_date !== null && loan_rate_date !== undefined && loan_rate_date !== ''){
    optionalParamsUrl += `loan_rate_date=${loan_rate_date}&`
  }
  if(receipt_value !== null && receipt_value !== undefined && receipt_value !== ''){
    optionalParamsUrl += `receipt_value=${receipt_value}`
  }
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrievecalCulatePurchaseValuePreAppliedDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/indicators/${indicator}/purchasevalues?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/indicators/${indicator}/purchasevalues?${optionalParamsUrl}`
  )
}

export function RetrieveExternalReceiptSelectDetails(ccc_whse_code, receipt_num){
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveExternalReceiptSelectDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/ccc_whse_code/${ccc_whse_code}/receipt-numbers/${receipt_num}/receiptselections`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/ccc_whse_code/${ccc_whse_code}/receipt-numbers/${receipt_num}/receiptselections`
  );
}

export function RetrieveCalculatedReceiptValue(area_id,pnut_type_id,damage_whole_pct,ss_whole_pct,fm_whole_pct,ok_whole_pct,smk_whole_pct,elk_whole_pct,shrink_net_wt,shrink_lsk_wt,seg_val){
  let optionalParamsUrl = ""

  if(damage_whole_pct !== null && damage_whole_pct !== undefined && damage_whole_pct !== ''){
    optionalParamsUrl += `damage_whole_pct=${damage_whole_pct}&`
  }
  if(ss_whole_pct !== null && ss_whole_pct !== undefined && ss_whole_pct !== ''){
    optionalParamsUrl += `ss_whole_pct=${ss_whole_pct}&`
  }
  if(fm_whole_pct !== null && fm_whole_pct !== undefined && fm_whole_pct !== ''){
    optionalParamsUrl += `fm_whole_pct=${fm_whole_pct}&`
  }
  if(ok_whole_pct !== null && ok_whole_pct !== undefined && ok_whole_pct !== ''){
    optionalParamsUrl += `ok_whole_pct=${ok_whole_pct}&`
  }
  if(smk_whole_pct !== null && smk_whole_pct !== undefined && smk_whole_pct !== ''){
    optionalParamsUrl += `smk_whole_pct=${smk_whole_pct}&`
  }
  if(elk_whole_pct !== null && elk_whole_pct !== undefined && elk_whole_pct !== ''){
    optionalParamsUrl += `elk_whole_pct=${elk_whole_pct}&`
  }
  if(shrink_net_wt !== null && shrink_net_wt !== undefined && shrink_net_wt !== ''){
    optionalParamsUrl += `shrink_net_wt=${shrink_net_wt}&`
  }
  if(shrink_lsk_wt !== null && shrink_lsk_wt !== undefined && shrink_lsk_wt !== ''){
    optionalParamsUrl += `shrink_lsk_wt=${shrink_lsk_wt}&`
  }
  if(seg_val !== null && seg_val !== undefined && seg_val !== ''){
    optionalParamsUrl += `seg_val=${seg_val}&`
  }
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveCalculatedReceiptValue/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/receiptvalues?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/receiptvalues?${optionalParamsUrl}`
  );
}

export function CreateWareHouseReceipt(data){
  return ApiService.postData(isLocal() ? `/WarehouseReceipts/CreateWareHouseReceipt/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehousereceipts`:
  `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehousereceipts`, data)
}

export function RetrieveSettleWhouseReceiptDetails(whse_rcpt_num, obl_buy_pt_id, unld_buy_pt_id, whouse_num, bin_num, net_wt, spot_price, spot_pounds, overide_farm, split_vendor, premium_amt, deduct_amt, proceeds_amt, user_id, chrg_end_date, option_payment, redeem_method_ind, rcpt_value, loan_payoff_amt, wire_xfer_data, dataObj) {
  let optionalParamsUrl = ''

  if (obl_buy_pt_id !== null && obl_buy_pt_id !== undefined && obl_buy_pt_id !== ""){
    optionalParamsUrl += `obl_buy_pt_id=${obl_buy_pt_id}&`
  }
  if (unld_buy_pt_id !== null && unld_buy_pt_id !== undefined && unld_buy_pt_id !== ""){
    optionalParamsUrl += `unld_buy_pt_id=${unld_buy_pt_id}&`
  }
  if (whouse_num !== null && whouse_num !== undefined && whouse_num !== ""){
    optionalParamsUrl += `whouse_num=${whouse_num}&`
  }
  if (bin_num !== null && bin_num !== undefined && bin_num !== ""){
    optionalParamsUrl += `bin_num=${bin_num}&`
  }
  if (net_wt !== null && net_wt !== undefined && net_wt !== ""){
    optionalParamsUrl += `net_wt=${net_wt}&`
  }
  if (spot_price !== null && spot_price !== undefined && spot_price !== ""){
    optionalParamsUrl += `spot_price=${spot_price}&`
  }
  if (overide_farm !== null && overide_farm !== undefined && overide_farm !== ""){
    optionalParamsUrl += `overide_farm=${overide_farm}&`
  }
  if (split_vendor !== null && split_vendor !== undefined && split_vendor !== ""){
    optionalParamsUrl += `split_vendor=${split_vendor}&`
  }
  if (spot_pounds !== null && spot_pounds !== undefined && spot_pounds !== ""){
    optionalParamsUrl += `spot_pounds=${spot_pounds}&`
  }
  if (premium_amt !== null && premium_amt !== undefined && premium_amt !== ""){
    optionalParamsUrl += `premium_amt=${premium_amt}&`
  }
  if (deduct_amt !== null && deduct_amt !== undefined && deduct_amt !== ""){
    optionalParamsUrl += `deduct_amt=${deduct_amt}&`
  }
  if (proceeds_amt !== null && proceeds_amt !== undefined && proceeds_amt !== ""){
    optionalParamsUrl += `proceeds_amt=${proceeds_amt}&`
  }
  if (user_id !== null && user_id !== undefined && user_id !== ""){
    optionalParamsUrl += `user_id=${user_id}&`
  }
  if (chrg_end_date !== null && chrg_end_date !== undefined && chrg_end_date !== ""){
    optionalParamsUrl += `chrg_end_date=${chrg_end_date}&`
  }
  if (option_payment !== null && option_payment !== undefined && option_payment !== ""){
    optionalParamsUrl += `option_payment=${option_payment}&`
  }
  if (redeem_method_ind !== null && redeem_method_ind !== undefined && redeem_method_ind !== ""){
    optionalParamsUrl += `redeem_method_ind=${redeem_method_ind}&`
  }
  if (rcpt_value !== null && rcpt_value !== undefined && rcpt_value !== ""){
    optionalParamsUrl += `rcpt_value=${rcpt_value}&`
  }
  if (loan_payoff_amt !== null && loan_payoff_amt !== undefined && loan_payoff_amt !== ""){
    optionalParamsUrl += `loan_payoff_amt=${loan_payoff_amt}&`;
  }
  if (wire_xfer_data !== null && wire_xfer_data !== undefined && wire_xfer_data !== ""){
    optionalParamsUrl += `wire_xfer_data=${wire_xfer_data}&`
  }
  return ApiService.postData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveSettleWhouseReceiptDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipts/${whse_rcpt_num}/settlewhousereceipts?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/warehouse-receipts/${whse_rcpt_num}/settlewhousereceipts?${optionalParamsUrl}`, dataObj
  )
}


export function UpdateSettleTentApplication(data) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateSettleTentApplication/settletentapplication`
      : `${warehouse_receipt_url_api}settletentapplication`, data);
}

export function UpdateExternalReceipt(receipt_num, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateExternalReceipt/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/receipt-numbers/${receipt_num}/receiptnumbers`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/receipt-numbers/${receipt_num}/receiptnumbers`
    , dataObj
  )
}

export function RetrieveWareHouseValueCalculationDetails(area_id, pnut_type_id, cr_br_pct, dcol_shel_pct, fan_pct, dam_pct, ss_pct, vicam_pct, fm_pct, smk, sspct, okpct, elkpct, lskpct, moisturepct, netwt, grosswt, lskwt, foreignmaterialpct){
  let optionalParamsUrl = ''
  // 
  if (cr_br_pct !== null && cr_br_pct !== undefined && cr_br_pct !== ""){
    optionalParamsUrl += `cr_br_pct=${cr_br_pct}&`
  }
  if (dcol_shel_pct !== null && dcol_shel_pct !== undefined && dcol_shel_pct !== ""){
    optionalParamsUrl += `dcol_shel_pct=${dcol_shel_pct}&`
  }
  if (fan_pct !== null && fan_pct !== undefined && fan_pct !== ""){
    optionalParamsUrl += `fan_pct=${fan_pct}&`
  }
  if (dam_pct !== null && dam_pct !== undefined && dam_pct !== ""){
    optionalParamsUrl += `dam_pct=${dam_pct}&`
  }
  if (ss_pct !== null && ss_pct !== undefined && ss_pct !== ""){
    optionalParamsUrl += `ss_pct=${ss_pct}&`
  }
  if (vicam_pct !== null && vicam_pct !== undefined && vicam_pct !== ""){
    optionalParamsUrl += `vicam_pct=${vicam_pct}&`
  }
  if (fm_pct !== null && fm_pct !== undefined && fm_pct !== ""){
    optionalParamsUrl += `fm_pct=${fm_pct}&`
  }
  if (smk !== null && smk !== undefined && smk !== ""){
    optionalParamsUrl += `smk=${smk}&`
  }
  if (sspct !== null && sspct !== undefined && sspct !== ""){
    optionalParamsUrl += `sspct=${sspct}&`
  }
  if (okpct !== null && okpct !== undefined && okpct !== ""){
    optionalParamsUrl += `okpct=${okpct}&`
  }
  if (elkpct !== null && elkpct !== undefined && elkpct !== ""){
    optionalParamsUrl += `elkpct=${elkpct}&`
  }
  if (lskpct !== null && lskpct !== undefined && lskpct !== ""){
    optionalParamsUrl += `lskpct=${lskpct}&`
  } 
  if (moisturepct !== null && moisturepct !== undefined && moisturepct !== ""){
    optionalParamsUrl += `moisturepct=${moisturepct}&`
  }
  if (netwt !== null && netwt !== undefined && netwt !== ""){
    optionalParamsUrl += `netwt=${netwt}&`
  }
  if (grosswt !== null && grosswt !== undefined && grosswt !== ""){
    optionalParamsUrl += `grosswt=${grosswt}&`
  }
  if (lskwt !== null && lskwt !== undefined && lskwt !== ""){
    optionalParamsUrl += `lskwt=${lskwt}&`
  }
  if (foreignmaterialpct !== null && foreignmaterialpct !== undefined && foreignmaterialpct !== ""){
    optionalParamsUrl += `foreignmaterialpct=${foreignmaterialpct}`
  }
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveWareHouseValueCalculationDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/valuecalculations?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/valuecalculations?${optionalParamsUrl}`
  )
}

export function UpdateExternalReceiptRelease(ccc_whse_code, receipt_num, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateExternalReceiptRelease/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/ccc_whse_code/${ccc_whse_code}/receipt-numbers/${receipt_num}/receiptreleases`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/ccc_whse_code/${ccc_whse_code}/receipt-numbers/${receipt_num}/receiptreleases`
    , dataObj
  )
}
export function RetrieveDeliveryAgreementAttachmentDetails(agree_list) {
  let optionalParamsUrl = ''

  if (agree_list !== null && agree_list !== undefined && agree_list !== ""){
    optionalParamsUrl += `agree_list=${agree_list}&`
  }
  
  return ApiService.getData(
    isLocal()
      ? `/WarehouseReceipts/RetrieveDeliveryAgreementAttachmentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/deliveryagreementattachments?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/deliveryagreementattachments?${optionalParamsUrl}`
  )
}

export function UpdateShrinkPaymentsReverse(buy_pt_id, whse_rcpt_num){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateShrinkPaymentsReverse/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/shrinkpayments`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/shrinkpayments`
      , { user_id : useridFromLS() } 
  )
}

export function CreateShrinkPay(buy_pt_id, pnut_type_id, dataObj){
  let optionalParamsUrl = ''
  if(pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== ''){
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}`
  }
  return ApiService.postData(
    isLocal()
      ? `/WarehouseReceipts/CreateShrinkPay/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/shrinkpayments?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/shrinkpayments?${optionalParamsUrl}`
      , dataObj 
  )
}

export function CreateStorageHandlingPaymentDetails(buy_pt_id, whse_rcpt_num){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/CreateStorageHandlingPaymentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/handlingpayments`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/handlingpayments`
      , { user_id : useridFromLS() } 
  )
}

export function UpdateReverseWarehouseReceiptTentApplicationDeatils(buy_pt_id, whse_rcpt_num, unld_buy_pt_id){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateReverseWarehouseReceiptTentApplicationDeatils/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/tentaplications`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/tentaplications`
      , { 
        user_id        : useridFromLS(), 
        unld_buy_pt_id : unld_buy_pt_id 
      } 
  )
}

export function UpdateUnPurchaseWarehouseReceipt(buy_pt_id, whse_rcpt_num, dataObj){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateUnPurchaseWarehouseReceipt/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/unpurchasereceipts`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/unpurchasereceipts`
      , dataObj 
  )
}

export function UpdateWarehouseReceiptVoid(buy_pt_id, whse_rcpt_num, dataObj){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateWarehouseReceiptVoid/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/receiptvoids`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/receiptvoids`
      , dataObj 
  )
}

export function UpdateWhouseReceiptUnvoid(buy_pt_id, whse_rcpt_num, dataObj){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateWhouseReceiptUnvoid/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/unvoids`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/unvoids`
      , dataObj 
  )
}

export function UpdateStorageHandlingPaymentReverse(buy_pt_id, whse_rcpt_num){
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateStorageHandlingPaymentReverse/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/payments`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/payments`
      , { "user_id" : useridFromLS() } 
  )
}

export function UpdateExternalReceiptPayOff(receipt_num, ccc_whse_code, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateExternalReceiptPayOff/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/receipt-numbers/${receipt_num}/warehouse-codes/${ccc_whse_code}/receiptnumberpayoffs`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/receipt-numbers/${receipt_num}/warehouse-codes/${ccc_whse_code}/receiptnumberpayoffs`
    , dataObj
  )
}

export function UpdateEwrTransmissionStatus(buy_pt_id, whse_rcpt_num, dataObj) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateEwrTransmissionStatus/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/transmissionstatus` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/transmissionstatus`, dataObj)
}

export function UpdateVoidWareHouseReceipt(buy_pt_id, whse_rcpt_num, dataObj) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateVoidWareHouseReceipt/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/voidwarehousereceipts` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/voidwarehousereceipts`, dataObj)
}

export function UpdateWareHouseReceiptChangeDate(buy_pt_id, whse_rcpt_num, dataObj) {
  return ApiService.updateData(isLocal() ? `/WarehouseReceipts/UpdateWareHouseReceiptChangeDate/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/changedates` : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/changedates`, dataObj)
}

export function UpdateWareHouseReceipt(buy_pt_id, whse_rcpt_num, save_loan_fields, dataObj){
  let optionalParamsUrl = ''
  if(save_loan_fields != '' && save_loan_fields !== undefined && save_loan_fields !== null){
    optionalParamsUrl += `save_loan_fields=${save_loan_fields}`
  }

  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateWareHouseReceipt/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/warehousereceipts?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/warehousereceipts?${optionalParamsUrl}`
      , dataObj
  )
}


export function UpdateDeliveryAgreement(modify_tag, agreement_num, delv_ind, dataObj) {

  let optionalParamsUrl = ''
  if (agreement_num !== undefined && agreement_num !== "" && agreement_num !== null) {
    optionalParamsUrl += `agreement_num=${agreement_num}&`
  }
  if (delv_ind !== undefined && delv_ind !== "" && delv_ind !== null) {
    optionalParamsUrl += `delv_ind=${delv_ind}`
  }

  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateDeliveryAgreement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/modify_tags/${modify_tag}/deliveryagreements?${optionalParamsUrl}`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/modify_tags/${modify_tag}/deliveryagreements?${optionalParamsUrl}`, dataObj
  )
}

export function UpdateLoadOutDeliveryComplete(buy_pt_id, whse_rcpt_num, dataObj) {

  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateLoadOutDeliveryComplete/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/loadoutcompletes`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/loadoutcompletes`, dataObj
  )
}

export function UpdateLoadOutDeliveryCompleteUndo(buy_pt_id, whse_rcpt_num, dataObj) {

  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateLoadOutDeliveryCompleteUndo/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/loadoutdeliverycompletes`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/warehouse-receipt-numbers/${whse_rcpt_num}/loadoutdeliverycompletes`, dataObj
  )
}

// DELV_SETTLE_COMPLETE 
export function UpdateDeliverySettlementComplete(buy_pt_id, trade_insp_num, dataObj) {
  // let optionalParameters = ''
  // if (cid != null && cid !== undefined && cid !== "") {
  //   optionalParameters += `cid=${cid}&`
  // }
  // if (trade_settle_num != null && trade_settle_num !== undefined && trade_settle_num !== "") {
  //   optionalParameters += `trade_settle_num=${trade_settle_num}&`
  // }
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateDeliverySettlementComplete/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/trade-inspection-numbers/${trade_insp_num}/deliverysettlements?`
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/trade-inspection-numbers/${trade_insp_num}/deliverysettlements?`
    , dataObj
  )
}

// DELV_SETTLE_REVERSE  
export function UpdateDeliverySettlementReverse(buy_pt_id, trade_insp_num, cid,dataObj) {
  let optionalParameters = ''
  if (cid != null) {
    optionalParameters += `&cid=${cid}`
  }
  return ApiService.updateData(
    isLocal()
      ? `/WarehouseReceipts/UpdateDeliverySettlementReverse/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/trade-inspection-numbers/${trade_insp_num}/settlementreverses?` + optionalParameters
      : `${warehouse_receipt_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/trade-inspection-numbers/${trade_insp_num}/settlementreverses?` + optionalParameters
    ,dataObj
  )
}