/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setValue,
  disable,
  getValue,
  setData
} from "../../shared/WindowImports";

import "./VoidReasonProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import FormLabel from "react-bootstrap/esm/FormLabel";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_VoidReasonProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VoidReasonProfile",
    windowName: "VoidReasonProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.VoidReasonProfile",
    horizontalForm:true,
    // START_USER_CODE-USER_VoidReasonProfile_PROPERTIES
    headerData: {
      scrName: "Void Reason Profile",
      scrCode: "PN0180B",
    },
    // END_USER_CODE-USER_VoidReasonProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxLimitAccess: {
      name: "chkboxLimitAccess",
      type: "CheckBoxWidget",
      parent: "grpbxVoidReasonProfile",
      Label: "Limit Access",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxLimitAccess_PROPERTIES

      // END_USER_CODE-USER_chkboxLimitAccess_PROPERTIES
    },
    ddAction: {
      name: "ddAction",
      type: "DropDownFieldWidget",
      parent: "grpbxVoidReasonProfile",
      Label: "Action:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAction_PROPERTIES
      SpecialFirstOption:""
      // END_USER_CODE-USER_ddAction_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtCode: {
      name: "txtCode",
      type: "TextBoxWidget",
      parent: "grpbxVoidReasonProfile",
      Label: "Code:",
      LengthRange: { MinLength: 0, MaxLength: 1 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCode_PROPERTIES

      // END_USER_CODE-USER_txtCode_PROPERTIES
    },
    txtDescription: {
      name: "txtDescription",
      type: "TextBoxWidget",
      parent: "grpbxVoidReasonProfile",
      Label: "Discription:",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDescription_PROPERTIES

      // END_USER_CODE-USER_txtDescription_PROPERTIES
    },
    txtTransmitCode: {
      name: "txtTransmitCode",
      type: "TextBoxWidget",
      parent: "grpbxVoidReasonProfile",
      Label: "Transmit Code:",
      LengthRange: { MinLength: 0, MaxLength: 1 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransmitCode_PROPERTIES

      // END_USER_CODE-USER_txtTransmitCode_PROPERTIES
    },
    grpbxVoidReasonProfile: {
      name: "grpbxVoidReasonProfile",
      type: "GroupBoxWidget",
      parent: "VoidReasonProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxVoidReasonProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxVoidReasonProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "VoidReasonProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  function FormLoad() { 
    let dataObj = getData(thisObj, 'frmVoidReasonProfile')
    if (dataObj.cmdOk_Caption == "Add") {
      document.getElementById('btnOk').innerText = "Add"
      bFillAction()
    }
    if (dataObj.cmdOk_Caption == "Update" || dataObj.cmdOk_Caption == "Double") {
      document.getElementById('btnOk').innerText = "Update"
      disable(thisObj, 'txtCode')
      setValue(thisObj, 'txtCode', dataObj.txtCode)
      setValue(thisObj, 'txtDescription', dataObj.txtDescription)
      document.getElementById('txtDescription').focus();
      setValue(thisObj, 'txtTransmitCode', dataObj.txtTransmitCode)
      setValue(thisObj, 'chkboxLimitAccess', dataObj.chkboxLimitAccess)
      setValue(thisObj, 'lblAddedByValue', dataObj.lblAddedBy)
      setValue(thisObj, 'lblChangedByValue', dataObj.lblChangedBy)
    }
    if (dataObj.cmdOk_Caption == "Update") {
      bFillAction(dataObj.actionCode);
    }
    else if (dataObj.cmdOk_Caption == "Double") {
      bFillAction()
    }
  }

  //Binding of Action dropdown
  const bFillAction = (actionCode) => {
    let js = []
    js.push({ key: 'N', description: 'NO SALE' })
    js.push({ key: 'V', description: 'VOID' })
    js.push({ key: 'S', description: 'SUPERSEDE' })
    thisObj.setState(current => {
      return {
        ...current,
        ddAction: {
          ...state["ddAction"],
          valueList: js
        }
      }
    })

    let dataObj = getData(thisObj, 'frmVoidReasonProfile')
    if (dataObj.cmdOk_Caption == "Update") {
      setValue(thisObj, "ddAction", js.at(actionCode).key)
    }
  }

  const FormValid = () => {
    var bFormValid = false
    let code = getValue(thisObj, 'txtCode')
    let description = getValue(thisObj, 'txtDescription')
    let transmitCode = getValue(thisObj, 'txtTransmitCode')
    let action = getValue(thisObj, 'ddAction')

    if (code == '' || code == null || code == undefined) {
      showMessage(thisObj, "Code is a required field.")
      bFormValid = false
      return bFormValid
    }

    if (description == '' || description == null || description == undefined) {
      showMessage(thisObj, "Description is a required field.")
      bFormValid = false
      return bFormValid
    }

    if (transmitCode == '' || transmitCode == null || transmitCode == undefined) {
      showMessage(thisObj, "Transmit Code is a required field.")
      bFormValid = false
      return bFormValid
    }

    if (action == '' || action == null || action == undefined) {
      showMessage(thisObj, "Action Code is a required field.")
      bFormValid = false
      return bFormValid
    }

    bFormValid = true
    return bFormValid
  }

  //Click event for Add/Edit button
  const onbtnOkClick = async() => {
    try{
      if (FormValid() == false) {
        return;
      }
      let dataObj = getData(thisObj, 'frmVoidReasonProfile')
      let void_reason_code = getValue(thisObj, 'txtCode')
      let void_reason_desc = getValue(thisObj, 'txtDescription')
      let transmit_code = getValue(thisObj, 'txtTransmitCode')
      let actionList = thisObj.state['ddAction'].valueList
      let action_code = getValue(thisObj, 'ddAction');
      let actionName = ''
      if (action_code !== undefined && action_code !== null && action_code !== '') {
        actionName = actionList.find(elem => elem.key == action_code).description
      }
      let limit_access = getValue(thisObj, 'chkboxLimitAccess')
      if (limit_access == true) {
        limit_access = 'Y'
      }
      else if (limit_access == false || limit_access == '' || limit_access == null || limit_access == undefined){
        limit_access = 'N'
      }

      if(dataObj.cmdOk_Caption == 'Add') {
        let temp = {
          "void_reason_code": void_reason_code,
          "void_reason_desc": void_reason_desc,
          "transmit_code": transmit_code,
          "action_code": actionName,
          "limit_access": limit_access
        }
        let response = await SystemMaintenanceMasterManagementService.CreateVoidReason(null, temp)
        let data = response
        if (data.status !== 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
          return;
        }
        let Array = []
        let Obj = {}
        Obj.txtCode = void_reason_code
        Obj.txtDescription = void_reason_desc
        Obj.txtTransmitCode = transmit_code
        Obj.ddAction = actionName
        Obj.chkboxLimitAccess = limit_access
        Array.push(Obj)
        setData(thisObj, 'frmVoidReasonSetup', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_VoidReasonProfilePopUp").childNodes[0].click()
      }

      if (dataObj.cmdOk_Caption == 'Update' || dataObj.cmdOk_Caption == "Double") {
        let temp = {
          "void_reason_desc": void_reason_desc,
          "transmit_code": transmit_code,
          "action_code": actionName,
          "limit_access": limit_access
        }
        let response = await SystemMaintenanceMasterManagementService.UpdateVoidReason(null, void_reason_code, temp)
        let data = response
        if (data.status !== 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
          return;
        }
        let Array = []
        let Obj = {}
        Obj.txtCode = void_reason_code
        Obj.txtDescription = void_reason_desc
        Obj.txtTransmitCode = transmit_code
        Obj.ddAction = actionName
        Obj.chkboxLimitAccess = limit_access
        Array.push(Obj)
        setData(thisObj, 'frmVoidReasonSetup', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_VoidReasonProfilePopUp").childNodes[0].click()
      }
     
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick

  //Click event for Cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_VoidReasonProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VoidReasonProfile*/}

              {/* END_USER_CODE-USER_BEFORE_VoidReasonProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVoidReasonProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVoidReasonProfile*/}

              <GroupBoxWidget
                conf={state.grpbxVoidReasonProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCode*/}

                {/* END_USER_CODE-USER_AFTER_txtCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtDescription*/}

                {/* END_USER_CODE-USER_BEFORE_txtDescription*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDescription}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDescription*/}

                {/* END_USER_CODE-USER_AFTER_txtDescription*/}
                {/* START_USER_CODE-USER_BEFORE_txtTransmitCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtTransmitCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTransmitCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTransmitCode*/}

                {/* END_USER_CODE-USER_AFTER_txtTransmitCode*/}
                {/* START_USER_CODE-USER_BEFORE_ddAction*/}

                {/* END_USER_CODE-USER_BEFORE_ddAction*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAction}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAction*/}

                {/* END_USER_CODE-USER_AFTER_ddAction*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxLimitAccess*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxLimitAccess*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxLimitAccess}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxLimitAccess*/}

                {/* END_USER_CODE-USER_AFTER_chkboxLimitAccess*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVoidReasonProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVoidReasonProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_VoidReasonProfile*/}

              {/* END_USER_CODE-USER_AFTER_VoidReasonProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_VoidReasonProfile);
