/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getValue,
  setValue,
  getData
} from "../../shared/WindowImports";

import "./ViewWRPayments.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions";
import Loading from "../../../Loader/Loading";
import { GetReportLogoImage } from "../../Common/Constants";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
const gridId = 'gridPayments';

function WarehouseReceipts_ViewWRPayments(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewWRPayments",
    windowName: "ViewWRPayments",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ViewWRPayments",
    // START_USER_CODE-USER_ViewWRPayments_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Payment History",
      scrCode: "PN1090E",
    },
    // END_USER_CODE-USER_ViewWRPayments_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export To Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colBuyingPoint: {
      name: "colBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colCheckNum: {
      name: "colCheckNum",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Check Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckNum_PROPERTIES

      // END_USER_CODE-USER_colCheckNum_PROPERTIES
    },
    colClearedDate: {
      name: "colClearedDate",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Cleared Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colClearedDate_PROPERTIES

      // END_USER_CODE-USER_colClearedDate_PROPERTIES
    },
    colInterestAgreement: {
      name: "colInterestAgreement",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Interest Agreement",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInterestAgreement_PROPERTIES

      // END_USER_CODE-USER_colInterestAgreement_PROPERTIES
    },
    colIssuedDate: {
      name: "colIssuedDate",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Issued Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIssuedDate_PROPERTIES

      // END_USER_CODE-USER_colIssuedDate_PROPERTIES
    },
    colPayType: {
      name: "colPayType",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Pay Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayType_PROPERTIES

      // END_USER_CODE-USER_colPayType_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colReceiptNum: {
      name: "colReceiptNum",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Receipt #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptNum_PROPERTIES

      // END_USER_CODE-USER_colReceiptNum_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colVoidDate: {
      name: "colVoidDate",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Void Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVoidDate_PROPERTIES

      // END_USER_CODE-USER_colVoidDate_PROPERTIES
    },
    gridPayments: {
      name: "gridPayments",
      type: "GridWidget",
      parent: "grpbxViewWRPayments",
      gridCellsOrder:
        "txtcolCheckNum,txtcolVendor,txtcolStatus,txtcolProceeds,txtcolInterestAgreement,txtcolPayType,txtcolIssuedDate,txtcolClearedDate,txtcolVoidDate,txtcolBuyingPoint,txtcolReceiptNum",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPayments_PROPERTIES

      // END_USER_CODE-USER_gridPayments_PROPERTIES
    },
    lblPayments: {
      name: "lblPayments",
      type: "LabelWidget",
      parent: "grpbxViewWRPayments",
      Label: "Payments:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPayments_PROPERTIES

      // END_USER_CODE-USER_lblPayments_PROPERTIES
    },
    txtcolBuyingPoint: {
      name: "txtcolBuyingPoint",
      type: "TextBoxWidget",
      colName: "colBuyingPoint",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolCheckNum: {
      name: "txtcolCheckNum",
      type: "TextBoxWidget",
      colName: "colCheckNum",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCheckNum_PROPERTIES

      // END_USER_CODE-USER_txtcolCheckNum_PROPERTIES
    },
    txtcolClearedDate: {
      name: "txtcolClearedDate",
      type: "TextBoxWidget",
      colName: "colClearedDate",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolClearedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolClearedDate_PROPERTIES
    },
    txtcolInterestAgreement: {
      name: "txtcolInterestAgreement",
      type: "TextBoxWidget",
      colName: "colInterestAgreement",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInterestAgreement_PROPERTIES

      // END_USER_CODE-USER_txtcolInterestAgreement_PROPERTIES
    },
    txtcolIssuedDate: {
      name: "txtcolIssuedDate",
      type: "TextBoxWidget",
      colName: "colIssuedDate",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIssuedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolIssuedDate_PROPERTIES
    },
    txtcolPayType: {
      name: "txtcolPayType",
      type: "TextBoxWidget",
      colName: "colPayType",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayType_PROPERTIES

      // END_USER_CODE-USER_txtcolPayType_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolReceiptNum: {
      name: "txtcolReceiptNum",
      type: "TextBoxWidget",
      colName: "colReceiptNum",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtcolReceiptNum_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolVoidDate: {
      name: "txtcolVoidDate",
      type: "TextBoxWidget",
      colName: "colVoidDate",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVoidDate_PROPERTIES

      // END_USER_CODE-USER_txtcolVoidDate_PROPERTIES
    },
    grpbxViewWRPayments: {
      name: "grpbxViewWRPayments",
      type: "GroupBoxWidget",
      parent: "ViewWRPayments",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewWRPayments_PROPERTIES

      // END_USER_CODE-USER_grpbxViewWRPayments_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ViewWRPayments",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const readPaymentObj = getData(thisObj, "frmViewWRPaymentsloadData");
    if (readPaymentObj) {
      loadWRPaymentsData(readPaymentObj);
    }
  }, [getData(thisObj, "frmViewWRPaymentsloadData")]);

  const loadWRPaymentsData = async (readPaymentObj) => {
    const warehouseReceipt = readPaymentObj.lblWRNum;
    const buyingPointId = readPaymentObj.lblBuyingPtId;
    try {
      await ReadPayments(warehouseReceipt, buyingPointId);
      await PaymentActivityDetails(warehouseReceipt, buyingPointId);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (e) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  // START_USER_CODE-USER_METHODS
  function formatDate(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = newDate.getMonth() + 1;
    var sDay = newDate.getDate();
    var sYear = newDate.getFullYear()?.toString()?.padStart(4, '0');
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSecond = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
  };

  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  };

  const ReadPayments = async (whseRecpt, buyPtId) => {
    try {
      let readPaymentResponse = await WarehouseReceiptService.RetrieveReadyToPayWareHouseDetails(buyPtId, whseRecpt);
      if (!readPaymentResponse?.length) {
        return;
      }

      const paymentData = [];
      if (readPaymentResponse?.length > 0) {
        readPaymentResponse.forEach(log => {
          const {
            check_num,
            payee_vendor,
            pay_status,
            rtp_proceeds,
            intr_agree_num,
            pay_type,
            issued_date,
            clear_date,
            void_date,
          } = log;

          const formattedIssuedDate = issued_date ? formatDate(issued_date).split(' ')[0] : '';
          const formattedClearDate = clear_date ? formatDate(clear_date).split(' ')[0] : '';
          const formattedVoidDate = void_date ? formatDate(void_date).split(' ')[0] : '';

          const tableRowObj = {
            txtcolCheckNum: check_num,
            txtcolVendor: payee_vendor,
            txtcolStatus: pay_status,
            txtcolProceeds: rtp_proceeds,
            txtcolInterestAgreement: intr_agree_num,
            txtcolPayType: pay_type,
            txtcolIssuedDate: formattedIssuedDate,
            txtcolClearedDate: formattedClearDate,
            txtcolVoidDate: formattedVoidDate,
            txtcolBuyingPoint: buyPtId,
            txtcolReceiptNum: whseRecpt?.toString()?.padStart(6, '0')
          }
          paymentData.push(tableRowObj);
        });
      }
      setValue(thisObj, gridId, paymentData);
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ReadPayments: ${error?.toString()}`
        );
      }
    }
  };

  const PaymentActivityDetails = async (whseRecpt, buyPtId) => {
    try {
      let paymentActivityResponse = await WarehouseReceiptService.RetrievePaymentActivityDetails(buyPtId, whseRecpt);
      if (!paymentActivityResponse?.length) {
        return;
      }

      const gridData = getValue(thisObj, gridId);
      const paymentData = gridData?.length ? gridData : [];
      if (paymentActivityResponse?.length > 0) {
        paymentActivityResponse.forEach(log => {
          const {
            check_num,
            payee_vendor,
            pay_status,
            check_proceeds,
            intr_agree_num,
            pay_type,
            issued_date,
            clear_date,
            void_date
          } = log;

          const formattedIssuedDate = issued_date ? formatDate(issued_date).split(' ')[0] : '';
          const formattedClearDate = clear_date ? formatDate(clear_date).split(' ')[0] : '';
          const formattedVoidDate = void_date ? formatDate(void_date).split(' ')[0] : '';

          const tableRowObj = {
            txtcolCheckNum: check_num,
            txtcolVendor: payee_vendor,
            txtcolStatus: pay_status,
            txtcolProceeds: check_proceeds,
            txtcolInterestAgreement: intr_agree_num,
            txtcolPayType: pay_type,
            txtcolIssuedDate: formattedIssuedDate, // MM/DD/YYYY
            txtcolClearedDate: formattedClearDate, // MM/DD/YYYY
            txtcolVoidDate: formattedVoidDate, // MM/DD/YYYY
            txtcolBuyingPoint: buyPtId,
            txtcolReceiptNum: whseRecpt
          }
          paymentData.push(tableRowObj);
        });
      }
      setValue(thisObj, gridId, paymentData);
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during PaymentActivityDetails: ${error?.toString()}`
        );
      }
    }
  };

  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const gridData = getValue(thisObj, gridId);

      if (!gridData?.length) {
        showMessage(thisObj, "No value in the grid to export");
        return;
      }

      const fileName = "Payment History WR "
        + gridData[0].txtcolBuyingPoint + ""
        + gridData[0].txtcolReceiptNum;

      const arrayData = [];

      for (let i = 0; i < gridData.length; i++) {
        const rowData = gridData[i];
        const obj = {
          "Check Num": ExcelNumericDataConvert(rowData.txtcolCheckNum),
          "Vendor": ExcelNumericDataConvert(rowData.txtcolVendor),
          "Status": rowData.txtcolStatus,
          "Proceeds": ExcelNumericDataConvert(rowData.txtcolProceeds), //.toFixed(2),
          "Interest Agreement": rowData.txtcolInterestAgreement,
          "Pay Type": rowData.txtcolPayType,
          "Issued Date": rowData.txtcolIssuedDate,
          "Cleared Date": rowData.txtcolClearedDate,
          "Void Date": rowData.txtcolVoidDate,
          "Buying Point": rowData.txtcolBuyingPoint,
          "Receipt #": ExcelNumericDataConvert(rowData.txtcolReceiptNum)
        }
        arrayData.push(obj);
      }

      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      const FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, `Exception in Export To Excel button Click: ${err.toString()}`);
    }
  }

  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnPrintClick = () => {
    try {
      const dataTable = getValue(thisObj, gridId);
      if (!dataTable?.length) {
        showMessage(thisObj, "No value in the grid to print");
        return;
      }

      const hostPath = window.location.origin;
      const imgUrl = GetReportLogoImage(window.location.origin)
      var obj = new jsPDF('landscape', 'mm', 'a4');
      var img = new Image();
      img.src = imgUrl;

      const pdfData = [];
      for (let i = 0; i < dataTable.length; i++) {
        const rowAllData = dataTable[i];
        const rowData = [
          rowAllData.txtcolCheckNum,
          rowAllData.txtcolVendor,
          rowAllData.txtcolStatus,
          rowAllData.txtcolProceeds,
          rowAllData.txtcolInterestAgreement,
          rowAllData.txtcolPayType,
          rowAllData.txtcolIssuedDate,
          rowAllData.txtcolClearedDate,
          rowAllData.txtcolVoidDate,
          rowAllData.txtcolBuyingPoint,
          rowAllData.txtcolReceiptNum
        ];

        pdfData.push(rowData);
      }

      const allColumnNames = [
        "Check Num",
        "Vendor",
        "Status",
        "Proceeds",
        "Interest\nAgreement",
        "Pay Type",
        "Issued Date",
        "Cleared Date",
        "Void Date",
        "Buying Point",
        "Receipt #",
      ];

      obj.autoTable({
        startX: 20,
        startY: 20,
        theme: 'grid',
        styles: { halign: 'center' },
        headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
        columnStyles: {
          3: { halign: 'right' },
        },
        head: [allColumnNames],
        body: pdfData
      });
      obj.setFontSize(12);
      obj.text(`Payment History WR ${dataTable[0].txtcolBuyingPoint}${dataTable[0].txtcolReceiptNum}`, 15, 200);
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Failed to print, please provide proper name to file");
        }
      }
      else {
        alert("Failed to print, please provide proper name to file");
      }
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, `Exception in Print button Click: ${err.toString()}`);
    }
  }

  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_ViewWRPaymentsPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, `Exception in Close button Click: ${err.toString()}`);
    }
  }

  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewWRPayments*/}

              {/* END_USER_CODE-USER_BEFORE_ViewWRPayments*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewWRPayments*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewWRPayments*/}

              <GroupBoxWidget
                conf={state.grpbxViewWRPayments}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPayments*/}

                {/* END_USER_CODE-USER_BEFORE_lblPayments*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPayments}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPayments*/}

                {/* END_USER_CODE-USER_AFTER_lblPayments*/}
                {/* START_USER_CODE-USER_BEFORE_gridPayments*/}

                {/* END_USER_CODE-USER_BEFORE_gridPayments*/}

                <GridWidget
                  conf={state.gridPayments}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPayments}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPayments*/}

                {/* END_USER_CODE-USER_AFTER_gridPayments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewWRPayments*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewWRPayments*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ViewWRPayments*/}

              {/* END_USER_CODE-USER_AFTER_ViewWRPayments*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ViewWRPayments);
