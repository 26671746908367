/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setData,
  setValue,
  getValue,
  getData,
  goTo,
  enable,
  disable,

} from "../../shared/WindowImports";

import "./LoanInterestSetup.scss";
import Loading from "../../../Loader/Loading";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_LoanInterestSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LoanInterestSetup",
    windowName: "LoanInterestSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.LoanInterestSetup",
    // START_USER_CODE-USER_LoanInterestSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Loan Interest Setup",
      scrCode: "PN0290A",
    },
    // END_USER_CODE-USER_LoanInterestSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxLoanInterestSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxLoanInterestSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxLoanInterestSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    lblLoanInterest: {
      name: "lblLoanInterest",
      type: "LabelWidget",
      parent: "grpbxLoanInterestSetup",
      Label: "Loan Interest:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoanInterest_PROPERTIES

      // END_USER_CODE-USER_lblLoanInterest_PROPERTIES
    },
    lstLoanInterest: {
      name: "lstLoanInterest",
      type: "ListBoxFieldWidget",
      parent: "grpbxLoanInterestSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLoanInterest_PROPERTIES

      // END_USER_CODE-USER_lstLoanInterest_PROPERTIES
    },
    grpbxLoanInterestSetup: {
      name: "grpbxLoanInterestSetup",
      type: "GroupBoxWidget",
      parent: "LoanInterestSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxLoanInterestSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxLoanInterestSetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "LoanInterestSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#LoanInterestProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#LoanInterestProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#LoanInterestProfile#DEFAULT#true#Click"],
    // },
    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#LoanInterestProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    EnableDisableControls()

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let childData = getData(thisObj, 'frmLoanInterestSetup')
    if (childData !== null) {
      bFillLoanInterestList()
    }
  }, [getData(thisObj, 'frmLoanInterestSetup')])

  // START_USER_CODE-USER_METHODS
  async function FormLoad() {
    try {
      await bFillLoanInterestList();
      await EnableDisableControls();
    }
    catch (err) {
      errorHandler(err)
    }
  }
  // EnableDisableControls functionalty

  const EnableDisableControls = () => {
    enable(thisObj, 'btnAdd')
    let LoanReduction = getData(thisObj, 'InterestLength');
    if (LoanReduction <= 0) {
      disable(thisObj, "btnEdit")
      disable(thisObj, "btnDelete")
    }
    else {
      enable(thisObj, "btnEdit")
      enable(thisObj, "btnDelete")
    }
  }
  const bFillLoanInterestList=async(effect_date_time)=>{
    try{
      setLoading(true)
      let js =[];
      let obj = {};
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveLoanInterestDetails(effect_date_time);
      if (data.length > 0) {
        enable(thisObj, "btnEdit")
        enable(thisObj, "btnDelete")
        if (data != undefined && data != null && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let dataExist = js.find(elem => elem.key == data[i].effectDateTime)
            if (dataExist == undefined) {
              obj = {
                key: data[i].effectDateTime,
                description: moment(data[i].effectDateTime).format('MM/DD/YYYY') + " - " + moment(data[i].effectDateTime).format('hh:mm A') + " - " + Number(data[i].interestRate).toFixed(3),
                date: data[i].effectDateTime
              }
              js.push(obj);
              obj = {};
            }
          }
        }
        if (js.length > 0) {
          setValue(thisObj, 'lstLoanInterest', [js.at(0).key]);
        }
        setData(thisObj, 'InterestLength', js.length)
      }
      else {
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstLoanInterest: {
            ...state["lstLoanInterest"],
            valueList: js
          }
        }
      })
      setLoading(false)

    }
    catch(err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading grid"
        );
      }
    }
  }

  // Add button functionality

  let onbtnAddClick = async () => {
    try {
      let frmLoanInterestProfile = {};
      frmLoanInterestProfile.cmdOk_Caption = "Add"
      frmLoanInterestProfile.txtEffectiveDateTime_enabled = 'True'
      setData(thisObj, "LoanInterestProfile", frmLoanInterestProfile)
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#LoanInterestProfile#DEFAULT#true#Click')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing Add button"
        );
      }
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick
  //Edit button functionality
  let onbtnEditClick = async () => {
    try {
      let interestList = thisObj.state['lstLoanInterest'].valueList
      let effect_date_time = getValue(thisObj, 'lstLoanInterest')[0]
      let date = ''
      if (effect_date_time !== undefined && effect_date_time !== null && effect_date_time !== '') {
        date = interestList.find(elem => elem.key == effect_date_time).date
      }
      let data = await SystemMaintenanceSpecialFunctionsService.RetrieveLoanInterestDetails(effect_date_time)
      let frmLoanInterestProfile = {}
      frmLoanInterestProfile.cmdOk_Caption = "Update"
      frmLoanInterestProfile.txtEffectiveDateTime_enabled = 'False'
      frmLoanInterestProfile.txtEffectiveDateTime = moment(data[0].effectDateTime).format('yyyy-MM-DD HH:mm:ss.SSS')
      frmLoanInterestProfile.txtInterestRate = Number(data[0].interestRate).toFixed(3)
      frmLoanInterestProfile.lblAddedByCaption = data[0].addUser + " " + moment(data[0].addDateTime).format('MM/DD/YYYY')
      frmLoanInterestProfile.lblChangedByCaption = data[0].chgUser + " " + moment(data[0].chgDateTime).format('MM/DD/YYYY')
      setData(thisObj, "LoanInterestProfile", frmLoanInterestProfile)
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#LoanInterestProfile#DEFAULT#true#Click')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

    //Delete button functionalty

    let onbtnDeleteClick = async () => {
      try {
        let interestList = thisObj.state['lstLoanInterest'].valueList
        let effect_date_time = getValue(thisObj, 'lstLoanInterest')[0]
        let interestDetails = ''
        if (effect_date_time !== undefined && effect_date_time !== null && effect_date_time !== '') {
          interestDetails = interestList.find(elem => elem.key == effect_date_time).description
        }
        if (effect_date_time == null) {
          showMessage(thisObj, 'Please select a Loan Repayment to delete')
          return;
        }
        let res = confirm(`Are you sure you wish to delete this Loan Interest \n'${interestDetails}'`)
        if (res == true) {
          let data = await SystemMaintenanceSpecialFunctionsService.RemoveLoanInterest(effect_date_time);
          if (![200, 400, 404].includes(data.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
          }
          else if (data.status != 200) {
            showMessage(thisObj, data.message, true)
          }
          setData(thisObj, 'InterestLength', '')      
          await bFillLoanInterestList()
          EnableDisableControls();
        }
        else {
          return;
        }
  
      }
      catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        }
        else {
          showMessage(
            thisObj,
            "Error occurred during executing delete button"
          );
        }
      }
    }
    thisObj.onbtnDeleteClick = onbtnDeleteClick
  
  // Close button Functionality
  const onbtnCloseClick = () => {
      try {
        document.getElementById("SystemMaintenanceSpecialFunctions_LoanInterestSetupPopUp").childNodes[0].click()
      } catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        } else {
          showMessage(
            thisObj,
            "Exception in PreSubmit Event code for widget:btnCancel event:Click"
          );
        }
        return false;
      }
      return true;
    };
    thisObj.onbtnCloseClick = onbtnCloseClick;
// Method for handling error
function errorHandler(err) {
  showMessage(thisObj, err.message)
}
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur, 
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>

              {/* START_USER_CODE-USER_BEFORE_LoanInterestSetup*/}

              {/* END_USER_CODE-USER_BEFORE_LoanInterestSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLoanInterestSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLoanInterestSetup*/}

              <GroupBoxWidget
                conf={state.grpbxLoanInterestSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblLoanInterest*/}

                {/* END_USER_CODE-USER_BEFORE_lblLoanInterest*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLoanInterest}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLoanInterest*/}

                {/* END_USER_CODE-USER_AFTER_lblLoanInterest*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstLoanInterest*/}

                {/* END_USER_CODE-USER_BEFORE_lstLoanInterest*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLoanInterest}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstLoanInterest*/}

                {/* END_USER_CODE-USER_AFTER_lstLoanInterest*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLoanInterestSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLoanInterestSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_LoanInterestSetup*/}

              {/* END_USER_CODE-USER_AFTER_LoanInterestSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_LoanInterestSetup);
