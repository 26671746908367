import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility} from "../ParentWidgetFunc";
function GroupWidget(props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        data-testid={conf.name}
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{props.children}</Col>
          ) : (
            <React.Fragment> {props.children}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default GroupWidget;
