/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  TextAreaWidget,
  setValue,
  getData,
  getValue,
  hide,
  goTo,
  show,
  updateState,
  setLabel,
  enable,
  disable,
  DateWidget,
  setData,
  getSelectedRowNumber,
  AutocompleteWidget,
  setFieldValues,
  hideWidgets,
} from "../../shared/WindowImports";
import { getWidgetLabel } from "../../widgets/ParentWidgetFunc";

import "./TradeAgreeProfile.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { number } from "yup";
// END_USER_CODE-USER_IMPORTS


function Settlements_TradeAgreeProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  const [stopFirstRender, setStopFirstRender] = useState(0)
  // END_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))


  let states = {
    Label: "TradeAgreeProfile",
    windowName: "TradeAgreeProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeAgreeProfile",
    // START_USER_CODE-USER_TradeAgreeProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Agreement Profile",
      scrCode: "PN1130B",
    },
    // END_USER_CODE-USER_TradeAgreeProfile_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btn3: {
      name: "btn3",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn3_PROPERTIES

      // END_USER_CODE-USER_btn3_PROPERTIES
    },
    btn4: {
      name: "btn4",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn5: {
      name: "btn5",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn6: {
      name: "btn6",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn7: {
      name: "btn7",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn8: {
      name: "btn8",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn9: {
      name: "btn9",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn10: {
      name: "btn10",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn11: {
      name: "btn11",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn12: {
      name: "btn12",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn13: {
      name: "btn13",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn14: {
      name: "btn14",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn15: {
      name: "btn15",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn16: {
      name: "btn16",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn17: {
      name: "btn17",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn18: {
      name: "btn18",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn19: {
      name: "btn19",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn20: {
      name: "btn20",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },

    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitSub",
      Label: '',
      CharWidth: "15",
      Mandatory: false
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChkbx",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxCol_PROPERTIES

      // END_USER_CODE-USER_chkbxCol_PROPERTIES
    },
    colAppldLbs: {
      name: "colAppldLbs",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Applied Lbs.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppldLbs_PROPERTIES

      // END_USER_CODE-USER_colAppldLbs_PROPERTIES
    },
    colChkbx: {
      name: "colChkbx",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChkbx_PROPERTIES

      // END_USER_CODE-USER_colChkbx_PROPERTIES
    },
    colIsStsClm: {
      name: "colIsStsClm",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Location",
      // HasLabel: false,
      // Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIsStsClm_PROPERTIES

      // END_USER_CODE-USER_colIsStsClm_PROPERTIES
    },
    colLoc: {
      name: "colLoc",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoc_PROPERTIES

      // END_USER_CODE-USER_colLoc_PROPERTIES
    },
    colRcvdDt: {
      name: "colRcvdDt",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Received Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcvdDt_PROPERTIES

      // END_USER_CODE-USER_colRcvdDt_PROPERTIES
    },
    colSegVal: {
      name: "colSegVal",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Seg Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSegVal_PROPERTIES

      // END_USER_CODE-USER_colSegVal_PROPERTIES
    },
    colShippdDt: {
      name: "colShippdDt",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Shipped Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShippdDt_PROPERTIES

      // END_USER_CODE-USER_colShippdDt_PROPERTIES
    },
    colTrdInsp: {
      name: "colTrdInsp",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Trade Insp #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTrdInsp_PROPERTIES

      // END_USER_CODE-USER_colTrdInsp_PROPERTIES
    },
    colTrdSettle: {
      name: "colTrdSettle",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Trade settle #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTrdSettle_PROPERTIES

      // END_USER_CODE-USER_colTrdSettle_PROPERTIES
    },
    ddAgreementStatus: {
      name: "ddAgreementStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxTrdSalePrchs",
      Label: "Agreement Status:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreementStatus_PROPERTIES

      // END_USER_CODE-USER_ddAgreementStatus_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Edible/Oil:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddFrimBasisFlat: {
      name: "ddFrimBasisFlat",
      type: "DropDownFieldWidget",
      parent: "grpbxPricingInfo",
      Label: "Firm/ Basis/ Flat:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFrimBasisFlat_PROPERTIES

      // END_USER_CODE-USER_ddFrimBasisFlat_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxTrdSalePrchs",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      optionMaxLength: 25,
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddRemitTo1: {
      name: "ddRemitTo1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo1_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo1_PROPERTIES
    },
    ddRemitTo2: {
      name: "ddRemitTo2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo2_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo2_PROPERTIES
    },
    ddRemitTo3: {
      name: "ddRemitTo3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo3_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo3_PROPERTIES
    },
    ddRemitTo4: {
      name: "ddRemitTo4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo5: {
      name: "ddRemitTo5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo6: {
      name: "ddRemitTo6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo7: {
      name: "ddRemitTo7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo8: {
      name: "ddRemitTo8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo9: {
      name: "ddRemitTo9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo10: {
      name: "ddRemitTo10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo11: {
      name: "ddRemitTo11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo12: {
      name: "ddRemitTo12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo13: {
      name: "ddRemitTo13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo14: {
      name: "ddRemitTo14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo15: {
      name: "ddRemitTo15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo16: {
      name: "ddRemitTo16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo17: {
      name: "ddRemitTo17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo18: {
      name: "ddRemitTo18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo19: {
      name: "ddRemitTo19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo20: {
      name: "ddRemitTo20",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 30,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seg #:",
      DataProviderForDropDown: "inline",
      Options: '',
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    grpbxVendorSplitRow0: {
      name: "grpbxVendorSplitRow0",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },

    grpbxPeanutInfo: {
      name: "grpbxPeanutInfo",
      type: "GroupBoxWidget",
      parent: "grpbxAgreementInfo",
      Label: "Peanut Information",
      ColSpan: "1",
      ColSpanForLargeDesktop: "5",
      Height: "",
      Width: "",
      clonedExtId: "22646",
      ColsForTabLandscape: "2",
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES
    },
    grpbxPricingInfo: {
      name: "grpbxPricingInfo",
      type: "GroupBoxWidget",
      parent: "grpbxVndrPrcngInfo",
      Label: "Pricing Information",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPricingInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPricingInfo_PROPERTIES
    },
    grpbxTrdSalePrchs: {
      name: "grpbxTrdSalePrchs",
      type: "GroupBoxWidget",
      parent: "grpbxAgreementInfo",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxTrdSalePrchs_PROPERTIES

      // END_USER_CODE-USER_grpbxTrdSalePrchs_PROPERTIES
    },
    grpbxVendorSplitRow1: {
      name: "grpbxVendorSplitRow1",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES
    },
    grpbxVendorSplitRow2: {
      name: "grpbxVendorSplitRow2",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES
    },
    grpbxVendorSplitRow3: {
      name: "grpbxVendorSplitRow3",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES
    },
    grpbxVendorSplitRow4: {
      name: "grpbxVendorSplitRow4",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow5: {
      name: "grpbxVendorSplitRow5",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",

      Height: "",
      Width: "",
      HasLabel: false,
      Visible: true,
      VisibleForLargeDesktop: true,
      VisibleForMobile: true,
      VisibleForTabLandscape: true,
      VisibleForTabPotrait: true,
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow6: {
      name: "grpbxVendorSplitRow6",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow7: {
      name: "grpbxVendorSplitRow7",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow8: {
      name: "grpbxVendorSplitRow8",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow9: {
      name: "grpbxVendorSplitRow9",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow10: {
      name: "grpbxVendorSplitRow10",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow11: {
      name: "grpbxVendorSplitRow11",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow12: {
      name: "grpbxVendorSplitRow12",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow13: {
      name: "grpbxVendorSplitRow13",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow14: {
      name: "grpbxVendorSplitRow14",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow15: {
      name: "grpbxVendorSplitRow15",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow16: {
      name: "grpbxVendorSplitRow16",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow17: {
      name: "grpbxVendorSplitRow17",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow18: {
      name: "grpbxVendorSplitRow18",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow19: {
      name: "grpbxVendorSplitRow19",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplits: {
      name: "grpbxVendorSplits",
      type: "GroupBoxWidget",
      parent: "grpbxVndrPrcngInfo",
      Label: "Vendor Splits",
      ColSpan: "1",
      Height: "",
      Width: "",
      clonedExtId: "22563",
      ColsForTabLandscape: "4",
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxVendorSplits_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplits_PROPERTIES
    },
    grpbxVendorSplitSub: {
      name: "grpbxVendorSplitSub",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "4",
      Height: "",
      Width: "",
      clonedExtId: "23098",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitSub_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitSub_PROPERTIES
    },
    lblAppldPounds: {
      name: "lblAppldPounds",
      type: "LabelWidget",
      parent: "grpbxPricingInfo",
      Label: "Applied Pounds:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAppldPounds_PROPERTIES

      // END_USER_CODE-USER_lblAppldPounds_PROPERTIES
    },
    lblAppldPoundsVal: {
      name: "lblAppldPoundsVal",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Applied Pounds:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      Enabled:false
      // START_USER_CODE-USER_lblAppldPoundsVal_PROPERTIES

      // END_USER_CODE-USER_lblAppldPoundsVal_PROPERTIES
    },

    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },
    lblOpenBlnc: {
      name: "lblOpenBlnc",
      type: "LabelWidget",
      parent: "grpbxPricingInfo",
      Label: "Open Balance:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOpenBlnc_PROPERTIES

      // END_USER_CODE-USER_lblOpenBlnc_PROPERTIES
    },
    lblOpenBlncVal: {
      name: "lblOpenBlncVal",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Open Balance:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      Enabled:false
      // START_USER_CODE-USER_lblAppldPoundsVal_PROPERTIES

      // END_USER_CODE-USER_lblAppldPoundsVal_PROPERTIES
    },

    lblPnutInfo: {
      name: "lblPnutInfo",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPnutInfo_PROPERTIES

      // END_USER_CODE-USER_lblPnutInfo_PROPERTIES
    },
    lblPricingInfo: {
      name: "lblPricingInfo",
      type: "LabelWidget",
      parent: "grpbxPricingInfo",
      Label: "Pricing Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPricingInfo_PROPERTIES

      // END_USER_CODE-USER_lblPricingInfo_PROPERTIES
    },
    lblRemitTo: {
      name: "lblRemitTo",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Remit To",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo_PROPERTIES
    },
    lblSharePercentage: {
      name: "lblSharePercentage",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePercentage_PROPERTIES

      // END_USER_CODE-USER_lblSharePercentage_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVndrSplts: {
      name: "lblVndrSplts",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor Splits",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVndrSplts_PROPERTIES

      // END_USER_CODE-USER_lblVndrSplts_PROPERTIES
    },
    lblXX1: {
      name: "lblXX1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX2: {
      name: "lblXX2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "Xxxxxxxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX2_PROPERTIES

      // END_USER_CODE-USER_lblXX2_PROPERTIES
    },
    lblXX3: {
      name: "lblXX3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "Xxxxxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX3_PROPERTIES

      // END_USER_CODE-USER_lblXX3_PROPERTIES
    },
    lblXX4: {
      name: "lblXX4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX4_PROPERTIES

      // END_USER_CODE-USER_lblXX4_PROPERTIES
    },
    lblXX5: {
      name: "lblXX5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX6: {
      name: "lblXX6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX7: {
      name: "lblXX7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX8: {
      name: "lblXX8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX9: {
      name: "lblXX9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX10: {
      name: "lblXX10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX11: {
      name: "lblXX11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX12: {
      name: "lblXX12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX13: {
      name: "lblXX13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX14: {
      name: "lblXX14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX15: {
      name: "lblXX15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX16: {
      name: "lblXX16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX17: {
      name: "lblXX17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX18: {
      name: "lblXX18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX19: {
      name: "lblXX19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX20: {
      name: "lblXX20",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    radioTradeSaleTradePurchase: {
      name: "radioTradeSaleTradePurchase",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxTrdSalePrchs",
      Options: "Trade Sale:S,Trade Purchase:P",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioTradeSaleTradePurchase_PROPERTIES

      // END_USER_CODE-USER_radioTradeSaleTradePurchase_PROPERTIES
    },
    radioGradePricingMethod: {
      name: "radioGradePricingMethod",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxReGrading",
      Options: "Full Grades:FULL,TKC Basis:TKC",
      Label: "Grade Pricing Method:",
      ColSpan: "3",
      RowSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioGradePricingMethod_PROPERTIES

      // END_USER_CODE-USER_radioGradePricingMethod_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtAgreedPounds: {
      name: "txtAgreedPounds",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Agreed Pounds:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",

    },
    txtAgreement: {
      name: "txtAgreement",
      type: "TextBoxWidget",
      parent: "grpbxTrdSalePrchs",
      Label: "Agreement #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreement_PROPERTIES

      // END_USER_CODE-USER_txtAgreement_PROPERTIES
    },
    txtAgreementDate: {
      name: "txtAgreementDate",
      type: "DateWidget",
      parent: "grpbxTrdSalePrchs",
      Label: "Agreement Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAgreementDate_PROPERTIES

      // END_USER_CODE-USER_txtAgreementDate_PROPERTIES
    },
    txtarComments: {
      name: "txtarComments",
      type: "TextAreaWidget",
      parent: "grpbxComments",
      Label: "Comments:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarComments_PROPERTIES

      // END_USER_CODE-USER_txtarComments_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES

      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtcolAppldLbs: {
      name: "txtcolAppldLbs",
      type: "TextBoxWidget",
      colName: "colAppldLbs",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppldLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolAppldLbs_PROPERTIES
    },
    txtcolIsStsClm: {
      name: "txtcolIsStsClm",
      type: "TextBoxWidget",
      colName: "colIsStsClm",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIsStsClm_PROPERTIES

      // END_USER_CODE-USER_txtcolIsStsClm_PROPERTIES
    },
    txtcolLoc: {
      name: "txtcolLoc",
      type: "TextBoxWidget",
      colName: "colLoc",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolLoc_PROPERTIES
    },
    txtcolRcvdDt: {
      name: "txtcolRcvdDt",
      type: "TextBoxWidget",
      colName: "colRcvdDt",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcvdDt_PROPERTIES

      // END_USER_CODE-USER_txtcolRcvdDt_PROPERTIES
    },
    txtcolSegVal: {
      name: "txtcolSegVal",
      type: "TextBoxWidget",
      colName: "colSegVal",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSegVal_PROPERTIES

      // END_USER_CODE-USER_txtcolSegVal_PROPERTIES
    },
    txtcolShippdDt: {
      name: "txtcolShippdDt",
      type: "TextBoxWidget",
      colName: "colShippdDt",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShippdDt_PROPERTIES

      // END_USER_CODE-USER_txtcolShippdDt_PROPERTIES
    },
    txtcolTrdInsp: {
      name: "txtcolTrdInsp",
      type: "TextBoxWidget",
      colName: "colTrdInsp",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTrdInsp_PROPERTIES

      // END_USER_CODE-USER_txtcolTrdInsp_PROPERTIES
    },
    txtcolTrdSettle: {
      name: "txtcolTrdSettle",
      type: "TextBoxWidget",
      colName: "colTrdSettle",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTrdSettle_PROPERTIES

      // END_USER_CODE-USER_txtcolTrdSettle_PROPERTIES
    },
    txtPrice: {
      name: "txtPrice",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Price:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrice_PROPERTIES

      // END_USER_CODE-USER_txtPrice_PROPERTIES
    },
    txtSharePercentage1: {
      name: "txtSharePercentage1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage2: {
      name: "txtSharePercentage2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage2_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage2_PROPERTIES
    },
    txtSharePercentage3: {
      name: "txtSharePercentage3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage3_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage3_PROPERTIES
    },
    txtSharePercentage4: {
      name: "txtSharePercentage4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage4_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage4_PROPERTIES
    },
    txtSharePercentage5: {
      name: "txtSharePercentage5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage6: {
      name: "txtSharePercentage6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage7: {
      name: "txtSharePercentage7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage8: {
      name: "txtSharePercentage8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage9: {
      name: "txtSharePercentage9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage10: {
      name: "txtSharePercentage10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage11: {
      name: "txtSharePercentage11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage12: {
      name: "txtSharePercentage12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage13: {
      name: "txtSharePercentage13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage14: {
      name: "txtSharePercentage14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage15: {
      name: "txtSharePercentage15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage16: {
      name: "txtSharePercentage16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage17: {
      name: "txtSharePercentage17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage18: {
      name: "txtSharePercentage18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage19: {
      name: "txtSharePercentage19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage20: {
      name: "txtSharePercentage20",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES

      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES

      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES

      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor6: {
      name: "txtVendor6",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor7: {
      name: "txtVendor7",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor8: {
      name: "txtVendor8",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor9: {
      name: "txtVendor9",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor10: {
      name: "txtVendor10",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor11: {
      name: "txtVendor11",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor12: {
      name: "txtVendor12",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor13: {
      name: "txtVendor13",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor14: {
      name: "txtVendor14",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor15: {
      name: "txtVendor15",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor16: {
      name: "txtVendor16",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor17: {
      name: "txtVendor17",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor18: {
      name: "txtVendor18",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor19: {
      name: "txtVendor19",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor20: {
      name: "txtVendor20",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    grpbxAgreementInfo: {
      name: "grpbxAgreementInfo",
      type: "GroupBoxWidget",
      parent: "TradeAgreeProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxAgreementInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxAgreementInfo_PROPERTIES
    },
    btnColContrctPopup: {
      name: "btnColContrctPopup",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_btnColContrctPopup_PROPERTIES

      // END_USER_CODE-USER_btnColContrctPopup_PROPERTIES
    },
    btnPopup: {
      name: "btnPopup",
      type: "ButtonWidget",
      colName: "btnColContrctPopup",
      parent: "gridApplicationInfo",
      Label: "...",
      CharWidth: "9",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPopup_PROPERTIES

      // END_USER_CODE-USER_btnPopup_PROPERTIES
    },
    grpbxVndrPrcngInfo: {
      name: "grpbxVndrPrcngInfo",
      type: "GroupBoxWidget",
      parent: "TradeAgreeProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxVndrPrcngInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxVndrPrcngInfo_PROPERTIES
    },
    lblApplicationInfo: {
      name: "lblApplicationInfo",
      type: "LabelWidget",
      parent: "grpbxApplicationInfo",
      Label: "Application Information",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblApplicationInfo_PROPERTIES

      // END_USER_CODE-USER_lblApplicationInfo_PROPERTIES
    },
    gridApplicationInfo: {
      name: "gridApplicationInfo",
      type: "GridWidget",
      parent: "grpbxApplicationInfo",
      gridCellsOrder:
        "btnPopup,txtcolLoc,txtcolTrdInsp,txtcolTrdSettle,txtcolShippdDt,txtcolRcvdDt,txtcolAppldLbs,txtcolSegVal",
      Pagination: false,
      Label: "Application Information",
      HasLabel: false,
      Height: "",
      Width: "",
      disable: true,
      needGridChange: true,
      ColSpan: "12",
      Cols: "12",
      noDataHeader:true
      // START_USER_CODE-USER_gridApplicationInfo_PROPERTIES

      // END_USER_CODE-USER_gridApplicationInfo_PROPERTIES
    },
    grpbxApplicationInfo: {
      name: "grpbxApplicationInfo",
      type: "GroupBoxWidget",
      parent: "TradeAgreeProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "12",
      // START_USER_CODE-USER_grpbxApplicationInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxApplicationInfo_PROPERTIES
    },
    grpbxComments: {
      name: "grpbxComments",
      type: "GroupBoxWidget",
      parent: "TradeAgreeProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxComments_PROPERTIES

      // END_USER_CODE-USER_grpbxComments_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TradeAgreeProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      "ContractManagement#MainMenu": {},
      "ContractManagement#VendorDisclosure": {},
      REVERSE: {},
      "ContractManagement#MainMenu": {},
      "ContractManagement#VendorDisclosure": {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btn1: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btn2: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btn3: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btn4: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnPopup: {
      DEFAULT: ["Settlements#TradeSettleProfile#DEFAULT#false#Click"],
    },
    btnClose: {
      DEFAULT: ["ContractManagement#TradeAgreeSearch#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  // var button=''
  var showbu = false
  var label = "Crop Year"
  var year = JSON.parse(sessionStorage.getItem('year'))
  var mbSeedGrower = false
  var mbSeedCredit = false
  var mbLandPlaster = false
  var mbForeignMaterial = false
  var mbFormDirty = false
  var mbHaveVendorName = false
  var mbSuccessfulLoad = false
  // var MintFillFarm = Long
  // var icount = Integer
  var mbApplicationsExist = false

  // var oControl = Control
  var sPermission = ""
  var buttonname = ""
  const columns = [
    {
      Header: "Name",
      accessor: "show.name",
    },
    {
      Header: "Type",
      accessor: "show.type",
    },
  ]
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    initialVendorsplithide();
    setData(thisObj, "vndrRowNo", 0)
    setData(thisObj, "arrRow", []);
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    // VendorDetails()
    // let js=[{}]
    // setValue(thisObj, "gridApplicationInfo",js);
    setValue(thisObj, "lblCropYear2", "CropYear : " + (sessionStorage.getItem('year')))
    setData(thisObj,"refresData","Ok")
  }, [])
  useEffect(() => {
    FormLoad()
  }, [getData(thisObj, 'CreateValue')])


  useEffect(() => {
    setStopFirstRender(stopFirstRender + 1)
    if (stopFirstRender >= 1) {
      let vndrnmbr = getData(thisObj, 'vendorDetails') == null ? '' : getData(thisObj, 'vendorDetails').VendorNumber
      getVendorDetails(vndrnmbr)
    }
  },
    [getData(thisObj, 'vendorDetails')]) 
  const initialVendorsplithide = () => {
    let element = ['grpbxVendorSplitRow4', 'grpbxVendorSplitRow5', 'grpbxVendorSplitRow6', 'grpbxVendorSplitRow7', 'grpbxVendorSplitRow8',
      'grpbxVendorSplitRow9', 'grpbxVendorSplitRow10','grpbxVendorSplitRow11' ,'grpbxVendorSplitRow12',
      'grpbxVendorSplitRow13', 'grpbxVendorSplitRow14','grpbxVendorSplitRow15' ,'grpbxVendorSplitRow16',
      'grpbxVendorSplitRow17', 'grpbxVendorSplitRow18','grpbxVendorSplitRow19']
      hideWidgets(thisObj, element, false)
    // hide(thisObj, 'grpbxVendorSplitRow4')
    // hide(thisObj, 'grpbxVendorSplitRow5')
    // hide(thisObj, 'grpbxVendorSplitRow6')
    // hide(thisObj, 'grpbxVendorSplitRow7')
    // hide(thisObj, 'grpbxVendorSplitRow8')
    // hide(thisObj, 'grpbxVendorSplitRow9')
    // hide(thisObj, 'grpbxVendorSplitRow10')
    // hide(thisObj, 'grpbxVendorSplitRow11')
    // hide(thisObj, 'grpbxVendorSplitRow12')
    // hide(thisObj, 'grpbxVendorSplitRow13')
    // hide(thisObj, 'grpbxVendorSplitRow14')
    // hide(thisObj, 'grpbxVendorSplitRow15')
    // hide(thisObj, 'grpbxVendorSplitRow16')
    // hide(thisObj, 'grpbxVendorSplitRow17')
    // hide(thisObj, 'grpbxVendorSplitRow18')
    // hide(thisObj, 'grpbxVendorSplitRow19')
  }

  const txtVendorBlur = (event) => {
    mbFormDirty = true
    let cntrlId = event.target.name
    let currentRow = cntrlId.match(/(\d+)/)[0]
    let vendor1 = event.target.value
    if (vendor1 != "" && vendor1 != undefined) {
      setData(thisObj, "vndrRowNo", currentRow)
      setData(thisObj, "vndrid", vendor1)
      getVendorDetails(vendor1)
    }
    else {
      let newRemit = 'ddRemitTo' + currentRow
      let newSharePct = 'txtSharePercentage' + currentRow
      let lblVendor = 'lblXX' + currentRow

      setValue(thisObj, newSharePct, "")
      setValue(thisObj, lblVendor, "")
      thisObj.state[newRemit].valueList = [];
      setTimeout(() => {
        setValue(thisObj, newRemit, '')
      }, 100);

    }
  }
  const txtsharePrcntgBlur = (event) => {
    let cntrlId = event.target.name
    let newCntrl = cntrlId.match(/(\d+)/)[0]
    let newTxtVndr = 'txtSharePercentage' + newCntrl;
    let addpercent = 0.00
    addpercent = addpercent + parseFloat(getValue(thisObj, newTxtVndr))
    let checknan = parseInt(getValue(thisObj, newTxtVndr)).toFixed(2)
    mbFormDirty = true
    if (newTxtVndr !== '' && newTxtVndr !== undefined && checknan !== 'NaN') {
      setValue(thisObj, newTxtVndr, addpercent.toFixed(2))
    } else {
      setValue(thisObj, newTxtVndr, '')
    }
  }
  const getVendorDetails = (vendorId) => {
    if (vendorId !== "" && vendorId !== null && vendorId !== undefined) {
      let vndrRowNo = getData(thisObj, "vndrRowNo")
      ContractManagementService.RetieveRemitDetails(vendorId).then(response => {
        let data = response;
        if (data.length > 0) {
          let js = [];
          let lblXXname = data[0].name
          for (var i = 0; i < data.length; i++) {
            let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].address1 + '-' + data[i].city;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          let newVendor = 'txtVendor' + vndrRowNo
          let newLblVendor = 'lblXX' + vndrRowNo
          let newRemitTo = 'ddRemitTo' + vndrRowNo
          let arrVal = getData(thisObj, "arrRow")
          setData(thisObj, "arrRow", [...arrVal, parseInt(vndrRowNo)])
          setValue(thisObj, newVendor, [vendorId])
          setValue(thisObj, newLblVendor, lblXXname)
          thisObj.state[newRemitTo].valueList = js;

          setTimeout(() => {
            setValue(thisObj, newRemitTo, js.at(0).key);
          }, 100);

          let finalArr = getData(thisObj, "arrRow")
          let result = finalArr.filter((arr) => {
            return (arr == vndrRowNo)
          })
          if (vndrRowNo >= 4 && vndrRowNo <= 19 && result.length <= 1) {
            let newGrpbx = `grpbxVendorSplitRow` + (Number(vndrRowNo) + 1)
            show(thisObj, newGrpbx)
          }
        }
      })
    }
  }
  const btnVendorClick = (cntrlId) => {
    setData(thisObj, "vndrRowNo", cntrlId.match(/(\d+)/)[0])
    goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
  }



  //showing vendorname txtbox

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  const FormLoad = async () => {
    bFillBuyingPointList(true); // Method for binding Buying Points
    bFillPeanutTypeList(true); // Method for binding Peanut Type dropdown
    bFillPeanutVarietyList(null, null, true);
    bFillTypeList();// Method for binding Organic List
    bFillOleicList(); // Method for binding Oleic values
    bFillSegmentList(true); // Method for binding Segments
    bFillEdibleOilList(true); // Method for binding Seed Grower List
    bFillGeneration();
    bFillagreestatus()
    hide(thisObj, 'radioGradePricingMethod')
    // setValue(thisObj, 'radioGradePricingMethod', 'Full')
    disable(thisObj, 'txtAddedBy')
    disable(thisObj, 'txtChangedBy')
    disable(thisObj, 'lblAppldPoundsVal')
    disable(thisObj, 'lblOpenBlncVal')
    setTimeout(() => {
      AddUpdateDetails([])
    }, 1000)

  }
  const EnableDisableControls = async (buyingpoint, agree_status, seg_type, data) => {
    setLoading(true)
    let arrfirs = [0, 1, 2, 3]
    let arrVal = getData(thisObj, "arrRow")
    let result = [...new Set(arrVal)]
    let combined = arrfirs.push(...result);
    let buy_pt_id = buyingpoint
    let PNUT_TYPE_ID = getValue(thisObj, 'ddPeanutType')
    let agreestatus = agree_status
    let segtype = seg_type
    if (seg_type = '1') {
      bFillEdibleOilList(false)
    }
    let GetAccessLevel = await ContractManagementService.RetrieveAccessPermissionDetails('PN1130', null, 'D', buy_pt_id);
    if (GetAccessLevel.length > 0 && GetAccessLevel[0].permission == 'Y') {
      sPermission = "D";
    } else {
      let GetAccessU = await ContractManagementService.RetrieveAccessPermissionDetails('PN1130', null, 'U', buy_pt_id);
      if (GetAccessU.length > 0 && GetAccessU[0].permission == 'Y') {
        sPermission = "U";
      } else {
        let GetAccessI = await ContractManagementService.RetrieveAccessPermissionDetails('PN1130', null, 'I', buy_pt_id);
        if (GetAccessI.length > 0 && GetAccessI[0].permission == 'Y') {
          sPermission = "I";

        } else {
          showMessage(thisObj, "Invalid Permissions")
          return false;
        }
      }
    }
    let modify_tag = document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText

    if (agreestatus == "O") {
      disable(thisObj, 'btnDelete')
      disable(thisObj, 'txtAgreement')
      bFillPeanutVarietyList(buy_pt_id, PNUT_TYPE_ID, true)
      bFillPeanutTypeList(true); // Method for binding Peanut Type dropdown
      bFillSegmentList(true);
      enable(thisObj, 'txtAgreementDate')
      enable(thisObj, 'txtarComments')
      enable(thisObj, 'txtAgreedPounds')
      document.getElementById("ddGeneration").disabled = false;
      document.getElementById("ddOleic").disabled = false;
      document.getElementById("ddFrimBasisFlat").disabled = false;
      enable(thisObj, 'radioGradePricingMethod')
      enable(thisObj, "txtPrice")
      enable(thisObj, 'radioTradeSaleTradePurchase')
      enable(thisObj, 'radioGradePricingMethod')
      enable(thisObj, 'txtAgreedPounds')
      disable(thisObj, 'lblAppldPoundsVal')
      disable(thisObj, 'lblOpenBlncVal')
      enable(thisObj, 'txtarComments')
      disable(thisObj, 'txtAddedBy')
      disable(thisObj, 'txtChangedBy')
      for (let j = 0; j < arrfirs.length; j++) {
        let settxtvendor = "txtVendor" + (j + 1)
        let setremit = "ddRemitTo" + (j + 1)
        let setpercendate = "txtSharePercentage" + (j + 1)
        enable(thisObj, settxtvendor)
        enable(thisObj, setremit)
        enable(thisObj, setpercendate)
      }
      enable(thisObj, 'btn1')
      enable(thisObj, 'btn2')
      enable(thisObj, 'btn3')
      enable(thisObj, 'btn4')
      enable(thisObj, 'btn5')
      enable(thisObj, 'btn6')
      enable(thisObj, 'btn7')
      enable(thisObj, 'btn8')
      enable(thisObj, 'btn9')
      enable(thisObj, 'btn10')
      enable(thisObj, 'btn11')
      enable(thisObj, 'btn12')
      enable(thisObj, 'btn13')
      enable(thisObj, 'btn14')
      enable(thisObj, 'btn15')
      enable(thisObj, 'btn16')
      enable(thisObj, 'btn17')
      enable(thisObj, 'btn18')
      enable(thisObj, 'btn19')
      enable(thisObj, 'btn20')
      // document.getElementById("ddEdibleOil").disabled = true;
      // bFillEdibleOilList(segtype) // for disable the Editable/oil

    }
    let gridApplicationInfo = data
    if (document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText == "Update") {

      if (gridApplicationInfo.length > 0) {
        bFillBuyingPointList(false)
        bFillPeanutTypeList(false); // Method for binding Peanut Type dropdown
        bFillSegmentList(false); // Method for binding Segments
        bFillEdibleOilList(false)
        disable(thisObj, 'txtAgreement')
        disable(thisObj, 'txtAgreementDate')
        document.getElementById("ddGeneration").disabled = true;
        document.getElementById("ddOleic").disabled = true;
        document.getElementById("ddFrimBasisFlat").disabled = true;
        disable(thisObj, 'radioGradePricingMethod')
        disable(thisObj, "txtPrice")
        disable(thisObj, 'radioTradeSaleTradePurchase')
        disable(thisObj, 'radioGradePricingMethod')
        disable(thisObj, 'txtAgreedPounds')
        disable(thisObj, 'lblAppldPoundsVal')
        disable(thisObj, 'lblOpenBlncVal')
        disable(thisObj, 'txtarComments')
        disable(thisObj, 'txtAddedBy')
        disable(thisObj, 'txtChangedBy')
        for (let j = 0; j < arrfirs.length; j++) {
          let settxtvendor = "txtVendor" + (j + 1)
          let setremit = "ddRemitTo" + (j + 1)
          let setpercendate = "txtSharePercentage" + (j + 1)
          disable(thisObj, settxtvendor)
          enable(thisObj, setremit)
          disable(thisObj, setpercendate)
        }
        enable(thisObj, 'txtAgreedPounds')
        enable(thisObj, 'txtarComments')
        enable(thisObj, 'btnPrint')
      } else {
        bFillBuyingPointList(false)
        disable(thisObj, 'txtAgreement')
      }
      if (sPermission == "D") {
        if (gridApplicationInfo.length > 0) {
          disable(thisObj, 'btnDelete')
        }
        else {
          enable(thisObj, 'btnDelete')

        }
      }
    }
    if (agreestatus == "C") {
      bFillBuyingPointList(false)
      bFillPeanutTypeList(false); // Method for binding Peanut Type dropdown
      bFillSegmentList(false); // Method for binding Segments
      bFillEdibleOilList(false)
      disable(thisObj, 'txtAgreement')
      disable(thisObj, 'txtAgreementDate')
      bFillPeanutVarietyList(buy_pt_id, PNUT_TYPE_ID, false)
      document.getElementById("ddGeneration").disabled = true;
      document.getElementById("ddOleic").disabled = true;
      document.getElementById("ddFrimBasisFlat").disabled = true;
      disable(thisObj, 'radioGradePricingMethod')
      disable(thisObj, "txtPrice")
      disable(thisObj, 'radioTradeSaleTradePurchase')
      disable(thisObj, 'radioGradePricingMethod')
      disable(thisObj, 'txtAgreedPounds')
      disable(thisObj, 'lblAppldPoundsVal')
      disable(thisObj, 'lblOpenBlncVal')
      disable(thisObj, 'txtarComments')
      disable(thisObj, 'txtAddedBy')
      disable(thisObj, 'txtChangedBy')
      for (let j = 0; j < arrfirs.length; j++) {
        let settxtvendor = "txtVendor" + (j + 1)
        let setremit = "ddRemitTo" + (j + 1)
        let setpercendate = "txtSharePercentage" + (j + 1)
        disable(thisObj, settxtvendor)
        disable(thisObj, setremit)
        disable(thisObj, setpercendate)
      }
      disable(thisObj, 'btn1')
      disable(thisObj, 'btn2')
      disable(thisObj, 'btn3')
      disable(thisObj, 'btn4')
      disable(thisObj, 'btn5')
      disable(thisObj, 'btn6')
      disable(thisObj, 'btn7')
      disable(thisObj, 'btn8')
      disable(thisObj, 'btn9')
      disable(thisObj, 'btn10')
      disable(thisObj, 'btn11')
      disable(thisObj, 'btn12')
      disable(thisObj, 'btn13')
      disable(thisObj, 'btn14')
      disable(thisObj, 'btn15')
      disable(thisObj, 'btn16')
      disable(thisObj, 'btn17')
      disable(thisObj, 'btn18')
      disable(thisObj, 'btn19')
      disable(thisObj, 'btn20')
      disable(thisObj, 'txtarComments')
      disable(thisObj, 'txtAgreedPounds')
      enable(thisObj, 'btnClose')
      enable(thisObj, 'btnPrint')
      enable(thisObj, 'btnUpdate')
    }
    if (sPermission == "I") {
      disable(thisObj, 'btnDelete')
      disable(thisObj, 'btnPrint')
      disable(thisObj, 'btnUpdate')
    }

    setLoading(false)
  }
  const bFillVendorSplit = async (splitvendornumarr,datan,splitshareprct,splitremito) => {
    let data = datan[0]
    if (data.length > 4) {
      for (var i = 0; i < data.length; i++) {
        let newGrpbx = `grpbxVendorSplitRow` + i
        show(thisObj, newGrpbx)
      }
      setData(thisObj, "arrRow", data)
    }
    let addVendor = splitvendornumarr[0]
    let addsplitshareprct = splitshareprct[0]
    let addsplitremito = splitremito[0]
    for (var i = 0; i < data.length; i++) {
      let newVendor = 'txtVendor' + (i + 1)
      let newLblxx = 'lblXX' + (i + 1)
      let newRemitTo = 'ddRemitTo' + (i + 1)
      let newshare = 'txtSharePercentage' + (i + 1)
      setValue(thisObj, newVendor, [addVendor[i]])
      setValue(thisObj, newshare, addsplitshareprct[i])
      RemitDetails(addVendor[i], newRemitTo, newLblxx, addsplitremito[i])
    }
   
  }
  const bFillForm = async (Agreenum) => {
    let BUY_PT_ID = getValue(thisObj, 'ddLocation')
    let RetrieveTradeAgreementDetails = await SettlementService.RetrieveTradeAgreementDetails('PN1130B', "", BUY_PT_ID, Agreenum, "", "", "", "", "", "", "", "", "N", "");
    let objVal = {}
    if (RetrieveTradeAgreementDetails.length > 0) {
      const splitvendornumarr = [];
      const datan = []
      const splitshareprct = [];
      const splitremito = [];
      splitvendornumarr.push(RetrieveTradeAgreementDetails[0].split_vendor.split('|'))
      datan.push(RetrieveTradeAgreementDetails[0].split_vendor.split('|'))
      splitshareprct.push(RetrieveTradeAgreementDetails[0].split_share_pct.split('|'))
      splitremito.push(RetrieveTradeAgreementDetails[0].split_remit.split('|'))
      let value = RetrieveTradeAgreementDetails[0].buy_pt_id
      objVal['txtAgreement'] = RetrieveTradeAgreementDetails[0].agreement_num
      objVal['ddLocation'] = value
      objVal['ddAgreementStatus'] = RetrieveTradeAgreementDetails[0].agree_status
      objVal['txtAgreementDate'] = RetrieveTradeAgreementDetails[0].agree_date_time
      objVal['ddPeanutType'] = RetrieveTradeAgreementDetails[0].pnut_type_id
      objVal['ddPeanutVariety'] = RetrieveTradeAgreementDetails[0].pnut_variety_id
      objVal['ddGeneration'] = RetrieveTradeAgreementDetails[0].seed_gen
      objVal['ddSeg'] = RetrieveTradeAgreementDetails[0].seg_type
      objVal['ddOleic'] = RetrieveTradeAgreementDetails[0].oleic_ind
      objVal['ddEdibleOil'] = RetrieveTradeAgreementDetails[0].edible_oil_ind
      objVal['radioGradePricingMethod'] = RetrieveTradeAgreementDetails[0].grade_pricing_method
      // setValue(thisObj, 'txtAgreement', RetrieveTradeAgreementDetails[0].agreement_num)
      // setValue(thisObj, 'ddLocation', value)
      // setValue(thisObj, 'ddAgreementStatus', RetrieveTradeAgreementDetails[0].agree_status)
      // setValue(thisObj, 'txtAgreementDate', RetrieveTradeAgreementDetails[0].agree_date_time)
      // setValue(thisObj, 'ddPeanutType', RetrieveTradeAgreementDetails[0].pnut_type_id)
      // setValue(thisObj, 'ddPeanutVariety', RetrieveTradeAgreementDetails[0].pnut_variety_id)
      // setValue(thisObj, 'ddGeneration', RetrieveTradeAgreementDetails[0].seed_gen)
      // setValue(thisObj, 'ddSeg', RetrieveTradeAgreementDetails[0].seg_type)
      // setValue(thisObj, 'ddOleic', RetrieveTradeAgreementDetails[0].oleic_ind)
      // setValue(thisObj, 'ddEdibleOil', RetrieveTradeAgreementDetails[0].edible_oil_ind)
      // setValue(thisObj, 'radioGradePricingMethod', RetrieveTradeAgreementDetails[0].grade_pricing_method)
      
      if (RetrieveTradeAgreementDetails[0].grade_pricing_method == "FULL") {
        let txtpricefloat = parseFloat(RetrieveTradeAgreementDetails[0].price_per_ton).toFixed(2)
        let txtnumer = txtpricefloat !== NaN ? txtpricefloat : ""
        objVal['txtPrice'] = txtnumer
        // setValue(thisObj, "txtPrice", txtnumer)
      } else {
        let txtpricefloat = parseFloat(RetrieveTradeAgreementDetails[0].price_per_lbs).toFixed(7)
        let txtnumer = txtpricefloat !== NaN ? txtpricefloat : ""
        objVal['txtPrice'] = txtnumer
        // setValue(thisObj, "txtPrice", txtnumer)
      }
      objVal['radioTradeSaleTradePurchase'] = RetrieveTradeAgreementDetails[0].purch_sale_ind
      objVal['ddFrimBasisFlat'] = RetrieveTradeAgreementDetails[0].firm_basis_opt
      // setValue(thisObj, 'radioTradeSaleTradePurchase', RetrieveTradeAgreementDetails[0].purch_sale_ind)
      // setValue(thisObj, 'ddFrimBasisFlat', RetrieveTradeAgreementDetails[0].firm_basis_opt)
      if (RetrieveTradeAgreementDetails[0].firm_basis_opt == "F") {
        show(thisObj, 'radioGradePricingMethod')
      }
      objVal['txtAgreedPounds'] = RetrieveTradeAgreementDetails[0].agreed_lbs
      objVal['lblAppldPoundsVal'] = RetrieveTradeAgreementDetails[0].delivered_lbs
      // setValue(thisObj, 'txtAgreedPounds', RetrieveTradeAgreementDetails[0].agreed_lbs)
      // setValue(thisObj, 'lblAppldPoundsVal', RetrieveTradeAgreementDetails[0].delivered_lbs)
      let txtAgreed_Pounds = parseInt(RetrieveTradeAgreementDetails[0].agreed_lbs)
      let lblAppldPounds_Val = parseInt(RetrieveTradeAgreementDetails[0].delivered_lbs)
      let lblOpenBlncVal = txtAgreed_Pounds - lblAppldPounds_Val
      if (lblOpenBlncVal == 0) {
        objVal['lblOpenBlncVal'] = '0'
        // setValue(thisObj, 'lblOpenBlncVal', '0')
      } else {
        objVal['lblOpenBlncVal'] = lblOpenBlncVal
        // setValue(thisObj, 'lblOpenBlncVal', lblOpenBlncVal)
      }
      objVal['txtarComments'] = RetrieveTradeAgreementDetails[0].remarks
      objVal['txtAddedBy'] = RetrieveTradeAgreementDetails[0].add_user + " " + moment(String(RetrieveTradeAgreementDetails[0].add_date_time)).format('MM/DD/YYYY hh:mm:ss A')
      objVal['txtChangedBy'] = RetrieveTradeAgreementDetails[0].chg_user + " " + moment(String(RetrieveTradeAgreementDetails[0].chg_date_time)).format('MM/DD/YYYY hh:mm:ss A')
      // setValue(thisObj, 'txtarComments', RetrieveTradeAgreementDetails[0].remarks)
      // setValue(thisObj, 'txtAddedBy', RetrieveTradeAgreementDetails[0].add_user + " " + moment(String(RetrieveTradeAgreementDetails[0].add_date_time)).format('MM/DD/YYYY hh:mm:ss A'))
      // setValue(thisObj, 'txtChangedBy', RetrieveTradeAgreementDetails[0].chg_user + " " + moment(String(RetrieveTradeAgreementDetails[0].chg_date_time)).format('MM/DD/YYYY hh:mm:ss A'))
      setFieldValues(thisObj, objVal, true)
      bFillVendorSplit(splitvendornumarr,datan,splitshareprct,splitremito)
      bFillPeanutVarietyList(RetrieveTradeAgreementDetails[0].buy_pt_id, RetrieveTradeAgreementDetails[0].pnut_type_id, true)
      let RetrieveTradeAgreementApplicationDetails = await SettlementService.RetrieveTradeAgreementApplicationDetails(Agreenum, "N", RetrieveTradeAgreementDetails[0].buy_pt_id);
      let data = RetrieveTradeAgreementApplicationDetails;
      let js = [];
      let obj = {}
      if (data.length > 0) {

        for (var j = 0; j < data.length; j++) {
          obj.btnPopup = "...",
            obj.txtcolLoc = data[j].buy_pt_id;
          obj.txtcolTrdInsp = data[j].audit_trade_insp;
          obj.txtcolTrdSettle = data[j].audit_trade_settle;
          obj.txtcolShippdDt = data[j].shp_date;
          obj.txtcolRcvdDt = data[j].rec_date;
          obj.txtcolAppldLbs = data[j].delivered_lbs_adj;
          obj.txtcolSegVal = data[j].agree_value;
          js.push(obj)
          obj = {}
        }
        setValue(thisObj, "gridApplicationInfo", js)
      }
      else {
        setValue(thisObj, "gridApplicationInfo", [])
      }
      EnableDisableControls(RetrieveTradeAgreementDetails[0].buy_pt_id, RetrieveTradeAgreementDetails[0].agree_status, RetrieveTradeAgreementDetails[0].seg_type, js)
    }
  }
  const AddUpdateDetails = async (data) => {
    let AddUpdateDetails = data.length > 0 ? data : getData(thisObj, 'CreateValue');
    if (AddUpdateDetails !== null) {
      let data = AddUpdateDetails;
      document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText = data[0].UpdateBtnCaption;
      let UpdateBtnEnabledDelete = data[0].UpdateBtnEnabledDelete
      buttonname = data[0].UpdateBtnCaption
      if (UpdateBtnEnabledDelete == "False") {
        disable(thisObj, 'btnDelete')
      } else if (UpdateBtnEnabledDelete == "True") {
        enable(thisObj, 'btnDelete')
      }
      let UpdateBtnEnabledPrint = data[0].UpdateBtnEnabledPrint
      if (UpdateBtnEnabledPrint == "True") {
        enable(thisObj, 'btnPrint')
      } else if (UpdateBtnEnabledPrint == "False") {
        disable(thisObj, 'btnPrint')
      }
      if (data[0].UpdateBtnCaption == "Update") {
        bFillForm(data[0].Agreenum)
        // bFillVendorSplit(data[0].Agreenum)
      } else {
        setValue(thisObj, 'ddAgreementStatus', 'O');
        setValue(thisObj, 'ddEdibleOil', 'E');
        setValue(thisObj, 'ddOleic', '');
        setValue(thisObj, 'ddPeanutVariety', '');
        setValue(thisObj, 'ddSeg', '1');
        setValue(thisObj, 'ddPeanutType', '');
        setValue(thisObj, 'ddLocation', '');
        setValue(thisObj, 'radioTradeSaleTradePurchase', 'P')
        setTimeout(() => {
          EnableDisableControls('', "O", '1', [])
        }, 1000)

      }
    }
  }
  const RemitDetails = (vendornum, Remit, newLblxx, addsplitremito) => {
    setLoading(true)
    ContractManagementService.RetieveRemitDetails(vendornum).then(response => {
      let data = response;
      if (data.length > 0) {
        let js = [];
        let lblXXname = data[0].name

        for (var i = 0; i < data.length; i++) {
          let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].address1 + '-' + data[i].city;
          let obj = { key: data[i].remittoid, description: description };
          js.push(obj);
        }
        setValue(thisObj, newLblxx, lblXXname)
        thisObj.state[Remit].valueList = js;
        setValue(thisObj, Remit, addsplitremito);
      }
      setLoading(false)
    });
  };
  // START_USER_CODE-USER_METHODS
  // Method for binding Buying Points
  const bFillBuyingPointList = (EnabledTF) => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1130', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js,
            Enabled: EnabledTF
          }
        }
      })
    })
  }
  //Method for binding Peanut Type dropdown
  const bFillPeanutTypeList = (EnabledTF) => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
            Enabled: EnabledTF
          }
        }
      })
    })

  }

  // Method for binding Segment list  
  const bFillSegmentList = (EnabledTF) => {
    let js = []
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js,
          Enabled: EnabledTF
        }
      }
    })

  }
  const bFillPeanutVarietyList = async (buyingPointId, pnut_type_id, EnabledTF) => {
    let js = [];
    let obj = {}
    obj = { key: "", description: ">>> All Varieties <<<" }
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      let varietyData = await ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null);
      if (varietyData.length > 0) {
        for (var i = 0; i < varietyData.length; i++) {
          let dataExist = js.find(elem => elem.key == varietyData[i].pnut_variety_id);
          if (dataExist == undefined) {
            obj = { key: varietyData[i].pnut_variety_id, description: varietyData[i].pnut_variety_name }
            if (varietyData[i].symbol_ind == "TRADEMARK") {
              obj = { key: varietyData[i].pnut_variety_id, description: varietyData[i].pnut_variety_name + 'ᵀᴹ' }
            }
            if (varietyData[i].symbol_ind == "REGISTERED TRADEMARK") {
              obj = { key: varietyData[i].pnut_variety_id, description: varietyData[i].pnut_variety_name + '®' }
            }
            if (varietyData[i].symbol_ind == "COPYRIGHT") {
              obj = { key: varietyData[i].pnut_variety_id, description: varietyData[i].pnut_variety_name + '©' }
            }
            js.push(obj);
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js,
            Enabled: EnabledTF
          }
        }
      });
    }
  }
 
  // Method for binding Oleic values
  const bFillOleicList = () => {
    let js = []
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: '', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })

  }
  const bFillagreestatus = () => {
    let js = []
    js.push({ key: 'O', description: 'OPEN' })
    js.push({ key: 'C', description: 'COMPLETE' })
    thisObj.setState(current => {
      return {
        ...current,
        ddAgreementStatus: {
          ...state["ddAgreementStatus"],
          valueList: js
        }
      }
    })

  }
  // Method for binding Oleic values
  const bFillTypeList = () => {
    let js = []
    js.push({ key: 'F', description: 'FIRM' })
    js.push({ key: 'B', description: 'BASIS' })
    js.push({ key: 'L', description: 'FLAT' })
    thisObj.setState(current => {
      return {
        ...current,
        ddFrimBasisFlat: {
          ...state["ddFrimBasisFlat"],
          valueList: js
        }
      }
    })
  }

  // Method for binding Seed Grower List
  const bFillEdibleOilList = (EnabledTF) => {
    let js = []
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })

    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js,
          Enabled: EnabledTF
        },
      }
    });
  }

  // Method for binding generation List
  const bFillGeneration = () => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
  }

  const onbtnPrintClick = async () => {
    let agreenumer = getValue(thisObj, 'txtAgreement')
    let BUY_PT_ID = getValue(thisObj, 'ddLocation')
    if(mbFormDirty == true){
    if (confirm("Changes have not been saved. Changes will not be reflected in print out. Do you wish to continue printing?") == true) {
      let js = [{
        GstrCompanyID: compIdFromLS,
        GstrCropYear: cropYearFromLS,
        lstBuyingPointID: BUY_PT_ID,
        Txt_Agree: agreenumer,
        ReportType: 'TRADE_AGREE_WORKSHEET'

      }]

      setData(thisObj, 'ReportPreviewTradedata', js)
      goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click");
      //  frmReportPreviewTrade.rptTradeAgreementWorksheet GstrCompanyID, GstrCropYear, lstBuyingPointID.List(cboBuyingPoint.ListIndex), Trim(Txt_Agree.Text)
    }}else{
      
      let js = [{
        GstrCompanyID: compIdFromLS,
        GstrCropYear: cropYearFromLS,
        lstBuyingPointID: BUY_PT_ID,
        Txt_Agree: agreenumer,
        ReportType: 'TRADE_AGREE_WORKSHEET'

      }]

      setData(thisObj, 'ReportPreviewTradedata', js)
      goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click");
    }
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;


  const dateIsValid = (dateEntered) => {
    let datavaild = ''
    if (dateEntered !== "" && dateEntered !== 'Invalid date' && dateEntered !== null && dateEntered !== undefined) {
      datavaild = moment(String(dateEntered)).format('MM/DD/YYYY')
      return datavaild
    } else {
      return datavaild
    }
  }
  const validationtaxvendor = (ven1, ven2, ven3, ven4, share1, share2, share3, share4) => {
    let datavaild = true
    if (ven1 !== "" && share1 == '') {
      datavaild = false
      return datavaild
    } else if (ven2 !== "" && share2 == '') {
      datavaild = false
      return datavaild
    } else if (ven3 !== "" && share3 == '') {
      datavaild = false
      return datavaild
    }
    else if (ven4 !== "" && share4 == '') {
      datavaild = false
      return datavaild
    } else {
      return datavaild
    }

  }
  const validationshare = (share1, share2, share3, share4) => {
    let datavaild = true
    if (share1 == '0.00') {
      datavaild = false
      return datavaild
    } else if (share2 == '0.00') {
      datavaild = false
      return datavaild
    } else if (share3 == '0.00') {
      datavaild = false
      return datavaild
    }
    else if (share4 == '0.00') {
      datavaild = false
      return datavaild
    } else {
      return datavaild
    }

  }
  const validationshare100 = (share1, share2, share3, share4) => {
    let datavaild = true
    if (parseInt(share1) > 100) {
      datavaild = false
      return datavaild
    } else if (parseInt(share2) > 100) {
      datavaild = false
      return datavaild
    } else if (parseInt(share3) > 100) {
      datavaild = false
      return datavaild
    }
    else if (parseInt(share4) > 100) {
      datavaild = false
      return datavaild
    } else {
      return datavaild
    }

  }
  const updateapi = async (modify_tag, agreenumer, vendordata) => {
    let BUY_PT_ID = getValue(thisObj, 'ddLocation')
    let radioTradeSaleTradePurchase_ = thisObj.values.radioTradeSaleTradePurchase
    let PNUT_TYPE_ID = getValue(thisObj, 'ddPeanutType')
    let PNUT_VARIETY_ID = getValue(thisObj, 'ddPeanutVariety')
    let SEG_TYPE = getValue(thisObj, 'ddSeg')
    let FIRM_BASIS_OPT = getValue(thisObj, 'ddFrimBasisFlat')
    let txtpricev = getValue(thisObj, 'txtPrice')
    let radioGradePricingMethod_ = thisObj.values.radioGradePricingMethod
    let AGREED_LBS = getValue(thisObj, 'txtAgreedPounds')
    let DELIVERED_LBS = getValue(thisObj, 'lblAppldPoundsVal')
    let AGREE_DATE_TIME = dateIsValid(getValue(thisObj, 'txtAgreementDate'))
    let useridFromLS = sessionStorage.getItem('userid')
    let PURCH_SALE_IND = ''
    if (radioTradeSaleTradePurchase_ == "P") {
      PURCH_SALE_IND = "P"
    } else {
      PURCH_SALE_IND = "S"
    }
    let AgreementStatus = getValue(thisObj, 'ddAgreementStatus');
    let AGREE_STATUS = ''
    if (AgreementStatus == "OPEN") {
      AGREE_STATUS = "O"
    } else {
      AGREE_STATUS = "C"
    }
    let SEED_GEN = getValue(thisObj, 'ddGeneration')
    let OLEIC_IND = getValue(thisObj, 'ddOleic')
    let lstEdibleOil = getValue(thisObj, 'ddEdibleOil')
    let GRADEPRICINGMETHOD = thisObj.values.radioGradePricingMethod
    let GRADE_PRICING_METHOD = '', PRICE_PER_TON = 0, PRICE_PER_LBS = 0
    let AGREE_REMARK = getValue(thisObj, 'txtarComments')

    if (GRADEPRICINGMETHOD == "FULL") {
      GRADE_PRICING_METHOD = "FULL"
      PRICE_PER_TON = (parseFloat(txtpricev)).toFixed(2)
      PRICE_PER_LBS = parseFloat(txtpricev) / 2000
    } else {
      GRADE_PRICING_METHOD = "TKC"
      PRICE_PER_TON = parseFloat(txtpricev) * 2000
      PRICE_PER_LBS = (parseFloat(txtpricev)).toFixed(7)
    }
    let objSave =
    {
      "purch_sale_ind": PURCH_SALE_IND,
      "agree_date_time": AGREE_DATE_TIME !== '' ? AGREE_DATE_TIME: null,
      "buy_pt_id": BUY_PT_ID,
      "pnut_type_id": PNUT_TYPE_ID,
      "pnut_variety_id": PNUT_VARIETY_ID,
      "seed_gen": SEED_GEN !== undefined ? SEED_GEN : "",
      "seg_type": SEG_TYPE,
      "oleic_ind": OLEIC_IND,
      "edible_oil_ind": lstEdibleOil,
      "grade_pricing_method": radioGradePricingMethod_,
      "firm_basis_opt": FIRM_BASIS_OPT,
      "agreed_lbs": AGREED_LBS,
      "delivered_lbs": DELIVERED_LBS,
      "price_per_ton": PRICE_PER_TON,
      "price_per_lbs": PRICE_PER_LBS,
      "agree_remark": AGREE_REMARK !== undefined ? AGREE_REMARK : "",
      "agree_status": AgreementStatus !== undefined ? AgreementStatus : "",
      "whse_rcpt_num": "",
      "split_vendor1": vendordata[0].txtVendor1 !== '' ? vendordata[0].txtVendor1 : null,
      "split_remit1": vendordata[0].ddRemitTo1 !== '' ? vendordata[0].ddRemitTo1 : "",
      "split_share_pct1": vendordata[0].txtSharePercentage1 !== '' ? parseFloat(vendordata[0].txtSharePercentage1) : 0,
      "split_vendor2": vendordata[0].txtVendor2 !== '' ? vendordata[0].txtVendor2 : null,
      "split_remit2": vendordata[0].ddRemitTo2 !== '' ? vendordata[0].ddRemitTo2 : "",
      "split_share_pct2": vendordata[0].txtSharePercentage2 !== '' ? parseFloat(vendordata[0].txtSharePercentage2) : 0,
      "split_vendor3": vendordata[0].txtVendor3 !== '' ? vendordata[0].txtVendor3 : null,
      "split_remit3": vendordata[0].ddRemitTo3 !== '' ? vendordata[0].ddRemitTo3 : "",
      "split_share_pct3": vendordata[0].txtSharePercentage3 !== '' ? parseFloat(vendordata[0].txtSharePercentage3) : 0,
      "split_vendor4": vendordata[0].txtVendor4 !== '' ? vendordata[0].txtVendor4 : null,
      "split_remit4": vendordata[0].ddRemitTo4 !== '' ? vendordata[0].ddRemitTo4 : "",
      "split_share_pct4": vendordata[0].txtSharePercentage4 !== '' ? parseFloat(vendordata[0].txtSharePercentage4) : 0,
      "user_id": useridFromLS,
      "split_vendor5": vendordata[0].txtVendor5 !== '' ? vendordata[0].txtVendor5 : null,
      "split_remit5": vendordata[0].ddRemitTo5 !== '' ? vendordata[0].ddRemitTo5 : "",
      "split_share_pct5": vendordata[0].txtSharePercentage5 !== '' ? parseFloat(vendordata[0].txtSharePercentage5) : 0,
      "split_vendor6": vendordata[0].txtVendor6 !== '' ? vendordata[0].txtVendor6 : null,
      "split_remit6": vendordata[0].ddRemitTo6 !== '' ? vendordata[0].ddRemitTo6 : "",
      "split_share_pct6": vendordata[0].txtSharePercentage6 !== '' ? parseFloat(vendordata[0].txtSharePercentage6) : 0,
      "split_vendor7": vendordata[0].txtVendor7 !== '' ? vendordata[0].txtVendor7 : null,
      "split_remit7": vendordata[0].ddRemitTo7 !== '' ? vendordata[0].ddRemitTo7 : "",
      "split_share_pct7": vendordata[0].txtSharePercentage7 !== '' ? parseFloat(vendordata[0].txtSharePercentage7) : 0,
      "split_vendor8": vendordata[0].txtVendor8 !== '' ? vendordata[0].txtVendor8 : null,
      "split_remit8": vendordata[0].ddRemitTo8 !== '' ? vendordata[0].ddRemitTo8 : "",
      "split_share_pct8": vendordata[0].txtSharePercentage8 !== '' ? parseFloat(vendordata[0].txtSharePercentage8) : 0,
      "split_vendor9": vendordata[0].txtVendor9 !== '' ? vendordata[0].txtVendor9 : null,
      "split_remit9": vendordata[0].ddRemitTo9 !== '' ? vendordata[0].ddRemitTo9 : "",
      "split_share_pct9": vendordata[0].txtSharePercentage9 !== '' ? parseFloat(vendordata[0].txtSharePercentage9) : 0,
      "split_vendor10": vendordata[0].txtVendor10 !== '' ? vendordata[0].txtVendor10 : null,
      "split_remit10": vendordata[0].ddRemitTo10 !== '' ? vendordata[0].ddRemitTo10 : "",
      "split_share_pct10": vendordata[0].txtSharePercentage10 !== '' ? parseFloat(vendordata[0].txtSharePercentage10) : 0,
      "split_vendor11": vendordata[0].txtVendor11 !== '' ? vendordata[0].txtVendor11 : null,
      "split_remit11": vendordata[0].ddRemitTo11 !== '' ? vendordata[0].ddRemitTo11 : "",
      "split_share_pct11": vendordata[0].txtSharePercentage11 !== '' ? parseFloat(vendordata[0].txtSharePercentage11) : 0,
      "split_vendor12": vendordata[0].txtVendor12 !== '' ? vendordata[0].txtVendor12 : null,
      "split_remit12": vendordata[0].ddRemitTo12 !== '' ? vendordata[0].ddRemitTo12 : "",
      "split_share_pct12": vendordata[0].txtSharePercentage12 !== '' ? parseFloat(vendordata[0].txtSharePercentage12) : 0,
      "split_vendor13": vendordata[0].txtVendor13 !== '' ? vendordata[0].txtVendor13 : null,
      "split_remit13": vendordata[0].ddRemitTo13 !== '' ? vendordata[0].ddRemitTo13 : "",
      "split_share_pct13": vendordata[0].txtSharePercentage13 !== '' ? parseFloat(vendordata[0].txtSharePercentage13) : 0,
      "split_vendor14": vendordata[0].txtVendor14 !== '' ? vendordata[0].txtVendor14 : null,
      "split_remit14": vendordata[0].ddRemitTo14 !== '' ? vendordata[0].ddRemitTo114 : "",
      "split_share_pct14": vendordata[0].txtSharePercentage14 !== '' ? parseFloat(vendordata[0].txtSharePercentage14) : 0,
      "split_vendor15": vendordata[0].txtVendor15 !== '' ? vendordata[0].txtVendor15 : null,
      "split_remit15": vendordata[0].ddRemitTo15 !== '' ? vendordata[0].ddRemitTo15 : "",
      "split_share_pct15": vendordata[0].txtSharePercentage15 !== '' ? parseFloat(vendordata[0].txtSharePercentage15) : 0,
      "split_vendor16": vendordata[0].txtVendor16 !== '' ? vendordata[0].txtVendor16 : null,
      "split_remit16": vendordata[0].ddRemitTo16 !== '' ? vendordata[0].ddRemitTo16 : "",
      "split_share_pct16": vendordata[0].txtSharePercentage16 !== '' ? parseFloat(vendordata[0].txtSharePercentage16) : 0,
      "split_vendor17": vendordata[0].txtVendor17 !== '' ? vendordata[0].txtVendor17 : null,
      "split_remit17": vendordata[0].ddRemitTo17 !== '' ? vendordata[0].ddRemitTo17 : "",
      "split_share_pct17": vendordata[0].txtSharePercentage17 !== '' ? parseFloat(vendordata[0].txtSharePercentage17) : 0,
      "split_vendor18": vendordata[0].txtVendor18 !== '' ? vendordata[0].txtVendor18 : null,
      "split_remit18": vendordata[0].ddRemitTo18 !== '' ? vendordata[0].ddRemitTo18 : "",
      "split_share_pct18": vendordata[0].txtSharePercentage18 !== '' ? parseFloat(vendordata[0].txtSharePercentage18) : 0,
      "split_vendor19": vendordata[0].txtVendor19 !== '' ? vendordata[0].txtVendor19 : null,
      "split_remit19": vendordata[0].ddRemitTo19 !== '' ? vendordata[0].ddRemitTo19 : "",
      "split_share_pct19": vendordata[0].txtSharePercentage19 !== '' ? parseFloat(vendordata[0].txtSharePercentage19) : 0,
      "split_vendor20": vendordata[0].txtVendor20 !== '' ? vendordata[0].txtVendor20 : null,
      "split_remit20": vendordata[0].ddRemitTo20 !== '' ? vendordata[0].ddRemitTo20 : "",
      "split_share_pct20": vendordata[0].txtSharePercentage20 !== '' ? parseFloat(vendordata[0].txtSharePercentage20) : 0,
      "user_id": useridFromLS,
      "delv_agree_dol": 0,
      "delv_app_dol": 0,
      "adjust_lbs": 0,
      "rcpt_value": 0,
      "rcpt_value_override_ind": ""
    }
    let addUpdateTradeAgreement = await SettlementService.UpdateTradeAgreement(modify_tag.toUpperCase(), agreenumer, "", objSave);

    if (addUpdateTradeAgreement.status == 200 && modify_tag == "Add") {
      let js = [{
        GstrCompanyID: compIdFromLS,
        GstrCropYear: cropYearFromLS,
        lstBuyingPointID: BUY_PT_ID,
        Txt_Agree: addUpdateTradeAgreement.result,
        ReportType: 'TRADE_AGREE_WORKSHEET'
      }]
      setData(thisObj, 'ReportPreviewTradedata', js)
      alert('Trade Agreement Add successful')
      let Obj = {}
      Obj.Agreenum = addUpdateTradeAgreement.result;
      Obj.GstrEditMode = "1";
      Obj.UpdateBtnCaption = "Update";
      Obj.UpdateBtnlblStatus = "Update";
      Obj.UpdateBtnEnabledDelete = "True";
      Obj.UpdateBtnEnabledPrint = "True";
      let data = [];
      data.push(Obj);
      document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText = 'Update';
      AddUpdateDetails(data)
      setData(thisObj, "CreateValue", data)
      let jsn = [{
        lbl_firstload: "D"
      }]
      setData(thisObj, 'Tradeagreesearchdata', jsn)
      goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click");
    } else if (addUpdateTradeAgreement.status == 200 && modify_tag == "Update") {
      let griddata = getValue(thisObj, "gridApplicationInfo")
      EnableDisableControls(BUY_PT_ID, AgreementStatus, SEG_TYPE, griddata)
      // FormLoad()
      alert('Trade Agreement Update successful')
      let js = [{
        lbl_firstload: "D"
      }]
      setData(thisObj, 'Tradeagreesearchdata', js)
      
    } else if (addUpdateTradeAgreement.status == 200 && modify_tag == "DELETE") {
      let js = [{
        lbl_firstload: "D"
      }]
      setData(thisObj, 'Tradeagreesearchdata', js)
      alert('Trade Agreement Delete successful')
      document.getElementById("Settlements_TradeAgreeProfilePopUp").childNodes[0].click();
    } else {
      alert(`An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n Error  \n ${addUpdateTradeAgreement.message}`)
    }


  }
  const bFormValid = async () => {
    let arrfirs = [0, 1, 2, 3]
    let arrVal = getData(thisObj, "arrRow")
    let result = [...new Set(arrVal)]
    let vendorsplitall = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    let combined = arrfirs.push(...result);
    var rv = {};
    let txvendorch = true, txsharepercheck = true, txshareper00check = true, txshareper100check = true,
      lblGrandTotal1 = 0.00,
      txtSharePercen111 = 0.00

    for (let j = 0; j < vendorsplitall.length; j++) {
      let settxtvendor = "txtVendor" + (j + 1)
      let setremit = "ddRemitTo" + (j + 1)
      let setpercendate = "txtSharePercentage" + (j + 1)
      let gettxvendor = '', getremit = '', getpercentage = ''
      gettxvendor = getValue(thisObj, settxtvendor)
      getremit = getValue(thisObj, setremit)
      getpercentage = getValue(thisObj, setpercendate)
      rv[settxtvendor] = gettxvendor.toString() !== undefined ? gettxvendor.toString() : ''
      rv[setremit] = getremit !== undefined ? getremit : ''
      rv[setpercendate] = getpercentage !== undefined ? getpercentage : ''
      let shjjjj = rv[setpercendate] !== "" ? parseFloat(rv[setpercendate]) : 0.00
      txtSharePercen111 = shjjjj + txtSharePercen111
      lblGrandTotal1 = txtSharePercen111
      if (rv[settxtvendor].length > 0) {
        txvendorch = false
      }
      if (rv[settxtvendor] > 0 && rv[setpercendate] == "") {
        txsharepercheck = false
      }
      if (rv[setpercendate] == "0.00") {
        txshareper00check = false
      }
      if (rv[setpercendate] > 100) {
        txshareper100check = false
      }
    }
    let modify_tag = document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText
    let agreenumer = getValue(thisObj, 'txtAgreement')
    let BUY_PT_ID = getValue(thisObj, 'ddLocation')
    let radioTradeSaleTradePurchase_ = thisObj.values.radioTradeSaleTradePurchase
    let PNUT_TYPE_ID = getValue(thisObj, 'ddPeanutType')
    let SEG_TYPE = getValue(thisObj, 'ddSeg')
    let FIRM_BASIS_OPT = getValue(thisObj, 'ddFrimBasisFlat')
    let txtpricev = getValue(thisObj, 'txtPrice')
    let radioGradePricingMethod_ = thisObj.values.radioGradePricingMethod
    let AGREED_LBS = getValue(thisObj, 'txtAgreedPounds')
    let DELIVERED_LBS = getValue(thisObj, 'lblAppldPoundsVal')
    let AGREE_DATE_TIME = getValue(thisObj, 'txtAgreementDate')
    let setdtagreeDate = moment(getValue(thisObj, 'txtAgreementDate')).format('MM/DD/YYYY');
    let datejun = "06/01/" + (cropYearFromLS);

    let PURCH_SALE_IND = ''
    if (radioTradeSaleTradePurchase_ == "P") {
      PURCH_SALE_IND = "P"
    } else {
      PURCH_SALE_IND = "S"
    }
    let AgreementStatus = getValue(thisObj, 'ddAgreementStatus');
    let AGREE_STATUS = ''
    if (AgreementStatus == "OPEN") {
      AGREE_STATUS = "O"
    } else {
      AGREE_STATUS = "C"
    }
    
    let GRADEPRICINGMETHOD = thisObj.values.radioGradePricingMethod

    if (BUY_PT_ID == "") {
      showMessage(thisObj, "Trade Agreement Location must be selected");
    }
    else if (dateIsValid(AGREE_DATE_TIME) == '') {
      showMessage(thisObj, 'Invalid Date!!!');
    }
    else if (new Date(setdtagreeDate) < new Date(datejun)) {
      showMessage(thisObj, "Date cannot be less than June 1 of current crop year!!!", false);
      return;
    }
    else if (radioTradeSaleTradePurchase_ == undefined) {
      showMessage(thisObj, "Incorrect Data. Trade Sale or Trade Purchase option must be selected!");
    } else if (PNUT_TYPE_ID == "") {
      showMessage(thisObj, "Please Select a Peanut Type!!!");

    }
    //  else if (PNUT_VARIETY_ID == "") {
    //   showMessage(thisObj, "Please Select a Peanut Variety!!!");
    // }
    else if (SEG_TYPE == "") {
      showMessage(thisObj, "Please Select a Segment!!!");
    } else if (FIRM_BASIS_OPT !== "F" && GRADEPRICINGMETHOD == "TKC") {
      showMessage(thisObj, "TKC pricing apply only to FIRM agreements!!!");
    } else if (GRADEPRICINGMETHOD == undefined) {
      showMessage(thisObj, "Incorrect Data. Grade Pricing method must be selected!");
    }
    else if (FIRM_BASIS_OPT == undefined) {
      showMessage(thisObj, "A Pricing Type must be selected!!!");
    } 
    else if (txtpricev == null || txtpricev == undefined || txtpricev == "") {
      showMessage(thisObj, "An Agreement Price must be entered.");
    }
    else if (AGREED_LBS == undefined) {
      showMessage(thisObj, "Agreed pounds must be entered!!!");
    }
    else if (parseInt(AGREED_LBS) < parseInt(DELIVERED_LBS)) {
      showMessage(thisObj, "Incorrect Data. Agreed pounds less than Applied pounds!");
    }
    else if (txvendorch == true) {
      showMessage(thisObj, 'At least one valid vendor is required!!!');
    } else if (txsharepercheck == false) {
      showMessage(thisObj, 'Vendor share must be of format ###.##!!!');
    } else if (txshareper00check == false) {
      showMessage(thisObj, 'Vendor share must be greater than 0!!!');
    }
    else if (txshareper100check == false) {
      showMessage(thisObj, 'Vendor share must be less than 100!!!');
    }
    else if (lblGrandTotal1 !== 100) {
      showMessage(thisObj, `Total of vendor shares ${lblGrandTotal1} ` + 'does not equal 100!!!')
    } 
    else if (GRADEPRICINGMETHOD == "FULL" && txtpricev > 9999.99) {
      showMessage(thisObj, "For Full, the Agreement Price must be of format ####.##");
    } else if (GRADEPRICINGMETHOD == "TKC" && txtpricev > 99.9999999) {
      showMessage(thisObj, "For TKC, the Agreement Price must be of format ##.#######");
    } else if (GRADEPRICINGMETHOD == "TKC" && txtpricev > 4.9999974) {
      showMessage(thisObj, "For TKC, the Agreement Price must entered be in cents per pound.");
    }
    else {
      updateapi(modify_tag, agreenumer, [rv])
    }
  }
  const onbtnUpdateClick = async () => {
    try {
      mbFormDirty = false
      bFormValid()

    }
    catch (err) {

    }
    // return true;
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const onbtnDeleteClick = async () => {
    try {
      mbFormDirty = false
      let modify_tag = document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText
      let agreenumer = getValue(thisObj, 'txtAgreement')
      let stateobj = thisObj.state
      let arrfirs = [0, 1, 2, 3]
      let arrVal = getData(thisObj, "arrRow")
      let result = [...new Set(arrVal)]
      let vendorsplitall = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
      let combined = arrfirs.push(...result);
      var rv = {};
      for (let j = 0; j < vendorsplitall.length; j++) {
        let settxtvendor = "txtVendor" + (j + 1)
        let setremit = "ddRemitTo" + (j + 1)
        let setpercendate = "txtSharePercentage" + (j + 1)
        const hasKeysettxtvendor = settxtvendor in stateobj;
        const hasKeysetremit = setremit in stateobj;
        const hasKeysetpercendate = setpercendate in stateobj;
        let gettxvendor = '', getremit = '', getpercentage = ''
        if (hasKeysettxtvendor == true) {
          gettxvendor = getValue(thisObj, settxtvendor)
        }
        if (hasKeysetremit == true) {
          getremit = getValue(thisObj, setremit)
        }
        if (hasKeysetpercendate == true) {
          getpercentage = getValue(thisObj, setpercendate)
        }
        rv[settxtvendor] = gettxvendor.toString() !== undefined ? gettxvendor.toString() : ''
        rv[setremit] = getremit !== undefined ? getremit : ''
        rv[setpercendate] = getpercentage !== undefined ? getpercentage : ''

      }
      if (confirm(`Are you sure you wish to delete the Agreement number ${agreenumer}`) == true) {
        updateapi("DELETE", agreenumer, [rv])
      }

    }
    catch (err) {

    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnCloseClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      setData(thisObj,'refresData','Ok');
      goTo(thisObj, "Settlements#TradeAgreeSearche#DEFAULT#false#Click");


      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id, true)
    }
    catch (err) {

    }

  };
  thisObj.onddLocationChange = onBuyingPointChange;

  const onddPeanutTypeChange = () => {
    try {
      mbFormDirty = true
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      mbFormDirty = true
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id, true)
    }
    catch (err) {

    }
  };
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddPeanutVarietyChange = () => {
    try {
     
      mbFormDirty = true
     
    }
    catch (err) {

    }
  };
  thisObj.onddPeanutVarietyChange = onddPeanutVarietyChange;

  const onddOleicChange = () => {
    try {
     
      mbFormDirty = true
     
    }
    catch (err) {

    }
  };
  thisObj.onddOleicChange = onddOleicChange;

  const onddFrimBasisFlatChange = () => {
    try {
      let FIRM_BASIS_OPT = getValue(thisObj, 'ddFrimBasisFlat')
      if (FIRM_BASIS_OPT !== "F") {
        hide(thisObj, 'radioGradePricingMethod')
        setValue(thisObj, 'radioGradePricingMethod', 'FULL')
      } else {
        show(thisObj, 'radioGradePricingMethod')
      }

    }
    catch (err) {

    }
  };
  thisObj.onddFrimBasisFlatChange = onddFrimBasisFlatChange

  const onradioGradePricingMethodChange = () => {
    try {
      let onradioGradePricingMethodV = getValue(thisObj, 'radioGradePricingMethod')
      setValue(thisObj, 'txtPrice', '')
    }
    catch (err) {

    }
  };
  thisObj.onradioGradePricingMethodChange = onradioGradePricingMethodChange

  const onddSegChange = () => {
    try {
      let dd_Seg = getValue(thisObj, 'ddSeg')

      if (dd_Seg == "1") {
        setValue(thisObj, 'ddEdibleOil', 'E')
        bFillEdibleOilList(false)
        document.getElementById("ddGeneration").disabled = false;

      } else {
        setValue(thisObj, 'ddEdibleOil', 'O')
        bFillEdibleOilList(true)
        setValue(thisObj, 'ddGeneration', '')
        document.getElementById("ddGeneration").disabled = true;
      }

    }
    catch (err) {

    }
  };
  thisObj.onddSegChange = onddSegChange

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtAgreedPoundsBlur = () => {
    try {
      let txtAgreedPounds = getValue(thisObj, 'txtAgreedPounds')
      let txtAgreedPoundsV = validateTextBox(txtAgreedPounds)
      setValue(thisObj, "txtAgreedPounds", parseInt(txtAgreedPoundsV))
    } catch (err) {

    }

  };
  thisObj.ontxtAgreedPoundsBlur = ontxtAgreedPoundsBlur;

  //showing vendorname txtbox


  const ontxtPriceBlur = () => {
    let txtpricev = getValue(thisObj, 'txtPrice')
    let addpercent = 0.00, flot7 = 0.0000000
    addpercent = addpercent + parseFloat(getValue(thisObj, 'txtPrice'))
    flot7 = flot7 + parseFloat(getValue(thisObj, 'txtPrice'))
    let radioGradePricingMethod_ = thisObj.values.radioGradePricingMethod
    
    if (txtpricev !== '' && txtpricev !== undefined &&txtpricev !== 'NaN') {
      if (radioGradePricingMethod_ !== "TKC") {
        setValue(thisObj, "txtPrice", addpercent.toFixed(2))
      } else {
        setValue(thisObj, "txtPrice", flot7.toFixed(7))
      }
    }else{
      setValue(thisObj, "txtPrice", '')
    }
  }
  thisObj.ontxtPriceBlur = ontxtPriceBlur;

  const ongridApplicationInfoRowSelect = (rowSelected) => {
    let data3 = rowSelected;
    let contractnum1 = state.gridApplicationInfo
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
    }

  };
  thisObj.ongridApplicationInfoRowSelect = ongridApplicationInfoRowSelect;

  const onbtnPopupClick = (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 15);//INC5109342
      let valueGrid = getValue(thisObj, "gridApplicationInfo")
      let gridApplicationdata = valueGrid[rowID]
      if (gridApplicationdata.txtcolTrdInsp != null && gridApplicationdata.txtcolTrdInsp != undefined && gridApplicationdata.txtcolTrdInsp != "") {
        let js = [];
        js.push({
          permission: getData(thisObj, 'accessPermission'),
          Inspnum: gridApplicationdata.txtcolTrdInsp,
          Locnum: gridApplicationdata.txtcolLoc,
          LoadTypeBtnCaption: 'Edit',
          closechild: 'Tradeagreeprofile'
        })
        setData(thisObj, "CreateValue", js)
        goTo(thisObj, "Settlements#TradeSettleProfile#DEFAULT#true#Click");
      } else {
        showMessage(thisObj, "A row must be selected");
        return false;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "No row Selected");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPopupClick = onbtnPopupClick;

  const ongridApplicationInfoDblclick = () => {
    try {
      if (thisObj.state.gridApplicationInfo.selected[0].txtcolTrdInsp !== undefined) {
      
      // set frmtradesettleprofile data
      let js = [];
      js.push({
        permission: getData(thisObj, 'accessPermission'),
        Inspnum: thisObj.state.gridApplicationInfo.selected[0].txtcolTrdInsp,
        Locnum: thisObj.state.gridApplicationInfo.selected[0].txtcolLoc,
        LoadTypeBtnCaption: 'Edit',
        closechild: 'Tradeagreeprofile'
      })
      setData(thisObj, "CreateValue", js)
      goTo(thisObj, "Settlements#TradeSettleProfile#DEFAULT#true#Click");
      }
      else {
        showMessage(thisObj, "error while selecting contract");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "error on double click , please try open button"
        );
      }
    }
  }
  thisObj.ongridApplicationInfoDblclick = ongridApplicationInfoDblclick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_TradeAgreeProfile*/}

              {/* END_USER_CODE-USER_BEFORE_TradeAgreeProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxAgreementInfo*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAgreementInfo*/}

              <GroupBoxWidget
                conf={state.grpbxAgreementInfo}
                screenConf={state}
              >
                
                {/* START_USER_CODE-USER_BEFORE_grpbxTrdSalePrchs*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTrdSalePrchs*/}

                <GroupBoxWidget
                  conf={state.grpbxTrdSalePrchs}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_radioTradeSaleTradePurchase*/}

                  {/* END_USER_CODE-USER_BEFORE_radioTradeSaleTradePurchase*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioTradeSaleTradePurchase}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioTradeSaleTradePurchase*/}

                  {/* END_USER_CODE-USER_AFTER_radioTradeSaleTradePurchase*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAgreement*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAgreement*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAgreement}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAgreement*/}

                  {/* END_USER_CODE-USER_AFTER_txtAgreement*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAgreementDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAgreementDate*/}
                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAgreementDate}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAgreementDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtAgreementDate*/}
                  {/* START_USER_CODE-USER_BEFORE_ddAgreementStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAgreementStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddAgreementStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddAgreementStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddAgreementStatus*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTrdSalePrchs*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTrdSalePrchs*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                <GroupBoxWidget conf={state.grpbxPeanutInfo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblPnutInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPnutInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPnutInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPnutInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblPnutInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEdibleOil}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                  {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAgreementInfo*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAgreementInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxVndrPrcngInfo*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVndrPrcngInfo*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxVndrPrcngInfo}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                <GroupBoxWidget
                  conf={state.grpbxVendorSplits}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblVndrSplts*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVndrSplts*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVndrSplts}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVndrSplts*/}

                  {/* END_USER_CODE-USER_AFTER_lblVndrSplts*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendor}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemitTo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemitTo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSharePercentage*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSharePercentage*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSharePercentage}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSharePercentage*/}

                  {/* END_USER_CODE-USER_AFTER_lblSharePercentage*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitSub*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitSub*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitSub}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow0}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor1}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                      {/* START_USER_CODE-USER_BEFORE_btn1*/}

                      {/* END_USER_CODE-USER_BEFORE_btn1*/}

                      <ButtonWidget
                        conf={state.btn1}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn1")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn1*/}

                      {/* END_USER_CODE-USER_AFTER_btn1*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo1}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo1*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage1*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage1*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX1*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX1*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX1}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX1*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX1*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow1}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor2*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor2}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor2*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor2*/}
                      {/* START_USER_CODE-USER_BEFORE_btn2*/}

                      {/* END_USER_CODE-USER_BEFORE_btn2*/}

                      <ButtonWidget
                        conf={state.btn2}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn2")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn2*/}

                      {/* END_USER_CODE-USER_AFTER_btn2*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo2}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo2*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage2*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage2*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX2*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX2*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX2}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX2*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX2*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow2}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor3*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor3*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor3}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor3*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor3*/}
                      {/* START_USER_CODE-USER_BEFORE_btn3*/}

                      {/* END_USER_CODE-USER_BEFORE_btn3*/}

                      <ButtonWidget
                        conf={state.btn3}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn3")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn3*/}

                      {/* END_USER_CODE-USER_AFTER_btn3*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo3}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo3*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo3*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage3*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage3*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage3}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage3*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage3*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX3*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX3*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX3}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX3*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX3*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow3}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor4*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor4*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor4}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor4*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor4*/}
                      {/* START_USER_CODE-USER_BEFORE_btn4*/}

                      {/* END_USER_CODE-USER_BEFORE_btn4*/}

                      <ButtonWidget
                        conf={state.btn4}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn4")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn4*/}

                      {/* END_USER_CODE-USER_AFTER_btn4*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo4}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo4*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo4*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage4*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage4*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage4}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage4*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage4*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX4*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX4*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX4}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX4*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX4*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow4}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor5*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor5*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor5}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor5*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor5*/}
                      {/* START_USER_CODE-USER_BEFORE_btn5*/}

                      {/* END_USER_CODE-USER_BEFORE_btn5*/}

                      <ButtonWidget
                        conf={state.btn5}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn5")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn5*/}

                      {/* END_USER_CODE-USER_AFTER_btn5*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo5}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo5*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo5*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage5*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage5*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage5}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage5*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage5*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX5*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX5*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX5}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX5*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX5*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow5}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor6*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor6*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor6}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor6*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor6*/}
                      {/* START_USER_CODE-USER_BEFORE_btn6*/}

                      {/* END_USER_CODE-USER_BEFORE_btn6*/}

                      <ButtonWidget
                        conf={state.btn6}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn6")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn6*/}

                      {/* END_USER_CODE-USER_AFTER_btn6*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo6}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo6*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo6*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage6*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage6*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage6}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage6*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage6*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX6*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX6*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX6}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX6*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX6*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow6}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor7*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor7*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor7}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor7*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor7*/}
                      {/* START_USER_CODE-USER_BEFORE_btn7*/}

                      {/* END_USER_CODE-USER_BEFORE_btn7*/}

                      <ButtonWidget
                        conf={state.btn7}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn7")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn7*/}

                      {/* END_USER_CODE-USER_AFTER_btn7*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo7}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo7*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo7*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage7*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage7*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage7}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage7*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage7*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX7*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX7*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX7}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX7*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX7*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow7}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor8*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor8*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor8}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor8*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor8*/}
                      {/* START_USER_CODE-USER_BEFORE_btn8*/}

                      {/* END_USER_CODE-USER_BEFORE_btn8*/}

                      <ButtonWidget
                        conf={state.btn8}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn8")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn8*/}

                      {/* END_USER_CODE-USER_AFTER_btn8*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo8}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo8*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo8*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage8*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage8*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }

                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage8}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage8*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage8*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX8*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX8*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX8}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX8*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX8*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow8}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor9*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor9*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor9}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor9*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor9*/}
                      {/* START_USER_CODE-USER_BEFORE_btn9*/}

                      {/* END_USER_CODE-USER_BEFORE_btn9*/}

                      <ButtonWidget
                        conf={state.btn9}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn9")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn9*/}

                      {/* END_USER_CODE-USER_AFTER_btn9*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo9}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo9*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo9*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage9*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage9*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage9}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage9*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage9*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX9*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX9*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX9}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX9*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX9*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow9}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor10*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor10*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor10}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor10*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor10*/}
                      {/* START_USER_CODE-USER_BEFORE_btn10*/}

                      {/* END_USER_CODE-USER_BEFORE_btn10*/}

                      <ButtonWidget
                        conf={state.btn10}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn10")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn10*/}

                      {/* END_USER_CODE-USER_AFTER_btn10*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo10}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo10*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo10*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage10*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage10*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage10}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage10*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage10*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX10*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX10*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX10}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX10*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX10*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow10}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor11*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor11*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor11}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor11*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor11*/}
                      {/* START_USER_CODE-USER_BEFORE_btn11*/}

                      {/* END_USER_CODE-USER_BEFORE_btn11*/}

                      <ButtonWidget
                        conf={state.btn11}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn11")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn11*/}

                      {/* END_USER_CODE-USER_AFTER_btn11*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo11}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo11*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo11*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage11*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage11*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage11}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage11*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage11*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX11*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX11*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX11}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX11*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX11*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow11}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor12*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor12*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor12}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor12*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor12*/}
                      {/* START_USER_CODE-USER_BEFORE_btn12*/}

                      {/* END_USER_CODE-USER_BEFORE_btn12*/}

                      <ButtonWidget
                        conf={state.btn12}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn12")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn12*/}

                      {/* END_USER_CODE-USER_AFTER_btn12*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo12}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo12*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo12*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage12*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage12*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage12}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage12*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage12*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX12*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX12*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX12}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX12*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX12*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow13}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor13*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor13*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor13}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor13*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor13*/}
                      {/* START_USER_CODE-USER_BEFORE_btn13*/}

                      {/* END_USER_CODE-USER_BEFORE_btn13*/}

                      <ButtonWidget
                        conf={state.btn13}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn13")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn13*/}

                      {/* END_USER_CODE-USER_AFTER_btn13*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo13}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo13*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo13*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage13*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage13*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage13}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage13*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage13*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX13*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX13*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX13}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX13*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX13*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow12}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor14*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor14*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor14}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor14*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor14*/}
                      {/* START_USER_CODE-USER_BEFORE_btn14*/}

                      {/* END_USER_CODE-USER_BEFORE_btn14*/}

                      <ButtonWidget
                        conf={state.btn14}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn14")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn14*/}

                      {/* END_USER_CODE-USER_AFTER_btn14*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo14}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo14*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo14*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage14*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage14*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage14}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage14*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage14*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX14*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX14*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX14}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX14*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX14*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow14}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor15*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor15*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor15}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor15*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor15*/}
                      {/* START_USER_CODE-USER_BEFORE_btn15*/}

                      {/* END_USER_CODE-USER_BEFORE_btn15*/}

                      <ButtonWidget
                        conf={state.btn15}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn15")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn15*/}

                      {/* END_USER_CODE-USER_AFTER_btn15*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo15}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo15*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo15*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage15*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage15*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage15}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage15*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage15*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX15*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX15*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX15}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX15*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX15*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow15}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor16*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor16*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor16}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor16*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor16*/}
                      {/* START_USER_CODE-USER_BEFORE_btn16*/}

                      {/* END_USER_CODE-USER_BEFORE_btn16*/}

                      <ButtonWidget
                        conf={state.btn16}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn16")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn16*/}

                      {/* END_USER_CODE-USER_AFTER_btn16*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo16}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo16*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo16*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage16*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage16*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage16}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage16*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage16*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX16*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX16*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX16}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX16*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX16*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow16}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor17*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor17*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor17}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor17*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor17*/}
                      {/* START_USER_CODE-USER_BEFORE_btn17*/}

                      {/* END_USER_CODE-USER_BEFORE_btn17*/}

                      <ButtonWidget
                        conf={state.btn17}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn17")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn17*/}

                      {/* END_USER_CODE-USER_AFTER_btn17*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo17}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo17*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo17*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage17*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage17*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage17}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage17*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage17*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX17*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX17*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX17}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX17*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX17*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow17}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor18*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor18*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor18}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor18*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor18*/}
                      {/* START_USER_CODE-USER_BEFORE_btn18*/}

                      {/* END_USER_CODE-USER_BEFORE_btn18*/}

                      <ButtonWidget
                        conf={state.btn18}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn18")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn18*/}

                      {/* END_USER_CODE-USER_AFTER_btn18*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo18}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo18*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo18*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage18*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage18*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage18}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage18*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage18*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX18*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX18*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX18}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX18*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX18*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow18}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor19*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor19*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor19}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor19*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor19*/}
                      {/* START_USER_CODE-USER_BEFORE_btn19*/}

                      {/* END_USER_CODE-USER_BEFORE_btn19*/}

                      <ButtonWidget
                        conf={state.btn19}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn19")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn19*/}

                      {/* END_USER_CODE-USER_AFTER_btn19*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo19}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo19*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo19*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage19*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage19*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage19}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage19*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage19*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX19*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX19*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX19}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX19*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX19*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow20*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow20*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow19}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor20*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor20*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor20}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor20*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor20*/}
                      {/* START_USER_CODE-USER_BEFORE_btn20*/}

                      {/* END_USER_CODE-USER_BEFORE_btn20*/}

                      <ButtonWidget
                        conf={state.btn20}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn20")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn20*/}

                      {/* END_USER_CODE-USER_AFTER_btn20*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo20*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo20*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo20}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo20*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo20*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage20*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage20*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage20}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage20*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage20*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX20*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX20*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX20}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX20*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX20*/}
                    </GroupBoxWidget>
                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitSub*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitSub*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVendorSplits*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVendorSplits*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPricingInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPricingInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxPricingInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPricingInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPricingInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPricingInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPricingInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblPricingInfo*/}
                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioGradePricingMethod}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_BEFORE_ddFrimBasisFlat*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFrimBasisFlat*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFrimBasisFlat}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFrimBasisFlat*/}

                  {/* END_USER_CODE-USER_AFTER_ddFrimBasisFlat*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPrice*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPrice}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPrice*/}

                  {/* END_USER_CODE-USER_AFTER_txtPrice*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAgreedPounds*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAgreedPounds*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAgreedPounds}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAgreedPounds*/}

                  {/* END_USER_CODE-USER_AFTER_txtAgreedPounds*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAppldPounds*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAppldPounds*/}
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lblAppldPoundsVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* <LabelWidget
                    values={values}
                    conf={state.lblAppldPounds}
                    screenConf={state}
                  ></LabelWidget> */}
                  {/* START_USER_CODE-USER_AFTER_lblAppldPounds*/}

                  {/* END_USER_CODE-USER_AFTER_lblAppldPounds*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAppldPoundsVal*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAppldPoundsVal*/}

                  {/* <LabelWidget
                    values={values}
                    conf={state.lblAppldPoundsVal}
                    screenConf={state}
                  ></LabelWidget> */}
                  {/* START_USER_CODE-USER_AFTER_lblAppldPoundsVal*/}

                  {/* END_USER_CODE-USER_AFTER_lblAppldPoundsVal*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOpenBlnc*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOpenBlnc*/}
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lblOpenBlncVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* <LabelWidget
                    values={values}
                    conf={state.lblOpenBlnc}
                    screenConf={state}
                  ></LabelWidget> */}
                  {/* START_USER_CODE-USER_AFTER_lblOpenBlnc*/}

                  {/* END_USER_CODE-USER_AFTER_lblOpenBlnc*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOpenBlncVal*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOpenBlncVal*/}

                  {/* <LabelWidget
                    values={values}
                    conf={state.lblOpenBlncVal}
                    screenConf={state}
                  ></LabelWidget> */}
                  {/* START_USER_CODE-USER_AFTER_lblOpenBlncVal*/}

                  {/* END_USER_CODE-USER_AFTER_lblOpenBlncVal*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPricingInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPricingInfo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVndrPrcngInfo*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVndrPrcngInfo*/}
              {/* START_USER_CODE-USER_BEFORE_gridApplicationInfo*/}

              {/* END_USER_CODE-USER_BEFORE_gridApplicationInfo*/}
              <GroupBoxWidget conf={state.grpbxApplicationInfo} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblApplicationInfo*/}

                {/* END_USER_CODE-USER_BEFORE_lblApplicationInfo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblApplicationInfo}
                  screenConf={state}
                ></LabelWidget>
              <GridWidget
                columns={columns}
                conf={state.gridApplicationInfo}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridApplicationInfo}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                setFieldValue={setFieldValue}
                refObject={thisObj}
              ></GridWidget>
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_gridApplicationInfo*/}

              {/* END_USER_CODE-USER_AFTER_gridApplicationInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxComments*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxComments*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxComments} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtarComments*/}

                {/* END_USER_CODE-USER_BEFORE_txtarComments*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarComments}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarComments*/}

                {/* END_USER_CODE-USER_AFTER_txtarComments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxComments*/}

              {/* END_USER_CODE-USER_AFTER_grpbxComments*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btn5*/}

                {/* END_USER_CODE-USER_BEFORE_btn5*/}


                {/* START_USER_CODE-USER_AFTER_btn5*/}

                {/* END_USER_CODE-USER_AFTER_btn5*/}
                {/* START_USER_CODE-USER_BEFORE_lblCropYear2*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear2*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear2*/}
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeAgreeProfile*/}

              {/* END_USER_CODE-USER_AFTER_TradeAgreeProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeAgreeProfile);
