/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  CheckboxWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./AreaProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_AreaProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "AreaProfile",
    windowName: "AreaProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.AreaProfile",
    // START_USER_CODE-USER_AreaProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Area Profile",
      scrCode: "PN0020B",
    },
    // END_USER_CODE-USER_AreaProfile_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxLandPlaster: {
      name: "chkboxLandPlaster",
      type: "CheckBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Landplaster Addendum Allowed",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxLandPlaster_PROPERTIES

      // END_USER_CODE-USER_chkboxLandPlaster_PROPERTIES
    },
    chkboxForeignMaterial: {
      name: "chkboxForeignMaterial",
      type: "CheckBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Foreign Material Allowed",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxForeignMaterial_PROPERTIES

      // END_USER_CODE-USER_chkboxForeignMaterial_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxAreaProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    ddDisplay: {
      name: "ddDisplay",
      type: "DropDownFieldWidget",
      parent: "grpbxAreaProfile",
      Label: "Display:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDisplay_PROPERTIES

      // END_USER_CODE-USER_ddDisplay_PROPERTIES
    },
    gridAreaProfileData: {
      name: "gridAreaProfileData",
      type: "GridWidget",
      parent: "grpbxAreaProfile",
      gridCellsOrder:
        "txtDeliveryId,txtActiveStatus,txtDeliveryPeriodDescription",
      ColSpan: "2",
      Pagination: false,
      ColsForTabLandscape: "13",
      HasLabel: false,
      Cols: "13",
      ColsForTabPotrait: "13",
      ColsForLargeDesktop: "13",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAreaProfileData_PROPERTIES

      // END_USER_CODE-USER_gridAreaProfileData_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCompany: {
      name: "lblCompany",
      type: "LabelWidget",
      parent: "grpbxAreaProfile",
      Label: "Company:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompany_PROPERTIES

      // END_USER_CODE-USER_lblCompany_PROPERTIES
    },
    lblCompanyValue: {
      name: "lblCompanyValue",
      type: "LabelWidget",
      parent: "grpbxAreaProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompanyValue_PROPERTIES

      // END_USER_CODE-USER_lblCompanyValue_PROPERTIES
    },
    lblID: {
      name: "lblID",
      type: "LabelWidget",
      parent: "grpbxAreaProfile",
      Label: "lblID",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblID_PROPERTIES

      // END_USER_CODE-USER_lblID_PROPERTIES
    },
    lblMax: {
      name: "lblMax",
      type: "LabelWidget",
      parent: "grpbxAreaProfile",
      Label: "Max",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMax_PROPERTIES

      // END_USER_CODE-USER_lblMax_PROPERTIES
    },
    lblMin: {
      name: "lblMin",
      type: "LabelWidget",
      parent: "grpbxAreaProfile",
      Label: "Min",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMin_PROPERTIES

      // END_USER_CODE-USER_lblMin_PROPERTIES
    },
    txtActiveStatus: {
      name: "txtActiveStatus",
      type: "TextBoxWidget",
      colName: "txtCol2",
      parent: "gridAreaProfileData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtActiveStatus_PROPERTIES

      // END_USER_CODE-USER_txtActiveStatus_PROPERTIES
    },
    txtCCCHandler: {
      name: "txtCCCHandler",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "CCC Handler #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCHandler_PROPERTIES

      // END_USER_CODE-USER_txtCCCHandler_PROPERTIES
    },
    txtClearingAmount: {
      name: "txtClearingAmount",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Clearing Amount:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtClearingAmount_PROPERTIES

      // END_USER_CODE-USER_txtClearingAmount_PROPERTIES
    },
    txtCol1: {
      name: "txtCol1",
      type: "GridColumnWidget",
      parent: "gridAreaProfileData",
      Label: "Delivery Id",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtCol1_PROPERTIES

      // END_USER_CODE-USER_txtCol1_PROPERTIES
    },
    txtCol2: {
      name: "txtCol2",
      type: "GridColumnWidget",
      parent: "gridAreaProfileData",
      Label: "Active Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtCol2_PROPERTIES

      // END_USER_CODE-USER_txtCol2_PROPERTIES
    },
    txtColDeliveryPeriodDescription: {
      name: "txtColDeliveryPeriodDescription",
      type: "GridColumnWidget",
      parent: "gridAreaProfileData",
      Label: "Delivery Period Desciption",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDeliveryPeriodDescription_PROPERTIES

      // END_USER_CODE-USER_txtColDeliveryPeriodDescription_PROPERTIES
    },
    txtContractPendingMaxDays: {
      name: "txtContractPendingMaxDays",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Contract Pending Max Days:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractPendingMaxDays_PROPERTIES

      // END_USER_CODE-USER_txtContractPendingMaxDays_PROPERTIES
    },
    txtDeliveryId: {
      name: "txtDeliveryId",
      type: "TextBoxWidget",
      colName: "txtCol1",
      parent: "gridAreaProfileData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryId_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryId_PROPERTIES
    },
    txtDeliveryPeriodDescription: {
      name: "txtDeliveryPeriodDescription",
      type: "TextBoxWidget",
      colName: "txtColDeliveryPeriodDescription",
      parent: "gridAreaProfileData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryPeriodDescription_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryPeriodDescription_PROPERTIES
    },
    txtHandlerLoc: {
      name: "txtHandlerLoc",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Handler Loc #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHandlerLoc_PROPERTIES

      // END_USER_CODE-USER_txtHandlerLoc_PROPERTIES
    },
    txtID: {
      name: "txtID",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "ID:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtID_PROPERTIES

      // END_USER_CODE-USER_txtID_PROPERTIES
    },
    txtKernalHullRangeMax: {
      name: "txtKernalHullRangeMax",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernalHullRangeMax_PROPERTIES

      // END_USER_CODE-USER_txtKernalHullRangeMax_PROPERTIES
    },
    txtKernalHullRangeMin: {
      name: "txtKernalHullRangeMin",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Kernal Hull Range:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernalHullRangeMin_PROPERTIES

      // END_USER_CODE-USER_txtKernalHullRangeMin_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxAreaProfile",
      Label: "Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    grpbxAreaProfile: {
      name: "grpbxAreaProfile",
      type: "GroupBoxWidget",
      parent: "AreaProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxAreaProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxAreaProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "AreaProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#DeliveryPeriodProfile":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#DeliveryPeriodProfile":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#DeliveryPeriodProfile#DEFAULT#true#Click"]
    // },
    // btnEdit:{
    //   DEFAULT:["SystemMaintenanceMasterManagement#DeliveryPeriodProfile#DEFAULT#true#Click"]
    // },
   
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddDisplay", '')
    FormLoad()
    EnableDisableControls();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  useEffect(()=>{
    let areaData = getData(thisObj, 'closingOfDeliveryprofile')
    document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText
    if (document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText == "Update") {
      bfillDeliverygrid()
    }
  },[getData(thisObj, 'closingOfDeliveryprofile')])
 
  const FormLoad = () => {
    let dataObj = getData(thisObj, 'BPSetupAreaData');
    ContractManagementService.RetrieveCompanyDetails().then(response => {
      let data = response;
      setValue(thisObj,"lblCompanyValue",data[0].compName)
      });
    if (dataObj.btnOk == "Add") {
      document.getElementById('btnOk').innerText = "Add"
      bFilldisplayList();
    }
    if (dataObj.btnOk == 'Update') {
      document.getElementById('btnOk').innerText = "Update"
      setValue(thisObj,"lblCompanyValue","Golden Peanut Company")
      disable(thisObj, 'txtID')
      setValue(thisObj, 'txtID', dataObj.txtID)
      setValue(thisObj, 'txtName', dataObj.ddArea)
      setValue(thisObj, 'txtHandlerLoc', dataObj.txtHandlerLocNumber)
       setValue(thisObj, 'txtCCCHandler', dataObj.txtCCCHandler)
       setValue(thisObj, 'txtKernalHullRangeMin', dataObj.txtMinKernHull =='' ? '0':dataObj.txtMinKernHull)
       setValue(thisObj, 'txtKernalHullRangeMax', dataObj.txtMaxKernHull=='' ? '0':dataObj.txtMaxKernHull)
       setValue(thisObj, 'txtClearingAmount', dataObj.txtClearingAccount)
       setValue(thisObj, 'txtContractPendingMaxDays', dataObj.txtContractMaxDay)
       setValue(thisObj,"lblAddedByValue",dataObj.lblAddedBy[0].lblAddedById+" "+dataObj.lblAddedBy[0].txtDate)
       setValue(thisObj,"lblChangedByValue",dataObj.lblChangedBy[0].lblChangedById+" "+dataObj.lblChangedBy[0].txtDate)
       bFilldisplayList(dataObj.lstLbsTons)
      setValue(thisObj, 'chkboxForeignMaterial', dataObj.chkFmAgreementAddAllowed)
      setValue(thisObj, 'chkboxLandPlaster', dataObj.chkLandplasterAddAllowed)
      bfillDeliverygrid();
    }
   }
// dropdown binding
  const bFilldisplayList = (lstLbsTons) => {
    let js = []
    js.push({ key: 'P', description: 'Lbs' })
    js.push({ key: 'T', description: 'Tons' })
    thisObj.setState(current => {
      return {
        ...current,
        ddDisplay: {
          ...state["ddDisplay"],
          valueList: js
        }
      }
    })
    let dataObj = getData(thisObj, 'BPSetupAreaData')
    if (dataObj.btnOk == "Add") {
      setValue(thisObj, "ddDisplay", js[0].key);
    }
    if (dataObj.btnOk == "Update") {
      setValue(thisObj, "ddDisplay", lstLbsTons)
    }
  }
  // EnableDisablecontrols functionality
  const EnableDisableControls = () => {
    let userAccess = ''   
    SystemMaintenanceMasterManagementService.RetrieveBuyingPointControlAccessM('U').then(response => {
      if (response <= 0) {
        return;
      }
      if (response[0].permission== "N") {  
        userAccess == 'N'
        disable(thisObj, "txtID")
        disable(thisObj, "txtName")
        disable(thisObj, "txtHandlerLoc")
        disable(thisObj, "txtCCCHandler")
        disable(thisObj, "txtKernalHullRangeMin")
        disable(thisObj, "txtKernalHullRangeMax")
        disable(thisObj, "txtClearingAmount")
        disable(thisObj, "ddDisplay")
        disable(thisObj, "chkboxForeignMaterial")
        disable(thisObj, "chkboxLandPlaster")
        disable(thisObj, "btnAdd")
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
        disable(thisObj, "btnOK")
      }
    })
  }
 //Formvalid function
   const FormValid = () => {
     var bFormValid = false
     let Id = getValue(thisObj, 'txtID')
     if (Id == null || Id == '' || Id == undefined) {
      showMessage(thisObj, "Id is a required field")
      bFormValid = false
       return bFormValid;
    }
    let name = getValue(thisObj, 'txtName')
    if (name == null || name == '' || name == undefined) {
     showMessage(thisObj, "Name is a required field")
     bFormValid = false
       return bFormValid;
     }
     let HandlerLocNumber = getValue(thisObj, 'txtHandlerLoc')
    if (HandlerLocNumber == null || HandlerLocNumber == '' || HandlerLocNumber == undefined) {
       showMessage(thisObj,"Handler Location is a required field.")
       bFormValid = false
       return bFormValid;
    }
     let MinKernHull = getValue(thisObj, 'txtKernalHullRangeMin')
     if (MinKernHull !== '' && MinKernHull !== undefined) {
       if (isNaN(MinKernHull)== false) {
         if (MinKernHull < 0 || MinKernHull > 999.99) {
           showMessage(thisObj, "Minimun Kernals and Hulls must be of format ####.##");
           bFormValid = false
           return bFormValid;
         }
       }
       else {
         showMessage(thisObj, "Minimun Kernals and Hulls must be of format ####.##");
         return bFormValid;
       }
     }
     else {
       setValue(thisObj, 'txtKernalHullRangeMin', '')
     }
     MinKernHull = MinKernHull == undefined ? "0" : MinKernHull

     let MaxKernHull = getValue(thisObj, 'txtKernalHullRangeMax')
     if (MaxKernHull !== '' && MaxKernHull !== undefined) {
       if (isNaN(MaxKernHull)== false) {
         if (MaxKernHull < 0 || MaxKernHull > 999.99) {
           showMessage(thisObj, "Maximum Kernals and Hulls must be of format ####.##");
           bFormValid = false
           return bFormValid;
         }
       }
       else {
         showMessage(thisObj, "Maximun Kernals and Hulls must be of format ####.##");
         return bFormValid;
       }
     }
     else {
       setValue(thisObj, 'txtKernalHullRangeMax', '')
     }
     MaxKernHull = MaxKernHull == undefined ? "0" : MaxKernHull

    let CCCHandler = getValue(thisObj, 'txtCCCHandler')
    if (CCCHandler == null || CCCHandler == '' || CCCHandler == undefined) {
      showMessage(thisObj,"CCC Handler is a required field.")
      bFormValid = false
      return bFormValid;
     }
 	let ContractMaxDay= getValue(thisObj, 'txtContractPendingMaxDays')
     if (ContractMaxDay == null || ContractMaxDay == '' || ContractMaxDay == undefined) {
       showMessage(thisObj,"Contract Pending Max Days is a required field.")
       bFormValid = false
       return bFormValid;
    }
     bFormValid =true
   }
// btn ok functionality
const onbtnOkClick = async () => {
 try {
    if (FormValid() == false) {
      return;
    }
    let dataObj = getData(thisObj, "BPSetupAreaData");
    let contract_max_day = getValue(thisObj, 'txtContractPendingMaxDays')
    let area_id = getValue(thisObj, 'txtID')
    let area_name = getValue(thisObj, 'txtName')
    let handler_loc_num = getValue(thisObj, 'txtHandlerLoc')
    let ccc_handler_num = getValue(thisObj, 'txtCCCHandler')
    let display_lbs_ton = getValue(thisObj, 'ddDisplay')
    let min_kern_hull_pct = getValue(thisObj, 'txtKernalHullRangeMin')
    let max_kern_hull_pct = getValue(thisObj, 'txtKernalHullRangeMax')
    let clear_acct = getValue(thisObj, 'txtClearingAmount')
    let landp_addem_ind = getValue(thisObj, 'chkboxLandPlaster')
     if (landp_addem_ind == true) {
    landp_addem_ind = 'Y'
        }
     else if (landp_addem_ind == false || landp_addem_ind == '' || landp_addem_ind == null || landp_addem_ind == undefined){
     landp_addem_ind = 'N'
    }
     let fm_agree_ind = getValue(thisObj, 'chkboxForeignMaterial')
      if (fm_agree_ind == true) {
      fm_agree_ind = 'Y'
         }
     else if (fm_agree_ind == false || fm_agree_ind == '' || fm_agree_ind == null || fm_agree_ind == undefined){
     fm_agree_ind = 'N'
        }

    //add button functionality
    if (dataObj.btnOk == "Add") {
      let temp = {
        "contract_max_day": contract_max_day,
        "area_id":area_id,
        "area_name": area_name,
        "handler_loc_num": handler_loc_num,
        "ccc_handler_num": ccc_handler_num,
        "landp_addem_ind": landp_addem_ind,
        "display_lbs_ton": display_lbs_ton,
        "min_kern_hull_pct": min_kern_hull_pct,
        "max_kern_hull_pct": max_kern_hull_pct,
        "clear_acct": clear_acct,
        "fm_agree_ind": fm_agree_ind
      }
      let response = await SystemMaintenanceMasterManagementService.CreateAreaControls(temp)
      let data = response
      if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
        return;
      };
        let Array = []
        let Obj = {}
        Obj.txtContractPendingMaxDays = contract_max_day
        Obj.txtID = area_id
        Obj.txtName = area_name
        Obj.txtHandlerLoc = handler_loc_num
        Obj.txtCCCHandler = ccc_handler_num
        Obj.chkboxAreaProfile0 = landp_addem_ind
        Obj.ddDisplay = display_lbs_ton
        Obj.txtKernalHullRangeMin = min_kern_hull_pct
        Obj.txtKernalHullRangeMax = max_kern_hull_pct
        Obj.txtClearingAmount = clear_acct
        Obj.chkboxAreaProfile1 = fm_agree_ind
        Array.push(Obj)
        setData(thisObj, 'closingOfAreaProfile', true)
        setData(thisObj, 'AreaProfile', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_AreaProfilePopUp").childNodes[0].click()
    }
     //update button functionality
    if (dataObj.btnOk == "Update") {
      disable(thisObj, 'txtID')
      let temp = {
        "contract_max_day": contract_max_day,
        "area_name": area_name,
        "handler_loc_num": handler_loc_num,
        "ccc_handler_num": ccc_handler_num,
        "landp_addem_ind": landp_addem_ind,
        "display_lbs_ton": display_lbs_ton,
        "min_kern_hull_pct": min_kern_hull_pct,
        "max_kern_hull_pct": max_kern_hull_pct,
        "clear_acct": clear_acct,
        "fm_agree_ind": fm_agree_ind
      }
      let response = await SystemMaintenanceMasterManagementService.UpdateAreaControls(area_id,temp)
     let data = response
     if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
      showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response.message )
      return;
    }
      let Array = []
      let Obj = {}
        Obj.txtContractPendingMaxDays = contract_max_day
        Obj.txtName = area_name
        Obj.txtHandlerLoc = handler_loc_num
        Obj.txtCCCHandler = ccc_handler_num
        Obj.chkboxAreaProfile0 = landp_addem_ind
        Obj.ddDisplay = display_lbs_ton
        Obj.txtKernalHullRangeMin = min_kern_hull_pct
        Obj.txtKernalHullRangeMax = max_kern_hull_pct
        Obj.txtClearingAmount = clear_acct
        Obj.chkboxAreaProfile1 = fm_agree_ind
      Array.push(Obj)
      setData(thisObj, 'closingOfAreaProfile', true)
      setData(thisObj, 'AreaProfile', Obj)
      document.getElementById("SystemMaintenanceMasterManagement_AreaProfilePopUp").childNodes[0].click()
  }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btn event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnOkClick = onbtnOkClick;


  //Method to bind the grid
  const  bfillDeliverygrid = async() => {
    let dataObj = getData(thisObj, 'BPSetupAreaData');
    let response = await SystemMaintenanceMasterManagementService.RetrieveDeliveryPeriodControlDetails(null,cropyear,null,dataObj.txtID, null)
     let js = [];
    if (response !== null && response !== undefined && response.length > 0) {
     let data = response;
     let obj = {};
      for (var i = 0; i < data.length; i++) {
        obj.txtDeliveryId = data[i].delivery_id;
         obj.txtActiveStatus = data[i].active_ind;
         obj.txtDeliveryPeriodDescription = data[i].delivery_period;
         js.push(obj)
        obj = {}
       }
     }
    setValue(thisObj, 'gridAreaProfileData', js);
    if (js.length > 0) {
      setSelectedRowNum(0);
      js[0].isSelected = true;
    }
   setData(thisObj, 'gridAreaProfileData', js);
    thisObj.setState((current) => {
      return {
        ...current,
        gridAreaProfileData: {
          ...current["gridAreaProfileData"],
          valueList: js
        },
      }
    
    });
}
const selectRow = (newRowNum) => {
  const gridData = getValue(thisObj, 'gridAreaProfileData');
  if (newRowNum !== undefined && selectedRowNum !== undefined && gridData?.length) {
    gridData[selectedRowNum].isSelected = false;
    setSelectedRowNum(newRowNum);
    return(newRowNum)
  }
}

const ongridAreaProfileDataRowSelect = (rowItems) => {
  const rowNum = getSelectedRowNumber(thisObj, 'gridAreaProfileData');
  selectRow(rowNum);
}
thisObj.ongridAreaProfileDataRowSelect = ongridAreaProfileDataRowSelect

//DeliveryPeriodProfile add btn functionality
	const onbtnAddClick = () => {
    try {
      let areaId = getValue(thisObj, "txtID");
      if (areaId !== undefined && areaId !== null && areaId !== '') {
      }
      let frmDeliveryPeriodProfile = {}
      frmDeliveryPeriodProfile.cmdSave_Caption = 'Add'
      frmDeliveryPeriodProfile.lblArea = areaId
      setData(thisObj, 'frmDeliveryPeriodProfile', frmDeliveryPeriodProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#DeliveryPeriodProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Add button"
        );
      }
      return false;
    }
   
    return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;
  //DeliveryPeriodProfile edit btn functionality
  const onbtnEditClick =  () => {
    try {
      let areaId = getValue(thisObj, "txtID");
      let gridData = getValue(thisObj, 'gridAreaProfileData');
    let rowNum = getSelectedRowNumber(thisObj, 'gridAreaProfileData');
     if (rowNum == undefined) {
    rowNum = 0;
    return;
     }
      let frmDeliveryPeriodProfile = {}
      frmDeliveryPeriodProfile.cmdSave_Caption = 'Add';
      frmDeliveryPeriodProfile.lblArea = areaId;
      frmDeliveryPeriodProfile.lblDeliveryId = gridData[rowNum].txtDeliveryId;
      frmDeliveryPeriodProfile.txtdelivery_period = gridData[rowNum].txtDeliveryPeriodDescription;
      if (gridData[rowNum].txtActiveStatus == 'Y') {
        frmDeliveryPeriodProfile.active_ind = true  
      }
      else {
        frmDeliveryPeriodProfile.active_ind = false
      }
      frmDeliveryPeriodProfile.cmdSave_Caption = "Update";
      setData(thisObj, 'frmDeliveryPeriodProfile', frmDeliveryPeriodProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#DeliveryPeriodProfile#DEFAULT#true#Click")
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during edit button"
      );
    }
    return false;
    }
  }
    thisObj.onbtnEditClick = onbtnEditClick;

 //Click event of Delete button
  const onbtnDeleteClick = async() => {
    try {
      let res = confirm(`Are you sure you wish to delete this delivery point description ?`)
      if (res == true) {
      let gridData = getValue(thisObj, 'gridAreaProfileData');
      let rowNum = getSelectedRowNumber(thisObj, 'gridAreaProfileData');
      if (rowNum == undefined) {
        alert("Description: Invalid property array index\nRoutine: frmAreaProfile,cmdDeleteArea_Click")
        return false;
      }
      let area_id = getValue(thisObj, "txtID")
      let delivery_id = gridData[rowNum].txtDeliveryId
      let action_type = 'DELETE'
      let dataObj = {
        "delivery_period" : "",
        "active_ind" : "",
        "user_id" : useridFromLS()
      }
        let response = await SystemMaintenanceMasterManagementService.UpdateDeliveryPeriodControls(area_id, action_type, delivery_id, dataObj)
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
          return
        }
        else {
          showMessage(thisObj, response.message, true)
        }
        await  bfillDeliverygrid(area_id, action_type, delivery_id,)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Button Click"
        );
      }
      return false;
    }
    
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  const ontxtContractPendingMaxDaysChange = () => {
    let ContractmaxValue = getValue(thisObj, 'txtContractPendingMaxDays')
    setValue(thisObj, "txtContractPendingMaxDays", validateTextBox(ContractmaxValue))
  };
  thisObj.ontxtContractPendingMaxDaysChange = ontxtContractPendingMaxDaysChange
  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }
  const onbtnCancelClick = () =>{
    try{
      document.getElementById("SystemMaintenanceMasterManagement_AreaProfilePopUp").childNodes[0].click()
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error on button Cancel Click event"	)	
      }
      return false
    }
    return true;
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_AreaProfile*/}

              {/* END_USER_CODE-USER_BEFORE_AreaProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxAreaProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAreaProfile*/}

              <GroupBoxWidget conf={state.grpbxAreaProfile} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblCompany*/}

                {/* END_USER_CODE-USER_BEFORE_lblCompany*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCompany}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCompany*/}

                {/* END_USER_CODE-USER_AFTER_lblCompany*/}
                {/* START_USER_CODE-USER_BEFORE_lblCompanyValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblCompanyValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCompanyValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCompanyValue*/}

                {/* END_USER_CODE-USER_AFTER_lblCompanyValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtID*/}

                {/* END_USER_CODE-USER_BEFORE_txtID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtID*/}

                {/* END_USER_CODE-USER_AFTER_txtID*/}
                {/* START_USER_CODE-USER_BEFORE_lblID*/}

                {/* END_USER_CODE-USER_BEFORE_lblID*/}

                <LabelWidget
                  values={values}
                  conf={state.lblID}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblID*/}

                {/* END_USER_CODE-USER_AFTER_lblID*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_txtHandlerLoc*/}

                {/* END_USER_CODE-USER_BEFORE_txtHandlerLoc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHandlerLoc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHandlerLoc*/}

                {/* END_USER_CODE-USER_AFTER_txtHandlerLoc*/}
                {/* START_USER_CODE-USER_BEFORE_txtCCCHandler*/}

                {/* END_USER_CODE-USER_BEFORE_txtCCCHandler*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCCCHandler}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCCCHandler*/}

                {/* END_USER_CODE-USER_AFTER_txtCCCHandler*/}
                {/* START_USER_CODE-USER_BEFORE_txtClearingAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtClearingAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtClearingAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtClearingAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtClearingAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtContractPendingMaxDays*/}

                {/* END_USER_CODE-USER_BEFORE_txtContractPendingMaxDays*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContractPendingMaxDays}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContractPendingMaxDays*/}

                {/* END_USER_CODE-USER_AFTER_txtContractPendingMaxDays*/}
                {/* START_USER_CODE-USER_BEFORE_ddDisplay*/}

                {/* END_USER_CODE-USER_BEFORE_ddDisplay*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDisplay}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDisplay*/}

                {/* END_USER_CODE-USER_AFTER_ddDisplay*/}
                {/* START_USER_CODE-USER_BEFORE_lblMin*/}

                {/* END_USER_CODE-USER_BEFORE_lblMin*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMin}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMin*/}

                {/* END_USER_CODE-USER_AFTER_lblMin*/}
                {/* START_USER_CODE-USER_BEFORE_lblMax*/}

                {/* END_USER_CODE-USER_BEFORE_lblMax*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMax}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMax*/}

                {/* END_USER_CODE-USER_AFTER_lblMax*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernalHullRangeMin*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernalHullRangeMin*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernalHullRangeMin}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernalHullRangeMin*/}

                {/* END_USER_CODE-USER_AFTER_txtKernalHullRangeMin*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernalHullRangeMax*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernalHullRangeMax*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernalHullRangeMax}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernalHullRangeMax*/}

                {/* END_USER_CODE-USER_AFTER_txtKernalHullRangeMax*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxAreaProfile*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxAreaProfile*/}

                <CheckboxWidget
    onChange={event =>
      invokeEventHandler(event, handleChange, handleBlur, thisObj)
    }
    onBlur={handleBlur}
    values={values}
    touched={touched}
    errors={errors}
    conf={state.chkboxLandPlaster}
    screenConf={state}
  ></CheckboxWidget>
  <CheckboxWidget
  onChange={event =>
    invokeEventHandler(event, handleChange, handleBlur, thisObj)
  }
  onBlur={handleBlur}
  values={values}
  touched={touched}
  errors={errors}
  conf={state.chkboxForeignMaterial}
  screenConf={state}
></CheckboxWidget>
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
                {/* START_USER_CODE-USER_BEFORE_gridAreaProfileData*/}

                {/* END_USER_CODE-USER_BEFORE_gridAreaProfileData*/}

                <GridWidget
                  conf={state.gridAreaProfileData}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAreaProfileData}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAreaProfileData*/}

                {/* END_USER_CODE-USER_AFTER_gridAreaProfileData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAreaProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAreaProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_AreaProfile*/}

              {/* END_USER_CODE-USER_AFTER_AreaProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_AreaProfile);
