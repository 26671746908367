/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  CheckboxWidget,
  setData,
  getData,
  setValue,
  getValue,
  hide,
} from "../../shared/WindowImports";

import "./1007GroupingProfile.scss";
import { returnNumericValues } from './../../Common/Constants';
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
//import {WarehouseReceiptService} from"../../WarehouseReceipts/Service/WarehouseReceiptService";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { string } from "yup";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_1007GroupingProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromSS = () => (sessionStorage.getItem('compId'));
  const cropYearFromSS = () => (JSON.parse(sessionStorage.getItem('year')));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "1007GroupingProfile",
    windowName: "1007GroupingProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.1007GroupingProfile",
    // START_USER_CODE-USER_1007GroupingProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "1007 Grouping Profile",
      scrCode: "PN1160B",
    },
    // END_USER_CODE-USER_1007GroupingProfile_PROPERTIES
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntntrActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntntrActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnSearchFor1007: {
      name: "btnSearchFor1007",
      type: "ButtonWidget",
      parent: "cmmndCntntrActions",
      Label: "Search for 1007",
      CharWidth: "31",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearchFor1007_PROPERTIES

      // END_USER_CODE-USER_btnSearchFor1007_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "cmmndCntntrActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    chkboxCMA: {
      name: "chkboxCMA",
      type: "CheckBoxWidget",
      parent: "grpbxDetails",
      Label: "CMA",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA_PROPERTIES
    },
    chkboxDefferedRedemption: {
      name: "chkboxDefferedRedemption",
      type: "CheckBoxWidget",
      parent: "grpbxDetails",
      Label: "Deffered Redemption",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDefferedRedemption_PROPERTIES

      // END_USER_CODE-USER_chkboxDefferedRedemption_PROPERTIES
    },
    chkboxDMA: {
      name: "chkboxDMA",
      type: "CheckBoxWidget",
      parent: "grpbxDetails",
      Label: "DMA",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDMA_PROPERTIES

      // END_USER_CODE-USER_chkboxDMA_PROPERTIES
    },
    col1007Num: {
      name: "col1007Num",
      type: "GridColumnWidget",
      parent: "grid1007Grouping",
      Label: "1007 #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007Num_PROPERTIES

      // END_USER_CODE-USER_col1007Num_PROPERTIES
    },
    colContractNum: {
      name: "colContractNum",
      type: "GridColumnWidget",
      parent: "grid1007Grouping",
      Label: "Contract #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractNum_PROPERTIES

      // END_USER_CODE-USER_colContractNum_PROPERTIES
    },
    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "grid1007Grouping",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    ddHolder: {
      name: "ddHolder",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Holder:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddHolder_PROPERTIES

      // END_USER_CODE-USER_ddHolder_PROPERTIES
    },
    grid1007Grouping: {
      name: "grid1007Grouping",
      type: "GridWidget",
      parent: "grpbx1007GroupingProfile",
      gridCellsOrder: "txtcol1007Num,txtcolContractNum,txtcolLocation,txtIStatus",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isCobolTable: true,
      isEditable: true,
      // START_USER_CODE-USER_grid1007Grouping_PROPERTIES

      // END_USER_CODE-USER_grid1007Grouping_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbx1007GroupingProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    txtColIsStatus: {
      name: "txtColIsStatus",
      type: "GridColumnWidget",
      parent: "grid1007Grouping",
      Label: "",
      HasLabel: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget7_PROPERTIES



      // END_USER_CODE-USER_gridcolumnwidget7_PROPERTIES
    },
    txtIStatus: {
      name: "txtIStatus",
      type: "TextBoxWidget",
      colName: "txtColIsStatus",
      parent: "grid1007Grouping",
      Label: "",
      HasLabel: false,
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget8_PROPERTIES



      // END_USER_CODE-USER_textboxwidget8_PROPERTIES
    },
    txtcol1007Num: {
      name: "txtcol1007Num",
      type: "TextBoxWidget",
      colName: "col1007Num",
      parent: "grid1007Grouping",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 0 },
      ofTypeDomain: "d_String",
      restrictPaste: true,
      // START_USER_CODE-USER_txtcol1007Num_PROPERTIES

      // END_USER_CODE-USER_txtcol1007Num_PROPERTIES
    },
    txtcolContractNum: {
      name: "txtcolContractNum",
      type: "TextBoxWidget",
      colName: "colContractNum",
      parent: "grid1007Grouping",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractNum_PROPERTIES

      // END_USER_CODE-USER_txtcolContractNum_PROPERTIES
    },
    txtcolLocation: {
      name: "txtcolLocation",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "grid1007Grouping",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolLocation_PROPERTIES
    },
    txtDate: {
      name: "txtDate",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Date:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDate_PROPERTIES

      // END_USER_CODE-USER_txtDate_PROPERTIES
    },
    txtGroupingNum: {
      name: "txtGroupingNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Grouping #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroupingNum_PROPERTIES

      // END_USER_CODE-USER_txtGroupingNum_PROPERTIES
    },
    grpbx1007GroupingProfile: {
      name: "grpbx1007GroupingProfile",
      type: "GroupBoxWidget",
      parent: "1007GroupingProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx1007GroupingProfile_PROPERTIES

      // END_USER_CODE-USER_grpbx1007GroupingProfile_PROPERTIES
    },
    cmmndCntntrActions: {
      name: "cmmndCntntrActions",
      type: "CommandContainerWidget",
      parent: "1007GroupingProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntntrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntntrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    hide(thisObj, "btnSearchFor1007");
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  async function FormLoad() {
    
   await bFillHolderList();
   await Load1007GroupingProfileData();
  }
  const bFillHolderList = async () => {
    try
    {
      setLoading(true);
    let js = []
    js.push({ key: '', description: '>>> All Holders <<<' })
    let response = await WarehouseReceiptService.RetrieveVendorHolderTransferDetails(compIdFromSS(), cropYearFromSS());
    if (response !== undefined && response.length > 0) {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].holder_id, description: data[i].holder_name }
        js.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddHolder: {
          ...state["ddHolder"],
          valueList: js,
        }
      }
    })
  }
  catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    } else {
      showMessage(thisObj, 'An error occured during bFillHolderList ')
    }
  }
  finally
  {
    setLoading(false);
  }
  }
  async function SetDefaultHolder(flg) {
    try {
      setLoading(true);
      let dfltFound = false;
      let lstDefaultHolder;
      let lstHolderVendor;
      let frm1007GroupingProfileLoadData = getData(thisObj, 'openButtonData')
    let response=await WarehouseReceiptService.RetrieveVendorHolderTransferDetails(compIdFromSS(), cropYearFromSS())
        if (response !== undefined && response.length > 0) {
          let data = response

          if (getData(thisObj, "chkboxCMA") == true) {
            for (var i = 0; i < data.length - 1; i++) {
              lstDefaultHolder = data[i].holder_default;
              lstHolderVendor = data[i].holder_vendor;
              if (lstDefaultHolder == "C") {
                if (lstHolderVendor == frm1007GroupingProfileLoadData[0].loccmavendor) {
                  setValue(thisObj, "ddHolder", data[i].holder_id)
                  dfltFound = true;
                  return;
                }
              }
            }
            if (dfltFound == false) {
              for (var i = 0; i < data.length; i++) {
                lstDefaultHolder = data[i].holder_default;
                if (lstDefaultHolder == "Y") {
                  setValue(thisObj, "ddHolder", data[i].holder_id)
                  dfltFound = true;
                  break;
                }
              }
            }
          }
          if (getData(thisObj, "chkboxDMA") == true) {
            for (var i = 0; i < data.length; i++) {
              lstDefaultHolder = data[i].holder_default;
              if (lstDefaultHolder == "D") {
                setValue(thisObj, "ddHolder", data[i].holder_id)
                dfltFound = true;
                break;
              }
            }
          }
          if (getData(thisObj, "chkboxDMA") == false && getData(thisObj, "chkboxCMA") == false) {
            for (var i = 0; i < data.length; i++) {
              lstDefaultHolder = data[i].holder_default;
              if (lstDefaultHolder == "Y") {
                setValue(thisObj, "ddHolder", data[i].holder_id)
                dfltFound = true;
                break;
              }
            }
          }

        }
        if(flg==0)
        {
      if (frm1007GroupingProfileLoadData != null && frm1007GroupingProfileLoadData != undefined && frm1007GroupingProfileLoadData.length > 0) {
        let frm1007GroupingProfileDataRowObj1 = frm1007GroupingProfileLoadData[0]
        if (frm1007GroupingProfileDataRowObj1.Holder != null && frm1007GroupingProfileDataRowObj1.Holder != undefined && frm1007GroupingProfileDataRowObj1.Holder != "") {
          setValue(thisObj, "ddHolder", frm1007GroupingProfileDataRowObj1.Holder);
        }
      }
    }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Error occurred while page loading 1007GroupingProfile screen data")
      }
    }
    finally
    {
      setLoading(false);
    }
  }
  async function Load1007GroupingProfileData() {
    try {
      setLoading(true);
      let groupingProfile1007GridDataArray = []
      let groupingProfile1007GridDataObj = {}
      let frm1007GroupingProfileLoadData = getData(thisObj, 'openButtonData')

      if (frm1007GroupingProfileLoadData != null && frm1007GroupingProfileLoadData != undefined && frm1007GroupingProfileLoadData.length > 0) {

        let frm1007GroupingProfileDataRowObj1 = frm1007GroupingProfileLoadData[0]

        setValue(thisObj, "txtGroupingNum", frm1007GroupingProfileDataRowObj1.SettlementGroupingNumber)
        if (frm1007GroupingProfileDataRowObj1.CMADMA == "C") {
          setValue(thisObj, "chkboxCMA", true)
          setData(thisObj, "chkboxCMA", true);
          setData(thisObj, "chkboxDMA", false);
        }
        else if (frm1007GroupingProfileDataRowObj1.CMADMA == "D") {
          setValue(thisObj, "chkboxDMA", true)
          setData(thisObj, "chkboxCMA", false);
          setData(thisObj, "chkboxDMA", true);
        }
        else {
          setValue(thisObj, "chkboxCMA", false)
          setValue(thisObj, "chkboxDMA", false)
          setData(thisObj, "chkboxCMA", false);
          setData(thisObj, "chkboxDMA", false);
        }


        if (frm1007GroupingProfileDataRowObj1.DeferredRedemption == "Y") {
          setValue(thisObj, "chkboxDefferedRedemption", true)
        }
        else {
          setValue(thisObj, "chkboxDefferedRedemption", false)
        }


        if (frm1007GroupingProfileDataRowObj1.grpdate != null && frm1007GroupingProfileDataRowObj1.grpdate != undefined && frm1007GroupingProfileDataRowObj1.grpdate != "") {
          setValue(thisObj, "txtDate", moment(String(frm1007GroupingProfileDataRowObj1.grpdate)).format('MM/DD/YYYY'))
        }

        for (let i = 0; i < frm1007GroupingProfileLoadData.length; i++) {
          groupingProfile1007GridDataObj.txtcol1007Num = frm1007GroupingProfileLoadData[i].g1007Number
          if (frm1007GroupingProfileLoadData[i].ContNum != "0")
            groupingProfile1007GridDataObj.txtcolContractNum = frm1007GroupingProfileLoadData[i].ContNum
          if (frm1007GroupingProfileLoadData[i].buy_pt_idvisible == true)
            groupingProfile1007GridDataObj.txtcolLocation = frm1007GroupingProfileLoadData[i].buy_pt_id
          groupingProfile1007GridDataObj.buy_pt_id = frm1007GroupingProfileLoadData[i].buy_pt_id
          groupingProfile1007GridDataArray.push(groupingProfile1007GridDataObj)
          groupingProfile1007GridDataObj = {}

        }

        setValue(thisObj, "grid1007Grouping", groupingProfile1007GridDataArray)
       await SetDefaultHolder(0);


      }
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Error occurred while page loading 1007GroupingProfile screen data")
      }
    }
    finally
    {
      setLoading(false);
    }
  }
  const onchkboxCMAChange = (e) => {
    //alert(getValue(thisObj, 'chkboxCMA'))
    if(getValue(thisObj, 'chkboxCMA')==false)
    {
    setValue(thisObj, 'chkboxCMA', 0)
    setData(thisObj, "chkboxCMA", false);
    }
    else
    {
    setValue(thisObj, 'chkboxCMA', 1)
    setData(thisObj, "chkboxCMA", true);
    }
    setValue(thisObj, 'chkboxDMA', 0)
    setData(thisObj, "chkboxDMA", false);
    SetDefaultHolder(1);
  }
  thisObj.onchkboxCMAChange = onchkboxCMAChange;

  const onchkboxDMAChange = () => {
    if(getValue(thisObj, 'chkboxDMA')==true)
    {
      setValue(thisObj, 'chkboxDMA', 1)
      setData(thisObj, "chkboxDMA", true);
    }
    else
    {
      setValue(thisObj, 'chkboxDMA', 0)
      setData(thisObj, "chkboxDMA", false);
    }
    setValue(thisObj, 'chkboxCMA', 0)
    setData(thisObj, "chkboxCMA", false);

    SetDefaultHolder(1);
  }
  thisObj.onchkboxDMAChange = onchkboxDMAChange;
  const onbtnDeleteClick = async (event) => {
    if (confirm("Are you sure you want to delete this 1007 # Grouping?") == true) {
      let dataobj = {
        applied: "",
        cont_type: "",
        vendor: "",
        bps_list: "",
        settle_grp_cma_dma_ind: "",
        settle_grp_deferred_ind: "",
        settle_grp_date: null,
        settle_grp_holder_id: ""

      }
      WarehouseReceiptService.Update1007Grouping("DELETE", getValue(thisObj, "txtGroupingNum"), dataobj).then(response => {
        if (response.status == 200) {
          setData(thisObj, "parentscreenrefresh", true);
          setData(thisObj, 'openButtonData', "");
          setData(thisObj, "chkboxCMA", false);
          setData(thisObj, "chkboxDMA", false);
          showMessage(thisObj, "1007 Grouping has been deleted.", true);
          document.getElementById("WarehouseReceipts_1007GroupingProfilePopUp").childNodes[0].click();
        }
        else {
          showMessage(thisObj, "1007 Grouping DELETE failed.DELETE 1007 Grouping Error.");
        }
      })
    }

  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
  const onbtnUpdateClick = async (event) => {
    try
    {
      setLoading(true);
    let s = "";
    let grid1007Grouping = getValue(thisObj, "grid1007Grouping");
    if (grid1007Grouping.length > 0) {
      for (let i = 0; i < grid1007Grouping.length; i++) {

        if (isNumeric(grid1007Grouping[i].txtcol1007Num)) {
          if(grid1007Grouping[i].txtcolContractNum!=undefined)
          s = s + grid1007Grouping[i].buy_pt_id + "," + grid1007Grouping[i].txtcol1007Num + "," + grid1007Grouping[i].txtcolContractNum + "|";
          else
          s = s + grid1007Grouping[i].buy_pt_id + "," + grid1007Grouping[i].txtcol1007Num + ",0|";
        }
      }
    }

    let sCMAorDMA = "";
    if (getValue(thisObj, "chkboxCMA") == true) {
      sCMAorDMA = "C";
      if (getValue(thisObj, "ddHolder") == "") {
        showMessage(thisObj, "Holder must be selected for CMA!");
        return false;
      }
    }
    else if (getValue(thisObj, "chkboxDMA") == true) {
      sCMAorDMA = "D";
      if (getValue(thisObj, "ddHolder") == "") {
        showMessage(thisObj, "Holder must be selected for CMA!");
        return false;
      }
    }
    else {
      sCMAorDMA = "N";
    }
    let sdeferred = "";
    if (getValue(thisObj, "chkboxDefferedRedemption") == true) {
      sdeferred = "Y";
    }
    else {
      sdeferred = "N";
    }
    let dataobj = {
      applied: "",
      cont_type: "",
      vendor: "",
      bps_list: s,
      settle_grp_cma_dma_ind: sCMAorDMA,
      settle_grp_deferred_ind: sdeferred,
      settle_grp_date: getValue(thisObj, "txtDate"),
      settle_grp_holder_id: getValue(thisObj, "ddHolder")

    }

    let response = await WarehouseReceiptService.Update1007Grouping("UPDATE", getValue(thisObj, "txtGroupingNum"), dataobj)
    if (response.status == 200) {
      setData(thisObj, "parentscreenrefresh", true);
      setData(thisObj, 'openButtonData', "");
      setData(thisObj, "chkboxCMA", false);
      setData(thisObj, "chkboxDMA", false);
      showMessage(thisObj, "1007 Grouping update was successful.", true);
      document.getElementById("WarehouseReceipts_1007GroupingProfilePopUp").childNodes[0].click();
    }
    else {
      showMessage(thisObj, "1007 Grouping UPDATE failed.UPDATE 1007 Grouping Error.");
    }
  } catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(thisObj, "Error occurred updating 1007GroupingProfile screen data")
    }
  }
  finally
  {
    setLoading(false);
  }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;
  const onbtnExitClick = () => {
    document
      .getElementById("WarehouseReceipts_1007GroupingProfilePopUp")
      .childNodes[0].click();
    return true;
  }
  thisObj.onbtnExitClick = onbtnExitClick;
  function isNumeric(value) {
    return value !== '' && !isNaN(parseFloat(value)) && isFinite(value);
  }
  const validatetextbox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
        res += data[i]
      }
    }
    return res;
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_1007GroupingProfile*/}

              {/* END_USER_CODE-USER_BEFORE_1007GroupingProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbx1007GroupingProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1007GroupingProfile*/}

              <GroupBoxWidget
                conf={state.grpbx1007GroupingProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtGroupingNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGroupingNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGroupingNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGroupingNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtGroupingNum*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxCMA*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxCMA*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxCMA}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxCMA*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxCMA*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxDMA*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxDMA*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxDMA}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxDMA*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxDMA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtDate*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxDefferedRedemption*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxDefferedRedemption*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxDefferedRedemption}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxDefferedRedemption*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxDefferedRedemption*/}
                  {/* START_USER_CODE-USER_BEFORE_ddHolder*/}

                  {/* END_USER_CODE-USER_BEFORE_ddHolder*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddHolder}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddHolder*/}

                  {/* END_USER_CODE-USER_AFTER_ddHolder*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grid1007Grouping*/}

                {/* END_USER_CODE-USER_BEFORE_grid1007Grouping*/}

                <GridWidget
                  conf={state.grid1007Grouping}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.grid1007Grouping}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_grid1007Grouping*/}

                {/* END_USER_CODE-USER_AFTER_grid1007Grouping*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1007GroupingProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1007GroupingProfile*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntntrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntntrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntntrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSearchFor1007*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearchFor1007*/}

                <ButtonWidget
                  conf={state.btnSearchFor1007}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearchFor1007*/}

                {/* END_USER_CODE-USER_AFTER_btnSearchFor1007*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntntrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntntrActions*/}

              {/* START_USER_CODE-USER_AFTER_1007GroupingProfile*/}

              {/* END_USER_CODE-USER_AFTER_1007GroupingProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_1007GroupingProfile);
