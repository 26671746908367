/* eslint-disable*/
import { ApiService } from '../../Common/ApiService';

export const StockTransferService = {
    RemoveFreightAccural,
    RetrieveMultistopAvailableTransferCheckDetails,
    RetrievefreightAccrualSearchDetails,
    RetrieveTransferHeaderList,
    RetrieveSettlementFreightAccrualDocuments,
    UpdateSettlementFreightAcculInvoice,
    RetrieveMultistopAvailableTransferDetails,
    RetrieveBinInventoryDetails,
    RetrieveMultistopTripDetails,
    CreateTransferHeader,
    UpdateFreightAccrual,
    UpdateFaInvoiceOnTransfer,
    RetrieveSettlementFreightAccrualDocumentDrafts,
    RetrieveFreightAccrualSearchUnreleased,
    CreateReleaseFaRepor,
    CreateSettlementFreightAccrual,
    UpdateFaBatchReleaseReadyToPayDetails,
    RetrieveTransferHeaderDetails,
    RetrieveTransferHeaderSelectDetails,
    RetrieveLeasedTrailerListDetails,
    RetrievePriceScheduleSelectDetails,
    RetrieveDiscountScheduleSelectDetails,
    RetrieveAreaControlSelectDetails,
    RetrievePeanutTypeList,
    RetrieveMultistopEditChecks,
    UpdateMultistopTrip,
    RetrievePremiumDeductionSheduleListDetails,
    RetrieveStateControlList,
    RetrieveCountyControlList,
    RetrieveFarmControlList,
    CreateFrieghtAccural,
    UpdateTransferHeaderVoidUnvoidDetails,
    UpdateTransferHeader,
    RemoveFreightAccrualDelete,
    UpdateCheckWarehouseBinInventory,
    RetrieveWhouseBinInventoryList
};

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const stock_transfer_url_api = '/stock-transfer/v1/';

export function RemoveFreightAccural(buyingPointId, transferType, transferNumber, tripId, newCropYear) {
    let optionsParamsURL = '';

    if (tripId !== null && tripId !== '' && tripId !== undefined) {
        optionsParamsURL += `&trip_id=${tripId}`;
    }
    if (newCropYear !== null && newCropYear !== '' && newCropYear !== undefined) {
        optionsParamsURL += `&new_crop_year=${newCropYear}`;
    }
    return ApiService.removeData(
        isLocal() ?
            `/StockTransfer/RemoveFrieghtAccural/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buyingPointId}/transfer-types/${transferType}/transfer-numbers/${transferNumber}/frieghtaccurals?${optionsParamsURL}` :
            `${stock_transfer_url_api}companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/buying-points/${buyingPointId}/transfer-types/${transferType}/transfer-numbers/${transferNumber}/frieghtaccurals?${optionsParamsURL}`);
};

export function RetrieveMultistopAvailableTransferCheckDetails(func_sub_id, transfer_num, trip_id, transfer_type, orig_buy_pt, dest_buy_pt, frt_scac_id, frt_vendor, vehicle_num) {
    let optionsParamsURL = '';

    if (func_sub_id !== null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionsParamsURL += `&func_sub_id=${func_sub_id}`;
    }
    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionsParamsURL += `&transfer_num=${transfer_num}`;
    }
    if (trip_id !== null && trip_id !== '' && trip_id !== undefined) {
        optionsParamsURL += `&trip_id=${trip_id}`;
    }
    if (transfer_type !== null && transfer_type !== '' && transfer_type !== undefined) {
        optionsParamsURL += `&transfer_type=${transfer_type}`;
    }
    if (orig_buy_pt !== null && orig_buy_pt !== '' && orig_buy_pt !== undefined) {
        optionsParamsURL += `&orig_buy_pt=${orig_buy_pt}`;
    }
    if (dest_buy_pt !== null && dest_buy_pt !== '' && dest_buy_pt !== undefined) {
        optionsParamsURL += `&dest_buy_pt=${dest_buy_pt}`;
    }
    if (frt_scac_id !== null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionsParamsURL += `&frt_scac_id=${frt_scac_id}`;
    }
    if (frt_vendor !== null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionsParamsURL += `&frt_vendor=${frt_vendor}`;
    }
    if (vehicle_num !== null && vehicle_num !== '' && vehicle_num !== undefined) {
        optionsParamsURL += `&vehicle_num=${vehicle_num}`;
    }
    return ApiService.getData(
        isLocal() ?
            `/StockTransfer/RetrieveMultistopAvailableTransferCheckDetails/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/transferchecks?${optionsParamsURL}` :
            `${stock_transfer_url_api}companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/transferchecks?${optionsParamsURL}`);
}

export function RetrievefreightAccrualSearchDetails(data) {
    let optionalParamsUrl = '';
    if (data.shipLocId !== null && data.shipLocId !== undefined && data.shipLocId !== "") {
        optionalParamsUrl += `orig_buy_pt_id=${data.shipLocId}&`
    }
    if (data.destLocId !== null && data.destLocId !== undefined && data.destLocId !== "") {
        optionalParamsUrl += `dest_buy_pt_id=${data.destLocId}&`
    }
    if (data.vendor !== null && data.vendor !== undefined && data.vendor !== "") {
        optionalParamsUrl += `vendor_num=${data.vendor}&`
    }
    if (data.carrAbbrNum !== null && data.carrAbbrNum !== undefined && data.carrAbbrNum !== "") {
        optionalParamsUrl += `scac_id=${data.carrAbbrNum}&`
    }
    if (data.transNum !== null && data.transNum !== undefined && data.transNum !== "") {
        optionalParamsUrl += `xfer_num=${data.transNum}&`
    }
    if (data.cropYr !== null && data.cropYr !== undefined && data.cropYr !== "") {
        optionalParamsUrl += `crop_year=${data.cropYr}&`
    }
    if (data.pblCropYr !== null && data.pblCropYr !== undefined && data.pblCropYr !== "") {
        optionalParamsUrl += `pay_crop_year=${data.pblCropYr}&`
    }
    if (data.scaleTktNum !== null && data.scaleTktNum !== undefined && data.scaleTktNum !== "") {
        optionalParamsUrl += `scale_tick=${data.scaleTktNum}&`
    }
    if (data.invoiceNum !== null && data.invoiceNum !== undefined && data.invoiceNum !== "") {
        optionalParamsUrl += `invoice_num=${data.invoiceNum}&`
    }
    if (data.memoNum !== null && data.memoNum !== undefined && data.memoNum !== "") {
        optionalParamsUrl += `memo_num=${data.memoNum}&`
    }
    if (data.dispatchNum !== null && data.dispatchNum !== undefined && data.dispatchNum !== "") {
        optionalParamsUrl += `dispatch_num=${data.dispatchNum}&`
    }
    if (data.checkNum !== null && data.checkNum !== undefined && data.checkNum !== "") {
        optionalParamsUrl += `check_num=${data.checkNum}&`
    }
    if (data.dateFrom !== null && data.dateFrom !== undefined && data.dateFrom !== "") {
        optionalParamsUrl += `st_date=${data.dateFrom}&`
    }
    if (data.dateTo !== null && data.dateTo !== undefined && data.dateTo !== "") {
        optionalParamsUrl += `end_date=${data.dateTo}&`
    }

    return ApiService.getData(isLocal() ? `/StockTransfer/RetrievefreightAccrualSearchDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/freightaccrualsearches?${optionalParamsUrl}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/freightaccrualsearches?${optionalParamsUrl}`)
}

export function RetrieveTransferHeaderList(func_id, func_sub_id, transfer_num, transfer_type, xfer_status, seg_type, peanut_type, start_shp_date, end_shp_date, start_rec_date, end_rec_date, shp_buy_pt, from_whse_num, from_bin_num, from_variety, from_generation, from_oleic, from_organic_ind, rec_buy_pt, to_whse_num, to_whse_bin, to_variety, to_generation, to_oleic, to_organic_ind, pay_freight_ind, frt_accrl_complete, frt_scac_id, frt_vendor) {
    let optionsParamsURL = '';
    if (func_id !== null && func_id !== '' && func_id !== undefined) {
        optionsParamsURL += `&func_id=${func_id}`;
    }
    if (func_sub_id !== null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionsParamsURL += `&func_sub_id=${func_sub_id}`;
    }
    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionsParamsURL += `&transfer_num=${transfer_num}`;
    }
    if (transfer_type !== null && transfer_type !== '' && transfer_type !== undefined) {
        optionsParamsURL += `&transfer_type=${transfer_type}`;
    }
    if (xfer_status !== null && xfer_status !== '' && xfer_status !== undefined) {
        optionsParamsURL += `&xfer_status=${xfer_status}`;
    }
    if (seg_type !== null && seg_type !== '' && seg_type !== undefined) {
        optionsParamsURL += `&seg_type=${seg_type}`;
    }
    if (peanut_type !== null && peanut_type !== '' && peanut_type !== undefined) {
        optionsParamsURL += `&peanut_type=${peanut_type}`;
    }
    if (start_shp_date !== null && start_shp_date !== '' && start_shp_date !== undefined) {
        optionsParamsURL += `&start_shp_date=${start_shp_date}`;
    }
    if (end_shp_date !== null && end_shp_date !== '' && end_shp_date !== undefined) {
        optionsParamsURL += `&end_shp_date=${end_shp_date}`;
    }
    if (start_rec_date !== null && start_rec_date !== '' && start_rec_date !== undefined) {
        optionsParamsURL += `&start_rec_date=${start_rec_date}`;
    }
    if (end_rec_date !== null && end_rec_date !== '' && end_rec_date !== undefined) {
        optionsParamsURL += `&end_rec_date=${end_rec_date}`;
    }
    if (shp_buy_pt !== null && shp_buy_pt !== '' && shp_buy_pt !== undefined) {
        optionsParamsURL += `&shp_buy_pt=${shp_buy_pt}`;
    }
    if (from_whse_num !== null && from_whse_num !== '' && from_whse_num !== undefined) {
        optionsParamsURL += `&from_whse_num=${from_whse_num}`;
    }
    if (from_bin_num !== null && from_bin_num !== '' && from_bin_num !== undefined) {
        optionsParamsURL += `&from_bin_num=${from_bin_num}`;
    }
    if (from_variety !== null && from_variety !== '' && from_variety !== undefined) {
        optionsParamsURL += `&from_variety=${from_variety}`;
    }
    if (from_generation !== null && from_generation !== '' && from_generation !== undefined) {
        optionsParamsURL += `&from_generation=${from_generation}`;
    }
    if (from_oleic !== null && from_oleic !== '' && from_oleic !== undefined) {
        optionsParamsURL += `&from_oleic=${from_oleic}`;
    }
    if (from_organic_ind !== null && from_organic_ind !== '' && from_organic_ind !== undefined) {
        optionsParamsURL += `&from_organic_ind=${from_organic_ind}`;
    }
    if (rec_buy_pt !== null && rec_buy_pt !== '' && rec_buy_pt !== undefined) {
        optionsParamsURL += `&rec_buy_pt=${rec_buy_pt}`;
    }
    if (to_whse_num !== null && to_whse_num !== '' && to_whse_num !== undefined) {
        optionsParamsURL += `&to_whse_num=${to_whse_num}`;
    }
    if (to_whse_bin !== null && to_whse_bin !== '' && to_whse_bin !== undefined) {
        optionsParamsURL += `&to_whse_bin=${to_whse_bin}`;
    }
    if (to_variety !== null && to_variety !== '' && to_variety !== undefined) {
        optionsParamsURL += `&to_variety=${to_variety}`;
    }
    if (to_generation !== null && to_generation !== '' && to_generation !== undefined) {
        optionsParamsURL += `&to_generation=${to_generation}`;
    }
    if (to_oleic !== null && to_oleic !== '' && to_oleic !== undefined) {
        optionsParamsURL += `&to_oleic=${to_oleic}`;
    }
    if (to_organic_ind !== null && to_organic_ind !== '' && to_organic_ind !== undefined) {
        optionsParamsURL += `&to_organic_ind=${to_organic_ind}`;
    }
    if (pay_freight_ind !== null && pay_freight_ind !== '' && pay_freight_ind !== undefined) {
        optionsParamsURL += `&pay_freight_ind=${pay_freight_ind}`;
    }
    if (frt_accrl_complete !== null && frt_accrl_complete !== '' && frt_accrl_complete !== undefined) {
        optionsParamsURL += `&frt_accrl_complete=${frt_accrl_complete}`;
    }
    if (frt_scac_id !== null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionsParamsURL += `&frt_scac_id=${frt_scac_id}`;
    }
    if (frt_vendor !== null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionsParamsURL += `&frt_vendor=${frt_vendor}`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveTransferHeaderList/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/transferheaders?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/transferheaders?${optionsParamsURL}`);
}

export function RetrieveSettlementFreightAccrualDocuments(cropyr, payCropYr, memoNum) {
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveSettlementFreightAccrualDocuments/companies/${compIdFromLS()}/pay-crop-years/${payCropYr}/memo-numbers/${memoNum}/freightaccrualdocuments?crop_year=${cropyr}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/pay-crop-years/${payCropYr}/memo-numbers/${memoNum}/freightaccrualdocuments?crop_year=${cropyr}`)
}

export function UpdateSettlementFreightAcculInvoice(cropYr, memoNum, data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateSettlementFreightAcculInvoice/companies/${compIdFromLS()}/crop-years/${cropYr}/users/${useridFromLS()}/memo-numbers/${memoNum}/freightacculinvoices` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYr}/users/${useridFromLS()}/memo-numbers/${memoNum}/freightacculinvoices`, data)
}

export function RetrieveMultistopAvailableTransferDetails(transfer_num, transfer_type, orig_buy_pt, dest_buy_pt, frt_scac_id, frt_vendor, vehichle_num, func_sub_id, trip_id) {
    let optionalParamsUrl = '';

    if (compIdFromLS() !== null && compIdFromLS() !== '' && compIdFromLS() !== undefined) {
        optionalParamsUrl += `&comp_id=${compIdFromLS()}`;
    }
    if (cropYearFromLS() !== null && cropYearFromLS() !== '' && cropYearFromLS() !== undefined) {
        optionalParamsUrl += `&crop_year=${cropYearFromLS()}`;
    }

    if (useridFromLS() !== null && useridFromLS() !== '' && useridFromLS() !== undefined) {
        optionalParamsUrl += `&user_id=${useridFromLS()}`;
    }

    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionalParamsUrl += `&transfer_num=${transfer_num}`;
    }
    if (transfer_type !== null && transfer_type !== '' && transfer_type !== undefined) {
        optionalParamsUrl += `&transfer_type=${transfer_type}`;
    }
    if (orig_buy_pt !== null && orig_buy_pt !== '' && orig_buy_pt !== undefined) {
        optionalParamsUrl += `&orig_buy_pt=${orig_buy_pt}`;
    }
    if (dest_buy_pt !== null && dest_buy_pt !== '' && dest_buy_pt !== undefined) {
        optionalParamsUrl += `&dest_buy_pt=${dest_buy_pt}`;
    }

    if (trip_id !== null && trip_id !== '' && trip_id !== undefined) {
        optionalParamsUrl += `&trip_id=${trip_id}`;
    }

    if (func_sub_id !== null && func_sub_id !== '' && func_sub_id !== undefined) {
        optionalParamsUrl += `&func_sub_id=${func_sub_id}`;
    }

    if (vehichle_num !== null && vehichle_num !== '' && vehichle_num !== undefined) {
        optionalParamsUrl += `&vehichle_num=${vehichle_num}`;
    }

    if (frt_vendor !== null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionalParamsUrl += `&frt_vendor=${frt_vendor}`;
    }

    if (frt_scac_id !== null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionalParamsUrl += `&frt_scac_id=${frt_scac_id}`;
    }

    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveMultistopAvailableTransferDetails/availabletransfers?${optionalParamsUrl}` :
        `${stock_transfer_url_api}/availabletransfers?${optionalParamsUrl}`)
}

export function RetrieveBinInventoryDetails(spname, comp_id, crop_year, buy_pt_id, whouse_num, bin_num, transfer_num, inspect_num, settle_num) {
    let optionsParamsURL = '';

    if (comp_id !== null && comp_id !== '' && comp_id !== undefined) {
        optionsParamsURL += `comp_id=${comp_id}`;
    }
    if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
        optionsParamsURL += `&crop_year=${crop_year}`;
    }
    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionsParamsURL += `&buy_pt_id=${buy_pt_id}`;
    }
    if (whouse_num !== null && whouse_num !== '' && whouse_num !== undefined) {
        optionsParamsURL += `&whouse_num=${whouse_num}`;
    }
    if (bin_num !== null && bin_num !== '' && bin_num !== undefined) {
        optionsParamsURL += `&bin_num=${bin_num}`;
    }
    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionsParamsURL += `&transfer_num=${transfer_num}`;
    }
    if (inspect_num !== null && inspect_num !== '' && inspect_num !== undefined) {
        optionsParamsURL += `&inspect_num=${inspect_num}`;
    }
    if (settle_num !== null && settle_num !== '' && settle_num !== undefined) {
        optionsParamsURL += `&settle_num=${settle_num}`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveBinInventoryDetails/procedure-names/${spname}/bininventories?${optionsParamsURL}` :
        `${stock_transfer_url_api}procedure-names/${spname}/bininventories?${optionsParamsURL}`)
}

export function RetrieveMultistopTripDetails(transfer_num, trip_id, transfer_type, orig_buy_pt, dest_buy_pt) {
    let optionsParamsURL = '';
    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionsParamsURL += `&transfer_num=${transfer_num}`;
    }
    if (trip_id !== null && trip_id !== '' && trip_id !== undefined) {
        optionsParamsURL += `&trip_id=${trip_id}`;
    }
    if (transfer_type !== null && transfer_type !== '' && transfer_type !== undefined) {
        optionsParamsURL += `&transfer_type=${transfer_type}`;
    }
    if (orig_buy_pt !== null && orig_buy_pt !== '' && orig_buy_pt !== undefined) {
        optionsParamsURL += `&orig_buy_pt=${orig_buy_pt}`;
    }
    if (dest_buy_pt !== null && dest_buy_pt !== '' && dest_buy_pt !== undefined) {
        optionsParamsURL += `&dest_buy_pt=${dest_buy_pt}`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveMultistopTripDetails/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/multistoptrips?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/multistoptrips?${optionsParamsURL}`);
}

export function CreateTransferHeader(transferHeaderPayload) {
    return ApiService.postData(isLocal()
        ? `/StockTransfer/CreateTransferHeader/transferheaders`
        : `${stock_transfer_url_api}transferheaders`,
        transferHeaderPayload);
};

export function UpdateFreightAccrual(data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateFreightAccrual/freightaccruals` :
        `${stock_transfer_url_api}freightaccruals`, data)
};

export function UpdateFaInvoiceOnTransfer(frt_vendor, memo, data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateFaInvoiceOnTransfer/companies/${compIdFromLS()}/vendors/${frt_vendor}/memo-numbers/${memo}/fainvoices` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/vendors/${frt_vendor}/memo-numbers/${memo}/fainvoices`, data)
};

export function RetrieveSettlementFreightAccrualDocumentDrafts(pay_crop_year, transaction_list) {
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveSettlementFreightAccrualDocumentDrafts/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/pay-crop-years/${pay_crop_year}/transactions/${transaction_list}/freightaccrualdocumentdrafts` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/pay-crop-years/${pay_crop_year}/transactions/${transaction_list}/freightaccrualdocumentdrafts`)
}

export function RetrieveFreightAccrualSearchUnreleased(crop_year, coll_pt, shp_buy_pt, rec_buy_pt, frt_scac_id, frt_vendor) {
    let optionsParamsURL = ''
    if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
        optionsParamsURL += `crop_year=${crop_year}&`;
    }
    if (coll_pt !== null && coll_pt !== '' && coll_pt !== undefined) {
        optionsParamsURL += `coll_pt=${coll_pt}&`;
    }
    if (shp_buy_pt !== null && shp_buy_pt !== '' && shp_buy_pt !== undefined) {
        optionsParamsURL += `shp_buy_pt=${shp_buy_pt}&`;
    }
    if (rec_buy_pt !== null && rec_buy_pt !== '' && rec_buy_pt !== undefined) {
        optionsParamsURL += `rec_buy_pt=${rec_buy_pt}&`;
    }
    if (frt_scac_id !== null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionsParamsURL += `frt_scac_id=${frt_scac_id}&`;
    }
    if (frt_vendor !== null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionsParamsURL += `frt_vendor=${frt_vendor}&`;
    }


    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveFreightAccrualSearchUnreleased/companies/${compIdFromLS()}/freightaccrualsearches?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/freightaccrualsearches?${optionsParamsURL}`)
}

export function CreateReleaseFaRepor(data) {
    return ApiService.postData(isLocal()
        ? `/StockTransfer/CreateReleaseFaRepor/fareports`
        : `${stock_transfer_url_api}fareports`,
        data);
};

export function CreateSettlementFreightAccrual(data) {
    return ApiService.postData(isLocal()
        ? `/StockTransfer/CreateSettlementFreightAccrual/settlementfreightaccruals`
        : `${stock_transfer_url_api}settlementfreightaccruals`,
        data);
};

export function UpdateFaBatchReleaseReadyToPayDetails(buy_pt_id, data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateFaBatchReleaseReadyToPayDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/batchreadytopays` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/batchreadytopays`, data)
}

export function RetrieveTransferHeaderDetails(transfer_num, transfer_type, shp_buy_pt) {
    let optionsParamsURL = '';
    if (shp_buy_pt !== null && shp_buy_pt !== '' && shp_buy_pt !== undefined) {
        optionsParamsURL += `shp_buy_pt=${shp_buy_pt}&`;
    }
    if (transfer_type !== null && transfer_type !== '' && transfer_type !== undefined) {
        optionsParamsURL += `transfer_type=${transfer_type}&`;
    }
    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionsParamsURL += `transfer_num=${transfer_num}&`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveTransferHeaderDetails/companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/transferheaders?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/` + `${compIdFromLS()}/crop-Years/${cropYearFromLS()}/transferheaders?${optionsParamsURL}`);
}

export function RetrieveLeasedTrailerListDetails(crop_year, vehicle_num) {
    let optionsParamsURL = '';
    if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
        optionsParamsURL += `crop_year=${crop_year}&`;
    }
    if (vehicle_num !== null && vehicle_num !== '' && vehicle_num !== undefined) {
        optionsParamsURL += `vehicle_num=${vehicle_num}&`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveLeasedTrailerListDetails/companies/${compIdFromLS()}/leasedtrailers?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/leasedtrailers?${optionsParamsURL}`);
}

export function RetrieveTransferHeaderSelectDetails(transfer_num, transfer_type, shp_buy_pt, crop_year) {
    let optionsParamsURL = '';
    if (transfer_type !== null && transfer_type !== '' && transfer_type !== undefined) {
        optionsParamsURL += `transfer_type=${transfer_type}&`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveTransferHeaderSelectDetails/companies/${compIdFromLS()}/crop-years/${crop_year}/buying-points/${shp_buy_pt}/transfer-numbers/${transfer_num}/transferheaderselects?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${crop_year}/buying-points/${shp_buy_pt}/transfer-numbers/${transfer_num}/transferheaderselects?${optionsParamsURL}`);
}

export function RetrieveMultistopEditChecks(dest_buy_pt, vendor, scac, trip_num, leginfo, pay_ind, vehicle_num, thru_haul_ind) {
    let optionsParamsURL = '';
    if (dest_buy_pt !== null && dest_buy_pt !== '' && dest_buy_pt !== undefined) {
        optionsParamsURL += `dest_buy_pt=${dest_buy_pt}&`;
    }
    if (vendor !== null && vendor !== '' && vendor !== undefined) {
        optionsParamsURL += `vendor=${vendor}&`;
    }
    if (scac !== null && scac !== '' && scac !== undefined) {
        optionsParamsURL += `scac=${scac}&`;
    }
    if (trip_num !== null && trip_num !== '' && trip_num !== undefined) {
        optionsParamsURL += `trip_num=${trip_num}&`;
    }
    if (leginfo !== null && leginfo !== '' && leginfo !== undefined) {
        optionsParamsURL += `leginfo=${leginfo}&`;
    }
    if (pay_ind !== null && pay_ind !== '' && pay_ind !== undefined) {
        optionsParamsURL += `pay_ind=${pay_ind}&`;
    }
    if (vehicle_num !== null && vehicle_num !== '' && vehicle_num !== undefined) {
        optionsParamsURL += `vehicle_num=${vehicle_num}&`;
    }
    if (thru_haul_ind !== null && thru_haul_ind !== '' && thru_haul_ind !== undefined) {
        optionsParamsURL += `thru_haul_ind=${thru_haul_ind}&`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveMultistopEditChecks/companies/${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/multistopchecks?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-Years/${cropYearFromLS()}/users/${useridFromLS()}/multistopchecks?${optionsParamsURL}`);
}

export function UpdateMultistopTrip(buy_pt_id, data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateMultistopTrip/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/multistoptrips` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/multistoptrips`, data)
}

export function RetrievePriceScheduleSelectDetails(areaId, pnut_type_id) {
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrievePriceScheduleSelectDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/area-ids/${areaId}/pnut_types/${pnut_type_id}/pricescheduleselect` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/area-ids/${areaId}/pnut_types/${pnut_type_id}/pricescheduleselect`);
}

export function RetrieveDiscountScheduleSelectDetails(areaId, pnut_type_id) {
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrieveDiscountScheduleSelectDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/area-ids/${areaId}/pnut_types/${pnut_type_id}/discountscheduleselect` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/area-ids/${areaId}/pnut_types/${pnut_type_id}/discountscheduleselect`);
}

export function RetrieveAreaControlSelectDetails(areaId) {
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrieveAreaControlSelectDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/area-ids/${areaId}/areacontrolselect` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/area-ids/${areaId}/areacontrolselect`);
}

export function RetrievePeanutTypeList() {
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrievePeanutTypeList/peanuttypes` :
        `${stock_transfer_url_api}peanuttypes`);
}

export function RetrievePremiumDeductionSheduleListDetails(dest_buy_pt,shp_buy_pt,transfer_type,transfer_num,trip_id,effective_date,frt_accrl_complete,leased_trailer_ind,mileage_override,total_weight,total_mileage,rate_basis,frt_rate,net_wt,lsk_wt,gross_wt,basis_grade_amt,thru_haul_wt,thru_haul_miles,fuel_surcharge_pct,fuel_mileage_rate,fuel_surcharge_exp,new_crop_year,frt_scac_id,frt_vendor,crop_year=""){
    let conditionalYear = crop_year == "" ? cropYearFromLS() : crop_year
    let optionalParamsUrl='';
    if (trip_id !== null && trip_id !== '' && trip_id !== undefined) {
        optionalParamsUrl += `trip_id=${trip_id}&`;
    }

    if (effective_date !== null && effective_date !== '' && effective_date !== undefined) {
        optionalParamsUrl += `effective_date=${effective_date}&`;
    }

    if (frt_accrl_complete !== null && frt_accrl_complete !== '' && frt_accrl_complete !== undefined) {
        optionalParamsUrl += `frt_accrl_complete=${frt_accrl_complete}&`;
    }

    if (leased_trailer_ind !== null && leased_trailer_ind !== '' && leased_trailer_ind !== undefined) {
        optionalParamsUrl += `leased_trailer_ind=${leased_trailer_ind}&`;
    }

    if (mileage_override !== null && mileage_override !== '' && mileage_override !== undefined) {
        optionalParamsUrl += `mileage_override=${mileage_override}&`;
    }

    if (total_weight !== null && total_weight !== '' && total_weight !== undefined) {
        optionalParamsUrl += `total_weight=${total_weight}&`;
    }

    if (total_mileage !== null && total_mileage !== '' && total_mileage !== undefined) {
        optionalParamsUrl += `total_mileage=${total_mileage}&`;
    }

    if (rate_basis !== null && rate_basis !== '' && rate_basis !== undefined) {
        optionalParamsUrl += `rate_basis=${rate_basis}&`;
    }

    if (frt_rate !== null && frt_rate !== '' && frt_rate !== undefined) {
        optionalParamsUrl += `frt_rate=${frt_rate}&`;
    }
    if (net_wt !== null && net_wt !== '' && net_wt !== undefined) {
        optionalParamsUrl += `net_wt=${net_wt}&`;
    }
    if (lsk_wt !== null && lsk_wt !== '' && lsk_wt !== undefined) {
        optionalParamsUrl += `lsk_wt=${lsk_wt}&`;
    }
    if (gross_wt !== null && gross_wt !== '' && gross_wt !== undefined) {
        optionalParamsUrl += `gross_wt=${gross_wt}&`;
    }
    if (basis_grade_amt !== null && basis_grade_amt !== '' && basis_grade_amt !== undefined) {
        optionalParamsUrl += `basis_grade_amt=${basis_grade_amt}&`;
    }
    if (thru_haul_wt !== null && thru_haul_wt !== '' && thru_haul_wt !== undefined) {
        optionalParamsUrl += `thru_haul_wt=${thru_haul_wt}&`;
    }

    if (thru_haul_miles !== null && thru_haul_miles !== '' && thru_haul_miles !== undefined) {
        optionalParamsUrl += `thru_haul_miles=${thru_haul_miles}&`;
    }
    if (fuel_surcharge_pct !== null && fuel_surcharge_pct !== '' && fuel_surcharge_pct !== undefined) {
        optionalParamsUrl += `fuel_surcharge_pct=${fuel_surcharge_pct}&`;
    }
    if (fuel_mileage_rate !== null && fuel_mileage_rate !== '' && fuel_mileage_rate !== undefined) {
        optionalParamsUrl += `fuel_mileage_rate=${fuel_mileage_rate}&`;
    }
    if (fuel_surcharge_exp !== null && fuel_surcharge_exp !== '' && fuel_surcharge_exp !== undefined) {
        optionalParamsUrl += `fuel_surcharge_exp=${fuel_surcharge_exp}&`;
    }
    if (new_crop_year !== null && new_crop_year !== '' && new_crop_year !== undefined) {
        optionalParamsUrl += `new_crop_year=${new_crop_year}&`;
    }
    if (frt_scac_id !== null && frt_scac_id !== '' && frt_scac_id !== undefined) {
        optionalParamsUrl += `frt_scac_id=${frt_scac_id}&`;
    }
    if (frt_vendor !== null && frt_vendor !== '' && frt_vendor !== undefined) {
        optionalParamsUrl += `frt_vendor=${frt_vendor}&`;
    }

    return ApiService.getData(isLocal()? `/StockTransfer/RetrievePremiumDeductionSheduleListDetails/companies/${compIdFromLS()}/crop-years/${conditionalYear}/dest-buying-points/${dest_buy_pt}/shp-buying-points/${shp_buy_pt}/transfer-types/${transfer_type}/transfer-numbers/${transfer_num}/premiumdeductions?${optionalParamsUrl}`:
                                            `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${conditionalYear}/dest-buying-points/${dest_buy_pt}/shp-buying-points/${shp_buy_pt}/transfer-types/${transfer_type}/transfer-numbers/${transfer_num}/premiumdeductions?${optionalParamsUrl}`)
}

export function RetrieveStateControlList(comp_id,crop_year,buy_pt_id,filtered) {
    let optionalParamsUrl='';
    if (comp_id !== null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${comp_id}&`;
    }

    if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${crop_year}&`;
    }

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }

    if (filtered !== null && filtered !== '' && filtered !== undefined) {
        optionalParamsUrl += `filtered=${filtered}`;
    }
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrieveStateControlList/statecontrols?${optionalParamsUrl}` :
        `${stock_transfer_url_api}statecontrols?${optionalParamsUrl}`);
}

export function RetrieveCountyControlList(comp_id,crop_year,buy_pt_id,filtered,state_abbr) {
    let optionalParamsUrl='';
    if (comp_id !== null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${comp_id}&`;
    }

    if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${crop_year}&`;
    }

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }

    if (filtered !== null && filtered !== '' && filtered !== undefined) {
        optionalParamsUrl += `filtered=${filtered}&`;
    }

    if (state_abbr !== null && state_abbr !== '' && state_abbr !== undefined) {
        optionalParamsUrl += `state_abbr=${state_abbr}`;
    }
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrieveCountyControlList/countycontrols?${optionalParamsUrl}` :
        `${stock_transfer_url_api}countycontrols?${optionalParamsUrl}`);
}

export function RetrieveFarmControlList(comp_id,crop_year,buy_pt_id,county_id,state_abbr) {
    let optionalParamsUrl='';
    if (comp_id !== null && comp_id !== '' && comp_id !== undefined) {
        optionalParamsUrl += `comp_id=${comp_id}&`;
    }

    if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
        optionalParamsUrl += `crop_year=${crop_year}&`;
    }

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
    }

    if (county_id !== null && county_id !== '' && county_id !== undefined) {
        optionalParamsUrl += `county_id=${county_id}&`;
    }

    if (state_abbr !== null && state_abbr !== '' && state_abbr !== undefined) {
        optionalParamsUrl += `state_abbr=${state_abbr}`;
    }
    return ApiService.getData(isLocal() ?
        `/StockTransfer/RetrieveFarmControlList/farmcontrols?${optionalParamsUrl}` :
        `${stock_transfer_url_api}farmcontrols?${optionalParamsUrl}`);
}

export function CreateFrieghtAccural(data){
    return ApiService.postData(isLocal() ? `/StockTransfer/CreateFrieghtAccural/frieghtaccurals` :
        `${stock_transfer_url_api}frieghtaccurals`, data) 
}

export function UpdateTransferHeaderVoidUnvoidDetails(data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateTransferHeaderVoidUnvoidDetails/transfersheadervoids` :
        `${stock_transfer_url_api}transfersheadervoids`, data)
}

export function UpdateTransferHeader(data) {
    return ApiService.updateData(isLocal() ? `/StockTransfer/UpdateTransferHeader/transfers` :
        `${stock_transfer_url_api}transfers`, data)
}

export function RemoveFreightAccrualDelete(shp_buy_pt, transfer_num, transfer_type, trip_id, chg_user) {
    let optionsParamsURL = '';

    if (trip_id !== null && trip_id !== '' && trip_id !== undefined) {
        optionsParamsURL += `trip_id=${trip_id}&`;
    }
    if (chg_user !== null && chg_user !== '' && chg_user !== undefined) {
        optionsParamsURL += `chg_user=${chg_user}&`;
    }
    return ApiService.removeData(
        isLocal() ?
            `/StockTransfer/RemoveFreightAccrualDelete/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${shp_buy_pt}/transfer-numbers/${transfer_num}/transfer-types/${transfer_type}/freightaccruals?${optionsParamsURL}` :
            `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${shp_buy_pt}/transfer-numbers/${transfer_num}/transfer-types/${transfer_type}/freightaccruals?${optionsParamsURL}`);
};

export function UpdateCheckWarehouseBinInventory(sf_buy_pt, sf_whse_num, sf_bin, st_buy_pt, st_whse_num, st_bin, lbsofpeanut_old, lbsofpeanut, netweight_old, netweight, status_old, status, data) {
    let optionsParamsURL = '';

    if (lbsofpeanut_old !== undefined && lbsofpeanut_old !== null && lbsofpeanut_old !== "") {
        optionsParamsURL += `lbsofpeanut_old=${lbsofpeanut_old}&`;
    }
    if (lbsofpeanut !== undefined && lbsofpeanut !== null && lbsofpeanut !== "") {
        optionsParamsURL += `lbsofpeanut=${lbsofpeanut}&`;
    }
    if (netweight_old !== undefined && netweight_old !== null && netweight_old !== "") {
        optionsParamsURL += `netweight_old=${netweight_old}&`;
    }
    if (netweight !== undefined && netweight !== null && netweight !== "") {
        optionsParamsURL += `netweight=${netweight}&`;
    }
    if (status_old !== undefined && status_old !== null && status_old !== "") {
        optionsParamsURL += `status_old=${status_old}&`;
    }
    if (status_old !== undefined && status_old !== null && status_old !== "") {
        optionsParamsURL += `status=${status}`;
    }

    return ApiService.updateData(
        isLocal() ?
            `/StockTransfer/UpdateCheckWarehouseBinInventory/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/sf-buying-points/${sf_buy_pt}/sf-warehouse-numbers/${sf_whse_num}/sf-bins/${sf_bin}/st-buying-points/${st_buy_pt}/st-warehouse-numbers/${st_whse_num}/st-bins/${st_bin}/bininventories?${optionsParamsURL}`
            : `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/sf-buying-points/${sf_buy_pt}/sf-warehouse-numbers/${sf_whse_num}/sf-bins/${sf_bin}/st-buying-points/${st_buy_pt}/st-warehouse-numbers/${st_whse_num}/st-bins/${st_bin}/bininventories?${optionsParamsURL}`
        , data)
}

export function RetrieveWhouseBinInventoryList(buy_pt_id, whouse_num, bin_num, transfer_num, inspect_num, settle_num) {
    let optionsParamsURL = '';
    if (whouse_num !== null && whouse_num !== '' && whouse_num !== undefined) {
        optionsParamsURL += `&whouse_num=${whouse_num}`;
    }
    if (bin_num !== null && bin_num !== '' && bin_num !== undefined) {
        optionsParamsURL += `&bin_num=${bin_num}`;
    }
    if (transfer_num !== null && transfer_num !== '' && transfer_num !== undefined) {
        optionsParamsURL += `&transfer_num=${transfer_num}`;
    }
    if (inspect_num !== null && inspect_num !== '' && inspect_num !== undefined) {
        optionsParamsURL += `&inspect_num=${inspect_num}`;
    }
    if (settle_num !== null && settle_num !== '' && settle_num !== undefined) {
        optionsParamsURL += `&settle_num=${settle_num}`;
    }
    return ApiService.getData(isLocal() ? `/StockTransfer/RetrieveWhouseBinInventoryList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/whousebininventories?${optionsParamsURL}` :
        `${stock_transfer_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/whousebininventories?${optionsParamsURL}`)
}