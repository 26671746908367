/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualDPRInvHistView from "./ManualDPRInvHistView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualDPRInvHistView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvHistView />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualDPRInvHistView Loads Successfully", () => {
    expect(screen.getByText("ManualDPRInvHistView")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualDPRInvHistView", () => {
    // START_USER_CODE-USER_ManualDPRInvHistView_Custom_Test_Case
    // END_USER_CODE-USER_ManualDPRInvHistView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvHistView />
      );
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("grpbxBefore(GroupBox Widget) Test Cases", async () => {
    const grpbxBefore = screen.getByTestId("grpbxBefore");
    expect(grpbxBefore.tagName).toBe("BUTTON");
    expect(grpbxBefore.type).toBe("button");
    expect(grpbxBefore.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBefore", () => {
    // START_USER_CODE-USER_grpbxBefore_TEST
    // END_USER_CODE-USER_grpbxBefore_TEST
  });
  test("grpbxBeforeuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxBeforeuic0 = screen.getByTestId("grpbxBeforeuic0");
    expect(grpbxBeforeuic0.tagName).toBe("BUTTON");
    expect(grpbxBeforeuic0.type).toBe("button");
    expect(grpbxBeforeuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBeforeuic0", () => {
    // START_USER_CODE-USER_grpbxBeforeuic0_TEST
    // END_USER_CODE-USER_grpbxBeforeuic0_TEST
  });
  test("grpbxManualDPR(GroupBox Widget) Test Cases", async () => {
    const grpbxManualDPR = screen.getByTestId("grpbxManualDPR");
    expect(grpbxManualDPR.tagName).toBe("BUTTON");
    expect(grpbxManualDPR.type).toBe("button");
    expect(grpbxManualDPR.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualDPR", () => {
    // START_USER_CODE-USER_grpbxManualDPR_TEST
    // END_USER_CODE-USER_grpbxManualDPR_TEST
  });
  test("lblAfter(Label Widget) Test Cases", async () => {
    const lblAfter = screen.getByTestId("lblAfter");
    expect(lblAfter.tagName).toBe("LABEL");
    expect(lblAfter.classList).toContain("form-label");
    expect(lblAfter.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_lblAfter")
    );
  });
  test("Custom Test Cases for lblAfter", () => {
    // START_USER_CODE-USER_lblAfter_TEST
    // END_USER_CODE-USER_lblAfter_TEST
  });
  test("lblBefore(Label Widget) Test Cases", async () => {
    const lblBefore = screen.getByTestId("lblBefore");
    expect(lblBefore.tagName).toBe("LABEL");
    expect(lblBefore.classList).toContain("form-label");
    expect(lblBefore.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_lblBefore")
    );
  });
  test("Custom Test Cases for lblBefore", () => {
    // START_USER_CODE-USER_lblBefore_TEST
    // END_USER_CODE-USER_lblBefore_TEST
  });
  test("txtAction(Textbox Widget) Test Cases", async () => {
    const txtAction = screen.getByTestId("txtAction");
    expect(txtAction.tagName).toBe("INPUT");
    expect(txtAction.type).toBe("text");
    expect(txtAction.classList).toContain("textboxWidgetClass");
    expect(txtAction.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtAction")
    );
    await act(async () => {
      userEvent.type(txtAction, "1");
    });
  });
  test("Custom Test Cases for txtAction", () => {
    // START_USER_CODE-USER_txtAction_TEST
    // END_USER_CODE-USER_txtAction_TEST
  });
  test("txtAudit(Textbox Widget) Test Cases", async () => {
    const txtAudit = screen.getByTestId("txtAudit");
    expect(txtAudit.tagName).toBe("INPUT");
    expect(txtAudit.type).toBe("text");
    expect(txtAudit.classList).toContain("textboxWidgetClass");
    expect(txtAudit.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtAudit")
    );
    await act(async () => {
      userEvent.type(txtAudit, "1");
    });
  });
  test("Custom Test Cases for txtAudit", () => {
    // START_USER_CODE-USER_txtAudit_TEST
    // END_USER_CODE-USER_txtAudit_TEST
  });
  test("txtAuditDate(Textbox Widget) Test Cases", async () => {
    const txtAuditDate = screen.getByTestId("txtAuditDate");
    expect(txtAuditDate.tagName).toBe("INPUT");
    expect(txtAuditDate.type).toBe("text");
    expect(txtAuditDate.classList).toContain("textboxWidgetClass");
    expect(txtAuditDate.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtAuditDate")
    );
    await act(async () => {
      userEvent.type(txtAuditDate, "1");
    });
  });
  test("Custom Test Cases for txtAuditDate", () => {
    // START_USER_CODE-USER_txtAuditDate_TEST
    // END_USER_CODE-USER_txtAuditDate_TEST
  });
  test("txtAuditDateAftr(Textbox Widget) Test Cases", async () => {
    const txtAuditDateAftr = screen.getByTestId("txtAuditDateAftr");
    expect(txtAuditDateAftr.tagName).toBe("INPUT");
    expect(txtAuditDateAftr.type).toBe("text");
    expect(txtAuditDateAftr.classList).toContain("textboxWidgetClass");
    expect(txtAuditDateAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtAuditDateAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtAuditDateAftr, "1");
    });
  });
  test("Custom Test Cases for txtAuditDateAftr", () => {
    // START_USER_CODE-USER_txtAuditDateAftr_TEST
    // END_USER_CODE-USER_txtAuditDateAftr_TEST
  });
  test("txtBuyngPt(Textbox Widget) Test Cases", async () => {
    const txtBuyngPt = screen.getByTestId("txtBuyngPt");
    expect(txtBuyngPt.tagName).toBe("INPUT");
    expect(txtBuyngPt.type).toBe("text");
    expect(txtBuyngPt.classList).toContain("textboxWidgetClass");
    expect(txtBuyngPt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtBuyngPt")
    );
    await act(async () => {
      userEvent.type(txtBuyngPt, "1");
    });
  });
  test("Custom Test Cases for txtBuyngPt", () => {
    // START_USER_CODE-USER_txtBuyngPt_TEST
    // END_USER_CODE-USER_txtBuyngPt_TEST
  });
  test("txtCompOwn(Textbox Widget) Test Cases", async () => {
    const txtCompOwn = screen.getByTestId("txtCompOwn");
    expect(txtCompOwn.tagName).toBe("INPUT");
    expect(txtCompOwn.type).toBe("text");
    expect(txtCompOwn.classList).toContain("textboxWidgetClass");
    expect(txtCompOwn.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtCompOwn")
    );
    await act(async () => {
      userEvent.type(txtCompOwn, "1");
    });
  });
  test("Custom Test Cases for txtCompOwn", () => {
    // START_USER_CODE-USER_txtCompOwn_TEST
    // END_USER_CODE-USER_txtCompOwn_TEST
  });
  test("txtCompOwnAftr(Textbox Widget) Test Cases", async () => {
    const txtCompOwnAftr = screen.getByTestId("txtCompOwnAftr");
    expect(txtCompOwnAftr.tagName).toBe("INPUT");
    expect(txtCompOwnAftr.type).toBe("text");
    expect(txtCompOwnAftr.classList).toContain("textboxWidgetClass");
    expect(txtCompOwnAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtCompOwnAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtCompOwnAftr, "1");
    });
  });
  test("Custom Test Cases for txtCompOwnAftr", () => {
    // START_USER_CODE-USER_txtCompOwnAftr_TEST
    // END_USER_CODE-USER_txtCompOwnAftr_TEST
  });
  test("txtIntransit(Textbox Widget) Test Cases", async () => {
    const txtIntransit = screen.getByTestId("txtIntransit");
    expect(txtIntransit.tagName).toBe("INPUT");
    expect(txtIntransit.type).toBe("text");
    expect(txtIntransit.classList).toContain("textboxWidgetClass");
    expect(txtIntransit.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtIntransit")
    );
    await act(async () => {
      userEvent.type(txtIntransit, "1");
    });
  });
  test("Custom Test Cases for txtIntransit", () => {
    // START_USER_CODE-USER_txtIntransit_TEST
    // END_USER_CODE-USER_txtIntransit_TEST
  });
  test("txtIntransitAftr(Textbox Widget) Test Cases", async () => {
    const txtIntransitAftr = screen.getByTestId("txtIntransitAftr");
    expect(txtIntransitAftr.tagName).toBe("INPUT");
    expect(txtIntransitAftr.type).toBe("text");
    expect(txtIntransitAftr.classList).toContain("textboxWidgetClass");
    expect(txtIntransitAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtIntransitAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtIntransitAftr, "1");
    });
  });
  test("Custom Test Cases for txtIntransitAftr", () => {
    // START_USER_CODE-USER_txtIntransitAftr_TEST
    // END_USER_CODE-USER_txtIntransitAftr_TEST
  });
  test("txtNotRecAdj(Textbox Widget) Test Cases", async () => {
    const txtNotRecAdj = screen.getByTestId("txtNotRecAdj");
    expect(txtNotRecAdj.tagName).toBe("INPUT");
    expect(txtNotRecAdj.type).toBe("text");
    expect(txtNotRecAdj.classList).toContain("textboxWidgetClass");
    expect(txtNotRecAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtNotRecAdj")
    );
    await act(async () => {
      userEvent.type(txtNotRecAdj, "1");
    });
  });
  test("Custom Test Cases for txtNotRecAdj", () => {
    // START_USER_CODE-USER_txtNotRecAdj_TEST
    // END_USER_CODE-USER_txtNotRecAdj_TEST
  });
  test("txtNotReceiptedAftr(Textbox Widget) Test Cases", async () => {
    const txtNotReceiptedAftr = screen.getByTestId("txtNotReceiptedAftr");
    expect(txtNotReceiptedAftr.tagName).toBe("INPUT");
    expect(txtNotReceiptedAftr.type).toBe("text");
    expect(txtNotReceiptedAftr.classList).toContain("textboxWidgetClass");
    expect(txtNotReceiptedAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtNotReceiptedAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtNotReceiptedAftr, "1");
    });
  });
  test("Custom Test Cases for txtNotReceiptedAftr", () => {
    // START_USER_CODE-USER_txtNotReceiptedAftr_TEST
    // END_USER_CODE-USER_txtNotReceiptedAftr_TEST
  });
  test("txtOleicInd(Textbox Widget) Test Cases", async () => {
    const txtOleicInd = screen.getByTestId("txtOleicInd");
    expect(txtOleicInd.tagName).toBe("INPUT");
    expect(txtOleicInd.type).toBe("text");
    expect(txtOleicInd.classList).toContain("textboxWidgetClass");
    expect(txtOleicInd.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtOleicInd")
    );
    await act(async () => {
      userEvent.type(txtOleicInd, "1");
    });
  });
  test("Custom Test Cases for txtOleicInd", () => {
    // START_USER_CODE-USER_txtOleicInd_TEST
    // END_USER_CODE-USER_txtOleicInd_TEST
  });
  test("txtOleicIndAftr(Textbox Widget) Test Cases", async () => {
    const txtOleicIndAftr = screen.getByTestId("txtOleicIndAftr");
    expect(txtOleicIndAftr.tagName).toBe("INPUT");
    expect(txtOleicIndAftr.type).toBe("text");
    expect(txtOleicIndAftr.classList).toContain("textboxWidgetClass");
    expect(txtOleicIndAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtOleicIndAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtOleicIndAftr, "1");
    });
  });
  test("Custom Test Cases for txtOleicIndAftr", () => {
    // START_USER_CODE-USER_txtOleicIndAftr_TEST
    // END_USER_CODE-USER_txtOleicIndAftr_TEST
  });
  test("txtOrgncInd(Textbox Widget) Test Cases", async () => {
    const txtOrgncInd = screen.getByTestId("txtOrgncInd");
    expect(txtOrgncInd.tagName).toBe("INPUT");
    expect(txtOrgncInd.type).toBe("text");
    expect(txtOrgncInd.classList).toContain("textboxWidgetClass");
    expect(txtOrgncInd.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtOrgncInd")
    );
    await act(async () => {
      userEvent.type(txtOrgncInd, "1");
    });
  });
  test("Custom Test Cases for txtOrgncInd", () => {
    // START_USER_CODE-USER_txtOrgncInd_TEST
    // END_USER_CODE-USER_txtOrgncInd_TEST
  });
  test("txtOrgncIndAftr(Textbox Widget) Test Cases", async () => {
    const txtOrgncIndAftr = screen.getByTestId("txtOrgncIndAftr");
    expect(txtOrgncIndAftr.tagName).toBe("INPUT");
    expect(txtOrgncIndAftr.type).toBe("text");
    expect(txtOrgncIndAftr.classList).toContain("textboxWidgetClass");
    expect(txtOrgncIndAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtOrgncIndAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtOrgncIndAftr, "1");
    });
  });
  test("Custom Test Cases for txtOrgncIndAftr", () => {
    // START_USER_CODE-USER_txtOrgncIndAftr_TEST
    // END_USER_CODE-USER_txtOrgncIndAftr_TEST
  });
  test("txtPhysclAdj(Textbox Widget) Test Cases", async () => {
    const txtPhysclAdj = screen.getByTestId("txtPhysclAdj");
    expect(txtPhysclAdj.tagName).toBe("INPUT");
    expect(txtPhysclAdj.type).toBe("text");
    expect(txtPhysclAdj.classList).toContain("textboxWidgetClass");
    expect(txtPhysclAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtPhysclAdj")
    );
    await act(async () => {
      userEvent.type(txtPhysclAdj, "1");
    });
  });
  test("Custom Test Cases for txtPhysclAdj", () => {
    // START_USER_CODE-USER_txtPhysclAdj_TEST
    // END_USER_CODE-USER_txtPhysclAdj_TEST
  });
  test("txtPhysclAdjAftr(Textbox Widget) Test Cases", async () => {
    const txtPhysclAdjAftr = screen.getByTestId("txtPhysclAdjAftr");
    expect(txtPhysclAdjAftr.tagName).toBe("INPUT");
    expect(txtPhysclAdjAftr.type).toBe("text");
    expect(txtPhysclAdjAftr.classList).toContain("textboxWidgetClass");
    expect(txtPhysclAdjAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtPhysclAdjAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtPhysclAdjAftr, "1");
    });
  });
  test("Custom Test Cases for txtPhysclAdjAftr", () => {
    // START_USER_CODE-USER_txtPhysclAdjAftr_TEST
    // END_USER_CODE-USER_txtPhysclAdjAftr_TEST
  });
  test("txtPnutTyp(Textbox Widget) Test Cases", async () => {
    const txtPnutTyp = screen.getByTestId("txtPnutTyp");
    expect(txtPnutTyp.tagName).toBe("INPUT");
    expect(txtPnutTyp.type).toBe("text");
    expect(txtPnutTyp.classList).toContain("textboxWidgetClass");
    expect(txtPnutTyp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtPnutTyp")
    );
    await act(async () => {
      userEvent.type(txtPnutTyp, "1");
    });
  });
  test("Custom Test Cases for txtPnutTyp", () => {
    // START_USER_CODE-USER_txtPnutTyp_TEST
    // END_USER_CODE-USER_txtPnutTyp_TEST
  });
  test("txtPnutTypAftr(Textbox Widget) Test Cases", async () => {
    const txtPnutTypAftr = screen.getByTestId("txtPnutTypAftr");
    expect(txtPnutTypAftr.tagName).toBe("INPUT");
    expect(txtPnutTypAftr.type).toBe("text");
    expect(txtPnutTypAftr.classList).toContain("textboxWidgetClass");
    expect(txtPnutTypAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtPnutTypAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtPnutTypAftr, "1");
    });
  });
  test("Custom Test Cases for txtPnutTypAftr", () => {
    // START_USER_CODE-USER_txtPnutTypAftr_TEST
    // END_USER_CODE-USER_txtPnutTypAftr_TEST
  });
  test("txtPnutVrty(Textbox Widget) Test Cases", async () => {
    const txtPnutVrty = screen.getByTestId("txtPnutVrty");
    expect(txtPnutVrty.tagName).toBe("INPUT");
    expect(txtPnutVrty.type).toBe("text");
    expect(txtPnutVrty.classList).toContain("textboxWidgetClass");
    expect(txtPnutVrty.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtPnutVrty")
    );
    await act(async () => {
      userEvent.type(txtPnutVrty, "1");
    });
  });
  test("Custom Test Cases for txtPnutVrty", () => {
    // START_USER_CODE-USER_txtPnutVrty_TEST
    // END_USER_CODE-USER_txtPnutVrty_TEST
  });
  test("txtPnutVrtyAftr(Textbox Widget) Test Cases", async () => {
    const txtPnutVrtyAftr = screen.getByTestId("txtPnutVrtyAftr");
    expect(txtPnutVrtyAftr.tagName).toBe("INPUT");
    expect(txtPnutVrtyAftr.type).toBe("text");
    expect(txtPnutVrtyAftr.classList).toContain("textboxWidgetClass");
    expect(txtPnutVrtyAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtPnutVrtyAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtPnutVrtyAftr, "1");
    });
  });
  test("Custom Test Cases for txtPnutVrtyAftr", () => {
    // START_USER_CODE-USER_txtPnutVrtyAftr_TEST
    // END_USER_CODE-USER_txtPnutVrtyAftr_TEST
  });
  test("txtReason(Textbox Widget) Test Cases", async () => {
    const txtReason = screen.getByTestId("txtReason");
    expect(txtReason.tagName).toBe("INPUT");
    expect(txtReason.type).toBe("text");
    expect(txtReason.classList).toContain("textboxWidgetClass");
    expect(txtReason.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtReason")
    );
    await act(async () => {
      userEvent.type(txtReason, "1");
    });
  });
  test("Custom Test Cases for txtReason", () => {
    // START_USER_CODE-USER_txtReason_TEST
    // END_USER_CODE-USER_txtReason_TEST
  });
  test("txtSeedGen(Textbox Widget) Test Cases", async () => {
    const txtSeedGen = screen.getByTestId("txtSeedGen");
    expect(txtSeedGen.tagName).toBe("INPUT");
    expect(txtSeedGen.type).toBe("text");
    expect(txtSeedGen.classList).toContain("textboxWidgetClass");
    expect(txtSeedGen.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtSeedGen")
    );
    await act(async () => {
      userEvent.type(txtSeedGen, "1");
    });
  });
  test("Custom Test Cases for txtSeedGen", () => {
    // START_USER_CODE-USER_txtSeedGen_TEST
    // END_USER_CODE-USER_txtSeedGen_TEST
  });
  test("txtSeedGenAftr(Textbox Widget) Test Cases", async () => {
    const txtSeedGenAftr = screen.getByTestId("txtSeedGenAftr");
    expect(txtSeedGenAftr.tagName).toBe("INPUT");
    expect(txtSeedGenAftr.type).toBe("text");
    expect(txtSeedGenAftr.classList).toContain("textboxWidgetClass");
    expect(txtSeedGenAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtSeedGenAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtSeedGenAftr, "1");
    });
  });
  test("Custom Test Cases for txtSeedGenAftr", () => {
    // START_USER_CODE-USER_txtSeedGenAftr_TEST
    // END_USER_CODE-USER_txtSeedGenAftr_TEST
  });
  test("txtSegTyp(Textbox Widget) Test Cases", async () => {
    const txtSegTyp = screen.getByTestId("txtSegTyp");
    expect(txtSegTyp.tagName).toBe("INPUT");
    expect(txtSegTyp.type).toBe("text");
    expect(txtSegTyp.classList).toContain("textboxWidgetClass");
    expect(txtSegTyp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtSegTyp")
    );
    await act(async () => {
      userEvent.type(txtSegTyp, "1");
    });
  });
  test("Custom Test Cases for txtSegTyp", () => {
    // START_USER_CODE-USER_txtSegTyp_TEST
    // END_USER_CODE-USER_txtSegTyp_TEST
  });
  test("txtSegTypAftr(Textbox Widget) Test Cases", async () => {
    const txtSegTypAftr = screen.getByTestId("txtSegTypAftr");
    expect(txtSegTypAftr.tagName).toBe("INPUT");
    expect(txtSegTypAftr.type).toBe("text");
    expect(txtSegTypAftr.classList).toContain("textboxWidgetClass");
    expect(txtSegTypAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtSegTypAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtSegTypAftr, "1");
    });
  });
  test("Custom Test Cases for txtSegTypAftr", () => {
    // START_USER_CODE-USER_txtSegTypAftr_TEST
    // END_USER_CODE-USER_txtSegTypAftr_TEST
  });
  test("txtShrinkAdj(Textbox Widget) Test Cases", async () => {
    const txtShrinkAdj = screen.getByTestId("txtShrinkAdj");
    expect(txtShrinkAdj.tagName).toBe("INPUT");
    expect(txtShrinkAdj.type).toBe("text");
    expect(txtShrinkAdj.classList).toContain("textboxWidgetClass");
    expect(txtShrinkAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtShrinkAdj")
    );
    await act(async () => {
      userEvent.type(txtShrinkAdj, "1");
    });
  });
  test("Custom Test Cases for txtShrinkAdj", () => {
    // START_USER_CODE-USER_txtShrinkAdj_TEST
    // END_USER_CODE-USER_txtShrinkAdj_TEST
  });
  test("txtShrinkAdjAftr(Textbox Widget) Test Cases", async () => {
    const txtShrinkAdjAftr = screen.getByTestId("txtShrinkAdjAftr");
    expect(txtShrinkAdjAftr.tagName).toBe("INPUT");
    expect(txtShrinkAdjAftr.type).toBe("text");
    expect(txtShrinkAdjAftr.classList).toContain("textboxWidgetClass");
    expect(txtShrinkAdjAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtShrinkAdjAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtShrinkAdjAftr, "1");
    });
  });
  test("Custom Test Cases for txtShrinkAdjAftr", () => {
    // START_USER_CODE-USER_txtShrinkAdjAftr_TEST
    // END_USER_CODE-USER_txtShrinkAdjAftr_TEST
  });
  test("txtSourceTxt(Textbox Widget) Test Cases", async () => {
    const txtSourceTxt = screen.getByTestId("txtSourceTxt");
    expect(txtSourceTxt.tagName).toBe("INPUT");
    expect(txtSourceTxt.type).toBe("text");
    expect(txtSourceTxt.classList).toContain("textboxWidgetClass");
    expect(txtSourceTxt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtSourceTxt")
    );
    await act(async () => {
      userEvent.type(txtSourceTxt, "1");
    });
  });
  test("Custom Test Cases for txtSourceTxt", () => {
    // START_USER_CODE-USER_txtSourceTxt_TEST
    // END_USER_CODE-USER_txtSourceTxt_TEST
  });
  test("txtUndetrmndAdj(Textbox Widget) Test Cases", async () => {
    const txtUndetrmndAdj = screen.getByTestId("txtUndetrmndAdj");
    expect(txtUndetrmndAdj.tagName).toBe("INPUT");
    expect(txtUndetrmndAdj.type).toBe("text");
    expect(txtUndetrmndAdj.classList).toContain("textboxWidgetClass");
    expect(txtUndetrmndAdj.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtUndetrmndAdj"
      )
    );
    await act(async () => {
      userEvent.type(txtUndetrmndAdj, "1");
    });
  });
  test("Custom Test Cases for txtUndetrmndAdj", () => {
    // START_USER_CODE-USER_txtUndetrmndAdj_TEST
    // END_USER_CODE-USER_txtUndetrmndAdj_TEST
  });
  test("txtUndetrmndAdjAftr(Textbox Widget) Test Cases", async () => {
    const txtUndetrmndAdjAftr = screen.getByTestId("txtUndetrmndAdjAftr");
    expect(txtUndetrmndAdjAftr.tagName).toBe("INPUT");
    expect(txtUndetrmndAdjAftr.type).toBe("text");
    expect(txtUndetrmndAdjAftr.classList).toContain("textboxWidgetClass");
    expect(txtUndetrmndAdjAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtUndetrmndAdjAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtUndetrmndAdjAftr, "1");
    });
  });
  test("Custom Test Cases for txtUndetrmndAdjAftr", () => {
    // START_USER_CODE-USER_txtUndetrmndAdjAftr_TEST
    // END_USER_CODE-USER_txtUndetrmndAdjAftr_TEST
  });
  test("txtUser(Textbox Widget) Test Cases", async () => {
    const txtUser = screen.getByTestId("txtUser");
    expect(txtUser.tagName).toBe("INPUT");
    expect(txtUser.type).toBe("text");
    expect(txtUser.classList).toContain("textboxWidgetClass");
    expect(txtUser.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtUser")
    );
    await act(async () => {
      userEvent.type(txtUser, "1");
    });
  });
  test("Custom Test Cases for txtUser", () => {
    // START_USER_CODE-USER_txtUser_TEST
    // END_USER_CODE-USER_txtUser_TEST
  });
  test("txtWhs(Textbox Widget) Test Cases", async () => {
    const txtWhs = screen.getByTestId("txtWhs");
    expect(txtWhs.tagName).toBe("INPUT");
    expect(txtWhs.type).toBe("text");
    expect(txtWhs.classList).toContain("textboxWidgetClass");
    expect(txtWhs.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtWhs")
    );
    await act(async () => {
      userEvent.type(txtWhs, "1");
    });
  });
  test("Custom Test Cases for txtWhs", () => {
    // START_USER_CODE-USER_txtWhs_TEST
    // END_USER_CODE-USER_txtWhs_TEST
  });
  test("txtWhsAftr(Textbox Widget) Test Cases", async () => {
    const txtWhsAftr = screen.getByTestId("txtWhsAftr");
    expect(txtWhsAftr.tagName).toBe("INPUT");
    expect(txtWhsAftr.type).toBe("text");
    expect(txtWhsAftr.classList).toContain("textboxWidgetClass");
    expect(txtWhsAftr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtWhsAftr")
    );
    await act(async () => {
      userEvent.type(txtWhsAftr, "1");
    });
  });
  test("Custom Test Cases for txtWhsAftr", () => {
    // START_USER_CODE-USER_txtWhsAftr_TEST
    // END_USER_CODE-USER_txtWhsAftr_TEST
  });
  test("txtWhsRec(Textbox Widget) Test Cases", async () => {
    const txtWhsRec = screen.getByTestId("txtWhsRec");
    expect(txtWhsRec.tagName).toBe("INPUT");
    expect(txtWhsRec.type).toBe("text");
    expect(txtWhsRec.classList).toContain("textboxWidgetClass");
    expect(txtWhsRec.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtWhsRec")
    );
    await act(async () => {
      userEvent.type(txtWhsRec, "1");
    });
  });
  test("Custom Test Cases for txtWhsRec", () => {
    // START_USER_CODE-USER_txtWhsRec_TEST
    // END_USER_CODE-USER_txtWhsRec_TEST
  });
  test("txtWhsRecAftr(Textbox Widget) Test Cases", async () => {
    const txtWhsRecAftr = screen.getByTestId("txtWhsRecAftr");
    expect(txtWhsRecAftr.tagName).toBe("INPUT");
    expect(txtWhsRecAftr.type).toBe("text");
    expect(txtWhsRecAftr.classList).toContain("textboxWidgetClass");
    expect(txtWhsRecAftr.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvHistView_txtWhsRecAftr"
      )
    );
    await act(async () => {
      userEvent.type(txtWhsRecAftr, "1");
    });
  });
  test("Custom Test Cases for txtWhsRecAftr", () => {
    // START_USER_CODE-USER_txtWhsRecAftr_TEST
    // END_USER_CODE-USER_txtWhsRecAftr_TEST
  });
});
