/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from "jspdf";

import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  setValue,
  getData,
  getValue,
  setData,
  goTo,
  disable,
  enable,
  hide,
  show
} from "../../shared/WindowImports";

import "./KCMOdataSearch.scss";

// START_USER_CODE-USER_IMPORTS
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../Service/SettlementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_KCMOdataSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "KCMOdataSearch",
    windowName: "KCMOdataSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.KCMOdataSearch",
    horizontalForm: true,
    // START_USER_CODE-USER_KCMOdataSearch_PROPERTIES
    headerData: {
      scrName: "KCMO Transmitted Data Search",
      scrCode: "PN1060K",
    },
    // END_USER_CODE-USER_KCMOdataSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export To Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "cmmndCntnrOpen",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnRequestFileResend: {
      name: "btnRequestFileResend",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Request File Resend",
      CharWidth: "39",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRequestFileResend_PROPERTIES

      // END_USER_CODE-USER_btnRequestFileResend_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "Search",
      ColSpan: "2",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    cmmndCntnrOpen: {
      name: "cmmndCntnrOpen",
      type: "CommandContainerWidget",
      parent: "grpbxKCMOdataSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrOpen_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrOpen_PROPERTIES
    },
    col1007Num: {
      name: "col1007Num",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007Num_PROPERTIES

      // END_USER_CODE-USER_col1007Num_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colCounty: {
      name: "colCounty",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "County#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCounty_PROPERTIES

      // END_USER_CODE-USER_colCounty_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Farm#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colFedBP: {
      name: "colFedBP",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Fed BP",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFedBP_PROPERTIES

      // END_USER_CODE-USER_colFedBP_PROPERTIES
    },
    colFileNum: {
      name: "colFileNum",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "File #",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFileNum_PROPERTIES

      // END_USER_CODE-USER_colFileNum_PROPERTIES
    },
    colInspectDate: {
      name: "colInspectDate",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Inspect Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspectDate_PROPERTIES

      // END_USER_CODE-USER_colInspectDate_PROPERTIES
    },
    colNetWt: {
      name: "colNetWt",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Net Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWt_PROPERTIES

      // END_USER_CODE-USER_colNetWt_PROPERTIES
    },
    colPnut: {
      name: "colPnut",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Pnut",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnut_PROPERTIES

      // END_USER_CODE-USER_colPnut_PROPERTIES
    },
    colSC95Num: {
      name: "colSC95Num",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "SC95#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSC95Num_PROPERTIES

      // END_USER_CODE-USER_colSC95Num_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colState: {
      name: "colState",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "State#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colState_PROPERTIES

      // END_USER_CODE-USER_colState_PROPERTIES
    },
    colTransmitDate: {
      name: "colTransmitDate",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Transmit Date",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransmitDate_PROPERTIES

      // END_USER_CODE-USER_colTransmitDate_PROPERTIES
    },
    colVoidCd: {
      name: "colVoidCd",
      type: "GridColumnWidget",
      parent: "gridKCMOdata",
      Label: "Void Cd",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVoidCd_PROPERTIES

      // END_USER_CODE-USER_colVoidCd_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    gridKCMOdata: {
      name: "gridKCMOdata",
      type: "GridWidget",
      parent: "grpbxKCMOdataSearch",
      gridCellsOrder:
        "txtcolFileNum,txtcolTransmitDate,txtcolBuyPt,txtcolFedBP,txtcol1007Num,txtcolSC95Num,txtcolState,txtcolCounty,txtcolFarm,txtcolInspectDate,txtcolVoidCd,txtcolPnut,txtcolSeg,txtcolNetWt",
      Pagination: false,
      HasLabel: false,
      Cols: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridKCMOdata_PROPERTIES

      // END_USER_CODE-USER_gridKCMOdata_PROPERTIES
    },
    txt1007Settlement: {
      name: "txt1007Settlement",
      type: "TextBoxWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "1007 Settle:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Settlement_PROPERTIES

      // END_USER_CODE-USER_txt1007Settlement_PROPERTIES
    },
    txt1007SettleRangeFrom: {
      name: "txt1007SettleRangeFrom",
      type: "TextBoxWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "1007 Settle Range:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007SettleRangeFrom_PROPERTIES

      // END_USER_CODE-USER_txt1007SettleRangeFrom_PROPERTIES
    },
    txt1007SettleRangeTo: {
      name: "txt1007SettleRangeTo",
      type: "TextBoxWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "- thru -",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007SettleRangeTo_PROPERTIES

      // END_USER_CODE-USER_txt1007SettleRangeTo_PROPERTIES
    },
    txtcol1007Num: {
      name: "txtcol1007Num",
      type: "TextBoxWidget",
      colName: "col1007Num",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007Num_PROPERTIES

      // END_USER_CODE-USER_txtcol1007Num_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolCounty: {
      name: "txtcolCounty",
      type: "TextBoxWidget",
      colName: "colCounty",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCounty_PROPERTIES

      // END_USER_CODE-USER_txtcolCounty_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolFedBP: {
      name: "txtcolFedBP",
      type: "TextBoxWidget",
      colName: "colFedBP",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFedBP_PROPERTIES

      // END_USER_CODE-USER_txtcolFedBP_PROPERTIES
    },
    txtcolFileNum: {
      name: "txtcolFileNum",
      type: "TextBoxWidget",
      colName: "colFileNum",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFileNum_PROPERTIES

      // END_USER_CODE-USER_txtcolFileNum_PROPERTIES
    },
    txtcolInspectDate: {
      name: "txtcolInspectDate",
      type: "TextBoxWidget",
      colName: "colInspectDate",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspectDate_PROPERTIES

      // END_USER_CODE-USER_txtcolInspectDate_PROPERTIES
    },
    txtcolNetWt: {
      name: "txtcolNetWt",
      type: "TextBoxWidget",
      colName: "colNetWt",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWt_PROPERTIES
      formattingReqd: true,
      // END_USER_CODE-USER_txtcolNetWt_PROPERTIES
    },
    txtcolPnut: {
      name: "txtcolPnut",
      type: "TextBoxWidget",
      colName: "colPnut",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnut_PROPERTIES

      // END_USER_CODE-USER_txtcolPnut_PROPERTIES
    },
    txtcolSC95Num: {
      name: "txtcolSC95Num",
      type: "TextBoxWidget",
      colName: "colSC95Num",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSC95Num_PROPERTIES

      // END_USER_CODE-USER_txtcolSC95Num_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolState: {
      name: "txtcolState",
      type: "TextBoxWidget",
      colName: "colState",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolState_PROPERTIES

      // END_USER_CODE-USER_txtcolState_PROPERTIES
    },
    txtcolTransmitDate: {
      name: "txtcolTransmitDate",
      type: "TextBoxWidget",
      colName: "colTransmitDate",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransmitDate_PROPERTIES

      // END_USER_CODE-USER_txtcolTransmitDate_PROPERTIES
    },
    txtcolVoidCd: {
      name: "txtcolVoidCd",
      type: "TextBoxWidget",
      colName: "colVoidCd",
      parent: "gridKCMOdata",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVoidCd_PROPERTIES

      // END_USER_CODE-USER_txtcolVoidCd_PROPERTIES
    },
    txtFederalBuyingPoint: {
      name: "txtFederalBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "Federal BuyPt:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFederalBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtFederalBuyingPoint_PROPERTIES
    },
    txtFileNumber: {
      name: "txtFileNumber",
      type: "TextBoxWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "File Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFileNumber_PROPERTIES

      // END_USER_CODE-USER_txtFileNumber_PROPERTIES
    },
    txtSC95Inspection: {
      name: "txtSC95Inspection",
      type: "TextBoxWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "SC95 Inspect:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95Inspection_PROPERTIES

      // END_USER_CODE-USER_txtSC95Inspection_PROPERTIES
    },
    txtTransmitDateFrom: {
      name: "txtTransmitDateFrom",
      type: "DateWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "Transmit Date Range:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtTransmitDateFrom_PROPERTIES

      // END_USER_CODE-USER_txtTransmitDateFrom_PROPERTIES
    },
    txtTransmitDateTo: {
      name: "txtTransmitDateTo",
      type: "DateWidget",
      parent: "grpbxKCMOdataSearch",
      Label: "- thru - ",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtTransmitDateTo_PROPERTIES

      // END_USER_CODE-USER_txtTransmitDateTo_PROPERTIES
    },
    grpbxKCMOdataSearch: {
      name: "grpbxKCMOdataSearch",
      type: "GroupBoxWidget",
      parent: "KCMOdataSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxKCMOdataSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxKCMOdataSearch_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "KCMOdataSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#ReportPreviewInspection": {},
        "Settlements#KCMOdataProfile": {}
      },
      REVERSE: {
        "Settlements#ReportPreviewInspection": {},
        "Settlements#KCMOdataProfile": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnPrint: {
    //   DEFAULT: ["Settlements#ReportPreviewInspection#DEFAULT#true#Click"],
    // },
    btnOpen: {
      DEFAULT: ["Settlements#KCMOdataProfile#DEFAULT#true#Click"],
    },
  };
  const [loading, setLoading] = useState(false);

  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddLocation", '')
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  async function FormLoad() {
    setLoading(true);
    bFillBuyingPointList(); // Method for binding Buying Points
    disable(thisObj, 'btnOpen')
    disable(thisObj, 'btnExportToExcel')
    disable(thisObj, 'btnPrint')
    let cropYrStartDate = '';
    let cropYrEndDate = '';
    let currentDate = '';
    let startDate = new Date(cropYear - 1, '30', '6');
    let endDate = new Date(cropYear, '31', '7');
    let response = await ContractManagementService.RetrieveCropYears()
    let data = response;
    if (response.length > 0) {
      cropYrStartDate = data[0].cropYearStartDate;
      cropYrEndDate = data[0].cropYearEndDate;
      currentDate = data[0].cuR_SYS_DATE;
    }
    if (compId == 'B9' && new Date(cropYrStartDate) <= startDate && new Date(cropYrEndDate) <= endDate) {
      let response1 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '011', 'U', null)
      if (response1 != null && response1 != undefined) {
        if (response1[0].permission == 'Y'){
          show(thisObj,"btnRequestFileResend")
        }
        else{
          hide(thisObj,"btnRequestFileResend",false)
        }
      }
    }
    setLoading(false);
  }

  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1160', null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddLocation', js.at(0).key);
    })
  }
  function ConvertToDate(str) {
    try {
      if (str !== undefined && str !== '') {
        let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
      } else {
        return '';
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ConvertToDate event:Click");
      }
      return false;
    }
    return true;
  }


  //converting date into dd/mm/yyyy formt
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");

  }
  function formatString(str) {
    // Check if string contains a number
    if (!isNaN(str)) {
      // If yes, format string with commas
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // If no, leave string as it is
      return str;
    }
  }

  const onbtnSearchClick = () => {
    try {
      let buy_pt_id = getValue(thisObj, 'ddLocation')
      let fed_buy_pt_id = getValue(thisObj, 'txtFederalBuyingPoint')
      let insp_num = getValue(thisObj, 'txtSC95Inspection')
      let settlement_num = getValue(thisObj, 'txt1007Settlement')
      let beg_settle_num = getValue(thisObj, 'txt1007SettleRangeFrom')
      let end_settle_num = getValue(thisObj, 'txt1007SettleRangeTo')
      let filenumber = getValue(thisObj, 'txtFileNumber')
      let beg_trans_date = null
      if (thisObj.values.txtTransmitDateFrom !== undefined) {
        beg_trans_date = ConvertToDate(getValue(thisObj, "txtTransmitDateFrom"))

        if (beg_trans_date == "aN/aN/NaN") {
          beg_trans_date = null
        }
      }
      let end_trans_date = null
      if (thisObj.values.txtTransmitDateTo !== undefined) {
        end_trans_date = ConvertToDate(getValue(thisObj, "txtTransmitDateTo"))
        if (end_trans_date == "aN/aN/NaN") {
          end_trans_date = null
        }
      }
      setLoading(true);
      SettlementService.RetrieveKCMODataDetails(buy_pt_id, fed_buy_pt_id, insp_num, settlement_num, beg_trans_date, end_trans_date, beg_settle_num, end_settle_num, filenumber)
        .then(response => {
          if (response != undefined && response.length > 0) {
            let TransformedRowsArray = [];
            let data = response[0].kcmo_records;
            let obj = {};
            let rowCount = response[0].row_count
            for (let i = 0; i < data.length; i++) {
              if (data[i].strFieldName.trim() == 'FILENUMBER') {
                obj.txtcolFileNum = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'KC_TRANS_DATE') {
                obj.txtcolTransmitDate = data[i].strFieldData.replace(/:\d\d /, '');
              }
              if (data[i].strFieldName.trim() == 'BUY_PT_ID') {
                obj.txtcolBuyPt = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'FED_BUY_PT_ID') {
                obj.txtcolFedBP = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'SETTLEMENT_NUM') {
                obj.txtcol1007Num = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'INSP_NUM') {
                obj.txtcolSC95Num = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'STATE_ID') {
                obj.txtcolState = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'COUNTY_ID') {
                obj.txtcolCounty = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'FARM_ID') {
                obj.txtcolFarm = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'INSP_DATE_TIME') {
                obj.txtcolInspectDate = data[i].strFieldData.replace(/:\d\d /, '');
              }
              if (data[i].strFieldName.trim() == 'VOID_REASON_CODE') {
                obj.txtcolVoidCd = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'PNUT_TYPE_ID') {
                obj.txtcolPnut = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'SEG_TYPE') {
                obj.txtcolSeg = data[i].strFieldData.trim()
              }
              if (data[i].strFieldName.trim() == 'NET_WT') {
                obj.txtcolNetWt = formatString(data[i].strFieldData.trim());
              }
              if (data[i].strFieldName.trim() == 'AUTHENTICATION_CODE') {
                TransformedRowsArray.push(obj)
                obj = {}
              }
            }
            setValue(thisObj, 'gridKCMOdata', TransformedRowsArray);
            const div1=document.getElementsByClassName('rdt_Table')
            Array.from(div1).forEach(element=>{element.parentNode.parentNode.scrollTop = 0})
            const div=document.getElementsByClassName('rdt_TableBody')
            Array.from(div).forEach(element=>element.setAttribute('tabindex','1'))
            if (rowCount >= 1000) {
              alert("Warning:  These search results have exceeded the maximum number allowed of 1000 rows." + " All results after row 1000 have been truncated." +
                "   Please consider narrowing you search parameters in order to return a complete results list.");
            }
            {
              enable(thisObj, 'btnOpen');
              enable(thisObj, 'btnExportToExcel');
              enable(thisObj, 'btnPrint');
            }
          }
          else {
            if (response.length <= 0 || response == undefined || response == null) {
              setValue(thisObj, "gridKCMOdata", []);//To make grid empty if data not found
              showMessage(thisObj, " Warning: No transmition data results were found for this search !")
              disable(thisObj, "btnOpen")
              disable(thisObj, 'btnExportToExcel')
              disable(thisObj, 'btnPrint')
            }
          }
          setLoading(false);
        });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      setLoading(false);
      return false
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const onbtnOpenClick = async () => {
    let BUY_PT_ID = "";
    let FED_BUY_PT_ID = "";
    let INSP_NUM = "";
    let SETTLEMENT_NUM = "";
    let FILENUMBER = "";
    let buyptdesc = ";"
    let data = thisObj.state.gridKCMOdata.selected[0];
    if (data !== undefined && data !== null && data !== "") {
      let buyingPointId = thisObj.state['ddLocation'].valueList;
      BUY_PT_ID = data.txtcolBuyPt
      buyptdesc = buyingPointId.find(elem => elem.key == BUY_PT_ID).description;
      FED_BUY_PT_ID = data.txtcolFedBP
      SETTLEMENT_NUM = data.txtcolSC95Num
      INSP_NUM = data.txtcol1007Num
      FILENUMBER = data.txtcolFileNum
    }
    else {
      showMessage(thisObj, "A row must be selected to open");
      return;
    }
    let response = await SettlementService.RetrieveKCMODataDetails(BUY_PT_ID, FED_BUY_PT_ID, SETTLEMENT_NUM, INSP_NUM, null, null, null, null, FILENUMBER)
    if (response.length > 0) {
      setData(thisObj, "frmKCMOdataProfileFomLoadData", response);
      setData(thisObj, "KCMOdataProfileFomLoadData", buyptdesc);
      goTo(thisObj, "Settlements#KCMOdataProfile#DEFAULT#true#Click")
    }
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;

  const ongridKCMOdataDblclick = async () => {
    let BUY_PT_ID = "";
    let FED_BUY_PT_ID = "";
    let INSP_NUM = "";
    let SETTLEMENT_NUM = "";
    let FILENUMBER = "";
    let buyptdesc = ";"
    let data = thisObj.state.gridKCMOdata.selected[0];
    if (data !== undefined && data !== null && data !== "") {
      let buyingPointId = thisObj.state['ddLocation'].valueList;
      BUY_PT_ID = data.txtcolBuyPt
      buyptdesc = buyingPointId.find(elem => elem.key == BUY_PT_ID).description;
      FED_BUY_PT_ID = data.txtcolFedBP
      SETTLEMENT_NUM = data.txtcolSC95Num
      INSP_NUM = data.txtcol1007Num
      FILENUMBER = data.txtcolFileNum
    }
    else {
      showMessage(thisObj, "A row must be selected to open");
      return;
    }
    let response = await SettlementService.RetrieveKCMODataDetails(BUY_PT_ID, FED_BUY_PT_ID, SETTLEMENT_NUM, INSP_NUM, null, null, null, null, FILENUMBER)

    if (response.length > 0) {
      setData(thisObj, "frmKCMOdataProfileFomLoadData", response);
      setData(thisObj, "KCMOdataProfileFomLoadData", buyptdesc);
      goTo(thisObj, "Settlements#KCMOdataProfile#DEFAULT#true#Click")
    }
  };
  thisObj.ongridKCMOdataDblclick = ongridKCMOdataDblclick;

  const onbtnRequestFileResendClick = async () => {
    try {
      let FileNumber = prompt("Please enter the File Number of the Data Transmission that you want to resend to Kansas City, MO.")
      if (FileNumber == "" || FileNumber == null || FileNumber == undefined) {
        alert("Request has NOT been created.")
        return false;
      }
      else {
        const IsNumeric = new RegExp('^[0-9]+$');
        if (!IsNumeric.test(FileNumber)) {
          alert("File Number must be numeric!")
          return false;
        }
      }
      const response = confirm("Are you sure you want resend " + "File # " + FileNumber + " to Kansas City, MO?");
      if (response) {
        let job_id = "FSA1007FileCreationUtility"
        let dataObj = {
          user_id: userid,
          comp_id: compId,
          crop_year: cropYear.toString(),
          parm_name_03: "FILENUMBER",
          parm_value_03: FileNumber,
          parm_name_04: "RESEND_DATETIME",
        }
        let response = await AccountPayment.CreateBatchJobRequestDetails(job_id, dataObj)
        if (response.status === 200) {
          alert("File # " + FileNumber + " will be resent to Kansas City, MO tonight.")
        }
        else {
          alert("Request Failed!:" + "A pending request to resend data to kanasa City, Mo for file number" + FileNumber + "already exists.")
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnResendWireConfirmationClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnRequestFileResendClick = onbtnRequestFileResendClick;

  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridKCMOdata")
      var fileName = "KCMO Transmitted Data"
      const arrayData = []
      for (let i = 0; i < excelData.length; i++) {
        let obj = {
          "File #": ExcelNumericDataConvert(excelData[i].txtcolFileNum),
          "Transmit Date": excelData[i].txtcolTransmitDate,
          "Buy Pt": excelData[i].txtcolBuyPt,
          "Fed BP": ExcelNumericDataConvert(excelData[i].txtcolFedBP),
          "1007#": ExcelNumericDataConvert(excelData[i].txtcol1007Num),
          "SC95#": ExcelNumericDataConvert(excelData[i].txtcolSC95Num),
          "State#":ExcelNumericDataConvert (excelData[i].txtcolState),
          "County#": ExcelNumericDataConvert(excelData[i].txtcolCounty),
          "Farm#": ExcelNumericDataConvert(excelData[i].txtcolFarm),
          "Inspect Date": excelData[i].txtcolInspectDate,
          "Void Cd": excelData[i].txtcolVoidCd,
          "Pnut": excelData[i].txtcolPnut,
          "Seg": ExcelNumericDataConvert(excelData[i].txtcolSeg),
          "Net Wt": ExcelNumericDataConvert(excelData[i].txtcolNetWt,[','])
        }
        arrayData.push(obj);
      }
      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);

    } catch (err) {

      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on export to excel button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridKCMOdata")
      var res = []
      for (var i = 0; i < data.length; i++) {
        res.push([
          data[i].txtcolFileNum,
          data[i].txtcolTransmitDate,
          data[i].txtcolBuyPt,
          data[i].txtcolFedBP,
          data[i].txtcol1007Num,
          data[i].txtcolSC95Num,
          data[i].txtcolState,
          data[i].txtcolCounty,
          data[i].txtcolFarm,
          data[i].txtcolInspectDate,
          data[i].txtcolVoidCd,
          data[i].txtcolPnut,
          data[i].txtcolSeg,
          data[i].txtcolNetWt,
        ])
      }
      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          [
            'File#', 'Transmit Date', 'Buy Pt', 'Fed BP', '1007#', 'SC95#', 'State#', 'County#', 'Farm#', 'Inspect Date', 'Void Cd', 'Pnut', 'Seg', 'Net Wt'
          ]
        ]
      });
      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
        obj.text('page: ' + pageCurrent + '/' + pageCount  + ' KCMO Transmitted Data', 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:KcmoDataSearch.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:KcmoDataSearch.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const ontxt1007SettlementChange = () => {
    let SettlementValue = getValue(thisObj, 'txt1007Settlement')
    setValue(thisObj, "txt1007Settlement", validateTextBox(SettlementValue))
  };
  thisObj.ontxt1007SettlementChange = ontxt1007SettlementChange

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtFileNumberChange = () => {
    let filenumberValue = getValue(thisObj, 'txtFileNumber')
    setValue(thisObj, "txtFileNumber", validateTextBox1(filenumberValue))
  };
  thisObj.ontxtFileNumberChange = ontxtFileNumberChange
  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxt1007SettleRangeFromChange = () => {
    let settleRangeValue = getValue(thisObj, 'txt1007SettleRangeFrom')
    setValue(thisObj, "txt1007SettleRangeFrom", validateTextBox2(settleRangeValue))
  };
  thisObj.ontxt1007SettleRangeFromChange = ontxt1007SettleRangeFromChange

  const validateTextBox2 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxt1007SettleRangeToChange = () => {
    let settleRangeValue = getValue(thisObj, 'txt1007SettleRangeTo')
    setValue(thisObj, "txt1007SettleRangeTo", validateTextBox3(settleRangeValue))
  };
  thisObj.ontxt1007SettleRangeToChange = ontxt1007SettleRangeToChange

  const validateTextBox3 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxt1007SettlementBlur = () => {
    try {
      let settlement_num = getValue(thisObj, 'txt1007Settlement');
      if (settlement_num != null && settlement_num != undefined && settlement_num != '') {
        if (isNaN(settlement_num)) {
          showMessage(thisObj, "Invalid 1007Settlement")
          setValue(thisObj, 'txt1007Settlement', '')
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txt1007Settlement Blur event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxt1007SettlementBlur = ontxt1007SettlementBlur;

  const ontxtFileNumberBlur = () => {
    try {
      let filenumber = getValue(thisObj, 'txtFileNumber');
      if (filenumber != null && filenumber != undefined && filenumber != '') {
        if (isNaN(filenumber)) {
          showMessage(thisObj, "Invalid 1007Settlement")
          setValue(thisObj, 'txtFileNumber', '')
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txtFileNumber Blur event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxtFileNumberBlur = ontxtFileNumberBlur;

  const ontxt1007SettleRangeFromBlur = () => {
    try {
      let SettleRange = getValue(thisObj, 'txt1007SettleRangeFrom');
      if (SettleRange != null && SettleRange != undefined && SettleRange != '') {
        if (isNaN(SettleRange)) {
          showMessage(thisObj, "Invalid SettleRange")
          setValue(thisObj, 'txt1007SettleRangeFrom', '')
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txt1007SettleRangeFrom Blur event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxt1007SettleRangeFromBlur = ontxt1007SettleRangeFromBlur;

  const ontxt1007SettleRangeToBlur = () => {
    try {
      let SettleRange = getValue(thisObj, 'txt1007SettleRangeTo');
      if (SettleRange != null && SettleRange != undefined && SettleRange != '') {
        if (isNaN(SettleRange)) {
          showMessage(thisObj, "Invalid SettleRange")
          setValue(thisObj, 'txt1007SettleRangeTo', '')
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txtFileNumber Blur event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxt1007SettleRangeToBlur = ontxt1007SettleRangeToBlur;

  
  const  ontxtSC95InspectionChange = () => {
    try {
      let value = getValue(thisObj, 'txtSC95Inspection')
      let strRegex = new RegExp(/^[A-Za-z]\d*$/)
      const reg = new RegExp('^[0-9]+$');
      if (strRegex.test(value))
        setValue(thisObj, 'txtSC95Inspection', value.toUpperCase())
      else
      if(reg.test(value))
      {
       setValue(thisObj, 'txtSC95Inspection', value)
      }
      else{
        setValue(thisObj, 'txtSC95Inspection', '')
      }
       }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
 thisObj. ontxtSC95InspectionChange =  ontxtSC95InspectionChange
 



  const onbtnCloseClick = () => {
    try {
      setData(thisObj, 'screen_reload_print', true)
      document.getElementById("Settlements_KCMOdataSearchPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
    }
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onGridRowChange = (rowSelected) => {
    let data3 = rowSelected;
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
      enable(thisObj, 'btnOpen')
    }
  };
  thisObj.onGridRowChange = onGridRowChange;


  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_KCMOdataSearch*/}

              {/* END_USER_CODE-USER_BEFORE_KCMOdataSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxKCMOdataSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxKCMOdataSearch*/}

              <GroupBoxWidget
                conf={state.grpbxKCMOdataSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtTransmitDateFrom*/}

                {/* END_USER_CODE-USER_BEFORE_txtTransmitDateFrom*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTransmitDateFrom}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtTransmitDateFrom*/}

                {/* END_USER_CODE-USER_AFTER_txtTransmitDateFrom*/}
                {/* START_USER_CODE-USER_BEFORE_txtTransmitDateTo*/}

                {/* END_USER_CODE-USER_BEFORE_txtTransmitDateTo*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTransmitDateTo}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtTransmitDateTo*/}

                {/* END_USER_CODE-USER_AFTER_txtTransmitDateTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtFederalBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtFederalBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFederalBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFederalBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtFederalBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007SettleRangeFrom*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007SettleRangeFrom*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007SettleRangeFrom}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007SettleRangeFrom*/}

                {/* END_USER_CODE-USER_AFTER_txt1007SettleRangeFrom*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007SettleRangeTo*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007SettleRangeTo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007SettleRangeTo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007SettleRangeTo*/}

                {/* END_USER_CODE-USER_AFTER_txt1007SettleRangeTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95Inspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95Inspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95Inspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95Inspection*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95Inspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtFileNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtFileNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFileNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFileNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtFileNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007Settlement*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007Settlement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007Settlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007Settlement*/}

                {/* END_USER_CODE-USER_AFTER_txt1007Settlement*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrOpen*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrOpen*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrOpen}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                  <ButtonWidget
                    conf={state.btnOpen}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrOpen*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrOpen*/}
                {/* START_USER_CODE-USER_BEFORE_gridKCMOdata*/}

                {/* END_USER_CODE-USER_BEFORE_gridKCMOdata*/}

                <GridWidget
                  conf={state.gridKCMOdata}
                  screenConf={state}
                  linkClick={event => {
                    if (state.gridKCMOdata.selected.length > 0) {

                      thisObj.selectedRow = state.gridKCMOdata.selected[0];
                    }
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridKCMOdata}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRow => onGridRowChange(selectedRow)}
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridKCMOdata*/}

                {/* END_USER_CODE-USER_AFTER_gridKCMOdata*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxKCMOdataSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxKCMOdataSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnRequestFileResend*/}

                {/* END_USER_CODE-USER_BEFORE_btnRequestFileResend*/}

                <ButtonWidget
                  conf={state.btnRequestFileResend}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRequestFileResend*/}

                {/* END_USER_CODE-USER_AFTER_btnRequestFileResend*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_KCMOdataSearch*/}

              {/* END_USER_CODE-USER_AFTER_KCMOdataSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_KCMOdataSearch);
