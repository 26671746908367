/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  hide,
  show,
  getValue,
  setValue,
  getData,
  setData,
  goTo,
} from "../../shared/WindowImports";

import "./SpecFuncReprint.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_SpecFuncReprint(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecFuncReprint",
    windowName: "SpecFuncReprint",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.SpecFuncReprint",
    // START_USER_CODE-USER_SpecFuncReprint_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Special Functions/Reprint",
      scrCode: "PN0200C",
    },
    // END_USER_CODE-USER_SpecFuncReprint_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnReprint: {
      name: "btnReprint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Reprint",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReprint_PROPERTIES

      // END_USER_CODE-USER_btnReprint_PROPERTIES
    },
    grpbxSettlement: {
      name: "grpbxSettlement",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncReprint",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxSettlement_PROPERTIES

      // END_USER_CODE-USER_grpbxSettlement_PROPERTIES
    },
    grpbxWarehouseReceipt: {
      name: "grpbxWarehouseReceipt",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncReprint",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseReceipt_PROPERTIES
    },
    lblReprintSettlement: {
      name: "lblReprintSettlement",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "Reprint Settlement(1007)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReprintSettlement_PROPERTIES

      // END_USER_CODE-USER_lblReprintSettlement_PROPERTIES
    },
    lblReprintWarehouseReceipt: {
      name: "lblReprintWarehouseReceipt",
      type: "LabelWidget",
      parent: "grpbxWarehouseReceipt",
      Label: "Reprint Warehouse Receipt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReprintWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_lblReprintWarehouseReceipt_PROPERTIES
    },
    radioReprintDocument: {
      name: "radioReprintDocument",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxSpecFuncReprint",
      Options: "Settlement (10007):1,Warehouse Receipt:2",
      Label: "Reprint Document:",
      ColSpan: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioReprintDocument_PROPERTIES

      // END_USER_CODE-USER_radioReprintDocument_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseReceipt",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtInspection: {
      name: "txtInspection",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspection_PROPERTIES

      // END_USER_CODE-USER_txtInspection_PROPERTIES
    },
    txtWarehouseReceiptNum: {
      name: "txtWarehouseReceiptNum",
      type: "TextBoxWidget",
      parent: "grpbxWarehouseReceipt",
      Label: "WR #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceiptNum_PROPERTIES
    },
    grpbxSpecFuncReprint: {
      name: "grpbxSpecFuncReprint",
      type: "GroupBoxWidget",
      parent: "SpecFuncReprint",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSpecFuncReprint_PROPERTIES

      // END_USER_CODE-USER_grpbxSpecFuncReprint_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "SpecFuncReprint",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const formLoad = () => {
    hide(thisObj, 'grpbxSettlement');
    hide(thisObj, 'grpbxWarehouseReceipt');

  }
  const onradioReprintDocumentChange = () => {
    if (getValue(thisObj, 'radioReprintDocument') === '1') {
      show(thisObj, 'grpbxSettlement');
      hide(thisObj, 'grpbxWarehouseReceipt');
    }
    else {
      hide(thisObj, 'grpbxSettlement');
      show(thisObj, 'grpbxWarehouseReceipt');
    }
  };
  thisObj.onradioReprintDocumentChange = onradioReprintDocumentChange;
  const onbtnReprintClick = async () => {
    let obj = {};
    if (getValue(thisObj, 'radioReprintDocument') === '1') {
      let inspectionnumber = getValue(thisObj, 'txtInspection');
      if (inspectionnumber == "" || inspectionnumber == undefined || inspectionnumber.length != "7") {
        showMessage(thisObj, "Please enter a valid Inspection Number.");
        return false;
      }
      let lstrxml = await SettlementService.RetrieveInspectionHeaderList(inspectionnumber, "", "", "")
      if (lstrxml.length <= 0 || lstrxml == undefined) {
        showMessage(thisObj, "Cannot find inspection number");
        return false;

      }

      if (isNaN(inspectionnumber)) {
        obj = {
          "SC95No": inspectionnumber,
          "method_name": "rptInspectionCertificate",
          "type": "REPRINT",
          "pmViewDoc": true
        }
      }
      else {
        obj = {
          "SC95No": inspectionnumber,
          "method_name": "rptInspectionCertificate_onedoc",
          "type": "REPRINT",
          "pmViewDoc": true
        }
      }

      setData(thisObj, 'ReportPreviewInspectionScreenData', obj);
      goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click");
    }
    else {
      let buyingpoint = getValue(thisObj, 'txtBuyingPoint');
      let warehousereceipt = getValue(thisObj, 'txtWarehouseReceiptNum');
      if (buyingpoint == "" || buyingpoint == undefined || buyingpoint.length != "3") {
        showMessage(thisObj, "Please enter a valid Buying Point.");
        return false;
      }
      if (warehousereceipt == "" || warehousereceipt == undefined || warehousereceipt.length != "6") {
        showMessage(thisObj, "Please enter a valid Warehouse Receipt Number.");
        return false;
      }
      obj = {
        "WRbuyptid": buyingpoint,
        "WRNumber": warehousereceipt,
        "method_name": "rptWarehouseReceipt"
      }
      setData(thisObj, 'rptWarehouseReceiptScreenData', obj);
      //goTo(thisObj, "Settlements#rptWarehouseReceipt#DEFAULT#true#Click");
      showMessage(thisObj, "Screen will develop in Phase3.");
        return false;
    }
  };
  thisObj.onbtnReprintClick = onbtnReprintClick;
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_SpecFuncReprintPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;

  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecFuncReprint*/}

              {/* END_USER_CODE-USER_BEFORE_SpecFuncReprint*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpecFuncReprint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpecFuncReprint*/}

              <GroupBoxWidget
                conf={state.grpbxSpecFuncReprint}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_radioReprintDocument*/}

                {/* END_USER_CODE-USER_BEFORE_radioReprintDocument*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioReprintDocument}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioReprintDocument*/}

                {/* END_USER_CODE-USER_AFTER_radioReprintDocument*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSettlement*/}

                <GroupBoxWidget
                  conf={state.grpbxSettlement}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblReprintSettlement*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReprintSettlement*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReprintSettlement}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReprintSettlement*/}

                  {/* END_USER_CODE-USER_AFTER_lblReprintSettlement*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspection*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspection*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspection}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspection*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspection*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSettlement*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseReceipt*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseReceipt*/}

                <GroupBoxWidget
                  conf={state.grpbxWarehouseReceipt}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblReprintWarehouseReceipt*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReprintWarehouseReceipt*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReprintWarehouseReceipt}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReprintWarehouseReceipt*/}

                  {/* END_USER_CODE-USER_AFTER_lblReprintWarehouseReceipt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyingPoint}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceiptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceiptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWarehouseReceiptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWarehouseReceiptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtWarehouseReceiptNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWarehouseReceipt*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWarehouseReceipt*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpecFuncReprint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpecFuncReprint*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnReprint*/}

                {/* END_USER_CODE-USER_BEFORE_btnReprint*/}

                <ButtonWidget
                  conf={state.btnReprint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReprint*/}

                {/* END_USER_CODE-USER_AFTER_btnReprint*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_SpecFuncReprint*/}

              {/* END_USER_CODE-USER_AFTER_SpecFuncReprint*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_SpecFuncReprint);
