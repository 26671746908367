/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  disable
} from "../../shared/WindowImports";

import "./CountyProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_CountyProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CountyProfile",
    windowName: "CountyProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.CountyProfile",
    // START_USER_CODE-USER_CountyProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "County Profile",
      scrCode: "PN0010C",
    },
    // END_USER_CODE-USER_CountyProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxCountyProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblCCCWireTransferData: {
      name: "lblCCCWireTransferData",
      type: "LabelWidget",
      parent: "grpbxDetails",
      Label: "CCC Wire Transfer Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCCWireTransferData_PROPERTIES

      // END_USER_CODE-USER_lblCCCWireTransferData_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblState: {
      name: "lblState",
      type: "LabelWidget",
      parent: "grpbxCountyProfile",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblState_PROPERTIES

      // END_USER_CODE-USER_lblState_PROPERTIES
    },
    lblStateValue: {
      name: "lblStateValue",
      type: "LabelWidget",
      parent: "grpbxCountyProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStateValue_PROPERTIES

      // END_USER_CODE-USER_lblStateValue_PROPERTIES
    },
    txtContact: {
      name: "txtContact",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Contact:",
      LengthRange: { MinLength: 0, MaxLength: 60 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContact_PROPERTIES

      // END_USER_CODE-USER_txtContact_PROPERTIES
    },
    txtEmail: {
      name: "txtEmail",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "E-mail:",
      LengthRange: { MinLength: 0, MaxLength: 105 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail_PROPERTIES

      // END_USER_CODE-USER_txtEmail_PROPERTIES
    },
    txtFax: {
      name: "txtFax",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Fax#:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFax_PROPERTIES

      // END_USER_CODE-USER_txtFax_PROPERTIES
    },
    txtID: {
      name: "txtID",
      type: "TextBoxWidget",
      parent: "grpbxCountyProfile",
      Label: "ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtID_PROPERTIES

      // END_USER_CODE-USER_txtID_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxCountyProfile",
      Label: "Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    grpbxCountyProfile: {
      name: "grpbxCountyProfile",
      type: "GroupBoxWidget",
      parent: "CountyProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCountyProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxCountyProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "CountyProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  let FormLoad=()=>{
    let countyData=getData(thisObj,'frmCountyProfileData')
    let CountyCaption=countyData.cmdOk_Caption;
    if(CountyCaption=='Add'){
      setValue(thisObj,'lblStateValue',countyData.lblState_Caption)
      document.getElementById('btnOk').innerText = "Add"
      setValue(thisObj,'lblAddedByValue','XXXXXXXXXXXXXX')
      setValue(thisObj,'lblChangedByValue','XXXXXXXXXXXXXX')
    }
    else if(CountyCaption=='Update'){
      disable(thisObj,'txtID')
      setValue(thisObj,'lblStateValue',countyData.stateName)
      setValue(thisObj,'txtID',countyData.txtID)
      setValue(thisObj,'txtName',countyData.txtName)
      setValue(thisObj,'txtFax',countyData.txtCCCFAXNumber)
      setValue(thisObj,'txtContact',countyData.txtCCCFAXContact)
      setValue(thisObj,'txtEmail',countyData.txtCCCEmailAddress)
      setValue(thisObj,'lblAddedByValue',countyData.lblAddedBy)
      setValue(thisObj,'lblChangedByValue',countyData.lblChangedBy)
      document.getElementById('btnOk').innerText = "Update"
    }
  }

  //Validation of the form Function
  let bFormValid=()=>{
    let formValid=true;

    let txtid=getValue(thisObj,'txtID')
    if(txtid=='' || txtid==undefined){
      showMessage(thisObj,'ID is a required field')
      formValid=false;
      return;
    }

    let txtName=getValue(thisObj,'txtName')
    if(txtName=='' || txtName==undefined){
      showMessage(thisObj,'Name is a required field')
      formValid=false;
      return;
    }

    let txtFax=getValue(thisObj,'txtFax')
    let txtContact=getValue(thisObj,'txtContact')
    if(txtFax==undefined){
      txtFax='';
    }
    if(txtContact==undefined){
      txtContact='';
    }
    
    if(txtFax!='' || txtContact!=''){
      if(txtFax==''){
        showMessage(thisObj,'CCC Wire Transfer FAX number is required')
        formValid=false;
      }
      if(txtContact==''){
        showMessage(thisObj,'CCC Wire Transfer FAX contact is required')
        formValid=false;
      }
    }
    return formValid;
  }

  //Update button function
  const onbtnOkClick=async ()=>{
    if(bFormValid()==true){
      let countyData= getData(thisObj,'frmCountyProfileData')
      let CountyCaption=countyData.cmdOk_Caption;
      let county_id=getValue(thisObj,'txtID')
      let county_name=getValue(thisObj,'txtName')
      let ccc_wire_fax_number=getValue(thisObj,'txtFax') 
      let ccc_wire_fax_contact_name=getValue(thisObj,'txtContact') 
      let ccc_wire_email_address=getValue(thisObj,'txtEmail') 
      let state_abbr=countyData.state_abbr;
      if(CountyCaption=='Add'){
        let data={
          "state_abbr":state_abbr,
          "county_id": county_id,
          "county_name": county_name,
          "ccc_wire_fax_number":ccc_wire_fax_number==undefined?'':ccc_wire_fax_number,
          "ccc_wire_fax_contact_name":ccc_wire_fax_contact_name==undefined?'':ccc_wire_fax_contact_name,
          "ccc_wire_email_address":ccc_wire_email_address==undefined? '': ccc_wire_email_address
        };
        let response=await SystemMaintenanceMasterManagementService.CreateCountyControl(data);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else{
          if(response.status!=200){
            showMessage(thisObj,response.message,true)
          }
         
        }
      }
      else if(CountyCaption=='Update'){
        let data={
          "county_name": county_name,
          "ccc_wire_fax_number": ccc_wire_fax_number==undefined?'':ccc_wire_fax_number,
          "ccc_wire_fax_contact_name":ccc_wire_fax_contact_name==undefined?'':ccc_wire_fax_contact_name,
          "ccc_wire_email_address": ccc_wire_email_address==undefined ? '' : ccc_wire_email_address
        };
        let response= await SystemMaintenanceMasterManagementService.UpdateCountyControl(state_abbr,county_id,data);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else{
          if(response.status!=200){
            showMessage(thisObj,response.message,true)
          }    
        }
      }
      setData(thisObj,'AddUpdateData',state_abbr);
      goTo(thisObj,'SystemMaintenanceMasterManagement_CountyProfilePopUp')
    }
    else{
      return;
    }
  }
  thisObj.onbtnOkClick= onbtnOkClick

  //Function to check if the value entered only numeric
  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }

  const validateTextBoxEmail = (data) => {
    var res = ''
    //^&*|"'<>/
    for (var i = 0; i < data.length; i++) {
      if((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
      (data[i].charCodeAt(0) >= 64 && data[i].charCodeAt(0) <= 90) ||
      (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 122) ||
      data[i].charCodeAt(0) == 46 || data[i].charCodeAt(0) == 59 || data[i].charCodeAt(0) == 95)
      {
        res += data[i]
      }
    }
    return res;
  }

  //Email textbox Validation
  let ontxtEmailChange=()=>{
    try{
      setValue(thisObj, "txtEmail", validateTextBoxEmail(txtEmail.value))
    }
    catch(err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Email change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtEmailChange=ontxtEmailChange

  //Fax# textbox Change Event
  let ontxtFaxChange = () =>{
    try {
      setValue(thisObj, "txtFax", validateTextBox(txtFax.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtFax change event"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtFaxChange=ontxtFaxChange

  //Cancle Button Code
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_CountyProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnCancel event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CountyProfile*/}

              {/* END_USER_CODE-USER_BEFORE_CountyProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCountyProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCountyProfile*/}

              <GroupBoxWidget
                conf={state.grpbxCountyProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblState*/}

                {/* END_USER_CODE-USER_BEFORE_lblState*/}

                <LabelWidget
                  values={values}
                  conf={state.lblState}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblState*/}

                {/* END_USER_CODE-USER_AFTER_lblState*/}
                {/* START_USER_CODE-USER_BEFORE_lblStateValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblStateValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblStateValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblStateValue*/}

                {/* END_USER_CODE-USER_AFTER_lblStateValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtID*/}

                {/* END_USER_CODE-USER_BEFORE_txtID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtID*/}

                {/* END_USER_CODE-USER_AFTER_txtID*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblCCCWireTransferData*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCCCWireTransferData*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCCCWireTransferData}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCCCWireTransferData*/}

                  {/* END_USER_CODE-USER_AFTER_lblCCCWireTransferData*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFax*/}

                  {/* END_USER_CODE-USER_AFTER_txtFax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContact*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContact*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContact}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContact*/}

                  {/* END_USER_CODE-USER_AFTER_txtContact*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEmail*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEmail*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEmail}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEmail*/}

                  {/* END_USER_CODE-USER_AFTER_txtEmail*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCountyProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCountyProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_CountyProfile*/}

              {/* END_USER_CODE-USER_AFTER_CountyProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_CountyProfile);
