/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  TextAreaWidget,
  setValue,
  getValue,
  setData,
  getData,
  show,
  hide,
  enable,
  disable,
  setLabel,
  goTo,
  setFieldValues,
  showWidgets,
  hideWidgets
} from "../../shared/WindowImports";

import "./1007Settlement.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_1007Settlement(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const [updt, setUpdt] = useState(0)
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "1007Settlement",
    windowName: "1007Settlement",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.1007Settlement",
    horizontalForm: true,

    // START_USER_CODE-USER_1007Settlement_PROPERTIES
    headerData: {
      scrName: "1007 Settlement",
      scrCode: "PN1050A",
    },
    // END_USER_CODE-USER_1007Settlement_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCntrctAppPremDeduct: {
      name: "btnCntrctAppPremDeduct",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Contract Applications/ Premium Deductions",
      CharWidth: "61",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCntrctAppPremDeduct_PROPERTIES

      // END_USER_CODE-USER_btnCntrctAppPremDeduct_PROPERTIES
    },
    btnLookup: {
      name: "btnLookup",
      type: "ButtonWidget",
      parent: "grpbxControlInfo",
      Label: "Lookup",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLookup_PROPERTIES

      // END_USER_CODE-USER_btnLookup_PROPERTIES
    },
    chkboxPurchaseAll: {
      name: "chkboxPurchaseAll",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplits",
      Label: "Purchase All",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxPurchaseAll_PROPERTIES

      // END_USER_CODE-USER_chkboxPurchaseAll_PROPERTIES
    },
    chkboxStoreAll: {
      name: "chkboxStoreAll",
      type: "CheckBoxWidget",
      parent: "grpbxVendorSplits",
      Label: "Store All",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxStoreAll_PROPERTIES

      // END_USER_CODE-USER_chkboxStoreAll_PROPERTIES
    },
    ddPS0: {
      name: "ddPS0",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS0_PROPERTIES

      // END_USER_CODE-USER_ddPS0_PROPERTIES
    },
    ddPS1: {
      name: "ddPS1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS1_PROPERTIES

      // END_USER_CODE-USER_ddPS1_PROPERTIES
    },
    ddPS10: {
      name: "ddPS10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS10_PROPERTIES

      // END_USER_CODE-USER_ddPS10_PROPERTIES
    },
    ddPS11: {
      name: "ddPS11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS11_PROPERTIES

      // END_USER_CODE-USER_ddPS11_PROPERTIES
    },
    ddPS12: {
      name: "ddPS12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS12_PROPERTIES

      // END_USER_CODE-USER_ddPS12_PROPERTIES
    },
    ddPS13: {
      name: "ddPS13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS13_PROPERTIES

      // END_USER_CODE-USER_ddPS13_PROPERTIES
    },
    ddPS14: {
      name: "ddPS14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS14_PROPERTIES

      // END_USER_CODE-USER_ddPS14_PROPERTIES
    },
    ddPS15: {
      name: "ddPS15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS15_PROPERTIES

      // END_USER_CODE-USER_ddPS15_PROPERTIES
    },
    ddPS16: {
      name: "ddPS16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS16_PROPERTIES

      // END_USER_CODE-USER_ddPS16_PROPERTIES
    },
    ddPS17: {
      name: "ddPS17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS17_PROPERTIES

      // END_USER_CODE-USER_ddPS17_PROPERTIES
    },
    ddPS18: {
      name: "ddPS18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS18_PROPERTIES

      // END_USER_CODE-USER_ddPS18_PROPERTIES
    },
    ddPS19: {
      name: "ddPS19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS19_PROPERTIES

      // END_USER_CODE-USER_ddPS19_PROPERTIES
    },
    ddPS2: {
      name: "ddPS2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS2_PROPERTIES

      // END_USER_CODE-USER_ddPS2_PROPERTIES
    },
    ddPS3: {
      name: "ddPS3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS3_PROPERTIES

      // END_USER_CODE-USER_ddPS3_PROPERTIES
    },
    ddPS4: {
      name: "ddPS4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS4_PROPERTIES

      // END_USER_CODE-USER_ddPS4_PROPERTIES
    },
    ddPS5: {
      name: "ddPS5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS5_PROPERTIES

      // END_USER_CODE-USER_ddPS5_PROPERTIES
    },
    ddPS6: {
      name: "ddPS6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS6_PROPERTIES

      // END_USER_CODE-USER_ddPS6_PROPERTIES
    },
    ddPS7: {
      name: "ddPS7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS7_PROPERTIES

      // END_USER_CODE-USER_ddPS7_PROPERTIES
    },
    ddPS8: {
      name: "ddPS8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS8_PROPERTIES

      // END_USER_CODE-USER_ddPS8_PROPERTIES
    },
    ddPS9: {
      name: "ddPS9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      DataProviderForDropDown: "inline",
      Enabled: true,
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPS9_PROPERTIES

      // END_USER_CODE-USER_ddPS9_PROPERTIES
    },
    ddWRNum0: {
      name: "ddWRNum0",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum0_PROPERTIES

      // END_USER_CODE-USER_ddWRNum0_PROPERTIES
    },
    ddWRNum1: {
      name: "ddWRNum1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum1_PROPERTIES

      // END_USER_CODE-USER_ddWRNum1_PROPERTIES
    },
    ddWRNum10: {
      name: "ddWRNum10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum10_PROPERTIES

      // END_USER_CODE-USER_ddWRNum10_PROPERTIES
    },
    ddWRNum11: {
      name: "ddWRNum11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum11_PROPERTIES

      // END_USER_CODE-USER_ddWRNum11_PROPERTIES
    },
    ddWRNum12: {
      name: "ddWRNum12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum12_PROPERTIES

      // END_USER_CODE-USER_ddWRNum12_PROPERTIES
    },
    ddWRNum13: {
      name: "ddWRNum13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum13_PROPERTIES

      // END_USER_CODE-USER_ddWRNum13_PROPERTIES
    },
    ddWRNum14: {
      name: "ddWRNum14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum14_PROPERTIES

      // END_USER_CODE-USER_ddWRNum14_PROPERTIES
    },
    ddWRNum15: {
      name: "ddWRNum15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum15_PROPERTIES

      // END_USER_CODE-USER_ddWRNum15_PROPERTIES
    },
    ddWRNum16: {
      name: "ddWRNum16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum16_PROPERTIES

      // END_USER_CODE-USER_ddWRNum16_PROPERTIES
    },
    ddWRNum17: {
      name: "ddWRNum17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum17_PROPERTIES

      // END_USER_CODE-USER_ddWRNum17_PROPERTIES
    },
    ddWRNum18: {
      name: "ddWRNum18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum18_PROPERTIES

      // END_USER_CODE-USER_ddWRNum18_PROPERTIES
    },
    ddWRNum19: {
      name: "ddWRNum19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum19_PROPERTIES

      // END_USER_CODE-USER_ddWRNum19_PROPERTIES
    },
    ddWRNum2: {
      name: "ddWRNum2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum2_PROPERTIES

      // END_USER_CODE-USER_ddWRNum2_PROPERTIES
    },
    ddWRNum3: {
      name: "ddWRNum3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum3_PROPERTIES

      // END_USER_CODE-USER_ddWRNum3_PROPERTIES
    },
    ddWRNum4: {
      name: "ddWRNum4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum4_PROPERTIES

      // END_USER_CODE-USER_ddWRNum4_PROPERTIES
    },
    ddWRNum5: {
      name: "ddWRNum5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum5_PROPERTIES

      // END_USER_CODE-USER_ddWRNum5_PROPERTIES
    },
    ddWRNum6: {
      name: "ddWRNum6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum6_PROPERTIES

      // END_USER_CODE-USER_ddWRNum6_PROPERTIES
    },
    ddWRNum7: {
      name: "ddWRNum7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum7_PROPERTIES

      // END_USER_CODE-USER_ddWRNum7_PROPERTIES
    },
    ddWRNum8: {
      name: "ddWRNum8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum8_PROPERTIES

      // END_USER_CODE-USER_ddWRNum8_PROPERTIES
    },
    ddWRNum9: {
      name: "ddWRNum9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWRNum9_PROPERTIES

      // END_USER_CODE-USER_ddWRNum9_PROPERTIES
    },
    grpbxControlInfo: {
      name: "grpbxControlInfo",
      type: "GroupBoxWidget",
      parent: "grpbx1007Settlements",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxControlInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxControlInfo_PROPERTIES
    },
    grpbxInspectorRemarks: {
      name: "grpbxInspectorRemarks",
      type: "GroupBoxWidget",
      parent: "grpbx1007Settlements",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_grpbxInspectorRemarks_PROPERTIES
    },
    grpbxPrchsStrgWtCert: {
      name: "grpbxPrchsStrgWtCert",
      type: "GroupBoxWidget",
      parent: "grpbx1007Settlements",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPrchsStrgWtCert_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsStrgWtCert_PROPERTIES
    },
    grpbxPurchases: {
      name: "grpbxPurchases",
      type: "GroupBoxWidget",
      parent: "grpbxPrchsStrgWtCert",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPurchases_PROPERTIES

      // END_USER_CODE-USER_grpbxPurchases_PROPERTIES
    },
    grpbxStorage: {
      name: "grpbxStorage",
      type: "GroupBoxWidget",
      parent: "grpbxPrchsStrgWtCert",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxStorage_PROPERTIES

      // END_USER_CODE-USER_grpbxStorage_PROPERTIES
    },
    grpbxValueCalculation: {
      name: "grpbxValueCalculation",
      type: "GroupBoxWidget",
      parent: "grpbx1007Settlements",
      ColSpan: "1",
      Height: "",
      Width: "",
      clonedExtId: "56030",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxValueCalculation_PROPERTIES

      // END_USER_CODE-USER_grpbxValueCalculation_PROPERTIES
    },
    grpbxVendorSplitRow0: {
      name: "grpbxVendorSplitRow0",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56744",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow1: {
      name: "grpbxVendorSplitRow1",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES
    },
    grpbxVendorSplitRow10: {
      name: "grpbxVendorSplitRow10",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES
    },
    grpbxVendorSplitRow11: {
      name: "grpbxVendorSplitRow11",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES
    },
    grpbxVendorSplitRow12: {
      name: "grpbxVendorSplitRow12",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES
    },
    grpbxVendorSplitRow13: {
      name: "grpbxVendorSplitRow13",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES
    },
    grpbxVendorSplitRow14: {
      name: "grpbxVendorSplitRow14",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES
    },
    grpbxVendorSplitRow15: {
      name: "grpbxVendorSplitRow15",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES
    },
    grpbxVendorSplitRow16: {
      name: "grpbxVendorSplitRow16",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES
    },
    grpbxVendorSplitRow17: {
      name: "grpbxVendorSplitRow17",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES
    },
    grpbxVendorSplitRow18: {
      name: "grpbxVendorSplitRow18",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES
    },
    grpbxVendorSplitRow19: {
      name: "grpbxVendorSplitRow19",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES
    },
    grpbxVendorSplitRow2: {
      name: "grpbxVendorSplitRow2",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES
    },
    grpbxVendorSplitRow3: {
      name: "grpbxVendorSplitRow3",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES
    },
    grpbxVendorSplitRow4: {
      name: "grpbxVendorSplitRow4",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES
    },
    grpbxVendorSplitRow5: {
      name: "grpbxVendorSplitRow5",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES
    },
    grpbxVendorSplitRow6: {
      name: "grpbxVendorSplitRow6",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow6_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow6_PROPERTIES
    },
    grpbxVendorSplitRow7: {
      name: "grpbxVendorSplitRow7",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES
    },
    grpbxVendorSplitRow8: {
      name: "grpbxVendorSplitRow8",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES
    },
    grpbxVendorSplitRow9: {
      name: "grpbxVendorSplitRow9",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "7",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES
    },
    grpbxVendorSplits: {
      name: "grpbxVendorSplits",
      type: "GroupBoxWidget",
      parent: "grpbx1007Settlements",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxVendorSplits_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplits_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblControlInformation: {
      name: "lblControlInformation",
      type: "LabelWidget",
      parent: "grpbxControlInfo",
      Label: "Control Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblControlInformation_PROPERTIES

      // END_USER_CODE-USER_lblControlInformation_PROPERTIES
    },
    lblInspectorRemarks: {
      name: "lblInspectorRemarks",
      type: "LabelWidget",
      parent: "grpbxInspectorRemarks",
      Label: "Inspector Remarks",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_lblInspectorRemarks_PROPERTIES
    },
    lblPerLb3: {
      name: "lblPerLb3",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb3_PROPERTIES

      // END_USER_CODE-USER_lblPerLb3_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    lblPOrSuic0: {
      name: "lblPOrSuic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "P/S",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPOrSuic0_PROPERTIES

      // END_USER_CODE-USER_lblPOrSuic0_PROPERTIES
    },
    lblPoundsuic0: {
      name: "lblPoundsuic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Pounds",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPoundsuic0_PROPERTIES

      // END_USER_CODE-USER_lblPoundsuic0_PROPERTIES
    },
    lblPurchases: {
      name: "lblPurchases",
      type: "LabelWidget",
      parent: "grpbxPurchases",
      Label: "Purchases",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPurchases_PROPERTIES

      // END_USER_CODE-USER_lblPurchases_PROPERTIES
    },
    lblRemitTo0uic0: {
      name: "lblRemitTo0uic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo0uic0_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo0uic0_PROPERTIES
    },
    lblSharePctuic0: {
      name: "lblSharePctuic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePctuic0_PROPERTIES

      // END_USER_CODE-USER_lblSharePctuic0_PROPERTIES
    },
    lblStorage: {
      name: "lblStorage",
      type: "LabelWidget",
      parent: "grpbxStorage",
      Label: "Storage",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStorage_PROPERTIES

      // END_USER_CODE-USER_lblStorage_PROPERTIES
    },
    lblValue0uic0: {
      name: "lblValue0uic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Value",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue0uic0_PROPERTIES

      // END_USER_CODE-USER_lblValue0uic0_PROPERTIES
    },
    lblValueCalculation: {
      name: "lblValueCalculation",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Calculation",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValueCalculation_PROPERTIES

      // END_USER_CODE-USER_lblValueCalculation_PROPERTIES
    },
    lblVendor0: {
      name: "lblVendor0",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor0_PROPERTIES

      // END_USER_CODE-USER_lblVendor0_PROPERTIES
    },
    lblVendor1: {
      name: "lblVendor1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor1_PROPERTIES

      // END_USER_CODE-USER_lblVendor1_PROPERTIES
    },
    lblVendor10: {
      name: "lblVendor10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor10_PROPERTIES

      // END_USER_CODE-USER_lblVendor10_PROPERTIES
    },
    lblVendor11: {
      name: "lblVendor11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor11_PROPERTIES

      // END_USER_CODE-USER_lblVendor11_PROPERTIES
    },
    lblVendor12: {
      name: "lblVendor12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor12_PROPERTIES

      // END_USER_CODE-USER_lblVendor12_PROPERTIES
    },
    lblVendor13: {
      name: "lblVendor13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor13_PROPERTIES

      // END_USER_CODE-USER_lblVendor13_PROPERTIES
    },
    lblVendor14: {
      name: "lblVendor14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor14_PROPERTIES

      // END_USER_CODE-USER_lblVendor14_PROPERTIES
    },
    lblVendor15: {
      name: "lblVendor15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor15_PROPERTIES

      // END_USER_CODE-USER_lblVendor15_PROPERTIES
    },
    lblVendor16: {
      name: "lblVendor16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor16_PROPERTIES

      // END_USER_CODE-USER_lblVendor16_PROPERTIES
    },
    lblVendor17: {
      name: "lblVendor17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor17_PROPERTIES

      // END_USER_CODE-USER_lblVendor17_PROPERTIES
    },
    lblVendor18: {
      name: "lblVendor18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor18_PROPERTIES

      // END_USER_CODE-USER_lblVendor18_PROPERTIES
    },
    lblVendor19: {
      name: "lblVendor19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor19_PROPERTIES

      // END_USER_CODE-USER_lblVendor19_PROPERTIES
    },
    lblVendor2: {
      name: "lblVendor2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor2_PROPERTIES

      // END_USER_CODE-USER_lblVendor2_PROPERTIES
    },
    lblVendor3: {
      name: "lblVendor3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor3_PROPERTIES

      // END_USER_CODE-USER_lblVendor3_PROPERTIES
    },
    lblVendor4: {
      name: "lblVendor4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor4_PROPERTIES

      // END_USER_CODE-USER_lblVendor4_PROPERTIES
    },
    lblVendor5: {
      name: "lblVendor5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor5_PROPERTIES

      // END_USER_CODE-USER_lblVendor5_PROPERTIES
    },
    lblVendor6: {
      name: "lblVendor6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor6_PROPERTIES

      // END_USER_CODE-USER_lblVendor6_PROPERTIES
    },
    lblVendor7: {
      name: "lblVendor7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor7_PROPERTIES

      // END_USER_CODE-USER_lblVendor7_PROPERTIES
    },
    lblVendor8: {
      name: "lblVendor8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor8_PROPERTIES

      // END_USER_CODE-USER_lblVendor8_PROPERTIES
    },
    lblVendor9: {
      name: "lblVendor9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "XXX",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor9_PROPERTIES

      // END_USER_CODE-USER_lblVendor9_PROPERTIES
    },
    lblVendorSplitsuic0: {
      name: "lblVendorSplitsuic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor Splits:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorSplitsuic0_PROPERTIES

      // END_USER_CODE-USER_lblVendorSplitsuic0_PROPERTIES
    },
    lblVendoruic0: {
      name: "lblVendoruic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendoruic0_PROPERTIES

      // END_USER_CODE-USER_lblVendoruic0_PROPERTIES
    },
    lblWhseRcptuic0: {
      name: "lblWhseRcptuic0",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "WR #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWhseRcptuic0_PROPERTIES

      // END_USER_CODE-USER_lblWhseRcptuic0_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxControlInfo",
      Label: "1007 #:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtAccessFMTon: {
      name: "txtAccessFMTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess FM:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccessFMTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtAccessFMTon_PROPERTIES
    },
    // txtAdditionalVendorInfo0: {
    //   name: "txtAdditionalVendorInfo0",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow0",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo0_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo0_PROPERTIES
    // },
    // txtAdditionalVendorInfo1: {
    //   name: "txtAdditionalVendorInfo1",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow1",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo1_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo1_PROPERTIES
    // },
    // txtAdditionalVendorInfo10: {
    //   name: "txtAdditionalVendorInfo10",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow10",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo10_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo10_PROPERTIES
    // },
    // txtAdditionalVendorInfo11: {
    //   name: "txtAdditionalVendorInfo11",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow11",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo11_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo11_PROPERTIES
    // },
    // txtAdditionalVendorInfo12: {
    //   name: "txtAdditionalVendorInfo12",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow12",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo12_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo12_PROPERTIES
    // },
    // txtAdditionalVendorInfo13: {
    //   name: "txtAdditionalVendorInfo13",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow13",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo13_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo13_PROPERTIES
    // },
    // txtAdditionalVendorInfo14: {
    //   name: "txtAdditionalVendorInfo14",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow14",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo14_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo14_PROPERTIES
    // },
    // txtAdditionalVendorInfo15: {
    //   name: "txtAdditionalVendorInfo15",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow15",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo15_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo15_PROPERTIES
    // },
    // txtAdditionalVendorInfo16: {
    //   name: "txtAdditionalVendorInfo16",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow16",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo16_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo16_PROPERTIES
    // },
    // txtAdditionalVendorInfo17: {
    //   name: "txtAdditionalVendorInfo17",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow17",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo17_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo17_PROPERTIES
    // },
    // txtAdditionalVendorInfo18: {
    //   name: "txtAdditionalVendorInfo18",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow18",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo18_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo18_PROPERTIES
    // },
    // txtAdditionalVendorInfo2: {
    //   name: "txtAdditionalVendorInfo2",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow2",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo2_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo2_PROPERTIES
    // },
    // txtAdditionalVendorInfo3: {
    //   name: "txtAdditionalVendorInfo3",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow3",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo3_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo3_PROPERTIES
    // },
    // txtAdditionalVendorInfo4: {
    //   name: "txtAdditionalVendorInfo4",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow4",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo4_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo4_PROPERTIES
    // },
    // txtAdditionalVendorInfo5: {
    //   name: "txtAdditionalVendorInfo5",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow5",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo5_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo5_PROPERTIES
    // },
    // txtAdditionalVendorInfo6: {
    //   name: "txtAdditionalVendorInfo6",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow6",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo6_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo6_PROPERTIES
    // },
    // txtAdditionalVendorInfo7: {
    //   name: "txtAdditionalVendorInfo7",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow7",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo7_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo7_PROPERTIES
    // },
    // txtAdditionalVendorInfo8: {
    //   name: "txtAdditionalVendorInfo8",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow8",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo8_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo8_PROPERTIES
    // },
    // txtAdditionalVendorInfo9: {
    //   name: "txtAdditionalVendorInfo9",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow9",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendorInfo9_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendorInfo9_PROPERTIES
    // },
    // txtAdditionalVendoruic174: {
    //   name: "txtAdditionalVendoruic174",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow19",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtAdditionalVendoruic174_PROPERTIES

    //   // END_USER_CODE-USER_txtAdditionalVendoruic174_PROPERTIES
    // },
    txtAFlavusTon: {
      name: "txtAFlavusTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "A. Flavus:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAFlavusTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtAFlavusTon_PROPERTIES
    },
    txtarInspectorRemarks: {
      name: "txtarInspectorRemarks",
      type: "TextAreaWidget",
      parent: "grpbxInspectorRemarks",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarInspectorRemarks_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxControlInfo",
      Label: "Buying Point:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    // txtCMAParticipant0: {
    //   name: "txtCMAParticipant0",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow0",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant0_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant0_PROPERTIES
    // },
    // txtCMAParticipant1: {
    //   name: "txtCMAParticipant1",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow1",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant1_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant1_PROPERTIES
    // },
    // txtCMAParticipant10: {
    //   name: "txtCMAParticipant10",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow10",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant10_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant10_PROPERTIES
    // },
    // txtCMAParticipant11: {
    //   name: "txtCMAParticipant11",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow11",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant11_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant11_PROPERTIES
    // },
    // txtCMAParticipant12: {
    //   name: "txtCMAParticipant12",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow12",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant12_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant12_PROPERTIES
    // },
    // txtCMAParticipant13: {
    //   name: "txtCMAParticipant13",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow13",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant13_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant13_PROPERTIES
    // },
    // txtCMAParticipant14: {
    //   name: "txtCMAParticipant14",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow14",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant14_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant14_PROPERTIES
    // },
    // txtCMAParticipant15: {
    //   name: "txtCMAParticipant15",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow15",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant15_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant15_PROPERTIES
    // },
    // txtCMAParticipant16: {
    //   name: "txtCMAParticipant16",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow16",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant16_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant16_PROPERTIES
    // },
    // txtCMAParticipant17: {
    //   name: "txtCMAParticipant17",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow17",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant17_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant17_PROPERTIES
    // },
    // txtCMAParticipant18: {
    //   name: "txtCMAParticipant18",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow18",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant18_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant18_PROPERTIES
    // },
    // txtCMAParticipant19: {
    //   name: "txtCMAParticipant19",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow19",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant19_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant19_PROPERTIES
    // },
    // txtCMAParticipant2: {
    //   name: "txtCMAParticipant2",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow2",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant2_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant2_PROPERTIES
    // },
    // txtCMAParticipant3: {
    //   name: "txtCMAParticipant3",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow3",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant3_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant3_PROPERTIES
    // },
    // txtCMAParticipant4: {
    //   name: "txtCMAParticipant4",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow4",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant4_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant4_PROPERTIES
    // },
    // txtCMAParticipant5: {
    //   name: "txtCMAParticipant5",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow5",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant5_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant5_PROPERTIES
    // },
    // txtCMAParticipant6: {
    //   name: "txtCMAParticipant6",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow6",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant6_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant6_PROPERTIES
    // },
    // txtCMAParticipant7: {
    //   name: "txtCMAParticipant7",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow7",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant7_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant7_PROPERTIES
    // },
    // txtCMAParticipant8: {
    //   name: "txtCMAParticipant8",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow8",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant8_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant8_PROPERTIES
    // },
    // txtCMAParticipant9: {
    //   name: "txtCMAParticipant9",
    //   type: "TextBoxWidget",
    //   parent: "grpbxVendorSplitRow9",
    //   Enabled: false,
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtCMAParticipant9_PROPERTIES

    //   // END_USER_CODE-USER_txtCMAParticipant9_PROPERTIES
    // },
    txtContractSpotPricePrchs: {
      name: "txtContractSpotPricePrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Contract/Spot Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractSpotPricePrchs_PROPERTIES

      // END_USER_CODE-USER_txtContractSpotPricePrchs_PROPERTIES
    },
    txtDamageTon: {
      name: "txtDamageTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Damage:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtDamageTon_PROPERTIES
    },
    txtDeductionsPrchs: {
      name: "txtDeductionsPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "- Deductions:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeductionsPrchs_PROPERTIES

      // END_USER_CODE-USER_txtDeductionsPrchs_PROPERTIES
    },
    txtDeductionsStrg: {
      name: "txtDeductionsStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Deductions:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeductionsStrg_PROPERTIES

      // END_USER_CODE-USER_txtDeductionsStrg_PROPERTIES
    },
    txtELKPremiumTon: {
      name: "txtELKPremiumTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ELK Premium:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPremiumTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtELKPremiumTon_PROPERTIES
    },
    txtExcessMoisture: {
      name: "txtExcessMoisture",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess Moisture:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessMoisture_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtExcessMoisture_PROPERTIES
    },
    txtExcessSplitsTon: {
      name: "txtExcessSplitsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ExcessSplits",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessSplitsTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtExcessSplitsTon_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxControlInfo",
      Label: "Farm:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmerStockCostPrchs: {
      name: "txtFarmerStockCostPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Farmer Stock Cost:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmerStockCostPrchs_PROPERTIES

      // END_USER_CODE-USER_txtFarmerStockCostPrchs_PROPERTIES
    },
    txtForeignMaterial: {
      name: "txtForeignMaterial",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Foreign Material:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterial_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtForeignMaterial_PROPERTIES
    },
    txtG: {
      name: "txtG",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "/G:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtG_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtG_PROPERTIES
    },
    txtGrossWeight: {
      name: "txtGrossWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Gross Weight:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrossWeight_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtGrossWeight_PROPERTIES
    },
    txtH: {
      name: "txtH",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "H x :",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtH_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtH_PROPERTIES
    },
    txtKernelValueTon: {
      name: "txtKernelValueTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Kernel Value:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelValueTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtKernelValueTon_PROPERTIES
    },
    txtLSKvc: {
      name: "txtLSKvc",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "LSK:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKvc_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtLSKvc_PROPERTIES
    },
    txtNetValPerTonExclSLK: {
      name: "txtNetValPerTonExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Value Per Ton Excluding SLK:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtNetWeightPrchs: {
      name: "txtNetWeightPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "(g) Net Weight:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeightPrchs_PROPERTIES

      // END_USER_CODE-USER_txtNetWeightPrchs_PROPERTIES
    },
    txtNetWeightStrg: {
      name: "txtNetWeightStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "(G) Net Weight:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeightStrg_PROPERTIES

      // END_USER_CODE-USER_txtNetWeightStrg_PROPERTIES
    },
    txtNetWtExclLSK: {
      name: "txtNetWtExclLSK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight Excluding LSK:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWtExclLSK_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtNetWtExclLSK_PROPERTIES
    },
    txtOI: {
      name: "txtOI",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "O x I $:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOI_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtOI_PROPERTIES
    },
    txtOptionPayment: {
      name: "txtOptionPayment",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Option Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionPayment_PROPERTIES

      // END_USER_CODE-USER_txtOptionPayment_PROPERTIES
    },
    txtOptionPriceStrg: {
      name: "txtOptionPriceStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Option Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionPriceStrg_PROPERTIES

      // END_USER_CODE-USER_txtOptionPriceStrg_PROPERTIES
    },
    txtPctOfSupportPrchs: {
      name: "txtPctOfSupportPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "% of Support:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctOfSupportPrchs_PROPERTIES

      // END_USER_CODE-USER_txtPctOfSupportPrchs_PROPERTIES
    },
    txtPctOfSupportStrg: {
      name: "txtPctOfSupportStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "% of Support:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctOfSupportStrg_PROPERTIES

      // END_USER_CODE-USER_txtPctOfSupportStrg_PROPERTIES
    },
    txtPounds0: {
      name: "txtPounds0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds0_PROPERTIES

      // END_USER_CODE-USER_txtPounds0_PROPERTIES
    },
    txtPounds1: {
      name: "txtPounds1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds1_PROPERTIES

      // END_USER_CODE-USER_txtPounds1_PROPERTIES
    },
    txtPounds10: {
      name: "txtPounds10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds10_PROPERTIES

      // END_USER_CODE-USER_txtPounds10_PROPERTIES
    },
    txtPounds11: {
      name: "txtPounds11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds11_PROPERTIES

      // END_USER_CODE-USER_txtPounds11_PROPERTIES
    },
    txtPounds12: {
      name: "txtPounds12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds12_PROPERTIES

      // END_USER_CODE-USER_txtPounds12_PROPERTIES
    },
    txtPounds13: {
      name: "txtPounds13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds13_PROPERTIES

      // END_USER_CODE-USER_txtPounds13_PROPERTIES
    },
    txtPounds14: {
      name: "txtPounds14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds14_PROPERTIES

      // END_USER_CODE-USER_txtPounds14_PROPERTIES
    },
    txtPounds15: {
      name: "txtPounds15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds15_PROPERTIES

      // END_USER_CODE-USER_txtPounds15_PROPERTIES
    },
    txtPounds16: {
      name: "txtPounds16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds16_PROPERTIES

      // END_USER_CODE-USER_txtPounds16_PROPERTIES
    },
    txtPounds17: {
      name: "txtPounds17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds17_PROPERTIES

      // END_USER_CODE-USER_txtPounds17_PROPERTIES
    },
    txtPounds18: {
      name: "txtPounds18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds18_PROPERTIES

      // END_USER_CODE-USER_txtPounds18_PROPERTIES
    },
    txtPounds19: {
      name: "txtPounds19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds19_PROPERTIES

      // END_USER_CODE-USER_txtPounds19_PROPERTIES
    },
    txtPounds2: {
      name: "txtPounds2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds2_PROPERTIES

      // END_USER_CODE-USER_txtPounds2_PROPERTIES
    },
    txtPounds3: {
      name: "txtPounds3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds3_PROPERTIES

      // END_USER_CODE-USER_txtPounds3_PROPERTIES
    },
    txtPounds4: {
      name: "txtPounds4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds4_PROPERTIES

      // END_USER_CODE-USER_txtPounds4_PROPERTIES
    },
    txtPounds5: {
      name: "txtPounds5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds5_PROPERTIES

      // END_USER_CODE-USER_txtPounds5_PROPERTIES
    },
    txtPounds6: {
      name: "txtPounds6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds6_PROPERTIES

      // END_USER_CODE-USER_txtPounds6_PROPERTIES
    },
    txtPounds7: {
      name: "txtPounds7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds7_PROPERTIES

      // END_USER_CODE-USER_txtPounds7_PROPERTIES
    },
    txtPounds8: {
      name: "txtPounds8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds8_PROPERTIES

      // END_USER_CODE-USER_txtPounds8_PROPERTIES
    },
    txtPounds9: {
      name: "txtPounds9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds9_PROPERTIES

      // END_USER_CODE-USER_txtPounds9_PROPERTIES
    },
    txtPremiumsPrchs: {
      name: "txtPremiumsPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "+ Premiums:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumsPrchs_PROPERTIES

      // END_USER_CODE-USER_txtPremiumsPrchs_PROPERTIES
    },
    txtProceedsPrchs: {
      name: "txtProceedsPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Proceeds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceedsPrchs_PROPERTIES

      // END_USER_CODE-USER_txtProceedsPrchs_PROPERTIES
    },
    txtProceedsStrg: {
      name: "txtProceedsStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Proceeds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceedsStrg_PROPERTIES

      // END_USER_CODE-USER_txtProceedsStrg_PROPERTIES
    },
    txtRemitTo0: {
      name: "txtRemitTo0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo0_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo0_PROPERTIES
    },
    txtRemitTo1: {
      name: "txtRemitTo1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo1_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo1_PROPERTIES
    },
    txtRemitTo10: {
      name: "txtRemitTo10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo10_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo10_PROPERTIES
    },
    txtRemitTo11: {
      name: "txtRemitTo11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo11_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo11_PROPERTIES
    },
    txtRemitTo12: {
      name: "txtRemitTo12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo12_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo12_PROPERTIES
    },
    txtRemitTo13: {
      name: "txtRemitTo13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo13_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo13_PROPERTIES
    },
    txtRemitTo14: {
      name: "txtRemitTo14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo14_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo14_PROPERTIES
    },
    txtRemitTo15: {
      name: "txtRemitTo15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo15_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo15_PROPERTIES
    },
    txtRemitTo16: {
      name: "txtRemitTo16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo16_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo16_PROPERTIES
    },
    txtRemitTo17: {
      name: "txtRemitTo17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo17_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo17_PROPERTIES
    },
    txtRemitTo18: {
      name: "txtRemitTo18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo18_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo18_PROPERTIES
    },
    txtRemitTo19: {
      name: "txtRemitTo19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo19_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo19_PROPERTIES
    },
    txtRemitTo2: {
      name: "txtRemitTo2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo2_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo2_PROPERTIES
    },
    txtRemitTo3: {
      name: "txtRemitTo3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo3_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo3_PROPERTIES
    },
    txtRemitTo4: {
      name: "txtRemitTo4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo4_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo4_PROPERTIES
    },
    txtRemitTo5: {
      name: "txtRemitTo5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo5_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo5_PROPERTIES
    },
    txtRemitTo6: {
      name: "txtRemitTo6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo6_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo6_PROPERTIES
    },
    txtRemitTo7: {
      name: "txtRemitTo7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo7_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo7_PROPERTIES
    },
    txtRemitTo8: {
      name: "txtRemitTo8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo8_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo8_PROPERTIES
    },
    txtRemitTo9: {
      name: "txtRemitTo9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo9_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo9_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxControlInfo",
      Label: "SC95 #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtSeedPremiumStrg: {
      name: "txtSeedPremiumStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Seed Premium:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedPremiumStrg_PROPERTIES

      // END_USER_CODE-USER_txtSeedPremiumStrg_PROPERTIES
    },
    txtSharePct0: {
      name: "txtSharePct0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct0_PROPERTIES

      // END_USER_CODE-USER_txtSharePct0_PROPERTIES
    },
    txtSharePct1: {
      name: "txtSharePct1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct1_PROPERTIES

      // END_USER_CODE-USER_txtSharePct1_PROPERTIES
    },
    txtSharePct10: {
      name: "txtSharePct10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct10_PROPERTIES

      // END_USER_CODE-USER_txtSharePct10_PROPERTIES
    },
    txtSharePct11: {
      name: "txtSharePct11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct11_PROPERTIES

      // END_USER_CODE-USER_txtSharePct11_PROPERTIES
    },
    txtSharePct12: {
      name: "txtSharePct12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct12_PROPERTIES

      // END_USER_CODE-USER_txtSharePct12_PROPERTIES
    },
    txtSharePct13: {
      name: "txtSharePct13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct13_PROPERTIES

      // END_USER_CODE-USER_txtSharePct13_PROPERTIES
    },
    txtSharePct14: {
      name: "txtSharePct14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct14_PROPERTIES

      // END_USER_CODE-USER_txtSharePct14_PROPERTIES
    },
    txtSharePct15: {
      name: "txtSharePct15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct15_PROPERTIES

      // END_USER_CODE-USER_txtSharePct15_PROPERTIES
    },
    txtSharePct16: {
      name: "txtSharePct16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct16_PROPERTIES

      // END_USER_CODE-USER_txtSharePct16_PROPERTIES
    },
    txtSharePct17: {
      name: "txtSharePct17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct17_PROPERTIES

      // END_USER_CODE-USER_txtSharePct17_PROPERTIES
    },
    txtSharePct18: {
      name: "txtSharePct18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct18_PROPERTIES

      // END_USER_CODE-USER_txtSharePct18_PROPERTIES
    },
    txtSharePct19: {
      name: "txtSharePct19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct19_PROPERTIES

      // END_USER_CODE-USER_txtSharePct19_PROPERTIES
    },
    txtSharePct2: {
      name: "txtSharePct2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct2_PROPERTIES

      // END_USER_CODE-USER_txtSharePct2_PROPERTIES
    },
    txtSharePct3: {
      name: "txtSharePct3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct3_PROPERTIES

      // END_USER_CODE-USER_txtSharePct3_PROPERTIES
    },
    txtSharePct4: {
      name: "txtSharePct4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct4_PROPERTIES

      // END_USER_CODE-USER_txtSharePct4_PROPERTIES
    },
    txtSharePct5: {
      name: "txtSharePct5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct5_PROPERTIES

      // END_USER_CODE-USER_txtSharePct5_PROPERTIES
    },
    txtSharePct6: {
      name: "txtSharePct6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct6_PROPERTIES

      // END_USER_CODE-USER_txtSharePct6_PROPERTIES
    },
    txtSharePct7: {
      name: "txtSharePct7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct7_PROPERTIES

      // END_USER_CODE-USER_txtSharePct7_PROPERTIES
    },
    txtSharePct8: {
      name: "txtSharePct8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct8_PROPERTIES

      // END_USER_CODE-USER_txtSharePct8_PROPERTIES
    },
    txtSharePct9: {
      name: "txtSharePct9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct9_PROPERTIES

      // END_USER_CODE-USER_txtSharePct9_PROPERTIES
    },
    txtTotalDiscountsTon: {
      name: "txtTotalDiscountsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Discounts:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDiscountsTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtTotalDiscountsTon_PROPERTIES
    },
    txtTotalLoadVal: {
      name: "txtTotalLoadVal",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Value of Load (g):",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLoadVal_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtTotalLoadVal_PROPERTIES
    },
    txtTotalLoadValuePrchs: {
      name: "txtTotalLoadValuePrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "(p) Total (Value Of Load):",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLoadValuePrchs_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoadValuePrchs_PROPERTIES
    },
    txtTotalLoadValueStrg: {
      name: "txtTotalLoadValueStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "(P) Total (Value Of Load):",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLoadValueStrg_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoadValueStrg_PROPERTIES
    },
    txtTotalTon: {
      name: "txtTotalTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtTotalTon_PROPERTIES
    },
    txtValPerPoundExclSLK: {
      name: "txtValPerPoundExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Excluding LSK:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES
    },
    txtValPerPoundInclSLK: {
      name: "txtValPerPoundInclSLK",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Including LSK:",
      //ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES
    },
    txtValue0: {
      name: "txtValue0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue0_PROPERTIES

      // END_USER_CODE-USER_txtValue0_PROPERTIES
    },
    txtValue1: {
      name: "txtValue1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue1_PROPERTIES

      // END_USER_CODE-USER_txtValue1_PROPERTIES
    },
    txtValue10: {
      name: "txtValue10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue10_PROPERTIES

      // END_USER_CODE-USER_txtValue10_PROPERTIES
    },
    txtValue11: {
      name: "txtValue11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue11_PROPERTIES

      // END_USER_CODE-USER_txtValue11_PROPERTIES
    },
    txtValue12: {
      name: "txtValue12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue12_PROPERTIES

      // END_USER_CODE-USER_txtValue12_PROPERTIES
    },
    txtValue13: {
      name: "txtValue13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue13_PROPERTIES

      // END_USER_CODE-USER_txtValue13_PROPERTIES
    },
    txtValue14: {
      name: "txtValue14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue14_PROPERTIES

      // END_USER_CODE-USER_txtValue14_PROPERTIES
    },
    txtValue15: {
      name: "txtValue15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue15_PROPERTIES

      // END_USER_CODE-USER_txtValue15_PROPERTIES
    },
    txtValue16: {
      name: "txtValue16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue16_PROPERTIES

      // END_USER_CODE-USER_txtValue16_PROPERTIES
    },
    txtValue17: {
      name: "txtValue17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue17_PROPERTIES

      // END_USER_CODE-USER_txtValue17_PROPERTIES
    },
    txtValue18: {
      name: "txtValue18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue18_PROPERTIES

      // END_USER_CODE-USER_txtValue18_PROPERTIES
    },
    txtValue19: {
      name: "txtValue19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue19_PROPERTIES

      // END_USER_CODE-USER_txtValue19_PROPERTIES
    },
    txtValue2: {
      name: "txtValue2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue2_PROPERTIES

      // END_USER_CODE-USER_txtValue2_PROPERTIES
    },
    txtValue3: {
      name: "txtValue3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue3_PROPERTIES

      // END_USER_CODE-USER_txtValue3_PROPERTIES
    },
    txtValue4: {
      name: "txtValue4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue4_PROPERTIES

      // END_USER_CODE-USER_txtValue4_PROPERTIES
    },
    txtValue5: {
      name: "txtValue5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue5_PROPERTIES

      // END_USER_CODE-USER_txtValue5_PROPERTIES
    },
    txtValue6: {
      name: "txtValue6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue6_PROPERTIES

      // END_USER_CODE-USER_txtValue6_PROPERTIES
    },
    txtValue7: {
      name: "txtValue7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue7_PROPERTIES

      // END_USER_CODE-USER_txtValue7_PROPERTIES
    },
    txtValue8: {
      name: "txtValue8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue8_PROPERTIES

      // END_USER_CODE-USER_txtValue8_PROPERTIES
    },
    txtValue9: {
      name: "txtValue9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue9_PROPERTIES

      // END_USER_CODE-USER_txtValue9_PROPERTIES
    },
    txtValueOfOptionStrg: {
      name: "txtValueOfOptionStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Value of Option:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValueOfOptionStrg_PROPERTIES

      // END_USER_CODE-USER_txtValueOfOptionStrg_PROPERTIES
    },
    txtValueOfSegPrchs: {
      name: "txtValueOfSegPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Value of Seg:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValueOfSegPrchs_PROPERTIES

      // END_USER_CODE-USER_txtValueOfSegPrchs_PROPERTIES
    },
    txtVendor0: {
      name: "txtVendor0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor0_PROPERTIES

      // END_USER_CODE-USER_txtVendor0_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor10: {
      name: "txtVendor10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor10_PROPERTIES

      // END_USER_CODE-USER_txtVendor10_PROPERTIES
    },
    txtVendor11: {
      name: "txtVendor11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor11_PROPERTIES

      // END_USER_CODE-USER_txtVendor11_PROPERTIES
    },
    txtVendor12: {
      name: "txtVendor12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor12_PROPERTIES

      // END_USER_CODE-USER_txtVendor12_PROPERTIES
    },
    txtVendor13: {
      name: "txtVendor13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor13_PROPERTIES

      // END_USER_CODE-USER_txtVendor13_PROPERTIES
    },
    txtVendor14: {
      name: "txtVendor14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor14_PROPERTIES

      // END_USER_CODE-USER_txtVendor14_PROPERTIES
    },
    txtVendor15: {
      name: "txtVendor15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor15_PROPERTIES

      // END_USER_CODE-USER_txtVendor15_PROPERTIES
    },
    txtVendor16: {
      name: "txtVendor16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor16_PROPERTIES

      // END_USER_CODE-USER_txtVendor16_PROPERTIES
    },
    txtVendor17: {
      name: "txtVendor17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor17_PROPERTIES

      // END_USER_CODE-USER_txtVendor17_PROPERTIES
    },
    txtVendor18: {
      name: "txtVendor18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor18_PROPERTIES

      // END_USER_CODE-USER_txtVendor18_PROPERTIES
    },
    txtVendor19: {
      name: "txtVendor19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor19_PROPERTIES

      // END_USER_CODE-USER_txtVendor19_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES

      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES

      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES

      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor5_PROPERTIES

      // END_USER_CODE-USER_txtVendor5_PROPERTIES
    },
    txtVendor6: {
      name: "txtVendor6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor6_PROPERTIES

      // END_USER_CODE-USER_txtVendor6_PROPERTIES
    },
    txtVendor7: {
      name: "txtVendor7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor7_PROPERTIES

      // END_USER_CODE-USER_txtVendor7_PROPERTIES
    },
    txtVendor8: {
      name: "txtVendor8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor8_PROPERTIES

      // END_USER_CODE-USER_txtVendor8_PROPERTIES
    },
    txtVendor9: {
      name: "txtVendor9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor9_PROPERTIES

      // END_USER_CODE-USER_txtVendor9_PROPERTIES
    },
    txtWtIncludingVehicle: {
      name: "txtWtIncludingVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. Including Vehicle:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES
    },
    txtWtlessFM: {
      name: "txtWtlessFM",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Weight Less FM:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtlessFM_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtWtlessFM_PROPERTIES
    },
    txtWtOfVehicle: {
      name: "txtWtOfVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. of Vehicle:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicle_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtWtOfVehicle_PROPERTIES
    },
    grpbx1007Settlements: {
      name: "grpbx1007Settlements",
      type: "GroupBoxWidget",
      parent: "1007Settlement",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx1007Settlements_PROPERTIES

      // END_USER_CODE-USER_grpbx1007Settlements_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "1007Settlement",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0)
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    customLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);


  useEffect(() => {
    async function after_ContractApplication2009() {
      let obj = getData(thisObj, "PageData")
      lblVendorNumber = getData(thisObj, "lblVendorNumber")
      try {
        if (getData(thisObj, "closingOfContractApplication2009") == true) {
          let dataFromContractApplication2009 = getData(thisObj, "lstNewContractLineFromContractApplication2009") == null ? [] : getData(thisObj, "lstNewContractLineFromContractApplication2009")
          obj.lstNewContractLine = dataFromContractApplication2009
          if (getData(thisObj, "lblApplicationsCanceled") == true) {
            ClearOptionPayment()
            return
          }
          CalculateSeg2009()

          if (obj.bSegValueCalculated == false) {
            ClearOptionPayment()
          }
          enable(thisObj, "btnCntrctAppPremDeduct")

          obj.sApplContractList = ""
          obj.sTentContractList = ""
          obj.sSeedContractList = ""

          obj.sAppliedList = ""
          obj.sTentList = ""
          obj.sSeedList = ""

          obj.sPVendorList = ""
          obj.sSVendorList = ""
          obj.sPrevContract = ""

          obj.lApplLbs = 0
          obj.lTentLbs = 0
          obj.lSeedLbs = 0

          for (let i = 0; i < dataFromContractApplication2009.length; i++) {
            let arrContractLine = obj.lstNewContractLine[i].split("|")
            if (arrContractLine[1] !== "SPOT") {
              if (obj.sPrevContract !== arrContractLine[0]) {
                if (obj.lApplLbs !== 0) {
                  obj.sAppliedList = obj.sAppliedList + obj.lApplLbs + ","
                  obj.sApplContractList = obj.sApplContractList + obj.sPrevContract + ","
                  obj.lApplLbs = 0
                }
                if (obj.lTentLbs !== 0) {
                  obj.sTentList = obj.sTentList + obj.lTentLbs + ","
                  obj.sTentContractList = obj.sTentContractList + obj.sPrevContract + ","
                  obj.lTentLbs = 0
                }
                if (obj.lSeedLbs !== 0) {
                  obj.sSeedList = obj.sSeedList + obj.lSeedLbs + ","
                  obj.sSeedContractList = obj.sSeedContractList + obj.sPrevContract + ","
                  obj.lSeedLbs = 0
                }
              }
              if (arrContractLine[3] == "A") {
                obj.lApplLbs = obj.lApplLbs + Number(arrContractLine[2])
              } else if (arrContractLine[3] == "S") {
                obj.lSeedLbs = obj.lSeedLbs + Number(arrContractLine[2])
              } else {
                obj.lTentLbs = obj.lTentLbs + Number(arrContractLine[2])
              }
              obj.sPrevContract = arrContractLine[0]
            }
          }

          if (obj.lApplLbs !== 0) {
            obj.sAppliedList = obj.sAppliedList + obj.lApplLbs + ","
            obj.sApplContractList = obj.sApplContractList + obj.sPrevContract + ","
            obj.lApplLbs = 0
          }
          if (obj.lTentLbs !== 0) {
            obj.sTentList = obj.sTentList + obj.lTentLbs + ","
            obj.sTentContractList = obj.sTentContractList + obj.sPrevContract + ","
            obj.lTentLbs = 0
          }
          if (obj.lSeedLbs !== 0) {
            obj.sSeedList = obj.sSeedList + obj.lSeedLbs + ","
            obj.sSeedContractList = obj.sSeedContractList + obj.sPrevContract + ","
            obj.lSeedLbs = 0
          }

          obj.dblGrossWtP = 0
          obj.dblGrossWtS = 0
          obj.dblLSKWtP = 0
          obj.dblLSKWtS = 0
          obj.dblNetWtP = 0
          obj.dblNetWtS = 0

          for (let i = 0; i < lblVendorNumber.length; i++) {
            if (getValue(thisObj, ["ddPS" + (i)]) == 'P') {
              obj.sPVendorList = obj.sPVendorList + lblVendorNumber[i] + ","
              obj.dblGrossWtP = obj.dblGrossWtP + Number((isNumeric(obj.marrVendorInfo6[i])) ? obj.marrVendorInfo6[i] : 0)
              obj.dblLSKWtP = obj.dblLSKWtP + Number((isNumeric(obj.marrVendorInfo8[i])) ? obj.marrVendorInfo8[i] : 0)
              obj.dblNetWtP = obj.dblNetWtP + Number((isNumeric(obj.marrVendorInfo7[i])) ? obj.marrVendorInfo7[i] : 0)
            }
            if (getValue(thisObj, ["ddPS" + (i)]) == 'S') {
              obj.sSVendorList = obj.sSVendorList + lblVendorNumber[i] + ","
              obj.dblGrossWtS = obj.dblGrossWtS + Number((isNumeric(obj.marrVendorInfo6[i])) ? obj.marrVendorInfo6[i] : 0)
              obj.dblLSKWtS = obj.dblLSKWtS + Number((isNumeric(obj.marrVendorInfo8[i])) ? obj.marrVendorInfo8[i] : 0)
              obj.dblNetWtS = obj.dblNetWtS + Number((isNumeric(obj.marrVendorInfo7[i])) ? obj.marrVendorInfo7[i] : 0)
            }
          }

          setDataFor_cleaningCuringCost()
          setData(thisObj, "closingOfCleaningCuringCost", false)
          goTo(thisObj, "Settlements#CleaningCuringCosts#DEFAULT#true#Click")
        }
        setData(thisObj, "closingOfContractApplication2009", false)
      } catch (error) {
        showMessage(thisObj, "error on functions after ContractApplication2009 closing")
        obj.lblPremDeductUnselect = ""
      }
      setData(thisObj, "PageData", obj)
    }
    after_ContractApplication2009()
  }, [getData(thisObj, "closingOfContractApplication2009")]);

  useEffect(() => {
    let obj = getData(thisObj, "PageData")
    try {
      if (getData(thisObj, "closingOfCleaningCuringCost") == true) {
        if (getData(thisObj, "mbCancelled") == true) {
          ClearOptionPayment()
          return
        }
        PublicProperties()
        if (obj.sSVendorList.length !== 0) {
          obj.sSVendorList = obj.sSVendorList.slice(0, -1);
        } else {
          obj.sSVendorList = ""
        }
        if (obj.sPVendorList.length !== 0) {
          obj.sPVendorList = obj.sPVendorList.slice(0, -1);
        } else {
          obj.sPVendorList = ""
        }
        if (obj.sAppliedList.length !== 0) {
          obj.sAppliedList = obj.sAppliedList.slice(0, -1);
        } else {
          obj.sAppliedList = ""
        }
        if (obj.sTentList.length !== 0) {
          obj.sTentList = obj.sTentList.slice(0, -1);
        } else {
          obj.sTentList = ""
        }
        if (obj.sSeedList.length !== 0) {
          obj.sSeedList = obj.sSeedList.slice(0, -1);
        } else {
          obj.sSeedList = ""
        }
        if (obj.sApplContractList.length !== 0) {
          obj.sApplContractList = obj.sApplContractList.slice(0, -1);
        } else {
          obj.sApplContractList = ""
        }
        if (obj.sTentContractList.length !== 0) {
          obj.sTentContractList = obj.sTentContractList.slice(0, -1);
        } else {
          obj.sTentContractList = ""
        }
        if (obj.sSeedContractList.length !== 0) {
          obj.sSeedContractList = obj.sSeedContractList.slice(0, -1);
        } else {
          obj.sSeedContractList = ""
        }
        if (getData(thisObj, "lblSeedPremExpected") == "Y") {
          setData(thisObj, "lblSeedPremNetLBS", (obj.dblNetWtS + obj.dblNetWtP))
        }
        if (obj.mbCorrectionProcess) {
          setData(thisObj, "mbCorrectionProcess_forPremiumDeductions", true) // data for PremiumDeduction screen
        }
        setData(thisObj, "ParentForm_1007Settlement", true) //data for PremiumDeduction screen
        obj.lblPremDeductUnselect = "" //doing this before opening next screen
        setDataFor_premiumDeductions(
          getValue(thisObj, "txtSC95"),
          (getValue(thisObj, "txtBuyingPoint")).slice(0, (getValue(thisObj, "txtBuyingPoint")).indexOf(" ")),
          "N",
          obj.lblFarmerStockCost,
          obj.sApplContractList,
          obj.sAppliedList,
          obj.sPVendorList,
          obj.dblNetWtP,
          obj.dblLSKWtP,
          obj.dblGrossWtP,
          obj.lblStoresValueOfSeg,
          obj.sTentContractList,
          obj.sTentList,
          obj.sSVendorList,
          obj.dblNetWtS,
          obj.dblLSKWtS,
          obj.dblGrossWtS,
          obj.lblPurchValueOfLoad,
          obj.lblStoresValueOfLoad,
          obj.strCureCleanCosts,
          obj.sPDcodes_cure_clean_costs,
          (Number(obj.lblFarmerStockCost) - Number(obj.lblOptionPayment)),
          obj.sSeedContractList,
          obj.sSeedList)
        setData(thisObj, "closingOfPremiumDeductions", false)
        goTo(thisObj, "Settlements#PremiumDeductions#DEFAULT#true#Click")
      }
      setData(thisObj, "closingOfCleaningCuringCost", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after CleaningCuringCost closing")
      obj.lblPremDeductUnselect = ""
    }
    setData(thisObj, "PageData", obj)
  }, [getData(thisObj, "closingOfCleaningCuringCost")])

  useEffect(() => {
    let obj = getData(thisObj, "PageData")
    try {
      if (getData(thisObj, "closingOfPremiumDeductions") == true) {
        let AllValues = getData(thisObj, "PremiumDeductionScreenData")
        if (getData(thisObj, "mbCancelled") == true || AllValues.lblPremDeduct == "") {
          ClearOptionPayment()
          return
        }
        dataUpdateAfterPremiumDeductions()
        document.getElementById("btnCntrctAppPremDeduct").innerText = "Complete/Print 1007"
      }
      setData(thisObj, "closingOfPremiumDeductions", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after PremiumDeduction closing")
      obj.lblPremDeductUnselect = ""
    }
    setData(thisObj, "PageData", obj)
  }, [getData(thisObj, "closingOfPremiumDeductions")])

  useEffect(() => {

    let obj = getData(thisObj, "PageData")
    try {
      if (getData(thisObj, "closingOfReportPreviewInspection") == true) {
        if (!getData(thisObj, "worksheet_printed_successfully")) {
          if (obj.mbCorrectionProcess) {
            let nPrompt1 = window.confirm("Did the correction document print correctly?")
            if (!nPrompt1) {
              setDataFor_reportPreviewInspection("1")
              goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
              setData(thisObj, "closingOfReportPreviewInspection", false)
              return
            }
          }
          else {
            let nPrompt2 = window.confirm("Did the settlement print correctly?")
            if (!nPrompt2) {
              if (getValue(thisObj, "txtSC95") !== getValue(thisObj, "txt1007")) {
                setDataFor_reportPreviewInspection("2")
                goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
                setData(thisObj, "closingOfReportPreviewInspection", false)
                return
              }
              else {
                setDataFor_reportPreviewInspection("3")
                goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
                setData(thisObj, "closingOfReportPreviewInspection", false)
                return
              }
            }

            let boolWorksheet = window.confirm("PRINT A WORKSHEET?")
            if (boolWorksheet) {
              setDataFor_reportPreviewInspection("4")
              goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
              setData(thisObj, "closingOfReportPreviewInspection", false)
              return
            }
          }
        }
        setData(thisObj, "lblfreefv95", "N")

        let newobj = {}
        newobj.free1007flag = "N"
        let buy_pt_id = thisObj.values.txtBuyingPoint == null ? null : thisObj.values.txtBuyingPoint.split(' ')[0].trim()
        let insp_num = getValue(thisObj, "txtSC95")
        SettlementService.updateFree1007Flag(buy_pt_id, insp_num, newobj).then(
          response => {
            if (response.status !== 200) {
              return
            }
            else {
              setData(thisObj, "UnloadFV95Screen", true)
              document.getElementById("Settlements_1007SettlementPopUp").childNodes[0].click();
            }
          }
        )

      }
      setData(thisObj, "closingOfReportPreviewInspection", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after ReportPreviewInspection closing")
      obj.lblPremDeductUnselect = ""
    }
    setData(thisObj, "PageData", obj)

  }, [getData(thisObj, "closingOfReportPreviewInspection")])

  useEffect(() => {
    if (updt > 0) {
      // setLoading(true)
      // setRndr(true)
      // setLoading(true)
      EmptyForm()
    }
  }, [updt])

  async function customLoad() {
    getDataFrom_ParentScreens()
    VaribleDeclaration()
    let obj = getData(thisObj, "PageData")
    for (let j = 0; j < 20; j++) {
      let obj1 = ''
      obj.marrVendorInfo0.push(obj1)
      obj.marrVendorInfo1.push(obj1)
      obj.marrVendorInfo2.push(obj1)
      obj.marrVendorInfo3.push(obj1)
      obj.marrVendorInfo4.push(obj1)
      obj.marrVendorInfo5.push(obj1)
      obj.marrVendorInfo6.push(obj1)
      obj.marrVendorInfo7.push(obj1)
      obj.marrVendorInfo8.push(obj1)
      obj.marrVendorInfo9.push(obj1)
      obj.marrVendorInfo10.push(obj1)
      obj.marrVendorInfo11.push(obj1)
      obj.marrVendorInfo12.push(obj1)
      obj.marrVendorInfo13.push(obj1)
      obj.marrVendorInfo14.push(obj1)
      obj.marrVendorInfo15.push(obj1)
      obj.marrVendorInfo16.push(obj1)
      obj.marrVendorInfo17.push(obj1)
    }

    setData(thisObj, "95ValChange", false)
    setData(thisObj, "95ValChange2", false) //for not running showhide operation Multiple times
    setData(thisObj, "closingOfContractapplication2009", false)
    setData(thisObj, "closingOfCleaningCuringCost", false)
    setData(thisObj, "closingOfPremiumDeductions", false)
    setData(thisObj, "closingOfReportPreviewInspection", false)
    setData(thisObj, "lblApplicationsCanceled", false)
    //setData(thisObj, "CheckApiBoolean", true)
    setData(thisObj, "mbCancelled", false)
    setData(thisObj, "PremiumDeductionScreenData", [])
    formLoad()
    setTimeout(() => {
      onbtnLookupClick() // suggested by mastercraft team  //this setout timer is needed as txtsc95 and txt1007 is taking time to load
    }, 500);
    setData(thisObj, 'selectedRowReload', true); //for FV95Search reload
    setData(thisObj, "PageData", obj)
  }


  // START_USER_CODE-USER_METHODS

  //Declaring variables
  async function VaribleDeclaration() {
    setData(thisObj, "worksheet_printed_successfully", false)
    let dataObj = {};
    setData(thisObj, "PageData", dataObj)
    dataObj.mbSupersedeProcess = null;
    dataObj.mbCorrectionProcess = null;

    dataObj.mdtSettlementDate = null;
    dataObj.mlSettlementNum = null;

    dataObj.mbStateId = null;
    dataObj.mbCountyId = null;
    dataObj.mbFarmId = null;
    dataObj.mbFarmSuffix = null;     //'Project Suffix
    dataObj.mbPnutTypeID = null;
    dataObj.mbPnutVarietyId = null;
    dataObj.mbBuyPtId = null;
    dataObj.mbAreaId = null;
    dataObj.mbInspStatus = null;
    dataObj.mbInspDateTime = null;
    dataObj.mbSeedGen = null;
    dataObj.mbProbePattern = null;
    dataObj.mbSegment = null;
    dataObj.mbEdibleOil = null;
    dataObj.mbOleicInd = null;
    dataObj.mbCornInd = null;
    dataObj.mbFlavusInd = null;
    dataObj.mbVicamInd = null;
    dataObj.mbVicamPPB = null;
    dataObj.mbOBLBuyId = null;
    dataObj.mbOBLWhseId = null;
    dataObj.mbOBLBin = null;
    dataObj.mbUnloadWhseId = null;
    dataObj.mbUnloadBin = null;
    dataObj.mbFMSample = null;
    dataObj.mbCleanSample = null;
    dataObj.mbSMKGR = null;
    dataObj.mbSSGR = null;
    dataObj.mbOKGR = null;
    dataObj.mbDamSplitGr = null;
    dataObj.mbDamGr = null;
    dataObj.mbHullGr = null;
    dataObj.mbFrezDamGr = null;
    dataObj.mbCnclRmdGr = null;
    dataObj.mbCrkBrkGr = null;
    dataObj.mbDcolGr = null;
    dataObj.mbHulBritGr = null;
    dataObj.mbJumboGr = null;
    dataObj.mbELKGr = null;
    dataObj.mbFMGr = null;
    dataObj.mbLKSGR = null;
    dataObj.mbFanGR = null;
    dataObj.mbSMKPct = null;
    dataObj.mbSMK1Pct = null;
    dataObj.mbSMK2Pct = null;
    dataObj.mbSMK3Pct = null;
    dataObj.mbSMK4Pct = null;
    dataObj.mbSSPct = null;
    dataObj.mbOKPct = null;
    dataObj.mbDamPct = null;
    dataObj.mbTKCPct = null;
    dataObj.mbHullPct = null;
    dataObj.mbFrezDamPct = null;
    dataObj.mbCnclRmdPct = null;
    dataObj.mbCrBrPct = null;
    dataObj.mbDcolPct = null;
    dataObj.mbHulBritPct = null;
    dataObj.mbJumboPct = null;
    dataObj.mbELKPct = null;
    dataObj.mbFMPct = null;
    dataObj.mbLSKPct = null;
    dataObj.mbMoistPct = null;
    dataObj.mbFanPct = null;
    dataObj.mbOtherFM = null;
    dataObj.mbSMK1Gauge = null;
    dataObj.mbSMK2Gauge = null;
    dataObj.mbSMK3Gauge = null;
    dataObj.mbSMK4Gauge = null;
    dataObj.mbTareWt = null;
    dataObj.mbVehicleWT = null;
    dataObj.mbGrossWt = null;
    dataObj.mbFMWt = null;
    dataObj.mbExMoistWt = null;
    dataObj.mbNetWt = null;
    dataObj.mbLSKWt = null;
    dataObj.mbKernValue = null;
    dataObj.mbElkPrem = null;
    dataObj.mbDamDiscount = null;
    dataObj.mbExFmDiscount = null;
    dataObj.mbExSSDiscount = null;
    dataObj.mbFlavusDiscount = null;
    dataObj.mbNetLesLSKVal = null;
    dataObj.mbNetPrice = null;
    dataObj.mbBasisGradeAmt = null;
    dataObj.mbScaleTicket = [] //mbScaleTicket(5)
    dataObj.mdblSupportPerTon = null;
    dataObj.mlngSpotPounds = null;
    dataObj.mlngBasisPrice = null;
    dataObj.mlngSpotPounds_storage = null;
    dataObj.mdblSpotPrice = null;

    //'2005-005
    dataObj.mdblunavailablePrice = null;
    dataObj.mdblunavailablePounds = null;

    //'2004-013
    dataObj.mbTicket_NUMBERS_CURE_CLEAN = null;
    dataObj.mbTicket_CURING_WT_IND = null;
    dataObj.mbTicket_CLEANING_WT_IND = null;
    dataObj.mbTicket_MOISTURE = null;
    dataObj.mbTicket_CLEAN_WT_OTHER = null;
    dataObj.mbTicket_CURE_WT_OTHER = null;
    dataObj.mbTicket_CURE_SCHEDULE = null;
    dataObj.mbTicket_CLEAN_SCHEDULE = null;
    dataObj.mbTicket_TOTAL_CURING_COST = null;
    dataObj.mbTicket_TOTAL_CLEANING_COST = null;
    dataObj.strCureCleanCosts = null;
    dataObj.mdblTotalCureCosts = null;
    dataObj.mdblTotalCleanCosts = null;
    dataObj.sPDcodes_cure_clean_costs = null;
    dataObj.mbCancelled = null;//Public mbCancelled 

    dataObj.z_marr = null;
    dataObj.y_marr = null;

    //'2004-147
    dataObj.mbOptPayStoreInd = null;
    dataObj.mbOptPayPurchInd = null;
    dataObj.bSegValueCalculated = null;

    //real ones
    dataObj.BuyPtId = null;
    dataObj.lblLastChange = null;
    dataObj.marrVendorInfo = []
    dataObj.marrVendorInfo0 = []
    dataObj.marrVendorInfo1 = []
    dataObj.marrVendorInfo2 = []
    dataObj.marrVendorInfo3 = []
    dataObj.marrVendorInfo4 = []
    dataObj.marrVendorInfo5 = []
    dataObj.marrVendorInfo6 = []
    dataObj.marrVendorInfo7 = []
    dataObj.marrVendorInfo8 = []
    dataObj.marrVendorInfo9 = []
    dataObj.marrVendorInfo10 = []
    dataObj.marrVendorInfo11 = []
    dataObj.marrVendorInfo12 = []
    dataObj.marrVendorInfo13 = []
    dataObj.marrVendorInfo14 = []
    dataObj.marrVendorInfo15 = []
    dataObj.marrVendorInfo16 = []
    dataObj.marrVendorInfo17 = []


    dataObj.lstNewContractLine = []
    dataObj.lblPremDeduct = null;
    dataObj.lblCMAVendor = null;
    dataObj.lblCMAAllowUnapplyLbs = null;
    dataObj.lblStoresContract = null; //'infra call 559378
    dataObj.lblPurchContactSpotPrice = null;
    dataObj.lblPurchPercentOfSupport = null;
    dataObj.lblPurchValueOfSeg = null;
    dataObj.lblStoresValueOfSeg = null;
    dataObj.lblStoresPercentOfSupport = null;
    dataObj.lblFV95Num = null;
    dataObj.lstNewPremDeduct = [];

    dataObj.lblFarmerStockCost = null;

    dataObj.lstOldContractLine = []

    //for ok button click
    dataObj.sApplContractList = null;
    dataObj.sTentContractList = null;
    dataObj.sSeedContractList = null;

    dataObj.sAppliedList = null;
    dataObj.sTentList = null;
    dataObj.sSeedList = null;

    dataObj.sPVendorList = null;
    dataObj.sSVendorList = null;
    dataObj.sPrevContract = null;

    dataObj.lApplLbs = null;
    dataObj.lTentLbs = null;
    dataObj.lSeedLbs = null;

    dataObj.dblGrossWtP = null;
    dataObj.dblGrossWtS = null;
    dataObj.dblLSKWtP = null;
    dataObj.dblLSKWtS = null;
    dataObj.dblNetWtP = null;
    dataObj.dblNetWtS = null;

    //const [lblPurchValueOfLoad, SetlblPurchValueOfLoad] = useState()
    //const [lblStoresValueOfLoad, SetlblStoresValueOfLoad] = useState()
    dataObj.lblPurchValueOfLoad = 0;
    dataObj.lblStoresValueOfLoad = 0;
    setData(thisObj, "PageData", dataObj);
  }
  //array for vendorsplit
  let cboWRNumArray = []
  let cboPSArray = []
  let lblVendorNumber = []
  let lblVendor = []
  let txtRemitToArray = []
  let txtSharePctArray = []
  let txtPoundsArray = []
  let txtValueArray = []
  let lblCMAParticipant = []
  let XtxtRemitToArray = []


  //varibles declaration done


  //formload
  async function formLoad() {
    bFillPSList()
    setValue(thisObj, "lblAddedByValue", '')
    setValue(thisObj, "lblChangedByValue", '')
    let obj = getData(thisObj, "PageData");
    setData(thisObj, "cboWRNumArray", [])
    setData(thisObj, "cboPSArray", [])
    setData(thisObj, "lblVendorNumber", [])
    setData(thisObj, "lblVendor", [])
    setData(thisObj, "txtRemitToArray", [])
    setData(thisObj, "txtSharePctArray", [])
    setData(thisObj, "txtPoundsArray", [])
    setData(thisObj, "txtValueArray", [])
    setData(thisObj, "lblCMAParticipant", [])
    setData(thisObj, "XtxtRemitToArray", [])
    obj.lblLastChange = ''

    obj.mbStateId = ""
    obj.mbCountyId = ""
    obj.mbFarmId = ""
    obj.mbFarmSuffix = ""   //Project Suffix
    obj.mbPnutTypeID = ""
    obj.mbPnutVarietyId = ""
    obj.mbBuyPtId = ""
    obj.mbAreaId = ""
    obj.mbInspStatus = ""
    obj.mbInspDateTime = ""
    obj.mbSeedGen = ""
    obj.mbProbePattern = ""
    obj.mbSegment = ""
    obj.mbEdibleOil = ""
    obj.mbOleicInd = ""
    obj.mbCornInd = ""
    obj.mbFlavusInd = ""
    obj.mbVicamInd = ""
    obj.mbVicamPPB = ""
    obj.mbOBLBuyId = ""
    obj.mbOBLWhseId = ""
    obj.mbOBLBin = ""
    obj.mbUnloadWhseId = ""
    obj.mbUnloadBin = ""
    obj.mbFMSample = ""
    obj.mbCleanSample = ""
    obj.mbSMKGR = ""
    obj.mbSSGR = ""
    obj.mbOKGR = ""
    obj.mbDamSplitGr = ""
    obj.mbDamGr = ""
    obj.mbHullGr = ""
    obj.mbFrezDamGr = ""
    obj.mbCnclRmdGr = ""
    obj.mbCrkBrkGr = ""
    obj.mbDcolGr = ""
    obj.mbHulBritGr = ""
    obj.mbJumboGr = ""
    obj.mbELKGr = ""
    obj.mbFMGr = ""
    obj.mbLKSGR = ""
    obj.mbFanGR = ""
    obj.mbSMKPct = ""
    obj.mbSMK1Pct = ""
    obj.mbSMK2Pct = ""
    obj.mbSMK3Pct = ""
    obj.mbSMK4Pct = ""
    obj.mbSSPct = ""
    obj.mbOKPct = ""
    obj.mbDamPct = ""
    obj.mbTKCPct = ""
    obj.mbHullPct = ""
    obj.mbFrezDamPct = ""
    obj.mbCnclRmdPct = ""
    obj.mbCrBrPct = ""
    obj.mbDcolPct = ""
    obj.mbHulBritPct = ""
    obj.mbJumboPct = ""
    obj.mbELKPct = ""
    obj.mbFMPct = ""
    obj.mbLSKPct = ""
    obj.mbMoistPct = ""
    obj.mbFanPct = ""
    obj.mbOtherFM = ""
    obj.mbSMK1Gauge = ""
    obj.mbSMK2Gauge = ""
    obj.mbSMK3Gauge = ""
    obj.mbSMK4Gauge = ""
    obj.mbTareWt = ""
    obj.mbVehicleWT = ""
    obj.mbGrossWt = ""
    obj.mbFMWt = ""
    obj.mbExMoistWt = ""
    obj.mbNetWt = ""
    obj.mbLSKWt = ""
    obj.mbKernValue = ""
    obj.mbElkPrem = ""
    obj.mbDamDiscount = ""
    obj.mbExFmDiscount = ""
    obj.mbExSSDiscount = ""
    obj.mbFlavusDiscount = ""
    obj.mbNetLesLSKVal = ""
    obj.mbNetPrice = ""
    obj.mbBasisGradeAmt = ""
    obj.mbScaleTicket = []  //need to change after understanding
    obj.mdblSupportPerTon = 0

    ClearVendorInfo()

    obj.mlngSpotPounds = 0
    obj.mlngBasisPrice = 0
    obj.mdblSpotPrice = 0

    EnableDisableControls()
    ShowHidePanels()
    disable(thisObj, "txtarInspectorRemarks")
    //hide(thisObj, "txtValPerPoundInclSLK")
    setData(thisObj, "PageData", obj);
  }

  //functions inside formLoad
  async function EnableDisableControls() { //need optimization
    let obj = getData(thisObj, "PageData");
    lblVendorNumber = getData(thisObj, "lblVendorNumber")
    //given if-else condition is getting overwritten in below few line hence not sure why it is needed
    // if (thisObj.values.chkboxPurchaseAll || thisObj.values.chkboxStoreAll) {
    //   for (let i = 0; i < 20; i++) {
    //     thisObj.setState(current => {
    //       return {
    //         ...current,
    //         ["ddPS" + (i)]: {
    //           ...state["ddPS" + (i)],
    //           Enabled: true
    //         }
    //       }
    //     })
    //   }
    // }
    // else {
    //   for (let i = 0; i < 20; i++) {
    //     if (lblVendorNumber !== null) {
    //       if (lblVendorNumber[i] == "") {
    //         thisObj.setState(current => {
    //           return {
    //             ...current,
    //             ["ddPS" + (i)]: {
    //               ...state["ddPS" + (i)],
    //               Enabled: false
    //             }
    //           }
    //         })
    //       }
    //     }
    //     else {
    //       thisObj.setState(current => {
    //         return {
    //           ...current,
    //           ["ddPS" + (i)]: {
    //             ...state["ddPS" + (i)],
    //             Enabled: true
    //           }
    //         }
    //       })
    //     }
    //   }
    // }

    enable(thisObj, "btnCntrctAppPremDeduct")

    //over-writting lines
    for (let i = 0; i < 20; i++) {
      enable(thisObj, ["ddPS" + (i)])
    }
    enable(thisObj, "chkboxPurchaseAll")
    show(thisObj, "chkboxPurchaseAll")
    enable(thisObj, "chkboxStoreAll")
    show(thisObj, "chkboxStoreAll")

    if (obj.mbInspStatus !== "I") {
      disable(thisObj, "btnCntrctAppPremDeduct")
      for (let i = 0; i < 20; i++) {
        disable(thisObj, ["ddPS" + (i)])
      }
      disable(thisObj, "chkboxPurchaseAll")
      hide(thisObj, "chkboxPurchaseAll")
      disable(thisObj, "chkboxStoreAll")
      hide(thisObj, "chkboxStoreAll")
    }
    let txt1007 = getValue(thisObj, "txt1007")
    if (txt1007 == "" && obj.txt1007afterChange == undefined) {  //if button is enable when it need to be disable remove this obj.txt1007afterChange from here
      disable(thisObj, "btnCntrctAppPremDeduct")
    }

    let lblfreefv95 = getData(thisObj, "lblfreefv95")
    if (lblfreefv95 == "Y") {
      for (let i = 0; i < 20; i++) {
        enable(thisObj, ["ddPS" + (i)])
        // thisObj.setState(current => {
        //   return {
        //     ...current,
        //     ["ddPS" + (i)]: {
        //       ...state["ddPS" + (i)],
        //       Enabled: true
        //     }
        //   }
        // })
      }
      //cboPS(nControlIndex).BackColor = &H80000005
      disable(thisObj, "chkboxPurchaseAll")
      disable(thisObj, "chkboxStoreAll")
    }

    let buy_pt_id = getValue(thisObj, "txtBuyingPoint") == undefined ? null : thisObj.values.txtBuyingPoint.split(' ')[0].trim()
    let insp_num = thisObj.values.txtSC95 == undefined ? null : getValue(thisObj, "txtSC95")

    let response
    if (buy_pt_id !== null && insp_num !== null) {
      response = await SettlementService.RetrieveCheckWareHouserInIvsDetails(buy_pt_id, insp_num)
    }
    else {
      response = []
    }

    for (let i = 0; i < response.length; i++) {
      if (lblVendorNumber[i] == response[i]) {
        disable(thisObj, ["ddPS" + (i)])
      }
    }
    setData(thisObj, "PageData", obj);
  }

  function ShowHidePanels() {
    let txtSC95val = getValue(thisObj, "txtSC95")
    let temp_length = 0
    if (txtSC95val == undefined) {
      temp_length = 7
    } else {
      temp_length = txtSC95val.length
    }
    if (txtSC95val !== "" && temp_length == 7 && !getData(thisObj, "95ValChange")) {
      show(thisObj, "grpbxPrchsStrgWtCert")
      show(thisObj, "grpbxVendorSplits")
      show(thisObj, "grpbxInspectorRemarks")
      show(thisObj, "grpbxValueCalculation")
    }
    else {
      hide(thisObj, "grpbxPrchsStrgWtCert")
      hide(thisObj, "grpbxVendorSplits")
      hide(thisObj, "grpbxInspectorRemarks")
      hide(thisObj, "grpbxValueCalculation")
    }
    EnableDisableControls()

    lblVendorNumber = getData(thisObj, "lblVendorNumber")
    let lengthCheck = lblVendorNumber == null ? [] : lblVendorNumber
    let hideArray = []
    let showArray = []

    if (lengthCheck.length <= 4) {
      for (let i = 4; i < 20; i++) {
        hideArray.push(["grpbxVendorSplitRow" + (i)])
      }
    } else {
      for (let i = 0; i < lblVendorNumber.length; i++) {
        showArray.push(["grpbxVendorSplitRow" + (i)])
      }
      for (let i = lblVendorNumber.length; i < 20; i++) {
        hideArray.push(["grpbxVendorSplitRow" + (i)])
      }
    }
    showWidgets(thisObj, showArray)
    hideWidgets(thisObj, hideArray)
  }

  async function bFillPSList() {
    let js = []
    js.push({ key: '', description: 'Select' })
    js.push({ key: 'P', description: 'P' })
    js.push({ key: 'S', description: 'S' })

    for (let i = 0; i < 20; i++) {
      thisObj.setState(current => {
        return {
          ...current,
          ["ddPS" + (i)]: {
            ...current["ddPS" + (i)],
            valueList: js
          }
        }
      })
      //setValue(thisObj, ["ddPS" + (i)], js[0].key)
    }
  }

  function bfillCboWRNum(i, array) {
    let js = []
    let js1 = []
    js.push({ key: '', description: 'Select' })
    if (i !== undefined) {
      if (array.length > 0) {
        for (let k = 0; k < array.length; k++) {
          if (!js1.includes(array[k])) {
            js.push({ key: array[k], description: array[k] })
            js1 = js
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ["ddWRNum" + (i)]: {
            ...current["ddWRNum" + (i)],
            valueList: js
          }
        }
      })
    }
    else {
      for (let j = 0; j < 20; j++) {
        thisObj.setState(current => {
          return {
            ...current,
            ["ddWRNum" + (j)]: {
              ...current["ddWRNum" + (j)],
              valueList: js
            }
          }
        })
      }
    }
  }

  function ClearVendorInfo() {
    let dataObj = getData(thisObj, "PageData")
    dataObj.marrVendorInfo = []
    dataObj.marrVendorInfo0 = []
    dataObj.marrVendorInfo1 = []
    dataObj.marrVendorInfo2 = []
    dataObj.marrVendorInfo3 = []
    dataObj.marrVendorInfo4 = []
    dataObj.marrVendorInfo5 = []
    dataObj.marrVendorInfo6 = []
    dataObj.marrVendorInfo7 = []
    dataObj.marrVendorInfo8 = []
    dataObj.marrVendorInfo9 = []
    dataObj.marrVendorInfo10 = []
    dataObj.marrVendorInfo11 = []
    dataObj.marrVendorInfo12 = []
    dataObj.marrVendorInfo13 = []
    dataObj.marrVendorInfo14 = []
    dataObj.marrVendorInfo15 = []
    dataObj.marrVendorInfo16 = []
    dataObj.marrVendorInfo17 = []
    let obj1 = ''
    for (let j = 0; j < 20; j++) {
      dataObj.marrVendorInfo0.push(obj1)
      dataObj.marrVendorInfo1.push(obj1)
      dataObj.marrVendorInfo2.push(obj1)
      dataObj.marrVendorInfo3.push(obj1)
      dataObj.marrVendorInfo4.push(obj1)
      dataObj.marrVendorInfo5.push(obj1)
      dataObj.marrVendorInfo6.push(obj1)
      dataObj.marrVendorInfo7.push(obj1)
      dataObj.marrVendorInfo8.push(obj1)
      dataObj.marrVendorInfo9.push(obj1)
      dataObj.marrVendorInfo10.push(obj1)
      dataObj.marrVendorInfo11.push(obj1)
      dataObj.marrVendorInfo12.push(obj1)
      dataObj.marrVendorInfo13.push(obj1)
      dataObj.marrVendorInfo14.push(obj1)
      dataObj.marrVendorInfo15.push(obj1)
      dataObj.marrVendorInfo16.push(obj1)
      dataObj.marrVendorInfo17.push(obj1)
    }

    setData(thisObj, "PageData", dataObj)
  }

  async function ClearOptionPayment() {
    let obj = getData(thisObj, "PageData")
    for (let i = 0; i < 20; i++) {
      obj.marrVendorInfo17[i] = ""
    }
    setData(thisObj, "PageData", obj)
  }

  //remaining functions 
  function CalculateWeightAndBasisGradeAmts() {
    let obj = getData(thisObj, "PageData");
    obj.lblPurchNetWeight = 0
    obj.lblPurchValueOfLoad = 0
    obj.lblStoresNetWeight = 0
    obj.lblStoresValueOfLoad = 0
    txtValueArray = getData(thisObj, "txtValueArray")
    txtPoundsArray = getData(thisObj, "txtPoundsArray")

    for (let i = 0; i < lblVendorNumber.length; i++) {
      if (getValue(thisObj, ["ddPS" + (i)]) == 'P' && isNumeric(txtPoundsArray[i])) {
        obj.lblPurchNetWeight = obj.lblPurchNetWeight + Number(txtPoundsArray[i])
        obj.lblPurchValueOfLoad = obj.lblPurchValueOfLoad + Number(txtValueArray[i])
      }
      if (getValue(thisObj, ["ddPS" + (i)]) == 'S' && isNumeric(txtPoundsArray[i])) {
        obj.lblStoresNetWeight = obj.lblStoresNetWeight + Number(txtPoundsArray[i])
        obj.lblStoresValueOfLoad = obj.lblStoresValueOfLoad + Number(txtValueArray[i])
      }
    }
    //setLabel(thisObj, "btnCntrctAppPremDeduct", "Contract Applications/ Premium Deductions")
    document.getElementById("btnCntrctAppPremDeduct").innerText = "Contract Applications/ Premium Deductions"
    obj.lstNewContractLine = []
    obj.lblPremDeduct = ''

    setData(thisObj, "txtValueArray", txtValueArray)
    setData(thisObj, "txtPoundsArray", txtPoundsArray)

    setValue(thisObj, "txtNetWeightPrchs", obj.lblPurchNetWeight == 0 ? "0.00" : obj.lblPurchNetWeight.toFixed(2))
    setValue(thisObj, "txtTotalLoadValuePrchs", obj.lblPurchValueOfLoad == 0 ? "0.00" : obj.lblPurchValueOfLoad.toFixed(2))
    setValue(thisObj, "txtNetWeightStrg", obj.lblStoresNetWeight == 0 ? "0.00" : obj.lblStoresNetWeight.toFixed(2))
    setValue(thisObj, "txtTotalLoadValueStrg", obj.lblStoresValueOfLoad == 0 ? "0.00" : obj.lblStoresValueOfLoad.toFixed(2))
    setData(thisObj, "PageData", obj);
  }

  function bFormValid() {
    lblVendorNumber = getData(thisObj, "lblVendorNumber") == null ? [] : getData(thisObj, "lblVendorNumber")
    for (let i = 0; i < lblVendorNumber.length; i++) {
      if (lblVendorNumber[i] !== "" || lblVendorNumber.length !== 0) {
        let drpvalue = getValue(thisObj, ["ddPS" + (i)])
        if (drpvalue !== 'P' && drpvalue !== 'S') {
          showMessage(thisObj, "Please select purchase or storage option for each vendor.")
          return false;
        }
      }
    }
    return true;
  }

  const CalculateValues2 = async (vAreaId, vPnutTypeId, vWeightIncludingVehicle, vVehicleWeight, vMoisturePct, vCrBrPct, vDcolShelPct, vFancyPct, vForeignMaterialPct, vLSKPct, vSMK, vSSPct, vOKPct, vELKPct, vDamagePct, vVicam) => {
    let obj = getData(thisObj, "PageData");
    let bDisplayTons;
    let areaControlList = await SettlementService.RetrieveAreaControlDetails(obj.mbAreaId);
    if (areaControlList.length > 0) {
      if (areaControlList[0].display_lbs_ton == 'P') {
        bDisplayTons = false;
      } else {
        bDisplayTons = true;
      }
    }

    let tempObj = {}
    let calculationList = await SettlementService.RetrieveValueCalculationList(vAreaId, vPnutTypeId, vCrBrPct, vDcolShelPct, vFancyPct, vDamagePct, vSSPct, vVicam, vForeignMaterialPct, vSMK, vSSPct, vOKPct, vELKPct, vLSKPct, vMoisturePct, vWeightIncludingVehicle, vVehicleWeight, vForeignMaterialPct);
    if (calculationList.length > 0) {
      tempObj['txtWtIncludingVehicle'] = calculationList[0].weight_including_vehicle.toString();
      tempObj['txtWtOfVehicle'] = calculationList[0].weight_of_vehicle.toString();
      tempObj['txtGrossWeight'] = calculationList[0].gross_weight.toString();
      tempObj['txtForeignMaterial'] = calculationList[0].foreign_material_weight.toString();
      tempObj['txtWtlessFM'] = calculationList[0].weight_less_fm.toString();
      tempObj['txtExcessMoisture'] = calculationList[0].excess_moisture_weight.toString();
      tempObj['txtNetWeight'] = calculationList[0].net_weight.toString();
      tempObj['txtLSKvc'] = calculationList[0].lsk_weight.toString();
      tempObj['txtNetWtExclLSK'] = calculationList[0].net_weight_excluding_lsk.toString();

      if (bDisplayTons) {
        //setLabel(thisObj, 'lblPerTon', '$/Ton');
        document.getElementsByClassName('lblPerTon')[0].innerText = '$/Ton'
        if(document.getElementsByClassName('lblPerTon')[1] != undefined){
          document.getElementsByClassName('lblPerTon')[1].innerText = '$/Ton'
        }
        tempObj['txtKernelValueTon'] = calculationList[0].kernel_value_dollars_per_ton.toFixed(2);
        tempObj['txtELKPremiumTon'] = calculationList[0].elk_premium_dollars_per_ton.toFixed(2);
        tempObj['txtTotalTon'] = calculationList[0].total_dollars_per_ton.toFixed(2);
        tempObj['txtDamageTon'] = calculationList[0].damaged_discount_dollars_per_ton.toFixed(2);
        tempObj['txtAccessFMTon'] = calculationList[0].excess_fm_discount_dollars_per_ton.toFixed(2);
        tempObj['txtExcessSplitsTon'] = calculationList[0].excess_splits_discount_dollars_per_ton.toFixed(2);
        tempObj['txtAFlavusTon'] = calculationList[0].flavus_discount_dollars_per_ton.toFixed(2);
        tempObj['txtTotalDiscountsTon'] = calculationList[0].total_discounts_dollars_per_ton.toFixed(2);
      } else {
        //setLabel(thisObj, 'lblPerTon', 'c/Lb');
        document.getElementsByClassName('lblPerTon')[0].innerText = 'c/Lb'
        if(document.getElementsByClassName('lblPerTon')[1] != undefined){
          document.getElementsByClassName('lblPerTon')[1].innerText = 'c/Lb'
        }
        tempObj['txtKernelValueTon'] = calculationList[0].kernel_value_cents_per_lbs.toFixed(5);
        tempObj['txtELKPremiumTon'] = calculationList[0].elk_premium_cents.toFixed(5);
        tempObj['txtTotalTon'] = calculationList[0].total_cents_per_lbs.toFixed(5);
        tempObj['txtDamageTon'] = calculationList[0].damaged_discount_cents_per_lbs.toFixed(5);
        tempObj['txtAccessFMTon'] = calculationList[0].excess_fm_discount_cents_per_lbs.toFixed(5);
        tempObj['txtExcessSplitsTon'] = calculationList[0].excess_splits_discount_cents_per_lbs.toFixed(5);
        tempObj['txtAFlavusTon'] = calculationList[0].flavus_discount_cents_per_lbs.toFixed(5);
        tempObj['txtTotalDiscountsTon'] = calculationList[0].total_discounts_cents_per_lbs.toFixed(5);
      }
      tempObj['txtNetValPerTonExclSLK'] = calculationList[0].net_value_per_ton_excluding_lsk.toFixed(2);
      tempObj['txtValPerPoundExclSLK'] = calculationList[0].value_per_pound_excluding_lsk.toFixed(5);
      tempObj['txtOI'] = calculationList[0].value_times_weight.toFixed(2);
      //setLabel(thisObj, 'txtH', "H x " + calculationList[0].lsk_per_pounds + ":");
      document.getElementsByClassName('txtH')[0].firstChild.firstChild.innerHTML = "H x " + calculationList[0].lsk_per_pounds + ":";
      if(document.getElementsByClassName('txtH')[1] != undefined){
        document.getElementsByClassName('txtH')[1].firstChild.firstChild.innerHTML = "H x " + calculationList[0].lsk_per_pounds + ":";
      }
      tempObj['txtH'] = calculationList[0].lsk_time_lsk_per_pounds.toFixed(2);
      tempObj['txtTotalLoadVal'] = calculationList[0].total_value_of_load.toFixed(2);
      tempObj['txtG'] = calculationList[0].value_per_pound_including_lsk.toFixed(5);
      obj.mdblSupportPerTon = calculationList[0].price_schedule[0].support_per_ton == undefined ? null : Number(calculationList[0].price_schedule[0].support_per_ton).toFixed(5);

      setFieldValues(thisObj, tempObj, true)
    }
    setData(thisObj, "PageData", obj);
  }

  //new functions
  function replicateString(char, num) {
    return char.repeat(num);
  }

  function isNumeric(value) {
    return value !== '' && !isNaN(parseFloat(value)) && isFinite(value);
  }

  async function CalculateSeg2009() {
    try {
      let obj = getData(thisObj, "PageData");
      let arrContractLine = []
      let nListIndex
      let dblPurchTotalWeights
      let dblPurchOptionPriceTimesWt
      let dblPurchContractPriceTimesWt
      let dblStoreTotalWeights
      let dblStoreOptionTotalWeights = 0
      let dblStorePriceTimesWt
      let dblVendorPurchSeg
      let dblVendorStoreSeg
      let dblLoanPrice
      let dblPercentOfSupport
      let dblVendorOptionPayment = 0
      let dblPurchOptionTotalWeights
      let blnBothContandOption
      let contracttype

      let arrContVend = []
      let arrContVendTotWt = []
      let arrContVendOptTotWt = []
      let arrContVendOptPriceTimesWt = []
      let arrContVendContPriceTimesWt = []
      let nContVendIndex = []
      let nContVendIndexMAX
      let dblContVendTotWt
      let dblContVendOptTotWt
      let dblContVendOptPriceTimesWt
      let dblContVendContPriceTimesWt
      let bVendorWithContract  //boolean

      obj.bSegValueCalculated = false

      dblPurchTotalWeights = 0
      dblStoreTotalWeights = 0
      dblStorePriceTimesWt = 0
      dblLoanPrice = 0
      obj.mlngSpotPounds = 0 //not declared inside this function
      obj.mdblSpotPrice = 0 //not declared inside this function

      dblPurchOptionPriceTimesWt = 0
      dblPurchContractPriceTimesWt = 0
      dblPurchOptionTotalWeights = 0
      obj.mbOptPayPurchInd = false //not declared inside this function
      obj.mbOptPayStoreInd = false //not declared inside this function

      //let cdate = moment(obj.mbInspDateTime).format("MM/DD/YYYY h:mm A")
      let cdate = moment(obj.mbInspDateTime).toISOString()
      let lstrxml = await SettlementService.RetrieveLoanRePaymentControlDetails(obj.mbPnutTypeID, null, cdate) //need to get checked form praveena
      if (lstrxml.length == 0 || lstrxml == undefined) {
        showMessage(thisObj, "No Loan Repayment Rate Data. \n Cannot Calculate Segment Value.")
        return
      }
      obj.lstNewContractLine = getData(thisObj, "lstNewContractLineFromContractApplication2009")
      nContVendIndex = 0
      for (let i = 0; i < obj.lstNewContractLine.length; i++) {
        arrContractLine = obj.lstNewContractLine[i].split("|")
        if (arrContractLine[3] == "T") {
          switch (arrContractLine[5]) {
            case "C":
              nContVendIndex = nContVendIndex + 1
              arrContVend[nContVendIndex] = arrContractLine[8]
              arrContVendTotWt[nContVendIndex] = CLng(arrContractLine[2])
              arrContVendOptTotWt[nContVendIndex] = 0
              arrContVendOptPriceTimesWt[nContVendIndex] = 0
              arrContVendContPriceTimesWt[nContVendIndex] = 0
              dblStoreTotalWeights = dblStoreTotalWeights + Number(arrContractLine[2])
              break;

            case "O":
            case "M":
            case "L":
              nContVendIndex = nContVendIndex + 1
              arrContVend[nContVendIndex] = arrContractLine[8]
              arrContVendTotWt[nContVendIndex] = Number(arrContractLine[2])
              arrContVendOptTotWt[nContVendIndex] = Number(arrContractLine[2])
              arrContVendOptPriceTimesWt[nContVendIndex] = Number(arrContractLine[2]) * (Number(arrContractLine[4]))
              arrContVendContPriceTimesWt[nContVendIndex] = 0
              obj.mbOptPayStoreInd = true
              dblStoreTotalWeights = dblStoreTotalWeights + Number(arrContractLine[2])
              dblStoreOptionTotalWeights = dblStoreOptionTotalWeights + Number(arrContractLine[2])
              dblStorePriceTimesWt = dblStorePriceTimesWt + Number(arrContractLine[2]) * (Number(arrContractLine[4]))
              break;

            default:
              break;
          }

          if (arrContractLine[1] == "SPOT") {
            obj.mdblunavailablePounds = Number(arrContractLine[2])
            obj.mdblunavailablePrice = Number(arrContractLine[4])
          }

        }
        else {
          if (arrContractLine[3] == "A") {
            if (lstrxml[0].ratePerTon == "") {
              showMessage(thisObj, "No Loan Repayment Rate Found. Cannot Calculate Segment Value")
            } else {
              dblLoanPrice = lstrxml[0].ratePerTon
            }

            switch (arrContractLine[5]) {
              case "O":
              case "L":
                if (arrContractLine[1] == "Basis") {
                  nContVendIndex = nContVendIndex + 1
                  arrContVend[nContVendIndex] = arrContractLine[8]
                  arrContVendTotWt[nContVendIndex] = Number(arrContractLine[2])
                  arrContVendOptTotWt[nContVendIndex] = Number(arrContractLine[2])
                  arrContVendOptPriceTimesWt[nContVendIndex] = Number(arrContractLine[2]) * (Number(arrContractLine[4]))
                  arrContVendContPriceTimesWt[nContVendIndex] = (Number(arrContractLine[2]) * dblLoanPrice)
                  obj.mbOptPayPurchInd = true
                  dblPurchTotalWeights = dblPurchTotalWeights + Number(arrContractLine[2])
                  dblPurchOptionTotalWeights = dblPurchOptionTotalWeights + Number(arrContractLine[2])
                  dblPurchOptionPriceTimesWt = dblPurchOptionPriceTimesWt + (Number(arrContractLine[2]) * Number(arrContractLine[4]))
                  dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (Number(arrContractLine[2]) * dblLoanPrice)
                }
                break;

              case "S":
              case "C":
                if (arrContractLine[1] == "Basis") {
                  nContVendIndex = nContVendIndex + 1
                  arrContVend[nContVendIndex] = arrContractLine[8]
                  arrContVendTotWt[nContVendIndex] = Number(arrContractLine[2])
                  arrContVendOptTotWt[nContVendIndex] = 0
                  arrContVendOptPriceTimesWt[nContVendIndex] = 0
                  arrContVendContPriceTimesWt[nContVendIndex] = (Number(arrContractLine[2]) * (Number(arrContractLine[4]) + dblLoanPrice))
                  dblPurchTotalWeights = dblPurchTotalWeights + Number(arrContractLine[2])
                  dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (Number(arrContractLine[2]) * (Number(arrContractLine[4]) + dblLoanPrice))
                }
                else {
                  dblPurchTotalWeights = dblPurchTotalWeights + Number(arrContractLine[2])
                  dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (Number(arrContractLine[2]) * Number(arrContractLine[4]))
                }
                if (arrContractLine[1] == "SPOT") {
                  obj.mlngSpotPounds = Number(arrContractLine[2])
                  obj.mdblSpotPrice = Number(arrContractLine[4])
                }
                break;

              default:
                break;
            }

          }
        }

        if (arrContractLine[1] == "SPOT" && arrContractLine[3] == "T") {
          obj.mlngSpotPounds_storage = Number(arrContractLine[2])
          dblStoreTotalWeights = dblStoreTotalWeights + obj.mdblunavailablePounds
        }
      }

      nContVendIndexMAX = nContVendIndex

      if (dblPurchTotalWeights == 0) {
        obj.lblPurchContactSpotPrice = dblPurchTotalWeights.toFixed(2)
      } else {
        obj.lblPurchContactSpotPrice = (dblPurchContractPriceTimesWt / dblPurchTotalWeights).toFixed(2)
      }

      obj.lblPurchPercentOfSupport = 0
      if (obj.lblPurchValueOfLoad !== 0) {
        obj.lblPurchPercentOfSupport = ((obj.lblPurchContactSpotPrice / obj.mdblSupportPerTon) * 100).toFixed(2)
      }
      obj.lblPurchValueOfSeg = (obj.lblPurchValueOfLoad * (obj.lblPurchPercentOfSupport / 100)).toFixed(2)

      if (dblPurchOptionTotalWeights > 0) {

        dblPercentOfSupport = ((dblPurchOptionPriceTimesWt / dblPurchOptionTotalWeights) / obj.mdblSupportPerTon).toFixed(5)
        obj.lblOptionPayment = (dblPercentOfSupport * (obj.lblPurchValueOfLoad * (dblPurchOptionTotalWeights / dblPurchTotalWeights))).toFixed(2)
        dblPercentOfSupport = Number(dblPercentOfSupport).toFixed(4)
      }
      else {
        obj.lblOptionPayment = "0.00"
        dblPercentOfSupport = 0
      }
      obj.lblFarmerStockCost = (Number(obj.lblPurchValueOfSeg) + Number(obj.lblOptionPayment)).toFixed(2)

      if (dblStoreTotalWeights == 0) {
        obj.lblStoresContract = 0.00
        obj.lblStoresValueOfSeg = 0.00
      } else {
        if (dblStoreOptionTotalWeights > 0) {
          obj.lblStoresContract = (dblStorePriceTimesWt / dblStoreOptionTotalWeights).toFixed(2)
        }
        setValue(thisObj, "txtPctOfSupportStrg", (((obj.lblStoresContract) * 100) / obj.mdblSupportPerTon).toFixed(2))
        obj.lblStoresPercentOfSupport = Number((((obj.lblStoresContract) * 100) / obj.mdblSupportPerTon).toFixed(2))
        setValue(thisObj, "txtValueOfOptionStrg", (obj.lblStoresPercentOfSupport * 0.01 * (obj.lblStoresValueOfLoad * (dblStoreOptionTotalWeights / dblStoreTotalWeights))).toFixed(2))
        obj.lblStoresValueOfSeg = Number((obj.lblStoresPercentOfSupport * 0.01 * (obj.lblStoresValueOfLoad * (dblStoreOptionTotalWeights / dblStoreTotalWeights))).toFixed(2))
      }
      dblVendorPurchSeg = 0
      dblVendorStoreSeg = 0

      ClearOptionPayment()

      for (let i = 0; obj.marrVendorInfo2[i] !== ""; i++) {
        if (obj.marrVendorInfo0[i] !== "") {
          dblContVendTotWt = 0
          dblContVendOptTotWt = 0
          dblContVendOptPriceTimesWt = 0
          dblContVendContPriceTimesWt = 0

          if (obj.marrVendorInfo5[i] == "P") {
            if (obj.lblCMAVendor !== "") {
              obj.marrVendorInfo10[i] = !isNumeric(obj.lblPurchContactSpotPrice) ? 0 : obj.lblPurchContactSpotPrice
              obj.marrVendorInfo11[i] = (((Number(obj.marrVendorInfo10[i])) * 100) / obj.mdblSupportPerTon).toFixed(2)
              obj.marrVendorInfo12[i] = Number(!isNumeric(obj.marrVendorInfo9[i]) ? 0 : obj.marrVendorInfo9[i] * Number((!isNumeric(obj.marrVendorInfo11[i]) ? 0 : obj.marrVendorInfo11[i]) * 0.01)).toFixed(2)
              dblVendorPurchSeg = dblVendorPurchSeg + Number(!isNumeric(obj.marrVendorInfo12[i]) ? 0 : obj.marrVendorInfo12[i])
              bVendorWithContract = false

              for (let j = 1; j <= nContVendIndexMAX + 1; j++) {
                if ((obj.marrVendorInfo0[i] == arrContVend[i]) || (obj.lblCMAVendor.trim() !== "" && arrContVend[i] !== "")) {
                  bVendorWithContract = True
                  dblContVendOptTotWt = dblContVendOptTotWt + arrContVendOptTotWt[j]
                  if (obj.lblCMAVendor.trim() !== "") {
                    dblContVendTotWt = dblContVendTotWt + arrContVendTotWt[j]
                  } else {
                    dblContVendTotWt = obj.marrVendorInfo7[i]
                  }
                }
              }

              if (bVendorWithContract) {
                obj.marrVendorInfo17[i] = (Number((!isNumeric(obj.marrVendorInfo17[i]) ? 0 : obj.marrVendorInfo17[i])) * (dblContVendOptTotWt / dblContVendTotWt) * dblPercentOfSupport).toFixed(2)
              } else {
                obj.marrVendorInfo17[i] = 0
              }
              dblVendorOptionPayment = dblVendorOptionPayment + Number(!isNumeric(obj.marrVendorInfo17[i]) ? 0 : obj.marrVendorInfo17[i])
            }
            else {
              bVendorWithContract = false
              for (let j = 1; j <= nContVendIndexMAX + 1; j++) {
                if ((obj.marrVendorInfo0[i] == arrContVend[j]) || (obj.lblCMAVendor.trim() !== "" && arrContVend[j] !== "")) {
                  bVendorWithContract = true
                  dblContVendOptTotWt = dblContVendOptTotWt + arrContVendOptTotWt[j]
                  dblContVendTotWt = dblContVendTotWt + arrContVendTotWt[j]
                  dblContVendOptPriceTimesWt = dblContVendOptPriceTimesWt + arrContVendOptPriceTimesWt[j]
                  dblContVendContPriceTimesWt = dblContVendContPriceTimesWt + arrContVendContPriceTimesWt[j]
                }
              }

              if (bVendorWithContract && dblContVendOptTotWt > 0) {
                dblPercentOfSupport = ((dblContVendOptPriceTimesWt / dblContVendOptTotWt) / obj.mdblSupportPerTon).toFixed(4)
              }

              if (dblContVendTotWt == 0) {
                obj.marrVendorInfo10[i] = (obj.mlngSpotPounds !== 0 ? obj.mdblSpotPrice : obj.lblPurchContactSpotPrice).toFixed(2)
              } else {
                if (dblContVendTotWt == Number(obj.marrVendorInfo7[i])) {
                  obj.marrVendorInfo10[i] = (dblContVendContPriceTimesWt / dblContVendTotWt).toFixed(2)
                } else {
                  obj.marrVendorInfo10[i] = ((dblContVendContPriceTimesWt + (obj.mdblSpotPrice * (Number(obj.marrVendorInfo7[i]) - dblContVendTotWt))) / Number(obj.marrVendorInfo7[i])).toFixed(2)
                }
              }

              obj.marrVendorInfo11[i] = (Number(obj.marrVendorInfo10[i]) * 100 / obj.mdblSupportPerTon).toFixed(2)
              obj.marrVendorInfo12[i] = (Number(obj.marrVendorInfo9[i]) * Number(obj.marrVendorInfo11[i]) * 0.01).toFixed(2)

              if (Number(obj.marrVendorInfo7[i]) == 0) {
                obj.marrVendorInfo17[i] = "0.00"
              } else {
                obj.marrVendorInfo17[i] = (Number(obj.marrVendorInfo9[i]) * (dblContVendOptTotWt / Number(obj.marrVendorInfo7[i])) * dblPercentOfSupport).toFixed(2)
              }

              dblVendorPurchSeg = dblVendorPurchSeg + Number(obj.marrVendorInfo12[i])
              dblVendorOptionPayment = dblVendorOptionPayment + Number(obj.marrVendorInfo17[i])
            }
          }
          else {
            bVendorWithContract = false
            for (let j = 1; j <= nContVendIndexMAX + 1; j++) {
              if ((obj.marrVendorInfo0[i] == arrContVend[j]) || obj.lblCMAVendor.trim() !== "" && arrContVend[j] !== "") {
                bVendorWithContract = true
                dblContVendOptTotWt = dblContVendOptTotWt + arrContVendOptTotWt[j]
                if (obj.lblCMAVendor.trim() !== "") {
                  dblContVendTotWt = dblContVendTotWt + arrContVendTotWt[j]
                } else {
                  dblContVendTotWt = obj.marrVendorInfo7[i]
                }
                dblContVendOptPriceTimesWt = dblContVendOptPriceTimesWt + arrContVendOptPriceTimesWt[j]
              }
            }
            if (bVendorWithContract && dblContVendOptTotWt > 0) {
              obj.marrVendorInfo10[i] = (dblContVendOptPriceTimesWt / dblContVendOptTotWt).toFixed(2)
              obj.marrVendorInfo11[i] = ((((Number((!isNumeric(obj.marrVendorInfo10[i]) ? 0 : obj.marrVendorInfo10[i]))) * 100) / obj.mdblSupportPerTon)).toFixed(2)
              obj.marrVendorInfo12[i] = (Number(isNumeric(obj.marrVendorInfo9[i]) ? obj.marrVendorInfo9[i] : 0) * (dblContVendOptTotWt / dblContVendTotWt) * Number(obj.marrVendorInfo11[i]) * 0.01).toFixed(2)
              dblVendorStoreSeg = dblVendorStoreSeg + Number((!isNumeric(obj.marrVendorInfo12[i]) ? 0 : obj.marrVendorInfo12[i]))
            } else {
              obj.marrVendorInfo10[i] = 0.00
              obj.marrVendorInfo11[i] = 0.00
              obj.marrVendorInfo12[i] = 0.00
            }
          }
        }

      }

      for (let i = 0; (obj.marrVendorInfo2[i] !== "" && Number(dblVendorPurchSeg).toFixed(2) !== Number(obj.lblPurchValueOfSeg).toFixed(2)); i++) {
        if (obj.marrVendorInfo5[i] == "P") {
          if (dblVendorPurchSeg.toFixed(2) < Number(obj.lblPurchValueOfSeg).toFixed(2)) {
            obj.marrVendorInfo12[i] = Number(!isNumeric(obj.marrVendorInfo12[i]) ? 0 : obj.marrVendorInfo12[i]) + 0.01
            dblVendorPurchSeg = dblVendorPurchSeg + 0.01
          }
          if (dblVendorPurchSeg.toFixed(2) > Number(obj.lblPurchValueOfSeg).toFixed(2)) {
            obj.marrVendorInfo12[i] = Number(!isNumeric(obj.marrVendorInfo12[i]) ? 0 : obj.marrVendorInfo12[i]) - 0.01
            dblVendorPurchSeg = dblVendorPurchSeg - 0.01
          }
        }
      }

      for (let i = 0; (obj.marrVendorInfo2[i] !== "" && Number(dblVendorStoreSeg).toFixed(2) !== Number(obj.lblStoresValueOfSeg).toFixed(2)); i++) {
        if (obj.marrVendorInfo5[i] == "S") {
          if (dblVendorStoreSeg.toFixed(2) < Number(obj.lblStoresValueOfSeg).toFixed(2)) {
            obj.marrVendorInfo12[i] = Number(!isNumeric(obj.marrVendorInfo12[i]) ? 0 : obj.marrVendorInfo12[i]) + 0.01
            dblVendorStoreSeg = dblVendorStoreSeg + 0.01
          }
          if (dblVendorStoreSeg.toFixed(2) > Number(obj.lblStoresValueOfSeg).toFixed(2)) {
            obj.marrVendorInfo12[i] = Number(!isNumeric(obj.marrVendorInfo12[i]) ? 0 : obj.marrVendorInfo12[i]) - 0.01
            dblVendorStoreSeg = dblVendorStoreSeg - 0.01
          }
        }
      }

      for (let i = 0; (obj.marrVendorInfo2[i] !== "" && Number(dblVendorOptionPayment).toFixed(2) !== Number(obj.lblOptionPayment).toFixed(2)); i++) {
        if (obj.marrVendorInfo5[i] == "P") {
          if (dblVendorOptionPayment.toFixed(2) < Number(obj.lblOptionPayment).toFixed(2)) {
            obj.marrVendorInfo17[i] = Number(!isNumeric(obj.marrVendorInfo17[i]) ? 0 : obj.marrVendorInfo17[i]) + 0.01
            dblVendorOptionPayment = dblVendorOptionPayment + 0.01
          }
          if (dblVendorOptionPayment.toFixed(2) > Number(obj.lblOptionPayment).toFixed(2)) {
            obj.marrVendorInfo17[i] = Number(!isNumeric(obj.marrVendorInfo17[i]) ? 0 : obj.marrVendorInfo17[i]) - 0.01
            dblVendorOptionPayment = dblVendorOptionPayment - 0.01
          }
        }
      }

      obj.bSegValueCalculated = true

      let tempObj = {}
      //Purchase column
      tempObj["txtNetWeightPrchs"] = obj.lblPurchNetWeight == undefined ? null : obj.lblPurchNetWeight.toString()
      tempObj["txtTotalLoadValuePrchs"] = obj.lblPurchValueOfLoad == undefined ? null : obj.lblPurchValueOfLoad.toString()
      tempObj["txtContractSpotPricePrchs"] = obj.lblPurchContactSpotPrice == undefined ? null : obj.lblPurchContactSpotPrice.toString()
      tempObj["txtPctOfSupportPrchs"] = obj.lblPurchPercentOfSupport == undefined ? null : obj.lblPurchPercentOfSupport.toString()
      tempObj["txtFarmerStockCostPrchs"] = obj.lblFarmerStockCost == undefined ? null : obj.lblFarmerStockCost.toString()
      tempObj["txtValueOfSegPrchs"] = obj.lblPurchValueOfSeg == undefined ? null : obj.lblPurchValueOfSeg.toString()
      tempObj["txtOptionPayment"] = obj.lblOptionPayment == undefined ? null : obj.lblOptionPayment.toString()
      // 3 remaining values only binds on lookup button

      //Storage column
      tempObj["txtNetWeightStrg"] = obj.lblStoresNetWeight == undefined ? null : obj.lblStoresNetWeight.toString()
      tempObj["txtTotalLoadValueStrg"] = obj.lblStoresValueOfLoad == undefined ? null : obj.lblStoresValueOfLoad.toFixed(2)
      tempObj["txtOptionPriceStrg"] = obj.lblStoresContract == undefined || obj.lblStoresContract == null ? null : obj.lblStoresContract.toString() //
      tempObj["txtPctOfSupportStrg"] = obj.lblStoresPercentOfSupport == undefined || obj.lblStoresPercentOfSupport == null ? null : obj.lblStoresPercentOfSupport.toString()//
      tempObj["txtValueOfOptionStrg"] = obj.lblStoresValueOfSeg == undefined || obj.lblStoresValueOfSeg == null ? null : obj.lblStoresValueOfSeg.toString()
      tempObj["txtSeedPremiumStrg"] = obj.lblStoresSeedPremium == undefined ? null : obj.lblStoresSeedPremium.toString()
      tempObj["txtDeductionsStrg"] = obj.lblStoresDeductions == undefined ? null : obj.lblStoresDeductions.toString()
      tempObj["txtProceedsStrg"] = obj.lblStorProceeds == undefined ? null : obj.lblStorProceeds.toString()

      setFieldValues(thisObj, tempObj, true)
      setData(thisObj, "PageData", obj);
    }
    catch (err) {
      showMessage(thisObj, "error on calculateSeg2009")
    }
  }
//PRB0054642_INC6116227_1007s saving incorrect premium deductions with more than 4 vendor splits.
  function dataUpdateAfterPremiumDeductions() {
    let AllValues = getData(thisObj, "PremiumDeductionScreenData")
    let stringData = AllValues.lblPremDeduct.split("&")
    let obj = getData(thisObj, "PageData")
    let pd_split_vendor = []
    let pd_split_remit = []
    let pd_code = []
    let pd_split_share_pct = []
    let pd_amount = []
    let pd_pounds = []
    let payee_vendor = []
    let payee_remit = []
    let pd_rate_type = []
    let pd_ps = []
    let premium_amt = []
    let deduct_amt = []
    let proceeds_amt = []
    let prem_deduct_count
    let separatedData = {}
  
    let tempObj = {}
    tempObj["txtDeductionsPrchs"] = AllValues.lblPurchDeductions.toFixed(2)
    tempObj["txtPremiumsPrchs"] = AllValues.lblPurchPremiums.toFixed(2)
    tempObj["txtProceedsPrchs"] = AllValues.lblPurchProceeds.toFixed(2)
    tempObj["txtProceedsStrg"] = AllValues.lblStorProceeds.toFixed(2)
    tempObj["txtDeductionsStrg"] = AllValues.lblStoresDeductions.toFixed(2)
    tempObj["txtSeedPremiumStrg"] = AllValues.lblStoresSeedPremium.toFixed(2)
    setFieldValues(thisObj, tempObj, true)
  
    setData(thisObj, "1007_DatafromPremiumDeduction", null)  
    let vendorCodeToIndex = {}
    for (let i = 0; i < obj.marrVendorInfo0.length; i++) {
      let vendorCode = obj.marrVendorInfo0[i]
      vendorCodeToIndex[vendorCode] = i
    }
    for (let i = 0; i < stringData.length; i++) {
      let [key, value] = stringData[i].split('=')
      if (key.startsWith('pd_split_vendor')) {
        pd_split_vendor.push(value)
      }
      if (key.startsWith('pd_split_remit')) {
        pd_split_remit.push(value)
      }
      if (key.startsWith('pd_code')) {
        pd_code.push(value);
      }
      if (key.startsWith('pd_split_share_pct')) {
        pd_split_share_pct.push(value);
      }
      if (key.startsWith('pd_amount')) {
        pd_amount.push(value)
      }
      if (key.startsWith('pd_pounds')) {
        pd_pounds.push(value)
      }
      if (key.startsWith('payee_vendor')) {
        payee_vendor.push("")
      }
      if (key.startsWith('payee_remit')) {
        payee_remit.push("")
      }
      if (key.startsWith('pd_rate_type')) {
        pd_rate_type.push(value.trim())
      }
      if (key.startsWith('pd_ps')) {
        pd_ps.push(value.trim())
      }
      if (key.startsWith('prem_deduct_count')) {
        prem_deduct_count = value
      }
      if (key.startsWith('premium_amt')) {
        premium_amt.push(value.trim())
      }
      if (key.startsWith('deduct_amt')) {
        deduct_amt.push(value.trim())
      }
      if (key.startsWith('proceeds_amt')) {
        proceeds_amt.push(value.trim())
      }
    }
      for (let i = 0; i < premium_amt.length; i++) {
      let vendorCode = obj.marrVendorInfo0[i]
      let vendorIndex = vendorCodeToIndex[vendorCode]  
      if (vendorIndex !== undefined) {
        obj.marrVendorInfo13[vendorIndex] = premium_amt[i] || "0.00"
        obj.marrVendorInfo14[vendorIndex] = deduct_amt[i] || "0.00"
        obj.marrVendorInfo15[vendorIndex] = proceeds_amt[i] || "0.00"
      }
    }
    setData(thisObj, "PageData", obj)
  
    separatedData.pd_split_vendor = pd_split_vendor
    separatedData.pd_split_remit = pd_split_remit
    separatedData.pd_code = pd_code
    separatedData.pd_split_share_pct = pd_split_share_pct
    separatedData.pd_amount = pd_amount
    separatedData.pd_pounds = pd_pounds
    separatedData.payee_vendor = payee_vendor
    separatedData.payee_remit = payee_remit
    separatedData.pd_rate_type = pd_rate_type
    separatedData.pd_ps = pd_ps
    separatedData.prem_deduct_count = prem_deduct_count  
    setData(thisObj, "1007_DatafromPremiumDeduction", separatedData)
  }
  
  const bSaveSettlement = async () => {
    let obj = getData(thisObj, "PageData")
    let buyingPoint = thisObj.values.txtBuyingPoint.split(' ')[0].trim();
    let txtSC95 = getValue(thisObj, 'txtSC95');
    let txt1007Number = getValue(thisObj, "txt1007");
    let lblVendorNumber = getData(thisObj, "lblVendorNumber")

    let Data_from_premiumDeduction = getData(thisObj, "1007_DatafromPremiumDeduction")
  
    let nApplicationCount = 0
    let nTentativeCount = 0
    let nSeedAddenCount = 0
    let arrNewContractApps = []
    let objSave = {

   //"settlement_date": (new Date).toISOString(),
      "settlement_date": (new Date(new Date() - (new Date().getTimezoneOffset() * 60000))).toISOString(),
      "spot_price": Number(obj.mdblSpotPrice),
      "spot_pounds": Number(obj.mlngSpotPounds),
      "overide_farm": '',                           //don't have


  
      "user_id": sessionStorage.getItem('userid'),
      "remarks": getValue(thisObj, "txtarInspectorRemarks"),
      "cur_cln_master": obj.strCureCleanCosts,
      "opt_pay_store_ind": obj.mbOptPayStoreInd ? "Y" : "N",
      "opt_pay_purch_ind": obj.mbOptPayPurchInd ? "Y" : "N",
      "last_date_time": new Date(obj.lblLastChange).toISOString(),
      "cma_remit": getData(thisObj, "1007_cboCMARemit").length > 3 ? getData(thisObj, "1007_cboCMARemit").slice(0, 3) : getData(thisObj, "1007_cboCMARemit"), //need from fv95
      "spot_pounds_s": Number(obj.mlngSpotPounds_storage),
      "pd_unselected_opt": obj.lblPremDeductUnselect,
  
      "pds_master": [],
      "ca_pur_master": [],
      "ca_sto_master": [],
      "sga_master": []
    };
  //PRB0054642_INC6116227_1007s saving incorrect premium deductions with more than 4 vendor splits.
    for (let i = 0; i < lblVendorNumber.length; i++) {
      let vendorCode = obj.marrVendorInfo0[i]; // Vendor code for getting data in loop
      objSave["split_vendor" + (i + 1)] = vendorCode
      objSave["split_remit" + (i + 1)] = obj.marrVendorInfo1[i]
      objSave["purh_stor_ind" + (i + 1)] = obj.marrVendorInfo5[i]
      objSave["gross_wt" + (i + 1)] = Number(obj.marrVendorInfo6[i])
      objSave["net_wt" + (i + 1)] = Number(obj.marrVendorInfo7[i])
      objSave["lsk_wt" + (i + 1)] = Number(obj.marrVendorInfo8[i])
      objSave["basis_grade_amt" + (i + 1)] = Number(obj.marrVendorInfo9[i])
      objSave["value_per_ton" + (i + 1)] = Number(obj.marrVendorInfo10[i])
      objSave["support_pct" + (i + 1)] = Number(obj.marrVendorInfo11[i])
      objSave["value_of_seg" + (i + 1)] = Number(obj.marrVendorInfo17[i] || 0) + Number(obj.marrVendorInfo12[i] || 0)
      objSave["premium_amt" + (i + 1)] = Number(obj.marrVendorInfo13[i] || 0)
      objSave["deduct_amt" + (i + 1)] = Number(obj.marrVendorInfo14[i] || 0)
      objSave["proceeds_amt" + (i + 1)] = Number(obj.marrVendorInfo15[i] || 0)
      objSave["opt_value_of_seg" + (i + 1)] =
        obj.marrVendorInfo17[i] === '' ? null : Number(obj.marrVendorInfo17[i])
    }
  
    if (getValue(thisObj, "chkboxPurchaseAll") || getValue(thisObj, "chkboxStoreAll")) {
      if (getValue(thisObj, "chkboxPurchaseAll")) {
        objSave["puch_stor_ind"] = "P"
      }
      if (getValue(thisObj, "chkboxStoreAll")) {
        objSave["puch_stor_ind"] = "S"
      }
    } 
    else {
            objSave["puch_stor_ind"] = ""
    }
  //PRB0054642_INC6116227_1007s saving incorrect premium deductions with more than 4 vendor splits.
    if ((obj.mlSettlementNum == null ? null : obj.mlSettlementNum.toString()) !== getValue(thisObj, "txt1007")) {
      objSave["settlement_date"] = new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString()
    } else {
      objSave["settlement_date"] = new Date(obj.mdtSettlementDate).toISOString()
    }
  
    // PRB0054642_INC6116227_1007s saving incorrect premium deductions with more than 4 vendor splits.
    for (let i = 0; i < obj.lstNewContractLine.length; i++) {
      arrNewContractApps = obj.lstNewContractLine[i].split("|")
      switch (arrNewContractApps[3].toUpperCase()) {
        case "T":
          if (arrNewContractApps[1].toUpperCase() !== "SPOT" && arrNewContractApps[1].toUpperCase() !== "SEED") {
            nTentativeCount += 1
            let newObj = {}
            newObj.tent_contract_num = arrNewContractApps[0]
            newObj.tent_pricing_line = arrNewContractApps[1].toUpperCase() == "FIRM" ? 1 : 2
            newObj.tent_lbs_adj = arrNewContractApps[2]
            newObj.user_tent_lbs = arrNewContractApps[6] === "" ? 0 : arrNewContractApps[6]
            newObj.tent_seed_adden_cont_num = arrNewContractApps[7]
            objSave["ca_sto_master"].push(newObj)
          }
          break;
  
        case "A":
          if (arrNewContractApps[1].toUpperCase() !== "SPOT" && arrNewContractApps[1].toUpperCase() !== "SEED") {
            nApplicationCount += 1
            let newObj = {}
            newObj.appl_contract_num = arrNewContractApps[0]
            newObj.appl_pricing_line = arrNewContractApps[1].toUpperCase() == "FIRM" ? 1 : 2
            newObj.appl_lbs_adj = arrNewContractApps[2]
            newObj.user_apply_lbs = arrNewContractApps[6] === "" ? 0 : arrNewContractApps[6]
            newObj.appl_seed_adden_cont_num = arrNewContractApps[7]
            newObj.unappl_val_adj = ""
            newObj.settle_dol_value = ""
            objSave["ca_pur_master"].push(newObj)
          }
          break;
  
        case "S":
          if (arrNewContractApps[1].toUpperCase() == "SEED") {
            nSeedAddenCount += 1
            let newObj = {}
            newObj.seed_contract_num = arrNewContractApps[0]
            newObj.seed_lbs_adj = arrNewContractApps[2]
            objSave["sga_master"].push(newObj)
          }
          break;
  
        default:
          break;
      }
    }
  
    for (let i = 1; i <= Data_from_premiumDeduction.prem_deduct_count; i++) {
      let tempObj = {}
      tempObj.pd_split_share_pct = Data_from_premiumDeduction.pd_split_share_pct[i - 1]
      tempObj.pd_code = Data_from_premiumDeduction.pd_code[i - 1]
      tempObj.pd_split_vendor = Data_from_premiumDeduction.pd_split_vendor[i - 1]
      tempObj.pd_split_remit = Data_from_premiumDeduction.pd_split_remit[i - 1]
      tempObj.pd_amount = Data_from_premiumDeduction.pd_amount[i - 1]
      tempObj.pd_pounds = Data_from_premiumDeduction.pd_pounds[i - 1]
      tempObj.pd_payee_vendor = Data_from_premiumDeduction.payee_vendor[i - 1]
      tempObj.pd_payee_remit = Data_from_premiumDeduction.payee_remit[i - 1]
      tempObj.pd_rate_type = Data_from_premiumDeduction.pd_rate_type[i - 1]
      tempObj.pd_ps = Data_from_premiumDeduction.pd_ps[i - 1]
      objSave["pds_master"].push(tempObj)
    }  
    objSave["prem_deduct_count"] = Number(Data_from_premiumDeduction.prem_deduct_count)
    objSave["appl_count"] = nApplicationCount
    objSave["tent_appl_count"] = nTentativeCount
    objSave["seed_adden_count"] = nSeedAddenCount
  
    setData(thisObj, "pbForceClose", false)
  
    let response = await SettlementService.UpdateSettlementInspection(buyingPoint, txtSC95, txt1007Number, objSave); //hardcoded
    if (response.status == 200) {
      showMessage(thisObj, response.message, true);
      obj.lstOldContractLine = obj.lstNewContractLine
      obj.lstNewContractLine = []
      setData(thisObj, "PageData", obj)
      //return response.result;
      return true;
    } 
    else {
      if (response.message.includes("The information from your screen is no longer current.")) {
        alert("The information from your screen is no longer current.");
        setData(thisObj, "pbForceClose", true)
        setData(thisObj, "PageData", obj)
        return false;
      } 
      else {
        alert(response.message)
        setData(thisObj, "PageData", obj)
        return false;
      }
    }
  }  

  //functions made for setData for child screens
  function setDataFor_contractApplication2009() {

    cboWRNumArray = getData(thisObj, "cboWRNumArray")
    cboPSArray = getData(thisObj, "cboPSArray")
    lblVendorNumber = getData(thisObj, "lblVendorNumber")
    lblVendor = getData(thisObj, "lblVendor")
    txtRemitToArray = getData(thisObj, "txtRemitToArray")
    XtxtRemitToArray = getData(thisObj, "XtxtRemitToArray")
    txtSharePctArray = getData(thisObj, "txtSharePctArray")
    txtPoundsArray = getData(thisObj, "txtPoundsArray")
    txtValueArray = getData(thisObj, "txtValueArray")
    lblCMAParticipant = getData(thisObj, "lblCMAParticipant")

    let frmContractApplications2009LoadDataObj = {}
    let vendorSplitDataFrom1007SettlementScreen = []

    frmContractApplications2009LoadDataObj.FV95Number = getValue(thisObj, "txtSC95")
    frmContractApplications2009LoadDataObj.I1007Number = getValue(thisObj, "txt1007")
    frmContractApplications2009LoadDataObj.ApplicationsCanceled = "TRUE"
    frmContractApplications2009LoadDataObj.PurchaseStorageCaption = "Purchase"
    frmContractApplications2009LoadDataObj.PurchaseStorageTag = "P"
    frmContractApplications2009LoadDataObj.lblCMAVendor = getData(thisObj, "lblCMAVendor")
    frmContractApplications2009LoadDataObj.lblSeedPremApplyLBS = "" //having separate setdata
    frmContractApplications2009LoadDataObj.lblCMAAllowUnapplyLbs = getData(thisObj, "lblCMAAllowUnapplyLbs")

    for (let i = 0; i < lblVendorNumber.length; i++) {
      let vendorObj = {}
      if (cboWRNumArray.length > 0) {
        vendorObj.CboWRNum = cboWRNumArray[i]
      } else {
        vendorObj.CboWRNum = null
      }
      vendorObj.cboPS = cboPSArray[i]
      vendorObj.lblVendorNumber = lblVendorNumber[i]
      vendorObj.lblPounds = txtPoundsArray[i]
      vendorObj.lblCMAParticipant = lblCMAParticipant[i]
      vendorObj.lblVendorName = lblVendor[i]
      vendorObj.txtRemitTo = txtRemitToArray[i]
      vendorObj.txtSharePct = txtSharePctArray[i]
      vendorObj.txtValue = txtValueArray[i]
      vendorSplitDataFrom1007SettlementScreen.push(vendorObj)
    }
    frmContractApplications2009LoadDataObj.vendorSplitData = vendorSplitDataFrom1007SettlementScreen

    setData(thisObj, "frmContractApplications2009LoadData", frmContractApplications2009LoadDataObj)
  }

  function setDataFor_cleaningCuringCost() {
    let obj = getData(thisObj, "PageData");
    let js = {
      insp_num: getValue(thisObj, "txtSC95"),
      buy_pt_id: thisObj.values.txtBuyingPoint == null ? null : thisObj.values.txtBuyingPoint.split(' ')[0].trim(),
      action: null,
      mbCorrectionProcess: obj.mbCorrectionProcess
    }
    setData(thisObj, "CleaningCuringCostsOnLoadData", js);
    setData(thisObj, "parentForm", { Name: 'frm1007Settlement' });
    setData(thisObj, "mbCancelled", false)
    setData(thisObj, "PageData", obj);
  }

  function setDataFor_premiumDeductions(value1, value2, value3, value4, value5, value6, value7, value8, value9, value10, value11, value12, value13, value14, value15, value16, value17, value18, value19, value20, value21, value22, value23, value24) {
    let obj = {}
    let ValuesForPremiumDeductions = []

    obj.sc95Num = value1,
      obj.BuyPtId = value2,
      obj.YNboolean = value3,
      obj.lblFarmerStockCost = value4,
      obj.sApplContractList = value5,
      obj.sAppliedList = value6,
      obj.sPVendorList = value7,
      obj.dblNetWtP = value8,
      obj.dblLSKWtP = value9,
      obj.dblGrossWtP = value10,
      obj.lblStoresValueOfSeg = value11,
      obj.sTentContractList = value12,
      obj.sTentList = value13,
      obj.sSVendorList = value14,
      obj.dblNetWtS = value15,
      obj.dblLSKWtS = value16,
      obj.dblGrossWtS = value17,
      obj.lblPurchValueOfLoad = value18,
      obj.lblStoresValueOfLoad = value19,
      obj.strCureCleanCosts = value20,
      obj.sPDcodes_cure_clean_costs = value21,
      obj.SubstractionValue = value22,
      obj.sSeedContractList = value23,
      obj.sSeedList = value24,

      obj.lblSeedPremExpected = getData(thisObj, "lblSeedPremExpected")
    obj.lblSeedPremNetLBS = getData(thisObj, "lblSeedPremNetLBS")
    obj.lblSeedPremApplyLBS = getData(thisObj, "lblSeedPremApplyLBS")

    let vendornumbercount = getData(thisObj, "lblVendorNumber")
    let cboPSValues = []
    for (let i = 0; i < vendornumbercount.length; i++) {
      cboPSValues[i] = getValue(thisObj, ["ddPS" + (i)])
    }

    obj.cboPS = cboPSValues
    obj.cboPSCount = cboPSValues.length <= 4 ? 4 : cboPSValues.length
    obj.vendorName = getData(thisObj, "lblVendor")
    let newObj = getData(thisObj, "PageData")
    let marrVendorInfo_current = []
    marrVendorInfo_current.push(
      newObj.marrVendorInfo0,
      newObj.marrVendorInfo1,
      newObj.marrVendorInfo2,
      newObj.marrVendorInfo3,
      newObj.marrVendorInfo4,
      newObj.marrVendorInfo5,
      newObj.marrVendorInfo6,
      newObj.marrVendorInfo7,
      newObj.marrVendorInfo8,
      newObj.marrVendorInfo9,
      newObj.marrVendorInfo10,
      newObj.marrVendorInfo11,
      newObj.marrVendorInfo12,
      newObj.marrVendorInfo13,
      newObj.marrVendorInfo14,
      newObj.marrVendorInfo15,
      newObj.marrVendorInfo16,
      newObj.marrVendorInfo17
    )
    obj.marrVendorInfo = marrVendorInfo_current

    ValuesForPremiumDeductions.push(obj)
    setData(thisObj, "ValuesForPremiumDeductions", ValuesForPremiumDeductions)
  }

  function setDataFor_reportPreviewInspection(value1) {
    let js = []
    let obj = {}
    obj.ParentForm = "1007Settlement"
    obj.SC95No = getValue(thisObj, "txtSC95")
    obj.buy_pt_id = thisObj.values.txtBuyingPoint == null ? null : thisObj.values.txtBuyingPoint.split(' ')[0].trim()
    obj.type = 'REPRINT_FROM_HEADER'
    obj.s1007No = getValue(thisObj, "txt1007")
    obj.status = "S"
    obj.docType = ''

    switch (value1) {
      case "1":
        obj.method_name = "CORRECTION DOCUMENT"//"rptCorrectionDocument"
        break;

      case "2":
        obj.method_name = "rptInspectionCertificate"
        obj.docType = 'Applicant Original,FSIS,SELLER'
        break;

      case "3":
        obj.method_name = "rptInspectionCertificate_onedoc"
        obj.docType = 'Applicant Original,FSIS,SELLER'
        break;

      case "4":
        obj.method_name = "rptSettlementWorkSheet"
        obj.docType = '1007Worksheet'
        break;

      default:
        break;
    }
    setData(thisObj, "ReportPreviewInspectionScreenData", obj)
  }

  async function getDataFrom_ParentScreens() {
    let parentData = getData(thisObj, '1007SettlementScreenData')
    setValue(thisObj, "txtSC95", parentData.FV95_NUM)
    setValue(thisObj, "txt1007", parentData.FV1007_NUM)

    setData(thisObj, "1007_sc95num", parentData.FV95_NUM)
    setData(thisObj, "1007_txt1007", parentData.FV1007_NUM)
    setData(thisObj, "1007_buyptid", parentData.BuyPtId)
    if (parentData.lblfreefv95 !== undefined) {
      setData(thisObj, "lblfreefv95", parentData.lblfreefv95)
    }
    else {
      setData(thisObj, "lblfreefv95", null)
    }
    if (parentData.cboCMARemit !== undefined) {
      setData(thisObj, "1007_cboCMARemit", parentData.cboCMARemit)
    }
    else {
      setData(thisObj, "1007_cboCMARemit", "")
    }
  }

  function PublicProperties() {

    let obj = getData(thisObj, "PageData")

    //Ticket_info public property
    let ticketInfo = getData(thisObj, 'TicketInfo');
    obj.mbTicket_NUMBERS_CURE_CLEAN = ticketInfo.txtClnCrgTicketNumber
    obj.mbTicket_CURING_WT_IND = ticketInfo.LstrTICKET_CURING_WT_IND
    obj.mbTicket_CLEANING_WT_IND = ticketInfo.LstrTICKET_CLEANING_WT_IND
    obj.mbTicket_MOISTURE = ticketInfo.LstrTICKET_MOISTURE
    obj.mbTicket_CLEAN_WT_OTHER = ticketInfo.LstrTICKET_CLEAN_WT_OTHER
    obj.mbTicket_CURE_WT_OTHER = ticketInfo.LstrTICKET_CURE_WT_OTHER
    obj.mbTicket_CURE_SCHEDULE = ticketInfo.LstrTICKET_CURE_SCHEDULE
    obj.mbTicket_CLEAN_SCHEDULE = ticketInfo.LstrTICKET_CLEAN_SCHEDULE
    obj.mbTicket_TOTAL_CURING_COST = ticketInfo.lblCostToCure
    obj.mbTicket_TOTAL_CLEANING_COST = ticketInfo.lblCostToClean

    //remaining 4 public properties
    let CureCleanCosts = getData(thisObj, 'CureCleanCosts');
    let PDcodes_cure_clean_costs = getData(thisObj, 'PDcodes_cure_clean_costs');
    let TotalCureCost = getData(thisObj, 'TotalCureCost');
    let TotalCleanCost = getData(thisObj, 'TotalCleanCost');

    obj.strCureCleanCosts = CureCleanCosts
    obj.mdblTotalCureCosts = TotalCureCost
    obj.mdblTotalCleanCosts = TotalCleanCost
    obj.sPDcodes_cure_clean_costs = PDcodes_cure_clean_costs


    setData(thisObj, "PageData", obj)
  }

  function EmptyForm() {
    setLoading(true)
    VaribleDeclaration()
    setData(thisObj, "1007_cboCMARemit", "")
    let obj = getData(thisObj, "PageData");
    obj.mbStateId = ""
    obj.mbCountyId = ""
    obj.mbBuyPtId = ""
    txt1007.Text = ""
    obj.mbAreaId = ""
    obj.mbFarmId = ""
    obj.mbFarmSuffix = ""
    obj.mbPnutTypeID = ""
    obj.mbPnutVarietyId = ""
    obj.mbInspStatus = ""
    obj.mbInspDateTime = ""
    obj.mbSeedGen = ""
    obj.mbProbePattern = ""
    obj.mbSegment = ""
    obj.mbEdibleOil = ""
    obj.mbOleicInd = ""
    obj.mbCornInd = ""
    obj.mbFlavusInd = ""
    obj.mbVicamInd = ""
    obj.mbVicamPPB = ""
    obj.mbOBLBuyId = ""
    obj.mbOBLWhseId = ""
    obj.mbOBLBin = ""
    obj.mbUnloadWhseId = ""
    obj.mbUnloadBin = ""
    obj.mbFMSample = ""
    obj.mbCleanSample = ""
    obj.mbSMKGR = ""
    obj.mbSSGR = ""
    obj.mbOKGR = ""
    obj.mbDamSplitGr = ""
    obj.mbDamGr = ""
    obj.mbHullGr = ""
    obj.mbFrezDamGr = ""
    obj.mbCnclRmdGr = ""
    obj.mbCrkBrkGr = ""
    obj.mbDcolGr = ""
    obj.mbHulBritGr = ""
    obj.mbJumboGr = ""
    obj.mbELKGr = ""
    obj.mbFMGr = ""
    obj.mbLKSGR = ""
    obj.mbFanGR = ""
    obj.mbSMKPct = ""
    obj.mbSMK1Pct = ""
    obj.mbSMK2Pct = ""
    obj.mbSMK3Pct = ""
    obj.mbSMK4Pct = ""
    obj.mbSSPct = ""
    obj.mbOKPct = ""
    obj.mbDamPct = ""
    obj.mbTKCPct = ""
    obj.mbHullPct = ""
    obj.mbFrezDamPct = ""
    obj.mbCnclRmdPct = ""
    obj.mbCrBrPct = ""
    obj.mbDcolPct = ""
    obj.mbHulBritPct = ""
    obj.mbJumboPct = ""
    obj.mbELKPct = ""
    obj.mbFMPct = ""
    obj.mbLSKPct = ""
    obj.mbMoistPct = ""
    obj.mbFanPct = ""
    obj.mbOtherFM = ""
    obj.mbSMK1Gauge = ""
    obj.mbSMK2Gauge = ""
    obj.mbSMK3Gauge = ""
    obj.mbSMK4Gauge = ""
    obj.mbTareWt = ""
    obj.mbVehicleWT = ""
    obj.mbGrossWt = ""
    obj.mbFMWt = ""
    obj.mbExMoistWt = ""
    obj.mbNetWt = ""
    obj.mbLSKWt = ""
    obj.mbKernValue = ""
    obj.mbElkPrem = ""
    obj.mbDamDiscount = ""
    obj.mbExFmDiscount = ""
    obj.mbExSSDiscount = ""
    obj.mbFlavusDiscount = ""
    obj.mbNetLesLSKVal = ""
    obj.mbNetPrice = ""
    obj.mbBasisGradeAmt = ""

    for (let i = 0; i < 4; i++) {
      obj.mbScaleTicket[i] = "|||||||||||||||||||||"
    }

    let newObj = {}
    newObj["txtWtIncludingVehicle"] = "";
    newObj["txtWtOfVehicle"] = ""
    newObj["txtGrossWeight"] = ""
    newObj["txtForeignMaterial"] = ""
    newObj["txtWtlessFM"] = ""
    newObj["txtExcessMoisture"] = ""
    newObj["txtNetWeight"] = ""
    newObj["txtLSKvc"] = ""
    newObj["txtNetWtExclLSK"] = ""
    newObj["txtKernelValueTon"] = ""
    newObj["txtELKPremiumTon"] = ""
    newObj["txtTotalTon"] = ""
    newObj["txtDamageTon"] = ""
    newObj["txtAccessFMTon"] = ""
    newObj["txtExcessSplitsTon"] = ""
    newObj["txtAFlavusTon"] = ""
    newObj["txtTotalDiscountsTon"] = ""
    newObj["txtNetValPerTonExclSLK"] = ""
    newObj["txtValPerPoundExclSLK"] = ""
    newObj["txtOI"] = ""
    newObj["txtH"] = ""
    newObj["txtTotalLoadVal"] = ""
    newObj["txtG"] = ""
    newObj["txtarInspectorRemarks"] = ""
    newObj["chkboxPurchaseAll"] = false
    newObj["chkboxStoreAll"] = false

    setFieldValues(thisObj, newObj, true)

    obj.mdblSupportPerTon = 0

    cboWRNumArray = []
    cboPSArray = []
    lblVendorNumber = []
    lblVendor = []
    txtRemitToArray = []
    txtSharePctArray = []
    txtPoundsArray = []
    txtValueArray = []
    lblCMAParticipant = []
    XtxtRemitToArray = []

    let setObj = {}
    for (let i = 0; i > 20; i++) {
      setObj["ddPS" + (i)] = '';
      setObj["txtVendor" + (i)] = '';
      setObj["txtRemitTo" + (i)] = '';
      setObj["txtSharePct" + (i)] = '';
      setObj["txtPounds" + (i)] = '';
      setObj["txtValue" + (i)] = '';
      setObj["lblVendor" + (i)] = '';
    }
    setFieldValues(thisObj, setObj, true)

    obj.lstOldContractLine = []
    obj.lstNewPremDeduct = []
    EnableDisableControls()
    setValue(thisObj, "txt1007", "")
    setValue(thisObj, "txtFarm", "")
    setValue(thisObj, "txtBuyingPoint", "")
    obj.txt1007afterChange = true
    //disable(thisObj, "btnCntrctAppPremDeduct")
    if (!getData(thisObj, "95ValChange2")) {  //for not running showhide operation Multiple times
      ShowHidePanels()
      //customLoad2()
    }

    //new setdata lines fromformload without txtsc95
    setData(thisObj, "closingOfContractapplication2009", false)
    setData(thisObj, "closingOfCleaningCuringCost", false)
    setData(thisObj, "closingOfPremiumDeductions", false)
    setData(thisObj, "closingOfReportPreviewInspection", false)
    setData(thisObj, "lblApplicationsCanceled", false)
    setData(thisObj, "mbCancelled", false)
    setData(thisObj, "PremiumDeductionScreenData", [])
    document.getElementById("btnCntrctAppPremDeduct").innerText = "Contract Applications/ Premium Deductions"
    setData(thisObj, "worksheet_printed_successfully", false)

    setData(thisObj, "PageData", obj);
    setTimeout(() => {
      setLoading(false)  // suggested by mastercraft team //needed for reseting page, as so many setValues,hide/show and enable/disable for reset
    }, 2000);
  }

  function ClearAllPreviousVendorSplit() {
    let obj = {}
    for (let i = 0; i < 20; i++) {
      let current_vendorNumber = getValue(thisObj, ["txtVendor" + (i)])
      if (current_vendorNumber !== undefined && current_vendorNumber !== "" && current_vendorNumber !== null) {
        obj["ddWRNum" + (i)] = ""
        obj["ddPS" + (i)] = ""
        obj["txtVendor" + (i)] = ""
        obj["txtRemitTo" + (i)] = ""
        obj["txtSharePct" + (i)] = ""
        obj["txtPounds" + (i)] = ""
        obj["txtValue" + (i)] = ""
        obj["lblVendor" + (i)] = ""
      }
    }
    setFieldValues(thisObj, obj, true);
  }
  //onChange events
  const all_ddPS_change = (i) => {
    lblVendorNumber = getData(thisObj, "lblVendorNumber")
    if (lblVendorNumber !== null) {
      try {
        let obj = getData(thisObj, "PageData")
        let cboPS = getValue(thisObj, ["ddPS" + (i)])
        if (lblVendorNumber[i] == "") {
          setValue(thisObj, ["ddPS" + (i)], '')
          return false
        }
        obj.marrVendorInfo5[i] = cboPS

        CalculateWeightAndBasisGradeAmts()

        if (getValue(thisObj, "chkboxPurchaseAll")) {
          if (cboPS == 'S') {
            setValue(thisObj, "chkboxPurchaseAll", false)
          }
        }
        if (getValue(thisObj, "chkboxStoreAll")) {
          if (cboPS == 'P') {
            setValue(thisObj, "chkboxStoreAll", false)
          }
        }
        setData(thisObj, "PageData", obj)
        return true;
      } catch (err) {
        //showMessage(thisObj, "error on P/S dropdown change")
        return false;
      }
    }
    else {
      return false;
    }
  }

  const onddPS0Change = () => {
    all_ddPS_change(0)
  }
  thisObj.onddPS0Change = onddPS0Change;

  const onddPS1Change = () => {
    all_ddPS_change(1)
  }
  thisObj.onddPS1Change = onddPS1Change;

  const onddPS2Change = () => {
    all_ddPS_change(2)
  }
  thisObj.onddPS2Change = onddPS2Change;

  const onddPS3Change = () => {
    all_ddPS_change(3)
  }
  thisObj.onddPS3Change = onddPS3Change;

  const onddPS4Change = () => {
    all_ddPS_change(4)
  }
  thisObj.onddPS4Change = onddPS4Change;

  const onddPS5Change = () => {
    all_ddPS_change(5)
  }
  thisObj.onddPS5Change = onddPS5Change;

  const onddPS6Change = () => {
    all_ddPS_change(6)
  }
  thisObj.onddPS6Change = onddPS6Change;

  const onddPS7Change = () => {
    all_ddPS_change(7)
  }
  thisObj.onddPS7Change = onddPS7Change;

  const onddPS8Change = () => {
    all_ddPS_change(1)
  }
  thisObj.onddPS8Change = onddPS8Change;

  const onddPS9Change = () => {
    all_ddPS_change(9)
  }
  thisObj.onddPS9Change = onddPS9Change;

  const onddPS10Change = () => {
    all_ddPS_change(10)
  }
  thisObj.onddPS10Change = onddPS10Change;

  const onddPS11Change = () => {
    all_ddPS_change(11)
  }
  thisObj.onddPS11Change = onddPS11Change;

  const onddPS12Change = () => {
    all_ddPS_change(12)
  }
  thisObj.onddPS12Change = onddPS12Change;

  const onddPS13Change = () => {
    all_ddPS_change(13)
  }
  thisObj.onddPS13Change = onddPS13Change;

  const onddPS14Change = () => {
    all_ddPS_change(14)
  }
  thisObj.onddPS14Change = onddPS14Change;

  const onddPS15Change = () => {
    all_ddPS_change(15)
  }
  thisObj.onddPS15Change = onddPS15Change;

  const onddPS16Change = () => {
    all_ddPS_change(16)
  }
  thisObj.onddPS16Change = onddPS16Change;

  const onddPS17Change = () => {
    all_ddPS_change(17)
  }
  thisObj.onddPS17Change = onddPS17Change;

  const onddPS18Change = () => {
    all_ddPS_change(18)
  }
  thisObj.onddPS18Change = onddPS18Change;

  const onddPS19Change = () => {
    all_ddPS_change(19)
  }
  thisObj.onddPS19Change = onddPS19Change;

  const onddWRNum0Change = () => {
    if (thisObj.values.ddWRNum0 !== "" && thisObj.values.ddWRNum0 !== undefined) {
      enable(thisObj, "ddWRNum1")
    }
  }
  thisObj.onddWRNum0Change = onddWRNum0Change;

  const onddWRNum1Change = () => {
    if (thisObj.values.ddWRNum1 !== "" && thisObj.values.ddWRNum1 !== undefined) {
      enable(thisObj, "ddWRNum2")
    }
  }
  thisObj.onddWRNum1Change = onddWRNum1Change;

  const onddWRNum2Change = () => {
    if (thisObj.values.ddWRNum2 !== "" && thisObj.values.ddWRNum2 !== undefined) {
      enable(thisObj, "ddWRNum3")
    }
  }
  thisObj.onddWRNum2Change = onddWRNum2Change;

  const onddWRNum3Change = () => {
    if (thisObj.values.ddWRNum3 !== "" && thisObj.values.ddWRNum3 !== undefined) {
      enable(thisObj, "ddWRNum4")
    }
  }
  thisObj.onddWRNum3Change = onddWRNum3Change;

  const onddWRNum4Change = () => {
    if (thisObj.values.ddWRNum4 !== "" && thisObj.values.ddWRNum4 !== undefined) {
      enable(thisObj, "ddWRNum5")
    }
  }
  thisObj.onddWRNum4Change = onddWRNum4Change;

  const onddWRNum5Change = () => {
    if (thisObj.values.ddWRNum5 !== "" && thisObj.values.ddWRNum5 !== undefined) {
      enable(thisObj, "ddWRNum6")
    }
  }
  thisObj.onddWRNum5Change = onddWRNum5Change;

  const onddWRNum6Change = () => {
    if (thisObj.values.ddWRNum6 !== "" && thisObj.values.ddWRNum6 !== undefined) {
      enable(thisObj, "ddWRNum7")
    }
  }
  thisObj.onddWRNum6Change = onddWRNum6Change;

  const onddWRNum7Change = () => {
    if (thisObj.values.ddWRNum7 !== "" && thisObj.values.ddWRNum7 !== undefined) {
      enable(thisObj, "ddWRNum8")
    }
  }
  thisObj.onddWRNum7Change = onddWRNum7Change;

  const onddWRNum8Change = () => {
    if (thisObj.values.ddWRNum8 !== "" && thisObj.values.ddWRNum8 !== undefined) {
      enable(thisObj, "ddWRNum9")
    }
  }
  thisObj.onddWRNum8Change = onddWRNum8Change;

  const onddWRNum9Change = () => {
    if (thisObj.values.ddWRNum9 !== "" && thisObj.values.ddWRNum9 !== undefined) {
      enable(thisObj, "ddWRNum10")
    }
  }
  thisObj.onddWRNum9Change = onddWRNum9Change;

  const onddWRNum10Change = () => {
    if (thisObj.values.ddWRNum10 !== "" && thisObj.values.ddWRNum10 !== undefined) {
      enable(thisObj, "ddWRNum11")
    }
  }
  thisObj.onddWRNum10Change = onddWRNum10Change;

  const onddWRNum11Change = () => {
    if (thisObj.values.ddWRNum11 !== "" && thisObj.values.ddWRNum11 !== undefined) {
      enable(thisObj, "ddWRNum12")
    }
  }
  thisObj.onddWRNum11Change = onddWRNum11Change;

  const onddWRNum12Change = () => {
    if (thisObj.values.ddWRNum12 !== "" && thisObj.values.ddWRNum12 !== undefined) {
      enable(thisObj, "ddWRNum13")
    }
  }
  thisObj.onddWRNum12Change = onddWRNum12Change;

  const onddWRNum13Change = () => {
    if (thisObj.values.ddWRNum13 !== "" && thisObj.values.ddWRNum13 !== undefined) {
      enable(thisObj, "ddWRNum14")
    }
  }
  thisObj.onddWRNum13Change = onddWRNum13Change;

  const onddWRNum14Change = () => {
    if (thisObj.values.ddWRNum14 !== "" && thisObj.values.ddWRNum14 !== undefined) {
      enable(thisObj, "ddWRNum15")
    }
  }
  thisObj.onddWRNum14Change = onddWRNum14Change;

  const onddWRNum15Change = () => {
    if (thisObj.values.ddWRNum15 !== "" && thisObj.values.ddWRNum15 !== undefined) {
      enable(thisObj, "ddWRNum16")
    }
  }
  thisObj.onddWRNum15Change = onddWRNum15Change;

  const onddWRNum16Change = () => {
    if (thisObj.values.ddWRNum16 !== "" && thisObj.values.ddWRNum16 !== undefined) {
      enable(thisObj, "ddWRNum17")
    }
  }
  thisObj.onddWRNum16Change = onddWRNum16Change;

  const onddWRNum17Change = () => {
    if (thisObj.values.ddWRNum17 !== "" && thisObj.values.ddWRNum17 !== undefined) {
      enable(thisObj, "ddWRNum18")
    }
  }
  thisObj.onddWRNum17Change = onddWRNum17Change;

  const onddWRNum18Change = () => {
    if (thisObj.values.ddWRNum18 !== "" && thisObj.values.ddWRNum18 !== undefined) {
      enable(thisObj, "ddWRNum19")
    }
  }
  thisObj.onddWRNum18Change = onddWRNum18Change;


  const ontxt1007Change = () => {
    ShowHidePanels()
  }
  thisObj.ontxt1007Change = ontxt1007Change;

  const ontxtSC95Change = () => {
    setCounter(counter + 1)
    if (counter < 1) {  //for optimization of EmptyForm
      setLoading(true)
      let lengthchecker = getValue(thisObj, "txtSC95")
      if (lengthchecker.length <= 6 || lengthchecker !== getData(thisObj, "1007_sc95num")) {
        setData(thisObj, "95ValChange", true) //for not running showhide operation Multiple times
      }

      if (lengthchecker.length < 7 || lengthchecker !== getData(thisObj, "1007_sc95num")) {
        setUpdt(updt + 1)
        hide(thisObj, "grpbxPrchsStrgWtCert")
        hide(thisObj, "grpbxVendorSplits")
        hide(thisObj, "grpbxInspectorRemarks")
        hide(thisObj, "grpbxValueCalculation")
      }
      setData(thisObj, "1007_sc95num", getValue(thisObj, "txtSC95"))

      setData(thisObj, "95ValChange2", true) //for not running showhide operation Multiple times
    }
    else {
      // setData(thisObj, "95ValChange", true)
      setData(thisObj, "1007_sc95num", getValue(thisObj, "txtSC95"))
      // setData(thisObj, "95ValChange2", true)

      // disable(thisObj,"btnLookup")
    }
  }
  thisObj.ontxtSC95Change = ontxtSC95Change;

  //Button clicks
  const onchkboxPurchaseAllChange = () => {
    try {
      let obj = getData(thisObj, "PageData")
      lblVendorNumber = getData(thisObj, "lblVendorNumber")
      if (lblVendorNumber !== null) {
        if (getValue(thisObj, "chkboxPurchaseAll")) {
          setValue(thisObj, "chkboxStoreAll", false)
          for (let i = 0; i < lblVendorNumber.length; i++) {
            if (lblVendorNumber[i] !== "" || lblVendorNumber.length !== 0) {
              setValue(thisObj, ["ddPS" + (i)], 'P')
              obj.marrVendorInfo5[i] = "P"
            }
          }
          setValue(thisObj, "chkboxPurchaseAll", true)
        }
        EnableDisableControls()
        CalculateWeightAndBasisGradeAmts()
      }
      setData(thisObj, "PageData", obj)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on Purchase checkbox click"
        );
      }

      return false;
    }
    return true
  }
  thisObj.onchkboxPurchaseAllChange = onchkboxPurchaseAllChange;

  const onchkboxStoreAllChange = () => {
    try {
      let obj = getData(thisObj, "PageData")
      lblVendorNumber = getData(thisObj, "lblVendorNumber")
      if (lblVendorNumber !== null) {
        if (getValue(thisObj, "chkboxStoreAll")) {
          setValue(thisObj, "chkboxPurchaseAll", false)
          for (let i = 0; i < lblVendorNumber.length; i++) {
            if (lblVendorNumber[i] !== "" || lblVendorNumber.length !== 0) {
              setValue(thisObj, ["ddPS" + (i)], 'S')
              obj.marrVendorInfo5[i] = "S"
            }
          }
          setValue(thisObj, "chkboxStoreAll", true)
        }
        EnableDisableControls()
        CalculateWeightAndBasisGradeAmts()
      }
      setData(thisObj, "PageData", obj)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on Store checkbox click"
        );
      }
      return false;
    }
    return true
  }
  thisObj.onchkboxStoreAllChange = onchkboxStoreAllChange;

  const onbtnLookupClick = async () => {
    try {
      let obj = getData(thisObj, "PageData");
      setLoading(true);
      setCounter(0)
      let sHeader
      let sVendorSplits
      let sVendorApplication
      let sRemarks
      let sScaleTickets
      let sPremDeductions

      let LdblTotalDeductions = 0
      let LdblTotalPremiums = 0
      let LdblTotalProceeds = 0

      bfillCboWRNum()
      //let insp_num = getValue(thisObj,"txtSC95")
      let insp_num = getData(thisObj, "1007_sc95num")
      let lstrxml = await SettlementService.RetrieveInspectionHeaderList(insp_num, null, null, 'Y')
      if (lstrxml == undefined || lstrxml.length == 0) {
        showMessage(thisObj, lstrxml.message == undefined ? "No inspections found." : lstrxml.message)
        setLoading(false)
        return false
      }
      else {
        if (lstrxml[0].insp_num == "" || lstrxml[0].insp_num == undefined || lstrxml[0].insp_num == null) {
          showMessage(thisObj, "No inspections found.")
          setLoading(false)
          return false
        }
        switch (lstrxml[0].insp_status) {
          case "S":
            showMessage(thisObj, "Inspection already settled.")
            setValue(thisObj, "txtSC95", "")
            setLoading(false)
            return false
            break;

          case "N":
            showMessage(thisObj, "Inspection already settled.")
            setValue(thisObj, "txtSC95", "")
            setLoading(false)
            return false
            break;

          case "V":
            showMessage(thisObj, "Inspection already settled.")
            setValue(thisObj, "txtSC95", "")
            setLoading(false)
            return false
            break;

          default:
            break;
        }
        sHeader = lstrxml[0] //because api's and asp's output structure is little different
        sVendorSplits = lstrxml[0].inspect_vendor_splits
        sVendorApplication = lstrxml[0].inspect_vendor_applications
        sRemarks = lstrxml[0].inspect_remarks
        sScaleTickets = lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket
        sPremDeductions = lstrxml[0].inspect_prem_deducts

        setValue(thisObj, "txtFarm", lstrxml[0].farm_name)
        setValue(thisObj, "txtBuyingPoint", lstrxml[0].buy_pt_id + ' - ' + lstrxml[0].buy_pt_nLdblDataRowsame)
        obj.BuyPtId = lstrxml[0].buy_pt_id
        obj.lblCMAVendor = lstrxml[0].cma_vendor_num
        setData(thisObj, "lblCMAVendor", obj.lblCMAVendor)
        obj.lblCMAAllowUnapplyLbs = lstrxml[0].allow_unappl_lbs
        setData(thisObj, "lblCMAAllowUnapplyLbs", obj.lblCMAAllowUnapplyLbs)

        obj.mbStateId = lstrxml[0].state_abbr
        obj.mbCountyId = lstrxml[0].county_id
        obj.mbBuyPtId = lstrxml[0].buy_pt_id

        let func_id = 'PN1050', func_sub_id = null, area_id = null, coll_pt_id = null
        let LstrXMLArea = await SettlementService.RetrieveBuyingPointControlDetails(func_id, func_sub_id, area_id, coll_pt_id, obj.mbBuyPtId)
        if (LstrXMLArea == undefined || LstrXMLArea.length == 0) {
          showMessage(thisObj, LstrXMLArea.message)
          setLoading(false)
          return false
        }
        else {
          if (sHeader.settlement_num == "") {
            if (lstrxml[0].insp_status == "I") {
              if (Number(lstrxml[0].superseded_settle) !== 0 && lstrxml[0].superseded_settle !== "") {
                obj.mlSettlementNum = 0
                obj.mbSupersedeProcess = true
              } else {
                obj.mbSupersedeProcess = false
              }
            } else {
              obj.mbSupersedeProcess = false
            }

            if (!isNumeric(lstrxml[0].insp_num)) {
              let editval = replicateString("0", (7 - (((LstrXMLArea[0].next1007).toString()).length))) + LstrXMLArea[0].next1007
              setValue(thisObj, "txt1007", editval)
            } else {
              setValue(thisObj, "txt1007", lstrxml[0].insp_num)
            }
          }
          else {
            if (lstrxml[0].insp_status == "I") {
              obj.mlSettlementNum = sHeader.settlement_num
              obj.mdtSettlementDate = sHeader.settlement_date
              obj.mbCorrectionProcess = true
            } else {
              obj.mbCorrectionProcess = false
            }
            let editval = replicateString("0", (7 - (((sHeader.settlement_num).toString()).length))) + sHeader.settlement_num
            setValue(thisObj, "txt1007", editval)
          }

          obj.mbAreaId = LstrXMLArea[0].areaId
          obj.mbFarmId = sHeader.farm_id
          obj.mbFarmSuffix = sHeader.farm_suffix
          obj.mbPnutTypeID = sHeader.pnut_type_id
          obj.mbPnutVarietyId = sHeader.pnut_variety_id
          obj.mbInspStatus = sHeader.insp_status
          obj.mbInspDateTime = sHeader.insp_date_time
          obj.mbSeedGen = sHeader.seed_gen
          obj.mbProbePattern = sHeader.probe_pattern
          obj.mbSegment = sHeader.seg_type
          obj.mbEdibleOil = sHeader.edible_oil_ind
          obj.mbOleicInd = sHeader.oleic_ind
          obj.mbCornInd = sHeader.corn_ind
          obj.mbFlavusInd = sHeader.flavus_ind
          obj.mbVicamInd = sHeader.vicam_ind
          obj.mbVicamPPB = sHeader.vicam_ppb
          obj.mbOBLBuyId = sHeader.obl_buy_id
          obj.mbOBLWhseId = sHeader.obl_whse_id
          obj.mbOBLBin = sHeader.obl_bin_id
          obj.mbUnloadWhseId = sHeader.unld_whse_id
          obj.mbUnloadBin = sHeader.unld_bin_id
          obj.mbFMSample = sHeader.fm_sample
          obj.mbCleanSample = sHeader.cln_sample
          obj.mbSMKGR = sHeader.smk_gr
          obj.mbSSGR = sHeader.ss_gr
          obj.mbOKGR = sHeader.ok_gr
          obj.mbDamSplitGr = sHeader.dam_split_gr
          obj.mbDamGr = sHeader.dam_gr
          obj.mbHullGr = sHeader.hull_gr
          obj.mbFrezDamGr = sHeader.frez_dam_gr
          obj.mbCnclRmdGr = sHeader.cncl_rmd_gr
          obj.mbCrkBrkGr = sHeader.cr_br_gr
          obj.mbDcolGr = sHeader.dcol_shel_gr
          obj.mbHulBritGr = sHeader.hul_brit_gr
          obj.mbJumboGr = sHeader.jumbo_gr
          obj.mbELKGr = sHeader.elk_gr
          obj.mbFMGr = sHeader.fm_gr
          obj.mbLKSGR = sHeader.lsk_gr
          obj.mbFanGR = sHeader.fan_gr
          obj.mbSMKPct = sHeader.smk_pct
          obj.mbSMK1Pct = sHeader.smk_1_pct
          obj.mbSMK2Pct = sHeader.smk_2_pct
          obj.mbSMK3Pct = sHeader.smk_3_pct
          obj.mbSMK4Pct = sHeader.smk_4_pct
          obj.mbSSPct = sHeader.ss_pct
          obj.mbOKPct = sHeader.ok_pct
          obj.mbDamPct = sHeader.dam_pct
          obj.mbTKCPct = sHeader.tkc_pct
          obj.mbHullPct = sHeader.hull_pct
          obj.mbFrezDamPct = sHeader.frez_dam_pct
          obj.mbCnclRmdPct = sHeader.cncl_rmd_pct
          obj.mbCrBrPct = sHeader.cr_br_pct
          obj.mbDcolPct = sHeader.dcol_shel_pct
          obj.mbHulBritPct = sHeader.hul_brit_pct
          obj.mbJumboPct = sHeader.jumbo_pct
          obj.mbELKPct = sHeader.elk_pct
          obj.mbFMPct = sHeader.fm_pct
          obj.mbLSKPct = sHeader.lsk_pct

          if (isNumeric(sHeader.hmoist_pct)) {
            obj.mbMoistPct = sHeader.hmoist_pct
          } else {
            obj.mbMoistPct = sHeader.moist_pct
          }
          obj.mbFanPct = sHeader.fan_pct
          obj.mbOtherFM = sHeader.oth_fm
          obj.mbSMK1Gauge = sHeader.smk_1_guage
          obj.mbSMK2Gauge = sHeader.smk_2_guage
          obj.mbSMK3Gauge = sHeader.smk_3_guage
          obj.mbSMK4Gauge = sHeader.smk_4_guage
          obj.mbTareWt = sHeader.tare_wt
          obj.mbVehicleWT = sHeader.vehicle_wt
          obj.mbGrossWt = sHeader.gross_wt
          obj.mbFMWt = sHeader.fm_wt
          obj.mbExMoistWt = sHeader.ex_moist_wt
          obj.mbNetWt = sHeader.net_wt
          obj.mbLSKWt = sHeader.lsk_wt
          obj.mbKernValue = sHeader.kern_value
          obj.mbElkPrem = sHeader.elk_prem
          obj.mbDamDiscount = sHeader.dam_discount
          obj.mbExFmDiscount = sHeader.ex_fm_discount
          obj.mbExSSDiscount = sHeader.ex_ss_discount
          obj.mbFlavusDiscount = sHeader.flavus_discount
          obj.mbNetLesLSKVal = sHeader.net_les_lsk_val
          obj.mbNetPrice = sHeader.net_price
          obj.mbBasisGradeAmt = sHeader.basis_grade_amt
          obj.mlngSpotPounds = 0 + Number(sHeader.spot_pounds)
          obj.mdblSpotPrice = 0 + Number(sHeader.spot_price)
          setValue(thisObj, "lblAddedByValue", sHeader.add_user + " " + sHeader.add_date_time)
          setValue(thisObj, "lblChangedByValue", sHeader.chg_user + " " + sHeader.chg_date_time)
          obj.lblLastChange = sHeader.chg_date_time

          obj.mbTicket_NUMBERS_CURE_CLEAN = sHeader.ticket_numbers_cure_clean
          obj.mbTicket_CURING_WT_IND = sHeader.ticket_curing_wt_ind
          obj.mbTicket_CLEANING_WT_IND = sHeader.ticket_cleaning_wt_ind
          obj.mbTicket_MOISTURE = sHeader.ticket_moisture
          obj.mbTicket_CLEAN_WT_OTHER = sHeader.ticket_clean_wt_other
          obj.mbTicket_CURE_WT_OTHER = sHeader.ticket_cure_wt_other
          obj.mbTicket_CURE_SCHEDULE = sHeader.ticket_cure_schedule
          obj.mbTicket_CLEAN_SCHEDULE = sHeader.ticket_clean_schedule
          obj.mbTicket_TOTAL_CURING_COST = sHeader.ticket_total_curing_cost
          obj.mbTicket_TOTAL_CLEANING_COST = sHeader.ticket_total_cleaning_cost


          for (let nScaleTicket = 0; nScaleTicket < sScaleTickets.length; nScaleTicket++) {
            obj.mbScaleTicket[nScaleTicket] = sScaleTickets[nScaleTicket].dry_ticket + "|" + sScaleTickets[nScaleTicket].dry_date + "|" + sScaleTickets[nScaleTicket].dry_carrier + "|" + sScaleTickets[nScaleTicket].dry_dispatch + "|" + sScaleTickets[nScaleTicket].dry_vehicle_id + "|" + sScaleTickets[nScaleTicket].dry_tare_wt + "|" + sScaleTickets[nScaleTicket].dry_vehicle_wt + "|" + sScaleTickets[nScaleTicket].dry_peanut_wt
            obj.mbScaleTicket[nScaleTicket] = obj.mbScaleTicket[nScaleTicket] + "|" + sScaleTickets[nScaleTicket].green_ticket + "|" + sScaleTickets[nScaleTicket].green_date + "|" + sScaleTickets[nScaleTicket].green_carrier + "|" + sScaleTickets[nScaleTicket].green_dispatch + "|" + sScaleTickets[nScaleTicket].green_vehicle_id + "|" + sScaleTickets[nScaleTicket].green_tare_wt + "|" + sScaleTickets[nScaleTicket].green_vehicle_wt + "|" + sScaleTickets[nScaleTicket].green_peanut_wt
            obj.mbScaleTicket[nScaleTicket] = obj.mbScaleTicket[nScaleTicket] + "|" + sScaleTickets[nScaleTicket].weigher_name
          }

          for (let nScaleTicket = sScaleTickets.length; nScaleTicket <= 4; nScaleTicket++) {
            obj.mbScaleTicket[nScaleTicket] = "||||||||||||||||||||||"
          }

          if (obj.mbInspStatus !== "") {
            if (obj.mbVicamPPB == "") {
              CalculateValues2(obj.mbAreaId, obj.mbPnutTypeID, obj.mbTareWt, obj.mbVehicleWT, Number(obj.mbMoistPct), Number(obj.mbCrBrPct), Number(obj.mbDcolPct), Number(obj.mbFanPct), Number(obj.mbFMPct), Number(obj.mbLSKPct), Number(obj.mbSMKPct), Number(obj.mbSSPct), Number(obj.mbOKPct), Number(obj.mbELKPct), Number(obj.mbDamPct)) //add parameters
            }
            else {
              CalculateValues2(obj.mbAreaId, obj.mbPnutTypeID, obj.mbTareWt, obj.mbVehicleWT, Number(obj.mbMoistPct), Number(obj.mbCrBrPct), Number(obj.mbDcolPct), Number(obj.mbFanPct), Number(obj.mbFMPct), Number(obj.mbLSKPct), Number(obj.mbSMKPct), Number(obj.mbSSPct), Number(obj.mbOKPct), Number(obj.mbELKPct), Number(obj.mbDamPct), obj.mbVicamPPB)  //add parameters
            }
          }

          ClearVendorInfo()
          ClearAllPreviousVendorSplit()
          cboWRNumArray = []
          cboPSArray = []
          lblVendorNumber = []
          lblVendor = []
          txtRemitToArray = []
          XtxtRemitToArray = []
          txtSharePctArray = []
          txtPoundsArray = []
          txtValueArray = []
          lblCMAParticipant = []

          let setObj = {}
          for (let i = 0; i < sVendorSplits.length; i++) {
            lblVendorNumber[i] = sVendorSplits[i].split_vendor
            lblVendor[i] = sVendorSplits[i].vendor_name
            txtRemitToArray[i] = sVendorSplits[i].split_remit
            XtxtRemitToArray[i] = sVendorSplits[i].split_remit + " - " + sVendorSplits[i].address1 + " " + sVendorSplits[i].address2 + ", " + sVendorSplits[i].city + " " + sVendorSplits[i].state
            txtSharePctArray[i] = Number(sVendorSplits[i].split_share_pct).toFixed(2)
            txtPoundsArray[i] = sVendorSplits[i].net_wt
            txtValueArray[i] = Number(sVendorSplits[i].basis_grade_amt).toFixed(2)
            lblCMAParticipant[i] = sVendorSplits[i].cma_participant_ind
            cboPSArray[i] = sVendorSplits[i].purh_stor_ind

            setObj["ddPS" + (i)] = cboPSArray[i]
            setObj["txtVendor" + (i)] = lblVendorNumber[i]
            setObj["txtRemitTo" + (i)] = XtxtRemitToArray[i]
            setObj["txtSharePct" + (i)] = txtSharePctArray[i]
            setObj["txtPounds" + (i)] = txtPoundsArray[i]
            setObj["txtValue" + (i)] = txtValueArray[i]
            setObj["lblVendor" + (i)] = lblVendor[i]

            for (let j = 0; j < sVendorApplication.length; j++) {
              if (lblVendorNumber[i] == sVendorApplication[j].rcpt_vendor && (sVendorSplits[i].purh_stor_ind).toUpperCase() == "S") {
                if (sVendorApplication[j].whse_rcpt_num !== "") {
                  let ddwr = sVendorApplication[j].rcpt_buy_pt + ' - ' + replicateString("0", (6 - (sVendorApplication[j].whse_rcpt_num).toString().length)) + sVendorApplication[j].whse_rcpt_num
                  cboWRNumArray[i] = ddwr
                }

                if (cboWRNumArray.length > 0) {
                  cboWRNumArray = []
                }

              }
            }
            bfillCboWRNum(i, cboWRNumArray)

            obj.marrVendorInfo0[i] = sVendorSplits[i].split_vendor
            obj.marrVendorInfo1[i] = sVendorSplits[i].split_remit
            obj.marrVendorInfo2[i] = sVendorSplits[i].split_share_pct
            obj.marrVendorInfo3[i] = sVendorSplits[i].whse_rcpt_bp
            obj.marrVendorInfo4[i] = sVendorSplits[i].whse_rcpt_num
            obj.marrVendorInfo5[i] = sVendorSplits[i].purh_stor_ind
            obj.marrVendorInfo6[i] = sVendorSplits[i].gross_wt
            obj.marrVendorInfo7[i] = sVendorSplits[i].net_wt
            obj.marrVendorInfo8[i] = sVendorSplits[i].lsk_wt
            obj.marrVendorInfo9[i] = sVendorSplits[i].basis_grade_amt
            obj.marrVendorInfo10[i] = sVendorSplits[i].value_per_ton
            obj.marrVendorInfo11[i] = sVendorSplits[i].support_pct
            obj.marrVendorInfo12[i] = sVendorSplits[i].value_of_seg
            obj.marrVendorInfo13[i] = sVendorSplits[i].premium_amt
            obj.marrVendorInfo14[i] = sVendorSplits[i].deduct_amt
            obj.marrVendorInfo15[i] = sVendorSplits[i].proceeds_amt
            obj.marrVendorInfo16[i] = sVendorSplits[i].taxid


            LdblTotalDeductions = LdblTotalDeductions + (isNumeric(obj.marrVendorInfo14[i])) ? Number(obj.marrVendorInfo14[i]) : 0
            LdblTotalPremiums = LdblTotalPremiums + (isNumeric(obj.marrVendorInfo13[i])) ? Number(obj.marrVendorInfo13[i]) : 0
            LdblTotalProceeds = LdblTotalProceeds + (isNumeric(obj.marrVendorInfo15[i])) ? Number(obj.marrVendorInfo15[i]) : 0

            obj.lblStoresContract = 0
            if (obj.marrVendorInfo5[i] == "P") {
              obj.lblPurchContactSpotPrice = obj.marrVendorInfo10[i]
              obj.lblPurchPercentOfSupport = obj.marrVendorInfo11[i]
              obj.lblPurchValueOfSeg = (isNumeric(obj.lblPurchValueOfSeg)) ? Number(obj.lblPurchValueOfSeg) : 0 + (isNumeric(obj.marrVendorInfo12[i])) ? Number(obj.marrVendorInfo12[i]) : 0
            }
            else {
              obj.lblStoresContract = obj.marrVendorInfo10[i]
              obj.lblStoresPercentOfSupport = obj.marrVendorInfo11[i]
              obj.lblStoresValueOfSeg = (isNumeric(obj.lblStoresValueOfSeg)) ? Number(obj.lblStoresValueOfSeg) : 0 + (isNumeric(obj.marrVendorInfo12[i])) ? Number(obj.marrVendorInfo12[i]) : 0
            }
          }
          setFieldValues(thisObj, setObj, true);
          setValue(thisObj, "txtarInspectorRemarks", sRemarks[0].remarks)


        }
      }
      obj.marrVendorInfo.push(
        obj.marrVendorInfo0,
        obj.marrVendorInfo1,
        obj.marrVendorInfo2,
        obj.marrVendorInfo3,
        obj.marrVendorInfo4,
        obj.marrVendorInfo5,
        obj.marrVendorInfo6,
        obj.marrVendorInfo7,
        obj.marrVendorInfo8,
        obj.marrVendorInfo9,
        obj.marrVendorInfo10,
        obj.marrVendorInfo11,
        obj.marrVendorInfo12,
        obj.marrVendorInfo13,
        obj.marrVendorInfo14,
        obj.marrVendorInfo15,
        obj.marrVendorInfo16
      )
      obj.lstOldContractLine = []
      let sc95Num = getData(thisObj, "1007_sc95num")
      let data = await ContractManagementService.RetrieveApplicationContractDetails(null, obj.BuyPtId, sc95Num, null, null)
      if (data.length !== 0 && data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pricingLine == 1 || data[i].pricingLine == "1") {
            obj.lstOldContractLine[i] = data[i].contract_num + "|Firm|" + data[i].appl_lbs_adj
          } else {
            obj.lstOldContractLine[i] = data[i].contract_num + "|Basis|" + data[i].appl_lbs_adj
          }
        }
      }
      setValue(thisObj, "txtProceedsPrchs", LdblTotalProceeds.toFixed(2))
      setValue(thisObj, "txtPremiumsPrchs", LdblTotalPremiums.toFixed(2))
      setValue(thisObj, "txtDeductionsPrchs", LdblTotalDeductions.toFixed(2))

      let sDeductionCode = ""
      for (let i = 0; i < sPremDeductions.length; i++) {
        if (sPremDeductions[i].pd_code !== sDeductionCode) {
          obj.lstNewPremDeduct[i] = sPremDeductions[i].pd_code + "|Y|" + sPremDeductions[i].pd_vendor + " - " + sPremDeductions[i].pd_remit + " - " + sPremDeductions[i].pd_vendor_name + "|" + sPremDeductions[i].pd_share_pct
          sDeductionCode = sPremDeductions[i].pd_code
        } else {
          obj.lstNewPremDeduct[obj.lstNewPremDeduct.length - 1] = obj.lstNewPremDeduct[obj.lstNewPremDeduct.length - 1] + "|" + sPremDeductions[i].pd_vendor + " - " + sPremDeductions[i].pd_remit + " - " + sPremDeductions[i].pd_vendor_name + "|" + sPremDeductions[i].pd_share_pct
        }
      }

      setData(thisObj, "cboWRNumArray", cboWRNumArray)
      setData(thisObj, "cboPSArray", cboPSArray)
      setData(thisObj, "lblVendorNumber", lblVendorNumber)
      setData(thisObj, "lblVendor", lblVendor)
      setData(thisObj, "txtRemitToArray", txtRemitToArray)
      setData(thisObj, "XtxtRemitToArray", XtxtRemitToArray)
      setData(thisObj, "txtSharePctArray", txtSharePctArray)
      setData(thisObj, "txtPoundsArray", txtPoundsArray)
      setData(thisObj, "txtValueArray", txtValueArray)
      setData(thisObj, "lblCMAParticipant", lblCMAParticipant)
      EnableDisableControls()
      setData(thisObj, "95ValChange", false)
      setData(thisObj, "95ValChange2", false)  //for not running showhide operations muliple time
      ShowHidePanels()




      let tempObj = {}
      //Purchase column
      tempObj["txtNetWeightPrchs"] = obj.lblPurchNetWeight == undefined ? null : obj.lblPurchNetWeight.toString()
      tempObj["txtTotalLoadValuePrchs"] = obj.lblPurchValueOfLoad == undefined ? null : obj.lblPurchValueOfLoad.toString()
      tempObj["txtContractSpotPricePrchs"] = obj.lblPurchContactSpotPrice == undefined ? null : obj.lblPurchContactSpotPrice.toString()
      tempObj["txtPctOfSupportPrchs"] = obj.lblPurchPercentOfSupport == undefined ? null : obj.lblPurchPercentOfSupport.toString()
      tempObj["txtFarmerStockCostPrchs"] = obj.lblFarmerStockCost == undefined ? null : obj.lblFarmerStockCost.toString()
      tempObj["txtValueOfSegPrchs"] = obj.lblPurchValueOfSeg == undefined ? null : obj.lblPurchValueOfSeg.toString()
      tempObj["txtOptionPayment"] = obj.lblOptionPayment == undefined ? null : obj.lblOptionPayment.toString()
      //remaining 3 are above after RetrieveApplicationContractDetails

      //Storage column
      tempObj["txtNetWeightStrg"] = obj.lblStoresNetWeight == undefined ? null : obj.lblStoresNetWeight.toString()
      tempObj["txtTotalLoadValueStrg"] = obj.lblStoresValueOfLoad == undefined ? null : obj.lblStoresValueOfLoad.toFixed(2)
      tempObj["txtOptionPriceStrg"] = obj.lblStoresContract == '0.00' || obj.lblStoresContract == null ? "0" : obj.lblStoresContract.toString() //
      tempObj["txtPctOfSupportStrg"] = obj.lblStoresPercentOfSupport == '0.00' || obj.lblStoresPercentOfSupport == null ? "0" : obj.lblStoresPercentOfSupport.toString()//
      tempObj["txtValueOfOptionStrg"] = obj.lblStoresValueOfSeg == undefined || obj.lblStoresValueOfSeg == null ? '0' : obj.lblStoresValueOfSeg.toString()
      tempObj["txtSeedPremiumStrg"] = obj.lblStoresSeedPremium == undefined ? null : obj.lblStoresSeedPremium.toString()
      tempObj["txtDeductionsStrg"] = obj.lblStoresDeductions == undefined ? null : obj.lblStoresDeductions.toString()
      tempObj["txtProceedsStrg"] = obj.lblStorProceeds == undefined ? null : obj.lblStorProceeds.toString()

      setFieldValues(thisObj, tempObj, true)
      setData(thisObj, "PageData", obj);
      bFillPSList()
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on lookup button click"
        );
      }
      setLoading(false)
      return false;
    }
    return true
  }
  thisObj.onbtnLookupClick = onbtnLookupClick;

  const onbtnCntrctAppPremDeductClick = async () => {
    let obj = getData(thisObj, "PageData");
    try {
      if (!(bFormValid())) {
        return false
      }
      setData(thisObj, 'SC95changeData', {
        flag : true,
        SC95Number : getValue(thisObj, "txtSC95")
      })

      switch (document.getElementById("btnCntrctAppPremDeduct").innerText) {
        case 'Contract Applications/ Premium Deductions':
          setData(thisObj, "lblSeedPremExpected", "")
          setData(thisObj, "lblSeedPremNetLBS", "")
          setData(thisObj, "lblSeedPremApplyLBS", "")
          cboPSArray = []
          for (let i = 0; i < lblVendorNumber.length; i++) {
            cboPSArray[i] = thisObj.values['ddPS' + i]
          }
          setData(thisObj, "cboPSArray", cboPSArray)
          setDataFor_contractApplication2009()
          setData(thisObj, "lblApplicationsCanceled", true)
          setData(thisObj, "closingOfContractApplication2009", false)
          goTo(thisObj, "Settlements#ContractApplications2009#DEFAULT#true#Click")
          break;

        case 'Complete/Print 1007':
          setLoading(true)
          let settlementBoolean = await bSaveSettlement()
          //let settlementBoolean = true //temp. hardcoded
          if (settlementBoolean) {
            obj.lblPremDeductUnselect = ""
            obj.mbInspStatus = "S"
            setDataFor_reportPreviewInspection()
            if (obj.mbCorrectionProcess) {
              setDataFor_reportPreviewInspection("1")
            }
            else {
              if (getValue(thisObj, "txtSC95") !== getValue(thisObj, "txt1007")) {
                setDataFor_reportPreviewInspection("2")
              }
              else {
                setDataFor_reportPreviewInspection("3")
              }
            }
            setLoading(false)
            setData(thisObj, "closingOfReportPreviewInspection", false)
            goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
          }
          setLoading(false)
          break;

        default:
          break;
      }

    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on Ok button click"
        );
      }
      obj.lblPremDeductUnselect = ""
      return false;
    }
    setData(thisObj, "PageData", obj);
    return true;
  }
  thisObj.onbtnCntrctAppPremDeductClick = onbtnCntrctAppPremDeductClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementById("Settlements_1007SettlementPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error on Cancel button Click, Please try 'X' button");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnCancelClick = onbtnCancelClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_1007Settlement*/}

              {/* END_USER_CODE-USER_BEFORE_1007Settlement*/}

              {/* START_USER_CODE-USER_BEFORE_grpbx1007Settlements*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1007Settlements*/}

              <GroupBoxWidget
                conf={state.grpbx1007Settlements}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxControlInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxControlInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxControlInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblControlInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblControlInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblControlInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblControlInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblControlInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                  {/* START_USER_CODE-USER_BEFORE_btnLookup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnLookup*/}

                  <ButtonWidget
                    conf={state.btnLookup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnLookup*/}

                  {/* END_USER_CODE-USER_AFTER_btnLookup*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarm}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyingPoint}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxControlInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxControlInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                <GroupBoxWidget
                  conf={state.grpbxVendorSplits}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblVendorSplitsuic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendorSplitsuic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendorSplitsuic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendorSplitsuic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendorSplitsuic0*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxPurchaseAll*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxPurchaseAll*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxPurchaseAll}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxPurchaseAll*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxPurchaseAll*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxStoreAll*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxStoreAll*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxStoreAll}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxStoreAll*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxStoreAll*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWhseRcptuic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWhseRcptuic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWhseRcptuic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWhseRcptuic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblWhseRcptuic0*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPOrSuic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPOrSuic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPOrSuic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPOrSuic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblPOrSuic0*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVendoruic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendoruic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendoruic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendoruic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendoruic0*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemitTo0uic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemitTo0uic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemitTo0uic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemitTo0uic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemitTo0uic0*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSharePctuic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSharePctuic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSharePctuic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSharePctuic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblSharePctuic0*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPoundsuic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPoundsuic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPoundsuic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPoundsuic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblPoundsuic0*/}
                  {/* START_USER_CODE-USER_BEFORE_lblValue0uic0*/}

                  {/* END_USER_CODE-USER_BEFORE_lblValue0uic0*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblValue0uic0}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblValue0uic0*/}

                  {/* END_USER_CODE-USER_AFTER_lblValue0uic0*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow0}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum0*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum0*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum0*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS0*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS0*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS0*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor0*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo0*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct0*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds0*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue0*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue0*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor0*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor0*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor0}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor0*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor0*/}


                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum1*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum1*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS1*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo1*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct1*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds1*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue1*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue1*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor1*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor1}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor1*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum2*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum2*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS2*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo2*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct2*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds2*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue2*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue2*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor2*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor2}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor2*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow3}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum3*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum3*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS3*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo3*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct3*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds3*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue3*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue3*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor3*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor3}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor3*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow4}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum4*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum4*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS4*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo4*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct4*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds4*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue4*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue4*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor4*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor4}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor4*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow5}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum5*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum5*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS5*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo5*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct5*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds5*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue5*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue5*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor5*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor5}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor5*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow6}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum6*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum6*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS6*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo6*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct6*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds6*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue6*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue6*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor6*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor6}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor6*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow7}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum7*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum7*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS7*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo7*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct7*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds7*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue7*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue7*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor7*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor7}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor7*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow8}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum8*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum8*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS8*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo8*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct8*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds8*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue8*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue8*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor8*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor8}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor8*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow9}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum9*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum9*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS9*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo9*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct9*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds9*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue9*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue9*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor9*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor9}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor9*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow10}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum10*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum10*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum10}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum10*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum10*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS10*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS10*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS10}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS10*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo10*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct10*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds10*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue10*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue10*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor10*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor10}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor10*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow11}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum11*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum11*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum11}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum11*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum11*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS11*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS11*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS11}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS11*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo11*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct11*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds11*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue11*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue11*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor11*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor11}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor11*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow12}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum12*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum12*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum12}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum12*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum12*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS12*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS12*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS12}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS12*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo12*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct12*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds12*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue12*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue12*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor12*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor12}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor12*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow13}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum13*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum13*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum13}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum13*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum13*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS13*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS13*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS13}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS13*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo13*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct13*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds13*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue13*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue13*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor13*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor13}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor13*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow14}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum14*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum14*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum14}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum14*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum14*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS14*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS14*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS14}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS14*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo14*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct14*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds14*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue14*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue14*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor14*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor14}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor14*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow15}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum15*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum15*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum15}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum15*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum15*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS15*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS15*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS15}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS15*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo15*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct15*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds15*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue15*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue15*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor15*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor15}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor15*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow16}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum16*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum16*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum16}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum16*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum16*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS16*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS16*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS16}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS16*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo16*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct16*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds16*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue16*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue16*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor16*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor16}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor16*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow17}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum17*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum17*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum17}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum17*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum17*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS17*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS17*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS17}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS17*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo17*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct17*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds17*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue17*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue17*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor17*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor17}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor17*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow18}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum18*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum18*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum18}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum18*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum18*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS18*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS18*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS18}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS18*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo18*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct18*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds18*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue18*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue18*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor18*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor18}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor18*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow19}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWRNum19*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWRNum19*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWRNum19}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWRNum19*/}

                    {/* END_USER_CODE-USER_AFTER_ddWRNum19*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPS19*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPS19*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPS19}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPS19*/}

                    {/* END_USER_CODE-USER_AFTER_ddPS19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo19*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct19*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds19*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue19*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue19*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendor19*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendor19}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendor19*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVendorSplits*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVendorSplits*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}

                <GroupBoxWidget
                  conf={state.grpbxInspectorRemarks}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblInspectorRemarks}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblInspectorRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_lblInspectorRemarks*/}
                  {/* START_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

                  <TextAreaWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtarInspectorRemarks}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}

                {/* END_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPrchsStrgWtCert*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPrchsStrgWtCert*/}

                <GroupBoxWidget
                  conf={state.grpbxPrchsStrgWtCert}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxPurchases*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPurchases*/}

                  <GroupBoxWidget
                    conf={state.grpbxPurchases}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblPurchases*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPurchases*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPurchases}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPurchases*/}

                    {/* END_USER_CODE-USER_AFTER_lblPurchases*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetWeightPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetWeightPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetWeightPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetWeightPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetWeightPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalLoadValuePrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalLoadValuePrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalLoadValuePrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalLoadValuePrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalLoadValuePrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtContractSpotPricePrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtContractSpotPricePrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtContractSpotPricePrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtContractSpotPricePrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtContractSpotPricePrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPctOfSupportPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPctOfSupportPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPctOfSupportPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPctOfSupportPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtPctOfSupportPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtFarmerStockCostPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtFarmerStockCostPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtFarmerStockCostPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtFarmerStockCostPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtFarmerStockCostPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValueOfSegPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValueOfSegPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValueOfSegPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValueOfSegPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtValueOfSegPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOptionPayment*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOptionPayment*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOptionPayment}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOptionPayment*/}

                    {/* END_USER_CODE-USER_AFTER_txtOptionPayment*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPremiumsPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPremiumsPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPremiumsPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPremiumsPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtPremiumsPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDeductionsPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDeductionsPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDeductionsPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDeductionsPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtDeductionsPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtProceedsPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtProceedsPrchs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtProceedsPrchs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtProceedsPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_txtProceedsPrchs*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPurchases*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPurchases*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStorage*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStorage*/}

                  <GroupBoxWidget conf={state.grpbxStorage} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblStorage*/}

                    {/* END_USER_CODE-USER_BEFORE_lblStorage*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblStorage}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblStorage*/}

                    {/* END_USER_CODE-USER_AFTER_lblStorage*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetWeightStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetWeightStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetWeightStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetWeightStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetWeightStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalLoadValueStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalLoadValueStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalLoadValueStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalLoadValueStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalLoadValueStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOptionPriceStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOptionPriceStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOptionPriceStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOptionPriceStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtOptionPriceStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPctOfSupportStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPctOfSupportStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPctOfSupportStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPctOfSupportStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtPctOfSupportStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValueOfOptionStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValueOfOptionStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValueOfOptionStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValueOfOptionStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtValueOfOptionStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSeedPremiumStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSeedPremiumStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSeedPremiumStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSeedPremiumStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtSeedPremiumStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDeductionsStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDeductionsStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDeductionsStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDeductionsStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtDeductionsStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtProceedsStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtProceedsStrg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtProceedsStrg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtProceedsStrg*/}

                    {/* END_USER_CODE-USER_AFTER_txtProceedsStrg*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStorage*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStorage*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPrchsStrgWtCert*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPrchsStrgWtCert*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

                <GroupBoxWidget
                  conf={state.grpbxValueCalculation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblValueCalculation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblValueCalculation*/}

                  {/* END_USER_CODE-USER_AFTER_lblValueCalculation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWtIncludingVehicle}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}

                  {/* END_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWtOfVehicle}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWtOfVehicle*/}

                  {/* END_USER_CODE-USER_AFTER_txtWtOfVehicle*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGrossWeight}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGrossWeight*/}

                  {/* END_USER_CODE-USER_AFTER_txtGrossWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                  {/* END_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtForeignMaterial}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtForeignMaterial*/}

                  {/* END_USER_CODE-USER_AFTER_txtForeignMaterial*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWtlessFM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWtlessFM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWtlessFM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWtlessFM*/}

                  {/* END_USER_CODE-USER_AFTER_txtWtlessFM*/}
                  {/* START_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                  {/* END_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtExcessMoisture}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtExcessMoisture*/}

                  {/* END_USER_CODE-USER_AFTER_txtExcessMoisture*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetWeight*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWeight}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetWeight*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKvc*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKvc*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKvc}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKvc*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKvc*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetWtExclLSK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetWtExclLSK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWtExclLSK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetWtExclLSK*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetWtExclLSK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerTon}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtKernelValueTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernelValueTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernelValueTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernelValueTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernelValueTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELKPremiumTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELKPremiumTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELKPremiumTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELKPremiumTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtELKPremiumTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDamageTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamageTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamageTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamageTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamageTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAccessFMTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAccessFMTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAccessFMTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAccessFMTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtAccessFMTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtExcessSplitsTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtExcessSplitsTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtExcessSplitsTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAFlavusTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAFlavusTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAFlavusTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAFlavusTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtAFlavusTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDiscountsTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDiscountsTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDiscountsTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDiscountsTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDiscountsTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetValPerTonExclSLK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValPerPoundExclSLK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                  <LabelWidget
                    values={values}
                    conf={state.txtValPerPoundInclSLK}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOI*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOI*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOI}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOI*/}

                  {/* END_USER_CODE-USER_AFTER_txtOI*/}
                  {/* START_USER_CODE-USER_BEFORE_txtH*/}

                  {/* END_USER_CODE-USER_BEFORE_txtH*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtH}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtH*/}

                  {/* END_USER_CODE-USER_AFTER_txtH*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLoadVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLoadVal*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLoadVal*/}
                  {/* START_USER_CODE-USER_BEFORE_txtG*/}

                  {/* END_USER_CODE-USER_BEFORE_txtG*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtG}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtG*/}

                  {/* END_USER_CODE-USER_AFTER_txtG*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerLb3*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerLb3*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerLb3}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerLb3*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerLb3*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxValueCalculation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxValueCalculation*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1007Settlements*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1007Settlements*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnCntrctAppPremDeduct*/}

                {/* END_USER_CODE-USER_BEFORE_btnCntrctAppPremDeduct*/}

                <ButtonWidget
                  conf={state.btnCntrctAppPremDeduct}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCntrctAppPremDeduct*/}

                {/* END_USER_CODE-USER_AFTER_btnCntrctAppPremDeduct*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_1007Settlement*/}

              {/* END_USER_CODE-USER_AFTER_1007Settlement*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_1007Settlement);
