/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  hide,
  show,
  hideColumn,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./FlexibleMarketPricings.scss";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import CommonContext from "../../Store/CommonContext";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_FlexibleMarketPricings(props) {
  const contextType = useAppContext();
  const screenRef = useRef();

  // START_USER_CODE-USER_PROPERTIES
  const commonContext = useContext(CommonContext);
  // END_USER_CODE-USER_PROPERTIES

  //radio button lable variables
  let rMarketPrice
  let rRebateRate1
  let rRePayrate
  let rRebateRate2
  let rLoanShare
  let rLoanShare2

  let states = {

    Label: "FlexibleMarketPricingsTest",
    windowName: "FlexibleMarketPricings",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.FlexibleMarketPricings",
    // START_USER_CODE-USER_FlexibleMarketPricings_PROPERTIES
    headerData: {
      scrName: "Flexible Market Pricings",
      scrCode: "PN1010G"
    },
    //horizontalForm: true,
    // END_USER_CODE-USER_FlexibleMarketPricings_PROPERTIES
    btnApprv: {
      name: "btnApprv",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Approve",
      CharWidth: "17",
      // START_USER_CODE-USER_btnApprv_PROPERTIES

      // END_USER_CODE-USER_btnApprv_PROPERTIES
    },
    coltxtFV95_1007: {
      name: "coltxtFV95_1007",
      type: "TextBoxWidget",
      colName: "colFv95",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      Label: "SC95/1007",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colFV95_1007_PROPERTIES
      // END_USER_CODE-USER_colFV95_1007_PROPERTIES
    },

    colFv95: {
      name: "colFv95",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "FV95_1007",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFv95_PROPERTIES
      // END_USER_CODE-USER_colFv95_PROPERTIES
    },
    btnSign: {
      name: "btnSign",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Sign",
      CharWidth: "17",
      // START_USER_CODE-USER_btnApprv_PROPERTIES

      // END_USER_CODE-USER_btnApprv_PROPERTIES
    },
    btnCreate: {
      name: "btnCreate",
      type: "ButtonWidget",
      parent: "grpboxMiddle",
      Label: "Create",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCreate_PROPERTIES

      // END_USER_CODE-USER_btnCreate_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnPrintWork: {
      name: "btnPrintWork",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Print WorkSheet",
      CharWidth: "32",
      // START_USER_CODE-USER_btnPrintWork_PROPERTIES

      // END_USER_CODE-USER_btnPrintWork_PROPERTIES
    },
    btnPrntPrcng: {
      name: "btnPrntPrcng",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Print Contract Pricing",
      CharWidth: "45",
      // START_USER_CODE-USER_btnPrntPrcng_PROPERTIES

      // END_USER_CODE-USER_btnPrntPrcng_PROPERTIES
    },
    btnRescan: {
      name: "btnRescan",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Rescan",
      CharWidth: "15",
      // START_USER_CODE-USER_btnRescan_PROPERTIES

      // END_USER_CODE-USER_btnRescan_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpBoxBottom",
      Label: "Void",
      CharWidth: "11",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    chkboxOverrideRebate: {
      name: "chkboxOverrideRebate",
      type: "CheckBoxWidget",
      parent: "grpboxMiddle",
      Label: "Override Rebate Rate?",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOverrideRebate_PROPERTIES

      // END_USER_CODE-USER_chkboxOverrideRebate_PROPERTIES
    },
    colBtnWhsRcpts: {
      name: "colBtnWhsRcpts",
      type: "GridColumnWidget",
      colName: "colWhsRcpts",
      parent: "gridExistingPricing",
      Label: "+",
      CharWidth: "6",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colBtnWhsRcpts_PROPERTIES

      // END_USER_CODE-USER_colBtnWhsRcpts_PROPERTIES
    },
    colElection: {
      name: "colElection",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Election",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colElection_PROPERTIES

      // END_USER_CODE-USER_colElection_PROPERTIES
    },
    colEmpty: {
      name: "colEmpty",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEmpty_PROPERTIES

      // END_USER_CODE-USER_colEmpty_PROPERTIES
    },
    colLoanGainRate: {
      name: "colLoanGainRate",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Loan Gain Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanGainRate_PROPERTIES

      // END_USER_CODE-USER_colLoanGainRate_PROPERTIES
    },
    colMarketPrice: {
      name: "colMarketPrice",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Market Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMarketPrice_PROPERTIES

      // END_USER_CODE-USER_colMarketPrice_PROPERTIES
    },
    colPostedPrice: {
      name: "colPostedPrice",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Posted Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPostedPrice_PROPERTIES

      // END_USER_CODE-USER_colPostedPrice_PROPERTIES
    },
    colPounds: {
      name: "colPounds",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPounds_PROPERTIES

      // END_USER_CODE-USER_colPounds_PROPERTIES
    },
    colPricingDate: {
      name: "colPricingDate",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Pricing Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricingDate_PROPERTIES

      // END_USER_CODE-USER_colPricingDate_PROPERTIES
    },
    colPricingNum: {
      name: "colPricingNum",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Pricing Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricingNum_PROPERTIES

      // END_USER_CODE-USER_colPricingNum_PROPERTIES
    },

    colRebateRate: {
      name: "colRebateRate",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Rebate Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRebateRate_PROPERTIES

      // END_USER_CODE-USER_colRebateRate_PROPERTIES
    },
    colRebateVal: {
      name: "colRebateVal",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Rebate Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRebateVal_PROPERTIES

      // END_USER_CODE-USER_colRebateVal_PROPERTIES
    },

    colTxtElection: {
      name: "colTxtElection",
      type: "TextBoxWidget",
      colName: "colElection",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtElection_PROPERTIES

      // END_USER_CODE-USER_colTxtElection_PROPERTIES
    },
    colTxtEmpty: {
      name: "colTxtEmpty",
      type: "TextBoxWidget",
      colName: "colEmpty",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtEmpty_PROPERTIES

      // END_USER_CODE-USER_colTxtEmpty_PROPERTIES
    },
    colTxtLoanGain: {
      name: "colTxtLoanGain",
      type: "TextBoxWidget",
      colName: "colLoanGainRate",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtLoanGain_PROPERTIES

      // END_USER_CODE-USER_colTxtLoanGain_PROPERTIES
    },
    colTxtMarketPrice: {
      name: "colTxtMarketPrice",
      type: "TextBoxWidget",
      colName: "colMarketPrice",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtMarketPrice_PROPERTIES

      // END_USER_CODE-USER_colTxtMarketPrice_PROPERTIES
    },
    colTxtPostedPrice: {
      name: "colTxtPostedPrice",
      type: "TextBoxWidget",
      colName: "colPostedPrice",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtPostedPrice_PROPERTIES

      // END_USER_CODE-USER_colTxtPostedPrice_PROPERTIES
    },
    colTxtPounds: {
      name: "colTxtPounds",
      type: "TextBoxWidget",
      colName: "colPounds",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtPounds_PROPERTIES

      // END_USER_CODE-USER_colTxtPounds_PROPERTIES
    },
    colTxtPricingDate: {
      name: "colTxtPricingDate",
      type: "TextBoxWidget",
      colName: "colPricingDate",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtPricingDate_PROPERTIES

      // END_USER_CODE-USER_colTxtPricingDate_PROPERTIES
    },
    colTxtPricingNum: {
      name: "colTxtPricingNum",
      type: "TextBoxWidget",
      colName: "colPricingNum",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtPricingNum_PROPERTIES

      // END_USER_CODE-USER_colTxtPricingNum_PROPERTIES
    },
    colTxtRebateRate: {
      name: "colTxtRebateRate",
      type: "TextBoxWidget",
      colName: "colRebateRate",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtRebateRate_PROPERTIES

      // END_USER_CODE-USER_colTxtRebateRate_PROPERTIES
    },
    colTxtRebateVal: {
      name: "colTxtRebateVal",
      type: "TextBoxWidget",
      colName: "colRebateVal",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtRebateVal_PROPERTIES

      // END_USER_CODE-USER_colTxtRebateVal_PROPERTIES
    },
    colTxtStatus: {
      name: "colTxtStatus",
      type: "TextBoxWidget",
      colName: "gridcolumnwidget30",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtStatus_PROPERTIES

      // END_USER_CODE-USER_colTxtStatus_PROPERTIES
    },
    colWhsRcpts: {
      name: "colWhsRcpts",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Whs. Rcpts",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhsRcpts_PROPERTIES

      // END_USER_CODE-USER_colWhsRcpts_PROPERTIES
    },
    gridcolumnwidget30: {
      name: "gridcolumnwidget30",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget30_PROPERTIES

      // END_USER_CODE-USER_gridcolumnwidget30_PROPERTIES
    },
    lblBoxPostingDate: {
      name: "lblBoxPostingDate",
      type: "LabelWidget",
      parent: "grpboxMiddle",
      Label: "Current Price Posting Date is:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBoxPostingDate_PROPERTIES

      // END_USER_CODE-USER_lblBoxPostingDate_PROPERTIES
    },
    radioMarketRebate: {
      name: "radioMarketRebate",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpboxMiddle",
      Options:
        //"Market Rebate. The effective U.S. runner medium market price of $#.#####/lbs resulting in a rebate rate of $###.##/ton basis grade.:1,50-50 split of the Market Loan Gain.  The effective loan repayment rate is $###.##/ton resulting in a rebate rate of $###.##/ton.:2",
        `Market Rebate. The effective U.S. runner medium market price of $ 000 /lbs resulting in a rebate rate of $ 000 /ton basis grade.:1,xyz - abc split of the Market Loan Gain.  The effective loan repayment rate is $ 000 /ton resulting in a rebate rate of $ 000 /ton.:2`,
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioMarketRebate_PROPERTIES

      // END_USER_CODE-USER_radioMarketRebate_PROPERTIES
    },
    txtApprovedPrice: {
      name: "txtApprovedPrice",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "Approved Price Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtApprovedPrice_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtApprovedPrice_PROPERTIES
    },
    txtAvailableLbs: {
      name: "txtAvailableLbs",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "Available Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAvailableLbs_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtAvailableLbs_PROPERTIES
    },
    colTxtOptionRate: {
      name: "colTxtOptionRate",
      type: "TextBoxWidget",
      colName: "colOptionRate",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtRebateRate_PROPERTIES
      // END_USER_CODE-USER_colTxtRebateRate_PROPERTIES
    },
    colOptionRate: {
      name: "colOptionRate",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Option Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOptionRate_PROPERTIES

      // END_USER_CODE-USER_colOptionRate_PROPERTIES
    },
    colTxtOptionVal: {
      name: "colTxtOptionVal",
      type: "TextBoxWidget",
      colName: "colOptionVal",
      parent: "gridExistingPricing",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colTxtRebateVal_PROPERTIES
      // END_USER_CODE-USER_colTxtRebateVal_PROPERTIES
    },
    colOptionVal: {
      name: "colOptionVal",
      type: "GridColumnWidget",
      parent: "gridExistingPricing",
      Label: "Option Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOptionVal_PROPERTIES

      // END_USER_CODE-USER_colOptionVal_PROPERTIES
    },

    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtCrntDate: {
      name: "txtCrntDate",
      type: "TextBoxWidget",
      parent: "grpboxMiddle",
      ColSpan: "3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrntDate_PROPERTIES
      isEditable: false,
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtCrntDate_PROPERTIES
    },
    txtMarketEndDate: {
      name: "txtMarketEndDate",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "Market Period End Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketEndDate_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtMarketEndDate_PROPERTIES
    },
    txtMultiPricing: {
      name: "txtMultiPricing",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "Multi Pricing:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMultiPricing_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtMultiPricing_PROPERTIES
    },
    txtOverride: {
      name: "txtOverride",
      type: "TextBoxWidget",
      parent: "grpboxMiddle",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverride_PROPERTIES

      // END_USER_CODE-USER_txtOverride_PROPERTIES
    },
    lblMrktRebate: {
      name: "lblMrktRebate",
      type: "LabelWidget",
      parent: "grpboxMiddle",
      // Label: "at $000.00/net ton basis grade. This price reflects the current farmer stock price of $000.00 less the original option paid of $000.00.",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMrktRebate_PROPERTIES

      // END_USER_CODE-USER_lblMrktRebate_PROPERTIES
    },


    txtPoundsPriced: {
      name: "txtPoundsPriced",
      type: "TextBoxWidget",
      parent: "grpboxMiddle",
      Label: "Pounds to be Priced:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPoundsPriced_PROPERTIES

      // END_USER_CODE-USER_txtPoundsPriced_PROPERTIES
    },
    txtRemainingLbs: {
      name: "txtRemainingLbs",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "Remaining Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemainingLbs_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtRemainingLbs_PROPERTIES
    },
    txtUnApprovedPriced: {
      name: "txtUnApprovedPriced",
      type: "TextBoxWidget",
      parent: "grpBoxTop",
      Label: "UN-Approved Priced Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUnApprovedPriced_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtUnApprovedPriced_PROPERTIES
    },
    grpBoxTop: {
      name: "grpBoxTop",
      type: "GroupBoxWidget",
      parent: "FlexibleMarketPricings",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "8",
      // START_USER_CODE-USER_grpBoxTop_PROPERTIES

      // END_USER_CODE-USER_grpBoxTop_PROPERTIES
    },
    grpboxMiddle: {
      name: "grpboxMiddle",
      type: "GroupBoxWidget",
      parent: "FlexibleMarketPricings",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpboxMiddle_PROPERTIES

      // END_USER_CODE-USER_grpboxMiddle_PROPERTIES
    },
    gridExistingPricing: {
      name: "gridExistingPricing",
      type: "GridWidget",
      parent: "FlexibleMarketPricings",
      gridCellsOrder:
        "colTxtPricingDate,colTxtPricingNum,colTxtStatus,colBtnWhsRcpts,coltxtFV95_1007,colTxtPounds,colTxtElection,colTxtMarketPrice,colTxtRebateRate,colTxtPostedPrice,colTxtLoanGain,colTxtRebateVal,colTxtOptionVal,colTxtOptionRate,colTxtEmpty",
      Label: "Existing Pricings:",
      Pagination: false,
      Height: "",
      Width: "",
      isExpandable: true,
      defaultExpand: false,
      needGridChange: true,
      expandedCompTemplate: ({ data }) => (

        <div>

          {data.children ? (

            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">

              {

                data.children.map((elem, i) =>
                  <div className={"row ExpandRow" + " rdt_ExpanderRow" + i} key={"expandRow" + i}>
                    <div className="extraRowCell_2 ch-3 rdt_TableCell" key="expandCell1">
                      { }
                    </div>
                    <div className="extraRowCell_3 ch-3 " key="expandCell2">
                      { }
                    </div>
                    <div className="extraRowCell_4 ch-3 " key="expandCell3">
                      { }
                    </div>
                    <div className="extraRowCell_5 ch-3" key="expandCell4">
                      {elem.colBtnWhsRcpts}
                    </div>
                    <div className="extraRowCell_6 ch-3 " key="expandCell5">
                      {elem.colTxtPounds}
                    </div>
                    <div className="extraRowCell_7 ch-3 " key="expandCell6">
                      { }
                    </div>
                    <div className="extraRowCell_8 ch-3 " key="expandCell7">
                      { }
                    </div>
                    <div className="extraRowCell_9 ch-3 " key="expandCell8">
                      { }
                    </div>
                    <div className="extraRowCell_10 ch-3 " key="expandCell9">
                      { }
                    </div>
                    <div className="extraRowCell_11 ch-3 " key="expandCell10">
                      { }
                    </div>
                    <div className="extraRowCell_12 ch-3 " key="expandCell11">
                      {elem.colTxtRebateVal}
                    </div>
                    <div className="extraRowCell_13 ch-3 " key="expandCell12">
                      { }
                    </div>
                    <div className="extraRowCell_14 ch-3 " key="expandCell13">
                      { }
                    </div>
                    <div className="extraRowCell_15 ch-3 " key="expandCell14">
                      {elem.colTxtEmpty}
                    </div>
                  </div>
                )}
            </div>
          ) : null}
        </div>
      )
      // START_USER_CODE-USER_gridExistingPricing_PROPERTIES

      // END_USER_CODE-USER_gridExistingPricing_PROPERTIES
    },
    grpBoxBottom: {
      name: "grpBoxBottom",
      type: "GroupBoxWidget",
      parent: "FlexibleMarketPricings",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpBoxBottom_PROPERTIES

      // END_USER_CODE-USER_grpBoxBottom_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#FlexMktPrint": {},

        "ContractManagement#FlexMktVoid": {},
        "ContractManagement#FlexMktSign": {}
      },
      REVERSE: {
        "ContractManagement#FlexMktPrint": {},

        "ContractManagement#FlexMktVoid": {},
        "ContractManagement#FlexMktSign": {}
      },
    },

  };

  let _buttonServices = { FlexMktPrint: {}, FlexMktVoid: {} };
  let _buttonNavigation = {
    btnVoid: {
      DEFAULT: ["ContractManagement#FlexMktVoid#DEFAULT#true#Click"],
    },
    btnPrntPrcng: {
      //DEFAULT: ["ContractManagement#FlexMktPrint#DEFAULT#true#Click"],
    },
    btnPrintWork: {
      DEFAULT: ["ContractManagement#FlexMktPrint#DEFAULT#true#Click"],
    },
    btnSign: {
      DEFAULT: ["ContractManagement#FlexMktSign#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };




  // Form Load Variables


  // Security Roles Parameters
  const [mbSecurityInquiry, setmbSecurityInquiry] = useState(false);
  const [mbSecurityCreateSign, setmbSecurityCreateSign] = useState(false)
  const [mbSecurityVoidPending, setmbSecurityVoidPending] = useState(false)
  const [mbSecurityApprove, setmbSecurityApprove] = useState(false)
  const [mbSecurityVoidSignedApproved, setmbSecurityVoidSignedApproved] = useState(false)
  const [mbSecurityOverridePrice, setmbSecurityOverridePrice] = useState(false)




  let mstrContractNumber = commonContext.getContractProfile_ContractNumber;
  let contractType = commonContext.getContractProfile_ContractType;
  let mstrContractType = contractType === "M" ? "FlexMarket" : contractType === "L" ? "Floor" : ""
  let mstrHarvestDate = commonContext.getContractProfile_HarvestDate;


  //Global Variables
  var isFormValid

  var dateTime = moment(new Date()).format('MM/DD/YYYY');


  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

  }, []);
  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    hide(thisObj, 'txtOverride')
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

  }, []);

  useEffect(() => {
    let sign_reload = getData(thisObj, 'screen_reload')
    if (sign_reload == true) {
      GetBFillPricings()
      setData(thisObj, 'screen_reload', false)
    }

    let print_reload = getData(thisObj, 'screen_reload_print')
    if (print_reload == true) {
      GetBFillPricings()
      setData(thisObj, 'screen_reload_print', false)
    }

    let void_reload = getData(thisObj, "flexpricing")
    if (void_reload == true) {
      GetBFillPricings()
      setData(thisObj, "flexpricing", false)
    }

    let approve_reload = getData(thisObj, "approveBtnReload")
    if (approve_reload == true) {
      GetBFillPricings()
      setData(thisObj, "approveBtnReload", false)
    }

  }, [getData(thisObj, 'screen_reload'), getData(thisObj, "flexpricing"), getData(thisObj, 'screen_reload_print'), getData(thisObj, "approveBtnReload")]);

  const FormLoad = () => {

    let checkOverrideRebateValue = getValue(thisObj, 'chkboxOverrideRebate');
    if (checkOverrideRebateValue == false) {
      hide(thisObj, 'txtOverride')
    }

    hide(thisObj, 'chkboxOverrideRebate')
    let buyingPointId = getData(thisObj, "buyingPointId")

    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '004', 'I', buyingPointId)
      .then(response => {
        let mbSecurityInquiryResp = response
        if (mbSecurityInquiryResp[0].permission === "Y") {
          setmbSecurityInquiry(true)
        } else {
          setmbSecurityInquiry(false)
        }
      })


    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '004', 'U', buyingPointId)
      .then(response => {
        let mbSecurityCreateSignResp = response
        if (mbSecurityCreateSignResp[0].permission === "Y") {
          setmbSecurityCreateSign(true)
          setData(thisObj, "SecurityCreateSign", true)
        } else {
          setmbSecurityCreateSign(false)
          setData(thisObj, "SecurityCreateSign", false)
        }
      })

    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '004', 'D', buyingPointId)
      .then(response => {
        let mbSecurityVoidPendingResp = response
        if (mbSecurityVoidPendingResp[0].permission === "Y") {
          setmbSecurityVoidPending(true)
        } else {
          setmbSecurityVoidPending(false)
        }
      })

    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '005', 'U', buyingPointId)
      .then(response => {
        let mbSecurityApproveResp = response
        if (mbSecurityApproveResp[0].permission === "Y") {
          setmbSecurityApprove(true)
        } else {
          setmbSecurityApprove(false)
        }
      })

    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '005', 'D', buyingPointId)
      .then(response => {
        let mbSecurityVoidSignedApprovedResp = response
        if (mbSecurityVoidSignedApprovedResp[0].permission === "Y") {
          setmbSecurityVoidSignedApproved(true)
        } else {
          setmbSecurityVoidSignedApproved(false)
        }
      })

    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '006', 'U', buyingPointId)
      .then(response => {
        let mbSecurityOverridePriceResp = response
        if (mbSecurityOverridePriceResp[0].permission === "Y") {
          setmbSecurityOverridePrice(true)
        } else {
          setmbSecurityOverridePrice(false)
        }
      })

    GetBFillPricings();

    if (mstrContractType === "FlexMarket") {
      thisObj.setState(current => {
        return {
          ...current,
          headerData: {
            scrName: "Flexible Market Pricings",
            scrCode: "PN1010G",
          },
        }
      })
    }
    if (mstrContractType === "Floor") {
      thisObj.setState(current => {
        return {
          ...current,
          headerData: {
            scrName: "Floor Contract Pricings"
          },
        }
      })
    }


    if (mstrHarvestDate == "/  /" || mstrHarvestDate == "" || mstrHarvestDate == null || mstrHarvestDate == undefined) {
      mstrHarvestDate = "12/31/9999"
    }

  }

  function ConvertToDate(str) {

    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }

  const validateOverrideTextBox = (val) => {
    var res = ''
    for (var i = 0; i < val.length; i++) {
      if ((val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) || (val[i].charCodeAt(0) <= 110) || (val[i].charCodeAt(0) >= 97 && val[i].charCodeAt(0) <= 105) || (val[i].charCodeAt(0) <= 190)) {
        res += val[i]
      }
    }
    return res;
  }

  const validatePoundsPricedTextBox = (val) => {
    var res = ''
    for (var i = 0; i < val.length; i++) {
      if ((val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) || (val[i].charCodeAt(0) >= 97 && val[i].charCodeAt(0) <= 105)) {
        res += val[i]
      }
    }
    return res;
  }

  const ongridExistingPricingRowSelect = (rowSelected) => {

    let row = getSelectedRowNumber(thisObj,"gridExistingPricing")
     if(row===0){
        let highlight = document.getElementsByClassName("activeRow")
        if (highlight.length > 0){highlight[0].classList.remove("activeRow")}
    document.getElementById("gridExistingPricingCollapse").firstChild.firstChild.firstChild.lastChild.firstChild.className+=" activeRow"}

    hide(thisObj, 'btnSign')
    hide(thisObj, 'btnApprv')
    hide(thisObj, 'btnVoid')
    hide(thisObj, 'btnPrntPrcng')
    hide(thisObj, 'btnRescan')
    let data1 = rowSelected;
    if (data1.selectedRows) {
      data1 = data1.selectedRows[0]
    }
    if (data1 != undefined) {
      setData(thisObj, 'rowSelected', data1);

      let selectedRowstatus = data1.colTxtStatus
      switch (selectedRowstatus) {
        case 'Pending':
          if (mbSecurityCreateSign === true)
            show(thisObj, 'btnSign')
          if (mbSecurityVoidPending === true)
            show(thisObj, 'btnVoid')
          if (mbSecurityInquiry === true)
            show(thisObj, 'btnPrntPrcng')
          break;
        case 'Signed':
          if (mbSecurityApprove === true) {
            show(thisObj, 'btnApprv')
            show(thisObj, 'btnRescan')
          }
          if (mbSecurityVoidSignedApproved === true)
            show(thisObj, 'btnVoid')
          if (mbSecurityInquiry === true)
            show(thisObj, 'btnPrntPrcng')
          if (mbSecurityCreateSign === true)
            show(thisObj, 'btnRescan')
          else {
            show(thisObj, 'btnVoid')
          }
          break;
        case 'Approved':
          if (mbSecurityVoidSignedApproved === true) {
            show(thisObj, 'btnVoid')
            show(thisObj, 'btnRescan')
          }

          if (mbSecurityInquiry === true)
            show(thisObj, 'btnPrntPrcng')
          else {
            show(thisObj, 'btnVoid')
          }
          break;
      }
    }

  };
  thisObj.ongridExistingPricingRowSelect = ongridExistingPricingRowSelect;

  const displayScannedPE = async () => {

    let sUploadStatus
    let sApprovedUse
    var sVendor

    let rowDetails = getData(thisObj, 'rowSelected')
    let suffix = rowDetails.pricing_suffix
    let mstrContractNumber = rowDetails.contract_num

    // get status
    let resspp = await ContractManagementService.RetrieveFlexMarketPriceElectionDetails(mstrContractNumber, suffix)
    let electionData = resspp;
    if (resspp.length == 0) {
      sUploadStatus = ""
      sApprovedUse = ""
      sVendor = ""
    } else if (resspp.length > 0) {
      sUploadStatus = electionData[0].upload_status
      sApprovedUse = electionData[0].approver_id
      sVendor = electionData[0].vendor
    }
    else {
      showMessage(thisObj, "Problem retrieving contract pricing status.", false)
      return false
    }

    
    //  'Waiting for server applicaiton to process the contract before approving
    if (sUploadStatus.toUpperCase() === "I") {
      showMessage(thisObj, "Invalid file was uploaded.  Please rescan the signed contract before it can be approved?", false)
      return false
    }

    // 'Server app has processed it
    if (sUploadStatus == undefined) {
      showMessage(thisObj, "Contract has not been processed.  Try again in about 5 minutes?", false)
      return false
    }

    // 'C stands for complete.  Server app has processed it
    if (sUploadStatus.toUpperCase() !== "C") {
      showMessage(thisObj, "Contract has not been processed.  Try again in about 5 minutes?", false)
      return false
    }
   
    let fileName = electionData[0]?.test_dir + electionData[0]?.upload_file_name
    window.open(fileName)
    return false;
  }


  const onbtnApprvClick = async () => {
    let sUploadStatus
    let sApprovedUse
    var sVendor
    let sSendEmail = ""
    sSendEmail = "N"
    var sEmail = ""

    try {

      let rowDetails = getData(thisObj, 'rowSelected')
      let bFillValue = getData(thisObj, 'isBfillPricing')
      let vsfResults = []
      vsfResults.push(rowDetails)

      let suffix = rowDetails.pricing_suffix
      let mstrContractNumber = rowDetails.contract_num
      // a row must be selected
      if (vsfResults.length < 1) {
        showMessage(thisObj, "Select a Pricing to approve", false)
        return
      }
      if (rowDetails.contract_num !== mstrContractNumber) {
        showMessage(thisObj, "Select a Pricing to approve", false)
        return
      }
      // Contract Pricing must be in signed status.
      if (rowDetails.colTxtStatus !== 'Signed') {
        showMessage(thisObj, "Contract Pricing must be in Signed status", false)
        return
      }

      // let apprvResp = await ContractManagementService.UpdateFlexMarketPriceElectionApproveDetails(mstrContractNumber, suffix, "Y")
      // if (apprvResp.result != undefined && apprvResp.result != "") {
      //   if(bFillValue){
      //     setData(thisObj, 'approveBtnReload', true)
      //   } else {
      //     return ;
      //   }
      // }

      // get status
      let resspp = await ContractManagementService.RetrieveFlexMarketPriceElectionDetails(mstrContractNumber, suffix)
      let electionData = resspp;
      if (resspp.length == 0) {
        sUploadStatus = ""
        sApprovedUse = ""
        sVendor = ""
      } else if (resspp.length > 0) {
        sUploadStatus = electionData[0].upload_status
        sApprovedUse = electionData[0].approver_id
        sVendor = electionData[0].vendor
      }
      else {
        showMessage(thisObj, "Problem retrieving contract pricing status.", false)
        return
      }

      //  'Waiting for server applicaiton to process the contract before approving
      if (sUploadStatus.toUpperCase() === "I") {
        showMessage(thisObj, "Invalid file was uploaded.  Please rescan the signed contract before it can be approved?", false)
        return
      }

      // 'C stands for complete.  Server app has processed it
      if (sUploadStatus.toUpperCase() !== "C" || sUploadStatus == undefined) {
        showMessage(thisObj, "Contract has not been processed.  Try again in about 5 minutes?", false)
        return
      }


      let response = await ContractManagementService.RetrieveUserSignatureDetailsFlexMktPricing()
      if (response != null && response != undefined) {
        let signatureData = response;
        setData(thisObj, 'approveSignatureData', signatureData)
        if (signatureData != null && signatureData != undefined && signatureData.length > 0) {
          if ((signatureData[0].user_id).trim() !== sessionStorage.getItem("userid")) {
            showMessage(thisObj, "Cannot find your approval signature.  Use Signature Setup to setup your information.", false)
            return
          }
          else {
            if (signatureData[0].name == "" && signatureData[0].image == "" && signatureData[0].title == "") {
              showMessage(thisObj, "To approve Price Elections, you must have name, title, and signature.  Use Signature Setup to setup your information.", false)
              return
            }
          }
        }
      }

      if (confirm("Do you want to email approved contract to producer?") == true) {
        let buyingPtId = getData(thisObj, "buyingPointId")
        let vendorEmailIndicator = false
        let rpess = await ContractManagementService.RetrieveVendorEmailDetails(buyingPtId, null, null, sVendor, null, null)

        if (rpess != null && rpess != undefined && rpess.length > 0) {
          let data = rpess[0]
          if (data.vendorEmail1 !== "" && data.vendorEmail1 != undefined) {
            vendorEmailIndicator = true
          }
          if (data.vendorEmail2 !== "" && data.vendorEmail2 != undefined) {
            vendorEmailIndicator = true
          }
          if (data.vendorEmail3 !== "" && data.vendorEmail3 != undefined) {
            vendorEmailIndicator = true
          }
        } else {
          vendorEmailIndicator = false
        }

        if (vendorEmailIndicator) {
          sSendEmail = "Y"
          let sigData = getData(thisObj, 'approveSignatureData')
          if (sigData != null && sigData != undefined && sigData.length > 0) {
            if (sigData[0].user_email = "") {
              showMessage(thisObj, "Cannot find your email to use as the sender.", false)
              return
            }
          } else {
            showMessage(thisObj, "Cannot find producer's email address.  Contract will not be sent to producer.", false)
            sSendEmail = "N"
            return
          }
        }
      } else {
        sSendEmail = "N"
      }

      let apprvResp = await ContractManagementService.UpdateFlexMarketPriceElectionApproveDetails(mstrContractNumber, suffix, sSendEmail)
      GetBFillPricings()
      if (apprvResp.result != undefined && apprvResp.result != "") {
        if(getData(thisObj, 'isBfillPricing')){
          setData(thisObj, 'approveBtnReload', true)
        } else {
          return ;
        }
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnApprv click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnApprvClick = onbtnApprvClick;


  // const GetVendorEmail = async(sVendor) => {
  //   let buyingPtId = getData(thisObj, "buyingPointId")
  //   setData(thisObj, 'vendorEmailIndicator', false)
  //   // let rpess = await ContractManagementService.RetrieveVendorEmailDetails(buyingPtId, null, null, sVendor, null, null)

  //       if (rpess != null && rpess != undefined && rpess.length > 0) {
  //         let data = rpess[0]
  //         if (data.vendorEmail1 !== "" && data.vendorEmail1 != undefined) {
  //           setData(thisObj, 'vendorEmailIndicator', true)
  //         }
  //         if (data.vendorEmail2 !== "" && data.vendorEmail2 != undefined) {
  //           setData(thisObj, 'vendorEmailIndicator', true)
  //         }
  //         if (data.vendorEmail3 !== "" && data.vendorEmail3 != undefined) {
  //           setData(thisObj, 'vendorEmailIndicator', true)
  //         }
  //       } else {
  //         setData(thisObj, 'vendorEmailIndicator', false)
  //       }
  // }

  const onbtnVoidClick = () => {
    try {
      let rowDetails = getData(thisObj, 'rowSelected')
      if (rowDetails.length < 1) {
        showMessage(thisObj, "Select a Pricing to void", false)
        return
      }

      if (rowDetails.contract_num !== mstrContractNumber) {
        showMessage(thisObj, "Select a Pricing to void", false)
        return
      }

      if (rowDetails.colTxtStatus == 'Voided') {
        showMessage(thisObj, "Contract Pricing has been voided", false)
        return
      }

      setData(thisObj, "frmFlexMktPricingDetailsData", rowDetails)
      goTo(thisObj, "ContractManagement#FlexMktVoid#DEFAULT#true#Click")

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnPrintWork click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnVoidClick = onbtnVoidClick;


  const onbtnPrintWorkClick =  () => {

    try {
      let rowDetails = []; //getData(thisObj, 'rowSelected')
      if (rowDetails == null || rowDetails == undefined) {
        rowDetails = getValue(thisObj, "gridExistingPricing")
      }
      commonContext.setContractProfile_ContractNumber(mstrContractNumber);     // will set Contract Number
      commonContext.setFlexMktPrint_PrintType('WORKSHEET');                    // will set Print Type
      commonContext.setFlexMktPrint_PriceSuffix(rowDetails.pricing_suffix); // will set Price Suffix
      commonContext.setFlexMktPrint_PriceLine('2')                             // will set Price Line
      commonContext.setFlexMktPrint_ContractPricingList(rowDetails)            // will set the selected Pricing List
      goTo(thisObj, "ContractManagement#FlexMktPrint#DEFAULT#true#Click")
      if (mstrContractType === "FlexMarket") {
        //If frmFlexMktPrint.PrintWorkSheet(mstrContractNumber, True) Then frmFlexMktPrint.Show vbModal
      }

      if (mstrContractType === "Floor") {
        //If frmFlexMktPrint.PrintFloorWorkSheet(mstrContractNumber) Then frmFlexMktPrint.Show vbModal
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnPrintWork click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnPrintWorkClick = onbtnPrintWorkClick;



  const onbtnRescanClick = () => {
    let uploadStatus
    let approvedUser

    try {

      let rowDetails = getData(thisObj, 'rowSelected')
      if (rowDetails === null || rowDetails === undefined) {
        showMessage(thisObj, "Select a Pricing to rescan", false)
        return
      } else if (rowDetails.colTxtPricingNum.slice(0, 6) !== mstrContractNumber) {
        showMessage(thisObj, "Select a Pricing to rescan", false)
        return
      }

      if (rowDetails.colTxtStatus !== "Signed" && rowDetails.colTxtStatus !== "Approved") {
        showMessage(thisObj, "Contract Pricing must be in Signed or Approved status", false)
        return
      }

      if (rowDetails.colTxtStatus === "Approved") {
        let suffix = rowDetails.colSuffixNam;
        let resp = ContractManagementService.RetrieveFlexMarketPriceElectionDetails(mstrContractNumber, suffix)
        if (resp != null && resp != undefined) {
          if (resp.length > 0) {
            uploadStatus = resp[0].upload_status
            approvedUser = resp[0].approver_id
          }
          else {
            showMessage(thisObj, "Problem retrieving contract pricing status.", false)
          }
        }
        // ContractManagementService.RetrieveFlexMarketPriceElectionDetails(mstrContractNumber, suffix)
        //   .then(resp => {
        //     let electionData = resp
        //     if (resp.length > 0) {
        //       uploadStatus = electionData[0].upload_status
        //       approvedUser = electionData[0].approver_id
        //     }
        //     else {
        //       showMessage(thisObj, "Problem retrieving contract pricing status.", false)
        //     }

        //   })
        if ((uploadStatus.toUpperCase() === 'C') && (uploadStatus.toUpperCase() === 'I')) {
          showMessage(thisObj, "Contract has not been processed.  Try again in about 5 minutes?", false)
        }



        let response = ContractManagementService.RetrieveUserSignatureDetailsFlexMktPricing()
        if (response != null && response != undefined) {
          let signatureData = response;
          setData(thisObj, 'rescanSignatureData', signatureData)
          if (signatureData != null && signatureData != undefined && signatureData.length < 1) {
            if ((signatureData[0].user_id).trim() !== sessionStorage.getItem("userid")) {
              showMessage(thisObj, "Cannot find your approval signature.  Use Signature Setup to setup your information.", false)
              return
            }
            else {
              if (signatureData[0].name == "" && signatureData[0].image == "" && signatureData[0].title == "") {
                showMessage(thisObj, "To approve Price Elections, you must have name, title, and signature.  Use Signature Setup to setup your information.", false)
                return
              }
            }
          }
        }
      }

      //   ContractManagementService.RetrieveUserSignatureDetailsFlexMktPricing().then(response => {
      //     let signatureData = response;
      //     setData(thisObj,'rescanSignatureData',signatureData)
      //     if(signatureData != null && signatureData && undefined && signatureData.length < 1){
      //     if (signatureData[0].user_id !== user_id) {
      //       showMessage(thisOb, "Cannot find your approval signature.  Use Signature Setup to setup your information.", false)
      //       return
      //     }
      //     else {
      //       if (signatureData[0].name == "" && signatureData[0].image == "" && signatureData[0].title == "") {
      //         showMessage(thisObj, "To approve Price Elections, you must have name, title, and signature.  Use Signature Setup to setup your information.", false)
      //         return
      //       }
      //     }
      //   }
      // });
      let array = []
      array.push({ mstrContractNumber: mstrContractNumber, ContractAction: "R", lblContractPricing: rowDetails.colTxtPricingNum, PriceSuffix: rowDetails.colSuffixNam })
      // array.push({ mstrContractNumber: mstrContractNumber, ContractAction: "S", lblContractPricing: rowDetails.colTxtPricingNum })
      setData(thisObj, 'frmFlexMktSigntdata', array);
      goTo(thisObj, "ContractManagement#FlexMktSign#DEFAULT#true#Click")

      // frmFlexMktSign.Show vbModal
      // bsuccess = frmFlexMktSign.ScanCompleted
      // Unload frmFlexMktSign
      // Set frmFlexMktSign = Nothing

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnRescan click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnRescanClick = onbtnRescanClick;


  const onbtnPrntPrcngClick = async () => {
    try {
      let data = getData(thisObj, 'rowSelected')
      if (!await btnPrintContPricing()) {
        return false
      }
      setData(thisObj, 'pricingNumber', data.colTxtPricingNum)
      goTo(thisObj, "ContractManagement#FlexMktPrint#DEFAULT#true#Click")
      return true;


      // let rowDetails = getData(thisObj, 'rowSelected')

      // commonContext.setContractProfile_ContractNumber(mstrContractNumber);     // will set Contract Number
      // commonContext.setFlexMktPrint_PrintType('PRICING CONTRACT');             // will set Print Type
      // commonContext.setFlexMktPrint_PriceSuffix(rowDetails.pricing_suffix); // will set Price Suffix
      // commonContext.setFlexMktPrint_PriceLine('2')                             // will set Price Line

      // if (rowDetails === null || rowDetails === undefined) {
      //   showMessage(thisObj, "Select a Pricing to print", false)
      //   return
      // } else if (rowDetails.colTxtPricingNum.slice(0, 6) !== mstrContractNumber) {
      //   showMessage(thisObj, "Select a Pricing to rescan", false)
      //   return
      // }


      // switch (rowDetails.colTxtStatus) {
      //   case "Peniding":
      //     if (mstrContractType === "FlexMarket") {
      //       // If frmFlexMktPrint.PrintPriceElection(mstrContractNumber, mstrPriceLine, Trim(vsfResults.TextMatrix(vsfResults.Row, COL_KEY_ID))) Then frmFlexMktPrint.Show vbModal
      //     }
      //     if (mstrContractType === "Floor") {
      //       // If frmFlexMktPrint.PrintFloorPricing(mstrContractNumber, mstrPriceLine, Trim(vsfResults.TextMatrix(vsfResults.Row, COL_KEY_ID))) Then frmFlexMktPrint.Show vbModal
      //     }
      //     break;
      //     case ("Signed" || "Approved"):
      //     await displayScannedPE()
      //     break;
      //     case "Voided":
      //       showMessage(thisObj, "Contract Pricing has been void.", false)
      //       default:
      //     break;
      //   }
      // goTo(thisObj, "ContractManagement#FlexMktPrint#DEFAULT#true#Click")

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnPrntPrcng click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnPrntPrcngClick = onbtnPrntPrcngClick;

  const btnPrintContPricing = async () => {
    let rowDetails = getData(thisObj, 'rowSelected')

    commonContext.setContractProfile_ContractNumber(mstrContractNumber);     // will set Contract Number
    commonContext.setFlexMktPrint_PrintType('PRICING CONTRACT');             // will set Print Type
    commonContext.setFlexMktPrint_PriceSuffix(rowDetails.pricing_suffix); // will set Price Suffix
    commonContext.setFlexMktPrint_PriceLine('2')                             // will set Price Line

    if (rowDetails === null || rowDetails === undefined) {
      showMessage(thisObj, "Select a Pricing to print", false)
      return false;
    } else if (rowDetails.colTxtPricingNum.slice(0, 6) !== mstrContractNumber) {
      showMessage(thisObj, "Select a Pricing to rescan", false)
      return false;
    }


    switch (rowDetails.colTxtStatus) {
      case "Peniding":
        // if (mstrContractType === "FlexMarket") {
        //   // If frmFlexMktPrint.PrintPriceElection(mstrContractNumber, mstrPriceLine, Trim(vsfResults.TextMatrix(vsfResults.Row, COL_KEY_ID))) Then frmFlexMktPrint.Show vbModal
        // }
        // if (mstrContractType === "Floor") {
        //   // If frmFlexMktPrint.PrintFloorPricing(mstrContractNumber, mstrPriceLine, Trim(vsfResults.TextMatrix(vsfResults.Row, COL_KEY_ID))) Then frmFlexMktPrint.Show vbModal
        // }
        break;
      case "Signed":
      case "Approved":
        let resp = await displayScannedPE()
        if(!resp){
          return false;
        }
        break;
      case "Voided":
        showMessage(thisObj, "Contract Pricing has been void.", false)
      default:
        break;
    }
    return true
  }


  async function SignData() {
    let rowDetails = getData(thisObj, 'rowSelected')

    setData(thisObj, 'PriceSuffix', rowDetails.colSuffixNam)
    if (rowDetails === null || rowDetails === undefined) {
      showMessage(thisObj, "Select a Pricing to sign", false)
      return
    }
    else if (rowDetails.colTxtPricingNum.slice(0, 6) !== mstrContractNumber) {
      showMessage(thisObj, "Select a Pricing to sign", false)
      return
    }

    if (rowDetails.colTxtStatus !== "Pending") {
      showMessage(thisObj, "Contract Pricing must be in Pending status", false)
      return
    }
    let array = []
    array.push({ mstrContractNumber: mstrContractNumber, ContractAction: "S", lblContractPricing: rowDetails.colTxtPricingNum })
    setData(thisObj, 'frmFlexMktSigntdata', array);

    let response = await ContractManagementService.RetrievePeanutStaticValues()
    let data = response;
    if (data[0].pps_defaults.B9_default_overrides != undefined) {
      if (data[0].pps_defaults.ej_default_overrides.contract_scan_sw = "OFF") {
        let rowDetails = getData(thisObj, 'rowSelected')
        let suffix = rowDetails.pricing_suffix
        const flexmktpriceelectionsign = ContractManagementService.RetrieveFlexMarketPriceElectionSignDetails(mstrContractNumber, suffix, null)
        if (flexmktpriceelectionsign[0] == null || flexmktpriceelectionsign[0] == "" || flexmktpriceelectionsign[0] == undefined) {
          showMessage(thisObj, "Cannot sign Price Election.", false)
        }
        return
      }
    }
  }

  const onbtnSignClick = async () => {
    try {
      await SignData()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnSign click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnSignClick = onbtnSignClick;


  const onbtnCreateClick = async () => {
    try {
      bFormValid()
      if (isFormValid !== true) {
        return false;
      }

      let contractnum = mstrContractNumber
      let pricing_line = "2"
      let priced_pounds = getValue(thisObj, 'txtPoundsPriced')
      let override_price = getValue(thisObj, 'txtOverride')
      let market_election
      let radioBtnValue = getValue(thisObj, 'radioMarketRebate')
      let bFillValue = getData(thisObj, 'isBfillPricing')

      if (mstrContractType == "FlexMarket" && radioBtnValue == '1') { /// need this optRebate value from bfillvalid
        market_election = "REBATE"
      }
      if (mstrContractType == "FlexMarket" && radioBtnValue == '2') {  /// need this optRebate value from bfillvalid
        market_election = "GAIN"
      }
      if (mstrContractType == "Floor") {
        market_election = "FLOOR"
      }

      let dataObj = {

        override_price: override_price == '' ? 0 : override_price,
        pricing_date: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
        priced_pounds: priced_pounds,
        market_election: market_election,
        uid: sessionStorage.getItem("userid"),

      }
      let response = await ContractManagementService.Createflexmarket(null, contractnum, pricing_line, dataObj)
      if (response.result == undefined || response.result == "") {
        showMessage(
          thisObj,
          "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."
        );
        return false;
      }

      if (bFillValue !== true) {
        return false;
      }
      setData(thisObj, 'pricingNumber', response.result[0].contract_num + response.result[0].pricing_suffix);             // will set Print Type
      if (mstrContractType == "FlexMarket") {
        commonContext.setContractProfile_ContractNumber(mstrContractNumber);     // will set Contract Number
        commonContext.setFlexMktPrint_PrintType('PRICING CONTRACT');             // will set Print Type
        commonContext.setFlexMktPrint_PriceSuffix(response.result[0].pricing_suffix); // will set Price Suffix
        commonContext.setFlexMktPrint_PriceLine(response.result[0].pricing_line)           // will set Price Line
        setValue(thisObj, 'txtPoundsPriced', '');
        let chkboxOverrideRebate = getValue(thisObj, 'chkboxOverrideRebate')
        setValue(thisObj, 'txtOverride', "");
        if (chkboxOverrideRebate === true) {
          hide(thisObj, 'txtOverride');
          setValue(thisObj, 'chkboxOverrideRebate', false);
        }
        GetBFillPricings();
        goTo(thisObj, "ContractManagement#FlexMktPrint#DEFAULT#true#Click");
        return true;
      }
      if (mstrContractType == "Floor") {
        commonContext.setContractProfile_ContractNumber(mstrContractNumber);     // will set Contract Number
        commonContext.setFlexMktPrint_PrintType('PRICING CONTRACT');             // will set Print Type
        commonContext.setFlexMktPrint_PriceSuffix(response.result[0].pricing_suffix); // will set Price Suffix
        commonContext.setFlexMktPrint_PriceLine(response.result[0].pricing_line)           // will set Price Line
        goTo(thisObj, "ContractManagement#FlexMktPrint#DEFAULT#true#Click");
        setValue(thisObj, 'txtPoundsPriced', '');
        let chkboxOverrideRebate = getValue(thisObj, 'chkboxOverrideRebate')
        setValue(thisObj, 'txtOverride', "");
        if (chkboxOverrideRebate === true) {
          hide(thisObj, 'txtOverride');
          setValue(thisObj, 'chkboxOverrideRebate', false);
        }
        GetBFillPricings();
        return true;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error on Create button click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCreateClick = onbtnCreateClick;



  const ontxtPoundsPricedChange = () => {
    try {
      setValue(thisObj, "txtPoundsPriced", validatePoundsPricedTextBox(txtPoundsPriced.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtPoundsPriced change event"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtPoundsPricedChange = ontxtPoundsPricedChange

  const ontxtOverrideChange = () => {
    try {
      setValue(thisObj, "txtOverride", validateOverrideTextBox(txtOverride.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtOverride change event"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtOverrideChange = ontxtOverrideChange

  const bFormValid = () => {

    isFormValid = false

    if (mstrContractType !== "FlexMarket" && mstrContractType !== "Floor") {
      showMessage(thisObj, "Contract Type is not valid for creating rebate pricings.", false)
      isFormValid = false
      return
    }

    if (isNaN(txtPoundsPriced.value) || txtPoundsPriced.value == undefined || txtPoundsPriced.value == "") {
      showMessage(thisObj, "Pounds to be Priced must be numeric.", false)
      txtPoundsPriced.value = ""
      isFormValid = false
      return
    }

    if (parseInt(txtPoundsPriced.value) <= 0) {
      showMessage(thisObj, "A Valid Pounds to be Priced is required.", false)
      txtPoundsPriced.value = ""
      isFormValid = false
      return
    }

    if (parseInt(txtPoundsPriced.value) > parseInt(txtRemainingLbs.value)) {
      showMessage(thisObj, "Trying to price more pounds than are remaining avalable.", false)
      isFormValid = false
      return
    }

    if ((txtMultiPricing.value == "No") && (parseInt(txtPoundsPriced.value) < parseInt(txtRemainingLbs.value))) {
      showMessage(thisObj, "Multiple pricings not allowed. All pounds must be priced at the same time.", false)
      isFormValid = false
      return
    }

    if (txtMultiPricing.value == "No" && (mstrHarvestDate.valueOf() > dateTime.valueOf())) { //And (CDate(mstrHarvestDate) > Date) Then ----------react doubt---------
      showMessage(thisObj, "Harvest complete date has not been reached. When multiple pricings are not allowed, all pounds must be priced together after the harvest is complete.", false)
      isFormValid = false
      return
    }

    if (mstrContractType == "FlexMarket") {
      let radioBtnValue = getValue(thisObj, "radioMarketRebate")
      if (radioBtnValue == undefined) {
        showMessage(thisObj, "Must choose pricing terms.", false)
        isFormValid = false
        return
      }

      if ((chkboxOverrideRebate.Value == true) && (isNaN(txtOverridePrice.Text))) {
        showMessage(thisObj, "Override Price must be numeric.", false)
        txtOverride.value = ""
        isFormValid = false
        return
      }

      if ((chkboxOverrideRebate.Value == true) && (txtOverridePrice.value <= 0)) {
        showMessage(thisObj, "A valid Override Price is required when choosing to Override the pricing terms.", false)
        txtOverride.value = ""
        isFormValid = false
        return
      }

    }
    isFormValid = true;

  }



  function addMultiOccurredRowId(data) {
    let map = new Map();
    let newJs = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      if (map.has(data[i].colTxtPricingNum)) {
        obj = { ...data[i] };
        obj.multiOccurredRowId = map.get(data[i].colTxtPricingNum);
      }
      else {
        map.set(data[i].colTxtPricingNum, data[i].RowId);
        obj = { ...data[i] };
        obj.multiOccurredRowId = null;
      }
      newJs.push(obj);

    }
    return newJs;
  }
  function groupMultiOccurredRows(data, multi = null) {

    return data.reduce((r, e) => {
      if (e.multiOccurredRowId == multi) {
        const obj = { ...e };
        const children = groupMultiOccurredRows(data, e.RowId);
        let valueAdded = 0, valuerebatevalue = 0, colTxtEmptyrtp = ''
        for (let i = 0; i < children.length; i++) {
          let count = parseInt(children[i].colTxtPounds)
          let rebatevaluecount = parseFloat(children[i].colTxtRebateVal)
          valueAdded += count
          valuerebatevalue += rebatevaluecount
          colTxtEmptyrtp = children[i].colTxtEmpty
        }

        valueAdded = parseInt(valueAdded)
        valuerebatevalue = parseFloat(valuerebatevalue)
        if (children.length) {
          obj.children = children;
          obj.valueAdded = valueAdded;
          obj.valuerebatevalue = valuerebatevalue.toFixed(2)
          // if (colTxtEmptyrtp == 'Y') {
          //   obj.colTxtEmptyrtp = 'R-T-P'
          // }
        }

        r.push(obj);
      }

      return r;
    }, [])
  }

  function totalpoundsRowsArray(data) {
    let totaljs = data;
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      let count = parseInt(data[i].valueAdded)
      let colTxtPounds = parseInt(data[i].colTxtPounds)
      let total = count + colTxtPounds
      obj.colmm = total
      totaljs.push(obj);
    }
    return totaljs

  }
  const GetBFillPricings = async () => {

    let mstrPriceLine = "2"
    let mstrHarvestDate = ""

    let strXML = ""
    let strCurOffer = ""
    let strHeader = ""
    let strPricings = ""
    let intRow = ""
    let intCol = ""
    let strSavSuffix = ""

    let lngPendPounds = 0
    let lngPricingPounds
    let dblPricingRebate
    let lngTotalPounds = 0
    let dblTotalRebate = 0

    let txtNewPriceLbs = ""
    let optGain = false
    let optRebate = false
    let chkOverride = 0
    let txtOverridePrice = ""


    let vsfResults = {
      clear: false,
      rows: 0,
      cols: 0
    }


    let response = await ContractManagementService.RetrieveFlexMarketDetails(mstrContractNumber);
    let data = response[0];

    if (data !== null && data !== undefined) {
      setData(thisObj, "isBfillPricing", true)
      if (data.contract_headers.length > 0) {
        strHeader = data.contract_headers
      }
      if (data.contract_flex_mkt_pricings.length > 0) {
        strPricings = data.contract_flex_mkt_pricings
      }

      if (mstrContractType == "FlexMarket") {

        if (data.current_offers.length > 0) {
          strCurOffer = data.current_offers
          rMarketPrice = Number(strCurOffer[0].cur_market_price).toFixed(5);
          rRebateRate1 = Number(strCurOffer[0].cur_rebate_rate).toFixed(2);

          rRebateRate2 = Number(strCurOffer[0].cur_loan_gain_rate).toFixed(2);
          rLoanShare2 = parseFloat(strCurOffer[0].cur_mkt_gain_share === "" ? "0.00" : strCurOffer[0].cur_mkt_gain_share)
          rLoanShare = (100.00 - rLoanShare2)
          rRePayrate = Number(strCurOffer[0].cur_loan_repay_rate).toFixed(2);

          let opt1 = thisObj.state.radioMarketRebate.valueList[0].description;
          let arr1 = opt1.split(' ');
          arr1.splice(11, 1, rMarketPrice)
          arr1.splice(20, 1, rRebateRate1)
          let desc1 = arr1.join(' ')
          thisObj.state.radioMarketRebate.valueList[0].description = desc1;
          let opt2 = thisObj.state.radioMarketRebate.valueList[1].description;
          let arr2 = opt2.split(' ');
          arr2.splice(0, 1, rLoanShare2)
          arr2.splice(2, 1, rLoanShare)
          arr2.splice(16, 1, rRePayrate)
          arr2.splice(26, 1, rRebateRate2)
          let desc2 = arr2.join(' ');
          thisObj.state.radioMarketRebate.valueList[1].description = desc2;
          let js = []
          js.push({ key: '1', description: desc1 })
          js.push({ key: '2', description: desc2 })
          thisObj.setState(current => {
            return {
              ...current,
              radioMarketRebate: {
                ...state["radioMarketRebate"],
                valueList: js
              }
            }
          })

          setValue(thisObj, "txtCrntDate", ConvertToDate(strCurOffer[0].cur_posting_date))

          let e = document.getElementsByClassName("lblMrktRebate")[0].firstChild;
          e.style.display = "none"
          if (strCurOffer[0].cur_rebate_rate != 0) {
            let eleOptGain = document.getElementsByClassName("radioMarketRebate")[0].firstChild.children[1].children[1];
            eleOptGain.style.display = "none"
          }

          if (strCurOffer[0].cur_loan_gain_rate != 0) {
            let eleRbtRate = document.getElementsByClassName("radioMarketRebate")[0].firstChild.children[1].children[0];
            eleRbtRate.style.display = "none"
          }

          if (strCurOffer[0].cur_loan_gain_rate > strCurOffer[0].cur_rebate_rate) {
            setValue(thisObj, "radioMarketRebate", '2')
            chkboxOverrideRebate.value = false
            txtOverride.value = ""
            hide(thisObj, 'chkboxOverrideRebate')
          }

          if (strCurOffer[0].cur_loan_gain_rate < strCurOffer[0].cur_rebate_rate) {
            setValue(thisObj, "radioMarketRebate", '1')
            chkboxOverrideRebate.value = false
            txtOverride.value = ""
            if (strHeader.length > 0) {
              ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '006', 'U', strHeader[0].buy_pt_id)
                .then(response => {
                  if (response[0].permission == "Y") {
                    show(thisObj, 'chkboxOverrideRebate');
                  }
                });
            }
          }
        }
      }

      if (mstrContractType == "Floor") {

        hide(thisObj, 'chkboxOverrideRebate')

        if (data.floor_offers.length > 0) {
          strCurOffer = data.floor_offers
          setValue(thisObj, "txtCrntDate", ConvertToDate(strCurOffer[0].cur_posting_date))
          setValue(thisObj, "lblMrktRebate", `at ${strCurOffer[0].cur_rebate_rate}/net ton basis grade. This price reflects the current farmer stock price of ${strCurOffer[0].cur_market_price} less the original option paid of $ ${strCurOffer[0].orig_floor_opt}.`)

          let eleRbtRate = document.getElementsByClassName("radioMarketRebate")[0].firstChild.children[1].children[0];
          eleRbtRate.style.display = "none"

          let eleOptGain = document.getElementsByClassName("radioMarketRebate")[0].firstChild.children[1].children[1];
          eleOptGain.style.display = "none"

        }
      }

      if (strHeader.length > 0) {
        const contractDetails = strHeader[0]


        let mstrBuyPt = contractDetails.buy_pt_id
        setData(thisObj, "buyingPointId", mstrBuyPt)

        setValue(thisObj, "txtContract", contractDetails.contract_num)
        setValue(thisObj, "txtAvailableLbs", contractDetails.delv_loan_lbs)
        // setValue(thisObj, "txtApprovedPrice", contractDetails.loan_priced)
        // setValue(thisObj, "txtUnApprovedPriced", "0")
        // setValue(thisObj, "txtRemainingLbs", contractDetails.loan_not_priced)
        setValue(thisObj, "txtMarketEndDate", ConvertToDate(contractDetails.flex_mkt_end_date))

        if (!isNaN(txtAvailableLbs.value)) {
          if (txtAvailableLbs.value > 0) {
            setValue(thisObj, "txtMultiPricing", (contractDetails.multi_pricing_ind) == 'N' ? "No" : "Yes")
          }
        }

        if (((contractDetails.cont_type).toUpperCase() == "L") && ((contractDetails.pay_rebate_at).toUpperCase() == "DELIVERY") && (contractDetails.delv_lbs != 0)) {
          hide(thisObj, 'btnVoid');
        } else {
          show(thisObj, "btnVoid");
        }
      }

      if (mstrContractType == "FlexMarket") {
        hideColumn(thisObj, 'gridExistingPricing', 'coltxtFV95_1007')
        hideColumn(thisObj, 'gridExistingPricing', 'colTxtOptionVal')
        hideColumn(thisObj, 'gridExistingPricing', 'colTxtOptionRate')
      }


      if (mstrContractType == "Floor") {
        hideColumn(thisObj, 'gridExistingPricing', 'colBtnWhsRcpts')
        hideColumn(thisObj, 'gridExistingPricing', 'colTxtMarketPrice')
        hideColumn(thisObj, 'gridExistingPricing', 'colTxtRebateRate')
        hideColumn(thisObj, 'gridExistingPricing', 'colTxtLoanGain')
        hideColumn(thisObj, 'gridExistingPricing', 'colTxtRebateVal')
      }


      let dt = strPricings;
      let tempPoundTotal = 0
      let tempRebateTotal = 0
      let RowId = 0
      let js = []
      let obj = {}
      for (let i = 0; i < dt.length; i++) {
        const pendPounds = (dt[i].pricing_status).toUpperCase()
        const status = (dt[i].pricing_status).toUpperCase()
        // const pound = dt[i].priced_pounds
        const electn = dt[i].expired_forced_ind

        RowId = RowId + 1;
        obj.RowId = RowId
        obj.colTxtPricingDate = ConvertToDate(dt[i].pricing_date)
        obj.colTxtPricingNum = dt[i].contract_num + '' + dt[i].pricing_suffix
        obj.colTxtStatus = status === "V" ? "Voided" : status === "P" ? "Pending" : status === "S" ? "Signed" : status === "A" ? "Approved" : ""
        obj.colTxtPounds = dt[i].priced_pounds
        obj.colBtnWhsRcpts = dt[i].whs_rcpt_bp + '-' + dt[i].whs_rcpt_num
        obj.colFv95 = dt[i].insp_bp + '-' + dt[i].insp_num
        obj.colTxtElection = electn === "Y" ? `FORCED ${dt[i].market_election}` : "N" ? dt[i].market_election : ''
        obj.colTxtMarketPrice = dt[i].market_price
        obj.colTxtRebateRate = dt[i].rebate_rate
        obj.colTxtOptionVal = dt[i].rebate_value
        obj.colTxtOptionRate = dt[i].rebate_rate
        obj.colTxtPostedPrice = dt[i].posted_price
        obj.colTxtLoanGain = dt[i].loan_gain_rate
        obj.colTxtRebateVal = dt[i].rebate_value
        obj.contract_num = dt[i].contract_num
        obj.pricing_suffix = dt[i].pricing_suffix
        obj.open_whs_rcpts = dt[i].open_whs_rcpts
        obj.colSuffixNam = dt[i].pricing_suffix
        obj.colTxtEmpty = dt[i].rtp_ind
        js.push(obj)
        obj = {}

        lngPendPounds = lngPendPounds + parseInt(pendPounds == "P" ? dt[i].priced_pounds : pendPounds == "S" ? dt[i].priced_pounds : 0)

        lngTotalPounds = lngTotalPounds + parseInt(pendPounds == "V" ? 0 : dt[i].priced_pounds)

        dblTotalRebate = dblTotalRebate + parseFloat(pendPounds == "V" ? 0 : dt[i].rebate_value)
      }


      setValue(thisObj, "txtApprovedPrice", (lngTotalPounds - lngPendPounds).toString())
      setValue(thisObj, "txtUnApprovedPriced", (lngPendPounds == "" ? "0" : lngPendPounds == undefined ? "0" : lngPendPounds.toString()))
      setValue(thisObj, "txtRemainingLbs", (parseFloat(txtAvailableLbs.value) - lngPendPounds).toString())





      let MultiOccurredRowArray = addMultiOccurredRowId(js);
      const groupedRowsArray = groupMultiOccurredRows(MultiOccurredRowArray);
      let jsss = []
      let objjj = {}
      for (let i = 0; i < groupedRowsArray.length; i++) {
        objjj = { ...groupedRowsArray[i] }
        let toltal = 0, totalrebade = 0
        let valueAdded_ = 0, toltal_ad = 0, valuerebatevalue = 0, total_rebade = 0, colTxtEmptyrtp = ''
        toltal = parseInt(groupedRowsArray[i].colTxtPounds)
        valueAdded_ = parseInt(groupedRowsArray[i].valueAdded)
        totalrebade = parseFloat(groupedRowsArray[i].colTxtRebateVal)
        valuerebatevalue = parseFloat(groupedRowsArray[i].valuerebatevalue)
        colTxtEmptyrtp = groupedRowsArray[i].colTxtEmpty
        if (valueAdded_) {
          toltal_ad = toltal + valueAdded_
          objjj.colTxtPounds = toltal_ad
          total_rebade = totalrebade + valuerebatevalue
          objjj.colTxtRebateVal = total_rebade.toFixed(2)

          objjj.colTxtEmpty = colTxtEmptyrtp == "Y" ? 'R-T-P' : ''
        }
        else {
          objjj.colTxtPounds = toltal
          objjj.colTxtRebateVal = totalrebade.toFixed(2)
        }
        jsss.push(objjj)
        objjj = {}

      }
      // setPoundsTotals(parseInt(lngTotalPounds))
      // setRebateValueTotal(parseFloat(dblTotalRebate).toFixed(2))
      let temppobjj = {}
      // "colTxtPricingDate" : "",
      temppobjj.colTxtPricingNum = "Total"
      // "colTxtStatus" : "",
      // "colBtnWhsRcpts" : "",
      // "coltxtFV95_1007" : "",
      temppobjj.colTxtPounds = lngTotalPounds
      // "colTxtElection" : "",
      // "colTxtMarketPrice" : "",
      // "colTxtRebateRate" : "",
      // "colTxtPostedPrice" : "",
      // "colTxtLoanGain" : "",
      temppobjj.colTxtRebateVal = dblTotalRebate.toFixed(2)
      temppobjj.colTxtOptionVal = dblTotalRebate.toFixed(2)
      // "colTxtOptionRate" : "",
      // "colTxtEmpty" : "",
      // "expandCell4" : "666",
      //  "expandCell1" : "88",
      // "expandCell5" : "99",
      // "expandCell6" : "11",
      //  }
      jsss.push(temppobjj)
      setValue(thisObj, "gridExistingPricing", jsss);


      let numOfRows = getValue(thisObj, "gridExistingPricing")
      // If vsfResults.Rows > 2 Then
      //     cmdPrintWS.Visible = True
      // Else
      //     cmdPrintWS.Visible = False
      // End If
      hide(thisObj, 'btnSign')
      hide(thisObj, 'btnApprv')
      hide(thisObj, 'btnVoid')
      hide(thisObj, 'btnPrntPrcng')
      hide(thisObj, 'btnRescan')
    }

    if ((getData(thisObj, "SecurityCreateSign")) == true && txtRemainingLbs.value > 0) {
      let elemt = document.getElementsByClassName("grpboxMiddle")[0].firstChild
      elemt.style.visibility = ""
    }else{
      let elemt = document.getElementsByClassName("grpboxMiddle")[0].firstChild
      elemt.style.visibility = "hidden"
    }

  }



  // START_USER_CODE-USER_METHODS
  const onbtnExitClick = () => {
    try {
      let availableLbs = parseFloat(getValue(thisObj, 'txtAvailableLbs'))
      let approvedPrice = parseFloat(getValue(thisObj, 'txtApprovedPrice'))
      let remainingLbs = parseFloat(getValue(thisObj, 'txtAvailableLbs')) - parseFloat(getValue(thisObj, 'txtApprovedPrice'));
      let flexMktEndDate
      if (getValue(thisObj, 'txtMarketEndDate')) {
        flexMktEndDate = getValue(thisObj, 'txtMarketEndDate')
      } else {
        flexMktEndDate = ""
      }
      let arr2 = []
      arr2.push({ availableLbs: availableLbs, approvedPrice: approvedPrice, remainingLbs: remainingLbs, flexMktEndDate: flexMktEndDate })
      setData(thisObj, 'frmContractProfiledata', arr2);
      document.getElementById("ContractManagement_FlexibleMarketPricingsPopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;

  const onradioMarketRebateChange = () => {
    let rdoBtnValue = getValue(thisObj, 'radioMarketRebate')
    if (rdoBtnValue == 1) {
      chkboxOverrideRebate.value = false
      txtOverride.value = ""
      if (mbSecurityOverridePrice) {
        show(thisObj, 'chkboxOverrideRebate')
        return
      }
    }

    if (rdoBtnValue == 2) {
      chkboxOverrideRebate.value = false
      txtOverride.value = ""
      hide(thisObj, 'chkboxOverrideRebate')
      return
    }

  }
  thisObj.onradioMarketRebateChange = onradioMarketRebateChange;


  const onchkboxOverrideRebateChange = () => {
    let chkboxOverrideRebate = getValue(thisObj, 'chkboxOverrideRebate')
    setValue(thisObj, 'txtOverride', "");
    if (chkboxOverrideRebate === true) {
      show(thisObj, 'txtOverride')
    } else {
      hide(thisObj, 'txtOverride')
    }
  }
  thisObj.onchkboxOverrideRebateChange = onchkboxOverrideRebateChange;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;

        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FlexibleMarketPricings*/}

              {/* END_USER_CODE-USER_BEFORE_FlexibleMarketPricings*/}

              {/* START_USER_CODE-USER_BEFORE_grpBoxTop*/}

              {/* END_USER_CODE-USER_BEFORE_grpBoxTop*/}

              <GroupBoxWidget conf={state.grpBoxTop} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtAvailableLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtAvailableLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAvailableLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAvailableLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtAvailableLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtApprovedPrice*/}

                {/* END_USER_CODE-USER_BEFORE_txtApprovedPrice*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtApprovedPrice}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtApprovedPrice*/}

                {/* END_USER_CODE-USER_AFTER_txtApprovedPrice*/}
                {/* START_USER_CODE-USER_BEFORE_txtUnApprovedPriced*/}

                {/* END_USER_CODE-USER_BEFORE_txtUnApprovedPriced*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUnApprovedPriced}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUnApprovedPriced*/}

                {/* END_USER_CODE-USER_AFTER_txtUnApprovedPriced*/}
                {/* START_USER_CODE-USER_BEFORE_txtRemainingLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtRemainingLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRemainingLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRemainingLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtRemainingLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtMultiPricing*/}

                {/* END_USER_CODE-USER_BEFORE_txtMultiPricing*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMultiPricing}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMultiPricing*/}

                {/* END_USER_CODE-USER_AFTER_txtMultiPricing*/}
                {/* START_USER_CODE-USER_BEFORE_txtMarketEndDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtMarketEndDate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMarketEndDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMarketEndDate*/}

                {/* END_USER_CODE-USER_AFTER_txtMarketEndDate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpBoxTop*/}

              {/* END_USER_CODE-USER_AFTER_grpBoxTop*/}
              {/* START_USER_CODE-USER_BEFORE_grpboxMiddle*/}

              {/* END_USER_CODE-USER_BEFORE_grpboxMiddle*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpboxMiddle} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblBoxPostingDate*/}

                {/* END_USER_CODE-USER_BEFORE_lblBoxPostingDate*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBoxPostingDate}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBoxPostingDate*/}

                {/* END_USER_CODE-USER_AFTER_lblBoxPostingDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtCrntDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtCrntDate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrntDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCrntDate*/}

                {/* END_USER_CODE-USER_AFTER_txtCrntDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtPoundsPriced*/}

                {/* END_USER_CODE-USER_BEFORE_txtPoundsPriced*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPoundsPriced}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPoundsPriced*/}

                {/* END_USER_CODE-USER_AFTER_txtPoundsPriced*/}
                {/* START_USER_CODE-USER_BEFORE_radioMarketRebate*/}

                {/* END_USER_CODE-USER_BEFORE_radioMarketRebate*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioMarketRebate}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioMarketRebate*/}

                {/* END_USER_CODE-USER_AFTER_radioMarketRebate*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxOverrideRebate*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxOverrideRebate*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxOverrideRebate}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxOverrideRebate*/}

                {/* END_USER_CODE-USER_AFTER_chkboxOverrideRebate*/}
                {/* START_USER_CODE-USER_BEFORE_txtOverride*/}

                {/* END_USER_CODE-USER_BEFORE_txtOverride*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOverride}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

                <LabelWidget
                  values={values}
                  conf={state.lblMrktRebate}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_txtOverride*/}

                {/* END_USER_CODE-USER_AFTER_txtOverride*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreate*/}

                <ButtonWidget
                  conf={state.btnCreate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreate*/}

                {/* END_USER_CODE-USER_AFTER_btnCreate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpboxMiddle*/}

              {/* END_USER_CODE-USER_AFTER_grpboxMiddle*/}
              {/* START_USER_CODE-USER_BEFORE_gridExistingPricing*/}

              {/* END_USER_CODE-USER_BEFORE_gridExistingPricing*/}
              <GridWidget
                conf={state.gridExistingPricing}
                screenConf={state}
                // onRowChange={selectedRows => onGridRowChange(selectedRows)}
                setFieldValue={setFieldValue}
                linkClick={event => {
                  if (state.gridExistingPricing.selected.length > 0) {

                    thisObj.selectedRow = state.gridExistingPricing.selected[0];
                  }
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                }
                errors={errors}
                touched={touched}
                rows={values.gridExistingPricing}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                refObject={thisObj}
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridExistingPricing*/}

              {/* END_USER_CODE-USER_AFTER_gridExistingPricing*/}
              {/* START_USER_CODE-USER_BEFORE_grpBoxBottom*/}

              {/* END_USER_CODE-USER_BEFORE_grpBoxBottom*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpBoxBottom} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnRescan*/}

                {/* END_USER_CODE-USER_BEFORE_btnRescan*/}

                <ButtonWidget
                  conf={state.btnRescan}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRescan*/}

                {/* END_USER_CODE-USER_AFTER_btnRescan*/}
                {/* START_USER_CODE-USER_BEFORE_btnApprv*/}

                {/* END_USER_CODE-USER_BEFORE_btnApprv*/}

                <ButtonWidget
                  conf={state.btnApprv}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnApprv*/}
                <ButtonWidget
                  conf={state.btnSign}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* END_USER_CODE-USER_AFTER_btnApprv*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrntPrcng*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrntPrcng*/}

                <ButtonWidget
                  conf={state.btnPrntPrcng}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrntPrcng*/}

                {/* END_USER_CODE-USER_AFTER_btnPrntPrcng*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintWork*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintWork*/}

                <ButtonWidget
                  conf={state.btnPrintWork}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintWork*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintWork*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpBoxBottom*/}

              {/* END_USER_CODE-USER_AFTER_grpBoxBottom*/}

              {/* START_USER_CODE-USER_AFTER_FlexibleMarketPricings*/}

              {/* END_USER_CODE-USER_AFTER_FlexibleMarketPricings*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_FlexibleMarketPricings);