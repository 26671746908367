/* eslint-disable*/
import React from "react";
import RadioButtonGroupWidget from "./RadioButtonGroupWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup, fireEvent } from "@testing-library/react";

describe("Radio button test cases", () => {
  beforeEach(async () => {
    act(() => {
      let radioButtonGroupWidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Yes:1,No:2",
        OptionsAlignment: "Vertical",
        name: "radioButtonGroupWidget1",
        type: "RadioButtonGroupWidget",
        parent: "groupboxwidget0",
        Label: "radioButtonGroupWidget12",
        ofTypeDomain: "d_boolean",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "1",
        ColsForMobile: "1",
        ColsForTabLandscape: "1",
        ColsForTabPotrait: "1",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <RadioButtonGroupWidget
          onChange={onChange}
          values={values}
          conf={radioButtonGroupWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("RadioButtonGroupWidget Test Cases", async () => {
    const radioButtonGroupWidget1 = screen.getByTestId(
      "radioButtonGroupWidget1"
    ).firstChild.lastChild.firstChild.firstChild;
    expect(radioButtonGroupWidget1.tagName).toBe("INPUT");
    expect(radioButtonGroupWidget1.type).toBe("radio");
    fireEvent.change(radioButtonGroupWidget1, { target: { value: "1" } });
    expect(radioButtonGroupWidget1.value).toBe("1");
    fireEvent.change(radioButtonGroupWidget1, { target: { value: "2" } });
    expect(radioButtonGroupWidget1.value).toBe("2");
  });
});

describe("Radio button test cases 2", () => {
  beforeEach(async () => {
    act(() => {
      let radioButtonGroupWidget2 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Yes:1,No:2",
        OptionsAlignment: "Vertical",
        name: "radioButtonGroupWidget2",
        type: "RadioButtonGroupWidget",
        parent: "groupboxwidget0",
        Label: "radioButtonGroupWidget22",
        ofTypeDomain: "d_boolean",
        valueList: [{key: '1', description: 'Yes'},{key: '2', description: 'No'}]
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "1",
        ColsForMobile: "1",
        ColsForTabLandscape: "1",
        ColsForTabPotrait: "1",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true
      };
      let values = {};
      let errors = {radioButtonGroupWidget2 : "some text"};
      let touched = {radioButtonGroupWidget2 : "some text"};
      let onChange = jest.fn();
      renderTestScreen(
        <RadioButtonGroupWidget
          onChange={onChange}
          values={values}
          conf={radioButtonGroupWidget2}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("RadioButtonGroupWidget Test Cases", async () => {
    const secondRadio = screen.getByLabelText("No");
    fireEvent.click(secondRadio);
    expect(secondRadio).toBeTruthy();

    const firstRadio = screen.getByLabelText("Yes");
    fireEvent.click(firstRadio);
    expect(firstRadio).toBeTruthy();
  });
});
