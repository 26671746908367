/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getValue,
  disable,
  getData,
  setData,
  enable,
  hideColumn,
  goTo,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./ManualDPRInvSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { isDate } from "moment";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualDPRInvSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const compNameFromLS = () => (sessionStorage.getItem('compName'))
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  let CompName = sessionStorage.getItem('Companyname')
  
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualDPRInvSearch",
    windowName: "ManualDPRInvSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualDPRInvSearch",
    // START_USER_CODE-USER_ManualDPRInvSearch_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "DPR Inventory Maintenance Search",
      scrCode: "PN0320A",
    },
    // END_USER_CODE-USER_ManualDPRInvSearch_PROPERTIES
    btmExit: {
      name: "btmExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btmExit_PROPERTIES

      // END_USER_CODE-USER_btmExit_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxAuditHistory",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnAuditHistory: {
      name: "btnAuditHistory",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Audit History",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAuditHistory_PROPERTIES

      // END_USER_CODE-USER_btnAuditHistory_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxAuditHistory",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxAuditHistory",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxPrintExport",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxPrintExport",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxTransferDetails",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    colAdjustmentSource: {
      name: "colAdjustmentSource",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Adjustment Source",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAdjustmentSource_PROPERTIES

      // END_USER_CODE-USER_colAdjustmentSource_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: " Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colChgMainComment: {
      name: "colChgMainComment",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Chg Maint Comment",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChgMainComment_PROPERTIES

      // END_USER_CODE-USER_colChgMainComment_PROPERTIES
    },
    colChgMaintDate: {
      name: "colChgMaintDate",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Chg Maint Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChgMaintDate_PROPERTIES

      // END_USER_CODE-USER_colChgMaintDate_PROPERTIES
    },
    colChgMaintUser: {
      name: "colChgMaintUser",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Chg Maint User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChgMaintUser_PROPERTIES

      // END_USER_CODE-USER_colChgMaintUser_PROPERTIES
    },
    colComment: {
      name: "colComment",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Comment",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colComment_PROPERTIES

      // END_USER_CODE-USER_colComment_PROPERTIES
    },
    colCompOwnedAdj: {
      name: "colCompOwnedAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Comp Owned Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_colCompOwnedAdj_PROPERTIES
    },
    colCreateDate: {
      name: "colCreateDate",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Create Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCreateDate_PROPERTIES

      // END_USER_CODE-USER_colCreateDate_PROPERTIES
    },
    colIntransitAdj: {
      name: "colIntransitAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Intransit Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_colIntransitAdj_PROPERTIES
    },
    colNotRcptdAdj: {
      name: "colNotRcptdAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Not Receipted Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNotRcptdAdj_PROPERTIES

      // END_USER_CODE-USER_colNotRcptdAdj_PROPERTIES
    },
    colPhysicalAdj: {
      name: "colPhysicalAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Physical Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_colPhysicalAdj_PROPERTIES
    },
    colPnutOleic: {
      name: "colPnutOleic",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Pnut Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutOleic_PROPERTIES

      // END_USER_CODE-USER_colPnutOleic_PROPERTIES
    },
    colPnutOrganic: {
      name: "colPnutOrganic",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Pnut Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutOrganic_PROPERTIES

      // END_USER_CODE-USER_colPnutOrganic_PROPERTIES
    },
    colPnutSeed: {
      name: "colPnutSeed",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Pnut Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutSeed_PROPERTIES

      // END_USER_CODE-USER_colPnutSeed_PROPERTIES
    },
    colPnutSeg: {
      name: "colPnutSeg",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Pnut Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutSeg_PROPERTIES

      // END_USER_CODE-USER_colPnutSeg_PROPERTIES
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutType_PROPERTIES

      // END_USER_CODE-USER_colPnutType_PROPERTIES
    },
    colPnutVariety: {
      name: "colPnutVariety",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Pnut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutVariety_PROPERTIES

      // END_USER_CODE-USER_colPnutVariety_PROPERTIES
    },
    colPostingDt: {
      name: "colPostingDt",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Posting Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPostingDt_PROPERTIES

      // END_USER_CODE-USER_colPostingDt_PROPERTIES
    },
    colRecordID: {
      name: "colRecordID",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Record ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecordID_PROPERTIES

      // END_USER_CODE-USER_colRecordID_PROPERTIES
    },
    colShrinkAdj: {
      name: "colShrinkAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Shrink Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_colShrinkAdj_PROPERTIES
    },
    colUndetrminedAdj: {
      name: "colUndetrminedAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Undetermined Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUndetrminedAdj_PROPERTIES

      // END_USER_CODE-USER_colUndetrminedAdj_PROPERTIES
    },
    colWhsBin: {
      name: "colWhsBin",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Whs/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhsBin_PROPERTIES

      // END_USER_CODE-USER_colWhsBin_PROPERTIES
    },
    colWhsRcptAdj: {
      name: "colWhsRcptAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory",
      Label: "Whs Receipt Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhsRcptAdj_PROPERTIES

      // END_USER_CODE-USER_colWhsRcptAdj_PROPERTIES
    },
    ddBuyPt: {
      name: "ddBuyPt",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyPt_PROPERTIES

      // END_USER_CODE-USER_ddBuyPt_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Organic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganic_PROPERTIES

      // END_USER_CODE-USER_ddOrganic_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Seg:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    ddVariety: {
      name: "ddVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVariety_PROPERTIES

      // END_USER_CODE-USER_ddVariety_PROPERTIES
    },
    ddWhseBin: {
      name: "ddWhseBin",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Whs/Bin:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseBin_PROPERTIES

      // END_USER_CODE-USER_ddWhseBin_PROPERTIES
    },
    gridAuditHistory: {
      name: "gridAuditHistory",
      type: "GridWidget",
      parent: "grpbxAuditHistory",
      gridCellsOrder:
        "txtcolRecordID,txtcolPostingDt,txtcolBuyPt,txtcolWhsBin,txtcolPnutType,txtcolPnutVariety,txtcolPnutSeed,txtcolPnutSeg,txtcolPnutOleic,txtcolPnutOrganic,txtcolPhysicalAdj,txtcolWhsRcptAdj,txtcolShrinkAdj,txtcolNotRcptdAdj,txtcolUndetrminedAdj,txtcolCompOwnedAdj,txtcolIntransitAdj,txtcolAdjustmentSource,txtcolCreateDate,txtcolComment,txtcolChgMainAction,txtcolChgMaintUser,txtcolChgMaintDate,txtcolChgMainComment",
      ColSpan: "8",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAuditHistory_PROPERTIES

      // END_USER_CODE-USER_gridAuditHistory_PROPERTIES
    },
    grpbxAuditHistory: {
      name: "grpbxAuditHistory",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvSearch",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxAuditHistory_PROPERTIES

      // END_USER_CODE-USER_grpbxAuditHistory_PROPERTIES
    },
    grpbxBuyPtDetails: {
      name: "grpbxBuyPtDetails",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvSearch",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBuyPtDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyPtDetails_PROPERTIES
    },
    grpbxPnutDetails: {
      name: "grpbxPnutDetails",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPnutDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxPnutDetails_PROPERTIES
    },
    grpbxPrintExport: {
      name: "grpbxPrintExport",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrintExport_PROPERTIES

      // END_USER_CODE-USER_grpbxPrintExport_PROPERTIES
    },
    grpbxTransferDetails: {
      name: "grpbxTransferDetails",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxTransferDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferDetails_PROPERTIES
    },
    lblAuditHistOfInvAdj: {
      name: "lblAuditHistOfInvAdj",
      type: "LabelWidget",
      parent: "grpbxAuditHistory",
      Label: "Inventory Adjusments:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAuditHistOfInvAdj_PROPERTIES

      // END_USER_CODE-USER_lblAuditHistOfInvAdj_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "grpbxAuditHistory",
      Label: "***Note: All weights are in Tons",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote_PROPERTIES

      // END_USER_CODE-USER_lblNote_PROPERTIES
    },
    lblPeanut: {
      name: "lblPeanut",
      type: "LabelWidget",
      parent: "grpbxPnutDetails",
      Label: "Peanut:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanut_PROPERTIES

      // END_USER_CODE-USER_lblPeanut_PROPERTIES
    },
    txt1007Num: {
      name: "txt1007Num",
      type: "TextBoxWidget",
      parent: "grpbxBuyPtDetails",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Num_PROPERTIES

      // END_USER_CODE-USER_txt1007Num_PROPERTIES
    },
    txtAuditDtFrom: {
      name: "txtAuditDtFrom",
      type: "DateWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Audit Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAuditDtFrom_PROPERTIES

      // END_USER_CODE-USER_txtAuditDtFrom_PROPERTIES
    },
    txtAuditDtTo: {
      name: "txtAuditDtTo",
      type: "DateWidget",
      parent: "grpbxBuyPtDetails",
      Label: "-Thru-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAuditDtTo_PROPERTIES

      // END_USER_CODE-USER_txtAuditDtTo_PROPERTIES
    },
    txtAuditUserNum: {
      name: "txtAuditUserNum",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Audit User #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAuditUserNum_PROPERTIES

      // END_USER_CODE-USER_txtAuditUserNum_PROPERTIES
    },
    txtcolAdjustmentSource: {
      name: "txtcolAdjustmentSource",
      type: "TextBoxWidget",
      colName: "colAdjustmentSource",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAdjustmentSource_PROPERTIES

      // END_USER_CODE-USER_txtcolAdjustmentSource_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolChgMainComment: {
      name: "txtcolChgMainComment",
      type: "TextBoxWidget",
      colName: "colChgMainComment",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMainComment_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMainComment_PROPERTIES
    },
    txtcolChgMaintDate: {
      name: "txtcolChgMaintDate",
      type: "TextBoxWidget",
      colName: "colChgMaintDate",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMaintDate_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMaintDate_PROPERTIES
    },
    txtcolChgMaintUser: {
      name: "txtcolChgMaintUser",
      type: "TextBoxWidget",
      colName: "colChgMaintUser",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMaintUser_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMaintUser_PROPERTIES
    },
    txtcolComment: {
      name: "txtcolComment",
      type: "TextBoxWidget",
      colName: "colComment",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolComment_PROPERTIES

      // END_USER_CODE-USER_txtcolComment_PROPERTIES
    },
    txtcolCompOwnedAdj: {
      name: "txtcolCompOwnedAdj",
      type: "TextBoxWidget",
      colName: "colCompOwnedAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolCompOwnedAdj_PROPERTIES
    },
    txtcolCreateDate: {
      name: "txtcolCreateDate",
      type: "TextBoxWidget",
      colName: "colCreateDate",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCreateDate_PROPERTIES

      // END_USER_CODE-USER_txtcolCreateDate_PROPERTIES
    },
    txtcolChgMainAction: {
      name: "txtcolChgMainAction",
      type: "TextBoxWidget",
      colName: "colCreateDate",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMainAction_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMainAction_PROPERTIES
    },
    txtcolIntransitAdj: {
      name: "txtcolIntransitAdj",
      type: "TextBoxWidget",
      colName: "colIntransitAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolIntransitAdj_PROPERTIES
    },
    txtcolNotRcptdAdj: {
      name: "txtcolNotRcptdAdj",
      type: "TextBoxWidget",
      colName: "colNotRcptdAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNotRcptdAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNotRcptdAdj_PROPERTIES
    },
    txtcolPhysicalAdj: {
      name: "txtcolPhysicalAdj",
      type: "TextBoxWidget",
      colName: "colPhysicalAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolPhysicalAdj_PROPERTIES
    },
    txtcolPnutOleic: {
      name: "txtcolPnutOleic",
      type: "TextBoxWidget",
      colName: "colPnutOleic",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutOleic_PROPERTIES
    },
    txtcolPnutOrganic: {
      name: "txtcolPnutOrganic",
      type: "TextBoxWidget",
      colName: "colPnutOrganic",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutOrganic_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutOrganic_PROPERTIES
    },
    txtcolPnutSeed: {
      name: "txtcolPnutSeed",
      type: "TextBoxWidget",
      colName: "colPnutSeed",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutSeed_PROPERTIES
    },
    txtcolPnutSeg: {
      name: "txtcolPnutSeg",
      type: "TextBoxWidget",
      colName: "colPnutSeg",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutSeg_PROPERTIES
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutType_PROPERTIES
    },
    txtcolPnutVariety: {
      name: "txtcolPnutVariety",
      type: "TextBoxWidget",
      colName: "colPnutVariety",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutVariety_PROPERTIES
    },
    txtcolPostingDt: {
      name: "txtcolPostingDt",
      type: "TextBoxWidget",
      colName: "colPostingDt",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPostingDt_PROPERTIES

      // END_USER_CODE-USER_txtcolPostingDt_PROPERTIES
    },
    txtcolRecordID: {
      name: "txtcolRecordID",
      type: "TextBoxWidget",
      colName: "colRecordID",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecordID_PROPERTIES

      // END_USER_CODE-USER_txtcolRecordID_PROPERTIES
    },
    txtcolShrinkAdj: {
      name: "txtcolShrinkAdj",
      type: "TextBoxWidget",
      colName: "colShrinkAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolShrinkAdj_PROPERTIES
    },
    txtcolUndetrminedAdj: {
      name: "txtcolUndetrminedAdj",
      type: "TextBoxWidget",
      colName: "colUndetrminedAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUndetrminedAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolUndetrminedAdj_PROPERTIES
    },
    txtcolWhsBin: {
      name: "txtcolWhsBin",
      type: "TextBoxWidget",
      colName: "colWhsBin",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhsBin_PROPERTIES

      // END_USER_CODE-USER_txtcolWhsBin_PROPERTIES
    },
    txtcolWhsRcptAdj: {
      name: "txtcolWhsRcptAdj",
      type: "TextBoxWidget",
      colName: "colWhsRcptAdj",
      parent: "gridAuditHistory",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhsRcptAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolWhsRcptAdj_PROPERTIES
    },
    txtSC95Num: {
      name: "txtSC95Num",
      type: "TextBoxWidget",
      parent: "grpbxBuyPtDetails",
      Label: "SC95 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95Num_PROPERTIES

      // END_USER_CODE-USER_txtSC95Num_PROPERTIES
    },
    txtTotalCompOwned: {
      name: "txtTotalCompOwned",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalCompOwned_PROPERTIES

      // END_USER_CODE-USER_txtTotalCompOwned_PROPERTIES
    },
    txtTotalIntransit: {
      name: "txtTotalIntransit",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalIntransit_PROPERTIES

      // END_USER_CODE-USER_txtTotalIntransit_PROPERTIES
    },
    txtTotalPhysical: {
      name: "txtTotalPhysical",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalPhysical_PROPERTIES

      // END_USER_CODE-USER_txtTotalPhysical_PROPERTIES
    },
    txtTotalReceipted: {
      name: "txtTotalReceipted",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalReceipted_PROPERTIES

      // END_USER_CODE-USER_txtTotalReceipted_PROPERTIES
    },
    txtTotalShrink: {
      name: "txtTotalShrink",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalShrink_PROPERTIES

      // END_USER_CODE-USER_txtTotalShrink_PROPERTIES
    },
    txtTotalUndetermined: {
      name: "txtTotalUndetermined",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalUndetermined_PROPERTIES

      // END_USER_CODE-USER_txtTotalUndetermined_PROPERTIES
    },
    txtTotalUnreceipted: {
      name: "txtTotalUnreceipted",
      type: "TextBoxWidget",
      parent: "grpbxAuditHistory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalUnreceipted_PROPERTIES

      // END_USER_CODE-USER_txtTotalUnreceipted_PROPERTIES
    },
    txtTradeInspNum: {
      name: "txtTradeInspNum",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Trade Insp #:",
      LengthRange: { MinLength: 0, MaxLength:7},
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspNum_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspNum_PROPERTIES
    },
    txtTradeSettleNum: {
      name: "txtTradeSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Trade Settle #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettleNum_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtWhseRcptNum: {
      name: "txtWhseRcptNum",
      type: "TextBoxWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Whs Rcpt #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtWhseRcptNum_PROPERTIES
    },
    grpbxManualDPRInvSearch: {
      name: "grpbxManualDPRInvSearch",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvSearch",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxManualDPRInvSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxManualDPRInvSearch_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#ManualDPRInvMaint":{},
        "SystemMaintenanceApplicationSupport#ManualDPRInvHistSearch":{},
        "SystemMaintenanceSpecialFunctions#SpecialFunctions":{},
       },
      REVERSE:{
        "SystemMaintenanceApplicationSupport#ManualDPRInvMaint":{},
        "SystemMaintenanceApplicationSupport#ManualDPRInvHistSearch":{},
        "SystemMaintenanceSpecialFunctions#SpecialFunctions":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
// btnAdd:{
//   DEFAULT:[
//   "SystemMaintenanceApplicationSupport#ManualDPRInvMaint#DEFAULT#true#Click",]
// },
// btnEdit:{
//   DEFAULT:[
//   "SystemMaintenanceApplicationSupport#ManualDPRInvMaint#DEFAULT#true#Click",]
// },
// btnAuditHistory:{
//   DEFAULT:[
//   "SystemMaintenanceApplicationSupport#ManualDPRInvHistSearch#DEFAULT#true#Click",]
// },
  // btmExit:{
  //   DEFAULT:[
  //     "SystemMaintenanceSpecialFunctions#SpecialFunctions#DEFAULT#true#Click",]
  // },
};

  const [loading, setLoading] = useState(true);
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);

    disable(thisObj, 'txtTotalPhysical')
    disable(thisObj, 'txtTotalReceipted')
    disable(thisObj, 'txtTotalShrink')
    disable(thisObj, 'txtTotalUnreceipted')
    disable(thisObj, 'txtTotalUndetermined')
    disable(thisObj, 'txtTotalCompOwned')
    disable(thisObj, 'txtTotalIntransit')

    Form_Load()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  useEffect(() =>{
    let Refresh = getData(thisObj, 'ClosingOfManualDPRInvMaint')
    let RefreshFlag = getData(thisObj, 'RefreshGrid')
    if(RefreshFlag != undefined && RefreshFlag != null && RefreshFlag != '' && RefreshFlag && Refresh != undefined && Refresh != null && Refresh != '' && Refresh){
      DoSearch()
      setData(thisObj, 'ClosingOfManualDPRInvMaint', false)
      setData(thisObj, 'RefreshGrid', false)
    }
  }, [getData(thisObj, 'ClosingOfManualDPRInvMaint')]);

  const ongridAuditHistoryRowSelect = () =>{
    let RowNum = getSelectedRowNumber(thisObj, "gridAuditHistory")
    let GridValue = getValue(thisObj, "gridAuditHistory")
    if(RowNum != undefined && RowNum != null && RowNum != '' && RowNum == (GridValue.length - 1)){
      thisObj.state.gridAuditHistory.selected[0] = null
    }
  }
  thisObj.ongridAuditHistoryRowSelect = ongridAuditHistoryRowSelect;

  const Form_Load = async () => {
    try {
      setLoading(true)
      let strXML = []

      await bFillBuyPtList()
      await bFillTypeList()

      strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'I', null)
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        if (strXML[0].permission != 'Y') {
          disable(thisObj, 'btnSearch')
          disable(thisObj, 'btnAuditHistory')
        }
      }

      strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'U', null)
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        if (strXML[0].permission != 'Y') {
          disable(thisObj, 'btnAdd')
        }
      }

      disable(thisObj, 'btnDelete')
      disable(thisObj, 'btnEdit')
      disable(thisObj, 'btnExportToExcel')
      disable(thisObj, 'btnPrint')

      await bFillSegmentList();
      await bFillOleicList();
      await bFillOrganicList();
      await bFillGenerationList();
      await bFillVarietyList();
      await bFillWhsBinList();
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }

  const ongridAuditHistoryDblclick = async () => {
    setLoading(true)
    await onbtnEditClick()
    setLoading(false)
  }
  thisObj.ongridAuditHistoryDblclick = ongridAuditHistoryDblclick;


  const bFillSegmentList = async () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Segments <<<' })
      js.push({ key: '1', description: 'Seg1' })
      js.push({ key: '2', description: 'Seg2' })
      js.push({ key: '3', description: 'Seg3' })
      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeg', js[0].key);
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const bFillOleicList = async () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Oleic <<<' })
      js.push({ key: 'N', description: 'None' })
      js.push({ key: 'M', description: 'Mid' })
      js.push({ key: 'H', description: 'High' })
      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...state["ddOleic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOleic', js[0].key);
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const bFillOrganicList = async () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Organic <<<' })
      js.push({ key: '_', description: ' ' })
      js.push({ key: 'N', description: 'No' })
      js.push({ key: 'Y', description: 'Yes' })
      thisObj.setState(current => {
        return {
          ...current,
          ddOrganic: {
            ...state["ddOrganic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOrganic', js[0].key);
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  const bFillBuyPtList = async () => {
    try {
      let js = []; 
      js.push({ key: '', description: '>>> Select Buying Point <<<' });
      let data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0320A', null, null, null, null)
      // .then(response => {
      //   let data = response
    // })
      if(data != undefined && data != null){
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + ' -- ' + ConvertToPascalCase(data[i].buy_pt_name.trim()) }
          js.push(obj)
          obj = {}
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyPt: {
              ...state["ddBuyPt"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddBuyPt', js[0].key);
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const bFillGenerationList = async () => {
    try {
      let js = []
      js.push({ key: '', description: '>>>All Generations<<<' })
      js.push({ key: 'N', description: 'Non-Seed' })
      js.push({ key: 'F', description: 'Foundation Seed' })
      js.push({ key: 'R', description: 'Registered Seed' })
      js.push({ key: 'C', description: 'Certified Seed' })
      thisObj.setState(current => {
        return {
          ...current,
          ddGeneration: {
            ...state["ddGeneration"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddGeneration', js[0].key);
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const bFillTypeList = async () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
      let data = await ContractManagementService.RetrievePeanutTypeControls(null)
      // .then(response => {
      //   let data = response
      // })
      if(data != undefined && data != null){
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeId + ' -- ' + data[i].pnutTypeName }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddType: {
              ...state["ddType"],
              valueList: js,
            }
          }
        })
      }
      setValue(thisObj, 'ddType', js.at(0).key);
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const bFillWhsBinList = async () => {
    try {
      let isbFillWhsBinList = false
      let strXML = []
      setValue(thisObj, 'ddWhseBin', '')
      let js = []
      let Obj = {}
      js.push({ key: '', description: ">>> All Whs/Bin <<<" })
      let buy_point_id = getValue(thisObj, 'ddBuyPt')
      strXML = await SettlementService.RetrieveWhouseBinInventoryDetails(buy_point_id, null, null, null, null)
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        for (let i = 0; i < strXML.length; i++) {
          Obj = {}
          Obj = { 
            key: strXML[i].whouseNum + "/" + strXML[i].binNum, 
            description: strXML[i].whouseNum + "/" + strXML[i].binNum + "  --  " + strXML[i].whouseBinId, 
            WhouseNum: strXML[i].whouseNum, 
            BinNum: strXML[i].binNum 
          }
          js.push(Obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddWhseBin: {
            ...state["ddWhseBin"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWhseBin', js[0].key)
      isbFillWhsBinList = true
      return isbFillWhsBinList
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const bFillVarietyList = async () => {
    try {
      let js = []; let buy_pt_id;
      let isbFillVarietyList = false
      js.push({ key: '', description: '>>> All Varieties <<<' })
      js.push({ key: '_', description: ' ' })
      let buying_pt = getValue(thisObj, 'ddBuyPt');
      let peanut_type = getValue(thisObj, 'ddType');

      if (peanut_type != null && peanut_type != "" && peanut_type != undefined) {
        let data = await ContractManagementService.RetrievePeanutVarietyControls(buying_pt, null, null, peanut_type, null)
        // .then(response => {
        //   let data = response;
        // })
        if(data != undefined && data != null){
          let count = 0;
          data.forEach(element => {
            count++;
            let newVar = true;
            for (let i = 0; i < count - 1; i++) {
              if ((element.pnut_variety_id).toUpperCase() == (data[i].pnut_variety_id).toUpperCase()) {
                newVar = false;
                break;
              }
            }
            let symbol;
            if (newVar) {
              switch (element.symbol_ind.trim()) {
                case "TRADEMARK":
                  symbol = " \u2122"
                  break;
                case "REGISTERED TRADEMARK":
                  symbol = " \xAE"
                  break;
                case "COPYRIGHT":
                  symbol = " \xA9"
                  break;
                default:
                  symbol = "";
              }
              let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
              js.push(obj)
            }
          });
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddVariety: {
            ...state["ddVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddVariety', js[0].key);
      isbFillVarietyList = true
      return isbFillVarietyList
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onddTypeChange = async () => {
    try {
      setLoading(true)
      await bFillVarietyList();
      await bFillSegmentList();
      await bFillGenerationList();
      await bFillOleicList();
      await bFillOrganicList();
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddTypeChange = onddTypeChange;

  const onddBuyPtChange = async () => {
    try {
      setLoading(true)
      await bFillWhsBinList();
      await bFillVarietyList();
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddBuyPtChange = onddBuyPtChange;

  const onbtnAuditHistoryClick = async () => {
    goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualDPRInvHistSearch#DEFAULT#true#Click")
  }
  thisObj.onbtnAuditHistoryClick = onbtnAuditHistoryClick;

  const onbtmExitClick = async () => {
      document.getElementById('SystemMaintenanceApplicationSupport_ManualDPRInvSearchPopUp').childNodes[0].click();
  }
  thisObj.onbtmExitClick = onbtmExitClick;

  const onbtnAddClick = async () => {
    try {
      setLoading(true)
      setData(thisObj, 'ManualDPRInvMainData', {
        "lstrAction": "ADD",
        "lstrRecordKey": "0"
      })
      setData(thisObj, 'ManualDPRInvMainDataUpdate', {})
      goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualDPRInvMaint#DEFAULT#true#Click")
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnEditClick = async () => {
    try {
      setLoading(true)
      let Obj = thisObj.state.gridAuditHistory.selected[0]
      if (Obj != undefined && Obj != null) {
        setData(thisObj, 'ManualDPRInvMainData', {
          "lstrAction": "CHG",
          "lstrRecordKey": Obj.txtcolRecordID
        })

        let js = {
          buyingPt: Obj.txtcolBuyPt,
          peanutType: Obj.txtcolPnutType,
          source: ""
        }

        if (["SC95#", "1007#"].includes(Obj.txtcolAdjustmentSource?.split(":")[0]?.slice(-5))) {
          js.source = "1"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Whs_Rcpt#") {
          js.source = "2"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Xfer#") {
          js.source = "3"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Trade#") {
          js.source = "4"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Manual Inv Adj by user") {
          js.source = "5"
        }

        setData(thisObj, 'ManualDPRInvMainDataUpdate', js)
        setData(thisObj, 'RefreshGrid', true)
        goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualDPRInvMaint#DEFAULT#true#Click")
      }
      else {
        showMessage(thisObj, "Invalid record selected. Select a valid row and try again!")
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick;

  const onbtnDeleteClick = async () => {
    try {
      setLoading(true)
      let Obj = thisObj.state.gridAuditHistory.selected[0]

      if (Obj != undefined && Obj != null) {
        setData(thisObj, 'ManualDPRInvMainData', {
          "lstrAction": "DEL",
          "lstrRecordKey": Obj.txtcolRecordID
        })

        let js = {
          buyingPt: Obj.txtcolBuyPt,
          peanutType: Obj.txtcolPnutType,
          source: ""
        }

        if (["SC95#", "1007#"].includes(Obj.txtcolAdjustmentSource?.split(":")[0]?.slice(-5))) {
          js.source = "1"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Whs_Rcpt#") {
          js.source = "2"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Xfer#") {
          js.source = "3"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Trade#") {
          js.source = "4"
        }

        if (Obj.txtcolAdjustmentSource?.split(":")[0] == "Manual Inv Adj by user") {
          js.source = "5"
        }

        setData(thisObj, 'ManualDPRInvMainDataUpdate', js)
        setData(thisObj, 'RefreshGrid', true)
        goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualDPRInvMaint#DEFAULT#true#Click")
      }
      else {
        showMessage(thisObj, "Invalid record selected. Select a valid row and try again!")
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const ontxt1007NumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txt1007Num')
      setData(thisObj, 'txt1007NumSet', Value)
      if (Value != undefined && Value != null && Value != '' && Value.length > 0 && Value.length < 7) {
        Value = '0'.repeat(7 - Value.length) + Value
        setValue(thisObj, 'txt1007Num', Value)
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxt1007NumBlur = ontxt1007NumBlur;

  const ontxt1007NumFocus = () => {
    try {
      let Value = getData(thisObj, 'txt1007NumSet')
      if (Value != undefined && Value != null && Value != '') {
        setValue(thisObj, 'txt1007Num', Value)
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxt1007NumFocus = ontxt1007NumFocus;

  const ontxt1007NumChange = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txt1007Num')
      if (Value != undefined && Value != null && Value != '') {
        setValue(thisObj, 'txt1007Num', validateTextBox(Value))
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxt1007NumChange = ontxt1007NumChange;

  const ontxtWhseRcptNumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtWhseRcptNum')
      setData(thisObj, 'txtWhseRcptNumSet', Value)
      if (Value != undefined && Value != null && Value != '' && Value.length > 0 && Value.length < 6) {
        Value = '0'.repeat(6 - Value.length) + Value
        setValue(thisObj, 'txtWhseRcptNum', Value)
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtWhseRcptNumBlur = ontxtWhseRcptNumBlur;

  const ontxtWhseRcptNumChange = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtWhseRcptNum')
      setData(thisObj, 'txtWhseRcptNumset', Value)
      if (Value != undefined && Value != null && Value != '') {
        setValue(thisObj, 'txtWhseRcptNum', validateTextBox(Value))
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtWhseRcptNumChange = ontxtWhseRcptNumChange;

  const ontxtWhseRcptNumFocus = () => {
    try {
      let Value = getData(thisObj, 'txtWhseRcptNumset')
      if (Value != undefined && Value != null && Value != '') {
        setValue(thisObj, 'txtWhseRcptNum', Value)
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtWhseRcptNumFocus = ontxtWhseRcptNumFocus;

  const ontxtSC95NumFocus = () => {
    try {
      document.getElementById('txtSC95Num').style.color = ''
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtSC95NumFocus = ontxtSC95NumFocus;

  const ontxtTransferNumFocus = () => {
    try {
      document.getElementById('txtTransferNum').style.color = ''
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtTransferNumFocus = ontxtTransferNumFocus;

  const ontxtTradeSettleNumFocus = () => {
    try {
      document.getElementById('txtTradeSettleNum').style.color = ''
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtTradeSettleNumFocus = ontxtTradeSettleNumFocus;

  const ontxtTradeInspNumFocus = () => {
    try {
      document.getElementById('txtTradeInspNum').style.color = ''
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtTradeInspNumFocus = ontxtTradeInspNumFocus;

  const ontxtAuditUserNumFocus = () => {
    try {
      document.getElementById('txtAuditUserNum').style.color = ''
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtAuditUserNumFocus = ontxtAuditUserNumFocus;

  const ontxtSC95NumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtSC95Num')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtSC95Num').style.color = "red"
      }
      else {
        document.getElementById('txtSC95Num').style.color = ""
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtSC95NumBlur = ontxtSC95NumBlur;

  const ontxtTradeInspNumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtTradeInspNum')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtTradeInspNum').style.color = "red"
      }
      else {
        document.getElementById('txtTradeInspNum').style.color = ""
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtTradeInspNumBlur = ontxtTradeInspNumBlur;

  const ontxtTradeSettleNumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtTradeSettleNum')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtTradeSettleNum').style.color = "red"
      }
      else {
        document.getElementById('txtTradeSettleNum').style.color = ""
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtTradeSettleNumBlur = ontxtTradeSettleNumBlur;

  const ontxtTransferNumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtTransferNum')
      if (Value != undefined && Value != null && Value != '' && Value.length != 6) {
        document.getElementById('txtTransferNum').style.color = "red"
      }
      else {
        document.getElementById('txtTransferNum').style.color = ""
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtTransferNumBlur = ontxtTransferNumBlur;

  const ontxtAuditUserNumBlur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtAuditUserNum')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtAuditUserNum').style.color = "red"
      }
      else {
        document.getElementById('txtAuditUserNum').style.color = ""
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtAuditUserNumBlur = ontxtAuditUserNumBlur;

  const validateTextBox = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
          res += data[i]
        }
      }
      return res;
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const DoSearch = async () => {
    try {
      setLoading(true)
      let strCMD = {}
      let strXML = []
      let iRow = 0
      let iCol = 0
      let dblTotPhysical = 0.0000
      let dblTotRcpted = 0.0000
      let dblTotShrink = 0.0000
      let dblTotUnrcpted = 0.0000
      let dblTotUndeterm = 0.0000
      let dblTotCompOwned = 0.0000
      let dblTotIntransit = 0.0000

      let DataObj = {
        txtcolRecordID : '',
        txtcolPostingDt: '',
        txtcolBuyPt: '',
        txtcolWhsBin: 'Totals:',
        txtcolPnutType: '',
        txtcolPnutVariety : '',
        txtcolPnutSeed: '',
        txtcolPnutSeg: '',
        txtcolPnutOleic: '',
        txtcolPnutOrganic: '',
        txtcolPhysicalAdj: '0.0000',
        txtcolWhsRcptAdj: '0.0000',
        txtcolShrinkAdj: '0.0000',
        txtcolNotRcptdAdj: '0.0000',
        txtcolUndetrminedAdj: '0.0000',
        txtcolCompOwnedAdj: '0.0000',
        txtcolIntransitAdj: '0.0000',
        txtcolAdjustmentSource: '',
        txtcolCreateDate: '',
        txtcolComment: '',
        txtcolChgMainAction: '',
        txtcolChgMaintUser: '',
        txtcolChgMaintDate: '',
        txtcolChgMainComment: ''
      }

      let Datajs = []
      Datajs.push(DataObj)

      strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'I', null)
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        if (strXML[0].permission == 'Y') {
          enable(thisObj, 'btnSearch')
          enable(thisObj, 'btnAuditHistory')
        }
        else {
          disable(thisObj, 'btnSearch')
          disable(thisObj, 'btnAuditHistory')
          return;
        }
      }

      strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'U', null)
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        if (strXML[0].permission == 'Y') {
          enable(thisObj, 'btnAdd')
        }
        else {
          disable(thisObj, 'btnAdd')
        }
      }

      strCMD.action_code = "INV_INQ"
      strCMD.buy_pt_id = getValue(thisObj, 'ddBuyPt') == undefined || getValue(thisObj, 'ddBuyPt') == null || getValue(thisObj, 'ddBuyPt') == '' ? '' : getValue(thisObj, 'ddBuyPt')
      let WhsBinList = thisObj.state['ddWhseBin'].valueList;
      let WhsBinValue = getValue(thisObj, 'ddWhseBin')
      if(WhsBinList != undefined && WhsBinList != null && WhsBinValue != undefined && WhsBinValue != null){
        strCMD.whs_num = WhsBinList.find(elem => elem.key === WhsBinValue).WhouseNum
        strCMD.bin_num = WhsBinList.find(elem => elem.key === WhsBinValue).BinNum
      }
      else{
        strCMD.whs_num = ''
        strCMD.bin_num = ''
      }
      strCMD.whs_num = strCMD.whs_num == undefined || strCMD.whs_num == null ? '' : strCMD.whs_num
      strCMD.bin_num = strCMD.bin_num == undefined || strCMD.bin_num == null ? '' : strCMD.bin_num
      if (getValue(thisObj, 'txtAuditDtTo') != undefined && getValue(thisObj, 'txtAuditDtTo') != null && getValue(thisObj, 'txtAuditDtTo') != '') {
        strCMD.end_date = moment(getValue(thisObj, 'txtAuditDtTo')).format('MM/DD/YYYY')
      }
      else {
        strCMD.end_date = ''
      }
      if (getValue(thisObj, 'txtAuditDtFrom') != undefined && getValue(thisObj, 'txtAuditDtFrom') != null && getValue(thisObj, 'txtAuditDtFrom') != '') {
        strCMD.beg_date = moment(getValue(thisObj, 'txtAuditDtFrom')).format('MM/DD/YYYY')
      }
      else {
        strCMD.beg_date = ''
      }

      strCMD.insp_num = getValue(thisObj, 'txtSC95Num') == undefined || getValue(thisObj, 'txtSC95Num') == null || getValue(thisObj, 'txtSC95Num') == '' ? '' : getValue(thisObj, 'txtSC95Num')
      strCMD.settle_num = getValue(thisObj, 'txt1007Num') == undefined || getValue(thisObj, 'txt1007Num') == null || getValue(thisObj, 'txt1007Num') == '' ? '' : getValue(thisObj, 'txt1007Num')
      strCMD.whs_rcpt = getValue(thisObj, 'txtWhseRcptNum') == undefined || getValue(thisObj, 'txtWhseRcptNum') == null || getValue(thisObj, 'txtWhseRcptNum') == '' ? '' : getValue(thisObj, 'txtWhseRcptNum')
      strCMD.xfer_num = getValue(thisObj, 'txtTransferNum') == undefined || getValue(thisObj, 'txtTransferNum') == null || getValue(thisObj, 'txtTransferNum') == '' ? '' : getValue(thisObj, 'txtTransferNum')
      strCMD.trade_insp = getValue(thisObj, 'txtTradeInspNum') == undefined || getValue(thisObj, 'txtTradeInspNum') == null || getValue(thisObj, 'txtTradeInspNum') == '' ? '' : getValue(thisObj, 'txtTradeInspNum')
      strCMD.trade_settle = getValue(thisObj, 'txtTradeSettleNum') == undefined || getValue(thisObj, 'txtTradeSettleNum') == null || getValue(thisObj, 'txtTradeSettleNum') == '' ? '' : getValue(thisObj, 'txtTradeSettleNum')
      strCMD.audit_user = getValue(thisObj, 'txtAuditUserNum') == undefined || getValue(thisObj, 'txtAuditUserNum') == null || getValue(thisObj, 'txtAuditUserNum') == '' ? '' : getValue(thisObj, 'txtAuditUserNum')
      strCMD.pnut_type = getValue(thisObj, 'ddType') == undefined || getValue(thisObj, 'ddType') == null || getValue(thisObj, 'ddType') == '' ? '' : getValue(thisObj, 'ddType')
      strCMD.pnut_variety = getValue(thisObj, 'ddVariety') == undefined || getValue(thisObj, 'ddVariety') == null || getValue(thisObj, 'ddVariety') == '' ? '' : getValue(thisObj, 'ddVariety')
      strCMD.seed_gen = getValue(thisObj, 'ddGeneration') == undefined || getValue(thisObj, 'ddGeneration') == null || getValue(thisObj, 'ddGeneration') == '' ? '' : getValue(thisObj, 'ddGeneration')
      strCMD.seg_type = getValue(thisObj, 'ddSeg') == undefined || getValue(thisObj, 'ddSeg') == null || getValue(thisObj, 'ddSeg') == '' ? '' : getValue(thisObj, 'ddSeg')
      strCMD.oleic_ind = getValue(thisObj, 'ddOleic') == undefined || getValue(thisObj, 'ddOleic') == null || getValue(thisObj, 'ddOleic') == '' ? '' : getValue(thisObj, 'ddOleic')
      strCMD.organic_ind = getValue(thisObj, 'ddOrganic') == undefined || getValue(thisObj, 'ddOrganic') == null || getValue(thisObj, 'ddOrganic') == '' ? '' : getValue(thisObj, 'ddOrganic')

      let lst = []

      lst = await SystemMaintenanceApplicationSupportService.RetrieveManualInvestmaintDetails(strCMD.action_code, null, strCMD.buy_pt_id, strCMD.whs_num, strCMD.bin_num, strCMD.beg_date, strCMD.end_date, strCMD.insp_num, strCMD.settle_num, strCMD.whs_rcpt, strCMD.xfer_num, strCMD.trade_insp, strCMD.trade_settle, strCMD.pnut_type, strCMD.pnut_variety, strCMD.seed_gen, strCMD.seg_type, strCMD.oleic_ind, strCMD.organic_ind, strCMD.audit_user)
      if(lst != undefined && lst != null){
        strXML = lst[0].inventory_adjustments
      }

      let js = []
      let Obj = {}
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        for (let i = 0; i < strXML.length; i++) {

          Obj.txtcolRecordID = strXML[i].transaction_num
          Obj.txtcolPostingDt = strXML[i].audit_date_time == undefined || strXML[i].audit_date_time == null || strXML[i].audit_date_time == '' ? '' : moment(strXML[i].audit_date_time).format('MM/DD/YYYY')
          Obj.txtcolBuyPt = strXML[i].buy_pt_id
          Obj.txtcolWhsBin = strXML[i].whouse_num + " / " + strXML[i].bin_num
          Obj.txtcolPnutType = strXML[i].pnut_type_id
          Obj.txtcolPnutVariety = strXML[i].pnut_variety_id
          Obj.txtcolPnutSeed = strXML[i].seed_gen
          Obj.txtcolPnutSeg = strXML[i].seg_type

          switch (strXML[i].oleic_ind) {
            case 'H':
              Obj.txtcolPnutOleic = "High"
              break;
            case 'M':
              Obj.txtcolPnutOleic = "Mid"
              break;
            default:
              Obj.txtcolPnutOleic = ""
              break;
          }

          Obj.txtcolPnutOrganic = strXML[i].organic_ind
          Obj.txtcolPhysicalAdj = strXML[i].physical_adj
          Obj.txtcolWhsRcptAdj = strXML[i].whouse_rcpt_adj
          Obj.txtcolShrinkAdj = strXML[i].rcpt_shrink_adj
          Obj.txtcolNotRcptdAdj = strXML[i].unrcpted_adj
          Obj.txtcolUndetrminedAdj = strXML[i].undetermined_adj
          Obj.txtcolCompOwnedAdj = strXML[i].comp_own_adj
          Obj.txtcolIntransitAdj = strXML[i].intransit_adj

          dblTotPhysical = dblTotPhysical + Number(strXML[i].physical_adj)
          dblTotRcpted = dblTotRcpted + Number(strXML[i].whouse_rcpt_adj)
          dblTotShrink = dblTotShrink + Number(strXML[i].rcpt_shrink_adj)
          dblTotUnrcpted = dblTotUnrcpted + Number(strXML[i].unrcpted_adj)
          dblTotUndeterm = dblTotUndeterm + Number(strXML[i].undetermined_adj)
          dblTotCompOwned = dblTotCompOwned + Number(strXML[i].comp_own_adj)
          dblTotIntransit = dblTotIntransit + Number(strXML[i].intransit_adj)

          if (strXML[i].audit_insp_num != undefined && strXML[i].audit_insp_num != null && strXML[i].audit_insp_num != '') {
            if (strXML[i].audit_settle_num.length > 0 && strXML[i].audit_settle_num < 7) {
              strXML[i].audit_settle_num = '0'.repeat((7 - strXML[i].audit_settle_num.length)) + strXML[i].audit_settle_num
            }
            strXML[i].audit_settle_num = strXML[i].audit_settle_num == undefined || strXML[i].audit_settle_num == null || strXML[i].audit_settle_num == '' ? '' : "1007#:" + strXML[i].audit_settle_num
            Obj.txtcolAdjustmentSource = strXML[i].audit_settle_num + "SC95#:" + strXML[i].audit_insp_num + " @ BuyPt:" + strXML[i].audit_buy_pt
          }

          if (strXML[i].audit_whse_rcpt != undefined && strXML[i].audit_whse_rcpt != null && strXML[i].audit_whse_rcpt != '') {
            Obj.txtcolAdjustmentSource = "Whs_Rcpt#:" + strXML[i].audit_whse_rcpt + " @ BuyPt:" + strXML[i].audit_buy_pt
          }

          if (strXML[i].audit_xfer_num != undefined && strXML[i].audit_xfer_num != null && strXML[i].audit_xfer_num != '') {
            Obj.txtcolAdjustmentSource = "Xfer#:" + strXML[i].audit_xfer_num + " Shipped From:" + strXML[i].audit_buy_pt
          }

          if (strXML[i].audit_trade_insp != undefined && strXML[i].audit_trade_insp != null && strXML[i].audit_trade_insp != '') {
            strXML[i].audit_trade_settle = strXML[i].audit_trade_settle == undefined || strXML[i].audit_trade_settle == null ? '' : " / " + strXML[i].audit_trade_settle
            Obj.txtcolAdjustmentSource = "Trade#: " + strXML[i].audit_trade_insp + strXML[i].audit_trade_settle + " @ BuyPt:" + strXML[i].audit_buy_pt
          }

          if (strXML[i].audit_user != undefined && strXML[i].audit_user != null && strXML[i].audit_user != '') {
            strXML[i].audit_trade_settle = strXML[i].audit_trade_settle == undefined || strXML[i].audit_trade_settle == null ? '' : " / " + strXML[i].audit_trade_settle
            Obj.txtcolAdjustmentSource = "Manual Inv Adj by user: " + strXML[i].audit_user
          }

          Obj.txtcolCreateDate = strXML[i].add_date_time == undefined || strXML[i].add_date_time == null || strXML[i].add_date_time == '' ? '' : moment(strXML[i].add_date_time).format('MM/DD/YYYY')
          Obj.txtcolComment = strXML[i].audit_reason
          Obj.txtcolChgMainAction = strXML[i].chg_maint_action
          Obj.txtcolChgMaintUser = strXML[i].chg_maint_user
          Obj.txtcolChgMaintDate = strXML[i].chg_maint_date_time == undefined || strXML[i].chg_maint_date_time == null || strXML[i].chg_maint_date_time == '' ? '' : moment(strXML[i].chg_maint_date_time).format('MM/DD/YYYY')
          Obj.txtcolChgMainComment = strXML[i].chg_maint_reason

          js.push(Obj)
          Obj = {}
        }


        // Obj.txtcolRecordID = ''
        // Obj.txtcolPostingDt = ''
        // Obj.txtcolBuyPt = ''
        // Obj.txtcolPnutType = ''
        // Obj.txtcolPnutVariety = ''
        // Obj.txtcolPnutSeed = ''
        // Obj.txtcolPnutSeg = ''
        // Obj.txtcolPnutOleic = ''
        // Obj.txtcolPnutOrganic = ''
        // Obj.txtcolAdjustmentSource = ''
        // Obj.txtcolCreateDate = ''
        // Obj.txtcolComment = ''
        // Obj.txtcolChgMaintUser = ''
        // Obj.txtcolChgMaintDate = ''
        // Obj.txtcolChgMainComment = ''
        // Obj.txtcolChgMainAction = ''

        Obj.txtcolWhsBin = "Totals:"
        Obj.txtcolPhysicalAdj = dblTotPhysical.toFixed(4)
        Obj.txtcolWhsRcptAdj = dblTotRcpted.toFixed(4)
        Obj.txtcolShrinkAdj = dblTotShrink.toFixed(4)
        Obj.txtcolNotRcptdAdj = dblTotUnrcpted.toFixed(4)
        Obj.txtcolUndetrminedAdj = dblTotUndeterm.toFixed(4)
        Obj.txtcolCompOwnedAdj = dblTotCompOwned.toFixed(4)
        Obj.txtcolIntransitAdj = dblTotIntransit.toFixed(4)
        js.push(Obj)
        Obj = {}

        setValue(thisObj, 'txtTotalPhysical', NumberFormatting(dblTotPhysical.toFixed(4)))
        setValue(thisObj, 'txtTotalReceipted', NumberFormatting(dblTotRcpted.toFixed(4)))
        setValue(thisObj, 'txtTotalShrink', NumberFormatting(dblTotShrink.toFixed(4)))
        setValue(thisObj, 'txtTotalUnreceipted', NumberFormatting(dblTotUnrcpted.toFixed(4)))
        setValue(thisObj, 'txtTotalUndetermined', NumberFormatting(dblTotUndeterm.toFixed(4)))
        setValue(thisObj, 'txtTotalCompOwned', NumberFormatting(dblTotCompOwned.toFixed(4)))
        setValue(thisObj, 'txtTotalIntransit', NumberFormatting(dblTotIntransit.toFixed(4)))

        enable(thisObj, 'btnEdit')
        enable(thisObj, 'btnDelete')
        enable(thisObj, 'btnExportToExcel')
        enable(thisObj, 'btnPrint')
        setValue(thisObj, 'gridAuditHistory', js)
        hideColumn(thisObj, 'gridAuditHistory', 'txtcolRecordID')
      }
      else {
        disable(thisObj, 'btnEdit')
        disable(thisObj, 'btnDelete')
        disable(thisObj, 'btnExportToExcel')
        disable(thisObj, 'btnPrint')
        showMessage(thisObj, "No Inventory Records found!")
        setValue(thisObj, 'gridAuditHistory', Datajs)
        setValue(thisObj, 'txtTotalPhysical', '0.0000')
        setValue(thisObj, 'txtTotalReceipted', '0.0000')
        setValue(thisObj, 'txtTotalShrink', '0.0000')
        setValue(thisObj, 'txtTotalUnreceipted', '0.0000')
        setValue(thisObj, 'txtTotalUndetermined', '0.0000')
        setValue(thisObj, 'txtTotalCompOwned', '0.0000')
        setValue(thisObj, 'txtTotalIntransit', '0.0000')
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }

  const NumberFormatting = (Value) =>{
    let NumArray = Value.split('.')
    let Num = NumArray[0]
    let Decimal = NumArray[1]

    let Result = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal
    return Result
  }

  const bFormValid = async () => {
    try {
      let isbFormValid = false
      let ddBuyPtValue = getValue(thisObj, 'ddBuyPt')
      let txtSC95NumValue = getValue(thisObj, 'txtSC95Num')
      let txt1007NumValue = getValue(thisObj, 'txt1007Num')
      let txtWhseRcptNumValue = getValue(thisObj, 'txtWhseRcptNum')
      let txtTransferNumValue = getValue(thisObj, 'txtTransferNum')
      let txtTradeInspNumValue = getValue(thisObj, 'txtTradeInspNum')
      let txtTradeSettleNumValue = getValue(thisObj, 'txtTradeSettleNum')
      let txtAuditUserNumValue = getValue(thisObj, 'txtAuditUserNum')
      let txtMinAuditDateValue = getValue(_kaledo.thisObj, 'txtAuditDtFrom')
      let txtMaxAuditDateValue = getValue(_kaledo.thisObj, 'txtAuditDtTo')
      txtMinAuditDateValue = txtMinAuditDateValue == undefined || txtMinAuditDateValue == null || txtMinAuditDateValue == '' ? '' : moment(txtMinAuditDateValue).format('MM/DD/YYYY')
      txtMaxAuditDateValue = txtMaxAuditDateValue == undefined || txtMaxAuditDateValue == null || txtMaxAuditDateValue == '' ? '' : moment(txtMaxAuditDateValue).format('MM/DD/YYYY')

      var item = [
        "Buying Point",
        "SC95 #",
        "1007 #",
        "Whse Receipt #",
        "Transfer #",
        "Trade Insp #",
        "Trade Settle #",
      ]
      var alertmessage = "You must specify to search by one of the following:\n";
      for (var i = 0; i < item.length; i++) {
        alertmessage += (i + 1) + '. ' + item[i] + "\n";
      }

      if (
        (ddBuyPtValue == undefined || ddBuyPtValue == null || ddBuyPtValue == '') &&
        (txtSC95NumValue == undefined || txtSC95NumValue == null || txtSC95NumValue == '') &&
        (txt1007NumValue == undefined || txt1007NumValue == null || txt1007NumValue == '') &&
        (txtWhseRcptNumValue == undefined || txtWhseRcptNumValue == null || txtWhseRcptNumValue == '') &&
        (txtTransferNumValue == undefined || txtTransferNumValue == null || txtTransferNumValue == '') &&
        (txtTradeInspNumValue == undefined || txtTradeInspNumValue == null || txtTradeInspNumValue == '') &&
        (txtTradeSettleNumValue == undefined || txtTradeSettleNumValue == null || txtTradeSettleNumValue == '') &&
        (txtAuditUserNumValue == undefined || txtAuditUserNumValue == null || txtAuditUserNumValue == '')
      ) {
        alert(alertmessage)
        return isbFormValid;
      }

      if (getValue(thisObj, 'txtAuditDtFrom') != undefined && getValue(thisObj, 'txtAuditDtFrom') != null && isDate(txtMinAuditDateValue) == true) {
        showMessage(thisObj, "Begining Audit Date is not a valid date!")
        document.getElementById("txtAuditDtFrom").focus();
        return isbFormValid;
      }

      if (getValue(thisObj, 'txtAuditDtTo') != undefined && getValue(thisObj, 'txtAuditDtTo') != null && isDate(txtMaxAuditDateValue) == true) {
        showMessage(thisObj, "Ending Audit Date is not a valid date!")
        document.getElementById("txtAuditDtTo").focus();
        return isbFormValid;
      }

      var date1 = new Date(txtMinAuditDateValue);
      var date2 = new Date(txtMaxAuditDateValue);
      if (txtMinAuditDateValue != '' && txtMaxAuditDateValue != '') {
        if ((date2.getFullYear() - date1.getFullYear()) < 0) {
          showMessage(thisObj, "The Begining Audit Date must be less than or equal to the Ending Audit Date!")
          document.getElementById('txtAuditDtFromParent').firstChild.style.color = 'red'
          document.getElementById('txtAuditDtToParent').firstChild.style.color = 'red'
          return isbFormValid;
        }
        else if ((date2.getFullYear() - date1.getFullYear()) == 0) {
          if ((date2.getMonth() - date1.getMonth()) < 0) {
            showMessage(thisObj, "The Begining Audit Date must be less than or equal to the Ending Audit Date!")
            document.getElementById('txtAuditDtFromParent').firstChild.style.color = 'red'
            document.getElementById('txtAuditDtToParent').firstChild.style.color = 'red'
            return isbFormValid;
          }
          else if ((date2.getMonth() - date1.getMonth()) == 0) {
            if ((date2.getDate() - date1.getDate()) < 0) {
              showMessage(thisObj, "The Begining Audit Date must be less than or equal to the Ending Audit Date!")
              document.getElementById('txtAuditDtFromParent').firstChild.style.color = 'red'
              document.getElementById('txtAuditDtToParent').firstChild.style.color = 'red'
              return isbFormValid;
            }
            else{
              document.getElementById('txtAuditDtFromParent').firstChild.style.color = ''
              document.getElementById('txtAuditDtToParent').firstChild.style.color = ''
            }
          }
          else{
            document.getElementById('txtAuditDtFromParent').firstChild.style.color = ''
            document.getElementById('txtAuditDtToParent').firstChild.style.color = ''
          }
        }
        else{
          document.getElementById('txtAuditDtFromParent').firstChild.style.color = ''
          document.getElementById('txtAuditDtToParent').firstChild.style.color = ''
        }
      }


      if (txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '' && txtSC95NumValue.length != 7) {
        showMessage(thisObj, "SC95 # must be 7 characters!")
        document.getElementById("txtSC95Num").focus();
        return isbFormValid;
      }

      if (txt1007NumValue != undefined && txt1007NumValue != null && txt1007NumValue != '' && txt1007NumValue.length != 7) {
        showMessage(thisObj, "1007 # must be 7 characters!")
        document.getElementById("txt1007Num").focus();
        return isbFormValid;
      }

      if (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' && txtWhseRcptNumValue.length != 6) {
        showMessage(thisObj, "Whse Receipt # must be 6 characters!")
        document.getElementById("txtWhseRcptNum").focus();
        return isbFormValid;
      }


      if (txtTransferNumValue != undefined && txtTransferNumValue != null && txtTransferNumValue != '' && txtTransferNumValue.length != 6) {
        showMessage(thisObj, "Transfer # must be 6 characters!")
        document.getElementById("txtTransferNum").focus();
        return isbFormValid;
      }

      if (txtTradeInspNumValue != undefined && txtTradeInspNumValue != null && txtTradeInspNumValue != '' && txtTradeInspNumValue.length != 7) {
        showMessage(thisObj, "Trade Inspection # must be 7 characters!")
        document.getElementById("txtTradeInspNum").focus();
        return isbFormValid;
      }

      if (txtTradeSettleNumValue != undefined && txtTradeSettleNumValue != null && txtTradeSettleNumValue != '' && txtTradeSettleNumValue.length != 7) {
        showMessage(thisObj, "Trade Settlement # must be 7 characters!")
        document.getElementById("txtTradeSettleNum").focus();
        return isbFormValid;
      }

      if (txtAuditUserNumValue != undefined && txtAuditUserNumValue != null && txtAuditUserNumValue != '' && txtAuditUserNumValue.length != 7) {
        showMessage(thisObj, " Audit User # must be 7 characters!")
        document.getElementById("txtAuditUserNum").focus();
        return isbFormValid;
      }

      if (
        ((txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') ||
          (txt1007NumValue != undefined && txt1007NumValue != null && txt1007NumValue != '')) &&
        (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '')
      ) {
        showMessage(thisObj, " Searching by SC95/1007 and Whs Receipt will not return any results!")
        if (txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') {
          document.getElementById("txtSC95Num").focus();
        } else {
          document.getElementById("txt1007Num").focus();
        }
        return isbFormValid;
      }

      if (
        ((txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') ||
          (txt1007NumValue != undefined && txt1007NumValue != null && txt1007NumValue != '')) &&
        (txtTransferNumValue != undefined && txtTransferNumValue != null && txtTransferNumValue != '')
      ) {
        showMessage(thisObj, " Searching by SC95/1007 and Transfer will not return any results!")
        if (txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') {
          document.getElementById("txtSC95Num").focus();
        } else {
          document.getElementById("txt1007Num").focus();
        }
        return isbFormValid;
      }

      if (
        ((txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') ||
          (txt1007NumValue != undefined && txt1007NumValue != null && txt1007NumValue != '')) &&
        ((txtTradeInspNumValue != undefined && txtTradeInspNumValue != null && txtTradeInspNumValue != '') ||
          (txtTradeSettleNumValue != undefined && txtTradeSettleNumValue != null && txtTradeSettleNumValue != ''))
      ) {
        showMessage(thisObj, " Searching by SC95/1007 and Trade Settlment will not return any results!")
        if (txtSC95NumValue != undefined && txtAuditUserNum != null && txtAuditUserNum != '') {
          document.getElementById("txtSC95Num").focus();
        } else {
          document.getElementById("txt1007Num").focus();
        }
        return isbFormValid;
      }

      if (
        ((txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') ||
          (txt1007NumValue != undefined && txt1007NumValue != null && txt1007NumValue != '')) &&
        (txtAuditUserNumValue != undefined && txtAuditUserNumValue != null && txtAuditUserNumValue != '')
      ) {
        showMessage(thisObj, " Searching by SC95/1007 and Audit User will not return any results!")
        if (txtSC95NumValue != undefined && txtSC95NumValue != null && txtSC95NumValue != '') {
          document.getElementById("txtSC95Num").focus();
        } else {
          document.getElementById("txt1007Num").focus();
        }
        return isbFormValid;
      }

      if (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' &&
        txtTransferNumValue != undefined && txtTransferNumValue != null && txtTransferNumValue != ''
      ) {
        showMessage(thisObj, " Searching by Whs Receipt and Transfer will not return any results!")
        document.getElementById("txtWhseRcptNum").focus();
        return isbFormValid;
      }

      if (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' &&
        ((txtTradeInspNumValue != undefined && txtTradeInspNumValue != null && txtTradeInspNumValue != '') ||
          (txtTradeSettleNumValue != undefined && txtTradeSettleNumValue != null && txtTradeSettleNumValue != ''))
      ) {
        showMessage(thisObj, " Searching by Whs Receipt and Trade Settlment will not return any results!")
        document.getElementById("txtWhseRcptNum").focus();
        return isbFormValid;
      }

      if (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' &&
        txtAuditUserNumValue != undefined && txtAuditUserNumValue != null && txtAuditUserNumValue != ''
      ) {
        showMessage(thisObj, " Searching by Whs Receipt and Audit User will not return any results!")
        document.getElementById("txtWhseRcptNum").focus();
        return isbFormValid;
      }

      if (txtTransferNumValue != undefined && txtTransferNumValue != null && txtTransferNumValue != '' &&
        ((txtTradeInspNumValue != undefined && txtTradeInspNumValue != null && txtTradeInspNumValue != '') ||
          (txtTradeSettleNumValue != undefined && txtTradeSettleNumValue != null && txtTradeSettleNumValue != ''))
      ) {
        showMessage(thisObj, " Searching by Transfer and Trade Settlment will not return any results!")
        document.getElementById("txtTransferNum").focus();
        return isbFormValid;
      }

      if (txtTransferNumValue != undefined && txtTransferNumValue != null && txtTransferNumValue != '' &&
        txtAuditUserNumValue != undefined && txtAuditUserNumValue != null && txtAuditUserNumValue != ''
      ) {
        showMessage(thisObj, " Searching by Transfer and Audit User will not return any results!")
        document.getElementById("txtTransferNum").focus();
        return isbFormValid;
      }

      if (((txtTradeInspNumValue != undefined && txtTradeInspNumValue != null && txtTradeInspNumValue != '') ||
        (txtTradeSettleNumValue != undefined && txtTradeSettleNumValue != null && txtTradeSettleNumValue != '')) &&
        txtAuditUserNumValue != undefined && txtAuditUserNumValue != null && txtAuditUserNumValue != ''
      ) {
        showMessage(thisObj, " Searching by Trade Settlment and Audit User will not return any results!")
        if (txtTradeInspNumValue != undefined && txtTradeInspNumValue != null && txtTradeInspNumValue != '') {
          document.getElementById("txtTradeInspNum").focus();
          return isbFormValid;
        }
        else {
          document.getElementById("txtTradeSettleNum").focus();
          return isbFormValid;
        }
      }


      isbFormValid = true
      return isbFormValid
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const onbtnSearchClick = async () => {
    try {
      setLoading(true)
      let isbFormValid = false
      await bFormValid().then(response => {
        isbFormValid = response
      })

      if (isbFormValid == false) {
        return;
      } else {
        await DoSearch()
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const ontxtAuditDtFromBlur = () => {
    try {
      setLoading(true)
      let Value = moment(new Date()).format('MM/DD/YYYY')
      let txtAuditDtFromValue = getValue(thisObj, 'txtAuditDtFrom')
      if (txtAuditDtFromValue != undefined && txtAuditDtFromValue != null && isDate(txtAuditDtFromValue) == true) {
        Value = moment(txtAuditDtFromValue).format('MM/DD/YYYY')
      }
      setValue(thisObj, 'txtAuditDtFrom', Value)


      let txtAuditDateFromValue = getValue(thisObj, 'txtAuditDtFrom') == undefined || getValue(thisObj, 'txtAuditDtFrom') == null || getValue(thisObj, 'txtAuditDtFrom') == '' ? '' : moment(getValue(thisObj, 'txtAuditDtFrom')).format('MM/DD/YYYY')
      let txtAuditDateToValue = getValue(thisObj, 'txtAuditDtTo') == undefined || getValue(thisObj, 'txtAuditDtTo') == null || getValue(thisObj, 'txtAuditDtTo') == '' ? '' : moment(getValue(thisObj, 'txtAuditDtTo')).format('MM/DD/YYYY')

      if (txtAuditDateFromValue == '') {
        return;
      }

      if (isDate(txtAuditDateFromValue) == true) {
        document.getElementById('txtAuditDtFrom').style.color = "red"
        return
      }

      if (isDate(txtAuditDateToValue) == true) {
        document.getElementById('txtAuditDtTo').style.color = "red"
        return
      }

      var date1 = new Date(txtAuditDateFromValue);
      var date2 = new Date(txtAuditDateToValue);
      if (txtAuditDateFromValue != '' && txtAuditDateToValue != '') {
        if ((date2.getFullYear() - date1.getFullYear()) < 0) {
          document.getElementById('txtAuditDtFrom').style.color = "red"
          document.getElementById('txtAuditDtTo').style.color = "red"
        }
        else if ((date2.getFullYear() - date1.getFullYear()) == 0) {
          if ((date2.getMonth() - date1.getMonth()) < 0) {
            document.getElementById('txtAuditDtFrom').style.color = "red"
            document.getElementById('txtAuditDtTo').style.color = "red"
          }
          else if ((date2.getMonth() - date1.getMonth()) == 0) {
            if ((date2.getDate() - date1.getDate()) < 0) {
              document.getElementById('txtAuditDtFrom').style.color = "red"
              document.getElementById('txtAuditDtTo').style.color = "red"
            }
            else {
              document.getElementById('txtAuditDtFrom').style.color = ""
              document.getElementById('txtAuditDtTo').style.color = ""
            }
          }
          else {
            document.getElementById('txtAuditDtFrom').style.color = ""
            document.getElementById('txtAuditDtTo').style.color = ""
          }
        }
        else {
          document.getElementById('txtAuditDtFrom').style.color = ""
          document.getElementById('txtAuditDtTo').style.color = ""
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtAuditDtFromBlur = ontxtAuditDtFromBlur;

  const ontxtAuditDtToBlur = () => {
    try {
      setLoading(true)
      let txtAuditDateFromValue = getValue(thisObj, 'txtAuditDtFrom') == undefined || getValue(thisObj, 'txtAuditDtFrom') == null || getValue(thisObj, 'txtAuditDtFrom') == '' ? '' : moment(getValue(thisObj, 'txtAuditDtFrom')).format('MM/DD/YYYY')
      let txtAuditDateToValue = getValue(thisObj, 'txtAuditDtTo') == undefined || getValue(thisObj, 'txtAuditDtTo') == null || getValue(thisObj, 'txtAuditDtTo') == '' ? '' : moment(getValue(thisObj, 'txtAuditDtTo')).format('MM/DD/YYYY')

      if (txtAuditDateToValue == '') {
        return
      }

      if (isDate(txtAuditDateToValue) == true) {
        document.getElementById('txtAuditDtTo').style.color = "red"
        return
      }

      if (isDate(txtAuditDateFromValue) == true) {
        document.getElementById('txtAuditDtFrom').style.color = "red"
        return
      }

      var date1 = new Date(txtAuditDateFromValue);
      var date2 = new Date(txtAuditDateToValue);
      if (txtAuditDateFromValue != '' && txtAuditDateToValue != '') {
        if ((date2.getFullYear() - date1.getFullYear()) < 0) {
          document.getElementById('txtAuditDtFrom').style.color = "red"
          document.getElementById('txtAuditDtTo').style.color = "red"
        }
        else if ((date2.getFullYear() - date1.getFullYear()) == 0) {
          if ((date2.getMonth() - date1.getMonth()) < 0) {
            document.getElementById('txtAuditDtFrom').style.color = "red"
            document.getElementById('txtAuditDtTo').style.color = "red"
          }
          else if ((date2.getMonth() - date1.getMonth()) == 0) {
            if ((date2.getDate() - date1.getDate()) < 0) {
              document.getElementById('txtAuditDtFrom').style.color = "red"
              document.getElementById('txtAuditDtTo').style.color = "red"
            }
            else {
              document.getElementById('txtAuditDtFrom').style.color = ""
              document.getElementById('txtAuditDtTo').style.color = ""
            }
          }
          else {
            document.getElementById('txtAuditDtFrom').style.color = ""
            document.getElementById('txtAuditDtTo').style.color = ""
          }
        }
        else {
          document.getElementById('txtAuditDtFrom').style.color = ""
          document.getElementById('txtAuditDtTo').style.color = ""
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtAuditDtToBlur = ontxtAuditDtToBlur;

  const onbtnExportToExcelClick = () => {
    try {
      setLoading(true)
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridAuditHistory")
      var fileName = "Inventory_Adjustments"

      let headerArray = []
      for(var i = 0; i < thisObj.state.gridAuditHistory.columns.length; i++){
        if(thisObj.state.gridAuditHistory.columns[i].Visible){
          headerArray.push(thisObj.state.gridAuditHistory.columns[i].Label)
        }
      }


      let arrayData = []
      let rowData = {}

      if (excelData.length > 0) {
        for (var i = 0; i < excelData.length; i++) {

          if(thisObj.state.gridAuditHistory.columns[0].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[0].Label] = excelData[i].txtcolRecordID
          }
          if(thisObj.state.gridAuditHistory.columns[1].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[1].Label] = moment(excelData[i].txtcolPostingDt).format('MM/DD/YYYY') == 'Invalid date' ? '' : moment(excelData[i].txtcolPostingDt).format('MM/DD/YYYY')
          }
          if(thisObj.state.gridAuditHistory.columns[2].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[2].Label] = excelData[i].txtcolBuyPt
          }
          if(thisObj.state.gridAuditHistory.columns[3].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[3].Label] = excelData[i].txtcolWhsBin
          }
          if(thisObj.state.gridAuditHistory.columns[4].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[4].Label] = excelData[i].txtcolPnutType
          }
          if(thisObj.state.gridAuditHistory.columns[5].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[5].Label] = excelData[i].txtcolPnutVariety
          }
          if(thisObj.state.gridAuditHistory.columns[6].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[6].Label] = excelData[i].txtcolPnutSeed
          }
          if(thisObj.state.gridAuditHistory.columns[7].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[7].Label] = excelData[i].txtcolPnutSeg
          }
          if(thisObj.state.gridAuditHistory.columns[8].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[8].Label] = excelData[i].txtcolPnutOleic
          }
          if(thisObj.state.gridAuditHistory.columns[9].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[9].Label] = excelData[i].txtcolPnutOrganic
          }
          if(thisObj.state.gridAuditHistory.columns[10].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[10].Label] = excelData[i].txtcolPhysicalAdj
          }
          if(thisObj.state.gridAuditHistory.columns[11].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[11].Label] = excelData[i].txtcolWhsRcptAdj
          }
          if(thisObj.state.gridAuditHistory.columns[12].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[12].Label] = excelData[i].txtcolShrinkAdj
          }
          if(thisObj.state.gridAuditHistory.columns[13].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[13].Label] = excelData[i].txtcolNotRcptdAdj
          }
          if(thisObj.state.gridAuditHistory.columns[14].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[14].Label] = excelData[i].txtcolUndetrminedAdj
          }
          if(thisObj.state.gridAuditHistory.columns[15].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[15].Label] = excelData[i].txtcolCompOwnedAdj
          }
          if(thisObj.state.gridAuditHistory.columns[16].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[16].Label] = excelData[i].txtcolIntransitAdj
          }
          if(thisObj.state.gridAuditHistory.columns[17].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[17].Label] = excelData[i].txtcolAdjustmentSource
          }
          if(thisObj.state.gridAuditHistory.columns[18].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[18].Label] =  moment(excelData[i].txtcolCreateDate).format('MM/DD/YYYY') == 'Invalid date' ? '' : moment(excelData[i].txtcolCreateDate).format('MM/DD/YYYY')
          }
          if(thisObj.state.gridAuditHistory.columns[19].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[19].Label] = excelData[i].txtcolComment
          }
          if(thisObj.state.gridAuditHistory.columns[20].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[20].Label] = excelData[i].txtcolChgMainAction
          }
          if(thisObj.state.gridAuditHistory.columns[21].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[21].Label] = excelData[i].txtcolChgMaintUser
          }
          if(thisObj.state.gridAuditHistory.columns[22].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[22].Label] = excelData[i].txtcolChgMaintDate
          }
          if(thisObj.state.gridAuditHistory.columns[23].Visible){
            rowData[thisObj.state.gridAuditHistory.columns[23].Label] = excelData[i].txtcolChgMainComment
          }
         

          arrayData.push(rowData);
          rowData = {}

        }
      }

      const ws = XLSX.utils.json_to_sheet(arrayData)
      // var wscols = [
      //   { width: 0 },
      // ];
      // ws['!cols'] = wscols;
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnPrintClick = () => {
    try {
      setLoading(true)
      var obj = new jsPDF('landscape', 'mm', 'a4');
      let datatable = getValue(thisObj, "gridAuditHistory");
      if (datatable != undefined && datatable != null && datatable != "") {
        let data = []
        let data2 = []
        let headerarray = []
        let headerarray1 = []
        let headerarray2 = []

        for(var i = 0; i < thisObj.state.gridAuditHistory.columns.length; i++){
          if(thisObj.state.gridAuditHistory.columns[i].Visible){
            headerarray.push(thisObj.state.gridAuditHistory.columns[i].Label)
          }
        }

        if (headerarray.length > 16) {
          headerarray1 = headerarray.slice(0, 16)
          headerarray2 = headerarray.slice(16, 23)
        }
        else {
          headerarray1 = headerarray
        }

        let Obj = { datatable: CompName + " - " + cropYearFromLS() + " " + "Vendor Search Results " }
        let count = 0;

        for (var i = 0; i < datatable.length; i++) {
          let bodyarray = []
          let childArray1 = []
          let childArray2 = []

          if(thisObj.state.gridAuditHistory.columns[0].Visible){
            bodyarray.push(datatable[i].txtcolRecordID)
          }
          if(thisObj.state.gridAuditHistory.columns[1].Visible){
            bodyarray.push(datatable[i].txtcolPostingDt)
          }
          if(thisObj.state.gridAuditHistory.columns[2].Visible){
            bodyarray.push(datatable[i].txtcolBuyPt)
          }
          if(thisObj.state.gridAuditHistory.columns[3].Visible){
            bodyarray.push(datatable[i].txtcolWhsBin)
          }
          if(thisObj.state.gridAuditHistory.columns[4].Visible){
            bodyarray.push(datatable[i].txtcolPnutType)
          }
          if(thisObj.state.gridAuditHistory.columns[5].Visible){
            bodyarray.push(datatable[i].txtcolPnutVariety)
          }
          if(thisObj.state.gridAuditHistory.columns[6].Visible){
            bodyarray.push(datatable[i].txtcolPnutSeed)
          }
          if(thisObj.state.gridAuditHistory.columns[7].Visible){
            bodyarray.push(datatable[i].txtcolPnutSeg)
          }
          if(thisObj.state.gridAuditHistory.columns[8].Visible){
            bodyarray.push(datatable[i].txtcolPnutOleic)
          }
          if(thisObj.state.gridAuditHistory.columns[9].Visible){
            bodyarray.push(datatable[i].txtcolPnutOrganic)
          }
          if(thisObj.state.gridAuditHistory.columns[10].Visible){
            bodyarray.push(datatable[i].txtcolPhysicalAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[11].Visible){
            bodyarray.push(datatable[i].txtcolWhsRcptAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[12].Visible){
            bodyarray.push(datatable[i].txtcolShrinkAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[13].Visible){
            bodyarray.push(datatable[i].txtcolNotRcptdAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[14].Visible){
            bodyarray.push(datatable[i].txtcolUndetrminedAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[15].Visible){
            bodyarray.push(datatable[i].txtcolCompOwnedAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[16].Visible){
            bodyarray.push(datatable[i].txtcolIntransitAdj)
          }
          if(thisObj.state.gridAuditHistory.columns[17].Visible){
            bodyarray.push(datatable[i].txtcolAdjustmentSource)
          }
          if(thisObj.state.gridAuditHistory.columns[18].Visible){
            bodyarray.push(datatable[i].txtcolCreateDate)
          }
          if(thisObj.state.gridAuditHistory.columns[19].Visible){
            bodyarray.push(datatable[i].txtcolComment)
          }
          if(thisObj.state.gridAuditHistory.columns[20].Visible){
            bodyarray.push(datatable[i].txtcolChgMainAction)
          }
          if(thisObj.state.gridAuditHistory.columns[21].Visible){
            bodyarray.push(datatable[i].txtcolChgMaintUser)
          }
          if(thisObj.state.gridAuditHistory.columns[22].Visible){
            bodyarray.push(datatable[i].txtcolChgMaintDate)
          }
          if(thisObj.state.gridAuditHistory.columns[23].Visible){
            bodyarray.push(datatable[i].txtcolChgMainComment)
          }

          
          if (headerarray.length > 16) {
            data.push(bodyarray.slice(0, 16))
            data2.push(bodyarray.slice(16, 23))
          }
          else {
            data.push(bodyarray)
          }
  
        }

        let pagelimit = 0
        var Array = []
        // Obj = { datatable: cropyear + " " + "Vendor Search Results " }
        // Array.push(Obj)
        let N = 0;

        if (headerarray.length > 16) {
          while (N < data.length) {
            // first set of 9 columns
            obj.autoTable({
              headStyles: { fontSize: 6.5, cellWidth: 'wrap' },
              styles: { fontSize: 6.5, lineWidth: 0.2 },
              margin: { top: 8, left: 3 },
              theme: 'plain',
              fontStyle: 'normal',
              head: [
                headerarray1
              ],
              body: data.slice(N, N + 23)
            })
  
            // footer of first 9 columns
            obj.autoTable({
              startY: 15,
              startX: 5,
              styles: { fontSize: 5, lineWidth: 0, color: 10 },
              margin: { bottom: 1, left: 3 },
              body: Array,
              theme: 'plain',
              fontStyle: 'normal',
            });
  
            obj.addPage();//Adding New page
  
            // remaining set of columns
            obj.autoTable({
              headStyles: { fontSize: 6.5, cellWidth: 'wrap' },
              styles: { fontSize: 6.5, lineWidth: 0.2 },
              margin: { top: 8, left: 3 },
              theme: 'plain',
              fontStyle: 'normal',
              head: [
                headerarray2
              ],
              body: data2.slice(N, N + 23)
  
            })
  
            // footer of remaining columns
            obj.autoTable({
              startY: 195,
              startX: 5,
              styles: { fontSize: 5, lineWidth: 0, color: 10 },
              margin: { bottom: 1, left: 3 },
              body: Array,
              theme: 'plain',
              fontStyle: 'normal',
            });
            N = N + 23;

            obj.addPage();//Adding New page
          }
        }
        else {
          while (N < data.length) {
            // first set of 9 columns
            obj.autoTable({
              headStyles: { fontSize: 6.5, cellWidth: 'wrap' },
              styles: { fontSize: 6.5, lineWidth: 0.2 },
              margin: { top: 8, left: 3 },
              theme: 'plain',
              fontStyle: 'normal',
              head: [
                headerarray1
              ],
              body: data.slice(N, N + 23)
            })
  
            // footer of first 9 columns
            obj.autoTable({
              startY: 15,
              startX: 5,
              styles: { fontSize: 5, lineWidth: 0, color: 10 },
              margin: { bottom: 1, left: 3 },
              body: Array,
              theme: 'plain',
              fontStyle: 'normal',
            });

            N = N + 23;
            obj.addPage();//Adding New page

          }
        }

        // Page count 
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 1; w <= pageCount; w++) {
          obj.setPage(w);
          obj.setFontSize(6.5);
          obj.text(CompName + " - " + cropYearFromLS() + ' - Inventory Adjusments', 2, 200);
          obj.text(280, 200, String(w))
        }

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message)
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;


  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualDPRInvSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ManualDPRInvSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualDPRInvSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualDPRInvSearch*/}

              <GroupBoxWidget
                conf={state.grpbxManualDPRInvSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxBuyPtDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBuyPtDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxBuyPtDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyPt*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyPt}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_ddWhseBin*/}

                  {/* END_USER_CODE-USER_BEFORE_ddWhseBin*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddWhseBin}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddWhseBin*/}

                  {/* END_USER_CODE-USER_AFTER_ddWhseBin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuditDtFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuditDtFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuditDtFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuditDtFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuditDtFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuditDtTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuditDtTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuditDtTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuditDtTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuditDtTo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95Num*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95Num*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95Num}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95Num*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95Num*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007Num*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007Num*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007Num}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007Num*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007Num*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWhseRcptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWhseRcptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWhseRcptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWhseRcptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtWhseRcptNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBuyPtDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBuyPtDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTransferDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTransferDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxTransferDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTransferNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTradeInspNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTradeInspNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTradeInspNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTradeInspNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTradeInspNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTradeSettleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTradeSettleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTradeSettleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTradeSettleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTradeSettleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuditUserNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuditUserNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuditUserNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuditUserNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuditUserNum*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTransferDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTransferDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPnutDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPnutDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxPnutDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPeanut*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPeanut*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPeanut}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPeanut*/}

                  {/* END_USER_CODE-USER_AFTER_lblPeanut*/}
                  {/* START_USER_CODE-USER_BEFORE_ddType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddType*/}

                  {/* END_USER_CODE-USER_AFTER_ddType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOrganic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOrganic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOrganic*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPnutDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPnutDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAuditHistory*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAuditHistory*/}

                <GroupBoxWidget
                  conf={state.grpbxAuditHistory}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblAuditHistOfInvAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAuditHistOfInvAdj*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAuditHistOfInvAdj}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAuditHistOfInvAdj*/}

                  {/* END_USER_CODE-USER_AFTER_lblAuditHistOfInvAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  {/* START_USER_CODE-USER_BEFORE_gridAuditHistory*/}

                  {/* END_USER_CODE-USER_BEFORE_gridAuditHistory*/}

                  <GridWidget
                    conf={state.gridAuditHistory}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridAuditHistory}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridAuditHistory*/}

                  {/* END_USER_CODE-USER_AFTER_gridAuditHistory*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNote*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNote*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNote}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNote*/}

                  {/* END_USER_CODE-USER_AFTER_lblNote*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalPhysical*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalPhysical*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalPhysical}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalPhysical*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalPhysical*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalReceipted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalReceipted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalReceipted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalReceipted*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalReceipted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalShrink*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalShrink*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalShrink}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalShrink*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalShrink*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalUnreceipted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalUnreceipted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalUnreceipted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalUnreceipted*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalUnreceipted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalUndetermined*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalUndetermined*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalUndetermined}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalUndetermined*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalUndetermined*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalCompOwned*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalCompOwned*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalCompOwned}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalCompOwned*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalCompOwned*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalIntransit*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalIntransit*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalIntransit}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalIntransit*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalIntransit*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAuditHistory*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAuditHistory*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualDPRInvSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualDPRInvSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxPrintExport*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPrintExport*/}

                <GroupBoxWidget
                  conf={state.grpbxPrintExport}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPrintExport*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPrintExport*/}
                {/* START_USER_CODE-USER_BEFORE_btmExit*/}

                {/* END_USER_CODE-USER_BEFORE_btmExit*/}

                <ButtonWidget
                  conf={state.btmExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btmExit*/}

                {/* END_USER_CODE-USER_AFTER_btmExit*/}
                {/* START_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                {/* END_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                <ButtonWidget
                  conf={state.btnAuditHistory}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAuditHistory*/}

                {/* END_USER_CODE-USER_AFTER_btnAuditHistory*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ManualDPRInvSearch*/}

              {/* END_USER_CODE-USER_AFTER_ManualDPRInvSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualDPRInvSearch
);
