/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualAcctDistSearch from "./ManualAcctDistSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualAcctDistSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualAcctDistSearch />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualAcctDistSearch Loads Successfully", () => {
    expect(screen.getByText("ManualAcctDistSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualAcctDistSearch", () => {
    // START_USER_CODE-USER_ManualAcctDistSearch_Custom_Test_Case
    // END_USER_CODE-USER_ManualAcctDistSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualAcctDistSearch />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistSearch_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnReversal(Button Widget) Test Cases", async () => {
    const btnReversal = screen.getByTestId("btnReversal");
    expect(btnReversal).toBeInTheDocument;
    expect(btnReversal.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_btnReversal"
      )
    );
  });
  test("Custom Test Cases for btnReversal", () => {
    // START_USER_CODE-USER_btnReversal_TEST
    // END_USER_CODE-USER_btnReversal_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_btnSearch"
      )
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnView(Button Widget) Test Cases", async () => {
    const btnView = screen.getByTestId("btnView");
    expect(btnView).toBeInTheDocument;
    expect(btnView.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistSearch_btnView")
    );
  });
  test("Custom Test Cases for btnView", () => {
    // START_USER_CODE-USER_btnView_TEST
    // END_USER_CODE-USER_btnView_TEST
  });
  test("gridManualAcctDist(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist = screen.getByTestId("gridManualAcctDist");
    let gridManualAcctDistbtn =
      gridManualAcctDist.nextElementSibling.firstElementChild;
    gridManualAcctDist =
      gridManualAcctDist.parentElement.parentElement.parentElement;
    expect(gridManualAcctDist.tagName).toBe("DIV");
    expect(gridManualAcctDist.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridManualAcctDist", () => {
    // START_USER_CODE-USER_gridManualAcctDist_TEST
    // END_USER_CODE-USER_gridManualAcctDist_TEST
  });
  test("txt1007Settlement(Textbox Widget) Test Cases", async () => {
    const txt1007Settlement = screen.getByTestId("txt1007Settlement");
    expect(txt1007Settlement.tagName).toBe("INPUT");
    expect(txt1007Settlement.type).toBe("text");
    expect(txt1007Settlement.classList).toContain("textboxWidgetClass");
    expect(txt1007Settlement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txt1007Settlement"
      )
    );
    await act(async () => {
      userEvent.type(txt1007Settlement, "1");
    });
  });
  test("Custom Test Cases for txt1007Settlement", () => {
    // START_USER_CODE-USER_txt1007Settlement_TEST
    // END_USER_CODE-USER_txt1007Settlement_TEST
  });
  test("txtAccount(Textbox Widget) Test Cases", async () => {
    const txtAccount = screen.getByTestId("txtAccount");
    expect(txtAccount.tagName).toBe("INPUT");
    expect(txtAccount.type).toBe("text");
    expect(txtAccount.classList).toContain("textboxWidgetClass");
    expect(txtAccount.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtAccount"
      )
    );
    await act(async () => {
      userEvent.type(txtAccount, "1");
    });
  });
  test("Custom Test Cases for txtAccount", () => {
    // START_USER_CODE-USER_txtAccount_TEST
    // END_USER_CODE-USER_txtAccount_TEST
  });
  test("txtAmount(Textbox Widget) Test Cases", async () => {
    const txtAmount = screen.getByTestId("txtAmount");
    expect(txtAmount.tagName).toBe("INPUT");
    expect(txtAmount.type).toBe("text");
    expect(txtAmount.classList).toContain("textboxWidgetClass");
    expect(txtAmount.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtAmount"
      )
    );
    await act(async () => {
      userEvent.type(txtAmount, "1");
    });
  });
  test("Custom Test Cases for txtAmount", () => {
    // START_USER_CODE-USER_txtAmount_TEST
    // END_USER_CODE-USER_txtAmount_TEST
  });
  test("txtChargeLocation(Textbox Widget) Test Cases", async () => {
    const txtChargeLocation = screen.getByTestId("txtChargeLocation");
    expect(txtChargeLocation.tagName).toBe("INPUT");
    expect(txtChargeLocation.type).toBe("text");
    expect(txtChargeLocation.classList).toContain("textboxWidgetClass");
    expect(txtChargeLocation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtChargeLocation"
      )
    );
    await act(async () => {
      userEvent.type(txtChargeLocation, "1");
    });
  });
  test("Custom Test Cases for txtChargeLocation", () => {
    // START_USER_CODE-USER_txtChargeLocation_TEST
    // END_USER_CODE-USER_txtChargeLocation_TEST
  });
  test("txtCheck(Textbox Widget) Test Cases", async () => {
    const txtCheck = screen.getByTestId("txtCheck");
    expect(txtCheck.tagName).toBe("INPUT");
    expect(txtCheck.type).toBe("text");
    expect(txtCheck.classList).toContain("textboxWidgetClass");
    expect(txtCheck.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtCheck")
    );
    await act(async () => {
      userEvent.type(txtCheck, "1");
    });
  });
  test("Custom Test Cases for txtCheck", () => {
    // START_USER_CODE-USER_txtCheck_TEST
    // END_USER_CODE-USER_txtCheck_TEST
  });
  test("gridManualAcctDist_txtcolAccount(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolAccount = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolAccountbtn =
      gridManualAcctDist_txtcolAccount.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolAccount =
      gridManualAcctDist_txtcolAccount.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolAccount.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolAccount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAccount", () => {
    // START_USER_CODE-USER_txtcolAccount_TEST
    // END_USER_CODE-USER_txtcolAccount_TEST
  });
  test("gridManualAcctDist_txtcolAmount(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolAmount = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolAmountbtn =
      gridManualAcctDist_txtcolAmount.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolAmount =
      gridManualAcctDist_txtcolAmount.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolAmount.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmount", () => {
    // START_USER_CODE-USER_txtcolAmount_TEST
    // END_USER_CODE-USER_txtcolAmount_TEST
  });
  test("gridManualAcctDist_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolBuyingPoint = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolBuyingPointbtn =
      gridManualAcctDist_txtcolBuyingPoint.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolBuyingPoint =
      gridManualAcctDist_txtcolBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridManualAcctDist_txtcolChargeLoc(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolChargeLoc = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolChargeLocbtn =
      gridManualAcctDist_txtcolChargeLoc.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolChargeLoc =
      gridManualAcctDist_txtcolChargeLoc.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolChargeLoc.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolChargeLoc.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolChargeLoc", () => {
    // START_USER_CODE-USER_txtcolChargeLoc_TEST
    // END_USER_CODE-USER_txtcolChargeLoc_TEST
  });
  test("gridManualAcctDist_txtcolDept(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolDept = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolDeptbtn =
      gridManualAcctDist_txtcolDept.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolDept =
      gridManualAcctDist_txtcolDept.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolDept.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolDept.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDept", () => {
    // START_USER_CODE-USER_txtcolDept_TEST
    // END_USER_CODE-USER_txtcolDept_TEST
  });
  test("gridManualAcctDist_txtcolNumber1(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolNumber1 = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolNumber1btn =
      gridManualAcctDist_txtcolNumber1.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolNumber1 =
      gridManualAcctDist_txtcolNumber1.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolNumber1.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolNumber1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1", () => {
    // START_USER_CODE-USER_txtcolNumber1_TEST
    // END_USER_CODE-USER_txtcolNumber1_TEST
  });
  test("gridManualAcctDist_txtcolNumber2(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolNumber2 = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolNumber2btn =
      gridManualAcctDist_txtcolNumber2.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolNumber2 =
      gridManualAcctDist_txtcolNumber2.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolNumber2.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolNumber2.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2", () => {
    // START_USER_CODE-USER_txtcolNumber2_TEST
    // END_USER_CODE-USER_txtcolNumber2_TEST
  });
  test("gridManualAcctDist_txtcolQuantity(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolQuantity = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolQuantitybtn =
      gridManualAcctDist_txtcolQuantity.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolQuantity =
      gridManualAcctDist_txtcolQuantity.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolQuantity.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolQuantity.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolQuantity", () => {
    // START_USER_CODE-USER_txtcolQuantity_TEST
    // END_USER_CODE-USER_txtcolQuantity_TEST
  });
  test("gridManualAcctDist_txtcolResp(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolResp = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolRespbtn =
      gridManualAcctDist_txtcolResp.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolResp =
      gridManualAcctDist_txtcolResp.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolResp.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolResp.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolResp", () => {
    // START_USER_CODE-USER_txtcolResp_TEST
    // END_USER_CODE-USER_txtcolResp_TEST
  });
  test("gridManualAcctDist_txtcolSubDept(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolSubDept = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolSubDeptbtn =
      gridManualAcctDist_txtcolSubDept.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolSubDept =
      gridManualAcctDist_txtcolSubDept.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolSubDept.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolSubDept.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSubDept", () => {
    // START_USER_CODE-USER_txtcolSubDept_TEST
    // END_USER_CODE-USER_txtcolSubDept_TEST
  });
  test("gridManualAcctDist_txtcolType1(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolType1 = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolType1btn =
      gridManualAcctDist_txtcolType1.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolType1 =
      gridManualAcctDist_txtcolType1.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolType1.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolType1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1", () => {
    // START_USER_CODE-USER_txtcolType1_TEST
    // END_USER_CODE-USER_txtcolType1_TEST
  });
  test("gridManualAcctDist_txtcolType2(Grid Widget) Test Cases", async () => {
    let gridManualAcctDist_txtcolType2 = screen.getByTestId(
      "gridManualAcctDist"
    );
    let gridManualAcctDist_txtcolType2btn =
      gridManualAcctDist_txtcolType2.nextElementSibling
        .firstElementChild;
    gridManualAcctDist_txtcolType2 =
      gridManualAcctDist_txtcolType2.parentElement.parentElement
        .parentElement;
    expect(gridManualAcctDist_txtcolType2.tagName).toBe("DIV");
    expect(gridManualAcctDist_txtcolType2.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_gridManualAcctDist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2", () => {
    // START_USER_CODE-USER_txtcolType2_TEST
    // END_USER_CODE-USER_txtcolType2_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtContract"
      )
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtDeliveryAgreement(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgreement = screen.getByTestId(
      "txtDeliveryAgreement"
    );
    expect(txtDeliveryAgreement.tagName).toBe("INPUT");
    expect(txtDeliveryAgreement.type).toBe("text");
    expect(txtDeliveryAgreement.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgreement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtDeliveryAgreement"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgreement, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgreement", () => {
    // START_USER_CODE-USER_txtDeliveryAgreement_TEST
    // END_USER_CODE-USER_txtDeliveryAgreement_TEST
  });
  test("txtDepartment(Textbox Widget) Test Cases", async () => {
    const txtDepartment = screen.getByTestId("txtDepartment");
    expect(txtDepartment.tagName).toBe("INPUT");
    expect(txtDepartment.type).toBe("text");
    expect(txtDepartment.classList).toContain("textboxWidgetClass");
    expect(txtDepartment.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtDepartment"
      )
    );
    await act(async () => {
      userEvent.type(txtDepartment, "1");
    });
  });
  test("Custom Test Cases for txtDepartment", () => {
    // START_USER_CODE-USER_txtDepartment_TEST
    // END_USER_CODE-USER_txtDepartment_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtLocation"
      )
    );
    await act(async () => {
      userEvent.type(txtLocation, "1");
    });
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtManualAcctDistSearch(GroupBox Widget) Test Cases", async () => {
    const txtManualAcctDistSearch = screen.getByTestId(
      "txtManualAcctDistSearch"
    );
    expect(txtManualAcctDistSearch.tagName).toBe("BUTTON");
    expect(txtManualAcctDistSearch.type).toBe("button");
    expect(txtManualAcctDistSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for txtManualAcctDistSearch", () => {
    // START_USER_CODE-USER_txtManualAcctDistSearch_TEST
    // END_USER_CODE-USER_txtManualAcctDistSearch_TEST
  });
  test("txtQuantity(Textbox Widget) Test Cases", async () => {
    const txtQuantity = screen.getByTestId("txtQuantity");
    expect(txtQuantity.tagName).toBe("INPUT");
    expect(txtQuantity.type).toBe("text");
    expect(txtQuantity.classList).toContain("textboxWidgetClass");
    expect(txtQuantity.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtQuantity"
      )
    );
    await act(async () => {
      userEvent.type(txtQuantity, "1");
    });
  });
  test("Custom Test Cases for txtQuantity", () => {
    // START_USER_CODE-USER_txtQuantity_TEST
    // END_USER_CODE-USER_txtQuantity_TEST
  });
  test("txtResponsibility(Textbox Widget) Test Cases", async () => {
    const txtResponsibility = screen.getByTestId("txtResponsibility");
    expect(txtResponsibility.tagName).toBe("INPUT");
    expect(txtResponsibility.type).toBe("text");
    expect(txtResponsibility.classList).toContain("textboxWidgetClass");
    expect(txtResponsibility.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtResponsibility"
      )
    );
    await act(async () => {
      userEvent.type(txtResponsibility, "1");
    });
  });
  test("Custom Test Cases for txtResponsibility", () => {
    // START_USER_CODE-USER_txtResponsibility_TEST
    // END_USER_CODE-USER_txtResponsibility_TEST
  });
  test("txtSC95Inspection(Textbox Widget) Test Cases", async () => {
    const txtSC95Inspection = screen.getByTestId("txtSC95Inspection");
    expect(txtSC95Inspection.tagName).toBe("INPUT");
    expect(txtSC95Inspection.type).toBe("text");
    expect(txtSC95Inspection.classList).toContain("textboxWidgetClass");
    expect(txtSC95Inspection.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtSC95Inspection"
      )
    );
    await act(async () => {
      userEvent.type(txtSC95Inspection, "1");
    });
  });
  test("Custom Test Cases for txtSC95Inspection", () => {
    // START_USER_CODE-USER_txtSC95Inspection_TEST
    // END_USER_CODE-USER_txtSC95Inspection_TEST
  });
  test("txtSubDepartment(Textbox Widget) Test Cases", async () => {
    const txtSubDepartment = screen.getByTestId("txtSubDepartment");
    expect(txtSubDepartment.tagName).toBe("INPUT");
    expect(txtSubDepartment.type).toBe("text");
    expect(txtSubDepartment.classList).toContain("textboxWidgetClass");
    expect(txtSubDepartment.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtSubDepartment"
      )
    );
    await act(async () => {
      userEvent.type(txtSubDepartment, "1");
    });
  });
  test("Custom Test Cases for txtSubDepartment", () => {
    // START_USER_CODE-USER_txtSubDepartment_TEST
    // END_USER_CODE-USER_txtSubDepartment_TEST
  });
  test("txtTradeSettlement(Textbox Widget) Test Cases", async () => {
    const txtTradeSettlement = screen.getByTestId("txtTradeSettlement");
    expect(txtTradeSettlement.tagName).toBe("INPUT");
    expect(txtTradeSettlement.type).toBe("text");
    expect(txtTradeSettlement.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettlement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtTradeSettlement"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeSettlement, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettlement", () => {
    // START_USER_CODE-USER_txtTradeSettlement_TEST
    // END_USER_CODE-USER_txtTradeSettlement_TEST
  });
  test("txtTradIinspection(Textbox Widget) Test Cases", async () => {
    const txtTradIinspection = screen.getByTestId("txtTradIinspection");
    expect(txtTradIinspection.tagName).toBe("INPUT");
    expect(txtTradIinspection.type).toBe("text");
    expect(txtTradIinspection.classList).toContain("textboxWidgetClass");
    expect(txtTradIinspection.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtTradIinspection"
      )
    );
    await act(async () => {
      userEvent.type(txtTradIinspection, "1");
    });
  });
  test("Custom Test Cases for txtTradIinspection", () => {
    // START_USER_CODE-USER_txtTradIinspection_TEST
    // END_USER_CODE-USER_txtTradIinspection_TEST
  });
  test("txtTransfer(Textbox Widget) Test Cases", async () => {
    const txtTransfer = screen.getByTestId("txtTransfer");
    expect(txtTransfer.tagName).toBe("INPUT");
    expect(txtTransfer.type).toBe("text");
    expect(txtTransfer.classList).toContain("textboxWidgetClass");
    expect(txtTransfer.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtTransfer"
      )
    );
    await act(async () => {
      userEvent.type(txtTransfer, "1");
    });
  });
  test("Custom Test Cases for txtTransfer", () => {
    // START_USER_CODE-USER_txtTransfer_TEST
    // END_USER_CODE-USER_txtTransfer_TEST
  });
  test("txtWarehouseReceipt(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceipt = screen.getByTestId(
      "txtWarehouseReceipt"
    );
    expect(txtWarehouseReceipt.tagName).toBe("INPUT");
    expect(txtWarehouseReceipt.type).toBe("text");
    expect(txtWarehouseReceipt.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseReceipt.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualAcctDistSearch_txtWarehouseReceipt"
      )
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceipt, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseReceipt", () => {
    // START_USER_CODE-USER_txtWarehouseReceipt_TEST
    // END_USER_CODE-USER_txtWarehouseReceipt_TEST
  });
});
