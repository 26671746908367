/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ExternalReceiptForfeituresSearch from "./ExternalReceiptForfeituresSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExternalReceiptForfeituresSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ExternalReceiptForfeituresSearch />);
    });
  });
  afterEach(cleanup);
  test("is ExternalReceiptForfeituresSearch Loads Successfully", () => {
    expect(screen.getByText("ExternalReceiptForfeituresSearch"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for ExternalReceiptForfeituresSearch", () => {
    // START_USER_CODE-USER_ExternalReceiptForfeituresSearch_Custom_Test_Case
    // END_USER_CODE-USER_ExternalReceiptForfeituresSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ExternalReceiptForfeituresSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreate(Button Widget) Test Cases", async () => {
    const btnCreate = screen.getByTestId("btnCreate");
    expect(btnCreate).toBeInTheDocument;
    expect(btnCreate.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_btnCreate")
    );
  });
  test("Custom Test Cases for btnCreate", () => {
    // START_USER_CODE-USER_btnCreate_TEST
    // END_USER_CODE-USER_btnCreate_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridExternalReceiptForfeitureSearchList(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchListbtn =
      gridExternalReceiptForfeitureSearchList.nextElementSibling
        .firstElementChild;
    gridExternalReceiptForfeitureSearchList =
      gridExternalReceiptForfeitureSearchList.parentElement.parentElement
        .parentElement;
    expect(gridExternalReceiptForfeitureSearchList.tagName).toBe("DIV");
    expect(gridExternalReceiptForfeitureSearchList.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridExternalReceiptForfeitureSearchList", () => {
    // START_USER_CODE-USER_gridExternalReceiptForfeitureSearchList_TEST
    // END_USER_CODE-USER_gridExternalReceiptForfeitureSearchList_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxExternalReceiptForfeituresSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxExternalReceiptForfeituresSearch = screen.getByTestId(
      "grpbxExternalReceiptForfeituresSearch"
    );
    expect(grpbxExternalReceiptForfeituresSearch.tagName).toBe("BUTTON");
    expect(grpbxExternalReceiptForfeituresSearch.type).toBe("button");
    expect(grpbxExternalReceiptForfeituresSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxExternalReceiptForfeituresSearch", () => {
    // START_USER_CODE-USER_grpbxExternalReceiptForfeituresSearch_TEST
    // END_USER_CODE-USER_grpbxExternalReceiptForfeituresSearch_TEST
  });
  test("grpbxSearchList(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchList = screen.getByTestId("grpbxSearchList");
    expect(grpbxSearchList.tagName).toBe("BUTTON");
    expect(grpbxSearchList.type).toBe("button");
    expect(grpbxSearchList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchList", () => {
    // START_USER_CODE-USER_grpbxSearchList_TEST
    // END_USER_CODE-USER_grpbxSearchList_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("lblExternalReceiptForfeitureSearchList(Label Widget) Test Cases", async () => {
    const lblExternalReceiptForfeitureSearchList = screen.getByTestId(
      "lblExternalReceiptForfeitureSearchList"
    );
    expect(lblExternalReceiptForfeitureSearchList.tagName).toBe("LABEL");
    expect(lblExternalReceiptForfeitureSearchList.classList).toContain(
      "form-label"
    );
    expect(lblExternalReceiptForfeitureSearchList.textContent).toEqual(
      t(
        "WarehouseReceipts:ExternalReceiptForfeituresSearch_lblExternalReceiptForfeitureSearchList"
      )
    );
  });
  test("Custom Test Cases for lblExternalReceiptForfeitureSearchList", () => {
    // START_USER_CODE-USER_lblExternalReceiptForfeitureSearchList_TEST
    // END_USER_CODE-USER_lblExternalReceiptForfeitureSearchList_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNamebtn =
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName =
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName
        .parentElement.parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointName.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPointName", () => {
    // START_USER_CODE-USER_txtcolBuyingPointName_TEST
    // END_USER_CODE-USER_txtcolBuyingPointName_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNumbtn =
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum =
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolBuyingPointNum.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPointNum", () => {
    // START_USER_CODE-USER_txtcolBuyingPointNum_TEST
    // END_USER_CODE-USER_txtcolBuyingPointNum_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolFarmCounty(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolFarmCounty = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolFarmCountybtn =
      gridExternalReceiptForfeitureSearchList_txtcolFarmCounty
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolFarmCounty =
      gridExternalReceiptForfeitureSearchList_txtcolFarmCounty.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolFarmCounty.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolFarmCounty.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmCounty", () => {
    // START_USER_CODE-USER_txtcolFarmCounty_TEST
    // END_USER_CODE-USER_txtcolFarmCounty_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolFarmNum(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolFarmNum = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolFarmNumbtn =
      gridExternalReceiptForfeitureSearchList_txtcolFarmNum.nextElementSibling
        .firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolFarmNum =
      gridExternalReceiptForfeitureSearchList_txtcolFarmNum.parentElement
        .parentElement.parentElement;
    expect(gridExternalReceiptForfeitureSearchList_txtcolFarmNum.tagName).toBe(
      "DIV"
    );
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolFarmNum.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmNum", () => {
    // START_USER_CODE-USER_txtcolFarmNum_TEST
    // END_USER_CODE-USER_txtcolFarmNum_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolFarmState(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolFarmState = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolFarmStatebtn =
      gridExternalReceiptForfeitureSearchList_txtcolFarmState.nextElementSibling
        .firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolFarmState =
      gridExternalReceiptForfeitureSearchList_txtcolFarmState.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolFarmState.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolFarmState.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmState", () => {
    // START_USER_CODE-USER_txtcolFarmState_TEST
    // END_USER_CODE-USER_txtcolFarmState_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeIDbtn =
      gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID =
      gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolPeanutTypeID.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutTypeID", () => {
    // START_USER_CODE-USER_txtcolPeanutTypeID_TEST
    // END_USER_CODE-USER_txtcolPeanutTypeID_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolProducerName(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolProducerName = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolProducerNamebtn =
      gridExternalReceiptForfeitureSearchList_txtcolProducerName
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolProducerName =
      gridExternalReceiptForfeitureSearchList_txtcolProducerName.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolProducerName.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolProducerName.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProducerName", () => {
    // START_USER_CODE-USER_txtcolProducerName_TEST
    // END_USER_CODE-USER_txtcolProducerName_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolReceiptNum(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolReceiptNum = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolReceiptNumbtn =
      gridExternalReceiptForfeitureSearchList_txtcolReceiptNum
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolReceiptNum =
      gridExternalReceiptForfeitureSearchList_txtcolReceiptNum.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolReceiptNum.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolReceiptNum.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptNum", () => {
    // START_USER_CODE-USER_txtcolReceiptNum_TEST
    // END_USER_CODE-USER_txtcolReceiptNum_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolReceiptValue(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolReceiptValue = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolReceiptValuebtn =
      gridExternalReceiptForfeitureSearchList_txtcolReceiptValue
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolReceiptValue =
      gridExternalReceiptForfeitureSearchList_txtcolReceiptValue.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolReceiptValue.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolReceiptValue.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptValue", () => {
    // START_USER_CODE-USER_txtcolReceiptValue_TEST
    // END_USER_CODE-USER_txtcolReceiptValue_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolRecordStatus(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolRecordStatus = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolRecordStatusbtn =
      gridExternalReceiptForfeitureSearchList_txtcolRecordStatus
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolRecordStatus =
      gridExternalReceiptForfeitureSearchList_txtcolRecordStatus.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolRecordStatus.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolRecordStatus.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecordStatus", () => {
    // START_USER_CODE-USER_txtcolRecordStatus_TEST
    // END_USER_CODE-USER_txtcolRecordStatus_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolTotalLbs(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolTotalLbs = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolTotalLbsbtn =
      gridExternalReceiptForfeitureSearchList_txtcolTotalLbs.nextElementSibling
        .firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolTotalLbs =
      gridExternalReceiptForfeitureSearchList_txtcolTotalLbs.parentElement
        .parentElement.parentElement;
    expect(gridExternalReceiptForfeitureSearchList_txtcolTotalLbs.tagName).toBe(
      "DIV"
    );
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolTotalLbs.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalLbs", () => {
    // START_USER_CODE-USER_txtcolTotalLbs_TEST
    // END_USER_CODE-USER_txtcolTotalLbs_TEST
  });
  test("gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode(Grid Widget) Test Cases", async () => {
    let gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode = screen.getByTestId(
      "gridExternalReceiptForfeitureSearchList"
    );
    let gridExternalReceiptForfeitureSearchList_txtcolWarehouseCodebtn =
      gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode
        .nextElementSibling.firstElementChild;
    gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode =
      gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode.parentElement
        .parentElement.parentElement;
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode.tagName
    ).toBe("DIV");
    expect(
      gridExternalReceiptForfeitureSearchList_txtcolWarehouseCode.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:ExternalReceiptForfeituresSearch_gridExternalReceiptForfeitureSearchList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWarehouseCode", () => {
    // START_USER_CODE-USER_txtcolWarehouseCode_TEST
    // END_USER_CODE-USER_txtcolWarehouseCode_TEST
  });
  test("txtDeliveryAgreementNum(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgreementNum = screen.getByTestId(
      "txtDeliveryAgreementNum"
    );
    expect(txtDeliveryAgreementNum.tagName).toBe("INPUT");
    expect(txtDeliveryAgreementNum.type).toBe("text");
    expect(txtDeliveryAgreementNum.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgreementNum.previousElementSibling.textContent).toEqual(
      t(
        "WarehouseReceipts:ExternalReceiptForfeituresSearch_txtDeliveryAgreementNum"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgreementNum, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgreementNum", () => {
    // START_USER_CODE-USER_txtDeliveryAgreementNum_TEST
    // END_USER_CODE-USER_txtDeliveryAgreementNum_TEST
  });
  test("txtFedBuyPtNum(Textbox Widget) Test Cases", async () => {
    const txtFedBuyPtNum = screen.getByTestId("txtFedBuyPtNum");
    expect(txtFedBuyPtNum.tagName).toBe("INPUT");
    expect(txtFedBuyPtNum.type).toBe("text");
    expect(txtFedBuyPtNum.classList).toContain("textboxWidgetClass");
    expect(txtFedBuyPtNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_txtFedBuyPtNum")
    );
    await act(async () => {
      userEvent.type(txtFedBuyPtNum, "1");
    });
  });
  test("Custom Test Cases for txtFedBuyPtNum", () => {
    // START_USER_CODE-USER_txtFedBuyPtNum_TEST
    // END_USER_CODE-USER_txtFedBuyPtNum_TEST
  });
  test("txtReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtReceiptNum = screen.getByTestId("txtReceiptNum");
    expect(txtReceiptNum.tagName).toBe("INPUT");
    expect(txtReceiptNum.type).toBe("text");
    expect(txtReceiptNum.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_txtReceiptNum")
    );
    await act(async () => {
      userEvent.type(txtReceiptNum, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNum", () => {
    // START_USER_CODE-USER_txtReceiptNum_TEST
    // END_USER_CODE-USER_txtReceiptNum_TEST
  });
  test("txtTotalPounds(Textbox Widget) Test Cases", async () => {
    const txtTotalPounds = screen.getByTestId("txtTotalPounds");
    expect(txtTotalPounds.tagName).toBe("INPUT");
    expect(txtTotalPounds.type).toBe("text");
    expect(txtTotalPounds.classList).toContain("textboxWidgetClass");
    expect(txtTotalPounds.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_txtTotalPounds")
    );
    await act(async () => {
      userEvent.type(txtTotalPounds, "1");
    });
  });
  test("Custom Test Cases for txtTotalPounds", () => {
    // START_USER_CODE-USER_txtTotalPounds_TEST
    // END_USER_CODE-USER_txtTotalPounds_TEST
  });
  test("txtTotalReceiptValue(Textbox Widget) Test Cases", async () => {
    const txtTotalReceiptValue = screen.getByTestId("txtTotalReceiptValue");
    expect(txtTotalReceiptValue.tagName).toBe("INPUT");
    expect(txtTotalReceiptValue.type).toBe("text");
    expect(txtTotalReceiptValue.classList).toContain("textboxWidgetClass");
    expect(txtTotalReceiptValue.previousElementSibling.textContent).toEqual(
      t(
        "WarehouseReceipts:ExternalReceiptForfeituresSearch_txtTotalReceiptValue"
      )
    );
    await act(async () => {
      userEvent.type(txtTotalReceiptValue, "1");
    });
  });
  test("Custom Test Cases for txtTotalReceiptValue", () => {
    // START_USER_CODE-USER_txtTotalReceiptValue_TEST
    // END_USER_CODE-USER_txtTotalReceiptValue_TEST
  });
  test("txtWarehouseCode(Textbox Widget) Test Cases", async () => {
    const txtWarehouseCode = screen.getByTestId("txtWarehouseCode");
    expect(txtWarehouseCode.tagName).toBe("INPUT");
    expect(txtWarehouseCode.type).toBe("text");
    expect(txtWarehouseCode.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ExternalReceiptForfeituresSearch_txtWarehouseCode")
    );
    await act(async () => {
      userEvent.type(txtWarehouseCode, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseCode", () => {
    // START_USER_CODE-USER_txtWarehouseCode_TEST
    // END_USER_CODE-USER_txtWarehouseCode_TEST
  });
});
