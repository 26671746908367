/* eslint-disable*/
import React from "react";
import StockTransfer_StockTransferOldNumber from "./StockTransferOldNumber";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("StockTransferOldNumber Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_StockTransferOldNumber />);
    });
  });
  afterEach(cleanup);
  test("is StockTransferOldNumber Loads Successfully", () => {
    expect(screen.getByText("StockTransferOldNumber")).toBeInTheDocument;
  });
  test("Custom Test Cases for StockTransferOldNumber", () => {
    // START_USER_CODE-USER_StockTransferOldNumber_Custom_Test_Case
    // END_USER_CODE-USER_StockTransferOldNumber_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_StockTransferOldNumber />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("StockTransfer:StockTransferOldNumber_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("StockTransfer:StockTransferOldNumber_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxStockTransferOldNumber(GroupBox Widget) Test Cases", async () => {
    const grpbxStockTransferOldNumber = screen.getByTestId(
      "grpbxStockTransferOldNumber"
    );
    expect(grpbxStockTransferOldNumber.tagName).toBe("BUTTON");
    expect(grpbxStockTransferOldNumber.type).toBe("button");
    expect(grpbxStockTransferOldNumber.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStockTransferOldNumber", () => {
    // START_USER_CODE-USER_grpbxStockTransferOldNumber_TEST
    // END_USER_CODE-USER_grpbxStockTransferOldNumber_TEST
  });
  test("lblNote1(Label Widget) Test Cases", async () => {
    const lblNote1 = screen.getByTestId("lblNote1");
    expect(lblNote1.tagName).toBe("LABEL");
    expect(lblNote1.classList).toContain("form-label");
    expect(lblNote1.textContent).toEqual(
      t("StockTransfer:StockTransferOldNumber_lblNote1")
    );
  });
  test("Custom Test Cases for lblNote1", () => {
    // START_USER_CODE-USER_lblNote1_TEST
    // END_USER_CODE-USER_lblNote1_TEST
  });
  test("lblNote2(Label Widget) Test Cases", async () => {
    const lblNote2 = screen.getByTestId("lblNote2");
    expect(lblNote2.tagName).toBe("LABEL");
    expect(lblNote2.classList).toContain("form-label");
    expect(lblNote2.textContent).toEqual(
      t("StockTransfer:StockTransferOldNumber_lblNote2")
    );
  });
  test("Custom Test Cases for lblNote2", () => {
    // START_USER_CODE-USER_lblNote2_TEST
    // END_USER_CODE-USER_lblNote2_TEST
  });
  test("txtCurrentShippingLocation(Textbox Widget) Test Cases", async () => {
    const txtCurrentShippingLocation = screen.getByTestId(
      "txtCurrentShippingLocation"
    );
    expect(txtCurrentShippingLocation.tagName).toBe("INPUT");
    expect(txtCurrentShippingLocation.type).toBe("text");
    expect(txtCurrentShippingLocation.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtCurrentShippingLocation.previousElementSibling.textContent
    ).toEqual(
      t("StockTransfer:StockTransferOldNumber_txtCurrentShippingLocation")
    );
    await act(async () => {
      userEvent.type(txtCurrentShippingLocation, "123");
    });
    expect(txtCurrentShippingLocation.getAttribute("value")).toBe("");
    expect(txtCurrentShippingLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCurrentShippingLocation", () => {
    // START_USER_CODE-USER_txtCurrentShippingLocation_TEST
    // END_USER_CODE-USER_txtCurrentShippingLocation_TEST
  });
});
