/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  ListboxWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  hide,
} from "../../shared/WindowImports";

import "./ProcurementReporting.scss";
import ContractManagement_header from "../../Header/Header";
// START_USER_CODE-USER_IMPORTS

// END_USER_CODE-USER_IMPORTS
function ReportTool_ProcurementReporting(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "",
    windowName: "ProcurementReporting",
    template: "Standard Screen",
    Cols: "6",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ReportTool.ProcurementReporting",
    // START_USER_CODE-USER_ProcurementReporting_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Procurement Reporting",
      scrCode: "",
    },
    // END_USER_CODE-USER_ProcurementReporting_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbx21",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnBase: {
      name: "btnBase",
      type: "ButtonWidget",
      parent: "grpbx12",
      Label: "Base",
      CharWidth: "11",
      // START_USER_CODE-USER_btnBase_PROPERTIES

      // END_USER_CODE-USER_btnBase_PROPERTIES
    },
    btnClearAll: {
      name: "btnClearAll",
      type: "ButtonWidget",
      parent: "grpbx21",
      Label: "Clear All",
      CharWidth: "21",
      // START_USER_CODE-USER_btnClearAll_PROPERTIES

      // END_USER_CODE-USER_btnClearAll_PROPERTIES
    },
    btnCollapseHeader: {
      name: "btnCollapseHeader",
      type: "ButtonWidget",
      parent: "grpbx31",
      Label: "Collapse Header",
      CharWidth: "32",
      // START_USER_CODE-USER_btnCollapseHeader_PROPERTIES

      // END_USER_CODE-USER_btnCollapseHeader_PROPERTIES
    },
    btnCustom: {
      name: "btnCustom",
      type: "ButtonWidget",
      parent: "grpbx12",
      Label: "Custom",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCustom_PROPERTIES

      // END_USER_CODE-USER_btnCustom_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbx13",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExpandHeaders: {
      name: "btnExpandHeaders",
      type: "ButtonWidget",
      parent: "grpbx31",
      Label: "Expand Headers",
      CharWidth: "30",
      // START_USER_CODE-USER_btnExpandHeaders_PROPERTIES

      // END_USER_CODE-USER_btnExpandHeaders_PROPERTIES
    },
    btnFormulaEditor: {
      name: "btnFormulaEditor",
      type: "ButtonWidget",
      parent: "grpbx35",
      Label: "Formula Editor",
      CharWidth: "30",
      // START_USER_CODE-USER_btnFormulaEditor_PROPERTIES

      // END_USER_CODE-USER_btnFormulaEditor_PROPERTIES
    },
    btnLeftShift: {
      name: "btnLeftShift",
      type: "ButtonWidget",
      parent: "grpbx32",
      Label: ">>>",
      CharWidth: "9",
      // START_USER_CODE-USER_btnLeftShift_PROPERTIES

      // END_USER_CODE-USER_btnLeftShift_PROPERTIES
    },
    btnOrderDown: {
      name: "btnOrderDown",
      type: "ButtonWidget",
      parent: "grpbx24",
      Label: "buttonwidget60",
      CharWidth: "4",
      HasLabel: false,
      // START_USER_CODE-USER_btnOrderDown_PROPERTIES

      // END_USER_CODE-USER_btnOrderDown_PROPERTIES
    },
    btnOrderDown1: {
      name: "btnOrderDown1",
      type: "ButtonWidget",
      parent: "grpbx34",
      Label: "buttonwidget64",
      CharWidth: "4",
      HasLabel: false,
      // START_USER_CODE-USER_btnOrderDown1_PROPERTIES

      // END_USER_CODE-USER_btnOrderDown1_PROPERTIES
    },
    btnOrderUp: {
      name: "btnOrderUp",
      type: "ButtonWidget",
      parent: "grpbx24",
      Label: "^",
      CharWidth: "6",
      // START_USER_CODE-USER_btnOrderUp_PROPERTIES

      // END_USER_CODE-USER_btnOrderUp_PROPERTIES
    },
    btnOrderUp1: {
      name: "btnOrderUp1",
      type: "ButtonWidget",
      parent: "grpbx34",
      Label: "^",
      CharWidth: "6",
      // START_USER_CODE-USER_btnOrderUp1_PROPERTIES

      // END_USER_CODE-USER_btnOrderUp1_PROPERTIES
    },
    btnRemove: {
      name: "btnRemove",
      type: "ButtonWidget",
      parent: "grpbx21",
      Label: "Remove",
      CharWidth: "15",
      // START_USER_CODE-USER_btnRemove_PROPERTIES

      // END_USER_CODE-USER_btnRemove_PROPERTIES
    },
    btnRightShift: {
      name: "btnRightShift",
      type: "ButtonWidget",
      parent: "grpbx32",
      Label: "<<<",
      CharWidth: "9",
      // START_USER_CODE-USER_btnRightShift_PROPERTIES

      // END_USER_CODE-USER_btnRightShift_PROPERTIES
    },
    btnRunReport: {
      name: "btnRunReport",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Run Report",
      CharWidth: "23",
      // START_USER_CODE-USER_btnRunReport_PROPERTIES

      // END_USER_CODE-USER_btnRunReport_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbx13",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnSaveAs: {
      name: "btnSaveAs",
      type: "ButtonWidget",
      parent: "grpbx13",
      Label: "Save As...",
      CharWidth: "23",
      // START_USER_CODE-USER_btnSaveAs_PROPERTIES

      // END_USER_CODE-USER_btnSaveAs_PROPERTIES
    },
    btnShare: {
      name: "btnShare",
      type: "ButtonWidget",
      parent: "grpbx13",
      Label: "Share",
      CharWidth: "13",
      // START_USER_CODE-USER_btnShare_PROPERTIES

      // END_USER_CODE-USER_btnShare_PROPERTIES
    },
    chkbxField: {
      name: "chkbxField",
      type: "CheckBoxWidget",
      parent: "grpbx22",
      Label: "Field",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxField_PROPERTIES

      // END_USER_CODE-USER_chkbxField_PROPERTIES
    },
    chkbxgrpSortBy: {
      name: "chkbxgrpSortBy",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbx23",
      Options: "Option:1,Option2:2,Option3:3",
      Label: "Sort By",
      ColSpan: "1",
      ColSpanForLargeDesktop: "3",
      ColSpanForTabLandscape: "3",
      ColSpanForTabPotrait: "3",
      HasLabel: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_chkbxgrpSortBy_PROPERTIES

      // END_USER_CODE-USER_chkbxgrpSortBy_PROPERTIES
    },
    chkbxSummary: {
      name: "chkbxSummary",
      type: "CheckBoxWidget",
      parent: "grpbx35",
      Label: "Summary",
      HasLabel:false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxSummary_PROPERTIES

      // END_USER_CODE-USER_chkbxSummary_PROPERTIES
    },
    chkbxValues: {
      name: "chkbxValues",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbx25",
      Label: "checkboxgroupfieldwidget73",
      ColSpan: "1",
      ColSpanForLargeDesktop: "3",
      ColSpanForTabLandscape: "3",
      ColSpanForTabPotrait: "3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_chkbxValues_PROPERTIES

      // END_USER_CODE-USER_chkbxValues_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbx11",
      Label: "Area:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbx11",
      Label: "Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddCollectionPoint: {
      name: "ddCollectionPoint",
      type: "DropDownFieldWidget",
      parent: "grpbx11",
      Label: "Collection Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPoint_PROPERTIES
    },
    ddCompany: {
      name: "ddCompany",
      type: "DropDownFieldWidget",
      parent: "grpbx11",
      Label: "Company:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCompany_PROPERTIES

      // END_USER_CODE-USER_ddCompany_PROPERTIES
    },
    ddCondition: {
      name: "ddCondition",
      type: "DropDownFieldWidget",
      parent: "grpbx21",
      Label: "Condition",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCondition_PROPERTIES

      // END_USER_CODE-USER_ddCondition_PROPERTIES
    },
    ddCropYear: {
      name: "ddCropYear",
      type: "DropDownFieldWidget",
      parent: "grpbx11",
      Label: "Crop Year:",
      DataProviderForDropDown: "inline",
      horizontalForm: "true",
      ofTypeDomain: "d_dateCalender",
      // START_USER_CODE-USER_ddCropYear_PROPERTIES

      // END_USER_CODE-USER_ddCropYear_PROPERTIES
    },
    ddField: {
      name: "ddField",
      type: "DropDownFieldWidget",
      parent: "grpbx21",
      Label: "Field",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddField_PROPERTIES

      // END_USER_CODE-USER_ddField_PROPERTIES
    },
    ddGroupBy: {
      name: "ddGroupBy",
      type: "DropDownFieldWidget",
      parent: "grpbx23",
      Label: "Group By",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGroupBy_PROPERTIES

      // END_USER_CODE-USER_ddGroupBy_PROPERTIES
    },
    ddValues: {
      name: "ddValues",
      type: "DropDownFieldWidget",
      parent: "grpbx25",
      Label: "dropdownfieldwidget72",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddValues_PROPERTIES

      // END_USER_CODE-USER_ddValues_PROPERTIES
    },
    grpbx11: {
      name: "grpbx11",
      type: "GroupBoxWidget",
      parent: "grpbx1",
      Label: "groupboxwidget7",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx11_PROPERTIES

      // END_USER_CODE-USER_grpbx11_PROPERTIES
    },
    grpbx12: {
      name: "grpbx12",
      type: "GroupBoxWidget",
      parent: "grpbx1",
      Label: "groupboxwidget8",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbx12_PROPERTIES

      // END_USER_CODE-USER_grpbx12_PROPERTIES
    },
    grpbx13: {
      name: "grpbx13",
      type: "GroupBoxWidget",
      parent: "grpbx1",
      Label: "groupboxwidget9",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx13_PROPERTIES

      // END_USER_CODE-USER_grpbx13_PROPERTIES
    },
    grpbx21: {
      name: "grpbx21",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget11",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbx21_PROPERTIES

      // END_USER_CODE-USER_grpbx21_PROPERTIES
    },
    grpbx22: {
      name: "grpbx22",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget12",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx22_PROPERTIES

      // END_USER_CODE-USER_grpbx22_PROPERTIES
    },
    grpbx23: {
      name: "grpbx23",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget13",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx23_PROPERTIES

      // END_USER_CODE-USER_grpbx23_PROPERTIES
    },
    grpbx24: {
      name: "grpbx24",
      type: "GroupBoxWidget",
      parent: "grpbx2",
      Label: "groupboxwidget14",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx24_PROPERTIES

      // END_USER_CODE-USER_grpbx24_PROPERTIES
    },
    grpbx25: {
      name: "grpbx25",
      type: "GroupBoxWidget",
      parent: "grpbx22",
      Label: "groupboxwidget71",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx25_PROPERTIES

      // END_USER_CODE-USER_grpbx25_PROPERTIES
    },
    grpbx31: {
      name: "grpbx31",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget17",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx31_PROPERTIES

      // END_USER_CODE-USER_grpbx31_PROPERTIES
    },
    grpbx32: {
      name: "grpbx32",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget20",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx32_PROPERTIES

      // END_USER_CODE-USER_grpbx32_PROPERTIES
    },
    grpbx33: {
      name: "grpbx33",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget18",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx33_PROPERTIES

      // END_USER_CODE-USER_grpbx33_PROPERTIES
    },
    grpbx34: {
      name: "grpbx34",
      type: "GroupBoxWidget",
      parent: "grpbx3",
      Label: "groupboxwidget19",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx34_PROPERTIES

      // END_USER_CODE-USER_grpbx34_PROPERTIES
    },
    grpbx35: {
      name: "grpbx35",
      type: "GroupBoxWidget",
      parent: "grpbx34",
      Label: "groupboxwidget33",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbx35_PROPERTIES

      // END_USER_CODE-USER_grpbx35_PROPERTIES
    },
    lblColumnOrder: {
      name: "lblColumnOrder",
      type: "LabelWidget",
      parent: "grpbx34",
      Label: "Column Order",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblColumnOrder_PROPERTIES

      // END_USER_CODE-USER_lblColumnOrder_PROPERTIES
    },
    lblError: {
      name: "lblError",
      type: "LabelWidget",
      parent: "grpbx21",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblError_PROPERTIES

      // END_USER_CODE-USER_lblError_PROPERTIES
    },
    lblReportColumns: {
      name: "lblReportColumns",
      type: "LabelWidget",
      parent: "grpbx31",
      Label: "Report Columns",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReportColumns_PROPERTIES

      // END_USER_CODE-USER_lblReportColumns_PROPERTIES
    },
    lblSelectedColumns: {
      name: "lblSelectedColumns",
      type: "LabelWidget",
      parent: "grpbx33",
      Label: "Selected Columns",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSelectedColumns_PROPERTIES

      // END_USER_CODE-USER_lblSelectedColumns_PROPERTIES
    },
    lblValues: {
      name: "lblValues",
      type: "LabelWidget",
      parent: "grpbx22",
      Label: "Value(s)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValues_PROPERTIES

      // END_USER_CODE-USER_lblValues_PROPERTIES
    },
    lstAdd: {
      name: "lstAdd",
      type: "ListBoxFieldWidget",
      parent: "grpbx21",
      Label: "listboxfieldwidget39",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,
      
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstAdd_PROPERTIES

      // END_USER_CODE-USER_lstAdd_PROPERTIES
    },
    lstBase: {
      name: "lstBase",
      type: "ListBoxFieldWidget",
      parent: "grpbx12",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstBase_PROPERTIES

      // END_USER_CODE-USER_lstBase_PROPERTIES
    },
    lstCustom: {
      name: "lstCustom",
      type: "ListBoxFieldWidget",
      parent: "grpbx12",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstCustom_PROPERTIES

      // END_USER_CODE-USER_lstCustom_PROPERTIES
    },
    lstReport: {
      name: "lstReport",
      type: "ListBoxFieldWidget",
      parent: "grpbx31",
      Label: "listboxfieldwidget28",
      ColSpan: "3",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstReport_PROPERTIES

      // END_USER_CODE-USER_lstReport_PROPERTIES
    },
    lstSelected: {
      name: "lstSelected",
      type: "ListBoxFieldWidget",
      parent: "grpbx33",
      Label: "listboxfieldwidget31",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstSelected_PROPERTIES

      // END_USER_CODE-USER_lstSelected_PROPERTIES
    },
    rbValues: {
      name: "radioValues",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbx25",
      Label: "radiobuttongroupfieldwidget74",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_rbValues_PROPERTIES

      // END_USER_CODE-USER_rbValues_PROPERTIES
    },
    txtReportDescription: {
      name: "txtReportDescription",
      type: "TextBoxWidget",
      parent: "grpbx12",
      Label: "Report Description: ",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReportDescription_PROPERTIES

      // END_USER_CODE-USER_txtReportDescription_PROPERTIES
    },
    txtReportMode: {
      name: "txtReportMode",
      type: "TextBoxWidget",
      parent: "grpbx12",
      Label: "Report Mode:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReportMode_PROPERTIES

      // END_USER_CODE-USER_txtReportMode_PROPERTIES
    },
    txtValues: {
      name: "txtValues",
      type: "TextBoxWidget",
      parent: "grpbx25",
      Label: "textboxwidget41",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValues_PROPERTIES

      // END_USER_CODE-USER_txtValues_PROPERTIES
    },
    grpbx1: {
      name: "grpbx1",
      type: "GroupBoxWidget",
      parent: "ProcurementReporting",
      Label: "groupboxwidget0",
      ColSpan: "6",
      Height: "",
      Width: "",
      clonedExtId: "26064",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx1_PROPERTIES

      // END_USER_CODE-USER_grpbx1_PROPERTIES
    },
    grpbx2: {
      name: "grpbx2",
      type: "GroupBoxWidget",
      parent: "ProcurementReporting",
      ColSpan: "6",
      Height: "",
      Width: "",
      clonedExtId: "26099",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_grpbx2_PROPERTIES

      // END_USER_CODE-USER_grpbx2_PROPERTIES
    },
    grpbx3: {
      name: "grpbx3",
      type: "GroupBoxWidget",
      parent: "ProcurementReporting",
      ColSpan: "6",
      Height: "",
      Width: "",
      clonedExtId: "26131",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_grpbx3_PROPERTIES

      // END_USER_CODE-USER_grpbx3_PROPERTIES
    },
    cmmndcntnrActions: {
      name: "cmmndcntnrActions",
      type: "CommandContainerWidget",
      parent: "ProcurementReporting",
      ColSpan: "6",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_cmmndcntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndcntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ReportTool#FormulaEditor":{},
       },
      REVERSE:{
        "ReportTool#FormulaEditor":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnFormulaEditor: {
      DEFAULT: ["ReportTool#FormulaEditor#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    hide(thisObj, "ddValues");
    hide(thisObj,'lstCustom')
    // hide(thisObj, "radioValues");
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ProcurementReporting*/}

              {/* END_USER_CODE-USER_BEFORE_ProcurementReporting*/}

              {/* START_USER_CODE-USER_BEFORE_grpbx1*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1*/}

              <GroupBoxWidget conf={state.grpbx1} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbx11*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx11*/}

                <GroupBoxWidget conf={state.grpbx11} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCropYear*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCropYear}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_ddCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCompany*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCompany*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCompany}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCompany*/}

                  {/* END_USER_CODE-USER_AFTER_ddCompany*/}
                  {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                  {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddArea}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddArea*/}

                  {/* END_USER_CODE-USER_AFTER_ddArea*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCollectionPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx11*/}

                {/* END_USER_CODE-USER_AFTER_grpbx11*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx12*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx12*/}

                <GroupBoxWidget conf={state.grpbx12} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxBase*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxBase*/}

                  {/* <GroupBoxWidget
                    conf={state.grpbxBase}
                    screenConf={state}
                  ></GroupBoxWidget> */}
                  {/* START_USER_CODE-USER_AFTER_grpbxBase*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxBase*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCustom*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCustom*/}

                  {/* <GroupBoxWidget
                    conf={state.grpbxCustom}
                    screenConf={state}
                  ></GroupBoxWidget> */}
                  {/* START_USER_CODE-USER_AFTER_grpbxCustom*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCustom*/}
                  {/* START_USER_CODE-USER_BEFORE_btnBase*/}

                  {/* END_USER_CODE-USER_BEFORE_btnBase*/}

                  <ButtonWidget
                    conf={state.btnBase}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnBase*/}

                  {/* END_USER_CODE-USER_AFTER_btnBase*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCustom*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCustom*/}

                  <ButtonWidget
                    conf={state.btnCustom}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCustom*/}

                  {/* END_USER_CODE-USER_AFTER_btnCustom*/}
                  {/* START_USER_CODE-USER_BEFORE_lstBase*/}

                  {/* END_USER_CODE-USER_BEFORE_lstBase*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstBase}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstBase*/}

                  {/* END_USER_CODE-USER_AFTER_lstBase*/}
                  {/* START_USER_CODE-USER_BEFORE_lstCustom*/}

                  {/* END_USER_CODE-USER_BEFORE_lstCustom*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstCustom}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstCustom*/}

                  {/* END_USER_CODE-USER_AFTER_lstCustom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReportDescription*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReportDescription*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReportDescription}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReportDescription*/}

                  {/* END_USER_CODE-USER_AFTER_txtReportDescription*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReportMode*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReportMode*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReportMode}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReportMode*/}

                  {/* END_USER_CODE-USER_AFTER_txtReportMode*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx12*/}

                {/* END_USER_CODE-USER_AFTER_grpbx12*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx13*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx13*/}

                <GroupBoxWidget conf={state.grpbx13} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                  <ButtonWidget
                    conf={state.btnSave}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSave*/}

                  {/* END_USER_CODE-USER_AFTER_btnSave*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveAs*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveAs*/}

                  <ButtonWidget
                    conf={state.btnSaveAs}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveAs*/}

                  {/* END_USER_CODE-USER_AFTER_btnSaveAs*/}
                  {/* START_USER_CODE-USER_BEFORE_btnShare*/}

                  {/* END_USER_CODE-USER_BEFORE_btnShare*/}

                  <ButtonWidget
                    conf={state.btnShare}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnShare*/}

                  {/* END_USER_CODE-USER_AFTER_btnShare*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx13*/}

                {/* END_USER_CODE-USER_AFTER_grpbx13*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1*/}
              {/* START_USER_CODE-USER_BEFORE_grpbx2*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx2*/}

              <GroupBoxWidget conf={state.grpbx2} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbx21*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx21*/}

                <GroupBoxWidget conf={state.grpbx21} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddField*/}

                  {/* END_USER_CODE-USER_BEFORE_ddField*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddField}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddField*/}

                  {/* END_USER_CODE-USER_AFTER_ddField*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCondition*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCondition*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCondition}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCondition*/}

                  {/* END_USER_CODE-USER_AFTER_ddCondition*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnRemove*/}

                  {/* END_USER_CODE-USER_BEFORE_btnRemove*/}

                  <ButtonWidget
                    conf={state.btnRemove}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnRemove*/}

                  {/* END_USER_CODE-USER_AFTER_btnRemove*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClearAll*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClearAll*/}

                  <ButtonWidget
                    conf={state.btnClearAll}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClearAll*/}

                  {/* END_USER_CODE-USER_AFTER_btnClearAll*/}
                  {/* START_USER_CODE-USER_BEFORE_lblError*/}

                  {/* END_USER_CODE-USER_BEFORE_lblError*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblError}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblError*/}

                  {/* END_USER_CODE-USER_AFTER_lblError*/}
                  {/* START_USER_CODE-USER_BEFORE_lstAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_lstAdd*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstAdd}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstAdd*/}

                  {/* END_USER_CODE-USER_AFTER_lstAdd*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx21*/}

                {/* END_USER_CODE-USER_AFTER_grpbx21*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx22*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx22*/}

                <GroupBoxWidget conf={state.grpbx22} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_chkbxField*/}

                  {/* END_USER_CODE-USER_BEFORE_chkbxField*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkbxField}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkbxField*/}

                  {/* END_USER_CODE-USER_AFTER_chkbxField*/}
                  {/* START_USER_CODE-USER_BEFORE_lblValues*/}

                  {/* END_USER_CODE-USER_BEFORE_lblValues*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblValues}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblValues*/}

                  {/* END_USER_CODE-USER_AFTER_lblValues*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbx25*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbx25*/}

                  <GroupBoxWidget conf={state.grpbx25} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_txtValues*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValues*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValues}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValues*/}

                    {/* END_USER_CODE-USER_AFTER_txtValues*/}
                    {/* START_USER_CODE-USER_BEFORE_ddValues*/}

                    {/* END_USER_CODE-USER_BEFORE_ddValues*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddValues}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddValues*/}

                    {/* END_USER_CODE-USER_AFTER_ddValues*/}
                    {/* START_USER_CODE-USER_BEFORE_rbValues*/}

                    {/* END_USER_CODE-USER_BEFORE_rbValues*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.rbValues}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_rbValues*/}

                    {/* END_USER_CODE-USER_AFTER_rbValues*/}
                    {/* START_USER_CODE-USER_BEFORE_chkbxValues*/}

                    {/* END_USER_CODE-USER_BEFORE_chkbxValues*/}

                    <CheckboxGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkbxValues}
                      screenConf={state}
                    ></CheckboxGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_chkbxValues*/}

                    {/* END_USER_CODE-USER_AFTER_chkbxValues*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbx25*/}

                  {/* END_USER_CODE-USER_AFTER_grpbx25*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx22*/}

                {/* END_USER_CODE-USER_AFTER_grpbx22*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx23*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx23*/}

                <GroupBoxWidget conf={state.grpbx23} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_chkbxgrpSortBy*/}

                  {/* END_USER_CODE-USER_BEFORE_chkbxgrpSortBy*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkbxgrpSortBy}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkbxgrpSortBy*/}

                  {/* END_USER_CODE-USER_AFTER_chkbxgrpSortBy*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGroupBy*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGroupBy*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGroupBy}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGroupBy*/}

                  {/* END_USER_CODE-USER_AFTER_ddGroupBy*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx23*/}

                {/* END_USER_CODE-USER_AFTER_grpbx23*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx24*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx24*/}

                <GroupBoxWidget conf={state.grpbx24} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnOrderUp*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOrderUp*/}

                  <ButtonWidget
                    conf={state.btnOrderUp}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOrderUp*/}

                  {/* END_USER_CODE-USER_AFTER_btnOrderUp*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOrderDown*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOrderDown*/}

                  <ButtonWidget
                    conf={state.btnOrderDown}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOrderDown*/}

                  {/* END_USER_CODE-USER_AFTER_btnOrderDown*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx24*/}

                {/* END_USER_CODE-USER_AFTER_grpbx24*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx2*/}

              {/* END_USER_CODE-USER_AFTER_grpbx2*/}
              {/* START_USER_CODE-USER_BEFORE_grpbx3*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx3*/}

              <GroupBoxWidget conf={state.grpbx3} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbx31*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx31*/}

                <GroupBoxWidget conf={state.grpbx31} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblReportColumns*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReportColumns*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReportColumns}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReportColumns*/}

                  {/* END_USER_CODE-USER_AFTER_lblReportColumns*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCollapseHeader*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCollapseHeader*/}

                  <ButtonWidget
                    conf={state.btnCollapseHeader}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCollapseHeader*/}

                  {/* END_USER_CODE-USER_AFTER_btnCollapseHeader*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExpandHeaders*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExpandHeaders*/}

                  <ButtonWidget
                    conf={state.btnExpandHeaders}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExpandHeaders*/}

                  {/* END_USER_CODE-USER_AFTER_btnExpandHeaders*/}
                  {/* START_USER_CODE-USER_BEFORE_lstReport*/}

                  {/* END_USER_CODE-USER_BEFORE_lstReport*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstReport}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstReport*/}

                  {/* END_USER_CODE-USER_AFTER_lstReport*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx31*/}

                {/* END_USER_CODE-USER_AFTER_grpbx31*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx32*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx32*/}

                <GroupBoxWidget conf={state.grpbx32} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnLeftShift*/}

                  {/* END_USER_CODE-USER_BEFORE_btnLeftShift*/}

                  <ButtonWidget
                    conf={state.btnLeftShift}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnLeftShift*/}

                  {/* END_USER_CODE-USER_AFTER_btnLeftShift*/}
                  {/* START_USER_CODE-USER_BEFORE_btnRightShift*/}

                  {/* END_USER_CODE-USER_BEFORE_btnRightShift*/}

                  <ButtonWidget
                    conf={state.btnRightShift}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnRightShift*/}

                  {/* END_USER_CODE-USER_AFTER_btnRightShift*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx32*/}

                {/* END_USER_CODE-USER_AFTER_grpbx32*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx33*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx33*/}

                <GroupBoxWidget conf={state.grpbx33} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblSelectedColumns*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSelectedColumns*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSelectedColumns}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSelectedColumns*/}

                  {/* END_USER_CODE-USER_AFTER_lblSelectedColumns*/}
                  {/* START_USER_CODE-USER_BEFORE_lstSelected*/}

                  {/* END_USER_CODE-USER_BEFORE_lstSelected*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstSelected}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstSelected*/}

                  {/* END_USER_CODE-USER_AFTER_lstSelected*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx33*/}

                {/* END_USER_CODE-USER_AFTER_grpbx33*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx34*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx34*/}

                <GroupBoxWidget conf={state.grpbx34} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblColumnOrder*/}

                  {/* END_USER_CODE-USER_BEFORE_lblColumnOrder*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblColumnOrder}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblColumnOrder*/}

                  {/* END_USER_CODE-USER_AFTER_lblColumnOrder*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOrderUp1*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOrderUp1*/}

                  <ButtonWidget
                    conf={state.btnOrderUp1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOrderUp1*/}

                  {/* END_USER_CODE-USER_AFTER_btnOrderUp1*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOrderDown1*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOrderDown1*/}

                  <ButtonWidget
                    conf={state.btnOrderDown1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOrderDown1*/}

                  {/* END_USER_CODE-USER_AFTER_btnOrderDown1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbx35*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbx35*/}

                  <GroupBoxWidget conf={state.grpbx35} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_chkbxSummary*/}

                    {/* END_USER_CODE-USER_BEFORE_chkbxSummary*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkbxSummary}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkbxSummary*/}

                    {/* END_USER_CODE-USER_AFTER_chkbxSummary*/}
                    {/* START_USER_CODE-USER_BEFORE_btnFormulaEditor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnFormulaEditor*/}

                    <ButtonWidget
                      conf={state.btnFormulaEditor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnFormulaEditor*/}

                    {/* END_USER_CODE-USER_AFTER_btnFormulaEditor*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbx35*/}

                  {/* END_USER_CODE-USER_AFTER_grpbx35*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx34*/}

                {/* END_USER_CODE-USER_AFTER_grpbx34*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx3*/}

              {/* END_USER_CODE-USER_AFTER_grpbx3*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndcntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndcntnrActions*/}

              <CommandContainerWidget
                conf={state.cmmndcntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnRunReport*/}

                {/* END_USER_CODE-USER_BEFORE_btnRunReport*/}

                <ButtonWidget
                  conf={state.btnRunReport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRunReport*/}

                {/* END_USER_CODE-USER_AFTER_btnRunReport*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndcntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndcntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ProcurementReporting*/}

              {/* END_USER_CODE-USER_AFTER_ProcurementReporting*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ReportTool_ProcurementReporting);
