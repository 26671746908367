/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_TradeCategoryProfile from "./TradeCategoryProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeCategoryProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_TradeCategoryProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is TradeCategoryProfile Loads Successfully", () => {
    expect(screen.getByText("TradeCategoryProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeCategoryProfile", () => {
    // START_USER_CODE-USER_TradeCategoryProfile_Custom_Test_Case
    // END_USER_CODE-USER_TradeCategoryProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_TradeCategoryProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategoryProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategoryProfile_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxTradeCategoryProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeCategoryProfile = screen.getByTestId(
      "grpbxTradeCategoryProfile"
    );
    expect(grpbxTradeCategoryProfile.tagName).toBe("BUTTON");
    expect(grpbxTradeCategoryProfile.type).toBe("button");
    expect(grpbxTradeCategoryProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeCategoryProfile", () => {
    // START_USER_CODE-USER_grpbxTradeCategoryProfile_TEST
    // END_USER_CODE-USER_grpbxTradeCategoryProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategoryProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:TradeCategoryProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategoryProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:TradeCategoryProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtAccount(Textbox Widget) Test Cases", async () => {
    const txtAccount = screen.getByTestId("txtAccount");
    expect(txtAccount.tagName).toBe("INPUT");
    expect(txtAccount.type).toBe("text");
    expect(txtAccount.classList).toContain("textboxWidgetClass");
    expect(txtAccount.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategoryProfile_txtAccount")
    );
    await act(async () => {
      userEvent.type(txtAccount, "1");
    });
  });
  test("Custom Test Cases for txtAccount", () => {
    // START_USER_CODE-USER_txtAccount_TEST
    // END_USER_CODE-USER_txtAccount_TEST
  });
  test("txtTradeCategory(Textbox Widget) Test Cases", async () => {
    const txtTradeCategory = screen.getByTestId("txtTradeCategory");
    expect(txtTradeCategory.tagName).toBe("INPUT");
    expect(txtTradeCategory.type).toBe("text");
    expect(txtTradeCategory.classList).toContain("textboxWidgetClass");
    expect(txtTradeCategory.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:TradeCategoryProfile_txtTradeCategory"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeCategory, "1");
    });
  });
  test("Custom Test Cases for txtTradeCategory", () => {
    // START_USER_CODE-USER_txtTradeCategory_TEST
    // END_USER_CODE-USER_txtTradeCategory_TEST
  });
});
