/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  setValue,
  getData,
  getValue,
  setData,
  disable,
  enable,
  goTo,
} from "../../shared/WindowImports";

import "./FreightRateSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FreightRateSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FreightRateSetup",
    windowName: "FreightRateSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FreightRateSetup",
    // START_USER_CODE-USER_FreightRateSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Freight Rate Setup",
      scrCode: "PN0250D",
    },
    // END_USER_CODE-USER_FreightRateSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrPrintClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrPrintClose",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    ddDestBuyingPoint: {
      name: "ddDestBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Dest. Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddDestBuyingPoint_PROPERTIES
    },
    gridFreightRateSetupData: {
      name: "gridFreightRateSetupData",
      type: "GridWidget",
      parent: "grpbxFreightRateSetup",
      gridCellsOrder:
        "txtDestBuyingPoint,txtCarrierAbbriviationC,txtEffectiveDateC,txtCarrierVendor,txtNoLoadIndicator,txtEmpty",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Cols: "4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFreightRateSetupData_PROPERTIES

      // END_USER_CODE-USER_gridFreightRateSetupData_PROPERTIES
    },
    txtCarrierAbbriviation: {
      name: "txtCarrierAbbriviation",
      type: "TextBoxWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Carrier Abbreviation:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbriviation_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbriviation_PROPERTIES
    },
    txtCarrierAbbriviationC: {
      name: "txtCarrierAbbriviationC",
      type: "TextBoxWidget",
      colName: "txtColCarrierAbbriviation",
      parent: "gridFreightRateSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbriviationC_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbriviationC_PROPERTIES
    },
    txtCarrierVendor: {
      name: "txtCarrierVendor",
      type: "TextBoxWidget",
      colName: "txtColCarrierVendor",
      parent: "gridFreightRateSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierVendor_PROPERTIES

      // END_USER_CODE-USER_txtCarrierVendor_PROPERTIES
    },
    txtColCarrierAbbriviation: {
      name: "txtColCarrierAbbriviation",
      type: "GridColumnWidget",
      parent: "gridFreightRateSetupData",
      Label: "Carrier Abbreviation",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCarrierAbbriviation_PROPERTIES

      // END_USER_CODE-USER_txtColCarrierAbbriviation_PROPERTIES
    },
    txtColCarrierVendor: {
      name: "txtColCarrierVendor",
      type: "GridColumnWidget",
      parent: "gridFreightRateSetupData",
      Label: "Carrier  Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCarrierVendor_PROPERTIES

      // END_USER_CODE-USER_txtColCarrierVendor_PROPERTIES
    },
    txtColDestBuyingPoint: {
      name: "txtColDestBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridFreightRateSetupData",
      Label: "Dest. Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDestBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtColDestBuyingPoint_PROPERTIES
    },
    txtColEffectiveDate: {
      name: "txtColEffectiveDate",
      type: "GridColumnWidget",
      parent: "gridFreightRateSetupData",
      Label: "Effective Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtColEffectiveDate_PROPERTIES
    },
    txtColNoLoadIndicator: {
      name: "txtColNoLoadIndicator",
      type: "GridColumnWidget",
      parent: "gridFreightRateSetupData",
      Label: "No Load Indicator",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColNoLoadIndicator_PROPERTIES

      // END_USER_CODE-USER_txtColNoLoadIndicator_PROPERTIES
    },
    txtEmpty: {
      name: "txtEmpty",
      type: "TextBoxWidget",
      colName: "txtcolEmpty",
      parent: "gridFreightRateSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmpty_PROPERTIES

      // END_USER_CODE-USER_txtEmpty_PROPERTIES
    },
    txtcolEmpty: {
      name: "txtcolEmpty",
      type: "GridColumnWidget",
      parent: "gridFreightRateSetupData",
      Label: "",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolEmpty_PROPERTIES

      // END_USER_CODE-USER_txtcolEmpty_PROPERTIES
    },
    txtDestBuyingPoint: {
      name: "txtDestBuyingPoint",
      type: "TextBoxWidget",
      colName: "txtColDestBuyingPoint",
      parent: "gridFreightRateSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDestBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtDestBuyingPoint_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateWidget",
      parent: "grpbxFreightRateSetup",
      Label: "Effective Date:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtEffectiveDateC: {
      name: "txtEffectiveDateC",
      type: "TextBoxWidget",
      colName: "txtColEffectiveDate",
      parent: "gridFreightRateSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEffectiveDateC_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDateC_PROPERTIES
    },
    txtNoLoadIndicator: {
      name: "txtNoLoadIndicator",
      type: "TextBoxWidget",
      colName: "txtColNoLoadIndicator",
      parent: "gridFreightRateSetupData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNoLoadIndicator_PROPERTIES

      // END_USER_CODE-USER_txtNoLoadIndicator_PROPERTIES
    },
    grpbxFreightRateSetup: {
      name: "grpbxFreightRateSetup",
      type: "GroupBoxWidget",
      parent: "FreightRateSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFreightRateSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightRateSetup_PROPERTIES
    },
    cmmndCntnrPrintClose: {
      name: "cmmndCntnrPrintClose",
      type: "CommandContainerWidget",
      parent: "FreightRateSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrPrintClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrPrintClose_PROPERTIES
    },
  };
  
  let _buttonServices = {};
  let _buttonNavigation = {
    
    
  };
  
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let flag = getData(thisObj, "frmFreightRateSetupRefresh")
    if(flag){
      if([undefined, null, ''].includes(getValue(thisObj, 'ddDestBuyingPoint')) || [ undefined, null, ''].includes(getValue(thisObj, 'txtCarrierAbbriviation'))){
        return
      }
      setLoading(true)
      onbtnSearchClick()
      setData(thisObj, 'frmFreightRateSetupRefresh', false)
    }
  }, [getData(thisObj, "frmFreightRateSetupRefresh")])  

  // START_USER_CODE-USER_METHODS
  const formLoad = ()=> {
   
    enable(thisObj,"btnEdit");
          enable(thisObj,"btnDelete");
    bFillReceivingLocationList();
    setLoading(false);
  }
  //Binding data to the Dest. Buying Point drop down
  const bFillReceivingLocationList = (pageloadtype) => {
    try{
     
    let js = [];
    js.push({ key: '', description: '>>> All Locations <<<' })
    let buy_point_id = getValue(thisObj, 'ddDestBuyingPoint');
    if (buy_point_id == "" || buy_point_id == undefined) {
      buy_point_id = null;
    }
    let ddDestBuyingPointlength=0;
    setLoading(true);
    SettlementService.RetrieveTransferBuyingPointDetails(buy_point_id, null).then(response => {
      let data = response
      ddDestBuyingPointlength=data.length;
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].xfer_buy_pt_id.trim() + ' - ' + ConvertToPascalCase(data[i].xfer_buy_pt_name.trim()) }
        js.push(obj)
      }
    })

    thisObj.setState(current => {
      return {
        ...current,
        ddDestBuyingPoint: {
          ...state["ddDestBuyingPoint"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddDestBuyingPoint', js[0].key);
    setLoading(false);
  }
  catch(err){	
    setLoading(false);
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on bFillReceivingLocationList method"	)	
    }
    return false
  }
  }
  //Binding data to the grid
  const bFillFRTRate=async(buy_point_id,frt_scac_id)=>{
    setLoading(true);
    let EffectiveDate=getValue(thisObj,"txtEffectiveDate");
      let edate="";
      if(EffectiveDate!="" && EffectiveDate!=undefined)
      {
         edate = moment(String(EffectiveDate)).format('MM/DD/YYYY')
      }
    SystemMaintenanceMasterManagementService.RetrieveFreightRateDetails(buy_point_id,frt_scac_id,edate)
      .then(response => {
        let TransformedRowsArray = [];

        if (response == undefined || response == '') {
          setLoading(false);
          showMessage(thisObj, 'No Record found');
          setValue(thisObj, "gridFreightRateSetupData", []);
              return;
        }
        if (response != undefined && response.length > 0) {
          enable(thisObj,"btnEdit");
          enable(thisObj,"btnDelete");
          let data = response;
          let obj = {};
          for (let i = 0; i < data.length; i++) {
            obj.txtDestBuyingPoint = data[i].buy_pt_id + ' - ' + data[i].rt_buy_name;
            obj.txtCarrierAbbriviationC = data[i].frt_scac_id;
            if(moment(data[i].effective_date).format("hh:mm A")=="12:00 AM")
            obj.txtEffectiveDateC = moment(data[i].effective_date).format("M/D/YYYY");
            else
            obj.txtEffectiveDateC = moment(data[i].effective_date).format("M/D/YYYY hh:mm:ss A");
            obj.txtCarrierVendor = data[i].frt_vendor;
            obj.txtNoLoadIndicator = data[i].no_load;
            obj.txtdeadfreight=data[i].dead_freight;
            obj.txtratebasis=data[i].rate_basis;
            obj.txtaddedby=data[i].add_user+ " " + moment(data[0].add_date_time).format("M/D/YYYY");
            obj.txtchangedby=data[i].chg_user+ " " + moment(data[i].chg_date_time).format("M/D/YYYY");
            obj.txtDestBuyingPointid = data[i].buy_pt_id;
            obj.effective_date = data[i].effective_date;
            TransformedRowsArray.push(obj)
            obj = {}
          }
          setValue(thisObj, "gridFreightRateSetupData", TransformedRowsArray);
          setLoading(false);
        }

      })
     
  }
  //finding and binding the result with given values
  const onbtnSearchClick=()=>{
    try
    {
      let buy_point_id = getValue(thisObj, 'ddDestBuyingPoint');
      let CarrierAbbriviation=getValue(thisObj,"txtCarrierAbbriviation");
      
      if ((buy_point_id == "" || buy_point_id == undefined) && (CarrierAbbriviation=="" || CarrierAbbriviation==null || CarrierAbbriviation==undefined)) {
        showMessage(thisObj, "Please select Dest. Buying Point or enter a carrier."	)	
        return false;
      }
      bFillFRTRate(buy_point_id,CarrierAbbriviation)
    }
    catch(err){	
      setLoading(false);
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error on search button Click event"	)	
      }
      return false
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;
  //Opening freightrate profile screen for adding values
  const onbtnAddClick = () => {
    try{
      let js={
        "cmdOkCaption": "Add",
        "lblOrgBPTCaption": "",
        "cmdCopyLocationenabled":  false,
        "cmdCopyCarrierenabled":  false,
        "cmdCopyRateenabled":  false
      }
     setData(thisObj,"frmFreightRateProfile",js);
     goTo(thisObj, "SystemMaintenanceMasterManagement#FreightRateProfile#DEFAULT#true#Click");
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error on onbtnAddClick Click event"	)	
      }
      return false
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;
  //Opening freightrate profile screen for editing values
  const onbtnEditClick = () => {
    try{
      if(thisObj.state.gridFreightRateSetupData.selected?.length == 0){
        return
      }
      if (thisObj.state.gridFreightRateSetupData.selected[0].txtDestBuyingPoint != undefined || thisObj.state.gridFreightRateSetupData.selected[0].txtDestBuyingPoint!=null) {
      let chkNoLoadValue="";
      if(thisObj.state.gridFreightRateSetupData.selected[0].no_load=="Y")
      {
        chkNoLoadValue=1;
      }
      let js={
        "cmbRateBasis":"FLAT,MILE,100WT,TON",
        "lblFrtVendor":thisObj.state.gridFreightRateSetupData.selected[0].txtCarrierVendor,
        //"cmbRateBasisListIndex":0,
        "cmdOkCaption": "Save",
        "lblOrgBPTCaption": thisObj.state.gridFreightRateSetupData.selected[0].txtDestBuyingPoint,
        "txtScac_id":thisObj.state.gridFreightRateSetupData.selected[0].txtCarrierAbbriviationC,
        "chkNoLoadValue":chkNoLoadValue,
        "chkNoLoadenabled":thisObj.state.gridFreightRateSetupData.selected[0].txtNoLoadIndicator,
        "lblEffectiveDate":thisObj.state.gridFreightRateSetupData.selected[0].txtEffectiveDateC,
        "txtDeadFreight":thisObj.state.gridFreightRateSetupData.selected[0].txtdeadfreight,
        "lblAddedBy":  thisObj.state.gridFreightRateSetupData.selected[0].txtaddedby,
        "lblChangedBy":  thisObj.state.gridFreightRateSetupData.selected[0].txtchangedby,
        "cmbRateBasis":thisObj.state.gridFreightRateSetupData.selected[0].txtratebasis,
        "effectiveDate":thisObj.state.gridFreightRateSetupData.selected[0].effective_date
      }
     setData(thisObj,"frmFreightRateProfile",js);
     goTo(thisObj, "SystemMaintenanceMasterManagement#FreightRateProfile#DEFAULT#true#Click");
  }
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "No freight rate selected"	)	
      }
      return false
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick;
  //Deleteing the freight raltes
  const onbtnDeleteClick =async () => {
    try{
      const response = confirm("Are you sure you wish to delete the Rate for Location " + thisObj.state.gridFreightRateSetupData.selected[0].txtDestBuyingPoint);
      if (response) {
        let buyptid=thisObj.state.gridFreightRateSetupData.selected[0].txtDestBuyingPointid;
        let noload=thisObj.state.gridFreightRateSetupData.selected[0].txtNoLoadIndicator;
        let CarrierAbbriviationC=thisObj.state.gridFreightRateSetupData.selected[0].txtCarrierAbbriviationC;
        let edate=thisObj.state.gridFreightRateSetupData.selected[0].txtEffectiveDateC
        let dataObj=
        {
          "effective_date": edate,
          "mileage": "",
          "rate_basis": "",
          "vendor": "",
          "user_id": userid,
          "frt_scac_id": CarrierAbbriviationC,
          
        }
       let response = await SystemMaintenanceMasterManagementService.UpdateFreightRate("D",buyptid, noload,dataObj)
        if (response.status == 200) {
          bFillFRTRate(getValue(thisObj, 'ddDestBuyingPoint'),getValue(thisObj,"txtCarrierAbbriviation"))
        }
        else
        {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        return
        }
      }
      else {
        return
      }
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "No freight rate selected"	)	
      }
      return false
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
  //Printing grid result in PDF
  const onbtnPrintClick =async () => {
    try
    {
        var data1 = getValue(thisObj, "gridFreightRateSetupData")
        if (data1.length != 0) {
          var res = []
          let headerArray = []
          if (thisObj.state.gridFreightRateSetupData.columns[0].Visible) {
            headerArray.push("Dest. Buying Point");
          }
          if (thisObj.state.gridFreightRateSetupData.columns[1].Visible) {
            headerArray.push("Carrier Abbreviation");
          }
          if (thisObj.state.gridFreightRateSetupData.columns[2].Visible) {
            headerArray.push("Effective Date");
          }
          if (thisObj.state.gridFreightRateSetupData.columns[3].Visible) {
            headerArray.push("Carrier Vendor");
          }
          if (thisObj.state.gridFreightRateSetupData.columns[4].Visible) {
            headerArray.push("No Load Indicator");
          }
          if (thisObj.state.gridFreightRateSetupData.columns[5].Visible) {
            headerArray.push("                                    ");
          }
          for (var i = 0; i < data1.length; i++)
          {
            let NewArray = []
            if (thisObj.state.gridFreightRateSetupData.columns[0].Visible) {
              NewArray.push(data1[i].txtDestBuyingPoint);
            }
            if (thisObj.state.gridFreightRateSetupData.columns[1].Visible) {
              NewArray.push(data1[i].txtCarrierAbbriviationC);
            }
            if (thisObj.state.gridFreightRateSetupData.columns[2].Visible) {
              NewArray.push(data1[i].txtEffectiveDateC);
            }
            if (thisObj.state.gridFreightRateSetupData.columns[3].Visible) {
              NewArray.push(data1[i].txtCarrierVendor);
            }
            if (thisObj.state.gridFreightRateSetupData.columns[4].Visible) {
              NewArray.push(data1[i].txtNoLoadIndicator);
            }
            if (thisObj.state.gridFreightRateSetupData.columns[5].Visible) {
              NewArray.push(data1[i].txtEmpty);
            }
            res.push(NewArray)
          }
          // for (var i = 0; i < data1.length; i++) {
          //   res.push([
          //     data1[i].txtDestBuyingPoint, data1[i].txtCarrierAbbriviationC, data1[i].txtEffectiveDateC, data1[i].txtCarrierVendor, data1[i].txtNoLoadIndicator
          //   ])
          // }
          var obj = new jsPDF();
          obj.autoTable({
            styles: { fontSize: 5, lineWidth: 0.2 },
            margin: { top: 5, left: 0.5 },
            body: res,
            theme: 'plain',
            fontStyle: 'normal',
            head: [headerArray]
          });
          var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
       obj.text('Location to Location Mileage. '+cropYear, 2, obj.internal.pageSize.height - 2);
       obj.text(pageCurrent?.toString(), obj?.internal?.pageSize?.width - 15, obj?.internal?.pageSize?.height - 2 )
      }
          let fileName = prompt("Please enter the file name :");
          if (fileName != null && fileName != undefined) {
            if (fileName != '') {
              obj.save(fileName + '.pdf')
            }
            else {
              alert("Routine:FreightRateSetup.cmdPrint_Click")
            }
          }
          else {
            alert("Routine:FreightRateSetup.cmdPrint_Click")
          }
        }
  
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"	)	
      }
      return false
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;
  //Closing the freight rate setup screen
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_FreightRateSetupPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;

  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  function ConvertToPascalCase(word){

    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FreightRateSetup*/}

              {/* END_USER_CODE-USER_BEFORE_FreightRateSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFreightRateSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFreightRateSetup*/}

              <GroupBoxWidget
                conf={state.grpbxFreightRateSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddDestBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddDestBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDestBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDestBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddDestBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbriviation*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbriviation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrierAbbriviation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrierAbbriviation*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrierAbbriviation*/}
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridFreightRateSetupData*/}

                {/* END_USER_CODE-USER_BEFORE_gridFreightRateSetupData*/}

                <GridWidget
                  conf={state.gridFreightRateSetupData}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFreightRateSetupData}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFreightRateSetupData*/}

                {/* END_USER_CODE-USER_AFTER_gridFreightRateSetupData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFreightRateSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFreightRateSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrPrintClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrPrintClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrPrintClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrPrintClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrPrintClose*/}

              {/* START_USER_CODE-USER_AFTER_FreightRateSetup*/}

              {/* END_USER_CODE-USER_AFTER_FreightRateSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FreightRateSetup);
