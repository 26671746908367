/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_CountySetup from "./CountySetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CountySetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_CountySetup />);
    });
  });
  afterEach(cleanup);
  test("is CountySetup Loads Successfully", () => {
    expect(screen.getByText("CountySetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for CountySetup", () => {
    // START_USER_CODE-USER_CountySetup_Custom_Test_Case
    // END_USER_CODE-USER_CountySetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_CountySetup />);
    });
  });
  afterEach(cleanup);
  test("btnAddC(Button Widget) Test Cases", async () => {
    const btnAddC = screen.getByTestId("btnAddC");
    expect(btnAddC).toBeInTheDocument;
    expect(btnAddC.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnAddC")
    );
  });
  test("Custom Test Cases for btnAddC", () => {
    // START_USER_CODE-USER_btnAddC_TEST
    // END_USER_CODE-USER_btnAddC_TEST
  });
  test("btnAddS(Button Widget) Test Cases", async () => {
    const btnAddS = screen.getByTestId("btnAddS");
    expect(btnAddS).toBeInTheDocument;
    expect(btnAddS.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnAddS")
    );
  });
  test("Custom Test Cases for btnAddS", () => {
    // START_USER_CODE-USER_btnAddS_TEST
    // END_USER_CODE-USER_btnAddS_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDeleteC(Button Widget) Test Cases", async () => {
    const btnDeleteC = screen.getByTestId("btnDeleteC");
    expect(btnDeleteC).toBeInTheDocument;
    expect(btnDeleteC.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnDeleteC")
    );
  });
  test("Custom Test Cases for btnDeleteC", () => {
    // START_USER_CODE-USER_btnDeleteC_TEST
    // END_USER_CODE-USER_btnDeleteC_TEST
  });
  test("btnDleteS(Button Widget) Test Cases", async () => {
    const btnDleteS = screen.getByTestId("btnDleteS");
    expect(btnDleteS).toBeInTheDocument;
    expect(btnDleteS.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnDleteS")
    );
  });
  test("Custom Test Cases for btnDleteS", () => {
    // START_USER_CODE-USER_btnDleteS_TEST
    // END_USER_CODE-USER_btnDleteS_TEST
  });
  test("btneDisable(Button Widget) Test Cases", async () => {
    const btneDisable = screen.getByTestId("btneDisable");
    expect(btneDisable).toBeInTheDocument;
    expect(btneDisable.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btneDisable")
    );
  });
  test("Custom Test Cases for btneDisable", () => {
    // START_USER_CODE-USER_btneDisable_TEST
    // END_USER_CODE-USER_btneDisable_TEST
  });
  test("btnEditC(Button Widget) Test Cases", async () => {
    const btnEditC = screen.getByTestId("btnEditC");
    expect(btnEditC).toBeInTheDocument;
    expect(btnEditC.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnEditC")
    );
  });
  test("Custom Test Cases for btnEditC", () => {
    // START_USER_CODE-USER_btnEditC_TEST
    // END_USER_CODE-USER_btnEditC_TEST
  });
  test("btnEditS(Button Widget) Test Cases", async () => {
    const btnEditS = screen.getByTestId("btnEditS");
    expect(btnEditS).toBeInTheDocument;
    expect(btnEditS.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:CountySetup_btnEditS")
    );
  });
  test("Custom Test Cases for btnEditS", () => {
    // START_USER_CODE-USER_btnEditS_TEST
    // END_USER_CODE-USER_btnEditS_TEST
  });
  test("grpbxCountySetup(GroupBox Widget) Test Cases", async () => {
    const grpbxCountySetup = screen.getByTestId("grpbxCountySetup");
    expect(grpbxCountySetup.tagName).toBe("BUTTON");
    expect(grpbxCountySetup.type).toBe("button");
    expect(grpbxCountySetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCountySetup", () => {
    // START_USER_CODE-USER_grpbxCountySetup_TEST
    // END_USER_CODE-USER_grpbxCountySetup_TEST
  });
});
