/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  getValue,
  getData,
  setData,
  enable,
  disable
} from "../../shared/WindowImports";

import "./DelvSettleScaleTicket.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { useField } from "formik";
import { isDate } from "moment";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvSettleScaleTicket(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvSettleScaleTicket",
    windowName: "DelvSettleScaleTicket",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvSettleScaleTicket",
    // START_USER_CODE-USER_DelvSettleScaleTicket_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Settle Scale Ticket",
      scrCode: "PN1180J",
    },
    // END_USER_CODE-USER_DelvSettleScaleTicket_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxSplitWtRW: {
      name: "chkboxSplitWtRW",
      type: "CheckBoxWidget",
      parent: "grpbxReceivedWt",
      Label: "Split Weight",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSplitWtRW_PROPERTIES

      // END_USER_CODE-USER_chkboxSplitWtRW_PROPERTIES
    },
    chkboxSplitWtSW: {
      name: "chkboxSplitWtSW",
      type: "CheckBoxWidget",
      parent: "grpbxShippedWt",
      Label: "Split Weight",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSplitWtSW_PROPERTIES

      // END_USER_CODE-USER_chkboxSplitWtSW_PROPERTIES
    },
    grpbxLoadInfo: {
      name: "grpbxLoadInfo",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleScaleTicket",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxLoadInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxLoadInfo_PROPERTIES
    },
    grpbxReceivedWt: {
      name: "grpbxReceivedWt",
      type: "GroupBoxWidget",
      parent: "grpbxShippedReceivedWt",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxReceivedWt_PROPERTIES

      // END_USER_CODE-USER_grpbxReceivedWt_PROPERTIES
    },
    grpbxShippedReceivedWt: {
      name: "grpbxShippedReceivedWt",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleScaleTicket",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxShippedReceivedWt_PROPERTIES

      // END_USER_CODE-USER_grpbxShippedReceivedWt_PROPERTIES
    },
    grpbxShippedWt: {
      name: "grpbxShippedWt",
      type: "GroupBoxWidget",
      parent: "grpbxShippedReceivedWt",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxShippedWt_PROPERTIES

      // END_USER_CODE-USER_grpbxShippedWt_PROPERTIES
    },
    grpbxUpdateInventory: {
      name: "grpbxUpdateInventory",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleScaleTicket",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxUpdateInventory_PROPERTIES

      // END_USER_CODE-USER_grpbxUpdateInventory_PROPERTIES
    },
    grpbxWtVariance: {
      name: "grpbxWtVariance",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleScaleTicket",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxWtVariance_PROPERTIES

      // END_USER_CODE-USER_grpbxWtVariance_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblDateFormatRW: {
      name: "lblDateFormatRW",
      type: "LabelWidget",
      parent: "grpbxReceivedWt",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateFormatRW_PROPERTIES

      // END_USER_CODE-USER_lblDateFormatRW_PROPERTIES
    },
    lblDateFormatSW: {
      name: "lblDateFormatSW",
      type: "LabelWidget",
      parent: "grpbxShippedWt",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateFormatSW_PROPERTIES

      // END_USER_CODE-USER_lblDateFormatSW_PROPERTIES
    },
    lblLbs: {
      name: "lblLbs",
      type: "LabelWidget",
      parent: "grpbxWtVariance",
      Label: "Lbs.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLbs_PROPERTIES

      // END_USER_CODE-USER_lblLbs_PROPERTIES
    },
    lblLoadInfo: {
      name: "lblLoadInfo",
      type: "LabelWidget",
      parent: "grpbxLoadInfo",
      Label: "Load Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoadInfo_PROPERTIES

      // END_USER_CODE-USER_lblLoadInfo_PROPERTIES
    },
    lblReceivedWt: {
      name: "lblReceivedWt",
      type: "LabelWidget",
      parent: "grpbxReceivedWt",
      Label: "Received Weight",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReceivedWt_PROPERTIES

      // END_USER_CODE-USER_lblReceivedWt_PROPERTIES
    },
    lblShippedWeight: {
      name: "lblShippedWeight",
      type: "LabelWidget",
      parent: "grpbxShippedWt",
      Label: "Shipped Weight",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippedWeight_PROPERTIES

      // END_USER_CODE-USER_lblShippedWeight_PROPERTIES
    },
    radioUpdateInventory: {
      name: "radioUpdateInventory",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxUpdateInventory",
      Options: "Shipped Weight:1,Received Weight:2,Other Weight:3",
      Label: "Update Inventory With:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioUpdateInventory_PROPERTIES

      // END_USER_CODE-USER_radioUpdateInventory_PROPERTIES
    },
    txtDriver: {
      name: "txtDriver",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Driver:",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDriver_PROPERTIES

      // END_USER_CODE-USER_txtDriver_PROPERTIES
    },
    txtLbsOfPeanutsRW: {
      name: "txtLbsOfPeanutsRW",
      type: "TextBoxWidget",
      parent: "grpbxReceivedWt",
      Label: "Lbs. of Peanuts:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLbsOfPeanutsRW_PROPERTIES

      // END_USER_CODE-USER_txtLbsOfPeanutsRW_PROPERTIES
    },
    txtLbsOfPeanutsSW: {
      name: "txtLbsOfPeanutsSW",
      type: "TextBoxWidget",
      parent: "grpbxShippedWt",
      Label: "Lbs. of Peanuts:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLbsOfPeanutsSW_PROPERTIES

      // END_USER_CODE-USER_txtLbsOfPeanutsSW_PROPERTIES
    },
    txtLoadNum: {
      name: "txtLoadNum",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Load #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoadNum_PROPERTIES

      // END_USER_CODE-USER_txtLoadNum_PROPERTIES
    },
    txtOtherWt: {
      name: "txtOtherWt",
      type: "TextBoxWidget",
      parent: "grpbxUpdateInventory",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherWt_PROPERTIES

      // END_USER_CODE-USER_txtOtherWt_PROPERTIES
    },
    txtReceivedDate: {
      name: "txtReceivedDate",
      type: "DateWidget",
      parent: "grpbxReceivedWt",
      Label: "Received Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtReceivedDate_PROPERTIES

      // END_USER_CODE-USER_txtReceivedDate_PROPERTIES
    },
    txtScaleTicketNumRW: {
      name: "txtScaleTicketNumRW",
      type: "TextBoxWidget",
      parent: "grpbxReceivedWt",
      Label: "Scale Ticket #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtScaleTicketNumRW_PROPERTIES

      // END_USER_CODE-USER_txtScaleTicketNumRW_PROPERTIES
    },
    txtScaleTicketNumSW: {
      name: "txtScaleTicketNumSW",
      type: "TextBoxWidget",
      parent: "grpbxShippedWt",
      Label: "Scale Ticket #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtScaleTicketNumSW_PROPERTIES

      // END_USER_CODE-USER_txtScaleTicketNumSW_PROPERTIES
    },
    txtShippedDate: {
      name: "txtShippedDate",
      type: "DateWidget",
      parent: "grpbxShippedWt",
      Label: "Shipped Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtShippedDate_PROPERTIES

      // END_USER_CODE-USER_txtShippedDate_PROPERTIES
    },
    txtTruckLine: {
      name: "txtTruckLine",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Truck Line:",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTruckLine_PROPERTIES

      // END_USER_CODE-USER_txtTruckLine_PROPERTIES
    },
    txtVehicleNum: {
      name: "txtVehicleNum",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Vehicle #:",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtVehicleNum_PROPERTIES
    },
    txtWtIncldVehicleRW: {
      name: "txtWtIncldVehicleRW",
      type: "TextBoxWidget",
      parent: "grpbxReceivedWt",
      Label: "Wt. Including Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncldVehicleRW_PROPERTIES

      // END_USER_CODE-USER_txtWtIncldVehicleRW_PROPERTIES
    },
    txtWtIncldVehicleSW: {
      name: "txtWtIncldVehicleSW",
      type: "TextBoxWidget",
      parent: "grpbxShippedWt",
      Label: "Wt. Including Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncldVehicleSW_PROPERTIES

      // END_USER_CODE-USER_txtWtIncldVehicleSW_PROPERTIES
    },
    txtWtOfVehicleRW: {
      name: "txtWtOfVehicleRW",
      type: "TextBoxWidget",
      parent: "grpbxReceivedWt",
      Label: "Wt. of Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicleRW_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicleRW_PROPERTIES
    },
    txtWtOfVehicleSW: {
      name: "txtWtOfVehicleSW",
      type: "TextBoxWidget",
      parent: "grpbxShippedWt",
      Label: "Wt. of Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicleSW_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicleSW_PROPERTIES
    },
    txtWtVariance: {
      name: "txtWtVariance",
      type: "TextBoxWidget",
      parent: "grpbxWtVariance",
      Label: "Weight Variance:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtVariance_PROPERTIES

      // END_USER_CODE-USER_txtWtVariance_PROPERTIES
    },
    grpbxDelvSettleScaleTicket: {
      name: "grpbxDelvSettleScaleTicket",
      type: "GroupBoxWidget",
      parent: "DelvSettleScaleTicket",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDelvSettleScaleTicket_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSettleScaleTicket_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "DelvSettleScaleTicket",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const caption = getData(thisObj, "TradeSettleScaleTicketdata");//Data Coming from Parent screen

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    if(caption != undefined && caption != null && caption != ''){
      if (caption?.cmdok == "Add") {
      FormLoad();
      }
      if(caption?.cmdok == "Update"){
        editCondition()
      }
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // Logic for Form load
  function FormLoad() {
    try {
      if (caption?.cmdok == "Add") {
        document.getElementById("btnOk").innerText = "Add";
        enable(thisObj, "btnOk");
      }
      if ((caption?.status)?.toUpperCase() !== "PENDING") {
        disable(thisObj, "btnOk");
      } else {
        enable(thisObj, "btnOk");
      }
      disable(thisObj, "txtLbsOfPeanutsSW");
      disable(thisObj, "txtLbsOfPeanutsRW");
      disable(thisObj, "txtWtVariance");
      disable(thisObj, "txtOtherWt");
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during FormLoad: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const editCondition = async () => {//Edit Mode Form load
    try {
      document.getElementById("btnOk").innerText = "Update";
      if ((caption?.status)?.toUpperCase() !== "PENDING") {
        disable(thisObj, "btnOk");
      } else {
        enable(thisObj, "btnOk");
      }
        disable(thisObj, "txtLbsOfPeanutsSW");
        disable(thisObj, "txtLbsOfPeanutsRW");
        disable(thisObj, "txtWtVariance");
        disable(thisObj, "txtOtherWt");
        let loanNum = caption?.arrTicketInfo?.load_num
        setValue(thisObj,"txtLoadNum",loanNum)
        let vehicleNum = caption?.arrTicketInfo?.vehicle_num
        setValue(thisObj,"txtVehicleNum",vehicleNum)
        let truckline = caption?.arrTicketInfo?.truck_line
        setValue(thisObj,"txtTruckLine",truckline)
        let driver = caption?.arrTicketInfo?.driver_name
        setValue(thisObj,"txtDriver",driver)
        let shipdates = caption?.arrTicketInfo?.shp_date
        if(shipdates != undefined && shipdates !=''){
          shipdates = convert(shipdates)
          setValue(thisObj,"txtShippedDate",shipdates)
        }
        else{
          shipdates = ''
          setValue(thisObj,"txtShippedDate",shipdates)
        }
        let sscaletktnum = caption?.arrTicketInfo?.shp_scale_tick
        setValue(thisObj,"txtScaleTicketNumSW",sscaletktnum)

        let schecked = caption?.chkSSplitWeight
        if(schecked != undefined && schecked != null && schecked != ''){
          switch (schecked) {
            case true:
              setValue(thisObj,"chkboxSplitWtSW",true)
              break;
            case false:
              setValue(thisObj,"chkboxSplitWtSW",false)
              break;
            default:
              setValue(thisObj,"chkboxSplitWtSW",false)
              break;
          }
        }

        let sweitinclude = caption?.txtSWeightIncludingVehicle
        setValue(thisObj,"txtWtIncldVehicleSW",sweitinclude)
        let sweight = caption?.txtSWeightOfVehicle
        setValue(thisObj,"txtWtOfVehicleSW",sweight)

        let lbspeanut = caption?.lblSLbsOfPeanuts //peanuts difference
        if(lbspeanut != undefined && lbspeanut != null){
          lbspeanut = Number(caption?.lblSLbsOfPeanuts)
          setValue(thisObj,"txtLbsOfPeanutsSW",lbspeanut)
        }

        let receivedates = caption?.arrTicketInfo?.rec_date
        if(receivedates != undefined && receivedates !=''){
          receivedates = convert(receivedates)
        }
        else{
          receivedates = ''
        }
        setValue(thisObj,"txtReceivedDate",receivedates)

        let rscaleticketNum = caption?.arrTicketInfo?.rec_scale_tick
        setValue(thisObj,"txtScaleTicketNumRW",rscaleticketNum)

        let rchecked = caption?.chkRSplitWeight
        if(rchecked != undefined && rchecked != null && rchecked != ''){
          switch (rchecked) {
            case true:
              setValue(thisObj,"chkboxSplitWtRW",true)
              break;
            default:
              setValue(thisObj,"chkboxSplitWtRW",false)
              break;
          }
        }

        let rweightinclude = caption?.txtRWeightIncludingVehicle
        setValue(thisObj,"txtWtIncldVehicleRW",rweightinclude)

        let rweightvehicle = caption?.txtRWeightOfVehicle
        setValue(thisObj,"txtWtOfVehicleRW",rweightvehicle)

        let rlblpeanut = caption?.lblRLbsOfPeanuts//peanuts difference
        if(rlblpeanut != undefined && rlblpeanut != null){
          rlblpeanut = Number(caption?.lblRLbsOfPeanuts)
          setValue(thisObj,"txtLbsOfPeanutsRW",rlblpeanut)
        }

        let optionsShippedweight = caption?.arrTicketInfo?.inventory_with_ind
        if(optionsShippedweight == 'S'){
          setValue(thisObj,"radioUpdateInventory","1")
        }
        let optionsReceiveweight = caption?.arrTicketInfo?.inventory_with_ind
        if(optionsReceiveweight == 'R'){
          setValue(thisObj,"radioUpdateInventory","2")
        }
        let optionOtherWeight = caption?.arrTicketInfo?.inventory_with_ind
        if(optionOtherWeight == 'O'){
          setValue(thisObj,"radioUpdateInventory","3")
          let txtotherweight = caption?.txtOtherWeight || ''
          setValue(thisObj,"txtOtherWt",txtotherweight)
          enable(thisObj, "txtOtherWt");
        }else{
          let otherzero = caption?.txtOtherWeight || ''
          setValue(thisObj,"txtOtherWt",otherzero)
        }
        await CalculateReceivedTotal()
        await CalculateShippedTotal()
        ontxtLbsOfPeanutsRWBlur(Number(lbspeanut),Number(rlblpeanut))
        ontxtLbsOfPeanutsSWBlur(Number(lbspeanut),Number(rlblpeanut))

        let sPermission = caption?.sPermission 
        if(sPermission == "I"){
          disable(thisObj, "btnOk");
        }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during editCondition: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  // Logic for date validating
  function isValidDate(date) {
    let dateObj = new Date(date);
    if (dateObj instanceof Date) {
      return true;
    } else {
      return false;
    }
  }

  function convert(str) { // for date conversion to mm/dd/yyyy
    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }

  // Logic for bFormValid Functionality
  const bFormValid = async () => {
    try {
      let bFormValid = false
      let loanNum = getValue(thisObj, 'txtLoadNum');
      let radioUpdateInventory = getValue(thisObj, 'radioUpdateInventory');

      let shipdate = getValue(thisObj, "txtShippedDate");
      if(shipdate != undefined && shipdate != null ){
        shipdate = convert(shipdate)
      }

      let SScaleTicketNum = getValue(thisObj, 'txtScaleTicketNumSW');
      let SWeightIncludingVehicle = getValue(thisObj, 'txtWtIncldVehicleSW');
      let SWeightOfVehicle = getValue(thisObj, 'txtWtOfVehicleSW');
      let lblSLbsOfPeanuts = getValue(thisObj, 'txtLbsOfPeanutsSW');

      let ReceivedDate = getValue(thisObj, 'txtReceivedDate');
      if(ReceivedDate != undefined && ReceivedDate != null){
        ReceivedDate = convert(ReceivedDate)
      }

      let RScaleTicketNum = getValue(thisObj, 'txtScaleTicketNumRW');
      let RWeightIncludingVehicle = getValue(thisObj, 'txtWtIncldVehicleRW');
      let RWeightOfVehicle = getValue(thisObj, 'txtWtOfVehicleRW');
      let RLbsOfPeanuts = getValue(thisObj, 'txtLbsOfPeanutsRW');
      let txtOtherWt = getValue(thisObj, 'txtOtherWt');
      if (isNaN(loanNum) || loanNum == '') {
        setValue(thisObj, 'txtLoadNum', "1");
      }
      if ((radioUpdateInventory == undefined || radioUpdateInventory == "")) {
        alert("An Update Inventory with Weight must be selected.");
        return;
      }

      //optShippedWeight radio button 1
      if (radioUpdateInventory == 1) {
        if ((!isValidDate(shipdate)) || (shipdate == undefined) || (shipdate == "")) {
          alert("Shipped Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
          return;
        }
        if (SScaleTicketNum == "" || SScaleTicketNum == null || SScaleTicketNum == undefined) {
          alert("Shipped Scale Ticket # is a required field when Update Inventory with Shipped Weight is selected.");
          document.getElementById("txtScaleTicketNumSW").focus();
          return;
        }
        if (SWeightIncludingVehicle == "" || SWeightIncludingVehicle == null || SWeightIncludingVehicle == undefined) {
          alert("Shipped Weight Including Vehicle is a required field when Update Inventory with Shipped Weight is selected.");
          document.getElementById("txtWtIncldVehicleSW").focus();
          return;
        }
        if (!isNaN(SWeightIncludingVehicle)) {
          if (SWeightIncludingVehicle < 0 || SWeightIncludingVehicle > 999999999) {
            alert("Shipped Weight Including Vehicle must be of format #########");
            document.getElementById("txtWtIncldVehicleSW").focus();
            return;
          }
        }
        else {
          alert("Shipped Weight Including Vehicle must be of format #########");
          document.getElementById("txtWtIncldVehicleSW").focus();
          return;
        }
        if (SWeightOfVehicle == '' || SWeightOfVehicle == undefined || SWeightOfVehicle == null) {
          alert("Shipped Weight Of Vehicle is a required field when Update Inventory with Shipped Weight is selected.");
          document.getElementById("txtWtOfVehicleSW").focus();
          return;
        }
        if (!isNaN(SWeightOfVehicle)) {
          if (SWeightOfVehicle < 0 || SWeightOfVehicle > 999999999) {
            alert("Shipped Weight Of Vehicle must be of format #########");
            document.getElementById("txtWtOfVehicleSW").focus();
            return;
          }
        }
        else {
          alert("Shipped Weight Of Vehicle must be of format #########");
          document.getElementById("txtWtOfVehicleSW").focus();
          return;
        }
        if (((lblSLbsOfPeanuts) !== '') || ((lblSLbsOfPeanuts) !== undefined)) {
          if (!isNaN(lblSLbsOfPeanuts)) {
            if (lblSLbsOfPeanuts <= 0 || lblSLbsOfPeanuts > 999999999) {
              alert("Shipped Weight Of Peanuts cannot be negative or zero.");
              return;
            }
          }
          else {
            alert("Shipped Weight Of Peanuts must be of format #########");
            return;
          }
        }
      }

      //optReceivedWeight radio button 2
      if (radioUpdateInventory == 2) {
        if (!isValidDate(ReceivedDate) || (ReceivedDate == undefined) || (ReceivedDate == "")) {
          alert("Received Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
          return;
        }
        if ((RScaleTicketNum)?.trim() == '' || (RScaleTicketNum)?.trim() == undefined || (RScaleTicketNum)?.trim() == null) {
          alert("Received Scale Ticket # is a required field when Update Inventory with Received Weight is selected.");
          document.getElementById("txtScaleTicketNumRW").focus();
          return;
        }
        if (RWeightIncludingVehicle == '' || RWeightIncludingVehicle == undefined || RWeightIncludingVehicle == null) {
          alert("Received Weight Including Vehicle is a required field when Update Inventory with Received Weight is selected.");
          document.getElementById("txtWtIncldVehicleRW").focus();
          return;
        }
        if (isNaN(RWeightIncludingVehicle)) {
          alert('Received Weight Including Vehicle must be of format #########');
          document.getElementById("txtWtIncldVehicleRW").focus();
          return;
        }
        else {
          if (RWeightIncludingVehicle < 0 || RWeightIncludingVehicle > 999999999) {
            alert('Received Weight Including Vehicle must be of format #########');
            document.getElementById("txtWtIncldVehicleRW").focus();
            return;
          }
        }
        if (RWeightOfVehicle == '' || RWeightOfVehicle == undefined || RWeightOfVehicle == null) {
          alert('Received Weight Of Vehicle is a required field when Update Inventory with Received Weight is selected.');
          document.getElementById("txtWtOfVehicleRW").focus();
          return;
        }
        if (isNaN(RWeightOfVehicle)) {
          alert('Received Weight Of Vehicle must be of format #########"');
          document.getElementById("txtWtOfVehicleRW").focus();
          return;
        }
        else {
          if (RWeightOfVehicle < 0 || RWeightOfVehicle > 999999999) {
            alert('Received Weight Of Vehicle must be of format #########"');
            document.getElementById("txtWtOfVehicleRW").focus();
            return;
          }
        }
        if (RLbsOfPeanuts !== '' || RLbsOfPeanuts !== undefined || RLbsOfPeanuts !== null);
        {
          if (!isNaN(RLbsOfPeanuts)) {
            if (RLbsOfPeanuts < 0 || RLbsOfPeanuts > 999999999) {
              alert('Received Weight Of Peanuts must be of format #########');
              return;
            }
          }
          else {
            alert('Received Weight Of Peanuts must be of format #########')
            return;
          }
        }
      }

      // optOtherWeight radio button 3
      if (radioUpdateInventory == 3) {
        if (txtOtherWt == '' || txtOtherWt == undefined || txtOtherWt == null) {
          alert('Other Weight is required when Update Inventory with Other Weight is selected.!!!');
          document.getElementById("txtOtherWt").focus();
          return;
        }

        if (!isNaN(txtOtherWt)) {
          if (txtOtherWt < 0 || txtOtherWt > 999999999) {
            alert('Other Weight must be of format #########');
            document.getElementById("txtOtherWt").focus();
            return;
          }
        }
        else {
          alert('Other Weight must be of format #########');
          document.getElementById("txtOtherWt").focus();
          return;
        }
        if (txtOtherWt != RLbsOfPeanuts) {
          alert('Please change the Received Weights so that the Total Received Pounds of Peanuts equals the Other Weight.');
          document.getElementById("txtWtIncldVehicleRW").focus();
          return;
        }
      }


      if (SScaleTicketNum != "" && SScaleTicketNum != undefined && SScaleTicketNum != null) {
        if (!isValidDate(shipdate) || (shipdate == undefined) || (shipdate == "")) {
          alert("Shipped Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
          document.getElementById("txtScaleTicketNumSW").focus();
          return;
        }
      }
      if (RScaleTicketNum != "" && RScaleTicketNum != undefined && RScaleTicketNum != null) {
        if (!isValidDate(ReceivedDate) || (ReceivedDate == undefined) || (ReceivedDate == "")) {
          alert("Received Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
          document.getElementById("txtScaleTicketNumRW").focus();
          return;
        }
      }
      if (isValidDate(shipdate) && isValidDate(ReceivedDate)) {
        if (new Date(ReceivedDate) < new Date(shipdate)) {
          alert("The Received date cannot be less than the Shipped date");
          return;
        }
      }
      bFormValid = true
      return bFormValid    
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFormValid: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  // Logic for OK button functionality
  const onbtnOkClick = async () => {
    try {
      if (!await bFormValid()) {
        return false;
      } else {
        let button = document.getElementById("btnOk").innerText;
        let sWeightInd = ''
        let txtOtherWeight = ''
        let loadNum = getValue(thisObj, 'txtLoadNum');
        let radioUpdateInventory = getValue(thisObj, 'radioUpdateInventory');

        let shipdate = getValue(thisObj, "txtShippedDate");
        if (shipdate != undefined && shipdate != '') {
          shipdate = getValue(thisObj, "txtShippedDate");
        }
        else {
          shipdate = ''
        }

        let SScaleTicketNum = getValue(thisObj, 'txtScaleTicketNumSW');
        let SWeightIncludingVehicle = getValue(thisObj, 'txtWtIncldVehicleSW');
        let SWeightOfVehicle = getValue(thisObj, 'txtWtOfVehicleSW');
        let lblSLbsOfPeanuts = getValue(thisObj, 'txtLbsOfPeanutsSW');

        let ReceivedDate = getValue(thisObj, "txtReceivedDate");
        if (ReceivedDate != undefined && ReceivedDate != '') {
          ReceivedDate = getValue(thisObj, "txtReceivedDate");
        } else {
          ReceivedDate = ''
        }

        let RScaleTicketNum = getValue(thisObj, 'txtScaleTicketNumRW');
        let RWeightIncludingVehicle = getValue(thisObj, 'txtWtIncldVehicleRW');
        let RWeightOfVehicle = getValue(thisObj, 'txtWtOfVehicleRW');
        let RLbsOfPeanuts = getValue(thisObj, 'txtLbsOfPeanutsRW');
        let txtOtherWt = getValue(thisObj, 'txtOtherWt');
        let vehicleNum = getValue(thisObj, "txtVehicleNum")
        let truckLine = getValue(thisObj, "txtTruckLine")
        let driver = getValue(thisObj, "txtDriver")
        let lblwetvarience = getValue(thisObj, "txtWtVariance")

        if (radioUpdateInventory == "1") {
          sWeightInd = "S"
          lblSLbsOfPeanuts = lblSLbsOfPeanuts
        }
        else if (radioUpdateInventory == '3') {
          sWeightInd = "O"
          txtOtherWeight = txtOtherWt
        } else {
          sWeightInd = "R"
          RLbsOfPeanuts = RLbsOfPeanuts
        }

        let chkSSplitWeight = document.getElementById('chkboxSplitWtSW').checked || ''
        let valuecheck = ''
        if (chkSSplitWeight != undefined && chkSSplitWeight != null && chkSSplitWeight != '') {
          valuecheck = "Y"
        } else {
          valuecheck = ''
        }

        let chkRSplitWeight = document.getElementById('chkboxSplitWtRW').checked || ''
        let valuecheckr = ''
        if (chkRSplitWeight != undefined && chkRSplitWeight != null && chkRSplitWeight != '') {
          valuecheckr = "Y"
        } else {
          valuecheckr = ''
        }

        // Add Condition for the Adding Scale Tickets
        if (button?.toUpperCase() == "ADD") {
          let obj = {
            load_num: loadNum || '',
            vehicle_num: vehicleNum || '',
            truck_line: truckLine || '',
            driver_name: driver || '',
            shp_date: convert(shipdate) || '',
            shp_scale_tick: SScaleTicketNum || '',
            shp_tare_wt: SWeightIncludingVehicle || '',
            shp_vehicle_wt: SWeightOfVehicle || '',
            shp_peanut_wt: lblSLbsOfPeanuts || '',
            rec_date: convert(ReceivedDate) || '',
            rec_scale_tick: RScaleTicketNum || '',
            rec_tare_wt: RWeightIncludingVehicle || '',
            rec_vehicle_wt: RWeightOfVehicle || '',
            shp_split_scale: valuecheck || '',
            rec_split_scale: valuecheckr || '',
            inventory_with_ind: sWeightInd || '',
            variance: lblwetvarience || '',
            rec_peanut_wt: RLbsOfPeanuts || '',
            sWeightInd: sWeightInd || '',
            inventory_other_wt: txtOtherWt || '',
            totalConcateBarValue: (loadNum || '') + "|" + (vehicleNum || '') + '|' + (truckLine || '') + "|" + (driver || '') + '|' + (convert(shipdate) || '') + "|" + (SScaleTicketNum || '') + "|" + (valuecheck || '') + "|" + (SWeightIncludingVehicle || '') + "|" + (SWeightOfVehicle || '') + "|" + (lblSLbsOfPeanuts || '') + "|" + (convert(ReceivedDate) || '') + "|" + (RScaleTicketNum || '') + "|" + (valuecheckr || '') + "|" + (RWeightIncludingVehicle || '') + "|" + (RWeightOfVehicle || '') + "|" + (RLbsOfPeanuts || '') + "|" + (sWeightInd || '') + "|" + (txtOtherWt || '') + "|" + (lblwetvarience || ''),
            lblFormDirty: "TRUE"
          }
          setData(thisObj, 'ScaleTicketData', obj)
          document.getElementById("WarehouseReceipts_DelvSettleScaleTicketPopUp").childNodes[0].click();
        }
        // Edit Condition for the Adding Scale Tickets
        else {
          let obj = {
            load_num: loadNum || '',
            vehicle_num: vehicleNum || '',
            truck_line: truckLine || '',
            driver_name: driver || '',
            shp_date: convert(shipdate) || '',
            shp_scale_tick: SScaleTicketNum || '',
            shp_tare_wt: SWeightIncludingVehicle || '',
            shp_vehicle_wt: SWeightOfVehicle || '',
            shp_peanut_wt: lblSLbsOfPeanuts || '',
            rec_date: convert(ReceivedDate) || '',
            rec_scale_tick: RScaleTicketNum || '',
            rec_tare_wt: RWeightIncludingVehicle || '',
            rec_vehicle_wt: RWeightOfVehicle || '',
            shp_split_scale: valuecheck || '',
            rec_split_scale: valuecheckr || '',
            inventory_with_ind: sWeightInd || '',
            variance: lblwetvarience || '',
            rec_peanut_wt: RLbsOfPeanuts || '',
            sWeightInd: sWeightInd || '',
            inventory_other_wt: txtOtherWt || '',
            totalConcateBarValue: (loadNum || '') + "|" + (vehicleNum || '') + '|' + (truckLine || '') + "|" + (driver || '') + '|' + (convert(shipdate) || '') + "|" + (SScaleTicketNum || '') + "|" + (valuecheck || '') + "|" + (SWeightIncludingVehicle || '') + "|" + (SWeightOfVehicle || '') + "|" + (lblSLbsOfPeanuts || '') + "|" + (convert(ReceivedDate) || '') + "|" + (RScaleTicketNum || '') + "|" + (valuecheckr || '') + "|" + (RWeightIncludingVehicle || '') + "|" + (RWeightOfVehicle || '') + "|" + (RLbsOfPeanuts || '') + "|" + (sWeightInd || '') + "|" + (txtOtherWt || '') + "|" + (lblwetvarience || ''),
            lblFormDirty: "TRUE"
          }
          setData(thisObj, 'ScaleTicketData', obj)
          document.getElementById("WarehouseReceipts_DelvSettleScaleTicketPopUp").childNodes[0].click();
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnOkClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick

  const onradioUpdateInventoryChange = () => {//Radio Button Change Events
    try{
    let radiobuttonvalue = getValue(thisObj, "radioUpdateInventory")
    if (radiobuttonvalue != undefined && radiobuttonvalue != '') {
      if (radiobuttonvalue == "3") {
        enable(thisObj, "txtOtherWt")
        setValue(thisObj, "txtOtherWt", '')
      }
      else {
        disable(thisObj, "txtOtherWt");
        setValue(thisObj, "txtOtherWt", '')
      }
    }
  }
  catch (error) {
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        `Error occurred during onradioUpdateInventoryChange: ${error?.toString()}`
      );
    }
    return false;
  }
  }
  thisObj.onradioUpdateInventoryChange = onradioUpdateInventoryChange
  
  // Logic For Validate inputs and ASCII Events
  const ontxtLoadNumChange = () => {
    let value = getValue(thisObj, 'txtLoadNum');
    setValue(thisObj, 'txtLoadNum', validatetxtLoadNum(value));
  }
  thisObj.ontxtLoadNumChange = ontxtLoadNumChange

  const ontxtOtherWtChange = () => {
    let value = getValue(thisObj, 'txtOtherWt');
    setValue(thisObj, 'txtOtherWt', validatetxtOtherWt(value));
  }
  thisObj.ontxtOtherWtChange = ontxtOtherWtChange

  const ontxtWtIncldVehicleRWChange = async () => {
    let value = getValue(thisObj, 'txtWtIncldVehicleRW');
    setValue(thisObj, 'txtWtIncldVehicleRW', validatetxtWtIncldVehicleRW(value));
    await CalculateReceivedTotal();
  }
  thisObj.ontxtWtIncldVehicleRWChange = ontxtWtIncldVehicleRWChange

  const ontxtWtOfVehicleRWChange = async () => {
    let value = getValue(thisObj, 'txtWtOfVehicleRW');
    setValue(thisObj, 'txtWtOfVehicleRW', validatetxtWtOfVehicleRW(value));
    await CalculateReceivedTotal();
  }
  thisObj.ontxtWtOfVehicleRWChange = ontxtWtOfVehicleRWChange


  // Logic for change Ship Weight Including Vehicle
  const ontxtWtIncldVehicleSWChange = async () => {//shipped include vehicle change event
    let value = getValue(thisObj, 'txtWtIncldVehicleSW');
    setValue(thisObj, 'txtWtIncldVehicleSW', validatetxtWtIncldVehicleSW(value));
    await CalculateShippedTotal();
  }
  thisObj.ontxtWtIncldVehicleSWChange = ontxtWtIncldVehicleSWChange

  const ontxtWtOfVehicleSWChange = async () => {//shipped weight of vehicle change event
    let value = getValue(thisObj, 'txtWtOfVehicleSW');
    setValue(thisObj, 'txtWtOfVehicleSW', validatetxtWtOfVehicleSW(value));
    await CalculateShippedTotal();
  }
  thisObj.ontxtWtOfVehicleSWChange = ontxtWtOfVehicleSWChange

  const ontxtVehicleNumChange = () => {//shipped vehicle Number change event
    let value = getValue(thisObj, 'txtVehicleNum');
    setValue(thisObj, 'txtVehicleNum', validatetxtVehicleNum(value));
  }
  thisObj.ontxtVehicleNumChange = ontxtVehicleNumChange

  const ontxtWtIncldVehicleSWBlur = () => {//blur for shipped vehicle include event
    let includevehicle = getValue(thisObj,"txtWtIncldVehicleSW")
    if(includevehicle != undefined && includevehicle != "" && includevehicle != null){
      let  newremove = parseInt(includevehicle,10)?.toString()
      setValue(thisObj,"txtWtIncldVehicleSW",newremove)
    }
  }
  thisObj.ontxtWtIncldVehicleSWBlur = ontxtWtIncldVehicleSWBlur

  const ontxtWtOfVehicleSWBlur = () => {//blur event for wt of Vehicle shipped event
    let vehicleWt = getValue(thisObj,"txtWtOfVehicleSW")
    if(vehicleWt != undefined && vehicleWt != "" && vehicleWt != null){
      let  newremove = parseInt(vehicleWt,10)?.toString()
      setValue(thisObj,"txtWtOfVehicleSW",newremove)
    }
  }
  thisObj.ontxtWtOfVehicleSWBlur = ontxtWtOfVehicleSWBlur

  const ontxtWtIncldVehicleRWBlur = () => { //recieve include vehicle blur event
    let includevehicler = getValue(thisObj,"txtWtIncldVehicleRW")
    if(includevehicler != undefined && includevehicler != "" && includevehicler != null){
      let  newremove = parseInt(includevehicler,10)?.toString()
      setValue(thisObj,"txtWtIncldVehicleRW",newremove)
    }
  }
  thisObj.ontxtWtIncldVehicleRWBlur = ontxtWtIncldVehicleRWBlur

  const ontxtWtOfVehicleRWBlur = () => { //recieve vehicle blur event
    let vehicleWtr = getValue(thisObj,"txtWtOfVehicleRW")
    if(vehicleWtr != undefined && vehicleWtr != "" && vehicleWtr != null){
      let  newremove = parseInt(vehicleWtr,10)?.toString()
      setValue(thisObj,"txtWtOfVehicleRW",newremove)
    }
  }
  thisObj.ontxtWtOfVehicleRWBlur = ontxtWtOfVehicleRWBlur

  // Logic for blur event for Received Lbs
  const ontxtLbsOfPeanutsRWBlur = (txtLbsOfPeanutsSW, txtLbsOfPeanutsRW) => {
    let RecievePeanutLbs = "";
    let LbsOfPeanutShipped = txtLbsOfPeanutsSW;
    let LbsOfPeanutReceived = txtLbsOfPeanutsRW;
    if (LbsOfPeanutShipped == undefined || LbsOfPeanutShipped == "" || LbsOfPeanutShipped == null) {
      LbsOfPeanutShipped = 0;
    }else{
      LbsOfPeanutShipped = Number(txtLbsOfPeanutsSW)
    }
    if (LbsOfPeanutReceived == undefined || LbsOfPeanutReceived == "" || LbsOfPeanutReceived == null) {
      LbsOfPeanutReceived = 0;
    }else{
      LbsOfPeanutReceived = Number(txtLbsOfPeanutsRW);
    }
    if (LbsOfPeanutShipped > 0 && LbsOfPeanutReceived > 0) {
      RecievePeanutLbs = Number(LbsOfPeanutShipped) - Number(LbsOfPeanutReceived);
      setValue(thisObj, "txtWtVariance", RecievePeanutLbs.toString());
    }
  }
  thisObj.ontxtLbsOfPeanutsRWBlur = ontxtLbsOfPeanutsRWBlur

  const ontxtLbsOfPeanutsSWBlur = (txtLbsOfPeanutsSW, txtLbsOfPeanutsRW) => {//added blur method for both
    let RecievePeanutLbs = "";
    let LbsOfPeanutShipped = txtLbsOfPeanutsSW;
    let LbsOfPeanutReceived = txtLbsOfPeanutsRW;
    if (LbsOfPeanutShipped == undefined || LbsOfPeanutShipped == "" || LbsOfPeanutShipped == null) {
      LbsOfPeanutShipped = 0;
    }else{
      LbsOfPeanutShipped = Number(txtLbsOfPeanutsSW)
    }
    if (LbsOfPeanutReceived == undefined || LbsOfPeanutReceived == "" || LbsOfPeanutReceived == null) {
      LbsOfPeanutReceived = 0;
    }else{
      LbsOfPeanutReceived = Number(txtLbsOfPeanutsRW);
    }
    if (LbsOfPeanutShipped > 0 && LbsOfPeanutReceived > 0) {
      RecievePeanutLbs = Number(LbsOfPeanutShipped) - Number(LbsOfPeanutReceived);
      setValue(thisObj, "txtWtVariance", RecievePeanutLbs.toString());
    }
  }
  thisObj.ontxtLbsOfPeanutsSWBlur = ontxtLbsOfPeanutsSWBlur

  //Logic for input value's ASCII function  
  const validatetxtLoadNum = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) && data[i].charCodeAt(0) != 8) {
        res += data[i]
      }
    }
    return res;
  }

  const validatetxtOtherWt = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) && data[i].charCodeAt(0) != 8) {
        res += data[i]
      }
    }
    return res;
  }

  const validatetxtWtIncldVehicleRW = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) && data[i].charCodeAt(0) != 8) {
        res += data[i]
      }
    }
    return res;
  }

  const validatetxtWtOfVehicleRW = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) && data[i].charCodeAt(0) != 8) {
        res += data[i]
      }
    }
    return res;
  }

  const validatetxtWtIncldVehicleSW = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) && data[i].charCodeAt(0) != 8) {
        res += data[i]
      }
    }
    return res;
  }

  const validatetxtWtOfVehicleSW = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) && data[i].charCodeAt(0) != 8) {
        res += data[i]
      }
    }
    return res;
  }

  const validatetxtVehicleNum = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 38) && (data[i].charCodeAt(0) != 39)) {
        res += data[i]
      }
    }
    return res;
  }

  // Logic for calculate Shipped Weight function
  const CalculateShippedTotal = async () => {
    try {
      let LbsOfPeanutsSW = "";
      let txtSWeightInclidingVelicle = getValue(thisObj, 'txtWtIncldVehicleSW');
      let txtSWeightOfVehicle = getValue(thisObj, 'txtWtOfVehicleSW');

      if(txtSWeightInclidingVelicle == undefined){
        txtSWeightInclidingVelicle = caption?.txtSWeightIncludingVehicle
      }
      if(txtSWeightOfVehicle == undefined){
        txtSWeightOfVehicle = caption?.txtSWeightOfVehicle
      }

      if ((!isNaN(txtSWeightInclidingVelicle) || txtSWeightInclidingVelicle == "" || txtSWeightInclidingVelicle == undefined) && (!isNaN(txtSWeightOfVehicle) || txtSWeightOfVehicle == "" || txtSWeightOfVehicle == undefined)) {
        if ((txtSWeightInclidingVelicle == "" || txtSWeightInclidingVelicle == undefined) && (txtSWeightOfVehicle == "" || txtSWeightOfVehicle == undefined)) {
          setValue(thisObj, "txtLbsOfPeanutsSW", LbsOfPeanutsSW);
        }
        else {
          if ((txtSWeightInclidingVelicle !== "" && txtSWeightInclidingVelicle !== undefined) && (txtSWeightOfVehicle !== "" && txtSWeightOfVehicle !== undefined)) {
            LbsOfPeanutsSW = (Number(txtSWeightInclidingVelicle) - Number(txtSWeightOfVehicle));
            setValue(thisObj, "txtLbsOfPeanutsSW", LbsOfPeanutsSW.toString());
          }
          else {
            if (txtSWeightInclidingVelicle == "" || txtSWeightInclidingVelicle == undefined) {
              LbsOfPeanutsSW = (0 - Number(txtSWeightOfVehicle));
              setValue(thisObj, "txtLbsOfPeanutsSW", LbsOfPeanutsSW.toString());
            }
            else {
              LbsOfPeanutsSW = (Number(txtSWeightInclidingVelicle) - 0);
              setValue(thisObj, "txtLbsOfPeanutsSW", LbsOfPeanutsSW.toString());
            }
          }
        }
      }
      else {
        setValue(thisObj, "txtLbsOfPeanutsSW", LbsOfPeanutsSW);
      }

      // ontxtLbsOfPeanutsRWBlur(LbsOfPeanutsSW, getValue(thisObj, "txtLbsOfPeanutsRW"));
      ontxtLbsOfPeanutsSWBlur(LbsOfPeanutsSW, getValue(thisObj, "txtLbsOfPeanutsRW"))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during CalculateShippedTotal: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  // Logic for calculate Received Weight function
  const CalculateReceivedTotal = async () => {
    try {
      let LbsOfPeanutsRW = "";
      let txtRWeightIncludingVehicle = getValue(thisObj, 'txtWtIncldVehicleRW');
      let txtRWeightOfVehicle = getValue(thisObj, 'txtWtOfVehicleRW');

      if(txtRWeightIncludingVehicle == undefined){
        txtRWeightIncludingVehicle = caption?.txtRWeightIncludingVehicle
      }

      if(txtRWeightOfVehicle == undefined){
        txtRWeightOfVehicle = caption?.txtRWeightOfVehicle
      }

      if ((!isNaN(txtRWeightIncludingVehicle) || (txtRWeightIncludingVehicle == "" || txtRWeightIncludingVehicle == undefined)) && ((!isNaN(txtRWeightOfVehicle) || (txtRWeightOfVehicle == undefined) || (txtRWeightOfVehicle == "")))) {
        if ((txtRWeightIncludingVehicle == "" || txtRWeightIncludingVehicle == undefined ) && (txtRWeightOfVehicle == "" || txtRWeightOfVehicle == undefined)) {
          setValue(thisObj, "txtLbsOfPeanutsRW", LbsOfPeanutsRW);
        }
        else {
          if (txtRWeightIncludingVehicle !== "" && txtRWeightOfVehicle !== "") {
            if (txtRWeightIncludingVehicle == "" || txtRWeightIncludingVehicle == undefined) {
              txtRWeightIncludingVehicle = 0;
            }
            if (txtRWeightOfVehicle == "" || txtRWeightOfVehicle == undefined) {
              txtRWeightOfVehicle = 0;
            }
            LbsOfPeanutsRW = Number(txtRWeightIncludingVehicle) - Number(txtRWeightOfVehicle);
            setValue(thisObj, "txtLbsOfPeanutsRW", LbsOfPeanutsRW.toString());
          }
          else {
            if (txtRWeightIncludingVehicle == "") {
              if (txtRWeightIncludingVehicle == "" || txtRWeightIncludingVehicle == undefined) {
                txtRWeightIncludingVehicle = 0;
              }
              if (txtRWeightOfVehicle == "" || txtRWeightOfVehicle == undefined) {
                txtRWeightOfVehicle = 0;
              }
              LbsOfPeanutsRW = (0 - Number(txtRWeightOfVehicle));
              setValue(thisObj, "txtLbsOfPeanutsRW", LbsOfPeanutsRW.toString());
            }
            else {
              if (txtRWeightIncludingVehicle == "" || txtRWeightIncludingVehicle == undefined) {
                txtRWeightIncludingVehicle = 0;
              }
              if (txtRWeightOfVehicle == "" || txtRWeightOfVehicle == undefined) {
                txtRWeightOfVehicle = 0;
              }
              LbsOfPeanutsRW = (Number(txtRWeightIncludingVehicle) - 0);
              setValue(thisObj, "txtLbsOfPeanutsRW", LbsOfPeanutsRW.toString());
            }
          }
        }
      }
      else {
        setValue(thisObj, "txtLbsOfPeanutsRW", LbsOfPeanutsRW);
      }

      ontxtLbsOfPeanutsRWBlur(getValue(thisObj, "txtLbsOfPeanutsSW"), LbsOfPeanutsRW);
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during CalculateShippedTotal: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  // Implement functionality for Cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("WarehouseReceipts_DelvSettleScaleTicketPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvSettleScaleTicket*/}

              {/* END_USER_CODE-USER_BEFORE_DelvSettleScaleTicket*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvSettleScaleTicket*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvSettleScaleTicket*/}

              <GroupBoxWidget
                conf={state.grpbxDelvSettleScaleTicket}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxLoadInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLoadInfo*/}

                <GroupBoxWidget conf={state.grpbxLoadInfo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblLoadInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLoadInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLoadInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLoadInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblLoadInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoadNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoadNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoadNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoadNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoadNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVehicleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVehicleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVehicleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVehicleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVehicleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTruckLine*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTruckLine*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTruckLine}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTruckLine*/}

                  {/* END_USER_CODE-USER_AFTER_txtTruckLine*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDriver*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDriver*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDriver}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDriver*/}

                  {/* END_USER_CODE-USER_AFTER_txtDriver*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLoadInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLoadInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxShippedReceivedWt*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxShippedReceivedWt*/}

                <GroupBoxWidget
                  conf={state.grpbxShippedReceivedWt}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxShippedWt*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxShippedWt*/}

                  <GroupBoxWidget
                    conf={state.grpbxShippedWt}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblShippedWeight*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShippedWeight*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblShippedWeight}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShippedWeight*/}

                    {/* END_USER_CODE-USER_AFTER_lblShippedWeight*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShippedDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShippedDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShippedDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShippedDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtShippedDate*/}
                    {/* START_USER_CODE-USER_BEFORE_lblDateFormatSW*/}

                    {/* END_USER_CODE-USER_BEFORE_lblDateFormatSW*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblDateFormatSW}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblDateFormatSW*/}

                    {/* END_USER_CODE-USER_AFTER_lblDateFormatSW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtScaleTicketNumSW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtScaleTicketNumSW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtScaleTicketNumSW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtScaleTicketNumSW*/}

                    {/* END_USER_CODE-USER_AFTER_txtScaleTicketNumSW*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxSplitWtSW*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxSplitWtSW*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxSplitWtSW}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxSplitWtSW*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxSplitWtSW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWtIncldVehicleSW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWtIncldVehicleSW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWtIncldVehicleSW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWtIncldVehicleSW*/}

                    {/* END_USER_CODE-USER_AFTER_txtWtIncldVehicleSW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicleSW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicleSW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWtOfVehicleSW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWtOfVehicleSW*/}

                    {/* END_USER_CODE-USER_AFTER_txtWtOfVehicleSW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLbsOfPeanutsSW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLbsOfPeanutsSW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLbsOfPeanutsSW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLbsOfPeanutsSW*/}

                    {/* END_USER_CODE-USER_AFTER_txtLbsOfPeanutsSW*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxShippedWt*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxShippedWt*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxReceivedWt*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxReceivedWt*/}

                  <GroupBoxWidget
                    conf={state.grpbxReceivedWt}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblReceivedWt*/}

                    {/* END_USER_CODE-USER_BEFORE_lblReceivedWt*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblReceivedWt}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblReceivedWt*/}

                    {/* END_USER_CODE-USER_AFTER_lblReceivedWt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtReceivedDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtReceivedDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtReceivedDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtReceivedDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtReceivedDate*/}
                    {/* START_USER_CODE-USER_BEFORE_lblDateFormatRW*/}

                    {/* END_USER_CODE-USER_BEFORE_lblDateFormatRW*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblDateFormatRW}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblDateFormatRW*/}

                    {/* END_USER_CODE-USER_AFTER_lblDateFormatRW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtScaleTicketNumRW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtScaleTicketNumRW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtScaleTicketNumRW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtScaleTicketNumRW*/}

                    {/* END_USER_CODE-USER_AFTER_txtScaleTicketNumRW*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxSplitWtRW*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxSplitWtRW*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxSplitWtRW}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxSplitWtRW*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxSplitWtRW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWtIncldVehicleRW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWtIncldVehicleRW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWtIncldVehicleRW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWtIncldVehicleRW*/}

                    {/* END_USER_CODE-USER_AFTER_txtWtIncldVehicleRW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicleRW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicleRW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWtOfVehicleRW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWtOfVehicleRW*/}

                    {/* END_USER_CODE-USER_AFTER_txtWtOfVehicleRW*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLbsOfPeanutsRW*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLbsOfPeanutsRW*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLbsOfPeanutsRW}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLbsOfPeanutsRW*/}

                    {/* END_USER_CODE-USER_AFTER_txtLbsOfPeanutsRW*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxReceivedWt*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxReceivedWt*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxShippedReceivedWt*/}

                {/* END_USER_CODE-USER_AFTER_grpbxShippedReceivedWt*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxUpdateInventory*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxUpdateInventory*/}

                <GroupBoxWidget
                  conf={state.grpbxUpdateInventory}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_radioUpdateInventory*/}

                  {/* END_USER_CODE-USER_BEFORE_radioUpdateInventory*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioUpdateInventory}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioUpdateInventory*/}

                  {/* END_USER_CODE-USER_AFTER_radioUpdateInventory*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOtherWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOtherWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOtherWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtOtherWt*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxUpdateInventory*/}

                {/* END_USER_CODE-USER_AFTER_grpbxUpdateInventory*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWtVariance*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWtVariance*/}

                <GroupBoxWidget conf={state.grpbxWtVariance} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtWtVariance*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWtVariance*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWtVariance}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWtVariance*/}

                  {/* END_USER_CODE-USER_AFTER_txtWtVariance*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLbs*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLbs}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLbs*/}

                  {/* END_USER_CODE-USER_AFTER_lblLbs*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWtVariance*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWtVariance*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvSettleScaleTicket*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvSettleScaleTicket*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvSettleScaleTicket*/}

              {/* END_USER_CODE-USER_AFTER_DelvSettleScaleTicket*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvSettleScaleTicket);
