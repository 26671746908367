/* eslint-disable*/
import React from "react";
import Settlements_TradeAgreeSearch from "./TradeAgreeSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeAgreeSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeAgreeSearch />);
    });
  });
  afterEach(cleanup);
  test("is TradeAgreeSearch Loads Successfully", () => {
    expect(screen.getByText("TradeAgreeSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeAgreeSearch", () => {
    // START_USER_CODE-USER_TradeAgreeSearch_Custom_Test_Case
    // END_USER_CODE-USER_TradeAgreeSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeAgreeSearch />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("Settlements:TradeAgreeSearch_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateAgreement(Button Widget) Test Cases", async () => {
    const btnCreateAgreement = screen.getByTestId("btnCreateAgreement");
    expect(btnCreateAgreement).toBeInTheDocument;
    expect(btnCreateAgreement.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnCreateAgreement")
    );
  });
  test("Custom Test Cases for btnCreateAgreement", () => {
    // START_USER_CODE-USER_btnCreateAgreement_TEST
    // END_USER_CODE-USER_btnCreateAgreement_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridAgrmntSrch(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrchbtn = gridAgrmntSrch.nextElementSibling.firstElementChild;
    gridAgrmntSrch = gridAgrmntSrch.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch.tagName).toBe("DIV");
    expect(gridAgrmntSrch.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAgrmntSrch", () => {
    // START_USER_CODE-USER_gridAgrmntSrch_TEST
    // END_USER_CODE-USER_gridAgrmntSrch_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCreateOpenAgrmnt(GroupBox Widget) Test Cases", async () => {
    const grpbxCreateOpenAgrmnt = screen.getByTestId("grpbxCreateOpenAgrmnt");
    expect(grpbxCreateOpenAgrmnt.tagName).toBe("BUTTON");
    expect(grpbxCreateOpenAgrmnt.type).toBe("button");
    expect(grpbxCreateOpenAgrmnt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCreateOpenAgrmnt", () => {
    // START_USER_CODE-USER_grpbxCreateOpenAgrmnt_TEST
    // END_USER_CODE-USER_grpbxCreateOpenAgrmnt_TEST
  });
  test("grpbxSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxSearch = screen.getByTestId("grpbxSearch");
    expect(grpbxSearch.tagName).toBe("BUTTON");
    expect(grpbxSearch.type).toBe("button");
    expect(grpbxSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearch", () => {
    // START_USER_CODE-USER_grpbxSearch_TEST
    // END_USER_CODE-USER_grpbxSearch_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("txtAgreedLbs(Textbox Widget) Test Cases", async () => {
    const txtAgreedLbs = screen.getByTestId("txtAgreedLbs");
    expect(txtAgreedLbs.tagName).toBe("INPUT");
    expect(txtAgreedLbs.type).toBe("text");
    expect(txtAgreedLbs.classList).toContain("textboxWidgetClass");
    expect(txtAgreedLbs.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_txtAgreedLbs")
    );
    await act(async () => {
      userEvent.type(txtAgreedLbs, "1");
    });
  });
  test("Custom Test Cases for txtAgreedLbs", () => {
    // START_USER_CODE-USER_txtAgreedLbs_TEST
    // END_USER_CODE-USER_txtAgreedLbs_TEST
  });
  test("txtAgreement(Textbox Widget) Test Cases", async () => {
    const txtAgreement = screen.getByTestId("txtAgreement");
    expect(txtAgreement.tagName).toBe("INPUT");
    expect(txtAgreement.type).toBe("text");
    expect(txtAgreement.classList).toContain("textboxWidgetClass");
    expect(txtAgreement.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_txtAgreement")
    );
    await act(async () => {
      userEvent.type(txtAgreement, "1");
    });
  });
  test("Custom Test Cases for txtAgreement", () => {
    // START_USER_CODE-USER_txtAgreement_TEST
    // END_USER_CODE-USER_txtAgreement_TEST
  });
  test("txtAppliedLbs(Textbox Widget) Test Cases", async () => {
    const txtAppliedLbs = screen.getByTestId("txtAppliedLbs");
    expect(txtAppliedLbs.tagName).toBe("INPUT");
    expect(txtAppliedLbs.type).toBe("text");
    expect(txtAppliedLbs.classList).toContain("textboxWidgetClass");
    expect(txtAppliedLbs.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_txtAppliedLbs")
    );
    await act(async () => {
      userEvent.type(txtAppliedLbs, "1");
    });
  });
  test("Custom Test Cases for txtAppliedLbs", () => {
    // START_USER_CODE-USER_txtAppliedLbs_TEST
    // END_USER_CODE-USER_txtAppliedLbs_TEST
  });
  test("gridAgrmntSrch_txtcolAgree(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolAgree = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolAgreebtn =
      gridAgrmntSrch_txtcolAgree.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolAgree =
      gridAgrmntSrch_txtcolAgree.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolAgree.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolAgree.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgree", () => {
    // START_USER_CODE-USER_txtcolAgree_TEST
    // END_USER_CODE-USER_txtcolAgree_TEST
  });
  test("gridAgrmntSrch_txtcolAgreedLbs(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolAgreedLbs = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolAgreedLbsbtn =
      gridAgrmntSrch_txtcolAgreedLbs.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolAgreedLbs =
      gridAgrmntSrch_txtcolAgreedLbs.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolAgreedLbs.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolAgreedLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreedLbs", () => {
    // START_USER_CODE-USER_txtcolAgreedLbs_TEST
    // END_USER_CODE-USER_txtcolAgreedLbs_TEST
  });
  test("gridAgrmntSrch_txtcolAgreeDt(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolAgreeDt = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolAgreeDtbtn =
      gridAgrmntSrch_txtcolAgreeDt.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolAgreeDt =
      gridAgrmntSrch_txtcolAgreeDt.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolAgreeDt.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolAgreeDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeDt", () => {
    // START_USER_CODE-USER_txtcolAgreeDt_TEST
    // END_USER_CODE-USER_txtcolAgreeDt_TEST
  });
  test("gridAgrmntSrch_txtcolAppldLbs(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolAppldLbs = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolAppldLbsbtn =
      gridAgrmntSrch_txtcolAppldLbs.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolAppldLbs =
      gridAgrmntSrch_txtcolAppldLbs.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolAppldLbs.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolAppldLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppldLbs", () => {
    // START_USER_CODE-USER_txtcolAppldLbs_TEST
    // END_USER_CODE-USER_txtcolAppldLbs_TEST
  });
  test("gridAgrmntSrch_txtcolGrdMthd(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolGrdMthd = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolGrdMthdbtn =
      gridAgrmntSrch_txtcolGrdMthd.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolGrdMthd =
      gridAgrmntSrch_txtcolGrdMthd.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolGrdMthd.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolGrdMthd.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrdMthd", () => {
    // START_USER_CODE-USER_txtcolGrdMthd_TEST
    // END_USER_CODE-USER_txtcolGrdMthd_TEST
  });
  test("gridAgrmntSrch_txtcolLoc(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolLoc = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolLocbtn =
      gridAgrmntSrch_txtcolLoc.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolLoc =
      gridAgrmntSrch_txtcolLoc.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolLoc.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoc", () => {
    // START_USER_CODE-USER_txtcolLoc_TEST
    // END_USER_CODE-USER_txtcolLoc_TEST
  });
  test("gridAgrmntSrch_txtcolOpenLbs(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolOpenLbs = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolOpenLbsbtn =
      gridAgrmntSrch_txtcolOpenLbs.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolOpenLbs =
      gridAgrmntSrch_txtcolOpenLbs.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolOpenLbs.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolOpenLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenLbs", () => {
    // START_USER_CODE-USER_txtcolOpenLbs_TEST
    // END_USER_CODE-USER_txtcolOpenLbs_TEST
  });
  test("gridAgrmntSrch_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolPrice = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolPricebtn =
      gridAgrmntSrch_txtcolPrice.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolPrice =
      gridAgrmntSrch_txtcolPrice.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolPrice.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolPrice.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridAgrmntSrch_txtcolPriceMethduic0(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolPriceMethduic0 = screen.getByTestId(
      "gridAgrmntSrch"
    );
    let gridAgrmntSrch_txtcolPriceMethduic0btn =
      gridAgrmntSrch_txtcolPriceMethduic0.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolPriceMethduic0 =
      gridAgrmntSrch_txtcolPriceMethduic0.parentElement.parentElement
        .parentElement;
    expect(gridAgrmntSrch_txtcolPriceMethduic0.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolPriceMethduic0.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceMethduic0", () => {
    // START_USER_CODE-USER_txtcolPriceMethduic0_TEST
    // END_USER_CODE-USER_txtcolPriceMethduic0_TEST
  });
  test("gridAgrmntSrch_txtcolPys(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolPys = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolPysbtn =
      gridAgrmntSrch_txtcolPys.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolPys =
      gridAgrmntSrch_txtcolPys.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolPys.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolPys.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPys", () => {
    // START_USER_CODE-USER_txtcolPys_TEST
    // END_USER_CODE-USER_txtcolPys_TEST
  });
  test("gridAgrmntSrch_txtcolSeedGen(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolSeedGen = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolSeedGenbtn =
      gridAgrmntSrch_txtcolSeedGen.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolSeedGen =
      gridAgrmntSrch_txtcolSeedGen.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolSeedGen.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolSeedGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGen", () => {
    // START_USER_CODE-USER_txtcolSeedGen_TEST
    // END_USER_CODE-USER_txtcolSeedGen_TEST
  });
  test("gridAgrmntSrch_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolSeg = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolSegbtn =
      gridAgrmntSrch_txtcolSeg.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolSeg =
      gridAgrmntSrch_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolSeg.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridAgrmntSrch_txtcolTyp(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolTyp = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolTypbtn =
      gridAgrmntSrch_txtcolTyp.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolTyp =
      gridAgrmntSrch_txtcolTyp.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolTyp.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTyp", () => {
    // START_USER_CODE-USER_txtcolTyp_TEST
    // END_USER_CODE-USER_txtcolTyp_TEST
  });
  test("gridAgrmntSrch_txtcolVndruic0(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolVndruic0 = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolVndruic0btn =
      gridAgrmntSrch_txtcolVndruic0.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolVndruic0 =
      gridAgrmntSrch_txtcolVndruic0.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolVndruic0.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolVndruic0.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVndruic0", () => {
    // START_USER_CODE-USER_txtcolVndruic0_TEST
    // END_USER_CODE-USER_txtcolVndruic0_TEST
  });
  test("gridAgrmntSrch_txtcolVrty(Grid Widget) Test Cases", async () => {
    let gridAgrmntSrch_txtcolVrty = screen.getByTestId("gridAgrmntSrch");
    let gridAgrmntSrch_txtcolVrtybtn =
      gridAgrmntSrch_txtcolVrty.nextElementSibling.firstElementChild;
    gridAgrmntSrch_txtcolVrty =
      gridAgrmntSrch_txtcolVrty.parentElement.parentElement.parentElement;
    expect(gridAgrmntSrch_txtcolVrty.tagName).toBe("DIV");
    expect(gridAgrmntSrch_txtcolVrty.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeAgreeSearch_gridAgrmntSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVrty", () => {
    // START_USER_CODE-USER_txtcolVrty_TEST
    // END_USER_CODE-USER_txtcolVrty_TEST
  });
  test("txtOpenLbs(Textbox Widget) Test Cases", async () => {
    const txtOpenLbs = screen.getByTestId("txtOpenLbs");
    expect(txtOpenLbs.tagName).toBe("INPUT");
    expect(txtOpenLbs.type).toBe("text");
    expect(txtOpenLbs.classList).toContain("textboxWidgetClass");
    expect(txtOpenLbs.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_txtOpenLbs")
    );
    await act(async () => {
      userEvent.type(txtOpenLbs, "1");
    });
  });
  test("Custom Test Cases for txtOpenLbs", () => {
    // START_USER_CODE-USER_txtOpenLbs_TEST
    // END_USER_CODE-USER_txtOpenLbs_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
