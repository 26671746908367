/* eslint-disable*/
import React from "react";
import StockTransfer_TransferErrorList from "./TransferErrorList";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TransferErrorList Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_TransferErrorList />);
    });
  });
  afterEach(cleanup);
  test("is TransferErrorList Loads Successfully", () => {
    expect(screen.getByText("TransferErrorList")).toBeInTheDocument;
  });
  test("Custom Test Cases for TransferErrorList", () => {
    // START_USER_CODE-USER_TransferErrorList_Custom_Test_Case
    // END_USER_CODE-USER_TransferErrorList_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_TransferErrorList />);
    });
  });
  afterEach(cleanup);
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("StockTransfer:TransferErrorList_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxError(GroupBox Widget) Test Cases", async () => {
    const grpbxError = screen.getByTestId("grpbxError");
    expect(grpbxError.tagName).toBe("BUTTON");
    expect(grpbxError.type).toBe("button");
    expect(grpbxError.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxError", () => {
    // START_USER_CODE-USER_grpbxError_TEST
    // END_USER_CODE-USER_grpbxError_TEST
  });
});
