/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FarmInquiry from "./FarmInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmInquiry />);
    });
  });
  afterEach(cleanup);
  test("is FarmInquiry Loads Successfully", () => {
    expect(screen.getByText("FarmInquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmInquiry", () => {
    // START_USER_CODE-USER_FarmInquiry_Custom_Test_Case
    // END_USER_CODE-USER_FarmInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmInquiry />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCountyDataForeNUTSImport(Button Widget) Test Cases", async () => {
    const btnCountyDataForeNUTSImport = screen.getByTestId(
      "btnCountyDataForeNUTSImport"
    );
    expect(btnCountyDataForeNUTSImport).toBeInTheDocument;
    expect(btnCountyDataForeNUTSImport.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmInquiry_btnCountyDataForeNUTSImport"
      )
    );
  });
  test("Custom Test Cases for btnCountyDataForeNUTSImport", () => {
    // START_USER_CODE-USER_btnCountyDataForeNUTSImport_TEST
    // END_USER_CODE-USER_btnCountyDataForeNUTSImport_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnFarmDataForAccess(Button Widget) Test Cases", async () => {
    const btnFarmDataForAccess = screen.getByTestId(
      "btnFarmDataForAccess"
    );
    expect(btnFarmDataForAccess).toBeInTheDocument;
    expect(btnFarmDataForAccess.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmInquiry_btnFarmDataForAccess"
      )
    );
  });
  test("Custom Test Cases for btnFarmDataForAccess", () => {
    // START_USER_CODE-USER_btnFarmDataForAccess_TEST
    // END_USER_CODE-USER_btnFarmDataForAccess_TEST
  });
  test("btnFarmDataForeNUTSImport(Button Widget) Test Cases", async () => {
    const btnFarmDataForeNUTSImport = screen.getByTestId(
      "btnFarmDataForeNUTSImport"
    );
    expect(btnFarmDataForeNUTSImport).toBeInTheDocument;
    expect(btnFarmDataForeNUTSImport.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmInquiry_btnFarmDataForeNUTSImport"
      )
    );
  });
  test("Custom Test Cases for btnFarmDataForeNUTSImport", () => {
    // START_USER_CODE-USER_btnFarmDataForeNUTSImport_TEST
    // END_USER_CODE-USER_btnFarmDataForeNUTSImport_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridFarmList(Grid Widget) Test Cases", async () => {
    let gridFarmList = screen.getByTestId("gridFarmList");
    let gridFarmListbtn =
      gridFarmList.nextElementSibling.firstElementChild;
    gridFarmList =
      gridFarmList.parentElement.parentElement.parentElement;
    expect(gridFarmList.tagName).toBe("DIV");
    expect(gridFarmList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridFarmList", () => {
    // START_USER_CODE-USER_gridFarmList_TEST
    // END_USER_CODE-USER_gridFarmList_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxFarmDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmDetails = screen.getByTestId("grpbxFarmDetails");
    expect(grpbxFarmDetails.tagName).toBe("BUTTON");
    expect(grpbxFarmDetails.type).toBe("button");
    expect(grpbxFarmDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmDetails", () => {
    // START_USER_CODE-USER_grpbxFarmDetails_TEST
    // END_USER_CODE-USER_grpbxFarmDetails_TEST
  });
  test("grpbxFarmList(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmList = screen.getByTestId("grpbxFarmList");
    expect(grpbxFarmList.tagName).toBe("BUTTON");
    expect(grpbxFarmList.type).toBe("button");
    expect(grpbxFarmList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmList", () => {
    // START_USER_CODE-USER_grpbxFarmList_TEST
    // END_USER_CODE-USER_grpbxFarmList_TEST
  });
  test("lblFarmList(Label Widget) Test Cases", async () => {
    const lblFarmList = screen.getByTestId("lblFarmList");
    expect(lblFarmList.tagName).toBe("LABEL");
    expect(lblFarmList.classList).toContain("form-label");
    expect(lblFarmList.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_lblFarmList")
    );
  });
  test("Custom Test Cases for lblFarmList", () => {
    // START_USER_CODE-USER_lblFarmList_TEST
    // END_USER_CODE-USER_lblFarmList_TEST
  });
  test("lblXxx(Label Widget) Test Cases", async () => {
    const lblXxx = screen.getByTestId("lblXxx");
    expect(lblXxx.tagName).toBe("LABEL");
    expect(lblXxx.classList).toContain("form-label");
    expect(lblXxx.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_lblXxx")
    );
  });
  test("Custom Test Cases for lblXxx", () => {
    // START_USER_CODE-USER_lblXxx_TEST
    // END_USER_CODE-USER_lblXxx_TEST
  });
  test("gridFarmList_txt10(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt10 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt10btn =
      gridFarmList_txt10.nextElementSibling.firstElementChild;
    gridFarmList_txt10 =
      gridFarmList_txt10.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt10.tagName).toBe("DIV");
    expect(gridFarmList_txt10.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt10", () => {
    // START_USER_CODE-USER_txt10_TEST
    // END_USER_CODE-USER_txt10_TEST
  });
  test("gridFarmList_txt1(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt1 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt1btn =
      gridFarmList_txt1.nextElementSibling.firstElementChild;
    gridFarmList_txt1 =
      gridFarmList_txt1.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt1.tagName).toBe("DIV");
    expect(gridFarmList_txt1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt1", () => {
    // START_USER_CODE-USER_txt1_TEST
    // END_USER_CODE-USER_txt1_TEST
  });
  test("gridFarmList_txt2(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt2 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt2btn =
      gridFarmList_txt2.nextElementSibling.firstElementChild;
    gridFarmList_txt2 =
      gridFarmList_txt2.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt2.tagName).toBe("DIV");
    expect(gridFarmList_txt2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt2", () => {
    // START_USER_CODE-USER_txt2_TEST
    // END_USER_CODE-USER_txt2_TEST
  });
  test("gridFarmList_txt3(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt3 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt3btn =
      gridFarmList_txt3.nextElementSibling.firstElementChild;
    gridFarmList_txt3 =
      gridFarmList_txt3.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt3.tagName).toBe("DIV");
    expect(gridFarmList_txt3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt3", () => {
    // START_USER_CODE-USER_txt3_TEST
    // END_USER_CODE-USER_txt3_TEST
  });
  test("gridFarmList_txt4(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt4 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt4btn =
      gridFarmList_txt4.nextElementSibling.firstElementChild;
    gridFarmList_txt4 =
      gridFarmList_txt4.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt4.tagName).toBe("DIV");
    expect(gridFarmList_txt4.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt4", () => {
    // START_USER_CODE-USER_txt4_TEST
    // END_USER_CODE-USER_txt4_TEST
  });
  test("gridFarmList_txt5(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt5 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt5btn =
      gridFarmList_txt5.nextElementSibling.firstElementChild;
    gridFarmList_txt5 =
      gridFarmList_txt5.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt5.tagName).toBe("DIV");
    expect(gridFarmList_txt5.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt5", () => {
    // START_USER_CODE-USER_txt5_TEST
    // END_USER_CODE-USER_txt5_TEST
  });
  test("gridFarmList_txt6(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt6 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt6btn =
      gridFarmList_txt6.nextElementSibling.firstElementChild;
    gridFarmList_txt6 =
      gridFarmList_txt6.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt6.tagName).toBe("DIV");
    expect(gridFarmList_txt6.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt6", () => {
    // START_USER_CODE-USER_txt6_TEST
    // END_USER_CODE-USER_txt6_TEST
  });
  test("gridFarmList_txt7(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt7 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt7btn =
      gridFarmList_txt7.nextElementSibling.firstElementChild;
    gridFarmList_txt7 =
      gridFarmList_txt7.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt7.tagName).toBe("DIV");
    expect(gridFarmList_txt7.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt7", () => {
    // START_USER_CODE-USER_txt7_TEST
    // END_USER_CODE-USER_txt7_TEST
  });
  test("gridFarmList_txt8(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt8 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt8btn =
      gridFarmList_txt8.nextElementSibling.firstElementChild;
    gridFarmList_txt8 =
      gridFarmList_txt8.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt8.tagName).toBe("DIV");
    expect(gridFarmList_txt8.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt8", () => {
    // START_USER_CODE-USER_txt8_TEST
    // END_USER_CODE-USER_txt8_TEST
  });
  test("gridFarmList_txt9(Grid Widget) Test Cases", async () => {
    let gridFarmList_txt9 = screen.getByTestId("gridFarmList");
    let gridFarmList_txt9btn =
      gridFarmList_txt9.nextElementSibling.firstElementChild;
    gridFarmList_txt9 =
      gridFarmList_txt9.parentElement.parentElement.parentElement;
    expect(gridFarmList_txt9.tagName).toBe("DIV");
    expect(gridFarmList_txt9.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FarmInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt9", () => {
    // START_USER_CODE-USER_txt9_TEST
    // END_USER_CODE-USER_txt9_TEST
  });
  test("txtBuyingPointID(Textbox Widget) Test Cases", async () => {
    const txtBuyingPointID = screen.getByTestId("txtBuyingPointID");
    expect(txtBuyingPointID.tagName).toBe("INPUT");
    expect(txtBuyingPointID.type).toBe("text");
    expect(txtBuyingPointID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBuyingPointID, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPointID", () => {
    // START_USER_CODE-USER_txtBuyingPointID_TEST
    // END_USER_CODE-USER_txtBuyingPointID_TEST
  });
  test("txtCountyID(Textbox Widget) Test Cases", async () => {
    const txtCountyID = screen.getByTestId("txtCountyID");
    expect(txtCountyID.tagName).toBe("INPUT");
    expect(txtCountyID.type).toBe("text");
    expect(txtCountyID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCountyID, "1");
    });
  });
  test("Custom Test Cases for txtCountyID", () => {
    // START_USER_CODE-USER_txtCountyID_TEST
    // END_USER_CODE-USER_txtCountyID_TEST
  });
  test("txtFarmID(Textbox Widget) Test Cases", async () => {
    const txtFarmID = screen.getByTestId("txtFarmID");
    expect(txtFarmID.tagName).toBe("INPUT");
    expect(txtFarmID.type).toBe("text");
    expect(txtFarmID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFarmID, "1");
    });
  });
  test("Custom Test Cases for txtFarmID", () => {
    // START_USER_CODE-USER_txtFarmID_TEST
    // END_USER_CODE-USER_txtFarmID_TEST
  });
  test("txtNameSearch(Textbox Widget) Test Cases", async () => {
    const txtNameSearch = screen.getByTestId("txtNameSearch");
    expect(txtNameSearch.tagName).toBe("INPUT");
    expect(txtNameSearch.type).toBe("text");
    expect(txtNameSearch.classList).toContain("textboxWidgetClass");
    expect(txtNameSearch.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_txtNameSearch")
    );
    await act(async () => {
      userEvent.type(txtNameSearch, "1");
    });
  });
  test("Custom Test Cases for txtNameSearch", () => {
    // START_USER_CODE-USER_txtNameSearch_TEST
    // END_USER_CODE-USER_txtNameSearch_TEST
  });
  test("txtStateID(Textbox Widget) Test Cases", async () => {
    const txtStateID = screen.getByTestId("txtStateID");
    expect(txtStateID.tagName).toBe("INPUT");
    expect(txtStateID.type).toBe("text");
    expect(txtStateID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtStateID, "1");
    });
  });
  test("Custom Test Cases for txtStateID", () => {
    // START_USER_CODE-USER_txtStateID_TEST
    // END_USER_CODE-USER_txtStateID_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmInquiry_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
