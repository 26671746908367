import React from "react";
import AutocompleteWidget from "./AutocompleteWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

describe("AutocompleteWidget Test Cases :1", () => {
  beforeEach(async () => {
    act(() => {
      let AutocompleteWidget1 = {
        name: "autocompletewidget10",
        type: "AutocompleteWidget",
        i18Key: "AllWidgetScreenReact.autocompletewidget10",
        value: "",
        parent: "groupboxwidget0",
        ToolTip: "",
        Left: "",
        Help: "",
        RowSpanForTabPotrait: "1",
        Render: true,
        minLength: "",
        RowSpanForLargeDesktop: "1",
        Label: "Auto Complete Widget",
        VisibleForLargeDesktop: true,
        VisibleForTabLandscape: true,
        RowSpanForTabLandscape: "1",
        ColSpan: "1",
        ShortcutKey: "i",
        DefaultValue: "Chennai",
        Alignment: "Left",
        CharWidth: "70",
        ColSpanForLargeDesktop: "1",
        ColSpanForTabLandscape: "1",
        Visible: true,
        Height: "20",
        Width: "60",
        ReadOnly: false,
        ColSpanForTabPotrait: "1",
        LOVReverseCopyString: "",
        Enabled: true,
        RowSpanForMobile: "1",
        VisibleForTabPotrait: true,
        HasLabel: true,
        IsHidable: false,
        Top: "",
        ProcessingText: "",
        LOVForwardCopyString: "",
        WidgetDescription: "",
        ColSpanForMobile: "1",
        LOVScreenMode: "DEFAULT",
        RowSpan: "1",
        VisibleForMobile: true,
        dataProviderForAutocomplete: "inline",
        valueListForAutocomplete: "Chennai,Delhi,Mumbai,Calcutta",
        Mandatory: false,
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <AutocompleteWidget
          values={values}
          onBlur={onBlur}
          onChange={onChange}
          conf={AutocompleteWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("AutocompleteWidget Basic Test Cases", async () => {
    const AutocompleteWidget1 = screen.getByTestId("autocompletewidget10")
      .firstChild.firstChild.nextElementSibling.firstChild.firstChild;
    expect(AutocompleteWidget1.tagName).toBe("INPUT");
    fireEvent.change(AutocompleteWidget1, { target: { value: "Chennai" } });
    const z = screen.getByText("Chennai");
    userEvent.type(z, { button: 0 });
  });
});
describe("AutocompleteWidget Test Cases :2", () => {
  beforeEach(async () => {
    act(() => {
      let AutocompleteWidget2 = {
        name: "AutocompleteWidget2",
        type: "AutocompleteWidget",
        i18Key: "AllWidgetScreenReact.autocompletewidget10",
        value: "",
        parent: "groupboxwidget0",
        ToolTip: "",
        Left: "",
        Help: "",
        RowSpanForTabPotrait: "1",
        Render: true,
        minLength: "",
        RowSpanForLargeDesktop: "1",
        Label: "Auto Complete Widget",
        VisibleForLargeDesktop: true,
        VisibleForTabLandscape: true,
        RowSpanForTabLandscape: "1",
        ColSpan: "1",
        ShortcutKey: "i",
        DefaultValue: "Chennai",
        Alignment: "Left",
        CharWidth: "70",
        ColSpanForLargeDesktop: "1",
        ColSpanForTabLandscape: "1",
        Visible: true,
        Height: "20",
        Width: "60",
        ReadOnly: false,
        ColSpanForTabPotrait: "1",
        LOVReverseCopyString: "",
        Enabled: true,
        RowSpanForMobile: "1",
        VisibleForTabPotrait: true,
        HasLabel: true,
        IsHidable: false,
        Top: "",
        ProcessingText: "",
        LOVForwardCopyString: "",
        WidgetDescription: "",
        ColSpanForMobile: "1",
        LOVScreenMode: "DEFAULT",
        RowSpan: "1",
        VisibleForMobile: true,
        dataProviderForAutocomplete: "inline",
        valueListForAutocomplete: "",

        Mandatory: false,
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <AutocompleteWidget
          values={values}
          onBlur={onBlur}
          onChange={onChange}
          conf={AutocompleteWidget2}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("AutocompleteWidget Basic Test Cases", async () => {
    const AutocompleteWidget2 = screen.getByTestId("AutocompleteWidget2")
      .firstChild.firstChild.nextElementSibling.firstChild;
    expect(AutocompleteWidget2.tagName).toBe("DIV");
  });
});

describe("AutocompleteWidget Test Cases :3", () => {
  beforeEach(async () => {
    act(() => {
      let AutocompleteWidget3 = {
        name: "AutocompleteWidget3",
        type: "AutocompleteWidget",
        i18Key: "AllWidgetScreenReact.autocompletewidget10",
        value: "",
        parent: "groupboxwidget0",
        ToolTip: "",
        Left: "",
        Help: "",
        RowSpanForTabPotrait: "1",
        Render: true,
        minLength: "",
        RowSpanForLargeDesktop: "1",
        Label: "Auto Complete Widget",
        VisibleForLargeDesktop: true,
        VisibleForTabLandscape: true,
        RowSpanForTabLandscape: "1",
        ColSpan: "1",
        ShortcutKey: "i",
        DefaultValue: "Chennai",
        Alignment: "Left",
        CharWidth: "70",
        ColSpanForLargeDesktop: "1",
        ColSpanForTabLandscape: "1",
        Visible: true,
        Height: "20",
        Width: "60",
        ReadOnly: false,
        ColSpanForTabPotrait: "1",
        LOVReverseCopyString: "",
        Enabled: true,
        RowSpanForMobile: "1",
        VisibleForTabPotrait: true,
        HasLabel: true,
        IsHidable: false,
        Top: "",
        ProcessingText: "",
        LOVForwardCopyString: "",
        WidgetDescription: "",
        ColSpanForMobile: "1",
        LOVScreenMode: "DEFAULT",
        RowSpan: "1",
        VisibleForMobile: true,
        dataProviderForAutocomplete: "inline",
        valueListForAutocomplete: 123,
        PlaceholderForAutoComplete: "Test Val",
        Mandatory: false,
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
        multiple: true,
        emptyLabel: "EmptyLabel"
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <AutocompleteWidget
          values={values}
          onBlur={onBlur}
          onChange={onChange}
          conf={AutocompleteWidget3}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("AutocompleteWidget Basic Test Cases", async () => {
    const AutocompleteWidget3 = screen.getByTestId("AutocompleteWidget3")
      .firstChild.firstChild.nextElementSibling.firstChild;
    expect(AutocompleteWidget3.tagName).toBe("DIV");
  });
});
