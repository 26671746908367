/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  setData,
  getData,
  setValue
} from "../../shared/WindowImports";

import "./WarehouseReceiptInquiryPopUp.scss";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";

// START_USER_CODE-USER_IMPORTS

// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WarehouseReceiptInquiryPopUp(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseReceiptInquiryPopUp",
    windowName: "WarehouseReceiptInquiryPopUp",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WarehouseReceiptInquiryPopUp",
    // START_USER_CODE-USER_WarehouseReceiptInquiryPopUp_PROPERTIES

    // END_USER_CODE-USER_WarehouseReceiptInquiryPopUp_PROPERTIES
    btnNo: {
      name: "btnNo",
      type: "ButtonWidget",
      parent: "grpbxPopUp",
      Label: "No",
      CharWidth: "8",
      // START_USER_CODE-USER_btnNo_PROPERTIES

      // END_USER_CODE-USER_btnNo_PROPERTIES
    },
    btnYes: {
      name: "btnYes",
      type: "ButtonWidget",
      parent: "grpbxPopUp",
      Label: "Yes",
      CharWidth: "10",
      // START_USER_CODE-USER_btnYes_PROPERTIES

      // END_USER_CODE-USER_btnYes_PROPERTIES
    },
    txtDoYouWant: {
      name: "txtDoYouWant",
      type: "TextBoxWidget",
      parent: "grpbxPopUp",
      ColSpan: "3",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDoYouWant_PROPERTIES

      // END_USER_CODE-USER_txtDoYouWant_PROPERTIES
    },
    lblConfirmMsg: {
      name: "lblConfirmMsg",
      type: "LabelWidget",
      parent: "grpbxPopUp",
      Label: "Confirm EWR Send",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblConfirmMsg_PROPERTIES

      // END_USER_CODE-USER_lblConfirmMsg_PROPERTIES
    },
    grpbxPopUp: {
      name: "grpbxPopUp",
      type: "GroupBoxWidget",
      parent: "WarehouseReceiptInquiryPopUp",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPopUp_PROPERTIES

      // END_USER_CODE-USER_grpbxPopUp_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const dataFromWhouseInquiry = getData(thisObj,"dataSendingPopup")
  let Send_to_ewr = ''
  let sManualActionCode = ''
  let lstrxml , cancelBtn , COL_WRNO ,COL_OBLBP

  function FormLoad() {//Form LOad Functionality
    try {
      document.querySelector('.modal-dialog').style.boxShadow = 'none' //CSS To remove the Shadow of popup screen.
      if(dataFromWhouseInquiry != undefined && dataFromWhouseInquiry != null && dataFromWhouseInquiry != ''){
         cancelBtn = dataFromWhouseInquiry?.cancelbtn
         COL_WRNO = dataFromWhouseInquiry?.COL_WR_NO
         COL_OBLBP = dataFromWhouseInquiry?.COL_OBL_BP
        let title = "Do you want to send the " + cancelBtn + " record to EWR, for receipt " + COL_WRNO + "?"
        if ((cancelBtn?.toUpperCase()) == "UNCANCEL") {
          sManualActionCode = "UCN"
        }
        else {
          sManualActionCode = "CAN"
        }
        setValue(thisObj,"txtDoYouWant",title)
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Form Load",
        );
      }
      return false;
    }
  }

  const onbtnYesClick = async () => {//onbtnYesClick event
    try {
      Send_to_ewr = "Y"
      let dataObj = {
        "sendtoewr": Send_to_ewr?.trim(),
        "manual_action_code": sManualActionCode?.toUpperCase(),
        "user_id": userid
      }
      lstrxml = await WarehouseReceiptService.UpdateWareHouseReceiptChangeDate(COL_OBLBP, COL_WRNO, dataObj)
        if (lstrxml.status != 200) {
          alert("The " + cancelBtn + " update Failed! Contact your system administrator if this problem persists.")
          return;
        }
        let js1 = {
          yesNO : "YES",
          LastAction : sManualActionCode
        }
        setData(thisObj,"returnfromNoBtnClick",js1)
        document.getElementById("WarehouseReceipts_WarehouseReceiptInquiryPopUpPopUp").childNodes[0].click();
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnYesClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnYesClick = onbtnYesClick;

  const onbtnNoClick = async () => {//onbtnNoClick event
    try {
      Send_to_ewr = "N"
      let dataObj = {
        "sendtoewr": Send_to_ewr?.trim(),
        "manual_action_code": sManualActionCode?.toUpperCase(),
        "user_id": userid
      }
      lstrxml = await WarehouseReceiptService.UpdateWareHouseReceiptChangeDate(COL_OBLBP, COL_WRNO, dataObj)
        if (lstrxml.status != 200) {
          alert("The " + cancelBtn + " update Failed! Contact your system administrator if this problem persists.")
          return;
        }
        let js1 = {
          yesNO : "NO",
          LastAction : sManualActionCode,
          Send_to_ewr : Send_to_ewr
        }
        setData(thisObj,"returnfromNoBtnClick",js1)
        document.getElementById("WarehouseReceipts_WarehouseReceiptInquiryPopUpPopUp").childNodes[0].click();
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnNoClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnNoClick = onbtnNoClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_WarehouseReceiptInquiryPopUp*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseReceiptInquiryPopUp*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPopUp*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPopUp*/}

              <GroupBoxWidget conf={state.grpbxPopUp} screenConf={state}>
              <LabelWidget
                    values={values}
                    conf={state.lblConfirmMsg}
                    screenConf={state}
                  ></LabelWidget>
                {/* START_USER_CODE-USER_BEFORE_txtDoYouWant*/}

                {/* END_USER_CODE-USER_BEFORE_txtDoYouWant*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDoYouWant}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDoYouWant*/}

                {/* END_USER_CODE-USER_AFTER_txtDoYouWant*/}
                {/* START_USER_CODE-USER_BEFORE_btnYes*/}

                {/* END_USER_CODE-USER_BEFORE_btnYes*/}

                <ButtonWidget
                  conf={state.btnYes}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnYes*/}

                {/* END_USER_CODE-USER_AFTER_btnYes*/}
                {/* START_USER_CODE-USER_BEFORE_btnNo*/}

                {/* END_USER_CODE-USER_BEFORE_btnNo*/}

                <ButtonWidget
                  conf={state.btnNo}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnNo*/}

                {/* END_USER_CODE-USER_AFTER_btnNo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPopUp*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPopUp*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseReceiptInquiryPopUp*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseReceiptInquiryPopUp*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WarehouseReceiptInquiryPopUp);
