/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FedWhouseLicenseSetup from "./FedWhouseLicenseSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FedWhouseLicenseSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FedWhouseLicenseSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is FedWhouseLicenseSetup Loads Successfully", () => {
    expect(screen.getByText("FedWhouseLicenseSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for FedWhouseLicenseSetup", () => {
    // START_USER_CODE-USER_FedWhouseLicenseSetup_Custom_Test_Case
    // END_USER_CODE-USER_FedWhouseLicenseSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FedWhouseLicenseSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btncmdEnableDisableControls(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls = screen.getByTestId(
      "btncmdEnableDisableControls"
    );
    expect(btncmdEnableDisableControls).toBeInTheDocument;
    expect(btncmdEnableDisableControls.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseSetup_btncmdEnableDisableControls"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxFedWhouseLicenseSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxFedWhouseLicenseSetup = screen.getByTestId(
      "grpbxFedWhouseLicenseSetup"
    );
    expect(grpbxFedWhouseLicenseSetup.tagName).toBe("BUTTON");
    expect(grpbxFedWhouseLicenseSetup.type).toBe("button");
    expect(grpbxFedWhouseLicenseSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFedWhouseLicenseSetup", () => {
    // START_USER_CODE-USER_grpbxFedWhouseLicenseSetup_TEST
    // END_USER_CODE-USER_grpbxFedWhouseLicenseSetup_TEST
  });
  test("lblFederalWhouseLicense(Label Widget) Test Cases", async () => {
    const lblFederalWhouseLicense = screen.getByTestId(
      "lblFederalWhouseLicense"
    );
    expect(lblFederalWhouseLicense.tagName).toBe("LABEL");
    expect(lblFederalWhouseLicense.classList).toContain("form-label");
    expect(lblFederalWhouseLicense.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseSetup_lblFederalWhouseLicense"
      )
    );
  });
  test("Custom Test Cases for lblFederalWhouseLicense", () => {
    // START_USER_CODE-USER_lblFederalWhouseLicense_TEST
    // END_USER_CODE-USER_lblFederalWhouseLicense_TEST
  });
});
