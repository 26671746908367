/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  getData,
  setData,
  setValue,
  getValue
} from "../../shared/WindowImports";

import "./EWRViewRRI.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_EWRViewRRI(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "EWRViewRRI",
    windowName: "EWRViewRRI",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.EWRViewRRI",
    // START_USER_CODE-USER_EWRViewRRI_PROPERTIES
    horizontalForm : true,
    headerData: {
      scrName: "EWR RRI View ",
      scrCode: "PN1090M",
    },
    // END_USER_CODE-USER_EWRViewRRI_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colAddDate: {
      name: "colAddDate",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Add Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddDate_PROPERTIES

      // END_USER_CODE-USER_colAddDate_PROPERTIES
    },
    colAddUser: {
      name: "colAddUser",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Add User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddUser_PROPERTIES

      // END_USER_CODE-USER_colAddUser_PROPERTIES
    },
    colBPName: {
      name: "colBPName",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "BP Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBPName_PROPERTIES

      // END_USER_CODE-USER_colBPName_PROPERTIES
    },
    colBPNum: {
      name: "colBPNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "BP #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBPNum_PROPERTIES

      // END_USER_CODE-USER_colBPNum_PROPERTIES
    },
    colCCCErrCode: {
      name: "colCCCErrCode",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "CCC Err Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCCErrCode_PROPERTIES

      // END_USER_CODE-USER_colCCCErrCode_PROPERTIES
    },
    colCCCLoanApplDt: {
      name: "colCCCLoanApplDt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "CCC Loan Appl Dt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCCLoanApplDt_PROPERTIES

      // END_USER_CODE-USER_colCCCLoanApplDt_PROPERTIES
    },
    colCCCLoanDispDt: {
      name: "colCCCLoanDispDt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "CCC Loan Disp Dt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCCLoanDispDt_PROPERTIES

      // END_USER_CODE-USER_colCCCLoanDispDt_PROPERTIES
    },
    colCCCLoanRecvDt: {
      name: "colCCCLoanRecvDt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "CCC Loan Recv Dt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCCLoanRecvDt_PROPERTIES

      // END_USER_CODE-USER_colCCCLoanRecvDt_PROPERTIES
    },
    colCCCLoanRepayDt: {
      name: "colCCCLoanRepayDt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "CCC Loan Repay Dt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCCLoanRepayDt_PROPERTIES

      // END_USER_CODE-USER_colCCCLoanRepayDt_PROPERTIES
    },
    colCCCLoanStatus: {
      name: "colCCCLoanStatus",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "CCC Loan Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCCLoanStatus_PROPERTIES

      // END_USER_CODE-USER_colCCCLoanStatus_PROPERTIES
    },
    colClnChrg: {
      name: "colClnChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Cln Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colClnChrg_PROPERTIES

      // END_USER_CODE-USER_colClnChrg_PROPERTIES
    },
    colClnDryChrg: {
      name: "colClnDryChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Cln Dry Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colClnDryChrg_PROPERTIES

      // END_USER_CODE-USER_colClnDryChrg_PROPERTIES
    },
    colCropYr: {
      name: "colCropYr",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Crop Yr",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCropYr_PROPERTIES

      // END_USER_CODE-USER_colCropYr_PROPERTIES
    },
    colCurrHolder: {
      name: "colCurrHolder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Curr Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCurrHolder_PROPERTIES

      // END_USER_CODE-USER_colCurrHolder_PROPERTIES
    },
    colCurrHolderName: {
      name: "colCurrHolderName",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Curr Holder Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCurrHolderName_PROPERTIES

      // END_USER_CODE-USER_colCurrHolderName_PROPERTIES
    },
    colDamSplitPct: {
      name: "colDamSplitPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Dam Split Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDamSplitPct_PROPERTIES

      // END_USER_CODE-USER_colDamSplitPct_PROPERTIES
    },
    colDateCancelled: {
      name: "colDateCancelled",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Cancelled",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateCancelled_PROPERTIES

      // END_USER_CODE-USER_colDateCancelled_PROPERTIES
    },
    colDateCurrHolder: {
      name: "colDateCurrHolder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Curr holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateCurrHolder_PROPERTIES

      // END_USER_CODE-USER_colDateCurrHolder_PROPERTIES
    },
    colDateIssued: {
      name: "colDateIssued",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Issued",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateIssued_PROPERTIES

      // END_USER_CODE-USER_colDateIssued_PROPERTIES
    },
    colDateLastAppvRej: {
      name: "colDateLastAppvRej",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Last Appv/Rej",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateLastAppvRej_PROPERTIES

      // END_USER_CODE-USER_colDateLastAppvRej_PROPERTIES
    },
    colDateLastChg: {
      name: "colDateLastChg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Last Chg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateLastChg_PROPERTIES

      // END_USER_CODE-USER_colDateLastChg_PROPERTIES
    },
    colDateLoanReleased: {
      name: "colDateLoanReleased",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Loan Released",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDateLoanReleased_PROPERTIES

      // END_USER_CODE-USER_colDateLoanReleased_PROPERTIES
    },
    colDatePrevHolderChg: {
      name: "colDatePrevHolderChg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Date Prev Holder Chg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDatePrevHolderChg_PROPERTIES

      // END_USER_CODE-USER_colDatePrevHolderChg_PROPERTIES
    },
    colDownloadDate: {
      name: "colDownloadDate",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Download Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDownloadDate_PROPERTIES

      // END_USER_CODE-USER_colDownloadDate_PROPERTIES
    },
    colDownloadTime: {
      name: "colDownloadTime",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Download Time",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDownloadTime_PROPERTIES

      // END_USER_CODE-USER_colDownloadTime_PROPERTIES
    },
    colDPSCnty: {
      name: "colDPSCnty",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "DPS Cnty",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDPSCnty_PROPERTIES

      // END_USER_CODE-USER_colDPSCnty_PROPERTIES
    },
    colDPSSt: {
      name: "colDPSSt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "DPS St",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDPSSt_PROPERTIES

      // END_USER_CODE-USER_colDPSSt_PROPERTIES
    },
    colDryChrg: {
      name: "colDryChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Dry Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDryChrg_PROPERTIES

      // END_USER_CODE-USER_colDryChrg_PROPERTIES
    },
    colErrCode: {
      name: "colErrCode",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Err Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colErrCode_PROPERTIES

      // END_USER_CODE-USER_colErrCode_PROPERTIES
    },
    colErrText: {
      name: "colErrText",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Err Text",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colErrText_PROPERTIES

      // END_USER_CODE-USER_colErrText_PROPERTIES
    },
    colFarmCnty: {
      name: "colFarmCnty",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Farm Cnty",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmCnty_PROPERTIES

      // END_USER_CODE-USER_colFarmCnty_PROPERTIES
    },
    colFarmNum: {
      name: "colFarmNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Farm #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmNum_PROPERTIES

      // END_USER_CODE-USER_colFarmNum_PROPERTIES
    },
    colFarmSt: {
      name: "colFarmSt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Farm St",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmSt_PROPERTIES

      // END_USER_CODE-USER_colFarmSt_PROPERTIES
    },
    colFileNum: {
      name: "colFileNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "File #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFileNum_PROPERTIES

      // END_USER_CODE-USER_colFileNum_PROPERTIES
    },
    colFlavFound: {
      name: "colFlavFound",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Flav Found",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFlavFound_PROPERTIES

      // END_USER_CODE-USER_colFlavFound_PROPERTIES
    },
    colFMPct: {
      name: "colFMPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "FM Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFMPct_PROPERTIES

      // END_USER_CODE-USER_colFMPct_PROPERTIES
    },
    colFromHolder: {
      name: "colFromHolder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "From Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFromHolder_PROPERTIES

      // END_USER_CODE-USER_colFromHolder_PROPERTIES
    },
    colFrzPct: {
      name: "colFrzPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Frz Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrzPct_PROPERTIES

      // END_USER_CODE-USER_colFrzPct_PROPERTIES
    },
    colGraded: {
      name: "colGraded",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Graded",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGraded_PROPERTIES

      // END_USER_CODE-USER_colGraded_PROPERTIES
    },
    colGrowHolder: {
      name: "colGrowHolder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Grow Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrowHolder_PROPERTIES

      // END_USER_CODE-USER_colGrowHolder_PROPERTIES
    },
    colHaulChrg: {
      name: "colHaulChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Haul Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHaulChrg_PROPERTIES

      // END_USER_CODE-USER_colHaulChrg_PROPERTIES
    },
    colHullPct: {
      name: "colHullPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Hull Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHullPct_PROPERTIES

      // END_USER_CODE-USER_colHullPct_PROPERTIES
    },
    colInsured: {
      name: "colInsured",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Insured",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInsured_PROPERTIES

      // END_USER_CODE-USER_colInsured_PROPERTIES
    },
    colLicNum: {
      name: "colLicNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Lic #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLicNum_PROPERTIES

      // END_USER_CODE-USER_colLicNum_PROPERTIES
    },
    colLicType: {
      name: "colLicType",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Lic Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLicType_PROPERTIES

      // END_USER_CODE-USER_colLicType_PROPERTIES
    },
    colLoanable: {
      name: "colLoanable",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Loanable",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanable_PROPERTIES

      // END_USER_CODE-USER_colLoanable_PROPERTIES
    },
    colLoanLDPType: {
      name: "colLoanLDPType",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Loan LDP Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanLDPType_PROPERTIES

      // END_USER_CODE-USER_colLoanLDPType_PROPERTIES
    },
    colLoanNum: {
      name: "colLoanNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Loan #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanNum_PROPERTIES

      // END_USER_CODE-USER_colLoanNum_PROPERTIES
    },
    colLotNum: {
      name: "colLotNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Lot #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLotNum_PROPERTIES

      // END_USER_CODE-USER_colLotNum_PROPERTIES
    },
    colLSKLbs: {
      name: "colLSKLbs",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Lsk Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLSKLbs_PROPERTIES

      // END_USER_CODE-USER_colLSKLbs_PROPERTIES
    },
    colLskTons: {
      name: "colLskTons",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Lsk Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLskTons_PROPERTIES

      // END_USER_CODE-USER_colLskTons_PROPERTIES
    },
    colMoistPct: {
      name: "colMoistPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Moist Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMoistPct_PROPERTIES

      // END_USER_CODE-USER_colMoistPct_PROPERTIES
    },
    colNegot: {
      name: "colNegot",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Negot",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNegot_PROPERTIES

      // END_USER_CODE-USER_colNegot_PROPERTIES
    },
    colNetLbs: {
      name: "colNetLbs",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Net Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetLbs_PROPERTIES

      // END_USER_CODE-USER_colNetLbs_PROPERTIES
    },
    colNetTons: {
      name: "colNetTons",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Net Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetTons_PROPERTIES

      // END_USER_CODE-USER_colNetTons_PROPERTIES
    },
    colNum: {
      name: "colNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNum_PROPERTIES

      // END_USER_CODE-USER_colNum_PROPERTIES
    },
    colOthChrg: {
      name: "colOthChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Oth Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOthChrg_PROPERTIES

      // END_USER_CODE-USER_colOthChrg_PROPERTIES
    },
    colOthKernPct: {
      name: "colOthKernPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Oth Kern Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOthKernPct_PROPERTIES

      // END_USER_CODE-USER_colOthKernPct_PROPERTIES
    },
    colPaperCntlNum: {
      name: "colPaperCntlNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Paper Cntl #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPaperCntlNum_PROPERTIES

      // END_USER_CODE-USER_colPaperCntlNum_PROPERTIES
    },
    colPaperNum: {
      name: "colPaperNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Paper #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPaperNum_PROPERTIES

      // END_USER_CODE-USER_colPaperNum_PROPERTIES
    },
    colParWhseCity: {
      name: "colParWhseCity",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Par Whse City",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colParWhseCity_PROPERTIES

      // END_USER_CODE-USER_colParWhseCity_PROPERTIES
    },
    colParWhseSt: {
      name: "colParWhseSt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Par Whse St",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colParWhseSt_PROPERTIES

      // END_USER_CODE-USER_colParWhseSt_PROPERTIES
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutType_PROPERTIES

      // END_USER_CODE-USER_colPnutType_PROPERTIES
    },
    colPrepaidInCharge: {
      name: "colPrepaidInCharge",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Prepaid In Charge",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrepaidInCharge_PROPERTIES

      // END_USER_CODE-USER_colPrepaidInCharge_PROPERTIES
    },
    colPrepaidOutCharge: {
      name: "colPrepaidOutCharge",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Prepaid Out Charge",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrepaidOutCharge_PROPERTIES

      // END_USER_CODE-USER_colPrepaidOutCharge_PROPERTIES
    },
    colPrevHolderName: {
      name: "colPrevHolderName",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Prev Holder Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrevHolderName_PROPERTIES

      // END_USER_CODE-USER_colPrevHolderName_PROPERTIES
    },
    colPrevSubholder: {
      name: "colPrevSubholder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Prev Subholder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrevSubholder_PROPERTIES

      // END_USER_CODE-USER_colPrevSubholder_PROPERTIES
    },
    colProdName: {
      name: "colProdName",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Prod Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProdName_PROPERTIES

      // END_USER_CODE-USER_colProdName_PROPERTIES
    },
    colRcptNum: {
      name: "colRcptNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Rcpt #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptNum_PROPERTIES

      // END_USER_CODE-USER_colRcptNum_PROPERTIES
    },
    colRcptStatus: {
      name: "colRcptStatus",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Rcpt Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptStatus_PROPERTIES

      // END_USER_CODE-USER_colRcptStatus_PROPERTIES
    },
    colRcptType: {
      name: "colRcptType",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Rcpt Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptType_PROPERTIES

      // END_USER_CODE-USER_colRcptType_PROPERTIES
    },
    colRcptVal: {
      name: "colRcptVal",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Rcpt Val",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptVal_PROPERTIES

      // END_USER_CODE-USER_colRcptVal_PROPERTIES
    },
    colRecvChrgAmt: {
      name: "colRecvChrgAmt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Recv Chrg Amt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvChrgAmt_PROPERTIES

      // END_USER_CODE-USER_colRecvChrgAmt_PROPERTIES
    },
    colRecvChrgPaid: {
      name: "colRecvChrgPaid",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Recv Chrg Paid",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvChrgPaid_PROPERTIES

      // END_USER_CODE-USER_colRecvChrgPaid_PROPERTIES
    },
    colRecvChrgPer: {
      name: "colRecvChrgPer",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Recv Chrg Per",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvChrgPer_PROPERTIES

      // END_USER_CODE-USER_colRecvChrgPer_PROPERTIES
    },
    colRecvdCity: {
      name: "colRecvdCity",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Recvd City",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvdCity_PROPERTIES

      // END_USER_CODE-USER_colRecvdCity_PROPERTIES
    },
    colRecvdFrom: {
      name: "colRecvdFrom",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Recvd From",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvdFrom_PROPERTIES

      // END_USER_CODE-USER_colRecvdFrom_PROPERTIES
    },
    colRecvdSt: {
      name: "colRecvdSt",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Recvd St",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvdSt_PROPERTIES

      // END_USER_CODE-USER_colRecvdSt_PROPERTIES
    },
    colRmdPct: {
      name: "colRmdPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Rmd Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRmdPct_PROPERTIES

      // END_USER_CODE-USER_colRmdPct_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colSmkrdPct: {
      name: "colSmkrdPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Smkrs Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSmkrdPct_PROPERTIES

      // END_USER_CODE-USER_colSmkrdPct_PROPERTIES
    },
    colSSPct: {
      name: "colSSPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "SS Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSSPct_PROPERTIES

      // END_USER_CODE-USER_colSSPct_PROPERTIES
    },
    colStgCont: {
      name: "colStgCont",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Stg Cont",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStgCont_PROPERTIES

      // END_USER_CODE-USER_colStgCont_PROPERTIES
    },
    colStgDate: {
      name: "colStgDate",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Stg Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStgDate_PROPERTIES

      // END_USER_CODE-USER_colStgDate_PROPERTIES
    },
    colStgStatus: {
      name: "colStgStatus",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Stg Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStgStatus_PROPERTIES

      // END_USER_CODE-USER_colStgStatus_PROPERTIES
    },
    colStorageCharge: {
      name: "colStorageCharge",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Storage Charge",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStorageCharge_PROPERTIES

      // END_USER_CODE-USER_colStorageCharge_PROPERTIES
    },
    colStoragePaidThroughDate: {
      name: "colStoragePaidThroughDate",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Storage Paid Through Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStoragePaidThroughDate_PROPERTIES

      // END_USER_CODE-USER_colStoragePaidThroughDate_PROPERTIES
    },
    colSubholder: {
      name: "colSubholder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Subholder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSubholder_PROPERTIES

      // END_USER_CODE-USER_colSubholder_PROPERTIES
    },
    colToHolder: {
      name: "colToHolder",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "To Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colToHolder_PROPERTIES

      // END_USER_CODE-USER_colToHolder_PROPERTIES
    },
    colTotalLbs: {
      name: "colTotalLbs",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalLbs_PROPERTIES

      // END_USER_CODE-USER_colTotalLbs_PROPERTIES
    },
    colTotalPrepaidInOutCharges: {
      name: "colTotalPrepaidInOutCharges",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Total Prepaid In Out Charges",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalPrepaidInOutCharges_PROPERTIES

      // END_USER_CODE-USER_colTotalPrepaidInOutCharges_PROPERTIES
    },
    colTotalTons: {
      name: "colTotalTons",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalTons_PROPERTIES

      // END_USER_CODE-USER_colTotalTons_PROPERTIES
    },
    colTotClnChrg: {
      name: "colTotClnChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Cln Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotClnChrg_PROPERTIES

      // END_USER_CODE-USER_colTotClnChrg_PROPERTIES
    },
    colTotClnDryChrg: {
      name: "colTotClnDryChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Cln Dry Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotClnDryChrg_PROPERTIES

      // END_USER_CODE-USER_colTotClnDryChrg_PROPERTIES
    },
    colTotDamKern: {
      name: "colTotDamKern",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Dam Kern",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotDamKern_PROPERTIES

      // END_USER_CODE-USER_colTotDamKern_PROPERTIES
    },
    colTotDamPct: {
      name: "colTotDamPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Dam Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotDamPct_PROPERTIES

      // END_USER_CODE-USER_colTotDamPct_PROPERTIES
    },
    colTotDryChrg: {
      name: "colTotDryChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Dry Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotDryChrg_PROPERTIES

      // END_USER_CODE-USER_colTotDryChrg_PROPERTIES
    },
    colTotHaulChrg: {
      name: "colTotHaulChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Haul Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotHaulChrg_PROPERTIES

      // END_USER_CODE-USER_colTotHaulChrg_PROPERTIES
    },
    colTotHullKernPct: {
      name: "colTotHullKernPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Hull Kern Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotHullKernPct_PROPERTIES

      // END_USER_CODE-USER_colTotHullKernPct_PROPERTIES
    },
    colTotKern: {
      name: "colTotKern",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Kern",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotKern_PROPERTIES

      // END_USER_CODE-USER_colTotKern_PROPERTIES
    },
    colTotKernPct: {
      name: "colTotKernPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Kern Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotKernPct_PROPERTIES

      // END_USER_CODE-USER_colTotKernPct_PROPERTIES
    },
    colTotOthChrg: {
      name: "colTotOthChrg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Oth Chrg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotOthChrg_PROPERTIES

      // END_USER_CODE-USER_colTotOthChrg_PROPERTIES
    },
    colTotSmkPct: {
      name: "colTotSmkPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Tot Smk Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotSmkPct_PROPERTIES

      // END_USER_CODE-USER_colTotSmkPct_PROPERTIES
    },
    colTransmissionNum: {
      name: "colTransmissionNum",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Transmission #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransmissionNum_PROPERTIES

      // END_USER_CODE-USER_colTransmissionNum_PROPERTIES
    },
    colUploadFile: {
      name: "colUploadFile",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Upload File",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUploadFile_PROPERTIES

      // END_USER_CODE-USER_colUploadFile_PROPERTIES
    },
    colUserCancel: {
      name: "colUserCancel",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "User Cancel",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserCancel_PROPERTIES

      // END_USER_CODE-USER_colUserCancel_PROPERTIES
    },
    colUserChg: {
      name: "colUserChg",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "User Chg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserChg_PROPERTIES

      // END_USER_CODE-USER_colUserChg_PROPERTIES
    },
    colUserDefined: {
      name: "colUserDefined",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "User Defined",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserDefined_PROPERTIES

      // END_USER_CODE-USER_colUserDefined_PROPERTIES
    },
    colUserIssued: {
      name: "colUserIssued",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "User Issued",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserIssued_PROPERTIES

      // END_USER_CODE-USER_colUserIssued_PROPERTIES
    },
    colUserLDP: {
      name: "colUserLDP",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "User Loan LDP",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserLDP_PROPERTIES

      // END_USER_CODE-USER_colUserLDP_PROPERTIES
    },
    colVaELKPct: {
      name: "colVaELKPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Va ELK Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVaELKPct_PROPERTIES

      // END_USER_CODE-USER_colVaELKPct_PROPERTIES
    },
    colVaFancyPct: {
      name: "colVaFancyPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Va Fancy Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVaFancyPct_PROPERTIES

      // END_USER_CODE-USER_colVaFancyPct_PROPERTIES
    },
    colVICrkedPct: {
      name: "colVICrkedPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "VI Crked Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVICrkedPct_PROPERTIES

      // END_USER_CODE-USER_colVICrkedPct_PROPERTIES
    },
    colVIDiscolPct: {
      name: "colVIDiscolPct",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "VI Discol Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVIDiscolPct_PROPERTIES

      // END_USER_CODE-USER_colVIDiscolPct_PROPERTIES
    },
    colWhseCode: {
      name: "colWhseCode",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Whse Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseCode_PROPERTIES

      // END_USER_CODE-USER_colWhseCode_PROPERTIES
    },
    colWhsemanDep: {
      name: "colWhsemanDep",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Whseman is Dep",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhsemanDep_PROPERTIES

      // END_USER_CODE-USER_colWhsemanDep_PROPERTIES
    },
    colWhseOper: {
      name: "colWhseOper",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Whse Oper",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseOper_PROPERTIES

      // END_USER_CODE-USER_colWhseOper_PROPERTIES
    },
    colWhsemanSign: {
      name: "colWhsemanSign",
      type: "GridColumnWidget",
      parent: "gridEWRViewRRI",
      Label: "Whseman Sign",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhsemanSign_PROPERTIES

      // END_USER_CODE-USER_colWhsemanSign_PROPERTIES
    },
    gridEWRViewRRI: {
      name: "gridEWRViewRRI",
      type: "GridWidget",
      parent: "grpbxEWRViewRRI",
      gridCellsOrder:
        "txtcolNum,txtFileNum,txtcolToHolder,txtcolAction,txtcolFromHolder,txtcolDownloadDate,txtcolDownloadTime,txtcolWhseCode,txtcolLicType,txtcolRcptNum,txtcolCropYr,txtcolLicNum,txtcolNegot,txtcolInsured,txtcolPaperNum,txtcolNetLbs,txtcolNetTons,txtcolLSKLbs,txtcolLskTons,txtcolTotalLbs,txtcolTotalTons,txtcolStgDate,txtcolStgCont,txtcolStgStatus,txtcolFarmSt,txtcolFarmCnty,txtcolParWhseCity,txtcolParWhseSt,txtcolRecvdFrom,txtcolProdName,txtcolFarmNum,txtcolRecvdCity,txtcolRecvdSt,txtcolRecvChrgAmt,txtcolRecvChrgPaid,txtcolRecvChrgPer,txtcolGraded,txtcolPnutType,txtcolSeg,txtcolMoistPct,txtcolFMPct,txtcolVaELKPct,txtcolVaFancyPct,txtcolVICrkedPct,txtcolVIDiscolPct,txtcolLotNum,txtcolTotKern,txtcolTotDamKern,txtcolSmkrdPct,txtcolSSPct,trtcolTotSmkPct,txtcolOthKernPct,txtcolDamSplitPct,txtcolTotDamPct,txtcolTotKernPct,txtcolHullPct,txtcolFlavFound,txtcolTotHullKernPct,txtcolFrzPct,txtcolRmdPct,txtcolWhsemanDep,txtcolWhseOper,txtcolWhsemanSign,txtcolPaperCntlNum,txtcolClnChrg,txtcolDryChrg,txtcolClnDryChrg,txtcolHaulChrg,txtcolOthChrg,txtcolTotClnChrg,txtcolTotDryChrg,txtcolTotClnDryChrg,txtcolTotHaulChrg,txtcolTotOthChrg,txtcolBPNum,txtcolBPName,txtcolRcptVal,txtcolDPSSt,txtcolDPSCnty,txtcolLoanNum,txtcolCurrHolder,txtcolDateCurrHolder,txtcolSubholder,txtcolPrevSubholder,txtcolRcptStatus,txtcolRcptType,txtcolCCCLoanStatus,txtcolDateIssued,txtcolDateCancelled,txtcolDateLastChg,txtcolDateLastAppvRej,txtcolDateLoanReleased,txtcolUserIssued,txtcolUserCancel,txtcolUserChg,txtcolUserLDP,txtcolGrowHolder,txtcolLoanable,txtcolLoanLDPType,txtcolDatePrevHolderChg,txtcolCurrHolderName,txtcolPrevHolderName,txtcolCCCLoanDispDt,txtcolCCCLoanApplDt,txtcolCCCLoanRepayDt,txtcolCCCLoanRecvDt,txtcolCCCErrCode,txtcolUserDefined,txtcolUploadFile,txtcolTransmissionNum,txtcolStorageCharge,txtcolStoragePaidThroughDate,txtcolPrepaidInCharge,txtcolPrepaidOutCharge,txtcolTotalPrepaidInOutCharges,txtcolErrCode,txtcolErrText,txtcolAddUser,txtcolAddDate",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridEWRViewRRI_PROPERTIES

      // END_USER_CODE-USER_gridEWRViewRRI_PROPERTIES
    },
    trtcolTotSmkPct: {
      name: "trtcolTotSmkPct",
      type: "TextBoxWidget",
      colName: "colTotSmkPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_trtcolTotSmkPct_PROPERTIES

      // END_USER_CODE-USER_trtcolTotSmkPct_PROPERTIES
    },
    txtcolAction: {
      name: "txtcolAction",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAction_PROPERTIES

      // END_USER_CODE-USER_txtcolAction_PROPERTIES
    },
    txtcolAddDate: {
      name: "txtcolAddDate",
      type: "TextBoxWidget",
      colName: "colAddDate",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAddDate_PROPERTIES

      // END_USER_CODE-USER_txtcolAddDate_PROPERTIES
    },
    txtcolAddUser: {
      name: "txtcolAddUser",
      type: "TextBoxWidget",
      colName: "colAddUser",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAddUser_PROPERTIES

      // END_USER_CODE-USER_txtcolAddUser_PROPERTIES
    },
    txtcolBPName: {
      name: "txtcolBPName",
      type: "TextBoxWidget",
      colName: "colBPName",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBPName_PROPERTIES

      // END_USER_CODE-USER_txtcolBPName_PROPERTIES
    },
    txtcolBPNum: {
      name: "txtcolBPNum",
      type: "TextBoxWidget",
      colName: "colBPNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBPNum_PROPERTIES

      // END_USER_CODE-USER_txtcolBPNum_PROPERTIES
    },
    txtcolCCCErrCode: {
      name: "txtcolCCCErrCode",
      type: "TextBoxWidget",
      colName: "colCCCErrCode",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCCErrCode_PROPERTIES

      // END_USER_CODE-USER_txtcolCCCErrCode_PROPERTIES
    },
    txtcolCCCLoanApplDt: {
      name: "txtcolCCCLoanApplDt",
      type: "TextBoxWidget",
      colName: "colCCCLoanApplDt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCCLoanApplDt_PROPERTIES

      // END_USER_CODE-USER_txtcolCCCLoanApplDt_PROPERTIES
    },
    txtcolCCCLoanDispDt: {
      name: "txtcolCCCLoanDispDt",
      type: "TextBoxWidget",
      colName: "colCCCLoanDispDt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCCLoanDispDt_PROPERTIES

      // END_USER_CODE-USER_txtcolCCCLoanDispDt_PROPERTIES
    },
    txtcolCCCLoanRecvDt: {
      name: "txtcolCCCLoanRecvDt",
      type: "TextBoxWidget",
      colName: "colCCCLoanRecvDt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCCLoanRecvDt_PROPERTIES

      // END_USER_CODE-USER_txtcolCCCLoanRecvDt_PROPERTIES
    },
    txtcolCCCLoanRepayDt: {
      name: "txtcolCCCLoanRepayDt",
      type: "TextBoxWidget",
      colName: "colCCCLoanRepayDt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCCLoanRepayDt_PROPERTIES

      // END_USER_CODE-USER_txtcolCCCLoanRepayDt_PROPERTIES
    },
    txtcolCCCLoanStatus: {
      name: "txtcolCCCLoanStatus",
      type: "TextBoxWidget",
      colName: "colCCCLoanStatus",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCCLoanStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolCCCLoanStatus_PROPERTIES
    },
    txtcolClnChrg: {
      name: "txtcolClnChrg",
      type: "TextBoxWidget",
      colName: "colClnChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolClnChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolClnChrg_PROPERTIES
    },
    txtcolClnDryChrg: {
      name: "txtcolClnDryChrg",
      type: "TextBoxWidget",
      colName: "colClnDryChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolClnDryChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolClnDryChrg_PROPERTIES
    },
    txtcolCropYr: {
      name: "txtcolCropYr",
      type: "TextBoxWidget",
      colName: "colCropYr",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCropYr_PROPERTIES

      // END_USER_CODE-USER_txtcolCropYr_PROPERTIES
    },
    txtcolCurrHolder: {
      name: "txtcolCurrHolder",
      type: "TextBoxWidget",
      colName: "colCurrHolder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCurrHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolCurrHolder_PROPERTIES
    },
    txtcolCurrHolderName: {
      name: "txtcolCurrHolderName",
      type: "TextBoxWidget",
      colName: "colCurrHolderName",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCurrHolderName_PROPERTIES

      // END_USER_CODE-USER_txtcolCurrHolderName_PROPERTIES
    },
    txtcolDamSplitPct: {
      name: "txtcolDamSplitPct",
      type: "TextBoxWidget",
      colName: "colDamSplitPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDamSplitPct_PROPERTIES

      // END_USER_CODE-USER_txtcolDamSplitPct_PROPERTIES
    },
    txtcolDateCancelled: {
      name: "txtcolDateCancelled",
      type: "TextBoxWidget",
      colName: "colDateCancelled",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateCancelled_PROPERTIES

      // END_USER_CODE-USER_txtcolDateCancelled_PROPERTIES
    },
    txtcolDateCurrHolder: {
      name: "txtcolDateCurrHolder",
      type: "TextBoxWidget",
      colName: "colDateCurrHolder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateCurrHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolDateCurrHolder_PROPERTIES
    },
    txtcolDateIssued: {
      name: "txtcolDateIssued",
      type: "TextBoxWidget",
      colName: "colDateIssued",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateIssued_PROPERTIES

      // END_USER_CODE-USER_txtcolDateIssued_PROPERTIES
    },
    txtcolDateLastAppvRej: {
      name: "txtcolDateLastAppvRej",
      type: "TextBoxWidget",
      colName: "colDateLastAppvRej",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateLastAppvRej_PROPERTIES

      // END_USER_CODE-USER_txtcolDateLastAppvRej_PROPERTIES
    },
    txtcolDateLastChg: {
      name: "txtcolDateLastChg",
      type: "TextBoxWidget",
      colName: "colDateLastChg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateLastChg_PROPERTIES

      // END_USER_CODE-USER_txtcolDateLastChg_PROPERTIES
    },
    txtcolDateLoanReleased: {
      name: "txtcolDateLoanReleased",
      type: "TextBoxWidget",
      colName: "colDateLoanReleased",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDateLoanReleased_PROPERTIES

      // END_USER_CODE-USER_txtcolDateLoanReleased_PROPERTIES
    },
    txtcolDatePrevHolderChg: {
      name: "txtcolDatePrevHolderChg",
      type: "TextBoxWidget",
      colName: "colDatePrevHolderChg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDatePrevHolderChg_PROPERTIES

      // END_USER_CODE-USER_txtcolDatePrevHolderChg_PROPERTIES
    },
    txtcolDownloadDate: {
      name: "txtcolDownloadDate",
      type: "TextBoxWidget",
      colName: "colDownloadDate",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDownloadDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDownloadDate_PROPERTIES
    },
    txtcolDownloadTime: {
      name: "txtcolDownloadTime",
      type: "TextBoxWidget",
      colName: "colDownloadTime",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDownloadTime_PROPERTIES

      // END_USER_CODE-USER_txtcolDownloadTime_PROPERTIES
    },
    txtcolDPSCnty: {
      name: "txtcolDPSCnty",
      type: "TextBoxWidget",
      colName: "colDPSCnty",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDPSCnty_PROPERTIES

      // END_USER_CODE-USER_txtcolDPSCnty_PROPERTIES
    },
    txtcolDPSSt: {
      name: "txtcolDPSSt",
      type: "TextBoxWidget",
      colName: "colDPSSt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDPSSt_PROPERTIES

      // END_USER_CODE-USER_txtcolDPSSt_PROPERTIES
    },
    txtcolDryChrg: {
      name: "txtcolDryChrg",
      type: "TextBoxWidget",
      colName: "colDryChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDryChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolDryChrg_PROPERTIES
    },
    txtcolErrCode: {
      name: "txtcolErrCode",
      type: "TextBoxWidget",
      colName: "colErrCode",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolErrCode_PROPERTIES

      // END_USER_CODE-USER_txtcolErrCode_PROPERTIES
    },
    txtcolErrText: {
      name: "txtcolErrText",
      type: "TextBoxWidget",
      colName: "colErrText",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolErrText_PROPERTIES

      // END_USER_CODE-USER_txtcolErrText_PROPERTIES
    },
    txtcolFarmCnty: {
      name: "txtcolFarmCnty",
      type: "TextBoxWidget",
      colName: "colFarmCnty",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmCnty_PROPERTIES

      // END_USER_CODE-USER_txtcolFarmCnty_PROPERTIES
    },
    txtcolFarmNum: {
      name: "txtcolFarmNum",
      type: "TextBoxWidget",
      colName: "colFarmNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmNum_PROPERTIES

      // END_USER_CODE-USER_txtcolFarmNum_PROPERTIES
    },
    txtcolFarmSt: {
      name: "txtcolFarmSt",
      type: "TextBoxWidget",
      colName: "colFarmSt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmSt_PROPERTIES

      // END_USER_CODE-USER_txtcolFarmSt_PROPERTIES
    },
    txtcolFlavFound: {
      name: "txtcolFlavFound",
      type: "TextBoxWidget",
      colName: "colFlavFound",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFlavFound_PROPERTIES

      // END_USER_CODE-USER_txtcolFlavFound_PROPERTIES
    },
    txtcolFMPct: {
      name: "txtcolFMPct",
      type: "TextBoxWidget",
      colName: "colFMPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFMPct_PROPERTIES

      // END_USER_CODE-USER_txtcolFMPct_PROPERTIES
    },
    txtcolFromHolder: {
      name: "txtcolFromHolder",
      type: "TextBoxWidget",
      colName: "colFromHolder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFromHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolFromHolder_PROPERTIES
    },
    txtcolFrzPct: {
      name: "txtcolFrzPct",
      type: "TextBoxWidget",
      colName: "colFrzPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFrzPct_PROPERTIES

      // END_USER_CODE-USER_txtcolFrzPct_PROPERTIES
    },
    txtcolGraded: {
      name: "txtcolGraded",
      type: "TextBoxWidget",
      colName: "colGraded",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGraded_PROPERTIES

      // END_USER_CODE-USER_txtcolGraded_PROPERTIES
    },
    txtcolGrowHolder: {
      name: "txtcolGrowHolder",
      type: "TextBoxWidget",
      colName: "colGrowHolder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrowHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolGrowHolder_PROPERTIES
    },
    txtcolHaulChrg: {
      name: "txtcolHaulChrg",
      type: "TextBoxWidget",
      colName: "colHaulChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHaulChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolHaulChrg_PROPERTIES
    },
    txtcolHullPct: {
      name: "txtcolHullPct",
      type: "TextBoxWidget",
      colName: "colHullPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHullPct_PROPERTIES

      // END_USER_CODE-USER_txtcolHullPct_PROPERTIES
    },
    txtcolInsured: {
      name: "txtcolInsured",
      type: "TextBoxWidget",
      colName: "colInsured",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInsured_PROPERTIES

      // END_USER_CODE-USER_txtcolInsured_PROPERTIES
    },
    txtcolLicNum: {
      name: "txtcolLicNum",
      type: "TextBoxWidget",
      colName: "colLicNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLicNum_PROPERTIES

      // END_USER_CODE-USER_txtcolLicNum_PROPERTIES
    },
    txtcolLicType: {
      name: "txtcolLicType",
      type: "TextBoxWidget",
      colName: "colLicType",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLicType_PROPERTIES

      // END_USER_CODE-USER_txtcolLicType_PROPERTIES
    },
    txtcolLoanable: {
      name: "txtcolLoanable",
      type: "TextBoxWidget",
      colName: "colLoanable",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanable_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanable_PROPERTIES
    },
    txtcolLoanLDPType: {
      name: "txtcolLoanLDPType",
      type: "TextBoxWidget",
      colName: "colLoanLDPType",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanLDPType_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanLDPType_PROPERTIES
    },
    txtcolLoanNum: {
      name: "txtcolLoanNum",
      type: "TextBoxWidget",
      colName: "colLoanNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanNum_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanNum_PROPERTIES
    },
    txtcolLotNum: {
      name: "txtcolLotNum",
      type: "TextBoxWidget",
      colName: "colLotNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLotNum_PROPERTIES

      // END_USER_CODE-USER_txtcolLotNum_PROPERTIES
    },
    txtcolLSKLbs: {
      name: "txtcolLSKLbs",
      type: "TextBoxWidget",
      colName: "colLSKLbs",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLSKLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolLSKLbs_PROPERTIES
    },
    txtcolLskTons: {
      name: "txtcolLskTons",
      type: "TextBoxWidget",
      colName: "colLskTons",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLskTons_PROPERTIES

      // END_USER_CODE-USER_txtcolLskTons_PROPERTIES
    },
    txtcolMoistPct: {
      name: "txtcolMoistPct",
      type: "TextBoxWidget",
      colName: "colMoistPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMoistPct_PROPERTIES

      // END_USER_CODE-USER_txtcolMoistPct_PROPERTIES
    },
    txtcolNegot: {
      name: "txtcolNegot",
      type: "TextBoxWidget",
      colName: "colNegot",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNegot_PROPERTIES

      // END_USER_CODE-USER_txtcolNegot_PROPERTIES
    },
    txtcolNetLbs: {
      name: "txtcolNetLbs",
      type: "TextBoxWidget",
      colName: "colNetLbs",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolNetLbs_PROPERTIES
    },
    txtcolNetTons: {
      name: "txtcolNetTons",
      type: "TextBoxWidget",
      colName: "colNetTons",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetTons_PROPERTIES

      // END_USER_CODE-USER_txtcolNetTons_PROPERTIES
    },
    txtcolNum: {
      name: "txtcolNum",
      type: "TextBoxWidget",
      colName: "colNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNum_PROPERTIES

      // END_USER_CODE-USER_txtcolNum_PROPERTIES
    },
    txtcolOthChrg: {
      name: "txtcolOthChrg",
      type: "TextBoxWidget",
      colName: "colOthChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOthChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolOthChrg_PROPERTIES
    },
    txtcolOthKernPct: {
      name: "txtcolOthKernPct",
      type: "TextBoxWidget",
      colName: "colOthKernPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOthKernPct_PROPERTIES

      // END_USER_CODE-USER_txtcolOthKernPct_PROPERTIES
    },
    txtcolPaperCntlNum: {
      name: "txtcolPaperCntlNum",
      type: "TextBoxWidget",
      colName: "colPaperCntlNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPaperCntlNum_PROPERTIES

      // END_USER_CODE-USER_txtcolPaperCntlNum_PROPERTIES
    },
    txtcolPaperNum: {
      name: "txtcolPaperNum",
      type: "TextBoxWidget",
      colName: "colPaperNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPaperNum_PROPERTIES

      // END_USER_CODE-USER_txtcolPaperNum_PROPERTIES
    },
    txtcolParWhseCity: {
      name: "txtcolParWhseCity",
      type: "TextBoxWidget",
      colName: "colParWhseCity",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolParWhseCity_PROPERTIES

      // END_USER_CODE-USER_txtcolParWhseCity_PROPERTIES
    },
    txtcolParWhseSt: {
      name: "txtcolParWhseSt",
      type: "TextBoxWidget",
      colName: "colParWhseSt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolParWhseSt_PROPERTIES

      // END_USER_CODE-USER_txtcolParWhseSt_PROPERTIES
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutType_PROPERTIES
    },
    txtcolPrepaidInCharge: {
      name: "txtcolPrepaidInCharge",
      type: "TextBoxWidget",
      colName: "colPrepaidInCharge",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrepaidInCharge_PROPERTIES

      // END_USER_CODE-USER_txtcolPrepaidInCharge_PROPERTIES
    },
    txtcolPrepaidOutCharge: {
      name: "txtcolPrepaidOutCharge",
      type: "TextBoxWidget",
      colName: "colPrepaidOutCharge",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrepaidOutCharge_PROPERTIES

      // END_USER_CODE-USER_txtcolPrepaidOutCharge_PROPERTIES
    },
    txtcolPrevHolderName: {
      name: "txtcolPrevHolderName",
      type: "TextBoxWidget",
      colName: "colPrevHolderName",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrevHolderName_PROPERTIES

      // END_USER_CODE-USER_txtcolPrevHolderName_PROPERTIES
    },
    txtcolPrevSubholder: {
      name: "txtcolPrevSubholder",
      type: "TextBoxWidget",
      colName: "colPrevSubholder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrevSubholder_PROPERTIES

      // END_USER_CODE-USER_txtcolPrevSubholder_PROPERTIES
    },
    txtcolProdName: {
      name: "txtcolProdName",
      type: "TextBoxWidget",
      colName: "colProdName",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProdName_PROPERTIES

      // END_USER_CODE-USER_txtcolProdName_PROPERTIES
    },
    txtcolRcptNum: {
      name: "txtcolRcptNum",
      type: "TextBoxWidget",
      colName: "colRcptNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptNum_PROPERTIES
    },
    txtcolRcptStatus: {
      name: "txtcolRcptStatus",
      type: "TextBoxWidget",
      colName: "colRcptStatus",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptStatus_PROPERTIES
    },
    txtcolRcptType: {
      name: "txtcolRcptType",
      type: "TextBoxWidget",
      colName: "colRcptType",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptType_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptType_PROPERTIES
    },
    txtcolRcptVal: {
      name: "txtcolRcptVal",
      type: "TextBoxWidget",
      colName: "colRcptVal",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptVal_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptVal_PROPERTIES
    },
    txtcolRecvChrgAmt: {
      name: "txtcolRecvChrgAmt",
      type: "TextBoxWidget",
      colName: "colRecvChrgAmt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvChrgAmt_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvChrgAmt_PROPERTIES
    },
    txtcolRecvChrgPaid: {
      name: "txtcolRecvChrgPaid",
      type: "TextBoxWidget",
      colName: "colRecvChrgPaid",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvChrgPaid_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvChrgPaid_PROPERTIES
    },
    txtcolRecvChrgPer: {
      name: "txtcolRecvChrgPer",
      type: "TextBoxWidget",
      colName: "colRecvChrgPer",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvChrgPer_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvChrgPer_PROPERTIES
    },
    txtcolRecvdCity: {
      name: "txtcolRecvdCity",
      type: "TextBoxWidget",
      colName: "colRecvdCity",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvdCity_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvdCity_PROPERTIES
    },
    txtcolRecvdFrom: {
      name: "txtcolRecvdFrom",
      type: "TextBoxWidget",
      colName: "colRecvdFrom",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvdFrom_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvdFrom_PROPERTIES
    },
    txtcolRecvdSt: {
      name: "txtcolRecvdSt",
      type: "TextBoxWidget",
      colName: "colRecvdSt",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvdSt_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvdSt_PROPERTIES
    },
    txtcolRmdPct: {
      name: "txtcolRmdPct",
      type: "TextBoxWidget",
      colName: "colRmdPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRmdPct_PROPERTIES

      // END_USER_CODE-USER_txtcolRmdPct_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolSmkrdPct: {
      name: "txtcolSmkrdPct",
      type: "TextBoxWidget",
      colName: "colSmkrdPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSmkrdPct_PROPERTIES

      // END_USER_CODE-USER_txtcolSmkrdPct_PROPERTIES
    },
    txtcolSSPct: {
      name: "txtcolSSPct",
      type: "TextBoxWidget",
      colName: "colSSPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSSPct_PROPERTIES

      // END_USER_CODE-USER_txtcolSSPct_PROPERTIES
    },
    txtcolStgCont: {
      name: "txtcolStgCont",
      type: "TextBoxWidget",
      colName: "colStgCont",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStgCont_PROPERTIES

      // END_USER_CODE-USER_txtcolStgCont_PROPERTIES
    },
    txtcolStgDate: {
      name: "txtcolStgDate",
      type: "TextBoxWidget",
      colName: "colStgDate",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStgDate_PROPERTIES

      // END_USER_CODE-USER_txtcolStgDate_PROPERTIES
    },
    txtcolStgStatus: {
      name: "txtcolStgStatus",
      type: "TextBoxWidget",
      colName: "colStgStatus",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStgStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStgStatus_PROPERTIES
    },
    txtcolStorageCharge: {
      name: "txtcolStorageCharge",
      type: "TextBoxWidget",
      colName: "colStorageCharge",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStorageCharge_PROPERTIES

      // END_USER_CODE-USER_txtcolStorageCharge_PROPERTIES
    },
    txtcolStoragePaidThroughDate: {
      name: "txtcolStoragePaidThroughDate",
      type: "TextBoxWidget",
      colName: "colStoragePaidThroughDate",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStoragePaidThroughDate_PROPERTIES

      // END_USER_CODE-USER_txtcolStoragePaidThroughDate_PROPERTIES
    },
    txtcolSubholder: {
      name: "txtcolSubholder",
      type: "TextBoxWidget",
      colName: "colSubholder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSubholder_PROPERTIES

      // END_USER_CODE-USER_txtcolSubholder_PROPERTIES
    },
    txtcolToHolder: {
      name: "txtcolToHolder",
      type: "TextBoxWidget",
      colName: "colToHolder",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolToHolder_PROPERTIES

      // END_USER_CODE-USER_txtcolToHolder_PROPERTIES
    },
    txtcolTotalLbs: {
      name: "txtcolTotalLbs",
      type: "TextBoxWidget",
      colName: "colTotalLbs",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolTotalLbs_PROPERTIES
    },
    txtcolTotalPrepaidInOutCharges: {
      name: "txtcolTotalPrepaidInOutCharges",
      type: "TextBoxWidget",
      colName: "colTotalPrepaidInOutCharges",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalPrepaidInOutCharges_PROPERTIES

      // END_USER_CODE-USER_txtcolTotalPrepaidInOutCharges_PROPERTIES
    },
    txtcolTotalTons: {
      name: "txtcolTotalTons",
      type: "TextBoxWidget",
      colName: "colTotalTons",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalTons_PROPERTIES

      // END_USER_CODE-USER_txtcolTotalTons_PROPERTIES
    },
    txtcolTotClnChrg: {
      name: "txtcolTotClnChrg",
      type: "TextBoxWidget",
      colName: "colTotClnChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotClnChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolTotClnChrg_PROPERTIES
    },
    txtcolTotClnDryChrg: {
      name: "txtcolTotClnDryChrg",
      type: "TextBoxWidget",
      colName: "colTotClnDryChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotClnDryChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolTotClnDryChrg_PROPERTIES
    },
    txtcolTotDamKern: {
      name: "txtcolTotDamKern",
      type: "TextBoxWidget",
      colName: "colTotDamKern",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotDamKern_PROPERTIES

      // END_USER_CODE-USER_txtcolTotDamKern_PROPERTIES
    },
    txtcolTotDamPct: {
      name: "txtcolTotDamPct",
      type: "TextBoxWidget",
      colName: "colTotDamPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotDamPct_PROPERTIES

      // END_USER_CODE-USER_txtcolTotDamPct_PROPERTIES
    },
    txtcolTotDryChrg: {
      name: "txtcolTotDryChrg",
      type: "TextBoxWidget",
      colName: "colTotDryChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotDryChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolTotDryChrg_PROPERTIES
    },
    txtcolTotHaulChrg: {
      name: "txtcolTotHaulChrg",
      type: "TextBoxWidget",
      colName: "colTotHaulChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotHaulChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolTotHaulChrg_PROPERTIES
    },
    txtcolTotHullKernPct: {
      name: "txtcolTotHullKernPct",
      type: "TextBoxWidget",
      colName: "colTotHullKernPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotHullKernPct_PROPERTIES

      // END_USER_CODE-USER_txtcolTotHullKernPct_PROPERTIES
    },
    txtcolTotKern: {
      name: "txtcolTotKern",
      type: "TextBoxWidget",
      colName: "colTotKern",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotKern_PROPERTIES

      // END_USER_CODE-USER_txtcolTotKern_PROPERTIES
    },
    txtcolTotKernPct: {
      name: "txtcolTotKernPct",
      type: "TextBoxWidget",
      colName: "colTotKernPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotKernPct_PROPERTIES

      // END_USER_CODE-USER_txtcolTotKernPct_PROPERTIES
    },
    txtcolTotOthChrg: {
      name: "txtcolTotOthChrg",
      type: "TextBoxWidget",
      colName: "colTotOthChrg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotOthChrg_PROPERTIES

      // END_USER_CODE-USER_txtcolTotOthChrg_PROPERTIES
    },
    txtcolTransmissionNum: {
      name: "txtcolTransmissionNum",
      type: "TextBoxWidget",
      colName: "colTransmissionNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransmissionNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTransmissionNum_PROPERTIES
    },
    txtcolUploadFile: {
      name: "txtcolUploadFile",
      type: "TextBoxWidget",
      colName: "colUploadFile",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUploadFile_PROPERTIES

      // END_USER_CODE-USER_txtcolUploadFile_PROPERTIES
    },
    txtcolUserCancel: {
      name: "txtcolUserCancel",
      type: "TextBoxWidget",
      colName: "colUserCancel",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserCancel_PROPERTIES

      // END_USER_CODE-USER_txtcolUserCancel_PROPERTIES
    },
    txtcolUserChg: {
      name: "txtcolUserChg",
      type: "TextBoxWidget",
      colName: "colUserChg",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserChg_PROPERTIES

      // END_USER_CODE-USER_txtcolUserChg_PROPERTIES
    },
    txtcolUserDefined: {
      name: "txtcolUserDefined",
      type: "TextBoxWidget",
      colName: "colUserDefined",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserDefined_PROPERTIES

      // END_USER_CODE-USER_txtcolUserDefined_PROPERTIES
    },
    txtcolUserIssued: {
      name: "txtcolUserIssued",
      type: "TextBoxWidget",
      colName: "colUserIssued",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserIssued_PROPERTIES

      // END_USER_CODE-USER_txtcolUserIssued_PROPERTIES
    },
    txtcolUserLDP: {
      name: "txtcolUserLDP",
      type: "TextBoxWidget",
      colName: "colUserLDP",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserLDP_PROPERTIES

      // END_USER_CODE-USER_txtcolUserLDP_PROPERTIES
    },
    txtcolVaELKPct: {
      name: "txtcolVaELKPct",
      type: "TextBoxWidget",
      colName: "colVaELKPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVaELKPct_PROPERTIES

      // END_USER_CODE-USER_txtcolVaELKPct_PROPERTIES
    },
    txtcolVaFancyPct: {
      name: "txtcolVaFancyPct",
      type: "TextBoxWidget",
      colName: "colVaFancyPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVaFancyPct_PROPERTIES

      // END_USER_CODE-USER_txtcolVaFancyPct_PROPERTIES
    },
    txtcolVICrkedPct: {
      name: "txtcolVICrkedPct",
      type: "TextBoxWidget",
      colName: "colVICrkedPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVICrkedPct_PROPERTIES

      // END_USER_CODE-USER_txtcolVICrkedPct_PROPERTIES
    },
    txtcolVIDiscolPct: {
      name: "txtcolVIDiscolPct",
      type: "TextBoxWidget",
      colName: "colVIDiscolPct",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVIDiscolPct_PROPERTIES

      // END_USER_CODE-USER_txtcolVIDiscolPct_PROPERTIES
    },
    txtcolWhseCode: {
      name: "txtcolWhseCode",
      type: "TextBoxWidget",
      colName: "colWhseCode",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhseCode_PROPERTIES

      // END_USER_CODE-USER_txtcolWhseCode_PROPERTIES
    },
    txtcolWhsemanDep: {
      name: "txtcolWhsemanDep",
      type: "TextBoxWidget",
      colName: "colWhsemanDep",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhsemanDep_PROPERTIES

      // END_USER_CODE-USER_txtcolWhsemanDep_PROPERTIES
    },
    txtcolWhseOper: {
      name: "txtcolWhseOper",
      type: "TextBoxWidget",
      colName: "colWhseOper",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhseOper_PROPERTIES

      // END_USER_CODE-USER_txtcolWhseOper_PROPERTIES
    },
    txtcolWhsemanSign: {
      name: "txtcolWhsemanSign",
      type: "TextBoxWidget",
      colName: "colWhsemanSign",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhsemanSign_PROPERTIES

      // END_USER_CODE-USER_txtcolWhsemanSign_PROPERTIES
    },
    txtFileNum: {
      name: "txtFileNum",
      type: "TextBoxWidget",
      colName: "colFileNum",
      parent: "gridEWRViewRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFileNum_PROPERTIES

      // END_USER_CODE-USER_txtFileNum_PROPERTIES
    },
    grpbxEWRViewRRI: {
      name: "grpbxEWRViewRRI",
      type: "GroupBoxWidget",
      parent: "EWRViewRRI",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxEWRViewRRI_PROPERTIES

      // END_USER_CODE-USER_grpbxEWRViewRRI_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "EWRViewRRI",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const FormLoad = async () => {
    await lblfilenumber()
  }
  const lblfilenumber = async () => {
    try {
      setLoading(true);
      let file_num = getData(thisObj, 'EWRViewRRIFormLoadData');
      let response = await WarehouseReceiptService.RetrieveEWarehouseInquiryLogDetails(file_num)
      let data = null
      let obj ={}
      let inqarr = []
      if (response != undefined && response.length > 0) {
        data = response
      }
      for (var i=0;i<data.length;i++)
      {
      obj.txtFileNum = data[i].comp_id
      obj.txtcolToHolder = data[i].to_holder_id
      obj.txtcolAction = data[i].action_code
      obj.txtcolFromHolder = data[i].from_holder_id
      obj.txtcolDownloadDate = data[i].download_date
      obj.txtcolDownloadTime = data[i].download_time
      obj.txtcolWhseCode = data[i].whse_code
      obj.txtcolLicType = data[i].license_type
      obj.txtcolRcptNum = data[i].receipt_num
      obj.txtcolCropYr = data[i].crop_year
      obj.txtcolLicNum = data[i].license_num
      obj.txtcolNegot = data[i].negotiable
      obj.txtcolInsured = data[i].insured
      obj.txtcolPaperNum = data[i].paper_num
      obj.txtcolNetLbs = data[i].net_lbs
      obj.txtcolNetTons = data[i].net_tons
      obj.txtcolLSKLbs = data[i].lsk_lbs
      obj.txtcolLskTons = data[i].lsk_tons
      obj.txtcolTotalLbs = data[i].total_lbs
      obj.txtcolTotalTons = data[i].total_tons
      obj.txtcolStgDate = data[i].storage_date
      obj.txtcolStgCont = data[i].storage_container
      obj.txtcolStgStatus = data[i].storage_status
      obj.txtcolFarmSt = data[i].farm_state_code
      obj.txtcolFarmCnty = data[i].farm_county_code
      obj.txtcolParWhseCity = data[i].parent_whse_city
      obj.txtcolParWhseSt = data[i].parent_whse_state
      obj.txtcolNum = data[i].transaction_num
      obj.txtcolRecvdFrom = data[i].received_from
      obj.txtcolProdName = data[i].producer_name
      obj.txtcolFarmNum = data[i].fsa_farm_num
      obj.txtcolRecvdCity = data[i].received_city
      obj.txtcolRecvdSt = data[i].received_state
      obj.txtcolRecvChrgAmt = data[i].receiving_charge_amt
      obj.txtcolRecvChrgPaid = data[i].receiving_charge_paid
      obj.txtcolRecvChrgPer = data[i].receiving_charge_per
      obj.txtcolGraded = data[i].graded
      obj.txtcolPnutType = data[i].pnut_type
      obj.txtcolSeg = data[i].seg
      obj.txtcolMoistPct = data[i].moisture_pct
      obj.txtcolFMPct = data[i].fm_pct
      obj.txtcolVaELKPct = data[i].virginia_elk_pct
      obj.txtcolVaFancyPct = data[i].virginia_fancy_pct
      obj.txtcolVICrkedPct = data[i].valencia_cracked_pct
      obj.txtcolVIDiscolPct = data[i].valencia_discolored_pct
      obj.txtcolLotNum = data[i].lot_num
      obj.txtcolTotKern = data[i].total_kernels
      obj.txtcolTotDamKern = data[i].total_damage_kernels
      obj.txtcolSmkrdPct = data[i].smkrs_pct
      obj.txtcolSSPct = data[i].sound_split_pct
      obj.trtcolTotSmkPct = data[i].total_smk_pct
      obj.txtcolOthKernPct = data[i].other_kernel_pct
      obj.txtcolDamSplitPct = data[i].damage_split_pct
      obj.txtcolTotDamPct = data[i].total_damage_pct
      obj.txtcolTotKernPct = data[i].total_kernel_pct
      obj.txtcolHullPct = data[i].hull_pct
      obj.txtcolFlavFound = data[i].flavus_found
      obj.txtcolTotHullKernPct = data[i].total_hull_kernel_pct
      obj.txtcolFrzPct = data[i].freeze_pct
      obj.txtcolRmdPct = data[i].rmd_pct
      obj.txtcolWhsemanDep = data[i].whseman_is_depositor
      obj.txtcolWhseOper = data[i].whse_operator
      obj.txtcolWhsemanSign = data[i].whseman_signature
      obj.txtcolPaperCntlNum = data[i].paper_control_num
      obj.txtcolClnChrg = data[i].cleaning_charge
      obj.txtcolDryChrg = data[i].drying_charge
      obj.txtcolClnDryChrg = data[i].clean_dry_charge
      obj.txtcolHaulChrg = data[i].hauling_charge
      obj.txtcolOthChrg = data[i].other_charge
      obj.txtcolTotClnChrg = data[i].total_cleaning_charge
      obj.txtcolTotDryChrg = data[i].total_drying_charge
      obj.txtcolTotClnDryChrg = data[i].total_clean_dry_charge
      obj.txtcolTotHaulChrg = data[i].total_hauling_charge
      obj.txtcolTotOthChrg = data[i].total_other_charge
      obj.txtcolBPNum = data[i].buying_pt_num
      obj.txtcolBPName = data[i].buying_pt_name
      obj.txtcolRcptVal = data[i].receipt_value
      obj.txtcolDPSSt = data[i].dps_state_code
      obj.txtcolDPSCnty = data[i].dps_county_code
      obj.txtcolLoanNum = data[i].loan_num
      obj.txtcolCurrHolder = data[i].current_holder_id
      obj.txtcolDateCurrHolder = data[i].date_time_current_holder
      obj.txtcolSubholder = data[i].subholder_id
      obj.txtcolPrevSubholder = data[i].prev_subholder_id
      obj.txtcolRcptStatus = data[i].receipt_status
      obj.txtcolRcptType = data[i].receipt_type
      obj.txtcolCCCLoanStatus = data[i].ccc_loan_status
      obj.txtcolDateIssued = data[i].date_time_issued
      obj.txtcolDateCancelled = data[i].date_time_cancelled
      obj.txtcolDateLastChg = data[i].date_time_last_changed
      obj.txtcolDateLastAppvRej = data[i].date_time_loan_approved_rejected
      obj.txtcolDateLoanReleased = data[i].date_time_loan_released
      obj.txtcolUserIssued = data[i].user_issued
      obj.txtcolUserCancel = data[i].user_cancelled
      obj.txtcolUserChg = data[i].user_changed
      obj.txtcolUserLDP = data[i].user_loan_ldp
      obj.txtcolGrowHolder = data[i].grower_holder_id
      obj.txtcolLoanable = data[i].loanable
      obj.txtcolLoanLDPType = data[i].loan_ldp_type
      obj.txtcolDatePrevHolderChg = data[i].date_time_prev_holder_changed
      obj.txtcolCurrHolderName = data[i].current_holder_name
      obj.txtcolPrevHolderName = data[i].previous_holder_name
      obj.txtcolCCCLoanDispDt = data[i].ccc_loan_dispursement_date
      obj.txtcolCCCLoanApplDt = data[i].ccc_loan_application_date
      obj.txtcolCCCLoanRepayDt = data[i].ccc_loan_repayment_date
      obj.txtcolCCCLoanRecvDt = data[i].ccc_loan_received_date
      obj.txtcolCCCErrCode = data[i].ccc_error_code
      obj.txtcolUserDefined = data[i].user_defined
      obj.txtcolUploadFile = data[i].upload_file
      obj.txtcolTransmissionNum = data[i].transmission_num
      obj.txtcolStorageCharge = data[i].STORAGE_CHARGE
      obj.txtcolStoragePaidThroughDate = data[i].STORAGE_PAID_THROUGH_DATE
      obj.txtcolPrepaidInCharge = data[i].PREPAID_IN_CHARGE
      obj.txtcolPrepaidOutCharge = data[i].PREPAID_OUT_CHARGE
      obj.txtcolTotalPrepaidInOutCharges = data[i].TOTAL_PREPAIDIN_OUT_CHARGES
      obj.txtcolErrCode = data[i].error_code
      obj.txtcolErrText = data[i].error_text
      obj.txtcolAddUser = data[i].add_user
      obj.txtcolAddDate = data[i].add_date_time
      inqarr.push(obj)
      obj = {}
      }
      setValue(thisObj,'gridEWRViewRRI',inqarr)
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
      }
      return false
      setLoading(false);
    }
    return true
  }
  // Close Button
  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_EWRViewRRIPopUp").childNodes[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_EWRViewRRI*/}

              {/* END_USER_CODE-USER_BEFORE_EWRViewRRI*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxEWRViewRRI*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxEWRViewRRI*/}

              <GroupBoxWidget conf={state.grpbxEWRViewRRI} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_gridEWRViewRRI*/}

                {/* END_USER_CODE-USER_BEFORE_gridEWRViewRRI*/}

                <GridWidget
                  conf={state.gridEWRViewRRI}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridEWRViewRRI}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridEWRViewRRI*/}

                {/* END_USER_CODE-USER_AFTER_gridEWRViewRRI*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxEWRViewRRI*/}

              {/* END_USER_CODE-USER_AFTER_grpbxEWRViewRRI*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_EWRViewRRI*/}

              {/* END_USER_CODE-USER_AFTER_EWRViewRRI*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_EWRViewRRI);
