/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  hide,
  setData,
  setValue,
  show,
  disable,
  getValue,
} from "../../shared/WindowImports";

import "./PeanutTypeProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PeanutTypeProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PeanutTypeProfile",
    windowName: "PeanutTypeProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PeanutTypeProfile",
    // START_USER_CODE-USER_PeanutTypeProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Peanut Type Profile",
      scrCode: "PN0040B",
    },
    // END_USER_CODE-USER_PeanutTypeProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    grpboxFlexMarketAllowed: {
      name: "grpboxFlexMarketAllowed",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "Flex Market Allowed",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_grpboxFlexMarketAllowed_PROPERTIES

      // END_USER_CODE-USER_grpboxFlexMarketAllowed_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtAccountSubDepartment: {
      name: "txtAccountSubDepartment",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "Account Sub Department:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccountSubDepartment_PROPERTIES

      // END_USER_CODE-USER_txtAccountSubDepartment_PROPERTIES
    },
    txtID: {
      name: "txtID",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "ID:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtID_PROPERTIES

      // END_USER_CODE-USER_txtID_PROPERTIES
    },
    txtLSKGuage: {
      name: "txtLSKGuage",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "LSK Guage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKGuage_PROPERTIES

      // END_USER_CODE-USER_txtLSKGuage_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtOkGuage: {
      name: "txtOkGuage",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "Ok Guage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOkGuage_PROPERTIES

      // END_USER_CODE-USER_txtOkGuage_PROPERTIES
    },
    txtSMK1Guage: {
      name: "txtSMK1Guage",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "SMK 1 Guage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Guage_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Guage_PROPERTIES
    },
    txtSMK2Guage: {
      name: "txtSMK2Guage",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "SMK 2 Guage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Guage_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Guage_PROPERTIES
    },
    txtSMK3Guage: {
      name: "txtSMK3Guage",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "SMK 3 Guage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Guage_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Guage_PROPERTIES
    },
    txtSMK4Guage: {
      name: "txtSMK4Guage",
      type: "TextBoxWidget",
      parent: "grpbxPeanutTypeProfile",
      Label: "SMK 4 Guage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Guage_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Guage_PROPERTIES
    },
    grpbxPeanutTypeProfile: {
      name: "grpbxPeanutTypeProfile",
      type: "GroupBoxWidget",
      parent: "PeanutTypeProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutTypeProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutTypeProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PeanutTypeProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
   
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const FormLoad = () => {
  
    let dataObj = getData(thisObj, 'frmPeanutTypeProfile');
    if (dataObj.cmdOk_Caption == "Add") {
        document.getElementById('btnOk').innerText = "Add"
      }
      if (dataObj.cmdOk_Caption == 'Update') {
        document.getElementById('btnOk').innerText = "Update"
        disable(thisObj, 'txtID')
        setValue(thisObj, 'txtID', dataObj.txtID)
        setValue(thisObj, 'txtName', dataObj.txtName)
        setValue(thisObj, 'txtOkGuage', dataObj.txtOkGuage)
        setValue(thisObj, 'txtSMK1Guage', dataObj.txtSMK1Guage)
        setValue(thisObj, 'txtSMK2Guage', dataObj.txtSMK2Guage)
        setValue(thisObj, 'txtSMK3Guage', dataObj.txtSMK3Guage)
        setValue(thisObj, 'txtSMK4Guage', dataObj.txtSMK4Guage)
        setValue(thisObj, 'txtLSKGuage', dataObj.txtLSKGuage)
        setValue(thisObj, 'txtAccountSubDepartment', dataObj.txtAccountSubDepartment)
        setValue(thisObj, 'grpboxFlexMarketAllowed', dataObj.grpboxFlexMarketAllowed)
        setValue(thisObj, 'lblAddedByValue', dataObj.lblAddedBy)
        setValue(thisObj, 'lblChangedByValue', dataObj.lblChangedBy)
      }
      if (dataObj.cmdOk_Caption == 'View') {
          hide(thisObj,'btnOk',false)
          disable(thisObj, 'txtID')
          setValue(thisObj, 'txtID', dataObj.txtID)
          setValue(thisObj, 'txtName', dataObj.txtName)
          setValue(thisObj, 'txtOkGuage', dataObj.txtOkGuage)
          setValue(thisObj, 'txtSMK1Guage', dataObj.txtSMK1Guage)
          setValue(thisObj, 'txtSMK2Guage', dataObj.txtSMK2Guage)
          setValue(thisObj, 'txtSMK3Guage', dataObj.txtSMK3Guage)
          setValue(thisObj, 'txtSMK4Guage', dataObj.txtSMK4Guage)
          setValue(thisObj, 'txtLSKGuage', dataObj.txtLSKGuage)
          setValue(thisObj, 'txtAccountSubDepartment', dataObj.txtAccountSubDepartment)
          setValue(thisObj, 'grpboxFlexMarketAllowed', dataObj.grpboxFlexMarketAllowed)
          setValue(thisObj, 'lblAddedByValue', dataObj.lblAddedBy)
          setValue(thisObj, 'lblChangedByValue', dataObj.lblChangedBy)
      }
  }
  //Formvalid function
  const FormValid = () => {
    var bFormValid = false
    let Id = getValue(thisObj, 'txtID')
    let name = getValue(thisObj, 'txtName')
    let AccountSubDepartment = getValue(thisObj, 'txtAccountSubDepartment')
    if (Id == null || Id == '' || Id == undefined) {
      showMessage(thisObj, "Id is a required field")
       return bFormValid;
    }
    if (name == null || name == '' || name == undefined) {
      showMessage(thisObj, "Name is a required field")
       return bFormValid;
    }
    if (AccountSubDepartment == null || AccountSubDepartment == '' || AccountSubDepartment == undefined) {
      showMessage(thisObj,"Account Sub Dept is a required field.")
      return bFormValid;
    }
     bFormValid =true
    }
  const onbtnOkClick = async () => {
    try {
      if (FormValid() == false) {
        return;
      }
      let dataObj = getData(thisObj, "frmPeanutTypeProfile");
      let pnut_type_id = getValue(thisObj, 'txtID').toLocaleUpperCase()
      let pnut_type_name = getValue(thisObj, 'txtName')
      let ok_guage = getValue(thisObj, 'txtOkGuage')
      let smk_1_guage = getValue(thisObj, 'txtSMK1Guage')
      let smk_2_guage = getValue(thisObj, 'txtSMK2Guage')
      let smk_3_guage = getValue(thisObj, 'txtSMK3Guage')
      let smk_4_guage = getValue(thisObj, 'txtSMK4Guage')
      let lsk_guage = getValue(thisObj, 'txtLSKGuage')
      let acct_sub_dept = getValue(thisObj, 'txtAccountSubDepartment')
      let flex_mkt_allow = getValue(thisObj, 'grpboxFlexMarketAllowed')
      if (flex_mkt_allow == true) {
        flex_mkt_allow = 'Y'
      }
      else if (flex_mkt_allow == false || flex_mkt_allow == '' || flex_mkt_allow == null || flex_mkt_allow == undefined){
        flex_mkt_allow = 'N'
      }
      //add button functionality
      if (dataObj.cmdOk_Caption == "Add") {
        let temp = {
          "pnut_type_id": pnut_type_id,
          "pnut_type_name": pnut_type_name,
          "ok_guage": ok_guage,
          "smk_1_guage": smk_1_guage,
          "smk_2_guage": smk_2_guage,
          "smk_3_guage": smk_3_guage,
          "smk_4_guage": smk_4_guage,
          "lsk_guage": lsk_guage,
          "acct_sub_dept": acct_sub_dept,
          "flex_mkt_allow": flex_mkt_allow
        }
        let response = await SystemMaintenanceMasterManagementService.CreatePeanutTypeControl(null, temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
          return;
        };
          let Array = []
          let Obj = {}
          Obj.txtID = pnut_type_id
          Obj.txtName = pnut_type_name
          Obj.txtOkGuage = ok_guage
          Obj.txtSMK1Guage = smk_1_guage
          Obj.txtSMK2Guage = smk_2_guage
          Obj.txtSMK3Guage = smk_3_guage
          Obj.txtSMK4Guage = smk_4_guage
          Obj.txtLSKGuage = lsk_guage
          Obj.txtAccountSubDepartment = acct_sub_dept
          Obj.grpboxFlexMarketAllowed = flex_mkt_allow
          Array.push(Obj)
          setData(thisObj, 'frmPeanutTypeSetup', Obj)
          document.getElementById("SystemMaintenanceMasterManagement_PeanutTypeProfilePopUp").childNodes[0].click()
        
      }
       //update button functionality
      if (dataObj.cmdOk_Caption == "Update") {
        disable(thisObj, 'txtID')
        let temp = {
          "pnut_type_name": pnut_type_name,
          "ok_guage": ok_guage,
          "smk_1_guage": smk_1_guage,
          "smk_2_guage": smk_2_guage,
          "smk_3_guage": smk_3_guage,
          "smk_4_guage": smk_4_guage,
          "lsk_guage": lsk_guage,
          "acct_sub_dept": acct_sub_dept,
          "flex_mkt_allow": flex_mkt_allow
        }
        let response = await SystemMaintenanceMasterManagementService.UpdatePeanutTypeControl(pnut_type_id, null, temp)
       let data = response
       if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response.message)
        return;
      }
        let Array = []
        let Obj = {}
        Obj.txtID = pnut_type_id
        Obj.txtName = pnut_type_name
        Obj.txtOkGuage = ok_guage
        Obj.txtSMK1Guage = smk_1_guage
        Obj.txtSMK2Guage = smk_2_guage
        Obj.txtSMK3Guage = smk_3_guage
        Obj.txtSMK4Guage = smk_4_guage
        Obj.txtLSKGuage = lsk_guage
        Obj.txtAccountSubDepartment = acct_sub_dept
        Obj.grpboxFlexMarketAllowed = flex_mkt_allow
        Array.push(Obj)
        setData(thisObj, 'frmPeanutTypeSetup', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_PeanutTypeProfilePopUp").childNodes[0].click()
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;
  //  cancel click
    const onbtnCancelClick = () => {
      try {
        document.getElementById("SystemMaintenanceMasterManagement_PeanutTypeProfilePopUp").childNodes[0].click()
      } catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        } else {
          showMessage(
            thisObj,
            "Exception in PreSubmit Event code for widget:btnCancel event:Click"
          );
        }
        return false;
      }
      return true;
    };
    thisObj.onbtnCancelClick = onbtnCancelClick;
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PeanutTypeProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PeanutTypeProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPeanutTypeProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPeanutTypeProfile*/}

              <GroupBoxWidget
                conf={state.grpbxPeanutTypeProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtID*/}

                {/* END_USER_CODE-USER_BEFORE_txtID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtID*/}

                {/* END_USER_CODE-USER_AFTER_txtID*/}
                {/* START_USER_CODE-USER_BEFORE_grpboxFlexMarketAllowed*/}

                {/* END_USER_CODE-USER_BEFORE_grpboxFlexMarketAllowed*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.grpboxFlexMarketAllowed}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_grpboxFlexMarketAllowed*/}

                {/* END_USER_CODE-USER_AFTER_grpboxFlexMarketAllowed*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_txtOkGuage*/}

                {/* END_USER_CODE-USER_BEFORE_txtOkGuage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOkGuage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOkGuage*/}

                {/* END_USER_CODE-USER_AFTER_txtOkGuage*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK1Guage*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK1Guage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK1Guage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK1Guage*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK1Guage*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK2Guage*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK2Guage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK2Guage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK2Guage*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK2Guage*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK4Guage*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK4Guage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK4Guage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK4Guage*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK4Guage*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK3Guage*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK3Guage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK3Guage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK3Guage*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK3Guage*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKGuage*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKGuage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKGuage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKGuage*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKGuage*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccountSubDepartment*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccountSubDepartment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccountSubDepartment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccountSubDepartment*/}

                {/* END_USER_CODE-USER_AFTER_txtAccountSubDepartment*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPeanutTypeProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutTypeProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PeanutTypeProfile*/}

              {/* END_USER_CODE-USER_AFTER_PeanutTypeProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_PeanutTypeProfile);
