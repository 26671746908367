/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvInAvailAgreement from "./DelvInAvailAgreement";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvInAvailAgreement Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvInAvailAgreement />);
    });
  });
  afterEach(cleanup);
  test("is DelvInAvailAgreement Loads Successfully", () => {
    expect(screen.getByText("DelvInAvailAgreement")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvInAvailAgreement", () => {
    // START_USER_CODE-USER_DelvInAvailAgreement_Custom_Test_Case
    // END_USER_CODE-USER_DelvInAvailAgreement_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvInAvailAgreement />);
    });
  });
  afterEach(cleanup);
  test("btnAttach(Button Widget) Test Cases", async () => {
    const btnAttach = screen.getByTestId("btnAttach");
    expect(btnAttach).toBeInTheDocument;
    expect(btnAttach.textContent).toEqual(
      t("WarehouseReceipts:DelvInAvailAgreement_btnAttach")
    );
  });
  test("Custom Test Cases for btnAttach", () => {
    // START_USER_CODE-USER_btnAttach_TEST
    // END_USER_CODE-USER_btnAttach_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:DelvInAvailAgreement_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnRemove(Button Widget) Test Cases", async () => {
    const btnRemove = screen.getByTestId("btnRemove");
    expect(btnRemove).toBeInTheDocument;
    expect(btnRemove.textContent).toEqual(
      t("WarehouseReceipts:DelvInAvailAgreement_btnRemove")
    );
  });
  test("Custom Test Cases for btnRemove", () => {
    // START_USER_CODE-USER_btnRemove_TEST
    // END_USER_CODE-USER_btnRemove_TEST
  });
  test("grpbxDelvInAvailAgreement(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvInAvailAgreement = screen.getByTestId(
      "grpbxDelvInAvailAgreement"
    );
    expect(grpbxDelvInAvailAgreement.tagName).toBe("BUTTON");
    expect(grpbxDelvInAvailAgreement.type).toBe("button");
    expect(grpbxDelvInAvailAgreement.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvInAvailAgreement", () => {
    // START_USER_CODE-USER_grpbxDelvInAvailAgreement_TEST
    // END_USER_CODE-USER_grpbxDelvInAvailAgreement_TEST
  });
  test("lbl4(Label Widget) Test Cases", async () => {
    const lbl4 = screen.getByTestId("lbl4");
    expect(lbl4.tagName).toBe("LABEL");
    expect(lbl4.classList).toContain("form-label");
    expect(lbl4.textContent).toEqual(
      t("WarehouseReceipts:DelvInAvailAgreement_lbl4")
    );
  });
  test("Custom Test Cases for lbl4", () => {
    // START_USER_CODE-USER_lbl4_TEST
    // END_USER_CODE-USER_lbl4_TEST
  });
  test("lblAgreeNumBuyPtId(Label Widget) Test Cases", async () => {
    const lblAgreeNumBuyPtId = screen.getByTestId("lblAgreeNumBuyPtId");
    expect(lblAgreeNumBuyPtId.tagName).toBe("LABEL");
    expect(lblAgreeNumBuyPtId.classList).toContain("form-label");
    expect(lblAgreeNumBuyPtId.textContent).toEqual(
      t("WarehouseReceipts:DelvInAvailAgreement_lblAgreeNumBuyPtId")
    );
  });
  test("Custom Test Cases for lblAgreeNumBuyPtId", () => {
    // START_USER_CODE-USER_lblAgreeNumBuyPtId_TEST
    // END_USER_CODE-USER_lblAgreeNumBuyPtId_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:DelvInAvailAgreement_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
});
