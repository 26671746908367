/* eslint-disable*/
import React from "react";
import WarehouseReceipts_Delivery from "./Delivery";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("Delivery Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_Delivery />);
    });
  });
  afterEach(cleanup);
  test("is Delivery Loads Successfully", () => {
    expect(screen.getByText("Delivery")).toBeInTheDocument;
  });
  test("Custom Test Cases for Delivery", () => {
    // START_USER_CODE-USER_Delivery_Custom_Test_Case
    // END_USER_CODE-USER_Delivery_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_Delivery />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:Delivery_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelAgreeProfile(Button Widget) Test Cases", async () => {
    const btnDelAgreeProfile = screen.getByTestId("btnDelAgreeProfile");
    expect(btnDelAgreeProfile).toBeInTheDocument;
    expect(btnDelAgreeProfile.textContent).toEqual(
      t("WarehouseReceipts:Delivery_btnDelAgreeProfile")
    );
  });
  test("Custom Test Cases for btnDelAgreeProfile", () => {
    // START_USER_CODE-USER_btnDelAgreeProfile_TEST
    // END_USER_CODE-USER_btnDelAgreeProfile_TEST
  });
  test("btnDelSettleProfile(Button Widget) Test Cases", async () => {
    const btnDelSettleProfile = screen.getByTestId("btnDelSettleProfile");
    expect(btnDelSettleProfile).toBeInTheDocument;
    expect(btnDelSettleProfile.textContent).toEqual(
      t("WarehouseReceipts:Delivery_btnDelSettleProfile")
    );
  });
  test("Custom Test Cases for btnDelSettleProfile", () => {
    // START_USER_CODE-USER_btnDelSettleProfile_TEST
    // END_USER_CODE-USER_btnDelSettleProfile_TEST
  });
  test("grpbxDeliveryMenu(GroupBox Widget) Test Cases", async () => {
    const grpbxDeliveryMenu = screen.getByTestId("grpbxDeliveryMenu");
    expect(grpbxDeliveryMenu.tagName).toBe("BUTTON");
    expect(grpbxDeliveryMenu.type).toBe("button");
    expect(grpbxDeliveryMenu.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDeliveryMenu", () => {
    // START_USER_CODE-USER_grpbxDeliveryMenu_TEST
    // END_USER_CODE-USER_grpbxDeliveryMenu_TEST
  });
});
